import { Button, Tooltip } from "antd";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeAdjustedExperienceIdSelector } from "/src/views/Experiences/selector";
import { dbUserResponsesSelector, loadingSelector } from "/src/views/UserResponses/selector";
import { activeExperienceUserSelector, overallGradingFormVisibleSelector } from "../selector";
import {
  DoubleRightOutlined,
} from "@ant-design/icons";
import { activeSegmentIdSelector } from "/src/views/Segments/selector";
import { actions as segmentActions } from "/src/views/Segments/redux";
import _ from "lodash";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const NonMemoizedUngradedQuestionButton = (props) => {
  console.log("UngradedQuestionButton =====>", props);
  const { questions, nestedSegmentsInSequence } = props
  let ungradedURs = {}
  let gradedURs = {}
  let ungradedURsLength = 0;
  const mrIntl = useTranslate()

  const [buttonsDisableStatus, setButtonsDisableStatus] = useState({
    nextUngradedQuestion: ungradedURsLength === 0,
  });

  const dispatch = useDispatch()
  const activeAdjustedExperienceId = useSelector(
    activeAdjustedExperienceIdSelector()
  );
  const loading = useSelector(loadingSelector())
  const activeExperienceUser = useSelector(activeExperienceUserSelector());
  const activeSegmentId = useSelector(activeSegmentIdSelector());
  const activeEUUserResponses = useSelector(
    dbUserResponsesSelector(
      activeAdjustedExperienceId,
      activeExperienceUser.user_id,
      null,
      false,
      false,
      activeExperienceUser.id
    )
  );
  const overallGradingFormVisible = useSelector(overallGradingFormVisibleSelector())

  if (activeEUUserResponses) {
    activeEUUserResponses.map((ur) => {
      let q = nestedSegmentsInSequence.find((s) => ur.segment_id == s.id && s.question_segment_attributes && s.question_segment_attributes.question_type != "group")
      if (q) {
        if (!ur.marked) {
          ungradedURs[ur.segment_id] = ur
          ungradedURsLength += 1
        } else {
          gradedURs[ur.segment_id] = ur
        }
      }
    })
  }

  useEffect(() => {
    if (!overallGradingFormVisible && !buttonsDisableStatus.nextUngradedQuestion) {
      document.addEventListener("keydown", onKeyDown, false);

      return () => {
        document.removeEventListener("keydown", onKeyDown, false);
      };
    }
  }, [activeSegmentId, overallGradingFormVisible, buttonsDisableStatus.nextUngradedQuestion]);

  useEffect(() => {
    setButtonsDisableStatus({
      ...buttonsDisableStatus,
      nextUngradedQuestion: (ungradedURsLength === 0) || (ungradedURsLength === 1 && !_.isEmpty(ungradedURs[activeSegmentId])),
    });
  }, [activeExperienceUser.id, ungradedURsLength, activeSegmentId])

  const onKeyDown = (event) => {
    const { target, key, ctrlKey, shiftKey } = event;
    console.log(
      "on key down event ==>1",
      event,
      target,
      target.nodeName,
      target.contentEditable
    );
    if (ctrlKey && shiftKey && key === "ArrowRight") {
      goToUngraded();
      event.preventDefault();
    }
  };

  const goToUngraded = (direction = "next") => {
    const currentIndex = nestedSegmentsInSequence.findIndex(q => q.id === activeSegmentId);
    let newIndex = currentIndex
    console.log("newIndex ==========>", currentIndex);
    if (ungradedURsLength >= 0) {
      while (true) {
        //if newIndex is currently 3 and nestedSegmentsInSequence has a length of 5, this line of code will update newIndex to 4. If newIndex is currently 4, the line of code will update newIndex to 0.
        newIndex = (newIndex + 1) % nestedSegmentsInSequence.length;
        let questionId = nestedSegmentsInSequence[newIndex].id;
        const ungradedUR = ungradedURs[questionId];

        if (ungradedUR) {
          dispatch(segmentActions.setActiveIdSuccess({ id: questionId }));
          break;
        }
      }
    }
  };

  return (
    <Tooltip title={buttonsDisableStatus.nextUngradedQuestion ? mrIntl("UngradedQuestionButton.no_ungraded_question_found") : mrIntl("UngradedQuestionButton.next_ungraded_question")}>
      <Button
        // loading={buttonsDisableStatus.nextUngradedQuestion ? false : loading }
        disabled={buttonsDisableStatus.nextUngradedQuestion || loading}
        type="primary"
        shape={"round"}
        onClick={() => goToUngraded("next")}
      >
        <DoubleRightOutlined />
      </Button>
    </Tooltip>
  )
}

const UngradedQuestionButton = memo(NonMemoizedUngradedQuestionButton);
export default UngradedQuestionButton;