import React, { useState, useEffect } from "react";
import { Button } from "antd";
// import { GrBookmark } from "react-icons/gr";
// import { GoBookmark } from "react-icons/go";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs"
import { useSelector, useDispatch } from "react-redux";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { bookmarksSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import "/src/views/Experiences/ExperienceShow/Components/BookmarkList/BookmarkList.scss";

const BookmarkButton = ({
  topic_idx,
  question_number,
  segment_id,
  question_id,
  topic_id,
  experience_id,
}) => {
  const [bookMarked, setBookMarked] = useState(false);
  const dispatch = useDispatch();
  const bookmarks = useSelector(bookmarksSelector);
  // const questionsStatus = useSelector(questionsStatusSelector);

  useEffect(() => {
    if (bookmarks.length !== 0) {
      const bookmark = bookmarks.find(
        (status) => status.segment_id === segment_id
      );
      if (bookmark) {
        setBookMarked(bookmark.bookmarked);
      }
    }
  }, []);

  const setBookMark = (bookmarked) => {
    setBookMarked(bookmarked);
    const newBookmarks = [...bookmarks]
    const index = newBookmarks.findIndex(
      (status) =>
        status.question_id === question_id && status.segment_id === segment_id && status.topic_id === topic_id
    );
    const objToUpdate = {
      question_id: question_id,
      segment_id: segment_id,
      topic_id: topic_id,
      topic_idx: topic_idx,
      question_number: question_number,
      bookmarked: bookmarked,
    };
    if (index >= 0) {
      newBookmarks[index] = {
        ...newBookmarks[index],
        ...objToUpdate,
      };
    } else {
      newBookmarks.push(objToUpdate);
    }

    const userInfo = {
      bookmarks: newBookmarks,
    };

    console.log("user_info to update ==>", userInfo);
    dispatch(
      firestoreInteractionActions.setUserInfo({
        experienceId: experience_id,
        userInfo,
      })
    );
  };
  return (
    <Button
    className="bookmark-btn"
      onClick={() => setBookMark(!bookMarked)}
      style={{
        position: "absolute",
        left: "-35px",
        top: "-2px",
      }}
      type="text"
      shape="circle" //Made the shape circle earlier it was rectangle, using circle for the cloud icon too.
      icon={
        bookMarked ? (
          <BsBookmarkFill
            className="bookmark-icon-fill-question"
          />
        ) : (
          <BsBookmark
            className="bookmark-icon"
          />
        )
      }
    ></Button>
  );
};

export default BookmarkButton;
