import React from "react";
import PropTypes from 'prop-types';

import { MrCrudView, MrForm as WrappedMrForm } from "mr_react_framework";
import { actions } from "./redux";
import IntegrationListItem from "./IntegrationListItem/IntegrationListItem";
import IntegrationsFilter from "./IntegrationsFilter/IntegrationsFilter";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors from "/src/App/commonSelectors";
import CustomExperienceCrudList from "../Experiences/CustomExperienceCrudList";
import GroupListItem from "../Groups/GroupListItem/GroupListItem";
import IntegrationList from "./IntegrationList/IntegrationList";
import { isKeyCodeValid, isStringEmpty } from "/src/lib/utils/helperMethods";


export const returnForm = (props) => {
  const { mrIntl } = props;
  let form = {
    processFormData: (dataProps, opts) => {
      if(opts?.values?.label){
        opts.values.label = opts.values.name;
      }
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        name: {
          type: "string",
          placeholder: mrIntl("CommonText.name"),
          rules: [
            {
              validator(rule, value) {
                if (!isStringEmpty(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Please enter valid integration name");
                }
              },
            },
          ],
        },
        code: {
          type: "string",
          placeholder: mrIntl("CommonText.code"),
          rules: [
            {
              validator(rule, value) {
                if (isKeyCodeValid(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Please enter valid integration code");
                }
              },
            },
          ],
        }
        // desc: {
        //   type: "string",
        // },
        // label: {
        //   type: "string",
        // },
        // settings: {
        //   // elementType: "object",
        //   label: "Settings",
        //   type: "string",
        // },
      },
    },
    uiSchema: {
      desc: {
        "ui:widget": "textarea"
      },
      settings: {
        "ui:field": "json"
      },
    },
  };
  return form;
};


const Integrations = MrCrudView({
  resourceName: "integrations",
  singleResourceName: "integration",
  displayName: "resourceName",
  resourceUrl: "/integrations",
  // layout: OrgsLayout,
  // filter: IntegrationsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  listItem: IntegrationListItem,
  forms: [{ name: "integrations", form: returnForm }],
  actions,
  actionNames: ["fetch", "create", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: IntegrationListItem,
    },
    itemDetail: {
      widget: IntegrationListItem,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      config: {
        search: {
          show: false
        },
        create: {
          show: true
        },
      }
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      widget: IntegrationList,
      layout: CustomExperienceCrudList,
      forceReload: true,
    },
  },
});

// Integrations.defaultProps = {};

Integrations.propTypes = {};
export default Integrations;
