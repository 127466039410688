
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Alert } from 'antd';
import { actions as segmentActions } from "/src/views/Segments/redux";
import { pinnedSegmentsSelector } from '../selector';
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const PinnedSegmentPlaceholder = (props) => {
  const { segment_id } = props
  
  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  const pinnedSegments = useSelector(pinnedSegmentsSelector());
  const handleUnpin = () => {
    let pinnedSegmentIndex = pinnedSegments.findIndex((s) => s.id === segment_id)
    const newPinnedSegments = [...pinnedSegments];
    newPinnedSegments.splice(pinnedSegmentIndex, 1);
    dispatch(segmentActions.pinnedSuccess({ pinnedSegments: newPinnedSegments }));
  };

  return (
    <Alert
      description={
        <Button size="large" type="text" onClick={handleUnpin}>
          {mrIntl("SegmentList.content_pinned_click_to_unpin_button")}      
        </Button>
      }
      type="info"
      className="pinned-segment-placeholder"
      closable={false}
    />
  );
};

export default PinnedSegmentPlaceholder;