import { createSelector } from "reselect";
import { activeExperienceUserIdState } from "/src/views/ExperienceUsers/selector";
// import lodash from "lodash";

const loadingState = (state, props) => {
  return state.userResponses.get("loading");
};
export const loadingSelector = () =>
  createSelector([loadingState], (loading) => loading);

// const errorState = (state, props) => state.userResponses.get("error");

// export const errorSelector = () =>
//   createSelector([errorState], (error) => error);

// const userResponsesForSummaryState = (state, props) =>
//   state.userResponses.get("user_responses_for_summary");

// export const userResponsesForSummarySelector = () =>
//   createSelector([userResponsesForSummaryState], (userResponses) => userResponses);


const dbUserResponsesState = (state) => {
  return state.userResponses.get("user_responses", [])
}

export const dbUserResponsesSelector = (experienceId, userId, segmentId, attemptNo, returnSingle = false, euId) => 
  createSelector([dbUserResponsesState], (userResponses) => {
    // console.log("dbuser responses ===>", experienceId, userId, segmentId, attemptNo, returnSingle)

    let newUserResponses = userResponses
    if (userResponses.hasOwnProperty("size")) {
      newUserResponses = userResponses.toJS();
    } 
    // try {
    //   newUserResponses = userResponses.toJS()
    // } catch (error) {
    //   newUserResponses = userResponses
    //   console.log("going inside catch ==>", error, userResponses)
    // }
    let filterStrings = []
    let filteredUserResponses = []
    experienceId && filterStrings.push(ur => ur.experience_id === parseInt(experienceId))
    segmentId && filterStrings.push(ur => ur.segment_id === parseInt(segmentId))
    userId && filterStrings.push(ur => ur.user_id === parseInt(userId))
    // Adding checking euId only when userId is passed - to handle multiple duplicate urs in peer reviewer case
    euId && filterStrings.push(ur => ur.experience_user_id === parseInt(euId))
    attemptNo && filterStrings.push(ur => ur.attempt_no === parseInt(attemptNo))

    if (filterStrings.length) {
      // console.log("SID filtered userResponse filterStrings", filterStrings);
      filteredUserResponses = newUserResponses.filter((ur) => filterStrings.every(f => f(ur)))
      // filteredUserResponses = userResponses.filter((ur) => ur.segment_id === parseInt(segmentId) )
      // console.log("SID filtered userResponse filteredUserResponses", filteredUserResponses);
      if (returnSingle){
        return filteredUserResponses[0]  // moved it here so that when only 1 needed - in SegmentItemDetail grading and in UserResponseCellDetail - compare against single object so that it can match correctly and not re-render all urs in table
      } else {
        return filteredUserResponses
      }
    } else{
      return newUserResponses
    }
  });

  const summaryLoadingState = (state, props) => {
    return state.userResponses.get("summaryloading");
  };
  export const summaryLoadingSelector = () =>
    createSelector([summaryLoadingState], (summaryloading) => summaryloading);
  