import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Tag,
  Space,
  Alert,
  Statistic,
  Spin,
  Col,
  Result,
} from "antd";
import {
  SendOutlined,
  LeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import QuitBtn from "/src/components/UI/QuitBtn";
import SessionActiveModal from "/src/views/Experiences/ExperienceShow/Components/SessionActiveModal";
import AttemptStatus from "/src/views/Experiences/ExperienceShow/Components/AttemptStatus";
import {
  attachmentsCountSelector,
  attemptedCountSelector,
  isTimeUpSelector,
  submitExperienceLoadingSelector,
  questionsStatusSelector
} from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { appRegionSelector, currentUserSelector } from "/src/views/Auth/Login/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import { endSessionBtnLoadingSelector } from "../../selector";
import { checkInternetConnectivity } from "/src/lib/CheckInternetConnectivity/CheckInternetConnectivity";

const EndView = (props) => {
  console.log("EndView props ==>", props)
  const {
    experienceProps,
    progressBar,
    // isTimeUp,
    // setIsTimeUp,
    setEndTestButtonLoading,
    setSwitchFromStartToResumeView,
    isSetUserInfoLoading,
    videoRender,
    focusLostModal,
    sessionModalConfig,
    setShowQRModal,
    timer,
    backToTestButtonLoading,
    setBackToTestButtonLoading,
    experienceDuration
  } = props;

  const {
    experience,
    experienceSettings,
    setUserInfo,
    embedded,
  } = experienceProps;

  const userInfoFromReduxAttachmentsCount = useSelector(attachmentsCountSelector);
  const isTimeUp = useSelector(isTimeUpSelector);
  const attemptedCount = useSelector(attemptedCountSelector);
  const currentUser = useSelector(currentUserSelector());
  const isSubmitExperienceLoading = useSelector(
    submitExperienceLoadingSelector
  );
  const endSessionBtnLoading = useSelector(endSessionBtnLoadingSelector());
  const questionsStatus = useSelector(questionsStatusSelector);
  const appRegion = useSelector(appRegionSelector())

  const questionsCount = experience.attemptable_questions_count;

  const dispatch = useDispatch();
  
  // In StartView, if we're at the bottom and click "End Test" with a slow or disconnected internet, a scroll issue occurs in EndView on macOS app. We're adding a useEffect to fix this - APL-5765
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const totalCount = userInfoFromReduxAttachmentsCount;
  const mrIntl = useTranslate();

  function showConfirm() {
    let confirmContent = (
      <>
        {mrIntl("EndView.attempted_question_message")}<br />
        {/* You have not attempted all questions. <br /> */}
        {mrIntl("EndView.attempted_question")}: {attemptedCount}/{questionsCount}
      </>
    );

    showConfirmModal({
      title: mrIntl("EndView.assessment_ended_modal_message"),
      icon: <ExclamationCircleOutlined />,
      content: confirmContent,
      okText: mrIntl("CommonText.submit"),
      cancelText: mrIntl("CommonText.cancel"),
      okType: "primary",
      // okText: "Submit",
      className: "height-centered-modal",
      onOk() {
        console.log("OK");
        submitResponses();
      },
      onCancel() {
        console.log("Cancel");
      },
    })
  }

  function submitResponses(params = {}) {
    console.log("submitResponses");
    if (isSubmitExperienceLoading || isSetUserInfoLoading) {
      return;
    }
    dispatch(
      firestoreInteractionActions.submitExperience({
        experienceId: experience.id,
        ...params,
      })
    );
  }

  let testEndedSubtitle = (
    <div>
      {!isSubmitExperienceLoading && (
        <Alert
          className="instructions-text"
          message={mrIntl("EndView.assessment_ended_message")}
          // message="Assessment ended. Make sure you submit your assessment!"
          type="warning"
          showIcon
        />
      )}

      <Space direction="vertical" style={{ marginTop: "20px" }}>
        {experienceSettings.is_timed &&
          experienceDuration > 0 &&
          isTimeUp && (
            <Tag color="red" style={{ marginRight: "0px", marginTop: "10px" }}>
              <MrTranslate id={"EndView.time_up"}/>
            </Tag>
          )}
        {experienceSettings.is_timed &&
          experienceDuration > 0 &&
          timer}

        {(isSetUserInfoLoading || isSubmitExperienceLoading) && (
          <Spin size="large" style={{ marginTop: "20px" }} />
        )}

        <div>{progressBar}</div>
        <Space>
          <AttemptStatus 
            // questionsStatus={questionsStatus} 
            experienceTopics={experience.topics} 
            experienceId={experience.id}
            userId={currentUser.id}
            wide={true} 
          />
        </Space>

        {experienceSettings.allow_student_upload_files && (
          <>
            <Statistic title={mrIntl("EndView.total_files_uploaded")} value={totalCount} />
          </>
        )}
      </Space>
      {/* {!embedded && experienceSettings.enable_ap_video_monitoring && (
        <Col span={20} offset={2} className="m-t-15">
          <div style={{width: "100%", height: '350px'}}> 
            {videoRender}
          </div>
        </Col>
      )} */}
    </div>
  );

  let backToTestBtn = (
    <Button
      onClick={() => {
        setBackToTestButtonLoading(true);
        setSwitchFromStartToResumeView(false);
        setUserInfo(
          { currentView: "startTest" },
          {
            log: {
              // logging: experienceItemConfig.log.logging,
              logging: true,
              action: "back_to_test_from_end_view",
            },
          }
        );
        // // Worse case scenario - when setUserInfo failing/no change of currentView for some reason, still remove loading so student can try again
        setTimeout(() => {
          setBackToTestButtonLoading(false);
        }, 5000);
      }}
      disabled={isSetUserInfoLoading || isSubmitExperienceLoading || endSessionBtnLoading}
      loading={backToTestButtonLoading}
      icon={<LeftOutlined />}
    >
      {/* {" "} */}
      <MrTranslate id={"EndView.back_to_test"}/>
    </Button>
  );
  let finalSubmitTestBtn = (
    <Button
      type="primary"
      onClick={() => {
        checkInternetConnectivity(
          appRegion,
          (internetStatus) => {
            if (internetStatus === "online") {
              attemptedCount === questionsCount ? submitResponses() : showConfirm();
            }
          },
          mrIntl)
      }}
      disabled={isSetUserInfoLoading || isSubmitExperienceLoading || endSessionBtnLoading}
    >
     {/* Submit Test <SendOutlined /> */}
     {mrIntl("EndView.submit_test")} <SendOutlined />
    </Button>
  );

  let extra = [];

  !isTimeUp && extra.push(backToTestBtn);
  extra.push(<QuitBtn key="end-view-quit-button" withLog={true} action={"quit_app_after_end"} />);
  extra.push(finalSubmitTestBtn);
  // <Button key="quit" href="/">Back to Home</Button> add for offline app later

  let finalRender = (
    <React.Fragment>
      <Result
        className="end-test-result"
        status="info"
        // icon={<EditOutlined />}
        title={experience.name}
        subTitle={testEndedSubtitle}
        extra={extra}
      />

      {focusLostModal}
      <SessionActiveModal
        sessionModalConfig={sessionModalConfig}
        setShowQRModal={setShowQRModal}
        itemMode={experienceSettings.mode}
      />
      {/* <SessionActiveModal sessionModalConfig={sessionModalConfig}/> */}
    </React.Fragment>
  );

  return finalRender;
};

export default EndView;