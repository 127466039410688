import React, { useState } from "react";
import { MrSelect } from "mr_react_framework";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import { userRoleMap } from "/src/lib/utils/helperMethods";
import SelectOrgDropdown from "/src/components/AppSpecific/SelectOrg/SelectOrgDropdown";
import { SelectAIModel, SelectFeedbackType, SelectPromptStatus } from "/src/components/AppSpecific/SelectSubject/SelectSubject";

const PromptsFilter = (props) => {
  console.log("PromptsFilter", props);
  
  let extraFilters = [];
  let by_model;
  let by_status;

  if (props.by_model) {
    by_model = props.by_model;
  }

  extraFilters.push(
    <SelectAIModel
      value={by_model}
      onChange={(value) => {
        props.setParams({ by_model: value });
      }}
      dropdownMatchSelectWidth={false}
    />
  );

  if (props.by_status) {
    by_status = props.by_status;
  }
  extraFilters.push(
    <SelectPromptStatus
      value={by_status}
      onChange={(value) => {
        props.setParams({ by_status: value });
      }}
      dropdownMatchSelectWidth={false}
    />
  );
  extraFilters.push(
    <ResetFilter key={"resetFiltersBtn"} setParams={props.setParams} />
  );

  return extraFilters;
};

PromptsFilter.defaultProps = {};

PromptsFilter.propTypes = {};

export default PromptsFilter;
