import React from "react";

const UnsupportedVersionLayout = props => {

  return <div className="unsupported-version-container">
    {/* Unsupported Layout */}
    {props.children}
  </div>

};
export default UnsupportedVersionLayout;