import React, { useState, useEffect, useCallback, useRef, memo } from "react";
import { MrSelect } from "mr_react_framework";
import { actions as genericActions } from "/src/App/genericRedux";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { Button, Space, Tooltip, TreeSelect } from "antd";
import { forceHideAnnotation, getLabelFromIndexPath } from "/src/lib/utils/helperMethods";
import {
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { actions as topicActions } from "/src/views/Segments/Topics/redux";
import {
  activeSegmentIdSelector,
  segmentsSelector,
} from "/src/views/Segments/selector";
import {
  activeTopicIdSelector,
  topicsSelector,
} from "/src/views/Segments/Topics/selector";
import _ from "lodash";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";

import { loadingSelector } from "/src/views/UserResponses/selector";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { getRecursiveSegments } from "/src/views/Experiences/ExperienceHelperMethods";
import TopicQuestionTitle from "./TopicQuestionTitle";
import UngradedQuestionButton from "./UngradedQuestionButton";
import { overallGradingFormVisibleSelector } from "../selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

export const getTopicsQuestionsAsTreeData = (props) => {
  const { topics, segments, segmentCallback, onlyQNumberInTree } = props
  const treeData = [];
  const indexPath = []
  let newNestedSegmentsInSequence = []

  let sectionStyle = {}
  if (onlyQNumberInTree) {
    sectionStyle = {
      height: "20px"
    }
  }
  topics.forEach((segment, i) => {
    let label = 
      <RenderHtml
        style={sectionStyle} 
        truncate 
        text={segment.section_segment_attributes?.title} 
      />
    const newIndexPath = [...indexPath, i]
    console.log("newIndexPath =====>", newIndexPath, i, topics.length, topics);
    let data = {
      indexPath: newIndexPath,
      value: segment.id,
      title: label,
      disabled: true,
      children: []
    };
    newNestedSegmentsInSequence.push(segment)

    let options = {
      segment_type: "question",
      segments: segments,
    }
    const callback = (data, segment, index, level, indexPath) => {
      let questionType = segment.question_segment_attributes.question_type
      // let qindex = [i, j]
      const newIndexPath = [...indexPath]
      newIndexPath.splice(level + 1)
      newIndexPath[level + 1] = index
      let titleToRender = `${getLabelFromIndexPath(newIndexPath)}`
      // console.log("2 index path ==>", newIndexPath, titleToRender)
      newNestedSegmentsInSequence.push(segment)
      let childrenData = {
        indexPath: newIndexPath,
        value: segment.id,
        title: 
          <TopicQuestionTitle 
            segment={segment} 
            questionType={questionType} 
            level={level}
            titleToRender={titleToRender}
            onlyQNumberInTree={onlyQNumberInTree}
          />,
        topicId: segment.parent_id,
        topicIndex: i,
        children: [],
      }

      if (segmentCallback) {
        segmentCallback(segment)
      }
      // NOTE: Move following to segmentCallback
      // if (segment.id === activeSegmentId) {
      //   dispatch(segmentActions.setActiveSegmentParentIdxsSuccess({ activeSegmentParentIdxs: newIndexPath }))
      // }
      if (questionType == "group") {
        getRecursiveSegments(childrenData, segment, index, level + 1, newIndexPath, options, callback)
      }

      data.children.push(childrenData);
    }

    if (segmentCallback) {
      segmentCallback(segment)
    }
    // NOTE: Move following to segmentCallback
    // if (segment.id === activeSegmentId) {
    //   dispatch(segmentActions.setActiveSegmentParentIdxsSuccess({ activeSegmentParentIdxs: newIndexPath }))
    // }
    getRecursiveSegments(data, segment, i, 0, newIndexPath, options, callback)
    treeData.push(data)
  });

  return { treeData, newNestedSegmentsInSequence }
}

// fetch topics and segments from segments redux? right now going to request state
const NonMemoizedTopicQuestionSwitcher = (props) => {
  console.log("TopicQuestionSwitcher =====>", props);
  const { checkIfLoadingCompleted } = props;

  const dispatch = useDispatch();
  const mrIntl = useTranslate();

  const currentUser = useSelector(currentUserSelector());
  const activeTopicId = useSelector(activeTopicIdSelector());
  const activeSegmentId = useSelector(activeSegmentIdSelector());
  const topics = useSelector(topicsSelector());
  const segments = useSelector(segmentsSelector());
  const activeAdjustedExperience = useSelector(activeAdjustedExperienceSelector())
  const loading = useSelector(loadingSelector())
  const overallGradingFormVisible = useSelector(overallGradingFormVisibleSelector())
  console.log("activeAdjustedExperience ======>", activeTopicId, activeSegmentId);

  const rubricType = activeAdjustedExperience.rubric && activeAdjustedExperience.rubric.type_c
  let questions = segments.filter((s) => s.segment_type === "question");

  const [tempVisible, setTempVisible] = useState(false);
  const [buttonsDisableStatus, setButtonsDisableStatus] = useState({
    nextQuestion: false,
    prevQuestion: false,
    // prevUngradedQuestion: false,
  });
  const [treeData, setTreeData] = useState([])
  const [nestedSegmentsInSequence, setNestedSegmentsInSequence] = useState([])


  console.log(
    "activeTopicId, activeSegmentId ==>",
    activeTopicId,
    activeSegmentId,
    topics,
    segments,
  );

  // const [pressedEvent, setPressedEvent] = useState();
  // const [triggerSwitchQuestion, setTriggerSwitchQuestion] = useState();

  let isStudent =
    currentUser.role === "student" || currentUser.role === "parent"
      ? true
      : false;

  const activeId = activeSegmentId === "all" ? activeTopicId : activeSegmentId;

  // const nestedSegmentsInSequence = []

  useEffect(() => {
    setTempVisible(true)
    setTimeout(() => {
      setTempVisible(false)
    }, 2000);
  }, []);

  useEffect(() => {
    if (!overallGradingFormVisible) {
      document.addEventListener("keydown", onKeyDown, false);

      return () => {
        document.removeEventListener("keydown", onKeyDown, false);
      };
    }
  }, [activeSegmentId, overallGradingFormVisible, treeData.length]);

  useEffect(() => {
    if (activeSegmentId && activeSegmentId !== "all") {
      let index = nestedSegmentsInSequence.findIndex((s) => s.id === activeSegmentId);
      console.log("useEffect index ===>", index, activeSegmentId, nestedSegmentsInSequence);
      if(index >= 0) {
        const currentTopic = findWithSegmentType(index, "prev", "section")
        if (currentTopic && currentTopic.id !== activeTopicId) {
          dispatch(topicActions.setActiveIdSuccess({ id: currentTopic.id }));
        }
      }
    }

    // nestedSegmentsInSequence.length added in dependency array because when click on any question from table then it will not update the active topic id because first time nestedSegmentsInSequence is empty
  }, [activeSegmentId, nestedSegmentsInSequence.length])

  // useEffect(() => {
  //   let activeSegmentIndex = nestedSegmentsInSequence.findIndex((q) => q.id === activeId);
  //   console.log("activeSegmentIndex ====>", activeSegmentIndex, nestedSegmentsInSequence);
  //   setButtonsDisableStatus({
  //     ...buttonsDisableStatus,
  //     nextQuestion: findAttemptableQuestion(activeSegmentIndex + 1, "next") == undefined,
  //     prevQuestion: findAttemptableQuestion(activeSegmentIndex - 1, "prev") == undefined,
  //   });
  // }, [activeId, activeSegmentId, activeTopicId])

  // useEffect(() => {
  //   console.log(
  //     "useEffect triggerSwitchQuestion ==>",
  //     pressedEvent,
  //     triggerSwitchQuestion
  //   );
  //   if (pressedEvent && pressedEvent.shiftKey && pressedEvent.key == "ArrowLeft") {
  //     goToQuestion("previous");
  //   } else if (pressedEvent && pressedEvent.shiftKey && pressedEvent.key === "ArrowRight") {
  //     goToQuestion("next");
  //   } 
  // }, [triggerSwitchQuestion]);

  const onKeyDown = (event) => {
    const target = event.target;
    console.log(
      "on key down event ==>",
      event,
      target,
      target.nodeName,
      target.contentEditable
    );
    // if (
    //   target &&
    //   target.nodeName != "INPUT" &&
    //   target.nodeName != "TEXTAREA" &&
    //   target.contentEditable != "true"
    // ) 
    // {
    // setPressedEvent(event);
    // setTriggerSwitchQuestion(event.timeStamp);
    // }
    if (event && !event.ctrlKey && event.shiftKey && event.key == "ArrowLeft") {
      goToQuestion("prev");
      event.preventDefault();
    } else if (event && !event.ctrlKey && event.shiftKey && event.key === "ArrowRight") {
      goToQuestion("next");
      event.preventDefault();
    }
  };

  const goToCurrentTopic = () => {
    const index = treeData.findIndex((td) => td.value === activeTopicId);
    if(index >= 0) {
      // Set active segment parent idxs for show question number correctly
      dispatch(segmentActions.setActiveSegmentParentIdxsSuccess({ activeSegmentParentIdxs: treeData[index].indexPath }))
    }

    // Not setting topic id because topic id is already set in useEffect when question was selected
    dispatch(segmentActions.setActiveIdSuccess({ id: "all" }));
  }

  const findWithSegmentType = (index, direction, requiredSegmentType) => {
    const segment = nestedSegmentsInSequence[index];
    if (segment) {
      const currentSegmentType = segment.segment_type;
      console.log("question ======>", segment, index, requiredSegmentType, currentSegmentType);
      let newIndex = direction === "next" ? index + 1 : index - 1;
      const questionType = segment.question_segment_attributes && segment.question_segment_attributes.question_type;
      
      // if ((questionType === "group") || (requiredSegmentType === "question" && currentSegmentType !== "question") || (requiredSegmentType === "section" && currentSegmentType !== "section")) {
      if ((questionType !== "group") && (currentSegmentType === requiredSegmentType)) {
        return segment
      } else {
        return findWithSegmentType(newIndex, direction, requiredSegmentType);
      }
    }
  }
  
  // useEffect(() => {
  //   const segment = segments.find((segment) => segment.id == activeSegmentId);
  //   console.log("active topic here==>", segment)
  //   segment && dispatch(topicActions.setActiveIdSuccess({ id: segment.parent_id }))

  // }, [activeSegmentId])

  const goToQuestion = (direction = "next") => {
    checkIfLoadingCompleted(() => {
      let index = nestedSegmentsInSequence.findIndex((q) => q.id === activeId);
      let newIndex = direction === "next" ? index + 1 : index - 1;
      console.log(
        "goToQuestion direction, index, newIndex",
        direction,
        index,
        // nextQuestion,
        questions,
        segments,
        nestedSegmentsInSequence
      );
      const nextQuestion = findWithSegmentType(newIndex, direction, "question")

      if (nextQuestion) {
        dispatch(segmentActions.setActiveIdSuccess({ id: nextQuestion.id }));
        // if (newIndex == 0) {
        //   setDisablePrev(true);
        // } else {
        //   setDisablePrev(false);
        // }

        // if (newIndex == questions.length - 1) {
        //   setDisableNext(true);
        // } else {
        //   setDisableNext(false);
        // }
        // Doing this because when we close the modal then the text annotation popup don't close sometime
        forceHideAnnotation()
      }
    });
  };

  // let ungradedURs = {}
  // let gradedURs = {}
  // let ungradedURsLength = 0;

  // const treeData = [];

  useEffect(() => {
    const treeDataToPush = [];
    const indexPath = []
    let newNestedSegmentsInSequence = []
    topics.forEach((segment, i) => {
      let label = <RenderHtml  truncate text={segment.section_segment_attributes && segment.section_segment_attributes.title} />
      const newIndexPath = [...indexPath, i]
      console.log("newIndexPath =====>", newIndexPath, i, topics.length, topics);
      let data = {
        indexPath: newIndexPath,
        value: segment.id,
        title: label,
        children: []
      };
      newNestedSegmentsInSequence.push(segment)

      let options = {
        segment_type: "question",
        segments: segments,
      }
      const callback = (data, segment, index, level, indexPath) => {
        let questionType = segment.question_segment_attributes.question_type
        // let qindex = [i, j]
        const newIndexPath = [...indexPath]
        newIndexPath.splice(level + 1)
        newIndexPath[level + 1] = index
        let titleToRender = `${getLabelFromIndexPath(newIndexPath)}`
        // console.log("2 index path ==>", newIndexPath, titleToRender)
        newNestedSegmentsInSequence.push(segment)
        let childrenData = {
          indexPath: newIndexPath,
          value: segment.id,
          title: <TopicQuestionTitle segment={segment} questionType={questionType} level={level} titleToRender={titleToRender} />,
          children: [],
        }
        if (segment.id === activeSegmentId) {
          console.log("segment.id ====>", segment.id, activeSegmentId, newIndexPath, getLabelFromIndexPath(newIndexPath));
          dispatch(segmentActions.setActiveSegmentParentIdxsSuccess({ activeSegmentParentIdxs: newIndexPath }))
        }
        if (questionType == "group") {
          getRecursiveSegments(childrenData, segment, index, level + 1, newIndexPath, options, callback)
        }

        data.children.push(childrenData);
      }
      if (segment.id === activeSegmentId) {
        console.log("segment.id ====>", segment.id, activeSegmentId, newIndexPath, getLabelFromIndexPath(newIndexPath));
        dispatch(segmentActions.setActiveSegmentParentIdxsSuccess({ activeSegmentParentIdxs: newIndexPath }))
      }
      getRecursiveSegments(data, segment, i, 0, newIndexPath, options, callback)
      treeDataToPush.push(data)
    });
    setTreeData(treeDataToPush);
    setNestedSegmentsInSequence(newNestedSegmentsInSequence)
  }, [])

  useEffect(() => {
    let activeSegmentIndex = nestedSegmentsInSequence.findIndex((s) => s.id === activeId);
    console.log("activeSegmentIndex ====>", activeSegmentIndex, nestedSegmentsInSequence, activeId);
    setButtonsDisableStatus({
      ...buttonsDisableStatus,
      nextQuestion: findWithSegmentType(activeSegmentIndex + 1, "next", "question") == undefined,
      prevQuestion: findWithSegmentType(activeSegmentIndex - 1, "prev", "question") == undefined,
    });
  }, [nestedSegmentsInSequence.length, activeId])


  // activeAdjustedExperience.topics.forEach((topic, i) => {


  let topicQuestionSwitcher = (
    <TreeSelect
      // showSearch
      className={"topic-question-switcher-select"}

      value={activeSegmentId == "all" ? activeTopicId : activeSegmentId}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      placeholder={mrIntl("TopicQuestionSwitcher.please_select")}
      // bordered={false}
      getPopupContainer={(triggerNode) => {
        const parentContainer = document.querySelector(".topic-question-switcher-wraper")
        // container.parentNode
        return parentContainer;
      }}
      // allowClear
      treeDefaultExpandAll
      dropdownMatchSelectWidth={false}
      onChange={(value, label, extra) => {
        const segment = segments.find((segment) => segment.id == value);
        console.log("value, label, extra, segment ==>", value, label, extra, segment, extra.triggerNode)
        const newParentIdx = (extra.triggerNode && extra.triggerNode.props.indexPath) || []

        if (segment) {
          dispatch(segmentActions.setActiveIdSuccess({ id: value }));
          const index = questions.findIndex((q) => q.id === value);
          setButtonsDisableStatus({
            prevQuestion: index === 0,
            nextQuestion: index === questions.length - 1,
            // nextUngradedQuestion: ungradedURsLength === 0
          });
        } else {
          console.log("topicActions.setActiveIdSuccess", value);
          dispatch(topicActions.setActiveIdSuccess({ id: value }));
          dispatch(segmentActions.setActiveIdSuccess({ id: "all" }));
          setButtonsDisableStatus({
            prevQuestion: true,
            nextQuestion: false,
            // nextUngradedQuestion: ungradedURsLength === 0
          });
        }
        // parentIdx.pop()
        dispatch(segmentActions.setActiveSegmentParentIdxsSuccess({ activeSegmentParentIdxs: newParentIdx }))

        // Remove focus from experience user switcher. Doing this because when we switch the question and EU with arrow keys then switcher automatically open.
        document.activeElement.blur();
      }}
      treeData={treeData}
    />
  );

  console.log("treed data before render ==>", treeData)
  return (
    <div className={"topic-question-switcher-wraper"}>
      <Space>
        {/* <Tooltip title="Previous ungraded question">
          <Button
            disabled={buttonsDisableStatus.prevUngradedQuestion}
            onClick={() => goToUngraded("prev")}
          >
            <DoubleLeftOutlined />
          </Button>
        </Tooltip> */}
        <Tooltip title={mrIntl("TopicQuestionSwitcher.previous_question")}>
          <Button
            // loading={buttonsDisableStatus.prevQuestion ? false : loading }
            disabled={buttonsDisableStatus.prevQuestion || loading}
            shape={"circle"}
            onClick={() => goToQuestion("prev")}
          >
            <LeftOutlined />
          </Button>
        </Tooltip>

        <Tooltip
          title={mrIntl("TopicQuestionSwitcher.select_section_or_question")}
          // placement="left"
          open={tempVisible}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          {topicQuestionSwitcher}
        </Tooltip>

        <Tooltip title={mrIntl("TopicQuestionSwitcher.next_question")}>
          <Button
            // loading={buttonsDisableStatus.nextQuestion ? false : loading }
            disabled={buttonsDisableStatus.nextQuestion || loading}
            type="primary"
            shape={"circle"}
            onClick={() => goToQuestion("next")}
            className="next-question"
          >
            <RightOutlined />
          </Button>
        </Tooltip>
        {!isStudent && <UngradedQuestionButton questions={questions} nestedSegmentsInSequence={nestedSegmentsInSequence} />}
        {activeSegmentId !== "all" &&
          <Button
            type="primary"
            size="small"
            onClick={() => goToCurrentTopic()}
            // disabled={activeSegmentId === "all"}
          >
            View Section
          </Button>
        }
      </Space>
    </div>
  );
};

const TopicQuestionSwitcher = memo(NonMemoizedTopicQuestionSwitcher)
export default TopicQuestionSwitcher;