import React, { useEffect, useState } from "react";
import { Row, Checkbox, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { actions as orgActions } from "/src/views/Orgs/redux";
import {
  boardsProgrammesSubjectsGradesLoadingSelector,
  boardsProgrammesSubjectsGradesSelector,
} from "/src/views/Orgs/selector";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { currentUserSelector } from "/src/views/Auth/Login/selector";

const buildObjForForm = (
  boardsProgramsSubjectsGrades,
  subjects = [],
  programmeId
) => {
  // Todo: find program from programmeId and use programme.subjects for rendering all subjects, combine with subjects
  let staticSubjects = [];
  let enabledSubject = null;
  let programmes = [];
  boardsProgramsSubjectsGrades.map((value, i) => {
    value.programmes.data.map((p, j) => {
      programmes.push(p);
    });
  });

  console.log("programmes", programmes);
  let program = programmes.find((p) => {
    return p.attributes.id === programmeId;
  });
  console.log("program", program);
  if (program && program.attributes.programme_subjects_attributes) {
    // staticSubjects = JSON.parse(program.subjects)
    staticSubjects = program.attributes.programme_subjects_attributes;
    // can be subjects or groups
    console.log("staticSubjects", staticSubjects);
    if (staticSubjects.length !== 0) {
      staticSubjects.map((staticSubject, i) => {
        console.log("staticSubjects subject", staticSubject);

        if (staticSubject.type_c === "group") {
          // let staticSubjectChildren = staticSubject.children
          let staticSubjectChildren = staticSubjects.filter((ss) => {
            return ss.parent_id === staticSubject.id;
          });
          let subjectGroup = subjects.find((s) => {
            console.log("looking for sg", s, staticSubject);
            // return s.programme_subject_id === ss.id
            return (
              (s.name && s.name.toLowerCase())
              === (staticSubject.name && staticSubject.name.toLowerCase())
              && s.type_c === staticSubject.type_c
            );
          });
          console.log("looking for sg", subjectGroup);
          console.log("staticSubjectChildren", staticSubjectChildren);
          if (staticSubjectChildren.length !== 0) {
            staticSubjectChildren.map((ss, j) => {
              console.log("staticSubjectChildren subject", ss);
              if (subjects.length !== 0) {
                // edit case
                enabledSubject = subjects.find((s) => {
                  // return s.programme_subject_id === ss.id
                  return (
                    (s.name && s.name.toLowerCase())
                    === (ss.name && ss.name.toLowerCase())
                    &&
                    s.type_c === ss.type_c &&
                    ((subjectGroup && s.parent_id === subjectGroup.id) ||
                      (s.parent_name && s.parent_name.toLowerCase())
                      === (staticSubject.name && staticSubject.name.toLowerCase())
                      )
                  );
                });
                console.log("enabledSubject", enabledSubject);
                if (enabledSubject) {
                  ss.enabled = !enabledSubject.archived; // set from subjects in case of edit
                  console.log("setting", ss);
                } else ss.enabled = false;
              } else ss.enabled = false;
            });
          }
        }
        // we always have subject groups for now - below is wrong anyway
        // else{
        //   if(subjects.length !== 0){
        //     // edit case
        //     enabledSubject = subjects.find(s => {
        //       // return s.programme_subject_id === staticSubject.id
        //       return s.name === staticSubject.name && s.type_c === staticSubject.type_c
        //     })
        //     console.log("enabledSubject", enabledSubject);
        //     if(enabledSubject){
        //       staticSubject.enabled = !enabledSubject.archived; // set from subjects in case of edit
        //       console.log("setting", staticSubject);
        //     }
        //     else
        //       staticSubject.enabled = false;
        //   }
        //   else
        //     staticSubject.enabled = false;

        // }
      });
    }
  }
  console.log("staticSubjects", staticSubjects);
  return staticSubjects;
};

const SubjectSelector = (props) => {
  // const {value, onChange} = props;
  // const value = props.componentProps.value;
  // const onChange = props.componentProps.onChange;
  const currentUser = useSelector(currentUserSelector());
  const value = props.value;
  const onChange = props.onChange;
  const programmeId = props.programmeId;
  const dispatch = useDispatch();
  // const isMB = currentUser.org.is_mb
  const isMB = props.isMB;
  const isEdlink = currentUser.org.is_edlink;

  console.log("SubjectSelector props", props);
  console.log("SubjectSelector programmeId", programmeId);
  console.log("SubjectSelector value", value);
  // value will be set by MrFormComponent automatically, in case of default value and edit form

  const boardsProgramsSubjectsGrades = useSelector(
    boardsProgrammesSubjectsGradesSelector()
  );
  const boardsProgrammesSubjectsGradesLoading = useSelector(
    boardsProgrammesSubjectsGradesLoadingSelector()
  );
  // const boardsProgramsSubjectsGrades = useSelector(state => {
  //   return state.login.get("currentUser").boards_programs_subjects_grades
  // })
  console.log("boardsProgramsSubjectsGrades", boardsProgramsSubjectsGrades);

  let subjects = value;
  console.log("subjects value", subjects);

  const [values, setValues] = useState([]);

  // const orgRoles = JSON.parse(values);

  console.log("props program year selector", values);

  const buildAndSetForm = (boardsProgramsSubjectsGrades) => {
    console.log(
      "boardsProgramsSubjectsGrades success callback",
      boardsProgramsSubjectsGrades
    );
    let allSubjectsWithSelected = buildObjForForm(
      boardsProgramsSubjectsGrades,
      subjects,
      programmeId
    );
    setValues(allSubjectsWithSelected);
  };

  useEffect(() => {
    console.log("boardsProgramsSubjectsGrades on mount");
    if (!boardsProgramsSubjectsGrades) {
      console.log("boardsProgramsSubjectsGrades on mount fetch");
      dispatch(
        orgActions.boardsProgrammesSubjectsGrades(
          {with_programme_subjects_attributes_for_static: false},
          {
            successCallback: buildAndSetForm,
          }
        )
      );
    } else {
      // tab switch - programmeId and subjects change but we have the static data so just calling the callbackfn
      buildAndSetForm(boardsProgramsSubjectsGrades);
    }
  }, []);

  // useEffect(() => {
  //   console.log("boardsProgramsSubjectsGrades changed", boardsProgramsSubjectsGrades);
  //   let allSubjectsWithSelected = []
  //   if(boardsProgramsSubjectsGrades){
  //     allSubjectsWithSelected = buildObjForForm(boardsProgramsSubjectsGrades, subjects, programmeId)
  //     setValues(allSubjectsWithSelected)
  //   }
  // }, [boardsProgramsSubjectsGrades]);

  const getNewSubjectObj = (s, parentName = null) => {
    console.log("s", s);
    return {
      name: s.name,
      code: s.code,
      label: s.name,
      programme_subject_id: s.id,
      parent_name: parentName, //using parent_name for new objs added for which sg doesn't exist yet
      // parent_id: parent_id, // saving on BE
      type_c: s.type_c, // only subject type is activated here - group if not exists is created on BE and parent_id assigned
      archived: false,
    };
  };

  const customOnChange = (i, j, s, sc, val, id) => {
    console.log("onchange roles form role", i);
    console.log("onchange roles form resource", j);
    console.log("onchange roles form subject id", id);
    console.log("onchange roles form p", s);
    console.log("onchange roles form g", sc);
    console.log("onchange roles form val", val);
    console.log("onchange roles form values", values);

    let newValues = [...values];
    // if (j === -1) {
    //   newValues[i] = { ...newValues[i], enabled: val };
    // } else {
    //   newValues[i].tempChildren[j] = {
    //     ...newValues[i].tempChildren[j],
    //     enabled: val,
    //   };
    // }
    let valueToChangeIdx = newValues.findIndex((value) => value.id === id);
    console.log("onchange roles form valueToChangeIdx", valueToChangeIdx);

    if (valueToChangeIdx > -1) {
      newValues[valueToChangeIdx].enabled = val;
    }

    setValues(newValues);
    // setValues(values);
    console.log("onchange roles form after set values", values);

    console.log("subjects before change", subjects);

    let sExists = null;
    if (subjects === undefined) subjects = [];
    // console.log("sExists", sExists)

    let subjectGroup = subjects.find((ss) => {
      // s is programmeSubject group
      return (ss.name && ss.name.toLowerCase())
      === (s.name && s.name.toLowerCase())
      && ss.type_c === s.type_c;
    });

    if (s && val === true && sc === -1) {
      // enabling subject
      sExists = subjects.find((ss) => {
        // return ss.programme_subject_id === s.id
        return (ss.name && ss.name.toLowerCase())
        === (s.name && s.name.toLowerCase())
        && ss.type_c === s.type_c;
      });
      if (sExists) {
        sExists.archived = false;
      } else subjects.push(getNewSubjectObj(s)); //add subject to subjects arr
    }
    if (s && val === false && sc === -1) {
      // disabling subject
      sExists = subjects.find((ss) => {
        // return ss.programme_subject_id === s.id
        return (ss.name && ss.name.toLowerCase())
        === (s.name && s.name.toLowerCase())
        && ss.type_c === s.type_c;
      });
      if (sExists) {
        sExists.archived = true;
      }
      // if id present, change archive, otherwise remove entry from array
    }
    if (s && val === true && sc !== -1) {
      // enabling subject child
      sExists = subjects.find((ss) => {
        // return ss.programme_subject_id === sc.id
        return (
          (ss.name && ss.name.toLowerCase())
          === (sc.name && sc.name.toLowerCase())
          &&
          ss.type_c === sc.type_c &&
          ((subjectGroup && ss.parent_id === subjectGroup.id) ||
            (s.name && s.name.toLowerCase())
            == (ss.parent_name && ss.parent_name.toLowerCase())
            )
        );
      });
      console.log("enabled sExists", sExists);
      if (sExists) {
        sExists.archived = false;
      } else subjects.push(getNewSubjectObj(sc, s.name)); //add subject to subjects arr
    }
    if (s && val === false && sc !== -1) {
      // disabling subject child
      sExists = subjects.find((ss) => {
        // return ss.programme_subject_id === sc.id
        return (
          (ss.name && ss.name.toLowerCase())
          === (sc.name && sc.name.toLowerCase())
          &&
          ss.type_c === sc.type_c &&
          ((subjectGroup && ss.parent_id === subjectGroup.id) ||
            (s.name && s.name.toLowerCase())
            == (ss.parent_name && ss.parent_name.toLowerCase())
            )
        );
      });
      console.log("disabled sExists", sExists);
      if (sExists) {
        sExists.archived = true;
      }
      // if id present, change archive, otherwise remove entry from array
    }

    console.log("subjects on change", subjects);

    onChange(subjects);

    // // setValues({...values, [key]: val});
    // // onChange({...values, [key]: val})
  };

  let finalRender = [];
  let toRender = [];
  if (boardsProgrammesSubjectsGradesLoading) {
    toRender.push(<Spinner />);
  }
  if (boardsProgramsSubjectsGrades) {
    if (values) {
      values.map((subject, i) => {
        if (subject.type_c === "group" && subject.archived === false) {
          toRender.push(
            <Col span={24} key={`sg_${i}`}>
              <h4 className="subjectspecific change-group-name m-t-25">
                {subject.name}
              </h4>
            </Col>
          );
          let subjectChildren = values.filter(
            (child) => child.parent_id === subject.id
          );
          if (subjectChildren) {
            subjectChildren.map((s, j) => {
              if(!s.archived){
                toRender.push(
                  <Col span={6} key={`sc_${i}_${j}`}>
                    <span className="subject-name">
                      <Checkbox
                        disabled={isMB || isEdlink}
                        checked={s.enabled}
                        onChange={(event) =>
                          customOnChange(
                            i,
                            j,
                            subject,
                            s,
                            event.target.checked,
                            s.id
                          )
                        }
                      />{" "}
                      {s.name}
                      {/* {s.enabled.toString()} */}
                    </span>
                  </Col>
                );
              }
            });
          }
        }
        // else if(!subject.parent_id && subject.type_c == "subject"){
        //   toRender.push(<span className="subject-name"  key={`s_${i}`}><Checkbox disabled={isMB} checked={subject.enabled} onChange={(event) => customOnChange(i, -1, subject, -1,  event.target.checked)}/> {subject.name}</span>)
        // }
      });
    }
  }

  finalRender.push(
    <React.Fragment key="subjects">
      {/* <h4>{subject.name} ({subject.type})</h4> */}
      {/* {JSON.stringify(subject.children)} */}
      <Row>{toRender}</Row>
    </React.Fragment>
  );

  return finalRender;

  // return <React.Fragment>
  //     <Input value={values.key1} onChange={(event) => customOnChange("key1", event.target.value)}/>
  //     <Input value={values.key2} onChange={(event) => customOnChange("key1", event.target.value)}/>
  // </React.Fragment>;
};
SubjectSelector.defaultProps = {};
SubjectSelector.propTypes = {};
export default SubjectSelector;
