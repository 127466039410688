import React, { useEffect, useState } from "react";
import { Row, Col, Drawer, Tag, Space, Badge, Alert, Modal, Table, Button, Radio } from "antd";
// import { SettingOutlined } from "@ant-design/icons";
import { Route, useRouteMatch } from "react-router-dom";
import LibrarySegments from "/src/views/Segments/LibrarySegments/LibrarySegments";
import { checkMob } from "/src/lib/utils/helperMethods";
import SegmentList from "/src/views/Segments/SegmentList/SegmentList";
import { useSelector } from "react-redux";
import { activeTopicIdSelector } from "/src/views/Segments/Topics/selector";
import { topicsSelector } from "/src/views/Segments/Topics/selector";
import { currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector } from "/src/views/Auth/Login/selector";
import { orgquestionbanksSelector } from "/src/views/Questionbanks/selector";
import CustomSegmentsCrudList from "/src/views/Experiences/CustomSegmentsCrudListLayout";
import MrTranslate, {useTranslate} from "/src/lib/MrTranslate/MrTranslate";
import { getContentAndQuestionOptions } from "/src/views/Segments/SegmentsFilter/SegmentsFilter";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";

const LibraryModal = (props) => {

  const { experience, libraryModalProps } = props
  const { libraryModalConfig, setLibraryModalConfig } = libraryModalProps
  const { path, match } = useRouteMatch();
  const isMobile = checkMob();
  const activeTopicId = useSelector(activeTopicIdSelector());
  const topics = useSelector(topicsSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const orgQBs = useSelector(orgquestionbanksSelector())
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const customFields = activeExperience.custom_fields || {}

  const bySectionShow = (import.meta.env.VITE_MODE !== "production" || ((customFields.org_programme_item_label === "IB Middle Years") || (activeExperience && activeExperience.original_questionbank && (activeExperience.original_questionbank.code === "ap_myp_standard" || activeExperience.original_questionbank.code === "ap_myp"))))
  ? true : false

  const [bySectionWise, setBySectionWise] = useState(bySectionShow)
  let [activeTopicIdx, setActiveTopicIdx] = useState(0)
  const mrIntl = useTranslate();
  // let parentIdxs = []
  useEffect(() => {
    if (activeTopicId) {
      let idx = topics.findIndex((topic) => topic.id == activeTopicId)
      // parentIdxs.push(idx)
      setActiveTopicIdx(idx)
    }
  }, [activeTopicId])
  console.log("LibraryModal props", libraryModalConfig.modalVisible);


  // const [isModalOpen, setIsModalOpen] = useState(libraryModalConfig.modalVisible);
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  console.log("LibraryModal path", path, match);

  const contentAndQuestionOptions = getContentAndQuestionOptions()
  const defaultItemTypes = [];
  contentAndQuestionOptions.forEach(option => {
    if (option.value === "question") {
      option.children.forEach(child => {
        defaultItemTypes.push(child.value);
      });
      if(customFields.org_programme_item_label === "IB Diploma")
      defaultItemTypes.push("group")
    }
  });
  
  let params = { is_library: true, by_original: true, is_library_modal: true, with_experience_data: true, page_size: 10};
  // TEMP DISABLE for QB CHECK
  // params.by_experience_rubric_id = experience.rubric_id;
  // !TEMP
  if(bySectionWise) {
    // params.by_segment_type = "section"
    params.page_size = 1
  } else {
    params.by_item_types = defaultItemTypes
  }

  // if(experience.rubric.type_c !== "myp_achievement_level"){
  //   // removing filter by rubric_id for MYP Achievement Level so that we see CWP questions too
  //   params.by_experience_rubric_id = experience.rubric_id;
  // }

  // params.by_experience_rubric_id = experience.rubric_id;
  // params.by_item_types = defaultItemTypes


  if (experience && experience.relation_items && experience.relation_items[0]) {
    if (experience.relation_items[0].subject_id) {
      params.by_experience_subject_id = experience.relation_items[0].subject_id;
    }
    // DP questions valid for both Grade 11 and 12 so not applying grade filter if exp is for DP
    if (experience.relation_items[0].grade_id && experience.custom_fields.org_programme_item_label !== "IB Diploma") {
      params.by_experience_grade_id = experience.relation_items[0].grade_id;
    }
  }

  // TODO: experience.relation_items[0].programme_subject_id and experience.relation_items[0].programme_grade_id need to be sent to SegmentFilters and if they're available then SyllabusContents automatically works. Or even sending subject_id and then changing syll conten BE filter would work. But need to send something

  
  const getLibraryDrawerTitle = (subjectLabel) => {
    let finalRender = []
    // finalRender.push(`Add from Library - ${subjectLabel}`)
    finalRender.push(`${ mrIntl("LibraryModal.add_from_library") } - ${subjectLabel}`)
    return <Space>{finalRender}</Space>;
  }

  // const handleOk = (e) => {
  //   libraryModalProps.setLibraryModalVisible(false);
  // };

  const handleCancel = (e) => {
    // libraryModalProps.setLibraryModalVisible(false);
    setLibraryModalConfig((libraryModalConfig) => {
      return {
        ...libraryModalConfig,
        modalVisible: false
      };
    });
  };

  const DPBankAnnouncementAlert = (props) => {
    let finalRender = []
    const orgQBs = useSelector(orgquestionbanksSelector())
    const currentUser = useSelector(currentUserSelector())
    const embedded = useSelector(getEmbeddedSelector())
    const [visible, setVisible] = useState(false);

    let isDPBundle = (currentUser.org.org_subscriptions_attributes && currentUser.org.org_subscriptions_attributes.filter(os => os.code == "AP-DP" && os.archived == false).length > 0) ? true : false
    let isDPBankEnabled = orgQBs && orgQBs.filter(oqb => oqb.custom_fields.title.indexOf("DP") > -1).length > 0 ? true : false
    console.log("isDPBankEnabled", orgQBs, isDPBankEnabled);

    const columns = [
      {
        title: mrIntl("CommonText.subjects"),
        dataIndex: 'subject',
        key: 'subject',
        // render: (text) => <a>{text}</a>,
      },
      {
        title: mrIntl("LibraryModal.assessment_count"),
        dataIndex: 'count',
        key: 'count',
        align: 'right'
      },
    ]
    const ib_dp_data = [
      {
        key: '1',
        subject: 'Maths A&A',
        count: 4,
      },
      {
        key: '2',
        subject: 'Maths A&I',
        count: 4,
      },
      {
        key: '3',
        subject: 'Biology',
        count: 4,
      },
      {
        key: '4',
        subject: 'Chemistry',
        count: 4,
      },
      {
        key: '5',
        subject: 'Physics',
        count: 8,
      },
      {
        key: '6',
        subject: 'I&S',
        count: "Coming soon",
      },
    ]
    const osc_dp_data = [
      {
        key: '1',
        subject: 'Maths A&A',
        count: 10,
      },
      {
        key: '2',
        subject: 'Maths A&I',
        count: 10,
      },
    ]
    const osc_dp_mocks_data = [
      {
        key: '1',
        subject: 'Maths A&A',
        count: 5,
      },
      {
        key: '2',
        subject: 'Maths A&I',
        count: 5,
      },
    ]
    
    let subjectInfoModal = <Modal
      title={mrIntl("LibraryModal.available_subjects")}
      open={visible}
      footer={null}
      // onOk={handleOk}
      onCancel={() => setVisible(false)}
      width={500}
      wrapClassName="height-centered-modal"
      closable={true}
      maskClosable={true}
      keyboard={true}
      bodyStyle={{overflowY: "auto"}}
    >
      <Row>
        <Space direction="vertical">
          {/* {orgQBs && orgQBs.map((oqb, i) => {
            if(oqb.custom_fields.title.indexOf("DP") > -1){
              
              return 
            }
          })} */}
          {isDPBundle && <span>
            <h4><b><MrTranslate id={"LibraryModal.ib_dp"}/></b></h4>
            <Table size={"small"} pagination={false} columns={columns} dataSource={ib_dp_data} />
            <br></br>
          </span>}
          <span>
            <h4><b><MrTranslate id={"LibraryModal.osc_dp"}/></b></h4>
            <Table size={"small"} pagination={false} columns={columns} dataSource={osc_dp_data} />
            <br></br>
          </span>
          <span>
            <h4><b><MrTranslate id={"LibraryModal.osc_dp_mocks"}/></b></h4>
            <Table size={"small"} pagination={false} columns={columns} dataSource={osc_dp_mocks_data} />
          </span>
        </Space>
      </Row>
    </Modal>

    // if(!embedded && isDPBankEnabled && (currentUser.role === "admin" || currentUser.role === "teacher")){
    //   finalRender.push(<Row>
    //     <div style={{marginLeft: "10px", width: "98%"}}>
    //       <Badge.Ribbon text= {mrIntl("LibraryModal.badge_ribbon_new")} color="red" placement="start">
    //         <Alert message={mrIntl("LibraryModal.select_a_dp_questionbank_to_view_dp_questions")} action={<Button size="small" type={"primary"} onClick={() => setVisible(true)}>{mrIntl("LibraryModal.see_available_subjects")}</Button>} className="m-b-15" style={{paddingLeft: "50px"}}/>
    //       </Badge.Ribbon>
    //     </div>
    //   </Row>)
    //   finalRender.push(subjectInfoModal)
    // }

    return finalRender;
  }
  const tabRadioOptions = [
    { label: "Section", value: true },
    { label: "Question", value: false }
  ];

  let libraryModal = (
    <Modal
      className="library-modal full-screen-modal"
      title={getLibraryDrawerTitle(experience.custom_fields.subject_item_label)}
      // placement="top"
      closable={true}
      onClose={handleCancel}
      // open={libraryModalProps.libraryModalVisible}
      open={libraryModalConfig.modalVisible} // TODO: TEMP
      // open={true}
      mask={true}
      // width={isMobile ? "100%" : "70%"}
      // height={"10%"}
      onCancel={handleCancel}
      footer={null}
    >
     <DPBankAnnouncementAlert />
     {bySectionShow && <Row>
      <Col span={24} className="library-tabs">
        <Radio.Group
          options={tabRadioOptions}
          onChange={(e) => {
            setBySectionWise(e.target.value)
          }}
          value={bySectionWise}
          optionType="button"
          buttonStyle="solid"
        />
      </Col>
     </Row>}
      <Row>
        {/* Load Segments component here and LibraryFilters */}
        <Col span={24} className="segments-list">
          <Route
            path={`${path}/topics/:topicsId`}
            render={(routeProps) => (
              <LibrarySegments
                experienceViewMode="apEditTest"
                isLibrary={true}
                isLibraryModal={true}
                libraryModalProps={libraryModalProps}
                isRoute={false}
                showNavigation={bySectionWise}
                // parentId={} // No parentId here so filtering based on nestingLevel 1 - see librarySegmentsSelector 
                activeTopicIdx={0}
                parentIdxs={[]}
                bySectionWise={bySectionWise}
                nestingLevel={1}
                {...routeProps}
                params={params}
                config={{
                  filter: {
                    show: true,
                    style: {
                      width: 6,
                      align: "right"
                    },
                    config: {
                      create: { show: false },
                      search: { show: false },
                    },
                  },
                  core: {
                    setQueryParams: false, // not setting query params in the url
                  },
                  pagination: { show: true },
                  // list: { forceReload: false, widget: SegmentList },
                  list: { forceReload: true, widget: SegmentList, layout: CustomSegmentsCrudList },
                   // TODO: not working here wihtout explicitly mentioning widget - something strange in framework - overriding default list widget with undefined even just if LibrarySegments is imported
                }}
              />
            )}
          />
        </Col>
      </Row>
    </Modal>
  );
  // let libraryDrawer = (
  //   <Drawer
  //     title={getLibraryDrawerTitle(experience.custom_fields.subject_item_label)}
  //     // placement="top"
  //     closable={true}
  //     onClose={handleCancel}
  //     // visible={libraryModalProps.libraryModalVisible}
  //     visible={libraryModalConfig.modalVisible}
  //     mask={true}
  //     width={isMobile ? "100%" : "70%"}
  //     // height={"10%"}
  //   >
  //     <DPBankAnnouncementAlert />
  //     <Row>
  //       {/* Load Segments component here and LibraryFilters */}
  //       <Col span={24} className="segments-list">
  //         <Route
  //           path={`${path}/topics/:topicsId`}
  //           render={(routeProps) => (
  //             <LibrarySegments
  //               experienceViewMode="apEditTest"
  //               isLibrary={true}
  //               isLibraryModal={true}
  //               libraryModalProps={libraryModalProps}
  //               isRoute={false}
  //               // parentId={} // No parentId here so filtering based on nestingLevel 1 - see librarySegmentsSelector 
  //               activeTopicIdx={activeTopicIdx}
  //               nestingLevel={1}
  //               {...routeProps}
  //               params={params}
  //               config={{
  //                 filter: {
  //                   show: true,
  //                   config: {
  //                     create: { show: false },
  //                     search: { show: true },
  //                   },
  //                 },
  //                 core: {
  //                   setQueryParams: false, // not setting query params in the url
  //                 },
  //                 pagination: { show: true },
  //                 list: { forceReload: true, widget: SegmentList }, // TODO: not working here wihtout explicitly mentioning widget - something strange in framework - overriding default list widget with undefined even just if LibrarySegments is imported
  //               }}
  //             />
  //           )}
  //         />
  //       </Col>
  //     </Row>
  //   </Drawer>
  // );

  // return <>{libraryDrawer}</>;
  return <>{libraryModal}</>;
};

export default LibraryModal;
