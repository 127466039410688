import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MrSelect, MrTreeSelect } from "mr_react_framework";
import {actions as genericActions} from "/src/App/genericRedux";
import {Route, useRouteMatch} from "react-router-dom";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import {Input, Drawer, Button, Space, Checkbox, Select} from "antd";
import {
  FilterOutlined,
} from "@ant-design/icons";
import {SelectProgrammeSubject, SelectProgrammeGrade, SelectTreeSyllabusContents, SelectPaperType, SelectTeachingLevel, SelectCommandTerm, SelectSubject as SelectOrgSubject, SelectAssessmentObjective,} from "/src/components/AppSpecific/SelectSubject/SelectSubject"
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { useDispatch, useSelector } from "react-redux";
import { orgquestionbanksSelector } from "/src/views/Questionbanks/selector";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import _, { filter, get, isEmpty } from "lodash";
import { checkMob } from "/src/lib/utils/helperMethods";
import { SelectTeachers } from "/src/views/Experiences/ExperiencesFilter/ExperiencesFilter";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { actions as orgActions } from "../../Orgs/redux";
import { getCurrentPlanStatus } from "/src/lib/utils/helperMethods";
const { Option, OptGroup } = Select

const SelectQuestionbank = MrSelect({
  actions: genericActions,
  resourceName: "orgquestionbanks",
  apiResourceName: "org_questionbanks",
  // resourceName: "questionbanks",
  config: {
    url: "/org_questionbanks",
    // params: { key: "value" },
    processData: (data, props) => {
      console.log("SelectQuestionbank processData", data, props);
      const options = []
      data.map((item) => {
        let resourceCountRender = item.resource_count_from_qps_custom_fields ? `(${item.resource_count_from_qps_custom_fields.segments.total_published})` : ""
        // let title = `${item.custom_fields.title} ${resourceCountRender}`
        let title = item.custom_fields.title

        options.push({ label: title, value: item.questionbank_id });
      });
      options.unshift({ label: "School library", value: null})
      
      return options
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: <MrTranslate id={"SegmentsFilter.select_questionbank"}/>,
      // placeholder: "Select questionbank",
      allowClear: true,
      // mode: "multiple",
      style: { width: "100%" },
    },
  },
});

const SelectExperience = MrSelect({
  actions: genericActions,
  resourceName: "experiences",
  apiResourceName: "experiences",
  config: {
    url: "/experiences",
    processData: (data, props) => {
      console.log("SelectExperience processData", data, props);
      const options = []
      data.length && data.map((item) => {
        let title = `${item.access_code} - ${item.name}`
        options.push({ label: title, value: item.id });
      });
      
      return options
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      allowClear: true,
      style: { width: "100%" },
    },
  },
});

const SelectCriteria = MrSelect({
  actions: genericActions,
  // resourceName: "criteria",
  // apiResourceName: "criteria",
  // resourceName: "questionbanks",
  config: {
    // url: "/criteria",
    // params: { key: "value" },
    processData: (data, props) => {
      console.log("data rubric type====>",data);
      return data.map((item) => {
        return { label: item.title, value: item.id };
      });
    },
    widgetConfig: {
      filterOption: true,
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: "Select criteria",
      allowClear: true,
      mode: "multiple",
      style: { width: "100%" },
    },
  },
});

const SelectQuestionType = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.label, value: item.value};
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        {label: "Long answer", value: "cke_subjective"},
        {label: "MCQ", value: "mcq_single"},
        {label: "MCQ multiple", value: "mcq_multiple"},
        {label: "True/False", value: "true_false"},
        {label: "Fill Text", value: "cke_fill_text"},
        {label: "Fill Dropdown", value: "cke_fill_dropdown"},
        {label: "Match", value: "rbd_inline_matching"},
        {label: "Sort", value: "rbd_inline_sorting"},
        {label: "Classify", value: "rbd_inline_classify"},
        {label: "Table", value: "rds_table"},
        {label: "Drawing", value: "fabric_drawing"},
        {label: "Label Drag", value: "fabric_dnd_label"},
        {label: "Label Fill", value: "fabric_fill_label"},
        {label: "Hotspot", value: "fabric_hotspot"},
        {label: "Geogebra Graph", value: "ggb_graph"},
        {label: "Desmos Graph", value: "desmos_graph"},
        {label: "Audio", value: "audio_input"},
        {label: "Video", value: "video_input"},
        {label: "Mini quiz", value: "mini_quiz"},
        {label: "Connect", value: "connect"},
        {label: "Flash Card", value: "flash_card"},
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Question type",
      allowClear: true,
      // mode: "multiple",
      style: {width: "100px"},
      // style: { width: "100%" },
    },

  }
})

const SelectContentType = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.label, value: item.value};
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        {label: "Text", value: "text"},
        {label: "Accordion", value: "accordion"},
        {label: "Image", value: "image"},
        {label: "PDF", value: "pdf"},
        {label: "Video", value: "video"},
        {label: "Audio", value: "audio"},
        {label: "Simulation", value: "simulation"},
        {label: "Embed", value: "embed"},
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Content type",
      allowClear: true,
      // mode: "multiple",
      style: {width: "100px"},
      // style: { width: "100%" },
    },

  }
})


const SelectDifficultyType = MrSelect({
  config: {
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.label, value: item.value};
      });
    },
    searchApi: false,
    widgetConfig: {
     options: [
        { label: "Accessible", value: "accessible" },
        { label: "Moderate", value: "moderate" },
        { label: "Hard", value: "hard" },
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Difficulty levels",
      allowClear: true,
      // mode: "multiple",
      style: { width: "100%" },
    },

  }
})

export const getContentAndQuestionOptions = () => {
  const options = [
    {
      label: <MrTranslate id={"CommonText.content"} />, value: "content", key: "content", children: [
        { label: <MrTranslate id={"CommonText.text"} />, value: "text", key: "text" },
        { label: <MrTranslate id={"CommonText.accordion"} />, value: "accordion" },
        { label: <MrTranslate id={"CommonText.image"} />, value: "image", key: "image" },
        { label: <MrTranslate id={"CommonText.pdf"} />, value: "pdf", key: "pdf" },
        { label: <MrTranslate id={"CommonText.video"} />, value: "video", key: "video" },
        { label: <MrTranslate id={"CommonText.audio"} />, value: "audio", key: "audio" },
        { label: <MrTranslate id={"CommonText.simulation"} />, value: "simulation", key: "simulation" },
        { label: <MrTranslate id={"CommonText.embed"} />, value: "embed", key: "embed" },
      ]
    },
    {
      label: <MrTranslate id={"CommonText.question"} />, value: "question", key: "question", children: [
        { label: <MrTranslate id={"CommonText.long_answer"} />, value: "cke_subjective", key: "cke_subjective" },
        { label: <MrTranslate id={"CommonText.mcq"} />, value: "mcq_single", key: "mcq_single" },
        { label: <MrTranslate id={"CommonText.mcq_multiple"} />, value: "mcq_multiple", key: "mcq_multiple" },
        { label: <MrTranslate id={"CommonText.true_false"} />, value: "true_false", key: "true_false" },
        { label: <MrTranslate id={"CommonText.fill_text"} />, value: "cke_fill_text", key: "cke_fill_text" },
        { label: <MrTranslate id={"CommonText.fill_dropdown"} />, value: "cke_fill_dropdown", key: "cke_fill_dropdown" },
        { label: <MrTranslate id={"CommonText.match"} />, value: "rbd_inline_matching", key: "rbd_inline_matching" },
        { label: <MrTranslate id={"CommonText.sort"} />, value: "rbd_inline_sorting", key: "rbd_inline_sorting" },
        { label: <MrTranslate id={"CommonText.classify"} />, value: "rbd_inline_classify", key: "rbd_inline_classify" },
        { label: <MrTranslate id={"CommonText.table"} />, value: "rds_table", key: "rds_table" },
        { label: <MrTranslate id={"CommonText.drawing"} />, value: "fabric_drawing", key: "fabric_drawing" },
        { label: <MrTranslate id={"CommonText.label_drag"} />, value: "fabric_dnd_label", key: "fabric_dnd_label" },
        { label: <MrTranslate id={"CommonText.label_fill"} />, value: "fabric_fill_label", key: "fabric_fill_label" },
        { label: <MrTranslate id={"CommonText.hotspot"} />, value: "fabric_hotspot", key: "fabric_hotspot" },
        { label: <MrTranslate id={"CommonText.geogebra_graph"} />, value: "ggb_graph", key: "ggb_graph" },
        { label: <MrTranslate id={"CommonText.desmos_graph"} />, value: "desmos_graph", key: "desmos_graph" },
        { label: <MrTranslate id={"CommonText.audio"} />, value: "audio_input", key: "audio_input" },
        { label: <MrTranslate id={"CommonText.video"} />, value: "video_input", key: "video_input" },
        { label: <MrTranslate id={"CommonText.mini_quiz"} />, value: "mini_quiz", key: "mini_quiz" },
        { label: <MrTranslate id={"CommonText.connect"} />, value: "connect", key: "connect" },
        { label: <MrTranslate id={"CommonText.flash_card"} />, value: "flash_card", key: "flash_card" },
        // { label: <MrTranslate id={"CommonText.file_upload"} />, value: "file_upload", key: "file_upload" },
      ]
    }
  ]

  return options
}


export const SelectItemType = MrTreeSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    // isJsx: true,
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      // return data.map((item) => {
      //   console.log("class selector item", item);
      //   return {label: item.label, value: item.value};
      // });

      // OVERRIDING OPTIONS DATA BELOW - NOT WORKING - TODO
      // let finalRender = []
      // let contentGroup = <OptGroup label={<MrTranslate id={"SegmentsFilter.content"}/>}>
      //   <Option value={"text"}><MrTranslate id={"CommonText.text"}/></Option>
      // </OptGroup>
      // let questionGroup = <OptGroup label={<MrTranslate id={"SegmentsFilter.questions"}/>}>
      //   <Option value={"cke_subjective"}><MrTranslate id={"CommonText.long_answer"}/></Option>
      // </OptGroup>

      // finalRender.push(contentGroup)
      // finalRender.push(questionGroup)

      return data
    },
    searchApi: false,
    widgetConfig: {
      // make these option groups
      options: getContentAndQuestionOptions(),
      // filterOption: true,
      // optionFilterProp: "label",
      placeholder:<MrTranslate id={"SegmentsFilter.item_type"}/>,
      allowClear: true,
      treeCheckable: true,
      treeDefaultExpandAll: true,
      treeNodeFilterProp: "title",
      showCheckedStrategy: "SHOW_PARENT",
      mode: "multiple",
      style: {width: "200px"},
      // style: { width: "100%" },
    },

  }
})



const SelectBlockType = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.label, value: item.value};
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        {label: "ADAPTIVE_QUIZ", value: "ADAPTIVE_QUIZ"},
        {label: "MINI_QUIZ", value: "MINI_QUIZ"},
        {label: "QUICK_CHECK", value: "QUICK_CHECK"},
        {label: "FLASHCARD", value: "FLASHCARD"},
        {label: "MATCHING", value: "MATCHING"},
        {label: "GROUP_SORTING", value: "GROUP_SORTING"},
        {label: "ORDERING", value: "ORDERING"},
        {label: "MULTI_SELECT", value: "MULTI_SELECT"},
        {label: "DROPDOWN", value: "DROPDOWN"},
        {label: "FILL_IN", value: "FILL_IN"},
        {label: "ACCORDION", value: "ACCORDION"},
        {label: "AUDIO_PLAYLIST", value: "AUDIO_PLAYLIST"},
        {label: "SLIDER", value: "SLIDER"},
        {label: "boolean", value: "boolean"},
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "PC block",
      // mode: "multiple",
      allowClear: true,
      // style: {width: "150px"},
      style: { width: "100%" },
    },

  }
})

const SelectCourseId = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.label, value: item.value};
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        {label: "1173", value: 1173},
        {label: "1174", value: 1174},
        {label: "1138", value: 1138},
        {label: "1175", value: 1175},
        {label: "1176", value: 1176},
        {label: "1178", value: 1178},
        {label: "1179", value: 1179},
        {label: "1180", value: 1180},
        {label: "1181", value: 1181},
        {label: "1182", value: 1182},
        {label: "1183", value: 1183},
        {label: "1186", value: 1186},
        {label: "1187", value: 1187},
        {label: "1188", value: 1188},
        {label: "1190", value: 1190},
        {label: "1191", value: 1191},
        {label: "1140", value: 1140},
        {label: "1185", value: 1185},
        {label: "1203", value: 1203},
        {label: "1167", value: 1167},
        {label: "1168", value: 1168},
        {label: "1169", value: 1169},
        {label: "1237", value: 1237},
        {label: "1315", value: 1315},
        {label: "1316", value: 1316},
        {label: "1317", value: 1317},
        {label: "1318", value: 1318},
        {label: "1328", value: 1328},
        {label: "1329", value: 1329},
        {label: "1330", value: 1330},
        {label: "1331", value: 1331},
        {label: "1341", value: 1341},
        {label: "1342", value: 1342},
        {label: "1343", value: 1343},
        {label: "1344", value: 1344},
        {label: "1345", value: 1345},
        {label: "1346", value: 1346},
        {label: "1347", value: 1347},
        {label: "1348", value: 1348},
        {label: "1350", value: 1350},
        {label: "1351", value: 1351},
        {label: "1352", value: 1352},
        {label: "1353", value: 1353},
        {label: "1354", value: 1354},
        {label: "1355", value: 1355},
        {label: "1356", value: 1356},
        {label: "1357", value: 1357},
        {label: "1360", value: 1360},
        {label: "1361", value: 1361},
        {label: "1364", value: 1364},
        {label: "1365", value: 1365},
        {label: "1366", value: 1366},
        {label: "1367", value: 1367},
        {label: "1368", value: 1368},
        {label: "1369", value: 1369},
        {label: "1370", value: 1370},
        {label: "1371", value: 1371},
        {label: "1373", value: 1373},
        {label: "1374", value: 1374},
        {label: "1375", value: 1375},
        {label: "1377", value: 1377},
        {label: "1378", value: 1378},
        {label: "1379", value: 1379},
        {label: "1380", value: 1380},
        {label: "1383", value: 1383},
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "PC ID",
      // mode: "multiple",
      allowClear: true,
      // style: {width: "150px"},
      style: { width: "100%" },
    },

  }
})

const SegmentFilter = (props) => {
  console.log("segmeny filter props===>", props)
  const {path, url} = useRouteMatch();
  const { currentUser, resourceName, parentProps } = props;
  const { bySectionWise } = parentProps;
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const orgQBs = useSelector(orgquestionbanksSelector())
  const activeExperience = useSelector(activeAdjustedExperienceSelector()) // using activeExperience as a check for is_libary_modal basically
  const contentAndQuestionOptions = getContentAndQuestionOptions()
  const customFields = activeExperience.custom_fields || {}
  const [defaultItemTypes, setDefaultItemTypes] = useState(null)
  const relationItems = activeExperience.relation_items || []
  const subjectItems = (relationItems[0] && relationItems[0].subject_items) || []

  const mrIntl = useTranslate();

  let defaultProgrammeSubjectId = []
  let defaultProgrammeGradeId  = []
  let defaultQuestionbankId = null
  if(activeExperience && activeExperience.relation_items && activeExperience.relation_items[0]){
    if(activeExperience.relation_items[0].subject_items[0] && activeExperience.relation_items[0].subject_items[0].programme_subject_id){
      defaultProgrammeSubjectId.push(activeExperience.relation_items[0].subject_items[0].programme_subject_id)
    }
    if(activeExperience.relation_items[0].grade_items[0] && activeExperience.relation_items[0].grade_items[0].programme_grade_id){
      defaultProgrammeGradeId.push(activeExperience.relation_items[0].grade_items[0].programme_grade_id)
    }
    if(currentUser.role === "qb_author"){
      defaultQuestionbankId = activeExperience.relation_items[0].questionbank_id 
    }
  }
  const expRubric = activeExperience.rubric || {}

  let extraFilters = []

  const defaultValues = {
    by_questionbank_id: defaultQuestionbankId,
    by_experience_id: null,
    by_block_type: null,
    by_question_type: null,
    by_content_type: null,
    by_tag_paper_types_ids: [],
    by_tag_command_terms_ids: [],
    by_tag_teaching_levels_ids: [],
    by_syllabus_content_ids: [],
    by_pamoja_course_id: null,
    by_block_id: null,
    by_pamoja_course_name: null,
    by_original: true,
    by_role: null,
    by_criteria_ids: [],
    by_tag_difficulty_levels: [],
    // by_subject_ids: [],
    by_author_ids: [],
    // by_programme_subject_id: defaultProgrammeSubjectId,
    // by_programme_grade_id: defaultProgrammeGradeId,
    by_assessment_objective_ids: [],
    by_item_types: defaultItemTypes
  }

  let {
    by_questionbank_id ,
    by_experience_id,
    by_programme_subject_id,
    by_programme_grade_id,
    by_block_type,
    by_question_type,
    by_content_type,
    by_item_types,
    by_tag_paper_types_ids,
    by_tag_command_terms_ids,
    by_tag_teaching_levels_ids,
    by_syllabus_content_ids,
    by_pamoja_course_id,
    by_block_id,
    by_pamoja_course_name,
    by_original,
    by_role,
    by_criteria_ids,
    by_tag_difficulty_levels,
    by_assessment_objective_ids,
    by_author_ids,
    // by_subject_ids
  } = defaultValues

  const [currentAuthorIds, setCurrentAuthorIds] = useState(by_author_ids)

  let filtersConfig = {
    showModal: checkMob() && currentUser.role !== "student",
    triggerOnChange: !checkMob(),
  };
  const itemTypes = [];
  contentAndQuestionOptions.forEach(option => {
    if (option.value === "question") {
      option.children.forEach(child => {
        itemTypes.push(child.value);
      });
      if(customFields.org_programme_item_label === "IB Diploma")
      itemTypes.push("group")
    }
  });

  // Doing this to rerender author filter
  useEffect(() => {
    setCurrentAuthorIds(by_author_ids)
  }, [props.by_author_ids])

  useEffect(() => {
    if(bySectionWise !== undefined){
      if(bySectionWise) {
        props.setParams({
          by_section: bySectionWise,
          by_item_types: []
        })
        setDefaultItemTypes([])
      } else {
        props.setParams({
          by_section: bySectionWise,
          by_item_types: itemTypes
        })
        setDefaultItemTypes(itemTypes)
      }
    }
    console.log("props.by_item_types ====>", props.by_item_types);
  }, [bySectionWise])

  useEffect(() => {
    if(defaultItemTypes === null && props.by_item_types === undefined) {
      setDefaultItemTypes(itemTypes)
    }
  }, [props.by_item_types])

  const getValueFromParams = (
    filterName,
    filterType = "string",
    defaultValue,
    valueToCompare = true
  ) => {
    // filterType = boolean, integer, string, array
    // valueToCompare = true/false in case boolean
    let filterValue = defaultValues[filterName];
    
    if (filterType === "boolean") {
      if (
        props[filterName] === valueToCompare ||
        props[filterName] === valueToCompare.toString()
      ) {
        filterValue = valueToCompare;
      }
      // if(props.by_original == false || props.by_original == "false"){
      //   by_original = false
      // }
    } else if (filterType === "integer") {
      if (props[filterName]) {
        filterValue = parseInt(props[filterName]);
      }
    } else if (filterType === "array_integer") {
      if (props[filterName]) {
        if (Array.isArray(props[filterName])){
          filterValue = props[filterName].map((value) => parseInt(value));
        }
      }
    } else {
      // string
      if(props[filterName]) {
        filterValue = props[filterName];
      }
    }

    // return filtersConfig.triggerOnChange ? filterValue : localFilters[filterName] || props[filterName] || filterValue // as per nitin
    // filtersConfig.showModal ? !isEmpty(localFilters) ? localFilters.by_questionbank_id : by_questionbank_id : by_questionbank_id // also doing this?
    // if (!filtersConfig.triggerOnChange && !isEmpty(localFilters)) {
    //   filterValue = localFilters[filterName];
    // }

    return filterValue;
  };

  by_questionbank_id = getValueFromParams("by_questionbank_id", "integer", by_questionbank_id);
  by_experience_id = getValueFromParams("by_experience_id", "integer", by_experience_id);
  by_pamoja_course_id = getValueFromParams("by_pamoja_course_id", "string", by_pamoja_course_id)
  by_block_type = getValueFromParams("by_block_type", "string", by_block_type)
  // by_programme_grade_id = getValueFromParams("by_programme_grade_id", "integer", by_programme_grade_id)
  // by_programme_subject_id = getValueFromParams("by_programme_subject_id", "integer", by_programme_subject_id)
  by_tag_paper_types_ids = getValueFromParams("by_tag_paper_types_ids", "array", by_tag_paper_types_ids)
  by_tag_command_terms_ids = getValueFromParams("by_tag_command_terms_ids", "array", by_tag_command_terms_ids)
  by_tag_teaching_levels_ids = getValueFromParams("by_tag_teaching_levels_ids", "array", by_tag_teaching_levels_ids)
  by_original = getValueFromParams("by_original", "string", by_original)
  by_role = getValueFromParams("by_role", "string", by_role);
  by_tag_difficulty_levels = getValueFromParams("by_tag_difficulty_levels", "array", by_tag_difficulty_levels)
  by_assessment_objective_ids = getValueFromParams("by_assessment_objective_ids", "array", by_assessment_objective_ids)
  by_criteria_ids = getValueFromParams("by_criteria_ids", "string", by_criteria_ids)
  console.log("by criters id ===>",by_criteria_ids );
  // by_subject_ids = getValueFromParams("by_subject_ids", "array_integer", by_subject_ids);
  by_author_ids = getValueFromParams("by_author_ids", "array_integer", by_author_ids);

  // extraFilters.push("Library filters");

  // if(props.by_questionbank_id){
  //   by_questionbank_id = parseInt(props.by_questionbank_id)
  // }

  if(by_questionbank_id){
    // not setting in dfault values because resetting sets these as well as experience_subject_id
    by_programme_subject_id = defaultProgrammeSubjectId
    by_programme_grade_id = defaultProgrammeGradeId
  }

  console.log("by_programme_subject_id", by_programme_subject_id, activeExperience);


  let selectedQB = orgQBs && orgQBs.find((qb) => qb.questionbank_id === by_questionbank_id);
  
  filtersConfig = {
    ...filtersConfig,
    showFiltersHeaderTitle: resourceName === "librarysegments" && currentUser.role !== "student" && currentUser.role !== "parent",
    showResetFilter: resourceName === "librarysegments" && currentUser.role !== "student" && currentUser.role !== "parent",
    showSelectQuestionbankFilter:
      resourceName === "librarysegments" &&
      currentUser.org.org_questionbanks &&
      currentUser.org.org_questionbanks.length !== 0 &&
      (!activeExperience ||
        _.isEmpty(activeExperience) ||
        (activeExperience &&
          currentUser.org.org_questionbanks.filter(
            (oqb) =>
              parseInt(oqb.custom_fields.programme_id) ===
              parseInt(activeExperience.relation_items[0].programme_id)
          ).length > 0)) &&
      ["admin", "teacher", "qb_author"].indexOf(currentUser.role) > -1,
    showSelectProgrammeGradeFilter:
      props.by_questionbank_id && currentUser.role === "qb_author",
    showSelectExperienceFilter: !props.by_questionbank_id && currentUser.role !== "qb_author",
    showSelectProgrammeSubjectFilter:
      // props.by_questionbank_id && (selectedQB.qb_code === "ap_myp" || selectedQB.qb_code === "ap_myp_standard"),
      props.by_questionbank_id && currentUser.role === "qb_author",
    showSelectOrgSubjectDropdownFilter:
      !props.by_questionbank_id && (activeExperience && activeExperience.custom_fields && activeExperience.custom_fields.org_programme_item_label === "IB Middle Years"),
    showSelectCourseIdFilter:
      props.by_questionbank_id && currentUser.role === "qb_author",
    showSelectBlockTypeFilter:
      props.by_questionbank_id && currentUser.role === "qb_author",
    showSelectPaperTypeFilter: // QB DP condition should be here
      by_questionbank_id && by_programme_subject_id && by_programme_grade_id,
    showSelectCommandTermFilter:
      by_questionbank_id && selectedQB && (selectedQB.qb_code === "ib_dp" || selectedQB.qb_code === "ap_myp" || selectedQB.qb_code === "ap_myp_standard"),
    showSelectTeachingLevelFilter:
      resourceName === "librarysegments" && selectedQB && selectedQB.qb_code === "ib_dp",
      //  || by_questionbank_id && by_programme_subject_id && by_programme_grade_id,
    showSelectTreeSyllabusContentsFilter:
      // enabledFeatures.allow_elastic_search &&
      by_questionbank_id && by_programme_subject_id && activeExperience && !isEmpty(activeExperience.tagged_standards_content_areas_data), // showing for MYP and DP both
      // by_questionbank_id && by_programme_subject_id && by_programme_grade_id && (selectedQB.qb_code === "ib_dp" || selectedQB.qb_code === "osc_dp" || selectedQB.qb_code === "osc_dp_mocks"),
    showSelectAuthorFilter: !by_questionbank_id,
    showSelectItemTypeFilter: resourceName === "librarysegments" && !props.parentProps.bySectionWise,
    showSelectDifficultyFilter:
      by_questionbank_id && selectedQB && (selectedQB.qb_code === "ib_dp" || selectedQB.qb_code === "osc_dp" || selectedQB.qb_code === "osc_dp_mocks"),
    showSelectCriteriaFilter: 
      ((activeExperience && activeExperience.custom_fields && activeExperience.custom_fields.org_programme_item_label === "IB Middle Years") || (props.by_questionbank_id && (selectedQB.qb_code === "ap_myp" || selectedQB.qb_code === "ap_myp_standard"))) && expRubric.type_c !== "comments_only",
    showAssessmentObjectiveFilter: selectedQB && selectedQB.qb_code === "ib_dp",
  };
  let showQBSelect = false
  // showQBSelect = resourceName == "libraryexperiences" && ((currentUser.role == "superadmin" || currentUser.role == "qb_author") || ((currentUser.role == "admin" || currentUser.role == "teacher") && currentUser.org.org_questionbanks && currentUser.org.org_questionbanks.length != 0)) 
  // Back to this for now - until all complete. once done, switch to below
  // showQBSelect = (currentUser.role == "superadmin" || currentUser.role == "qb_author") 
  // if(import.meta.env.VITE_MODE === "staging" || import.meta.env.VITE_MODE === "development"){
    showQBSelect = resourceName === "librarysegments" && currentUser.org.org_questionbanks && currentUser.org.org_questionbanks.length !== 0 && (!activeExperience || _.isEmpty(activeExperience)  || (activeExperience && currentUser.org.org_questionbanks.filter(oqb => parseInt(oqb.custom_fields.programme_id) === parseInt(activeExperience.relation_items[0].programme_id)).length > 0)) && ["admin","teacher","qb_author"].indexOf(currentUser.role) > -1
  // }

  if(filtersConfig.showFiltersHeaderTitle){
    extraFilters.push(<Space style={{justifyContent: "space-between", width: "100%", marginBottom: "15px"}}>
      {/* <div>Filters</div>  */}
      {/* <Button icon={<FilterOutlined />}></Button> */}
      {filtersConfig.showResetFilter && <div className="text-right"><ResetFilter label={mrIntl("CommonText.reset")} setParams={props.setParams} defaultValues={defaultValues} useDefaultValues={true} setQueryParamsConfigValue={props.parentProps.config.core.setQueryParams}/></div>}
    </Space>)
  }

  // if (filtersConfig.showSelectQuestionbankFilter) {
  if(showQBSelect){
    let selectQuestionbankParams = {};
    if (
      activeExperience &&
      activeExperience.relation_items &&
      activeExperience.relation_items[0]
    ) {
      selectQuestionbankParams.by_programme_id =
        activeExperience.relation_items[0].programme_id;
      selectQuestionbankParams.with_resource_count_by_subject_id =
        activeExperience.relation_items[0].programme_subject_id;
      selectQuestionbankParams.with_resource_count_for_resource_type = "segment"
    }
    if(currentUser.role !== 'qb_author') {
      selectQuestionbankParams.without_qb_codes = ["osc_dp_mocks", "osc_dp"]
    } else {
      selectQuestionbankParams.without_qb_codes = []
    }

    extraFilters.push(<div className="filter-title">{mrIntl("CommonText.assessment_bank")}</div>)
    extraFilters.push(
      <SelectQuestionbank
        key="selectQuestionbankSegmentsFilter"
        value={by_questionbank_id}
        onChange={(value) => {
          props.setParams({
            by_questionbank_id: value,
            by_programme_grade_id: [],
            by_programme_subject_id: [],
            by_experience_id: null,
          });
        }}
        config={{ 
          params: selectQuestionbankParams,
          widgetConfig: { 
            style: {width: "100%"},
            placeholder: mrIntl("CommonText.school_library"),
            // mode: "multiple",
          }
        }}
        // style= {{ width: "100%"}}
      />
    );
  }

  /* Check if the selected question bank's code is "ap_myp_standard", If true, add an "Upgrade Plan" button to the extra filters, 
  upgrade button will open an upgrade to max plan modal */
  const { isStandardPlan, hasMaxOrPlusPlan } = getCurrentPlanStatus(currentUser);
  const dispatch = useDispatch();
  console.log("selectedQB==>", selectedQB);

  const isSelectedQBTitle = selectedQB && selectedQB.custom_fields?.title

  if (isStandardPlan && !hasMaxOrPlusPlan && isSelectedQBTitle === "AP MYP Standard") {
    extraFilters.push(
      <Button
        className="filter-title"
        type="primary"
        onClick={() => {
          dispatch(
            orgActions.setShowUpgradePlanModalSuccess({
              show: true,
              feature_code: "segment_library_question_bank_filter",
            })
          );
        }}
      >
        {mrIntl("SegmentsFilter.upgrade_plan")}
      </Button>
    );
  }
  
  if (filtersConfig.showSelectExperienceFilter) {
    extraFilters.push(<div className="filter-title">{mrIntl("SegmentsFilter.select_assessment")}</div>)
    extraFilters.push(
      <SelectExperience
        key="selectExperienceFilter"
        value={by_experience_id}
        onChange={(value) => {
          props.setParams({
            by_experience_id: value,
            // Show all original experiences when searching for experience, otherwise show only the original experiences
            by_original: value === undefined ? true : null
          });
        }}
        config={{
          params: {
            // by_rubric_type: activeExperience && activeExperience.rubric && activeExperience.rubric.type_c,
            by_subject_id: subjectItems[0] && subjectItems[0].subject_id,
            is_library: "true",
          },
          widgetConfig: {
            style: { width: "100%" },
            placeholder: mrIntl("SegmentsFilter.select_assessment"),
            // mode: "multiple",
          }
        }}
      // style= {{ width: "100%"}}
      />
    );
  }
  // }
  

  // TODO: need to control not by user_role but by a param passedinto SegmentFilter - QB author library and add from library - both library cases and qb_author may also need from library modal in future. as of now i dont even have isLibrary here
  if (filtersConfig.showSelectCourseIdFilter) {
    let selectedQB =
      orgQBs &&
      orgQBs.find(
        (qb) => qb.questionbank_id === parseInt(props.by_questionbank_id)
      );
    if (selectedQB && selectedQB.custom_fields.title.indexOf("Pamoja") > -1) {
      // if (props.by_pamoja_course_id) {
      //   by_pamoja_course_id = props.by_pamoja_course_id;
      // }
      extraFilters.push(
        <SelectCourseId
          key="selectCourseIdSegmentsFilter"
          value={by_pamoja_course_id}
          onChange={(value) => props.setParams({ by_pamoja_course_id: value })}
        />
      );
    }
  }

  if (filtersConfig.showSelectBlockTypeFilter) {
    // if (props.by_block_type) {
    //   by_block_type = props.by_block_type;
    // }
    extraFilters.push(
      <SelectBlockType
        key="selectBlockTypeSegmentsFilter"
        value={by_block_type}
        onChange={(value) => props.setParams({ by_block_type: value })}
      />
    );
  }
    
  if (filtersConfig.showSelectProgrammeGradeFilter) {
    // if (props.by_programme_grade_id) {
    //   by_programme_grade_id = parseInt(props.by_programme_grade_id);
    // }
    extraFilters.push(<div className="filter-title">{mrIntl("CommonText.grade")}</div>)
    extraFilters.push(
      <SelectProgrammeGrade
        key="selectGradeSegmentsFilter"
        value={by_programme_grade_id}
        onChange={(value) => props.setParams({ by_programme_grade_id: value })}
        config={{
          forceReload: true,
          params: { by_questionbank_id: props.by_questionbank_id },
          widgetConfig: {
            // enabledFeatures: enabledFeatures,
            style: {width: "100%"},
            placeholder: mrIntl("CommonText.all_grades"),
            // mode: "multiple",
          },
        }}
      />
    );
  }
  console.log("select subject ======>", activeExperience);
  if (filtersConfig.showSelectProgrammeSubjectFilter) {
    console.log("select subject ======>",by_programme_subject_id, activeExperience);
    // if (props.by_programme_subject_id) {
    //   by_programme_subject_id = parseInt(props.by_programme_subject_id);
    // }

    extraFilters.push(<div className="filter-title">{mrIntl("CommonText.subject")}</div>)
    extraFilters.push(
      <SelectProgrammeSubject
        key="selectSubjectSegmentsFilter"
        value={by_programme_subject_id}
        onChange={(value) =>
          props.setParams({ by_programme_subject_id: value })
        }
        config={{
          forceReload: true,
          params: { by_questionbank_id: by_questionbank_id },
          widgetConfig: { 
            style: {width: "100%"},
            placeholder: "All subjects",
            mode: "multiple",
            limitToSubjectGroupName: activeExperience && activeExperience.custom_fields && activeExperience.custom_fields.subject_item_label_group,
          },
        }}
      />
    );
  }

  // if (filtersConfig.showSelectOrgSubjectDropdownFilter) {
  //   const orgProgrammesId = activeExperience.relation_items[0].org_programme_id
  //   extraFilters.push(<div className="filter-title">Subject</div>)
  //   extraFilters.push(
  //     <SelectOrgSubject
  //       key="selectSubjectSegmentsFilter"
  //       dropdownMatchSelectWidth={false}
  //       // value={by_subject_id}
  //       value={by_subject_ids}
  //       onChange={(value) => {
  //         props.setParams({ by_subject_ids: value });
  //       }}
  //       config={{
  //         forceReload: true,
  //         params: {
  //           unarchived: true,
  //           by_org_programme_id: orgProgrammesId,
  //         },
  //         widgetConfig: {
  //           enabledFeatures: enabledFeatures,
  //           style: {width: "100%"},
  //           placeholder: "All subjects",
  //           mode: "multiple",
  //           limitToSubjectGroupName: activeExperience.custom_fields.subject_item_label_group
  //         },
  //       }}
  //     />
  //   );
  // }

  if (filtersConfig.showSelectTreeSyllabusContentsFilter) {
    if (props.by_syllabus_content_ids) {
      by_syllabus_content_ids = props.by_syllabus_content_ids.map((id, i) =>
        parseInt(id)
      );
    }
    // NOT using params anymore - using syllabus topics from content_area of the experience
    let params = {
      by_questionbank_id: by_questionbank_id,
      by_programme_subject_id: by_programme_subject_id[0],
      // by_programme_grade_id: by_programme_grade_id // TODO: need in library segments qb author but not in add from library for now - adding this filters for that grade only and DP topics are currently not to any grade but only subject
    };
    extraFilters.push(<div className="filter-title">{mrIntl("SegmentsFilter.syllabus_topic")}</div>)
    extraFilters.push(
      <SelectTreeSyllabusContents
        key="selectSyllabusContentsSegmentsFilter"
        value={by_syllabus_content_ids}
        onChange={(value) =>
          props.setParams({ by_syllabus_content_ids: value })
        }
        config={{
          forceReload: true,
          // params: params,
          widgetConfig: { 
            style: { width: "100%" },
            placeholder: mrIntl("SegmentsFilter.all_topics"),
            // options: activeExperience.standards_content_area_tag || {},
            options: activeExperience?.tagged_standards_content_areas_data || {},
          }
        }}
      />
      // will need to use by_subject_id and by_grade_id in add from library case since org. otherwise i want to pass by_programme_subject_id to this filter component
    );
  }

  if (filtersConfig.showSelectItemTypeFilter) {
    console.log("props.by_item_types ====>", props.by_item_types);
    if(props.by_item_types){
      by_item_types = props.by_item_types.filter(itemType => itemType !== "group")
    }
    const customFields = activeExperience.custom_fields || {}
    extraFilters.push(<div className="filter-title">{mrIntl("SegmentsFilter.item")}</div>)
    extraFilters.push(
      <SelectItemType 
        key="selectSegmentItemTypeFilter" 
        value={by_item_types}
        onChange={(values, label, extra) =>  {
          let item_types = []
          const contentAndQuestionOptions = getContentAndQuestionOptions()
          values.forEach((item_type) => {
            if(item_type === "content" || item_type === "question") {
              contentAndQuestionOptions.forEach((option) => {
                if(option.value === item_type) {
                  option.children.forEach((child) => item_types.push(child.value))
                }
              })
            } else {
              item_types.push(item_type)
            }
          })
          
          if(customFields.org_programme_item_label === "IB Diploma" && item_types.includes("cke_subjective")) {
            item_types.push("group")
          }
          props.setParams({by_item_types: item_types})
        }}
        config={{
          widgetConfig: { 
            style: { width: "100%" },
            mode: "multiple",
            placeholder: mrIntl("SegmentsFilter.all_items"),
          }
        }}
       />
    )
  }

  if (filtersConfig.showSelectCommandTermFilter) {
    // let selectedQB =
    //   orgQBs &&
    //   orgQBs.find(
    //     (qb) => qb.questionbank_id === parseInt(props.by_questionbank_id)
    //   );
    // if (selectedQB && selectedQB.custom_fields.title.indexOf("DP") > -1) {
    //   // Only for DP
    //   // if (props.by_tag_command_terms_ids) {
    //   //   by_tag_command_terms_ids = props.by_tag_command_terms_ids;
    //   // }
      extraFilters.push(<div className="filter-title">{mrIntl("SegmentsFilter.command_term")}</div>)
      extraFilters.push(
        <SelectCommandTerm
          key="selectCommandSelectCommandTermFilter"
          value={by_tag_command_terms_ids}
          onChange={(value) =>
            props.setParams({ by_tag_command_terms_ids: value })
          }
          config={{
            params: { by_questionbank_id: props.by_questionbank_id },
            widgetConfig: { 
              style: { width: "100%" },
              mode: "multiple",
              placeholder: mrIntl("SegmentsFilter.all_terms"),
            }
          }}
        />
      );
    // }
  }
  
  if (filtersConfig.showSelectPaperTypeFilter) {
    let selectedQB =
      orgQBs &&
      orgQBs.find(
        (qb) => qb.questionbank_id === parseInt(props.by_questionbank_id)
      );
    if (selectedQB && selectedQB.custom_fields.title.indexOf("DP") > -1) {
      // Only for DP
      // if (props.by_tag_paper_types_ids) {
      //   by_tag_paper_types_ids = props.by_tag_paper_types_ids;
      // }
      extraFilters.push(<div className="filter-title">{mrIntl("CommonText.paper")}</div>)
      extraFilters.push(
        <SelectPaperType
          key="selectPaperTypeFilter"
          value={by_tag_paper_types_ids}
          onChange={(value) =>
            props.setParams({ by_tag_paper_types_ids: value })
          }
          config={{
            widgetConfig: { 
              style: { width: "100%" },
              mode: "multiple",
              placeholder: mrIntl("CommonText.all_papers"),
            }
          }}
        />
      );
    }
  }

  if (filtersConfig.showSelectTeachingLevelFilter) {
    let selectedQB =
      orgQBs &&
      orgQBs.find(
        (qb) => qb.questionbank_id === parseInt(props.by_questionbank_id)
      );
    console.log("selectedQB", orgQBs, selectedQB);
    if (selectedQB && selectedQB.custom_fields.title.indexOf("DP") > -1) {
      // if (props.by_tag_teaching_levels_ids) {
      //   by_tag_teaching_levels_ids = props.by_tag_teaching_levels_ids;
      // }
      extraFilters.push(<div className="filter-title">{mrIntl("CommonText.teaching_level")}</div>)
      extraFilters.push(
        <SelectTeachingLevel
          key="selectTeachingTypeFilter"
          value={by_tag_teaching_levels_ids}
          onChange={(value) =>{
            console.log("select teaching lebel value===>", value)
            props.setParams({ by_tag_teaching_levels_ids: value })
          }}
          config={{
            widgetConfig: { 
              hideSl: true,
              style: { width: "100%" },
              mode: "multiple",
              placeholder: mrIntl("CommonText.all_levels"),
            }
          }}
        />
      );
    }
  }


  if (filtersConfig.showSelectDifficultyFilter) {
    extraFilters.push(<div className="filter-title">{mrIntl("SegmentsFilter.difficulty_level")}</div>)
    extraFilters.push(
      <SelectDifficultyType
        value={by_tag_difficulty_levels}
        key="selectDifficultyLevel"
        // onChange={(value) => props.setParams({by_tag_difficulty_levels: value ? [value] : []})}
        onChange={(value) => props.setParams({by_tag_difficulty_levels: value})}
        config={{
          widgetConfig: { 
            style: { width: "100%" },
            mode: "multiple",
            placeholder: mrIntl("CommonText.all_levels"),
          }
        }}
      />
    );
  }

  if(filtersConfig.showAssessmentObjectiveFilter){
    extraFilters.push(<div className="filter-title">Assessment Objective</div>)
    extraFilters.push(
    <SelectAssessmentObjective 
      value={by_assessment_objective_ids}
      key="selectDifficultyLevel"
      // onChange={(value) => props.setParams({by_tag_difficulty_levels: value ? [value] : []})}
      onChange={(value) => props.setParams({by_assessment_objective_ids: value})}
      config={{
        params: { by_questionbank_id: props.by_questionbank_id },
        widgetConfig: {
          style: { width: "100%" },
          mode: "multiple",
          placeholder: "All Objective",
        }
      }}
    />)
  }

  // if(props.by_content_type){
  //   by_content_type = props.by_content_type
  // }
  // extraFilters.push(
  //   <SelectContentType key="selectSegmentContentTypeFilter" value={by_content_type} onChange={(value) => props.setParams({by_content_type: value})}/>
  // )

  // if(props.by_question_type){
  //   by_question_type = props.by_question_type
  // }
  // extraFilters.push(
  //   <SelectQuestionType key="selectSegmentQuestionTypeFilter" value={by_question_type} onChange={(value) => props.setParams({by_question_type: value})}/>
  // )
  if (filtersConfig.showSelectCriteriaFilter) {
    extraFilters.push(<div className="filter-title">{mrIntl("SegmentsFilter.criteria")}</div>)
    extraFilters.push(
      <SelectCriteria
        key="selectCriteriaFilter"
        value={by_criteria_ids}
        onChange={(value) => props.setParams({ by_criteria_ids: value })}
        config={{
          widgetConfig: {
            options: activeExperience && activeExperience.rubric && activeExperience.rubric.criteria,
            style: { width: "100%" },
            placeholder: mrIntl("SegmentsFilter.all_criteria"),
            mode: "multiple",
          },
        }}
      />
    );
  };

  // First time author filter not fetch author thats why we rerender the author filter
  if (filtersConfig.showSelectAuthorFilter && currentAuthorIds.length === by_author_ids.length) {
    console.log("by role value ===>", by_author_ids);
    let index = by_author_ids.indexOf(currentUser.id)
    extraFilters.push(<div className="filter-title">{mrIntl("CommonText.authors")}</div>)
    extraFilters.push(
      <Checkbox checked={index > -1 ? true : false}
        onChange={(e) => {
          console.log("author checkbox", e.target.checked, by_author_ids, currentUser.id, index);
          let updatedAuthorIds = [...by_author_ids]
          if (e.target.checked) {
            updatedAuthorIds.push(currentUser.id)
            console.log("author checkbox after", updatedAuthorIds);
            return props.setParams({ by_author_ids: updatedAuthorIds })
          } else {
            if (index > -1) {
              updatedAuthorIds.splice(index, 1)
              return props.setParams({ by_author_ids: updatedAuthorIds })
            }
          }
        }
        }
      > {mrIntl("CommonText.me")} </Checkbox>
    )
    extraFilters.push(
      <SelectTeachers
        key="select-author"
        value={by_author_ids}
        onChange={(value) => {
          props.setParams({ by_author_ids: value });
        }}
      />
    );
  }

  // TODO: bring back later
  // if(props.by_original){
  //   by_original = props.by_original
  // }
  // extraFilters.push(
  //   <Checkbox key="checkboxOriginalSegmentsFilter" value={!by_original} onChange={(e) => {console.log("original filter value", e); props.setParams({by_original: !e.target.checked})}}>{mrIntl("SegmentsFilter.show_duplicates")}</Checkbox>
  // )

  const [visible, setVisible] = useState(false);
  const toggleFilterDrawer = () => {
    setVisible(!visible);
  };
  const onClose = () => {
    setVisible(false);
  };
  let drawerFilters = <React.Fragment>
    {/* show filter here if value selected, otherwise in drawer only */}
    <Button icon={<FilterOutlined />} onClick={toggleFilterDrawer} >{mrIntl("SegmentsFilter.filters")}</Button>
    {/* <Button icon={<FilterOutlined />} onClick={() => {console.log("show filters")}} >Filters</Button> */}
    <ResetFilter setParams={props.setParams} />
    <Drawer
      title="Filters"
      // placement="top"
      closable={true}
      onClose={onClose}
      open={visible}
      mask={false}
      width={"20%"}
      // height={"10%"}
    >
      <Space direction="vertical" style={{"width":"100%"}}>
        {extraFilters}
      </Space>
    </Drawer>
  </React.Fragment>

  return extraFilters;
  // return null
  // return drawerFilters;
  // return <Button icon={<FilterOutlined />} >Filters</Button>;
};

SegmentFilter.defaultProps = {};

SegmentFilter.propTypes = {};

export default SegmentFilter;
