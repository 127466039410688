import { createSelector } from "reselect";
// import lodash from "lodash";

// const loadingState = (state, props) => {
//   return state.groups.get("loading");
// };
// export const loadingSelector = () =>
//   createSelector([loadingState], (loading) => loading);

// const errorState = (state, props) => state.groups.get("error");

// export const errorSelector = () =>
//   createSelector([errorState], (error) => error);

const rubricsState = (state, props) =>
  state.request.get("rubrics") || [];

export const rubricsSelector = () =>
  createSelector([rubricsState], (rubrics) => rubrics);

