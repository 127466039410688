import { createSelector } from "reselect";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; 
import timezone from "dayjs/plugin/timezone"; 

const defaultAppTheme = "v2-light"
// const defaultAppTheme = "v3-light"

const isAuthenticatedState = (state) => {
  return state.login.get("token") !== null;
  // return localStorage.getItem.get("token") !== null;
};

export const isAuthenticatedSelector = () =>
  createSelector([isAuthenticatedState], (isAuthenticated) => {
    console.log("isAuthenticated", isAuthenticated);
    let token = localStorage.getItem("token");
    console.log("token", token);
    let tokenPresent = false;
    if (token !== null) {
      tokenPresent = true;
    }
    return isAuthenticated;
    // return isAuthenticated;
  });

const tokenPresentState = (state) => {
  let token = localStorage.getItem("token");

  return token ? true : false;
};

export const tokenPresentSelector = () =>
  createSelector([tokenPresentState], (tokenPresent) => tokenPresent);

const loadingState = (state) => {
  return state.login.get("loading");
};

export const loadingSelector = () =>
  createSelector([loadingState], (loading) => loading);

const errorState = (state) => {
  return state.login.get("error");
};

export const errorSelector = () =>
  createSelector([errorState], (error) => error);

const appRegionState = (state) => {
  let appRegion = state.login.get("region") || "";
  const isEmbedded = embeddedState(state) 
  // const activeExperience = activeAdjustedExperienceState(state) 
  // console.log("appRegion selector activeExperience", activeExperience)
  
  // TEMP for dev check
  // if(appRegion === "global"){
  // TEMP
  if(appRegion === "global" && isEmbedded){

    // let isPamoja = false
    // // let isPamoja = true
    // if(activeExperience && activeExperience.custom_fields && activeExperience.custom_fields.block){
    //   isPamoja = true
    // }
    // if(isPamoja){
      // override in case of embedded pamoja - mb_pamoja_poc school has china students also so checking tz and overriding so that behaves like china - use back4app, etc
      dayjs.extend(utc);
      dayjs.extend(timezone);
      let userTimeZone = dayjs.tz.guess() 
      console.log("appRegion selector userTimeZone", userTimeZone)
      if(userTimeZone === "Asia/Shanghai"){
        appRegion = "china"
      }
    // }
  }
  console.log("appRegion selector", appRegion)
  return appRegion
};

export const appRegionSelector = () =>
  createSelector([appRegionState], (appRegion) => appRegion);

export const currentUserState = (state) => {
  return state.login.get("currentUser");
};

export const currentUserSelector = () =>
  createSelector([currentUserState], (currentUser) => {
    // if (currentUser.permission && currentUser.permission.settings) {
    //   return {
    //     ...currentUser,
    //     permission: {
    //       ...currentUser.permission,
    //       settings: JSON.parse(currentUser.permission.settings),
    //     },
    //   };
    // } else {
    //   return currentUser;
    // }
    return currentUser;
  });

export const permissionsSelector = () =>
  createSelector([currentUserState], (currentUser) => {
    let permissions = {
      ...currentUser.permission,
    };
    return permissions;
  });

const orgState = (state) => {
  return state.login.get("currentUser").org;
};

export const orgSelector = () => createSelector([orgState], (org) => org);

export const enabledFeaturesState = (state) => {
  if (state.login.get("currentUser").org) {
    return state.login.get("currentUser").org.enabled_features || {};
  } else {
    // case where embedded and no currentUser hence no org
    return {};
  }
};

export const enabledFeaturesSelector = () =>
  createSelector([enabledFeaturesState], (enabledFeatures) => enabledFeatures);

export const embeddedState = (state) => state.login.get("embedded", false);
export const getEmbeddedSelector = () =>
  createSelector([embeddedState], (embedded) => embedded);

export const oscAccountTypeState = (state) => state.login.get("oscAccountType", false);
export const oscAccountTypeSelector = () =>
  createSelector([oscAccountTypeState], (oscAccountType) => oscAccountType);

  export const partnerNameState = (state) => state.login.get("partnerName", "");
export const partnerNameSelector = () =>
  createSelector([partnerNameState], (partnerName) => partnerName);

export const isMBIframeSelector = () =>
  createSelector([partnerNameState, embeddedState], (partnerName, embedded) => {
    return embedded && !partnerName
  });

export const userIpState = (state) => state.login.get("userIp", "");

export const getUserIpSelector = () =>
  createSelector([userIpState], (userIp) => userIp);

export const googleDriveAccessTokenState = (state) => state.login.get("googleDriveAccessToken", "");

export const googleDriveAccessTokenSelector = () =>
  createSelector([googleDriveAccessTokenState], (googleDriveAccessToken) => googleDriveAccessToken);

export const googleLoadingSelector = (state) => {
  return state.login.get("googleloading");
};
export const emailLoadingSelector = (state) => {
  return state.login.get("createloading");
};
export const microsoftLoadingSelector = (state) => {
  return state.login.get("microsoftloading");
};
export const mbLoadingSelector = (state) => {
  return state.login.get("mbloading");
};
export const forgotpasswordloadingSelector = (state) => {
  return state.login.get("forgotpasswordloading");
};

export const resetpasswordloadingSelector = (state) => {
  return state.login.get("resetpasswordloading");
};

export const mbAccountsPortalLinkedDataState = (state) => state.login.get("mb_linked_data", {});
export const mbAccountsPortalLinkedDataSelector = () =>
  createSelector([mbAccountsPortalLinkedDataState], (mb_linked_data) => mb_linked_data);

const appTheme = (state) => {
  return state.login.get("appTheme", defaultAppTheme);
};
export const appThemeSelector = () =>
  createSelector([appTheme], (appTheme) => appTheme);