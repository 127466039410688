import React, { useState, useEffect } from "react";
import { Button, Modal, Tooltip, Row, Tag, Space, Checkbox, Divider } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import { useSelector, useDispatch } from "react-redux";
import {
  DownloadOutlined,
  CloseOutlined,
  CopyOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import { exportLoadingSelector } from "/src/views/Experiences/selector";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { actions as experiencesActions } from "/src/views/Experiences/redux";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import dayjs from "dayjs";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { encodeURLCustom, experienceNameWithAccessCode, getFormattedPrintOptionsTitle, getPrintOptionTitle, writeToClipboardPolyfill } from "/src/lib/utils/helperMethods";
import { upperFirst } from 'lodash'
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
const ExportModal = (props) => {
  console.log("ExportModal props===>", props.exportModalConfig);
  const { experience = {}, exportModalConfig, setExportModalConfig, showTaskSpecificClarification, tscEnabled} = props;
  const [printOptions, setPrintOptions] = useState({with_questions: true});
  const {
    exportType,
    exportURLType = "pdf",
    experienceUser = {},
    autoInitiateExportDisabled = true,
    postToMb = false,
  } = exportModalConfig;
  let logging = true
  if (postToMb) {
    logging = false;
  }
  const currentUser = useSelector(currentUserSelector())
  console.log( "exportURLType===>",exportURLType, exportModalConfig);
  let item,
    itemType,
    export_json = {},
    itemActions = experienceUserActions;
  if (exportType == "student_submission") {
    itemType = "experience_user";
    item = experienceUser;
    itemActions = experienceUserActions;
    export_json = experienceUser.export_json || {};
  } else {
    itemType = "experience";
    item = experience;
    itemActions = experiencesActions;
    export_json = experience.export_json || {};
  }

  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const experienceSettings = experience.settings || {};
  const exported_item = export_json[exportType] || {};
  const export_status = exported_item["export_status"];
  const isPaperMode = experienceSettings.mode === "paper";
  // Disallow export if 'with_print_copies_for_each_student' is selected and there are no students mapped to the experience
  const isPrintCopiesForEachStudentAllowed = experience.students?.length > 0;
  // const exportLodingFromRedux = useSelector(exportLoadingSelector())
  const isExportStudentSubmission =
    exportType === "student_submission" ? true : false;
  const isExportAllStudentSubmissions =
    exportType === "all_student_submissions" ? true : false;
  const isExportStudentSubmissionOrAllSubmissions =
    exportType === "student_submission" ||
    exportType === "all_student_submissions"
      ? true
      : false;
  const studentName = experienceUser && experienceUser.name;
  console.log(
    "checking bugs in log msg",
    isExportStudentSubmission,
    isExportAllStudentSubmissions
  );
  const submissionLogMessage = `${
    isExportStudentSubmissionOrAllSubmissions
      ? isExportStudentSubmission
        ? "Student submission for Assessment"
        : "Student submissions for Assessment"
      : `Assessment`
  }`;
  const submissionCancelLogMessage = `${
    isExportStudentSubmissionOrAllSubmissions
      ? isExportStudentSubmission
        ? "student submission for Assessment"
        : "student submissions for Assessment"
      : `Assessment`
  }`;
  const exportFor = isExportStudentSubmissionOrAllSubmissions
      ? isExportAllStudentSubmissions
        ? "for all students"
        : `for ${studentName}`
      : "";
  const exportLoading = export_status == "loading";

  let modalTitle = exportModalConfig.modalTitle || mrIntl("ExportModal.export");
  let exportButtonText = exportModalConfig.exportButtonText || mrIntl("ExportModal.export_to_pdf");
  let timeMessage = exportModalConfig.timeMessage || mrIntl("ExportModal.this_will_take_about");
  let downloadMessage = exportModalConfig.downloadMessage || mrIntl("ExportModal.download_exported_file");
  let failedMessage = mrIntl("ExportModal.most_recent_export_failed_for_some_reason");
  let cancelledMessage = mrIntl("ExportModal.most_recent_export_was_cancelled");
  const lastExported = dayjs(
    exported_item.export_completed_at ||
    exported_item.export_url_at_time
  ).format("MMM DD, hh:mm a")
  // NOTE: handle this too from parent config
  let closable = true;
  // let closable =
  //   exportType == "student_submission" && exportLoading ? false : true;

  console.log("exportModalConfig==>", printOptions);
  console.log("exportModalConfig==>", export_json[exportType]);
  const experiencePrintOptions =
    export_json[exportType] && export_json[exportType].print_options;
  let formattedPrintOptions = "";
  if (experiencePrintOptions && typeof experiencePrintOptions === "string") {
    let selectedPrintOptions = [];
    let printOptionsArray = experiencePrintOptions.split("&");
    printOptionsArray.forEach((item) => {
      const [key, value] = item.split("=");
      let optionTitle = getPrintOptionTitle(key, mrIntl)
      if (value === "true" && optionTitle) {
        if(key === "with_print_copies_for_each_student") {
          downloadMessage = mrIntl("ExportModal.download_zip");
        }
        selectedPrintOptions.push(optionTitle);
      }
    });
    console.log("formattedPrintOptions ===>", formattedPrintOptions, selectedPrintOptions);
    formattedPrintOptions = getFormattedPrintOptionsTitle(selectedPrintOptions) 
  }

  let selectedPrintOptionsString = `Last printed: ${
    formattedPrintOptions !== "" ? formattedPrintOptions : "No options selected"
  }`;

  const handleOk = (e) => {
    setExportModalConfig({
      ...exportModalConfig,
      showModal: false,
    });
  };

  const handleCancel = (e) => {
    setExportModalConfig({
      ...exportModalConfig,
      showModal: false,
      postToMb: false
    });
  };

  const updateItemExportJSON = (exported_item) => {
    const updatedItem = {
      ...item,
      export_json: {
        ...export_json,
        [exportType]: {
          ...exported_item,
        },
      },
    };

    // updating redux
    dispatch(
      itemActions.showSuccess({
        data: {
          [itemType]: updatedItem,
        },
      })
    );

    if (itemType == "experience_user") {
      setExportModalConfig((exportModalConfig) => {
        return {
          ...exportModalConfig,
          experienceUser: updatedItem,
        };
      });
    } else {
      setExportModalConfig((exportModalConfig) => {
        return {
          ...exportModalConfig,
          experience: updatedItem,
        };
      });
    }
  };
  const copyLink = () => {
    writeToClipboardPolyfill(
      encodeURLCustom(exported_item.export_url, exportType)
    );

    message.success(mrIntl("ExportModal.link_copied"));
  };
  const cancelExport = () => {
    const textForExcelFormat = exportURLType === "excel" ? "data to Excel" : "";
    const textForPdfFormat =
      exportURLType === "pdf" || exportURLType === "zip" ? "to PDF" : "";
    console.log("isPDFisEXCEl", textForPdfFormat);
    const exportWithSendToMbMsgCancel = `${
    isExportStudentSubmissionOrAllSubmissions
      ? isExportStudentSubmission
        ? "student submission for Assessment"
        : "all student submissions for Assessment"
      : `Assessment`
    }`;
    dispatch(
      experiencesActions.export(
        {
          print_type: exportType,
          uuid: experience.uid,
          experience_user_id: experienceUser.id,
          user_id: experienceUser.user_id,
          export_cancelled: true,
        },
        {
          successCallback: () => {
            if(logging) {
              dispatch(
                firestoreInteractionActions.setLogs({
                  logging: true,
                  action: `${postToMb ? 'post_to_mb_cancelled' : 'print_cancelled'}`,
                  current_value: {
                    action_msg: submissionCancelLogMessage,
                    experience_name: experienceNameWithAccessCode(experience),
                    pdf_format: textForPdfFormat,
                    excel_format: textForExcelFormat,
                    export_type: exportFor,
                    send_to_mb_msg_cancel: exportWithSendToMbMsgCancel,
                    student_name:  exportType === "student_submission" ? `for ${studentName}` : ""
                  },
                  itemId: experience.id,
                  experienceId: experience.id,
                })
              );
            }
            updateItemExportJSON({
              ...exported_item,
              export_status: "cancelled",
            });
            message.warning(mrIntl("ExportModal.export_cancelled"));
            dispatch(experiencesActions.exportSuccess());
          },
        }
      )
    );

    // updating State
    setExportModalConfig({
      ...exportModalConfig,
      [exportType]: {
        ...exported_item,
        export_status: "cancelled",
      },
    });
  };

  const initiateExport = (newRequest = false) => {
    console.log('exporting on 134==>', printOptions)
    if(!isPrintCopiesForEachStudentAllowed && printOptions.with_print_copies_for_each_student) {
      message.warning(mrIntl("ExportModal.to_export_copies_for_each_student_please_assign_students_or"))
      return;
    }
    let exportOptions = {
      exportURLType: printOptions.with_print_copies_for_each_student ? "zip" : exportURLType,
      print_type: exportType,
      experience_id: experience.id,
      uuid: experience.uid,
      experience_user_id: experienceUser.id,
      user_id: experienceUser.user_id,
      current_user_utc_offset: dayjs().utcOffset() * 60,
    };
    const exportWithSendToMbMsg = `${
    isExportStudentSubmissionOrAllSubmissions
      ? isExportStudentSubmission
        ? "Student submission for Assessment"
        : "All student submissions for Assessment"
      : `Assessment`
    }`;
    let printOptionsToSend = printOptions;
    if (postToMb) {
      printOptionsToSend = {
        with_student_responses: true,
        with_answer_explanation: true,
        // with_markscheme: "true",
        with_questions: true
      }
    }
    console.log("printOptionstosend", printOptionsToSend)
    if (exportType === "experience" || isExportStudentSubmission || isExportAllStudentSubmissions) {
      exportOptions = {
        ...exportOptions,
        print_options: new URLSearchParams(printOptionsToSend).toString()
      }
    }
    if (postToMb) {
      exportOptions = {
        ...exportOptions,
        post_to_mb: postToMb
      }
    }
    let printOptionsToDisplay = [];
    Object.keys(printOptionsToSend).forEach((item, index) => {
      if (printOptionsToSend[item]) {
        printOptionsToDisplay.push(getPrintOptionTitle(item, mrIntl));
      }
    })
    printOptionsToDisplay = getFormattedPrintOptionsTitle(printOptionsToDisplay);
    console.log('printOptionsToD==>', printOptionsToDisplay);
    const textForExcelFormat = exportURLType === "excel" ? "Excel" : "";
    const textForPdfFormat =
      exportURLType === "pdf" || exportURLType === "zip" ? "PDF" : "";
    console.log("isPDFisEXCEl", textForPdfFormat, exportURLType);
    if (export_status !== "loading" && !exportLoading) {
      // Show immediate loading by updating status before dispatching the action
      updateItemExportJSON({
        ...exported_item,
        export_status: "loading",
      });
      dispatch(
        experiencesActions.export(exportOptions, {
          successCallback: () => {
            console.log('checking here==>', exportModalConfig)
            if(logging) {
              dispatch(
                firestoreInteractionActions.setLogs({
                  logging: true,
                  action: `${postToMb ? 'post_submission_to_mb' : 'print_started'}`,
                  current_value: {
                    action_msg: submissionLogMessage,
                    experience_name: experienceNameWithAccessCode(experience),
                    print_option: printOptionsToDisplay,
                    pdf_format: textForPdfFormat,
                    excel_format: textForExcelFormat,
                    export_type: exportFor,
                    send_to_mb_msg: exportWithSendToMbMsg,
                    student_name:  exportType === "student_submission" ? `for ${studentName}` : ""
                  },
                  itemId: experience.id,
                  experienceId: experience.id,
                })
              );
            }
            // updateItemExportJSON({
            //   ...exported_item,
            //   export_status: "loading",
            // });
          },
          errorCallback: (error) => {
            updateItemExportJSON({
              ...exported_item,
              export_status: "failed",
            });
            message.error(mrIntl("ExportModal.export_failed"));
            dispatch(experiencesActions.exportFail());
          },
        })
      );
    }
  };

  useEffect(() => {
    console.log("ExportModal exportModalConfig on 167", exportModalConfig);
    let newPrintOptions = {
      ...printOptions,
      with_questions: true,
    }

    if (isExportStudentSubmissionOrAllSubmissions) {
      newPrintOptions = {
        ...newPrintOptions,
        with_student_responses: true,
      }
    } else {
      delete newPrintOptions.with_student_responses
    }
    
    if(isPaperMode && !isExportStudentSubmissionOrAllSubmissions && newPrintOptions.with_space_for_answers) {
      newPrintOptions = {
        ...newPrintOptions,
        with_print_copies_for_each_student: true
      }
    } else {
      delete newPrintOptions.with_print_copies_for_each_student
    }
    
    setPrintOptions(newPrintOptions)
    if (exportModalConfig.showModal) {
      setExportModalConfig({
        ...exportModalConfig,
        ...exported_item,
      });
      
      if (!export_status && !exportModalConfig.noSubmissionsMessage && !autoInitiateExportDisabled) {
        initiateExport();
      }
    }
  }, [exportModalConfig.showModal]);

  useEffect(() => {
    let interval = null;
    let retries = 0;
    // let delay = 300;
    let delay = exportModalConfig.delay || 10;
    console.log("ExportModal useEffect", exportModalConfig, exportLoading);
    if (exportLoading) {
      interval = setInterval(() => {
        // TODO: should only send request for fetching exported url and time and update that here only in redux and config- not cal show for entire eu
        dispatch(
          experiencesActions.exportStatus(
            {
              export_type: exportType,
              id: item.id,
            },
            {
              successCallback: (response) => {
                console.log("Export status success data ==>", response, exportType, modalTitle);
                const returnedExportedItem = response[exportType] || {};

                if (returnedExportedItem["export_status"] == "completed") {
                  if (postToMb) {
                    message.success(mrIntl("ExportModal.submission_successfully_sent_to_managebac_gradebook"));
                  } else {
                    message.success(mrIntl("ExportModal.export_complete_msg", { ItemName: item.name }));
                  }
                  // message.success(`Export complete for ${item.name}. PDF ready for download`)
                  updateItemExportJSON(returnedExportedItem);
                  dispatch(experiencesActions.exportSuccess());
                }
                
                if (returnedExportedItem["export_status"] == "failed") {
                  message.error(mrIntl("ExportModal.export_failed"));
                  updateItemExportJSON(returnedExportedItem);
                  dispatch(experiencesActions.exportFail());
                }
              },
              errorCallback: () => {
                message.error(mrIntl("ExportModal.export_failed"));
                dispatch(experiencesActions.exportFail());
              },
            }
          )
        );

        retries += 1;
        // console.log( "retry 1");
        // if(retries >= 3 && retries <= 5){
        if (retries % 10 === 0) {
          // console.log( "updating delay" );
          delay += 45;
        }
        if (retries > 20) {
          if (interval) {
            clearInterval(interval);
          }
        }
        console.log("retries and delay", retries, delay);
      }, delay * 1000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [exportLoading, experienceUser]);

  const afterClose = () => {
    setExportModalConfig({
      ...exportModalConfig,
      showModal: false,
    });
  };
  console.log("downloadMessage==>", downloadMessage, exportModalConfig);

  const getEncodedURL = (url) => {
    console.log("exportModalConfig.exportUrl", url);
    if (exportURLType == "excel") {
      return url;
    }
    // let splitArr = [];

    // TODO: This should be handled on backend
    // let splitStr = url.match(/\/${exportURLType}\/\d{13}\//g);
    // let splitStr = url.match(/\/\d{13}\//g);

    ////////////////////
    // NOTE: To handle both old and new pdf print urls
    // https://v2res1.assessprep.com/pdf/Class_10_B_Social_Sc._Mid-Term_Examination__2FAPZ3_KHUSHI SHARMA  _GT2RYK-1620643087226.pdf
    // https://v2res1.assessprep.com/pdf/1634065707181/Arithmetic and Geometric Series (Z2TDRV) - Anahan Baptist (F4NCTW).pdf
    ////////////////////
    let splitArr = url.split("/");
    let fileName = splitArr.pop();

    let finalURL = url;

    if (fileName) {
      finalURL =
        url.replace(fileName, "") + encodeURIComponent(fileName).toString();
    }

    console.log(
      "exportModalConfig.exportUrl",
      encodeURIComponent(url),
      finalURL
    );

    return finalURL;
  };

  const onCheckboxChange = (e) => {
    console.log("onCheckboxChange==>", e.target.name, e.target.checked);
    let name = e.target.name;
    let checked = e.target.checked;
    let newPrintOptions = {
      ...printOptions,
      [name]: checked,
    };
    if (isPaperMode && name === "with_space_for_answers") {
      newPrintOptions.with_print_copies_for_each_student = checked;
    }
    setPrintOptions(newPrintOptions);
  };

  return (
    <>
      {exportModalConfig.showModal && <Modal
        title={modalTitle}
        open={exportModalConfig.showModal}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={500}
        wrapClassName="experience-info-modal export-modal"
        closable={closable}
        maskClosable={closable}
        keyboard={closable}
        afterClose={afterClose}
        zIndex={10000}
      >
        <Row>
          {/* <h4>Download last exported file or Export again</h4> */}

          {/* <Button loading={exportLoading} href={experience.exported_results_excel_url} type={!exportLoading ? "primary" : ""}> Download Excel</Button> */}
          {exportModalConfig.noSubmissionsMessage}
          {!exportModalConfig.noSubmissionsMessage && (
            <Space
              direction="vertical"
              style={{ width: "100%", textAlign: "center" }}
            >
              {!exportModalConfig.postToMb && exportType !== "all_student_grades" && (
                <Space direction="vertical" style={{ textAlign: "left" }}>
                  <Checkbox
                    checked={printOptions.with_questions}
                    disabled={
                      exportModalConfig.exportType === "experience"
                        ? true
                        : false
                    }
                    name={"with_questions"}
                    onChange={onCheckboxChange}
                  >
                    {mrIntl("ExportModal.questions")}
                  </Checkbox>
                  {isExportStudentSubmissionOrAllSubmissions  && (
                    <Checkbox
                      checked={printOptions.with_student_responses}
                      disabled={true}
                      name={"with_student_responses"}
                      onChange={onCheckboxChange}
                    >
                      {mrIntl("ExportModal.student_responses")}
                    </Checkbox>
                  )}
                  {exportModalConfig.exportType === "experience" && (
                    <Checkbox
                      checked={printOptions.with_space_for_answers}
                      name={"with_space_for_answers"}
                      onChange={onCheckboxChange}
                    >
                      {mrIntl("ExportModal.space_for_student_answers")}
                    </Checkbox>
                  )}
                  <Checkbox
                    checked={printOptions.with_answer_explanation}
                    name={"with_answer_explanation"}
                    onChange={onCheckboxChange}
                  >
                    {mrIntl("ExportModal.teachers_explanation")}
                  </Checkbox>
                  <Checkbox
                    checked={printOptions.with_markscheme}
                    name={"with_markscheme"}
                    onChange={onCheckboxChange}
                  >
                    {mrIntl("ExportModal.markscheme")}
                  </Checkbox>
                  {enabledFeatures.print_tsc_in_export_pdf &&
                  showTaskSpecificClarification &&
                  tscEnabled && (
                    <Checkbox 
                      checked={printOptions.with_tsc}
                      name="with_tsc" 
                      onChange={onCheckboxChange}
                    >
                      {" "}
                      {mrIntl("CommonText.task_specific_clarification")}
                    </Checkbox>
                  )}
                  {isPaperMode && !isExportStudentSubmissionOrAllSubmissions && 
                    <Checkbox
                      name="with_print_copies_for_each_student"
                      disabled={isPaperMode || !isPrintCopiesForEachStudentAllowed}
                      checked={printOptions.with_print_copies_for_each_student}
                      onChange={onCheckboxChange}
                    >
                      <Tooltip title={isPaperMode && mrIntl("ExportModal.to_toggle_this_use_space_for_student_answers")} getPopupContainer={triggerNode => triggerNode}>
                        {mrIntl("ExportModal.print_copies_respective_to_each_student")}
                      </Tooltip>
                    </Checkbox>
                  }
                </Space>
              )}
              {exported_item.export_status == "failed" && !exportLoading && (
                <i>{failedMessage}</i>
              )}
              {exported_item.export_status == "cancelled" && !exportLoading && (
                <i>{cancelledMessage}</i>
              )}

              <Space>
                <Tooltip
                  //  title={
                  //   exported_item.export_started_at &&
                  //   `Last started ${dayjs(
                  //     exported_item.export_started_at
                  //   ).format("MMM DD, hh:mm a")}`
                  // }
                  title={
                    exported_item.export_started_at &&
                    mrIntl("ExportModal.last_started", {
                      exportStartAt: dayjs(
                        exported_item.export_started_at
                      ).format("MMM DD, hh:mm a"),
                    })
                  }
                  placement="left"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  autoAdjustOverflow={false}
                >
                  <Button
                    key="start-export"
                    loading={exportLoading}
                    onClick={() => {
                      initiateExport(true);
                    }}
                    type={"primary"}
                  >
                    {exportButtonText}
                    {exported_item.export_url
                      ? ` ${mrIntl("ExportModal.again_btn")}`
                      : exportLoading
                      ? ` ${mrIntl("ExportModal.in_progress_btn")}`
                      : ""}
                  </Button>
                </Tooltip>
                {exportLoading && (
                  <Button
                    key="cancel-export"
                    icon={<CloseOutlined />}
                    onClick={() => {
                      cancelExport();
                    }}
                  >
                    {mrIntl("CommonText.cancel")}
                  </Button>
                )}

              </Space>

              <i className="time-message" >
                {timeMessage}
              </i>

              {!exportModalConfig.postToMb && exported_item.export_url && !exportLoading && (
                <>
                  <Divider className="divider" />
                  {exportType !== "all_student_grades" && (
                    <span>{selectedPrintOptionsString}</span>
                  )}
                  <Space>
                    <Tooltip
                      /////////////
                      // NOTE: export_url_at_time is old variable not saved anymore but supporting
                      /////////////
                      title={mrIntl("ExportModal.last_exported_title", {
                        lastExported: lastExported,
                      })}
                      autoAdjustOverflow={false} // To force placement of tooltip as given
                      placement="left"
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    >
                      <Button
                        key="download-export"
                        icon={<DownloadOutlined />}
                        loading={exportLoading}
                        href={encodeURLCustom(
                          exported_item.export_url,
                          exportType
                        )}
                        target={"_blank"}
                      >
                        {" "}
                        {downloadMessage}
                      </Button>
                    </Tooltip>
                    <Tooltip
                      /////////////
                      // NOTE: export_url_at_time is old variable not saved anymore but supporting
                      /////////////
                      className="export-link-copy-button"
                      // title={`Copy download link`}
                      title={mrIntl("ExportModal.copy_download_link")}
                      autoAdjustOverflow={false} // To force placement of tooltip as given
                      placement="right"
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    >
                      <Button
                        loading={exportLoading}
                        onClick={() => {
                          copyLink();
                        }}
                        type={"default"}
                      >
                        <CopyOutlined />
                      </Button>
                    </Tooltip>
                  </Space>
                </>
              )}
            </Space>
          )}
        </Row>
      </Modal>}
    </>
  );
};

export default ExportModal;
