import { Button, Empty, Radio } from "antd";
import SelectOrgDropdown from "/src/components/AppSpecific/SelectOrg/SelectOrgDropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppVersionDistributionModal from "/src/views/Experiences/ExperienceShow/ExperienceManage/ExperienceMonitor/AppVersionDistributionModal";
import { executeConnectionObject } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/firebaseHelper";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { isFirebaseAuthenticatedSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import axios from "axios";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { getFromLS } from "/src/lib/utils/helperMethods";
import { axiosInstance } from "/src/api/apiModule";
import dayjs from "dayjs";
import { appRegionSelector } from "/src/views/Auth/Login/selector";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";
// import { expIdsToTest } from './data.js';

const expIdsToTest = [13247, 105510, 191597];

function AllDeviceInfos() {
  const [allDeviceInfo, setAllDeviceInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchType, setFetchType] = useState("");
  const [error, setError] = useState(false)

  const dispatch = useDispatch();
  let isFirebaseAuthenticated = useSelector(isFirebaseAuthenticatedSelector);
  const appRegion = useSelector(appRegionSelector())

  useEffect(() => {

  }, [isFirebaseAuthenticated]);

  useEffect(() => {
    dispatch(
      firestoreInteractionActions.getFirebaseToken({
        experienceId: expIdsToTest[0],
      })
    );
  }, []);

  const fetchUserInfos = (id) => {
    setLoading(true);
    setError(false)

    let config = {};
    if (id) {
      config = {
        org_id: id
      }
    }
    axiosInstance.instance
      .post(
        `experiences_get_ids_for_version_distribution.json`,
        config
      )
      .then((data) => {
        if (data.data.experience_ids.length === 0) {
          setLoading(false);
          setError(true)
        }
        if (isFirebaseAuthenticated) {
          const today = dayjs().add(1, 'day');
          const sixMonthsAgo = today.subtract(6, 'month');

          const newArray = [];
          for (const exp of data.data.experience_ids) {
            console.log("ids data==>", exp, typeof exp);
            const params = appRegion === "china" ? {experience_id: ["eq", exp]} : {}
            executeConnectionObject(
              {
                collectionPath: `experiences/${exp}/user_info`,
                tableName: "user_info",
                appRegion: appRegion,
              },
              { onlyBase: true, realtime: false, params: params },
              (data) => {
                console.log("ids data==>12", data);
                if (data.empty || data.length === 0) {
                  setLoading(false);
                  setError(true);
                }
                data.forEach((element) => {
                  let data = {};
                  if (appRegion === 'china') {
                    data = {
                      ...element,
                    };
                  } else {
                    data = {
                      ...data,
                      user_id: element.id,
                      ...element.data(),
                    };
                  }
                  
                  newArray.push(data);
                });
                if (newArray.length > 0) {
                  const filteredData = newArray.filter(
                    (student) =>
                      student.deviceInfo &&
                      student.deviceInfo.appVersion &&
                      dayjs(student.started_at).isAfter(sixMonthsAgo, 'day') &&
                      dayjs(student.started_at).isBefore(today, 'day')
                  );
                  console.log("allDeviceInfo user_info data", newArray, filteredData);
                  const updatedData = filteredData.reduce((acc, curr) => {
                    if (
                      !acc[curr.user_id] ||
                      acc[curr.user_id].started_at < curr.started_at
                    ) {
                      acc[curr.user_id] = curr;
                    }
                    return acc;
                  }, {});
                  const finalData = Object.values(updatedData);
                  setAllDeviceInfo([...allDeviceInfo, ...finalData]);
                  setLoading(false);
                }
              }
            );
          }
        }

      })
      .catch((error) => console.log(error));
  }

  const onFetchChange = (e) => {
    setFetchType(e.target.value);
    if (e.target.value === "all") {
      fetchUserInfos()
    }
  };
  return (
    <>
      <Radio.Group buttonStyle="solid" onChange={onFetchChange}>
        <Radio.Button value="all"><MrTranslate id={"AllDeviceInfos.fetch_all_user"}/></Radio.Button>
        <Radio.Button value="one"><MrTranslate id={"AllDeviceInfos.fetch_for_school"}/></Radio.Button>
      </Radio.Group>
      <br />
      {loading && <Spinner />}
      {fetchType === "one" && (
        <SelectOrgDropdown
          onChange={(value) => {
            fetchUserInfos(value)
          }}
          config={{ widgetConfig: { style: { width: "250px" } } }}
        />
      )}
      {allDeviceInfo.length > 0 && (
        <AppVersionDistributionModal data={allDeviceInfo} />
      )}
      {error && <Empty />}
    </>
  );
}

export default AllDeviceInfos;
