import React, { useState } from "react";
import { Button, Card, Col, Dropdown, Input, Menu, Row, Select, Space, Tag } from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import UseAISolutionsButton from "../../GenerateAI/UseAISolutionButton";
import RateAiResult from "../../GenerateAI/RateAiResult";
import { CheckBox } from "/src/components/UI/Segment/UIHelper";
import "./AIContentImprovementFooter.scss";
import AIAssistantSettings from "../../GenerateAI/AIAssistantSettings";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { useSelector } from "react-redux";
import MagicButton from "../../GenerateAI/MagicButton";
import { ImMagicWand } from "react-icons/im";
import Meta from "antd/es/card/Meta";
import { SettingOutlined, EditOutlined, EllipsisOutlined, CloseOutlined, BulbOutlined } from "@ant-design/icons";
const AIContentImprovementFooter = ({
  onTextChanged,
  handleUseButtonClick,
  onMagicButtonClick,
  promptList,
  handleDiscard,
  showImproveButton = false,
  aiGeneratedContent = "",
  isStreaming = false,
  showUseAndDiscardButton = true,
  defaultActiveTabInSettings = "Authoring",
  improveInputPlaceholder = ""
}) => {
  const [showImproveInput, setShowImproveInput] = useState(false);
  const [userPrompt, setUserPrompt] = useState("");
  const [applyDisabled, setApplyDisabled] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);
  const mrIntl = useTranslate();
  const enabledFeatures = useSelector(enabledFeaturesSelector())

  const handleImprove = () => {
    setShowImproveInput(true);
  };

  const handleApplyClick = () => {
    onMagicButtonClick({
      user_prompt: userPrompt,
      action_type: "improve",
      last_generated_value: aiGeneratedContent,
    });
    setApplyDisabled(true);
    setApplyLoading(true);
  };

  const handleDiscardClick = () => {
    handleDiscard()
    setShowImproveInput(false)
    setApplyLoading(false)
    setUserPrompt("")
  };

  const promptMenu = (
    <Menu>
      {promptList && promptList.map((prompt, index) => (
        <Menu.Item key={index} onClick={() => setUserPrompt(prompt)}>
          {prompt}
        </Menu.Item>
      ))}
    </Menu>
  );

  let improveCard = (
    <Card
      // actions={[
      //   <Button icon={<ImMagicWand />}>Suggestions</Button> ,
      //   <Button icon={<EditOutlined />}>Settings</Button>,
      //   <Button icon={<EllipsisOutlined />}>Cancel</Button>,
      //   <Button icon={<EllipsisOutlined />}>Apply</Button>,
      // ]}
      className="ai-assistant-card"
    >
      <Input.TextArea
        autoFocus={true}
        autoSize={{
          minRows: 1,
          maxRows: 3,
        }}
        value={userPrompt}
        onChange={(e) => setUserPrompt(e.target.value)}
        placeholder={improveInputPlaceholder}
        className="ai-assistant-card-input"
      />
      <Row className="ai-assistant-card-actions" justify="space-between">
        <Col>
          <Row align="middle" gutter={[5, 5]}>
            <Col>
              <Dropdown
                overlay={promptMenu}
                trigger={['click']}
              >
                <Button icon={<BulbOutlined />} color="default" type="text">
                  {mrIntl("AIContentImprovementFooter.suggestions")}
                </Button>
              </Dropdown>
            </Col>
            {enabledFeatures.ai_assistant_settings ? (
              <Col>
                <AIAssistantSettings withText={true} defaultActiveTab={defaultActiveTabInSettings} />
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col>
          <Row align="middle" gutter={[5, 5]}>
            <Col>
              <Button icon={<CloseOutlined />} color="default" type="text" disabled={applyLoading} onClick={() => setShowImproveInput(false)}>{mrIntl("AIContentImprovementFooter.cancel")}</Button>
            </Col>
            <Col>
              <MagicButton
                // tooltipTitle={mrIntl("AIContentImprovementFooter.improve")}
                shape="default"
                mode="subtle"
                type="text"
                text={applyDisabled ? mrIntl("AIContentImprovementFooter.applying") : mrIntl("AIContentImprovementFooter.apply")}
                // loading={explanationLoading}
                feature_code="generate_explanation_with_ai"
                onClick={() => handleApplyClick()}
                loading={applyLoading}
                disabled={!userPrompt.trim() || applyDisabled}
                icon={<ImMagicWand />}
                // disabled={!isQuestionContentExist}
                extraClassName="float-right"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )

  // return finalRender;

  return (
    <div className="ai-content-improvement-footer">
      <div className="ai-footer-buttons">
        <div className="ai-left-buttons">
          {!showImproveInput ? (
            <>
              {showUseAndDiscardButton ?
                <UseAISolutionsButton
                  loading={isStreaming}
                  onTextChanged={() => onTextChanged(aiGeneratedContent)}
                  onClick={handleUseButtonClick}
                /> : null}
              {(showImproveButton && enabledFeatures.allow_improve_ai_content) ?
                // <Button 
                //   onClick={() => handleImprove()} 
                //   size="small"
                // >
                //   {mrIntl("AIContentImprovementFooter.improve")}
                // </Button>
                // TODO: handle loading and stop for improve also - its also a magicbutton
                <MagicButton
                  // tooltipTitle={mrIntl("AIContentImprovementFooter.improve")}
                  shape="default"
                  mode="subtle"
                  type="text"
                  size="small"
                  text={mrIntl("AIContentImprovementFooter.improve")}
                  // loading={explanationLoading}
                  feature_code="generate_explanation_with_ai"
                  onClick={() => handleImprove()}
                  icon={<ImMagicWand />}
                  // disabled={!isQuestionContentExist}
                  extraClassName="float-right"
                />
                : null}
              {showUseAndDiscardButton ?
                <Button
                  onClick={() => handleDiscardClick()}
                  // danger
                  variant="filled"
                  color="default"
                  size="small"
                >
                  {mrIntl("AIContentImprovementFooter.discard")}
                </Button> : null}
            </>
          ) : null}
        </div>

        {!showImproveInput &&
          <div className="ai-right-buttons">
            {showImproveButton && (
              <RateAiResult generatedData={aiGeneratedContent} action="generate_solutions" />
            )}
          </div>}
      </div>

      {showImproveInput && aiGeneratedContent ? (
        // <div className="ai-improve-input-container">
        //   <div className="ai-input-row">
        //     <div className="ai-input">
        //       <Space direction="vertical">
        //         <Input.TextArea
        //           autoFocus={true}
        //           autoSize={{
        //             minRows: 1,
        //             maxRows: 3,
        //           }}
        //           value={improvedText}
        //           onChange={(e) => setImprovedText(e.target.value)}
        //           placeholder={improveInputPlaceholder}
        //         />
        //         <div className="ai-prompt-tag-container">
        //           {promptList.map((prompt, index) => (
        //             <Tag key={`prompts-${index}`} bordered={false} color="purple" onClick={() => setImprovedText(prompt)}>
        //               {prompt}
        //             </Tag>
        //           ))}
        //         </div>
        //       </Space>
        //     </div>

        //     <div className="ai-controls">
        //       <Row gutter={[10, 10]} className="ai-button-row" >
        //         <Col className="ai-button-group">
        //           <Button
        //             type="primary"
        //             size="small"
        //             onClick={() => handleApplyClick()}
        //             disabled={!improvedText.trim() || applyDisabled}
        //             loading={applyLoading}
        //           >
        //             {applyDisabled ? mrIntl("AIContentImprovementFooter.applying") : mrIntl("AIContentImprovementFooter.apply")}
        //           </Button>
        //           <Button onClick={() => setShowImproveInput(false)} disabled={applyLoading} size="small">
        //             {mrIntl("AIContentImprovementFooter.cancel")}
        //           </Button>
        //         </Col>
        //         {enabledFeatures.ai_assistant_settings ? (
        //           <Col>
        //             <AIAssistantSettings withText={true} defaultActiveTab={defaultActiveTabInSettings} />
        //           </Col>
        //         ) : null}
        //       </Row>
        //     </div>
        //   </div>
        // </div>
        improveCard
      ) : null}
    </div>
  );
};

export default AIContentImprovementFooter;