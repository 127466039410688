import React, { useEffect, useState } from "react";
import { List, Space, Tooltip, Button, Avatar, Popconfirm, Tag, Statistic, Row, Col, Dropdown, Menu, Collapse } from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import "/src/App/App.scss";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ActionBtns from "../../components/ActionBtns/ActionBtns";

import { DeleteOutlined, EditOutlined, LockOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import {actions} from "./redux";
import {actions as loginActions} from "/src/views/Auth/Login/redux";
import OrgStatsModal from "./OrgStatsModal";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";
import { DownOutlined, ExportOutlined, WarningOutlined } from "@ant-design/icons";
import ExportOrgsListModal from "/src/views/Orgs/ExportOrgsListModal";
import { BiArchiveIn,  } from "react-icons/bi";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

function useDispatchInterval(loading, callback) {
  // const currentUser = useSelector(currentUserSelector());
  useEffect(() => {
    let interval = null;
    let retries = 0;
    let delay = 30;
    // let delay = 60;
    if(loading){
      interval = setInterval(() => {
        callback();
        // dispatch(actions.show({id: org.id}, {
        //   successCallback: (data) => {
        //     console.log( "syncorg data", data , currentUser.org_id);
        //     // clog
        //     if(data.data.org){
        //       if(org.id === currentUser.org_id){
        //         dispatch(loginActions.orgSuccess({org: data.data.org}));
        //       }
        //     }
        //   }
        // }));
        retries += 1;
        // console.log( "retry 1");
        // if(retries >= 3 && retries <= 5){
        if(retries % 2 === 0){
          // console.log( "updating delay" );
          delay += 30;
        }
        if(retries > 20){
          if(interval){
            clearInterval(interval)
          }
        }
      }, delay * 1000);
    } else {
      if(interval){
        clearInterval(interval)
      }
    }
    
    return () => {
      if(interval){
        clearInterval(interval)
      }
    }
  }, [loading, callback]);
}

export const syncLoadingSelector = props => state => {
  const {org} = props;
  let loading = state.orgs.get("syncloading");
  let syncLoading = false;
  if(org.custom_fields && org.custom_fields.sync_info){
    if(org.custom_fields.sync_info.status === "loading"){
      syncLoading = true
    }
    let currentDate = new Date();
    let syncStartDate = new Date(org.custom_fields.sync_info.latest_started_at || org.custom_fields.sync_info.started_at);
    // console.log( "currentDate - syncStartDate", currentDate - syncStartDate > 5 * 60 * 1000 );
    if((currentDate - syncStartDate) > (20 * 60 * 1000)){
      syncLoading = false;
    }
  }
  const finalLoading = loading || syncLoading;
  return finalLoading;
}
export const migrateLoadingSelector = props => state => {
  const {org} = props;
  let loading = state.orgs.get("migrateloading");
  let migrateLoading = false;
  if(org.custom_fields && org.custom_fields.migration_info){
    if(org.custom_fields.migration_info.status === "loading"){
      migrateLoading = true
    }
    let currentDate = new Date();
    let migrationStartDate = new Date(org.custom_fields.migration_info.latest_started_at || org.custom_fields.migration_info.started_at);
    // console.log( "currentDate - migrationStartDate", currentDate - migrationStartDate > 5 * 60 * 1000 );
    if((currentDate - migrationStartDate) > (50 * 60 * 1000)){
      // migrateLoading = false;
    }
  }
  const finalLoading = loading || migrateLoading;
  return finalLoading;
}

export const MigrateBtn = (props) => {
  const {org} = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector())
  const selectorProps = {
    org
  }
  const loading = useSelector(migrateLoadingSelector(selectorProps));
  const onClick = (event) => {
    let params = {}
    // event.stopPropagation();
    // event.preventDefault();
    if(!loading){
      params.id = org.id
      if(event.key === "1"){
        params.only_failed = false
      }
      if(event.key === "2"){
        params.only_failed = true
      }
      // if(event.key === "3"){
      //   params.continue_remaining = true
      // }
      // if(event.key === "4"){
      //   params.duration = "1w"
      // }
      // if(event.key === "5"){
      //   params.duration = "1m"
      // }
      if(event.key.indexOf("-") > -1){
        params.duration = `2021-${event.key}`
      }
      // if(event.key === "6"){
      //   params.duration = "last_completed"
      // }
      // if(event.key === "6"){
      //   params.with_submissions = false
      // }
      
      dispatch(actions.migrate(params))
    }
  }
  useEffect(() => {
    let interval = null;
    let retries = 0;
    // let delay = 300;
    let delay = 60;
    if(loading){
      interval = setInterval(() => {
        dispatch(actions.show({id: org.id}, {
          successCallback: (data) => {
            console.log( "migrate org data", data , currentUser.org_id);
            // clog
            if(data.data.org){
              if(org.id === currentUser.org_id){
                dispatch(loginActions.orgSuccess({org: data.data.org}));
              }
            }
          }
        }));
        retries += 1;
        // console.log( "retry 1");
        // if(retries >= 3 && retries <= 5){
        if(retries % 2 === 0){
          // console.log( "updating delay" );
          delay += 60;
        }
        if(retries > 20){
          if(interval){
            clearInterval(interval)
          }
        }
      }, delay * 1000);
    } else {
      if(interval){
        clearInterval(interval)
      }
    }
    
    return () => {
      if(interval){
        clearInterval(interval)
      }
    }
  }, [loading, dispatch, org]);
  
  
  const menu = <Menu onClick={onClick}>
    <Menu.Item key="1">
      Migrate all
    </Menu.Item>
    <Menu.Item key="01-22">
      Migrate from Jan 22
    </Menu.Item>
    <Menu.Item key="01-23">
      Migrate from Jan 23
    </Menu.Item>
    <Menu.Item key="01-24">
      Migrate from Jan 24
    </Menu.Item>
    <Menu.Item key="01-25">
      Migrate from Jan 25
    </Menu.Item>
    <Menu.Item key="01-26">
      Migrate from Jan 26
    </Menu.Item>
    <Menu.Item key="01-27">
      Migrate from Jan 27
    </Menu.Item>
    <Menu.Item key="01-28">
      Migrate from Jan 28
    </Menu.Item>
    <Menu.Item key="01-29">
      Migrate from Jan 29
    </Menu.Item>
    <Menu.Item key="01-30">
      Migrate from Jan 30
    </Menu.Item>
    <Menu.Item key="01-31">
      Migrate from Jan 31
    </Menu.Item>
    <Menu.Item key="02-01">
      Migrate from Feb 1
    </Menu.Item>
    <Menu.Item key="02-02">
      Migrate from Feb 2
    </Menu.Item>
    <Menu.Item key="02-03">
      Migrate from Feb 3
    </Menu.Item>
    <Menu.Item key="02-04">
      Migrate from Feb 4
    </Menu.Item>
    <Menu.Item key="02-05">
      Migrate from Feb 5
    </Menu.Item>


    {/* <Menu.Item key="6">
      Migrate from last completed
    </Menu.Item> */}
    {/* <Menu.Item key="5">
      Migrate all 1m
    </Menu.Item> */}
    {/* <Menu.Item key="3">
      Resume
    </Menu.Item> */}
    <Menu.Item key="2">
      Migrate only failed
    </Menu.Item>
    {/* <Menu.Item key="6">
      Migrate all w/o submissions
    </Menu.Item> */}
  </Menu>
  return <>
    <Tooltip title={org.custom_fields.migration_info && `${org.custom_fields.migration_info.status}-${org.custom_fields.migration_info.latest_completed_at}`} placement="top">
      <Dropdown overlay={menu} disabled={ loading } >
      {/* <Dropdown overlay={menu} disabled={org.migration_complete || loading } > */}
        <Button loading={loading}>
          {org.migration_complete ? "Migration complete" : "Migrate"} <DownOutlined />
        </Button>
      </Dropdown>
    </Tooltip>
  </>

}
export const SyncBtn = (props) => {
  const {org} = props;
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const currentUser = useSelector(currentUserSelector())
  const selectorProps = {
    org
  }
  dayjs.extend(relativeTime);
  
  const loading = useSelector(syncLoadingSelector(selectorProps));
  console.log( "org.custom_fields.sync_info.completed_at", org.custom_fields.sync_info );
  console.log( "org.custom_fields.sync_info.completed_at", loading );
  let completedAt, syncedSince, syncError;
  if(org.custom_fields && org.custom_fields.sync_info){
    completedAt = org.custom_fields.sync_info.latest_completed_at || org.custom_fields.sync_info.completed_at;
    syncedSince = org.custom_fields.sync_info.latest_synced_date_from || org.custom_fields.sync_info.synced_date_from;
    syncError = org.custom_fields.sync_info.error;
  }
  
  // console.log( "completed_at", completedAt );
  useEffect(() => {
    let interval = null;
    let retries = 0;
    let delay = 30;
    // let delay = 60;
    if(loading){
      interval = setInterval(() => {
        dispatch(actions.show({id: org.id}, {
          successCallback: (data) => {
            console.log( "syncorg data", data , currentUser.org_id);
            // clog
            if(data.data.org){
              if(org.id === currentUser.org_id){
                dispatch(loginActions.orgSuccess({org: data.data.org}));
              }
            }
          }
        }));
        retries += 1;
        // console.log( "retry 1");
        // if(retries >= 3 && retries <= 5){
        if(retries % 2 === 0){
          // console.log( "updating delay" );
          delay += 30;
        }
        if(retries > 20){
          if(interval){
            clearInterval(interval)
          }
        }
      }, delay * 1000);
    } else {
      if(interval){
        clearInterval(interval)
      }
    }
    
    return () => {
      if(interval){
        clearInterval(interval)
      }
    }
  }, [loading, dispatch, org]);
  
  // console.log( "custom_fields", org.custom_fields );
  
  const onClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if(!loading){
      dispatch(actions.sync({id: org.id}))
    }
  }
  let lastSyncedAtRender = null, syncedSinceRender = null;
  if(completedAt || syncError){
    // lastSyncedAtRender
    // lastSyncedAtRender = <Tooltip title="Last Synced" placement="top"><Tag>{Last synced dayjs(completedAt).format("MMM DD, hh:mm:ss a")}</Tag></Tooltip>
    if(syncedSince){
      syncedSinceRender = <> for {dayjs(syncedSince).fromNow(true)}</>
    }
    lastSyncedAtRender = syncError ? <Tooltip title="Error" placement="top"><Tag color={"red"}>{syncError}</Tag></Tooltip> : <Tooltip title="Last Synced" placement="top"><Tag>Synced {dayjs(completedAt).fromNow()}{syncedSinceRender}</Tag></Tooltip>
  }
  return <Space>
    {!loading && lastSyncedAtRender}
    <Button loading={loading} onClick={onClick}>
      Sync MB
    </Button>
  </Space>
  
}

const OrgsList = ({ dataSource, actionBtns, currentUser, deleted }) => {
  console.log("inside orgslist==>", dataSource);
  // let listHeader = <Tag color="processing">{dataSource.length} schools</Tag>
  const Title = ({item}) => {
    console.log('title==>', item);
    let title = []
    
    if(item.is_mb){
      title.push(<Tag color="warning">MB</Tag>)
    }
    if(item.is_atlas){
      title.push(<Tag color="error">AT</Tag>)
    }
    if(item.custom_fields.is_v1_org || item.custom_fields.ap_id){
      title.push(<Tooltip title={item.custom_fields.ap_id}><Tag color="error">V1</Tag></Tooltip>)
    }
    if(item.migration_complete){
      title.push(<Tooltip title={item.id}><Tag color="processing">V2</Tag></Tooltip>)
    }
    if (item.archived) {
      title.push(
        <Tooltip title={item.custom_fields.reason_for_archiving}>
          <Tag color="default">
            <BiArchiveIn />
          </Tag>
        </Tooltip>
      );
    }
    if (item.custom_fields.show_notice) {
      title.push(
        <Tooltip title={`${item.custom_fields.notice_type} - ${item.custom_fields.notice_message}`}>
          <Tag color="error">
            <WarningOutlined />
          </Tag>
        </Tooltip>
      );
    }
    title.push(`[${item.country}] ${item.name}`)
    title.push(<OrgStatsModal item={item} />)
    return title;
  }

  const Description = ({item}) => {
    let orgProgrammeDetails = []
    const mrIntl = useTranslate();
    item.org_stats.ops_stats.map((program, i) => {
      if(i <= 4){
        let programTag = <Tag key={`program-tag-${program.id}`}>{program.label}({program.students_count})</Tag>
        orgProgrammeDetails.push(programTag)
      }
    })
    if(item.org_stats.ops_stats.length > 4){
      orgProgrammeDetails.push(<span>...</span>)
    }
    return (
      <Space direction="vertical">
        <Space className="description" key={item.id}>
        {/* {currentUser.role == "superadmin" && <span>{item.org.name}</span>} */}
        {<Tooltip title="Created on" placement="bottom"><span>{dayjs(item.created_at).format("MMM DD, YYYY")}</span></Tooltip>}
        {<Tooltip title="Updated at" placement="bottom"><span>{dayjs(item.updated_at).format("MMM DD, YYYY h:mm a")}</span></Tooltip>}
        {<Tooltip title="Total registered students" placement="bottom"><span><Tag color="processing">
          {item.org_stats.org_users_count.total_registered_students}
        </Tag></span></Tooltip>}
        {<Tooltip title="Total active students - atleast 1 submission in last 12 months (includes guest submissions)" placement="bottom"><span><Tag color="warning">
          {item.org_stats.org_users_count.total_active_students}
        </Tag></span></Tooltip>}
        {<Tooltip title="Max students allowed" placement="bottom"><span><Tag color="error">
          {item.org_stats.org_users_count.max_students_allowed}
        </Tag></span></Tooltip>}
        {<Tooltip title="Total assessments delivered" placement="bottom"><span><Tag color="processing">
          {item.org_stats.org_exp_stats.total_delivered_experiences}
        </Tag></span></Tooltip>}
        {<Tooltip title="Total submissions" placement="bottom"><span><Tag color="processing">
          {item.org_stats.org_exp_stats.total_submissions}
        </Tag></span></Tooltip>}
        {<Tooltip title="Total MB linked" placement="bottom"><span><Tag color="success">
          {item.org_stats.org_exp_stats.total_experiences_mb_linked}
        </Tag></span></Tooltip>}
        {item.org_stats.org_exp_stats.total_delivered_experiences > 0 && <Tooltip title="Latest assessment created on" placement="bottom"><span><Tag color="processing">
          {dayjs(item.org_stats.org_exp_stats.latest_exp_created_at).format("MMM DD, YYYY")}
        </Tag></span></Tooltip>}
        {orgProgrammeDetails}
        {/* <span><InfoCircleOutlined/></span> */}
        </Space>
        <Space>
          <OrgSubscriptionDetails item={item}/>
        </Space>
      </Space>
    )
  }

  const ProfileIcon = ({item}) => (
    <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
      {item && item.name.substr(0, 1).toUpperCase()}
    </Avatar>
  )

  const OrgSubscriptionDetails = ({item}) => {
    const mrIntl = useTranslate()
    let orgSubscriptionDetails = []
    item.org_subscriptions_attributes.map((sub, i) => {
      // console.log("teacher", teacher)
      let tagColor = sub.status === "Customer" ? "success" : (sub.status == "Trial" ? "processing" : "")
      // let subTag = <Tooltip placement="bottom" title={sub.status === "Customer" ? `Customer since ${dayjs(sub.ends_on).format("MMM DD, YYYY")} - Renewal date ${dayjs(sub.next_renews_on).format("MMM DD, YYYY")}` : (sub.status === "Trial" ? `Trial ends on ${dayjs(sub.trial_ends_on).format("MMM DD, YYYY")}` : "")}><Tag color={tagColor} key={`sub-tag-${sub.id}`}>{sub.name}-{sub.status}-{sub.max_users}</Tag></Tooltip>
      let subTag = 
        <Tooltip
          placement="bottom"
          title={sub.status === "Customer" ? `Customer since ${dayjs(sub.starts_on).format("MMM DD, YYYY")} - Renewal date ${dayjs(sub.next_renews_on).format("MMM DD, YYYY")}` : (sub.status === "Trial" ? `Trial ends on ${dayjs(sub.trial_ends_on).format("MMM DD, YYYY")}` : "")}>
          <Tag color={tagColor} key={`sub-tag-${sub.id}`}>
            {sub.name}-{sub.status}-{sub.max_users}
          </Tag>
        </Tooltip>
      // let subTag = `${sub.name}-${sub.status}-${sub.max_users} |`
      orgSubscriptionDetails = orgSubscriptionDetails.concat(subTag)
    })
    return orgSubscriptionDetails
  }

  const ExtraInfo = ({item}) => {
    const extra = [];
    // extra.push(<OrgSubscriptionDetails item={item} />);
    return extra;
  }

  // const Actions = ({item}) => {
  //   const actions = [];
  //   let showSyncBtn = false;
  //   item.org_subscriptions_attributes.map((sub, i) => {     
  //     if(sub.status === "Customer" || sub.status === "Trial"){
  //       showSyncBtn = true
  //     }
  //   });
  //   (currentUser.role === 'superadmin' || currentUser.role === "support") && item.is_mb && showSyncBtn && actions.push(<Space>
  //     <SyncBtn key={`syncBtn${item.id}`} org={item} />
  //   </Space>)
  //   return actions;
  // }

  const extraActions = (item) => {
    const actions = [];
    let showSyncBtn = false;
    item.org_subscriptions_attributes.map((sub, i) => {     
      if(sub.status === "Customer" || sub.status === "Trial"){
        showSyncBtn = true
      }
    });
    if(import.meta.env.NODE_ENV === "development"){
      showSyncBtn = true;
    }
    if((currentUser.role === 'superadmin' || currentUser.role === "support") && item.is_mb && showSyncBtn){
      actions.push(<Space>
        <SyncBtn key={`syncBtn${item.id}`} org={item} />
      </Space>)
    }
    // if((currentUser.role === 'superadmin') && item.is_mb && showSyncBtn){
    // showing for non-mb schools too
    // if((currentUser.role === 'superadmin') && showSyncBtn){
    //   actions.push(<Space>
    //     <MigrateBtn key={`migrateBtn${item.id}`} org={item} />
    //   </Space>)
    // }
      
    return actions;
  }


  const ListHeader = () => {
    let header = ""
    const dispatch = useDispatch();
    const mrIntl = useTranslate();
    const orgListHeaderActionsMenu = (
      <Menu>
        <Menu.Item key="menu-item-export-excel" title="Export all to excel" icon={<ExportOutlined />} onClick={() => {
          setExportModalVisible(true)
          dispatch(actions.exportToExcel({}))
          }}>
          Export to excel
        </Menu.Item>
      </Menu>
    );
    const orgListHeaderActionsButton = <Dropdown overlay={orgListHeaderActionsMenu} placement="bottomRight">
      <Button type="text" ghost={false} style={{"padding":"0px"}}>
          <MrTranslate id={"CommonText.actions"}/> <DownOutlined />
      </Button>
    </Dropdown>

    if(currentUser.role == "superadmin" ){
      header = <Space align="baseline" style={{"display":"flex", "alignItems":"baseline", "justifyContent":"space-between"}}>
        <h4>All schools </h4>
        {orgListHeaderActionsButton}
      </Space>
    }
    return header
  }

  const [exportModalVisible, setExportModalVisible] = useState(false);

  return (
    <>
      <ExportOrgsListModal setExportModalVisible={setExportModalVisible} exportModalVisible={exportModalVisible}/>
      <List
        header={<ListHeader />}
        className="custom-main-list"
        // loading={initLoading}
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(item, i) => (
          <CustomListItem
            item={item}
            // actions={[<SyncBtn item={item} />]}
            // currentUser={currentUser}
            edit={currentUser.role === 'superadmin' || currentUser.role === "support"}
            deleted={deleted}
            deletedAllow={false}
            show={currentUser.role === 'superadmin' || currentUser.role === "support"}
            title={<Title item={item} />}
            description={<Description item={item} />}
            extraInfo={<ExtraInfo item={item} />}
            avatar={<ProfileIcon item={item} />}
            // extraActions={<Actions item={item} currentUser={currentUser} />}
            extraActions={extraActions(item)}
            // permission={permission}
            // showForm={props.showForm}
            // setUserToSetPassword={setUserToSetPassword}
          />
        )}
      />
    </>
  );
  // return (
  //   <>
  //     <List
  //       // header={listHeader}
  //       className="custom-main-list"
  //       // loading={initLoading}
  //       itemLayout="horizontal"
  //       dataSource={dataSource}
  //       renderItem={(item, i) => (
  //         <CustomUserListItem
  //           item={item}
  //           actions={actions}
  //           // currentUser={currentUser}
  //           edit={true}
  //           deleted={false}
  //           // permission={permission}
  //           // showForm={props.showForm}
  //           // setUserToSetPassword={setUserToSetPassword}
  //         />
  //       )}
  //     />
  //   </>
  // );
};

export default OrgsList;
