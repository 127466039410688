import React, { useEffect, useState, useRef } from "react";
// import GGBApplet from "./deployggb";
// import { actions } from "./redux";
import { Row, Col, Button, Switch } from "antd";
import styled from 'styled-components'
import { AddButton, CheckAnswerButton, QuickCheckBox, TryAgainButton, CustomTextButton, Segment, SegmentData, ShowText, ShowTextIndex, ShowTextContent, ShowTextFile, InputArea, DeleteButton, HorizontalDroppableContainer, DragDropArea, VerticalDroppableContainer } from '/src/components/UI/Segment/UIHelper'
import DefaultCKE from "/src/components/UI/CKEditor/DefaultCKE"
import SegmentItemHeader from '/src/views/Segments/SegmentItemDetail/SegmentItemHeader'
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { renderMathInElement } from "/src/lib/utils/helperMethods"
import { useSelector } from "react-redux";
import { isMBIframeSelector } from "/src/views/Auth/Login/selector";


const DropDownOption = props => {
  console.log("DropDownOption props", props);
  const isMBIframe = useSelector(isMBIframeSelector());

  const renderUsingWiris = !isMBIframe


  const dropDownOption = useRef()
  useEffect(() => {
    renderMathInElement(dropDownOption.current, renderUsingWiris)
  }, [])

  

  return (
    <div
      ref={(node) => {
        dropDownOption.current = node
      }}
      dangerouslySetInnerHTML={{ __html: props.data }}>
    </div>

  );
};
export default DropDownOption;