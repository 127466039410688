import React, { useEffect, useState } from "react";

import { Radio, Space } from "antd";
import { initialData, removeItemFromArray, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { Segment, SegmentData, CustomLabel } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { cloneDeep } from 'lodash';
import MediaUI from "/src/components/UI/Media/MediaUI"
import AudioView from "./AudioView";
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments"
import { useSelector } from "react-redux";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const AudioForm = props => {
	console.log("RTC Audio props", props);
	let { createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)
	let parsedJSONObject
  const mrIntl = useTranslate();
	if (createMode) {
		parsedJSONObject = props.value || initialData('resource', 1, 4);
	} else {
		parsedJSONObject = props.resource_json
	}

  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const currentUser = useSelector(currentUserSelector()) 

	const [state, setState] = useState(parsedJSONObject)
	const [stateUpdatedCount, setStateUpdatedCount] = useState(0)

  console.log("current user in audio form ==>", currentUser)
  const allowLargeFiles = enabledFeatures.allow_large_files

	const mediaFormInitialState = {
		modal: false,
		loading: false,
		visible: false,
		title: mrIntl("CommonText.insert_audio"),
		okText: mrIntl("CommonText.add_audio_button"),
		cancelText: mrIntl("CommonText.cancel"),
		triggerSaveAction: 0,
		okDisabled: true,
		requireFooter: false,
		currentMediaType: 'audio',
		content_type: 'audio',
		supportedTypes: 'audio/mp3, audio/mpeg, audio/wav',  // mpeg file because some mp3 file content type is audio/mpeg 
		supportedFormats: 'mp3, wav',
		selectConfig: {
			maxSize: allowLargeFiles ? "500 MB" : "50 MB",
		}
	}
	const [mediaFormSetting, setMediaFormSetting] = useState(mediaFormInitialState)

	let currentJSON
	if (presentationMode) {
		currentJSON = parsedJSONObject
	} else {
		currentJSON = state
	}
	const segment_data = currentJSON?.segment_data || {}
	const resource_setting = currentJSON.resource_setting || { view_type: 'tabular' }
	const attachments = segment_data.attachments || []
	const attachmentsCount = attachments.length

	useEffect(() => {
		setMediaFormSetting({
			...mediaFormSetting,
			modal: attachmentsCount > 0,
			visible: false,
		})
	}, [attachmentsCount])

	useEffect(() => {
		if (createMode) {
			if (props.onChange) {
				let resource_json = cloneDeep(state)
				props.onChange(resource_json)
			}
		}
	}, [stateUpdatedCount]);

	const saveState = (state) => {
		setState(state)
		console.log("stateUpdated count ==>", stateUpdatedCount)
		setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
	}

	const onTabDragEnd = (attachments) => {
		const newAttachments = attachments.slice()

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const removeItem = (index) => {
		const extra = {
			noMinSize: true
		}
		const newAttachments = removeItemFromArray(attachments, index, extra)
		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}
		saveState(newState)
	}

	const removeFile = (index) => {
		const newAttachments = attachments
    newAttachments[index] = { ...newAttachments[index], files: [] };

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}
		saveState(newState)
	}

	const onSaveMedia = (files) => {
		console.log('Files and attachmetns ==>', attachments, files)
		let newAttachments = attachments
		if (mediaFormSetting.currentMediaType == 'audio') {
			newAttachments = attachments.concat(files)
		} else if (mediaFormSetting.currentMediaType == 'image') {
			newAttachments[mediaFormSetting.currentAttachmentIndex].files = files
		}

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log('ON save image ==>', newAttachments, newState)

		saveState(newState)
		setMediaFormSetting({
			...mediaFormSetting,
			from: '',
			modal: true,
			visible: false,
			triggerSaveAction: 0,
		})
	}

	const onTextChanged = (type, index, data) => {
		const newAttachments = attachments
		newAttachments[index][type] = data

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log("onchange is getting fired ==>", newState, data)
		saveState(newState)
	}

	const onTabEdit = (index, action) => {
		if (action == 'add') {
			openMediaModal('audio')
		} else if (action == 'remove') {
			removeItem(index)
		}
	}

	const setResourceSetting = (key, value) => {
		const newResourceSetting = {
			...resource_setting,
			[key]: value
		}

		const newState = {
			...state,
			resource_setting: newResourceSetting
		}

		saveState(newState)
	}

	const handleViewTypeChange = (e) => {
		setResourceSetting('view_type', e.target.value)
	}

	const openMediaModal = (type, index) => {
		if (type == "image") {
			setMediaFormSetting({
				...mediaFormSetting,
				modal: true,
				visible: true,
				currentMediaType: type,
				from: 'computer',
				title: mrIntl("CommonText.insert_images"),
				okText: mrIntl("CommonText.add_images_button"),
				cancelText: mrIntl("CommonText.cancel"),
				currentAttachmentIndex: index,
				content_type: 'image',
				supportedTypes: 'image/jpg, image/jpeg, image/png, image/gif, image/bmp',
				supportedFormats: 'jpg, jpeg, png, gif, bmp',
				selectConfig: {
					maxSize: "20 MB"
				},
			})
		} else if (type == "audio") {
			setMediaFormSetting({
				...mediaFormSetting,
				modal: true,
				visible: true,
				currentMediaType: type,
				from: 'audiorecorder',
				title: mrIntl("CommonText.insert_audio"),
				okText: mrIntl("CommonText.add_audio_button"),
				cancelText: mrIntl("CommonText.cancel"),
				content_type: 'audio',
				supportedTypes: 'audio/mp3, audio/mpeg, audio/wav',  // mpeg file because some mp3 file content type is audio/mpeg 
				supportedFormats: 'mp3, wav',
				selectConfig: {
					maxSize: "50 MB",
				}
			})
		}
	}

	const getMediaForm = () => {
		const type = mediaFormSetting.currentMediaType

		let audiorecorder = false
		let computer = true
		let url = true

		if (type == 'audio') {
			audiorecorder = true
			url = false
		}

		console.log("mediaFormSetting in getmedia form ==>", mediaFormSetting)

		return (<MediaUI
			audiorecorder={audiorecorder}
			computer={computer}
			url={url}
			mediaFormSetting={mediaFormSetting}
			setMediaFormSetting={setMediaFormSetting}
			onSaveMedia={onSaveMedia}
		></MediaUI>)
	}

	console.log("Audio form state before render ==>", state, mediaFormSetting)
	return (
		<Segment>
			<SegmentData>
				{createMode && getMediaForm()}

				{attachmentsCount > 0 && <div>
					{createMode ? <TabularAttachments
						createMode={createMode}
						attachmentType="audio"
						tabPrefix="Audio"
						titleRequired={true}
						attachments={attachments}
						resource_setting={resource_setting}
						onTabDragEnd={onTabDragEnd}
						removeFile={removeFile}
						onTabEdit={onTabEdit}
						onTextChanged={onTextChanged}
						openMediaModal={openMediaModal}
					></TabularAttachments>
						: <AudioView
							resource_json={state}
						></AudioView>}

					{createMode && attachmentsCount > 1 &&
						<div>
							<CustomLabel key="audio-layout-text" style={{ "fontWeight": "700", "display": "block", "margin": "0" }}>{mrIntl("AudioForm.layout")}</CustomLabel>
							<Radio.Group onChange={(e) => handleViewTypeChange(e)} value={resource_setting.view_type} style={{ margin: '20px' }} buttonStyle="solid">
								<Space>
									<Radio.Button value="tabular">{mrIntl("AudioForm.tabular")}</Radio.Button>
									<Radio.Button value="playlist">{mrIntl("AudioForm.playlist_button")}</Radio.Button>
								</Space>
							</Radio.Group>
						</div>}
				</div>}
			</SegmentData>
		</Segment >
	);
}
export default AudioForm;