import React from "react";

import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const MobileUploadDone = (props) => {
  const { setUserInfo } = props;

  // const userInfoFromRedux = useSelector(userInfoSelector);
  // const currentSessionIdFromRedux = useSelector(currentSessionIdSelector);

  // const [checkIfSessionChanged, setCheckIfSessionChanged] = useState()
  // useEffect(() => {
  //   if (checkIfSessionChanged) {
  //     if (currentSessionIdFromRedux == userInfoFromRedux.session_id) {
  //       message.error(`Not able to reach your web client current ${currentSessionIdFromRedux} user info ${userInfoFromRedux.session_id}`)
  //     } else {
  //       message.info("Successfully uploaded")
  //     }
  //   }
  // }, [checkIfSessionChanged])

  return (
    <Button
      onClick={() => {
        setUserInfo({ is_uploading_finished: true });
        // setTimeout(() => {
        //   setCheckIfSessionChanged(getUniqueId())
        // }, 10000)
      }}
      icon={<CheckOutlined />}
      danger
    >
      <MrTranslate id={"MobileUploadDone.done"}/>
    </Button>
  );
};

export default MobileUploadDone;
