import React, { useState } from "react";
import { List, Avatar, Space, Tag, Tooltip, Button, Popconfirm, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import "../Users.scss";
// import UserCard from "./UserCard";
import dayjs from "dayjs";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { actions as userReduxActions } from "/src/views/Users/redux";
import { LockOutlined, DeleteOutlined, InfoCircleTwoTone, UsergroupAddOutlined, LoginOutlined, MailOutlined } from "@ant-design/icons";
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { enabledFeaturesSelector } from "../../Auth/Login/selector";
import SetAccommodationBtn from "./SetAccommodationBtn";
import AccommodationModal from "./AccommodationModal";

const UsersList = (props) => {
  console.log("user props==>", props);
  const { dataSource, permission, currentUser, deleted } = props;
  const [userToSetPassword, setUserToSetPassword] = useState(null);
  const [userToSetActiveOrgUser, setUserToSetActiveOrgUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isAccommodationModalOpen, setIsAccommodationModalOpen] = useState(false);

  const dispatch = useDispatch();
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const mrIntl = useTranslate();

  const { url } = useRouteMatch();
  console.log("exp list item url", url);
  // return (
  //   <>
  //     {checkMob() ? (
  //       <>
  //         <UserCard dataSource={dataSource} />
  //       </>
  //     ) : (
  //       <Table
  //         dataSource={tableDataSource}
  //         columns={columns}
  //         pagination={false}
  //       />
  //     )}
  //   </>
  // );
  const Title = ({ item }) => (
    <Space className="title">
      {item.archived && (
        <Tooltip title={mrIntl("UsersList.this_user_is_archived_title")}>
          <span className="archive-icon">
            <BiArchiveIn />
          </span>
        </Tooltip>
      )}
      {(currentUser.role === "superadmin" ||
        currentUser.role === "support") && (
        <Tag>
          {item.id} - {item.source_id} - {item.custom_fields.mb_account_uid}
        </Tag>
      )}
      <span>{item.name}</span>
    </Space>
  );

  const Description = ({ item, currentUser }) => {
    const isStudent = item.role === "student" ? true : false;
    return (
      <Space className="description">
        {<span>{item.email}</span>}
        {isStudent && item.grade && <span>{item.grade.label}</span>}
        {<span>{dayjs(item.created_at).format("MMM DD, YYYY")}</span>}
        {(currentUser.role === "superadmin" ||
          currentUser.role === "support") && <span>{item.org.name}</span>}
        {/* <span><InfoCircleOutlined/></span> */}
      </Space>
    );
  };

  const ProfileIcon = ({ item }) => (
    <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
      {item.name.substr(0, 1).toUpperCase()}
    </Avatar>
  );

  const ExtraInfo = ({ item, currentUser, showForm }) => {
    const isStudent = item.role === "student" ? true : false;
    const extra = [];
    isStudent && extra.push(<Tooltip title={mrIntl("UsersList.student_code")} placement="top">
      <Tag>{item.s_code}</Tag>
    </Tooltip>
    );
    extra.push(<Tooltip title={mrIntl("UsersList.user_role")} placement="top">
      <Tag>{item.role}</Tag>
    </Tooltip>
    );
    (currentUser.role === "superadmin" || currentUser.role === "support") &&
      (item.role === "teacher" || item.role === "admin") &&
      extra.push(
        <Tooltip title={mrIntl("CommonText.total_assessments_delivered")}>
          <Tag color="blue">{item.user_stats.total_experiences_delivered}</Tag>
        </Tooltip>
      );

    (currentUser.role === "superadmin" || currentUser.role === "support") &&
      (item.role === "teacher" || item.role === "admin") &&
      extra.push(
        <Tooltip title={mrIntl("UsersList.total_original_items_authored")}>
          <Tag color="blue">{item.user_stats.total_segments_created}</Tag>
        </Tooltip>
      );

    (currentUser.role === "superadmin" || currentUser.role === "support") &&
      isStudent &&
      extra.push(
        <Tooltip 
        // title="Total submissions"
        title={mrIntl("CommonText.total_submissions")}
        >
          <Tag color="blue">{item.user_stats.total_submissions}</Tag>
        </Tooltip>
      );
    currentUser.role === "superadmin" &&
      isStudent &&
      extra.push(
        <Tooltip
        //  title="Parent count"
          title= {mrIntl("CommonText.parent_count")}
         >
          <Tag color="yellow">{item.user_relations.length}</Tag>
        </Tooltip>
      );

    (currentUser.role === "superadmin" || currentUser.role === "support") &&
      extra.push(
        <Tooltip 
        // title="Org users"
        title={mrIntl("CommonText.org_users")}
        >
          <Tag
            style={{cursor: "pointer"}}
            color="yellow" 
            icon={<UsergroupAddOutlined />}
            onClick={() => {
              if(permission.user.update){
                setUserToSetActiveOrgUser(item);
                showForm({ name: "usersSetActiveOrgUser" });
              }
            }}
          >
            {item.org_users.length}
          </Tag>
        </Tooltip>
      );

    return extra;
  };

  const UnarchiveBtn = ({ item, withText }) => (
    <>
      <Space>
        <Tooltip title={withText ? "" : mrIntl("UsersList.unarchive")} placement="bottom">
          <Button
            type="text"
            shape={!withText && "circle"}
            icon={<BiArchiveOut />}
            className="unarchive-action-button"
            onClick={() => {
              dispatch(
                userReduxActions.update(
                  { id: item.id, org_user_org_id: currentUser.org_id, should_update_org_user: true, archived: false },
                  { success: { showMessage: true, message: mrIntl("UsersList.user_unarchived") } }
                )
              );
            }}
          >{withText ? withText : ""}</Button>
        </Tooltip>
      </Space>
    </>
  );

  const ArchiveBtn = ({ item, withText }) => {
    const confirm = (e) => {
      e.stopPropagation();
      // deleted(item);
      dispatch(
        userReduxActions.update(
          { id: item.id, org_user_org_id: currentUser.org_id, should_update_org_user: true, archived: true },
          { success: { showMessage: true, message: mrIntl("UsersList.user_archived") } }
        )
      );
    };
    const cancel = (e) => {
      e.stopPropagation();
    };

    return <>
      <Popconfirm
        key={`delete-${item.id}`}
        title= {mrIntl("CommonText.confirm_archive")}
        placement="left"
        zIndex="10000"
        onConfirm={confirm}
        onCancel={cancel}
        okText={mrIntl("CommonText.yes")}
        cancelText={mrIntl("CommonText.no")}
      >
        <Tooltip title={withText ? "" : mrIntl("CommonText.archive")} placement="bottom">
          <Button
            className="archive-button"
            type="text"
            shape={!withText && "circle"}
            icon={<DeleteOutlined />}
            onClick={(e) => e.stopPropagation()}
          >{withText ? withText : ""}</Button>
        </Tooltip>
      </Popconfirm>
    </>
  }


  const WelcomeEmailBtn = ({ item = {}, withText = " " }) => {
    const handleConfirm = () => {
      const payload = {
        id: item.id,
        org_id: item.org_id,
      };
      dispatch(userReduxActions.sendWelcomeEmail(payload));
    };

    const cancel = (e) => {
      e.stopPropagation();
    };

    return (
      <Popconfirm
        key={`sendEmail-${item?.id}`}
        title={mrIntl("UsersList.confirm")}
        onConfirm={handleConfirm}
        onCancel={cancel}
        placement={withText && "left"}
        okText={mrIntl("CommonText.yes")}
        cancelText={mrIntl("CommonText.no")}
      >
          <Button
            onClick={(event) => {event.stopPropagation()}}
            className="list-item-edit-button"
            type="text"
            shape={!withText && "circle"}
            icon={<MailOutlined />}
          >
            {withText}
          </Button>
      </Popconfirm>
    );
  };

  const TrackingInfo = (props) => {
    const { item } = props;

    const content = (
      <div style={{fontSize: "12px"}}>
        <p>sign_in_count: {item.sign_in_count}</p>
        <p>current_sign_in_at: {item.current_sign_in_at}</p>
        <p>last_sign_in_at: {item.last_sign_in_at}</p>
        <p>current_sign_in_ip: {item.current_sign_in_ip}</p>
        <p>last_sign_in_ip: {item.last_sign_in_ip}</p>
      </div>
    );

    let finalRender = <Popover content={content} title={mrIntl("UsersList.login_info")}>
      <Button ghost type={"text"} icon={<InfoCircleTwoTone />} onClick={(event) => event.preventDefault()}></Button>
    </Popover>

    return finalRender;
  }
  
    
  const SwitchIdentityBtn = ({ item, asMenuItem }) => (
    <>
      <Space>
        <Button
          key={`switchIdentity-${item.id}`}
          type={asMenuItem ? "text" : "primary"}
          icon={asMenuItem && <LoginOutlined />}
          onClick={(event) => {
            console.log('switching identity==>', currentUser.role);
            event.preventDefault()
            localStorage.setItem('originalUserRole', currentUser.role)
            dispatch(loginActions.switchIdentity({ email: item.email }));
          }}
        >
          {mrIntl("UsersList.login")}
        </Button>
      </Space>
    </>
  );

  const SetPasswordBtn = ({ item, showForm, withText }) => (
    <>
      <Space>
        <Tooltip title= {withText ? "" : mrIntl("UsersList.set_password")} placement="top">
          <Button
            className="set-password-button"
            type="text"
            icon={<LockOutlined />}
            onClick={(e) => {
              e.preventDefault()
              setUserToSetPassword(item);
              showForm({ name: "usersSetPassword" });
            }}
          >{withText ? withText : ""}</Button>
        </Tooltip>
      </Space>
    </>
  );

  const SetActiveOrgUserBtn = ({ item, showForm }) => (
    <>
      <Space>
        <Tooltip title="Set active org user" placement="top">
          <Button
            shape="text"
            icon={<UsergroupAddOutlined />}
            onClick={() => {
              setUserToSetActiveOrgUser(item);
              showForm({ name: "usersSetActiveOrgUser" });
            }}
          />
        </Tooltip>
      </Space>
    </>
  );

  const returnModifiedItem = (item, currentUser) => {
    let newItem = {...item}
    if(item.org_users.length){
      let orgId = currentUser.org_id
      let correctOrgUser = item.org_users.find(ou => ou.org_id == orgId)
      if(correctOrgUser){
        // TODO: we should actually use all attrs of correctOrgUser - since we join with org_users for the list but are still showing user attrs - might be wrong
        newItem.archived = correctOrgUser.archived || item.archived // can remove item.archived after rake
        newItem.org_programme_is_mb = correctOrgUser.org_programme_is_mb || item.org_programme_is_mb // need to get from grade_id of org_user in org_user serializer
        // When updating user accommodations, only the org_user custom fields should be updated, not the other org accommodations
        newItem.custom_fields = correctOrgUser.custom_fields || item.custom_fields 
      }
      console.log("returnModifiedItem newItem", newItem, currentUser, correctOrgUser);
    }
    // console.log("returnModifiedItem newItem", newItem);
    return newItem
  }

  const actions = (item, showForm) => {
    const actions = [];
    if (
      (currentUser.role === "superadmin" ||
        currentUser.role === "support" ||
        import.meta.env.VITE_MODE === "development") &&
      !item.archived
    ) {
      actions.push(<SwitchIdentityBtn item={item} />);
      actions.push(<TrackingInfo item={item} />);
    }
    
    // permission.user.update &&
    //   (currentUser.role === "superadmin" ||
    //   currentUser.role === "support") &&
    //   !item.archived &&
    //   actions.push(<SetActiveOrgUserBtn item={item} showForm={showForm} />);
    permission.user.update &&
      !item.archived && item.role !== "admin" && item.role !== "teacher" &&
      actions.push(<SetPasswordBtn item={item} showForm={showForm} />);

    return actions;
  };

  const openAccommodationModal = (item) => {
    setSelectedUser(item);
    setIsAccommodationModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setIsAccommodationModalOpen(false);
  };

  const getMenuItemActions = (item, showForm) => {
    const menuItemActions = []
    const isAdminWithManagePermission = currentUser.role === "admin" && currentUser.permission?.manage_permissions?.list;
    const shouldAddAccommodationButton = (
      enabledFeatures.student_accommodations &&
      !item.archived &&
      currentUser.role === "admin" &&
      item.role === "student"
    );
    
  
    permission.user.delete &&
    (!currentUser.org.is_mb || (item.role === "student" && !item.org_programme_is_mb)) &&
    !item.archived &&
    (currentUser.role === "admin") &&
      menuItemActions.push(<ArchiveBtn item={item} withText={mrIntl("CommonText.archive")}/>);
    if (shouldAddAccommodationButton) {
      menuItemActions.push(
        <SetAccommodationBtn
          key={`accommodation-${item.id}`}
          openAccommodationModal={() => openAccommodationModal(item)}
          buttonConfig={{ 
            buttonProps: {
              disabled: currentUser.org.is_mb,
            },
            disabledTooltip: mrIntl("UsersList.not_allowed"),
          }}
        />
      );
    }

    permission.user.delete &&
    (!currentUser.org.is_mb || (item.role === "student" && !item.org_programme_is_mb)) &&
    item.archived &&
    (currentUser.role === "admin") &&
      menuItemActions.push(<UnarchiveBtn item={item} withText={mrIntl("UsersList.unarchive")}/>);

    if (enabledFeatures.send_welcome_email_to_user && isAdminWithManagePermission) {
      menuItemActions.push(<WelcomeEmailBtn item={item} withText={mrIntl("UsersList.send_welcome_email")} />);
    }
    
    return menuItemActions
  }

  const handleAccommodationChange = (accommodations) => {
    console.log("user list accommodation===>>>>>>", accommodations);
    dispatch(userReduxActions.update(
      {
        id: selectedUser.id,
        org_user_org_id: currentUser.org_id,
        should_update_org_user: true,
        custom_fields: {
          ...selectedUser.custom_fields,
          accommodations: {
            ...accommodations,
          },
        },
      },
      {
        success: {
          showMessage: true,
          message: mrIntl("UsersList.accommodations_updated"),
        },
        error: {
          showMessage: true,
          message: mrIntl("UsersList.error_updating_accommodations"),
        },
        successCallback: (response) => {
          console.log("accommodations updated===>>>>>>", response);
          const updatedAccommodations = response?.data?.user?.data?.attributes?.custom_fields?.accommodations || {};
          console.log("updatedUser===>>>>>>", updatedAccommodations);
          setSelectedUser({
            ...selectedUser,
            custom_fields: {
              ...selectedUser?.custom_fields || {},
              accommodations: updatedAccommodations,
            },
          });
        },
      }
    ));
  }

  let finalRender = null;

  finalRender = (
    <React.Fragment>
      <AccommodationModal
        name={selectedUser?.name}
        isAccommodationModalOpen={isAccommodationModalOpen}
        accommodations={selectedUser?.custom_fields?.accommodations}
        onChange={handleAccommodationChange}
        closeModal={() => closeModal()}
      />
      <List
        className="custom-main-list"
        // loading={initLoading}
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(originalItem, i) => {
          
          // Modifying the archived flag using org_user.archived
          let item = returnModifiedItem(originalItem, currentUser)
          return <CustomListItem
            item={item}
            title={<Title item={item} />}
            description={<Description item={item} currentUser={currentUser} />}
            avatar={<ProfileIcon item={item} />}
            extraInfo={<ExtraInfo item={item} currentUser={currentUser} showForm={props.showForm} />}
            extraActions={actions(item, props.showForm)}
            menuItemActions={getMenuItemActions(item, props.showForm)}
            isListActionsMenu={true}
            type="users"
            linkToGo={null}
            currentUser={currentUser}
            edit={
              permission.user.update && (!currentUser.org.is_mb || !currentUser.org.is_edlink || (item.role === "student" && !item.org_programme_is_mb)) && !item.archived && (currentUser.role === "admin" || (currentUser.role === "superadmin" && item.role === "qb_author"))
            }
            // deleted={deleted}
            deleted={false} // custom archiveBtn
            // deletedAllow={
            //   permission.user.delete && !item.archived && !currentUser.org.is_mb
            // }
            permission={permission}
            showForm={props.showForm}
            setUserToSetPassword={setUserToSetPassword}
            setUserToSetActiveOrgUser={setUserToSetActiveOrgUser}
          />
        }}
      />

      <div>
        {props.renderForm({
          name: "usersSetPassword",
          config: {
            isModal: true,
            visible: props.showFormVal === "usersSetPassword",
            handleCancelCallback: () => {
              console.log("cancelled")
            },
            submitConfig: { hideForm: true },
            modal: { title: mrIntl("UsersList.set_password"), closeIcon: false },
          },
          resource: userToSetPassword,
        })}
        {props.renderForm({
          name: "usersSetActiveOrgUser",
          config: {
            isModal: true,
            visible: props.showFormVal === "usersSetActiveOrgUser",
            handleCancelCallback: () => {
              console.log("cancelled")
            },
            submitConfig: { hideForm: true },
            modal: { title: mrIntl("UsersList.set_active_org_user"), className: "large-modal", closeIcon: false },
          },
          resource: userToSetActiveOrgUser,
        })}
      </div>
    </React.Fragment>
  );

  return finalRender;
};

export default UsersList;
