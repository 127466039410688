import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Modal,
  Button,
  Tooltip,
  Popover,
  Table,
  InputNumber,
  Space,
  message
} from "antd";
import {
  QuestionCircleOutlined,
  CalculatorOutlined,
  LeftOutlined,
  SwapRightOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useDispatch, useSelector } from "react-redux";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import { experienceUsersCountSelector } from "../selector";
import { PiLessThan } from "react-icons/pi";
import { FaLessThanEqual } from "react-icons/fa6";
import { debounce } from "lodash";

const isDataSourceValid = (dataSource) => {
  console.log("DataSource validation ===>", dataSource);
  const hasError = dataSource.some(item => item.error ?? true);
  return hasError;
}

//  setting input and error for every input on each input value change
//  error with condition that each input should be more than the previous value...
//  ...and less than the next value. making input blank if value is more than 100 or less than 0
const handleDataChange = (prevData, value, index) => {
  return prevData.map((obj, i) => {
    let { error, percentage } = obj;

    if (i === index) {
      percentage = value;
    }

    if ((i === index || error !== null) && i !== 8) {
      const prevPercentage = prevData[i - 1]?.percentage;
      const nextPercentage = prevData[i + 1]?.percentage;

      if (percentage === '' || percentage === null) {
        error = "empty";
      } else if (percentage >= 100) {
        percentage = '';
        error = "max";
      } else if (percentage <= 0) {
        percentage = '';
        error = "min";
      } else if(!Number.isInteger(percentage)) {
        percentage = '';
        error = "whole";
      } else if (i - 1 === index && percentage <= value) {
        error = value ? "small" : false;
      } else if (i + 1 === index && percentage >= value) {
        error = value ? "large" : false;
      } else if (i - 1 !== index && percentage <= prevPercentage) {
        error = prevPercentage ? "small" : false;
      } else if (i + 1 !== index && percentage >= nextPercentage) {
        error = nextPercentage ? "large" : false;
      } else {
        error = false;
      }
    }

    return { percentage, error };
  });
};


const ConvertToAchievementLevel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);  
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const experienceUsersCount = useSelector(experienceUsersCountSelector())
  const isExperienceUsersExist = experienceUsersCount === 0 ? false : true
  const minPercentage = 0;
  const maxPercentage = "100%"

  const mrIntl = useTranslate();
  const dispatch = useDispatch();

  useEffect(() => {
    let updatedDataSource;
    const criteriumAssociation = activeExperience?.criterium_associations?.[0]?.custom_fields?.cwp_al_conversions;

    let dataSource = criteriumAssociation?.type === "percentage" 
      ? criteriumAssociation?.ranges 
      : [];
         
    if (!dataSource || dataSource.length === 0) {
      // Creating a default array containing data for each input of the table.
      updatedDataSource = Array.from({ length: 10 }, (_, i) => {
        if (i === 0) return {percentage: minPercentage, error: false};
        if (i === 9) return {percentage: maxPercentage, error: false};
        return {percentage: '', error: null};
      });
    } else {
      updatedDataSource = dataSource.map((val, index) => ({percentage: val, error: false}))
    }

    updatedDataSource.shift();  // remove 0th index value as not needed in table dataSource
    setData(updatedDataSource);
  }, []);

  const onInputChange = (value, index) => {
    setData((prevData) => {
      const newData = handleDataChange(prevData, value, index);
      return newData;
    });
  };

  const debouncedHandleInputChange = useCallback(
    debounce(onInputChange, 200),
    [data] 
  );


  const columns = [
    {
      title: mrIntl("ConvertToAchievementLevel.overall_criteria_points"),
      dataIndex: "overall_criteria_points",
      key: "overall_criteria_points",
      colSpan: 3,
      className: "overall-criteria-points-column",
      ellipsis: true,
      width: 100
    },
    {
      title: "",
      dataIndex: "arrow_icon",
      key: "arrow_icon",
      render: (a,b,index) => {
        if(index > 0 && index < 8) {
          return <PiLessThan />
        } else if (index === 8) {
          return <FaLessThanEqual />
        }
        return "to less than"
      },
      colSpan: 0,
      className: "arrow-icon-column",
    },
    {
      title: "",
      dataIndex: "percentage_input",
      key: "percentage_input",
      colSpan: 0,
      width: "160px",
      className: "percentage-input-column",
      align: "center"
    },
    {
      title: "",
      dataIndex: "swap_icon",
      key: "swap_icon",
      render: () => <SwapRightOutlined />,
      colSpan: 0,
      align: "left",
      className: "right-outlined-icon-column",
    },
    {
      title: mrIntl("ConvertToAchievementLevel.equivalent_level"),
      dataIndex: "equivalent_level",
      key: "equivalent_level",
      colSpan: 2,
      className: "equivalent-level-column",
      width: 60
    },
  ];
  // using useMemo so that dataSource only change only by change of data 
  const dataSource = useMemo(() => {
    return data.map((obj, index) => {
      const value = obj.percentage
      // const error = validateInput(index, value, data); // handling error for every input here as error is not a state
      const error = obj.error;
      const errorMessages = {
        small: mrIntl("ConvertToAchievementLevel.must_be_more_than_previous_value"),
        large: mrIntl("ConvertToAchievementLevel.must_be_less_than_next_value"),
        empty: mrIntl("ConvertToAchievementLevel.must_not_be_empty"),
        max: mrIntl("ConvertToAchievementLevel.must_be_less_than_the_maximum"),
        min: mrIntl("ConvertToAchievementLevel.must_be_more_than_the_minimum"),
        whole: mrIntl("ConvertToAchievementLevel.enter_a_whole_number")
      };
      const errorMessage = errorMessages[error];
      
      return {
        key: `percentage-calculator-row-${index}`,
        overall_criteria_points: (
          <div className="overall-critera-points">
            <span>{index === 0 ? 0 : data[index - 1].percentage}</span>
          </div>
        ),
        percentage_input: (
          <Space direction="vertical" size="small" className="percentage-input-container">
            <InputNumber
              className={`percentage-input ${error ? "error-bg" : ""}`}
              onChange={(value) => debouncedHandleInputChange(value, index)}
              value={value}
              controls={false}
              disabled={index === 8}
            />
            {error && errorMessage && <span className="error-message">{errorMessage}</span>}
          </Space>
        ),
        equivalent_level: index,
      };
    });
  }, [data]);

  const calculateAchievementLevel = () => {
    const values = data.map(obj => obj.percentage)
    const dataToPost = {
      experience_id: activeExperience.id,
      type: "percentage",
      criteria_boundary: [0, ...values]  //adding 0 back because i need to send in request which i removed in useEffect as it was not needed in table dataSource
    }
    console.log("dataToPost ==>", JSON.stringify(dataToPost));
    showConfirmModal({
      centered: true,
      title: mrIntl("ConvertToAchievementLevel.this_action_will_overwrite_any_existing_overall_achievement_levels_with"),
      cancelText: "Cancel",
      onOk: () => {
        dispatch(
          experienceUserActions.convertToAchievementLevel(dataToPost, {
            successCallback: () => {
              message.success(mrIntl("ConvertToAchievementLevel.achievement_levels_calculated_successfully"));
              setIsModalOpen(false);
            }
          })
        );
      },
      onCancel: () => {},
      mrIntl: mrIntl,
    });
  };
  
  const tooltipTitle = isExperienceUsersExist
    ? mrIntl("ConvertToAchievementLevel.convert_pointbased_scores_into_achievement_levels_on_a_scale_from")
    : "";

  return (
    <>
      <Tooltip
        placement="left"
        title={tooltipTitle}
      >
        <Button
          className="convert-to-achievement-level-button"
          type="text"
          ghost={true}
          onClick={() => setIsModalOpen(true)}
          icon={<CalculatorOutlined />}
          disabled={!isExperienceUsersExist}
        >
          {mrIntl("ConvertToAchievementLevel.convert_to_achievement_level")}
        </Button>
      </Tooltip>
      <Modal
        title={
          <span>
            {mrIntl(
              "ConvertToAchievementLevel.convert_to_achievement_level"
            )}
            <Popover
              content={
                <p className="convert-to-achievement-level-popover">
                  {mrIntl("ConvertToAchievementLevel.this_feature_allows_you_to_convert_overall_criteria_points_expressed")}
                </p>
              }
              trigger="hover"
            >
              <QuestionCircleOutlined style={{ marginLeft: 5 }} />
            </Popover>
          </span>
        }
        open={isModalOpen}
        footer={
          <Button 
            onClick={() => calculateAchievementLevel()} 
            disabled={isDataSourceValid(data)} 
            type="primary"
          >
            {mrIntl("ConvertToAchievementLevel.convert")}
          </Button>
        }
        onCancel={() => setIsModalOpen(false)}
        okText={mrIntl("CommonText.save")}
        wrapClassName="default-percentage-modal-wrapper"
        centered={true}
        getContainer={() => document.body}
      >
        <Table className="percentage-table" dataSource={dataSource} columns={columns} pagination={false} rowHoverable={false} scroll={{y: "70vh"}} />
      </Modal>
    </>
  );
};

export default ConvertToAchievementLevel;