import React, { Suspense, useEffect, useRef } from "react";
import { Route, useRouteMatch } from "react-router-dom";
import SegmentList from "./SegmentList";
import { Button, Card, Empty, Skeleton } from "antd";
import { aiGenerateQuestionsConfigSelector } from "../GenerateAI/selector";
import { useDispatch, useSelector } from "react-redux";
import { actions as aiSegmentActions } from "../GenerateAI/redux";
import { FaRegStopCircle } from "react-icons/fa";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { enabledFeaturesSelector } from "../../Auth/Login/selector";
import RateAiResult from "../GenerateAI/RateAiResult";
import { aiSegmentsSelector } from "../selector";
import { scrollToElementWithOffset } from "/src/lib/utils/helperMethods";
import AddAllAISegmentsButton from "./AddAllAISegmentsButton";
import { isNewGenerateQuestionsWithAI } from "../GenerateAI/generateAIHelperMethods";

const AISegments = React.lazy(() => import("../GenerateAI/AISegments"));

const AISegmentsWrapper = (props) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const addedAiSegments = useRef([]);

  const aiSegments = useSelector(aiSegmentsSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const aiGenerateQuestionsConfig = useSelector(
    aiGenerateQuestionsConfigSelector()
  );
  
  let aiSegmentsLength = aiSegments?.length;

  const {
    questionType,
    sseRef: aiGenerateSSERef,
    loading: aiGenerateLoading,
    extractFromPdf,
  } = aiGenerateQuestionsConfig;

  let newGenerateQuestionsWithAI =
    isNewGenerateQuestionsWithAI(enabledFeatures);
  

  useEffect(() => {
    if (newGenerateQuestionsWithAI && aiGenerateLoading) {
      const loadingElement = document.getElementsByClassName(
        "loading-skeleton-card"
      );
      if (loadingElement && loadingElement.length > 0) {
        scrollToElementWithOffset(loadingElement[0], 80);
      }
    }
  }, [aiGenerateLoading]);

  useEffect(() => {
    if (newGenerateQuestionsWithAI) {
      if (addedAiSegments.current.length > 0) {
        addedAiSegments.current = [];
      }
    }
  }, [questionType]);

  const stopAIQuestionsGeneration = (
    <Button
      danger={true}
      size="medium"
      onClick={() => {
        aiGenerateSSERef.current.abortSse();
        dispatch(
          aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
            data: {
              ...aiGenerateQuestionsConfig,
              loading: false,
            },
          })
        );
      }}
    >
      <span className="generate-ai-stop">
        <FaRegStopCircle className="generate-ai-stop-icon" />
        {mrIntl("CommonText.stop")}
      </span>
    </Button>
  );

  let finalRender = [];
  if (
    (!aiGenerateLoading ||
      (questionType !== "task" && !extractFromPdf)) &&
    aiSegmentsLength
  ) {
    finalRender.push(
      <Route
        path={`${path}`}
        render={(routeProps) => (
          <Suspense fallback={<Skeleton active />}>
            <AISegments
              isSegmentInAIList={true}
              experienceViewMode="apEditTest"
              isRoute={false}
              addedAiSegments={addedAiSegments}
              // activeTopicIdx={activeTopicIdx}
              parentId={null}
              parentIdxs={[]}
              nestingLevel={1}
              {...routeProps}
              config={{
                routes: { show: newGenerateQuestionsWithAI },
                core: {
                  setQueryParams: false, // not setting query params in the url
                },
                pagination: { show: false },
                // itemDetail: { widget: SegmentItemDetail },
                list: {
                  forceReload: true,
                  widget: SegmentList,
                  shouldExecuteRequest: false,
                },
              }}
            />
          </Suspense>
        )}
      />
    );
  }
  if (newGenerateQuestionsWithAI && aiGenerateLoading) {
    finalRender.push(
      <Card
        actions={[stopAIQuestionsGeneration]}
        type="inner"
        className="loading-skeleton-card bg-lavender"
      >
        <Skeleton active />
      </Card>
    );
  }

  const aiSegmentsRender = (
    <div
      className={`ai-genrated-questions ${
        newGenerateQuestionsWithAI ? "bg-lavender" : ""
      }`}
    >
      {aiSegmentsLength ? (
        <div className="ai-generated-questions-header">
          {newGenerateQuestionsWithAI && (
            <h3 className="ai-generated-questions-heading">
              {mrIntl("SegmentList.ai_generated_questions")}
            </h3>
          )}
          {questionType !== "task" &&
            questionType !== "nested_question" && (
              <AddAllAISegmentsButton 
                addedAiSegments={addedAiSegments}
              />
            )}
        </div>
      ) : null}
      {finalRender}
      {newGenerateQuestionsWithAI && !aiGenerateLoading && aiSegmentsLength && (
        <RateAiResult generatedData={aiSegments} action="generate_segments" />
      )}
    </div>
  );
  const EmptyCard = (
    <Card type="inner" className="bg-lavender">
      <Empty
        description={mrIntl(
          "SegmentList.select_filters_on_the_right_and_click_generate_to_see"
        )}
      />
    </Card>
  );

  return newGenerateQuestionsWithAI ? (
    <div className="p-t-10 p-b-10">
      {(aiSegments && aiSegments.length > 0) || aiGenerateLoading
        ? aiSegmentsRender
        : EmptyCard}
    </div>
  ) : (
    aiSegmentsRender
  );
};

export default AISegmentsWrapper;
