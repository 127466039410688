import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Collapse, Drawer, Space, Tooltip } from "antd";
import { ShowText, ShowTextContent } from "/src/components/UI/Segment/UIHelper";
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent";
import { getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import MediaShow from "/src/components/UI/Media/MediaShow";
import ReactPlayer from "react-player";
import TextAnswerExplanation from "./TextAnswerExplanation";
import VideoAnswerExplanation from "./VideoAnswerExplanation";
import PDFAnswerExplanation from "./PDFAnswerExplanation";
import { partnerNameSelector } from "/src/views/Auth/Login/selector";
import { OSCPDFButton } from "/src/components/Partners/OSC/GenericComponents";
import { DownCircleFilled, CloseOutlined } from "@ant-design/icons";
import { OSCVimeoPlayer } from "/src/components/Partners/OSC/OSCVideoPlayer/Video/OSCVimeoPlayer";
import { Route, useRouteMatch } from "react-router-dom";
// import Segments from "../Segments";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { activeAdjustedExperienceSelector, printOptionsSelector } from "/src/views/Experiences/selector";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { EditOutlined } from "@ant-design/icons";
import { experienceUsersSelector } from "../../ExperienceUsers/selector";
import { isArray } from "lodash";
import { SolarTeacherExplanation } from "/src/components/UI/Icons/SolarIcons";

const Segments = React.lazy(() => import('../Segments'));

const AnswerExplanation = (props) => {
  console.log("AnswerExplanation props", props);
  const { Panel } = Collapse;
  const {
    answer_explanation,
    attachments_json = {},
    setRenderMath,
    childrenRenderProps = {},
    explanationViewMode,
    experienceViewMode,
    showChildrenExplanations,
    printMode,
    segmentSettings,
    modal,
    onClose = () => {
      console.log("onClose is not defined")
    },
  } = props;

  const {
    // experienceViewMode,
    experience,
    mobileUploadMode,
    embedded,
    rearrangeMode,
    readingModeEnabled,
    segment,
    nestingLevel,
    libraryModalProps,
  } = childrenRenderProps;

  const { path } = useRouteMatch();
  const partnerName = useSelector(partnerNameSelector());
  const printOptions = useSelector(printOptionsSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  
  const mrIntl = useTranslate();
  const [visible, setVisible] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setVisible(true)
  }, [])

  const openModal = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowModal(true)
  }
  
  let finalRender = [];
  let textAnswerExplanation, videoAnswerExplanation, pdfAnswerExplanation;
  let showMessageForNoExplanation = false
  const showExplanationEditButton = activeExperience.status != "draft" && answer_explanation
  && experienceViewMode === "apEditTest"
  && activeExperience.submissions_count > 0;
  if (attachments_json.pdf_answer_explanation && attachments_json.pdf_answer_explanation.pdfs[0]) {
    if (partnerName === "osc") {
      if(segmentSettings.oscSettings && segmentSettings.oscSettings.shouldShowPDFMarksScheme){
        pdfAnswerExplanation = (
          <OSCPDFButton
            style={{"marginTop": "10px"}}
            url={attachments_json.pdf_answer_explanation.pdfs[0].url}
          ></OSCPDFButton>
        );
      }
    } else {
      pdfAnswerExplanation = (
        <PDFAnswerExplanation
          {...props}
          pdf_answer_explanation={attachments_json.pdf_answer_explanation}
        ></PDFAnswerExplanation>
      );
    }
    finalRender.push(pdfAnswerExplanation);
  }

  if (attachments_json.video_answer_explanation && attachments_json.video_answer_explanation.videos[0]) {
    if (partnerName === "osc") {
      showMessageForNoExplanation = false
      videoAnswerExplanation = (
        <div class={"video-partner-osc"}>
          <OSCVimeoPlayer
            url={attachments_json.video_answer_explanation.videos[0].url}
          />
        </div>
      );
      finalRender.push(<h3 className="video-explanation-header"><MrTranslate id={"AnswerExplanation.video_solution"}/></h3>);
    } else {
      videoAnswerExplanation = (
        <VideoAnswerExplanation
          {...props}
          video_answer_explanation={attachments_json.video_answer_explanation}
        ></VideoAnswerExplanation>
      );
    }
    finalRender.push(videoAnswerExplanation);
  } else {
    if (partnerName === "osc") {
      showMessageForNoExplanation = true
    }
  }

  if ((answer_explanation && !modal) || showModal) {
    showMessageForNoExplanation = false
    textAnswerExplanation = (
      <TextAnswerExplanation
        {...props}
        answer_explanation={answer_explanation}
        showModal={showModal}
        setShowModal={setShowModal}
      ></TextAnswerExplanation>
    );
    if (partnerName === "osc") {
      finalRender.push(<h3 className="text-explanation-header"><MrTranslate id={"AnswerExplanation.step_by_step_solution"}/></h3>);
    } 
    finalRender.push(textAnswerExplanation);
  } else {
    if (partnerName === "osc") {
      showMessageForNoExplanation = true
    }
  }

  if(showMessageForNoExplanation && !showChildrenExplanations){
    finalRender.push(<h3 className="text-explanation-header"><MrTranslate id={"AnswerExplanation.no_solution_available"}/></h3>);
  }

  console.log("showChildrenExplanations ==>", showChildrenExplanations)
  if (partnerName === "osc" && showChildrenExplanations) {
    finalRender.push(
      <Route
        path={`${path}`}
        render={(routeProps) => (
          <Suspense fallback={<Spinner />}>
            <Segments
              experienceViewMode={experienceViewMode}
              experienceProps={{ item: experience }}
              mobileUploadMode={mobileUploadMode}
              embedded={embedded}
              rearrangeMode={rearrangeMode}
              readingModeEnabled={readingModeEnabled}
              topicProps={{ item: segment }}
              parentId={segment.id}
              nestingLevel={nestingLevel + 1}
              params={{
                by_experience_id: segment.experience_id,
                by_parent_id: segment.id,
              }}
              createUpdateParams={{
                experience_id: segment.experience_id,
                parent_id: segment.id,
              }}
              libraryModalProps={libraryModalProps}
              isRoute={false}
              {...routeProps}
              config={{
                list: {
                  shouldExecuteRequest: false,
                },
                //   routes: { fetch: false }, // don't fetch even if empty - in this case, always will be present in redux and getting from selector
              }}
              onlyExplanationMode={true}
            />
          </Suspense>
        )}
      />
    );
  }

  if (explanationViewMode === "drawer") {
    finalRender = (
      <Drawer
        rootClassName={`explanation-drawer ${
          partnerName === "osc" ? "partner-osc" : ""
        }`}
        title={partnerName === "osc" ? "" : mrIntl("CommonText.explanation_title")}
        placement={"bottom"}
        // width="80%"
        height="100%"
        closeIcon={
          partnerName === "osc" ? <DownCircleFilled /> : <CloseOutlined />
        }
        closable={true}
        onClose={() => onClose(false)}
        open={visible}
        // visible={true} // TODO: since this is set to true - no animation - already visible on page - need to toggle open close on click
      >
        <Space direction={"vertical"}>{finalRender}</Space>
      </Drawer>
    );
  } else if (explanationViewMode === "accordion") {
    console.log("answer explanation final render length", explanationViewMode, printMode, printOptions)
    if (finalRender.length) {
      if (printMode) {
        if (printOptions.with_answer_explanation === "true") {
          finalRender = <>{finalRender}</>;
        }
      } else if(!showModal) {
        const panelHeader = <>
          {mrIntl("AnswerExplanation.teachers_explanation")}
          {/* { <Button className={"answer-explanation-edit-button"} type="text" icon={<EditOutlined />} onClick={(e) => openModal(e)} />} */}

        </>
        finalRender = (
          <Collapse
            defaultActiveKey={printMode ? ["1"] : []}
            ghost
            className={'teacher-explanation-accordion'}
          >
            <Panel
              header={panelHeader}
              key="1"
            >
              {finalRender}
            </Panel>
          </Collapse>
        );
      }
    }
  }

  return (
    <>
      {modal ? <Tooltip title={props.answer_explanation ? mrIntl("AnswerExplanation.edit_teachers_explanation") : mrIntl("AnswerExplanation.add_teacher_explanation")}>
        <Button
          type="text"
          shape="circle"
          className={`answer-explanation-button`}
          icon={<SolarTeacherExplanation />}
          onClick={(e) => openModal(e)}
        />
      </Tooltip> : null}
      {finalRender}
    </>
    );
};
export default AnswerExplanation;
