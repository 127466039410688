import React, { useEffect, useState } from 'react';
import "./highlightsMethods.scss";
import { HighlightOutlined } from "@ant-design/icons";
import { Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { actions as experienceActions } from '/src/views/Experiences/redux';
import { experienceViewModeSelector, highlightTextEnabledSelector } from '../Experiences/selector';
import { enabledFeaturesSelector } from '../Auth/Login/selector';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const TextHighlighter = () => {
  const highlightTextEnabled = useSelector(highlightTextEnabledSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const experienceViewMode = useSelector(experienceViewModeSelector());
  
  const mrIntl = useTranslate()
  const newJoinViewWithDynamicConfigEnabled = enabledFeatures.new_join_view_with_dynamic_config
  const dispatch = useDispatch();

  const className = newJoinViewWithDynamicConfigEnabled && highlightTextEnabled ? "highlighter-button" : "";
  const buttonType = newJoinViewWithDynamicConfigEnabled ? "text" : highlightTextEnabled ? 'primary' : 'default';

  const toggleHighlightText = () => {
    dispatch(experienceActions.setHighlightTextSuccess({
      highlightTextEnabled: !highlightTextEnabled
    }));
  }

  return (experienceViewMode === "apPreviewTest" || experienceViewMode === "apTakeTest") ? (
    <div className='text-highlighter-button'>
      <Tooltip placement="left" title={mrIntl("TextHighlighter.text_highlighter")}>
        <Button
          className={className}
          icon={<HighlightOutlined />}
          type={buttonType}
          onClick={toggleHighlightText}
        />
      </Tooltip>
    </div>
  ) : null;
};

export default TextHighlighter;
