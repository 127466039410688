import { Button, Col, Progress, Row, Space, Tooltip } from 'antd';
import Spinner from '/src/components/UI/Spinner/Spinner';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { InfoCircleTwoTone } from "@ant-design/icons";
import React from 'react';
import "/src/views/Groups/BulkImportGroups/BulkImportGroups.scss";

const ImportStatus = ({ loading, bulkImportInfo, singleResourceName, totalDataCount }) => {
  console.log("bulkImportInfo =====>", totalDataCount);
  
  const mrIntl = useTranslate();
  const type = singleResourceName === 'users' ? 'users' : 'classes';
  const remainingDataCount = totalDataCount - bulkImportInfo[`imported_${type}_count`];
  return (
    loading ? <Spinner /> : singleResourceName === 'users' ?
      <Space direction='vertical'>
        <h2>{mrIntl("ImportStatus.import_details")}:</h2>
        <Row>
          <Col span={16}>{mrIntl("ImportStatus.added_users")}:</Col>
          <Col span={8}>{bulkImportInfo[`imported_${type}_count`]}</Col>
        </Row>
        <Row>
          <Col span={20}>{mrIntl("ImportStatus.uploaded_users")}:</Col>
          <Col span={4}>{totalDataCount}</Col>
        </Row>
        <Row>
          <Col span={18}>{mrIntl("ImportStatus.skipped_users")}:</Col>
          <Col span={6}>{remainingDataCount}</Col>
        </Row>
        {/* <Button type="primary">{mrIntl("ImportStatus.download_skipped_users")}</Button> */}
      </Space>
      :
      <Space direction='vertical'>
        <Row>
          {/* <Progress className='classes-progress-bar' size={[300, 20]} percent={60} percentPosition={{ align: 'start', type: 'outer' }} /> */}
        </Row>
        <Row>
          <h3>{mrIntl("ImportStatus.import_details")}:</h3>
        </Row>
        <Row>
          <Col span={14}>{mrIntl("ImportStatus.added")}:</Col>
          <Col span={8}>{bulkImportInfo[`imported_${type}_count`]}</Col>
        </Row>
        <Row>
          <Col span={16}>{mrIntl("ImportStatus.uploaded")}:</Col>
          <Col span={8}>0</Col>
        </Row>
        <Row>
          <Col span={16}>{mrIntl("ImportStatus.skipped")}:
          </Col>
          <Col span={8}>{remainingDataCount} &nbsp;
            {bulkImportInfo?.logs && remainingDataCount > 0 && <Tooltip title={bulkImportInfo?.logs}>
              <InfoCircleTwoTone />
            </Tooltip>}
          </Col>
        </Row>
        {/* <Button type="primary">{mrIntl("ImportStatus.download_skip_file")}</Button> */}
      </Space>
  );
};

export default ImportStatus;