import React, { memo } from "react";
import { useSelector } from "react-redux";
import { generateClassName } from "/src/views/Experiences/ExperienceHelperMethods";
import { activeAdjustedExperienceIdSelector } from "/src/views/Experiences/selector";
import { dbUserResponsesSelector } from "/src/views/UserResponses/selector";
import { activeExperienceUserSelector } from "../selector";
import { MessageOutlined, FileOutlined, CheckCircleOutlined, MinusCircleOutlined, CheckOutlined, CloseCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { BiCommentDetail } from "react-icons/bi";
import { isStringEmpty } from "/src/lib/utils/helperMethods";
import { Space } from "antd";
import { currentUserSelector } from "../../Auth/Login/selector";

const NonMemoizedTopicQuestionTitle = (props) => {
  console.log("NonMemoizedTopicQuestionTitle =======>", props);
  const { segment, questionType, level, titleToRender, onlyQNumberInTree } = props

  const currentUser = useSelector(currentUserSelector());
  const activeAdjustedExperienceId = useSelector(
    activeAdjustedExperienceIdSelector()
  );
  const activeExperienceUser = useSelector(activeExperienceUserSelector());
  const isStudent = currentUser.role === "student" || currentUser.role === "parent" ? true : false
  let dbUserResponse = useSelector(dbUserResponsesSelector(activeAdjustedExperienceId, activeExperienceUser.user_id, segment.id, false, true, activeExperienceUser.id))

  return (
    <Space className={`topic-question-title ${!onlyQNumberInTree ? `p-l-10 ${level > 0 && 'm-l-10'} ${questionType !== "group" ? `m-l-${level + 1}8` : ''}` : ''}`}>
      {/* <span className={`${isStudent ? '' : `question-cell-color-${generateClassName(dbUserResponse, rubricType)} ${dbUserResponse && !dbUserResponse.attempted && "unattempted"}`} ${questionType !== "group" && `m-l-${level + 1}8`} p-l-10 ${level > 0 && "m-l-10"}`}>
      </span> */}
        {!isStudent && !onlyQNumberInTree && <span>
          {dbUserResponse && dbUserResponse.marked ? <CheckCircleOutlined /> : <MinusCircleOutlined />}
        </span>}
        <span>
          {(onlyQNumberInTree ? "Q " : "") + titleToRender}
        </span>
        {!onlyQNumberInTree && <span>
          {/* {dbUserResponse && (!isStringEmpty(dbUserResponse.comments) || (dbUserResponse.custom_fields.media_comments && dbUserResponse.custom_fields.media_comments.attachments.length > 0)) && <BiCommentDetail className="m-l-5 f-s-10" />} */}
          {dbUserResponse && dbUserResponse.attachments_json.attachments && dbUserResponse.attachments_json.attachments.length > 0 && <FileOutlined />}
        </span>}
    </Space>
  )
}
const TopicQuestionTitle = memo(NonMemoizedTopicQuestionTitle);
export default TopicQuestionTitle;