import React, { useEffect, useState } from "react";

import { Radio, Space } from "antd";
import { initialData, removeItemFromArray, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { CheckBox, Segment, SegmentData, CustomLabel } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import ImageView from "./ImageView";
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments"
import { cloneDeep } from 'lodash';
import MediaUI from "/src/components/UI/Media/MediaUI"
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { message } from "/src/components/UI/AntdAppHelper";

const ImageForm = props => {
	console.log("Image Form props ==>", props);
	let { createMode, answerMode, printMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)
	let parsedJSONObject
  const mrIntl = useTranslate();
	if (createMode) {
		parsedJSONObject = props.value || initialData('resource', 1, 4);
	} else {
		parsedJSONObject = props.resource_json
	}

	console.log('Updated data ==>', parsedJSONObject)
	const [state, setState] = useState(parsedJSONObject)
	const [stateUpdatedCount, setStateUpdatedCount] = useState(0)

	const mediaFormInitialState = {
		modal: false,
		loading: false,
		visible: false,
		title: mrIntl("CommonText.insert_images"),
		okText: mrIntl("CommonText.add_images_button"),
		cancelText: mrIntl("CommonText.cancel"),
		triggerSaveAction: 0,
		requireFooter: false,
		content_type: 'image',
		supportedTypes: 'image/jpg, image/jpeg, image/png, image/gif, image/bmp',
		supportedFormats: 'jpg, jpeg, png, gif, bmp',
		selectConfig: {
			maxSize: "20 MB"
		},
	}
	const [mediaFormSetting, setMediaFormSetting] = useState(mediaFormInitialState)

	let currentJSON
	if (presentationMode) {
		currentJSON = parsedJSONObject
	} else {
		currentJSON = state
	}
	const segment_data = currentJSON?.segment_data || {}
	const resource_setting = currentJSON.resource_setting || { view_type: 'tabular' }
	const attachments = segment_data.attachments || []
	const attachmentsCount = attachments.length

	useEffect(() => {
		setMediaFormSetting({
			...mediaFormSetting,
			modal: attachmentsCount > 0,
			visible: false,
		})
	}, [attachmentsCount])

	useEffect(() => {
		if (createMode) {
			if (props.onChange) {
				let resource_json = cloneDeep(state)
				props.onChange(resource_json)
			}
		}
	}, [stateUpdatedCount]);

	const saveState = (state) => {
		setState(state)
		console.log("stateUpdated count ==>", stateUpdatedCount)
		setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
	}

	const onTabDragEnd = (attachments) => {
		const newAttachments = attachments.slice()

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const removeItem = (index) => {
		const extra = {
			noMinSize: true,
			withConfirmation: true,
		}
		const newAttachments = removeItemFromArray(attachments, index, extra)
		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}
		saveState(newState)
	}

	const removeFile = (index) => {
		const newAttachments = attachments
    newAttachments[index] = { ...newAttachments[index], files: [] };

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const onSaveMedia = (files) => {
		console.log('Files and attachmetns ==>', attachments, files)
    // If the user clicks the Add button without selecting a file, a message is shown
    if (files.length === 0) {
      message.error(mrIntl("CommonText.at_least_one_file_required"));
      return
    }

		const newAttachments = attachments.concat(files)

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log('ON save image ==>', newAttachments, newState)

		saveState(newState)
		setMediaFormSetting({
			...mediaFormSetting,
			from: '',
			modal: true,
			visible: false,
			triggerSaveAction: 0,
		})
	}

	const onTextChanged = (type, index, data) => {
		const newAttachments = attachments
		newAttachments[index][type] = data

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log("onchange is getting fired ==>", newState, data)
		saveState(newState)
	}

	const setResourceSetting = (key, value) => {
		const newResourceSetting = {
			...resource_setting,
			[key]: value
		}

		const newState = {
			...state,
			resource_setting: newResourceSetting
		}

		saveState(newState)
	}

	const onTabEdit = (index, action) => {
		if (action == 'add') {
			setMediaFormSetting({
				...mediaFormSetting,
				from: 'computer',
				modal: true,
				visible: true,
			})
		} else if (action == 'remove') {
			removeItem(index)
		}
	}

	const handleTabPositionChange = (e) => {
		setResourceSetting('tab_position', e.target.value)
	}

	const handleViewTypeChange = (e) => {
		setResourceSetting('view_type', e.target.value)
	}

	const handleAutoSlide = (e) => {
		setResourceSetting('auto_slide', !resource_setting.auto_slide)
	}

	const getMediaForm = () => {

		return (
      <MediaUI
        computer={true}
        url={true}
        drive={true}
        mediaFormSetting={mediaFormSetting}
        setMediaFormSetting={setMediaFormSetting}
        onSaveMedia={onSaveMedia}
      ></MediaUI>
    );
	}

	console.log("Image form state before render ==>", state, mediaFormSetting)
	return (
		<Segment>
			<SegmentData>
				{createMode && getMediaForm()}

				{attachmentsCount > 0 && <div>
					{/* {createMode && <Radio.Group onChange={(e) => handleTabPositionChange(e)} value={resource_setting.tabPosition} style={{ margin: '20px' }} buttonStyle="solid">
					<Radio.Button value="top">Horizontal</Radio.Button>
					<Radio.Button value="left">Vertical</Radio.Button>
				</Radio.Group>} */}


					{/* <Space>
						{createMode && <Radio.Group onChange={(e) => handleViewTypeChange(e)} value={resource_setting.view_type} style={{ margin: '20px' }} buttonStyle="solid">
							<Space>
								<Radio.Button value="tabular">Tabular</Radio.Button>
								<Radio.Button value="slider">Slider</Radio.Button>
							</Space>
						</Radio.Group>}

						{createMode && resource_setting.view_type == 'slider' && <CheckBox
							checked={resource_setting.auto_slide}
							onClick={(e) => handleAutoSlide()}
						>
							Auto Slide
					</CheckBox>}
					</Space> */}

					{createMode
						? <TabularAttachments
							key={`tabular-attachments-${props.unique_segment_identifier}`}
							createMode={createMode}
							attachmentType="image"
							tabPrefix="Image"
							titleRequired={true}
							attachments={attachments}
							resource_setting={resource_setting}
							onTabDragEnd={onTabDragEnd}
							removeFile={removeFile}
							onTabEdit={onTabEdit}
							onTextChanged={onTextChanged}
						></TabularAttachments>
						: <ImageView
							printMode={printMode}
							resource_json={state}
						></ImageView>
					}

					{createMode && attachmentsCount > 1 &&
						<div>
							<CustomLabel key="image-layout-text" style={{ "fontWeight": "700", "display": "block", "margin": "0" }}><MrTranslate id={"ImageForm.layout_button"}/></CustomLabel>

							<Space>
								<Radio.Group onChange={(e) => handleViewTypeChange(e)} value={resource_setting.view_type} style={{ margin: '20px' }} buttonStyle="solid">
									<Space>
										<Radio.Button value="tabular"><MrTranslate id={"ImageForm.tabular"}/></Radio.Button>
										<Radio.Button value="slider"><MrTranslate id={"ImageForm.slider"}/></Radio.Button>
									</Space>
								</Radio.Group>

								{resource_setting.view_type == 'slider' &&
									<CheckBox
										checked={resource_setting.auto_slide}
										onClick={(e) => handleAutoSlide()}
									>
										<MrTranslate id={"ImageForm.auto_slide"}/>
									</CheckBox>}
							</Space>
						</div>}
				</div>}
			</SegmentData>
		</Segment >
	);
}
export default ImageForm;