import Immutable, { fromJS } from "immutable";
import { call, put, takeEvery, select, delay, take } from "redux-saga/effects";
import { message } from "/src/components/UI/AntdAppHelper";
import { MrReduxCrud, formatJsonApiData } from "mr_react_framework";
import axios from "axios";
import get from "lodash/get";
import { axiosInstance } from "/src/api/apiModule";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { firebaseAuth } from "/src/config/initializers";
import { appRegionSelector, currentUserSelector } from "./selector";
import * as Sentry from "@sentry/react";
// import { formatJsonApiData } from "../../../lib/utils/helperMethods";
import { checkMob, getMobileOperatingSystem, getOS, getBrowser, openUrl, getBrowserName, getIpClient } from "/src/lib/utils/helperMethods";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { getAppVersion, isNewAppVersion } from "/src/App/OfflineApp/offlineAppHelper";
// import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";


// export const axiosInstance = makeAxiosInstance();
// console.log("axiosInstance", axiosInstance);
const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "login",
  message,
  actionNames: ["GOOGLE_CREATE", "MICROSOFT_CREATE", "MB_CREATE", "SWITCH_IDENTITY", "CREATE", "LOGOUT", "CHECK_AUTH_STATE", "VERIFY_JWT", "INIT_APP", "SET_EMBEDDED", "SET_PARTNER_NAME", "SET_OSC_ACCOUNT_TYPE", "ORG", "SEB_CONFIG_FILE", "FORGOTPASSWORD", "RESETPASSWORD", "SIGNUP", "GET_SERVICES", "LOGOUT_FROM_MB", "SET_USER_IP", "SET_GOOGLE_DRIVE_ACCESS_TOKEN", "SET_APP_THEME", "OVERRIDE_ORG_FEATURES", "UPDATE_CURRENT_USER"],
  resourceUrl: "sessions",
});

const initialState = Immutable.Map({
  appType: "", // Now, set the default appType value to an empty string "" instead of "web". This change is necessary because we are currently getting the default appType as "web" before the auto-update logic in ExperienceLayout (Mac app v11.0.0). We do not want to load the test during the auto-update process. This update discussion with @uttam regarding APL-2857 and APL-4381. 
  region: import.meta.env.VITE_REGION || "global",
  token: null,
  userId: null,
  error: null,
  loading: false,
  authRedirectPath: "/",
  currentUser: {},
  googleloading: false,
  preferences: {},
  permission: {},
  embedded: false, // for prod
  // embedded: true // for dev testing
});

const forgotPasswordFunc = (state = initialState, action) => {
  return state;
}

export function* forgotpasswordSaga(action) {
  yield put(actions.forgotpasswordStart());
  const forgotpasswordData = {
    user: {
      email: action.payload.email,
    },
  };
  let url = "passwords.json";
  try {
    const response = yield axiosInstance.instance.post(url, forgotpasswordData);
    // yield localStorage.setItem("token", response.data.token);
    console.log( "forgotpass response", response );
    yield put(actions.forgotpasswordSuccess(response.data, action.options || {}));
  } catch (error) {
    console.log( "forgotpass error", error );
    yield put(actions.forgotpasswordFail({error: error.response.data.message}));
  }
}

export function* resetpasswordSaga(action) {
  yield put(actions.resetpasswordStart());
  const resetpasswordData = {
    
    user: {
      ...action.payload
      // token: 
      // password: action.payload.password,
      // confirm_password: 
    },
  };
  let url = "passwords.json";
  try {
    const response = yield axiosInstance.instance.put(url, resetpasswordData);
    // yield localStorage.setItem("token", response.data.token);
    console.log( "forgotpass response", response );
    yield put(actions.resetpasswordSuccess(response.data, action.options));
    
    yield put(actions.createSuccess({data: {user: response.data.user, token: response.data.token}}))
    // to login just use this
  } catch (error) {
    console.log( "forgotpass error", error );
    yield put(actions.resetpasswordFail({error: error.response.data.message}));
  }
}

// yield takeEvery(actionTypes., forgotPassSaga);

export function* signupSaga(action) {
  yield put(actions.signupStart());
  const signupData = {
    
    user: {
      ...action.payload
      // token: 
      // password: action.payload.password,
      // confirm_password: 
    },
  };
  let url = "registrations.json";
  try {
    const response = yield axiosInstance.instance.post(url, signupData);
    // yield localStorage.setItem("token", response.data.token);
    console.log( "signup response", response );
    yield put(actions.signupSuccess(response.data, action.options));
    
    yield put(actions.createSuccess({data: {user: response.data.user, token: response.data.token}}))
    // to login just use this
  } catch (error) {
    console.log( "signup error", error );
    yield put(actions.signupFail({error: error.response.data.message}));
  }
}

const successFunc = (state = initialState, action) => {
  // console.log("action data ==>", action);
  try {
    let appType = "web";
    let appValid = true;
    console.log( "successfun action", action );
    if(window.downloadHelper){
      appType = "win";
    } else if(window.webkit){
      // To do with custom Suffix in userAgent like SEB. current condition is setting appType to mac for ipad also
      // appType = "mac";
    }

    console.log("successFunc action", action);
    let payload = action.payload || {};
    // let currentUser = payload.data.user;
    let currentUser = formatJsonApiData(payload.data?.user?.data);
    console.log("currentUser", currentUser);
    let newPermission, newPreference;
    let isSebPresent = payload.data.is_seb_present;
    let isSebValid = payload.data.is_seb_valid || false;
    if(isSebPresent){
      appType = "seb";
      appValid = isSebValid;
    }
    // try {
    //   newPermission = {
    //     ...JSON.parse(currentUser.permission),
    //   };
    // } catch (error) {
    //   console.log( "error", error );
    //   newPermission;
    // }
    // newPermission = {
    //   ...JSON.parse(currentUser.permission.settings)
    // };
    // currentUser.permission = newPermission;
    // newPreference = {
    //   ...JSON.parse(currentUser.preference.settings)
    // };
    // currentUser.preference = newPreference;
    axiosInstance.resetInstance(payload.data.token);
    localStorage.setItem("token", payload.data.token);
    let newState = state
      .set("currentUser", currentUser)
      .set("token", payload.data.token)
      .set("permission", currentUser.permission)
      // .set("userId", payload.data.user.id)
      .set("org", currentUser.org)
      // .set("company", payload.data.company)
      .set("loading", false)
      .set("googleloading", false)
      .set("microsoftloading", false)
      // TEMP
      // .set("appType", appType)
      // .set("appValid", appValid);
    
    Sentry.setUser({ 
      id: currentUser.id,
      username: currentUser.name,
      email: currentUser.email,
      role: currentUser.role,
      org: currentUser.org && currentUser.org.name, 
    });

    return newState;
  } catch (error) {
    console.log("Sentry capture", error);
    Sentry.captureException(error);
  }
};

const logoutFunc = (state = initialState, action) => {
  localStorage.setItem("activeOrgProgrammeId", null);
  // As reset instance also resetting appType - so saving it before reset
  const appType = state.get("appType");
  axiosInstance.resetInstance();
  return state
    .set("token", null)
    .set("userId", null)
    .set("currentUser", {})
    .set("permission", {})
    .set("org", {})
    .set("error", null)
    .set("loading", false)
    .set("appType", appType);
};

const overrideOrgFeaturesSuccess = (state = initialState, action) => {
  const currentUser = state.get("currentUser");
  // If guest user, overriding org enabled_features and in current_user with exp_org_enabled_features - so don't have to rely on exp settings
  if (currentUser?.custom_fields?.account_type === "guest" && action.payload.exp_org_enabled_features) {
    const org = currentUser.org;
    const updatedOrg = {
      ...org,
      enabled_features: action.payload.exp_org_enabled_features,
    };

    return state.set("currentUser", {
      ...currentUser,
      org: updatedOrg,
    }).set("org", updatedOrg);
  }

  return state;
};

export function* checkAuthStateSaga(action) {
  const token = localStorage.getItem("token");
  // console.log("checkAuthStateSaga window", window.location)

  // moved here also from Authlayout - this checks after auth
  let appType = "web";
  let appValid = true;
  if(window.downloadHelper){
    appType = "win";
  } else if(window.webkit){
    // To do with custom Suffix in userAgent like SEB. current condition is setting appType to mac for ipad also
    // appType = "mac";
  }
  console.log( "appType 87", appType );
  console.log( "actions 87", actions );
  // yield put(actions.initAppSuccess({appType}));
  // yield put(offlineAppActions.executeMethod({key: "first mac keys", value: "first mac value"}));

  // setup windows app
  if (!token) {
    yield put(actions.logout());
  } else {
    axiosInstance.resetInstance(token);
    // const loginAxiosInstance = makeAxiosInstance();
    let url = "get_user.json";
    try {
      // const response = yield axiosInstance.get(url);
      const appRegion = yield select(appRegionSelector())
      yield put(actions.createStart());
      yield put({
        type: "LOGIN_CREATE_START",
      });
      const response = yield axiosInstance.instance.get(url);
      console.log("response", response);
      let formattedUserData = formatJsonApiData(response.data.user.data);
      // sending a new token each time now from BE so updating here on page refresh
      let newToken = response.data.token || token;
      let isSebPresent = response.data.is_seb_present;
      let isSebValid = response.data.is_seb_valid || false;
      if(isSebPresent){
        appType = "seb";
        appValid = isSebValid;
      }
      let unformattedUserData = response.data.user;
      let payload = {
        data: {
          user: {
            ...unformattedUserData,
            token: newToken,
          },
          // org: unformattedUserData.org,
          token: newToken,
        },
      };

      yield put(actions.createSuccess(payload));
      const appVersion = getAppVersion(appType);
      // We have implemented native sentry SDK in mac app version v11.0.0
      try {
        if((appType === "mac" && isNewAppVersion(appVersion, "11.0.0")) || (appType === "ios" && isNewAppVersion(appVersion, "3.2.0"))){
          let currentUser = formatJsonApiData(payload.data?.user?.data);
          const sentryUserObj = { 
            id: currentUser.id,
            username: currentUser.name,
            email: currentUser.email,
            role: currentUser.role,
            org: currentUser.org && currentUser.org.name, 
          }
          const sentryUserStr = JSON.stringify(sentryUserObj);
          yield put(
            offlineAppActions.executeMethod({
              key: "setSentryUser",
              value: sentryUserStr,
            })
          );
        }
      } catch (error) {
        Sentry.captureException(error);
        console.log("setSentryUser error")
      }
      // TODO: move ip set to login CreateSuccess so can be used after verifyJwt also
      if(appRegion !== "china") {
        const userIp = yield getIpClient()
        // .then((data) => {
        //   console.log("checking the ip==>", data);
        // })
        // .catch((error) => {
        //   console.log("error in setting ip");
        // });
        console.log("userIp ==>", userIp)
        yield put(actions.setUserIpSuccess({ ip: userIp }));
      }
    } catch (error) {
      console.log( "auth error", error );
      console.log("Sentry capture", error);
      Sentry.captureException(error);
      yield put(actions.logout());
      // yield put(actions.createFail());
      yield put({
        type: "LOGIN_CREATE_FAIL",
      });
    }
  }

  // TEMP
  // yield put(actions.initAppSuccess({appType, appValid}));

}

function initAppSuccess(state = initialState, action) {
  let newState = state.set("appType", action.payload.appType);
  if(typeof(action.payload.appValid) !== "undefined"){
    newState = newState.set("appValid", action.payload.appValid);
  }
  return newState;
}

function setEmbeddedSuccess(state = initialState, action) {
  return state.set("embedded", action.payload.embedded);
}

function setPartnerNameSuccess(state = initialState, action) {
  return state.set("partnerName", action.payload.partnerName);
}

function setOscAccountTypeSuccess(state = initialState, action) {
  return state.set("oscAccountType", action.payload.oscAccountType);
}

function orgSuccess(state = initialState, action) {
  console.log( "orgSuccess action", action );
  const org = formatJsonApiData(action.payload.org);
  const existingUser = state.get("currentUser");
  console.log( "orgSuccess org", org );
  const updatedUser = {
    ...existingUser,
    org: {
      ...org
    }
  }
  return state.set("org", org).set("currentUser",updatedUser);
  // return state.set("org", )
  // return state.set("embedded", action.payload.embedded);
}

function sebConfigFileSuccess(state = initialState, action) {
  console.log( "sebConfigFileSuccess action", action );
  return state.set("seb_config_file", action.payload.url).set("sebloading", false)
}

function getServicesSuccess(state = initialState, action) {
  console.log( "getServicesSuccess action", action.payload.mb_accounts_portal_linked_data );
  return state.set("mb_linked_data", action.payload.mb_accounts_portal_linked_data)
}
// export function* verifyJwtSaga(action) {
//   yield put(actions.verifyJwtStart());
//   const verifyJwtData = {
//     jwt: action.payload.jwt,
//     client: action.payload.client
//   };
//   let url = "verify_jwt.json";
//   try {
//     const response = yield axiosInstance.instance.post(url, verifyJwtData);
//     // yield localStorage.setItem("token", response.data.token);
//     yield put(actions.createSuccess(response))
//     yield put(actions.verifyJwtSuccess(response.data));
//   } catch (error) {
//     yield put(actions.verifyJwtFail(error.response.data.error));
//   }
// }

export function* verifyJwtSaga(action) {
  yield put(actions.verifyJwtStart());
  const verifyJwtData = {
    jwt: action.payload.jwt,
    client: action.payload.client,
    partner: action.payload.client,
  };
  if (action.payload.expUUID) {
    // optional
    verifyJwtData.exp_uuid = action.payload.expUUID;
  }
  let url = "verify_jwt.json";
  try {
    const response = yield axiosInstance.instance.post(url, verifyJwtData);
    // console.log("verifyJwt response", response);
    // yield localStorage.setItem("token", response.data.token);
    if (response?.data?.status === 403 || response?.data?.status === 500) {
      // Invalid JWT error case
      throw new Error(response?.data?.error);
    }

    if (response?.data?.redirect_to_exp_uuid) {
      console.log("double-render-check redirect_to_exp_uuid", response?.data?.redirect_to_exp_uuid);
      // special case for redirecting to new exp - used in pamoja bank test link case
      // Only setting token in local storage - not in redux state - after redirection, checkAuthState will be called and set token and other params in state as default
      // Directly logging in user and then redirecting to new exp was causing double render issue - isAuthenticated was being set to true before redirect trigger and was showing 1st test and then redirecting to new test
      localStorage.setItem("token", response.data.token);
      const newUUID = response.data.redirect_to_exp_uuid;
      const currentURL = window.location.href;
      const updatedURL = currentURL.replace(/\/tests\/[^/]+/, `/tests/${newUUID}`);
      // console.log("verifyJwt redirecting to new exp", newUUID, currentURL, updatedURL);

      console.log("double-render-check redirecting now");
      window.location.href = updatedURL;
    } else {
      // default case
      yield put(actions.createSuccess(response))
      console.log("double-render-check verifyJwtSuccess - logging in user");
      
      yield put(actions.verifyJwtSuccess({data: response.data}));
      console.log("double-render-check redirect check");
    }

  } catch (error) {
    console.error(error);
    console.log("Sentry capture", error);
    Sentry.captureException(error);
    yield put(actions.verifyJwtFail({error: error.response?.data?.error || error}));
    yield put(actions.logout({shouldRedirectToHomePage: true}))
  }
}


// function logoutMBAccountsPortal(){
//   axios.delete(
//     import.meta.env.VITE_MB_ACCOUNTS_URL + "/accounts/sign-out",
//   )
// }

export function* logoutFromMbSaga() {
  console.log('logging out from mb==>')
  const appType = yield select(appTypeSelector());
  let browserName = getBrowserName();
  const appVersion = getAppVersion(appType);

  try {
    if (appType === "seb" || (appType === "web" && browserName !== "Safari")) {
      var formData = new FormData();
      formData.append("_method", "delete");
      axios({
        method: "post",
        url: import.meta.env.VITE_MB_ACCOUNTS_URL + "/accounts/sign-out",
        data: formData,
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log("Logout of MB accounts success", response);
        })
        .catch(function (response) {
          //handle error
          console.log("Logout of MB accounts error", response);
        });
    } else if ((appType === "mac" && isNewAppVersion(appVersion,"9.0.1")) || (appType === "ios" && isNewAppVersion(appVersion,"2.0.5"))) { 
      yield put(
        offlineAppActions.executeMethod({
          key: "clearCacheWithSession",
          value: true,
        })
      );
    } else if (appType === "web" && browserName === "Safari") {
      const title = "Logout window";
      let logoutWindow = window.open(
        "about:blank",
        title,
        "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none"
      );
      // window.focus();

      let form = document.createElement("form");
      form.action =
        import.meta.env.VITE_MB_ACCOUNTS_URL + "/accounts/sign-out";
      form.method = "post";
      form.style.display = "none";

      let methodInput = document.createElement("input");
      methodInput.type = "hidden";
      methodInput.name = "_method";
      methodInput.value = "delete";

      let submitInput = document.createElement("input");
      submitInput.type = "submit";
      submitInput.value = "Logout";

      form.appendChild(methodInput);
      form.appendChild(submitInput);
      logoutWindow.document.body.appendChild(form);

      form.submit();
      setTimeout(() => {
        logoutWindow.close();
      }, 1000);
    }

    // Sergiy example:
    // const form = new FormData();
    // form.set('_method', 'delete');
    // fetch(import.meta.env.VITE_MB_ACCOUNTS_URL + "/accounts/sign-out", { method: 'POST', body: form, credentials: 'include' });

    //can call this fn for mac and ios app - to clear cookies - if needed. MB logout got solved by MB post request (works everywhere - desktop apps, web) otherwise this was alternate method for mac and ios atleast
    //yield put(offlineAppActions.executeMethod({key: "clearCache", value: "true"}));
    // }

    // console.log( "window.location", window.location );
    // if(window.location.pathname !== "/auth/login"){
    //   window.location.reload();
  } catch (error) {
    console.log("error when logging out from managebac", error);
  }
}


export function* logoutLoginSaga(action) {
  try {
    const {shouldDispatchChangeSecurity, shouldRedirectToHomePage, preserveOriginalUserRole} = action.payload || {}
    const currentUser = yield select(currentUserSelector());
    const appType = yield select(appTypeSelector());
    let browserName = getBrowserName();
    const appVersion = getAppVersion(appType);
    
    let isMb = currentUser && currentUser.org && currentUser.org.is_mb;
    // console.log("logout saga==>", import.meta.env.VITE_MB_ACCOUNTS_URL)
    const shouldDispatchCloseWindow = (appType === "mac" && currentUser.role === "student" && isNewAppVersion(appVersion, "10.1.0"))
    let executeMethods = [];
      if (shouldDispatchChangeSecurity) {
        executeMethods.push({
          key: "changeSecurity",
          value: false,
        });
      }
       
      shouldDispatchCloseWindow && executeMethods.push(
        { key: "closeAllChildWindow", value: true },
        { key: "closeAllNewWindow", value: true }
      )

      if(executeMethods.length > 0){
        yield put(offlineAppActions.executeMethod(executeMethods))
        yield delay(1000);
      } 
  

    
    if (!preserveOriginalUserRole) {
      const originlUserRole = localStorage.getItem("originalUserRole");
      if (originlUserRole != null) {
        localStorage.setItem("originalUserRole", null);
      }
    }
    // yield put(firestoreInteractionActions.setLogs({
    //   logging: true,
    //   log_type: "user",
    //   category: "user_access",
    //   msg:  `User ${currentUser.name} (${currentUser.role}) logged out of AssessPrep.`,
    // }))
    const lastSignInFrom = currentUser?.custom_fields?.last_sign_in_from;

    yield localStorage.removeItem("token");
    yield put(actions.logoutSuccess());
    const appRegion = yield select(appRegionSelector());
    if (appRegion !== "china") {
      yield firebaseAuth.signOut();
      // TODO: add supabase sign out
    }     

    // Logout of MB accounts portal also - if MB user
    if (isMb) {
      yield put(actions.logoutFromMb());
      // if (appType === "seb" || (appType === "web" && browserName !== "Safari")) {
      //   var formData = new FormData();
      //   formData.append("_method", "delete");
      //   axios({
      //     method: "post",
      //     url: import.meta.env.VITE_MB_ACCOUNTS_URL + "/accounts/sign-out",
      //     data: formData,
      //     withCredentials: true,
      //     headers: { "Content-Type": "multipart/form-data" },
      //   })
      //     .then(function (response) {
      //       //handle success
      //       console.log("Logout of MB accounts success", response);
      //     })
      //     .catch(function (response) {
      //       //handle error
      //       console.log("Logout of MB accounts error", response);
      //     });
      // } else if (appType === "mac" || appType === "ios") {
      //   yield put(
      //     offlineAppActions.executeMethod({
      //       key: "clearCacheWithSession",
      //       value: true,
      //     })
      //   );
      // } else if (appType === "web" && browserName === "Safari") {
      //   const title = "Logout window";
      //   let logoutWindow = window.open(
      //     "about:blank",
      //     title,
      //     "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none"
      //   );
      //   // window.focus();
  
      //   let form = document.createElement("form");
      //   form.action =
      //     import.meta.env.VITE_MB_ACCOUNTS_URL + "/accounts/sign-out";
      //   form.method = "post";
      //   form.style.display = "none";
  
      //   let methodInput = document.createElement("input");
      //   methodInput.type = "hidden";
      //   methodInput.name = "_method";
      //   methodInput.value = "delete";
  
      //   let submitInput = document.createElement("input");
      //   submitInput.type = "submit";
      //   submitInput.value = "Logout";
  
      //   form.appendChild(methodInput);
      //   form.appendChild(submitInput);
      //   logoutWindow.document.body.appendChild(form);
  
      //   form.submit();
      //   setTimeout(() => {
      //     logoutWindow.close();
      //   }, 1000);
      // }
  
      // // Sergiy example:
      // // const form = new FormData();
      // // form.set('_method', 'delete');
      // // fetch(import.meta.env.VITE_MB_ACCOUNTS_URL + "/accounts/sign-out", { method: 'POST', body: form, credentials: 'include' });
  
      // //can call this fn for mac and ios app - to clear cookies - if needed. MB logout got solved by MB post request (works everywhere - desktop apps, web) otherwise this was alternate method for mac and ios atleast
      // //yield put(offlineAppActions.executeMethod({key: "clearCache", value: "true"}));
      // // }
  
      // // console.log( "window.location", window.location );
      // // if(window.location.pathname !== "/auth/login"){
      // //   window.location.reload();
    }
    if(shouldRedirectToHomePage === true || lastSignInFrom === "microsoft"){
      yield call(openUrl, "/");
    }
   
  } catch (e) {
    console.log("e===========>", e);
    yield put(actions.logoutFail());
  }
}

// function* logoutSaga(action) {
//   yield put(actions.logoutSuccess());
// }

export function* getServicesSaga(action) {
  try {
    const currentUser = yield select(currentUserSelector());
    let mbAccountUid = currentUser && currentUser.custom_fields && currentUser.custom_fields.mb_account_uid

    // console.log("getServicesSaga==>", import.meta.env.VITE_MB_ACCOUNTS_URL)
    if (import.meta.env.VITE_MODE == "development" || mbAccountUid) {
      yield put(actions.getServicesStart());
      const response = yield axiosInstance.instance.get("/get_mb_accounts_portal_linked_data");
      console.log("getServices response", response.data);
      console.log("getServicesSuccess ====> ", actions);
      yield put(actions.getServicesSuccess(response.data))
    }
  }
  catch (e) {
    console.log("getServicesSaga fail e", e.response.data.error);
    // let errorMsg = e.response.data.error
    // yield put(actions.getServicesFail({error: errorMsg}));
  }
}

export function* switchIdentitySaga(action) {
  // localStorage.setItem("activeOrgProgrammeId", null)
  yield put(actions.switchIdentityStart());
  const switchIdentityData = {
    email: action.payload.email,
    user_uuid: action.payload.user_uuid,
  };
  let url = "switch_identity.json";
  try {
    const response = yield axiosInstance.instance.post(url, switchIdentityData);
    // yield localStorage.setItem("token", response.data.token);
    yield put(actions.logout({preserveOriginalUserRole: true}))

    // Wait for logout to complete when logout_login_success is dispatched then we call createSuccess and switchIdentitySuccess, regin is when we don't add delay then createSuccess is called before LOGOUT_LOGIN_SUCCESS and then we get error in createSuccess
    // When we call setLogs in login saga then it is take longer time to call logout and then createSuccess is called before LOGOUT_LOGIN_SUCCESS and then we get error in createSuccess
    yield take(['LOGOUT_LOGIN_FAIL', 'LOGOUT_LOGIN_SUCCESS']);
    yield put(actions.createSuccess(response));
    yield put(actions.switchIdentitySuccess({ data: response.data }));
    yield put(openUrl("/"))
  } catch (error) {
    console.error( "error", error );
    let errorMsg = get(error, "response.data.error") || "Something went wrong";
    yield put(actions.switchIdentityFail({error: errorMsg}));
  }
}


export function* sebConfigFileSaga(action) {
  yield put(actions.sebConfigFileStart());
  const os = checkMob() ? getMobileOperatingSystem() : getOS()
  const currentUser = yield select(currentUserSelector());
  
  let url = "get_seb_config_file.json"
  url += `?os=${os}`
  if(currentUser && currentUser.org_id){
    url += `&org_id=${currentUser.org_id}`
  }
  if(action && action.payload && action.payload.exp_id){
    url += `&exp_id=${action.payload.exp_id}`
  }
  if(action && action.payload && action.payload.subject_label){
    url += `&subject_label=${action.payload.subject_label}`
  }
  // const sebconfigFileData = {
  //   os: checkMob() ? getMobileOperatingSystem() : getOS(),
  // };
  console.log("sebConfigFile calling", action, os)
  try {
    const response = yield axiosInstance.instance.get(url);
    console.log("sebConfigFile response", response)
    if(response && response.data.url){
      yield put(actions.sebConfigFileSuccess({url: response.data.url}));
      if(action.options && action.options.successCallback){
        action.options.successCallback(response.data.url)
      }
    }
    
  } catch (error) {
    console.error("sebConfigFile error", error)
    message.error('Something went wrong in getting config file');
    yield put(actions.sebConfigFileFail({error}));
  }
}

const setUserIpSuccess = (state = initialState, action) => {
  console.log("setUserIpSuccess action", action);
  return state.set("userIp", action.payload.ip);
}

const setGoogleDriveAccessTokenSuccess = (state = initialState, action) => {
  return state.set("googleDriveAccessToken", action.payload.accessToken);
}

const setAppThemeSuccess = (state = initialState, action) => {
  return state.set("appTheme", action.payload.appTheme);
}

const updateCurrentUserSuccess = (state = initialState, action) => {
  return state.set("currentUser", action.payload.currentUser);
}


export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  CREATE_LOGIN_SUCCESS: successFunc,
  GOOGLE_CREATE_LOGIN_SUCCESS: successFunc,
  MICROSOFT_CREATE_LOGIN_SUCCESS: successFunc,
  LOGOUT_LOGIN_SUCCESS: logoutFunc,
  OVERRIDE_ORG_FEATURES_LOGIN_SUCCESS: overrideOrgFeaturesSuccess,
  [actionTypes.INIT_APP_LOGIN_SUCCESS]: initAppSuccess,
  [actionTypes.SET_EMBEDDED_LOGIN_SUCCESS]: setEmbeddedSuccess,
  [actionTypes.SET_PARTNER_NAME_LOGIN_SUCCESS]: setPartnerNameSuccess,
  [actionTypes.SET_OSC_ACCOUNT_TYPE_LOGIN_SUCCESS]: setOscAccountTypeSuccess,
  [actionTypes.ORG_LOGIN_SUCCESS]: orgSuccess,
  [actionTypes.SEB_CONFIG_FILE_LOGIN_SUCCESS]: sebConfigFileSuccess,
  [actionTypes.GET_SERVICES_LOGIN_SUCCESS]: getServicesSuccess,
  [actionTypes.SET_USER_IP_LOGIN_SUCCESS]: setUserIpSuccess,
  [actionTypes.SET_GOOGLE_DRIVE_ACCESS_TOKEN_LOGIN_SUCCESS]: setGoogleDriveAccessTokenSuccess,
  [actionTypes.SET_APP_THEME_LOGIN_SUCCESS]: setAppThemeSuccess,
  [actionTypes.UPDATE_CURRENT_USER_LOGIN_SUCCESS]: updateCurrentUserSuccess,
});

export const watchLogin = reduxCrud.generateWatchSaga({
  baseUrl: "sessions",
  CHECK_AUTH_STATE_LOGIN: checkAuthStateSaga,
  LOGOUT_LOGIN: logoutLoginSaga,
  [actionTypes.VERIFY_JWT_LOGIN]: verifyJwtSaga,
  [actionTypes.FORGOTPASSWORD_LOGIN]: forgotpasswordSaga,
  [actionTypes.RESETPASSWORD_LOGIN]: resetpasswordSaga,
  [actionTypes.SIGNUP_LOGIN]: signupSaga,
  [actionTypes.SWITCH_IDENTITY_LOGIN]: switchIdentitySaga,
  [actionTypes.SEB_CONFIG_FILE_LOGIN]: sebConfigFileSaga,
  [actionTypes.GET_SERVICES_LOGIN]: getServicesSaga,
  [actionTypes.LOGOUT_FROM_MB_LOGIN]: logoutFromMbSaga,
  
});



export default reduxCrud;
