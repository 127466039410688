import {
  Alert,
  Button,
  Col,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  message,
  Tooltip
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleTwoTone } from "@ant-design/icons";
import {
  activeAdjustedExperienceIdSelector,
  activeAdjustedExperienceSelector,
} from "/src/views/Experiences/selector";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { experienceUsersSelector } from "../selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";

const AchievementLevelCalculator = (props) => {
  const [
    showAchievementLevelCalculator,
    setShowAchievementLevelCalculator,
  ] = useState(false);
  const [selectedValue, setSelectedValue] = useState("highest");
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const allExperienceUsers = useSelector(experienceUsersSelector());
  const { isExperienceUsersExist } = props

  const dispatch = useDispatch();

  const mrIntl = useTranslate();

  let totalFullyGradedStudents = 0;
  allExperienceUsers.map((eu) => {
    if (eu.marked_responses_count === eu.total_responses_count) {
      totalFullyGradedStudents += 1;
    }
  });


  console.log(
    "totalResponsesCount ==>",
    totalFullyGradedStudents,
    allExperienceUsers
  );

  const calculateMethodDetail = {
    mean: mrIntl(
      "AchievementLevelCalculator.calculates_the_average_level_decimal_values_are_rounded_to_the"
    ),
    median: mrIntl(
      "AchievementLevelCalculator.determines_the_central_score_in_case_of_even_number_of"
    ),

    mode: mrIntl(
      "AchievementLevelCalculator.identifies_the_most_common_score_when_multiple_modes_the_average"
    ),

    highest: mrIntl(
      "AchievementLevelCalculator.identifies_the_highest_level_achieved_for_each_criteria_highlights_peak"
    ),
  };

  const handleOk = () => {
    setShowAchievementLevelCalculator(false);
  };
  const handleCancel = () => {
    setShowAchievementLevelCalculator(false);
  };

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const options = [
    { value: "mean", label: "Mean" },
    { value: "mode", label: "Mode" },
    { value: "median", label: "Median" },
    { value: "highest", label: "Highest" },
  ];

  const calculateAchievementLevel = () => {
    showConfirmModal({
      centered: true,
      title: mrIntl("AchievementLevelCalculator.this_action_will_overwrite_any_existing_overall_achievement_levels_with"),
      okText: mrIntl("AchievementLevelCalculator.proceed"),
      cancelText: mrIntl("CommonText.cancel"),
      onOk: () => {
        dispatch(
          experienceUserActions.calculateAchievementLevel({
            experience_id: activeExperience.id,
            method: selectedValue,
          })
        );
        message.success(mrIntl("AchievementLevelCalculator.calculation_complete_overall_achievement_levels_have_been_successfully_updated"));
        setShowAchievementLevelCalculator(false);
      },
      onCancel: () => {},
      mrIntl: mrIntl,
    });
  };

  const modalTitle = <Space>
    {mrIntl("AchievementLevelCalculator.achievement_level_calculator")}
    <Tooltip
      title={mrIntl("AchievementLevelCalculator.note_the_calculations_provided_by_this_tool_are_based_on")}
      placement="bottom"
    >
      <InfoCircleTwoTone />
    </Tooltip>
  </Space>

  return (
    <>
      <Button
        className="calculate-achievement-level-button"
        type="text"
        ghost={true}
        disabled={!isExperienceUsersExist}
        onClick={() =>
          setShowAchievementLevelCalculator(!showAchievementLevelCalculator)
        }
      >
        {mrIntl("AchievementLevelCalculator.achievement_level_calculator")}
      </Button>
      {showAchievementLevelCalculator && (
        <Modal
          title={modalTitle}
          open={showAchievementLevelCalculator}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={"50%"}
          wrapClassName="achievement-level-calculator-modal"
        >
          <hr />
          <Row className="select-calculate-achievement-level-method">
            <Col span={24} className="achievement-level-calculator-warning">
              <Alert
                type="error"
                message={mrIntl("AchievementLevelCalculator.this_calculator_estimates_achievement_levels_based_on_available_scores")}
              />
            </Col>
            <Col span={24}>
              <span>
                {mrIntl("AchievementLevelCalculator.choose_calculation_method")}
              </span>{" "}
              <Select
                value={selectedValue}
                style={{
                  width: 120,
                }}
                onChange={handleChange}
                options={options}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className="calculate-method-detail">
                {calculateMethodDetail[selectedValue]}
              </p>
            </Col>
          </Row>
          <Row className="calculate-achievement-level-button">
            <Col span={24}>
              <Button
                type="primary"
                className="calculate-button"
                onClick={() => calculateAchievementLevel()}
              >
                {mrIntl("AchievementLevelCalculator.calculate")}
              </Button>
            </Col>
          </Row>

          {/* <br />
          <span className="achievement-level-calculator-warning-message">
            {mrIntl(
              "AchievementLevelCalculator.note_the_calculations_provided_by_this_tool_are_based_on"
            )}
          </span> */}
        </Modal>
      )}
    </>
  );
};
export default AchievementLevelCalculator;
