
import React from 'react';
import { Alert, Button, Result } from 'antd';
import QuitBtn from '/src/components/UI/QuitBtn';

const InvalidTimeAlert = (props) => {
  const { experience } = props;
  let subTitle = []
  let extra = []
  subTitle.push(
    <Alert
      style={{ marginTop: "20px" }}
      message={<>
        Your system time is out of sync! fix the date and time on your system, <br/>
        <a
          href="https://drive.google.com/drive/u/2/folders/1FR5_R5ANMSMCAWTfCa27mqHX3GBb7kOw"
          target="_blank"
          rel="noopener noreferrer"
        >
          Please click this link to learn how to fix this issue.
        </a>
      </>}
      type="error"
      showIcon
    />
  )

  extra.push(<Button onClick={() => { window.location.reload() }}>Refresh</Button>)
  extra.push(<QuitBtn />)

  return <React.Fragment>
    <Result
      className="end-test-result"
      status="warning"
      // icon={<EditOutlined />}
      title={experience.name}
      subTitle={subTitle}
      extra={extra}
    />

  </React.Fragment>;
}

export default InvalidTimeAlert; // Export the component as default
