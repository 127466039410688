import React from "react";
import { Button, Tooltip } from "antd";
import { UndoOutlined } from "@ant-design/icons";
import query from "query-string";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { isEmpty } from "lodash";

const ResetFilter = ({ setParams, label = null, defaultValues = {}, filterType, useDefaultValues = false, setQueryParamsConfigValue = true }) => {
  console.log("ResetFilter defaultValues", defaultValues);
  const mrIntl = useTranslate()
  const resetFilters = () => {
    const parsedParams = query.parse(window.location.search, { arrayFormat: 'bracket' });
    const newObj = {};
   
    if(useDefaultValues){
      if(!setQueryParamsConfigValue) {
        // eg segments in lib modal
        setParams(defaultValues);
      } else if(!isEmpty(parsedParams)) {
        for (const key in parsedParams) {
          if(defaultValues.hasOwnProperty(key)) {
            newObj[key] = defaultValues[key];
          }
        }
        setParams(newObj);
      } else {
        // no params to resetFilters, do nothing
      }
    } else {
      // old filters - users, classes etc
      for (const key in parsedParams) {
        newObj[key] = null
      }
      setParams(newObj);
    }
  };
  return (
    <>
      <Tooltip title={mrIntl("ResetFilter.reset_filters")}>
        <Button onClick={resetFilters} icon={<UndoOutlined />} className="reset-filters">
          {label && label}
        </Button>
      </Tooltip>
    </>
  );
};
export default ResetFilter;
