import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeExperienceUserSelector, showConvertedAchievementLevelSelector, overallGradingFormVisibleSelector } from "../selector";
import { Col, Modal, Row, Space, Tag } from "antd";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { activeExperienceGradingDisabledSelector, aiAssistantSettingsSelector } from "/src/views/Experiences/selector";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
// import { useHistory, useRouteMatch } from "react-router-dom";
import { displayPoints, isStringEmpty, renderMathInElement } from "/src/lib/utils/helperMethods";
import ShowMediaComments from "/src/views/UserResponses/ShowMediaComments";
import { highLightActiveCell } from "/src/views/Experiences/ExperienceHelperMethods";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import TaskSpecificClarification from "/src/views/Experiences/ExperienceShow/ExperienceManage/TaskSpecificClarification/TaskSpecificClarification";
import { useLoadingCheck } from "../ExperienceUsersHelpers";
import{ OverallAIGradingSuggestionColumn, OverallAIGradingMagicButton, OverallAIGradingModalFooter } from "./OverallAIGrading";
import AIAssistantSettings from "../../Segments/GenerateAI/AIAssistantSettings";


const ExperienceUserOverallGradingForm = (props) => {
  console.log("ExperienceUserOverallGradingForm ==>", props); 
  const { experience, isModal, gradingConfig } = props;
  const [showOverallGradingForm, setShowOverallGradingForm] = useState(true);
  const [gradingAILoading, setGradingAILoading] = useState(false);
  const [suggestedGrades, setSuggestedGrades] = useState({});
  const [showOverallAIGradingSuggestionColumn, setShowOverallAIGradingSuggestionColumn] = useState(false);

  const commentRef = useRef()
  const dispatch = useDispatch()
  const sseRef = useRef(null)
  // const history = useHistory();
  // const { path, url } = useRouteMatch;
  // const experienceUser = useSelector(activeExperienceUserSelector());
  const overallGradingFormVisible = useSelector(overallGradingFormVisibleSelector())
  const experienceUser = useSelector(activeExperienceUserSelector());

  const activeExperienceGradingDisabled = useSelector(
    activeExperienceGradingDisabledSelector()
  );
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const aiAssistantSettings = useSelector(aiAssistantSettingsSelector());
  const currentUser = useSelector(currentUserSelector());
  const checkIfLoadingCompleted = useLoadingCheck();
  const mrIntl = useTranslate();
  const showConvertedAchievementLevel = useSelector(showConvertedAchievementLevelSelector())

  let formEU = { ...experienceUser };
  
  const experienceSettings = experience.settings || {};
  const experienceRubric = experience.rubric || {};
  const tscEnabled = experienceSettings.tsc_enabled
  const isTscAchievementLevel = tscEnabled && experienceRubric.type_c === "myp_achievement_level";
  // const overallAIGradingEnabled = enabledFeatures.overall_grading_with_ai && isTscAchievementLevel && !experienceUser.marked;
  const overallAIGradingEnabled = enabledFeatures.overall_grading_with_ai && isTscAchievementLevel; // Temporarily allowing AI grading for both marked and unmarked submissions for testing purposes. Will revert to only allow for unmarked submissions after testing is complete.
  const showAIAssistantSettings =  overallAIGradingEnabled;

  useEffect(() => {
    if(commentRef.current) {
      renderMathInElement(commentRef.current)
    }
    return () => {
      setSuggestedGrades({})
      setShowOverallAIGradingSuggestionColumn(false)
    }
  }, [overallGradingFormVisible])

  if (experienceSettings.grading_setting === "per_question") {
    delete formEU.points;
    // delete formEU.submission_criterium_associations_attributes; // TODO: check - maybe don't need to remove this?
    // modifying to remove points from item so that when giving only comments (we want to show comments in all cases), pts don't get overridden by old value
  }

  const closeModal = () => {
    checkIfLoadingCompleted(() => {
      dispatch(experienceUsersActions.setOverallGradingFormVisibleSuccess({ visible: false }));
      const selectedCell = document.querySelector(`[data-id="total-points-cell-${experienceUser.id}"] div`);
      if (selectedCell) {
        highLightActiveCell(selectedCell)
      }
    })
  }
  
  const formToRender = showOverallGradingForm && props.renderForm({
    // {graderAttrs.gradingEnabled && props.renderForm({
    name: "experienceUsers",
    config: {
      isModal: false,
      visible: true,
      // modal: { title: "Overall comments", onCancel: () => { console.log("modal cancel called"); props.showForm({name: ""}) } }, 
      // handle goBack on cancel - to handle cancel/outside click - default behaviour is to go back to previous state. overriding the onCancel func here
      actions: {
        submitText: mrIntl("CommonText.save"),
        showSubmitBtn: false,
        showCancelBtn: false,
        submitEnabled: activeExperienceGradingDisabled ? false : true,
        showSavedMsg: true,
        autoSaveConfig: { 
          shouldAutoSave: true, 
          debounce: 100, 
          // successMsg: "Saved successfully",
          successMsg: mrIntl("CommonText.assessment_saved_successfully"),
          successCallback: (data) => {
            console.log("success message callback when auto save for overall grading", data)
          },
          // errorMsg: "Error in saving",
          errorMsg: mrIntl("CommonText.error_in_saving"),
          hideMessage: true, // to hide the saved/error message after a few seconds
          hideMessageTimeout: 3000,
        },
      },
      submitConfig: {
        hideForm: false, // don't close form onsubmit in case of autosave. 
        redirectTo: "", // option exists to redirect somewhere on submit - non-autosave case only
        success: { showMessage: true, message: mrIntl("CommonText.points_comments_saved") },
        error: {
          showMessage: true,
          message: mrIntl("CommonText.error_in_saving_points_comments"),
        },
        getSavedAtMsg: (data) => {
          return " "; //needs to be a space otherwise not rendering successMsg
        },
        successCallback: (data) => {
          console.log("successCallback data ===>", data);
          if (data) {
            try {
              const experienceUser = data.data.experience_user.data.attributes;
              const updatedExperienceUser = {
                ...experienceUser,
                has_user_responses_in_redux: true,
              };
              console.log("updatedExperienceUser===>", updatedExperienceUser);
              dispatch(
                experienceUsersActions.showSuccess(
                  { data: { experience_user: updatedExperienceUser } },
                  { success: { showMessage: false } }
                )
              );
            } catch (error) {
              console.error("Error in updating experience user:", error);
            }
          }
          // if (autoSaveAllowed) return message.success('Comments saved');
        },
      },
      // handleCancelCallback: () => {
      //   console.log("cancel called");
      // }  // handle goBack on cancel - to handle cancel/outside click - default behaviour is to go back to previous state. False means don't go anywhere. Just close the modal - NOT WORKING - using onCancel in 36 above. AJ will give config to do this
    },
    resource: formEU, //use this for edit
  })

  let overallGradingCommentsRender = (
    <Space direction="vertical" className={"overall-comments-render"} ref={commentRef}>
      <p>{isStringEmpty(experienceUser.comments) && mrIntl("ExperienceUserOverallGradingForm.no_comments_given")}</p>
      {!isStringEmpty(experienceUser.comments) && <span style={{"color":"#aaa"}}><MrTranslate id={"ExperienceUserOverallGradingForm.comment"}/></span>} 
      <span>
        {!isStringEmpty(experienceUser.comments) && (
          <div dangerouslySetInnerHTML={{ __html: experienceUser.comments }}></div>
        )}
      </span>
      {experienceUser && experienceUser.custom_fields && experienceUser.custom_fields.media_comments && (
        <div> <ShowMediaComments media_comments={experienceUser.custom_fields.media_comments} /></div>
      )}
    </Space>
  );
  const shouldDisplayPoints = experienceRubric.type_c !== "myp_achievement_level" && experienceRubric.type_c !== "criteria_with_points" && experienceRubric.type_c !== "comments_only";
  const shouldDisplayCriteriaPoints = (experienceRubric.type_c === "myp_achievement_level" && experienceUser.marked) || (experienceRubric.type_c === "criteria_with_points")

  let criteriaPointsView = [];
  let convertedCriteriaLevelsView = [];
  if (experience.criterium_associations) {
    experience.criterium_associations.forEach((ca, i) => {
      const currentCriteriaPoints = experienceUser.submission_criterium_associations_attributes && experienceUser.submission_criterium_associations_attributes.find((sca) => sca.custom_fields.title === ca.custom_fields.title) || {}
      const title = ca.custom_fields.title || ""
      if(experienceRubric.type_c === "myp_achievement_level") {
        let points;
        points = currentCriteriaPoints.points != null ? displayPoints(currentCriteriaPoints.points) : "-"
        criteriaPointsView.push(<Tag color="blue">{title} {points}</Tag>)
      } else {
        let caMaxPoints = ca.points
        criteriaPointsView.push(<Tag color="blue">{title} {displayPoints(currentCriteriaPoints.points)}/{displayPoints(caMaxPoints)}</Tag>)

        if (showConvertedAchievementLevel) {
          let convertedLevel = currentCriteriaPoints.custom_fields && currentCriteriaPoints.custom_fields.converted_achievement_level != null ? displayPoints(currentCriteriaPoints.custom_fields.converted_achievement_level) : "-"
          convertedCriteriaLevelsView.push(<Tag color="blue">{title} {convertedLevel}/8</Tag>)
        }
      }
    });
  }
  // const overAllCommentsStyle = {padding: "0 12px"}
  let toRender =
    currentUser.role === "support" ? (
      <span>{overallGradingCommentsRender}</span> // isOverallGrading ?
    ) : (
      <Row>
        <Col span={showOverallAIGradingSuggestionColumn ? 10 : 24}>
          <Space direction="vertical" className="overall-comments-space">
            {shouldDisplayPoints && (
              <Tag color="blue">
                {displayPoints(experienceUser.points)}/
                {displayPoints(experience.points)}
              </Tag>
            )}
            {shouldDisplayCriteriaPoints && (
              <Space direction="vertical">
                {showConvertedAchievementLevel && <b>Criteria points</b>}
                <Space>{criteriaPointsView}</Space>
              </Space>
            )}
            {experienceUser.marked && showConvertedAchievementLevel && (
              <Space direction="vertical" className="m-t-15">
                <b>
                  {mrIntl(
                    "ExperienceUserOverallGradingForm.equivalent_achievement_level"
                  )}
                </b>
                <Space>{convertedCriteriaLevelsView}</Space>
              </Space>
            )}
            <span>
              {experienceUser.marked
                ? overallGradingCommentsRender
                : formToRender}
            </span>
          </Space>
        </Col>
        {showOverallAIGradingSuggestionColumn ? (
          <OverallAIGradingSuggestionColumn
            suggestedGrades={suggestedGrades}
            gradingAILoading={gradingAILoading}
            sseRef={sseRef}
          />
        ) : null}
      </Row>
    );
    
  const studentName = gradingConfig.anonymousGradingEnabled ? (experienceUser ? experienceUser.custom_fields && experienceUser.custom_fields.anonymous_name : "") : (experienceUser ? experienceUser.name : "");

  const taskSpecificClarification = isTscAchievementLevel ? <TaskSpecificClarification /> : null ;
  let renderItem = null

  const overallAIGradingButton = overallAIGradingEnabled ? (
    <OverallAIGradingMagicButton
      gradingAILoading={gradingAILoading}
      setGradingAILoading={setGradingAILoading}
      sseRef={sseRef}
      suggestedGrades={suggestedGrades}
      setSuggestedGrades={setSuggestedGrades}
      setShowOverallAIGradingSuggestionColumn={setShowOverallAIGradingSuggestionColumn}
    />
  ) : null;

  const modalTitle = (
    <Space direction="vertical">
      <Space>
        {mrIntl("ExperienceUserOverallGradingForm.overall_grading_for", {
          studentName: studentName,
        })}{" "}
        {taskSpecificClarification}
        {showAIAssistantSettings && <AIAssistantSettings disabled={gradingAILoading} defaultActiveTab={"grading"} />}
      </Space>
      {overallAIGradingButton}
    </Space>
  );

  if (isModal) {
    if (overallGradingFormVisible) {
      renderItem = (
        <Modal
          key={`experience-user-overall-grading-${experienceUser.id}`}
          open={overallGradingFormVisible}
          width={showOverallAIGradingSuggestionColumn ? 1200 : 600}
          className={"height-centered-modal eu-overall-grading-modal"}
          title={modalTitle}
          footer={
            showOverallAIGradingSuggestionColumn ? (
              <OverallAIGradingModalFooter
                suggestedGrades={suggestedGrades}
                setSuggestedGrades={setSuggestedGrades}
                gradingAILoading={gradingAILoading}
                setGradingAILoading={setGradingAILoading}
                setShowOverallGradingForm={setShowOverallGradingForm}
                sseRef={sseRef}
                setShowOverallAIGradingSuggestionColumn={setShowOverallAIGradingSuggestionColumn}
                enabledFeatures={enabledFeatures}
                aiAssistantSettings={aiAssistantSettings}
              />
            ) 
            : null
          }
          onCancel={() => closeModal()}
        >
          {toRender}
        </Modal>
      );
    }
  } else {
    renderItem = toRender
  }

  return renderItem;
};

export default ExperienceUserOverallGradingForm;
