import React, { useEffect, useState } from "react";

import { RBDDroppableArea } from "/src/components/UI/DnD/RBDDroppableArea";
import { DnDDroppableTitle } from "/src/components/UI/DnD/DnDDroppableTitle";
import { DnDTabularTextBoxes } from "/src/components/UI/DnD/DnDTabularTextBoxes";
import { DnDInlineTextBoxes } from "/src/components/UI/DnD/DnDInlineTextBoxes";
import { DnDDistractors } from "/src/components/UI/DnD/DnDDistractors";
import { DragDropContext } from "react-beautiful-dnd";
import { updateOnDragEnd } from "/src/views/Segments/DnDHelpers";
import {
  getArrayOfList,
  addListToArray,
  addItemToArray,
  removeItemFromArray,
  getParsedJSONObject,
} from "/src/views/Segments/InteractiveHelpers";
import {
  Segment,
  SegmentData,
  HorizontalDroppableContainer,
  VerticalDroppableContainer,
  InlineDroppablesContainer,
  InlineDroppable,
} from "/src/components/UI/Segment/UIHelper";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { cloneDeep, shuffle } from "lodash";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import SegmentItemHeader from "/src/views/Segments/SegmentItemDetail/SegmentItemHeader";
import { ScoreButton } from "/src/components/UI/Game/ScoreButton";
import { AttemptLeftButton } from "/src/components/UI/Game/AttemptLeftButton";
import { RBDDroppableBox } from "/src/components/UI/DnD/RBDDroppableBox";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RDDroppableBox } from "/src/components/UI/DnD/ReactDnD/RDDroppableBox";
import { RDDraggableItem } from "/src/components/UI/DnD/ReactDnD/RDDraggableItem";
import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import GameStages from "/src/components/UI/Game/GameStages";

export const convertToMatchGame = (json) => {
  let newJSON = { ...json };
  const segment_data = json.segment_data;
  const columns = segment_data.columns;
  const possible_responses = segment_data.possible_responses;

  let possibleResponsesItems = [];

  columns.map((column) => {
    column.items.map((item) => {
      possibleResponsesItems.push({
        ...item,
        matchId: column.id,
      });
    });

    let newPossibleResponse = {
      ...column,
      content: column.title,
      matchId: column.id,
    };
    delete newPossibleResponse.items;
    possibleResponsesItems.push(newPossibleResponse);
  });

  newJSON = {
    ...newJSON,
    segment_data: {
      ...segment_data,
      columns: [],
      possible_responses: {
        ...possible_responses,
        items: possibleResponsesItems,
      },
    },
  };

  console.log("Match game json ==>", newJSON);
  return newJSON;
};

const MatchGame = (props) => {
  console.log("MatchGame props", props);
  const {
    segment_data,
    columns,
    possible_responses,
    distractors,
    state,
    saveState,
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
    answerMode,
    presentationMode,
    autoCheckMode,
    attemptStatus,
    setAttemptStatus,
    // shuffleItems,
    // checkResponses,
    gameStatus,
    setGameStatus,
  } = props;

  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
    showCorrectAnswerSwitchValue: false,
  });
  const [myAnswer, setMyAnswer] = useState(state);
  const [play, setPlay] = useState(false);
  const [previousItem, setPreviousItem] = useState();
  const screenSettings = props.screenSettings || {};

  const score = gameStatus.score
  const attempt_left = gameStatus.attempt_left
  const max_attempt = gameStatus.max_attempt
  const gameEnded = possible_responses.items.length / 2 === score || attempt_left <= 0
  const gameLost = possible_responses.items.length / 2 != score && attempt_left <= 0
  const maxAttempt = possible_responses.items.length + possible_responses.items.length / 2

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  useEffect(() => {
    if (gameEnded) {
      setPlay(false)
    }
  }, [gameEnded])

  useEffect(() => {
    let newGameStatus = {
      ...gameStatus,
      max_attempt: maxAttempt
    }
    if (!gameStatus.started) {
      newGameStatus = {
        ...gameStatus,
        attempt_left: maxAttempt,
      }
    }
    setGameStatus(newGameStatus)
  }, [gameStatus.started])

  function shuffleItems(json) {
    const segment_data = json.segment_data || {};
    const possible_responses = segment_data.possible_responses || {};
    possible_responses.items = shuffle(possible_responses.items);

    return json;
  }

  function validateAttempted(json) {
    let initial_possible_responses_items =
      props.student_json.segment_data.possible_responses.items;
    let current_possible_responses_items =
      json.segment_data.possible_responses.items;

    let isAtleastOneDropped = false;
    if (
      current_possible_responses_items.length !=
      initial_possible_responses_items.length
    ) {
      isAtleastOneDropped = true;
    }

    console.log(
      "validate attempt status ==>",
      attemptStatus,
      isAtleastOneDropped
    );
    setAttemptStatus({
      ...attemptStatus,
      json_attempted: isAtleastOneDropped,
    });
  }

  const handleDrop = (dragItem, dropItem) => {
    console.log("result in handleDrop ==>", dragItem, dropItem);

    if (dragItem.id == dropItem.id) {
      return
    }

    let sourceIndex = possible_responses.items.findIndex(
      (item) => item.id == dragItem.id
    );
    let destinationIndex = possible_responses.items.findIndex(
      (item) => item.id == dropItem.id
    );

    let newGameStatus = {
      ...gameStatus,
      attempt_left: attempt_left > 0 ? attempt_left - 1 : 0
    }

    if (dragItem.matchId == dropItem.matchId) {
      dragItem.correct = true;
      dropItem.correct = true;

      newGameStatus = {
        ...newGameStatus,
        score: score + 1
      }
    }

    setGameStatus(newGameStatus)

    possible_responses.items[sourceIndex] = dragItem;
    possible_responses.items[destinationIndex] = dropItem;

    const newSegmentData = {
      ...segment_data,
      possible_responses: possible_responses,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    if (answerMode) {
      validateAttempted(newState);
    }
    
    saveState(newState);
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const resetQuestion = () => {
    let student_json = convertToMatchGame(cloneDeep(props.teacher_json));
    const newState = shuffleItems(student_json);
    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    setGameStatus({
      ...gameStatus,
      score: 0,
      max_attempt: maxAttempt,
      started: false,
    })
    saveState(newState);
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: false,
    });
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const handlePlayButton = () => {
    let newGameStatus = {
      ...gameStatus,
      started: true
    }
    setPlay(true)
    setGameStatus(newGameStatus)
  }

  const selectItem = (item) => {
    const currentId = `drag-${item.id}`
    const previousId = previousItem && `drag-${previousItem.id}`
    if (previousItem && previousItem.id !== item.id) {
      handleDrop(previousItem, item)
      setPreviousItem(null)
      if (!item.correct) {
        document.getElementById(currentId).classList.add("error")
        document.getElementById(previousId).classList.add("error")
        setTimeout(() => {
          document.getElementById(currentId).classList.remove("error")
          document.getElementById(previousId).classList.remove("error")
          document.getElementById(currentId).classList.remove("selected-item")
          document.getElementById(previousId).classList.remove("selected-item")
        }, 1000)
      }
    } else {
      setPreviousItem(item)
      if (previousItem) {
        document.getElementById(previousId).classList.remove("selected-item")
        setPreviousItem(null)
      } else {
        document.getElementById(currentId).classList.add("selected-item")
      }
    }
  }

  const scoreButton = <ScoreButton score={score} className="match-score-button"></ScoreButton>
  const gameViewConfig = {
    play: play,
    scoreButton: scoreButton,
    attempt_left: attempt_left,
    gameEnded: gameEnded,
    gameLost: gameLost,
    onTryAgain: resetQuestion,
    handlePlayButton: handlePlayButton,
  }
  console.log(
    "Match Game State before render ==>",
    state,
    attemptStatus,
    gameStatus,
  );
  return (
    <Segment className="game-segment">
      <SegmentData key={`segment-data-${props.unique_segment_identifier}`} className="match-game">
        <GameStages  {...gameViewConfig}/>
        {scoreButton}
        <AttemptLeftButton attempt_left={attempt_left} className="match-attempt-left-button"></AttemptLeftButton>
        <div>
          <DndProvider backend={HTML5Backend}>
            {possible_responses.items.map((item, index) => (
              <RDDroppableBox
                accept={`box-${props.unique_segment_identifier}`}
                item={item}
                index={index}
                handleDrop={handleDrop}
                style={{
                  width: "20%",
                  height: "100px",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: item.correct && "#47c0e9a6",
                }}
                draggable={
                  !item.correct ? <RDDraggableItem
                    id={`drag-${item.id}`}
                    type={`box-${props.unique_segment_identifier}`}
                    item={item}
                    index={index}
                    onClick={() => selectItem(item)}
                    canDrag={attempt_left > 0}
                    style={{
                      width: "90%",
                      height: "90%",
                    }}
                  ></RDDraggableItem> : <div>
                    <CheckOutlined />
                  </div>
                }
              ></RDDroppableBox>
            ))}
          </DndProvider>
        </div>
      </SegmentData>

      <SegmentItemFooter
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        resetQuestion={resetQuestion}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default MatchGame;
