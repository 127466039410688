import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import "./ChatWrapper.scss";
import { Button, Badge, Tooltip } from 'antd';
import {MessageOutlined, CloseCircleOutlined} from "@ant-design/icons"
import DetectOutsideClick from '/src/lib/DetectOutsideClick/DetectOutsideClick';
import { merge } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { firstActiveChatSelector, studentUnreadMessagesCountUserInfoSelector } from '/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector';
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector } from '/src/views/Auth/Login/selector';
import { showNotification } from '/src/components/UI/Segment/UIHelper';
import { activeAdjustedExperienceSelector, experienceViewModeSelector } from "/src/views/Experiences/selector";
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
const defaultConfig = {
  closeOnOutsideClick: false,
  initiallyOpen: false,
  chatPopupStyle: {
    // position: "fixed"
  },
  overlayStyle: {
    
  }
};
export const chatWrapperStatus = "closed";
export const ChatWrapperContext = React.createContext();
const ChatWrapper = (props) => {

  console.log("ChatWrapper props", props)
  
  const { config, newMessages, openChat} = props;
  const finalConfig = merge({}, defaultConfig, config);
  // const [newMessages, setNewMessages] = useState(true)
  const firstActiveChat = useSelector(firstActiveChatSelector) || {};
  const dispatch = useDispatch()
  const currentUser = useSelector(currentUserSelector());
  const appRegion = useSelector(appRegionSelector());
  const embedded = useSelector(getEmbeddedSelector());
  const [chatVisible, setChatVisible] = useState(finalConfig.initiallyOpen);
  const studentUnreadCount = useSelector(studentUnreadMessagesCountUserInfoSelector);
  const experienceViewMode = useSelector(experienceViewModeSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector()) 
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const mrIntl = useTranslate();

  const isChina = appRegion === "china" ? true : false;
  const chatDisabled = activeExperience?.settings?.chat_disabled;

  const tooltipTitle = chatDisabled
   ? mrIntl("ChatWrapper.chat_has_been_disabled_for_this_assessment")
   : firstActiveChat.name 
      ? firstActiveChat.name
      : mrIntl("ChatWrapper.chat");

  const newJoinViewWithDynamicConfigEnabled = enabledFeatures.new_join_view_with_dynamic_config && currentUser.role === "student"
  const mainWrapperStyle = {}
  useEffect(() => {
    setChatVisible(openChat)
  }, [openChat])
  
  useEffect(() => {
    if(props.onVisibleChangeCallback){
      props.onVisibleChangeCallback(chatVisible);

    }
    if(currentUser.role === "teacher"){
      dispatch(firestoreInteractionActions.setActiveChatSuccess({id: finalConfig.chat_id, status: chatVisible ? "open" : "close", name: firstActiveChat.name}));
    }

    // if(finalConfig.initiallyOpen && !chatVisible){
    //   setChatVisible(finalConfig.initiallyOpen);
    // }
    return () => {
      if(props.chatUnmountCallback){
        props.chatUnmountCallback();
      }
    }
    
  }, [chatVisible, finalConfig.chat_id])
  useEffect(() => {
    if(["teacher", "admin"].includes(currentUser.role)){
      if(!chatVisible && firstActiveChat.status === "open"){
        setChatVisible(true);
        // dispatch(firestoreInteractionActions.setActiveChatSuccess({id: finalConfig.chat_id, status: chatVisible ? "open" : "minimized", name: firstActiveChat.name}));
      }
    }
    if(chatVisible && firstActiveChat.status === "minimized") {
      setChatVisible(false);
    }
  }, [chatVisible, firstActiveChat.status])
  useEffect(() => {
    if(finalConfig.initiallyOpen && !chatVisible){
      setChatVisible(finalConfig.initiallyOpen);
    }
    console.log( "chat finalConfig", finalConfig, chatVisible );
    
  }, [finalConfig.chat_id])

  if(embedded){
    return null;
  }
  // if(configStyle.position){
  //   mainWrapperStyle.position = configStyle.position;
  // }
  // return <div className={"ap-main-chat-wrapper"} style={{...finalConfig.overlayStyle}}>
  return <div className={`ap-main-chat-wrapper ${currentUser.role !== "student" && experienceViewMode !== "apPreviewTest" ? 'teacher-chat' : ''}`} style={{...finalConfig.overlayStyle}}>
    <DetectOutsideClick detectOutside={finalConfig.closeOnOutsideClick} callback={() => {
        if(finalConfig.closeOnOutsideClick){
          setChatVisible(false)
          if(["teacher", "admin"].includes(currentUser.role)){
            dispatch(firestoreInteractionActions.setActiveChatSuccess({id: firstActiveChat.id, status: chatVisible ? "open" : "minimized", name: firstActiveChat.name}));
          }
        }
        if(props.outsideClickCallback){
          props.outsideClickCallback();
        }
      }}>
      <div className={`chat-feed-wrapper ${!chatVisible ? "hidden" : ""}`} style={{...finalConfig.chatPopupStyle}}>
        <ChatWrapperContext.Provider value={chatVisible ? "open" : "minimized"}>
          {props.children}
        </ChatWrapperContext.Provider>
      </div>
      {/* {config.chat_enabled && */}

      {/* adding conditions for show chat button if flag is off. and if flag is on hide the chat button only for student  */}
      {(!newJoinViewWithDynamicConfigEnabled) ? 
      <Badge 
        count={!isChina && studentUnreadCount !== 0 ? studentUnreadCount : null}  
        dot={isChina && studentUnreadCount}
        onClick={() => {
          setChatVisible(!chatVisible);
            dispatch(firestoreInteractionActions.setActiveChatSuccess({id: firstActiveChat.id, status: !chatVisible ? "open" : "minimized", name: firstActiveChat.name}));
        }}>
        <Tooltip title={tooltipTitle} placement="left">
        <Button disabled={chatDisabled} className="ant-btn-icon-m-t-4" size={"large"} type={currentUser.role === "student" &&  !newMessages ? "default" : "primary"} shape={currentUser.role === "student" ? "round" : "circle"} icon={chatVisible ? <CloseCircleOutlined /> : <MessageOutlined />}>
            {currentUser.role === "student" ? mrIntl("ChatWrapper.chat") : ""}
          </Button>
        </Tooltip>
      </Badge>
      : null}
    </DetectOutsideClick>
  </div>
};
ChatWrapper.defaultProps = {
  config: {
    style: {}
  }
}

ChatWrapper.propTypes = {}

export default ChatWrapper;