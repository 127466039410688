import React, { useEffect, useState, useRef } from "react";

import { Carousel, Row, Col } from "antd";
import { InboxOutlined, UploadOutlined, SoundOutlined, PauseCircleOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { initialData, addItemToArray, removeItemFromArray, convertDataAsPerNewSystem, getParsedJSONObject, numaricalSort } from "/src/views/Segments/InteractiveHelpers";
import { AddButton, DeleteButton, CheckBox, InputArea, MCQArea, MCQList, TextArea, ShowText, ShowTextIndex, ShowTextContent, CustomTextButton, Segment, SegmentData } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { MrInput } from "mr_react_framework"
import { debounce } from 'lodash';
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import InsertFromURL from "/src/components/UI/Media/InsertFromURL";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const SliderImages = props => {
  console.log("Slider Images props", props);

  const { attachments, resource_setting } = props
  const slides = attachments
  const mrIntl = useTranslate()
  const sliderImagesCarousel = useRef()
  const [sliderSetting, setSliderSetting] = useState({
    currentSlideIndex: 0,
    currentSlide: slides[0],
    slidesCount: slides.length,
  })

  const showSlide = (switchTo) => {
    let nextIndex, nextSlide, status
    if (switchTo == 'prev') {
      if (sliderSetting.currentSlideIndex == 0) {
        nextIndex = sliderSetting.slidesCount - 1
      } else {
        nextIndex = sliderSetting.currentSlideIndex - 1
      }
    } else if (switchTo == 'next') {
      if (sliderSetting.currentSlideIndex == sliderSetting.slidesCount - 1) {
        nextIndex = 0
      } else {
        nextIndex = sliderSetting.currentSlideIndex + 1
      }
    } else if (switchTo == 'reset') {
      nextIndex = 0
    } else if (switchTo == 'play') {
      status = 'playing'
    } else if (switchTo == 'pause') {
      status = 'paused'
    }

    nextSlide = slides[nextIndex]

    sliderImagesCarousel.current.goTo(nextIndex)
    setSliderSetting({
      ...sliderSetting,
      currentSlideIndex: nextIndex,
      status: status,
      currentSlide: {
        ...nextSlide
      }
    })
  }

  const updateSliderSetting = (index) => {
    setSliderSetting({
      ...sliderSetting,
      currentSlideIndex: index,
      currentSlide: slides[index]
    })
  }

  console.log("slider images before render ==>", sliderSetting)
  return (
    <div className="image-slider-carousel">
      <Carousel
        ref={ref => {
          sliderImagesCarousel.current = ref
        }}
        autoplay={resource_setting.auto_slide && sliderSetting.status != 'paused'}
        afterChange={(index) => updateSliderSetting(index)}
        dotPosition='none'  
      >
        {slides.map((slide) => (<span>
          <img src={slide.url} ></img>
          {slide.caption && <ShowTextContent dangerouslySetInnerHTML={{ __html: slide.caption }}
                ></ShowTextContent>}
          {slide.description && <ShowTextContent dangerouslySetInnerHTML={{ __html: slide.description }}
                ></ShowTextContent>}
        </span>)
        )}
      </Carousel>


      <CustomTextButton
        buttonText={mrIntl("SliderImages.prev")}
        type="primary"
        // disabled={sliderSetting.currentSlideIndex == (sliderSetting.slidesCount - 1)}
        clicked={(e) => showSlide('prev')}
      ></CustomTextButton>

      {/* {resource_setting.auto_slide && <CustomTextButton
        buttonText={sliderSetting.status == 'paused' ? 'Play' : 'Pause'}
        type="primary"
        clicked={(e) => showSlide(sliderSetting.status == 'paused' ? 'play' : 'pause')}
      >
      </CustomTextButton>} */}

      <CustomTextButton
        buttonText={mrIntl("SliderImages.next")}
        type="primary"
        // disabled={sliderSetting.currentSlideIndex == (sliderSetting.slidesCount - 1)}
        clicked={(e) => showSlide('next')}
      ></CustomTextButton>

      {/* {sliderSetting.currentSlideIndex == (sliderSetting.slidesCount - 1) ?
        <CustomTextButton
          buttonText='Reset'
          clicked={(e) => showSlide('reset')}
        ></CustomTextButton>
        :
        <CustomTextButton
          buttonText='Next'
          type="primary"
          // disabled={sliderSetting.currentSlideIndex == (sliderSetting.slidesCount - 1)}
          clicked={(e) => showSlide('next')}
        ></CustomTextButton>
      } */}
    </div>
  );
}
export default SliderImages;