import React from "react";
import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { actions as fireStoreReduxActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { useHistory } from "react-router-dom";
import { checkIfShouldDispatchChangeSecurity } from "/src/App/OfflineApp/offlineAppHelper";

const BackToHomeBtn = (props) => {
  const { action, experience, className } = props;

  const experienceSettings = experience.settings || {};

  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  const history = useHistory();
  const appType = useSelector(appTypeSelector());

  const shouldDispatchChangeSecurity = checkIfShouldDispatchChangeSecurity(appType, experienceSettings);

  const handleBackButtonClick = (e) => {
    if(action) {
      dispatch(
        fireStoreReduxActions.setUserInfo(
          {},
          {
            log: {
              logging: true,
              action: props.action
            },
          }
        )
      );
    }
    if(shouldDispatchChangeSecurity) {
      dispatch(
        offlineAppActions.executeMethod({ key: "changeSecurity", value: false })
      );
    }
    history.replace("/");
  };

  const backToHomeBtn = (
    <Button
      key="back-to-home-btn"
      // href="/" // In case of no internet, this will not work
      onClick={(e) => handleBackButtonClick()}
      icon={<LeftOutlined />}
      className={className}
    >
     {mrIntl("CommonText.back_to_home")}
    </Button>
  );
  return backToHomeBtn;
};

export default BackToHomeBtn;
