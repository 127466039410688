import React, { useEffect } from 'react';
import ViewCard from '../ViewComponents/ViewCard';
import BackToHomeBtn from '/src/components/UI/BackToHomeBtn';
import MrTranslate, { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { Button, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { appRegionSelector, currentUserSelector } from '/src/views/Auth/Login/selector';
import { currentSessionIdSelector, isTimeUpSelector, requestResumeSelector, requestResumeSessionIdSelector, sessionIdSelector } from '../../FirestoreInteractions/selector';
import { experienceViewModeSelector } from '../../../selector';
import { writeToClipboardPolyfill } from '/src/lib/utils/helperMethods';
import { checkInternetConnectivity } from '/src/lib/CheckInternetConnectivity/CheckInternetConnectivity';
import "./ResumeView.scss";
import { message } from '/src/components/UI/AntdAppHelper';
import { appTypeSelector } from '/src/App/OfflineApp/offlineAppSelectors';
import ViewStatus from '../ViewComponents/ViewStatus';
import { SolarPenNewSquareBold, SolarPlayCircleBold } from '/src/components/UI/Icons/SolarIcons';
import ViewCard1 from '../ViewComponents/ViewCard1';
import { getOrCreateSessionId } from '../../FirestoreInteractions/redux';

const ResumeView = (props) => {
  const {
    experienceProps,
    setSwitchFromStartToResumeView,
    endButton,
    timer,
    sessionSwitching,
    isUploadDeviceSession,
  } = props;

  const { experience, embedded, setUserInfo } = experienceProps;
  const experienceSettings = experience.settings || {};
  const mrIntl = useTranslate();
  const appRegion = useSelector(appRegionSelector());
  const currentUser = useSelector(currentUserSelector());
  const userInfoFromReduxrequestResume = useSelector(requestResumeSelector);
  const currentSessionIdFromRedux = useSelector(currentSessionIdSelector);
  const userInfoFromReduxsessionId = useSelector(sessionIdSelector)
  const userInfoFromReduxRequestResumeSessionId = useSelector(requestResumeSessionIdSelector)
  let experienceViewMode = useSelector(experienceViewModeSelector());
  const isTimeUp = useSelector(isTimeUpSelector);
  const appType = useSelector(appTypeSelector());

  const currentSessionIdFromSessionStorage = getOrCreateSessionId(); 
  const showConfig = {
    showProgress: true,
    timer: timer,
    titleColorClassName: 'bg-orange'
  }

  const isMyResumeRequestedSessionActive = userInfoFromReduxRequestResumeSessionId === currentSessionIdFromSessionStorage
  const resumeRequested = isMyResumeRequestedSessionActive && userInfoFromReduxrequestResume === "request"
  // Resume test view - allow-decline logic
  // TODO: move this to ResumeTestView component
  // console.log("Resume View session ids ==>", {
  //   userInfoFromReduxrequestResume,
  //   isMyResumeRequestedSessionActive,
  //   userInfoFromReduxRequestResumeSessionId,
  //   currentSessionIdFromSessionStorage
  // });
  useEffect(() => {
    if (isMyResumeRequestedSessionActive) {
      if (userInfoFromReduxrequestResume === "allow") {
        setSwitchFromStartToResumeView(false);
        setUserInfo(
          {
            currentView: "startTest",
            request_resume: "initial",
            request_resume_session_id: null, // Resetting request_resume_session_id
          },
          {
            log: {
              logging: true,
              action: "resume_allowed_student",
              current_value: currentUser.name,
              action_by: `${currentUser.name} (${currentUser.role})`,
            },
          }
        );
        message.success(mrIntl("ResumeView.request_resume_allowed_msg"));
      } else if (userInfoFromReduxrequestResume === "decline") {
        setUserInfo(
          {
            request_resume: "initial",
          },
          // {
          //   log: {
          //     logging: true,
          //     action: "resume_declined",
          //     currrent_value: currentUser.name,
          //     action_by: `${currentUser.name} (${currentUser.role})`,
          //   },
          // }
        );
        message.error(mrIntl("ResumeView.request_to_resume_declined_msg"));
      }
    }
  }, [userInfoFromReduxrequestResume, isMyResumeRequestedSessionActive]);
  // Resume test view end

  let resumeButton = (
    <Button
      type="primary"
      onClick={() => {
        !embedded && 
        experienceViewMode === "apTakeTest" && 
        writeToClipboardPolyfill("");
        checkInternetConnectivity(appRegion, (internetStatus) => {
          if (internetStatus === "online") {
            setSwitchFromStartToResumeView(false);
            setUserInfo(
              {
                currentView: "startTest",
                session_id: currentSessionIdFromSessionStorage,
                request_resume_session_id: currentSessionIdFromSessionStorage,
                // confirm what else to set
              },
              {
                // TODO: add log for student re-entering the test - change log message in firestore redux?
                // log: {
                //   // logging: experienceProps.log.logging,
                //   logging: true,
                //   // msg: `Requested to resume`,
                //   action: "resume_requested",
                //   // msg: mrIntl("ResumeView.requested_to_resume"),
                // },
              }
            );
          } 
          // else {
          //   message.error("Your internet connection not stable. Please check your internet connection and try again.")
          // }
        }, mrIntl)
        
      }}
    >
      {mrIntl("ResumeView.resume")}
    </Button>
  )

  let requestResumeBtn = (
    <Button
      type="primary"
      disabled={resumeRequested}
      loading={resumeRequested}
      className="resume-button"
      onClick={() => {
        !embedded && experienceViewMode === "apTakeTest" && writeToClipboardPolyfill("");
        checkInternetConnectivity(appRegion, (internetStatus) => {
          if (internetStatus === "online") {
            setUserInfo(
              {
                request_resume: "request",
                needs_attention_at: Date.now(),
                request_resume_session_id: currentSessionIdFromSessionStorage,
              },
              {
                log: {
                  // logging: experienceProps.log.logging,
                  logging: true,
                  // msg: `Requested to resume`,
                  action: "resume_requested",
                  // msg: mrIntl("ResumeView.requested_to_resume"),
                },
              }
            );
          }
          // else {
          //   message.error("Your internet connection not stable. Please check your internet connection and try again.")
          // }
        }, mrIntl)
        
      }}
    >
      {sessionSwitching ? mrIntl("ResumeView.request_switch_here") : mrIntl("ResumeView.request_resume")}
      {/* {experienceSettings.mode === "online" ? mrIntl("ResumeView.resume") :  <MrTranslate id={"ResumeView.request_resume"}/> }  <SolarPenNewSquareBold className="resume-button-icon" /> */}
    </Button>
  );

  let cancelRequestResumeBtn = (
    <Button
      danger
      onClick={() => {
        !embedded &&
            experienceViewMode === "apTakeTest" &&
            writeToClipboardPolyfill("");
        setUserInfo(
          {
            request_resume: "initial",
            request_resume_session_id: userInfoFromReduxsessionId,
          },
          {
            log: {
              logging: true,
              action: "resume_request_cancelled",
            },
          }
        );
      }}
    >
      <MrTranslate id={"ResumeView.cancel_request"}/>
    </Button>
  );

  const extra = [];

  if (!isTimeUp) {
    if (experienceSettings.mode === "online") {
      extra.push(resumeButton);
    } else {
      extra.push(requestResumeBtn);
      if (resumeRequested) {
        extra.push(cancelRequestResumeBtn);
      }
    }
  } else {
    extra.push(endButton);
  }


  return (
    <div className="resume-view-container">
      <ViewStatus
        statusIcon={<SolarPlayCircleBold />}
        statusText={mrIntl("ResumeView.resume_test")}
        statusTextType={"warning"}
        statusMessage={isUploadDeviceSession? mrIntl("ResumeView.resume_one_of_the_session") : mrIntl("ResumeView.review_your_progress_and_click_resume_test_to_continue_from")}
      />
      <ViewCard1
        experience={experience}
        showConfig={showConfig}
        quitButtonAction="quit_app_before_resuming"
        // footerCenterComponent={timer} 
        footerLeftComponent={
          !embedded && appType === "web" && 
            <BackToHomeBtn 
              key="join-view-back-to-home-button" 
              experience={experience} action="back_to_home_before_starting_test" 
              className={'back-to-home-button'}
            />} 
        footerRightComponent={!isUploadDeviceSession ? extra : []} 
      />
    </div>
  );
};

export default ResumeView;