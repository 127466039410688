import { Col, Modal, Row } from "antd";
import _ from "lodash";
import React from "react";
import RubricCriteria from "./RubricCriteria";
import RubricLevels from "./RubricLevels";

const PreviewRubrics = (props) => {
const { addLevels, deleteLevels, customOnChange, state,menuItem, createMode, addCriteria, showPreviewRubrics, setShowPreviewRubrics } = props
  return(
    <Modal
      open={showPreviewRubrics}
      width="100%"
      onCancel={() => setShowPreviewRubrics(false)}
      wrapClassName="preview-rubrics"
    >
      <Row>
        <Col>
          {state && state.criteria.map((criteria, index) => {
            if (!criteria._destroy) {
              return (
                <RubricCriteria 
                  criteria={criteria} 
                  index={index}
                  state={state} 
                  menuItem={menuItem}
                  customOnChange={customOnChange}
                  addCriteria={addCriteria} 
                  createMode={false} />
              )
            }
          })}
        </Col>
        <Col className="levels-main-wraper">
          {state && state.criteria.map((criteria, index) => {
            if (!criteria._destroy) {
              return (
                <div style={{ position: "relative" }}>
                  <div className="criteria-wrapper">
                    <div style={{ display: "flex" }}>
                      {!_.isEmpty(criteria.levels_json) && criteria.levels_json.map((level, levelIndex) => {
                        return (
                          <RubricLevels
                            levels={level}
                            levelIndex={levelIndex}
                            addLevels={addLevels}
                            parentIndex={index}
                            deleteLevels={deleteLevels}
                            customOnChange={customOnChange}
                            useScoring={state.settings.use_scoring}
                            oneLevelForAll={state.settings.one_level_for_all}
                            criteria={criteria}
                            menuItem={menuItem}
                            createMode={false}
                          />
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </Col>
        
      </Row> 
    </Modal>
  )
}

export default PreviewRubrics;