import React, { useState } from "react";
import { Button, Tooltip, Menu, Dropdown } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import {
  CheckOutlined,
  MinusOutlined,
  CloseOutlined,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
  CloseCircleTwoTone
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import dayjs from "dayjs";
import { checkMob, dropDownTrigger } from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";


const ExperienceStatusBtn = (props) => {
  const { experience } = props;
  const experienceSettings = experience.settings;
  const dispatch = useDispatch();

  const mrIntl = useTranslate()
  const appType = useSelector(appTypeSelector());
  const isMobile = checkMob();
  let finalRender = [];
  const [loading, setLoading] = useState(false);
 
  const publishTimeAndDate = dayjs(experience.updated_at).format(
    "MMM DD, HH:mm"
  )
  const closeTimeAndDate = dayjs(
    experienceSettings.close_at_datetime
  ).format("MMM DD, HH:mm:ss")

  let statusStyle;
  if (experience.status === "published") {
    statusStyle = {
      // className: "bg-green border-green",
      // background: "limegreen",
      // borderColor: "limegreen",
      icon: <CheckOutlined />,
      buttonType: "primary",
      // tooltipTitle: `Publish time ${dayjs(experience.updated_at).format(
      //   "MMM DD, HH:mm"
      // )}`,
      tooltipTitle: mrIntl("ExperienceStatusBtn.publish_date_and_time", {publishTimeAndDate: publishTimeAndDate }),
      // fix this -> save publish time and show that
    };
  } else if (experience.status === "draft") {
    statusStyle = {
      // className: "bg-orange border-orange",
      // background: "orangered",
      // borderColor: "orangered",
      icon: <MinusOutlined />,
      buttonType: "primary",
      tooltipTitle: "",
      danger: true
    };
  } else if (experience.status === "closed") {
    statusStyle = {
      // className: "bg-grey border-grey",
      // background: "lightslategrey",
      // borderColor: "lightslategrey",
      icon: <CloseOutlined />,
      buttonType: "primary",
      // tooltipTitle: `Close time ${closeTimeAndDate}`,
      tooltipTitle: mrIntl("ExperienceStatusBtn.close_time", {closeTimeAndDate: closeTimeAndDate })
    };
  }

  const updateExperienceStatus = (statusToSet) => {
    dispatch(
      experienceActions.updateStatus(
        { id: experience.id, status: statusToSet },
        {
          successCallback: (response) => {
            console.log("updateExperienceStatus response ===>", response);
            // TODO merge/clean - only works from show view - need to also make it work for list view
            dispatch(
              experienceActions.showSuccess({
                data: {
                  experience: {
                    ...experience,
                    status: response.data.experience.status,
                    // custom_fields: response.data.experience.custom_fields
                    settings: {
                      ...experience.settings,
                      lockdown_config: response.data.experience.settings.lockdown_config,
                    },
                  },
                },
              })
            );
            // TODO merge/clean - need this for when called from list view
            // dispatch(
            //   experienceActions.showSuccess({
            //     data: {
            //       experience: {
            //         ...experience,
            //         status: response.data.experience.status,
            //       },
            //     },
            //   })
            // );
            // message.success(
            //   `Assessment ${
            //     statusToSet === "draft" ? "changed to draft" : statusToSet
            //   }`
            // );
            // message.success(statusToSet === "draft" ?
            // "Assessment change to draft" : statusToSet === "publish" ? "Assessment change to published" : "Assessment closed"
            // )
            
            message.success(
              statusToSet === "draft" 
                ? mrIntl("ExperienceStatusBtn.changed_to_draft_success_msg")
                : statusToSet === "published" 
                  ? mrIntl("ExperienceStatusBtn.changed_to_published") 
                  : mrIntl("ExperienceStatusBtn.assessment_closed")
            )
            setLoading(false);
          },
          errorCallback: (errors) => {
            console.log("errors ==>", errors);
            if (errors.length > 0) {
              errors.map((error) => {
                message.error(error.message);
              });
            } else {
              message.error(mrIntl("ExperienceStatusBtn.error_in_updating_msg"));
            }
            setLoading(false);
          },
        }
      )
    );
  }

  const onExperienceStatusChange = (statusToSet) => {
    if (statusToSet === "published" && experience.attemptable_questions_count === 0) {
      message.error(
        mrIntl("ExperienceManage.publish_assessment_error_msg")
      );
      return;
    }
    setLoading(true);
    
    dispatch(
      firestoreInteractionActions.getExperienceInfo(
        {
          experienceId: experience.id,
        },
        {
          successCallback: (data) => {
            console.log("inside callback==>", data);
            if (statusToSet === "draft" && data.haveStudentsStarted) {
              showConfirmModal({
                title: mrIntl("CommonText.some_students_have_already_started_this_assessment_msg"),
                onOk: () => {
                  updateExperienceStatus(statusToSet);
                },
                onCancel: () => {
                  setLoading(false);
                  return;
                },
                okButtonProps: {
                  type: 'primary'
                },
                mrIntl: mrIntl
              });
            } else {
              updateExperienceStatus(statusToSet);
            }
          },
          errorCallback: (data) => {
            setLoading(false);
            updateExperienceStatus(statusToSet);
          }
        }
      )
    );


    
    // updateResource(
    //   { uid: experience.uid, status: statusToSet },
    //   {
    //     success: {
    //       showMessage: true,
    //       message: `Assessment ${statusToSet === "draft" ? "changed to draft" : statusToSet }`,
    //     },
    //     error: {
    //       showMessage: true,
    //       message: "Error in updating assessment status",
    //     },
    //     successCallback: (data) => {
    //       console.log("status updated", data);
    //     },
    //   }
    // );
  };

  let menuItems = [];
  if (experience.status === "draft") {
    menuItems.push(
      <Menu.Item
        className="assessment-status"
        key="menu-experience-status-1"
        title={mrIntl("ExperienceStatusBtn.publish_test")}
        icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
        onClick={() => onExperienceStatusChange("published")}
      >
       {mrIntl("CommonText.publish")}
      </Menu.Item>
    );
  } else if (experience.status === "published") {
    // if(experience.submissions_count == 0){
    menuItems.push(
      <Menu.Item
       className="assessment-status"
        key="menu-experience-status-3"
        title={
          experience.submissions_count !== 0
            ? mrIntl("CommonText.cannot_convert_to_draft_msg")
            : mrIntl("CommonText.change_assessment_to_draft")
        }
        disabled={experience.submissions_count !== 0 ? true : false}
        icon={<MinusCircleTwoTone twoToneColor="#eb2f96" />}
        onClick={() => onExperienceStatusChange("draft")}
      >
        {mrIntl("CommonText.draft")}
      </Menu.Item>
    );

    menuItems.push(
      <Menu.Item
        key="menu-experience-status-2"
        title={mrIntl("CommonText.close_test")}
        icon={<CloseCircleTwoTone twoToneColor="lightslategrey" />}
        onClick={() => onExperienceStatusChange("closed")}
      >
        {mrIntl("CommonText.close")}
      </Menu.Item>
    );

    // }
    // else{
    //   menuItems.push(<Menu.Item key="menu-experience-status-2" icon={<CloseCircleTwoTone twoToneColor="lightslategrey"/>} onClick={() => updateExperienceStatus("closed")}>
    //     Close
    //   </Menu.Item>)
    // }
  } else if (experience.status === "closed") {
    menuItems.push(
      <Menu.Item
        key="menu-experience-status-3"
        disabled="true"
        title={mrIntl("CommonText.cannot_convert_to_draft_after_closed")}
        icon={<MinusCircleTwoTone twoToneColor="#eb2f96" />}
      >
        {mrIntl("CommonText.draft")}
      </Menu.Item>
    );

    menuItems.push(
      <Menu.Item
        key="menu-experience-status-4"
        title={mrIntl("CommonText.open_again_test")}
        icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
        onClick={() => onExperienceStatusChange("published")}
      >
        {mrIntl("CommonText.publish")}
      </Menu.Item>
    );
  }

  const deliverStatusActions = <Menu className="deliver-status-btn-menu">{menuItems}</Menu>;

  const changeStatusBtn = (
    <Tooltip placement="left" title={statusStyle.tooltipTitle}>
      <Dropdown
        overlay={deliverStatusActions}
        placement="bottomRight"
        trigger={[dropDownTrigger]}
      >
        <Button
          loading={loading}
          // className={statusStyle.className}
          style={{
            // background: statusStyle.background,
            // borderColor: statusStyle.borderColor,
            textTransform: "capitalize",
            color:"white"
          }}
          shape={isMobile ? "circle" : "round"}
          icon={statusStyle.icon}
          ghost={false}
          type={statusStyle.buttonType}
          trigger={"click"}
          danger={statusStyle.danger}
        >
          {/* {experience.status} <DownOutlined /> */}
          {!isMobile && experience.status}
          {/* <DownOutlined /> */}
        </Button>
        {/* <Button style={experience.status == "published" ? { background: "limegreen", borderColor: "limegreen" } : { background: "orangered", borderColor: "orangered" }} shape="round" icon={experience.status == "published" ? <CheckOutlined /> : <MinusOutlined />} ghost={false} type={experience.status == "published" ? "primary" : "danger"}>
        {experience.status}
        </Button> */}
      </Dropdown>
    </Tooltip>
  );

 
  finalRender.push(changeStatusBtn)

  return finalRender;
};

export default ExperienceStatusBtn;
