import { Button, Tooltip } from "antd";
import React from "react";
import { ImMagicWand } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { isAIAssistantDisabledSelector } from "/src/views/Orgs/selector";
import { addAllButtonLoadingSelector, aiGenerateLoadingSelector } from "./selector";
import { currentUserSelector, enabledFeaturesSelector } from "../../Auth/Login/selector";
import { isNewGenerateQuestionsWithAI } from "./generateAIHelperMethods";
import { actions as orgActions } from "../../Orgs/redux";
import { activeAdjustedExperienceSelector } from "../../Experiences/selector";
import { getCurrentPlanStatus } from "/src/lib/utils/helperMethods";

const MagicButton = ({
  shape = "circle",
  size = "middle",
  ghost = false,
  onClick,
  text,
  icon,
  type = 'default',
  disabled = false,
  loading = false,
  tooltipTitle = "",
  tooltipPlacement,
  mode="primary",
  extraClassName="",
  feature_code=""
}) => {
  console.log("MagicButton -> tooltipTitle", shape, disabled)
  
  const isAIAssistantDisabled = useSelector(isAIAssistantDisabledSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const aiGenerateLoading = useSelector(aiGenerateLoadingSelector());
  const addAllButtonLoading = useSelector(addAllButtonLoadingSelector());
  const dispatch = useDispatch()
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const currentUser = useSelector(currentUserSelector());
  const experienceCustomFields = (activeExperience && activeExperience.custom_fields) || {}
  const options = {
    programmeLabel: experienceCustomFields.org_programme_item_label 
  }
  const { isNonDPProgramWithOnlyDPMaxPlan } = getCurrentPlanStatus(currentUser, options)
  const showUpgradePlanModalForFeatures = ["ai_generate_questions_with_prompt", "grade_with_ai"];

  // Determine if the upgrade plan modal should be shown is user's plan is APDPMAX but not APMAX and programme ≠ DP or if particular feature flag is disabled.
  const showUpgradePlanModal =
    (showUpgradePlanModalForFeatures.includes(feature_code) &&
    !enabledFeatures[feature_code]) || isNonDPProgramWithOnlyDPMaxPlan;

  const handleMagicButtonClick = () => {
    if (showUpgradePlanModal) {
      dispatch(
        orgActions.setShowUpgradePlanModalSuccess({
          show: true,
          feature_code: feature_code,
        })
      );
    } else {
      if (onclick) {
        onClick();
      }
    }
  };

  const newGenerateQuestionsWithAI = isNewGenerateQuestionsWithAI(enabledFeatures);
  let disableMagicButton = isAIAssistantDisabled.disabled || disabled;

  if (
    newGenerateQuestionsWithAI &&
    (aiGenerateLoading || addAllButtonLoading)
  ) {
    disableMagicButton = true;
  }
  console.log("isAIAssistantDisabled ===>", isAIAssistantDisabled);
  if (isAIAssistantDisabled && isAIAssistantDisabled.message) {
    if (!tooltipTitle) {
      tooltipTitle = "";
    } else {
      tooltipTitle = " - ";
    }
    tooltipTitle = tooltipTitle.concat(" - ").concat(isAIAssistantDisabled.message)
    // .concat(" - Learn more") // TODO: learn more to open AI features modal -  learn more modal if reason is subject disable or standard plan
  }
  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
      <Button
        loading={loading}
        type={type}
        disabled={disableMagicButton}
        className={`${mode === "primary" ? "magic-button-primary" : "magic-button-subtle"} ${shape === "circle" ? "magic-button-circle" : ""} ${extraClassName}`}
        shape={shape}
        size={size}
        ghost={ghost}
        icon={icon ? icon : <ImMagicWand />}
        onClick={() => handleMagicButtonClick()}
      >
        {text && <>{text}</>}
      </Button>
    </Tooltip>
  );
};

export default MagicButton;
