import { Spin } from "antd";
import React, { useRef, useEffect } from "react";
import "/src/views/Experiences/ExperienceShow/Components/Calculator/Calculator.scss" 


export function showCalcLoader(isShow) {
  var desmosDiv = document.getElementsByClassName("desmos-calculator")[0];
  if (desmosDiv) {
    if (isShow) {
      desmosDiv.classList.add("desmos-loading");
    } else {
      desmosDiv.classList.remove("desmos-loading");
    }
  }
}

const DesmosScientific = (props) => {
  console.log("Desmos Props ==>", props);

  const { showLoading = false, isFullScreen, styleCalc } = props;
  const desmosRef = useRef();
  const Desmos = window.Desmos;
  useEffect(() => {
    if (Desmos) {
      Desmos.ScientificCalculator(desmosRef.current, { degreeMode: true });
    }
  }, []);
  const className = isFullScreen
    ? "desmos-scientific-with-full-screen"
    : styleCalc;

  return (
    <Spin spinning={showLoading} size="large">
      <div
        className={`desmos-calculator`}
      >
        <Spin size="large" />
        <div
          ref={desmosRef}
          className={className}
          // style={{ width: "350x", height: "350px" }}
        ></div>
      </div>
    </Spin>
  );
};

export default DesmosScientific;
