import React, { useState } from "react";
import { Col, List, Card, Tooltip, Button, Popconfirm, Alert, Dropdown, Menu, Tabs } from "antd";
// import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import { useRouteMatch } from "react-router-dom";
import './SegmentList.scss';
import AddSegmentSelector from "../CustomSegmentSelector/AddSegmentSelector"
import { EditOutlined, DeleteOutlined, LinkOutlined, PlusOutlined, CloseOutlined, DragOutlined, CopyOutlined, MoreOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
// import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import {activeAdjustedExperienceIdSelector, activeAdjustedExperienceSelector} from "/src/views/Experiences/selector"
import { deleteLoadingSelector, duplicateLoadingSelector } from "/src/views/Segments/selector"
import { isPinnedSelector, pinnedSegmentsSelector } from "/src/views/Segments/selector"
import { checkIPAD, checkMob, getDestinationPositionLex, getQuestionContent, getQuestionNumber } from "/src/lib/utils/helperMethods";
import { activeTopicIdSelector, topicsSelector } from "../Topics/selector";
import NoData from "/src/components/UI/NoData/NoData";
import GoToNextSection from "/src/components/UI/Segment/GoToNextSection";
import { currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector } from "/src/views/Auth/Login/selector";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { updateSegmentTypeCount } from "/src/views/Experiences/ExperienceHelperMethods";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { actions as topicActions } from "/src/views/Segments/Topics/redux"
import SegmentItemFeedback from "./SegmentItemFeedback";
import { activeAISegmentPositionSelector, aiGenerateModeSelector } from "../GenerateAI/selector";
import SegmentItemDetail from "../SegmentItemDetail/SegmentItemDetail";
import PinnedSegmentPlaceholder from "../Components/PinnedSegmentPlaceholder";
import MagicButton from "../GenerateAI/MagicButton";
import { isNewGenerateQuestionsWithAI, onGenerateSimilar } from "../GenerateAI/generateAIHelperMethods";
import ExtraAIActionsHeader from "../GenerateAI/ExtraAIActionsHeader";
import AISegmentsWrapper from "./AISegmentsWrapper";
import Markscheme from "../Markscheme/Markscheme";
import AnswerExplanation from "../Explanation/AnswerExplanation";
import { actions as orgActions } from "../../Orgs/redux";

const SegmentList = (props) => {
  console.log("segment list props==> SegmentList", props.isSegmentInAIList);
  const { segments, customPropSegments, renderQuestionAreaOnly, librarysegments, permission, rearrangeMode, topicProps, params, libraryModalProps, readingModeEnabled, printMode, mobileUploadMode, updateResource, parentId, parentSegment, parentIdxs, activeTopicIdx, nestingLevel, onlyExplanationMode, gradingConfig, showAnnotatorToStudent, experienceUser, isSegmentInAIList, aiSegments, addedAiSegments } = props;
  // const [experienceViewMode, setExperienceViewMode] = useState(props.parentInstance.props.experienceViewMode)
  const { ErrorBoundary } = Alert;
  let experienceViewMode = props.parentInstance.props.experienceViewMode
  let isLibrary = props.parentInstance.props.isLibrary;
  let experience = ""
  let segmentsToShow = segments
  const embedded = useSelector(getEmbeddedSelector());
  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const aiGenerateMode = useSelector(aiGenerateModeSelector());
  const activeAISegmentPosition = useSelector(activeAISegmentPositionSelector());
  if (props.experienceProps) {
    experience = props.experienceProps.item
    // embedded = props.experienceProps.embedded
  }
  console.log("line no 49=>", segments);
  const isQuestionBankExperience = experience && experience.questionbank ? true : false
  const isAdminOrTeacher = currentUser.role === "admin" || currentUser.role === "teacher"
  const isQBAuthor = currentUser.role === "qb_author"
  const questionBankId = params.by_questionbank_id
  if(librarysegments){
    segmentsToShow = librarysegments
  }
  if (customPropSegments) {
    segmentsToShow = customPropSegments
  }
  if (isSegmentInAIList && aiSegments) {
    segmentsToShow = aiSegments;
  }

  console.log("line number 79 in segment list==>", props.resourceName, nestingLevel, segmentsToShow, props)
  // if(children){
  //   segments = children
  // }
  console.log("segmentsToShow", isLibrary, nestingLevel, librarysegments)
  console.log("topicProps ==> 49", topicProps, topicProps && topicProps.item)
  let topic = {}
  if(topicProps && topicProps.item){
    topic = topicProps.item.section_segment_attributes
  }
  

  let initValuesForLibraryForm = {}
  let formName = "segments"
  let isLibraryModal = false
  // let mobileUploadMode = true // temp - - receive from parent - getting in EID from params
  
  const { TabPane } = Tabs;
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  // const [segmentViewMode, setSegmentViewMode] = useState("show");
  const [editModeForSegmentId, setEditModeForSegmentId] = useState(0);
  const [createModeAfterSegmentId, showCreateModeAfterSegmentId] = useState(0);
  const [activeEditQuestion, setActiveEditQuestion] = useState(null);
  const activeAdjustedExperienceId = useSelector(activeAdjustedExperienceIdSelector())
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  // const topicSegments = useSelector(segmentsSelector(activeTopicId))
  const pinnedSegments = useSelector(pinnedSegmentsSelector())
  const isPinned = useSelector(isPinnedSelector());
  const topics = useSelector(topicsSelector())
  let activeTopicId = useSelector(activeTopicIdSelector())
  
  // const activeSegment = useSelector(activeSegmentSelector())
  // if (activeSegment && activeSegment.segment_type == "section") {
  //   activeTopicId = activeSegment.id
  // }

  let isSegmentsLimitReached = !isLibrary && segmentsToShow.length >= 50
  let newGenerateQuestionsWithAI = isNewGenerateQuestionsWithAI(enabledFeatures);

  console.log("check if isPinnedSelector working ==>", isPinned)
  console.log("seg list item url", url);
  console.log("segment list props", props, experienceViewMode, isLibrary);
  console.log('activetopic id==>', activeTopicId);
  // function setLogs(
  //   msg,
  //   segmentId,
  //   experienceId,
  //   segmentAction,
  //   segmentType,
  //   questionType,
  //   archivedContent
  // ) {
  //   let logsConfig = {
  //     logging: true,
  //     log_type: "segment",
  //     msg: msg,
  //     experienceId: experienceId,
  //     itemId: segmentId,
  //     segmentAction: segmentAction,
  //     segmentType: segmentType,
  //     questionType: questionType,
  //   };
  //   if (archivedContent) {
  //     logsConfig = {
  //       ...logsConfig,
  //       archivedContent: archivedContent,
  //     };
  //   }
  //   dispatch(firestoreInteractionActions.setLogs(logsConfig));
  // }
  
  // MOVED out to TopicItemDetail and LibraryModal and sending down
  // let [activeTopicIdx, setActiveTopicIdx] = useState(0)
  // // let currentParentIdxs = parentIdxs || []
  // useEffect(() => {
  //   if (activeTopicId) {
  //     let idx = topics.findIndex((topic) => topic.id == activeTopicId)
  //     setActiveTopicIdx(idx)
  //   }
  // }, [activeTopicId])

  let isMobile = checkMob()
  const isIPAD = checkIPAD();

  if (isLibrary) {
    // experienceViewMode = "apEditTest"
    formName = "librarySegments"
    initValuesForLibraryForm = {
      questionbank_id: params.by_questionbank_id,
      subject_name: params.by_subject_name,
      grade_name: params.by_grade_name,
    }
    if (props.isLibraryModal){
      isLibraryModal = props.parentInstance.props.isLibraryModal
    }
  }
  let createAllowed = permission.segment && permission.segment.create && experienceViewMode === "apEditTest" && (experience.status === "draft" || (isLibrary && !isLibraryModal)) && !renderQuestionAreaOnly;
  let editAllowed = permission.segment && permission.segment.update && experienceViewMode === "apEditTest" && (experience.status === "draft" || (isLibrary && !isLibraryModal))
  if(experience){
    createAllowed = createAllowed && experience.master_editable && experience.current_user_can_edit
    editAllowed = editAllowed && experience.master_editable && experience.current_user_can_edit
  }
  let firstCreateAllowed = createAllowed
  let createAllowedAfterEachSegment = createAllowed && !isLibrary

  // Not allowing edits in embedded for now - handles MB task preview mode for drafts as well
  if(embedded){
    createAllowed = editAllowed = firstCreateAllowed = createAllowedAfterEachSegment = false
  }

  const pinnedSegmentToRender = []
  const finalRender = [];
  const mrIntl = useTranslate();

  if (!isMobile && topicProps && nestingLevel === 1) {
    finalRender.push(
      <h3 style={{ paddingLeft: "20px" }} id={`topic-${topicProps.item.id}`}>
        <RenderHtml text={topicProps.item && topicProps.item.section_segment_attributes && topicProps.item.section_segment_attributes.title} />
      </h3>
    );
  }

  let segmentListConfig = {
    layout: "vertical_list" // Default
  }
  if(parentSegment && parentSegment.segment_type === "question" && parentSegment.question_segment_attributes.question_type === "group" && nestingLevel > 1 && parentSegment.settings.is_game && enabledFeatures.games){
    segmentListConfig.layout = "horizontal_tabs"
  }

  const switchMode = (mode, segmentId = 0) => {
    console.log("segmentId", segmentId);
    console.log("mode", mode);
    showCreateModeAfterSegmentId(0)
    setEditModeForSegmentId(0);
    // setSegmentViewMode("show");
    if (mode === "edit") {
      // setSegmentViewMode("edit");
      setEditModeForSegmentId(segmentId);
    }
    else if (mode === "create") {
      showCreateModeAfterSegmentId(segmentId)
    }
  }

  const EditButton = (props) => {
    return (
      <Tooltip title={ mrIntl("CommonText.edit")}>
        <Button type="text" shape="circle" icon={<EditOutlined />} 
          onClick={(event) => { 
            switchMode("edit", props.segment.id); 
            props.showForm({ name: props.formName });  
          }} />
      </Tooltip>
      // <EditOutlined onClick={(event) => switchMode("edit", props.segment.id)} />
    )
  }
  // const DeleteButton = ({segment}) => {
  //   return (
  //     <ActionBtns
  //         delete={true}
  //         deleted={props.deleted}
  //         item={segment}
  //         id={segment.id}
  //       />
  //     // <EditOutlined onClick={(event) => switchMode("edit", props.segment.id)} />
  //   )
  // }
  const CancelButton = (props) => {
    return (
      <Tooltip title={mrIntl("CommonText.cancel")}>
        <Button shape="circle" ghost={true} danger icon={<CloseOutlined />} onClick={(event) => { switchMode("cancel"); props.showForm({ name: undefined }) }} />
      </Tooltip>
    )
  }

  const EmbedButton = (props) => {
    return (
      <Tooltip title= {mrIntl("SegmentList.embed")}>
        <Button type="text" shape="circle" icon={<LinkOutlined />} href={`/embed/${props.segment.uuid}`} target="_blank" />
      </Tooltip>
    )
  }
  const DupButton = (props) => {
    const duplicateLoading = useSelector(duplicateLoadingSelector())
    console.log("duplicateLoading==>", duplicateLoading, props)
    let segmentDuplicateLoading = duplicateLoading && duplicateLoading[props.segment.id];

    return (
      <Tooltip title={ mrIntl("SegmentList.duplicate")}>
        <Button type="text"
         loading={segmentDuplicateLoading}
         icon={<CopyOutlined />}
         disabled={segmentDuplicateLoading ? false : duplicateLoading}
         onClick={() =>{
          let prevPosition = (segmentsToShow[props.index] || {}).position
          let nextPosition = (segmentsToShow[props.index + 1] || {}).position
          let destinationPosition = getDestinationPositionLex(prevPosition, nextPosition)
          dispatch(segmentActions.duplicate(
            // , previousId: add to first obj
            {id: props.segment.id, previous_id: props.segment.id, destination_position: destinationPosition}, 
            // {id: props.segment.id}, 
            {duplicateAtIndex: props.index+1, parentId: props.segment.parent_id, successCallback: (data) => {
              let updatedSegment = data.data.segment.data.attributes
              // const questionType =
              //   updatedSegment.segment_type === "question"
              //     ? updatedSegment.question_segment_attributes.question_type
              //     : updatedSegment.segment_type === "resource"
              //     ? updatedSegment.resource_segment_attributes.resource_type
              //     : "";
              console.log("duplicate segment done==>", updatedSegment);
              if(experience){
                // !causing re-rendering of all levels of SegmentLists
                dispatch(segmentActions.setParentsPoints({ updatedSegment }));
                // let msg = `${
                //   updatedSegment.segment_type === "resource" || updatedSegment.segment_type === "text"
                //     ? "Content"
                //     : "Question"
                // } duplicated by ${currentUser.name} (${upperFirst(
                //   currentUser.role
                // )})`;
                // setLogs(msg, updatedSegment.id, updatedSegment.experience_id, "duplicated", updatedSegment.segment_type, questionType)
                // dispatch(
                //   firestoreInteractionActions.setLogs({
                //     logging: true,
                //     log_type: "segment",
                //     msg: msg,
                //     experienceId: updatedSegment.experience_id,
                //     itemId: updatedSegment.id,
                //     segmentAction: "duplicated",
                //     segmentType: updatedSegment.segment_type,
                //     questionType:
                //       updatedSegment.segment_type === "question"
                //         ? updatedSegment.question_segment_attributes
                //             .question_type
                //         : updatedSegment.segment_type === "resource"
                //         ? updatedSegment.resource_segment_attributes
                //             .resource_type
                //         : "",
                //   })
                // );
                
              }
            }}
          ))}} >
            {segmentDuplicateLoading ? <MrTranslate id={"SegmentList.duplicating_button"}/> : ""}
        </Button>
       </Tooltip>
    )
  }
  
  const MoreActions = (props) => {
    const { item } = props;
    console.log("MoreActions ======>", props, segmentActions);
    const showLogsHistory = () => {
      dispatch(segmentActions.setActiveLogsHistoryForSegmentIdSuccess({
        activeLogsHistoryForSegmentId: item.id
      }))
    }

    const menu = (
      <Menu>
        <Menu.Item onClick={() => showLogsHistory()}>
         <MrTranslate id={"SegmentList.logs_history"}/>
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <Button type="text" shape="circle" icon={<MoreOutlined />}></Button>
      </Dropdown>
    );
  };

  const DeleteButton = (props) => {    
    const archiveLoading = useSelector(deleteLoadingSelector())
    let segmentArchiveLoading = archiveLoading && archiveLoading[props.item.id];

    const confirm = e => {
      e.stopPropagation()
      props.deleted(props.item, {
        success: {showMessage: true, message: mrIntl("SegmentList.item_deleted")},
        successCallback: (data) => {
          let updatedSegment = data.data.segment.data.attributes
          // const questionType = updatedSegment.segment_type === "question"
          //         ? updatedSegment.question_segment_attributes.question_type
          //         : updatedSegment.segment_type === "resource"
          //         ? updatedSegment.resource_segment_attributes.resource_type
          //         : ""
          // console.log("delete segment done==>", updatedSegment);
          // let archivedContent;
          // if (updatedSegment.segment_type === "question") {
          //   if (
          //     updatedSegment.question_segment_attributes.question_type ===
          //       "cke_fill_text" ||
          //     updatedSegment.question_segment_attributes.question_type ===
          //       "cke_fill_dropdown"
          //   ) {
          //     archivedContent =
          //       updatedSegment.question_segment_attributes.teacher_json
          //         .segment_data.content;
          //   } else {
          //     archivedContent =
          //       updatedSegment.question_segment_attributes.content;
          //   }
          // } else if (updatedSegment.segment_type === "resource") {
          //   archivedContent =
          //     updatedSegment.resource_segment_attributes.title;
          // } else if (updatedSegment.segment_type === "text") {
          //   archivedContent =
          //     updatedSegment.text_segment_attributes.content;
          // }

          if(experience){
            // let msg = `${props.item.segment_type === 'resource' ? 'Content' : 'Question' } archived by ${currentUser.name} (${upperFirst(currentUser.role)})`
            // setLogs(msg, updatedSegment.id, updatedSegment.experience_id, "archived", updatedSegment.segment_type, questionType, archivedContent)
            // dispatch(
            //   firestoreInteractionActions.setLogs({
            //     logging: true,
            //     log_type: "segment",
            //     msg: msg,
            //     itemId: updatedSegment.id,
            //     experienceId: props.item.experience_id,
            //     segmentAction: "archived",
            //     segmentType: updatedSegment.segment_type,
            //     questionType:
            //       updatedSegment.segment_type === "question"
            //         ? updatedSegment.question_segment_attributes.question_type
            //         : updatedSegment.segment_type === "resource"
            //         ? updatedSegment.resource_segment_attributes.resource_type
            //         : "",
            //     archivedContent: archivedContent
            //   })
            // );
            // dispatch(firestoreInteractionActions.setUserInfo(
            //   {
            //     id: currentUser.id
            //   },
            //   {
            //     log: {
            //       logging: true,
            //       log_type: "experience",
            //       msg: msg,
            //     },
            //   }
            // ))
            dispatch(segmentActions.setParentsPoints({ updatedSegment }));
            updateSegmentTypeCount(data, dispatch, experience);
          }
        }
      });
      // message.success("Archived successfully");
    };
    const cancel = e => {
      e.stopPropagation()
      // message.error("Delete cancelled");
    };
    return (
      <Popconfirm
        key={`delete-pop-confirm-${props.item.id}`}
        title={mrIntl("CommonText.confirm_archive")}
        onConfirm={confirm}
        onCancel={cancel}
        okText={mrIntl("CommonText.yes")}
        cancelText={mrIntl("CommonText.no")}
      >
        <Tooltip title={ mrIntl("SegmentList.archive") } placement="bottom">
          <Button onClick={(event) => event.stopPropagation()} type="text" shape="circle"
            className="segment-delete-button"
            loading={segmentArchiveLoading}
            disabled={segmentArchiveLoading ? false : archiveLoading}
            icon={<DeleteOutlined />}>
            {segmentArchiveLoading ? <MrTranslate id={"SegmentList.archiving_button"} /> : ""}
          </Button>
        </Tooltip>
      </Popconfirm>
    )
  }

  const DupFromLibButton = (props) => {
    const { segment, libraryModalProps, activeAdjustedExperienceId, activeTopicId, activeExperience} = props
    const { libraryModalConfig, setLibraryModalConfig } = libraryModalProps
    const [duplicateLoading, setDuplicateLoading] = useState(false)
    console.log("DupFromLibButton props", props)
    return (
      <Tooltip title={mrIntl("CommonText.add")}>
        <Button type="primary" ghost={false} loading={duplicateLoading} shape="round" icon={< PlusOutlined />} onClick={() =>{
          console.log("segment type libraryModalConfig ==>", segment.segment_type, libraryModalConfig, segment);
          setDuplicateLoading(true)
          if(activeAdjustedExperienceId && activeTopicId) {
            if(segment.segment_type === "section"){
              // Not using activeTopicIndex because that is librarySegments topic index that always comes as 0, so we need to find the currentTopicIndex from topics
              const currentTopicIndex = topics.findIndex((topic) => topic.id == activeTopicId);
              const nextTopicIndex = currentTopicIndex + 1;
              const nextPosition = (topics[nextTopicIndex] || {}).position;
              const previousPosition = topics[currentTopicIndex].position;
              dispatch(topicActions.duplicate(
                {
                  id: segment.id, 
                  experience_id: activeAdjustedExperienceId, 
                  parent_id: activeAdjustedExperienceId, 
                  destination_position: getDestinationPositionLex(previousPosition, nextPosition),
                  previous_id: activeTopicId
                }, 
                {
                  duplicateAtIndex: nextTopicIndex, 
                  parentId: activeAdjustedExperienceId, 
                  successCallback: (data) => {
                    setDuplicateLoading(false)
                    let updatedSegment = data.data.segment.data.attributes
                    console.log("insert from lib done==>", updatedSegment)
                    if(activeExperience){
                      dispatch(segmentActions.setParentsPoints({ updatedSegment }));
                    }
                  }
                }
              ))
            } else {
               dispatch(segmentActions.duplicate(
                {
                  id: segment.id, 
                  experience_id: activeAdjustedExperienceId, 
                  parent_id: activeTopicId, 
                  destination_position: libraryModalConfig.addFromLibDestinationPosition,
                  previous_id: libraryModalConfig.addFromLibPreviousId
                }, 
                {
                  duplicateAtIndex: libraryModalConfig.addFromLibAtIndex, 
                  parentId: activeTopicId, 
                  successCallback: (data) => {
                    setDuplicateLoading(false)
                    let updatedSegment = data.data.segment.data.attributes
                    console.log("insert from lib done==>", updatedSegment)
                    if(activeExperience){
                      dispatch(segmentActions.setParentsPoints({ updatedSegment }));
                      let newPrevPosition = updatedSegment.position
                      let newPrevId = updatedSegment.id
                      setLibraryModalConfig((libraryModalConfig) => {
                        return {
                          ...libraryModalConfig,
                          addFromLibPreviousId: newPrevId,
                          addFromLibPrevPosition: newPrevPosition,
                          addFromLibDestinationPosition: getDestinationPositionLex(newPrevPosition, libraryModalConfig.addFromLibNextPosition),
                          addFromLibAtIndex: libraryModalConfig.addFromLibAtIndex + 1,
                        };
                      });
                    }
                  }
                }
              ))
            }
          }
        }}
        >
        <MrTranslate id={"CommonText.add"}/> 
        </Button>
      </Tooltip>
    )
  }

  // const PreviewSegmentButton = (props) => {
  //   return (
  //     <Tooltip title="Preview">
  //       <Button shape="circle" icon={<EyeOutlined />} onClick={() => {setExperienceViewMode("apTakeTest")}} target="_blank" />
  //     </Tooltip>
  //   )
  // }

  const updateParent = (segmentsToShow) => {
    let updatedOrder = _.map(segmentsToShow, 'id')
    topicProps.updateResource({ id: topicProps.item.id, children_order: updatedOrder })
  }

  const insertOnDragEnd = (result, segmentArray) => {
    if(!result.destination)
      return

    let sourceIdx = result.source.index;
    let destinationIdx = result.destination.index

    if(sourceIdx === destinationIdx)
      return

    if (sourceIdx != undefined && destinationIdx != undefined) {
      // to update segment position field on BE. before redux changes so using sourceIdx
      let segmentToUpdate = segmentsToShow[sourceIdx]
      // *Confusing but had to be different since indexes being changed after rearrange redux action not before and we don't want to count position of item being moved
      let prevIdx, nextIdx
      if(sourceIdx < destinationIdx){
        prevIdx = destinationIdx
        nextIdx = destinationIdx + 1
        
      }else if(sourceIdx > destinationIdx){
        prevIdx = destinationIdx - 1
        nextIdx = destinationIdx
      }
      let prevPosition = (segmentsToShow[prevIdx] || {}).position
      let nextPosition = (segmentsToShow[nextIdx] || {}).position
      let destinationPosition = getDestinationPositionLex(prevPosition, nextPosition)
      updateResource({ id: segmentToUpdate.id, destination_position: destinationPosition })

      // to rearrange in redux and updateParent children_order in callback
      dispatch(segmentActions.rearrange({ 
        sourceIdx, 
        destinationIdx, 
        parentId: segmentToUpdate.parent_id,
        experienceId: experience.id,
        callback: (data) => updateParent(data),
      }));
    }

    return ""
  }

  // first add button
  if (firstCreateAllowed && nestingLevel <= 3) {
    console.log("firstCreateAllowed creating add button")
    let addAtIdx = 0
    let prevPosition = null
    let nextPosition = (segmentsToShow[addAtIdx] || {}).position
    finalRender.push(
      <List.Item key={`first-add-segment-${nestingLevel}`} style={{ "width": "100%", "display": "block", "textAlign": "center" }} className={rearrangeMode ? "hidden" : ""}>
        <AddSegmentSelector isLibrary={isLibrary} initValuesForLibraryForm={initValuesForLibraryForm} sId={0} prevPosition={prevPosition} nextPosition={nextPosition} idx={addAtIdx} parentId={parentId} showForm={props.showForm} renderForm={props.renderForm} libraryModalProps={libraryModalProps} isSegmentsLimitReached={isSegmentsLimitReached} segmentsLength={segmentsToShow && segmentsToShow.length} nestingLevel={nestingLevel} parentSegment={parentSegment}/>
        {/* {(enabledFeatures.ai_generate_questions_with_prompt || import.meta.env.VITE_MODE === "development") && <GenerateAIWithPrompt withAddButton={true} key="generate-button" segment={{}} previousId={0} destinationPosition={0} createAtIndex={0} />} */}
      </List.Item>
    )
    if (newGenerateQuestionsWithAI && aiGenerateMode && activeAISegmentPosition.idx === 0 && activeAISegmentPosition.parentId === parentId) {
      finalRender.push(<AISegmentsWrapper />)
    }
  }
  
  if (!createAllowed) {
    // showing the msg if section is empty shoiwng no item in this section  or if section have only content(no question) showing no question in this section
    const hasSegmentsToShow = segmentsToShow && segmentsToShow.length > 0;
    const hasQuestions = segmentsToShow.findIndex(segment => segment.segment_type === "question") >= 0;
    const showNoDataInSection = !hasSegmentsToShow;
    const showNoQuestionInSection = mobileUploadMode && !hasQuestions && hasSegmentsToShow;

    if ((showNoDataInSection || showNoQuestionInSection) && !isSegmentInAIList) {
      const noDataMessage = showNoQuestionInSection ? mrIntl("SegmentList.no_question_in_this_section") : mrIntl("SegmentList.no_data_in_section")
      finalRender.push(<NoData key="no-data-message" description={noDataMessage} />);
    }
  }

  let qCount = 1
  let newFinalRender = []
  if(segmentListConfig.layout === "vertical_list"){
    segmentsToShow.map((segment, i) => {
      // show view
      console.log("props.showFormVal==>", segment)
      const segmentType = segment.segment_type;
      const resourceType = segment.resource_segment_attributes?.resource_type;
      const questionType =
        segmentType === "question" &&
        segment.question_segment_attributes &&
        segment.question_segment_attributes.question_type;
      const questionSegmentAttributes = segment?.question_segment_attributes || {}
      const questionContent = getQuestionContent(questionType, questionSegmentAttributes)
      const allowToUpdateMarkschemeAndAnswerExplanation = activeExperience && 
        activeExperience.status !== "draft" && 
        experienceViewMode === "apEditTest" && !isQuestionBankExperience && 
        segmentType === "question";

      if (editModeForSegmentId !== segment.id || !props.showFormVal) {
        let qNo = 
          (segmentType == "question") 
            ? getQuestionNumber(qCount, nestingLevel) 
            : ""
        
        let thisSegmentParentIdxs = []
        if(parentIdxs){
          thisSegmentParentIdxs = [...parentIdxs]
          thisSegmentParentIdxs.push(qCount - 1)
        }
        // let cardTitle = (qNo && `Q${qNo}`) + " - " + segment.segment_type + `(${segment.id} - ${segment.custom_fields.block ? segment.custom_fields.block.id : ""} - ${segment.uuid})`;
        // let cardTitle = `${segment.segment_type}(${segment.id})` + ` - ${segment.custom_fields.block ? segment.custom_fields.block.block_type + "(" + segment.custom_fields.block.id + ")" : ""} - ${segment.uuid})`;
        let cardTitle = import.meta.env.VITE_MODE === "staging1" || import.meta.env.VITE_MODE === "development1" && false ? <span><MrTranslate id={"SegmentList.level"}/> {nestingLevel} - {segment.position} - {segment.segment_type} - {segment.id} - {segment.parent_id} - {JSON.stringify(segment.children_order)} - {segment.points} <MrTranslate id={"CommonText.pts"}/></span> : (segment.title && !isLibrary ? segment.title : "")
        let extra = []
        
        // if(segment.custom_fields && segment.custom_fields.ai_used){
        //   extra.push(<Tag color={"#8E2DE2"} key={`ai-used-tag-${segment.id}`}>AI</Tag>)
        // }

        if (editAllowed) {
          const isDevelopmentMode = import.meta.env.VITE_MODE === "development" || import.meta.env.VITE_MODE === "staging";

          const isQBAuthorWithPermission =
            currentUser &&
            currentUser.role === "qb_author" &&
            currentUser.custom_fields &&
            currentUser.custom_fields.can_view_all_experiences;


          const isTextSegment = segmentType === "text";

          const segmentResourceAttributes = segmentType === "resource" && segment.resource_segment_attributes;

          const segmentResourceAttributesAttachments = segmentResourceAttributes && segmentResourceAttributes.resource_json.segment_data.attachments;

          const isVideoResource =
            (enabledFeatures.ai_generate_questions_from_video || isDevelopmentMode) &&
            segmentResourceAttributes && 
            segmentResourceAttributes.resource_type === "video";

          const isImageResource =
            (enabledFeatures.ai_generate_questions_from_image || isDevelopmentMode) &&
            segmentResourceAttributes && 
            segmentResourceAttributes.resource_type === "image" &&
            segmentResourceAttributesAttachments &&
            segmentResourceAttributesAttachments.length > 0;

          const isPdfResource =
            (enabledFeatures.ai_generate_questions_from_pdf || isDevelopmentMode || import.meta.env.VITE_MODE === "demo") &&
            segmentResourceAttributes &&
            segmentResourceAttributes.resource_type === "pdf" &&
            segmentResourceAttributesAttachments &&
            segmentResourceAttributesAttachments.length > 0;

          const isSubjectiveQuestion = questionType && questionType === "cke_subjective";
          // if ((enabledFeatures.ai_generate_questions || import.meta.env.VITE_MODE === "development") && (segment.segment_type === "question" && (segment.question_segment_attributes.question_type.includes('mcq') || segment.question_segment_attributes.question_type === "cke_subjective"))) {
          //   extra.push(<GenerateAI key="generate-button" segment={segment} previousId={(segments[i] || {}).id || 0} destinationPosition={getDestinationPositionLex((segments[i] || {}).position, (segments[i + 1] || {}).position)} createAtIndex={i + 1} />)
          // }

          const tooltipTitle =
              segmentType === "text"
                ? mrIntl("GenerateAIWithPrompt.generate_questions_from_this_text_title")
                : resourceType
                  ? mrIntl("GenerateAIWithPrompt.generate_questions_from_this_title", { resourceType: resourceType })
                  : mrIntl("GenerateAIWithPrompt.generate_similar_questions");

          const handleAIModalClick = (extractFromPdf) => {
            if (enabledFeatures.ai_generate_questions_with_prompt) {
              onGenerateSimilar(
                extractFromPdf,
                dispatch,
                i,
                segmentsToShow,
                parentId,
                nestingLevel,
                segment,
                newGenerateQuestionsWithAI
              );
            }
          };
                          
          const renderMagicButton = (tooltipTitle, extractFromPdf = false) => (
            // Mode handles classname of the button which handles different theme, here different for extract button and generate button
            <MagicButton
              tooltipTitle={tooltipTitle}
              tooltipPlacement="top"
              type="text"
              size="medium"
              extraClassName={extractFromPdf ? "m-l-10" : ""}
              mode={extractFromPdf ? "subtle" : "primary"}
              feature_code='ai_generate_questions_with_prompt'
              onClick={() => handleAIModalClick(extractFromPdf)}
            />
          );

          if (
            (isDevelopmentMode ||
              isQBAuthorWithPermission) &&
            (isSubjectiveQuestion ||
              isTextSegment ||
              isVideoResource ||
              isImageResource ||
              isPdfResource)
          ) {
            extra.push(renderMagicButton(tooltipTitle));
            if (newGenerateQuestionsWithAI && isPdfResource) {
              extra.push(
                renderMagicButton(
                  mrIntl("SegmentList.extract_questions_from_this_pdf"),
                  true
                )
              );
            }

            // extra.push(
            //   <GenerateAIWithPrompt
            //     key={`generate-button-${segment.id}`}
            //     createCondition={
            //       segment.question_segment_attributes &&
            //       segment.question_segment_attributes.question_type === "cke_subjective"
            //         ? "similar"
            //         : (segment.segment_type === "resource" ||
            //             segment.segment_type === "text" || (segment.resource_segment_attributes && segment.resource_segment_attributes.resource_type === "image")) &&
            //           "based_on"
            //     }
            //     segment={segment}
            //     parentId={parentId}
            //     previousId={(segmentsToShow[i] || {}).id || 0}
            //     destinationPosition={getDestinationPositionLex(
            //       (segmentsToShow[i] || {}).position,
            //       (segmentsToShow[i + 1] || {}).position
            //     )}
            //     createAtIndex={i + 1}
            //     nestingLevel={nestingLevel}
            //     nextPosition={(segmentsToShow[i+1] || {}).position}
            //   />
            // );
          }          
          extra.push(<EditButton key={`edit-button-${segment.id}`} segment={segment} showForm={props.showForm} formName={formName} />)
          // Showing dup in lib now but not in lib modal obv
          !isLibraryModal && extra.push(<DupButton key={`dup-button-${segment.id}`} segment={segment} index={i} />)
          extra.push(<DeleteButton key={`delete-button-${segment.id}`} item={segment} deleted={props.deleted}/>)
        }
        if (allowToUpdateMarkschemeAndAnswerExplanation) {
          extra.push(
            <AnswerExplanation segment={segment} modal={true} answer_explanation={questionSegmentAttributes?.answer_explanation} questionType={questionType} questionContent={questionContent} />
          )
          extra.push(
            <Markscheme segmentFooterAttributes={{ segment: segment }} modal={true} markscheme={questionSegmentAttributes?.markscheme} questionType={questionType} />
          );
        }
        if (((!isQuestionBankExperience && isAdminOrTeacher) || (isQuestionBankExperience && isQBAuthor)) && !isLibraryModal && !isSegmentInAIList) {
          extra.push(
            <MoreActions key={`more-actions-${segment.id}`} item={segment} />
          );
        }

        if (permission.segment && permission.segment.read && segment.uuid && segment.settings.allow_embed == true && !segment.experience_id && !isLibraryModal && isQBAuthor && embedded === false) {
          // {isLibrary && extra.push(<PreviewSegmentButton segment={segment} />)}
          extra.push(<EmbedButton key={`view-embed-link-button-${segment.id}`} segment={segment} />)
        }
        if (
          (enabledFeatures.segment_item_feedback || import.meta.env.VITE_MODE === 'development') &&
          (isQuestionBankExperience || (questionBankId && isLibrary)) && isAdminOrTeacher
        ) {
          extra.push(<SegmentItemFeedback segment={segment} thisSegmentParentIdxs={thisSegmentParentIdxs} />);
        } 
        isLibraryModal &&
          !isSegmentInAIList &&
          extra.push(
            <DupFromLibButton
              key={`dup-from-lib-button-${segment.id}`}
              segment={segment}
              libraryModalProps={libraryModalProps}
              activeAdjustedExperienceId={activeAdjustedExperienceId}
              activeTopicId={activeTopicId}
              activeExperience={activeExperience}
            />
          );
        if (isSegmentInAIList) {
          extra.push(
           <ExtraAIActionsHeader 
            index={i} 
            segment={segment}
            nestingLevel={nestingLevel}
            addedAiSegments={addedAiSegments}
            activeEditQuestion={activeEditQuestion}
            setActiveEditQuestion={setActiveEditQuestion}
          />
          );
        }


        if(!mobileUploadMode || (mobileUploadMode && segmentType === "question")){
          let borderlessCard = (((isQuestionBankExperience && isAdminOrTeacher) || isLibraryModal || isSegmentInAIList) && nestingLevel > 1) || printMode || renderQuestionAreaOnly;
          console.log("thisSegmentParentIdxs", parentIdxs, thisSegmentParentIdxs, nestingLevel);

          let pinnedSegmentIndex = pinnedSegments.findIndex((s) => s.id === segment.id)

          let isPinnedSegment = false
          if (pinnedSegmentIndex >= 0) {
            isPinnedSegment = true
          }
          
          let segmentItem = 
            <ErrorBoundary key={`segment-error-boundry-${segment.id}`} message={mrIntl("SegmentList.some_error_occured_msg")} description={`Error inside SegmentList with id: ${segment.id}`}>
              <List.Item className={`segment-list-item ${questionType === "group" ? "group-question" : ""}`} key={`segment-show-${segment.id}`} id={segment && segment.id && `item-${segment.id}`} style={{display: 'inline-block'}}>
                <Draggable type="segments" draggableId={`draggable-${segment.id}`} index={i} isDragDisabled={!rearrangeMode}>
                  {(provided, snapshot) => (
                    <div className={rearrangeMode ? "segment-draggable rearrange-enabled" : "segment-draggable"}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {rearrangeMode ? <DragOutlined /> : ""}
                      {/* Segment ID {segment.id} */}
                      {experienceViewMode !== "apEditTest" || (experienceViewMode === "apEditTest" && embedded === true) 
                      ? 
                      <Card bordered={false}>
                        <SegmentItemDetail key={`segment-item-detail-${segment.id}`} item={segment} experienceViewMode={experienceViewMode} experience={experience} qNo={qNo} mobileUploadMode={mobileUploadMode} readingModeEnabled={readingModeEnabled} printMode={printMode} isLibrary={isLibrary} embedded={embedded} parentIdxs={thisSegmentParentIdxs} activeTopicIdx={activeTopicIdx} nestingLevel={nestingLevel} onlyExplanationMode={onlyExplanationMode} experienceUser={experienceUser} gradingConfig={gradingConfig} showAnnotatorToStudent={showAnnotatorToStudent} isPinnedSegment={isPinnedSegment}/>
                      </Card>
                      : 
                      <Card key={`segment-item-detail-card-${segment.id}`} bordered={borderlessCard ? false : true} type={borderlessCard ? "" : "inner"} title={borderlessCard ? "" : cardTitle} extra={borderlessCard ? "" : extra}>
                        <SegmentItemDetail key={`segment-item-detail-${segment.id}`} item={segment} experienceViewMode={experienceViewMode} experience={experience} qNo={qNo} mobileUploadMode={mobileUploadMode} printMode={printMode} embedded={embedded} readingModeEnabled={readingModeEnabled} isLibrary={isLibrary} isLibraryModal={isLibraryModal} parentIdxs={thisSegmentParentIdxs} activeTopicIdx={activeTopicIdx} renderform={props.renderForm} nestingLevel={nestingLevel} libraryModalProps={libraryModalProps} rearrangeMode={rearrangeMode} onlyExplanationMode={onlyExplanationMode} experienceUser={experienceUser} gradingConfig={gradingConfig} showAnnotatorToStudent={showAnnotatorToStudent} isPinnedSegment={isPinnedSegment} isSegmentInAIList={isSegmentInAIList} />
                      </Card>
                      }

                    </div>
                  )}
                </Draggable>
              </List.Item>
            </ErrorBoundary>

          if(pinnedSegmentIndex >= 0 && questionType !== "group") {
            let pinnedSegmentPlaceholder =
              <PinnedSegmentPlaceholder key={`pinned-segment-placeholder-${segment.id}`} segment_id={segment.id} />
            finalRender.push(pinnedSegmentPlaceholder)
          } else {
            finalRender.push(segmentItem)
          }
        } 
      }
      // edit view
      if (editModeForSegmentId === segment.id && props.showFormVal === formName) {
        console.log("segment edit", segment)
        finalRender.push(
          <List.Item key={`segment-edit-${segment.id}`} style={{listStyle: "none"}}>
            <Card type="inner" extra={<CancelButton showForm={props.showForm} />}>
              <div>
                {props.renderForm({
                  name: formName,
                  disablePrompt: true,
                  config: { 
                    isModal: false, 
                    actions: {submitText: "Save", showCancelBtn: true}, 
                    visible: (props.showFormVal === formName && editModeForSegmentId === segment.id), 
                    // submitConfig: { hideForm: true },
                    submitConfig: {
                      destinationPosition: null, //was staying set after duplicate and edit for some reason - we don't want to send anything when just editing 
                      hideForm: true,
                      success: {showMessage: true, message: mrIntl("SegmentList.item_saved_success_msg")}, 
                      error: {showMessage: true, message: mrIntl("SegmentList.error_in_saving_item_error_msg")}, 
                      successCallback: (data) => {
                        console.log("segment updated callback", data, data.data.segment.data.attributes)
                        let updatedSegment = data.data.segment.data.attributes
                      //   {/* let msg = `${
                      //   updatedSegment.segment_type === "question"
                      //     ? "Question"
                      //     : "Content"
                      // } updated by ${currentUser.name} (${upperFirst(
                      //   currentUser.role
                      // )})`;
                      // const questionType =
                      //   updatedSegment.segment_type === "question"
                      //     ? updatedSegment.question_segment_attributes
                      //         .question_type
                      //     : updatedSegment.segment_type === "resource"
                      //     ? updatedSegment.resource_segment_attributes
                      //         .resource_type
                      //     : ""; */}
                        if(experience){
                          dispatch(segmentActions.setParentsPoints({ updatedSegment }));
                          // {/* setLogs(msg, updatedSegment.id, updatedSegment.experience_id, "updated", updatedSegment.segment_type, questionType) */}
                        }
                      },
                    }
                  },
                  resource: segment //use this for edit
                })}
              </div>
            </Card>
          </List.Item>
        )
      }
      if (createAllowedAfterEachSegment && nestingLevel <= 3) {
        // add button and form after each segment
        let addAtIdx = i + 1
        let prevPosition = (segmentsToShow[addAtIdx - 1] || {}).position
        let nextPosition = (segmentsToShow[addAtIdx] || {}).position
        console.log("prevPosition, nextPosition ==>", i, segmentsToShow[addAtIdx - 1], segmentsToShow[addAtIdx], prevPosition, nextPosition)
        finalRender.push(
          <List.Item style={{ "width": "100%", "display": "block", "textAlign": "center" }} key={`segment-create-${segment.id}`} className={rearrangeMode ? "hidden" : ""}>
            <AddSegmentSelector isLibrary={isLibrary} sId={segment.id} prevPosition={prevPosition} nextPosition={nextPosition} idx={addAtIdx} parentId={parentId} showForm={props.showForm} renderForm={props.renderForm} libraryModalProps={libraryModalProps} isSegmentsLimitReached={isSegmentsLimitReached} nestingLevel={nestingLevel} />
            {/* {(enabledFeatures.ai_generate_questions_with_prompt || import.meta.env.VITE_MODE === "development") && <GenerateAIWithPrompt withAddButton={true} key="generate-button" segment={segment} previousId={(segments[i] || {}).id || 0} destinationPosition={getDestinationPositionLex((segments[i] || {}).position, (segments[i + 1] || {}).position)} createAtIndex={i + 1}  />} */}
          </List.Item>
        )
        if (newGenerateQuestionsWithAI && aiGenerateMode && activeAISegmentPosition.idx === addAtIdx && activeAISegmentPosition.parentId === parentId) {
          finalRender.push(<AISegmentsWrapper isSegmentInAIList={isSegmentInAIList} nestingLevel={nestingLevel} />)
        } 
      }
      qCount = (segmentType === "question") ? qCount+1 : qCount
    });
    finalRender.push(<>
      {nestingLevel === 1 && (isMobile || isIPAD) && (experienceViewMode === 'apTakeTest' || experienceViewMode === 'apPreviewTest') && <GoToNextSection activeTopicId={activeTopicId} />}
    </>);
    // pinnedSegmentToRender.push(<h4> Pinned contents {pinnedSegments.length} </h4>)
    pinnedSegments.forEach((segment, i) => {
      const segmentType = segment && segment.segment_type;

      const questionType =
        segmentType === "question" &&
        segment.question_segment_attributes &&
        segment.question_segment_attributes.question_type;

      const notRenderQGChildren = 
        questionType === "group" ? true : false

      let segmentItem = 
        <ErrorBoundary key={`pinned-segment-error-boundry-${segment.id}`} message={mrIntl("SegmentList.some_error_occured_msg")} description={`Error inside SegmentList with id: ${segment.id}`}>
          <List.Item className="segment-list-item" key={`pinned-segment-show-${segment.id}`} style={{display: 'inline-block'}}>
            <Draggable type="segments" draggableId={`draggable-${segment.id}`} index={i} isDragDisabled={!rearrangeMode}>
              {(provided, snapshot) => (
                <div className={rearrangeMode ? "segment-draggable rearrange-enabled" : "segment-draggable"}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <Card bordered={false}>
                    <SegmentItemDetail key={`pinned-segment-item-detail-${segment.id}`} item={segment} experienceViewMode={experienceViewMode} experience={experience} mobileUploadMode={mobileUploadMode} readingModeEnabled={readingModeEnabled} isLibrary={isLibrary} embedded={embedded} notRenderQGChildren={notRenderQGChildren}/>
                  </Card>
                </div>
              )}
            </Draggable>
          </List.Item>
        </ErrorBoundary>
      pinnedSegmentToRender.push(segmentItem)
    });
    newFinalRender = <DragDropContext onDragEnd={insertOnDragEnd}>
      <Droppable className="segment-list-container" droppableId="droppable-segments" type="segments" key="droppable-segments">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{ backgroundColor: snapshot.isDraggingOver ? 'aliceblue' : '' }}
            {...provided.droppableProps}
            className={`segment-list-droppable nesting-level-${nestingLevel} ${nestingLevel === 1 && !printMode && "p-20 p-b-400"} ${nestingLevel === 1 && isMobile && "m-b-50"} `}
          >
            {isPinned && nestingLevel === 1? 
              <>
                <Col span={12} className="pinned-segment">
                  {pinnedSegmentToRender}
                </Col>
                <Col span={12} offset={14}>
                  {finalRender}
                </Col>
              </>
              : finalRender
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  }

  
  if(segmentListConfig.layout === "horizontal_tabs"){
    let tabsInnerRender = []
    segmentsToShow.map((segment, i) => {
      let tabInnerRender = []
      if (editModeForSegmentId !== segment.id || !props.showFormVal) {
        let qNo = (segment.segment_type == "question") ? getQuestionNumber(qCount, nestingLevel) : ""
        // let cardTitle = (qNo && `Q${qNo}`) + " - " + segment.segment_type + `(${segment.id} - ${segment.custom_fields.block ? segment.custom_fields.block.id : ""} - ${segment.uuid})`;
        // let cardTitle = `${segment.segment_type}(${segment.id})` + ` - ${segment.custom_fields.block ? segment.custom_fields.block.block_type + "(" + segment.custom_fields.block.id + ")" : ""} - ${segment.uuid})`;
        let cardTitle = import.meta.env.VITE_MODE === "staging1" || import.meta.env.VITE_MODE === "development1" ? <span>{mrIntl("SegmentList.level")} {nestingLevel} - {segment.position} - {segment.segment_type} - {segment.id} - {segment.parent_id} - {JSON.stringify(segment.children_order)} - {segment.points} pts</span> : (segment.title ? segment.title : "")
        let extra = []
        if (editAllowed) {
          extra.push(<EditButton key="edit-button" segment={segment} showForm={props.showForm} formName={formName} />)
          // Showing dup in lib now but not in lib modal obv
          !isLibraryModal && extra.push(<DupButton key="dup-button" segment={segment} index={i} />)
          extra.push(<DeleteButton key="delete-button" item={segment} deleted={props.deleted}/>)
        }

        if (permission.segment && permission.segment.read && segment.uuid && segment.settings.allow_embed == true && !isLibraryModal && embedded === false) {
          // {isLibrary && extra.push(<PreviewSegmentButton segment={segment} />)}
          extra.push(<EmbedButton key="view-embed-link-button" segment={segment} />)
        }

        isLibraryModal && !isSegmentInAIList && extra.push(<DupFromLibButton key="dup-from-lib-button" segment={segment} libraryModalProps={libraryModalProps} activeAdjustedExperienceId={activeAdjustedExperienceId} activeTopicId={activeTopicId} activeExperience={activeExperience} />)
        
        // aiGenerateMode && extra.push(<AddGeneratedSegmentButton segment={segment} />)
        // if(permission.segment && permission.segment.delete){
        //   extra.push(<DeleteButton segment={segment}/>)
        // }

        if(!mobileUploadMode || (mobileUploadMode && segment.segment_type === "question")){
          let borderlessCard = (isLibraryModal && nestingLevel > 1) || printMode || renderQuestionAreaOnly;
          let thisSegmentParentIdxs = []
          if(parentIdxs){
            thisSegmentParentIdxs = [...parentIdxs]
            thisSegmentParentIdxs[nestingLevel] = qCount - 1
          }
          console.log("thisSegmentParentIdxs", parentIdxs, thisSegmentParentIdxs, nestingLevel);
          let segmentItem = 
              <ErrorBoundary key={`segment-error-boundry-${segment.id}`} message={mrIntl("SegmentList.some_error_occured_msg")} description={`Error inside SegmentList with id: ${segment.id}`}>
              <List.Item className="segment-list-item" key={`segment-show-${segment.id}`} id={qNo && `question-${qNo}`} style={{display: 'inline-block'}}>
                <Draggable type="segments" draggableId={`draggable-${segment.id}`} index={i} isDragDisabled={!rearrangeMode}>
                  {(provided, snapshot) => (
                    <div className={rearrangeMode ? "segment-draggable rearrange-enabled" : "segment-draggable"}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {rearrangeMode ? <DragOutlined /> : ""}
                      {/* Segment ID {segment.id} */}
                      {experienceViewMode !== "apEditTest" || (experienceViewMode === "apEditTest" && embedded === true) 
                      ? 
                      <Card bordered={false}>
                        <SegmentItemDetail key={`segment-item-detail-${segment.id}`} item={segment} experienceViewMode={experienceViewMode} experience={experience} qNo={qNo} mobileUploadMode={mobileUploadMode} readingModeEnabled={readingModeEnabled} printMode={printMode} isLibrary={isLibrary} embedded={embedded} parentIdxs={thisSegmentParentIdxs} activeTopicIdx={activeTopicIdx} nestingLevel={nestingLevel} onlyExplanationMode={onlyExplanationMode} experienceUser={experienceUser} gradingConfig={gradingConfig} showAnnotatorToStudent={showAnnotatorToStudent} />
                      </Card>
                      : 
                      <Card bordered={borderlessCard ? false : true} type={borderlessCard ? "" : "inner"} title={borderlessCard ? "" : cardTitle} extra={borderlessCard ? "" : extra}>
                        <SegmentItemDetail key={`segment-item-detail-${segment.id}`} item={segment} experienceViewMode={experienceViewMode} experience={experience} qNo={qNo} mobileUploadMode={mobileUploadMode} printMode={printMode} embedded={embedded} readingModeEnabled={readingModeEnabled} isLibrary={isLibrary} isLibraryModal={isLibraryModal} parentIdxs={thisSegmentParentIdxs} activeTopicIdx={activeTopicIdx} renderform={props.renderForm} nestingLevel={nestingLevel} libraryModalProps={libraryModalProps} rearrangeMode={rearrangeMode} onlyExplanationMode={onlyExplanationMode} experienceUser={experienceUser} gradingConfig={gradingConfig} showAnnotatorToStudent={showAnnotatorToStudent}/>
                      </Card>
                      }

                    </div>
                  )}
                </Draggable>
              </List.Item>
            </ErrorBoundary>
          let sIndex = pinnedSegments.findIndex((s) => s.id === segment.id)
          if (sIndex >= 0) {
            let pinnedSegmentPlaceholder =
              <Alert
                description={
                  <Button 
                    size="large" 
                    type="text"
                    onClick={() => {
                      const newPinnedSegments = [...pinnedSegments]
                      newPinnedSegments.splice(sIndex, 1)
                      dispatch(segmentActions.pinnedSuccess({pinnedSegments: newPinnedSegments}))
                    }}
                  >
                    <MrTranslate id={"SegmentList.content_pinned_click_to_unpin_button"}/>
                  </Button>
                }
                type="info"
                className="pinned-segment-placeholder"
                closable={false}
              />
            tabInnerRender.push(pinnedSegmentPlaceholder)
          } else {
            tabInnerRender.push(segmentItem)
          }
        }
        
      }
      // edit view
      if (editModeForSegmentId === segment.id && props.showFormVal === formName) {
        console.log("segment edit", segment)
        tabInnerRender.push(
          <List.Item key={`segment-edit-${segment.id}`}>
            <Card type="inner" extra={<CancelButton showForm={props.showForm} />}>
              <div>
                {props.renderForm({
                  name: formName,
                  disablePrompt: true,
                  config: { 
                    isModal: false, 
                    actions: {submitText: mrIntl("CommonText.save"), showCancelBtn: true}, 
                    visible: (props.showFormVal === formName && editModeForSegmentId === segment.id), 
                    // submitConfig: { hideForm: true },
                    submitConfig: {
                      destinationPosition: null, //was staying set after duplicate and edit for some reason - we don't want to send anything when just editing 
                      hideForm: true,
                      success: {showMessage: true, message: mrIntl("SegmentList.item_saved_success_msg")}, 
                      error: {showMessage: true, message: mrIntl("SegmentList.error_in_saving_item_error_msg")}, 
                      successCallback: (data) => {
                        console.log("segment updated callback", data, data.data.segment.data.attributes)
                        let updatedSegment = data.data.segment.data.attributes
                        if(experience){
                          dispatch(segmentActions.setParentsPoints({ updatedSegment }));
                        }
                      },
                    }
                  },
                  resource: segment //use this for edit
                })}
              </div>
            </Card>
          </List.Item>
        )
      }

      tabsInnerRender.push(<TabPane
      //  tab={`Level ${i+1}`}
       tab={mrIntl("SegmentList.level_tab", {index: i+1})}
       key={segment.id}>
        {tabInnerRender}
      </TabPane>)

    })
    // setTabPanes(tabsInnerRender)

    finalRender.push(<Tabs type="editable-card" style={{"width": "100%"}}>
      {/* {tabPanes} */}
      {tabsInnerRender}
    </Tabs>)
    newFinalRender = finalRender
  }

  return (
    <React.Fragment>
      {/* {finalRender} */}
      {/* {segmentListConfig.layout === "vertical_list" && newFinalRender} */}
      {/* {segmentListConfig.layout === "horizontal_tabs" && <Space>{newFinalRender}</Space>} */}
      {newFinalRender}
    </React.Fragment>
  );
};
SegmentList.defaultProps = {};

SegmentList.propTypes = {};

export default SegmentList;
