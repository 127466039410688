import React, {useEffect, useState} from 'react';
import {Row, Checkbox, Col, Space, Switch, Tooltip} from "antd";
import {useSelector, useDispatch} from "react-redux";
import { actions as orgActions } from "/src/views/Orgs/redux";
import { boardsProgrammesSubjectsGradesLoadingSelector, boardsProgrammesSubjectsGradesSelector } from './selector';
import Spinner from '/src/components/UI/Spinner/Spinner';
import { currentUserSelector } from '/src/views/Auth/Login/selector';
import "./ProgramYearSelector.scss"
import MrTranslate, { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const buildObjForForm = (boardsProgramsSubjectsGrades, orgProgrammes = []) => {
  console.log("building form", boardsProgramsSubjectsGrades)
  let obj = [];
  let enabledProgram = null;
  boardsProgramsSubjectsGrades.map((value, i) => {
    let board = value.board;
    board.programmes = value.programmes.data;
    board.programmes.map((program, j) => {
      program = program.attributes
      console.log("orgProgrammes", orgProgrammes);
      
      if(orgProgrammes.length !== 0){
        // edit case
        enabledProgram = orgProgrammes.find(p => { 
          console.log("orgProgrammes p.programme_id", p.programme_id);
          console.log("orgProgrammes program.id", program.id);
          return p.programme_id === program.id
        })
        console.log("orgProgrammes enabledProgram", enabledProgram);
        if(enabledProgram){
          program.enabled = !enabledProgram.archived; // set from org_programmes in case of edit
          if(enabledProgram.settings){
            program.mb_integration_enabled = enabledProgram.settings.mb_integration_enabled; // set from org_programmes in case of edit
          }
          console.log("enabling", program);
        }
        else
          program.enabled = false;
      }
      else
        program.enabled = false;
      console.log("boardsProgramsSubjectsGrades", boardsProgramsSubjectsGrades);
      
      if(program.programme_grades_attributes){ //as string in static data
        console.log("program.programme_grades_attributes", program.programme_grades_attributes)
        // program.programme_grades_attributes = JSON.parse(program.programme_grades_attributes)
        
        program.programme_grades_attributes && program.programme_grades_attributes.map((grade, k) => {
          console.log("orgProgrammes p g", orgProgrammes);
        
          if(enabledProgram && enabledProgram.grades){
            // edit case
            let enabledGrade = enabledProgram.grades.find(g => { 
              console.log("orgProgrammes p g", g.code);
              console.log("orgProgrammes p g", program.id);
              return (g.programme_grade_id === grade.id && g.archived === false)
            })
            if(enabledGrade){
              grade.enabled = !enabledGrade.archived; // set from org_programmes in case of edit
              console.log("enabling", grade);
            }
            else
              grade.enabled = false;
          }
          else
            grade.enabled = false;
        })
      }
    })
    obj.push(board);
  })

  return obj;
}

const ProgramYearSelector = (props) => {
  const {value, onChange} = props;
  console.log("ProgramYearSelector props", props);
  console.log("ProgramYearSelector value", value);
  // value will be set by MrFormComponent automatically, in case of default value and edit form
  const mrIntl = useTranslate()
  const dispatch = useDispatch();
  const boardsProgramsSubjectsGrades = useSelector(boardsProgrammesSubjectsGradesSelector());
  const boardsProgrammesSubjectsGradesLoading = useSelector(boardsProgrammesSubjectsGradesLoadingSelector())
  const currentUser = useSelector(currentUserSelector())

  // const boardsProgramsSubjectsGrades = useSelector(state => {
  //   return state.login.get("currentUser").boards_programs_subjects_grades
  // }) 
  console.log("boardsProgramsSubjectsGrades", boardsProgramsSubjectsGrades);

  let orgProgrammes = value;
  console.log("orgProgrammes", orgProgrammes);

  // const nestedBoardsProgramsSubjectsGrades = buildObjForForm(boardsProgramsSubjectsGrades, orgProgrammes)
  
  const [values, setValues] = useState([]);
  // const orgRoles = JSON.parse(values);
  
  console.log("props program year selector", values);

  const buildAndSetForm = (boardsProgramsSubjectsGrades) => {
    console.log("boardsProgramsSubjectsGrades success callback", boardsProgramsSubjectsGrades);
    let nestedBoardsProgramsSubjectsGrades = buildObjForForm(boardsProgramsSubjectsGrades, orgProgrammes)
    setValues(nestedBoardsProgramsSubjectsGrades);
  };

  useEffect(() => {
    console.log("boardsProgramsSubjectsGrades on mount");
    if (!boardsProgramsSubjectsGrades) {
      console.log("boardsProgramsSubjectsGrades on mount fetch");
      dispatch(
        orgActions.boardsProgrammesSubjectsGrades(
          {with_programme_subjects_attributes_for_static: false},
          {
            successCallback: buildAndSetForm,
          }
        )
      );
    }else{
      // org switch - we have the static data so just calling the callbackfn
      buildAndSetForm(boardsProgramsSubjectsGrades)
    }
  }, []);

  const getNewOrgProgramObj = (p) => {
    return {
      programme_id: p.id,
      name: p.name,
      code: p.code,
      abbr: p.abbr,
      archived: false,
      grades: []
    }
  }

  const getNewProgramGradeObj = (g) => {
    return {
      name: g.name,
      code: g.code,
      label: g.name,
      programme_grade_id: g.id,
      archived: false
    }
  }

  const customOnChange = (boardIdx, programIdx, gradeIdx, p, g, val) => {
    console.log("onchange roles form role", boardIdx);
    console.log("onchange roles form resource", programIdx);
    console.log("onchange roles form action", gradeIdx);
    console.log("onchange roles form p", p);
    console.log("onchange roles form g", g);
    console.log("onchange roles form val", val);
    console.log("onchange roles form values", values);
    
    let newValues = [...values]
    if(gradeIdx === -1){
      // update program enabled
      // newValues[boardIdx].programmes[programIdx]["enabled"] = val;
      newValues[boardIdx].programmes[programIdx].attributes = {...newValues[boardIdx].programmes[programIdx].attributes, enabled: val}
      // Todo: set all nested grades to false too if val = false
      // if(val === false){
      //   newValues.map((b, i) => {
      //     b.programmes.map((p, j) => {
      //       p.grades.map((g, k) => {
      //         g.enabled = false
      //       })
      //     })
      //   })
      // }
    }
    else{
      // update grades enabled
      newValues[boardIdx] = {
        ...newValues[boardIdx],
        programmes: [...newValues[boardIdx].programmes]
      }

      newValues[boardIdx].programmes[programIdx].attributes.programme_grades_attributes[gradeIdx] = {...newValues[boardIdx].programmes[programIdx].attributes.programme_grades_attributes[gradeIdx], enabled: val}

      // if(val === true){
      //   // enable program also - not needed - can't selectgrade unless program true
      //   newValues[boardIdx].programmes[programIdx] = {
      //     ...newValues[boardIdx].programmes[programIdx],
      //     grades: [
      //       ...newValues[boardIdx].programmes[programIdx].grades,
      //     ],
      //     enabled: val
      //   }
      // }
      
      
      // console.log("newValues[boardIdx].programmes[programIdx]", newValues[boardIdx].programmes[programIdx])
      
    }

    setValues(newValues);
    // setValues(values);

    // console.log("onchange roles afterSet values", values);

    console.log("orgProgrammes before change", orgProgrammes);

    // BUILDING ORG_PROGRAMMES_ATTRIBUTES for form submission
    let pExists = null
    let gExists = null
    if(orgProgrammes !== undefined){
      pExists = orgProgrammes.find(pr => { 
        return pr.programme_id === p.id
      })
    }
    else
      orgProgrammes = []
    // console.log("pExists", pExists)

    if(pExists){
      // pIdx = orgProgrammes.indexOf(pExists)
      gExists = pExists.grades.find(gr => { 
        return gr.programme_grade_id === g.id
      })
      // if(gExists){
      //   gIdx = orgProgrammes[pIdx].grades.indexOf(gExists)
      // }
    }
    // console.log("gExists", gExists)

    if(p && val === true && g === -1){
      // enabling pr.programmesogram
      if(pExists){
        pExists.archived = false
      }
      else
        orgProgrammes.push(getNewOrgProgramObj(p)) //add program to orgProgrammes arr
    }
    if(p && val === false && g === -1){
      // disabling program
      if(pExists){
        pExists.archived = true
        pExists.grades.map((gr, i) => {
          gr.archived = true
        })
      }
      // if id present, change archive, otherwise remove entry from array
    }
    if(p && val === true && g !== -1){
      // enabling year
      if(pExists){
        if(gExists){
          gExists.archived = false
        }
        else
          pExists.grades.push(getNewProgramGradeObj(g))
      }
      else
      {
        // program doesn't exist -> give message that select a program first
      }
    }
    if(p && val === false && g !== -1){
      // disabling year
      if(pExists && gExists){
        gExists.archived = true
      }
      // if id present, change archive, otherwise remove entry from array
    }
    console.log("orgProgrammes on change", orgProgrammes);

    onChange(orgProgrammes);

    // // setValues({...values, [key]: val});
    // // onChange({...values, [key]: val})
  }

  let finalRender = [];
  console.log("values before render", values);
  if(boardsProgrammesSubjectsGradesLoading){
    finalRender.push(<Spinner />)
  }
  if(boardsProgramsSubjectsGrades){
    if(values){
      // finalRender.push(JSON.stringify(values));
      values.map((board, i) => 
        finalRender.push(
          <React.Fragment key={`board_${i}`}>
            <span className="org-program-year">
            <br></br>
            <h4 className="program-year-separator">{board.name} ({board.abbr})</h4>
            {
              board.programmes.map((program, j) =>
                // these 2 are old and archived but need archived in the api so hiding on FE
                program.attributes.code !== "ib_myp" && program.attributes.code !== "ib_dp" && <Row key={`program_${j}`} className="program-year-response-separator">
                  <Col span="6">
                   <Tooltip title={program.attributes.enabled && currentUser.role == "admin" && "To disable that contact support"}>
                    <Switch checked={program.attributes.enabled} size={"small"} onChange={(event) => customOnChange(i, j, -1, program.attributes, -1, event)} disabled={currentUser.role == "admin" && program.attributes.enabled}/></Tooltip> &nbsp; 
                    {(currentUser.role === "superadmin" || currentUser.role === "support") && <Switch size={"small"} checked={program.attributes.mb_integration_enabled} disabled={import.meta.env.VITE_MODE === "production"}/> } &nbsp; 
                    {program.attributes.name} &nbsp; 
                  </Col>
                  <Col span="18">
                    <Space className="program-year-select-id">
                      {
                        Array.isArray(program.attributes.programme_grades_attributes) && program.attributes.programme_grades_attributes.map((grade, k) =>
                          <span key={`grade_${k}`} className={!program.attributes.enabled && "span-disable-program"}>
                            <Tooltip title={grade.enabled && currentUser.role == "admin"  && "To disable that contact support"}>
                            <Checkbox checked={grade.enabled}  disabled={!program.attributes.enabled || (currentUser.role == "admin" && grade.enabled)} onChange={(event) => customOnChange(i, j, k, program.attributes, grade, event.target.checked)}/></Tooltip> &nbsp; {grade.name}
                          </span>
                        )
                      }
                    </Space>
                  </Col>
                </Row>
              )
            }
            </span> 
          </React.Fragment>
        )
      )
    }
  }

  return finalRender;

  // return <React.Fragment>
  //     <Input value={values.key1} onChange={(event) => customOnChange("key1", event.target.value)}/>
  //     <Input value={values.key2} onChange={(event) => customOnChange("key1", event.target.value)}/>
  // </React.Fragment>;


};
ProgramYearSelector.defaultProps = {}
ProgramYearSelector.propTypes = {}
export default ProgramYearSelector;

