import React from "react";
import classes from "./Spinner.module.css";
import {Spin, Skeleton} from "antd";
import {Spinner as DefaultSpinner} from "mr_react_framework";
import { useSelector } from "react-redux";
import { partnerNameSelector } from "/src/views/Auth/Login/selector";
import { OSCSpinner } from "/src/components/Partners/OSC/GenericComponents";
// const Spinner = props => {
//   // return <div className={classes.Loader}>Loading...</div>;
//   return <Spin size="large" style={{"position":"absolute", "left":"48%", "top":"100px"}}/>;
//   // return <Skeleton active />;
// };

const Spinner = (props) => {
  const partnerName = useSelector(partnerNameSelector())
  let finalRender = <DefaultSpinner />
  if(partnerName === "osc"){
    finalRender = <OSCSpinner style={{"position":"absolute", "left":"48%", "top":"48%"}}/>
  }
  return finalRender
}

export default Spinner;
