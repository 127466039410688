import React from "react";
import PropTypes from 'prop-types';

import { MrSelect, MrCrudView, MrForm as WrappedMrForm, FormRenderer } from "mr_react_framework";
import { actions } from "./redux";
import ExperienceListItem from "../ExperienceListItem/ExperienceListItem";
import ExperienceList from "../ExperienceList/ExperienceList";
import ExperiencesFilter from "../ExperiencesFilter/ExperiencesFilter";
import ExperienceItemDetail from "/src/views/Experiences/ExperienceShow/ExperienceItemDetail";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors from "/src/App/commonSelectors";
import _ from "lodash";
// import {orgSubjectsSelector} from "../Orgs/selector";
// import { experiencesSelector } from "./selector";
import {getFromLS} from "/src/lib/utils/helperMethods"
import { todayexperiencesSelector } from "../selector";
import CustomExperienceCrudList from "../CustomExperienceCrudList";


const setDefaultParams = (props) => {
  console.log("setDefaultParams props", props)
  if(props.currentUser == "admin" || props.currentUser == "teacher" ){
    return {by_org_programme_id: getFromLS("activeOrgProgrammeId")}
  }
  else{
    return {}
  }
}

const TodayExperiences = MrCrudView({
  resourceName: "todayexperiences",
  singleResourceName: "experience",
  // displayName: "Create/Edit Tests",
  resourceUrl: "/experiences",
  // layout: OrgsLayout,
  filter: ExperiencesFilter,
  // listHeader: ExperienceHeader,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
    // classesForMultiselect: groupsSelector,
    todayexperiences: todayexperiencesSelector,
    // subjects: orgSubjectsSelector
  },
  listItem: ExperienceListItem,
  forms: [
    // { name: "experiences", form: returnForm, config: { title: {show: true, value: "Test"}, isModal: true, visible: true, actions: {submitText: "Save", showCancelBtn: true} } }, 
  ],
  actions,
  actionNames: ["fetch", "create", "show", "update"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: ExperienceListItem
    },
    itemDetail: {
      widget: ExperienceItemDetail
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      style: {
        width: 6,
        align: "right"
      }
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      widget: ExperienceList,
      layout: CustomExperienceCrudList,
      // forceReload: true
      // params: setDefaultParams

    }
  },
});

// TodayExperiences.defaultProps = {};

TodayExperiences.propTypes = {};
export default TodayExperiences;
