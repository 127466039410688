import React from "react";
// import GGBApplet from "./deployggb";
// import { actions } from "./redux";

import { Button } from "antd";
import styled from 'styled-components'
import { RBDDroppableArea } from '/src/components/UI/DnD/RBDDroppableArea'
import { RBDDroppableBox } from '/src/components/UI/DnD/RBDDroppableBox'
import { AddButton } from '/src/components/UI/Segment/UIHelper'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { replaceOnDragEnd, appendOnDragEnd } from '/src/views/Segments/DnDHelpers'
import { initialData, addItemToArray, removeItemFromArray, convertDataAsPerNewSystem } from "/src/views/Segments/InteractiveHelpers";
import { getUniqueId } from '/src/lib/utils/helperMethods';

const DragDropArea = styled.div`
  display: flex;
`
const VerticalDroppableContainer = styled.div`
	// display: flex;
	// flex-direction: column;
	// flex-grow: 1;
	margin: 10px;
	width: 50%;
`
const HorizontalDroppableContainer = styled.div`
	display: flex;
	// justify-content: center;
	border: 1px solid #939393;
	border-radius: 3px;
	min-height: 80px;
	text-align: center;
	padding: 10px;
	margin-top: 20px;
`

class RTCAudioRecording extends React.Component {
	constructor(props) {
		console.log("Matching props", props);
		super(props);
		const { item } = props;
		let data;

		if (props.viewConfig.createMode) {
			data = initialData('sorting', 1);
			this.createMode = true;
		}

		if (props.viewConfig.editMode) {
			data = convertDataAsPerNewSystem(props.viewConfig.question.teacher_json, props.viewConfig)
			this.createMode = true;
		}

		if (props.viewConfig.answerMode) {
			data = convertDataAsPerNewSystem(props.viewConfig.question.student_json, props.viewConfig)
			this.answerMode = true;
		}

		console.log('Updated data ==>', data)
		this.state = data;
	}

	onDragEnd = result => {
		// const updatedData = replaceOnDragEnd(result, this.state, append => true);
		// console.log('updatedData ==>', updatedData);

		// if (!updatedData) {
		// 	return
		// }

		// this.setState(updatedData)
		// return
	}

	addItem = keyName => {
		let lists = this.state.lists
		let items = lists[keyName].items;
		items = addItemToArray(items);

		const newState = {
			...this.state,
			lists: lists
		}
		this.setState(newState)
	}

	removeItem = (keyName, index) => {
		let lists = this.state.lists
		let items = lists[keyName].items;
		items = removeItemFromArray(items);

		const newState = {
			...this.state,
			lists: lists
		}
		this.setState(newState)
	}

	render() {
		return (
			<DragDropContext onDragEnd={this.onDragEnd}>
				<DragDropArea>
					{Object.keys(this.state.lists).map((keyName, i) => (
						this.state.lists[keyName].title.indexOf('Column') >= 0 ?
							<VerticalDroppableContainer
							>
								{this.state.lists[keyName].items.map((item, index) => (
									<RBDDroppableBox
										key={item.id}
										list={this.state.lists[keyName]}
										item={item}
										index={index}
										type={this.answerMode ? 'DEFAULT' : this.state.lists[keyName].title}
										showTextArea={this.createMode || this.props.viewConfig.editMode}
										removeItem={this.removeItem}
									/>
								))}
								{this.createMode ?
									<AddButton
										clicked={(e) => this.addItem(keyName)}
									/> : ''}
							</VerticalDroppableContainer>
							: ''
					))}
				</DragDropArea>
				{this.showMode ?
					Object.keys(this.state.lists).map((keyName, i) => (
						this.state.lists[keyName].title == 'Possibile Responses' ?
							<HorizontalDroppableContainer>
								<RBDDroppableArea
									key={this.state.lists[keyName].id}
									list={this.state.lists[keyName]}
									// disableDraggableAcrossColumn={true}
									type={'DEFAULT'}
									hidePlaceholder={true}
									direction={'horizontal'}
								/>
							</HorizontalDroppableContainer>
							: ''
					)) : ''
				}
			</DragDropContext>
			// <div>
			// 	<DragDropContext onDragEnd={this.onDragEnd}>
			// 		<Container>
			// 			{
			// 				Object.keys(this.state.lists).map((keyName, i) => (
			// 					<div>
			// 						<DroppableArea
			// 							key={this.state.lists[keyName].id}
			// 							list={this.state.lists[keyName]}
			// 							disableDraggableAcrossColumn={true}
			// 						/>
			// 						{this.createMode ?
			// 							<AddButton
			// 								clicked={(e) => this.addItem(keyName)}
			// 							/> : ''
			// 						}
			// 						{this.createMode ?
			// 							<ListRemoveButton
			// 								clicked={(e) => this.removeItem(keyName)}
			// 							/> : ''
			// 						}
			// 					</div>
			// 				))
			// 			}
			// 		</Container>
			// 	</DragDropContext>
			// </div>
		);
	}
};
export default RTCAudioRecording;

