import React from "react";
import { useSelector,useDispatch } from "react-redux";
import { Card, Avatar, Menu, Dropdown, Button, Tooltip } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import _ from "lodash";
import { partnerNameSelector } from "/src/views/Auth/Login/selector";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { getAppVersion } from "/src/App/OfflineApp/offlineAppHelper";

const LogoutDropdown = (props) => {
  const { currentUser, logout } = props
  console.log("line no 8", currentUser);
  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  const partnerName = useSelector(partnerNameSelector())
  const appType = useSelector(appTypeSelector());
  const { Meta } = Card
  const userLogo = currentUser.attachments_json && currentUser.attachments_json.logo || ""
  const userName = currentUser.name || ""
  const userRole = currentUser.role || ""
  let userNameFirstLetter = userName.charAt(0);

  const currentAppVersion = getAppVersion(appType)



  let menuItems =
    <div className="profile-info-dropdown">
      <Menu.Item style={{ backgroundColor: "transparent", pointerEvents: 'none' }} key={0}>
        <Meta
          avatar={currentUser.attachments_json ? <Avatar size={"large"} src={userLogo} /> : <Avatar className="default-logo-button">{userNameFirstLetter}</Avatar>}
          title={userName}
          description={_.upperFirst(userRole)}
        />
      </Menu.Item>
      {partnerName != "21k" && <Menu.ItemGroup>
        <Menu.Item key={1} onClick={() => {
          logout(); 
        }}>
          <Meta
            title={<><LogoutOutlined />{" "} {mrIntl("LogoutDropdown.logout")}</>}
          />
        </Menu.Item>
      </Menu.ItemGroup>}
    </div>
  const menu = <Menu style={{ top: 10 }}>{menuItems}</Menu>;

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Tooltip title={""} placement="left">
      <Button type="link" color="#1890ff">
        {currentUser.attachments_json ? <Avatar className="profile-logo-button" src={userLogo} /> : <Avatar className="default-logo-button profile-logo-button">{userNameFirstLetter}</Avatar>}
      </Button>
      </Tooltip>
    </Dropdown>
  )
}

export default LogoutDropdown;