import React, { useState, state } from "react";
import { useSelector } from "react-redux";
import { Button, Space, Tooltip } from "antd";
import { MediaButtonsContainer } from "/src/components/UI/Segment/UIHelper";
import MediaUI from "/src/components/UI/Media/MediaUI";
import { AudioOutlined } from "@ant-design/icons";
import ShowMediaComments from "./ShowMediaComments";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { activeExperienceGradingDisabledSelector } from "/src/views/Experiences/selector";

const MediaComments = (props) => {
  const { mediaButtonsConfig = { audio: true }, onChange, gradingDisabled } = props;
  let media_comments = props.value || props.media_comments;

  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const mrIntl = useTranslate();
  const activeExperienceGradingDisabled = useSelector(activeExperienceGradingDisabledSelector())
  
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [mediaFormSetting, setMediaFormSetting] = useState({
    modal: true,
    loading: false,
    visible: false,
    // from: "computer",
    currentMediaType: "",
    okDisabled: true,
    triggerSaveAction: 0,
    limit: 1,
  });
  const saveState = (state) => {
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
    onChange(state);

  };
  const onSaveMedia = (files) => {
    const media_comments = {
      attachments: [files[0]]
    };
    saveState(media_comments);
  };
  const openMediaModal = (type) => {
    if (type === "audio") {
      setMediaFormSetting({
        ...mediaFormSetting,
        visible: true,
        currentMediaType: type,
        from: "audiorecorder",
        title: mrIntl("CommonText.insert_audio"),
        okText: mrIntl("CommonText.add_audio_button"),
        cancelText: mrIntl("CommonText.cancel"),
        content_type: "audio",
        supportedTypes: "audio/mp3, audio/ogg, audio/mpeg",
        supportedFormats: "mp3, ogg",
        selectConfig: {
          multiple: false,
          maxSize: "20 MB",
        },
      });
    }
  };
  const beforeOnSaveMedia = (files) => {
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: false,
      triggerSaveAction: 0,
    });
    const limit = mediaFormSetting.limit;
    if (limit) {
      files = files.slice(-limit);
    }
    onSaveMedia(files);
  };
  const getMediaForm = (type) => {
    // let audiorecorder = false;
    // let computer = true;
    let url = true;
    if (type === "audio") {
      // audiorecorder = true;
      url = false;
    }
    return (<MediaUI
      // audiorecorder={audiorecorder}
      // computer={computer}      
      url={url}
      mediaFormSetting={mediaFormSetting}
      setMediaFormSetting={(data) => setMediaFormSetting(data)}
      onSaveMedia={(files) => beforeOnSaveMedia(files)}
    ></MediaUI>);
  };

  const mediaCommentsAllowed = enabledFeatures.media_comments && !activeExperienceGradingDisabled
  return (
    <div className="media-comments">
      <div className=" media-comments-form">
        <span>
          {mediaFormSetting.visible && getMediaForm(mediaFormSetting.currentMediaType)}
          {!media_comments || media_comments.attachments.length <= 0 ? <MediaButtonsContainer
            className='media-buttons'
          >
            <Space direction="vertical">
              {mediaButtonsConfig.audio &&
                <Tooltip title={mediaCommentsAllowed ? mrIntl("CommonText.record_title") : mrIntl("MediaComments.audio_feedback_is_only_available_in_plus_plan")} placement="top">
                  <Button
                    onClick={(e) => openMediaModal("audio")}
                    type="text"
                    shape="circle"
                    // size="small"
                    className="record-button"
                    disabled={!mediaCommentsAllowed}
                  >
                    <AudioOutlined />
                    {/* Audio comment */}
                  </Button>
                </Tooltip>
              }
            </Space>
          </MediaButtonsContainer> : ""}
        </span>
      </div>
      {media_comments && <ShowMediaComments
        media_comments={media_comments}
        removeFileIcon={true}
        onChange={onChange}
      >
      </ShowMediaComments>}
    </div>
  );
};
export default MediaComments;
