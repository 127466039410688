import { Button, Modal, Space, Input, message, Tooltip } from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import React, { useState } from "react";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "/src/views/Feedbacks/redux";
import "./RateAiResult.scss";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";

const { TextArea } = Input;

const RateAiResult = ({ generatedData, action }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feedbackMsg, setFeedbackMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [rateAiButtonDisabled, setRateAiButtonDisabled] = useState(false);
  const mrIntl = useTranslate();
  const dispatch = useDispatch();
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const accessCode = activeExperience && activeExperience.access_code;
  console.log("activeExperience==>", activeExperience)

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setRateAiButtonDisabled(true);
    sendFeedback("thumbs down");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const sendFeedback = (feedbackRating) => {
    setRateAiButtonDisabled(true);
    let feedbackData = {
      feedback_type: "other_feedback",
      message: `Feedback in (${action}) - ${feedbackRating === "thumbs up" ? "&#128077" : "&#128078" } \nTest code:- ${accessCode} ${feedbackMsg ? `\nMessage:- ${feedbackMsg}` : ""} \nAI result:- ${JSON.stringify(generatedData)}`,
    };
    dispatch(
      actions.create(
        {
          ...feedbackData,
        },
        {
          success: {
            showMessage: true,
            message: mrIntl("FeedbackModal.thank_you_for_your_feedback"),
          },
          successCallback: () => {
            setLoading(false);
            setIsModalOpen(false);
          }
        }
      )
    );
  };
  const rateAiButtonDisabledText = rateAiButtonDisabled
  ? mrIntl("RateAiResult.feedback_successfully_submitted")
  : "";

  return (
    <>
      <Space className="rate-ai-buttons-container">
          <Tooltip title={rateAiButtonDisabled ? mrIntl("RateAiResult.feedback_successfully_submitted") : "Good response"}>
            <Button
              disabled={rateAiButtonDisabled }
              icon={<FaRegThumbsUp />}
              type="text"
              size="small"
              onClick={() => sendFeedback("thumbs up")}
            />
          </Tooltip>
          <Tooltip title={rateAiButtonDisabled ? mrIntl("RateAiResult.feedback_successfully_submitted") : "Bad response"}>
            <Button
              disabled={rateAiButtonDisabled}
              icon={<FaRegThumbsDown />}
              type="text"
              size="small"
              onClick={() => showModal()}
            />
          </Tooltip>
      </Space>
      {isModalOpen && (
        <Modal
          confirmLoading={loading}
          title={mrIntl("RateAiResult.provide_feedback")}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={mrIntl("RateAiResult.submit_feedback")}
          okButtonProps={{ disabled: !feedbackMsg }}
        >
          <TextArea
            rows={4}
            value={feedbackMsg}
            onChange={(e) => setFeedbackMsg(e.target.value)}
            placeholder={mrIntl("RateAiResult.type_here")}
          />
        </Modal>
      )}
    </>
  );
};
export default RateAiResult;
