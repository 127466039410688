import React from "react";
import { useSelector } from "react-redux";
import {
  appRegionSelector,
  currentUserSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";
import {
  hasStudentReadUserInfoSelector,
  hasTeacherReadUserInfoSelector,
  isStudentActiveUserInfoSelector,
  isTeacherActiveUserInfoSelector,
} from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import MrChat from "/src/lib/MrChat/MrChat";
import ChatWrapper from "/src/lib/MrChat/UI/ChatWrapper/ChatWrapper";
import { firestoreFieldValue } from "/src/config/initializers";

const StudentChat = (props) => {
  console.log("StudentChat props==>", props);

  const { setUserInfo, experience, openChat, handleCurrentTool } = props;
  const appRegion = useSelector(appRegionSelector());
  const currentUser = useSelector(currentUserSelector());
  const hasStudentRead = useSelector(hasStudentReadUserInfoSelector);
  const hasTeacherRead = useSelector(hasTeacherReadUserInfoSelector);
  const isTeacherActive = useSelector(isTeacherActiveUserInfoSelector);
  const isStudentActive = useSelector(isStudentActiveUserInfoSelector);
  const enabledFeatures = useSelector(enabledFeaturesSelector())

  // let userChatInfo = {
  //   isOnline: true,
  //   isActive: isTeacherActive
  // }
  const newJoinViewWithDynamicConfigEnabled = enabledFeatures.new_join_view_with_dynamic_config

  let chatModule = (
    <MrChat
      key={`student-chat-module-${currentUser.id}`}
      config={{
        role: "student",
        chat_type: "individual",
        isOnline: true,
        sender_name: currentUser.name,
        sender_id: currentUser.id,
        chat_id: `${currentUser.id}`,
        isActive: isTeacherActive,
        hasStudentRead: hasStudentRead,
      }}
      msgExtra={{ experience_id: experience.id }}
      collection={{ path: `/experiences/${experience.id}/messages` }}
      afterMessageCallback={() => {
        if (!isTeacherActive || !hasTeacherRead) {
          setUserInfo({ has_teacher_read: false, needs_attention_at: Date.now() });
        }
      }}
      openChat={openChat}
      handleCurrentTool={handleCurrentTool}
    />
  );

  let finalRender = null;

  // if (appRegion !== "china") {
    finalRender = (
      <ChatWrapper
        newMessages={hasStudentRead === false}
        chatUnmountCallback={() => {
          if (newJoinViewWithDynamicConfigEnabled && isStudentActive) {
            // ON first test start its creating new user info object with only these fields - so now checking isStudentActive to avoid creating new user info object
            setUserInfo({
              active_students: appRegion !== "china" ? firestoreFieldValue.arrayRemove(currentUser.id) : [],
              is_student_active: false,
              chat_student_last_seen_at: Date.now(),
            });
          }
        }}
        onVisibleChangeCallback={(visible) => {
          // let updatedUserInfo = {}, shouldUpdateUserInfo = false;
          if (visible) {
            setUserInfo({
              is_student_active: true,
              active_students: appRegion !== "china" ? firestoreFieldValue.arrayUnion(currentUser.id) : [],
              has_student_read: true,
              student_unread_count: 0,
            });
            // console.log("chat visible true", visible)
          } else {
            setUserInfo({
              active_students: appRegion !== "china" ? firestoreFieldValue.arrayRemove(currentUser.id) : [],
              is_student_active: false,
              chat_student_last_seen_at: Date.now(),
            });
            console.log("chat visible false", visible);
          }
        }}
        openChat={openChat}
      >
        {chatModule}
      </ChatWrapper>
    );
  // }
  return finalRender;
};

export default StudentChat;
