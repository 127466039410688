import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useScript from '/src/lib/utils/useScript';
import { getUniqueId } from '/src/lib/utils/helperMethods';
import queryString from "query-string";
import "./AppleLogin.scss";
// let AppleID;
const CustomAppleLogin = (props) => {
  const [loaded] = useScript(
    `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`
  );
  const designProps = {
    height: 40,
    // width: "",
    // color: "",
    // border: "",
    type: "sign-in",
    border_radius: 0,
    scale: 1,
    locale: "en_US"
  }
  useEffect(() => {
    if(!loaded){
      return;
    }
    const AppleID = window.AppleID;
    console.log( "AppleID", AppleID );
    const appleLoginState = getUniqueId();
    const appleLoginNounce = getUniqueId();
    try {
      const apiBaseUrl = import.meta.env.VITE_API_BASE_URL || "https://api-dev2.assessprep.com/"

      AppleID.auth.init({
        clientId : 'com.assessprep.applessoclient',
        scope : 'name email',
        redirectURI : `${apiBaseUrl}oauth/apple/callback`,
        state : appleLoginState,
        // nonce : appleLoginNounce,
        nonce : "nounce123",
        // usePopup : false //or false defaults to false
      });
    } catch (error) {
      console.log( "AppleID error", AppleID );
    }
    return () => {
      // cleanup
    }
  }, [loaded])
  const onClick = (e) => {
    console.log( "applelogin check", e );
    console.log( "applelogin loaded", loaded );
    if (e) {
      // e.preventDefault();
    }
    if(!loaded){
      return;
    }
    // const AppleID = window.AppleID;
    // console.log( "AppleID", AppleID );
    // try {
      
    //   AppleID.auth.init({
    //     clientId : 'com.assessprep.applessoclient',
    //     scope : 'name email',
    //     redirectURI : 'https://api-dev2.assessprep.com/oauth/apple/callback',
    //     state : 'randomstring',
    //     // nonce : '[NONCE]',
    //     usePopup : false //or false defaults to false
    //   });
    // } catch (error) {
    //   console.log( "AppleID error", AppleID );
    // }
  }
  // useEffect(() => {
  //   useScript("")
  //   return () => {
  //     cleanup
  //   }
  // }, [])
  return (
    <div
      class={"appleid-wrapper"}
    >
      <div
        id="appleid-signin"
        data-color="black"
        data-border="true"
        data-type="sign in"
        style={{
          cursor: props.disabled ? "not-allowed" : "",
          pointerEvents: props.disabled ? "none" : "auto",
        }}
      >
        <img
          alt={"Apple Signin"}
          src={`https://appleid.cdn-apple.com/appleid/button?${queryString.stringify(
            designProps
          )}`}
        />
      </div>
    </div>
  );
  
};

CustomAppleLogin.defaultProps = {}

CustomAppleLogin.propTypes = {}

export default CustomAppleLogin;
