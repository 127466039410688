import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button, Modal, Alert, Tooltip, Tag, Space } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import { removeItemFromArray } from "/src/views/Segments/InteractiveHelpers";
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import ThumbnailAttachments from "/src/components/UI/Attachments/ThumbnailAttachments/ThumbnailAttachments";
import PreviewAttachment from "/src/components/UI/Attachments/PreviewAttachment";
import { cloneDeep, unionBy } from "lodash";
import { checkIPAD, checkMob, getLabelFromIndexPath, getUniqueId, sortBySegmentIndexPath } from "/src/lib/utils/helperMethods";
import Compressor from "compressorjs";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { activeAdjustedExperienceSelector, experienceViewModeSelector, getInternetStatusSelector } from "/src/views/Experiences/selector";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { UndoOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { formatJsonApiData } from "mr_react_framework";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import  "./ResponseAttachments.scss";
import { activeExperienceUserSelector, experienceUsersLoadingSelector } from "/src/views/ExperienceUsers/selector";
import { MdRestore } from "react-icons/md";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import ImageCaptureUploadModal from "/src/components/UI/Media/ImageCaptureUploadModal";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { allowedInBrowserSelector } from "../../Experiences/ExperienceShow/FirestoreInteractions/selector";
import NoData from "/src/components/UI/NoData/NoData";
import MediaShow from "/src/components/UI/Media/MediaShow";
import MapAttachmentToQuestionSelector from "./MapAttachmentToQuestionSelector";

// const AutoMapHandWrittenAnswers = (props) => {
//   console.log("AutoMapHandWrittenAnswers props ==>", props)
//   const { disabled, onSubmit, attachments_json, mediaFormSetting, setMediaFormSetting, stateUpdatedCount, setStateUpdatedCount } = props

//   const dispatch = useDispatch()
//   const mrIntl = useTranslate()

//   const currentUser = useSelector(currentUserSelector())
//   const activeExperience = useSelector(activeAdjustedExperienceSelector())
//   const experienceUser = useSelector(activeExperienceUserSelector())

//   const [triggerCheckProgressInterval, setTriggerCheckProgressInterval] = useState(null)

//   const customFields = experienceUser.custom_fields || {};
//   const autoMappingProgress = customFields["auto_mapping_progress"];

//   const handleAutoMapHandWrittenAnswers = () => {
//     dispatch(
//       experienceUserActions.autoMapHandwrittenAnswers({ 
//         experienceUserId: experienceUser.id,
//       })
//     );
//   }

//   useEffect(() => {
//     console.log("triggerCheckProgressInterval useEffect ==>", experienceUser)
//     let intervalId
//     if ((autoMappingProgress && autoMappingProgress["status"] !== "completed") || triggerCheckProgressInterval) {
//       setMediaFormSetting({
//         ...mediaFormSetting,
//         okDisabled: true,
//         autoMapLoading: true
//       });
//       intervalId = setInterval(() => {
//         dispatch(experienceUserActions.show({ 
//           id: experienceUser.id,
//         }, {
//           successCallback: (response) => {
//             const updatedExperienceUser = formatJsonApiData(response.data.experience_user);
//             console.log("updatedExperienceUser ==>", updatedExperienceUser);
//             const updatedAutoMappingProgress = updatedExperienceUser.custom_fields?.auto_mapping_progress;

//             if (updatedAutoMappingProgress) {
//               const progressCompleted = ["completed", "failed"].includes(updatedAutoMappingProgress.status);
//               const messageContent = `${updatedAutoMappingProgress.message}${updatedAutoMappingProgress.status === "failed" ? ". Please try again." : "."}`;
//               const messageMethod = updatedAutoMappingProgress.status === "completed" ? message.success : message.error;

//               if (progressCompleted) {
//                 clearInterval(intervalId);
//                 setStateUpdatedCount(0);
//                 setMediaFormSetting({
//                   ...mediaFormSetting,
//                   loading: false,
//                   autoMapLoading: false,
//                 });
//                 messageMethod(messageContent);
//               }
//             }
//           }
//         }));
//       }, 10000);

//       // Timeout loading after 1 minute
//       setTimeout(() => {
//         clearInterval(intervalId);
//         setStateUpdatedCount(0)
//         setMediaFormSetting({
//           ...mediaFormSetting,
//           loading: false,
//           autoMapLoading: false,
//         });
//       }, 120000);
//     }

//     return () => {
//       clearInterval(intervalId);
//     }
//   }, [triggerCheckProgressInterval])

//   const showAutoMapHandWrittenAnswersConfirm = () => {
//     showConfirmModal({
//       title: "Are you sure you want to auto map hand written answers?",
//       icon: <ExclamationCircleOutlined />,
//       okText: mrIntl("CommonText.ok"),
//       cancelText: mrIntl("CommonText.cancel"),
//       okType: "primary",
//       content: "This will map hand written answers to the correct questions. Can take upto 30 seconds.",
//       onOk() {
//         if (onSubmit && stateUpdatedCount) {
//           onSubmit(
//             attachments_json, 
//             {
//               // autoMap: true,
//               doNotCloseModal: true,
//               doNotShowConfirmModal: true,
//               submitAttachmentsStartCallback: () => {
//                 setMediaFormSetting({
//                   ...mediaFormSetting,
//                   loading: true,
//                 });
//               },
//               submitAttachmentsCompletedCallback: () => {
//               }
//             }
//           );
//         } else {
//           handleAutoMapHandWrittenAnswers()
//         }
//         setTriggerCheckProgressInterval(getUniqueId());
//       },
//       onCancel() {
//         console.log("Cancel");
//       },
//     });
//   };


//   return (
//     <>
//     <Tooltip title={"Auto map hand written answers"}>
//       {(currentUser.role === "admin" ||
//         (currentUser.role === "teacher")) && 
//         (<Button
//           key="menu-item-auto-map-hand-written-answers"
//           className="auto-map-hand-written-answers-button"
//           icon={<ImMagicWand />}
//           onClick={() => {
//             showAutoMapHandWrittenAnswersConfirm();
//           }}
//           loading={mediaFormSetting.autoMapLoading}
//           disabled={activeExperience.status === "closed" || disabled}
//         />)
//       }
//     </Tooltip>
//   </>
//   )
// }

const ReopenUploads = (props) => {
  const { disabled } = props
  const dispatch = useDispatch()
  const mrIntl = useTranslate()
  
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const currentUser = useSelector(currentUserSelector())
  const experienceUser = useSelector(activeExperienceUserSelector())

  const experienceSettings = activeExperience.settings || {};
  let isAllowStudentUploadFiles = experienceSettings.allow_student_upload_files;

  const showUploadBtnConfirm = () => {
    showConfirmModal({
      title: mrIntl("ResponseAttachments.are_you_sure_you_want_to_allow_more_uploads_from_student"),
      icon: <ExclamationCircleOutlined />,
      okText: mrIntl("CommonText.ok"),
      cancelText: mrIntl("CommonText.cancel"),
      okType: "primary",
      content: mrIntl("ResponseAttachments.this_will_allow_the_student_to_upload_extra_files"),
      onOk() {
        console.log("OK");
        dispatch(
          experienceUserActions.retakeExperience(
            { experienceUser: experienceUser },
            {
              successCallback: (response) => {
                const updatedExperienceUser = {
                  ...experienceUser,
                  uploads_allowed: true,
                };

                dispatch(
                  experienceUserActions.showSuccess(
                    { data: { experience_user: updatedExperienceUser } },
                    { success: { showMessage: false } }
                  )
                );
              },
            }
          )
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return <>
    <Tooltip title={
      activeExperience.status === "closed"
        ? mrIntl("ResponseAttachments.published_assessment_then_reopen_uploads")
        : disabled ? mrIntl("ResponseAttachments.unpublished_result_to_reopen_upload") : mrIntl("ResponseAttachments.reopen_uploads_title")
    }>
      {(currentUser.role === "admin" ||
        (currentUser.role === "teacher" &&
          currentUser.permission.experience_user &&
          currentUser.permission.experience_user.reset === true)) &&
        isAllowStudentUploadFiles && (<Button
          key="menu-item-reopen-uploads"
          className="reopen-upload-button"
          icon={<MdRestore />}
          onClick={() => {
            showUploadBtnConfirm();
          }}
          disabled={activeExperience.status === "closed" || disabled}
        />)}
    </Tooltip>
  </>
}
const internetStatusSelector = getInternetStatusSelector();
const ResponseAttachments = (props) => {
  console.log("Response attachments props ==>", props)
  const {
    answerMode,
    modalVisible,
    closeModal,
    listType,
    previewType,
    presentationMode,
    attempt_status = {},
    maxAttachments,
    gradingEnabled,
    experienceSettings = {},
    screenSettings,
    parentIdxs,
    disableUploader = false,
    disabledUploaderTooltip,
    isQuestionMappingEnabled,
    deleteNotAllowedMessage,
    activeExperienceUser
  } = props;

  let attemptStatusObject = {
    files_uploaded: attempt_status.files_uploaded || 0,
    files_upload_attempted: attempt_status.files_upload_attempted || false,
  };

  const mrIntl = useTranslate()
  const internetStatus = useSelector(internetStatusSelector);
  const currentUser = useSelector(currentUserSelector())
  let experienceUser = useSelector(activeExperienceUserSelector());
  if (activeExperienceUser) {
    experienceUser = activeExperienceUser
  }
  const appType = useSelector(appTypeSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const experienceUsersLoading = useSelector(experienceUsersLoadingSelector())
  const allowedInBrowser = useSelector(allowedInBrowserSelector)
  const experienceViewMode = useSelector(experienceViewModeSelector())

  const [state, setState] = useState(cloneDeep(props.attachments_json));
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [unsavedFiles, setUnsavedFiles] = useState([])
  const [stateSetting, setStateSetting] = useState({
      updatedCount: 0,
    });

  const experienceMembership = experienceUser.student_experience_membership;
  const customFields = experienceUser.custom_fields || {};
  const pdfConversionProgress = customFields["pdf_conversion_progress"] || {}
  const autoMappingProgress = customFields["auto_mapping_progress"] || {};

  let attachments_json;
  if (presentationMode) {
    attachments_json = props.attachments_json || {};
  } else {
    attachments_json = state || {};
  }
  console.log("state check ===>", {aj: props.attachments_json, state});

  const attachments = attachments_json.attachments || [];
  const images = attachments_json.images || [];
  const removedAttachments = attachments_json.removedAttachments || [];
  const attachmentCount = attachments?.length || 0;
  const hasUploads = attachmentCount || images?.length
  const showDragger = !hasUploads && isQuestionMappingEnabled;
  const defaultType = showDragger ? "" : isQuestionMappingEnabled ? "upload-button" : "pictures-wall";

  const user_id = props.userId || currentUser.id
  const isMobile = checkMob()
  const isIPAD = checkIPAD()

  const allowZipFileUpload = 
    enabledFeatures.allow_zip_file_upload
  
  const allowTxtFileUpload =
    enabledFeatures.allow_txt_file_upload

  const allowImageCaptureUploadFlagEnable =
    enabledFeatures.allow_image_capture_upload;
  const isOnlineSecureOrOfflineMode =
    experienceSettings.mode === "online_secure" ||
    experienceSettings.mode === "offline";

  const isAllowedInBrowser =
    (experienceMembership && experienceMembership.settings.allow_online) ||
    allowedInBrowser; // Handling for both new and old join view

  //Only allow allowImageCaptureUploadFlagEnable + (web + online_secure || seb/Mac/iPad + offline) 
  const shouldShowImageCaptureUploadModal =
    !isMobile &&
    !isIPAD &&
    allowImageCaptureUploadFlagEnable &&
    experienceViewMode == "apTakeTest" &&
    ((appType === "web" && experienceSettings.mode == "online_secure") ||
      ((appType === "seb" || appType === "mac") &&
        isOnlineSecureOrOfflineMode) ||
      (isOnlineSecureOrOfflineMode && isAllowedInBrowser)); 

  const hideUploader = 
    experienceViewMode === "apTakeTest" &&
    experienceSettings.mode !== "online" &&
    !isMobile &&
    !isIPAD
      ? true
      : false

  const supportDirectCameraAccess = isIPAD && isOnlineSecureOrOfflineMode;

  const isAdminOrTeacher = currentUser.role === "admin" || currentUser.role === "teacher";
  const isStudent = currentUser.role === "student";
  const isPaperMode = experienceSettings.mode === "paper";

  const defaultFileList = 
    isQuestionMappingEnabled 
      ? [...images]
      : [...attachments]

  const customItemRender = (originNode, file, fileList, actions) => {
    console.log("customItemRender file ==>", file)
    const questionNumber = getLabelFromIndexPath(file.segment_index_path)
    const isHighlighted = file.url === mediaFormSetting?.previewCurrentFile?.url;  
    
    // const clonedNode = React.cloneElement(originNode, {}, ...originNode.props.children);
    
    // // Find the element with the class 'ant-upload-icon' and replace its content
    // const updatedChildren = React.Children.map(clonedNode?.props.children, child => {
    //   if (child?.props && child.props.className === 'ant-upload-icon') {
    //     return <span className="attachment-question-number">{questionNumber ? <Tag>{`Q ${questionNumber}`}</Tag> : "-"}</span>;
    //   }
    //   return child;
    // });

    // console.log("clonedNode check ==>", clonedNode);


    // const handleClick = (e) => {
    //   e.preventDefault();
    //   actions.preview(e);
    // }
    // return (
    //   <Row className={`file-item-row ${isHighlighted ? "highlight" : ""}`} onClick={handleClick}>
    //     <Col span={23}>
    //       {React.cloneElement(clonedNode, {}, updatedChildren)}
    //     </Col>
    //   </Row>
    // );

    return (
      <Row className={`file-item-row ${isHighlighted ? "highlight" : ""}`}>
        <Col span={20}>
          {originNode}
        </Col>
        <Col span={4} className="file-item-question-tag">
          {questionNumber ? (
            <Tag>{questionNumber}</Tag>
          )
        : "-"}
        </Col>
      </Row>
    );
  };

  let mediaFormInitialState = {
    id: props.id,
    modal: false,
    loading: false,
    visible: false,
    title: mrIntl("ResponseAttachments.insert_attachments_title"),
    okText: mrIntl("CommonText.save"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    requireFooter: false,
    content_type: "image",
    supportedTypes:
      "image/jpg, image/jpeg, image/png, image/gif, image/bmp, application/pdf",
    supportedFormats: "jpg, jpeg, png, gif, bmp, pdf",
    limit: maxAttachments || 25,
    deleteNotAllowedMessage: deleteNotAllowedMessage || "",
    currentFileIndex: 0,
    extraParams: `save_record=true&user_id=${user_id}&record_type=Experience&record_id=${props.recordId}&upload_type=${props.uploadType}`,
    previewCurrentFile:
      isQuestionMappingEnabled && images.length ? images[0] : null,
    showSupportedTypes: !supportDirectCameraAccess && !hideUploader,
    selectConfig: {
      hide_uploader: hideUploader,
      // experienceViewMode === "apTakeTest"
      // ? (experienceSettings.mode == "online" || (experienceSettings.mode != "online" && (isMobile || checkIPAD())))
      // ? false
      // : true
      // : false,
      disabled: disableUploader,
      disabledUploaderTooltip: disableUploader && disabledUploaderTooltip,
      type: defaultType,
      overrideUploadList: false,
      notDeletableFiles: deleteNotAllowedMessage ? [...defaultFileList] : [],
      defaultFileList: [...defaultFileList],
      maxSize: "60 MB",
      multiple: (isAdminOrTeacher || (isStudent && isPaperMode))  ? true : false,
      doNotShowUploadButton: !answerMode, 
      transformFile: (file) => {
        return new Promise((resolve) => {
          new Compressor(file, {
            quality: 0.6,
            success(result) {
              console.log("Compressor resolve file ==>", result);
              resolve(result);
            },
            error(err) {
              console.log(err.message);
              resolve(file);
            },
          });
        });
      },
    },
    imageCaptureConfig: {
      showImageCaptureUploader: shouldShowImageCaptureUploadModal,
      disabled: disableUploader,
      disabledUploaderTooltip: disableUploader && disabledUploaderTooltip,
      type: "pictures-wall",
      overrideUploadList: false,
    },
    // onPreview: async file => {
    //   let src = file.url;
    //   if (!src) {
    //     src = await new Promise(resolve => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(file.originFileObj);
    //       reader.onload = () => resolve(reader.result);
    //     });
    //   }

    //   setMediaFormSetting({
    //     ...mediaFormSetting,
    //     previewCurrentFile: {
    //       ...file,
    //       url: src
    //     }
    //   })
    // },
    // onChangeSaveFiles: (files) => {
    //   console.log("on change files ==>", files)
    //   const newFiles = files
    //   onSaveMedia(newFiles)
    // },
    // onRemoveFile: (file) => {
    //   onRemoveMedia(file)
    // },
    // onDragEnd: (result) => {
    //   return onMediaOrderChange(result)
    // }
  };

  if (supportDirectCameraAccess) {
    mediaFormInitialState = {
      ...mediaFormInitialState,
      selectConfig: {
        ...mediaFormInitialState.selectConfig,
        accept: "image/x-png,image/jpeg", // accept will allow the capture image option on native camera otherwise will allow the video also
        capture: "environment",
      },
    };
  }

  if (allowZipFileUpload) {
    mediaFormInitialState = {
      ...mediaFormInitialState,
      supportedTypes:  mediaFormInitialState.supportedTypes + ", application/zip, application/x-zip-compressed",
      supportedFormats: mediaFormInitialState.supportedFormats +  ", zip",
    };
  }

  if (allowTxtFileUpload) {
    mediaFormInitialState = {
      ...mediaFormInitialState,
      supportedTypes:  mediaFormInitialState.supportedTypes + ", text/plain",
      supportedFormats: mediaFormInitialState.supportedFormats +  ", txt",
    };
  }

  if (listType) {
    mediaFormInitialState = {
      ...mediaFormInitialState,
      selectConfig: {
        ...mediaFormInitialState.selectConfig,
        listType: listType
      }
    }
  }

  let autoMappingStatusMsg = null;
  let isAutoMappingStatusFinalized = false;
  let isPdfConversionStatusFinalized = false;

  
  if (isQuestionMappingEnabled) {
    const { status: pdfStatus } = pdfConversionProgress;
    const { status: autoMapStatus } = autoMappingProgress;

    if (pdfStatus === "in_progress") {
      autoMappingStatusMsg = <Tag color="processing">Preparing files for auto mapping...</Tag>;
    } else if (pdfStatus === "failed") {
      autoMappingStatusMsg = <Tag color="error">Something went wrong. Can't auto map files.</Tag>;
    } else if (autoMapStatus === "initialized") {
      autoMappingStatusMsg = <Tag color="success">Files are ready for auto mapping.</Tag>;
    } else if (autoMapStatus === "in_progress") {
      autoMappingStatusMsg = <Tag color="processing">Auto mapping in progress...</Tag>;
    } else if (autoMapStatus === "failed") {
      isAutoMappingStatusFinalized = true;
      autoMappingStatusMsg = <Tag color="error">Something went wrong auto mapping failed.</Tag>;
    } else if (autoMapStatus === "completed") {
      isAutoMappingStatusFinalized = true;
      autoMappingStatusMsg = <Tag color="success">Files auto mapped.</Tag>;
    }

    if(pdfStatus === "completed" || pdfStatus === "failed") {
      isPdfConversionStatusFinalized = true
    }
  }
  
  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );

  // mediaFormSetting.previewCurrentFile = (file) => {
  //   console.log("Preview file ===>", file)
  //   return file.url
  // }

  //it help to fix focus lost when ipad camera capture modal open , APL-4664
  if (shouldShowImageCaptureUploadModal || supportDirectCameraAccess) {
    mediaFormSetting.skipFocusLost = () => {
        setSkipFocusLostModal(true);
        setTimeout(() => {
          setSkipFocusLostModal(false);
        }, 2000);
    };
  }

  //skipFocusLostModal => Skipping the focus lost modal when camera capture will open, It will skip for only 2 sec when user done the camera campture, now calling the on beforeUpload funciton of selectFromComputer function
  const setSkipFocusLostModal = (value) => {
    console.log("setSkipFocusLostModal ==>", value)
    dispatch(
      experienceReduxActions.setSkipFocusLostModalSuccess({
        skipFocusLostModal: value,
      })
    );
  };

  mediaFormSetting.previewFile = (file) => {
    return file;
  };
  
  const onPreview = async (file, value) => {
    console.log("onPreview file ==>", file, value);
    if (props.onPreview) {
      props.onPreview()
    } else {
      let previewFile = file
      
      if (file.response) {
        previewFile = file.response
      } else {
        let src = file.url;
        if (!src) {
          src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        previewFile.url = src;
      }
      
      setMediaFormSetting(prevMediaFormSetting => ({
        ...prevMediaFormSetting,
        previewCurrentFile: previewFile,
      }));
    }
  };

  if (isQuestionMappingEnabled) {
    mediaFormSetting.selectConfig.onPreview = onPreview
    mediaFormSetting.selectConfig.itemRender = customItemRender
  }

  mediaFormSetting.onChangeSaveFiles = (files) => {
    console.log("on change files ==>", files);
    const { newFiles, extraFiles } = beforeSave(files);
    onSaveMedia(newFiles, extraFiles);
  };

  mediaFormSetting.onRemoveFile = (file) => {
    onRemoveMedia(file);
  };

  mediaFormSetting.onDragEnd = (result) => {
    return onMediaOrderChange(result);
  };

  const beforeSave = (files) => {
    let newFiles = [];
    let extraFiles = [];
    let { segment_id, topic_id, activeTopicIdx, qNo, parentIdxs } = props;
    files.map((file) => {
      file["upload_type"] =
        segment_id || file["segment_id"] ? "QuestionWise" : "ExperienceWise";
      if (segment_id) {
        file["topic_id"] = topic_id;
        file["segment_id"] = segment_id;
        // file["question_index"] = qNo - 1;
        file["topic_index"] = activeTopicIdx;
        // file["segment_index_label"] = getLabelFromIndexPath(parentIdxs)
        file["segment_index_path"] = parentIdxs
      }
      if (
        file.type &&
        file.type.indexOf("pdf") >= 0 &&
        file.page_urls &&
        false
      ) {
        // disabling conversion during upload - only after submission
        extraFiles.push(file);
        file.page_urls.map((page) => {
          page["topic_id"] = topic_id;
          page["segment_id"] = segment_id;
          page["upload_type"] = segment_id ? "QuestionWise" : "ExperienceWise";
          newFiles.push(page);
        });
      } else {
        newFiles.push(file);
      }

      console.log(
        "uploading this file ==>",
        file,
        segment_id,
        topic_id,
        activeTopicIdx,
        qNo,
        props
      );
    });

    return { newFiles, extraFiles };
  };

  useEffect(() => {
    return () => {
      setMediaFormSetting(mediaFormInitialState)
    }
  },[])

  useEffect(() => {
    if (!experienceUsersLoading && props.attachments_json) {
      setState(props.attachments_json);
    }
  }, [experienceUsersLoading])

  useEffect(() => {
    if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          attachments_json: cloneDeep(state),
          attempt_status: cloneDeep(attemptStatus),
        };
        console.log("last result which is sent ==>", result);
        props.onChange(result);
      }
    }

    if (isQuestionMappingEnabled) {
      let sortedImages = [...sortBySegmentIndexPath(images)];
      console.log("allImages ===>1", {sortedImages, unsavedFiles, state});
      
      setMediaFormSetting((prev) => ({
        ...prev,
        triggerFileListChange: getUniqueId(),
        selectConfig: {
          ...prev.selectConfig,
          type: showDragger ? "" : "upload-button",
          controlledFileList: sortedImages,
        },
      }));
    }
  }, [stateUpdatedCount]);

  useEffect(() => {
    if (isQuestionMappingEnabled && (isAutoMappingStatusFinalized || isPdfConversionStatusFinalized)) {
      const newAttachmentsJSON = cloneDeep(props.attachments_json);
      const savedImages = newAttachmentsJSON?.images || [];
      
      const updatedUnsavedFiles = state.images.filter(img => unsavedFiles.some(aImg => aImg.uid === img.uid));
      // replacing updatedImages(from state) with savedImages(from props) as they would have more updated segment to image relation (for saved images)data when isQuestionMapping finalized 

      const finalImages = unionBy(savedImages, updatedUnsavedFiles, "uid");
      const sortedImages = sortBySegmentIndexPath(finalImages);
      
      console.log("allImages ===>2", { updatedUnsavedFiles, sortedImages, savedImages, unsavedFiles, state });
      
      setMediaFormSetting(prev => ({
        ...prev,
        triggerFileListChange: getUniqueId(),
        previewCurrentFile: sortedImages[0],
        selectConfig: {
          ...prev.selectConfig,
          notDeletableFiles: savedImages,
          controlledFileList: sortedImages,
        },
      }));

      setState(prev => ({
        ...newAttachmentsJSON,
        images: sortedImages,
      }));
    }
  }, [isAutoMappingStatusFinalized, isPdfConversionStatusFinalized]);

  const saveState = (state) => {
    setState(state); 
    console.log(
      "save state is called with stateUpdatedCount",
      stateUpdatedCount,
      state
    );
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
    console.log(
      "save state is called with stateUpdatedCount",
      stateUpdatedCount,
      state
    );
    setMediaFormSetting((prevMediaFormSetting) => {
      return {
        ...prevMediaFormSetting,
        okDisabled: false,
      }
    });
  };

  const showResponseFileUploader = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: true,
    });
  };

  const handleOk = () => {
    
    if (!attachments.length) {
      message.error(mrIntl("ResponseAttachments.upload_file_eror_msg"));
      return;
    }
    
    if (props.onSubmit && stateUpdatedCount) {
      // setMediaFormSetting({
      //   ...mediaFormInitialState,
      //   loading: true,
      // });   
      props.onSubmit(
        state, 
        {
          submitAttachmentsStartCallback: () => {
            setMediaFormSetting({
              ...mediaFormSetting,
              loading: true,
            });
          },
          submitAttachmentsCompletedCallback: () => {
            setMediaFormSetting({
              ...mediaFormSetting,
              loading: false,
            });
            setStateUpdatedCount(0)
          }
        }
      );
    } else {
      closeModal(stateUpdatedCount);
    }
  };

  const handleRecover = () => {
    if (props.onRecoverUploads) {
      props.onRecoverUploads();
    }
  };

  const handleCancel = () => {
    closeModal(stateUpdatedCount);
  };

  const onTabDragEnd = (attachments) => {
    const newAttachments = attachments;

    const newAttachemntsJSON = {
      ...attachments_json,
      attachments: newAttachments,
    };
    saveState(newAttachemntsJSON);
  };

  const removeItem = (index) => {
    const extra = {
      noMinSize: true,
    };
    let newRemovedAttachments = [...removedAttachments, attachments[index]];
    let newAttachments = [...attachments];

    const removedAttachment = attachments[index];
    newAttachments = removeItemFromArray(newAttachments, index, extra);
    let newImages = images.filter((img) => img.id !== removedAttachment.id);

    const newAttachemntsJSON = {
      ...attachments_json,
      images: newImages,
      attachments: newAttachments,
      removedAttachments: newRemovedAttachments,
    };
    console.log("removeItem ==>", newAttachemntsJSON);

    const newAttachmentsCount = newAttachments.length;
    const isFilesUploaded = newAttachmentsCount ? true : false;

    if (answerMode) {
      setAttemptStatus({
        ...attemptStatus,
        files_uploaded: newAttachmentsCount,
        files_upload_attempted: isFilesUploaded,
      });
    }

    if (mediaFormSetting.previewCurrentFile && mediaFormSetting.previewCurrentFile.uid === removedAttachment.uid) {
      console.log("mediaFormSetting remove preview ==>", newImages[index] || newImages[0], removedAttachment, mediaFormSetting.previewCurrentFile);
      setMediaFormSetting((prevMediaFormSetting) => {
        return {
          ...prevMediaFormSetting,
          previewCurrentFile: 
            isQuestionMappingEnabled
              ? newImages[index] || newImages[0]
              : newAttachments[index] || newAttachments[0],
        }
      });
    }

    message.success(mrIntl("ResponseAttachments.delete_file_success_msg"));

    setUnsavedFiles(prevFiles => prevFiles.filter(({ uid }) => uid !== removedAttachment.uid));
    saveState(newAttachemntsJSON);
    console.log("removeItem ==>", newAttachemntsJSON, state);
  };

  const onSaveMedia = (files, extraFiles) => {
    const newAttachments = files;
    const newUnsavedFiles = []
    newAttachments.forEach((atc) => {
      const matchedImage = images.find((img) => img.id === atc.id);
      if (!matchedImage) {   
        newUnsavedFiles.push(atc);
      }
    });

    let updatedImages = [...images, ...newUnsavedFiles];

    const newAttachemntsJSON = {
      ...attachments_json,
      attachments: newAttachments,
      extraAttachments: extraFiles,
      images: updatedImages
    };

    const newAttachmentsCount = newAttachments.length;
    const isFilesUploaded = newAttachmentsCount ? true : false;

    if (answerMode) {
      setAttemptStatus({
        ...attemptStatus,
        files_uploaded: newAttachmentsCount,
        files_upload_attempted: isFilesUploaded,
      });
    }

    setMediaFormSetting((prevMediaFormSetting) => {
      return {
        ...prevMediaFormSetting,
        previewCurrentFile: updatedImages[updatedImages.length - 1],
      }
    });

    message.success(mrIntl("ResponseAttachments.add_file_success_msg"));
    setUnsavedFiles(prevFiles => [...prevFiles, ...newUnsavedFiles])
    saveState(newAttachemntsJSON);

    // setMediaFormSetting({
    //   ...mediaFormSetting,
    //   from: "",
    //   modal: false,
    //   visible: false,
    //   loading: false,
    //   triggerSaveAction: 0,
    // });
  };

  console.log("mediaFormSetting ==>", mediaFormSetting);

  const onRemoveMedia = (file) => {
    let newAttachments = attachments;
    const index = newAttachments.findIndex((atc) => atc.id === file.id);
    console.log("onRemoveMedia index ==>", newAttachments, index, file, mediaFormSetting.previewCurrentFile);
    if (index >= 0) {
      removeItem(index);
    }
  };
  
  const dispatch = useDispatch();
  const recoverAttachments = () => {
    dispatch(
      experienceUserActions.recoverAttachments(
        {
          experience_id: experienceUser.experience_id,
          user_id: experienceUser.user_id,
        },
        {
          success: { showMessage: true, message: mrIntl("CommonText.recovered_successfully") },
          successCallback: (response) => {
            console.log("response after upload =>", response);
            const finalExperienceUser = formatJsonApiData(
              response.data.experience_user
            );
            const updatedAttachmentsJSON = finalExperienceUser.attachments_json;

            const newExperienceUser = {
              ...experienceUser,
              attachments_json: updatedAttachmentsJSON,
              attachments_count: finalExperienceUser.attachments_count,
            };
           
            dispatch(
              experienceUserActions.updateSuccess(
                { data: { experience_user: newExperienceUser } },
                { success: { showMessage: false } }
              )
            );

            setState(updatedAttachmentsJSON)

            const updatedAttachments = [...updatedAttachmentsJSON.attachments];
            const updatedImages = [...updatedAttachmentsJSON.images];
            const updatedFileList = 
              isQuestionMappingEnabled 
                ? updatedImages
                : updatedAttachments

            setMediaFormSetting((prevMediaFormSetting) => {
              const updatedSettings = {
                ...prevMediaFormSetting,
                selectConfig: {
                  ...prevMediaFormSetting.selectConfig,
                  notDeletableFiles: updatedFileList,
                  defaultFileList: updatedFileList,
                  controlledFileList: updatedFileList,
                },
              };

              if (isQuestionMappingEnabled && updatedFileList.length > 0) {
                updatedSettings.previewCurrentFile = updatedFileList[updatedFileList.length - 1];
              }
              return updatedSettings;
            });
            console.log(
              "newExperienceUser ==>",
              newExperienceUser,
              finalExperienceUser,
              state
            );
          },
        }
      )
    );
  };

  const showRecoverFilesConfirm = () => {
    showConfirmModal({
      centered: true,
      title: mrIntl("ResponseAttachments.recovering_attachments"),
      content: mrIntl("ResponseAttachments.this_will_recover_all_possible_missing_deleted_files"),
      onOk: () => {
        recoverAttachments();
      },
      mrIntl: mrIntl
    });
  };

  const onMediaOrderChange = ({ source, destination }) => {
    const newAttachments = attachments;

    const draggableItem = newAttachments[source.index];
    newAttachments.splice(source.index, 1);
    newAttachments.splice(destination.index, 0, draggableItem);

    const newAttachemntsJSON = {
      ...attachments_json,
      attachments: newAttachments,
    };
    saveState(newAttachemntsJSON);
    return newAttachments;
  };

  const onTextChanged = (type, index, data) => {
    const newAttachments = attachments;
    newAttachments[index][type] = data;

    const newAttachemntsJSON = {
      ...attachments_json,
      attachments: newAttachments,
    };
    saveState(newAttachemntsJSON);
  };

  const onTabEdit = (index, action) => {
    if (action == "add") {
    } else if (action == "remove") {
      removeItem(index);
    }
  };

  let offlineAlert;
  if (internetStatus != "online") {
    offlineAlert = (
      <Alert
        message={mrIntl("ResponseAttachments.connect_internet_to_see_file_msg")}
        banner
        closable={false}
      />
    );
  }

  const handleMappingAttachments = (segmentIds, options) => {
    const { currentImage, selected, selectedSegmentId, selectedSegmentIndexPath, selectedSegmentTopicId, selectedSegmentTopicIndex } = options;
    let updatedImage = currentImage;

    // Update attachments with new segment information
    const newAttachments = attachments.map((attachment) =>
      attachment.id === currentImage.id
        ? { ...attachment, segment_id: segmentIds[0], segment_ids: segmentIds }
        : attachment
    );

    console.log("currentImage check ===>", currentImage, options);

    let newImages = images.slice();
    
    // Find the index of the current image in the newImages array using its unique identifier (uid)
    const currentIndex = newImages.findIndex((img) => img.uid === currentImage.uid);

    // Check if a question is selected to be mapped to an image
    if (selected) {
      if (currentIndex >= 0 && !newImages[currentIndex].segment_id) {
        // If the current image is not already mapped to a question (no segment_id), set the segment_id and other properties
        updatedImage = {
          ...updatedImage,
          segment_id: selectedSegmentId,
          segment_index_path: selectedSegmentIndexPath,
          topic_id: selectedSegmentTopicId,
          topic_index: selectedSegmentTopicIndex,
          upload_type: "QuestionWise",
        };
        newImages[currentIndex] = { ...newImages[currentIndex], ...updatedImage };
      } else {
        // If the current image is already mapped to a question, duplicate it with a new unique identifier and update properties
        updatedImage = {
          ...newImages[currentIndex],
          uid: getUniqueId(),
          segment_ids: segmentIds,
          segment_id: selectedSegmentId,
          segment_index_path: selectedSegmentIndexPath,
          topic_id: selectedSegmentTopicId,
          topic_index: selectedSegmentTopicIndex,
          upload_type: "QuestionWise",
        };
        newImages.push(updatedImage);
      }
    } else {
      // If a question is removed from the image
      const currentIndex = newImages.findIndex((img) => img.url === currentImage.url && img.segment_id === selectedSegmentId);
      const sameImages = newImages.filter((img) => img.url === currentImage.url);

      if (sameImages.length > 1) {
        // Remove the image only if there are multiple instances of it
        newImages.splice(currentIndex, 1);
        updatedImage = newImages[currentIndex]?.url === currentImage.url ? newImages[currentIndex] : newImages[currentIndex - 1];
      } else {
        // If only one instance exists, remove the segment_id and other details
        const imageToUpdate = newImages[currentIndex];
        delete imageToUpdate.segment_id;
        delete imageToUpdate.segment_index_path;
        delete imageToUpdate.topic_id;
        delete imageToUpdate.topic_index;
        updatedImage = imageToUpdate;
      }
    }

    // Updating segment_ids for each Image
    newImages = newImages.map((image) =>
      image.url === currentImage.url ? { ...image, segment_ids: segmentIds } : image
    );

    const newState = {
      ...attachments_json,
      images: sortBySegmentIndexPath(newImages),
      attachments: sortBySegmentIndexPath(newAttachments),
    };
    setUnsavedFiles(prevFiles => [...prevFiles, updatedImage])
    saveState(newState);    
    onPreview(updatedImage);
  };

  console.log("Attachments check ===>", {attachments, images});

  const getForm = () => {
    return (
      <Row className={`response-attachments-row m-t-10`}>
        <Col
          span={isQuestionMappingEnabled && !showDragger ? 8 : 24}
          className={"attachment-form-wrapper"}
        >
          {shouldShowImageCaptureUploadModal ? (
            <ImageCaptureUploadModal
              modal={true}
              video={true}
              attachments={attachments}
              mediaFormSetting={mediaFormSetting}
              setMediaFormSetting={setMediaFormSetting}
              //onSaveMedia={onSaveMedia} // now using mediaFormSetting.onChangeSaveFiles instead of `onSaveMedia` fn due to save/update attachement count with `segmentid` in `beforeSave` fn
              appType={appType}
            ></ImageCaptureUploadModal>
          ) : (
            <>
              <SelectFromComputer
                // computer={true}
                // url={true}
                mediaFormSetting={mediaFormSetting}
                setMediaFormSetting={setMediaFormSetting}
              />
              {mediaFormSetting.showSupportedTypes && !(mediaFormSetting.selectConfig.listType === "text") && (
                <p className="file-support-msg">
                  {mrIntl("CommonText.supported_types")}:
                  {mediaFormSetting.supportedFormats}
                </p>
              )}
            </>
          )}
          {/* To show the message only when atleast 1 file is in images */}
          {isQuestionMappingEnabled && images.length > 0 && <Space className="file-mapping-status">{autoMappingStatusMsg}</Space>}
        </Col>

        {isQuestionMappingEnabled && !showDragger && images.length > 0 && (
          <Col
            span={15}
            offset={1}
            className="map-attachment-to-question-selector-col"
          > {
            !isStudent && 
            <div className="map-attachments-to-question-selector-header">
              <MapAttachmentToQuestionSelector
                images={images}
                currentPreviewImage={mediaFormSetting.previewCurrentFile || {}}
                handleMappingAttachments={handleMappingAttachments}
                autoMappingProgress={autoMappingProgress}
                isAutoMappingStatusFinalized={isAutoMappingStatusFinalized}
              />
            </div>
          }
            {mediaFormSetting.previewCurrentFile && (
              <MediaShow file={mediaFormSetting.previewCurrentFile || {}} />
            )}
          </Col>
        )}
      </Row>
    );
  };

  const resourceFooter = (
    <Space style={{ justifyContent: "space-between", width: "100%" }}>
      <Space>
        <MrTranslate
          id={
            "ResponseAttachments.do_not_close_this_window_while_a_file_is_uploading"
          }
        />
      </Space>
      <Space>
        <Button
          key="close-response-attachment"
          onClick={(e) => handleCancel()}
          // loading={mediaFormSetting.loading}
        >
          <MrTranslate id={"CommonText.close"} />
          {/* </Button>,
        // props.onRecoverUploads &&
        // <Button
        //   key="recover-response-attachment"
        //   type="primary"
        //   onClick={(e) => handleRecover()}
        //   loading={mediaFormSetting.loading}
        //   // disabled={mediaFormSetting.okDisabled}
        // >
        //   Recover*/}
        </Button>
        <Button
          key="ok-response-attachment"
          type="primary"
          onClick={(e) => handleOk()}
          loading={mediaFormSetting.loading}
          disabled={mediaFormSetting.okDisabled}
        >
          <MrTranslate id={"CommonText.save"} />
        </Button>
      </Space>
    </Space>
  );
  
  const getShowView = () => {
    return (
      <div>
        <ThumbnailAttachments
          attachments={attachments}
          draggableProps={{
            isDragDisabled: true,
          }}
          listProps={{
            listType: "picture-card",
            showRemoveIcon: false,
            onPreview: onPreview,
          }}
        />

        <PreviewAttachment
          type={previewType}
          visible={mediaFormSetting.previewCurrentFile}
          file={mediaFormSetting.previewCurrentFile}
          files={attachments}
          onCancel={() =>
            setMediaFormSetting({
              ...mediaFormSetting,
              previewCurrentFile: null,
            })
          }
        />
      </div>
    );
  };

  // const downloadAllUploads = (e) => {
  //   console.log("downloadAllUploads ==>", e);
  // };

  const modalTitleToRender = (
    <div className="response-attachment-title">
      <MrTranslate id={"ResponseAttachments.attachments"}/> <Tag>{attachmentCount}</Tag>
      
      {isAdminOrTeacher && (
        <>
          <ReopenUploads disabled={disableUploader} />
          <Tooltip title={disableUploader ? mrIntl("ResponseAttachments.please_unpublish_the_result_to_recover_files_tooltip") : mrIntl("ExperienceUserItemDetail.recover_files")}>
            <Button
              className="recover-upload-file-btn"
              icon={<UndoOutlined />}
              onClick={showRecoverFilesConfirm}
              disabled={mediaFormSetting.selectConfig.disabled}
            />
          </Tooltip>
          {/* {(import.meta.env.VITE_MODE === "development" || import.meta.env.VITE_MODE === "staging" || enabledFeatures.auto_map_handwritten_answers_to_questions) && <AutoMapHandWrittenAnswers 
            disabled={disableUploader} 
            attachments_json={attachments_json} 
            onSubmit={props.onSubmit} 
            mediaFormSetting={mediaFormSetting}
            setMediaFormSetting={setMediaFormSetting}
            stateUpdatedCount={stateUpdatedCount}
            setStateUpdatedCount={setStateUpdatedCount}
          />} */}
        </>
      )}
      {/* <Tooltip title="Download all files">
        <Button onClick={downloadAllUploads} icon={<DownloadOutlined />} className="attachments-download-btn"/>
      </Tooltip> */}
      {/* Download API not done yet*/}
    </div>
  );

  const modalWidth = checkMob() ? "" : isQuestionMappingEnabled && hasUploads ? "70%" : "50%";

  let formToRender;
  if (modalVisible) {
    formToRender = (
      <Modal
        {...mediaFormSetting}
        open={modalVisible}
        width={modalWidth}
        centered={checkMob() ? true : false}
        className={`${checkMob() ? "height-centered-modal" : ""} ${isQuestionMappingEnabled && !showDragger ? "response-attachments-mapping-modal" : ""}`}
        closable={answerMode ? false : true}
        maskClosable={answerMode ? false : true}
        keyboard={false}
        title={modalTitleToRender}
        // onOk={handleOk}
        onCancel={answerMode ? null : handleCancel}
        footer={answerMode ? resourceFooter : null}
        loading={false}
      >
        {offlineAlert}
        {getForm()}
      </Modal>
    );
  } else {
    formToRender = getForm();
  }

  let showToRender;
  if (modalVisible && !answerMode) {
    showToRender = (
      <Modal
        open={modalVisible}
        width={checkMob() ? "" : "50%"}
        // centered={checkMob() ? true : false}
        centered={true}
        className={checkMob() ? "height-centered-modal" : ""}
        title={mrIntl("ResponseAttachments.attachments")}
        footer={null}
        onCancel={handleCancel}
      >
       {!attachments.length ? <NoData description={mrIntl("ResponseAttachments.no_file")} /> : getShowView()}
      </Modal>
    );
  } else {
    showToRender = getShowView();
  }

  console.log(
    "Response attachments before render ==>",
    state,
    attachments,
    mediaFormSetting
  );
  return (answerMode || isQuestionMappingEnabled) ? formToRender : showToRender;
};
export default ResponseAttachments;
