import React, { useEffect, useState } from "react";

import { RBDDroppableArea } from "/src/components/UI/DnD/RBDDroppableArea";
import { DnDDroppableTitle } from "/src/components/UI/DnD/DnDDroppableTitle";
import { DnDTabularTextBoxes } from "/src/components/UI/DnD/DnDTabularTextBoxes";
import { DnDInlineTextBoxes } from "/src/components/UI/DnD/DnDInlineTextBoxes";
import { DnDDistractors } from "/src/components/UI/DnD/DnDDistractors";
import { DragDropContext } from "react-beautiful-dnd";
import { updateOnDragEnd } from "/src/views/Segments/DnDHelpers";
import {
  getArrayOfList,
  addListToArray,
  addItemToArray,
  removeItemFromArray,
  getParsedJSONObject,
} from "/src/views/Segments/InteractiveHelpers";
import {
  Segment,
  SegmentData,
  HorizontalDroppableContainer,
  VerticalDroppableContainer,
  InlineDroppablesContainer,
  InlineDroppable,
} from "/src/components/UI/Segment/UIHelper";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { cloneDeep, shuffle } from "lodash";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import SegmentItemHeader from "/src/views/Segments/SegmentItemDetail/SegmentItemHeader";
import MatchForm from "./MatchForm";
import MatchGame, { convertToMatchGame } from "./MatchGame";
import MatchAnswer, {
  shuffleItems as answerShuffleItems,
  checkResponses as answerCheckResponses,
} from "./MatchAnswer";

const RBDInlineMatching = (props) => {
  console.log("RBDInlineMatch props", props);
  const mrIntl = useTranslate()
  const {
    question_type,
    game_type,
    showCorrectAnswerSwitch,
    segmentSettings = {},
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
    createMode,
    answerMode,
    gameMode,
    presentationMode,
    autoCheckMode,
    game_status,
  } = props;
  let { parsedJSONObject } = getParsedJSONObject(props);

  let textAnswerString = props.text_answer || "";
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  const propsGameStatus = props.game_status || {};

  let gameStatusObject = {
    score: propsGameStatus.score || 0,
    attempt_left:
      propsGameStatus.attempt_left || segmentSettings.max_attempt || 0,
    max_attempt: segmentSettings.max_attempt || 0,
    started: propsGameStatus.started || false,
  };

  const extra = {
    titeRequired: false,
    titlePrefix: "Match target",
    namePrefix: "Match target",
    placeholderPrefix: mrIntl("CommonText.match_target"),
    colSize: 0,
    itemConfig: {
      contentRequired: false,
      contentPrefix: "Match phrase",
      placeholderPrefix: mrIntl("CommonText.match_phrase"),
    },
  };
  if (createMode) {
    parsedJSONObject = {
      segment_data: {
        columns: getArrayOfList(2, 1, extra),
        possible_responses: {
          id: getUniqueId(),
          title: "Possible Responses",
          items: [],
        },
        distractors: [],
      },
      segment_version: "1.0.0",
    };
    if (question_type == "rbd_inline_matching") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }

  if (!props.response_json && gameMode) {
    // NOTE: In game mode using teacher_json which has answers too.
    parsedJSONObject = convertToMatchGame(parsedJSONObject);
  }

  if (answerMode) {
    parsedJSONObject = answerShuffleItems(parsedJSONObject);
  }

  if (autoCheckMode) {
    let question_json = cloneDeep(props.teacher_json);
    let answer_json = cloneDeep(parsedJSONObject);
    parsedJSONObject = answerCheckResponses(question_json, answer_json);
  }

  const [state, setState] = useState(parsedJSONObject);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [gameStatus, setGameStatus] = useState(gameStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
    showCorrectAnswerSwitchValue: false,
  });
  const [myAnswer, setMyAnswer] = useState(state);
  const screenSettings = props.screenSettings || {};

  let currentJSON, text_answer, attempt_status;
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    attempt_status = attemptStatus;
  }

  const segment_data = currentJSON?.segment_data || {};
  const columns = segment_data.columns || [];
  const possible_responses = segment_data.possible_responses || [];
  const distractors = segment_data.distractors || [];

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          game_status: cloneDeep(gameStatus),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    setState(state);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  console.log(
    "RBD Inline Matching State before render ==>",
    state,
    stateSetting.quickCheckModeEnabled
  );
  return (
    <div className="segment-matching-inline">
      {createMode ? (
        <MatchForm
          {...props}
          extra={extra}
          segment_data={segment_data}
          columns={columns}
          possible_responses={possible_responses}
          distractors={distractors}
          state={state}
          saveState={saveState}
        ></MatchForm>
      ) : gameMode ? (
        <MatchGame
          {...props}
          segment_data={segment_data}
          columns={columns}
          possible_responses={possible_responses}
          distractors={distractors}
          state={state}
          saveState={saveState}
          attemptStatus={attemptStatus}
          setAttemptStatus={setAttemptStatus}
          gameStatus={gameStatus}
          setGameStatus={setGameStatus}
        ></MatchGame>
      ) : (
        <MatchAnswer
          {...props}
          segment_data={segment_data}
          columns={columns}
          possible_responses={possible_responses}
          distractors={distractors}
          state={state}
          saveState={saveState}
          stateSetting={stateSetting}
          setStateSetting={setStateSetting}
          // shuffleItems={shuffleItems}
          // checkResponses={checkResponses}
          attemptStatus={attemptStatus}
          setAttemptStatus={setAttemptStatus}
        ></MatchAnswer>
      )}
    </div>
  );
};
export default RBDInlineMatching;
