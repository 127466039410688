import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import {
  actions as experienceActions,
} from "/src/views/Experiences/redux";
import { makeShowSaga } from "../saga";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "todayexperience",
  singleResourceName: "experience",
  apiUrl: "/experiences",
  actionNames: ["FETCH", "CREATE", "SHOW", "UPDATE"],
});

const initialState = fromJS({
  experiences: [],
  printExperience: {},
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

export const actions = reduxCrud.getActions();
const todayShowSaga = makeShowSaga(
  "todayexperiences",
  actions,
  experienceActions
);
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {});
export const watchTodayexperiences = reduxCrud.generateWatchSaga({
  [actionTypes.SHOW_TODAYEXPERIENCE]: todayShowSaga,
});

export default reduxCrud;
