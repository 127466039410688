import React from "react";
import { Route, useLocation, useRouteMatch } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
// import ExperienceGrade from "/src/views/Experiences/ExperienceShow/ExperienceManage/ExperienceGrade/ExperienceGradeOld";
import ExperiencePageHeader from "/src/views/Experiences/ExperienceShow/ExperiencePageHeader/ExperiencePageHeader";
import { checkMob } from "/src/lib/utils/helperMethods";
import ExperienceUsers from "/src/views/ExperienceUsers/ExperienceUsers";
import ExperienceGrade from "../../ExperienceManage/ExperienceGrade/ExperienceGrade";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ResultView = (props) => {
  console.log("ResultView props ==>", props);
  const { experienceProps } = props;
  const {
    experience,
    embedded,
    experienceViewMode,
    fromResourceName,
    fromUrl
  } = experienceProps;

  const isMobile = checkMob();
  const mrIntl = useTranslate();

  const { path } = useRouteMatch();
  let params = { by_experience_id: experience.id };
  const finalRender = [];
  const extra = [];
  const location = useLocation();
  const mbGradeBookEmbeddedView =
    embedded &&
    experience.is_mb_linked &&
    location.pathname.indexOf("grade") > -1
      ? true
      : false;
  // subTitle = `${item.questions_count} questions - ${parseFloat(item.points)} pts`

  // FOR  NOW LIMITING to 1 ATTEMPT BUT WILL NEED THIS LATER - ALL WORKING
  // subTitle = `${item.questions_count} questions - ${parseFloat(item.points)} points - Remaining attempts: ${item.settings.attempts_no - item.attempts}`
  // setExperienceViewMode("apStudentFeedback")
  // {item.attempts < item.settings.attempts_no && extra.push(
  //   <Button type="primary" onClick={(e) => {
  //     // setExperienceViewMode("apTakeTest")
  //     setUserInfo({currentView: "", group_id: 2, name: currentUser.name, online: true, s_code: currentUser.s_code, time_remaining: convertMs(item.settings.duration)});
  //     // resetUserResponses too
  //     // console.log()
  //   }
  //   }>Try again</Button>
  // )}
  // FOR  NOW LIMITING to 1 ATTEMPT
  finalRender.push(
    <ExperiencePageHeader
      experience={experience}
      experienceViewMode={experienceViewMode}
      embedded={embedded}
      fromResourceName={fromResourceName}
      fromUrl={fromUrl}
      pageHeaderProps={{
        className: `site-page-header-responsive experience-header ${
          isMobile ? "mobile" : ""
        }`,
        backIcon: !embedded && (
          <Tooltip title= {mrIntl("CommonText.back")} placement="bottom">
            <Button shape="circle" icon={<ArrowLeftOutlined />} />
          </Tooltip>
        ),
      }}
    ></ExperiencePageHeader>
  );

  // finalRender.push(
  //   <Route
  //     path={`${path}`}
  //     render={(routeProps) => (
  //       <ExperienceGrade
  //         isRoute={false}
  //         experienceProps={{
  //           ...experienceProps,
  //           fromResourceName: fromResourceName,
  //         }}
  //         isGradingEnabled={false}
  //         {...routeProps}
  //       />
  //     )}
  //   />
  // );
  
  finalRender.push(
    <Route
      path={`${path}`}
      render={(routeProps) => (
        <ExperienceGrade
          experienceProps={{
            ...experienceProps,
          }}
          isRoute={false}
          isGradingEnabled={false}
          mbGradeBookEmbeddedView={mbGradeBookEmbeddedView}
          {...routeProps}
        />
      )}
    />
  )
  return finalRender;
};

export default ResultView;
