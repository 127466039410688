import { createSelector } from "reselect";
// import lodash from "lodash";

// const loadingState = (state, props) => {
//   return state.groups.get("loading");
// };
// export const loadingSelector = () =>
//   createSelector([loadingState], (loading) => loading);

// const errorState = (state, props) => state.groups.get("error");

// export const errorSelector = () =>
//   createSelector([errorState], (error) => error);

const questionbanksState = (state, props) =>
  state.questionbanks.get("questionbanks");

export const questionbanksSelector = () =>
  createSelector([questionbanksState], (questionbanks) => questionbanks);

const orgquestionbanksState = (state, props) =>
  state.request.get("orgquestionbanks");

export const orgquestionbanksSelector = () =>
  createSelector([orgquestionbanksState], (orgquestionbanks) => orgquestionbanks);

const questionbanksFormRequestState = (state, props) =>
  state.request.get("questionbanks") || [];

export const questionbanksFormRequestSelector = () =>
  createSelector([questionbanksFormRequestState], (questionbanks) => questionbanks);

// const groupsShowState = (state, props) => {

//   const groups = state.groups.get("groups");

//   if (groups.size === 0) {
//     return {};
//   } else {
//     const id = parseInt(props.match.params.id);
//     const idx = lodash.findIndex(groups, {
//       id: id,
//     });
//     if (idx !== -1) {
//       return groups[idx];
//     }
//     return {};
//   }
// };

// export const groupsShowSelector = () =>
//   createSelector([groupsShowState], (groups) => groups);

// const paginationState = (state, props) => {
//   const pageObj = {};
//   pageObj.page = state.groups.get("page");
//   pageObj.totalPages = state.groups.get("total_pages");

//   return pageObj;
// };

// export const paginationSelector = () =>
//   createSelector([paginationState], (pageObj) => pageObj);

// export const usersState = (state, props) => {
//     return state.users.get("users");
// };