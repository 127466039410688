import React from "react";
import { List, Space } from "antd";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";

const AppConstantsList = ({ dataSource, currentUser, deleted, permission }) => {
  console.log("permission==>", dataSource);
  const Title = ({ item }) =>
    (item.text_value &&
      `${item.text_value.charAt(0).toUpperCase()}${item.text_value.slice(
        1
      )}`) ||
    `${item.key.charAt(0).toUpperCase()}${item.key.slice(1)}`;
  const Description = ({ item }) => (
    <Space className="description" key={item.id}>
      {item.desc}
    </Space>
  );
  return (
    <>
      <List
        // header={listHeader}
        className="custom-main-list"
        // loading={initLoading}
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(item, i) => (
          <CustomListItem
            // type="app_constants_list"
            item={item}
            currentUser={currentUser}
            edit={
              currentUser.role === "superadmin" ||
              currentUser.role === "support"
            }
            deleted={deleted}
            deletedAllow={false}
            show={
              currentUser.role === "superadmin" ||
              currentUser.role === "support"
            }
            title={<Title item={item} />}
            description={<Description item={item} />}
            // extraInfo={<ExtraInfo item={item} />}
            // avatar={<ProfileIcon item={item} />}
            // extraActions={<Actions item={item} currentUser={currentUser} />}
            permission={permission}
            // showForm={props.showForm}
            // setUserToSetPassword={setUserToSetPassword}
          />
        )}
      />
    </>
  );
};

export default AppConstantsList;
