

import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { convertMs, timeInMiliSeconds } from "/src/lib/utils/helperMethods";
import * as Sentry from '@sentry/react';
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useSelector } from 'react-redux';
import { currentSessionIdSelector } from '../FirestoreInteractions/selector';
import { getOrCreateSessionId } from '../FirestoreInteractions/redux';

const EndButton = (props) => {
  // console.log("EndButton props ==>", props);
  const { 
    isMobile,
    experienceViewMode,
    userInfoFromReduxstartedAt,
    experienceSettings,
    userInfoFromReduxextraTimeOffset,
    userInfoFromReduxextraTime,
    experienceDuration,
    setSwitchFromStartToResumeView,
    setUserInfo,
  } = props;

  const mrIntl = useTranslate();

  const [endTestButtonLoading, setEndTestButtonLoading] = useState(false);
  // const currentSessionIdFromRedux = useSelector(currentSessionIdSelector);
  const currentSessionIdFromSessionStorage = getOrCreateSessionId();

  return (
    <Tooltip
      key="end-test-btn"
      title={
        experienceViewMode == "apPreviewTest"
          ? mrIntl("ExperienceTake.disabled_in_preview")
          : mrIntl("ExperienceTake.end_test")
      }
      placement="bottom"
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <Button
        type="primary"
        danger
        className="end-test-button"
        loading={endTestButtonLoading}
        icon={isMobile ? <SendOutlined /> : ""}
        onClick={() => {
          setEndTestButtonLoading(true);
          setSwitchFromStartToResumeView(false);

          let remainingTime = "";
          if (experienceSettings.is_timed) {
            const studentStartedAt = userInfoFromReduxstartedAt == undefined ? Date.now() : userInfoFromReduxstartedAt;

            try {
              const calculateRemainingTimeInMs = studentStartedAt + userInfoFromReduxextraTimeOffset + userInfoFromReduxextraTime + convertMs(experienceDuration) - Date.now();

              remainingTime = timeInMiliSeconds(calculateRemainingTimeInMs);
            } catch (error) {
              console.log("error in calculateRemainingTimeInMs", error);
              Sentry.captureException(error);
            }
          }

          setUserInfo(
            {
              currentView: "endTest",
              ended_at: Date.now(),
              session_id: currentSessionIdFromSessionStorage
            },
            {
              log: {
                logging: true,
                action: "test_ended_by_student",
                current_value: {
                  remaining_time: remainingTime,
                },
              },
              successCallback: () => {
                setEndTestButtonLoading(false);
              },
              errorCallback: () => {
                setEndTestButtonLoading(false);
              },
            }
          );
          // Worst case scenario - when setUserInfo failing/no change of currentView for some reason, still remove loading so student can try again
          setTimeout(() => {
            setEndTestButtonLoading(false);
          }, 5000);
        }}
        disabled={experienceViewMode == "apPreviewTest"}
      >
        {isMobile ? "" : <MrTranslate id={"ExperienceTake.end_test"} />}
      </Button>
    </Tooltip>
  );
};

export default EndButton;
