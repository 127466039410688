import React from "react";
import "./MarksLegend.scss";
import { Tooltip } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";



const MarksLegend = (props) => {
  let rubricType = props.rubricType
  let hideGradedTile = rubricType !== "comments_only" && rubricType !== "myp_achievement_level" ? true : false
  let hidePerformanceTiles = rubricType === "comments_only" ? true : false
  const mrIntl = useTranslate()

  const legendRows = [
    {
      label: mrIntl("MarksLegend.unattempted"),
      // info: "Indicates a question that the student did not attempt during the assessment.",
      info: mrIntl("MarksLegend.question_that_the_student_did_not_attempt"),
      className: "color-box-unattempted",
      type: "unattempted"
    },
    {
      label: mrIntl("MarksLegend.ungraded"),
      info: mrIntl("MarksLegend.assessment_has_not_been_graded_yet"),
      className: "color-box-ungraded",
      type: "ungraded"
    },
    {
      label: mrIntl("MarksLegend.graded"),
      // info: "Indicates that a specific question has been graded.",
      info: mrIntl("MarksLegend.specific_question_has_been_graded"),
      className: "color-box-blue",
      type: "graded"
    },
    {
      label: "0-25",
      // info: "Indicates a score between 0-25% for either a question or the overall assessment.",
      info: mrIntl("MarksLegend.indicates_a_score_between_0_25"),
      className: "color-box-red",
      type: "performance"
    },
    {
      label: "25-50",
      info: mrIntl("MarksLegend.indicates_a_score_between_25_50"),
      className: "color-box-orange",
      type: "performance"
    },
    {
      label: "50-75",
      info: mrIntl("MarksLegend.indicates_a_score_between_50_75"),
      className: "color-box-yellow",
      type: "performance"
    },
    {
      label: "75-100",
      info: mrIntl("MarksLegend.indicates_a_score_between_75_100"),
      className: "color-box-green",
      type: "performance"
    },
  ];

  let filteredRows = legendRows
  if(hideGradedTile){
    filteredRows = legendRows.filter(row => row.type !== "graded")
  }
  if(hidePerformanceTiles){
    filteredRows = legendRows.filter(row => row.type !== "performance")
  }
  
  return (
    <div className="experience-grading-legend">
      <div className="legend">
        {filteredRows.map(({ label, info, className, type }) => (
          <Tooltip title={info}>
            <div className="legend-row" key={label}>
              {type === "unattempted" ? 
                <div className={"unattempted-flag"}>
                  <CloseCircleFilled />
                </div>
                :
                <div className={`color-box ${className}`}></div>
              }
              <div className="legend-label">{label}</div>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default MarksLegend;