import React from 'react';
import { Button, message, Result } from "antd";
import { appTypeSelector } from '/src/App/OfflineApp/offlineAppSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { appRegionSelector, enabledFeaturesSelector } from '/src/views/Auth/Login/selector';
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux"
import QuitBtn from '../QuitBtn';
import "./UnsupportedVersionNotice.scss"
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { CheckAndSetAppType } from '/src/App/OfflineApp/CheckAndSetAppType';
import { browserUnsupported, checkAutoUpdateSupported, getAppVersion, getDeviceInfo, getOfflineAppDownloadUrl, isNewAppVersion } from '/src/App/OfflineApp/offlineAppHelper';

const UnsupportedVersionNotice = () => {
  const dispatch = useDispatch()
  const mrIntl = useTranslate();
  const appType = useSelector(appTypeSelector());
  const appRegion = useSelector(appRegionSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const currentAppVersion = getAppVersion(appType)
  const deviceInfo = getDeviceInfo(appType);
  const isBrowserUnsupported = browserUnsupported(appType, deviceInfo);
  const appTypeMessages = {
    ios: mrIntl("UnsupportedVersionNotice.you_are_using_an_unsupported_version_of_the_application"),
    seb: mrIntl("UnsupportedVersionNotice.this_version_of_the_assessprep_lockdown_app_seb_is_no_longer_supporetd"),
    mac: isNewAppVersion(currentAppVersion, "9.0.0") ? mrIntl("UnsupportedVersionNotice.you_are_using_an_unsupported_version_of_the_application_please_update_the_latest") : mrIntl("UnsupportedVersionNotice.this_version_of_the_assessprep_lockdown_app_is_no_longer_supported_download")
  }

  let unsupportedAppVersionNoticeMessage =
    isBrowserUnsupported
      ? mrIntl("UnsupportedVersionNotice.this_version_of_the_web_browser_is_no_longer_supported")
      : appTypeMessages[appType];

  const handleClick = () => {
    if (appType === "mac") {
      const isEnableAutoUpdateInMacApp = enabledFeatures.allow_auto_update_mac_app && appType === "mac";
      // Mac app v11.0.0 we have started auto update feature APL-4381 
      if (isEnableAutoUpdateInMacApp && checkAutoUpdateSupported(appType)) {
        // dispatch the startUpdate action if auto update flag enable
        const appUpdateType = "manual";
        //TODO : update update type logic //getAutoUpdateType(appType, enabledFeatures);
        dispatch(
          offlineAppActions.executeMethod(
            [
              {
                key: "startUpdate",//startUpdate
                value: appUpdateType,
                errorCallback: () => {
                  // download the dmg file to open in browser 
                  dispatchToDownloadNativeMacApp()
                },
              },
            ],
            {
              errorCallback: () => {
                // download the dmg file to open in browse
                dispatchToDownloadNativeMacApp()
              },
            }
          )
        );
      } else {
        dispatchToDownloadNativeMacApp()
      }
    }
  }

  function dispatchToDownloadNativeMacApp() {
    dispatch(
      offlineAppActions.executeMethod([
        {
          key: "openLinkInBrowser", // allow open url in new browser for earlier version (existing functionality) for mac app
          value: getOfflineAppDownloadUrl(appType, appRegion, enabledFeatures),
        },
      ])
    );
  }

  return (
    <div className='unsupported-app'>
      {/* after refshing this page checkAndSetApp component is not loaded , apptype is undefined that's why i am using this component */}
      <CheckAndSetAppType />
      <Result
        status="500"
        title={mrIntl("UnsupportedVersionNotice.update_required")}
        subTitle={unsupportedAppVersionNoticeMessage}
        extra={
          <>
            <QuitBtn />
            {(appType === "mac") ? (
              isNewAppVersion(currentAppVersion, "9.0.0")
                ?
                <Button
                  type="primary"
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {mrIntl("UnsupportedVersionNotice.update_app")}
                </Button>
                : null
            ) : null}
          </>
        }
      />
    </div>
  );
};

export default UnsupportedVersionNotice;