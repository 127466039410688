import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { put } from "redux-saga/effects";
import { message } from "/src/components/UI/AntdAppHelper";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "group",
  actionNames: ["FETCH", "CREATE", "SHOW", "UPDATE", "DELETE", "BULK_DELETE", "FETCH_BY_UUIDS"],
});

const initialState = fromJS({
  groups: [],
  groups_by_source_uuids: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

export function* bulkDeleteSaga(action) {
  yield put(actions.bulkDeleteStart());
  let url = "groups/bulk_delete.json";
  let params = action.payload.paramsToUse;
  console.log("BulkActions bulkDeleteSaga calling", action);
  try {
    const response = yield axiosInstance.instance.post(url, params);
    console.log("BulkActions bulkDeleteSaga response", response);
    if (response && response.status === 200) {
      message.success("Bulk archive successful");
      yield put(
        // actions.fetchSuccess({data: {users: []}}) // to set empty list
        actions.fetch({params: params}) // to reload list
      );
      yield put(actions.bulkDeleteSuccess());
      if(action.options.successCallback){
        action.options.successCallback()
      }
    }
  } catch (error) {
    console.error("bulkDeleteSaga error", error, error.response);
    let errorMessageToshow = "Something went wrong in bulk archive";
    if(error.response && error.response.data && error.response.data.error){
      errorMessageToshow = error.response.data.error
    }
    // message.error("Something went wrong in bulk archive");
    yield put(actions.bulkDeleteFail({ error: errorMessageToshow }));
    if(action.options.errorCallback){
      action.options.errorCallback()
    }
  }
}

export function* fetchByUuidsSaga(action) {
  yield put(actions.fetchByUuidsStart());
  let url = "groups.json";
  let params = action.payload;
  console.log("BulkActions fetchByUuidsSaga calling", action);
  try {
    const response = yield axiosInstance.instance.get(url, {params: params});
    console.log("BulkActions fetchByUuidsSaga response", response);
    if (response && response.status === 200) {
      yield put(actions.fetchByUuidsSuccess(response.data));
      if(action.options.successCallback){
        action.options.successCallback()
      }
    }
  } catch (error) {
    console.error("fetchByUuidsSaga error", error, error.response);
    let errorMessageToshow = "Something went wrong in fetch by uuids";
    if(error.response && error.response.data && error.response.data.error){
      errorMessageToshow = error.response.data.error
    }
    // message.error("Something went wrong in fetch by uuids");
    yield put(actions.fetchByUuidsFail({ error: errorMessageToshow }));
    if(action.options.errorCallback){
      action.options.errorCallback()
    }
  }
}

function fetchByUuidsSuccess(state, action) {
  return state.set("groups_by_source_uuids", fromJS(action.payload.groups.data)).set("loading", false).set("fetchloading", false);
}

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  FETCH_BY_UUIDS_GROUP_SUCCESS: fetchByUuidsSuccess,
});
export const watchGroups = reduxCrud.generateWatchSaga({
  [actionTypes.BULK_DELETE_GROUP]: bulkDeleteSaga,
  [actionTypes.FETCH_BY_UUIDS_GROUP]: fetchByUuidsSaga,
});

export default reduxCrud;
