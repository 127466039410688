import { Button, Dropdown, Input, Modal, Radio, Row, Space, Spin, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { aiModelSelector } from "/src/views/Orgs/selector";
import { actions } from "/src/views/Orgs/redux";
import MagicButton from "./MagicButton";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import ApErrorBoundary from "/src/components/ApErrorBoundary/ApErrorBoundary";
import {
  fetchWithStream,
  getAIServiceBaseURL,
  getFromLS,
  renderMathInElement,
  writeToClipboardPolyfill,
  availableLanguageOptionsForAI,
} from "/src/lib/utils/helperMethods";
import { ImMagicWand } from "react-icons/im";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import "./WritingAssistant.scss";
import TypingEffect from "/src/views/Experiences/ExperienceShow/Components/TypingEffect/TypingEffect";
import { FaRegStopCircle } from "react-icons/fa";
import DetectOutsideClick from "/src/lib/DetectOutsideClick/DetectOutsideClick";
import { LoadingOutlined, CopyOutlined } from "@ant-design/icons";
import { isAIAssistantDisabledSelector } from "/src/views/Orgs/selector";

const WritingAssistant = (props) => {
  console.log("WritingAssistant ====>", props);
  const { config, editor, isEditorReady } = props;

  // const currentEditor = editor.current;
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const generatedOutputRef = useRef(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [streamData, setStreamData] = useState({
    showAIText: false,
    content: null,
    responseCompleted: false,
  });
  const [editorContent, setEditorContent] = useState();
  const [selectedContent, setSelectedContent] = useState();
  const [isAssistantActive, setIsAssistantActive] = useState(false);
  const [showCommands, setShowCommands] = useState(false);
  const [activeCommand, setActiveCommand] = useState();
  const [showCommandsDropdown, setShowCommandsDropdown] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [generatedOutput, setGeneratedOutput] = useState(false);
  const [inputHistory, setInputHistory] = useState(null)
  const [isDropdownItemFocused, setIsDropdownItemFocused] = useState(false);
  const [openSubmenuCount, setOpenSubmenuCount] = useState(0)
  const aiModel = useSelector(aiModelSelector());
  const sseRef = useRef(null);
  const maxCharacterLimit = 100; //TODO: Set the actual character limit
  
  const isAIAssistantDisabled = useSelector(isAIAssistantDisabledSelector())
  
  const enableShortcuts = !isAIAssistantDisabled.disabled;
  let showError = inputValue && inputValue.length > maxCharacterLimit;
  let dropdownOverlayClass = "writing-assistant-commands-dropdown-overlay"
  let dropdownContainerClass = "dropdown-container";

  useEffect(() => {
    if (!isAssistantActive && editor.current) {
      editor.current && editor.current.disableReadOnlyMode('editor');
      editor.current.focus();
      stopStreaming();
      setIsDropdownItemFocused(false);

      if (showOutput) {
        setShowOutput(false);
      }

      if (selectedContent) {
        removeHighlight();
        setSelectedContent();
      }
    }
  }, [isAssistantActive]);
  
  useEffect(() => {
    if (enableShortcuts) {
      let writingAssistantContainer = getClosestContainer();
      const dropdownElement = document.querySelector(`.${dropdownContainerClass}`);
      if (writingAssistantContainer) {
        writingAssistantContainer.addEventListener("keydown", handleKeyDown, {
          capture: true,
        });
        if (isDropdownItemFocused && dropdownElement) {
          dropdownElement.addEventListener("keydown", handleKeyDown, {
            capture: true,
          });
        }
        return () => {
          writingAssistantContainer.removeEventListener(
            "keydown",
            handleKeyDown,
            { capture: true }
          );
          if (dropdownElement) {
            dropdownElement.removeEventListener("keydown", handleKeyDown, {
              capture: true,
            });
          }
        };
      }
    }
  }, [isEditorReady, isAssistantActive, isDropdownItemFocused, openSubmenuCount]);

  useEffect(() => {
    if (isAssistantActive && showCommands) {
      inputRef.current.focus({
        cursor: "start",
      });
    }
  }, [isAssistantActive, showCommands]);

  const getClosestContainer = () => {
    try {
      if (editor.current) {
        return editor.current.sourceElement.closest(".show-placeholder");
      }
    } catch (error) {
      console.log("error in getting closest container", error);
    }
  };

  const handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "/") {
      event.preventDefault();
      toggleFocus();
    }
    if (inputRef.current) {
      if(event.key === "Escape"){
        toggleFocus();
      }
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        event.preventDefault();
        let dropdownOverlay = document.querySelector(`.${dropdownContainerClass}`);
        if (openSubmenuCount) {
          dropdownOverlay = document.querySelector(`.${dropdownOverlayClass}`);
        }
        if (dropdownOverlay) {
          let menuItems = dropdownOverlay.querySelectorAll(
            ".ant-dropdown-menu-item, .ant-dropdown-menu-submenu"
          );
          if (menuItems.length > 0) {
            let activeIndex = Array.from(menuItems).findIndex((item) =>
              item.classList.contains("ant-dropdown-menu-item-active") ||
              item.classList.contains("ant-dropdown-menu-submenu-active")
            );
            if (activeIndex < 0) {
              if (event.key === "ArrowDown") {
                setIsDropdownItemFocused(true)
                activeIndex = 0;
              } else if (event.key === "ArrowUp") {
                setIsDropdownItemFocused(true)
                activeIndex = menuItems.length - 1;
              }
            }
            let subMenuItemTitle = menuItems[activeIndex].querySelector(
              ".ant-dropdown-menu-submenu-title"
            );
            if (subMenuItemTitle) {
              subMenuItemTitle.focus();
            } else {
              menuItems[activeIndex].focus();
            }
          }
        }
      }
    }
  };

  const toggleFocus = () => {
    if (
      editor &&
      editor.current &&
      editor.current.editing.view.document.isFocused
    ) {
      handleMagicButton();
    } else if (isAssistantActive && editor && editor.current) {
      setIsAssistantActive(false);
    }
  };

  const replaceContent = (editor, replacementContent) => {
    var currentContent = editor.getData();
    setInputHistory(currentContent);
    let replaceAll = selectedContent ? false : true;

    if (replacementContent) {
      if (replaceAll) {
        editor.setData(replacementContent);
      } else {
        modifySelection(editor, replacementContent, true);
      }
    }
    setIsAssistantActive(false);
    setShowOutput(false);
    editor.current && editor.current.disableReadOnlyMode('editor');
  };

  const modifySelection = (editor, content, replace = false) => {
    const selection = editor.model.document.selection;
    if (selection) {
      // Get the selected range
      const selectedRange = selection.getFirstRange();
      if (selectedRange) {
        const fragment = editor.data.parse(content);
        console.log("selectedRange =====>", { selectedRange, content });
        editor.model.change((writer) => {
          editor.model.insertContent(fragment, selectedRange.end);
          if (replace) {
            writer.remove(selectedRange);
          } else {
            const space = writer.createText(" ");
            editor.model.insertContent(space, selectedRange.end);
          }
        });
      } else {
        console.error("No selection range available.");
      }
    } else {
      console.error("No selection available.");
    }
  };

  const insertContent = (editor, content, insertToRight = false) => {
    const currentData = editor.getData();
    setInputHistory(currentData);
    if (insertToRight) {
      if (selectedContent) {
        modifySelection(editor, content, false);
      } else {
        console.log("currentData ====>", currentData);
        const newData = currentData + " " + content;
        if (newData) {
          editor.setData(newData);
        }
      }
    } else {
      if (content) {
        editor.setData(content);
      }
    }
    setIsAssistantActive(false);
    setShowOutput(false);
    editor.current && editor.current.disableReadOnlyMode('editor');
  };

  const undoContent = (editor) => {
    editor.setData(inputHistory);
    setInputHistory(null);
    removeHighlight();
  };

  function getSelectedContent(editor) {
    const model = editor.model;
    const selection = model.document.selection;
    console.log("model =====>", model, selection);
    // Check if there's a selection
    if (!selection) {
      console.warn("No content is selected.");
      return null;
    }

    // Get the selected content
    const content = [];

    // for (const range of selection.getRanges()) {
    //   const startPos = range.start;
    //   const endPos = range.end;

    //   // Get the text between the start and end positions
    //   const textBetweenSelection = model.document.getRange(startPos, endPos).getSelectedContent();

    //   // Push the selected text into the array
    //   selectedContent.push(textBetweenSelection);
    // }

    for (const range of selection.getRanges()) {
      const selectedText = [];
      for (const item of range.getItems()) {
        selectedText.push(item.data);
      }
      console.log("selectedText ==>", selectedText);
      content.push(selectedText.join(""));
    }

    setSelectedContent(content[0]);
    if (content[0]) {
      editor.execute("highlight", { value: "yellowMarker" });
    }
    setEditorContent(editor.getData());
    // return content[0];
  }

  const removeHighlight = () => {
    var content = editor.current.getData();

    // Remove <mark> tags while keeping their content
    content = content.replace(
      /<mark class="marker-yellow">(.*?)<\/mark>/g,
      "$1"
    );

    // Set the modified content back to the editor
    editor.current.setData(content);
  };

  const stopStreaming = () => {
    setStreamData((prevData) => ({
      ...prevData,
      isStreaming: false,
    }));
    setShowOutput(false);
    if (loading) {
      setLoading(false);
    }
    console.log("stopStreaming ====>", sseRef.current);
    // setIsStreamStarted(false);
    if (sseRef && sseRef.current) {
      sseRef.current.abortSse();
    }
  };

  const handleMagicButton = () => {
    if (streamData.isStreaming) {
      stopStreaming();
    } else {
      getSelectedContent(editor.current);
      setIsAssistantActive(!isAssistantActive);
      setShowCommands(true);
      setInputHistory(null); // removing undo button on generating
      // editorContent not yet set
      setInputValue(null)
      const hasContent = editor.current.getData() !== "";
      setShowCommandsDropdown(hasContent);
      setShowOutput(false);
      editor.current && editor.current.enableReadOnlyMode('editor')
      setStreamData({
        showAIText: false,
        content: null,
      });
    }
  };

  const processCommand = async (command, promptText = "") => {
    console.log("processCommand =====>", command, promptText);
    setActiveCommand(command);
    setShowOutput(true);

    if (command === "write") {
      setPrompt(promptText);
    } else {
      setPrompt(null);
      setShowCommands(false);
    }

    const token = getFromLS("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let data = {
      command: command,
      text: (command === "write" && streamData.content) || selectedContent || editorContent, // priority order - using streamData.content to allow further conversation in case of write
    };
    if (promptText) {
      data.prompt = promptText;
    }
    if (aiModel) {
      data.model = aiModel;
    }
    try {
      setLoading(true);

      // TODO: math render
      // setTimeout(() => {
      //   console.log("Rendering math");
      //   renderMathInElement(generatedOutputRef.current, true);
      // }, 100);

      const fetchOptions = {
        url: `${getAIServiceBaseURL()}/process-text`,
        dataToPost: data,
        setLoading: setLoading,
        content: "",
        sseRef: sseRef,
        removeVerticalCursor: true,
        successCallback: (data, responseStatus) => {
          setStreamData((prevData) => ({
            showAIText: true,
            content: data,
            isStreaming: true,
            responseCompleted: responseStatus === "stop",
          }));
          // if (responseStatus === "stop") {
          // }
        },
        // setTriggerCKEDataUpdate: setTriggerCKEDataUpdate
      };
      fetchWithStream(fetchOptions);      
      setInputValue(null);
    } catch (error) {
      console.error(error);
      message.error("Something went wrong in processing request")
      setLoading(false);
      setShowOutput(false);
    }
  };

  const availableCommands = {
    write: {
      label: "Write",
      processing_label: "Writing",
      hide_in_menu: true,
    },
    shorter: {
      label: "Make shorter",
      processing_label: "Shortening",
    },
    longer: {
      label: "Make longer",
      processing_label: "Lengthening",
    },
    fix_spelling_grammar: {
      label: "Fix spelling & grammar",
      processing_label: "Fixing",
    },
    explain: {
      label: "Explain",
      processing_label: "Explaining",
      // icon: <ImMagicWand />,
      // disabled: true
    },
    simplify: {
      label: "Simplify language",
      processing_label: "Simplifying",
    },
    translate_to: {
      parent_label: "Translate to",
      children: availableLanguageOptionsForAI.map(language => ({
        key: `translate_to_${language.toLowerCase()}`, 
        label: language,
        processing_label: "Translating"
      }))
    },
    change_tone_professional: {
      label: "Professional",
      processing_label: "Changing tone to professional",
      parent_label: "Change tone",
    },
    change_tone_conversational: {
      label: "Conversational",
      processing_label: "Changing tone to conversational",
      parent_label: "Change tone",
    },
    change_tone_friendly: {
      label: "Friendly",
      processing_label: "Changing tone to friendly",
      parent_label: "Change tone",
    },
    change_tone_funny: {
      label: "Funny",
      processing_label: "Changing tone to funny",
      parent_label: "Change tone",
    },
  };

  let commands = [];
  Object.keys(availableCommands).forEach((key, i) => {
    let command = availableCommands[key];
    console.log("availableCommands", key, command);
    if (!command) {
      return;
    }
    if (command.hide_in_menu) {
      return;
    }
    let objectToPush = {
      key: key,
      label: command.label,
    };
    if (command.children) {
      commands.push({
        popupClassName: `${dropdownOverlayClass} writing-assistant-submenu-popup`,
        label: command.parent_label,
        children: command.children,
      });
      return;
    }
    if (command.parent_label) {
      let index = commands.findIndex(
        (item) => item.label == command.parent_label
      );
      if (index !== -1) {
        commands[index].children.push(objectToPush);
      } else {
        commands.push({
          popupClassName: `${dropdownOverlayClass} writing-assistant-submenu-popup`,
          label: command.parent_label,
          children: [objectToPush],
        });
      }
    } else {
      commands.push(objectToPush);
    }
  });
  console.log("commandsToShow", commands);

  // TODO get error boundary to work
  // <ApErrorBoundary
  //   fallback={`Error inside Writing Assistant. Please try again.`}
  // >
  // </ApErrorBoundary>

  return (
    <DetectOutsideClick
      detectOutside={true}
      targetExceptionClassname={[dropdownOverlayClass]}
      callback={() => {
        setIsAssistantActive(false);
      }}
    >
      <Row
        className={`writing-assistant-container-row text-left ${
          isAssistantActive ? "active" : ""
        }`}
      >
        <Space
          direction="vertical"
          className="writing-assistant-container-space"
          size={4}
        >
          {showOutput && (
            <Space direction="vertical" size={6} style={{width: "100%"}} ref={generatedOutputRef}>
              {loading && <span>
                <LoadingOutlined spin />
                {/* Command:
                {availableCommands[activeCommand] &&
                  availableCommands[activeCommand].label}
                | Prompt: {prompt} */}
              </span>}
              {streamData.showAIText && streamData.isStreaming ? (
                <TypingEffect
                  text={streamData.content}
                  typingSpeed={10}
                  isStreamStarted={streamData.isStreaming}
                  setIsStreamStarted={(value) => {
                    setStreamData((prevData) => ({
                      ...prevData,
                      isStreaming: value,
                    }));
                  }}
                  responseCompleted={streamData.responseCompleted}
                  className={"text-streaming-box"}
                />
              ) : (
                streamData.content && <div className="text-streaming-box">
                  <RenderHtml
                    text={streamData.content}
                  />
                  <Button
                    type="text"
                    icon={<CopyOutlined />}
                    className="copy-to-clipboard-button"
                    onClick={(event) => {
                      writeToClipboardPolyfill(streamData.content, true);
                      message.success("Copied to clipboard");
                    }}
                  />
                </div>
              )}
            </Space>
          )}

          <Space direction="vertical">
            {showOutput && !loading && !streamData.isStreaming && (
              <Space>
                {editorContent !== "" && (
                  <Button
                    type="primary"
                    ghost
                    size="small"
                    onClick={() =>
                      replaceContent(editor.current, streamData.content)
                    }
                  >
                    Replace{" "}
                    {selectedContent ? "selection" : editorContent ? "all" : ""}
                  </Button>
                )}
                <Button
                  type="primary"
                  ghost
                  size="small"
                  onClick={() => {
                    insertContent(editor.current, streamData.content, true);
                  }}
                >
                  Insert{" "}
                  {selectedContent
                    ? "after selection"
                    : editorContent
                    ? "at the end"
                    : ""}
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    processCommand(activeCommand, prompt);
                  }}
                >
                  Try again
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setShowOutput(false);
                    setIsAssistantActive(false);
                  }}
                >
                  Cancel
                </Button>
              </Space>
            )}
            <Space>
              <MagicButton
                shape={"default"}
                mode={isAssistantActive ? "primary" : "subtle"}
                icon={
                  streamData.isStreaming ? <FaRegStopCircle /> : <ImMagicWand />
                }
                type="text"
                disabled={config.loading}
                feature_code="ai_writing_assistant"
                // onClick={() => getSelectedContent(editor.current)}
                onClick={() => handleMagicButton()}
              />
              {isAssistantActive && (
                <Space direction="vertical" className="writing-assistant-input-container">
                  {showCommands && (
                    <Dropdown
                      overlayClassName={`${dropdownOverlayClass} ${dropdownContainerClass}`}
                      overlayStyle={{
                        width: "200px",
                        minWidth: "200px !important",
                      }}
                      // autoAdjustOverflow={false}
                      menu={{
                        items: commands,
                        onClick: ({ item, key }) => {
                          console.log("process this key =====>", key, item);
                          setShowCommandsDropdown(false);
                          processCommand(key);
                          // setInputValue(null);
                        },
                        getPopupContainer: (element) => {
                          let writingAssistantContainer = getClosestContainer();
                          if (writingAssistantContainer) {
                            return writingAssistantContainer;
                          }
                        },
                        onOpenChange: (indexArray) => {
                          setOpenSubmenuCount(indexArray.length);
                        },
                      }}
                      open={showCommandsDropdown}
                    >
                      <Input
                        ref={inputRef}
                        placeholder="Ask AI to write or edit.."
                        value={inputValue}
                        onChange={(e) => {
                          console.log("prompt onChange", e);
                          setInputValue(e.target.value);
                          showCommandsDropdown &&
                            setShowCommandsDropdown(false);
                        }}
                        onPressEnter={(e) => {
                          e.preventDefault();
                          if (
                            showError ||
                            !inputValue ||
                            (inputValue && !inputValue.trim()) ||
                            streamData.isStreaming ||
                            loading
                          ) {
                            return;
                          }
                          console.log("prompt onpressenter", e);
                          // if (e.target.value !== "") {
                          processCommand("write", e.target.value);
                          // setInputValue(null);
                          // }
                        }}
                      />

                      {/* TODO: Add stop button as add-on to input while loading/streaming */}
                    </Dropdown>
                  )}
                </Space>
              )}
              {inputHistory && (
                <Button
                  disabled={config.loading}
                  size="small"
                  onClick={() => {
                    undoContent(editor.current);
                  }}
                >
                  Undo
                </Button>
              )}
            </Space>
          </Space>
          {showError && isAssistantActive && (
            <span className="error-message">
              {mrIntl("WritingAssistant.max_character_limit_reached")}
            </span>
          )}
        </Space>
      </Row>
    </DetectOutsideClick>
  );
};

export default WritingAssistant;
