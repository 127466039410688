import React, { useEffect, useState } from "react";
import { initialData, removeItemFromArray, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { Segment, SegmentData } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { cloneDeep } from 'lodash';
import MediaUI from "/src/components/UI/Media/MediaUI"
import PDFView from "./PDFView";
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments"
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import GenerateAIWithPrompt from "../GenerateAI/GenerateAIWithPrompt";
import { useDispatch, useSelector } from "react-redux";
import { activeTopicIdSelector } from "../Topics/selector";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import MagicButton from "../GenerateAI/MagicButton";
import { initializeAiGenerateQuestionsConfig, isNewGenerateQuestionsWithAI } from "../GenerateAI/generateAIHelperMethods";
import { aiGenerateQuestionsConfigSelector } from "../GenerateAI/selector";
import { message } from "/src/components/UI/AntdAppHelper";

const PDFForm = props => {
	console.log("PDF props", props);
  const { extra_props = {} } = props;
  const { previousId, destinationPosition, createAtIndex, nestingLevel, parentId, setShowForm, prevPosition, nextPosition } = extra_props;
	let { createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)
	let parsedJSONObject
  const mrIntl = useTranslate();
  const dispatch = useDispatch()
  const aiGenerateQuestionsConfig = useSelector(aiGenerateQuestionsConfigSelector())
	if (createMode) {
		parsedJSONObject = props.value || initialData('resource', 1, 4);
	} else {
		parsedJSONObject = props.resource_json
	}

	const [state, setState] = useState(parsedJSONObject)
	const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  // const [extractQuestions, setExtractQuestions] = useState(false);
    const activeTopicId = useSelector(activeTopicIdSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());

  const newGenerateQuestionsWithAI = isNewGenerateQuestionsWithAI(enabledFeatures);

	const mediaFormInitialState = {
		modal: false,
		loading: false,
		visible: false,
		title: mrIntl("PDFForm.insert_pdf_title"),
		okText: mrIntl("PDFForm.insert_pdf_ok_text"),
		cancelText: mrIntl("CommonText.cancel"),
		triggerSaveAction: 0,
    limit: 1,
		requireFooter: false,
		content_type: 'pdf',
		allowed_type: 'pdf',
		supportedTypes: 'application/pdf',
		supportedFormats: 'pdf',
		selectConfig: {
			maxSize: "20 MB",
		},
    showExtractQuestionsFromPdfButton: true,
		beforeLoadURL: (data) => {
			let updatedURL = data
			return updatedURL
		},
    onChangeSaveFiles: (files) => {
      console.log("onChangeSaveFiles==>", files)
      if (files.length > 0) {
        if (!aiGenerateQuestionsConfig.generateMode) {
          setUploadedPdf(files)
        }
      }
    }
	}
	const [mediaFormSetting, setMediaFormSetting] = useState(mediaFormInitialState);
  const [uploadedPdf, setUploadedPdf] = useState([]);

  useEffect(() => {
    setUploadedPdf([])
  }, [mediaFormSetting.visible])

  console.log("line no 76=>", mediaFormSetting)
	let currentJSON
	if (presentationMode) {
		currentJSON = parsedJSONObject
	} else {
		currentJSON = state
	}
	const segment_data = currentJSON?.segment_data || {}
	const resource_setting = currentJSON.resource_setting || {}
	const attachments = segment_data.attachments || []
	const attachmentsCount = attachments.length
  console.log("line no 87=>", mediaFormSetting)
	useEffect(() => {
		setMediaFormSetting({
			...mediaFormSetting,
			modal: attachmentsCount > 0,
			visible: false,
		})
	}, [attachmentsCount])

	useEffect(() => {
		if (createMode) {
			if (props.onChange) {
				let resource_json = cloneDeep(state)
				props.onChange(resource_json)
			}
		}
	}, [stateUpdatedCount]);

	const saveState = (state) => {
		setState(state)
		console.log("stateUpdated count ==>", stateUpdatedCount)
		setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
	}

	const onTabDragEnd = (attachments) => {
		const newAttachments = attachments.slice()

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const removeItem = (index) => {
		const extra = {
			noMinSize: true
		}
		const newAttachments = removeItemFromArray(attachments, index, extra)
		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}
		saveState(newState)
	}

	const removeFile = (index) => {
		const newAttachments = attachments
    newAttachments[index] = { ...newAttachments[index], files: [] };

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const onSaveMedia = (files) => {
		console.log('Files and attachmetns ==>', attachments, files, attachmentsCount)
    // If the user clicks the Add button without selecting a file, a message is shown
    if (files.length === 0) {
      message.error(mrIntl("CommonText.at_least_one_file_required"));
      return
    }

		const newAttachments = attachments.concat(files)

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log('ON save PDF ==>', newAttachments, newState)

		saveState(newState)
		setMediaFormSetting({
			...mediaFormSetting,
			from: '',
			modal: true,
			visible: false,
			triggerSaveAction: 0,
      files: newState.segment_data.attachments
		})
	}

	const onTextChanged = (type, index, data) => {
		const newAttachments = attachments
		newAttachments[index][type] = data

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log("onchange is getting fired ==>", newState, data)
		saveState(newState)
	}

	const setResourceSetting = (key, value) => {
		const newResourceSetting = {
			...resource_setting,
			[key]: value
		}

		const newState = {
			...state,
			resource_setting: newResourceSetting
		}

		saveState(newState)
	}

	const onTabEdit = (index, action) => {
		if (action == 'add') {
			setMediaFormSetting({
				...mediaFormSetting,
				from: 'computer',
				modal: true,
				visible: true,
			})
		} else if (action == 'remove') {
			removeItem(index)
		}
	}

	const handleTabPositionChange = (e) => {
		setResourceSetting('tab_position', e.target.value)
	}

	const handleViewTypeChange = (e) => {
		setResourceSetting('view_type', e.target.value)
	}
 console.log("line no 239=>", attachments, attachmentsCount)
	const getMediaForm = () => {

		return (
      <MediaUI
        computer={true}
        // url={true}
        drive={true}
        mediaFormSetting={mediaFormSetting}
        setMediaFormSetting={(data) => setMediaFormSetting(data)}
        onSaveMedia={(files) => {
          console.log("onsavemedia==>", files);
          onSaveMedia(files);
        }}
        extractQuestionsFromPdf={extractQuestionsFromPdf}
      ></MediaUI>
    );
	}

  const shouldExtractFromPdf = !newGenerateQuestionsWithAI && enabledFeatures.ai_generate_questions_from_pdf && import.meta.env.VITE_MODE !== 'china_production' && uploadedPdf.length > 0;

  const extractQuestionsFromPdf = (
    <>
      {shouldExtractFromPdf ?
        <MagicButton
          type="text"
          size="medium"
          text={mrIntl("PDFForm.extract_form_pdf")}
          shape="default"
          onClick={() => {
            let payload = {
              generateMode: true,
              createCondition: "new",
              destinationPosition: destinationPosition,
              prevPosition: prevPosition,
              nextPosition: nextPosition,
              previousId: previousId,
              createAtIndex: createAtIndex,
              key: "generate-button",
              parentId: parentId,
              nestingLevel: nestingLevel,
              extractFromPdf: true,
              segment: {},
              uploadedPdf: uploadedPdf
            }
            initializeAiGenerateQuestionsConfig(dispatch, payload)
          }}
		  feature_code="ai_generate_questions_from_pdf"
        />
        : null}
    </>
  );

	return (
		<Segment>
			<SegmentData>
				{createMode && getMediaForm()}

				{attachmentsCount > 0 && <div>
					{createMode ? <TabularAttachments
						createMode={createMode}
            hideAdd={mediaFormSetting.limit == 1}
						attachmentType="pdf"
						tabPrefix={mrIntl("CommonText.pdf")}
						titleRequired={true}
						attachments={attachments}
						resource_setting={resource_setting}
						onTabDragEnd={onTabDragEnd}
						onTabEdit={onTabEdit}
						onTextChanged={onTextChanged}
					></TabularAttachments>
						: <PDFView
							resource_json={state}
						></PDFView>}
				</div>}
			</SegmentData>
		</Segment >
	);
}
export default PDFForm;