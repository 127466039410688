import React from "react";
import { MrSelect, MrCrudView } from "mr_react_framework";
import { actions } from "./redux";
// import GroupMembersListItem from "./GroupListItem/GroupListItem";
// import GroupMembersItemDetail from "./GroupMembersItemDetail/GroupMembersItemDetail";
// import GroupMembersFilter from "./GroupMembersFilter/GroupMembersFilter";
import GroupMembersList from "./GroupMembersList/GroupMembersList";
import commonSelectors from "/src/App/commonSelectors";
// import {usersState} from "./selector";
// import {buildOptionsArr} from "../../lib/utils/helperMethods";
// import {Input, AutoComplete, Tabs, Button, Form} from "antd";
// import Experiences from "../Experiences/Experiences";
// import {Route, useRouteMatch} from "react-router-dom";
import {actions as genericActions} from "/src/App/genericRedux";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";


const SelectUsers = MrSelect({
  actions: genericActions,
  resourceName: "users",
  config: {
    url: "/users",
    // params: {by_role: "student", by_org_programme_id: org_programme_id, by_grade_id: grade_id}, //default is org_id on BE
    params: {by_role: "student"}, //default is org_id on BE
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.name, value: item.id};
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"CommonText.search_for_students"} />,
      mode: "multiple",
      style: {width: "400px"},
      // onDeselect: (val) => { console.log("val", val)}
    },
  }
});

export const returnForm = (props) => {
  
  console.log("props groups form", props);
  let form = {
    // opts = {name, formFinishProps, formState, values }
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      let group_users_attributes = []
      if(opts.values.group_student_ids){
        opts.values.group_student_ids.map((userId, i) => {
          group_users_attributes.push({
            relation: "teacher",
            user_id: userId,
          })
        })
        opts.values.group_users_attributes = group_users_attributes;
      }
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        group_student_ids: {
          type: "string",
          title: <MrTranslate id={"CommonText.select_students"} />,
          span: 24,
          widget: SelectUsers,
          // widget: (props) => {console.log("widget props gu", props); return null;},
        },
      },
    }
  };
  return form;
};



const GroupMembers = MrCrudView({
  resourceName: "groupUsers",
  singleResourceName: "group_user",
  displayName: "Create/Edit Class Member",
  resourceUrl: "/group_users",
  // layout: OrgsLayout,
  // filter: GroupMembersFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
    // users: usersState
  },
  // listItem: GroupListItem, //allowed but now using itemPreview below
  forms: [
    { name: "groupUsersOld", form: returnForm, config: {isModal: true, modalConfig: {width: "", wrapClassName: "class-create-modal"}} },
  ],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  config: {
    itemPreview: {
      widget: (props) => {JSON.stringify(props.item)}
    },
    itemDetail: {
      widget: (props) => {
        console.log( "itemDetail props", props );
        return <span><MrTranslate id={"CommonText.item_detail"}/> {JSON.stringify(props.item)}</span>
      }
      // widget: GroupMembersItemDetail,
    },
    pagination: {
      show: false,
    },
    filter: {
      show: false,
      config: {
        create: {show: false},
        search: {show: false},
      }
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: false,
      forceReload: true,
      widget: GroupMembersList,
    }
  },
});

// GroupMembers.defaultProps = {};

GroupMembers.propTypes = {};
export default GroupMembers;
