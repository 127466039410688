import React, { useState, useEffect } from "react";
import { MrSelect } from "mr_react_framework";
import { actions as genericActions } from "/src/App/genericRedux";
import { useSelector } from "react-redux";
import { experienceUsersSelector } from "../selector";

export const ExperienceUserSelect = MrSelect({
  actions: genericActions,
  config: {
    dropdownMatchSelectWidth: false,
    processData: (data, props) => {
      console.log("experience user select ==>", data, props);
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      searchApi: false,
    },
  },
});
