import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player'
import Slider from "rc-slider";
import screenfull from "screenfull";
import Player from "@vimeo/player"
import { isTablet, isTouchDevice, isiPhone ,isMobile} from '../helpers';
import { SpeedIcon, FullscreenIcon, VolumeIcon, PlayIcon, ExitFullScreenIcon, MuteIcon, PauseIcon, RestartIcon } from '../icons/FlashcardIcons';
import "rc-slider/assets/index.css";
import {
    VideoControl,
    VideoTopBar,
    VideoSpeed,
    SpeedNumber,
    VideoVolume,
    VideoCenter,
    VideoPlay,
    PlayButton,
    PlayIconStyle,
    FullscreenIconStyle,
    VolumeIconStyle,
    VideoSeekBar,
    SeekBar,
    VideoTimer,
    RemainingTime,
    TotalTime,
    LoadedVedio,
    VideoControlContaint,
    VideoOpacity,
   
    FromBeginingDes,
    VideoPlayer,
    VideoPlayerSection,
    VideoMain
} from './Player.style';
import Loader from './Loader';


const format = (seconds) => {
    if (isNaN(seconds)) {
        return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
        return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
};


export const OSCVimeoPlayer = (props) => {
    const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("normal");
    const videoPlayerRef = useRef(null);
    const seekBarRef = useRef(null)
    const playerRef = useRef(null);
    const mainVimeoPlayerRef = useRef(null);
    const [fullscreen, setfullscreen] = useState(false);
    const [played, setPlayed] = useState("");
    const [videoReady, setVideoReady] = useState(false);
    const [skeletonShow, setSkeletonShow] = useState(true)
    const controlOverlayTimer = useRef(null);
    const [controlOverlay, setControlOverlay] = useState(true);
    const [reStart, setReStart] = useState(false)
    const [loaded, setLoaded] = useState(0);
    const [isSeekDrag, setSeekDrag] = useState(true);
    const [resume, setResume] = useState(false);
    const [vWidth, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [bufferLoading, setBufferLoading] = useState(false);
    const [state, setState] = useState({
        playing: false,
        muted: false,
        playbackRate: 1,
        seeking: false,
        isHover: false,
    });
    const {
        playing,
        muted,
        playbackRate,
        isHover,
    } = state;
    useEffect(() => {
        if (videoReady) {
            if (isTablet() || isiPhone()) {
                let iframe = videoPlayerRef.current.querySelector('iframe');
                if (iframe) {
                    mainVimeoPlayerRef.current = new Player(iframe);
                    mainVimeoPlayerRef.current.on('fullscreenchange', function (data) {
                        if (!data.fullscreen) {
                            setfullscreen(false)
                            if (isiPhone()) {
                                setState({ ...state, playing: false });
                            }
                        } else {
                            setfullscreen(true);
                            if (isiPhone()) {
                                setState({ ...state, playing: true });
                            }
                        }
                    });
                } else {
                    // setErrorLog("iframe not found")
                }
            }
        }
    }, [videoReady]);
    const toggleFullScreen = (e) => {
        e.stopPropagation();
        if (isiPhone()) {
            if (playing) {
                mainVimeoPlayerRef.current.play();
            } else {
                mainVimeoPlayerRef.current.play();
                //    mainVimeoPlayerRef.current.setCurrentTime(Number(props.videoProgress.get('currentTime'))).then(function(seconds) {
                //        mainVimeoPlayerRef.current.pause()
                //    }).catch(function(error) {
                //        setErrorLog(error)
                //    });
            }
            mainVimeoPlayerRef.current.requestFullscreen().then(function () {
                setfullscreen(true)
                // TODO: recheck on vimeo ticket on fullscreenchange bug
                const interval = setInterval(() => {
                    mainVimeoPlayerRef.current.getFullscreen().then(function (localFullscreen) {
                        if (localFullscreen) {
                            // setState({...state, playing: true})
                        } else {
                            setfullscreen(false)
                            setState({ ...state, playing: false })
                            clearInterval(interval);
                        }
                    }).catch(function (error) {
                        clearInterval(interval)
                    });
                }, 1000);
            }).catch(function (error) {
                //setErrorLog(error)
            });
        } else {
            screenfull.toggle(videoPlayerRef.current);
            setfullscreen(!fullscreen);
        }
    };

    const handlePlayPause = (e) => {
        e.stopPropagation();
        if (reStart) {
            setPlayed(1)
            setLoaded(1)
            setSeekDrag(false)
            setState({ ...state, playing: true });
            playerRef.current.seekTo(1, 'seconds');
            const timeout = setTimeout(() => {
                setState({ ...state, playing: true })
                setPlayed(1)
                setLoaded(1)   
                playerRef.current.seekTo(1, 'seconds');
                setReStart(false)
            }, 10)
            return () => {
                clearTimeout(timeout)
            }
        } else {
            if (playing) {
                setState({ ...state, playing: false });
            } else {
                setState({ ...state, playing: true });
            }
           
            setReStart(false)
            setControlOverlay(true)
        }
    };

    const handleProgress = (changeState) => {
        if(changeState.played>0){
            setLoaded(changeState.loaded)
            if (!state.seeking) {
                setSeekDrag(false)
                setState({ ...state, ...changeState });
                if(isSeekDrag){
                    if(played>0){
                        setPlayed(parseFloat(played))
                    }
                }else{
                    setPlayed(changeState.played)
                }
            }
           
            
        } 
    };
   
    const OnStartTime =()=>{
        playerRef.current.seekTo(0);
    }

    const onEnded=() => {
        setState({ ...state, playing: false });
        setReStart(true)
        setResume(false)
        setPlayed(100)
       
    }

    function onReady(){
        setVideoReady(true);
    }

    function onPlay(){
        if(isiPhone()){
            if(!playing){
                setState({ ...state, playing: false });
            } else {
                setState({ ...state, playing: true });
            }
        } else {
            if(!playing){
                setState({ ...state, playing: true });
                const timeout = setTimeout(() => {
                    setState({ ...state, playing: false});
                }, 10)
            } 
        }
    }
    function handleClick(e) {
        e.preventDefault();
        e.stopPropagation();
        if (playing) {
            if (isMobile) {
                if (controlOverlay) {
                    setControlOverlay(false)
                } else {
                    setControlOverlay(true)
                }
            } else {
                if (controlOverlay && !isHover) {
                    setControlOverlay(false)
                } else {
                    setControlOverlay(true)
                }
            }

        } else {
            setControlOverlay(true)
        }

    };
    function onMouseLeaveHandler() {
        setState({ ...state, isHover: false });
    }

    function onMouseEnterHandler() {

        setState({ ...state, isHover: true });

    }
    const handlePlaybackRate = (e, rate) => {

        setState({ ...state, playbackRate: rate });
        setControlOverlay(true)
        e.preventDefault();
        e.stopPropagation();
    };
    const handleMute = (e) => {

        setState({ ...state, muted: !state.muted });
        setControlOverlay(true)
        e.preventDefault();
        e.stopPropagation();
    };
    const currentTime =
        playerRef && playerRef.current
            ? playerRef.current.getCurrentTime()
            : "00:00";

    const duration =
        playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";
    const elapsedTime =
        timeDisplayFormat == "normal"
            ? format(currentTime)
            : `-${format(duration - currentTime)}`;
    const totalDuration = format(duration);
    function onChangeSeekRange(played) {
        if (!controlOverlay) {
            setControlOverlay(true);
        }
        setReStart(false)
        setSeekDrag(true)
        setState({ ...state, seeking: false });
        setPlayed(parseFloat(played / 100))
        if (played < 2) {
            playerRef.current.seekTo(0, "seconds");
        } else {
            playerRef.current.seekTo(played / 100, "fraction");
        }
        if (played == 100) {
            setState({ ...state, playing: false });
            setReStart(true)
            setResume(false)
            setPlayed(100)
            playerRef.current.seekTo(100, "fraction");
        }
    }

    function isLandscape (){
        if (window.matchMedia("(orientation: landscape)").matches) {
           return true
         }else{
             return false
         }
    }
    function handleHeight() {
        let videoWidth = videoPlayerRef && videoPlayerRef.current && videoPlayerRef.current.clientWidth;
        let videoHeight, videoWidths, vWidth;
        if (isMobile() && isLandscape() && !fullscreen) {
            videoWidth = videoPlayerRef && videoPlayerRef.current && videoPlayerRef.current.clientWidth;

            videoHeight = window.innerHeight - 135;
            console.log(videoHeight ,"line number 296")
            videoWidths = videoHeight * (16 / 9);
            if (videoWidth > videoWidths) {
                setWidth(videoWidth)
            } else {
                setWidth(videoWidths)
            }
        } else {
            if (isMobile()) {
                videoHeight = videoWidth && videoWidth * (9 / 16);
            } else {
                videoHeight = videoWidth && videoWidth * (9 / 16);
            }
            const vWidths = videoHeight * (16 / 9);
            if (videoWidth > vWidths) {
                setWidth(videoWidth)
            } else {
                setWidth(vWidths)
            }
        }
        setHeight(videoHeight);
        
        if (isMobile() ) {
            setHeight(window.innerHeight);
        }
        if (videoPlayerRef.current) {
            videoPlayerRef.current.style.height = videoPlayerRef ? videoHeight + "px" : "0";
            if (isMobile() &&  !fullscreen) {
                videoPlayerRef.current.style.width = videoPlayerRef ? vWidth + "px" : "0";
            } else {
            }
        }
    }
    function handleMouseMove () {
        if(playing && !isTouchDevice()){           
            setControlOverlay(true)
        }
    };

    function hanldeMouseLeave () {
      
        if(playing && !isTouchDevice()){          
            setControlOverlay(false)
        }
    };
    useEffect(() => {
      
        if (!videoPlayerRef && !videoPlayerRef.current) return;
        const baseTabContent = videoPlayerRef && videoPlayerRef.current;
        let resizeObserver = new ResizeObserver(() => {
            handleHeight();
        });
        resizeObserver.observe(baseTabContent);
        return () => {
            resizeObserver.unobserve(baseTabContent);
        };
       
    }, [fullscreen,isMobile(),isiPhone(),isTouchDevice()]);
    useEffect(() => { 
       
        if(playing){
            controlOverlayTimer.current = setTimeout(() => {
                setControlOverlay(false)
            }, 2000);
            return () => {
                clearTimeout(controlOverlayTimer.current);
            }
        }else{
            setControlOverlay(true)
        }
    },[playing, controlOverlay, controlOverlayTimer.current])

    function onBufferStart() {
      setBufferLoading(true);
    }
    function onBufferEnd() {
      setBufferLoading(false)
    }

    return (
        <div className='app'>
            <VideoMain vWidth={vWidth}>
            <VideoPlayerSection skeletonShow={skeletonShow} className="video-player-section" form={true}>
                <VideoPlayer className={"video-play exams-video"} id="video-wrapper" ref={videoPlayerRef} fullscreen={fullscreen}
                onMouseMove={handleMouseMove}
                onMouseLeave={hanldeMouseLeave}
                >
                    <ReactPlayer
                        ref={playerRef}
                        playsInline
                        width="100%"
                        // height={"100%"}
                        height={fullscreen ? "100%" : "100%"}
                        controls={false}
                        onBuffer={onBufferStart}
                        onBufferEnd={onBufferEnd}
                        muted={muted}
                        volume={1}
                        loop={false}
                        playsinline={true}
                        playing={playing}
                        playbackRate={playbackRate}
                        onProgress={handleProgress}
                        className="video-player"
                        // url={'https://player.vimeo.com/video/505843794?h=4f9ab5b383&title=0&byline=0&portrait=0&autopause=0&controls=0&app_id=122963'}
                        url={props.url}
                        config={{
                            vimeo: {
                                playerOptions: {
                                    autoplay: false,
                                    speed: true,
                                    responsive: false,
                                    controls: false,
                                },
                            }
                        }}
                        onStart={OnStartTime}
                        onEnded={onEnded}
                        onReady={onReady}
                        onPlay={onPlay}
                    />
                    <VideoControlContaint vWidth={vWidth} fullscreen={fullscreen} height={height} onClick={handleClick} >
                        <VideoOpacity vWidth={vWidth} fullscreen={fullscreen} ></VideoOpacity>
                        <VideoControl vWidth={vWidth} fullscreen={fullscreen} activControl={controlOverlay || bufferLoading}  >
                       
                            <VideoTopBar>
                                <VideoSpeed onMouseEnter={() => onMouseEnterHandler()} onMouseLeave={() => onMouseLeaveHandler()}>
                                    <SpeedIcon />
                                    {[1, 1.5, 2].map((rate, index) => (
                                        <SpeedNumber key={index} isPlayBackRate={rate === playbackRate} large={rate == 1.5} onClick={(e) => handlePlaybackRate(e, rate)}>{rate}x</SpeedNumber>
                                    ))}
                                </VideoSpeed>
                                <VideoVolume onMouseEnter={() => onMouseEnterHandler()} onMouseLeave={() => onMouseLeaveHandler()}>
                                    <VolumeIconStyle onClick={(e) => handleMute(e)} >{muted ? <MuteIcon /> : <VolumeIcon />}</VolumeIconStyle>
                                    <FullscreenIconStyle onClick={(e) => toggleFullScreen(e)} >{fullscreen ? (isTablet() || isiPhone()) ? <div /> : <ExitFullScreenIcon /> : <FullscreenIcon />}</FullscreenIconStyle>
                                </VideoVolume>
                            </VideoTopBar>
                            <VideoCenter >
                              {bufferLoading ? <Loader /> :
                                <VideoPlay begining={reStart} >
                                  <PlayIconStyle onClick={handlePlayPause} >{reStart ? <RestartIcon /> : playing ? <PauseIcon /> : <PlayIcon />}</PlayIconStyle>
                                  {resume ?
                                    <PlayButton>{playing ? "Pause" : "Resume"}</PlayButton>
                                    : reStart ?
                                      <React.Fragment>
                                        <PlayButton begining={reStart}>FROM BEGINNING</PlayButton>
                                        <FromBeginingDes>This will remove the 'Watched‘ flag</FromBeginingDes>
                                      </React.Fragment>
                                      :
                                      <PlayButton>{playing ? "Pause" : "Play"}</PlayButton>
                                  }
                                </VideoPlay>
                              }
                            </VideoCenter>
                            <VideoSeekBar>
                                <VideoTimer>
                                    <RemainingTime>{elapsedTime} / </RemainingTime>
                                    <TotalTime>{totalDuration}</TotalTime>
                                </VideoTimer>
                                <SeekBar ref={seekBarRef}>
                                    <Slider
                                        className={'input-range'}
                                        min={1}
                                        max={100}
                                        value={played * 100}
                                        onChange={onChangeSeekRange}
                                        railStyle={{
                                            appearance: 'none',
                                            height: 4,
                                            backgroundColor: "#FFFFFF",
                                            borderRadius: 2,
                                        }}
                                        handleStyle={{
                                            appearance: 'none',
                                            height: 24,
                                            width: 24,
                                            backgroundColor: "#50D3A6",
                                            borderRadius: 12,
                                            border: 0,
                                        }}
                                        trackStyle={{
                                            background: "#50D3A6"
                                        }}
                                    />
                                </SeekBar>
                                <LoadedVedio width={loaded * 100}></LoadedVedio>
                            </VideoSeekBar>
                        </VideoControl>
                    </VideoControlContaint>
                </VideoPlayer>
            </VideoPlayerSection>
            </VideoMain>
            {/* ExamVimeoPlayer */}
        </div>
    )
}


export default OSCVimeoPlayer;