import React from "react";
import { MrCrudView, FormRenderer } from "mr_react_framework";
import { actions } from "./redux";
import ExperienceUsersFilter from "./ExperienceUsersFilter/ExperienceUsersFilter";
// import List from "./ExperienceUsersList/ExperienceUsersList";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors from "/src/App/commonSelectors";
// import {usersState} from "./selector";
// import {buildOptionsArr} from "../../lib/utils/helperMethods";
// import {Input, AutoComplete, Tabs, Button, Form} from "antd";
// import Experiences from "../Experiences/Experiences";
// import {Route, useRouteMatch} from "react-router-dom";
// import {actions as genericActions} from "/src/App/genericRedux";
import ExperienceUsersList from "/src/views/ExperienceUsers/ExperienceUsersList/ExperienceUsersList";
import { useSelector } from "react-redux";
import { activeAdjustedExperienceSelector, activeExperienceGradingDisabledSelector } from "/src/views/Experiences/selector";
import RubricCriteriaPointsForm from "/src/views/Segments/RubricCriteriaPointsForm/RubricCriteriaPointsForm";
import { InputNumber } from "antd";
import { CKETextCommentTools } from "/src/components/UI/CKEditor/CKEConfig";
import { displayPoints } from "/src/lib/utils/helperMethods";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import MrTranslate, { mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import Comment from "/src/views/UserResponses/Comment";
import MediaComments from "/src/views/UserResponses/MediaComments";
import GradingExperienceUsersCustomLayout from "./GradingExperienceUsersCustomLayout";
import { getEmbeddedSelector } from "/src/views/Auth/Login/selector";
import { showConvertedAchievementLevelSelector } from "./selector";

const PointsChildComponent =  (props) => {
  console.log("PointsChildComponent eu", props)
  const { 
    // getFieldValue, 
    sourceValues
  } = props
  const mrIntl = useTranslate();
  // const segmentType = getFieldValue(["segment_type"]);
  let scas = sourceValues.submission_criterium_associations_attributes
  let finalRender = null;

  // get active experience rubric = use criteria to build form for points if rubric not Points. if points or if no experience/adding segment in library, then default to below. if grading for full_experience then hide altogether

  // const activeExperienceId = useSelector(activeAdjustedExperienceIdSelector())
  // const activeExperience = useSelector(state => state.experiences.get("experiences").find((item) => item.id == activeExperienceId))
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const showConvertedAchievementLevel = useSelector(showConvertedAchievementLevelSelector())

  console.log("PointsChildComponent", activeExperience)
  let rubricType = activeExperience.rubric && activeExperience.rubric.type_c;
  
  // if (segmentType === "question" || (!segmentType && sourceValues.id && sourceValues.segment_type === "question")){
  if (sourceValues){
   
    // get active experience grading_setting = per_question/full_experience
    if ((activeExperience.settings.grading_setting === "per_question" && !showConvertedAchievementLevel) || (rubricType === "comments_only")) {
      finalRender = ""
    }else{
      // if grading per question
      // change this to rubric_code
      console.log("scas==>", scas)
      // if(!scas || scas.length === 0){
        if(rubricType === "points"){
        let maxPoints = displayPoints(sourceValues.max_points)
        finalRender = <FormRenderer
          formObj={{
            type: "object",
            properties: {
              points: {
                type: "string",
                placeholder: mrIntl("CommonText.points"),
                span: 14,
                wrapClassName: "points",
                widget: InputNumber,
                // widget: Input,
                widgetConfig:{
                  min: 0,
                  max: parseFloat(maxPoints),
                  // addonAfter: `/ ${parseFloat(maxPoints)}`
                  // addonAfter: parseFloat(maxPoints)
                },
                rules: [
                  {
                    validator(rule, value) {
                      // console.log('UserResponses validator', value, typeof(value))
                      // if((!value && value != 0) || value == ""){
                      //   return Promise.reject("Points can't be blank")
                      // } 
                      if(value >= 0 && value <= maxPoints && typeof(value) == "number"){
                      // if(value >= 0 && value <= maxPoints){
                        return Promise.resolve()
                      }
                      // // else if(value == "" || value == undefined || value == null){
                      // else if(!value || value == ""){
                      //   return Promise.reject("Points can't be blank")
                      // } 
                      else {
                        return Promise.reject(mrIntl("CommonText.not_allowed"))
                      }
                    }
                  },
                ]
              },  
              // showing in collapse header now - no need in form
              // total_points: {
              //   type: "string",
              //   // placeholder: "Points",
              //   span: 9,
              //   wrapClassName: "total-points",
              //   // widget: TotalPointsWidget
              //   widget: () => {
              //     return `/ ${maxPoints}`
              //   }
              // },
            
            }
          }}
          // propertyKey={"section_segment_attributes"}
          sourceValues={sourceValues}
        />
      }
      else if(scas && scas.length != 0){
        // any other rubric, show criteria wise here
        // show this form if rubric/achievement level and points both chosen. if only achievement level -> no points per question -> only level for full experienceUser 
        // form for criterium_associations of type "question" -> only for questions with criterium_associations of type experience (4 criteria for eg)
        // used criteria in all questions say A an B -> attach only those that are used in questions to experience
        // let maxPoints = parseFloat(sourceValues.max_points).toFixed(1)


        // let maxPoints = parseFloat(sourceValues.custom_fields.experience_points).toFixed(1)
        // const pointsTotalDisplay = <h3 style={{"marginTop":"4px"}}><b>{`${parseFloat(sourceValues.points).toFixed(1)}/${maxPoints}`}</b></h3>
        finalRender = <FormRenderer
        formObj={{
          type: "object",
          properties: {
            submission_criterium_associations_attributes: {
              type: "string",
              placeholder: mrIntl("CommonText.points"),
              setInitialValue: true,
              span: 24,
              widget: RubricCriteriaPointsForm,
              rules: [
                {
                  validator(rule, value) {
                    console.log('UserResponses validator', value, typeof(value))
                    let validationPassed = true
                    if(!value){
                      validationPassed = false
                    }else{
                      for(let i = 0; i <= value.length - 1; i++){
                      // value.map((ca, i) => {
                        let ca = value[i]
                        let updatedPoints = ca.points
                        // console.log('UserResponses validator updatedPoints', ca, updatedPoints, typeof(updatedPoints), maxPoints, parseFloat(updatedPoints), typeof(parseFloat(updatedPoints)))
                        // console.log('UserResponses validator updatedPoints conditions', updatedPoints >= 0, updatedPoints <= parseFloat(ca.custom_fields.max_points), typeof(updatedPoints))
                        // if (updatedPoints >= 0 && updatedPoints <= parseFloat(ca.custom_fields.max_points) && typeof(parseFloat(updatedPoints)) == "number") {
                        if(updatedPoints == "0" || updatedPoints == null || ((parseFloat(updatedPoints) >= 0 && parseFloat(updatedPoints) <= parseFloat(ca.custom_fields.max_points) && typeof(parseFloat(updatedPoints)) == "number") && updatedPoints.toString().slice(-1) != ".")){
                          // allowed
                        }
                        else{
                          validationPassed = true
                          break;
                        }
                      }
                    }
                    
                    console.log('UserResponses validator updatedPoints', validationPassed)
                    if(validationPassed){
                      return Promise.resolve()
                    }
                    else {
                      return Promise.reject(mrIntl("CommonText.not_allowed"))
                    }
                  }
                },
              ]
            },
            // showing in collapse header now - no need in form
            // points_total_display: {
            //   type: "string",
            //   // placeholder: "Points",
            //   span: 2,
            //   setInitialValue: true,
            //   widget: (props) => {
            //     console.log("points_total_display", props)
            //     return pointsTotalDisplay
            //     // return "5/6"
            //   },
            // },
          }
          
        }}
        // propertyKey={"section_segment_attributes"}
        sourceValues={sourceValues}
      />
      }
    }
  }
  
  return finalRender;
}

const processInitialValues = (data) => {
  console.log('processInitialValues==>', data)
  let newData = {...data};
  if (data.custom_fields) {
    newData.media_comments = data.custom_fields.media_comments;
  }
  return newData;
} 

export const returnForm = (props) => {
  console.log("return form new ===>>>>", props);
  const { mrIntl } = props

  const isEmbedded = props.embedded
  const activeExperienceGradingDisabled = props.activeExperienceGradingDisabled
  // setting disabled true when mb_linked and rubric_type = myp_achievement_level. We want grading on MB side in this case.
  let form = {
    // opts = {name, formFinishProps, formState, values }
    processFormData: (dataProps, opts) => {

      if(opts.values.media_comments){
        let customFields = {
        ...opts.values.custom_fields,
        media_comments: opts.values.media_comments
        }
        opts.values.custom_fields = customFields;
      }
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        points: {
          type: "string",
          // placeholder: "Points",
          placeholder: mrIntl("CommonText.points"),
          span: 24,
          // wrapClassName: "wrap-segment-form-points",
          dependsOn: [""],
          children: PointsChildComponent,
          // // setInitialValue: true,
          rules: [
            {
              required: true,
              message: mrIntl("CommonText.required_msg"),
            },
          ]
        },
        max_points: {
          // just to getFieldValue for above
          type: "string",
          wrapClassName: "hidden",
        },
        comments: {
          type: "string",
          // placeholder: "Comments",
          span: 24,
          widget: Comment,
          widgetConfig: {
            ckeConfig: {
              ...CKETextCommentTools,
              isReadOnly: activeExperienceGradingDisabled,
              debounceInterval: 500, // the default value of 1000 was leading to APL-2496 and APL-2507
              // isReadOnly: true //testing on local
              // overAllComments: true,
              autoFocus: true
            },
            writingAssistantConfig: {
              enabled: true
            },
          },
          wrapClassName: "comments",
        },
        media_comments: {
          type: "string",
          span: 24,
          widget: MediaComments,
          // wrapClassName: "experience-users-media-comments"
          wrapClassName: isEmbedded ? "hidden" : "experience-users-media-comments"
        },
      },
    }
  };
  return form;
};



const ExperienceUsers = MrCrudView({
  resourceName: "experienceUsers",
  singleResourceName: "experience_user",
  // displayName: "Create/Edit Class Member",
  resourceUrl: "/experience_users",
  // layout: OrgsLayout,
  filter: ExperienceUsersFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
    // activeExperience: activeAdjustedExperienceSelector, // TODO: getting state error
    activeExperienceGradingDisabled: activeExperienceGradingDisabledSelector,
    embedded: getEmbeddedSelector,
    // showConvertedAchievementLevel: showConvertedAchievementLevelSelector,
    // users: usersState
  },
  // listItem: GroupListItem, //allowed but now using itemPreview below
  forms: [
    { name: "experienceUsers", form: returnForm, config: { processInitialValues, isModal: false, modalConfig: {width: "", wrapClassName: "class-create-modal"}} },
  ],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  config: {
    itemPreview: {
      widget: (props) => {JSON.stringify(props.item)}
    },
    itemDetail: {
      widget: (props) => {
        console.log( "itemDetail props", props );
        return <span>Item Detail {JSON.stringify(props.item)}</span>
      }
      // widget: ExperienceUsersItemDetail,
    },
    pagination: {
      show: false,
    },
    filter: {
      show: true,
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: false,
      widget: ExperienceUsersList,
      forceReload: true,
      layout: GradingExperienceUsersCustomLayout,
    },
  },
});

// ExperienceUsers.defaultProps = {};

ExperienceUsers.propTypes = {};
export default ExperienceUsers;
