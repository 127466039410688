import { getAIServiceBaseURL, getDestinationPositionLex, getFromLS, getUniqueId, updateConfigHeaderForDemoUseStagingAI } from "/src/lib/utils/helperMethods";
import {actions as segmentActions} from "/src/views/Segments/redux";
import {actions as aiSegmentActions} from "/src/views/Segments/GenerateAI/redux"
import axios from "axios";
import {actions as topicActions} from "/src/views/Segments/Topics/redux";
import { message } from "/src/components/UI/AntdAppHelper";


export const convertPDFToImages = async (pdfUrl) => {
  try {
    const dataToPost = {
      pdf_url: pdfUrl,
    };
    const token = getFromLS("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    config = updateConfigHeaderForDemoUseStagingAI(config);

    const response = await axios.post(
      `${getAIServiceBaseURL()}/pdf2img`,
      dataToPost,
      config
    );
    return [response];
  } catch (error) {
    console.log("convertPDFToImages error==>", error);
    return [];
  }
};

const findItemById = (id, items) => {
  for (const item of items) {
    if (item.id === id) {
      return { label: item.label, description: item.description };
    }
    if (item.children && item.children.length > 0) {
      const result = findItemById(id, item.children);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const getDetailsFromIds = (ids, allItems) => {
  console.log("getDetailsFromIds ===>", { ids, allItems });
  let items = [];
  for (const id of ids) {
    const selectedItem = findItemById(id, allItems);
    if (selectedItem) {
      items.push(selectedItem);
    }
  }
  return items.length > 0 ? items : null;
};


export const buildOptionsForFillType = (role, content) => {
  const answer = Array.isArray(content?.answer) ? content.answer : [content.answer]
  return content.options.map((option, index) => ({
    checked: role === 'teacher' && answer.includes(option),
    content: option,
    placeholder: `Option ${index + 1}`,
    id: getUniqueId()
  }));
};

export const buildItemsForRBDInline = (items, keyName) => {    
  return items.map((item, itemIndex) => ({
    content: item,
    placeholder: `${keyName} ${itemIndex + 1}`,
    id: getUniqueId(),
  }));
};

export const buildColumnsForRBDInline = (questionData, questionType) => {
  const columnKeyNames = {
    rbd_inline_matching: { column: "Match target", item: "Match phrase", data: "pairs" },
    rbd_inline_sorting: { column: "Column", item: "Item", data: "items" },
    rbd_inline_classify: { column: "Category", item: "Item", data: "categories" }
  };

  const { column: columnKeyName, item: itemKeyName, data } = columnKeyNames[questionType];
  const columns = questionData[data];

  return columns.map((column, columnIndex) => {
    const columnItems = questionType === "rbd_inline_matching" 
      ? [column.correct_match] 
      : questionType === "rbd_inline_sorting" 
      ? [column] 
      : questionType === "rbd_inline_classify"
      ? column.category_items
      : []

    const columnId = getUniqueId(16);
    const items = buildItemsForRBDInline(columnItems, itemKeyName);

    return {
      items,
      title: column.content || "",
      name: `${columnKeyName} ${columnIndex + 1}`,
      placeholder: `${columnKeyName} ${columnIndex + 1}`,
      id: columnId,
      key: columnId,
    };
  });
};

export const buildPossibleResponses = (teacherJsonSegmentData) => ({
  items: teacherJsonSegmentData.columns.flatMap((column) => column.items),
  title: "Possible Responses",
  id: getUniqueId(16),
});

export const findSyllabusTopicKey = (topics, tagId, returnKey) => {
  let syllabusTopicKey;

  for (const tag of topics) {
    if (parseInt(tag.id) === parseInt(tagId)) {
      syllabusTopicKey = tag[returnKey];
      console.log("findSyllabusTopicKey==>", tagId, returnKey, syllabusTopicKey)

      break;
    } else if (tag.children) {
      syllabusTopicKey = findSyllabusTopicKey(tag.children, tag.id, returnKey);
      if (syllabusTopicKey) {
        break;
      }
    }
  }

  return syllabusTopicKey;
};

export const addToSegment = (addSegmentOptions, options={}) => {
  const { dispatch, commandTermId, syllabusTopicId, paperTypeId, teachingLevelId, segment, allChildrenSegments} = addSegmentOptions;

  const {handleSuccess, handleError} = options;
  
  // if (!addAll) {
    // setAddButtonLoading(true);
  // }

  
  // let question_segments_attributes = {
  //   question_type:
  //     questionType === "long_answer"
  //       ? "cke_subjective"
  //       : questionType === "mcq"
  //         ? "mcq_single"
  //         : questionType === "nested_question"
  //           ? "group"
  //           : questionType === "classify"
  //             ? "rbd_inline_classify"
  //             : questionType === "match"
  //               ? "rbd_inline_matching"
  //               : questionType,    
  //     content: content.question,
  // };

  // if (questionType === 'mcq') {
  //   question_segments_attributes = {
  //     ...question_segments_attributes,
  //     teacher_json: {
  //       segment_data: {
  //         options: buildOptions('teacher', content)
  //       }
  //     },
  //     student_json: {
  //       segment_data: {
  //         options: buildOptions('student', content)
  //       }
  //     }
  //   };
  // }

  // if (questionType === "true_false") {
  //   question_segments_attributes = {
  //     ...question_segments_attributes,
  //     teacher_json: {
  //       segment_data: {
  //         statements: buildOptionsForTrueFalse('teacher', content)
  //       }
  //     },
  //     student_json: {
  //       segment_data: {
  //         statements: buildOptionsForTrueFalse('student', content)
  //       }
  //     }
  //   };
  // }
  // if (questionType === "classify" || questionType === "match") {
  //   console.log("questiontypeweee ==>", questionType);
  //   let columnKeyName = "Category"; 
  //   let itemKeyName = "Item"
  //   let allPossibleOptions = [];
  //   if (questionType === "rbd_inline_matching") {
  //     columnKeyName = "Match target"
  //     itemKeyName = "Match phrase"
  //   }
  //   if (content["columns"].length > 0) {
  //     content["columns"].forEach((column) => {
  //       allPossibleOptions = [...allPossibleOptions, ...column.items];
  //     });
  //   }
  //   question_segments_attributes = {
  //     ...question_segments_attributes,
  //     teacher_json: {
  //       segment_data: {
  //         columns: buildColumnsForRBDInline("teacher", content, columnKeyName),
  //         possible_responses: buildPossibleResponses(
  //           "teacher",
  //           allPossibleOptions,
  //           itemKeyName
  //         ),
  //       },
  //     },
  //     student_json: {
  //       segment_data: {
  //         columns: buildColumnsForRBDInline("student", content, columnKeyName),
  //         possible_responses: buildPossibleResponses(
  //           "student",
  //           allPossibleOptions, 
  //           itemKeyName
  //         ),
  //       },
  //     },
  //   };
  // }
  let createSegmentPayload = {
    segment: {
      ...segment,
      settings: {
        mode: "exam_mode"
      },
      custom_fields: {
        ai_used: {
          content: true
        },
      },
      tag_items_attributes: [],
    }
  };

  let childrenAttributes = [];
  let childrenSegments = []
  if (allChildrenSegments) {
    childrenSegments = [...allChildrenSegments]
  }

  if (commandTermId) {
    createSegmentPayload.segment.tag_items_attributes.push({
      itemable_type: "Segment",
      tag_id: commandTermId,
    });
  }
  if (syllabusTopicId) {
    createSegmentPayload.segment.tag_items_attributes.push({
      itemable_type: "Segment",
      tag_id: syllabusTopicId,
    });
  }
  if (paperTypeId) {
    createSegmentPayload.segment.tag_items_attributes.push({
      itemable_type: "Segment",
      tag_id: paperTypeId,
    });
  }
  if (teachingLevelId) {
    createSegmentPayload.segment.tag_items_attributes.push({
      itemable_type: "Segment",
      tag_id: teachingLevelId,
    });
  }
  // if (difficultyLevelId) {
  //   createSegmentPayload.segment.tag_items_attributes.push({
  //     itemable_type: "Segment",
  //     tag_id: difficultyLevelId,
  //   });
  // }
  // console.log("segment-ids===>",commandTermId, syllabusTopicId, paperTypeId, teachingLevelId, difficultyLevelId)
  
  if (childrenSegments.length > 0) {
    let prevPosition; 
    childrenSegments.map((item, i) => {
      // delete item.id
      // delete item.user_id;
      // delete item.parent_id;
      // delete item.position;
      item.question_segments_attributes = [
        item.question_segment_attributes,
      ];
      delete item.question_segment_attributes;
      let destinationPosition = getDestinationPositionLex(prevPosition);
      item.destination_position = destinationPosition;
      item.settings =  {
        mode: "exam_mode"
      };
      item.custom_fields = {
        ai_used:  {
          content: true
        },
      };
      childrenAttributes.push(item);
      prevPosition = destinationPosition
    });
    console.log("childrenSegments ==>", childrenAttributes);
    createSegmentPayload.segment.children_attributes = childrenAttributes;
  }


  const segmentActionOptions = {
    // createAtIndex: createAtIndex,
    // parentId: activeTopicId,
    // previousId: previousId,
    // destinationPosition: destinationPosition,
    success: {
      showMessage: false,
    },
    // successCallback: (data) => {
    //   console.log("successCallback==>", data);
    // },
    successCallback: (response) => {
      if(handleSuccess && response?.data?.segment?.data?.id) {
        const addedSegmentId = parseInt(response.data.segment.data.id)
        handleSuccess(addedSegmentId);
      }
      // if ((isNested  && createAtIndex + 1 === subPartsLength) || !isNested) {
      //   if (!addAll) {
      //     message.success(
      //       mrIntl("GenerateAIWithPrompt.new_question_added_success_msg")
      //     );
      //     // setAddButtonLoading(false);
      //   }
      // }

      let updatedSegment = response.data.segment.data.attributes;
      // console.log("updatedSegment ai==>", updatedSegment);
     
      // if (questionType === "group" && allChildrenSegments.length > 0) {
      //   // using async await here to send the segment adding request one after another
      //   async function addNestedQuestions(index) {
      //     if (index < allChildrenSegments.length) {
      //       const nestedPositionArray = ["U", "j", "r"]; //need to change this to dynamic - for this spoke with @Uttam - will calculate the positions on the FE first then will pass it instead of calculating on the BE
      //       try {
      //         await new Promise(resolve => setTimeout(resolve, 1000));
      //         delete allChildrenSegments[index].id;
      //         delete allChildrenSegments[index].user_id;
      //         delete allChildrenSegments[index].position;
      //         delete allChildrenSegments[index].points;
      //         allChildrenSegments[index].parent_id = updatedSegment.id;
      //         allChildrenSegments[index].destination_position = getDestinationPositionLex(nestedPositionArray[index]);
      //         allChildrenSegments[index].previous_id = updatedSegment.id;
      //         const addSegmentOptions = {
      //           questionType: "long_answer",
      //           // setAddButtonLoading,
      //           parentId: updatedSegment.id,
      //           destinationPosition: getDestinationPositionLex(nestedPositionArray[index]),
      //           previousId: updatedSegment.id,
      //           marks: allChildrenSegments[index].points,
      //           createAtIndex: index,
      //           dispatch,
      //           mrIntl,
      //           commandTermId,
      //           syllabusTopicId,
      //           paperTypeId,
      //           teachingLevelId,
      //           // difficultyLevelId,
      //           showCriteria,
      //           criteria,
      //           strands,
      //           isNested,
      //           subPartsLength,
      //           segment: allChildrenSegments[index],
      //         };

      //         await addToSegment(addSegmentOptions);
      //         if (activeExperience && !isEmpty(activeExperience)) {
      //           dispatch(segmentActions.setParentsPoints({ updatedSegment }));
      //        }
      
      //         console.log(" adding sub-part at index:===> success", index);
      //       } catch (error) {
      //         console.error(" adding sub-part at index:===> error", index, error);
      //       }
      
      //       await addNestedQuestions(index + 1); // Recursively call with the next index
      //     } else {
      //       // setAddAllButtonLoading(false);
      //       // All questions added, do something if needed
      //     }
      //   }
      
      //   addNestedQuestions(0); // Start the recursive loop with index 0
      // }
      // if (activeExperience && !isEmpty(activeExperience)) {
      dispatch(segmentActions.setParentsPoints({ updatedSegment }));
    //  }

    },
    errorCallback: (data) => {
     if(handleError) {
      handleError()
     }
    }
  };
  dispatch(segmentActions.create(createSegmentPayload, segmentActionOptions));
};

export const initializeAiGenerateQuestionsConfig = (dispatch, payload={}) =>{
  dispatch(aiSegmentActions.setAiGenerateQuestionsConfigSuccess({data: payload}))
  dispatch(aiSegmentActions.fetchSuccess({data: {segments: []}}))
}

export const addAllAIQuestionsToSegments = (
  segmentsToShow,
  aiGenerateQuestionsConfig,
  timeout=2000,
  dispatch,
  mrIntl,
  addedAiSegments
) => {
  const {
    strands,
    criteria,
    commandTermId,
    syllabusTopicId,
    paperTypeId,
    teachingLevelId,
    showCriteria,
    prevPosition,
    nextPosition,
    parentId,
    previousId
  } = aiGenerateQuestionsConfig;

  // Filter out already added segments before processing
  const segmentsNotAdded = segmentsToShow.filter(
    segment => !addedAiSegments.current.includes(segment.id)
  );

  const handleSuccess = (addedSegmentId, position, aiSegmentId, idx) => {
    const isLastSegment = idx === segmentsNotAdded.length - 1;
    let config =  {
      ...aiGenerateQuestionsConfig,
      prevPosition: position,
      addAllButtonLoading: true,
      previousId: addedSegmentId
    }
    if(isLastSegment) {
      config = {
        ...config,
        addAllButtonLoading: false
      }
    }
    dispatch(
      aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
        data: {
          ...config
        },
      })
    );
    addedAiSegments.current.push(aiSegmentId);
  };

  const handleError = (idx) => {
    const isLastSegment = idx = segmentsNotAdded.length - 1;
    if(isLastSegment) {
      dispatch(aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
        data: {
          ...aiGenerateQuestionsConfig,
          addAllButtonLoading: false
        }
      }))
    }
  }

  const addAIQuestionsWithDelay = (prevPos, nextPos, previousId, idx) => {
    if (idx >= segmentsNotAdded.length) {
      return;
    }

    const segment = segmentsNotAdded[idx];
    const questionType = segment.question_segment_attributes.question_type;
    const newPosition = getDestinationPositionLex(prevPos, nextPos);
    let updatedPreviousId = previousId
    const newSegment = {
      ...segment,
      destination_position: newPosition,
      parent_id: parentId,
      question_segments_attributes: [segment.question_segment_attributes],
      previous_id: updatedPreviousId
    };
    delete newSegment.id;
    delete newSegment.position;
    delete newSegment.question_segment_attributes

    const addSegmentOptions = {
      isNested: questionType === "group",
      segment: newSegment,
      questionType,
      dispatch,
      mrIntl,
      syllabusTopicId,
      commandTermId,
      strands,
      showCriteria,
      criteria,
      paperTypeId,
      teachingLevelId,
    };

    setTimeout(() => {
      addToSegment(addSegmentOptions, {
        handleSuccess: (addedSegmentId) => {
          updatedPreviousId = addedSegmentId
          handleSuccess(addedSegmentId, newPosition, segment.id, idx);
          addAIQuestionsWithDelay(newPosition, nextPos, updatedPreviousId, idx + 1);
        },
        handleError: () => {
          handleError(idx)
          addAIQuestionsWithDelay(newPosition, nextPos, updatedPreviousId, idx + 1);
        },
      });
      // addAIQuestionsWithDelay(newPosition, nextPos, updatedPreviousId, idx + 1);
    }, timeout);
  };

  dispatch(
    aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
      data: {
        ...aiGenerateQuestionsConfig,
        addAllButtonLoading: true
      },
    })
  );
  // Start processing from the filtered list
  addAIQuestionsWithDelay(prevPosition, nextPosition, previousId, 0);
};

export const onGenerateSimilar = (extractFromPdf, dispatch, i, segmentsToShow, parentId, nestingLevel, segment, newGenerateQuestionsWithAI) => {
  let payload = {
    generateMode: true,
    createCondition: extractFromPdf ? "new" : segment.question_segment_attributes &&
      segment.question_segment_attributes.question_type === "cke_subjective"
      ? "similar"
      : (segment.segment_type === "resource" ||
        segment.segment_type === "text" || (segment.resource_segment_attributes && segment.resource_segment_attributes.resource_type === "image")) && 
        "based_on",
    destinationPosition: getDestinationPositionLex(
      (segmentsToShow[i] || {}).position,
      (segmentsToShow[i + 1] || {}).position
    ),
    prevPosition: (segmentsToShow[i] || {}).position,
    nextPosition: (segmentsToShow[i + 1] || {}).position,
    previousId: (segmentsToShow[i] || {}).id || 0,
    createAtIndex: i + 1,
    key: "generate-button",
    parentId: parentId,
    nestingLevel: nestingLevel,
    extractFromPdf: extractFromPdf,
    segment: extractFromPdf ? {} : segment,
    uploadedPdf: extractFromPdf ? segment.resource_segment_attributes.resource_json.segment_data.attachments : null,
  };
  if(newGenerateQuestionsWithAI) {
    payload = {
      ...payload,
      activeAISegmentPosition: {idx: i + 1, parentId: parentId}
    }
  }
  initializeAiGenerateQuestionsConfig(dispatch, payload);
}

export const groupByParent = (flatArray) => {  
  const groupedArray = [];

  // Iterate through the flat array and find parent elements
  flatArray.forEach((item) => {
    if (item.parent_id === null) {
      // Find children of this parent
      const children = flatArray.filter((child) => child.parent_id === item.id);
      
      // Push the parent and its children into the grouped array
      groupedArray.push([item, ...children]);
    }
  });

  return groupedArray;
};

export const addTask = (lastSegment, activeExperienceId, dispatch, aiGenerateQuestionsConfig, aiSegments, mrIntl, addedAiSegments, allChildrenSegments) => {
  
  const sectionPayload = {
   segment: {
    "experience_id": activeExperienceId,
    "parent_id": activeExperienceId,
    "parent_type": "experience",
    "segment_type": "section",
    "previous_id": lastSegment.id,
    "destination_position": getDestinationPositionLex(lastSegment.position),
    "section_segments_attributes": [
      {
        "title": "<p>Task</p>"
      }
    ]
   }
  }
  const segmentActionOptions = {
    success: {
      showMessage: false,
    },
    successCallback: (data) => {
      const updatedSegment = data.data.segment.data.attributes;
      const newDestinationPosition = getDestinationPositionLex(aiGenerateQuestionsConfig.prevPosition);
      dispatch(segmentActions.setParentsPoints({ updatedSegment }));
      addAllAIQuestionsToSegmentsForTask(
        allChildrenSegments,
        aiGenerateQuestionsConfig,
        2000,
        dispatch,
        mrIntl,
        addedAiSegments,
        updatedSegment.id,
        newDestinationPosition
      );
      const textSegmentParams = {
        destination_position: newDestinationPosition,
        experience_id: activeExperienceId,
        parent_id: updatedSegment.id,
        previous_id: 0,
        segment_type: "text",
        settings: { allow_embed: false },
        text_segments_attributes: [
          {
            resource_type: "text",
            content: aiSegments[0].question_segment_attributes.content
          }
        ],
        custom_fields: {
          ai_used: {
            content: true
          },
        },
      }
      const textSegmentOptions = {
        success: {
          showMessage: false,
        },
        successCallback: (data) => {
          dispatch(
            aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
              data: {
                ...aiGenerateQuestionsConfig,
                addAllButtonLoading: false,
              },
            })
          );  
        },
        errorCallback: (error) => {
          console.error("error in creating text content", error);
          dispatch(
            aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
              data: {
                ...aiGenerateQuestionsConfig,
                addAllButtonLoading: false,
              },
            })
          );  
        }
      }
      dispatch(segmentActions.create({segment: textSegmentParams}, textSegmentOptions))
    },
    errorCallback: (error) => {
      console.error("failed to create topic", error);
      dispatch(
        aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
          data: {
            ...aiGenerateQuestionsConfig,
            addAllButtonLoading: true,
          },
        })
      );      
    }
  }
  dispatch(topicActions.create(sectionPayload, segmentActionOptions))
  dispatch(
    aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
      data: {
        ...aiGenerateQuestionsConfig,
        addAllButtonLoading: true,
      },
    })
  );
}

export const addAllAIQuestionsToSegmentsForTask = (
  segmentsToShow,
  aiGenerateQuestionsConfig,
  timeout=2000,
  dispatch,
  mrIntl,
  addedAiSegments,
  parentId,
  prevPosition
) => {
  const {
    strands,
    criteria,
    commandTermId,
    syllabusTopicId,
    paperTypeId,
    teachingLevelId,
    showCriteria,
    previousId
  } = aiGenerateQuestionsConfig;
  
  const segmentsNotAdded = [...segmentsToShow]
  const handleSuccess = (addedSegmentId, position, idx) => {
    const isLastSegment = idx === segmentsNotAdded.length - 1;
    let config =  {
      ...aiGenerateQuestionsConfig,
      prevPosition: position,
      previousId: addedSegmentId,
      addAllButtonLoading: true
    }
    if(isLastSegment) {
      config = {
        ...config,
        addAllButtonLoading: false,
        taskAdded: true
      }
      message.success(mrIntl("generateAIHelperMethods.task_added_successfully"))
    }
    dispatch(
      aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
        data: {
          ...config
        },
      })
    );
    // addedAiSegments.current.push(id);
  };

  const handleError = (idx) => {
    const isLastSegment = idx = segmentsNotAdded.length - 1;
    if(isLastSegment) {
      dispatch(aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
        data: {
          ...aiGenerateQuestionsConfig,
          addAllButtonLoading: false
        }
      }))
    }
  }

  const addAIQuestionsWithDelay = (prevPos, idx, previousId) => {
    if (idx >= segmentsNotAdded.length) {
      return;
    }
 
    const segment = segmentsNotAdded[idx];
    const questionType = segment.question_segment_attributes.question_type;
    const newPosition = getDestinationPositionLex(prevPos);
    
    let updatedPreviousId = previousId

    const newSegment = {
      ...segment,
      destination_position: newPosition,
      parent_id: parentId,
      question_segments_attributes: [segment.question_segment_attributes],
      previous_id: updatedPreviousId
    };

    delete newSegment.id;
    delete newSegment.position;
    delete newSegment.question_segment_attributes

    const addSegmentOptions = {
      isNested: questionType === "group",
      segment: newSegment,
      questionType,
      dispatch,
      mrIntl,
      syllabusTopicId,
      commandTermId,
      strands,
      showCriteria,
      criteria,
      paperTypeId,
      teachingLevelId,
    };

    setTimeout(() => {
      addToSegment(addSegmentOptions, {
        handleSuccess: (addedSegmentId) => {
          updatedPreviousId = addedSegmentId;
          handleSuccess(addedSegmentId, newPosition, idx);
          addAIQuestionsWithDelay(newPosition, idx + 1, updatedPreviousId);

        }, 
        handleError: () => {
          handleError(idx)
          addAIQuestionsWithDelay(newPosition, idx + 1);
        }
      });
      // addAIQuestionsWithDelay(newPosition, idx + 1);
    }, timeout);
  };

  dispatch(
    aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
      data: {
        ...aiGenerateQuestionsConfig,
        addAllButtonLoading: true
      },
    })
  );
  // Start processing from the filtered list
  addAIQuestionsWithDelay(prevPosition, 0, previousId);
};

export const aiQuestionTypesAllowedToAppend = ["mcq", "long_answer", "nested_question", "true_false", "classify", "match", "fill_text", "fill_dropdown", "sort", "mcq_multiple"];
// Created this function to have single control point over enabling new or old ai in the code. can return true for new and false for old
export const isNewGenerateQuestionsWithAI = (enabledFeatures) => {
  return enabledFeatures.new_generate_questions_with_ai;
}

export const questionTypesRequiringAnswers = ["mcq", "mcq_multiple"]
