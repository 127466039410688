import React, { useRef, useState, useEffect } from "react";

import { Button } from "antd";
import { SketchPicker, CompactPicker } from 'react-color';

const ColorPicker = (props) => {
  console.log("ColorPicker Props ==>", props)
  const { defaultConfig, type, onChange } = props

  const [colorSetting, setColorSetting] = useState(defaultConfig || {
    color: {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    }
  })

  const onChangeComplete = (color) => {
    if (onChange) {
      onChange(color)
    }
  }

  return (
    <div
      style={props.style}
      className={props.className}
    >
      {type == 'SketchPicker' &&
        <SketchPicker
          color={colorSetting.color}
          onChange={(color) => {
            setColorSetting({
              ...colorSetting,
              color: color
            })
          }}
          onChangeComplete={onChangeComplete}
        >
        </SketchPicker>
      }

      {type == 'CompactPicker' &&
        <CompactPicker
          color={colorSetting.color}
          onChange={(color) => {
            setColorSetting({
              ...colorSetting,
              color: color
            })
          }}
          onChangeComplete={onChangeComplete}
        >
        </CompactPicker>
      }
    </div>
  );
};

export default ColorPicker;