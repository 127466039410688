import React from "react";
import { Card, Row, Col, Typography, Space, Button, Tag, Table, Progress, Avatar } from "antd";
import { LeftOutlined, SendOutlined, CloseOutlined } from "@ant-design/icons";
import { SolarDurationIcon, SolarUserIcon, SolarPaperIcon, SolarStarLineDuotone, SolarTimerIcon } from "/src/components/UI/Icons/SolarIcons";
import { useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import {
  attachmentsCountSelector,
  attemptedCountSelector,
  currentViewSelector,
  requestResumeSelector,
  experiencePausedSelector,
  submitExperienceLoadingSelector,
} from "../../FirestoreInteractions/selector";
import AttemptStatus from "../../Components/AttemptStatus";
import QuitBtn from "/src/components/UI/QuitBtn";
import "./ViewCard1.scss";
import {
  checkIPAD,
  checkMob,
  convertToHoursAndMinutes,
  displayPoints,
  getSubjectIconMap,
} from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import Spinner from "/src/components/UI/Spinner/Spinner";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";

const { Title, Text } = Typography;
const isIPAD = checkIPAD();
const isMobile = checkMob();
const isMobileOrIpad = isIPAD || isMobile;

export const CardBodyLeft = (props) => {
  const { experience, showConfig = {} } = props
  const { showInstructions, timer, startCountDown, titleColorClassName } = showConfig

  const currentUser = useSelector(currentUserSelector());
  const mrIntl = useTranslate();

  const subjectIconMap = getSubjectIconMap();
  
  const experienceSettings = experience.settings || {};
  const experienceRubric = experience.rubric || {};
  const experienceRubricTypeC = experienceRubric.type_c;
  const customFields = experience.custom_fields || {};
  const gradeItemLabel = customFields.grade_item_label;
  const subjectItemLabel = customFields.subject_item_label
  const currentUserName = currentUser.name
  const subjectIconFileName = subjectIconMap?.[subjectItemLabel?.toLowerCase()]

  let showPts = false;
  let isMYPAchievementLevel = false
  if (experienceRubricTypeC === "comments_only") {
    showPts = false;
  } else if (experienceRubricTypeC === "myp_achievement_level") {
    showPts = false;
    isMYPAchievementLevel = true
  } else {
    showPts = true
  }

  const getSubjectLabel = (subjectLabel, subjectIcon) => {
    if (subjectIcon && subjectLabel?.length > 20) {
      return `${subjectLabel.slice(0, 20)}...`;
    } else if (subjectLabel?.length > 24 && !subjectIcon) {
      return `${subjectLabel.slice(0, 23)}...`;
    }
    return subjectLabel;
  };

  let criteriaPointsView = [];
  if (experience.criterium_associations) {
    experience.criterium_associations.map((ca, i) => {
      const title = ca?.custom_fields?.title;
      if (title) {
        criteriaPointsView.push(
          <Tag key={`criteria-points-${ca.id}`} className="question-wrapper">
            {isMYPAchievementLevel ? title : `${title} ${displayPoints(ca.points)}`}
          </Tag>
        );
      }
    });
  }

  let assessmentDetailsRender = (
    <Card className="assessment-details" bordered={false}>
      <Title level={3} className="text-center">{experience.name}</Title>
      <Space className={`assessment-details ${!showInstructions && "assessment-details-body"}`} direction="vertical">
        <Row className="m-b-10" span={24}>
          <Col span={12} align='right' style={{ paddingRight: '50px', borderRight: '1px solid #f3f3f3' }}>
            <Col className='m-b-10'>
              <Text level={4} type="secondary">{mrIntl("CommonText.name")}</Text>
            </Col>
            <Col className='m-b-10'>
              <Text level={4} type="secondary">{mrIntl("CommonText.subject")}</Text>
            </Col>
            <Col className='m-b-10'>
              <Text level={4} type="secondary">{mrIntl("CommonText.grade")}</Text>
            </Col>
            {experienceSettings.duration && (
              <Col className='m-b-10'>
                <Text level={4} type="secondary">
                  {mrIntl("ViewCard1.duration")}
                </Text>
              </Col>
            )}
            {(startCountDown || timer) && (
              <Col className='m-b-10'>
                <Text level={4} type="secondary">
                  {mrIntl("ViewCard1.time")}
                </Text>
              </Col>
            )}
            <Col className='m-b-10'>
              <Text level={4} type="secondary">{mrIntl("CommonText.question")}</Text>
            </Col>
            {showPts &&
              <Col className='m-b-10'>
                <Text level={4} type="secondary">{mrIntl("CommonText.points")}</Text>
              </Col>
            }
            {/* {criteriaPointsView.length > 0 &&
          <Col style={{marginBottom: '10px'}}>
            <Text level={4} type="secondary">Criteria</Text>
          </Col>
          } */}
          </Col>
          <Col span={12} style={{ paddingLeft: '50px' }}>
            <Col className='assessment-detail-item' align='left'>
              {/* <SolarUserIcon /> */}
              <Text title={`${currentUserName.length > 20 ? currentUserName : ''}`}>
                {currentUserName.length > 20 ? currentUserName?.slice(0, 20) + '...' : currentUserName}
              </Text>
            </Col>
            <Col className='assessment-detail-item' align='left' style={{ columnGap: 2 }}>
              {/* {subjectIconFileName && (
                <Avatar className='subject-icon' src={`https://v2res1.assessprep.com/manual_uploads/icons/sebo_icons/${subjectIconFileName}`} />
              )} */}
              <Text title={subjectItemLabel?.length > 20 ? subjectItemLabel : ""}>
                {getSubjectLabel(subjectItemLabel, subjectIconFileName)}
              </Text>
            </Col>
            <Col className='assessment-detail-item' align='left'>
              <Text>{gradeItemLabel}</Text>
            </Col>
            {experienceSettings.duration && (
              <Col className='assessment-detail-item' align='left'>
                {/* <SolarDurationIcon /> */}
                <Text level={4} >
                  {convertToHoursAndMinutes(experienceSettings.duration)}
                </Text>
              </Col>
            )}
            {(startCountDown || timer) && (
              <Col className='assessment-detail-item' align='left'>
                {/* <SolarTimerIcon /> */}
                <Text level={4} type="secondary" className={timer ? 'countdown-timer' : 'start-countdown-timer'}>
                  {startCountDown || timer}
                </Text>
              </Col>
            )}
            <Col className='assessment-detail-item' align='left'>
              {/* <SolarPaperIcon /> */}
              <Text className="question-wrapper">
                {experience.questions_count} {mrIntl("ViewCard1.qs")}
              </Text>
            </Col>
            {showPts &&
              <Col className='assessment-detail-item' align='left'>
                <Text className="points-wrapper">{displayPoints(experience.points)} {mrIntl("ViewCard1.pts")} </Text>
              </Col>}
            {/* <Col style={{marginBottom: '10px'}} align='left'>
            {(showPts || isMYPAchievementLevel) && criteriaPointsView.length > 0 && !isMobileOrIpad &&
              <Text className={'points-questions-wrapper'} >
                {criteriaPointsView}
              </Text>
            }
          </Col> */}
          </Col>
        </Row>
      </Space>
    </Card>
  );

  return (
    <div className="assessment-details-cards-wrapper">
      <div className="assessment-details-title-wrapper">
        <Title
          className={`assessment-details-title ${titleColorClassName}`}
        >
          {mrIntl("ViewCard1.assessment_details")}
        </Title>
      </div>
      <Card className='assessment-details-card'>
        <div>{assessmentDetailsRender}</div>
      </Card>
    </div>
  );
};

export const CardBodyRight = (props) => {
  const { experience, showConfig = {} } = props
  const { showInstructions, titleColorClassName } = showConfig

  const experienceSettings = experience.settings || {};
  const questionsCount = experience.attemptable_questions_count;

  const currentUser = useSelector(currentUserSelector());
  const attemptedCount = useSelector(attemptedCountSelector);
  const attachmentsTotalCount = useSelector(attachmentsCountSelector);

  const mrIntl = useTranslate();

  const attemptPercentage = 
    questionsCount
      ? ((attemptedCount / questionsCount) * 100).toFixed(0)
      : 0;

  let titleToRender = null
  let componentToRender = null
  if (showInstructions) {
    titleToRender = mrIntl("ViewCard1.instructions")
    componentToRender = 
      <Row className="">
        <Col span={24} className="text-left join-view-instructions-wrapper">
          <Card className="instructions-card" bordered={false}>
            <RenderHtml text={experienceSettings.extra_instructions_text} />
          </Card>
        </Col>
      </Row>
  } else {
    titleToRender = mrIntl("ViewCard1.assessment_progress")
    componentToRender = 
      <Row className="question-grid">
        <Col span={24} className="text-center">
          <Space direction="vertical" size="large">
            <Space className="assessment-progress" direction="horizontal" align="center">
              <div className="progress-item">
                <Text type="secondary">{mrIntl("ViewCard.progress")}</Text>
                {/* <div className="progress-circle">{attemptPercentage}%</div> */}
                <div className="progress-circle">
                  <Progress
                    type="circle"
                    percent={attemptPercentage}
                    width={30}
                    strokeColor="#12b78f"
                    size={25}
                    format={() => ''}
                    gapDegree={180}
                    gapPosition="bottom"
                  />
                  {attemptPercentage}%
                </div>
              </div>
              <div className="progress-item">
                <Text type="secondary">{mrIntl("ViewCard.files")}</Text>
                <div className="files-count">{attachmentsTotalCount}</div>
              </div>
              <div className="progress-item">
                <Text type="secondary">{mrIntl("ViewCard.answered")}</Text>
                <div className="answered-count">{attemptedCount}/{questionsCount}</div>
              </div>
            </Space>
            {experience?.topics ? (
              <AttemptStatus
                experienceTopics={experience.topics}
                experienceId={experience.id}
                userId={currentUser.id}
                wide={true}
                showFileIcon={true}
                hideGroupQuestionIndexPathLabel={true}
              />
            ) : (
              <Spinner />
            )}
          </Space>
        </Col>
      </Row>
  }

  return (
    <div className="assessment-details-cards-wrapper">
      <div className="assessment-details-title-wrapper">
        <Title
          className={`assessment-details-title ${titleColorClassName}`}
        >
          {titleToRender}
        </Title>
      </div>
      <Card className='assessment-details-card'>
        {componentToRender}
      </Card>
  </div>
  );
}

export const CardFooter = ({
  quitButtonAction,
  footerLeftComponent,
  footerCenterComponent,
  footerRightComponent
}) => {
  
  const mrIntl = useTranslate();

  return (
    <Row className="view-card-footer-container">
      <Col span={24} className="text-center">
      <Space>
        {footerLeftComponent}
        <QuitBtn
          key="join-view-quit-button"
          withLog={true}
          action={quitButtonAction}
          className="quit-button"
          quitButtonIcon={<CloseOutlined />}
          // quitButtonText={mrIntl("ViewCard.quit_app")} // Not customizing this text for now
        />
        {footerCenterComponent}
        {footerRightComponent}
      </Space>
      </Col>
    </Row>
  );
};

const ViewCard1 = ({
  experience,
  showConfig,
  isSubmitExperienceLoading,
  footerContent,
  footerCenterComponent,
  footerLeftComponent,
  footerRightComponent
}) => {
  const { showRightAssessmentDetailsCard = true } = showConfig

  return (
    <div className="assessment-details-container">
      <Row>
        <Col span={24} className="text-center">
          <Space>
            <CardBodyLeft experience={experience} showConfig={showConfig} />
            {showRightAssessmentDetailsCard && <CardBodyRight experience={experience} showConfig={showConfig} />}
          </Space>
        </Col>
        <Col span={24} className="text-center">
          <CardFooter
            experience={experience}
            showConfig={showConfig}
            footerCenterComponent={footerCenterComponent}
            footerLeftComponent={footerLeftComponent}
            footerRightComponent={footerRightComponent}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ViewCard1;