import React, { useState } from 'react'
import ModifyAIQuestion from './ModifyAIQuestion';
import { aiGenerateQuestionsConfigSelector } from './selector';
import { Space } from 'antd';
import AddGeneratedSegmentToList from '../SegmentList/AddGeneratedSegmentToList';
import { useSelector } from 'react-redux';

function ExtraAIActionsHeader(props) {
  const {index, segment, nestingLevel, addedAiSegments, activeEditQuestion, setActiveEditQuestion} = props
  const aiGenerateQuestionsConfig = useSelector(aiGenerateQuestionsConfigSelector());

  const {generateMode: aiGenerateMode, loading: aiGenerateLoading, questionType, addAllButtonLoading} = aiGenerateQuestionsConfig;

  const [showModifyAIResultCommands, setShowModifyAIResultCommands] = useState(false);

  const modifiableQuestionTypes = ["long_answer", "mcq", "true_false"]
  const showModifyQuestionButton = modifiableQuestionTypes.includes(questionType);   // TODO: ADD other question types for modify.

  return (
    <Space>
    {showModifyQuestionButton && (
      <ModifyAIQuestion
        activeEditQuestion={activeEditQuestion}
        setActiveEditQuestion={setActiveEditQuestion}
        index={index}
        showModifyAIResultCommands={showModifyAIResultCommands}
        setShowModifyAIResultCommands={setShowModifyAIResultCommands}
        aiGenerateMode={aiGenerateMode}
        questionType={questionType}
        question={segment}
        addAllButtonLoading={addAllButtonLoading}
        aiGenerateLoading={aiGenerateLoading}
      />
    )}
    <AddGeneratedSegmentToList
      key={`add-ai-segment-${segment.id}`}
      segment={segment}
      addedAiSegments={addedAiSegments}
      setActiveEditQuestion={setActiveEditQuestion}
    />
  </Space>
  )
}

export default ExtraAIActionsHeader