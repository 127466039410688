import React from "react";
import PropTypes from "prop-types";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const UserResponseFilter = (props) => {
  return <span><MrTranslate id={"UserResponseFilter.userresponse_filter"}/></span>;
};

UserResponseFilter.defaultProps = {};

UserResponseFilter.propTypes = {};

export default UserResponseFilter;
