import React from "react";
import { Space, Switch, Table, Tag, Typography } from "antd";
import { useRouteMatch } from "react-router-dom";
import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import dayjs from "dayjs";

const PromptsList = (props) => {
  console.log("PromptsList props", props);
  const { dataSource, updateResource } = props;
  const { Text, Link } = Typography;
  const tableDataSource = [];
  for (let index = 0; index < dataSource.length; index++) {
    let item = dataSource[index]
    let objToPush = {};
    objToPush.s_no = index + 1;
    objToPush.code = <Tag>{item.code}</Tag>
    objToPush.label = <Space direction="vertical">
      {item.label}
      {/* <Text type="secondary">{item.desc}</Text> */}
      {/* <Text type="secondary">{dayjs(dataSource[index].updated_at).format("MMM DD, YYYY h:mm a")}</Text> */}
    </Space>;
    // objToPush.desc = item.desc;
    objToPush.model = <Tag color="blue">{item.model}</Tag>;
    objToPush.system_message = <Text code ellipsis={true}>{item.system_message}</Text>;
    // objToPush.api_endpoint = <Space direction="vertical">
      // <Tag>{item.api_endpoint}</Tag>
      {/* {item.api_params && item.api_params.split(",").map((param) => { return <Tag>{param}</Tag>})} */}
    // </Space>
    objToPush.status = <Switch checked={item.status === "published"} onChange={(e) => { 
      console.log("switch e", e);
      updateResource(
        {
          id: item.id,
          status: e ? "published" : "draft",
        },
        {
          success: {
            showMessage: true,
            message:  e ? "Published" : "Set to draft",
          },
          error: {
            showMessage: true,
            message: "Error in updating prompt",
          },
        }
      ) 
    }}>{item.status}</Switch>
    // objToPush.updated_at = dayjs(dataSource[index].updated_at).format("MMM DD, YYYY h:mm a");
    objToPush.actions = (
      <ActionBtns
        edit={true}
        delete={true}
        deleted={props.deleted}
        item={dataSource[index]}
        id={dataSource[index].id}
      />
    );
    tableDataSource.push(objToPush);
  }

  const columns = [
    {
      title: "",
      dataIndex: "s_no",
      key: "s_no",
      width: 40
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 80
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
    },
    // {
    //   title: "Desc",
    //   dataIndex: "desc",
    //   key: "desc",
    // },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      width: 80
    },
    {
      title: "System message",
      dataIndex: "system_message",
      key: "system_message",
    },
    // {
    //   title: "API endpoint",
    //   dataIndex: "api_endpoint",
    //   key: "api_endpoint",
    // },
    // {
    //   title: "API params",
    //   dataIndex: "api_params",
    //   key: "api_params",
    // },
    // {
    //   title: "Last updated",
    //   dataIndex: "updated_at",
    //   key: "updated_at",
    // },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100
    },
    // TODO: add usage column also - fetch code wise from firestore - no of times and tokens used - org/overall
  ];
  const { url } = useRouteMatch();
  console.log("exp list item url", url);
  return (
    <Table dataSource={tableDataSource} columns={columns} pagination={false} />
  );
};

export default PromptsList;
