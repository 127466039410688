import React from "react";
import { MrSelect, MrCrudView, FormRenderer } from "mr_react_framework";
import { actions } from "./redux";
import QuestionbanksList from "./QuestionbanksList/QuestionbanksList";
// import BoardsFilter from "./BoardsFilter/BoardsFilter";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors from "/src/App/commonSelectors";
import { actions as genericActions } from "/src/App/genericRedux";
import {
  GenericWidgetCreator,
  SelectProgrammeSubject,
} from "/src/components/AppSpecific/SelectSubject/SelectSubject";

const SelectProgram = MrSelect({
  actions: genericActions,
  resourceName: "programmes",
  config: {
    url: "/programmes",
    params: { should_serialize: false },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.abbr, value: item.id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: "Select programme",
      // mode: "multiple",
      style: { width: "500px" },
    },
  },
});

const SelectSubjectWidget = GenericWidgetCreator((props) => {
  return {
    config: {
      forceReload: true,
      params: {
        by_type_c: "group",
        by_programme_id: props.formInstance.getFieldValue("programme_id"),
      },
    },
  };
}, SelectProgrammeSubject);

const getSelectSubjectField = (sourceValues) => {
  console.log("getSelectSubjectField sourceValues", sourceValues);
  return {
    type: "object",
    properties: {
      // subject_id: {
      //   placeholder: "Select subject",
      //   type: "string",
      //   span: 24,
      //   setInitialValue: true,
      //   widget: SelectSubjectWidget,
      // },
      questionbank_programme_subjects: {
        type: "array",
        title: "Programme Subjects",
        addButtonTitle: "+ Add Subject",
        span: 24,
        min: 1,
        max: 100,
        default: [{}],
        items: {
          type: "object",
          properties: {
            programme_subject_id: {
              type: "string",
              title: "Subject",
              widget: SelectSubjectWidget,
              span: 8,
            },
          },
        },
      },
    },
  };
};

const QBProgrammeSubjectsChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const programmeId = getFieldValue(["programme_id"]);
  let finalRender = null;
  if (programmeId) {
    finalRender = (
      <FormRenderer
        formObj={getSelectSubjectField(sourceValues)}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};

const returnForm = () => {
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      opts.values.questionbank_programme_subjects_attributes =
        opts.values.questionbank_programme_subjects;

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        title: {
          type: "string",
          placeholder: "Title",
        },
        code: {
          type: "string",
          default: "",
          placeholder: "Code",
        },
        programme_id: {
          title: "Select program",
          type: "string",
          span: 24,
          widget: SelectProgram,
        },
        questionbank_programme_subjects: {
          type: "string",
          dependsOn: ["programme_id"],
          children: QBProgrammeSubjectsChildComponent,
        },
        settings: {
          type: "object",
          properties: {
            dups_editable: {
              title: "Duplicates editable for users?",
              type: "string",
              span: 6,
              default: false,
              widget: "RadioGroupWidget",
              widgetConfig: {
                options: [
                  {
                    label: "Yes",
                    value: true,
                  },
                  {
                    label: "No",
                    value: false,
                  },
                ],
              },
            },
          },
        },
      },
    },
  };
  return form;
};

const Questionbanks = MrCrudView({
  resourceName: "questionbanks",
  singleResourceName: "questionbank",
  // displayName: "Create/Edit Questionbank",
  resourceUrl: "/questionbanks",
  // filter: BoardsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  // listItem: BoardListItem,
  forms: [{ name: "questionbanks", form: returnForm }],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span>Item Detail {JSON.stringify(props.item)}</span>;
      },
    },
    itemDetail: {
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span>Item Detail {JSON.stringify(props.item)}</span>;
      },
      // widget: ProgrammeItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      widget: (props) => {
        return <span>Filter</span>;
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      widget: QuestionbanksList,
      // widget: (props) => {
      // return <span>List</span>;
      // },
    },
  },
});

// Questionbanks.defaultProps = {};

Questionbanks.propTypes = {};
export default Questionbanks;
