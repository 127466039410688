import React from 'react'
import styled from 'styled-components'
import { Droppable } from 'react-beautiful-dnd'
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { AddButton, CheckAnswerButton, QuickCheckBox, TryAgainButton, CustomTextButton, TextArea, Segment, SegmentData, SegmentSetting, InputArea, DeleteButton, HorizontalDroppableContainer, DragDropArea, VerticalDroppableContainer, DroppableHeader, InlineDroppablesContainer, InlineDroppable, ShowText, ShowTextContent, ShowTextFile } from '/src/components/UI/Segment/UIHelper'
import MediaHoverButton from "/src/components/UI/Media/MediaHoverButton"
import MediaShow from "/src/components/UI/Media/MediaShow"

const CKETitle = styled.div`
`;

export const DnDDroppableTitle = (props) => {
  const { list } = props

  return (
    props.showTextArea ?
      <DroppableHeader>
        <TextArea>
          <InputArea>
            <BasicCKE
              data={list.title}
              onTextChanged={(data) => props.onTextChanged(data)}>
            </BasicCKE>
            {list.files && <MediaShow
              file={list.files && list.files[0]}
              removeFile={(e) => props.removeFile(list)}
            >
            </MediaShow>}
          </InputArea>
          <MediaHoverButton
            onSaveMedia={(files) => props.onSaveMedia(files)}
          ></MediaHoverButton>
          <DeleteButton
            clicked={(e) => props.removeList()}
          ></DeleteButton>
        </TextArea>
      </DroppableHeader>
      :
      <DroppableHeader>
        <CKETitle>
          <ShowText>
            {list.title && <ShowTextContent dangerouslySetInnerHTML={{ __html: list.title }}></ShowTextContent>}
            {list.files && <ShowTextFile>
              <MediaShow
                file={list.files && list.files[0]}
                removeIcon={false}
              >
              </MediaShow>
            </ShowTextFile>}
          </ShowText>
        </CKETitle>
      </DroppableHeader>
  )
}
