import React, { useState } from "react";
// import "./GroupItemDetail.scss";
import { Route, useRouteMatch } from "react-router-dom";
import { Tabs, Row, Col, Button, List, Card, Switch } from "antd";
import { AddButton, DeleteButton, CheckAnswerButton, TryAgainButton, QuickCheckBox, CheckBox, InputArea, Segment, SegmentData, SegmentSetting, MCQList, TextArea, ShowText, ShowTextIndex, ShowTextContent } from '/src/components/UI/Segment/UIHelper'
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const SegmentItemHeader = (props) => {

  console.log("SegmentItemHeader props==>", props);

  return (
    <React.Fragment>
      <Row>
        {props.quickCheckModeEnabled && <Col span={6} className="segment-item">
         <MrTranslate id={"SegmentItemHeader.my_answer"}/> <Switch onChange={(value) => props.showCorrectAnswer(value)} /> <MrTranslate id={"CommonText.correct_answer"}/>
        </Col>}
      </Row>
    </React.Fragment >

  );
}
export default SegmentItemHeader;


