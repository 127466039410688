import React, {Component} from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { isMobile } from './helpers';
import {IncreaseFlashcardZoom, DecreaseFlashcardZoom, PinZoomIncrease, ZoomClose} from './SvgContainer';	
import {isTouchDevice} from './helpers';	
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ZoomCanvas from './ZoomCanvas';
import RendererArea from './RendererArea';
// import image from './../image.png'
const isValidNode = function () {
  return true;
};
let revisionListener, zoomListener;
let lastScrollTop = 0, delta = 5;

class RendererContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {	
      isScrolled: false,	
      hasZoom: false,	
      zoomLevel: {	
        current: 1,	
        max: 2	
      },	
      imageZoom:false,	
      imageWidth:400,	
      imageHeight:400,	
      ishasZoom: false,	
      isZoomed: false	
    };
    this.revisionRef = React.createRef() 
    this.revRef = React.createRef()
    this.revisionScrollRef = React.createRef();
    this.isTouchDevice = isTouchDevice();	
    this.zoomCanvas = React.createRef();
  }

  showTargetElement = () => {
    if(isMobile()){
      disableBodyScroll(this.revisionScrollRef.current);
    }
  };

  hideTargetElement = () => {
    enableBodyScroll(this.revisionScrollRef.current);
  };


  componentDidMount() {
    if (!this.props.flashcardId) return;
    // this.getFlashcardContent();
    this.imageZoom();
    this.RevisionLink();
    this.tabFunction();
    
    this.revisionScrollRef && this.revisionScrollRef.current.addEventListener('scroll', this.handleRevisionScroll);
    let parentThis =this
    window.addEventListener('keyup', function(e){ 
        if(parentThis.state.imageZoom){   
          if(e.code == "Space" ){
              if (['INPUT', 'TEXTAREA'].indexOf(e.target.nodeName) !== -1) return;
              parentThis.onImageClick()
          }
          if(e.code == "Escape"){
              parentThis.closeZoom()
          }
        }
    })
  }
 
  getClosest = (elem, selector) => {
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
      if ( elem.matches( selector ) ) return elem;
    }
    return null;
  }
  tabFunction = () => {
    if(this.revRef.current){ 
      this.revRef.current.addEventListener("click", (e) => {
        if(e.target.classList.contains("reset-3c756112--tabsItem-488dd212--UIH300-2063425d") || e.target.classList.contains("text-4505230f--UIH300-2063425d--textContentFamily-49a318e1") || e.target.classList.contains("reset-3c756112") || e.target.classList.contains("reset-3c756112--tabInner-1de1736f")){ 
          let tabContent;
          let selectTabs = [];
          if(e.target.classList.contains("reset-3c756112--tabsItem-488dd212--UIH300-2063425d")){
            tabContent = e.target.children[1].children[0].children[0].textContent;
          }else if(e.target.classList.contains("text-4505230f--UIH300-2063425d--textContentFamily-49a318e1")){
            tabContent = e.target.textContent;
          }
          const  parentTab = this.getClosest(e.target, '.reset-3c756112--tabsHeaderContent-5e26ff2c');
          const selectTab = parentTab.querySelectorAll(".reset-3c756112--tabsItem-488dd212--UIH300-2063425d");
          const myNodeListAsArray = Array.prototype.slice.call(selectTab);
          myNodeListAsArray.forEach((tabs, index) => {
            selectTabs[index] = tabs.children[1].children[0].children[0].textContent;
          });
          const unSelectTab = parentTab.querySelector(".reset-3c756112--tabsItem-488dd212--tabsItemActive-7bf3ed3d--UIH300-2063425d");
          if(selectTabs.includes(tabContent)){
            const selectTabIndex = selectTabs.indexOf(tabContent);
            selectTab[selectTabIndex].classList.remove("reset-3c756112--tabsItem-488dd212--UIH300-2063425d")
            selectTab[selectTabIndex].classList.add("reset-3c756112--tabsItem-488dd212--tabsItemActive-7bf3ed3d--UIH300-2063425d")
            unSelectTab.classList.remove("reset-3c756112--tabsItem-488dd212--tabsItemActive-7bf3ed3d--UIH300-2063425d")
            unSelectTab.classList.add("reset-3c756112--tabsItem-488dd212--UIH300-2063425d")
            const  parentTabContent = this.getClosest(e.target, '.reset-3c756112--tabs-4505230f--tabs-ed73a86a');
            const selectTabContent = parentTabContent.querySelector(".reset-3c756112--tabsItemBody-2814c950--tabsItemBodyActive-41e173d5");
            const parentTabDiv = parentTabContent.children[1]
            const unSelectTabContent = parentTabDiv.querySelectorAll(".reset-3c756112--tabsItemBody-2814c950");
            selectTabContent.classList.remove("reset-3c756112--tabsItemBody-2814c950--tabsItemBodyActive-41e173d5")
            selectTabContent.classList.add("reset-3c756112--tabsItemBody-2814c950")
            unSelectTabContent[selectTabIndex].classList.remove("reset-3c756112--tabsItemBody-2814c950")
            unSelectTabContent[selectTabIndex].classList.add("reset-3c756112--tabsItemBody-2814c950--tabsItemBodyActive-41e173d5")
          }
        }
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    setTimeout(() => {
      this.imageZoom();
      this.RevisionLink();
      this.tabFunction();
    }, 10);
  }
  RevisionLink = () => {
    this.revRef.current && this.revRef.current.querySelectorAll('a') && this.revRef.current.querySelectorAll('a').forEach(item => {
        if(item.getAttribute('href') && (item.getAttribute('href')).indexOf('guide') == 1){
          revisionListener = item.addEventListener('click', event => {
            event.preventDefault();
            event.stopPropagation()
            // this.props.setRevisionLink(item.getAttribute('href'))
            return
          })
        }
    });
  }
  imageZoom = () => {
    this.revRef.current && this.revRef.current.querySelectorAll('img') && this.revRef.current.querySelectorAll('img').forEach(items => {
        if(items.getAttribute('src')){
          zoomListener = items.addEventListener('click', event => {
            this.setState({imageZoom :true, imageWidth: items.width, imageHeight: items.height})
            const modal = document.getElementById('myModal');
            const modalImg = document.getElementById("img01"); 
            modal.style.display = "flex";
            modalImg.src = items.getAttribute('src')
            modalImg.alt = "";
          })
        }
    });
  }
  closeZoom = () => {
    const modalImg = document.getElementById("img01");
    const modal = document.getElementById('myModal');
    modalImg.className += " out";
    this.setState({imageZoom : false})
    this.onReset()
    setTimeout(function() {
       modal.style.display = "none";
       modalImg.className = "modal-contents";
    }, 400);
  }

  componentWillUnmount(){
    this.revRef.current && this.revRef.current.removeEventListener("click", (e) =>{ });
    //revisionListener.removeEventListener("click", (e) =>{ });
    this.revisionScrollRef.current && this.revisionScrollRef.current.removeEventListener('scroll', this.handleScroll);
    let cardInfoSection = document.querySelector('.revision-wrapper').parentNode.children[0];
    let revisionBottomSection = document.querySelector('.revision-wrapper').children[1];
    cardInfoSection.classList.remove('is-cardInfo-scroll-down')
    revisionBottomSection.classList.remove('is-bottom-menu-scroll-down')
    clearAllBodyScrollLocks();
  }
  componentDidUpdate(prevProps) {
    if (this.props.flashcardId !== prevProps.flashcardId && this.props.flashcardId) {
      if(this.revisionRef && this.state.imageZoom){
        this.revisionRef.current.scrollTo(0, 0);
        this.closeZoom()
        this.zoomCanvas.current && this.zoomCanvas.current.wrappedInstance.initializeZoomArea();	
        this.onReset();	
      }
    }

  }


  onReset = () => {  	
    const {zoomLevel} = this.state;	
      this.setState({	
        zoomLevel: {	
          ...zoomLevel,	
          current: 1	
        },	
        ishasZoom: false,	
      })	
  }	

  onZoomIn = () => { 	
    const {zoomLevel} = this.state;	
    let finalZoomLevel = zoomLevel.current < 1.5 ? 1.5 : 2; 	
    if(zoomLevel.current < zoomLevel.max){	
      this.setState({	
        zoomLevel: {	
          ...zoomLevel,	
          current: finalZoomLevel	
        },        	
      })	
    }	
  }	
  onZoomOut = () => {	
    const {zoomLevel} = this.state;	
    let finalZoomLevel = zoomLevel.current > 1.5 ? 1.5 : 1;	
    if(zoomLevel.current > 1){	
      this.setState({	
        zoomLevel: {	
          ...zoomLevel,	
          current: finalZoomLevel	
        },	
      })	
    }	
  }	
  setZoomReset = (val) => {	
    this.setState({ishasZoom: val})	
  }	
  handleMapMove = (e) => {	
    if (e.touches && e.touches.length === 2) {	
      this.setState({	
            ishasZoom: true	
      });  	
    }	
  }	
  onChangeZoomRange = (e) => {	
    const {zoomLevel} = this.state;	
    this.setState({	
      zoomLevel: {	
        ...zoomLevel,	
        current: parseFloat(e.target.value)	
      },	
    }, () => {	
    })	
  }

  onImageClick = () =>{
    const {zoomLevel} = this.state;	
    if(zoomLevel.current == 1){
      this.setState({	
        zoomLevel: {	
          ...zoomLevel,	
          current: 1.5	
        },        	
      })	
    }else{
      this.setState({	
        zoomLevel: {	
          ...zoomLevel,	
          current: 1	
        },        	
      })	
    }
}

  render() {
   
    return (
      <React.Fragment>
        <div id="myModal" className={`${this.state.imageZoom && 'image-modal-zoom'} img-modal renderer-wrapper`}  onTouchStart={(e) => this.handleMapMove(e)}>	
            <React.Fragment>  	
              <div className={'zoom-close-section'}>	
                  <div className={'pin-close'} onClick={(e) => this.closeZoom()}>	
                    <ZoomClose /> 	
                  </div>  	
              </div>	
              <div className={'zoom-section'}>	
                {this.state.zoomLevel.current > 1 || this.state.ishasZoom ?  	
                  <div className={`pin-zoom ${this.isTouchDevice ? 'mobile-pin-zoom' :" "}`} onClick={(e) => this.onReset()}>	
                    <PinZoomIncrease /> 	
                  </div>  	
                : null} 	
                  <div className={'zoom-factor'}>	
                    <div className={`decrease-zoom ${this.state.zoomLevel.current === 1 ? 'remove-decrease-click' : '' }`} onClick={(e) => this.onZoomOut()}>	
                        <DecreaseFlashcardZoom />	
                    </div>	
                    <div className={'main-progress-zoom'}>	
                        <input className={'input-range'} type="range" min="1"  max="2" step="0.01" value={this.state.zoomLevel.current} onChange={(e) => this.onChangeZoomRange(e)}/>	
                    </div>	
                    <div className={`increase-zoom ${this.state.zoomLevel.current === 2 ? 'remove-increase-click' : '' }`} onClick={(e) => this.onZoomIn()}>	
                        <IncreaseFlashcardZoom />	
                    </div>	
                   </div>
                  
              </div>	
            </React.Fragment> 	
            
              <ZoomCanvas imageZoom={true} minZoom={null} width={this.state.imageWidth}	
                revisionGuide={true}
                ref={this.zoomCanvas} isMobile={this.props.isMobile} 	
                externalZoomFactor={this.state.zoomLevel.current} 	
                currentFlashcardId={this.props.currentFlashcardId}	
                isTouchDevice={this.isTouchDevice} 	
                isZoomed={this.state.ishasZoom} 	
                onActionFlashcard={()=>this.onImageClick()} 	
                canEditExercise={this.props.canEditExercise} 	
                resetZoom={this.setZoomReset} 	
                listFlashcardId={this.props.listFlashcardId}	
                selectedSide={this.props.selectedSide}>	             
                  <RendererArea width={this.state.imageWidth} isZoomed={this.state.zoomLevel.current>1}  >
                      <img className="modal-contents" id="img01" alt="" src={this.props.imageUrl}/>	
                  </RendererArea>	
              </ZoomCanvas> 
            
      </div>
      </React.Fragment>
    );
  }
}

RendererContainer.propTypes = {
  isMobile: PropTypes.bool,
};


export default RendererContainer;

