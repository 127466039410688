import React, { useEffect, useState } from "react";
import { Col, Space, Row, Button, Tabs } from "antd";
import { executeConnectionObject } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/firebaseHelper";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { isFirebaseAuthenticatedSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { useDispatch, useSelector } from "react-redux";
import { JSONTree } from "react-json-tree";
import { isEmpty } from "lodash";
import { MrSelect } from "mr_react_framework";
import { actions as genericActions } from "/src/App/genericRedux";
import SelectOrgDropdown from "/src/components/AppSpecific/SelectOrg/SelectOrgDropdown";
import AllDeviceInfos from "./AllDeviceInfos";

const { TabPane } = Tabs


const FbDashboard = () => {
  const [orgId, setOrgId] = useState("");
  const [studentId, setStudentId] = useState();
  const [experienceId, setExperienceId] = useState();
  const [userInfoData, setUserInfoData] = useState([]);
  const [userResponseData, setUserResponseData] = useState([]);
  const [allUserInfoData, setAllUserInfoData] = useState([]);
  const dispatch = useDispatch();
  let isFirebaseAuthenticated = useSelector(isFirebaseAuthenticatedSelector);

  // useEffect(() => {
  //   //   Need to change this token for superadmin
  //   dispatch(firestoreInteractionActions.getFirebaseToken({ experienceId: 649 }));
  // }, [dispatch]);
  useEffect(() => {
    //   Need to change this token for superadmin
    dispatch(firestoreInteractionActions.getFirebaseToken({ experienceId: experienceId }));
  }, [experienceId]);

  const SelectExperience = MrSelect({
    actions: genericActions,
    resourceName: "experiences",
    config: {
      url: "/experiences",
      processData: (data, props) => {
        return data.map((item) => {
          return { label: item.access_code + " (" + item.id + ") - " + item.name + " - " + item.status, value: item.id };
        });
      },
      searchApi: true,
      widgetConfig: {
        showSearch: true,
        filterOption: true,
        optionFilterProp: "label",
        title: "Select Experience",
        placeholder: "Select Experience",
        style: { width: "400px" },
        allowClear: true
      },
    }
  });

  const SelectStudent = MrSelect({
    config: {
      processData: (data, props) => {
        console.log("props select groups", data);
        return data.map((item) => {
          return { label: item.s_code + " (" + item.fbId + ") - " + item.name, value: item.fbId };
        });
      },
      searchApi: false,
      widgetConfig: {
        showSearch: true,
        filterOption: true,
        optionFilterProp: "label",
        placeholder: "Select Student",
        // mode: "multiple",
        style: { width: "250px" },
        allowClear: true
      },
    }
  });

  const onExperienceChange = (value) => {
    setExperienceId(value);
    setStudentId(null);
    executeConnectionObject(
      {
        collectionPath: `experiences/${value}/user_info`,
        // docId: `${userId}`,
      },
      { onlyBase: true, realtime: true },
      (data) => {
        const newArray = [];
        data.forEach((element) => {
          const data = {
            fbId: element.id,
            ...element.data()
          }
          newArray.push(data);
        });
        setAllUserInfoData(newArray);
      }
    );
    console.log("allUserInfoData", allUserInfoData)
  };

  const selectStudent = (id) => {
    setStudentId(id);
    const filteredStudent = allUserInfoData.filter((item) => item.fbId === id);
    setUserInfoData(filteredStudent[0]);
    executeConnectionObject(
      {
        collectionPath: `experiences/${experienceId}/user_responses`,
        // docId: `${studentId}`
      },
      {
        onlyBase: false,
        realtime: true,
        // limit: 1,
        params: { user_id: ["==", parseInt(id)] },
        // sortParams: { updated_at: "desc" },
      },
      (data) => {
        const newArray = [];
        data.forEach((element) => {
          newArray.push(element.data());
        });
        setUserResponseData(newArray);
      }
    );
  };

  const onRefresh = () => {
    executeConnectionObject(
      {
        collectionPath: `experiences/${experienceId}/user_info`,
        // docId: `${userId}`,
      },
      { onlyBase: true, realtime: true },
      (data) => {
        const newArray = [];
        data.forEach((element) => {
          const data = {
            fbId: element.id,
            ...element.data()
          }
          newArray.push(data);
        });
        setAllUserInfoData(newArray);
      }
    );
  }

  const selectExperienceConfig = {
    params: { by_org_id: orgId },
    // params: {by_org_id: orgId, by_status: 'published'},
  }
  const selectStudentConfig = {
    widgetConfig: {
      options: allUserInfoData
    }
  }
  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="School Wise User Info/Response" key="1">
          <Row>
            <Col span={24}>
              <Space>
                <h4>Firebase Dashboard</h4>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Space>
                <SelectOrgDropdown
                  onChange={(value) => setOrgId(value)}
                  config={{ widgetConfig: { style: { width: "250px" } } }}
                />
              </Space>
            </Col>
            <Col span={12}>
              <Space>
                {orgId && (
                  <SelectExperience
                    value={experienceId}
                    config={selectExperienceConfig}
                    onChange={(value) => onExperienceChange(value)}
                  />
                )}
              </Space>
            </Col>
            {isFirebaseAuthenticated ? (
              <Col span={6}>
                <Space>
                  {allUserInfoData.length !== 0 && (
                    <SelectStudent
                      value={studentId}
                      config={selectStudentConfig}
                      onChange={(value) => selectStudent(value)}
                    />
                  )}
                </Space>
              </Col>
            ) : (
              "Token not available yet"
            )}
            {/* <Col span={4}>
            {studentId && <Button
              type="primary"
              onClick={onRefresh}
            >
              Refresh
            </Button>}
          </Col> */}
          </Row>
          <Row>
            <Col span={12}>
              {!isEmpty(userInfoData) ? (
                <JSONTree data={userInfoData} invertTheme={false} />
              ) : null}
            </Col>
            <Col span={12}>
              {!isEmpty(userResponseData) ? (
                <JSONTree data={userResponseData} invertTheme={false} />
              ) : null}
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="All Device Infos" key="2">
          <AllDeviceInfos />
        </TabPane>
      </Tabs>

    </>
  );
};

export default FbDashboard;
