import { Button, Col, Dropdown, Form, Input, Row, Space } from "antd";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { InputArea } from "/src/components/UI/Segment/UIHelper";
import React from "react";
import { PlusCircleOutlined, MoreOutlined } from "@ant-design/icons";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import RubricLevels from "./RubricLevels";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const RubricCriteria = (props) => {
  console.log("RubricCriteria =====>", props);
  const { criteria, strand = {}, index, state, menuItem, customOnChange, addCriteria, createMode, addStrands, strandIndex = 0, type } = props;
  const mrIntl = useTranslate()
  // let title = strand.type === "strands" ? strand.title : criteria.title
  return (
    <div style={{ position: "relative" }} key={`criteria-${criteria.id}`}>
      <div className="criteria-wrapper">
        <div className={`criteria-container ${state.settings.one_level_for_all && index === 0 && "handle-criteria-header"}`}
        >
          <Space style={{ float: "right", marginRight: "-15px", marginTop: "-15px" }}>
            {createMode && type !== "strands" && <Dropdown overlay={menuItem("criteria", null, index)} trigger={["click"]} disabled={state.criteria.filter((item) => !item._destroy).length === 1}>
              <Button
                type="text"
                icon={<MoreOutlined />}
              />
            </Dropdown>}
          </Space>
          <Row>
            <Col span={4}>
              {createMode ? <Input
                key={`${type}-${criteria.id}`}
                placeholder={mrIntl("CommonText.title")}
                value={criteria.title}
                style={{ marginRight: "5px" }}
                disabled={true}
              >
              </Input> : <p>{criteria.title}</p>}
            </Col>
            <Col span={20}>
              {createMode ? <Form.Item
                name={`label-${type}-${criteria.id}`}
                rules={[{
                  required: criteria.label === "" ? true : false,
                  message: mrIntl("CommonText.required_msg")
                }]}
              >
                <Input
                  key={`label-${type}-${criteria.id}`}
                  placeholder={mrIntl("CommonText.label")}
                  value={criteria.label}
                  onChange={(e) => customOnChange(
                    e.target.value,
                    "label",
                    null,
                    index,
                    null,
                    type,
                    strandIndex
                  )}
                >
                </Input>
                {" "}
              </Form.Item> : <p>{criteria.label}</p>}
            </Col>
          </Row>
          {createMode && type !== "strands" ? <InputArea
            className="multiline-input-area"
            disabled={!createMode}
          >
            <BasicCKE
              placeholder={mrIntl("CommonText.description")}
              data={criteria.desc}
              onTextChanged={(data) => customOnChange(
                data,
                "desc",
                null,
                index,
                null,
                type,
                strandIndex
              )}
              className={"multiline-input-for-criteria"}
            >
            </BasicCKE>
          </InputArea> : <span className="multiline-input-for-criteria"><RenderHtml text={criteria.desc}/></span>}
        </div>
      </div>
      <Col>
        {createMode && type !== "strands" && <Button size="large" onClick={() => addCriteria(index, "criteria")} className="add-criteria-button" type="text" icon={<PlusCircleOutlined />}>
        </Button>}
        {createMode && state.settings.strands && <Button size="large" onClick={() => addStrands(strandIndex, index)} className="add-stands-button" type="text" icon={<PlusCircleOutlined />}>
        </Button>}
      </Col>
    </div>
  )
}

export default RubricCriteria