import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import ReactDOM from 'react-dom';

import { getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { Segment, SegmentData, TextArea } from '/src/components/UI/Segment/UIHelper'
import SegmentItemFooter from '/src/views/Segments/SegmentItemDetail/SegmentItemFooter'
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent"
import CKETextAnswer from "/src/views/Segments/CKETextContent/CKETextAnswer";
import { cloneDeep } from 'lodash';
import { getUniqueId } from "/src/lib/utils/helperMethods";
import MediaHoverButton from "/src/components/UI/Media/MediaHoverButton"
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";

const CKESubjective = (props) => {
  console.log("CKE Subjective props ==>", props);

  const { segmentMode, segmentSettings, showCorrectAnswerSwitch, triggerStateUpdate, annotationOptions, setRenderMath, attempt_status, segmentFooterAttributes, onlyExplanationMode, printMode, writingAssistantConfig, attachmentsCount } = props
  let { createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)
  let { ckeConfig = {}, numberOfLinesForAnswerSpace } = props

  let textAnswerString = props.text_answer || ''

  let parsedJSONObject
  if (createMode) {
    parsedJSONObject = props.value || ''
  } else {
    parsedJSONObject = props.text_answer
  }

  const enabledFeatures = useSelector(enabledFeaturesSelector())

  const [state, setState] = useState(parsedJSONObject)
  const [textAnswer, setTextAnswer] = useState(textAnswerString)
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
  })

  const screenSettings = props.screenSettings || {}
  const [triggerCKEDataUpdate, setTriggerCKEDataUpdate] = useState()

  let content
  if (presentationMode) {
    content = parsedJSONObject
  } else {
    content = state
  }

  ckeConfig = {
    ...ckeConfig,
    triggerCKEDataUpdate,
    customReactComponent: {
      audioPlayerRenderer: (src, domElement) => {
        console.log("cke audio player render with url ==>", src)
        ReactDOM.render(
          <audio class="cke-custom-audio-player" src={src} controls></audio>,
          domElement
        );
      }
    },
  }

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }, [])

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state)
        props.onChange(teacher_json)
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = cloneDeep(state)
        props.onChange(result)
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (newState) => {
    setState(newState)
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
  }

  const onTextChanged = (data) => {
    const newState = data
    saveState(newState)
  }

  const onSaveMedia = (files) => {
    if (files[0]) {
      const newState = state + `<audio class="cke-custom-audio-player" src="${files[0].url}" controls></audio>`
      saveState(newState)
      setTriggerCKEDataUpdate(getUniqueId())
    }
  }

  const toggleAnswer = () => {
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: !stateSetting.quickCheckModeEnabled,
    })
  }

  console.log('cke subjective state before render ==>', state, ckeConfig)
  return (
    <Segment>
      <SegmentData
        key={`segment-data-${props.unique_segment_identifier}`}
      >
        {createMode &&
          <TextArea>
            <CKETextContent
              ckeConfig={ckeConfig}
              value={content}
              onChange={(data) => onTextChanged(data)}
              writingAssistantConfig={writingAssistantConfig}
              extraClass = 'max-height-editor'
              editorFooterConfig={{
                visible: true,
              }}
            >
            </CKETextContent>
            <MediaHoverButton
              mediaButtonsConfig={{
                audio: true,
              }}
              onSaveMedia={(files) => onSaveMedia(files)}
            ></MediaHoverButton>
          </TextArea>
        }
        {((answerMode && segmentMode != "quick_check")
          || (presentationMode && autoCheckMode && !props.showCorrectAnswer))
          && <CKETextAnswer
            unique_segment_identifier={props.unique_segment_identifier}
            answerMode={answerMode}
            numberOfLinesForAnswerSpace={numberOfLinesForAnswerSpace}
            text_answer={content}
            ckeConfig={ckeConfig}
            attempt_status={attempt_status}
            presentationMode={presentationMode}
            onChange={(result) => saveState(result)}
            annotationOptions={annotationOptions}
            setRenderMath={setRenderMath}
            extraClass='max-height-editor'
            attachmentsCount={attachmentsCount}
            ></CKETextAnswer>
            }
      </SegmentData>

      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        toggleAnswer={toggleAnswer}
      >
      </SegmentItemFooter>
    </Segment>
  );
};
export default CKESubjective;