import { Spin } from "antd";
import { getFromLS, setToLS } from "/src/lib/utils/helperMethods";
import React, { useRef, useEffect } from "react";

const DesmosGraphing = (props) => {
  console.log("DesmosGraphing Props ==>", props);
  const { setCalculator, showLoading = false, styleCalc } = props;
  const desmosRef = useRef();
  const Desmos = window.Desmos;
  const desmosConfig = {
    images: false,
    folders: false,
    degreeMode: false,
  };

  useEffect(() => {
    if (Desmos) {
      var calculator = Desmos.GraphingCalculator(
        desmosRef.current,
        desmosConfig
      );
      const getPreviousData = getFromLS("graphingCalcData");
      const parseData = JSON.parse(getPreviousData);
      calculator.setState(parseData);
      calculator.observeEvent("change", function () {
        console.log("Change occurred");
        var graphingCalcData = calculator.getState();
        setToLS("graphingCalcData", JSON.stringify(graphingCalcData));
      });
      setCalculator(calculator);
      window.GraphingCalculator = calculator;
    }
  }, []);

  return (
    <Spin spinning={showLoading} size="large">
      <div className="graphing-calculator">
        <Spin size="large" />
        <div ref={desmosRef} className={styleCalc}></div>
      </div>
    </Spin>
  );
};

export default DesmosGraphing;
