import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  withRouter,
  Route,
  Switch,
  Redirect,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { Breadcrumb, List, Card, Pagination, Modal, Button } from "antd";
import styled, { ThemeProvider } from "styled-components";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

// import { SampleComponent } from 'mr_react_framework';
defineMessages({
  search: {
    id: "general.search",
    defaultMessage: "Search"
  }
});
export const CYButton = styled(Button)`
  background: ${props1 => props1.theme.button.background};
  color: ${props1 => props1.theme.main.colors.primary};
`;
const OrgsLayout = props => {
  const {
    resourceName,
    singleResourceName,
    MrForm,
    filter,
    forms,
    MrFilter,
    displayName,
    resourceForm,
    CustomListItem,
    ActionBtns,
    params,
    setParams,
    showFormVal,
    showForm,
    loading,
    item,
    handleOk,
    handleCancel,
    refresh,
    dataSource,
    pagination,
    onPageChange,
    edited,
    deleted,
    permission,
    currentUser,
    ListHeader,
    resourceUrl,
    parentInstance,
    hocProps
  } = props;
  let isRoute = false;
  if (hocProps.isRoute && props.isRoute) {
    isRoute = true;
  }

  console.log("layout isRoute", isRoute);

  let toRender;
  let content = [];
  let match = useRouteMatch();
  const mrIntl = useTranslate();

  let visible = false;

  console.log("resourceName, showFormVal", resourceName, showFormVal);
  if (showFormVal === resourceName) {
    visible = true;
  }
  const WrappedMrForm = MrForm({
    currentUser,
    permission
  });
  // const WrappedMrForm = this.mrForm;
  let extraFilters = [];

  if (filter) {
    extraFilters = filter({
      ...params,
      changed: setParams
    });
  }

  const Filter = MrFilter({
    name: displayName,
    singleResourceName
    // extraFilters
  });
  const intl = useIntl();
  // const intl = {
  //   formatMessage: () => {}
  // }
  const formObj = resourceForm.form({
    intl,
    currentUser,
    permission
  });
  let form = (
    // <WrappedMrForm
    //   isModal
    //   fields={resourceForm.form(intl)}
    //   visible={visible}
    //   displayName={displayName}
    //   loading={loading}
    //   item={item}
    //   handleOk={handleOk}
    //   handleCancel={handleCancel}
    // />
    <WrappedMrForm
      isModal
      schema={formObj.schema}
      jsonForm={formObj}
      noHtml5Validate={true}
      onSubmit={form => {
        console.log("form", form);
      }}
      // fields={resourceForm.form(intl)}
      visible={visible}
      displayName={displayName}
      loading={loading}
      item={item}
      handleOk={handleOk}
      handleCancel={handleCancel}
      onError={error => {
        console.log("errors", error);
      }}
      formData={item}
    />
  );

  content.push(form);
  let createBtn = null;
  console.log("permission", permission);
  console.log("singleResourceName", singleResourceName);

  if (permission[singleResourceName].create) {
    createBtn = (
      <CYButton
        key={"createBtn"}
        onClick={() => showForm({ name: resourceName })}
        icon={"plus"}
      >
        <FormattedMessage
          id="general.create"
          defaultMessage= {mrIntl("CommonText.create")}
          // defaultMessage={`{text}`}
          // description="Create"
          // values={{ text: "Create" }}
        />
      </CYButton>
    );
    // content.push(createBtn);
  }
  if (isRoute) {
    content.push(
      <Filter
        {...params}
        changed={setParams}
        refresh={refresh}
        createBtn={createBtn}
        // create={() => this.showForm({ name: resourceName })}
      />
    );
  }
  const listItemProps = {
    edited,
    deleted,
    currentUser,
    permission,
    parentInstance,
    hocProps
  };
  let list = (
    <List
      key={"list"}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 1,
        lg: 1,
        xl: 1,
        xxl: 1
      }}
      dataSource={dataSource}
      renderItem={item => (
        <CustomListItem
          className={"custom-list-item"}
          item={item}
          resourceUrl={`${resourceUrl}/${item.id}`}
          actionBtns={
            <ActionBtns
              edit={permission[singleResourceName].update}
              delete={permission[singleResourceName].delete}
              edited={edited}
              deleted={deleted}
              name={resourceName}
              item={item}
            />
          }
          name={resourceName}
          {...listItemProps}
        />
      )}
    />
  );
  console.log("permission", permission);
  content.push(ListHeader);
  content.push(list);
  console.log("pagination", pagination);
  // content.push(pagination.totalPages);
  if (hocProps.isRoute) {
    content.push(
      <Pagination
        // showQuickJumper
        total={pagination.totalCount}
        pageSize={pagination.pageSize}
        // showTotal={total => `Total ${total} items`}
        onChange={onPageChange}
        current={pagination.page}
        // defaultCurrent={params.page}
      />
    );
  }
  let breadcrumb = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <a href="">Home</a>
      </Breadcrumb.Item>
      <Breadcrumb.Item>{displayName}</Breadcrumb.Item>
    </Breadcrumb>
  );
  toRender = content;
  return toRender;
};
OrgsLayout.defaultProps = {
  isRoute: true,
  item: {}
};
export default OrgsLayout;
