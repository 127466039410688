import { fromJS } from "immutable";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { MrReduxCrud, formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import _ from "lodash";


const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "librarysegment",
  singleResourceName: "segment",
  apiUrl: "/segments",
  actionNames: ["FETCH", "CREATE", "SHOW", "UPDATE", "DELETE", "REARRANGE"],
});

const initialState = fromJS({
  segments: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

const successFunc = (state, action) => {
  // console.log("action data", action);
  console.log("action, state", action, state.get("segments"));
  let stateTopics = state.get("segments");
  let topics = [...stateTopics];
  let payload = action.payload;
  console.log("action.payload", action.payload);


  const elmToMove = topics.splice(action.payload.sourceIdx, 1)[0];
  console.log('on drag end result elmToMove ==>', elmToMove);
  topics.splice(action.payload.destinationIdx, 0, elmToMove);
  // if(action.payload.callback){
  //   action.payload.callback(topics);
  // }
  console.log(" state after save", topics);
  return state.set("segments", topics)

}
function* rearrangeTopicSaga(action){

  try{
    yield put(actions.rearrangeSuccess({
      ...action.payload
    }));
    // const fullState = yield select(state => state);
    // console.log("fullState", fullState);
    const topics = yield select(state => state.topics.get("segments"));
    console.log("saga topics", topics);
    if(action.payload.callback){
      yield call(action.payload.callback, topics);
    }

  } catch(e) {
    yield put(actions.rearrangeFail({
      ...action.payload
    }));
  }

}

const fetchSuccessFunc = (state, action) => {
  console.log("librarysegments fetchSuccess action, state", action, state.get("segments"));
  const data = action.payload.data || {};

  let formattedSegments = formatJsonApiData(data.segments)
  let l1Segments = [...formattedSegments]
  let allDescendants = []
  l1Segments.forEach((segment, i) => {
    segment.nesting_level = 1
    if(segment.descendants.length > 0){
      allDescendants.push(segment.descendants)
    }
  });

  // console.log("librarySegments fetchSuccess", l1Segments, allDescendants)
  let allSegments = _.concat(l1Segments, _.flatten(allDescendants))

  let newState = state.set("segments", allSegments).set("page", data.page).set("page_size", data.page_size).set("total_count", data.total_count).set("total_pages", data.total_pages)
  // let newState = state.set("segments", l1Segments).set("descendants", _.flatten(allDescendants))
  
  // console.log("librarySegments fetchSuccess", data.segments, formatJsonApiData(data.segments), newState.get("segments"));
  newState = newState.set("firstAction", true).set("loading", false).set("fetchloading", false)
  return newState

}


export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  FETCH_LIBRARYSEGMENT_SUCCESS: fetchSuccessFunc
});
export const watchLibrarysegments = reduxCrud.generateWatchSaga();

export default reduxCrud;
