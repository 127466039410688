import React from "react";
import { Button, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const SegmentLogInfo = ({ itemId, setIsLogModalOpen }) => {
  const history = useHistory();
  const mrIntl = useTranslate()
  const { url } = useRouteMatch();
  let currentSegment = useSelector((state) => {
    const allSegments = state.segments.get("segments");
    return allSegments.filter((item) => item.id === itemId)[0];
  });

  let finalContent = "";
  if (currentSegment) {
    if (currentSegment.question_segment_attributes) {
      finalContent = currentSegment.question_segment_attributes.content;
    } else if (currentSegment.text_segment_attributes) {
      finalContent = currentSegment.text_segment_attributes.content;
    } else if (
      currentSegment.resource_segment_attributes &&
      currentSegment.resource_segment_attributes.title
    ) {
      finalContent = currentSegment.resource_segment_attributes.title;
    } else {
      finalContent = "";
    }
  } else {
    finalContent = "This segment was archived";
  }

  const handleOnClick = () => {
    history.push(
      `${url}/topics/${currentSegment.parent_id}#item-${currentSegment.id}`
    );
    setIsLogModalOpen(false);
  };

  const style = currentSegment && { color: "black", width: "100px" };

  return (
    <>
      <Popover
        content={
          <>
            <RenderHtml style={style} text={finalContent} truncate={true} />

            {currentSegment && (
              <Button type="link" onClick={() => handleOnClick()}>
                <MrTranslate id={"SegmentLogInfo.go_to_segment"}/>
              </Button>
            )}
          </>
        }
      >
        <span>
          <InfoCircleOutlined />
        </span>
      </Popover>
    </>
  );
};

export default SegmentLogInfo;
