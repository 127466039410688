import React, { useState } from "react";
import {
  Button,
  Modal,
  Row,
  Select,
  Input,
  Space,
  message,
  Spin,
  Result,
  Tooltip,
} from "antd";
import { TranslationOutlined, DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { availableLanguageOptionsForAI } from "/src/lib/utils/helperMethods";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { currentUserSelector } from "../../Auth/Login/selector";
import { showExperienceHelper } from "../../Experiences/ExperienceHelperMethods";

const TranslateAssessment = (props) => {
  const { isLibrary = false, renderInExperienceAction = false, item } = props;

  const { uid: id, name } = item;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const mrIntl = useTranslate();
  const [language, setLanguage] = useState("english");
  const [dupComplete, setDupComplete] = useState(false);
  const [newExperience, setNewExperience] = useState({});
  const [assessmentName, setAssessmentName] = useState(`${name}(${language})`);
  const currentUser = useSelector(currentUserSelector());
  const history = useHistory();

  const languageOptions = availableLanguageOptionsForAI.map((language) => ({
    value: language.toLowerCase(),
    label: language,
  }));

  const resetStatesOnClose = () => {
    setIsModalOpen(false);
    setDupComplete(false);
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();
  let contentCompleteExtraArr = [];

  const openAssessment = () => {
    showExperienceHelper(
      newExperience,
      props.resourceName,
      null,
      currentUser,
      history,
      dispatch
    );
    setDupComplete(false);
    setIsModalOpen(false);
  };
  contentCompleteExtraArr.push(
    <Link to={`/e/tests/${newExperience.uid}`} onClick={openAssessment}>
      <Button key="my-assessments" type="primary">
        {mrIntl("TranslateAssessment.go_to_assessment")}
      </Button>
    </Link>
  );
  contentCompleteExtraArr.push(
    <Button key="close" onClick={resetStatesOnClose}>
      {mrIntl("TranslateAssessment.close")}
    </Button>
  );

  let contentComplete = (
    <Result
      status="success"
      title={mrIntl("TranslateAssessment.success_title")}
      subTitle={mrIntl("TranslateAssessment.success_subtitle")}
      extra={contentCompleteExtraArr}
    />
  );

  let contentInProgress = (
    <div
      style={{ textAlign: "center", padding: "5px" }}
      className="duplicate-loading"
    >
      <Spin size="large" />
      <br />
      <br />
      {mrIntl("TranslateAssessment.translating")}
    </div>
  );

  const extra = {
    translate_to: language,
    duplicate_assessment_name: assessmentName,
  };

  const handleTranslate = () => {
    setLoading(true);
    dispatch(
      experienceReduxActions.duplicate(
        { uid: id, id: id, ...extra },
        {
          duplicateAtIndex: 0,
          success: {
            showMessage: true,
            message: mrIntl("TranslateAssessment.success_message"),
          },
          error: { showMessage: false },
          successCallback: (data) => {
            console.log("duplicate done", data);
            setDupComplete(true);
            setLoading(false);
            setNewExperience(data.data.experience.data.attributes);
          },
          errorCallback: () => {
            message.error(mrIntl("TranslateAssessment.error_message"));
            setLoading(false);
          },
        }
      )
    );
  };

  const modalTitle = (
    <Space>
      {mrIntl("TranslateAssessment.modal_title")}
      <Tooltip title={mrIntl("TranslateAssessment.canvas_questions_are_partially_translated")}>
        <InfoCircleOutlined style={{ color: "#34A6BD" }} />
      </Tooltip>
    </Space>
  );
  const modalContent = (
    <Space direction="vertical">
      <Row justify="center">
        <h3>
          <TranslationOutlined /> {mrIntl("TranslateAssessment.create_copy")}
        </h3>
        <Space size="middle" style={{ width: "60%" }} direction="vertical">
          <Input
            style={{ width: "100%" }}
            placeholder={mrIntl("TranslateAssessment.assessment_name")}
            value={assessmentName}
            onChange={(e) => {
              setAssessmentName(e.target.value);
            }}
            disabled={loading}
          />
          <Select
            showSearch
            style={{ width: "100%" }}
            options={languageOptions}
            value={language}
            disabled={loading}
            suffixIcon={<DownOutlined />}
            onChange={(value) => {
              setLanguage(value);
              setAssessmentName((prevName) => {
                const nameWithoutLang = prevName.replace(/\(.*\)$/, '').trim();
                return `${nameWithoutLang} (${value})`;
              });
            }}
          />
        </Space>
      </Row>
      <Row className="m-t-20" justify={"center"}>
        <Space>
          <Button type="primary" loading={loading} onClick={handleTranslate}>
            {mrIntl("TranslateAssessment.translate_button")}
          </Button>
        </Space>
      </Row>
    </Space>
  );

  return (
    <>
      <Button
        type={isLibrary ? "primary" : "text"}
        onClick={() => setIsModalOpen(true)}
        icon={<TranslationOutlined />}
        className={
          isLibrary
            ? ""
            : renderInExperienceAction && "actions-translate-button full-width-action-button"
        }
        shape={isLibrary ? "round" : "default"}
     
      >
        {mrIntl("TranslateAssessment.translate")}
      </Button>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        footer={null}
        onCancel={resetStatesOnClose}
        className="translate-assessment-modal"
      >
        {loading
          ? contentInProgress
          : dupComplete
          ? contentComplete
          : modalContent}
      </Modal>
    </>
  );
};

export default TranslateAssessment;
