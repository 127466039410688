import React, { useEffect, useRef, useState } from "react";

import { RBDDroppableArea } from '/src/components/UI/DnD/RBDDroppableArea'
import { DnDDroppableTitle } from '/src/components/UI/DnD/DnDDroppableTitle'
import { DnDTabularTextBoxes } from '/src/components/UI/DnD/DnDTabularTextBoxes'
import { DnDInlineTextBoxes } from '/src/components/UI/DnD/DnDInlineTextBoxes'
import { DragDropContext } from 'react-beautiful-dnd';
import { updateOnDragEnd, useClickAndClickSensor } from '/src/views/Segments/DnDHelpers'
import { getArrayOfList, addListToArray, removeItemFromArray, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { Segment, SegmentData, HorizontalDroppableContainer, VerticalDroppableContainer, InlineDroppablesContainer, InlineDroppable } from '/src/components/UI/Segment/UIHelper'
import { cloneDeep, shuffle } from 'lodash';
import SegmentItemFooter from '/src/views/Segments/SegmentItemDetail/SegmentItemFooter'
import SegmentItemHeader from '/src/views/Segments/SegmentItemDetail/SegmentItemHeader'
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const RBDInlineSorting = props => {
	console.log("Sorting props", props);
	const { question_type, showCorrectAnswerSwitch, segmentSettings, triggerStateUpdate, setRenderMath, segmentFooterAttributes } = props
	let { parsedJSONObject, createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)
  const mrIntl= useTranslate()
	let textAnswerString = props.text_answer || ''
	const propsAttemptStatus = props.attempt_status || {}
	let attemptStatusObject = {
		reset_count: propsAttemptStatus.reset_count || 0,
		json_attempted: propsAttemptStatus.json_attempted || false,
	}

	const extra = {
		titeRequired: false,
		titlePrefix: 'Column',
		namePrefix: 'Column',
		placeholderPrefix: 'Column',
		colSize: 0,
		itemConfig: {
			contentRequired: false,
			contentPrefix: 'Item',
			placeholderPrefix: mrIntl("RBDInlineSorting.item"),
		}
	}
	if (createMode) {
		parsedJSONObject = {
			segment_data: {
				columns: getArrayOfList(2, 1, extra),
				possible_responses: {
					id: getUniqueId(),
					title: "Possible Responses",
					items: []
				}
			},
			segment_version: '1.0.0'
		}
		if (question_type == 'rbd_inline_sorting') {
			parsedJSONObject = props.value || parsedJSONObject
		}
	}

	if (answerMode) {
		parsedJSONObject = shuffleItems(parsedJSONObject)
	}

	if (autoCheckMode) {
		let question_json = cloneDeep(props.teacher_json)
		let answer_json = cloneDeep(parsedJSONObject)
		parsedJSONObject = checkResponses(question_json, answer_json)
	}

	const [state, setState] = useState(parsedJSONObject)
	const [textAnswer, setTextAnswer] = useState(textAnswerString)
	const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject)
	const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
	const [stateSetting, setStateSetting] = useState({
		autoCheckMode: autoCheckMode,
		quickCheckModeEnabled: false,
		showCorrectAnswerSwitch: showCorrectAnswerSwitch,
		showCorrectAnswerSwitchValue: false,
	})
	const [myAnswer, setMyAnswer] = useState(state)
	const screenSettings = props.screenSettings || {}

	let currentJSON, text_answer, attempt_status
	if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
		currentJSON = parsedJSONObject
		text_answer = textAnswerString
		attempt_status = attemptStatusObject
	} else {
		currentJSON = state
		text_answer = textAnswer
		attempt_status = attemptStatus
	}

	const segment_data = currentJSON?.segment_data || {}
	const columns = segment_data.columns || []
	const possible_responses = segment_data.possible_responses || []
	const distractors = segment_data.distractors || []

	useEffect(() => {
		if (setRenderMath) {
			setRenderMath(getUniqueId())
		}
	}, [])

	useEffect(() => {
		if (createMode) {
			if (props.onChange) {
				let teacher_json = cloneDeep(state)
				props.onChange(teacher_json)
			}
		} else if (answerMode && stateUpdatedCount > 0) {
			if (props.onChange) {
				let result = {
					response_json: cloneDeep(state),
					// text_answer: cloneDeep(textAnswer),
					attempt_status: cloneDeep(attemptStatus)
				}

				props.onChange(result)
			}
		}
	}, [stateUpdatedCount]);

	const saveState = (state) => {
		setState(state)
		setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
	}

	const saveTextAnswer = (data) => {
		setTextAnswer(data)
		setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
	}

	function checkResponses(question_json, answer_json) {
		console.log('Question and Answer json', question_json, answer_json)
		if (!question_json || !answer_json) {
			return
		}
		let q_segment_data = question_json.segment_data
		let a_segment_data = answer_json.segment_data

		let q_columns = q_segment_data ? q_segment_data.columns : []
		let a_columns = a_segment_data ? a_segment_data.columns : []
		let i = 0;
		while (i < q_columns.length) {
			let q_items = q_columns ? q_columns[i].items : []
			let a_items = a_columns ? a_columns[i].items : []

			if (a_items && a_items[0]) {
				if (a_items[0].id == q_items[0].id) {
					a_items[0].correct = true
				} else {
					a_items[0].correct = false
				}
			}
			i++
		}
		return answer_json
	}

	function shuffleItems(json) {
		const segment_data = json.segment_data || {}
		const possible_responses = segment_data.possible_responses || {}
		possible_responses.items = shuffle(possible_responses.items)

		return json
	}

	function validateAttempted(json) {
		let initial_possible_responses_items = props.student_json.segment_data.possible_responses.items
		let current_possible_responses_items = json.segment_data.possible_responses.items

		let isAtleastOneDropped = false
		if (current_possible_responses_items.length != initial_possible_responses_items.length) {
			isAtleastOneDropped = true
		}

		console.log("validate attempt status ==>", attemptStatus, isAtleastOneDropped)
		if (isAtleastOneDropped != attemptStatus.json_attempted) {
			setAttemptStatus({
				...attemptStatus,
				json_attempted: isAtleastOneDropped
			})
		}
	}

	const onDragEnd = (result) => {
		const dragDropConfig = {
			updateType: 'replace',
			append: true,
			limit: 1
		}
		const newSegmentData = updateOnDragEnd(result, state, dragDropConfig);

		if (!newSegmentData) {
			return
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		if (answerMode) {
			validateAttempted(newState)
		}

		console.log('on drag end data ==>', newSegmentData)
		saveState(newState)
		if (setRenderMath) {
			setRenderMath(getUniqueId())
		}
	}

	const onTabDragEnd = (columns) => {
		const newColumns = columns.slice()

		const newSegmentData = {
			...segment_data,
			columns: newColumns
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const addColumn = () => {
		const newExtra = {
			...extra,
			colSize: columns.length,
		}
		const newColumns = addListToArray(columns, newExtra)

		const newSegmentData = {
			...segment_data,
			columns: newColumns
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const removeColumn = (index) => {
		const newColumns = removeItemFromArray(columns, index);

		const newSegmentData = {
			...segment_data,
			columns: newColumns
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const removeFile = (type, index, childIndex) => {
		const newColumns = columns
		const newDistractors = distractors

		if (type == "column") {
			if (childIndex >= 0) {
				delete newColumns[index].items[childIndex].files
			} else {
				delete newColumns[index].files
			}
		} else if (type == "distractor") {
			delete newDistractors[index].files
		}

		const newSegmentData = {
			...segment_data,
			columns: newColumns,
			distractors: newDistractors
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const onTabEdit = (index, action) => {
		if (action == 'add') {
			addColumn()
		} else if (action == 'remove') {
			removeColumn(index)
		}
	}

	const onSaveMedia = (type, index, files, childIndex) => {
		const newColumns = columns
		const newDistractors = distractors

		if (type == "column") {
			if (childIndex >= 0) {
				newColumns[index].items[childIndex].files = files
			} else {
				newColumns[index].files = files
			}
		} else if (type == "distractor") {
			newDistractors[index].files = files
		}

		const newSegmentData = {
			...segment_data,
			columns: newColumns,
			distractors: newDistractors
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const onTextChanged = (type, index, data, childIndex) => {
		const newColumns = columns
		const newDistractors = distractors

		if (type == 'column') {
			if (childIndex >= 0) {
				newColumns[index].items[childIndex].content = data
			} else {
				newColumns[index].title = data
			}
		} else if (type == 'distractor') {
			newDistractors[index].content = data
		}

		const newSegmentData = {
			...segment_data,
			columns: newColumns,
			distractors: newDistractors
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

  const checkAnswer = (check = false) => {
    if (check) {
      let question_json = cloneDeep(props.teacher_json);
      let answer_json = cloneDeep(state);
      const newState = checkResponses(question_json, answer_json);

      setMyAnswer(state);
      saveState(newState);
    }

    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: check,
    });

    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

	const showCorrectAnswer = (value) => {
		console.log("Value in switch ==>", value)
		let question_json, answer_json
		if (value) {
			question_json = cloneDeep(props.teacher_json)
			answer_json = cloneDeep(props.teacher_json)
		} else {
			question_json = cloneDeep(props.teacher_json)
			answer_json = cloneDeep(myAnswer)
		}

		const newState = checkResponses(question_json, answer_json)
		saveState(newState)
		setStateSetting({
			...stateSetting,
			quickCheckModeEnabled: true,
			showCorrectAnswerSwitchValue: value
		})
		if (setRenderMath) {
			setRenderMath(getUniqueId())
		}
	}

	const resetQuestion = () => {
		let student_json = cloneDeep(props.student_json)
		const newState = shuffleItems(student_json)

		setAttemptStatus({
			...attemptStatus,
			reset_count: (attemptStatus.reset_count + 1),
			json_attempted: false,
		})
		saveState(newState)
		setStateSetting({
			...stateSetting,
			quickCheckModeEnabled: false,
		})
		if (setRenderMath) {
			setRenderMath(getUniqueId())
		}
	}

  const sensorAPI = useRef()
  const liftedItem = useRef()
  const clickAndClickSensor = useClickAndClickSensor((api) => { sensorAPI.current = api }, liftedItem)

	console.log('State before render ==>', state, stateSetting.quickCheckModeEnabled)
	return (
		<Segment className="segment-sort-inline">
			{/* <SegmentItemHeader
				segmentStateSettings={stateSetting}
				showCorrectAnswer={(value) => showCorrectAnswer(value)}
			>
			</SegmentItemHeader> */}

			<SegmentData
				key={`segment-data-${props.unique_segment_identifier}`}
			>
				{createMode ? <div>
					<DnDTabularTextBoxes
						tabPrefix={mrIntl("RBDInlineSorting.item")}
						// titleLabel="Item"
						// itemsLabel="Item"
						editableTitle={false}
						columns={columns}
						onTabDragEnd={onTabDragEnd}
						onTabEdit={onTabEdit}
						onTextChanged={onTextChanged}
						removeFile={removeFile}
						onSaveMedia={onSaveMedia}
					></DnDTabularTextBoxes>

					{/* <DnDInlineTextBoxes
						tabPrefix="Item"
						titleLabel="Item"
            itemsLabel="Item"
						editableTitle={false}
						columns={columns}
						addColumn={addColumn}
						removeColumn={removeColumn}
						onTextChanged={onTextChanged}
						removeFile={removeFile}
						onSaveMedia={onSaveMedia}
					></DnDInlineTextBoxes> */}

				</div>
					: <DragDropContext
						key={`drag-drop-context-${props.unique_segment_identifier}`}
						onDragEnd={onDragEnd}
            sensors={[clickAndClickSensor]}
            >
						<InlineDroppablesContainer
							key={`inline-droppables-container-${props.unique_segment_identifier}`}
							style={screenSettings.isMobile ? {
								maxHeight: '500px',
								overflow: 'auto'
							} : {
									maxHeight: '100%',
								}}
						>
							{columns.map((column, index) => (
								<InlineDroppable
									key={`inline-droppable-${column.id}`}
									style={screenSettings.isMobile ? {
										width: '50%',
										minHeight: '200px'
									} : {
											width: '25%',
										}
									}
								>
									<DnDDroppableTitle
										list={{
											...column,
											title: `Item ${index + 1}`
										}}
									></DnDDroppableTitle>
									<VerticalDroppableContainer
										key={`vertical-droppable-container-${column.id}`}
										className="sorting-vertical-droppable-container"
									>
										<RBDDroppableArea
											key={`rbd-droppable-area-${column.id}`}
											droppableProps={{
												list: column,
												type: 'DEFAULT',
												// darkBackground: true,
												droppableId: column.id,
											}}
											draggableProps={{
												list: column,
												hidePlaceholder: true,
												stretchHeight: true,
												draggingDisabled: (!answerMode || stateSetting.quickCheckModeEnabled) ? true : false,
												// draggingDisabledIndexArray: [0],
												showCorrect: (autoCheckMode || stateSetting.quickCheckModeEnabled),
                        liftedItem: liftedItem,
                        sensorAPI: sensorAPI
											}}
										/>

									</VerticalDroppableContainer>
								</InlineDroppable>
							))}
						</InlineDroppablesContainer>
						{(answerMode || presentationMode) && <HorizontalDroppableContainer>
							{(possible_responses && possible_responses.id) && <RBDDroppableArea
								key={`rbd-droppable-area-${possible_responses.id}`}
								droppableProps={{
									list: possible_responses,
									type: 'DEFAULT',
									droppableId: possible_responses.id,
									style: screenSettings.isMobile ? {
										whiteSpace: 'nowrap',
									} : {
											// 'max-height': '100%',
										}
								}}
								draggableProps={{
									list: possible_responses,
									direction: 'horizontal',
									hidePlaceholder: true,
									draggingDisabled: (!answerMode || stateSetting.quickCheckModeEnabled) ? true : false,
                  liftedItem: liftedItem,
                  sensorAPI: sensorAPI
								}}
							/>}
						</HorizontalDroppableContainer>}
					</DragDropContext>}
			</SegmentData>

			<SegmentItemFooter
				createMode={createMode}
				answerMode={answerMode}
				presentationMode={presentationMode}
				segmentFooterAttributes={segmentFooterAttributes}
				segmentSettings={segmentSettings}
				segmentStateSettings={stateSetting}
				screenSettings={screenSettings}
				setRenderMath={setRenderMath}
				saveTextAnswer={saveTextAnswer}
				resetQuestion={resetQuestion}
				checkAnswer={checkAnswer}
				showCorrectAnswer={showCorrectAnswer}
			>
			</SegmentItemFooter>
		</Segment>
	);
};
export default RBDInlineSorting;

