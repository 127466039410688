import React, { useEffect, useState } from "react";
// import GGBApplet from "./deployggb";
// import { actions } from "./redux";
import { Row, Col, Button, Switch } from "antd";
import styled from 'styled-components'
import { RBDDroppableArea } from '/src/components/UI/DnD/RBDDroppableArea'
import { RBDDroppableBox } from '/src/components/UI/DnD/RBDDroppableBox'
import { DragDropContext } from 'react-beautiful-dnd';
import { updateOnDragEnd } from '/src/views/Segments/DnDHelpers'
import { initialData, addItemToArray, removeItemFromArray, convertDataAsPerNewSystem, getParsedJSONObject, numaricalSort } from "/src/views/Segments/InteractiveHelpers";
import { AddButton, CheckAnswerButton, QuickCheckBox, TryAgainButton, Segment, SegmentData, SegmentSetting, HorizontalDroppableContainer, DragDropArea, VerticalDroppableContainer } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { cloneDeep } from 'lodash';
import SegmentItemFooter from '/src/views/Segments/SegmentItemDetail/SegmentItemFooter'
import SegmentItemHeader from '/src/views/Segments/SegmentItemDetail/SegmentItemHeader'
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const RBDMatching = props => {
	console.log("RBDMatching props", props);

	let { parsedJSONObject, createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)

	if (createMode) {
		parsedJSONObject = props.value || initialData('matching', 2, 4);
	}

	const [quickCheckModeEnabled, setQuickCheckModeEnabled] = useState(false)
	if (props.autoCheckMode) {
		let question_json = convertDataAsPerNewSystem(props.teacher_json, props)
		let answer_json = parsedJSONObject
		parsedJSONObject = checkResponses(question_json, answer_json)
	}

	const [state, setState] = useState(parsedJSONObject)
	useEffect(() => {
		if (props.answerMode) {
		}

		if (props.presentationMode) {
		}

		console.log('Updated data ==>', parsedJSONObject)
		setState(parsedJSONObject)
	}, [])

	const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
	useEffect(() => {
		if (stateUpdatedCount > 0) {
			if (createMode) {
				if (props.onChange) {
					let teacher_json = cloneDeep(state)
					props.onChange(teacher_json)
				}
			} else if (answerMode && stateUpdatedCount > 0) {
				if (props.onChange) {
					let response_json = state
					props.onChange(response_json)
				}
			}
		}
	}, [stateUpdatedCount]);

	const saveState = (state) => {
		setState(state)
		setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
	}

	console.log('State in start ==>', state)

	let segment_data = state.segment_data

	let vertical_lists = segment_data ? segment_data.vertical_lists ? segment_data.vertical_lists : {} : {}
	let horizontal_lists = segment_data ? segment_data.horizontal_lists ? segment_data.horizontal_lists : {} : {}

	const [stateSetting, setStateSetting] = useState({
		quickCheckModeEnabled: false,
	})

	function checkResponses(question_json, answer_json) {
		console.log('Question and Answer json', question_json, answer_json)
		if (!question_json || !answer_json) {
			return
		}
		let q_segment_data = question_json.segment_data
		let a_segment_data = answer_json.segment_data

		let q_vertical_lists = q_segment_data ? q_segment_data.vertical_lists : {}
		let a_vertical_lists = a_segment_data ? a_segment_data.vertical_lists : {}

		let q_list = q_vertical_lists ? q_vertical_lists[1] : {}
		let a_list = a_vertical_lists ? a_vertical_lists[1] : {}

		let q_items = q_list.items
		let a_items = a_list.items

		if (a_items) {
			let i = 0;
			while (i < a_items.length) {
				if (q_items[i].id == a_items[i].id) {
					a_items[i].correct = true
				} else {
					a_items[i].correct = false
				}
				i++
			}
		}
		return answer_json
	}

	function onDragEnd(result) {
		const dragDropConfig = {
			updateType: 'replace',
			append: true
		}
		const newSegmentData = updateOnDragEnd(result, state, dragDropConfig);

		if (!newSegmentData) {
			return
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log('on drag end data ==>', newSegmentData)
		saveState(newState)
	}

	function addItem(keyName) {
		let items1 = vertical_lists[0].items;
		let items2 = vertical_lists[1].items;
		items1 = addItemToArray(items1);
		items2 = addItemToArray(items2);

		const newSegmentData = {
			...segment_data,
			vertical_lists: vertical_lists
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	function removeItem(keyName, index) {
		let items1 = vertical_lists[0].items;
		let items2 = vertical_lists[1].items;
		items1 = removeItemFromArray(items1, index);
		items2 = removeItemFromArray(items2, index);
		// items = removeItemFromArray(items, index);

		const newSegmentData = {
			...segment_data,
			vertical_lists: vertical_lists
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const saveOnTextChanged = () => {

		const newSegmentData = {
			...segment_data,
			vertical_lists: vertical_lists
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const checkAnswer = () => {
		let question_json = convertDataAsPerNewSystem(props.teacher_json, props)
		let answer_json = cloneDeep(state)
		const newState = checkResponses(question_json, answer_json)

		saveState(newState)
		setMyAnswer(state)
		setStateSetting({
			...stateSetting,
			quickCheckModeEnabled: true,
		})
	}

	const [myAnswer, setMyAnswer] = useState(state)
	const showCorrectAnswer = (value) => {
		console.log("Value in switch ==>", value)
		let question_json, answer_json
		if (value) {
			question_json = convertDataAsPerNewSystem(props.teacher_json, props)
			answer_json = convertDataAsPerNewSystem(props.teacher_json, props)
		} else {
			question_json = convertDataAsPerNewSystem(props.teacher_json, props)
			answer_json = cloneDeep(myAnswer)
		}

		const newState = checkResponses(question_json, answer_json)
		saveState(newState)
		setStateSetting({
			...stateSetting,
			quickCheckModeEnabled: true,
		})
	}

	const resetQuestion = () => {
		console.log("Student json ===>", props.student_json)
		const newState = convertDataAsPerNewSystem(props.student_json, props)
		saveState(newState)
		setStateSetting({
			...stateSetting,
			quickCheckModeEnabled: false,
		})
	}

	console.log('State before render ==>', state, quickCheckModeEnabled)
	return (
		<Segment>
			{/* <SegmentItemHeader
				segmentStateSettings={stateSetting}
				showCorrectAnswer={(value) => showCorrectAnswer(value)}
			>
			</SegmentItemHeader> */}

			<SegmentData>
				<DragDropContext onDragEnd={onDragEnd}>
					<DragDropArea>
						{createMode ? <VerticalDroppableContainer
							style={{ width: '100%' }}
						>
							{vertical_lists[0] && vertical_lists[0].items.map((item, index) => (
								<Row>
									<Col span={12}>
										<RBDDroppableBox
											droppableProps={{
												list: vertical_lists[0],
												type: vertical_lists[0].title,
												droppableId: item.id,
											}}
											draggableProps={{
												list: vertical_lists[0],
												item: item,
												index: index,
												showTextArea: createMode,
												draggableId: item.id,
												saveOnTextChanged: (e) => saveOnTextChanged(),
											}}
										/>
									</Col>
									<Col span={12}>
										<RBDDroppableBox
											droppableProps={{
												list: vertical_lists[1],
												type: vertical_lists[1].title,
												droppableId: vertical_lists[1].items[index].id,
											}}
											draggableProps={{
												list: vertical_lists[1],
												item: vertical_lists[1].items[index],
												index: index,
												showTextArea: createMode,
												draggableId: vertical_lists[1].items[index].id,
												removeItem: (keyName, index) => removeItem(keyName, index),
												saveOnTextChanged: (e) => saveOnTextChanged()
											}}
										/>
									</Col>
								</Row>
							))}
							<AddButton
								clicked={(e) => addItem(0)}
							/>
						</VerticalDroppableContainer>
							:
							<VerticalDroppableContainer
								style={{ width: '100%' }}
							>
								{vertical_lists[0] && vertical_lists[0].items.map((item, index) => (
									<Row>
										<Col span={12}>
											<RBDDroppableBox
												droppableProps={{
													list: vertical_lists[0],
													type: vertical_lists[0].title,
													droppableId: item.id,
												}}
												draggableProps={{
													list: vertical_lists[0],
													item: item,
													index: index,
													showTextArea: createMode,
													draggingDisabled: true,
													draggableId: item.id,
												}}
											/>
										</Col>
										<Col span={12}>
											<RBDDroppableBox
												droppableProps={{
													list: vertical_lists[1],
													type: answerMode ? 'DEFAULT' : vertical_lists[1].title,
													droppableId: vertical_lists[1].items[index].id,
												}}
												draggableProps={{
													list: vertical_lists[1],
													item: vertical_lists[1].items[index],
													index: index,
													showTextArea: createMode,
													draggableId: vertical_lists[1].items[index].id,
													showCorrect: (autoCheckMode || quickCheckModeEnabled),
													draggingDisabled: (!answerMode && !quickCheckModeEnabled) ? true : false,
												}}
											/>
										</Col>
									</Row>
								))}
							</VerticalDroppableContainer>
						}
					</DragDropArea>
					{((answerMode || presentationMode) && horizontal_lists[0]) && <HorizontalDroppableContainer>
						<RBDDroppableArea
							droppableProps={{
								list: horizontal_lists[0],
								type: 'DEFAULT',
								droppableId: horizontal_lists[0].id,
							}}
							draggableProps={{
								list: horizontal_lists[0],
								direction: 'horizontal',
								hidePlaceholder: true,
								draggingDisabled: (!answerMode && !quickCheckModeEnabled) ? true : false,
							}}
						/>
					</HorizontalDroppableContainer>}
				</DragDropContext>
			</SegmentData>

			{answerMode && <SegmentItemFooter
				segmentSettings={props.segmentSettings}
				segmentStateSettings={stateSetting}
				resetQuestion={(e) => resetQuestion()}
				checkAnswer={(e) => checkAnswer()}
				showCorrectAnswer={(value) => showCorrectAnswer(value)}
			>
			</SegmentItemFooter>}
		</Segment>
	);
};
export default RBDMatching;

