import React, { useEffect, useState } from "react";

import { initialData, removeItemFromArray, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { Segment, SegmentData } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { cloneDeep } from 'lodash';
import MediaUI from "/src/components/UI/Media/MediaUI"
import SimulationView from "./SimulationView";
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments"
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { message } from "/src/components/UI/AntdAppHelper";

const SimulationForm = props => {
	console.log("Simulation props", props);
	let { createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)
	let parsedJSONObject

	if (createMode) {
		parsedJSONObject = props.value || initialData('resource', 1, 4);
	} else {
		parsedJSONObject = props.resource_json
	}

	const [state, setState] = useState(parsedJSONObject)
	const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
  const mrIntl = useTranslate();

	const mediaFormInitialState = {
		modal: false,
		loading: false,
		visible: false,
    title: mrIntl("SimulationForm.insert_simulations"),
		okText: mrIntl("SimulationForm.add_simulations_button"),
		cancelText: mrIntl("CommonText.cancel"),
		triggerSaveAction: 0,
		requireFooter: false,
		limit: 1,
		content_type: 'simulation',
		supportedTypes: 'application/x-shockwave-flash, text/html',
		supportedFormats: 'html',
		selectConfig: {
			multiple: false,
			maxSize: "20 MB",
		},
		
	}
	const [mediaFormSetting, setMediaFormSetting] = useState(mediaFormInitialState)
	mediaFormSetting.beforeLoadURL = (data) => {
		// return data
		let updatedURL = 
		data.match(/(https:\/\/leosiiman.neocities.org.*.htm)\w+/g) ||
		data.match(/(https:\/\/contrib.pbslearningmedia.org.*.htm)\w+/g) ||
		data.match(/(https:\/\/phet.colorado.edu.*.htm)\w+/g)
		
		setMediaFormSetting({
			...mediaFormSetting,
			disableUploadURLToS3:
				data.match(/(https:\/\/phet.colorado.edu.*.htm)\w+/g) ? false : true
		})
		return updatedURL ? updatedURL[0] : updatedURL 
	}	

	let currentJSON
	if (presentationMode) {
		currentJSON = parsedJSONObject
	} else {
		currentJSON = state
	}
	const segment_data = currentJSON?.segment_data || {}
	const resource_setting = currentJSON.resource_setting || {}
	const attachments = segment_data.attachments || []
	const attachmentsCount = attachments.length

	useEffect(() => {
		setMediaFormSetting({
			...mediaFormSetting,
			modal: attachmentsCount > 0,
			visible: false,
		})
	}, [attachmentsCount])

	useEffect(() => {
		if (createMode) {
			if (props.onChange) {
				let resource_json = cloneDeep(state)
				props.onChange(resource_json)
			}
		}
	}, [stateUpdatedCount]);

	const saveState = (state) => {
		setState(state)
		console.log("stateUpdated count ==>", stateUpdatedCount)
		setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
	}

	const onTabDragEnd = (attachments) => {
		const newAttachments = attachments.slice()

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const removeItem = (index) => {
		const extra = {
			noMinSize: true
		}
		const newAttachments = removeItemFromArray(attachments, index, extra)
		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}
		saveState(newState)
	}

	const removeFile = (index) => {
		const newAttachments = attachments
		delete newAttachments[index].files

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const onSaveMedia = (files) => {
		console.log('Files and attachmetns ==>', attachments, files)
    // If the user clicks the Add button without selecting a file, a message is shown
    if (files.length === 0) {
      message.error(mrIntl("CommonText.at_least_one_file_required"));
      return
    }

		let newAttachments = attachments.concat(files)

		const limit = mediaFormSetting.limit
		if (limit) {
			newAttachments = newAttachments.slice(-limit)
		}

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log('ON save Simulation ==>', newAttachments, newState)

		saveState(newState)
		setMediaFormSetting({
			...mediaFormSetting,
			from: '',
			modal: false,
			visible: false,
			triggerSaveAction: 0,
		})
	}

	const onTextChanged = (type, index, data) => {
		const newAttachments = attachments
		newAttachments[index][type] = data

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log("onchange is getting fired ==>", newState, data)
		saveState(newState)
	}

	const setResourceSetting = (key, value) => {
		const newResourceSetting = {
			...resource_setting,
			[key]: value
		}

		const newState = {
			...state,
			resource_setting: newResourceSetting
		}

		saveState(newState)
	}

	const onTabEdit = (index, action) => {
		if (action == 'add') {
			setMediaFormSetting({
				...mediaFormSetting,
				from: 'computer',
				modal: true,
				visible: true,
			})
		} else if (action == 'remove') {
			removeItem(index)
		}
	}

	const handleTabPositionChange = (e) => {
		setResourceSetting('tab_position', e.target.value)
	}
	const handleViewTypeChange = (e) => {
		setResourceSetting('view_type', e.target.value)
	}

	const getMediaForm = () => {

		return (<MediaUI
			computer={true}
			url={true}
			mediaFormSetting={mediaFormSetting}
			setMediaFormSetting={(data) => setMediaFormSetting(data)}
			onSaveMedia={(files) => onSaveMedia(files)}
		></MediaUI>)
	}

	return (
		<Segment>
			<SegmentData>
				{createMode && getMediaForm()}

				{attachmentsCount > 0 && <div>
					{createMode ? <TabularAttachments
						createMode={createMode}
						hideAdd={mediaFormSetting.limit == 1}
						titleRequired={false}
						attachmentType="simulation"
						tabPrefix={mrIntl("CommonText.simulation")}
						attachments={attachments}
						resource_setting={resource_setting}
						onTabDragEnd={onTabDragEnd}
						onTabEdit={onTabEdit}
						onTextChanged={onTextChanged}
					></TabularAttachments>
						: <SimulationView
							resource_json={state}
						></SimulationView>}
				</div>}
			</SegmentData>
		</Segment >
	);
}
export default SimulationForm;