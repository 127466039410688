import React from "react";
import { Col, Row } from "antd";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import MediaShow from "/src/components/UI/Media/MediaShow";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { cloneDeep } from "lodash";
import {
  Segment,
  ShowText,
  ShowTextContent,
  ShowTextFile,
} from "/src/components/UI/Segment/UIHelper";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

export const convertToConnectGame = (json) => {
  let student_json = cloneDeep(json);
  let segment_data = student_json.segment_data || {};
  let answer = segment_data.answer;
  if (segment_data) {
    answer.content = "";
  }

  return student_json;
};

const ConnectGame = (props) => {
  const {
    createMode,
    state,
    saveState,
    segment_data,
    hints,
    answers,
    answer,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
    answerMode,
    presentationMode,
    autoCheckMode,
    attemptStatus,
    setAttemptStatus,
    screenSettings,
  } = props;
  const mrIntl = useTranslate();
  function validateAttempted(json, data) {
    let isAtleastOneChanged = attemptStatus.json_attempted;
    if (data) {
      isAtleastOneChanged = true;
    }

    if (isAtleastOneChanged != attemptStatus.json_attempted) {
      setAttemptStatus({
        ...attemptStatus,
        json_attempted: isAtleastOneChanged,
      });
    }
  }

  const onChangedText = (data) => {
    const newAnswer = answer;
    newAnswer.content = data;
    const newSegmentData = {
      ...segment_data,
      answer: newAnswer,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
    if (answerMode) {
      validateAttempted(newState, data);
    }
  };

  return (
    <Segment>
      <Row className="connect-content">
        {hints.map((item, index) => (
          <Col span={12}>
            <ShowTextContent key={`show-text-container-${item.id}`}>
              <ShowText>
                {item.content && <RenderHtml text={item.content}></RenderHtml>}
                {item.files && (
                  <ShowTextFile>
                    <MediaShow
                      file={item.files && item.files[0]}
                      removeIcon={false}
                    ></MediaShow>
                  </ShowTextFile>
                )}
              </ShowText>
            </ShowTextContent>
          </Col>
        ))}
      </Row>
      <Row className={"connect-content"}>
        {answerMode ? (
          <BasicCKE
            key={`basic-cke-${answer.id}`}
            itemId={`ckeditor-${answer.id}`}
            data={answer && answer.content}
            onTextChanged={(data) => onChangedText(data)}
            className="correct-answer-cke"
            placeholder={mrIntl("ConnectGame.enter_answer")}
          ></BasicCKE>
        ) : (
          <ShowText>
            {!createMode && (
              <ShowTextContent
                dangerouslySetInnerHTML={{ __html: answer.content }}
              ></ShowTextContent>
            )}
          </ShowText>
        )}
      </Row>
      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default ConnectGame;
