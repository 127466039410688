import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { pauseTimeSelector } from "/src/views/Experiences//ExperienceShow/FirestoreInteractions/selector";
import { useSelector } from "react-redux";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const PausedTimeModal = (props) => {
  const { setUserInfo } = props;

  const userInfoFromReduxpauseTime = useSelector(pauseTimeSelector);

  return (
    <Modal
      wrapClassName="height-centered-modal"
      centered={true}
      closable={false}
      footer={null}
      open={userInfoFromReduxpauseTime}
    >
      <MrTranslate id={"PausedTimeModal.time_paused"}/>
      <Button onClick={() => setUserInfo({ pause_time: false })}><MrTranslate id={"CommonText.close"}/></Button>
    </Modal>
  );
};

export default PausedTimeModal;
