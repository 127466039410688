import { createSelector } from "reselect";
import { experienceSettingsState } from "../../selector";

const defaultEmptyString = ""
const defaultEmptyObject = {};
const defaultFalseValue = false;
const defaultTrueValue = true;
const defaultEmptyArray = [] 

export const firebaseTokenSelector = (state) =>
  state.firebase.get("firebaseToken", null);
export const isFirebaseAuthenticatedSelector = (state) =>
  state.firebase.get("isFirebaseAuthenticated", false);
export const getFirebaseTokenLoadingSelector = (state) =>
  state.firebase.get("getFirebaseTokenLoading", false);
export const getFirebaseTokenErrorSelector = (state) =>
  state.firebase.get("getFirebaseTokenError", null);
export const signInToFirestoreLoadingSelector = (state) =>
  state.firebase.get("signInToFirestoreLoading", false);
export const signInToFirestoreErrorSelector = (state) =>
  state.firebase.get("signInToFirestoreError", null);
export const syncOfflineResponsesLoadingSelector = (state) =>
  state.firebase.get("syncOfflineResponsesLoading", false);

// const userInfoState = (state, props) => {
//     return state.firebase.get("user_info", {});
//   };

// export const userInfoSelector = () =>
//   createSelector(
//       [userInfoState],
//       userInfo => userInfo
//     );

export const userInfoSelector = (state) => {
  if (state.firebase) {
    return state.firebase.get("user_info", {});
  }
  return {};
};

export const experienceInfoSelector = (state) => {
  console.log('inside selector==>', state.firebase.get("experience_info", {}))
  return state.firebase.get("experience_info", {})
};

export const aiUsageInfoSelector = (state) => {
  console.log('inside selector orgAiUsageInfoSelector==>', state.firebase.get("ai_usage_info", {}))
  return state.firebase.get("ai_usage_info", {})
};

export const publishedAtSelector = (state) =>
  state.firebase.get("experience_info").published_at;

export const calcEnabledSelector = () => 
  createSelector([userInfoSelector, experienceSettingsState],
  (userInfo, experienceSettings) => {
    console.log("userInfo==>", userInfo, experienceSettings)
    return userInfo.calc_enabled !== undefined 
      ? userInfo.calc_enabled 
      : userInfo.accommodations?.calc_enabled !== undefined 
        ? userInfo.accommodations.calc_enabled 
        : experienceSettings.calc_enabled;
  }
);

export const graphingCalcEnabledSelector = () => 
  createSelector([userInfoSelector, experienceSettingsState],
  (userInfo, experienceSettings) => {
    return userInfo.graphing_calc_enabled !== undefined 
      ? userInfo.graphing_calc_enabled 
      : userInfo.accommodations?.graphing_calc_enabled !== undefined 
        ? userInfo.accommodations.graphing_calc_enabled 
        : experienceSettings.graphing_calc_enabled;
  }
);

export const additionalTimeSelector = (state) => {
  const userInfo = state.firebase.get("user_info") || {};
  return userInfo.accommodations?.additional_time || 0;
}

export const spellCheckEnabledSelector = () => 
  createSelector([userInfoSelector, experienceSettingsState],
  (userInfo, experienceSettings) => {
    return userInfo.spellcheck_enabled !== undefined 
      ? userInfo.spellcheck_enabled 
      : userInfo.accommodations?.spellcheck !== undefined 
        ? userInfo.accommodations.spellcheck 
        : experienceSettings.spellcheck_enabled;
  }
);

export const haveStudentsStartedSelector = (state) =>
  state.firebase.get("experience_info").haveStudentsStarted;
 
export const attemptedCountSelector = (state) =>
  state.firebase.get("user_info").attempt_count || 0;

export const currentViewSelector = (state) => {
  return state.firebase.get("user_info").currentView;
}

export const enableLocalStorageSyncSelector = (state) => {
  return state.firebase.get("user_info").enable_local_storage_sync;
}

export const mobileUploadModeSelector = (state) =>
  state.firebase.get("user_info").mobile_upload_mode;

export const pauseTimeSelector = (state) =>
  state.firebase.get("user_info").pause_time;

export const totalAttachmentsSelector = (state) => 
  state.firebase.get("user_info").total_attachments_json || { attachments: [] };

export const attachmentsCountSelector = (state) =>
  state.firebase.get("user_info").attachments_count || 0;

export const focusLostCountSelector = (state) =>
  state.firebase.get("user_info").focus_lost_count || 0;

export const focusLostStatusSelector = (state) =>
  state.firebase.get("user_info").is_focus_lost;

export const focusLostAtSelector = (state) => 
  state.firebase.get("user_info").focus_lost_at;

export const startedAtSelector = (state) =>
  state.firebase.get("user_info").started_at;

export const durationAfterResetSelector = (state) =>
  state.firebase.get("user_info").duration_after_reset || false;

export const sessionIdSelector = (state) =>
  state.firebase.get("user_info").session_id || defaultEmptyString;

export const requestResumeSessionIdSelector = (state) =>
  state.firebase.get("user_info").request_resume_session_id || defaultEmptyString;

export const extraTimeSelector = (state) =>
  state.firebase.get("user_info").extra_time || 0;

export const extraTimeOffsetSelector = (state) =>
  state.firebase.get("user_info").extra_time_offset || 0;

export const isTimeUpSelector = (state) =>
  userInfoSelector(state).is_time_up || false;

export const securitySelector = (state) =>
  state.firebase.get("user_info").security;

export const securityInterruptedSelector = (state) =>
  state.firebase.get("user_info").security_interrupted;

export const requestResumeSelector = (state) =>
  state.firebase.get("user_info").request_resume;

export const studentNotesSelector = (state) =>
  state.firebase.get("user_info").student_notes;
   
export const ttsAllowedSelector = (state) => {
  const userInfo = state.firebase.get("user_info") || {};
  return userInfo.tts_allowed || userInfo.accommodations?.tts_allowed || defaultFalseValue;
}

export const sttAllowedSelector = (state) => {
  const userInfo = state.firebase.get("user_info") || {};
  return userInfo?.stt_allowed || userInfo?.accommodations?.stt_allowed || defaultFalseValue
};

export const textHighlightsSelector = (state) =>
   state.firebase.get("user_info").text_highlights || defaultEmptyArray;  

export const closeLaunchInAppWindowSelector = (state) =>
  state.firebase.get("user_info").closeLaunchInAppWindow

export const allowedInBrowserSelector = (state) =>
  state.firebase.get("user_info").allowed_in_browser

export const currentSessionIdSelector = (state) =>
  state.firebase.get("currentSessionId", "");

export const setUserInfoLoadingSelector = (state) =>
  state.firebase.get("setUserInfoLoading", false);

export const onlineStatusSelector = (state) =>
  state.firebase.get("onlineStatus", false);

export const userResponseConfigSelector = (state) =>
  state.firebase.get("userResponseConfig", {});

export const submitExperienceLoadingSelector = (state) =>
  state.firebase.get("submitExperienceLoading", false);

export const getUserResponsesLoadingSelector = (state) =>
  state.firebase.get("getUserResponsesLoading", false);
  
const responsesState = (state) =>
  state.firebase.get("responses", {}).toJS();

export const userResponsesSelector = (experience_id, user_id, responseIdentifier, segmentIds) => 
  createSelector([responsesState], (responses) => {
    if (experience_id && user_id) {
      if (responseIdentifier) {
        return responses[responseIdentifier] || {}
      } else if (segmentIds) {
        let newResponses = [];
        segmentIds.forEach((segmentId) => {
          const segmentResponseIdentifier = `eId${experience_id}_uId${user_id}_sId${segmentId}`
          const segmentResponse = responses[segmentResponseIdentifier]
          if (segmentResponse) {
            newResponses.push(segmentResponse)
          }
        })
        return newResponses;
      } else {
        let newResponses = {};
        for (const key in responses) {
          let response = responses[key]
          if (response.experience_id === experience_id && response.user_id === parseInt(user_id)) {
            newResponses[key] = response
          }
        };
        console.log("newResponses 108 ==>", newResponses)
        return newResponses;
      }
    } else {
      return responses;
    }
  });

export const userResponseSelector = (responseIdentifier) => 
  createSelector([responsesState], (responses) => {
    return responses[responseIdentifier] || {}
  })

export const activeExperienceIdSelector = (state) =>
  state.firebase.get("activeExperienceId");

export const teacherSubmitLoadingSelector = (state) =>
  state.firebase.get("teacherSubmitLoading", false);

export const isStudentActiveUserInfoSelector = (state) =>
  userInfoSelector(state).is_student_active;

export const isTeacherActiveUserInfoSelector = (state) =>
  userInfoSelector(state).is_teacher_active;

export const hasTeacherReadUserInfoSelector = (state) =>
  userInfoSelector(state).has_teacher_read;

export const hasStudentReadUserInfoSelector = (state) =>
  userInfoSelector(state).has_student_read;

export const studentUnreadMessagesCountUserInfoSelector = (state) =>
  userInfoSelector(state).student_unread_count || 0;

export const totalEngagementPointsUserInfoSelector = (state) =>
  userInfoSelector(state).total_e_points || 0;

export const questionsStatusSelector = (state) =>
  userInfoSelector(state).questions_status || [];

export const bookmarksSelector = (state) =>
  userInfoSelector(state).bookmarks || [];

export const activeSegmentInfoSelector = (state) =>
  userInfoSelector(state).active_segment_info || defaultEmptyObject;

export const uploadSessionIdSelector = (state) =>
  state.firebase.get("user_info").uploadDeviceInfo?.upload_session_id || defaultEmptyString;

export const activeChatsSelector = (state) => state.firebase.get("activeChats");

export const firstActiveChatSelector = (state) =>
  state.firebase.get("activeChats")[0];

export const activeChatSelector = (props = {}) => (state) =>
  state.firebase.get("activeChats").find((item) => item.id === props.id);

export const firebaseState = (state) =>
  state.firebase

export const savingStatusState = (state, props = {}) => {
  let key = props.segment_id || "latest";
  console.log("state.firebase.getIn ==>", state.firebase.getIn(["savingStatus", key]), key)
  return state.firebase.getIn(["savingStatus", key]) || {};
};

export const savingStatusSelector = () =>
  createSelector([savingStatusState], (savingStatus) => {
    // let key = segment_id || "latest"
    // let savingStatus = firebaseState.getIn(["savingStatus", segment_id])
    // console.log("savingStatus create selector ==>", savingStatus)
    return savingStatus || {};
  });

export const supportOldUserResponseIdState = (state) =>
  state.firebase.get("supportOldUserResponseId", false)

export const supportOldUserResponseIdSelector = (state) =>
  createSelector([supportOldUserResponseIdState], (supportOldUserResponseId) => supportOldUserResponseId)

export const presenceDataState = (state, props = {}) => {
  return state.firebase.get("presence_data", {})
  // let key = props.user_id
  // console.log("socketIO - state.firebase.getIn ==>", state.firebase.getIn(["presence_data", key]), key)
  // return state.firebase.getIn(["presence_data", key]) || {};
};

export const presenceDataSelector = (userId) =>
  createSelector([presenceDataState], (presenceData) => {
    console.log("socketIO - presenceDataSelector", presenceData, userId);
    if(userId){
      return presenceData[userId] || {};
    }else{
      return presenceData || {};
    }
  });

export const firebaseUserResponseSelector = (responseIdentifier) => 
  createSelector([firebaseState], (firebase) => {
    let firebaseUserResponse = firebase.getIn(["responses", responseIdentifier])
    return firebaseUserResponse || {}
  })

export const firestoreConnectionClosedSelector = () => 
  createSelector([firebaseState], (firebase) => {
    return firebase.get("firestoreConnectionClosed", defaultFalseValue);
  })

export const sessionSwitchedSelector = () => 
  createSelector([firebaseState], (firebase) => {
    return firebase.get("sessionSwitched", defaultFalseValue);
  })

export const experiencePausedSelector = (state) =>
  state.firebase.get("experience_info", {}).paused;

export const experiencePausedAtSelector = (state) =>
  state.firebase.get("experience_info", {}).paused_at || 0;

export const experienceUnpausedAtSelector = (state) =>
  state.firebase.get("experience_info", {}).unpaused_at || 0;

export const unpausedOffsetUserInfoSelector = (state) =>
  state.firebase.get("user_info").unpaused_offset || 0;