import React, { useEffect, useState } from "react";

import { Button, Modal } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { debounce } from 'lodash';
import { CustomInput } from '/src/components/UI/Segment/UIHelper'
import { checkMob } from "/src/lib/utils/helperMethods";
import axios from 'axios';
// import ReactPlayer from 'react-player'

import loadable from '@loadable/component'
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
const ReactPlayer = loadable(() => import(/* webpackChunkName: "react-player" */ 'react-player'))

const InsertFromURL = props => {
  console.log("Insert From URL props", props);
  const { mediaFormSetting, setMediaFormSetting, onSaveMedia } = props

  const [urlSetting, setUrlSetting] = useState({ invalidURL: true })
  const mrIntl = useTranslate();
  const onURLChange = debounce((data) => {
    console.log('On change is happening', data)
    let tempURL = data
    if (mediaFormSetting.beforeLoadURL) {
      tempURL = mediaFormSetting.beforeLoadURL(data)
    }

    setUrlSetting({
      ...urlSetting,
      saved: false,
      tempUrl: tempURL
    })
  }, 1000)

  useEffect(() => {
    const newMediaFormSetting = {
      ...mediaFormSetting,
      okDisabled: urlSetting.invalidURL,
    }
    setMediaFormSetting(newMediaFormSetting)
  }, [urlSetting.invalidURL])

  useEffect(() => {
    if (mediaFormSetting.triggerSaveAction) {
      handleOk()
    }
  }, [mediaFormSetting.triggerSaveAction])

  const onLoadSuccess = () => {
    setUrlSetting({
      ...urlSetting,
      invalidURL: false
    })
    setMediaFormSetting({
      ...mediaFormSetting,
      okDisabled: false
    })
  }

  const onLoadError = () => {
    setUrlSetting({
      ...urlSetting,
      invalidURL: true
    })
    setMediaFormSetting({
      ...mediaFormSetting,
      okDisabled: true
    })
  }

  const saveButtonStyle = {
    margin: '10px',
    float: 'right'
  }

  const handleOk = () => {
    if (!mediaFormSetting.disableUploadURLToS3) {
      const token = localStorage.getItem('token')
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          token: token
        }
      }

      setMediaFormSetting({
        ...mediaFormSetting,
        loading: true,
        okDisabled: true
      })

      const url = (import.meta.env.VITE_API_URL || "/backend/api/v1/") + 'attachments'

      const formData = new FormData()
      formData.append('url', urlSetting.tempUrl)
      axios.post(url, formData, config)
        .then((response) => {
          console.log('Returned response ==>', response)
          setUrlSetting({
            ...urlSetting,
            saved: true
          })

          const files = [response.data]
          if (onSaveMedia) {
            onSaveMedia(files)
          }
        }).catch((e) => {
          console.log("error ==>", e, e.response)
          if (e.response) {
            // message.error(e.response.data.error)
            message.error('File upload failed. Check if url is valid.')
          }

          setMediaFormSetting({
            ...mediaFormSetting,
            okDisabled: false
          })
        })

    } else {
      setUrlSetting({
        ...urlSetting,
        saved: true
      })

      let file =  {
        id: getUniqueId(),
        url: urlSetting.tempUrl,
        type: mediaFormSetting.content_type,
        name: getUniqueId(),
        s3_url: urlSetting.tempUrl,
        webm_url: urlSetting.tempUrl, 
      }

      if (onSaveMedia) {
        onSaveMedia([file])
      }
    }
  }

  const handleCancel = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      from: '',
      visible: false
    })
  }

  console.log("Insert from url before render ==>", urlSetting)
  const getForm = () => {
    return (
      <div>
        <div className="url-form">
          <CustomInput
            onChange={(data) => onURLChange(data)}
            placeholder= {mrIntl("InsertFromURL.paste_your_url_here")}
            // placeholder='Paste your url here'
            rules={[
              {
                validator(rule, value) {
                  console.log("validation ==>", rule, value, urlSetting.invalidURL)
                  if (urlSetting.invalidURL) {
                    return Promise.reject('URL not supported')
                  } else {
                    return Promise.resolve()
                  }
                },
                message: 'URL not supported',
              }
            ]}
          >
          </CustomInput>
          {mediaFormSetting.supportedURLs && <p style={{color: "#8b8b8b", marginTop: '5px'}}><i>{mediaFormSetting.supportedURLs}</i></p>}
        </div>

        <div className="url-preview">
          {mediaFormSetting.content_type == 'audio' && urlSetting.tempUrl && <ReactPlayer
            url={urlSetting.tempUrl}
            controls={true}
            height={'50px'}
            file={{
              forceAudio: true
            }}
            onReady={() => onLoadSuccess()}
            onError={() => onLoadError()}
          />}

          {mediaFormSetting.content_type == 'video' && urlSetting.tempUrl && <ReactPlayer
            width={"100%"}
            url={urlSetting.tempUrl}
            controls={true}
            onReady={() => onLoadSuccess()}
            onError={() => onLoadError()}
          />}

          {mediaFormSetting.content_type == 'image' && urlSetting.tempUrl && <img
            src={urlSetting.tempUrl}
            onLoad={() => onLoadSuccess()}
            onError={() => onLoadError()}
          ></img>}

          {mediaFormSetting.content_type == 'pdf' && urlSetting.tempUrl && <iframe
            src={`/pdfjs/web/viewer.html?file=${urlSetting.tempUrl}`}
            width="100%"
            height="500px"
            onLoad={() => onLoadSuccess()}
            onError={() => onLoadError()}
          ></iframe>}

          {mediaFormSetting.content_type == 'simulation' && urlSetting.tempUrl && <iframe
            src={urlSetting.tempUrl}
            width="100%"
            height="500px"
            onLoad={() => onLoadSuccess()}
            onError={() => onLoadError()}
          ></iframe>}

          {mediaFormSetting.content_type == 'embed' && urlSetting.tempUrl && <iframe
            src={urlSetting.tempUrl}
            width="100%"
            height="500px"
            onLoad={() => onLoadSuccess()}
            onError={() => onLoadError()}
          ></iframe>}

          {mediaFormSetting.content_type == 'excel' && urlSetting.tempUrl && <iframe
            src={urlSetting.tempUrl}
            width="100%"
            height="500px"
            onLoad={() => onLoadSuccess()}
            onError={() => onLoadError()}
          ></iframe>}
        </div>
      </div>
    )
  }

  const getFooter = () => {
    return [
      <Button
        key="back"
        onClick={(e) => handleCancel()}
        disabled={mediaFormSetting.okDisabled && mediaFormSetting.loading}
      // style={saveButtonStyle}
      >
        {(mediaFormSetting && mediaFormSetting.cancelText) || 'Return'}
      </Button>,
      <Button
        key="submit"
        type="primary"
        onClick={(e) => handleOk()}
        loading={mediaFormSetting.okDisabled && mediaFormSetting.loading}
        disabled={mediaFormSetting.okDisabled}
      // style={saveButtonStyle}
      >
        {(mediaFormSetting && mediaFormSetting.okText) || mrIntl("CommonText.submit")}
      </Button>
    ]
  }

  let finalRender
  if (props.modal) {
    finalRender = <Modal
      {...mediaFormSetting}
      open={mediaFormSetting.visible}
      width={checkMob() ? '' : '50%'}
      centered={checkMob() ? true : false}
      className={checkMob() ? 'height-centered-modal' : ''}
      closable={false}
      maskClosable={false}
      keyboard={false}
      title={mediaFormSetting.title || "Insert Media"}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={getFooter()}
      loading={false}
    >
      <div className="insert-from-url-form">
        {getForm()}
      </div>
    </Modal>
  } else {
    finalRender = <div className="insert-from-url-form">
      {getForm()}
      {mediaFormSetting.requireFooter && getFooter()}
    </div>
  }

  console.log('Final render ==>', finalRender)
  return (finalRender)
}
export default InsertFromURL;