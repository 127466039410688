import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';

import { CKETextInputTools } from "/src/components/UI/CKEditor/CKEConfig"
import DefaultCKE from "/src/components/UI/CKEditor/DefaultCKE"
import GapAnswerModal from "/src/views/Segments/CKEFill/GapAnswerModal"
import { debounce } from 'lodash';
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const TextInputCKE = (props) => {
  console.log("CKE text input props ==>", props)
  const { onTextChanged, onGapAnswerChanged } = props
  const mrIntl = useTranslate();
  const [triggerSaveLastSelectionRange, setTriggerSaveLastSelectionRange] = useState(null)
  const [triggerRestoreLastSelectionRange, setTriggerRestoreLastSelectionRange] = useState(null)
  const [currentGap, setCurrentGap] = useState(null)
  const [formSetting, setFormSetting] = useState({
    title: mrIntl("TextInputCKE.correct_answers"),
    visible: false,
    gapItemsSetting: {
      contentRequired: false,
      contentPrefix: 'Answer',
      placeholderPrefix: 'Answer'
    }
  })

  const tools = {
    ...CKETextInputTools,
    customReactComponent: {
      textInputRenderer: (id, domElement) => {
        const newGaps = props.gaps
        const gapsLength = newGaps.length
        const index = newGaps.findIndex(gap => gap.id == id)
        let gap = newGaps[index]
        let gapPlaceholderText
        if (!gap) {
          // gapPlaceholderText = `Text Input ${gapsLength + 1 || 1}`
          gapPlaceholderText = mrIntl("TextInputCKE.text_input_gap_length_placeholder", {gapsLength: gapsLength + 1 || 1})
          let firstItem = {
            id: getUniqueId(),
            content: '',
            placeholder: mrIntl("TextInputCKE.answer_1_placeholder")
          }
          gap = {
            id: id,
            title: `Gap ${gapsLength + 1}`,
            answer: firstItem,
            items: [firstItem],
            invalid: true,
          }
          // openModal(gap)
          onGapAnswerChanged(gap)
        } else {
          // gapPlaceholderText = `Text Input ${index + 1}`
          gapPlaceholderText = mrIntl("TextInputCKE.text_input_index_placeholder", {index: index + 1})
        }

        ReactDOM.render(
          <div
            key={`text-input-placeholder-${id}`}
            className={`text-input-react-component ${gap.invalid && 'error'}`}
            // onDoubleClick={() => openModal(gap)}
            onClick={() => openModal(gap)}
          >
            <div className="text-content"> {gapPlaceholderText} </div>
          </div>,
          domElement
        );
      }
    },
  }

  const openModal = (gap) => {
    setFormSetting({
      ...formSetting,
      visible: true
    })

    setCurrentGap({ ...gap })
    setTriggerSaveLastSelectionRange(getUniqueId())
  }

  return (
    <div>
      {currentGap && <GapAnswerModal
        currentGap={currentGap}
        setCurrentGap={setCurrentGap}
        formSetting={formSetting}
        setFormSetting={setFormSetting}
        onGapModalSubmit={onGapAnswerChanged}
        setTriggerRestoreLastSelectionRange={setTriggerRestoreLastSelectionRange}
      >
      </GapAnswerModal>}

      <DefaultCKE
        tools={tools}
        data={props.data}
        onTextChanged={onTextChanged}
        triggerSaveLastSelectionRange={triggerSaveLastSelectionRange}
        triggerRestoreLastSelectionRange={triggerRestoreLastSelectionRange}
      >
      </DefaultCKE>
    </div>
  );
};
export default TextInputCKE;

