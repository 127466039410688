import { createSelector } from "reselect";


const commandTermsState = (state, props) =>
  state.request.get("commandterms", []);

export const commandTermsSelector = () =>
  createSelector([commandTermsState], (commandTerms) => commandTerms);


const orgsRequestState = (state, props) =>
  state.request.get("orgs", []);

export const orgsRequestSelector = () =>
  createSelector([orgsRequestState], (orgs) => orgs);

const gradesRequestState = (state, props) =>
  state.request.get("grades", []);

export const gradesRequestSelector = () =>
  createSelector([gradesRequestState], (grades) => grades);

const syllabusTopicsState = (state, props) =>
  state.request.get("tags", []);

export const syllabusTopicsSelector = () =>
  createSelector([syllabusTopicsState], (syllabusTopics) => syllabusTopics);

const contentAreaState = (state, props) =>
  state.request.get("standardcollectioncontentareas", []);

export const contentAreaSelector = () =>
  createSelector([contentAreaState], (standardcollectioncontentareas) => standardcollectioncontentareas);

const gradeGroupState = (state, props) =>
  state.request.get("contentareagradegroups", []);

export const gradeGroupSelector = () =>
  createSelector([gradeGroupState], (contentareagradegroups) => contentareagradegroups);

const standardCollectionsState = (state, props) =>
  state.request.get("standardcollections", []);

export const standardCollectionsSelector = () =>
  createSelector([standardCollectionsState], (standardcollections) => standardcollections);

