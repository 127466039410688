import { useCallback, useEffect } from 'react';
import { getUniqueId } from '../../lib/utils/helperMethods';

export const liftDraggableItem = (event, id, liftedItem, sensorAPI) => {
  if (!sensorAPI.current) {
    return
  }
  console.log("lift draggable event =====>", event, id, sensorAPI.current)

  const preDrag = sensorAPI.current.tryGetLock(id);
  console.log("preDrag ===>", preDrag)
  if (!preDrag) {
    return;
  }
  liftedItem.current = preDrag.fluidLift({x: event.clientX, y: event.clientY});
  console.log("Lifted draggable item =====>", liftedItem.current)
}

export const useClickAndClickSensor = (callback, liftedItem) => {
  const dropLiftedDraggableItem = (event) => {
    if (!liftedItem.current) {
      return
    }
    console.log("drop draggable item =====>", event, liftedItem.current)
    liftedItem.current.move({x: event.clientX, y: event.clientY})
    setTimeout(() => {
      liftedItem.current.drop()
      liftedItem.current = null
    }, 100);
  }
  
  useEffect(() => {
    window.addEventListener('mouseup', dropLiftedDraggableItem, true);
    return () => {
      window.removeEventListener('mouseup', dropLiftedDraggableItem, true);
    };
  }, []);

  return useCallback((api) => {
    callback(api)
  }, [])
}

export const getStartAndFinishList = (rootList, source, destination) => {
	let start, finish
	const updatedSource = { ...source }
	const updatedDestination = { ...destination }

	console.log("Parent list ==>", rootList)

	for (const parentKey in rootList) {
		let parentType
		let lists = rootList[parentKey]
		let newLists = lists
		if (Array.isArray(lists)) {
			parentType = 'array'
		} else {
			parentType	= 'list'
		}

		for (const key in newLists) {
			let list, listKey = key
			if (newLists.items) {
				list = newLists
				listKey = parentKey
			} else {
				list = newLists[key]
			}
			console.log('list id, source id, destination id ==>', listKey, list, list.id, source.droppableId, destination.droppableId)
			if (list.id == source.droppableId) {
				start = { from: parentKey, fromType: parentType, key: listKey, type: 'list', list: list }
			} else {
				if (list.items) {
					let index = list.items.findIndex(item => item.id == source.droppableId)
					if (index >= 0) {
						start = { from: parentKey, fromType: parentType, key: listKey, type: 'item', list: list }
						updatedSource['index'] = index
					}
				}
			}

			if (list.id == destination.droppableId) {
				finish = { from: parentKey, fromType: parentType, key: listKey, type: 'list', list: list }
        if (list.items && destination.index >= list.items.length) {
					updatedDestination['index'] = list.items.length - 1
				}
			} else {
				if (list.items) {
					let index = list.items.findIndex(item => item.id == destination.droppableId)
					console.log("finish index ==>", index, list.items)
					if (index >= 0) {
						finish = { from: parentKey, fromType: parentType, key: listKey, type: 'item', list: list }
						updatedDestination['index'] = index
					}
				}
			}
		}
	}

	console.log("start and finish list ==>", start, finish, updatedSource, updatedDestination)
	return { start, finish, updatedSource, updatedDestination }
}

export const updateSameList = (rootList, start, finish, updatedSource, updatedDestination, dragDropConfig) => {
  console.log("rootList, start, finish, updatedSource, updatedDestination, dragDropConfig", rootList, start, finish, updatedSource, updatedDestination, dragDropConfig)
	let newList, newParentList, newRootList

	const startType = start.type
	const newItems = Array.from(start.list.items)

	const draggableItem = newItems[updatedSource.index]
	const droppableItem = newItems[updatedDestination.index]

	const { updateType, append, limit } = dragDropConfig
	if (updateType == 'replace') {
		if (startType == 'list') {
			if (append) {
				newItems.splice(updatedSource.index, 1)
				newItems.splice(newItems.length, 0, draggableItem)
			} else {
				newItems[updatedSource.index] = droppableItem
				newItems[updatedDestination.index] = draggableItem
			}
		} else {
			newItems[updatedSource.index] = droppableItem
			newItems[updatedDestination.index] = draggableItem
		}
	}

	newList = {
		...start.list,
		items: newItems
	}

	if (start.fromType == 'array') {
		newParentList = rootList[start.from]
		newParentList[start.key] = newList
	} else {
    if (rootList[start.from][start.key]) {
      newParentList = {
        ...rootList[start.from],
        [start.key]: newList
      }
    } else {
      newParentList = {
        ...rootList[start.from],
        ...newList
      }
    }
	}

	newRootList = {
		...rootList,
		[start.from]: newParentList
	}

	return newRootList

}

export const updateDifferentList = (rootList, start, finish, updatedSource, updatedDestination, dragDropConfig) => {
	let newStartList, newFinishList, newStartParentList, newFinishParentList, newRootList
	const startType = start.type
	const finishType = finish.type

	const startItems = Array.from(start.list.items)
	const finishItems = Array.from(finish.list.items)

	const draggableItem = startItems[updatedSource.index]
	const droppableItem = finishItems[updatedDestination.index]

	console.log("updated source and destination with start types ==>", startItems, finishItems, updatedSource, updatedDestination, startType, finishType)
	console.log("Finish Items ==>", finishItems.length, finish.list.items)
	const startItemsLength = startItems.length
	const finishItemsLength = finishItems.length
	const { updateType, append, limit } = dragDropConfig
	console.log("Drag drop config ==>", updateType, append, limit)
	if (updateType == 'replace') {
		if (startType == 'list') {
			startItems.splice(updatedSource.index, 1)
		} else {
			startItems[updatedSource.index] = droppableItem
		}

		if (finishType == 'list') {
			if (append) {
				// Pamoja questions - some may not have name
        console.log("finish ==>", finish)
				const limitAllowed = (finish.list.name || finish.list.title || "").indexOf("Possible Response") < 0
				if (limit && limit == finishItemsLength && limitAllowed) {
					startItems.splice(startItemsLength, 0, finishItems[finishItemsLength - 1])
					finishItems[finishItemsLength - 1] = draggableItem
				} else {
					finishItems.splice(finishItemsLength, 0, draggableItem)
				}
			} else {
				finishItems[updatedDestination.index] = draggableItem
			}
		} else {
			finishItems[updatedDestination.index] = draggableItem
		}
	}

	console.log("old not updated root List ==>", rootList, start, finish)
	
	newStartList = {
		...start.list,
		items: startItems
	}

	newFinishList = {
		...finish.list,
		items: finishItems
	}

	if (start.from == finish.from && start.fromType == finish.fromType) {
		if (start.fromType == 'array') {
			newStartParentList = rootList[start.from]
			newStartParentList[start.key] = newStartList
			newStartParentList[finish.key] = newFinishList
		} else {
			newStartParentList = {
				...rootList[start.from],
				[start.key]: newStartList,
				[finish.key]: newFinishList
			}
		}

		newRootList = {
			...rootList,
			[start.from]: newStartParentList,
		}
	} else {

		if (start.fromType == 'array') {
			newStartParentList = rootList[start.from]
			newStartParentList[start.key] = newStartList
		} else {
			if (rootList[start.from][start.key]) {
				newStartParentList = {
					...rootList[start.from],
					[start.key]: newStartList
				}
			} else {
				newStartParentList = {
					...rootList[start.from],
					...newStartList
				}
			}
		}

		if (finish.fromType == 'array') {
			newFinishParentList = rootList[finish.from]
			newFinishParentList[finish.key] = newFinishList
		} else {
			if (rootList[finish.from][finish.key]) {
				newFinishParentList = {
					...rootList[finish.from],
					[finish.key]: newFinishList
				}
			} else {
				newFinishParentList = {
					...rootList[finish.from],
					...newFinishList
				}
			}
		}

		newRootList = {
			...rootList,
			[start.from]: newStartParentList,
			[finish.from]: newFinishParentList
		}
	}

	console.log("updated new root list ", newStartList, newFinishList, newStartParentList, newFinishParentList, newRootList)

	return newRootList

}

export const updateOnDragEnd = (result, data, dragDropConfig) => {
	const { destination, source, draggableId, type } = result
	console.log('on drag end result, data, source and destination ==>', result, data, source, destination);
	if (!destination) {
		return
	}

	if (
		destination.droppableId === source.droppableId &&
		(destination.index === source.index || dragDropConfig.updateType == 'replace')
	) {
		return
	}

	const rootList = data.segment_data
	const { start, finish, updatedSource, updatedDestination } = getStartAndFinishList(rootList, source, destination)
	console.log("updated start and finish, source and destination ==>", start, finish, updatedSource, updatedDestination)

	if (!start || !finish) {
		return rootList
	}

	let newRootList

	if (start.list === finish.list) {
		newRootList = updateSameList(rootList, start, finish, updatedSource, updatedDestination, dragDropConfig)

	} else {
		// Moving from one list to another
		newRootList = updateDifferentList(rootList, start, finish, updatedSource, updatedDestination, dragDropConfig)
	}

	console.log('New data before return ==>', newRootList)
	return newRootList
}

