import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { List, Card, Table, Tag, Space, Tooltip } from "antd";
import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import {Link, useRouteMatch} from "react-router-dom";
import dayjs from "dayjs";
// import '../Terms.scss'
import CustomListItem from '/src/components/CustomListItem/CustomListItem'
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";


const TermsList = (props) => {
  const { dataSource, permission, deleted } = props;
  console.log("TermsList props", props);
  const mrIntl = useTranslate()
  const {path, url} = useRouteMatch();
  console.log("exp list item url", url, path);

  console.log('groups list props===>123', dataSource)

  let Title = ({item}) => (
    item.name
  )

  let Description = ({item}) => {
    return (
      <>
        {item.children && item.children.map((i) => (
          <Fragment key={i.id}>
            <Space className="description">
              <Tag style={{marginTop: '5px'}}>
                {i.name} {" - "}
                <Tooltip title={mrIntl("TermsList.starts_on")} placement="bottom"><span>{dayjs(i.starts_on).format("MMM DD, YYYY")}</span></Tooltip>
                {" "}to{" "}
                <Tooltip title={mrIntl("TermsList.ends_on")} placement="bottom"><span>{dayjs(i.ends_on).format("MMM DD, YYYY")}</span></Tooltip>
              </Tag>
            </Space>
            <br />
          </Fragment>
        ))}
      </>
    )
  }

  return (
    <>
      <List
        // header={listHeader}
        className="custom-main-list"
        // loading={initLoading}
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(item, i) => (
          <CustomListItem
            type="terms"
            item={item}
            title={<Title item={item} />}
            description={<Description item={item} />}
            // currentUser={currentUser}
            // edit={permission.term.update}
            edit={props.config.filter.config.create.show} // making it same as create btn perm - if non-mb program, allowed to create and update
            deleted={deleted}
            deletedAllow={false}
            permission={permission}
            // showForm={props.showForm}
            // setUserToSetPassword={setUserToSetPassword}
          />
        )}
      />
    </>
    
  );

};
TermsList.defaultProps = {};

TermsList.propTypes = {};

export default TermsList;
