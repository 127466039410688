import React, { useState } from "react";
import { Button, Modal, Tooltip, Row, Tag, Space, Alert, Statistic, Spin, Col, Result } from "antd";
import {
  SendOutlined,
  LeftOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { experienceConfigTakeExperienceSelector } from "/src/views/Experiences/selector";
import { getMaxEngagementPoints } from "../SegmentHelperMethods";

function EngagementPoints(props) {

  const { segment, userResponse = {} } = props
  const currentUser = useSelector(currentUserSelector())
  const experienceConfigTakeFromRedux = useSelector(experienceConfigTakeExperienceSelector())

  console.log('EngagementPoints props', props, userResponse);

  const engagementConfig = experienceConfigTakeFromRedux.engagement

  let finalRender = []
  let ePoints = getMaxEngagementPoints(segment, engagementConfig)
  let ePointsRender = <Tag className={"engagement-points-wrapper"}>{userResponse.e_points}/{ePoints}</Tag>
  ePoints !== 0 && finalRender.push(ePointsRender)

  return finalRender

};

export default EngagementPoints;