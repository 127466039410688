import React, { useEffect, useRef, useState } from "react";
import { Avatar, Form, Button, List, Input, Space } from 'antd';
import { removeItemFromArray } from "/src/views/Segments/InteractiveHelpers";
import { debounce } from "lodash";
import { Comment } from '@ant-design/compatible';
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const { TextArea } = Input;

const Editor = ({ onChange, saveComment, cancelComment, submitting, value }) => {
  const commentRef = useRef()
  const mrIntl = useTranslate()

  useEffect(() => {
    if(commentRef.current) {
      commentRef.current.focus({preventScroll: true})
    }
  }, [])

  return (
  <>
    <Form.Item>
      <TextArea
        ref={commentRef}
        value={value}
        key="comment-editor"
        maxLength={300}
        onChange={onChange}
        onBlur={() => saveComment()}
        placeholder={mrIntl("CanvasComment.comment_here")}
        className={"comment-text-area"}
        autoSize={{ minRows: 2, maxRows: 6 }}
      />
    </Form.Item>
    {/* <Form.Item>
      <Space>
        <Button
          htmlType="submit"
          loading={submitting}
          onClick={() => saveComment()}
          type="primary"
          size="small"
        >
          Save
        </Button>
        {cancelComment &&
          <Button
            htmlType="submit"
            onClick={() => cancelComment()}
            type="danger"
            size="small"
          >
            Cancel
          </Button> 
        }
      </Space>
    </Form.Item> */}
  </>
)}

const CommentList = ({ createMode, annotationMode, comments, newAddedComment, currentComment, currentCommentIndex, editComment, deleteComment, saveComment, highlightCommentIndex }) => {
  const mrIntl = useTranslate()
  
  return <List
    dataSource={comments}
    // header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
    itemLayout="horizontal"
    renderItem={(props, index) => {
      console.log("Comment props in list ==>", props)

      if (comments.length - 1 == index && newAddedComment) {
        return null
      }

      let commentActions = null, commentContent = null
      if (createMode && currentCommentIndex == index) {
        commentContent = <Editor
          onChange={(e) => saveComment(e, index, true)}
          saveComment={(e) => saveComment(e, index, false)}
          value={currentComment}
        />
      } else {
        commentContent = props.content
        commentActions =
          createMode && annotationMode
            ? [
              <span
                key="edit-comment"
                onClick={() => editComment(index)}>
                Edit
              </span>,
              <span
                key="delete-comment"
                onClick={() => deleteComment(index)}>
                Delete
              </span>
            ] : null
      }
      return (
        <Comment
          key={`comment-${props.id}`}
          author={`${mrIntl("CanvasComment.comment")} ${index + 1}`}
          content={commentContent}
          actions={commentActions}
          className={`annotation-comment-box ${highlightCommentIndex == index && 'highlight-comment'}`}
        />
      )
    }}
  />
};

const CanvasComment = (props) => {
  console.log("Canvas comment props ==>", props)
  const { createMode, floatingComment, annotationMode, parentStateSetting = {}, comments = [], saveComments, canvas } = props

  const commentsCount = comments.length
  const [state, setState] = useState({
    commentsCount: commentsCount,
  })

  // useEffect(() => {
  //   console.log("Comments count updated ==>", commentsCount, parentStateSetting.newAddedComment, parentStateSetting)
  //   if (commentsCount && parentStateSetting.newAddedComment) {
  //     let currentCommentIndex = commentsCount - 1
  //     setState({
  //       ...state,
  //       commentsCount: commentsCount,
  //       currentCommentIndex: currentCommentIndex,
  //       currentComment: comments[currentCommentIndex].content,
  //     })
  //   }
  // }, [commentsCount])

  useEffect(() => {
    if (state.currentImageIndex >= 0 && state.currentImageIndex != parentStateSetting.currentImageIndex) {
      setState({
        ...state,
        currentCommentIndex: -1,
        currentComment: '',
        currentImageIndex: parentStateSetting.currentImageIndex,
      })
    }
  }, [parentStateSetting.currentImageIndex])

  const editComment = (index) => {
    setState({
      ...state,
      currentCommentIndex: index,
      currentComment: comments[index].content
    })
  }

  const deleteComment = (index) => {
    console.log("Delete comment index ==>", index, canvas)

    let newComments = comments
    const comment = newComments[index]
    canvas.setActiveObject(canvas.getObjectsByAttr({ id: comment.id })[0])
    canvas.deleteObject()
    newComments = newComments.filter((c) => c.id != comment.id)
    // newComments.splice(index, 1)
    console.log("new spliced comments", newComments)
    saveComments(newComments)
    setState({
      ...state,
      currentCommentIndex: -1,
      currentComment: ''
    })
  }

  const saveComment = (e, index, onChnage) => {
    console.log("deleteComment(commentsCount - 1)", index, comments, state.currentComment, comments[index].author);
    if(onChnage) {
      setState({
        ...state,
        currentCommentIndex: index,
        currentComment: e.target.value
      })
    } else {
      let newComments = comments
      if (index >= 0) {
        newComments[index].content = state.currentComment
      } else {
        newComments = [...newComments, {
          author: (commentsCount + 1),
          content: state.currentComment
        }]
      }
      if(state.currentComment != undefined && state.currentComment != "") {
        saveComments(newComments)
        setState({
          ...state,
          currentCommentIndex: -1,
          currentComment: '',
        })
      } else {
        deleteComment(index)
      }
    }
  }

  console.log("canvas comment state before render ==>", state, parentStateSetting, canvas)
  return (
    <>
      {comments.length > 0 &&
        <div className={props.className}>
          {floatingComment && parentStateSetting.newAddedComment &&
            <Comment
              author={`Comment ${commentsCount}`}
              content={<Editor
                onChange={(e) => saveComment(e, commentsCount - 1, true)}
                saveComment={(e) => saveComment(e, commentsCount - 1, false)}
                cancelComment={() => deleteComment(commentsCount - 1)}
                value={state.currentComment}
              />}
              className={'floating-comment'}
            // actions={commentActions}
            />
          }

          {!floatingComment &&
            <CommentList
              {...state}
              {...parentStateSetting}
              createMode={createMode}
              annotationMode={annotationMode}
              comments={comments}
              editComment={editComment}
              deleteComment={deleteComment}
              // handleChange={handleChange}
              saveComment={saveComment}
            />
          }
        </div>
      }
    </>
  )
}

export default CanvasComment