import React from "react";
import PropTypes from "prop-types";

const TermsFilter = (props) => {
  return <span></span>;
  // return <span>Term Filter</span>;
};

TermsFilter.defaultProps = {};

TermsFilter.propTypes = {};

export default TermsFilter;
