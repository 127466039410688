import { createSelector, } from "reselect";
import { checkMob, getFromLS } from "/src/lib/utils/helperMethods";

const defaultFalseValue = false; 
const defaultQuitRequestedValue = getFromLS("quitRequested") === "true";

export const appTypeState = (state, props) => {
  //  return "ios"
  // const appType = state.login.get("appType");
  // return appType === "web" && !checkMob() ? "mac" : appType;
  return state.login.get("appType");
};

export const appTypeSelector = () =>
  createSelector([appTypeState,], appType => appType);

const executeMethodLoadingState = (state) => {
  return state.offlineApp.get("executemethodloading", defaultFalseValue);
};

export const executeMethodLoadingSelector = () =>
  createSelector([executeMethodLoadingState], (executemethodloading) => executemethodloading);

const changeSecurityLoadingState = (state) => {
  return state.offlineApp.get("changesecurityloading", defaultFalseValue);
};

export const changeSecurityLoadingSelector = () =>
  createSelector([changeSecurityLoadingState], (changesecurityloading) => changesecurityloading);

const quitLoadingState = (state) => {
  return state.offlineApp.get("quitloading", defaultFalseValue);
};

const quitRequestedState = (state) => {
  return state.offlineApp.get("quitRequested", defaultQuitRequestedValue);
}

export const quitRequestedSelector = () =>
  createSelector([quitRequestedState], (quitRequested) => quitRequested);

export const quitLoadingSelector = () =>
  createSelector([quitLoadingState], (quitloading) => quitloading);

const appValidState = (state, props) => {
  return state.login.get("appValid");
};

export const appValidSelector = () =>
  createSelector([appValidState,], appValid => appValid);

const sebConfigFileState = (state, props) => {
  return state.login.get("seb_config_file");
};

export const sebConfigFileSelector = () =>
  createSelector([sebConfigFileState,], sebConfigFile => sebConfigFile);

export const sebConfigFileLoadingState = (state) =>
  state.login.get("sebloading", false);

export const sebConfigFileLoadingSelector = () =>
  createSelector([sebConfigFileLoadingState], (sebloading) => sebloading);


const changeSecurityState = (state) => {
  return state.offlineApp.get("changeSecurity");
};

const getSecurityStatusState = (state) => {
  return state.offlineApp.get("getSecurityStatus");
};

const appUrlState = (state) => {
  return state.offlineApp.get("setAppUrl");
};

export const appEnvTypeSelector = () =>
  createSelector([appUrlState,], appEnvUrl => appEnvUrl);

export const changeSecurityStatusSelector = () =>
  createSelector([changeSecurityState,], securityStatus => securityStatus);

export const getSecurityStatusSelector = () =>
  createSelector([getSecurityStatusState,], getSecurityStatus => getSecurityStatus);
