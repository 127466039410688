import { InputNumber, Modal, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { aiModelSelector, aiModelTempSelector } from '/src/views/Orgs/selector';
import { actions } from '/src/views/Orgs/redux';
import { currentUserSelector, enabledFeaturesSelector } from '/src/views/Auth/Login/selector';
import { getFromLS } from '/src/lib/utils/helperMethods';

const ChooseAIModelModal = (props) => {
  const { setShowAIModelModal } = props;
  const dispatch = useDispatch();
  const originalUserRole = getFromLS("originalUserRole")
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const chooseAIModalEnabled = enabledFeatures.choose_ai_model;
  let envIsNotProd = import.meta.env.VITE_MODE !== "production";
  const currentUser = useSelector(currentUserSelector())
  let options = []

  // set value from selector on mount
  const aiModel = useSelector(aiModelSelector());
  const aiModelTemp = useSelector(aiModelTempSelector());
  console.log("aiModel ======>", aiModel);
  const [value, setValue] = useState(aiModel);
  const [temperature, setTemperature] = useState(aiModelTemp);
  const saveInRedux = (value) => {
    // dispatch({ type: "SET_AI_MODEL", payload: model });
    // close in success callback
    // show success message
    console.log("actions ======>", actions);
    dispatch(actions.setAiModelSuccess(value))
    setShowAIModelModal(false)
  }
  const saveTempInRedux = (value) => {
    console.log("saveTempInRedux ======>", value);
    dispatch(actions.setAiModelTempSuccess(value))
  }


  const modelOptions = {
    "OpenAI GPT": [
      {name: "O1", value: "O1"},
      // {name: "O1-mini [Text]", value: "O1-mini"},
      {name: "O3-mini [Text]", value: "O3-mini"},
      {name: "GPT 4o", value: "GPT4o", schoolSelectable: true},
      {name: "GPT 4-turbo", value: "GPT4-turbo"},
      {name: "GPT 4", value: "GPT4"}, 
      {name: "GPT 3.5", value: "GPT3.5"},
    ],
    "Anthropic Claude": [
      {name: "Claude 3.5 Sonnet", value: "Claude3.5-sonnet", schoolSelectable: true},
      {name: "Claude 3 Opus", value: "Claude3-opus", schoolSelectable: true},
      {name: "Claude 3 Sonnet", value: "Claude3-sonnet"},
      {name: "Claude 3 Haiku", value: "Claude3-haiku"},
    ],
    "Google Gemini": [
      {name: "Gemini 2.0 Pro", value: "Gemini-2-pro"},
      {name: "Gemini 2.0 Flash thinking", value: "Gemini-2-flash-thinking"},
      {name: "Gemini 2.0 Flash", value: "Gemini-2-flash"},
      {name: "Gemini 1.5 Pro", value: "Gemini-1.5-pro", schoolSelectable: true},
      {name: "Gemini 1.5 Flash", value: "Gemini-1.5-Flash"},
    ]
  }

  let modelOptionsRender = []
  for (const key in modelOptions) {
    if (Object.hasOwnProperty.call(modelOptions, key)) {
      const element = modelOptions[key];
      let optionRender = []
      optionRender.push(
        <b>{key}</b>
      )
      let radioOptions = []
      element.forEach((model) => {
        // only show school selectable models if chooseAIModalEnabled is true - currently only for Korea jeju university experiment
        if ((chooseAIModalEnabled && model.schoolSelectable) || envIsNotProd || currentUser.role === "qb_author") {
          radioOptions.push(
            <Radio value={model.value} disabled={model.disabled}>{model.name}</Radio>
          )
        } 
      })
      optionRender.push(
        <Radio.Group className='m-t-10 m-b-10' onChange={(e) => setValue(e.target.value)} value={value} buttonStyle={"solid"} optionType={"button"}>
          {radioOptions}
        </Radio.Group>
      )
      modelOptionsRender.push(optionRender)
      
    }
  }


  return <Modal
    visible={true}
    title="Choose base AI model"
    width="800px"
    closable={true}
    onCancel={() => setShowAIModelModal(false)}
    onOk={() => {saveInRedux(value); saveTempInRedux(temperature)}}
    okText="Save"
  >

    <Space direction="vertical">
      {!chooseAIModalEnabled && <i>[Defaults: GPT3.5-turbo for chat, GPT4o for everything else]</i>}
      {!chooseAIModalEnabled && <i>[Note: GPT4 will default to 4o when vision needed]</i>}
      <br></br>

      {modelOptionsRender}

      {((chooseAIModalEnabled && originalUserRole === "superadmin") || envIsNotProd) && <Space direction='vertical'>
        <hr></hr>
        <b>Other options</b>
        <InputNumber addonBefore={"Temp"} min={0.1} max={2} step={0.1} defaultValue={1} onChange={(val) => setTemperature(val)} value={temperature} /> [Lower is more conservative, higher is more creative] [0-1 for claude, 0.1-2 for others]
      </Space>}
    </Space>

  </Modal>
}

export default ChooseAIModelModal;
