import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { msalConfig, msLoginRequest } from '/src/lib/microsoft-login/authConfig';
import { Button } from 'antd';
import msalObj from '/src/lib/microsoft-login/msalInstance';
// import {PublicClientApplication} from "@azure/msal-browser";
let username = "";
const MsLogin = (props) => {
  const {
    successCallback,
    errorCallback,
    forceRedirectStrategy,
    onMicrosoftLoginStart,
    microsoftLoading,
  } = props;
  // let msalObj;
  
  useEffect(() => {
    if(forceRedirectStrategy){
      msalObj.handleRedirectPromise()
      .then(successCallback)
      .catch(errorCallback);
    }
    return () => {
      
    }
  }, [])

  const initiateMicrosoftLogin = () => {
    if(msalObj && !microsoftLoading){
      onMicrosoftLoginStart();
      if(forceRedirectStrategy){
        msalObj.loginRedirect(msLoginRequest);
      } else {
        msalObj.loginPopup(msLoginRequest)
          .then(successCallback)
          .catch(errorCallback);
        
      }
    }
  }

  return <span onClick={() => initiateMicrosoftLogin()}>{props.children}</span>;
};

MsLogin.defaultProps = {}

MsLogin.propTypes = {}

export default MsLogin;