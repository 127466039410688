import React, { useState } from "react";
import {
  Button,
  Tag,
  Space,
  Result,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import QuitBtn from "/src/components/UI/QuitBtn";
import { useHistory, useRouteMatch } from "react-router-dom";
import { setUserInfoLoadingSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { useSelector } from "react-redux";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import LogoutBtn from "/src/components/UI/LogoutBtn";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import BackToHomeBtn from "/src/components/UI/BackToHomeBtn";
import { checkIfSEBHasDynamicConfig } from "/src/App/OfflineApp/offlineAppHelper";

const SubmitViewOld = (props) => {
  const { experienceProps, videoRender } = props;
  const {
    experience,
    embedded,
    setUserInfo,
    experienceSettings,
  } = experienceProps;

  const extra = [];

  const { url } = useRouteMatch();
  const history = useHistory();

  const isSetUserInfoLoading = useSelector(setUserInfoLoadingSelector);
  const appType = useSelector(appTypeSelector());
  const mrIntl = useTranslate();
  const isMBLinked = experience.is_mb_linked

  experienceSettings.assessment_type == "quick_check" &&
    extra.push(
      <Button
        loading={isSetUserInfoLoading}
        type="primary"
        onClick={() => {
          // openUrl(url)
          // history.replace(url);
          // history.replace(url.concat("/grade"));
          setUserInfo({ currentView: "viewFeedback" });
        }}
      >
        <MrTranslate id={"SubmitView.view_results"}/>
      </Button>
    );

  !embedded && !isMBLinked &&
    appType == "web" &&
    extra.push(<BackToHomeBtn key="submit-view-back-to-home-button" experience={experience} action="back_to_home_after_submit"/>);

  // only for lockdown apps
  if (!checkIfSEBHasDynamicConfig(appType)) {
    extra.push(<LogoutBtn withLog={true} action={"log_out_after_submit"} experience = {experience} />);
  }
  extra.push(<QuitBtn withLog={true} action={"quit_app_after_submit"} />);

  let testSubmittedSubtitle = (
    <div>
      <Space direction="vertical" style={{ marginTop: "20px" }}>
        <Tag color="processing" icon={<CheckOutlined />}>
          <MrTranslate id={"SubmitView.submitted_successfully"}/>
        </Tag>
      </Space>
      {/* {!embedded && experienceSettings.enable_ap_video_monitoring && (
        <Col span={20} offset={2} className="m-t-15">
          <div style={{width: "100%", height: '350px'}}> 
            {videoRender}
          </div>
        </Col>
      )} */}
    </div>
  );

  let finalRender = (
    <React.Fragment>
      <Result
        className="submit-test-result"
        status="success"
        title={experience.name}
        subTitle={testSubmittedSubtitle}
        extra={extra}
        // status="error"
        // extra={[
        // <Button onClick={() => setUserInfo({currentView: "startTest"})}> Start again</Button>,
        // <Button type="primary" onClick={() => {console.log("view results", url); window.location.href = url}}> View Results</Button>,
        // ]}
      />
    </React.Fragment>
  );

  return finalRender;
};

export default SubmitViewOld;
