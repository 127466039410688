import React, {useEffect, useState, useRef} from "react";
import { MrCrudView } from "mr_react_framework";
import { actions } from "./redux";
import UserListItem from "./UserListItem/UserListItem";
import UsersFilter from "./UsersFilter/UsersFilter";
import UsersList from "./UsersList/UsersList";
import UserItemDetail from "./UserItemDetail/UserItemDetail";
import commonSelectors from "/src/App/commonSelectors";
import { Input, Switch, Form} from "antd";
import { MrSelect, FormRenderer } from "mr_react_framework";
import { actions as genericActions } from "/src/App/genericRedux";
import SelectRoleDropdown from "/src/components/AppSpecific/SelectRole/SelectRoleDropdown";
import SelectOrgDropdown from "/src/components/AppSpecific/SelectOrg/SelectOrgDropdown";
import {
  SelectParent,
  SelectProgram,
} from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import { SelectGrade } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import _ from "lodash";
import MrTranslate, { mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import CustomExperienceCrudList from "/src/views/Experiences/CustomExperienceCrudList";
import { useSelector } from "react-redux";

const SelectOrgQuestionbank = MrSelect({
  actions: genericActions,
  resourceName: "orgquestionbanks",
  apiResourceName: "org_questionbanks",
  // resourceName: "questionbanks",
  config: {
    url: "/org_questionbanks",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.custom_fields.title, value: item.questionbank_id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: <MrTranslate id={"CommonText.select_questionbank"}/>,
      mode: "multiple",
      style: { width: "300px" },
      // style: { width: "100%" },
    },
  },
});

const SelectClass = MrSelect({
  actions: genericActions,
  resourceName: "groups",
  apiResourceName: "groups",
  config: {
    url: "/groups",
    // params: {by_role: "student"}, //default is org_id on BE
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.name, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select classes",
      placeholder: <MrTranslate id={"CommonText.select_classes"}/>,
      mode: "multiple",
      // style: {width: "400px"},
      // onDeselect: (val) => { console.log("val", val)}
    },
  },
});

const SelectActiveOrgUser = MrSelect({
  // actions: genericActions,
  // resourceName: "groups",
  // apiResourceName: "groups",
  config: {
    // url: "/groups",
    // params: {by_role: "student"}, //default is org_id on BE
    processData: (data, props) => {
      console.log("data select org_users", data);
      console.log("props select org_users", props);
      return data.map((item) => {
        console.log("org_users selector item", item);
        return {
          label: `${item.org_id} - ${item.custom_fields.org_name} - ${item.first_name} ${item.last_name} - ${item.role} - ${item.source_id} - ${item.uuid} - ${item.archived}`,
          value: item.id,
        };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"Users.select_active_org_user_placeholder"}/>,
      // mode: "multiple",
      // style: {width: "400px"},
      // onDeselect: (val) => { console.log("val", val)}
    },
  },
});

const getSelectUserRelationsField = (sourceValues) => {
  console.log("getSelectUserRelationsField sourceValues", sourceValues);
  return {
    type: "object",
    properties: {
      user_relations: {
        type: "array",
        addButtonTitle: `+ ${<MrTranslate id={"Users.add_parent_button"}/>}`,
        title: "Parents",
        span: 24,
        min: 0,
        max: 4,
        default: [{}],
        items: {
          type: "object",
          properties: {
            relation_id: {
              type: "string",
              // title: "Select parent",
              // default: "Term 1"
              span: 8,
              widget: SelectParent,
            },
          },
        },
      },
    },
  };
};

const selectOrgQuestionbankField = {
  type: "object",
  properties: {
    allowed_questionbank_ids: {
      // title: "Select qb",
      type: "string",
      span: 12,
      placeholder: <MrTranslate id={"Users.select_qb_placeholder"}/>,
      setInitialValue: true,
      widget: SelectOrgQuestionbank,
    },
  },
};

const switchCanViewAllExpField = {
  type: "object",
  properties: {
    can_view_all_experiences: {
      // title: "Select qb",
      type: "string",
      span: 24,
      title: "Can view all experiences",
      setInitialValue: true,
      widget: Switch,
      valuePropName: "checked",
    },
  },
};

const switchCanArchiveQBExpField = {
  type: "object",
  properties: {
    can_archive_qb_experiences: {
      // title: "Select qb",
      type: "string",
      span: 24,
      title: "Can archive QB experiences (drafts only)",
      setInitialValue: true,
      widget: Switch,
      valuePropName: "checked",
    },
  },
};

const switchCanEditTagsField = {
  type: "object",
  properties: {
    can_edit_tags: {
      // title: "Select qb",
      type: "string",
      span: 24,
      title: "Can edit tags",
      setInitialValue: true,
      widget: Switch,
      valuePropName: "checked",
    },
  },
};

const ChildComponent = ({ getFieldValue, sourceValues, formInstance }) => {
  const role = getFieldValue(["role"]);
  const orgProgrammeId = getFieldValue(["org_programme_id"]);
  const mrIntl = useTranslate()
  let editAllowed = (sourceValues.org_users && sourceValues.org_users.length > 1) ? false : true
  
  let finalRender = null;

  if (
    role === "student" ||
    (!role && sourceValues.id && sourceValues.role === "student")
  ) {
    if (orgProgrammeId) {
      // const org_programme_id = formInstance.getFieldValue(["org_programme_id"])
      // finalRender = <Form.Item>
      //   <SelectGrade config={{forceReload: true, params: {by_org_programme_id: org_programme_id}}}/>
      // </Form.Item>
      finalRender = (
        <FormRenderer
          formObj={getSelectGradeField(editAllowed, mrIntl)}
          sourceValues={sourceValues}
          formInstance={formInstance}
        />
      );
    }
  }
  return finalRender;
};

const PasswordCustomChildComponent = (props) => {
  const { value = "", onChange } = props;
  const mrIntl = useTranslate()

  const handleInputChange = (value) => {
    const newValue = value.replace(/\s/g, '');
    onChange(newValue);
  };

  return (
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: mrIntl("CommonText.please_enter_a_password")
        },
        {
          min: 8,
          message: mrIntl("Users.minimum_characters")
        },
        {
          max: 20,
          message: mrIntl("Users.password_cannot_exceed_20_characters")
        }
      ]}
    >
      <Input.Password
        placeholder={mrIntl("Users.password")}
        value={value}
        onChange={(e) => handleInputChange(e.target.value)}
      />

    </Form.Item>
  )
}

const PasswordChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const email = getFieldValue(["email"]);
  const userId = sourceValues.id;
  let finalRender = null;
  const mrIntl = useTranslate();

  if (email && !userId) {
    finalRender = (
      <FormRenderer
        formObj={{
          type: "object",
          properties: {
            password: {
              type: "string",
              title: mrIntl("CommonText.password"),
              // widget: Input.Password,
              // default: getUniqueId(8),

              span: 24,
              widget: PasswordCustomChildComponent,
            },
          },
        }}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};

const OrgProgrammeChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  console.log("OrgProgrammeChildComponent", sourceValues);
  const currentUser = useSelector(currentUserSelector());
  let editAllowed = (sourceValues.org_users && sourceValues.org_users.length > 1) ? false : true 

  const isMB = currentUser.org.is_mb;

  const role = getFieldValue(["role"]);
  let finalRender = null;

  if (
    role === "student" ||
    (!role && sourceValues.id && sourceValues.role === "student")
  ) {
    finalRender = (
      <FormRenderer
        formObj={
          {
            type: "object",
            properties: {
              org_programme_id: {
                title: <MrTranslate id={"CommonText.select_program"}/>,
                type: "string",
                span: 24,
                placeholder: <MrTranslate id={"CommonText.select_program"}/>,
                setInitialValue: true,
                widget: SelectProgram,
                widgetConfig: {
                  disabled: !editAllowed,
                  disableMBIntegratedProgrammes: isMB,
                },
                rules: [
                  {
                    required: true,
                    message: <MrTranslate id={"CommonText.please_choose_a_program"}/>,
                  },
                ],
              },
            },
          }
        }
        sourceValues={sourceValues}
      />
    );
  }
  return finalRender;
};

const UserRelationsChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const role = getFieldValue(["role"]);
  let finalRender = null;

  if (
    role === "student" ||
    (!role && sourceValues.id && sourceValues.role === "student")
  ) {
    finalRender = (
      <FormRenderer
        formObj={getSelectUserRelationsField(sourceValues)}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};

const OrgQuestionbankChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const role = getFieldValue(["role"]);
  let finalRender = null;

  if (
    role === "qb_author" ||
    (!role && sourceValues.id && sourceValues.role === "qb_author")
  ) {
    finalRender = (
      <FormRenderer
        formObj={selectOrgQuestionbankField}
        propertyKey={"custom_fields"}
        sourceValues={sourceValues}
      />
    );
  }
  return finalRender;
};

const CanViewAllExpChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const role = getFieldValue(["role"]);
  let finalRender = null;

  if (
    role === "qb_author" ||
    role === "superadmin" ||
    role === "support" ||
    (!role &&
      sourceValues.id &&
      (sourceValues.role === "qb_author" ||
        role === "superadmin" ||
        role === "support"))
  ) {
    finalRender = (
      <FormRenderer
        formObj={switchCanViewAllExpField}
        propertyKey={"custom_fields"}
        sourceValues={sourceValues}
      />
    );
  }
  return finalRender;
};

const CanArchiveQBExpChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const role = getFieldValue(["role"]);
  let finalRender = null;

  if (
    (role === "qb_author") ||
    (!role &&
      sourceValues.id &&
      sourceValues.role === "qb_author")
  ) {
    finalRender = (
      <FormRenderer
        formObj={switchCanArchiveQBExpField}
        propertyKey={"custom_fields"}
        sourceValues={sourceValues}
      />
    );
  }
  return finalRender;
};

const CanEditTagsChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const role = getFieldValue(["role"]);
  let finalRender = null;

  if (
    (role === "qb_author") ||
    (!role &&
      sourceValues.id &&
      sourceValues.role === "qb_author")
  ) {
    finalRender = (
      <FormRenderer
        formObj={switchCanEditTagsField}
        propertyKey={"custom_fields"}
        sourceValues={sourceValues}
      />
    );
  }
  return finalRender;
};

const GenericWidgetCreator = (hocProps, Component) => {
  return (props) => {
    let finalHocProps = {};
    if (typeof hocProps === "function") {
      finalHocProps = hocProps(props);
    }
    return <Component {...props} {...finalHocProps} />;
  };
};

const SelectGradeWidget = GenericWidgetCreator((props) => {
  return {
    config: {
      forceReload: true,
      params: {
        by_org_programme_id: props.formInstance.getFieldValue([
          "org_programme_id",
        ]),
      },
    },
  };
}, SelectGrade);

// const SelectClassWidget = GenericWidgetCreator((props) => {
//   console.log("SelectClassWidget org_p_id", props.formInstance.getFieldValue(['org_programme_id']), props.formInstance.getFieldValue(['grade_id']));

//   return {config: {forceReload: true, params: {by_org_programme_id: props.formInstance.getFieldValue(["org_programme_id"]), by_grade_id: props.formInstance.getFieldValue(["grade_id"])}}}
// }, SelectClass);

const SelectActiveOrgUserWidget = GenericWidgetCreator((props) => {
  console.log(
    "SelectActiveOrgUserWidget props",
    props,
    props.formInstance.getFieldValue(["org_users"])
  );
  let currentOrgUsers = props.formInstance.getFieldValue(["org_users"]) || [];
  return { config: { widgetConfig: { options: currentOrgUsers } } };
}, SelectActiveOrgUser);

// const selectClassField = {
//   type: "object",
//   properties: {
//     group_user_ids: {
//       title: "Select classes",
//       type: "string",
//       span: 12,
//       setInitialValue: true,
//       widget: SelectClassWidget,
//     },
//   }
// };

// const ClassSelectComponent = ({getFieldValue, sourceValues, formInstance}) => {
//   const role = getFieldValue(['role'])
//   const orgProgrammeId = getFieldValue(['org_programme_id'])
//   const gradeId = getFieldValue(['grade_id'])
//   let finalRender = null;

//   if (role === "student" || (!role && sourceValues.id && sourceValues.role === "student")) {
//     if(orgProgrammeId && gradeId){
//       finalRender = <FormRenderer
//         formObj={selectClassField}
//         sourceValues={sourceValues}
//         formInstance={formInstance}
//       />
//     }
//   }
//   return finalRender
// }

const getSelectGradeField = (editAllowed = true, mrIntl) => {
  return {
    type: "object",
    properties: {
      grade_id: {
        title: mrIntl("Users.select_grade"),
        // title: "Select grade",
        type: "string",
        span: 24,
        setInitialValue: true,
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.please_choose_a_grade"),
            // message: "Please choose a grade",
          },
        ],
        widget: SelectGradeWidget,
        widgetConfig: {
          disabled: !editAllowed
        },
        wrapClassName: "select-grade-field",
        // generating dynamically using GenericWidgetCreator
        // widget: (props) =>
        // <SelectGrade value={props.value} onChange={props.onChange} config={{forceReload: false, params: {by_org_programme_id: org_programme_id}}}/>,
      },
    },
  };
};

export const returnForm = (props) => {
  const { mrIntl } = props

  console.log("props in users.js==>", props);
  let currentUser = props.currentUser;
  const enabledFeatures = props.enabledFeatures;
  // For now disabling manual edit if more that 1 org_user
  const values = props.formState.values;
  const isMB = currentUser.org.is_mb;
  const currentUserPermissions = currentUser.permission || {};
  const isNonMBProgramEnabledForMBIntegration = isMB && currentUserPermissions.user?.create;

  let editAllowed = (values.org_users && values.org_users.length > 1) ? false : true 

  console.log("props in users.js==> editAllowed", editAllowed);
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      let group_users_attributes = [];

      let toCreate = null;
      let toRemove = null;
      let toUnarchive = null;
      let existing = null;
      let modified = null;
      // create/update
      // create if id exists in opts.values.group_student_ids but not in dataProps.item.group_users_students.pluck("user_id")
      if (opts.values.group_student_ids) {
        modified = opts.values.group_student_ids;
      }
      // added to create OrgUser for manually created users
      if(dataProps.item && !dataProps.item.id){
        opts.values.should_create_org_user = true;
      }
      // IMP: edit disabled for superadmin and support. only allowed via school admin - need to send accurate org_user_org_id
      // added to update OrgUser when manually editing users in admin view
      if(dataProps.item && dataProps.item.id){
        opts.values.should_update_org_user = true;
        opts.values.org_user_org_id = currentUser.org_id;
      }
      if (dataProps.item && dataProps.item.group_users_students) {
        existing = _.map(dataProps.item.group_users_students, "group_id");
      }
      toCreate = _.difference(modified, existing);
      toRemove = _.difference(existing, modified);
      toUnarchive = _.intersection(existing, modified);

      console.log("toCreate, toRemove ", toCreate, toRemove);
      if (toCreate) {
        toCreate.map((groupId, i) => {
          group_users_attributes.push({
            relation: "student",
            group_id: groupId,
          });
        });
      }
      if (toRemove) {
        toRemove.map((groupId, i) => {
          console.log(
            "groupId, existing ",
            groupId,
            existing,
            dataProps.item.group_users_students
          );
          let gu = _.find(dataProps.item.group_users_students, [
            "group_id",
            groupId,
          ]);
          group_users_attributes.push({
            id: gu.id,
            // relation: "student",
            // group_id: groupId,
            archived: true,
          });
        });
      }
      if (toUnarchive) {
        toUnarchive.map((groupId, i) => {
          console.log(
            "groupId, existing ",
            groupId,
            existing,
            dataProps.item.group_users_students
          );
          let gu = _.find(dataProps.item.group_users_students, [
            "group_id",
            groupId,
          ]);
          group_users_attributes.push({
            id: gu.id,
            // relation: "student",
            // group_id: groupId,
            archived: false,
          });
        });
      }

      opts.values.group_users_attributes = group_users_attributes;

      opts.values.user_relations_attributes = opts.values.user_relations;

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        first_name: {
          type: "string",
          title: mrIntl("CommonText.first_name"),
          // title: "First Name",
          span: 9,
          widgetConfig: {
            disabled: !editAllowed,
          },
          rules: [
            {
              required: true,
              message: mrIntl("Users.please_enter_your_first_name"),
              // message: "Please enter your first name",
            },
          ],
        },
        last_name: {
          type: "string",
          title: mrIntl("CommonText.last_name"),
          // title: "Last Name",
          span: 9,
          widgetConfig: {
            disabled: !editAllowed,
          },
        },
        display_name: {
          type: "string",
          title: mrIntl("CommonText.display_name"),
          // title: "Display Name",
          span: 6,
          widgetConfig: {
            disabled: !editAllowed,
          },
        },
        email: {
          type: "string",
          title: mrIntl("Users.email"),
          // title: "Email",
          span: 12,
          widgetConfig: {
            disabled: !editAllowed,
          },
          rules: [
            {
              required: true,
              //I added the type because we are experiencing issues with emails. To address the email issue, I added the type for that.
              type: 'email',
              message: mrIntl("Users.please_enter_a_valid_email"),
              // message: "Please enter a valid email",
            },
          ],
        },
        password: {
          type: "string",
          title: mrIntl("Users.password"),
          // widget: Input.Password,
          default: getUniqueId(10),
          span: 12,
          dependsOn: ["email"],
          children: PasswordChildComponent,
          // wrapClassName: props.formState.values.id && props.currentUser.role != "superadmin" ? "hidden" : "",
          // wrapClassName: props.formState.values.id ? "hidden" : "",
          // rules: [
          //   {
          //     // required: props.formState.values.id ? false : true,
          //     required: true,
          //     message: "Please enter a password"
          //   },
          //   {
          //     min: 8,
          //     message: "Minimum 8 characters"
          //   }
          // ]
        },
        role: {
          type: "string",
          title: mrIntl("Users.role"),
          // title: "Role",
          placeholder: mrIntl("Users.select_role"),
          // placeholder: "Select Role",
          // widget: "SelectWidget",
          span: 8,
          wrapClassName: "select-role",
          widgetConfig: {
            disabled: !editAllowed,
            allowedRoles: isNonMBProgramEnabledForMBIntegration && ["student"]
          },
          rules: [
            {
              required: true,
              message: mrIntl("Users.please_choose_a_role"),
              // message: "Please choose a role",
            },
          ],
          widget: SelectRoleDropdown,
          // widgetConfig: {
          // options: buildOptionsArr(["admin", "teacher", "student", "parent"])
          // },
        },

        org_programme_id: {
          type: "string",
          dependsOn: ["role"],
          span: 8,
          children: OrgProgrammeChildComponent,
        },
        grade_id: {
          type: "string",
          span: 8,
          dependsOn: ["role", "org_programme_id"],
          children: ChildComponent,
          // widget: (props) => {console.log("widget props", props); return null}
          // widget: DynamicSelectGrade
          // getSelectGrade(props.formInstance.getFieldValue(["org_programme_id"]))
          // widget: (props) => {
          //   console.log("widget props", props);
          //   return <SelectGrade config={{forceReload: true, params: {by_org_programme_id: props.formInstance.getFieldValue(["org_programme_id"])}}}/>
          // }
        },
        // group_user_ids: {
        //   type: "string",
        //   span: 12,
        //   dependsOn: ["role", "org_programme_id", "grade_id"],
        //   children: ClassSelectComponent
        // },
        org_id: {
          type: "string",
          span: 12,
          default:
            props.currentUser.role === "superadmin"
              ? props.formState.values.org_id
              : props.formState.values.id
              ? props.formState.values.org_id
              : props.currentUser.org.id,
          rules: [
            {
              required: true,
              message: mrIntl("Users.please_choose_a_school_msg"),
            },
          ],
          widget: SelectOrgDropdown,
          widgetConfig: {
            disabled:
              props.formState.values && props.formState.values.org_id
                ? true
                : false,
          },
          className:
            props.currentUser.role !== "superadmin" &&
            props.currentUser.role !== "support"
              ? "hidden"
              : "",
        },
        // user_relations: {
        //   type: "string",
        //   span: 24,
        //   // className: props.currentUser.role != "superadmin" ? "hidden" : "", // doesn't work with children @abhimanyu please fix
        //   dependsOn: ["role"],
        //   children: UserRelationsChildComponent,
        // },
        custom_fields: {
          type: "object",
          span: 24,
          properties: {
            allowed_questionbank_ids: {
              type: "string",
              span: 24,
              dependsOn: ["role"],
              children: OrgQuestionbankChildComponent,
            },
            can_view_all_experiences: {
              type: "string",
              span: 24,
              dependsOn: ["role"],
              children: CanViewAllExpChildComponent,
            },
            can_archive_qb_experiences: {
              type: "string",
              span: 24,
              dependsOn: ["role"],
              children: CanArchiveQBExpChildComponent,
            },
            can_edit_tags: {
              type: "string",
              span: 24,
              dependsOn: ["role"],
              children: CanEditTagsChildComponent,
            },
            // citizenship: {
            //   type: "string",
            //   placeholder: "Citizenship",
            // },
            // school_region: {
            //   type: "string",
            //   placeholder: "Emirate",
            // },
          },
        },
      },
    },
  };

  if (props.currentUser.role === "superadmin" || (enabledFeatures.student_parent_mapping && !props.currentUser.org.is_mb)) {
    form.schema.properties.user_relations = {
      type: "string",
      span: 24,
      // className: props.currentUser.role != "superadmin" ? "hidden" : "", // doesn't work with children @abhimanyu please fix
      dependsOn: ["role"],
      children: UserRelationsChildComponent,
    };
  }

  // if (
  //   props.currentUser.role === "superadmin" ||
  //   props.currentUser.role === "support"
  // ) {
  //   // org_users just to add it to formInstance to use in SelectActiveOrgUserWidget
  //   form.schema.properties.org_users = {
  //     type: "string",
  //     span: 24,
  //     className: "hidden",
  //   };
  //   if (props.formState.values.id) {
  //     // only for update, not create
  //     form.schema.properties.active_org_user_id = {
  //       type: "string",
  //       span: 24,
  //       title: "Select active org user",
  //       // className: props.formState.values.org_users.length > 1 ? "" : "hidden",
  //       widget: SelectActiveOrgUserWidget,
  //       // dependsOn: ["role"],
  //       // children: OrgUsersChildComponent,
  //     };
  //   }
  // }
  return form;
};

export const returnSetActiveOrgUserForm = (props) => {
  console.log("props in users.js======>", props);
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {}
    },
  };
  // org_users just to add it to formInstance to use in SelectActiveOrgUserWidget
  form.schema.properties.org_users = {
    type: "string",
    span: 24,
    className: "hidden",
  };
  if (props.formState.values.id) {
    // only for update, not create
    form.schema.properties.active_org_user_id = {
      type: "string",
      span: 24,
      title: <MrTranslate id={"Users.select_active_org_user_title"}/>,
      // className: props.formState.values.org_users.length > 1 ? "" : "hidden",
      widget: SelectActiveOrgUserWidget,
      // dependsOn: ["role"],
      // children: OrgUsersChildComponent,
    };
  }
  return form;
};

export const returnSetPasswordForm = (props) => {
  const { mrIntl } = props

  console.log("props in users.js==>", props);
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        password: {
          type: "string",
          // placeholder: "Password",
          placeholder: mrIntl("CommonText.password"),
          span: 18,
          widget: PasswordCustomChildComponent,
        },
      },
    },
  };
  return form;
};

export const tempFormObj = () => {
  
  
  let form = {
    schema: {
      type: "object",
      properties: {
        first_name1: {
          type: "string",
          placeholder: <MrTranslate id = {"CommonText.first_name"}/>,
          // placeholder: "First Name",
          rules: [
            {
              required: true,
              message: <MrTranslate id={"Users.please_enter_your_first_name"}/>,
            },
          ],
        },
        last_name1: {
          type: "string",
          placeholder: <MrTranslate id = {"CommonText.last_name"}/>,
          // placeholder: "Last Name",
        },
      },
    },
    uiSchema: {
      // settings: {
      //   // "ui:field": "json"
      //   "ui:widget": "JsonWidget",
      // },
    },
  };
  return form;
};

const Users = MrCrudView({
  resourceName: "users",
  singleResourceName: "user",
  displayName: "Create User",
  resourceUrl: "/users",
  // layout: OrgsLayout,
  filter: UsersFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
    enabledFeatures: enabledFeaturesSelector,
  },
  listItem: UserListItem,
  forms: [
    {
      name: "users",
      form: returnForm,
      config: { isModal: true, visible: true },
    },
    {
      name: "usersSetPassword",
      form: returnSetPasswordForm,
      config: { actions: { submitText: <MrTranslate id = {"Users.update"}/>, showCancelBtn: true } },
    },
    {
      name: "usersSetActiveOrgUser",
      form: returnSetActiveOrgUserForm,
      config: { actions: { submitText: <MrTranslate id={"Users.update"}/>, showCancelBtn: true } },
    },
  ],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  config: {
    itemPreview: {
      widget: UserListItem,
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: UserItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      // config: {create: {show: false}}
      style: {
        width: 6,
        align: "right"
      },
      config: {
        search: {
          show: false // default hide in filter render
        },
        create: {
          show: true
        },
        actions: {
          show: true
        }
      }
    },
    core: {
      setQueryParams: true,
    },
    list: {
      forceReload: true,
      show: false,
      widget: UsersList,
      layout: CustomExperienceCrudList,
      // forceReload: true
    },
  },
});

Users.defaultProps = {};

Users.propTypes = {};
export default Users;
