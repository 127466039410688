import React from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { MrFormComponent } from "mr_react_framework";
import queryString from "query-string";
import { Divider } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../Login/redux";
import {
  isAuthenticatedSelector,
  resetpasswordloadingSelector,
} from "../Login/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const getForgotPassForm = (props, mrIntl) => {
  return {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      // opts.values.email = opts.values.password.toLowerCase().trim()
      // trim and downcase this
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        password: {
          type: "string",
          // placeholder: "New password",
          placeholder: mrIntl("SetPassword.new_password"),
          // widget: Input.Password,
          widget: "PasswordWidget",
          className: "login-password",
          rules: [
            {
              // required: props.formState.values.id ? false : true,
              required: true,
              message: mrIntl("CommonText.please_enter_a_password"),
            },
            {
              min: 8,
              message: mrIntl("SetPassword.minimum_characters"),
            },
          ],
        },
        confirm_password: {
          type: "string",
          placeholder: mrIntl("SetPassword.confirm_password"),
          // widget: Input.Password,
          widget: "PasswordWidget",
          widgetConfig: {
            dependencies: ["password"],
          },
          className: "login-password",
          rules: [
            {
              // required: props.formState.values.id ? false : true,
              required: true,
              message: mrIntl("CommonText.please_enter_a_password"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  mrIntl("SetPassword.the_two_passwords_that_you_entered_do_not_match"),
                );
              },
            }),
          ],
        },
      },
    },
  };
};
const SetPassword = (props) => {
  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  const jsonForm = getForgotPassForm(props, mrIntl);
  const location = useLocation();
  const loading = useSelector(resetpasswordloadingSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector());
  const queryParams = queryString.parse(location.search);
  const handleSubmit = (data) => {
    const finalData = { user: { ...data } };
    console.log("handleSubmit", finalData);
    dispatch(
      actions.resetpassword(
        {
          reset_token: queryParams.reset_token,
          ...data,
        },
        {
          success: {
            showMessage: true,
            message: `${mrIntl("SetPassword.password_successfully_changed")}.`,
          },
        }
      )
    );
  };
  useEffect(() => {
    console.log("location", location);
    // return () => {
    //   cleanup
    // }
  }, [location]);
  console.log("location", location);
  if (isAuthenticated) {
    return <Redirect to={"/"} />;
  }
  if (!queryParams || (queryParams && !queryParams.reset_token)) {
    return <span><MrTranslate id={"SetPassword.no_reset_token_found"} />
    </span>;
  }

  const mainForm = (
    <MrFormComponent
      key={"mrForm"}
      {...props}
      visible={true}
      resourceForm={{
        form: { ...jsonForm },
        config: { isModal: false, visible: true },
      }}
      item={{}}
      loading={loading}
      // formConfig={{actions: {submitText: "Login", showCancelBtn: false}}}
      formConfig={{ actions: { submitText: mrIntl("CommonText.submit"), showCancelBtn: false } }}
      formProps={{}}
      // jsonForm={jsonForm}
      // schema={jsonForm.schema}
      handleOk={handleSubmit}
      handleSubmit={handleSubmit}
      // styleProps={styleProps}
    />
  );

  return (
    <>
      <h3>{mrIntl("SetPassword.set_new_password")}</h3>
      <br></br>
      {mainForm}
      <Divider>{mrIntl("CommonText.or")}</Divider>
      <div class={"back-to-login-link"}>
        <Link to={"/auth/login"}>{mrIntl("CommonText.go_back_to_login")}</Link>
      </div>
    </>
  );
};

SetPassword.defaultProps = {};

SetPassword.propTypes = {};

export default SetPassword;
