import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, Space, Checkbox, Button, Spin } from "antd";
import {
  CloseOutlined,
} from "@ant-design/icons";
import { checkMob, getUniqueId } from "/src/lib/utils/helperMethods";
import {
  Segment,
  SegmentData,
  showConfirmModal,
} from "/src/components/UI/Segment/UIHelper";
import { getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import SegmentItemHeader from "/src/views/Segments/SegmentItemDetail/SegmentItemHeader";
import { cloneDeep, debounce } from "lodash";
import MrSelect from "/src/components/UI/MrSelect/MrSelect";
import Modal from "antd/lib/modal/Modal";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const useGGBGraph = (parameters, modalOpened, onChange) => {
  const { id, appName, reInitGGb, showAlgebraInput } = parameters;
  console.log(
    "use ggb graph parameters ==>", parameters
  );
  return useCallback(
    (node) => {
      if (node && modalOpened) {
        const ggbVersion = parameters.ggbVersion || true;
        const ggbApp = new window.GGBApplet(parameters, ggbVersion);
        ggbApp.setHTML5Codebase("https://www.geogebra.org/apps/latest/web3d");  // Not loading latest because when Geogebra loads directly on page its setting wrong base - assessprep.com getting appended in initial
        ggbApp.inject(node);
        console.log("ggb app with node", ggbApp, node, modalOpened);

        // window.ggbOnInit = function (param) {
        // 	console.log("ON change in ggbon init", param, id)

        // 	if (onChange) {
        // 		onChange(window[param], param, id);
        // 	}
        // };
      }
    },
    [id, appName, reInitGGb, showAlgebraInput, modalOpened]
  );
};

const GGBGraph = (props) => {
  console.log("GGB graph props ==>", props);
  const {
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setTriggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
  } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);

  // let disableGeogebraAppChange = false

  let textAnswerString = props.text_answer || "";
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  if (createMode) {
    parsedJSONObject = {
      segment_data: {
        appName: "classic",
        ggbVersion: "6.0",
        graphId: getUniqueId(),
        pngBase64:
          "https://d3kgcbih1bmz00.cloudfront.net/direct_uploads/attachments/01FPWT43TX46HWMZ6B0S2G3HV1.png",
      },
      segment_version: "1.0.0",
    };
    if (props.question_type == "ggb_graph") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }

  const [state, setState] = useState(parsedJSONObject);
  const [textAnswer, setTextAnswer] = useState(textAnswerString);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    updatedCount: 0,
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitchValue: false,
  });
  const screenSettings = props.screenSettings || {};
  const [appletReady, setAppletReady] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [initialData, setInitialData] = useState(parsedJSONObject)
  const [modalOpened, setModalOpened] = useState(false)

  let currentJSON;
  if (presentationMode) {
    currentJSON = parsedJSONObject;
  } else {
    currentJSON = state;
  }

  currentJSON = currentJSON || {};

  const unique_segment_identifier = props.unique_segment_identifier || props.id; // For create mode using id - same is using in SegmentCreateForm
  const segment_data = currentJSON?.segment_data || {};
  const id = segment_data.graphId || getUniqueId();
  const graphId = `${id}_${unique_segment_identifier}`; // Makes graph id unique in a page - not saving this
  const appName = segment_data.appName;
  const ggbVersion = segment_data.ggbVersion;
  const ggbBase64Data = segment_data.ggbBase64Data;
  const pngBase64 = segment_data.pngBase64 || "https://d3kgcbih1bmz00.cloudfront.net/direct_uploads/attachments/01FPWT43TX46HWMZ6B0S2G3HV1.png";
  const screenshotBase64 = segment_data.screenshotBase64;
  const ggbObjects = segment_data.ggbObjects || {};
  const showAlgebraInput = segment_data.showAlgebraInput || false;
  const mrIntl = useTranslate();

  const parameters = {
    appName: appName,
    width: checkMob() ? 800 : 1366,
    height: checkMob() ? 600 : window.innerHeight - 45,
    // height: 500,
    showMenuBar: !presentationMode,
    showToolBar: true,
    showToolBarHelp: !presentationMode,
    enableFileFeatures: false,
    // playButton: true,
    showFullscreenButton: true,
    allowStyleBar: !presentationMode,
    showAlgebraInput: createMode ? true : false,
    enableLabelDrags: true,
    enableShiftDragZoom: true,
    enableUndoRedo: true,
    showResetIcon: true,
    enable3d: false,
    enableCAS: false,
    enableRightClick: !presentationMode,
    errorDialogsActive: false,
    useBrowserForJS: true,
    preventFocus: true,
    borderColor: "#000000",
    showSplash: false,
    language: "en",
    autoHeight: true,
    scaleContainerClass: "graph-container",
    id: graphId,
    ggbVersion: ggbVersion,
    ggbBase64: ggbBase64Data || "",
    // customToolBar: '0 39 | 1 501 67 , 5 19 , 72 | 2 15 45 , 18 65 , 7 37 | 4 3 8 9 , 13 44 , 58 , 47 | 16 51 64 , 70 | 10 34 53 11 , 24  20 22 , 21 23 | 55 56 57 , 12 | 36 46 , 38 49  50 , 71 | 30 29 54 32 31 33 | 17 62 73 , 14 68 , 25 | 27 28 35 | 41 42 | 40 | 6'
  };

  if (appName == "classic") {
    parameters.customToolBar =
      "0 39 | 1 501 67 , 5 19 , 72 | 2 15 45 , 18 65 , 7 37 | 4 3 8 9 , 13 44 , 58 , 47 | 16 51 64 , 70 | 10 34 53 11 , 24  20 22 , 21 23 | 55 56 57 , 12 | 36 46 , 38 49  50 , 71 | 30 29 54 32 31 33 | 17 62 73 , 14 68 , 25 | 27 28 35 | 41 42 | 40 | 6";
    if (presentationMode) {
      parameters.customToolBar = "40 | 41 | 42";
    }
  } else if (appName == "graphing") {
  } else if (appName == "geometry") {
  }

  if (answerMode) {
    parameters.showAlgebraInput = showAlgebraInput;
    parameters.showMenuBar = false;
  }

  if (triggerStateUpdate) {
    parameters.reInitGGb = triggerStateUpdate;
  }

  parameters.appletOnLoad = function (api) {
    ggbApplet.current = api;
    setShowLoader(false);
    if (!createMode && !answerMode) {
      return;
    }

    api.registerStoreUndoListener(saveStateOfGraph);
    api.registerRemoveListener(saveStateOfGraph);
    api.registerAddListener((name) => {
      console.log("add listener state setting ==>", stateSetting);
      stateSetting.ggbObjects[name] = {
        ...stateSetting.ggbObjects[name],
        role: createMode ? "teacher" : "student",
      };

      api.setLabelVisible(name, true);
      setJSONAttempted(true);
    });
    api.registerRemoveListener((name) => {
      delete stateSetting.ggbObjects[name];
      setJSONAttempted(true);
    });
    api.registerUpdateListener((name) => {
      setJSONAttempted(true);
    });
    updateAlgebraInputCss()
  };

  const ggbApplet = useRef();
  const ggbRef = useGGBGraph(
    parameters,
    modalOpened
    // (applet, param, id) => {
    // 	ggbApplet.current = applet

    // 	console.log("Geogebra loaded ==>", param, id, applet)
    // 	setAppletReady(true)
    // 	// if (appName == 'classic') {
    // 	// 	ggbApplet.current.setPerspective("2")
    // 	// 	ggbApplet.current.setGridVisible(true)
    // 	// 	ggbApplet.current.setAxesVisible(1, true, true)
    // 	// 	ggbApplet.current.showAlgebraInput(true)
    // 	// }
    // }
  );

  stateSetting.ggbObjects = ggbObjects;
  
  const updateAlgebraInputCss = () => {
    if (showAlgebraInput) {
      const dockPanelParents = document.getElementsByClassName("dockPanelParent")
      const leftSideGraphPannel = dockPanelParents[0]
      const rightSideGraphPannel = dockPanelParents[1]
      const rightSideGraphPannelDragger = document.getElementsByClassName("draggerParentHorizontal")[0]
      if (leftSideGraphPannel && rightSideGraphPannel) {
        console.log("leftSideGraphPannel.width", leftSideGraphPannel.clientWidth, leftSideGraphPannel);
        if(leftSideGraphPannel.clientWidth < 10) {
          leftSideGraphPannel.classList.add("left-side-graph-pannel")
          rightSideGraphPannel.classList.add("right-side-graph-pannel")
          rightSideGraphPannelDragger.classList.add("right-side-graph-pannel-dragger")
        }
      }
    }
  }

  useEffect(() => {
    if (ggbApplet.current && presentationMode) {
      updateGraphData(segment_data);
    }
  }, [ggbBase64Data, stateSetting.ggbModal])

  useEffect(() => {
    if (stateSetting.ggbModal) {
      setState(parsedJSONObject)
      setInitialData(parsedJSONObject)
      updateAlgebraInputCss()
    }
  }, [stateSetting.ggbModal])

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }

    return () => {
      window[graphId] = null;
    };
  }, []);

  // useEffect(() => {
  //   if (ggbApplet.current) {
  //     ggbApplet.current.showAlgebraInput(showAlgebraInput)
  //   }
  // }, parameters.showAlgebraInput)

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          // text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    console.log("save state ==>", state)
    setState(state);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const saveStateOfGraph = debounce(() => {
    const newGGBObjects = stateSetting.ggbObjects;
    const newGGBBase64Data = ggbApplet.current.getBase64();
    // let pngBase64 = "data:image/png;base64," + ggbApplet.current.getPNGBase64(1, true, 72)
    const newSegmentData = {
      ...segment_data,
      graphId: id,
      // pngBase64: "data:image/png;base64," + url,
      ggbBase64Data: newGGBBase64Data,
      ggbObjects: newGGBObjects,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
    // ggbApplet.current.getScreenshotBase64((url) => {
    // })
  }, 500);

  const setJSONAttempted = (value) => {
    if (answerMode && !attemptStatus.json_attempted) {
      setAttemptStatus({
        ...attemptStatus,
        json_attempted: true,
      });
    }
  };

  const updateGraphData = function (data) {
    if (ggbApplet.current && data.ggbBase64Data) {
      console.log("updateGraphData show algebra input ==>", data)
      ggbApplet.current.setBase64(data.ggbBase64Data);
    }
  };

  const resetQuestion = () => {
    let student_json = cloneDeep(props.student_json);
    const newState = student_json || {
      segment_data: {},
      segment_version: "1.0.0",
    };
    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    console.log("resetQuestion state ==>", newState)
    saveState(newState);
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
    setTriggerStateUpdate(getUniqueId())
    // updateGraphData(newState.segment_data);
  };

  const setProperty = (type, value) => {
    const newSegmentData = {
      ...segment_data,
      [type]: value,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  // const showGGBGraph = createMode || !pngBase64;
  const showGGBGraph = !pngBase64;

  const openModal = () => {
    setStateSetting({
      ...stateSetting,
      ggbModal: true,
    });
  };
  const canNotSaveMsg =  answerMode ? mrIntl("GGBGraph.answer_can_not_be_saved_title") : ""

  const handleOk = () => {
    if (!ggbApplet.current) {
      showConfirmModal({
        // title: `Something went wrong, ${
        //   answerMode ? "answer can't be saved," : ""
        // } Please refresh page.`,
        title: mrIntl("GGBGraph.show_confirm_modal_title", {canNotSaveMsg: canNotSaveMsg}),
        onOk: () => {
          setStateSetting({
            ...stateSetting,
            ggbModal: false,
          });
        },
        onCancel: () => {
          return;
        },
        mrIntl: mrIntl
      });
      return;
    }

    if (createMode || answerMode) {
      const newGGBObjects = stateSetting.ggbObjects;
      const newGGBBase64Data = ggbApplet.current.getBase64();
      const newPNGBase64 =
        "data:image/png;base64," + ggbApplet.current.getPNGBase64(1, true, 72);
        

      const newSegmentData = {
        ...segment_data,
        graphId: id,
        ggbBase64Data: newGGBBase64Data,
        ggbObjects: newGGBObjects,
        pngBase64: newPNGBase64,
      };

      const newState = {
        ...state,
        segment_data: newSegmentData,
      };

      saveState(newState);
    }

    setStateSetting({
      ...stateSetting,
      ggbModal: false,
    });
  };

  const handleCancel = () => {
    if (presentationMode) {
      setStateSetting({
        ...stateSetting,
        ggbModal: false,
      });
    } else {
      showConfirmModal({
        title: mrIntl("GGBGraph.are_you_sure"),
        onOk: () => {
          updateGraphData(initialData.segment_data)
          saveState(initialData);
          setStateSetting({
            ...stateSetting,
            ggbModal: false,
          });
        },
        onCancel: () => {
          return;
        },
        mrIntl: mrIntl
      });
    }
  };

  let ggbAppletToRender = null;
  let ggbAppletDiv = (
  <Spin spinning={showLoader} size="large" tip={`${mrIntl("CommonText.loading")}...`} >
      <div className="graph-container">
        <div id={graphId} ref={ggbRef} className={"geogebra-graph"}></div>
      </div>
    </Spin>
  );

  let ggbImageDiv = (
    <div className="graph-container">
      <img
        src={pngBase64}
        width={200}
        height={200}
        onClick={() => openModal()}
      />
    </div>
  );

  console.log(
    "GGB graph state before render ==>",
    graphId,
    state,
    stateSetting,
    ggbApplet.current,
    modalOpened
  );
  return (
    <Segment>
      <SegmentData key={`segment-data-${props.unique_segment_identifier}`}>
        <Modal
          width="95%"
          open={stateSetting.ggbModal}
          className={"full-screen-modal graph-modal"}
          closable={false}
          centered={true}
          afterOpenChange={(open) => setModalOpened(open)}
          // onOk={() => handleOk()}
          // onCancel={() => handleCancel()}
          // closeIcon={true}
          // closeIcon={[
          //   <Button
          //     key={`cancel-${graphId}`}
          //     onClick={() => handleCancel()}
          //     type="text"
          //     shape="circle"
          //   >
          //     <CloseOutlined/>
          //   </Button>,
          //   <Button
          //     key={`ok-${graphId}`}
          //     onClick={() => handleOk()}
          //     size="small"
          //     type="primary"
          //     className={"modal-ok-at-top"}
          //   >
          //     OK
          //   </Button>,
          //   <Button
          //     key={`cancel-${graphId}`}
          //     onClick={() => handleCancel()}
          //     type="default"
          //   >
          //     Cancel
          //   </Button>,
          //   <Button
          //     key={`ok-${graphId}`}
          //     onClick={() => handleOk()}
          //     type="primary"
          //   >
          //     OK
          //   </Button>,
          // ]}
          // footer={null}
          footer={[
            <Button
              key={`cancel-${graphId}`}
              onClick={() => handleCancel()}
              type="default"
            >
              {!presentationMode ? mrIntl("CommonText.cancel") : mrIntl("CommonText.close")}
            </Button>,
            !presentationMode ? <Button
              key={`ok-${graphId}`}
              onClick={() => handleOk()}
              type="primary"
            >
              <MrTranslate id={"CommonText.save"}/>
            </Button> : "",
          ]}
        >
          {ggbAppletDiv}
        </Modal>

        {showGGBGraph && !stateSetting.ggbModal ? ggbAppletDiv : ggbImageDiv}

        <div
          style={{
            margin: "20px",
          }}
        >
          {createMode && (
            <Space>
              <MrSelect
                config={{
                  style: { width: "200px" },
                  placeholder: mrIntl("GGBGraph.select_geogebra_app_placeholder"),
                  value: appName,
                  // disabled: disableGeogebraAppChange,
                  onChange: (value) => setProperty("appName", value),
                }}
                options={[
                  // {<MrTranslate id={"GGBGraph.classic"} values={{value: "classic"}}/>}
                  // { label: <MrTranslate id={"GGBGraph.classic"}/> value: "classic" },
                  // { label: "Classic", value: "classic" },
                  {label: mrIntl("GGBGraph.classic"), value: "classic"},
                  { label: mrIntl("GGBGraph.graphing"), value: "graphing"},
                  { label: mrIntl("GGBGraph.geometry"), value: "geometry"},
                ]}
              ></MrSelect>

              <Checkbox
                style={{}}
                defaultChecked={showAlgebraInput}
                onChange={(e) => {
                  setProperty("showAlgebraInput", e.target.checked);
                }}
              >
                {" "}
                <MrTranslate id={"GGBGraph.allow_algebra_input_for_student"}/>
              </Checkbox>
            </Space>
          )}
        </div>
      </SegmentData>
      <SegmentItemFooter
        key={`segment-item-footer-${props.unique_segment_identifier}`}
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        resetQuestion={resetQuestion}
        // checkAnswer={(e) => checkAnswer()}
        // showCorrectAnswer={(value) => showCorrectAnswer(value)}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default GGBGraph;
