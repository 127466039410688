import React, { useEffect } from "react";
// import { Layout } from "antd";
// import classNames from "classnames";
// import styled from "styled-components";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
// import styles from "./AuthLayout.scss";
// import BaseLayout from "../BaseLayout/BaseLayout";
// import LogoImage from "../../../assets/images/logo.png";
import { useRouteMatch, useLocation, Redirect } from "react-router-dom";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { actions as embeddedResourcesActions } from "/src/views/EmbeddedResources/redux";
import { activeEmbeddedResourceSelector } from "/src/views/EmbeddedResources/selector";
import ZoomableImageContainer from "/src/components/UI/ZoomableImageContainer/ZoomableImageContainer";
import { keyUpEventNotifier } from "/src/components/Partners/OSC/GenericComponents";
// const styledContentTop = {};

const EmbedLayout = (props) => {
  const match = useRouteMatch();
  // const {path, url} = match;
  const location = useLocation();
  const { isAuthenticated, tokenPresent } = props;
  const dispatch = useDispatch();
  const activeEmbeddedResource =
    useSelector(activeEmbeddedResourceSelector()) || {};

  let queryParams = {};
  queryParams = queryString.parse(location.search);

  useEffect(() => {
    document.body.style.height = "auto";
    if (location) {
      const partnerName = queryParams.partner || "mb";
      console.log(
        "location, isAuthenticated, queryParams",
        location,
        isAuthenticated,
        queryParams
      );
      console.log( "location match", match );
      if (partnerName === "osc"){
        window.addEventListener('keyup', keyUpEventNotifier);
      }

      if (queryParams.jwt) {
        dispatch(
          embeddedResourcesActions.setJwtSuccess({
            jwt: queryParams.jwt,
            client: partnerName,
          })
        );
        // if(!isAuthenticated){
        dispatch(
          loginActions.verifyJwt({ jwt: queryParams.jwt, client: partnerName })
        );
        return <Spinner />;
        // }
      }
      
    }
    return () => {
      if (location) {
        const partnerName = queryParams.partner || "mb";
        if (partnerName === "osc"){
          window.removeEventListener('keyup', keyUpEventNotifier);
        }
      }
      document.body.style.height = "100%";
    };
  }, []);

  console.log("EmbedLayout props", props, activeEmbeddedResource);
  console.log("match 29", match, location);
  console.log("EmbedLayout this.props.location", location);
  console.log("isAuthenticated, tokenPresent", isAuthenticated, tokenPresent);
  // const homePath = "/"
  // if(tokenPresent){
  //   if(isAuthenticated){
  //     let redirectPath = homePath;
  //     if(location && location.state){
  //       redirectPath = location.state.from.pathname;
  //     }
  //     return <Redirect to={redirectPath}/>
  //   } else {
  //     return <Spinner />
  //   }
  // }

  // let toRender;
  // console.log("1", props);
  // if(!props.children){
  //   console.log("2", props);
  //   toRender = <div>HI {props.main}</div>
  // }

  // if(!isAuthenticated && activeEmbeddedResource.resource_type === "Experience"){
  // so that segment doesnt keep showing loading

  if (import.meta.env.VITE_MAINTENANCE_MODE == "true") {
    return <Redirect to="/maintenance-mode/maintenance-page" />;
  }
  if (!isAuthenticated && queryParams.jwt) {
    return <Spinner />;
  }

  return (
    <div>
      {/* Embed Layout */}
      <div>{props.children}</div>
      {/* {toRender} */}

      <ZoomableImageContainer />
    </div>
  );

  // return (
  //   <BaseLayout>
  //     <ContentTop>
  //       <StyledLogo src={LogoImage} />
  //       <h1 class="sign-in-text">Sign in to AssessPrep</h1>
  //     </ContentTop>
  //     <div>{props.children}</div>
  //     <ContentMain className="auth-content-main">{!props.children ? props.main : ''}</ContentMain>
  //   </BaseLayout>
  // );
};
export default EmbedLayout;
