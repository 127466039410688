import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  MrSelect,
  MrCrudView,
  MrForm as WrappedMrForm,
  formatJsonApiData
} from "mr_react_framework";
import { actions } from "./redux";
import ProgrammeListItem from "./ProgrammeListItem/ProgrammeListItem";
import ProgrammesFilter from "./ProgrammesFilter/ProgrammesFilter";
import ProgrammeItemDetail from "./ProgrammeItemDetail/ProgramItemDetail";
import ProgrammeCustomInput from "./ProgrammeCustomInput";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors from "/src/App/commonSelectors";
// import SelectBoard from "/src/components/AppSpecific/SelectBoard/SelectBoard";
import { actions as genericActions } from "/src/App/genericRedux";
import ProgrammeList from "./ProgrammeList";
import MrTranslate, { mrTranslate } from "/src/lib/MrTranslate/MrTranslate";
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import { message, Row, Col } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { isKeyCodeValid } from "/src/lib/utils/helperMethods";

const SelectBoard = MrSelect({
  actions: genericActions,
  resourceName: "boards",
  config: {
    url: "/boards",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.abbr, value: item.id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: <MrTranslate id={"Programmes.select_board_placeholder"}/>,
      // mode: "multiple",
      style: { width: "500px" },
    },
  },
});

const UploadIconComponent = (props) => {
  const { getFieldValue, sourceValues, formInstance, onChange, value } = props;

  const initialFileList = value ? [{ url: value, type: "image/png" }] : [];

  const mediaFormInitialState = {
    modal: false,
    loading: false,
    visible: false,
    title: "Select icon",
    okText: "Save",
    cancelText: "Cancel",
    triggerSaveAction: 0,
    requireFooter: false,
    limit: 1,
    supportedTypes: "image/png",
    supportedFormats: "png",
    selectConfig: {
      defaultFileList: initialFileList,
      type: "pictures-wall",
      className: "subject-icon-uploader",
      fileUploadButtonText: (
        <Row justify="center">
          <Col>
            <UploadOutlined />
            <Col>Icon</Col>
          </Col>
        </Row>
      ),
      showUploadList: {
        showRemoveIcon: true,
        showPreviewIcon: true,
      },
    },
  };

  const [mediaFormSetting, setMediaFormSetting] = useState(mediaFormInitialState);

  mediaFormSetting.onChangeSaveFiles = (files) => {
    console.log("attachmentFiles ==>", files);
    if (files && files.length > 0) {
      const url = files[0].url;
      if (onChange) {
        onChange(url);
      }
      setMediaFormSetting({
        ...mediaFormSetting,
        from: '',
        triggerSaveAction: 0,
      })
      message.success("Icon saved successfully!")
    }
  };

  mediaFormSetting.onRemoveFile = (file) => {
    console.log("File removed==", file);
    if (onChange) {
      onChange(null);
    }
    message.success("Icon deleted successfully!");
  };

  return (
    <SelectFromComputer
      mediaFormSetting={mediaFormSetting}
      setMediaFormSetting={(data) => setMediaFormSetting(data)}
    />
  );
};

export const returnForm = (props) => {
  console.log("in programmes==>", props);
  const { mrIntl } = props

  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      if (opts.values && opts.values.programme_subjects_attributes) {
        opts.values.programme_subjects_attributes.forEach(subjectGroup => {

          if (!subjectGroup.custom_fields || subjectGroup.custom_fields === "{}") {
            subjectGroup.custom_fields = {};
          }

          if (subjectGroup?.icon) {
            subjectGroup.custom_fields.icon = subjectGroup.icon;
            delete subjectGroup.icon;
          } else {
            if (subjectGroup.custom_fields.icon) {
              delete subjectGroup.custom_fields.icon;
            }
          }

          if (subjectGroup.children_attributes) {
            subjectGroup.children_attributes.forEach(child => {

              if (!child.custom_fields || child.custom_fields === "{}") {
                child.custom_fields = {};
              }

              if (child?.icon) {
                child.custom_fields.icon = child.icon;
                delete child.icon;
              } else {
                if (child.custom_fields.icon) {
                  delete child.custom_fields.icon;
                }
              }
            });
          }
        });

      }
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        name: {
          type: "string",
          placeholder: mrIntl("Programmes.program_name_placeholder"),
          rules: [
            {
              required: true,
              message: mrIntl("CommonText.required_msg"),
            },
          ]
        },
        code: {
          type: "string",
          placeholder: mrIntl("Programmes.program_code_placeholder"),
          rules: [
            {
              validator(rule, value) {
                if (isKeyCodeValid(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Please enter valid program code");
                }
              },
            },
          ],
        },
        abbr: {
          type: "string",
          placeholder: mrIntl("Programmes.abbr_placeholder"),
        },
        desc: {
          type: "string",
          placeholder: mrIntl("CommonText.description"),
        },
        board_id: {
          title: "Select board",
          type: "string",
          span: 24,
          widget: SelectBoard,
          rules: [
            {
              required: true,
              message: mrIntl("CommonText.required_msg"),
            },
          ]
        },
        // subject_title_display: {
        //   // title: "Select board",
        //   type: "string",
        //   span: 24,
        //   widget: (props) => {
        //     return "Subjects"
        //   },
        // },
        programme_subjects_attributes: {
          type: "array",
          addButtonTitle: `+ ${mrIntl("Programmes.add_new_subject_group_btn_text")}`,
          span: 24,
          min: 1,
          title: mrIntl("CommonText.subjects"),
          max: 100,
          items: {
            type: "object",
            properties: {
              icon: {
                type: "string",
                span: 2,
                widget: UploadIconComponent,
              },
              name: {
                type: "string",
                title: mrIntl("Programmes.subject_group_name"),
                span: 8,
                rules: [
                  {
                    required: true,
                    message: mrIntl("CommonText.required_msg"),
                  },
                ]
              },
              code: {
                type: "string",
                title: mrIntl("CommonText.code"),
                // default: "2020-2021"
                span: 8,
                rules: [
                  {
                    required: true,
                    message: mrIntl("CommonText.required_msg"),
                  },
                ]
              },
              type_c: {
                type: "string",
                title: mrIntl("CommonText.type"),
                default: "group",
                span: 4,
                widget: "SelectWidget",
                widgetConfig: {
                  options: [
                    { label: mrIntl("CommonText.group"), value: "group" },
                    { label: mrIntl("CommonText.subject"), value: "subject" },
                  ]
                },
                rules: [
                  {
                    required: true,
                    message: mrIntl("CommonText.required_msg"),
                  },
                ]
              },
              children_attributes: {
                type: "array",
                addButtonTitle: `+ ${mrIntl("CommonText.add_new_subject")}`,
                span: 24,
                min: 1,
                max: 200,
                items: {
                  type: "object",
                  properties: {
                    icon: {
                      type: "string",
                      span: 2,
                      setInitialValue: true,
                      widget: UploadIconComponent,
                    },
                    name: {
                      type: "string",
                      title: mrIntl("CommonText.subject_name"),
                      span: 6,
                      rules: [
                        {
                          required: true,
                          message: mrIntl("CommonText.required_msg"),
                        },
                      ]
                    },
                    code: {
                      type: "string",
                      title: mrIntl("CommonText.code"),
                      // default: "2020-2021"
                      span: 6,
                      rules: [
                        {
                          required: true,
                          message: mrIntl("CommonText.required_msg"),
                        },
                      ]
                    },
                    type_c: {
                      type: "string",
                      title: mrIntl("CommonText.type"),
                      default: "subject",
                      span: 4,
                      widget: "SelectWidget",
                      widgetConfig: {
                        options: [
                          { label: mrIntl("Programmes.groups"), value: "group" },
                          { label: mrIntl("CommonText.subject"), value: "subject" },
                        ]
                      },
                      rules: [
                        {
                          required: true,
                          message: mrIntl("CommonText.required_msg"),
                        },
                      ]
                    },
                  }
                }
              },
            },
            
          }

        },
        // grade_title_display: {
        //   // title: "Select board",
        //   type: "string",
        //   span: 24,
        //   widget: (props) => {
        //     return "Grades"
        //   },
        // },
        programme_grades_attributes: {
          type: "array",
          span: 24,
          min: 1,
          max: 20,
          title: mrIntl("CommonText.grades"),
          items: {
            type: "object",
            properties: {
              name: {
                type: "string",
                placeholder: mrIntl("CommonText.name"),
                span: 6,
              },
              type_c: {
                type: "string",
                placeholder: "Type",
                // default: "2020-2021",
                span: 6,
                widgetConfig: {
                  disabled: true,
                }
              },
              code: {
                type: "string",
                placeholder: mrIntl("CommonText.code"),
                // default: "2020-2021",
                span: 6,
              },
              grade_number: {
                type: "string",
                placeholder: "Grade number",
                // default: "2020-2021",
                span: 6,
              },
            },
            
          }

        },
        // subjects: {
        //   title: "Subjects",
        //   type: "string",
        //   default: "{}",
        //   widget: ProgrammeCustomInput
        // },
        // grades: {
        //   title: "Grades",
        //   type: "string",
        //   default: "{}",
        //   widget: ProgrammeCustomInput,
         
        // },

       
      },
    },
  };
  return form;
};

const processProgrammeFormInitialValues = (data) => {
  console.log( "initial data", data );
  let newData = data
  // let newData = {
  //   ...data,
  //   nestedValues: {
  //     ...data.nestedValues,
  //     tempNested: "tempNested updated"
  //   },
  //   name: "hello1"
  // }
  // data = nestedValues

  if(data.programme_subjects_attributes_for_static){
    let programmeSubjectsAttributes = formatJsonApiData(data.programme_subjects_attributes_for_static)
    if (programmeSubjectsAttributes) {
      programmeSubjectsAttributes.forEach(subjectGroup => {
  
        if (subjectGroup?.custom_fields?.icon) {
          subjectGroup.icon = subjectGroup.custom_fields.icon;
          // delete subjectGroup.custom_fields.icon;
        }
        
        if (subjectGroup?.children_attributes) {
          subjectGroup.children_attributes.forEach(child => {
  
            if (child?.custom_fields?.icon) {
              child.icon = child.custom_fields.icon;
              // delete child.custom_fields.icon;
            }
  
          });
        }
      });
    }
    newData.programme_subjects_attributes = programmeSubjectsAttributes
  }

  return newData;
} 

const Programmes = MrCrudView({
  resourceName: "programmes",
  singleResourceName: "programme",
  displayName: "resourceName",
  resourceUrl: "/programmes",
  // layout: OrgsLayout,
  filter: ProgrammesFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  listItem: ProgrammeListItem,
  forms: [
    { name: "programmes", form: returnForm, config: {
      processInitialValues: processProgrammeFormInitialValues
    } },
  ],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { page_size: 5 },
  config: {
    itemPreview: {
      widget: ProgrammeListItem,
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: ProgrammeItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      widget: (props) => {
        return <span><MrTranslate id={"CommonText.filter"}/></span>;
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      // type: "table/list/custom",
      widget: ProgrammeList,
      // params here does not work as expected
      // params: {
      //   page_size: 5,
      // }
    }
  },
});

// Programmes.defaultProps = {};

Programmes.propTypes = {};
export default Programmes;
