import React, { useCallback, useEffect, useRef, useState } from "react";

import { RBDDroppableArea } from "/src/components/UI/DnD/RBDDroppableArea";
import { DnDDroppableTitle } from "/src/components/UI/DnD/DnDDroppableTitle";
import { DnDTabularTextBoxes } from "/src/components/UI/DnD/DnDTabularTextBoxes";
import { DnDInlineTextBoxes } from "/src/components/UI/DnD/DnDInlineTextBoxes";
import { DnDDistractors } from "/src/components/UI/DnD/DnDDistractors";
import { DragDropContext } from "react-beautiful-dnd";
import { updateOnDragEnd, useClickAndClickSensor } from "/src/views/Segments/DnDHelpers";
import {
  getArrayOfList,
  addListToArray,
  addItemToArray,
  removeItemFromArray,
  getParsedJSONObject,
} from "/src/views/Segments/InteractiveHelpers";
import {
  Segment,
  SegmentData,
  HorizontalDroppableContainer,
  VerticalDroppableContainer,
  InlineDroppablesContainer,
  InlineDroppable,
} from "/src/components/UI/Segment/UIHelper";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { cloneDeep, shuffle } from "lodash";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import SegmentItemHeader from "/src/views/Segments/SegmentItemDetail/SegmentItemHeader";
import MatchForm from "./MatchForm";

export function shuffleItems(json) {
  const segment_data = json?.segment_data || {};
  const possible_responses = segment_data.possible_responses || {};
  possible_responses.items = shuffle(possible_responses.items);

  return json;
}

export function checkResponses(question_json, answer_json) {
  // Note update logic for question and answer json when in game mode as json is converted to support game
  console.log("Question and Answer json", question_json, answer_json);
  if (!question_json || !answer_json) {
    return;
  }
  let q_segment_data = question_json.segment_data;
  let a_segment_data = answer_json.segment_data;

  let q_columns = q_segment_data ? q_segment_data.columns : [];
  let a_columns = a_segment_data ? a_segment_data.columns : [];
  let i = 0;
  while (i < q_columns.length) {
    let q_items = q_columns ? q_columns[i].items : [];
    let a_items = a_columns ? a_columns[i].items : [];

    if (a_items && a_items[0]) {
      if (a_items[0].id == q_items[0].id) {
        a_items[0].correct = true;
      } else {
        a_items[0].correct = false;
      }
    }
    i++;
  }
  return answer_json;
}

const MatchAnswer = (props) => {
  console.log("RBDInlineMatch props", props);
  const {
    segment_data,
    columns,
    possible_responses,
    distractors,
    state,
    saveState,
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
    answerMode,
    presentationMode,
    autoCheckMode,
    attemptStatus,
    setAttemptStatus,
    stateSetting,
    setStateSetting,
    // shuffleItems,
    // checkResponses,
  } = props;

  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  const [myAnswer, setMyAnswer] = useState(state);
  const screenSettings = props.screenSettings || {};

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  function validateAttempted(json) {
    let initial_possible_responses_items =
      props.student_json.segment_data.possible_responses.items;
    let current_possible_responses_items =
      json.segment_data.possible_responses.items;

    let isAtleastOneDropped = false;
    if (
      current_possible_responses_items.length !=
      initial_possible_responses_items.length
    ) {
      isAtleastOneDropped = true;
    }

    console.log(
      "validate attempt status ==>",
      attemptStatus,
      isAtleastOneDropped
    );
    setAttemptStatus({
      ...attemptStatus,
      json_attempted: isAtleastOneDropped,
    });
  }

  const onDragEnd = (result) => {
    const dragDropConfig = {
      updateType: "replace",
      append: true,
      limit: 1,
    };
    const newSegmentData = updateOnDragEnd(result, state, dragDropConfig);

    if (!newSegmentData) {
      return;
    }

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    if (answerMode) {
      validateAttempted(newState);
    }

    console.log("on drag end data ==>", newSegmentData);
    saveState(newState);
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const checkAnswer = (check = false) => {
    if (check) {
      let question_json = cloneDeep(props.teacher_json);
      let answer_json = cloneDeep(state);
      const newState = checkResponses(question_json, answer_json);

      setMyAnswer(state);
      saveState(newState);
    }

    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: check,
    });

    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const showCorrectAnswer = (value) => {
    let question_json, answer_json;
    if (value) {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(props.teacher_json);
    } else {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(myAnswer);
    }

    const newState = checkResponses(question_json, answer_json);
    saveState(newState);
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: true,
      showCorrectAnswerSwitchValue: value,
    });
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const resetQuestion = () => {
    let student_json = cloneDeep(props.student_json);
    const newState = shuffleItems(student_json);

    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    saveState(newState);
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: false,
    });
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  console.log(
    "RBD Inline Matching State before render ==>",
    state,
    stateSetting.quickCheckModeEnabled
  );

  const sensorAPI = useRef()
  const liftedItem = useRef()
  const clickAndClickSensor = useClickAndClickSensor((api) => { sensorAPI.current = api }, liftedItem)
  
  return (
    <Segment>
      <SegmentData key={`segment-data-${props.unique_segment_identifier}`}>
        <DragDropContext
          key={`drag-drop-context-${props.unique_segment_identifier}`}
          onDragEnd={onDragEnd}
          sensors={[clickAndClickSensor]}
        >
          <InlineDroppablesContainer
            key={`inline-droppables-container-${props.unique_segment_identifier}`}
            style={
              screenSettings.isMobile
                ? {
                    maxHeight: "500px",
                    overflow: "auto",
                  }
                : {
                    maxHeight: "100%",
                  }
            }
          >
            {columns.map((column, index) => (
              <InlineDroppable
                key={`inline-droppable-${column.id}`}
                style={
                  screenSettings.isMobile
                    ? {
                        width: "50%",
                        minHeight: "200px",
                      }
                    : {
                        width: "25%",
                      }
                }
              >
                <DnDDroppableTitle list={column}></DnDDroppableTitle>
                <VerticalDroppableContainer
                  key={`vertical-droppable-container-${column.id}`}
                  className="matching-vertical-droppable-container"
                >
                  <RBDDroppableArea
                    key={`rbd-droppable-area-${column.id}`}
                    droppableProps={{
                      list: column,
                      type: "DEFAULT",
                      // darkBackground: true,
                      droppableId: column.id,
                    }}
                    draggableProps={{
                      list: column,
                      hidePlaceholder: true,
                      stretchHeight: true,
                      draggingDisabled:
                        !answerMode || stateSetting.quickCheckModeEnabled
                          ? true
                          : false,
                      showCorrect:
                        autoCheckMode || stateSetting.quickCheckModeEnabled,
                      liftedItem: liftedItem,
                      sensorAPI: sensorAPI
                    }}
                  />
                </VerticalDroppableContainer>
              </InlineDroppable>
            ))}
          </InlineDroppablesContainer>
          {(answerMode || presentationMode) && (
            <HorizontalDroppableContainer>
              {possible_responses && possible_responses.id && (
                <RBDDroppableArea
                  key={`rbd-droppable-area-${possible_responses.id}`}
                  droppableProps={{
                    list: possible_responses,
                    type: "DEFAULT",
                    droppableId: possible_responses.id,
                    style: screenSettings.isMobile
                      ? {
                          whiteSpace: "nowrap",
                        }
                      : {
                          // 'max-height': '100%',
                        },
                  }}
                  draggableProps={{
                    list: possible_responses,
                    direction: "horizontal",
                    hidePlaceholder: true,
                    draggingDisabled:
                      !answerMode || stateSetting.quickCheckModeEnabled
                        ? true
                        : false,
                    liftedItem: liftedItem,
                    sensorAPI: sensorAPI
                  }}
                />
              )}
            </HorizontalDroppableContainer>
          )}
        </DragDropContext>
      </SegmentData>

      <SegmentItemFooter
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        resetQuestion={resetQuestion}
        checkAnswer={checkAnswer}
        showCorrectAnswer={showCorrectAnswer}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default MatchAnswer;
