import React, { useEffect } from "react";
import { Button, Space, Menu, Dropdown, Typography, Tag, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  UserOutlined,
  // UserSwitchOutlined
  UserSwitchOutlined,
  CaretDownOutlined,
  DownOutlined
} from "@ant-design/icons";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { appRegionSelector, currentUserSelector, mbAccountsPortalLinkedDataSelector } from "/src/views/Auth/Login/selector";
import _ from "lodash";
import { CgMenuGridO, CgMenuGridR } from "react-icons/cg"
import { Card, Avatar } from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const OrgUserSwitcher = (props) => {
  console.log("OrgUserSwitcher props", props);
  const { Meta } = Card
  // const history = useHistory();
  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  const currentUser = useSelector(currentUserSelector())
  const appRegion = useSelector(appRegionSelector())
  const mbAccountsPortalLinkedData = useSelector(mbAccountsPortalLinkedDataSelector())
  const mbApplications = mbAccountsPortalLinkedData.applications || []
  const mbUsers = mbAccountsPortalLinkedData.users || []
  const { Text } = Typography;
  console.log("currentUser=====>", currentUser)
  useEffect(() => {
    if(currentUser.org.is_mb && currentUser.custom_fields.mb_account_uid && appRegion === "global"){ // Open for china once confirmed from MB
      dispatch(loginActions.getServices());
    }
  }, [])

  const handleMenuClick = (obj, isCurrentOrgUser) => {
    console.log( "orgUser switcher click", obj );
    if(isCurrentOrgUser){
      return
    }
      // let email = `${obj.key}@eduvo.com`;
    dispatch(loginActions.switchIdentity({ user_uuid: obj.key }));
  };

  // let usersAvailable = [
  //   { key: "sharon", name: "Sharon Arese", role: "Admin" },
  //   { key: "risa", name: "Risa Aoki", role: "Teacher" },
  //   { key: "chloe", name: "Chloe Epelbaum", role: "DP Student" },
  //   { key: "david", name: "David Etuhu", role: "DP Student" },
  //   { key: "rachel.epelbaum", name: "Rachel Epelbaum", role: "MYP Student" },
  // ];

  let userNameFirstLetter = currentUser.name.charAt(0);
  let menuItems =
  <div className="org-info-dropdown">
      <Menu.Item className={"no-hover"}>
        <Space
          align="baseline"
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Meta
            avatar={currentUser.attachments_json ? <Avatar src={currentUser.attachments_json.logo} /> : <Avatar className="default-logo-button">{userNameFirstLetter}</Avatar>}
            title={currentUser.name}
            className="user-title"
          />
        </Space>
      </Menu.Item>
      <Menu.ItemGroup title="Linked AssessPrep Accounts" className="linked-accounts-content">
        {currentUser.org_users.map((orgUser, i) => {

          let isCurrentOrgUser = currentUser.org.uuid === orgUser.org.uuid ? true : false
          const orgName = orgUser.org.name || ""
          let title = orgName
          if(isCurrentOrgUser){
            title = <span>{orgName} <Tag color="blue" className={"custom-current-tag "}>Current</Tag></span>
          }
          let orgNameFirstLetter = orgName.charAt(0);
          const orgLogo = orgUser.org.attachments_json.top_nav_logo || "";
          let showOrgUser = true
          let menuItemRender = <Menu.Item key={orgUser.uuid} className={isCurrentOrgUser ? "current-org-user no-hover" : ""} onClick={(e) => handleMenuClick(e, isCurrentOrgUser)}>
            <Space
              align="baseline"
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <Meta
                avatar={orgLogo ? 
                  <Avatar shape="square" src={orgLogo} /> 
                  : 
                  <Avatar className="default-logo-button">{orgNameFirstLetter}</Avatar>
                }
                title={title}
                description={_.upperFirst(orgUser.role)}
              />
            </Space>
          </Menu.Item>

          // Below logic to hide student org_users - only show current. and in special osc case, show other roles also.
          if(currentUser.role === "student"){
            // TMP: until we figure out pamoja students (POC easy, but PLS not sure how to identify)
            if(isCurrentOrgUser){
              showOrgUser = true
            }else{
              showOrgUser = false
            }
            // if(currentUser.org.uuid.includes("osc")){
            //   // TMP: to allow users with same email who are teacher/admin in a school and same email is used by their child as a student of osc 
            //   if(orgUser.role !== 'student' || isCurrentOrgUser ){
            //     showOrgUser = true
            //   }else{
            //     showOrgUser = false
            //   }
            // }
          }
          if(orgUser.archived){
            showOrgUser = false
          }
          // hiding all osc org_users if not current
          if(!isCurrentOrgUser && orgUser.org.uuid.includes("osc")){
            showOrgUser = false
          }
         
          return showOrgUser ? menuItemRender : null;

          // if(currentUser.org.uuid.includes("osc")) {
          //   if(orgUser.role != 'student' && !orgUser.archived){
          //       return (
          //         <Menu.Item key={orgUser.uuid} onClick={handleMenuClick}>
          //           <Space
          //             align="baseline"
          //             style={{
          //               display: "flex",
          //               alignItems: "baseline",
          //               justifyContent: "space-between",
          //             }}
          //           >
          //             <Meta
          //               avatar={orgLogo ? 
          //                 <Avatar shape="square" src={orgLogo} /> 
          //                 : 
          //                 <Avatar className="default-logo-button">{orgNameFirstLetter}</Avatar>
          //               }
          //               title={orgName}
          //               description={_.upperFirst(orgUser.role)}
          //             />
          //           </Space>
          //         </Menu.Item>
          //       )
          //   }
          // } else if(currentUser.org.uuid === orgUser.org.uuid){
          //   return (
          //     <Menu.Item key={orgUser.uuid} onClick={handleMenuClick}>
          //       <Space
          //         align="baseline"
          //         style={{
          //           display: "flex",
          //           alignItems: "baseline",
          //           justifyContent: "space-between",
          //         }}
          //       >
          //         <Meta
          //           avatar={orgLogo ? 
          //             <Avatar shape="square" src={orgLogo} /> 
          //             : 
          //             <Avatar className="default-logo-button">{orgNameFirstLetter}</Avatar>
          //           }
          //           title={orgName}
          //           description={_.upperFirst(orgUser.role)}
          //         />
          //       </Space>
          //     </Menu.Item>
          //   )
          // }
        }
        )
        }
      </Menu.ItemGroup>
      {(mbApplications.length > 0 || mbUsers.length > 0) && <Menu.ItemGroup title="Switch Services" className="linked-accounts-content">
        {mbApplications.map((app, i) => {
            const orgName = app.name || ""
            let orgNameFirstLetter = orgName.charAt(0);
            const orgLogo = app.logo || ""

            return (
              app.partner_id != "assess_prep" && <Menu.Item key={app.partner_id} onClick={() => window.open(app.jump_url)}>
                <Space
                  align="baseline"
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                  }}
                >
                  <Meta
                    avatar={orgLogo ?
                      <Avatar shape="square" src={orgLogo} />
                      :
                      <Avatar className="default-logo-button">{orgNameFirstLetter}</Avatar>
                    }
                    title={orgName}
                    description={_.upperFirst(app.description)}
                  />
                </Space>
              </Menu.Item>
              )
          })
        }
        {mbUsers.map((user, i) => {
            const orgName = user.school_name || ""
            let orgNameFirstLetter = orgName.charAt(0);
            const orgLogo = user.logo || ""

            return (
              <Menu.Item key={i} onClick={() => window.open(user.jump_url)}>
                <Space
                  align="baseline"
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                  }}
                >
                  <Meta
                    avatar={orgLogo ?
                      <Avatar shape="square" src={orgLogo} />
                      :
                      <Avatar className="default-logo-button">{orgNameFirstLetter}</Avatar>
                    }
                    title={orgName}
                    description={_.upperFirst(user.role)}
                  />
                </Space>
              </Menu.Item>
              )
          })
        }
      </Menu.ItemGroup>}
      {import.meta.env.VITE_MODE === "demo" && <Menu.ItemGroup title="Switch Services" className="linked-accounts-content">
        <Menu.Item disabled={true}>
          <Tooltip title={"Disabled for demo"} placement="left">
            <Space
              align="baseline"
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <Meta
                avatar={<Avatar shape="square" src={"https://accounts-partners.devel.faria.org/assets/logos/managebac-e1597aef64c4b5455c222237a2b815417cf571d41a124c1dccdecd08d59498a6.svg"} />
                }
                title={"ManageBac"}
                description={_.upperFirst("Curriculum-First Learning Platform")}
              />
            </Space>
          </Tooltip>
        </Menu.Item>
        <Menu.Item disabled={true}>
          <Tooltip title={"Disabled for demo"} placement="left">
            <Space
              align="baseline"
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <Meta
                avatar={<Avatar shape="square" src={"https://accounts-partners.devel.faria.org/assets/logos/smartprep-99983f799e611deae2593340a2497da400f16dd641bdb22f8ae6a17ee5f418c8.svg"} />
                }
                title={"OSC Study"}
                description={_.upperFirst("All-in-One IB DP Revision")}
              />
            </Space>
          </Tooltip>
        </Menu.Item>
      </Menu.ItemGroup>}
    </div>

  const menu = <Menu>{menuItems}</Menu>;
  
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Tooltip 
          // title={"Linked accounts"}
          title={mrIntl("OrgUserSwitcher.linked_accounts")}
          placement="bottom">
          <Button type="link" color="#1890ff" icon={<CgMenuGridR />}>
          </Button>
        </Tooltip>
      </Dropdown>

      {/* <Tag>{currentUser.org_users.map((ou) => ou.custom_fields.org_name)}</Tag> */}
    </>
  );
};

OrgUserSwitcher.defaultProps = {};

OrgUserSwitcher.propTypes = {};

export default OrgUserSwitcher;
