import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useLocation } from "react-router-dom";
import { Button, Modal, Statistic, Alert } from "antd";
import { getSessionId, checkIPAD } from "/src/lib/utils/helperMethods";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { checkMob } from "/src/lib/utils/helperMethods";
import { getInternetStatusSelector } from "/src/views/Experiences/selector";
import {
  attachmentsCountSelector,
  currentSessionIdSelector,
  userInfoSelector,
} from "/src/views/Experiences//ExperienceShow/FirestoreInteractions/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { actions as fireStoreReduxActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";

const internetStatusSelector = getInternetStatusSelector();
const SessionActiveModal = (props) => {
  const { url, path } = useRouteMatch();
  const location = useLocation();
  let isMobile = checkMob() || (checkIPAD() && props.itemMode !== "offline");
  // if high stakes on ipad, then don't treat as mobile
  const userInfoFromRedux = useSelector(userInfoSelector);
  const { sessionModalConfig, setShowQRModal } = props;
  const mrIntl = useTranslate()
  console.log(
    "SessionActiveModal props",
    url,
    path,
    location,
    sessionModalConfig
  );
  const embedded = sessionModalConfig.embedded;
  const experienceViewMode = sessionModalConfig.experienceViewMode;
  // const userInfoFromRedux = sessionModalConfig.userInfoFromRedux
  const setUserInfo = sessionModalConfig.setUserInfo;
  const mobileUploadMode = userInfoFromRedux.mobile_upload_mode;

  // const [attachmentsTotalCount, setAttachmentsTotalCount] = useState(0)
  const attachmentsTotalCount = useSelector(attachmentsCountSelector);
  const internetStatus = useSelector(internetStatusSelector);
  // SESSION ACTIVE STATUS
  let currentSessionIdFromRedux = useSelector(currentSessionIdSelector);
  const [
    isSessionActiveModalVisible,
    setIsSessionActiveModalVisible,
  ] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(
      "session stale - show inactive overlay with resume btn",
      currentSessionIdFromRedux,
      userInfoFromRedux.session_id
    );

    if (
      !embedded &&
      experienceViewMode === "apTakeTest" &&
      userInfoFromRedux &&
      currentSessionIdFromRedux &&
      currentSessionIdFromRedux !== userInfoFromRedux.session_id &&
      (userInfoFromRedux.currentView === "startTest" || userInfoFromRedux.currentView === "endTest")
    ) {
      console.log(
        "session stale - show inactive overlay with resume btn",
        currentSessionIdFromRedux,
        userInfoFromRedux.session_id
      );
      setShowQRModal(false); // fix this not working
      setIsSessionActiveModalVisible(true);
      if (!mobileUploadMode && userInfoFromRedux.deviceInfo) {
        setUserInfo(
          {},
          {
            log: {
              logging: true,
              action: "use_here_triggered",
              current_value: {
                current_device_browser_details: userInfoFromRedux.deviceInfo.browser
              }
            },
          }
        )
      }

      // if(userInfoFromRedux.mobileUploadMode == true){
      //   setReadingModeEnabled(true)
      // }
      // else{
      //   setIsSessionActiveModalVisible(true)
      // }
    }
  }, [currentSessionIdFromRedux, userInfoFromRedux.session_id]);

  // useEffect(() => {
  //   if(mobileUploadMode){
  //     // calc count for now - and later prepare full topic wise count obj and set so render changes
  //     let totalAttachments = {...userInfoFromRedux.total_attachments_json}
  //     console.log("new files uploaded", totalAttachments, Object.keys(totalAttachments));
  //     let totalCount = 0
  //     _.forEach(totalAttachments, (questions, topicId) => {
  //       console.log("new files uploaded questions", questions);
  //       _.forEach(questions, (q, qId) => {
  //         console.log("new files uploaded q", q);
  //         if(q.files_uploaded != undefined){
  //           totalCount += parseInt(q.files_uploaded)
  //         }
  //         console.log("new files uploaded totalCount", totalCount);
  //       })
  //     })
  //     // Object.keys(totalAttachments).map((topicId, i) => {
  //     //   totalAttachments[topicId]
  //     // })
  //     if(!isMobile){
  //       if(attachmentsTotalCount && totalCount != attachmentsTotalCount){
  //         setIsSessionActiveModalVisible(true)
  //       }
  //     }
  //     setAttachmentsTotalCount(totalCount)
  //   }
  // }, [userInfoFromRedux.total_attachments_json]);

  console.log("currentSessionIdFromRedux and userInfoFromRedux.session_id", currentSessionIdFromRedux, userInfoFromRedux, isSessionActiveModalVisible)
  useEffect(() => {
    if (
      currentSessionIdFromRedux !== userInfoFromRedux.session_id &&
      mobileUploadMode &&
      !isMobile &&
      attachmentsTotalCount !== 0 &&
      userInfoFromRedux.currentView === "startTest"
    ) {
      // show modal each time count changes - in case hasn't shown up first time/or any time internet was missing
      setIsSessionActiveModalVisible(true);
    }
  }, [attachmentsTotalCount]);

  useEffect(() => {
    console.log(
      "watching userInfoFromRedux.is_uploading_finished, isMobile",
      userInfoFromRedux.is_uploading_finished,
      mobileUploadMode,
      isMobile
    );
    if (
      mobileUploadMode &&
      !isMobile &&
      userInfoFromRedux.is_uploading_finished == true
    ) {
      // on laptop
      dispatch(firestoreInteractionActions.getUserResponses());
      // const sessionId = getSessionId();
      // dispatch(firestoreInteractionActions.setCurrentSessionIdSuccess({ sessionId }))
      // setUserInfo({  });
      setUserInfo({ session_id: currentSessionIdFromRedux, is_uploading_finished: false });
      setIsSessionActiveModalVisible(false);
      // setUserInfo({is_uploading_finished: false})
    }
    // should bring this back - but test it out
    // if(mobileUploadMode && isMobile && userInfoFromRedux.is_uploading_finished == true){
    //   // on mobile in case session doesn't transfer to laptop cuz offline
    //   // const sessionId = getSessionId();
    //   // dispatch(actions.getUserResponses({sessionId}));
    //   // setUserInfo({session_id: sessionId})
    //   setIsSessionActiveModalVisible(true);
    //   setUserInfo({is_uploading_finished: false})
    // }
  }, [userInfoFromRedux.is_uploading_finished]);

  // useEffect(() => {
  //   if (mobileUploadMode) {
  //     setShowQRModal(false)
  //   }
  // }, [mobileUploadMode])

  let offlineAlert;
  if (internetStatus != "online") {
    offlineAlert = (
      <Alert
        message={mrIntl("SessionActiveModal.internet_error")}
        banner
        closable={false}
      />
    );
  }

  // ** need to handle case where showing this on end test page **
  let modalBodyHtml = "";
  let buttonText = "";
  let buttonType = "primary";
  let showBtn = true;
  let dangerButton = false;
  if (mobileUploadMode == true) {
    if (isMobile) {
      modalBodyHtml = (
        <>
          <Alert
            message={mrIntl("SessionActiveModal.if_your_test_page_is_not_showing_your_uploads_msg")}
            type="warning"
          ></Alert>
          <br></br>
          <h2><MrTranslate id={"SessionActiveModal.upload_status"} /></h2>
          <Statistic
            title={mrIntl("SessionActiveModal.total_files_uploaded")}
            value={attachmentsTotalCount}
          />
        </>
      );
      buttonText = mrIntl("SessionActiveModal.upload_more");
      buttonType = "primary";
    } else {
      modalBodyHtml = (
        <>
          <h2><MrTranslate id={"SessionActiveModal.upload_status"} /></h2>
          {/* {JSON.stringify(userInfoFromRedux.total_attachments_json)} */}
          <Statistic
            title={mrIntl("SessionActiveModal.total_files_uploaded")}
            value={attachmentsTotalCount}
          />
        </>
      );
      showBtn = true;
      // buttonText = "Finish uploading"
      buttonText = mrIntl("SessionActiveModal.done");
      // buttonType = "danger";
      dangerButton = true;
    }
  } else {
    modalBodyHtml = <h3><MrTranslate id={"SessionActiveModal.active_another_window_device_msg"} /></h3>;
    buttonText = mrIntl("SessionActiveModal.use_here");
    buttonType = "primary";
  }

  return (
    <>
      <Modal
        wrapClassName="height-centered-modal text-centered-modal"
        centered={true}
        open={isSessionActiveModalVisible}
        closable={false}
        maskClosable={false}
        keyboard={false}
        footer={null}
        width={400}
      >
        {/* <h3>Mobile UPload mode: {mobileUploadMode.toString()}</h3> */}
        {offlineAlert}
        {modalBodyHtml}
        {showBtn && (
          <Button
            size="large"
            className="m-t-25"
            type={buttonType}
            danger={dangerButton}
            disabled={internetStatus !== "online"}
            onClick={() => {
              // console.log("actions 795", actions)
              console.log("firestoreInteractionActions ==>", firestoreInteractionActions)
              dispatch(firestoreInteractionActions.getUserResponses());
              // const sessionId = getSessionId();
              // dispatch(firestoreInteractionActions.setCurrentSessionIdSuccess({ sessionId }))
              setUserInfo({ session_id: currentSessionIdFromRedux });
              // dispatch(firestoreInteractionActions.setCurrentSessionIdSuccess({ sessionId }))
              // TODO: add log above here to know when user switches from web to mobile and back
              setIsSessionActiveModalVisible(false);
              // setShowQRModal(false) -- // fix this not working

              // mainTestScreen.enter()
              if (!mobileUploadMode && userInfoFromRedux.deviceInfo) {
                setUserInfo(
                  {},
                  {
                    log: {
                      logging: true,
                      action: "use_here_clicked",
                      current_value: {
                        current_device_browser_details: userInfoFromRedux.deviceInfo.browser
                      }
                    },
                  }
                )
              }
            }}
          >
            {buttonText}
          </Button>
        )}
      </Modal>
    </>
  );
};

export default SessionActiveModal;
