import { checkParentElementHasClassNames } from "/src/lib/utils/helperMethods";
import React, { useRef, useEffect } from "react";

export function useOutsideDetector(ref, detectOutside, callback = null, targetExceptionClassname = null) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (checkParentElementHasClassNames(event.target, targetExceptionClassname)) {
          return;
        }
        // alert("You clicked outside of me!");
        console.log("chat clicked outside");
        if (callback) {
          callback();
        }
      }
    }

    if (detectOutside) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      if (detectOutside) {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [ref]);
}

export default function DetectOutsideClick(props) {
  const { detectOutside, callback, targetExceptionClassname = null } = props;
  const wrapperRef = useRef(null);
  useOutsideDetector(wrapperRef, detectOutside, callback, targetExceptionClassname);

  return <span ref={wrapperRef}>{props.children}</span>;
}
