import React, { useEffect, useRef, useState } from "react";
import { Tabs } from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
const type = "DraggableTabNode";


const WrapTabNode = (props) => {
  const { index, children, moveTabNode } = props
  console.log("WrapTabNode props ==>", props)
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: "dropping",
      };
    },
    drop: (item) => {
      moveTabNode(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <div ref={ref} className={isOver ? dropClassName : ""}>
      {children}
    </div>
  );
};

export const DraggableTabs = (props) => {
  console.log("Draggable tabs props ==>", props);
  const { order, setOrder, canDrag } = props;

  const moveTabNode = (dragKey, hoverKey) => {
    console.log("on move tab node ==>", dragKey, hoverKey);
    const newOrder = order.slice();

    const dragIndex = newOrder.findIndex((o) => o.id == dragKey);
    const hoverIndex = newOrder.findIndex((o) => o.id == hoverKey);

    const dragItem = newOrder[dragIndex];
    const dropItem = newOrder[hoverIndex];

    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragItem);

    setOrder(newOrder);
  };

  const renderTabBar = (props, DefaultTabBar) => (
    <DefaultTabBar {...props}>
      {(node) => (
        <WrapTabNode
          key={node.key}
          index={node.key}
          canDrag={canDrag}
          moveTabNode={moveTabNode}
        >
          {node}
        </WrapTabNode>
      )}
    </DefaultTabBar>
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Tabs renderTabBar={renderTabBar} {...props}></Tabs>
    </DndProvider>
  );
};
