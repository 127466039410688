import React, { useEffect, useState } from "react";
import { Modal, Button, Tooltip } from "antd";

import {
  CheckOutlined,
  CloseOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { BsTextareaT, BsArrowRight, BsPencil } from "react-icons/bs";
import {
  MdLabelOutline,
  MdFlipToFront,
  MdFlipToBack,
  MdDelete,
  MdBorderColor,
} from "react-icons/md";
import {
  BiCircle,
  BiRectangle,
  BiUndo,
  BiRedo,
  BiCheckCircle,
  BiColorFill,
} from "react-icons/bi";
import { GoDotFill } from "react-icons/go";
import { FaDrawPolygon } from "react-icons/fa";
import { AiOutlinePicture, AiOutlineMinus } from "react-icons/ai";
import { HiOutlineRefresh } from "react-icons/hi";
import { FiMove } from "react-icons/fi";
import { getUniqueId } from "/src/lib/utils/helperMethods";

import MediaUI from "/src/components/UI/Media/MediaUI";
import ColorPicker from "/src/components/UI/ColorPicker/ColorPicker";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const FabricTools = (props) => {
  console.log("Ye fabric tools ke props hai ==>", props);
  const { canvas, toolBarConfig } = props;
  const mrIntl = useTranslate()
  const toolClassName = "tool";
  const toolStyle = {
    tooltipColor: "#108ee9",
    grabButtonType: "primary",
    tooltipPlacement: "top",
    ...toolBarConfig.toolStyle,
  };

  const imageFormInitialState = {
    modal: true,
    loading: false,
    visible: false,
    title: mrIntl ("FabricTools.insert_images"),
    okText: mrIntl("FabricTools.add_images"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    requireFooter: false,
    content_type: "image",
    okDisabled: true,
    supportedTypes: "image/jpg, image/jpeg, image/png, image/gif, image/bmp",
    supportedFormats: "jpg, jpeg, png, gif, bmp",
    selectConfig: {
      maxSize: "10 MB",
    },
  };
  const [imageFormSeting, setImageFormSetting] = useState(
    imageFormInitialState
  );
  const showColorPicker = toolBarConfig.toolBar.findIndex((type) => type === "strokeColor" || type === "fillColor")

  canvas.drawMode = toolBarConfig.drawMode;

  // useEffect(() => {
  //   if (props.resources) {
  //     setImageFormSetting({
  //       ...imageFormSeting,
  //       extraOkText: "Insert to Resource",
  //     });
  //   }
  // }, []);

  // canvas.tools = {
  //   drawingTools: [
  //     "textLabel",
  //     "line",
  //     "arrow",
  //     "rectangle",
  //     "circle",
  //     "polygon",
  //   ],
  //   addOrUpdateTools: ["move", "iText", "bringToFront", "sendToBack"],
  //   selectAndUpdateTools: ["delete", "setHotspot"],
  // };

  const [currentTool, setCurrentTool] = useState("");
  const updateTool = (type, value) => {
    // canvas.discardActiveObject().renderAll();
    setCurrentTool(value ? type : "");
    if (type === "line") {
      canvas.toolType.line = value;
    } else {
      canvas.toolType.line = false;
    }

    if (type === "arrow") {
      canvas.toolType.arrow = value;
    } else {
      canvas.toolType.arrow = false;
    }

    if (canvas.drawMode) {
      if (type === "rectangle") {
        canvas.toolType.rectangle = value;
      } else {
        canvas.toolType.rectangle = false;
      }

      if (type === "circle") {
        canvas.toolType.circle = value;
      } else {
        canvas.toolType.circle = false;
      }

      if (type === "dot") {
        canvas.toolType.dot = value;
      } else {
        canvas.toolType.dot = false;
      }

      if (type === "polygon") {
        canvas.toolType.polygon = value;
      } else {
        canvas.toolType.polygon = false;
      }

      if (type === "path") {
        canvas.toolType.path = canvas.isDrawingMode = value;
        // canvas["toolType"]["path = canvas.canvas["toolType"]["pathingBrush;
        // canvas["toolType"]["path.width = 2;
        // canvas["toolType"]["path.color = shapeStyle.fill;
      } else {
        canvas.toolType.path = canvas.isDrawingMode = false;
      }

      if (type === "text") {
        canvas.toolType.text = value;
      } else {
        canvas.toolType.text = false;
      }

      if (type === "textLabel") {
        canvas.toolType.textLabel = value;
      } else {
        canvas.toolType.textLabel = false;
      }

      if (type === "setHotspot") {
        canvas.hotspotModeEnabled = value;
        canvas.toolType.setHotspot = value;
      } else {
        canvas.hotspotModeEnabled = false;
        canvas.toolType.setHotspot = false;
      }
    }

    console.log("Current shape ===>", type, canvas.toolType, value);
    canvas.addShape(type);

    if (type == "move") {
      canvas.toolType.move = value;
    } else {
      canvas.toolType.move = false;
    }

    if (type == "zoomIn") {
      canvas.toolType.zoomIn = value;
    } else {
      canvas.toolType.zoomIn = false;
    }

    if (type == "zoomOut") {
      canvas.toolType.zoomOut = value;
    } else {
      canvas.toolType.zoomOut = false;
    }

    if (type == "checkTick") {
      canvas.toolType.checkTick = value;
    } else {
      canvas.toolType.checkTick = false;
    }

    if (type == "wrongTick") {
      canvas.toolType.wrongTick = value;
    } else {
      canvas.toolType.wrongTick = false;
    }

    if (type == "commentIndicator") {
      canvas.toolType.commentIndicator = value;
    } else {
      canvas.toolType.commentIndicator = false;
    }

    if (type == "fillColor") {
      canvas.toolType.fillColor = value;
    } else {
      canvas.toolType.fillColor = false;
    }

    if (type == "strokeColor") {
      canvas.toolType.strokeColor = value;
    } else {
      canvas.toolType.strokeColor = false;
    }

    if (type == "delete") {
      canvas.toolType.delete = value;
      // if (value) {
      // 	deleteSelectedObject()
      // }
    } else {
      canvas.toolType.delete = false;
    }

    if (type == "image") {
      openMediaModal("image");
    } else if (type == "bgImage") {
      openMediaModal("bgImage");
    } else if (type == "rotateLeft") {
      rotateImage("left");
    } else if (type == "rotateRight") {
      rotateImage("right");
    } else if (type == "bringToFront") {
      bringToFront();
    } else if (type == "sendToBack") {
      sendToBack();
    } else if (type == "undo") {
      undoLastObject();
    } else if (type == "redo") {
      redoLastObject();
    } else if (type == "clearAll") {
      clearAll();
    }

    canvas.currentTool = type;
    canvas.onToolSelect(type, value);
    canvas.updateShapePosition(type);
  };

  const rotateImage = (direction) => {
    canvas.rotateImage(direction);
  };

  const bringToFront = () => {
    canvas.bringToFrontObject();
  };

  const sendToBack = () => {
    canvas.sendToBackObject();
  };
  const undoLastObject = () => {
    canvas.undoLastObject();
    setCurrentTool("move")
  };

  const redoLastObject = () => {
    canvas.redoLastObject();
    setCurrentTool("move")
  };

  const deleteSelectedObject = () => {
    canvas.deleteObject();
  };

  const clearAll = () => {
    canvas.clearAll();
  };

  const openMediaModal = (type) => {
    const newImageFormSeting = {
      ...imageFormSeting,
      visible: true,
      imageType: type,
      from: "computer",
    };
    setImageFormSetting(newImageFormSeting);
  };

  const onSaveImages = (files) => {
    console.log("On save media ==>", files);
    if (imageFormSeting.extraOkClicked) {
      // canvas.resources = canvas.resources.concat(files);
      setImageFormSetting(imageFormInitialState);
    } else {
      setImageFormSetting({
        ...imageFormSeting,
        okDisabled: true,
      });
      canvas.insertImages(files, imageFormSeting.imageType, (response) => {
        if (response) {
          setImageFormSetting(imageFormInitialState);
        }
      });
    }
  };

  canvas.updateCurrentTool = (toolType, value) => {
    console.log("Update current tool ==>", toolType, value);
    updateTool(toolType, value);
  };

  canvas.tools = {
    move: {
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "move",
      imageHoverCursor: "move",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.move")}
          color={toolStyle.tooltipColor}
          key="move"
        >
          <Button
            onClick={(e) => updateTool("move", true)}
            disabled={props.disabled}
            type={(currentTool == "move" && toolStyle.grabButtonType) || "default"} 
            className={toolClassName}
            icon={<FiMove />}
          ></Button>
        </Tooltip>
      ),
    },
    iText: {
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "move",
      imageHoverCursor: "move",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.add_text")}
          color={toolStyle.tooltipColor}
          key="i-text"
        >
          <Button
            onClick={(e) => updateTool("iText", false)}
            disabled={props.disabled}
            className={toolClassName}
            icon={<BsTextareaT />}
          ></Button>
        </Tooltip>
      ),
    },
    text: {
      canSelect: false,
      canvasHoverCursor: "default",
      objectHoverCursor: "text",
      imageHoverCursor: "text",
      perPixelTargetFind: true,
      objectEvented: false,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.add_text")}
          color={toolStyle.tooltipColor}
          key="text"
        >
          <Button
            onClick={(e) => updateTool("text", true)}
            disabled={props.disabled}
            className={toolClassName}
            icon={<BsTextareaT />}
          ></Button>
        </Tooltip>
      ),
    },
    textLabel: {
      drawingTool: false,
      canvasHoverCursor: "crosshair",
      objectHoverCursor: "move",
      imageHoverCursor: "move",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: false,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.draw_label")}
          color={toolStyle.tooltipColor}
          key="i-text-label"
        >
          <Button
            onClick={(e) => updateTool("textLabel", !canvas.toolType.textLabel)}
            disabled={props.disabled}
            type={(currentTool == "textLabel" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<MdLabelOutline />}
          ></Button>
        </Tooltip>
      ),
    },
    checkTick: {
      drawingTool: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "pointer",
      imageHoverCursor: "pointer",
      perPixelTargetFind: true,
      objectEvented: false,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.check")}
          color={toolStyle.tooltipColor}
          key="check-tick"
        >
          <Button
            onClick={(e) => updateTool("checkTick", !canvas.toolType.checkTick)}
            disabled={props.disabled}
            type={(currentTool == "checkTick" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<CheckOutlined />}
          ></Button>
        </Tooltip>
      ),
    },
    wrongTick: {
      drawingTool: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "pointer",
      imageHoverCursor: "default",
      perPixelTargetFind: true,
      objectEvented: false,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.cross")}
          color={toolStyle.tooltipColor}
          key="wrong-tick"
        >
          <Button
            onClick={(e) => updateTool("wrongTick", !canvas.toolType.wrongTick)}
            disabled={props.disabled}
            type={(currentTool == "wrongTick" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<CloseOutlined />}
          ></Button>
        </Tooltip>
      ),
    },
    commentIndicator: {
      drawingTool: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "pointer",
      imageHoverCursor: "default",
      perPixelTargetFind: true,
      objectEvented: false,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.comment")}
          color={toolStyle.tooltipColor}
          key="comment-indicator"
        >
          <Button
            onClick={(e) =>
              updateTool("commentIndicator", !canvas.toolType.commentIndicator)
            }
            disabled={props.disabled}
            type={(currentTool == "commentIndicator" && toolStyle.grabButtonType)  || "default"}
            className={toolClassName}
            icon={<CommentOutlined />}
          ></Button>
        </Tooltip>
      ),
    },
    image: {
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "move",
      imageHoverCursor: "move",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.insert_image")}
          color={toolStyle.tooltipColor}
          key="insert-image"
        >
          <Button
            onClick={(e) => updateTool("image", true)}
            disabled={props.disabled}
            className={toolClassName}
            icon={<AiOutlinePicture />}
          ></Button>
        </Tooltip>
      ),
    },
    bgImage: {
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "move",
      imageHoverCursor: "move",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.insert_images")}
          color={toolStyle.tooltipColor}
          key="insert-image"
        >
          <Button
            onClick={(e) => updateTool("bgImage", true)}
            disabled={props.disabled}
            className={toolClassName}
            icon={<AiOutlinePicture />}
          ></Button>
        </Tooltip>
      ),
    },
    line: {
      drawingTool: false,
      canvasHoverCursor: "crosshair",
      objectHoverCursor: "move",
      imageHoverCursor: "crosshair",
      perPixelTargetFind: true,
      objectEvented: false,
      imageEvented: false,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.draw_line")}
          color={toolStyle.tooltipColor}
          key="draw-line"
        >
          <Button
            onClick={(e) => updateTool("line", !canvas.toolType.line)}
            disabled={props.disabled}
            type={(currentTool == "line" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<AiOutlineMinus />}
          ></Button>
        </Tooltip>
      ),
    },
    arrow: {
      drawingTool: false,
      canvasHoverCursor: "crosshair",
      objectHoverCursor: "move",
      imageHoverCursor: "crosshair",
      perPixelTargetFind: true,
      objectEvented: false,
      imageEvented: false,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.draw_arrow")}
          color={toolStyle.tooltipColor}
          key="draw-arrow"
        >
          <Button
            onClick={(e) => updateTool("arrow", !canvas.toolType.arrow)}
            disabled={props.disabled}
            type={(currentTool == "arrow" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<BsArrowRight />}
          ></Button>
        </Tooltip>
      ),
    },
    rectangle: {
      drawingTool: false,
      canvasHoverCursor: "crosshair",
      objectHoverCursor: "move",
      imageHoverCursor: "crosshair",
      perPixelTargetFind: true,
      objectEvented: false,
      imageEvented: false,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.draw_rectangle")}
          color={toolStyle.tooltipColor}
          key="draw-rectangle"
        >
          <Button
            onClick={(e) => updateTool("rectangle", !canvas.toolType.rectangle)}
            disabled={props.disabled}
            type={(currentTool == "rectangle" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<BiRectangle />}
          ></Button>
        </Tooltip>
      ),
      ...toolBarConfig.rectangle,
    },
    circle: {
      drawingTool: false,
      canvasHoverCursor: "crosshair",
      objectHoverCursor: "move",
      imageHoverCursor: "crosshair",
      perPixelTargetFind: true,
      objectEvented: false,
      imageEvented: false,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.draw_circle")}
          color={toolStyle.tooltipColor}
          key="draw-circle"
        >
          <Button
            onClick={(e) => updateTool("circle", !canvas.toolType.circle)}
            disabled={props.disabled}
            type={(currentTool == "circle" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<BiCircle />}
          ></Button>
        </Tooltip>
      ),
    },
    dot: {
      drawingTool: false,
      canvasHoverCursor: "crosshair",
      objectHoverCursor: "move",
      imageHoverCursor: "crosshair",
      perPixelTargetFind: true,
      objectEvented: false,
      imageEvented: false,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.draw_dot")}
          color={toolStyle.tooltipColor}
          key="draw-circle"
        >
          <Button
            onClick={(e) => updateTool("dot", !canvas.toolType.dot)}
            disabled={props.disabled}
            type={(currentTool === "dot" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<GoDotFill />}
          ></Button>
        </Tooltip>
      ),
    },
    polygon: {
      drawingTool: true,
      canvasHoverCursor: "crosshair",
      objectHoverCursor: "move",
      imageHoverCursor: "crosshair",
      perPixelTargetFind: true,
      objectEvented: false,
      imageEvented: false,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.draw_polygon")}
          color={toolStyle.tooltipColor}
          key="draw-polygon"
        >
          <Button
            onClick={(e) => updateTool("polygon", !canvas.toolType.polygon)}
            disabled={props.disabled}
            type={(currentTool == "polygon" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<FaDrawPolygon />}
          ></Button>
        </Tooltip>
      ),
    },
    path: {
      drawingTool: true,
      canvasHoverCursor: "crosshair",
      objectHoverCursor: "move",
      imageHoverCursor: "crosshair",
      perPixelTargetFind: true,
      objectEvented: false,
      imageEvented: false,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.draw_path")}
          color={toolStyle.tooltipColor}
          key="draw-path"
        >
          <Button
            onClick={(e) => updateTool("path", !canvas.toolType.path)}
            disabled={props.disabled}
            type={(currentTool == "path" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<BsPencil />}
          ></Button>
        </Tooltip>
      ),
    },
    fillColor: {
      drawingTool: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "pointer",
      imageHoverCursor: "default",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: false,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.fill_color")}
          color={toolStyle.tooltipColor}
          key="fill-color"
        >
          <Button
            onClick={(e) => updateTool("fillColor", !canvas.toolType.fillColor)}
            disabled={props.disabled}
            type={(currentTool == "fillColor" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<BiColorFill />}
          ></Button>
        </Tooltip>
      ),
    },
    strokeColor: {
      drawingTool: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "pointer",
      imageHoverCursor: "default",
      perPixelTargetFind: true,
      objectEvented: true,
      imageEvented: false,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.border_color")}
          color={toolStyle.tooltipColor}
          key="stroke-color"
        >
          <Button
            onClick={(e) =>
              updateTool("strokeColor", !canvas.toolType.strokeColor)
            }
            disabled={props.disabled}
            type={(currentTool == "strokeColor" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<MdBorderColor />}
          ></Button>
        </Tooltip>
      ),
    },
    bringToFront: {
      drawingTool: true,
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "pointer",
      imageHoverCursor: "pointer",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.bring_to_front")}
          color={toolStyle.tooltipColor}
          key="bring-to-front"
        >
          <Button
            onClick={(e) => updateTool("bringToFront", true)}
            disabled={props.disabled}
            className={toolClassName}
            icon={<MdFlipToFront />}
          ></Button>
        </Tooltip>
      ),
    },
    sendToBack: {
      drawingTool: true,
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "pointer",
      imageHoverCursor: "pointer",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.send_to_back")}
          color={toolStyle.tooltipColor}
          key="send-to-back"
        >
          <Button
            onClick={(e) => updateTool("sendToBack", true)}
            disabled={props.disabled}
            className={toolClassName}
            icon={<MdFlipToBack />}
          ></Button>
        </Tooltip>
      ),
    },
    rotateLeft: {
      drawingTool: true,
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "move",
      imageHoverCursor: "move",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.rotate_left")}
          color={toolStyle.tooltipColor}
          key="rotate-left"
        >
          <Button
            onClick={(e) => updateTool("rotateLeft", false)}
            disabled={props.disabled}
            className={toolClassName}
            icon={<RotateLeftOutlined />}
          ></Button>
        </Tooltip>
      ),
    },
    rotateRight: {
      drawingTool: true,
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "move",
      imageHoverCursor: "move",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.rotate_right")}
          color={toolStyle.tooltipColor}
          key="rotate-right"
        >
          <Button
            onClick={(e) => updateTool("rotateRight", false)}
            disabled={props.disabled}
            className={toolClassName}
            icon={<RotateRightOutlined />}
          ></Button>
        </Tooltip>
      ),
    },
    zoomIn: {
      drawingTool: true,
      canvasHoverCursor: "zoom-in",
      objectHoverCursor: "zoom-in",
      imageHoverCursor: "zoom-in",
      perPixelTargetFind: false,
      objectEvented: false,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.zoom_in")}
          color={toolStyle.tooltipColor}
          key="zoom-in"
        >
          <Button
            onClick={(e) => updateTool("zoomIn", true)}
            disabled={props.disabled}
            type={(currentTool == "zoomIn" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<ZoomInOutlined />}
          ></Button>
        </Tooltip>
      ),
    },
    zoomOut: {
      drawingTool: true,
      canvasHoverCursor: "zoom-out",
      objectHoverCursor: "zoom-out",
      imageHoverCursor: "zoom-out",
      perPixelTargetFind: false,
      objectEvented: false,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.zoom_out")}
          color={toolStyle.tooltipColor}
          key="zoom-out"
        >
          <Button
            onClick={(e) => updateTool("zoomOut", true)}
            disabled={props.disabled}
            type={(currentTool == "zoomOut" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<ZoomOutOutlined />}
          ></Button>
        </Tooltip>
      ),
    },
    undo: {
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "move",
      imageHoverCursor: "move",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.undo")}
          color={toolStyle.tooltipColor}
          key="undo-change"
        >
          <Button
            onClick={(e) => updateTool("undo", true)}
            disabled={props.disabled}
            className={toolClassName}
            icon={<BiUndo />}
          ></Button>
        </Tooltip>
      ),
    },
    redo: {
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "move",
      imageHoverCursor: "move",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.redo")}
          color={toolStyle.tooltipColor}
          key="redo-change"
        >
          <Button
            onClick={(e) => updateTool("redo", true)}
            disabled={props.disabled}
            className={toolClassName}
            icon={<BiRedo />}
          ></Button>
        </Tooltip>
      ),
    },
    delete: {
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "pointer",
      imageHoverCursor: "pointer",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("CommonText.delete")}
          color={toolStyle.tooltipColor}
          key="delete-object"
        >
          <Button
            onClick={(e) => deleteSelectedObject()}
            disabled={props.disabled}
            type={(currentTool == "delete" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<MdDelete />}
          ></Button>
        </Tooltip>
      ),
    },
    clearAll: {
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "move",
      imageHoverCursor: "move",
      perPixelTargetFind: false,
      objectEvented: true,
      imageEvented: true,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.clear_all")}
          color={toolStyle.tooltipColor}
          key="clear-canvas"
        >
          <Button
            onClick={(e) => updateTool("clearAll", true)}
            disabled={props.disabled}
            className={toolClassName}
            icon={<HiOutlineRefresh />}
          ></Button>
        </Tooltip>
      ),
    },
    setHotspot: {
      drawingTool: true,
      canSelect: true,
      canvasHoverCursor: "default",
      objectHoverCursor: "pointer",
      imageHoverCursor: "defult",
      perPixelTargetFind: true,
      objectEvented: true,
      imageEvented: false,
      getTool: () => (
        <Tooltip
          placement={toolStyle.tooltipPlacement}
          title={mrIntl("FabricTools.choose_correct_hotspot")}
          color={toolStyle.tooltipColor}
          key="choose-hotspot"
        >
          <Button
            onClick={(e) =>
              updateTool("setHotspot", !canvas.toolType.setHotspot)
            }
            disabled={props.disabled}
            type={(currentTool == "setHotspot" && toolStyle.grabButtonType) || "default"}
            className={toolClassName}
            icon={<BiCheckCircle />}
          ></Button>
        </Tooltip>
      ),
    },
    // resources: {
    //   canSelect: true,
    //   canvasHoverCursor: "default",
    //   objectHoverCursor: "move",
    //   imageHoverCursor: "move",
    //   perPixelTargetFind: false,
    //   objectEvented: true,
    //   imageEvented: true,
    //   getTool: () => <div key="choose-from-resource"></div>,
    // },
  };
 
  console.log("Tools ==>", canvas.tools);
  return (
    <div className="fabric-tools">
      {toolBarConfig.toolBar.map((type) => {
        if (canvas.tools[type]) {
          return canvas.tools[type].getTool();
        }
      })} 
      {showColorPicker >= 0 && <ColorPicker
        defaultConfig={{
          color: canvas[currentTool],
        }}
        className="fabric-canvas-color-pallete"
        type="CompactPicker"
        onChange={(color) => {
          canvas.colorPickerColor = color.hex
          // canvas[currentTool] = color.hex;
          canvas.freeDrawingBrush.color = color.hex;
          // currentTool === "fillColor" ? canvas.shapeStyle[currentTool] = color.hex : canvas.shapeStyle["strokeColor"] = color.hex;
          canvas.shapeStyle["strokeColor"] = color.hex;
          canvas.shapeStyle["fillColor"] = color.hex;
          console.log("on change color ==>", color);
        }}
      ></ColorPicker>}

      <MediaUI
        computer={true}
        url={true}
        mediaFormSetting={imageFormSeting}
        setMediaFormSetting={setImageFormSetting}
        onSaveMedia={(files) => onSaveImages(files)}
      ></MediaUI>
    </div>
  );
};

export default FabricTools;
