import DraggableList from '/src/components/UI/CrudDnD/DraggableList'
import TagsFilter from './TagsFilter/TagsFilter';
import React, {useState, useEffect, createContext, useCallback} from "react";
import {Route, useRouteMatch} from "react-router-dom";
import {Button, Tooltip, Row, Col, message, Select, Space} from "antd";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import {SelectGradeGroup, SelectProgrammeGrade}  from "/src/components/AppSpecific/SelectSubject/SelectSubject"
import _ from "lodash";
import { findStandardsByGrade, getDestinationPositionLex } from '/src/lib/utils/helperMethods';
import { useDispatch } from 'react-redux';
import { actions as tagsActions } from "/src/views/Tags/redux";
import { debounce } from 'lodash';
import LoadingCanvas from '/src/components/UI/Spinner/LoadingCanvas';
const { Option } = Select

export const filterByGrade = (list, grade )=>{
  if (!grade) {
    return list;
  }
  console.log("filterByGrade called", { list, grade });

  let filteredList = list.filter((topic) => {
    if (!topic.relations) {
      console.log("aq relations", topic);
      return true;
    } else {
      let isMatched = topic.relations.some((relation) => {
        console.log("aq relations", relation.label, grade, topic);
        let flag = relation.label == grade;
        return flag;
      });
      return isMatched
    }
  });
  console.log("filteredList", filteredList);

  return filteredList;
}


const Filters = (props)=>{
  const {path, url} = useRouteMatch();
  const {gradeId, setGrade, programme_id, contentAreaId, gradeGroups} = props

  console.log("props tags filter", props)

  let extraFilters = [];
  const handleGrade = (opts)=>{
    console.log("aqp handleGrade opts", opts);
    if(!opts){
      setGrade({label: null, value: null})
    }
    else{
      let {label, value, grade_number} = opts  
      setGrade({label: label, value: value, grade_number: grade_number})
    }
  }
  if(programme_id || contentAreaId){
    // extraFilters.push(
    //   <SelectProgrammeGrade
    //     key="selectGradeSegmentsFilter"
    //     value={gradeId}
    //     onChange={(value, opts) => {
    //       console.log("aq opts grade", opts); 
    //       // props.setParams({by_programme_grade_id: value})
    //       handleGrade(opts);
    //     }}
    //     config={{
    //       // forceReload: true,
    //       // To show programme grade and filter by grade_number
    //       params: { by_programme_id: programme_id, by_type_c: "grade" },
    //       // To show benchmark level filter and filter by label -- but issue is that it is showing all benchmark levels across content areas
    //       // params: { by_programme_id: programme_id, by_type_c: "group" },
    //       widgetConfig: { style: { width: "250px" } },
    //     }}
    //   />
    // );
    extraFilters.push(
      <SelectGradeGroup
        key="selectGradeGroupFilter"
        value={gradeId}
        onChange={(value, opts) => {
          console.log("aq opts grade", opts); 
          // props.setParams({by_programme_grade_id: value})
          handleGrade(opts);
        }}
        config={{
          // forceReload: true,
          params: { by_parent_id: contentAreaId, by_type_c: "grade_group" },
          widgetConfig: { 
            style: { width: "250px" },
            options: gradeGroups || [],
          },
        }}
      />
    );
  }

  extraFilters.push(
    <ResetFilter setParams={()=>setGrade({label : null, value: null})}/>
  )

  return extraFilters;
}

// export const FinalJsonContext = createContext([])

const TagItemDetail = (props) => {
  console.log("eaqmsd TagItemDetal", props);
  const {item, item: {programme_id}, updateResource} = props
  const finalJsonData = (item.final_json && item.final_json.data) || []; 
  const [tags, setTags] = useState(finalJsonData)
  const [grade, setGrade] = useState({ label: null, value: null, grade_number: null });
  const [filteredTags, setFilteredTags] = useState([])
  // const [canSubmit, setCanSubmit] = useState(true)
  const [loading, setLoading] = useState(false)
  
  const tagId = item.id
  const tagUUID = item.uuid
  const isAtlasSynced = item.uuid.indexOf("atlas") > -1
  const tagTypeC = item.type_c
  const gradeNumber = grade.grade_number;
  const gradeLabel = grade.label;
  // const nestingInputOptions = [2, 3, 4];
  const dispatch = useDispatch()
  console.log("eaqmsd TagItemDetal contentArea", finalJsonData);
  
  useEffect(() => {
    console.log("tags useEffect", tags, gradeNumber);
    if (tags.length >= 0) {
      if (gradeNumber) {
        let filteredTags = findStandardsByGrade(tags, null, gradeNumber);
        setFilteredTags(filteredTags);
      } else if (gradeLabel) {
        // when filtering by benchmark level - tag grade_group
        let filteredTags = findStandardsByGrade(tags, gradeLabel, null, "grade_group");
        setFilteredTags(filteredTags);
      } else {
        setFilteredTags(tags);
      }
      console.log("eaqmsd filteredTags useEffect", filteredTags);
    }
  }, [gradeNumber, gradeLabel, tags]);

  console.log("filteredTags", filteredTags);
  const filteredTagsLength = filteredTags.length

  const createNewItem = (parentId = null, parentTypeC = "content_area", typeC = "topic", prevPosition = null, nextPosition = null) => {
    console.log("createNewItem called");
    
    const item = {
      archived: false,
      has_children: false,
      label: "",
      name: "",
      parent_id: parentId,
      parent_type_c: parentTypeC,
      // relations: (data && data.relations) || [], //TODO ask how to create relations in item -- in same and children
      type_c: typeC,
      position: getDestinationPositionLex(prevPosition, nextPosition),
    };
    return item;
  }

  const handleDropAction = (dropItem, dragItem, dropPosition, dragItemParentId, shouldChangeDragParentHasChildren)=>{
    console.log("zzhh handleDropAction",{dropItem, dragItem, dropPosition, dragItemParentId, shouldChangeDragParentHasChildren});
    dispatch(
      tagsActions.update(
        { id: dragItem.id, position: dragItem.position, parent_id: dragItem.parent_id },
        {
          success: {
            showMessage: false,
          },
          successCallback: (data) => {
            try {
              console.log("handleDrop dragitem successCallback", data);
              let updatedFinalJson = tags.map((listItem) => {
                if (listItem.id === dragItem.id) {
                  return (listItem = { ...dragItem }); // TODO: update values and whole item when getting perfect response
                }
                return listItem;
              });
              if(shouldChangeDragParentHasChildren){
                dispatch(
                  tagsActions.update(
                    { id: dragItemParentId, has_children: false },
                    {
                      success: {
                        showMessage: false,
                      },
                      successCallback: (data) => {
                        console.log("deleteItem has_children successCallback", data);
                        let hasChildren = data.data.tag.data.attributes.has_children;
                        updatedFinalJson = updatedFinalJson.map((listItem) => {
                          if (listItem.id === dragItemParentId) {
                            return { ...listItem, has_children: hasChildren }; 
                          }
                          return listItem;
                        });
                        if (!dropItem.has_children && dropPosition=="addChild") {
                          dispatch(
                            tagsActions.update(
                              { id: dropItem.id, has_children: true },
                              {
                                success: {
                                  showMessage: false,
                                },
                                successCallback: (data) => {
                                  console.log(data);
                                  let hasChildren = data.data.tag.data.attributes.has_children;
                                  updatedFinalJson = updatedFinalJson.map((listItem) => {
                                    if (listItem.id === dropItem.id) {
                                      return { ...listItem, has_children: hasChildren };  
                                    }
                                    return listItem;
                                  });
                                  handleSubmit(updatedFinalJson)
                                },
                                errorCallback: ()=>{
                                  message.error( "Error in updating tags")
                                  setLoading(false)
                                }
                              }
                            )
                          );
                        } else {
                          handleSubmit(updatedFinalJson)
                        }  
                      },
                      errorCallback: ()=>{
                        message.error( "Error in updating tags")
                        setLoading(false)
                      }
                    }
                  )
                );
              }
              else if(!dropItem.has_children && dropPosition === "addChild") {
                dispatch(
                  tagsActions.update(
                    { id: dropItem.id, has_children: true },
                    {
                      success: {
                        showMessage: false,
                      },
                      successCallback: (data) => {
                        console.log(data);
                        let hasChildren = data.data.tag.data.attributes.has_children;
                        updatedFinalJson = updatedFinalJson.map((listItem) => {
                          if (listItem.id === dropItem.id) {
                            return { ...listItem, has_children: hasChildren }; 
                          }
                          return listItem;
                        });
                        // setItems(updatedFinalJson);
                        handleSubmit(updatedFinalJson)
                      },
                      errorCallback: ()=>{
                        message.error( "Error in updating tags")
                        setLoading(false)
                      }
                    }
                  )
                );
              } else {
                // setItems(updatedFinalJson);
                handleSubmit(updatedFinalJson)
              }    
            } catch (error) {
              message.error("error in drop actoin")
              console.log("handleDropAction error ===>", error);
            }
          },
          errorCallback: ()=>{
            message.error( "Error in updating tags")
            setLoading(false)
          }
        }
      )
    );
  }

  const handleDragItemAndDrop = (dropItem, dragItem, dropPosition, nextPositionForChild, index, dropItemSiblings, dragItemSiblings) => {
    setLoading(true)
    console.log("zzhh handleDragItemAndDrop data", {
      dropItem, dragItem, dropPosition, nextPositionForChild, index, dropItemSiblings, dragItemSiblings
    });
    let dragItemParentId = dragItem.parent_id
    let shouldChangeDragParentHasChildren = dragItemParentId && dragItemSiblings.length == 1

    const { id, parent_id, relations, type_c, has_children } = dropItem;
    let prevPosition, nextPosition, position;
    if (dropPosition === "addAfter") {
      prevPosition = dropItemSiblings[index].position;
      nextPosition = dropItemSiblings[index + 1] ? dropItemSiblings[index + 1].position : null;;
      dragItem.parent_id = parent_id;
      dragItem.parent_type_c = dropItem.parent_type_c
    } else if (dropPosition === "addBefore") {
      prevPosition = dropItemSiblings[index - 1] ? dropItemSiblings[index - 1].position : null;
      nextPosition = dropItemSiblings[index].position;
      dragItem.parent_id = parent_id;
      dragItem.parent_type_c = dropItem.parent_type_c
    } else if (dropPosition === "addChild") {
      prevPosition = null;
      nextPosition = nextPositionForChild;
      dragItem.parent_id = id;
      dragItem.parent_type_c = null
    }
    
    position = getDestinationPositionLex(prevPosition, nextPosition);
    console.log("zzhh drop updated positions", {prevPosition, nextPosition, position});
    dragItem.position = position;
    handleDropAction(dropItem,dragItem, dropPosition, dragItemParentId, shouldChangeDragParentHasChildren)
  }

  const addChildrenItem = (sourceItem) => {
    setLoading(true)
    const {id, parent_id, parent_type_c, type_c, position} = sourceItem
    let newItem = createNewItem(id, null, type_c);
    dispatch(
      tagsActions.create(
        {
          tag: newItem,
        },
        {
          success: {
            showMessage: false,
          },
          successCallback: (data) => {
            try {
              console.log("tag successCallback data", data);
              newItem.id = data.data.tag.data.attributes.id;
              newItem.uuid = data.data.tag.data.attributes.uuid;
              console.log("aqped has_children", sourceItem.has_children);
              if (!sourceItem.has_children) {
                dispatch(
                  tagsActions.update(
                    { id: sourceItem.id, has_children: true },
                    {
                      success: {
                        showMessage: false,
                      },
                      successCallback: (data) => {
                        console.log("addChildrenItem has_children successCallback", data);
                        let hasChildren = 
                          data.data.tag.data.attributes.has_children;
                        let updatedFinalJson = tags.map((listItem) => {
                          if (listItem.id === sourceItem.id) {
                            return { ...listItem, has_children: hasChildren }; 
                          }
                          return listItem;
                        });
                        // setItems((prevItems) => [...updatedFinalJson, newItem]);
                        updatedFinalJson = updatedFinalJson.concat(newItem)
                        handleSubmit(updatedFinalJson)
            
                      },
                      errorCallback: ()=>{
                        message.error( "Error in updating tags")
                        setLoading(false)
                      }
                    }
                  )
                );
              } else {
                // setItems((prevItems) => [...prevItems, newItem]);
                let updatedFinalJson = tags.concat(newItem)
                handleSubmit(updatedFinalJson)
              }              
            } catch (error) {
              message.error("error in adding children");
              console.log("addChildrenItem error===>", error);
            }
          },
          errorCallback: ()=>{
            message.error( "Error in updating tags")
            setLoading(false)
          }
        }
      )
    ); 
  };
  const addItem = (sourceItem, addPosition, sourceItemIndex, sourceItemSiblings) => {
    setLoading(true)
    console.log("ddkk addItem data ===>", { sourceItem, addPosition, sourceItemIndex, sourceItemSiblings });
    const {id, parent_id, parent_type_c, type_c, position} = sourceItem

    let prevPosition = (sourceItemSiblings[sourceItemIndex - 1] && sourceItemSiblings[sourceItemIndex - 1].position) || null;
    let nextPosition = (sourceItemSiblings[sourceItemIndex + 1] && sourceItemSiblings[sourceItemIndex + 1].position) || null;
  
    console.log("addItem updated positions ===>", { prevPosition, nextPosition });

    let newItem
    if(addPosition == "before"){
      newItem = createNewItem(parent_id, parent_type_c, type_c,  prevPosition, position);
    }else if(addPosition == "after"){ 
      newItem = createNewItem(parent_id, parent_type_c, type_c, position, nextPosition);
    }
    console.log("addItem newItem", newItem);
  
    dispatch(
      tagsActions.create(
        {
          tag: newItem,
        },
        {
          success: {
            showMessage: false,
          },
          successCallback: (data) => {
            try {
              console.log("tag successCallback data", data);
              newItem.id = data.data.tag.data.attributes.id;
              newItem.uuid = data.data.tag.data.attributes.uuid;
              // setItems((prevItems) => [...prevItems, newItem]);
              let updatedFinalJson = tags.concat(newItem)
              handleSubmit(updatedFinalJson)
            } catch (error) {
              message.error("error in adding item");
              console.log("addItem error ===>", error);
            }
          },
          errorCallback: ()=>{
            message.error( "Error in updating tags")
            setLoading(false)
          }
        }
      )
    );
  };
  const handleAddNewItem = () => {
    setLoading(true)
    
    let newItem = createNewItem(tagId)
    dispatch(
      tagsActions.create(
        {
          tag: newItem,
        },
        {
          success: {
            showMessage: false,
          },
          successCallback: (data) => {
            try {
              console.log("tag successCallback data", data);
              newItem.id = data.data.tag.data.attributes.id;
              newItem.uuid = data.data.tag.data.attributes.uuid;
              // setItems((prevItems) => [...prevItems, newItem]);
              let updatedFinalJson = tags.concat(newItem)
              handleSubmit(updatedFinalJson)
            } catch (error) {
              message.error("error in adding new item");
              console.log("handleAddNewItem error ===>", error);
            }
          },
          errorCallback: ()=>{
            message.error( "Error in updating tags")
            setLoading(false)
          }
        }
      )
    );
  };

  const deleteItem = (sourceItem, itemSiblingsCount) => {
    console.log("deleteItem data", {sourceItem, itemSiblingsCount});
    
    setLoading(true)
    dispatch(
      tagsActions.delete(
        {
          id: sourceItem.id,
        },
        {
          success: {
            showMessage: false,
          },
          successCallback: (response) => {
            try {
              console.log("deleteItem successCallback", response);
              let updatedFinalJson = tags.filter(
                (listItem) => listItem.id != sourceItem.id
              );
              if(itemSiblingsCount == 1){
                dispatch(
                  tagsActions.update(
                    { id: sourceItem.parent_id, has_children: false },
                    {
                      success: {
                        showMessage: false,
                      },
                      successCallback: (data) => {
                        console.log("deleteItem has_children successCallback", data);
                        console.log("deleteItem has_children successCallback", data);
                        let hasChildren = 
                          data.data.tag.data.attributes.has_children;
                        updatedFinalJson = updatedFinalJson.map((listItem) => {
                          if (listItem.id === sourceItem.parent_id) {
                            return { ...listItem, has_children: hasChildren }; 
                          }
                          return listItem;
                        });
                        console.log("deleteItem has_children successCallback", data);
                        handleSubmit(updatedFinalJson)
  
                      },
                      errorCallback: ()=>{
                        message.error( "Error in updating tags")
                        setLoading(false)
                      }
                    }
                  )
                );
              } else {
                handleSubmit(updatedFinalJson)
              }
            } catch (error) {
              message.error("error in deleting item");
              console.log("deleteItem error ===>", error);
            }
          },
          errorCallback: ()=>{
            message.error( "Error in updating tags")
            setLoading(false)
          }
        }
      )
    );
  };

  const updateItemDetail = (id, updateObject) => {
    console.log("updateItemDetail ===>", { id, updateObject });
    setLoading(true)
    dispatch(
      tagsActions.update(
        { id: id, ...updateObject },
        {
          success: {
            showMessage: false,
          },
          successCallback: (data) => {
            try {
              console.log("updateItemDetail ===> success", data, tags);
              let updatedFinalJson = tags.map((listItem) => {
                if (listItem.id === id) {
                  let newListItem = { ...listItem };
                  let updateArray = Object.entries(updateObject) || []
                  updateArray.forEach(([key, value]) => {
                    if (data.data.tag.data.attributes.hasOwnProperty(key)) {
                      newListItem[key] = data.data.tag.data.attributes[key];
                    }
                  });
                  return newListItem;
                }
                return listItem;
              });
              console.log(
                "updateItemDetail ===> updated finaljson at updateItemDetail",
                updatedFinalJson
              );
              handleSubmit(updatedFinalJson);
            } catch (error) {
              message.error("error in updating item");
             console.log("updateItemDetail error ===>", error); 
            }
          },
          errorCallback: () => {
            message.error("updateItemDetail ===> Error in updating tags");
          },
        }
      )
    );
  };

  const handleSubmit = (updatedFinalJson) => {
    updateResource(
      {
        id: tagId,
        final_json: {data: updatedFinalJson},
      },
      {
        success: {
          showMessage: false,
        },
        successCallback: data => {
          try {
            console.log("submit success", data, loading);
            setLoading(false)
            setTags(updatedFinalJson)
            message.success("Saved")
          } catch (error) {
            message.error("error in submit");
            console.log("handleSubmit error ===>", error);
          }
        },
        errorCallback: ()=>{
          message.error( "Error in updating tags")
          setLoading(false)
        }
      }
    );
  };

  // const updateNestingLevel = (value) => {
  //   console.log("updateNestingLevel===>", value);
  //   updateResource(
  //     {
  //       id: tagId,
  //       custom_fields: {
  //         max_nesting_level_for_tagging: value
  //       }
  //     },
  //     {
  //       success: {
  //         showMessage: false,
  //       },
  //       successCallback: data => {
  //         console.log("nesting level upadted", data);
  //       },
  //       errorCallback: ()=>{
  //         message.error("Error in updating nesting level")
  //       }
  //     }
  //   )
  // }


  return (
    <Space direction='vertical'>
      <Row>
        <Col span={24}>
          <Space direction='vertical'>
            <h3>Content area: {item.name} ({item.uuid})</h3>
            <b>Is Atlas synced: {JSON.stringify(isAtlasSynced)}</b>
            <br></br>
            {/* <Select
              value={item.custom_fields && item.custom_fields.max_nesting_level_for_tagging}
              onChange={(value)=>updateNestingLevel(value)}        
              placeholder={"Select max nesting level for tagging"}  
            >
              {nestingInputOptions.map((number) => 
                <Option value={number}>{number}</Option> 
              )}
            </Select> */}
          </Space>
        </Col>
      </Row>
      <Filters
        setGrade={setGrade}
        gradeId={grade.value}
        programme_id={programme_id}
        contentAreaId={item.id}
        gradeGroups={item.grade_groups}
      />
      
      {//filteredTagsLength == 0 && <Button onClick={() => addNewItem()}>+ Add</Button>
      }
      {/* <FinalJsonContext.Provider value = {tags}> */}
      <DraggableList
        items={filteredTags}
        // setItems={setTags}
        // canSubmit = {canSubmit}
        // setCanSubmit = {(value)=>setCanSubmit(value)}
        // updateResource = {updateResource}
        handleSubmit = {handleSubmit}
        loading = {loading}
        // createItem = {createItem}
        // createChildren = {createChildren}
        handleDropAction = {handleDropAction}
        handleDragItemAndDrop = {handleDragItemAndDrop}
        addItem = {addItem}
        addChildrenItem = {addChildrenItem}
        deleteItem = {deleteItem}
        handleAddNewItem = {handleAddNewItem}
        updateItemDetail={updateItemDetail}
        showDetailAddons={true}
        isEditable={!isAtlasSynced}
      />
      {/* </FinalJsonContext.Provider> */}
      {loading && <LoadingCanvas/>}
    </Space>
  );
}

export default TagItemDetail


// const FinalJsonContext = createContext()
  // let filteredTags
  // const otherLeftOutItems = [] 
  


  // const [filteredTags, setFilteredTags] = useState([]);
  // const [leftOutItems, setLeftOutItems] = useState([]);
  // const [loading, setLoading] = useState(false)
  // const [updateWithFilter, setUpdateWithFilter]= useState(false)
  

  // console.log("TagItemDetail tags", tags);

  // useEffect(() => {   
  //   console.log("useEffect logs", grade.label, );
  //   let filteredList = findStandardsByGrade(tags, grade.label);
  //   let otherItems = _.difference(tags,filteredList)
  //   let nestedStructure  = convertToNestedList(filteredList)
  //   let sortedNestedStructure = sortListByPosition(nestedStructure);
  //   setLeftOutItems((prevState)=>[...prevState, ...otherItems, ...otherLeftOutItems])
  //   setFilteredTags(sortedNestedStructure);
  //   console.log("eaqmsd useEffect filteredTags", filteredTags);
  //   console.log("leftOutItems", otherItems);
  //   console.log("eaqmsd useEffect sortedNestedStructure", sortedNestedStructure);
  // }, [grade.value, updateWithFilter]);

  // console.log("eaqmsd TagItemDetail filteredTags", filteredTags);

  

  

  // const flattenWithChildren = (item) => {
  //   if (item.children) {
  //     const children = _.flatMap(item.children, flattenWithChildren);
  //     delete item.children;
  //     return [item, ...children];
  //   }
  //   return item;
  // };

  // const recursivelyFlatten = (array) => {
  //   return _.flatMap(array, flattenWithChildren);
  // };
