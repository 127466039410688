import React, { useEffect, useState} from "react";
import { Button, Modal, Space, Tag, Tooltip, Upload } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import axios from "axios";
import { HiOutlineStop } from "react-icons/hi";
import { CameraOutlined } from "@ant-design/icons";
import { checkMob, getPermissions } from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { showConfirmModal, showInfoModal } from "../Segment/UIHelper";
import ThumbnailAttachments from "/src/components/UI/Attachments/ThumbnailAttachments/ThumbnailAttachments"
import PreviewAttachment from "../Attachments/PreviewAttachment";
import Spinner from "../Spinner/Spinner";
import { useDispatch } from "react-redux";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import "./ImageCaptureUploadModal.scss"

const ImageCaptureUploadModal = (props) => {
  const {
    attachments,
    mediaFormSetting,
    setMediaFormSetting,
    video = true,
  } = props;
  const mrIntl = useTranslate();
  const dispatch = useDispatch();
  
  const notDeletableFiles = mediaFormSetting.selectConfig.notDeletableFiles || [];

  const [stream, setStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState({});
  const mediaRecordConstraints = {
    audio: false,
    video: true,
  };
  const [recorder, setRecorder] = useState({});
  const [fileSetting, setFileSetting] = useState({});
  
  useEffect(() => {
    if (recorder.state) {
      setMediaFormSetting({
        ...mediaFormSetting,
        okDisabled: recorder.state !== "stopped",
      });
    }
    console.log("recorder state changed ==>", recorder, recorder.state);
    return () => {
      stopStream()
    }
  }, [recorder.state]);

  const [loading, setLoading] = useState(false);
  const [askPermission, setAskPermission] = useState(false);

  const initMediaRecorder = (stream) => {
    console.log("come in stream=====>");
    setAskPermission(false)
    setLoading(true);
    setMediaFormSetting((mediaFormSetting) => {
      return {
        ...mediaFormSetting,
        video: true,
        visible: true,
      };
    });
    setStream(stream);
    setTimeout(() => {
      const streamContainer = document.querySelector("#stream_video");
      if (streamContainer) {
        console.log("streamContainer  ===>", streamContainer);
        streamContainer.style.display = "block";
        streamContainer.srcObject = stream;
        streamContainer.captureStream =
          streamContainer.captureStream || streamContainer.mozCaptureStream;
        setLoading(false);
        setRecorder({
          ...recorder,
          state: "recording", //mediaRecorder.state,
        });
      }
    }, 200);
  };

  const restartCapture = () => {
      startRecording()    
  };

  const startRecording = () => {
    getPermissions(mediaRecordConstraints, {
      successCallback: initMediaRecorder,
      errorCallback: errorNotSupported,
    });
  };

  const setSkipFocusLostModal = (value) => {
    console.log("setSkipFocusLostModal ==>", value)
    dispatch(
      experienceReduxActions.setSkipFocusLostModalSuccess({
        skipFocusLostModal: value,
      })
    );
  };

  const captureImage = () => {
    let streamContainer = document.getElementById("stream_video");
    if (streamContainer) {
      const canvas = document.getElementById("canvas");
      var context = canvas.getContext("2d");
      context.drawImage(streamContainer, 0, 0, 640, 480);
      streamContainer.style.display = "none";
      streamContainer.srcObject = null;
      streamContainer.muted = true;
      setRecorder({
        ...recorder,
        saved: false,
        state: "stopped", //mediaRecorder.state,
      });
    }
  };

  const errorNotSupported = () => {
    setAskPermission(false)
    console.log("Recording not supported");
    if (!mediaRecorder.startRecording) {
      showInfoModal({
        type: "warning",
        title: mrIntl("ImageCaptureUploadModal.please_allow_camera"),
        key:'allow-camera-warning'
      });
      return;
    }
  };

  const stopStream = () =>{
    if(stream){
      stream.getTracks().forEach(track => track.stop())
      setStream(null)
    }
  }

  const handleOk = () => {
    console.log("On save media ==>", recorder);
    if (!mediaRecorder) {
      return;
    }

    stopStream()

    setRecorder({
      ...recorder,
      saved: true,
    });


    setMediaFormSetting({
      ...mediaFormSetting,
      okDisabled:true,
      loading: true
    });

    const formData = new FormData();
    const canvas = document.getElementById("canvas");
    const imgData = canvas.toDataURL();
    formData.append("url", imgData);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        token: token,
      },
    };
    const url =
      (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "attachments?" + mediaFormSetting.extraParams;
      axios.post(url, formData, config).then(
      (response) => {
        let responseData = response.data;
        const newAttachments = [...attachments];
        newAttachments.push(responseData);
        if (mediaFormSetting.onChangeSaveFiles) {
          mediaFormSetting.onChangeSaveFiles(newAttachments);
        }
        resetState();

        setMediaFormSetting({
          ...mediaFormSetting,
          from: "",
          modal: false,
          visible: false,
          loading: false,
          triggerSaveAction: 0,
        });

      },
      (error) => {
        message.error(mrIntl("MediaRecorder.something_went_wrong"));
        setMediaFormSetting({
          ...mediaFormSetting,
          okDisabled: false,
          visible: false,
        });
      }
    );
  };

  const handleCancel = () => {
    setRecorder({});
    stopStream()
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "",
      visible: false,
    });
    resetState();
  };

  const showModal = () => {
    //askPermission to fix multiple time show the info modal when user multi click when browser ask permission modal open
    if (!askPermission) {
      setAskPermission(true);
      setSkipFocusLostModal(true);
      setTimeout(() => {
        setSkipFocusLostModal(false);
      }, 2000);
      startRecording();
    }
  };

  const resetState = () => {
    setRecorder({});
  };

  const onRemove = (file) => {
    if (notDeletableFiles.find((f) => f.uid === file.uid)) {
      showInfoModal({
        type: "warning",
        title:
          "This file cannot be deleted now because it is already submitted",
        content: mediaFormSetting.deleteNotAllowedMessage,
      });
      return false;
    } else {
      return new Promise((removeResolve) => {
        showConfirmModal({
          // okButtonProps: { loading: true },
          centered: true,
          title: mrIntl("CommonText.confirm_delete"),
          onOk: (close) => {
            removeResolve(true);
            return new Promise((okResolve) => {
              console.log("on remove ==>", file);

              if (mediaFormSetting.onRemoveFile) {
                mediaFormSetting.onRemoveFile(file);
              }

              okResolve(close);
            });
          },
          onCancel: () => {
            removeResolve(false);
          },
        });
      });
    }
  };

  const onPreview = async (file) => {
    const fileResponse = file.response;
    if (fileResponse && fileResponse.status === "done") {
      file = fileResponse;
    }
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setFileSetting({
      ...fileSetting,
      previewFile: {
        ...file,
        url: src,
      },
    });
  };

  
  const getForm = () => {
    const showCanvas = !["recording", "paused"].includes(recorder.state);
    return (
      <div>
        <div className="recorder-form">
          <Space direction="vertical">
            <Space>
              {recorder.state === "stopped" && (
                <Button
                  onClick={(e) => restartCapture()}
                  size="large"
                  shape="round"
                  type="primary"
                  className="m-b-10"
                  ghost={true}
                  icon={<CameraOutlined />}
                >
                  {mrIntl("ImageCaptureUploadModal.retake_picture")}
                </Button>
              )}
              {(recorder.state == "recording" ||
                recorder.state == "paused") && (
                <Button
                  onClick={(e) => captureImage()}
                  size="large"
                  shape="round"
                  className="m-b-10 capture-icon"
                  icon={<HiOutlineStop />}
                >
                  {mrIntl("ImageCaptureUploadModal.take_a_picture")}
                </Button>
              )}
            </Space>
          </Space>
        </div>

        <div className="recorder-preview">
          {loading && <Spinner />}
          <video
            className="video-input"
            id="stream_video"
            autoplay="autoplay"
            disablePictureInPicture
            style={{
              visibility:
                recorder.state == "recording" || recorder.state == "paused"
                  ? "visible"
                  : "hidden",
              height: "360", //recorder.state ? "360px" : "60px",
            }}
          />
          <canvas
            id="canvas"
            width="640"
            height="480"
            style={{ display: showCanvas ? "block" : "none" }}
          ></canvas>
        </div>
      </div>
    );
  };

  const getFooter = () => {
    return [
      <Button
        key="back"
        onClick={(e) => handleCancel()}
        disabled={mediaFormSetting.okDisabled && mediaFormSetting.loading}
      >
        {mediaFormSetting.cancelText || "Return"}
      </Button>,
      <Button
        key="submit"
        type="primary"
        onClick={(e) => handleOk()}
        loading={mediaFormSetting.okDisabled && mediaFormSetting.loading}
        disabled={mediaFormSetting.okDisabled}
      >
        {mediaFormSetting.okText || mrIntl("CommonText.submit")}
      </Button>,
    ];
  };

  const showMaxmLimitReachedMessage = () => {
    message.error(mediaFormSetting.limitMessage || "Max upload limit reached");
  };

  let finalRender = [],
  uploadComponent = null;

  const limit = mediaFormSetting.limit;
  let maxLimitReached = attachments.length >= limit;

  uploadComponent = 
    mediaFormSetting.imageCaptureConfig.showImageCaptureUploader && (
    <Tooltip
      title={mediaFormSetting.imageCaptureConfig.disabledUploaderTooltip}
    >
      <span
        className="image-upload-centered-element-container"
        style={{ width: "100%", height: "100%"}}
        onClick={(e) => !maxLimitReached ? showModal() : showMaxmLimitReachedMessage()}
      >
        <CameraOutlined />
        <div style={{ marginTop: "8px" }}> Capture </div>
      </span>
    </Tooltip>
  );
  finalRender = [
    <ThumbnailAttachments
      attachments={attachments}
      listProps={{
        showDownloadIcon: false,
        listType: "picture-card",
        onPreview: onPreview,
        onRemove: onRemove,
        openFileDialogOnClick: false,
      }}
      componentAfterThumbnails={uploadComponent}
    />,
  ];

  finalRender.push(
    <PreviewAttachment
      type={"modal"}
      visible={fileSetting.previewFile}
      file={fileSetting.previewFile}
      onCancel={() =>
        setFileSetting({
          ...fileSetting,
          previewFile: null,
        })
      }
    />
  );

  if (props.modal && mediaFormSetting.visible) {
    finalRender.push(
      <Modal
        {...mediaFormSetting}
        width={checkMob() ? "" : "50%"}
        centered={checkMob() ? true : false}
        className={checkMob() ? "height-centered-modal" : ""}
        open={mediaFormSetting.visible}
        closable={false}
        maskClosable={false}
        keyboard={false}
        title={mediaFormSetting.title || "Capture Image"}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={getFooter()}
        style={{ background: "white", paddingBottom: "0px" }}
        bodyStyle={{ overflow: "hidden" }}
        wrapClassName="capture-upload-modal"
        loading={false}
      >
        <div className="media-recorder-form">{getForm()}</div>
      </Modal>
    );
  }

  return finalRender;
};
export default ImageCaptureUploadModal;
