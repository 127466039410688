import React from 'react';

export function SpeedIcon() {
  return (

    <svg width="20px" height="16px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Icons/Content/Video Speed/White</title>
      <defs>
        <rect id="path-1222" x="0" y="0" width="20" height="20"></rect>
      </defs>
      <g id="V7.1---Media-(Video-Exceptions---Audio,-Full-Screen-Mode,-Descriptions)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="1a---Mobile-P---V---Unmuted" transform="translate(-20.000000, -228.000000)">
          <g id="CC" transform="translate(0.000000, 36.000000)">
            <g id="Video" transform="translate(0.000000, 176.000000)">
              <g id="Speed" transform="translate(20.000000, 13.000000)">
                <g id="Speed" transform="translate(0.000000, 1.000000)">
                  <mask id="mask-222" fill="white">
                    <use href="#path-1222"></use>
                  </mask>
                  <g id="Placeholder"></g>
                  <g id="Icon" mask="url(#mask-222)">
                    <g transform="translate(-2.837034, -1.000000)">
                      <path d="M14.9895384,21.6778607 C18.8539525,20.6669323 21.6756388,17.1813259 21.6756388,13 C21.6756388,8.02943725 17.6462015,4 12.6756388,4 C8.49214375,4 5.00885255,6.82085235 4,10.6883222" id="Oval" stroke="#FFFFFF" strokeWidth="1.8" fill="none" strokeLinecap="round" transform="translate(12.837819, 12.838930) rotate(-45.000000) translate(-12.837819, -12.838930) "></path>
                      <line x1="12.8378194" y1="14.5675028" x2="16.0240953" y2="9.7179593" id="Line" stroke="#FFFFFF" strokeWidth="1.8" fill="none" strokeLinecap="round"></line>
                      <circle id="Oval" stroke="none" fill="#FFFFFF" fillRule="evenodd" cx="12.8370338" cy="14.5675028" r="2"></circle>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function FullscreenIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1L19 1V7" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M19 13V19H13" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M7 19H1L1 13" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M1 7V1H7" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
    </svg>
  );
}

export function VolumeIcon() {
  return (
    <svg className="volume-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0004 9.79158C15.0004 7.83048 13.8671 6.136 12.2227 5.31934V14.2693C13.8671 13.4471 15.0004 11.7527 15.0004 9.79158Z" fill="white" />
      <path d="M1 6.45816C0.447715 6.45816 0 6.90588 0 7.45816V12.1248C0 12.6771 0.447715 13.1248 1 13.1248H4.44443L10 18.6804V0.902588L4.44443 6.45816H1Z" fill="white" />
      <path d="M12.2227 0.0471191V2.34155C15.4337 3.29712 17.7782 6.26931 17.7782 9.79155C17.7782 13.3138 15.4337 16.286 12.2227 17.2415V19.536C16.6727 18.5249 20.0004 14.5526 20.0004 9.79155C20.0004 5.03045 16.6727 1.05821 12.2227 0.0471191Z" fill="white" />
    </svg>
  );
}
export function PlayIcon() {
  return (
    <svg className="play-icon" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 22C12 27.5228 16.4772 32 22 32C27.5228 32 32 27.5228 32 22C32 16.4772 27.5228 12 22 12C16.4772 12 12 16.4772 12 22ZM19.765 26.5219L26.3216 22.424C26.6349 22.2282 26.6349 21.7718 26.3216 21.576L19.765 17.4781C19.432 17.27 19 17.5094 19 17.9021V26.0979C19 26.4906 19.432 26.73 19.765 26.5219Z" fill="white" />
    </svg>
  );
}



export function ExitFullScreenIcon() {
  return (
    <svg className="exit-full-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 7H13V1" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M13 19L13 13L19 13" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M1 13L7 13L7 19" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M7 1L7 7L1 7" stroke="#FFFFFF" strokeWidth="1.8" strokeLinecap="round" />
    </svg>
  );
}
export function MuteIcon() {
  return (
    <svg className="mute-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0001 10.0001C15.0001 8.03898 13.8667 6.3445 12.2223 5.52783V7.9834L14.9501 10.7112C14.9834 10.4778 15.0001 10.239 15.0001 10.0001Z" fill="#FFFFFF" />
      <path d="M17.7778 10C17.7778 11.0445 17.5501 12.0278 17.1778 12.9334L18.8611 14.6167C19.5834 13.2334 20.0001 11.6667 20.0001 10C20.0001 5.24447 16.6723 1.26671 12.2223 0.255615V2.55004C15.4334 3.50562 17.7778 6.4778 17.7778 10Z" fill="#FFFFFF" />
      <path d="M9.99997 1.11108L7.67773 3.43332L9.99997 5.75556V1.11108Z" fill="#FFFFFF" />
      <path d="M2.12377 0.707106C1.73325 0.316582 1.09886 0.317809 0.708333 0.708333C0.317809 1.09886 0.316583 1.73325 0.707107 2.12377L5.25 6.66667H1C0.447715 6.66667 0 7.11438 0 7.66667V12.3333C0 12.8856 0.447715 13.3333 1 13.3333H4.44443L10 18.8889V11.4167L14.7278 16.1444C13.9833 16.7167 13.1444 17.1778 12.2222 17.4555V19.75C13.7499 19.4 15.1444 18.7 16.3166 17.7389L17.8762 19.2947C18.2669 19.6843 18.901 19.6823 19.2912 19.2921C19.6818 18.9016 19.6834 18.2668 19.2929 17.8762L10 8.58333L2.12377 0.707106Z" fill="#FFFFFF" />
    </svg>
  );
}
export function PauseIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M-7.10542736e-15,10 C-7.10542736e-15,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,1.01453063e-15 10,0 C4.4771525,-1.01453063e-15 -3.55271368e-15,4.4771525 -7.10542736e-15,10 Z M8,5 C8.55228475,5 9,5.44771525 9,6 L9,14 C9,14.5522847 8.55228475,15 8,15 L7,15 C6.44771525,15 6,14.5522847 6,14 L6,6 C6,5.44771525 6.44771525,5 7,5 L8,5 Z M13,5 C13.5522847,5 14,5.44771525 14,6 L14,14 C14,14.5522847 13.5522847,15 13,15 L12,15 C11.4477153,15 11,14.5522847 11,14 L11,6 C11,5.44771525 11.4477153,5 12,5 L13,5 Z" id="Combined-Shape" fill="white"></path>
    </svg>
  );
}

export function RestartIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle r="8" transform="matrix(1 0 0 -1 9.86963 10.4087)" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.29905 2.30964C6.13418 2.41161 6.13418 2.65141 6.29905 2.75337L9.78972 4.91221C10.0214 5.05552 10.3205 4.88885 10.3205 4.61639V0.446621C10.3205 0.174162 10.0214 0.0074876 9.78972 0.150799L6.29905 2.30964Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.032 9.68798C13.2546 9.83641 13.2546 10.1636 13.032 10.312L8.08301 13.6113C7.8338 13.7775 7.5 13.5988 7.5 13.2993L7.5 6.70069C7.5 6.40118 7.8338 6.22254 8.08301 6.38868L13.032 9.68798Z" fill="white" />
    </svg>
  );
}
