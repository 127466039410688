import React, { useEffect, useState } from "react";
// import "./GroupItemDetail.scss";
import queryString from "query-string";
import { Route, useRouteMatch, useLocation } from "react-router-dom";
import { Row, Col, Alert, Space, Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ExperienceUsers from "/src/views/ExperienceUsers/ExperienceUsers";
import { checkMob } from "/src/lib/utils/helperMethods";
import { experienceUsersCountSelector } from "/src/views/ExperienceUsers/selector";
// import ExportModal from "/src/views/Experiences/ExperienceShow/Components/ExportModal";
// import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import {
  currentUserSelector,
  enabledFeaturesSelector,
  getEmbeddedSelector,
} from "/src/views/Auth/Login/selector";
import { activeChildIdSelector } from "/src/views/Users/selector";
import { getFirstQuestion, getMBTaskUrl, getRecursiveSegments } from "/src/views/Experiences/ExperienceHelperMethods";
import ExperienceUserGrading from "/src/views/ExperienceUsers/ExperienceUserItemDetail/ExperienceUserGrading";
import { actions as topicActions } from "/src/views/Segments/Topics/redux";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { actions as userResponseReduxActions } from "/src/views/UserResponses/redux";
import { segmentsSelector } from "/src/views/Segments/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ExperienceGrade = (props) => {
  console.log("ExperienceGrade props", props);

  const {
    experienceProps,
    mbGradeBookEmbeddedView,
    isGradingEnabled,
    exportModalConfig = {},
    setExportModalConfig,
  } = props;
  const { experience } = experienceProps;
  const mrIntl = useTranslate()
  // const item = experience;
  // const {path, url} = useRouteMatch();
  const match = useRouteMatch();
  const dispatch = useDispatch() 
  const { path, url } = match;
  const location = useLocation();
  const locationQueryParams = queryString.parse(location.search);
  console.log("experienceDetail path", path, url, match, location);
  const experienceSettings = experience.settings || {};
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const currentUser = useSelector(currentUserSelector());
  const isStudent = currentUser.role === "student" || currentUser.role === "parent";
  let experienceUser = experience.experience_user;
  console.log("experience and experience user ==>", experience, experienceUser)
  const rubricType = experience.rubric && experience.rubric.type_c
  let isMYPAchievementLevel =
    rubricType === "myp_achievement_level"
      ? true
      : false;
  let defaultGradingConfig = {
    gradingMode: "quick", // default one, or "annotate"
    // gradingMode: "annotate", // default one, or "annotate"
    gradingEnabled: isGradingEnabled, // false for student, true for teacher
    anonymousGradingEnabled:
      enabledFeatures.anonymous_grading &&
      experienceSettings.anonymous_grading_enabled,
    showAllMarks: true,
    assignReviewersEnabled: !isStudent && locationQueryParams.assign_peers, // Enable button to assign reviewers using params
  };
  const [gradingConfig, setGradingConfig] = useState(defaultGradingConfig);
  let gradingEnabled = gradingConfig.gradingEnabled;
  const experienceUsersCount = useSelector(experienceUsersCountSelector());
  console.log("experienceUsersCount", experienceUsersCount);

  // const gradingEnabled = experienceViewMode == "apStudentFeedback" ? false : true
  const embedded = useSelector(getEmbeddedSelector());
  const activeChildId = useSelector(activeChildIdSelector());
  let segments = useSelector(segmentsSelector());
  const firstTopic = (experience.topics && experience.topics[0] && experience.topics[0].segment) || {}

  const experienceUserId = experienceUser && experienceUser.id;
  
  const isStudentReviewerMode = experience.student_reviewer_mode
  // const firstQuestion = segments.find((segment) => (firstTopic.id == segment.parent_id) && segment.segment_type == "question") || {}
  // let embedded = false
  // if(props.embedded){
  //   embedded = props.embedded
  // }
  
  useEffect(() => {
    console.log("experience.experience_user ==>", experience.experience_user);
    let firstQuestion = getFirstQuestion(experience.topics, segments)
    let experienceUserIdFromUrl = parseInt(location.pathname.split("/grade/")[1]);
    if (locationQueryParams && locationQueryParams.review_by_id) {
      experienceUserIdFromUrl = parseInt(locationQueryParams.review_by_id);
    }
    console.log("experienceUserIdFromUrl ==>", experienceUserIdFromUrl);
    if(mbGradeBookEmbeddedView){
      if(experienceUserIdFromUrl) {
        dispatch(
          experienceUsersActions.setActiveExperienceUserIdSuccess({
            id: experienceUserIdFromUrl,
          })
        );
        dispatch(
          topicActions.setActiveIdSuccess({
            id: firstTopic.id,
          })
        );
        dispatch(
          segmentActions.setActiveIdSuccess({
            id: "all",
          })
        );
        dispatch(userResponseReduxActions.summary({ experienceId: experience.id, experienceUserId: experienceUserIdFromUrl}));
      }
    } else if(experienceUser && !experienceUserIdFromUrl) {
      dispatch(
        experienceUsersActions.setActiveExperienceUserIdSuccess({
          id: experienceUserId,
        })
      );
      dispatch(
        topicActions.setActiveIdSuccess({
          id: firstTopic.id,
        })
      );
      dispatch(
        segmentActions.setActiveIdSuccess({
          id: "all",
        })
      );
      dispatch(userResponseReduxActions.summary({ experienceId: experience.id, userId:  experienceUser.user_id}));
    } else {
      // dispatch(userResponseReduxActions.summary({ experienceId: experience.id }));

      if(experienceUserIdFromUrl) {
        dispatch(
          experienceUsersActions.setActiveExperienceUserIdSuccess({
            id: experienceUserIdFromUrl
          })
        );
        dispatch(topicActions.setActiveIdSuccess({ id: firstQuestion.parent_id }));
        dispatch(segmentActions.setActiveIdSuccess({ id: firstQuestion.id }));
        dispatch(experienceUsersActions.setGradingModalVisibleSuccess({visible: true}));
      }
    }

    return () => {
      if (rubricType === "myp_achievement_level" || rubricType === "criteria_with_points") {
        dispatch(
          experienceUsersActions.setCurrentCriteriaIdSuccess({
            currentCriteriaId: null,
          })
        );
      }
    }
  }, [])

  let params = { by_experience_id: experience.id, with_annotations: true };
  let config = {
    routes: { show: false },
    filter: {
      show: true,
      // config: {
      // search: {show: false},
      // search: {show: gradingConfig.anonymousGradingEnabled},
      // }
    },
  }; // temp hiding filters

  console.log("gradingEnabled", gradingEnabled);
  // if (!gradingEnabled) {
  //   // TODO: in case of parent, get activeChildId from selector - should be set. if not set, send back to home page. if set use that id below to get eu instead of currentUser.id
  //   let student_id = currentUser.id; // default for student
  //   if (currentUser.role === "parent") {
  //     student_id = activeChildId;
  //   }
  //   params = { by_experience_id: experience.id, by_user_id: student_id };


  let showSupportModificationWarning = currentUser.role === "support";

  let alertsRender = <Row className="m-b-15">
    <Col span={22} offset={1}>
      {showSupportModificationWarning && (
        <Alert
          message={mrIntl("ExperienceGrade.only_admin_can_perform_any_action_info_msg")}
          type="error"
          showIcon
          className="m-b-15"
        />
      )}
    </Col>
  </Row>

  let finalRender = null

  if(mbGradeBookEmbeddedView) { // MB embedded case
    finalRender = (
      <Route
        key={"experience-user-embedded"}
        path={`${path}/:id`}
        render={(routeProps) => (
          <ExperienceUsers
            params={params}
            createUpdateParams={{ experience_id: experience.id }}
            gradingConfig={gradingConfig}
            setGradingConfig={setGradingConfig}
            experience={experience}
            exportModalConfig={exportModalConfig}
            setExportModalConfig={setExportModalConfig}
            mbGradeBookEmbeddedView={mbGradeBookEmbeddedView}
            embedded={embedded}
            isRoute={false}
            config={{
              routes: { fetch: false },
              itemDetail: { widget: ExperienceUserGrading },
            }}
            {...routeProps}
          />
        )}
      />
    )
  } else if(experienceUser && !(experienceSettings.peer_review_enabled && isStudentReviewerMode)) { // Student case
    finalRender = (
      <Route
        key={"experience-user-result"}
        path={`${path}`}
        render={(routeProps) => (
          <ExperienceUsers
            params={params}
            createUpdateParams={{ experience_id: experience.id }}
            gradingConfig={gradingConfig}
            setGradingConfig={setGradingConfig}
            experience={experience}
            exportModalConfig={exportModalConfig}
            setExportModalConfig={setExportModalConfig}
            embedded={embedded}
            resourceId={experienceUserId}
            isRoute={false}
            config={{
              routes: { fetch: false },
              itemDetail: { widget: ExperienceUserGrading },
            }}
            {...routeProps}
          />
        )}
      />
    )
  } else if (
    (currentUser.role !== "student" && currentUser.role !== "parent") ||
    (experienceSettings.peer_review_enabled && isStudentReviewerMode)
    ){ // default teacher case
    params = {
      ...params,
      with_submissions_count: true
    }
    finalRender = (
      <>
        {(showSupportModificationWarning) && alertsRender}
        <Route
          key={"experience-users-list"}
          path={`${path}`}
          render={(routeProps) => (
            <ExperienceUsers
              params={{...params, with_first_10_eus_user_responses: true}}
              createUpdateParams={{ experience_id: experience.id }}
              gradingConfig={gradingConfig}
              setGradingConfig={setGradingConfig}
              experience={experience}
              exportModalConfig={exportModalConfig}
              setExportModalConfig={setExportModalConfig}
              embedded={embedded}
              config={config}
              isRoute={false}
              {...routeProps}
            />
          )}
        />
      </>
    )
  }

  return (
    <React.Fragment>
      <Row style={{ marginTop: "15px", width: "100%" }}>
        <Col span={24} className={mbGradeBookEmbeddedView || experienceUser ? "single-submission-view" : ""}>
          {finalRender}
        </Col>
      </Row>
    </React.Fragment>
  );
};

ExperienceGrade.defaultProps = {};

ExperienceGrade.propTypes = {};

export default ExperienceGrade;
