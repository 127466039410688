import React, { useEffect } from "react";

import DefaultCKE from "/src/components/UI/CKEditor/DefaultCKE"
import { CKETextCaptionTools } from "/src/components/UI/CKEditor/CKEConfig"
import { InputArea } from '/src/components/UI/Segment/UIHelper'
import MediaShow from "/src/components/UI/Media/MediaShow"

const CKETextCaption = (props) => {
  console.log("CKE Text Caption props ==>", props);

  const onTextChanged = (data) => {
    props.onChange(data)
  }

  return (
    <InputArea
      className="singleline-input-area"
    >
      <DefaultCKE
        tools={CKETextCaptionTools}
        data={props.value}
        onTextChanged={(data) => onTextChanged(data)}
      >
      </DefaultCKE>
      {props.files && <MediaShow
        file={props.files && props.files[0]}
        removeFile={props.removeFile}
      >
      </MediaShow>}
    </InputArea>
  );
};
export default CKETextCaption;