import React from "react";
import { useHistory } from "react-router-dom";
import { Space } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const MaintenancePage = (props) => {
  let history = useHistory();
  let mrIntl = useTranslate();

  const switchToApp = () => {
    history.push("/");
  };

  if (import.meta.env.VITE_MAINTENANCE_MODE != "true") {
    switchToApp();
  }
  return (
    <Space className="maintenance-mode-info-box" direction="vertical">
      <h2>{mrIntl("MaintenancePage.well_be_back_soon")}</h2>
      <SettingOutlined spin />
      <p className="m-t-20">
        {mrIntl("MaintenancePage.assessprep_is_undergoing_routine_maintenance")}
      </p>
      <p>
        {mrIntl("MaintenancePage.from_time1_to_time2", {
          time1: import.meta.env.VITE_MAINTENANCE_MODE_START_TIME,
          time2: import.meta.env.VITE_MAINTENANCE_MODE_END_TIME,
        })}
      </p>
    </Space>
  );
};

export default MaintenancePage;
