import React, {
  useEffect,
  useState,
  Suspense,
  useRef,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import "react-datasheet/lib/react-datasheet.css";
import "./RDSTable.css";
import { Space, Checkbox, Button } from "antd";
// import ReactDataSheet from 'react-datasheet';
import { getUniqueId, renderMathInElement } from "/src/lib/utils/helperMethods";
import { getParsedJSONObject } from "../InteractiveHelpers";
import {
  CustomIconButton,
  CustomTextButton,
  Segment,
  SegmentData,
  SegmentSetting,
  CustomInput,
  CustomLabel,
  showInfoModal,
} from "/src/components/UI/Segment/UIHelper";

// import {
//   ContextMenu,
//   MenuItem,
//   SubMenu,
//   ContextMenuTrigger,
//   connectMenu,
// } from "react-contextmenu";
// import RCJChart from "../RCJChart/RCJChart";
import MrSelect from "/src/components/UI/MrSelect/MrSelect";
import { cloneDeep, debounce } from "lodash";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import { BiUndo } from "react-icons/bi";
import loadable from "@loadable/component";
import CellEditorModal from "./CellEditorModal";
import {
  currentUserSelector,
  enabledFeaturesSelector,
  isMBIframeSelector,
} from "/src/views/Auth/Login/selector";

import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { Item, Menu, Submenu, contextMenu } from "react-contexify";
import ListActionsMenu from "/src/components/UI/ListActionsMenu/ListActionsMenu";
import { activeAdjustedExperienceSelector } from "../../Experiences/selector";
const ReactDataSheet = loadable(() => import(/* webpackChunkName: "react-datasheet" */ 'react-datasheet'))
const RCJChart = loadable(() => import(/* webpackChunkName: "react-chart" */ '../RCJChart/RCJChart'))

// const ReactDataSheet = loadable(() =>
//   import(/* webpackChunkName: "react-datasheet" */ "react-datasheet")
// );
// const RCJChart = loadable(() =>
//   import(/* webpackChunkName: "react-chart" */ "../RCJChart/RCJChart")
// );

const RDSTable = (props) => {
  console.log("RDS Table props", props);
  const {
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
  } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);
  const mrIntl = useTranslate()
  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());

  const isMBIframe = useSelector(isMBIframeSelector());

  const experienceSettings = activeExperience.settings || {};
  const renderUsingWiris = !isMBIframe && false // APL-4255 - Found issue in Table math render - turned off wiris render for table math for now - only using Mathjax

  let textAnswerString = props.text_answer || "";
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  const extra = {};
  const getInitialCellData = () => {
    const id = getUniqueId();
    return {
      key: id,
      id: id,
      content: "",
      placeholder: mrIntl("RDSTable.data_placeholder"),
    };
  };

  if (createMode) {
    // parsedJSONObject = initialData('table', 4, 2);
    parsedJSONObject = {
      segment_data: {
        table_data: [
          [
            {
              key: getUniqueId(),
              id: getUniqueId(),
              content: "Data 1",
            },
            {
              key: getUniqueId(),
              id: getUniqueId(),
              content: "Data 2",
            },
            {
              key: getUniqueId(),
              id: getUniqueId(),
              content: "Data 3",
            },
            {
              key: getUniqueId(),
              id: getUniqueId(),
              content: "Data 4",
            },
          ],
          [
            {
              key: getUniqueId(),
              id: getUniqueId(),
              content: "10",
            },
            {
              key: getUniqueId(),
              id: getUniqueId(),
              content: "20",
            },
            {
              key: getUniqueId(),
              id: getUniqueId(),
              content: "30",
            },
            {
              key: getUniqueId(),
              id: getUniqueId(),
              content: "40",
            },
          ],
        ],
        chart_settings: {
          data_type: "row",
          datasets: [],
          legends: [],
        },
      },
      segment_version: "1.0.0",
    };
    if (props.question_type == "rds_table") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }

  const [state, setState] = useState(parsedJSONObject);
  const [textAnswer, setTextAnswer] = useState(textAnswerString);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitchValue: false,
  });
  const screenSettings = props.screenSettings || {};
  const [currentCell, setCurrentCell] = useState({});
  const [mathModalSetting, setMathModalSetting] = useState({
    title: "Insert/Edit Math",
    visible: false,
  });
  const rdsTableRef = useRef(null);

  let currentJSON, text_answer, attempt_status;
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject;
    text_answer = textAnswerString;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    text_answer = textAnswer;
    attempt_status = attemptStatus;
  }

  const segment_data = currentJSON?.segment_data || {};
  const td = segment_data.table_data;
  const table_data = (typeof td == "string" ? JSON.parse(td) : td) || [[]];

  // const [tableData, setTableData] = useState((typeof td == "string" ? JSON.parse(td) : td) || [[]]);
  // const table_data = tableData;
  const table_editable = segment_data.table_editable;
  const chart_editable = segment_data.chart_editable;
  const chart_plottable = segment_data.chart_plottable;
  const chart_settings = segment_data.chart_settings || {};
  const chart_legends = chart_settings.legends || [];

  const contextMenuID = `Table_Context_Menu_${props.unique_segment_identifier}`;

  console.log(
    "contextMenuID with unique_segment_identifier ==>",
    contextMenuID
  );
  if (presentationMode) {
    table_data.map((row) => {
      row.map((cell) => {
        cell.readOnly = true;
      });
    });
  }

  console.log("Updated data ==>", currentJSON, table_data);

  useEffect(() => {
    setTimeout(() => {
      renderMathInElement(rdsTableRef.current, renderUsingWiris);
    }, 100);
  }, []);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          // text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    contextMenu.show({
      id: contextMenuID,
      event: event,
      props: {
        cell: currentCell
      }
    });
  };

  const saveState = (state) => {
    setState(state);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const saveTextAnswer = (data) => {
    setTextAnswer(data);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const addColumn = (index) => {
    const newTableData = table_data;
    if (index == undefined) {
      index = newTableData[0].length;
    }

    newTableData.map((row) => {
      row.splice(index, 0, getInitialCellData());
    });

    // const newSegmentData = {
    // 	...segment_data,
    // 	table_data: newTableData
    // }

    // const newState = {
    // 	...state,
    // 	segment_data: newSegmentData
    // }

    updateChartSettings(newTableData);
    // saveState(newState)
  };

  const removeColumn = (index) => {
    const newTableData = table_data;
    const colCount = newTableData[0].length;
    if (colCount <= 1) {
      showInfoModal({
        type: "warning",
        title: mrIntl("RDSTable.reached_minimum_allowed_limit_title"),
      });
      return;
    }
    if (index == undefined) {
      index = colCount - 1;
    }

    newTableData.map((row) => {
      row.splice(index, 1);
    });

    // const newSegmentData = {
    // 	...segment_data,
    // 	table_data: newTableData
    // }

    // const newState = {
    // 	...state,
    // 	segment_data: newSegmentData
    // }

    updateChartSettings(newTableData);
    // saveState(newState)
  };

  const addRow = (index) => {
    const newTableData = table_data;
    if (index == undefined) {
      index = newTableData.length;
    }

    let newRow = [];
    for (let i = 0; i < newTableData[0].length; i++) {
      newRow.push(getInitialCellData());
    }

    newTableData.splice(index, 0, newRow);

    // const newSegmentData = {
    // 	...segment_data,
    // 	table_data: newTableData
    // }

    // const newState = {
    // 	...state,
    // 	segment_data: newSegmentData
    // }

    // console.log("add row new state ===>", newState, index)
    updateChartSettings(newTableData);
    // saveState(newState)
  };

  const removeRow = (index) => {
    const newTableData = table_data;
    const rowCount = newTableData.length;
    if (rowCount <= 1) {
      showInfoModal({
        type: "warning",
        title: mrIntl("RDSTable.reached_minimum_allowed_limit_title"),
      });
      return;
    }

    if (index == undefined) {
      index = rowCount - 1;
    }

    newTableData.splice(index, 1);

    // const newSegmentData = {
    // 	...segment_data,
    // 	table_data: newTableData
    // }

    // const newState = {
    // 	...state,
    // 	segment_data: newSegmentData
    // }

    updateChartSettings(newTableData);
    // saveState(newState)
  };

  const handleTextAlignChange = (alignment) => {
    const { row, col } = currentCell;
    if (row >= 0 && col >= 0) {
      const newTableData = table_data;
      newTableData[row][col] = {
        ...table_data[row][col],
        textAlign: alignment,
      };
      updateChartSettings(newTableData);
    }
  };

  const onCellsChanged = (changes, isMath = false) => {
    // const table_data = state.table_data;
    const newTableData = table_data;
    changes.forEach(({ cell, row, col, value }) => {
      newTableData[row][col] = {
        ...table_data[row][col],
        content: value,
        isMath: isMath,
      };
    });

    // const newSegmentData = {
    //   ...segment_data,
    //   table_data: newTableData,
    // };

    // const newState = {
    //   ...state,
    //   segment_data: newSegmentData,
    // };

    if (answerMode && !attemptStatus.json_attempted) {
      setAttemptStatus({
        ...attemptStatus,
        json_attempted: true,
      });
    }

    console.log("Cells changed ==>", changes, newTableData);
    updateChartSettings(newTableData);
    // if (chart_settings.type) {
    // } else {
    // 	saveState(newState);
    // }
  };

  // const updateChartSettings = (value) => {
  // 	chart_settings.data_type = value
  // 	chart_settings.datasets = []

  // 	const newSegmentData = {
  // 		...segment_data,
  // 		chart_settings: chart_settings
  // 	}

  // 	const newState = {
  // 		...state,
  // 		segment_data: newSegmentData
  // 	}

  // 	saveState(newState)
  // 	updateChartSettings(table_data, chart_settings.type)
  // }

  const updateChartSettings = (table_data, settings) => {
    let newLabels = [];
    let newDatasets = [];
    let newChartLegends = chart_legends;
    let newChartSettings = {
      ...chart_settings,
      ...settings,
    };

    if (newChartSettings.data_type == "col") {
      table_data.map((row, i) => {
        row.map((cell, j) => {
          if (j == 0) {
            let label =
              (cell.content && cell.content.replace(/(<([^>]+)>)/gi, "")) || "";
            newLabels[i] = label;
          } else {
            let dataset = newDatasets[j - 1] || {
              data: [],
            };
            let legend = newChartLegends[j - 1] || {
              label: `Dataset ${j}`,
            };

            let number =
              parseInt(cell.content && cell.content.match(/\d+/g)) || 0;
            dataset.data[i] = number;
            dataset.label = legend.label;
            newDatasets[j - 1] = dataset;
            newChartLegends[j - 1] = legend;
          }
        });
      });
      newDatasets = newDatasets.slice(0, table_data[0].length - 1);
      newChartLegends = newChartLegends.slice(0, table_data[0].length - 1);
    } else if (newChartSettings.data_type == "row") {
      table_data.map((row, i) => {
        row.map((cell, j) => {
          if (i == 0) {
            let label =
              (cell.content && cell.content.replace(/(<([^>]+)>)/gi, "")) || "";
            newLabels[j] = label;
          } else {
            let dataset = newDatasets[i - 1] || {
              data: [],
            };
            let legend = newChartLegends[i - 1] || {
              label: `Dataset ${i}`,
            };

            let number =
              parseInt(cell.content && cell.content.match(/\d+/g)) || 0;
            dataset.data[j] = number;
            dataset.label = legend.label;
            newDatasets[i - 1] = dataset;
            newChartLegends[i - 1] = legend;
          }
        });
      });
      newDatasets = newDatasets.slice(0, table_data.length - 1);
      newChartLegends = newChartLegends.slice(0, table_data.length - 1);
    }

    newChartSettings = {
      ...newChartSettings,
      labels: newLabels,
      datasets: newDatasets,
      legends: newChartLegends,
    };

    const newSegmentData = {
      ...segment_data,
      table_data: table_data,
      chart_settings: newChartSettings,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    if (answerMode && !attemptStatus.json_attempted) {
      setAttemptStatus({
        ...attemptStatus,
        json_attempted: true,
      });
    }

    saveState(newState);
  };

  const onTextChanged = (type, data, index) => {
    const newChartSettings = chart_settings;
    if (type == "legends") {
      newChartSettings[type][index].label = data;
      newChartSettings["datasets"][index].label = data;
    } else {
      newChartSettings[type] = data;
    }

    const newSegmentData = {
      ...segment_data,
      chart_settings: chart_settings,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    if (answerMode && !attemptStatus.json_attempted) {
      setAttemptStatus({
        ...attemptStatus,
        json_attempted: true,
      });
    }

    saveState(newState);
  };

  const setProperty = (type, value) => {
    const newSegmentData = {
      ...segment_data,
      [type]: value,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    if (answerMode && !attemptStatus.json_attempted) {
      setAttemptStatus({
        ...attemptStatus,
        json_attempted: true,
      });
    }

    saveState(newState);
  };

  // const checkAnswer = () => {
  //   let question_json = cloneDeep(props.teacher_json)
  //   let answer_json = cloneDeep(state)
  //   const newState = checkResponses(question_json, answer_json)

  //   setMyAnswer(state)
  //   saveState(newState)
  //   setStateSetting({
  //     ...stateSetting,
  //     quickCheckModeEnabled: true,
  //   })
  //   if (setRenderMath) {
  //     setRenderMath(getUniqueId())
  //   }
  // }

  // const showCorrectAnswer = (value) => {
  //   let question_json, answer_json
  //   if (value) {
  //     question_json = cloneDeep(props.teacher_json)
  //     answer_json = cloneDeep(props.teacher_json)
  //   } else {
  //     question_json = cloneDeep(props.teacher_json)
  //     answer_json = cloneDeep(myAnswer)
  //   }

  //   const newState = checkResponses(question_json, answer_json)
  //   saveState(newState)
  //   setStateSetting({
  //     ...stateSetting,
  //     quickCheckModeEnabled: true,
  //   })
  //   if (setRenderMath) {
  //     setRenderMath(getUniqueId())
  //   }
  // }

  const resetQuestion = () => {
    let student_json = cloneDeep(props.student_json);
    const newState = student_json;

    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    saveState(newState);
    renderMathInElement(rdsTableRef.current, renderUsingWiris);
  };

  // function TableContextMenu({ id }) {
  // 	return (
  // 		<ContextMenu id={id}>
  // 			<MenuItem
  // 				onClick={(e) => removeRow(currentCell.row)}
  // 			>
  // 				Delete Row
  // 			</MenuItem>
  // 			<SubMenu title="Insert Row">
  // 				<MenuItem onClick={(e) => addRow(currentCell.row)} >
  // 					Above
  // 				</MenuItem>
  // 				<MenuItem onClick={(e) => addRow(currentCell.row + 1)} >
  // 					Below
  // 				</MenuItem>
  // 			</SubMenu>
  // 			<MenuItem
  // 				onClick={(e) => removeColumn(currentCell.col)}
  // 			>
  // 				Delete Column
  // 			</MenuItem>
  // 			<SubMenu title="Insert Column">
  // 				<MenuItem onClick={(e) => addColumn(currentCell.col)} >
  // 					Left
  // 				</MenuItem>
  // 				<MenuItem onClick={(e) => addColumn(currentCell.col + 1)} >
  // 					Right
  // 				</MenuItem>
  // 			</SubMenu>
  // 		</ContextMenu>
  // 	)
  // }

  const showMathModal = () => {
    setMathModalSetting({
      ...mathModalSetting,
      visible: true,
    });
  };

  const valueRenderer = (cell, i, j) => {
    renderMathInElement(rdsTableRef.current, renderUsingWiris);
    return cell.content;
  };

  const valueViewer = ({ cell, i, j }) => {
    return (
      <span
        className={"value-viewer"}
        dangerouslySetInnerHTML={{ __html: cell.content }}
      ></span>
    );
  };

  const menuItems = [];

  if (createMode || table_editable) {
    menuItems.push(
      {
        item: <Button type="text" onClick={(e) => removeRow(currentCell.row)}>
          <MrTranslate id={"RDSTable.delete_row_table_menu"} />
        </Button>
      },
      {
        item: mrIntl("RDSTable.insert_row_title"),
        subMenu: [
          { label: <Button type="text" onClick={(e) => addRow(currentCell.row)}><MrTranslate id={"RDSTable.above_table_menu"} /></Button> },
          {
            label: <Button type="text" onClick={(e) => addRow(currentCell.row + 1)}>
              <MrTranslate id={"RDSTable.below_table_menu"} />
            </Button>
          }
        ]
      },
      {
        item: <Button type="text" onClick={(e) => removeColumn(currentCell.col)}>
          <MrTranslate id={"RDSTable.delete_column_table_menu"} />
        </Button>
      },
      {
        item: mrIntl("RDSTable.insert_column_title"),
        subMenu: [
          {
            label: <Button type="text" onClick={(e) => addColumn(currentCell.col)}>
              <MrTranslate id={"RDSTable.left_table_menu"} />
            </Button>
          },
          {
            label: <Button type="text" onClick={(e) => addColumn(currentCell.col + 1)}>
              <MrTranslate id={"RDSTable.right_table_menu"} />
            </Button>
          }
        ]
      },
      {
        item: "Alignment",
        subMenu: [
          { label: <Button type="text" onClick={() => handleTextAlignChange('left')}>Left</Button> },
          { label: <Button type="text" onClick={() => handleTextAlignChange('center')}>Center</Button> },
          { label: <Button type="text" onClick={() => handleTextAlignChange('right')}>Right</Button> }
        ]
      }
    )
    if (currentUser.role == "qb_author" ||
      enabledFeatures.math_in_table) {
      menuItems.push({
        item: <Button type="text" onClick={() => showMathModal()}>
          <MrTranslate id={"RDSTable.insert_edit_math_table_menu"} />
        </Button>
      })
    }
  }

  console.log("Table state before render ==>", state);
  return (
    <Segment>
      <SegmentData
        key={`segment-data-${props.unique_segment_identifier}`}
        ref={rdsTableRef}
        className={"rds-table"}
      >
        {createMode && (
          <div>
            <CustomTextButton
              buttonText={mrIntl("RDSTable.add_column_button")}
              clicked={(e) => addColumn()}
            ></CustomTextButton>
            <CustomTextButton
              buttonText={mrIntl("RDSTable.remove_column_button")}
              clicked={(e) => removeColumn()}
            ></CustomTextButton>

            <CustomTextButton
              buttonText={mrIntl("RDSTable.add_row")}
              clicked={(e) => addRow()}
            ></CustomTextButton>
            <CustomTextButton
              buttonText={mrIntl("RDSTable.remove_row")}
              clicked={(e) => removeRow()}
            ></CustomTextButton>
          </div>
        )}
        <ListActionsMenu onContextMenu={handleContextMenu} dropdownKey={`rds-table-context-menu`}
        menuItems={menuItems} triggerType={"contextMenu"} className="rds-table-context-menu">
        <div>
          <Suspense fallback={<div><MrTranslate id={"CommonText.loading"}/>...</div>}>
            <ReactDataSheet
              data={table_data}
              valueRenderer={(cell, i, j) => valueRenderer(cell, i, j)}
              // dataRenderer={(cell, i, j) => cell.content}
              cellRenderer={(props) => {
                console.log("cellRenderer =====>", props);
                const {
                  cell,
                  onDoubleClick,
                  row,
                  col,
                  attributesRenderer,
                } = props;
                const attributes = attributesRenderer ? attributesRenderer(cell, row, col) : {};
                const handleDoubleClick = (event) => {
                  if (cell.isMath) {
                    if (!cell.readOnly) {
                      setCurrentCell({
                        cell: cell,
                        row: row,
                        col: col,
                      });
                      showMathModal();
                    }
                    console.log("event on double click ==>", event, cell);
                  } else {
                    onDoubleClick(event);
                  }
                };
                return (
                  <td
                    {...props}
                    {...attributes}
                    key={cell.key}
                    style={{ textAlign: cell.textAlign || 'left' }}
                    onDoubleClick={(event) => handleDoubleClick(event)}
                    onTouchEnd={(event) => handleDoubleClick(event)}
                  >
                    {props.children}
                  </td>
                );
              }}
              // onSelect={({ start, end }) => {
              //   console.log("On select ", start, end);
              //   let row = start.i;
              //   let col = start.j;
              //   setCurrentCell({
              //     cell: table_data[row][col],
              //     row: row,
              //     col: col,
              //   });
              // }}
              // dataEditor={({ cell, row, col, value, onCommit }) => {
              // 	return (
              // 		<BasicCKE
              // 			data={cell.content}
              // 			// onTextChanged={(data) => onTextChanged(cell, data)}
              // 			onExitEditing={(data, event) =>
              // 				onCommit(data)
              // 			}
              // 		>
              // 		</BasicCKE>
              // 	)
              // }}
              valueViewer={(props) => valueViewer(props)}
              onCellsChanged={(changes) => onCellsChanged(changes)}
              onContextMenu={(e, cell, i, j) =>
                setCurrentCell({ cell: cell, row: i, col: j })
              }
              overflow={"wrap"}
              // dataEditor={(props) => {
              //   const { cell, row, col, value, onCommit, onChange } = props;
              //   console.log("Data editor props=====>", props);
              //   return (
              //     <BasicCKE
              //       data={cell.content}
              //       tools={rdsTableTools}
              //       // onTextChanged={(data) => onChange(data)}
              //       onExitEditing={(data) => onCommit(data)}
              //     ></BasicCKE>
              //   );
              // }}
            />
          </Suspense>
        </div>
        </ListActionsMenu>
        {/* <TableContextMenu id={contextMenuID}/> */}
        {/* {(createMode || table_editable) &&
          <ListActionsMenu id={contextMenuID}
          menuItems={menuItems} triggerType={"contextMenu"}>
            <Item onClick={(e) => removeRow(currentCell.row)}>
              <MrTranslate id={"RDSTable.delete_row_table_menu"} />
            </Item>
            <Submenu title={mrIntl("RDSTable.insert_row_title")}>
              <Item onClick={(e) => addRow(currentCell.row)}><MrTranslate id={"RDSTable.above_table_menu"} /></Item>
              <Item onClick={(e) => addRow(currentCell.row + 1)}>
                <MrTranslate id={"RDSTable.below_table_menu"} />
              </Item>
            </Submenu>
            <Item onClick={(e) => removeColumn(currentCell.col)}>
              <MrTranslate id={"RDSTable.delete_column_table_menu"} />
            </Item>
            <Submenu title={mrIntl("RDSTable.insert_column_title")}>
              <Item onClick={(e) => addColumn(currentCell.col)}>
                <MrTranslate id={"RDSTable.left_table_menu"} />
              </Item>
              <Item onClick={(e) => addColumn(currentCell.col + 1)}>
                <MrTranslate id={"RDSTable.right_table_menu"} />
              </Item>
            </Submenu>
            <Submenu title="Alignment">
              <Item onClick={() => handleTextAlignChange('left')}>Left</Item>
              <Item onClick={() => handleTextAlignChange('center')}>Center</Item>
              <Item onClick={() => handleTextAlignChange('right')}>Right</Item>
            </Submenu>
            {(currentUser.role == "qb_author" ||
              enabledFeatures.math_in_table) && (
                <Item onClick={() => showMathModal()}>
                  <MrTranslate id={"RDSTable.insert_edit_math_table_menu"} />
                </Item>
              )}
          </ListActionsMenu>
          } */}
        <br></br>
        {createMode && (
          <Space direction="vertical">
            <Checkbox
              defaultChecked={table_editable}
              onChange={(e) => {
                setProperty("table_editable", e.target.checked);
              }}
            >
              <MrTranslate id={"RDSTable.allow_students_to_edit_prefilled_text"}/>{" "}
              <b>(<MrTranslate id={"RDSTable.note_empty_cells_will_always_be_editable"}/>)</b>
            </Checkbox>
            <Checkbox
              defaultChecked={chart_plottable}
              onChange={(e) => {
                setProperty("chart_plottable", e.target.checked);
              }}
            >
              <MrTranslate id={"RDSTable.enable_autoplot_msg"}/>
            </Checkbox>
          </Space>
        )}
        {(createMode || (answerMode && chart_plottable)) && (
          <div>
            <Space>
              <MrSelect
                config={{
                  style: { width: "200px" },
                  placeholder: mrIntl("RDSTable.select_chart_type"),
                  value: chart_settings.type,
                  onChange: (value) =>
                    updateChartSettings(table_data, { type: value }),
                }}
                options={[
                  { label: mrIntl("RDSTable.bar_chart"), value: "bar" },
                  // { label: 'Pie Chart', value: 'pie' },
                  { label: mrIntl("RDSTable.line_chart"), value: "line" },
                ]}
              ></MrSelect>
              {chart_settings.type && (
                <MrSelect
                  config={{
                    style: { width: "200px" },
                    placeholder: mrIntl("RDSTable.select_chart_data_placeholder"),
                    value: chart_settings.data_type,
                    onChange: (value) =>
                      updateChartSettings(table_data, { data_type: value }),
                  }}
                  options={[
                    { label: mrIntl("RDSTable.x_axis_labels_from_row_1"), value: "row" },
                    { label: mrIntl("RDSTable.x_axis_labels_from_column_1"), value: "col" },
                  ]}
                ></MrSelect>
              )}
              <CustomIconButton
                clicked={() => {
                  updateChartSettings(table_data, { type: null });
                }}
                icon={<BiUndo />}
              ></CustomIconButton>
            </Space>
          </div>
        )}

        {chart_settings.type && (
          <div>
            <RCJChart chart_settings={chart_settings}></RCJChart>
            {(createMode ||
              (answerMode && (chart_editable || chart_plottable))) && (
              <div>
                <Space>
                  <CustomInput
                    key="chart-title-input"
                    placeholder={mrIntl("RDSTable.chart_title_placeholder")}
                    defaultValue={chart_settings.title}
                    onChange={(data) => onTextChanged("title", data)}
                  ></CustomInput>
                  <CustomInput
                    key="chart-x-axis-input"
                    placeholder={mrIntl("RDSTable.x_axis_label_placeholder")}
                    defaultValue={chart_settings.xAxesLabel}
                    onChange={(data) => onTextChanged("xAxesLabel", data)}
                  ></CustomInput>
                  <CustomInput
                    key="chart-y-axis-input"
                    placeholder={mrIntl("RDSTable.y_axis_label_placeholder")}
                    defaultValue={chart_settings.yAxesLabel}
                    onChange={(data) => onTextChanged("yAxesLabel", data)}
                  ></CustomInput>
                </Space>
                <Space
                  style={{
                    marginTop: "20px",
                    width: "50%",
                  }}
                  direction="vertical"
                >
                  <CustomLabel key="legends">{mrIntl("RDSTable.legends")}</CustomLabel>
                  {chart_legends.map((legend, index) => (
                    <CustomInput
                      key="chart-dataset-input"
                      // placeholder={`Dataset ${index + 1}`}
                      placeholder={mrIntl("RDSTable.dataset_placeholder", { index: index + 1 })}
                      defaultValue={legend.label}
                      onChange={(data) => onTextChanged("legends", data, index)}
                    ></CustomInput>
                  ))}
                </Space>
              </div>
            )}
          </div>
        )}
        {mathModalSetting.visible && (
          <CellEditorModal
            mathModalSetting={mathModalSetting}
            setMathModalSetting={setMathModalSetting}
            onCellsChanged={onCellsChanged}
            currentCell={currentCell}
          ></CellEditorModal>
        )}
      </SegmentData>
      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        saveTextAnswer={saveTextAnswer}
        resetQuestion={resetQuestion}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default RDSTable;
