import React,{useState} from "react";
import {Button,Tag, Space, Menu, Dropdown,Divider,Input} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  UserOutlined,
  DownOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {actions as offlineAppActions} from "/src/App/OfflineApp/offlineAppRedux"
import { appEnvTypeSelector} from "/src/App/OfflineApp/offlineAppSelectors";



const EnvSelection = (props) => {
  console.log( "DemoUserSwitcher props", props );
  
  const history = useHistory();
  const dispatch = useDispatch();

  const appEnvUrl = useSelector(appEnvTypeSelector())
  const [inputValue, setInputValue] = useState("");
  
  const handleMenuClick = (obj) => {
    dispatch(offlineAppActions.executeMethod({key: "setAppUrl", value: obj.key}));
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        inputValue!="" && dispatch(offlineAppActions.executeMethod({key: "setAppUrl", value: inputValue}));
    }
  }

  const handleInputChange = (event) =>{
    setInputValue(event.target.value);
   }

  const menu = (
    <Menu onClick={handleMenuClick}>
      {/* key to be ID of user */}
      <Menu.Item key="https://app-staging.assessprep.com/">app-staging</Menu.Item>
      <Menu.Item key="https://app-dev.assessprep.com/">dev</Menu.Item>
      <Menu.Item key="https://app-dev2.assessprep.com/">dev2</Menu.Item>
      <Menu.Item key="https://app-dev3.assessprep.com/">dev3</Menu.Item>
      <Menu.Item key="https://app.assessprep.com/">production</Menu.Item>
      <Menu.Item key="https://app.assessprep.com/" disabled >
        <Input placeholder="Enter custom url" autoCapitalize='none' value={ inputValue } onChange={ handleInputChange } onKeyDown={handleKeyDown}/></Menu.Item>
    </Menu>
  );

let envSelectedTitle = "Select Environment";
if(appEnvUrl=="https://app-staging.assessprep.com/"){
    envSelectedTitle = "app-staging";
}else if (appEnvUrl=="https://app.assessprep.com/"){
    envSelectedTitle = "production";
}else if(appEnvUrl=="https://app-dev.assessprep.com/"){
    envSelectedTitle = "dev";
}else if (appEnvUrl=="https://app-dev2.assessprep.com/"){
    envSelectedTitle = "dev2";
}else if(appEnvUrl=="https://app-dev3.assessprep.com/"){
    envSelectedTitle = "dev3";
}

  return <>
  <Space direction={"vertical"} >
    <Dropdown overlay={menu}>
      <Button>
        {envSelectedTitle} <DownOutlined />
      </Button>
    </Dropdown>
    </Space>
  </>

}


EnvSelection.defaultProps = {};
EnvSelection.propTypes = {};
export default EnvSelection;


                