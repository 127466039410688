import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import {
  Segment,
  SegmentData,
  ShowText,
  ShowTextContent,
  TextArea,
} from "/src/components/UI/Segment/UIHelper";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent";
import CKETextAnswer from "/src/views/Segments/CKETextContent/CKETextAnswer";
import { cloneDeep, range } from "lodash";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import MediaRecorder from "/src/components/UI/Media/MediaRecorder";
import { Alert, Badge, Button, Col, Row, Select, Switch, Tooltip } from "antd";
import { HiOutlineMicrophone } from "react-icons/hi";
import { InfoCircleTwoTone, QuestionCircleTwoTone } from "@ant-design/icons";
import MediaShow from "/src/components/UI/Media/MediaShow";
import CKETextCaption from "../CKETextContent/CKETextCaption";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { getAppVersion, getDeviceInfo, isNewAppVersion } from "/src/App/OfflineApp/offlineAppHelper";

const AudioInput = (props) => {
  console.log("Audio Input props ==>", props);

  const { Option } = Select;
  const {
    segmentSettings,
    showCorrectAnswerSwitch,
    triggerStateUpdate,
    annotationOptions,
    setRenderMath,
    ckeConfig,
    segmentFooterAttributes,
  } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);

  const appType = useSelector(appTypeSelector());
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  if (createMode) {
    parsedJSONObject = {
      segment_data: {
        max_recording_duration: 300,
      },
      segment_version: "1.0.0",
    };
    if (props.question_type == "audio_input") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }

  const [state, setState] = useState(parsedJSONObject);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    updatedCount: 0,
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitchValue: false,
  });

  const screenSettings = props.screenSettings || {};

  let currentJSON, attempt_status;
  if (presentationMode) {
    currentJSON = parsedJSONObject;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    attempt_status = attemptStatus;
  }

  console.log("current json ==>", currentJSON, state);
  const segment_data = currentJSON?.segment_data || {};
  const caption = segment_data.caption;
  const attachments = segment_data.attachments || [];
  const maxRecordingDuration = segment_data.max_recording_duration || 30;
  const secondsArray = range(5, 60, 5);
  const minutesArray = range(1, 21);
  const mrIntl = useTranslate();

  useEffect(() => {
    setMediaFormSetting({
      ...mediaFormSetting,
      maxRecordingDuration: maxRecordingDuration
    })
  }, [maxRecordingDuration]);

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
      if (props.onSubmit) {
        console.log("On submit is available now", props.onSubmit);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          // text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (newState) => {
    setState(newState);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const onTextChanged = (data) => {
    const newSegmentData = {
      ...segment_data,
      caption: data,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    const isTextAdded = data ? true : false;
    if (answerMode && isTextAdded) {
      setAttemptStatus({
        ...attemptStatus,
        caption_added: isTextAdded,
        json_attempted: isTextAdded || attemptStatus.audio_recorded,
      });
    }

    saveState(newState);
  };

  const toggleAnswer = () => {
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: !stateSetting.quickCheckModeEnabled,
    });
  };

  const mediaFormInitialState = {
    modal: true,
    loading: false,
    visible: false,
    title: mrIntl("CommonText.record_title"),
    okText: mrIntl("CommonText.save"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    requireFooter: false,
    okDisabled: true,
    currentMediaType: "audio",
    content_type: "audio",
    supportedTypes: "audio/mp3, audio/mpeg, audio/wav",// mpeg file because some mp3 file content type is audio/mpeg
    supportedFormats: "mp3, wav",
    maxRecordingDuration: maxRecordingDuration,
    selectConfig: {
      maxSize: "50 MB",
    },
  };
  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );

  // mediaFormSetting.onChangeSaveFiles = (files) => {
  //   console.log("on change files ==>", files)
  //   const { newFiles, extraFiles } = files
  //   onSaveMedia(newFiles, extraFiles)
  // }

  const onSaveMedia = (files) => {
    console.log("Files ==>", files);

    const newSegmentData = {
      ...segment_data,
      attachments: files,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    const isAudioRecorded = files && files.length ? true : false;
    if (answerMode && isAudioRecorded) {
      setAttemptStatus({
        ...attemptStatus,
        audio_recorded: isAudioRecorded,
        json_attempted: isAudioRecorded || attemptStatus.caption_added,
      });
    }

    saveState(newState);

    setMediaFormSetting({
      ...mediaFormSetting,
      visible: false,
    });
  };

  const openMediaModal = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: true,
    });
  };

  const saveRecordingDuration = (type, value) => {
    let duration = 0;
    if (type == "seconds") {
      duration = value;
    } else {
      duration = 60 * value;
    }
    const newSegmentData = {
      ...segment_data,
      max_recording_duration: duration,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const [durationType, setDurationType] = useState(
    maxRecordingDuration < 60 ? "seconds" : "minutes"
  );
  const onDurationTypeChange = (value) => {
    setDurationType(value ? "seconds" : "minutes");
  };

  const resetQuestion = () => {
    const data = cloneDeep(props.student_json);
    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });

    saveState(data);

    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };
  const deviceInfo = getDeviceInfo(appType);
  const deviceOsVersion = deviceInfo.osVersion;
  const currentAppVersion = getAppVersion(appType)
  const isiOSSupported = (appType === "ios" && isNewAppVersion(deviceOsVersion, "14.3.0"))
  const isMacOSSupported = (appType === "mac" && isNewAppVersion(currentAppVersion,"9.0.1") && isNewAppVersion(deviceOsVersion,"11.0.0"))

  let shouldAudioButtonDisable =
    (appType === "mac" && !isMacOSSupported ) || (appType === "ios" && !isiOSSupported);

  console.log("audio input state before render ==>", state, attachments);
  return (
    <Segment>
      <SegmentData
        key={`segment-data-${props.unique_segment_identifier}`}
        // className={"audio-input"}
        className={"media-input"}
      >
        {createMode ? (
          <div>
            <Alert
              description={
                mrIntl("AudioInput.student_will_get_option_to_record_audio_answer_here")
              }
              type="info"
              closable={false}
            />
            <div style={{ margin: "10px" }}>
              {mrIntl("AudioInput.max_recording_time")}: &nbsp;
              {/* Max recording time: { } */}
              <Select
                placeholder= {mrIntl("AudioInput.time_in_seconds_placeholder")}
                value={
                  durationType == "seconds"
                    ? maxRecordingDuration
                    : maxRecordingDuration / 60
                }
                onChange={(value) => saveRecordingDuration(durationType, value)}
                style={{ width: "80px" }}
              >
                {durationType == "seconds"
                  ? secondsArray.map((second) => (
                      <Option value={second}>{second}</Option>
                    ))
                  : minutesArray.map((minute) => (
                      <Option value={minute}>{minute}</Option>
                    ))}
              </Select>{" "}
              <Switch
                size="large"
                checkedChildren="seconds"
                unCheckedChildren="minutes"
                defaultChecked={durationType == "seconds" ? true : false}
                onChange={onDurationTypeChange}
              />
              {/*seconds OR <Select placeholder="Time in minutes" value={maxRecordingDuration} onChange={(value) => saveRecordingDuration('minutes', value)} style={{ width: '80px' }}>
              {minutesArray.map((minute) => <Option value={minute} >{minute}</Option>)}
            </Select> minute{maxRecordingDuration > 1 && 's'} */}
            </div>
          </div>
        ) : (
          <Row>
            <Col span={24}>
              {answerMode && (
                <>
                  <Badge
                    count={
                      (shouldAudioButtonDisable) ?  (
                        <Tooltip
                          title={mrIntl("AudioInput.not_supported_on_mac_tooltip")}
                          placement="top"
                        >
                          <QuestionCircleTwoTone />
                        </Tooltip>
                      ) : null
                    }
                  >
                    <Button
                      className="record-answer"
                      onClick={(e) => openMediaModal()}
                      size="large"
                      // shape="round"
                      type="primary"
                      ghost={true}
                      icon={<HiOutlineMicrophone />}
                      disabled={!answerMode && shouldAudioButtonDisable}
                    >
                      {mrIntl("AudioInput.record_answer_button")}
                    </Button>
                  </Badge>
                  {mediaFormSetting.visible && (
                    <MediaRecorder
                      modal={true}
                      audio={true}
                      mediaFormSetting={mediaFormSetting}
                      setMediaFormSetting={setMediaFormSetting}
                      onSaveMedia={onSaveMedia}
                    ></MediaRecorder>
                  )}
                </>
              )}
              <p style={{ color: "#8b8b8b", marginTop: "5px" }}>
               {/* <i>
                  Max allowed recording duration is{" "}
                  {maxRecordingDuration < 60
                    ? `${maxRecordingDuration} seconds`
                    : `${maxRecordingDuration / 60} minute${
                        maxRecordingDuration >= 120 ? "s" : ""
                      }`}
                </i> */}
                {/* <i>
                  { maxRecordingDuration < 60 ?
                  `{Max allowed recording duration is ${maxRecordingDuration} seconds}` :
                    maxRecordingDuration >= 120 ?
                    `{Max allowed recording duration is ${maxRecordingDuration / 60} minutes}` :
                  `{Max allowed recording duration is ${maxRecordingDuration / 60} minute}`}
                </i> */}
                <i>
                  { maxRecordingDuration < 60 ?
                    mrIntl("CommonText.max_allowed_duration_in_second", {maxRecordingDuration: maxRecordingDuration}) :
                    maxRecordingDuration >= 120 ?
                    mrIntl("CommonText.duration_in_minutes", {maxRecordingDuration: maxRecordingDuration / 60}) :
                    mrIntl("CommonText.duration_in_minute", {maxRecordingDuration: maxRecordingDuration / 60})}
                </i>
              </p>
              <MediaShow file={attachments[0]} mediaType="audio"></MediaShow>
            </Col>
          </Row>
        )}
      </SegmentData>

      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        toggleAnswer={toggleAnswer}
        resetQuestion={resetQuestion}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default AudioInput;
