import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';

import {Tabs, List, Card, Table } from "antd";
import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import {Link, Route, useRouteMatch, useLocation} from "react-router-dom";
import OrgProgrammes from "../OrgProgrammes";
import OrgProgrammeItemDetail from "../OrgProgrammeItemDetail/OrgProgrammeItemDetail";
import { getActiveKeyFromPathname } from "/src/lib/utils/helperMethods";
import { SyncBtn } from "/src/views/Orgs/OrgsList";

const OrgProgrammeList = (props) => {
  const { dataSource } = props;
  const {history} = props;
  const {path, url} = useRouteMatch();
  const location = useLocation();
  console.log("exp list item url", url);
  console.log("org programmes list dataSource", dataSource);

  const { TabPane } = Tabs;

  const [activeKey, setActiveKey] = useState(null)
  useEffect(() => {
    console.log('org program list useeffect==>', location.pathname.split('/')[4], dataSource);
    console.log( "location.pathname.split", location.pathname.split("/") );
    // let activeKey1 = location.pathname.split("/")[4]
    let pathNameArray = window.location.pathname.split("/")
    let programsIndex = pathNameArray.indexOf("programs")

    if(programsIndex > -1 && pathNameArray[programsIndex + 1]){
      setActiveKey(pathNameArray[programsIndex + 1])
    } else{
      if(dataSource.length != 0){
        console.log( "dataSource[0].id", dataSource[0].id );
        history.push(`${url}/${dataSource[0].id}`)
        setActiveKey(parseInt(dataSource[0].id))
      }
    }
  }, []);
  
  const handleTabClick = (key, event) => {
    history.push(`${url}/${key}`)
    setActiveKey(key)
    console.log('inside tab click==>', key, url, activeKey);
  }


  // let activeKey = getActiveKeyFromPathname(window.location.pathname)
  // if(activeKey == "subjects" || activeKey == "years" || activeKey == "terms")
  //   activeKey = window.location.pathname.split("/").reverse()[1]
  // useEffect(() => {
  //   if(activeKey == "programs" && dataSource.length != 0)
  //     history.push(`${url}/${dataSource[0].id}`)
  //   // if(dataSource.length != 0){
  //   //   // console.log('topics useeffect ==>', topics)
  //   //   history.push(`${url}/${dataSource[0].id}`)
  //   // }
  // }, [])

  // const handleTabClick = (key, event) => {
  //   history.push(`${url}/${key}`)
  //   activeKey = key
  // }

  let finalRender = [];
  dataSource.map((program, i) => {
    // program.abbr_display
    let tab = <TabPane tab={program.name} key={program.id}>
      {/* add tabs for Subjects, Years, Academic Years and respective forms/components */}
      <Route path={`${path}/:orgProgrammesId`} render={routeProps => <OrgProgrammes params={{by_org_programme_id: program.id}} createUpdateParams={{org_programme_id: program.id}} isRoute={false} {...routeProps} config={{routes: {fetch:false}, itemDetail: {widget: OrgProgrammeItemDetail}}}/>}  /> 
    </TabPane>
    
    finalRender.push(tab);
  })
  let syncBtn = null;
  
  console.log( "props org programmelist", props );
  if(props.currentUser && props.currentUser.org){
    const org = props.currentUser.org;
    if(org && org.is_mb && org.custom_fields && org.custom_fields.sync_info && org.custom_fields.sync_info.completed_at){
      // sync has run atleast once
      syncBtn = <SyncBtn key={"mainMBSyncBtn"} size={"compact"} org={org}/>
    }
    
  }
  
  return <React.Fragment>
    {syncBtn}
    <Tabs activeKey={activeKey} onTabClick={handleTabClick} style={{"width":"100%"}}>
      {finalRender}    
    </Tabs>
  </React.Fragment>

};
OrgProgrammeList.defaultProps = {};

OrgProgrammeList.propTypes = {};

export default OrgProgrammeList;
