import React, { useState } from "react";
import { Button, Modal, Tooltip, Row, Tag, Space } from "antd";
import {
  UserAddOutlined,
  InfoOutlined,
  InfoCircleTwoTone
} from "@ant-design/icons";
import { appTypeSelector, sebConfigFileLoadingSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { useSelector, useDispatch } from "react-redux";
import {actions as loginActions} from "/src/views/Auth/Login/redux"
import SEBStartAlternativeModal from "/src/views/Experiences/SEBStartAlternativeModal";
import { openUrl, getOS, checkMob, getFromLS, generateOpenInAppUrl } from "/src/lib/utils/helperMethods";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { getDeviceInfo } from "/src/App/OfflineApp/offlineAppHelper";
import { currentViewSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";

const StartInSebBtn = (props) => {
  // actually now startInApp button = only showing when offline test and appType = "web"
  const {item, disabled, tooltip, className = ''} = props;
  console.log("StartInSebBtn props", props);
  const dispatch = useDispatch()
  const mrIntl = useTranslate()
  const sebConfigFileLoading = useSelector(sebConfigFileLoadingSelector())
  const appType = useSelector(appTypeSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const currentView = useSelector(currentViewSelector);
  const lockdownConfig = item.settings.lockdown_config || {}
  const token =  getFromLS("token")
  const newJoinViewWithDynamicConfigEnabled = token && enabledFeatures.new_join_view_with_dynamic_config
  const isJoinedView = currentView === "joined" && newJoinViewWithDynamicConfigEnabled
  // const isViteDevMode =
  //   import.meta.env.VITE_MODE === "development" ||
  //   import.meta.env.VITE_MODE === "staging";
  // TODO remove after merge

  // const isDynamicLockdownConfigForMac =
  //   (newJoinViewWithDynamicConfigEnabled && enabledFeatures.dynamic_lockdown_config_for_mac)
  //   //|| isViteDevMode; // not needed

  // TODO: In iPad we don't want to support dynamic lockdown config - so its not fully supported - need to cover cases
  const isDynamicLockdownConfigForiPad =
    (newJoinViewWithDynamicConfigEnabled && enabledFeatures.dynamic_lockdown_config_for_ipad)
  
  const closeFirestoreConnection = () => {
    dispatch(firestoreInteractionActions.setFirestoreConnectionClosedSuccess({firestoreConnectionClosed: true}))
  }

  let startInSEBBtn = <Tooltip
    title={tooltip}
  >
    <Button className={`${className} ${isJoinedView ? "start-in-app-button-for-new-join-view" : ""}`} shape="round" type="primary" disabled={disabled || sebConfigFileLoading} loading={sebConfigFileLoading} onClick={() => {
      if (sebConfigFileLoading) {
        return
      }
      // Make sure to check if the lockdown config is generated and has quit_password then only allow to generate the seb config file, we are not sending the quit_password in index as its sensitive info for the student
      if (newJoinViewWithDynamicConfigEnabled) {
        openUrl(`${import.meta.env.VITE_API_URL.replace("http", 'seb')}get_seb_config_xml/${item.uid}?token=${token}`)
      } else {
        dispatch(loginActions.sebConfigFile({ subject_label: item.custom_fields.subject_item_label }, { successCallback: openUrl }))
      }
      closeFirestoreConnection()
    }}>
      {mrIntl("StartInSebBtn.start_in_seb")}
    </Button>
  </Tooltip>

  // let startInMacAppBtn = <Tooltip title={mrIntl("StartInSebBtn.make_sure_app_is_installed")}><Button shape="round" type="primary" onClick={() => {
  //   const webBaseUrl = import.meta.env.VITE_WEB_URL
  //   let urlWithoutProtocol = webBaseUrl.split("https://")[1]
  //   let expUrl = `assessprep://${urlWithoutProtocol}`
  //   if (isDynamicLockdownConfigForMac) {
  // The appLogin parameter is used to handle the changeSecurity method after a redirect and the changeSecurity method call as per appLogin == true, if macapp open from dynamic config .
  // This is necessary because the `token` will be removed from the query parameters after the redirect.
  //     expUrl = `assessprep://${urlWithoutProtocol}/e/tests/${item.uid}?firstLoad=true&appLogin=true&token=${token}`;
  //   }
  //   // if(item){
  //   //   expUrl += `/e/tests/${item.uid}?firstLoad=true`
  //   // }
  //   // alert(expUrl)
  //   // window.location.href = expUrl
  //   openUrl(expUrl)
  // }}>
  //   {mrIntl("StartInSebBtn.start_in_ap_mac")}
  // </Button></Tooltip>

  // DONE: if on MAC OS, then open our mac app - open url -> assessprep://app.assessprep.com/e/:uid <- full test url for student
  // assessprep://app-staging.assessprep.com/e/tests/01ET82AAPBJ8WH6V3TEX37RGXR/


  let startInMacAppBtn = (
    <Tooltip title={mrIntl("StartInSebBtn.make_sure_app_is_installed")}>
      <Button
        className={`${className} ${isJoinedView ? "start-in-app-button-for-new-join-view" : ""}`}
        shape="round"
        type="primary"
        onClick={() => {
          const expUrl = generateOpenInAppUrl(
            item,
            token,
            newJoinViewWithDynamicConfigEnabled
          );
          openUrl(expUrl);
          closeFirestoreConnection()
        }}
      >
        {mrIntl("CommonText.start_in_app")}
      </Button>
    </Tooltip>
  );

  // let startInIOSAppBtn = <Tooltip title={mrIntl("StartInSebBtn.open_this_test_ios_app")}><Button shape="round" type="primary" disabled onClick={() => {
  //   // const webBaseUrl = import.meta.env.VITE_WEB_URL
  //   // let urlWithoutProtocol = webBaseUrl.split("https://")[1]
  //   // let expUrl = `assessprep://${urlWithoutProtocol}`
  //   // openUrl(expUrl)
  // }}>
  //   {mrIntl("CommonText.start_in_app")}
  // </Button></Tooltip>

  let startInIOSAppBtn = (
    <Tooltip title={mrIntl("StartInSebBtn.open_this_test_ios_app")}>
      <Button
        className={`${className} ${isJoinedView ? "start-in-app-button-for-new-join-view" : ""}`}
        shape="round"
        type="primary"
        onClick={() => {
          const withToken = true
          const expUrl = generateOpenInAppUrl(
            item,
            token,
            isDynamicLockdownConfigForiPad,
            withToken
          );
          openUrl(expUrl);
          closeFirestoreConnection()
        }}
      >
        {mrIntl("CommonText.start_in_app")}
      </Button>
    </Tooltip>
  );


  let startInChromebookBtn = <Tooltip title={mrIntl("StartInSebBtn.this_operating_system_is_not_supported")}><Button className={`${className} ${isJoinedView ? "disabled-start-in-app-button-for-new-join-view" : ""}`} shape="round" type="primary" disabled>
    {/* Start in App */}
    {mrIntl("CommonText.start_in_app")}
  </Button></Tooltip>

  let notSupportedBtn = <Tooltip title={mrIntl("StartInSebBtn.this_operating_system_is_not_supported")}><Button className={`${className} ${isJoinedView ? "disabled-start-in-app-button-for-new-join-view" : ""}`} shape="round" type="primary" disabled>
    {/* Start in App */}
    {mrIntl("CommonText.start_in_app")}
  </Button></Tooltip>

  let startBtnToRender = []
  // const os = getOS()
  const deviceInfo = getDeviceInfo(appType)
  const os = deviceInfo.os
  if(os === "Mac OS"){
    // startBtnToRender.push(startInSEBBtn) // no need now
    startBtnToRender.push(startInMacAppBtn)
  }else if(os == "Windows"){
    startBtnToRender.push(startInSEBBtn)
  }
  else if(os == "IPad OS"){ // appType web but os = IOS - should have start in app button. for now, not showing on iphone, only ipad
    startBtnToRender.push(startInIOSAppBtn)
  }else if(os == "ChromeOS"){
    startBtnToRender.push(startInChromebookBtn)
  }
  else{ // appType web but os where no lockdown app support = android, linux etc
    startBtnToRender.push(notSupportedBtn)
  }


  return <Space>{startBtnToRender}</Space>
};

export default StartInSebBtn;
