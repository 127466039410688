import React from "react";
import PropTypes from 'prop-types';

import { MrCrudView, MrForm as WrappedMrForm } from "mr_react_framework";
import { actions } from "./redux";
import SegmentListItem from "../SegmentListItem/SegmentListItem";
import commonSelectors from "/src/App/commonSelectors";
import { FormRenderer } from "mr_react_framework";
// import TopicList from "./TopicList/TopicList";
import TopicListNew from "./TopicList/TopicListNew";
import TopicItemDetail from "./TopicItemDetail/TopicItemDetail";

import sectionForm from "../SegmentForms/SectionForm";
import { topicsSelector } from "./selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const TopicChildComponent = ({ getFieldValue, sourceValues }) => {
  console.log("chilren, line 42");
  const segmentType = getFieldValue(["segment_type"]);
  let finalRender = null;
  const mrIntl = useTranslate()
  
  // if (segmentType === "section" || (!segmentType && sourceValues.id && sourceValues.segment_type === "section")) {
    finalRender = <FormRenderer
      formObj={sectionForm(mrIntl)}
      propertyKey={"section_segment_attributes"}
      sourceValues={sourceValues}
    />
  // }
  return finalRender;
}

export const returnForm = (props) => {
  console.log("test form props", props);
 

   let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps  ==>", dataProps);
      console.log("opts  ==>", opts);
      // process and set values and then return changed values
      // opts.values["custom_field"] = "custom_value";
      if (!opts.values.segment_type) {
        // setting default for create
        opts.values.segment_type = "section"
      }
      opts.values.previous_id = dataProps.formConfig.submitConfig.previousId
      if(dataProps.formConfig.submitConfig && dataProps.formConfig.submitConfig.destinationPosition){
        opts.values.destination_position = dataProps.formConfig.submitConfig.destinationPosition
      }

      // changed to cater to has_many change in BE
      if(opts.values[opts.values.segment_type + "_segment_attributes"]){
        // needs to be an array of objects
        opts.values[opts.values.segment_type + "_segments_attributes"] = [opts.values[opts.values.segment_type + "_segment_attributes"]]
        delete opts.values[opts.values.segment_type + "_segment_attributes"] 
      }
      
      console.log('opts.values', opts.values)
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        // segment_type: {
        //   type: "string",
        //   // title: "Segment type",
        //   default: "section",
        //   span: 24,
        //   widgetConfig: {
        //     hidden: true
        //   }
        // },
        // title: {
        //   type: "string",
        //   placeholder: "Segment title"
        // },
        // user_id: {
        //   type: "string",
        //   default: props.currentUser.id,
        //   widgetConfig: {
        //     hidden: true
        //   }
        // },
        // experience: {
        //   type: "string",
        //   placeholder: "Segment title",
        //   default: props.currentUser.id,
        // },
        section_segment_attributes: {
            type: "string",
            dependsOn: ["segment_type"],
            children: TopicChildComponent
        }
        // section_segment_attributes: {
        //   type: "string",
        //   dependsOn: ["segment_type"],
        //   children: ({ getFieldValue, sourceValues }) => {
        //     console.log("chilren, line 42");
        //     const segmentType = getFieldValue(["segment_type"]);
        //     let finalRender = null;
            
        //     // if (segmentType === "section" || (!segmentType && sourceValues.id && sourceValues.segment_type === "section")) {
        //       finalRender = <FormRenderer
        //         formObj={sectionForm}
        //         propertyKey={"section_segment_attributes"}
        //         sourceValues={sourceValues}
        //       />
        //     // }
        //     return finalRender;
        //   },
        // },
        
      },
    }
  };
  return form;
};


const Topics = MrCrudView({
  resourceName: "topics",
  singleResourceName: "segment",
  // displayName: "Create/Edit Tests",
  resourceUrl: "/segments",
  // layout: OrgsLayout,
  // filter: ExperiencesFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
    // topics: topicsSelector,
    topics: topicsSelector
  },
  forms: [{ name: "topics", form: returnForm , config: {isModal: false }}],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: SegmentListItem
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: TopicItemDetail
    },
    pagination: {
      show: false,
    },
    filter: {
      show: false,
      widget: (props) => {
        return <span><MrTranslate id={"CommonText.filter"}/></span>
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      // widget: TopicList,
      widget: TopicListNew,
      // forceReload: true
      // widget: (props) => {
      //   return <span>List Component</span>;
      // }
    }
  },
});

// Segments.defaultProps = {};

Topics.propTypes = {};
export default Topics;
