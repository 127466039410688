import React from 'react';
import { Button, Tooltip } from 'antd';
import { StarOutlined } from "@ant-design/icons";
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const SetAccommodationBtn = (props) => {
  console.log('SetAccommodationBtn===>>>>>>', props);
  const { openAccommodationModal, buttonText, disabledTooltip, tooltip, buttonProps = {} } = props;

  const mrIntl = useTranslate();
  return <>
    <Tooltip placement='bottom' title={buttonProps.disabled ? disabledTooltip : (tooltip || "")}>
      <Button
        className={'set-accommodation-btn'}
        type={"text"}
        icon={<StarOutlined />}
        onClick={() => openAccommodationModal()}
        {...buttonProps}
      >{buttonText || mrIntl("SetAccommodationBtn.accommodation")}
      </Button>
    </Tooltip>
  </>
}
export default SetAccommodationBtn;