import React, {useEffect, useState} from "react";
// import { List, Button, Drawer, Tag, Tooltip, Space, Popconfirm, Alert, Empty, Badge, Modal, Menu, Dropdown } from "antd";
import { List, Button, Drawer, Tag, Tooltip, Space, Popconfirm, Alert, Empty, Badge, Modal, Menu, Dropdown, Input, Checkbox } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import IosStartBtn from "/src/components/UI/IosStartBtn"
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import "../Experiences.scss";
import { checkMob, checkIfStartTime, getServerTimeOffset, currentTimeValidWrtServerTime, writeToClipboardPolyfill, displayPoints, getSubjectIconMap, checkOffsetInterval, dropDownTrigger, checkParentElementHasClassNames, setToLS, checkIfSEBConfigIsValid, getUniqueId, getCurrentPlanStatus, convertToHoursAndMinutes } from "../../../lib/utils/helperMethods";
// import ExperienceCard from "./ExperienceCard";
import dayjs from "dayjs";
import { formatJsonApiData } from "mr_react_framework";
// import {actions as loginActions} from "/src/views/Auth/Login/redux"
import { BsThreeDots  } from "react-icons/bs"
import {actions as experienceReduxActions} from "/src/views/Experiences/redux"
import {actions as todayReduxActions} from "/src/views/Experiences/TodayExperiences/redux"
import {actions as libraryExperienceReduxActions} from "/src/views/Experiences/LibraryExperiences/redux"
import {actions as experienceUserActions} from "/src/views/ExperienceUsers/redux"
import {
  AppleOutlined,
  WindowsOutlined,
  EyeOutlined,
  CloudTwoTone,
  LockTwoTone,
  MinusCircleTwoTone,
  CheckCircleTwoTone,
  CheckCircleOutlined,
  CopyOutlined,
  TrophyOutlined,
  DeleteOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  CheckOutlined,
  SyncOutlined,
  SafetyCertificateTwoTone,
  VideoCameraOutlined,
  CloseCircleTwoTone,
  EditOutlined,
  LinkOutlined,
  EllipsisOutlined,
  UserOutlined,
  FileTextTwoTone
} from "@ant-design/icons";
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi"
import { useDispatch, useSelector } from "react-redux";
import { getServerTimeOffsetSelector } from "../selector";
import ResponseAttachments from "/src/views/Segments/ResponseAttachments/ResponseAttachments";
import { UploadFilesButton } from "/src/components/UI/Segment/UIHelper";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import CountDown from "react-countdown";
import { appTypeSelector, sebConfigFileLoadingSelector} from "/src/App/OfflineApp/offlineAppSelectors";
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector, partnerNameSelector } from "/src/views/Auth/Login/selector";
import SEBStartAlternativeModal from "../SEBStartAlternativeModal";
import StartInSebBtn from "/src/components/UI/SEB/StartInSebBtn";
import DuplicateExperienceButton from "/src/views/Experiences/ExperienceShow/Components/DuplicateExperienceButton";
import { showExperienceHelper } from "/src/views/Experiences/ExperienceHelperMethods";
import ExternalVideoCallButton from "/src/views/Experiences/ExperienceShow/Components/ExternalVideoCallButton";
import MBIcon from "/src/assets/images/MB-icon-new.png";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { upperFirst } from "lodash";
import ExperienceStatusBtn from "/src/components/UI/ExperienceStatusBtn/ExperienceStatusBtn";
import ListActionsMenu from "/src/components/UI/ListActionsMenu/ListActionsMenu";
import { checkInternetConnectivity } from "/src/lib/CheckInternetConnectivity/CheckInternetConnectivity";
import StartTestButtonOld from "/src/components/UI/StartTestButton/StartTestButtonOld";
import JoinTestButton from "/src/components/UI/JoinTestButton/JoinTestButton";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { getLatestAppVersion, getOfflineAppDownloadUrl, supportsMacAACSecurity, checkIfUnsupportedApp } from "/src/App/OfflineApp/offlineAppHelper";
import { useAutoMappingProgress } from "/src/components/UI/ViewUploadsModal";
import { getCurrentExtraTime } from "../ExperienceShow/Components/AddTimeBtn";
import DummyListItemsForUpgradePlan from "/src/lib/UpgradePlanFeature/DummyListItemsForUpgradePlan";
import TranslateAssessment from "../../Segments/GenerateAI/TranslateAssessment";
import { orgquestionbanksSelector } from "/src/views/Questionbanks/selector";


const ExperienceDrawerPreview = (props) => {
  // to show test preview in drawer
  console.log("ExperienceDrawerPreview props", props)
  const experience = props.experience;
  const [visible, setVisible] = useState(false);
  const {path, url} = useRouteMatch();
  const mrIntl = useTranslate()
  const openDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  let drawerPreview = <React.Fragment>
    <Button icon={<EyeOutlined />} type="text" onClick={(e) => {e.stopPropagation(); openDrawer();}} />
    <Drawer
      // title={experience.name}
      placement="bottom"
      // closable={true}
      onClose={onClose}
      open={visible}
      // mask={false}
      // width={"20%"}
      height={"50%"}
    >
      {/* {experience && experience.id} */}
      {visible && <>
        {/* <Route path={`${path}/:experiencesId`} render={routeProps => <Experiences resourceId={experience.id} params={{embed: true}} isRoute={false} {...routeProps} config={{routes: {fetch:false}, itemDetail: {widget: ExperienceItemDetail}}}/>}  /> */}
        <iframe height="100%" width="100%" title={mrIntl("ExperienceList.assessment_preview")} src={`/embed/${experience.uuid}`}></iframe>
      </>}
    </Drawer>
  </React.Fragment>

  return drawerPreview;
}

const DeleteButton = (props) => {
  const { item, reduxActionsToUse } = props;
  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  const deleteButtonDisableCondition = item.is_mb_linked || item.submissions_count;
  const deleteButtonTitle = item.submissions_count
    ? mrIntl("ExperienceList.cannot_delete_assessment_with_student_submissions")
    : item.is_mb_linked
      ? mrIntl("ExperienceList.unlink_from_managebac_task_to_delete")
      : "";


  const [modal, contextHolder] = Modal.useModal();
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const [showDeleteConfirmModal, setShowDeleteModal] = useState(false)
  const [isCheckboxChecked, setCheckboxChecked] = useState(false);

  const handleCancel = () => {
    setShowDeleteButton(false);
    setShowDeleteModal(false)
  };

  let finalRender = [];

  const deleteExperienceItem = () => {
    return new Promise((resolve, reject) => {
      dispatch(reduxActionsToUse.update({
        id: item.uid, deleted_at: true
      }, {
        success: { showMessage: false },
        successCallback: (response) => {
          console.log("success callback");
          dispatch(reduxActionsToUse.deleteSuccess(
            { data: { experience: { id: item.id } } },
            {
              success: {
                showMessage: true, message: mrIntl("ExperienceList.test_deleted")
              },
            }
          ))
          resolve()
          setShowDeleteModal(false)
        },
        errorCallback: (error) => {
          reject("Something went wrong, Not able to delete test")
        },
      }));
    })
  }

  const openDeleteExperienceModal = () => {
    setShowDeleteModal(true)
  }
  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
  };

  finalRender.push(
    <Modal
      className="delete-confirm-modal"
      visible={showDeleteConfirmModal}
      closable={false}
      okText={mrIntl("CommonText.delete")}
      onOk={() => deleteExperienceItem()}
      okButtonProps={{
        disabled: !isCheckboxChecked,
        style: {
          backgroundColor: !isCheckboxChecked ? "" : "red",
          color: !isCheckboxChecked ? "" : "white",
        }
      }}
      onCancel={() => handleCancel()}
    >
      <div className="delete-warning">
        <span className="warning-icon"><DeleteOutlined />Permanently <b>DELETE</b> Assessment?</span>
        <br /><br />
        <p>Permanently deleting "<b>{item.name} ({item.access_code})</b>" will: </p>
        <ul>
          <li>Remove access for all user in your school.</li>
          <li>Permanently erase all associated data.</li>
        </ul>
        <Checkbox onChange={handleCheckboxChange}>I understand this action is permanent and cannot be undone</Checkbox>
      </div>
    </Modal>
  )

  finalRender.push(
    <Tooltip
      title={deleteButtonTitle}
      placement="left">
      <Button
        disabled={deleteButtonDisableCondition}
        icon={<DeleteOutlined />}
        type={"text"}
        style={{
          color: deleteButtonDisableCondition ? '#rgb(189 189 190)' : 'red'
        }}
        className="full-width-action-button"  // This class ensures the button occupies the full width of the dropdown menu, making the entire area clickable.
        onClick={openDeleteExperienceModal}>
        {mrIntl("CommonText.delete")}
      </Button>
      {contextHolder}
    </Tooltip>
  )
  return finalRender;
}

const CustomExperienceListItem = (props) => {
  const { item, currentUser, deleted, duplicateResource, index, isLibrary, permission, history, isTodayExperiences } = props;
  const [modal, contextHolder] = Modal.useModal();
  const {path, url} = useRouteMatch();
  const dispatch = useDispatch();
  const appType = useSelector(appTypeSelector())
  const appRegion = useSelector(appRegionSelector())
  const sebConfigFileLoading = useSelector(sebConfigFileLoadingSelector())
  const embedded = useSelector(getEmbeddedSelector());
  const mrIntl = useTranslate();
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const location = useLocation();
  const experienceSettings = item.settings;
  const experienceUser = item.experience_user;

  console.log("useRouteMatch url", url, path, item)
  console.log("sebConfigFileLoading", sebConfigFileLoading)

  const isMobile = checkMob()
  // const isPAD = checkIPAD()
  const serverTimeOffset = useSelector(serverTimeOffsetSelector);
  console.log("ExperienceList serverTimeOffset", serverTimeOffset)

  function setLogs(action) {

    let msg = `Assessment ${item.name} (Join Code: ${item.access_code}) was opened by ${currentUser.name} (${upperFirst(currentUser.role)})`

    if(action === "monitor"){
      msg = `${currentUser.name} (${upperFirst(currentUser.role)}) opened the Monitor Tab for ${item.access_code} ${item.name}.`
    } 
    dispatch(
      firestoreInteractionActions.setLogs({
        logging: true,
        log_type: "experience",
        category: "experience_crud",
        msg: msg,
        itemId: item.id,
        experienceId: item.id
      })
    );
  }

  const [responseAttachmentsModalVisible, setResponseAttachmentsModalVisible] = useState(false)
  // To trigger requests for getting pdf-conversion and auto-mapping progress of experience_user
  const [triggerCheckProgressInterval, setTriggerCheckProgressInterval] = useState(null)
  let isMYPAchievementLevel = item.rubric && item.rubric.type_c === "myp_achievement_level" ? true : false
  let isCommentsOnly = item.rubric && item.rubric.type_c === "comments_only" ? true : false
  let isCustomRubric = item.rubric && item.rubric.type_c === "custom" ? true : false
  let isCriteriaWithPoints = item.rubric && item.rubric.type_c === "criteria_with_points" ? true : false;

  const showExperience = (item, fromResourceName, tab = "default", event, setLogs = null) => {  
    showExperienceHelper(item, fromResourceName, tab, currentUser, history, dispatch, event, location, setLogs)
  }

  const subjectIconsMap = getSubjectIconMap()

  const isStudent = currentUser.role === "student" || currentUser.role === "parent" ? true : false

  let reduxActionsToUse = experienceReduxActions
  if (props.resourceName === "libraryexperiences") {
    reduxActionsToUse = libraryExperienceReduxActions
  } else if (props.resourceName === "todayexperiences") {
    reduxActionsToUse = todayReduxActions
  }

  const subjectIconFileName = subjectIconsMap?.[item?.custom_fields?.subject_item_label?.toLowerCase()];
  const isQuestionMappingEnabled = experienceSettings.mode === "paper";
  const onlyUploadAllowed = currentUser.role === "student" && experienceSettings.mode === "paper";

  const experienceListConfig = {
    subjectIconsExist: item.custom_fields && item.custom_fields.subject_item_label && subjectIconFileName != undefined ? true : false,
    showHlTag: item.relation_items && item.relation_items[0].teaching_level_items.filter(ti => ti.archived === false)[0] && item.relation_items[0].teaching_level_items.filter(ti => ti.archived === false)[0].label === "HL only" ? true : false,
    showSlTag: item.relation_items && item.relation_items[0].teaching_level_items.filter(ti => ti.archived === false)[0] && item.relation_items[0].teaching_level_items.filter(ti => ti.archived === false)[0].label === "SL only" ? true : false,
    showSlHlTag: item.relation_items && item.relation_items[0].teaching_level_items.filter(ti => ti.archived === false)[0] && item.relation_items[0].teaching_level_items.filter(ti => ti.archived === false)[0].label === "SL & HL" ? true : false,
  }

  const actionButtonConfig = {
    showUnarchive: permission.experience && permission.experience.delete && !isStudent && item.master_editable && (item.current_user_can_edit || (currentUser.role === "qb_author" && item.parent_id)) && (props.resourceName === "experiences" || (currentUser.role === "admin" && props.resourceName === "libraryexperiences") || (currentUser.role === "qb_author" && currentUser.custom_fields.can_archive_qb_experiences && props.resourceName === "libraryexperiences" && item.status === "draft")) && item.archived ? true : false,

    showEditButton: permission.experience && permission.experience.update && !isStudent && item.master_editable && (item.current_user_can_edit || (currentUser.role === "qb_author" && item.parent_id)) && !item.archived && !checkMob() && !isTodayExperiences ? true : false,

    showDuplicateButton: permission.experience && permission.experience.create && !isStudent && !isTodayExperiences && !checkMob() && item.experience_type !== "group" && (!isLibrary || currentUser.role === "qb_author") ? true : false,

    showArchiveButton: permission.experience && permission.experience.delete && !isStudent && item.master_editable && (item.current_user_can_edit || (currentUser.role === "qb_author" && item.parent_id)) && (props.resourceName === "experiences" || (currentUser.role === "admin" && props.resourceName === "libraryexperiences") || (currentUser.role === "qb_author" && currentUser.custom_fields.can_archive_qb_experiences && props.resourceName === "libraryexperiences" && item.status === "draft")) && !item.archived && !checkMob() ? true : false,
    
    showEmbedButton: !isStudent && currentUser.role === "qb_author" && permission.experience && permission.experience.read && props.resourceName === "libraryexperiences" && item.uuid && experienceSettings.allow_embed == true && !item.archived && !checkMob() ? true : false,

    showOpenButton: !isStudent && !item.archived && !checkMob() && item.experience_type !== "group",
    
    showDeleteBtn: permission.experience && permission.experience.delete && !isStudent && item.master_editable && (item.current_user_can_edit || (currentUser.role === "qb_author" && item.parent_id)) && (props.resourceName === "experiences" || (currentUser.role === "admin" && props.resourceName === "libraryexperiences") || (currentUser.role === "qb_author" && currentUser.custom_fields.can_archive_qb_experiences && props.resourceName === "libraryexperiences" && item.status === "draft")) && !item.deleted_at && !checkMob() ? true : false,
    showTranslateButton: enabledFeatures.translate_assessment && (currentUser.role === "admin" || currentUser.role === "teacher") && item.experience_type !== "group" && !isStudent
  }

  const autoMappingProgress = experienceUser?.custom_fields["auto_mapping_progress"];
  const shouldPoll = isQuestionMappingEnabled && onlyUploadAllowed && ((autoMappingProgress && !["failed", "completed"].includes(autoMappingProgress["status"])) || triggerCheckProgressInterval);
  console.log("shouldPoll ===>", shouldPoll, experienceUser);

  const updateExperienceUserInExperience = (updatedExperienceUser) => {
    const updatedExperience = {
      ...item,
      experience_user: {
        ...updatedExperienceUser
      }
    }
    dispatch(experienceReduxActions.updateSuccess({data: {experience: updatedExperience }}, {success: {showMessage: false}}))
  }

  // To update automapping progress on student side
  useAutoMappingProgress(experienceUser, shouldPoll, triggerCheckProgressInterval, updateExperienceUserInExperience);

  const submitAttachments = (attachments_json, submitAttachmentsStartCallback, submitAttachmentsCompletedCallback) => {
    console.log("submit attachments ==>", attachments_json)

    submitAttachmentsStartCallback();
    
    const submitData = {
      experience_id: (currentUser.role === "student" && experienceSettings.mode === "paper") ? item.id : experienceUser.experience_id,
      user_id: (currentUser.role === "student" && experienceSettings.mode === "paper") ? currentUser.id : experienceUser.user_id,
      uploads_allowed: false,
      attachments_count: attachments_json.attachments && attachments_json.attachments.length,
      attachments_json: attachments_json,
    };

    if (experienceUser?.id) {
      submitData.id = experienceUser.id 
    }

    dispatch(experienceUserActions.submitAttachments(submitData, {
      success: {showMessage: true, message: mrIntl("ExperienceList.files_submitted_successfully")},
      successCallback: (response) => {
        console.log('response after upload =>', response)
        const finalExperienceUser = formatJsonApiData(response.data.experience_user)
        const updatedExperience = {
          ...item,
          experience_user: {
            ...finalExperienceUser
          }
        }
        dispatch(experienceReduxActions.updateSuccess({data: {experience: updatedExperience }}, {success: {showMessage: false}}))
        setTriggerCheckProgressInterval(getUniqueId())
        submitAttachmentsCompletedCallback(response)
        setResponseAttachmentsModalVisible(false)
      },
      errorCallback: (error) => {
        submitAttachmentsCompletedCallback(error)
      }
    }))
  }

  const recoverAttachments = () => {
    dispatch(experienceUserActions.recoverAttachments({
      experience_id: experienceUser.experience_id,
      user_id: experienceUser.user_id,
    },{
      success: {showMessage: true, message: mrIntl("CommonText.recovered_successfully")},
      successCallback: (response) => {
        console.log('response after upload =>', response)
        const finalExperienceUser = formatJsonApiData(response.data.experience_user)
        const updatedExperience = {
          ...item,
          experience_user: {
            ...finalExperienceUser
          }
        }
        dispatch(experienceReduxActions.updateSuccess({data: {experience: updatedExperience }}, {success: {showMessage: false}}))
        setResponseAttachmentsModalVisible(false)
        setTimeout(() => {
          setResponseAttachmentsModalVisible(true)
        }, 1)
      }
    }))
  }
  
  // < EditTwoTone/>
  let statusIconStyle
  let joinCodeTagColor = "success"
  if(item.status === "published"){
    statusIconStyle = {
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      tooltipTitle: mrIntl("CommonText.published"),
      // tooltipTitle: "Published"
    }
  }
  else if(item.status === "draft"){
    joinCodeTagColor = "error"
    statusIconStyle = {
      icon: <MinusCircleTwoTone twoToneColor="#eb2f96" />,
      tooltipTitle: mrIntl("CommonText.draft"),
      // tooltipTitle: "Draft"
    }
  }
  else if(item.status === "closed"){
    joinCodeTagColor = "default"
    statusIconStyle = {
      icon: <CloseCircleTwoTone twoToneColor="lightslategrey"/>,
      tooltipTitle: mrIntl("CommonText.closed"),}
  }
  const statusIcon = <Tooltip title={statusIconStyle.tooltipTitle}>{statusIconStyle.icon}</Tooltip>
  
  const deliveryModesStaticValues = {
    "online": {
      title: mrIntl("ExperienceList.any_browser"),
      // "title": "Any browser",
      "icon": <CloudTwoTone />
    },
    "online_secure": {
      title: mrIntl("ExperienceList.any_browser_with_security"),
      // "title": "Any browser with security",
      "icon": <SafetyCertificateTwoTone/>
    },
    "online-secure": {
      title: mrIntl("ExperienceList.any_browser_with_security"),
      // "title": "Any browser with security",
      "icon": <SafetyCertificateTwoTone/>
    },
    "offline": {
      title: mrIntl("ExperienceList.lockdown_app"),
      // "title": "Lockdown app",
      "icon": <LockTwoTone twoToneColor="#eb2f96"/>
    },
    "paper": { // Need this without flag - else breaks for already created tests
      title: mrIntl("CommonText.paper_mode"),
      icon:<FileTextTwoTone />
    }
  }
  // const modeIcon = <Tooltip title={experienceSettings.mode == "online" ? "Any browser" : "Lockdown app"}>{experienceSettings.mode == "online" ? <CloudTwoTone/> : <LockTwoTone twoToneColor="#eb2f96" />}</Tooltip>
  // console.log("experienceSettings.mode", item, experienceSettings.mode)
  const modeIcon = experienceSettings.mode && <Tooltip title={deliveryModesStaticValues[experienceSettings.mode].title}>{deliveryModesStaticValues[experienceSettings.mode].icon}</Tooltip>

  
  const isOriginal = <Tooltip title={item.original_id == null ? mrIntl("ExperienceList.original") : mrIntl("ExperienceList.duplicated")}>{item.original_id != null ? <span><CopyOutlined /></span> : <span><TrophyOutlined /></span>}</Tooltip>

  // const infoTooltipTitle = <Space>
  //   <Tag color="green">{item.access_code}</Tag>
  //   {/* <Tag color="red">{experienceSettings.i_code}</Tag> */}
  // </Space>

  // const info = <Tooltip color="white" title={infoTooltipTitle}><InfoCircleOutlined/></Tooltip>

  // const mbLinked = <Tooltip title={"Linked to MB"}>{<Tag color="blue">MB</Tag>}</Tooltip>
  const mbLinked = <Tooltip title={mrIntl("ExperienceList.linked_to_a_manageBac_task")}>{<img alt="MB-logo" style={{"width": "16px"}} src={MBIcon}></img>}</Tooltip>

  // Not using getExperienceDurationForStudent as firestore item is not available here so duration_after_reset is not available
  let displayDuration = experienceSettings.duration;
  if (currentUser.role === "student") {
    const extraTime = getCurrentExtraTime({ accommodations: currentUser?.custom_fields?.accommodations }, experienceSettings.duration) || 0;
    displayDuration = parseInt(experienceSettings.duration) + parseInt(extraTime);
  }
  const duration = <Tooltip
  //  title="Duration"
  title={mrIntl("ExperienceList.duration")}
  ><Tag color="warning" icon={<ClockCircleOutlined />}>{convertToHoursAndMinutes(displayDuration)}</Tag></Tooltip>
  
  const start_at_datetime = experienceSettings.start_at_datetime && <Tooltip title={mrIntl("ExperienceList.start_time")}><Tag color="success" icon={<CalendarOutlined />}>{dayjs(experienceSettings.start_at_datetime).format("MMM DD, hh:mm a")}</Tag></Tooltip>

  const close_at_datetime = experienceSettings.close_at_datetime && <Tooltip title={mrIntl("ExperienceList.close_time")} placement="bottom"><Tag color="error" icon={<CalendarOutlined />}>{dayjs(experienceSettings.close_at_datetime).format("MMM DD, hh:mm a")}</Tag></Tooltip>
  
  const points = <Tooltip title={mrIntl("CommonText.points")}><Tag color="success">{displayPoints(item.points)} {mrIntl("CommonText.pts")}</Tag></Tooltip>
  // const criteriumView = (
  //   <>
  //     <Tag key={`criteria-points`}>A: 20</Tag>
  //     <Tag key={`criteria-points`}>B: 10</Tag>
  //     <Tag key={`criteria-points`}>C: 4</Tag>
  //     <Tag key={`criteria-points`}>D: 3</Tag>
  //   </>
  // );
  
  const canOpenGradeTab = item.current_user_can_grade && !item.archived
  const submissions_count = <Tooltip title={mrIntl("CommonText.submissions")}><Tag style={canOpenGradeTab ? {"cursor":"pointer"} : {}} color="processing" onClick={ (e) => {
    if(canOpenGradeTab ) {
      e.stopPropagation(); showExperience(item, props.resourceName, "grade")
    }
  }}>{item.submissions_count}</Tag></Tooltip>
  
  const student_submitted = isStudent && experienceUser && <Tag color="processing" icon={<CheckOutlined />}>{checkMob() ? "" : `${mrIntl("ExperienceList.submitted_on")} ${dayjs(experienceUser.created_at).format("MMM DD, YYYY HH:mm a")}`}</Tag>
  
  const grading_status = isStudent && experienceUser && ((!experienceUser.marked && <Tag color="warning" icon={<SyncOutlined spin/>}>{mrIntl("CommonText.awaiting_grading")}</Tag>) || experienceUser.marked && <Tag color="success" icon={<CheckOutlined/>}>{mrIntl("ExperienceList.graded")}</Tag>)

  let tooltipTitleUploadBtn = mrIntl("ExperienceList.upload_files")
  if(experienceUser && !experienceUser.uploads_allowed){
    tooltipTitleUploadBtn = mrIntl("CommonText.view_files")
  }else if(item.status === "closed"){
    tooltipTitleUploadBtn = mrIntl("ExperienceList.test_has_been_closed_for_submissions")
  }else if(appType === "seb"){
    tooltipTitleUploadBtn = mrIntl("ExperienceList.cannot_upload_files_from_within_seb")
  }
  const responseAttachmentsBtn =
    ((onlyUploadAllowed || (experienceUser && !experienceUser.marked))) 
      ?
      <UploadFilesButton
        disabled={appType === "seb" || (experienceUser?.uploads_allowed && item.status === "closed")}
        buttonText={!isMobile && (((experienceUser?.uploads_allowed ?? onlyUploadAllowed)) ? mrIntl("ExperienceList.upload") : mrIntl("CommonText.view_files"))}
        shape="round"
        // type={'primary'}
        clicked={() => {
          setResponseAttachmentsModalVisible(true)
        }}
        tooltipTitle={tooltipTitleUploadBtn}
      /> : null
  
  console.log("check experienceUser ===>", {responseAttachmentsModalVisible, onlyUploadAllowed, experienceUser})
  const responseAttachments =
    (responseAttachmentsModalVisible && (experienceUser || onlyUploadAllowed)) 
      ?
      <ResponseAttachments
        answerMode={(experienceUser?.uploads_allowed ?? onlyUploadAllowed)}
        previewType={'modal'}
        maxAttachments={100}
        recordId={`${item.id}`}
        modalVisible={responseAttachmentsModalVisible}
        experienceSettings={experienceSettings}
        attachments_json={experienceUser?.attachments_json || {}}
        deleteNotAllowedMessage={true}
        isQuestionMappingEnabled={isQuestionMappingEnabled}
        listType={isQuestionMappingEnabled ? "text" : null}
        activeExperienceUser={experienceUser || {}}
        onRecoverUploads={() => {
          showConfirmModal({
            centered: true,
            // title: `Recovering attachments`,
            title: mrIntl("ExperienceList.recovering_attachments"),
            // content: 'This will recover all possible missing/deleted files.',
            content: mrIntl("ExperienceList.this_will_recover_all_possible_missing_deleted_files"),
            onOk: () => {
              recoverAttachments()
            },
            mrIntl: mrIntl
          })
        }}
        onSubmit={(attachments_json, options) => {
          const {submitAttachmentsStartCallback, submitAttachmentsCompletedCallback} = options
          showConfirmModal({
            centered: true,
            // title: `Submitting attachments`,
            title: mrIntl("ExperienceList.submitting_attachments"),
            content: mrIntl("ExperienceList.can_not_modify_submission_after_submit_msg"),
            onOk: () => {
              submitAttachments(attachments_json, submitAttachmentsStartCallback, submitAttachmentsCompletedCallback)
            },
            mrIntl: mrIntl
          })
        }}
        closeModal={(changed) => {
          if (changed) {
            showConfirmModal({
              centered: true,
              // title: `Cancel submission`,
              title: mrIntl("ExperienceList.cancel_submission"),
              // content: 'Some attachments are not saved. Are you sure you wish to close?',
              content: mrIntl("ExperienceList.some_attachments_are_not_saved_are_you_sure_you_wish_to_close"),
              onOk: () => {
                setResponseAttachmentsModalVisible(false)
              },
              mrIntl: mrIntl
            })
          } else {
            setResponseAttachmentsModalVisible(false)
          }
        }}
      /> : null
  let subjectTooltipTitle = item.custom_fields.subject_item_label_group ? `${item.custom_fields.subject_item_label_group} > ${item.custom_fields.subject_item_label}` : `${item.custom_fields.subject_item_label}`

  let showQBauthorButNotTeacherIfQBExp = ((currentUser.role === "qb_author" && item.questionbank) || (currentUser.role !== "qb_author" && !item.questionbank) )
  let maxLength = checkMob() ? 25 : 35 
  let title = <Space className="title cursor-pointer">
    {/* {!isLibrary && !isStudent && <span className="color-bbb">{info}</span>} */}
    {!isStudent && item.is_mb_linked && mbLinked}
    {/* {!isStudent && item.experience_type === "group" && <Tag color="warning" style={{"margin":"0","padding": "0px 2px","lineHeight": "1","fontSize": "10px","fontWeight": "600"}}>G</Tag>} */}
    {!isStudent && item.custom_fields.ap_id && <Tooltip title={item.custom_fields.ap_id}><Tag color="error" style={{"margin":"0","padding": "0px 2px","lineHeight": "1","fontSize": "10px","fontWeight": "600"}}><MrTranslate id={"ExperienceList.v1"}/></Tag></Tooltip>}
    {/* {!isStudent && !checkMob() && <span className="color-bbb">{isOriginal}</span>} */}
    {/* <span>{statusIcon}</span> */}
    <span>{modeIcon}</span>
    {item.archived && <span className="archive-icon"><BiArchiveIn /></span>}
    {/* {!isStudent && !checkMob() && showQBauthorButNotTeacherIfQBExp && <Tooltip title="Click to copy Join Code"><Tag color="success" className={"m-0"} onClick={() => {writeToClipboardPolyfill(item.access_code); message.success("Join code copied!")}}>{item.access_code}</Tag></Tooltip>} */}
    {item.custom_fields.subject_item_label && subjectIconFileName && <Tooltip title={subjectTooltipTitle}>{experienceListConfig.subjectIconsExist && <span><img alt="subject-icon" src={`https://v2res1.assessprep.com/manual_uploads/icons/sebo_icons/${subjectIconFileName}`} className="subject-icon"></img></span>}</Tooltip>}

    {item.relation_items && item.relation_items[0] && item.relation_items[0].teaching_level_items && item.relation_items[0].teaching_level_items.filter(ti => ti.archived === false)[0] && !checkMob() && 
    <>
      {experienceListConfig.showHlTag && <Tag color="#41CD6C" className={"teaching-level-tag-icon"}>HL</Tag>}
      {experienceListConfig.showSlTag && <Tag color="#458CFC" className={"teaching-level-tag-icon"}>SL</Tag>}
      {experienceListConfig.showSlHlTag && <><Tag color="#41CD6C" className={"teaching-level-tag-icon"}>HL</Tag><Tag color="#458CFC" className={"teaching-level-tag-icon"}>SL</Tag></>}
    </>}

    <span className="title-text">{item.name.length > maxLength ? <Tooltip title={item.archived && !isStudent ? mrIntl("ExperienceList.this_test_is_archived_unarchive_to_open") : item.name}>{item.name.substr(0,maxLength) + "..."}</Tooltip> : item.name }</span>
  </Space>

  let standardCollectionContentAreaItems = item.relation_items && item.relation_items[0] && item.relation_items[0].standard_collection_content_area_items && item.relation_items[0].standard_collection_content_area_items.filter(ca => ca.archived === false)
  let firstStandardCollectionContentAreaLabel = standardCollectionContentAreaItems && standardCollectionContentAreaItems.length > 0 && standardCollectionContentAreaItems[0].label

  let description = <Space direction="vertical" size={6} className={"custom-list-item-description"}>
    <Space className={"custom-list-item-description-row-1"}>
      {/* {!isStudent && !checkMob() && showQBauthorButNotTeacherIfQBExp && <Tooltip title="Click to copy Join Code" placement="bottom"><Tag color="success" onClick={() => {writeToClipboardPolyfill(item.access_code); message.success("Join code copied!")}}>{item.access_code}</Tag></Tooltip>} */}
      {!isStudent && !checkMob() && currentUser.role === "qb_author" && item.custom_fields.ib_reference_code && <Tag color="yellow" title={mrIntl("ExperienceList.ib_reference_code")}>{item.custom_fields.ib_reference_code}</Tag>}
      
      {/* {(enabledFeatures.allow_select_evaluation_type || import.meta.env.VITE_MODE !== "production") && !isStudent && !checkMob() && experienceSettings.evaluation_type && <Tooltip title={mrIntl("CommonText.evaluation_type")}><Tag color={experienceSettings.evaluation_type === "formative" ? "processing" : experienceSettings.evaluation_type === "summative" ? "warning" : ""} className="evaluation-type">
        {experienceSettings.evaluation_type == "summative" ? mrIntl("CommonText.summative") : mrIntl("CommonText.formative")}
      </Tag></Tooltip>} */}
      
      {!isStudent && !checkMob() && currentUser.role === "qb_author" && item.custom_fields.show_in_osc && <Tooltip title={mrIntl("ExperienceList.showing_in_osc")}><Tag color="blue">{mrIntl("ExperienceList.osc")}</Tag></Tooltip>}
      {!isStudent && !checkMob() && !item.custom_fields.show_in_osc && item.questionbank_title.indexOf("DP") > -1 && <Tooltip title={<span>{mrIntl("ExperienceList.these_are_exams_that_are_not_available_to_students")} <a href="https://www.managebac.com/ib-diploma/system#study-revision" target="blank">{mrIntl("ExperienceList.learn_more")}</a></span>}><Tag color="blue"><MrTranslate
       id={"ExperienceList.ap_only"}/></Tag></Tooltip>}
      {!isStudent && !checkMob() && item.questionbank_title !== 'Schoolbank' && item.questionbank_title}
      {!isStudent && !checkMob() && item.original_questionbank && <Tooltip title={mrIntl("ExperienceList.used_from_this_questionbank")}>{item.original_questionbank.title}</Tooltip>}
      {!isStudent && !checkMob() && item.custom_fields.lesson && item.custom_fields.lesson.name}
      {!isStudent && !checkMob() && item.custom_fields.course && item.custom_fields.course.name}
      {item.custom_fields.grade_item_label && !checkMob() && <span>{item.custom_fields.grade_item_label}  </span>}

      {item.custom_fields.subject_item_label && <Tooltip placement="bottom" title={item.custom_fields.subject_item_label_group ? `Group: ${item.custom_fields.subject_item_label_group}` : ""}>{experienceListConfig.subjectIconsExist && false ? <span><img alt="subject-icon" src={`https://v2res1.assessprep.com/manual_uploads/icons/sebo_icons/${subjectIconFileName}`} style={{width: "15px", marginRight: "5px"}}></img>{item.custom_fields.subject_item_label}</span> : <span>{item.custom_fields.subject_item_label}</span>}</Tooltip>}

      {(enabledFeatures.allow_select_evaluation_type || import.meta.env.VITE_MODE !== "production") && !isStudent && !checkMob() && experienceSettings.evaluation_type && <Tooltip>
      {/* title={mrIntl("CommonText.evaluation_type")} */}
        {experienceSettings.evaluation_type == "summative" ? mrIntl("CommonText.summative") : mrIntl("CommonText.formative")}
      </Tooltip>}

      {/* {item.relation_items && item.relation_items[0] && item.relation_items[0].teaching_level_items && item.relation_items[0].teaching_level_items[0] && !checkMob() && (experienceListConfig.useIconBased && false ? (experienceListConfig.useHlTag ? <Tag color="#41CD6C" style={{padding: "0px", margin: "0px"}}>{"HL"}</Tag> : experienceListConfig.useSlTag ? <Tag color="#458CFC" style={{padding: "0px", margin: "0px"}}>{"SL"}</Tag> : <><Tag color="#41CD6C" style={{padding: "0px", margin: "0px"}}>{"HL"}</Tag><Tag color="#458CFC" style={{padding: "0px", margin: "0px"}}>{"SL"}</Tag></>) : <span>{item.relation_items[0].teaching_level_items[0].label}</span>)} */}
      {/* {!checkMob() && showQBauthorButNotTeacherIfQBExp && <Tooltip title="Created by" placement="bottom"><span>{item.created_by}  </span></Tooltip>} */}
      {/* {!isStudent && showQBauthorButNotTeacherIfQBExp && <Tooltip title="Created on" placement="bottom"><span>{dayjs(item.created_at).format("MMM DD, YYYY")}</span></Tooltip>} */}
      {import.meta.env.VITE_MODE === 'development' && item.custom_fields.ap_id && !isStudent && <Tooltip title={mrIntl("CommonText.last_updated")}><span>{dayjs(item.updated_at).format("MMM DD, YYYY")}</span></Tooltip>}
      {!isStudent && item.rubric && !checkMob() && <span>{item.rubric.title}  </span>}
      {isStudent && checkMob() && start_at_datetime}
      {/* {(import.meta.env.VITE_MODE == 'development' || import.meta.env.VITE_MODE == 'staging') && item.custom_fields.ap_id && <Tag>v1 id: {item.custom_fields.ap_id} </Tag>} */}
    </Space>
    {(currentUser.role === "qb_author" || !item.questionbank) && <Space className={"custom-list-item-description-row-2"}>
      {!checkMob() && !isStudent && showQBauthorButNotTeacherIfQBExp && firstStandardCollectionContentAreaLabel && <Tooltip title="Standard collection content area" placement="bottom"><span>{firstStandardCollectionContentAreaLabel}  </span></Tooltip>}
      {!checkMob() && !isStudent && showQBauthorButNotTeacherIfQBExp && <Tooltip title="" placement="bottom"><span>{item.created_by}  </span></Tooltip>}
      {!isStudent && showQBauthorButNotTeacherIfQBExp && <Tooltip title="" placement="bottom"><span>{dayjs(item.created_at).format("MMM DD, YYYY")}</span></Tooltip>}
    </Space>}
  </Space>

  const confirm = e => {
    e.stopPropagation()
    // deleted(item);
    let successMsgObj = {
      showMessage: true, 
      message: mrIntl("ExperienceList.assessment_archived")
    }
    deleted({...item, id: item.uid}, {success: successMsgObj});
    // let msg = `Test archived by ${currentUser.name} (${upperFirst(currentUser.role)})`;
    // setLogs('archived');
    // dispatch(
    //   firestoreInteractionActions.setUserInfo(
    //     {
    //       id: currentUser.id,
    //       experienceId: item.id,
    //     },
    //     {
    //       log: {
    //         logging: true,
    //         log_type: "experience",
    //         msg: msg,
    //       },
    //     }
    //   )
    // );
    // message.success("Archived successfully");
  };
  const cancel = e => {
    e.stopPropagation()
    // message.error("Delete cancelled");
  };

  let actions = []

  const editBtnEnabled = 
  <Link to={`${url}/${item.uid}/edit`}> 
  {/* <Link to={`/u/tests/${item.uid}/edit`}>  */}
    {/* <Tooltip title={item.status !== "draft" ? (item.submissions_count !== 0 ? mrIntl("ExperienceList.cannot_edit_test_with_submissions") : mrIntl("ExperienceList.cannot_edit_unless_unpublished")) : mrIntl("CommonText.edit")}> */}
      <Button  className="full-width-action-button" type="text" icon={<EditOutlined />} onClick={(event) => event.stopPropagation()}>{mrIntl("CommonText.edit")}</Button> 
    {/* </Tooltip> */}
  </Link> 

  const editBtnDisabled = 
  <Tooltip title={item.status !== "draft" ? (item.submissions_count !== 0 ? mrIntl("ExperienceList.cannot_edit_test_with_submissions") : mrIntl("ExperienceList.cannot_edit_unless_unpublished")) : mrIntl("CommonText.edit")} placement="left">
    <Button type="text" icon={<EditOutlined />} disabled={true} onClickCapture={(event) => {console.log("onclickcapture edit");event.stopPropagation();event.stopPropagation()}}>{mrIntl("CommonText.edit")}</Button>
  </Tooltip>


  const editBtn = item.status === "draft" 
    ? 
    editBtnEnabled
    :
    editBtnDisabled

  const monitorBtn =
    <Tooltip title={mrIntl("ExperienceList.monitor")}>
      <Button shape="round" type="primary" icon={<EyeOutlined />} onClick={(e) => {
        if(!item.archived){
          showExperience(item, props.resourceName, "monitor")
        }
      }}> <MrTranslate id={"ExperienceList.monitor"}/></Button>
    </Tooltip>

  // const previewBtn = <Link to={`/e/tests/${item.id}`} target="_blank"> 
  const previewBtn =
    // <Tooltip title={mrIntl("CommonText.view")}>
      <Button className="full-width-action-button" type="text" icon={<EyeOutlined />} onClick={(e) => handleOpenExperience(e)}> 
      <MrTranslate id={"ExperienceList.open"}/>

      </Button>
    // </Tooltip>
  // </Link> 
  // const previewBtn = <ExperienceDrawerPreview experience={item} />
  
  const archiveBtn = item.is_mb_linked ? (
    <Tooltip title={mrIntl("ExperienceList.unlink_from_managebac_task_to_archive")} placement="left">
      <Button disabled={true} type="text" className="assessments-delete" icon={<BiArchiveIn />}>
        {mrIntl("ExperienceList.archive")}
      </Button>
    </Tooltip>
  ) : (
      <Popconfirm
        key="delete"
        title={mrIntl("CommonText.confirm_archive")}
        zIndex="10000"
        placement="left"
        onConfirm={confirm}
        onCancel={cancel}
        okText={mrIntl("CommonText.yes")}
        cancelText={mrIntl("CommonText.no")}
      >
        <Button type="text" className="assessments-delete full-width-action-button" onClick={(e) => {e.stopPropagation()}} icon={<BiArchiveIn />}>
          {mrIntl("ExperienceList.archive")}
        </Button>
      </Popconfirm>
  );
  
  const deleteBtn = <DeleteButton item={item} reduxActionsToUse={reduxActionsToUse} />
 
  const unarchiveBtn = (
    // <Tooltip title={mrIntl("ExperienceList.unarchive")} placement="bottom">
      <Button
        type="text"
        icon={<BiArchiveOut />}
        className="unarchive-action-button full-width-action-button"
        onClick={() => {
          // let msg = `Test unarchived by ${currentUser.name} (${upperFirst(currentUser.role)})`;
          // setLogs('unarchived')
          // dispatch(
          //   firestoreInteractionActions.setLogs({
          //     logging: true,
          //     log_type: "experience",
          //     msg: msg,
          //     itemId: item.id,
          //     experienceId: item.id
          //   })
          // );
          // dispatch(
          //   firestoreInteractionActions.setUserInfo(
          //     {
          //       id: currentUser.id,
          //       experienceId: item.id
          //     },
          //     {
          //       log: {
          //         logging: true,
          //         log_type: "experience",
          //         msg: msg,
          //       },
          //     }
          //   )
          // );
          dispatch(
            reduxActionsToUse.update(
              { id: item.uid, archived: false },
              { 
                success: {showMessage: false},
                // success: { showMessage: true, message: mrIntl("ExperienceList.assessment_unarchived") },
                successCallback: (response) => {
                  dispatch(reduxActionsToUse.deleteSuccess(
                    { data: { experience: { id: item.id } } },
                    {
                      success: {
                        showMessage: true, message: mrIntl("ExperienceList.assessment_unarchived")
                      },
                    }
                  ))
                },
              }
            )
          );
        }}
      >{mrIntl("ExperienceList.unarchive")}</Button>
    // {/* </Tooltip> */}
  );

  const embedBtn = 
  // <Tooltip title={mrIntl("ExperienceList.embed")}>
    <Button className="full-width-action-button" type="text" icon={<LinkOutlined />} href={`/embed/${item.uuid}`} target="_blank" onClick={(event) => event.stopPropagation()}>{mrIntl("ExperienceList.embed")}</Button>
  // </Tooltip>
  const duplicateBtn = <DuplicateExperienceButton item={item} sourceId={item.uid} index={index} duplicateResource={duplicateResource} isLibrary={isLibrary} showExperience={showExperience} popconfirmPlacement={"left"} withText={(!isLibrary || currentUser.role === "qb_author") && mrIntl("DuplicateExperienceButton.duplicate")}/>
  // combine this onclick below for teacher and student in 1 function
  // let conditionToShowJoinVsStart = isStudent && !experienceUser && experienceSettings.enable_ap_video_monitoring && !checkIfStartTime(item)
  let conditionToShowJoin = isStudent && !experienceUser && experienceSettings.enable_ap_video_monitoring && false && currentTimeValidWrtServerTime(serverTimeOffset) && appType != "ios" //ios not supported jitsi
  // TEMP: hiding join button in mac v10 because not handling security on join click
  if(supportsMacAACSecurity(appType)){
    conditionToShowJoin = false
  }
  // let startTooltipTitle = item.status == "closed" ? "Test closed" : (conditionToShowJoinVsStart ? "Join" : "Start")
  // let startTooltipTitle = item.status === "closed" ? mrIntl("ExperienceList.test_closed") : appVersionStatus && appVersionStatus.status === "unsupported" ? mrIntl("ExperienceList.unsupported_version_of_the_app_please_update_your_app_to_start_this_test") : mrIntl("ExperienceList.start")

  let joinBtn = <Link to={`/e/tests/${item.uid}`} onClick={(e) => {
    showExperience(item, props.resourceName, "default", e)
    }}> 
    <Tooltip 
    //  title="Join video call"  title={mrIntl("CommonText.confirm_archive")}
    title= {mrIntl("ExperienceList.join_video_call")}
     >
      {/* <Button disabled={item.status == "closed" ? true : false} type="primary" shape="round">{conditionToShowJoinVsStart ? "Join" : "Start"}</Button> */}
      <Button  disabled={item.status === "closed" ? true : false} icon={<VideoCameraOutlined />} shape="round"> <MrTranslate id={"CommonText.join"}/></Button>
    </Tooltip>
  </Link> 

  const translateAssessmentBtn = <TranslateAssessment item={item} />
  const isExperienceSubmitted = experienceUser && experienceUser.status === "taken";
  const isPeerReviewEnabled = experienceSettings.peer_review_enabled || false;

  const ReviewButton = (props) => {
    const experienceUser = item.experience_user || {}
    const customFields = experienceUser.custom_fields || {}
    const isReviewingExperienceUsersEmpty = (customFields["reviewing_user_ids"] || []).length === 0;

    let isReviewCloseTimePassed = false;
    if (experienceSettings.reviewer_setting && experienceSettings.reviewer_setting.close_review_at_datetime) {
      const reviewCloseDateTime = Date.parse(experienceSettings.reviewer_setting.close_review_at_datetime);
      const currentTime = new Date().getTime();
      if (reviewCloseDateTime < currentTime) {
        isReviewCloseTimePassed = true;
      }
    }

    const tooltipTitle = 
      isReviewingExperienceUsersEmpty 
        ? "Nothing to review" 
        : isReviewCloseTimePassed 
          ? "Review closed" 
          : "Review"
    const handleReviewButtonClick = (e) => {
      if (isPeerReviewEnabled) {
        showExperience(item, props.resourceName);
        let urlToOpen = `/e/tests/${item.uid}/grade?reviewer=true`; // Should handle this also in showExperience
        history.push(urlToOpen);
      } else {
        showExperience(item, props.resourceName);
      }
    };

    return (
      <Tooltip title={tooltipTitle}>
      <Button 
        shape="round" 
        onClick={(e) => handleReviewButtonClick(e)} 
        disabled={
          isReviewCloseTimePassed 
          || isReviewingExperienceUsersEmpty
        }
      >
        <MrTranslate id={"ExperienceList.review"} />
      </Button>
      </Tooltip>
    );
  };

  const ViewFeedbackButton = () => {
    console.log("item, props.resourceName ==>", item, props.resourceName)
    const viewResultsButtonText = 
      checkMob() 
        ? <MrTranslate id={"ExperienceList.results"} /> 
        : <MrTranslate id={"ExperienceList.view_results"} />

    const handleFeedbackButtonClick = (e) => {
      console.log("on menu item click ==>", e)
      showExperience(item, props.resourceName);
    };

    return (
      <Tooltip title={mrIntl("CommonText.view")}>
        <Button shape="round" type="primary" onClick={(e) => handleFeedbackButtonClick(e)}>
          {viewResultsButtonText}
        </Button>
      </Tooltip>
    );
  }

  const viewFeedbackBtn = <ViewFeedbackButton />

  if(experienceSettings.mode === "offline" && appType === "web"){
    joinBtn = ""
  }
  
  let videoCallButton = 
    <ExternalVideoCallButton
      videoCallLink={experienceSettings.video_call_link}	
      experienceSettings={experienceSettings}	
      showTitle={true}	
      buttonShape={isMobile ? "circle" : "round"}
      experienceId={item.id}
    />

  const actionMenuItems = []
  actionButtonConfig.showOpenButton && actionMenuItems.push(previewBtn)
  actionButtonConfig.showEditButton && actionMenuItems.push(editBtn) 
  actionButtonConfig.showDuplicateButton && actionMenuItems.push(duplicateBtn)
  actionButtonConfig.showUnarchive && actionMenuItems.push(unarchiveBtn)
  actionButtonConfig.showArchiveButton && actionMenuItems.push(archiveBtn)
  actionButtonConfig.showEmbedButton && actionMenuItems.push(embedBtn)
  actionButtonConfig.showDeleteBtn && actionMenuItems.push(deleteBtn)
  actionButtonConfig.showTranslateButton && actionMenuItems.push(translateAssessmentBtn)

// actions.push(<Button shape="circle" icon={<EditOutlined/>} />)
  // if(!isStudent && !item.archived && !checkMob() && item.experience_type !== "group"){
  //   if(isTodayExperiences){
  //     actions.push(monitorBtn)
  //   }
  //   else{
  //     actions.push(previewBtn)
  //   }
  // }
  if(permission.experience && permission.experience.update && item.master_editable && !isStudent && !item.archived && !checkMob() && item.experience_type === "group" && currentUser.role === "qb_author" && currentUser.email === "karan_qb@apschool.com"){
    actions.push(<ExperienceStatusBtn experience={item} />)
  }
  
  // permission.experience && permission.experience.update && !isStudent && item.master_editable && (item.current_user_can_edit || (currentUser.role === "qb_author" && item.parent_id)) && !item.archived && !checkMob() && !isTodayExperiences &&  actions.push(editBtn)
  // TODO: above parent_id condition is temp
  // permission.experience && permission.experience.create && !isStudent && currentUser.role != 'qb_author' && !isTodayExperiences && actions.push(duplicateBtn) //disabled for Meg for now
  // Not having create permission because We are allowing duplicate in case of crimson in library 
  isLibrary && currentUser.role !== "qb_author" && !isStudent && !isTodayExperiences && !checkMob() && item.experience_type !== "group" && actions.push(duplicateBtn)
  // permission.experience && permission.experience.delete && !isStudent && item.master_editable && (item.current_user_can_edit || (currentUser.role === "qb_author" && item.parent_id)) && (props.resourceName === "experiences" || (currentUser.role === "qb_author" && currentUser.custom_fields.can_archive_qb_experiences && props.resourceName === "libraryexperiences" && item.status === "draft")) && !item.archived && !item.is_mb_linked && !checkMob() && actions.push(deleteBtn)
  // current_user_can_edit not being set for children experiences in qb author ib dp exams
  // permission.experience && permission.experience.delete && !isStudent && item.master_editable && (item.current_user_can_edit || (currentUser.role === "qb_author" && item.parent_id)) && (props.resourceName === "experiences" || (currentUser.role === "admin" && props.resourceName === "libraryexperiences") || (currentUser.role === "qb_author" && currentUser.custom_fields.can_archive_qb_experiences && props.resourceName === "libraryexperiences" && item.status === "draft")) && item.archived && actions.push(unarchiveBtn)
  // !isStudent && currentUser.role === "qb_author" && permission.experience && permission.experience.read && props.resourceName === "libraryexperiences" && item.uuid && experienceSettings.allow_embed == true && !item.archived && !checkMob() && actions.push(embedBtn)
  // isStudent && item.attempts == 0 && actions.push(startBtn) // add canStart based on start time and close time

  if (!enabledFeatures.new_join_view_with_dynamic_config) {
    isStudent && !experienceUser && item.status === "published" && experienceSettings.include_video_call_link && experienceSettings.video_call_link && actions.push(videoCallButton)
  }
  actionMenuItems.length && actions.push(<ListActionsMenu
    dropdownKey={`${item.id}-more-menu-drop-down`}
    menuItems={actionMenuItems} triggerType={"click"}>
    <Button shape="circle" icon={<BsThreeDots />} type="text" className="action-menu-dropdown" onClick={(event) => event.stopPropagation()} onContextMenu={(e) => e.stopPropagation()}/>
  </ListActionsMenu>)
  console.log("checkIfStartTime(item)", serverTimeOffset, currentTimeValidWrtServerTime(serverTimeOffset), checkIfStartTime(item))
  // if(((isStudent && !experienceUser && currentTimeValidWrtServerTime(serverTimeOffset) && checkIfStartTime(item)) || conditionToShowJoinVsStart)){
  if(conditionToShowJoin){
    let targetTime = experienceSettings.start_at_datetime
    let targetTimeUTC = Date.parse(targetTime)
    let currentTimeUTC = new Date().getTime()
    if(targetTime && targetTimeUTC > currentTimeUTC){
      if(targetTimeUTC - currentTimeUTC > 30*60*1000){
        // 30 mins in milliseconds
        // setFinalRender(showBeforeCountDownStarts)
      }else{
        actions.push(joinBtn)
      }
    }
    else{
      actions.push(joinBtn)
    }
    // actions.push(joinBtn)
  }
  if (!onlyUploadAllowed) {
    if(enabledFeatures.new_join_view_with_dynamic_config) {
      actions.push(<JoinTestButton key={`join-test-button-${item.id}`} item={item} history={history} showExperience={showExperience} serverTimeOffset={serverTimeOffset} resourceName={props.resourceName} />)
    } else {
      actions.push(<StartTestButtonOld key={`start-test-button-${item.id}`} item={item} serverTimeOffset={serverTimeOffset} history={history} showExperience={showExperience} buttonText="Start" />)
    }
  }

  const experienceMarked =
    isStudent && experienceUser && experienceUser.marked;

  // NOTE: We should show review button for parents isStudents handles same
  experienceMarked && isStudent && isExperienceSubmitted && actions.push(viewFeedbackBtn);

  // NOTE: We should not show review button for parents
  if (isPeerReviewEnabled && currentUser.role === "student" && isExperienceSubmitted) { 
    actions.push(<ReviewButton />);
  }

  const avatar = <Space direction="vertical" className="experience-list-avatar" size={0}>
    {statusIcon}
    {!isStudent && !checkMob() && showQBauthorButNotTeacherIfQBExp && <Tooltip 
    
    // title="Click to copy Join Code"
     title= {mrIntl("ExperienceList.click_to_copy_join_code")}
     placement="bottom"><Tag color={joinCodeTagColor} onClick={(e) => { e.stopPropagation(); writeToClipboardPolyfill(item.access_code); message.success(mrIntl("ExperienceList.join_code_copied"))}}>{item.access_code}</Tag></Tooltip>}
  </Space>

  const handleOpenExperience = (e, isListItem = false) => {
    console.log("onClick list item ====>", e, item);
    if (!item.archived && !isStudent) {
      // let msg = `Test  by ${currentUser.name} (${upperFirst(currentUser.role)})`;
      // setLogs('opened')
      // dispatch(
      //   firestoreInteractionActions.setLogs({
      //     logging: true,
      //     log_type: "experience",
      //     msg: msg,
      //     itemId: item.id,
      //     experienceId: item.id
      //   })
      // );
      // dispatch(
      //   firestoreInteractionActions.setUserInfo(
      //     {
      //       id: currentUser.id,
      //       experienceId: item.id
      //     },
      //     {
      //       log: {
      //         logging: true,
      //         log_type: "experience",
      //         msg: msg,
      //       },
      //     }
      //   )
      // );
      if(isListItem) {
        const isClassExist = checkParentElementHasClassNames(e.target, ["teacher-list-item"])
        // need to check isClassExist. To stop onClick event. when open duplicate modal
        if (!isClassExist) {
          return false;
        }
      }

      if (isTodayExperiences) {
        showExperience(item, props.resourceName, "monitor", e, setLogs)
      } 
      // If current user can edit permission is off the we are showing view tab in every case, so we not need to open grade tab in any case.
      // else if (item.current_user_can_grade && !item.current_user_can_edit) {
      //   showExperience(item, props.resourceName, "grade", e, setLogs)
      // }
       else {
        showExperience(item, props.resourceName, "default", e, setLogs);
      }
    }
  }

  let listItem = <List.Item
  className={isStudent ? "student-list-item" : `teacher-list-item  ${item.experience_type === "group" && "group-list-item" }`}
  key={`experience-list-item-${item.id}`}
  actions={actions}
  onClick={(e) => handleOpenExperience(e, true)}
  >
    <List.Item.Meta
      // avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
      // avatar={modeIcon}
      // avatar={isStudent ? false : statusIcon}
      avatar={isStudent ? false : avatar}
      title={title}
      // description="Individuals & Societies | Grade 11 | Abhimanyu Jhajharia"
      description={description}
    />
    {/* <Space className="experience-extra-info"> */}
      {/* <Tag>{experienceSettings.grading_type}</Tag> */}
      {/* <Tag>{dayjs(experienceSettings.start_at_datetime).format("MMM DD, YYYY")}</Tag> */}
      {!isStudent && !isLibrary && !checkMob() && <Space direction={"vertical"}>
        {start_at_datetime}
        {experienceSettings.is_auto_close && close_at_datetime}
      </Space>}
      {isStudent && !checkMob() && !experienceUser && <Space direction={"vertical"}>
        {/* {start_at_datetime} */} 
        {experienceSettings.is_auto_close && close_at_datetime}
      </Space>}
      {experienceSettings.is_timed && experienceSettings.duration > 0 && !experienceUser && !checkMob() && duration}
      {!isStudent && !isMYPAchievementLevel && !isCommentsOnly && !isCustomRubric && !isCriteriaWithPoints && item.points && !experienceUser && !checkMob() && points}
      {/* {criteriumView} */}
      {/* {!isStudent && (!isLibrary || (isLibrary && currentUser.role === "support")) && item.submissions_count !== 0 && submissions_count} */}
      {!isStudent && item.submissions_count !== 0 && submissions_count}
      {isStudent && student_submitted}
      {isStudent && !checkMob() && grading_status}
      {/* IMP: isStudent means parent also now */}
      {((currentUser.role === "student" && student_submitted && experienceSettings.allow_student_upload_files) || onlyUploadAllowed) && responseAttachmentsBtn}
      {((currentUser.role === "student" && student_submitted && experienceSettings.allow_student_upload_files) || onlyUploadAllowed) && responseAttachments}
    {/* </Space> */}
  </List.Item>

  listItem =
    actionMenuItems.length ? <ListActionsMenu
      dropdownKey={`${item.id}-context-menu`}
      menuItems={actionMenuItems} triggerType={"contextMenu"}>
      {listItem}
    </ListActionsMenu> : listItem

  let experienceChildrenList = null
  if(item.nested_children && item.nested_children.length > 0){
    experienceChildrenList = <List 
      className={`custom-main-list experiences-list no-min-height indented ${checkMob() ? 'mobile' : ''}`}
      // loading={initLoading}
      // locale={{emptyText: "No assessments here"}}  
      itemLayout="horizontal"
      dataSource={item.nested_children}
      // dataSource={experiencesToShow}
      renderItem={(item, i) => (
        <CustomExperienceListItem item={item} currentUser={currentUser} deleted={deleted} duplicateResource={duplicateResource} index={i} isLibrary={isLibrary} permission={permission} history={history} isTodayExperiences={isTodayExperiences} resourceName={props.resourceName}/>
      )}
    />
  }
  let finalRender = null
  if(isStudent){
    finalRender = listItem
  }
  else{
    finalRender = <div> 
      {listItem}
      {experienceChildrenList}
    </div>
  }

  if (!isStudent && !checkMob() && props.resourceName === "libraryexperiences" && item.custom_fields.is_new) {
    finalRender = 
      <Badge.Ribbon text="New" color="red" placement="start" size="small" className="experience-new-badge">
        {finalRender}
      </Badge.Ribbon>
  }

  return finalRender
}

const serverTimeOffsetSelector = getServerTimeOffsetSelector()
const ExperienceList = (props) => {
  console.log("ExperienceList props", props);
  const { dataSource, permission, currentUser, deleted, duplicateResource, history, params, pagination} = props;
  const isTodayExperiences = props.resourceName === "todayexperiences" ? true : false
  const appType = useSelector(appTypeSelector())
  const appRegion = useSelector(appRegionSelector())
  const partnerName = useSelector(partnerNameSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const orgQBs = useSelector(orgquestionbanksSelector())
  const mrIntl = useTranslate()
  const { isStandardPlan, hasMaxOrPlusPlan } = getCurrentPlanStatus(currentUser);

  const dispatch = useDispatch()
  const setServerTimeCallback = (offset) => {
    console.log("setServerTimeCallback offse", offset)
    dispatch(experienceReduxActions.setServerTimeOffsetSuccess({serverTimeOffset: offset}))
  }

  // let experiencesToShow = [...dataSource] 
  // console.log("ExperienceList dataSource", experiencesToShow);
  // if(appType == "seb" && dataSource.length != 0){
  //   experiencesToShow = dataSource.filter(exp => exp.settings.mode == "offline" && !exp.experience_user)
  //   console.log("ExperienceList dataSource", experiencesToShow);
  // }

  useEffect(() => {
    getServerTimeOffset(setServerTimeCallback)

    // NOTE: Not checking server time offset periodically as when system goes to sleep it can go out of sync too. So checking only once on page load, Inside test its already checking periodically
    // checkOffsetInterval("on", setServerTimeCallback)

    if (appType === "mac" || appType === "ios") {
      setToLS("changeSecurityGuid", null)
      setToLS("changeSecurityValue", null)
      // To reset local storage value of quitRequested need to set it to false on page load on list page
      dispatch(offlineAppActions.quitrequestedSuccess({ quitRequested: false }));
      dispatch(experienceReduxActions.setSwitchFromStartToResumeViewSuccess({switchFromStartToResumeView: true}))  // Making sure resume view is shown on next visit for offline tests when non offline test opened before it
    }

    return () => {
      // checkOffsetInterval("off", setServerTimeCallback)
    }
  }, [appType]);  

  const serverTimeOffset = useSelector(serverTimeOffsetSelector);
  console.log("ExperienceList serverTimeOffset", serverTimeOffset)


  // const startingParamsSet = params.by_starting ? true : false
  let isLibrary = false
  if(props.isLibrary){
    isLibrary = props.isLibrary
  }

  let showExperienceList = true
  if((currentUser.role === "teacher" || 
      currentUser.role === "admin") && 
      (isLibrary &&  
        currentUser.permission && 
        ((!params.by_questionbank_id &&
          (currentUser.permission.schoolbank_experience && 
          currentUser.permission.schoolbank_experience.list === false)) ||
        (params.by_questionbank_id && 
          (currentUser.permission.questionbank_experience &&
          currentUser.permission.questionbank_experience.list === false)))
  )){
    showExperienceList = false
  }
  console.log("showExperienceList", showExperienceList, currentUser.permission, params)

  const { url } = useRouteMatch();
  console.log("exp list item url", url);

  // const handleTabClick = (key, event) => {
  //   // history.push(`${url}/${key}`)
  //   props.setParams({ by_student_view: key });
  // };

  const TimeAlertMessage = () => (
    <>
      <MrTranslate id={"CommonText.your_system_time_is_out_of_sync_not_be_able_to_start_tests"}/> {" "} <a href="https://drive.google.com/drive/u/2/folders/1FR5_R5ANMSMCAWTfCa27mqHX3GBb7kOw" target="_blank" rel="noopener noreferrer">Please click this link to learn how to fix this issue.</a>
    </>
  )

  let finalRender = null;
  const macAppLatestVersion = getLatestAppVersion("mac", enabledFeatures)
  const sebAppLatestVersion = getLatestAppVersion("seb")
  // const appUpdateMessage = <span>AssessPrep v7.0.1 for Mac OS has been released. Please download the latest version  <Button type="primary" icon={<AppleOutlined />} onClick={(e)=>{
  //   dispatch(offlineAppActions.executeMethod({key: "openLinkInBrowser", value: "https://tinyurl.com/4yxyv7er"}))
  // }} >Download update</Button></span>
  const macAppUpdateMessage = <span>{mrIntl("ExperienceList.assessprep_mac_app_latest_version_for_mac_os_has_been_released_please_install", {macAppLatestVersion: macAppLatestVersion})}  <Button type="primary" icon={<AppleOutlined />} href={getOfflineAppDownloadUrl('mac', appRegion, enabledFeatures)} target="_blank" >{mrIntl("ExperienceList.download_update")}</Button></span>
  const sebAppUpdateMessage = <span>{mrIntl("ExperienceList.seb_app_latest_version_for_windows_81_and_10_has_been_released", {sebAppLatestVersion: sebAppLatestVersion})} <Button type="primary" icon={<WindowsOutlined />} href={getOfflineAppDownloadUrl('win10', appRegion)} target="_blank" >{mrIntl("ExperienceList.download_update")}</Button></span>
  const isStudent = currentUser.role === "student"
  const isStudentOrParent = isStudent || currentUser.role === "parent";
  const dataSourceLength = props.dataSource.length > 0
  const searchFilterValue = props.filterProps.search !== undefined && props.filterProps.search !== ''
  const overViewSearchFilterMsg = searchFilterValue ? mrIntl("ExperienceList.unable_to_find_the_assessment_ensure_your_search_terms_and") : mrIntl("CommonText.no_data")
  const archiveFilterMsg = props.filterProps.only_archived
    ? mrIntl("ExperienceList.no_archived_assessments_match_your_search")
    : mrIntl("ExperienceList.cant_find_your_assessment")
  const searchAllAssessmentsMessage = searchFilterValue
    ? props.filterProps.only_archived
      ? mrIntl("ExperienceList.currently_displaying_results_from_archived")
      : mrIntl("ExperienceList.search_excludes_archived_assessments") : ""
  const description = props.resourceName === "todayexperiences" ? overViewSearchFilterMsg : searchFilterValue ? archiveFilterMsg : mrIntl("CommonText.no_data")
  const isAPMYPBankEnabled = orgQBs && orgQBs.filter(oqb => oqb.custom_fields?.title === "AP MYP").length > 0 ? true : false
  const selectedQBTitle = orgQBs && orgQBs.find(oqb => oqb.questionbank_id === parseInt(params.by_questionbank_id))?.custom_fields?.title
  const showDummyList = isLibrary && dataSource.length > 0 && isStandardPlan && !hasMaxOrPlusPlan && !isAPMYPBankEnabled && selectedQBTitle === "AP MYP Standard"
  
  

  finalRender = (
    <React.Fragment>
      {/* TODO: bring back when new releases go out */}
      {/* {appType === "web" && deviceInfo.os === "Mac OS" && currentUser.role === "student" && partnerName != "21k" && <Alert className={"app-update-announcement-alert"} message={macAppUpdateMessage} type="warning" showIcon />} */}
      {/* {appType === "web" && deviceInfo.os === "Windows" && currentUser.role === "student" && partnerName != "21k" && <Alert className={"app-update-announcement-alert"} message={sebAppUpdateMessage} type="warning" showIcon />} */}

      {currentUser.role === "student" &&
        currentTimeValidWrtServerTime(serverTimeOffset) === false && (
          <Alert message={<TimeAlertMessage />} type="error" showIcon />
        )}

      {showExperienceList ? (
        <>
          <div style={{ minHeight: "600px", width: "100%" }}>
            <List
              className={`custom-main-list experiences-list ${
                checkMob() ? "mobile" : ""
              }`}
              style={{ minHeight: "0px" }}
              // loading={initLoading}
              // locale={{emptyText: "No assessments here"}}
              itemLayout="horizontal"
              dataSource={dataSource}
              locale={{
                emptyText: (
                  <Empty
                    description={
                      isStudentOrParent
                        ? mrIntl("CommonText.no_data")
                        : description
                    }
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ),
              }}
              // header={<span>{pagination.totalCount} assessments found</span>}
              // dataSource={experiencesToShow}
              renderItem={(item, i) => (
                <CustomExperienceListItem
                  item={item}
                  currentUser={currentUser}
                  deleted={deleted}
                  duplicateResource={duplicateResource}
                  index={i}
                  isLibrary={isLibrary}
                  permission={permission}
                  history={history}
                  isTodayExperiences={isTodayExperiences}
                  resourceName={props.resourceName}
                />
              )}
            />
            {showDummyList && (
              <DummyListItemsForUpgradePlan />
            )}
          </div>
        </>
      ) : (
        <Empty
          style={{ paddingTop: "40px", paddingBottom: "400px", width: "100%" }}
          description={mrIntl(
            "ExperienceList.you_do_not_have_permission_to_access_assessments_please"
          )}
        />
      )}
    </React.Fragment>
  );

  // const safeExamBrowserObject = window.SafeExamBrowser || {};
  // const sebSecurity = safeExamBrowserObject.security || {}
  // const configKeyFromJSAPI = sebSecurity.configKey;
  // const {validKey, hashedKeys} = checkIfSEBConfigIsValid(configKeyFromJSAPI, ["572ae5203d144a36cc2a4db6521c71be0ebd2bc7fde77378078f6ada941d4e55"])
  // const hashedKeysMap = hashedKeys.map((h) => {
  //   return <p>{h.sha2}</p>
  // })

  return (
    <>
      {!isStudentOrParent && dataSourceLength && searchFilterValue && props.resourceName != "todayexperiences" && <Alert
      message={searchAllAssessmentsMessage}
      type="warning"
      showIcon
      />}
      {/* {appType === "seb" && <p>configKeyFromJSAPI: {configKeyFromJSAPI}</p>}
      {appType === "seb" && <p>href: {window.location.href}</p>}
      {appType === "seb" && <p>hashedKeys: {hashedKeysMap}</p>} */}
      {finalRender}
    </>
    )
    ;
};



ExperienceList.defaultProps = {};

ExperienceList.propTypes = {};

export default ExperienceList;
