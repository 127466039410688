import React, { useRef, useCallback, useEffect, useState } from 'react';
// import { fabric } from 'fabric';
import { Button, Spin } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { debounce } from 'lodash';
import LoadingCanvas from '/src/components/UI/Spinner/LoadingCanvas'
import Modal from "antd/lib/modal/Modal";
import { printModeSelector } from '/src/views/Experiences/selector';

import loadable from '@loadable/component'
import { useSelector } from 'react-redux';
const fabricLib = loadable.lib(() => import(/* webpackChunkName: "fabric" */ 'fabric'))

export const useFabric = (onChange) => {
  return useCallback((node) => {
    console.log('canvas node =>', node)
    const fabric = window.fabric
    if (fabric && node) {
      const canvas = new fabric.Canvas(node, {
        width: 700,
        height: 450
      });

      if (typeof (ResizeObserver) == 'function') {
        const resizeObserver = new ResizeObserver((element) => {
          if (canvas.resizeCanvas) {
            canvas.resizeCanvas()
          }
          // console.log("resize observer is observing ==>", element)
        })
        resizeObserver.observe(canvas.wrapperEl.parentElement)
      } else {
        if (canvas.resizeCanvas) {
          canvas.resizeCanvas()
        }
      }

      if (onChange) {
        onChange(canvas);
      }
    }
  }, []);
};

export const globalCanvasConfig = {
  shapeStyle: {
    fill: 'rgba(73, 189, 212, 0.5)',
    strokeColor: 'rgba(73, 189, 212, 0.5)',
    borderColor: 'rgba(73, 189, 212, 1)',
    strokeWidth: 2,
    strokeDashArray: [0, 0],
    left: 100,
    top: 100,
    perPixelTargetFind: true
  },
  hotspotStyle: {
    fill: 'rgba(68, 157, 72, 0.5)',
    strokeColor: 'rgba(68, 157, 72, 1)',
    borderColor: 'rgba(68, 157, 72, 1)',
    strokeWidth: 2,
    strokeDashArray: [0, 0]
  }
}

export const hotspotShapes = ["Rectangle", "Circle", "Polygon", "Path"];

export const FabricCanvas = props => {
  console.log('Fabric canvas props ==>', props);
  const fabric = window.fabric
  const { canvas, canvasModal, pngBase64, saveCanvasData, maxAllowedComment, showLoader, loaderMessage, refreshCanvas, autoSetActiveImageAfterInsert = false } = props;
  const { openModal = () => { } } = props
  const { handleOk = () => { } } = props
  const printMode = useSelector(printModeSelector())

  if (canvas.colorPickerColor) {
    canvas.freeDrawingBrush.color = canvas.colorPickerColor;
    canvas.shapeStyle.strokeColor = canvas.colorPickerColor;
    canvas.shapeStyle.fillColor = canvas.colorPickerColor;
  }

  const padding = 5;
  // const shapeStyle = {
  //   fill: 'rgba(73, 189, 212, 0.5)', // Static value 
  //   fillColor: 'rgba(73, 189, 212, 1)', // Dynamic value
  //   strokeColor: 'rgba(73, 189, 212, 1)',
  //   borderColor: 'rgba(73, 189, 212, 1)',
  //   strokeWidth: 2,
  //   strokeDashArray: [0, 0],
  //   left: 100,
  //   top: 100,
  //   perPixelTargetFind: canvasConfig.disablePerPixelTargetFind ? false : true,
  //   ...canvasConfig.shapeStyle
  // }

  const answerStyle = {
    fill:
    {
      right: 'rgba(68, 157, 72, 0.5)',
      wrong: 'rgba(255, 87, 34, 0.5)'
    },
    strokeColor:
    {
      right: 'rgba(68, 157, 72, 1)',
      wrong: 'rgba(255, 87, 34, 1)'
    }
  }

  const respMarkStyle = {
    radius: 8,
    fontSize: 10,
    fill: 'rgba(255, 255, 255, 1)',
    strokeColor: 'rgba(255, 255, 255, 1)',
    strokeWidth: 2,
    background: {
      fill: {
        right: 'rgba(68, 157, 72, 1)',
        wrong: 'rgba(255, 87, 34, 1)'
      },
      strokeColor: {
        right: 'rgba(68, 157, 72, 1)',
        wrong: 'rgba(255, 87, 34, 1)'
      }
    },
    text: {
      fill: {
        right: 'rgba(68, 157, 72, 1)',
        wrong: 'rgba(255, 87, 34, 1)'
      },
      strokeColor: {
        right: 'rgba(68, 157, 72, 1)',
        wrong: 'rgba(255, 87, 34, 1)'
      }
    }
  }

  const commentIndicatorStyle = {
    svg: {
      fill: 'rgb(255, 69, 0, 0.8)',
      strokeColor: 'rgb(255, 69, 0, 1)',
      strokeWidth: 2,
      background: 'rgb(255, 69, 0, 1)',
    },
    text: {
      fontSize: 12,
      fill: 'rgb(255, 255, 255, 1)',
      strokeColor: 'rgb(255, 255, 255, 1)',
      strokeWidth: 2,
      background: 'rgb(255, 255, 255, 1)',
    }
  }

  const containerRectStyle = {
    fill: 'rgba(255, 255, 255, 0.5)',
    stroke: 'rgba(73, 189, 240, 0.5)',
    strokeWidth: 2
  }

  const controlPointStyle = {
    radius: 4,
    fill: 'rgba(73, 189, 212, 0.5)',
    strokeColor: 'rgba(73, 189, 212, 1)',
    strokeWidth: 2,
    strokeDashArray: [0, 0]
  };

  const labelHolderPointerRadius = 4
  const labelHolderBoxToPointerDist = { left: 15, top: 0 }
  const labelHolderStyle = {
    color: {
      'default': 'rgba(255, 255, 255, 0.3)',
      'ready': 'rgba(73, 189, 212, 0.3)',
      'filled': 'rgba(255, 255, 255, 0.3)'
    },
    fill: 'rgba(255, 255, 255, 0.5)',
    stroke: 'rgba(73, 189, 212, 1)',
    strokeWidth: 2
  }

  const polylineArrowStyle = {
    fill: 'rgba(255, 255, 255, 0)',
    stroke: 'rgba(73, 189, 212, 1)',
    strokeWidth: 2
  };

  const lineArrowStyle = {
    fill: 'rgba(255, 255, 255, 0)',
    stroke: 'rgba(73, 189, 212, 1)',
    strokeWidth: 2
  }
  const textFontSize = 18;
  const textBgColors = 'rgba(255, 255, 255, 0.5)'
  const holderBoxToPointerDist = { left: 15, top: 0 }

  const charsLimit = {
    count: 70,
    msg: "Can't write more than 70 characters in a Label. Please add another Label if you need to."
  }


  function getContainerRectProps(object) {
    let props = {};
    if (object.originX === 'right') {
      props['left'] = object.left + padding;
      props['top'] = object.top;
    } else {
      props['left'] = object.left - padding;
      props['top'] = object.top;
    }
    props['width'] = object.getScaledWidth() + 2 * padding;
    props['height'] = object.getScaledHeight() + 2 * padding;
    return props
  }

  function getLabelHolderProps(object) {
    let props = {};
    if (object.originX === 'right') {
      props['left'] = object.left + padding;
      props['top'] = object.top;
    } else {
      props['left'] = object.left - padding;
      props['top'] = object.top;
    }
    props['width'] = object.getScaledWidth() + 2 * padding;
    props['height'] = object.getScaledHeight() + 2 * padding;
    return props
  }

  function getTransformedPoints(obj) {
    let d, matrix, objectPoints, points, transformedPath, transformedPoints;
    matrix = obj.calcTransformMatrix();
    // // console.log('get Transeformed points ==>', obj)
    if (obj.type === "polygon" || obj.type === "polyline") {
      d = obj._calcDimensions();
      transformedPoints = obj.get('points').map(function (p) {
        return new fabric.Point(p.x - d.left - (obj.width / 2), p.y - d.top - (obj.height / 2));
      }).map(function (p, index) {
        return fabric.util.transformPoint(p, matrix);
      });
      obj.transformedPoints = transformedPoints;
      obj.points = transformedPoints;
    } else if (obj.type === "path") {
      // d = obj._parseDimensions();
      // transformedPath = obj.get('path').map(function (p) {
      //   return [p[0], new fabric.Point(p[1] - d.left - (obj.width / 2), p[2] - d.top - (obj.height / 2)), new fabric.Point(p[3] - d.left - (obj.width / 2), p[4] - d.top - (obj.height / 2))];
      // }).map(function (p, index) {
      //   return [p[0], (fabric.util.transformPoint(p[1], matrix)).x, (fabric.util.transformPoint(p[1], matrix)).y, (fabric.util.transformPoint(p[2], matrix)).x, (fabric.util.transformPoint(p[2], matrix)).y];
      // });
      // obj.set('path', transformedPath);
      // obj.transformedPath = transformedPath;
    } else if (obj.type === "line") {
      points = obj.calcLinePoints();
      objectPoints = [
        {
          x: points.x1,
          y: points.y1
        }, {
          x: points.x2,
          y: points.y2
        }
      ];
      transformedPoints = objectPoints.map(function (p, index) {
        return fabric.util.transformPoint(p, matrix);
      });
      obj.set('x1', transformedPoints[0].x);
      obj.set('y1', transformedPoints[0].y);
      obj.set('x2', transformedPoints[1].x);
      obj.set('y2', transformedPoints[1].y);
    }
    fixPolyGrabControlOnUpdate(obj);
  };

  function fixPolyGrabControlOnUpdate(obj) {
    let d;
    // // console.log('object and object type ==>', obj.type === 'polyline')
    if (obj.type == 'polyline' || obj.type == 'polygon') {
      d = obj._calcDimensions();
      obj.set({
        left: d.left + obj.width / 2,
        top: d.top + obj.height / 2,
        width: d.width,
        height: d.height,
        pathOffset: {
          x: d.left + obj.width / 2,
          y: d.top + obj.height / 2
        },
        scaleX: 1,
        scaleY: 1,
        flipX: false,
        flipY: false
      });
      obj.setCoords();
    } else if (obj.type === 'path') {
      // d = obj._parseDimensions();
      // obj.set({
      //   width: d.width,
      //   height: d.height,
      //   pathOffset: {
      //     x: d.left + obj.width / 2,
      //     y: d.top + obj.height / 2
      //   },
      //   scaleX: 1,
      //   scaleY: 1,
      //   flipX: false,
      //   flipY: false
      // });
      // obj.setCoords();
      return obj;
    }
  };

  function elementIsOutOfViewport(el) {
    let rect = el.getBoundingClientRect();
    if ((rect.bottom - rect.height / 2 < 0 || rect.top + rect.height / 2 > window.innerHeight) && rect.height < window.innerHeight) {
      return true;
    }
    return false;
  };

  function scrollCanvasIntoView(el) {
    if (el && elementIsOutOfViewport(el)) {
      return el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start"
      });
    }
  };

  const [showCanvas, setShowCanvas] = useState(false)
  useEffect(() => {
    if (!window.fabric) {
      fabricLib.load().then(() => {
        setShowCanvas(true)
      })
    } else {
      setShowCanvas(true)
    }
  }, [])

  useEffect(() => {
    if (canvas.fabricReady) {
      // NOTE: Adding any values to canvas.prototype will mutate across all canvases on page
      fabric.Canvas.prototype.jsonPropToAdd = [
        'id',
        'name',
        'role',
        'minWidth',
        'minHeight',
        'maxWidth',
        'maxHeight',
        'padding',
        'opacity',
        'hasControls',
        'hasBorders',
        'lockMovementX',
        'lockMovementY',
        'selectable',
        'lockScalingFlip',
        'perPixelTargetFind',
        'customType',
        'holdingObjectId',
        'objectPlaced',
        'holderFilled',
        'hasRotatingPoint',
        'src_backup',
        'online_src',
        'offline_src',
        'hoverCursor',
        'evented',
        'angle',
        'controls',
        'connectedObjectsIds',
        'controlIndx',
        'arrowHead',
        'objectCaching',
        'notDeletable',
        'placeholder',
        'hasCustomControlPoints',
        // 'resources',
        'checkedHotspot',
        'disablePerPixelTargetFind',
      ];
      fabric.Canvas.prototype.selection = false;
      fabric.Canvas.prototype.preserveObjectStacking = true;
      fabric.Canvas.prototype.targetFindTolerance = 5;
      // fabric.Canvas.prototype.resources = [];
      fabric.Canvas.prototype.undoDisable = true;
      fabric.Canvas.prototype.redoDisable = true;
      // fabric.Canvas.prototype.canvasInstancesForUndo = ['{}'];
      // fabric.Canvas.prototype.canvasInstancesForRedo = [];
      fabric.Canvas.prototype.toolType = {
        line: false,
        arrow: false,
        rectangle: false,
        circle: false,
        polygon: false,
        path: false,
        textLabel: false,
        setHotspot: false,
        deleteObject: false,
      }
      fabric.Object.prototype.role = canvas.role
      fabric.Object.prototype.originX = 'center';
      fabric.Object.prototype.originY = 'center';
      fabric.Object.prototype.cornerSize = 10;
      fabric.Object.prototype.cornerStyle = 'circle';
      fabric.Object.prototype.cornerColor = 'rgba(73, 189, 212, 0.5)';
      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerStrokeColor = 'rgba(73, 189, 240, 1)';
      fabric.Object.prototype.connectedObjectsIds = [];
      
      fabric.IText.prototype.selectionColor = 'rgba(73, 189, 212, 0.5)';
      
      fabric.Canvas.prototype.shapeStyle = globalCanvasConfig.shapeStyle
      fabric.Canvas.prototype.hotspotStyle = globalCanvasConfig.hotspotStyle;
      
      // Init Canvas or Object with function () {} only
      fabric.Canvas.prototype.getObjectsByAttr = function (props) {
        let canvas = this;
        let objArr = []
        canvas.forEachObject(function (obj, indx) {
          let available = true
          for (const key in props) {
            if (obj[key] != props[key]) {
              available = false
            }
          }
          if (available) {
            objArr.push(obj)
          }
        })
        return objArr
      };
      
      fabric.Canvas.prototype.resizeCanvas = function () {
        let canvas = this;
        if (canvas && canvas.wrapperEl) {
          const orientation = canvas.orientation || "landscape"
          const initialCanvasWidth = orientation === "landscape" ? 700 : 450 
          const initialCanvasHeight = orientation === "landscape"  ? 450 : 700

          let width = canvas.wrapperEl.parentElement.offsetWidth
      
          let min_width = 250;
          let max_width = 1000;
          if(printMode){
            max_width =  700;
          }
      
          if (width < min_width) {
            width = min_width;
          }
          if (width > max_width) {
            width = max_width;
          }
      
          let height = canvas.wrapperEl.parentElement.offsetHeight
      
          let min_height = 400;
          let max_height = 1000;
      
          if (height < min_height) {
            height = min_height;
          }
          if (height > max_height) {
            height = max_height;
          }
          let z = width / initialCanvasWidth
          canvas.setZoom(1)
          canvas.setZoom(z)
          canvas.setWidth(width)
      
          if (canvas.parentHeight) {
            canvas.setHeight(height)
          } else if (!canvas.noHeightUpdate) {
            let currentHeight = z * initialCanvasHeight
            canvas.setHeight(currentHeight);
          }
      
          canvas.renderAll();
        }
      };
      fabric.Canvas.prototype.addShape = function (type) {
        let canvas = this
        if (type == 'iText') {
          const shape = new fabric.IText("Write here...", {
            left: 100,
            top: 100,
            width: 50,
            height: 100,
            placeholder: 'Write Here..',
            fontSize: textFontSize,
            textAlign: 'left',
            padding: padding,
            centeredScaling: true,
            hasRotatingPoint: true,
            lockScalingFlip: true,
            name: 'Text',
            id: getUniqueId(),
          })
          canvas.add(shape);
        } else if (type == 'rect' && !canvas.drawMode) {
          const shape = new fabric.Rect({
            left: 100,
            top: 100,
            width: 100,
            height: 100,
            originX: 'center',
            originY: 'center',
            fill: canvas.shapeStyle.fill,
            stroke: canvas.shapeStyle.strokeColor,
            strokeDashArray: canvas.shapeStyle.strokeDashArray,
            strokeWidth: canvas.shapeStyle.strokeWidth,
            name: 'Rectangle',
            hasRotatingPoint: true,
            perPixelTargetFind: canvas.shapeStyle.perPixelTargetFind,
            id: getUniqueId(),
          })
          canvas.add(shape);
        } else if (type == 'circle' && !canvas.drawMode) {
          const shape = new fabric.Circle({
            left: 100,
            top: 100,
            radius: 50,
            fill: canvas.shapeStyle.fill,
            stroke: canvas.shapeStyle.strokeColor,
            strokeDashArray: canvas.shapeStyle.strokeDashArray,
            strokeWidth: canvas.shapeStyle.strokeWidth,
            originX: 'center',
            originY: 'center',
            name: 'Circle',
            hasRotatingPoint: true,
            perPixelTargetFind: canvas.shapeStyle.perPixelTargetFind,
          })
          canvas.add(shape);
        }
      }
      
      fabric.IText.prototype.limitChars = function (charsLimit) {
        let obj, canvas, charCount, msg;
        obj = this
        canvas = obj.canvas;
        msg = charsLimit.msg;
        charCount = charsLimit.count;
        obj.paste = function (e) {
          canvas.msg = "";
          if (e.clipboardData.getData('Label Text').length + obj.text.length >= charCount) {
            e.preventDefault();
            canvas.msg = msg;
            obj.hiddenTextarea = null;
            obj.exitEditing();
          }
        };
        obj.on("changed", function (e) {
          canvas.msg = "";
          if (obj.text.length >= charCount) {
            obj.removeChars(obj.selectionStart - 1, obj.selectionEnd);
            canvas.msg = msg;
            obj.hiddenTextarea = null;
            obj.exitEditing();
          }
        });
      };
      
      fabric.Canvas.prototype.fullScreenCanvas = function (id) {
        let canvas, currentHeight, currentWidth, height, width;
        canvas = this;
        height = window.innerHeight;
        width = window.innerWidth;
        canvas.setZoom(1);
        currentWidth = canvas.getWidth();
        currentHeight = canvas.getHeight();
        if (height > width) {
          canvas.setZoom(width / currentWidth);
        } else {
          canvas.setZoom(height / currentHeight);
        }
        canvas.setWidth(width);
        canvas.setHeight(height);
        canvas.setBackgroundColor('rgba(255, 255, 255, 1)');
        canvas.renderAll();
      };
      
      fabric.Canvas.prototype.exitTextEditing = function (e) {
        let canvas;
        canvas = this;
        if (canvas) {
          canvas.getObjectsByAttr({ type: 'i-text' }).forEach(function (obj) {
            if (obj.isEditing) {
              obj.exitEditing();
              obj.selectable = true;
              canvas.renderAll();
            }
          });
        }
      };
      
      fabric.Canvas.prototype.insertImages = function (files, imageType, callback) {
        let canvas = this
      
        const orientation = canvas.orientation || "landscape"
        const initialCanvasWidth = orientation === "landscape" ? 700 : 450 
        const initialCanvasHeight = orientation === "landscape"  ? 450 : 700
      
        files.map((file, index) => {
          const image_url = `${file.url}?x-request=xhr`
          console.log("Insert image to canvas ==>", file, image_url)
          fabric.Image.fromURL(image_url, (image) => {
            // fabric.util.loadImage(image_url, (img) => {
            // const canvasZoom = canvas.getZoom();
            // const canvasWidth = (canvas.getWidth() - 2) / canvasZoom;
            // const canvasHeight = (canvas.getHeight() - 2) / canvasZoom;
      
            let imageLeft = initialCanvasWidth / 2 + 1
            let imageTop = initialCanvasHeight / 2 + 1
      
            // console.log("canvasZoom, canvasWidth, canvasHeight ==>")
      
            // image.width = 100
            // image.height = 100
            // var image = new fabric.Image(img);
            console.log("from url insert image ==>", image, image.scaleX, image.scaleY)
            image.set('id', getUniqueId())
            image.set('originX', 'center').set('originY', 'center')
            image.set('src_backup', image_url).set('online_src', image_url).set('offline_src', image_url)
            image.set('allowedToGoOutsideCanvas', true)
            image.src = image_url
            canvas.add(image)
      
            if (imageType === 'image') {
              image.scaleToWidth(300, true)
              image.set('name', 'Image')
            } else if (imageType === 'bgImage') {
              const bgImageIndex = canvas.getObjectsByAttr({ name: 'Background Image' }).length
              canvas.moveTo(image, bgImageIndex)
              image.scaleToWidth(500, true)
              image.set('name', 'Background Image')
              imageTop = image.getScaledHeight() / 2 + 2
            } else if (imageType == 'fullSizeBGImage') {
              canvas.moveTo(image, 0)
              
              image.scaleToWidth(initialCanvasWidth, true)
              // if (image.width > image.height) {
              // } else {
              //   image.scaleToHeight(500, true)
              // }
              image.set('name', 'Background Image')
              imageLeft = image.getScaledWidth() / 2 + 2
              imageTop = image.getScaledHeight() / 2 + 2
            }
      
            image.set('left', imageLeft).set('top', imageTop)
            console.log("Image inserted in canvas ==>", image, image.getScaledWidth())
            callback(image, index)

            // Selecting last uploaded image
            if (autoSetActiveImageAfterInsert && index === files.length - 1) {
              canvas.setActiveObject(image);
              canvas.updateCurrentTool("move", true);
            }
          }, {
            crossOrigin: imageType === 'fullSizeBGImage' ? 'anonymous' : ''
          })
        })
      }
      
      // fabric.Image.prototype.toObject = (function(toObject) {
      //   return function() {
      //     return fabric.util.object.extend(toObject.call(this), {
      //       src: this.toDataURL()
      //     });
      //   };
      // })(fabric.Image.prototype.toObject);
      
      // fabric.Image.fromObject = function (object, callback) {
      //   fabric.util.loadImage(object.src, (function (img, error) {
      //     if (!img || error) {
      //       callback && callback(null, error);
      //       return;
      //     }
      //     fabric.Image.prototype._initFilters.call(object, object.filters, function (filters) {
      //       object.filters = filters || [];
      //       fabric.Image.prototype._initFilters.call(object, [object.resizeFilter], function (resizeFilters) {
      //         let elHeight, elWidth, image, scaleX, scaleY;
      //         object.resizeFilter = resizeFilters[0];
      //         if (typeof object.version === 'undefined') {
      //           elWidth = img.naturalWidth || img.width;
      //           elHeight = img.naturalHeight || img.height;
      //           scaleX = (object.scaleX || 1) * object.width / elWidth;
      //           scaleY = (object.scaleY || 1) * object.height / elHeight;
      //           object.width = elWidth;
      //           object.height = elHeight;
      //           object.scaleX = scaleX;
      //           object.scaleY = scaleY;
      //         }
      //         image = new fabric.Image(img, object);
      //         callback(image);
      //       });
      //     });
      //   }), null, object.crossOrigin);
      // };
      
      fabric.Object.prototype.Clone = function (object) {
        let obj, toGroup;
        if (this.type === 'group') {
          toGroup = [];
          this.getObjects().forEach(function (o) {
            return toGroup.push(o.clone());
          });
          obj = new fabric.Group(toGroup);
          obj.left = this.left;
          obj.top = this.top;
        } else {
          obj = this.clone();
        }
        return obj;
      };
      
      fabric.Object.prototype.hide = function () {
        this.set({
          opacity: 0,
          selectable: false
        });
      };
      
      fabric.Object.prototype.show = function () {
        this.set({
          opacity: 1,
          selectable: true
        });
      };
      
      fabric.Canvas.prototype.stopToGoOutsideCanvas = function (obj) {
        let bound, canvasBound, currentHeight, currentWidth, maxHeight, maxWidth, objHeight, objWidth, objectBound;
        let canvas = this
      
        if (!obj || obj.allowedToGoOutsideCanvas || canvas.allowObjectsToGoOutsideCanvas) {
          return;
        }
        obj.setCoords();
        let z = canvas.getZoom();
        let canvasWidth = canvas.getWidth() / z;
        let canvasHeight = canvas.getHeight() / z;
        // canvasWidth = canvas.getWidth();
        // canvasHeight = canvas.getHeight();
        bound = obj.getBoundingRect(true);
        objWidth = bound.width;
        objHeight = bound.height;
      
        if (obj.name === 'Background Image') {
          maxWidth = canvasWidth - 50;
          maxHeight = canvasWidth - 50;
          currentWidth = obj.getScaledWidth();
          currentHeight = obj.getScaledHeight();
          if (currentWidth > maxWidth) {
            obj.set({
              scaleX: maxWidth / obj.width
            });
          }
          if (currentHeight > maxHeight) {
            obj.set({
              scaleY: maxHeight / obj.height
            });
          }
        }
      
        canvasBound = {
          tl: {
            x: 10,
            y: 10
          },
          br: {
            x: canvasWidth - 10,
            y: canvasHeight - 10
          }
        };
        objectBound = {
          tl: {
            x: bound.left,
            y: bound.top
          },
          br: {
            x: bound.left + bound.width,
            y: bound.top + bound.height
          }
        };
        if (objWidth > canvasWidth || objHeight > canvasHeight) {
          return;
        }
      
        console.log('when stoping to go outside ==>', objectBound, canvasBound)
        if (objectBound.tl.y < canvasBound.tl.y) {
          if (obj.originY === 'top') {
            obj.top = Math.max(obj.top, 0);
          } else if (obj.originY === 'center') {
            obj.top = Math.max(obj.top, canvasBound.tl.x + objHeight / 2);
          } else if (obj.originY === 'bottom') {
            obj.top = Math.max(obj.top, canvasBound.tl.x + objHeight);
          }
        }
        if (objectBound.tl.x < canvasBound.tl.x) {
          if (obj.originX === 'left') {
            obj.left = Math.max(obj.left, 0);
          } else if (obj.originX === 'center') {
            obj.left = Math.max(obj.left, canvasBound.tl.x + objWidth / 2);
          } else if (obj.originX === 'right') {
            obj.left = Math.max(obj.left, canvasBound.tl.x + objWidth);
          }
        }
        if (objectBound.br.y > canvasBound.br.y) {
          if (obj.originY === 'top') {
            obj.top = Math.min(obj.top, canvasBound.br.y - objHeight);
          } else if (obj.originY === 'center') {
            obj.top = Math.min(obj.top, canvasBound.br.y - objHeight / 2);
          } else if (obj.originY === 'bottom') {
            obj.top = Math.min(obj.top, canvasBound.br.y);
          }
        }
        if (objectBound.br.x > canvasBound.br.x) {
          if (obj.originX === 'left') {
            obj.left = Math.min(obj.left, canvasBound.br.x - objWidth);
          } else if (obj.originX === 'center') {
            obj.left = Math.min(obj.left, canvasBound.br.x - objWidth / 2);
          } else if (obj.originX === 'right') {
            obj.left = Math.min(obj.left, canvasBound.br.x);
          }
        }
        obj.setCoords();
      };
      
      fabric.Canvas.prototype.rotateImage = function (direction, object) {
        let canvas = this;

        const orientation = canvas.orientation || "landscape"
        const initialCanvasWidth = orientation === "landscape" ? 700 : 450 
        const initialCanvasHeight = orientation === "landscape"  ? 450 : 700

        object = canvas.getObjectsByAttr({ type: 'image' })[0];
        if (object) {
          console.log("object angle before rotation ==>", object.angle)
          let d = direction === 'right' ? 1 : -1
          let rotateAngle = object.angle + (90 * d)
          // const canvasZoom = canvas.getZoom();
          // const canvasWidth = (canvas.getWidth() - 2) / canvasZoom;
          // const canvasHeight = (canvas.getHeight() - 2) / canvasZoom;
          if (rotateAngle === (360 * d)) {
            rotateAngle = 0
          }
      
          let objectLeft = object.left
          let objectTop = object.top
          object.set('angle', rotateAngle)
          console.log("object, rotate angle, canvasWidth ==>", object, rotateAngle)
      
          if (rotateAngle === (90 * d) || rotateAngle === (270 * d)) {
            object.scaleToHeight(initialCanvasWidth, true)
            // if (object.width > object.height) {
            //   object.scaleToWidth(canvasHeight - 2, true)
            // } else {
            //   object.scaleToHeight(canvasHeight - 2, true)
            // }
          } else {
            object.scaleToWidth(object.width, true)
            object.scaleToWidth(initialCanvasWidth, true)
            // if (object.width > object.height) {
            //   object.scaleToWidth(object.width, true)
            //   object.scaleToWidth(canvasWidth - 2, true)
            // } else {
            //   object.scaleToHeight(object.height, true)
            //   object.scaleToHeight(canvasWidth - 2, true)
            // }
          }
          if (object.name == 'Background Image') {
            // if (object.width > object.height) {
            //   objectTop = (object.getScaledHeight() * canvas.getZoom()) / 2 + 2
            // } else {
            //   objectTop = (object.getScaledWidth() * canvas.getZoom()) / 2 + 2
            // }
            if (rotateAngle === (90 * d) || rotateAngle === (270 * d)) {
              objectLeft = object.getScaledHeight() / 2 + 2
              objectTop = object.getScaledWidth() / 2 + 2
            } else {
              objectLeft = object.getScaledWidth() / 2 + 2
              objectTop = object.getScaledHeight() / 2 + 2
            }
            console.log("Background image object top is ==>", objectTop,  object.getScaledWidth(), object.getScaledHeight())
            object.set("left", objectLeft).set("top", objectTop)
          }
          canvas.renderAll()
          canvas.fire('object:modified', {target: object});
        }
      }
      
      fabric.Canvas.prototype.bringToFrontObject = function (object) {
        let canvas = this;
        let objCurrentPosition, objPosition;
        object = canvas.getActiveObject();
        objCurrentPosition = canvas.getObjects().indexOf(object);
        if (object && !object.notDeletable) {
          if (object.name === 'Background Image') {
            objPosition = canvas.getObjectsByAttr({ name: 'Background Image' }).length - 1;
            canvas.moveTo(object, objPosition);
          } else {
            objPosition = canvas.getObjects().length - 1;
            canvas.moveTo(object, objPosition);
            canvas.getObjectsByAttr({ connectedObjectId: object.id }).forEach(function (co) {
              if (co.customType == 'label-holder') {
                canvas.moveTo(co, objPosition - 2);
              } else if (co.customType == 'container-rect') {
                canvas.moveTo(co, objPosition - 1);
              }
            });
          }
          if (objCurrentPosition !== objPosition) {
            canvas.saveStateOnChange();
          }
          canvas.renderAll()
        }
      };
      fabric.Canvas.prototype.sendToBackObject = function (object) {
        let canvas = this;
        let objCurrentPosition, objPosition;
        object = canvas.getActiveObject();
        objCurrentPosition = canvas.getObjects().indexOf(object);
        if (object && !object.notDeletable) {
          if (object.name === 'Background Image') {
            objPosition = 0;
            canvas.moveTo(object, objPosition);
          } else {
            objPosition = canvas.getObjectsByAttr({ name: 'Background Image' }).length;
            canvas.getObjectsByAttr({ connectedObjectId: object.id }).forEach(function (co) {
              if (co.customType == 'label-holder') {
                canvas.moveTo(co, objPosition);
                return objPosition++;
              } else if (co.customType == 'container-rect') {
                canvas.moveTo(co, objPosition);
                return objPosition++;
              }
            });
            canvas.moveTo(object, objPosition);
          }
          if (objCurrentPosition !== objPosition) {
            canvas.saveStateOnChange();
          }
          canvas.renderAll()
        }
      };
      fabric.Canvas.prototype.zoomIn = function (object) {
        // let activeObject, zoomToX, zoomToY, zoomValue;
        // zoomValue = this.getZoom();
        // activeObject = this.getActiveObject();
        // if (activeObject) {
        //   zoomToX = activeObject.left;
        //   zoomToY = activeObject.top;
        // } else if (pointer.x && pointer.y) {
        //   zoomToX = pointer.x;
        //   zoomToY = pointer.y;
        // } else {
        //   zoomToX = this.width / 2;
        //   zoomToY = this.height / 2;
        // }
        // this.zoomToPoint({
        //   x: zoomToX,
        //   y: zoomToY
        // }, zoomValue * 1.25);
        // return this.renderAll();
      };
      fabric.Canvas.prototype.zoomOut = function (object) {
        // let activeObject, zoomToX, zoomToY, zoomValue;
        // zoomValue = this.getZoom();
        // activeObject = this.getActiveObject();
        // if (activeObject) {
        //   zoomToX = activeObject.left;
        //   zoomToY = activeObject.top;
        // } else if (pointer.x && pointer.y) {
        //   zoomToX = pointer.x;
        //   zoomToY = pointer.y;
        // } else {
        //   zoomToX = this.width / 2;
        //   zoomToY = this.height / 2;
        // }
        // this.zoomToPoint({
        //   x: zoomToX,
        //   y: zoomToY
        // }, zoomValue / 1.25);
        // return this.renderAll();
      };
      fabric.Object.prototype.getControlPoint = function (x, y, indx) {
        let object = this
        let innerPoint, outerPoint, control;
        outerPoint = new fabric.Circle({
          radius: controlPointStyle.radius,
          fill: controlPointStyle.fill,
          stroke: controlPointStyle.strokeColor,
          originX: 'center',
          originY: 'center'
        });
        innerPoint = new fabric.Circle({
          radius: controlPointStyle.radius / 2,
          fill: controlPointStyle.strokeColor,
          originX: 'center',
          originY: 'center'
        });
        control = new fabric.Group([outerPoint, innerPoint], {
          left: x,
          top: y,
          hasControls: false,
          hasBorders: false,
          hoverCursor: 'move',
          // type: 'custom-control',
          customType: 'custom-control',
          name: object.name + ' Control',
          perPixelTargetFind: true,
          connectedObjectsIds: [object.id],
          controlIndx: indx,
          notDeletable: true,
          id: getUniqueId(),
          // excludeFromExport: true
        });
        // // console.log('this one is control')
        return control;
      };
      fabric.Object.prototype.showControlPoints = function () {
        let points, control;
        let object = this
        let canvas = object.canvas;
      
        if (object && object.hasCustomControlPoints) {
          object.hideControlPoints();
          let connectedObjectsIds = object.connectedObjectsIds
          if (object.type === 'polygon' || object.type === 'polyline') {
            points = object.points;
            points.forEach(function (point, indx) {
              control = object.getControlPoint(point.x, point.y, indx);
              canvas.add(control);
              control.bringForward(true);
              connectedObjectsIds.push(control.id);
            });
            object.set('connectedObjectsIds', connectedObjectsIds);
          } else if (object && object.type === 'line') {
            points = [{ x: object.x1, y: object.y1 }, { x: object.x2, y: object.y2 }];
            points.forEach(function (point, indx) {
              control = object.getControlPoint(point.x, point.y, indx);
              canvas.add(control);
              control.bringForward(true);
              connectedObjectsIds.push(control.id);
            });
            object.set('connectedObjectsIds', connectedObjectsIds);
          }
        }
      };
      
      fabric.Object.prototype.hideControlPoints = function () {
        let object = this;
        let canvas = object.canvas;
        if (object && object.hasCustomControlPoints) {
          object.connectedObjectsIds = object.connectedObjectsIds.filter((id) => {
            let co = canvas.getObjectsByAttr({ id: id })[0]
            if (co && co.customType == 'custom-control') {
              canvas.remove(co);
            } else {
              return id
            }
          });
        }
      };
      
      fabric.Canvas.prototype.undoLastObject = function () {
        let canvas, lastInstance, undo_length;
        canvas = this;
        if (canvas.noUndo) {
          return
        }
        console.log("canvas and canvas instances ==>", canvas, canvas.canvasInstancesForUndo)
        undo_length = canvas.canvasInstancesForUndo.length;
        if (undo_length > 1) {
          lastInstance = canvas.canvasInstancesForUndo.pop();
          canvas.canvasInstancesForRedo.push(lastInstance);
          const previousInstance = canvas.canvasInstancesForUndo[undo_length - 2] || '{}';
          canvas.loadFromJSON(JSON.parse(previousInstance), function () {
            canvas.renderAll();
          });
          canvas.redoDisable = false;
        }
        if (undo_length <= 2) {
          canvas.undoDisable = true;
        }
      };
      
      fabric.Canvas.prototype.redoLastObject = function () {
        let canvas, lastInstance, redo_length;
        canvas = this;
        if (canvas.noRedo) {
          return
        }
        redo_length = canvas.canvasInstancesForRedo.length;
        if (redo_length > 0) {
          lastInstance = canvas.canvasInstancesForRedo.pop() || '{}';
          canvas.canvasInstancesForUndo.push(lastInstance);
          canvas.loadFromJSON(JSON.parse(lastInstance), function () {
            canvas.renderAll();
          });
          canvas.undoDisable = false;
        }
        if (redo_length <= 1) {
          canvas.redoDisable = true;
        }
      };
      
      fabric.Canvas.prototype.saveStateOnChange = function () {
        let canvas, canvasInstance;
        canvas = this;
        canvas.undoDisable = false;
        canvas.redoDisable = true;
        console.log("canvas save state on change ==>", canvas.canvasInstancesForUndo)
        if (canvas.jsonPropToAdd && canvas.canvasInstancesForUndo) {
          canvasInstance = JSON.stringify(canvas.toJSON(canvas.jsonPropToAdd));
          canvas.canvasInstancesForUndo.push(canvasInstance);
          if (canvas.canvasInstancesForUndo.length > 50) {
            canvas.canvasInstancesForUndo.shift();
          }
        }
      };
      
      fabric.Canvas.prototype.deleteConnectedObject = function (object) {
        let canvas;
        canvas = this;
        // if (object.name === 'holder') {
        //   canvas.getObjectsByAttr('id', object.holdingObjectId).forEach(function (objectInHolder) {
        //     canvas.remove(objectInHolder);
        //   });
        // }
        // if (object.name === 'Label Text' || object.name === 'image') {
        //   canvas.getObjectsByAttr('holdingObjectId', object.id).forEach(function (labelHolder) {
        //     canvas.remove(labelHolder);
        //   });
        // }
        // canvas.getObjectsByAttr('id', object.id).forEach(function (obj) {
        //   canvas.remove(obj);
        // });
        object.connectedObjectsIds.forEach(function (id) {
          let co = canvas.getObjectsByAttr({ id: id })[0]
          if (co) {
            canvas.remove(co);
            canvas.deleteConnectedObject(co)
          }
        });
        canvas.discardActiveObject().renderAll();
      };
      
      fabric.Canvas.prototype.deleteObject = function () {
        let canvas;
        canvas = this;
        canvas.getActiveObjects().forEach(function (object) {
          if (!object.notDeletable) {
            canvas.deleteConnectedObject(object);
            canvas.remove(object);
            canvas.saveStateOnChange();
          }
        });
        canvas.discardActiveObject().renderAll();
      };
      
      fabric.Canvas.prototype.clearAll = function () {
        let canvas;
        canvas = this;
        if (canvas.getObjects().length > 0) {
          canvas.clear();
          canvas.renderAll();
          canvas.saveStateOnChange();
        }
      };
      
      fabric.Object.prototype.updateBadge = function () {
        let object = this
        let canvas = this.canvas
        if (!object || !canvas) {
          return
        }
        let box, objCenter, signLeft, signTop;
      
        if (object.type == 'polygon') {
          if (object.type == 'group') {
            signLeft = object.getObjects()[0].points[0].x;
            signTop = object.getObjects()[0].points[0].y;
          } else {
            signLeft = object.points[0].x;
            signTop = object.points[0].y;
          }
        } else if (object.type == 'path' && object.path[0]) {
          // signLeft = object.path[0][1];
          // signTop = object.path[0][2];
          objCenter = object.getCenterPoint();
          signLeft = objCenter.x - object.width / 2;
          signTop = objCenter.y - object.height / 2;
        } else if (object.type == 'rect') {
          objCenter = object.getCenterPoint();
          signLeft = objCenter.x - object.width / 2;
          signTop = objCenter.y - object.height / 2;
        } else if (object.type == 'circle') {
          objCenter = object.getCenterPoint();
          signLeft = objCenter.x;
          signTop = objCenter.y - object.height / 2;
        } else if (object.customType == 'label-holder') {
          objCenter = object.getCenterPoint();
          if (object.originX == 'right') {
            signLeft = objCenter.x - object.width / 2;
            signTop = objCenter.y - object.height / 2;
          } else {
            signLeft = objCenter.x + object.width / 2;
            signTop = objCenter.y - object.height / 2;
          }
        }
      
        object.badge = object.getConnectedObjectsByAttributes({ customType: 'badge' })[0]
        console.log("Updating tag badge ==>", object.type, object, signLeft, signTop, object.badge)
        if (object.requireBadge && !object.badge) {
          let badge = new fabric.Text(object.commentIndex.toString(), {
            left: signLeft,
            top: signTop,
            originX: 'center',
            originY: 'center',
            selectable: false,
            evented: false,
            customType: 'badge',
            name: 'Badge',
            id: getUniqueId(),
            connectedObjectsIds: [object.id],
          });
          canvas.add(badge)
          object.badge = badge
          object.set('connectedObjectsIds', [badge.id])
        } else if (object.badge) {
          object.badge.set('text', object.commentIndex.toString()).set('left', signLeft).set('top', signTop)
        }
      }
      
      fabric.Object.prototype.correctAnswerStyle = function () {
        let object = this
        let canvas = this.canvas
        if (!object || !canvas) {
          return
        }
        let box, correctMark, correctMarkBadge, correctSign, objCenter, position, signLeft, signTop;
        // object.name = object.name.toLowerCase();
      
        // console.log('we called correct are you ready ==>', object)
        if (object.type == 'polygon') {
          if (object.type == 'group') {
            signLeft = object.getObjects()[0].points[0].x;
            signTop = object.getObjects()[0].points[0].y;
          } else {
            signLeft = object.points[0].x;
            signTop = object.points[0].y;
          }
          object.set('stroke', answerStyle.strokeColor.right);
          object.set('fill', answerStyle.fill.right);
        } else if (object.type == 'path' && object.path[0]) {
          signLeft = object.path[0][1];
          signTop = object.path[0][2];
          object.set('stroke', answerStyle.strokeColor.right);
          object.set('fill', answerStyle.fill.right);
        } else if (object.type == 'rect') {
          objCenter = object.getCenterPoint();
          signLeft = objCenter.x + object.width / 2;
          signTop = objCenter.y - object.height / 2;
          object.set('stroke', answerStyle.strokeColor.right);
          object.set('fill', answerStyle.fill.right);
        } else if (object.type == 'circle') {
          objCenter = object.getCenterPoint();
          signLeft = objCenter.x;
          signTop = objCenter.y - object.height / 2;
          object.set('stroke', answerStyle.strokeColor.right);
          object.set('fill', answerStyle.fill.right);
        } else if (object.customType == 'label-holder') {
          objCenter = object.getCenterPoint();
          if (object.originX == 'right') {
            signLeft = objCenter.x - object.width / 2;
            signTop = objCenter.y - object.height / 2;
          } else {
            signLeft = objCenter.x + object.width / 2;
            signTop = objCenter.y - object.height / 2;
          }
          box = object.getObjects()[2];
          box.set('stroke', answerStyle.strokeColor.right);
          box.set('fill', answerStyle.fill.right);
        }
        correctMark = new fabric.Text('\u221A', {
          fill: respMarkStyle.fill,
          stroke: respMarkStyle.strokeColor,
          fontSize: respMarkStyle.fontSize,
          strokeWidth: respMarkStyle.strokeWidth,
          originX: 'center',
          originY: 'center'
        });
        correctMarkBadge = new fabric.Circle({
          fill: respMarkStyle.background.fill.right,
          stroke: respMarkStyle.background.strokeColor.right,
          radius: respMarkStyle.fontSize,
          originX: 'center',
          originY: 'center'
        });
        correctSign = new fabric.Group([correctMarkBadge, correctMark], {
          left: signLeft,
          top: signTop,
          originX: 'center',
          originY: 'center',
          selectable: false,
          evented: false,
          name: 'Correct Sign',
          id: object.id
        });
        canvas.add(correctSign)
        // position = this.getObjects().indexOf(object) + signsToBeAdded.length + 1;
        // return signsToBeAdded.push({
        //   obj: correctSign,
        //   pos: position
        // });
      };
      
      fabric.Object.prototype.wrongAnswerStyle = function () {
        let object = this
        let canvas = this.canvas
        if (!object || !canvas) {
          return
        }
        let bound, box, objCenter, position, signLeft, signTop, wrongMark, wrongMarkBadge, wrongSign;
        if (object.type == 'polygon') {
          if (object.type == 'group') {
            signLeft = object.getObjects()[0].points[0].x;
            signTop = object.getObjects()[0].points[0].y;
          } else {
            signLeft = object.points[0].x;
            signTop = object.points[0].y;
          }
          object.set('stroke', answerStyle.strokeColor.wrong);
          object.set('fill', answerStyle.fill.wrong);
        } else if (object.type == 'path' && object.path[0]) {
          signLeft = object.path[0][1];
          signTop = object.path[0][2];
          object.set('stroke', answerStyle.strokeColor.wrong);
          object.set('fill', answerStyle.fill.wrong);
        } else if (object.type == 'rect') {
          objCenter = object.getCenterPoint();
          signLeft = objCenter.x + object.width / 2;
          signTop = objCenter.y - object.height / 2;
          object.set('stroke', answerStyle.strokeColor.wrong);
          object.set('fill', answerStyle.fill.wrong);
        } else if (object.type == 'circle') {
          bound = object.getBoundingRect();
          objCenter = object.getCenterPoint();
          signLeft = objCenter.x;
          signTop = objCenter.y - object.height / 2;
          object.set('stroke', answerStyle.strokeColor.wrong);
          object.set('fill', answerStyle.fill.wrong);
        } else if (object.customType == 'label-holder') {
          objCenter = object.getCenterPoint();
          if (object.originX === 'right') {
            signLeft = objCenter.x - object.width / 2;
            signTop = objCenter.y - object.height / 2;
          } else {
            signLeft = objCenter.x + object.width / 2;
            signTop = objCenter.y - object.height / 2;
          }
          box = object.getObjects()[2];
          box.set('stroke', answerStyle.strokeColor.wrong);
          box.set('fill', answerStyle.fill.wrong);
        }
        wrongMark = new fabric.Text('\u00D7', {
          fill: respMarkStyle.fill,
          stroke: respMarkStyle.strokeColor,
          fontSize: respMarkStyle.fontSize,
          strokeWidth: respMarkStyle.strokeWidth,
          originX: 'center',
          originY: 'center'
        });
        wrongMarkBadge = new fabric.Circle({
          fill: respMarkStyle.background.fill.wrong,
          stroke: respMarkStyle.background.strokeColor.wrong,
          radius: respMarkStyle.fontSize,
          originX: 'center',
          originY: 'center'
        });
        wrongSign = new fabric.Group([wrongMarkBadge, wrongMark], {
          left: signLeft,
          top: signTop,
          originX: 'center',
          originY: 'center',
          selectable: false,
          evented: false,
          name: 'Wrong Sign',
          id: object.id
        });
        canvas.add(wrongSign)
        // position = this.getObjects().indexOf(object) + signsToBeAdded.length + 1;
        // return signsToBeAdded.push({
        //   obj: wrongSign,
        //   pos: position
        // });
      };
      // fabric.Canvas.prototype.addSignsToObjects = function () {
      //   let canvas;
      //   canvas = this;
      //   signsToBeAdded.forEach(function (s) {
      //     canvas.moveTo(s.obj, s.pos);
      //   });
      //   signsToBeAdded = [];
      //   canvas.renderAll();
      // };
      
      fabric.Object.prototype.updateContainerRect = function (connectedObjectsIds) {
        let object = this
        let canvas = this.canvas
        if (!object || !canvas) {
          return
        }
        let bound, containerRect, containerRectHeight, containerRectLeft, containerRectTop, containerRectWidth;
        bound = object.getBoundingRect();
        if (object.originX === 'right') {
          containerRectLeft = object.left + padding;
          containerRectTop = object.top;
        } else {
          containerRectLeft = object.left - padding;
          containerRectTop = object.top;
        }
        containerRectWidth = object.getScaledWidth() + 2 * padding;
        containerRectHeight = object.getScaledHeight() + 2 * padding;
        object.containerRect = object.getConnectedObjectsByAttributes({ customType: 'container-rect' })[0]
        console.log('Container rect', object.requireContainerRect, object.containerRect)
        if (object.requireContainerRect && !object.containerRect) {
          let props = getContainerRectProps(object)
          containerRect = new fabric.Rect({
            // left: containerRectLeft,
            // top: containerRectTop,
            // width: containerRectWidth,
            // height: containerRectHeight,
            originX: object.originX,
            originY: object.originY,
            fill: containerRectStyle.fill,
            stroke: containerRectStyle.stroke,
            strokeWidth: containerRectStyle.strokeWidth,
            objectCaching: false,
            strokeLineJoin: 'round',
            hasBorders: false,
            hasControls: false,
            evented: false,
            // type: 'container-rect',
            customType: 'container-rect',
            name: 'Container Rectangle',
            id: getUniqueId(),
            connectedObjectsIds: connectedObjectsIds,
            notDeletable: true
          });
      
          for (const key in props) {
            if (props.hasOwnProperty(key)) {
              containerRect.set(key, props[key]);
            }
          }
          canvas.add(containerRect);
          object.set('containerRect', containerRect)
          object.bringToFront()
          return containerRect
        } else {
          if (object.name === "Label Text" || object.name === "Label Image") {
            let props = getContainerRectProps(object)
            object.connectedObjectsIds.forEach(function (id) {
              let co = canvas.getObjectsByAttr({ id: id })[0]
              if (co && co.customType == 'container-rect') {
                for (const key in props) {
                  if (props.hasOwnProperty(key)) {
                    co.set(key, props[key]);
                  }
                }
                co.set('zoomX', object.zoomX).set('zoomY', object.zoomY);
                co.setCoords();
              }
            });
          }
        }
      }
      fabric.Object.prototype.updateLabelHolder = function (connectedObjectsIds) {
        let object = this
        let canvas = this.canvas
        if (!object || !canvas) {
          return
        }
        let bound, box, boxHeight, boxWidth, direction, labelHolder, labelHolderFlipX, labelHolderLeft, labelHolderMinHeight, labelHolderMinWidth, labelHolderTop, innerPoint, outerPoint, pointer;
      
        labelHolderMinWidth = 80;
        labelHolderMinHeight = 30;
        boxWidth = object.getScaledWidth() + 2 * padding;
        boxHeight = object.getScaledHeight() + 2 * padding;
        object.labelHolder = object.getConnectedObjectsByAttributes({ customType: 'label-holder' })[0]
        console.log("Object label holder is here ==>", object, object.requireLabelHolder, object.labelHolder)
        if (object.requireLabelHolder && !object.labelHolder) {
          if (object.originX === 'right') {
            labelHolderFlipX = true;
            labelHolderLeft = object.left + labelHolderBoxToPointerDist.left + padding;
          } else {
            labelHolderFlipX = false;
            labelHolderLeft = object.left - labelHolderBoxToPointerDist.left - padding;
          }
          labelHolderTop = object.top;
          outerPoint = new fabric.Circle({
            radius: labelHolderPointerRadius,
            fill: labelHolderStyle.fill,
            stroke: labelHolderStyle.stroke
          });
          innerPoint = new fabric.Circle({
            radius: labelHolderPointerRadius / 2,
            fill: labelHolderStyle.stroke
          });
          pointer = new fabric.Group([outerPoint, innerPoint]);
          direction = new fabric.Polyline([
            {
              x: labelHolderBoxToPointerDist.left - outerPoint.radius,
              y: 0
            }, {
              x: 0,
              y: labelHolderMinHeight / 2
            }, {
              x: labelHolderBoxToPointerDist.left - outerPoint.radius,
              y: labelHolderMinHeight
            }
          ], {
            top: 0,
            fill: labelHolderStyle.stroke,
            stroke: labelHolderStyle.stroke,
            strokeWidth: labelHolderStyle.strokeWidth,
            strokeLineJoin: 'round',
            originX: 'left',
            originY: 'center'
          });
          box = new fabric.Rect({
            width: boxWidth,
            height: boxHeight,
            minWidth: labelHolderMinWidth,
            minHeight: labelHolderMinHeight,
            left: labelHolderBoxToPointerDist.left - outerPoint.radius,
            fill: labelHolderStyle.fill,
            stroke: labelHolderStyle.stroke,
            strokeWidth: labelHolderStyle.strokeWidth,
            strokeLineJoin: 'round',
            originX: 'left',
            originY: 'center'
          });
          labelHolder = new fabric.Group([pointer, direction, box], {
            left: labelHolderLeft,
            top: labelHolderTop,
            originX: object.originX,
            originY: object.originY,
            flipX: labelHolderFlipX,
            hasControls: false,
            hasBorders: false,
            perPixelTargetFind: true,
            // type: 'label-holder',
            customType: 'label-holder',
            name: 'Label Holder',
            id: object.unsupportedHolder ? object.unsupportedHolder.id : getUniqueId(),
            holdingObjectId: object.id,
            connectedObjectsIds: connectedObjectsIds,
            objectCaching: false,
            notDeletable: true,
            dirty: true
          });
          canvas.add(labelHolder);
          object.bringToFront();
          object.set('labelHolder', labelHolder)
          return labelHolder
        } else {
          object.connectedObjectsIds.forEach(function (id) {
            let co = canvas.getObjectsByAttr({ id: id })[0]
            if (!co || co.customDisableUpdate) {
              return
            }
            // console.log('found connectObject ==>', co.name, co)
            let coLeft, coTop;
            if (object.name === "Label Text" || object.name === "Label Image") {
              if (co.name === 'Label Holder') {
                if (co.originX === 'right') {
                  coLeft = object.left + labelHolderBoxToPointerDist.left + padding;
                  coTop = object.top;
                } else {
                  coLeft = object.left - labelHolderBoxToPointerDist.left - padding;
                  coTop = object.top;
                }
                co.set('left', coLeft);
                co.set('top', coTop);
                box = co.getObjects()[2];
                co.removeWithUpdate(box);
                box.set('height', boxHeight);
                box.set('width', boxWidth);
                co.addWithUpdate(box);
                co.setCoords();
                object.updateContainerRect()
              }
            } else if (object.name === 'Label Holder') {
              labelHolder = object;
              if (co.name === "Label Text" || co.name === "Image") {
                if (labelHolder.originX === 'right') {
                  coLeft = labelHolder.left - labelHolderBoxToPointerDist.left - padding;
                  coTop = labelHolder.top;
                  if (co.customType == 'container-rect') {
                    coLeft += padding;
                  }
                } else {
                  coLeft = labelHolder.left + labelHolderBoxToPointerDist.left + padding;
                  coTop = labelHolder.top;
                  if (co.customType == 'container-rect') {
                    coLeft -= padding;
                  }
                }
                co.set('left', coLeft);
                co.set('top', coTop);
                co.setCoords();
                co.updateContainerRect()
              }
            }
          });
        }
      }
      fabric.Object.prototype.updateHotspot = function () {
        let object = this
        let canvas = this.canvas
        if (!object || object.name == 'Background Image' || object.customType == 'custom-control') {
          return;
        }
      
        if (object.checkedHotspot) {
          // object.set('fill', canvas.shapeStyle.fill); # overridding with Drawing canvas style
          object.set('fill', 'rgba(73, 189, 212, 0.5)');
          object.set("stroke", canvas.shapeStyle.strokeColor);
          object.set('strokeWidth', canvas.shapeStyle.strokeWidth);
          object.set('checkedHotspot', false);
        } else {
          object.set('fill', canvas.hotspotStyle.fill);
          object.set('stroke', canvas.hotspotStyle.strokeColor);
          object.set('strokeWidth', canvas.hotspotStyle.strokeWidth);
          object.set('checkedHotspot', true);
        }
        object.setCoords();
        canvas.renderAll()
        canvas.saveStateOnChange();
      
        if (canvas._customEventListeners.onUpdateHotspot) {
          canvas._customEventListeners.onUpdateHotspot(object)
        }
      };
      fabric.Canvas.prototype.onToolSelect = function (type, changeCursor) {
        let canvas = this;
        console.log("Type and changecursor ==>", type, changeCursor)
      
        if (changeCursor && canvas.tools[type].drawingTool) {
          canvas.defaultCursor = canvas.tools[type].canvasHoverCursor;
        } else {
          canvas.defaultCursor = 'default';
        }
      
        canvas.forEachObject(function (obj) {
          if (obj.name == 'Temp Polyline') {
            canvas.setActiveObject(obj)
            canvas.deleteObject()
          }
      
          if (obj.notDeletable) {
            return
          }
      
          if (obj.name == 'Background Image' || obj.type == 'image') {
            if (changeCursor) {
              obj.evented = canvas.tools[type].imageEvented;
              obj.hoverCursor = canvas.tools[type].imageHoverCursor;
            } else {
              obj.evented = true;
              obj.hoverCursor = 'move';
            }
          } else {
            if (changeCursor) {
              obj.evented = canvas.tools[type].objectEvented;
              obj.hoverCursor = canvas.tools[type].objectHoverCursor;
              obj.perPixelTargetFind = canvas.tools[type].perPixelTargetFind;
            } else {
              obj.evented = true;
              obj.hoverCursor = 'move';
              obj.perPixelTargetFind = false;
            }
          }
          obj.setCoords()
        });
      };
      
      fabric.IText.prototype._mouseDownHandler = function (options) {
        if (this.text === this.placeholder) {
          this.selectionStart = 0;
          this.selectionEnd = 0;
          this._updateTextarea();
          return;
        }
        if (!this.canvas || !this.editable || (options.e.button && options.e.button !== 1)) {
          return;
        }
        this.__isMousedown = true;
        if (this.selected) {
          this.setCursorByClick(options.e);
        }
        if (this.isEditing) {
          this.__selectionStartOnMouseDown = this.selectionStart;
          if (this.selectionStart === this.selectionEnd) {
            this.abortCursorAnimation();
          }
          return this.renderCursorOrSelection();
        }
      };
      
      fabric.IText.prototype._setEditingProps = function () {
        this.hoverCursor = 'text';
      
        if (this.canvas) {
          this.canvas.defaultCursor = this.canvas.moveCursor = 'text';
        }
      
        this.borderColor = this.editingBorderColor;
      
        this.hasControls = this.selectable = true;
        this.lockMovementX = this.lockMovementY = true;
      }
      
      fabric.IText.prototype.showPlaceholderText = function () {
        let object = this
        if (object.text === '') {
          object.placeholder = object.placeholder ? object.placeholder : 'Write here..'
          object.set('text', object.placeholder);
          object.updatePlaceholderColor();
        }
      };
      
      fabric.IText.prototype.hidePlaceholderText = function () {
        let object = this
        if (object.text === object.placeholder) {
          object.text = ''
          object.hiddenTextarea.value = '';
          object.setSelectionStart(0);
          object.setSelectionEnd(0);
          object.updatePlaceholderColor();
        }
      };
      
      fabric.IText.prototype.updatePlaceholderColor = function () {
        let object = this
        if (object.text === object.placeholder) {
          object.set('fill', '#8d8d8d');
        } else {
          object.set('fill', 'black');
        }
      };
      fabric.Canvas.prototype.downloadCanvasAsImage = function () {
        let canvas, exportedImage, link;
        canvas = this;
        if (canvas) {
          exportedImage = canvas.toDataURL();
          link = document.createElement("a");
          link.setAttribute("href", exportedImage);
          link.setAttribute("download", 'Image.png');
          return link.click();
        }
      };
      fabric.Canvas.prototype.updateColorOfObject = function (obj, setType, color) {
        let canvas;
        canvas = this;
        if (canvas) {
          if (obj.type === "group") {
            return obj.getObjects().forEach(function (o) {
              canvas.updateColorOfObject(o, setType, color);
            });
          } else {
            return obj.set(setType, color);
          }
        }
      };
      fabric.Canvas.prototype.setStyleOfObjectsInCanvas = function (setType, color) {
        let canvas, objects;
        canvas = this;
        if (canvas) {
          objects = canvas.getActiveObjects();
          if (objects.length) {
            objects.forEach(function (obj) {
              canvas.updateColorOfObject(obj, setType, color);
            });
            canvas.renderAll();
          }
        }
      };
      fabric.Canvas.prototype.copySelectedObjectsInCanvas = function () {
        let canvas, objects;
        canvas = this;
        if (canvas) {
          objects = canvas.getActiveObjects();
          if (objects.length) {
            canvas.copiedObjects = objects;
          }
        }
      };
      fabric.Canvas.prototype.pasteSelectedObjectsInCanvas = function () {
        let canvas;
        canvas = this;
        if (canvas && canvas.copiedObjects.length) {
          canvas.copiedObjects.forEach(function (obj) {
            let copiedObject;
            copiedObject = fabric.util.object.clone(obj);
            copiedObject.set({
              left: copiedObject.left + 20,
              top: copiedObject.top + 20
            });
            canvas.add(copiedObject);
          });
        }
      };
      
      fabric.IText.prototype.superSubScript = function (keydown) {
        console.log("superSubScript =====>", keydown);
        let canvas, end, object, start;
        object = this;
        canvas = object.canvas;
        start = object.selectionStart;
        end = object.selectionEnd;
        if (keydown && start === end) {
          start = start - 1;
        }
        if (object.superscriptEnabled) {
          object.setSelectionStyles({
            'fontSize': 12,
            'deltaY': -7
          }, start, end);
        } else if (object.subscriptEnabled) {
          object.setSelectionStyles({
            'fontSize': 12,
            'deltaY': 2.2
          }, start, end);
        } else {
          object.setSelectionStyles({
            'fontSize': void 0,
            'deltaY': void 0
          }, start, end);
        }
        canvas.requestRenderAll();
      };
      
      fabric.Canvas.prototype.makeHolderReadyToGetFilled = function (object) {
        let canvas = this
        let closestHolderDist = 100000;
        // object = object;
        object.bringToFront()
        object.setCoords(true);
        object.currentLabelHolder = undefined;
        canvas.getObjectsByAttr({ name: 'Label Holder' }).forEach(function (holder) {
          if (object.intersectsWithObject(holder, true, true)) {
            let distObjToHolder;
            let holderCenter = holder.getPointByOrigin('center', 'center');
            let objectCenter = object.getPointByOrigin('center', 'center');
            distObjToHolder = Math.sqrt(Math.pow(holderCenter.x - objectCenter.x, 2) + Math.pow(holderCenter.y - objectCenter.y, 2));
            if (closestHolderDist > distObjToHolder || object.containsPoint(holderCenter)) {
              closestHolderDist = distObjToHolder;
              object.currentLabelHolder = holder;
            }
      
            if (holder.holderFilled && holder.holdingObjectId === object.id) {
              holder.holdingObjectId = "";
              holder.holderFilled = false;
              object.objectPlaced = false;
            }
          }
          holder.updateCustomStyle('default');
        });
        if (object.currentLabelHolder) {
          object.currentLabelHolder.updateCustomStyle('ready');
        } else {
          object.objectPlaced = false
        }
      };
      
      fabric.Object.prototype.updateCustomStyle = function (type, subObject) {
        let object = this
        if (object.customType == 'label-holder') {
          let box, holder = object
          box = holder.getObjects()[2];
          holder.removeWithUpdate(box);
          if (type == 'default') {
            if (!holder.holderFilled) {
              box.set('height', box.minHeight);
              box.set('width', box.minWidth);
            }
          } else if (type == 'ready') {
      
          } else if (type == 'filled') {
            box.set('height', subObject.getScaledHeight() + 2 * padding);
            box.set('width', subObject.getScaledWidth() + 2 * padding);
          }
          box.set('fill', labelHolderStyle.color[type]);
          holder.addWithUpdate(box);
          holder.setCoords();
        }
      };
      
      fabric.Canvas.prototype.resetNotFilledHoldersStyle = function () {
        let canvas = this
        canvas.getObjectsByAttr({ name: 'Label Holder' }).forEach(function (holder) {
          holder.updateCustomStyle('default');
        });
      };
      
      fabric.Canvas.prototype.resetNotPlacedObjectsPos = function () {
        let canvas = this
        let objectList = [];
        canvas.getObjectsByAttr({ selectable: true }).forEach(function (object) {
          if (!object.objectPlaced) {
            objectList.push(object);
          }
        });
        canvas.possibleObjectListPos(objectList);
      };
      
      fabric.Canvas.prototype.setObjectPosOnMouseUp = function (object) {
        let canvas, box, boxLeft, boxTop, holder;
        canvas = this
        if (object && object.selectable) {
          holder = object.currentLabelHolder;
          console.log("object and holder on setObjectPosOnMouseUp", object, holder)
          if (!holder || !object || holder.id == object.id) {
            canvas.resetNotPlacedObjectsPos();
            return;
          }
          box = holder.getObjects()[2];
          if (holder.originX === 'right' && object.originX === 'right') {
            boxLeft = holder.left - holderBoxToPointerDist.left - padding;
            boxTop = holder.top + box.top;
          } else if (holder.originX === 'right' && object.originX === 'left') {
            boxLeft = holder.left - object.getScaledWidth() - holderBoxToPointerDist.left - 1.5 * padding;
            boxTop = holder.top + box.top;
          } else if (holder.originX === 'left' && object.originX === 'right') {
            boxLeft = holder.left + object.getScaledWidth() + holderBoxToPointerDist.left + 1.5 * padding;
            boxTop = holder.top + box.top;
          } else if (holder.originX === 'left' && object.originX === 'left') {
            boxLeft = holder.left + holderBoxToPointerDist.left + padding;
            boxTop = holder.top + box.top;
          }
          holder.set('holderFilled', true);
          object.set('objectPlaced', true);
          object.set('left', boxLeft);
          object.set('top', boxTop);
          holder.bringToFront();
          object.bringToFront();
          if (holder.holdingObjectId != "" && holder.holdingObjectId != object.id) {
            canvas.getObjectsByAttr({ id: holder.holdingObjectId }).forEach(function (obj) {
              obj.objectPlaced = false;
            });
          }
          holder.holdingObjectId = object.id;
          holder.updateCustomStyle('filled', object);
          object.updateContainerRect();
          holder.setCoords();
          object.setCoords();
          canvas.resetNotPlacedObjectsPos();
      
          if (canvas._customEventListeners.onSetObjectPosOnMouseUp) {
            canvas._customEventListeners.onSetObjectPosOnMouseUp(object)
          }
          // canvas.resetNotFilledHoldersStyle(); Not in use
        }
      };
      
      fabric.Canvas.prototype.possibleObjectListPos = function (objectList) {
        let canvas = this;
        let z = canvas.getZoom()
        let canvasWidth = canvas.getWidth() / z
        let canvasHeight = canvas.getHeight() / z
        let maxObjectHeight = 0;
        let initObjectPos = { left: 50, top: 450 }
        let marginObjectPos = { row: 20, col: 20 }
        let objectPos = {
          left: initObjectPos.left,
          top: initObjectPos.top
        };
      
        objectList.forEach(function (object) {
          let bound;
          object.objectPlaced = false;
          bound = object.getBoundingRect();
          if (objectPos.left < canvasWidth - (bound.width + marginObjectPos.col + initObjectPos.left)) {
            if (object.originX === 'left') {
              object.left = objectPos.left;
            } else if (object.originX === 'center') {
              object.left = objectPos.left + object.width / 2;
            } else if (object.originX === 'right') {
              object.left = objectPos.left + object.width;
            }
            if (object.originY === 'top') {
              object.top = objectPos.top;
            } else if (object.originY === 'center') {
              object.top = objectPos.top + object.height / 2;
            } else if (object.originY === 'right') {
              object.top = objectPos.top + object.height;
            }
            objectPos.left += bound.width + marginObjectPos.col;
            if (maxObjectHeight < bound.height) {
              maxObjectHeight = bound.height;
            }
          } else {
            objectPos.top = objectPos.top + maxObjectHeight + marginObjectPos.row;
            objectPos.left = initObjectPos.left;
            if (object.originX === 'left') {
              object.left = objectPos.left;
            } else if (object.originX === 'center') {
              object.left = objectPos.left + object.width / 2;
            } else if (object.originX === 'right') {
              object.left = objectPos.left + object.width;
            }
            if (object.originY === 'top') {
              object.top = objectPos.top;
            } else if (object.originY === 'center') {
              object.top = objectPos.top + object.height / 2;
            } else if (object.originY === 'right') {
              object.top = objectPos.top + object.height;
            }
            objectPos.left += bound.width + marginObjectPos.col;
            maxObjectHeight = bound.height;
          }
          object.selectable = true;
          object.setCoords();
          object.updateContainerRect();
          canvasHeight = objectPos.top + maxObjectHeight + marginObjectPos.row;
        });
        canvas.setHeight(z * canvasHeight);
        canvas.renderAll();
      };
      fabric.Canvas.prototype.updateShapePosition = function (type) {
        let canvas;
        canvas = this;
        if (['iText', 'rectangle', 'circle', 'point'].indexOf(type) >= 0) {
          if (canvas.shapeStyle.left < canvas.getWidth() - 300) {
            canvas.shapeStyle.left += 50;
          } else if (canvas.shapeStyle.top < canvas.getHeight() - 300) {
            canvas.shapeStyle.left = 100;
            canvas.shapeStyle.top += 50;
          } else {
            canvas.shapeStyle.left = 100;
            canvas.shapeStyle.top = 100;
          }
        }
        return canvas.shapeStyle;
      };
      
      fabric.Object.prototype.getConnectedObjectsByAttributes = function (props) {
        let object = this;
        let canvas = object.canvas
        let coArr = []
      
        if (!object || !object.connectedObjectsIds || !canvas) {
          return coArr
        }
      
        object.connectedObjectsIds.forEach(function (id, indx) {
          let co = canvas.getObjectsByAttr({ id: id })[0]
          if (co) {
            let available = true
            for (const key in props) {
              if (co[key] != props[key]) {
                available = false
              }
            }
            if (available) {
              coArr.push(co)
            }
          }
        })
        return coArr
      }
      
      fabric.Canvas.prototype.onKeyDown = function (event) {
        const element = event.currentTarget
        let canvas = this
        let code = event.keyCode || event.which;
        let ctrlKey = event.ctrlKey
        let metaKey = event.metaKey
        let shiftKey = event.shiftKey
        // console.log("document active element ===>", document.activeElement)
        // || document.activeElement.className.indexOf('cke_editable') > -1
        if (!canvas || !element || document.activeElement.className.indexOf('canvas-text-area') > -1) {
          return;
        }
      
        scrollCanvasIntoView(element);
        // console.log('onKeyDown', code, event, element);
        if (code === 46 || code === 8) {
          canvas.deleteObject();
        }
        if ((ctrlKey || metaKey) && code === 90) {
          canvas.undoLastObject();
        }
        if (((ctrlKey || metaKey) && code === 89) || (ctrlKey && shiftKey && code === 90)) {
          canvas.redoLastObject();
        }
        // if ((ctrlKey || metaKey) && code === 67) {
        //   canvas.copySelectedObjectsInCanvas();
        // }
        // if ((ctrlKey || metaKey) && code === 86) {
        //   canvas.pasteSelectedObjectsInCanvas();
        // }
      };
    }
  }, [canvas.fabricReady])

  useEffect(() => {
    if (canvas.fabricReady) {
      let line, arrow, rectangle, circle, dot, polygon, path = {};
      let objectSelected = false;
      let drawingStarted = false;
      let pointer = { x: '', y: '' };
      let mousePointer = { x: '', y: '' };
      let signsToBeAdded = []
      let objectList
      let selectedObject
      let polylineArrow, angle, arrowHead;
      let deleteObjectFromCanvas = {}
      let undoLastObject = {}
      let redoLastObject = {}
      let copySelectedObjects = {}
      let pasteSelectedObjects = {}

      if (canvas.width) {
        canvas.freeDrawingBrush.width = 2
        canvas.freeDrawingBrush.color = canvas.shapeStyle.strokeColor;
        // canvas.freeDrawingBrush.shadow = new fabric.Shadow({
        //   blur: 0,
        //   offsetX: 0,
        //   offsetY: 0,
        //   affectStroke: true,
        //   color: canvas.shapeStyle.fill
        // })

        function resetCanvasToolTypeValues() {
          canvas.toolType = {}
        }

        function startDrawingLineOnMouseDown(options) {
          const points = [
            pointer.x,
            pointer.y,
            pointer.x,
            pointer.y,
          ]

          line = new fabric.Line(points, {
            fill: canvas.shapeStyle.fill,
            stroke: canvas.shapeStyle.strokeColor,
            strokeWidth: canvas.shapeStyle.strokeWidth,
            name: 'Line',
            hasRotatingPoint: false,
            hasBorders: true,
            hasControls: false,
            perPixelTargetFind: true,
            hasCustomControlPoints: true,
            id: getUniqueId()
          });
          
          canvas.add(line);
        }

        function drawingLineOnMouseMoving(options) {
          if (!line) {
            return false
          }
          line.set({ x2: mousePointer.x, y2: mousePointer.y })
          line.setCoords()
        }

        function startDrawingArrowOnMouseDown(options) {
          const points = [
            pointer.x,
            pointer.y,
            pointer.x + 1,
            pointer.y,
          ]

          // angle = getAngle(points[0], points[1], points[2], points[3]);
          // let arrowHeadProps = {
          //   left: pointer.x,
          //   top: pointer.y,
          //   angle: angle,
          //   style: lineArrowStyle,
          //   name: "Arrow Head"
          // }
          // arrowHead = getArrowHead(arrowHeadProps);
          // canvas.add(arrowHead)

          arrow = new fabric.Line(points, {
            fill: canvas.shapeStyle.fill,
            stroke: canvas.shapeStyle.strokeColor,
            strokeWidth: canvas.shapeStyle.strokeWidth,
            name: 'Arrow',
            hasRotatingPoint: false,
            hasBorders: true,
            hasControls: false,
            perPixelTargetFind: true,
            hasCustomControlPoints: true,
            id: getUniqueId()
          });

          canvas.add(arrow)
        }

        function drawingArrowOnMouseMoving(options) {
          if (!arrow) {
            return false
          }

          arrow.set({ x2: mousePointer.x, y2: mousePointer.y })
          updatingConnectedObjectsOnModifying(arrow);
        }

        function updatingArrowOnMouseUp(options) {
          if (!arrow) {
            return false
          }

          angle = getAngle(arrow.x1, arrow.y1, arrow.x2, arrow.y2);
          let arrowHeadProps = {
            left: mousePointer.x,
            top: mousePointer.y,
            angle: angle,
            style: lineArrowStyle,
            name: "Arrow Head"
          }
          arrowHead = getArrowHead(arrowHeadProps);
          canvas.add(arrowHead)

          // if (Math.abs(arrow.x2 - arrow.x1) < 10) {
          //   arrow.set({ x2: arrow.x1 + 30 })
          // }
          arrow.set('connectedObjectsIds', [arrowHead.id])
          arrow.showControlPoints()
          canvas.setActiveObject(arrow)
          updatingConnectedObjectsOnModifying(arrow);
        }

        function startDrawingRectangleOnMouseDown(options) {
          
        console.log("line no 2096",canvas.shapeStyle.fill);

          rectangle = new fabric.Rect({
            width: 0,
            height: 0,
            left: pointer.x,
            top: pointer.y,
            originX: 'center',
            originY: 'center',
            fill: canvas.shapeStyle.fill,
            stroke: canvas.shapeStyle.strokeColor,
            strokeDashArray: canvas.shapeStyle.strokeDashArray,
            strokeWidth: canvas.shapeStyle.strokeWidth,
            name: 'Rectangle',
            hasRotatingPoint: false,
            perPixelTargetFind: canvas.shapeStyle.perPixelTargetFind,
            id: getUniqueId()
          });

          canvas.add(rectangle)
        }

        function drawingRectangleOnMouseMoving(options) {
          const w = Math.abs(mousePointer.x - pointer.x)
          const h = Math.abs(mousePointer.y - pointer.y)

          if (!w || !h || !rectangle) {
            return false
          }

          if (pointer.x > mousePointer.x) {
            rectangle.set('left', pointer.x - w / 2)
          } else {
            rectangle.set('left', pointer.x + w / 2)
          }

          if (pointer.y > mousePointer.y) {
            rectangle.set('top', pointer.y - h / 2)
          } else {
            rectangle.set('top', pointer.y + h / 2)
          }

          rectangle.set('width', w).set('height', h)
          rectangle.setCoords()
        }

        function startDrawingCircleOnMouseDown(options) {
          circle = new fabric.Circle({
            radius: 0,
            left: pointer.x,
            top: pointer.y,
            fill: canvas.shapeStyle.fill,
            stroke: canvas.shapeStyle.strokeColor,
            strokeDashArray: canvas.shapeStyle.strokeDashArray,
            strokeWidth: canvas.shapeStyle.strokeWidth,
            originX: 'center',
            originY: 'center',
            name: 'Circle',
            hasRotatingPoint: false,
            perPixelTargetFind: canvas.shapeStyle.perPixelTargetFind,
            id: getUniqueId()
          });
          canvas.add(circle)
        }

        function drawingCircleOnMouseMoving(options) {
          const w = Math.abs(mousePointer.x - pointer.x)
          const h = Math.abs(mousePointer.y - pointer.y)

          if (!w || !h || !circle) {
            return false
          }

          const radius = Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2)) / 2

          circle.set('radius', radius)
          circle.set('left', (pointer.x + mousePointer.x) / 2).set('top', (pointer.y + mousePointer.y) / 2)
          circle.setCoords()
        }

        function startDrawingDotOnMouseDown(options) {
          dot = new fabric.Circle({
            radius: 5,
            left: pointer.x,
            top: pointer.y,
            fill: canvas.shapeStyle.strokeColor,
            // stroke: canvas.shapeStyle.strokeColor,
            // strokeDashArray: canvas.shapeStyle.strokeDashArray,
            // strokeWidth: canvas.shapeStyle.strokeWidth,
            originX: 'center',
            originY: 'center',
            name: 'Dot',
            hasControls: false,
            hasRotatingPoint: false,
            perPixelTargetFind: canvas.shapeStyle.perPixelTargetFind,
            id: getUniqueId()
          });
          canvas.add(dot)
        }

        let polygonPoints = []
        let polygon = undefined
        function startDrawingPolygonOnMouseDown(options) {
          let object = options.target
          polygonPoints.push({
            x: pointer.x,
            y: pointer.y
          });
          if (polygon) {
            let control = polygon.getConnectedObjectsByAttributes({ customType: 'custom-control', controlIndx: 0 })[0]
            if (object && control && (object.id == control.id)) {
              polygonPoints.pop();
              polygonPoints.pop();
              polygon.hideControlPoints()
              canvas.remove(polygon);
              polygon = new fabric.Polygon(polygonPoints, {
                fill: canvas.shapeStyle.fill,
                stroke: canvas.shapeStyle.strokeColor,
                strokeDashArray: canvas.shapeStyle.strokeDashArray,
                strokeWidth: canvas.shapeStyle.strokeWidth,
                originX: 'center',
                originY: 'center',
                name: 'Polygon',
                hasControls: false,
                hasBorders: true,
                hasRotatingPoint: false,
                perPixelTargetFind: true,
                objectCaching: false,
                hasCustomControlPoints: true,
                id: getUniqueId()
              });
              canvas.add(polygon);
              polygon.showControlPoints()
              fixPolyGrabControlOnUpdate(polygon);
              canvas.setActiveObject(polygon);
              canvas.saveStateOnChange();
              polygonPoints = []
              polygon = undefined
            } else {
              polygon.set('points', polygonPoints);
              polygon.showControlPoints();
            }
          } else {
            polygonPoints.push({
              x: pointer.x,
              y: pointer.y
            });
            polygon = new fabric.Polyline(polygonPoints, {
              fill: '',
              stroke: canvas.shapeStyle.strokeColor,
              strokeDashArray: canvas.shapeStyle.strokeDashArray,
              strokeWidth: canvas.shapeStyle.strokeWidth,
              originX: 'center',
              originY: 'center',
              name: 'Temp Polyline',
              hasControls: false,
              hasBorders: false,
              hasRotatingPoint: false,
              perPixelTargetFind: true,
              selectable: false,
              objectCaching: false,
              hoverCursor: 'crosshair',
              hasCustomControlPoints: true,
              id: getUniqueId()
            });
            canvas.add(polygon);
            polygon.showControlPoints();
          }
        }

        function drawingPolygonOnMouseMoving(options) {
          let w, h;
          w = Math.abs(mousePointer.x - pointer.x)
          h = Math.abs(mousePointer.y - pointer.y)

          if (!w || !h || !polygon) {
            return false
          }

          polygonPoints[polygonPoints.length - 1] = { x: mousePointer.x, y: mousePointer.y }
          polygon.set('points', polygonPoints)
          fixPolyGrabControlOnUpdate(polygon)
          // fixPolyGrabControlOnUpdate(polygon)
          // // console.log('Polygon and polygon points ==>', polygon, polygon.points);
        }

        function startDrawingPathOnMouseDown(options) {
          // canvas.freeDrawingBrush.color = canvas.shapeStyle.strokeColor;
        }

        function drawingPathOnMouseMoving(options) {
          // canvas.freeDrawingBrush.color = canvas.shapeStyle.strokeColor;
        }

        function updateFreeDrawPath(object) {
          if (object.type === 'path') {
            object.id = getUniqueId();
            object.fill = canvas.shapeStyle.fill;
            object.stroke = canvas.shapeStyle.strokeColor;
            object.strokeDashArray = canvas.shapeStyle.strokeDashArray;
            object.strokeWidth = canvas.shapeStyle.strokeWidth;
            object.originX = 'center';
            object.originY = 'center';
            object.perPixelTargetFind = canvas.shapeStyle.perPixelTargetFind;
            object.objectCaching = false;
            object.hasRotatingPoint = false;
            object.name = 'Path';

            if (canvas._customEventListeners.onUpdateFreeDrawPath) {
              canvas._customEventListeners.onUpdateFreeDrawPath(object)
            }
          }
        };

        function getPolylinePoints(x1, y1, x2, y2) {
          let points;
          points = [];
          points.push({
            x: x1,
            y: y1
          }, {
            x: x2,
            y: y1
          }, {
            x: x2,
            y: y2
          });
          return points;
        };

        function getPolyline(points, props) {
          let polyline;
          polyline = new fabric.Polyline(points, {
            fill: props.style.fill,
            stroke: props.style.stroke,
            strokeWidth: props.style.strokeWidth,
            hasControls: false,
            hasBorders: false,
            evented: false,
            objectCaching: false,
            controls: [],
            connectedObjectsIds: props.connectedObjectsIds,
            // notDeletable: true
            id: getUniqueId(),
            type: 'polyline',
            name: props.name,
          });
          return polyline;
        };

        function getAngle(x1, y1, x2, y2) {
          let angle, dx, dy;
          dy = y2 - y1;
          dx = x2 - x1;
          angle = Math.atan2(dy, dx) * 180 / Math.PI + 180;
          return angle;
        };

        function getArrowHead(props) {
          let arrowHead;
          arrowHead = new fabric.Polygon([
            {
              x: -12,
              y: 10
            }, {
              x: 0,
              y: 20
            }, {
              x: -12,
              y: 30
            }
          ], {
            left: props.left,
            top: props.top,
            angle: props.angle,
            fill: props.style.stroke,
            stroke: props.style.stroke,
            hasBorders: false,
            hasControls: false,
            evented: false,
            notDeletable: true,
            id: getUniqueId(),
            // type: "arrow-head",
            customType: "arrow-head",
            name: props.name,
          });
          return arrowHead;
        };

        function updatingConnectedObjectsOnModifying(object, position) {
          // console.log('Update conencted objects ==>', object.connectedObjectsIds)
          if (!object || !object.connectedObjectsIds || polygon) {
            return
          }
          let objectPoints, controlIndx, controlMargin;

          if (object.points) {
            objectPoints = object.points;
          }

          if (object.name == 'Label Text' || object.name == 'Label Holder') {
            object.updateLabelHolder()
            object.updateContainerRect()
          }
          object.connectedObjectsIds.forEach(function (id, indx) {
            let co = canvas.getObjectsByAttr({ id: id })[0]
            // console.log('connected object exist? ==>', co)
            if (co) {
              if (co.customDisableUpdate) {
                return
              }
              // // console.log('Updating connected object ==>', object.name, object, co.name, co)
              if (co.customType == 'custom-control') {
                let control = co;
                let x, y, x2, y2;
                if (object.type == 'line') {
                  if (control.controlIndx == 0) {
                    x = object.x1
                    y = object.y1
                  } else {
                    x = object.x2
                    y = object.y2
                  }
                } else if (object.type == 'polyline' || object.type == 'polygon') {
                  x = objectPoints[control.controlIndx].x
                  y = objectPoints[control.controlIndx].y
                }
                if (control) {
                  control.set('left', x).set('top', y);
                  control.setCoords();
                }
              } else if (co.customType == "arrow-head") {
                let arrowHead = co;
                let x1, y1, x2, y2;
                let left, top, angle
                if (object.type == 'line') {
                  x1 = object.x1
                  y1 = object.y1
                  left = x2 = object.x2
                  top = y2 = object.y2

                  angle = getAngle(x2, y2, x1, y1)
                } else if (object.type == 'polyline' || object.type == 'polygon') {
                  left = x1 = objectPoints[0].x
                  top = y1 = objectPoints[0].y
                  x2 = objectPoints[1].x
                  y2 = objectPoints[1].y

                  angle = getAngle(x1, y1, x2, y2)
                }
                arrowHead.set('angle', angle);
                arrowHead.set('left', left).set('top', top);
                arrowHead.setCoords();
              } else if (co.type == "line") {
                let line = co
                if (object.customType == 'custom-control') {
                  if (object.controlIndx == 0) {
                    line.set('x1', object.left).set('y1', object.top)
                  } else {
                    line.set('x2', object.left).set('y2', object.top)
                  }
                  line.setCoords()
                  updatingConnectedObjectsOnModifying(line)
                }
              } else if (co.type == "polyline") {
                let polyline = co;
                let polylinePointX, polylinePointY;
                let polylinePoints = polyline.points;
                if (polyline) {
                  if (object.name === 'Polyline Arrow Control') {
                    controlMargin = 0;
                    controlIndx = object.controlIndx;
                    polylinePointX = object.left + controlMargin;
                    if (controlIndx === 2) {
                      return false;
                    }
                  } else if (object.name === 'Label Holder') {
                    controlMargin = 5;
                    controlIndx = polylinePoints.length - 1;
                    if (object.originX === 'right') {
                      polylinePointX = object.left - controlMargin;
                    } else {
                      polylinePointX = object.left + controlMargin;
                    }
                  } else if (object.name === 'Label Text') {
                    controlMargin = -labelHolderBoxToPointerDist.left;
                    controlIndx = polylinePoints.length - 1;
                    if (object.originX === 'right') {
                      polylinePointX = object.left - controlMargin;
                    } else {
                      polylinePointX = object.left + controlMargin;
                    }
                  }

                  if (controlIndx >= 0) {
                    polylinePointY = object.top;
                    polyline.points[controlIndx].x = polylinePointX;
                    polyline.points[controlIndx].y = polylinePointY;
                    updatingConnectedObjectsOnModifying(polyline)
                    fixPolyGrabControlOnUpdate(polyline);
                  }
                }
              } else if (co.type == "polygon") {
                let polygonPointX, polygonPointY;
                let polygon = co;
                let polygonPoints = polygon.points;
                if (polygon) {
                  if (object.name == 'Polygon Control') {
                    controlIndx = object.controlIndx;
                    polygonPointX = object.left;
                    polygonPointY = object.top;
                    polygon.points[controlIndx].x = polygonPointX;
                    polygon.points[controlIndx].y = polygonPointY;
                    updatingConnectedObjectsOnModifying(polygon)
                    fixPolyGrabControlOnUpdate(polygon);
                  }
                }
              }
            }
          });

          canvas.renderAll()
        }

        function startDrawingPolylineArrowOnMouseDown(options) {
          let polylinePoints, angle, arrowHead, control;
          polylinePoints = getPolylinePoints(pointer.x, pointer.y, pointer.x + 1, pointer.y + 1);
          angle = getAngle(polylinePoints[0].x, polylinePoints[0].y, polylinePoints[1].x, polylinePoints[1].y);
          let arrowHeadProps = {
            left: polylinePoints[0].x,
            top: polylinePoints[0].y,
            angle: angle,
            style: polylineArrowStyle,
            name: "Polyline Arrow Head"
          }
          arrowHead = getArrowHead(arrowHeadProps);
          let polylineArrowProps = {
            name: 'Polyline Arrow',
            style: polylineArrowStyle,
            connectedObjectsIds: [arrowHead.id]
          }
          polylineArrow = getPolyline(polylinePoints, polylineArrowProps);
          polylinePoints.forEach(function (point, indx) {
            control = polylineArrow.getControlPoint(point.x, point.y, indx);
            polylineArrow.connectedObjectsIds.push(control.id)
            canvas.add(control);
          });
          canvas.add(polylineArrow, arrowHead);
        }

        function drawingPolylineArrowOnMouseMoving(options) {
          let polylinePoints, object;
          object = options.target;
          if (!polylineArrow) {
            return false;
          }
          let canvasBoundaryMargin = 50;
          let z = canvas.getZoom()
          let canvasWidth = canvas.getWidth() / z;
          let canvasHeight = canvas.getHeight() / z;
          if (mousePointer.x <= canvasBoundaryMargin) {
            mousePointer.x = canvasBoundaryMargin;
          } else if (mousePointer.x >= canvasWidth - canvasBoundaryMargin) {
            mousePointer.x = canvasWidth - canvasBoundaryMargin;
          }
          if (mousePointer.y <= canvasBoundaryMargin) {
            mousePointer.y = canvasBoundaryMargin;
          } else if (mousePointer.y >= canvasHeight - canvasBoundaryMargin) {
            mousePointer.y = canvasHeight - canvasBoundaryMargin;
          }
          polylinePoints = getPolylinePoints(pointer.x, pointer.y, mousePointer.x, mousePointer.y);
          polylineArrow.set('points', polylinePoints);
          fixPolyGrabControlOnUpdate(polylineArrow);
          updatingConnectedObjectsOnModifying(polylineArrow);
        }

        function addingTextLabelToPolylineArrowOnMouseUp(options) {
          let labelLeft, labelOriginX, labelOriginY, lebelTop;
          if (!drawingStarted || !polylineArrow) {
            return false;
          }
          if (mousePointer.x >= pointer.x) {
            labelOriginX = 'left';
            labelOriginY = 'center';
            labelLeft = mousePointer.x + labelHolderBoxToPointerDist.left;
            lebelTop = mousePointer.y;
          } else {
            labelOriginX = 'right';
            labelOriginY = 'center';
            labelLeft = mousePointer.x - labelHolderBoxToPointerDist.left;
            lebelTop = mousePointer.y;
          }
          addTextLabel(labelLeft, lebelTop, labelOriginX, labelOriginY, polylineArrow);
        };

        function addTextLabel(labelLeft, labelTop, labelOriginX, labelOriginY, polylineArrow) {
          let text;
          text = new fabric.IText('Write Here..', {
            placeholder: 'Write Here..',
            fill: '#8d8d8d',
            left: labelLeft,
            top: labelTop,
            fontSize: textFontSize,
            textAlign: 'left',
            originX: labelOriginX,
            originY: labelOriginY,
            padding: padding,
            backgroundColor: textBgColors,
            centeredScaling: true,
            hasRotatingPoint: false,
            lockScalingFlip: true,
            requireContainerRect: true,
            requireLabelHolder: true,
            objectPlaced: true,
            id: getUniqueId(),
            name: 'Label Text'
          });
          canvas.add(text);
          text.limitChars(charsLimit)
          text.updateContainerRect([text.id])
          text.updateLabelHolder([polylineArrow.id, text.id])
          text.set('connectedObjectsIds', [polylineArrow.id, text.containerRect.id, text.labelHolder.id])
          polylineArrow.set('notDeletable', true)
          canvas.setActiveObject(text);
          canvas.renderAll()
          canvas.saveStateOnChange();
          onObjectStatusChanged(text);

          if (canvas._customEventListeners.onAddTextLabel) {
            canvas._customEventListeners.onAddTextLabel(text)
          }
        };

        function addCommentIndicatorOnMouseDown(options) {
          const currentNumber = (canvas.getObjectsByAttr({ name: 'Comment Indicator' }).length + 1).toString()
          if (currentNumber > maxAllowedComment) {
            canvas.toolType.commentIndicator = false
            message.error('Reached maximum allowed limit for comment.')
            return
          }
          if(canvas.commentIndicatorSavingStatus) {
            return
          }
          canvas.commentIndicatorSavingStatus = true

          fabric.loadSVGFromString(`<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32z"></path></svg>`, (svg) => {
            console.log("svg inserted ==>", svg)
            let commentBox = svg[0]

            commentBox.left = 0
            commentBox.top = 0
            // commentBox.width = 30
            // commentBox.height = 30
            commentBox.scaleToWidth(20, true)
            commentBox.scaleToHeight(20, true)
            commentBox.fill = commentIndicatorStyle.svg.fill
            commentBox.stroke = commentIndicatorStyle.svg.strokeColor
            commentBox.name = 'Comment SVG'
            commentBox.hasBorders = false
            commentBox.hasControls = false
            commentBox.hasRotatingPoint = false
            commentBox.id = getUniqueId()

            console.log("comment box ==>", commentBox)
            let commentNumber = new fabric.Text(currentNumber, {
              left: 0,
              top: 0,
              originX: 'center',
              originY: 'center',
              fontSize: commentIndicatorStyle.text.fontSize,
              fill: commentIndicatorStyle.text.fill,
              stroke: commentIndicatorStyle.text.strokeColor,
              name: 'Comment Number',
              hasBorders: false,
              hasControls: false,
              hasRotatingPoint: false,
              id: getUniqueId()
            });

            let comment = new fabric.Group([commentBox, commentNumber], {
              left: pointer.x,
              top: pointer.y,
              originX: 'center',
              originY: 'center',
              name: 'Comment Indicator',
              hasBorders: false,
              hasControls: false,
              hasRotatingPoint: false,
              perPixelTargetFind: false,
              id: getUniqueId()
            })

            canvas.add(comment)
            // canvas.add(commentBox)
            // canvas.add(commentNumber)
          })
        }

        function addCheckTickOnMouseDown(options) {
          let checkTick = new fabric.Text('\u2714', {
            left: pointer.x,
            top: pointer.y,
            originX: 'center',
            originY: 'center',
            fill: respMarkStyle.text.fill.right,
            stroke: respMarkStyle.text.strokeColor.right,
            strokeDashArray: canvas.shapeStyle.strokeDashArray,
            strokeWidth: canvas.shapeStyle.strokeWidth,
            fontSize: canvas.shapeStyle.fontSize,
            name: 'Check Tick',
            hasBorders: true,
            hasControls: false,
            hasRotatingPoint: false,
            perPixelTargetFind: canvas.shapeStyle.perPixelTargetFind,
            id: getUniqueId()
          });

          canvas.add(checkTick)
        }

        function addWrongTickOnMouseDown(options) {
          let wrongTick = new fabric.Text('\u00D7', {
            left: pointer.x,
            top: pointer.y,
            originX: 'center',
            originY: 'center',
            fill: respMarkStyle.text.fill.wrong,
            stroke: respMarkStyle.text.strokeColor.wrong,
            strokeDashArray: canvas.shapeStyle.strokeDashArray,
            strokeWidth: canvas.shapeStyle.strokeWidth,
            fontSize: canvas.shapeStyle.fontSize,
            name: 'Cross Tick',
            hasBorders: true,
            hasControls: false,
            hasRotatingPoint: false,
            perPixelTargetFind: canvas.shapeStyle.perPixelTargetFind,
            id: getUniqueId()
          });

          canvas.add(wrongTick)
        }

        function zoomCanvasToPoint(event, delta) {
          let point = { x: event.offsetX || event.touches[0].clientX, y: event.offsetY || event.touches[0].clientY }
          console.log("zoomCanvasToPoint point, delta", event, point, delta)
          var zoom = canvas.getZoom();
          zoom *= 0.999 ** delta;
          if (zoom > 20) zoom = 20;
          if (zoom < 0.01) zoom = 0.01;
          canvas.zoomToPoint(point, zoom);
          console.log("Final zoom value ===>", zoom, canvas.viewportTransform)
        }

        function startPanningCanvasOnMouseDown(options) {
          if (canvas.panningAllowed) {
            let event = options.e

            canvas.lastPosX = event.clientX || event.touches[0].clientX
            canvas.lastPosY = event.clientY || event.touches[0].clientY
            canvas.panningStarted = true

            console.log("Panning started on mouse down ==>", event, canvas.lastPosX, canvas.lastPosY)
          }
        }

        function addTextOnMouseDown(options) {
          let text = new fabric.IText('', {
            left: pointer.x,
            top: pointer.y,
            originX: 'center',
            originY: 'center',
            // backgroundColor: canvas.shapeStyle.fill,
            height: 100,
            placeholder: ' ',
            borderColor: "yellow",
            editingBorderColor: "yellow",
            fontSize: textFontSize,
            textAlign: 'left',
            padding: padding,
            centeredScaling: true,
            hasRotatingPoint: true,
            lockScalingFlip: true,
            hasControls: true,
            name: 'Text',
            id: getUniqueId(),
          });
          text.width = 40;
          canvas.add(text)
          canvas.setActiveObject(text);
          text.enterEditing(options.e);
          text.hiddenTextarea.focus();
        }

        function panningCanvasOnMouseMove(options) {
          if (canvas.panningAllowed && canvas.panningStarted) {
            let event = options.e
            let vpt = canvas.viewportTransform

            let eClientX = event.clientX || event.touches[0].clientX
            let eClientY = event.clientY || event.touches[0].clientY
            vpt[4] += eClientX - canvas.lastPosX
            vpt[5] += eClientY - canvas.lastPosY

            canvas.requestRenderAll()
            canvas.lastPosX = eClientX
            canvas.lastPosY = eClientY

            console.log("Panning on mouse move ==>", vpt, event, event.clientX, canvas.lastPosX, canvas.lastPosY)
          }
        }

        function stopPanningCanvasOnMouseUp(options) {
          if (canvas.panningAllowed && canvas.panningStarted) {
            canvas.setViewportTransform(canvas.viewportTransform)
            canvas.startPanning = false
          }
        }

        function checkObjectSelection(object) {
          if (canvas.toolType.polygon && polygon) {
            let control = polygon.getConnectedObjectsByAttributes({ customType: 'custom-control', controlIndx: 0 })[0]
            if (object && control && object.id == control.id) {
              objectSelected = false
            } else {
              objectSelected = true
            }
          } else {
            polygon = undefined
            polygonPoints = []
            objectSelected = true
          }
        }

        function onMouseDown(options) {
          if (canvas.notEvented) {
            return
          }

          if (canvas._customEventListeners.onMouseDown) {
            canvas._customEventListeners.onMouseDown(options)
          }
          let event = options.e
          let object = options.target

          if (object) {
            if (canvas.hotspotModeEnabled) {
              object.updateHotspot()
            } else if (canvas.toolType.fillColor) {
              object.set('fill', canvas.colorPickerColor)
            }
            // else if (canvas.toolType.strokeColor) {
            //   object.set('stroke', canvas.shapeStyle.strokeColor)
            // }
             else if (canvas.toolType.delete) {
              canvas.deleteObject()
            }
            canvas.renderAll()
          }

          if (canvas.drawOnImageOnly) {
            if (!object || object.type != 'image') {
              return
            }
          }
          pointer.x = canvas.getPointer(options.e).x
          pointer.y = canvas.getPointer(options.e).y

          if (!objectSelected) {
            pointer.x = canvas.getPointer(options.e).x
            pointer.y = canvas.getPointer(options.e).y

            drawingStarted = true
            if (canvas.toolType.line) {
              startDrawingLineOnMouseDown(options)
            } else if (canvas.toolType.arrow) {
              startDrawingArrowOnMouseDown(options)
            } else if (canvas.toolType.rectangle) {
              startDrawingRectangleOnMouseDown(options)
            } else if (canvas.toolType.circle) {
              startDrawingCircleOnMouseDown(options)
            } if (canvas.toolType.dot) {
              startDrawingDotOnMouseDown(options)
            } else if (canvas.toolType.polygon) {
              startDrawingPolygonOnMouseDown(options)
            } else if (canvas.toolType.path) {
              startDrawingPathOnMouseDown(options)
            } else if (canvas.toolType.textLabel) {
              startDrawingPolylineArrowOnMouseDown(options)
            } else if (canvas.toolType.checkTick) {
              addCheckTickOnMouseDown(options)
            } else if (canvas.toolType.wrongTick) {
              addWrongTickOnMouseDown(options)
            } else if (canvas.toolType.commentIndicator) {
              addCommentIndicatorOnMouseDown(options)
            } else if (canvas.toolType.zoomIn) {
              zoomCanvasToPoint(event, -200)
            } else if (canvas.toolType.zoomOut) {
              zoomCanvasToPoint(event, 200)
            } else if (canvas.toolType.move) {
              startPanningCanvasOnMouseDown(options)
            } else if (canvas.toolType.text) {
              addTextOnMouseDown(options)
            }
          }
        }

        function onMouseMove(options) {
          if (canvas._customEventListeners.onMouseMove) {
            canvas._customEventListeners.onMouseMove(options)
          }
          mousePointer.x = canvas.getPointer(options.e).x
          mousePointer.y = canvas.getPointer(options.e).y

          if (!drawingStarted) {
            return false
          }

          if (canvas.toolType.line) {
            drawingLineOnMouseMoving(options)
          }

          if (canvas.toolType.arrow) {
            drawingArrowOnMouseMoving(options)
          }

          if (canvas.toolType.rectangle) {
            drawingRectangleOnMouseMoving(options)
          }

          if (canvas.toolType.circle) {
            drawingCircleOnMouseMoving(options)
          }

          if (canvas.toolType.polygon) {
            drawingPolygonOnMouseMoving(options)
          }

          if (canvas.toolType.path) {
            drawingPathOnMouseMoving(options)
          }

          if (canvas.toolType.textLabel) {
            drawingPolylineArrowOnMouseMoving(options)
          }

          if (canvas.toolType.move) {
            panningCanvasOnMouseMove(options)
          }

          canvas.renderAll()
        }
        function onMouseUp(options) {
          if (canvas._customEventListeners.onMouseUp) {
            canvas._customEventListeners.onMouseUp(options)
          }
          let object = options.target
          if (drawingStarted && object && (canvas.toolType.circle || canvas.toolType.rectangle)) {
            object.set('perPixelTargetFind', true)
            object.setCoords()
          }

          if (canvas.toolType.textLabel) {
            addingTextLabelToPolylineArrowOnMouseUp(options)
          }

          if (canvas.toolType.arrow) {
            updatingArrowOnMouseUp(options)
          }

          if (canvas.toolType.move) {
            stopPanningCanvasOnMouseUp(options)
          }

          if (canvas.toolType.polygon) {
            drawingStarted = true
          } else if (drawingStarted) {
            drawingStarted = false
            polylineArrow = line = arrow = rectangle = circle = undefined;
            console.log("object stopped ==>", object, objectSelected)
            // We don't want to save on mouse up if no drawing action occurs
            if (object || objectSelected) {
              canvas.saveStateOnChange();
              // Commented for now, will use in future
              // const canvasShapes = canvas.getObjects();
              // if (canvasShapes.length > 0) {
              //   const lastObject = canvasShapes[canvasShapes.length - 1];
              //   canvas.setActiveObject(object);
              //   canvas.renderAll();
              // }
            }
          }
          if (canvas.currentTool && !canvas.tools[canvas.currentTool]?.drawingTool || (canvas.toolType.polygon && !polygon)) {
            canvas.updateCurrentTool("move", true);
          }
        }

        function onObjectAdded(options) {
          if (canvas._customEventListeners.onObjectAdded) {
            canvas._customEventListeners.onObjectAdded(options)
          }
          let object = options.target

          if (canvas.toolType.path) {
            updateFreeDrawPath(object)
          }

          if (object.type == 'i-text') {
            object.limitChars(charsLimit)
          }
          // onObjectStatusChanged(object)
        }

        function onObjectRemoved(options) {
          if (canvas._customEventListeners.onObjectRemoved) {
            canvas._customEventListeners.onObjectRemoved(options)
          }
          let object = options.target
          // onObjectStatusChanged(object)
        }

        function onObjectMoving(options) {
          if (canvas._customEventListeners.onObjectMoving) {
            canvas._customEventListeners.onObjectMoving(options)
          }
          let object = options.target
          selectedObject = object
          onObjectStatusChanged(object)
        }

        function onObjectScaling(options) {
          if (canvas._customEventListeners.onObjectScaling) {
            canvas._customEventListeners.onObjectScaling(options)
          }
          let object = options.target
          onObjectStatusChanged(object)
        }

        function onObjectModified(options) {
          if (canvas._customEventListeners.onObjectModified) {
            canvas._customEventListeners.onObjectModified(options)
          }
          let object = options.target
          onObjectStatusChanged(object)
          canvas.saveStateOnChange()
        }

        function onObjectSelectionCreated(options) {
          if (canvas._customEventListeners.onObjectSelectionCreated) {
            canvas._customEventListeners.onObjectSelectionCreated(options)
          }
          let object = options.target

          object.showControlPoints()
          checkObjectSelection(object)
        }

        function onObjectSelectionUpdated(options) {
          if (canvas._customEventListeners.onObjectSelectionUpdated) {
            canvas._customEventListeners.onObjectSelectionUpdated(options)
          }
          let object = options.target
          let deselectedObjects = options.deselected
          // if (object.type != 'custom-control' && deselectedObjects) {
          //   deselectedObjects.map(function (obj) {
          //     obj.hideControlPoints()
          //   })
          // }
          object.showControlPoints()
          checkObjectSelection(object)
        }

        function onObjectSelectionClear(options) {
          if (canvas._customEventListeners.onObjectSelectionClear) {
            canvas._customEventListeners.onObjectSelectionClear(options)
          }
          let object = options.target
          objectSelected = false
          // object.hideControlPoints()
        }

        function onTextEditingEntered(options) {
          console.log("onTextEditingEntered ====>", options);
          if (canvas._customEventListeners.onTextEditingEntered) {
            canvas._customEventListeners.onTextEditingEntered(options)
          }
          let object = options.target
          object.hidePlaceholderText()
          resetCanvasToolTypeValues()
          canvas.ITextEditing = true
          if (object.text === "") {
            object.set({
              hasControls: false,
            });
          }
        };

        function onTextChanged(options) {
          console.log("onTextChanged ====>", options);
          if (canvas._customEventListeners.onTextChanged) {
            canvas._customEventListeners.onTextChanged(options)
          }
          let object = options.target
          object.updatePlaceholderColor()
          if (object.text === "") {
            object.set({
              hasControls: false,
            });
          } else {
            object.set({
              hasControls: true,
            });
          }
          onObjectStatusChanged(object)
        };

        function onTextEditingExited(options) {
          console.log("onTextEditingExited ====>", options);
          if (canvas._customEventListeners.onTextEditingExited) {
            canvas._customEventListeners.onTextEditingExited(options)
          }
          let object = options.target
          object.showPlaceholderText()
          canvas.onToolSelect('none', false)
          if(object.text === " ") {
            canvas.remove(object)
          }
          canvas.ITextEditing = false
        };

        function onPathCreated() { }

        function onMouseOver(options) {
          if (canvas._customEventListeners.onMouseOver) {
            canvas._customEventListeners.onMouseOver(options)
          }
        }

        function onMouseOut(options) {
          if (canvas._customEventListeners.onMouseWheel) {
            canvas._customEventListeners.onMouseOut(options)
          }
        }

        function onMouseWheel(options) {
          if (canvas.toolType.zoomIn || canvas.toolType.zoomOut) {
            if (canvas._customEventListeners.onMouseWheel) {
              canvas._customEventListeners.onMouseWheel(options)
            }
            let event = options.e
            if (canvas.zoomWithScroll) {
              zoomCanvasToPoint(event, event.deltaY) 
            }
            event.preventDefault();
            event.stopPropagation();
          }
        }

        const updateCanvasJSON = (json) => {
          const canvas_objects = json.objects || []
          canvas_objects.map((object) => {
            if (object.type == 'i-text') {
              object.selectable = true
              object.evented = true
              object.lockMovementX = false
              object.lockMovementY = false
              object.lockScalingFlip = false
              object.hoverCursor = 'move'
            }
            if (canvas.shapeStyle.perPixelTargetFind == false) {
              if (object.name == 'Rectangle' || object.name == 'Circle' || object.name == 'Polygon') {
                object.perPixelTargetFind = false
              }
            }
          })
          return json
        }

        const saveWithDebounce = debounce((data) => {
          if (saveCanvasData) {
            saveCanvasData(updateCanvasJSON(data))
          }
        }, 1000)

        function afterRender(options) {
          if (canvas._customEventListeners.afterRender) {
            canvas._customEventListeners.afterRender(options)
          }
          saveWithDebounce(canvas.toJSON(canvas.jsonPropToAdd))
        }

        function onObjectStatusChanged(object) {
          if (canvas._customEventListeners.onObjectStatusChanged) {
            canvas._customEventListeners.onObjectStatusChanged(object)
          }
          if (object) {
            canvas.stopToGoOutsideCanvas(object);
            getTransformedPoints(object);
            updatingConnectedObjectsOnModifying(object);
          }
        };

        canvas.on({
          'mouse:down': onMouseDown,
          'mouse:move': onMouseMove,
          'mouse:up': onMouseUp,
          'object:added': onObjectAdded,
          'object:removed': onObjectRemoved,
          'object:moving': onObjectMoving,
          'object:scaling': onObjectScaling,
          'object:modified': onObjectModified,
          'selection:created': onObjectSelectionCreated,
          'selection:updated': onObjectSelectionUpdated,
          'before:selection:cleared': onObjectSelectionClear,
          'text:editing:entered': onTextEditingEntered,
          'text:changed': onTextChanged,
          'text:editing:exited': onTextEditingExited,
          'path:created': onPathCreated,
          'mouse:over': onMouseOver,
          'mouse:out': onMouseOut,
          'mouse:wheel': onMouseWheel,
          'after:render': afterRender,
        });
        
        return () => {
          canvas.off({
            'mouse:down': onMouseDown,
            'mouse:move': onMouseMove,
            'mouse:up': onMouseUp,
            'object:added': onObjectAdded,
            'object:removed': onObjectRemoved,
            'object:moving': onObjectMoving,
            'object:scaling': onObjectScaling,
            'object:modified': onObjectModified,
            'selection:created': onObjectSelectionCreated,
            'selection:updated': onObjectSelectionUpdated,
            'before:selection:cleared': onObjectSelectionClear,
            'text:editing:entered': onTextEditingEntered,
            'text:changed': onTextChanged,
            'text:editing:exited': onTextEditingExited,
            'path:created': onPathCreated,
            'mouse:over': onMouseOver,
            'mouse:out': onMouseOut,
            'mouse:wheel': onMouseWheel,
            'after:render': afterRender,
          });
        }
      }
    }

  }, [canvas.fabricReady, refreshCanvas]);

  const canvasDiv =
    <div className={`canvas-wrapper`}>
      <canvas
        ref={props.fabricRef}
        style={{ border: "solid 1px" }}
      />
    </div>

  const canvasImage =
    <div className='canvas-wrapper'>
      <img
        alt=""
        src={pngBase64}
        width={200}
        height={200}
        onClick={() => openModal()}
      />
    </div>
    
  return (
    <>
      <Modal
        width="95%"
        open={canvasModal}
        className={'full-screen-modal'}
        closable={false}
        centered={true}
        // onOk={() => handleOk()}
        // onCancel={() => handleCancel()}
        footer={[
          <Button key={`ok-${props.itemId}`} onClick={() => handleOk()} type='primary'> OK </Button>
        ]}
      >
        {canvasDiv}
      </Modal>
      
      <Spin spinning={(!showCanvas || showLoader) ? true : false} size="large" tip={loaderMessage || "Loading..."}>
        {showCanvas && (!canvasModal ? canvasDiv : canvasImage)}
      </Spin>
    </>
  );
};