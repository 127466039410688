import React, { useEffect, useState } from "react";
// import GGBApplet from "./deployggb";
// import { actions } from "./redux";
import { Row, Col, Button, Switch } from "antd";
import styled from 'styled-components'
import { AddButton, CheckAnswerButton, QuickCheckBox, TryAgainButton, CustomTextButton, Segment, SegmentData, SegmentSetting, InputArea, DeleteButton, HorizontalDroppableContainer, DragDropArea, VerticalDroppableContainer, DroppableHeader, InlineDroppablesContainer, InlineDroppable, ShowText, ShowTextIndex, ShowTextContent, ShowTextFile } from '/src/components/UI/Segment/UIHelper'
import DefaultCKE from "/src/components/UI/CKEditor/DefaultCKE"
import SegmentItemFooter from '/src/views/Segments/SegmentItemDetail/SegmentItemFooter'
import SegmentItemHeader from '/src/views/Segments/SegmentItemDetail/SegmentItemHeader'
import { getUniqueId } from "/src/lib/utils/helperMethods";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { CKETextOptionTools } from "/src/components/UI/CKEditor/CKEConfig";

const InlineTextInput = props => {
  console.log("Text Input props ==>", props);

  const { answerMode, gaps, onInlineGapTextChanged, autoCheckMode, stateSetting, setRenderMath } = props
  const id = props['data-id']
  const gap = gaps.find(gap => gap.id == id) || {}

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }, [])

  let className = ''
  if (autoCheckMode || stateSetting.quickCheckModeEnabled) {
    if (gap.correct) {
      className += 'correct-answer'
    } else {
      className += 'incorrect-answer'
    }
  }

  return (
    <div
      key={`inline-input-area-${gap.id}`}
      className={`inline-text-input ${className}`}
    >
      {(answerMode && !stateSetting.quickCheckModeEnabled) ? <BasicCKE
        key={`basic-cke-${gap.id}`}
        itemId={`ckeditor-${gap.id}`}
        tools={CKETextOptionTools}
        data={gap.answer && gap.answer.content}
        onTextChanged={(data) => onInlineGapTextChanged(gap, data)}
        className="inline-cke"
      ></BasicCKE>
        : <ShowText
          className="inline-text"
        >
          <ShowTextContent
            dangerouslySetInnerHTML={{ __html: gap.answer && gap.answer.content }}
          >
          </ShowTextContent>
        </ShowText>
      }
    </div>
  );
};
export default InlineTextInput;