import React from "react";
import { Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { timeInMiliSeconds, writeToClipboardPolyfill } from "/src/lib/utils/helperMethods";
import screenfull from "screenfull";
import { useSelector } from "react-redux";
import { focusLostAtSelector } from "../FirestoreInteractions/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const FocusLostModal = (props) => {
  const {
    experienceProps,
    focusLostModalVisible,
    focusLostExplanation,
    setFocusLostExplanation,
    setFocusLostModalVisible,
    resumeModalVisible,
    setResumeModalVisible,
  } = props;
  const mrIntl = useTranslate()
  
  const {
    experience,
    experienceSettings,
    embedded,
    experienceViewMode,
    setUserInfo,
  } = experienceProps;
  const fullScreenTab = () => {
    if (experienceProps.experienceSettings.mode === "online_secure" && false) {
      if (screenfull.isEnabled) {
        screenfull.request();
      }
    }
  };


  var focusLostAt = useSelector(focusLostAtSelector);
  
  return (
    <Modal
      wrapClassName="height-centered-modal"
      centered={true}
      open={focusLostModalVisible || resumeModalVisible}
      closable={false}
      maskClosable={false}
      footer={null}
      width={300}
    >
      {focusLostModalVisible && <h3><MrTranslate id={"FocusLostModal.focus_lost"}/></h3>}
      {focusLostModalVisible && (
        <h5>
          <MrTranslate id={"FocusLostModal.there_was_an_attempt_to_move_out_of_this_test"}/>
        </h5>
      )}
      {/* Input box for writing explanation here */}
      {focusLostModalVisible && (
        <TextArea
          value={focusLostExplanation}
          onChange={(e) => setFocusLostExplanation(e.target.value.trimStart())}
          placeholder={mrIntl("FocusLostModal.enter_explanation")}
          autoSize={{ minRows: 3, maxRows: 5 }}
          maxLength={300}
          style={{ marginTop: "10px", marginBottom: "20px" }}
        />
      )}
      <Button
        style={{display: resumeModalVisible && 'flex', margin: resumeModalVisible && 'auto'}}
        type="primary"
        disabled={focusLostModalVisible && !focusLostExplanation}
        onClick={() => {
          fullScreenTab();
          resumeModalVisible && setResumeModalVisible(false);
          !embedded &&
            experienceViewMode === "apTakeTest" &&
            writeToClipboardPolyfill("");

          if (!focusLostExplanation) {
            return;
          }
          var resumeTestTime = Date.now();
          var focusLostTime = resumeTestTime - focusLostAt

          focusLostModalVisible && setFocusLostModalVisible(false);
          focusLostModalVisible &&
            setUserInfo(
              {
                is_focus_lost: false,
              },
              {
                log: {
                  // logging: experienceProps.log.logging,
                  logging: true,
                  action: "focus_restored",
                  current_value: 
                    {
                      duration: timeInMiliSeconds(focusLostTime), 
                      explanation: focusLostExplanation
                    }
                },
              }
            );
          // log focusLostExplanation
          // setFocusLostExplanation("")
          // mainTestScreen.exit();
          // setTimeout(() => {
          // mainTestScreen.enter();
          // }, 100)
        }}
      >
        <MrTranslate id={"FocusLostModal.resume_test"}/>
      </Button>
    </Modal>
  );
};

export default FocusLostModal;
