import { Col, Modal, Row, Input, Button, Tooltip, message } from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { BiMessageRoundedError } from "react-icons/bi";
import { actions } from "/src/views/Feedbacks/redux";
import "./SegmentItemFeedback.scss";
import SegmentItemFeedbackModal from "./SegmentItemFeedbackModal";

const SegmentItemFeedback = ({ segment, thisSegmentParentIdxs }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isTextOrResourceSegment =
    segment.segment_type === "text" || segment.segment_type === "resource";
  const isSectionSegment = segment.segment_type === "section";
  let tooltipText;
  if (isTextOrResourceSegment) {
    tooltipText = "content";
  } else if (isSectionSegment) {
    tooltipText = "section";
  } else {
    tooltipText = "question";
  }
  const mrIntl = useTranslate();
  console.log("line no 18=>", segment.segment_type);
  return (
    <>
      {isModalOpen && (
        <SegmentItemFeedbackModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          segment={segment}
          thisSegmentParentIdxs={thisSegmentParentIdxs}
        />
      )}
      <Tooltip
        title={mrIntl(
          "SegmentItemFeedback.segment_item_feedback_report_an_issue_in_this",
          { tooltipText: tooltipText }
        )}
      >
        <Button
          className="segment-feedback-btn"
          icon={<BiMessageRoundedError className="segment-feedback-icon" />}
          type="text"
          ghost={true}
          onClick={() => setIsModalOpen(!isModalOpen)}
        />
      </Tooltip>
    </>
  );
};

export default SegmentItemFeedback;
