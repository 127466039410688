import React from "react";
import { Tabs } from "antd";
import ReactPlayer from "react-player";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { useSelector } from 'react-redux';
import MrTranslate, { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import {
  currentUserSelector,
  partnerNameSelector,
} from "/src/views/Auth/Login/selector";

const Help = () => {
  const { TabPane } = Tabs;
  const appType = useSelector(appTypeSelector());
  const partnerName = useSelector(partnerNameSelector());
  const currentUser = useSelector(currentUserSelector());
  const mrIntl = useTranslate();
  const isStudentOrParent =
    currentUser.role === "student" || currentUser.role === "parent";

  return (
    <>
      {/* <h1>Coming Soon</h1> */}
      

      <h4><MrTranslate id={"Help.help"} /></h4>

      {appType != "seb" ? (
        <Tabs tabPosition={"left"}>
          {partnerName != "21k" && !isStudentOrParent && (
            <TabPane tab={mrIntl("Help.getting_started_with_sssessprep")} key="guide-7">
              {/* <h3>Getting started with Assessprep</h3> */}
              <h3><MrTranslate id={"Help.getting_started_with_sssessprep"}/></h3>
              {/* <p>
                            <a href="https://assessprep.zendesk.com/hc/en-us/articles/360017991918-Accessing-AssessPrep-with-ManageBac-Single-Sign-On-SSO-" target="_blank">Click here to view a detailed guide on how to access AssessPrep with ManageBac Single-Sign-On (SSO)</a>
                        </p> */}
              {/* <p>
                            <a href="https://assessprep.zendesk.com/hc/en-us/articles/360017991978-Updating-your-AssessPrep-Password" target="_blank">Click here to view how to update your AssessPrep password</a>
                        </p> */}
              {/* <p>
                            <a href="https://assessprep.zendesk.com/hc/en-us/articles/360017992058-Integrating-AssessPrep-with-ManageBac" target="_blank">Click here to view how the Integration with ManageBac works</a>
                        </p> */}
              {/* <p>
                            <a href="https://assessprep.zendesk.com/hc/en-us/articles/360017992078-Configuring-AssessPrep-Settings-for-Non-ManageBac-Schools-" target="_blank">Click here to view how to configure AssessPrep settings (For Non-ManageBac schools)</a>
                        </p> */}
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841301325713-Setup-for-a-Non-MB-school"
                  target="_blank"
                >
                  {/* Setup for a Non-MB school */}
                  <MrTranslate id={"Help.setup_for_a_non_mb_school"}/>
                </a>
              </p>
            </TabPane>
          )}
          {!isStudentOrParent && (
            <TabPane tab={mrIntl("Help.creating_an_assessment")} key="guide-1">
              <h3><MrTranslate id={"Help.creating_an_assessment"}/></h3>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4831181980177-Creating-a-test-on-AssessPrep"
                  target="_blank"
                >
                  {/* Creating a test on AssessPrep */}
                  <MrTranslate id={"Help.creating_a_test_on_assessPrep"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841224337809-Accessing-editing-and-duplicating-tests"
                  target="_blank"
                >
                  {/* Accessing, editing and duplicating tests  */}
                  <MrTranslate id={"Help.accessing_editing_and_duplicating_tests"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4831275244049-Content-types-on-AssessPrep"
                  target="_blank"
                >
                  {/* Content types on AssessPrep */}
                  <MrTranslate id={"Help.content_types_on_assessPrep"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841222799633-Question-types-on-AssessPrep"
                  target="_blank"
                >
                  {/* Question types on AssessPrep */}
                  <MrTranslate id={"Help.question_types_on_assessPrep"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/5465815913361-Rearrange-adding-collaborators-and-other-actions"
                  target="_blank"
                >
                  {/* Rearrange, adding collaborators, and other actions */}
                  <MrTranslate id={"Help.rearrange_adding_collaborators_and_other_actions"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841228082065-Using-the-AssessPrep-Library-Test-Banks-and-more"
                  target="_blank"
                >
                  {/* Using the AssessPrep Library : Test Banks and more */}
                  <MrTranslate id={"Help.using_the_assessprep_library_test_banks_and_more"} />
                </a>
              </p>
            </TabPane>
          )}
          {!isStudentOrParent && (
            <TabPane tab={mrIntl("Help.delivery_settings")} key="guide-2">
              <h3><MrTranslate id={"Help.delivery_settings"} /></h3>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/6058182285201-Publishing-and-Closing-access-to-a-test"
                  target="_blank"
                >
                  {/* Publishing and Closing access to a test */}
                  <MrTranslate id={"Help.publishing_and_closing_access_to_a_test"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841245203601-Delivery-modes"
                  target="_blank"
                >
                  {/* Delivery modes */}
                  <MrTranslate id={"Help.delivery_modes"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841268533777-Start-condition-Scheduling-a-test-vs-access-with-join-code"
                  target="_blank"
                >
                  {/* Start condition: Scheduling a test vs access with join code */}
                  <MrTranslate id={"Help.start_condition_scheduling_a_test_vs_access_with_join_code"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841268935825-Test-delivery-settings"
                  target="_blank"
                >
                  {/* Test delivery settings */}
                  <MrTranslate id={"Help.test_delivery_settings"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841254523537-Mapping-Students-to-a-test"
                  target="_blank"
                >
                  {/* Mapping Students to a test */}
                  <MrTranslate id={"Help.mapping_students_to_a_test"} />
                </a>
              </p>
            </TabPane>
          )}
          {!isStudentOrParent && (
            <TabPane tab={mrIntl("Help.monitoring_a_test")} key="guide-5">
              <h3><MrTranslate id={"Help.monitoring_a_test"} /></h3>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/5601640089745-Monitoring-an-assessment-as-an-Invigilator-"
                  target="_blank"
                >
                  {/* Monitoring an assessment as an Invigilator? */}
                  <MrTranslate id={"Help.monitoring_an_assessment_as_an_Invigilator"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841247686033-Monitoring-Students-using-the-Live-Invigilation-Dashboard"
                  target="_blank"
                >
                  {/* Monitoring Students using the Live Invigilation Dashboard */}
                  <MrTranslate id={"Help.monitoring_students_using_the_live_Invigilation_dashboard"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841239101329-Monitor-Remotely-via-Zoom-Microsoft-Teams-or-Google-Meet"
                  target="_blank"
                >
                  {/* Monitor Remotely via Zoom, Microsoft Teams or Google Meet */}
                  <MrTranslate id={"Help.monitor_remotely_via_zoom_microsoft_Teams_or_google_Meet"} />

                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841271564689-Monitor-Remotely-via-embedded-AP-video-call"
                  target="_blank"
                >
                  {/* Monitor Remotely via embedded AP video call */}
                  <MrTranslate id={"Help.monitor_remotely_via_embedded_ap_video_call"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841239606033-For-Admins-Monitoring-all-assessments-in-your-school"
                  target="_blank"
                >
                  {/* For Admins: Monitoring all assessments in your school */}
                  <MrTranslate id={"Help.for_admins_monitoring_all_assessments_in_your_school"} />
                </a>
              </p>
              {/* <p>
                            Guide coming soon!
                            https://ap-v2-prod.s3.eu-central-1.amazonaws.com/manual_uploads/help_guides/Grading_Assessments_in_AssessPrep_V2.pdf
                        </p> */}
            </TabPane>
          )}
          {!isStudentOrParent && (
            <TabPane tab={mrIntl("Help.grading_and_analytics")} key="guide-4">
              <h3><MrTranslate id={"Help.grading_and_analytics"} /></h3>
              {/* <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/5528475508369-Anonymous-Grading"
                  target="_blank"
                >
                  <MrTranslate id={"Help.anonymous_grading"} />
                </a>
              </p> */}
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841272501521-Grading-student-responses"
                  target="_blank"
                >
                  {/* Review and Grade student responses */}
                  <MrTranslate id={"Help.grading_student_responses"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841272770321-Annotating-student-hand-written-submissions"
                  target="_blank"
                >
                  {/* Annotating student hand-written submissions */}
                  <MrTranslate id={"Help.grading_and_annotating_hand_written_submissions"} />
                </a>
              </p>
              {/* <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841264360465-Export-to-Excel-and-PDF"
                  target="_blank"
                >
                  <MrTranslate id={"Help.export_to_excel_and_pdf"} />
                </a>
              </p> */}
              {/* <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841276747665-Advanced-Recover-answers-Grading-summary-and-Reset-Submissions"
                  target="_blank"
                >
                  <MrTranslate id={"Help.advanced_recover_answers_grading_summary_and_reset_submissions"} />
                </a>
              </p> */}
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841264360465-Understanding-the-grading-grid-view-"
                  target="_blank"
                >
                  {/* For Students: Viewing Grades and Feedback */}
                  <MrTranslate id={"Help.understanding_the_grading_gridview"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841276747665-Advanced-tips-and-tricks"
                  target="_blank"
                >
                  {/* For Students: Viewing Grades and Feedback */}
                  <MrTranslate id={"Help.advanced_tips_and_tricks"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841284938513-Viewing-results-feedback-as-a-student"
                  target="_blank"
                >
                  {/* For Students: Viewing Grades and Feedback */}
                  <MrTranslate id={"Help.viewing_results_feedback_as_a_student"} />

                </a>
              </p>
              {/* <p>
                            Guide coming soon!
                            https://ap-v2-prod.s3.eu-central-1.amazonaws.com/manual_uploads/help_guides/Grading_Assessments_in_AssessPrep_V2.pdf
                        </p> */}
            </TabPane>
          )}

          <TabPane tab={mrIntl("Help.taking_a_test")} key="guide-3">
            <h3><MrTranslate id={"Help.taking_a_test"} /></h3>
            <p>
              <a
                href="https://assessprep.zendesk.com/hc/en-us/articles/6055572318737-Troubleshooting-issues-with-Safe-Exam-Browser-SEB-"
                target="_blank"
              >
                {/* Troubleshooting issues with Safe Exam Browser (SEB) */}
                <MrTranslate id={"Help.troubleshooting_seb"} />
              </a>
            </p>
            <p>
              <a
                href="https://assessprep.zendesk.com/hc/en-us/articles/4841287667217-Installing-AssessPrep-lockdown-app-for-Windows-Mac-and-iPad"
                target="_blank"
              >
                {/* Installing AssessPrep lockdown app for Windows, Mac and iPad */}
                <MrTranslate id={"Help.installing_assessprep_win_mac_ipad"} />
              </a>
            </p>
            <p>
              <a
                href="https://assessprep.zendesk.com/hc/en-us/articles/4841302240145-Taking-a-test-in-a-browser"
                target="_blank"
              >
                {/* Taking a test in a browser */}
                <MrTranslate id={"Help.taking_test_in_browser"} />
              </a>
            </p>
            <p>
              <a
                href="https://assessprep.zendesk.com/hc/en-us/articles/4841317799825-Taking-a-test-in-lock-down-mode"
                target="_blank"
              >
                {/* Taking a test in lock-down mode */}
                <MrTranslate id={"Help.taking_test_in_lockdown_mode"} />
              </a>
            </p>
            <p>
              <a
                href="https://assessprep.zendesk.com/hc/en-us/articles/4841279890961-Taking-a-test-as-a-guest-user"
                target="_blank"
              >
                {/* Taking a test as a guest user */}
                <MrTranslate id={"Help.taking_test_as_guest_user"} />
              </a>
            </p>
            <p>
              <a
                href="https://assessprep.zendesk.com/hc/en-us/articles/4841280151057-Uploading-hand-written-submissions"
                target="_blank"
              >
                {/* Uploading hand-written submissions */}
                <MrTranslate id={"Help.uploading_handwritten_submissions"} />
              </a>
            </p>
            {/* <p>
                            <ReactPlayer
                                //   width={"100%"}
                                url="https://www.youtube.com/watch?v=G8UUbUNUYuU"
                                controls={true}
                            />
                        </p> */}
          </TabPane>
          {partnerName != "21k" && !isStudentOrParent && (
            <TabPane tab={mrIntl("Help.managebac_integration")}>
              <h3><MrTranslate id={"Help.managebac_integration"} /></h3>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841304893585-Integrating-AssessPrep-with-ManageBac"
                  target="_blank"
                >
                  {/* Integrating AssessPrep with ManageBac */}
                  <MrTranslate id={"Help.managebac_assessprep_integration"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841291248273-Accessing-AssessPrep-via-ManageBac-Single-Sign-On"
                  target="_blank"
                >
                  {/* Accessing AssessPrep via ManageBac Single Sign-On */}
                  <MrTranslate id={"Help.managebac_sso_access"} />
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841282551569-Creating-an-AssessPrep-Online-Assessment-using-a-ManageBac-Task"
                  target="_blank"
                >
                 <MrTranslate id={"Help.managebac_create_assessment"} />
                  {/* Creating an AssessPrep Online Assessment using a ManageBac
                  Task */}
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841291772945-Linking-an-Existing-AssessPrep-Online-Assessment-to-a-ManageBac-Task"
                  target="_blank"
                >
                  <MrTranslate id={"Help.managebac_link_assessment"} />
                  {/* Linking an Existing AssessPrep Online Assessment to a
                  ManageBac Task */}
                </a>
              </p>
              <p>
                <a
                  href="https://assessprep.zendesk.com/hc/en-us/articles/4841315848209-Grading-an-AssessPrep-Online-Assessment-via-the-ManageBac-Gradebook"
                  target="_blank"
                >
                  <MrTranslate id={"Help.managebac_grade_assessment"} />
                  {/* Grading an AssessPrep Online Assessment via the ManageBac
                  Gradebook */}
                </a>
              </p>
            </TabPane>
          )}

          <TabPane tab={mrIntl("Help.troubleshooting_faqs")} key="guide-6">
            <h3><MrTranslate id={"Help.troubleshooting_faqs"} /></h3>
            <p>
              <a
                href="https://assessprep.zendesk.com/hc/en-us/articles/4841335022609-FAQs"
                target="_blank"
              >
                {/* FAQs */}
                <MrTranslate id={"Help.faqs"} />
              </a>
            </p>
            <p>
              <a
                href="https://assessprep.zendesk.com/hc/en-us/articles/4841317260177-Common-Technical-Issues"
                target="_blank"
              >
                {/* Common Technical Issues */}
                <MrTranslate id={"Help.common_technical_issues"} />
              </a>
            </p>
            {/* <p>
                        Guide coming soon!
                        https://ap-v2-prod.s3.eu-central-1.amazonaws.com/manual_uploads/help_guides/Grading_Assessments_in_AssessPrep_V2.pdf
                    </p> */}
          </TabPane>
        </Tabs>
      ) : (
        <MrTranslate id={"Help.coming_soon"}/>
      )}
    </>
  );
};

export default Help;
