import React from "react";
import { Tag, Space } from "antd";
import dayjs from "dayjs";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const UserItemDetail = (props) => {
  console.log("itemDetail props", props);
  const { item } = props;
  const mrIntl = useTranslate()
  const ChildrenNames = ({ children }) => {
    let childrenNamesRender = [];
    if (children.length > 0) {
      childrenNamesRender.push("Children: ");
      children.map((child, i) => {
        childrenNamesRender.push(
          <Tag key={`child-tag-${child.id}`}>
            {child.first_name} {child.last_name}
          </Tag>
        );
      });
    }

    return childrenNamesRender;
  };

  const OrgSubscriptionDetails = ({ org }) => {
    let orgSubscriptionDetails = [];
    org.org_subscriptions_attributes &&
      org.org_subscriptions_attributes.map((sub, i) => {
        // console.log("teacher", teacher)
        if (sub.status === "Customer" || sub.status === "Trial") {
          let tagColor =
            sub.status === "Customer"
              ? "success"
              : sub.status === "Trial"
              ? "processing"
              : "";
          let subTag = (
            <Tag color={tagColor} key={`sub-tag-${sub.id}`}>
              {sub.name} - {sub.status}
            </Tag>
          );
          // let subTag = `${sub.name}-${sub.status}-${sub.max_users} |`
          orgSubscriptionDetails = orgSubscriptionDetails.concat(subTag);
        }
      });
    return orgSubscriptionDetails;
  };

  return (
    <React.Fragment>
      <h2>{item.name}</h2>
      <Space>
        {item.role && <Tag>{item.role}</Tag>}
        {item.email && <Tag>{item.email}</Tag>}
        {item.role === "student" && item.s_code && <Tag>{item.s_code}</Tag>}
        <Tag>{dayjs(item.created_at).format("MMM DD, YYYY")}</Tag>
      </Space>

      <br></br>
      <br></br>

      <div>
        {item.role === "parent" && <ChildrenNames children={item.children} />}
        <br></br>
        <br></br>
      </div>

      <Space>
        {item.org_id && <Tag>{item.org.name}</Tag>}
        {["admin", "teacher"].indexOf(item.role) > -1 && (
          <OrgSubscriptionDetails org={item.org} />
        )}
      </Space>

      <div className="profile-change-password">
        <h4>{mrIntl("UserItemDetail.change_password")}</h4>
        {props.renderForm({
          name: "usersSetPassword",
          config: {
            isModal: false,
            visible: true,
            submitConfig: {
              hideForm: true,
              success: { showMessage: true, message: mrIntl("UserItemDetail.password_updated") },
              error: {
                showMessage: true,
                message: mrIntl("UserItemDetail.error_in_updating_password"),
              },
            },
          },
          resource: item,
        })}
      </div>
    </React.Fragment>
  );
};

UserItemDetail.defaultProps = {};

UserItemDetail.propTypes = {};

export default UserItemDetail;
