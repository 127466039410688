import React, { useState, useEffect } from "react";
import { Layout, Alert, message} from "antd";
import classNames from "classnames";
import styled from "styled-components";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import LogoImage from "../../../assets/images/logo.png";
import { useRouteMatch, useLocation, Redirect, useHistory } from "react-router-dom";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { CheckInternetConnectivity } from "/src/lib/CheckInternetConnectivity/CheckInternetConnectivity";
import CheckBrowser from "/src/lib/CheckBrowser/CheckBrowser";
// import { actions } from "/src/views/Experiences/redux"
import { activeAdjustedExperienceSelector, getInternetStatusSelector, getOfflineDBStatusSelector } from "/src/views/Experiences/selector";
import { CheckAndSetAppType } from "/src/App/OfflineApp/CheckAndSetAppType";
import AppNotice from "/src/components/UI/AppNotice/AppNotice";
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector } from "/src/views/Auth/Login/selector";
import { appTypeSelector, quitRequestedSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { actions as embeddedResourcesActions } from "/src/views/EmbeddedResources/redux";
import { setQueryParams } from "/src/lib/utils/helperMethods";
import ZoomableImageContainer from "/src/components/UI/ZoomableImageContainer/ZoomableImageContainer";
import { actions as loginReduxActions } from "/src/views/Auth/Login/redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import HowToFixBtn from "./HowToFixBtn"
import CheckAIUsage from "/src/components/UI/CheckAIUsage/CheckAIUsage";
import ChooseAIModelModal from "/src/components/UI/ChooseAIModelModal/ChooseAIModelModal";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { CheckNativeAppUpdate } from "/src/App/OfflineApp/CheckNativeAppUpdate";
import QuitRequested from "/src/components/UI/QuitRequested";
import { getDeviceInfo, checkIfUnsupportedApp } from "/src/App/OfflineApp/offlineAppHelper";
import UpgradePlanModal from "/src/lib/UpgradePlanFeature/UpgradePlanModal";

const ExperienceLayout = (props) => {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState(false);
  const [showAIModelModal, setShowAIModelModal] = useState(false);
  const [autoUpdateChecking, setAutoUpdateChecking] = useState(true);
  const { isAuthenticated, tokenPresent} = props;
  console.log("ExperienceLayout props", props);
  console.log("ExperienceLayout this.props.location", location);
  console.log("isAuthenticated, tokenPresent", isAuthenticated, tokenPresent);
  const mrIntl = useTranslate()
  const internetStatus = useSelector(getInternetStatusSelector());
  const offlineDBStatus = useSelector(getOfflineDBStatusSelector());
  const currentUser = useSelector(currentUserSelector());
  const appType = useSelector(appTypeSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const embedded = useSelector(getEmbeddedSelector());
  const appRegion = useSelector(appRegionSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const quitRequested = useSelector(quitRequestedSelector());
  const isChina = appRegion === "china" ? true : false;
  const chooseAIModalEnabled = enabledFeatures.choose_ai_model;
  // let queryParams = {};
  let envIsNotProd = import.meta.env.VITE_MODE !== "production";
  let envIsNotDemo = import.meta.env.VITE_MODE !== "demo" && import.meta.env.VITE_MODE !== "demo-internal";
  const isUnsupportedApp = checkIfUnsupportedApp(appType, enabledFeatures, currentUser);

  const handleKeyboardShortcuts = (e) => {
    // console.log("handleKeyboardShortcuts - e", e);
    // Shortcut to select which AI model to use
    if ((e.altKey || e.metaKey) && e.key === "k") {
      e.preventDefault();
      setShowAIModelModal(true);
    }
    // if (e.ctrlKey && e.shiftKey && e.key === "K") {
    //   e.preventDefault();
    // }
  }

  useEffect(() => {
    // Pending: implement auth when token present in URL
    let queryParams = {};
    let localTokenPresent = tokenPresent
    if (location) {
      queryParams = queryString.parse(location.search);
      console.log(
        "login-check - tokenPresent, isAuthenticated, queryParams",
        tokenPresent,
        isAuthenticated,
        queryParams
      );
      // login with token - from Applayout since isPrivate now false
      if (queryParams.token) {
        localStorage.setItem("token", queryParams.token);
        localTokenPresent = true
        setQueryParams({
          url: location.pathname,
          history: history,
          location: location,
          removeParams: ['token'],
        }); // removing token to avoid multiple login requests to BE
        // if(!isAuthenticated){
        //   dispatch(loginActions.checkAuthState());
        // }
      }
  
      // login with jwt
      if (queryParams.jwt) {
        dispatch(
          embeddedResourcesActions.setJwtSuccess({
            jwt: queryParams.jwt,
            client: "mb",
          })
        );
        // removed to make sure if i am already logged in as user 1, and user 2 tries to login - i should be logged in as user 2 now
        // if (!isAuthenticated) {
          const uuidRegex = /\/e\/tests\/([A-Z0-9]+)/;
          const match = location.pathname.match(uuidRegex);
          const expUUID = match ? match[1] : null;
          dispatch(
            loginActions.verifyJwt({ jwt: queryParams.jwt, client: "mb", expUUID: expUUID })
          );
          // return <Spinner />;
        // }
        setQueryParams({
          url: location.pathname,
          history: history,
          location: location,
          removeParams: ['jwt'],
        });
      }
    }

    // login
    // in case of queryParams.token, this auth below is being used. But not needed in case of queryParams.jwt
    if(localTokenPresent){
      if(!isAuthenticated && !queryParams.jwt){
        // NOT dispatching if jwt is present - because verifyJWT will handle - this was setting isAuthenticated true and showing the test even before verifying the jwt
        // dispatch checkAuthState action
        console.log("double-render-check redirect check");
        console.log("double-render-check = login-check - calling checkAuthState", tokenPresent, isAuthenticated)
        dispatch(loginActions.checkAuthState());
      }
    }
    if(!localTokenPresent && !queryParams.jwt){
      // if no token in url and not in LS and no jwt in url - then just opening the test page without logging in and wihtout anything in url - so redirect to login page
      setShouldRedirectToLogin(true)
      
    }

    const partnerName = window.name == "21k-assessprep-embed" ? "21k" : null;
    if(partnerName){
      dispatch(loginReduxActions.setPartnerNameSuccess({partnerName: partnerName}))
    }

  }, [])

  useEffect(() => {
    // console.log("embedded, isChina, envIsNotProd, currentUser.role", embedded, isChina, envIsNotProd, currentUser.role);
    if(!embedded && !isChina && (((envIsNotProd || chooseAIModalEnabled) && (currentUser.role === "admin" || currentUser.role === "teacher")) || currentUser.role === "qb_author")){
      document.addEventListener("keydown", handleKeyboardShortcuts);
    }
    return () => {
      // cleanup
      document.removeEventListener("keydown", handleKeyboardShortcuts);
    }
  }, [embedded, isChina, currentUser.role])

  if (import.meta.env.VITE_MAINTENANCE_MODE == "true") {
    return <Redirect to="/maintenance-mode/maintenance-page" />;
  }

  if(shouldRedirectToLogin){
    return <Redirect to={'/auth/login'} />
  }

  
  if(!isAuthenticated){
    console.log("double-render-check holding user - isAuthenticated false");
    return <Spinner />;
  }
  
  console.log("double-render-check user now authenticated - proceed", isAuthenticated);

  console.log("intenetStatus, offlineDBStatus in useSelector ==>",internetStatus,offlineDBStatus);
  let offlineAlert, offlineDBAlert;
  if (internetStatus != "online") {
    const isOfflineMode = activeExperience.settings && activeExperience.settings.mode == "offline";
    const shouldAddActionBtn = currentUser.role == "student" && appType === "mac" && isOfflineMode;
    offlineAlert = (
      <Alert
        message={mrIntl("ExperienceLayout.it_seems_you_are_not_connected_to_the_internet")}
        banner
        closable={false}
        className={"internet-alert"}
        action={shouldAddActionBtn && <HowToFixBtn />}
      />
    );
  }

  if (offlineDBStatus != "open" && currentUser.role == "student") {
    offlineDBAlert = (
      <Alert
        // message="Something went wrong. Could not enable offline support (local backups) on this browser."
        message={mrIntl("ExperienceLayout.something_went_wrong_could_not_enable_offline_support_local_backups_on_this_browser")}
        banner
        closable={false}
        className={"offline-alert"}
      />
    );
  }
  if (isUnsupportedApp) {
    return <Redirect to="/unsupported" />;
  }

  return (
    <div style={{ minHeight: "100%" }}>
      <CheckAndSetAppType />
      <CheckNativeAppUpdate
        handleSetAutoUpdateChecking={(value) => setAutoUpdateChecking(value)}
      />
      <CheckInternetConnectivity withLog={currentUser.role === "student"} />
      <CheckBrowser />
      <CheckAIUsage />
      <ZoomableImageContainer />
      {showAIModelModal && (
        <ChooseAIModelModal setShowAIModelModal={setShowAIModelModal} />
      )}
      {/* <AppNotice /> */}
      {offlineAlert}
      {offlineDBAlert}
      {quitRequested && <QuitRequested experience={activeExperience}/>}
      <UpgradePlanModal />
      <div>{!autoUpdateChecking && !quitRequested && props.children}</div>
    </div>
  );

  // return (
  //   <BaseLayout>
  //     <ContentTop>
  //       <StyledLogo src={LogoImage} />
  //       <h1 class="sign-in-text">Sign in to AssessPrep</h1>
  //     </ContentTop>
  //     <div>{props.children}</div>
  //     <ContentMain className="auth-content-main">{!props.children ? props.main : ''}</ContentMain>
  //   </BaseLayout>
  // );
};
export default ExperienceLayout;
