import React, { useState } from "react";
import { Modal, Tooltip, Row, Tag, Space } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import {
  InfoCircleTwoTone,
  CopyOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { countries } from "/src/lib/utils/countries";
import { writeToClipboardPolyfill } from "/src/lib/utils/helperMethods";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const OrgStatsModal = (props) => {
  const { item } = props;

  const [visible, setVisible] = useState(false);
  const mrIntl = useTranslate()
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  let country = countries.countries.find((c) => c.code === item.country);
  const upgradeDetails = item?.custom_fields?.request_plan_upgrade_details;
  let opWiseDetails = [];
  item.org_stats.ops_stats.map((program, i) => {
    let gradeWiseDetails = [];
    program.grades.map((grade, i) => {
      let gradeColumn = (
        <Space key={`grade-tag-${grade.id}`} direction="vertical">
          <Tag>
            {grade.name} - {grade.label} -{" "}
            {program.gradewise_students_count[grade.id]}
          </Tag>
        </Space>
      );
      gradeWiseDetails.push(gradeColumn);
    });

    let programColumn = (
      <Space
        key={`program-tag-${program.id}`}
        direction="vertical"
        style={{ overflowX: "auto" }}
      >
        <h4>{program.label}</h4>
        <Tag><MrTranslate id={"CommonText.students"}/> {program.students_count}</Tag>
        <Tag><MrTranslate id={"CommonText.subjects"}/> {program.subjects_count}</Tag>
        <Tag><MrTranslate id={"CommonText.classes"}/> {program.groups_count}</Tag>
        <h5><MrTranslate id={"CommonText.grades"}/></h5>
        {gradeWiseDetails.length > 0 ? (
          gradeWiseDetails
        ) : (
          <i>No grades enabled</i>
        )}
      </Space>
    );

    opWiseDetails.push(programColumn);
  });

  let orgContactsRender = [];
  orgContactsRender.push(<h3>Contacts</h3>);
  item.org_contacts.map((contact, i) => {
    orgContactsRender.push(
      <div style={{ width: "100%" }}>
        {contact.primary ? <CheckOutlined /> : ""} {contact.title} -{" "}
        {contact.email}
      </div>
    );
  });

  let orgQBsRender = [];
  orgQBsRender.push(<h4>QBs enabled: </h4>);
  item.org_questionbanks.map((qb, i) => {
    orgQBsRender.push(qb.custom_fields.title);
  });

  const renderOneRosterLogs = () => {
    const logs = item?.custom_fields?.oneroster_logs;
    
    return logs ? (
      <pre style={{ whiteSpace: "pre-wrap" }}>
        =======================OneRoster Logs=======================
        <br />{logs.errors?.map((error, index) => `ERROR ${index + 1}:: ${error}`).join("\n")}<br />
        <br />STATUS:: {logs?.status}
        <br />LAST UPDATED AT:: {logs?.last_updated_at}
        <br />SYNC STARTED AT:: {logs?.sync_started_at}
      </pre>
    ) : null;
  }; 

  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Share
      </Button> */}
      <Tooltip title={mrIntl("CommonText.view_details")}>
        {/* <Button size="small" shape="circle" icon={<InfoOutlined />} onClick={showModal}/> */}
        <InfoCircleTwoTone className="m-l-10" onClick={showModal} />
      </Tooltip>
      <Modal
        title={`[${country && country.name}] ${item.name} [uuid: ${item.uuid}]`}
        open={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        wrapClassName="experience-info-modal"
      >
        <Row>
          <Space>
            <Tag>
             <MrTranslate id={"CommonText.total_assessments_delivered"}/>:{" "}
              {item.org_stats.org_exp_stats.total_delivered_experiences}
            </Tag>
            <Tag>
              <MrTranslate id={"CommonText.total_submissions"}/>:{" "}
              {item.org_stats.org_exp_stats.total_submissions}
            </Tag>
          </Space>
        </Row>
        <Row className="m-t-25">
          <Space>
            <Tag>Admins: {item.org_stats.org_users_count.total_admins}</Tag>
            <Tag>Teachers: {item.org_stats.org_users_count.total_teachers}</Tag>
            <Tag>Students: {item.org_stats.org_users_count.total_students}</Tag>
            <Tag>Parents: {item.org_stats.org_users_count.total_parents}</Tag>
          </Space>
        </Row>
        <Row className="m-t-25">{orgQBsRender}</Row>
        <Row className="m-t-25">
          <Space align="baseline">{opWiseDetails}</Space>
        </Row>

        <Row className="m-t-25">{orgContactsRender}</Row>

        <Row className="m-t-25">
          <h5>Last sync log</h5>
          <br></br>
          <pre>{item.sync_log}</pre>
        </Row>

        <Row className="m-t-25">
          <h5>Last sync log by OneRoster</h5>
          <br></br>
          <pre>{renderOneRosterLogs()}</pre>
        </Row>

        <Row className="m-t-25">
          <h5>
            <CopyOutlined
              onClick={() => {
                writeToClipboardPolyfill(item.migration_log);
                message.success("Log copied!");
              }}
            />{" "}
            Last migration log [Total v1 tests:{" "}
            {item.custom_fields.migration_info &&
              item.custom_fields.migration_info.total_v1_tests}
            ]
          </h5>
        </Row>
        <Row className="m-t-10">
          <pre>{item.migration_log}</pre>
        </Row>
        {upgradeDetails ? <Row className="m-t-25">
          <h4>Users who have requested plan upgrade: {upgradeDetails.length}</h4>
          {upgradeDetails.length > 0 && upgradeDetails.map((detail, i) => {
            return (
              <div key={i}>
                {JSON.stringify(detail)}
              </div>
            );
          })}
        </Row> : null}
        {/* {JSON.stringify(item.org_stats)} */}
      </Modal>
    </>
  );
};

export default OrgStatsModal;
