import React from "react";
import { Button, Space, Menu, Dropdown, Typography } from "antd";
import { useDispatch } from "react-redux";
import {
  UserOutlined,
  // UserSwitchOutlined
} from "@ant-design/icons";
import { actions as loginActions } from "/src/views/Auth/Login/redux";

const DemoUserSwitcher = (props) => {
  console.log("DemoUserSwitcher props", props);

  // const history = useHistory();
  const dispatch = useDispatch();
  const { Text } = Typography;

  const handleMenuClick = (obj) => {
    // console.log( "email to login to", obj );
    // login user with id = key
    let email = `${obj.key}@eduvo.com`;
    dispatch(loginActions.switchIdentity({ email: email }));
  };

  let usersAvailable = [
    { key: "sharon", name: "Sharon Arese", role: "Admin" },
    { key: "risa", name: "Risa Aoki", role: "Teacher" },
    { key: "chloe", name: "Chloe Epelbaum", role: "DP Student" },
    { key: "david", name: "David Etuhu", role: "DP Student" },
    { key: "rachel.epelbaum", name: "Rachel Epelbaum", role: "MYP Student" },
  ];

  let menuItems = usersAvailable.map((user, i) => {
    return (
      <Menu.Item key={user.key}>
        <Space
          align="baseline"
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <span>{user.name}</span>
          <Text type="secondary" style={{ fontSize: "12px" }}>
            {user.role}
          </Text>
        </Space>
      </Menu.Item>
    );
  });

  const menu = <Menu onClick={handleMenuClick}>{menuItems}</Menu>;

  return (
    <>
      <Dropdown overlay={menu}>
        <Button shape="round">
          Switch user
          <UserOutlined />
        </Button>
      </Dropdown>
    </>
  );
};

DemoUserSwitcher.defaultProps = {};

DemoUserSwitcher.propTypes = {};

export default DemoUserSwitcher;
