import React from "react";
import { Link } from "react-router-dom";
import { MrFormComponent } from "mr_react_framework";
import { Divider } from "antd";
import "../Login/Login.scss";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../Login/redux";
import { forgotpasswordloadingSelector } from "../Login/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const getForgotPassForm = (mrIntl) => {
  return {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      opts.values.email = opts.values.email.toLowerCase().trim();
      // trim and downcase this
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        email: {
          type: "string",
          format: "email",
          defaultValue: "sample@email.com",
          placeholder: mrIntl("ForgotPassword.email"),
          className: "login-email",
          rules: [
            {
              required: true,
              message: mrIntl("ForgotPassword.please_enter_a_valid_email")
            },
          ],
        },
      },
    },
  };
};

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector(forgotpasswordloadingSelector);
  const mrIntl = useTranslate()
  const jsonForm = getForgotPassForm(mrIntl);
  const handleSubmit = (data) => {
    const finalData = { user: { ...data } };
    console.log("handleSubmit", finalData);
    dispatch(
      actions.forgotpassword(data, {
        success: {
          showMessage: true,
          // message: "Password reset link sent. Please check your email.",
          message: mrIntl("ForgotPassword.password_reset_link_msg")
        },
      })
    );
  };

  const mainForm = (
    <MrFormComponent
      key={"mrForm"}
      {...props}
      visible={true}
      resourceForm={{
        form: { ...jsonForm },
        config: { isModal: false, visible: true },
      }}
      item={{}}
      loading={loading}
      // resourceName={"forgotPassword"}
      // formConfig={{actions: {submitText: "Login", showCancelBtn: false}}}
      formConfig={{ actions: { submitText: mrIntl("CommonText.submit"), showCancelBtn: false } }}
      formProps={{}}
      // jsonForm={jsonForm}
      // schema={jsonForm.schema}
      handleOk={handleSubmit}
      handleSubmit={handleSubmit}
      // styleProps={styleProps}
    />
  );

  return (
    <div className={"forgot-pass-main auth-form-container"}>
      {/* <h3>Forgot password</h3> */}
      <h3>{mrIntl("ForgotPassword.forgot_password")}</h3>
      <br></br>
      {mainForm}
      <Divider>{mrIntl("CommonText.or")} </Divider>
      <div class={"back-to-login-link"}>
        <Link to={"/auth/login"}>{mrIntl("CommonText.go_back_to_login")}</Link>
      </div>
    </div>
  );
};

ForgotPassword.defaultProps = {};

ForgotPassword.propTypes = {};

export default ForgotPassword;
