import React from 'react';
import './Overlay.scss'; 
const Overlay = ({ children,onClick}) => {
  return (
    <div className="overlay-container" onClick={onClick}>
        {children}
    </div>
  );
};
export default Overlay;
