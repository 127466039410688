import React from "react";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import { MrSelect } from "mr_react_framework";
import { useDispatch, useSelector } from "react-redux";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import { studentNameFormatSelector } from "../selector";

const SelectGradingStatusFilter = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.label, value: item.value};
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        {label: <MrTranslate id={"ExperienceUsersFilter.ungraded"}/>, value: "ungraded"}, // incomplete grading
        {label: <MrTranslate id={"ExperienceUsersFilter.unpublished"}/>, value: "unpublished"},
      ],
      className: "select-grading-status",
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"ExperienceUsersFilter.status"}/>,
      style: { width: "100%" },
      allowClear: true
    },
  }
})
// const SelectViewByNameFilter = MrSelect({
//   // actions: genericActions,
//   // resourceName: "grades",
//   config: {
//     // url: "/grades",
//     // params: {by_org_programme_id: 3}, //get from previous filter
//     // processData: (data, props) => {
//     //   console.log("data select groups", data);
//     //   console.log("props select groups", props);
//     //   return data.map((item) => {
//     //     console.log("class selector item", item);
//     //     return {label: item.label, value: item.value};
//     //   });
//     // },
//     searchApi: false,
//     widgetConfig: {
//       className: "select-grading-status",
//       showSearch: false,
//       filterOption: true,
//       optionFilterProp: "label",
//       placeholder: "View by",
//       style: { width: "100%" },
//       allowClear: false,
//       defaultValue: "First Name"
//     },
//   }
// })

const SelectCriteriaFilter = MrSelect({
  config: {
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"ExperienceUsersFilter.select_criteria"}/>,
      // style: { width: "120px" },
      allowClear: true
    },
  }
})

const SelectGroupFilter = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.label, value: item.value};
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"ExperienceUsersFilter.class_placeholder"} />,
      style: { width: "100%" },
      allowClear: true,
      // mode: "multiple"
    },
  }
})

const ExperienceUsersFilter = (props) => {
  // return <span>User Filter</span>;
  const { currentUser } = props;
  const dispatch = useDispatch();
  console.log("ExperienceUsersFilter props  =>", props)
  // const activeExperienceId = useSelector(activeAdjustedExperienceIdSelector())
  // const activeExperience = useSelector(state => state.experiences.get("experiences").find((item) => item.id == activeExperienceId))
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const studentNameFormat = useSelector(studentNameFormatSelector())
  console.log("ExperienceUsersFilter activeExperience", activeExperience)

  let extraFilters = [];

  if(currentUser && currentUser.role != "student"){
    let by_eu_status = null
    let by_group_id = null
    // let by_group_id = []
    
    if(props.by_eu_status){
      by_eu_status = props.by_eu_status
    }
    if(props.by_group_id){
      by_group_id = parseInt(props.by_group_id)
      // by_group_id = props.by_group_id
    }

    let gradingStatusBtn = <SelectGradingStatusFilter key="experience-users-filter-btn" dropdownMatchSelectWidth={false} value={by_eu_status} onChange={(value) => {props.setParams({by_eu_status: value})}}/>
    
    extraFilters.push(gradingStatusBtn)

    let groups = activeExperience.groups 
    if(groups && groups.length > 0){
      let groupOptions = groups.map((g,i) => {
        console.log("groupOptions", g)
        return {label: g.name, value: g.id}
      })
      console.log("groupOptions", groupOptions)
      let groupFilterBtn = <SelectGroupFilter key="experience-users-group-filter-btn" dropdownMatchSelectWidth={false} value={by_group_id} onChange={(value) => {props.setParams({by_group_id: value})}} config={{widgetConfig:{ options: groupOptions}}}/>
      
      extraFilters.push(groupFilterBtn)

    }

    // function getAvailableOptions() {
    //   return (studentNameFormat === "first_name")
    //     ? [{ label: "Last Name", value: "last_name" }]
    //     : [{ label: "First Name", value: "first_name" }];
    // }

    // let selectViewByNameFilter = <SelectViewByNameFilter key="experience-users-filter-btn" dropdownMatchSelectWidth={false} onChange={(value) => {
    //   dispatch(
    //     experienceUsersActions.setStudentNameFormatSuccess({
    //       student_name_format: value,
    //     })
    //   );
    // }}
    //   config={{
    //     widgetConfig: {
    //       options: getAvailableOptions()
    //     }
    //   }} />

    // extraFilters.push(selectViewByNameFilter)
  }
  if(activeExperience.criterium_associations && (activeExperience.rubric.type_c === "myp_achievement_level" || activeExperience.rubric.type_c === "criteria_with_points")) {
    let caLabels = activeExperience.criterium_associations.map((ca,i) => {
      console.log("criterium_associations", ca)
      let label = `${ca.custom_fields.title} ${ca.custom_fields.label}` || ""
      return {label: label, value: ca.criterium_id}
    })
    console.log("caLabels =====>", caLabels);
    if(caLabels) {
      let selectCriteriaFilter = <SelectCriteriaFilter key="criteria-filter-btn" dropdownMatchSelectWidth={false} 
      onChange={(value) => {
        dispatch(
          experienceUsersActions.setCurrentCriteriaIdSuccess({
            currentCriteriaId: value,
          })
        ); //setStudentNameFormatSuccess
      }}
       config={{widgetConfig:{ style: {width:"150px"},  options: caLabels}}}/>
  
      extraFilters.push(selectCriteriaFilter)
    }
  }
  

  // extraFilters.push(<ResetFilter key="experience-users-filter-reset-filter" setParams={props.setParams} />)
  // TODO: reset filter needs to remove search text also adn figure out space

  return extraFilters;
};

ExperienceUsersFilter.defaultProps = {};

ExperienceUsersFilter.propTypes = {};

export default ExperienceUsersFilter;
