import React, { useState, useEffect } from "react";
import { FormRenderer } from "mr_react_framework";
import { buildOptionsArr } from "/src/lib/utils/helperMethods";
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent"
import AudioForm from "../RTCAudio/AudioForm";
import VideoForm from "../Video/VideoForm";
import ImageForm from "../Image/ImageForm";
// import FlashCard from "../FlashCard/FlashCard";
import PDFForm from "../PDF/PDFForm";
import SimulationForm from "../Simulation/SimulationForm";
import EmbedForm from "../Embed/EmbedForm";
import Accordion from "../Accordion/Accordion";

import { validateJSON } from "/src/views/Segments/InteractiveHelpers"
import MrTranslate, { mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import DocumentForm from "../Document/DocumentForm";
import PPTForm from "../PPT/PPTForm";
// import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

let segmentConfig = {
  createMode: true,
  editMode: false,
}

const descriptionForm = (props) => {
  console.log("line number 23", props);
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      caption: {
        type: "string",
        title: mrIntl("CommonText.description"),
        // title: "Description",
        widget: CKETextContent,
        widgetConfig: {
          ...segmentConfig,
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value, callback) {
            if (!value) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(mrIntl("CommonText.required_msg"))
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const accordionForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  console.log("line number 63");
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      resource_json: {
        type: "string",
        title: mrIntl("ResourceForm.accordion"),
        // title: "Accordion",
        listType: "picture-card",
        widget: Accordion,
        widgetConfig: {
          ...segmentConfig,
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('accordian', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const audioForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      resource_json: {
        type: "string",
        title: mrIntl("ResourceForm.audio_files"),
        // title: "Audio files",
        listType: "picture-card",
        widget: AudioForm,
        widgetConfig: {
          ...segmentConfig,
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('audio', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const videoForm = (props) => {
  const { formObjProps, setFormObjProps, sourceValues, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      resource_json: {
        type: "string",
        title: mrIntl("ResourceForm.video_files"),
        // title: "Video files",
        listType: "picture-card",
        widget: VideoForm,
        widgetConfig: {
          ...segmentConfig,
          sourceValues: sourceValues,
          segment_id: sourceValues.id,
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('video', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

// const flashCardForm = {
//   type: "object",
//   properties: {
//     resource_json: {
//       type: "string",
//       title: "Flash Card",
//       widget: FlashCard,
//       widgetConfig: {
//         ...segmentConfig,
//       },
//     }
//   }
// }

const imageForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps
  
  return {
    type: "object",
    properties: {
      resource_json: {
        type: "string",
        title: mrIntl("ResourceForm.image_files"),
        // title: "Image files",
        widget: ImageForm,
        widgetConfig: {
          ...segmentConfig,
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('image', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const pdfForm = (props) => {
  console.log("pdfForm props ==>", props)
  const { formObjProps, setFormObjProps, mrIntl, resourceAttributes} = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      resource_json: {
        type: "string",
        title: mrIntl("ResourceForm.pdf_file"),
        // title: "PDF files",
        listType: "picture-card",
        widget: PDFForm,
        widgetConfig: {
          ...segmentConfig,
          ...resourceAttributes
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('pdf', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const simulationForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      resource_json: {
        type: "string",
        title: mrIntl("ResourceForm.simulation_files"),
        // title: "Simulation files",
        listType: "picture-card",
        widget: SimulationForm,
        widgetConfig: {
          ...segmentConfig,
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('simulation', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}

const embedForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props
  const { validateTrigger } = formObjProps

  return {
    type: "object",
    properties: {
      resource_json: {
        type: "string",
        title: mrIntl("ResourceForm.embed_files_title"),
        listType: "picture-card",
        widget: EmbedForm,
        widgetConfig: {
          ...segmentConfig,
        },
        trigger: 'onSubmit',
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [{
          validator(rule, value) {
            const validation = validateJSON('embed', value)
            if (!validation.success) {
              setFormObjProps({
                ...formObjProps,
                validateTrigger: 'onChange'
              })
              return Promise.reject(validation.messages)
            } else {
              return Promise.resolve()
            }
          },
        }]
      },
    }
  }
}
const documentForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props;
  const { validateTrigger } = formObjProps;
  console.log("documentForm ==>>", formObjProps, setFormObjProps);

  return {
    type: "object",
    properties: {
      resource_json: {
        type: "string",
        title: "Document files",
        listType: "picture-card",
        widget: DocumentForm,
        widgetConfig: {
          ...segmentConfig,
        },
        trigger: "onSubmit",
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [
          {
            validator(rule, value) {
              const validation = validateJSON("doc", value);
              if (!validation.success) {
                setFormObjProps({
                  ...formObjProps,
                  validateTrigger: "onChange",
                });
                return Promise.reject(validation.messages);
              } else {
                return Promise.resolve();
              }
            },
          },
        ],
      },
    },
  };
};

const pptForm = (props) => {
  const { formObjProps, setFormObjProps, mrIntl } = props;
  const { validateTrigger } = formObjProps;
  console.log("pptForm ==>>", formObjProps, setFormObjProps);

  return {
    type: "object",
    properties: {
      resource_json: {
        type: "string",
        title: "PPT files",
        listType: "picture-card",
        widget: PPTForm,
        widgetConfig: {
          ...segmentConfig,
        },
        trigger: "onSubmit",
        formItemConfig: { validateTrigger: validateTrigger },
        rules: [
          {
            validator(rule, value) {
              const validation = validateJSON("ppt", value);
              if (!validation.success) {
                setFormObjProps({
                  ...formObjProps,
                  validateTrigger: "onChange",
                });
                return Promise.reject(validation.messages);
              } else {
                return Promise.resolve();
              }
            },
          },
        ],
      },
    },
  };
};

const DescriptionChildComponent = (props) => {
  let { getFieldValue, sourceValues, setFieldsValue, formInstance } = props
  const includeCaption = getFieldValue(["resource_segment_attributes", "include_caption"])
  const mrIntl = useTranslate();
  const [formObjProps, setFormObjProps] = useState({
    validateTrigger: 'onSubmit',
  })

  let finalRender = null
  if (includeCaption) {
    finalRender = <FormRenderer
      formObj={descriptionForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"resource_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  } 
  // else {
  //   setFieldsValue({ resource_segment_attributes: { caption: null } })
  // }
  return finalRender
}

const ResourceJSONChildComponent = (props) => {
  console.log("ResourceJSONChildComponent props ==>", props)
  let { getFieldValue, sourceValues, formInstance, setFieldsValue } = props
  const resourceType = getFieldValue(["resource_segment_attributes", "resource_type"]);

  console.log('sourceValues ==>', sourceValues)
  const mrIntl = useTranslate();

  useEffect(() => {
    if (sourceValues.resource_segment_attributes.caption) {
      setFieldsValue({ resource_segment_attributes: { include_caption: true } })
    }
  }, [])

  const [formObjProps, setFormObjProps] = useState({
    validateTrigger: 'onSubmit',
  })

  let finalRender = null;
  if (resourceType === "accordion" || (!resourceType && sourceValues.id && sourceValues.resource_type === "accordion")) {
    finalRender = <FormRenderer
      formObj={accordionForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl  })}
      propertyKey={"resource_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (resourceType === "audio" || (!resourceType && sourceValues.id && sourceValues.resource_type === "audio")) {
    finalRender = <FormRenderer
      formObj={audioForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"resource_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  if (resourceType === "video" || (!resourceType && sourceValues.id && sourceValues.resource_type === "video")) {
    finalRender = <FormRenderer
      formObj={videoForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, sourceValues: sourceValues, mrIntl: mrIntl })}
      propertyKey={"resource_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  if (resourceType === "image" || (!resourceType && sourceValues.id && sourceValues.resource_type === "image")) {
    finalRender = <FormRenderer
      formObj={imageForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"resource_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  // if (resourceType === "flash_card" || (!resourceType && sourceValues.id && sourceValues.resource_type === "flash_card")) {
  //   finalRender = <FormRenderer
  //     formObj={flashCardForm}
  //     propertyKey={"resource_segment_attributes"}
  //     sourceValues={sourceValues}
  //     formInstance={formInstance}
  //   />
  // }

  if (resourceType === "pdf" || (!resourceType && sourceValues.id && sourceValues.resource_type === "pdf")) {
    finalRender = <FormRenderer
      formObj={pdfForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl, resourceAttributes: sourceValues.resource_segment_attributes})}
      propertyKey={"resource_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  if (resourceType === "simulation" || (!resourceType && sourceValues.id && sourceValues.resource_type === "simulation")) {
    finalRender = <FormRenderer
      formObj={simulationForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"resource_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  if (resourceType === "embed" || (!resourceType && sourceValues.id && sourceValues.resource_type === "embed")) {
    finalRender = <FormRenderer
      formObj={embedForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"resource_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  if (resourceType === "doc" || (!resourceType && sourceValues.id && sourceValues.resource_type === "doc")) {
    finalRender = <FormRenderer
      formObj={documentForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"resource_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  if (resourceType === "ppt" || (!resourceType && sourceValues.id && sourceValues.resource_type === "ppt")) {
    finalRender = <FormRenderer
      formObj={pptForm({ formObjProps: formObjProps, setFormObjProps: setFormObjProps, mrIntl: mrIntl })}
      propertyKey={"resource_segment_attributes"}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  return finalRender;
}

const resourceForm = (mrIntl) => {
  console.log("resource form ===props==>", mrIntl);
  
  return {
  type: "object",
  properties: {
    resource_type: {
      type: "string",
      // title: "Resource type",
      setInitialValue: true,
      widget: "RadioGroupWidget",
      widgetConfig: {
        options: buildOptionsArr(['audio', 'video', 'pdf', 'simulation', 'image', 'embed'])
      },
      className: "hidden"
    },
    title: {
      type: "string",
      span: 18,
      placeholder: mrIntl("ResourceForm.title"),
      // placeholder: <MrTranslate id = {"ResourceForm.title"}/>,
      // placeholder: "Title",
      setInitialValue: true,
      // className: "hidden"
      trigger: 'onSubmit',
    },
    // settings: {
    //   type: "object",
    //   properties: {
    //     include_description: {
    //       type: "string",
    //       // title: "Include Description",
    //       span: 6,
    //       widget: "CheckboxWidget",
    //       valuePropName: "checked",
    //       widgetConfig: {
    //         // label: 'Include Description',
    //         children: 'Add description',
    //       },
    //     },
    //   }
    // },
    include_caption: {
      type: "string",
      // title: "Include Description",
      span: 6,
      widget: "CheckboxWidget",
      valuePropName: "checked",
      widgetConfig: {
        // label: 'Include Description',
        children: mrIntl("ResourceForm.add_description"),
      },
    },
    caption_parent: {
      type: "string",
      title: mrIntl("CommonText.description"),
      setInitialValue: true,
      dependsOn: ["resource_segment_attributes.include_caption"],
      children: DescriptionChildComponent
    },
    // resource_url: {
    //   type: "string",
    //   title: "Resource URL",
    //   setInitialValue: true,
    // },
    // resource_id: {
    //   type: "string",
    //   title: "Resource ID (video_id)",
    //   setInitialValue: true,
    // },
    resource_json_parent: {
      type: "string",
      title: mrIntl("ResourceForm.resource_json_title"),
      dependsOn: ["resource_segment_attributes.resource_type"],
      children: ResourceJSONChildComponent,
    },
  }
}
};

export default resourceForm;