import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Image, Popconfirm, Modal, Button } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { MdSmartDisplay } from "react-icons/md";
import { currentUserSelector, getEmbeddedSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { FullscreenOutlined, FullscreenExitOutlined, Html5Outlined, SoundOutlined, VideoCameraOutlined, FileWordOutlined, FilePptOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import { actions as segmentsActions } from "/src/views/Segments/redux";
import loadable from "@loadable/component";
import CustomVideoPlayer from "./CustomVideoPlayer/CustomVideoPlayer";
import { checkURLExist, checkURLSource } from "/src/lib/utils/helperMethods";
import { activeMediaIdSelector } from "/src/views/Segments/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import RenderHtml from "../RenderHtml/RenderHtml";
import { BsFillCollectionPlayFill } from "react-icons/bs";
import { FaCode, FaFileAudio, FaRegFilePdf } from "react-icons/fa";
import { capitalize, upperCase } from "lodash";
import { activeAdjustedExperienceSelector, experienceViewModeSelector, printModeSelector } from "/src/views/Experiences/selector";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import Overlay from "/src/components/UI/Overlay/Overlay";
import { checkNativeMethodSupported, getAppVersion, isNewAppVersion } from "/src/App/OfflineApp/offlineAppHelper";
const ReactPlayer = loadable(() =>
  import(/* webpackChunkName: "react-player" */ "react-player")
);

const MediaShow = (props) => {
  console.log("Media Show Props ==>", props);

  const {
    noCache,
    file = {},
    mediaShowSetting = {},
    setMediaShowSetting,
    showZoomButton = false,
  } = props;

  const supportedContentTypes = ["image", "audio", "pdf", "video", "embed", "doc", "ppt"];
  var dispatch = useDispatch()
  const mrIntl = useTranslate()
  const embedded = useSelector(getEmbeddedSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const appType = useSelector(appTypeSelector());
  const activeMediaId = useSelector(activeMediaIdSelector())
  const printMode = useSelector(printModeSelector())
  const currentUser = useSelector(currentUserSelector())
  const experienceViewMode = useSelector(experienceViewModeSelector()); 
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const experienceSettings = activeExperience.settings || {};
  const lockdownConfig = experienceSettings.lockdown_config || {};
  const currentSystemAppVersion = getAppVersion(appType)
  const isTeacherOrAdmin = currentUser && (currentUser.role === "teacher" || currentUser.role === "admin")
  
  const newJoinViewWithDynamicConfigEnabled = enabledFeatures.new_join_view_with_dynamic_config;
  const isLockdownExperienceInMacApp =
    appType === "mac" && experienceSettings.mode == "offline";

  // Allow whitelist embed url to open in new window/modal for mac app 10.2.0 onward and seb as well 
  const isOpenEmebedSegmentURLInNewTab =
    newJoinViewWithDynamicConfigEnabled &&
    lockdownConfig.generated_at && // Constructing whitelist urls on publish only
    ((isLockdownExperienceInMacApp &&
      isNewAppVersion(currentSystemAppVersion, "10.2.0")) ||
      appType === "seb");
  

  let fileType;
  supportedContentTypes.map((ct) => {
    if (file?.type?.includes(ct) || file?.content_type?.includes(ct)) {
      fileType = ct;
    }
  });

  const contentType = props.mediaType || fileType;

  console.log("appType ==>", appType);
  console.log("contentType ==>", contentType, file);
  // let url = null
  // if (contentType == 'pdf') {
  //   if (appType != 'seb') {
  //     url = `/pdfjs/web/viewer.html?file=${file.url}`
  //   } else {
  //     url = `${file.url}#toolbar=0`
  //   }
  // }

  // const fileURL = noCache && file.s3_url ? file.s3_url + `?${Date.now()}` : file.url
  const fileURL = noCache && file.s3_url ? file.s3_url : file.url;
  const showRemoveIcon = props.removeFile && !props.hideRemoveIcon;
  const iframeHeight = mediaShowSetting.iframeHeight || "500px";

  const [mediaStateSetting, setMediaStateSetting] = useState({});
  const [activeMediaPlaying, setActiveMediaPlaying] = useState(false);
  const [fullScreenModal , setFullScreenModal] = useState(false);

  const [reload, setReload] = useState(1)
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    console.log("Media Show activeMediaId ==>", activeMediaId, file.id );
    if (activeMediaId && file.id !== activeMediaId) {
      setActiveMediaPlaying(false);
    }
  }, [activeMediaId])

  const mediaPlayer = useRef(null);

  const pdfToRender = (
    <div className="pdf-show-container">
      <Button
        className= {!fullScreenModal  ? "pdf-full-screen-button" : "pdf-full-screen-exit-button" }
        onClick={() => setFullScreenModal(!fullScreenModal)}
        icon={
          fullScreenModal ? <FullscreenExitOutlined /> : <FullscreenOutlined />
        }
      ></Button>
      <iframe
        title="pdf-viewer"
        src={`/pdfjs/web/viewer.html?file=${fileURL}`}
        width="100%"
        height ={iframeHeight}
      ></iframe>
    </div>
  );

const openUrlInChildWindow = (url) => {
  let nativeMethodsToCall = [];
  //v11.0.0 we have openContentInModal where open embed url in model same as zoom call and user can minimize the model 
  if (checkNativeMethodSupported(appType, "openContentInModal")) {
    nativeMethodsToCall.push({
      key: "openContentInModal",
      value: url + "------" + "Embed Url",
    });
  } else if (checkNativeMethodSupported(appType, "openChildWindow")) {
    //v10.2.0 and onward we have openChildWindow where open embed url in child window but can't the minimize the window 
    nativeMethodsToCall.push({
      key: "openChildWindow",
      value: url + "------" + "Embed Url",
    });
  }

  dispatch(offlineAppActions.executeMethod(nativeMethodsToCall));
};

//Load or Whitelist the Embed URL in a child window on mac app due to lack of restrict to open within an iframe, APL-5062 
const loadEmbedIframe = (fileURL) => {
  const iFrameTag = <iframe src={fileURL} width="100%" height="500px"></iframe>;
  //shouldOpenEmbedUrlInNewTab handle to open new tab in mac and seb only for now , appType === "mac" to childWindow action and else for seb in _blank (seb manage new tab by _blank)
  if (isOpenEmebedSegmentURLInNewTab) {
    return (
      <Overlay
        onClick={() => {
          appType === "mac"
            ? openUrlInChildWindow(fileURL)
            : window.open(fileURL, "_blank");
        }}
      >
        {iFrameTag}
      </Overlay>
    );
  }
  return iFrameTag;
};

  const handlePlayPause = () => {
    const activeMediaId = file.id
    dispatch(segmentsActions.setActiveMediaIdSuccess({ activeMediaId }))
    console.log("handlePlayPause activeMediaPlaying ===>", activeMediaPlaying)
    setActiveMediaPlaying(!activeMediaPlaying)
  }

  //React player having  some issue to get latest value of playing so setting playing = true in onPlay
  //Reference: https://github.com/CookPete/react-player/blob/master/src/demo/App.js
  
  const handleOnPlay = () => {
    setActiveMediaPlaying(true)
  }

  console.log("mediaStateSetting before render ==>", mediaStateSetting)
  return (
    <div key={`media-show-${file.id}-${reload}`} className="media-show">
      {contentType == "image" && (
        <span>
          {showZoomButton ?
            <div className="image-container">
              <span className={showZoomButton ? "pointer-events-none" : ""}>
                <Image
                  src={fileURL}
                  className={"antd-zoom-image"}
                  placeholder={false}
                  preview={{
                    mask: false,
                    visible: visible,
                    // onVisibleChange: (visible) => { 
                    //   setVisible(visible) 
                    // },
                    getContainer: () => {
                      // We should improve this when antd resolves getContainer issue
                      const elements = document.querySelectorAll(".ant-image-preview-root, .ant-image-preview-close")
                      console.log("elements ==>", elements)
                      elements.forEach((element) => {
                        element.addEventListener("click", (e) => {
                          e.stopPropagation();
                            // When we click on the image, this closes the preview modal, so we need to stop the propagation
                          if (e.target.classList.contains("ant-image-preview-img")) {
                            // setVisible(true);
                          } else {
                            setVisible(false);
                          }
                        });
                      });
                    }
                  }}
                ></Image>
              </span>
              {(currentUser.role === "student" || experienceViewMode === "apPreviewTest") && (
                <Button
                  icon={<ArrowsAltOutlined />}
                  className="media-show-zoom-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setVisible(true);
                  }}
                />
              )}
            </div>
            : <Image
              src={fileURL}
              className={"antd-zoom-image"}
              placeholder={false}
              preview={{ mask: false, ...props.imagePreview }}
            ></Image>}
          {printMode && file.title && <h3>{file.title}</h3>}
          {printMode && file.caption && <RenderHtml text={file.caption} />}
          {/* {embedded ? (
              <img src={fileURL} />
              ) : (
              <Image
                src={fileURL}
                className={"antd-zoom-image"}
                placeholder={false}
              ></Image>
              )} */}
          {showRemoveIcon && (
            <Popconfirm
              title={mrIntl("CommonText.confirm_delete")}
              onConfirm={(e) => props.removeFile()}
              okText={mrIntl("CommonText.yes")}
              cancelText={mrIntl("CommonText.no")}
            >
              <AiOutlineClose
                // onClick={(e) => props.removeFile()}
                className="remove-image"
              />
            </Popconfirm>
          )}
        </span>
      )}
      
      {contentType == "pdf" && (
        <>
          {printMode ? (
            <div>
              <FaRegFilePdf className="print-content-icon" />
              {file.title && <h3>{file.title}</h3>}
              {file.caption && <RenderHtml text={file.caption} />}
            </div>
          ) : <span>
            <Modal
              open={fullScreenModal}
              className="pdf-full-screen-modal"
              footer={false}
              closable={false}
              centered={true}
            >
              {pdfToRender}
            </Modal>
              {pdfToRender && pdfToRender}
            {showRemoveIcon && (
              <Popconfirm
                title={mrIntl("CommonText.confirm_delete")}
                onConfirm={(e) => props.removeFile()}
                okText={mrIntl("CommonText.yes")}
                cancelText={mrIntl("CommonText.no")}
              >
                <AiOutlineClose
                  // onClick={(e) => props.removeFile()}
                  className="remove-pdf"
                />
              </Popconfirm>
            )}
          </span>}
        </>
      )}

      {contentType == "audio" && (
        <>
          {printMode ? (
            <div>
              <SoundOutlined className="print-content-icon" />
              {file.title && <h3>{file.title}</h3>}
              {file.caption && <RenderHtml text={file.caption} />}
            </div>
          ) : (
            <span>
              <ReactPlayer
                url={fileURL}
                controls={true}
                // width={'250px'}
                height={"50px"}
                className={"audio-player"}
                playing={activeMediaPlaying}
                config={{
                  file: {
                    attributes: {
                      controlsList: isTeacherOrAdmin && experienceViewMode !== "apPreviewTest" ? "" : "nodownload",
                      preload: "metadata",
                    },
                    forceAudio: true,
                  },
                }}
                onPlay={(e) => handlePlayPause(e)}
                onDuration={(duration) => {
                  console.log("Audio Player duration is ==>", duration);
                }}
                onReady={(e) => {
                  console.log(
                    "This audio tag is ready now",
                    e,
                    e.getDuration()
                  );
                }}
              />
              {showRemoveIcon && (
                <Popconfirm
                  title={mrIntl("CommonText.confirm_delete")}
                  onConfirm={(e) => props.removeFile()}
                  okText={mrIntl("CommonText.yes")}
                  cancelText={mrIntl("CommonText.no")}
                >
                  <AiOutlineClose
                    // onClick={(e) => props.removeFile()}
                    className="remove-audio"
                  />
                </Popconfirm>
              )}
            </span>
          )}
        </>
      )}

      {contentType === "video" && (
        <>
         {printMode ? (
           <div>
             <VideoCameraOutlined className="print-content-icon" />
             {file.title && <h3>{file.title}</h3>}
             {file.caption && <RenderHtml text={file.caption} />}
           </div>
         ) : (
           <span>
          <CustomVideoPlayer
            {...props}
            key={`custom-player-${file.id}-${reload}`}
            contentType={contentType}
            mediaPlayer={mediaPlayer}
            mediaStateSetting={mediaStateSetting}
            setMediaStateSetting={setMediaStateSetting}
            mediaShowSetting={mediaShowSetting}
            setMediaShowSetting={setMediaShowSetting}
            activeMediaPlaying={activeMediaPlaying}
            handlePlayPause={handlePlayPause}
            handleOnPlay={handleOnPlay}
            setActiveMediaPlaying={setActiveMediaPlaying}
            setReload={setReload}
          />
          {showRemoveIcon && (
            <Popconfirm
              title= { mrIntl("CommonText.confirm_delete")}
              onConfirm={(e) => props.removeFile()}
              okText= {mrIntl("CommonText.yes")}
              cancelText= {mrIntl("CommonText.no")}
            >
              <AiOutlineClose
                // onClick={(e) => props.removeFile()}
                className="remove-video"
              />
            </Popconfirm>
          )}
          </span>
        )} 
        </>
      )}
      {contentType == "simulation" && (
        <>
          {printMode ? (
            <div>
              <Html5Outlined className="print-content-icon" />
              {/* {title && <h3>{title}</h3>}
              {caption && <RenderHtml text={caption} />}{" "} */}
            </div>
          ) : (
            <span>
              <iframe src={fileURL} width="100%" height="500px"></iframe>
              {showRemoveIcon && (
                <Popconfirm
                  title={mrIntl("CommonText.confirm_delete")}
                  onConfirm={(e) => props.removeFile()}
                  okText={mrIntl("CommonText.yes")}
                  cancelText={mrIntl("CommonText.no")}
                >
                  <AiOutlineClose
                    // onClick={(e) => props.removeFile()}
                    className="remove-simulation"
                  />
                </Popconfirm>
              )}
            </span>
          )}
        </>
      )}
      {contentType == "embed" && (
        <>
          {printMode ? (
            <div>
              <FaCode className="print-content-icon" />
              {/* {title && <h3>{title}</h3>}
              {caption && <RenderHtml text={caption} />}{" "} */}
            </div>
          ) : <span>
              {/* <iframe src={fileURL} width="100%" height="500px"></iframe> */}
              {loadEmbedIframe(fileURL)}
          {showRemoveIcon && (
            <Popconfirm
              title={mrIntl("CommonText.confirm_delete")}
              onConfirm={(e) => props.removeFile()}
              okText={mrIntl("CommonText.yes")}
              cancelText={mrIntl("CommonText.no")}
            >
              <AiOutlineClose
                // onClick={(e) => props.removeFile()}
                className="remove-embed"
              />
            </Popconfirm>
          )}
        </span>} 
        </>
      )}
      {contentType == "doc" && (
        <>
          {printMode ? (
            <div>
              <FileWordOutlined className="print-content-icon" />
            </div>
          ) : <span>
          <iframe src={fileURL} width="100%" height="500px"></iframe>
          {showRemoveIcon && (
            <Popconfirm
              title={mrIntl("CommonText.confirm_delete")}
              onConfirm={(e) => props.removeFile()}
              okText={mrIntl("CommonText.yes")}
              cancelText={mrIntl("CommonText.no")}
            >
              <AiOutlineClose
                className="remove-embed"
              />
            </Popconfirm>
          )}
        </span>} 
        </>
      )}
      {contentType == "ppt" && (
        <>
          {printMode ? (
            <div>
              <FilePptOutlined className="print-content-icon" />
            </div>
          ) : <span>
          <iframe src={fileURL} width="100%" height="500px"></iframe>
          {showRemoveIcon && (
            <Popconfirm
              title={mrIntl("CommonText.confirm_delete")}
              onConfirm={(e) => props.removeFile()}
              okText={mrIntl("CommonText.yes")}
              cancelText={mrIntl("CommonText.no")}
            >
              <AiOutlineClose
                className="remove-embed"
              />
            </Popconfirm>
          )}
        </span>} 
        </>
      )}
      {printMode && contentType !== "image" && <h4>This item includes interactive content. Please refer to the digital version of this assessment to view this item.</h4>}
    </div>
  );
};
export default MediaShow;
