import React, { memo, useEffect, useState, useRef } from "react";
import CountDown from "react-countdown";
import { 
  convertMs, 
  getSessionId, 
  checkMob, 
  getServerTimeOffset, 
  currentTimeValidWrtServerTime 
} from "/src/lib/utils/helperMethods";
import { Tooltip, Button, Tag } from "antd";
import { PauseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "/src/components/UI/Segment/UIHelper";
import { 
  attemptedCountSelector, 
  experiencePausedAtSelector, 
  experiencePausedSelector, 
  isTimeUpSelector, 
  submitExperienceLoadingSelector 
} from "../FirestoreInteractions/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";

const CountDownTimer = memo(
  ({
    startedAt,
    duration,
    multi = false,
    extra_time = 0,
    extra_time_offset = 0,
    unpaused_offset = 0, // Its different for individual students saved in user_info
    setUserInfo = {},
    // setIsTimeUp = {},
    mobileUploadMode = false,
    experienceSettings = {},
    forMonitor = false,
    currentView,
    questionsCount,
    setSwitchFromStartToResumeView,
    setServerTimeCallback,
    countDownButtonDisable,
    countDownButtonIcon,
  }) => {
    const mrIntl = useTranslate()
    const isMobile = checkMob();
    const isSubmitExperienceLoading = useSelector(submitExperienceLoadingSelector);
    const isTimeUpFromRedux = useSelector(isTimeUpSelector);
    const experiencePaused = useSelector(experiencePausedSelector)
    const experiencePausedAt = useSelector(experiencePausedAtSelector)
    const enabledFeatures = useSelector(enabledFeaturesSelector())
    // const [exTime, setExTime] = useState(0);
    // const [exTimeOffset, setExTimeOffset] = useState(0);
    const studentTimer = useRef(null);
    const countDownRef = useRef(null);

    const currentTime = Date.now();
    const studentStartedAt = (startedAt === undefined) ? currentTime : startedAt;
    
    const calcPausedOffset = enabledFeatures.pause_unpause_test ?
      (experiencePaused && startedAt !== undefined 
        ? currentTime - experiencePausedAt 
        : 0
      ) + (unpaused_offset || 0) : 0;

    const [pausedOffset, setPausedOffset] = useState(calcPausedOffset);
    let calcTargetTime =
      studentStartedAt +
      (multi 
        ? duration 
        : convertMs(duration)
      ) +
      extra_time +
      extra_time_offset +
      pausedOffset;
    const [targetTime, setTargetTime] = useState(calcTargetTime);
    const [isTimeUp, setIsTimeUp] = useState(false)

    // console.log("CountDownTimer targetTime ==>", {
    //   calcTargetTime: new Date(calcTargetTime).toLocaleString(),
    //   targetTime: new Date(targetTime).toLocaleString(),
    //   extra_time: `${Math.floor(extra_time/1000/60)} minutes ${Math.floor((extra_time/1000)%60)} seconds`,
    //   extra_time_offset: `${Math.floor(extra_time_offset/1000/60)} minutes ${Math.floor((extra_time_offset/1000)%60)} seconds`,
    //   pausedOffset: `${Math.floor(pausedOffset/1000/60)} minutes ${Math.floor((pausedOffset/1000)%60)} seconds`,
    //   unpaused_offset: `${Math.floor(unpaused_offset/1000/60)} minutes ${Math.floor((unpaused_offset/1000)%60)} seconds`,
    //   experiencePaused,
    //   experiencePausedAt: experiencePausedAt ? new Date(experiencePausedAt).toLocaleString() : null,
    //   calcPausedOffset: `${Math.floor(calcPausedOffset/1000/60)} minutes ${Math.floor((calcPausedOffset/1000)%60)} seconds`
    // });
    // const [showCountDownTimer, setShowCountDownTimer] = useState(true)

    useEffect(() => {
      console.log("CountDownTimer extra_time==>", extra_time);
      if (typeof extra_time != "undefined" || (Number.isInteger(pausedOffset) && pausedOffset != 0)) {
        // setExTime(extra_time);
        let calcTargetTime =
          studentStartedAt +
          (multi ? duration : convertMs(duration)) +
          extra_time +
          extra_time_offset + 
          pausedOffset;
        setTargetTime(calcTargetTime);
        //TODO: We were facing an issue where the condition (calcTargetTime > Date.now()) would sometimes evaluate to true even after the time for the paper had ended because sometimes calcTargetTime is greater than Date.now(). Therefore, we have created a new condition such that when the student's paper time ends, this value will become false.
        if (parseInt((calcTargetTime - Date.now()) / 1000) > 1) {
          // TODO: set in userinfo?
          // setIsTimeUp(false);
          if(!forMonitor){
            setUserInfo({
              is_time_up: false
            });
            setIsTimeUp(false)
          }
          // if(currentView == "startTest"){
          //   setUserInfo({currentView: "endTest", is_uploading_finished: true, ended_at: Date.now()})
          // }
        }
      }
    }, [extra_time, pausedOffset]);

    useEffect(() => {
      if(enabledFeatures.pause_unpause_test) {
        if(countDownRef.current) {
          if (experiencePaused) {
            countDownRef.current.pause()
          } else {
            countDownRef.current.start()
          }
          setPausedOffset(calcPausedOffset)
        }
      }
    }, [experiencePaused])
    
    // console.log("CountDownTimer studentStartedAt", studentStartedAt, extra_time);

    // if endedAt < studentStartedAt + convertMs(duration)
    // exTimeOffset = 0
    // else
    // let exTimeOffset = Date.now()-(studentStartedAt + convertMs(duration))
    // let targetTime = studentStartedAt + (multi ? duration : convertMs(duration)) + extra_time + extra_time_offset
    const attemptedCount = useSelector(attemptedCountSelector)
    const questionsLeft = questionsCount - attemptedCount
    console.log("targetTime", attemptedCount, questionsLeft, questionsCount);
    
    // let targetTime = studentStartedAt + (multi ? duration : convertMs(duration)) + (exTime)
    useEffect(() => {
      setIsTimeUp(isTimeUpFromRedux)
    }, [isTimeUpFromRedux])
    // Todo: this extra time will work if test still in progress. if adding after ending - set studentStartedAt again and add just the extraTime - log how many times and when extra time added. after end test essentially a restart of the test
    useEffect(() => {
      console.log("Countdowntimer on mount", targetTime, extra_time, extra_time_offset, forMonitor, Date.now(), currentView);
      if (!forMonitor && targetTime < Date.now()) {
        // setIsTimeUp(true); // want this in endTest case too
        let userInfoObj = {
          is_time_up: true,
        }
        setIsTimeUp(true)
        // One case:- If we have set a time for an assessment and the student clicks on 'Request Resume' before the time runs out, and if the admin grants permission after the assessment time has ended, then if the student quits the test and re-enters the same assessment, they are directly entering the assessment, even though the assessment time has already ended in that case. So, we need to set the currentView to 'endTest' in this case.
        if (currentView === "startTest" || currentView === "resumeTest") {
          setSwitchFromStartToResumeView(false);
          userInfoObj = {
            ...userInfoObj,
            currentView: "endTest",
            is_uploading_finished: true,
            ended_at: Date.now(),
          }
          setTimeout(() => {
            setUserInfo(userInfoObj,
            {
              log: {
                // logging: experienceItemConfig.log.logging,
                logging: true,
                action: "test_ended_on_time_completed",
              },
            });
          }, 1000)
        }
        // want is_time_up to be set in endTest case too but no currentView change
        if (currentView === "endTest") {
          setTimeout(() => {
            setUserInfo(userInfoObj);
          }, 1000)
        }
      }
    }, []);
    // let isTimeUp = targetTime <= Date.now() ? true : false

    const onTick = (data) => {
      if (data.hours == 0 && data.minutes == 10 && data.seconds == 0) {
        var description;
        var message;
        if (
          experienceSettings.allow_student_upload_files &&
          !mobileUploadMode
        ) {
          message = mrIntl("CountDownTimer.last_10_minutes_left")
          description = `\n${
            isMobile
              ? mrIntl("CountDownTimer.please_make_sure_you_upload_your_handwritten_answers")
              : `${mrIntl("CountDownTimer.click_on_upload_btn")} \n${
                  questionsLeft !== 0 &&
                  `${mrIntl("CountDownTimer.unanswered_questions" , {questionsLeft: questionsLeft })}`
                }`
          }`;
        } else if (questionsLeft !== 0) {
          message = mrIntl("CountDownTimer.last_10_minutes_left")
          // description = `You have ${questionsLeft} unanswered questions.`;
          description = mrIntl("CountDownTimer.unanswered_questions" , {questionsLeft: questionsLeft });

        }
        showNotification("warning", {
          message: message,
          description: description,
          duration: 10,
          top: 65,
        });
      } else if (data.hours == 0 && data.minutes == 2 && data.seconds == 0) {
        showNotification("warning", {
          message: mrIntl("CountDownTimer.only_2_minutes_left"),
          description:
            mrIntl("CountDownTimer.this_test_will_end_in_minutes_please_make_sure_to_review_your_answers"),
          duration: 10,
          top: 65,
        });
      }
    };

    let finalRender = forMonitor ? (
      <Tag color={experiencePaused ? "error" : "processing"}>
        {experiencePaused ?  <PauseOutlined />  : ''}
        <CountDown
          // Adding targetTime to key to re-render when targetTime changes this is because this component is not a controlled component so we have to re-render it when targetTime changes
          key={`experience-timer-for-monitor-${targetTime}`}
          daysInHours={true}
          ref={countDownRef}
          // we need true for unpaused cases if paused, then it will be false.
          autoStart={!experiencePaused}
          // date={Date.now() + 10000 + (exTime !== 0 && exTime)}
          date={targetTime}
          controlled={false}
          // Todo - do this on teacher side also - end test?
          onComplete={() => {
            // Want to avoid calling updateRecord / updating user_info from teacher side - in case any issues, will end test/is_time_up = true for student - instead doing an extra 
            // TODO: set in userinfo?
            // setIsTimeUp(true);
            // TODO: set in userinfo from here also - only case where there will be an issue is when time ends but student offline - is_time_up will not be set to true and adding time may be inaccurate
          }}
        />
      </Tag>
    ) : (
      <Tooltip title={countDownButtonDisable ? "" : mrIntl("CountDownTimer.time_left")} placement="bottom" getPopupContainer={triggerNode => triggerNode.parentNode}>
        <Button disabled={countDownButtonDisable} ref={studentTimer} size={isMobile && "small"}>
        {/* {countDownButtonIcon ? countDownButtonIcon : null} */}
          <CountDown
            // Adding targetTime to key to re-render when targetTime changes this is because this component is not a controlled component so we have to re-render it when targetTime changes
            key={`experience-timer-for-monitor-${targetTime}`}
            daysInHours={true}
            ref={countDownRef}
            // we need true for unpaused cases if paused, then it will be false.
            autoStart={!experiencePaused}
            // date={Date.now() + 10000 + (exTime !== 0 && exTime)}
            date={targetTime}
            controlled={false}
            onMount={() => getServerTimeOffset(setServerTimeCallback)}
            onTick={(data) => onTick(data)}
            onComplete={() => {
              console.log("timer complete")
              if (studentTimer.current) {
                studentTimer.current.focus(); // Focusing to trigger blur on text editors to auto save answers
              }
              setSwitchFromStartToResumeView(false);
              // setIsTimeUp(true);
              // if (currentView === "startTest") {
                // ISsue here - ended_at not being updated when sitting on endTest and test ends = removing startTest condition -calling this anytime onComplete triggers - resumeTest, startTest, endTest. It doesn't trigger if time over already and page refreshed.
                console.log("isSubmitExperienceLoading ====>", isSubmitExperienceLoading, isTimeUp);
                if(!isSubmitExperienceLoading && !isTimeUp) {
                  let userInfoObj = {
                    is_time_up: true
                  }
                  setIsTimeUp(true)
                  let logObj = {}
                  if (currentView === "endTest") {
                    logObj = {
                      log: {
                        // logging: experienceItemConfig.log.logging,
                        logging: true,
                        action: "time_completed_on_end_view",
                      },
                    }
                  } else {
                    // Set is_uploading_finished to true to close the upload status modal in the end view.
                    userInfoObj = {...userInfoObj, currentView: "endTest", ended_at: Date.now(), is_uploading_finished: true }
                    logObj = {
                      log: {
                        // logging: experienceItemConfig.log.logging,
                        logging: true,
                        action: "test_ended_on_time_completed",
                      },
                    }
                  }
                  setUserInfo(
                    userInfoObj,
                    logObj
                  );
                }
            }}
          />
        </Button>
      </Tooltip>
    );

    return <>{finalRender}</>;
  }
);
export default CountDownTimer;
