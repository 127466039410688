import React from "react";
import { List, Avatar, Space, Tag, Tooltip, Button } from "antd";
import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import { Link, useRouteMatch } from "react-router-dom";
import dayjs from "dayjs";
import { actions as integrationActions } from "/src/views/Integrations/redux"
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi"
import { useDispatch } from "react-redux";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import "./IntegrationList.scss"

const IntegrationList = (props) => {
  console.log("Integration List props===>", props);
  const { dataSource, permission, currentUser, deleted, history} = props;
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const tableDataSource = [];
  const updatePermission = permission?.integration?.update;
  for (let index = 0; index < dataSource.length; index++) {
    let rowData = {};
    rowData.id = dataSource[index].id;
    // rowData.name = dataSource[index].name
    rowData.name = (
      <Link to={`${url}/${dataSource[index].id}/members`}>
        {dataSource[index].id} - {dataSource[index].name}
      </Link>
    );

    rowData.timestamps = []
    rowData.timestamps.push(<Tag>{dayjs(dataSource[index].updated_at).format("MMM DD, YYYY")}</Tag>)
    
    if (updatePermission) {
      rowData.actions = (
        <ActionBtns
          edit={true}
          delete={true}
          deleted={props.deleted}
          item={dataSource[index]}
          id={dataSource[index].id}
        />
      );
    }
    tableDataSource.push(rowData);
  }

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "settings",
      dataIndex: "settings",
      key: "settings",
    }
  ];

  if (updatePermission) {
    columns.push({
      title: mrIntl("CommonText.actions"),
      dataIndex: "actions",
      key: "actions",
    });
  }

  const Title = ({item}) => (
    <Space className="title">
      {item.archived && <Tooltip title={mrIntl("IntegrationList.this_integration_is_archived")}><span className="archive-icon"><BiArchiveIn /></span></Tooltip>}
      <span>{item.name}</span>
    </Space>
  )

  const Description = ({item}) => {
    console.log("item in descriptions ==>", item)
    return (
      <Space className="description">
        {<span>{dayjs(item.updated_at).format("MMM DD, YYYY")}</span>}
      </Space>
    )
  }

  const ProfileIcon = ({item}) => (
    <Tooltip title={item.org_programme_is_mb ? `MB(${item.source_id})`:  ""}><Avatar className="profile-icon">{item.name.substr(0,1).toUpperCase()}</Avatar></Tooltip>

  )

  const ExtraInfo = ({item}) => {
    const extra = [];
    item.org_programme_abbr && extra.push(<Tag>{item.org_programme_abbr}</Tag>)
    item.subject_label && extra.push(<Tooltip title={mrIntl("CommonText.subject")}><Tag>{item.subject_label}</Tag></Tooltip>)
    item.subject_group_label && extra.push(<Tooltip title={mrIntl("CommonText.subject_group")}><Tag>{item.subject_group_label}</Tag></Tooltip>)
    item.grade_label && extra.push(<Tag>{item.grade_label}</Tag>)
    item.group_students && extra.push(<Tag>{item.group_students.length} {item.group_students.length !== 1 ? <span>{mrIntl("CommonText.students")}</span> : mrIntl("CommonText.student")}</Tag>)
    return extra;
  }

  const extraActions = (item, permission, currentUser) => {
    const actions = [];
    permission.integration.delete && !currentUser.org.is_mb && item.archived && actions.push(<Tooltip title={mrIntl("GroupsList.unarchive")} placement="bottom">
      <Button type="text" shape="circle" icon={<BiArchiveOut />} onClick={() => {
        dispatch(integrationActions.update({id: item.id, archived: false}, {success: {showMessage: true, message: mrIntl("IntegrationList.integration_archived")}, }));
      }}></Button>
    </Tooltip>)
    return actions;
  }

  let finalRender = null;

  finalRender = (
    <React.Fragment>
      <List 
        className="integration-list-wrapper"
        // loading={initLoading}
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(item, i) => (
          <CustomListItem item={item} type={"integration_list"} linkToGo={null} title={<Title item={item} />} description={<Description item={item} />} avatar={<ProfileIcon item={item} />} extraInfo={<ExtraInfo item={item} />} extraActions={extraActions(item, permission, currentUser)} isListActionsMenu={true} currentUser={currentUser} edit={updatePermission} deleted={deleted} deletedAllow={!item.archived && permission.integration.delete} permission={permission} history={history}/>
        )}
      />
    </React.Fragment>
  );

  return finalRender;
};
IntegrationList.defaultProps = {};

IntegrationList.propTypes = {};

export default IntegrationList;
