import { useSelector } from "react-redux";
import { canvasLoadingSelector } from "/src/views/CanvasAnnotations/selector";
import { loadingSelector } from "/src/views/UserResponses/selector";
import { experienceUsersLoadingSelector } from "./selector";
import { userResponseConfigSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { useEffect } from "react";

export const useLoadingCheck = () => {
  const loadingUserResponse = useSelector(loadingSelector());
  const loadingCanvas = useSelector(canvasLoadingSelector());
  const loadingExperienceUsers = useSelector(experienceUsersLoadingSelector())
  const loadingCheckRef = useSelector(userResponseConfigSelector);
  useEffect(() => {
    loadingCheckRef.current = loadingUserResponse || loadingCanvas || loadingExperienceUsers;
  }, [loadingUserResponse, loadingCanvas, loadingExperienceUsers]);
  const checkIfLoadingCompleted = (callback) => {
    console.log("checkIfLoadingCompleted =======>", loadingCheckRef.current);
    if (loadingCheckRef.current) {
      setTimeout(() => {
        checkIfLoadingCompleted(callback);
      }, 200);
    } else if (callback) {
      callback();
    }
  };
  
  return checkIfLoadingCompleted;
};