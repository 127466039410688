
      if (import.meta.env.VITE_REGION === 'china') {
        
        //  removed crossorigin and referrer to get rid of cors error
        //  !IMP: Update supabase monthly to latest version in china
        var script=document.createElement("script");
        // script.async=true;
        script.src="https://app.assessprep.cn/cdn/supabase-js@2.45.1/dist/umd/supabase.js";
        script.onload=() => window.supabase = supabase;
        document.getElementsByTagName("head")[0].appendChild(script);

        var script = document.createElement('script');
        
        // console.log("mathjax script window.location", window.location);
        if (window.location && window.location.pathname && window.location.pathname.indexOf("embed") > -1 && window.location.search.length == 0){
          // Pamoja/MB - we want mathjax to render latex and mathml for font parity - only in embed
          // script.src = 'https://unpkg.com/mathjax@3.1.2/es5/tex-mml-svg.js';
          script.src = 'https://app.assessprep.cn/cdn/3.1.2/tex-mml-svg.js';
        } else {
          // AP and OSC - we want mathjax to render latex and wiris to render mathml. Also is used for QB author even if pamoja so authors might think different font
          // script.src = 'https://unpkg.com/mathjax@3.1.2/es5/tex-svg-full.js';
          script.src = 'https://app.assessprep.cn/cdn/3.1.2/tex-svg-full.js';
        }
        script.async = true;
        
        // console.log("mathjax script", script);
        document.body.appendChild(script);

      } else {
        // !IMP: Loading supabase on global as well since pamoja poc case where students from china access global managebac and hence global assessprep - we set region based on timezone in that case
        var script=document.createElement("script");
        // script.async=true;
        script.src="https://unpkg.com/@supabase/supabase-js@2";
        script.onload=() => window.supabase = supabase;
        document.getElementsByTagName("head")[0].appendChild(script);

        var script = document.createElement('script');
        
        // console.log("mathjax script window.location", window.location);
        if (window.location && window.location.pathname && window.location.pathname.indexOf("embed") > -1 && window.location.search.length == 0){
          // Pamoja/MB - we want mathjax to render latex and mathml for font parity - only in embed
          script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.1.2/es5/tex-mml-svg.min.js';
          script.integrity = "sha512-cvY8FLZ100SA0lUHyX38BDEr1rz0AKP6Iz+t5yfOPOyuKX72RNHIuPGH/+ScEsbKBhngYhKJSaFbtKet0te1Sw==";
        } else {
          // AP and OSC - we want mathjax to render latex and wiris to render mathml. Also is used for QB author even if pamoja so authors might think different font
          script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.1.2/es5/tex-svg-full.min.js';
          script.integrity = "sha512-QrJlCHKrgI59L3L/iOlwyEfg4uY7KdiIdZEXaHX9BIgkSsP+9wmPN+rM4/yJbdS/KHB7EFFvhV75TYAJgaPqUw==";
        }
        script.crossOrigin = "anonymous";
        script.referrerPolicy = "no-referrer";
        script.async = true;
        
        // console.log("mathjax script", script);
        document.body.appendChild(script);
        
      }
    