import React, { useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import UploadList from 'antd/es/upload/UploadList';
import MediaShow from "../Media/MediaShow";
import { capitalize } from "lodash";
import { Space } from "antd";
import RenderHtml from "../RenderHtml/RenderHtml";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useSelector } from "react-redux";
import { printModeSelector } from "/src/views/Experiences/selector";

const ListAttachments = (props) => {
  const { attachments = [], listProps = {}, droppableProps = {}, draggableProps = {}, onDragEnd, componentAfterThumbnails,removeFile, caption, title, attachmentType, titlePrefix, hideTitle } = props;

  const mrIntl = useTranslate();
  const printMode = useSelector(printModeSelector());
  console.log("List attachments props ==>", printMode)

  const hasBorderClass = printMode ? "bordered-content" : ""

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableProps.droppableId || 'droppable'} {...droppableProps}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            // style={snapshot.isDraggingOver ? {
            //   display: 'flex',
            //   overflow: 'auto',
            // } : {
            //     display: 'flex',
            //     flexWrap: 'wrap',
            //   }}
            // style={{
            //   display: 'flex',
            //   overflow: 'auto',
            // }}
          >
            <div className="print-content-type" >
              {printMode && <h3>{mrIntl("ListAttachments.content_type")}{(titlePrefix)}</h3>}
              <p>{title}</p>
              <RenderHtml text={caption} />
            </div>
            {attachments.map((atc, index) => (
              <Draggable className={hasBorderClass} key={atc.uid} draggableId={atc.uid} index={index} {...draggableProps}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >

                    { printMode || hideTitle ? "": `${props.titlePrefix || 'File'} ${index+1}`}
                    <MediaShow
                      // title={title}
                      // caption={caption}
                      printMode={printMode}
                      removeFile={removeFile}
                      file={atc}
                      mediaType={attachmentType}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {componentAfterThumbnails}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default ListAttachments
