import React from "react";
import { Empty } from "antd";

const NoData = ({ description }) => {
  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description} />
  );
};

export default NoData;
