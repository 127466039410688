import React from "react";
import PropTypes from "prop-types";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const BoardFilter = (props) => {
  return <span><MrTranslate id={"BoardsFilter.board_filter"} /></span>;
};

BoardFilter.defaultProps = {};

BoardFilter.propTypes = {};

export default BoardFilter;
