import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "./redux";
import { Modal, Button, Alert, Tooltip } from "antd";
import { MrFormComponent } from "mr_react_framework";
import "./FeedbackModal.scss";
import FeedbackFileUpload from "./FeedbackFileUpload";
import CKETextFeedback from "./CKETextFeedback";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { activeAdjustedExperienceSelector } from "../Experiences/selector";
import { isEmpty } from "lodash";
import { getDeviceInfo } from "/src/App/OfflineApp/offlineAppHelper";
// import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";

const FeedbackModal = (props) => {
  console.log("line number 137", props);
  const dispatch = useDispatch();
  const appType = useSelector(appTypeSelector())
  const [modalStatus, setModalStatus] = useState(false);
  const mrIntl = useTranslate();
  const experience = useSelector(activeAdjustedExperienceSelector());


  const showModal = () => {
    setModalStatus(true);
  };

  const handleOk = () => {
    setModalStatus(false);
  };

  const handleCancel = () => {
    setModalStatus(false);
  };

  const handleSubmit = (data) => {
    dispatch(
      actions.create(
        {
          ...data,
        },
        {
          success: {
            showMessage: true,
            // message: "Thank you for your feedback!",
            message: mrIntl("FeedbackModal.thank_you_for_your_feedback"),
          },
        }
      )
    );
    setModalStatus(false);
  };

  const getFeedbackForm = (props, appType, experience) => {
    console.log('props in feedback modal==>', props, experience);
    const device_info = getDeviceInfo(appType);
    let pageUrl = window.location.href;
  
    let options;
    let label = "";
    // let appVersion = "";
    // if (
    //   props.item &&
    //   props.item.app_type !== "web"
    // ) {
    //   appVersion = getAppVersion(props.item.app_type);
    // }
    let studentInfo = {};
    let experienceInfo = {};
  
    if (props.default === "reported_issue") {
      label = mrIntl("FeedbackModal.report_issue");
    }
    if (experience && !isEmpty(experience)) {
      experienceInfo = {
        experienceId: experience.id,
        experienceName: experience.name,
        experienceMode: experience.settings.mode,
        experienceAccessCode: experience.access_code,
      };
    }
    if (props.default) {
      options = []; // Here default is treated as only one option to show - so hiding option too but we should rename default from props for better clarity
    } else {
      options = [
        { label: mrIntl("CommonText.feature_request"), value: "feature_request" },
        { label: mrIntl("CommonText.report_an_issue"), value: "reported_issue" },
        { label: mrIntl("CommonText.other_feedback"), value: "other_feedback" },
      ];
    }
  
    if (props.item) {
      studentInfo = {
        ...studentInfo,
        studentName: props.item.name,
        sCode: props.item.s_code,
        appType: props.item.app_type,
        currentView: props.item.currentView,
        studentDeviceinfo: props.item.deviceInfo,
        appVersion:
          props.item.app_native_vars && props.item.app_native_vars.appVersion,
        appNativeVarsInfo: props.item.app_native_vars,
      };
    }
  
    return {
      processFormData: (dataProps, opts) => {
        console.log('opts in feedback==>', opts);
        const customFields = {
          device_info,
          studentInfo: studentInfo,
          experienceInfo: experienceInfo,
        };
        opts.values.from_page = pageUrl;
        opts.values.custom_fields = customFields;
        return opts.values;
      },
      schema: {
        type: "object",
        properties: {
          feedback_type: {
            type: "string",
            widget: "RadioGroupWidget",
            className: props.default ? "hidden" : "",
            placeholder: mrIntl("CommonText.feedback_type"),
            default: props.default ? props.default : "feature_request",
            widgetConfig: {
              options: options,
            },
            rules: [
              {
                required: true,
                message: mrIntl("CommonText.required_msg"),
              },
            ],
          },
          message: {
            type: "string",
            title: mrIntl("CommonText.message"),
            placeholder: `${mrIntl("FeedbackModal.write_here")}...`,
            className: "feedback",
            widget: CKETextFeedback,
            rules: [
              {
                required: true,
                message: mrIntl("CommonText.required_msg"),
              },
            ],
          },
  
          attachments_json: {
            type: "object",
            properties: {
              images: {
                type: "string",
                // title: "Upload file",
                title: mrIntl("FeedbackModal.upload_file"),
                widget: FeedbackFileUpload,
                widgetConfig: {},
                //   rules: [
                //     {
                //       required: true,
                //       message: "Please write feedback.",
                //     },
                //   ],
              },
            },
          },
        },
      },
    };
  };

  const jsonForm = getFeedbackForm(props, appType, experience);

  const mainForm = (
    <MrFormComponent
      key={"feedback-form"}
      {...props}
      open={true}
      resourceForm={{
        form: { ...jsonForm },
        config: { isModal: false, visible: true },
      }}
      item={{}}
      formConfig={{ actions: { submitText: mrIntl("CommonText.submit"), showCancelBtn: false } }}
      formProps={{}}
      handleOk={handleSubmit}
      className="modalform"
      handleSubmit={handleSubmit}
    />
  );

  return (
    <>
      <Tooltip
        title={props.tooltipTitle ? props.tooltipTitle : ""}
        placement={props.placement ? props.placement : "left"}
      >
        <Button
          onClick={() => showModal()}
          type={props.type ? props.type : "link"}
          shape={props.shape ? props.shape : ""}
          ghost={props.ghost ? props.ghost : false}
          icon={props.icon ? props.icon : ""}
          style={props.buttonStyle}
        >
          {props.title ? props.title : ""}
        </Button>
      </Tooltip>
      {modalStatus && (
        <Modal
          title={
            props.modalTitle
              ? props.modalTitle
              : mrIntl("FeedbackModal.share_your_feedback_with_assessprep")
          }
          open={modalStatus}
          onCancel={() => handleCancel()}
          onOk={() => handleOk()}
          wrapClassName={"height-centered-modal feedback-modal"}
          closable={true}
          footer={null}
        >
          {mainForm}
          {(props.default === "reported_issue") && (
            <>
              <Alert
                type="info"
                message= {mrIntl("FeedbackModal.additional_details_will_be_automatically_sent_to_help_diagnose_msg")}
              ></Alert>
              {/* <p>The follwing details are to be sent :- </p>
              <p>Student Code - {props.item.s_code}</p>
              <p>Current View - {props.item.currentView}</p>
              <p>OS - {props.item.deviceInfo.os}</p>
              <p>Browser - {props.item.deviceInfo.browser}</p>
              <br /> */}
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default FeedbackModal;
