import { useDispatch, useSelector } from "react-redux";
import { textHighlightsSelector } from "../Experiences/ExperienceShow/FirestoreInteractions/selector";
import { activeAdjustedExperienceSelector, experienceViewModeSelector, highlightTextEnabledSelector } from "../Experiences/selector";
import "./highlightsMethods.scss";
import { enabledFeaturesSelector } from "../Auth/Login/selector";
import { useCallback, useEffect, useRef } from "react";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { getUniqueId } from "/src/lib/utils/helperMethods";

export const useTextHighlighter = (htmlRef, uniqueTextIdentifier) => {
  const dispatch = useDispatch();

  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const experienceViewModeFromRedux = useSelector(experienceViewModeSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const highlightTextEnabled = useSelector(highlightTextEnabledSelector());
  const highlights = useSelector(textHighlightsSelector);
  const textHighlighterFeatureEnabled = enabledFeatures.text_highlight

  const highlightsRef = useRef([]);
  // We use highlightsRef instead of directly using highlights because highlightsRef is a mutable reference that can be accessed in events with there latest values
  useEffect(() => {
    highlightsRef.current = highlights;
  }, [highlights]);

  const handleHighlightUpdate = (currentHighlight, action = "add") => {
    const updatedHighlights = [...highlightsRef.current];
    console.log("updatedHighlights", updatedHighlights);
    if (action === "remove") {
      const indexToRemove = updatedHighlights.findIndex((highlight) => highlight.id === currentHighlight.id
      );
      if (indexToRemove > -1) {
        updatedHighlights.splice(indexToRemove, 1);
      }
    } else {
      const currentHighlightIndex = updatedHighlights.findIndex((highlight) => highlight.id === currentHighlight.id);
      if (currentHighlightIndex > -1) {
        updatedHighlights[currentHighlightIndex] = currentHighlight;
      } else {
        updatedHighlights.push({
          ...currentHighlight,
        });
      }
    }

    const userInfo = {
      text_highlights: updatedHighlights,
    };
    dispatch(
      firestoreInteractionActions.setUserInfo({
        userInfo,
      })
    );
  };

  const findTextNode = (node, offset) => {
    let currentNode = node;
    let currentOffset = offset;
    const stack = [currentNode];
    
    while (stack.length > 0) {
      currentNode = stack.pop();

      if (currentNode.nodeType === Node.TEXT_NODE) {
        if (currentOffset <= currentNode.length) {
          return { node: currentNode, offset: currentOffset };
        }
        currentOffset -= currentNode.length;
      } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
        if (currentNode.classList.contains('ant-select-selection-item')) {
          continue;
        }
        if (currentNode.nodeName === 'IMG' && currentNode.nodeName !== 'MATH') {
          currentOffset -= 1;
        }
        if (currentNode.nodeName === 'DIV' && (currentNode.classList.contains('inline-dropdown-input') || currentNode.classList.contains('inline-text-input'))) {
          currentOffset -= 1;
        }
        for (let i = currentNode.childNodes.length - 1; i >= 0; i--) {
          stack.push(currentNode.childNodes[i]);
        }
      }
    }
    return null;
  };

  const applyHighlights = (uniqueTextIdentifier, highlights, htmlRef) => {
    const selection = window.getSelection();
    selection.removeAllRanges();

    highlights.forEach((highlight) => {
      if (uniqueTextIdentifier === highlight.unique_text_identifier) {
        const { startOffset, endOffset } = highlight;
        const start = findTextNode(htmlRef.current, startOffset);
        const end = findTextNode(htmlRef.current, endOffset);
        const applyHighlight = true;
        if (start && end) {
          try {
            const range = document.createRange();
            range.setStart(start.node, start.offset);
            range.setEnd(end.node, end.offset);
            selection.addRange(range);
            handleTextHighlighting(uniqueTextIdentifier, highlight, applyHighlight);
          } catch (e) {
            console.error("Error applying highlight:", e);
          }
        } else {
          console.error("Could not find text nodes for highlighting");
        }
      }
    });
  };

  const handleQuestionHighlight = (highlightElements, selection, uniqueTextIdentifier, highlight, applyHighlight) => {
    highlightElements.forEach(element => {
      if (element.contains(selection.anchorNode) && element.contains(selection.focusNode)) {
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
  
          if (isWithinHighlightedSpan(range)) {
            return;
          }
  
          if (!applyHighlight && range.cloneContents().querySelectorAll('img, div').length > 0) {
            return;
          }
  
          const absoluteStartOffset = getTextOffset(range.startContainer, range.startOffset);
          const absoluteEndOffset = getTextOffset(range.endContainer, range.endOffset);
          const selectedContent = range.cloneContents();
          let currentHighlightId = getUniqueId();
  
          if (range.toString().length < 2 || (absoluteEndOffset - absoluteStartOffset <= 2) || isOverlappingWithExistingHighlight(absoluteStartOffset, absoluteEndOffset, element)) {
            return;
          }
  
          let updatedHighlight = {
            ...highlight,
            startOffset: absoluteStartOffset,
            endOffset: absoluteEndOffset,
            unique_text_identifier: uniqueTextIdentifier,
            color: highlight.color || "yellow",
          };
          
          if (!updatedHighlight.id) {
            updatedHighlight = {
              ...updatedHighlight,
              id: currentHighlightId,
            };
          }
  
          console.log("updatedHighlight==>", updatedHighlight);
          let span = setSelection(selectedContent, updatedHighlight, applyHighlight);
          range.deleteContents();
          range.insertNode(span);
  
          if (applyHighlight) {
            selection.removeAllRanges();
          }
        }
      }
    });
  };
  
  const handleTextHighlighting = (uniqueTextIdentifier, highlight = {}, applyHighlight) => {
    const questionTexts = document.querySelectorAll('.question-text');
    const showFillAnswers = document.querySelectorAll('.show-fill-answer');
    const selection = window.getSelection();

    //  we are only handling the questions that have the class show-html and question-text.
    questionTexts.forEach(questionText => {
      const highlightElements = questionText.querySelectorAll('.show-html');
      handleQuestionHighlight(highlightElements, selection, uniqueTextIdentifier, highlight, applyHighlight);
    });
  
    handleQuestionHighlight(showFillAnswers, selection, uniqueTextIdentifier, highlight, applyHighlight);
  };
  
  const setSelection = (selectedContent, highlight, applyHighlight) => {
    const { color } = highlight;
    const span = document.createElement('span');
    span.style.backgroundColor = color;
    span.style.position = "relative";
    span.classList.add('highlighted-text');
    if (highlight.id && !applyHighlight) {
      handleHighlightUpdate(highlight);
    }
    addColorPalette(span, highlight);

    selectedContent.childNodes.forEach(node => highlightNodes(node, span, highlight));
    return span;
  };

  const highlightNodes = (node, span, highlight) => {
    // we are highlighting a specific tag so that it doesn't go beyond its parent tag.
    const allowedTags = ['U', 'STRONG', 'I', 'BR', 'A', 'SUB', 'SUP', 'SPAN'];
    const excludeBackgroundColor = 'rgb(255, 255, 255)';
    if (node.nodeType === Node.TEXT_NODE) {
      span.appendChild(document.createTextNode(node.textContent));
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      if (node.tagName !== 'IMG' && node.tagName !== 'DIV') {
        if (allowedTags.includes(node.tagName)) {
          node.dataset.originalBackgroundColor = node.style.backgroundColor;
          node.dataset.highlightId = highlight.id;
          node.style.backgroundColor = "";
          span.appendChild(node.cloneNode(true));
        } else {
          node.childNodes.forEach(child => highlightNodes(child, span));
        }
      } else {
        span.appendChild(node.cloneNode(true));
      }
    }
  };

  const addColorPalette = (span, highlight) => {
    const colors = ['yellow', 'pink', 'lightblue'];
    const paletteContainer = createPaletteContainer();

    colors.forEach(color => {
      const colorButton = createColorButton(color, () => {
        span.style.backgroundColor = color;
        handleHighlightUpdate( {...highlight, color});
      });
      paletteContainer.appendChild(colorButton);
    });

    const removeButton = createRemoveButton(() => {
      removeHighlight(span, paletteContainer, highlight);
    });

    paletteContainer.appendChild(removeButton);
    document.body.appendChild(paletteContainer);

    const updatePalettePosition = () => {
      const rect = span.getBoundingClientRect();
      paletteContainer.style.top = `${rect.top + window.scrollY - 30}px`;
      paletteContainer.style.left = `${rect.right + window.scrollX - 100}px`;
    };

    span.addEventListener('mouseover', () => handleSpanMouseOver(span, paletteContainer, updatePalettePosition));
    span.addEventListener('mouseout', (event) => handleSpanMouseOut(span, paletteContainer, event));
    paletteContainer.addEventListener('mouseout', (event) => handlePaletteMouseOut(span, paletteContainer, event));

    window.addEventListener('scroll', updatePalettePosition);
    span.addEventListener('remove', () => {
      window.removeEventListener('scroll', updatePalettePosition);
    });
  };

  const createPaletteContainer = () => {
    const paletteContainer = document.createElement('div');
    paletteContainer.classList.add('highlight-palette-container');
    return paletteContainer;
  };

  const createColorButton = (color, onClick) => {
    const colorButton = document.createElement('button');
    colorButton.className = 'color-button';
    colorButton.style.backgroundColor = color;
    colorButton.onclick = onClick;
    return colorButton;
  };

  const createRemoveButton = (onClick) => {
    const removeButton = document.createElement('button');
    removeButton.className = 'remove-button';
    removeButton.innerText = 'X';
    removeButton.onclick = onClick;
    return removeButton;
  };

  const removeHighlight = (span, paletteContainer, highlight) => {
    const parent = span.parentNode;
    while (span.firstChild) {
      parent.insertBefore(span.firstChild, span);
    }
    parent.removeChild(span);
    // Restore original colors if available
    parent.querySelectorAll(`[data-highlight-id="${highlight.id}"]`).forEach(el => {
      el.style.backgroundColor = el.dataset.originalBackgroundColor || "";
      delete el.dataset.originalBackgroundColor;
      delete el.dataset.highlightId;
    });
    paletteContainer.remove();
    handleHighlightUpdate(highlight, "remove");
  };

  const handleSpanMouseOver = (span, paletteContainer, updatePalettePosition) => {
    updatePalettePosition();
    paletteContainer.style.display = 'flex';
  };

  const handleSpanMouseOut = (span, paletteContainer, event) => {
    if (!span.contains(event.relatedTarget) && event.relatedTarget !== paletteContainer) {
      paletteContainer.style.display = 'none';
    }
  };

  const handlePaletteMouseOut = (span, paletteContainer, event) => {
    if (!paletteContainer.contains(event.relatedTarget) && event.relatedTarget !== span) {
      paletteContainer.style.display = 'none';
    }
  };

  const getTextOffset = (container, offset) => {
    let textOffset = 0;
    const traverseNodes = (node) => {
      if (node === container) {
        textOffset += offset;
        return true;
      }

      if (node.nodeType === Node.TEXT_NODE) {
        textOffset += node.textContent.length;
      } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'DIV' && (node.classList.contains('inline-dropdown-input') || node.classList.contains('inline-text-input')) ) {
        textOffset += 1;
      }
      else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== 'IMG' && node.nodeName !== 'MATH') {
        for (let child of node.childNodes) {
          if (traverseNodes(child)) return true;
        }
      } else if (['IMG', 'MATH'].includes(node.nodeName)) {
        const mathContent = node.getAttribute('data-mathml');
        if (mathContent) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(mathContent, 'text/xml');
          const mnTags = Array.from(doc.querySelectorAll('mn, mo'));
          mnTags.forEach(tag => {
            textOffset += tag.textContent.length;
          });
        } else {
          textOffset += 1;
        }
      }
      return false;
    };

    let parentNode = container.parentNode;

    while (parentNode && parentNode.nodeType === Node.ELEMENT_NODE && parentNode.classList && !(parentNode.classList.contains('show-html') || parentNode.classList.contains('show-fill-answer'))) {
      parentNode = parentNode.parentNode;
    }

    if (parentNode) {
      traverseNodes(parentNode);
    } else {
      traverseNodes(container.parentNode);
    }

    return textOffset;
  };

  const isWithinHighlightedSpan = (range) => {
    for (let node = range.commonAncestorContainer; node; node = node.parentNode) {
      if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('highlighted-text')) {
        return true;
      }
    }
  };

  const isOverlappingWithExistingHighlight = (startOffset, endOffset, parentElement) => {
    const highlightSpans = parentElement.querySelectorAll('.highlighted-text');
    return Array.from(highlightSpans).some((span) => {
      const spanStart = getTextOffset(span.firstChild, 0);
      const spanEnd = spanStart + span.textContent.length;
      return (
        (startOffset >= spanStart && startOffset < spanEnd) ||
        (endOffset > spanStart && endOffset <= spanEnd) ||
        (startOffset <= spanStart && endOffset >= spanEnd)
      );
    });
  };

  useEffect(() => {
    if ((experienceViewModeFromRedux === "apPreviewTest" || experienceViewModeFromRedux === "apTakeTest") && htmlRef.current) {
      applyHighlights(uniqueTextIdentifier, highlights, htmlRef);
      
      const mouseUpHandler = () => handleTextHighlighting(uniqueTextIdentifier);
      if(highlightTextEnabled){
        htmlRef.current.addEventListener("mouseup", mouseUpHandler);
      }

      return () => {
        if (htmlRef.current) {
          htmlRef.current.removeEventListener("mouseup", mouseUpHandler);
        }
      };
    }
  }, [highlightTextEnabled, experienceViewModeFromRedux]);

  return {}
}
