import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { Segment, SegmentData } from "/src/components/UI/Segment/UIHelper";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import { cloneDeep, range } from "lodash";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { Alert, Badge, Button, Col, Row, Select, Switch, Tooltip } from "antd";
import { HiOutlineMicrophone } from "react-icons/hi";
import { QuestionCircleTwoTone, VideoCameraAddOutlined } from "@ant-design/icons";
import MediaShow from "/src/components/UI/Media/MediaShow";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import loadable from "@loadable/component";
import MediaRecorder from "/src/components/UI/Media/MediaRecorder";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { getAppVersion, getDeviceInfo, isNewAppVersion } from "/src/App/OfflineApp/offlineAppHelper";

const VideoInput = (props) => {
  const { Option } = Select;
  const { segmentSettings, setRenderMath, segmentFooterAttributes } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);
  const RecordRTCLib = loadable(() =>
  import(/* webpackChunkName: "recordrtc" */ "recordrtc")
);
  const appType = useSelector(appTypeSelector());
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };
  if (createMode) {
    parsedJSONObject = {
      segment_data: {
        max_recording_duration: 300,
      },
      segment_version: "1.0.0",
    };
    if (props.question_type == "video_input") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }
  const deviceInfo = getDeviceInfo(appType);
  const deviceOsVersion = deviceInfo.osVersion;
  const currentAppVersion = getAppVersion(appType)
  const isiOSSupported = (appType === "ios" && isNewAppVersion(deviceOsVersion, "14.3.0"))
  const isMacOSSupported = (appType === "mac" && isNewAppVersion(currentAppVersion,"9.0.1") && isNewAppVersion(deviceOsVersion,"11.0.0"))
  let shouldVideoButtonDisable =
    (appType === "mac" && !isMacOSSupported ) || (appType === "ios" && !isiOSSupported);
  const [state, setState] = useState(parsedJSONObject);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    updatedCount: 0,
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitchValue: false,
  });
  const screenSettings = props.screenSettings || {};
  let currentJSON, attempt_status;
  if (presentationMode) {
    currentJSON = parsedJSONObject;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    attempt_status = attemptStatus;
  }
  const segment_data = currentJSON?.segment_data || {};
  const attachments = segment_data.attachments || [];
  const maxRecordingDuration =
    segment_data.max_recording_duration ||
    300;
  const secondsArray = range(5, 60, 5);
  const minutesArray = range(1, 21);

  const [durationType, setDurationType] = useState(
    maxRecordingDuration < 60 ? "seconds" : "minutes"
  );
  const mrIntl = useTranslate();

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  useEffect(() => {
    setMediaFormSetting({
      ...mediaFormSetting,
      maxRecordingDuration: maxRecordingDuration
    })
  }, [maxRecordingDuration]);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
      if (props.onSubmit) {
        console.log("On submit is available now", props.onSubmit);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (newState) => {
    console.log("line number 23", newState);
    setState(newState);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const toggleAnswer = () => {
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: !stateSetting.quickCheckModeEnabled,
    });
  };

  const mediaFormInitialState = {
    modal: true,
    loading: false,
    visible: false,
    title: "Record",
    okText: "Save",
    cancelText: "Cancel",
    triggerSaveAction: 0,
    requireFooter: false,
    currentMediaType: "video",
    content_type: "video",
    okDisabled: true,
    maxRecordingDuration: maxRecordingDuration,
    selectConfig: {
      maxSize: "100 MB",
    },
  };
  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );

  const onSaveMedia = (files) => {
    console.log("Files ==>", files);

    const newSegmentData = {
      ...segment_data,
      attachments: files,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    const isVideoRecorded = files && files.length ? true : false;
    if (answerMode && isVideoRecorded) {
      setAttemptStatus({
        ...attemptStatus,
        video_recorded: isVideoRecorded,
        json_attempted: isVideoRecorded || attemptStatus.caption_added,
      });
    }

    saveState(newState);

    setMediaFormSetting({
      ...mediaFormSetting,
      visible: false,
    });
  };

  const openVideoModal = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: true,
    });
  };

  const saveRecordingDuration = (type, value) => {
    let duration = 0;
    if (type == "seconds") {
      duration = value;
    } else {
      duration = 60 * value;
    }
    const newSegmentData = {
      ...segment_data,
      max_recording_duration: duration,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const onDurationTypeChange = (value) => {
    setDurationType(value ? "seconds" : "minutes");
  };

  const resetQuestion = () => {
    const data = cloneDeep(props.student_json);
    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    saveState(data);

    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };



  return (
    <Segment>
      <SegmentData className={"media-input"}>
        {createMode ? (
          <div>
            <Alert
              description={
                mrIntl("VideoInput.student_will_get_option_to_record_video_answer_here_not")
              }
              type="info"
              closable={false}
            />
            <div style={{ margin: "10px" }}>
              Max recording time:{" "}
              <Select
                placeholder="Time in seconds"
                value={
                  durationType == "seconds"
                    ? maxRecordingDuration
                    : maxRecordingDuration / 60
                }
                onChange={(value) => saveRecordingDuration(durationType, value)}
                style={{ width: "80px" }}
              >
                {durationType == "seconds"
                  ? secondsArray.map((second) => (
                      <Option value={second}>{second}</Option>
                    ))
                  : minutesArray.map((minute) => (
                      <Option value={minute}>{minute}</Option>
                    ))}
              </Select>{" "}
              <Switch
                size="large"
                checkedChildren="seconds"
                unCheckedChildren="minutes"
                defaultChecked={durationType == "seconds" ? true : false}
                onChange={onDurationTypeChange}
              />
            </div>
          </div>
        ) : (
          <Row>
            <Col span={24}>
              {answerMode && (
                <>
                  <Badge
                    count={
                     (shouldVideoButtonDisable) ?
                       (
                        <Tooltip
                          title={mrIntl("VideoInput.not_supported_on_mac_os_110_and_below_and_assessprep")}
                          placement="top"
                        >
                          <QuestionCircleTwoTone />
                        </Tooltip>
                      ): null
                    }
                  >
                    <Button
                      className="record-video-answer"
                      onClick={(e) => openVideoModal()}
                      size="large"
                      type="primary"
                      ghost={true}
                      icon={<VideoCameraAddOutlined />}
                      disabled={!answerMode && shouldVideoButtonDisable}
                    >
                      Record Video Answer
                    </Button>
                  </Badge>
                  {mediaFormSetting.visible && (
                    <MediaRecorder
                      modal={true}
                      video={true}
                      mediaFormSetting={mediaFormSetting}
                      setMediaFormSetting={setMediaFormSetting}
                      onSaveMedia={onSaveMedia}
                      appType={appType}
                    ></MediaRecorder>
                  )}
                </>
              )}
              <p style={{ color: "#8b8b8b", marginTop: "5px" }}>
                <i>
                  Max allowed recording duration is{" "}
                  {maxRecordingDuration < 60
                    ? `${maxRecordingDuration} seconds`
                    : `${maxRecordingDuration / 60} minute${
                        maxRecordingDuration >= 120 ? "s" : ""
                      }`}
                </i>
              </p>
              {attachments && attachments.length > 0 && (
                <MediaShow file={attachments[0]} mediaType="video"></MediaShow>
              )}
            </Col>
          </Row>
        )}
      </SegmentData>
      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        toggleAnswer={toggleAnswer}
        resetQuestion={resetQuestion}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default VideoInput;