import { createSelector } from "reselect";

export const programmesSubjectsFromRequestState = (state, props) =>
  state.request.get("programmesubjects", []);

export const programmeSubjectsState = (state) =>
  state.programmeSubjects.get("programme_subjects", []);

export const programmeSubjectsSelector = () =>
  createSelector(
    [programmesSubjectsFromRequestState, programmeSubjectsState],
    (programmesSubjectsFromRequest, programmeSubjects) =>
      (programmesSubjectsFromRequest.length && programmesSubjectsFromRequest) ||
      programmeSubjects
  );