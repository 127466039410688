import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Table, Tag, Tooltip, Space, Col, Badge, Switch, Alert, Menu, Dropdown, Button, Modal, Row, Skeleton } from "antd";
// import { useLocation, Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import '../Groups.scss'
import { MessageOutlined, SortAscendingOutlined, DownOutlined, CloseCircleOutlined, FileDoneOutlined, FileOutlined } from "@ant-design/icons";
import _, { debounce } from "lodash";
// import ExportResultsProgressModal from "../ExportResultsProgressModal";
// import {
  // dbUserResponsesSelector,
  // allUserResponsesSelector,
// } from "/src/views/UserResponses/selector";
import { actions as userResponseReduxActions } from "/src/views/UserResponses/redux";
import { actions as topicActions } from "/src/views/Segments/Topics/redux";
import { calculatePercentage, displayPoints, dropDownTrigger, getLabelFromIndexPath, getQuestionNumber, getUniqueId } from "/src/lib/utils/helperMethods";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
// import ExperienceUserListItem from "./ExperienceUserListItem";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import { topicsSelector } from "/src/views/Segments/Topics/selector";
import { activeExperienceGradingDisabledSelector } from "/src/views/Experiences/selector";
import { studentNameFormatSelector, currentCriteriaIdSelector, experienceUsersLoadingSelector } from "../selector";
import ViewUploadsModal from "/src/components/UI/ViewUploadsModal";
import ExperienceUserGradingProgressBar from "../ExperienceUserItemDetail/ExperienceUserGradingProgressBar";
import ExperienceUsersVirtualList from "./ExperienceUsersVirtualList";
import { getRecursiveSegments } from "/src/views/Experiences/ExperienceHelperMethods";
import ExperienceUserOverallGradingForm from "../ExperienceUserItemDetail/ExperienceUserOverallGradingForm";
import ExperienceUserGradingModal from "../ExperienceUserItemDetail/ExperienceUserGradingModal";
import UserResponseCellDetail from "./UserResponseCellDetail";
import ExperienceUserOverallPoints from "./ExperienceUserOverallPoints";
import MarksLegend from "/src/components/UI/MarksLegend";
import NoData from "/src/components/UI/NoData/NoData";
import ExperienceUserOverallTitle from "./ExperienceUserOverallTitle";
import ExperienceUsersTable from "./ExperienceUsersTable";
import ExperienceUsersListTableFooter from "./ExperienceUsersListTableFooter";
import { canvasLoadingSelector } from "/src/views/CanvasAnnotations/selector";
import { loadingSelector } from "/src/views/UserResponses/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useHistory } from "react-router-dom";
import { message } from "/src/components/UI/AntdAppHelper";


const ExperienceUsersList = (props) => {
  console.log("ExperienceUsersList props===>", props);
  const {
    // dataSource,
    experienceUsers,
    experience,
    gradingConfig,
    setGradingConfig,
    exportModalConfig = {},
    setExportModalConfig,
    // setParams,
    updateResource
  } = props;
  // const { path, url } = useRouteMatch();
  // const history = useHistory();
  // const location = useLocation();
  const currentUser = useSelector(currentUserSelector());
  // const activeAdjustedExperienceId = useSelector(
  //   activeAdjustedExperienceIdSelector()
  // );
  
  // const [sortedColumn, setSortedColumn] = useState({
  //   segmentId: "",
  //   loading: false,
  //   sortOrder: ""
  // })
  const mrIntl = useTranslate()
  const history = useHistory();
  const rubric = experience.rubric;
  const rubricType = rubric && rubric.type_c;

  const experienceSettings = experience.settings || {};

  // const isAllowStudentUploadFiles =
  //   experienceSettings.allow_student_upload_files;
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  
  const isAnonymousGradingAvailable = enabledFeatures.anonymous_grading && experienceSettings.anonymous_grading_enabled;

  useEffect(() => {
    if (currentUser.role !== "student" || !experienceSettings.peer_review_enabled) return;
    const reviewCloseDateTime = Date.parse(experienceSettings?.reviewer_setting?.close_review_at_datetime);
    const currentTime = new Date().getTime();
    const isReviewTimeClosed = reviewCloseDateTime < currentTime;
    console.log("check Time Passed==>", reviewCloseDateTime < currentTime)
    if (isReviewTimeClosed) {
      let urlToOpen = `/u/tests`;
      message.error(mrIntl("ExperienceUsersList.review_time_has_been_passed"));
      history.push(urlToOpen);
    }
  }, [])

  return (
    <React.Fragment>
      {/* <Col span={24}></Col> */}
      {/* <Col span={24} style={{ textAlign: "right" }} className="p-0-20">
        {gradeListActionsButton}
      </Col> */}
      {/* Hiding this in student view now = showing the student header in EU item detail now */}
      {experienceUsers.length > 0 ?
        <>
        
          <ExperienceUsersTable experienceUsers={experienceUsers} gradingConfig={gradingConfig} setGradingConfig={setGradingConfig} exportModalConfig={exportModalConfig} setExportModalConfig={setExportModalConfig} updateResource={updateResource} isAnonymousGradingAvailable={isAnonymousGradingAvailable}/>
          <ExperienceUsersListTableFooter rubricType={rubricType} gradingConfig={gradingConfig} setGradingConfig={setGradingConfig} isAnonymousGradingAvailable={isAnonymousGradingAvailable}/>
        </> 
        : <div className="empty-submissions-message"> <NoData description={mrIntl("ExperienceUsersList.no_submissions_here")} /></div>}
      {/* REVERTING TO TABLE since virtual list columns freeze is via a hack of making 2 grids and syncing scroll - and scroll can be laggy - plus it re-renders all cells coming back into the visible view */}
      {/* <ExperienceUsersVirtualList 
      // ref={tableRef}
      columns={gridColumns}
      dataSource={experienceUsers}
      experienceSettings={experienceSettings}
      setURLWithExperienceUserId={setURLWithExperienceUserId}
      /> */}

      <ExperienceUserGradingModal
        experience={experience} 
        gradingConfig={gradingConfig} 
        setGradingConfig={setGradingConfig} 
        exportModalConfig={exportModalConfig} 
        setExportModalConfig={setExportModalConfig}
        {...props}
      >
      </ExperienceUserGradingModal>
      
      <ExperienceUserOverallGradingForm experience={experience} showFormVal={props.showFormVal} renderForm={props.renderForm} showForm={props.showForm} isModal={true} gradingConfig={gradingConfig}></ExperienceUserOverallGradingForm>
      
    </React.Fragment>
  );
};
ExperienceUsersList.defaultProps = {};

ExperienceUsersList.propTypes = {};

export default ExperienceUsersList;
