import React, { useState } from "react";
import ListAttachments from "/src/components/UI/Attachments/ListAttachments";
import { useSelector } from "react-redux";
import { printModeSelector } from "/src/views/Experiences/selector";

const ShowMediaComments = (props) => {
  console.log("ShowMediaComments props", props);
  const { media_comments, showMiniAudioPlayer, removeFileIcon, onChange } = props;
  const printMode = useSelector(printModeSelector())
  if (props.media_comments.attachments) {
    var attachments = media_comments.attachments;
  }

  const removeFile = () => {
    const newAttachments = [...attachments]
    newAttachments.splice(0, 1);

    const newMediaComments = {
      attachments: newAttachments
    };

    onChange(newMediaComments);

  };
  
  return (
    <div className={`show-media-comments ${showMiniAudioPlayer && "mini-player"} ${printMode && "bordered-content print-media-comments"}`}>
      {<ListAttachments
        titlePrefix={"Audio"}
        hideTitle={true}
        attachments={attachments}
        removeFile={removeFileIcon ? removeFile : ""}
      >
      </ListAttachments>}
    </div>
  );
};

export default ShowMediaComments;