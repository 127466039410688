import React, { useState } from 'react';
import { Modal, Button, Space, Row, Col, Tooltip, Select, Switch, Table } from 'antd';
import { CheckBox, showConfirmModal } from '/src/components/UI/Segment/UIHelper';
import { useDispatch } from 'react-redux';
import { actions as usersActions } from "/src/views/Users/redux";
import "./OrgPermissions.scss";
import { isEmpty } from 'lodash';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const PermissionCheckbox = ({ onChange, disabled, children, checked, tooltipTitle }) => (
  <Row>
    <Col span={24}>
      <Tooltip title={tooltipTitle} placement="bottom">
        <CheckBox onChange={onChange} disabled={disabled} checked={checked}>
          <b>{children}</b>
        </CheckBox>
      </Tooltip>
    </Col>
  </Row>
);

const BulkPermissionsModal = ({ fetchUsers = null, orgId }) => {
  const [visible, setVisible] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [permissionsToUpdateValues, setPermissionsToUpdateValues] = useState({});
  const defaultRole = {
    label: "All",
    value: "teacher_admin"
  }
  const [selectedRole, setSelectedRole] = useState(defaultRole);
  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  const isPermissionsToUpdateEmpty = isEmpty(permissionsToUpdateValues)
  const showBulkUpdateModal = () => {
    setVisible(true);
  }
  const handleCancel = () => {
    setVisible(false)
    setSelectedPermissions({})
    setPermissionsToUpdateValues({})
    setSelectedRole(defaultRole)
  };
  const toggleSelectedPermissions = (currentPermission, checked) => {
    const selectedPermissionsName = currentPermission.name

    if (checked) {
      // When we select permissions that time we set permissions to update values false
      togglePermissionToUpdateValues(currentPermission, false)
    } else {
      // remove key from permissionsToUpdateValues if we remove the selected permissions 
      let newPermissionsToUpdateValues = { ...permissionsToUpdateValues }
      delete newPermissionsToUpdateValues[selectedPermissionsName]
      setPermissionsToUpdateValues(newPermissionsToUpdateValues)
    }

    setSelectedPermissions({
      ...selectedPermissions,
      [selectedPermissionsName]: checked
    });
  }

  const togglePermissionToUpdateValues = (currentPermission, checked) => {
    setPermissionsToUpdateValues({
      ...permissionsToUpdateValues,
      [currentPermission.name]: {
        [currentPermission.permission]: checked
      }
    });
  }

  const data = [
    {
      key: '1',
      permissionTitle: mrIntl("BulkPermissionsModal.school_library"),
      name: 'schoolbank_experience',
      permission: 'list',
    },
    {
      key: '2',
      permissionTitle: mrIntl("BulkPermissionsModal.ap_banks"),
      name: 'questionbank_experience',
      permission: 'list',
    },
    {
      key: '3',
      permissionTitle: mrIntl("BulkPermissionsModal.ai_assistant"),
      name: 'ai_assistant',
      permission: 'show',
    },
    {
      key: '4',
      permissionTitle: mrIntl("BulkPermissionsModal.manage_permissions"),
      name: 'manage_permissions',
      permission: 'list',
    },
  ];

  const updateRolePermission = selectedRole.label

  const handleOk = () => {
    let permissionMessage = [];
    data.forEach(d => {
      const updatedPermission = permissionsToUpdateValues[d.name];
      if (updatedPermission && selectedPermissions[d.name]) {
        permissionMessage.push(`${d.permissionTitle}: ${updatedPermission[d.permission]}`);
      }
    });
    permissionMessage = permissionMessage.join(', ');
    const confirmationMessage = (
      <span>
        {mrIntl("BulkPermissionsModal.are_you_sure_you_want_to_update_bulk_permission_you_update_permission_for")}
        <strong> {updateRolePermission}:</strong> {permissionMessage}
      </span>
    );

    showConfirmModal({
      title: mrIntl("BulkPermissionsModal.update_bulk_permission"),
      content: confirmationMessage,
      onOk: () => {
        const roles = selectedRole.value === "teacher_admin" ? ["teacher", "admin"] : [selectedRole.value]
        dispatch(usersActions.updatePermission({ by_roles: roles, permissions: permissionsToUpdateValues, org_id: orgId }, {
          successCallback: () => {
            if (fetchUsers) {
              fetchUsers();
            }
            setSelectedPermissions({})
            setPermissionsToUpdateValues({})
            setSelectedRole(defaultRole)
            setVisible(false);
          }
        }))
      }
    })
  }

  const handleRoleChange = (value, extra) => {
    console.log('handleRoleChange ==>>', value, extra);
    if (value === undefined) {
      setSelectedRole(defaultRole)
    } else {
      setSelectedRole(extra)
    }
    setSelectedPermissions({});
    setPermissionsToUpdateValues({})
  }

  const columns = [
    {
      title: mrIntl("BulkPermissionsModal.permission_name"),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        const name = record.name
        const permission = record.permission
        const permissionName = record.permissionTitle
        const isDisabled = (selectedRole.value === 'teacher_admin' || selectedRole.value === 'teacher') && permissionName === 'Manage permissions'
        return (
          <Space>
            <PermissionCheckbox
              name={name}
              permission={permission}
              onChange={(e) => toggleSelectedPermissions({ name: name, permission: permission }, e.target.checked)}
              checked={selectedPermissions[name] ? true : false}
              disabled={isDisabled}
              tooltipTitle={isDisabled && mrIntl("BulkPermissionsModal.manage_permissions_are_only_available_for_admins_please_select_admin")}
            >
              {permissionName}
            </PermissionCheckbox>
          </Space>
        )
      },
    },
    {
      title: mrIntl("BulkPermissionsModal.value"),
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => {
        const recordName = record.name
        const permissionName = record.permission
        let finalRender = null
        if (selectedPermissions[recordName]) {
          finalRender = <Switch
            size="small"
            onChange={(checked) => {
              togglePermissionToUpdateValues({ name: recordName, permission: permissionName }, checked)
            }}
          />
        }
        return finalRender;
      },
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={() => showBulkUpdateModal()}>
        {mrIntl("BulkPermissionsModal.bulk_update")}
      </Button>
      <Modal
        okText={mrIntl("BulkPermissionsModal.update")}
        title={mrIntl("BulkPermissionsModal.bulk_update_permissions")}
        visible={visible}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        okButtonProps={{
          disabled: isPermissionsToUpdateEmpty,
          title: isPermissionsToUpdateEmpty && mrIntl("BulkPermissionsModal.please_select_permission_first")
        }}
        className='user-permissions-filters'
      >
        <Space>
          <p className='select-role'>{mrIntl("BulkPermissionsModal.select_role")}</p>
          <Select
            allowClear
            className="user-permission-role"
            value={selectedRole.value}
            onChange={(value, extra) => handleRoleChange(value, extra)}
            options={[
              { label: mrIntl("CommonText.all"), value: "teacher_admin" },
              { label: mrIntl("CommonText.admin"), value: "admin" },
              { label: mrIntl("CommonText.teacher"), value: "teacher" }
            ]}
          />
        </Space>
        <p className='select-permissions-action'>{mrIntl("BulkPermissionsModal.select_permissions_and_action")}</p>
        <Table className='bulk-update-table' bordered columns={columns} dataSource={data} pagination={false} />
      </Modal>
    </div>
  );
};

export default BulkPermissionsModal;