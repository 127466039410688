import { createSelector } from "reselect";
// import lodash from "lodash";

const annotationsLoadingState = (state, props) => {
  return state.annotations.get("loading");
};
export const annotationsLoadingSelector = () =>
  createSelector([annotationsLoadingState], (annotationsLoading) => annotationsLoading);

const annotationsFetchLoadingState = (state, props) => {
  return state.annotations.get("fetchloading", false);
};
export const annotationsFetchLoadingSelector = () =>
  createSelector([annotationsFetchLoadingState], (annotationsFetchLoading) => annotationsFetchLoading);

// const errorState = (state, props) => state.annotations.get("error");

// export const errorSelector = () =>
//   createSelector([errorState], (error) => error);

// const annotationsState = (state, props) =>
//   state.annotations.get("annotations");

// export const annotationsSelector = () =>
//   createSelector([annotationsState], (annotations) => annotations);

// const annotationsShowState = (state, props) => {

//   const annotations = state.annotations.get("annotations");

//   if (annotations.size === 0) {
//     return {};
//   } else {
//     const id = parseInt(props.match.params.id);
//     const idx = lodash.findIndex(annotations, {
//       id: id,
//     });
//     if (idx !== -1) {
//       return annotations[idx];
//     }
//     return {};
//   }
// };

// export const annotationsShowSelector = () =>
//   createSelector([annotationsShowState], (annotations) => annotations);

// const paginationState = (state, props) => {
//   const pageObj = {};
//   pageObj.page = state.annotations.get("page");
//   pageObj.totalPages = state.annotations.get("total_pages");

//   return pageObj;
// };

// export const paginationSelector = () =>
//   createSelector([paginationState], (pageObj) => pageObj);


const annotationsState = (state) => {
  return state.annotations.get("annotations", []);
}

export const annotationsSelector = (segmentId, archived) => createSelector(
  [annotationsState],
  (annotations) => {
    console.log("annotationsSelector ==> line 67", annotations, segmentId, archived)    
    return annotations.filter(
      (annotation) => {
        console.log("annotationsSelector ==> line 70", annotations, segmentId, archived)    
        return annotation.segment_id === segmentId && annotation.archived === archived
      }
    );
    
  }
);


export const userAnnoationsSelector = (user_id) => 
  createSelector([annotationsState], (annotations) => {
    return annotations.filter((annotation) => annotation.user_id == user_id) || []
  });

export const uriAnnoationsSelector = (uri, user) => 
  createSelector([annotationsState], (annotations) => {
    console.log("uri annotations ==>", annotations, uri, user)
    return annotations.filter((annotation) => {
      if (!annotation.archived && annotation.uri === uri) {
        if ( user === 'admin' || user === 'teacher') {
        } else {
          annotation.permissions = {
            create: false,
            update: false,
            delete: false,
          }
        }
        return annotation
      }
    }) || []
  });