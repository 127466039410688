import React, { useState, useEffect } from "react";
import { Spinner, MrSelect } from "mr_react_framework";
import { useDispatch, useSelector } from "react-redux";
import {
  appRegionSelector,
  currentUserSelector,
} from "/src/views/Auth/Login/selector";
import { checkMob, displayPoints, getAPColorPallete } from "/src/lib/utils/helperMethods";
import { Button, Col, Row, Space, Statistic, Table, Tag, Tooltip, Typography } from "antd";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import { activeAdjustedExperienceSelector, experienceAnalyticsQuestionsSelector } from "/src/views/Experiences/selector";
import loadable from "@loadable/component";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { isArray } from "lodash";
import {RightOutlined, LeftOutlined} from '@ant-design/icons';
import { drawTopLineLabels } from "./ExperienceAnalytics";

const { Title } = Typography;
const RCJChart = loadable(() =>
  import(/* webpackChunkName: "react-chart" */ "/src/views/Segments/RCJChart/RCJChart")
);

// const QuestionsChart = (props) => {
//   const { chartData } = props
//   const mrIntl = useTranslate()
//   console.log("ExperienceAnalyticsSummary QuestionsChart chartData", chartData)
//   const apColors = getAPColorPallete();
  
//   const [currentPage, setCurrentPage] = useState(0);
//   const itemsPerPage = 10;

//   // Determine whether to disable the buttons
//   const isPreviousDisabled = currentPage === 0;
//   const totalPages = Math.ceil(chartData.labels.length / itemsPerPage);
//   const isNextDisabled = currentPage >= totalPages - 1;

//   // Get the current page data
//   const getCurrentPageData = () => {
//     const startIndex = currentPage * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     return {
//       labels: chartData.labels.slice(startIndex, endIndex),
//       dataset: chartData.dataset.map(data => data.slice(startIndex, endIndex)),
//     };
//   };

//   const currentData = getCurrentPageData();
//   console.log('startIndex, endIndex', currentData);

//   const handlePrevious = () => {
//     if (currentPage > 0) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   const handleNext = () => {
//     const totalPages = Math.ceil(chartData.labels.length / itemsPerPage);
//     if (currentPage < totalPages - 1) {
//       setCurrentPage(currentPage + 1);
//     }
//   };
  
//   let chart_settings = {
//     type: "bar",
//     // type: "pie",
//     // title: "Student performance",
//     xAxesLabel: "Score range (%)",
//     yAxesLabel: "Number of students",
//     labels: currentData.labels, 
//     // datasets: chartData.student_counts,
//     // labels: ["10", "20", "30", "40"], // Column headers - week number or month number
//     // labels: ["Jan", "Feb", "Mar", "Apr"], // Column headers - week number or month number
//     datasets: [ // one object for each data row/line graph - one object for single line, multiple for multiple lines
//       {
//         data: currentData.dataset[0],
//         label: mrIntl("ExperienceAnalyticsQuestions.unattempted"),
//         backgroundColor: apColors.lightgrey // array for pie/bar if all different needed
//       },
//       {
//         data: currentData.dataset[1],
//         label: mrIntl("ExperienceAnalyticsQuestions.ungraded"),
//         backgroundColor: apColors.grey
//       },
//       {
//         data: currentData.dataset[2],
//         label: mrIntl("CommonText.0_25_label"),
//         backgroundColor: apColors.red
//       },
//       {
//         data: currentData.dataset[3],
//         label: mrIntl("CommonText.25_50_label"),
//         backgroundColor: apColors.lightorange
//       },
//       {
//         data: currentData.dataset[4],
//         label: mrIntl("CommonText.50_75_label"),
//         backgroundColor: apColors.green
//       },
//       {
//         data: currentData.dataset[5],
//         label: mrIntl("CommonText.75_100_label"),
//         backgroundColor: apColors.blue
//       },
//       // Can't do this on same scale/axes as the student count y axis
//       // {
//       //   type: "line",
//       //   data: chartData.dataset[4],
//       //   label: "Average",
//       //   // backgroundColor: apColors.blue
//       // }
//     ],
//     showGridLines: false,
//     showLegend: true,
//     customColors: true, // only supported in bar for now
//     stacked: true,
//     // stepSize: 2, // using precision: 0 instead // only supported in bar for now
    
//   }

//   return <div className="question-graph-container">
//     <Tooltip title={mrIntl("ExperienceAnalyticsQuestions.previous")} placement="left">
//       <Button
//         type="primary"
//         onClick={handlePrevious}
//         disabled={isPreviousDisabled}
//         icon={<LeftOutlined />}
//       />
//     </Tooltip>
//     <div className="question-graph">
//       <RCJChart chart_settings={chart_settings} />
//     </div>
//     <Tooltip title={mrIntl("ExperienceAnalyticsQuestions.next")} placement="right">
//       <Button
//         type="primary"
//         onClick={handleNext}
//         disabled={isNextDisabled}
//         icon={<RightOutlined />}
//       />
//     </Tooltip>
//   </div>
// }
const QuestionsChart = (props) => {
  const { chartData } = props
  const mrIntl = useTranslate()
  const apColors = getAPColorPallete();
  console.log("ExperienceAnalyticsSummary QuestionsChart chartData", chartData)

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  // Determine whether to disable the buttons
  const isPreviousDisabled = currentPage === 0;
  const totalPages = Math.ceil(chartData?.labels?.length / itemsPerPage);
  const isNextDisabled = currentPage >= totalPages - 1;
  const showNextAndPrevious = totalPages > 1;

  // Get the current page data
  const getCurrentPageData = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return {
      labels: chartData?.labels?.slice(startIndex, endIndex),
      dataset: chartData?.dataset?.map(data => data.slice(startIndex, endIndex)),
    };
  };

  const currentData = getCurrentPageData();
  console.log('startIndex, endIndex', currentData);

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  let chart_settings = {
    type: "bar",
    // type: "pie",
    // title: "Student performance",
    xAxesLabel: "Questions",
    yAxesLabel: "Average (%)",
    labels: currentData.labels, 
    max: 100,
    // scales: {
    //   max: 100
    // },
    
    // datasets: chartData.student_counts,
    // labels: ["10", "20", "30", "40"], // Column headers - week number or month number
    // labels: ["Jan", "Feb", "Mar", "Apr"], // Column headers - week number or month number
    datasets: [ // one object for each data row/line graph - one object for single line, multiple for multiple lines
      // {
      //   data: currentData.dataset[0],
      //   label: mrIntl("ExperienceAnalyticsQuestions.unattempted"),
      //   backgroundColor: apColors.red // array for pie/bar if all different needed
      // },
      // {
      //   data: currentData.dataset[1],
      //   label: mrIntl("ExperienceAnalyticsQuestions.ungraded"),
      //   backgroundColor: apColors.grey
      // },
      {
        data: currentData.dataset[0],
        label: "Average %",
        backgroundColor: apColors.lightblue,
        barThickness: 30,
      },
      // {
      //   data: currentData.dataset[1],
      //   label: mrIntl("CommonText.25_50_label"),
      //   backgroundColor: apColors.lightorange
      // },
      // {
      //   data: currentData.dataset[2],
      //   label: mrIntl("CommonText.50_75_label"),
      //   backgroundColor: apColors.lightblue
      // },
      // {
      //   data: currentData.dataset[3],
      //   label: mrIntl("CommonText.75_100_label"),
      //   backgroundColor: apColors.lightgreen
      // },
      // Can't do this on same scale/axes as the student count y axis
      // {
      //   type: "line",
      //   data: chartData.dataset[4],
      //   label: "Average",
      //   // backgroundColor: apColors.blue
      // }
    ],
    showGridLines: false,
    showLegend: false,
    customColors: true, // only supported in bar for now
    // stacked: true,
    // stepSize: 20, // using precision: 0 instead // only supported in bar for now 
    topLine: drawTopLineLabels(true),
    layout: {  
      padding: {
        top: 20,
      },
    },
  }

  return <div className="question-graph-container">
    {showNextAndPrevious && <Tooltip title={mrIntl("ExperienceAnalyticsQuestions.previous")} placement="left">
      <Button
        type="primary"
        onClick={handlePrevious}
        disabled={isPreviousDisabled}
        icon={<LeftOutlined />}
      />
    </Tooltip>}
    <div className="question-graph">
    <Row>
      <Title level={3}>
        {mrIntl("CommonText.points")}
      </Title>
    </Row>
      <RCJChart chart_settings={chart_settings} />
    </div>
    {showNextAndPrevious && <Tooltip title={mrIntl("ExperienceAnalyticsQuestions.next")} placement="right">
      <Button
        type="primary"
        onClick={handleNext}
        disabled={isNextDisabled}
        icon={<RightOutlined />}
      />
    </Tooltip>}
  </div>
}

const ExperienceAnalyticsQuestions = (props) => {
  console.log("ExperienceAnalyticsQuestions props ==>", props);
  // const { } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const rubric = activeExperience.rubric || {};
  const experienceAnalyticsQuestionsData = useSelector(experienceAnalyticsQuestionsSelector());
  const mrIntl = useTranslate()
  const experienceRubric = activeExperience.rubric || {};
  const experienceRubricTypeC = experienceRubric.type_c
  const isCWPorAL = experienceRubricTypeC === "myp_achievement_level" || experienceRubricTypeC === "criteria_with_points"

  useEffect(() => {
    dispatch(experienceActions.getAnalytics({experience_id: activeExperience.id, type: "questions"}))
    return () => {
    };
  }, []);

  const newChartData = {
    labels: [],
    dataset: [[]],
  }
  
  experienceAnalyticsQuestionsData?.question_data?.map((qd) => {
    newChartData.labels.push(qd.question_no)
    newChartData.dataset[0].push(qd.average_percentage)
  })
  console.log("ExperienceAnalyticsQuestions experienceAnalyticsQuestionsData", experienceAnalyticsQuestionsData);

  const columns = [
    // {
    //   title: mrIntl("CommonText.id"),
    //   dataIndex: "segment_id",
    //   key: "segment_id",
    //   sorter: (a, b) => a.segment_id.localeCompare(b.segment_id),
    //   sortDirections: ["ascend", "descend"],
    //   defaultSorter: "ascend",
    // },
    {
      title: mrIntl("CommonText.question"),
      dataIndex: "question_no",
      key: "question_no",
      sorter: (a, b) => a.question_no.localeCompare(b.question_no),
      sortDirections: ["ascend", "descend"],
      defaultSorter: "ascend",
    },
    ...(isCWPorAL
      ? [{
          title: mrIntl("ExperienceAnalyticsQuestions.criteria"),
          dataIndex: 'criteria',
          key: "criteria",
          render: (_, item, rowIndex) => {
            const criteriumAssociationsAttributesTitle = item.criterium_associations_attributes.map(ca => ca.custom_fields.title)
            return criteriumAssociationsAttributesTitle.join(',')
          }
        }]
      : []),
    ...(isCWPorAL
      ? [{
          title: mrIntl("ExperienceAnalyticsQuestions.strands"),
          dataIndex: "strands",
          key: "strands",
          render: (_, item, rowIndex) => {
            const strandsLabel = []
            item.criterium_associations_attributes.map(ca => {
              if (isArray(ca.strands)) {
                ca.strands.forEach(strandLabel => strandsLabel.push(strandLabel.label))
              }
            })
            const newLabel = strandsLabel.length ? strandsLabel.join(",") : "-";
            return newLabel
          }
        }]
      : []),
    {
      title: mrIntl("CommonText.type"),
      dataIndex: "question_type",
      key: "question_type",
      sorter: (a, b) => a.question_type.localeCompare(b.question_type),
      sortDirections: ["ascend", "descend"],
      // defaultSorter: "ascend",
    },
    
    ...(!isCWPorAL
      ? [{
        title: mrIntl("ExperienceAnalyticsQuestions.standards"),
        dataIndex: "syll_topic_labels",
        key: "syll_topic_labels",
        // width: 150,
        // sorter: (a, b) => a.question_type.localeCompare(b.question_type),
        // sortDirections: ["ascend", "descend"],
        // defaultSorter: "ascend",
        render: (_, item, rowIndex) => (
          item.syll_topic_labels.map((label) => { return <Tag>{label.substring(0,8)}</Tag>})
        ),
      }]
      : []),
    ...(!isCWPorAL
      ? [{
        title: mrIntl("ExperienceAnalyticsQuestions.highest_points"),
        dataIndex: "points",
        key: "points",
        sorter: (a, b) => a.points - b.points,
        sortDirections: ["ascend", "descend"],
        // defaultSorter: "ascend",
        render: (_, item, rowIndex) => (
          // displayPoints(item.points)
          displayPoints(item.max_points) // max scored by student, not max of question
        ),
      }]
      : []),
    ...(!isCWPorAL
      ? [{
        title: mrIntl("ExperienceAnalyticsQuestions.avg_points"),
        dataIndex: "average_points",
        key: "average_points",
        sorter: (a, b) => a.average_points - b.average_points,
        sortDirections: ["ascend", "descend"],
        // defaultSorter: "ascend",
        render: (_, item, rowIndex) => (
          displayPoints(item.average_points)
        ),
      }]
      : []),
    ...(!isCWPorAL
      ? [{
        title: mrIntl("ExperienceAnalyticsQuestions.avg_percentage"),
        dataIndex: "average_percentage",
        key: "average_percentage",
        sorter: (a, b) => a.average_percentage - b.average_percentage,
        sortDirections: ["ascend", "descend"],
        // defaultSorter: "ascend",
        render: (_, item, rowIndex) => (
          displayPoints(item.average_percentage)
        ),
      }]
      : []),
    {
      title: mrIntl("ExperienceAnalyticsQuestions.attempted"),
      dataIndex: "attempted_percentage",
      key: "attempted_percentage",
      sorter: (a, b) => a.attempted_percentage - b.attempted_percentage,
      sortDirections: ["ascend", "descend"],
      // defaultSorter: "ascend",
    },
    // {
    //   title: mrIntl("ExperienceAnalyticsQuestions.marked"),
    //   dataIndex: "marked_percentage",
    //   key: "marked_percentage",
    //   sorter: (a, b) => a.marked_percentage - b.marked_percentage,
    //   sortDirections: ["ascend", "descend"],
    //   // defaultSorter: "ascend",
    // },
  ]

  return (
    <React.Fragment>
      {experienceAnalyticsQuestionsData?.question_data && (experienceRubricTypeC === "points" || experienceRubricTypeC === "criteria_with_points") && <Row className="m-t-30">
        <Col span={24}>
           <QuestionsChart chartData={newChartData} />
        </Col>
      </Row>}
      {experienceAnalyticsQuestionsData.question_data && <Row className="m-t-30">
        <Col span={24}>
          <Row>
            <Title level={3}>
              {mrIntl("ExperienceAnalyticsQuestions.questions")}
            </Title>
          </Row>
          {/* {JSON.stringify(experienceAnalyticsQuestionsData.question_data)} */}
          <Table
            // rowSelection={rowSelection}
            dataSource={experienceAnalyticsQuestionsData.question_data}
            columns={columns}
            pagination={false}
            // style={{ maxHeight: "500px", overflow: "auto" }}
          />
        </Col>
      </Row>}
    </React.Fragment>
  );
};

export default ExperienceAnalyticsQuestions;
