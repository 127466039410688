import { Col, Modal, Row, Input, Typography } from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { BiMessageRoundedError } from "react-icons/bi";
import { actions } from "/src/views/Feedbacks/redux";
import "./SegmentItemFeedback.scss";
import { getLabelFromIndexPath, removeHTMLTagsFromText, renderMathInElement } from "/src/lib/utils/helperMethods";
import { isEmpty } from "lodash";

const { Text } = Typography;

const SegmentItemFeedbackModal = ({ isModalOpen, setIsModalOpen, segment, thisSegmentParentIdxs }) => {
  const { TextArea } = Input;
  const [feedbackMsg, setFeedbackMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const mrIntl = useTranslate();
  const dispatch = useDispatch();
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  let subjectLabel = null;
  if (activeExperience.custom_fields) {
    if (activeExperience.custom_fields.subject_item_label) {
      subjectLabel = activeExperience.custom_fields.subject_item_label;
    }
  }
  let qNo = null;
  if (segment.segment_type === "question" && isEmpty(segment.experience_data)) {
    qNo = getLabelFromIndexPath(thisSegmentParentIdxs);
  }
  console.log("line no 45==>", segment)
  const segmentTitleRef = useRef(null);
  let accessCode = null;
  if (activeExperience) {
    accessCode = activeExperience.access_code;
  }

  let paperUUID = null;
  if (segment.experience_data) {
    paperUUID = segment.experience_data.paper_uuid;
  }

  useEffect(() => {
    setTimeout(() => {
      console.log("Rendering math");
      renderMathInElement(segmentTitleRef.current, true);
    }, 100);
  }, [isModalOpen]);
  const isSection = segment.segment_type === "section";
  const isNonTextSegment =
    segment.segment_type !== "text" && segment.segment_type !== "resource";
  let qbName = null;
  if (activeExperience.questionbank_title !== "Schoolbank") {
    qbName = activeExperience.questionbank_title
  } else if (segment.experience_data) {
    qbName = segment.experience_data.qb_title;
  } 
  let segmentAttributesContent = null;
  if (segment.question_segment_attributes) {
    if (segment.question_segment_attributes.content) {
      segmentAttributesContent = segment.question_segment_attributes.content;
    }
  } else if (segment.text_segment_attributes) {
    if (segment.text_segment_attributes.content) {
      segmentAttributesContent = segment.text_segment_attributes.content;
    }
  }
  console.log("line no 60=>", activeExperience, segment)
  let segmentAttributesTitle = null;
  let finalSectionTitle = null;
  if (segment.section_segment_attributes) {
    segmentAttributesTitle = segment.section_segment_attributes.title;
  }
  const segmentContent = !isSection
    ? removeHTMLTagsFromText(segmentAttributesContent, true)
    : "";

  const sectionTitle = removeHTMLTagsFromText(segmentAttributesTitle, true);
  finalSectionTitle = sectionTitle.replace('&nbsp;', "");
  let payloadMessage = `Feedback on ${isSection ? "Section" : "Segment"}`;
  if (qbName) {
    payloadMessage = payloadMessage + `\nQuestion bank:- ${qbName}`;
  }
  if (activeExperience) {
    payloadMessage = payloadMessage + `\nAssessment:- ${
        activeExperience.name}`;
  }
  if (subjectLabel) {
    payloadMessage = payloadMessage + `\nSubject:- ${subjectLabel}`;
  }
 if (segmentContent) {
   payloadMessage +=
     segmentContent.length <= 30
       ? `\nQuestion text:- ${segmentContent}`
       : `\nQuestion text:- ${segmentContent.slice(0, 50)}...`;
 }
  if (accessCode) {
    payloadMessage += `\nJoin code:- ${accessCode}`;
  }
  if (paperUUID) {
    payloadMessage += `\nPaper UUID:- ${paperUUID}`;
  }
  if (isSection) {
    payloadMessage += `\nSection title:- ${finalSectionTitle}`;
  } else if (qNo) {
    payloadMessage += `\nQuestion number:- ${qNo}`;
  }
  if (segment) {
    payloadMessage += `\nSegment ID:- ${segment.id}`;
  }
  if (feedbackMsg){
    payloadMessage += `\nMessage:- ${feedbackMsg}`;
  }
  const handleOk = () => {
    setLoading(true);
    setFeedbackMsg("");
    let feedbackData = {
      feedback_type: "reported_issue",
      message: payloadMessage,
    };
    dispatch(
      actions.create(
        {
          ...feedbackData,
        },
        {
          success: {
            showMessage: true,
            message: mrIntl("FeedbackModal.thank_you_for_your_feedback"),
          },
          successCallback: () => {
            setLoading(false);
            setIsModalOpen(false);
          },
          errorCallback: () => {
            setLoading(false);
            setIsModalOpen(false);
          },
          error: {
            showMessage: true,
            message: "Error feedback not sent",
          },
        }
      )
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFeedbackMsg("");
  };
  return (
    <>
      {isModalOpen && (
        <Modal
          confirmLoading={loading}
          title={mrIntl("SegmentItemFeedback.report_an_issue_in_question_bank")}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={mrIntl("SegmentItemFeedback.submit_feedback")}
          okButtonProps={{ disabled: !feedbackMsg }}
        >
          <Row className="m-t-10 m-b-10">
            <Col span={6}>
              <p>{mrIntl("SegmentItemFeedback.question_bank")}</p>
            </Col>
            <Col span={18}>
              <Text type="secondary">{qbName}</Text>
            </Col>
            <Col span={6}>
              <p>{mrIntl("SegmentItemFeedback.subject")}</p>
            </Col>
            <Col span={18}>
              <Text type="secondary">{subjectLabel}</Text>
            </Col>
            {isSection ? (
              <>
                <Col span={6}>
                  <p>{mrIntl("SegmentItemFeedback.section_title")}</p>
                </Col>
                <Col span={18}>
                  <Text type="secondary">{finalSectionTitle}</Text>
                </Col>
              </>
            ) : (
              <>
                {(isNonTextSegment && qNo) ? (
                  <>
                    <Col span={6}>
                      <p>{mrIntl("SegmentItemFeedback.question_no")}</p>
                    </Col>
                    <Col span={18}>
                      <Text type="secondary">{qNo}</Text>
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </Row>
          <TextArea
            rows={4}
            value={feedbackMsg}
            onChange={(e) => setFeedbackMsg(e.target.value)}
            placeholder={mrIntl("SegmentItemFeedback.enter_feedback")}
            maxLength={500}
          />
        </Modal>
      )}
    </>
  );
};

export default SegmentItemFeedbackModal;
