import React, { useEffect, useRef, useState } from "react";
import { MrSelect, MrCrudView, FormRenderer } from "mr_react_framework";
import { InfoCircleTwoTone, QuestionCircleTwoTone, CopyOutlined, LinkOutlined, ClockCircleOutlined, DownOutlined } from "@ant-design/icons";
import { actions } from "./redux";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import ExperienceListItem from "./ExperienceListItem/ExperienceListItem";
import ExperienceList from "./ExperienceList/ExperienceList";
import ExperiencesFilter from "./ExperiencesFilter/ExperiencesFilter";
import commonSelectors from "/src/App/commonSelectors";
import { groupsSelector } from "../Groups/selector";
import { orgSubjectsSelector } from "../Orgs/selector";
import {
  buildOptionsArr,
  getZoomMeetingId,
  isValidZoomOrMeetUrl,
  isValidZoomUrl,
  isValidHttpsUrl,
  writeToClipboardPolyfill,
  focusElement,
} from "../../lib/utils/helperMethods";
import { actions as genericActions } from "/src/App/genericRedux";
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent";
import _, { range } from "lodash";
import { Switch, Space, Tooltip, Tag, Button, Radio, Select, Form, Input, DatePicker, Row, Checkbox, Col, Dropdown } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import { activeAdjustedExperienceSelector, activeExperienceSelector, experiencesSelector } from "./selector";
import moment from "moment";
import dayjs from "dayjs";
import generateExperienceCreateForm, { CustomRadioGroupWidget } from "./ExperienceForms/ExperienceCreateForm";
import ExperienceItemDetail from "/src/views/Experiences/ExperienceShow/ExperienceItemDetail";
import {
  appRegionSelector,
  enabledFeaturesSelector,
  partnerNameSelector,
} from "/src/views/Auth/Login/selector";
import { getExperienceModeOptionsForForm } from "./ExperienceHelperMethods";
import MrTranslate, { mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import CustomExperienceCrudList from "./CustomExperienceCrudList";
import { useDispatch, useSelector } from "react-redux";
import { CKETextContentTools } from "/src/components/UI/CKEditor/CKEConfig";
import { LeftSideFormChildComponent, RightSideFormChildComponent } from "./ExperienceShow/ExperienceManage/ExperienceDeliver/ExperienceDeliver";
import { CheckBox, showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import ExperienceMemberships from "./ExperienceShow/ExperienceManage/ExperienceDeliver/ExperienceMemberships";
// import TextArea from "views/Segments/TextArea/TextArea";
// import {getFromLS} from "lib/utils/helperMethods"
// import { appTypeSelector } from "App/OfflineApp/offlineAppSelectors";

const GenericWidgetCreator = (hocProps, Component) => {
  return (props) => {
    let finalHocProps = {};
    if (typeof hocProps === "function") {
      finalHocProps = hocProps(props);
    }
    return <Component {...props} {...finalHocProps} />;
  };
};

const SelectTeachers = MrSelect({
  actions: genericActions,
  resourceName: "users",
  config: {
    url: "/users",
    params: { by_role: "teacher_admin" }, //adding multiple roles and parsing on BE - want admin also here //default is org_id on BE
    processData: (data, props) => {
      console.log("data select SelectTeachers", data);
      // console.log("props select SelectTeachers", props, props.formInstance.getFieldValue(["experience_memberships_editors"]));

      // find author and remove from list - show only non-authors
      let expEditors = props.formInstance.getFieldValue([
        "experience_memberships_editors",
      ]);
      let authorId = null;
      if (expEditors && expEditors.length !== 0) {
        let author = expEditors.find((em) => em.memberable_type === "owner");
        if (author) {
          authorId = author.memberable_id;
        }
      }
      data = data.filter((teacher) => teacher.id !== authorId);
      // show only non-authors

      console.log("updated data", data);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.name, value: item.id };
      });
    },
    // searchApi: false,
    searchApi: true,
    widgetConfig: {
      showSearch: true, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Search for collaborators",
      mode: "multiple",
      style: { width: "400px" },
    },
  },
});

// BELOW NOT USED
const SelectStudents = MrSelect({
  actions: genericActions,
  resourceName: "students",
  apiResourceName: "users",
  config: {
    url: "/users",
    // params: {by_role: "student", by_org_programme_id: org_programme_id, by_grade_id: grade_id}, //default is org_id on BE
    params: { by_role: "student" }, //default is org_id on BE
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.name, value: item.id };
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"CommonText.search_for_students"}/>,
      mode: "multiple",
      // style: {width: "400px"},
    },
  },
});

const SelectGroups = MrSelect({
  actions: genericActions,
  resourceName: "groups",
  config: {
    url: "/groups",
    // params: {org_id: "value"},
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.name, value: item.id };
      });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: true, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      filterOption: true,
      optionFilterProp: "label",
      placeholder: `${<MrTranslate id={"Experiences.start_typing_to_search_placeholder"}/>}...`,
      mode: "multiple",
      // style: {width: "500px"},
      onDeselect: (val) => {
        console.log("val", val);
      },
    },
  },
});

const DurationCustomComponent = (props) => {
  console.log("DurationCustomComponent =====>", props, typeof props.value);
  const { formInstance: { getFieldValue, setFieldsValue }, value, disabled } = props
  const mrIntl = useTranslate()
  let finalRender = null
  const isTimed = getFieldValue(["settings", "is_timed"]);
  const [blurTriggered, setBlurTriggered] = useState(false)
  const durations = [
    "5",
    "10",
    "15",
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "55",
    "60",
    "65",
    "70",
    "75",
    "80",
    "85",
    "90",
    "95",
    "100",
    "105",
    "110",
    "115",
    "120",
    "125",
    "130",
    "135",
    "140",
    "145",
    "150",
    "155",
    "160",
    "165",
    "170",
    "175",
    "180",
    "185",
    "190",
    "195",
    "200",
    "205",
    "210",
    "215",
    "220",
    "225",
    "230",
    "235",
    "240",
  ];

  if (import.meta.env.VITE_MODE !== "production") {
    durations.unshift("1");
  }

  const handleOnChange = (value) => {
    console.log("onDurationChange =====>", value);
    setFieldsValue({settings: {is_timed: true}})
    props.onChange(value)
    // props.formInstance.validateFields()
  }

  const onBlur = () => {
    setBlurTriggered(true)
  }

  if(isTimed) {
    const showError = !value && blurTriggered
    finalRender = 
    <Form.Item
      help={showError ? mrIntl("CommonText.required_msg") : ""}
      validateStatus={showError ? "error" : ""}
      className="select-timer-dropdown"
    >
      <Select 
        autoFocus 
        options={buildOptionsArr(durations)} 
        placeholder={`${mrIntl("Experiences.duration")} *`} 
        onChange={(value) => handleOnChange(value)} 
        defaultValue={value} 
        onBlur={() => onBlur()}
        disabled={disabled}
      />
    </Form.Item>
  }

  return finalRender
}

// const DurationChildComponent = (props) => {
//   console.log("DurationChildComponent props", props, process.env);
//   const { getFieldValue, sourceValues, formInstance } = props;
//   const mrIntl = useTranslate()
//   const isTimed = getFieldValue(["settings", "is_timed"]);
//   console.log("isTimed ====>", isTimed);
//   let finalRender = null;

//   const durations = [
//     "5",
//     "10",
//     "15",
//     "20",
//     "25",
//     "30",
//     "35",
//     "40",
//     "45",
//     "50",
//     "55",
//     "60",
//     "65",
//     "70",
//     "75",
//     "80",
//     "85",
//     "90",
//     "95",
//     "100",
//     "105",
//     "110",
//     "115",
//     "120",
//     "125",
//     "130",
//     "135",
//     "140",
//     "145",
//     "150",
//     "155",
//     "160",
//     "165",
//     "170",
//     "175",
//     "180",
//     "185",
//     "190",
//     "195",
//     "200",
//     "205",
//     "210",
//     "215",
//     "220",
//     "225",
//     "230",
//     "235",
//     "240",
//   ];

//   if (import.meta.env.VITE_MODE !== "production") {
//     durations.unshift("1");
//   }

//   if (
//     isTimed === true ||
//     (!isTimed && sourceValues.id && sourceValues.is_timed === true)
//   ) {
//     finalRender = (
//       <FormRenderer
//         formObj={{
//           type: "object",
//           properties: {
//             duration: {
//               type: "string",
//               placeholder: `${mrIntl("Experiences.duration")} *`,
//               // placeholder: "Duration (mins) *",
//               widget: DurationCustomComponent,
//               widgetConfig: {
//                 options: buildOptionsArr(durations),
//                 // not working cuz don't have formValues
//                 // autoFocus: true,
//                 disabled: sourceValues.status !== "draft" ? true : false,
//               },
//               // span: 8,
//               setInitialValue: true,
//               // rules: [
//               //   {
//               //     required: true,
//               //     message: mrIntl("CommonText.required_msg"),
//               //   },
//               // ],
//               // validateTrigger: ["onBlur", "onChange"]
//             },
//           },
//         }}
//         propertyKey={"settings"}
//         sourceValues={sourceValues}
//         formInstance={formInstance}
//         options={buildOptionsArr(durations)}
//       />
//     );
//   }
//   return finalRender;
// };

const IsTimeCustomComponent = (props) => {
  console.log("IsTimeCustomComponent ====>", props);
  const { formInstance: { setFieldsValue }, checked, tooltipProps } = props

  const handleOnChange = (value) => {
    if(value) {
      setFieldsValue({ settings: { is_timed: value } })
    } else {
      setFieldsValue({ settings: { duration: null } })
      props.onChange(value)
    }
  }

  return (
    <CustomSwitchWidget placeholder={props.placeholder} checked={checked} onChange={handleOnChange} disabled={props.disabled}/>
  )
}

// const IsTimedChildComponent = (props) => {
//   console.log("IsTimedChildComponent props", props, process.env);
//   const { getFieldValue, sourceValues, setFieldsValue, formInstance } = props;
//   const mrIntl = useTranslate()
//   const duration = getFieldValue(["settings", "duration"]);
//   let finalRender = null;
//   const [currentDuration, setCurrentDuration] = useState(duration)
//   const isTimed = sourceValues.settings.is_timed
//   useEffect(() => {
//     if(!isTimed) {
//       setFieldsValue({settings: { duration: null  }})
//     }  
//   }, [isTimed])
  

//   useEffect(() => {
//     setCurrentDuration(duration)
//   }, [duration])

//   if (duration != currentDuration) {
//     return null
//   }

//   finalRender = (
//     <FormRenderer
//       key="timed-assessment"
//       formObj={{
//         type: "object",
//         properties: {
//           is_timed: {
//             type: "string",
//             // title: "Timed",
//             placeholder: mrIntl("Experiences.timed_assessment"),
//             // placeholder: "Timed assessment",
//             widget: IsTimeCustomComponent,
//             valuePropName: "checked",
//             widgetConfig: {
//               // size: 'small',
//               // children: 'Timed',
//               formInstance: formInstance,
//               disabled: sourceValues.status !== "draft" ? true : false,
//             },
//             // span: 8,
//             setInitialValue: true,
//             // rules: [
//             //   {
//             //     validator(rule, value) {
//             //       let validationPassed = true
//             //       if(value && !duration){
//             //         validationPassed = false
//             //       }
//             //       else{
//             //         validationPassed = true
//             //       }
//             //       console.log('IsTimedChildComponent validator ===>', validationPassed)
//             //       if(validationPassed){
//             //         return Promise.resolve()
//             //       }
//             //       else {
//             //         return Promise.reject()
//             //       }
//             //     }
//             //   },
//             // ],          
//           },
//         },
//       }}
//       propertyKey={"settings"}
//       sourceValues={sourceValues}
//       formInstance={formInstance}
//     />
//   );

//   return finalRender;
// };

// const ReadingTimeChildComponent =  (props) => {
//   console.log("ReadingTimeChildComponent props", props)
//   const { getFieldValue, sourceValues } = props
//   const isTimed = getFieldValue(["settings","allow_reading_time"]);
//   let finalRender = null;

//   if (isTimed === true || (!isTimed && sourceValues.id && sourceValues.is_timed === true)) {
//     finalRender = <FormRenderer
//       formObj={{
//         type: "object",
//         properties: {
//           reading_time: {
//             type: "string",
//             placeholder: "Reading time (mins) *",
//             widget: "SelectWidget",
//             widgetConfig: {
//               options: buildOptionsArr(["5", "10", "15", "20", "25", "30"]),
//               disabled: sourceValues.status !== "draft" ? true : false
//             },
//             // span: 8,
//             setInitialValue: true,
//             rules: [
//               {
//                 required: true,
//                 message: 'Required',
//               },
//             ]
//           },
//         }
//       }}
//       propertyKey={"settings"}
//       sourceValues={sourceValues}
//     />
//   }
//   return finalRender;
// }

const VideoCallLinkCustomComponent = (props) => {
  const { formInstance: { getFieldValue, setFieldsValue }, value } = props
  const mrIntl = useTranslate()
  const deliveryMode = getFieldValue(["settings", "mode"]);
  const [validation, setValidation] = useState({});
  const [blurTriggered, setBlurTriggered] = useState(false)
  // const [currentValue, setCurrentValue] = useState(value)
  let finalRender = null
  const includeVideoCallLink = getFieldValue([
    "settings",
    "include_video_call_link",
  ]);

  const errorMessages = {
    // required: true,
    requiredMessage: mrIntl("CommonText.required_msg"),
    validZoomLinkMsg:
      `${mrIntl("Experiences.please_add_a_valid_zoom_link_btn")} : https://us05web.zoom.us/j/123456789?pwd=******`, //TODO add urls in msg
    validHttpMsg: mrIntl("Experiences.please_add_valid_https_url_msg"),
    allowOnlyZoomUrlMsg: mrIntl("Experiences.only_valid_zoom_or_meet_url_msg")
  };

  const handleOnChange = (value) => {
    console.log("handleOnChange ====>", value, isValidHttpsUrl(value), getZoomMeetingId(value));
    // setCurrentValue(value)
    if (!isValidHttpsUrl(value)) {
      setValidation({
        status: "error",
        message: errorMessages.validHttpMsg
      })
    } else if (deliveryMode === "offline" && !isValidZoomOrMeetUrl(value)) {
      setValidation({
        status: "error",
        message: errorMessages.allowOnlyZoomUrlMsg
      })
      //Show `allow only zoom url` msg else merge with below condtion
    } else if (deliveryMode === "offline" && isValidZoomUrl(value) && !getZoomMeetingId(value)) {
      setValidation({
        status: "error",
        message: errorMessages.validZoomLinkMsg
      })
    } else {
      setValidation({
        status: "",
        message: ""
      })
      setFieldsValue({ settings: { include_video_call_link: true } })
      props.onChange(value)
    }
  }

  const onBlur = () => {
    setBlurTriggered(true)
    if(!value) {
      setValidation({
        message: errorMessages.requiredMessage,
        status: "error"
      })
    }
  }

  if (includeVideoCallLink) {
    const showError = blurTriggered || value
    finalRender = (
      <Form.Item
        help={showError && validation.message}
        validateStatus={showError && validation.status}
      >
        <Input 
          placeholder={mrIntl("Experiences.video_call_link")} 
          onChange={(e) => handleOnChange(e.target.value)} 
          // value={currentValue} 
          onBlur={() => onBlur()} 
          defaultValue={value}
          autoFocus 
        />
      </Form.Item>
    )
  }

  return finalRender
}

const PeerReviewCustomComponent = (props) => {
  console.log("peerReviewCustomComponent ====>", props);

  const {
    formInstance: { setFieldsValue, getFieldValue },
    checked,
    tooltipProps
  } = props;
  const handleOnChange = (value) => {
    console.log("swicther onoff ====>", value);
    let reviewerSetting
    if (!value) {
      reviewerSetting = {}
    } else {
      reviewerSetting = {
        assign_reviewer_type: 'automatic',
        assign_reviewers_per_student: 1,
        assign_reviewers_at_datetime: null,
        close_review_at_datetime: null
      } 
    }
    setFieldsValue({
      settings: {
        reviewer_setting: reviewerSetting
      }
    });
    props.onChange(value);
  };
  return (
    <CustomSwitchWidget tooltipProps={tooltipProps} placeholder={props.placeholder} checked={checked} onChange={handleOnChange} disabled={props.disabled}/>
  );
};

const AssignReviewerType = (props) => {
  const { value = "automatic", formInstance: { setFieldsValue, getFieldValue }, checked } = props;
  const mrIntl = useTranslate();

  const handleOnChange = (assignReviewerType) => {
    const reviewerSettings = getFieldValue(["settings", "reviewer_setting"])

    let reviewerSettingToUpdate = {
      ...reviewerSettings,
      assign_reviewers_at_datetime: null,
      close_review_at_datetime: null
    }
    if (assignReviewerType !== "automatic") {
      reviewerSettingToUpdate.assign_reviewers_per_student = null
    }
    setFieldsValue({
      settings: {
        reviewer_setting: reviewerSettingToUpdate
      }
    });
    props.onChange(assignReviewerType);
  };
  
  const options = [
    {
      label: mrIntl("Experiences.manual"),
      value: 'manual',
    },
    {
      label: mrIntl("Experiences.automatic"),
      value: 'automatic',
    },
  ]

  return (
    <Radio.Group 
      buttonStyle="solid" 
      optionType="button" 
      options={options} 
      value={value}
      defaultValue={value}
      onChange={(e) => handleOnChange(e.target.value)} 
      disabled={props.disabled}
    />
  )

  // return (
  //   <Space direction="vertical">
  //     <label>Peer review assessment type: </label>
  //     <Radio.Group
        
  //       onChange={(e) => handleOnChange(e.target.value)}
  //     >
  //       <Row>
  //         <Radio value="manual">
  //           {mrIntl("Experiences.manual")}
  //         </Radio>
  //         <Radio value="automatic">
  //           {mrIntl("Experiences.automatic")}
  //         </Radio>
  //       </Row>
  //     </Radio.Group>
  //   </Space>
  // );
};

const ReviewerPerStudent = (props) => {
  const { value, formInstance: { setFieldsValue, getFieldValue }, checked } = props;
  console.log("ReviewerPerStudent ====>", props);
  
  const options = [];
  const minAllowedPeers = 1;
  const maxAllowedPeers = 5;
  for (let i = minAllowedPeers; i <= maxAllowedPeers; i++) {
    options.push({
      label: i.toString(),
      value: i,
    });
  }
  
  const handleOnChange = (value) => {
    props.onChange(value);
  };

  return (
    <Select
      value={value || "1"}
      onChange={(value) => handleOnChange(value)}
      options={options}
      disabled={props.disabled}
      style={{ maxWidth: "200px" }}
    />
  );
};

const AssignReviewerAtDatetimeCustomComponent = (props) => {
  console.log("AssignReviewerAtDatetimeCustomComponent ====>", props);
  const { value, formInstance: { getFieldValue, setFieldsValue } } = props
  const mrIntl = useTranslate()
  const assignReviewersDateTimeRef = useRef()
  const start_at_datetime = getFieldValue(["settings", "start_at_datetime"]) || dayjs() // If we do not select the scheduled time, we will use the current date.
  const close_at_datetime = getFieldValue(["settings", "close_at_datetime"]) || null
  const assignReviewerType = getFieldValue(["settings", "reviewer_setting","assign_reviewer_type"])
  const peerReviewEnabled = getFieldValue(["settings", "peer_review_enabled"])

  const reviewerAssignedDate = getFieldValue(["settings", "reviewer_setting", "assign_reviewers_at_datetime"])
  const isReviewerAssignDateGreater = reviewerAssignedDate && reviewerAssignedDate < dayjs()
  const [blurTriggered, setBlurTriggered] = useState(false)
  console.log("assignReviewerType AssignReviewerAtDatetimeCustomComponent", assignReviewerType);

  useEffect(() => {
    if (assignReviewerType === "manual" || !value) return;
    // we are checking start/close date is aviailable or not, if not then we are using current date 
    const isStartDateTimeIsGreater = start_at_datetime && !close_at_datetime && start_at_datetime > value;
    const isCloseDateTimeIsGreater = close_at_datetime && close_at_datetime > value;
  
    if ((isStartDateTimeIsGreater || isCloseDateTimeIsGreater) && !isReviewerAssignDateGreater ) {
      setFieldsValue({
        settings: {
          reviewer_setting: {
            assign_reviewers_at_datetime: null,
            close_review_at_datetime: null
          }
        }
      });
    }
  }, [start_at_datetime, close_at_datetime]);

  useEffect(() => {
    if (!props.disabled && assignReviewersDateTimeRef.current) {
      assignReviewersDateTimeRef.current.focus()
    }
  }, [props.disabled, peerReviewEnabled, assignReviewerType])

  const handleOnChange = (value) => {
    console.log("handleOnChange ==>", value)
    let assign_reviewers_at = value
    if (value < start_at_datetime) {
      assign_reviewers_at = dayjs(start_at_datetime).add(5, "m")
    } else if (close_at_datetime && value < close_at_datetime) {
      assign_reviewers_at = dayjs(close_at_datetime).add(5, "m");
    }
    if (value < dayjs()) {
      assign_reviewers_at = dayjs().add(5, "m")
    }
    props.onChange(assign_reviewers_at)
  }

  const onBlur = () => {
    setBlurTriggered(true)
  }

  const showError = !value && blurTriggered
  const finalRender = (
    <Form.Item
      help={showError && mrIntl("CommonText.required_msg")}
      validateStatus={showError && "error"}
    >
      <DatePicker
        format={"YYYY-MM-DD h:mm a"}
        ref={assignReviewersDateTimeRef}
        showTime={{
          use12Hours: true,
          format: "h:mm a",
          minuteStep: 15,
        }}
        showNow={false}
        needConfirm={false}
        value={value}
        disabled={props.disabled}
        disabledDate={(currentDate) => {
          const startDate = dayjs(start_at_datetime)
          const closeDate = dayjs(close_at_datetime);
          let dd = dayjs(startDate).isAfter(currentDate, 'day')
            || dayjs(startDate).add(3, 'month').startOf('day').isBefore(currentDate, 'day')
            || (close_at_datetime && dayjs(closeDate).isAfter(currentDate, 'day'));
          console.log("startDate, dd ====> disabledDate", startDate, closeDate, dd)
          return dd;
        }}
        disabledTime={(currentDate) => {
          console.log("current date ===>", currentDate)
          const shouldAssignAfterDate = close_at_datetime || start_at_datetime || dayjs();
          let dt = {}
          const cd = dayjs(currentDate)
          if (cd.date() === shouldAssignAfterDate.date() && cd.month() === shouldAssignAfterDate.month()) {
            dt = {
              disabledHours: () => range(0, 24).filter((h) => shouldAssignAfterDate.minute() < 45 ? h < shouldAssignAfterDate.hour() : h <= shouldAssignAfterDate.hour())
            };
            if (cd.hour() <= shouldAssignAfterDate.hour()) {
              dt = {
                ...dt,
                disabledMinutes: () => [0, 15, 30, 45].filter((m) => m <= shouldAssignAfterDate.minute())
              };
            }
          }
          return dt
        }}
        onChange={(value) => handleOnChange(value)}
        onBlur={onBlur}
        autoFocus
      />
    </Form.Item>
  )

  return finalRender
}

const ReviewCloseAtDatetimeCustomComponent = (props) => {
  console.log("ReviewCloseAtDatetimeCustomComponent ====>", props);
  const { value, formInstance: { getFieldValue, setFieldsValue } } = props
  const assign_reviewers_at_datetime = getFieldValue(["settings", "reviewer_setting", "assign_reviewers_at_datetime"]) || null
  const assignReviewerType = getFieldValue(["settings", "reviewer_setting", "assign_reviewer_type"])

  useEffect(() => {
    if (assignReviewerType !== "manual" && (assign_reviewers_at_datetime && assign_reviewers_at_datetime > value)) {
      setFieldsValue({
        settings: {
          reviewer_setting: {
            close_review_at_datetime: null
          }
        }
      });
    }
  }, [assign_reviewers_at_datetime]);

  const handleOnChange = (value) => {
    console.log("handleOnChange ==>", value)
    let assign_reviewers_at = value
    
    if (assign_reviewers_at_datetime && value < assign_reviewers_at_datetime) {
      assign_reviewers_at = dayjs(assign_reviewers_at_datetime).add(5, "m");
    }
    props.onChange(assign_reviewers_at)
  }
  
  const finalRender = (
    <Form.Item>
      <DatePicker
        format={"YYYY-MM-DD h:mm a"}
        showTime={{
          use12Hours: true,
          format: "h:mm a",
          minuteStep: 15,
        }}
        showNow={false}
        needConfirm={false}
        value={value}
        disabled={props.disabled}
        disabledDate={(currentDate) => {
          const closeDate = dayjs(assign_reviewers_at_datetime);
          let dd = (assign_reviewers_at_datetime && dayjs(closeDate).isAfter(currentDate, 'day'));
          return dd;
        }}
        disabledTime={(currentDate) => {
          console.log("current date ===>", currentDate)
          const shouldAssignAfterDate = assign_reviewers_at_datetime;
          let dt = {}
          const cd = dayjs(currentDate)
          // - We don't need to match the year because we're only allowing 3 months to select dates
          if (shouldAssignAfterDate && cd.date() === shouldAssignAfterDate.date() && cd.month() === shouldAssignAfterDate.month()) {
            dt = {
              disabledHours: () => range(0, 24).filter((h) => shouldAssignAfterDate.minute() < 45 ? h < shouldAssignAfterDate.hour() : h <= shouldAssignAfterDate.hour())
            };
            if (cd.hour() <= shouldAssignAfterDate.hour()) {
              dt = {
                ...dt,
                disabledMinutes: () => [0, 15, 30, 45].filter((m) => m <= shouldAssignAfterDate.minute()),
              }
            }
          }
          return dt
        }}
        onChange={(value) => handleOnChange(value)}
      />
    </Form.Item>
  )

  return finalRender
}

// const VideoCallLinkChildComponent = (props) => {
//   console.log("VideoCallLinkChildComponent props", props);
//   const { getFieldValue, sourceValues, formInstance } = props;
//   const mrIntl = useTranslate();
//   const includeVideoCallLink = getFieldValue([
//     "settings",
//     "include_video_call_link",
//   ]);
//   let finalRender = null;

//   const deliveryMode = getFieldValue(["settings", "mode"]);

//   if (
//     includeVideoCallLink === true ||
//     (!includeVideoCallLink &&
//       sourceValues.id &&
//       sourceValues.include_video_call_link === true)
//   ) {
//     finalRender = (
//       <FormRenderer
//         formObj={{
//           type: "object",
//           properties: {
//             video_call_link: {
//               type: "string",
//               placeholder: mrIntl("Experiences.video_call_link"),
//               // placeholder: "Video call link",
//               setInitialValue: true,
//               widget: VideoCallLinkCustomComponent,
//               widgetConfig: {
//                 autoFocus: true
//               },
//               // rules: [
//               //   {
//               //     required: true,
//               //     message: mrIntl("CommonText.required_msg"),
//               //   },
//               // ],
//               // trigger: "onSubmit",
//               // formItemConfig: { validateTrigger: "onSubmit" },
//               // rules: [
//               //   {
//               //     validator(rule, value) {
//               //       const validationRules = {
//               //         // required: true,
//               //         required: true,
//               //         // message:
//               //         //   "Please add a valid zoom link - it should look like this: https://us05web.zoom.us/j/123456789?pwd=******", //TODO add urls in msg
//               //         message:
//               //           `${mrIntl("Experiences.please_add_a_valid_zoom_link_btn")} : https://us05web.zoom.us/j/123456789?pwd=******`,
//               //         validHttpMsg: mrIntl("Experiences.please_add_valid_https_url_msg"),
//               //         allowOnlyUrlMsg : Only valid zoom url
//               //       };
//               //       if (!isValidHttpsUrl(value)) {
//               //         return Promise.reject(validationRules.validHttpMsg);
//               //       }

//               //       if(deliveryMode == "offline" && !isValidZoomUrl(value)){
//               //         return Promise.reject(validationRules.allowOnlyUrlMsg);
//               //       } //Show `allow only zoom url` msg else merge with below condtion

//               //       if (
//               //         deliveryMode == "offline" &&
//               //         isValidZoomUrl(value) &&
//               //         !getZoomMeetingId(value)
//               //       ) {
//               //          return Promise.reject(validationRules.message);
//               //        } else {
//               //         return Promise.resolve();
//               //       }
//               //     },
//               //   },
//               // ],
//               // validateTrigger: ["onBlur", "onChange"]
//             },
//           },
//         }}
//         propertyKey={"settings"}
//         sourceValues={sourceValues}
//         formInstance={formInstance}
//       />
//     );
//   }
//   return finalRender;
// };

const ExtraInstructionsCustomComponent = (props) => {
  console.log("IsTimeCustomComponent ====>", props);
  const { formInstance: { setFieldsValue, getFieldValue }, sourceValues, value, disabled } = props

  const mrIntl = useTranslate()
  const includeExtraInstructions = getFieldValue([
    "settings",
    "include_extra_instructions",
  ]);
  let finalRender = null
  const [blurTriggered, setBlurTriggered] = useState(false)

  const handleOnChange = (value) => {
    console.log("handleOnChange ====>", value);
    setFieldsValue({ settings: { include_extra_instructions: true } })
    props.onChange(value)
  }

  const ckeConfig = {
    ...CKETextContentTools,
    debounceInterval: 1000,
    isReadOnly: disabled ? true : false,
    autoFocus: true,
  }

  const onBlur = () => {
    setBlurTriggered(true)
  }

  if(includeExtraInstructions) {
    const showError = !value && blurTriggered
    finalRender = (
        <Form.Item
          help={showError && mrIntl("CommonText.required_msg")}
          validateStatus={showError && "error"}
          className="select-timer-dropdown"
        >
          <CKETextContent 
            value={value} 
            ckeConfig={ckeConfig} 
            onChange={(value) => handleOnChange(value)} 
            placeholder={mrIntl("Experiences.instructions")} 
            onBlur={onBlur}
          />
        </Form.Item>
    )
  }
  
  return finalRender
}

// const ExtraInstructionsChildComponent = (props) => {
//   console.log("ExtraInstructionsChildComponent props", props);
//   const { getFieldValue, sourceValues, formInstance } = props;
//   const includeExtraInstructions = getFieldValue([
//     "settings",
//     "include_extra_instructions",
//   ]);
//   const mrIntl = useTranslate();
//   let finalRender = null;

//   if (
//     includeExtraInstructions === true ||
//     (!includeExtraInstructions &&
//       sourceValues.id &&
//       sourceValues.include_extra_instructions === true)
//   ) {
//     finalRender = (
//       <FormRenderer
//         formObj={{
//           type: "object",
//           properties: {
//             extra_instructions_text: {
//               type: "string",
//               placeholder: mrIntl("Experiences.instructions"),
//               // placeholder: "Instructions",
//               setInitialValue: true,
//               widget: ExtraInstructionsCustomComponent,
//               // widgetConfig: {
//               //   ckeConfig: {
                  
//               //   },
//               // },
              
              
//               // rules: [
//               //   {
//               //     required: true,
//               //     message: mrIntl("CommonText.required_msg"),
//               //   },
//               // ],
//               // validateTrigger: ["onBlur", "onChange"]
//             },
//           },
//         }}
//         propertyKey={"settings"}
//         sourceValues={sourceValues}
//         formInstance={formInstance}
//       />
//     );
//   }
//   return finalRender;
// };

const IncludeExtraInstructionsCustomComponent = (props) => {
  console.log("IncludeExtraInstructionsChildComponent ====>", props);

  const { formInstance: { setFieldsValue }, checked } = props
  const handleOnChange = (value) => {
    if(value) {
      setFieldsValue({ settings: { include_extra_instructions: value } })
    } else {
      setFieldsValue({ settings: { extra_instructions_text: null } })
      props.onChange(value)
    }
  }

  return (
    <CustomSwitchWidget placeholder={props.placeholder} checked={checked} onChange={handleOnChange} disabled={props.disabled} />
  )
}

// const IncludeExtraInstructionsChildComponent = (props) => {
//   console.log("IncludeExtraInstructionsChildComponent props", props);
//   const { getFieldValue, sourceValues, formInstance } = props;
//   const extraInstructionsText = getFieldValue([
//     "settings",
//     "extra_instructions_text",
//   ]);
//   let finalRender = null
//   const mrIntl = useTranslate();
//   const [currentInstructions, setCurrentInstructions] = useState(extraInstructionsText)
//   console.log("extraInstructionsText ====>", extraInstructionsText);
//   useEffect(() => {
//     setCurrentInstructions(extraInstructionsText)
//   }, [extraInstructionsText])

//   if (extraInstructionsText !== currentInstructions) {
//     return null
//   }

//   finalRender = (
//     <FormRenderer
//       formObj={{
//         type: "object",
//         properties: {
//           include_extra_instructions: {
//             type: "string",
//             placeholder: mrIntl("Experiences.extra_instructions"),
//             widget: IncludeExtraInstructionsCustomComponent,
//             valuePropName: "checked",
//             widgetConfig: {
//               // options: buildOptionsArr(['Extra Instructions']),
//               disabled: sourceValues.status !== "draft" ? true : false,
//             },
//             setInitialValue: true,
//             // rules: [
//             //   {
//             //     validator(rule, value) {
//             //       let validationPassed = true
                  
//             //       if(value && (!extraInstructionsText || extraInstructionsText === "")){
//             //         validationPassed = false
//             //       }
//             //       else{
//             //         validationPassed = true
//             //       }
//             //       console.log("IncludeExtraInstructionsChildComponent ===>", extraInstructionsText, value, validationPassed);
//             //       if(validationPassed){
//             //         return Promise.resolve()
//             //       }
//             //       else {
//             //         return Promise.reject()
//             //       }
//             //     }
//             //   },
//             // ],
            
//           }
//         },
//       }}
//       propertyKey={"settings"}
//       sourceValues={sourceValues}
//       formInstance={formInstance}
//     />
//   );

//   return finalRender;
// }

const EnabelScreenshotChildComponent = (props) => {
  console.log("EnabelScreenshotChildComponent props", props);
  const { getFieldValue, sourceValues } = props;
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const appRegion = useSelector(appRegionSelector());
  const mrIntl = useTranslate()
  const deliveryMode = getFieldValue([
    "settings",
    "mode",
  ]);
  let finalRender = null;

  if (appRegion != "china" && enabledFeatures.auto_screenshots && (deliveryMode === "online_secure" || deliveryMode === "offline")) {
    finalRender = (
      <FormRenderer
        formObj={{
          type: "object",
          properties: {
            enable_auto_screenshots: {
              type: "string",
              span: 12,
              placeholder: (
                <>
                  {mrIntl("Experiences.auto_screenshots")} &nbsp;
                  <Tooltip
                    title={mrIntl("Experiences.enable_taking_screenshots_msg")}
                    placement="top"
                  >
                    {/* <sup> */}
                    <InfoCircleTwoTone />
                    {/* </sup> */}
                  </Tooltip>
                </>
              ),
              widget: CustomSwitchWidget,
              setInitialValue: true,
              valuePropName: "checked",
              widgetConfig: {
                disabled: sourceValues.status !== "draft" ? true : false,
              },
            },
          },
        }}
        propertyKey={"settings"}
        sourceValues={sourceValues}
      />
    );
  }
  return finalRender;
};

// const SelectGroupsWidget = GenericWidgetCreator((props) => {
//   console.log("SelectGroupsWidget props", props);
//   let by_org_programme_id = null;
//   let by_subject_id = null;
//   let by_grade_id = null;
//   if (
//     props.sourceValues.relation_items[0] &&
//     props.sourceValues.relation_items[0].org_programme_items &&
//     props.sourceValues.relation_items[0].org_programme_items[0]
//   ) {
//     by_org_programme_id =
//       props.sourceValues.relation_items[0].org_programme_items[0]
//         .org_programme_id;
//   }
//   if (
//     props.sourceValues.relation_items[0] &&
//     props.sourceValues.relation_items[0].subject_items &&
//     props.sourceValues.relation_items[0].subject_items[0]
//   ) {
//     by_subject_id =
//       props.sourceValues.relation_items[0].subject_items[0].subject_id;
//   }
//   if (
//     props.sourceValues.relation_items[0] &&
//     props.sourceValues.relation_items[0].grade_items &&
//     props.sourceValues.relation_items[0].grade_items[0]
//   ) {
//     by_grade_id = props.sourceValues.relation_items[0].grade_items[0].grade_id;
//   }
//   return {
//     config: {
//       forceReload: false,
//       params: {
//         by_org_programme_id: by_org_programme_id,
//         by_subject_id: by_subject_id,
//         by_grade_id: by_grade_id,
//         by_user_id: false,
//       },
//     },
//   };
//   // by not user_id so that teacher can see all classes of this subject and grade
// }, SelectGroups);

// const EMGIdsChildComponent = (props) => {
//   console.log("EMGIdsChildComponent props", props);
//   const { getFieldValue, sourceValues, formInstance } = props;
//   const mrIntl = useTranslate()
//   const mapClassesOrStudents = getFieldValue([
//     "settings",
//     "map_classes_or_students",
//   ]);
//   let finalRender = null;

//   if (
//     mapClassesOrStudents === "groups" ||
//     (!mapClassesOrStudents &&
//       sourceValues.id &&
//       sourceValues.map_classes_or_students === "groups")
//   ) {
//     finalRender = (
//       <FormRenderer
//         formObj={{
//           type: "object",
//           properties: {
//             experience_membership_group_ids: {
//               type: "string",
//               title: mrIntl("CommonText.select_classes"),
//               setInitialValue: true,
//               span: 24,
//               // widget: SelectGroups,
//               widget: SelectGroupsWidget,
//               widgetConfig: {
//                 sourceValues: sourceValues,
//                 disabled: sourceValues.status !== "draft" ? true : false,
//               },
//               // rules: [
//               //   {
//               //     required: true,
//               //     message: 'Required',
//               //   },
//               // ]
//             },
//           },
//         }}
//         sourceValues={sourceValues}
//         formInstance={formInstance}
//       />
//     );
//   }
//   return finalRender;
// };

// const SelectStudentsWidget = GenericWidgetCreator((props) => {
//   console.log("SelectStudentsWidget props", props);
//   let by_grade_id = null;
//   if (
//     props.sourceValues.relation_items[0] &&
//     props.sourceValues.relation_items[0].grade_items &&
//     props.sourceValues.relation_items[0].grade_items[0]
//   ) {
//     by_grade_id = props.sourceValues.relation_items[0].grade_items[0].grade_id;
//   }
//   return {
//     config: { forceReload: false, params: { by_grade_id: by_grade_id } },
//   };
// }, SelectStudents);

// const EMSIdsChildComponent = (props) => {
//   console.log("EMSIdsChildComponent props", props);
//   const { getFieldValue, sourceValues, formInstance } = props;
//   const mapClassesOrStudents = getFieldValue([
//     "settings",
//     "map_classes_or_students",
//   ]);
//   let finalRender = null;
//   const mrIntl = useTranslate()

//   if (
//     mapClassesOrStudents === "students" ||
//     (!mapClassesOrStudents &&
//       sourceValues.id &&
//       sourceValues.map_classes_or_students === "students")
//   ) {
//     finalRender = (
//       <FormRenderer
//         formObj={{
//           type: "object",
//           properties: {
//             experience_membership_student_ids: {
//               type: "string",
//               title: mrIntl("CommonText.select_students"),
//               setInitialValue: true,
//               span: 24,
//               // widget: SelectStudents,
//               widget: SelectStudentsWidget,
//               widgetConfig: {
//                 sourceValues: sourceValues,
//                 disabled: sourceValues.status !== "draft" ? true : false,
//               },
//               // rules: [
//               //   {
//               //     required: true,
//               //     message: 'Required',
//               //   },
//               // ]
//             },
//           },
//         }}
//         sourceValues={sourceValues}
//         formInstance={formInstance}
//       />
//     );
//   }
//   return finalRender;
// };

const StartConditionCustomComponent = (props) => {
  console.log("StartConditionCustomComponent =====>", props);
  const { value, formInstance: { getFieldValue, setFieldsValue } } = props
  const mrIntl = useTranslate()
  const options = [
    {
      label: mrIntl("Experiences.anytime"),
      // label: "Any time",
      value: "anytime",
      // no-condition
    },
    {
      label: mrIntl("Experiences.scheduled_time"),
      // label: "Scheduled time",
      value: "scheduled_time",
      // TODO: make start date required if this selected
    },
  ]
  const start_at_datetime = getFieldValue(["settings", "start_at_datetime"])

  const handleOnChange = (value) => {
    if(value == "scheduled_time") {
      setFieldsValue({settings: {start_condition: "scheduled_time"}})
    } else {
      setFieldsValue({settings: {start_condition: "anytime", start_at_datetime: null}})
      if (start_at_datetime) {
        props.onChange(value)
      }
    }
  }

  return (
    // <Form.Item
    //   help={value === "scheduled_time" && "Please add start date and time"}
    //   className="select-timer-dropdown"
    // >
      <Radio.Group 
        buttonStyle="solid" 
        optionType="button" 
        options={options} 
        defaultValue={value} 
        onChange={(value) => handleOnChange(value.target.value)} 
        disabled={props.disabled}
      />
    // </Form.Item>
  )
}

// export const StartConditionChildComponent = (props) => {
//   console.log("StartConditionChildComponent", props);
//   const {
//     getFieldValue,
//     sourceValues,
//     setFieldsValue,
//     formInstance
//   } = props;
//   const mrIntl = useTranslate();
//   const startCondition = getFieldValue(["settings", "start_condition"]);
//   const startDateAndTime = getFieldValue(["settings", "start_at_datetime"]);
//   const timeInMilliSeconds = startDateAndTime ? startDateAndTime.valueOf() : 0
//   const [currentTimeInMilliSeconds, setCurrentTimeInMilliSeconds] = useState(timeInMilliSeconds) 

//   useEffect(() => {
//     if (startCondition === "anytime") {
//       setFieldsValue({settings: {start_at_datetime: null}})
//     }
//   }, [startCondition])
  
//   useEffect(() => {
//     setCurrentTimeInMilliSeconds(timeInMilliSeconds)
//   }, [timeInMilliSeconds])

//   if (timeInMilliSeconds != currentTimeInMilliSeconds) {
//     return null
//   }

//   let finalRender = (
//     <FormRenderer
//       formObj={{
//         type: "object",
//         properties: {
//           start_condition: {
//             type: "string",
//             title: mrIntl("Experiences.start_condition"),
//             // title: "Start condition [NEW]",
//             widget: StartConditionCustomComponent,
//             // default: "anytime",
//             span: 24,
//             // rules: [
//             //   {
//             //     required: true,
//             //     validator(rule, value) {
//             //       let validationPassed = true
//             //       console.log("startDateAndTime ====>", startDateAndTime, validationPassed)
//             //       if(startCondition === "scheduled_time" && !startDateAndTime){
//             //         validationPassed = false
//             //       }
//             //       else{
//             //         validationPassed = true
//             //       }
//             //       if(validationPassed){
//             //         return Promise.resolve()
//             //       }
//             //       else {
//             //         return Promise.reject()
//             //       }
//             //     }
//             //   },
//             // ],
//             setInitialValue: true,
//             widgetConfig: {
//               options: [
//                 {
//                   label: mrIntl("Experiences.anytime"),
//                   // label: "Any time",
//                   value: "anytime",
//                   // no-condition
//                 },
//                 {
//                   label: mrIntl("Experiences.scheduled_time"),
//                   // label: "Scheduled time",
//                   value: "scheduled_time",
//                   // TODO: make start date required if this selected
//                 },
//               ],
//               disabled: sourceValues.status !== "draft" ? true : false,
//               // shouldUpdate: (prevValues, curValues) => {
//               //   console.log("prevValue, curValue", prevValues, curValues);
//               //   return true
//               // },
//               optionType: "button",
//               buttonStyle: "solid",
//             }
//           },
//         },
//       }}
//       propertyKey={"settings"}
//       sourceValues={sourceValues}
//       formInstance={formInstance}
//       options={[
//         {
//           label: mrIntl("Experiences.anytime"),
//           // label: "Any time",
//           value: "anytime",
//           // no-condition
//         },
//         {
//           label: mrIntl("Experiences.scheduled_time"),
//           // label: "Scheduled time",
//           value: "scheduled_time",
//           // TODO: make start date required if this selected
//         },
//       ]}
//     />
//   );

//   return finalRender;
// };

const StartDatetimeCustomComponent = (props) => {
  console.log("StartDatetimeCustomComponent ====>", props);
  const { value, formInstance: { setFieldsValue, getFieldValue } } = props
  const env = import.meta.env.VITE_MODE;
  let finalRender = null
  const mrIntl = useTranslate()
  const startCondition = getFieldValue(["settings", "start_condition"])
  const [blurTriggered, setBlurTriggered] = useState(false)

  const startDateTimePickerRef = useRef()
  const handleOnChange = (value) => {
    console.log("dateTimeChange ====>", typeof value, value);
    setFieldsValue({settings: { start_condition: "scheduled_time"}})
    let start_at = value
    if (value < dayjs()) {
      start_at = dayjs()
    }
    props.onChange(start_at)
  }

  const onBlur = () => {
    setBlurTriggered(true)
  }

  useEffect(() => {
    if (!props.disabled && startDateTimePickerRef.current) {
      startDateTimePickerRef.current.focus()
    }
  }, [props.disabled])

  // if (startCondition === "scheduled_time") {
  // }
  
  const showError = !value && blurTriggered && startCondition == "scheduled_time"
  finalRender = (
    <Form.Item
      help={showError && mrIntl("CommonText.required_msg")}
      validateStatus={showError && "error"}
    >
      {/* <label>{mrIntl("CommonText.start_date_and_time")}</label><br/> */}
      <DatePicker
        ref={startDateTimePickerRef}
        format={"YYYY-MM-DD h:mm a"}
        showTime={{
          use12Hours: true,
          format: "h:mm a",
          minuteStep: 15,
        }}
        needConfirm={false}
        value={value}
        disabled={props.disabled}
        disabledDate={(current) => {
          if (env !== "demo" && env !== "demo-internal") {
            let dd = dayjs().startOf('day').isAfter(current, 'day') || dayjs().add(3, 'month').startOf('day').isBefore(current, 'day')
            return dd;
          }
        }}
        disabledTime={(currentDate) => {
          const cd = dayjs(currentDate)
          const todaysDate = dayjs()
          let dt = {}
          // Check if the current date matches today's date
          // - We need to disable times for today if today's date is selected
          // - If we only match the day without the month, it will disable times for that day in every month
          // - We don't need to match the year because we're only allowing 3 months to select dates
          if (cd.date() === todaysDate.date() && cd.month() === todaysDate.month()){
            dt = {
              disabledHours: () => range(0, 24).filter((h) => todaysDate.minute() < 45 ? h < todaysDate.hour() : h <= todaysDate.hour()),
            }
            if (cd.hour() <= todaysDate.hour()) {
              dt = {
                ...dt,
                disabledMinutes: () => [0, 15, 30, 45].filter((m) => m <= todaysDate.minute()),
              }
            }
          }
          return dt 
        }}
        onChange={(value) => handleOnChange(value)}
        onBlur={onBlur}
        autoFocus
      />
    </Form.Item>
  )

  return finalRender
}


// const IsAutoCloseCustomComponent = (props) => {
//   console.log("IsTimeCustomComponent ====>", props);
//   const { formInstance: { getFieldValue, setFieldsValue }, checked } = props

//   const close_at_datetime = getFieldValue(["settings", "close_at_datetime"])
//   const activeExperience = useSelector(activeAdjustedExperienceSelector())
//   const mrIntl = useTranslate()

//   const handleOnChange = (value) => {
//     console.log("Is auto close on change value ==>", value)
//     if(value) {
//       setFieldsValue({ settings: { is_auto_close: value } })
//     } else {
//       setFieldsValue({ settings: { is_auto_close: value, close_at_datetime: null } })
//       if (close_at_datetime) {
//         props.onChange(value)
//       }
//     }
//   }

//   return (
//     <Space>
//       {/* <Form.Item
//         help={value && "Please select duration"}
//         className="select-timer-dropdown"
//       >  */}
//       <Switch onChange={(value) => handleOnChange(value)} checked={checked} disabled={props.disabled}/> <label>
//             {props.placeholder}  &nbsp; 
//             <Tooltip
//               title={mrIntl("Experiences.auto_close_message")}
//               placement="top"
//             >
//               <InfoCircleTwoTone />&nbsp;
//               {/* {activeExperience && activeExperience.custom_fields.auto_close_job_id && <ClockCircleOutlined />} */}
//             </Tooltip>
//           </label>
//       {/* </Form.Item> */}
//     </Space>
//   )
// }

const IsAutoCloseCustomComponent = (props) => {
  console.log("IsAutoCloseCustomComponent =====>", props);
  const { value = false, formInstance: { getFieldValue, setFieldsValue }, isMBLinked = false } = props
  const defaultValue = isMBLinked ? true : false
  const mrIntl = useTranslate()
  const options = [
    {
      label: mrIntl("Experiences.manual"),
      value: false,
      disabled: isMBLinked ? true : false
    },
    {
      label: mrIntl("Experiences.scheduled"),
      value: true,
    },
  ]
  const close_at_datetime = getFieldValue(["settings", "close_at_datetime"])

  const handleOnChange = (value) => {
    console.log("Is auto close on change value ==>", value)
    if (value) {
      setFieldsValue({ settings: { is_auto_close: value } })
    } else {
      setFieldsValue({ settings: { is_auto_close: value, close_at_datetime: null } })
      if (close_at_datetime) {
        props.onChange(value)
      }
    }
  }

  return (
    <Radio.Group 
      buttonStyle="solid" 
      optionType="button" 
      options={options} 
      value={value}
      defaultValue={defaultValue} 
      onChange={(value) => handleOnChange(value.target.value)} 
      disabled={props.disabled}
    />
  )
}

const CloseDatetimeCustomComponent = (props) => {
  console.log("CloseDatetimeCustomComponent ====>", props);
  const { value, formInstance: { getFieldValue, setFieldsValue } } = props
  const mrIntl = useTranslate()
  const closeAtDateTimeRef = useRef()
  const start_at_datetime = getFieldValue(["settings", "start_at_datetime"])
  const duration = getFieldValue(["settings", "duration"]) || 0
  const is_auto_close = getFieldValue(["settings", "is_auto_close"])
  const [blurTriggered, setBlurTriggered] = useState(false)

  useEffect(() => {
    if (is_auto_close && start_at_datetime && value && start_at_datetime > value) {
      setFieldsValue({settings: { is_auto_close: false, close_at_datetime: null }})
    }
  }, [start_at_datetime])

  useEffect(() => {
    if (!props.disabled && closeAtDateTimeRef.current) {
      closeAtDateTimeRef.current.focus()
    }
  }, [props.disabled])

  const handleOnChange = (value) => {
    console.log("start_at_datetime => on change triggering ==>", value)
    let closed_at = value
    if (value < start_at_datetime) {
      closed_at = dayjs(start_at_datetime).add(5, "m")
    }
    if (value < dayjs()) {
      closed_at = dayjs().add(5, "m")
    }
    props.onChange(closed_at)
  }

  const onBlur = () => {
    setBlurTriggered(true)
  }

  const showError = !value && blurTriggered && is_auto_close
  const finalRender = (
    <Form.Item
      help={showError && mrIntl("CommonText.required_msg")}
      validateStatus={showError && "error"}
    >
      <DatePicker
        format={"YYYY-MM-DD h:mm a"}
        ref={closeAtDateTimeRef}
        showTime={{
          use12Hours: true,
          format: "h:mm a",
          minuteStep: 15,
        }}
        showNow={false}
        needConfirm={false}
        value={value}
        disabled={props.disabled}
        disabledDate={(currentDate) => {
          const startDate = dayjs(start_at_datetime)
          let dd = dayjs(startDate).isAfter(currentDate, 'day') || dayjs(startDate).add(3, 'month').startOf('day').isBefore(currentDate, 'day')
          console.log("startDate, dd ====> disabledDate", startDate, dd)
          return dd; 
        }}
        disabledTime={(currentDate) => {
          console.log("current date ===>", currentDate)
          const cd = dayjs(currentDate)
          const startDate = start_at_datetime > dayjs() ? dayjs(start_at_datetime) : dayjs()
          let dt = {}
          if (cd.date() <= startDate.date()) {
            dt = {
              disabledHours: () => range(0, 24).filter((h) => startDate.minute() < 45 ? h < startDate.hour() : h <= startDate.hour()),
            }
            if (cd.hour() <= startDate.hour()) {
              dt = {
                ...dt,
                disabledMinutes: () => [0, 15, 30, 45].filter((m) => m <= startDate.minute()),
              }
            }
          }
          return dt 
        }}
        onChange={(value) => handleOnChange(value)}
        onBlur={onBlur}
        autoFocus
      />
    </Form.Item>
  )

  return finalRender
}

// const StartDatetimeChildComponent = (props) => {
//   console.log("StartDatetimeChildComponent props", props);
//   const { getFieldValue, sourceValues, setFieldsValue, formInstance } = props;
//   const startCondition = getFieldValue(["settings", "start_condition"]);
//   const startAtDatetime = getFieldValue(["settings", "start_at_datetime"]);
//   // useEffect(() => {
//   //   console.log("startAtDatetime ======>", startAtDatetime, startCondition);
//   //   if(!startAtDatetime) {
//   //     setFieldsValue({settings: {start_at_datetime: " "}})
//   //   }
//   // }, [startCondition])
//   const env = import.meta.env.VITE_MODE;
//   const mrIntl = useTranslate();
//   console.log("startCondition ====>", startCondition);
//   let finalRender = null;
//   if (startCondition === "scheduled_time" || (sourceValues.id && sourceValues.startCondition)) {
//     finalRender = (
//       <FormRenderer
//         formObj={{
//           type: "object",
//           properties: {
//             start_at_datetime: {
//               title: (
//                 <>
//                 {mrIntl("Experiences.start_date_and_time")} &nbsp;
//                 {/* Start date and time &nbsp; */}
//                 { startCondition === "anytime" && <Tooltip
//                 title={mrIntl("Experiences.students_can_start_test_anytime_after_published")}
//                   // title=" The students can start the test anytime after it is published"
//                   placement="top"
//                 >
//                   <InfoCircleTwoTone />
//                 </Tooltip>}

//                 { startCondition === "scheduled_time" && <Tooltip
//                   title={mrIntl("Experiences.students_will_not_be_able_to_start")}
//                   placement="top"
//                 >
//                   <InfoCircleTwoTone />
//                 </Tooltip>}
//                 </>

//               ),
//               placeholder: mrIntl("Experiences.start_date_and_time"),
//               // placeholder: "Start date and time",
//               type: "string",
//               span: 24,
//               widget: StartDatetimeCustomComponent,
//               validateStatus: "warning",
//               widgetConfig: {
//                 format: "YYYY-MM-DD h:mm a",
//                 // showTime: true,
//                 autoFocus: true,
//                 showTime: {
//                   use12Hours: true,
//                   format: "h:mm a",
//                   minuteStep: 15,
//                 },
//                 disabled: (sourceValues.status !== "draft" || startCondition === 'anytime') ? true : false,
//                 disabledDate: (current) => {
//                   // console.log("Env in start at date time ==>", env)
//                   if (env !== "demo" && env !== "demo-internal") {
//                     let dd =
//                       moment().startOf("day") >= current ||
//                       moment().add(3, "month") <= current;
//                     // console.log("Env in start at date time ==>", env, dd, current)
//                     return dd;
//                   }
//                 },
//               },
//               setInitialValue: true,
//               // rules: [
//               //   {
//               //     validator(rule, value) {
//               //       // console.log('StartConditionChildComponent validator', startCondition)
//               //       let validationPassed = true
//               //       if(startCondition !== "scheduled_time" && !value){
//               //         validationPassed = false
//               //       }
//               //       else{
//               //         validationPassed = true
//               //       }
//               //       // console.log('StartConditionChildComponent validator updatedPoints', validationPassed)
//               //       if(validationPassed){
//               //         return Promise.resolve()
//               //       }
//               //       else {
//               //         return Promise.reject()
//               //       }
//               //     }
//               //   },
//               // ],
//               // rules: [
//               //   {
//               //     required: startCondition === "scheduled_time" ? true : false,
//               //     message: mrIntl("CommonText.required_msg"),
//               //   },
//               // ],
//               // validateTrigger: ["onBlur", "onChange"]
//             },
//           },
//         }}
//         propertyKey={"settings"}
//         sourceValues={sourceValues}
//         formInstance={formInstance}
//       />
//     );
//   }
//   return finalRender;
// };

export const SpellCheckChildComponent = (props) => {
  console.log("SpellCheck Child props", props);
  const {
    getFieldValue,
    sourceValues,
  } = props;
  const mrIntl = useTranslate();
  const deliverMode = getFieldValue(["settings","mode"]);
  const isPaperMode = deliverMode === "paper";
  const tooltipTitle = deliverMode === "offline"
    ? mrIntl("Experiences.disabling_spellcheck_here_means_that_our_inbuilt_spellchecker_will_not")
    : mrIntl("Experiences.disabling_spellcheck_info_msg");

  let finalRender = (
    <FormRenderer
      formObj={{
        type: "object",
        properties: {
          spellcheck_enabled: {
            type: "string",
            placeholder: (
              <>
                {mrIntl("CommonText.spellcheck")} &nbsp;
                <Tooltip
                  title={tooltipTitle}
                  placement="top"
                  className="spellcheck-info-icon"
                >
                  {/* <sup> */}
                  <InfoCircleTwoTone />
                  {/* </sup> */}
                </Tooltip>
              </>
            ),
            setInitialValue: true,
            valuePropName: "checked",
            widget: CustomSwitchWidget,
            widgetConfig: {
              disabled: (sourceValues.status !== "draft" || isPaperMode) ? true : false,
              tooltipProps: { title: isPaperMode && mrIntl("CommonText.disabled_since_delivering_on_paper"), placement: "right"}
            }
          },
        },
      }}
      propertyKey={"settings"}
      sourceValues={sourceValues}
    />
  );

  return finalRender;
};

export const RequiredJoinCode = (props) => {

  const { getFieldValue, sourceValues } = props;
  const joinCode = sourceValues && sourceValues.access_code
  const joinCodeToStartTest = getFieldValue(["settings", "with_join_code"]);
  const deliveryMode = getFieldValue(["settings", "mode"]);
  const isPaperMode = deliveryMode === "paper";
  const mrIntl = useTranslate()

  let finalRender = (
    <FormRenderer
      formObj={{
        type: "object",
        properties: {
          with_join_code: {
            type: "string",
            placeholder: (
              <>
                {mrIntl("CommonText.require_join_code")} &nbsp; 
                <Tooltip
                  // title="Turn this on for an extra layer of security, allowing only students with the code to start the test. Don't forget to share the code with the students prior to the test."
                  title={mrIntl("Experiences.require_join_code_message")}
                  placement="top"
                  className="spellcheck-info-icon"
                >
                  <InfoCircleTwoTone />&nbsp;
                </Tooltip>
                {joinCodeToStartTest &&
                  <Tooltip title={mrIntl("Experiences.click_to_copy_join_code")} placement="right">
                    <Button
                      className={"join-code-custom-btn"}
                      type={"primary"}
                      ghost
                      icon={<CopyOutlined />}
                      shape={"round"}
                      onClick={(e) => {
                        writeToClipboardPolyfill(joinCode);
                        message.success(mrIntl("Experiences.join_code_copied"));
                      }}
                    >
                      {joinCode}
                    </Button>
                  </Tooltip>}
              </>
            ),
            valuePropName: "checked",
            widget: CustomSwitchWidget,
            setInitialValue: true,
            widgetConfig: {
              disabled: (sourceValues.status !== "draft" || isPaperMode) ? true : false,
              tooltipProps: { title: isPaperMode && mrIntl("CommonText.disabled_since_delivering_on_paper"), placement: "right"}
              // defaultChecked: deliveryMode === "join_code"
            },
          }
        }
      }}
      propertyKey={"settings"}
      sourceValues={sourceValues}
    />
  );

  return finalRender;
};

const ExternalVideoCallLinkCustomComponent = (props) => {
  console.log("ExternalVideoCallLinkCustomComponent ====>", props);
  const { checked, formInstance: { setFieldsValue }, tooltipProps } = props
  const mrIntl = useTranslate()
  
  const handleOnChange = (value) => {
    if(value) {
      setFieldsValue({ settings: { include_video_call_link: value } })
    } else {
      setFieldsValue({ settings: { video_call_link: null } })
      props.onChange(value)
    }
  }

  return (
    <CustomSwitchWidget placeholder={mrIntl("Experiences.external_video_call_link")} checked={checked} onChange={handleOnChange} disabled={props.disabled} tooltipProps={tooltipProps}/>
  )
}

// export const ExternalVideoCallLinkChildComponent = (props) => {
//   const {
//     getFieldValue,
//     sourceValues,
//     formInstance
//   } = props;
//   const mrIntl = useTranslate()
//   const deliveryMode = getFieldValue(["settings","mode"]);
//   const videoCallLink = getFieldValue(["settings","video_call_link"]);
//   const [currentVideoCallLink, setCurrentVideoCallLink] = useState(videoCallLink)

//   useEffect(() => {
//     setCurrentVideoCallLink(videoCallLink)
//   }, [videoCallLink])

//   if (videoCallLink != currentVideoCallLink) {
//     return null
//   }

//   let finalRender = (
//     <FormRenderer
//       formObj={{
//         type: "object",
//         properties: {
//           include_video_call_link: {
//             type: "string",
            // placeholder: (
            //   <>
            //     {mrIntl("Experiences.external_video_call_link")} &nbsp;
            //     {deliveryMode === "offline" && (
            //       <Tooltip
            //         title={mrIntl("Experiences.zoom_links_will_be_opened")}
            //         placement="top"
            //         className="external-video-call-info-icon"
            //       >
            //         {/* <sup> */}
            //         <InfoCircleTwoTone />
            //         {/* </sup> */}
            //       </Tooltip>
            //     )}
            //   </>
            // ),
//             valuePropName: "checked",
//             widget: ExternalVideoCallLinkCustomComponent,
//             setInitialValue: true,
//             // rules: [
//             //   {
//             //     validator(rule, value) {
//             //       let validationPassed = true
//             //       if(value && !isValidHttpsUrl(videoCallLink)){
//             //         validationPassed = false
//             //       }
//             //       else{
//             //         validationPassed = true
//             //       }
//             //       console.log('ExternalVideoCallLinkChildComponent validator ===>', validationPassed)
//             //       if(validationPassed){
//             //         return Promise.resolve()
//             //       }
//             //       else {
//             //         return Promise.reject()
//             //       }
//             //     }
//             //   },
//             // ],
//           },
//         },
//       }}
//       propertyKey={"settings"}
//       sourceValues={sourceValues}
//       formInstance={formInstance}
//     />
//   );

//   return finalRender;
// };

export const returnForm = (props) => {
  console.log("test form props", props);
  // getFromLS("activeOrgProgrammeId")
  let form = generateExperienceCreateForm("experience", props);
  return form;
};

export const CustomSwitchWidget = (props) => {
  console.log("CustomSwitchWidget props", props);
  const { tooltipProps = {} } = props;
  return (
    <Tooltip {...tooltipProps}>
      <Space>
        <Switch {...props} /> <label>{props.placeholder}</label>
      </Space>
    </Tooltip>  
  );
};

const processSettingsFormInitialValues = (data) => {
  console.log("initial data", data);
  let newData = { ...data };
  let modifiedSettings = data.settings;
  if (data.settings) {
    // let children = formatJsonApiData(data.children)
    if (data.settings.start_at_datetime) {
      modifiedSettings.start_at_datetime = dayjs(
        data.settings.start_at_datetime
      );
    }
    if (data.settings.close_at_datetime) {
      modifiedSettings.close_at_datetime = dayjs(
        data.settings.close_at_datetime
      );
    }
    // Define a constant
    const reviewerCloseTime = data &&
    data.settings &&
    data.settings.reviewer_setting &&
    data.settings.reviewer_setting.close_review_at_datetime;
    if (reviewerCloseTime) {
      modifiedSettings.reviewer_setting.close_review_at_datetime = dayjs(
        reviewerCloseTime
      );
    }
    if (data.settings.reviewer_setting && data.settings.reviewer_setting.assign_reviewers_at_datetime) {
      modifiedSettings.reviewer_setting.assign_reviewers_at_datetime = dayjs(
        data.settings.reviewer_setting.assign_reviewers_at_datetime
      );
    }

    if (!data.settings.map_classes_or_students) {
      modifiedSettings.map_classes_or_students = "students"
    }
    newData.settings = modifiedSettings;
  }

  console.log("newData ==>", newData);
  return newData;
};

const processExperienceCreateFormInitialValues = (data) => {
  console.log("initial data ====>", data);
  let newData = { ...data };
  let settings = data.settings || {}
  if (settings.start_at_datetime) {
    newData = {
      ...newData, 
      settings: {
        ...newData.settings, 
        start_at_datetime: dayjs(
          settings.start_at_datetime
        )
      }
    }
  }

  if(newData?.relation_items && newData?.relation_items[0]?.standard_collection_content_area_items) {

    const standard_set_tag_data = []
    const standard_collection_content_area_items = newData.relation_items[0].standard_collection_content_area_items
    
    standard_collection_content_area_items.forEach((item) => {
      if(!item.archived) {
        standard_set_tag_data.push({
          content_area_id: item?.tag_id,
          content_area_label: item?.custom_fields?.content_area_label,
          grade_group_id: item?.custom_fields?.grade_group_id,
          grade_group_label: item?.custom_fields?.grade_group_label,
          standard_collection_label: item?.custom_fields?.standard_collection_label,
          standard_collection_id: item?.custom_fields?.standard_collection_id,
        })
      }
    })
    newData.relation_items[0].standard_set_tag_data = standard_set_tag_data;
  }
  
  if(newData?.grading_scale_id) {
    newData.enable_standards_based_grading = true
  }

  return newData
}

export const settingsFormObj = (props, mrIntl) => {
  console.log("settingsFormObj ====>", props);
  const {
    sourceValues,
    options,
    getFieldValue
  } = props;

  const { currentUser, enabledFeatures, partnerName } = options.mainProps
  const startCondition = getFieldValue(["settings", "start_condition"])
  const includeExtraInstructions = getFieldValue(["settings", "include_extra_instructions"]);
  const deliveryMode = getFieldValue(["settings", "mode"]);
  const isAutoClose = getFieldValue(["settings", "is_auto_close"])
  const isDisableLockdownMode = enabledFeatures.disable_lockdown_mode
  const isPaperModeAllowed = enabledFeatures.paper_mode
  const isPaperMode = deliveryMode === "paper";
  const isAllowPeerReviewAssessmentEnabled = enabledFeatures.allow_peer_review_assessment
  const isPeerReviewEnabled = isAllowPeerReviewAssessmentEnabled && getFieldValue(["settings", "peer_review_enabled"]);
  const isAutoAssignReviewers = isPeerReviewEnabled && (getFieldValue(["settings", "reviewer_setting", "assign_reviewer_type"]) === 'automatic') ? true : false;

  const reviewersAssignedAt = sourceValues.reviewers_assigned_at
  const currentDateTime = dayjs()
  
  const isReviewersAssigned =
  reviewersAssignedAt
  ? currentDateTime.isAfter(dayjs(reviewersAssignedAt))
  ? true
  : false
  : false
  console.log("sourceValues================>>>", sourceValues.reviewers_assigned_at, reviewersAssignedAt, isReviewersAssigned)  
  
  const currentUserPermission = currentUser.permission || {}
  const canSwitchAnonymousGrading = currentUserPermission.experience?.can_switch_anonymous_grading

  const finalForm = {
    type: "object",
    properties: {
      mode: {
        type: "string",
        title: mrIntl("Experiences.delivery_mode"),
        // title: "Delivery mode",
        widget: CustomRadioGroupWidget,
        default: "online",
        span: 24,
        setInitialValue: true,
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.required_msg"),
          },
        ],
        widgetConfig: {
          options: getExperienceModeOptionsForForm({
            partnerName: partnerName,
            isDisableLockdownMode: isDisableLockdownMode,
            isPaperModeAllowed
          }, mrIntl ),
          // disabled: true
          disabled: sourceValues.status !== "draft" ? true : false,
          optionType: "button",
          buttonStyle: "solid",
        },
      },
      // start_condition: {
      //   type: "string",
      //   title: mrIntl("Experiences.start_condition"),
      //   // title: "Start condition [NEW]",
      //   widget: "RadioGroupWidget",
      //   // default: "anytime",
      //   span: 24,
      //   rules: [
      //     {
      //       required: true,
      //       message: mrIntl("CommonText.required_msg")
      //     },
      //   ],
      //   widgetConfig: {
      //     options: [
      //       {
      //         label: mrIntl("Experiences.anytime"),
      //         // label: "Any time",
      //         value: "anytime",
      //         // no-condition
      //       },
      //       {
      //         label: mrIntl("Experiences.scheduled_time"),
      //         // label: "Scheduled time",
      //         value: "scheduled_time",
      //         // TODO: make start date required if this selected
      //       },
      //       // {
      //       //   label: "Join code",
      //       //   value: "join_code",
      //       // },
      //     ],
      //     disabled: sourceValues.status !== "draft" ? true : false,
      //     optionType: "button",
      //     buttonStyle: "solid",
      //   },
      // },
      // assessment_type: {
      //   type: "string",
      //   span: 8,
      //   title: "Assessment type",
      //   widget: "RadioGroupWidget",
      //   default: "exam",
      //   widgetConfig: {
      //     options: [
      //       {
      //         label: "Exam",
      //         value: "exam",
      //       },
      //       {
      //         label: "Live quiz",
      //         value: "live_quiz",
      //       },
      //       {
      //         label: "Quick check",
      //         value: "quick_check",
      //       },
      //     ],
      //     disabled: sourceValues.status != "draft" ? true : false
      //   },
      // },

      // rubric_id: {
      //   type: "string",
      //   span: 24,
      //   title: "Grading type/Rubric",
      //   widget: SelectRubric,
      // },

      // grading_setting is equal to rubric. grading_type old and not used anymore
      // grading_type: {
      //   type: "string",
      //   span: 24,
      //   title: "Grading type/Rubric",
      //   widget: "RadioGroupWidget",
      //   // widgetConfig: {
      //   //   options: buildOptionsArr(['Achievement level','Points','Other rubric'])
      //   //   // others rubrics?
      //   // },
      //   default: "points",
      //   // wrapClassName: sourceValues.custom_fields.block != undefined ? "hidden" : "shikhar",
      //   wrapClassName: "shikhar",
      //   className: "shikhar",
      //   widgetConfig: {
      //     className: "shikhar",
      //     options: [
      //       {
      //         label: "Points",
      //         value: "points",
      //       },
      //       {
      //         label: "Achievement level",
      //         value: "level",
      //       },
      //       {
      //         label: "Other rubric",
      //         value: "other",
      //       },

      //     ],
      //     // disabled: sourceValues.status != "draft" ? true : false,
      //     disabled: true,
      //     optionType: "button",
      //     buttonStyle: "solid",
      //   },
      // },
      start_condition: {
        type: "string",
        title: mrIntl("Experiences.start_condition"),
        span: 12,
        // dependsOn: ["settings", "start_at_datetime"],
        setInitialValue: true,
        widget: StartConditionCustomComponent,
        widgetConfig: {
          disabled: sourceValues.status !== "draft" ? true : false,
        },
      },
      start_at_datetime: {
        type: "string",
        title: mrIntl("CommonText.start_date_and_time"),
        span: 12,
        // dependsOn: ["settings", "start_condition"],
        setInitialValue: true,
        // wrapClassName: `start-at-datetime ${startCondition !== "scheduled_time" && "hidden"}`,
        wrapClassName: `start-at-datetime`,
        widget: StartDatetimeCustomComponent,
        widgetConfig: {
          disabled: (sourceValues.status !== "draft" || startCondition !== "scheduled_time") ? true : false,
        },
        // widget: "DatePickerWidget",
        // widgetConfig: {
        //   // showTime: true,
        //   showTime: {use12Hours: true, format:"h:mm a", minuteStep: 15},
        //   disabled: sourceValues.status != "draft" ? true : false,

        // },
      },
      is_auto_close: {
        type: "string",
        span: 12,
        title: <>{mrIntl("Experiences.close_condition")}&nbsp;<Tooltip
          title={mrIntl("Experiences.set_a_final_deadline_after_which_students_cannot_start_this")}
          placement="top"
        >
          <InfoCircleTwoTone className="vertical-align-middle" />&nbsp;
          {/* {activeExperience && activeExperience.custom_fields.auto_close_job_id && <ClockCircleOutlined />} */}
        </Tooltip>
        </>,
        placeholder: mrIntl("Experiences.is_auto_close"),
        widget: IsAutoCloseCustomComponent,
        setInitialValue: true,
        widgetConfig: {
          // size: 'small',
          disabled: sourceValues.status == "closed" ? true : false,
          isMBLinked: sourceValues.is_mb_linked
        },
      },
      close_at_datetime: {
        // title: mrIntl("Experiences.close_at_datetime"),
        type: "string",
        span: 12,
        title: mrIntl("Experiences.close_at_datetime"),
        wrapClassName: `close-at-datetime`,
        widget: CloseDatetimeCustomComponent,
        setInitialValue: true,
        widgetConfig: {
          disabled: (sourceValues.status == "closed" || !isAutoClose) ? true : false,
        },
      },
      with_join_code: {
        type: "string",
        span: 24,
        // placeholder: "Require join code to start test",
        placeholder: mrIntl("CommonText.require_join_code"),
        dependsOn: ["settings"],
        wrapClassName: "required-join-code",
        children: RequiredJoinCode,
        // widget: RequiredJoinCode
      },
      is_timed: {
        type: "string",
        span: 12,
        // title: "Timed",
        placeholder: mrIntl("Experiences.timed_assessment"),
        // placeholder: "Timed assessment",
        // widget: "CheckboxWidget",
        // dependsOn: ["settings", "duration"],
        // children: CustomSwitchWidget,
        widget: IsTimeCustomComponent,
        valuePropName: "checked",
        setInitialValue: true,
        widgetConfig: {
          // size: 'small',
          // children: 'Timed',
          disabled: sourceValues.status !== "draft" ? true : false,
        },
      },
      duration: {
        // title: "Test Duration",
        type: "string",
        placeholder: `${mrIntl("Experiences.duration")} *`,
        // widget: "SelectWidget",
        widgetConfig: {
          // options: buildOptionsArr(["10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95", "100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160", "165", "170", "175", "180", "185", "190", "195", "200", "205", "210", "215", "220", "225", "230", "235", "240"]),
          disabled: sourceValues.status != "draft" ? true : false
        },
        setInitialValue: true,
        wrapClassName: "select-timer-dropdown",
        span: 7,
        // dependsOn: ["settings", "is_timed"],
        widget: DurationCustomComponent,
      },
      allow_student_upload_files: {
        type: "string",
        span: 24,
        placeholder: mrIntl("Experiences.allow_upload_documents"),
        // widget: "SwitchWidget",
        widget: CustomSwitchWidget,
        setInitialValue: true,
        // widget: "CheckboxWidget",
        valuePropName: "checked",
        widgetConfig: {
          // children: 'Calculator',
          disabled: (sourceValues.status !== "draft" || isPaperMode) ? true : false,
          tooltipProps: { title: isPaperMode && mrIntl("CommonText.disabled_since_delivering_on_paper"), placement: "right"},
        },
      },
      calc_enabled: {
        type: "string",
        span: 12,
        placeholder: mrIntl("Calculator.scientific_calculator"),
        // widget: "SwitchWidget",
        widget: CustomSwitchWidget,
        setInitialValue: true,
        // widget: "CheckboxWidget",
        valuePropName: "checked",
        widgetConfig: {
          // children: 'Calculator',
          disabled: sourceValues.status !== "draft" || isPaperMode ? true : false,
          tooltipProps: { title: isPaperMode && mrIntl("CommonText.disabled_since_delivering_on_paper"), placement: "right"},
        },
      },
      graphing_calc_enabled: {
        type: "string",
        span: 12,
        placeholder: mrIntl("Calculator.graphing_calculator"),
        // widget: "SwitchWidget",
        valuePropName: "checked",
        setInitialValue: true,
        widget: CustomSwitchWidget,
        widgetConfig: {
          // children: 'Calculator',
          disabled: (sourceValues.status !== "draft" || isPaperMode) ? true : false,
          tooltipProps: { title: isPaperMode && mrIntl("CommonText.disabled_since_delivering_on_paper"), placement: "right"},
        },
      },
      spellcheck_enabled: {
        type: "string",
        span: 12,
        placeholder: mrIntl("CommonText.spellcheck"),
        wrapClassName: enabledFeatures.spellcheck ? "" : "hidden",
        dependsOn: ["settings", "mode"],
        children: SpellCheckChildComponent,
      },
      anonymous_grading_enabled: {
        type: "string",
        span: 12,
        placeholder: mrIntl("Experiences.anonymous_grading"),
        // placeholder: "Anonymous grading",
        widget: CustomSwitchWidget,
        setInitialValue: true,
        valuePropName: "checked",
        wrapClassName: enabledFeatures.anonymous_grading ? "" : "hidden",
        widgetConfig: {
          disabled:
            currentUser.role !== "admin" &&
            !canSwitchAnonymousGrading &&
            sourceValues.status !== "draft"
              ? true
              : false,
        },
      },
      // allow_reading_time: {
      //   type: "string",
      //   span: 8,
      //   placeholder: "Allow reading time",
      //   // widget: "SwitchWidget",
      //   widget: CustomSwitchWidget,
      //   // widget: "CheckboxWidget",
      //   valuePropName: "checked",
      //   widgetConfig: {
      //     // children: 'Calculator',
      //     disabled: sourceValues.status != "draft" ? true : false
      //   }
      // },
      // reading_time: {
      //   type: "string",
      //   span: 9,
      //   placeholder: "Reading time",
      //   widgetConfig: {
      //     disabled: sourceValues.status != "draft" ? true : false
      //   },
      //   dependsOn: ["settings", "allow_reading_time"],
      //   children: ReadingTimeChildComponent,
      // },
      enable_ap_video_monitoring: {
        type: "string",
        span: 13,
        placeholder: (
          <span>
          {mrIntl("Experiences.ap_video_monitoring")}&nbsp;
            <Tooltip
              title={mrIntl("Experiences.enable_ap_video_monitoring_tooltip")}
              placement="top"
              className="ap-video-monitoring-info-icon"
            >
              {/* <sup> */}
              <QuestionCircleTwoTone />
              {/* </sup> */}
            </Tooltip>
          </span>
        ),
        widget: CustomSwitchWidget,
        setInitialValue: true,
        valuePropName: "checked",
        // wrapClassName: import.meta.env.VITE_MODE == "development" || import.meta.env.VITE_MODE == "staging" || import.meta.env.VITE_MODE == "demo" || import.meta.env.VITE_MODE == "demo-internal" || org.custom_fields.can_enable_ap_monitoring ? "" : "hidden",
        wrapClassName: "hidden",
        widgetConfig: {
          disabled: sourceValues.status !== "draft" ? true : false,
        },
      },
      include_video_call_link: {
        type: "string",
        span: 12,
        // placeholder: "External video call link",
        placeholder: (
          <>
            {mrIntl("Experiences.external_video_call_link")} &nbsp;
            {deliveryMode === "offline" && (
              <Tooltip
                title={mrIntl("Experiences.zoom_links_will_be_opened")}
                placement="top"
                className="external-video-call-info-icon"
              >
                {/* <sup> */}
                <InfoCircleTwoTone />
                {/* </sup> */}
              </Tooltip>
            )}
          </>
        ),
        // dependsOn: ["settings", "video_call_link"],
        widget: ExternalVideoCallLinkCustomComponent,
        // dependsOn: ["settings", "mode"],
        valuePropName: "checked",
        setInitialValue: true,
        widgetConfig: {
          disabled: (sourceValues.status !== "draft" || isPaperMode) ? true : false,
          tooltipProps: { title: isPaperMode && mrIntl("CommonText.disabled_since_delivering_on_paper"), placement: "right"},
        },
      },
      video_call_link: {
        type: "string",
        span: 12,
        placeholder: mrIntl("Experiences.video_call_link"),
        // placeholder: "Video call link",
        wrapClassName: "external-video-call",
        widgetConfig: {
          disabled: sourceValues.status !== "draft" ? true : false,
        },
        // dependsOn: ["settings", "include_video_call_link"],
        setInitialValue: true,
        widget: VideoCallLinkCustomComponent,
      },
      // allow_upload_submissions: {
      //   type: "string",
      //   title: "Allow students to upload documents",
      //   span: 6,
      //   widget: "SwitchWidget",
      //   widgetConfig: {
      //     disabled: sourceValues.status != "draft" ? true : false
      //   }
      //   // widgetConfig: {
      //   //   defaultChecked: false,
      //   // },
      // },
      // randomise_questions: {
      //   title: "Randomise questions",
      //   type: "string",
      //   span: 4,
      //   widget: "SwitchWidget",
      //   widgetConfig: {
      //     disabled: sourceValues.status != "draft" ? true : false
      //   }
      // },
      // shuffle_answers: {
      //   title: "Shuffle answers",
      //   type: "string",
      //   span: 4,
      //   widget: "SwitchWidget",
      //   widgetConfig: {
      //     disabled: sourceValues.status != "draft" ? true : false
      //   }
      // },
      // attempts_no: {
      //   title: "Number of attempts - qc",
      //   type: "string",
      //   span: 4,
      //   widgetConfig: {
      //     disabled: sourceValues.status != "draft" ? true : false
      //   }
      // },

      // Add text box
      // allowed_websites: {
      //   type: "string",
      //   span: 24,
      //   placeholder: "Allowed websites",
      //   // widget: "CheckboxGroupWidget",
      //   widget: CustomSwitchWidget,
      //   valuePropName: "checked",
      //   widgetConfig: {
      //     // options: buildOptionsArr(['Allowed websites']),
      //     disabled: sourceValues.status != "draft" ? true : false
      //   },
      // },

      include_extra_instructions: {
        type: "string",
        span: 12,
        placeholder: mrIntl("Experiences.extra_instructions"),
        // placeholder: "Extra instructions",
        // widget: "CheckboxGroupWidget",
        // widget: CustomSwitchWidget,
        // dependsOn: ["settings", "extra_instructions_text"],
        widget: IncludeExtraInstructionsCustomComponent,
        setInitialValue: true,
        valuePropName: "checked",
        widgetConfig: {
          // options: buildOptionsArr(['Extra Instructions']),
          disabled: sourceValues.status !== "draft" ? true : false,
        },
      },
      extra_instructions_text: {
        type: "string",
        span: 24,
        // widget: CKETextContent,
        wrapClassName: !includeExtraInstructions && "hidden", 
        widgetConfig: {
          // options: buildOptionsArr(['Extra Instructions']),
          disabled: sourceValues.status !== "draft" ? true : false,
        },
        setInitialValue: true,
        // dependsOn: ["settings", "include_extra_instructions"],
        widget: ExtraInstructionsCustomComponent,
      },
      enable_auto_screenshots: {
        type: "string",
        span: 24,
        dependsOn: ["settings", "mode"],
        children: EnabelScreenshotChildComponent,
        // widget: CustomSwitchWidget,
        valuePropName: "checked",
      },
      chatgpt_enabled: {
        type: "string",
        span: 24,
        placeholder: mrIntl("Experiences.allow_chatgpt"),
        // widget: "SwitchWidget",
        widget: CustomSwitchWidget,
        // widget: "CheckboxWidget",
        setInitialValue: true,
        valuePropName: "checked",
        widgetConfig: {
          // children: 'Calculator',
          disabled: sourceValues.status !== "draft" ? true : false,
        },
        wrapClassName: !enabledFeatures.allow_chatgpt_for_students && "hidden"
      },
      chat_disabled: {
        type: "string",
        span: 24,
        setInitialValue: true,
        placeholder: (
          <span>
            {mrIntl("Experiences.disable_chat")} &nbsp;
            <Tooltip
              title={mrIntl("Experiences.turn_this_on_to_disable_chat_between_teacher_and_student")}
              placement="top"
            >
              <InfoCircleTwoTone />
            </Tooltip>
          </span>
        ),
        widget: CustomSwitchWidget,
        widgetConfig: {
          disabled: isPaperMode ? true : false,
          tooltipProps: { title: isPaperMode && mrIntl("CommonText.disabled_since_delivering_on_paper"), placement: "right"},
        },
        valuePropName: "checked",
      },
      peer_review_enabled: {
        type: "string",
        span: sourceValues.is_mb_linked ? 12 : 24,
        placeholder: <span>
          {mrIntl("Experiences.peer_review")} &nbsp;
          <Tooltip
            title={mrIntl("Experiences.reviewers_already_assigned_please_make_changes_from_the_grade_tab")}
            placement="top"
          >
            <InfoCircleTwoTone />
          </Tooltip>
        </span>,
        wrapClassName: isAllowPeerReviewAssessmentEnabled ? "" : "hidden",
        widget: PeerReviewCustomComponent,
        valuePropName: "checked",
        setInitialValue: true,
        widgetConfig: {
          disabled: (isReviewersAssigned || isPaperMode) ? true : false,
          tooltipProps: { title: isPaperMode && mrIntl("CommonText.disabled_since_delivering_on_paper"), placement: "right"},
        },
      },
      reviewer_setting: {
        type: "object",
        properties: {
          assign_reviewer_type: {
            type: "string",
            span: 12,
            title: <>{mrIntl("Experiences.assign_reviewers")}</>,
            // &nbsp;<Tooltip
            //   title={mrIntl("Experiences.set_a_final_deadline_after_which_students_cannot_start_this")}
            //   placement="top"
            // >
            //   <InfoCircleTwoTone />&nbsp;
            //   {/* {activeExperience && activeExperience.custom_fields.auto_close_job_id && <ClockCircleOutlined />} */}
            // </Tooltip>
            widget: AssignReviewerType,
            wrapClassName: isPeerReviewEnabled && !sourceValues.is_mb_linked ? "" : "hidden",
            setInitialValue: true,
            widgetConfig: {
              disabled: isReviewersAssigned ? true : false,
            },
          },
          assign_reviewers_per_student: {
            type: "string",
            span: 12,
            title: <>{mrIntl("Experiences.assign_reviewers_per_student")}</>,
            widget: ReviewerPerStudent,
            wrapClassName: isAutoAssignReviewers ? "" : "hidden",
            setInitialValue: true,
            widgetConfig: {
              disabled: isReviewersAssigned ? true : false,
            },
          },
          assign_reviewers_at_datetime: {
            type: "string",
            // offset: 1,
            span: 12,
            title: <>{mrIntl("Experiences.assign_reviewers_at_datetime")}</>,
            wrapClassName: isAutoAssignReviewers ? "" : "hidden",
            widget: AssignReviewerAtDatetimeCustomComponent,
            setInitialValue: true,
            widgetConfig: {
              disabled: isReviewersAssigned ? true : false,
            },
          },
          close_review_at_datetime: {
            type: "string",
            className: "review-close-at-date-time",
            // offset: 1,
            span: 12,
            title: mrIntl("Experiences.close_review_at_date_and_time"),
            wrapClassName: isPeerReviewEnabled ? "" : "hidden",
            widget: ReviewCloseAtDatetimeCustomComponent,
            setInitialValue: true,
          },
          // anonymous_peers_grading: {
          //   type: "string",
          //   span: 12,
          //   placeholder: "Anonymous peers grading",
          //   widget: CustomSwitchWidget,
          //   wrapClassName: isPeerReviewEnabled ? "" : "hidden",
          //   valuePropName: "checked",
          //   setInitialValue: true,
          // },
          // allow_peers_annotations: {
          //   type: "string",
          //   span: 12,
          //   placeholder: "Anonymous peers annotation",
          //   widget: CustomSwitchWidget,
          //   wrapClassName: isPeerReviewEnabled ? "" : "hidden",
          //   valuePropName: "checked",
          //   setInitialValue: true,
          // },
          // allow_peers_comments: {
          //   type: "string",
          //   span: 12,
          //   placeholder: "Anonymous peers comments",
          //   widget: CustomSwitchWidget,
          //   wrapClassName: isPeerReviewEnabled ? "" : "hidden",
          //   valuePropName: "checked",
          //   setInitialValue: true,
          // },
        }
      },
    },
  }

  return finalForm
}

const RestrictJoinToMappedStudents = (props) => {
  const { value, formInstance: { getFieldValue, setFieldsValue }, sourceValues } = props;
  
  let allowUnmappedStudents = value
  let allowGuests = getFieldValue(["settings", "allow_guests"])
  const deliveryMode = getFieldValue(["settings", "mode"]);
  const isPaperMode = deliveryMode === "paper";
  const mrIntl = useTranslate()

  // item here is experience
  let restrictJoinToMappedStudents = allowUnmappedStudents === undefined ? true : allowUnmappedStudents;


  const updateSettings = (value) => {
    if(!value && allowGuests) {
      setFieldsValue({settings: {allow_guests: false}})
    }

    props.onChange(value)
  }

  const restrictStudentChange = (value) => {
    // NOTE: DO we need possibleStudents condition here?
    // if (restrictJoinToMappedStudents && !possibleStudents) {
    updateSettings(value)
  };

  return (
    <>
    <Tooltip title={isPaperMode && mrIntl("CommonText.disabled_since_delivering_on_paper")} placement="top">
      <Space>
          <Switch
            size={"small"}
            defaultChecked
            disabled={(restrictJoinToMappedStudents && sourceValues.status !== "draft") || isPaperMode}
            checked={restrictJoinToMappedStudents}
            onChange={(value) => restrictStudentChange(value)}
          />{" "}
          {mrIntl("Experiences.allow_unmapped_students_to_take_test_using_join_code")}
      </Space>
    </Tooltip>
    </>
  );
};

const AllowGuestsSwitch = (props) => {
  console.log("Allow Guests Switch props ==>", props)
  const { value,  formInstance: { getFieldValue, setFieldsValue }, sourceValues } = props;

  let allowGuests = value
  let allowUnmappedStudents = getFieldValue(["settings", "allow_unmapped_students"])
  const deliveryMode = getFieldValue(["settings", "mode"]);
  const isPaperMode = deliveryMode === "paper"
  const mrIntl = useTranslate();

  const partnerName = useSelector(partnerNameSelector())

  const allowGuestsChange = (value) => {
    if(!allowUnmappedStudents && value) {
      setFieldsValue({settings: {allow_unmapped_students: true}})
    }
    console.log("allowUnmappedStudents && allowGuests", allowUnmappedStudents && allowGuests, allowUnmappedStudents, allowGuests);
    props.onChange(value)
  };

  let finalRender = null

  if (!sourceValues.is_mb_linked && partnerName != "21k") {
    finalRender = 
    <>
    <Tooltip title={isPaperMode && mrIntl("CommonText.disabled_since_delivering_on_paper")}>  
      <Space>
            <Switch
              size={"small"}
              checked={allowGuests}
              disabled={isPaperMode}
              onChange={(value) => allowGuestsChange(value)}
            />{" "}
            {mrIntl("CommonText.allow_guests")}
          <Tooltip
            title={mrIntl("Experiences.students_without_an_account_on_assessprep_can_access_and_take")}
            placement="top"
            className="spellcheck-info-icon"
          >
            <InfoCircleTwoTone className="vertical-align-middle" />
          </Tooltip>
          {/* Allow guests */}
        {/* </Tooltip> */}
        {allowGuests && (
          <Tooltip title={mrIntl("CommonText.copy_join_link")} placement="top">
            <Button
              icon={<LinkOutlined />}
              type={"primary"}
              ghost
              shape={"round"}
              onClick={() => {
                let expUrl =
                  import.meta.env.VITE_WEB_URL +
                  "/guest/join?code=" +
                  sourceValues.access_code;
                writeToClipboardPolyfill(expUrl);
                message.success(`${mrIntl("CommonText.join_link_copied")}!`);
              }}
            >
              {mrIntl("CommonText.copy_join_link")}
            </Button>
          </Tooltip>
        )}
      </Space>
    </Tooltip>   
    </>
  }


  return finalRender
};


const MapClassesOrStudents = (props) => {
  console.log("MapClassesOrStudents", props);

  const { value,  formInstance: { getFieldValue, setFieldsValue }, sourceValues } = props;
  const mrIntl = useTranslate();
  const experienceMembershipsAttributes = getFieldValue("experience_memberships_attributes")
  const currentGradeOnly = getFieldValue(["settings", "current_grade_only"])
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const dispatch = useDispatch()
  const relationItems = activeExperience.relation_items || []
  const gradeItem = relationItems[0]?.grade_items[0] || {}
  const currentGradeLabel = gradeItem.label || ""
  console.log("currentGrade currentGrade ==>", currentGradeLabel);
  
  const [showingCurrentGradeOnly, setShowingCurrentGradeOnly] = useState(true);

  const onMembershipTypeChange = (e) => {
    console.log("onMembershipTypeChange ==>", e.target.value)
    setFieldsValue({settings: {map_classes_or_students: e.target.value}})
  }

  const getGradeLabel = () => {
    if (showingCurrentGradeOnly) {
      return currentGradeLabel.length > 10
        ? `${currentGradeLabel.substring(0, 10)}...`
        : currentGradeLabel;
    }
    return mrIntl("Experiences.all_grades");
  }

  const items = [
    {
      label: currentGradeLabel,
      key: "current_grade_only",
    },
    {
      label: mrIntl("Experiences.all_grades"),
      key: "all_grade",
    },
  ]

  // NOTE: Custom component because we don't want to trigger onChange of form on changing value of this item, If we use form validation then other fields changes does not get saved when this invalidate
  let finalRender = <Space className="map-class-student-inner-space" direction="vertical" size={10}>
    <Space>
      <span style={{fontSize: "16px"}}>{mrIntl("Experiences.assign_specific_classes_or_students")}</span>
      <Tooltip
        title={mrIntl("ExperienceMemberships.mapped_students_can_access_and_take_this_test_directly_from")}
        placement="top"
        className="spellcheck-info-icon"
      >
        <InfoCircleTwoTone className="vertical-align-middle" />
      </Tooltip>
    </Space>
    <Row>
      <Col span={16}>
        <Radio.Group defaultValue="students" value={value} onChange={onMembershipTypeChange} disabled={sourceValues.status !== "draft" || sourceValues.is_mb_linked ? true : false} >
          <Radio value="groups">{mrIntl("CommonText.select_classes")}</Radio>
          <Radio value="students">{mrIntl("CommonText.select_students")}</Radio>
        </Radio.Group>
      </Col>
      <Col span={8} className="grade-dropdown-container">
        {value === "groups" ? mrIntl("Experiences.showing_classes_for") : mrIntl("Experiences.showing_students_for")}
        <Dropdown
          trigger={["click"]}
          menu={{
            items,
            selectable: true,
            defaultSelectedKeys: ['current_grade_only'],
            onClick:({ key }) => {
              const checked = key === "current_grade_only";
              setShowingCurrentGradeOnly(checked);
              setFieldsValue({ settings: { current_grade_only: checked } });
            }
          }}
        >
          <Button type="text" className="grade-dropdown-button">
            {getGradeLabel()}
            <DownOutlined />
          </Button>
        </Dropdown>
      </Col>
    </Row>

  </Space>
  // let finalRender = (
  //   <FormRenderer
  //     formObj={{
  //       type: "object",
  //       properties: {
  //         map_classes_or_students: {
  //           type: "string",
  //           widget: "RadioGroupWidget",
  //           default: "students",
  //           span: 24,
  //           setInitialValue: true,
  //           rules: [
  //             {
  //               validator(rule, value) {
  //                 let validationPassed = true
  //                 if(!experienceMembershipsAttributes || !experienceMembershipsAttributes.length){
  //                   validationPassed = false
  //                 }
  //                 else{
  //                   validationPassed = true
  //                 }
  //                 if(validationPassed){
  //                   return Promise.resolve()
  //                 }
  //                 else {
  //                   return Promise.reject()
  //                 }
  //               }
  //             },
  //           ],
  //           widgetConfig: {
  //             options: [
  //               {
  //                 label: mrIntl("CommonText.select_classes"),
  //                 value: "groups",
  //               },
  //               {
  //                 label: mrIntl("CommonText.select_students"),
  //                 value: "students",
  //               },
  //             ],
  //             disabled: sourceValues.status !== "draft" || sourceValues.is_mb_linked ? true : false, // NOTE: is_mb_linked not sending
  //             optionType: "button",
  //             buttonStyle: "solid",
  //           }
  //         },
  //       },
  //     }}
  //     propertyKey={"settings"}
  //     sourceValues={sourceValues}
  //   />
  // );

  return finalRender;
}


export const assignStudentsFormObj = (props, mrIntl) => {
  console.log("assignStudentsFormObj ==>", props)
  const {
    sourceValues,
  } = props;

  let formObj =  {
    type: "object",
    properties: {
      allow_unmapped_students: {
        type: "string",
        span: 24,
        widget: RestrictJoinToMappedStudents,
        setInitialValue: true,
        widgetConfig: {
          sourceValues: sourceValues,
        },
      },
      allow_guests: {
        type: "string",
        span: 24,
        widget: AllowGuestsSwitch,
        setInitialValue: true,
        widgetConfig: {
          sourceValues: sourceValues,
        }
      },
      map_classes_or_students: {
        type: "string",
        span: 24,
        widget: MapClassesOrStudents,
        setInitialValue: true,
        wrapClassName: "map-classes-students-radio",
        widgetConfig: {
          sourceValues: sourceValues,
        },
      },
    },
  }

  return formObj
}

export const returnSettingsForm = (props) => {
  console.log("returnSettingsForm props ==>", props)
  const { mrIntl, enabledFeatures } = props

  let form = {
    processFormData: (dataProps, opts) => {
      console.log("settings process form dataProps and opts", dataProps, opts);
      // console.log("opts", opts);
      // process and set values and then return changed values
      // opts.values["custom_field"] = "custom_value";
      // opts.values = {}
      // const newOptsValues = { id: opts.values.id, settings: opts.values.settings }
      if(opts.values.settings.is_timed) {
        if(opts.values.settings.duration == null) {
          opts.values.settings.is_timed = false
        }
      }
      if(opts.values.settings.start_condition === "scheduled_time") {
        if(!opts.values.settings.start_at_datetime) {
          opts.values.settings.start_condition = "anytime"
        }
        if (opts.values.settings.start_at_datetime > opts.values.settings.close_at_datetime) {
          opts.values.settings.is_auto_close = false
          opts.values.settings.close_at_datetime = null
        }
      }
      if(opts.values.settings.is_auto_close === true) {
        if(!opts.values.settings.close_at_datetime) {
          opts.values.settings.is_auto_close = false
        }
      }
      if(opts.values.settings.include_video_call_link) {
        if(!opts.values.settings.video_call_link) {
          opts.values.settings.include_video_call_link = false
        }
      }
      if(opts.values.settings.include_extra_instructions) {
        if(!opts.values.settings.extra_instructions_text) {
          opts.values.settings.include_extra_instructions = false
        }
      }
      // if (!opts.values.settings.include_extra_instructions) {
      //   opts.values.settings.extra_instructions_text = "";
      // }
     
      if (enabledFeatures.allow_peer_review_assessment) {
        const optsValuesSettings = {...opts.values.settings};
        const reviewerSetting = optsValuesSettings.reviewer_setting;
        if (optsValuesSettings.peer_review_enabled) {
          const { assign_reviewer_type, assign_reviewers_at_datetime } = reviewerSetting;
          if (assign_reviewer_type === "automatic" && assign_reviewers_at_datetime === null) {
            optsValuesSettings.peer_review_enabled = false;
            reviewerSetting.assign_reviewer_type = undefined;
            reviewerSetting.reviewer_per_student = 0;
            reviewerSetting.assign_reviewers_per_student = 0;
          }
        } else {
          reviewerSetting.assign_reviewer_type = undefined;
          reviewerSetting.reviewer_per_student = 0;
          reviewerSetting.assign_reviewers_per_student = 0;
          reviewerSetting.assign_reviewers_at_datetime = null;
          reviewerSetting.close_review_at_datetime = null;
          // reviewerSetting.anonymous_peers_grading = undefined;
          // reviewerSetting.allow_peers_annotations = undefined;
          // reviewerSetting.allow_peers_comments = undefined;
        }
        opts.values.settings = optsValuesSettings;
        opts.values.settings.reviewer_setting = reviewerSetting
      }

      // if (
      //   !opts.values.settings.reviewer_setting.assign_reviewer_type &&
      //   opts.values.settings.reviewer_setting.reviewer_per_student > 0
      // ) {
      //   opts.values.settings.reviewer_setting.reviewer_per_student = undefined;
      // }
      const newOptsValues = {
        uid: opts.values.uid,
        settings: opts.values.settings,
        experience_memberships_attributes: opts.values.experience_memberships_attributes
      };
      // opts.values = { settings: }
      return newOptsValues;
    },
    schema: {
      type: "object",
      properties: {
        settings: {
          type: "object",
          properties: {
            left_side_form: {
              type: "string",
              dependsOn: ["status"],
              children: LeftSideFormChildComponent,
              span: 11,
            },
            right_side_form: {
              type: "string",
              dependsOn: ["status"],
              children: RightSideFormChildComponent,
              span: 11,
              offset: 1,
            },
          },
        },
        // Note: hidden field of experience_memberships_attributes because we need its UI render in nested form of settings
        experience_memberships_attributes: {
          type: "string",
          wrapClassName: "hidden",
          // widget: "Input",
          // style: {
          //   display: 'none'
          // },
          setInitialValue: true,
        }
      },
    },
  };
  return form;
};

export const returnCollaboratorsForm = (props) => {
  console.log("test form props", props);
  const { mrIntl } = props

  // const formValues = props.formState.values;
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      // process and set values and then return changed values
      // opts.values["custom_field"] = "custom_value";
      let experience_memberships_attributes = [];
      let toCreate = null;
      let toRemove = null;
      let toUnarchive = null;
      let existing = null;
      let modified = null;
      // create/update
      // create if id exists in opts.values.group_teacher_ids but not in dataProps.item.group_users_teachers.pluck("user_id")
      if (opts.values.experience_membership_collaborator_ids) {
        modified = opts.values.experience_membership_collaborator_ids;
      }
      if (
        dataProps.item &&
        dataProps.item.experience_memberships_collaborators
      ) {
        existing = _.map(
          dataProps.item.experience_memberships_collaborators,
          "memberable_id"
        );
      }
      toCreate = _.difference(modified, existing);
      toRemove = _.difference(existing, modified);
      toUnarchive = _.intersection(existing, modified);

      console.log("toCreate, toRemove ", toCreate, toRemove);
      if (toCreate) {
        toCreate.map((userId, i) => {
          experience_memberships_attributes.push({
            memberable_type: "collaborator",
            memberable_id: userId,
          });
        });
      }
      if (toRemove) {
        toRemove.map((userId, i) => {
          console.log(
            "userId, existing ",
            userId,
            existing,
            dataProps.item.experience_memberships_collaborators
          );
          let user = _.find(
            dataProps.item.experience_memberships_collaborators,
            ["memberable_id", userId]
          );
          experience_memberships_attributes.push({
            id: user.id,
            memberable_type: "collaborator",
            memberable_id: userId,
            archived: true,
          });
        });
      }
      if (toUnarchive) {
        toUnarchive.map((userId, i) => {
          console.log(
            "userId, existing ",
            userId,
            existing,
            dataProps.item.experience_memberships_collaborators
          );
          let user = _.find(
            dataProps.item.experience_memberships_collaborators,
            ["memberable_id", userId]
          );
          experience_memberships_attributes.push({
            id: user.id,
            memberable_type: "collaborator",
            memberable_id: userId,
            archived: false,
          });
        });
      }

      opts.values.experience_memberships_attributes = experience_memberships_attributes;

      // const newOptsValues = {id: opts.values.id, experience_memberships_attributes: experience_memberships_attributes}
      const newOptsValues = {
        uid: opts.values.uid,
        experience_memberships_attributes: experience_memberships_attributes,
      };
      return newOptsValues;
    },
    schema: {
      type: "object",
      properties: {
        experience_membership_collaborator_ids: {
          wrapClassName: "select-collaborators",
          type: "string",
          title: mrIntl("CommonText.select_teachers"),
          // title: "Select teachers",
          default: [],
          span: 24,
          widget: SelectTeachers,
        },
        experience_memberships_editors: {
          type: "string",
          // title: "Select teachers",
          span: 24,
          wrapClassName: "hidden",
          // here just to getFieldValue in SelectTeachers and disable selecting owner/author
        },
      },
    },
  };
  return form;
};

export const returnInvigilatorsForm = (props) => {
  console.log("test form props", props);
  const { mrIntl } = props

  // const formValues = props.formState.values;
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      // process and set values and then return changed values
      // opts.values["custom_field"] = "custom_value";
      let experience_memberships_attributes = [];
      let toCreate = null;
      let toRemove = null;
      let toUnarchive = null;
      let existing = null;
      let modified = null;
      // create/update
      // create if id exists in opts.values.group_teacher_ids but not in dataProps.item.group_users_teachers.pluck("user_id")
      if (opts.values.experience_membership_invigilator_ids) {
        modified = opts.values.experience_membership_invigilator_ids;
      }
      if (
        dataProps.item &&
        dataProps.item.experience_memberships_invigilators
      ) {
        existing = _.map(
          dataProps.item.experience_memberships_invigilators,
          "memberable_id"
        );
      }
      toCreate = _.difference(modified, existing);
      toRemove = _.difference(existing, modified);
      toUnarchive = _.intersection(existing, modified);

      console.log("toCreate, toRemove ", toCreate, toRemove);
      if (toCreate) {
        toCreate.map((userId, i) => {
          experience_memberships_attributes.push({
            memberable_type: "invigilator",
            memberable_id: userId,
          });
        });
      }
      if (toRemove) {
        toRemove.map((userId, i) => {
          console.log(
            "userId, existing ",
            userId,
            existing,
            dataProps.item.experience_memberships_invigilators
          );
          let user = _.find(
            dataProps.item.experience_memberships_invigilators,
            ["memberable_id", userId]
          );
          experience_memberships_attributes.push({
            id: user.id,
            memberable_type: "invigilator",
            memberable_id: userId,
            archived: true,
          });
        });
      }
      if (toUnarchive) {
        toUnarchive.map((userId, i) => {
          console.log(
            "userId, existing ",
            userId,
            existing,
            dataProps.item.experience_memberships_invigilators
          );
          let user = _.find(
            dataProps.item.experience_memberships_invigilators,
            ["memberable_id", userId]
          );
          experience_memberships_attributes.push({
            id: user.id,
            memberable_type: "invigilator",
            memberable_id: userId,
            archived: false,
          });
        });
      }

      opts.values.experience_memberships_attributes = experience_memberships_attributes;

      // const newOptsValues = {id: opts.values.id, experience_memberships_attributes: experience_memberships_attributes}
      const newOptsValues = {
        uid: opts.values.uid,
        experience_memberships_attributes: experience_memberships_attributes,
      };
      return newOptsValues;
    },
    schema: {
      type: "object",
      properties: {
        experience_membership_invigilator_ids: {
          wrapClassName: "select-invigilators",
          type: "string",
          title: mrIntl("Experiences.select_invigilators"),
          // title: "Select invigilators",
          default: [],
          span: 24,
          widget: SelectTeachers,
        },
        experience_memberships_editors: {
          type: "string",
          // title: "Select teachers",
          span: 24,
          wrapClassName: "hidden",
          // here just to getFieldValue in SelectTeachers and disable selecting owner/author
        },
      },
    },
  };
  return form;
};

// export const returnStudentsForm = (props) => {
//   // NOT USED AS OF NOW - REPLACED WITH CUSTOM COMPONENT
//   console.log("test form props", props);
//   const { mrIntl } = props

//   const formValues = props.formState.values;
//   let form = {
//     processFormData: (dataProps, opts) => {
//       console.log("dataProps and opts of students form", dataProps, opts);
//       // console.log("opts", opts, props);
//       // process and set values and then return changed values
//       // opts.values["custom_field"] = "custom_value";
//       let setFieldsValue =
//         opts.formFinishProps.forms.experiences_1.setFieldsValue;
//       let experience_memberships_attributes = [];
//       let toCreate = null;
//       let toRemove = null;
//       let toUnarchive = null;
//       let existing = null;
//       let modified = null;
//       // create/update
//       // create if id exists in opts.values.group_teacher_ids but not in dataProps.item.group_users_teachers.pluck("user_id")
//       if (opts.values.experience_membership_group_ids) {
//         modified = opts.values.experience_membership_group_ids;
//       }
//       if (dataProps.item && dataProps.item.experience_memberships_groups) {
//         existing = _.map(
//           dataProps.item.experience_memberships_groups,
//           "memberable_id"
//         );
//       }
//       if (opts.values.settings.map_classes_or_students === "groups") {
//         toCreate = _.difference(modified, existing);
//         toRemove = _.difference(existing, modified);
//         toUnarchive = _.intersection(existing, modified);
//       } else {
//         toRemove = _.union(existing, modified);
//         setFieldsValue({ experience_membership_group_ids: [] });
//       }

//       console.log(
//         "create remove update mr select",
//         modified,
//         existing,
//         toCreate,
//         toRemove
//       );

//       console.log("toCreate, toRemove ", toCreate, toRemove);
//       if (toCreate) {
//         toCreate.map((groupId, i) => {
//           experience_memberships_attributes.push({
//             memberable_type: "group",
//             memberable_id: groupId,
//           });
//         });
//       }
//       if (toRemove) {
//         toRemove.map((groupId, i) => {
//           console.log(
//             "groupId, existing ",
//             groupId,
//             existing,
//             dataProps.item.experience_memberships_groups
//           );
//           let group = _.find(dataProps.item.experience_memberships_groups, [
//             "memberable_id",
//             groupId,
//           ]);
//           experience_memberships_attributes.push({
//             id: group.id,
//             memberable_type: "group",
//             memberable_id: groupId,
//             archived: true,
//           });
//         });
//       }
//       if (toUnarchive) {
//         toUnarchive.map((groupId, i) => {
//           console.log(
//             "groupId, existing ",
//             groupId,
//             existing,
//             dataProps.item.experience_memberships_groups
//           );
//           let group = _.find(dataProps.item.experience_memberships_groups, [
//             "memberable_id",
//             groupId,
//           ]);
//           experience_memberships_attributes.push({
//             id: group.id,
//             memberable_type: "group",
//             memberable_id: groupId,
//             archived: false,
//           });
//         });
//       }

//       toCreate = null;
//       toRemove = null;
//       toUnarchive = null;
//       existing = null;
//       modified = null;
//       // create/update
//       // create if id exists in opts.values.group_teacher_ids but not in dataProps.item.group_users_teachers.pluck("user_id")
//       if (opts.values.experience_membership_student_ids) {
//         modified = opts.values.experience_membership_student_ids;
//       }
//       if (dataProps.item && dataProps.item.experience_memberships_students) {
//         existing = _.map(
//           dataProps.item.experience_memberships_students,
//           "memberable_id"
//         );
//       }

//       if (opts.values.settings.map_classes_or_students === "students") {
//         toCreate = _.difference(modified, existing);
//         toRemove = _.difference(existing, modified);
//         toUnarchive = _.intersection(existing, modified);
//       } else {
//         toRemove = _.union(existing, modified);
//         setFieldsValue({ experience_membership_student_ids: [] });
//       }

//       console.log("toCreate, toRemove ", toCreate, toRemove);
//       if (toCreate) {
//         toCreate.map((studentId, i) => {
//           experience_memberships_attributes.push({
//             memberable_type: "student",
//             memberable_id: studentId,
//           });
//         });
//       }
//       if (toRemove) {
//         toRemove.map((studentId, i) => {
//           console.log(
//             "studentId, existing ",
//             studentId,
//             existing,
//             dataProps.item.experience_memberships_students
//           );
//           let student = _.find(dataProps.item.experience_memberships_students, [
//             "memberable_id",
//             studentId,
//           ]);
//           experience_memberships_attributes.push({
//             id: student.id,
//             memberable_type: "student",
//             memberable_id: studentId,
//             archived: true,
//           });
//         });
//       }
//       if (toUnarchive) {
//         toUnarchive.map((studentId, i) => {
//           console.log(
//             "studentId, existing ",
//             studentId,
//             existing,
//             dataProps.item.experience_memberships_students
//           );
//           let student = _.find(dataProps.item.experience_memberships_students, [
//             "memberable_id",
//             studentId,
//           ]);
//           experience_memberships_attributes.push({
//             id: student.id,
//             memberable_type: "student",
//             memberable_id: studentId,
//             archived: false,
//           });
//         });
//       }

//       // if(opts.values.experience_membership_collaborator_ids){
//       //   opts.values.experience_membership_collaborator_ids.map((userId, i) => {
//       //     experience_memberships_attributes.push({
//       //       memberable_type: "collaborator",
//       //       memberable_id: userId,
//       //     })
//       //   })
//       // }

//       opts.values.experience_memberships_attributes = experience_memberships_attributes;

//       // const newOptsValues = {id: opts.values.id, experience_memberships_attributes: experience_memberships_attributes, settings: opts.values.settings}
//       const newOptsValues = {
//         uid: opts.values.uid,
//         experience_memberships_attributes: experience_memberships_attributes,
//         settings: opts.values.settings,
//       };
//       return newOptsValues;
//     },
//     schema: {
//       type: "object",
//       properties: {
//         settings: {
//           type: "object",
//           properties: {
//             map_classes_or_students: {
//               type: "string",
//               widget: "RadioGroupWidget",
//               default: "students",
//               span: 24,
//               widgetConfig: {
//                 options: [
//                   {
//                     label: mrIntl("CommonText.select_classes"),
//                     value: "groups",
//                   },
//                   {
//                     label: mrIntl("CommonText.select_students"),
//                     value: "students",
//                   },
//                 ],
//                 disabled: formValues.status !== "draft" ? true : false,
//                 optionType: "button",
//                 buttonStyle: "solid",
//               },
//             },
//           },
//         },
//         experience_membership_group_ids: {
//           title: mrIntl("CommonText.select_classes"),
//           type: "string",
//           span: 24,
//           // placeholder: "Select classes",
//           // widget: SelectGroups,
//           dependsOn: ["settings", "map_classes_or_students"],
//           children: EMGIdsChildComponent,
//         },
//         experience_membership_student_ids: {
//           title: mrIntl("CommonText.select_students"),
//           type: "string",
//           span: 24,
//           // placeholder: "Select students",
//           // widget: SelectStudents,
//           dependsOn: ["settings", "map_classes_or_students"],
//           children: EMSIdsChildComponent,
//         },
//         // settings: {
//         //   type: "object",
//         //   properties: {
//         //     allow_guests: {
//         //       type: "string",
//         //       span: 12,
//         //       title: "Allow access via join link",
//         //       widget: "CheckboxWidget",
//         //     },
//         //   }
//         // }

//         // experience_membership_collaborator_ids: {
//         //   type: "string",
//         //   title: "Select students",
//         //   default: [],
//         //   span: 24,
//         //   widget: SelectTeachers,
//         // },
//       },
//     },
//   };
//   return form;
// };

// const setDefaultParams = (props) => {
//   console.log("setDefaultParams props", props)
//   if(props.currentUser === "admin" || props.currentUser === "teacher" ){
//     return {by_org_programme_id: getFromLS("activeOrgProgrammeId")}
//   }
//   else{
//     return {}
//   }
// }

const getForms = (props) => {
  console.log("getForms =====>", props);
  // const { mrIntl } = props // IMP: issue in translation with mrIntl here - APL-3409, not even on refresh
  const { intl } = props
  const mrIntl = mrTranslate(intl); // with this it works on refresh atleast

  const forms = [
    {
      name: "experiences",
      form: returnForm,
      config: {
        // title: { show: true, value: "assessment" },
        processInitialValues: processExperienceCreateFormInitialValues,
        disablePrompt: true,
        title: { show: true, createValue: mrIntl("Experiences.create_assessment"), editValue: mrIntl("Experiences.edit_assessment") },
        submitConfig: {
          success: {
            showMessage: true,
            message: mrIntl("CommonText.assessment_saved_successfully"), //need check
          },
          error: { showMessage: true, message: mrIntl("CommonText.error_in_saving_assessment") },
        },
        isModal: true,
        visible: true,
        actions: {
          submitText: mrIntl("CommonText.save"),
          //  submitText: "Save", 
           showCancelBtn: false },
      },
    },
    {
      name: "experiencesSettings",
      form: returnSettingsForm,
      config: { processInitialValues: processSettingsFormInitialValues },
    },
    {
      name: "experiencesCollaborators",
      form: returnCollaboratorsForm,
      config: { isModal: true },
    },
    {
      name: "experiencesInvigilators",
      form: returnInvigilatorsForm,
      config: { isModal: true },
    },
    // { name: "experiencesStudents", form: returnStudentsForm },
  ]
  return forms
}

const Experiences = MrCrudView({
  resourceName: "experiences",
  singleResourceName: "experience",
  // displayName: "Create/Edit Tests",
  resourceUrl: "/experiences",
  // layout: OrgsLayout,
  filter: ExperiencesFilter,
  // listHeader: ExperienceHeader,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
    classesForMultiselect: groupsSelector,
    experiences: experiencesSelector,
    subjects: orgSubjectsSelector,
    partnerName: partnerNameSelector,
    enabledFeatures: enabledFeaturesSelector,
    // appType: appTypeSelector
  },
  listItem: ExperienceListItem,
  forms: getForms,
  actions,
  actionNames: ["fetch", "create", "show", "update", "duplicate", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: ExperienceListItem,
    },
    itemDetail: {
      // widget: ExperienceItemDetail
      widget: ExperienceItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      style: {
        width: 6,
        align: "right"
      },
      config: {
        search: {
          show: false // default hide in filter render
        },
        create: {
          show: true
        }
      }
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      layout: CustomExperienceCrudList,
      // layout: (props) => {
      //   console.log("custom layout props", props);
      //   return "hello"
      // },
      forceReload: true,
      widget: ExperienceList,
      // forceReload: true
      // params: setDefaultParams
    },
    uuid: {
      active: true,
      key: "uid",
    },
  },
});

// Experiences.defaultProps = {};

Experiences.propTypes = {};
export default Experiences;
