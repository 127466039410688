import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";

const DroppableBoxContainer = styled.div`
  display: inline-block;
  border-radius: 2px;
  // border: 1px solid lightgrey;
  border: 1px dashed lightgrey;
  padding: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  transition: background-color 0.2s ease;
  min-width: 100px;
  // max-width: 400px;
  min-height: 100px;
  vertical-align: middle;
  // width: 100%;
  background-color: ${(props) => (props.isOver ? "skyblue" : "white")};
  // flex-grow: 1;
`;

export const RDDroppableBox = (props) => {
  console.log("RDDroppableBox props ==>", props);
  const { index, item, accept, draggable, handleDrop } = props;
  const [{ canDrop, isOver }, drop] = useDrop({
    // The type (or types) to accept - strings or symbols
    accept: accept || "BOX",
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(_item, monitor) {
      handleDrop(_item, item)
      return undefined
    }
  });

  return (
    <DroppableBoxContainer
      ref={drop}
      role={"Dustbin"}
      isOver={isOver}
      // style={{ backgroundColor: isOver ? 'red' : 'white' }}
      {...props}
    >
      {draggable}
    </DroppableBoxContainer>
  );
};
