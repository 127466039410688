import React, { useState } from "react";
import { Layout, Alert, Button, Modal, Row, Space } from "antd";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const HowToFixBtn = (props) => {
  const [visible, setVisible] = useState(false);
  const mrIntl = useTranslate();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <>
      <Button type="default" onClick={showModal}>
        How to Fix
      </Button>
      <Modal
        // title="Assessment details"
        open={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        wrapClassName="experience-info-modal"
      >
        <Row className={"m-t-15"}>
          <Space>
            <span>
              {mrIntl(
                "ExperienceLayout.it_seems_you_are_not_connected_to_the_internet"
              )}
              <br />
              <b>{mrIntl("NoConnectivity.possible_causes")}</b>
              <ul>
                <li>{mrIntl("NoConnectivity.your_device_not_connected")}</li>
                <li>{mrIntl("NoConnectivity.you_have_network_monitoring")}</li>
              </ul>
              <b>{mrIntl("NoConnectivity.suggested_solutions")}</b>
              <ul>
                <li>{mrIntl("NoConnectivity.check_internet_connection")}</li>
                <li>{mrIntl("NoConnectivity.you_have_network_monitoring")}</li>
                <ul>
                  <li>{mrIntl("NoConnectivity.temporarily_disabling")}</li>
                  <li>{mrIntl("NoConnectivity.create_new_user_profile")}</li>
                </ul>
              </ul>
            </span>
          </Space>
        </Row>
      </Modal>
    </>
  );
};

export default HowToFixBtn;
