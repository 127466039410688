import React from "react";
import PropTypes from 'prop-types';
 
import { MrCrudView, MrForm as WrappedMrForm , formatJsonApiData} from "mr_react_framework";
import { actions } from "./redux";
import OrgProgrammeListItem from "./OrgProgrammeListItem/OrgProgrammeListItem";
import OrgProgrammesFilter from "./OrgProgrammesFilter/OrgProgrammesFilter";
import OrgProgrammeList from "./OrgProgrammeList/OrgProgrammeList";
import OrgProgrammeItemDetail from "./OrgProgrammeItemDetail/OrgProgrammeItemDetail";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors  from "/src/App/commonSelectors";
import SubjectSelector from "./SubjectSelector";
import Input from "/src/components/UI/Input/Input";
import MrTranslate, { mrTranslate } from "/src/lib/MrTranslate/MrTranslate";

// NOT BEING USED
export const returnForm = (props) => {
  // console.log("org programme form props", props);
  // let form = {
  //   schema: {
  //     type: "object",
  //     properties: {
  //       name: {
  //         label: "Name",
  //         type: "string",
  //       },
  //       abbr: {
  //         label: "Abbr",
  //         type: "string",
  //       },
  //       subjects: {
  //         label: "Subjects",
  //         type: "string",
  //         widget: SubjectSelector,
  //         // widget: (componentProps) => {
  //         //   // return <SelectGroup config={{id: componentProps.item.id}}/>
  //         //   return <SubjectSelector props={props} programmeId={props.formState.values.programme_id}/>
  //         // },
  //         widgetConfig: {
  //           isMB: props.isMB,
  //           programmeId: props.formState.values.programme_id,
  //         }
  //       },
  //       grades: {
  //         label: "Grades",
  //         type: "string",
  //       },
  //     },
  //   },
    
  // };
  // return form;
  return {}
};

const processProgrammeFormInitialValues = (data) => {
  console.log( "initial data", data );
  let newData = {...data}
  if(data.subjects_attributes_for_static){
    let subjectsAttributes = formatJsonApiData(data.subjects_attributes_for_static)
    newData = {
      ...newData,
      custom_subjects: subjectsAttributes
    }
    newData.custom_subjects.forEach(item => {
      let childrenAttributes = item.children;
      item.children_attributes = childrenAttributes
    })
  }
  return newData;
}

// export const processOrgProgramForCustomSubjects = (data) => {
//   console.log( "initial data", data );
//   let newData = {...data};
//   // let newData = {
//   //   ...data,
//   //   nestedValues: {
//   //     ...data.nestedValues,
//   //     tempNested: "tempNested updated"
//   //   },
//   //   name: "hello1"
//   // }
//   // data = nestedValues
//   if(data.subjects_attributes_for_static){
//     let subjectsAttributes = formatJsonApiData(data.subjects_attributes_for_static)
//     newData.subjects = subjectsAttributes
//     newData.subjects.forEach(item => {
//       let childrenAttributes = item.children;
//       item.children_attributes = childrenAttributes
//     })
//   }
//   return newData;
// }

export const returnCustomSubjectForm = (props) => {
  console.log("returnSubjectForm props", props);
  // in case of MB integration and one roster 
  const readOnly = props.formState.values.settings.mb_integration_enabled || props.org.is_one_roster || props.org.is_edlink;
  console.log('mb integration==>', readOnly);
  const { mrIntl } = props;

  let form = {
    processFormData: (dataProps, opts) => {
      console.log('opts.values==>', opts, dataProps);
      const orgProgrammeId = opts.values.id
      opts.values.custom_subjects.forEach((item) => {
        item.type_c = "group"
        item.org_programme_id = orgProgrammeId;
        item.name = item.label;  // Bulk import happens on the basis of subject name
        if (!item.id) {
          item.code = item.label
        }
        item.children_attributes && item.children_attributes.forEach((child) => {
          child.type_c = "subject"
          child.org_programme_id = orgProgrammeId
          child.name = child.label;  // Bulk import happens on the basis of subject name
          if (!child.id) {
            child.code = child.label
          }
        });
      });
      
      opts.values.subjects_attributes = opts.values.custom_subjects;
      console.log("opts.values.custom_subjects", opts.values);

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        custom_subjects:  {
          type: "array",
          addButtonTitle: `+ ${mrIntl("OrgProgrammes.add_new_subject_group")}`,
          // addButtonTitle: "+ Add new subject group",
          span: 24,
          min: 0,
          title: mrIntl("CommonText.subject"),
          // title: "Subjects", 
          max: readOnly ? -1 : 100, // when we are setting 0 then add button is not removed if length one  
          items: {
            type: "object",
            properties: {
              // name: {
              //   type: "string",
              //   title: "Group name",
              //   span: 6,
              //   rules: [
              //     {
              //       required: true,
              //       message: "Required",
              //     },
              //   ],
              // },
              label: {
                type: "string",
                title: mrIntl("OrgProgrammes.subject_group_name"),
                // title: "Subject group name",
                span: 12,
                widgetConfig: {
                  // disabled: (props) => {
                  //   console.log("props in disabled ==>", props)
                  //   return props.sourceValues.settings.mb_integration_enabled
                  // },
                  disabled: readOnly
                },
                rules: [
                  {
                    required: true,
                    message: mrIntl("CommonText.required_msg")
                    // message: "Required",
                  },
                ],
              },
              // code: {
              //   type: "string",
              //   title: "Code",
              //   // default: "2020-2021"
              //   span: 6,
              //   rules: [
              //     {
              //       required: true,
              //       message: "Required",
              //     },
              //   ],
              // },
              // type_c: {
              //   type: "string",
              //   title: "Type",
              //   default: "group",
              //   span: 6,
              //   widget: "SelectWidget",
              //   default: "group",
              //   wrapClassName: "hidden",
              //   widgetConfig: {
              //     options: [
              //       { label: "group", value: "group" },
              //       // { label: "subject", value: "subject" },
              //     ],
              //   },
              //   rules: [
              //     {
              //       required: true,
              //       message: "Required",
              //     },
              //   ],
              // },
              children_attributes: {
                type: "array",
                addButtonTitle: `+ ${mrIntl("CommonText.add_new_subject")}`,
                // addButtonTitle: "+ Add new subject",
                span: 24,
                min: 0,
                max: readOnly ? -1 : 100, // when we are setting 0 then add button is not removed if length one
                items: {
                  type: "object",
                  properties: {
                    // name: {
                    //   type: "string",
                    //   title: "Subject name",
                    //   span: 6,
                    //   rules: [
                    //     {
                    //       required: true,
                    //       message: "Required",
                    //     },
                    //   ],
                    // },
                    label: {
                      type: "string",
                      title: mrIntl("CommonText.subject_name"),
                      // title: "Subject name",
                      span: 12,
                      widgetConfig: {
                        // disabled: (props) => {
                        //   console.log("props in disabled ==> nested", props)
                        //   return props.sourceValues.settings.mb_integration_enabled
                        // },
                        disabled: readOnly
                      },
                      rules: [
                        {
                          required: true,
                          message: mrIntl("CommonText.required_msg"),
                          // message: "Required",
                        },
                      ],
                    },
                    // code: {
                    //   type: "string",
                    //   title: "Code",
                    //   // default: "2020-2021"
                    //   span: 6,
                    //   // offset: 2,
                    //   rules: [
                    //     {
                    //       required: true,
                    //       message: "Required",
                    //     },
                    //   ],
                    // },
                    // type_c: {
                    //   type: "string",
                    //   title: "Type",
                    //   default: "subject",
                    //   span: 6,
                    //   // offset: 2,
                    //   widget: "SelectWidget",
                    //   default: "subject",
                    //   wrapClassName: "hidden",
                    //   widgetConfig: {
                    //     options: [
                    //       // { label: "group", value: "group" },
                    //       { label: "subject", value: "subject" },
                    //     ],
                    //   },
                    //   rules: [
                    //     {
                    //       required: true,
                    //       message: "Required",
                    //     },
                    //   ],
                    // },
                  },
                },
              },
            },
          },
        },
        // grades: {
        //   label: "Grades",
        //   type: "string",
        // },
      },
    },
  };
  return form;
};


export const returnSubjectsForm = (props) => {
  console.log("returnSubjectForm props", props)
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      // process and set values and then return changed values
      // opts.values["custom_field"] = "custom_value";
      console.log("opts", opts);
      opts.values.subjects_attributes = opts.values.subjects
      console.log("opts.values", opts.values);

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        subjects: {
          // label: "Subjects",
          type: "string",
          // widget: (componentProps) => {
          //   return <SubjectSelector componentProps={componentProps} programmeId={props.formState.values.programme_id} isMB={props.formState.values.settings.mb_integration_enabled}  />
          // }
          widget: SubjectSelector,
          widgetConfig: {
            isMB: props.formState.values.settings.mb_integration_enabled,
            programmeId: props.formState.values.programme_id,
          }
        },
        // grades: {
        //   label: "Grades",
        //   type: "string",
        // },
      },
    },
    
  };
  return form;
};

export const returnGradesForm = (props) => {
  console.log("returnGradesForm props", props)
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      // process and set values and then return changed values
      // opts.values["custom_field"] = "custom_value";
      console.log("opts", opts);
      opts.values.grades_attributes = opts.values.grades
      console.log("opts.values", opts.values);

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        grades: {
          // label: "Grades",
          // type: "string",
          // widget: (componentProps) => <h1>Grade label change form</h1>,
          type: "array",
          span: 24,
          min: 0,
          max: 1,
          title: <MrTranslate id = {"CommonText.grades"}/>,
          // title: "Grades",
          items: {
            type: "object",
            properties: {
              name: {
                type: "string",
                title: <MrTranslate id = {"CommonText.name"}/>,
                // title: "Name",
                // placeholder: "Name",
                span: 6,
                widgetConfig: {
                  disabled: true
                }
              },
              label: {
                type: "string",
                title: <MrTranslate id = {"CommonText.label"}/>,
                // title: "Label",
                span: 6,
                widgetConfig: {
                  // disabled: props.org.is_mb
                  disabled: props.formState.values.settings.mb_integration_enabled == true || props.org.is_one_roster || props.org.is_edlink
                }
                // default: "2020-2021"
              },
            },
            
          }

        },
      },
    },
    
  };
  return form;
};


const OrgProgrammes = MrCrudView({
  resourceName: "orgProgrammes",
  singleResourceName: "org_programme",
  // displayName: "resourceName",
  resourceUrl: "/org_programmes",
  // layout: OrgsLayout,
  filter: OrgProgrammesFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  // listItem: OrgProgrammeListItem,
  forms: [{ name: "orgProgrammes", form: returnForm }, { name: "orgProgrammesSubjects", form: returnSubjectsForm }, { name: "orgProgrammesGrades", form: returnGradesForm }, {name: "customSubjectForm", form: returnCustomSubjectForm, config: {processInitialValues: processProgrammeFormInitialValues, }}],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    routes:{
      show: false
    },
    itemPreview: {
      widget: OrgProgrammeListItem
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: OrgProgrammeItemDetail
    },
    pagination: {
      show: false,
    },
    filter: {
      show: false,
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      widget: OrgProgrammeList,
      forceReload: true

    }
  },
});

// OrgProgrammes.defaultProps = {};

OrgProgrammes.propTypes = {};
export default OrgProgrammes;
