import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { useDispatch } from "react-redux";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";

const RegionSelector = (props) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  // const defaultSelectedRegion = "global"
  const defaultSelectedRegion = import.meta.env.VITE_REGION;
  const [selectedRegion, setSelectedRegion] = useState(defaultSelectedRegion);
  let globalUrl, chinaUrl;
  const [loading, setLoading] = useState(false);

  if (import.meta.env.VITE_REGION === "global") {
    globalUrl = import.meta.env.VITE_WEB_URL;
    chinaUrl = globalUrl.split(".com")[0] + ".cn";
  } else if (import.meta.env.VITE_REGION === "china") {
    chinaUrl = import.meta.env.VITE_WEB_URL;
    globalUrl = chinaUrl.split(".cn")[0] + ".com";
  }

  useEffect(() => {
    // let savedSelectedRegion = getSelectedRegionFromPreferences()
    // console.log("RegionSelector savedSelectedRegion", savedSelectedRegion)
    console.log("RegionSelector defaultSelectedRegion", defaultSelectedRegion);

    // Do it in native - here it loads entire page and then reloads
    // if(savedSelectedRegion == "cn"){
    //   window.location.href = chinaUrl
    // }

    // if(savedSelectedRegion != import.meta.env.VITE_REGION){
    setSelectedRegionInPreferences(import.meta.env.VITE_REGION);
    // }
  }, []);

  const getSelectedRegionFromPreferences = () => {
    console.log("getSelectedRegionFromPreferences");
    // return "global"
    // return "china"
    // TODO
    // return dispatch(offlineAppActions.executeMethod({key: "getCountryName", value: region}));
  };
  const setSelectedRegionInPreferences = (region) => {
    console.log("setSelectedRegionInPreferences region", region);
    dispatch(
      offlineAppActions.executeMethod({ key: "setCountryName", value: region })
    );
    // TODO: add to mapped functions
  };

  const changeRegion = (value) => {
    if (value === "china") {
      window.location.href = chinaUrl;
    } else {
      window.location.href = globalUrl;
    }
    setLoading(true)
    console.log("changeRegion", value);
  };

  let regionSelectorSelect = (
    <Select
      defaultValue={defaultSelectedRegion}
      style={{ width: 150, marginTop: 15 }}
      onChange={changeRegion}
      loading={loading}
    >
      <Option value="global">Global</Option>
      <Option value="china">Mainland China</Option>
    </Select>
  );

  // let regionSelectorSwitch = <Space className={"m-t-15"}><label>WorldWide</label><Switch size={"large"} checked={selectedRegion == "mainland_china"} onChange={(checked, e) => changeRegion(checked, e)}/> <label>Mainland China</label></Space>

  return regionSelectorSelect;
};

export default RegionSelector;
