import { createSelector } from "reselect";
// import lodash from "lodash";

const jwtState = (state, props) => {
  return state.embeddedResources.get("jwt");
};

export const jwtSelector = () =>
  createSelector([jwtState,], jwt => jwt);

const activeEmbeddedResourceState = (state, props) => {
  return state.embeddedResources.get("activeEmbeddedResource", {});
};

export const activeEmbeddedResourceSelector = () =>
  createSelector([activeEmbeddedResourceState,], activeEmbeddedResource => activeEmbeddedResource);

const findloadingState = (state, props) => {
  return state.embeddedResources.get("findloading");
};
export const findloadingSelector = () =>
  createSelector([findloadingState], (findloading) => findloading);

const loadingState = (state, props) => {
  return state.embeddedResources.get("findloading");
};
export const loadingSelector = () =>
  createSelector([loadingState], (loading) => loading);


const errorState = (state, props) => state.embeddedResources.get("error");

export const errorSelector = () =>
  createSelector([errorState], (error) => error);

// const embeddedResourcesState = (state, props) =>
//   state.embeddedResources.get("embedded_resources");

// export const embeddedResourcesSelector = () =>
//   createSelector([embeddedResourcesState], (embeddedResources) => embeddedResources);

// const embeddedResourcesShowState = (state, props) => {

//   const embeddedResources = state.embeddedResources.get("embedded_resources");

//   if (embeddedResources.size === 0) {
//     return {};
//   } else {
//     const id = parseInt(props.match.params.id);
//     const idx = lodash.findIndex(embeddedResources, {
//       id: id,
//     });
//     if (idx !== -1) {
//       return embeddedResources[idx];
//     }
//     return {};
//   }
// };

// export const embeddedResourcesShowSelector = () =>
//   createSelector([embeddedResourcesShowState], (embeddedResources) => embeddedResources);

// const paginationState = (state, props) => {
//   const pageObj = {};
//   pageObj.page = state.embeddedResources.get("page");
//   pageObj.totalPages = state.embeddedResources.get("total_pages");

//   return pageObj;
// };

// export const paginationSelector = () =>
//   createSelector([paginationState], (pageObj) => pageObj);
