import React, { useEffect, useState } from "react";

import { Tabs, Card, Row, Col, Input, Space } from "antd";
import { DeleteFilled } from '@ant-design/icons'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { removeItemFromArray, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { Segment, SegmentData, TabAddIcon, CustomTextButton, CustomLabel, showConfirmModal, CustomInput } from '/src/components/UI/Segment/UIHelper'
import { cloneDeep, shuffle } from 'lodash';
import SegmentItemFooter from '/src/views/Segments/SegmentItemDetail/SegmentItemFooter'
import SegmentItemHeader from '/src/views/Segments/SegmentItemDetail/SegmentItemHeader'
import MCQSingle from "/src/views/Segments/MCQ/MCQSingle";
import MCQMultiple from "/src/views/Segments/MCQ/MCQMultiple";
import CKESubjective from "/src/views/Segments/CKESubjective/CKESubjective";
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent";
import TextAnswerExplanation from "/src/views/Segments/Explanation/TextAnswerExplanation"

import { getFinalStudentJSON } from "../InteractiveHelpers"
import { DraggableTabs } from "/src/components/UI/DnD/DraggableTabs"
import MrSelect from "/src/components/UI/MrSelect/MrSelect";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

export const getMiniQuizStudentJSON = (json) => {
  let student_json = cloneDeep(json)

  return student_json
  // let qsa = props.formInstance.getFieldValue(["question_segment_attributes"])
  // qsa.student_json = student_json
  // props.formInstance.setFieldsValue({ 'question_segment_attributes': qsa })
  // console.log('forminstance get field value ==>', props.formInstance.getFieldValue(["question_segment_attributes"]))
}

const MiniQuiz = props => {
  console.log("Mini Quiz props", props);
  const { TabPane } = Tabs;
  const { question_type, showCorrectAnswerSwitch, segmentSettings, triggerStateUpdate, setRenderMath } = props
  let { parsedJSONObject, createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)
  const mrIntl = useTranslate()
  let textAnswerString = props.text_answer || ''
  const propsAttemptStatus = props.attempt_status || {}
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  }

  if (createMode) {
    parsedJSONObject = {
      segment_data:
      {
        questions: [{
          id: getUniqueId(),
          content: '',
          question_type: 'mcq_single',
          // teacher_json: '',
          // student_json: '',

        }]
      },
      segment_version: '1.0.0'
    };
    if (question_type == 'mini_quiz') {
      parsedJSONObject = props.value || parsedJSONObject
    }
  }

  if (answerMode) {
    parsedJSONObject = shuffleItems(parsedJSONObject)
  }

  if (autoCheckMode) {
    let question_json = cloneDeep(props.teacher_json)
    let answer_json = cloneDeep(parsedJSONObject)
    parsedJSONObject = checkResponses(question_json, answer_json)
  }

  const [state, setState] = useState(parsedJSONObject)
  const [textAnswer, setTextAnswer] = useState(textAnswerString)
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject)
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
    showCorrectAnswerSwitchValue: false,
  })
  const screenSettings = props.screenSettings || {}

  let currentJSON, text_answer, attempt_status
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject
    text_answer = textAnswerString
    attempt_status = attemptStatusObject
  } else {
    currentJSON = state
    text_answer = textAnswer
    attempt_status = attemptStatus
  }
  const segment_data = currentJSON?.segment_data || {}
  const questions = segment_data.questions || []

  const [tabSetting, setTabSetting] = useState({
    activeKey: questions[0].id.toString()
  })

  const initialMiniQuizSetting = {
    currentQuestionIndex: 0,
    currentQuestion: questions[0],
    questionsCount: questions.length,
    correctQuestionsCount: 0,
    attemptedAll: false,
  }
  const [miniQuizSetting, setMiniQuizSetting] = useState(initialMiniQuizSetting)

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }, [])

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state)
        props.onChange(teacher_json)
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus)
        }

        props.onChange(result)
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    setState(state)
    console.log("stateUpdated count ==>", stateUpdatedCount)
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
  }

  const saveTextAnswer = (data) => {
    setTextAnswer(data)
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
  }

  function checkResponses(question_json, answer_json) {
    console.log('Question and Answer JSON ==>', question_json, answer_json)
    if (!question_json || !answer_json) {
      return
    }

    return answer_json
  }

  // const checkAnswer = () => {
  //   let question_json = convertDataAsPerNewSystem(props.teacher_json, props)
  //   let answer_json = state
  //   const newState = checkResponses(question_json, answer_json)

  //   saveState(newState)
  //   setMyAnswer(state)
  //   setStateSetting({
  //     ...stateSetting,
  //     quickCheckModeEnabled: true,
  //   })
  // }

  // const [myAnswer, setMyAnswer] = useState(state)
  // const showCorrectAnswer = (value) => {
  //   console.log("Value in switch ==>", value)
  //   let question_json, answer_json
  //   if (value) {
  //     question_json = convertDataAsPerNewSystem(props.teacher_json, props)
  //     answer_json = convertDataAsPerNewSystem(props.teacher_json, props)
  //   } else {
  //     question_json = convertDataAsPerNewSystem(props.teacher_json, props)
  //     answer_json = cloneDeep(myAnswer)
  //   }

  //   const newState = checkResponses(question_json, answer_json)
  //   saveState(newState)
  //   setStateSetting({
  //     ...stateSetting,
  //     quickCheckModeEnabled: true,
  //   })
  // }

  // const resetQuestion = () => {
  //   console.log("Student json ===>", props.student_json)
  //   const newState = cloneDeep(convertDataAsPerNewSystem(props.student_json, props))
  //   saveState(newState)
  //   setStateSetting({
  //     ...stateSetting,
  //     quickCheckModeEnabled: false,
  //   })
  // }

  function shuffleItems(json) {
    if (segmentSettings.shuffle) {
      const segment_data = json.segment_data
      segment_data.questions = shuffle(segment_data.questions)
    }

    return json
  }

  const addQuestion = () => {
    const newQuestions = questions
    newQuestions.push({
      id: getUniqueId(),
      content: '',
      question_type: 'mcq_single',
    })

    const newSegmentData = {
      ...segment_data,
      questions: newQuestions,
    }

    const newState = {
      ...state,
      segment_data: newSegmentData,
    }

    saveState(newState)
    setTabSetting({
      ...tabSetting,
      activeKey: newQuestions[newQuestions.length - 1].id.toString()
    })
  }

  const removeQuestion = (index) => {
    console.log("Remove index ==>", index)
    const newQuestions = removeItemFromArray(questions, index);

    if (!newQuestions) {
      return
    }

    const newSegmentData = {
      ...segment_data,
      questions: newQuestions
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    console.log("Updated state after remove ==>", newState)

    saveState(newState)

    const newQuestionsCount = newQuestions.length
    let activeKey
    if (index >= newQuestionsCount)
      activeKey = newQuestions[newQuestionsCount - 1].id.toString()
    else {
      activeKey = newQuestions[index].id.toString()
    }
    setTabSetting({
      ...tabSetting,
      activeKey: activeKey
    })
  }

  const onTabEdit = (targetKey, action) => {
    if (action == 'add') {
      addQuestion()
    } else if (action == 'remove') {
      const index = questions.findIndex((question) => question.id == targetKey)
      showConfirmModal({
        title: mrIntl("MiniQuiz.are_you_sure_to_remove_this_question_title"),
        onOk: () => {
          removeQuestion(index)
        },
        mrIntl: mrIntl
      })
    }
  }

  const onTabChange = (targetKey) => {
    setTabSetting({
      ...tabSetting,
      activeKey: targetKey
    })
  }

  const onTabDragEnd = (questions) => {
    const newQuestions = questions.slice()

    const newSegmentData = {
      ...segment_data,
      questions: newQuestions
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const setProperty = (type, index, value) => {
    const newQuestions = questions
    newQuestions[index][type] = value

    const newSegmentData = {
      ...segment_data,
      questions: newQuestions,
    }

    const newState = {
      ...state,
      segment_data: newSegmentData,
    }

    saveState(newState)
  }

  const saveQuestion = (index, value) => {
    const newQuestions = questions
    newQuestions[index].teacher_json = value
    newQuestions[index].student_json = getFinalStudentJSON(newQuestions[index].question_type, value)

    const newSegmentData = {
      ...segment_data,
      questions: newQuestions,
    }

    const newState = {
      ...state,
      segment_data: newSegmentData,
    }

    saveState(newState)
  }

  const saveAnswer = (index, result) => {
    console.log("result in miniquiz =>", result)
    const newQuestions = questions
    newQuestions[index].response_json = result.response_json

    const newSegmentData = {
      ...segment_data,
      questions: newQuestions,
    }

    const newState = {
      ...state,
      segment_data: newSegmentData,
    }

    saveState(newState)

    setMiniQuizSetting({
      ...miniQuizSetting,
      currentQuestion: {
        ...miniQuizSetting.currentQuestion,
        response_json: result.response_json
      }
    })
  }

  const showQuestion = (switchTo) => {
    let nextIndex, nextQuestion, attemptedAll, correctQuestionsCount = miniQuizSetting.correctQuestionsCount
    if (switchTo == 'prev') {
      attemptedAll = false
      nextIndex = miniQuizSetting.currentQuestionIndex - 1
      nextQuestion = questions[nextIndex]
    } else if (switchTo == 'next') {
      nextIndex = miniQuizSetting.currentQuestionIndex + 1
      if (nextIndex >= miniQuizSetting.questionsCount) {
        attemptedAll = true
      } else {
        attemptedAll = false
        nextQuestion = questions[nextIndex]
      }
    } else if (switchTo == 'reset') {
      const questions = resetQuiz()
      attemptedAll = false
      nextIndex = 0
      nextQuestion = questions[nextIndex]
      correctQuestionsCount = 0
      console.log("question after reset ==>", nextQuestion, questions[nextIndex], miniQuizSetting)
    }

    // attemptedAll = attemptedAll && miniQuizSetting.currentQuestion.question_type == 'mcq_multiple'
    if (attemptedAll) {
      correctQuestionsCount = 0
      questions.forEach((question) => {
        if (question.grade_json && question.grade_json.all_correct) {
          correctQuestionsCount += 1
        }
      })
    }
    setMiniQuizSetting({
      ...miniQuizSetting,
      switchAllowed: false,
      attemptedAll: attemptedAll,
      currentQuestionIndex: nextIndex,
      correctQuestionsCount: correctQuestionsCount,
      currentQuestion: {
        ...nextQuestion
      }
    })
  }

  const resetQuiz = () => {
    let student_json = cloneDeep(props.student_json)
    const newState = shuffleItems(student_json)
    saveState(newState)

    const questions = newState.segment_data.questions
    return questions
  }

  const onAutoCheck = (index, newGradeSetting) => {
    console.log("grade after Auto check correct status ==>", index, newGradeSetting)
    // const oldGradeSetting = questions[index].grade_json || {}

    // let correctQuestionsCount = miniQuizSetting.correctQuestionsCount
    // if (newGradeSetting.all_correct && !oldGradeSetting.all_correct) {
    //   correctQuestionsCount = miniQuizSetting.correctQuestionsCount + 1
    // } else if (!newGradeSetting.all_correct && oldGradeSetting.all_correct) {
    //   correctQuestionsCount = miniQuizSetting.correctQuestionsCount - 1
    // }

    // console.log("grade after autocheck ==>", oldGradeSetting, newGradeSetting, miniQuizSetting)
    setMiniQuizSetting({
      ...miniQuizSetting,
      switchAllowed: true,
    })

    const newQuestions = questions
    newQuestions[index].grade_json = newGradeSetting

    const newSegmentData = {
      ...segment_data,
      questions: newQuestions,
    }

    const newState = {
      ...state,
      segment_data: newSegmentData,
    }

    saveState(newState)
  }

  const onTryAgain = (index) => {
    let newMiniQuizSettings = {
      ...miniQuizSetting,
      switchAllowed: false,
    }

    const oldGradeSetting = questions[index].grade_json || {}
    if (oldGradeSetting.all_correct) {
      newMiniQuizSettings = {
        ...newMiniQuizSettings,
        correctQuestionsCount: miniQuizSetting.correctQuestionsCount - 1
      }
    }
    setMiniQuizSetting(newMiniQuizSettings)
  }

  console.log("Mini quiz setting ==>", miniQuizSetting)
  console.log('Mini Quiz State before render ==>', state, questions, stateSetting)
  return (
    <Segment>
      {/* <SegmentItemHeader
				segmentStateSettings={stateSetting}
				showCorrectAnswer={(value) => showCorrectAnswer(value)}
			>
			</SegmentItemHeader> */}
      {/* <CustomTextButton
            clicked={(e) => addQuestion('cke_subjective')}
            buttonText='Add Long answer'
          />
          <CustomTextButton
            clicked={(e) => addQuestion('mcq_multiple')}
            buttonText='Add MCQ multiple'
          /> */}
      <SegmentData>
        {createMode ? <div>
          <DraggableTabs
            addIcon={<TabAddIcon
              iconText= {mrIntl("CommonText.add")}
            ></TabAddIcon>}
            type="editable-card"
            tabPosition={'top'}
            activeKey={tabSetting.activeKey}
            onEdit={onTabEdit}
            onChange={onTabChange}
            canDrag={true}
            order={questions}
            setOrder={onTabDragEnd}
            className="tabular-questions"
          >
            {questions.map((question, index) => (
              <TabPane
                key={question.id}
                // tab={`Question ${index + 1}`}
                tab={ mrIntl("MiniQuiz.question_tappan_title", {index: index + 1})}
                closeIcon={<DeleteFilled />}
              >
                <Space>
                  <MrSelect
                    config={{
                      style: { width: '200px' },
                      className: question.question_type && 'custom-success',
                      value: question.question_type,
                      onChange: (value) => setProperty('question_type', index, value),
                    }}
                    options={[
                      { label: mrIntl("CommonText.long_answer"), value: 'cke_subjective' },
                      { label: mrIntl("CommonText.mcq_single_label"), value: 'mcq_single' },
                      { label: mrIntl("CommonText.mcq_multiple"), value: 'mcq_multiple' },
                    ]}
                  >
                  </MrSelect>
                  <CustomLabel> {mrIntl("CommonText.points")} </CustomLabel>
                  <CustomInput
                    key='question-points'
                    style={{
                      width: '200px'
                    }}
                    className={question.points && 'custom-success'}
                    defaultValue={question.points}
                    onChange={(data) => setProperty('points', index, data)}
                  ></CustomInput>
                </Space>

                <div style={{ "marginTop": "20px" }}>
                  <CustomLabel> <MrTranslate id={"CommonText.content"}/> </CustomLabel>
                  {/* <CKETextContent
                    value={question.content}
                    onChange={(value) => setProperty('content', index, value)}
                  >
                  </CKETextContent> */}
                  <CKESubjective
                    createMode={true}
                    value={question.content}
                    onChange={(value) => setProperty('content', index, value)}
                  >
                  </CKESubjective>

                </div>

                {question.question_type == 'mcq_single' &&
                  <div style={{ "marginTop": "20px" }}>
                    <CustomLabel><MrTranslate id={"CommonText.answer_choices"}/></CustomLabel>
                    <MCQSingle
                      {...question}
                      createMode={true}
                      value={question.teacher_json}
                      onChange={(value) => saveQuestion(index, value)}
                    ></MCQSingle>
                  </div>}

                {question.question_type == 'mcq_multiple' &&
                  <div style={{ "marginTop": "20px" }}>
                    <CustomLabel><MrTranslate id={"CommonText.answer_choices"}/></CustomLabel>
                    <MCQMultiple
                      {...question}
                      createMode={true}
                      value={question.teacher_json}
                      onChange={(value) => saveQuestion(index, value)}
                    ></MCQMultiple>
                  </div>}

                <div style={{ "marginTop": "20px" }}>
                  <CustomLabel><MrTranslate id={"CommonText.explanation_title"}/></CustomLabel>
                  <TextAnswerExplanation
                    createMode={true}
                    value={question.answer_explanation}
                    onChange={(value) => setProperty('answer_explanation', index, value)}
                  >
                  </TextAnswerExplanation>
                </div>
              </TabPane>
            ))}
          </DraggableTabs>
        </div>
          :
          <Card>
            <Row>
              {!miniQuizSetting.attemptedAll ? <Col span={24}>
              {/* <h4>Question {miniQuizSetting.currentQuestionIndex + 1} of {miniQuizSetting.questionsCount}</h4> */}
              <h4>{mrIntl("MiniQuiz.question_mini_quiz_setting", {currentQuestionIndex: miniQuizSetting.currentQuestionIndex + 1, questionsCount: miniQuizSetting.questionsCount })}</h4>
                <br />
                <RenderHtml text={miniQuizSetting.currentQuestion.content}/>

                {miniQuizSetting.currentQuestion.question_type == 'cke_subjective' && <CKESubjective
                  key={`cke-subjective-${miniQuizSetting.currentQuestion.id}`}
                  {...miniQuizSetting.currentQuestion}
                  answerMode={props.answerMode}
                  presentationMode={props.presentationMode}
                  showCorrectAnswer={props.showCorrectAnswer}
                  showCorrectAnswerSwitch={props.showCorrectAnswerSwitch}
                  screenSettings={screenSettings}
                  onChange={(value) => saveAnswer(miniQuizSetting.currentQuestionIndex, value)}
                  segmentSettings={{
                    include_explanation: true,
                    mode: 'quick_check',
                  }}
                  segmentMode="quick_check"
                  segmentFooterAttributes={{
                    segmentMode: 'quick_check',
                    explanationViewMode: "",
                    answer_explanation: miniQuizSetting.currentQuestion.answer_explanation,
                    attachments_json: miniQuizSetting.currentQuestion.attachments_json || {}
                  }}
                  setRenderMath={setRenderMath}
                ></CKESubjective>}
                {miniQuizSetting.currentQuestion.question_type == 'mcq_single' && <MCQSingle
                  key={`mcq-single-${miniQuizSetting.currentQuestion.id}`}
                  {...miniQuizSetting.currentQuestion}
                  answerMode={props.answerMode}
                  autoCheckMode={props.autoCheckMode}
                  presentationMode={props.presentationMode}
                  showCorrectAnswer={props.showCorrectAnswer}
                  showCorrectAnswerSwitch={props.showCorrectAnswerSwitch}
                  screenSettings={screenSettings}
                  onChange={(answer) => saveAnswer(miniQuizSetting.currentQuestionIndex, answer)}
                  onAutoCheck={(gradeSetting) => onAutoCheck(miniQuizSetting.currentQuestionIndex, gradeSetting)}
                  onTryAgain={() => onTryAgain(miniQuizSetting.currentQuestionIndex)}
                  segmentSettings={{
                    include_explanation: true,
                    mode: 'quick_check',
                  }}
                  segmentMode="quick_check"
                  segmentFooterAttributes={{
                    segmentMode: 'quick_check',
                    explanationViewMode: "",
                    answer_explanation: miniQuizSetting.currentQuestion.answer_explanation,
                    attachments_json: miniQuizSetting.currentQuestion.attachments_json || {}
                  }}
                  setRenderMath={setRenderMath}
                ></MCQSingle>}
                {miniQuizSetting.currentQuestion.question_type == 'mcq_multiple' && <MCQMultiple
                  key={`mcq-multiple-${miniQuizSetting.currentQuestion.id}`}
                  {...miniQuizSetting.currentQuestion}
                  answerMode={props.answerMode}
                  autoCheckMode={props.autoCheckMode}
                  presentationMode={props.presentationMode}
                  showCorrectAnswer={props.showCorrectAnswer}
                  showCorrectAnswerSwitch={props.showCorrectAnswerSwitch}
                  screenSettings={screenSettings}
                  onChange={(answer) => saveAnswer(miniQuizSetting.currentQuestionIndex, answer)}
                  onAutoCheck={(gradeSetting) => onAutoCheck(miniQuizSetting.currentQuestionIndex, gradeSetting)}
                  onTryAgain={() => onTryAgain(miniQuizSetting.currentQuestionIndex)}
                  segmentSettings={{
                    include_explanation: true,
                    mode: 'quick_check',
                  }}
                  segmentMode="quick_check"
                  segmentFooterAttributes={{
                    segmentMode: 'quick_check',
                    explanationViewMode: "",
                    answer_explanation: miniQuizSetting.currentQuestion.answer_explanation,
                    attachments_json: miniQuizSetting.currentQuestion.attachments_json || {}
                  }}
                  setRenderMath={setRenderMath}
                ></MCQMultiple>}
              </Col> : <Col span={24}>
                <Col span={6}>
                {/* <h4>
                    Your quiz score
                    <br />
                    {miniQuizSetting.correctQuestionsCount} of {miniQuizSetting.questionsCount} Correct
                  </h4> */}
                  <h4>
                    {mrIntl("MiniQuiz.your_quiz_score", {correctQuestionsCount: miniQuizSetting.correctQuestionsCount, questionsCount: miniQuizSetting.questionsCount })}
                  </h4>
                </Col>
                <Col span={18}>
                  <TextAnswerExplanation
                    answer_explanation={props.answer_explanation}
                    segmentSettings={{
                      ...props.segmentSettings,
                      include_explanation: true,
                    }}
                    answerMode={props.answerMode}
                    presentationMode={props.presentationMode}
                    showCorrectAnswer={props.showCorrectAnswer}
                    screenSettings={screenSettings}
                    setRenderMath={setRenderMath}
                  ></TextAnswerExplanation>
                </Col>
              </Col>}
            </Row>
            {(miniQuizSetting.questionsCount > 1 || miniQuizSetting.currentQuestion.question_type != 'cke_subjective') && <Row style={{
              float: 'right',
            }}>
              <Col span={24} className="mini-quiz-controls">
                {(!miniQuizSetting.attemptedAll) && <CustomTextButton
                  clicked={(e) => showQuestion('prev')}
                  disabled={miniQuizSetting.currentQuestionIndex == 0 || (answerMode && !miniQuizSetting.switchAllowed && (miniQuizSetting.currentQuestion.question_type == 'mcq_multiple' || miniQuizSetting.currentQuestion.question_type == 'mcq_single'))}
                  buttonText={mrIntl("MiniQuiz.prev_btn")}
                ></CustomTextButton>}
                {(!miniQuizSetting.attemptedAll) && <CustomTextButton
                  clicked={(e) => showQuestion('next')}
                  buttonText={mrIntl("MiniQuiz.next_btn")}
                  disabled={(miniQuizSetting.currentQuestionIndex == miniQuizSetting.questionsCount - 1) && (!answerMode || miniQuizSetting.currentQuestion.question_type == 'cke_subjective') || (answerMode && !miniQuizSetting.switchAllowed && (miniQuizSetting.currentQuestion.question_type == 'mcq_multiple' || miniQuizSetting.currentQuestion.question_type == 'mcq_single'))}
                ></CustomTextButton>}
                {(miniQuizSetting.attemptedAll) && <CustomTextButton
                  clicked={(e) => showQuestion('reset')}
                  buttonText={mrIntl("MiniQuiz.reset")}
                // disabled={miniQuizSetting.currentQuestionIndex == (miniQuizSetting.questionsCount - 1)}
                >
                </CustomTextButton>}
              </Col>
            </Row>}
          </Card>
        }
      </SegmentData >
    </Segment >
  );
}
export default MiniQuiz;