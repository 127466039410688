import React, { useEffect, useState } from "react";

import { Tabs, Input, Radio, Button, Checkbox, Upload, Row, Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { InboxOutlined, UploadOutlined, SoundOutlined, PauseCircleOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { initialData, addItemToArray, removeItemFromArray, convertDataAsPerNewSystem, getParsedJSONObject, numaricalSort } from "/src/views/Segments/InteractiveHelpers";
import { AddButton, DeleteButton, CustomTextButton, CheckBox, CustomInput, InputArea, MCQArea, MCQList, TextArea, ShowText, ShowTextIndex, ShowTextContent, CustomErrorMessage } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { MrInput } from "mr_react_framework"
import { debounce } from 'lodash';
import MediaHoverButton from "/src/components/UI/Media/MediaHoverButton"
import MediaShow from "/src/components/UI/Media/MediaShow"
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { CKETextOptionTools } from "../CKEditor/CKEConfig";

export const DnDDistractors = props => {
  console.log("Distractors props", props);

  const { distractors, addDistractor, removeDistractor, onTextChanged, removeFile, onSaveMedia, removeItem } = props
  const mrIntl = useTranslate();
  return (
    <div>
      {addDistractor && <CustomTextButton
        clicked={(e) => addDistractor()}
        buttonText= {mrIntl("DnDDistractors.add_distractor")}
        // buttonText="Add distractor"
      >
      </CustomTextButton>}

      <Row>
        {distractors.map((item, index) => (
          <Col span={12} key={`col-${item.id}`}>
            <TextArea key={`text-area-${item.id}`}>
              <InputArea
                key={`input-area-${item.id}`}
                className={`singleline-input-area ${!(item.content || (item.files && item.files.length)) && 'custom-error'}`}
              >
                <BasicCKE
                  key={`basic-ck-${item.id}`}
                  itemId={item.id}
                  tools={CKETextOptionTools}
                  data={item.content}
                  placeholder={item.placeholder}
                  onTextChanged={(data) => onTextChanged('distractor', index, data)}
                >
                </BasicCKE>
                {item.files && <MediaShow
                  key={`media-show-${item.id}`}
                  file={item.files && item.files[0]}
                  removeFile={(e) => removeFile('distractor', index)}
                >
                </MediaShow>}
                <CustomErrorMessage className="error-message" message="Required"></CustomErrorMessage>
              </InputArea>
              <MediaHoverButton
                key={`media-hover-button-${item.id}`}
                onSaveMedia={(files) => onSaveMedia('distractor', index, files)}
              ></MediaHoverButton>
              <DeleteButton
                key={`delete-button-${item.id}`}
                clicked={(e) => removeDistractor(index)}
              >
                <DeleteOutlined />
              </DeleteButton>
            </TextArea>
          </Col>
        ))}
      </Row>
    </div>
  );
}