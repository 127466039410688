import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "antd";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import {
  getParsedJSONObject,
} from "/src/views/Segments/InteractiveHelpers";
import {
  Segment,
  SegmentData,
} from "/src/components/UI/Segment/UIHelper";
import { cloneDeep, shuffle } from "lodash";
import { getFinalStudentJSON } from "../InteractiveHelpers";
import SegmentItemDetail from "../SegmentItemDetail/SegmentItemDetail";
import GameStages from "../../../components/UI/Game/GameStages";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const CrypticHunt = (props) => {
  console.log("Cryptic Hunt props", props);
  const {
    segment,
    experience,
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings = {},
    triggerStateUpdate,
    setRenderMath,
    nestingLevel,
  } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);

  let textAnswerString = props.text_answer || "";
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  const propsGameStatus = props.game_status || {}
  
  let gameStatusObject = {
    score: propsGameStatus.score || 0,
    attempt_left: propsGameStatus.attempt_left || segmentSettings.max_attempt || 0,
    max_attempt: segmentSettings.max_attempt || 0,
    started: propsGameStatus.started || false,
  }

  // if (createMode) {
  //   parsedJSONObject = {
  //     segment_data:
  //     {
  //       questions: [{
  //         id: getUniqueId(),
  //         content: '',
  //         question_type: 'mcq_single',
  //         // teacher_json: '',
  //         // student_json: '',

  //       }]
  //     },
  //     segment_version: '1.0.0'
  //   };
  //   if (question_type == 'mini_quiz') {
  //     parsedJSONObject = props.value || parsedJSONObject
  //   }
  // }

  // if (answerMode) {
  //   parsedJSONObject = shuffleItems(parsedJSONObject)
  // }

  // if (autoCheckMode) {
  //   let question_json = cloneDeep(props.teacher_json)
  //   let answer_json = cloneDeep(parsedJSONObject)
  //   parsedJSONObject = checkResponses(question_json, answer_json)
  // }
  const mrIntl = useTranslate();
  const [state, setState] = useState(parsedJSONObject);
  const [textAnswer, setTextAnswer] = useState(textAnswerString);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [gameStatus, setGameStatus] = useState(gameStatusObject)
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
    showCorrectAnswerSwitchValue: false,
  });
  const [play, setPlay] = useState();

  const screenSettings = props.screenSettings || {};

  let currentJSON, text_answer, attempt_status;
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject;
    text_answer = textAnswerString;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    text_answer = textAnswer;
    attempt_status = attemptStatus;
  }
  const segment_data = currentJSON?.segment_data || {};
  const questions = segment.descendants || [];
  console.log("line no 94", questions);
  // const questionContent = questions.map((q) => question_segment_attributes.teacher_json.segment_data)
  const [tabSetting, setTabSetting] = useState({
    activeKey: questions[0].id.toString(),
  });

  const initialCrypticHuntSetting = {
    currentQuestionIndex: 0,
    currentQuestion: questions[0],
    questionsCount: questions.length,
    correctQuestionsCount: 0,
    attemptedAll: false,
  };
  const [crypticHuntSetting, setCrypticHuntSetting] = useState(
    initialCrypticHuntSetting
  );

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          game_status: cloneDeep(gameStatus),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    setState(state);
    console.log("stateUpdated count ==>", stateUpdatedCount);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  // function checkResponses(question_json, answer_json) {
  //   console.log("Question and Answer JSON ==>", question_json, answer_json);
  //   if (!question_json || !answer_json) {
  //     return;
  //   }

  //   return answer_json;
  // }

  // const checkAnswer = () => {
  //   let question_json = convertDataAsPerNewSystem(props.teacher_json, props)
  //   let answer_json = state
  //   const newState = checkResponses(question_json, answer_json)

  //   saveState(newState)
  //   setMyAnswer(state)
  //   setStateSetting({
  //     ...stateSetting,
  //     quickCheckModeEnabled: true,
  //   })
  // }

  // const [myAnswer, setMyAnswer] = useState(state)
  // const showCorrectAnswer = (value) => {
  //   console.log("Value in switch ==>", value)
  //   let question_json, answer_json
  //   if (value) {
  //     question_json = convertDataAsPerNewSystem(props.teacher_json, props)
  //     answer_json = convertDataAsPerNewSystem(props.teacher_json, props)
  //   } else {
  //     question_json = convertDataAsPerNewSystem(props.teacher_json, props)
  //     answer_json = cloneDeep(myAnswer)
  //   }

  //   const newState = checkResponses(question_json, answer_json)
  //   saveState(newState)
  //   setStateSetting({
  //     ...stateSetting,
  //     quickCheckModeEnabled: true,
  //   })
  // }

  // const resetQuestion = () => {
  //   console.log("Student json ===>", props.student_json)
  //   const newState = cloneDeep(convertDataAsPerNewSystem(props.student_json, props))
  //   saveState(newState)
  //   setStateSetting({
  //     ...stateSetting,
  //     quickCheckModeEnabled: false,
  //   })
  // }

  function shuffleItems(json) {
    if (segmentSettings.shuffle) {
      const segment_data = json.segment_data;
      segment_data.questions = shuffle(segment_data.questions);
    }

    return json;
  }

  const setProperty = (type, index, value) => {
    const newQuestions = questions;
    newQuestions[index][type] = value;

    const newSegmentData = {
      ...segment_data,
      questions: newQuestions,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const saveQuestion = (index, value) => {
    const newQuestions = questions;
    newQuestions[index].teacher_json = value;
    newQuestions[index].student_json = getFinalStudentJSON(
      newQuestions[index].question_type,
      value
    );

    const newSegmentData = {
      ...segment_data,
      questions: newQuestions,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const saveAnswer = (index, result) => {
    console.log("result in miniquiz =>", result);
    const newQuestions = questions;
    newQuestions[index].response_json = result.response_json;

    const newSegmentData = {
      ...segment_data,
      questions: newQuestions,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);

    setCrypticHuntSetting({
      ...crypticHuntSetting,
      currentQuestion: {
        ...crypticHuntSetting.currentQuestion,
        response_json: result.response_json,
      },
    });
  };

  const showQuestion = (switchTo) => {
    let nextIndex,
      nextQuestion,
      attemptedAll,
      correctQuestionsCount = crypticHuntSetting.correctQuestionsCount;
    if (
      crypticHuntSetting.currentQuestionIndex + 1 !=
      crypticHuntSetting.questionsCount
    ) {
      if (switchTo == "next") {
        nextIndex = crypticHuntSetting.currentQuestionIndex + 1;
        if (nextIndex >= crypticHuntSetting.questionsCount) {
          attemptedAll = true;
        } else {
          attemptedAll = false;
          nextQuestion = questions[nextIndex];
        }
      }
      
      if (attemptedAll) {
        correctQuestionsCount = 0;
        questions.forEach((question) => {
          if (question.grade_json && question.grade_json.all_correct) {
            correctQuestionsCount += 1;
          }
        });
      }
      setCrypticHuntSetting({
        ...crypticHuntSetting,
        switchAllowed: false,
        attemptedAll: attemptedAll,
        currentQuestionIndex: nextIndex,
        correctQuestionsCount: correctQuestionsCount,
        currentQuestion: {
          ...nextQuestion,
        },
      });
    } else {
      setPlay(false);
    }
  };

  const resetQuiz = () => {
    let student_json = cloneDeep(props.student_json);
    const newState = shuffleItems(student_json);
    saveState(newState);

    const questions = newState.segment_data.questions;
    return questions;
  };

  const onAutoCheck = (index, newGradeSetting) => {
    console.log(
      "grade after Auto check correct status ==>",
      index,
      newGradeSetting
    );
    
    setCrypticHuntSetting({
      ...crypticHuntSetting,
      switchAllowed: true,
    });

    const newQuestions = questions;
    newQuestions[index].grade_json = newGradeSetting;

    const newSegmentData = {
      ...segment_data,
      questions: newQuestions,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const onTryAgain = (index) => {
    let newCrypticHuntSettings = {
      ...crypticHuntSetting,
      ...initialCrypticHuntSetting,
    };

    questions.map((question) => {
      
    })

    setCrypticHuntSetting(newCrypticHuntSettings);
    setPlay(true);
  };

  const handlePlayButton = () => {
    setPlay(true);
  };

  const levelButtons = questions.map((item, index) => {
    return (
      <Button
        className={`lavel-button ${
          crypticHuntSetting.currentQuestionIndex === index && "active"
        }`}
      >
        {index + 1}
      </Button>
    );
  });
  
  const gameStagesConfig = {
    play: play,
    gameEnded: !play,
    gameOverMessage: mrIntl("CrypticHunt.completed_all_levels_message"),
    onTryAgain: onTryAgain,
    handlePlayButton: handlePlayButton,
  };

  console.log("Cryptic Hunt setting ==>", crypticHuntSetting);
  console.log(
    "Cryptic Hunt State before render ==>",
    state,
    questions,
    stateSetting
  );
  return (
    <Segment className="game-segment">
      <SegmentData className="cryptic-hunt-game">
        {
          <Card>
            <Row>
              <GameStages {...gameStagesConfig} />
              {
                <Col span={24}>
                  {levelButtons}
                  <SegmentItemDetail
                    nestingLevel={nestingLevel}
                    experience={experience}
                    item={crypticHuntSetting.currentQuestion}
                    allowResponseAttachments={false}
                    allowIncludeTextAnswer={false}
                    allowSavingStatusIndicator={false}
                  ></SegmentItemDetail>
                  <Button
                    onClick={(e) => showQuestion("next")}
                    className="next-level-button"
                  >
                    {crypticHuntSetting.currentQuestionIndex + 1 ===
                    crypticHuntSetting.questionsCount
                      ? <MrTranslate id={"CrypticHunt.end_game_button"}/>
                      : <MrTranslate id={"CrypticHunt.next_level_button"}/>}
                  </Button>
                </Col>
              }
            </Row>
          </Card>
        }
      </SegmentData>
    </Segment>
  );
};
export default CrypticHunt;
