import { deviceType, isTablet as isTab } from "react-device-detect";

export function isiPhone() {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPhone/i);
    if(iOS){
      return true;
    }else{
      return false;
    }
  }
  export function isTablet(){
    if(isTab){
      return true;
    }
  }
  export function isTouchDevice() {
    if(deviceType !== "browser"){
      return true;
    }
    if(isTab){
      return true;
    }
    return false;
  }
  
  export function isMobile() {
    if(deviceType == "mobile"){
      return true;
    }
    return false;
  }