import React from "react";
import PropTypes from "prop-types";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const ProgrammeFilter = (props) => {
  return <span><MrTranslate id={"ProgrammeFilter.programme_filter"}/></span>;
};

ProgrammeFilter.defaultProps = {};

ProgrammeFilter.propTypes = {};

export default ProgrammeFilter;
