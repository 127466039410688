import React, { useRef, useCallback, useEffect, useState } from 'react';
import { forceHideAnnotation, getUniqueId } from '/src/lib/utils/helperMethods';
import { useStore, useDispatch, useSelector } from "react-redux";
import { actions } from "/src/views/Annotations/redux";
import { formatJsonApiData } from "mr_react_framework";
import { annotationsFetchLoadingSelector, uriAnnoationsSelector } from '/src/views/Annotations/selector';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { printModeSelector } from '/src/views/Experiences/selector';
// let reduxStore = (options = {}) => {

//   return {
//     create: function (annotation) {
//       // console.log( "",  );
//       if (typeof annotation.id === 'undefined' ||
//         annotation.id === null) {
//         annotation.id = getUniqueId();
//       }


//       return new Promise((resolve, reject) => {

//         setTimeout(() => {
//           console.log("redux store create called", annotation)
//           resolve(annotation);
//           // return annotation;
//         }, 1000);
//       })
//     },

//     update: function (annotation) {
//       console.log("redux store update called", annotation)
//       return annotation;
//     },

//     delete: function (annotation) {
//       console.log("redux store delete called", annotation)
//       return annotation;
//     },

//     query: function (options) {
//       console.log("redux store query called", options)
//       return new Promise((resolve, reject) => {

//       });
//       return {
//         results: [{
//           id: 20,
//           text: 'The whole paragraph has too many spelling errors along with incorrect sentence formation.',
//           quote: 'This all start with a small dream where an independent women .Who see a dream to be business women she was all alone just three children and her husband died due to cancer and they were not having enough money so the doctors were not able to treate him . But the two children were able to handle themselve but the third on was too young he was toodler .  from that day the women thought that money is everthing to survie . So she started with a small beauty shop where she use to sell product to the beauty parlour but and there was no one to look at the child so she use to take him in the shop .',
//           uri: 'its custom uri',
//           category: 'incorrect',
//           ranges: [{
//             start: '',
//             end: '',
//             startOffset: 2,
//             endOffset: 30
//           }],
//           tags: ['Bad', 'Good', 'Cool'],
//           // user: 'current user changed',
//           // permissions: {
//           //   update: false,
//           //   delete: false,
//           // }
//         }]
//       };
//     },

//     configure: function (registry) {
//       registry.registerUtility(this, 'storage');
//     }
//   }
// }

const applySequenceNumbersToAnnotations = (node, annotations, setAnnotationsListBySequence) => {
  const annotationsByQuote = {};
  if (annotations && annotations.length > 0) {
    annotations.forEach(({ quote, text }) => {
      annotationsByQuote[quote] ||= [];
      annotationsByQuote[quote].push(text);
    });
  }

  const allAnnotatorHlSpans = node.querySelectorAll(
    ".annotator-hl"
  );

  const annotatorHlSpans = Array.from(allAnnotatorHlSpans).filter(span => !span.querySelector('.annotator-hl'));
  // using Array.from to convert the Nodelist to normal array to perform filter
  // using filter to handle multiple annotations
  let finalAnnotations = [];
  annotatorHlSpans.forEach((span, index) => {
    const sequenceNumber = index + 1;
    const existingSup = span.querySelector("sup");
    if (!existingSup) {
      const sup = document.createElement("sup");
      sup.textContent = (sequenceNumber).toString();
      span.appendChild(sup);
    }
    
    if(annotationsByQuote){
      const quote = span.textContent
      .replace(new RegExp(sequenceNumber + '$'), "")
      .trim();
      annotationsByQuote[quote].forEach((annotation, index) => {
        let subAnnotations;
        if (annotationsByQuote[quote].length > 1) {
          subAnnotations = `${sequenceNumber}${String.fromCharCode(
            97 + index
          )}. ${annotation}`;
        } else {
          subAnnotations = `${sequenceNumber}. ${annotation}`;
        }

        finalAnnotations.push(subAnnotations);
      });
    }  
  });

  if(setAnnotationsListBySequence){
    setAnnotationsListBySequence(finalAnnotations);
  }
}

const useAnnotationStore = (options = {}) => {
  console.log("useAnnotationStore options ==>", options);
  const { allowed, uri, user } = options
  // const annotationsFetchLoading = useSelector(annotationsFetchLoadingSelector())
  const uriAnnotations = useSelector(uriAnnoationsSelector(uri, user));
  const mrIntl = useTranslate()
  const queryResults = allowed ? {results: uriAnnotations} : null
  console.log("useAnnotationStore annotations from store ==>", queryResults)

  const dispatch = useDispatch();

  useEffect(() => {
    const modalBody = document.querySelector(".ant-modal-body")
    if (modalBody) {
      modalBody.addEventListener('scroll', handlePageScroll);

      return () => {
        modalBody.removeEventListener('scroll', handlePageScroll);
      };
    }
  }, []);

  const handlePageScroll = () => {
    console.log("handlePageScroll ====>");
    forceHideAnnotation()
  }

  // NOTE: create was not taking latest options so added this
  const createAnnotation = (annotation) => {
    const annotationParams = {
      ...annotation,
      ...options,
      annotator_ranges_attributes: annotation.ranges
    }
    return new Promise((resolve, reject) => {

      console.log("Annotation data ==>", annotation, annotationParams)
      dispatch(actions.create({
        annotation: {
          ...annotationParams,
        }
      }, {
        successCallback: (response) => {
          let apiAnnotation = formatJsonApiData(response.data.annotation)
          console.log("returned annotation ==>", apiAnnotation)
          resolve(apiAnnotation)
        },
        errorCallback: (error) => {
          // reject("Something went wrong, Not able to create annotation")
          reject(mrIntl("TextAnnotation.not_able_to_create_annotation_error_msg"))
        },
      }));
    })
  }

  const updateAnnotation = (annotation) => {
    const annotationParams = {
      ...annotation,
      ...options,
      annotator_ranges_attributes: annotation.ranges
    }

    return new Promise((resolve, reject) => {
      dispatch(actions.update({
        id: annotation.id,
        annotation: {
          ...annotationParams,
        }
      }, {
        successCallback: (response) => {
          let apiAnnotation = formatJsonApiData(response.data.annotation)
          resolve(apiAnnotation)
        },
        errorCallback: (error) => {
          // reject("Something went wrong, Not able to update annotation")
          reject(mrIntl("TextAnnotation.not_able_to_update_annotation_error_msg"))
        },
      }));
    })
  } 

  const deleteAnnotation = (annotation) => {
    console.log("redux store delete called", annotation)
    return new Promise((resolve, reject) => {
      dispatch(actions.delete({
        id: annotation.id,
      }, {
        successCallback: (response) => {
          let apiAnnotation = formatJsonApiData(response.data.annotation)
          resolve(apiAnnotation)
        },
        errorCallback: (error) => {
          // reject("Something went wrong, Not able to delete annotation")
          reject(mrIntl("TextAnnotation.not_able_to_delete_annotation_error_msg"))
        },
      }));
    })
  }

  const queryAnnotations = (options) => {
    console.log("redux store query called", options, queryResults);
    return queryResults;
  }

  function finalReduxAnnotationStore() {
    return {
      create: createAnnotation,
      update: updateAnnotation,
      delete: deleteAnnotation,
      query: queryAnnotations,
      configure: function (registry) {
        return registry.registerUtility(this, 'storage');
      },
    }
  }
  return finalReduxAnnotationStore
}

export const useAnnotation = (options, setAnnotationsListBySequence) => {
  const { allowed, uri, user_response_id, user } = options
  const annotationStore = useAnnotationStore(options);
  const annotationsFetchLoading = useSelector(annotationsFetchLoadingSelector())
  const annotations = useSelector(uriAnnoationsSelector(uri, user));
  const printMode = useSelector(printModeSelector())
  console.log("useAnnotation==>", options, annotationsFetchLoading)
  return useCallback((node) => {
    
    if (allowed && user_response_id && node && !annotationsFetchLoading) {
      let annotator = window.annotator;
      if (typeof annotator === 'undefined') {
        console.log("Annotator not available")
      } else {
        try {
          let app = new annotator.App();
          app.include(annotator.ui.main, {
            element: node,
            // viewerExtensions: [annotator.ui.tags.viewerExtension],
            // editorExtensions: [annotator.ui.tags.editorExtension],
          });
          app.include(annotationStore);
          app
            .start()
            .then(function () {
              app.annotations.load({ uri: uri }).then(function () {
                if (printMode) {
                  applySequenceNumbersToAnnotations(node, annotations, setAnnotationsListBySequence)
                }
              })
              if (user === 'admin' || user === 'teacher') {
                app.ident.identity = 'teacher'
              }
            });
          console.log("Annotators ==>", app, node, uri)         
        } catch (error){
          console.error("error coming in annotations", error)
        }
      }
    }
  }, [user_response_id, annotationsFetchLoading, allowed]);
};
