import styled, {css} from 'styled-components';
import media from './media'



export const VideoControl = styled.div`
    position: absolute;
    visibility: ${props => props.activControl ? 'visible' : 'hidden'};
    z-index: 2;
    //width: 100%;
    width: ${props => props.vWidth ? props.vWidth+"px" :  0};
    height: 100%;
   // height: ${props => props.height ? props.height+"px" :  0};
    top: 0;
    ${props => props.fullscreen && css`
        //height: 100%;
        width: 100%;
        position: unset;
        ${media.phone(css`    
         @media(orientation: landscape){
                height:  100% !important;
            }
        `)}
    `};
    background: #133956;
    mix-blend-mode: normal;
    opacity: 0.75;
`;
export const VideoOpacity = styled.div`
    position: absolute;
    background: #133956;
    mix-blend-mode: normal;
    opacity: 0;
    z-index: 2;
    //width: 100%;
    width: ${props => props.vWidth ? props.vWidth+"px" :  0}; 
    //height: ${props => props.height ? props.height+"px" :  0};
    height: 100%;
    ${props => props.fullscreen && css`
        height: 100%;
        width: 100%;
    `};
    top: 0;
`;
export const VideoTopBar = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    top: 0;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px 0 20px;
    z-index: 5;
`;
export const VideoSpeed = styled.div`
    width: 128px;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    align-items: center;
`;
export const SpeedNumber = styled.div`
    ${props => props.isPlayBackRate && css`
        background: #378EF8;
    `};
    border-radius: 6px;
    width: ${props => props.large ? "34px" : "23px"};
    height: 22px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF;
    cursor: pointer;
    user-select: none;
`;

export const VideoVolume = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const VideoCenter = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    //height: 100%;
    z-index: 5;
    justify-content: center;
`;

export const VideoPlay = styled.div`
    width:  ${props => props.begining ? "100%" : "44px"};
    ${props => !props.begining && css`
        height: 66px;
    `}
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 8;
`;


export const PlayButton = styled.div`
    font-weight: bold;
    
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFFFFF;
    // below custom added by AP
    width: 100px;
`;

export const FromBeginingDes = styled.div`
    text-align: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.6;
`;

export const PlayIconStyle = styled.div`
    cursor: pointer;
    height: 44px;
    width: 44px;
    user-select: none;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (pointer: fine) {
        &:hover {
            background: rgba(255, 255, 255, 0.2);
        }
    }
    &:active {
        background: #378EF8;
    }
`;
export const VolumeIconStyle = styled.div`
    cursor: pointer;
    margin-right: 22px;
    z-index: 5;
`;
export const FullscreenIconStyle = styled.div`
    cursor: pointer;
    z-index: 5;
`;

export const VideoSeekBar = styled.div`
    position: absolute;
    width: 100%;
    height: 46px;
    bottom: 0;
    padding: 0 20px;
    bottom: 12px;
   
`;
export const SeekBar = styled.div`
    height: 24px;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    //flex-direction: column;
    .input-range{
        ${'' /* background-color: #FFFFFF;
        border-radius: 2px;
        height: 4px; */}
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        bottom: 0;
        margin: 0;
        margin-left: 10px;
        margin-right: 10px;
        -webkit-appearance: none;
        outline: none;
       
        ${'' /* &::-webkit-slider-thumb{
            appearance: none;
            background: #50D3A6;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            cursor: pointer;
        } */}
       
    }
`;
export const VideoPlayOverlay = styled.div`
     height: ${props => props.height ? props.height+"px" :  0};
     position: absolute;
     width: 100%;
`;

export const LoadedVedio = styled.div`
    height: 4px;
    width: ${props => props.width ? props.width+"%" :  0};
    position: relative;
    background: #B1B4B6;
    margin-top: -14px;
    z-index: 8;
    border-radius: 2px;
    margin-left: -1px;
`;
export const VideoTimer = styled.div`
    display: flex;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF;
    height: 18px;
    align-items: center;
    margin-bottom: 4px;
`;
export const RemainingTime = styled.div`
    margin-right: 2px;
`;
export const TotalTime = styled.div`
    opacity: 0.6;
`;
export const Number = styled.div`
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    margin-left: ${props => props.isRight ? "0" : "4px" };
    margin-right: ${props => props.isRight ? "8px" : "0" };
`;
export const LIcon = styled.div`
    
`;



export const VideoControlContaint = styled.div` 
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: manipulation;
    
    ${props => props.fullscreen && css`
        position: absolute;
        display: flex;
        // width: 100%;
        width: ${props => props.vWidth ? props.vWidth+"px" :  '100%'}; 
        height: ${props => props.height ? props.height+"px" :  0};
    `};
    ${media.phone(css`    
        @media(orientation: landscape){
            //height:  100%;
        }
  `)}
`;



export const VideoPlayer = styled.div`
    ${props => props.fullscreen && css`
        display: flex;
        align-items: center;
    `};
`;

export const VideoPlayerSection = styled.div`

${media.phone(css`    
@media(orientation: landscape){
    display: flex;
    align-items: center;
    justify-content: center;
}
`)}
${props => props.skeletonShow && css`
padding-bottom: ${props => !props.form && "50px"};
`}
`;

export const VideoMain = styled.div`
width: 100%;
//width: ${props => props.vWidth ? props.vWidth+"px" :  "0"};
padding: ${props => props.skeletonShow ? "16px 20px " :  "24px 0 0 0"};
@media (min-width: 1601px) and (max-width: 1920px) {
    max-width: 975px;
    //padding: ${props =>props.isNavigationSectionVisible ? "24px 0 0 0" : "24px 0 0 0"};
}
@media (min-width: 1112px) and (max-width: 1600px) {
    //max-width: 1059px;
    max-width: 785px;
    //padding: ${props =>props.isNavigationSectionVisible ? "24px 0 0 0" : "24px 0 0 0"};
}
@media (min-width: 1024px) and (max-width: 1112px){
    @media (orientation: portrait){
        max-width: 785px;
    }
    @media (orientation: landscape){
        max-width: 585px;
    }
    //padding: ${props =>props.isNavigationSectionVisible ? "24px 0 0 0" : "24px 0 0 0"};
}
@media (min-width: 990px) and (max-width: 1112px) and (orientation: portrait){
    @media (orientation: portrait){
        //max-width: 785px;
        max-width: 757px;
    }
    @media (orientation: landscape){
        max-width: 557px;
    }
   // padding: ${props =>props.isNavigationSectionVisible ? "24px 0 0 0" : "24px 0 0 0"};
}
@media (min-width: 320px) and (max-width: 1024px) and (orientation: portrait){
    padding: ${props => props.skeletonShow ? "16px 20px " :  "14px 0 0 0"};
}
@media (min-width: 320px) and (max-width: 900px) and (orientation: landscape){
    max-width: ${props => props.vWidth ? props.vWidth+"px" : "100%"};
    padding: ${props => props.skeletonShow ? "16px 20px " :  "14px 0 0 0"}; 
}
@media only screen and (min-height: 769px) and (max-height: 860px) {
  max-width:  815px !important;
}
${'' /* @media (orientation: landscape){
    padding: 14px 0 0 0;
} */}
`;
