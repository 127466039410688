import React from "react";
import { List, Card } from "antd";
import { Link } from "react-router-dom";

const OrgListItem = (props) => {
  console.log("props==>", props.item);
  const { item, actionBtns } = props;

  return (
    <>
      <List.Item>
        <Card title={item.key}>
          <Link to={`/u/orgs/${item.id}`}>
            {" "}
            {item.id} - {item.name}
          </Link>
          {JSON.stringify(item.settings)}
          {actionBtns}
        </Card>
      </List.Item>
    </>
  );
};

export default OrgListItem;
