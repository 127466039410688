import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, useRouteMatch } from "react-router-dom";
import { Tabs, Row, Col, Button, List, Card, Switch, Space } from "antd";
import { ResetButton, CustomTextButton, showConfirmModal, ShowTextIndex } from "/src/components/UI/Segment/UIHelper";
import { checkMob, getUniqueId } from "/src/lib/utils/helperMethods";
import { partnerNameSelector } from "/src/views/Auth/Login/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { printModeSelector, printOptionsSelector } from "/src/views/Experiences/selector";
import Spinner from "/src/components/UI/Spinner/Spinner";

const AnswerExplanation = React.lazy(() => import("/src/views/Segments/Explanation/AnswerExplanation"));

const SegmentItemFooter = (props) => {
  console.log("SegmentItemFooter props ==>", props);
  const {
    createMode,
    answerMode,
    presentationMode,
    segmentFooterAttributes = {},
    segmentSettings = {},
    segmentStateSettings = {},
    setRenderMath,
    showChildrenExplanations,
    childrenRenderProps,
    questionType,
  } = props;
  const mrIntl = useTranslate() 
  const isMobile = checkMob();
  const partnerName = useSelector(partnerNameSelector());
  const printMode = useSelector(printModeSelector())
  const printOptions = useSelector(printOptionsSelector());
  const includeExplanationInPrint =
   (printMode &&
    (printOptions.with_answer_explanation === "true" ||
      printOptions.with_answer_explanation === true));
  let oscFooterConfig = {
    showB2cInfoCard: segmentSettings.oscSettings && segmentSettings.oscSettings.shouldShowB2CInfoCard && (!questionType || ((questionType === "mcq_single" &&
    !segmentStateSettings.doNotShowExplanation && 
    !segmentStateSettings.allowCheckAnswer))) ? true : false,
  }
  console.log(
    "segmentSettings in footer ==>",
    ((segmentStateSettings.autoCheckMode ||
      segmentStateSettings.quickCheckModeEnabled) &&
      !segmentStateSettings.doNotShowExplanation) ||
      printOptions.print_type === "experience"
  );
  const showExplanation = (!printMode && (segmentStateSettings.autoCheckMode ||
    segmentStateSettings.quickCheckModeEnabled) &&
    !segmentStateSettings.doNotShowExplanation) ||
    includeExplanationInPrint;

  const showResetConfirm = () => {
    showConfirmModal({
      title: mrIntl("SegmentItemFooter.reset_question_confirm_msg"),
      mrIntl: mrIntl,
      onOk: () => props.resetQuestion()
    })
  }

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          {/* Comment this if not used - check with pamoja */}
          {/* {segmentSettings.include_text_answer &&
            (answerMode ||
              (presentationMode && segmentStateSettings.autoCheckMode)) && (
              <CKETextAnswer
                text_answer={segmentFooterAttributes.text_answer}
                answerMode={answerMode}
                setRenderMath={setRenderMath}
                onChange={(data) => props.saveTextAnswer(data)}
              ></CKETextAnswer>
            )} */}  
         {
          showExplanation &&
              (
                <Suspense fallback={<Spinner />}>
                  <AnswerExplanation
                    {...props.segmentFooterAttributes}
                    childrenRenderProps={childrenRenderProps}
                    segmentSettings={segmentSettings}
                    setRenderMath={setRenderMath}
                    showChildrenExplanations={showChildrenExplanations}
                    onClose={props.toggleAnswer || props.checkAnswer}
                  ></AnswerExplanation>
                </Suspense>
              )
        }
        </Col>
      </Row>
      {!printMode && 
      (!segmentSettings.nestingLevel || segmentSettings.nestingLevel === 1) && (
        <Row>
          <Col span={24}>
            {answerMode &&
              segmentFooterAttributes.segmentMode == "quick_check" && oscFooterConfig.showB2cInfoCard && (
                <Space
                  direction="vertical"
                  className={"quick-check-buttons partner-osc b2c-card"}
                >
                  <h3><MrTranslate id={"SegmentItemFooter.step_by_step_solution"}/></h3>
                  
                  {props.toggleAnswer &&
                    (partnerName !== "osc" ||
                      (partnerName === "osc" &&
                        ((questionType === "mcq_single" &&
                          segmentStateSettings.doNotShowExplanation) ||
                          !questionType))) && (
                      <CustomTextButton
                        // checked={autoCheckMode}
                        type="primary"
                        clicked={(e) => {
                          props.toggleAnswer();
                          // window.parent.postMessage("correct", "*");
                        }}
                        shape={partnerName === "osc" ? "round" : ""}
                        size={partnerName === "osc" ? "large" : ""}
                        // style={{ width: "200px" }}
                        buttonText={
                          segmentStateSettings.quickCheckModeEnabled &&
                          !segmentStateSettings.doNotShowExplanation
                            ? mrIntl("SegmentItemFooter.hide_answer_button")
                            : partnerName === "osc"
                            ? mrIntl("SegmentItemFooter.solution_button")
                            : mrIntl("SegmentItemFooter.show_answer_button")
                        }
                      ></CustomTextButton>
                    )}

                  {(questionType === "mcq_single") && 
                    <h3><MrTranslate id={"CommonText.select"}/> <Space style={{paddingRight: "5px"}}>
                      <span className={"mcq-option-index-circled bg-dark-blue"}><MrTranslate id={"SegmentItemFooter.mcq_option_a"}/></span>
                      <span className={"mcq-option-index-circled bg-pink"}><MrTranslate id={"SegmentItemFooter.mcq_option_b"}/></span>
                      <span className={"mcq-option-index-circled bg-orange"}><MrTranslate id={"SegmentItemFooter.mcq_option_c"}/></span>
                      <span className={"mcq-option-index-circled bg-green"}><MrTranslate id={"SegmentItemFooter.mcq_option_d"}/></span>
                      </Space> 
                    <MrTranslate id={"SegmentItemFooter.to_view_the_solution"}/></h3>
                  }
                  {<Space direction={"vertical"} gap={0} className={"osc-b2c-learn-more"}>
                    <p><MrTranslate id={"SegmentItemFooter.purchase_managebac_passport_msg"}/></p>
                    <Button shape={"round"} ghost={true} href="https://www.managebac.com/ib-diploma/system" target="_blank">
                      <MrTranslate id={"SegmentItemFooter.learn_more_button"}/> 
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.5" y="0.5" width="13" height="13" rx="3.5" stroke="#378EF8"/>
                      <path d="M5.50002 8.5L10.5 3.5" stroke="#378EF8" strokeWidth="0.91" strokeLinecap="round"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M10.2503 2.75787C10.7946 2.76337 11.2346 3.20334 11.2401 3.74772L11.2756 7.25785C11.2801 7.70572 10.7387 7.93316 10.422 7.61645L6.38152 3.57595C6.06482 3.25924 6.29226 2.71789 6.74013 2.72242L10.2503 2.75787Z" fill="#378EF8"/>
                      </svg>
                    </Button>  
                    <p><MrTranslate id={"SegmentItemFooter.can_purchase_exam_questions_ib_store_msg"}/></p>  
                    <Button shape={"round"} ghost={true} href="https://www.follettibstore.com/search?directlogin=Y&nodeid=260832&src=M" target="_blank">
                      <MrTranslate id={"SegmentItemFooter.visit_ib_store_button"}/>
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.5" y="0.5" width="13" height="13" rx="3.5" stroke="#378EF8"/>
                      <path d="M5.50002 8.5L10.5 3.5" stroke="#378EF8" strokeWidth="0.91" strokeLinecap="round"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M10.2503 2.75787C10.7946 2.76337 11.2346 3.20334 11.2401 3.74772L11.2756 7.25785C11.2801 7.70572 10.7387 7.93316 10.422 7.61645L6.38152 3.57595C6.06482 3.25924 6.29226 2.71789 6.74013 2.72242L10.2503 2.75787Z" fill="#378EF8"/>
                      </svg>
                    </Button>  
                  </Space>}
                </Space>
              )}
            {answerMode && !oscFooterConfig.showB2cInfoCard &&
              (segmentFooterAttributes.segmentMode == "quick_check" ? (
                <Space
                  className={`quick-check-buttons ${ partnerName === "osc" ? "partner-osc" : "" }`}
                >
                  {(segmentStateSettings.autoCheckMode ||
                    segmentStateSettings.quickCheckModeEnabled) &&
                    props.showCorrectAnswer && (
                      <span>
                        {isMobile ? "" : mrIntl("SegmentItemFooter.my_answer")}
                        <Switch
                          onChange={(value) => props.showCorrectAnswer(value)}
                        />
                        {isMobile ? "" : mrIntl("CommonText.correct_answer")}
                      </span>
                    )}
                  {props.resetQuestion && segmentStateSettings.quickCheckModeEnabled && (
                    <CustomTextButton
                      // disabled={!segmentStateSettings.quickCheckModeEnabled}
                      clicked={(e) => props.resetQuestion()}
                      buttonText={mrIntl("SegmentItemFooter.try_again")}
                      shape={partnerName === "osc" ? "round" : ""}
                      size={partnerName === "osc" ? "large" : ""}
                    ></CustomTextButton>
                  )}
                  {props.checkAnswer &&
                    (partnerName !== "osc" ||
                      (partnerName === "osc" &&
                        !segmentStateSettings.quickCheckModeEnabled && segmentStateSettings.allowCheckAnswer)) && (
                      <CustomTextButton
                        // checked={autoCheckMode}
                        type="primary"
                        shape={partnerName === "osc" ? "round" : ""}
                        size={partnerName === "osc" ? "large" : ""}
                        disabled={segmentStateSettings.quickCheckModeEnabled}
                        clicked={(e) => props.checkAnswer(true)}
                        buttonText={mrIntl("SegmentItemFooter.check_answer_button")}
                      ></CustomTextButton>
                    )}
                  {props.toggleAnswer &&
                    (partnerName !== "osc" ||
                      (partnerName === "osc" &&
                        ((questionType === "mcq_single" &&
                          segmentStateSettings.doNotShowExplanation) ||
                          !questionType))) && (
                      <CustomTextButton
                        // checked={autoCheckMode}
                        type="primary"
                        clicked={(e) => {
                          props.toggleAnswer();
                          // window.parent.postMessage("correct", "*");
                        }}
                        shape={partnerName === "osc" ? "round" : ""}
                        size={partnerName === "osc" ? "large" : ""}
                        // style={{ width: "200px" }}
                        buttonText={
                          segmentStateSettings.quickCheckModeEnabled &&
                          !segmentStateSettings.doNotShowExplanation
                            ? mrIntl("SegmentItemFooter.hide_answer_button")
                            : partnerName === "osc"
                            ? mrIntl("SegmentItemFooter.solution_button")
                            : mrIntl("SegmentItemFooter.show_answer_button")
                        }
                      ></CustomTextButton>
                    )}
                </Space>
              ) : (
                <Space className="exam-mode-buttons">
                  {props.resetQuestion && (
                    <ResetButton mrIntl={mrIntl}
                      clicked={(e) => showResetConfirm()}
                    ></ResetButton>
                  )}
                </Space>
              ))}
            {presentationMode && segmentStateSettings.showCorrectAnswerSwitch && props.showCorrectAnswer &&  (
              <Space className="auto-check-buttons">
                <span>
                  {/* Grading view switch */}
                  {isMobile ? "" : ""}
                  <Switch
                    onChange={(value) => props.showCorrectAnswer(value)}
                  />{" "}
                  {isMobile ? "" : mrIntl("SegmentItemFooter.show_correct")}
                </span>
              </Space>
            )}
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};
export default SegmentItemFooter;
