import React, { useEffect, useState } from 'react';
import { Image, Button, Modal, Row, Col} from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined, CloseOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import RendererContainer from '/src/components/Partners/OSC/OSCImageZoom/RendererContainer';
import Container from "/src/components/Partners/OSC/OSCImageZoom/Container";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ZoomableImageContainer = (props) => {
  const [visible, setVisible] = useState(false);
  // const [zoomableImageContainerVisible, setZoomableImageContainerVisible] = useState(false);
  const [zoomableImageSrc, setZoomableImageSrc] = useState(null);
    
  function openZoomModal(event){
    if (!event.target.matches('.show-html img:not(.Wirisformula)')) return;
    if (event.target.matches('.image-zoom-disabled img')) return;
    event.preventDefault();
    console.log("openZoomModal zoomable clicked", event.target, event.target.src)
    setZoomableImageSrc(event.target.src)
    setVisible(true)
    // setZoomableImageContainerVisible(true);
  }


  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  useEffect(() => {
    window.addEventListener("click", openZoomModal, false);
    return () => {
      window.removeEventListener("click", openZoomModal, false);
    };

    //const el = document.getElementById('canvas');
    //el.addEventListener('touchstart', handleStart);
  }, []);

  return (
    <>
      {/* <RendererContainer imageUrl={"https://d3kgcbih1bmz00.cloudfront.net/direct_uploads/attachments/01FTA4VYYPRBDVMWC08GSBA73N/Screen%20Shot%202022-01-09%20at%2011.07.01%20PM.png"} /> */}

      {/* <Image
        width={200}
        style={{ display: 'none' }}
        src={zoomableImageSrc}
        preview={{

          visible,
          src: zoomableImageSrc,
          getContainer: ".image-mount", //".react-transform-wrapper",
          // onOpenChange: (prev, curr) => {
          //   console.log("--------->",prev, curr)
          // },
          onOpenChange: value => {
            console.log("on visible changes=====>",value)
            setVisible(value);
          },
        }}
        
      /> */}
      <Modal
        // title="Image zoom"
        open={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        // width={400}
        wrapClassName="antd-full-screen-modal zoomable-image-container"
        closable={false}
        maskClosable={false}
        keyboard={false}
        width={"100%"}
        style={{top:10}}
      >
        <TransformWrapper
          defaultScale={1}
          //defaultPositionX={200}
          //defaultPositionY={100}
          visible={visible}
        >
          {({
            zoomIn,
            zoomOut,
            resetTransform,
            positionX,
            positionY,
            ...rest
          }) => (
            <React.Fragment>
              <div className="zoom-tools">
                <Button
                  type="text"
                  size="large"
                  onClick={() => zoomIn()}
                  icon={<ZoomInOutlined />}
                ></Button>
                <Button
                  type="text"
                  size="large"
                  onClick={() => zoomOut()}
                  icon={<ZoomOutOutlined />}
                ></Button>
                {/* <Button
                  type="text"
                  size="large"
                  onClick={() => resetTransform()}
                  icon={<CloseCircleOutlined />}
                ></Button> */}
                <Button
                  className="close-button"
                  type="text"
                  size="large"
                  onClick={() => {resetTransform(); setVisible(false)}}
                  icon={<CloseOutlined />}
                ></Button>
              </div>
              <TransformComponent>
                <div className="zoom-container">  
                  <img
                    //width={200}
                    // width={"auto"}
                    // height={"auto"}
                    id="panzoom-element"
                    src={zoomableImageSrc}
                  />
                </div>
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </Modal>
    </>
  );
}

export default ZoomableImageContainer;