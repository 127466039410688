import React, { useState } from "react";
import { Button, Modal, Tooltip, Row, Tag, App, Space } from "antd";
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import { message, notification } from '/src/components/UI/AntdAppHelper';
import {
  UserAddOutlined,
  InfoOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { displayPoints, writeToClipboardPolyfill } from "/src/lib/utils/helperMethods";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import _ from "lodash";
import { showNotification } from "/src/components/UI/Segment/UIHelper";
import { useSelector } from "react-redux";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";

const InfoModal = (props) => {
  const { experience } = props;
  // const { message } = App.useApp() // doesn't support legacy browsers css - contextHolder method does
  const mrIntl=useTranslate();

  console.log("message import", message);

  // const [messageApi, contextHolder1] = message.useMessage();
  // const [notifApi, contextHolder] = notification.useNotification();
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  console.log("Info Modal enabledFeatures ===>", enabledFeatures);

  const [visible, setVisible] = useState(false);

  const experienceRubric = experience.rubric || {};

  let experienceRubricTypeC = "";
  
  if (experienceRubric.type_c === "criteria_with_points") {
    experienceRubricTypeC = mrIntl("CommonText.myp_criteria_with_points");
  } else if (experienceRubric.type_c === "myp_achievement_level") {
    experienceRubricTypeC = mrIntl("CommonText.myp_achievement_level");
  }
  
  const showModal = () => {
    setVisible(true);
  };
  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  let criteriaPointsView = [];
  if (experience.criterium_associations) {
    experience.criterium_associations.map((ca, i) => {
      let caStrands = ca.strands
      let mypCriteriaStrandsRender = []
      if(caStrands && caStrands.length > 0){
        caStrands = _.orderBy(caStrands, ["label"], ["asc"]) 
        mypCriteriaStrandsRender.push(
          <>
            {caStrands.map((strand) =>
              <Tag>{strand.label}</Tag>
            )}
          </>
        );
      }
      criteriaPointsView.push(
        <>
          <Tag key={`criteria-points-${ca.id}`}>
            {ca.custom_fields.title} {displayPoints(ca.points)}
          </Tag>
          {mypCriteriaStrandsRender}
        </>
      );
    });
  }

  let segment_types_count = experience.custom_fields.segment_types_count || {};

  console.log("segment_types_count ===>", segment_types_count);

  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Share
      </Button> */}
      <Tooltip 
        getPopupContainer={() => document.querySelector(".experience-header")}
        placement="top" 
        title={mrIntl("CommonText.view_details")} 
      >
        {/* <Button shape="circle" icon={<InfoOutlined />} onClick={showModal}/> */}
        <InfoCircleTwoTone  className="assessment-view-details" onClick={showModal} />
      </Tooltip>
      <Modal
        title={mrIntl("InfoModal.assessment_details")}
        // title="Assessment details"
        open={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        wrapClassName="experience-info-modal"
      >
        <Row>
          {experience.is_mb_linked && <Tag color="blue"><MrTranslate id={"InfoModal.linked_to_mb_task"}/></Tag>}
          {!experience.questionbank_id && experience.created_by && (
            <Tag>{experience.created_by}</Tag>
          )}
          {experience.custom_fields.org_programme_item_label && (
            <Tag>{experience.custom_fields.org_programme_item_label}</Tag>
          )}
          {experience.custom_fields.subject_item_label && (
            <Tooltip title= {mrIntl("CommonText.subject")}>
              <Tag>{experience.custom_fields.subject_item_label}</Tag>
            </Tooltip>
          )}
          {experience.custom_fields.subject_item_label_group && (
            <Tooltip title={ mrIntl("CommonText.subject_group")}>
              <Tag>{experience.custom_fields.subject_item_label_group}</Tag>
            </Tooltip>
          )}
          {experience.custom_fields.grade_item_label && (
            <Tag>{experience.custom_fields.grade_item_label}</Tag>
          )}
          {experience.settings.is_timed && (
            <Tag>{experience.settings.duration} mins</Tag>
          )}
          {experience.settings.start_at_datetime && (
            <Tag>
              {dayjs(experience.settings.start_at_datetime).format(
                "MMM DD, YYYY"
              )}
            </Tag>
          )}
          {experience.access_code && (
            <Tooltip title={mrIntl("InfoModal.click_to_copy_join_code")}>
              <Tag
                color="success"
                onClick={() => {
                  writeToClipboardPolyfill(experience.access_code);
                  message.success(mrIntl("InfoModal.join_code_copied"));
                }}
              >
                {experience.access_code}
              </Tag>
            </Tooltip>
          )}

          {/* This code block is not required - but need for development to see this pin without joining test from SEB*/}
          {import.meta.env.VITE_MODE === "development" && (
          enabledFeatures.new_join_view_with_dynamic_config && 
          experience.settings["lockdown_config"]) && (
            <Tooltip title={"Copy quit password"}>
              <Tag
                color="warning"
                onClick={() => {
                  writeToClipboardPolyfill(experience.settings["lockdown_config"]["quit_password"]);
                  message.success("Copied");
                }}
              >
                {experience.settings["lockdown_config"]["quit_password"]}
              </Tag>
            </Tooltip>
          )}
          {/* {experience.settings.i_code && <Tag color="error">{experience.settings.i_code}</Tag>} */}

          {/* <Card title="Test settings and publish"> */}
          {/* </Card> */}
        </Row>

        <Row className={"m-t-15"}>
          <Space>
            {experienceRubricTypeC}
            <span>{criteriaPointsView}</span>
          </Space>
        </Row>

        {(import.meta.env.VITE_MODE == "staging" ||
          import.meta.env.VITE_MODE == "development") && (
          <Row className={"m-t-15"}>
            <Space>
              {Object.entries(segment_types_count).map((value) => {
                return (
                  <Tag>
                    {value[0]} - {value[1]}
                  </Tag>
                );
              })}
            </Space>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default InfoModal;
