import React from "react";
import PropTypes from 'prop-types';

import { MrSelect, MrCrudView, MrForm as WrappedMrForm, FormRenderer } from "mr_react_framework";
import { actions } from "./redux";
// import BoardListItem from "./BoardListItem/BoardListItem";
// import BoardsFilter from "./BoardsFilter/BoardsFilter";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors from "/src/App/commonSelectors";
import { Switch } from "antd";
import { buildOptionsArr, getUniqueId } from "/src/lib/utils/helperMethods";
import {actions as genericActions} from "/src/App/genericRedux";
import RubricsList from "./RubricsList/RubricsList";
import RubricsCustomComponent, { validateJsonValue } from "./RubricCustomComponent";
import { GenericWidgetCreator, SelectProgram as SelectOrgProgramme, SelectSubject, SelectSubjectGroup } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import MrTranslate, { mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const SelectAssociableType = MrSelect({
  // actions: genericActions,
  // resourceName: "plans",
  config: {
    // url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      options: buildOptionsArr(["Programme", "ProgrammeSubjectGroup"]),
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"CommonText.status"}/>,
      // style: { width: "100%" },
      // allowClear: true
    },
  },
});

const SelectProgram = MrSelect({
  actions: genericActions,
  resourceName: "programmes",
  config: {
    url: "/programmes",
    params: { should_serialize: false },
    processData: (data, props) => {
      console.log("processData=====>", data);
      return data.map((item) => {
        return { label: item.name, value: item.id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: <MrTranslate id={"CommonText.select_programme_placeholder"}/>,
      // mode: "multiple",
      // style: { width: "500px" },
      allowClear: true
    },
  },
});

// const SelectSubjectGroup = MrSelect({
//   actions: genericActions,
//   // resourceName: "subjects",
//   resourceName: "programmesubjects",
//   apiResourceName: "programme_subjects",
//   config: {
//     url: "/programme_subjects",
//     params: { by_type_c: "group" },
//     processData: (data, props) => {
//       console.log("programme_subjects=====>", data)
//       return data.map((item) => {
//         // add to programme_label
//         return { label: item.programme_name+" - "+item.label , value: item.id };
//       });
//     },
//     // searchApi: true,
//     searchApi: false,
//     widgetConfig: {
//       showSearch: false,
//       filterOption: true,
//       optionFilterProp: "label",
//       placeholder: <MrTranslate id={"Rubrics.select_subject_group_placeholder"}/>,
//       allowClear: true
//     },
//   },
// });

// export const SelectProgramComponent = (props) => {
//   console.log("SelectProgramComponent Child props", props);
//   const {
//     getFieldValue,
//     sourceValues,
//     options,
//   } = props;
//   let finalRender = null
//     finalRender = (
//       <FormRenderer
//         formObj={{
//           type: "object",
//           properties: {
//             associable_id_programme: {
//               type: "string",
//               setInitialValue: true,
//               title: "Select Program",
//               widget: SelactOrgProgramme,
//             },
//           },
//         }}
//         propertyKey={"rubric_associations"}
//       />
//     );

//   return finalRender;
// };

const SelectSubjectWidget = GenericWidgetCreator((props) => {
  console.log("SelectSubjectWidget =======>", props.formInstance.getFieldValue(["rubric_associations", "associable_id_programme"]));
  return { config: { forceReload: true, params: { by_org_programme_id: props.formInstance.getFieldValue(["rubric_associations", "associable_id_programme"]) } } }
}, SelectSubject);

const SelectSubjectComponent = (props) => {
  console.log("SelectSubjectComponent Child props", props);
  const {
    getFieldValue,
    sourceValues,
    options,
    formInstance,
  } = props;
  const mrIntl = useTranslate()
  const associableIdProgramme = getFieldValue(["rubric_associations", "associable_id_programme"]);
  let finalRender = null
  if(associableIdProgramme) {
    finalRender = (
      <FormRenderer
        formInstance={formInstance}
        formObj={{
          type: "object",
          properties: {
            associable_id_subject: {
              type: "string",
              title: mrIntl("CommonText.select_subject"),
              // dependsOn: ["rubric_associations", "associable_id_programme"],
              widget: SelectSubjectWidget,
              rules: [
                {
                  required: true,
                  message: mrIntl("CommonText.required_msg"),
                },
              ]
            },
          },
        }}
        propertyKey={"rubric_associations"}
      />
    );
  }

  return finalRender;
}

const rubricAssociationForSuperAdmin = (mrIntl) => {
  return {
    type: "array",
    title: mrIntl("Rubrics.associations"),
    // wrapClassName: enableForSuperAdmin ? "" : "hidden",
    addButtonTitle: `+ ${mrIntl("Rubrics.add_association")}`,
    span: 24,
    min: 1,
    max: 100,
    default: [{}],
    items: {
      type: "object",
      properties: {
        associable_type: {
          type: "string",
          title: mrIntl("Rubrics.attached_to_title"),
          // placeholder: "Programme/ProgrammeSubject/Subject"
          // default: "Term 1"
          widget: SelectAssociableType,
          span: 8,
        },
        associable_id_programme: {
          type: "string",
          title: mrIntl("Rubrics.attached_to_id_title"),
          widget: SelectProgram,
          span: 8,
        },
        associable_id_subject_group: {
          type: "string",
          title: mrIntl("Rubrics.attached_to_id_title"),
          widget: SelectSubjectGroup,
          // placeholder: "Programme_id/Programme_subject_id/subject_id.."
          // default: "Term 1"
          span: 8,
        },
      },
      rules: [
        {
          required: true,
          message: mrIntl("CommonText.required_msg"),
        },
      ]
    }
  }
}

const rubricAssociationForTeacher = (mrIntl) => {
  return {
    type: "object",
    properties: {
      associable_id_programme: {
        type: "string",
        title: mrIntl("Rubrics.attached_to_id_title"),
        span: 8,
        widget: SelectOrgProgramme,
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.required_msg"),
          },
        ]
      },
      associable_id_subject: {
        type: "string",
        title: mrIntl("Rubrics.attached_to_id_title"),
        dependsOn: ["rubric_associations", "associable_id_programme"],
        children: SelectSubjectComponent,
        span: 8,
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.required_msg"),
          },
        ]
      },
    }
  }
}

const returnForm = (props) => {
  console.log("returnForm====>", props);
  const { mrIntl, formState } = props
  const formValues = formState.values;
  const enableForSuperAdmin = props.currentUser.role === "superadmin" ? true : false;
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("processFormData ======>", dataProps, opts);

      // TEMP hack until array for index and depends on 
      if(opts.values.rubric_associations){
        let newRubricAssociations
        if(enableForSuperAdmin) {
          newRubricAssociations = opts.values.rubric_associations.map((item) => {
            let associable_id
            if(item.associable_type == "Programme"){
              associable_id = item.associable_id_programme
            }else if(item.associable_type == "ProgrammeSubjectGroup"){
              associable_id = item.associable_id_subject_group
            }
            return {
              ...item,
              associable_id: associable_id,
            }
          });
        } else {
          newRubricAssociations = [{
            associable_id: opts.values.rubric_associations.associable_id_subject,
            associable_type: "Subject",
            // associable_id_programme: opts.values.rubric_associations.associable_id_programme,
          }]
          if(opts.values.rubric_associations.id) {
            newRubricAssociations[0].id = opts.values.rubric_associations.id
          }
        }
        

        if(opts.values.type_c === "custom"){
          // Default value not working in nested form so having to add level_id in each level before post
          opts.values.criteria.criteria.map((criterium, i) => {
            // if(criterium.type === "strands") {
            //   console.log("strands index =======>", i);
            //   opts.values.criteria.criteria[i-1].strands.push(criterium)
            //   return opts.values.criteria.criteria.splice(i, 1)
            // }
            let levelsJson = criterium.levels_json
            if(levelsJson && levelsJson.length){
              let newLevelsJson = levelsJson.map((level) => {
                if(!level.id){
                  level.id = getUniqueId(10)
                }
              })
              return {
                ...criterium,
                newLevelsJson
              }
            }else{
              return {
                ...criterium,
              }
            }
          });
        }
        opts.values.criteria_attributes = opts.values.criteria.criteria;
        
        console.log("newRubricAssociations", newRubricAssociations);
        opts.values.rubric_associations_attributes = newRubricAssociations;
      }
      
      if(props.currentUser && props.currentUser.role === "superadmin"){
        opts.values.public = true
      }else{
        opts.values.org_id = props.currentUser.org_id
        opts.values.public = false
      }
      opts.values.settings = opts.values.criteria.settings
      opts.values.criteria = opts.values.criteria.criteria
      console.log("rubric process form dataProps and opts", dataProps, opts);
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        title: {
          type: "string",
          placeholder: mrIntl("CommonText.title")
        },
        desc: {
          type: "string",
          default: "",
          placeholder: mrIntl("CommonText.description")
        },
        type_c: {
          type: "string",
          span: 24,
          title: mrIntl("CommonText.type"),
          widget: "RadioGroupWidget",
          default: enableForSuperAdmin ? "points" : "custom",
          wrapClassName: !enableForSuperAdmin && "hidden",
          widgetConfig: { 
            // disabled: formValues.type_c ? true : false,
            options: [
              {
                label: mrIntl("CommonText.points"),
                value: "points",
              },
              {
                label: mrIntl("CommonText.myp_criteria_with_points"),
                value: "criteria_with_points",
              },
              {
                label: mrIntl("CommonText.myp_achievement_level"),
                value: "myp_achievement_level",
              },
              {
                label: mrIntl("CommonText.comments_only"),
                value: "comments_only",
              },
              {
                label: mrIntl("Rubrics.custom_label"),
                value: "custom",
              },
            ],
          },
          rules: [
            {
              required: true,
              message: mrIntl("CommonText.required_msg"),
            },
          ]
        },
        public: {
          type: "string",
          default: enableForSuperAdmin ? true : false,
          title: mrIntl("Rubrics.public_title"),
          widget: Switch,
          valuePropName: "checked",
          wrapClassName: "hidden",
        },
        // TODO: DON'T use settings. Use the fact that if levels present or not - if no levels, the give open input in question creation (criteria selection + max points) and in grading. if levels preset, then show selection of pre-decided level values mentioned in criteria -> levels_json that user chooses from while grading - nothing while creating a question - just criteria selection
        // for rubric_associable_type and id, use array type form -> can be select type and then select program/subject Select
        rubric_associations: enableForSuperAdmin ? rubricAssociationForSuperAdmin(mrIntl) : rubricAssociationForTeacher(mrIntl),

        // ADD array form for criteria instead

        // Points is also a rubric with single criteria and no name or label
        // myp criteria with points is a separate rubric with no min/MaxLevel
        // myp achievement level is a separate rubric with min/MaxLevel

        // criteria_json not needed
        // use array or array form
        criteria: {
          title: mrIntl("CommonText.subjects"),
          type: "string",
          // default: "{}",
          widget: RubricsCustomComponent,
          trigger: 'onSubmit',

          formItemConfig: {
            noStyle: true,
            validateTrigger: 'onSubmit',
          },
          rules: [{
            validator(rule, value) {
              const validation = validateJsonValue(value)
              console.log("validateJsonValue======>", validation, value);
              if (!validation.success) {
                return Promise.reject(validation.messages)
              } else {
                return Promise.resolve()
              }
            },
          }]
          // rules: [{
          //   validator(rule, value) {
          //     console.log("validator====>", rule, value);
          //   }}
          // ],
        }
        // criteria: {
        //   type: "array",
        //   addButtonTitle: "+ Add criteria",
        //   span: 24,
        //   min: 1,
        //   max: 10,
        //   title: "Criteria",
        //   default: [{}],
        //   items: {
        //     type: "object",
        //     properties: {
        //       title: {
        //         type: "string",
        //         title: "Title",
        //         span: 4,
        //       },
        //       label: {
        //         type: "string",
        //         title: "Label",
        //         span: 8,
        //       },
        //       desc: {
        //         type: "string",
        //         title: "Description",
        //         span: 8,
        //       },
        //       levels_json: {
        //         type: "array",
        //         addButtonTitle: "+ Add level",
        //         // span: 24,
        //         span: 6,
        //         min: 1,
        //         max: 10,
        //         title: "Levels",
        //         // default: [{}],
        //         items: {
        //           type: "object",
        //           properties: {
        //             // id: {
        //             //   type: "string",
        //             //   title: "Level points",
        //             //   // className: "hidden",
        //             //   default: getUniqueId(10),
        //             //   span: 5,
        //             // },
        //             level_points: {
        //               type: "string",
        //               title: "Level points",
        //               // default: 1,
        //               // span: 3,
        //               span: 24,
        //             },
        //             level_label: {
        //               type: "string",
        //               title: "Level/Grade",
        //               // span: 4,
        //               span: 24,
        //             },
        //             desc: {
        //               type: "string",
        //               title: "Description",
        //               // span: 15,
        //               span: 24,
        //             },
        //           }
        //         }
        //       },
        //     }
        //   }
        // },
        
      },
    },
    uiSchema: {},
  };
  return form;
};


const processRubricFormInitialValues = (data) => {
  console.log( "initial data",  );
  let newData = data
  // let newData = {
    //   ...data,
  //   nestedValues: {
  //     ...data.nestedValues,
  //     tempNested: "tempNested updated"
  //   },
  //   name: "hello1"
  // }
  // data = nestedValues
  

  if(data.rubric_associations){
    // let rubricAssociationsAttributes = formatJsonApiData(data.rubric_associations)
    let rubricAssociationsAttributes = data.rubric_associations
    
    if(newData.type_c === "custom") {
      if(newData.rubric_associations[0]) {
        newData.rubric_associations = {
          ...rubricAssociationsAttributes[0],
          associable_id_subject_group: rubricAssociationsAttributes[0].associable_id,
          associable_id_subject: rubricAssociationsAttributes[0].associable_id,
        }
        if(rubricAssociationsAttributes[0].org_programme_id) {
          newData.rubric_associations = {
            ...newData.rubric_associations,
            associable_id_programme: rubricAssociationsAttributes[0].org_programme_id,
          }
        }
      }
    } else {
      newData.rubric_associations = rubricAssociationsAttributes.map((item) => {
        console.log( "processing initial data", item );
        let associable_id_programme, associable_id_subject_group, associable_id_subject
        if(item.associable_type == "Programme"){
          associable_id_programme = item.associable_id
          console.log( "processing initial data associable_id_programme", associable_id_programme );
        }else if(item.associable_type == "ProgrammeSubjectGroup"){
          associable_id_subject_group = item.associable_id
          console.log( "processing initial data associable_id_subject_group", associable_id_subject_group );
        } else if(item.associable_type == "Subject") {
          associable_id_subject = item.associable_id
          if(item.org_programme_id) {
            associable_id_programme = item.org_programme_id
          }
        }
        return {
          ...item,
          associable_id_programme: associable_id_programme,
          associable_id_subject_group: associable_id_subject_group,
          associable_id_subject: associable_id_subject
        }
      });
    }
    console.log( "processed initial data", newData);
  }
  // if(newData.criteria.criteria.length) {
  //   newData.criteria.criteria.map((c, i) => {
  //     if(c.strands && c.strands.length > 0) {
  //       console.log("newData.criteria.criteria =====>",  newData.criteria.criteria );
  //       newData.criteria.criteria.splice(i + 1, 0, ...c.strands)
  //       return newData.criteria.criteria[i].strands = []
  //     } else {
  //       newData.criteria.criteria[i].strands = []
  //     } 
  //   })
  if(data.criteria && data.criteria.length > 0) {
    newData = {
      ...newData,
      criteria: {
        criteria: data.criteria,
        settings: data.settings,
      }
    }
  }
  console.log("newData ======>", newData, data.criteria);
  return newData;
} 


const Rubrics = MrCrudView({
  resourceName: "rubrics",
  singleResourceName: "rubric",
  displayName: "resourceName",
  resourceUrl: "/rubrics",
  // filter: BoardsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  // listItem: BoardListItem,
  forms: [{ name: "rubrics", form: returnForm, config: {processInitialValues: processRubricFormInitialValues} }],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: (props) => {
        console.log( "itemDetail props", props );
        return <span><MrTranslate id={"CommonText.item_detail"}/> {JSON.stringify(props.item)}</span>
      },
    },
    itemDetail: {
      widget: (props) => {
        console.log( "itemDetail props", props );
        return <span><MrTranslate id={"CommonText.item_detail"}/> {JSON.stringify(props.item)}</span>
      }
      // widget: ProgrammeItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      widget: (props) => {
        return <span><MrTranslate id={"CommonText.filter"}/></span>;
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      widget: RubricsList,
      // widget: (props) => {
      //   return <span>List</span>;
      // },
    }
  },
});

// Rubrics.defaultProps = {};

Rubrics.propTypes = {};
export default Rubrics;
