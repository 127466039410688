import React from "react";
import { MrSelect } from "mr_react_framework";
import { buildOptionsArr } from "/src/lib/utils/helperMethods";
import { actions as genericActions } from "/src/App/genericRedux";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";
import { SelectCountry } from "/src/components/AppSpecific/SelectSubject/SelectSubject";

const SelectPlan = MrSelect({
  actions: genericActions,
  resourceName: "plans",
  config: {
    url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      console.log("select plan", data);
      return data.map((item) => {
        return { label: item.name, value: item.id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select plan",
      placeholder: <MrTranslate id={"CommonText.select_plan"} />,
      mode: "multiple",
      // style: { width: "200px" },
      allowClear: true,
    },
  },
});

const SelectSubscriptionStatus = MrSelect({
  // actions: genericActions,
  // resourceName: "plans",
  config: {
    // url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      options: buildOptionsArr([
        "Trial",
        "Customer",
        "Strong Lead",
        "Incoming Lead",
        "Accepted Lead",
        "Qualified Lead",
        "No Contact",
        "Closed: Cold Lead",
        "Closed: Not Interested",
        "Closed: Churn",
      ]),
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Status",
      mode: "multiple",
      // style: { width: "100%" },
      allowClear: true,
    },
  },
});

const SelectMB = MrSelect({
  // actions: genericActions,
  // resourceName: "plans",
  config: {
    // url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      options: buildOptionsArr(["All", "MB", "Non-MB"]),
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Type",
      // style: { width: "100%" },
      allowClear: true,
    },
  },
});

const SortDates = MrSelect({
  // actions: genericActions,
  // resourceName: "plans",
  config: {
    // url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      options: [
        { label: "Recent customers", value: "recent_customers" },
        { label: "Oldest customers", value: "oldest_customers" },
        { label: "Renewal soon", value: "renewal_soon" },
        { label: "Trial ending soon", value: "trial_ending" },
        // {label: "QUICK_CHECK", value: "QUICK_CHECK"},
        // {label: "FLASHCARD", value: "FLASHCARD"},
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Sort by dates",
      // style: { width: "100%" },
      allowClear: true,
    },
  },
});

const SortActive = MrSelect({
  // actions: genericActions,
  // resourceName: "plans",
  config: {
    // url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      options: [
        { label: "Most active all time", value: "most_active_overall" },
        // {label: "Most active last 3m", value: "most_active_last_3m"},
        { label: "Least active all time (Non-zero)", value: "least_active_overall" },
        // {label: "Least active last 3m", value: "least_active_last_3m"},
        { label: "Most active mb task wise", value: "most_active_mb_task_wise" },
        { label: "Least active mb task wise (Non-zero)", value: "least_active_mb_task_wise" },
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Sort by active",
      // style: { width: "100%" },
      allowClear: true,
    },
  },
});

const SelectProgram = MrSelect({
  actions: genericActions,
  resourceName: "programmes",
  config: {
    url: "/programmes",
    params: { should_serialize: false },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.abbr, value: item.id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: <MrTranslate id={"CommonText.select_programme_placeholder"}/>,
      mode: "multiple",
      style: { width: "200px" },
    },
  },
});

const OrgsFilter = (props) => {
  // let toRender;
  const extraFilters = [];
  let by_plan = [];
  let by_country_codes = [];
  let by_subscription_status = [];
  let by_mb = null;
  let sort_by_dates = null;
  let sort_by_active = null;
  // let by_recently_converted = null;
  // let by_programme_id = null;

  if (props.by_country_codes) {
    by_country_codes = props.by_country_codes;
  }
  extraFilters.push(
    <SelectCountry
      key="orgs-select-countries"
      value={by_country_codes}
      onChange={(value) => props.setParams({ by_country_codes: value })}
      config={{
        widgetConfig: { style: { width: "200px" }, mode: "multiple" },
      }}
    />
  );

  if (props.by_plan) {
    by_plan = props.by_plan;
  }
  extraFilters.push(
    <SelectPlan
      key="orgs-select-plan"
      value={by_plan}
      onChange={(value) => props.setParams({ by_plan: value })}
      config={{
        widgetConfig: { style: { width: "200px" } },
      }}
    />
  );

  if (props.by_subscription_status) {
    by_subscription_status = props.by_subscription_status;
  }
  extraFilters.push(
    <SelectSubscriptionStatus
      key="orgs-select-status"
      value={by_subscription_status}
      onChange={(value) => props.setParams({ by_subscription_status: value })}
      config={{
        widgetConfig: { style: { width: "150px" } },
      }}
    />
  );

  // if(props.by_programme_id){
  //   by_programme_id = parseInt(props.by_programme_id)
  // }
  extraFilters.push(
    <SelectProgram
      key="orgs-select-program"
      value={props.by_programme_ids}
      onChange={(value) => props.setParams({ by_programme_ids: value })}
      config={{
        widgetConfig: { style: { width: "150px" } },
        clearValueOnParamsChange: false,
        // params: {by_ids: props.by_programme_ids}
      }}
    />
  );

  if (props.by_mb) {
    by_mb = props.by_mb;
  }
  extraFilters.push(
    <SelectMB
      key="orgs-select-mb"
      value={by_mb}
      onChange={(value) => props.setParams({ by_mb: value })}
      config={{
        widgetConfig: { style: { width: "100px" } },
      }}
    />
  );

  if (props.sort_by_dates) {
    sort_by_dates = props.sort_by_dates;
  }
  extraFilters.push(
    <SortDates
      key="orgs-select-sort-dates"
      value={sort_by_dates}
      onChange={(value) =>
        props.setParams({ sort_by_dates: value, sort_by_active: null })
      }
      config={{
        widgetConfig: { style: { width: "150px" } },
      }}
    />
  );

  if (props.sort_by_active) {
    sort_by_active = props.sort_by_active;
  }
  extraFilters.push(
    <SortActive
      key="orgs-select-sort-active"
      value={sort_by_active}
      onChange={(value) =>
        props.setParams({ sort_by_active: value, sort_by_dates: null })
      }
      config={{
        widgetConfig: { style: { width: "150px" } },
      }}
    />
  );

  // if(props.by_recently_converted){
  //   by_recently_converted = props.by_recently_converted
  // }
  // extraFilters.push(
  //   <Checkbox key="checkboxRecentlyConvertedFilter" checked={by_recently_converted} onChange={(e) => {console.log("original filter value", e); props.setParams({by_recently_converted: e.target.checked})}}>Recently converted</Checkbox>
  // )

  // if(props.by_mb){
  //   by_mb = props.by_mb
  // }
  // extraFilters.push(
  //   <Checkbox key="orgs-checkbox-mb" value={by_mb} onChange={(e) => {console.log("original filter value", e); props.setParams({by_mb: e.target.checked})}}>MB</Checkbox>
  // )
  extraFilters.push(<ResetFilter setParams={props.setParams} />);
  return extraFilters;
};
export default OrgsFilter;
