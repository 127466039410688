import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import "./PoweredBy.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import CKEDITOR from "@ckeditor/ckeditor5-super-build";
import { CKEBasicTools, WirisMathTool, updateConfigForTool } from "./CKEConfig";
import { debounce } from "lodash";
import { Skeleton } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import * as Sentry from "@sentry/react";

import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import {
  activeAdjustedExperienceSelector,
  printModeSelector,
} from "/src/views/Experiences/selector";

import loadable from "@loadable/component";
import Listeners from "@wiris/mathtype-html-integration-devkit/src/listeners";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";


const ckLib = loadable.lib(() =>
  import(/* webpackChunkName: "ckeditor" */ "@ckeditor/ckeditor5-super-build")
);

const BasicCKE = (props) => {
  console.log("BasicCKE props ===>", props)
  const CKEDITOR = window.CKEDITOR || {};
  const mrIntl = useTranslate()

  const printMode = useSelector(printModeSelector());

  let ckEditor,
    config = {};
  if (props.classicEditor) {
    ckEditor = CKEDITOR.ClassicEditor;
  } else {
    ckEditor = CKEDITOR.InlineEditor;
  }

  if (props.tools) {
    config = {
      ...props.tools,
    };
  } else {
    config = {
      ...CKEBasicTools,
    };
  }
  config = {
    ...config,
    placeholder: !printMode ? props.placeholder || mrIntl("CommonText.write_here") : "",
    simpleUpload: {
      ...config.simpleUpload,
      headers: {
        ...config.simpleUpload.headers,
        token: localStorage.getItem("token"), // Token should be latest, in case of logout/caching it was going null
      },
    },
  };

  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());

  const experienceSettings = activeExperience.settings || {};

  let currentData = "";
  const propsData = props.data;

  const myEditor = useRef();
  const [initialData, setInitialData] = useState(propsData);
  const [showCKE, setShowCKE] = useState(false);
  const [reloadCKE, setReloadCKE] = useState(0);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [pendingDebounce, setPendingDebounce] = useState(false);
  console.log("propsData initialData===>",propsData, initialData);
  const wirisMathPluginEnabled =
    enabledFeatures.wiris_math_plugin;
  const [showWirisMath, setShowWirisMath] = useState(wirisMathPluginEnabled);

  if (showWirisMath) {
    config = updateConfigForTool(config, WirisMathTool.toolbarName);
  }

  // const onTextChangedWithDebounce = debounce((data) => {
  // 	currentData = myEditor.current.getData()
  // 	props.onTextChanged(currentData)
  // }, 500)

  useEffect(() => {
    if (myEditor.current && stateUpdatedCount > 0) {
      const data = myEditor.current.getData();
      const extra = {
        wordCount: config.wordCount,
      };
      setPendingDebounce(false);
      if (props.onTextChanged) {
        props.onTextChanged(data, extra);
      }
    }
  }, [stateUpdatedCount]);

  useEffect(() => {
    // if (config.isReadOnly)  {
      if (myEditor.current) {
        // myEditor.current.isReadOnly = config.isReadOnly;
        if (config.isReadOnly) {
          myEditor.current.enableReadOnlyMode(`basic-editor-${props.itemId}`); // new method for setting readonly mode in ckeditor
        } else {
          myEditor.current.disableReadOnlyMode(`basic-editor-${props.itemId}`);
        }
      }
    // }
  }, [config.isReadOnly])

  // sending data from useEffect because debounce using old values of index in parent components
  const onTextChangedWithDebounce = debounce((data, extra = {}) => {
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  }, 1000);

  const handleChange = useCallback(onTextChangedWithDebounce, []);

  const handleBlur = (data) => {
    if (pendingDebounce) {
      onTextChangedWithDebounce.cancel();
      setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
    }
  };
  
  const handleFocus = (data) => {};

  var onBeforeFormulaInsertion = (event) => {
    const internetStatus = localStorage.getItem("internetStatus")
    console.log(
      "onBeforeFormulaInsertion internetStatus and event",
      internetStatus,
      event
    );
    if (internetStatus === "offline" || internetStatus === "network_issue") {
      message.error(
        "Your internet is not working. Please resolve that to proceed."
      );
      event.defaultPrevented();
      return event;
    }
  };

  useEffect(() => {
    if (myEditor.current) {
      currentData = myEditor.current.getData();
      // Check why inconsistent for fill
      if (currentData != propsData && propsData == "") {
        myEditor.current.setData("");
      }
    }
  }, [propsData]);

  useEffect(() => {
    let ckeTimeout;
    setShowCKE(false);
    if (!window.CKEDITOR) {
      ckLib.load().then((module) => {
        window.CKEDITOR = module.default;
        if (window.CKEDITOR && window.CKEDITOR.InlineEditor) {
          ckeTimeout = setTimeout(() => {
            setShowCKE(true);
          }, 10);
        }
      });
    } else {
      if (window.CKEDITOR && window.CKEDITOR.InlineEditor) {
        ckeTimeout = setTimeout(() => {
          setShowCKE(true);
        }, 10);
      }
    }
    return () => {
      if (ckeTimeout) {
        clearTimeout(ckeTimeout);
      }
      if (myEditor.current) {
        myEditor.current = null;
      }
    };
  }, [reloadCKE]);
  
  return (
    // <div
    // 	key={`basic-ckeditor-${props.itemId}`}
    // 	ref={basicCKERef}
    // 	className='ck-div'
    // 	// placeholder={config.placeholder}
    // 	// contenteditable="true"
    // 	dangerouslySetInnerHTML={{__html: initialData}}
    // >
    // </div>
    showCKE ? (
      <CKEditor
        key={`basic-ckeditor-${props.itemId}`}
        editor={ckEditor}
        config={config}
        data={initialData}
        onReady={(editor) => {
          if (editor) {
            if (config.autoFocus || config.autoFocusAtEnd) {
              editor.focus()
              if (config.autoFocusAtEnd) {
                try {
                  editor.model.change((writer) => {
                    writer.setSelection(writer.createPositionAt(editor.model.document.getRoot(), "end"));
                  });
                } catch (error) {
                  console.error("An error occurred:", error);
                  Sentry.captureException(error);
                }
              }
            }
            if (config.isReadOnly) {
              editor.enableReadOnlyMode(`basic-editor-${props.itemId}`);
            }
            myEditor.current = editor;
            console.log("Editor is ready to use!", config, editor);
            const { blockedKeys } = config;
            if (blockedKeys) {
              editor.editing.view.document.on(
                "keydown",
                (evt, data) => {
                  blockedKeys.forEach(() => {
                    if (data.keyCode == 13) {
                      data.stopPropagation();
                      data.preventDefault();
                      evt.stop();
                    }
                  });
                },
                { priority: "highest" }
              );
            }
            if (config.pastePlainText) {
              editor.editing.view.document.on('clipboardInput', (evt, data) => {
                const dataTransfer = data.dataTransfer;
                let content = dataTransfer.getData('text/plain');
                data.content = editor.data.htmlProcessor.toView(removeHTMLTagsFromText(content, true))
                console.log("plan text ====>", content, editor);
              });
            }
            if (window.WirisPlugin) {
              window.WirisPlugin.currentInstance.core.listeners.add(
                Listeners.newListener(
                  "onBeforeFormulaInsertion",
                  onBeforeFormulaInsertion
                )
              );
            }
          }
        }}
        onChange={(event, editor) => {
          // issue was here--- in onChange editor.getData was getting called while math was getting inserted although we were never using it so removing
          // const data = editor.getData();
          // console.log("this is si", editor.getData());
          // const extra = {
          //   wordCount: config.wordCount,
          // };
          handleChange();
          if (!pendingDebounce) {
            setPendingDebounce(true);
          }
        }}
        onBlur={(event, editor) => {
          const data = editor.getData();
          handleBlur(data);
          console.log("on blur called ==>", event)
          if (props.onExitEditing) {
            props.onExitEditing(data);
          }
        }}
        onFocus={(event, editor) => {
          const data = editor.getData();
          handleFocus(data);
        }}
        onError={(error, { phase }) => {
          console.error("Error happend in ckeditor ==>", error.message, phase);
          if (phase == "initialization") {
            if (error.message.indexOf("wiris") >= 0) {
              setShowWirisMath(false);
              setReloadCKE(1);
            }
          }
        }}
      />
    ) : (
      <Skeleton.Input active={true} size={"small"} />
    )
    // /> : appType == "seb" ? <TextArea placeholder="Write here.." rows={2} /> : <Skeleton.Input active={true} size={'small'} />
  );
};

export default BasicCKE;
