import React, { useEffect, useState } from "react";

import { message } from "/src/components/UI/AntdAppHelper";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import axios from "axios";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useDispatch, useSelector } from "react-redux";
import { googleDriveAccessTokenSelector } from "/src/views/Auth/Login/selector";

const InsertFromDrive = (props) => {
  console.log("Insert From URL props", props);
  const { mediaFormSetting, setMediaFormSetting, onSaveMedia } = props;

  const [urlSetting, setUrlSetting] = useState({ invalidURL: true });
  const [gisInited, setGisInited] = useState(false);
  const [tokenClient, setTokenClient] = useState(null);
  const dispatch = useDispatch();
  const googleDriveAccessTokenFromRedux = useSelector(googleDriveAccessTokenSelector())
  // TODO add these to environment file
  const clientId = import.meta.env.VITE_DRIVE_UPLOAD_CLIENT_ID;
  const apiKey = import.meta.env.VITE_DRIVE_UPLOAD_API_KEY;
  const appId = import.meta.env.VITE_DRIVE_UPLOAD_APP_ID;
  let accessToken = googleDriveAccessTokenFromRedux || null;
  const gapi = window.gapi;
  const google = window.google;
  const mrIntl = useTranslate();
  let googlePicker, viewId;

  useEffect(() => {
    onApiLoad();
    gisLoaded();
    if (gisInited) {
      createPicker();
    }
  }, [gisInited]);

  const onPickerApiLoad = async () => {
    try {
      await gapi.client.load(
        "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
      );
    } catch (error) {
      console.error("Error loading Google Picker API:", error);
    }
  };

  const gisLoaded = () => {
    try {
      if(!tokenClient) {
        let tokenClient1 = google.accounts.oauth2.initTokenClient({
          client_id: clientId,
          scope: "https://www.googleapis.com/auth/drive.file",
          callback: "", // defined later
          error_callback: "",
        });
        setTokenClient(tokenClient1)
        setGisInited(true);
      }
    } catch (error) {
      console.log("error in gisLoaded", error);
    }
  };
  const onApiLoad = () => {
    gapi.load("client:picker", onPickerApiLoad);
    // gapi.load('drive', onPickerApiLoad);
  };

  const checkAccessTokenValidity = async (token) => {
    try {
      const response = await fetch(
        "https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=" +
          token
      );
      console.log("validityCheck accesstoken===>", response);
      return response.ok;
    } catch (error) {
      console.error("Error checking token validity", error);
      return false;
    }
  };

  const showPicker = () => {
    console.log("picker content_type===>", mediaFormSetting.comtent_type);
    googlePicker = google.picker || {};
    viewId = googlePicker.ViewId;
    let view = new googlePicker.View(viewId.DOCS);
    if (mediaFormSetting.content_type === "image") {
      view = new googlePicker.View(viewId.DOCS_IMAGES);
    } else if (mediaFormSetting.content_type === "pdf") {
      view = new googlePicker.View(viewId.PDFS);
    } else if (mediaFormSetting.content_type === "ppt") {
      view = new googlePicker.View(viewId.PRESENTATIONS);
    } else if (mediaFormSetting.content_type === "doc") {
      view = new googlePicker.View(viewId.DOCUMENTS);
    }

    const picker = new googlePicker.PickerBuilder()
      .addView(view)
      // .enableFeature(googlePicker.Feature.NAV_HIDDEN)
      // .enableFeature(googlePicker.Feature.MULTISELECT_ENABLED)
      .enableFeature(googlePicker.Feature.MINE_ONLY)
      .setDeveloperKey(apiKey)
      .setAppId(appId)
      .setOAuthToken(accessToken)
      .setCallback(pickerCallback)
      .build();
    picker.setVisible(true);
  };
  const createPicker = async () => {
    tokenClient.callback = (response) => {
      console.log("pickerLogss===> response", response);
      if (response.error !== undefined) {
        console.log("picker response error not undefined", response);
        handleCancel();
        throw response;
      }
      accessToken = response.access_token;
      dispatch(loginActions.setGoogleDriveAccessTokenSuccess({ accessToken: accessToken }));
      showPicker();
    };
    tokenClient.error_callback = (error) => {
      console.log("picker error===>", error);
      handleCancel();
    };

    let isAccessTokenValid = await checkAccessTokenValidity(accessToken); 
    console.log("isAccessTokenValid ==>", isAccessTokenValid );
    if (accessToken && isAccessTokenValid) {
      showPicker();
    } else {
      let params = { prompt: "consent" };
      tokenClient.requestAccessToken(params);
    }
  };
  const checkFilePermissions = (fileId) => {
      return gapi.client.drive.permissions
        .list({
          fileId: fileId,
        })
        .then((response) => {
          console.log("picker file permissions", response);
          var permissions = response.result && response.result.permissions;
          if (permissions) {
            // Check if there is a permission for anyone with link and role as reader
            var hasAnyoneWithLink = permissions.some((permission) => {
              return (
                permission.type === "anyone" && permission.role === "reader"
              );
            });
            if (hasAnyoneWithLink) {
              // File is already shared with anyone with link in reader view
              return true;
            } else {
              // File is not shared with anyone with link in reader view
              return false;
            }
          } else {
            console.error("Failed to retrieve file permissions.");
            return false;
          }
        })
        .catch((error) => {
          console.log("error in checkFilePermissions", error);
        });
  };
  const confirmChangePermissions = (fileId, embedUrl, fileName) => {
    showConfirmModal({
      centered: true,
      title: mrIntl("InsertFromDrive.permission_update_required"),
      content: (
        <>
          <p>{mrIntl("InsertFromDrive.this_file_has_restricted_access_to_ensure_all_students_can")}</p>
          <p>{mrIntl("InsertFromDrive.allow_assessprep_to_update_the_sharing_settings")}</p>
          <p><strong>{mrIntl("InsertFromDrive.note")}:</strong> {mrIntl("InsertFromDrive.students_will_only_be_able_to_view_the_file_they")}</p>
        </>
      ),
      okText: mrIntl("InsertFromDrive.update_settings"),
      cancelText: mrIntl("CommonText.cancel"),
      okType: "primary",
      onOk: () => {
        insertPermission(fileId, embedUrl, fileName);
      },
      onCancel: () => {
        handleCancel();
      },
      mrIntl: mrIntl,
    });
  };
  const insertPermission = (fileId, embedUrl, fileName) => {
    var body = {
      role: "reader",
      type: "anyone",
    };
    try {
      gapi.client.drive.permissions
        .create({
          fileId: fileId,
          resource: body,
        })
        .then((response) => {
          console.log("Permission inserted successfully:", response.result);
          let file = {
            id: getUniqueId(),
            url: embedUrl,
            type: mediaFormSetting.content_type,
            name: fileName,
            s3_url: embedUrl,
            webm_url: embedUrl,
          };
          saveFile(file);
        });
    } catch (error) {
      console.log("error in insertPermission", error);
    }
  };
  const pickerCallback = async (data) => {
    console.log("pickerCallback===>", data, gapi, google);
    try {
      if (data[googlePicker.Response.ACTION] == googlePicker.Action.PICKED) {
        let doc = data[googlePicker.Response.DOCUMENTS][0];
        if (doc.driveSuccess) {
          setMediaFormSetting({
            ...mediaFormSetting,
            loading: true
          })
          const embedUrl = doc.embedUrl;
          const fileId = doc[googlePicker.Document.ID];
          const fileName = doc.name
          const contentType = doc.mimeType;
          // createFile(fileId, contentType, embedUrl);
          if (!mediaFormSetting.disableUploadURLToS3) {
            const token = localStorage.getItem("token");
            let downloadUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
            const config = {
              headers: {
                "content-type": "multipart/form-data",
                token: token,
              },
            };

            setMediaFormSetting({
              ...mediaFormSetting,
              loading: true,
            });

            const url =
              (import.meta.env.VITE_API_URL || "/backend/api/v1/") +
              "attachments";

            const formData = new FormData();
            formData.append("url", downloadUrl);
            formData.append(
              "headers",
              `Authorization: Bearer ${accessToken},Content-Type: ${contentType}`
            );
            axios.post(url, formData, config)
              .then((response) => {
                console.log("Returned response ==>", response);

                let files = [response.data];
                // remove when type comes from backend
                let modifiendFiles = files.map((file) => ({
                  ...file,
                  type: mediaFormSetting.content_type,
                  name: fileName
                }));
                saveFile(modifiendFiles);
              })
              .catch((e) => {
                console.log("error ==>", e, e.response);
                if (e.response) {
                  // message.error(e.response.data.error)
                  message.error("File upload failed. Check if url is valid.");
                }

                setMediaFormSetting({
                  ...mediaFormSetting,
                  okDisabled: false,
                });
              });
          } else {
            checkFilePermissions(fileId).then((hasPermission) => {
              if (!hasPermission) {
                // If file is not shared with anyone with link in reader view, prompt user for confirmation
                confirmChangePermissions(fileId, embedUrl, fileName);
              } else {
                let file = {
                  id: getUniqueId(),
                  url: embedUrl,
                  type: mediaFormSetting.content_type,
                  name: fileName,
                  s3_url: embedUrl,
                  webm_url: embedUrl,
                };
                saveFile(file);
              }
            });
          }
        } else {
          handleCancel();
          message.error("something went wrong while getting files from drive");
          if (doc.driveError) {
            console.log("error with picker===>", doc.driveError);
          }
        }
      } else if (
        data[googlePicker.Response.ACTION] == googlePicker.Action.CANCEL
      ) {
        handleCancel();
      }
    } catch (error) {
      setMediaFormSetting({
        ...mediaFormSetting,
        loading: false
      })
      console.log("pickerCallback error===>", error);
    }
  };

  const saveFile = (file) => {
    setUrlSetting({
      ...urlSetting,
      saved: true,
    });
    setMediaFormSetting({
      ...mediaFormSetting,
      loading: false,
    });


    if (onSaveMedia) {
      onSaveMedia(file);
    }
  };

  const handleCancel = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "",
      visible: false,
    });
  };

  return null;
};
export default InsertFromDrive;
