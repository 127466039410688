import { Button } from "antd";
import React from "react";

const GameStages = (props) => {
  const { handlePlayButton, onTryAgain, scoreButton } = props
  return (
    <div className={`${!props.play ? "start-view" : "hide-start-view"} ${props.attempt_left <= 0 && "all-attempt-done"}`}>
      {!props.gameEnded
        ? <Button onClick={() => handlePlayButton()} className="game-play-button">Play Game</Button>
        : <div>
          {props.gameOverMessage ? <p>{props.gameOverMessage}</p> : <p>{props.handleGameEndMessage ? "Game over you lose the game, here's your score." : "Game over, Congratulations you won the game here's your score."}
            <br /> 
            {scoreButton && scoreButton}
          </p>}
          <Button className="play-again" onClick={() => onTryAgain()}>Try again</Button>
        </div>}
    </div>
  )
}
export default GameStages;