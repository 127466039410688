import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useLocation } from "react-router-dom";
import { Button, Modal, Statistic, Alert } from "antd";
import { checkIPAD, checkMob } from "/src/lib/utils/helperMethods";
import { actions as firestoreInteractionActions, getOrCreateSessionId } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { getInternetStatusSelector } from "/src/views/Experiences/selector";
import {
  attachmentsCountSelector,
  currentSessionIdSelector,
  sessionIdSelector,
  uploadSessionIdSelector,
  userInfoSelector,
} from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const FileUploadModal = ({ sessionModalConfig, setShowQRModal, itemMode }) => {
  const dispatch = useDispatch();
  const mrIntl = useTranslate();

  const isMobile = checkMob() || (checkIPAD() && itemMode !== "offline");
  const userInfo = useSelector(userInfoSelector);
  const attachmentsCount = useSelector(attachmentsCountSelector);
  const internetStatus = useSelector(getInternetStatusSelector());
  const userInfoFromReduxSessionId = useSelector(sessionIdSelector);
  const userInfoFromReduxUploadSessionId = useSelector(uploadSessionIdSelector);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { embedded, experienceViewMode, setUserInfo } = sessionModalConfig;
  const mobileUploadMode = userInfo.mobile_upload_mode;
  const currentSessionIdFromSessionStorage = getOrCreateSessionId();

  useEffect(() => {
    const isOpenFileStatusModalForViews = ["startTest", "endTest"].includes(userInfo.currentView);

    // console.log("FileUploadModal session ids ==>", {
    //   currentSessionIdFromSessionStorage,
    //   userInfoFromReduxSessionId,
    //   userInfoFromReduxUploadSessionId,
    //   is_uploading_finished: userInfo.is_uploading_finished
    // });

    if (
      !embedded &&
      experienceViewMode === "apTakeTest" &&
      userInfoFromReduxSessionId &&
      currentSessionIdFromSessionStorage &&
      userInfoFromReduxUploadSessionId &&
      (
        (currentSessionIdFromSessionStorage === userInfoFromReduxSessionId && 
         currentSessionIdFromSessionStorage !== userInfoFromReduxUploadSessionId) ||
        (currentSessionIdFromSessionStorage === userInfoFromReduxUploadSessionId &&
         currentSessionIdFromSessionStorage !== userInfoFromReduxSessionId)
      ) &&
      isOpenFileStatusModalForViews
    ) {
      if (userInfo.is_uploading_finished) {
        dispatch(firestoreInteractionActions.getUserResponses());
        setIsModalVisible(isMobile);
      } else {
        setShowQRModal(false);
        setIsModalVisible(!isMobile);
      }
    }
  }, [currentSessionIdFromSessionStorage, userInfoFromReduxSessionId, userInfoFromReduxUploadSessionId, attachmentsCount, userInfo.is_uploading_finished]);

  const handleDone = () => {
    dispatch(firestoreInteractionActions.getUserResponses());
    setUserInfo({ is_uploading_finished: isMobile ? false : true });
    setIsModalVisible(false);
  };

  const renderModalContent = () => {
    if (!mobileUploadMode) return null;

    const uploadStats = (
      <>
        <h2>{mrIntl("SessionActiveModal.upload_status")}</h2>
        <Statistic
          title={mrIntl("SessionActiveModal.total_files_uploaded")}
          value={attachmentsCount}
        />
      </>
    );

    if (isMobile) {
      return (
        <>
          <Alert
            message={mrIntl("SessionActiveModal.if_your_test_page_is_not_showing_your_uploads_msg")}
            type="warning"
          />
          <br />
          {uploadStats}
        </>
      );
    }

    return uploadStats;
  };

  const buttonProps = {
    size: "large",
    className: "m-t-25",
    type: "primary",
    disabled: internetStatus !== "online",
    onClick: handleDone,
    danger: !isMobile,
    children: isMobile 
      ? mrIntl("SessionActiveModal.upload_more")
      : mrIntl("SessionActiveModal.done")
  };

  return (
    <Modal
      wrapClassName="height-centered-modal text-centered-modal"
      centered
      open={isModalVisible}
      closable={false}
      maskClosable={false}
      keyboard={false}
      footer={null}
      width={400}
    >
      {internetStatus !== "online" && (
        <Alert
          message={mrIntl("SessionActiveModal.internet_error")}
          banner
          closable={false}
        />
      )}
      {renderModalContent()}
      <Button {...buttonProps} />
    </Modal>
  );
};

export default FileUploadModal;
