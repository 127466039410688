import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import MediaShow from "/src/components/UI/Media/MediaShow";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { DeleteButton, DragHandle, InputArea, MediaHoverButton, ShowText, ShowTextFile, TextArea } from "/src/components/UI/Segment/UIHelper";
import React, { useEffect, useState } from "react";
import { useDrag } from 'react-dnd'
import { RiDragMoveLine } from "react-icons/ri";
import { DeleteOutlined } from "@ant-design/icons";
import styled from 'styled-components'

const DraggableContainer = styled.div`
  margin: 2px;
  min-width: 100px;
  max-width: 300px;
  white-space: break-spaces;
  border: 1px solid #eee;
  height: ${props =>
    props.stretchHeight
      ? '100%'
      : ''
  };
  background-color: ${props =>
    // console.log("Props in background color ==>", props.isDragging)
    props.isDragging
      ? 'lightcoral'
      // : '#f0f0f0'
      : '#fafafa'
  };
`

export const getRenderItem = (props) => {
  console.log('Draggable getRenderItemProps ==>', props)
  const { item } = props

  let className = ''
  if (props.direction == 'horizontal') {
    className += 'inline-draggable'
  }

  if (props.draggingDisabled || props.forcedDraggingDisabled) {
    className += ' dragging-disabled'
  }

  if (props.showCorrect) {
    if (item.correct) {
      className += ' correct-answer'
    } else {
      className += ' incorrect-answer'
    }
  }
  

  console.log("Classname in draggable item ==>", className, props)

  return (
    props.showTextArea ?
      <TextArea>
        <DragHandle
          key={`drag-handle-${item.id}`}
        >
          <RiDragMoveLine />
        </DragHandle>
        <InputArea>
          <BasicCKE
            key={`basic-ck-${item.id}`}
            itemId={item.id}
            data={item.content}
            onTextChanged={(data) => props.onTextChanged(props.index, data)}
          >
          </BasicCKE>
          {item.files && <MediaShow
            file={item.files && item.files[0]}
            removeFile={(e) => props.removeFile(props.index)}
          >
          </MediaShow>}
        </InputArea>
        {props.onSaveMedia && <MediaHoverButton
          key={`media-hover-button-${item.id}`}
          onSaveMedia={(files) => props.onSaveMedia(props.index, files)}
        ></MediaHoverButton>}
        {props.removeItem && <DeleteButton
          key={`delete-button-${item.id}`}
          clicked={(e) => props.removeItem(props.index)}
        >
          <DeleteOutlined />
        </DeleteButton>}
      </TextArea>
      :
      <ShowText
        key={`show-text-${item.id}`}
        className="draggable-content"
      >
        {item.content && <RenderHtml text={item.content}></RenderHtml>}
        {item.files && <ShowTextFile>
          <MediaShow
            file={item.files && item.files[0]}
            removeIcon={false}
          >
          </MediaShow>
        </ShowTextFile>}
      </ShowText>
  )
}

export const RDDraggableItem = (props) => {
  console.log("RDDraggableItem props ==>", props);
  const {  type, index, item, canDrag } = props
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
		// "type" is required. It is used by the "accept" specification of drop targets.
    type: type || 'Box',
    item: item,
		// The collect function utilizes a "monitor" instance (see the Overview for what this is)
		// to pull important pieces of state from the DnD system.
    canDrag: (monitor) => {
      return canDrag
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  const renderItem = getRenderItem(props)

  return (
    <DraggableContainer 
      // This is optional. The dragPreview will be attached to the dragSource by default
      // ref={dragPreview} 
      ref={drag}
      isDragging={isDragging} 
      {...props}
      // style={{ opacity: isDragging ? 0.5 : 1}}
    >
        {/* The drag ref marks this node as being the "pick-up" node */}
        {renderItem}
        {/* <div role="Handle" >
        </div> */}
    </DraggableContainer>
  )
}