import React from "react";
import { Link } from "react-router-dom";
import { Button, Space } from "antd";
import { useSelector } from "react-redux";
// import Spinner from "/src/components/UI/Spinner/Spinner";
import {} from "@ant-design/icons";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ErrorPage = (props) => {
  console.log("ErrorPage props", props);
  // const {history} = props;
  const appType = useSelector(appTypeSelector());
  const mrIntl = useTranslate()

  // let finalRender = []
  // const switchToApp = () => {
  //   history.push("/")
  // }

  return (
    <>
      <Space
        className="demo-reset-info-box"
        direction="vertical"
        align="center"
      >
        {mrIntl("ErrorPage.error_page_not_found")}
        <Link to="/">
          <Button type="primary">{mrIntl("CommonText.back_to_home")}</Button>
        </Link>
        {appType === "seb" && (
          <Button
            key="quit"
            danger
            href={`${import.meta.env.VITE_WEB_URL}/seb_quit.html`}
          >
            {mrIntl("CommonText.quit")}
          </Button>
        )}
      </Space>
    </>
  );
};

ErrorPage.defaultProps = {};

ErrorPage.propTypes = {};

export default ErrorPage;
