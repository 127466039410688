import React, { useState, useEffect } from "react";
import { Spinner, MrSelect } from "mr_react_framework";
import { useDispatch, useSelector } from "react-redux";
import {
  appRegionSelector,
  currentUserSelector,
} from "/src/views/Auth/Login/selector";
import { checkMob, displayPoints, getAPColorPallete } from "/src/lib/utils/helperMethods";
import { Button, Col, Row, Statistic, Switch, Table, Tag, Tooltip, Typography } from "antd";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import { activeAdjustedExperienceSelector, experienceAnalyticsTagsSelector } from "/src/views/Experiences/selector";
import loadable from "@loadable/component";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import {RightOutlined, LeftOutlined} from '@ant-design/icons';
import { drawTopLineLabels } from "./ExperienceAnalytics";

const { Title } = Typography;

const RCJChart = loadable(() =>
  import(/* webpackChunkName: "react-chart" */ "/src/views/Segments/RCJChart/RCJChart")
);

const TagsChart = (props) => {
  const { chartData } = props
  const mrIntl = useTranslate()
  console.log("ExperienceAnalyticsSummary TagsChart chartData", chartData)
  const apColors = getAPColorPallete();
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 10;
  const isPreviousDisabled = currentPage === 0;
  const totalPages = Math.ceil(chartData?.labels?.length / itemsPerPage);
  const isNextDisabled = currentPage >= totalPages - 1;
  const showNextAndPrevious = totalPages > 1;

  const getCurrentPageData = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return {
      labels: chartData?.labels?.slice(startIndex, endIndex),
      dataset: chartData?.dataset?.map(data => data.slice(startIndex, endIndex)),
    };
  };

  const currentData = getCurrentPageData();

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  
  let chart_settings = {
    type: "bar",
    // type: "pie",
    // title: "Student performance",
    xAxesLabel: mrIntl("ExperienceAnalyticsTags.syllabus_topic"),
    yAxesLabel: "Average (%)",
    labels: currentData.labels, 
    max: 100,
    // datasets: currentData.student_counts,
    // labels: ["10", "20", "30", "40"], // Column headers - week number or month number
    // labels: ["Jan", "Feb", "Mar", "Apr"], // Column headers - week number or month number
    datasets: [ // one object for each data row/line graph - one object for single line, multiple for multiple lines
      {
        data: currentData.dataset[0],
        // label: chartData.dataset[0],
        backgroundColor: apColors.lightblue,
        barThickness: 30,
      },
      // {
      //   data: chartData.dataset[1].values,
      //   label: chartData.dataset[1].label,
      //   backgroundColor: chartData.dataset[1].color
      // },
      // {
      //   data: chartData.dataset[2].values,
      //   label: chartData.dataset[2].label,
      //   backgroundColor: chartData.dataset[2].color
      // },
      // {
      //   data: chartData.dataset[3].values,
      //   label: chartData.dataset[3].label,
      //   backgroundColor: chartData.dataset[3].color
      // },
      // {
      //   data: chartData.dataset[4].values,
      //   label: chartData.dataset[4].label,
      //   backgroundColor: chartData.dataset[4].color
      // },
      // {
      //   data: chartData.dataset[5].values,
      //   label: chartData.dataset[5].label,
      //   backgroundColor: chartData.dataset[5].color
      // },

      
      // Can't do this on same scale/axes as the student count y axis
      // {
      //   type: "line",
      //   data: chartData.dataset[4],
      //   label: "Average",
      //   // backgroundColor: apColors.blue
      // }
    ],
    showGridLines: false,
    showLegend: false,
    customColors: true, // only supported in bar for now
    stacked: true,
    // stepSize: 2, // using precision: 0 instead // only supported in bar for now
    topLine: drawTopLineLabels(true),
    layout: {
      padding: {
        top: 20,
      },
    },
  }

  return <div className="question-graph-container">
  {showNextAndPrevious && <Tooltip title={mrIntl("ExperienceAnalyticsQuestions.previous")} placement="left">
    <Button
      type="primary"
      onClick={handlePrevious}
      disabled={isPreviousDisabled}
      icon={<LeftOutlined />}
    />
  </Tooltip>}
  <Row className="question-graph">
    <Col span={24}>
      <Title level={3}>
        {mrIntl("CommonText.points")}
      </Title>
    </Col>
    <Col span={24}>
      <RCJChart chart_settings={chart_settings} />
    </Col>
  </Row>
  {showNextAndPrevious && <Tooltip title={mrIntl("ExperienceAnalyticsQuestions.next")} placement="right">
    <Button
      type="primary"
      onClick={handleNext}
      disabled={isNextDisabled}
      icon={<RightOutlined />}
    />
  </Tooltip>}
</div>
}

const TagsStudentsGrid = (props) => {
  const { data, showProficiencyLevels } = props;
  const eus = data.eus;
  const mrIntl = useTranslate()
  const tags = data.tags;
  const tagsData = data.tags_data;
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  let finalRender = [];

  useEffect(() => {
    let rows = [];
    let columns = [];
    columns[0] = {
      // title: "Students ("+eus.length+")",
      title: mrIntl("ExperienceAnalyticsTags.students_eus_length", {euslength: eus.length}),
      dataIndex: "name",
      key: "name",
      width: 150,
      fixed: "left",
      // ellipsis: {
      //   showTitle: true,
      // },
      sorter: (a, b) => a.name?.props?.children?.localeCompare(b.name?.props?.children),
      sortDirections: ['ascend', 'descend'],
      defaultSorter: 'ascend'
    };
    columns[1] = {
      title: mrIntl("ExperienceAnalyticsTags.avg_percentage"),
      dataIndex: "average_percentage",
      key: "average_percentage",
      width: 100,
      sorter: (a, b) => a.average_percentage - b.average_percentage,
      sortDirections: ["ascend", "descend"],
      defaultSorter: "ascend",
    };
    eus.forEach((eu, i) => {
      let row = {}
      row.id = eu.id
      row.name = (
        <span
          // style={{ cursor: "pointer" }}
          // onClick={() => {
          //   setSelectedExperienceUser(eu.id);
          //   setShowGradingGridModal(false);
          // }}
        >
          {eu.name}
        </span>
      );
      row.average_percentage = (
        <span>{displayPoints((eu.points*100)/eu.custom_fields.experience_points)}%</span>
      );
      
      if (tags) {
        tags.forEach((tag, j) => {
          const label = tag.label || "";
          const lettersBeforeFirstSpace = label.split(" ")[0];
          if (i == 0) {
            columns.push({
              title: (
                // <Space direction={"vertical"}>
                <Tooltip title={label}>
                  <span>{lettersBeforeFirstSpace}</span>
                </Tooltip>
                //   <span>
                //     ({displayPoints(segment.points, false, true)})
                //   </span>
                // </Space>
              ),
              dataIndex: j,
              key: "column-"+j,
              width: 100,
              sorter: (a, b) => a.j - b.j,
              sortDirections: ["ascend", "descend"],
              defaultSorter: "ascend",
            });
          }

          let tagData = tagsData.find((td) => td.user_id === eu.user_id && td.tag_id === tag.tag_id);
          if (tagData) {
            let tagPercentage = displayPoints(tagData.average_percentage);
            row[j] = (
              <span>
                {tagPercentage}% <br />
                {showProficiencyLevels && <Tag color={tagData.proficiency_level.color}>{tagData.proficiency_level.label}</Tag>}
                {/* // TODO: make this tag conditional on if org.grading_scales set? */}
              </span>
            );
          }
        });
      }
      rows.push(row);
    });

    console.log("GradingGrid rows columns", rows, columns);
    setGridRows(rows);
    setGridColumns(columns);
  }, [ tagsData, eus, showProficiencyLevels ]);

  let tableRender = <Table
    // className={"grading-ggrid"}
    dataSource={gridRows}
    columns={gridColumns}
    pagination={false}
    // size="small"
    showHeader={true}
    locale={{ triggerDesc: null, triggerAsc: null, cancelSort: null }}
    scroll={{ x: 800, y: 500 }}
  />
  
  finalRender.push(tableRender)
  return finalRender;
}

const ExperienceAnalyticsTags = (props) => {
  console.log("ExperienceAnalyticsTags props ==>", props);
  // const { } = props;
  const dispatch = useDispatch();
  const [showProficiencyLevels, setShowProficiencyLevels] = useState(false);
  const currentUser = useSelector(currentUserSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const experienceAnalyticsTagsData = useSelector(experienceAnalyticsTagsSelector());
  console.log("experienceAnalyticsTagsData ======>", experienceAnalyticsTagsData);
  
  const mrIntl = useTranslate()
  useEffect(() => {
    dispatch(experienceActions.getAnalytics({experience_id: activeExperience.id, type: "tags"}))
    return () => {
    };
  }, []);

  const newChartData = {
    labels: [],
    dataset: [[]],
  }
  
  experienceAnalyticsTagsData?.tags_data?.map((td) => {
    const label = td.label || "";
    const lettersBeforeFirstSpace = label.length > 15 ? label.slice(0, 15) + '...' : label;
    newChartData.labels.push(lettersBeforeFirstSpace)
    newChartData.dataset[0].push(td.average_percentage)
  })
  console.log("ExperienceAnalyticsTags newChartData", newChartData);
  
  console.log("ExperienceAnalyticsTags experienceAnalyticsTagsData", experienceAnalyticsTagsData);

  const columns = [
    {
      title: mrIntl("ExperienceAnalyticsTags.standard"),
      dataIndex: "label",
      key: "label",
      sorter: (a, b) => a.label.localeCompare(b.label),
      sortDirections: ["ascend", "descend"],
      defaultSorter: "ascend",
    },
    {
      title: mrIntl("ExperienceAnalyticsTags.no_of_questions"),
      dataIndex: "questions_count",
      key: "questions_count",
      sorter: (a, b) => a.questions_count - b.questions_count,
      sortDirections: ["ascend", "descend"],
      defaultSorter: "ascend",
    },
    {
      title: mrIntl("ExperienceAnalyticsTags.total_points"),
      dataIndex: "total_points",
      key: "total_points",
      sorter: (a, b) => a.total_points - b.total_points,
      sortDirections: ["ascend", "descend"],
      defaultSorter: "ascend",
      render: (_, item, rowIndex) => (
        displayPoints(item.total_points)
      ),
    },
    {
      title: mrIntl("ExperienceAnalyticsTags.average"),
      dataIndex: "average_percentage",
      key: "average_percentage",
      sorter: (a, b) => a.average_percentage - b.average_percentage,
      sortDirections: ["ascend", "descend"],
      defaultSorter: "ascend",
      render: (_, item, rowIndex) => (
        displayPoints(item.average_percentage)
      ),
    },
    { // TODO: make this column conditional on if org.grading_scales set?
      title: "Proficiency level",
      dataIndex: "proficiency_level",
      key: "proficiency_level",
      sorter: (a, b) => a.proficiency_level - b.proficiency_level,
      sortDirections: ["ascend", "descend"],
      defaultSorter: "ascend",
      render: (_, item, rowIndex) => (
        <Tag color={item.proficiency_level.color}>{item.proficiency_level.label}</Tag>
      ),
    },
  ]

  return (
    <React.Fragment>
      {experienceAnalyticsTagsData.chart_data && <Row className="m-t-30">
        <Col span={24}>
          <TagsChart chartData={newChartData} />
        </Col>
      </Row>}
      {/* {experienceAnalyticsTagsData.tags_data && <Row className="m-t-30">
        <Col span={24}>
          <Table
            // rowSelection={rowSelection}
            dataSource={experienceAnalyticsTagsData.tags_data}
            columns={columns} 
            pagination={false}
            // style={{ maxHeight: "500px", overflow: "auto" }}
          />
        </Col>
      </Row>} */}
      {experienceAnalyticsTagsData.tags_students_grid && <Row className="m-t-30">
        <Col span={24} className="m-b-10">
          <Row>
            <Col span={12}><Title level={3} className="m-b-15">{mrIntl("ExperienceAnalyticsTags.standards")}</Title></Col>
            <Col span={12} className="text-right">
              <Switch onChange={(checked) => {
                // dispatch(experienceActions.getAnalytics({experience_id: activeExperience.id, type: "tags", with_proficiency_levels: checked ? true : false })) 
                setShowProficiencyLevels(!showProficiencyLevels)
              }} />  <label>{mrIntl("ExperienceAnalyticsTags.show_proficiency_levels")}</label>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="m-b-30">
          <TagsStudentsGrid data={experienceAnalyticsTagsData.tags_students_grid} showProficiencyLevels={showProficiencyLevels}/>
        </Col>
      </Row>}
    </React.Fragment>
  );
};

export default ExperienceAnalyticsTags;
