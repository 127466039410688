import React, { useEffect, useState } from "react";
// import GGBApplet from "./deployggb";
// import { actions } from "./redux";

import { Button, Table, Switch } from "antd";
import styled from 'styled-components'
import { RBDDroppableArea } from '/src/components/UI/DnD/RBDDroppableArea'
import { RBDDroppableBox } from '/src/components/UI/DnD/RBDDroppableBox'
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { updateOnDragEnd } from '/src/views/Segments/DnDHelpers'
import { initialData, addItemToArray, removeItemFromArray, addItemToObject, removeItemFromObject, convertDataAsPerNewSystem, getParsedJSONObject, numaricalSort } from "/src/views/Segments/InteractiveHelpers";
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { AddButton, CheckAnswerButton, QuickCheckBox, TryAgainButton, Segment, SegmentData, SegmentSetting, Column, ColumnHeader, DroppableHeader, InputArea, DeleteButton, HorizontalDroppableContainer, DragDropArea, VerticalDroppableContainer } from '/src/components/UI/Segment/UIHelper'
import { cloneDeep } from 'lodash';
import SegmentItemFooter from '/src/views/Segments/SegmentItemDetail/SegmentItemFooter'
import SegmentItemHeader from '/src/views/Segments/SegmentItemDetail/SegmentItemHeader'
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const RBDClassify = props => {
	console.log("Classify props", props);
	// const [state, setState] = useState({})
	let { parsedJSONObject, createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)
	const mrIntl = MrTranslate()
	let textAnswerString = props.text_answer || ''
  let attemptStatusObject = props.attempt_status || {
    reset_count: 0,
    attempted: false,
    json_attempted: false,
    text_answer_attempted: false
	}
	
	if (createMode) {
		parsedJSONObject = props.value || initialData('classify', 2, 4);
	}

	const [quickCheckModeEnabled, setQuickCheckModeEnabled] = useState(false)
	if (props.autoCheckMode) {
		let question_json = convertDataAsPerNewSystem(props.teacher_json, props)
		let answer_json = parsedJSONObject
		parsedJSONObject = checkResponses(question_json, answer_json)
	}

	const [state, setState] = useState(parsedJSONObject)
	const [textAnswer, setTextAnswer] = useState(textAnswerString)
	const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject)
	useEffect(() => {
		if (props.answerMode) {
		}

		if (props.presentationMode) {
		}

		console.log('Updated data ==>', parsedJSONObject)
		setState(parsedJSONObject)
	}, [])

	const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
	useEffect(() => {
		if (createMode) {
			if (props.onChange) {
				let teacher_json = cloneDeep(state)
				props.onChange(teacher_json)
			}
		} else if (answerMode && stateUpdatedCount > 0) {
			if (props.onChange) {
				let result = {
					response_json: cloneDeep(state),
					text_answer: cloneDeep(textAnswer),
					attempt_status: cloneDeep(attemptStatus)
				}
				
				props.onChange(result)
			}
		}
	}, [stateUpdatedCount]);

	const saveState = (state) => {
		setState(state)
		setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
	}

	console.log('State in start ==>', state)

	let segment_data = state.segment_data

	let vertical_lists = segment_data ? segment_data.vertical_lists ? segment_data.vertical_lists : {} : {}
	let horizontal_lists = segment_data ? segment_data.horizontal_lists ? segment_data.horizontal_lists : {} : {}

	const verticalListKeyArray = numaricalSort(Object.keys(vertical_lists))
	const horizontalListKeyArray = numaricalSort(Object.keys(horizontal_lists))

	const [stateSetting, setStateSetting] = useState({
		quickCheckModeEnabled: false,
	})

	function checkResponses(question_json, answer_json) {
		console.log(question_json, answer_json)
		if (!question_json || !answer_json) {
			return
		}

		let q_segment_data = question_json.segment_data
		let a_segment_data = answer_json.segment_data

		let q_vertical_lists = q_segment_data ? q_segment_data.vertical_lists : {}
		let a_vertical_lists = a_segment_data ? a_segment_data.vertical_lists : {}

		let list_legnth = Object.keys(q_vertical_lists).length
		let i = 0;
		while (i < list_legnth) {
			let q_list = q_vertical_lists ? q_vertical_lists[i] : {}
			let a_list = a_vertical_lists ? a_vertical_lists[i] : {}
			let q_items = q_list.items
			let a_items = a_list.items

			if (a_items) {
				a_items.map((a_item) => {
					q_items.map((q_item) => {
						if (q_item.id == a_item.id) {
							a_item.correct = true
						}
					})
				})
			}
			i++
		}
		return answer_json
	}

	function onDragEnd(result) {
		const dragDropConfig = {
			updateType: 'replace',
			append: true
		}
		const newSegmentData = updateOnDragEnd(result, state, dragDropConfig);

		if (!newSegmentData) {
			return
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log('on drag end data ==>', newSegmentData)
		saveState(newState)
	}

	function addItem(keyName) {
		let items = vertical_lists[keyName].items;
		items = addItemToArray(items);

		const newSegmentData = {
			...segment_data,
			vertical_lists: vertical_lists
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		setState(newState)
	}

	function removeItem(keyName, index) {
		let items = vertical_lists[keyName].items;
		items = removeItemFromArray(items, index);

		const newSegmentData = {
			...segment_data,
			vertical_lists: vertical_lists
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		setState(newState)
	}

	const addList = (keyName) => {
		const list_legnth = verticalListKeyArray.length
		let newVerticalLists = addItemToObject(vertical_lists, list_legnth, 2)

		const newSegmentData = {
			...segment_data,
			vertical_lists: newVerticalLists
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}


	const removeList = (keyName) => {
		let lists = removeItemFromObject(vertical_lists, keyName);

		if (!lists) {
			return
		}

		const newSegmentData = {
			...segment_data,
			vertical_lists: vertical_lists
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		setState(newState)
	}

	const saveOnTextChanged = () => {

		const newSegmentData = {
			...segment_data,
			vertical_lists: vertical_lists
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const saveTitleOnTextChanged = (item, data) => {
		item.title = data
		saveOnTextChanged()
	}

	const checkAnswer = () => {
		let question_json = convertDataAsPerNewSystem(props.teacher_json, props)
		let answer_json = cloneDeep(state)
		const newState = checkResponses(question_json, answer_json)

		saveState(newState)
		setMyAnswer(state)
		setStateSetting({
			...stateSetting,
			quickCheckModeEnabled: true,
		})
	}

	const [myAnswer, setMyAnswer] = useState(state)
	const showCorrectAnswer = (value) => {
		console.log("Value in switch ==>", value)
		let question_json, answer_json
		if (value) {
			question_json = convertDataAsPerNewSystem(props.teacher_json, props)
			answer_json = convertDataAsPerNewSystem(props.teacher_json, props)
		} else {
			question_json = convertDataAsPerNewSystem(props.teacher_json, props)
			answer_json = cloneDeep(myAnswer)
		}

		const newState = checkResponses(question_json, answer_json)
		saveState(newState)
		setStateSetting({
			...stateSetting,
			quickCheckModeEnabled: true,
		})
	}

	const resetQuestion = () => {
		console.log("Student json ===>", props.student_json)
		const newState = convertDataAsPerNewSystem(props.student_json, props)
		saveState(newState)
		setStateSetting({
			...stateSetting,
			quickCheckModeEnabled: false,
		})
	}

	console.log('State before render ==>', state, quickCheckModeEnabled)

	return (
		<Segment>
			<SegmentItemHeader
				segmentStateSettings={stateSetting}
				showCorrectAnswer={(value) => showCorrectAnswer(value)}
			>
			</SegmentItemHeader>

			<SegmentData>
				<DragDropContext onDragEnd={onDragEnd}>
					<DragDropArea>
						{verticalListKeyArray.map((keyName, i) => (
							createMode ?
								<Column>
									<DroppableHeader>
										<ColumnHeader>
											<InputArea>
												<BasicCKE data={vertical_lists[keyName].title} onTextChanged={(data) => saveTitleOnTextChanged(vertical_lists[keyName], data)}></BasicCKE>
											</InputArea>
											<DeleteButton
												clicked={(e) => removeList(vertical_lists[keyName].id)}
                        disabled={Object?.keys(vertical_lists).length <= 2}
                        tooltipTitle={Object?.keys(vertical_lists).length <= 2 ? mrIntl("CommonText.minimum_limit_reached") : ""}
											></DeleteButton>
										</ColumnHeader>
									</DroppableHeader>
									<VerticalDroppableContainer>
										<RBDDroppableArea
											droppableProps={{
												list: vertical_lists[keyName],
												type: 'DEFAULT',
												droppableId: vertical_lists[keyName].id,
											}}
											draggableProps={{
												list: vertical_lists[keyName],
												hidePlaceholder: true,
												showTextArea: createMode,
												removeItem: (keyName, index) => removeItem(keyName, index),
												saveOnTextChanged: (e) => saveOnTextChanged()
											}}
										/>
									</VerticalDroppableContainer>
									<AddButton
										style={{ width: '50px' }}
										clicked={(e) => addItem(keyName)}
									/>
								</Column>
								:
								<Column>
									<DroppableHeader> {vertical_lists[keyName].title}</DroppableHeader>
									<HorizontalDroppableContainer
										style={{ 'text-align': 'left' }}
									>
										<RBDDroppableArea
											droppableProps={{
												list: vertical_lists[keyName],
												type: 'DEFAULT',
												droppableId: vertical_lists[keyName].id,
											}}
											draggableProps={{
                        list: vertical_lists[keyName],
                        direction: 'horizontal',
												hidePlaceholder: true,
												showCorrect: autoCheckMode || quickCheckModeEnabled,
												draggingDisabled: (!answerMode && !quickCheckModeEnabled) ? true : false,
											}}
										/>
									</HorizontalDroppableContainer>
								</Column>
						))}
					</DragDropArea>
					{((answerMode || presentationMode) && horizontal_lists[0]) && <HorizontalDroppableContainer>
						<RBDDroppableArea
							droppableProps={{
								list: horizontal_lists[0],
								type: 'DEFAULT',
								droppableId: horizontal_lists[0].id,
							}}
							draggableProps={{
								list: horizontal_lists[0],
								direction: 'horizontal',
								hidePlaceholder: true,
								draggingDisabled: (!answerMode && !quickCheckModeEnabled) ? true : false,
							}}
						/>
					</HorizontalDroppableContainer>}
				</DragDropContext>
			</SegmentData>

			{answerMode && <SegmentItemFooter
				segmentSettings={props.segmentSettings}
				segmentStateSettings={stateSetting}
				resetQuestion={(e) => resetQuestion()}
				checkAnswer={(e) => checkAnswer()}
			>
			</SegmentItemFooter>}
		</Segment>
	);
};
export default RBDClassify;

