import { deviceType, isTablet } from "react-device-detect";

export function isTouchDevice() {
    if (deviceType !== "browser") {
        return true;
    }
    if (isTablet) {
        return true;
    }
    return false;
}

export function isMobile() {
    if (deviceType === "mobile") {
        return true;
    }
    return false;
}

