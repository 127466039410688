/* eslint-disable no-loop-func */
import { firestoreDB } from "/src/config/initializers";
import { getKeysFromObject } from "/src/lib/utils/helperMethods";
import * as Sentry from "@sentry/react";

import supabaseDB from "/src/config/supabaseInitializer";

// Not used anymore
export function buildConnectionObjectOld(config, options = { onlyBase: false }) {
  const params = options.params || {};
  const limit = options.limit;
  const sortParams = options.sortParams || {};
  const docId = config.docId;
  let finalConnectionObject = firestoreDB;

  finalConnectionObject = finalConnectionObject.collection(
    config.collectionPath
  );
  finalConnectionObject = docId
    ? finalConnectionObject.doc(docId)
    : finalConnectionObject;

  // finalConfig.forEach(element => {
  //   finalConnectionObject = finalConnectionObject[element.name](...element.args);
  // });
  if (!options.onlyBase) {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key];
        console.log("element params==>", element, key);
        if (Array.isArray(element) && element.length > 0) {
          if (element[0] && Array.isArray(element[0])) {
            for (let index = 0; index < element.length; index++) {
              const itm = element[index];
              finalConnectionObject = finalConnectionObject.where(
                `${key}`,
                ...itm
              );
            }
          } else {
            finalConnectionObject = finalConnectionObject.where(
              `${key}`,
              ...element
            );
          }
        }
      }
    }
    for (const key in sortParams) {
      if (sortParams.hasOwnProperty(key)) {
        const element = sortParams[key];
        if (element === false) {
          finalConnectionObject = finalConnectionObject.orderBy(key);
          console.log("chat orderby", key, element);
        } else if (element) {
          finalConnectionObject = finalConnectionObject.orderBy(key, element);
          console.log("chat orderby", key, element);
        }
      }
    }
    console.log("chat sortParams", sortParams);
  }
  if (limit) {
    finalConnectionObject = finalConnectionObject.limit(limit);
  }
  return finalConnectionObject;
}

export function buildFirestoreConnectionObject(config, options = { onlyBase: false }) {
  const params = options.params || {};
  const limit = options.limit;
  const sortParams = options.sortParams || {};
  const docId = config.docId;
  let finalConnectionObject = firestoreDB;

  finalConnectionObject = finalConnectionObject.collection(
    config.collectionPath
  );
  finalConnectionObject = docId
    ? finalConnectionObject.doc(docId)
    : finalConnectionObject;

  // finalConfig.forEach(element => {
  //   finalConnectionObject = finalConnectionObject[element.name](...element.args);
  // });
  if (!options.onlyBase) {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key];
        console.log("element params==>", element, key);
        if (Array.isArray(element) && element.length > 0) {
          if (element[0] && Array.isArray(element[0])) {
            for (let index = 0; index < element.length; index++) {
              const itm = element[index];
              finalConnectionObject = finalConnectionObject.where(
                `${key}`,
                ...itm
              );
            }
          } else {
            finalConnectionObject = finalConnectionObject.where(
              `${key}`,
              ...element
            );
          }
        }
      }
    }
    for (const key in sortParams) {
      if (sortParams.hasOwnProperty(key)) {
        const element = sortParams[key];
        if (element === false) { // TODO: @abhimanyu why does false mean default asc?
          finalConnectionObject = finalConnectionObject.orderBy(key);
        } else if (element) {
          finalConnectionObject = finalConnectionObject.orderBy(key, element);
        }
      }
    }
    // console.log("chat sortParams", sortParams);
  }
  if (limit) {
    finalConnectionObject = finalConnectionObject.limit(limit);
  }
  console.log("finalConnectionObject ====>", finalConnectionObject);
  return finalConnectionObject;
}

export async function executeConnectionObject(
  config,
  options = {},
  successCallback = null,
  errorCallback = null,
  // firstGetSuccessFunc = null
) {
  console.log("new executeConnectionObject", config, options);
  const docId = config.docId;
  const appRegion = config.appRegion || "global";
  const tableName = config.tableName;
  const realtime = options.realtime || false;
  let channelName = options.channelName || false;

  let finalSuccessCallback = (data, callbackForRealtime = true) => {
    if (appRegion !== "china" && docId) {
      console.log("data finalSuccessCallback 74", data.data());
      successCallback({ fbId: data.id, ...data.data() });
    } else {
      console.log("supabase realtime subs successcallback", callbackForRealtime, tableName, data);
      successCallback(data, callbackForRealtime);
    }
  };
  let finalErrorCallback = (error) => {
    console.error("supabaseDB realtime subs error in firebaseHelper executeConnectionObject", error);
    errorCallback && errorCallback();
  };

  if(appRegion === "china"){
    const params = options.params || {}
    const sortParams = options.sortParams || {}
    let filters = []
    let supabaseQuery = supabaseDB().from(tableName).select()
    for (const key in params) {
      if (Object.hasOwnProperty.call(params, key)) {
        const element = params[key];
        if(element){
          if (element[0] && Array.isArray(element[0])) {
            for (let index = 0; index < element.length; index++) {
              const paramOperatorAndValue = element[index]
              const operator = paramOperatorAndValue[0]
              const value = paramOperatorAndValue[1]
              console.log("supabaseDB dynamic query", operator, key, value);
              // supabaseQuery = supabaseQuery.eq(key, value)
              // operators can be eq, ilike, contains, containedBy, etc
              try {
                supabaseQuery = supabaseQuery[operator](key, value)
              } catch (error) {
                console.log("supabaseDB dynamic query error - couldnt apply filter", operator, key, value, error);
              }
              filters.push(`${key}=${operator}.${value}`) // actually will only be one filter - subscribe doesn't support more than 1 filter - so using uuid/subscription_key in all cases where more than 1 column to filter on
            }
          } else {
            const paramOperatorAndValue = element
            const operator = paramOperatorAndValue[0]
            const value = paramOperatorAndValue[1]
            console.log("supabaseDB dynamic query", operator, key, value);
            // supabaseQuery = supabaseQuery.eq(key, value)
            // operators can be eq, ilike, contains, containedBy, etc
            try {
              supabaseQuery = supabaseQuery[operator](key, value)
            } catch (error) {
              console.log("supabaseDB dynamic query error - couldnt apply filter", operator, key, value, error);
            }
            filters.push(`${key}=${operator}.${value}`) // actually will only be one filter - subscribe doesn't support more than 1 filter - so using uuid/subscription_key in all cases where more than 1 column to filter on 
          }
        }
      }
    }
    for (const key in sortParams) {
      if (Object.hasOwnProperty.call(sortParams, key)) {
        const value = sortParams[key];
        if(typeof(value)){
          supabaseQuery = supabaseQuery.order(key, {ascending: value === "desc" || value === true ? false : true}) // value false means asc just to make it the same as firestore sort params above - feels ulta but using in chat created_at: false but means asc
        }
      }
    }
    let filtersString = filters.join(":")

    // const { data, error } = await supabaseQuery // had to make executeConnectionObject async for this
    // if(error){
    //   console.log("supabaseDB Error in getting user info from parse: ", error);
    //   finalErrorCallback(error)
    // }else{
    //   finalSuccessCallback(data) 
    // }

    //starting livequery only if realtime is enabled
    if(realtime){
      const { data, error } = await supabaseQuery // had to make executeConnectionObject async for this
      if(error){
        console.log("supabaseDB Error in getting user info from parse: ", error);
        finalErrorCallback(error)
      }else{
        finalSuccessCallback(data, false) 
      }
      try {          
        console.log("supabase realtime subs tableName, filtersString", tableName, filtersString);
        if(!channelName){
          // Sending in MrFirebase but not in Firestore interactions or MrChat - TODO
          channelName = `public:${tableName}:${filtersString}`
        }
        // supabaseDB.channel(`public:${tableName}:${filtersString}`) // sending from config because need the name to unsubscribe/remove channel in mrfirebase and mrchat
        let connection = supabaseDB().channel(`${channelName}`)
        .on('postgres_changes', 
          { event: '*', schema: 'public', table: tableName, filter: filtersString }, 
          payload => {
            finalSuccessCallback(payload) 
          }
        ).subscribe()
        console.log("supabase.connection", connection);
        // return channelName;
        return () => {
          console.log("supabaseDB - removing channel");
          supabaseDB().channel(channelName).unsubscribe()
        }
        // return connection;
      } catch (error) {
        console.log("supabaseDB RL sub user info teacher side error", error);
        finalErrorCallback(error)
      }
    } else {
      const { data, error } = await supabaseQuery // had to make executeConnectionObject async for this
      if(error){
        console.log("supabaseDB Error in getting user info from parse: ", error);
        finalErrorCallback(error)
      }else{
        finalSuccessCallback(data, false) 
      }
    }

  }else{
    let finalConnectionObject = buildFirestoreConnectionObject(config, options);
    if (realtime) {
      return finalConnectionObject.onSnapshot(
        finalSuccessCallback,
        finalErrorCallback
      );
    } else {
      return finalConnectionObject
        .get()
        .then(finalSuccessCallback)
        .catch(finalErrorCallback);
    }
  }
}

export const updateValue = (config, id, updatedValue) => {
  console.log("in helper==>", typeof id, updatedValue);
  let finalConnectionObject = buildFirestoreConnectionObject(config);

  // let finalConnectionObject = firestoreDB;
  // finalConnectionObject = finalConnectionObject.collection(
  //   config.collectionPath
  // );
  // finalConnectionObject = finalConnectionObject.doc(config.docId)
  // finalConnectionObject.doc
  finalConnectionObject.doc(id).set(
    {
      ...updatedValue,
    },
    { merge: true }
  );
};

export const realtimeBatchActions = async (argsArr = [], mainOptions = {}) => {

  const { appRegion="global" } = mainOptions;


  // argsArr can be array of objects that are a mix of 
  // different tables with single or multiple docIds 
  // or even same tables with single or multiple docIds
  // regardless of table, there can be a single object being updated at multiple docIds or diff objects for each docId

  // for global, we just need to add each object as per each docId sent into a batch arr and execute together in 1 request = so a loop on objects and then a loop on docIds
  // for china, 2 ways - rpc or multiple requests
  // rpc - add each object as per each docId sent in a similar way to global but as needed by the rpc and execute the rpc
  // multiple requests - for each object, send a request but here we want to use upsert multiple objects for the same table and only send 1 request per table. and we need uuid and other required fields in the object even if update request - supabase doesn't update without them - OR remove required from supabase tables except for uuids - better maybe atleast write won't fail. use uuids instead of docIds for china

  // So basically for global, we use basePath and docIds to find where to update
  // For china supabase, we use tableName and uuids


  if (appRegion !== "china") {
    const fireStoreDbRef = mainOptions.fireStoreDbref || firestoreDB;
    var batch = fireStoreDbRef.batch();
    try {
      argsArr.forEach((element) => {
        let { basePath, docIds, object, options } = element;
        // const actionType = options.actionType || "set";
        if (options.override) {
          options.overrideCallback && options.overrideCallback();
          return;
        }
        if (!object) {
          throw "object not found";
        }
        // Get a new write batch

        let records = [];
        if (Array.isArray(docIds)) {
          records = [...docIds];
        } else {
          records = getKeysFromObject(docIds);
        }

        let finalConnectionObject = buildFirestoreConnectionObject(
          {
            collectionPath: basePath,
          },
          { onlyBase: true }
        );
        console.log("1", records);
        records.forEach((element) => {
          console.log("2");
          let tempRef, finalElement;
          finalElement = element;
          if (options.createNewDocId) {
            console.log("3");
            tempRef = finalConnectionObject.doc();
            if (options.newDocKey) {
              if (options.parseInt) {
                finalElement = parseInt(element);
              }
              object = {
                ...object,
                [options.newDocKey]: element,
              };
            }
          } else {
            tempRef = finalConnectionObject.doc(element);
          }
          // if(actionType === "set"){
          //   batch[actionType](tempRef, {...object}, {merge: true});
          // } else {
          //   batch[actionType](tempRef, {...object})
          // }
          if (options.actionType) {
            batch[options.actionType](tempRef, { ...object });
          } else {
            batch["set"](tempRef, { ...object }, { merge: true });
          }
        });
      });
      batch.commit().then(
        function () {
          // ...
          console.log("bulk update successful", mainOptions);
          mainOptions.successCallback && mainOptions.successCallback();
        },
        (error) => {
          console.log("error in bulk update", error);
          throw Error(error)
        }
      );
    } catch (error) {
      console.log("error in bulk update", error);
      mainOptions.errorCallback && mainOptions.errorCallback(error);
      Sentry.captureException(error);
    }
  }  else if (appRegion === "china"){
    
    try {
      let tableWiseObjects = {}
      console.log("supabaseDB realtimeBatchActions argsArr", argsArr);
      let errors = []

      argsArr.forEach((element) => {
        console.log("supabaseDB realtimeBatchActions element", element);
        let { tableName, docIds, uuids, object, options } = element;
        // const actionType = options.actionType || "set";
        if (options.override) {
          options.overrideCallback && options.overrideCallback();
          return;
        }
        if (!object) {
          throw Error("object not found");
        }

        let records = [];
        if (Array.isArray(uuids)) {
          records = [...uuids];
        } else {
          records = getKeysFromObject(uuids);
        }

        console.log("supabaseDB realtimeBatchActions records", records);
        records.forEach((element) => {
          if(!tableWiseObjects[tableName]){
            tableWiseObjects[tableName] = []
          }
          console.log("supabaseDB realtimeBatchActions object", object, element, object.uuid);
          let newObject = {...object}
          if(!newObject.uuid){
            newObject = {
              ...newObject,
              uuid: element
            }
          }
          console.log("supabaseDB realtimeBatchActions subscription_key", options.createNewDocId, tableName, object.msg_type);
          if(options.createNewDocId && tableName === "messages" && object.msg_type === "announcement"){
            newObject = {
              ...newObject,
              subscription_key: newObject.uuid.split("_____")[0]
            }
          }
          tableWiseObjects[tableName].push(newObject)
        });
      });

      console.log("supabaseDB realtimeBatchActions tableWiseObjects", tableWiseObjects);
      for (const tableName in tableWiseObjects) {
        if (Object.hasOwnProperty.call(tableWiseObjects, tableName)) {
          const objectsToUpdate = tableWiseObjects[tableName]; // array of objects - could be 1 or more
          const { data, error } = await supabaseDB().from(tableName).upsert(objectsToUpdate, { onConflict: 'uuid' })
          if(error){
            // !IMP DONE: if this error below: saving each row separately 
            // code: "PGRST102"
            // message: "All object keys must match"
            if(error.message == "All object keys must match"){
              for (let i = 0; i < objectsToUpdate.length; i++) {
                const objectToUpdate = objectsToUpdate[i];
                const { data: data1, error: error1 } = await supabaseDB().from(tableName).upsert(objectToUpdate, { onConflict: 'uuid' })
                if(error1){
                  errors.push(error1)
                }
              }
            } else {
              errors.push(error)
            }
          }
        }
      }

      if(errors.length){
        console.log("error in bulk update", errors);
        const errorsMessage = errors.map((error) => {
          return `Code: ${error.code}, Message: ${error.message}, Hint: ${error.hint}, Details: ${error.details}`
        }).join(", ")
        throw Error(errorsMessage)
      }else{
        console.log("bulk update successful", mainOptions);
        mainOptions.successCallback && mainOptions.successCallback();
      }

      // RPC way:
      // yield supabaseDB.rpc('update_user_info_and_logs', {data: {user_info: userInfoToUpdate, logs: logObj }})
        // yield supabaseDB.rpc('update_user_info', {
        //   user_info: userInfoToUpdate
        // })

        // yield supabaseDB.rpc('update_user_info', {
        //   data: {
            // user_info: {
            //   uuid: userInfoToUpdate.uuid, 
            //   columns: Object.keys(userInfoToUpdate), 
            //   values: Object.values(userInfoToUpdate)
            // } 
            // user_info: {
            //   "uuid": "eId1160_uId800",
            //   "columns": [
            //       "currentView",
            //       "app_type",
            //       "session_id",
            //       "experience_id",
            //       "user_id",
            //       "uid",
            //       "uuid"
            //   ],
            //   "values": [
            //       "startTest",
            //       "web",
            //       "1673683416615-ciQ6",
            //       1160,
            //       "800",
            //       "800",
            //       "eId1160_uId800"
            //   ]
            // }
        //   }
        // })
      
    } catch (error) {
      console.log("error in bulk update", error);
      mainOptions.errorCallback && mainOptions.errorCallback(error);
      Sentry.captureException(error);
    }
  }
};

export const unsubscribeRealtimeListenerWhenPromise = (listener) => {
  console.log("listener unsub call", listener);
  if(listener && typeof(listener) === "object"){ 
    console.log("listener is object/promise", listener);
    listener.then((unsubscribe) => {
      if(unsubscribe && typeof(unsubscribe) === "function"){
        console.log("listener unsub execute", unsubscribe);
        unsubscribe();
      }
    })
  }
}
