import React from "react";
import { Avatar } from "antd";
import { checkMob } from "/src/lib/utils/helperMethods";


const TopLeftNavExtra = (props) => {
  const { currentUser } = props
  const orgLogo = currentUser.org.attachments_json.top_nav_logo || ""
  const orgName = currentUser.org.name || ""
  let orgNameFirstLetter = orgName.charAt(0);
  const isMobile = checkMob()

  return (
    <>
      {!isMobile && <span className="top-left-nav">
        {orgLogo ?
          <Avatar shape="square" src={orgLogo} />
          :
          <> <Avatar className="default-logo-button">{orgNameFirstLetter}</Avatar> </>
        }
        {orgName}
      </span>}
    </>
  )
}
export default TopLeftNavExtra;