import React, { useState, useEffect } from "react";
import { Button, Modal, Tooltip, Row, Tag } from "antd";
import {
  UserAddOutlined,
  InfoOutlined,
  InfoCircleTwoTone
} from "@ant-design/icons";
import dayjs from "dayjs";
import { exportToExcelOrgsLoadingSelector, exportedExcelUrlSelector } from "/src/views/Orgs/selector";
import { useSelector } from "react-redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ExportOrgsListModal = (props) => {

  const {exportModalVisible, setExportModalVisible} = props;
  const exportLoading = useSelector(exportToExcelOrgsLoadingSelector())
  const exportedExcelUrl = useSelector(exportedExcelUrlSelector())
  const mrIntl = useTranslate()
  const handleOk = (e) => {
    setExportModalVisible(false);
  };

  const handleCancel = (e) => {
    setExportModalVisible(false);
  };
  return (
    <>
      <Modal
        title={mrIntl("ExportOrgsListModal.exporting_all_orgs_to_excel")}
        open={exportModalVisible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
        wrapClassName="experience-info-modal"
      >
        {mrIntl("ExportOrgsListModal.export_is_started_you_will_receive_an_email_with_the")}
      </Modal>
    </>
  );
};

export default ExportOrgsListModal;
