import React, { useEffect, useState } from "react";
import { capitalize, isEmpty, toLower, toUpper } from "lodash";
import {
  Col,
  Alert,
  Button,
  Table,
  Modal,
  Tooltip,
  Tabs,
  Typography,
  Tag,
  Switch,
  Space,
} from "antd";
import {
  DownOutlined,
  UpOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useSelector } from "react-redux";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import dayjs from "dayjs";
import { checkUserAppVersionStatus } from "/src/App/OfflineApp/offlineAppHelper";
const { TabPane } = Tabs;
const { Title } = Typography;

const DeviceInfoTable = ({ data, toggleVersion }) => {
  const getStartedAt = (name) => {
    const item = data.find((item) => item.name === name);
    if (item) {
      const formattedDate = dayjs(item.started_at).format("MMMM DD, YYYY")
      return formattedDate;
    }
    return "";
  };
  let deviceInfo = {};
  const mrIntl = useTranslate()
  data.forEach((item) => {
    let device;
    if (item && item.app_type) {
      if (item.app_type === "cros") {
        device = "AP Chromebook";
      } else if (item.app_type !== "web") {
        device = `${
          toggleVersion ? "" : item.app_type === "seb" ? "" : "AP "
        } ${toggleVersion ? "" : `${toUpper(item.app_type)} -`}  ${
          toggleVersion ? item.deviceInfo.osVersion : item.deviceInfo.appVersion
        }`;
      } else {
        device = item.deviceInfo.browser.replace(/\-\d.+/g, "");
      }
    }

    if (device) {
      if (!deviceInfo[device]) {
        deviceInfo[device] = {
          count: 1,
          names: [item.name],
        };
      } else {
        deviceInfo[device].count++;
        deviceInfo[device].names.push(item.name);
      }
    }
  });

  let deviceInfoTableData = [];
  for (let key in deviceInfo) {
    deviceInfoTableData.push({
      key: key,
      device: key,
      count: deviceInfo[key].count,
      names: deviceInfo[key].names,
    });
  }
  const columns = [
    {
      title: mrIntl("AppVersionDistributionModal.device"),
      dataIndex: "device",
      key: "device",
    },
    {
      title: mrIntl("AppVersionDistributionModal.count"),
      dataIndex: "count",
      key: "count",
    },
    // Table.EXPAND_COLUMN,
  ];
  return (
    <>
      <Table
        dataSource={deviceInfoTableData}
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
                marginLeft: "48px",
              }}
            >
              {record.names.map((name, index) => (
                <span key={name}>
                  {toggleVersion ? (
                    <Tooltip title={getStartedAt(name)}>
                      <span>{name}</span>
                    </Tooltip>
                  ) : (
                    <span>{name}</span>
                  )}
                  {index !== record.names.length - 1 && ","}
                </span>
              ))}
            </p>
          ),
          expandRowByClick: true,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
      />
    </>
  );
};

const TabTitle = ({ name, count }) => {
  return (
    <Title level={5}>
      {capitalize(name)}{" "}
      {count === 0 ? (
        "(0)"
      ) : (
        <Tag
          color={
            name === "latest"
              ? "processing"
              : name === "old"
              ? "warning"
              : "error"
          }
        >
          {count}
        </Tag>
      )}
    </Title>
  );
};

const AppVersionDistributionModal = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleVersion, setToggleVersion] = useState(false);
  const [finalRender, setFinalRender] = useState([]);
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const currentUser = useSelector(currentUserSelector());
  // let finalRender = [];
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const mrIntl = useTranslate();
  const getTabName = (key) => {
    let tabName = null
    if (key == "latest") {
      tabName = mrIntl("AppVersionDistributionModal.latest")
    } else if (key == "old") {
      tabName = mrIntl("AppVersionDistributionModal.old")
    } else if (key == "unsupported") {
      tabName = mrIntl("AppVersionDistributionModal.unsupported")
    }
    return tabName
  }

  useEffect(() => {
    if (data && data.length !== 0 && isModalOpen) {
      let finalTabs = [];
      let finalData = {};

      if (toggleVersion) {
        data.forEach((item) => {
          const os = item.deviceInfo.os;
          if (!finalData[os]) {
            finalData[os] = [];
          }
          finalData[os].push(item);
        });
      } else {
        finalData = {
          latest: [],
          old: [],
          unsupported: [],
        };

        data.forEach((item) => {
          if (item.app_type !== undefined) {
            let appVersionStatus = checkUserAppVersionStatus(
              item.app_type,
              item.deviceInfo
            );

            if (isEmpty(appVersionStatus)) {
              finalData.latest.push(item);
            } else {
              finalData[appVersionStatus.status].push(item);
            }
          }
        });
      }
      Object.keys(finalData).forEach((key) => {
        const tab = (
          <TabPane
            tab={<TabTitle
              // name={key}
              name={getTabName(key)}
              count={finalData[key].length} />}
            key={key}
          >
            <DeviceInfoTable
              data={finalData[key]}
              toggleVersion={toggleVersion}
            />
          </TabPane>
        );
        finalTabs.push(tab);
      });

      setFinalRender(finalTabs);
    }
  }, [data, isModalOpen, toggleVersion]);

  console.log("modal data==>", isModalOpen, data);
  const onChange = (key) => {
    console.log(key);
  };

  const onSwitchChange = (checked) => {
    setToggleVersion(checked);
  };

  return (
    <>
      {data.length > 0 && (
        <>
          <Tooltip 
          // title={"Click to see the distribution in the test"}
          title={mrIntl("AppVersionDistributionModal.see_the_distribution_in_test")}
          >
            <Button
              icon={<InfoCircleOutlined />}
              type="text"
              onClick={() => showModal()}
            />
          </Tooltip>
          <Modal
            open={isModalOpen}
            title={
              <Space>
                {toggleVersion ? mrIntl("AppVersionDistributionModal.os_versions") : mrIntl("AppVersionDistributionModal.app_versions")}
                {currentUser.role === "superadmin" && (
                  <Switch checked={toggleVersion} onChange={onSwitchChange} />
                )}
              </Space>
            }
            // open={isModalOpen}
            footer={null}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Tabs onChange={onChange} defaultActiveKey="latest">
              {finalRender}
            </Tabs>
          </Modal>
        </>
      )}
    </>
  );
};

export default AppVersionDistributionModal;
