import React, { useState } from "react";
import { Button, Modal, Tooltip, Row, Tag, Space, Alert, Statistic, Spin, Col, Result } from "antd";
import {
  SendOutlined,
  LeftOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { isEmpty, orderBy } from "lodash";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { getStrandsFromRubricsWithUpdatedCA, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";

const SegmentRelations = (props) => {
  console.log("SegmentRelations ===>", props);
  const { segmentRelations, segmentCAs = [], experienceData } = props
  const currentUser = useSelector(currentUserSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const mrIntl = useTranslate()
  console.log('segmentRelations', segmentRelations);
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const relationItems = activeExperience.relation_items || [];
  const rubric = activeExperience.rubric || {};
  const segmentRubricStrands = getStrandsFromRubricsWithUpdatedCA(segmentCAs, rubric.criteria, relationItems)
  
  let finalRender = []

  let segmentRelationsInnerRender = []
  let syllabusItemsRender = [], qbItemsRender = [], subjectItemsRender = [], gradeItemsRender = [], difficultyLevelItemsRender = [], teachingLevelItemsRender = [], depthOfKnowledgeItemsRender = [], commandTermItemsRender = [], assessmentObjectiveItemsRender = []

  let firstSegmentRelation = segmentRelations ? segmentRelations[0] : undefined

  if(firstSegmentRelation){
    if(currentUser.role === "qb_author"){
      let qbItem = firstSegmentRelation.questionbank_items[0]
      if(qbItem){
        qbItemsRender.push(<Tooltip key={`qb-item-${qbItem.id}`} title={mrIntl("CommonText.questionbanks")}><Tag color="warning">{qbItem.label}</Tag></Tooltip>)
      }
      let subjectItem = firstSegmentRelation.subject_items[0]
      if(subjectItem){
        subjectItemsRender.push(<Tooltip key={`subject-item-${subjectItem.id}`} title={mrIntl("CommonText.subject")}><Tag color="warning">{subjectItem.label}</Tag></Tooltip>)
      }
      let gradeItem = firstSegmentRelation.grade_items[0]
      if(gradeItem){
        gradeItemsRender.push(<Tooltip key={`grade-item-${gradeItem.id}`} title={mrIntl("SegmentRelations.grade_title")}><Tag color="warning">{gradeItem.label}</Tag></Tooltip>)
      }
    }
    
    let syllabusItems = firstSegmentRelation.syllabus_items ? firstSegmentRelation.syllabus_items.filter(si => si.archived === false) : undefined
    if(syllabusItems){
      syllabusItems.map((si, i) => {
        let segmentItemLabel = <RenderHtml text={si.label} truncate={true} />
        syllabusItemsRender.push(<Tooltip key={`syllabus-item-${si.id}`} title={mrIntl("SegmentRelations.syllabus_topic")}><Tag className="syllabus-topic-tag truncate-text">{segmentItemLabel}</Tag></Tooltip>)
      })
    }
    let difficultyLevelItems = firstSegmentRelation.difficulty_level_items ? firstSegmentRelation.difficulty_level_items.filter(di => di.archived === false) : undefined
    if(difficultyLevelItems){
      difficultyLevelItems.map((di, i) => {
        difficultyLevelItemsRender.push(<Tooltip key={`difficulty-item-${di.id}`} title={mrIntl("SegmentRelations.difficulty_level")}><Tag>{di.label}</Tag></Tooltip>)
      })
    }
    let teachingLevelItems = firstSegmentRelation.teaching_level_items ? firstSegmentRelation.teaching_level_items.filter(ti => ti.archived === false) : undefined
    if(teachingLevelItems){
      teachingLevelItems.map((ti, i) => {
        teachingLevelItemsRender.push(<Tooltip key={`teaching-item-${ti.id}`} title={mrIntl("SegmentRelations.teaching_level")}><Tag>{ti.label}</Tag></Tooltip>)
      })
    }
    let commandTermItems = firstSegmentRelation.command_term_items ? firstSegmentRelation.command_term_items.filter(ti => ti.archived === false) : undefined
    if(commandTermItems){
      commandTermItems.map((ti, i) => {
        commandTermItemsRender.push(<Tooltip key={`command-term-item-${ti.id}`} title={mrIntl("SegmentRelations.command_term")}><Tag color="processing">{ti.label}</Tag></Tooltip>)
      })
    }
    let assessmentObjectiveItems = firstSegmentRelation.assessment_objective_items ? firstSegmentRelation.assessment_objective_items.filter(ti => ti.archived === false) : undefined
    if(assessmentObjectiveItems){
      assessmentObjectiveItems.map((ti, i) => {
        assessmentObjectiveItemsRender.push(<Tooltip key={`assessment_objective-item-${ti.id}`} title={mrIntl("SegmentRelations.assessment_objective")}><Tag color="processing">{ti.label}</Tag></Tooltip>)
      })
    }
    let depthOfKnowledgeItems = firstSegmentRelation.depth_of_knowledge_items ? firstSegmentRelation.depth_of_knowledge_items.filter(ti => ti.archived === false) : undefined
    if(depthOfKnowledgeItems){
      depthOfKnowledgeItems.map((ti, i) => {
        depthOfKnowledgeItemsRender.push(<Tooltip key={`dok-item-${ti.id}`} title={mrIntl("SegmentRelations.depth_of_knowledge")}><Tag>{ti.label}</Tag></Tooltip>)
      })
    }

    let mypCriteriaStrandsRender = [];
    if (segmentRubricStrands) {
      segmentRubricStrands.map((ca, i) => {
        let caStrands = ca.strands
        if(caStrands && caStrands.length > 0){
          caStrands = orderBy(caStrands, ["label"], ["asc"]) 
          mypCriteriaStrandsRender.push(
            <>
              {caStrands.map((strand) =>
                  <Tooltip placement="bottom" title={strand.desc}>
                    <Tag>{strand.label}</Tag>
                  </Tooltip>
              )}
            </>
          );
        }
      });
    }

    let subjectLabelToRender = []
    let gradeLabelToRender = []
    let qbTitleToRender = []
    let examNameToRender = []
    let paperTypeToRender = []
    if(!isEmpty(experienceData)) {

      let { subject_label, grade_label, qb_title, exam_name, tag_labels, qb_code } = experienceData

      if(subject_label) {
        subjectLabelToRender.push(<Tag key={subject_label}>{experienceData.subject_label}</Tag>)
      }
      if(grade_label) {
        gradeLabelToRender.push(<Tag key={grade_label}>{grade_label}</Tag>)
      }
      if(qb_title) {
        qbTitleToRender.push(<Tag>{qb_title}</Tag>)
      }
      if(qb_code === "ib_dp" || qb_code === "osc_dp" || qb_code === "osc_dp_mocks") {
        if(exam_name) {
          examNameToRender.push(<Tag>{exam_name}</Tag>)
        }
        if(tag_labels.length) {
          const paperType = []
          tag_labels.map((tag) => {
            if(tag.type === "paper_type") {
              paperType.push(tag)
            }
          })
          if(paperType.length) {
            paperTypeToRender.push(<Tag>{paperType[0].label}</Tag>)
          }
        }
      }
    }

    qbItemsRender.length && segmentRelationsInnerRender.push(qbItemsRender)
    subjectItemsRender.length && segmentRelationsInnerRender.push(subjectItemsRender)
    gradeItemsRender.length && segmentRelationsInnerRender.push(gradeItemsRender)
    // syllabusItemsRender.length && segmentRelationsInnerRender.push(syllabusItemsRender)
    difficultyLevelItemsRender.length && segmentRelationsInnerRender.push(difficultyLevelItemsRender)
    teachingLevelItemsRender.length && segmentRelationsInnerRender.push(teachingLevelItemsRender)
    commandTermItemsRender.length && segmentRelationsInnerRender.push(commandTermItemsRender)
    assessmentObjectiveItemsRender.length && segmentRelationsInnerRender.push(assessmentObjectiveItemsRender)
    depthOfKnowledgeItemsRender.length && segmentRelationsInnerRender.push(depthOfKnowledgeItemsRender)
    mypCriteriaStrandsRender.length && segmentRelationsInnerRender.push(mypCriteriaStrandsRender)
    subjectLabelToRender.length && segmentRelationsInnerRender.push(subjectLabelToRender)
    gradeLabelToRender.length && segmentRelationsInnerRender.push(gradeLabelToRender)
    qbTitleToRender.length && segmentRelationsInnerRender.push(qbTitleToRender)
    examNameToRender.length && segmentRelationsInnerRender.push(examNameToRender)
    paperTypeToRender.length && segmentRelationsInnerRender.push(paperTypeToRender)
  
    console.log("segmentRelations segmentRelationsInnerRender", segmentRelationsInnerRender)
    let segmentRelationsClassName = `${props.className} ${!syllabusItemsRender.length && "m-t-10"}`;

    finalRender = (
      <React.Fragment>
        {syllabusItemsRender.length > 0 && (
          <Row style={{ marginTop: "10px" }} className={props.className}>
            <Col span={24}>{syllabusItemsRender}</Col>
          </Row>
        )}
        {segmentRelationsInnerRender.length > 0 && (
          <Row
            className={segmentRelationsClassName}
          >
            <Col span={24}>{segmentRelationsInnerRender}</Col>
          </Row>
        )}
      </React.Fragment>
    );
  }



  return finalRender

};

export default SegmentRelations;
