import { Segment, showInfoModal, ShowText, ShowTextContent, ShowTextFile } from "/src/components/UI/Segment/UIHelper";
import React from "react";
import { PlusOutlined } from '@ant-design/icons';
import { InputOptions } from "./InputOptions";
import { addItemToArray, removeItemFromArray } from "../InteractiveHelpers";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ConnectForm = (props) => {
  const { segment_data, hints, answers, state, saveState, answer, createMode } = props
  const mrIntl = useTranslate();
  const addCorrectAnswer = (index) => {
    const extra = {
      contentRequired: false,
      contentPrefix: 'Correct Answer',
      placeholderPrefix: 'Correct Answer'
    }
    const newAnswers = addItemToArray(answers, extra)

    const newSegmentData = {
      ...segment_data,
      answers: newAnswers
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)

  }

  const addHints = (index) => {
    if (hints.length < 4) {
      const extra = {
        contentRequired: false,
        contentPrefix: 'Hint',
        placeholderPrefix: 'Hint'
      }
      const newHints = addItemToArray(hints, extra)

      const newSegmentData = {
        ...segment_data,
        hints: newHints
      }

      const newState = {
        ...state,
        segment_data: newSegmentData
      }

      saveState(newState)
    } else {
      showInfoModal({
        type: 'warning',
        title: mrIntl("CommonText.reached_maximum_allowed_limit"),
      })
    }
  }

  const removeHints = (index) => {
    if (hints.length > 1) {
      const extra = {
        noMinSize: true
      }
      const newHints = removeItemFromArray(hints, index, extra)
      const newSegmentData = {
        ...segment_data,
        hints: newHints
      }
      const newState = {
        ...state,
        segment_data: newSegmentData
      }

      saveState(newState)
    } else {
      showInfoModal({
        type: 'warning',
        title: mrIntl("CommonText.reached_maximum_allowed_limit"),
      })
    }
  }

  const onTextChanged = (type, data, index, childIndex) => {
    const newHints = hints
    const newAnswers = answers
    const newAnswer = answer
    const i = newAnswers.findIndex((g) => g.id == data.id);
    if (type == 'correct') {
      if (index === 0) {
        newAnswers[index].content = data
        newAnswer.content = data
      } else {
        newAnswers[index].content = data
      }

    } else if (type == 'hints') {
      newHints[index].content = data
    }
    const newSegmentData = {
      ...segment_data,
      hints: newHints,
      answers: newAnswers,
      answer: newAnswer
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const removeFile = (type, index, childIndex) => {
    const newHints = hints

    if (type == "hints") {
      delete newHints[index].files
    }

    const newSegmentData = {
      ...segment_data,
      hints: newHints
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const onSaveMedia = (type, index, files, childIndex) => {
    const newHints = hints

    if (type == "hints") {
      newHints[index].files = files
    }

    const newSegmentData = {
      ...segment_data,
      hints: newHints
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const removeCorrectAnswer = (index) => {
    if (answers.length > 1) {
      const extra = {
        noMinSize: true
      }
      const newAnswers = removeItemFromArray(answers, index, extra)
      const newSegmentData = {
        ...segment_data,
        answers: newAnswers
      }
      const newState = {
        ...state,
        segment_data: newSegmentData
      }
      saveState(newState)
    } else {
      showInfoModal({
        type: 'warning',
        title: mrIntl("CommonText.reached_maximum_allowed_limit"),
      })
    }
  }

  return( 
    <Segment>
      <div className="connect-input-options">
        <InputOptions
          field={hints}
          add={addHints}
          remove={removeHints}
          onTextChanged={onTextChanged}
          removeFile={removeFile}
          onSaveMedia={onSaveMedia}
          buttonText={mrIntl("ConnectForm.add_hints")}
          span={12}
          type="hints"
        >
        </InputOptions>
        <div className="connect-correct-answer">{mrIntl("CommonText.correct_answer")}</div>
        <InputOptions
          field={answers}
          add={addCorrectAnswer}
          remove={removeCorrectAnswer}
          onTextChanged={onTextChanged}
          buttonText={<PlusOutlined />}
          span={16}
          type="correct"
        >
        </InputOptions>
      </div>
    </Segment>
  )
}

export default ConnectForm;