import React from "react";
import { Button, message } from "antd";
import { aiSegmentsSelector, segmentsCreateLoadingSelector } from "../selector";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { getDestinationPositionLex } from "/src/lib/utils/helperMethods";
import { aiGenerateQuestionsConfigSelector } from "../GenerateAI/selector";
import { addTask, addToSegment, isNewGenerateQuestionsWithAI } from "../GenerateAI/generateAIHelperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { enabledFeaturesSelector } from "../../Auth/Login/selector";
import { actions as aiSegmentActions } from "../GenerateAI/redux";
import { topicsSelector } from "../Topics/selector";
import { activeAdjustedExperienceSelector } from "../../Experiences/selector";

const AddGeneratedSegmentToList = ({segment, addedAiSegments, setActiveEditQuestion}) => {
  
  const isQuestionAdded = addedAiSegments.current.includes(segment.id)
  const segmentsCreateLoading = useSelector(segmentsCreateLoadingSelector());
  const aiSegments = useSelector(aiSegmentsSelector())
  const aiGenerateQuestionsConfig = useSelector(aiGenerateQuestionsConfigSelector());

  const {strands, criteria, commandTermId, syllabusTopicId, paperTypeId, teachingLevelId, showCriteria, prevPosition, nextPosition, parentId, previousId, questionType, addAllButtonLoading, taskAdded, loading} = aiGenerateQuestionsConfig;

  const destinationPosition = getDestinationPositionLex(
    prevPosition,
    nextPosition
  );

  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  let allChildrenSegments = useSelector(aiSegmentsSelector(segment.id));
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const segments = useSelector(topicsSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());

  const childrenSegments = [...allChildrenSegments]
  let newGenerateQuestionsWithAI =
  isNewGenerateQuestionsWithAI(enabledFeatures);
  let isAddTask = !newGenerateQuestionsWithAI && questionType === "task"


  const addSegmentToList = () => {
    // To close modifyQuestionInput if add is clicked
    setActiveEditQuestion(null)
    // The ...rest syntax is used to capture any remaining properties of the item object into the rest variable and then pass it without modifying them.
    const questionType = segment.question_segment_attributes.question_type;
    const newSegment = {
      ...segment,
      question_segments_attributes: [segment.question_segment_attributes],
      destination_position: destinationPosition,
      parent_id: parentId,
      previous_id: previousId
    };
    delete newSegment.id
    delete newSegment.position
    delete newSegment.question_segment_attributes

    const addSegmentOptions = {
      isNested: questionType === "group",
      segment: newSegment,
      questionType: questionType,
      dispatch: dispatch,
      mrIntl: mrIntl,
      syllabusTopicId: syllabusTopicId, 
      commandTermId: commandTermId,
      strands: strands,
      showCriteria: showCriteria,
      criteria: criteria,
      paperTypeId: paperTypeId,
      teachingLevelId: teachingLevelId,
    };

    if (childrenSegments && childrenSegments.length > 0) {
      const finalChildSegments = childrenSegments.map(({ id, parent_id, position, ...rest }) => rest);
      addSegmentOptions.subPartsLength = finalChildSegments.length;
      addSegmentOptions.allChildrenSegments = finalChildSegments;
    }

    addToSegment(addSegmentOptions, {handleSuccess});
  }

  const handleSuccess = (addedSegmentId) => {
    addedAiSegments.current.push(segment.id)
    message.success(mrIntl("AddGeneratedSegmentToList.question_successfully_added"))
    dispatch(aiSegmentActions.setAiGenerateQuestionsConfigSuccess({ data: {
      ...aiGenerateQuestionsConfig,
      loading: false,
      prevPosition: destinationPosition,
      previousId: addedSegmentId
    }}))
  }

  return (
    <Button
      loading={(segmentsCreateLoading || addAllButtonLoading) && !isQuestionAdded}
      type="primary"
      shape="round"
      icon={<PlusOutlined />}
      disabled={isQuestionAdded || taskAdded || loading}
      onClick={() => {
        if (isAddTask) {
          let lastSegment = segments && segments.length && segments[segments.length - 1]
          addTask(lastSegment, activeExperience.id, dispatch, aiGenerateQuestionsConfig, aiSegments, mrIntl, addedAiSegments, allChildrenSegments);
        } else {
        addSegmentToList()
        }
      }}
    >
      {isQuestionAdded ? mrIntl("AddGeneratedSegmentToList.added") : mrIntl("CommonText.add")}
    </Button>
  );
}
export default AddGeneratedSegmentToList;