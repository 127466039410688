import React, { useEffect, useState } from "react";

import { initialData, removeItemFromArray, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { Segment, SegmentData } from '/src/components/UI/Segment/UIHelper'
import { cloneDeep } from 'lodash';
import MediaUI from "/src/components/UI/Media/MediaUI"
import EmbedView from "./EmbedView";
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments"
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const EmbedForm = props => {
	console.log("Embed props", props);
	let { createMode, presentationMode } = getParsedJSONObject(props)
	let parsedJSONObject
  const mrIntl = useTranslate();
	if (createMode) {
		parsedJSONObject = props.value || initialData('resource', 1, 4);
	} else {
		parsedJSONObject = props.resource_json
	}

	const [state, setState] = useState(parsedJSONObject)
	const [stateUpdatedCount, setStateUpdatedCount] = useState(0)

	const mediaFormInitialState = {
		modal: false,
		loading: false,
		visible: false,
		title: mrIntl("EmbedForm.insert_embed_title"),
		okText: mrIntl("EmbedForm.add_embed_button"),
		cancelText: mrIntl("CommonText.cancel"),
		triggerSaveAction: 0,
		requireFooter: false,
		limit: 1,
		content_type: 'embed',
    supportedTypes: 'application/x-shockwave-flash, text/html',
		supportedFormats: 'html',
		selectConfig: {
			multiple: false,
			maxSize: "200 MB",
		},
		
	}
	const [mediaFormSetting, setMediaFormSetting] = useState(mediaFormInitialState)
	mediaFormSetting.beforeLoadURL = (data) => {
    setMediaFormSetting({
      ...mediaFormSetting,
      disableUploadURLToS3: true,
    });
    return data;
  };	

	let currentJSON
	if (presentationMode) {
		currentJSON = parsedJSONObject
	} else {
		currentJSON = state
	}
	const segment_data = currentJSON?.segment_data || {}
	const resource_setting = currentJSON.resource_setting || {}
	const attachments = segment_data.attachments || []
	const attachmentsCount = attachments.length

	useEffect(() => {
		setMediaFormSetting({
			...mediaFormSetting,
			modal: attachmentsCount > 0,
			visible: false,
		})
	}, [attachmentsCount])

	useEffect(() => {
		if (createMode) {
			if (props.onChange) {
				let resource_json = cloneDeep(state)
				props.onChange(resource_json)
			}
		}
	}, [stateUpdatedCount]);

	const saveState = (state) => {
		setState(state)
		console.log("stateUpdated count ==>", stateUpdatedCount)
		setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
	}

	const onTabDragEnd = (attachments) => {
		const newAttachments = attachments.slice()

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const removeItem = (index) => {
		const extra = {
			noMinSize: true
		}
		const newAttachments = removeItemFromArray(attachments, index, extra)
		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}
		saveState(newState)
	}

	const onSaveMedia = (files) => {
		console.log('Files and attachmetns ==>', attachments, files)
		let newAttachments = attachments.concat(files)

		const limit = mediaFormSetting.limit
		if (limit) {
			newAttachments = newAttachments.slice(-limit)
		}

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log('ON save Embed ==>', newAttachments, newState)

		saveState(newState)
		setMediaFormSetting({
			...mediaFormSetting,
			from: '',
			modal: false,
			visible: false,
			triggerSaveAction: 0,
		})
	}

	const onTextChanged = (type, index, data) => {
		const newAttachments = attachments
		newAttachments[index][type] = data

		const newSegmentData = {
			...segment_data,
			attachments: newAttachments
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		console.log("onchange is getting fired ==>", newState, data)
		saveState(newState)
	}

	const setResourceSetting = (key, value) => {
		const newResourceSetting = {
			...resource_setting,
			[key]: value
		}

		const newState = {
			...state,
			resource_setting: newResourceSetting
		}

		saveState(newState)
	}

	const onTabEdit = (index, action) => {
		if (action == 'add') {
			setMediaFormSetting({
				...mediaFormSetting,
				from: 'computer',
				modal: true,
				visible: true,
			})
		} else if (action == 'remove') {
			removeItem(index)
		}
	}

	const getMediaForm = () => {

		return (<MediaUI
			computer={false}
			url={true}
			mediaFormSetting={mediaFormSetting}
			setMediaFormSetting={(data) => setMediaFormSetting(data)}
			onSaveMedia={(files) => onSaveMedia(files)}
		></MediaUI>)
	}

	return (
		<Segment>
			<SegmentData>
				{createMode && getMediaForm()}

				{attachmentsCount > 0 && <div>
					{createMode ? <TabularAttachments
						createMode={createMode}
						hideAdd={mediaFormSetting.limit == 1}
						titleRequired={false}
						attachmentType="embed"
						tabPrefix="Embed"
						attachments={attachments}
						resource_setting={resource_setting}
						onTabDragEnd={onTabDragEnd}
						onTabEdit={onTabEdit}
						onTextChanged={onTextChanged}
					></TabularAttachments>
						: <EmbedView
							resource_json={state}
						></EmbedView>}
				</div>}
			</SegmentData>
		</Segment >
	);
}
export default EmbedForm;