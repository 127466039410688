import { fromJS, List } from "immutable";
import { MrReduxCrud, formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { all, put, select } from "redux-saga/effects";
import { actions as annotationsActions } from "/src/views/Annotations/redux";
import { annotationsSelector } from "/src/views/Annotations/selector";
import _ from "lodash";
import { message } from "/src/components/UI/AntdAppHelper";
import { activeAdjustedExperienceIdSelector, activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { dbUserResponsesSelector, summaryLoadingSelector } from "./selector";
import {actions as experienceUserActions} from "/src/views/ExperienceUsers/redux"
import { activeExperienceUserIdSelector, activeExperienceUserSelector, experienceUsersSelector } from "/src/views/ExperienceUsers/selector";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "user_response",
  actionNames: [
    "FETCH",
    "CREATE",
    "UPDATE",
    "SHOW",
    "DELETE",
    "GET_CUSTOM",
    "SUMMARY",
    "RECOVER_RESPONSE",
    "SET_PARENTS_POINTS",
    "FETCH_MISSING"
  ],
});

const initialState = fromJS({
  user_responses: [],
  // user_responses_for_summary: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
  summaryloading: false
});

export function* summarySaga(action) {
  console.log("summary saga actions ==>", action)
  if (action.payload.setEmpty) {
    // on experience change - so on grid summary component unmount
    yield put(actions.summarySuccess({ user_responses: List([]) }));
  } else {
    let summaryData = {
      params: {
        by_eu_ids: [],
        by_experience_id: action.payload.experienceId,
        with_annotations: true,
        serialized: true,
        // with_submission_criterium_associations: true,
        // serialized: false,
      },
    };
    if(action.payload.userId) {
      summaryData.params.by_user_id = action.payload.userId
    }
    if(action.payload.experienceUserId) {
      summaryData.params.by_experience_user_id = action.payload.experienceUserId
    }
    if (action.payload.experienceUserIds) {
      summaryData.params.by_eu_ids = action.payload.experienceUserIds
    }
    if (action.payload.userIds) {
      summaryData.params.by_u_ids = action.payload.userIds
    }
    if (action.payload.segmentId) {
      summaryData.params.by_segment_id = action.payload.segmentId
    }
    let url = "user_responses.json";
    console.log("GradingSummaryGrid saga get", summaryData);
    try {
      // TODO - add condition to only show message if summaryloading false 
      // let isSummaryLoading = yield select(summaryLoadingSelector())
      // if(!isSummaryLoading){
        message.info("Fetching responses...", 2);
      // }
      yield put(actions.summaryStart());
      const response = yield axiosInstance.instance.get(url, summaryData);
      // yield localStorage.setItem("token", response.data.token);
      console.log("GradingSummaryGrid saga response", response);

      if (response.data) {
        if (response.data.user_responses) {
          let userResponses =  formatJsonApiData(response.data.user_responses.data) || [];
          yield put(
            actions.summarySuccess({
              user_responses: userResponses,
            })
          );

          yield put(
            experienceUserActions.setHasUserResponsesSuccess({ids: userResponses.map(ur => parseInt(ur.experience_user_id)), segment_id: action.payload.segmentId })
          )

          if (action.options && action.options.successCallback) {
            if (action.options.fromRedux) {
              userResponses = yield select(dbUserResponsesSelector(action.payload.experienceId, action.payload.userId,  action.payload.segmentId, action.payload.attemptNo, action.options.returnSingle, action.options.experienceUserId))
              console.log("userResponses from redux when fetched ===>", userResponses)
            }
            action.options.successCallback(userResponses)
          }
        }

        if (response.data.annotations) {
          console.log("annotationsActions ==>", annotationsActions)
          let annotations = formatJsonApiData(response.data.annotations.data) || [];
          yield put(annotationsActions.fetchStart()); // We want to set fetchloading, to trigger load of annotations on node
          yield put(
            annotationsActions.fetchSuccess({
              annotations: annotations
            })
          );
        }
        
        // yield put(actions.summarySuccess(response.data));
      }
    } catch (error) {
      console.log("GradingSummaryGrid error", error);
      yield put(actions.summaryFail({ error: error }));
    }
  }
}

function summarySuccess(state = initialState, action) {
  console.log("summarySuccess action.payload", action.payload, state.get("user_responses"));

  // let annotations = [];
  let newState = state.set("loading", false).set("summaryloading", false);

  let currentUserResponses = state.get("user_responses", []);
  if (currentUserResponses.hasOwnProperty("size")) {
    currentUserResponses = currentUserResponses.toJS();
  } 
  const newUserResponses = action.payload.user_responses
  // Concatenating newUserResponses with currentUserResponses, rather than the reverse, to ensure that the most recent updates are retained. This is because the _.uniqBy function keeps the first occurrence of each unique item based on the comparator, which in this case is the "id".
  const userResponses = _.uniqBy(
    newUserResponses.concat(currentUserResponses),
    "id"
  );

  newState = newState.set("user_responses", userResponses);
  return newState;
}

// export function* getCustomSaga(action) {
//   // const getCustomData = {
//   //   params: {
//   //     email: action.email,
//   //   },
//   // };
//   let url = "user_responses.json";
//   try {
//     yield put(actions.getCustomStart());
//     const response = yield axiosInstance.instance.get(url, action.payload);
//     // yield localStorage.setItem("token", response.data.token);
//     console.log("response", response);
//     // let annotations = formatJsonApiData(response.data.annotations) || [];
//     // let existingAnnotations = yield select(annotationsSelector());
//     // if (existingAnnotations.hasOwnProperty("size")) {
//     //   existingAnnotations = existingAnnotations.toJS();
//     // }
//     // let newAnnotations = _.uniqBy(
//     //   existingAnnotations.concat(annotations),
//     //   "id"
//     // );
//     // console.log(
//     //   "annotations and existing annotations ==>",
//     //   annotations,
//     //   existingAnnotations,
//     //   newAnnotations
//     // );
//     // yield put(
//     //   annotationsActions.fetchSuccess({ data: { annotations: newAnnotations } })
//     // );
//     yield put(
//       actions.getCustomSuccess({
//         user_responses: response.data.user_responses.data || [],
//         // annotations: response.data.annotations || { data: [] },
//       })
//     );

//     if (response.data.annotations) {
//       yield put(
//         annotationsActions.fetchSuccess({
//           annotations: response.data.annotations.data || []
//         })
//       );
//     }
//   } catch (error) {
//     console.log("annotations error", error);
//     yield put(actions.getCustomFail({ error: error }));
//   }
// }

// function getCustomSuccess(state = initialState, action) {
//   console.log("action.payload", action.payload);
//   // const finalData = formatJsonApiData(action.payload.user_responses);
//   // let newState = state.set("loading", false);
//   // // const annotations = action.payload.annotations.data || [];
//   // if (finalData.length === 1) {
//   //   const existingUserResponse = state.get("user_responses", []);
//   //   // const existingAnnotations = state.get("annotations", []);
//   //   if (!existingUserResponse.find((obj) => obj.id === finalData[0].id)) {
//   //     newState = newState
//   //       .set("user_responses", existingUserResponse.concat(finalData))
//   //       // .set("annotations", existingAnnotations.concat(annotations));
//   //   }
//   // } else {
//   //   newState = newState
//   //     .set("user_responses", finalData)
//   //     // .set("annotations", annotations);
//   // }
//   // console.log("custom success ==>", finalData)

//   let currentUserResponses = state.get("user_responses", []);
//   if (currentUserResponses.hasOwnProperty("size")) {
//     currentUserResponses = currentUserResponses.toJS();
//   } 
//   const newUserResponses = formatJsonApiData(action.payload.user_responses)
//   const userResponses = _.uniqBy(
//     currentUserResponses.concat(newUserResponses),
//     "id"
//   );
//   return state.set("user_responses", userResponses)
//   // return newState;
//   // return .//.set("user_responses", action.payload.data);
//   // return state;
// }

function* setParentsPointsUserResponseSaga(action){
  console.log("setParentsPointsUserResponseSaga action.payload", action.payload)
  try{
    console.log("setParentsPointsUserResponseSaga action.payload", action.payload)

    let updatedUserResponse = action.payload.updatedUserResponse
    let isPerQuestionGrading = action.payload.isPerQuestionGrading
    let experienceUser = yield select(activeExperienceUserSelector())
    if(isPerQuestionGrading){
      let updatedParentsPoints = updatedUserResponse.parents_points
      const userResponses = yield select(dbUserResponsesSelector(updatedUserResponse.experience_id, updatedUserResponse.user_id, null, false, false, updatedUserResponse.experience_user_id));
      // yield cannot be used inside loop - so we do this instead:
      yield all(updatedParentsPoints.map((parent) => {
        console.log("ur update callback  updatedParentsPoints parent", userResponses, parent);
        let parentUserResponse = userResponses.find(ur => ur.id === parent.id)
        console.log("ur update callback  updatedParentsPoints parentUserResponse", parentUserResponse)
        if(parentUserResponse){
          return put(actions.showSuccess({data: {
            user_response: {
              ...parentUserResponse, 
              points: parent.points, 
              marked: parent.marked, 
            }
          }}))
        }
      }))
      console.log("ur update callback  updatedParentsPoints userResponses after update", userResponses);
    }

    // marked_responses_count to be updated for all rubric types 
    let updatedEU = { 
      ...experienceUser, 
      marked_responses_count: updatedUserResponse.eu_marked_responses_count 
    }
    if(isPerQuestionGrading){
      // here we want to update eu points based on urs total
      updatedEU.points = updatedUserResponse.eu_points
      if(updatedUserResponse.eu_submission_criterium_associations){
        updatedEU.submission_criterium_associations_attributes = updatedUserResponse.eu_submission_criterium_associations
      }
    }

    yield put(experienceUserActions.showSuccess({data: {
      experience_user: updatedEU
    }}))
    
  } catch(e) {
    console.log("setParentsPointsUserResponseSaga", e);
    yield put(actions.parentsPointsFail({
      ...action.payload
    }));
  }

}

export function* recoverResponseSaga(action) {
  yield put(actions.recoverResponseStart());

  let url = "recover_response.json";
  try {
    console.log("action options ==>", action, action.options);
    const response = yield axiosInstance.instance.post(url, action.payload);
    console.log("recovere user response", response);

    if (response && response.data.user_response) {
      message.success(response.data.message || "Recovered successfully");
      yield put(actions.recoverResponseSuccess({ loading: false }));
      const { success, successCallback } = action.options;
      if (successCallback) {
        successCallback(response);
      }
    }
  } catch (error) {
    console.error("Recover user response error", error);
    yield put(actions.recoverResponseFail({ error }));
  }
}

export function* fetchMissingUserResponsesSaga(action) {
  // yield put(actions.fetchMissingStart());
  console.log("fetchMissingUserResponsesSaga action.payload", action.payload)
  try {
    const activeAdjustedExperienceId = yield select(activeAdjustedExperienceIdSelector())
    const experienceUsers = yield select(experienceUsersSelector())
    const activeExperienceUserId = yield select(activeExperienceUserIdSelector())

    let summaryData = {
      ...action.payload,
      experienceId: activeAdjustedExperienceId,
    }
    
    const { requestedExperienceUserIds, segmentId } = action.payload
    let missingExperienceUserIds = []
    let missingUserIds = []

    if (requestedExperienceUserIds) {
      experienceUsers.forEach((eu) => {
        if (requestedExperienceUserIds.indexOf(eu.id) >= 0) {
          if (segmentId) {
            const has_user_responses_for_segment_ids_in_redux = eu.has_user_responses_for_segment_ids_in_redux || []
            if (eu.has_user_responses_in_redux != true &&has_user_responses_for_segment_ids_in_redux.indexOf(segmentId) < 0) {
              missingExperienceUserIds.push(eu.id)
              missingUserIds.push(eu.user_id)
            }
          } else {
            if (eu.has_user_responses_in_redux != true) {
              missingExperienceUserIds.push(eu.id)
              missingUserIds.push(eu.user_id)
            }
          }
        }

        // In case of direct opening grading modal for the student which is not visible in grading table on page load, it will fetch the user responses for that student
        if (eu.id === activeExperienceUserId && eu.has_user_responses_in_redux != true) {
          missingExperienceUserIds.push(eu.id)
          missingUserIds.push(eu.user_id)
        }
      })
      summaryData = {
        ...summaryData,
        experienceUserIds: missingExperienceUserIds,
        userIds: missingUserIds,
      }
    }

    console.log("summary data and action ==>", summaryData, action)
    if (missingExperienceUserIds.length) {
      yield put(
        actions.summary(summaryData, action.options)
      );
    } else {
      let userResponses = []
       if (action.options.fromRedux) {
        userResponses = yield select(dbUserResponsesSelector(action.payload.experienceId, action.payload.userId,  action.payload.segmentId, action.payload.attemptNo, action.options.returnSingle, action.options.experienceUserId))
      }
      if (action.options && action.options.successCallback) {
        action.options.successCallback(userResponses)
      }
    }
    // yield put(actions.fetchMissingSuccess());
  } catch (error) {
    console.error("Fetch missing user responses error", error);
    yield put(actions.fetchMissingFail({ error }));
  }
}

// yield takeEvery(actionTypes., getCustomSaga);
export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  // [actionTypes.GET_CUSTOM_USER_RESPONSE_SUCCESS]: getCustomSuccess,
  [actionTypes.SUMMARY_USER_RESPONSE_SUCCESS]: summarySuccess,
});
export const watchUserResponses = reduxCrud.generateWatchSaga({
  // [actionTypes.GET_CUSTOM_USER_RESPONSE]: getCustomSaga,
  [actionTypes.SUMMARY_USER_RESPONSE]: summarySaga,
  [actionTypes.RECOVER_RESPONSE_USER_RESPONSE]: recoverResponseSaga,
  [actionTypes.SET_PARENTS_POINTS_USER_RESPONSE]: setParentsPointsUserResponseSaga,
  [actionTypes.FETCH_MISSING_USER_RESPONSE]: fetchMissingUserResponsesSaga,
});

export default reduxCrud;
