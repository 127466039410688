import { Button, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined, CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { canvasLoadingSelector } from "/src/views/CanvasAnnotations/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useLoadingCheck } from "../ExperienceUsersHelpers";
import { isGradingWithAIInProgressForEUSelector } from "/src/views/Experiences/selector";

const PublishResultButton = (props) => {
  const { item = {}, type = "", experience = {}, publishReviewers = false} = props
  console.log("PublishResultButton====>", props)

  const publishButtonStyle = {
    padding: 0,
    width: "100%",
    textAlign: "left",
    background: "transparent"           //made it transparent so that it won't show an extra background of button when hovered, which was the case with other dropdow elements 
  }
  const dispatch = useDispatch()
  const checkIfLoadingCompleted = useLoadingCheck();
  const canvasLoading = useSelector(canvasLoadingSelector()) 
  const isGradeAllSubmissionInProgressForEU = useSelector(isGradingWithAIInProgressForEUSelector(item.id))
  const mrIntl = useTranslate();
  const experienceSettings = experience.settings || {}
  const peerReviewEnabled = experienceSettings.peer_review_enabled
  const experienceRubric = experience.rubric
  const rubricType = experienceRubric.type_c
  let canPublishWithoutAllMarked =  peerReviewEnabled || rubricType === "myp_achievement_level" || rubricType === "comments_only" ? true : false
  const handlePublish = (e) => {
    checkIfLoadingCompleted(() => {
      e.stopPropagation();

      if (peerReviewEnabled && publishReviewers) {
        let publishParams = {
          experience: experience,
          publishStatus: item.marked ? "unpublish" : "publish",
          user_id: item.user_id
        }
        dispatch(experienceUserActions.publishResults(publishParams))
      } else {
        dispatch(
          experienceUserActions.setLoadingSuccess({
            loading: true,
            experience_user_id: item.id
          })
        )
  
        props.updateResource(
          { id: item.id, marked: !item.marked },
          {
            success: {
              showMessage: true,
              message: item.marked
              ? mrIntl("PublishResultButton.submission_unpublished")
              : mrIntl("PublishResultButton.submission_published"),
            },
            // inverted cux using item.marked value from before update
            error: {
              showMessage: true,
              message: mrIntl("PublishResultButton.error_in_updating_submission_status"),
            },
            successCallback: (data) => {
              dispatch(
                experienceUserActions.setLoadingSuccess({
                  loading: false,
                  experience_user_id: item.id
                })
              )
              console.log(
                "updateResource updated data",
                data,
                experience,
                item.marked
              );
              if (experience) {
                let marked_submissions_count =
                  experience.marked_submissions_count;
                let all_marked = false;
                let updatedEu = data.data.experience_user.data.attributes;
                if (updatedEu.marked) {
                  marked_submissions_count += 1;
                } else {
                  marked_submissions_count -= 1;
                }
                if (
                  marked_submissions_count === experience.submissions_count
                ) {
                  all_marked = true;
                }
                console.log(
                  "updated data",
                  marked_submissions_count,
                  all_marked
                );
                dispatch(
                  experienceReduxActions.showSuccess({
                    data: {
                      experience: {
                        ...experience,
                        marked_submissions_count: marked_submissions_count,
                        all_marked: all_marked,
                      },
                    },
                  })
                );
              }
            },
            errorCallback: (error) => {
              dispatch(
                experienceUserActions.setLoadingSuccess({
                  loading: false,
                  experience_user_id: item.id
                })
              )
            }
          }
        );
      }
    })
  }
  // let isMYPAchievementLevel =
  //   experience.rubric && experience.rubric.type_c === "myp_achievement_level"
  //     ? true
  //     : false;

  let buttonType = "primary"
  let isDanger = false
  if (type === "insideDropDown") {
    buttonType = "text"
  } else {
    if (!item.marked) {
      isDanger = true
    }
  }

  const reviewerUserIds = item?.custom_fields?.reviewer_user_ids || []
  const reviewerUserIdsLength = reviewerUserIds.length

  const getTitle = () => {
    if (peerReviewEnabled && reviewerUserIdsLength === 0) {
      return mrIntl("PublishResultButton.reviewers_need_to_be_assigned_to_publish_the_results");
    }
    if (isGradeAllSubmissionInProgressForEU) {
      return mrIntl("PublishResultButton.publishing_is_disabled_while_grading_is_in_progress");
    }
    if (!peerReviewEnabled && !item.marked && item.marked_responses_count < item.total_responses_count && !canPublishWithoutAllMarked) {
      return mrIntl("PublishResultButton.can_publish_once_all_answers_are_marked");
    }
    if (item.marked) {
      return mrIntl("PublishResultButton.change_grading");
    }
    return mrIntl("PublishResultButton.publish_will_let_students_see_their_results");
  };

  const isPublishDisabled = () => {
    if (peerReviewEnabled) {
      return reviewerUserIdsLength > 0 && !canvasLoading ? false : true;
    }
    return (
      canvasLoading ||
      isGradeAllSubmissionInProgressForEU ||
      (!item.marked &&
        item.marked_responses_count < item.total_responses_count &&
        !canPublishWithoutAllMarked)
    );
  };
 
  return (
    <>
      <Tooltip
        placement="bottom"
        title={getTitle()}
      >
        <Button
          type={buttonType}
          danger={isDanger}
          size={"small"}
          disabled={isPublishDisabled()}
          // type={type === "inSideDropDown" ? "text" : item.marked ? "primary" : "danger"}
          shape={type != "insideDropDown" ? "round" : "" }
          ghost={true}
          style={ type === "insideDropDown" ? publishButtonStyle : {}}
          loading={type != "insideDropDown" ? item.loading : false}
          onClick={(e) => handlePublish(e)}
        >
          {item.marked ? <EditOutlined /> : <CheckOutlined />}{" "}
          {item.marked ? mrIntl("PublishResultButton.change_grading") : mrIntl("PublishResultButton.publish_result")}
        </Button>
      </Tooltip>
    </>
  )
}

export default PublishResultButton;