import React from "react";
import PropTypes from 'prop-types';
import { List, Card } from "antd";
// import ActionBtns from "/src/components/ActionBtns/ActionBtns";
const BoardListItem = (props) => {
  // console.log("props", props);
  const { item, edited, deleted, actionBtns } = props;
  return (
    <List.Item>
      <Card title={item.key}>
        {actionBtns}
        {item.name} {JSON.stringify(item.settings)}
      </Card>
    </List.Item>
  );
};
BoardListItem.defaultProps = {};

BoardListItem.propTypes = {};

export default BoardListItem;
