import React, { useState, useEffect } from "react";
import { Spinner, MrSelect } from "mr_react_framework";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  appRegionSelector,
  currentUserSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";
import { checkMob, displayPoints, findStandardsByGrade, getUTCDate } from "/src/lib/utils/helperMethods";
import { Col, DatePicker, Radio, Row, Space, Switch, Table, Tabs, Tag, Tooltip } from "antd";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import { gradebookAnalyticsSelector } from "/src/views/Experiences/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import {
  SelectProgram, SelectStandardCollection, SelectSubject as SelectOrgSubject,
  SelectGrade as SelectOrgGrade,
  SelectGradeGroup,
  SelectStandardCollectionContentArea,
  SelectStandardCollectionOnly,
  SelectGradingScale,
} from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import { actions as tagActions } from '/src/views/Tags/redux.js';
import dayjs from "dayjs";
import { allTagsSelector } from "../Tags/selector";

const { RangePicker } = DatePicker;

const ProficiencyLevelTag = ({ level }) => {
  if (!level) return null;
  return <Tag color={level.color}>{level.label}</Tag>;
};

const ExperiencesStudentsGrid = (props) => {
  console.log("ExperiencesStudentsGrid props", props);
  const { data, viewBy, showProficiencyLevels } = props;
  const all_mapped_and_submitted_students = data.all_mapped_and_submitted_students || [];
  const experiences = data.experiences;
  const experiences_submissions_data = data.experiences_submissions_data;
  const standards = data.standards;
  const standards_performance_data = data.standards_performance_data;
  const mrIntl = useTranslate()
  const [gridRows, setGridRows] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  let finalRender = []

  useEffect(() => {
    let rows = [];
    let columns = [];
    if (viewBy === "standards") {
      columns[0] = {
        title: "Standards",
        dataIndex: "name",
        key: "name",
        width: 150,
        fixed: "left",
        // ellipsis: {
        //   showTitle: true,
        // },
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['ascend', 'descend'],
        defaultSorter: 'ascend'
      };
      columns[1] = {
        title: "Distribution",
        dataIndex: "distribution",
        key: "distribution",
        width: 350,
        fixed: "left",
        // ellipsis: {
        //   showTitle: true,
        // },
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['ascend', 'descend'],
        defaultSorter: 'ascend'
      };

      // TODO: show standards here but when propoerly filtered
      standards_performance_data.forEach((s, i) => {
        let row = {}
        // let userId = u[0]
        // let name = u[1]
        row.id = s.tag_id
        row.name = s.label;
        row.distribution = (
          <div style={{width: "500px", display: "flex"}}>
            {/* {JSON.stringify(s.distribution)} */}
            {s.distribution?.map((obj) => {
              if(obj.label !== "Ungraded" && obj.label !== "Unattempted"){
                return <Tooltip title={obj.label.concat(" - ").concat(obj.percentage).concat("%")}><div style={{width: `${obj.percentage}%`, backgroundColor: obj.color, height: "30px"}}></div></Tooltip>
              }
            })}
          </div>
        )

        rows.push(row);
      });

    } else {
      columns[0] = {
        // title: "Students ("+all_mapped_and_submitted_students.length+")",
        title: mrIntl("Gradebook.all_mapped_and_submit_student", {allMappedAndSubmitStudent: all_mapped_and_submitted_students.length}),
        dataIndex: "name",
        key: "name",
        width: 150,
        fixed: "left",
        // ellipsis: {
        //   showTitle: true,
        // },
        sorter: (a, b) => a?.name?.localeCompare(b?.name),
        sortDirections: ['ascend', 'descend'],
        defaultSorter: 'ascend'
      };
      // columns[1] = {
      //   title: "Avg. %",
      //   dataIndex: "average_percentage",
      //   key: "average_percentage",
      //   width: 100,
      //   sorter: (a, b) => a.average_percentage - b.average_percentage,
      //   sortDirections: ["ascend", "descend"],
      //   defaultSorter: "ascend",
      // };
      all_mapped_and_submitted_students.forEach((u, i) => {
        let row = {}
        // let userId = u[0]
        // let name = u[1]
        row.id = u.id
        row.name = u.name
        // row.average_percentage = (
        //   <span>{displayPoints((eu.points*100)/eu.custom_fields.experience_points)}%</span>
        // );
        
        if (experiences) {
          experiences.forEach((e, j) => {
            if (i == 0) {
              columns.push({
                title: (
                  // <Space direction={"vertical"}>
                    <span>{e.name.substring(0,6).concat("..")}</span>
                  //   <span>
                  //     ({displayPoints(segment.points, false, true)})
                  //   </span>
                  // </Space>
                ),
                dataIndex: j,
                key: "column-"+j,
                width: 100,
                sorter: (a, b) => a.j - b.j,
                sortDirections: ["ascend", "descend"],
                defaultSorter: "ascend",
              });
            }
  
            let euData = experiences_submissions_data.find((eu) => eu.experience_id === e.id && eu.user_id === u.id);
            
            if (euData) {
              let eu = euData.eu;
              if(eu){
                let euPercentage = displayPoints((eu.points*100)/eu.custom_fields.experience_points); // TODO percentage
                row[j] = (
                  <span>
                    {euPercentage}%
                    <br></br>
                    {showProficiencyLevels && <ProficiencyLevelTag level={euData.proficiency_level} />}
                  </span>
                );
              }else{
                // absent
                row[j] = (
                  <span>
                    <MrTranslate id={"Gradebook.absent"}/>
                  </span>
                );
              }
            }else{
              // Not mapped
              row[j] = "-"
            }
          });
        } else if (standards) {
          standards.forEach((s, j) => {
            if (i == 0) {
              columns.push({
                title: (
                  // <Space direction={"vertical"}>
                    <span>{s.label.substring(0,6)}</span>
                  //   <span>
                  //     ({displayPoints(segment.points, false, true)})
                  //   </span>
                  // </Space>
                ),
                dataIndex: j,
                key: "column-"+j,
                width: 100,
                sorter: (a, b) => a.j - b.j,
                sortDirections: ["ascend", "descend"],
                defaultSorter: "ascend",
              });
            }
  
            let standardsData = standards_performance_data.find((obj) => obj.tag_id === s.tag_id && obj.user_id === u.id);
            if (standardsData) {
              // let eu = euData.eu;
              // if(eu){
                // let euPercentage = displayPoints((eu.points*100)/eu.custom_fields.experience_points); // TODO percentage
                row[j] = (
                  <span>
                    {standardsData.average_percentage}%
                    <br></br>
                    {showProficiencyLevels && <ProficiencyLevelTag level={standardsData.proficiency_level} />}
                  </span>
                );
              // }else{
              //   // absent
              //   row[j] = (
              //     <span>
              //       <MrTranslate id={"Gradebook.absent"}/>
              //     </span>
              //   );
              // }
            }else{
              // Not mapped
              row[j] = "-"
            }
          });
        }
        rows.push(row);
      });
    }

    console.log("GradingGrid rows columns", rows, columns);
    setGridRows(rows);
    setGridColumns(columns);
  }, [ experiences, experiences_submissions_data, all_mapped_and_submitted_students.length, standards, standards_performance_data, showProficiencyLevels ]);

  let tableRender = <Table
    // className={"grading-ggrid"}
    dataSource={gridRows}
    columns={gridColumns}
    pagination={false}
    // size="small"
    showHeader={true}
    scroll={{ x: 800, y: 500 }}
  />
  
  finalRender.push(tableRender)
  return finalRender;
}

const Gradebook = (props) => {
  console.log("Gradebook props ==>", props);
  // const {  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const locationQueryParams = queryString.parse(location.search);
  const view_by_from_url = locationQueryParams.view_by;
  const content_area_id_from_url = locationQueryParams.content_area_id;
  const defaultViewBy = view_by_from_url || "students_vs_experiences"
  const defaultContentAreaId = content_area_id_from_url ? parseInt(content_area_id_from_url) : null
  // const [viewBy, setViewBy] = useState(defaultViewBy);
  // const [contentAreaId, setContentAreaId] = useState(defaultContentAreaId);
  const currentUser = useSelector(currentUserSelector());
  const currentOrg = currentUser?.org || {};
  const gradingScalesAttributes = currentOrg.grading_scales_attributes || [];
  const firstGradingScaleId = gradingScalesAttributes[0]?.id || null;
  const getDefaultFilterParams = () => {
    return {
      view_by: defaultViewBy,
      by_org_programme_id: null,
      by_subject_ids: [],
      by_grade_ids: [],
      by_date_range_selected: null,
      by_content_area_id: defaultContentAreaId,
      by_grade_group: null,
      by_standard_collection_id: null,
      by_grading_scale_id: null,
    };
  };

  const [filterParams, setFiltersParams] = useState(getDefaultFilterParams());
  const [showProficiencyLevels, setShowProficiencyLevels] = useState(false);
  let extraFilters = []

  const gradebookAnalyticsData = useSelector(gradebookAnalyticsSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const selectedContentArea = useSelector(allTagsSelector(filterParams?.by_content_area_id))

  const mrIntl = useTranslate()

  useEffect(() => {
    let shouldFetch = false
    console.log("Gradebook filterParams", filterParams);
    
    if(filterParams?.view_by?.includes("standard") && filterParams?.by_content_area_id) {
      // making contentAreaId required if vs standards
      shouldFetch = true
    } else if (!filterParams?.view_by?.includes("standard") && filterParams?.by_subject_ids.length > 0) {
      shouldFetch = true
    }
    
    if (shouldFetch){
      dispatch(experienceActions.getAnalytics({type: "gradebook", ...filterParams, with_proficiency_levels: filterParams.by_grading_scale_id ? true : false}))
    }
    return () => {
    };
  }, [filterParams]);

  // useEffect(() => {
  //   // console.log("StandardSetTagData useEffect selectedContentArea", selectedContentArea)
  //   const finalJsonData = selectedContentArea?.final_json?.data || [];
    // let filteredStandards = findStandardsByGrade(finalJsonData, gradeGroup?.label, null, "grade_group");
    // setFilteredTags(filteredStandards)
  // }, [selectedContentArea?.id, gradeGroup?.label])

  let filtersConfig = {
    showOrgProgrammeFilter: filterParams?.view_by === "students_vs_experiences",
    showSelectOrgSubjectDropdownFilter: filterParams?.view_by === "students_vs_experiences" && filterParams?.by_org_programme_id,
    showSelectOrgGradeDropdownFilter: filterParams?.view_by === "students_vs_experiences" && filterParams?.by_org_programme_id && filterParams?.by_subject_ids?.length > 0,
    showDateRangeFilter: filterParams?.view_by === "students_vs_experiences",
    showStandardCollectionFilter: filterParams?.view_by === "standards" || filterParams?.view_by === "students_vs_standards",
    showContentAreaFilter: (filterParams?.view_by === "standards" || filterParams?.view_by === "students_vs_standards") && filterParams?.by_standard_collection_id,
    showGradeGroupFilter: (filterParams?.view_by === "standards" || filterParams?.view_by === "students_vs_standards") && filterParams?.by_content_area_id,
    showGradingScaleFilter: enabledFeatures.set_grading_scales_for_sbg && (filterParams?.by_subject_ids?.length > 0 || filterParams?.by_content_area_id),
    showProficiencyLevelsFilter: enabledFeatures.set_grading_scales_for_sbg &&
      ["students_vs_experiences", "students_vs_standards"].includes(filterParams?.view_by) &&
      filterParams?.by_grading_scale_id &&
      (filterParams?.by_subject_ids?.length > 0 || filterParams?.by_content_area_id)
  }

  const fetchContentArea = (value) => {
    dispatch(tagActions.fetch({params: {by_ids: [value]}}))
  }

  if(filtersConfig.showOrgProgrammeFilter) {
    extraFilters.push(
      <SelectProgram
        key="selectOrgProgrammeFilter"
        dropdownMatchSelectWidth={false}
        value={filterParams?.by_org_programme_id}
        onChange={(value) => {
          setFiltersParams({ 
            ...filterParams, 
            by_org_programme_id: value,
            by_subject_ids: [],
            by_grade_ids: [],
          })
        }}
        config={{
          params: { by_org_id: currentUser?.org_id },
          widgetConfig: {
            style: { width: "150px" },
            placeholder: mrIntl("ExperiencesFilter.all_programs"),
          }
        }}
      />
    );
  }

  if (filtersConfig.showSelectOrgSubjectDropdownFilter) {
    extraFilters.push(
      <SelectOrgSubject
        key="selectSubjectSegmentsFilter"
        dropdownMatchSelectWidth={false}
        // value={by_subject_id}
        value={filterParams?.by_subject_ids}
        onChange={(value) => {
          setFiltersParams({
            ...filterParams, 
            by_subject_ids: value,
            by_grade_ids: [],
          })
        }}
        config={{
          forceReload: true,
          params: {
            unarchived: true,
            by_org_programme_id: filterParams?.by_org_programme_id,
          },
          widgetConfig: {
            enabledFeatures: enabledFeatures,
            style: {width: "150px"},
            placeholder: mrIntl("ExperiencesFilter.all_subjects"),
            mode: "multiple",
          },
        }}
      />
    );
  }

  if (filtersConfig.showSelectOrgGradeDropdownFilter) {
    extraFilters.push(
      <SelectOrgGrade
        key="selectGradeSegmentsFilter"
        // value={by_grade_id}
        value={filterParams?.by_grade_ids}
        onChange={(value) => {
          setFiltersParams({...filterParams, by_grade_ids: value})
        }}
        config={{
          forceReload: true,
          params: { by_org_programme_id: filterParams.by_org_programme_id },
          widgetConfig: { 
            style: {width: "150px"},
            placeholder: mrIntl("CommonText.all_grades"),
            mode: "multiple",
          }
        }}
        />
    );
  }

  if (filtersConfig.showDateRangeFilter) {
    let by_date_range_selected = [];
    if (filterParams?.by_date_range && filterParams?.by_date_range?.length > 0) {
      by_date_range_selected = filterParams?.by_date_range?.map((date) => dayjs(date));
    } else {
      by_date_range_selected = [dayjs().add(-1, 'month'), dayjs()];
    }
    extraFilters.push(
      <RangePicker
        placeholder={[mrIntl("ExperiencesFilter.start_date"), mrIntl("ExperiencesFilter.end_date")]}
        value={by_date_range_selected}
        className="date-picker"
        presets={[
          { label: mrIntl("ExperiencesFilter.today"), value: [getUTCDate({ type: "startOf" }), getUTCDate({ type: "endOf" })] },
          { label: mrIntl("ExperiencesFilter.last_7_days"), value: [getUTCDate({ type: "startOf", daysDiff: -7 }), getUTCDate({ type: "endOf" })] },
          { label: mrIntl("ExperiencesFilter.last_30_days"), value: [getUTCDate({ type: "startOf", daysDiff: -30 }), getUTCDate({ type: "endOf" })] },
          { label: mrIntl("ExperiencesFilter.last_90_days"), value: [getUTCDate({ type: "startOf", daysDiff: -90 }), getUTCDate({ type: "endOf" })] },
          { label: mrIntl("ExperiencesFilter.last_180_days"), value: [getUTCDate({ type: "startOf", daysDiff: -180 }), getUTCDate({ type: "endOf" })] },
          { label: mrIntl("ExperiencesFilter.last_365_days_1_year"), value: [getUTCDate({ type: "startOf", daysDiff: -365 }), getUTCDate({ type: "endOf" })] },
        ]}
        onChange={(value, dateString) => {
          setFiltersParams({ ...filterParams, by_date_range: value ? value.map((d) => d.toISOString()) : null })
        }}
      />
    );
  }
  if (filtersConfig.showStandardCollectionFilter) {
    extraFilters.push(
      <SelectStandardCollectionOnly
        value={filterParams?.by_standard_collection_id}
        onChange={(value, extra) => {
          setFiltersParams({
            ...filterParams,
            by_standard_collection_id: value,
            by_content_area_id: null,
            by_grade_group: null,
          })
        }}
        config={{
          widgetConfig: {
            style: {
              width: '250px', marginRight: '10px'
            },
            options: currentUser?.org?.enabled_standard_collections || []
          },
          // params: {
          //   should_serialize: false,
          // }
        }}
      />
    )
  }
  if (filtersConfig.showContentAreaFilter) {
    extraFilters.push(
      <SelectStandardCollectionContentArea
        value={filterParams?.by_content_area_id}
        onChange={(value, extra) => {
          setFiltersParams({
            ...filterParams,
            by_content_area_id: value,
            by_grade_group: null,
          })
          if (value) {
            fetchContentArea(value)
          }
        }}
        config={{
          params: {
            by_parent_id: filterParams?.by_standard_collection_id,
            should_serialize: true,
            with_final_json: false,
          },
          widgetConfig: {
            style: {
              width: '250px', marginRight: '10px'
            }
          },
        }}
      />
    )
  }
  if (filtersConfig.showGradeGroupFilter) {
    extraFilters.push(
      <SelectGradeGroup
        value={filterParams?.by_grade_group}
        onChange={(value, extra) => setFiltersParams({ ...filterParams, by_grade_group: value })}
        config={{
          params: {
            by_parent_id: filterParams?.by_content_area_id,
            should_serialize: false,
          },
          widgetConfig: {
            style: {
              width: '150px',
            },
            options: selectedContentArea?.grade_groups || [],
          },
        }}
      />
    )
  }
  if (filtersConfig.showGradingScaleFilter) {
    extraFilters.push(
      <SelectGradingScale
        key="selectGradingScaleFilter"
        value={filterParams?.by_grading_scale_id}
        config={{
          widgetConfig: {
            style: {width: "150px"},
            options: gradingScalesAttributes,
            allowClear: true,
          }
        }}
        onChange={(value) => {
          console.log("value ===>", value);
          setFiltersParams({...filterParams, by_grading_scale_id: value})
          if(filterParams?.view_by === "standards" && !value) {
            setFiltersParams({...filterParams, by_grading_scale_id: firstGradingScaleId})
          } else {
            setFiltersParams({...filterParams, by_grading_scale_id: value})
          }
        }}
      />
    )
  }
  if(filtersConfig.showProficiencyLevelsFilter) {
    extraFilters.push(
      <Space>
        <Switch
          checked={showProficiencyLevels}
          onChange={(checked) => setShowProficiencyLevels(checked)}
        /> <label>{mrIntl("ExperienceAnalyticsTags.show_proficiency_levels")}</label>
      </Space>
    )
  }
    
  console.log("Gradebook gradebookAnalyticsData ", gradebookAnalyticsData);

 
  return (
    <React.Fragment>
      <Row>
        <Col span={24} className="m-b-10">
          <Radio.Group defaultValue="students_vs_experiences" buttonStyle="solid" value={filterParams?.view_by} onChange={(e) => {
            if(e.target.value === "standards") {
              setFiltersParams({...getDefaultFilterParams(), view_by: e.target.value, by_grading_scale_id: firstGradingScaleId})
            } else {
              setFiltersParams({...getDefaultFilterParams(), view_by: e.target.value})
            }
            setShowProficiencyLevels(false)
            // setViewBy(e.target.value);
          }}>
            <Radio.Button value="students_vs_experiences">Students & Assessments</Radio.Button>
            <Radio.Button value="students_vs_standards">Students & Standards</Radio.Button>
            <Radio.Button value="standards">Standards</Radio.Button>
          </Radio.Group>
         
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {/* <Row justify="end" style={{marginRight: "70px"}}> */}
            <Space>
              {extraFilters}
            </Space>
          {/* </Row> */}
        </Col>
      </Row>
      <Row>
        {/* Filters */}
      </Row>
      <Row className="m-t-20">
        <Col span={22} offset={1}>
          {gradebookAnalyticsData.grid_data && <ExperiencesStudentsGrid data={gradebookAnalyticsData.grid_data} viewBy={filterParams?.view_by} showProficiencyLevels={showProficiencyLevels}/>}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Gradebook;
