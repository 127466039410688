import React from "react";
import { List, Space } from "antd";
import "/src/App/App.scss";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";
import { useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";

const RubricsList = ({ dataSource, actionBtns, deleted, permission}) => {
  console.log("inside RubricsList==>", dataSource);
  const currentUser = useSelector(currentUserSelector())
  // let listHeader = <Tag color="processing">{dataSource.length} schools</Tag>
  const Title = ({ item }) => item.title;

  const Description = ({ item }) => (
    <Space className="description">{item.desc}</Space>
  );
  return (
    <>
      <List
        // header={listHeader}
        className="custom-main-list"
        // loading={initLoading}
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(item, i) => (
          <CustomListItem
            item={item}
            type="rubrics"
            linkToGo={currentUser.role === "support"
            ? `/u/orgs/${item.id}/edit`
            : `/u/orgs/${item.id}`}
            // currentUser={currentUser}
            title={<Title item={item} />}
            description={<Description item={item} />}
            edit={permission.rubric && permission.rubric.update}
            deleted={deleted}
            deletedAllow={false}
            // permission={permission}
            // showForm={props.showForm}
            // setUserToSetPassword={setUserToSetPassword}
          />
        )}
      />
    </>
  );
};

export default RubricsList;
