import React, {useEffect, useRef, useState} from "react";
// import "./GroupItemDetail.scss";
import {Route, useRouteMatch} from "react-router-dom";
import { Tabs, Button, Row, Col, Tag, Space, Popover, Card, Tooltip } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  SoundOutlined
} from "@ant-design/icons";
import {actions as experienceUserActions} from "/src/views/ExperienceUsers/redux"
import { useDispatch, useSelector } from "react-redux";
import { activeAdjustedExperienceSelector, printModeSelector } from "/src/views/Experiences/selector";
import { displayPoints, getAlphabetAtIndex, getQuestionContent, getStudentAnswer, isStringEmpty, removeHTMLTagsFromText, renderMathInElement } from "/src/lib/utils/helperMethods";
import { actions as userResponseActions } from "/src/views/UserResponses/redux"
import { autoCheckableQuestionTypes, getMarkingScheme } from "/src/views/Segments/InteractiveHelpers";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import ListAttachments from "/src/components/UI/Attachments/ListAttachments";
import ShowMediaComments from "/src/views/UserResponses/ShowMediaComments";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import _, { includes, isObject } from "lodash";
import dayjs from "dayjs";
import CheckTextOriginality from "/src/views/Segments/CKETextContent/CheckTextOriginality";
import CheckGradeWithAI from "/src/views/Segments/CKETextContent/CheckGradeWithAI";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import relativeTime from 'dayjs/plugin/relativeTime';
import { getSegmentByIdSelector } from "../../Segments/selector";
import CheckGradeWithAINew from "../../Segments/CKETextContent/CheckGradeWithAINew";

// const usePrevious = value => {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// };

const RelativeTime = ({time, interval = 60}) => {
  const [localTime, setTime] = useState(dayjs(time).fromNow());
  let interval1

  useEffect(() => {
    interval1 = setInterval(() => {
      setTime(dayjs(time).fromNow());
    }, interval * 1000);
    return () => clearInterval(interval1);
  }, [time, interval]);

  
  return (
    <>
      {localTime}
      {/* sss */}
    </>
  );
}

const UserResponsesItemDetail = (props) => {
  const {item, graderAttrs, permission} = props
  const segment = graderAttrs.segment || {}
  const segmentSettings = segment.settings || {}
  const question = graderAttrs.segment.question_segment_attributes || {}
  const markscheme = segmentSettings.include_markscheme ? question.markscheme : "";
  const answerExplanation = segmentSettings.include_explanation ? question.answer_explanation : "";
  const questionType = question.question_type;
  const experienceUser = graderAttrs.experienceUser
  const {path, url} = useRouteMatch();
  const [showGradingForm, setShowGradingForm] = useState(null)
  console.log( "UserResponsesItemDetail graderAttrs", props);
  dayjs.extend(relativeTime)  
  const mrIntl = useTranslate()

  let parentSegmentContent; 
  let parentSegment = useSelector(getSegmentByIdSelector(segment.parent_id))
  
  const printExperience = useSelector(state => state.experiences.get("printExperience"))
  const currentUser = useSelector(currentUserSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const printMode = useSelector(printModeSelector());
  // const activeExperienceId = useSelector(activeAdjustedExperienceIdSelector())
  // console.log("UserResponsesItemDetail activeExperienceId", activeExperienceId)
  // // const activeExperience = useSelector(activeAdjustedExperienceSelector)
  // let activeExperience = useSelector(state => state.experiences.get("experiences").find((item) => item.id == activeExperienceId))
  // const showMiniAudioPlayer = graderAttrs.showAnnotatorToStudent
  let activeExperience = useSelector(activeAdjustedExperienceSelector())
  if(printMode && printExperience){
    activeExperience = printExperience.experience
    console.log("activeExperience", activeExperience )
  }
  const rubricType = activeExperience.rubric && activeExperience.rubric.type_c
  const dispatch = useDispatch()
  const commentRef = useRef()

  useEffect(() => {
    setShowGradingForm(false)
    let timeout = setTimeout(() => {setShowGradingForm(true)}, 1)
    return () => {
      clearTimeout(timeout)
    }
  }, [item.id])

  useEffect(() => {
    if(commentRef.current) {
      renderMathInElement(commentRef.current)
    }
  }, [experienceUser.marked])

  let criteriaPointsView = []
  if (printMode) {
    if (item.submission_criterium_associations_attributes) {
      item.submission_criterium_associations_attributes.forEach((ca, i) => {
        criteriaPointsView.push(
          <>
            <Tooltip
              key={`criteria-${ca.id}`}
              title={ca.custom_fields.title + ": " + ca.custom_fields.label}
            >
              <Tag>
                {ca.custom_fields.title}{" "}
                {rubricType &&
                  (rubricType === "myp_achievement_level"
                    ? (ca.points ? displayPoints(ca.points) : "-")
                    : rubricType === "criteria_with_points"
                      ? (ca.points ? `${displayPoints(ca.points)} / ${displayPoints(ca.custom_fields.max_points)}` : "-")
                      : "-")
                }
              </Tag>
            </Tooltip>
          </>
        );
      });
    }
  } else {
    if(item.submission_criterium_associations_attributes){
      item.submission_criterium_associations_attributes.map((ca, i) => {
        const title = ca.custom_fields.title || ""
        if(rubricType === "myp_achievement_level") {
          const points = ca.points !== null ? displayPoints(ca.points) : "-"
          console.log("ca.points ======>", ca.points, points);
          criteriaPointsView.push(<Tag color="blue">{title} {points}</Tag>)
        } else {
          let caMaxPoints = ca.custom_fields.max_points
          criteriaPointsView.push(<Tag color="blue">{title} {displayPoints(ca.points)}/{displayPoints(caMaxPoints)}</Tag>)
        }
      })
    }
  }

  let isPerQuestionGrading = (activeExperience && activeExperience.settings && activeExperience.settings.grading_setting == "per_question") ? true : false
  // let isPerQuestionGrading = (activeExperience && activeExperience.settings && activeExperience.settings.grading_setting == "per_question") || (rubricType === "myp_achievement_level" || rubricType === "comments_only") ? true : false
  let isMBTask = activeExperience && activeExperience.custom_fields && activeExperience.custom_fields.mb_task_id ? true : false
  const showTotalPoints = isPerQuestionGrading && rubricType !== "myp_achievement_level" && rubricType !== "criteria_with_points";

  const isStudent = currentUser.role === "student" || currentUser.role === "parent";
  const sca = item.submission_criterium_associations_attributes;
  const hasSubmissionCriterium = sca && sca.some(sca => sca.points != null && sca.points != undefined);
  const showCriteriaPointsView = isStudent
    ? (isPerQuestionGrading || (rubricType === "myp_achievement_level" && hasSubmissionCriterium))
    : (isPerQuestionGrading || (rubricType === "myp_achievement_level" && !permission.experience.hide_per_question_grading_myp_al)) && sca && sca.length > 0;

  const commentsToShow = !isStringEmpty(item.comments) ? <div dangerouslySetInnerHTML={{ __html: item.comments }}></div> : printMode ? <Space><p className="no-comments">No comments for this question</p></Space> : ""
  console.log( "UserResponsesItemDetaiL props", item.comments, commentsToShow );

  const pointsToShow = <>
    {showTotalPoints ? <span>{item.points ? displayPoints(item.points, isMBTask) : "__"} / {displayPoints(item.max_points, isMBTask)} pts</span> : ""}
    {showCriteriaPointsView && <Space size={0}>{criteriaPointsView}</Space>}
  </>
  console.log("pointsToShow==>", criteriaPointsView, item)

  var media_comments;
  if (item.custom_fields.media_comments) {
    media_comments = item.custom_fields.media_comments;
  } 

  var correct_status = item.response_json && item.response_json.correct_status || { }

  const isSubjectiveQuestionAttemptedAndAllowedForAIDetection = () => {
    const questionType = segment.question_segment_attributes.question_type
    const allowedSubjectsForAIDetection = [
      "English",
      "Physics",
      "Biology",
      "Chemistry",
      "Sciences",
      "Mathematics",
      "History",
      "Economics",
      "Dance",
      "Design",
    ];
    const allowedQuestionTypesForAIGrading = [
      "cke_subjective",
      "mcq_single",
    ]
    if(enabledFeatures.allow_grade_all_question_types) {
      allowedQuestionTypesForAIGrading.push(
        "rds_table",
        "fabric_drawing",
        "ggb_graph",
        "desmos_graph",
        // "audio_input",
        // "video_input"
      );
    }
    return (
      item.attempted &&
      segment.segment_type === "question" &&
      allowedQuestionTypesForAIGrading.includes(questionType)
      && activeExperience 
      // && includes(
      //   allowedSubjectsForAIDetection,
      //   activeExperience.relation_items[0].subject_items[0].label
      // )
    );
  };



  let shouldShowAIDetectionButton = false;
  let shouldShowGradeWithAIButton = !experienceUser.marked && isSubjectiveQuestionAttemptedAndAllowedForAIDetection();

  if (enabledFeatures.check_ai_originality_score || import.meta.env.VITE_MODE === "development"){
    shouldShowAIDetectionButton = isSubjectiveQuestionAttemptedAndAllowedForAIDetection() && false // Hiding the AI detection in all cases
  }
  // if ((enabledFeatures.grade_with_ai || import.meta.env.VITE_MODE === "development" ) && rubricType !== "myp_achievement_level"){
  // if ((enabledFeatures.grade_with_ai || import.meta.env.VITE_MODE === "development" )){
  //   shouldShowGradeWithAIButton = isSubjectiveQuestionAttemptedAndAllowedForAIDetection()
  // }

  if(shouldShowGradeWithAIButton && parentSegment && parentSegment.segment_type === "question") {
    parentSegmentContent = parentSegment.question_segment_attributes.content;
  }

  const onSuccess = (comment, points = null, criteria = []) => {
      let newCustomFieldsObject = item.custom_fields && typeof item.custom_fields === "object" ? {...item.custom_fields} : {};
      newCustomFieldsObject = {
        ...newCustomFieldsObject,
        ai_used: {
          ...newCustomFieldsObject.ai_used,
          points: true,
          comments: true,
        },
      };

      console.log("onSuccess ss", comment, points);
      if(item.submission_criterium_associations_attributes && item.submission_criterium_associations_attributes.length > 0){
        // !TEMP until MYP grading function calling works
        // let first_sca = item.submission_criterium_associations_attributes[0]
        // first_sca.points = criteria[0].points
        // !TEMP until MYP grading function calling works
        item.submission_criterium_associations_attributes.forEach(element => {
          let matchingCriteria = criteria.find((c) => { return parseInt(element.criterium_id) === parseInt(c.id) }) // there are times it may not return id - also match title? - sometimes points key not returned
          console.log("onSuccess ss matchingCriteria", matchingCriteria);
          if(matchingCriteria && matchingCriteria.points !== undefined && matchingCriteria.points !== null){ // can be 0
            element.points = matchingCriteria.points
          }
        });

        let scas = item.submission_criterium_associations_attributes

        let pointsNull = false
         if (rubricType !== "points" && ((!scas || !scas.length) || (scas && scas.length > 0 && scas.find((sca) => sca.points === null || sca.points === undefined)))) {
          // !scas.length to handle old AL submissions where we didn't create scas
          pointsNull = true
        }

        const isMarked = pointsNull ? false : true

        dispatch(userResponseActions.update({
          id: item.id,
          comments: comment,
          submission_criterium_associations_attributes: item.submission_criterium_associations_attributes,
          custom_fields: newCustomFieldsObject,
          marked: isMarked
        }, {
          success: { showMessage: true, message: mrIntl("CommonText.points_comments_saved") },
          successCallback: (response) => {
            console.log('after callback==>', response)
            setShowGradingForm(false)
            let timeout = setTimeout(() => {setShowGradingForm(true); clearTimeout(timeout)}, 1)
            let updatedUserResponse = response.data.user_response.data.attributes
              dispatch(userResponseActions.setParentsPoints({ updatedUserResponse, isPerQuestionGrading }));
          },
          errorCallback: (error) => {
            // reject("Something went wrong, Not able to update annotation")
          },
        }));
      } else {
      // DP
      const commentsInfluenceMarked = rubricType === "comments_only"
      const commentsNull = isStringEmpty(comment) 

      let pointsNull = false
      if (points == undefined || points == null) {
        pointsNull = true
      }
      console.log("item.points ====>", points);
      const isMarked = (pointsNull && item.points == null) || (commentsInfluenceMarked && commentsNull) ? false : true
      // checking to make sure no existing points assigned - in case of MCQ - already points assigned, only generating comments - so this was setting marked false
      
      let aiGradedUserResponse = {
        id: item.id,
        comments: comment,
        // points: points,
        marked: isMarked,
        custom_fields: newCustomFieldsObject
      }
      if (rubricType !== "comments_only" && !pointsNull) {
        aiGradedUserResponse = {
          ...aiGradedUserResponse,
          points: points
        }
      }
      dispatch(
        userResponseActions.update(
          aiGradedUserResponse,
          {
            success: {
              showMessage: true,
              message: mrIntl("CommonText.points_comments_saved"),
            },
            successCallback: (response) => {
              console.log(
                "after callback==>",
                item.custom_fields,
                newCustomFieldsObject
              );
              setShowGradingForm(false);
              let timeout = setTimeout(() => {
                setShowGradingForm(true);
                clearTimeout(timeout);
              }, 1);
              let updatedUserResponse = response.data.user_response.data.attributes
              dispatch(userResponseActions.setParentsPoints({ updatedUserResponse, isPerQuestionGrading }));
            },
            errorCallback: (error) => {
              // reject("Something went wrong, Not able to update annotation")
            },
          }
        )
      );
    }
  };
  // console.log("item.attempt_status.words==>", item.attempt_status.words)
  // return <>shikhar</>
  
  const getStudentAnswerImagesForSegment = (euAttachmentsJson) => {
    // need to get corresponding segment images from euAttachmentsJson images -> not euAttachmentsJson attachments. images have images from converted pdf also - APL-5787
    let images = euAttachmentsJson?.images?.filter((a) => a.segment_id === segment.id) || []

    return images
  }
  
  return <React.Fragment key={`user-response-${item.id}`}>
    {/* {graderAttrs.gradingEnabled && <span>UR show - {JSON.stringify(item)}</span>} */}
    

    <Row>
      <Col span={24}>
        {/* need to rerender form when item changes */}
        {item.attempted && !printMode && <Tag color="blue"> <MrTranslate id={"UserResponsesItemDetail.attempted"}/></Tag>}
        {!item.attempted && !printMode && <Tag color="warning"><ExclamationCircleOutlined /> <MrTranslate id = {"UserResponsesItemDetail.not_attempted"}/></Tag>}
        {item.attempted && autoCheckableQuestionTypes.indexOf(questionType) >=0 && import.meta.env.VITE_MODE != 'production' && !_.isEmpty(correct_status) &&
          <Popover content={getMarkingScheme(questionType)} placement="bottom" overlayClassName="marking-scheme-popover">
            {correct_status.all_correct && <Tag color="green"><CheckOutlined /> <MrTranslate id={"UserResponsesItemDetail.correct_tags"}/></Tag>}
            {correct_status.partial_correct && <Tag color="green"><CheckOutlined /> <MrTranslate id={"UserResponsesItemDetail.partial_correct_tags"}/></Tag>}
            {!correct_status.all_correct && !correct_status.partial_correct && <Tag color="red"><CloseOutlined /> <MrTranslate id={"UserResponsesItemDetail.incorrect_tags"}/></Tag>}
          </Popover>}      
        {/* {item.attempted.toString()} */}
        
        {shouldShowAIDetectionButton && graderAttrs.gradingEnabled && <CheckTextOriginality disabled={!item.attempt_status.words || (item.attempt_status.words && item.attempt_status.words < 100)} text={item.text_answer} updateResource={props.updateResource} id={item.id} custom_fields={item.custom_fields} />}
        {shouldShowGradeWithAIButton && !enabledFeatures.new_grade_with_ai && graderAttrs.gradingEnabled && <div className="m-t-20"><CheckGradeWithAI rubricType={rubricType} questionType={questionType} answerExplanation={answerExplanation} markscheme={markscheme} questionText={getQuestionContent(question.question_type, question)} parentQuestionText = {parentSegmentContent} studentAnswer={getStudentAnswer(question.question_type, item)} studentAnswerImages={getStudentAnswerImagesForSegment(experienceUser.attachments_json)} userResponse={item} maxPoints={item.max_points} scas={item.submission_criterium_associations_attributes} segmentId={segment.id} onSuccess={onSuccess}/></div>}
        <div>
          {enabledFeatures.new_grade_with_ai && shouldShowGradeWithAIButton && graderAttrs.gradingEnabled && <CheckGradeWithAINew {...graderAttrs} setShowGradingForm={setShowGradingForm}/>}
          {showGradingForm && !experienceUser.marked && graderAttrs.gradingEnabled && currentUser.role !== "support" && question.question_type !== "group" && props.renderForm({
          // {graderAttrs.gradingEnabled && props.renderForm({
            name: "userResponses",
            config: { isModal: false, visible: true, actions: 
              {
                showSavedMsg: true,
                showSubmitBtn: false,
                autoSaveConfig: {
                  shouldAutoSave: true,
                  // validateAllFieldsTouched: false, // default is true. Here we want to keep it true - APL-3039
                  debounce: 100, // IMPORTANT TO HAVE 100 (NOT 10) - SEE APL-3275
                  // successMsg: "Last saved",
                  successMsg: mrIntl("CommonText.saved_successfully_success_msg"),
                  // successMsg: <Space><CheckOutlined /> Saved</Space>,
                  errorMsg: mrIntl("CommonText.error_in_saving"),
                  loadingMsg: mrIntl("UserResponsesItemDetail.saving"),
                  hideMessage: true, // to hide the saved/error message after a few seconds
                  hideMessageTimeout: 3000,
                }
              },
              submitConfig: {
                // this success and error is only used when shouldAutoSave false - to use same - TODO later
                success: {showMessage: true, message: mrIntl("CommonText.points_comments_saved")}, 
                error: {showMessage: false, message: mrIntl("CommonText.error_in_saving_points_comments")}, 
                // this success and error is only used when shouldAutoSave false

                getSavedAtMsg: (data) => {
                  // console.log("getSavedAtMsg", data.data.user_response.data.attributes.updated_at); 
                  // let updatedAt = data.data.user_response.data.attributes.updated_at
                  // let lastSavedDateTime = dayjs(updatedAt).format("MMM DD, hh:mm:ss a")
                  // let lastSavedDateTime = dayjs(updatedAt).fromNow()
                  // return lastSavedDateTime;
                  // return <RelativeTime time={updatedAt}/> // working but not using for now
                  return " "; //needs to be a space otherwise not rendering successMsg
                },
                successCallback: (data) => {
                  let updatedUserResponse = data.data.user_response.data.attributes
                  console.log("points saved callback", data, updatedUserResponse.eu_points)
                  // if(isPerQuestionGrading){
                  // Calling in all cases because it also updates grading progress - eu.marked_responses_count - since setting marked true in AL and comments_only cases also now even though they are "overall" grading. TODO handle this for custom rubrics later
                    dispatch(userResponseActions.setParentsPoints({ updatedUserResponse, isPerQuestionGrading }));
                  // }
                }
              }
            },
            resource: item //use this for edit
          })}
        </div>
        {/* on ur success callback update eu points above */}

        {console.log("condition checking==>", experienceUser, printMode, currentUser, questionType)}

        {(experienceUser.marked || currentUser.role === "support" || question.question_type === "group") && !printMode &&
          <Space className="points-and-comments" direction="vertical" ref={commentRef}>
              {/* {item.marked.toString()} */}
            {/* {isPerQuestionGrading && <span>Points: {parseFloat(item.points).toFixed(1)} / {parseFloat(item.max_points).toFixed(1)}</span>} */}
            {/* {isPerQuestionGrading ? <span>{item.points ? parseFloat(item.points).toFixed(1) : "__"} / {parseFloat(item.max_points).toFixed(1)} pts</span> : ""}  */}
            {pointsToShow}
            {!isStringEmpty(item.comments) && <span style={{"color":"#aaa"}}>{mrIntl("UserResponsesItemDetail.comment")}</span>} 
            {commentsToShow}
            <div> {media_comments && <ShowMediaComments media_comments={media_comments} />}</div>
            
          </Space>
        }
        {printMode && question.question_type !== "group" && (
          <>
            <Card
              className="print-teacher-comments"
              style={{ marginTop: "20px" }}
              title={"Teacher's comments"}
              extra={[<>{pointsToShow}</>]}
            >
              {commentsToShow}
              <br />
              {media_comments && <Space><p className="no-comments"><span><SoundOutlined /></span>{" "}This comment includes audio feedback. Please visit AssessPrep to listen to this feedback.</p></Space>}
            </Card>
            
          </>
        )}

        
        {/* {!experienceUser.marked && <p>Awaiting grading</p>} */}
      </Col>
    </Row>
    
    
  </React.Fragment>
}


UserResponsesItemDetail.defaultProps = {};

UserResponsesItemDetail.propTypes = {};

export default UserResponsesItemDetail;
