import React from "react";
import { useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { Tooltip, Button, Popconfirm, List, Space } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { BsThreeDots  } from "react-icons/bs"
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import ListActionsMenu from "/src/components/UI/ListActionsMenu/ListActionsMenu";
import { dropDownTrigger, openUrl } from "/src/lib/utils/helperMethods";
import { useDispatch } from "react-redux";
const WithLink = ({ link, children }) =>
  link ? <Link to={link}>{children}</Link> : children;
  const CustomListItem = ({
  type,
  linkToGo = null,
  item,
  edit,
  editShouldRefreshPage,
  deleted,
  deletedAllow,
  permission,
  title,
  description,
  show,
  extraActions,
  menuItemActions = [],
  isListActionsMenu,
  extraInfo,
  avatar,
}) => {
  const { url } = useRouteMatch();
  const currentUser = useSelector(currentUserSelector());
  const mrIntl = useTranslate();
  const listType = type ? type : "blank";
  const dispatch = useDispatch();
  let actionsArray = [];
  let menuItemActionsArray = [];

  if (isListActionsMenu) {
    menuItemActions.forEach((extra) => menuItemActionsArray.push(extra));
  }

  if (extraActions) {
    // actionsArray.push(extraActions);
    extraActions.forEach((extra) => actionsArray.push(extra));
  }

  const ShowBtn = ({ withText }) => {
    return (
      <Space>
        <Link to={`${url}/${item.id}`} key={`show-${item.id}`}>
          <Tooltip title= {withText ? "" : mrIntl("CommonText.show")}>
            <Button type="text" shape={withText && "circle"} icon={<EyeOutlined />}>{withText ? withText : ""}</Button>
          </Tooltip>
        </Link>
      </Space>
    );
  } 

  if (show) {
    if(isListActionsMenu) {
      menuItemActionsArray.push(<ShowBtn withText={mrIntl("CommonText.show")}/>);
    } else {
      actionsArray.push(<ShowBtn />);
    }
    
  }

  const EditBtn = ({ withText, editShouldRefreshPage }) => {
    console.log("EditBtn editShouldRefreshPage", editShouldRefreshPage);
    return (

      // <Space>
        editShouldRefreshPage ? 
          <Tooltip title= {withText ? "" : mrIntl("CommonText.edit")}>
            <Button key={`edit-${item.id}`} className="list-item-edit-button" type="text" shape={!withText && "circle"} icon={<EditOutlined />} onClick={() => {
              openUrl(`${url}/${item.id}/edit`)
            }}>{withText && withText}</Button>
          </Tooltip>
        :
          <Link to={`${url}/${item.id}/edit`} key={`edit-${item.id}`}>
            <Tooltip title= {withText ? "" : mrIntl("CommonText.edit")}>
              <Button className="list-item-edit-button" type="text" shape={!withText && "circle"} icon={<EditOutlined />}>{withText && withText}</Button>
            </Tooltip>
          </Link>
        
      // </Space>
    )
  }

  if (edit) {
    if(isListActionsMenu) {
      menuItemActionsArray.unshift(<EditBtn withText={mrIntl("CommonText.edit")} editShouldRefreshPage={editShouldRefreshPage}/>);
    } else {
      actionsArray.unshift(<EditBtn editShouldRefreshPage={editShouldRefreshPage}/>);
    }
  }

  const confirm = (e) => {
    e.stopPropagation();
    let successMsgObj = {}
    if(type === "groups_list") {
      successMsgObj.success = {
        showMessage: true, 
        message: mrIntl("CustomListItem.class_archived")
      }
    }
    deleted(item, successMsgObj);
  };
  const cancel = (e) => {
    e.stopPropagation();
  };
  const DeleteBtn = ({ withText }) => {

    return (
      <Popconfirm
        key={`delete-${item.id}`}
        title={mrIntl("CommonText.confirm_archive")}
        // title="Confirm archive?"
        onConfirm={confirm}
        onCancel={cancel}
        placement={withText && "left"}
        okText= {mrIntl("CommonText.yes")}
        cancelText= {mrIntl("CommonText.no")}
      >
        <Tooltip title= {withText ? "" : mrIntl("CommonText.archive")} placement="bottom">
          <Button
          className="list-item-edit-button"
            type="text"
            onClick={(event) => {event.stopPropagation()}}
            shape={!withText && "circle"}
            icon={<DeleteOutlined />}
          >{withText ? withText : ""}</Button>
        </Tooltip>
      </Popconfirm>
    );
  } 

  
  if (deletedAllow) {
    if(isListActionsMenu) {
      menuItemActionsArray.push(<DeleteBtn withText={mrIntl("CommonText.archive")} />);
    } else {
      actionsArray.push(<DeleteBtn />)
    }
  }

  if(isListActionsMenu && menuItemActionsArray.length > 0) {
    actionsArray.push(<ListActionsMenu
      dropdownKey={`${item.id}-more-menu-drop-down`}
      menuItems={menuItemActionsArray} 
      triggerType={"click"}>
        <Button 
          shape="circle" 
          icon={<BsThreeDots />} 
          type="text" 
          className="action-menu-dropdown" 
          onClick={(event) => event.preventDefault()} 
          onContextMenu={(e) => e.stopPropagation()}
        />
    </ListActionsMenu>)
  }
  

  // const linkToGo =
  //   type === "rubrics"
  //     ? currentUser.role === "support"
  //       ? `/u/orgs/${item.id}/edit`
  //       : `/u/orgs/${item.id}`
  //     : type === "groups_list" && !item.archived
  //     ? `${url}/${item.id}`
  //     : type === "users" ? `${url}/${item.id}/edit` : null;

  const extraInfoArray = [];
  extraInfoArray.push(extraInfo);

  const itemId = item.id || item.uid;
  const listItemKey = `${listType}-list-item-${itemId}`;
  let listItem = (
    <List.Item
      key={listItemKey}
      actions={actionsArray}
      // className={["custom-main-list"]}
    >
      <List.Item.Meta title={title} description={description} avatar={avatar} />
      {extraInfoArray}
    </List.Item>
  );
  
  listItem = 
    isListActionsMenu && menuItemActionsArray.length > 0 ? <ListActionsMenu
      dropdownKey={`${item.id}-more-menu-drop-down`}
      menuItems={menuItemActionsArray}
      triggerType={"contextMenu"}
    >
      {listItem}
    </ListActionsMenu> : listItem

  return <WithLink link={linkToGo}>{listItem}</WithLink>;
};

export default CustomListItem;
