import React, { useEffect, useState } from "react";
import { Layout, Button, Space, Row, Col, Tooltip, Tag} from "antd";
import * as Sentry from "@sentry/react";
import styled from "styled-components";

import "./AuthLayout.scss";
import BaseLayout from "../BaseLayout/BaseLayout";
// import LogoImage from "../../../assets/images/logo.png";
import LogoImage from "../../../assets/images/logo-hor.png";
import {
  useRouteMatch,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "/src/components/UI/Spinner/Spinner";
import queryString from "query-string";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import {
  getFromLS,
  deleteFromLS,
  setToLS,
  openUrl,
  checkIPAD,
  getMobileOperatingSystem,
  getOS,
  checkIfSEBConfigIsValid,
} from "/src/lib/utils/helperMethods";
import {
  appTypeSelector,
  sebConfigFileSelector
} from "/src/App/OfflineApp/offlineAppSelectors";
import { CheckAndSetAppType } from "/src/App/OfflineApp/CheckAndSetAppType";
import QuitBtn from "/src/components/UI/QuitBtn";
import RegionSelector from "/src/components/UI/RegionSelector/RegionSelector";
import DownloadAppsModal from "/src/views/OfflineApps/DownloadAppsModal";
import EnvSelection from "/src/components/UI/EnvSelection/EnvSelection";
import { actions as embeddedResourcesActions } from "/src/views/EmbeddedResources/redux";
import { actions as loginReduxActions } from "/src/views/Auth/Login/redux";
import IcpText from "/src/components/UI/IcpText/IcpText";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { getAppVersion, getDeviceInfo } from "/src/App/OfflineApp/offlineAppHelper";

const { Header, Content, Footer } = Layout;
const ContentTop = styled.div`
  display: block;
  text-align: center;
  box-sizing: border-box;
  ::before,
  ::after {
    box-sizing: border-box;
  }
`;
const ContentMain = styled.div`
  display: block;
  width: 100%;
  max-width: 500px;
  padding: 10px 0px 0px 0px;
  margin: 0 auto;
  ::before,
  ::after {
    box-sizing: border-box;
  }

  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  // width: 550px;
  border-radius: 10px;
`;

const StyledLogo = styled.img`
  src: url(${(props) => props.src});
  // width: 100px;
  // width: 50px;
  height: 50px;
  margin-bottom: 30px;
`;

const styledContentTop = {};
const redirectUrlKey = "ap-redirect-url";
const AuthLayout = (props) => {
  console.log("AuthLayout ====>", props);
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, tokenPresent } = props;
  const appType = useSelector(appTypeSelector());
  const mrIntl = useTranslate()
  
  // const sebConfigFileUrl = useSelector(sebConfigFileSelector())
  let queryParams = {};
  queryParams = queryString.parse(location.search);

  useEffect(() => {
    try {
      if (tokenPresent && !queryParams.jwt) {
        dispatch(loginActions.checkAuthState());
      }
      const locationQueryParams = queryString.parse(location.search);
      // Sentry.captureMessage(`AuthLayout location ${window.location.href}`);
      console.log("locationQueryParams", locationQueryParams);
      if (locationQueryParams.error) {
        const errorEmail = locationQueryParams.error.split("user_not_found_")[1];
        console.log("errorEmail", errorEmail);
        console.log("loginActions", loginActions);
        dispatch(
          loginActions.createFail({
            // error: `User not found with email ${errorEmail}`,
            error: mrIntl("AuthLayout.user_not_found_email", {errorEmail: errorEmail }),
          })
        );
        history.replace({
          search: "",
        });
      }
      if (location && location.state) {
        fromLocation = location.state.from;
        console.log("fromLocation 103", fromLocation, location);
        let storeUrlFlag = false;
        if (fromLocation.pathname) {
          let finalRedirectUrlToStore = fromLocation.pathname;
          setToLS(
            redirectUrlKey,
            `${finalRedirectUrlToStore}${fromLocation.search}`
          );
        }
        fromQueryParams = queryString.parse(fromLocation.search);
        if (fromQueryParams.token) {
          // localStorage.setItem("token", fromQueryParams.token);
          setToLS("token", fromQueryParams.token);
          dispatch(loginActions.checkAuthState());
        }
      } else {
        // let locationQueryParams = queryString.parse(location.search);
        if (locationQueryParams.token) {
          // localStorage.setItem("token", fromQueryParams.token);
          setToLS("token", locationQueryParams.token);
          dispatch(loginActions.checkAuthState());
        }
      }
      console.log("location 60", location);
  
      if (location) {
        const partnerName = window.name == "21k-assessprep-embed" ? "21k" : (queryParams.partner || "mb");
        console.log(
          "location, isAuthenticated, queryParams",
          location,
          isAuthenticated,
          queryParams, 
        );
        if (queryParams.jwt) {
          dispatch(
            embeddedResourcesActions.setJwtSuccess({
              jwt: queryParams.jwt,
              client: partnerName,
            })
          );
          // if(!isAuthenticated){
          dispatch(
            loginActions.verifyJwt({ jwt: queryParams.jwt, client: partnerName })
          );
          // return <Spinner />;
          // }
        }
        if(queryParams.partner){
          dispatch(loginReduxActions.setPartnerNameSuccess({partnerName: queryParams.partner}))
        }
      }
  
      // let appType = "web";
      // // let appTypeFromLS = localStorage.getItem("appType")
      // // if((location && location.search && location.search.indexOf("appType=seb") > -1) || (appTypeFromLS && appTypeFromLS == "seb")){
      // if((location && location.search && location.search.indexOf("appType=seb") > -1)){
      //   appType = "seb"
      //   // dispatch(loginActions.initAppSuccess({appType}))
      //   // localStorage.setItem("appType", appType)
      //   // using LS here in case user refreshes inside SEB
      // }
      // // moved here also from checkAuthState
      // if(window.downloadHelper){
      //   appType = "win";
      // } else if(window.webkit){
      //   appType = "mac";
      // }
      // dispatch(loginActions.initAppSuccess({appType}))
    } catch (error) {
      console.log("Sentry capture");
      Sentry.captureException(error);
    }
  }, []);
  
  // const openSEB = (sebConfigFileUrl) => {
  //   // console.log("sebConfigFileUrl", sebConfigFileUrl)
  //   if(sebConfigFileUrl){
  //     window.location.href = sebConfigFileUrl
  //   }
  // }
  if (import.meta.env.VITE_MAINTENANCE_MODE == "true") {
    return <Redirect to="/maintenance-mode/maintenance-page" />;
  }
  const homePath = "/";
  let fromLocation, fromQueryParams;
  // console.log("tokenPresent isAuthenticated", tokenPresent, isAuthenticated)
  console.log("location 63", location);

  // moved here also from checkAuthState - this checks on first load login page so url checker here

  fromQueryParams = {};
  if (tokenPresent) {
    if (isAuthenticated) {
      try {
        let redirectPath = homePath;
        console.log("redirectPath location", location)
        if (location && location.state) {
          console.log("location 69", location);
          fromLocation = location.state.from;
          // redirectPath = fromLocation.pathname;
          redirectPath = fromLocation.pathname.concat(fromLocation.search);
          console.log("redirectPath", redirectPath)
        } else {
          let tempRedirectPath = getFromLS(redirectUrlKey);
          console.log("tempRedirectPath", tempRedirectPath);
          if (tempRedirectPath) {
            redirectPath = tempRedirectPath;
          }
        }
        deleteFromLS(redirectUrlKey);
        return <Redirect to={redirectPath} />;
    
      } catch (error) {
        console.log("Sentry capture");
        Sentry.captureException(error); 
      }
      
    } else {
      return <Spinner />;
    }
  }

  if (!isAuthenticated && queryParams.jwt) {
    return <Spinner />;
  }

  // let toRender;
  // console.log("1", props);
  // if(!props.children){
  //   console.log("2", props);
  //   toRender = <div>HI {props.main}</div>
  // }
  // return <div>
  //   Auth Layout
  //   <div>{props.children}</div>
  //   {/* {toRender} */}
  // </div>

  // log s

  const deviceInfo = getDeviceInfo(appType);
  const os = deviceInfo.os;
  const showGuestButton = appType != "ios" && location.pathname && !location.pathname.includes("guest") 
  // const safeExamBrowserObject = window.SafeExamBrowser || {};
  // const sebSecurity = safeExamBrowserObject.security || {}
  // const configKeyFromJSAPI = sebSecurity.configKey;

  // const {validKey, hashedKeys} = checkIfSEBConfigIsValid(configKeyFromJSAPI, ["572ae5203d144a36cc2a4db6521c71be0ebd2bc7fde77378078f6ada941d4e55"])
  // const hashedKeysMap = hashedKeys.map((h) => {
  //   return <p>{h.sha2}</p>
  // })

  return (
    <BaseLayout>
      <ContentTop>
        {/* <StyledLogo src={LogoImage} />
        <br></br> */}
        {/* <h1 className="sign-in-text">Sign in to AssessPrep</h1> */}
        {(appType == "mac" || appType == "ios") && <RegionSelector />}
        {/* {appType != "web" &&
          import.meta.env.VITE_MODE != "production" && <EnvSelection />} */}
      </ContentTop>
      <ContentMain className="auth-content-main">
        <StyledLogo src={LogoImage} />
        <br></br>
        {props.children}
      </ContentMain>
      <Row className="login-page-extra text-center m-t-15">
        <Col span={24}>
          <CheckAndSetAppType />
          <Space>
            {/* {appType === "web" && import.meta.env.VITE_MODE !== "production" && import.meta.env.VITE_MODE !== "demo" && (
              <Tooltip title={mrIntl("AuthLayout.sign_up")} placement="top">
                <Button shape="" type="default" href={"/auth/signup"}>
                  {mrIntl("AuthLayout.sign_up")}
                </Button>
              </Tooltip>
            )} */}
            {showGuestButton && (
              <Tooltip title={mrIntl("AuthLayout.take_test_as_guest")} placement="top">
                <Button shape="" type="default" href={"/guest/join"}>
                  {/* Take test as guest */}
                  {mrIntl("AuthLayout.take_test_as_guest")}
                </Button>
              </Tooltip>
            )}
          </Space>
          <QuitBtn onQuitTurnOffSecurityOnly={true} />
        </Col>

        <IcpText />

        <hr></hr>
        {import.meta.env.VITE_REGION === "global" && appType === "web" && (
          <Col span={24}>
            <br></br>
            <br></br>
            <Space>
              <Tooltip title={mrIntl("AuthLayout.go_to_assessprep_china")} placement="top">
                <Button type="text" href={"https://app.assessprep.cn"}>
                  {mrIntl("AuthLayout.go_to_ap_china")}
                </Button>
              </Tooltip>
              {/* <Tooltip title="Go to AssessPrep V1" placement="top">
                <Button type="text" href={"https://v1.assessprep.com"}>
                  Go to AP V1 (deprecated)
                </Button>
              </Tooltip> */}
            </Space>
          </Col>
        )}

        {import.meta.env.VITE_MODE !== "production" &&
          import.meta.env.VITE_MODE !== "demo" && (
            <Col span={24}>
              <br></br>
              <br></br>
              <Space direction="vertical">
                <Space>
                  <Tag>
                    FE branch from env var:{" "}
                    {import.meta.env.VITE_GIT_BRANCH_NAME}
                  </Tag>
                  <Tag>RELEASE_TIME: {import.meta.env.VITE_RELEASE_TIME}</Tag>
                  <Tag>RELEASE_ENV: {import.meta.env.VITE_RELEASE_ENV}</Tag>
                  <Tag>
                    RELEASE_VERSION: {import.meta.env.VITE_RELEASE_VERSION}
                  </Tag>
                </Space>
                {/* <Space>
              <Tag>FE branch: {gitInfo.branch}</Tag>
              <Tag>FE last commit: {gitInfo.commit.date}</Tag>
            </Space> */}
                <span>User agent: {navigator.userAgent}</span>
                <span>User navigator.onLine: {JSON.stringify(navigator.onLine)}</span>
                <span>Device info: {JSON.stringify(deviceInfo)} </span>
                <span>Mobile OS: {getMobileOperatingSystem()}</span>
                <span>Desktop OS: {getOS()}</span>
                <span>
                  App native vars: {localStorage.getItem("apNativeVars")}
                </span>
                <span>
                  App type and version: {appType} - {getAppVersion(appType)}
                </span>
                <span>
                  LS appType: {localStorage.getItem("appType")}
                </span>
                <span>
                  queryParams: {JSON.stringify(queryParams)}
                </span>
                <span>Media Devices, getUserMedia, webkitGetUserMedia, mozGetUserMedia: {JSON.stringify(navigator.mediaDevices ? true : false)} || {JSON.stringify(navigator.getUserMedia ? true : false)} || {JSON.stringify(navigator.webkitGetUserMedia ? true : false)} || {JSON.stringify(navigator.mozGetUserMedia ? true : false)}</span>
                {/* {appType === "seb" && <p>configKeyFromJSAPI: {configKeyFromJSAPI}</p>}
                {appType === "seb" && <p>href: {window.location.href}</p>}
                {appType === "seb" && <p>hashedKeys: {hashedKeysMap}</p>} */}
              </Space>
            </Col>
          )}
      </Row>
    </BaseLayout>
  );
};
export default AuthLayout;
