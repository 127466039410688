import { fromJS } from "immutable";
import { MrReduxCrud, formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { put, select } from "redux-saga/effects";
import { annotationsSelector, userAnnoationsSelector } from "./selector";
import _ from "lodash";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "annotation",
  actionNames: ["FETCH", "CREATE", "UPDATE", "DELETE"],
});

const initialState = fromJS({
  annotations: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
  fetchloading: false,
});

export function* fetchCustomSaga(action) {
  console.log("Overring fetch annotations", action.payload);

  try {
    let existingAnnotations = yield select(annotationsSelector());
    if (existingAnnotations.hasOwnProperty("size")) {
      existingAnnotations = existingAnnotations.toJS();
    }
    const userAnnotations = existingAnnotations.filter((annotation) => annotation.user_id == action.payload.params.by_user_id) || []
    console.log("userAnnotations ==>", userAnnotations)

    let url = "annotations.json";
    if (userAnnotations.length <= 0) {
      yield put(actions.fetchStart());
      const response = yield axiosInstance.instance.get(url, action.payload);

      console.log("response on fetch custom annotations saga ==>", response)
      let annotations = formatJsonApiData(response.data.annotations) || [];

      const newAnnotations = _.uniqBy(
        existingAnnotations.concat(annotations),
        "id"
      );

      yield put(
        actions.fetchSuccess({ data: { annotations: newAnnotations } })
      );
    }
    
  } catch (error) {
    console.log("Overring fetchCustomSaga annotations fetch error", error);
    yield put(actions.fetchFail({ error: error }));
  }
}

export function fetchCustomSuccess(state = initialState, action) {
  let currentAnnotations = state.get("annotations", [])
  if (currentAnnotations.hasOwnProperty("size")) {
    currentAnnotations = currentAnnotations.toJS();
  } 
  const newAnnotations = formatJsonApiData(action.payload.annotations);
  const annotations =  _.uniqBy(
    currentAnnotations.concat(newAnnotations),
    "id"
  );
  console.log("fetch custom success ==>", action, currentAnnotations, newAnnotations, annotations)

  return state.set("annotations", annotations).set("loading", false).set("fetchloading", false)
}

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState,  {
  [actionTypes.FETCH_ANNOTATION_SUCCESS]: fetchCustomSuccess,
});
export const watchAnnotations = reduxCrud.generateWatchSaga({
  [actionTypes.FETCH_ANNOTATION]: fetchCustomSaga,
});

export default reduxCrud;
