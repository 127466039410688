import React, { useEffect, useState } from "react";

import { Tabs, Input, Radio, Button, Checkbox, Upload, Row, Col } from "antd";
import { InboxOutlined, UploadOutlined, SoundOutlined, PauseCircleOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { initialData, addItemToArray, removeItemFromArray, convertDataAsPerNewSystem, getParsedJSONObject, numaricalSort } from "/src/views/Segments/InteractiveHelpers";
import { AddButton, DeleteButton, CheckBox, InputArea, MCQArea, MCQList, TextArea, ShowText, ShowTextIndex, ShowTextContent } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments"
import ListAttachments from "/src/components/UI/Attachments/ListAttachments";
import { useSelector } from "react-redux";
import { printModeSelector } from "/src/views/Experiences/selector";

const VideoView = props => {
	console.log("Video view props", props);
	const { createMode, resource_json, onTabEdit, onTextChanged, removeFile, mediaShowSetting, setMediaShowSetting, experienceSettings, caption, title } = props

	const segment_data = resource_json.segment_data
	const resource_setting = resource_json.resource_setting || {}
	const attachments = segment_data.attachments || []
  const printMode = useSelector(printModeSelector());
	return (
		<div>
      {printMode ? <ListAttachments
          key={`list-attachments-${props.unique_segment_identifier}`}
          titlePrefix={"Video"}
          caption={caption}
          title={title}
          attachments={attachments}
        ></ListAttachments> : (!createMode && resource_setting.view_type == 'playlist') ?
				''
				// <PlayListVideos
				// 	attachments={attachments}
				// 	resource_setting={resource_setting}
				// ></PlayListVideos>
				: <TabularAttachments
					attachmentType="video"
					tabPrefix="Video"
					attachments={attachments}
					resource_setting={resource_setting}
					mediaShowSetting={mediaShowSetting}
					setMediaShowSetting={setMediaShowSetting}
				></TabularAttachments>}
		</div>
	);
}
export default VideoView;