import React from 'react';
import { useSelector } from 'react-redux';
import { setUserInfoLoadingSelector } from '../../FirestoreInteractions/selector';
import { Button, Card, Space, Tag, Typography } from 'antd';
import BackToHomeBtn from '/src/components/UI/BackToHomeBtn';
import LogoutBtn from '/src/components/UI/LogoutBtn';
import ViewStatus from '../ViewComponents/ViewStatus';
import "../ViewComponents/ViewCard1.scss"
import { appTypeSelector } from '/src/App/OfflineApp/offlineAppSelectors';
import { SolarClipboardCheckBold, SolarStarLineDuotone } from '/src/components/UI/Icons/SolarIcons';
import { LoginOutlined } from "@ant-design/icons";
import ViewCard1 from '../ViewComponents/ViewCard1';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const SubmitView = (props) => {
  const {
    experienceProps,
    timer,
  } = props;

  const {
    experience,
    experienceSettings,
    embedded,
    setUserInfo,
  } = experienceProps;

  const mrIntl = useTranslate();
  const appType = useSelector(appTypeSelector());
  const experienceRubric = experience.rubric || {};
  const tscEnabled = experienceSettings.tsc_enabled
  const showTaskSpecificClarification = tscEnabled && experienceRubric.type_c === "myp_achievement_level";
  const isMBLinked = experience.is_mb_linked
  console.log('showTaskSpecificClarification, tscEnabled ===>>', experienceSettings, experience, showTaskSpecificClarification, tscEnabled, experience.settings);
  const isSetUserInfoLoading = useSelector(setUserInfoLoadingSelector);
  const showConfig = {
    showProgress: false,
    titleColorClassName: 'bg-blue'
  }

  return (
    <div className="end-view-container submit-view-container">
      <ViewStatus
        statusIcon={<SolarClipboardCheckBold className='submit-view-icon' />}
        statusText={mrIntl("SubmitView.submitted_successfully")}
        statusTextType={"success"}
      />
      <ViewCard1
        showConfig={showConfig}
        experience={experience}
        quitButtonAction="quit_app_after_submit"
        footerLeftComponent={
          !embedded &&
            !isMBLinked &&
            appType == "web"
            ? <BackToHomeBtn
              key="submit-view-back-to-home-button"
              experience={experience}
              action="back_to_home_after_submit"
              className='back-to-home-button'
            />
            : null
        }
        footerCenterComponent={timer}
        footerRightComponent={
          <>
            {appType !== "mac" && appType !== "seb" &&
              <LogoutBtn
                withLog={true}
                action={"log_out_after_submit"}
                experience={experience}
                logoutButtonIcon={<LoginOutlined />}
                className={"logout-button"}
              />
            }
            {experienceSettings.assessment_type == "quick_check" ?
              <Button
                loading={isSetUserInfoLoading}
                type="primary"
                onClick={() => {
                  setUserInfo({ currentView: "viewFeedback" });
                }}
              >
                {mrIntl("SubmitView.view_results")}
              </Button> : null
            }
          </>
        }
      />
    </div>
  );
};

export default SubmitView;