import React, { useEffect, useState } from "react";

import { Tooltip } from "antd";
import { ShowText, ShowTextContent, TextArea, InputArea, CustomErrorMessage } from '/src/components/UI/Segment/UIHelper'
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { AlignLeftOutlined } from "@ant-design/icons";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { CKETextOptionTools } from "/src/components/UI/CKEditor/CKEConfig";

const OptionFeedback = props => {
  console.log("Option feedback props", props);

  const { createMode, answerMode, presentationMode, quickCheckModeEnabled, option, onTextChanged, removeFeedbackButton } = props
  const mrIntl = useTranslate()
  return (
    (option.show_feedback || option.custom_feedback) ?
      <div key={`option-feedback-${option.id}`} className='option-feedback'>
        {createMode
          ?
          <>
            <Tooltip title={mrIntl("CommonText.specific_feedback_title")} placement="top">
              <AlignLeftOutlined className='option-feedback-icon' />
            </Tooltip>
            <InputArea
              className={`singleline-input-area ${!option.custom_feedback && 'custom-error'}`}
            >
              <BasicCKE
                data={option.custom_feedback}
                placeholder={`${mrIntl("CommonText.write_feedback_here_placeholder")}...`}
                onTextChanged={onTextChanged}
                tools={CKETextOptionTools}
              ></BasicCKE>
              <CustomErrorMessage className="error-message" message={mrIntl("CommonText.required_msg")}></CustomErrorMessage>
            </InputArea>
            {removeFeedbackButton}
          </>
          :
          (!answerMode || quickCheckModeEnabled) && option.checked ?
            <>
              <Tooltip title={mrIntl("CommonText.specific_feedback_title")} placement="right">
                {/* <AlignLeftOutlined className='option-feedback-icon' /> */}
                <ShowText
                  className="show-text"
                >
                  <ShowTextContent
                    dangerouslySetInnerHTML={{ __html: option.custom_feedback }}
                  >
                  </ShowTextContent>
                </ShowText>
              </Tooltip>
            </>
            : null
        }
      </div>
      : null
  );
};
export default OptionFeedback;