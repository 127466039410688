import React, { useState } from "react";
import { Button, Dropdown, Input } from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { availableLanguageOptionsForAI } from "/src/lib/utils/helperMethods";
import { CheckOutlined, SearchOutlined } from "@ant-design/icons";
import { CiGlobe } from "react-icons/ci";
import { FaGlobe } from "react-icons/fa";

const SelectAIOutputLanguage = (props) => {
  const { language, setLanguage, disabled, newGenerateQuestionsWithAI } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const mrIntl = useTranslate()
  const options = availableLanguageOptionsForAI.map((lang) => {
    const objToPush = {
      label: lang,
      key: lang,
      icon: language === lang ? <CheckOutlined /> : <span></span>,
    };
    return objToPush;
  });
  const filteredLanguages = options.filter(language => 
    language.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const items = [
    {
      key: "select-output-language",
      type: "group",
      children: filteredLanguages,
    },
  ];
  return (
    <Dropdown
      disabled={disabled}
      trigger={['click']}
      className="select-output-language"
      menu={{ items: items, onClick: (lang) => setLanguage(lang.key) }}
      arrow={{
        pointAtCenter:"center",
      }}
      placement="bottom"
      dropdownRender={(menu) => (
        <div className="select-ai-language-dropdown-wrapper">
          <div className="search-input-wrapper">
          <Input
          onChange={(e)=>{
            setSearchQuery(e.currentTarget.value)}
          }
          placeholder={mrIntl("SelectAIOutputLanguage.search")}
          prefix={<SearchOutlined />}
          ref={input => {setTimeout(() => input?.focus(), 0)}}
          >
          </Input>
          </div>
          {menu}
        </div>
      )}
    >
      <Button type="text" size={newGenerateQuestionsWithAI ? "small" : "medium"} icon={newGenerateQuestionsWithAI ? <CiGlobe /> : <FaGlobe /> } ghost={true}/>
    </Dropdown>
  );
};

export default SelectAIOutputLanguage