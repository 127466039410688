import { Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import "./ListActionsMenu.scss";
import { isObject } from "lodash";

const ListActionsMenu = (props) => {
  console.log("ListActionsMenu ====>", props);
  const { children, menuItems, triggerType, dropdownKey, className = "" } = props

  // use this state to set active list item
  const [isListItemActive, setIsListItemActive] = useState(null)

  // useEffect(() => {
  //   // Use scroll event for Close context menu dropdown when scroll 
  //   if (isListItemActive) {
  //     window.addEventListener('scroll', handlePageScroll);

  //     return () => {
  //       window.removeEventListener('scroll', handlePageScroll);
  //     };
  //   }
  // }, [isListItemActive]);

  // const handlePageScroll = () => {
  //   setIsListItemActive(false)
  // }

  const handleContextMenu = (e) => {
    console.log("handleContextMenu ====>", e);
    e.stopPropagation(); // Prevent the default context menu behavior when we right click on menuitem 
  };

  const handleMenuItemClick = (domEvent) => {
    domEvent.stopPropagation() // Prevent the default click behavior when we click on menuitem 
    setIsListItemActive(false)
  }

  const items = []
  menuItems.forEach((item) => {
    if (isObject(item) && item.item) {
      console.log('menuItems && menuItems ==> if', item)
      if(item.subMenu) {
        items.push({
          label: item.item,
          children: item.subMenu,
          popupClassName: "custom-actions-submenu-overlay"
        })
      } else {
        items.push({
          label: item.item,
        })
      }
    } else {
      console.log('menuItems && menuItems ==> else', item)
      items.push({
        label: item,
      })
      //  <Menu.Item className="custom-context-menu-item" onClick={({ domEvent }) => handleMenuItemClick(domEvent)} onContextMenu={handleContextMenu}>{item}</Menu.Item>
    }
  })

  const menu = <Menu className="custom-context-menu" onContextMenu={(event) => handleContextMenu(event)} items={items} />

  return menuItems.length > 0 ? <Dropdown overlay={menu}
    trigger={[triggerType]}
    key={dropdownKey}
    placement="bottom"
    arrow={{
      pointAtCenter: true,
    }}
    // open={isListItemActive}
    onOpenChange={(visible) => setIsListItemActive(visible)}
    className={`${isListItemActive && "context-menu-active-item"} ${className}`}>
    {children}
  </Dropdown> : children
}

export default ListActionsMenu;