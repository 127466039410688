import { fromJS } from "immutable";
import { MrReduxCrud, formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import {actions as segmentActions} from "/src/views/Segments/redux";
import {actions as experienceActions} from "/src/views/Experiences/redux";
import {actions as topicActions} from "/src/views/Segments/Topics/redux";

import { put, select } from "redux-saga/effects";
import {jwtSelector} from "./selector";


const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "embedded_resource",
  actionNames: ["FETCH", "CREATE", "UPDATE", "DELETE", "SHOW", "FIND_BY", "SET_JWT"],
});

const initialState = fromJS({
  embedded_resources: [],
  page: 1,
  jwt: null,
  activeEmbeddedResource: null,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

function setJwtSuccess(state = initialState, action) {
  const {payload} = action;
  const payloadPartner = payload.partner || "mb";
  return state.set("jwt", payload.jwt).set("partner", payloadPartner);
}
const finalJwtSelector = jwtSelector();
export function* findBySaga(action) {
  try {
    const payload = action.payload || {};
    const key = payload.key;
    const value = payload.value;
    const partner = payload.partner;
    
    const jwt = yield select(finalJwtSelector);
    let url = `embedded_resources_by_${key}/${value}.json`;
    console.log( "jwt 43", jwt );
    yield put(actions.findByStart());
    
    const response = yield axiosInstance.instance.get(url, {
      params: {
        jwt: jwt,
        partner: partner
      }
    });
    console.log( "response 51", response );
    if(response.data.error){
      yield put(actions.findByFail({error: response.data.error.message}));
      return
    }
    const embeddedResource = response.data.embedded_resource;
    console.log("embeddedResource", embeddedResource)
    const resourceType = embeddedResource.data.attributes.resource_type;
    console.log( "resourceType", resourceType );
    if(resourceType === "Segment"){
      let segmentResource = embeddedResource.data.attributes.resource
      let segment = formatJsonApiData(segmentResource);
      console.log( "embeddedResource show segment", segment );
      let descendants = segment.descendants
      if(descendants && descendants.length > 0){
        yield put(segmentActions.fetchSuccess({data: {segments: descendants}}))
      }
      yield put(segmentActions.showSuccess({data: {
        segment: segment
      }}));

    } else if(resourceType === "Experience"){
      const experience = response.data.experience;
      
      let experienceAttributes = experience.data.attributes

      // IMP - see APL-2880
      // const activeAdjustedExperienceId = !experienceAttributes.master_editable ? experienceAttributes.original_id : experienceAttributes.id;
      const activeAdjustedExperienceId = experienceAttributes.id
      // IMP

      console.log( "experience 70", activeAdjustedExperienceId, experience );
      yield put(experienceActions.setActiveSuccess({id: activeAdjustedExperienceId}));

      yield put(experienceActions.showSuccess({data: {
        experience: experience
      }}));
      if(experience.data.attributes.status !== "draft"){
        console.log( "published exp");
        let segments = []
        const topics = response.data.topics.map((topic, idx) => {
          // segments.concat(topic.children);
          segments = segments.concat(topic.children);
          return topic.segment;
        });
        yield put(topicActions.fetchSuccess({data: {segments: topics}}))
        yield put(segmentActions.fetchSuccess({data: {segments: segments}}))
      }

    }
    yield put(actions.findBySuccess({data: response.data}));
  } catch (error) {
    console.log( "embedded_resources error", error );
    yield put(actions.findByFail({error: error.message}));
  }
}


function findBySuccessFunc(state = initialState, action) {
  const data = formatJsonApiData(action.payload.data.embedded_resource);

  return state.set("activeEmbeddedResource", data).set("loading", false).set("findloading", false);
}
function findByFailFunc(state = initialState, action) {
  const error = action.payload.error;
  return state.set("error", error).set("loading", false).set("findloading", false);
}


export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  [actionTypes.FIND_BY_EMBEDDED_RESOURCE_SUCCESS]: findBySuccessFunc,
  [actionTypes.FIND_BY_EMBEDDED_RESOURCE_FAIL]: findByFailFunc,
  [actionTypes.SET_JWT_EMBEDDED_RESOURCE_SUCCESS]: setJwtSuccess
});
export const watchEmbeddedResources = reduxCrud.generateWatchSaga({
  [actionTypes.FIND_BY_EMBEDDED_RESOURCE]: findBySaga,
});

export default reduxCrud;
