import React from "react";
import { Button, Checkbox, Space } from "antd";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import { MrSelect } from "mr_react_framework";
import {actions as genericActions} from "/src/App/genericRedux";
import {Route, useRouteMatch} from "react-router-dom";
import { set } from "lodash";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import {SelectSubject} from "/src/components/AppSpecific/SelectSubject/SelectSubject"
import {SelectProgram} from "/src/components/AppSpecific/SelectSubject/SelectSubject"
import {SelectGrade} from "/src/components/AppSpecific/SelectSubject/SelectSubject"
import { SyncBtn } from "/src/views/Orgs/OrgsList";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import SelectOrgDropdown from "/src/components/AppSpecific/SelectOrg/SelectOrgDropdown";


// const SelectProgram = MrSelect({
//   actions: genericActions,
//   resourceName: "orgprogrammes",
//   apiResourceName: "org_programmes",
//   config: {
//     url: "/org_programmes",
//     params: {}, //default is org_id on BE
//     processData: (data, props) => {
//       console.log("data select groups", data);
//       console.log("props select groups", props);
//       return data.map((item) => {
//         console.log("class selector item", item);
//         return {label: item.name, value: item.id};
//       });
//     },
//     searchApi: false,
//     widgetConfig: {
//       showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
//       // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
//       filterOption: true,
//       optionFilterProp: "label",
//       placeholder: "Select program",
//       // mode: "multiple",
//       // style: {width: "500px"},
//       // onDeselect: (val) => { console.log("val", val)}
//     },
//   }
// });
// const SelectGrade = MrSelect({
//   actions: genericActions,
//   resourceName: "grades",
//   config: {
//     url: "/grades",
//     // params: {by_org_programme_id: 3}, //get from previous filter
//     processData: (data, props) => {
//       console.log("data select groups", data);
//       console.log("props select groups", props);
//       return data.map((item) => {
//         console.log("class selector item", item);
//         return {label: item.name, value: item.id};
//       });
//     },
//     searchApi: false,
//     widgetConfig: {
//       showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
//       // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
//       filterOption: true,
//       optionFilterProp: "label",
//       placeholder: "Select grade",
//       // mode: "multiple",
//       // style: {width: "500px"},
//       // onDeselect: (val) => { console.log("val", val)}
//     },

//   }
// })

// const SelectSubject = MrSelect({
//   actions: genericActions,
//   resourceName: "subjects",
//   config: {
//     url: "/subjects",
//     // params: {by_org_programme_id: 3}, //get from previous filter
//     processData: (data, props) => {
//       console.log("data select groups", data);
//       console.log("props select groups", props);
//       return data.map((item) => {
//         console.log("class selector item", item);
//         return {label: item.name, value: item.id};
//       });
//     },
//     searchApi: false,
//     widgetConfig: {
//       showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
//       // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
//       filterOption: true,
//       optionFilterProp: "label",
//       placeholder: "Select subject",
//       // mode: "multiple",
//       // style: {width: "500px"},
//       // onDeselect: (val) => { console.log("val", val)}
//     },

//   }
// })

const GroupsFilter = (props) => {
  const {path, url} = useRouteMatch();
  const mrIntl = useTranslate()
  console.log("props group filter", props)
  const orgId = props.currentUser.org_id

  let extraFilters = [];
  let by_org_id = null
  let by_org_programme_id = null
  let by_subject_id = null
  let by_grade_id = null
  let by_grade_ids = []
  let include_archived = null
  let only_archived = null

  let filtersConfig = {
    showFiltersHeaderTitle: true, 
    showResetFilter: true
  }

  if(filtersConfig.showFiltersHeaderTitle){
    extraFilters.push(<Space style={{justifyContent: "space-between", width: "100%", marginBottom: "15px"}}>
      {/* <div>Filters</div>  */}
      {/* <Button icon={<FilterOutlined />}></Button> */}
      {filtersConfig.showResetFilter && <div className="text-right"><ResetFilter label={mrIntl("CommonText.reset")} setParams={props.setParams} useDefaultValues={false} /></div>}
    </Space>)
  }

  if(props.currentUser.role == "superadmin"){
    if(props.by_org_id){
      by_org_id = parseInt(props.by_org_id)
    }
    extraFilters.push(
      <SelectOrgDropdown value={by_org_id} onChange={(value) => props.setParams({by_org_id: value})} 
      config={{
        widgetConfig: { 
          style: {width: "100%"}
        }
      }}
      />
    );
  }
  
  let org_id = orgId
  if(props.currentUser.role === "superadmin"){
    org_id = props.by_org_id
  }

  if(org_id){
    if(props.by_org_programme_id){
      by_org_programme_id = parseInt(props.by_org_programme_id)
    }
    extraFilters.push(
      <SelectProgram key="selectProgramGroupsFilter" value={by_org_programme_id} onChange={(value) => {props.setParams({by_org_programme_id: value, by_grade_ids: [], by_subject_id: null})}} config={{
        params: {by_org_id: org_id},
        widgetConfig:{ style: {width:"100%"}},
      }}/>
    )
  }

  if(props.by_org_programme_id){
    // if(props.by_grade_id){
    //   by_grade_id = parseInt(props.by_grade_id)
    // }
    if(props.by_grade_ids){
      by_grade_ids = props.by_grade_ids.map((v) => parseInt(v))
    }
    extraFilters.push(
      <SelectGrade key="selectGradeGroupsFilter" value={by_grade_ids} onChange={(value) => props.setParams({by_grade_ids: value})} config={{forceReload: true, params: {by_org_programme_id: props.by_org_programme_id}, widgetConfig:{ style: {width:"100%"}, mode: "multiple",}}}/>
      // <SelectGrade key="selectGradeGroupsFilter" value={by_grade_id} onChange={(value) => props.setParams({by_grade_id: value})} config={{forceReload: true, params: {by_org_programme_id: props.by_org_programme_id}, widgetConfig:{ style: {width:"100%"}}}}/>
    )
  }
  if(props.by_org_programme_id){
    if(props.by_subject_id){
      by_subject_id = parseInt(props.by_subject_id)
    }
    extraFilters.push(
      <SelectSubject key="selectSubjectGroupsFilter" value={by_subject_id} onChange={(value) => props.setParams({by_subject_id: value})} config={{forceReload: true, params: {by_org_programme_id: props.by_org_programme_id}, widgetConfig:{ style: {width:"100%"}}}}/>
    )
  }

  // if(props.include_archived){
  //   include_archived = props.include_archived
  // }
  // extraFilters.push(
  //   <Checkbox key="checkboxArchivedExperiencesFilter" checked={include_archived} onChange={(e) => {
  //     console.log("archived filter value", e); 
  //     props.setParams({include_archived: (e.target.checked ? true : null)})}}><MrTranslate id={"GroupFilter.show_archived"}/></Checkbox>
  // )

  if(props.only_archived){
    only_archived = props.only_archived
  }
  extraFilters.push(
    <Checkbox key="checkboxArchivedExperiencesFilter" checked={only_archived} onChange={(e) => {
      console.log("archived filter value", e); 
      props.setParams({only_archived: (e.target.checked ? true : null)})}}><MrTranslate id={"GroupFilter.show_archived"}/></Checkbox>
  )

  // extraFilters.push(
  //     <ResetFilter key={"resetFiltersBtn"} setParams={props.setParams} />
  // )
  // console.log( "props groupsfilter", props );
  const {org, currentUser} = props;
  // const currentUser = props.currentUser;
  if(org && org.is_mb && org.custom_fields && org.custom_fields.sync_info && org.custom_fields.sync_info.latest_started_at && currentUser && currentUser.role === "teacher"){
    extraFilters.push(
      <SyncBtn key={"mainMBSyncBtn"} size={"compact"} org={org}/>
    )
  }


  return extraFilters;
  // return <span>Group Filter</span>;
};

GroupsFilter.defaultProps = {};

GroupsFilter.propTypes = {};

export default GroupsFilter;
