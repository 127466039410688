import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, Space, Tooltip, Button, Avatar, Popconfirm, Tag, Statistic, Row, Col, Dropdown, Menu, Collapse, Divider } from "antd";
import "/src/App/App.scss";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import {actions as orgActions} from "/src/views/Orgs/redux";
import loadable from "@loadable/component";
import { superadminDashboardStatsSelector } from "/src/views/Orgs/selector";
const RCJChart = loadable(() => import(/* webpackChunkName: "react-chart" */ '/src/views/Segments/RCJChart/RCJChart'))


// LEAVING HERE AS SAMPLE
const OrgStatusGrowthChartSAMPLE = (dataset) => {
  // using RCJChart as base component
  let chart_settings = {
    type: "line", // can be bar or pie also - add support to RCJChart
    // type: "bar", // can be bar or pie also - add column graph support to RCJChart
    title: "Customer/Trial/Leads growth weekly",
    xAxesLabel: "Week",
    yAxesLabel: "Count",
    labels: ["W1", "W2", "W3", "W4"], // Column headers - week number or month number
    // labels: ["Jan", "Feb", "Mar", "Apr"], // Column headers - week number or month number
    datasets: [ // one object for each data row/line graph - one object for single line, multiple for multiple lines
      {
        data: [10,20,30,40], // customers
        label: "Customers"
      },
      {
        data: [100,200,300,400], // trials
        label: "Trials"
      }
    ],
    
  }

  return <RCJChart chart_settings={chart_settings} />
}

const OrgStatusGrowthChart = (props) => {
  const { dataset } = props
  console.log("OrgStatusGrowthChart", dataset)
  let finalDataSet1 = [ 
    {
      data: [10,20,30,40],
      label: "Customers"
    },
    {
      data: [100,200,300,400],
      label: "Trials"
    }
  ]
  
  let finalDataSet = dataset.datasets
  console.log("OrgStatusGrowthChart", finalDataSet1, finalDataSet)

  // using RCJChart as base component
  let chart_settings = {
    // type: "line",
    type: "bar",
    title: "Customer/Trial/Leads growth",
    xAxesLabel: "Month",
    yAxesLabel: "Count",
    // labels: ["W1", "W2", "W3", "W4"], 
    labels: dataset.labels, 
    datasets: finalDataSet,
    showGridLines: false,
    
  }

  return <RCJChart chart_settings={chart_settings} />
}

const SubmissionsGrowthChart = (props) => {
  const { dataset } = props
  let chart_settings = {
    type: "bar",
    title: "Submissions monthly",
    xAxesLabel: "Month",
    yAxesLabel: "Count",
    labels: dataset.labels, 
    datasets: dataset.datasets,
    showGridLines: false,
    stacked: true,
    
  }

  return <RCJChart chart_settings={chart_settings} />
}

const ExperiencesScheduledChart = (props) => {
  const { dataset } = props
  let chart_settings = {
    type: "bar",
    title: "Assessments scheduled monthly",
    xAxesLabel: "Month",
    yAxesLabel: "Count",
    labels: dataset.labels, 
    datasets: dataset.datasets,
    showGridLines: false,
    stacked: true,
    
  }

  return <RCJChart chart_settings={chart_settings} />
}

const OrgSubscriptionStatusChart = (props) => {
  const { stats } = props
  console.log("OrgSubscriptionStatusChart stats", stats)
  console.log("OrgSubscriptionStatusChart", Object.keys(stats.org_count_status_wise))
  console.log("OrgSubscriptionStatusChart", Object.values(stats.org_count_status_wise))

  let datasets = [
    {
      label: "Total",
      data: Object.values(stats.org_count_status_wise)
    },
  ]

  let chart_settings = {
    type: "bar",
    title: "Org subscriptions status counts",
    xAxesLabel: "Subs status",
    yAxesLabel: "Count",
    labels: Object.keys(stats.org_count_status_wise), 
    datasets: datasets,
    showGridLines: false,
    // stacked: true,
    
  }

  return <RCJChart chart_settings={chart_settings} />
}

const OrgSubscriptionStatusPlansChart = (props) => {
  const { stats } = props
  console.log("OrgSubscriptionStatusPlansChart stats", stats)

  let datasets = [
    {
      label: "AP Standard",
      data: Object.values(stats.org_count_APSTD_status_wise)
    },
    {
      label: "AP Plus",
      data: Object.values(stats.org_count_APPLUS_status_wise)
    },
    {
      label: "AP Max",
      data: Object.values(stats.org_count_APMAX_status_wise)
    },
    {
      label: "AP DP Bundle",
      data: Object.values(stats.org_count_DP_BUNDLE_status_wise)
    },
    {
      label: "AP DP Bundle Max",
      data: Object.values(stats.org_count_DP_BUNDLE_MAX_status_wise)
    },
  ]

  let chart_settings = {
    type: "bar",
    title: "Org subscriptions status plan wise counts",
    xAxesLabel: "Subs status plan wise",
    yAxesLabel: "Count",
    labels: Object.keys(stats.org_count_status_wise), 
    datasets: datasets,
    showGridLines: false,
    stacked: true,
    
  }

  return <RCJChart chart_settings={chart_settings} />
}

const OrgSubscriptionAmountPlansChart = (props) => {
  const { stats } = props
  console.log("OrgSubscriptionAmountPlansChart stats", stats)

  let datasets = [
    {
      label: "AP Standard",
      data: Object.values(stats.amount_APSTD_status_wise)
    },
    {
      label: "AP Plus",
      data: Object.values(stats.amount_APPLUS_status_wise)
    },
    {
      label: "AP Max",
      data: Object.values(stats.amount_APMAX_status_wise)
    },
    {
      label: "AP DP Bundle",
      data: Object.values(stats.amount_DP_BUNDLE_status_wise)
    },
    {
      label: "AP DP Bundle Max",
      data: Object.values(stats.amount_DP_BUNDLE_MAX_status_wise)
    },
  ]

  let chart_settings = {
    type: "bar",
    title: "Org subscriptions status plan wise amounts",
    xAxesLabel: "Subs status plan wise",
    yAxesLabel: "Count",
    labels: Object.keys(stats.org_count_status_wise), 
    datasets: datasets,
    showGridLines: false,
    stacked: true,
    
  }

  return <RCJChart chart_settings={chart_settings} />
}

const OrgCountryWiseCountChart = (props) => {
  const { stats } = props
  console.log("OrgCountryWiseCountChart stats", stats)

  let datasets = [
    {
      label: "Customers",
      data: Object.values(stats.org_customer_count_country_wise)
    },
    {
      label: "Trials",
      data: Object.values(stats.org_trial_count_country_wise)
    },
    {
      label: "Leads",
      data: Object.values(stats.org_leads_count_country_wise)
    },
  ]

  let chart_settings = {
    type: "bar",
    title: "Org counts country wise",
    xAxesLabel: "Countries",
    yAxesLabel: "Count",
    labels: Object.keys(stats.org_customer_count_country_wise), 
    datasets: datasets,
    showGridLines: false,
    stacked: true,
    
  }

  return <RCJChart chart_settings={chart_settings} />
}

const SuperadminDashboard = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector());
  const superadminDashboardStats = useSelector(superadminDashboardStatsSelector());
  console.log("SuperadminDashboard stats", superadminDashboardStats)
  const stats = superadminDashboardStats
  // const stats = currentUser.superadmin_dashboard_stats
  // console.log("SuperadminDashboard stats", stats)

  const { Panel } = Collapse;
  let finalRender = []

  useEffect(() => {
    dispatch(orgActions.superadminDashboardStats({}))
  }, []);
  

  if(stats){

    // MainKPIS
    let mainKPIS = <>
      <Row gutter={16} style={{"width":"100%", "textAlign":"center"}}>
        <Col span={8}>
          <Statistic title="Customers" value={stats.org_count_status_wise['Customer']} />
        </Col>
        <Col span={8}>
          <Statistic title="ARR" value={stats.amount_status_wise['Customer']} />
        </Col>
        
        
      </Row>
      <Row gutter={16} style={{"width":"100%", "textAlign":"center", "marginTop":"20px", "marginBottom":"20px"}}>
        <Col span={8}>
          <Statistic title="Assessments delivered" value={stats.total_experiences} />
        </Col>
        <Col span={8}>
          <Statistic title="Items created" value={stats.total_segments} />
        </Col>
        <Col span={8}>
          <Statistic title="Submissions" value={stats.total_submissions} />
        </Col>
      </Row>
      <Row gutter={16} style={{"width":"100%", "textAlign":"center", "marginTop":"20px", "marginBottom":"20px"}}>
        <Col span={8}>
          <Statistic title="MB linked experiences" value={stats.total_experiences_mb_linked} />
        </Col>
        <Col span={8}>
          <Statistic title="MB linked (Non Pamoja)" value={stats.total_experiences_mb_linked_non_pamoja} />
        </Col>
        <Col span={8}>
          <Statistic title="Submissions sent to MB" value={stats.total_submissions_posted_to_mb} />
        </Col>
        {/* <Col span={6}>
          <Statistic title="Active users" value={stats.active_users_count} />
        </Col> */}
      </Row>
      <Divider />
    </>
    

    // Total current counts. TODO: adjust this as per updated plans
    let CollapseStats = 
      <Collapse style={{"marginTop":"20px"}}>
        <Panel header="More stats" key="overall-stats">
          <Row style={{"width":"100%"}} className="text-center"> 
            <Col span={24}>
              <Space>
                <Statistic title={"Total org count"} value={stats.total_org_count} />
                <Statistic title={"Total MB"} value={stats.total_org_count_mb} />
                <Statistic title={"Total Non-MB"} value={stats.total_org_count_non_mb} />
              </Space>
            </Col>
            <Col span={24}>
              <h4 className="m-t-15">Org count status wise</h4>
              <Space>
                {Object.entries(stats.org_count_status_wise).map((val, i) => {
                  return <Statistic title={val[0]} value={val[1]} />
                })}
              </Space>
            </Col>
            <Col span={24}>
              <h4 className="m-t-15">Amount status wise</h4>
              <Space>
                {Object.entries(stats.amount_status_wise).map((val, i) => {
                  return <Statistic title={val[0]} value={val[1]} />
                })}
              </Space>
            </Col>
            <Col span={24}>
              <h4 className="m-t-15">Amount name wise</h4>
              <Space>
                {Object.entries(stats.amount_name_wise).map((val, i) => {
                  return <Statistic title={val[0]} value={val[1]} />
                })}
              </Space>
            </Col>
            <Col span={24}>
              <h4 className="m-t-15">Org count APSTD status wise</h4>
              <Space>
                {Object.entries(stats.org_count_APSTD_status_wise).map((val, i) => {
                  return <Statistic title={val[0]} value={val[1]} />
                })}
              </Space>
            </Col>
            <Col span={24}>
              <h4 className="m-t-15">Org count APPLUS status wise</h4>
              <Space>
                {Object.entries(stats.org_count_APPLUS_status_wise).map((val, i) => {
                  return <Statistic title={val[0]} value={val[1]} />
                })}
              </Space>
            </Col>
            <Col span={24}>
              <h4 className="m-t-15">Org count APMAX status wise</h4>
              <Space>
                {Object.entries(stats.org_count_APMAX_status_wise).map((val, i) => {
                  return <Statistic title={val[0]} value={val[1]} />
                })}
              </Space>
            </Col>
            <Col span={24}>
              <h4 className="m-t-15">Org count DP BUNDLE status wise</h4>
              <Space>
                {Object.entries(stats.org_count_DP_BUNDLE_status_wise).map((val, i) => {
                  return <Statistic title={val[0]} value={val[1]} />
                })}
              </Space>
            </Col>
            <Col span={24}>
              <h4 className="m-t-15">Org count DP BUNDLE MAX status wise</h4>
              <Space>
                {Object.entries(stats.org_count_DP_BUNDLE_MAX_status_wise).map((val, i) => {
                  return <Statistic title={val[0]} value={val[1]} />
                })}
              </Space>
            </Col>
            <hr></hr>
            <Col span={24}>
              <h4 className="m-t-15">Org count MYP status wise</h4>
              <Space>
                {Object.entries(stats.org_count_MYP_status_wise).map((val, i) => {
                  return <Statistic title={val[0]} value={val[1]} />
                })}
              </Space>
            </Col>
            <Col span={24}>
              <h4 className="m-t-15">Org count PYP status wise</h4>
              <Space>
                {Object.entries(stats.org_count_PYP_status_wise).map((val, i) => {
                  return <Statistic title={val[0]} value={val[1]} />
                })}
              </Space>
            </Col>
            <Col span={24}>
              <h4 className="m-t-15">Org count DP ARCHIVED status wise</h4>
              <Space>
                {Object.entries(stats.org_count_DP_ARCHIVED_status_wise).map((val, i) => {
                  return <Statistic title={val[0]} value={val[1]} />
                })}
              </Space>
            </Col>
          </Row>
          
        </Panel>
      
      </Collapse>

    
    // Weekly/monthly growth charts
    let charts = []
    // add trial and other status to this chart only
    let orgStatusGrowthChartRender = <Col span={24}>
      <OrgStatusGrowthChart dataset={stats.org_growth_data} />
      <Divider />
    </Col>

    let submissionsGrowthChartRender = <Col span={24}>
      <SubmissionsGrowthChart dataset={stats.eu_growth_data} />
      <Divider />
    </Col>

    let experiencesScheduledChartRender = <Col span={24}>
      <ExperiencesScheduledChart dataset={stats.exp_scheduled_data} />
      <Divider />
    </Col>

    let orgsSubscriptionStatusChartRender = <Col span={24}>
      <OrgSubscriptionStatusChart stats={stats} />
      <Divider />
    </Col>
    
    let orgsSubscriptionStatusPlansChartRender = <Col span={24}>
      <OrgSubscriptionStatusPlansChart stats={stats} />
      <Divider />
    </Col>

    let orgSubscriptionAmountPlansChartRender = <Col span={24}>
      <OrgSubscriptionAmountPlansChart stats={stats} />
      <Divider />
    </Col>
    
    let orgCountryWiseCountChartRender = <Col span={24}>
      <OrgCountryWiseCountChart stats={stats} />
      <Divider />
    </Col>

    // TODO: charts to add - programmes enabled, mb and non-mb overall orgs, filters for time - W/M/last 3 months, etc
    // TODO: Add list of power users - orgs and teachers

    charts.push(orgStatusGrowthChartRender)
    charts.push(orgsSubscriptionStatusChartRender)
    charts.push(orgsSubscriptionStatusPlansChartRender)
    charts.push(orgSubscriptionAmountPlansChartRender)
    charts.push(orgCountryWiseCountChartRender)
    charts.push(submissionsGrowthChartRender)
    charts.push(experiencesScheduledChartRender)

    finalRender.push(<Row>{mainKPIS}</Row>)
    finalRender.push(<Row>{charts}</Row>)
    finalRender.push(<Row>{CollapseStats}</Row>)

  }
  else {
    finalRender = "Loading..."
  }

  return finalRender

}

export default SuperadminDashboard;