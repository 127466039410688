import React, { useEffect, useState } from "react";
import { checkInternetConnectivity } from '/src/lib/CheckInternetConnectivity/CheckInternetConnectivity';
import { currentTimeValidWrtServerTime, getServerTimeOffset, writeToClipboardPolyfill } from '/src/lib/utils/helperMethods';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux"
import { appTypeSelector } from '/src/App/OfflineApp/offlineAppSelectors';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { message } from '../AntdAppHelper';
import MrTranslate, { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import IosStartBtn from '../IosStartBtn';
import { Button, Space, Tag, Tooltip } from 'antd';
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector } from "/src/views/Auth/Login/selector";
import {
  CalendarOutlined
} from "@ant-design/icons";
import SEBStartAlternativeModal from "/src/views/Experiences/SEBStartAlternativeModal";
import StartInSebBtn from "../SEB/StartInSebBtn";
import dayjs from "dayjs";
import Countdown from "react-countdown";
import ButtonCountDownWrapper from "/src/views/Experiences/ExperienceShow/Components/ButtonCountDownWrapper";
import { endSessionBtnLoadingSelector, startTestBtnLoadingSelector } from "/src/views/Experiences/selector";
import { getAppVersionStatus, getDeviceInfo, supportsMacAACSecurity, checkIfUnsupportedApp } from "/src/App/OfflineApp/offlineAppHelper";

const StartTestButtonOld = (props) => {
  const { item, serverTimeOffset, history, showExperience  = null, buttonText, startButtonWithoutCountDown = false, startButtonEnabled = true } = props;
  console.log("StartTestButtonOld item =====>", item);
  let finalRender = null
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const {path, url} = useRouteMatch();
  const appType = useSelector(appTypeSelector());
  const deviceInfo = getDeviceInfo(appType)
  const appVersionStatus = getAppVersionStatus(appType, deviceInfo.appVersion);
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const currentUser = useSelector(currentUserSelector())
  const embedded = useSelector(getEmbeddedSelector())
  const appRegion = useSelector(appRegionSelector())
  const startTestBtnLoading = useSelector(startTestBtnLoadingSelector()); // NOTE: Do not use it for showing loading, else it will reflect all start buttons on page
  const endSessionBtnLoading = useSelector(endSessionBtnLoadingSelector()); 
  
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (startTestBtnLoading && startTestBtnLoading[item.uid]) {
      setLoading(true)
    }
  }, [startTestBtnLoading]);
  
  const isStudent = 
    currentUser.role === "student" || currentUser.role === "parent" 
      ? true 
      : false
  
  const shouldStartBtnDisabled =
    !startButtonEnabled ||
    item.status === "closed" ||
    (startTestBtnLoading && !startTestBtnLoading[item.uid]) ||
    endSessionBtnLoading

  let startTooltipTitle = 
    item.status === "closed" 
      ? mrIntl("ExperienceList.test_closed") 
      : appVersionStatus && appVersionStatus.status === "unsupported" 
        ? mrIntl("ExperienceList.unsupported_version_of_the_app_please_update_your_app_to_start_this_test") 
        : mrIntl("ExperienceList.start")
    
        // {/* IMP: pointerEvents is to prevent the link from opening - disabling the button below is not enough BUT because of this- no tooltips showing  */};
  let startBtn = 
        <Tooltip title={startTooltipTitle}>
          {/* <Button disabled={item.status == "closed" ? true : false} type="primary" shape="round">{conditionToShowJoinVsStart ? "Join" : "Start"}</Button> */}
          <Button 
            loading={loading}
            key={`start-button-${item.uid}`}
            style={{ pointerEvents: shouldStartBtnDisabled ? "none" : 'auto' }}
            disabled={shouldStartBtnDisabled}
            onClick={(e) => {
              if (!startTestBtnLoading) {
                setLoading(true)
                dispatch(experienceReduxActions.setStartTestBtnLoadingSuccess({startTestBtnLoading: {[item.uid]: true}}))
                !embedded && currentUser.role === 'student' && writeToClipboardPolyfill("");
                checkInternetConnectivity(
                  appRegion, 
                  (internetStatus) => {
                    if (internetStatus === "online") { 
                      getServerTimeOffset((offset) => {
                        console.log("StartTestButtonOld urlToOpen ===>", internetStatus);
                        console.log("getServerTimeOffset offset", offset, currentTimeValidWrtServerTime(offset));
                        dispatch(experienceReduxActions.setServerTimeOffsetSuccess({ serverTimeOffset: offset }))
                        console.log("currentTimeValidWrtServerTime =====>", currentTimeValidWrtServerTime(offset), offset);
                        if (currentTimeValidWrtServerTime(offset)) {
                          showExperience(item, props.resourceName, 'default', e)
                          let urlToOpen = `/e/tests/${item.uid}${appType === "seb" || (appType === "mac" && item.settings.mode === "offline") || (appType === "ios" && item.settings.mode === "offline") || (appType === "cros" && item.settings.mode === "offline") ? '?firstLoad=true' : ''}`; // we want the firstload param on start click from list - but maybe should handle this also in showExperience
                          history.push(urlToOpen);
                        } else {
                          message.error(mrIntl("CommonText.your_system_time_is_out_of_sync_not_be_able_to_start_tests"))
                        }
                        setLoading(false)
                        dispatch(experienceReduxActions.setStartTestBtnLoadingSuccess({startTestBtnLoading: null}))
                      })
                    } else {
                      setLoading(false)
                      dispatch(experienceReduxActions.setStartTestBtnLoadingSuccess({startTestBtnLoading: null}))
                      // message.error("Your internet connection not stable. Please check your internet connection and try again.")
                    }
                  }, 
                  mrIntl)
              }
            }}  
            type="primary" 
            shape="round">
              {buttonText}
          </Button>
        </Tooltip>
  console.log("shouldStartBtnDisabled || !isJoinCodeCorrect item =====>", shouldStartBtnDisabled);

  if ((appType === "ios" || (appType === "mac" && supportsMacAACSecurity(appType))) && item.settings.mode === "offline") {
    startBtn = <IosStartBtn disabled={shouldStartBtnDisabled} item={item} resourceName={props.resourceName} showExperience={showExperience} />
  }

  let startInSEBBtn = 
    <Space>
      <StartInSebBtn disabled={shouldStartBtnDisabled} item={item} />
      {item && item.student_experience_membership && item.student_experience_membership.settings.allow_online && <SEBStartAlternativeModal startBtn={startBtn} />}
    </Space>

  if (item.settings.mode === "offline" && appType === "web") {
    startBtn = startInSEBBtn
  }

  console.log("StartTestButtonOld item.experience_user ===>", item.settings.start_condition === "scheduled_time");
  // if(((isStudent && !item.experience_user && currentTimeValidWrtServerTime(serverTimeOffset)))){
  if (isStudent && !item.experience_user) {
    if (item.settings.start_condition === "scheduled_time" && !startButtonWithoutCountDown) {
      if (currentTimeValidWrtServerTime(serverTimeOffset)) {
        let showBeforeCountDownStarts = 
          <Tag 
            color="success" 
            icon={<CalendarOutlined />}
          >
            <MrTranslate id={"ExperienceList.starts_on"} /> {dayjs(item.settings.start_at_datetime).format("MMM DD, hh:mm a")}
          </Tag>
        let startCountDown = 
          <ButtonCountDownWrapper 
            key={`start-count-down-wrapper-${item.id}`} 
            targetTime={item.settings.start_at_datetime}
            showBeforeCountDownStarts={showBeforeCountDownStarts} 
            showAfterCountDownComplete={startBtn}
            triggerUpdateFinalRender={shouldStartBtnDisabled}
          ></ButtonCountDownWrapper>

        finalRender = startCountDown // add canStart based on start time and close time
      }
    } else {
      finalRender = startBtn
    }
  }
  console.log("StartTestButtonOld finalRender =====>", finalRender);
  return finalRender
};

export default StartTestButtonOld;