import React from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber } from "antd";
import { FormRenderer } from "mr_react_framework";
import { removeHTMLTagsFromText } from "../../../lib/utils/helperMethods";
import SectionTitle from "./SectionTitle";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";


const sectionForm = (mrIntl) => {
  return {
  type: "object",
  properties: {
    title: {
      type: "string",
      placeholder: mrIntl("CommonText.title"),
      widget: SectionTitle,
      wrapClassName: 'topic-form-item',
      setInitialValue: true,
      formItemConfig: {validateTrigger: 'onSubmit'},
      rules: [
        // {
        //   required: true,
        //   message: 'Required',
        // },
        // {
        //   max: 50,
        //   message: 'Max 50 characters',
        // },
        {validator(rule, value) {
          const charactersCount =  removeHTMLTagsFromText(value).length;
          if (charactersCount === 0) {
            return Promise.reject(new Error(mrIntl("CommonText.required_msg")))
          }
          else if (charactersCount >= 50) {
            return Promise.reject(new Error(mrIntl("SectionForm.max_characters_error")))
          } else {
            return Promise.resolve()
          }
        }}
      ]
    },
    // points: {
    //   type: "string",
    //   title: "Points",
    //   setInitialValue: true,
    // }
  }
 }
};


export default sectionForm;