import React from "react";
import { DnDTabularTextBoxes } from "/src/components/UI/DnD/DnDTabularTextBoxes";
import { DnDDistractors } from "/src/components/UI/DnD/DnDDistractors";
import {
  addListToArray,
  addItemToArray,
  removeItemFromArray,
} from "/src/views/Segments/InteractiveHelpers";
import {
  Segment,
  SegmentData,
} from "/src/components/UI/Segment/UIHelper";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const MatchForm = (props) => {
  console.log("RBDInlineMatch props", props);
  const {
    extra,
    segment_data,
    columns,
    possible_responses,
    distractors,
    state,
    saveState,
  } = props;
  
  const onTabDragEnd = (columns) => {
    const newColumns = columns.slice();

    const newSegmentData = {
      ...segment_data,
      columns: newColumns,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };
  const mrIntl = useTranslate()
  const addPair = () => {
    const newExtra = {
      ...extra,
      colSize: columns.length,
    };
    const newColumns = addListToArray(columns, newExtra);

    const newSegmentData = {
      ...segment_data,
      columns: newColumns,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const addDistractor = (index) => {
    const extra = {
      contentRequired: false,
      contentPrefix: "Distractor",
      placeholderPrefix: mrIntl("MatchForm.distractor"),
    };
    const newDistractors = addItemToArray(distractors, extra);

    const newSegmentData = {
      ...segment_data,
      distractors: newDistractors,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const removePair = (index) => {
    const newColumns = removeItemFromArray(columns, index);

    const newSegmentData = {
      ...segment_data,
      columns: newColumns,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const removeDistractor = (index) => {
    const extra = {
      noMinSize: true,
    };
    const newDistractors = removeItemFromArray(distractors, index, extra);

    const newSegmentData = {
      ...segment_data,
      distractors: newDistractors,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const removeFile = (type, index, childIndex) => {
    const newColumns = columns;
    const newDistractors = distractors;

    if (type == "column") {
      if (childIndex >= 0) {
        delete newColumns[index].items[childIndex].files;
      } else {
        delete newColumns[index].files;
      }
    } else if (type == "distractor") {
      delete newDistractors[index].files;
    }

    const newSegmentData = {
      ...segment_data,
      columns: newColumns,
      distractors: newDistractors,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const onTabEdit = (index, action) => {
    if (action == "add") {
      addPair();
    } else if (action == "remove") {
      removePair(index);
    }
  };

  const onSaveMedia = (type, index, files, childIndex) => {
    const newColumns = columns;
    const newDistractors = distractors;

    if (type == "column") {
      if (childIndex >= 0) {
        newColumns[index].items[childIndex].files = files;
      } else {
        newColumns[index].files = files;
      }
    } else if (type == "distractor") {
      newDistractors[index].files = files;
    }

    const newSegmentData = {
      ...segment_data,
      columns: newColumns,
      distractors: newDistractors,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const onTextChanged = (type, index, data, childIndex) => {
    const newColumns = columns;
    const newDistractors = distractors;

    if (type == "column") {
      if (childIndex >= 0) {
        newColumns[index].items[childIndex].content = data;
      } else {
        newColumns[index].title = data;
      }
    } else if (type == "distractor") {
      newDistractors[index].content = data;
    }

    const newSegmentData = {
      ...segment_data,
      columns: newColumns,
      distractors: newDistractors,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  return (
    <Segment>
      <SegmentData key={`segment-data-${props.unique_segment_identifier}`}>
        <div>
          <DnDTabularTextBoxes
            tabPrefix={mrIntl("MatchForm.pair")}
            titleLabel={mrIntl("CommonText.match_phrase")}
            itemsLabel={mrIntl("CommonText.match_target")}
            editableTitle={true}
            columns={columns}
            onTabDragEnd={onTabDragEnd}
            onTabEdit={onTabEdit}
            onTextChanged={onTextChanged}
            removeFile={removeFile}
            onSaveMedia={onSaveMedia}
          ></DnDTabularTextBoxes>

          <DnDDistractors
            distractors={distractors}
            addDistractor={addDistractor}
            removeDistractor={removeDistractor}
            onTextChanged={onTextChanged}
            removeFile={removeFile}
            onSaveMedia={onSaveMedia}
          ></DnDDistractors>
        </div>
      </SegmentData>
    </Segment>
  );
};
export default MatchForm;
