import React from "react";
import { MrCrudView } from "mr_react_framework";
import { actions } from "./redux";
import TermsFilter from "./TermsFilter/TermsFilter";
import TermsList from "./TermsList/TermsList";
import commonSelectors from "/src/App/commonSelectors";
// import moment from "moment";
import MrTranslate, { mrTranslate } from "/src/lib/MrTranslate/MrTranslate";
import dayjs from "dayjs";

const returnForm = (props) => {
console.log("return foem props===>", props)
const { mrIntl } = props

  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      // process and set values and then return changed values
      // opts.values["custom_field"] = "custom_value";

      if (opts.values.children) {
        let newChildren = opts.values.children
        // console.log("newChildren", newChildren);
        // let newChildren = opts.values.children
        opts.values.children_attributes = newChildren;
      }

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        name: {
          type: "string",
          title: mrIntl("Terms.academic_year_name_title"),
          //  title: "Academic year name",
          // default: "2020-2021"
          span: 8,
        },
        // label: {
        //   type: "string",
        //   placeholder: "Academic year label",
        //   // default: "2020-2021"
        // },
        children: {
          type: "array",
          addButtonTitle: `+ ${mrIntl("Terms.add_term")}`,
          // addButtonTitle : "+ Add term",
          span: 20,
          min: 1,
          max: 4,
          default: [{ name: "Term 1" }],
          items: {
            type: "object",
            properties: {
              name: {
                type: "string",
                title: mrIntl("Terms.term_name_title"),
                // title: "Term name", 
                // default: "Term 1"
                span: 8,
              },
              // label: {
              //   type: "string",
              //   placeholder: "Term label",
              //   // default: "Term 1"
              // },
              starts_on: {
                title: mrIntl("Terms.starts_on_title"),
                // title: "Start on",
                type: "string",
                span: 6,
                widget: "DatePickerWidget",
                // widgetConfig: {
                //   format: "YYYY-MM-DD h:mm a",
                //   showTime: {use12Hours: true, format:"h:mm a", minuteStep: 15},
                // },
                setInitialValue: true,
              },
              ends_on: {
                title: mrIntl("Terms.ends_on_title"),
                // title: "End on",
                type: "string",
                span: 6,
                widget: "DatePickerWidget",
                // widgetConfig: {
                //   format: "YYYY-MM-DD",
                // },
                setInitialValue: true,
              },
              // itemArray: {
              //   type: "array",
              //   items: {
              //     type: "object",
              //     properties: {
              //       itemArrayItem1: {
              //         type: "string",
              //         placeholder: "itemArrayItem1"
              //       },
              //       itemArrayItem2: {
              //         type: "string",
              //         placeholder: "itemArrayItem2"
              //       },
              //     }
              //   }
              // }
            },
          },
        },
      },
    },
    uiSchema: {},
  };
  return form;
};

const processTermsFormInitialValues = (data) => {
  console.log("initial data", data);
  let newData = { ...data };
  // let newData = {
  //   ...data,
  //   nestedValues: {
  //     ...data.nestedValues,
  //     tempNested: "tempNested updated"
  //   },
  //   name: "hello1"
  // }
  // data = nestedValues
  if (data.children) {
    // let children = formatJsonApiData(data.children)
    let children = data.children;
    newData.children = children.map((item) => {
      return {
        ...item,
        // starts_on: moment.utc(item.starts_on),
        // ends_on: moment.utc(item.ends_on),
        starts_on: dayjs.utc(item.starts_on),
        ends_on: dayjs.utc(item.ends_on),
      };
    });
    // newData.children = children
  }
  console.log("newData", newData);
  return newData;
};

const Terms = MrCrudView({
  resourceName: "terms",
  singleResourceName: "term",
  // displayName: "Academic year",
  resourceUrl: "/terms",
  // layout: OrgsLayout,
  filter: TermsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  // listItem: TermListItem,
  forms: [
    {
      name: "terms",
      form: returnForm,
      config: { processInitialValues: processTermsFormInitialValues },
    },
  ],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      // widget: TermListItem
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span><MrTranslate id={"CommonText.item_detail"}/> {JSON.stringify(props.item)}</span>;
      },
    },
    itemDetail: {
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span><MrTranslate id={"CommonText.item_detail"}/> {JSON.stringify(props.item)}</span>;
      },
      // widget: TermItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      config: {
        create: { show: true },
        search: { show: false },
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      widget: TermsList,
      forceReload: true,
    },
  },
});

// Boards.defaultProps = {};

Terms.propTypes = {};
export default Terms;
