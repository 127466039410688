import React from "react";

import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments";
import ListAttachments from "/src/components/UI/Attachments/ListAttachments";

const DocumentView = (props) => {
  console.log("Document view props", props);

  const { resource_json, printMode, title, caption } = props;

  const segment_data = resource_json.segment_data || {};
  const resource_setting = resource_json.resource_setting || {};
  const attachments = segment_data.attachments || [];

  return (
    <div>
      {printMode ? (
        <ListAttachments
          key={`list-attachments-${props.unique_segment_identifier}`}
          titlePrefix={"Doc"}
          printMode={printMode}
          caption={caption}
          hideTitle={printMode}
          title={title}
          attachments={attachments}
        ></ListAttachments>
      ) : (
        <TabularAttachments
          attachmentType="doc"
          tabPrefix="Doc"
          attachments={attachments}
          resource_setting={resource_setting}
        ></TabularAttachments>
      )}
    </div>
  );
};
export default DocumentView;
