import React, { useEffect } from "react";
// import "./GroupItemDetail.scss";
import { Route, useRouteMatch } from "react-router-dom";
import { Tabs, Button, Card, Row, Col, List, Tag, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { RocketOutlined, UndoOutlined } from "@ant-design/icons";
import CountDown from "react-countdown";
import { useHistory } from "react-router-dom";
import { checkMob } from "/src/lib/utils/helperMethods";

const DemoResetCountDown = (props) => {
  console.log("DemoResetCountDown props", props);
  const isMobile = checkMob();

  const switchToMaintenanceMode = () => {
    history.push("/maintenance/resetting-demo");
  };

  let history = useHistory();
  let currentTime = new Date();
  if (currentTime.getUTCHours() % 2 == 0 && currentTime.getUTCMinutes() == 0) {
    // in the first minute of an even hour - switch mode - in case someone manually tries to refresh during reset
    switchToMaintenanceMode();
  }
  let currentUTCTime = new Date().setHours(
    currentTime.getUTCHours(),
    currentTime.getUTCMinutes(),
    currentTime.getUTCSeconds()
  );
  // 1599214301228
  let timeHoursToAdd = currentTime.getUTCHours() % 2 === 0 ? 2 : 1;
  let targetUTCTime = new Date().setHours(
    currentTime.getUTCHours() + timeHoursToAdd,
    0,
    0
  );
  // 1599215400915
  let timerTime = targetUTCTime - currentUTCTime;
  // let timerTime = 10000

  return (
    <>
      <Space>
        {/* <span>Demo will reset in</span> */}
        <Tag color="orange">
          {isMobile ? "Demo resets in " : "Demo will reset in "}
          <CountDown
            date={Date.now() + timerTime}
            daysInHours={true}
            onComplete={() => switchToMaintenanceMode()}
          />
        </Tag>
      </Space>
    </>
  );
};

DemoResetCountDown.defaultProps = {};

DemoResetCountDown.propTypes = {};

export default DemoResetCountDown;
