import React from 'react'
import styled from 'styled-components'
import { RBDDraggableItem, getRenderItem } from './RBDDraggableItem'
import { Droppable } from 'react-beautiful-dnd'
import { getUniqueId } from '/src/lib/utils/helperMethods'

const DroppableBoxContainer = styled.div`
	display: block;
	margin-left: auto;
	margin-right: auto;
	border-radius: 2px;
	// border: 1px solid lightgrey;
	border: 1px dashed lightgrey;
  padding: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
	transition: background-color 0.2s ease;
	min-width: 120px;
	max-width: 400px;
	min-height: 60px;
	vertical-align: middle;
	// width: 100%;
  background-color: ${props =>
		props.isDraggingOver ? 'skyblue' : 'white'}
  // flex-grow: 1;
`
const DraggablePlaceholder = styled.div`
  display: none;
`
export const RBDDroppableBox = (props) => {
	console.log('DroppableBox props ', props);
	const { draggableProps, droppableProps } = props
	const renderItem = getRenderItem(draggableProps, droppableProps.list.items)

	return (
		<Droppable
			{...droppableProps}
			droppableId={droppableProps.droppableId}
			renderClone={renderItem}
		>
			{(provided, snapshot) => (
				<DroppableBoxContainer
					ref={provided.innerRef}
					{...provided.droppableProps}
					{...droppableProps}
					isDraggingOver={snapshot.isDraggingOver}
				>
					<RBDDraggableItem
						key={draggableProps.item.id}
						draggableProps={{
							...props.draggableProps,
							hidePlaceholder: true,
						}}
					/>
					<DraggablePlaceholder>
						{provided.placeholder}
					</DraggablePlaceholder>
				</DroppableBoxContainer>
			)}
		</Droppable>
	)
}
