import React from 'react';
import { Col, Row, Tooltip, TreeSelect } from 'antd';
import { getTopicsQuestionsAsTreeData } from '../../ExperienceUsers/ExperienceUserItemDetail/TopicQuestionSwitcher';
import { truncate } from 'lodash';
import { topicsSelector } from '../Topics/selector';
import { segmentsSelector } from '../selector';
import { useSelector } from 'react-redux';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const MapAttachmentToQuestionSelector = (props) => {
  console.log("MapAttachmentToQuestionSelector props ==>", props);
  const { currentPreviewImage, images, handleMappingAttachments, autoMappingProgress, isAutoMappingStatusFinalized } = props;

  const topics = useSelector(topicsSelector());
  const segments = useSelector(segmentsSelector());
  const mrIntl = useTranslate();

  const currentImage = images.find((image) => image.uid === currentPreviewImage.uid) || {}

  let isQuestionSelectorDisabled = false;
  let questionSelectorTooltipTitle = '';

  if(autoMappingProgress.status && !isAutoMappingStatusFinalized) {
    isQuestionSelectorDisabled = true
    questionSelectorTooltipTitle = mrIntl("MapAttachmentToQuestionSelector.disabled_as_auto_mapping_in_progress")
  } else if (currentImage?.type && !currentImage.type.includes("image")) {
    isQuestionSelectorDisabled = true
    questionSelectorTooltipTitle = mrIntl("MapAttachmentToQuestionSelector.only_images_are_allowed_to_be_manually_mapped")
  }
  
  let treeData = getTopicsQuestionsAsTreeData({
    topics: topics,
    segments: segments, 
    onlyQNumberInTree: true 
  }).treeData

  const value = 
    currentImage.segment_ids || 
      (currentImage.segment_id 
        ? [currentImage.segment_id] 
        : []
      );
        
  console.log("MapAttachmentToQuestionSelector ==>", currentImage, currentImage.segment_ids);
  return (
    <Row align="middle">
      <Col span={12}>
        <Tooltip title={questionSelectorTooltipTitle}>
          <TreeSelect
            showSearch
            // disabled={!currentImage.id}
            value={value}
            onChange={(segmentIds, label, extra) => {
              const options  = {
                currentImage: currentImage,
                selectedSegmentIndexPath: extra.triggerNode?.props.indexPath,
                selectedSegmentTopicId: extra.triggerNode?.props.topicId,
                selectedSegmentTopicIndex: extra.triggerNode?.props.topicIndex,
                selectedSegmentId: extra.triggerValue,
                selected: extra.selected,
              }
              console.log("segmentIds, label, extra ==>", segmentIds, label, extra, options)
              handleMappingAttachments(segmentIds, options)
            }}
            style={{
              width: '100%',
            }}
            className="map-attachment-to-question-selector"
            placeholder="Please select question numbers"
            allowClear
            multiple
            treeDefaultExpandAll
            treeData={treeData}
            disabled={isQuestionSelectorDisabled}
          />
        </Tooltip>
      </Col>
      <Col span={7} offset={5} style={{textAlign: "end"}} className='p-r-10' >
        {truncate(currentImage?.name, {length: 30}) }
      </Col>
    </Row>
  );
};

export default MapAttachmentToQuestionSelector;