import React, { useEffect, useState } from "react";

import MediaUI from "/src/components/UI/Media/MediaUI";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const FeedbackFileUpload = (props) => {
  console.log("BulkImportFileURL props ==>", props);
  const [fileList, setFileList] = useState(props.value || []);
  const mrIntl = useTranslate()
  const fileURL = props.value || [];

  const mediaFormInitialState = {
    modal: false,
    loading: false,
    visible: false,
    title: mrIntl("CommonText.insert_files"),
    okText: mrIntl("CommonText.add_file"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    requireFooter: false,
    limit: 5,
    limitMessage: mrIntl("FeedbackFileUpload.max_upload_limit_reached"),
    content_type: "image",
    supportedTypes: "image/jpg, image/jpeg, image/png, image/gif, image/bmp",
    supportedFormats: "jpg, jpeg, png, gif, bmp",
    selectConfig: {
      multiple: true,
      maxSize: "20 MB",
    },
  };
  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );

  const onSaveMedia = (files) => {
    console.log("files in upload==>", files);
    // if (files && files[0]) {
    // let url = files[0].url;
    let url = [];
    files.map((file) => url.push(file.url));
    setFileList(url);
    if (props.onChange) {
      props.onChange(url);
    }
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "",
      triggerSaveAction: 0,
    });
    // }
  };

  return (
    <>
      <MediaUI
        computer={true}
        url={true}
        mediaFormSetting={mediaFormSetting}
        setMediaFormSetting={setMediaFormSetting}
        onSaveMedia={onSaveMedia}
      ></MediaUI>

      {fileURL && (
        <h4
          style={{
            "overflow-wrap": "break-word",
            "word-wrap": "break-word",
            "word-break": "break-word",
          }}
        >
          {fileList.length !== 0 && (
            <>
              <MrTranslate id={"FeedbackFileUpload.attached_files"}/>:
              <br />
              {fileList.map((file, index) => (
                <>
                  <a href={file} target="_blank">
                  {/* {`File ${index + 1}`} */}
                  {mrIntl("FeedbackFileUpload.file", {index: index + 1})}
                  </a>
                  <br />
                </>
              ))}
            </>
          )}
        </h4>
      )}
    </>
  );
};

export default FeedbackFileUpload;
