import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { call, put, select } from "redux-saga/effects";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { message } from "/src/components/UI/AntdAppHelper";
import { actions as firestoreInteractionActions, getOrgAiUsageInfo } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "org",
  actionNames: [
    "FETCH",
    "CREATE",
    "SHOW",
    "UPDATE",
    "DELETE",
    "SYNC",
    "MIGRATE",
    "EXPORT_TO_EXCEL",
    "SUPERADMIN_DASHBOARD_STATS",
    "BOARDS_PROGRAMMES_SUBJECTS_GRADES",
    "CHECK_IF_ORG_HAS_EXCEEDED_AI_QUOTA",
    "SET_AI_MODEL",
    "SET_AI_MODEL_TEMP",
    "IMPORT_STATUS",
    "REQUEST_PLAN_UPGRADE",
    "SET_SHOW_UPGRADE_PLAN_MODAL",
  ],
});

const initialState = fromJS({
  orgs: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

function superadminDashboardStatsSuccess(state = initialState, action) {
  console.log("superadminDashboardStatsSuccess action", action);
  return state
    .set(
      "superadmin_dashboard_stats",
      action.payload.superadmin_dashboard_stats
    )
    .set("superadmindashboardstatsloading", false);
}

export function* superadminDashboardStatsSaga(action) {
  yield put(actions.superadminDashboardStatsStart());
  let url = "get_superadmin_dashboard_stats.json";
  // const superadminDashboardStatsData = {
  //   os: checkMob() ? getMobileOperatingSystem() : getOS(),
  // };
  console.log("superadminDashboardStats calling", action);
  try {
    const response = yield axiosInstance.instance.get(url);
    console.log("superadminDashboardStats response", response);
    if (response && response.data.stats) {
      yield put(
        actions.superadminDashboardStatsSuccess({
          superadmin_dashboard_stats: response.data.stats,
        })
      );
      // if(action.options && action.options.successCallback){
      //   action.options.successCallback(response.data.url)
      // }
    }
  } catch (error) {
    console.error("superadminDashboardStats error", error);
    message.error(
      "Something went wrong in getting superadminDashboardStats file"
    );
    yield put(actions.superadminDashboardStatsFail({ error }));
  }
}

function boardsProgrammesSubjectsGradesSuccess(state = initialState, action) {
  console.log("boardsProgrammesSubjectsGradesSuccess action", action);
  return state
    .set(
      "boards_programmes_subjects_grades",
      action.payload.boards_programmes_subjects_grades
    )
    .set("boardsloading", false)
    .set("loading", false);
  // Org redux so orgs -> loading = true automatically
}

function checkIfOrgHasExceededAiQuotaSuccess(state = initialState, action) {
  console.log("calculateOrgStatsSuccess action", action);
  const { hasExceededAiQuota } = action.payload;
  
  return state.set("has_exceeded_ai_quota", hasExceededAiQuota)
}

function setAiModelSuccess(state = initialState, action) {
  console.log("setAiModelSuccess action", action);
  return state.set("ai_model", action.payload);
}

function setAiModelTempSuccess(state = initialState, action) {
  console.log("setAiModelTempSuccess action", action);
  return state.set("ai_model_temp", action.payload);
}

export function* checkIfOrgHasExceededAiQuotaSaga(action) {
  const { orgId, aiCreditsUSD } = action.payload;
  yield put(actions.checkIfOrgHasExceededAiQuotaStart());
  try {
    let hasExceededAiQuota = false;
    const orgAiUsageInfo = yield call(getOrgAiUsageInfo,
      {
        orgId: orgId,
      }
    );

    // if(!aiCreditsUSD) { // if no credits assigned yet
    //   hasExceededAiQuota = true;
    // }
    
    if(orgAiUsageInfo.stats && aiCreditsUSD) {
      if(parseInt(orgAiUsageInfo.stats.total_cost) > parseInt(aiCreditsUSD)) {
        hasExceededAiQuota = true;
      }
    }
    
    console.log("calculateOrgStatsSaga hasUsesExisted =====>", hasExceededAiQuota, orgAiUsageInfo, orgAiUsageInfo.stats);
    yield put(actions.checkIfOrgHasExceededAiQuotaSuccess({ hasExceededAiQuota }));
  } catch (error) {
    console.error("calculateOrgStats error", error);
    message.error(
      "Something went wrong in getting calculateOrgStats file"
    );
    yield put(actions.checkIfOrgHasExceededAiQuotaFail({ error }));
  }
}

export function* boardsProgrammesSubjectsGradesSaga(action) {
  yield put(actions.boardsProgrammesSubjectsGradesStart());
  let url = "static_boards_programmes_subjects_grades.json";
  // const boardsProgrammesSubjectsGradesData = {
  //   os: checkMob() ? getMobileOperatingSystem() : getOS(),
  // };
  console.log("boardsProgrammesSubjectsGrades calling", action);
  try {
    const response = yield axiosInstance.instance.get(url, {params: {
      with_programme_subjects_attributes_for_static: action.payload.with_programme_subjects_attributes_for_static
    }});
    console.log("boardsProgrammesSubjectsGrades response", response);
    if (response && response.data.boards_static_data) {
      yield put(
        actions.boardsProgrammesSubjectsGradesSuccess({
          boards_programmes_subjects_grades: response.data.boards_static_data,
        })
      );
      if (action.options && action.options.successCallback) {
        action.options.successCallback(response.data.boards_static_data);
      }
    }
  } catch (error) {
    console.error("boardsProgrammesSubjectsGrades error", error);
    message.error(
      "Something went wrong in getting boardsProgrammesSubjectsGrades file"
    );
    yield put(actions.boardsProgrammesSubjectsGradesFail({ error }));
  }
}

function* importStatusSaga(action) {
  yield put(actions.importStatusStart());
  let url = "bulk_import_status_check.json";

  console.log("import status check action ==>", action)
  const { successCallback, errorCallback } = action.options;
  try {
    const response = yield axiosInstance.instance.get(url, { 
      params: action.payload 
    });
    console.log("import status check response", response);
    
    if (response && response.data) {
      if (successCallback) {
        successCallback(response.data);
      }
    }
  } catch (error) {
    console.error("import status check error", error);
    if (errorCallback) {
      errorCallback(error);
    }
    yield put(actions.importStatusFail({ error }));
  }
}


function exportToExcelSuccess(state = initialState, action) {
  // custom fn to override
  const { payload } = action;
  // TODO: update state here according to data
  console.log("exportToExcelSuccess", action);
  return state.set(payload.key, payload.response).set("exportloading", false);
  // return state;
  // return state.set
}

export function* exportToExcelSaga(action) {
  yield put(actions.exportToExcelStart());
  let url = "orgs/export_to_excel.json";
  let exportToExcelData = {};
  console.log("exportToExcelSaga calling", exportToExcelData);
  try {
    const response = yield axiosInstance.instance.post(url, exportToExcelData);

    console.log("exportToExcelSaga response", response);
    if (response && response.data.export_excel_url) {
      yield put(
        actions.exportToExcelSuccess({
          key: "exported_excel_url",
          response: response.data.export_excel_url,
        })
      );
    }
  } catch (error) {
    console.error("exportToExcelSaga error", error);
    message.error("Something went wrong in exporting to excel");
    yield put(actions.exportToExcelFail({ error }));
  }
}

export function* migrateSaga(action) {
  console.log("org redux migrateSaga action", action);

  const migrationData = {
    only_failed: action.payload.only_failed,
    continue_remaining: action.payload.continue_remaining || false,
    duration: action.payload.duration,
    with_submissions: action.payload.with_submissions,
    // terms: {
    //   sync: true,
    // },
    // subjects: {
    //   sync: true,
    // },
    // users: {
    //   sync: true,
    // },
    // grades: {
    //   sync: true,
    // }
  };
  let url = `orgs/${action.payload.id}/migrate_from_v1.json`;
  try {
    yield put(actions.migrateStart());
    const response = yield axiosInstance.instance.post(url, migrationData);
    const currentUser = yield select(currentUserSelector());
    // const responseData = formatJsonApiData(response.data.org)
    yield put(actions.showSuccess({ data: { org: response.data.org } }));
    console.log("org redux response.data.org", response.data.org);
    if (
      currentUser &&
      currentUser.org_id === response.data.org.data.attributes.id
    ) {
      yield put(loginActions.orgSuccess({ org: response.data.org }));
    }
    // yield localStorage.setItem("token", response.data.token);

    console.log("ORGS SYNC response", response);
    yield put(actions.migrateSuccess(response.data));
  } catch (error) {
    console.error("error", error);
    if (error.response) {
      yield put(actions.migrateFail({ error: error.response.data.error }));
    } else {
      yield put(actions.migrateFail({ error: error }));
    }
  }
}

export function* syncSaga(action) {
  const syncData = {
    terms: {
      sync: true,
    },
    subjects: {
      sync: true,
    },
    users: {
      sync: true,
    },
    grades: {
      sync: true,
    },
  };
  let url = `orgs/${action.payload.id}/sync_mb.json`;
  try {
    yield put(actions.syncStart());
    const response = yield axiosInstance.instance.post(url, syncData);
    const currentUser = yield select(currentUserSelector());
    // const responseData = formatJsonApiData(response.data.org)
    yield put(actions.showSuccess({ data: { org: response.data.org } }));
    console.log("org redux response.data.org", response.data.org);
    if (
      currentUser &&
      currentUser.org_id === response.data.org.data.attributes.id
    ) {
      yield put(loginActions.orgSuccess({ org: response.data.org }));
    }
    // yield localStorage.setItem("token", response.data.token);

    console.log("ORGS SYNC response", response);
    yield put(actions.syncSuccess(response.data));
  } catch (error) {
    console.error("error", error);
    if (error.response) {
      yield put(actions.syncFail({ error: error.response.data.error }));
    } else {
      yield put(actions.syncFail({ error: error }));
    }
  }
}

// function syncSuccess(state = initialState, action) {
//   console.log("syncsuccess action", action);
//   return state;
// }

export function* requestPlanUpgradeSaga(action) {
  console.log("checkplanrequest", action)
  const { successCallback } = action.options;
  let url = `orgs/request_plan_upgrade`;
  try {
    yield put(actions.requestPlanUpgradeStart());
    const response = yield axiosInstance.instance.post(url, action.payload);

    if (response.status === 200) {
      console.log("response==>", response);
      message.success("Your request has been successfully submitted! We'll get in touch in the next 48 hrs");
    }
  } catch (error) {
    message.error("Something went wrong, Please try again.");
    yield put(actions.requestPlanUpgradeFail({ error: error }));
  }
};

function setShowUpgradePlanModal(state = initialState, action) {
  console.log(" ", action);
  return state.set("upgrade_plan_modal_config", action.payload);
}



export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  [actionTypes.EXPORT_TO_EXCEL_ORG_SUCCESS]: exportToExcelSuccess,
  // Overriding success func to update custom key in org state
  [actionTypes.SUPERADMIN_DASHBOARD_STATS_ORG_SUCCESS]: superadminDashboardStatsSuccess,
  [actionTypes.BOARDS_PROGRAMMES_SUBJECTS_GRADES_ORG_SUCCESS]: boardsProgrammesSubjectsGradesSuccess,
  [actionTypes.CHECK_IF_ORG_HAS_EXCEEDED_AI_QUOTA_ORG_SUCCESS]: checkIfOrgHasExceededAiQuotaSuccess,
  [actionTypes.SET_AI_MODEL_ORG_SUCCESS]: setAiModelSuccess,
  [actionTypes.SET_AI_MODEL_TEMP_ORG_SUCCESS]: setAiModelTempSuccess,
  [actionTypes.SET_SHOW_UPGRADE_PLAN_MODAL_ORG_SUCCESS]: setShowUpgradePlanModal,
});
export const watchOrgs = reduxCrud.generateWatchSaga({
  [actionTypes.SYNC_ORG]: syncSaga,
  [actionTypes.MIGRATE_ORG]: migrateSaga,
  [actionTypes.EXPORT_TO_EXCEL_ORG]: exportToExcelSaga,
  [actionTypes.SUPERADMIN_DASHBOARD_STATS_ORG]: superadminDashboardStatsSaga,
  [actionTypes.BOARDS_PROGRAMMES_SUBJECTS_GRADES_ORG]: boardsProgrammesSubjectsGradesSaga,
  [actionTypes.CHECK_IF_ORG_HAS_EXCEEDED_AI_QUOTA_ORG]: checkIfOrgHasExceededAiQuotaSaga,
  [actionTypes.IMPORT_STATUS_ORG]: importStatusSaga,
  [actionTypes.REQUEST_PLAN_UPGRADE_ORG]: requestPlanUpgradeSaga,
});

export default reduxCrud;
