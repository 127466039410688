import { fromJS } from "immutable";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { MrReduxCrud, formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { upperFirst, concat, flatten } from 'lodash';
import { message } from "/src/components/UI/AntdAppHelper";


const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "topic",
  singleResourceName: "segment",
  apiUrl: "/segments",
  actionNames: ["FETCH", "CREATE", "SHOW", "UPDATE", "DELETE", "DUPLICATE", "REARRANGE", "SET_ACTIVE_ID"],
});

const initialState = fromJS({
  segments: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

const successFunc = (state, action) => {
  // console.log("action data", action);
  console.log("action, state", action, state.get("segments"));
  let stateTopics = state.get("segments");
  let topics = [...stateTopics];
  let payload = action.payload;
  console.log("action.payload", action.payload);


  const elmToMove = topics.splice(action.payload.sourceIdx, 1)[0];
  console.log('on drag end result elmToMove ==>', elmToMove);
  topics.splice(action.payload.destinationIdx, 0, elmToMove);
  // if(action.payload.callback){
  //   action.payload.callback(topics);
  // }

  // // for updating position on FE
  // topics.forEach((topic, i) => {
  //   if(i > payload.destinationIdx){
  //     topic.position += 1
  //   }  
  // });
  console.log(" state after save", topics);
  return state.set("segments", topics)

}

function setActiveIdSuccess(state = initialState, action) {
  console.log("activeTopicId action ==>", action)
  return state.set("activeTopicId", action.payload.id);
}

const duplicateTopicSuccess = (state, action) => {
  let payload = action.payload;
  let options = action.options;
  let stateSegments = state.get("segments")
  let segments = [...stateSegments];


  let insertAtIndex
  if(action.type.indexOf("CREATE") !== -1){
    insertAtIndex = options && options.createAtIndex
  }
  if(action.type.indexOf("DUPLICATE") !== -1){
    insertAtIndex = options && options.duplicateAtIndex
  }
  let updatedTopic = formatJsonApiData(payload.data.segment)
  if(typeof(insertAtIndex) !== "undefined"){
    segments.splice(insertAtIndex, 0, updatedTopic);
  } else {
    segments.unshift(updatedTopic);
  }

  message.success(`Section successfully added.`);

  return state.set("segments", segments).set("loading", false).set("duplicateloading", false).set("createloading", false)
}

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState,{
    REARRANGE_TOPIC_SUCCESS: successFunc,
    SET_ACTIVE_ID_TOPIC_SUCCESS: setActiveIdSuccess,
    DUPLICATE_TOPIC_SUCCESS: duplicateTopicSuccess,
});

export default reduxCrud;
