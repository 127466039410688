import React, { Component } from "react";
import html2canvas from "html2canvas";
import axios from "axios";
import { getCanvasSizeObj } from "/src/lib/utils/helperMethods";
import { showCalcLoader } from "../Desmos/DesmosScientific";
import { message } from "/src/components/UI/AntdAppHelper";
import { speechToText } from "../../SpeechToText/SpeechToText";


const token = localStorage.getItem("token");
export const simpleUpload = {
  // The URL that the images are uploaded to.
  uploadUrl:
    (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "attachments",
  // Headers sent along with the XMLHttpRequest to the upload server.
  headers: {
    token: token, // Token should be latest, in case of logout/caching its going null
  },
};

export const defaultToolbar = [
  "bold",
  "italic",
  "underline",
  "superscript",
  "subscript",
  "removeFormat",
  "|",
  "bulletedList",
  "numberedList",
  "|",
  "insertTable",
  "specialCharacters",
  "mathInput",
  "imageUpload",
  "|",
  "undo",
  "redo",
  "|",
  "alignment",
  "direction",
  // "direction:ltr",
  // "direction:rtl",
  "fontSize",
  "fontColor",
  "fontBackgroundColor",
];

export const basicPlugins = [];

export const basicToolbar = ["mathInput"];

export const removeExtraPlugins = [
  "TextTransformation",
  "URLImage",
  "TextInput",
  "DnDInput",
  "DropDownInput",
  "WProofreader",
  "MathType",
  "SpeechToText"
];

export const fontSize = {
  options: [
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    // 'default',
  ],
};

export const link = {
  // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
  addTargetToExternalLinks: true,
};

export const imageConfig = {
  styles: ["alignLeft", "alignCenter", "alignRight"],
  toolbar: [
    "imageStyle:alignLeft",
    "imageStyle:alignCenter",
    "imageStyle:alignRight",
  ],
};

export const wordCountConfig = {
  onUpdate: (stats) => {
    console.log(`Characters: ${stats.characters}\nWords: ${stats.words}`);
  },
};

export const copyToCK = {
  onCopyToCK: (editor) => {
    const scientificCalcClass = "dcg-basic-list";
    const graphingCalcClass = "graphing-calculator";
    var scientificEle = document.getElementsByClassName(scientificCalcClass)[0];
    var graphingEle = document.getElementsByClassName(graphingCalcClass)[0];

    if (scientificEle) {
      // var svgElements = scientificEle.getElementsByTagName("svg");
      const canvasSizeObj = getCanvasSizeObj(scientificEle);
      showCalcLoader(true);
      html2canvas(scientificEle, canvasSizeObj).then((canvas) => {
        imageInsertFromUrl(editor, canvas.toDataURL());
      });
    } else if (graphingEle) {
      showCalcLoader(true);
      const canvasSizeObj = getCanvasSizeObj(graphingEle);
      html2canvas(graphingEle, canvasSizeObj).then((canvas) => {
        imageInsertFromUrl(editor, canvas.toDataURL());
      });
    } else {
      message.warning("Your calculator must be visible");
    }
  },
};

export const imageInsertFromUrl = (editor, src) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      token: token,
    },
  };
  const url =
    (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "attachments";

  const formData = new FormData();
  formData.append("url", src);

  axios.post(url, formData, config)
    .then((response) => {
      console.log("Returned response ==>", response);
      editor.execute("imageInsert", { source: response.data.url });
      showCalcLoader(false);
    })
    .catch((error) => {
      console.log(
        "Something went wrong in saving calc canvas image ==>",
        error
      );
      message.error("Something went wrong in copying from calculator.");
      showCalcLoader(false);
    });
};

export const wproofreader = {
  serviceId: "M8D14egaTvKpMMW",
  srcUrl: "https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js",
  enableGrammar: false,
  styleGuideSuggestions: false,
  spellingSuggestions: true,
  autocorrect: false,
  lang: "en_US",
  autoSearch: true,
  suggestionsCount: 4,
  actionItems: ["ignoreall", "addword", "settings", "toggle"],
  settingsSections: ['languages','dictionaries','options'],
  disableOptionsStorage: ['styleGuideSuggestions', 'autocorrect'],
  // disableOptionsStorage: [],
  ignoreAllCapsWords: false,
  ignoreDomainNames: false,
  ignoreWordsWithMixedCases: false,
  ignoreWordsWithNumbers: false,
  enableLanguagesInBadgeButton: true,
};


// export const languageDirection = {
//   onLanguageDirectionChange: null,
// };

export const CKEDefaultTools = {
  removePlugins: removeExtraPlugins,
  toolbar: defaultToolbar,
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  image: imageConfig,
  // languageDirection: languageDirection,
  link: link,
};

export const CKEBasicTools = {
  removePlugins: removeExtraPlugins,
  toolbar: basicToolbar,
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  link: link,
};

export const CKETextOptionTools = {
  removePlugins: removeExtraPlugins,
  toolbar: basicToolbar,
  simpleUpload: simpleUpload,
  pastePlainText: true,
  fontSize: fontSize,
  link: link,
};

export const CKETextContentTools = {
  removePlugins: removeExtraPlugins,
  toolbar: defaultToolbar,
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  image: imageConfig,
  // languageDirection: languageDirection,
  link: link,
};

export const CKETextGenerateAISourceTools = {
  removePlugins: removeExtraPlugins,  
  toolbar: [
    "bold",
    "italic", 
    "underline",
    "numberedList",
    "bulletedList",
    "mathInput",
  ],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  // languageDirection: languageDirection,
  link: link,
};
export const CKETagDescriptionTools = {
  removePlugins: removeExtraPlugins,  
  autoFocusAtEnd: true,
  toolbar: [
    "bold",
    "italic", 
    "underline",
    "superscript",
    "subscript",
    "removeFormat",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "specialCharacters",
    "mathInput",
    "|",
    "alignment",
    "direction",
    // "direction:ltr",
    // "direction:rtl",
  ],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  // languageDirection: languageDirection,
  link: link,
};

export const CKETextCommentTools = {
  removePlugins: removeExtraPlugins,
  autoFocusAtEnd: true,
  toolbar: [
    "bold",
    "italic",
    "underline",
    "superscript",
    "subscript",
    "removeFormat",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "specialCharacters",
    "mathInput",
    "|",
    "undo",
    "redo",
    "|",
    "alignment",
    "direction",
    // "direction:ltr",
    // "direction:rtl",
    "fontSize",
    "fontColor",
    "fontBackgroundColor",
  ],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  image: imageConfig,
  // languageDirection: languageDirection,
  link: link,
};

export const CKETextAccordionTitleTools = {
  removePlugins: removeExtraPlugins,
  toolbar: [
    "bold",
    "italic",
    "underline",
    "superscript",
    "subscript",
    "removeFormat",
    "specialCharacters",
    "mathInput",
    "alignment",
    "direction",
    // "direction:ltr",
    // "direction:rtl",
  ],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  image: imageConfig,
  // languageDirection: languageDirection,
  link: link,
};

export const CKETextCaptionTools = {
  removePlugins: removeExtraPlugins,
  toolbar: [
    "bold",
    "italic",
    "underline",
    "superscript",
    "subscript",
    "removeFormat",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "specialCharacters",
    "mathInput",
    "|",
    "undo",
    "redo",
    "|",
    "alignment",
    "direction",
    // "direction:ltr",
    // "direction:rtl",
    "|",
    "fontSize",
    "fontColor",
    "fontBackgroundColor",
  ],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  // languageDirection: languageDirection,
  link: link,
};

export const CKETextFeedbackTools = {
  removePlugins: removeExtraPlugins,
  toolbar: [
    "bold",
    "italic",
    "underline",
    "superscript",
    "subscript",
    "removeFormat",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "undo",
    "redo",
  ],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  // languageDirection: languageDirection,
  link: link,
};

export const CKETextSectionTitleTools = {
  removePlugins: removeExtraPlugins,
  toolbar: [
    "bold",
    "italic",
    "underline",
    "|",
    "alignment",
    "direction",
    // "direction:ltr",
    // "direction:rtl",
  ],
  blockedKeys: ["enter"],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  // languageDirection: languageDirection,
  link: link,
  pastePlainText: true,
  autoFocus: true,
  autoFocusAtEnd: true
};

export const CKETextAnswerTools = {
  removePlugins: removeExtraPlugins.concat(["Link", "CKFinder"]), // Remove link from student side and also remove CKFinder because if we only remove link then finder gives error  
  toolbar: [
    "bold",
    "italic",
    "superscript",
    "subscript",
    "removeFormat",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "insertTable",
    "specialCharacters",
    "mathInput",
    "|",
    "heading",
    "|",
    "alignment",
    "fontColor",
    // "fontBackgroundColor",
    "direction",
    // "direction:ltr",
    // "direction:rtl",
  ],
  simpleUpload: simpleUpload,
  // languageDirection: languageDirection,
  link: link,
  pastePlainText: true,
  extraPaddingTopOnFocus: "20px",
};

export const CalcTool = {
  toolbarName: "copyToCK",
  copyToCK: copyToCK,
};

export const SpeechToTextTool = {
  toolbarName: "SpeechToText",
  speechToText: speechToText,
  pluginName: "SpeechToText"
}

export const SpellCheckTool = {
  pluginName: "WProofreader",
  toolbarName: "wproofreader",
  wproofreader: wproofreader,
};

export const WirisMathTool = {
  replaceWithToolbarName: "mathInput",
  pluginName: "MathType",
  toolbarName: "MathType",
  mathTypeParameters: {},
};

export const updateConfigForTool = (config, toolbarName) => {
  let newToolbar = [...config.toolbar];
  let newRemovePlugins = [...config.removePlugins];

  if (toolbarName == CalcTool.toolbarName) {
    newToolbar.push(CalcTool.toolbarName);
    config = {
      ...config,
      toolbar: [...newToolbar],
      copyToCK: CalcTool.copyToCK,
    };
  } else if (toolbarName == SpellCheckTool.toolbarName) {
    let spellcheckIndexInRemovePlugins = newRemovePlugins.indexOf(
      SpellCheckTool.pluginName
    );
    newToolbar.push(SpellCheckTool.toolbarName);
    newRemovePlugins.splice(spellcheckIndexInRemovePlugins, 1);

    config = {
      ...config,
      toolbar: [...newToolbar],
      removePlugins: [...newRemovePlugins],
      wproofreader: SpellCheckTool.wproofreader,
    };
  } else if (toolbarName == WirisMathTool.toolbarName) {
    let mathInputIndexInToolbar = newToolbar.indexOf(
      WirisMathTool.replaceWithToolbarName
    );
    let mathTypeIndexInRemovePlugins = newRemovePlugins.indexOf(
      WirisMathTool.pluginName
    );
    if (mathInputIndexInToolbar >= 0) {
      newToolbar[mathInputIndexInToolbar] = WirisMathTool.toolbarName;
    }
    newRemovePlugins.splice(mathTypeIndexInRemovePlugins, 1);
    config = {
      ...config,
      toolbar: [...newToolbar],
      removePlugins: [...newRemovePlugins],
      mathTypeParameters: WirisMathTool.mathTypeParameters,
    };
  } else if (toolbarName == SpeechToTextTool.toolbarName) {
    let speechToTextToolIndexInRemovePlugins = newRemovePlugins.indexOf(
      SpeechToTextTool.pluginName
    );
    newToolbar.unshift(SpeechToTextTool.toolbarName);
    newRemovePlugins.splice(speechToTextToolIndexInRemovePlugins, 1);
    config = {
      ...config,
      toolbar: [...newToolbar],
      removePlugins: [...newRemovePlugins],
      speechToText: SpeechToTextTool.speechToText,
    };
  }

  return config;
};

export const CKETextInputTools = {
  removePlugins: removeExtraPlugins,
  toolbar: [
    "textInput",
    "bold",
    "italic",
    "underline",
    "superscript",
    "subscript",
    "removeFormat",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "insertTable",
    "specialCharacters",
    "mathInput",
    "imageUpload",
    "|",
    "undo",
    "redo",
    "|",
    "alignment",
    "direction",
    // "direction:ltr",
    // "direction:rtl",
    "fontSize",
    "fontColor",
    "fontBackgroundColor",
  ],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  image: imageConfig,
  // languageDirection: languageDirection,
  link: link,
};

export const CKEDropDownInputTools = {
  removePlugins: removeExtraPlugins,
  toolbar: [
    "dropDownInput",
    "bold",
    "italic",
    "underline",
    "superscript",
    "subscript",
    "removeFormat",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "insertTable",
    "specialCharacters",
    "mathInput",
    "imageUpload",
    "|",
    "undo",
    "redo",
    "|",
    "alignment",
    "direction",
    // "direction:ltr",
    // "direction:rtl",
    "fontSize",
    "fontColor",
    "fontBackgroundColor",
  ],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  image: imageConfig,
  // languageDirection: languageDirection,
  link: link,
  bold: {
    tooltipPosition: "n",
  },
};
export const CKENotepadTools = {
  removePlugins: removeExtraPlugins,
  toolbar: [
    "bold",
    "numberedList",
    "alignment",
    "direction",
    // "direction:ltr",
    // "direction:rtl",
  ],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
  // languageDirection: languageDirection,
  pastePlainText: true,
};

export const rdsTableTools = {
  removePlugins: removeExtraPlugins,
  toolbar: [],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
};

export const rdsTableCellTools = {
  removePlugins: removeExtraPlugins,
  toolbar: [
    "bold",
    "italic",
    "mathInput",
    "alignment",
  ],
  simpleUpload: simpleUpload,
  fontSize: fontSize,
};

export const tscTableConfig = { 
  autoFocus: true,
  autoFocusAtEnd: true,
  toolbar: ['bold', 'italic', 'underline'],
}

