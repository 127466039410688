import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { RBDDraggableItem, getRenderItem } from './RBDDraggableItem'
import { Droppable } from 'react-beautiful-dnd'
import { getEmbeddedSelector, isMBIframeSelector, partnerNameSelector } from '/src/views/Auth/Login/selector';
import { useSelector } from 'react-redux';

const DroppableContainer = styled.div`
  // display: flex;
  padding: 5px;
  // width: 100%;
  // height: 100%;
  min-height: 50px;
  // transition: background-color 0.2s ease;
  background-color: ${props =>
    props.isDraggingOver 
      ? props.isMBIframe
        ? '#f0f6fe'
        : 'skyblue' 
      : props.darkBackground
        ? '#8b8b8b17'
        : 'white'

  };
  flex-grow: 1;
`

const DraggablePlaceholder = styled.div`
  display: none;
`

export const RBDDroppableArea = (props) => {
  const { draggableProps, droppableProps } = props;
  const renderItem = getRenderItem(draggableProps, droppableProps.list.items)
  // const partnerName = useSelector(partnerNameSelector())
  // const embedded = useSelector(getEmbeddedSelector());

  const isMBIframe = useSelector(isMBIframeSelector());


  return (
    <Droppable
      key={`droppable-${droppableProps.droppableId}`}
      {...droppableProps}
      droppableId={droppableProps.droppableId}
      direction={droppableProps.direction}
      // renderClone={renderItem}
      // getContainerForClone={() => }
    >
      {(provided, snapshot) => (
        <DroppableContainer
          key={`droppable-cotainer-${droppableProps.droppableId}`}
          ref={provided.innerRef}
          {...provided.droppableProps}
          {...droppableProps}
          isMBIframe={isMBIframe}
          isDraggingOver={snapshot.isDraggingOver}
        >
          {droppableProps.list.items && droppableProps.list.items.map((item, index) => (
            <RBDDraggableItem
              key={`rbd-draggable-item-${item.id}`}
              draggableProps={{
                ...draggableProps,
                item: item,
                index: index,
                draggableId: item.id,
              }}
            />
          ))}
          <DraggablePlaceholder>
            {provided.placeholder}
          </DraggablePlaceholder>
        </DroppableContainer>
      )}
    </Droppable>
  )
}
