import React, { useState } from "react";
import { Button, Modal, Tooltip, Row } from "antd";
import {
  UserAddOutlined,
  InfoOutlined,
  InfoCircleTwoTone,
  QuestionOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const SEBStartAlternativeModal = (props) => {

  const {startBtn} = props;
  const mrIntl =useTranslate();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };
  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Share
      </Button> */}
      <Tooltip title={mrIntl("SEBStartAlternativeModal.having_issues")}>
        {/* <Button shape="circle" icon={<InfoOutlined />} onClick={showModal}/> */}
        {/* <QuestionCircleOutlined  onClick={showModal}/> */}
        <Button shape="circle" icon={<QuestionOutlined/>} onClick={showModal}/>
      </Tooltip>
      <Modal
        title={mrIntl("SEBStartAlternativeModal.start_test")}
        open={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={500}
        wrapClassName="experience-info-modal height-centered-modal"
      >
        <Row>
          <h4><MrTranslate id={"SEBStartAlternativeModal.lockdown_app_issue_msg"}/></h4>
          {startBtn}
          {/* {item.created_by && <Tag>{item.created_by}</Tag>}
          {item.custom_fields.org_programme_item_label && <Tag>{item.custom_fields.org_programme_item_label}</Tag>}
          {item.custom_fields.subject_item_label && <Tag>{item.custom_fields.subject_item_label}</Tag>}
          {item.custom_fields.grade_item_label && <Tag>{item.custom_fields.grade_item_label}</Tag>}
          {item.settings.is_timed && <Tag>{item.settings.duration}</Tag>}
          {item.settings.start_at_datetime && <Tag>{dayjs(item.settings.start_at_datetime).format("MMM DD, YYYY")}</Tag>}
          {item.access_code && <Tooltip title="Click to copy Join Code"><Tag color="success" onClick={() => {navigator.clipboard.writeText(item.access_code); message.success("Join code copied!")}}>{item.access_code}</Tag></Tooltip>} */}
          {/* {item.settings.i_code && <Tag color="error">{item.settings.i_code}</Tag>} */}
          

          {/* <Card title="Test settings and publish"> */}
           
          {/* </Card> */}
        </Row>
      </Modal>
    </>
  );
};

export default SEBStartAlternativeModal;
