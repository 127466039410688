import { Table } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useState } from "react";

const LogsInTable = (props) => {
  console.log("LogsInTable ====>", props);
  const [paginationConfig, setPaginationConfig] = useState({pageSize: 50})
  const { data } = props
  let filteredData = data
  if(props.localSearchValue !== "") {
    filteredData = data.filter(item => {
      if((item.user_email && item.user_email.includes(props.localSearchValue)) || (item.user_ip && item.user_ip.includes(props.localSearchValue)) || 
        (item.msg && item.msg.includes(props.localSearchValue))) {
        return item
      }
    });
  }
  
  const finalGridColumns = []

  const logDateAndTime = {
    title: "Date And Time",
    dataIndex: "updated_at",
    key: "updated_at",
    width: 70,
    style: { cursor: "pointer" },
    render: (_, data, rowIndex) => {
      return <span>
        <span>{dayjs(data.created_at).format("DD-MM-YYYY")}</span><br/>
        <span className="log-time">{dayjs(data.created_at).format("hh:mm:ss a")}</span>
      </span>
    },
  }
  finalGridColumns.push(logDateAndTime)

  const userIp = {
    title: "User IP",
    dataIndex: "user_ip",
    key: "user_ip",
    width: 70,
    style: { cursor: "pointer" },
    render: (_, data, rowIndex) => {
      return data.user_ip
    },
  }
  finalGridColumns.push(userIp)

  const userEmail = {
    title: "User Email",
    dataIndex: "user_email",
    key: "user_email",
    width: 70,
    style: { cursor: "pointer" },
    render: (_, data, rowIndex) => {
      return data.user_email
    },
  }
  finalGridColumns.push(userEmail)

  const LogMessage = {
    title: "Message",
    dataIndex: "msg",
    key: "msg",
    width: 350,
    style: { cursor: "pointer" },
    render: (_, data, rowIndex) => {
      let messageToRender
      if(data.msg) {
        const finalMsg = data.msg
        if(data.values && data.values.hasOwnProperty('start_at_datetime')) {
          const newValue = dayjs(data.values.start_at_datetime).format(
            "MMM DD, hh:mm a"
          )
          // Add start_at_datetime in log message
          messageToRender = finalMsg.replace("{start_at_datetime_placeholder}", newValue)
        } else if(data.values && data.values.hasOwnProperty('close_at_datetime')) {
          const newValue = dayjs(data.values.close_at_datetime).format(
            "MMM DD, hh:mm a"
          )
          // Add close_at_datetime_placeholder in log message
          messageToRender = finalMsg.replace("{close_at_datetime_placeholder}", newValue)
        } else {
          messageToRender = finalMsg
        }

        if (data.log_type === "segment") {
          messageToRender = finalMsg + ` - for Assessment '${data.values.experience_name}'`
        } 
      }

      return <span style={{ whiteSpace: 'pre-wrap' }}>{messageToRender}</span>
    },
  }
  finalGridColumns.push(LogMessage)

  return <Table
    rowkey="id"
    dataSource={filteredData}
    columns={finalGridColumns}
    onChange={(pagination, filters, sorter, extra) => setPaginationConfig(pagination)}
    bordered
    size="large"
    showHeader={true}
    pagination={paginationConfig}
  />
}
export default LogsInTable;