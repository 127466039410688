import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { fetchTimeoutWrapper, getIpClient, getOKPingUrl } from "/src/lib/utils/helperMethods";
import axios from "axios";
import { appRegionSelector, currentUserSelector } from "/src/views/Auth/Login/selector";
import { experienceViewModeSelector } from "/src/views/Experiences/selector";
import { activeExperienceIdSelector, currentViewSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { message } from "/src/components/UI/AntdAppHelper";
import * as Sentry from "@sentry/react";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";

export const checkInternetConnectivity = (appRegion, onChangeCallback, mrIntl = () => {}) => {
  let connectionErrorMessage = mrIntl("CheckInternetConnectivity.it_seems_like_you_are_not_connected_to_the_internet")
  let internetStatus = "online"
  fetchTimeoutWrapper(8000, axios.get(getOKPingUrl(appRegion)))
    .then((response) => {
      if (response.data === "OK") {
        if (onChangeCallback) {
          onChangeCallback(internetStatus);
        }
      }
      console.log("CONNECTED TO INTERNET", response.data);
    })
    .catch((error) => {
      message.error(connectionErrorMessage)
      internetStatus = "offline"
      if(import.meta.env.VITE_MODE === "development"){
        internetStatus = "online"
      }
      if (onChangeCallback) {
        onChangeCallback(internetStatus);
      }
      Sentry.captureException(error)
    });
  // const isOnline = navigator.onLine;
  // // NOTE: Not checking browser online status as it is not reliable
  // if (isOnline) {
  // } else {
  //   message.error(`Navigator.onLine false ${JSON.stringify(navigator.onLine)}`, connectionErrorMessage)
  //   if(import.meta.env.VITE_MODE == "development"){
  //     if (onChangeCallback) {
  //       onChangeCallback("online");
  //     }
  //   }
  //   // if (onChangeCallback) {
  //   //   onChangeCallback("offline");
  //   // }
  // }
}

const overrideNavigatorOnline = (value) => {
  // APL-5761, In case of iPad in latest OS (iOS 17.6 onwards) AAC Mode security, navigator.onLine is always false, so we need to override it to support Wiris math
  try {
    console.log("overriding navigator online ==>", value);
    
    // Check if the property is already defined and configurable
    const descriptor = Object.getOwnPropertyDescriptor(window.navigator, 'onLine');
    
    if (!descriptor || descriptor.configurable) {
      Object.defineProperty(window.navigator, 'onLine', {
        get: function() {
          return value;
        },
        configurable: true // Set configurable to true to allow future redefinition
      });
    } else {
      console.log("navigator.onLine property is not configurable.");
    }
  } catch (error) {
    console.log("error in overriding navigator online ==>", error);
    Sentry.captureException(error);
  }
}

export const CheckInternetConnectivity = (props) => {
  console.log("Checkinternet conncetivity props ==>", props)
  
  const { withLog = false } = props
  const experienceId = useSelector(activeExperienceIdSelector)
  const currentUser = useSelector(currentUserSelector());
  const experienceViewMode = useSelector(experienceViewModeSelector());
  const currentView = useSelector(currentViewSelector)
  const appRegion = useSelector(appRegionSelector());
  const appType = useSelector(appTypeSelector());
  const dispatch = useDispatch();

  console.log("current user in check internet connectivity ==>", currentUser, withLog, experienceId, experienceViewMode, currentView)
  const [onlineStatus, setOnlineStatus] = useState('online')
  useEffect(() => {
    console.log('online status changing==>', onlineStatus);
    if (onlineStatus) {
      dispatch(experienceActions.setInternetStatusSuccess({ status: onlineStatus }));
      localStorage.setItem("internetStatus", onlineStatus)
      const shouldLog = withLog && experienceId && experienceViewMode === "apTakeTest" && currentView && currentView !== "submittedTest";
      if (shouldLog) {
        dispatch(firestoreInteractionActions.setUserInfo({experienceId: experienceId, userInfo: {onlineStatus: onlineStatus}}, {internetStatusChanged: true, log: {logging: true, action: 'online_status_change', current_value: {
          status: `${onlineStatus === "network_issue" ? "had connection issues" : onlineStatus === 'offline' ? "went offline" : "came online"}`
        }}}));
      }
      if (onlineStatus === "online" && appRegion !== "china") {
        getIpClient()
          .then((data) => {
            console.log("checking the ip==>", data);
            dispatch(loginActions.setUserIpSuccess({ ip: data }));
          })
          .catch((error) => {
            console.log("error in setting ip");
          });
      }
    }
  }, [onlineStatus])

  function onChangeCallback(onlineStatus) {
    setOnlineStatus(onlineStatus)
  }

  useEffect(() => {
    if (appType === "ios") {
      // Only doing once as system throws error if we try to override navigator.online multiple times
      const isOnline = onlineStatus === "online";
      overrideNavigatorOnline(isOnline)
    }
  }, [onlineStatus, appType]);

  useEffect(() => {
    let internetStatus;
    // TEMP dev only
    if (appType === "ios") {
      overrideNavigatorOnline(true)
    }

    let intervalDuration = onlineStatus === "online" ? 10000 : 2000
    let checkInternet = import.meta.env.VITE_MODE !== "development1" && setInterval(() => {
      if (window.stopCheckInternetConnectivity) {
        clearInterval(checkInternet);
      }
      // const isOnline = navigator.onLine;
      const config = {
        headers: {
          // "Access-Control-Expose-Headers": '*',
          // "access-control-expose-headers": '*',
          // cache: "no-store",
          // mode: "no-cors",
          // pragma: "no-cache",
          // "cache-control": "no-cache",
          // 'access-control-allow-methods': 'GET',
          // 'access-control-allow-origin': '*'
        },
      };
      
      // Now headers are set on file as meta-data

      // NOTE: Not checking browser online status as it is not reliable
      // if (isOnline || appType === "ios") {
        // Check for internet connectivity
        let pingRequestUrl = `${getOKPingUrl(appRegion)}`
        if(appType === "ios" || appType === "mac"){
          const timestamp = new Date().getTime()
          pingRequestUrl += `?cache_bust=${encodeURIComponent(timestamp)}`
        }

        // console.log("pingRequestUrl ==>", appType, pingRequestUrl)
        fetchTimeoutWrapper(
          8000,
          axios.get(pingRequestUrl,
            // config
          )
        )
          .then((response) => {
            console.log("getOKPingUrl response", response);
            if (response.data === "OK" && internetStatus !== "online") {
              internetStatus = "online";
              if (onChangeCallback) {
                onChangeCallback(internetStatus);
              }
            }

            // if (appType === "ios") {
            //   overrideNavigatorOnline(true)
            // }
            console.log("CONNECTED TO INTERNET", internetStatus);
          })
          .catch((error) => {
            if(error.message === "timeout") {
              internetStatus = "network_issue";
            } else {
              internetStatus = "offline";
              // if (appType === "ios") {
              //   overrideNavigatorOnline(false)
              // }
            }
            if (onChangeCallback) {
              onChangeCallback(internetStatus);
            }
            // if (error && internetStatus !== "network_issue") {
            //   internetStatus = "network_issue";
            //   if (onChangeCallback) {
            //     onChangeCallback(internetStatus);
            //   }
            // }
          });
      // } else {
      //   if (internetStatus !== "offline") {
      //     internetStatus = "offline";
      //     if (onChangeCallback) {
      //       onChangeCallback(internetStatus);
      //     }
      //   }
      //   console.log("You are OFFLINE", internetStatus);
      // }
    }, intervalDuration);

    return () => {
      clearInterval(checkInternet);
    };
  }, [appRegion, onlineStatus, appType]);

  return <></>;
};
