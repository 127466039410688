import React from 'react';
import { Col, Card, Space, Avatar, Button } from 'antd';
import { useTranslate } from '../MrTranslate/MrTranslate';
import { actions as orgActions } from '/src/views/Orgs/redux';
import { useDispatch } from 'react-redux';
import './DummyCardForUpgradePlan.scss';
import { getQBIcon } from '/src/lib/utils/helperMethods';

const DummyCardForUpgradePlan = () => {
  const mrIntl = useTranslate();
  const dispatch = useDispatch();
  return (
    <Col xs={24} sm={12} md={8} lg={6} className='upgrade-modal-dummy-card-wrapper'>
      <Card
        className="text-center cursor-pointer clickable-card qb-card upgrade-modal-dummy-card"
        hoverable={false}
      >
        <Space direction="vertical">
          <Avatar src={getQBIcon("ap_myp")}></Avatar>
          <h2 className="m-t-10">AP MYP</h2>
          <p className='upgrade-modal-dummy-card-text'>
            300+ Assessments
          </p>
        </Space>
      </Card>
      <Button
        type="primary"
        className='upgrade-modal-dummy-card-btn'
        onClick={() => {
          dispatch(
            orgActions.setShowUpgradePlanModalSuccess({
              show: true,
              feature_code: "library_explore_page",
            })
          );
        }}
      >
        {mrIntl("LibraryPage.upgrade_plan")}
      </Button>
    </Col>
  );
};

export default DummyCardForUpgradePlan;