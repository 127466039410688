import React, { useEffect, useState } from "react";

import { Tabs, Input, Radio, Button, Checkbox, Upload, Row, Col, Tooltip } from "antd";
import { InboxOutlined, UploadOutlined, SoundOutlined, PauseCircleOutlined, PlayCircleOutlined, StopOutlined, DeleteFilled } from '@ant-design/icons';
import { AddButton, DeleteButton, CheckBox, TabAddIcon, CustomLabel, CustomInput, InputArea, MCQArea, MCQList, TextArea, ShowText, ShowTextIndex, ShowTextContent, ShowTextFile, showConfirmModal } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { MrInput } from "mr_react_framework"
import { debounce } from 'lodash';
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import InsertFromURL from "/src/components/UI/Media/InsertFromURL";
import CKETextCaption from "/src/views/Segments/CKETextContent/CKETextCaption"
import MediaShow from "/src/components/UI/Media/MediaShow"
import { DraggableTabs } from "/src/components/UI/DnD/DraggableTabs"
import { AiOutlinePicture } from "react-icons/ai"
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const TabularAttachments = props => {
  console.log("Tabular Attachments props", props);

  const { createMode, attachments, resource_setting, onTabDragEnd, onTabEdit, onTextChanged, removeFile, openMediaModal, attachmentSettingComponentToRender, mediaShowSetting, setMediaShowSetting, tabTitleLength } = props
  
  const { TabPane } = Tabs;
  const mrIntl = useTranslate()
  const attachmentsCount = attachments.length
  const firstAttachment = attachments[0]
  const lastAttachment = attachments[attachmentsCount - 1] || {}
  const [tabSetting, setTabSetting] = useState({
    activeKey: firstAttachment && firstAttachment.id.toString(),
    currentAttachmentsCount: attachments.length,
  })

  const onTabChange = (targetKey) => {
    setTabSetting({
      ...tabSetting,
      activeKey: targetKey
    })
  }

  useEffect(() => {
    if (lastAttachment.id && attachmentsCount == 1) {
      setTabSetting({
        ...tabSetting,
        activeKey: lastAttachment.id.toString(),
        currentAttachmentsCount: attachmentsCount
      })
    }
  }, [lastAttachment.id])

  useEffect(() => {
    if (attachmentsCount > tabSetting.currentAttachmentsCount) {
      setTabSetting({
        ...tabSetting,
        activeKey: lastAttachment.id.toString(),
        currentAttachmentsCount: attachmentsCount
      })
    }
  }, [attachmentsCount])

  const onEdit = (targetKey, action) => {
    let index
    if (action == 'remove') {
      index = attachments.findIndex((attachment) => attachment.id == targetKey)
      let activeKey = ''
      if (index == attachmentsCount - 1) {
        if (index == 0) {
          activeKey = attachments[index].id
        } else {
          activeKey = attachments[index - 1].id
        }
      } else {
        activeKey = attachments[index + 1].id
      }

      showConfirmModal({
        // title: `Are you sure to remove this ${props.tabPrefix}?`,
        title: mrIntl("CommonText.confirm_delete"),
        onOk: () => {
          setTabSetting({
            ...tabSetting,
            activeKey: activeKey.toString(),
            currentAttachmentsCount: attachmentsCount - 1
          })
          onTabEdit(index, action)
        },
        mrIntl: mrIntl
      })
    } else {
      onTabEdit(index, action)
    }
  }

  const getDisplayTitile = (atc, index) => {
    const title = atc.title;
    const tabTitle = title ? (title.length > 10 ? `${title.substring(0, 10)}...` : title) : null;
    const displayTitle = tabTitleLength && tabTitle ? tabTitle : `${props.tabPrefix} ${index + 1}`;
    
    return displayTitle
  }

  console.log("tab setting before render ==>", tabSetting)
  return (
    <DraggableTabs
      addIcon={<TabAddIcon
        iconText={mrIntl("CommonText.add")}
      ></TabAddIcon>}
      hideAdd={(!createMode || props.hideAdd) ? true : false}
      type="editable-card"
      tabPosition={'top'}
      activeKey={tabSetting.activeKey}
      onEdit={onEdit}
      onChange={onTabChange}
      canDrag={createMode}
      order={attachments}
      setOrder={onTabDragEnd}
      className="tabular-resources"
    >
      {attachments.map((atc, index) => (
        <TabPane
          key={atc.id}
          tab={
            <Tooltip title={tabTitleLength ? atc.title : ""}>
              {getDisplayTitile(atc, index)}
            </Tooltip>
            }
          closable={(createMode && !props.hideClose) ? true : false}
          closeIcon={<DeleteFilled />}
        >
          {props.titleRequired && createMode
            ? <div>
              <div style={{
                display: 'flex',
              }}>
                <div style={{
                  flexGrow: 10,
                  "marginBottom": "20px"
                }}>
                  <CustomLabel key={`label-${atc.id}`}> {props.titleLabel || 'Title'} </CustomLabel>
                  <CustomInput
                    maxLength={50}
                    defaultValue={atc.title}
                    onChange={(data) => onTextChanged('title', index, data)}
                  ></CustomInput>
                  {atc.title && atc.title.length == 50 && <div style={{ color: '#ff4d4f' }}> Reached maximum allowed limit. </div>}
                </div>

                <Checkbox
                  style={{
                    flexGrow: 2,
                    // marginTop: '25px',
                    marginLeft: '20px',
                    alignItems: "center"
                  }}
                  defaultChecked={atc.caption ? true : false}
                  onChange={(e) => {
                    atc.include_caption = e.target.checked
                    onTextChanged('caption', index, '')
                  }}
                >{mrIntl("TabularAttachments.include_caption")}</Checkbox>
              </div>

              {atc.include_caption && <div>
                <CustomLabel key={`caption-${atc.id}`}> {props.captionLabel || 'Caption'} </CustomLabel>
                <TextArea>
                  <CKETextCaption
                    value={atc.caption}
                    onChange={(data) => onTextChanged('caption', index, data)}
                    files={atc.files}
                    removeFile={(e) => removeFile(index)}
                  ></CKETextCaption>
                  {openMediaModal && <Button
                    onClick={(e) => openMediaModal('image', index)}
                    // disabled={props.disabled}
                    type="primary"
                    shape="circle"
                    ghost
                  >
                    <AiOutlinePicture />
                  </Button>}
                </TextArea>
              </div>}
            </div>
            : <div>
              {/* {atc.title && <h4>{atc.title}</h4>} */}
              {/* This media show when file has children/dependent files */}
              {(atc.caption || atc.files) &&
                <ShowText >
                  <ShowTextContent dangerouslySetInnerHTML={{ __html: atc.caption }}
                  >
                  </ShowTextContent>
                  {atc.files && 
                    <ShowTextFile>
                      <MediaShow
                        file={atc.files[0]}
                      >
                      </MediaShow>
                    </ShowTextFile>
                  }
                </ShowText>}
            </div>}

          <MediaShow
            file={atc}
            mediaType={props.attachmentType}
            mediaShowSetting={mediaShowSetting}
						setMediaShowSetting={setMediaShowSetting}
          />
          {/* {attachmentSettingComponentToRender({
            snippets: atc.snippets || [{}]
          })} */}
        </TabPane>
      ))}
    </DraggableTabs >
  );
}
export default TabularAttachments;