import React, { useState, useEffect, useCallback } from "react";
import { Button, Space, Tooltip, TreeSelect } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import { ExperienceUserSelect } from "./ExperienceUserSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  activeExperienceUserIdSelector,
  activeExperienceUserSelector,
  allUserResponsesSelector,
  experienceUsersSelector,
  overallGradingFormVisibleSelector,
  studentNameFormatSelector,
} from "../selector";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { actions as userResponseReduxActions } from "/src/views/UserResponses/redux";
import ExperienceUserGradingProgressBar from "./ExperienceUserGradingProgressBar";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { forceHideAnnotation } from "/src/lib/utils/helperMethods";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { activeAdjustedExperienceSelector } from "../../Experiences/selector";

export const ExperienceUserSwitcher = (props) => {
  console.log("ExperienceUserSwitcher==>", props);
  const { checkIfLoadingCompleted, gradingConfig = {}, showReviewedByTitle } = props
  const mrIntl = useTranslate();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);

  const experienceUsers = useSelector(experienceUsersSelector());
  const activeExperienceUserId = useSelector(activeExperienceUserIdSelector());
  const activeExperienceUser = useSelector(activeExperienceUserSelector());
  const overallGradingFormVisible = useSelector(overallGradingFormVisibleSelector())
  const studentNameFormat = useSelector(studentNameFormatSelector());
 
  const currentUser = useSelector(currentUserSelector());
  const experience = useSelector(activeAdjustedExperienceSelector())
  const isStudent =
    currentUser.role == "student" || currentUser.role == "parent"
      ? true
      : false;

  const isStudentReviewerMode = experience.student_reviewer_mode
  const isPeerReviewEnabled = experience.settings.peer_review_enabled ? true : false;

  const selectedExperienceUserId = 
    isStudentReviewerMode || !activeExperienceUser.dup_from_id
    ? activeExperienceUserId
    : activeExperienceUser.dup_from_id
  
  const euSelectOptions = []
  const reviewerSelectOptions = []

  experienceUsers.forEach(eu => {  
    if (!eu.dup_from_id || isStudentReviewerMode) {
      const showName = 
        gradingConfig.anonymousGradingEnabled 
          ? eu.custom_fields["anonymous_name"]
          : studentNameFormat === "last_name" 
            ? eu.name_by_last_name
            : eu.name;
            
      const label = (
        <span>
          <ExperienceUserGradingProgressBar item={eu} width={70}/>
            <span className="experience-user-name">
              {showName.length > 12 
                ? <span>{showName.substring(0, 12)}...</span> 
                : showName}
            </span>
        </span>
      )
      euSelectOptions.push({label: label, value: eu.id})
    }
  });

  if (isPeerReviewEnabled) {
    const reviewers = experienceUsers.filter(eu => eu.user_id === activeExperienceUser.user_id);
    reviewers.forEach(eu => {
      const showName = 
        gradingConfig.anonymousGradingEnabled 
          ? eu.custom_fields["reviewer_anonymous_name"]
          : studentNameFormat === "last_name" 
            ? eu.custom_fields["reviewer_name_by_last_name"]
            : eu.custom_fields["reviewer_name"];

      reviewerSelectOptions.push({ label: showName || "Teacher", value: eu.id })
    })
  }
  
  const setURLWithExperienceUserId = (eu_id) => {
    // View feedback is not grade tab so wrong url updating
    if (gradingConfig.gradingEnabled) {
      history.push(`${url}/${eu_id}${location.search}`); 
    }
  };

  const onKeyDown = (event) => {
    const target = event.target;
    console.log(
      "on key down event ==>",
      event,
      target,
      target.nodeName,
      target.contentEditable
    );
    // if (
    //   target &&
    //   target.nodeName != "INPUT" &&
    //   target.nodeName != "TEXTAREA" &&
    //   target.contentEditable != "true"
    // ) {
      // setPressedEvent(event);
      // setTriggerSwitchExperienceUser(event.timeStamp);
    // }
    if (event && event.shiftKey) {
      if (event.key == "ArrowUp") {
        event.preventDefault()
        goToExperienceUser("prev");
      } else if (event.key === "ArrowDown") {
        event.preventDefault()
        goToExperienceUser("next");
      }
    }
  };

  // useEffect(() => {
  //   console.log(
  //     "useEffect triggerSwitchQuestion ==>",
  //     pressedEvent,
  //     triggerSwitchQuestion
  //   );
  //   if (pressedEvent && pressedEvent.shiftKey && pressedEvent.key == "ArrowUp") {
  //     goToExperienceUser("previous");
  //   } else if (pressedEvent && pressedEvent.shiftKey && pressedEvent.key === "ArrowDown") {
  //     goToExperienceUser("next");
  //   }
  // }, [triggerSwitchExperienceUser]);

  // useEffect(() => {
  //   document.addEventListener("keydown", onKeyDown, false);

  //   return () => {
  //     document.removeEventListener("keydown", onKeyDown, false);
  //   };
  // }, []);

  // useEffect(() => {
  //   setTempVisible(true)
  //   setTimeout(() => {
  //     setTempVisible(false)
  //   }, 3000);
  // }, []);

  useEffect(() => {
    if(!overallGradingFormVisible) {
      let index = euSelectOptions.findIndex((option) => option.value === selectedExperienceUserId);
      setDisablePrev(index === 0);
      setDisableNext(index === euSelectOptions.length - 1);
  
      document.addEventListener("keydown", onKeyDown, false);
  
      return () => {
        document.removeEventListener("keydown", onKeyDown, false);
      };
    }
  }, [selectedExperienceUserId, overallGradingFormVisible]);


  const goToExperienceUser = (direction = "next", eu_id = null) => {
    checkIfLoadingCompleted(() => {
      let nextExperienceUserId = eu_id;
      if (nextExperienceUserId) {
        dispatch(
          experienceUsersActions.setActiveExperienceUserIdSuccess({
            id: nextExperienceUserId,
          })
        );
      } else {
        console.log("goToExperienceUser==>", direction, activeExperienceUserId, euSelectOptions);
        let index = euSelectOptions.findIndex(
          (option) => option.value === selectedExperienceUserId
        );
        let newIndex = direction === "next" ? index + 1 : index - 1;
        const nextSelectedOption = euSelectOptions[newIndex];

        if (nextSelectedOption) {
          nextExperienceUserId = nextSelectedOption.value;
          dispatch(
            experienceUsersActions.setActiveExperienceUserIdSuccess({
              id: nextExperienceUserId,
            })
          );
        }
      }

      if (nextExperienceUserId) {
        const selectedIds = getSurroundingIds(nextExperienceUserId);
        dispatch(userResponseReduxActions.fetchMissing({ requestedExperienceUserIds: selectedIds }));
      }

      // Doing this because when we close the modal then the text annotation popup don't close sometime
      forceHideAnnotation()

      // Remove focus from experience user switcher. Doing this because when we switch the question and EU with arrow keys then switcher automatically open.
      document.activeElement.blur();

      if (nextExperienceUserId) {
        setURLWithExperienceUserId(nextExperienceUserId);
      }
      // if (nextExperienceUserId) {
      //   const urlArray = url.split('/');
      //   urlArray.pop();
      //   urlArray.push(nextExperienceUserId);
      //   const newUrl = urlArray.join('/');
      //   history.push(newUrl);
      // }
      /* do this with query params */
    })
  };  

  // const goToUngraded = (direction = "next") => {
  //   let ungradedURs = activeEUUserResponses.filter((ur) => !ur.marked);

  //   let index = ungradedURs.findIndex(
  //     (ur) => ur.segment_id === activeSegmentId
  //   );
  //   let newIndex = direction === "next" ? index + 1 : index - 1;
  //   const nextUngraded = ungradedURs[newIndex];

  //   if (nextUngraded) {
  //     dispatch(
  //       segmentActions.setActiveIdSuccess({ id: nextUngraded.segment_id })
  //     );

  //     setButtonsDisableStatus({
  //       ...buttonsDisableStatus,
  //       prevUngradedQuestion: newIndex === 0,
  //       nextUngradedQuestion: newIndex == ungradedURs.length - 1,
  //     });
  //   }
  // };

  function getSurroundingIds(id) {
    const index = experienceUsers.findIndex(eu => eu.id === id);
    const start = Math.max(0, index - 5);
    const end = Math.min(experienceUsers.length - 1, index + 5);
    return experienceUsers.slice(start, end + 1).map(eu => parseInt(eu.id));
  }
  
  const experienceUserSelect = (
    <ExperienceUserSelect
      dropdownMatchSelectWidth={false}
      value={selectedExperienceUserId}
      onChange={(eu_id) => {
        goToExperienceUser(null, eu_id);
      }}
      config={{
        widgetConfig: {
          style: { width: "200px" },
          options: euSelectOptions,
          // bordered: false,
        },
      }}
      getPopupContainer={(triggerNode) => {
        const parentContainer = document.querySelector(".experience-user-switcher-select")
        // container.parentNode
        return parentContainer;
      }}
      className={"experience-user-switcher-select"}
    ></ExperienceUserSelect>
  );

  const experienceReviewerSelect = (
    <ExperienceUserSelect
      dropdownMatchSelectWidth={false}
      value={activeExperienceUserId}
      onChange={(eu_id) => {
        goToExperienceUser(null, eu_id);
      }}
      config={{
        widgetConfig: {
          style: { width: "200px" },
          options: reviewerSelectOptions,
          // bordered: false,
        },
      }}
      getPopupContainer={(triggerNode) => {
        const parentContainer = document.querySelector(".experience-user-switcher-select")
        // container.parentNode
        return parentContainer;
      }}
      className={"experience-user-switcher-select"}
    ></ExperienceUserSelect>
  );

  return (
    <>
      {(!isStudent || isStudentReviewerMode) &&
        <Space className="experience-user-switcher-wrapper">
          <Tooltip title={mrIntl("ExperienceUserSwitcher.previous_student")}>
            <Button
              shape={"circle"}
              disabled={disablePrev}
              onClick={() => goToExperienceUser("prev")}
            >
              <UpOutlined />
              {/* {gradingConfig.gradingMode === "quick" ? ` Previous` : ""} */}
              {/* <LeftOutlined /> Previous */}
            </Button>
          </Tooltip>
          <Tooltip
            title={mrIntl("ExperienceUserSwitcher.select_student")}
            // visible={tempVisible}
            open={false}
            placement="right"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            {experienceUserSelect}
          </Tooltip>
          <Tooltip title={mrIntl("ExperienceUserSwitcher.next_student")}>
            <Button
              shape={"circle"}
              disabled={disableNext}
              type="primary"
              onClick={() => goToExperienceUser("next")}
            >
              {/* {gradingConfig.gradingMode === "quick" ? `Next ` : ""} */}
              <DownOutlined />
            </Button>
          </Tooltip>
        </Space>}

      {isPeerReviewEnabled && 
      (!isStudent || showReviewedByTitle) &&
      reviewerSelectOptions.length > 0 && 
        <Space className="reviewed-by-dropdown">
          <span>Reviewed by: </span>
          {experienceReviewerSelect}
        </Space>
      }
    </>
  );
};
