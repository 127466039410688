import React from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { Button, Modal, Row, Tag, Col } from "antd";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const StudentCodeAckModal = (props) => {
  const { url, path } = useRouteMatch();
  const location = useLocation();
  const { sCode, setShowStudentCodeAckModal, showStudentCodeAckModal } = props;
  console.log("StudentCodeAckModal props", sCode, url, path, location);
  const mrIntl = useTranslate()
  // const showModal = () => {
  //   setShowStudentCodeAckModal(true);
  // };

  const handleOk = (e) => {
    setShowStudentCodeAckModal(false);
  };

  const handleCancel = (e) => {
    setShowStudentCodeAckModal(false);
  };

  return (
    <>
      <Modal
        title= {mrIntl("StudentCodeAckModal.please_note_your_student_code")}
        open={showStudentCodeAckModal}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
        wrapClassName="qr-modal height-centered-modal"
        closable={false}
        maskClosable={false}
        keyboard={false}
      >
        <Row className={"text-center"}>
          <Col span={22} offset={1}>
            {/* {sCode} */}
            <b><MrTranslate id={"StudentCodeAckModal.your_student_code"}/></b>
            <br></br>
            <br></br>
            <Tag size={"large"} color="processing">
              {sCode}
            </Tag>
            <br></br>
            <br></br>
            <p>
              <MrTranslate id={"StudentCodeAckModal.this_code_will_needed_resume_this_test"}/>
            </p>
            <br></br>
            <Button
              type="primary"
              shape={"round"}
              onClick={() => {
                setShowStudentCodeAckModal(false);
              }}
            >
              <MrTranslate id={"StudentCodeAckModal.i_wrote_it_down_continue"}/>
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default StudentCodeAckModal;
