import React, { useEffect, useState } from "react";
import { Statistic, Card, Row, Col, Alert, Tag, List } from "antd";
import _ from "lodash";
import Modal from "antd/lib/modal/Modal";
import { checkMob } from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const StudentsNotJoinedModal = (props) => {
  const { listData, mappedStudents = [], filterParams } = props;
  const [visible, setVisible] = useState(false);
  const isMobile = checkMob();
  const mrIntl = useTranslate();
  const [filteredMappedStudents, setFilteredMappedStudents] = useState(mappedStudents);
  const [unjoinedStudents, setUnjoinedStudents] = useState([]);
  // actually unstartedStudents not unjoinedStudents

  useEffect(() => {
    let filteredMappedStudentsTemp = [];
    if (filterParams.group_id) {
      mappedStudents.forEach((student, i) => {
        let group_ids = filterParams.group_id[1];
        // if student.group_users_ids don't have group_ids, then false
        if (_.intersection(student.group_student_ids, group_ids).length > 0) {
          filteredMappedStudentsTemp.push(student);
        }
      });
    } else {
      filteredMappedStudentsTemp = mappedStudents;
    }
    setFilteredMappedStudents(filteredMappedStudentsTemp);
  }, [filterParams.group_id]);

  useEffect(() => {
    // calculate mapped - list and based on that show warning or successs alert
    let unjoinedStudentsTemp = [];
    filteredMappedStudents.forEach((student, i) => {
      let checkPresence = true;
      // console.log("StudentsNotJoinedModal", student, filterParams)
      // if(filterParams.group_id){
      //   let group_ids = filterParams.group_id[1]
      //   // if student.group_users_ids don't have group_ids, then false
      //   if(_.intersection(student.group_student_ids, group_ids).length == 0){
      //     checkPresence = false
      //   }
      // }
      if (checkPresence) {
        let isPresent = listData.find(
          (item) => student.id === parseInt(item.uid)
        );
        console.log("isPresent", isPresent, student);
        let tempObj = {};
        if (!isPresent) {
          // not joined
          tempObj = {
            student: student,
            currentViewStatus: "Not joined",
          };
          unjoinedStudentsTemp.push(tempObj);
        } else {
          if (
            isPresent.currentView === "joined" ||
            isPresent.currentView === ""
          ) {
            // joined but not started
            tempObj = {
              student: student,
              currentViewStatus: "Not started but joined",
            };
            unjoinedStudentsTemp.push(tempObj);
          }
        }
      }
    });

    unjoinedStudentsTemp = _.sortBy(unjoinedStudentsTemp, [
      "currentViewStatus",
    ]);
    if (unjoinedStudentsTemp !== unjoinedStudents) {
      setUnjoinedStudents(unjoinedStudentsTemp);
    }
    console.log(
      "filteredMappedStudents unjoinedStudents",
      filteredMappedStudents,
      listData,
      unjoinedStudents
    );
  }, [listData, filteredMappedStudents]);

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = (e) => {
    setVisible(false);
  };
  const handleCancel = (e) => {
    setVisible(false);
  };
  const filteredMappedStudentsMsg = {unjoinedStudents: unjoinedStudents?.length, filteredMappedStudents: filteredMappedStudents?.length}
  return (
    <>
      {filteredMappedStudents?.length !== 0 ? (
        unjoinedStudents?.length !== 0 ? (
          <Col span={isMobile ? 22 : 12} offset={isMobile ? 1 : 6}>
            {/* <Alert className={"cursor-pointer text-center"} message={`${unjoinedStudents.length}/${filteredMappedStudents.length} mapped student${unjoinedStudents.length > 1 ? "s have" : " has"} not started yet. Click to view`} type="warning" showIcon onClick={showModal} /> */}
            <Alert
              className={"cursor-pointer text-center"}
              // message={`${unjoinedStudents.length} mapped student${
              //   unjoinedStudents.length > 1
              //     ? `s (out of ${filteredMappedStudents.length}) have`
              //     : ` (out of ${filteredMappedStudents.length}) has`
              // } not started yet. Click to view`}
              message= {unjoinedStudents.length > 1 ? 
                mrIntl("StatisticsData.mapped_students_have_not_started", filteredMappedStudentsMsg) :
                mrIntl("StatisticsData.mapped_student_has_not_started", filteredMappedStudentsMsg)
              }
              type="warning"
              showIcon
              onClick={showModal}
            />
          </Col>
        ) : (
          <Col span={isMobile ? 22 : 8} offset={isMobile ? 1 : 8}>
            <Alert
              className={"text-center"}
              message={mrIntl("StatisticData.all_mapped_students_have_started_msg")}
              type="success"
              showIcon
            />
          </Col>
        )
      ) : (
        ""
      )}
      <Modal
        title= {mrIntl("StatisticsData.students_who_have_not_started_yet")}
        // title="Students who have not started yet"
        open={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        wrapClassName="experience-info-modal height-centered-modal"
        bodyStyle={{ overflowY: "auto" }}
      >
        <List
          // className="m-t-25"
          dataSource={unjoinedStudents}
          renderItem={(item, i) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <b>
                    {item.student && item.student.first_name}{" "}
                    {item.student && item.student.last_name}
                  </b>
                }
              />
              <Tag
                color={
                  item.currentViewStatus === "Not joined" ? "error" : "warning"
                }
              >
                {item.currentViewStatus}
              </Tag>
              <Tag>{item.student && item.student.s_code}</Tag>
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

const StatisticData = ({ listData, experience, filterParams }) => {
  console.log(
    "StatisticData props",
    listData,
    experience.students,
    filterParams
  );
  // using listData instead of calling data again - but for this made status filter local filter - and group filter automatically works and changes stats
  // const [experienceData, setExperienceData] = useState(listData);
  const [totalStarted, setTotalStarted] = useState([]);
  const [totalEnded, setTotalEnded] = useState([]);
  const [totalSubmitted, setTotalSubmitted] = useState([]);
  const mrIntl = useTranslate();

  useEffect(() => {
    let totalStartedCount = 0;
    let totalEndedCount = 0;
    let totalSubmittedCount = 0;
    if (listData.length !== 0) {
      listData.forEach((item) => {
        if (item.currentView === "startTest") {
          totalStartedCount += 1;
        } else if (item.currentView === "endTest") {
          totalEndedCount += 1;
        } else if (item.currentView === "submittedTest") {
          totalSubmittedCount += 1;
        }
      });
    }
    if (totalStartedCount !== totalStarted) {
      setTotalStarted(totalStartedCount);
    }
    if (totalEndedCount !== totalEnded) {
      setTotalEnded(totalEndedCount);
    }
    if (totalSubmittedCount !== totalSubmitted) {
      setTotalSubmitted(totalSubmittedCount);
    }

    console.log("listData", listData, totalStarted, totalEnded, totalSubmitted);

    // setExperienceData(listData)
  }, [listData, totalStarted, totalEnded, totalSubmitted]);

  // console.log("experienceData", experienceData, totalStarted, totalEnded, totalSubmitted)

  return (
    <>
      {/* {experienceData.length !== 0 && JSON.stringify(experienceData)} */}
      <div className="statistic-data">
        <Row>
          <StudentsNotJoinedModal
            listData={listData}
            mappedStudents={experience.students}
            filterParams={filterParams}
          />
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Card>
              <Statistic
                  title={mrIntl("StatisticsData.all")}
                // title="All"
                value={listData.length}
                // precision={2}
                valueStyle={{ color: "#3f8600", textAlign: "center" }}
                // prefix={<ArrowUpOutlined />}
                // suffix="%"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
               title={mrIntl("StatisticsData.started")}
                // title="Started"
                value={totalStarted}
                // precision={2}
                valueStyle={{ color: "#3f8600", textAlign: "center" }}
                // prefix={<ArrowUpOutlined />}
                // suffix="%"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title={mrIntl("StatisticsData.ended")}
                // title="Ended"
                value={totalEnded}
                // precision={2}
                valueStyle={{ color: "#3f8600", textAlign: "center" }}
                // prefix={<ArrowUpOutlined />}
                // suffix="%"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title={mrIntl("StatisticsData.submitted")}
                // title="Submitted"
                value={totalSubmitted}
                // precision={2}
                valueStyle={{ color: "#3f8600", textAlign: "center" }}
                // prefix={<ArrowUpOutlined />}
                // suffix="%"
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default StatisticData;
