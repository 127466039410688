import React, { memo, useEffect, useState } from "react";
import { List, Tag, Tooltip, Avatar, Space, Progress, Spin } from "antd";
import { useRouteMatch, useHistory } from "react-router-dom";
// import '../Groups.scss'
import dayjs from "dayjs";
import {
  CheckOutlined,
  MinusCircleTwoTone,
  SyncOutlined,
  CheckSquareTwoTone,
  FileOutlined,
  ClockCircleOutlined,
  CheckCircleTwoTone,
  LoadingOutlined,
  StarFilled
} from "@ant-design/icons";
import _ from "lodash";
import { displayPoints } from "/src/lib/utils/helperMethods";
import ExperienceUserActions from "../ExperienceUserItemDetail/ExperienceUserActions";
// import ExperienceUserGradingProgressBar from "../ExperienceUserItemDetail/ExperienceUserGradingProgressBar";
import { useSelector } from "react-redux";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { publishResultsExperienceUsersLoadingSelector } from "../selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ExperienceUserListItem = (props) => {
  const {
    item,
    // experience,
    // deleted,
    // permission,
    studentNameFormat,
    // currentUser,
    // isAllowStudentUploadFiles,
    // isMYPAchievementLevel,
    // setGradingConfig,
    gradingConfig,
    rubricType,
    // isMBLinked,
    exportModalConfig,
    setExportModalConfig,
    updateResource,
    reviewerColumn,
  } = props;
  console.log("ExperienceUserListItem ==>", props);
  // const { path, url } = useRouteMatch();
  // const history = useHistory();
  const experience = useSelector(activeAdjustedExperienceSelector())
  const currentUser = useSelector(currentUserSelector())
  const publishResultsLoading = useSelector(publishResultsExperienceUsersLoadingSelector())
  const mrIntl = useTranslate()

  const experienceSettings = experience.settings || {}
  const isMBLinked = experience.is_mb_linked
  const isStudent =
    currentUser.role == "student" || currentUser.role == "parent"
      ? true
      : false;
  const isCommentsOnly = rubricType === "comments_only" ? true : false;
  const isCustomRubric = rubricType === "custom" ? true : false;

  
  
  let isReviewer = false
  if (experienceSettings.peer_review_enabled && item.dup_from_id && item.review_by_user_id) {
    isReviewer = true
  }

  const showReviewerName = reviewerColumn && !isStudent // !isStudent check when student is grading then we want to show students name whom he is grading
  const showMetaAndActions = (!reviewerColumn && !isReviewer) || (isStudent)
  console.log("showReviewerName ===>", showReviewerName)
  // const maxLength = isStudent ? 20 : 14;
  const anonymousGradingEnabled = gradingConfig.anonymousGradingEnabled;
  const itemName = 
    anonymousGradingEnabled
      ? showReviewerName 
        ? item.custom_fields["reviewer_anonymous_name"] || "Teacher"
        : isReviewer && !isStudent
          ? ""
          : item.custom_fields["anonymous_name"] 
      : showReviewerName
        ? studentNameFormat === "last_name" 
          ? item.custom_fields["reviewer_name_by_last_name"] || "Teacher"
          : item.custom_fields["reviewer_name"] || "Teacher"
        : isReviewer && !isStudent
          ? ""
          : studentNameFormat === "last_name" 
            ? item.name_by_last_name
            : item.name;

  const itemCreatedAt = dayjs(item.created_at).format("MMM DD, HH:mm:ss a")

  console.log("item name ===>", itemName)
  // const experienceSettings = experience.settings
  // const isAllowStudentUploadFiles =
  // experienceSettings.allow_student_upload_files;

  let studentName = 
    <span className={item.custom_fields.is_late ? "c-orange-red" : ""}>
      {itemName && itemName.length > 15 ? (
        <Tooltip title={itemName} placement="right">
          {itemName && itemName.substring(0, 15)}...
        </Tooltip>
      ) : (
        itemName
      )}
      {item.is_guest_submission && !reviewerColumn && !item.dup_from_id
        ? " " + mrIntl("ExperienceUserListItem.guest") 
        : ""
      }
    </span>

  // if (import.meta.env.VITE_MODE === "development" && item.s_code) {
  //   studentName = <>{studentName} <span>{item.s_code}</span></>
  // }

  const title = []
  if (showMetaAndActions) {
    title.push(
      <Tooltip
        title={item.marked == true ? mrIntl("ExperienceUserListItem.grades_published") : mrIntl("ExperienceUserListItem.not_yet_published")}
      >
        <span style={{ marginRight: "5px", marginLeft: isReviewer && "15px" }}>
          {item.loading || publishResultsLoading ? (
            <LoadingOutlined spin />
          ) : item.marked ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <MinusCircleTwoTone twoToneColor="#eb2f96" />
          )}
        </span>
      </Tooltip>
    );
  }
  if (item.custom_fields?.is_seed) {
    title.push(
      <Tooltip title={mrIntl("ExperienceUserListItem.marked_as_seed")}>
        <span style={{ marginRight: "5px"}}>
          <StarFilled style={{ color: "gold" }} />
        </span>
      </Tooltip>
    )
  }
  title.push(studentName)

  // Not using anymore
  // const description = (
  //   <Space direction="horizontal" className="description">
  //     {!isStudent && isMBLinked && (
  //       <Tooltip
  //         title={
  //           item.custom_fields.mb_post_status === "completed"
  //             ? `Sent to MB successfully`
  //             : item.custom_fields.mb_post_status === "in_progress"
  //             ? "Sending to MB in progress"
  //             : item.custom_fields.mb_post_status === "failed"
  //             ? "Sending to MB failed"
  //             : ""
  //         }
  //         placement="bottom"
  //       >
  //         <Tag
  //           color={
  //             item.custom_fields.mb_post_status === "completed"
  //               ? "processing"
  //               : item.custom_fields.mb_post_status === "in_progress"
  //               ? "warning"
  //               : item.custom_fields.mb_post_status === "failed"
  //               ? "error"
  //               : ""
  //           }
  //         >
  //           MB
  //         </Tag>
  //         {/* <img
  //               alt="MB-logo"
  //               style={{ width: "16px", marginTop: "-4px" }}
  //               src={MBIcon}
  //             ></img> */}
  //       </Tooltip>
  //     )}
  //     <Tooltip
  //       title={item.marked == true ? "Grades published" : "Not yet published"}
  //     >
  //       <span style={{ marginTop: "3px", fontSize: "23px" }}>
  //         {item.marked == true ? (
  //           <CheckSquareTwoTone twoToneColor="#52c41a" />
  //         ) : (
  //           <MinusCircleTwoTone twoToneColor="#eb2f96" />
  //         )}
  //       </span>
  //     </Tooltip>
  //     <span className={item.custom_fields.is_late ? "c-orange-red" : ""}>
  //       {dayjs(item.created_at).format("MMM DD, HH:mm:ss")}
  //     </span>

  //     {/* {isStudent && item.marked && item.comments && (
  //       <span>
  //         Teacher's comments:
  //         <div
  //           style={{ fontWeight: "800" }}
  //           dangerouslySetInnerHTML={{ __html: item.comments }}
  //         ></div>
  //       </span>
  //     )} */}
  //     {/* {!isStudent && isAllowStudentUploadFiles && (
  //       <Tooltip title="Total uploads" placement="bottom">
  //         <Tag>
  //           <FileOutlined /> {item.attachments_count} files
  //         </Tag>
  //       </Tooltip>
  //     )} */}
  //   </Space>
  // );

  const rightSideMeta = (
    <Space direction="horizontal" className="description" gap={5}>
      {!isStudent && isMBLinked && (
        <Tooltip
          title={
            item.custom_fields.mb_post_status === "completed"
              ? mrIntl("ExperienceUserListItem.sent_to_mb_successfully")
              : item.custom_fields.mb_post_status === "in_progress"
              ? mrIntl("ExperienceUserListItem.sending_to_mb_in_progress")
              : item.custom_fields.mb_post_status === "failed"
              ? mrIntl("ExperienceUserListItem.sending_to_mb_failed")
              : ""
          }
          placement="bottom"
        >
          <Tag
            color={
              item.custom_fields.mb_post_status === "completed"
                ? "processing"
                : item.custom_fields.mb_post_status === "in_progress"
                ? "warning"
                : item.custom_fields.mb_post_status === "failed"
                ? "error"
                : ""
            }
          >
            {mrIntl("ExperienceUserListItem.mb")}
          </Tag>
        </Tooltip>
      )}

      <Tooltip title={mrIntl("ExperienceUserListItem.submitted_on", { itemCreated: itemCreatedAt})}>
        <ClockCircleOutlined style={{ color: item.custom_fields.is_late ? "orangered" : "#ccc" }} />
      </Tooltip>
      {/* <span className={item.custom_fields.is_late ? "c-orange-red" : ""}>
        {dayjs(item.created_at).format("MMM DD, HH:mm:ss ")}
      </span> */}

      {/* Hide - When creating reviewers, do not allow the action to reset the submission */}
      {!gradingConfig.assignReviewersEnabled ?
        <ExperienceUserActions
          experienceUser={item}
          experience={experience}
          currentUser={currentUser}
          gradingConfig={gradingConfig}
          exportModalConfig={exportModalConfig}
          setExportModalConfig={setExportModalConfig}
          updateResource={updateResource}
          showPublishButton={true}
        ></ExperienceUserActions>
        : null}

    </Space>
  )

  // let criteriaPointsView = [];
  // item.submission_criterium_associations_attributes &&
  //   item.submission_criterium_associations_attributes.map((ca, i) => {
  //     isStudent &&
  //       criteriaPointsView.push(
  //         <Tag style={{ fontWeight: "800" }}>
  //           {ca.custom_fields.title} {displayPoints(ca.points)}/
  //           {displayPoints(ca.custom_fields.max_points)}
  //         </Tag>
  //       );
  //   });
  // if (isStudent || (!isStudent && !isMYPAchievementLevel && !isCustomRubric)) {
  //   criteriaPointsView.push(
  //     <Tag style={{ fontWeight: "800" }}>
  //       {isStudent ? "Total" : ""} {displayPoints(item.points)}/
  //       {displayPoints(item.custom_fields.experience_points)}
  //     </Tag>
  //   );
  // }

  // const actions = [];

  const listItem = (
    <List.Item
      key={`student-${item.id}`}
      // actions={actions}
      // actions={["title", "shikhar"]}
      onClick={(event) => {
        // setGradingConfig((gradingConfig) => {
        //   return {
        //     ...gradingConfig,
        //     showStudentsList:
        //       gradingConfig.gradingMode == "quick" ? true : false,
        //   };
        // });
        // history.push(`${url}/${item.id}`)
      }}
      className={"full-clickable-list-item"}
    >
      <List.Item.Meta
        // avatar={
        //   <Avatar size="large" className="grading-progress-avatar">
        //     {!isMYPAchievementLevel && !isCustomRubric && experienceUserGradingProgressBar}
        //   </Avatar>
        // }
        title={title}
        // description={description}
      />
      {/* <Space className="experience-extra-info"> */}
      {/* <Tag>{item.s_code}</Tag> */}

      {/* TODO: add these to student side header */}
      {/* {isStudent && !item.marked && (
        <Tooltip
          title={`Submitted on ${dayjs(item.created_at).format(
            "MMM DD, HH:mm a"
          )}`}
        >
          <Tag color="processing" icon={<CheckOutlined />}>
            Submitted
          </Tag>
        </Tooltip>
      )}

      {isStudent && !item.marked && (
        <Tag color="warning" icon={<SyncOutlined spin />}>
          Awaiting grading
        </Tag>
      )} */}

      {/* TODO: Impliment same condition in Overall column of ExperienceUsersList - SHIKHAR & UTTAM*/}
      {/* {
        (!isStudent || (isStudent && item.marked == true)) &&
          (item.submission_criterium_associations_attributes &&
          item.submission_criterium_associations_attributes.length == 0
            ? // show the marks for selected item/student only by request feture APL-1314
              (gradingConfig.showAllMarks == true ||
                selectedExperienceUserId == item.id ||
                true) &&
              !isCommentsOnly &&
              !isCustomRubric && (
                <Tag color="processing">
                  {displayPoints(item.points)}/
                  {displayPoints(item.custom_fields.experience_points)}
                </Tag>
              )
            : (gradingConfig.showAllMarks == true ||
                selectedExperienceUserId == item.id ||
                true) &&
              criteriaPointsView) // show the marks for selected item/student only by request feture APL-1314
      } */}

      {/* {isStudent && isAllowStudentUploadFiles && (
        <Tooltip title="Total uploads" placement="bottom">
          <Tag>
            <FileOutlined /> {item.attachments_count}
          </Tag>
        </Tooltip>
      )} */}

      {showMetaAndActions ? rightSideMeta : null}

      {/* <ExperienceUserActions
        experienceUser={item}
        experience={experience}
        currentUser={currentUser}
        gradingConfig={gradingConfig}
        exportModalConfig={exportModalConfig}
        setExportModalConfig={setExportModalConfig}
      ></ExperienceUserActions> */}


      {/* TODO add some of these to student side header */}
      {/* <Tag>{item.marked.toString()}</Tag> */}
      {/* <Tag>{dayjs(item.settings.start_at_datetime).format("MMM DD, YYYY")}</Tag> */}
      {/* {!isLibrary && start_at_datetime}
          {item.settings.duration && !item.experience_user && duration}
          {item.points && !item.experience_user && points}
          {!isStudent && !isLibrary && item.submissions_count != 0 && submissions_count}
          {isStudent && student_submitted}
          {isStudent && grading_status} */}
      {/* </Space> */}
    </List.Item>
  );

  return listItem;
};

export default memo(ExperienceUserListItem);