import React, { useEffect, useState } from "react";
import { checkInternetConnectivity } from '/src/lib/CheckInternetConnectivity/CheckInternetConnectivity';
import { currentTimeValidWrtServerTime, getServerTimeOffset, writeToClipboardPolyfill } from '/src/lib/utils/helperMethods';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux"
import { appTypeSelector } from '/src/App/OfflineApp/offlineAppSelectors';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { message } from '../AntdAppHelper';
import MrTranslate, { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import IosStartBtn from '../IosStartBtn';
import { Button, Space, Tag, Tooltip } from 'antd';
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector } from "/src/views/Auth/Login/selector";
import {
  CalendarOutlined
} from "@ant-design/icons";
import SEBStartAlternativeModal from "/src/views/Experiences/SEBStartAlternativeModal";
import StartInSebBtn from "../SEB/StartInSebBtn";
import dayjs from "dayjs";
import Countdown from "react-countdown";
import ButtonCountDownWrapper from "/src/views/Experiences/ExperienceShow/Components/ButtonCountDownWrapper";
import { actions as firestoreInteractionActions, getOrCreateSessionId } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { endSessionBtnLoadingSelector, experienceConfigSelector, experienceViewModeSelector, startTestBtnLoadingSelector } from "/src/views/Experiences/selector";
import { actions as experienceActions } from "/src/views/Experiences/redux";
// import { allowedInBrowserSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { getAppNativeVars, getAppVersionStatus, getDeviceInfo, checkIfUnsupportedApp } from "/src/App/OfflineApp/offlineAppHelper";
import { allowedInBrowserSelector, currentSessionIdSelector, firestoreConnectionClosedSelector, sessionIdSelector, userInfoSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import DownloadAppsModal from "/src/views/OfflineApps/DownloadAppsModal";

const StartTestButton = (props) => {
  const { experience, setSwitchFromStartToResumeView, startButtonEnabled = true, setUserInfo, buttonIcon = null, className } = props;
  let finalRender = null
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const {path, url} = useRouteMatch();
  const appType = useSelector(appTypeSelector());
  const deviceInfo = getDeviceInfo(appType)
  const appVersionStatus = getAppVersionStatus(appType, deviceInfo.appVersion);
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const currentUser = useSelector(currentUserSelector())
  const embedded = useSelector(getEmbeddedSelector())
  const appRegion = useSelector(appRegionSelector())
  let experienceViewMode = useSelector(experienceViewModeSelector());
  const experienceConfigFromRedux = useSelector(experienceConfigSelector());
  const allowedInBrowser = useSelector(allowedInBrowserSelector)

  const currentSessionIdFromRedux = useSelector(currentSessionIdSelector);
  const userInfoFromReduxsessionId = useSelector(sessionIdSelector);
  const endSessionBtnLoading = useSelector(endSessionBtnLoadingSelector());
  const firestoreConnectionClosed = useSelector(firestoreConnectionClosedSelector())
  
  let appNativeVars = getAppNativeVars(appType);
  let currentDeviceInfo = getDeviceInfo(appType);
  const currentSessionIdFromSessionStorage = getOrCreateSessionId();
  
  const isStudent = 
    currentUser.role === "student" || currentUser.role === "parent" 
      ? true
      : false

  const shouldStartBtnDisabled =
    (experience.status === "closed") || 
    endSessionBtnLoading || 
    firestoreConnectionClosed
  
  let startTooltipTitle = 
    experience.status === "closed" 
      ? mrIntl("ExperienceList.test_closed") 
      : appVersionStatus && appVersionStatus.status === "unsupported" 
        ? mrIntl("ExperienceList.unsupported_version_of_the_app_please_update_your_app_to_start_this_test") 
        : mrIntl("ExperienceList.start")

  let startBtn = (
    <Tooltip title={startTooltipTitle}>
      <Button
        className={`m-r-20 ${startButtonEnabled ? className : 'start-test-button-disabled'}`}
        type="primary"
        key="start-test"
        disabled={experience.status === "closed" || !startButtonEnabled ? true : false}
        onClick={() => {
          console.log('on join==>', firestoreInteractionActions, experience.id);
          checkInternetConnectivity(
            appRegion,
            (internetStatus) => {
              if (internetStatus === "online") {
                getServerTimeOffset((offset) => {
                  dispatch(experienceReduxActions.setServerTimeOffsetSuccess({ serverTimeOffset: offset }))
                  if (currentTimeValidWrtServerTime(offset)) {
                    let experienceInfo = {
                          // studentsCount: firestoreFieldValue.increment(1)
                          haveStudentsStarted: true
                    }
                    dispatch(firestoreInteractionActions.setExperienceInfo({
                      experienceId: experience.id,
                      experienceInfo
                    }))
                    setSwitchFromStartToResumeView(false);
                    !embedded &&
                      experienceViewMode === "apTakeTest" &&
                      writeToClipboardPolyfill("");

                    let logValues = {
                      app_version: appType === "mac"
                        ? `AssessPrep Mac App ${appNativeVars.appVersion}`
                        : appType == "ios"
                          ? `AssessPrep iOS App ${appNativeVars.appVersion}`
                          : appType == "cros"
                            ? `Chromebook`
                            : `SEB ${appNativeVars.appVersion}`,
                      browser_details: currentDeviceInfo.browser,
                      os_details: currentDeviceInfo.os + " " + currentDeviceInfo.osVersion,
                    }
                    let newUserInfo = {}
                    if (currentSessionIdFromSessionStorage !== userInfoFromReduxsessionId) {
                      newUserInfo = {
                        session_id: currentSessionIdFromSessionStorage,
                      }
                    }
                    setUserInfo(
                      {
                        ...newUserInfo,
                        currentView: "startTest",
                        // currentView: "joined",
                        // group_id: getStudentGroupId(experience.groups, currentUser),
                        group_id: experience.current_student_group_ids || null,
                        started_at: Date.now(),
                        needs_attention_at: Date.now(),
                        security: experience.settings["mode"] == "offline" ? true : false,
                        // active_teachers: [],
                        // active_students: []
                      },
                      {
                        log: {
                          logging: true,
                          action: appType !== "web" ? "test_started_in_app" : "test_started_in_browser",
                          current_value: logValues,
                        },
                      }
                    );
                    // setIsJoinCodeCorrect(false); // resetting so that when submission reset and come back to joined screen, it is set to false

                    if (experienceConfigFromRedux.takeExperience.join.createExperienceUserOnStart) {
                      dispatch(experienceActions.makeExperienceUser({
                        experienceId: experience.id
                      }))
                    }
                  } else {
                    message.error(mrIntl("CommonText.your_system_time_is_out_of_sync_not_be_able_to_start_tests"))
                  }
                })
              }
            },
            mrIntl)
        }}
      >
        <MrTranslate id={"JoinView.start_test"}/>{buttonIcon}
      </Button>
    </Tooltip>
  );
  console.log("shouldStartBtnDisabled || !isJoinCodeCorrect item =====>", shouldStartBtnDisabled);


  // TODO check ios - removing for mac since security already on by now when launching test directly - maybe check if security is off and still try to turn on
  // if ((appType === "ios" || (appType === "mac" && supportsMacAACSecurity(appType))) && experience.settings.mode === "offline") {
  //   startBtn = <IosStartBtn disabled={shouldStartBtnDisabled} item={item} resourceName={props.resourceName} showExperience={showExperience} />
  // }
  
  if (experience.settings.mode === "offline" && appType === "web" && !allowedInBrowser) {
    startBtn =
      <Space>
        <StartInSebBtn disabled={shouldStartBtnDisabled} item={experience} />
        {firestoreConnectionClosed ? <Button onClick={() => { window.location.reload() }}>Refresh</Button> : <DownloadAppsModal />}
      </Space>
  }

  console.log("StartTestButton experience.experience_user ===>", experience.settings.start_condition === "scheduled_time");
  // if(((isStudent && !experience.experience_user && currentTimeValidWrtServerTime(serverTimeOffset)))){
  if (isStudent && !experience.experience_user) {
    finalRender = startBtn
  }
  console.log("StartTestButton finalRender =====>", finalRender);
  return finalRender
};

export default StartTestButton;