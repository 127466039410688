import React from "react";
import { List, Card } from "antd";
// import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import "./GroupListItem.scss";
import { Link, useRouteMatch } from "react-router-dom";

const GroupListItem = (props) => {
  console.log("props in group==>", props);
  const {
    item,
    // edited,
    // deleted,
    actionBtns,
  } = props;
  const { path } = useRouteMatch();
  return (
    <List.Item>
      <Card title={item.key}>
        <Link to={`${path}/${item.id}/tests`}>
          {" "}
          {item.id} - {item.name}{" "}
        </Link>
        {JSON.stringify(item.settings)}
        {actionBtns}
      </Card>
    </List.Item>
  );
};
GroupListItem.defaultProps = {};

GroupListItem.propTypes = {};

export default GroupListItem;
