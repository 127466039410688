import React from "react";

import { Input, Button, Row, Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { DeleteButton, CustomTextButton, InputArea, TextArea, ShowText, CustomErrorMessage } from '/src/components/UI/Segment/UIHelper';
import MediaHoverButton from "/src/components/UI/Media/MediaHoverButton"
import MediaShow from "/src/components/UI/Media/MediaShow"
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { CKETextOptionTools } from "/src/components/UI/CKEditor/CKEConfig";

export const InputOptions = props => {
  console.log("input options props", props);

  const { field, add, remove, onTextChanged, removeFile, onSaveMedia, removeItem, buttonText, span, type } = props
  const mrIntl = useTranslate();
  return (
    <div>
      <Row>
        {field.map((item, index) => (
          <Col span={span || 12} key={`col-${item.id}`}>
            <TextArea key={`text-area-${item.id}`}>
              <InputArea
                key={`input-area-${item.id}`}
                className={`singleline-input-area ${!(item.content || (item.files && item.files.length)) && 'custom-error'}`}
              >
                <BasicCKE
                  key={`basic-ck-${item.id}`}
                  itemId={item.id}
                  data={item.content}
                  tools={CKETextOptionTools}
                  placeholder={item.placeholder}
                  onTextChanged={(data) => onTextChanged(type, data, index)}
                >
                </BasicCKE>
                {item.files && removeFile && <MediaShow
                  key={`media-show-${item.id}`}
                  file={item.files && item.files[0]}
                  removeFile={(e) => removeFile('hints', index)}
                >
                </MediaShow>}
                <CustomErrorMessage className="error-message" message= {mrIntl("CommonText.required_msg")}></CustomErrorMessage>
              </InputArea>
              {onSaveMedia && <MediaHoverButton
                key={`media-hover-button-${item.id}`}
                onSaveMedia={(files) => onSaveMedia('hints', index, files)}
              ></MediaHoverButton>}
              <DeleteButton
                key={`delete-button-${item.id}`}
                clicked={(e) => remove(index)}
                disabled={field.length === 1}
                tooltipTitle={field.length === 1 ? mrIntl("CommonText.minimum_limit_reached") : ""}
              >
                <DeleteOutlined />
              </DeleteButton>
            </TextArea>
          </Col>
        ))}
      </Row>
      {add && <CustomTextButton
        clicked={(e) => add()}
        buttonText={buttonText || mrIntl("CommonText.add")}
      >
      </CustomTextButton>}
    </div >
  );
}