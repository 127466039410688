import React, {useState, useEffect} from "react";
import { MrCrudView, FormRenderer } from "mr_react_framework";
import { actions } from "./redux";
import FeaturesList from "./FeaturesList/FeaturesList";
import commonSelectors from "/src/App/commonSelectors";
import FeaturesFilter from "./FeaturesList/FeaturesFilter/FeaturesFilter";
import { Select, Form } from "antd";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import OrgFeaturesChildComponent from "./OrgFeaturesChildComponent";
import { buildOptionsArr, isKeyCodeValid, isStringEmpty } from "/src/lib/utils/helperMethods";
import { SelectPlan } from "/src/components/AppSpecific/SelectSubject/SelectSubject";

const StatusCustomComponent = ({ value, onChange }) => {

  const options = [
    { label: "Development", value: "development" },
    { label: "Stable", value: "stable" },
    { label: "Released", value: "released" },
  ];

  const handleChange = (selectedValue) => {
    if (selectedValue === "released") {
      showConfirmModal({
        title: "Are you sure you want to release this feature?",
        okText: "Yes",
        closable: false,
        onOk: () => {
          onChange(selectedValue); 
        },
      });
    } else {
      onChange(selectedValue);
    }
  };

  return (
    <Form.Item>
      <Select
        options={options}
        value={value}
        onChange={handleChange}
      />
    </Form.Item>
  );
};


const returnForm = (props) => {
  const { mrIntl } = props

  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps=====", opts.values);
      
      if (Array.isArray(opts.values.org_features)) {
        opts.values.org_features = opts.values.org_features.map(feature => ({
          ...feature,
          enabled: feature.enabled !== undefined ? feature.enabled : true,
        }));
        opts.values.org_features_attributes = opts.values.org_features
        delete opts.values.org_features;
      }

      if (opts.values.plan_ids) {
        const planIds = opts.values.plan_ids;
        const existingFeatures = opts.values.plan_features || [];
        
        opts.values.plan_features_attributes = [
          // Update existing features
          ...existingFeatures.map(feature => ({
            ...feature,
            archived: !planIds.includes(feature.plan_id)
          })),
          // Add new features
          ...planIds
            .filter(id => !existingFeatures.some(f => f.plan_id === id))
            .map(plan_id => ({ plan_id, archived: false }))
        ];
      
        delete opts.values.plan_ids;
      }

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        name: {
          type: "string",
          default: "",
          placeholder: mrIntl("CommonText.name"),
          rules: [
            {
              validator(rule, value) {
                if (!isStringEmpty(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Please enter valid feature name");
                }
              },
            },
          ],
        },
        code: {
          type: "string",
          default: "",
          placeholder: mrIntl("CommonText.code"),
          rules: [
            {
              validator(rule, value) {
                if (isKeyCodeValid(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Please enter valid feature code");
                }
              },
            },
          ],
        },
        desc: {
          type: "string",
          default: "",
          placeholder: mrIntl("CommonText.description"),
        },
        plan_ids: {
          type: "string",
          placeholder: "Select plan",
          widget: SelectPlan,
          widgetConfig: {
            mode: "multiple"
          },
        },
        status: {
          type: "string",
          placeholder: "Select status",
          default:"stable",
          widget: StatusCustomComponent,
        },
        org_features: {
          type: "string",
          dependsOn: ["status"],     
          children: OrgFeaturesChildComponent
        },
      },
    },
  };
  return form;
};

const processFeatureFormInitialValues = (data) => {
  console.log("initial data ====>", data);
  let newData = { ...data };
  if (data?.plan_features) {
    newData.plan_ids = data.plan_features.map(plan => plan.plan_id);
  }
  return newData;
};

const Features = MrCrudView({
  resourceName: "features",
  singleResourceName: "feature",
  // displayName: "Create/Edit feature",
  resourceUrl: "/features",
  filter: FeaturesFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  // listItem: BoardListItem,
  forms: [{
    name: "features", 
    form: returnForm,
    config: {
      processInitialValues: processFeatureFormInitialValues,
      submitConfig: {
        success: {
          showMessage: true,
          message: "Feature created successfully",
        },
      },
    }
  }],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span>Item Detail {JSON.stringify(props.item)}</span>;
      },
    },
    itemDetail: {
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span>Item Detail {JSON.stringify(props.item)}</span>;
      },
      // widget: ProgrammeItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      widget: (props) => {
        return <span>Filter</span>;
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      widget: FeaturesList,
      // widget: (props) => {
      // return <span>List</span>;
      // },
    },
  },
});

// Plans.defaultProps = {};

Features.propTypes = {};
export default Features;
