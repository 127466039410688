
      function loadScript(src, callback) {
        var script = document.createElement('script');
        script.src = src;
        script.onload = callback;
        document.body.appendChild(script);
      }
        let url = 'https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js'
        if (import.meta.env.VITE_REGION === 'china') {
          url = 'https://app.assessprep.cn/cdn/1.11.0/jquery.min.js'
        }

        loadScript(url, function () {
          console.log('jQuery loaded');

          loadScript('/mathquill-0.10.1/mathquill-matrix.min.js', function () {
            console.log('MathQuill loaded');

            try {
              window.MQ = MathQuill.getInterface(2);
            } catch (error) {
              window.MQ = {};
              console.log('MathQuill load fail', error);
            }
            // IMP: we are now loading mqEditor js before tex-svg-full.js - in case of rendering issues or error, double check this
            loadScript('/mqEditor/bundle-1.0.0.js', function () {
              console.log('MQ Editor loaded');
            });
          });
        });
    