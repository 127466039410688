import React from 'react';

const DefaultCKEFooter = (props) => {
  console.log("DefaultCKEFooter props ==>", props)
  const { visible, statusTitle, extraInfo } = props;

  return (
    visible 
      ? <div className="default-cke-footer">
          <span className="footer-status-title">
            {statusTitle}
          </span>
          <span className="footer-extra-info">
            {extraInfo}
          </span>
        </div>
      : null
  );
};

export default DefaultCKEFooter;