import React, { useEffect, useState } from "react";

import { Row, Col } from "antd";
import MediaUI from "/src/components/UI/Media/MediaUI";
import MediaShow from "/src/components/UI/Media/MediaShow";
import { cloneDeep } from "lodash";
import { removeItemFromArray } from "../InteractiveHelpers";
import { Segment, SegmentData } from "/src/components/UI/Segment/UIHelper";
import ThumbnailAttachments from "/src/components/UI/Attachments/ThumbnailAttachments/ThumbnailAttachments";
import PreviewAttachment from "/src/components/UI/Attachments/PreviewAttachment";
import ListAttachments from "/src/components/UI/Attachments/ListAttachments";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const PDFAnswerExplanation = (props) => {
  console.log("PDFAnswerExplanation props ==>", props);
  const { createMode, answerMode, presentationMode } = props;
  const mrIntl = useTranslate()
  let parsedJSONObject;
  if (createMode) {
    parsedJSONObject = props.value || {
      pdfs: [],
    };
  } else {
    parsedJSONObject = props.pdf_answer_explanation;
  }

  const [state, setState] = useState(parsedJSONObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);

  const mediaFormInitialState = {
    modal: false,
    loading: false,
    visible: false,
    title: mrIntl("PDFAnswerExplanation.insert_pdfs_title"),
    okText: mrIntl("PDFAnswerExplanation.add_pdfs_button"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    requireFooter: false,
    content_type: "pdf",
    allowed_type: "pdf",
    supportedTypes: "application/pdf",
    supportedFormats: "pdf",
    selectConfig: {
      maxSize: "20 MB",
    },
  };
  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );

  mediaFormSetting.onPreview = async (file, value) => {
    console.log("file and value", file, value);
    let src = file.url;
    if (createMode) {
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }

      setMediaFormSetting({
        ...mediaFormSetting,
        previewCurrentFile: {
          ...file,
          url: src,
        },
      });
    } else {
      window.open(src, "_blank").focus();
    }
  };

  let currentJSON;
  if (presentationMode) {
    currentJSON = parsedJSONObject;
  } else {
    currentJSON = state;
  }

  const pdfs = currentJSON.pdfs || [];

  console.log("pdfs ==>", pdfs);
  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let pdf_answer_explanation = cloneDeep(state);
        props.onChange(pdf_answer_explanation);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    setState(state);
    console.log("stateUpdated count ==>", stateUpdatedCount);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const onSaveMedia = (files) => {
    console.log("files ==>", files);
    let newState = {
      ...state,
      pdfs: pdfs.concat(files),
    };

    saveState(newState);
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "",
      modal: false,
      visible: false,
      triggerSaveAction: 0,
    });
  };

  const removeFile = (file) => {
    console.log("remove file", file);
    const extra = {
      noMinSize: true,
      withConfirmation: true,
    };

    const index = pdfs.findIndex((pdf) => pdf.id == file.id);
    const newPDFs = removeItemFromArray(pdfs, index, extra);
    let newState = {
      ...state,
      pdfs: newPDFs,
    };

    saveState(newState);
  };

  return (
    <Row>
      <Col span={24}>
        {createMode && (
          <MediaUI
            computer={true}
            url={true}
            mediaFormSetting={mediaFormSetting}
            setMediaFormSetting={setMediaFormSetting}
            onSaveMedia={onSaveMedia}
          ></MediaUI>
        )}

        {pdfs.length > 0 && (
          <ThumbnailAttachments
            attachments={pdfs}
            draggableProps={{
              isDragDisabled: true,
            }}
            listProps={{
              listType: "text",
              showRemoveIcon: createMode ? true : false,
              onRemove: createMode && removeFile,
              onPreview: mediaFormSetting.onPreview,
            }}
          />
        )}

        <PreviewAttachment
          type={"modal"}
          visible={mediaFormSetting.previewCurrentFile}
          file={mediaFormSetting.previewCurrentFile}
          files={pdfs}
          onCancel={() =>
            setMediaFormSetting({
              ...mediaFormSetting,
              previewCurrentFile: null,
            })
          }
        />
      </Col>
    </Row>
  );
};

export default PDFAnswerExplanation;
