import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import messages_en from "/src/lang/en_US.json";

export const MrTranslate = (props) => {
  const { id } = props;
  const contentType = props.contentType || "message";
  const Component = props.component;
  const componentProps = props.componentProps || {};
  let defaultMsg = props.defaultMessage || id;
  const values = props.values || {};
  const opts = props.opts || {};

  const FinalTranslationComponent = FormattedMessage;
  let finalProps = {}
  if (contentType === "message") {
    finalProps = {
      ...finalProps,
      id,
      defaultMessage: defaultMsg,
      values
    }
  }
  if (Component) {
    return <Component {...componentProps} />

  } else {
    return <>
      <FinalTranslationComponent {...finalProps} />
    </>

  }
}

const translationFuncCreator = (intlObj, hocOpts = {}) => {
  function translationFunc(key, values = {}, opts = {}) {
    // const defaultMsg = opts.defaultMsg || key;

    let defaultEnglishValue = messages_en[key]
    const defaultMsg = opts.defaultMsg || defaultEnglishValue || key;
    
    return intlObj ? intlObj.formatMessage({ id: key, defaultMessage: defaultMsg }, values) : defaultMsg;
  }
  return translationFunc;
}
export const useTranslate = (opts = {}) => {
  const intlObj = useIntl();
  return translationFuncCreator(intlObj);
  // function translationFunc(key, values = {}, opts = {}){
  //   const defaultMsg = opts.defaultMsg || key;
  //   return intlObj.formatMessage({id: key, defaultMessage: defaultMsg}, values);
  // }
  // return translationFunc;
}

export const mrTranslate = (intl, opts = {}) => {
  return translationFuncCreator(intl, opts);
}

export default MrTranslate;