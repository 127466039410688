import React, { useState } from "react";
import { MrSelect } from "mr_react_framework";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import SelectOrgDropdown from "/src/components/AppSpecific/SelectOrg/SelectOrgDropdown";

const SelectFeaturesType = MrSelect({
  config: {
    processData: (data) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    searchApi: false,
    default: "stable",
    widgetConfig: {
      options: [
        { label: "Development", value: "development" },
        { label: "Stable", value: "stable" },
        { label: "Released", value: "released" },
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Select Status",
      allowClear: true,
    },
  },
});

const FeaturesFilter = (props) => {
  console.log("FeaturesFilter", props);

  let extraFilters = [];
  let by_org_id = null;

  if (
    props.currentUser.role == "superadmin" ||
    props.currentUser.role == "support"
  ) {
    if (props.by_org_id) {
      by_org_id = parseInt(props.by_org_id);
    }
    extraFilters.push(
      <SelectOrgDropdown
        value={by_org_id}
        onChange={(value) => props.setParams({ by_org_id: value })}
        config={{
          widgetConfig: {
            placeholder:"Select Organization"
          },
        }}
      />
    );
  }

  let by_status;
  if (props.by_status) {
    by_status = props.by_status;
  }

  extraFilters.push(
    <SelectFeaturesType
      value={by_status}
      onChange={(value) => {
        props.setParams({ by_status: value });
      }}
    />
  );
  extraFilters.push(
    <ResetFilter key={"resetFiltersBtn"} setParams={props.setParams} />
  );

  return extraFilters;
};

FeaturesFilter.defaultProps = {};

FeaturesFilter.propTypes = {};

export default FeaturesFilter;