import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { msalConfig } from '/src/lib/microsoft-login/authConfig';
import msalObj from '/src/lib/microsoft-login/msalInstance';
import { useDispatch } from 'react-redux';
import {actions as loginActions} from "/src/views/Auth/Login/redux";
import MrTranslate from '/src/lib/MrTranslate/MrTranslate';
const OauthCallback = (props) => {
  const location = useLocation();
  console.log( "props", props );
  console.log( "location", location );
  const dispatch = useDispatch();
  const handleMicrosoftLogin = (response) => {
    console.log( "MS LOGIN response", response );
    if(response !== null){
      const msLoginData = {
        msLoginObj: response,
      };
      // dispatch(actions.googleCreate(msLoginData));
      dispatch(loginActions.microsoftCreate(msLoginData));
    }
  }
  useEffect(() => {
    msalObj.handleRedirectPromise()
    .then(handleMicrosoftLogin)
    .catch((error) => {
        console.error("MS LOGIN line 30", error);
    });
    // if (location.hash.includes("code")) {
    //   // new UserAgentApplication(msalConfig.auth.clientId, null, null);
    // }

    
  }, [])
  console.log( "location", location );
  return <span>OauthCallback</span>;
};

OauthCallback.defaultProps = {}

OauthCallback.propTypes = {}

export default OauthCallback;