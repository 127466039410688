import React, { useState } from 'react';
import { Alert, Button, Modal, Space, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import { showConfirmModal } from '/src/components/UI/Segment/UIHelper';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const AssignReviewerButton = (props) => {
  const { activeExperience, reviewerSetting = {}, gradingConfig, setGradingConfig } = props;
  console.log("AssignReviewerButton====>", props)

  const mrIntl = useTranslate();

  const assignAtDateTime = dayjs(reviewerSetting.assign_reviewers_at_datetime)
  const isAssignTypeAutomatic = reviewerSetting.assign_reviewer_type === "automatic"
  const isAssignAtDateTimePassed = assignAtDateTime < dayjs()
  const isMinimumSubmissionsToAssignReviewers = activeExperience.submissions_count >= 2

  const isDisabled = (isAssignTypeAutomatic && !isAssignAtDateTimePassed) || (!isMinimumSubmissionsToAssignReviewers) ? true : false;
  
  const tooltipTitle = 
    !isMinimumSubmissionsToAssignReviewers
      ? mrIntl("AssignReviewerButton.must_have_more_than_2_submissions_to_assign_reviewers")
      : isAssignAtDateTimePassed
        ? mrIntl("AssignReviewerButton.reassign_reviewers")
        : isAssignTypeAutomatic
          ? mrIntl("AssignReviewerButton.reviewers_assigning_will_happen_at", { assignAtDateTime: assignAtDateTime.format("MMM DD, HH:mm:ss") })
          : mrIntl("AssignReviewerButton.assign_submission_to")

  const dispatch = useDispatch();

  const assignPeerButtonText = isAssignTypeAutomatic && isAssignAtDateTimePassed ? mrIntl("AssignReviewerButton.reassign_reviewers") : mrIntl("AssignReviewerButton.assign_submission_to")

  const handleOk = () => {
    setGradingConfig({
      ...gradingConfig,
      assignReviewersEnabled: false,
    });
    dispatch(experienceUsersActions.assignReviewers({
      experience_id: activeExperience.id,
    }));
  };

  const handleCancel = () => {
    setGradingConfig({
      ...gradingConfig,
      assignReviewersEnabled: false,
    });
    dispatch(experienceUsersActions.fetch({
      params: {
        by_experience_id: activeExperience.id,
      }
    }));
  };                              

  const confirmModal = () => {
    showConfirmModal({
      title: "Are you sure you want to re-assign reviewers?",
      content: <p> {mrIntl("AssignReviewerButton.if_a_reviewer_that_is_being_removed_or_reassigned_has")} </p>,
      onOk: handleOk,
      onCancel: handleCancel
    })
  }

  return (
    <>
      {!gradingConfig.assignReviewersEnabled || isDisabled ? (
        <>
        <Tooltip title={tooltipTitle}>
          <Button
            type="primary"
            disabled={isDisabled}
            onClick={() => {
              setGradingConfig({
                ...gradingConfig,
                assignReviewersEnabled: true,
              });
            }}
          >
            {assignPeerButtonText}
          </Button>
        </Tooltip>
        </>
      ) : (
        <Space>
          <Button
            danger
            onClick={() => {confirmModal()}}
          >
            {mrIntl("AssignReviewerButton.save_assign_reviewers")}
          </Button>

          <Button
            type="primary"
            onClick={() => {handleCancel()}}
          >
            {mrIntl("CommonText.cancel")}
          </Button>
        </Space>
      )}
    </>
  );
};

export default AssignReviewerButton;
