import React, { useEffect, useState } from "react"
import { Button, Tooltip } from "antd"
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { CKENotepadTools } from "/src/components/UI/CKEditor/CKEConfig"
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { useDispatch, useSelector } from "react-redux";
import { CustomErrorMessage, InputArea, WordCount } from "/src/components/UI/Segment/UIHelper";
import DefaultCKE from "/src/components/UI/CKEditor/DefaultCKE";
import Draggable from "react-draggable";
import "./Notepad.scss";
import { studentNotesSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import {allowedPasteCharactersLimit, checkIPAD, checkMob} from "/src/lib/utils/helperMethods"
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { CgArrowsExpandUpLeft, CgArrowsExpandDownRight } from "react-icons/cg";
import { usePinUnpin } from "/src/lib/utils/usePinUnpin";

export const wordValidation = (text, rules = {}, mrIntl) => {
  console.log("mrintl ====>", mrIntl);
  const maxWords = rules.maxWord || 500
  const maxWordMessage = rules.maxWordMessage || mrIntl("Notepad.text_limit_reached")
  const validation = {
    success: false,
    messages: []
  }

  let hasRequiredError, hasLimitError
  if (rules.required && !text) {
    hasRequiredError = true
    validation.messages.push(rules)
  } else if (text && text >= maxWords) {
    hasLimitError = true
    validation.messages.push(maxWordMessage)
  }

  if (!hasRequiredError && !hasLimitError) {
    validation.success = true
  }
  return validation
}

const Notepad = (props) => {
  const { showNotepadInSidebar, handleCurrentTool } = props;
  const studentNotes = useSelector(studentNotesSelector)
  const enabledFeatures = useSelector(enabledFeaturesSelector())

  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  const maxWord = 500
  const activeExperience = useSelector(activeAdjustedExperienceSelector())

  const draggableTool = enabledFeatures.enable_draggable_tool
  const newJoinViewWithDynamicConfigEnabled = enabledFeatures.new_join_view_with_dynamic_config
  const [showNotepad, setShowNotepad] = useState(false);
  const [wordCount, setWordCount] = useState({
    maxWord: maxWord,
    onUpdate: (stats) => {
      const isLimitExceeded = stats.words > maxWord
      const isCloseToLimit = !isLimitExceeded && stats.words > maxWord * .8;
      setWordCount({
        ...wordCount,
        ...stats,
        isLimitExceeded: isLimitExceeded,
        isCloseToLimit: isCloseToLimit
      })
      if (isLimitExceeded) {
      }
    }
  })

  const { isPinned, position, isDragging, togglePin, handleDrag, handleStop } = usePinUnpin();

  const validationRules = {
    maxWord: maxWord,
    isLimitExceeded: wordCount.isLimitExceeded,
    maxWordMessage: mrIntl("Notepad.word_limit_reached_your_notes_will_not_be_saved")
  }
  const validation = wordValidation(wordCount.words, validationRules, mrIntl)
  const valid = validation.success
  let errorMessage = null
  let className = 'multiline-input-area'
  if (!valid) {
    className += ' show-error'
    errorMessage = validation.messages[0]
  }
  let ckeTools = {
    ...CKENotepadTools,
    wordCount: wordCount,
  }

  if (activeExperience && activeExperience.settings && (activeExperience.settings.mode === "offline" || activeExperience.settings.mode === "online_secure")) {
    ckeTools = {
      ...ckeTools,
      pasteCharactersLimit: allowedPasteCharactersLimit,
    }
  }

  const onTextChanged = (data, extra = {}) => {
    if (!wordCount.isLimitExceeded) {
      let userInfo = { student_notes: data }
      dispatch(
        firestoreInteractionActions.setUserInfo({ userInfo })
      );
    }
  }

  useEffect(() => {
    setShowNotepad(showNotepadInSidebar)
  }, [showNotepadInSidebar])
   
  const handleNotepadClose = () => {
    setShowNotepad(false)
    if (newJoinViewWithDynamicConfigEnabled) {
      handleCurrentTool(false)
    }
  }

  const classNames = `draggable-notepad ${newJoinViewWithDynamicConfigEnabled ? 'new-join-view-tool' : ''} ${!isPinned ? 'drag-tool-shadow' : ''} ${isDragging ? 'tool-dragging' : ''}`;

  const isMobileOrIpad = checkIPAD() || checkMob(); 

  return (
    <div>
      {showNotepad ? <Draggable
        handle=".notepad-header"
        disabled={isPinned}
        bounds="body"
        onStop={handleStop}
        position={position}
        onDrag={handleDrag}
      >
        <div className={classNames}>
          <div className="notepad-header">
            <div className="notepad-title">{mrIntl("Notepad.notepad")}</div>
            {newJoinViewWithDynamicConfigEnabled && draggableTool && <Tooltip title={isPinned ? "Drag" : "Fixed"}>
              <Button
                className={!isPinned ? "tool-draggable-button" : ""}
                type="text"
                icon={isPinned ? <CgArrowsExpandUpLeft /> : <CgArrowsExpandDownRight />}
                {...(isMobileOrIpad ? { onTouchStart: () => togglePin() } : { onClick: () => togglePin() })}
              ></Button>
            </Tooltip>}
            <Button title={mrIntl("CommonText.close")} icon={<CloseOutlined />} type="text"  {...(isMobileOrIpad ? { onTouchStart: () => handleNotepadClose() } : { onClick: () => handleNotepadClose() })}></Button>
          </div>
          <InputArea className={className}>
            <DefaultCKE
              data={studentNotes}
              tools={ckeTools}
              placeholder={mrIntl("Notepad.write_your_notes")}
              onTextChanged={(data) => onTextChanged(data)}
            ></DefaultCKE>
            <CustomErrorMessage className="error-message" message={errorMessage} /><br />
            <WordCount wordCount={wordCount.words}></WordCount>
          </InputArea>
        </div>
      </Draggable> : null}
      {!newJoinViewWithDynamicConfigEnabled ? <Button className="notepad-button"
        onClick={() => setShowNotepad(!showNotepad)}
      >
        <EditOutlined />
        {mrIntl("Notepad.notepad")}</Button> : null}
    </div>
  );
};

export default Notepad;