import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { put } from "redux-saga/effects";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "integration",
  actionNames: ["FETCH", "CREATE", "UPDATE", "DELETE", "EDLINK_SYNC"],
});

const initialState = fromJS({
  integrations: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

export function* edlinkSyncSaga(action) {
  console.log("Overring edlinkSyncIntegrationSaga", action.payload);

  try {
    yield put(actions.edlinkSyncStart());
    const response = yield axiosInstance.instance.post(
      "/integrations/edlink/orgs.json",
      action.payload
    );

    console.log("response on edlinkSyncIntegrationSaga ==>", response);

    yield put(actions.edlinkSyncSuccess({ data: response.data }));
  } catch (error) {
    console.log("Overring edlinkSyncIntegrationSaga error", error);
    yield put(actions.edlinkSyncFail({ error: error }));
  }

}

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState
//   {
//   [actionTypes.EDLINK_SYNC_INTEGRATION_SUCCESS]: edlinkSyncIntegrationSaga,
// }
);
export const watchIntegrations = reduxCrud.generateWatchSaga({
  [actionTypes.EDLINK_SYNC_INTEGRATION]: edlinkSyncSaga,
});

export default reduxCrud;
