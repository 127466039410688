import React, { useEffect, useState, useMemo } from "react";
import { Button, Col, Input, message, Modal, Row, Space, Switch, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "../Auth/Login/selector";
import { actions as integrationActions } from "/src/views/Integrations/redux";
import { integrationsSelector } from "../Integrations/selector";
import { actions as orgIntegrationActions } from "./redux";
import { orgIntegrationsSelector } from "./selector";
import { clone, cloneDeep, debounce } from "lodash";
import Spinner from "/src/components/UI/Spinner/Spinner";
import {
  SettingOutlined,
} from "@ant-design/icons";
import { SelectProgram } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { orgProgrammesSelector } from "../OrgProgrammes/selector";
import "./OrgIntegration.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { axiosInstance } from "/src/api/apiModule";
import { isStringEmptyOrHasBlankSpace } from "/src/lib/utils/helperMethods";

const RenderSwitch = ({ record, disabled, handleSwitchChange }) => {
  const defaultEnabled = record.enabled || false;
  const [checked, setChecked] = useState(defaultEnabled);
  const mrIntl = useTranslate();
  return (
    <Switch
      checked={checked}
      title={disabled && mrIntl("OrgIntegration.select_settings")}
      disabled={disabled}
      onChange={(checked) => {
        handleSwitchChange(record, checked, true);
        setChecked(checked);
      }}
    />
  );
};

const OrgIntegration = ({ orgId , org }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector());
  const integrations = useSelector(integrationsSelector());
  const orgIntegrations = useSelector(orgIntegrationsSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const mrIntl = useTranslate();

  const isSuperadmin = currentUser.role === "superadmin";
  const isAdmin = currentUser.role === "admin";

  const [showModal, setShowModal] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);
  
  const [enabledIntegrations, setEnabledIntegrations] = useState([]);
  const [activeIntegrationId, setActiveIntegrationId] = useState(null);
  const [integrationLabel, setIntegrationLabel] = useState(null);
  const [orgIntegrationSetting, setOrgIntegrationSetting] = useState({});
  const [selectedIntegrationLogs, setSelectedIntegrationLogs] = useState(null);
  const showConfirmModalContent = orgIntegrationSetting.org_programme_id ?
    mrIntl("OrgIntegration.are_you_sure_you_want_to_modify_the_integration_id")
    : mrIntl("OrgIntegration.once_you_select_a_program_you_cannot_change_it")

  let currentIntegrationOrgProgrammeId = null;
  let currentEdlinkIntegrationId = null;
  let currentIntegrationSourceId = null;
  let shouldDisableOrgProgrammeSelector = false;
  
  if (activeIntegrationId && orgIntegrationSetting) {
    currentIntegrationOrgProgrammeId = orgIntegrationSetting?.org_programme_id;
    currentEdlinkIntegrationId = orgIntegrationSetting?.edlink_integration_id;
    currentIntegrationSourceId = orgIntegrationSetting?.org_source_id;
    shouldDisableOrgProgrammeSelector = orgIntegrationSetting?.org_programme_id ? true : false;
  }

  const [disableButton, setDisableButton] = useState(true);
  const [isFieldsValidated, setIsFieldsValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    const debouncedEffect = debounce(() => {
      console.log("enabledIntegrations =====>", enabledIntegrations, orgIntegrations);
      
      // for superadmins, we are merging both 'orgIntegration' and 'integration', while for admins, we are displaying only 'orgIntegrations'. 
      if (isSuperadmin) {
        let updatedIntegrations = cloneDeep(integrations);
        if (orgIntegrations.length > 0 || updatedIntegrations.length > 0) {
          updatedIntegrations = integrations.filter(integration => !integration.archived).map((integration) => {
            const currentOrgIntegration = orgIntegrations.find(item => item.integration_id === integration.id);
            console.log("orgIntegration ========>", currentOrgIntegration);
            if (!currentOrgIntegration) {
              integration.enabled = false;
            }
            return currentOrgIntegration || integration;
          });
          if (updatedIntegrations.length > 0) {
            setEnabledIntegrations(updatedIntegrations);
          }
        }
      } else {
        if (orgIntegrations.length > 0) {
          const newOrgIntegrations = orgIntegrations.filter((oi) => oi.enabled === true && !oi.archived);          
          setEnabledIntegrations(newOrgIntegrations);
        }
      }
    }, 300); 

    debouncedEffect();

    return () => {
      debouncedEffect.cancel();
    };
  }, [orgIntegrations, integrations]);

  const isInvalid = (val) => isStringEmptyOrHasBlankSpace(val); 
  const isMissing = (val) => val === undefined || isStringEmptyOrHasBlankSpace(val);

  useEffect(() => {
    setShowErrorMessage(isInvalid(currentEdlinkIntegrationId) || isInvalid(currentIntegrationSourceId));
    setDisableButton(isMissing(currentEdlinkIntegrationId) || isMissing(currentIntegrationSourceId) || !currentIntegrationOrgProgrammeId);
  }, [currentEdlinkIntegrationId, currentIntegrationSourceId, currentIntegrationOrgProgrammeId]); 

  useEffect(() => {
    dispatch(orgIntegrationActions.fetch({ params: { by_org_id: orgId } }));
    if (isSuperadmin) {
      dispatch(integrationActions.fetch());
    }
  }, [currentUser.role, dispatch]);

  const handleSwitchChange = (record, checked, shouldNotUpdateSetting = false) => {
    const id = record.integration_id || record.id;
    const index = orgIntegrations.findIndex(item => item.integration_id === id);
    const currentSettings = orgIntegrationSetting;
    console.log("index ========>", currentSettings, record);
    if (index === -1) {
      dispatch(orgIntegrationActions.create({
        label: record.label, integration_id: id, org_id: orgId, enabled: checked, settings: {
          edlink_integration_id: currentSettings.edlink_integration_id,
          org_source_id: currentSettings.org_source_id,
          org_programme_id: currentSettings.org_programme_id,
        }
      }, {
        success: {
          showMessage: true,
          message: mrIntl("OrgIntegration.integration_updated_successfully"),
        },
      }));
    } else if (shouldNotUpdateSetting) {
      dispatch(orgIntegrationActions.update({ id: record.id, enabled: checked }))
    } else {
      const settings = {
        ...record.settings,
        edlink_integration_id: currentSettings.edlink_integration_id,
        org_source_id: currentSettings.org_source_id,
      }
      if (!settings?.org_programme_id) {
        settings["org_programme_id"] = orgIntegrationSetting.org_programme_id;
      }
      dispatch(orgIntegrationActions.update({ id: record.id, enabled: checked, settings: settings }, {
        success: {
          showMessage: true,
          message: mrIntl("OrgIntegration.integration_updated_successfully"),
        },
      }));
    }
  };

  const handleSync = (record) => {
    message.success(mrIntl("OrgIntegration.sync_started_successfully"))
    console.log("integrationActions =====>", integrationActions, currentIntegrationOrgProgrammeId);
    const id = record.integration_id || record.id;
    const programId = orgIntegrationSetting.org_programme_id;
    console.log("programId =====>", programId, orgIntegrationSetting, id);
    dispatch(integrationActions.edlinkSync({
      org_id: orgId,
      integration_id: id,
    }));
  }

  const handleSyncEvent = () => {
  axiosInstance.instance.post('/orgs/sync_cron')
    .then(response => {
      message.success(response.data.message);
    })
    .catch(error => {
      message.error("Something went wrong! please try again.");
      console.error("Sync Cron Error:", error);
    });
  }

  // const renderAdminControls = () => (
  //   <>
  //     {org.is_edlink ? (
  //       mrIntl("OrgIntegration.your_integration_is_paused_due_to_technical_reasons")
  //     ) : (
  //       mrIntl("OrgIntegration.the_integration_is_not_set_up_yet")
  //     )}
  //   </>
  // );

  const columns = [
    {
      title: "Integration name",
      dataIndex: "label",
      key: "label",
      width: 150,
    },
  ];

  if (isSuperadmin) {
    columns.push({
      title: "Enabled",
      key: "enabled",
      width: 100,
      render: (record) => {
        const ogs = orgIntegrationSetting
        const disabled = (record.settings?.edlink_integration_id && record.settings?.org_source_id) ? false : true;
        console.log("ogs =====>", ogs, disabled);
        return <RenderSwitch disabled={disabled} record={record} handleSwitchChange={handleSwitchChange} />
      },
    });
    columns.push(
      {
        title: "Settings",
        key: "settings",
        width: 100,
        render: (record) => {
          const settings = record.settings;
          return (<Button onClick={() => {
            const integrationId = record.integration_id || record.id // We are doing this because in record we have integration or orgIntegration 
            setIsFieldsValidated(false)
            setShowModal(!showModal)
            setIntegrationLabel(record.label)
            setActiveIntegrationId(integrationId);
            if (settings) {
              setOrgIntegrationSetting(settings)
            }
          }}  type="text" icon={<SettingOutlined />}></Button>)
        },
      }
    )
  }

  if (isAdmin || isSuperadmin) {
    columns.push(
      {
        title: "Sync",
        key: "sync",
        width: 200,
        render: (record) => {
          const ogs = orgIntegrationSetting;
          // Start of Selection
          return (
            <Space>
              <Button
                title={!ogs?.org_programme_id && mrIntl("OrgIntegration.select_settings")}
                disabled={!record.enabled}
                onClick={() => handleSync(record)}
              >
                Sync
              </Button>
              {/* "Sync Event" button is intended for testing purposes only. It runs a sync events cron job and is not meant for the production environment. */}
              {import.meta.env.VITE_MODE !== "production" &&
                <Button
                  title="Start org integration sync for all schools"
                  disabled={!record.enabled}
                  onClick={() => handleSyncEvent()}
                >
                  Sync Event
                </Button>}
            </Space>
          )
        },
      }
    );
  }
  if (isSuperadmin) {
    columns.push(
      {
        title: "Logs",
        key: "logs",
        width: 100,
        render: (record) => <Tooltip title="Show Logs">
          <Button onClick={() => {
            if (record?.custom_fields?.edlink_logs) {
              setSelectedIntegrationLogs(record?.custom_fields?.edlink_logs)
            }
            setShowLogsModal(!showLogsModal)
            setIntegrationLabel(record.label)
            console.log("record =====>", record);
          }} type="text" icon={<InfoCircleOutlined />}>
          </Button>
        </Tooltip>,
      }
    );
  }

  const updateSetting = (keyToUpdate, value) => {
    setOrgIntegrationSetting({
      ...orgIntegrationSetting,
      [keyToUpdate]: value
    })
  }

  const showSettingsModal = () => {
    setShowModal(!showModal)
    const activeIntegration = enabledIntegrations.find(item => (item.integration_id || item.id) === activeIntegrationId);
    const checked = activeIntegration.enabled;
    handleSwitchChange(activeIntegration, checked);
  }

  const handleValidation = (integration_id, source_id) => {
    const activeIntegration = enabledIntegrations.find(item => (item.integration_id || item.id) === activeIntegrationId);
    dispatch(orgIntegrationActions.validate(
      {
        org_id: orgId,
        integration_id: integration_id,
        source_id: source_id,
        source_provider: activeIntegration.label,
      },
      {
        successCallback: (data) => {
          console.log("checksuccess", data)
          setIsFieldsValidated(!isFieldsValidated)
          setLoading(false)
        },
        errorCallback: () => {
          setLoading(false)
        }
      } 
    ))
  }
  
  if (integrations.length > 0 && enabledIntegrations.length === 0) {
    return <Spinner />
  }

  return (
    <>
      {enabledIntegrations.length === 0 && isAdmin && mrIntl("OrgIntegration.the_integration_is_not_set_up_yet")}
      {enabledIntegrations.length > 0 && (
        <Table
          className="integrations-table"
          columns={columns}
          dataSource={enabledIntegrations}
          rowKey="id"
          pagination={false}
        />
      )}
      <Modal title={`${integrationLabel} integration settings`} closable={false} visible={showModal}
        onCancel={() => {
          setShowModal(!showModal)
          setActiveIntegrationId(null)
        }}
        okText={loading ? "Validating" : isFieldsValidated ? "Save" : "Validate"}
        onOk={() => {
          if (!isFieldsValidated) {
            handleValidation(currentEdlinkIntegrationId, currentIntegrationSourceId);
            setLoading(true);
          } else {
            showConfirmModal({
              centered: true,
              content: showConfirmModalContent,
              onOk: () => {
                showSettingsModal();
              },
            });
          }
        }}
        okButtonProps={{
          loading: loading,
          disabled: loading || disableButton,
        }}
      >
        {isSuperadmin && (
          <>
            <Row>
              <Col span={22}>
                {mrIntl("OrgIntegration.integration_id")}
                <Input
                  placeholder={mrIntl("OrgIntegration.integration_id")}
                  value={currentEdlinkIntegrationId}
                  onChange={(event) => updateSetting("edlink_integration_id", event.target.value)}
                />
              </Col>
              <Col span={22}>
                {org.type_c === "district" ? "District id" : mrIntl("OrgIntegration.school_id")}
                <Input
                  placeholder={org.type_c === "district" ? "District id" : mrIntl("OrgIntegration.school_id")}
                  value={currentIntegrationSourceId}
                  onChange={(event) => updateSetting("org_source_id", event.target.value)}
                />
              </Col>
            </Row>
            <Col span={22}>{mrIntl("OrgIntegration.select_program")}
              <SelectProgram
                key="selectOrgProgrammeFilter"
                dropdownMatchSelectWidth={false}
                value={currentIntegrationOrgProgrammeId}
                disabled={shouldDisableOrgProgrammeSelector}
                onChange={(value) => updateSetting("org_programme_id", value)}
                config={{
                  params: { by_org_id: orgId },
                  widgetConfig: {
                    style: { width: "100%" },
                    placeholder: mrIntl("ExperiencesFilter.all_programs"),
                  }
                }}
              />
            </Col>
            <span className={`c-red ${showErrorMessage ? "visibility-visible" : "visibility-hidden"}`}>
              {mrIntl("OrgIntegration.integration_or_source_id_cannot_be_empty_or_contain_spaces")}
            </span>
          </>
        )}
      </Modal>
      <Modal title={`${integrationLabel} Logs`} width={1000} visible={showLogsModal} onCancel={() => {
        setShowLogsModal(!showLogsModal)
      }} onOk={() => setShowLogsModal(!showLogsModal)}>
        {selectedIntegrationLogs ? (
          <pre>
            {(() => {
              const processLogs = (data) => {
                if (Array.isArray(data)) {
                  return data.map(processLogs);
                }
                if (typeof data === "object" && data !== null) {
                  return Object.fromEntries(
                    Object.entries(data).map(([key, value]) => [
                      key,
                      key === "logs_string" && typeof value === "string"
                        ? value.replace(/^\n/, "").split("\n")
                        : processLogs(value),
                    ])
                  );
                }
                return data;
              };
              const processedLogs = processLogs(selectedIntegrationLogs);
              return JSON.stringify(processedLogs, null, 2);
            })()}
          </pre>
        ) : (
          <p>{mrIntl("OrgIntegration.no_logs_available")}</p>
        )}
      </Modal>
    </>
  );
};

export default OrgIntegration;