import React from "react";
import CKETextContent from "../CKETextContent/CKETextContent";

const textForm = (mrIntl) => {
  return {
    type: "object",
    properties: {
      content: {
        type: "string",
        title: mrIntl("TextForm.text_content"),
        // title: "Text content",
        widget: CKETextContent,
        widgetConfig: {
          extraClass: 'max-height-editor',
          editorFooterConfig: {
            visible: true,
          },
          writingAssistantConfig: {
            enabled: true
          },
        },
        setInitialValue: true,
        rules: [{
          required: true,
          message: mrIntl("CommonText.required_msg")
        }]
      }
    }
  }
};

export default textForm;