/* eslint-disable no-restricted-globals */

const workercode = () => {
  let timerInterval;
  let time = 0;
  self.onmessage = function ({ data: { turn, interval } }) {
    console.log("interval_worker on message ==>", turn, interval)
    if (turn === "off" || timerInterval) {
      clearInterval(timerInterval);
      time = 0;
    }
    if (turn === "on") {
      timerInterval = setInterval(() => {
        time += interval
        self.postMessage({ time });
      }, interval);
    }
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const interval_worker = URL.createObjectURL(blob);

// module.exports = interval_worker;
export default interval_worker
