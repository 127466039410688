import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Alert, Tooltip, Carousel, Image, Slider, Row, Col, Empty } from "antd";
import { MrFormComponent } from "mr_react_framework";
import "./ScreenshotLogs.scss";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import {
  CaretRightOutlined,
  PauseOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import MediaShow from "/src/components/UI/Media/MediaShow";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ScreenshotLogs = (props) => {
  console.log("ScreenshotLogs =======>", props);
  const { data } = props;
  const filteredData = data
    .sort((a, b) => dayjs(a.updated_at).diff(dayjs(b.updated_at)));
  console.log("filteredData =======>", filteredData, filteredData.length);
  const [playerSettings, setPlayerSettings] = useState({
    playing: false,
    displayCurrentTime: 0,
    currentData: filteredData[0]
  })
  const [isSeekDrag, setSeekDrag] = useState(false); 
  const carouselRef = useRef();
  const mrIntl = useTranslate();
  console.log("filteredData ======>", filteredData, playerSettings);
  const playPauseTitle = playerSettings.playing ? mrIntl("CommonText.pause") : mrIntl("ScreenshotLogs.play_title");

  const onChange = (val) => {
    if(!isSeekDrag) {
      setPlayerSettings({
        ...playerSettings,
        displayCurrentTime: parseFloat(val / 100),
        currentData: filteredData[val]
      })
    }
  }

  const onSliderChange = (played) => {
    setSeekDrag(true)
    setPlayerSettings({
      ...playerSettings,
      displayCurrentTime: parseFloat(played / 100)
    })
  }

  function onSliderAfterChange(played) {
    setSeekDrag(false)
    if (carouselRef.current) {
      carouselRef.current.goTo(Math.round(played))
    }
  }

  if (filteredData.length === 0) {
    return <Empty />
  }

  return (
    <div className="screenshot-carousel">
      {/* {playerSettings.currentData} */}
      <Carousel autoplay={playerSettings.playing} afterChange={onChange} dots={false} ref={carouselRef} effect="fade">
        {filteredData.map((item) => {
          return <span>
            <MediaShow
              file={item}
              mediaType={"image"}
            /></span>
        })}
      </Carousel>
      <Row className="screenshot-carousel-control">
        <Col span={1}>
          <Tooltip
            placement={"bottom"}
            title={playPauseTitle}
          >
            <Button icon={
              playerSettings.playing ? (
                <PauseOutlined />
              ) : (
                <CaretRightOutlined />
              )
            } onClick={() =>
                setPlayerSettings({
                  ...playerSettings,
                  playing: !playerSettings.playing,
                })
              }
            ></Button>
          </Tooltip>
        </Col>
        <Col span={23}>
          <Slider
            min={0}
            max={filteredData.length - 1}
            onAfterChange={onSliderAfterChange}
            onChange={onSliderChange}
            value={playerSettings.displayCurrentTime * 100}
          />
          <span>{playerSettings.currentData && "Time: " + dayjs(playerSettings.currentData.updated_at).format("hh:mm:ss a")}</span>
        </Col>
      </Row>
    </div>
  );
};

export default ScreenshotLogs;
