import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Row, Col, Pagination, Button, List, Space, Dropdown, Menu, Skeleton } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
// import Spinner from '../../../UI/Spinner/Spinner';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import "./CustomExperienceCrudList.scss"
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import {
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

export const CYButton = styled(Button)`
  background: ${(props) => props.theme.button.background};
  color: ${(props) => props.theme.main.colors.primary};
`;
const CustomSegmentsCrudList = (props) => {
  console.log("sustom segment filter props==>", props);
  const {
    resourceName,
    singleResourceName,
    filter,
    forms,
    MrFilter,
    displayName,
    resourceForm,
    CustomListItem,
    ActionBtns,
    params,
    setParams,
    showFormVal,
    showForm,
    loading,
    item = {},
    handleOk,
    handleCancel,
    refresh,
    dataSource,
    pagination,
    onPageChange,
    edited,
    deleted,
    permission,
    currentUser,
    ListHeader,
    resourceUrl,
    parentInstance,
    hocProps,
    selectorProps,
    renderList,
    renderForm,
    manualFormRender,
    crudMode,
    
    formMode,
    config,
    duplicateResource,
    crudListData
  } = props;
  // let { path, url } = useRouteMatch();
  // const history = useHistory();
  
  const history = props.history;
  const path = props.match.path;
  const url = props.match.url;
  const CrudListData = crudListData;
  let customHeaderCreateSearchRender = []
  let isRoute = false;
  const mrIntl = useTranslate()
  
  
  // if(dataSource.length === 0) return null;
  if (hocProps.isRoute && props.isRoute) {
    isRoute = true;
  }
  // if (!item) {
  //   item = {};
  // }
  
  let newItem = {
    ...item,
  };
  
  const resourcePermission = permission[singleResourceName] || {};
  let toRender;
  let content = [];

  let visible = false;
  // if (loading && item && item.size === 0) {
  //   // debugger;
  //   return <Spinner />;
  // }
  
  if (showFormVal === resourceName) {
    visible = true;
  }
  
  let extraFilters = [];

  if (filter) {
    extraFilters = filter({
      ...params,
      ...selectorProps,
      changed: setParams,
      url,
      setParams,
      resourceName,
      singleResourceName,
      parentProps: {
        ...props
      }
    });
  }
  const filterProps = {
    parentProps: {
      ...props
    },
    name: displayName,
    singleResourceName,
    extraFilters,
    ...selectorProps,
    config: config.filter.config || {}
  };
  const Filter = MrFilter;
  
  
  // const intl = {
  //   formatMessage: () => {}
  // }
  
  // if (!manualFormRender) {
  //   const formHelperProps = {
  //     currentUser,
  //     permission,
  //     selectorProps,
  //     forms,
  //     handleOk,
  //     handleCancel,
  //     newItem,
  //     loading,
  //     displayName,
  //     showFormVal,
  //     resourceName,
  //   };
    
  //   const form = <MrFormRenderHelper {...formHelperProps}/>;

  //   content.push(form);
  // }
  let createBtn = null;
  
  let totalColWidth = 24;
  
  
  if (
    permission &&
    permission[singleResourceName] &&
    permission[singleResourceName].create
  ) {
    if(config.list.createBtn.component) {
      createBtn = config.list.createBtn.component;
    } else {
      // CreateBtnComponent = CYButton;
      createBtn = function CreateBtn(parentProps) {
        return <CYButton
          key={"createBtn"}
          // onClick={() => showForm({ name: resourceName })}
          type="primary"
          onClick={() => history.push(`${url}/create`)}
          icon={<PlusOutlined />}
        >
          <FormattedMessage
            id="general.create"
            defaultMessage="Create"
            // defaultMessage={`{text}`}
            // description="Create"
            // values={{ text: "Create" }}
          />
        </CYButton>
      }
    }
    if(config.filter.config && config.filter.config.create && config.filter.config.create.show) {
      const CreateBtnFinal = createBtn
      customHeaderCreateSearchRender.push(<CreateBtnFinal key="experience-main-create-btn" />)
    }
    // content.push(createBtn);
  }
  const finalFilter = config.filter.show ? (<Col span={config.filter.style.width}
    className="filter-container-wrapper"
   key={`filter${singleResourceName}MainContainer`}>
        <Row>
          <Filter
            key={`filter${singleResourceName}Main`}
            {...params}
            changed={setParams}
            setParams={setParams}
            refresh={refresh}
            createBtn={createBtn}
            {...filterProps}
            // create={() => this.showForm({ name: resourceName })}
          />
        </Row>
      </Col>) : null;
  // if (isRoute) {
  //   content.push(
      
  //   );
  // }

  const itemCountMsg = pagination.totalCount > 1 ? mrIntl("CustomSegmentsCrudListLayout.items_found", {totalCount: pagination.totalCount}) : mrIntl("CustomSegmentsCrudListLayout.item_found", {totalCount: pagination.totalCount})

  customHeaderCreateSearchRender.push(props.renderSearchComponent({setParams: props.setParams, search: filterProps.search, ...params}))
  !loading && customHeaderCreateSearchRender.push(<span> {itemCountMsg}</span>)

  const listWrapperWidth = config.filter.style.width < 12 ? (totalColWidth - config.filter.style.width) : totalColWidth;
  
  console.log( "listWrapperWidth - width", listWrapperWidth );

  const list = <CrudListData key={`mainCrudList${resourceName}`} {...props}/>
  let listWrapper = (
    <Col span={listWrapperWidth} key={`mainList${resourceName}Container`}>
      <Row className='custom-main-crud-list-header'>
        <Col span={20}>
          <Space>
            {customHeaderCreateSearchRender}
          </Space>
        </Col>
        <Col span={4} className='text-right'>
          <Space>
            {/* <Button>Sort</Button> */}
            {/* <Button icon={<FilterOutlined />}> Filters</Button> */}
          </Space>
        </Col>
      </Row>
      <Row>
        {loading ? <>
          {[...Array(3)].map((_, index) => (
            <Skeleton
              className="active-skeleton-loading"
              key={index}
              active
              title={true}
              paragraph={{ rows: 2 }}
            />
          ))}
        </> :
          <>
          {props.showNavigation ? <Row className='custom-main-crud-list'>
            <Col className='custom-list-button' span={1}>
              <Button icon={<LeftOutlined />} shape="circle" type="primary" className={"list-pervious-button"} disabled={pagination.page === 1} title='Pervious' onClick={() => onPageChange(pagination.page - 1)}></Button>
            </Col>
            <Col span={21}>{list}</Col>
            <Col span={1} className='custom-list-button'>
              <Button icon={<RightOutlined />}  shape="circle" type="primary"  className='list-next-button' disabled={pagination.totalCount === 0 ? true : pagination.page === pagination.totalCount} title='Next' onClick={() => onPageChange(pagination.page + 1)}></Button>
            </Col>
          </Row> : list}

          {hocProps.isRoute && config.pagination.show ? <Col span={24}>
          <Row className='m-t-20'>
            <Pagination
              key={"mainPagination"}
              // showQuickJumper
              total={pagination.totalCount}
              pageSize={pagination.pageSize}
              // showTotal={total => `Total ${total} items`}
              onChange={onPageChange}
              current={pagination.page}
              // to allow to disable show page size
              {...config.pagination.config}
              // defaultCurrent={params.page}
            />
          </Row>
        </Col> : null}
        </>}
      </Row>
    </Col>
  );
  
  
  content.push(ListHeader);
  content.push(listWrapper);

  
  // content.push(pagination.totalPages);
  // if (hocProps.isRoute) {
  //   content.push(
  //     config.pagination.show ? () : null
  //   );
  // }
  // let breadcrumb = (
  //   <Breadcrumb key={"mainBreadCrumb"}>
  //     <Breadcrumb.Item>
  //       <a href="">Home</a>
  //     </Breadcrumb.Item>
  //     <Breadcrumb.Item>{displayName}</Breadcrumb.Item>
  //   </Breadcrumb>
  // );
  toRender = (
    // <Row key={"mainRowCRUDLayout"} loading={loading}>
    <Row key={"mainRowCRUDLayout"} >
      {content}
    </Row>
  );
 

  // TODO fix loader like in exp crud

  return (<React.Fragment>
    <Row>
      {config.filter.style.align !== "right" ? finalFilter : null}
      {content}
      {config.filter.style.align === "right" ? finalFilter : null}
    </Row>
  </React.Fragment>);


  // return (
  //   <React.Fragment>
  //     <Row>
  //       <Col span={5}>{config.filter.style.align !== "right" ? finalFilter : null}</Col>
  //       <Col span={19}>{loading ? <Loader /> : content}</Col>
  //       {/* {config.filter.style.align == "right" ? finalFilter : null} */}
  //     </Row>
  //   </React.Fragment>
  // );
};

// CustomSegmentsCrudList.defaultProps = {}

CustomSegmentsCrudList.propTypes = {}
if(import.meta.env.NODE_ENV === 'development'){
  CustomSegmentsCrudList.whyDidYouRender = true;
}
export default CustomSegmentsCrudList;