import React from "react";
import { Row, Col, Space, Alert } from "antd";
import RateAiResult from "./RateAiResult";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ModalFooter = (props) => {
  let {
    magicButtonRenderInFooter,
    error,
    generatedQuestions,
    isStreaming,
    aiGenerateLoading
  } = props;
  const mrIntl = useTranslate();
  return (
    <Row>
      <Col span={12}></Col>
      <Col span={8}>
        {error && (
          <Alert
            type="warning"
            className="error-alert"
            message={mrIntl("CommonText.something_went_wrong_please_try_again")}
          />
        )}
        {!error && generatedQuestions.length > 0 && !isStreaming && !aiGenerateLoading && (
          <div className="text-right">
            <RateAiResult
              generatedData={generatedQuestions}
              action="generate_segments"
            />
          </div>
        )}
      </Col>
      <Col span={4} className="text-right">
        <Space>{magicButtonRenderInFooter}</Space>
      </Col>
    </Row>
  );
};

export default ModalFooter;
