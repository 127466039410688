import React, { useState, useEffect } from "react";
import { MrSelect, MrCrudView, MrForm as WrappedMrForm, FormRenderer } from "mr_react_framework";

import { actions } from "./redux";

import OrgListItem from "./OrgListItem/OrgListItem";
import OrgsFilter from "./OrgsFilter/OrgsFilter";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors from "../../App/commonSelectors";
import OrgsLayout from "./OrgsLayout/OrgsLayout";
import TokenVerifier from "../../components/Integrations/Managebac/TokenVerifier.js/TokenVerifier";
import OrgItemDetail from "./OrgItemDetail/OrgItemDetail";
// import MrReduxCrud from '../../lib/utils/MrReduxCrud/MrReduxCrud';
import {buildOptionsArr, buildCountriesOptionsArr} from "../../lib/utils/helperMethods";
import CustomFormInput from "./OrgRolesForm";
import {actions as genericActions} from "/src/App/genericRedux";
import ProgramYearSelector from "./ProgramYearSelector";
import SelectQuestionbankDropdown from "/src/components/AppSpecific/SelectQuestionbank/SelectQuestionbankDropdown";
import _ from "lodash";
import dayjs from "dayjs";
import {Input, AutoComplete, Tabs, Button, Form, Select, Space, Switch} from "antd";
import {SelectProgram, SelectFeaturesCode, SelectCountry} from "/src/components/AppSpecific/SelectSubject/SelectSubject"
import OrgsList from "./OrgsList";
import BulkImportFileURL from "./BulkImportFileURL";
// import moment from 'moment';
import MrTranslate, { mrTranslate } from "/src/lib/MrTranslate/MrTranslate";
import OrgFeaturesChildComponent from "../Features/OrgFeaturesChildComponent";

const { Option, OptGroup } = Select

const SelectNoticeType = MrSelect({
  config: {
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      showSearch: false,
      options: [
        { label: "Payment delay", value: "paymentDelay" },
        { label: "Trial ending", value: "trialEnding" },
      ],
    },
  },
});

const SelectPlan = MrSelect({
  actions: genericActions,
  resourceName: "plans",
  config: {
    url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      // console.log("select plan", data)
      let options = []
      data.map((item) => {
        // if(item.tiers_json.length > 0){
          // item.tiers_json.map((tier) => {
            // let label = item.name + "-" + tier.name + "-" + tier.max_users
            // options.push({ label: label, value: item.id })
            // })
            // }
        options.push({ label: item.name, value: item.id })
      });
      return options
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: "Select plan",
      // mode: "multiple",
      // style: { width: "200px" },
    },
  },
});

const SelectTier = MrSelect({
  // actions: genericActions,
  // resourceName: "plans",
  config: {
    // url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.name, value: item.id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: "Select plan",
      // mode: "multiple",
      // style: { width: "200px" },
    },
  },
});

const SelectSubscriptionStatus = MrSelect({
  // actions: genericActions,
  // resourceName: "plans",
  config: {
    // url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      options: buildOptionsArr(["Trial", "Customer", "Strong Lead", "Incoming Lead", "Accepted Lead", "Qualified Lead", "No Contact", "Closed: Cold Lead", "Closed: Not Interested", "Closed: Churn"]),
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Status",
      // style: { width: "100%" },
      allowClear: true
    },
  },
});

const SelectOrgType = MrSelect({
  config: {
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      options: [
        { label: "School", value: "school" },
        { label: "District", value: "district" },
      ],
      showSearch: false,
      allowClear: true,
    },
  },
});

const SelectStartTerm = MrSelect({
  actions: genericActions,
  resourceName: "terms",
  config: {
    url: "/terms",
    isJsx: true,
    // params: {by_org_programme_id: 8}, //get from top right dropdown
    processData: (data, props) => {
      console.log("data select groups terms", data);
      console.log("props select groups", props);

      let allTermGroups = []
      let finalRender
      data.map((item) => {
        if(item.children.length != 0){
          let optGroupChildren = [] 
          item.children.map((child) => {
            let label = `${child.label} (${dayjs(child.starts_on).format("MMM DD")} - ${dayjs(child.ends_on).format("MMM DD")})`
            optGroupChildren.push(<Option value={child.id}>{label}</Option>)
          })
          let optGroup = <OptGroup label={item.label}>
            {optGroupChildren}
          </OptGroup>
          allTermGroups.push(optGroup)
          finalRender = allTermGroups
        }
      })
      return <>
        {finalRender}
      </>


      // return data.map((item) => {
      //   console.log("class selector item", item);
      //   return {label: item.name, value: item.id};
      // });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false, 
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Select term",
      // mode: "multiple",
      // style: {width: "400px"},
    },
  }
});

const SelectEndTerm = MrSelect({
  actions: genericActions,
  resourceName: "terms",
  config: {
    url: "/terms",
    isJsx: true,
    // params: {by_org_programme_id: 8}, //get from top right dropdown
    processData: (data, props) => {
      console.log("data select groups terms", data);
      console.log("props select groups", props);

      let allTermGroups = []
      let finalRender
      data.map((item) => {
        if(item.children.length != 0){
          let optGroupChildren = [] 
          item.children.map((child) => {
            let label = `${child.label} (${dayjs(child.starts_on).format("MMM DD")} - ${dayjs(child.ends_on).format("MMM DD")})`
            optGroupChildren.push(<Option value={child.id}>{label}</Option>)
          })
          let optGroup = <OptGroup label={item.label}>
            {optGroupChildren}
          </OptGroup>
          allTermGroups.push(optGroup)
          finalRender = allTermGroups
        }
      })
      return <>
        {finalRender}
      </>


      // return data.map((item) => {
      //   console.log("class selector item", item);
      //   return {label: item.name, value: item.id};
      // });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false, 
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Select term",
      // mode: "multiple",
      // style: {width: "400px"},
    },
  }
});

const GenericWidgetCreator  = (hocProps, Component) => {  
  return (props) => {
    let finalHocProps = {}
    if(typeof(hocProps) === "function"){
      finalHocProps = hocProps(props);
    }
    return <Component {...props} {...finalHocProps}/>
  }
}



const SelectStartTermWidget = GenericWidgetCreator((props) => {
  return {config: {forceReload: true, params: {by_termable_id: props.formInstance.getFieldValue(["org_programme_id"])}}}
}, SelectStartTerm);


const getSelectStartTermField = () => {
  return {
    type: "object",
    properties: {
      start_term_id: {
        title: "Select start term",
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectStartTermWidget,
        rules: [{
          required: true,
          message: "Please choose a start term"
        }],
      },
    }
  }
};

const StartTermChildComponent = ({getFieldValue, sourceValues, formInstance}) => {
  const orgProgrammeId = getFieldValue(["org_programme_id"]);
  let finalRender = null;
  if(orgProgrammeId){
    finalRender = <FormRenderer
      formObj={getSelectStartTermField()}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  return finalRender;
}


const SelectEndTermWidget = GenericWidgetCreator((props) => {
  return {config: {forceReload: true, params: {by_termable_id: props.formInstance.getFieldValue(["org_programme_id"])}}}
}, SelectEndTerm);


const getSelectEndTermField = () => {
  return {
    type: "object",
    properties: {
      end_term_id: {
        title: "Select end term",
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectEndTermWidget,
        rules: [{
          required: true,
          message: "Please choose an end term"
        }],
      },
    }
  }
};

const EndTermChildComponent = ({getFieldValue, sourceValues, formInstance}) => {
  const orgProgrammeId = getFieldValue(["org_programme_id"]);
  let finalRender = null;
  if(orgProgrammeId){
    finalRender = <FormRenderer
      formObj={getSelectEndTermField()}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  return finalRender;
}

const SelectTierWidget = GenericWidgetCreator((props) => {
  console.log("SelectTierWidget props", props)
  return {config: {forceReload: true, params: {by_subscribable_id: props.formInstance.getFieldValue(["org_subscriptions", "0", "subscribable_id"])}}}
}, SelectTier);


const getSelectTierField = () => {
  return {
    type: "object",
    properties: {
      grade_id: {
        title: "Select tier",
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectTierWidget,
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ]
      },
    }
  }
};

const TierChildComponent = ({getFieldValue, sourceValues, formInstance}) => {
  const subscribableId = getFieldValue(["org_subscriptions", "0", "subscribable_id"]);
  // getIndex dynamically
  let finalRender = null;
  if(subscribableId){
    finalRender = <FormRenderer
      formObj={getSelectTierField()}
      sourceValues={sourceValues}
      formInstance={formInstance}
      propertyKey={"org_subscriptions", "0"}
    />
  }
  return finalRender;
}


const SelectOrgProgrammeWidget = GenericWidgetCreator((props) => {
  console.log("SelectOrgProgrammeWidget props ", props)
  return {config: {forceReload: true, params: {by_org_id: props.formInstance.getFieldValue(["id"])}}}
}, SelectProgram);


const orgsBulkImportForm = (props) => {
  console.log("orgsBulkImportForm props ", props)
  

  let form = {
    schema: {
      type: "object",
      properties: {
        id: {
          type: "string",
          span: 8,
          wrapClassName: "hidden"
        },
        org_programme_id: {
          title: "Select program",
          type: "string",
          span: 8,
          // widget: SelectProgram,
          widget: SelectOrgProgrammeWidget,
          rules: [{
            required: true,
            message: "Please choose a program"
          }],
        },
        start_term_id: {
          span: 8,
          type: "string",
          dependsOn: ["org_programme_id"],
          children: StartTermChildComponent
        },
        end_term_id: {
          span: 8,
          type: "string",
          dependsOn: ["org_programme_id"],
          children: EndTermChildComponent
        },
        batch_year: {
          type: "string",
          title: "Batch year",
          span: 8
        },
        class_prefix: {
          type: "string",
          title: "Class Prefix",
          span: 8
        },
        bulk_import_file_url: {
          type: "string",
          title: "Upload file",
          widget: BulkImportFileURL,
          widgetConfig: {},
          // type: "string",
          // placeholder: "bulk_import_file_url",
          // span: 24,
          // type: "string",
          // placeholder: "Contact email",
          // span: 12,
          // default: [],
          // listType: "picture-card",
          // widget: "UploadWidget",
          // widgetConfig: {
          //   multipe: false
          // },
          // items: {
          //   type: "object",
          //   properties: {
          //     value: {
          //       type: "string",
          //       format: "data-url",
          //     },
          //   },
          // },
        },
        bulk_import_classes_file_url: {
          type: "string",
          title: "Upload classes file",
          widget: BulkImportFileURL,
          widgetConfig: {},
        },
        
        // settings: {
        //   // elementType: "object",
        //   label: "Settings",
        //   type: "object",
        // },
      },
    },
  };

  return form;

};

const orgsGenSettingsForm = (props) => {
  let form = {
    schema: {
      type: "object",
      properties: {
        // custom_fields: {
        //   type: "object",
        //   properties: {
        //     mb_token: {
        //       placeholder: "MB Token",
        //       type: "string",
        //     },
        //   }
        // },
        // TO FIX
        // school_logo: {
        //   type: "string",
        //   default: [],
        //   title: "Logo",
        //   listType: "picture-card",
        //   // icon: "upload",
        //   widget: "UploadWidget",
        //   items: {
        //     type: "object",
        //     properties: {
        //       value: {
        //         type: "string",
        //         format: "data-url",
        //       },
        //     },
        //   },
        // },
        name: {
          type: "string",
          placeholder: "School name",
          span: 8,
          disabled: props.formState.values.id ? true : false,
        },
        address: {
          type: "string",
          placeholder: "Address",
          span: 10
        },
        country: {
          type: "string",
          placeholder: "Country",
          span: 6,
          widget: SelectCountry,
          // widget: "SelectWidget",
          // widgetConfig: {
          //   options: buildOptionsArr(["India","China"])
          // },
          disabled: props.formState.values.id ? true : false,
        },
        contact_name: {
          type: "string",
          placeholder: "Contact name",
          span: 12
        },
        contact_email: {
          type: "string",
          placeholder: "Contact email",
          span: 12
        },
        
        // settings: {
        //   // elementType: "object",
        //   label: "Settings",
        //   type: "object",
        // },
      },
    },
  };

  return form;

};

const orgsRolesForm = () => {
  let form = {
    schema: {
      type: "object",
      properties: {
       
        roles: {
          type: "string",
          title: "Roles and permissions",
          // default: "{}",
          widget: CustomFormInput
        },
      },
    },
  };
  return form;
};

const archiveReason = () => {
  return {
    type: "object",
    properties: {
      reason_for_archiving: {
        title: "Reason for disabling/re-enabling",
        type: "string",
        span: 24,
        setInitialValue: true,
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ]
      },
    }
  }
};

const ArchiveReasonComponent = ({getFieldValue, sourceValues, formInstance}) => {
  const isArchived = getFieldValue(["archived"]);
  let finalRender = null;
  // if (isArchived || (!isArchived && sourceValues.id && sourceValues.archived)) {
  if (isArchived) {
    finalRender = <FormRenderer
      formObj={archiveReason()}
      // propertyKey={"custom_fields"}
      sourceValues={sourceValues}
    />
  }
  return finalRender;
}

// const SelectNoticeTypeWidget = GenericWidgetCreator((props) => {
//   console.log("SelectTierWidget props", props)
//   return {config: {forceReload: true, params: {by_subscribable_id: props.formInstance.getFieldValue(["custom_fields", "0", "show_notice"])}}}
// }, SelectNoticeType);


const noticeType = () => {
  return {
    type: "object",
    properties: {
      notice_type: {
        title: "Select notice type",
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectNoticeType,
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ]
      },
    }
  }
};

const NoticeType = ({getFieldValue, sourceValues, formInstance}) => {
  const showNotice = getFieldValue(["show_notice"]);
  let finalRender = null;
  // if (showNotice || (!showNotice && sourceValues.id && sourceValues.show_notice)) {
  if (showNotice) {
    finalRender = <FormRenderer
      formObj={noticeType()}
      // propertyKey={"custom_fields"}
      sourceValues={sourceValues}
    />
  }
  return finalRender;
}


const paymentDelayMessage = () => {
  return {
    type: "object",
    properties: {
      // payment_delay_message: {
      notice_message: {
        title: "Payment due message",
        type: "string",
        span: 24,
        setInitialValue: true,
        // default: "Your payment is due. Please make your payment at the earliest to avoid discontinuation of services.",
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ]
      },
    }
  }
};

const trialEndingMessage = () => {
  return {
    type: "object",
    properties: {
      // trial_ending_message: {
      notice_message: {
        type: "string",
        title: "Trial ending message",
        span: 24,
        // default: "Your trial with AssessPrep is ending on <date>. Please upgrade to continue using AssessPrep.",
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ]
      },
    }
  }
};


const NoticeMessage = ({ getFieldValue, sourceValues, formInstance }) => {
  const noticeMessageType = getFieldValue(["notice_type"]);
  const showNotice = getFieldValue(["show_notice"]);
  console.log("notice message==>", showNotice, noticeMessageType, sourceValues);
  let finalRender = null;
  if (
    // showNotice ||
    // (!showNotice &&
    //   sourceValues.id &&
    //   sourceValues.show_notice)
    showNotice
  ) {
    if (
      noticeMessageType === "paymentDelay" ||
      (!noticeMessageType &&
        sourceValues.id &&
        sourceValues.notice_type === "paymentDelay")
    ) {
      finalRender = (
        <FormRenderer
          formObj={paymentDelayMessage()}
          // propertyKey={"custom_fields"}
          sourceValues={sourceValues}
        />
      );
    } else if (
      noticeMessageType === "trialEnding" ||
      (!noticeMessageType &&
        sourceValues.id &&
        sourceValues.notice_type === "trialEnding")
    ) {
      finalRender = (
        <FormRenderer
          formObj={trialEndingMessage()}
          // propertyKey={"custom_fields"}
          sourceValues={sourceValues}
        />
      );
    }
  }

  return finalRender;
};

const orgCreateForm = (props) => {
  const { intl } = props
  const mrIntl = mrTranslate(intl)
  console.log("org create form props", props);
  const currentUserRole = props.currentUser.role
  const formValues = props.formState.values
  let editDisabled = formValues.id && currentUserRole != "superadmin" ? true : false

  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      // process and set values and then return changed values
      // opts.values["custom_field"] = "custom_value";
      // let org_programmes_attributes = []
      console.log("opts", opts);
      opts.values.org_programmes.map((p, i) => {
        p.grades_attributes = p.grades
      })
      opts.values.org_programmes_attributes = opts.values.org_programmes

      // if(dataProps.item && dataProps.item.org_questionbank_ids.length == 0){
      //   // protect override each time - ToDo support multiple select and remove
      //   console.log("add org qb")
      //   let org_questionbanks_attributes = []
      //   let toCreate = []
      //   if(opts.values.org_questionbank_ids){
      //     toCreate = opts.values.org_questionbank_ids
      //     toCreate.map((qbId, i) => {
      //       org_questionbanks_attributes.push({
      //         questionbank_id: qbId,
      //         // item_id: segment.id,
      //       })
      //     })
      //   }

      //   opts.values.org_questionbanks_attributes = org_questionbanks_attributes
      // }

      let org_questionbanks_attributes = []
      let toCreate = null
      let toRemove = null
      let toUnarchive = null;
      let existing = null
      let modified = null
      // create/update
      // create if id exists in opts.values.group_teacher_ids but not in dataProps.item.group_users_teachers.pluck("user_id")
      if(opts.values.org_questionbank_ids){
        modified = opts.values.org_questionbank_ids
      }
      if(dataProps.item && dataProps.item.org_questionbanks_incl_archived){
        existing = _.map(dataProps.item.org_questionbanks_incl_archived, 'questionbank_id')
      }
      toCreate = _.difference(modified, existing)
      toRemove = _.difference(existing, modified)
      toUnarchive = _.intersection(existing, modified);

      console.log("toCreate, toRemove ", toCreate, toRemove);
      if(toCreate){
        toCreate.map((qbId, i) => {
          org_questionbanks_attributes.push({
            questionbank_id: qbId,
          })
        })
      }
      if(toRemove){
        toRemove.map((qbId, i) => {
          console.log("qbId, existing ", qbId, existing, dataProps.item.org_questionbanks_incl_archived);
          let orgQb = _.find(dataProps.item.org_questionbanks_incl_archived, ['questionbank_id', qbId])
          org_questionbanks_attributes.push({
            id: orgQb.id,
            questionbank_id: qbId,
            archived: true
          })
        })
      }
      if (toUnarchive) {
        toUnarchive.map((qbId, i) => {
          console.log(
            "qbId, existing ",
            qbId,
            existing,
            dataProps.item.org_questionbanks_incl_archived
          );
          let orgQb = _.find(dataProps.item.org_questionbanks_incl_archived, [
            "questionbank_id",
            qbId,
          ]);
          org_questionbanks_attributes.push({
            id: orgQb.id,
            questionbank_id: qbId,
            archived: false,
          });
        });
      }

      opts.values.org_questionbanks_attributes = org_questionbanks_attributes


      // opts.values.org_questionbanks_attributes

      // opts.values.custom_fields.can_enable_ap_monitoring = opts.values.can_enable_ap_monitoring
      opts.values.custom_fields.guest_org = opts.values.guest_org
      opts.values.custom_fields.feature_overrides = opts.values.feature_overrides
      opts.values.custom_fields.reason_for_archiving = opts.values.reason_for_archiving
      opts.values.custom_fields.show_notice = opts.values.show_notice
      opts.values.custom_fields.notice_type = opts.values.notice_type
      opts.values.custom_fields.notice_message = opts.values.notice_message

      if (opts.values.org_features) {
        opts.values.org_features_attributes = opts.values.org_features
        delete opts.values.org_features;
      }

      console.log("formValues", formValues)
      if(!formValues.is_mb){
        opts.values.custom_fields.ap_id = opts.values.ap_id
      }
      console.log("opts.values", opts.values);

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        name: {
          type: "string",
          title: "School name",
          default: "Sample School",
          span: 24,
          widgetConfig: {
            disabled: editDisabled
          }
          // disabled: editDisabled,
        },
        org_type: {
          type: "string",
          title: "Org type",
          default: "school",
          span: 8,
          widget: SelectOrgType,
          widgetConfig: {
            disabled: editDisabled
          },
          rules: [{
            required: "true",
            message: "Required"
          }],
        },
        custom_fields: {
          span: 14,
          type: "object",
          properties: {
            mb_token: {
              title: "MB Token",
              type: "string",
              span: 6,
              widgetConfig: {
                disabled: editDisabled
              }
            },
            mb_integration_enabled: {
              title: "MB enabled",
              type: "string",
              span: 4,
              widget: Switch,
              valuePropName: "checked",
              widgetConfig: {
                disabled: editDisabled
              }
            },
            block_mb_provisioning_request: {
              title: "Block MB provisioning",
              type: "string",
              span: 12,
              widget: Switch,
              valuePropName: "checked",
              widgetConfig: {
                disabled: editDisabled
              }
            },
            one_roster_sync_enabled: {
              title: "One roster enabled",
              type: "string",
              span: 24,
              widget: Switch,
              valuePropName: "checked",
              widgetConfig: {
                disabled: editDisabled
              }
            },
            ai_credits_usd: {
              type: "string",
              title: "AI credits",
              span: 4,
              widgetConfig: {
                disabled: editDisabled
              }
            },
            atlas_org_id: {
              type: "string",
              title: "Atlas org_id",
              span: 20,
              widgetConfig: {
                disabled: editDisabled
              }
            },
          }
        },
        // school_logo: {
        //   type: "string",
        //   default: [],
        //   title: "Logo",
        //   listType: "picture-card",
        //   // icon: "upload",
        //   widget: "UploadWidget",
        //   items: {
        //     type: "object",
        //     properties: {
        //       value: {
        //         type: "string",
        //         format: "data-url",
        //       },
        //     },
        //   },
        // },
        
        address: {
          type: "string",
          title: "Address",
          span: 6,
          widgetConfig: {
            disabled: editDisabled
          }
        },
        country: {
          type: "string",
          title: "Country",
          span: 6,
          default: "IN",
          widget: SelectCountry,
          widgetConfig: {
            disabled: editDisabled
          }
          // widget: "SelectWidget",
          // widgetConfig: {
          //   // options: buildOptionsArr(["India","UK","UAE","US","Netherlands","China"])
          //   options: buildCountriesOptionsArr()
          // },
          // disabled: props.formState.values.id ? true : false,
        },
        contact_name: {
          type: "string",
          title: "Contact name",
          span: 6,
          widgetConfig: {
            disabled: editDisabled
          }
        },
        contact_email: {
          type: "string",
          title: "Contact email",
          span: 6,
          widgetConfig: {
            disabled: editDisabled
          }
        },
         // org_subscriptions
        // array form - each to have plan MrSelect, Tier Mrselect based on that plan, starts_on, ends_on, status, 
        org_subscriptions_attributes: {
          type: "array",
          span: 24,
          min: 1,
          max: 10,
          title: "Plans",
          default: [{}],
          items: {
            type: "object",
            properties: {
              subscribable_id: {
                type: "string",
                title: "Select plan",
                span: 5,
                widget: SelectPlan,
                widgetConfig: {
                  disabled: editDisabled
                },
              },
              status: {
                type: "string",
                title: "Status",
                span: 5,
                widget: SelectSubscriptionStatus,
                widgetConfig: {
                  disabled: editDisabled
                },
                rules: [{
                  required: "true",
                  message: "Required"
                }],
              },
              // dependson and from selected plan ka json - get from redux using selector - and break it down to build org_subscription_attributes before form submit - and in serializer build and send
              // tier_max_users: {
              //   title: "Select tier",
              //   type: "string",
              //   span: 8,

              //   // widget: SelectTier
              //   // dependsOn: ["org_subscriptions", "0", "subscribable_id"],
              //   // children: TierChildComponent,
              // },
              max_users: {
                title: "Mxusrs",
                type: "string",
                span: 2,
                rules: [{
                  required: "true",
                  message: "Required"
                }
                ],
                widgetConfig: {
                  disabled: editDisabled
                },
              },
              expected_cost: {
                title: "Expected cost",
                type: "string",
                span: 3,
                widgetConfig: {
                  disabled: editDisabled
                },
              },
              discount: {
                title: "Discount",
                type: "string",
                span: 3,
                widgetConfig: {
                  disabled: editDisabled
                },
              },
              discount_type: {
                title: "Discount type",
                type: "string",
                span: 3,
                widgetConfig: {
                  disabled: editDisabled
                },
              },
              final_price: {
                title: "Final price",
                type: "string",
                span: 3,
                widgetConfig: {
                  disabled: editDisabled
                },
              },
              
              starts_on: {
                title: "Starts on",
                type: "string",
                span: 6,
                widget: "DatePickerWidget",
                widgetConfig: {
                  showTime: false,
                  disabled: editDisabled
                  // showTime: {use12Hours: true, format:"h:mm a", minuteStep: 15},
                }
              },
              ends_on: {
                title: "Ends on",
                type: "string",
                span: 6,
                widget: "DatePickerWidget",
                widgetConfig: {
                  showTime: false,
                  disabled: editDisabled
                  // showTime: {use12Hours: true, format:"h:mm a", minuteStep: 15},
                }
              },
              trial_ends_on: {
                title: "Trial ends on",
                type: "string",
                span: 6,
                widget: "DatePickerWidget",
                widgetConfig: {
                  showTime: false,
                  disabled: editDisabled
                  // showTime: {use12Hours: true, format:"h:mm a", minuteStep: 15},
                }
              },
              next_renews_on: {
                title: "Next renews on",
                type: "string",
                span: 6,
                widget: "DatePickerWidget",
                widgetConfig: {
                  showTime: false,
                  disabled: editDisabled
                  // showTime: {use12Hours: true, format:"h:mm a", minuteStep: 15},
                }
              },
            },
          }
        },

        org_programmes: {
          type: "string",
          span: 24,
          title: mrIntl("Orgs.programs_and_years_required"),
          // default: staticBoardsArrFromBE,
          // default: getStaticBoardsArrFromBE(),
          widget: ProgramYearSelector
        },
        org_questionbank_ids: {
          type: "string",
          span: 6,
          title: "Questionbanks",
          widget: SelectQuestionbankDropdown,
          widgetConfig: {
            disabled: editDisabled
          }
        },
        
        // can_enable_ap_monitoring: {
        //   title: "Can enable AP video monitoring",
        //   type: "string",
        //   span: 24,
        //   widget: Switch,
        //   valuePropName: "checked",
        //   widgetConfig: {
        //     disabled: editDisabled
        //   }
        // },

        ap_id: {
          title: "AP V1 ID",
          type: "string",
          span: 6,
          // widget: Switch,
          // valuePropName: "checked",
          widgetConfig: {
            disabled: editDisabled || formValues.is_mb
          }
        },

        guest_org: {
          title: "Is guest org",
          type: "string",
          span: 24,
          widget: Switch,
          valuePropName: "checked",
          widgetConfig: {
            disabled: formValues.name && formValues.name.indexOf("AssessPrep") == -1
          }
        },
       
        enabled_features: {
          title: "Org enabled features",
          type: "string",
          span: 24,
          widget: (props) => {
            console.log("enabled_features props", props, formValues)
            return JSON.stringify(formValues.enabled_features) || "{}"
          },
          widgetConfig: {}
        },

        // TODO: add feature_overrrides here - in org only - to set anything specific off or on - add on but not in plan/beta features
        // feature_overrides: {
        //   type: "array",
        //   span: 24,
        //   min: 0,
        //   max: 100,
        //   title: "Feature overrides",
        //   default: [{}],
        //   items: {
        //     type: "object",
        //     properties: {
        //       feature_code: {
        //         type: "string",
        //         title: "Feature code",
        //         widget: SelectFeaturesCode,
        //         span: 8,
        //       },
        //       feature_enabled: {
        //         type: "string",
        //         title: "Feature enabled",
        //         span: 6,
        //         default: false,
        //         widget: Switch,
        //         valuePropName: "checked",
        //       },
             
        //     },
        //   }
        // },
        org_features: {
          type: "string",
          dependsOn: [], // This is important for children to render
          children: OrgFeaturesChildComponent,
        },
        hr: {
          type: "string",
          widget: () => { return <hr></hr>}
        },
        archived: {
          title: "Disable org",
          type: "string",
          span: 4,
          widget: Switch,
          valuePropName: "checked",
          // wrapClassName: import.meta.env.VITE_MODE === "production" ? "hidden" : "",
        },
        reason_for_archiving: {
          title: "Reason for disabling/re-enabling",
          type: "string",
          span: 20,
          dependsOn: ["archived"],
          children: ArchiveReasonComponent
        },
        show_notice: {
          title: "Show notice",
          type: "string",
          span: 4,
          widget: Switch,
          valuePropName: "checked",
          // wrapClassName: import.meta.env.VITE_MODE === "production" ? "hidden" : "",
        },
        notice_type: {
          title: "Notice type",
          type: "string",
          span: 4,
          dependsOn: ["show_notice"],
          children: NoticeType
        },
        // TODO: fix default and then enable below
        // notice_message: {
        //   title: "Notice message",
        //   type: "string",
        //   span: 16,
        //   dependsOn: ["show_notice", "notice_type"],
        //   children: NoticeMessage,
        //   default: {
        //     notice_message: "Your message!!!"
        //   }
        // },

      },
    },
  };
  return form;
};

const orgsProgramsYearsForm = (props) => {
  const { intl } = props
  console.log("org create form props", props);
  const mrIntl = mrTranslate(intl)
  const currentUserRole = props.currentUser.role
  const formValues = props.formState.values
  let editDisabled = formValues.id && currentUserRole != "superadmin" ? true : false

  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      // process and set values and then return changed values
      // opts.values["custom_field"] = "custom_value";
      // let org_programmes_attributes = []
      console.log("opts", opts);
      opts.values.org_programmes.map((p, i) => {
        p.grades_attributes = p.grades
      })
      opts.values.org_programmes_attributes = opts.values.org_programmes

    
      console.log("formValues", formValues)
      console.log("opts.values", opts.values);

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        org_programmes: {
          type: "string",
          span: 24,
          title: mrIntl("Orgs.programs_and_years_required"),
          // default: staticBoardsArrFromBE,
          // default: getStaticBoardsArrFromBE(),
          widget: ProgramYearSelector
        },
        
      },
    },
  };
  return form;
};

const processOrgsFormInitialValues = (data) => {
  console.log( "initial data", data );
  let newData = {...data}

  if(data.custom_fields){
    newData.reason_for_archiving = data.custom_fields.reason_for_archiving
    newData.show_notice = data.custom_fields.show_notice
    newData.notice_type = data.custom_fields.notice_type
    newData.notice_message = data.custom_fields.notice_message
  }

  // let newData = {
    //   ...data,
  //   nestedValues: {
  //     ...data.nestedValues,
  //     tempNested: "tempNested updated"
  //   },
  //   name: "hello1"
  // }
  // data = nestedValues
  if(data.org_subscriptions_attributes){
    // let org_subscriptions_attributes = formatJsonApiData(data.org_subscriptions_attributes)
    let org_subscriptions_attributes = data.org_subscriptions_attributes
    newData.org_subscriptions_attributes = org_subscriptions_attributes.map((item) => {
      return {
        ...item,
        // starts_on: moment(item.starts_on),
        // ends_on: moment(item.ends_on),
        // trial_ends_on: moment(item.trial_ends_on),
        // starts_on: item.starts_on ? moment(item.starts_on) : item.starts_on,
        starts_on: item.starts_on ? dayjs(item.starts_on) : item.starts_on,
        // ends_on: item.ends_on ? moment(item.ends_on) : item.ends_on,
        ends_on: item.ends_on ? dayjs(item.ends_on) : item.ends_on,
        // trial_ends_on: item.trial_ends_on ? moment(item.trial_ends_on) : item.trial_ends_on,
        trial_ends_on: item.trial_ends_on ? dayjs(item.trial_ends_on) : item.trial_ends_on,
        // next_renews_on: item.next_renews_on ? moment(item.next_renews_on) : item.next_renews_on,
        next_renews_on: item.next_renews_on ? dayjs(item.next_renews_on) : item.next_renews_on,
      }
    });
    // newData.children = children
  }
  console.log( "newData", newData );
  return newData;
} 

const Orgs = MrCrudView({
  resourceName: "orgs",
  singleResourceName: "org",
  // displayName: "Organizations",
  resourceUrl: "/orgs",
  // layout: OrgsLayout,
  filter: OrgsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  // listItem: OrgListItem,
  forms: [
    { name: "orgs", form: orgCreateForm, config: {processInitialValues: processOrgsFormInitialValues, isModal: false }
    }, 
    { name: "orgs-general", form: orgsGenSettingsForm, config: { isModal: false, actions: {submitText: "Save"} } },
    { name: "orgs-bulk-import", form: orgsBulkImportForm, config: {isModal: false, actions: {submitText: "Submit"} }
    }, 
    { name: "org-roles", form: orgsRolesForm, config: {isModal: false, actions: {submitText: <MrTranslate id={"CommonText.submit"}/>} } },
    { name: "orgs-programs-years", form: orgsProgramsYearsForm, config: {isModal: false, actions: {submitText: <MrTranslate id={"CommonText.submit"}/>} } }
  ],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: OrgListItem
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: OrgItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      widget: (props) => {
        return <span>Filter</span>
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      widget: OrgsList,
      // widget: (props) => {
      //   return <span>List Component</span>;
      //   // Custom index view here - table/etc. comment out widget if you want itemPreview to be used
      // }
    }
  },
});
export default Orgs;
