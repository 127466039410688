import React, { useEffect, useState } from 'react';
import { Modal, Table, Button, Space } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslate } from '../MrTranslate/MrTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { upgradePlanModalConfigSelector } from '/src/views/Orgs/selector';
import { actions as orgActions } from '/src/views/Orgs/redux';
import { currentUserSelector } from '/src/views/Auth/Login/selector';
import './UpgradePlanModal.scss';



const UpgradePlanModal = () => {
  const currentUser = useSelector(currentUserSelector());
  const { show, feature_code } = useSelector(upgradePlanModalConfigSelector());

  const currentUserOrg = currentUser.org || {};
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const [dataSource, setDataSource] = useState([]);
  const currentPlan = currentUserOrg.active_plan?.name;
  const crossIcon = <CloseOutlined className="icon-red" />;
  const checkIcon = <CheckOutlined className="icon-green" />;

  const UpgradePlanSubscriptionData = [
    {
      "category": "AIAssistant",
      "label": "AI Assistant",
      "features": [
        {
          "label": "Generate Questions with AI",
          "Standard": <Space>{crossIcon}</Space>,
          "Plus": <Space>{crossIcon}</Space>,
          "Max": <Space>{checkIcon}<a href="https://assessprep.zendesk.com/hc/en-us/articles/18650736758685-AI-Assisted-Question-Authoring" target="_blank" rel="noopener noreferrer">Learn more</a></Space>
        },
        {
          "label": "Grade with AI",
          "Standard": <Space>{crossIcon}</Space>,
          "Plus": <Space>{crossIcon}</Space>,
          "Max": <Space>{checkIcon}<a href="https://assessprep.zendesk.com/hc/en-us/articles/18650764830237-Grading-with-AI" target="_blank" rel="noopener noreferrer">Learn more</a></Space>
        },
        {
          "label": "AI Writing Assistant",
          "Standard": <Space>{crossIcon}</Space>,
          "Plus": <Space>{crossIcon}</Space>,
          "Max": <Space>{checkIcon}<a href="https://assessprep.zendesk.com/hc/en-us/articles/18650750166173-AI-Writing-Assistant" target="_blank" rel="noopener noreferrer">Learn more</a></Space>
        },
        {
          "label": "Paper Mode",
          "Standard": <Space>{crossIcon}</Space>,
          "Plus": <Space>{crossIcon}</Space>,
          "Max": <Space>{checkIcon}</Space>
        },
        {
          "label": "Translate Assessment",
          "Standard": <Space>{crossIcon}</Space>,
          "Plus": <Space>{crossIcon}</Space>,
          "Max": <Space>{checkIcon}</Space>
        }
      ]
    },
    {
      "category": "authoring",
      "label": "Authoring",
      "features": [
        {
          "label": "Interactive Question Types",
          "Standard": "15 question types",
          "Plus": "Additional Audio/Video question types",
          "Max": "Additional Audio/Video question types"
        },
        {
          "label": "Math Editor",
          "Standard": "Basic",
          "Plus": "Advanced (Wiris editor)",
          "Max": "Advanced (Wiris editor)"
        },
        {
          "label": "MYP Questionbank",
          "Standard": "20 Assessments across 10 subjects",
          "Plus": "350+ Assessments across 14 subjects",
          "Max": "350+ Assessments across 14 subjects"
        },
        {
          "label": "DP Questionbank",
          "Standard": "No Assessments",
          "Plus": "500+ Assessments across 18 subjects",
          "Max": "500+ Assessments across 18 subjects"
        },
      ]
    },
    {
      "category": "monitoring",
      "label": "Monitoring",
      "features": [
        {
          "label": "Spell Check",
          "Standard": <Space>{crossIcon}</Space>,
          "Plus": <Space>{checkIcon}</Space>,
          "Max": <Space>{checkIcon}</Space>
        },
        {
          "label": "Live Attempt Status",
          "Standard": <Space>{crossIcon}</Space>,
          "Plus": <Space>{checkIcon}</Space>,
          "Max": <Space>{checkIcon}</Space>
        }
      ]
    },
    {
      "category": "grading",
      "label": "Grading & Analytics",
      "features": [
        {
          "label": "Anonymized Marking of Tests",
          "Standard": <Space>{crossIcon}</Space>,
          "Plus": <Space>{checkIcon}</Space>,
          "Max": <Space>{checkIcon}</Space>
        },
        {
          "label": "Media Comments",
          "Standard": <Space>{crossIcon}</Space>,
          "Plus": <Space>{checkIcon}</Space>,
          "Max": <Space>{checkIcon}</Space>
        },
        {
          "label": "Assessment Analytics",
          "Standard": <Space>{crossIcon}</Space>,
          "Plus": <Space>{checkIcon}</Space>,
          "Max": <Space>{checkIcon}</Space>
        }
      ]
    }
  ];

  const closeUpgradePlanModal = () => {
    dispatch(
      orgActions.setShowUpgradePlanModalSuccess({
        show: false,
        feature_code: null,
      })
    );
  };

  const requestPlanUpgrade = () => {
    dispatch(
      orgActions.requestPlanUpgrade(
        { request_from: feature_code },
      )
    );
  };

  useEffect(() => {
    if (show) {
      const updatedDataSource = UpgradePlanSubscriptionData.flatMap((item, index) => [
        {
          key: `category-${index}`,
          category: item.label,
          isCategory: true,
          isAIAssistant: item.label === "AI Assistant",
        },
        ...mapFeaturesToDataSource(item, index),
      ]);
      setDataSource(updatedDataSource);
    }

    // if (
    //   show &&
    //   (feature_code === "grade_with_ai" ||
    //     feature_code === "ai_generate_questions_with_prompt")
    // ) {
    //   const aiAssistantRow = document.querySelector(".ai-assistant-row");
    //   console.log("aiAssisstantRow==>", aiAssistantRow);
    //   if (aiAssistantRow) {
    //     aiAssistantRow.scrollIntoView({ behavior: "smooth", block: "start" });
    //   }
    // }
    
  }, [show, feature_code]);

    /* This code is responsible for transforming subscription plan data into a format
  that can be used by a table component. It includes functions to map features
  to table rows, determine which icons to display based on feature availability,
  and configure the columns of the table based on the current user's plan. */
  // const getIconForFeaturePlan = (value) => {
  //   if (typeof value === "boolean") {
  //     return value ? (
  //       <CheckOutlined className="icon-green" />
  //     ) : (
  //       <CloseOutlined className="icon-red" />
  //     );
  //   }
  //   return value;
  // };

  // Convert the features of each plan into a format suitable for the data source
  const mapFeaturesToDataSource = (planItem, planIndex) => {
    return planItem.features.map((feature, featureIndex) => ({
      key: `plan-${planIndex}-feature-${featureIndex}`,
      category: "",
      feature: feature.label,
      Standard: feature.Standard,
      Plus: feature.Plus,
      Max: feature.Max,
    }));
  };

  const getFilteredColumns = (currentPlan) => {
    const columns = [
      {
        title: "Feature",
        dataIndex: "category",
        key: "category",
        render: (text, record) =>
          record.isCategory ? <strong>{text}</strong> : record.feature,
      },
    ];

    if (currentPlan === "Standard") {
      columns.push({
        title: "Standard (Current)",
        dataIndex: "Standard",
        key: "Standard",
        align: "center",
      });
    }

    if (currentPlan === "Plus") {
      columns.push({
        title: "Plus (Current)",
        dataIndex: "Plus",
        key: "Plus",
        align: "center",
      });
    }

    columns.push({
      title: <strong>Max</strong>,
      dataIndex: "Max",
      key: "Max",
      align: "center",
    });

    return columns;
  };

  return (
    <Modal
      title={mrIntl("useUpgradePlanFeature.upgrade_to_max")}
      open={show}
      onCancel={closeUpgradePlanModal}
      footer={null}
      width="60%"
      zIndex={1050}
      className="upgrade-plan-modal"
    >
      <Table
        dataSource={dataSource}
        columns={getFilteredColumns(currentPlan)}
        pagination={false}
        scroll={{ y: 550 }}
        rowClassName={(record) =>
          record.isCategory
            ? record.isAIAssistant
              ? "ai-assistant-row"
              : "category-row"
            : ""
        }
        className="upgrade-modal-table"
      />

      <div className="upgrade-max-container">
        <Button
          className="upgrade-button-primary"
          // type="primary"
          variant="solid"
          color="purple"
          size="large"
          onClick={() => requestPlanUpgrade()}
        >
          {mrIntl("useUpgradePlanFeature.upgrade_to_max")}
        </Button>
      </div>
    </Modal>
  );
}

export default UpgradePlanModal;