import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firebaseTokenSelector, isFirebaseAuthenticatedSelector, getFirebaseTokenLoadingSelector, userInfoSelector, userResponsesSelector, signInToFirestoreErrorSelector } from "./selector";
import { actions } from "./redux";
import { executeConnectionObject, realtimeBatchActions, unsubscribeRealtimeListenerWhenPromise } from '/src/views/Experiences/ExperienceShow/FirestoreInteractions/firebaseHelper';
import { Alert, Button, Input, Result, Space, Tooltip } from 'antd';
import { firebaseAuth, firestoreDB, realtimeDB } from "/src/config/initializers";
import { currentUserSelector, appRegionSelector, getEmbeddedSelector } from '/src/views/Auth/Login/selector';
import Spinner from '/src/components/UI/Spinner/Spinner';
import { InfoCircleOutlined } from "@ant-design/icons";
import { getItemsFromOfflineDB } from "/src/lib/OfflineDatabase/OfflineDatabase"
import supabaseDB from '/src/config/supabaseInitializer';
import QuitBtn from '/src/components/UI/QuitBtn';
import { appTypeSelector } from '/src/App/OfflineApp/offlineAppSelectors';
import MrTranslate, { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

// import React, { Component } from 'react';

class FirestoreInteractions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // currentView: "", // start, end, submit
    };
    this.userInfoSubscription = null;
    this.experienceInfoSubscription = null;
    this.realtimeUserInfoListener = null;
    this.userStatusDatabaseRef = null;
    this.userRealtimeDBRPresenceRef = null;
    this.backupInterval = null;

  }
  componentWillUnmount() {
    console.log("FSI check - componentWillUnmount");
    const { appRegion, experience } = this.props;
    // is now a promise after making executeConnectionObject async after china supabase
    unsubscribeRealtimeListenerWhenPromise(this.realtimeUserInfoListener)
    unsubscribeRealtimeListenerWhenPromise(this.realtimeExperienceInfoListener)

    if (appRegion === "china") {      
      supabaseDB().channel(`online_users_eId${experience.id}`).unsubscribe()
    }

    if (this.backupInterval) {
      this.backupInterval.clearInterval();
    }
    if (this.userRealtimeDBRPresenceRef){
      console.log("FSI check - this.userRealtimeDBRPresenceRef off");
      this.userRealtimeDBRPresenceRef.off();
    }
    if (this.userStatusDatabaseRef){
      this.userStatusDatabaseRef.off();
    }
  }
  realtimeDbPresence = async (args) => {
    console.log("statingDocPath");
    const {experienceId, currentUser} = args;
    const { experience, appRegion } = this.props;
    const currentUserId = currentUser.id;
    let userInfoConnectionOptions = {
      onlyBase: true, 
      realtime: true,
    }
    let experiencesConnectionOptions = {
      onlyBase: true, 
      realtime: true,
    }
    let userInfoSuccessFunc = (userInfoFirestoreDB) => {
      this.props.setLocalUserInfo({ userInfo: userInfoFirestoreDB });
    }
    let experiencesSuccessFunc = (experienceInfo) => {
      this.props.setLocalExperienceInfo(experienceInfo);
    }
    let errorFunc = (error) => {
      console.log("error in starting student side realtime sub", error);
      // TODO: Report to Sentry
    }
    
    if (appRegion === "china") {
      try {
        const channel = supabaseDB().channel(`online_users_eId${experienceId}`, {
          config: {
            presence: {
              key: currentUser.id,
            },
          },
        })
        // channel.on('presence', { event: 'sync' }, () => {
        //   console.log('supabaseDB presence Online users: ', channel.presenceState())
        // })
        channel.subscribe(async (status) => {
          if (status === 'SUBSCRIBED') {
            const status = await channel.track({ user_id: currentUser.id, last_changed: Date.now() })
            console.log("supabaseDB subscribe status", status)
          }
        })
      } catch (error) {
        console.log("supabaseDB presence sub student side error", error);
      }

      userInfoConnectionOptions.params = {
        uuid: ["eq", `eId${experienceId}_uId${currentUserId}`]
      }
      userInfoSuccessFunc = (payload) => {
        console.log('supabaseDB user info Change received student side!', payload)
        this.props.setLocalUserInfo({ userInfo: payload.new })
      }

      experiencesConnectionOptions.params = {
        uuid: ["eq", `eId${experienceId}`]
      }
      experiencesSuccessFunc = (payload) => {
        console.log('supabaseDB exp Change received student side!', payload)
        this.props.setLocalExperienceInfo(payload.new)
      }
      

    } else {
      this.userStatusDatabaseRef = realtimeDB.ref(`experiences/${experienceId}/users/${currentUserId}`);
    
      var isOfflineForDatabase = {
        state: 'offline',
        last_changed: Date.now()
      };

      var isOnlineForDatabase = {
        state: 'online',
        last_changed: Date.now()
      };

      this.userStatusDatabaseRef.on('value', (snap) => {
        let currentVal = snap.val() !== null ? snap.val() : { state: 'offline'} ;
        if (currentVal.state === 'offline') {
          this.userStatusDatabaseRef.set(isOnlineForDatabase);
        }
      });
      this.userRealtimeDBRPresenceRef = realtimeDB.ref('.info/connected');
      // const parentThis = this;
      // console.log("parentThis 73", parentThis.props);
      this.userRealtimeDBRPresenceRef.on('value', (snapshot) => {
        console.log("snapshot.val", snapshot.val());
        console.log('snapshot', snapshot);
        if(snapshot.val() === true){
          console.log("firebase online is true");
          this.props.updateOnlineStatus({status: true});
          this.userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then( () => {
            // this.props.updateOnlineStatus({status: false});
            console.log("firebase online is disconnected");
            this.userStatusDatabaseRef.set(isOnlineForDatabase);
          });

        } else {
          console.log("firebase online is false");
          this.props.updateOnlineStatus({status: false});
        }
      }, (error) => {
        console.error("error in firebase offline ==>", error)
      });

     
    }

    if(currentUser.role === "student"){
      this.realtimeUserInfoListener = executeConnectionObject(
        {
          collectionPath: `experiences/${experienceId}/user_info`,
          docId: `${currentUserId}`,
          tableName: "user_info",
          appRegion: appRegion,
        },
        userInfoConnectionOptions,
        userInfoSuccessFunc,
        errorFunc
      );

      // try {
      //   supabaseDB
      //   .channel(`public:user_info:uuid=eq.eId${experienceId}_uId${currentUser.id}`)
      //   .on('postgres_changes', { event: '*', schema: 'public', table: 'user_info', filter: `uuid=eq.eId${experienceId}_uId${currentUser.id}` }, 
      //   (payload) => {
      //     console.log('supabaseDB user info Change received student side!', payload)
      //     this.props.setLocalUserInfo({ userInfo: payload.new })
      //   })
      //   .subscribe()
      // } catch (error) {
      //   console.log("supabaseDB RL sub student side error", error);
      // }
      // try {
      //   supabaseDB
      //   .channel(`public:experiences:uuid=eq.eId${experienceId}`)
      //   .on('postgres_changes', { event: '*', schema: 'public', table: 'experiences', filter: `uuid=eq.eId${experienceId}` }, 
      //   (payload) => {
      //     console.log('supabaseDB exp Change received student side!', payload)
      //     this.props.setLocalExperienceInfo(payload.new)
      //   })
      //   .subscribe()
      // } catch (error) {
      //   console.log("supabaseDB RL sub student side error", error);
      // }
      
    }
    // we need to listen to experience info for paused/unpaused status on teacher side also
    this.realtimeExperienceInfoListener = executeConnectionObject(
      { 
        collectionPath: `experiences`, 
        docId: `${experienceId}`,
        tableName: "experiences",
        appRegion: appRegion,
      },
      experiencesConnectionOptions,
      experiencesSuccessFunc,
      errorFunc
    );

    // this.offsetRef = realtimeDB.ref(".info/serverTimeOffset");
    // this.offsetRef.on("value", function (snap) {
    //   var offset = snap.val();
    //   var estimatedServerTimeMs = new Date().getTime() + offset;
    // });
  }
  componentDidMount() {
    console.log("FSI check - componentDidMount");
    const { experience, currentUser, userInfo, appRegion, mobileUploadMode } = this.props;
    console.log('tests==>', userInfo)
    console.log('initFirebase 102', this.props)
    this.props.initFirebase({ experienceId: this.props.experience.id, currentStudentGroupIds: this.props.experience.current_student_group_ids }, {
      callback: this.realtimeDbPresence,
      mobileUploadMode: mobileUploadMode || false
    });
  }
  componentDidUpdate(prevProps, prevState) {
    // if(prevProps.firebaseToken !== this.props.firebaseToken && this.props.firebaseToken){
    //   // attempt to auth to firebase
    //   firebase.auth().signInWithCustomToken(this.props.firebaseToken).catch((error) => {
    //     console.log( "firebase auth error" );
    //     // notify here about all the events
    //     console.log( "error.code", error.code );
    //     console.log( "error.message", error.message );
    //   })
    // }
  }

  render() {
    const { experience, getFirebaseTokenLoading, signInToFirestoreError, currentUser, appRegion, appType, embedded } = this.props;
    console.log("firestore interaction props, getFirebaseTokenLoading", this.props, getFirebaseTokenLoading);
    const experienceId = experience.id;
    // if (appRegion === "china") {
    //   return <span />;
    // }
    if (getFirebaseTokenLoading) {
      return null
      // return <Spinner />
      // return <h1>Loading firebase token</h1>
    }
    if (signInToFirestoreError && currentUser.role === "student") { // TODO: show error for teacher also but decide how to
      // return <h2>fb token error - {JSON.stringify(signInToFirestoreError)}</h2>
      // return <div className='firestore-msg-alert'>
      //   <Alert
      //     className={"firestore-error"}
      //     message="Something went wrong Please go back..."
      //     type="error"
      //     showIcon
      //   ></Alert>
      //   <a href="/"> <Button className='firestore-error-button'>Click here Go Back</Button></a>
      // </div>
      let extra = []
      if(appType === "web"){
        if(embedded){
          extra.push(<Alert message="Please refresh the page" type="error" style={{display: "inline-block"}}/>)
        } else {
          extra.push(<a href="/"> <Button>Back home</Button></a>)
          extra.push(<Button onClick={() => { window.location.reload() }}>Refresh</Button>)
        }
      } else {
        extra.push(<Button onClick={() => { window.location.reload() }}>Refresh</Button>)
        extra.push(<QuitBtn key="firestore-error-view-quit-button" withLog={true} action={"quit_app_while_error_in_page"}/>)
      }

      let subtitle = <Space>
        <Alert message={<MrTranslate id={"FirestoreInteractions.critical_service_connection_error_student"}/>} type="error" style={{display: "inline-block", maxWidth: "500px"}}/>
      </Space>

      return <Result
        status="500"
        title={<MrTranslate id={"FirestoreInteractions.sorry_something_went_wrong"}/>}
        subTitle= {subtitle}
        extra={extra}
      />
    }

    return <React.Fragment>
      {/* <Button onClick={() => this.props.setUserInfo({userInfo: {currentView: "startTest"}, experienceId })}>Start test</Button>
      <Input onBlur={(e) => this.props.setUserResponse({experienceId: experience.id, 
        questionId: 1,
        userId: currentUser.id,
        userResponseId: `eId${experience.id}_uId${currentUser.id}_qId${1}`, userResponse: e.target.value || ""
      })}/>
      <div>Student responses</div>
      <div>{JSON.stringify(this.props.userInfo)}</div> */}
      {/* {JSON.stringify(this.props.userinfo)} */}
    </React.Fragment>;
  }
}

// export default FirestoreInteractions;

// const FirestoreInteractions = (props) => {
//   const {experience} = props;
//   const [state, setState] = useState();
//   const dispatch = useDispatch();
//   useEffect(() => {

//     // return () => {
//     //   cleanup
//     // }
//   }, [])
//   return <React.Fragment>
//     <div>Student responses</div>
//   </React.Fragment>;
// };


const makeMapStateToProps = () => {
  const mapStateToProps = (state) => {
    return {
      //  bar: getBarState(state)
      firebaseToken: firebaseTokenSelector(state),
      isFirebaseAuthenticated: isFirebaseAuthenticatedSelector(state),
      getFirebaseTokenLoading: getFirebaseTokenLoadingSelector(state),
      signInToFirestoreError: signInToFirestoreErrorSelector(state),
      appRegion: appRegionSelector()(state),
      appType: appTypeSelector()(state),
      embedded: getEmbeddedSelector()(state),
      userInfo: userInfoSelector(state),
      // userResponses: userResponsesSelector()(state),
      currentUser: currentUserSelector()(state),
    };
  };
  return mapStateToProps;
};
const mapDispatchToProps = (dispatch) => {
  return {
    initFirebase: (params, options = {}) => {
      dispatch(actions.getFirebaseToken(params, options));
    },
    // for teacher/admin
    setUserInfo: (params, options) => {
      dispatch(actions.setUserInfo(params, options));
    },
    setLocalUserInfo: (params, options) => {
      dispatch(actions.setUserInfoSuccess(params, options));
    },
    setLocalExperienceInfo: (params, options) => {
      dispatch(actions.setExperienceInfoSuccess(params, options))
    },
    setUserResponse: (params, options) => {
      dispatch(actions.setUserResponse(params, options));
    },
    updateOnlineStatus: (params) => {
      dispatch(actions.updateOnlineStatusSuccess(params));
    },
  };
};
// FirestoreInteractions.defaultProps = {}

FirestoreInteractions.propTypes = {
  experience: PropTypes.object.isRequired,
}

export default connect(makeMapStateToProps, mapDispatchToProps)(FirestoreInteractions);