import React from "react";
import PropTypes from "prop-types";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const OrgProgrammeFilter = (props) => {
  return <span><MrTranslate id={"OrgProgrammeFilter.orgprogramme_filter"} /></span>;
};

OrgProgrammeFilter.defaultProps = {};

OrgProgrammeFilter.propTypes = {};

export default OrgProgrammeFilter;
