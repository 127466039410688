import React, { useState, useEffect } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { Button, Modal, Tooltip, Row, Tag, Col } from "antd";
import { VideoCameraOutlined, CloseOutlined } from "@ant-design/icons";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { currentUserSelector, enabledFeaturesSelector} from "/src/views/Auth/Login/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import {
  checkMob,
  getZoomJoinUrlForEmbedded,
  getRegexExpFor,
} from "/src/lib/utils/helperMethods";
import { useSelector, useDispatch } from "react-redux";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { actions as fireStoreReduxActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { currentViewSelector } from "../FirestoreInteractions/selector";
import { experienceViewModeSelector } from "../../selector";
import { getWhiteListUrls, isVideoUrlAllowedInsideApp } from "/src/App/OfflineApp/offlineAppHelper";

const ExternalVideoCallButton = (props) => {
  const {
    videoCallLink = "",
    showTitle = false,
    experienceSettings = {},
    buttonShape = "round",
    experienceId = "",
  } = props;
  const isMobile = checkMob();
  const mrIntl = useTranslate()
  const appType = useSelector(appTypeSelector());
  const currentView = useSelector(currentViewSelector);
  console.log("currentView====>>>>", currentView)
  const dispatch = useDispatch();
  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const experienceViewMode = useSelector(experienceViewModeSelector());
  const whitelistUrls = getWhiteListUrls(experienceSettings);

  
  let experienceMode = experienceSettings.mode
  console.log("experienceMode====>>>>", experienceMode)

  const newJoinViewWithDynamicConfigEnabled = enabledFeatures.new_join_view_with_dynamic_config
  const hideVideoCallButton = newJoinViewWithDynamicConfigEnabled && appType === "ios" && experienceSettings.mode === "offline"

  const isStartOrEndTestView = ["startTest", "endTest", "resumeTest", "joined"].includes(currentView);
  const buttonType = newJoinViewWithDynamicConfigEnabled && (isStartOrEndTestView || experienceViewMode === "apPreviewTest") ? "text" : "";

  const getFinalExternalUrl = (url) => {
    let finalExternalUrl =
      videoCallLink.indexOf("http") > -1
        ? videoCallLink
        : `https://${videoCallLink}`;

    let isZoomUrl = new RegExp(getRegexExpFor("zoom")).test(finalExternalUrl)
    if(isZoomUrl){
      finalExternalUrl = getZoomJoinUrlForEmbedded(finalExternalUrl)
    }

    return finalExternalUrl;
  }

  // Final conditions (same for ExperienceList and StartView):

  // for web
  // online, online_secure -> enabled = true and action = "openInBrowser"
  // lockdown -> no such case

  // for mac, ios
  // online, online_secure -> enabled = true and action = "openInBrowser"
  // lockdown -> 
    // if url = (zoom) AND isTabFeatureSupported -> enabled = true and action = "openInApp"
    // else -> enabled = false and tooltip = "Join video call before starting lockdown application"
  
  // for seb
  // online, online_secure -> no such case
  // lockdown -> 
    // if url = (zoom or meet) -> enabled = true and action = "openInApp" # IMP: needs config file update so flag off until that is done
    // else -> enabled = false and tooltip = "Join video call before starting lockdown application"

  // for cros
  // enabled = false and tooltipTitle = "Not yet supported inside Chromebook Kiosk mode"

  // defaults for web and other conditions above
  const buttonConfig = {
    enabled: appType === "web"  ? true : false, // || import.meta.env.VITE_MODE === "staging"
    // enabled: true, 
    action: "openInBrowser", // or "openInApp"
    tooltipTitle: appType === "web"  ? mrIntl("ExternalVideoCallButton.join_video_call") : mrIntl("ExternalVideoCallButton.join_video_call_before_starting_lockdown_application"), // || import.meta.env.VITE_MODE === "staging"
    // tooltipTitle: "Join video call",
    finalExternalUrl: getFinalExternalUrl(videoCallLink),
    showTitle: showTitle && !isMobile,
  }

  
  // experienceMode = anything -> if opened in lockdown app, will open inside app if possible
  //if(appType !== "web" && import.meta.env.VITE_MODE !== "production"){ // TODO: TEMP turned off on prod until apps updated
  if(appType !== "web"){
    buttonConfig.enabled = isVideoUrlAllowedInsideApp(buttonConfig.finalExternalUrl, appType) ? true : false
    if(buttonConfig.enabled){
      buttonConfig.action = "openInApp"
    }else{
      if(appType === "cros"){
        buttonConfig.tooltipTitle = mrIntl("ExternalVideoCallButton.not_yet_supported_inside_Chromebook_Kiosk_mode")
      }else{
        buttonConfig.tooltipTitle = mrIntl("ExternalVideoCallButton.join_video_call_before_starting_lockdown_application") 
      }
    }
  }
  
  const openExternalLink = () => {
    if(!buttonConfig.enabled){
      return false;
    }
    // disabled always for cros as of now so ignoring that
    if(appType === "web" || appType === "seb"){
      // do nothing, target blank handles it
    }
    else if (appType === "mac" || appType === "ios") {
      let executeMethods = []; 
      executeMethods.push( {
        key: buttonConfig.action === "openInApp"
          ? "openNewWindow"
          : "openLinkInBrowser", // allow open url in new browser for earlier version (existing functionality) for mac app
        value: buttonConfig.action === "openInApp"
          ? buttonConfig.finalExternalUrl + "------" + "Video Call"
          : buttonConfig.finalExternalUrl,
      });
      appType === "mac" && executeMethods.push({ key: "setWhitelistedUrls", value: whitelistUrls })
      //Call setWhitelistedUrls to handle external ulrs of video call (meet/zoom) button from experience list.
      dispatch(
        offlineAppActions.executeMethod(executeMethods)
      );
    }

    dispatch(
      fireStoreReduxActions.setUserInfo(
        { experienceId: experienceId },
        {
          log: {
            logging: true,
            action: "joined_external_video_call",
            current_value: {
              link: buttonConfig.finalExternalUrl,
            }
          },
        }
      )
    );
  }

 {/* { Dummy function example for future refrence to call openUrlInChildWindow  } */}
  // const openUrlInChildWindow = (url) => {
  //   if (appType === "mac" || appType === "ios") {
  //     dispatch(
  //       offlineAppActions.executeMethod([
  //         {
  //           key: "openChildWindow", // allow open url in new browser for earlier version (existing functionality) for mac app
  //           value:url+ "------" + "Video Call"
  //         },
  //       ])
  //     );
  //   }
  // }

  return (
    !hideVideoCallButton && <Tooltip
      title={buttonConfig.tooltipTitle}
      placement={newJoinViewWithDynamicConfigEnabled ? "left" : "bottom"}
    >
      <Button
        type= {buttonType}
        shape={buttonShape}
        icon={<VideoCameraOutlined />}
        disabled={!buttonConfig.enabled}
        href={buttonConfig.finalExternalUrl}
        target="_blank"
        onClick={() => {
          openExternalLink();
        }}
      >
        {buttonConfig.showTitle
          ? mrIntl("ExternalVideoCallButton.video_call")
          : ""}
      </Button>
      {/* { Dummy button example for future refrence to open URL in child window  } */}
       {/* {((import.meta.env.VITE_MODE == "staging" || import.meta.env.VITE_MODE == "development") && currentUser.role === "student")  && (
        <>
          <Button
            //disabled = {import.meta.env.VITE_MODE != "staging"}
            shape={isMobile ? "circle" : "round"}
            icon={<VideoCameraOutlined />}
            href={"https://www.youtube.com/"}
            target="_blank"
            onClick={() => {
              openUrlInChildWindow("https://www.youtube.com/");
            }}
          >
            {"youtube Link"}
          </Button>
        </>
      )}  */}
    </Tooltip>
  );
};

export default ExternalVideoCallButton;