import React, { useEffect, useState } from "react";

import MediaUI from "/src/components/UI/Media/MediaUI";
import { useDispatch, useSelector } from "react-redux";
import { programmeSubjectsSelector } from "../selector";
import MediaShow from "/src/components/UI/Media/MediaShow";
import { actions as programmeSubjectActions } from "../redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { getUniqueId } from "/src/lib/utils/helperMethods";


const SubjectResourcesUpload = (props) => {
  const programmeSubjectId  = props.formInstance.getFieldValue(["id"])
  const selectedProgrammeId  = props.formInstance.getFieldValue(["selected_programme_id"])
  // let programSubjectResources = props.value || []
  const [programSubjectResources, setProgramSubjectResources] = useState([]);
  console.log("programSubjectResources===>", programSubjectResources);
  const programmeSubjectGroups = useSelector(programmeSubjectsSelector())
  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  console.log('programmeSubjectGroups==>', programmeSubjectGroups)

  var programSubjects = [];
  programmeSubjectGroups.map((subjectGroups) => {
    programSubjects = [...programSubjects, ...subjectGroups.children_attributes]
  })
  const mediaFormInitialState = {
    modal: false,
    showUploadList: true,
    loading: false,
    visible: false,
    multiple: true,
    title: mrIntl("CommonText.insert_files"),
    okText: mrIntl("CommonText.save"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    requireFooter: false,
    limit: 5,
    limitMessage: mrIntl("SubjectResourcesUpload.max_upload_limit_reached"),
    content_type: "embed",
    supportedTypes: "image/jpg, image/jpeg, image/png, image/gif, image/bmp, application/pdf",
    supportedFormats: "jpg, jpeg, png, gif, bmp, pdf",
    disableUploadURLToS3: true,
    selectConfig: {
      multiple: true,
      maxSize: "20 MB",
      defaultFileList: [],
    },
  };
  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );

  useEffect(() => {
    // setProgramSubjectResources([]);
    // let tempProgramSubjectResources = [];
    // console.log('tempProgramSubjectResources==>',programSubjects.find(ps => ps.id === programmeSubjectId) )
    // programSubjects.map((ps) => {
    //   if (ps.id === programmeSubjectId) {
    //     console.log('programmeSubjectId==>', ps)
    //     tempProgramSubjectResources =
    //       ps.attachments_json.resources !== undefined
    //         ? programSubjectResources.concat(ps.attachments_json.resources)
    //         : [];
    //   }
    // });
    // setMediaFormSetting({
    //   ...mediaFormSetting,
    //   selectConfig: {
    //     ...mediaFormSetting.selectConfig,
    //     defaultFileList: tempProgramSubjectResources,
    //   },
    // }); 
    dispatch(
      programmeSubjectActions.show({ id: programmeSubjectId }, {
        successCallback: (data) => {
          // const resources = data.data.programme_subject.data.attributes.attachments_json && data.data.programme_subject.data.attributes.attachments_json.resources
          const resources = data && data.data && data.data.programme_subject && data.data.programme_subject.data && data.data.programme_subject.data.attributes && data.data.programme_subject.data.attributes.attachments_json  && data.data.programme_subject.data.attributes.attachments_json.resources
          setProgramSubjectResources(resources);
          setMediaFormSetting({
            ...mediaFormSetting,
            triggerFileListChange: getUniqueId(),
            selectConfig: {
              ...mediaFormSetting.selectConfig,
              controlledFileList: resources,
            },
          });
        }
      })
    );
  }, [programmeSubjectId]);

  useEffect(() => {
    setMediaFormSetting({
      ...mediaFormSetting,
      triggerFileListChange: getUniqueId(),
      selectConfig: {
        ...mediaFormSetting.selectConfig,
        controlledFileList: programSubjectResources,
      },
    });
    console.log('programSubjectResources==>', programSubjectResources)
  }, [programSubjectResources && programSubjectResources.length]);

  const onSaveMedia = (files) => {
    if (props.onChange) {
      props.onChange(files);
    }
    setProgramSubjectResources(files)
    // setMediaFormSetting((prevMediaFormSetting) => ({
    //   ...prevMediaFormSetting,
    //   selectConfig: {
    //     ...prevMediaFormSetting.selectConfig,
    //     defaultFileList: files,
    //   },
    // }));
    const data = {
      id: programmeSubjectId,
      selected_programme_id: selectedProgrammeId,
      attachments_json: {
        resources: files
      }
    }
    const programmeSubjects = {
      id: programmeSubjectId,
      programme_subjects: { ...data },
    };
    console.log('onSaveMedia==>', mediaFormSetting, files)
    dispatch(
      programmeSubjectActions.update(programmeSubjects, {
        success: {
          showMessage: true,
          message: "File added",
        },
      })
    );
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "",
      // triggerSaveAction: 0,
    });
  };


  console.log("SubjectResourcesUpload props ==>", mediaFormSetting);

  return (
    <div className="subject-resource">
      <span className="subject-resource-upload">
        <MediaUI
          // contentType={"embed"}
          computer={true}
          url={false}
          mediaFormSetting={mediaFormSetting}
          setMediaFormSetting={setMediaFormSetting}
          onSaveMedia={onSaveMedia}
        ></MediaUI>
      </span>
      {
        <span className="subject-resources-upload-list">
          {programSubjectResources && programSubjectResources.length !== 0 && (
            <>
            <br />
            {mrIntl("SubjectResourcesUpload.attached_files")}:
              <br />
              {programSubjectResources.map((file, index) => (
                <MediaShow file={file}>{`File ${index + 1}`}</MediaShow>
              ))}
            </>
          )}
        </span>
      }
    </div>
  );
};

export default SubjectResourcesUpload;