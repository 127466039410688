import React, { useEffect, useState } from "react";
// import GGBApplet from "./deployggb";
// import { actions } from "./redux";
import { Row, Col, Button, Switch, Select } from "antd";
import styled from 'styled-components'
import { AddButton, CheckAnswerButton, QuickCheckBox, TryAgainButton, CustomTextButton, Segment, SegmentData, SegmentSetting, InputArea, InlineInputArea, DeleteButton, HorizontalDroppableContainer, DragDropArea, VerticalDroppableContainer, DroppableHeader, InlineDroppablesContainer, InlineDroppable, ShowText, ShowTextIndex, ShowTextContent, ShowTextFile } from '/src/components/UI/Segment/UIHelper'
import DefaultCKE from "/src/components/UI/CKEditor/DefaultCKE"
import SegmentItemFooter from '/src/views/Segments/SegmentItemDetail/SegmentItemFooter'
import SegmentItemHeader from '/src/views/Segments/SegmentItemDetail/SegmentItemHeader'
import { getUniqueId } from "/src/lib/utils/helperMethods";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import DropDownOption from "/src/views/Segments/CKEFill/DropDownOption"

const InlineDropDownInput = props => {
  console.log("Drop Down Input props ==>", props);

  const { Option } = Select;
  const { answerMode, gaps, handleChange, autoCheckMode, stateSetting, setRenderMath } = props
  const id = props['data-id']
  const gap = gaps.find(gap => gap.id == id) || {}

  console.log("gap before render ==>", gap)
  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }, [])

  return (
    <div
      key={`inline-input-area-${gap.id}`}
      className="inline-dropdown-input"
    >
      {
        <Select
          value={gap.answer && gap.answer.id}
          style={{
            minWidth: "100px"
          }}
          dropdownMatchSelectWidth={false}
          className={
            (autoCheckMode || stateSetting.quickCheckModeEnabled)
              ? gap.correct
                ? 'correct-answer'
                : 'incorrect-answer'
              : ''
          }
          onChange={(value) => handleChange(gap, value)}
          disabled={!answerMode || stateSetting.quickCheckModeEnabled}
        >
          {gap.items && gap.items.map((item) => (
            <Option key={`option-${item.id}`} value={item.id}>
              <DropDownOption
                data={item.content}
                setRenderMath={props.setRenderMath}
              ></DropDownOption>
            </Option>
          ))}
        </Select>
      }
    </div>

  );
};
export default InlineDropDownInput;