import React from "react";
import { MrCrudView } from "mr_react_framework";
import { actions } from "./redux";
import PlansList from "./PlansList/PlansList";
import commonSelectors from "/src/App/commonSelectors";
import { SelectFeatures } from "/src/components/AppSpecific/SelectSubject/SelectSubject";

const returnForm = () => {
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      opts.values.plan_features_attributes = opts.values.plan_features;

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        name: {
          type: "string",
          placeholder: "Name",
        },
        code: {
          type: "string",
          default: "",
          placeholder: "Code",
        },
        desc: {
          type: "string",
          default: "",
          placeholder: "Description",
        },
        // ADD tiers_json
        // array type form with title, max_users, price
        tiers_json: {
          type: "array",
          span: 24,
          min: 1,
          max: 10,
          title: "Tiers",
          default: [{}],
          items: {
            type: "object",
            properties: {
              name: {
                type: "string",
                title: "Name",
                span: 6,
              },
              max_users: {
                type: "string",
                title: "Max users",
                span: 6,
              },
              price: {
                type: "string",
                title: "Price",
                span: 6,
              },
            },
          },
        },
        plan_features: {
          type: "array",
          title: "Plan features",
          addButtonTitle: "+ Add Feature",
          span: 24,
          min: 0,
          max: 100,
          default: [{}],
          items: {
            type: "object",
            properties: {
              feature_id: {
                type: "string",
                title: "Feature",
                widget: SelectFeatures,
                span: 8,
              },
              _destroy: {
                type: "string",
                title: "Remove",
                widget: "CheckboxWidget",
                valuePropName: "checked",
                span: 8,
              },
            },
          },
        },
      },
    },
  };
  return form;
};

const Plans = MrCrudView({
  resourceName: "plans",
  singleResourceName: "plan",
  // displayName: "Create/Edit plan",
  resourceUrl: "/plans",
  // filter: BoardsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  // listItem: BoardListItem,
  forms: [{ name: "plans", form: returnForm }],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span>Item Detail {JSON.stringify(props.item)}</span>;
      },
    },
    itemDetail: {
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span>Item Detail {JSON.stringify(props.item)}</span>;
      },
      // widget: ProgrammeItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      widget: (props) => {
        return <span>Filter</span>;
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      widget: PlansList,
      // widget: (props) => {
      // return <span>List</span>;
      // },
    },
  },
});

// Plans.defaultProps = {};

Plans.propTypes = {};
export default Plans;
