import React from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const HelpCenterButton = () => {
  let finalRender = null;
  const currentUser = useSelector(currentUserSelector());
  const mrIntl = useTranslate();
  const handleClick = () => {
    const url = "https://assessprep.zendesk.com/hc/en-us"
    window.open(url, '_blank');
  };
  if (currentUser.role === "admin" || currentUser.role === "teacher") {
    finalRender = (
      <Button icon={<QuestionCircleOutlined />} type="text" onClick={() => handleClick()}>
        {mrIntl("HelpCenterButton.help_center")}
      </Button>
    );
  }

  return finalRender
}

export default HelpCenterButton