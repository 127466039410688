import React, {useState} from "react";
import PropTypes from "prop-types";
import { MrSelect } from "mr_react_framework";
import {Route, useRouteMatch} from "react-router-dom";
import {Input, Drawer, Button, Space, Checkbox} from "antd";
import {actions as genericActions} from "/src/App/genericRedux";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import { SelectProgrammeSubject, SelectProgrammeGrade, SelectProgramme, SelectTagType, SelectSubjectGroup }  from "/src/components/AppSpecific/SelectSubject/SelectSubject"
import {
  FilterOutlined,
} from "@ant-design/icons";


const TagsFilter = (props) => {
  const {path, url} = useRouteMatch();
  const { currentUser } = props;

  console.log("props segment filter", props)

  let extraFilters = [];
  let by_programme_id = null
  let by_programme_subject_id = null
  let by_programme_grade_id = null
  let by_type_c = null

  if(props.by_type_c){
    by_type_c = props.by_type_c
  }

  extraFilters.push(
    <SelectTagType key="selectSCTypeFilter" value={by_type_c} onChange={(value) => props.setParams({by_type_c: value})} config={{widgetConfig: {style: {width: "150px"}}}}/>
  )

  if(props.by_programme_id){
    by_programme_id = parseInt(props.by_programme_id)
  }

  extraFilters.push(
    <SelectProgramme key="selectProgrammeSyllabusFilter" value={by_programme_id} onChange={(value) => {props.setParams({by_programme_id: value, by_programme_grade_id: null, by_programme_subject_id: null})}} config={{widgetConfig: {style: {width: "150px"}}}}/>
  )

  
  if(props.by_programme_id){
    if(props.by_programme_grade_id){
      by_programme_grade_id = parseInt(props.by_programme_grade_id)
    }
    extraFilters.push(
      <SelectProgrammeGrade key="selectGradeSegmentsFilter" value={by_programme_grade_id} onChange={(value) => props.setParams({by_programme_grade_id: value})} config={{forceReload: true, params: {by_programme_id: props.by_programme_id}, widgetConfig: {style: {width: "150px"}}}}/>
    )
  }
  if(props.by_programme_id){
    if(props.by_programme_subject_id){
      by_programme_subject_id = parseInt(props.by_programme_subject_id)
    }
    extraFilters.push(
      <SelectSubjectGroup key="selectSubjectGroupSegmentsFilter" value={by_programme_subject_id} onChange={(value) => props.setParams({by_programme_subject_id: value})} config={{forceReload: true, params: {by_programme_id: props.by_programme_id, by_type_c: "group"}, widgetConfig: {style: {width: "350px"}}}}/>
    )
    extraFilters.push(
      <SelectProgrammeSubject key="selectSubjectSegmentsFilter" value={by_programme_subject_id} onChange={(value) => props.setParams({by_programme_subject_id: value})} config={{forceReload: true, params: {by_programme_id: props.by_programme_id, by_type_c: "group"}, widgetConfig: {style: {width: "150px"}}}}/>
    )
    
  }

  extraFilters.push(
    <ResetFilter setParams={props.setParams} />
  )

  return extraFilters;
};

TagsFilter.defaultProps = {};

TagsFilter.propTypes = {};

export default TagsFilter;
