
import React, { useCallback } from "react";
import { Route, useRouteMatch, useLocation } from "react-router-dom";
import { getUTCDate } from "/src/lib/utils/helperMethods";
import Experiences from "/src/views/Experiences/Experiences";
import dayjs from "dayjs";

const MyAssessments = (props) => {
  console.log("MyAssessments props", props);
  const match = useRouteMatch();
  const { path, url } = match;
  const location = useLocation();
  console.log("MyAssessments props", match, location, path, url);

  return (
    <React.Fragment>
      <Route
        path={`${path}`}
        render={(routeProps) => (
          <Experiences
            isRoute={false}
            {...routeProps}
            params={{
              // by_date_range: [getUTCDate({type: "startOf", daysDiff: -180}).toISOString(), getUTCDate({type: "endOf"}).toISOString()],
            }}
          />
        )}
      />
    </React.Fragment>
  );
};

MyAssessments.defaultProps = {};

MyAssessments.propTypes = {};

export default MyAssessments;
