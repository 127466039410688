import React from "react";
import { List, Card } from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
// import { useRouteMatch } from "react-router-dom";

const SegmentListItem = (props) => {
  console.log("SEGMENT LIST props SegmentListItem", props);
  const {
    item,
    // edited,
    // deleted,
    actionBtns,
  } = props;
  const mrIntl = useTranslate()
  // const {path, url} = useRouteMatch();
  return (
    <React.Fragment>
      <div>
        <List.Item>
          <Card title={`Segment_${item.id}`}>
            {/* <Link to={`${url}/${item.id}`}> {item.id} - {item.name} </Link>   */}
            {JSON.stringify(item)}
            {actionBtns}
            {/* can replace actionbtns with custom and edit can simply set the resource below and hide segment card -> becomes edit form */}
            {/* create button will show create form below */}
          </Card>
        </List.Item>
        <List.Item>
          <Card title={"Segment Form"}>
            {props.renderForm({
              name: "segments",
              config: { isModal: false, visible: true },
              // resource: item //use this for edit
            })}
          </Card>
        </List.Item>
      </div>
    </React.Fragment>
  );
};
SegmentListItem.defaultProps = {};

SegmentListItem.propTypes = {};

export default SegmentListItem;
