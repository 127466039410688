import React, { useEffect, useState } from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber, Button } from "antd";
import { MrSelect, FormRenderer } from "mr_react_framework";
// import moment from "moment";
import { useSelector } from "react-redux";
import { actions as genericActions } from "/src/App/genericRedux";
import {
  GenericWidgetCreator,
  SelectGrade as SelectOrgGrade,
  SelectSubject as SelectOrgSubject,
  SelectProgrammeSubject,
  SelectProgrammeGrade,
  SelectProgram,
  SelectRubric,
  SelectTreeSyllabusContents,
  SelectDifficultyLevel,
  SelectTag,
  SelectTeachingLevel,
  SelectDepthOfKnowledge,
  SelectCommandTerm,
  SelectAssessmentObjective,
} from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import questionForm from "./QuestionForm";
import resourceForm from "./ResourceForm";
import sectionForm from "./SectionForm";
import textForm from "./TextForm";
import settingsForm from "./SettingsForm";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import RubricCriteriaPointsForm from "../RubricCriteriaPointsForm/RubricCriteriaPointsForm";
import {
  currentUserSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";
import { getFinalStudentJSON } from "../InteractiveHelpers";
import { createUpdateSelectAttrsBeforeSubmit, getAIServiceBaseURL, getFromLS, getUniqueId, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";
import _, { isEmpty } from "lodash";
import { rubricsSelector } from "/src/views/Rubrics/Selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import StrandsForm from "./StrandsForm";
import { ImMagicWand } from "react-icons/im";
import axios from "axios";
import { commandTermsSelector } from "/src/App/genericSelector";
import MagicButton from "../GenerateAI/MagicButton";

const { Option, OptGroup } = Select;

const SelectQuestionbank = MrSelect({
  actions: genericActions,
  resourceName: "questionbanks",
  config: {
    url: "/questionbanks",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.title, value: item.id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: <MrTranslate id={"CommonText.select_questionbank"}/>,
      // mode: "multiple",
      // style: { width: "300px" },
    },
  },
});

const SelectLearningOutcomes = MrSelect({
  actions: genericActions,
  resourceName: "learningoutcomes",
  config: {
    url: "/learningoutcomes",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.title, value: item.id };
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: "Select LOs",
      mode: "multiple",
      // style: { width: "300px" },
    },
  },
});


const SelectGradeWidget = GenericWidgetCreator((props) => {
  return {
    config: {
      forceReload: true,
      params: {
        by_questionbank_id: props.formInstance.getFieldValue([
          "relation_items",
          "0",
          "questionbank_id",
        ]),
      },
    },
  };
}, SelectProgrammeGrade);

const getSelectGradeField = (sourceValues) => {
  return {
    type: "object",
    properties: {
      grade_id: {
        placeholder: <MrTranslate id={"CommonText.select_grade"}/>,
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectGradeWidget,
        widgetConfig: {
          disabled:
            sourceValues.relation_items &&
            sourceValues.relation_items[0].grade_id
              ? true
              : false,
          // TODO
          // disabled: sourceValues.relation_items && sourceValues.relation_items[0].subject_id && sourceValues.rubric.type_c != 'points' ? true : false
        },
      },
    },
  };
};

const GradeChildComponent = ({ getFieldValue, sourceValues, formInstance }) => {
  const questionbankId = getFieldValue([
    "relation_items",
    "0",
    "questionbank_id",
  ]);
  let finalRender = null;
  if (questionbankId) {
    finalRender = (
      <FormRenderer
        formObj={getSelectGradeField(sourceValues)}
        sourceValues={sourceValues}
        formInstance={formInstance}
        propertyKey={("relation_items", "0")}
      />
    );
  }
  return finalRender;
};

const SelectSubjectWidget = GenericWidgetCreator((props) => {
  return {
    config: {
      forceReload: true,
      params: {
        by_type_c: "group",
        by_questionbank_id: props.formInstance.getFieldValue([
          "relation_items",
          "0",
          "questionbank_id",
        ]),
      },
    },
  };
}, SelectProgrammeSubject);

const getSelectSubjectField = (sourceValues) => {
  console.log("getSelectSubjectField sourceValues", sourceValues);
  return {
    type: "object",
    properties: {
      subject_id: {
        placeholder: <MrTranslate id={"CommonText.select_subject"}/>,
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectSubjectWidget,
        widgetConfig: {
          disabled:
            sourceValues.relation_items &&
            sourceValues.relation_items[0].subject_id
              ? true
              : false,
          // TODO
          // disabled: sourceValues.relation_items && sourceValues.relation_items[0].subject_id && sourceValues.rubric.type_c != 'points' ? true : false
        },
        // Be careful to make this required - MB pamoja quizzes don't have subjects and grades as of now - edit will not save then
        // rules: [
        //   {
        //     required: true,
        //     message: 'Required',
        //   },
        // ]
      },
    },
  };
};

const SubjectChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const questionbankId = getFieldValue([
    "relation_items",
    "0",
    "questionbank_id",
  ]);
  let finalRender = null;
  if (questionbankId) {
    finalRender = (
      <FormRenderer
        formObj={getSelectSubjectField(sourceValues)}
        sourceValues={sourceValues}
        formInstance={formInstance}
        propertyKey={("relation_items", "0")}
      />
    );
  }
  return finalRender;
};

const SelectRubricWidget = GenericWidgetCreator((props) => {
  console.log("SelectRubricWidget", props);
  let params = {};
  let subjectId = props.formInstance.getFieldValue([
    "relation_items",
    "0",
    "subject_id",
  ]);
  // subject_id is programme_subject_id in serializer from BE if qb_items > 0
  params = { by_subject_id: subjectId };
  return { config: { forceReload: true, params: params } };
}, SelectRubric);

const getRubricField = (sourceValues, mrIntl) => {
  console.log("getRubricField sourceValues", sourceValues);
  return {
    type: "object",
    properties: {
      rubric_id: {
        type: "string",
        title: "Rubric",
        placeholder: mrIntl("CommonText.select_rubric"),
        widget: SelectRubricWidget,
        widgetConfig: {
          disabled: sourceValues.rubric_id ? true : false,
        },
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.required_msg"),
          },
        ],
        setInitialValue: true,
      },
    },
  };
};

const RubricChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
  setFieldsValue,
}) => {
  const mrIntl = useTranslate()
  const subjectId = getFieldValue(["relation_items", "0", "subject_id"]);
  const segmentType = getFieldValue(["segment_type"]);
  // console.log("RubricChildComponent segmentType", segmentType);
  let finalRender = null;
  useEffect(() => {
    if (!subjectId) {
      setFieldsValue({ rubric_id: null });
    }
  }, [subjectId, setFieldsValue]);
  if (subjectId && segmentType === "question") {
    if (sourceValues.rubric_id) {
      finalRender = (
        <Col style={{ marginBottom: "20px" }} span={6}>
          <span>
            <MrTranslate id={"CommonText.selected_rubric"}/>: <b>{sourceValues.rubric.title}</b>
          </span>
        </Col>
      );
      // finalRender = ""
    } else {
      finalRender = (
        <FormRenderer
          // formObj={getSelectOrgSubjectField(sourceValues)}
          sourceValues={sourceValues}
          formInstance={formInstance}
          formObj={getRubricField(sourceValues, mrIntl)}
          // propertyKey={"settings"}
        />
      );
    }
    // Done if rubric_id exists then dont show dropdown, just show simple show
  }
  return finalRender;
};

const SelectSyllabusWidget = GenericWidgetCreator((props) => {
  console.log("SelectSyllabusWidget props", props);
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const enableStandardsContentAreaTagMultiple = enabledFeatures.enable_standards_content_area_tag_multiple
  // let params = {};
  

  // TODO TEMP: remove for CC check
  // // top 3 used in case of resourceName segment, bottom 3 in case of librarySegment
  // if (props.programme_id) {
  //   params.by_programme_id = props.programme_id;
  // }
  // if (props.programme_subject_id) {
  //   params.by_programme_subject_id = props.programme_subject_id;
  // }
  // //TODO: Not filtering by grade right now since DP doesn't need - but will need for other programmes
  // // if(props.programme_grade_id){
  // //   params.by_programme_grade_id = props.programme_grade_id
  // // }
  // if (
  //   props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])
  // ) {
  //   params.by_questionbank_id = props.formInstance.getFieldValue([
  //     "relation_items",
  //     "0",
  //     "questionbank_id",
  //   ]);
  // }
  // if (
  //   props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])
  // ) {
  //   params.by_programme_subject_id = props.formInstance.getFieldValue([
  //     "relation_items",
  //     "0",
  //     "subject_id",
  //   ]);
  // }
  // // if(props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])){
  // //   params.by_programme_grade_id = props.formInstance.getFieldValue(["relation_items", "0", "grade_id"])
  // // }
  // TODO TEMP: remove for CC check

  
  // return { value: props.value ? props.value : [], config: { forceReload: true, widgetConfig: { options: props.standards_content_area_tag, grade_number: props.grade_number, grade_label: props.grade_label }} };
  return { value: props.value ? props.value : [], config: { forceReload: true, widgetConfig: { options: props.tagged_standards_content_areas_data, enableStandardsContentAreaTagMultiple }} };
}, SelectTreeSyllabusContents);

const SelectDifficultyLevelWidget = GenericWidgetCreator((props) => {
  console.log("SelectDifficultyLevelWidget props", props);
  let params = {};
  if (props.programme_id) {
    params.by_programme_id = props.programme_id;
  }
  // if (props.programme_subject_id) {
  //   params.by_programme_subject_id = props.programme_subject_id;
  // }
  // if(props.programme_grade_id){
  //   params.by_programme_grade_id = props.programme_grade_id
  // }
  if (
    props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])
  ) {
    params.by_questionbank_id = props.formInstance.getFieldValue([
      "relation_items",
      "0",
      "questionbank_id",
    ]);
  }
  // if (
  //   props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])
  // ) {
  //   params.by_programme_subject_id = props.formInstance.getFieldValue([
  //     "relation_items",
  //     "0",
  //     "subject_id",
  //   ]);
  // }
  // if(props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])){
  //   params.by_programme_grade_id = props.formInstance.getFieldValue(["relation_items", "0", "grade_id"])
  // }
  return { config: { forceReload: true, params: params } };
}, SelectDifficultyLevel);

// const SelectCommandTermWidget = GenericWidgetCreator((props) => {
//   console.log("SelectCommandTermWidget props", props);
//   let params = {};
//   if (props.programme_id) {
//     params.by_programme_id = props.programme_id;
//   }
//   if (props.programme_subject_id) {
//     params.by_programme_subject_id = props.programme_subject_id;
//   }
//   // if(props.programme_grade_id){
//   //   params.by_programme_grade_id = props.programme_grade_id
//   // }
//   if (
//     props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])
//   ) {
//     params.by_questionbank_id = props.formInstance.getFieldValue([
//       "relation_items",
//       "0",
//       "questionbank_id",
//     ]);
//     params.by_programme_subject_id = props.formInstance.getFieldValue([
//       "relation_items",
//       "0",
//       "subject_id",
//     ]);
//   }
//   // if(props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])){
//   //   params.by_programme_grade_id = props.formInstance.getFieldValue(["relation_items", "0", "grade_id"])
//   // }
//   return {config: { forceReload: true, params: params } };
// }, SelectCommandTerm);

const SelectCommandTermCustom = GenericWidgetCreator((props) => {
  console.log("SelectCommandTermCustom props", props);
  let params = {};
  if (props.programme_id) {
    params.by_programme_id = props.programme_id;
  }
  if (props.programme_subject_id) {
    params.by_programme_subject_id = props.programme_subject_id;
  }
  // if(props.programme_grade_id){
  //   params.by_programme_grade_id = props.programme_grade_id
  // }
  if (
    props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])
  ) {
    params.by_questionbank_id = props.formInstance.getFieldValue([
      "relation_items",
      "0",
      "questionbank_id",
    ]);
    params.by_programme_subject_id = props.formInstance.getFieldValue([
      "relation_items",
      "0",
      "subject_id",
    ]);
  }
  // if(props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])){
  //   params.by_programme_grade_id = props.formInstance.getFieldValue(["relation_items", "0", "grade_id"])
  // }
  return {
    value: props.value ? props.value : [],
    config: { forceReload: true, params: params },
  };
}, SelectCommandTerm);

const SelectCommandTermWidget = (props) => {
  const { formInstance: { getFieldValue, setFieldsValue, setFieldValue } } = props
  console.log("title of command term with props ===>", props, getFieldValue(["question_segment_attributes", "content"]));

  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const [commandTermLoading, setCommandTermLoading] = useState(false);
  const mrIntl = useTranslate()

  const commandTerms = useSelector(commandTermsSelector())
  const generateCommandTerm = async () => {
    setCommandTermLoading(true)
    const token = getFromLS("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const commandTermLabels = []
    commandTerms.forEach((ct) => commandTermLabels.push(ct.label))
    // PROMPT for auto tagging command terms
    let dataToPost = {
      "question_type": "text",
      "number_of_questions": 1,
      "prompt": `
      Role: Head Examiner

      Here are a list of command terms: ${commandTermLabels.join(", ")}.

      Task: Pick the command term from the list above that is most appropriate for the following question. Respond with only the command term. If you are not able to determine a relevant command term, then respond with just 'false'.

      Question: ${removeHTMLTagsFromText(getFieldValue(["question_segment_attributes", "content"]), true)}
      `
    }

    try {
      await axios.post(`${getAIServiceBaseURL()}/generate-using-prompt`, dataToPost, config)
        .then(function (response) {
          setCommandTermLoading(false)
          console.log('generate command terms ==>', commandTerms, response.data);
          const data = response.data.text;
          if (data) {
            const command_term_tag_id = commandTerms.find((ct) => ct.label === response.data.text).id
            console.log("command_term_tag_id ==>", commandTerms, command_term_tag_id)
            props.onChange([command_term_tag_id])
          }
        })
        .catch(function (error) {
          setCommandTermLoading(false)
          console.log(error);
        });

    } catch (error) {
      console.error(error);
    }
  }
  
  const finalRender = [<SelectCommandTermCustom {...props} />]
  if ((enabledFeatures.generate_explanation_with_ai || import.meta.env.VITE_MODE === "development") && false){
    finalRender.push(
      // <Tooltip title={mrIntl("SegmentCreateForm.auto_generate_command_term")}>
      <MagicButton
          tooltipTitle={mrIntl("SegmentCreateForm.auto_generate_command_term")}
          loading={commandTermLoading}
          type="text"
          onClick={() => generateCommandTerm()}
          feature_code="generate_explanation_with_ai"
        />
        //<Button loading={commandTermLoading} icon={<ImMagicWand />} type="text" onClick={() => generateCommandTerm()} />
      // </Tooltip>
    )
  }
  
  return finalRender
}



const SelectAssessmentObjectiveWidget = GenericWidgetCreator((props) => {
  console.log("SelectAssessmentObjectiveWidget props", props);
  let params = {};
  if (props.programme_id) {
    params.by_programme_id = props.programme_id;
  }
  if (props.programme_subject_id) {
    params.by_programme_subject_id = props.programme_subject_id;
  }
  // if(props.programme_grade_id){
  //   params.by_programme_grade_id = props.programme_grade_id
  // }
  if (
    props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])
  ) {
    params.by_questionbank_id = props.formInstance.getFieldValue([
      "relation_items",
      "0",
      "questionbank_id",
    ]);
    params.by_programme_subject_id = props.formInstance.getFieldValue([
      "relation_items",
      "0",
      "subject_id",
    ]);
  }
  // if(props.formInstance.getFieldValue(["relation_items", "0", "questionbank_id"])){
  //   params.by_programme_grade_id = props.formInstance.getFieldValue(["relation_items", "0", "grade_id"])
  // }
  return { config: { forceReload: true, params: params } };
}, SelectAssessmentObjective);

const SelectTagWidget = GenericWidgetCreator((props) => {
  console.log("SelectTagWidget props", props);
  let params = {
    by_type_c: props.type_c
  };
  // top 3 used in case of resourceName segment, bottom 3 in case of librarySegment
  let resourceName = ""
  // if(props.type_c === "difficulty_level"){
  //   resourceName = "difficultylevels"
  // }
  // if(props.type_c === "teaching_level"){
  //   resourceName = "teachinglevels"
  // }

  // dynamic resourceName not working
  return { resourceName: resourceName, config: { forceReload: true, params: params, widgetConfig: {placeholder: `Select ${props.type_c}`}} };
}, SelectTag);

const getSelectSyllabusField = (sourceValues, mrIntl) => {
  return {
    type: "object",
    properties: {
      syllabus_content_ids: {
        placeholder: mrIntl("SegmentCreateForm.select_syllabus_topic"),
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectSyllabusWidget,
        widgetConfig: {
          // disabled: sourceValues.relation_items && sourceValues.relation_items[0].syllabus_content_ids ? true : false
        },
      },
    },
  };
};

const SyllabusChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const questionbankId = getFieldValue([
    "relation_items",
    "0",
    "questionbank_id",
  ]);
  const mrIntl = useTranslate()
  const subjectId = getFieldValue(["relation_items", "0", "subject_id"]);
  const gradeId = getFieldValue(["relation_items", "0", "grade_id"]);
  let finalRender = null;
  if (questionbankId && subjectId && gradeId) {
    finalRender = (
      <FormRenderer
        formObj={getSelectSyllabusField(sourceValues, mrIntl)}
        sourceValues={sourceValues}
        formInstance={formInstance}
        propertyKey={("relation_items", "0")}
      />
    );
  }
  return finalRender;
};

const TextChildComponent = ({ getFieldValue, sourceValues }) => {
  console.log("chilren, line 42");
  const segmentType = getFieldValue(["segment_type"]);
  const mrIntl = useTranslate();
  let finalRender = null;
  
  if (
    segmentType === "text" ||
    (!segmentType && sourceValues.id && sourceValues.segment_type === "text")
    ) {
    console.log("segment type====>", segmentType, sourceValues);
    finalRender = (
      <FormRenderer
        // formObj={textForm()}
        formObj={textForm(mrIntl)}
        propertyKey={"text_segment_attributes"}
        sourceValues={sourceValues}
      />
    );
  }
  return finalRender;
};

const SectionChildComponent = (props) => {
  const { getFieldValue, sourceValues } = props;
  const segmentType = getFieldValue(["segment_type"]);
  let finalRender = null;
  const mrIntl = useTranslate()

  if (
    segmentType === "section" ||
    (!segmentType && sourceValues.id && sourceValues.segment_type === "section")
  ) {
    // No need now - using question group
    // const segmentConfig = {
    //   createMode: true,
    //   editMode: false,
    //   autoCheckMode: false,
    // }
    // sectionForm.properties.solution = {
    //   type: "string",
    //   placeholder: "Solution/Explanation",
    //   title: "Solution/Explanation",
    //   widget: TextAnswerExplanation,
    //   widgetConfig: {
    //     ...segmentConfig,
    //   },
    //   setInitialValue: true,
    // }
    // sectionForm.properties.attachments_json = {
    //   type: "object",
    //   properties: {
    //     solution_video_url: {
    //       type: "string",
    //       title: "Solution/explanation video url",
    //     },
    //   }
    // }
    finalRender = (
      <FormRenderer
        formObj={sectionForm(mrIntl)}
        propertyKey={"section_segment_attributes"}
        sourceValues={sourceValues}
      />
    );
  }
  return finalRender;
};

const PointsChildComponent = (props) => {
  console.log("PointsChildComponent", props);
  const { getFieldValue, sourceValues, formInstance } = props;
  const segmentType = getFieldValue(["segment_type"]);
  const questionType = getFieldValue(["question_segment_attributes", "question_type"]);
  const questionbankId = getFieldValue([
    "relation_items",
    "0",
    "questionbank_id",
  ]);
  const rubricId = getFieldValue(["rubric_id"]);
  console.log("PointsChildComponent", segmentType, questionType);
  const mrIntl = useTranslate();
  let rubric;

  let finalRender = null;
  // get active experience rubric = use criteria to build form for points if rubric not Points. if points or if no experience/adding segment in library, then default to below. if grading for full_experience then hide altogether

  // const activeExperienceId = useSelector(activeAdjustedExperienceIdSelector())
  // const activeExperience = useSelector(state => state.experiences.get("experiences").find((item) => item.id == activeExperienceId))
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const rubrics = useSelector(rubricsSelector());
  console.log(
    "PointsChildComponent",
    activeExperience,
    rubrics,
    questionbankId,
    rubricId,
    questionbankId && rubricId
  );

  if (
    segmentType === "question" ||
    (!segmentType &&
      sourceValues.id &&
      sourceValues.segment_type === "question")
  ) {
    if (activeExperience.rubric) {
      rubric = activeExperience.rubric;
    } else if (questionbankId && rubricId) {
      rubric = rubrics.find((r) => r.id === parseInt(rubricId));
    }
    if (!rubric) {
      return "";
    }
    if (questionType === "group"){
      return "";
    }
    // // get active experience grading_setting = per_question/full_experience
    // if(activeExperience.settings.grading_setting == "overall"){
    //   finalRender = ""
    // }else{
    //   // if grading per question
    //   // change this to rubric_code

    if (rubric.type_c === "points") {
      finalRender = (
        <FormRenderer
          formObj={{
            type: "object",
            properties: {
              points: {
                type: "string",
                placeholder: mrIntl("CommonText.points"),
                // placeholder: "Points",
                setInitialValue: true,
                widget: InputNumber,
                rules: [
                  {
                    validator(rule, value) {
                      if (value && value > 0) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(<MrTranslate id={"CommonText.not_allowed"}/>);
                      }
                    },
                  },
                ],
              },
            },
          }}
          // propertyKey={"section_segment_attributes"}
          sourceValues={sourceValues}
        />
      );
    } else if (
      rubric &&
      (rubric.type_c == "criteria_with_points" ||
        rubric.type_c == "myp_achievement_level") &&
      rubric.criteria &&
      rubric.criteria.length != 0
    ) {
      finalRender = (
        <FormRenderer
          formObj={{
            type: "object",
            properties: {
              criterium_associations_attributes: {
                type: "string",
                placeholder: mrIntl("CommonText.points"),
                setInitialValue: true,
                widget: RubricCriteriaPointsForm,
                widgetConfig: {
                  selectedRubric: rubric,
                },
                rules: [
                  // ({ getFieldValue }) => ({
                  //   validator(rule, value) {
                  //     if (!value || getFieldValue('password') === value) {
                  //       return Promise.resolve();
                  //     }
                  //     return Promise.reject('The two passwords that you entered do not match!');
                  //   },
                  // }),
                  {
                    validator(rule, value) {
                      console.log("Segment ca validator", value, typeof value);
                      let validationPassed = true;
                      let checkBoxValidationPassed = false;
                      let message = mrIntl("SegmentCreateForm.please_select_atleast_one_msg");
                      if (!value || value.length === 0) {
                        validationPassed = false;
                      } else {
                        if (rubric.type_c == "criteria_with_points") {
                          for (let i = 0; i <= value.length - 1; i++) {
                            // check if atleast 1 is checked
                            let ca = value[i];
                            checkBoxValidationPassed = false;
                            // message = "Please select atleast one"
                            if (
                              ca.checked ||
                              (typeof ca.checked === "undefined" && ca.id)
                            ) {
                              // typeof and id condition for first time - when component isnt touched then checked doesn't get set since only on FE
                              checkBoxValidationPassed = true;
                              // console.log('Segment ca validator', validationPassed, ca.checked)
                              message = mrIntl("CommonText.passed_msg");
                              break;
                            }
                          }

                          // check if points valid
                          if (checkBoxValidationPassed) {
                            for (let i = 0; i <= value.length - 1; i++) {
                              let ca = value[i];
                              let updatedPoints = ca.points;
                              if (
                                updatedPoints &&
                                parseFloat(updatedPoints) > 0 &&
                                typeof parseFloat(updatedPoints) == "number" &&
                                updatedPoints.slice(-1) != "."
                              ) {
                                if (/[a-z]/gi.test(updatedPoints)) {
                                  validationPassed = false;
                                  message = mrIntl("SegmentCreateForm.points_must_not_contain_any_letter_msg");
                                  } else if (!/^\d+(\.\d)?$/.test(updatedPoints)) {
                                  validationPassed = false;
                                  message = mrIntl("SegmentCreateForm.points_must_have_at_most_one_decimal_place");
                                }
                                // allowed
                              } else if(ca.checked) {
                                validationPassed = false;
                                message = mrIntl("SegmentCreateForm.points_must_be_non_zero_msg");
                                break;
                              }
                            }
                          } else {
                            validationPassed = false;
                            message = mrIntl("SegmentCreateForm.please_select_atleast_one_msg");
                          }
                        } else if (rubric.type_c == "myp_achievement_level") {
                          // console.log('Segment ca validator', ca, ca.checked)
                          for (let i = 0; i <= value.length - 1; i++) {
                            let ca = value[i];
                            // check if atleast 1 is checked
                            validationPassed = false;
                            message = mrIntl("SegmentCreateForm.please_select_atleast_one_msg");
                            if (
                              ca.checked ||
                              (typeof ca.checked === "undefined" && ca.id)
                            ) {
                              // typeof and id condition for first time - when component isnt touched then checked doesn't get set since only on FE
                              validationPassed = true;
                              // console.log('Segment ca validator', validationPassed, ca.checked)
                              message = mrIntl("CommonText.passed_msg");
                              break;
                            }
                          }
                        }
                        // })
                      }

                      console.log(
                        "UserResponses validator updatedPoints",
                        validationPassed
                      );
                      if (validationPassed) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error(message));
                      }
                    },
                  },
                ],
              },
            },
          }}
          // propertyKey={"section_segment_attributes"}
          formInstance={formInstance} 
          sourceValues={sourceValues}
        />
      );
    }
  }

  return finalRender;
};

const PointsChildComponentTempLibrary = (props) => {
  const { getFieldValue, sourceValues } = props;
  const segmentType = getFieldValue(["segment_type"]);
  let finalRender = null;

  if (
    segmentType === "question" ||
    (!segmentType &&
      sourceValues.id &&
      sourceValues.segment_type === "question")
  ) {
    finalRender = (
      <FormRenderer
        formObj={{
          type: "object",
          properties: {
            points: {
              type: "string",
              placeholder: "Points",
              setInitialValue: true,
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ],
            },
          },
        }}
        // propertyKey={"section_segment_attributes"}
        sourceValues={sourceValues}
      />
    );
  }
  return finalRender;
};

const ResourceChildComponent = (props) => {
  const { getFieldValue, sourceValues } = props;
  console.log("chilren, line 236");
  const segmentType = getFieldValue(["segment_type"]);
  let finalRender = null;
  const mrIntl = useTranslate();

  if (
    segmentType === "resource" ||
    (!segmentType &&
      sourceValues.id &&
      sourceValues.segment_type === "resource")
  ) {
    finalRender = (
      <FormRenderer
        // formObj={resourceForm}
        formObj={resourceForm(mrIntl)}
        propertyKey={"resource_segment_attributes"}
        sourceValues={sourceValues}
      />
    );
  }
  return finalRender;
};

const QuestionChildComponent = (props) => {
  let { getFieldValue, sourceValues, formInstance, nestingLevel } = props;
  console.log("props inside question segment att ==>", props);
  console.log("sourceValues", sourceValues);
  const segmentType = getFieldValue(["segment_type"]);
  let finalRender = null;

  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const currentUser = useSelector(currentUserSelector());
  const mrIntl = useTranslate()
  if (
    segmentType === "question" ||
    (!segmentType &&
      sourceValues.id &&
      sourceValues.segment_type === "question")
  ) {
    finalRender = (
      <FormRenderer
        formObj={questionForm(enabledFeatures, currentUser, mrIntl, nestingLevel, activeExperience)}
        propertyKey={"question_segment_attributes"}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};

const SettingsChildComponent = (props) => {
  let { getFieldValue, sourceValues, formInstance } = props;
  console.log("props inside question segment att ==>", props);
  console.log("sourceValues", sourceValues);
  const segmentType = getFieldValue(["segment_type"]);
  let finalRender = null;
  const mrIntl = useTranslate()

  if (
    segmentType === "question" ||
    (!segmentType &&
      sourceValues.id &&
      sourceValues.segment_type === "question")
  ) {
    finalRender = (
      <FormRenderer
        formObj={settingsForm(mrIntl)}
        propertyKey={"settings"}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};

const TitleChildComponent = (props) => {
  const { getFieldValue, sourceValues } = props;
  console.log("chilren, line 236");
  const segmentType = getFieldValue(["segment_type"]);
  let finalRender = null;
  const mrIntl = useTranslate()

  if (
    segmentType === "question" ||
    (!segmentType &&
      sourceValues.id &&
      sourceValues.segment_type === "question")
  ) {
    finalRender = (
      <FormRenderer
        formObj={
          {
            type: "object",
            properties: {
              title: {
                type: "string",
                span: 18,
                title: mrIntl("SegmentCreateForm.title_name_title"),
                setInitialValue: true,
                // className: "hidden"
                trigger: 'onSubmit',
              },
            }
          }
        }
        sourceValues={sourceValues}
      />
    );
  }
  return finalRender;
};

const StrandsForCriteriaChildComponent = (props) => {
  let { getFieldValue, sourceValues, formInstance } = props;
  const criteriumAssociations = getFieldValue(["criterium_associations_attributes"]);
  console.log("StrandsForCriteriaChildComponent props ==>", props)
  const mrIntl = useTranslate()

  let finalRender = null;
  if (criteriumAssociations && criteriumAssociations.length > 0) {
    finalRender = (
      <FormRenderer
        formObj={{
          type: "string",
          title: mrIntl("SegmentCreateForm.strands_title"),
          widget: StrandsForm,          
          wrapClassName: "text-left",
          widgetConfig: {
            size: "small",
            criteriumAssociations: criteriumAssociations,
          },
        }}
        propertyKey={["strands"]}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};
// const createUpdateSelectAttrsBeforeSubmit = (existing_items, modified_ids) => {
//   console.log("createUpdateSelectAttrsBeforeSubmit", existing_items, modified_ids)
//   // currently only for tag_items - can further generalise
//   // both params are arrays
//   let items_attributes = [];

//   let toCreate = null;
//   let toRemove = null;
//   let toUnarchive = null;
//   let existing = null;
//   let modified = null;
//   // create/update
//   if (modified_ids) {
//     if(typeof(modified_ids) == "number"){
//       modified = [modified_ids]; // to handle case where single select, modified_ids not an array in this case
//     }else{
//       modified = modified_ids; // modified_ids is an array
//     }
//   }
//   if (existing_items) {
//     existing = _.map(existing_items, "tag_id");
//   }
//   toCreate = _.difference(modified, existing);
//   toRemove = _.difference(existing, modified);
//   toUnarchive = _.intersection(existing, modified);

//   console.log("toCreate, toRemove ", toCreate, toRemove);
//   if (toCreate) {
//     toCreate.map((selectItemId, i) => {
//       items_attributes.push({
//         itemable_type: "Segment",
//         tag_id: selectItemId, // may need to change when we offer custom syllabus topics per org - based on qbMode
//       });
//     });
//   }
//   if (toRemove) {
//     toRemove.map((selectItemId, i) => {
//       console.log(
//         "selectItemId, existing ",
//         selectItemId,
//         existing,
//         existing_items
//       );
//       let selectItem = _.find(existing_items, [
//         "tag_id",
//         selectItemId,
//       ]);
//       items_attributes.push({
//         id: selectItem.id,
//         itemable_type: "Segment",
//         tag_id: selectItemId,
//         archived: true,
//       });
//     });
//   }
//   if (toUnarchive) {
//     toUnarchive.map((selectItemId, i) => {
//       console.log(
//         "selectItemId, existing ",
//         selectItemId,
//         existing,
//         existing_items
//       );
//       let selectItem = _.find(existing_items, [
//         "tag_id",
//         selectItemId,
//       ]);
//       items_attributes.push({
//         id: selectItem.id,
//         itemable_type: "Segment",
//         tag_id: selectItemId,
//         archived: false,
//       });
//     });
//   }

//   return items_attributes
// }


const generateSegmentCreateForm = (resourceName, props) => {
  // based on whether resource name is librarysegment or segment, build below form to return
  // const { resourceName } = props
  let isDevelopmentMode = import.meta.env.VITE_MODE === "development1";
  let org = props.org;
  let activeExperience = props.activeExperience; // props.activeExperience can be nil in case of librarysegment crud
  let currentUser = props.currentUser;
  let formValues = props.formState.values;
  let mainProps = props.mainProps || {}
  let nestingLevel = mainProps.nestingLevel || 1
  let enabledFeatures = props.enabledFeatures
  let qbMode =
    currentUser.role == "qb_author" && resourceName == "librarysegment"
      ? true
      : false;
  const isQuestion = formValues.segment_type == "question";
  let createQBItems = qbMode,
    createSubjectItems = qbMode,
    createGradeItems = qbMode,
    createUpdateTags = isQuestion && (currentUser.role === "qb_author" || (enabledFeatures.enable_standards_content_area_tag_multiple && activeExperience && !isEmpty(activeExperience.tagged_standards_content_areas_data))); // can be customised later in case wanted for org case also
  let allowEmbed = currentUser.role === "qb_author" && nestingLevel === 1 ? true : false
  let rubricType = activeExperience && activeExperience.rubric && activeExperience.rubric.type_c

  console.log("generateSegmentCreateForm", props, qbMode);

  let processFormData = (dataProps, opts) => {
    console.log("dataProps", dataProps);
    console.log("opts", opts);
    // process and set values and then return changed values
    // opts.values["custom_field"] = "custom_value";
    // let experience_memberships_attributes = []

    // opts.values.experience_memberships_attributes = experience_memberships_attributes;

    console.log("dataProps and opts ==>", dataProps, opts);
    // process and set values and then return changed values
    // opts.values["custom_field"] = "custom_value";

    if (opts.values.strands && opts.values.criterium_associations_attributes) {
      opts.values.criterium_associations_attributes.forEach((ca) => {
        const selectedStrands = [];
        const strands = opts.values.strands
        strands.forEach((label) => {
          let splitLabel = label.split("(")
          if (ca.custom_fields.title == splitLabel[0]) {
            selectedStrands.push({
              label: label,
            });
          }
        });
        ca.strands = selectedStrands;
      });
    }    

    if (opts.values.segment_type == "question") {
      const questionId = opts.values.question_segment_attributes.id || "";
      let question_type = opts.values.question_segment_attributes.question_type;

      // On edit nested object values were getting merged - so replacing with new values
      // Questions json have nested objects which should not get merged with old data on edit
      opts.values.question_segment_attributes.teacher_json =
        opts.formFinishProps.values.question_segment_attributes.teacher_json;

      let currentTeacherJSONValue =
        opts.values.question_segment_attributes.teacher_json;

      opts.values.question_segment_attributes.student_json = getFinalStudentJSON(
        question_type,
        currentTeacherJSONValue
      );

      let currentStudentJSONValue =
        opts.values.question_segment_attributes.student_json;
      // To support - in React data sheet datasource is array of arrays and jsonb does not save array of arrays
      if (question_type == "rds_table") {
        if (
          typeof currentTeacherJSONValue.segment_data.table_data == "object"
        ) {
          currentTeacherJSONValue.segment_data.table_data = JSON.stringify(
            currentTeacherJSONValue.segment_data.table_data
          );
        }
        if (
          typeof currentStudentJSONValue.segment_data.table_data == "object"
        ) {
          currentStudentJSONValue.segment_data.table_data = JSON.stringify(
            currentStudentJSONValue.segment_data.table_data
          );
        }
      }

      // To support - in canvas path is array of arrays and jsonb does not save array of arrays
      if (
        question_type == "fabric_drawing" ||
        question_type == "fabric_hotspot"
      ) {
        if (
          typeof currentTeacherJSONValue.segment_data.canvas_data == "object"
        ) {
          currentTeacherJSONValue.segment_data.canvas_data = JSON.stringify(
            currentTeacherJSONValue.segment_data.canvas_data
          );
        }
        if (
          typeof currentStudentJSONValue.segment_data.canvas_data == "object"
        ) {
          currentStudentJSONValue.segment_data.canvas_data = JSON.stringify(
            currentStudentJSONValue.segment_data.canvas_data
          );
        }
      }

      if (question_type == "ggb_graph") {
        const graphId = `${currentTeacherJSONValue.segment_data.graphId}_segments_1_question_segment_attributes_teacher_json`;
        const ggbApplet = window[graphId];
        console.log("GGB applet on submit ==>", graphId, ggbApplet);
        if (ggbApplet) {
          const pngBase64 =
            "data:image/png;base64," + ggbApplet.getPNGBase64(1, true, 72);
          currentTeacherJSONValue.segment_data.pngBase64 = pngBase64;
          currentStudentJSONValue.segment_data.pngBase64 = pngBase64;
        }
      }

      if (
        question_type == "cke_fill_text" ||
        question_type == "cke_fill_dropdown"
      ) {
        let gaps = currentTeacherJSONValue.segment_data.gaps;
        currentTeacherJSONValue.segment_data.gaps = gaps.filter(
          (gap) => !gap.archived
        );
      }

      if (currentTeacherJSONValue) {
        currentTeacherJSONValue.json_uuid = getUniqueId();
      }
      if (currentStudentJSONValue) {
        currentStudentJSONValue.json_uuid = getUniqueId();
      }

      // opts.values.settings.is_game = true
      // opts.values.question_segment_attributes.game_type = "cryptic_hunt"
      opts.values.settings.mode = "exam_mode";
      if (opts.values.settings.mode == true) {
        opts.values.settings.mode = "quick_check";
      }

      if (!opts.values.settings.include_explanation) {
        opts.values.question_segment_attributes.answer_explanation = "";
      }
      
      if (!opts.values.settings.include_markscheme) {
        opts.values.question_segment_attributes.markscheme = "";
      }

      // opts.values.question_segment_attributes.teacher_json = JSON.stringify(opts.values.question_segment_attributes.teacher_json)
    } else if (opts.values.segment_type == "resource") {
      if (!opts.values.resource_segment_attributes.include_caption) {
        opts.values.resource_segment_attributes.caption = null;
      }
    }
    console.log(
      "opts.values.question_segment_attributes",
      opts.values.question_segment_attributes
    );

    // for all segments
    if(!opts.values.settings){
      opts.values.settings = {}
    }
    opts.values.settings.allow_embed = allowEmbed;
    opts.values.previous_id = dataProps.formConfig.submitConfig.previousId;
    console.log("dataProps.formConfig", dataProps.formConfig)
    if(dataProps.formConfig.submitConfig && dataProps.formConfig.submitConfig.destinationPosition){
      opts.values.destination_position = dataProps.formConfig.submitConfig.destinationPosition;
    }

    // TODO
    // if(qbMode){
    //   if(!(dataProps.item && dataProps.item.questionbank_id)){
    //     // protect override each time
    //     let questionbank_items_attributes = []
    //     let toCreate = []
    //     if(opts.values.questionbank_id){
    //       toCreate.push(opts.values.questionbank_id)
    //       toCreate.map((qbId, i) => {
    //         questionbank_items_attributes.push({
    //           questionbank_id: qbId,
    //           itemable_type: "Segment",
    //           // item_id: segment.id,
    //         })
    //       })
    //     }

    //     opts.values.questionbank_items_attributes = questionbank_items_attributes;
    //   }
    // }

    let relationItems =
      opts.values.relation_items && opts.values.relation_items[0];

    // We are using opts.formFinishProps.values because when we clear the value of a single select, the correct value does not appear in opts.value. APL-3541
    let formFinishPropsRelationItem =
      opts.formFinishProps.values.relation_items && opts.formFinishProps.values.relation_items[0];
    // not supporting multiple right now

    if (createQBItems) {
      let questionbank_items_attributes = [];
      let opi;
      if (
        relationItems.questionbank_items &&
        relationItems.questionbank_items.length != 0
      ) {
        opi = relationItems.questionbank_items[0];
        opi.questionbank_id = relationItems.questionbank_id;
      } else {
        opi = {
          itemable_type: "Segment",
          questionbank_id: relationItems.questionbank_id,
        };
      }
      questionbank_items_attributes.push(opi);
      opts.values.questionbank_items_attributes = questionbank_items_attributes;
    }
    // NOT USING BELOW since org not creating segments directly right now - only inside experience
    // else{
    //   let org_programme_items_attributes = []
    //   let opi
    //   if(relationItems.org_programme_items && relationItems.org_programme_items.length != 0){
    //     opi = relationItems.org_programme_items[0]
    //     opi.org_programme_id = relationItems.org_programme_id
    //   }
    //   else{
    //     opi = {
    //       itemable_type: "Experience",
    //       org_programme_id: relationItems.org_programme_id,
    //     }
    //   }
    //   org_programme_items_attributes.push(opi)
    //   opts.values.org_programme_items_attributes = org_programme_items_attributes
    // }

    if (createSubjectItems) {
      //currently equivalent to qbMode = so wont go into else below in 684
      let subject_items_attributes = [];
      let si;
      if (
        relationItems.subject_items &&
        relationItems.subject_items.length != 0
      ) {
        si = relationItems.subject_items[0];
        // si.subject_id = relationItems.subject_id
      } else {
        si = {
          itemable_type: "Segment",
          // subject_id: relationItems.subject_id,
        };
      }
      if (qbMode) {
        si.programme_subject_id = relationItems.subject_id;
      } else {
        // Not really applicable to segments yet - since no org creating segments directly. leaving here for whne we allow orgs to do
        si.subject_id = relationItems.subject_id;
      }
      subject_items_attributes.push(si);
      opts.values.subject_items_attributes = subject_items_attributes;
    }

    if (createGradeItems) {
      let grade_items_attributes = [];
      let gi;
      if (relationItems.grade_items && relationItems.grade_items.length != 0) {
        gi = relationItems.grade_items[0];
        // gi.grade_id = relationItems.grade_id
      } else {
        gi = {
          itemable_type: "Segment",
          // grade_id: relationItems.grade_id,
        };
      }
      if (qbMode) {
        gi.programme_grade_id = relationItems.grade_id;
      } else {
        gi.grade_id = relationItems.grade_id;
      }
      grade_items_attributes.push(gi);
      opts.values.grade_items_attributes = grade_items_attributes;
    }

    if (createUpdateTags && relationItems) {
      // createUpdateSelectAttrsBeforeSubmit(<existing items>, <modified_ids>) // currently only for tag_items
      let syllabus_items_attributes = createUpdateSelectAttrsBeforeSubmit("Segment", relationItems.syllabus_items, relationItems.syllabus_content_ids)
      let difficulty_level_items_attributes = createUpdateSelectAttrsBeforeSubmit("Segment", formFinishPropsRelationItem.difficulty_level_items, formFinishPropsRelationItem.difficulty_level_tag_ids)
      let teaching_level_items_attributes = createUpdateSelectAttrsBeforeSubmit("Segment", formFinishPropsRelationItem.teaching_level_items, formFinishPropsRelationItem.teaching_level_tag_ids)
      let command_term_items_attributes = createUpdateSelectAttrsBeforeSubmit("Segment", relationItems.command_term_items, relationItems.command_term_tag_ids)
      let depth_of_knowledge_items_attributes = createUpdateSelectAttrsBeforeSubmit("Segment", relationItems.depth_of_knowledge_items, relationItems.depth_of_knowledge_tag_ids)
      let assessment_objective_items_attributes = createUpdateSelectAttrsBeforeSubmit("Segment", formFinishPropsRelationItem.assessment_objective_items, formFinishPropsRelationItem.assessment_objective_tag_ids)

      console.log("tag_items_attributes", syllabus_items_attributes, difficulty_level_items_attributes, teaching_level_items_attributes)
      opts.values.tag_items_attributes = syllabus_items_attributes.concat(difficulty_level_items_attributes).concat(teaching_level_items_attributes).concat(depth_of_knowledge_items_attributes).concat(command_term_items_attributes).concat(assessment_objective_items_attributes)
    }

    console.log("opts.values exp create form process form data", opts.values);

    // changed to cater to has_many change in BE
    if (opts.values[opts.values.segment_type + "_segment_attributes"]) {
      // needs to be an array of objects
      opts.values[opts.values.segment_type + "_segments_attributes"] = [
        opts.values[opts.values.segment_type + "_segment_attributes"],
      ];
      delete opts.values[opts.values.segment_type + "_segment_attributes"];
    }
    return opts.values;
  };
  
  // separate each and add to properties as needed based on resourceName or override
  let properties = {
    // Todo: add support for multiple select
    // TODO when org qb with segments, not needed when creating segment inside experience
    // relation_items: {
    //   type: "array",
    //   span: 24,
    //   min: 1,
    //   max: 1,
    //   default: [{}],
    //   // default: [{org_programme_id: parseInt(getFromLS("activeOrgProgrammeId"))}],
    //   // set defaults from LS - hiding for now
    //   // title: "Relations",
    //   // widgetConfig: {
    //   //   disabled: props.formState.values.id ? true : false
    //   // },
    //   items: {
    //     type: "object",
    //     properties: {
    //       // questionbank_id: {
    //       //   placeholder: "Select questionbank",
    //       //   type: "string",
    //       //   span: 8,
    //       //   widget: SelectQuestionbank,
    //       // },

    //       // For schools to create test in school bank by default
    //       org_programme_id: {
    //         title: "Select program",
    //         type: "string",
    //         span: 6,
    //         widget: SelectProgram,
    //         widgetConfig: {
    //           disabled: formValues.relation_items && formValues.relation_items[0].org_programme_id ? true : false,
    //           allowClear: false
    //         },
    //         rules: [
    //           {
    //             required: true,
    //             message: 'Required',
    //           },
    //         ]
    //       },
    //       subject_id: {
    //         placeholder: "Select subject",
    //         type: "string",
    //         span: 8,
    //         dependsOn: ["relation_items", "0", "org_programme_id"],
    //         children: OrgSubjectChildComponent,

    //       },
    //       grade_id: {
    //         placeholder: "Select grade",
    //         type: "string",
    //         span: 4,
    //         dependsOn: ["relation_items", "0", "org_programme_id"],
    //         children: OrgGradeChildComponent,

    //       },
    //     },

    //   }
    // },

    segment_type: {
      type: "string",
      // title: "Segment type",
      span: 24,
      // widget: CustomSegmentSelector,
      widget: "RadioGroupWidget",
      widgetConfig: {
        // options: buildOptionsArr(['text', 'resource', 'question']),
      },
      className: "hidden",
    },
    points: {
      type: "string",
      placeholder: <MrTranslate id={"CommonText.points"}/>,
      // span: 4,
      span: 16,
      wrapClassName: "wrap-segment-form-points",
      // dependsOn: ["segment_type"],
      // children: qbMode ? PointsChildComponentTempLibrary : PointsChildComponent,
      dependsOn: qbMode ? ["rubric_id"] : ["segment_type"],
      children: PointsChildComponent,
      // // setInitialValue: true,
      rules: [
        {
          required: true,
          message: <MrTranslate id={"CommonText.required_msg"}/>,
        },
      ],
    },

    
    section_segment_attributes: {
      type: "string",
      dependsOn: ["segment_type"],
      children: SectionChildComponent,
    },
    text_segment_attributes: {
      type: "string",
      dependsOn: ["segment_type"],
      children: TextChildComponent,
    },
    resource_segment_attributes: {
      type: "string",
      dependsOn: ["segment_type"],
      children: ResourceChildComponent,
    },
    question_segment_attributes: {
      type: "string",
      dependsOn: ["segment_type"],
      children: QuestionChildComponent,
      widgetConfig: {
        nestingLevel: nestingLevel
      }
    },
    title: {
      type: "string",
      dependsOn: ["segment_type"],
      wrapClassName: currentUser.role !== "qb_author" || nestingLevel > 1 ? "hidden" : "",
      children: TitleChildComponent,
    },
    settings: {
      type: "string",
      dependsOn: ["segment_type"],
      children: SettingsChildComponent,
    },
    custom_fields: {
      type: "string",
      className: "hidden",
    }
  };

  if (rubricType == "criteria_with_points" || rubricType == "myp_achievement_level") {
    properties.myp_strands = {
      type: "string",
      dependsOn: ["question_segment_attributes"],
      children: StrandsForCriteriaChildComponent,
    };
  }


  if (enabledFeatures.enable_standards_content_area_tag_multiple && 
  formValues.segment_type === "question" && activeExperience && !isEmpty(activeExperience.tagged_standards_content_areas_data) && (currentUser.role === "admin" || currentUser.role === "teacher" )){
    properties.relation_items = {
      type: "array",
      span: 24,
      min: 1,
      max: 1,
      default: [{}],
      items: {
        type: "object",
        properties: {},
      },
    };
    properties.relation_items.items.properties.syllabus_content_ids = {
      placeholder: <MrTranslate id={"SegmentCreateForm.select_syllabus_topics_placeholder"}/>,
      type: "string",
      span: 24,
      // wrapClassName:
      //   import.meta.env.VITE_MODE === "production"
      //     ? "hidden"
      //     : "",
      widget: SelectSyllabusWidget,
      widgetConfig: {
        // programme_id: activeExperience.relation_items[0].programme_id,
        // programme_subject_id: activeExperience.relation_items[0].subject_id, // in case of qb exp, subject_id from BE in serializer is equal to programme_subject_id
        // programme_grade_id: activeExperience.relation_items[0].grade_id, // in case of qb exp, grade_id from BE in serializer is equal to programme_grade_id

        tagged_standards_content_areas_data: activeExperience?.tagged_standards_content_areas_data || {},
        // grade_number: activeExperience.relation_items[0].grade_number,
        // grade_label:  "Twelfth grade",
        // grade_label:  activeExperience.custom_fields.grade_item_label, // Note: would be incorrect to use grade_item_label - need programme_grade_label
        // disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
      },
    };
    properties.relation_items.items.properties.command_term_tag_ids = {
      title: <MrTranslate id={"SegmentCreateForm.select_command_term"}/>,
      // placeholder: <MrTranslate id={"SegmentCreateForm.select_command_term"}/>,
      // title: Placeholder,
      // placeholder: Placeholder,
      type: "string",
      span: 12,
      // wrapClassName:
      //   nestingLevel > 1
      //     ? "hidden"
      //     : "",
      // widget: SelectTagWidget,
      widget: SelectCommandTermWidget,
      widgetConfig: {
        type_c: "command_term",
        programme_id: activeExperience.relation_items[0].programme_id,
        // programme_subject_id: activeExperience.relation_items[0].subject_id, // in case of qb exp, subject_id from BE in serializer is equal to programme_subject_id
        // disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
      },
    };
  }

  if (currentUser.role === "qb_author") {
    // to allow syllabus select inside experience authoring also for qb_author
    properties.relation_items = {
      type: "array",
      span: 24,
      min: 1,
      max: 1,
      default: [{}],
      items: {
        type: "object",
        properties: {},
      },
    };
    if (resourceName === "librarysegment") {
      properties.relation_items.items.properties.questionbank_id = {
        placeholder: "Select questionbank",
        type: "string",
        span: 8,
        widget: SelectQuestionbank,
        widgetConfig: {
          disabled:
            formValues.relation_items &&
            formValues.relation_items[0].questionbank_id
              ? true
              : false,
        },
        rules: [
          {
            required: true,
            message: <MrTranslate id={"CommonText.required_msg"}/>,
          },
        ],
      };
      properties.relation_items.items.properties.subject_id = {
        placeholder: <MrTranslate id={"CommonText.select_subject"}/>,
        type: "string",
        span: 8,
        dependsOn: ["relation_items", "0", "questionbank_id"],
        children: SubjectChildComponent,
      };
      properties.relation_items.items.properties.grade_id = {
        placeholder: <MrTranslate id={"CommonText.select_grade"}/>,
        type: "string",
        span: 4,
        dependsOn: ["relation_items", "0", "questionbank_id"],
        children: GradeChildComponent,
      };
      properties.relation_items.items.properties.syllabus_content_ids = {
        placeholder:<MrTranslate id={"SegmentCreateForm.select_syllabus_topics_placeholder"}/>,
        type: "string",
        span: 24,
        // wrapClassName:
        //   import.meta.env.VITE_MODE === "production"
        //     ? "hidden"
        //     : "",
        dependsOn: ["relation_items", "0", "questionbank_id"],
        children: SyllabusChildComponent,
        // widget: SelectSyllabusTopics,
        // widgetConfig: {
        // disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
        // },
      };
      properties.rubric_id = {
        type: "string",
        span: 4,
        title: <MrTranslate id={"SegmentCreateForm.rubrics"} />,
        dependsOn: ["relation_items", "0", "subject_id"],
        default: 1,
        children: RubricChildComponent,
        // wrapClassName:
        //   // import.meta.env.VITE_MODE !== "production" &&
        //   formValues.segment_type === "question"
        //     ? ""
        //     : "hidden",
        widgetConfig: {
          disabled: formValues.rubric_id ? true : false,
        },
      };
    }

    if (resourceName === "segment" && activeExperience) {
      properties.relation_items.items.properties.syllabus_content_ids = {
        placeholder: <MrTranslate id={"SegmentCreateForm.select_syllabus_topics_placeholder"}/>,
        type: "string",
        span: 24,
        // wrapClassName:
        //   import.meta.env.VITE_MODE === "production"
        //     ? "hidden"
        //     : "",
        widget: SelectSyllabusWidget,
        widgetConfig: {
          // Removing for now - currently not using this
          // programme_id: activeExperience.relation_items[0].programme_id,
          // programme_subject_id: activeExperience.relation_items[0].subject_id, // in case of qb exp, subject_id from BE in serializer is equal to programme_subject_id
          // programme_grade_id: activeExperience.relation_items[0].grade_id, // in case of qb exp, grade_id from BE in serializer is equal to programme_grade_id
          tagged_standards_content_areas_data: activeExperience?.tagged_standards_content_areas_data || {}, 
          // grade_number: activeExperience.relation_items[0].grade_number,
          // grade_label:  "Twelfth grade",
          // grade_label:  activeExperience.custom_fields.grade_item_label, // Note: would be incorrect to use grade_item_label - need programme_grade_label

          // disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
        },
      };
      properties.relation_items.items.properties.command_term_tag_ids = {
        placeholder: <MrTranslate id={"SegmentCreateForm.select_command_term"}/>,
        type: "string",
        span: 12,
        // wrapClassName:
        //   nestingLevel > 1
        //     ? "hidden"
        //     : "",
        // widget: SelectTagWidget,
        widget: SelectCommandTermWidget,
        widgetConfig: {
          type_c: "command_term",
          programme_id: activeExperience.relation_items[0].programme_id,
          // programme_subject_id: activeExperience.relation_items[0].subject_id, // in case of qb exp, subject_id from BE in serializer is equal to programme_subject_id
          // disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
        },
      };
      // if(activeExperience.questionbank_title === "IB DP"){
      // // if(activeExperience.questionbank_title === "AP SP DP bank"){
      //   properties.custom_fields = {
      //     type: "object",
      //     properties: {
      //       ib_reference_code: {
      //         placeholder: <MrTranslate id={"CommonText.ib_reference_code"}/>,
      //         type: "string",
      //         span: 8,
      //       },
      //       ib_question_type: {
      //         placeholder: <MrTranslate id={"CommonText.ib_question_type"}/>,
      //         // placeholder: "IB question type",
      //         type: "string",
      //         span: 8,
      //       }
      //     }
      //   }
      // }
    }

    // TODO: issue in using the same SelectTagWidget - resourcename tags for all 3 so its overriding all with the last one

    // TODO: need to handle differently when adding segments in qb - like syll topics
    if(activeExperience && activeExperience.questionbank_title.includes("DP")){
      properties.relation_items.items.properties.difficulty_level_tag_ids = {
        placeholder: <MrTranslate id={"SegmentCreateForm.select_difficulty_level"}/>,
        type: "string",
        span: 12,
        wrapClassName:
          nestingLevel > 1
            ? "hidden"
            : "",
        // widget: SelectTagWidget,
        // widget: SelectDifficultyLevel,
        // widgetConfig: {
        //   type_c: "difficulty_level"
        //   // disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
        // },
        widget: SelectDifficultyLevelWidget,
        widgetConfig: {
          type_c: "difficulty_level",
          programme_id: activeExperience && activeExperience.relation_items[0].programme_id,
          // programme_subject_id: activeExperience.relation_items[0].subject_id, // in case of qb exp, subject_id from BE in serializer is equal to programme_subject_id
          // programme_grade_id: activeExperience.relation_items[0].grade_id, // in case of qb exp, grade_id from BE in serializer is equal to programme_grade_id
          // disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
        },
      };
      properties.relation_items.items.properties.teaching_level_tag_ids = {
        placeholder: <MrTranslate id={"SegmentCreateForm.select_teaching_level"}/>,
        type: "string",
        span: 12,
        wrapClassName:
          nestingLevel > 1
            ? "hidden"
            : "",
        // widget: SelectTagWidget,
        widget: SelectTeachingLevel,
        widgetConfig: {
          type_c: "teaching_level"
          // disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
        },
      };
      properties.relation_items.items.properties.assessment_objective_tag_ids = {
        placeholder: <MrTranslate id={"SegmentCreateForm.select_assessment_objective"}/>,
        type: "string",
        span: 12,
        // wrapClassName:
        //   nestingLevel > 1
        //     ? "hidden"
        //     : "",
        // widget: SelectTagWidget,
        widget: SelectAssessmentObjectiveWidget,
        widgetConfig: {
          type_c: "assessment_objective",
          programme_id: activeExperience.relation_items[0].programme_id,
          programme_subject_id: activeExperience.relation_items[0].subject_id, // in case of qb exp, subject_id from BE in serializer is equal to programme_subject_id
          // disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
        },
      };
    }
    if(activeExperience && !activeExperience.questionbank_title.includes("DP") && !activeExperience.questionbank_title.includes("MYP")){
      // TODO: need to handle differently when adding segments in qb - like syll topics
      properties.relation_items.items.properties.depth_of_knowledge_tag_ids = {
        placeholder: <MrTranslate id={"SegmentCreateForm.select_dok_level_placeholder"}/>,
        type: "string",
        span: 12,
        wrapClassName:
          nestingLevel > 1
            ? "hidden"
            : "",
        // widget: SelectTagWidget,
        widget: SelectDepthOfKnowledge,
        widgetConfig: {
          type_c: "depth_of_knowledge"
          // disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
        },
      };
    }
    
    // properties.custom_fields = properties.custom_fields || {type: "object",
    // properties: {}}
    // properties.custom_fields.properties.teaching_level =  {
    //   placeholder: "Select teaching level",
    //   type: "string",
    //   span: 12,
    //   wrapClassName:
    //     nestingLevel > 1
    //       ? "hidden"
    //       : "",
    //   widget: SelectTeachingLevel,
    //   widgetConfig: {
    //     // disabled: formValues.relation_items && formValues.relation_items[0].questionbank_id ? true : false
    //   },
    // }
  }

  let finalForm = {
    processFormData: processFormData,
    schema: {
      type: "object",
      properties: properties,
    },
  };

  return finalForm;
};

export default generateSegmentCreateForm;
