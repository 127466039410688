import React from "react";
import { Layout } from "antd";
import classNames from "classnames";
import styled from "styled-components";
// import loginBgImage from "/src/assets/images/login-bg-large-5.png";

const { Header, Content, Footer } = Layout;
const StyledLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  // background-color: red !important;
`;

const StyledContent = styled(Content)`
  // padding: 32px 0 24px 0px;
  flex: 1 1;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(255, 255, 255, 0) !important;
`;

const StyledFooter = styled(Footer)`
  margin: 48px 0 24px 0;
  padding: 0 16px;
  text-align: center;
  display: block;
  ::before,
  ::after {
    box-sizing: border-box;
  }
  background-color: rgba(255, 255, 255, 0) !important;
`;
const BaseLayout = (props) => {
  let content = [];
  content.push(<Header key={"header"} className="base-header"></Header>);
  content.push(<StyledContent key={"content"}>{props.children}</StyledContent>);
  content.push(<StyledFooter key={"footer"}></StyledFooter>);
  return (
    <StyledLayout
      className={classNames("layout")}
      // style={{ backgroundImage: `url(${loginBgImage})`, backgroundPosition: "center", backgroundRepeat: "no-repeat" }}
    >
      {content}
    </StyledLayout>
  );
};
export default BaseLayout;
