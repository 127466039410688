import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal, Tooltip, Alert, Space, Result } from "antd";
import { FontColorsOutlined, SendOutlined } from "@ant-design/icons";
import queryString from "query-string";
import * as Sentry from "@sentry/react";
import {
  checkIPAD,
  checkIfSEBConfigIsValid,
  checkMob,
  checkOffsetInterval,
  checkScreen,
  convertMs,
  currentTimeValidWrtServerTime,
  getFromLS,
  getPermissions,
  getServerTimeOffset,
  setUserInfoOnBeforeEndSession,
  timeInMiliSeconds,
} from "/src/lib/utils/helperMethods";
import {
  currentViewSelector,
  extraTimeOffsetSelector,
  extraTimeSelector,
  focusLostStatusSelector,
  getFirebaseTokenLoadingSelector,
  isFirebaseAuthenticatedSelector,
  mobileUploadModeSelector,
  securitySelector,
  startedAtSelector,
  submitExperienceLoadingSelector,
  experienceInfoSelector,
  focusLostCountSelector,
  sessionIdSelector,
  durationAfterResetSelector,
  calcEnabledSelector,
  spellCheckEnabledSelector,
  sttAllowedSelector,
  signInToFirestoreErrorSelector,
  graphingCalcEnabledSelector,
  enableLocalStorageSyncSelector} from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import {
  studentVideoCallConfig,
  teacherVideoCallConfig,
} from "/src/lib/VideoCall/config";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useFullScreenHandle } from "react-full-screen";
import {
  appRegionSelector,
  currentUserSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";
import { firestoreFieldValue } from "/src/config/initializers";
import ProgressBar from "/src/views/Experiences/ExperienceShow/Components/ProgressBar";
import CountDownTimer from "/src/views/Experiences/ExperienceShow/Components/CountDownTimer";
import FocusLostModal from "/src/views/Experiences/ExperienceShow/Components/FocusLostModal";
import StudentChat from "/src/views/Experiences/ExperienceShow/Components/StudentChat";
import StartViewOld from "/src/views/Experiences/ExperienceShow/ExperienceTake/StartViewOld";
import EndViewOld from "/src/views/Experiences/ExperienceShow/ExperienceTake/EndViewOld";
import SubmitViewOld from "/src/views/Experiences/ExperienceShow/ExperienceTake/SubmitViewOld";
import ResultViewOld from "/src/views/Experiences/ExperienceShow/ExperienceTake/ResultViewOld";
import ResumeViewOld from "/src/views/Experiences/ExperienceShow/ExperienceTake/ResumeViewOld";
import JoinViewOld from "/src/views/Experiences/ExperienceShow/ExperienceTake/JoinViewOld";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { isOfflineExperienceAndNotAllowedOnline, unMountExperience } from "/src/views/Experiences/ExperienceHelperMethods";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";

import VideoCall from "/src/lib/VideoCall/VideoCall";
import FirestoreInteractions from "../FirestoreInteractions/FirestoreInteractions";
import { appTypeSelector, appValidSelector, changeSecurityStatusSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import screenfull from 'screenfull';
import StudentFloatingTools from "../Components/StudentFloatingTools";
import { actions as segmentsActions } from "/src/views/Segments/redux"; 
import { experienceConfigTakeExperienceSelector, experienceTypeSelector, getOfflineDBStatusSelector, getServerTimeOffsetSelector, switchFromStartToResumeViewSelector, skipFocusLostModalSelector } from "/src/views/Experiences/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import TakeScreenshots from "/src/components/UI/TakeScreenshots";

import StartInSebBtn from "/src/components/UI/SEB/StartInSebBtn";
import DownloadAppsModal from "/src/views/OfflineApps/DownloadAppsModal";
import QuitBtn from "/src/components/UI/QuitBtn";
import dayjs from "dayjs";
import loadable from "@loadable/component";
import { Redirect } from "react-router-dom";
import { message } from "/src/components/UI/AntdAppHelper";
import { showConfirmModal, showNotification } from "/src/components/UI/Segment/UIHelper";
import ToolsSidebar from "../Components/ToolsSidebar";
import { usePrevious } from "/src/views/Segments/InteractiveHelpers";
import { checkIfSEBHasDynamicConfig, getAppVersion, getWhiteListUrls, isNewAppVersion, macAppsToClose, supportsMacAACSecurity } from "/src/App/OfflineApp/offlineAppHelper";
import LocalStorageSyncView from "./LocalStorageSyncView/LocalStorageSyncView";

const grammarlyDisableCSS = loadable.lib(() => import('/src/App/GrammarlyDisable.scss'))

const ExperienceTakeOld = (props) => {
  const { experienceProps } = props;

  const {
    experience,
    experienceSettings,
    embedded,
    experienceViewMode,
    setUserInfo,
    fromResourceName, 
    fromUrl
  } = experienceProps;

  console.log(
    "userInfoFromReduxsecurity",
    experienceSettings.mode,
    !embedded,
    experienceViewMode
  );
  console.log("window.desktopApp", window.desktopApp);
  console.log("ExperienceTake props", props, experienceProps);

  // const isMobile = checkMob();
  // const isIPAD = experienceSettings.mode != "offline" && checkIPAD();
  let isTab = experienceSettings.mode != "offline" && checkIPAD();
  let isMobile = checkMob();

  if(isTab) {
    isMobile = false
  }
  
  // if (import.meta.env.VITE_MODE != 'production') {
  //   isMobile = checkScreen('mobile') 
  //   isTab = (!checkScreen('mobile') && checkScreen('tab')) || (experienceSettings.mode != "offline" && checkIPAD());
  // }
 
  const location = useLocation();
  const dispatch = useDispatch();

  console.log('location inside take==>', location);

  const currentUser = useSelector(currentUserSelector());
  const isFirebaseAuthenticated = useSelector(isFirebaseAuthenticatedSelector);
  const signInToFirestoreError = useSelector(signInToFirestoreErrorSelector);
  const userInfoFromReduxsecurity = useSelector(securitySelector);
  const userInfoFromReduxcurrentView = useSelector(currentViewSelector);
  const userInfoFromReduxEnableLocalStorageSync = useSelector(enableLocalStorageSyncSelector);
  const userInfoFromReduxstartedAt = useSelector(startedAtSelector);
  const userInfoFromReduxsessionId = useSelector(sessionIdSelector)
  const userInfoFromReduxextraTime = useSelector(extraTimeSelector);
  const userInfoFromReduxextraTimeOffset = useSelector(extraTimeOffsetSelector);
  const durationAfterReset = useSelector(durationAfterResetSelector)
  const userInfoFromReduxmobileUploadMode = useSelector(
    mobileUploadModeSelector
  );
  const userInfoFromReduxFocusLostCount = useSelector(
    focusLostCountSelector
  );
  
  const serverTimeOffset = useSelector(getServerTimeOffsetSelector())
  const appRegion = useSelector(appRegionSelector());
  const appType = useSelector(appTypeSelector());
  const isFirebaseTokenLoading = useSelector(getFirebaseTokenLoadingSelector);
  const userInfoFromReduxFocusLostStatus = useSelector(focusLostStatusSelector);
  const offlineDBStatus = useSelector(getOfflineDBStatusSelector());
  const switchFromStartToResumeView = useSelector(switchFromStartToResumeViewSelector())
  const skipFocusLostModal = useSelector(skipFocusLostModalSelector())
  const experienceType = useSelector(experienceTypeSelector())
  const experienceConfigTakeFromRedux = useSelector(experienceConfigTakeExperienceSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const changeSecurityStatus = useSelector(changeSecurityStatusSelector());
  const calcEnabledFromUserInfo = useSelector(calcEnabledSelector()) ?? undefined; // For Firestore default is undefined and for supabase default is null
  const graphingCalcEnabledFromUserInfo = useSelector(graphingCalcEnabledSelector()) ?? undefined;
  const spellCheckEnabledFromUserInfo = useSelector(spellCheckEnabledSelector()) ?? undefined;
  console.log("graphingCalcEnabledFromUserInfo ====>", graphingCalcEnabledFromUserInfo);
  const sttCheckEnabledFromUserInfo = useSelector(sttAllowedSelector) ?? undefined;
  const [showQRModal, setShowQRModal] = useState(false);
  const [mobileUploadMode, setMobileUploadMode] = useState(null);
  const [focusLostModalVisible, setFocusLostModalVisible] = useState(false);
  const [resumeModalVisible, setResumeModalVisible] = useState(false);
  const [showStudentCodeAckModal, setShowStudentCodeAckModal] = useState(false);
  const [focusLostExplanation, setFocusLostExplanation] = useState("");
  const [isUserInfoInRedux, setIsUserInfoInRedux] = useState(false);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const currentSystemAppVersion = getAppVersion(appType)
  const mrIntl = useTranslate();
  // const [
  //   switchFromStartToResumeView,
  //   setSwitchFromStartToResumeView,
  // ] = useState(true);
  const [endTestButtonLoading, setEndTestButtonLoading] = useState(false);
  const [backToTestButtonLoading, setBackToTestButtonLoading] = useState(false);
  
  const prevCalcEnabled = usePrevious(calcEnabledFromUserInfo);
  const prevGraphingcalcEnabled = usePrevious(graphingCalcEnabledFromUserInfo);

  const prevSpellcheckEnabled = usePrevious(spellCheckEnabledFromUserInfo);
  const prevSttEnabled = usePrevious(sttCheckEnabledFromUserInfo);
  
  const safeExamBrowserObject = window.SafeExamBrowser || {};
  const sebSecurity = safeExamBrowserObject.security || {}
  const configKeyFromJSAPI = sebSecurity.configKey;

  const locationQueryParams = queryString.parse(location.search);
  const lockdownConfig = experienceSettings.lockdown_config || {};
  const sebConfigKeys = lockdownConfig.seb_config_keys || [];

  const whitelistUrls = getWhiteListUrls(experienceSettings)


  const isDynamicLockdownConfigForMacApp =
    appType === "mac" &&
    experienceSettings.mode == "offline" &&
    currentUser.role == "student" &&
    supportsMacAACSecurity(appType) &&
    false && locationQueryParams.appLogin;

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  // TODO: Config key from JSAPI and SEB config key are not matching because we are generating a dynamic config, seb_config_key is a static value coming from the backend.
  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  const {validKey, hashedKeys} = checkIfSEBConfigIsValid(configKeyFromJSAPI, sebConfigKeys)
  const isInvalidSEBConfig = appType === "seb" && !checkIfSEBHasDynamicConfig(appType) && !validKey ;

  // const hashedKeysMap = hashedKeys.map((h) => {
  //   return <p>{h.sha2}</p>
  // })

  // useEffect(() => {
  //   if (appType === "seb") {
  //     showConfirmModal({
  //       title: "Invalid SEB Config",
  //       content: <>
  //         <p>Invalid SEB Config.</p><br />
  //         <p>configKeyFromJSAPI {configKeyFromJSAPI}</p><br />
  //         <p>checkIfSEBHasDynamicConfig: {`${checkIfSEBHasDynamicConfig(appType)}`}</p>
  //         <p>href: {window.location.href}</p>
  //         <p>hashedKeys: {hashedKeysMap}</p>
  //       </>,
  //     })
  //   }
  // }, [isInvalidSEBConfig])

  let isStudentSideOfflineModeAACSupportedMacApp =
    appType == "mac" &&
    experienceSettings.mode == "offline" &&
    currentUser.role == "student" &&
    experienceViewMode === "apTakeTest" &&
    supportsMacAACSecurity(appType);

  let shouldSecurityInterruptedEventCheck =
    isStudentSideOfflineModeAACSupportedMacApp &&
    !isNewAppVersion(currentSystemAppVersion, "10.2.0");

     //!isNewAppVersion(currentSystemAppVersion, "10.2.0") => Lower version of app < 10.2.0 with not supported AAC will quit the app automatically when security interrupted. Because we did not handled native Alert or Method to quit the app but now v10.2.0, We will showing the alert with quit button whenever security was interrupted.

  const setSwitchFromStartToResumeView = (value) => {
    console.log("experienceReduxActions ==>", experienceReduxActions)
    dispatch(experienceReduxActions.setSwitchFromStartToResumeViewSuccess({switchFromStartToResumeView: value}))
  }

  const setServerTimeCallback = (offset) => {
    console.log("setServerTimeCallback offset", offset);
    dispatch(
      experienceReduxActions.setServerTimeOffsetSuccess({
        serverTimeOffset: offset,
      })
    );
  };  

  const handleFocusLostEventMac = (e) => {
    console.log("focusLost e", e);
    // e.value
    // set focus lost
    // set userinfo and log

    // setFocusLostModalVisible(true);
    // setResumeModalVisible(false);
    // let focusLostCount = userInfoFromReduxFocusLostCount;
    // setUserInfo(
    //   {
    //     is_focus_lost: true,
    //     focus_lost_count:
    //       appRegion == "china"
    //         ? ++focusLostCount
    //         : firestoreFieldValue.increment(1),
    //   },
    //   {
    //     log: {
    //       // logging: experienceProps.log.logging,
    //       logging: true,
    //       log_level: "warning",
    //       // logging: true,
    //       msg: `Focus lost for student`,
    //     },
    //   }
    // );

    //APL-2478
    if (userInfoFromReduxcurrentView != "resumeTest") {
      setUserInfo(
        {
          currentView: "resumeTest",
        },
        {
          log: {
            logging: true,
            action: "focus_lost_in_mac",
          },
        }
      );
    }
   
  }

  const handleAACSecurityInterrupted = (e) => {
    // NOt setting false since decided to Quit the app
    // dispatch(
    //   offlineAppActions.executeMethodSuccess({ key: "changeSecurity", response: "false" })
    // );

    if (shouldSecurityInterruptedEventCheck && userInfoFromReduxcurrentView !== "viewFeedback") {
      setUserInfo(
        {},
        {
          log: {
            // logging: experienceProps.log.logging,
            logging: true,
            log_level: "warning",
            // logging: true,
            action: "security_interrupted_in_aac_mode_quitting_app",
          },
        }
      );
      // Decided to quit the app for now - refreshing the page after showing the error was causing issues since changeSecurity only in redux
      setTimeout(
        () => {
          dispatch(
            offlineAppActions.executeMethod([
              {
                key: "quit",
                value: true,
              },
            ])
          );
        },
        1500 // for the log to be sent
      );
  
      // dispatch(
      //   offlineAppActions.executeMethod([
      //     { key: "changeSecurity", value: true }
      //   ])
      // );
    }

  }
  
  const handleSetAACSecurityInterruptedLog = (e) => {
    // Before the Quit app from native side alert , We will Update the log whenever security interrupted 
      setUserInfo(
        {},
        {
          log: {
            logging: true,
            log_level: "warning",
            action: "security_interrupted_in_aac_mode",
          },
        }
      );
      //Send native error log to sentry
    Sentry.captureMessage(`Security Interrupted appVersion: ${currentSystemAppVersion} error: ${e.detail}`);
  };


  const handleSetSecurityResumeLog = (e) => {
    // On the native side, when a security interruption occurs, an alert message is displayed with the option to 'Resume the session.' Clicking on this option triggers a function to save log information.
      setUserInfo(
        {},
        {
          log: {
            logging: true,
            log_level: "info",
            action: "resuming_after_security_interrupted_in_aac_mode",
          },
        }
      );
  };

  const handleSetNativeQuitLog = (e) => {
    // On the native side, in the event of a security interruption, an alert message is presented with the option to 'Quit.' Clicking on this option triggers a function to save the log information.
      setUserInfo(
        {},
        {
          log: {
            logging: true,
            log_level: "info",
            action: "quit_after_security_interrupted_in_aac_mode",
          },
        }
      );
  };

  const refreshModalDescription = (
    <>
      <p>
        {mrIntl("ExperienceTake.there_are_some_changes_in_the_test_please_refresh_the")}
      </p>
      <Button type="primary" onClick={() => window.location.reload()}>{mrIntl("CommonText.refresh")}</Button>
    </>
  );
  

  useEffect(() => {
    grammarlyDisableCSS.load()
  }, [])

  useEffect(() => {
    if (userInfoFromReduxFocusLostStatus) {
      setFocusLostExplanation("");
      setFocusLostModalVisible(true);
      setResumeModalVisible(false);
    }
  }, [userInfoFromReduxFocusLostStatus]);

  useEffect(() => {
    // mobileUploadMode

    // DONT REMOVE
    // FULL SCREEN and FOCUS LOST
    // console.log("addEventListener blur", tempMobileUploadMode, embedded, experienceViewMode, experience.settings.mode, userInfoFromReduxcurrentView)
    // if(!embedded && !isMobile && experienceViewMode == "apTakeTest" && experience.settings.mode == "online_secure" ){
    //   window.addEventListener("blur", blurFunction, {capture: true});
    //   window.addEventListener("focus", focusFunction, {capture: true});
    // }
    // FULL SCREEN and FOCUS LOST

    if (appType === "ios"){
      dispatch(offlineAppActions.executeMethod({key: "clearClipboard", value: "true"}))
    }

    if (
      // !(isMobile && !((currentUser.org_id == 948 || currentUser.org_id == 99) && isScreenSizeSupported)) &&
      !isMobile &&
      !embedded &&
      experienceViewMode == "apTakeTest" &&
      (experienceSettings.mode == "online_secure" ||
        (experienceSettings.mode == "offline" && appType == "web")) &&
      !mobileUploadMode
    ) {
      if (!document.hasFocus()) {
        setFocusLostExplanation("");
        setFocusLostModalVisible(true);
        setResumeModalVisible(false);
      } else {
        setTimeout(() => {
          if (screenfull.isEnabled && false) {
            console.log(
              "focus lost nahi hua==>",
              screenfull.isEnabled,
              screenfull.isFullscreen,
              resumeModalVisible
            );
            if (screenfull.isFullscreen) {
              setResumeModalVisible(false);
            } else {
              setResumeModalVisible(true);
            }
          }
        }, 1000);
      }
    }

    console.log(
      "isMobile, mobileUploadMode ==>",
      isMobile,
      location.search.indexOf("mobileUploadMode")
    );
    if (
      (isMobile || isTab) &&
      location.search.indexOf("mobileUploadMode") > -1
    ) {
      // if(location.search.indexOf("mobileUploadMode") > -1){
      setMobileUploadMode(true); // get from params and true only if isMobile also true
    } else {
      setMobileUploadMode(false);
    }
    // mobileUploadMode END

    // if user account type guest, then show this modal by default on start test to note down their s_code
    //  console.log("currentUser.custom_fields", currentUser.custom_fields, userInfoFromReduxcurrentView)
    if (currentUser && currentUser.custom_fields.account_type == "guest") {
      setShowStudentCodeAckModal(true);
    }

    getServerTimeOffset(setServerTimeCallback); // still need this here for iframes and guests - TODO: can make condiitonal if we feel too many requests

    if (appType == "web" || experienceSettings.mode !== "offline"){ // for web tests and app tests that are without security
      checkOffsetInterval("on", setServerTimeCallback) // TODO: not limiting to scheduled tests only - confirm
    }

    //TODO Calling from mac to logout feature
    // if(appType == "mac"){
    //   window.addEventListener("executeSessionLogout", (e) => {
    //     console.log("call executeSessionLogout", e.detail);
    //     dispatch(loginActions.logout())
    //   })
    // }

    if (
      appType == "mac" &&
      experienceSettings.mode == "offline" &&
      currentUser.role == "student" 
      && !supportsMacAACSecurity(appType)
    ) {
      dispatch(
        offlineAppActions.executeMethod([
          { key: "changeSecurity", value: true },
          { key: "clearClipboard", value: true },
          { key: "closeAllApps", value: macAppsToClose },
          { key: "setWhitelistedUrls", value: whitelistUrls },
        ])
      );
    }

    return () => {
      if (appType == "web" || experienceSettings.mode !== "offline"){ // for web tests and app tests that are without security
        checkOffsetInterval("off", setServerTimeCallback)
      }
    }
  }, []);
  
  // For old code it was in StartView but for new code it is in ExperienceTake
  useEffect(() => {
    if (userInfoFromReduxsessionId) {
      setIsUserInfoInRedux(true);
    }
  }, [userInfoFromReduxsessionId]);

  useEffect(() => {
    // Also showing notification of changing values for calculator and spellcheck from monitor tab for each student.
    // Using Previous values so that notification won't show on initial mount 

    if (((prevCalcEnabled !== calcEnabledFromUserInfo || prevGraphingcalcEnabled !== graphingCalcEnabledFromUserInfo || prevSpellcheckEnabled !== spellCheckEnabledFromUserInfo || prevSttEnabled !== sttCheckEnabledFromUserInfo) && isUserInfoInRedux) && (userInfoFromReduxcurrentView === "startTest" || userInfoFromReduxcurrentView === "joined")) {
      showNotification("warning", {
        key: "refresh-page",
        message: mrIntl("StartView.refresh_page_msg"),
        description: refreshModalDescription,
        duration: 0,
        top: 65,
        // closeIcon: () => { // making issue it applying globally for all notification
        //   return null
        // },
      });
    }
    return () => {
      showNotification("destroy", "refresh-page")
    }
  }, [calcEnabledFromUserInfo, spellCheckEnabledFromUserInfo, sttCheckEnabledFromUserInfo, graphingCalcEnabledFromUserInfo])

  useEffect(() => {
    if (
      appType == "mac" &&
      experienceSettings.mode == "offline" &&
      currentUser.role == "student" && 
      !supportsMacAACSecurity(appType)
    ) {
      // removing event listener for AAC (v10 onwards)
      window.addEventListener("AssessPrepFocusLost", handleFocusLostEventMac);
    }

    return () => {
      window.removeEventListener("AssessPrepFocusLost", handleFocusLostEventMac);
    };
  }, [handleFocusLostEventMac])

//When security is interrupted, Resume/Turning on the session again from native side and then give 2 buttons.Resume and Quit. 
//When security is interrupted, calling ==> handleSetAACSecurityInterruptedLog => save the logs
//Native side give 2 buttons.  => save the logs
//Resume test =>  handleSetSecurityResumeLog  => save the logs
//Resume and Quit => handleSetNativeQuitLog  => save the logs

  useEffect(() => {
    if (
      isStudentSideOfflineModeAACSupportedMacApp &&
      isNewAppVersion(currentSystemAppVersion, "10.2.0") // supportsMacAACSecurity => 10.2.0 onward apps supported the AAC apple security mode
    ) {
      // removing event listener for AAC (v10 onwards)
      window.addEventListener(
        "SetAACSecurityInterruptedLog",
        handleSetAACSecurityInterruptedLog
      );

      window.addEventListener(
        "SetSecurityResumeLog",
        handleSetSecurityResumeLog
      );

      window.addEventListener("SetNativeQuitLog", handleSetNativeQuitLog);

    }

    return () => {
      window.removeEventListener(
        "SetAACSecurityInterruptedLog",
        handleSetAACSecurityInterruptedLog
      );
      window.removeEventListener(
        "SetSecurityResumeLog",
        handleSetSecurityResumeLog
      );
      window.removeEventListener(
        "SetNativeQuitLog",
        handleSetNativeQuitLog
      );
    };
  }, []);

  
  useEffect(() => {
    if (
      shouldSecurityInterruptedEventCheck
      // Check Mac and iOS appType from there supportsMacAACSecurity function and now handling security interrupted to both mac and iOS app type
    ) {
      window.addEventListener(
        "AACSecurityInterrupted",
        handleAACSecurityInterrupted
      );
    }

    return () => {
      window.removeEventListener(
        "AACSecurityInterrupted",
        handleAACSecurityInterrupted
      );
    };
  }, [shouldSecurityInterruptedEventCheck]);

  const showQuitAlertOnNativeSide = (e) => {
    setUserInfo(
      {},
      {
        log: {
          // logging: experienceProps.log.logging,
          logging: true,
          log_level: "warning",
          // logging: true,
          action: "security_could_not_be_turned_on",
        },
      });
    // mac app v10.2.0 , Native side we have showQuitAlert function to showing alert with info, Quit app option.
    // If security could not to be turned on , we have a option to app Quit only 
    dispatch(
      offlineAppActions.executeMethod([
        { key: "showQuitAlert", value: "Security couldn't be turned on, and the test cannot be started. In case this issue continues, please restart your machine and try again." }
      ])
    );
}

useEffect(() => {
  // //don't want to open test if isDynamicLockdownConfigForMacApp and not supported AAC mode  
  if (isDynamicLockdownConfigForMacApp && isNewAppVersion(currentSystemAppVersion, "10.2.0")) {
    dispatch(
      offlineAppActions.executeMethod(
        [
          {
            key: "getSecurityStatus",
            value: "status",
            successCallback: (response) => {
              if (response !== "true") {
                dispatch(
                  offlineAppActions.executeMethod([
                    { key: "changeSecurity", value: true },
                    { key: "setWhitelistedUrls", value: whitelistUrls },
                  ])
                );
              }
            },
            errorCallback: () => {
              showQuitAlertOnNativeSide();
            },
          },
        ],
        {
          errorCallback: () => {
            showQuitAlertOnNativeSide();
          },
        }
      )
    );
  }
}, []);

useEffect(() => {
  if (isDynamicLockdownConfigForMacApp && isNewAppVersion(currentSystemAppVersion, "10.2.0") && (changeSecurityStatus === "false" || changeSecurityStatus === "failed")) {
      console.log("Show error interrupted");
       // mac app v10.2.0 , Native side we have showQuitAlert function to showing alert with info, Quit app option.
      // If security could not to be turned on , we have a option to app Quit only if launching app from dynamic config
      showQuitAlertOnNativeSide()
      //handleAACSecurityInterrupted();
      //openUrl("/error")
  }
}, [changeSecurityStatus]);


useEffect(() => {
  if (
    (appType === "ios" &&
    isNewAppVersion(currentSystemAppVersion, "4.0.0")) || (appType === "mac" &&
    isNewAppVersion(currentSystemAppVersion, "12.0.0")) //iPad 4.0.0 supported native session end and mac v12 native quit 
  ) {
    window.addEventListener("onBeforeEndSession", handleOnBeforeEndSession);
  }

  return () => {
    window.removeEventListener(
      "onBeforeEndSession",
      handleOnBeforeEndSession
    );
  };
}, [userInfoFromReduxcurrentView]);



const handleOnBeforeEndSession = (e) => {
  // On the native side, in the event of 'Session End.' taping on this option triggers a function to save the log information.
  let actionFrom = signInToFirestoreError ? "errorInPage" : userInfoFromReduxcurrentView
  setUserInfoOnBeforeEndSession(actionFrom, setUserInfo, appType);
};

  
  // Not usefull for mac
  // useEffect(() => {
  //   if (experienceSettings.enable_ap_video_monitoring) {
  //     getPermissions(
  //       { audio: true, video: true },
  //       {
  //         successCallback: () => {
  //           console.log("permission given.");
  //         },
  //         errorCallback: () => {
  //           console.log("some error happened in taking permission.");
  //         },
  //       }
  //     );
  //   }
  // }, []);

  // TODO: check if still needed-  I think its for native mac app logout and login case/ sleep case
  // // MAC app focus lost
  // useEffect(() => {
  //   console.log("window.hasNativeAppLostFocus", window.hasNativeAppLostFocus, appType)
  //   if(window.hasNativeAppLostFocus == true && appType == "mac" && experienceViewMode === "apTakeTest" && experience.settings.mode == "offline"){
  //     setFocusLostModalVisible(true)
  //   }
  // }, [window.hasNativeAppLostFocus])
  // // MAC app focus lost

  // FOCUS LOST
  useEffect(() => {
    // Resume test view
    // console.log(
    //   "going to resume page",
    //   userInfoFromReduxcurrentView,
    //   switchFromStartToResumeView,
    //   appType,
    //   location.search
    // );

    // MOVED to getFirebaseToken
    
    // if (
    //   (userInfoFromReduxcurrentView == "startTest" ||
    //     userInfoFromReduxcurrentView == "endTest") &&
    //   switchFromStartToResumeView == true &&
    //   experienceViewMode != "apPreviewTest" &&
    //   experienceSettings.mode == "offline" &&
    //   (appType == "seb" || appType == "mac" || appType == "ios") &&
    //   location.search.indexOf("firstLoad") > -1
    // ) {
    //   console.log("going to resume page inside now");
    //   // only for seb and high stake right now and only on first time load from test list page - not on refresh
    //   setUserInfo(
    //     {
    //       currentView: "resumeTest",
    //     },
    //     {
    //       log: {
    //         // logging: experienceProps.log.logging,
    //         logging: true,
    //         msg: `Re-entered the test`,
    //       },
    //     }
    //   );
    // }
    // Resume test view end

    if (
      userInfoFromReduxcurrentView == "startTest" ||
      userInfoFromReduxcurrentView == "endTest"
    ) {
      // && !isTab
      if (	
        !isMobile &&	
        !embedded &&	
        experienceViewMode == "apTakeTest" &&	
        (experienceSettings.mode == "online_secure" ||	
          (experienceSettings.mode == "offline" && appType === "web")) &&	
        !mobileUploadMode
      ) {
        window.addEventListener("blur", blurFunction, { capture: true });
        window.addEventListener("focus", focusFunction, { capture: true });
        if (isTab) {
          window.addEventListener("visibilitychange", blurFunction, {
            capture: true,
          }); // for ipad chrome and safari - tab switching was not triggering blur event - see https://whatwebcando.today/foreground-detection.html - should use this in all cases?
        }
      }
      if (	
        !embedded &&	
        experienceViewMode == "apTakeTest" &&	
        (experienceSettings.mode == "online_secure" ||	
          (experienceSettings.mode == "offline" && appType == "web")) &&	
        !mobileUploadMode	
      ) {
        // Done by uttam
        // window.addEventListener("beforeunload", beforeunloadFunction);
      }

      if (((isMobile || isTab) && appType !== "ios") && userInfoFromReduxmobileUploadMode) {
        // in case refreshed while already in mobileUploadMode
        setMobileUploadMode(userInfoFromReduxmobileUploadMode);
      }
    }
    return () => {
      if (
        userInfoFromReduxcurrentView == "startTest" ||
        userInfoFromReduxcurrentView == "endTest"
      ) {
        // && !isTab
        if (	
          !isMobile &&	
          !embedded &&	
          experienceViewMode == "apTakeTest" &&	
          (experienceSettings.mode == "online_secure" ||	
            (experienceSettings.mode == "offline" && appType == "web")) &&	
          !mobileUploadMode	
        ) {
          window.removeEventListener("blur", blurFunction, { capture: true });
          window.removeEventListener("focus", focusFunction, { capture: true });
          if (isTab) {
            window.removeEventListener("visibilitychange", blurFunction, {
              capture: true,
            });
          }
        }

        if (	
          !embedded &&	
          experienceViewMode == "apTakeTest" &&	
          (experienceSettings.mode == "online_secure" ||	
            (experienceSettings.mode == "offline" && appType == "web")) &&	
          !mobileUploadMode	
        ) {
          // Done by uttam
          // window.removeEventListener("beforeunload", beforeunloadFunction);
        }
      }
    };
  }, [userInfoFromReduxcurrentView, focusLostModalVisible, skipFocusLostModal]);

  useEffect(() => {
    if (
      !embedded &&
      !mobileUploadMode &&
      experienceViewMode == "apTakeTest" &&
      experienceSettings.mode == "online_secure"
    ) {
      if (!screenfull.isFullscreen && false) {
        setResumeModalVisible(true);
        console.log(
          "experienceViewMode",
          experienceViewMode,
        );
      }
    }
  }, [screenfull.isFullscreen])

  // for track Esc click

  // useEffect(() => {
  //   document.addEventListener("keydown", escFunction);

  //   return () => {
  //     document.removeEventListener("keydown", escFunction);
  //   };
  // }, []);

  // const escFunction = (event) => {
  //   if (event.keyCode === 27) {
  //     console.log("esc pressed");
  //     setFocusLostModalVisible(true);
  //     setFocusLostExplanation("");
  //   }
  // }

  const mainTestScreen = useFullScreenHandle();
  const reportChange = useCallback(
    (state, handle) => {
      if (handle === mainTestScreen) {
        console.log("Screen 1 went to", state, handle);
        if (state == false) {
          setFocusLostModalVisible(true);
        }
      }
    },
    [mainTestScreen]
  );

  // const handleVisibilityChange = (isVisible) => {
  //   console.log('handleVisibilityChange', isVisible, document.visibilityState, document.hasFocus());
  //   if(isVisible === false){
  //     setFocusLostModalVisible(true)
  //   }
  // }
  var hasUserLeft = false;

  // TODO: Move these function to helper mathods
  const blurFunction = () => {
    console.log(
      "blurFunction",
      document.visibilityState,
      document.hasFocus(),
      userInfoFromReduxcurrentView,
      focusLostModalVisible
    );

    // alert('blurFunction', document.visibilityState, document.hasFocus(), userInfoFromReduxcurrentView, focusLostModalVisible)
    if (!document.hasFocus() && focusLostModalVisible != true && !hasUserLeft && !skipFocusLostModal ) {
      //!skipFocusLostModal => Skipping the focus lost modal when camera permission will show for ImageCaptureUpload modal component, It will skip for only 2 sec,

      // no focus and modal not alrady visible
      setFocusLostExplanation("");
      // mainTestScreen.exit() // enable again when fullscreen fixed
      setFocusLostModalVisible(true);
      setResumeModalVisible(false);
      // setUserInfo({is_focus_lost: true, focus_lost_count: focusLostCount + 1}, {
      let focusLostCount = userInfoFromReduxFocusLostCount;
      setUserInfo(
        {
          is_focus_lost: true,
          focus_lost_at:  Date.now(),
          focus_lost_count:
            appRegion == "china" ? ++focusLostCount : firestoreFieldValue.increment(1),
        },
        {
          log: {
            logging: true,
            action: "focus_lost_on_blur",
          },
        }
      );
    }
  };

  const focusFunction = () => {
    console.log("focusFunction", document.visibilityState, document.hasFocus());
    // TODO: show modal on focus also
  };

  const settingHasUserLeftAfterInterval = () => {
    hasUserLeft = false;
  };

  const beforeunloadFunction = (e) => {
    setTimeout(settingHasUserLeftAfterInterval, 100);
    hasUserLeft = true;
    // Cancel the event
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = "Data will be lost if you leave the page, are you sure?";
  };

  // NEED TO FIX THIS;
  let isSetUserInfoLoading = false;

  let finalRender = [];
  // not being used right now but keep - used for dynamically changing securty from monitor
  if (userInfoFromReduxsecurity != undefined) {
    window.desktopApp &&
      window.downloadHelper.changeSecurity(userInfoFromReduxsecurity);
  }

  // TODO: move this also to ExperiencePageHeader
  // let engagementConfig = experienceConfigTakeFromRedux.engagement
  let progressBar = (
    <ProgressBar
      key="progress-bar"
      experienceTopics={experience.topics}
      questionsCount={experience.attemptable_questions_count}
      experienceId={experience.id}
      userId={currentUser.id}
    />
  );

  // let embeddedSubTitle = <span>
  //   <Tag>{experience.questions_count} questions</Tag>
  //   {experience.points && <Tag>{parseFloat(experience.points)} points</Tag>}
  //   {displayUserName}
  // </span>
  // let defaultSubTitle = <span className="title">{userName} <Tag>{sCode}</Tag></span>

  // let subTitle = embedded ? embeddedSubTitle : defaultSubTitle

  const experienceDuration = durationAfterReset ? durationAfterReset : experienceSettings.duration

  let timer =
    experienceSettings.is_timed && experienceSettings.duration > 0 ? (
      <span>
        {/* <CountDown key="experience-timer" date={Date.now() + convertMs(experienceSettings.duration)} /> */}
        <CountDownTimer
          key="experience-timer-component"
          duration={experienceDuration}
          experienceSettings={experienceSettings}
          extra_time={userInfoFromReduxextraTime}
          extra_time_offset={userInfoFromReduxextraTimeOffset}
          startedAt={userInfoFromReduxstartedAt}
          mobileUploadMode={userInfoFromReduxmobileUploadMode}
          currentView={userInfoFromReduxcurrentView}
          setUserInfo={setUserInfo}
          setIsTimeUp={setIsTimeUp}
          setSwitchFromStartToResumeView={setSwitchFromStartToResumeView}
          questionsCount={experience.attemptable_questions_count}
          setServerTimeCallback={setServerTimeCallback}
        />
      </span>
    ) : null;

  // let editModeSwitcher = experienceViewMode == "apPreviewTest" && EditModeButton  

  let endButton = (
    <Tooltip
      key="end-test-btn"
      title={
        experienceViewMode == "apPreviewTest"
          ? mrIntl("ExperienceTake.disabled_in_preview")
          : mrIntl("ExperienceTake.end_test")


      }
      placement="bottom"
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <Button
        type="primary"
        danger
        className="end-test-button"
        loading={endTestButtonLoading}
        icon={isMobile ? <SendOutlined /> : ""}
        onClick={() => {
          setEndTestButtonLoading(true);
          setSwitchFromStartToResumeView(false);
          
          let remainingTime = ""
          if(experienceSettings.is_timed) {
            const studentStartedAt = userInfoFromReduxstartedAt == undefined ? Date.now() : userInfoFromReduxstartedAt;
        
            try {
              const calculateRemainingTimeInMs = (studentStartedAt + userInfoFromReduxextraTimeOffset + userInfoFromReduxextraTime) + convertMs(experienceDuration)- Date.now()
          
              remainingTime = timeInMiliSeconds(calculateRemainingTimeInMs);
            } catch (error) {
              console.log("error in calculateRemainingTimeInMs", error);
              Sentry.captureException(error);
            }
          }
          
          setUserInfo(
            {
              currentView: "endTest",
              ended_at: Date.now(),
            },
            {
              log: {
                logging: true,
                action: "test_ended_by_student",
                current_value: {
                  remaining_time: remainingTime,
                }
              },
              successCallback: () => {
                setEndTestButtonLoading(false);
              },
              errorCallback: () => {
                setEndTestButtonLoading(false);
              },
            }
          );
          // Worst case scenario - when setUserInfo failing/no change of currentView for some reason, still remove loading so student can try again
          setTimeout(() => {
            setEndTestButtonLoading(false);
          }, 5000);
        }}
        disabled={experienceViewMode == "apPreviewTest"}
      >
        {isMobile ? "" : <MrTranslate id={"ExperienceTake.end_test"}/>}
      </Button>
    </Tooltip>
  );

  // const videoCallConfig =
  //   currentUser.role === "student"
  //     ? studentVideoCallConfig
  //     : teacherVideoCallConfig;
  // let uuid = experience.access_code;

  // let QuickCheckSubmitButton = <Tooltip title="Submit">
  //   <Button key="1" type="danger" onClick={() => submitResponses()}>Submit</Button>
  // </Tooltip>

  // let viewResourcesButton = <Tooltip title="Open resource sheet">
  //   <Button shape="circle" icon={<FileOutlined />}/>
  // </Tooltip>

  const OfflineExperienceAndNotAllowedOnlineWarning = () => {
    let subTitle = []
    let extra = []
    subTitle.push(
      <Alert 
        // message="This assessment can only be taken in the lockdown application" 
        message= {mrIntl("ExperienceTake.assessment_for_lockdown_app")}
        type="error"
        showIcon
      />
    )
    extra.push(
      <Space>
        <StartInSebBtn item={experience} />
        <DownloadAppsModal />
      </Space>
    );

    return <React.Fragment>
      <Result
        className="end-test-result"
        status="warning"
        // icon={<EditOutlined />}
        title={experience.name}
        subTitle={subTitle}
        extra={extra}
      />

    </React.Fragment>;
  }

  let changeLanguageButton = (
    <Tooltip title="Change input language" placement="bottom">
      <Button shape="circle" icon={<FontColorsOutlined />} />
    </Tooltip>
  );

  let quitBtn = (
    <Button
      danger
      key="quit"
      href={`${import.meta.env.VITE_WEB_URL}/seb_quit.html`}
    >
      Quit
    </Button>
  );

  let studentMainView = [];
  let totalCount;
  console.log("userinfo logs common ==> isFirebaseAuthenticated, userInfoFromReduxcurrentView", isFirebaseAuthenticated, userInfoFromReduxcurrentView);

  const InvalidTimeAlert = () => {
    let subTitle = []
    let extra = []
    subTitle.push(
      <Alert
        style={{ marginTop: "20px" }}
        message={<>
          Your system time is out of sync! fix the date and time on your system, <br/>
          <a
            href="https://drive.google.com/drive/u/2/folders/1FR5_R5ANMSMCAWTfCa27mqHX3GBb7kOw"
            target="_blank"
            rel="noopener noreferrer"
          >
            Please click this link to learn how to fix this issue.
          </a>
        </>}
        type="error"
        showIcon
      />
    )

    extra.push(<Button onClick={() => { window.location.reload() }}>Refresh</Button>)
    extra.push(<QuitBtn />)

    // useEffect(() => {
    //   setUserInfo({},
    //   {
    //     log: {
    //       logging: true,
    //       log_level: "warning",
    //       msg: `System time is out of sync`,
    //     },
    //   })
    // }, [])

    return <React.Fragment>
      <Result
        className="end-test-result"
        status="warning"
        // icon={<EditOutlined />}
        title={experience.name}
        subTitle={subTitle}
        extra={extra}
      />

    </React.Fragment>;
  }


  const SecurityInterrupted = () => {
    let subTitle = []
    let extra = []
    subTitle.push(
      <Alert
        key="security-interrupted-alert"
        style={{ marginTop: "20px", textAlign: "left" }}
        message={
          <>
            Lockdown security interrupted. This may occur due to unintentional
            key presses or system issues. <br />
            <br />
            Please follow these steps: <br />
            <br />
            1. Quit the application using the button below. <br />
            2. Inform your teacher about this interruption. <br />
            3. Restart the app and request permission from your teacher to
            resume this test. <br />
            <br />
            Remember, maintaining the integrity of the test is crucial. Thank
            you for helping us keep the test fair for everyone.
            <br />
          </>
        }
        type="error"
        //showIcon
      />
    );

    extra.push(<QuitBtn 
      // userInfo={{userInfo: {security_interrupted: false}}} 
      key="security-interrupted-quit-btn"
      withLog={true} 
      logMessage={"Student quit the test from the security interruption screen"} 
      />
    )


    // useEffect(() => {
    //   setUserInfo({},
    //   {
    //     log: {
    //       logging: true,
    //       log_level: "warning",
    //       msg: `System time is out of sync`,
    //     },
    //   })
    // }, [])

    return <React.Fragment>
      <Result
        className="end-test-result"
        status="warning"
        // icon={<EditOutlined />}
        title={experience.name}
        subTitle={subTitle}
        extra={extra}
      />

    </React.Fragment>;
  }

  // TODO: separate Header componenent till here

  // TODO: point to discuss: we show PageHeader for student only in startTest view right now - all other views - joined, end, resume, submit do not - whether to show or not from UI perspective. One case for this is the instructions modal/icon, video call for eg. Also timer and chat (not in header I know) or improve UI for all other views to handle all the content better - ESP FOR MOBILE

  // isFirebaseAuthenticated = false;
  ///////////////////////////////////////////////////////
  // NOTE: isFirebaseAuthenticated getting set when signInToFirestore, but isFirebaseTokenLoading getting set when getFirebaseToken called which is currently behaving like initExperienceTakeData so need to check both to render views - As some of the child components (question - cke_subjetive) having there own state which is getting rendered before getFirebaseToken gets data, one of the case is student refresh in exam
  ///////////////////////////////////////////////////////
  if (isFirebaseAuthenticated && !isFirebaseTokenLoading && userInfoFromReduxsessionId) {
    // start test
    let currentView = userInfoFromReduxcurrentView;
    // if(experience.attempts){  //add condition to compare to experienceSettings.attempt_no to support multiple attempts
    if (experience.experience_user){
      if(experienceType === "assessment") {
      //add condition to compare to experienceSettings.attempt_no to support multiple attempts
        currentView = "viewFeedback";
        if(experience.settings.assessment_type === "quick_check"){
          // don't override to viewFeedback basically to show view results button on Submit view
          currentView = userInfoFromReduxcurrentView;
        }
      }
      if(experienceType === "learning") {
        currentView = "startTest";
      }
    }
    if (experienceViewMode == "apPreviewTest") {
      currentView = "startTest";
      // since no FB for non students now, setting this as default
    }

    // const currentView = "startTest"
    // TODO: separate component
    let focusLostModal = (
      <FocusLostModal
        experienceProps={experienceProps}
        focusLostModalVisible={focusLostModalVisible}
        resumeModalVisible={resumeModalVisible}
        focusLostExplanation={focusLostExplanation}
        setFocusLostExplanation={setFocusLostExplanation}
        setFocusLostModalVisible={setFocusLostModalVisible}
        mainTestScreen={mainTestScreen}
        setResumeModalVisible={setResumeModalVisible}
      ></FocusLostModal>
    );

    if (userInfoFromReduxEnableLocalStorageSync) {
      currentView = "localStorageSync";
    }
    // let videoRender = uuid && (
    //   <>
    //     <VideoCall
    //       containerDimensions={{
    //         // height:
    //         //   currentView == "startTest" ? "0px" : isMobile ? "200px" : "300px",
    //         // width: currentView == "startTest" ? "0px" : "100%",
    //         height: "100%",
    //         width: "100%",
    //       }}
    //       config={{
    //         roomName: `AssessPrep-${uuid}`,
    //         // height: isMobile ? "200px" : "300px",
    //         width: "100%",
    //         height: "100%",
    //         // height: isMobile ? "300px" : "100%",
    //         // width: isMobile ? "400px" : "600px",
    //         // passwordRequired: true,
    //         userInfo: {
    //           email: currentUser.email,
    //           displayName: currentUser.name,
    //         },
    //         ...videoCallConfig,
    //       }}
    //     />
    //   </>
    // );

    const sessionModalConfig = {
      embedded,
      experienceViewMode,
      setUserInfo,
    };

    // currentView = "localStorageSync";
    switch (currentView) {
      // TODO: separate components for each view
      case "joined":
        let joinViewConfig = {
          experienceProps: experienceProps,
          // videoRender: videoRender,
          showStudentCodeAckModal: showStudentCodeAckModal,
          setSwitchFromStartToResumeView: setSwitchFromStartToResumeView,
          setShowStudentCodeAckModal: setShowStudentCodeAckModal,
          experienceDuration: experienceDuration
        };
        finalRender.push(<JoinViewOld {...joinViewConfig} />);
        break;

      case "startTest":
        let startViewConfig = {
          experienceProps: experienceProps,
          timer: timer,
          sessionModalConfig: sessionModalConfig,
          showQRModal: showQRModal,
          progressBar: progressBar,
          endButton: endButton,
          focusLostModal: focusLostModal,
          // videoRender: videoRender,
          mobileUploadMode: mobileUploadMode,
          setShowQRModal: setShowQRModal,
          experienceDuration: experienceDuration
        };
        finalRender.push(<StartViewOld {...startViewConfig}></StartViewOld>);

        break;
      case "endTest":
        let endViewConfig = {
          experienceProps: experienceProps,
          timer: timer,
          progressBar: progressBar,
          isSetUserInfoLoading: isSetUserInfoLoading,
          focusLostModal: focusLostModal,
          // videoRender: videoRender,
          sessionModalConfig: sessionModalConfig,
          isTimeUp: isTimeUp,
          backToTestButtonLoading: backToTestButtonLoading,
          setShowQRModal: setShowQRModal,
          setEndTestButtonLoading: setEndTestButtonLoading,
          setSwitchFromStartToResumeView: setSwitchFromStartToResumeView,
          setBackToTestButtonLoading: setBackToTestButtonLoading,
          experienceDuration: experienceDuration
        };
        finalRender.push(<EndViewOld {...endViewConfig} />);
        // finalRender.push("shikhar);
        break;

      case "resumeTest":
        let resumeViewConfig = {
          experienceProps: experienceProps,
          timer: timer,
          progressBar: progressBar,
          endButton: endButton,
          // videoRender: videoRender,
          isTimeUp: isTimeUp,
          setIsTimeUp: setIsTimeUp,
          setSwitchFromStartToResumeView: setSwitchFromStartToResumeView,
          experienceDuration: experienceDuration
        };
        finalRender.push(<ResumeViewOld {...resumeViewConfig}></ResumeViewOld>);
        break;

      case "submittedTest": // fetch this from experience_users from rails
        let submitViewConfig = {
          experienceProps: experienceProps,
          // videoRender: videoRender,
          experienceDuration: experienceDuration
        };
        finalRender.push(<SubmitViewOld {...submitViewConfig}></SubmitViewOld>);
        break;

      case "viewFeedback":
        let resultViewConfig = {
          experienceProps: experienceProps,
          experienceDuration: experienceDuration,
          fromUrl: fromUrl,
          fromResourceName: fromResourceName
        };

        finalRender.push(<ResultViewOld {...resultViewConfig}></ResultViewOld>);
        break;

      case "localStorageSync":
        let localStorageSyncViewConfig = {
          experience: experience,
        };

        finalRender.push(<LocalStorageSyncView {...localStorageSyncViewConfig} />)
        break;

      default:
        finalRender.push(mrIntl("ExperienceTake.something_went_wrong"));
        break;
    }

    if (currentView !== "viewFeedback" &&
      (experienceViewMode === "apTakeTest" || (experienceViewMode === "apPreviewTest" && !experienceSettings.mode === "paper")) &&
      !(isMobile && mobileUploadMode)
    ) {
      finalRender.push(
        <StudentFloatingTools setUserInfo={setUserInfo} experience={experience} experienceSettings={experienceSettings} />
      );
    }

    let takeSecurityScreenshots = (enabledFeatures.auto_screenshots && experienceSettings && experienceSettings.enable_auto_screenshots && currentUser.role === "student" && !embedded && experienceViewMode !== "apPreviewTest" && currentView !== "viewFeedback" && !(mobileUploadMode && isMobile)) ? true : false

    if(takeSecurityScreenshots){
      finalRender.push(<TakeScreenshots experienceId={experience.id} />)
    }

    // APL-3187 - keep this at the end since overridding finalRender
    if(currentView && currentView !== "joined" && currentView !== "viewFeedback" && isOfflineExperienceAndNotAllowedOnline(experience, appType) && experienceViewMode !== "apPreviewTest" && !(mobileUploadMode && isMobile)){
      finalRender = <OfflineExperienceAndNotAllowedOnlineWarning />
    }

    if (currentTimeValidWrtServerTime(serverTimeOffset) === false) {
      finalRender = <InvalidTimeAlert />
    }

    // let shouldShowSecurityInterruptedView =
    //   changeSecurityStatus !== "true" &&
    //   shouldSecurityInterruptedEventCheck &&
    //   currentView !== "viewFeedback"; //changeSecurityStatus  => can be undefined or "false"

    // if (shouldShowSecurityInterruptedView) {
    //   finalRender = <SecurityInterrupted key="security-interrupted" />;
    // }
  } else {
    if (isFirebaseTokenLoading) {
      finalRender.push(<Spinner />);
    }
  }

  let finalRenderWithFirestore = finalRender;

  if (mobileUploadMode != null) {
    finalRenderWithFirestore = (
      <>
        {finalRender}
        {/* Initializes firestore, gets token, sets initial values */}
        <FirestoreInteractions
          experience={experience}
          mobileUploadMode={mobileUploadMode}
        />
      </>
    );
  }

  if (isInvalidSEBConfig && false) {
    // history.push("/error")
    return <Redirect to="/error" />;
  }

  if (
    isDynamicLockdownConfigForMacApp &&
    !isNewAppVersion(currentSystemAppVersion, "10.2.0")
  ) {
    //if mac application !isNewAppVersion(currentSystemAppVersion, "10.2.0") , move to listing page instad of expereince take
    //because  showQuitAlertOnNativeSide() => showQuitAlert function ; not supported earlier version

    return <Redirect to="/u/tests" />;
  }

  return finalRenderWithFirestore;
};

export default ExperienceTakeOld;
