import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Badge } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import query from "query-string";
import { isEmpty } from "lodash";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import "./ModalFilters.scss";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ModalFilters = ({
  filters,
  applyLocalFilters,
  setParams,
  setLocalFilters,
}) => {
  const mrIntl = useTranslate()
  const [filterApplied, setFilterApplied] = useState(false);
  const filterCheck = query.parse(window.location.search, { arrayFormat: 'bracket' });
  useEffect(() => {
    if (isEmpty(filterCheck)) {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
    }
  }, [filterCheck]);
  const newFilters = [];
  filters &&
    filters.map((filter) => {
      newFilters.push(<Col span={24}>{filter}</Col>);
    });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    applyLocalFilters();
  };

  const handleCancel = () => {
    !filterApplied && setLocalFilters({});
    setIsModalVisible(false);
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Button
            type="default"
            icon={
              <Badge dot={filterApplied}>
                <FilterOutlined />
              </Badge>
            }
            onClick={showModal}
          />
        </Col>
        {filterApplied && (
          <Col span={12}>
            <div onClick={() => setLocalFilters({})}>
              <ResetFilter setParams={setParams} />
            </div>
          </Col>
        )}
      </Row>
      <Modal
        width={"100%"}
        className="mobile-filters"
        title={mrIntl("ModalFilters.filter_assessments")}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={mrIntl("ModalFilters.apply")}
      >
        <Row gutter={[8, 15]}>{newFilters}</Row>
      </Modal>
    </>
  );
};

export default ModalFilters;
