import React, { useEffect, useState } from "react";

import { Button, Radio } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { getUniqueId, getAlphabetAtIndex } from '/src/lib/utils/helperMethods';
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { getItemsOfSize, addItemToArray, removeItemFromArray, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { AddButton, DeleteButton, InputArea, Segment, SegmentData, MCQList, ShowTextContainer, TextArea, ShowText, ShowTextIndex, ShowTextContent, ShowTextFile, CustomErrorMessage, AddFeedbackButton, RemoveFeedbackButton } from '/src/components/UI/Segment/UIHelper'
import { cloneDeep, shuffle } from 'lodash';
import SegmentItemFooter from '/src/views/Segments/SegmentItemDetail/SegmentItemFooter'
import SegmentItemHeader from '/src/views/Segments/SegmentItemDetail/SegmentItemHeader'
import MediaHoverButton from "/src/components/UI/Media/MediaHoverButton"
import MediaShow from "/src/components/UI/Media/MediaShow"
import OptionFeedback from "/src/views/Segments/Explanation/OptionFeedback";
import { useSelector } from "react-redux";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { useDispatch } from "react-redux";
import { getEmbeddedSelector, partnerNameSelector } from "/src/views/Auth/Login/selector";
import { actions as firestoreInteractionActions} from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { CKETextOptionTools } from "/src/components/UI/CKEditor/CKEConfig";


const MCQSingle = props => {
	console.log("MCQ Single props", props);
	const { question_type, showCorrectAnswerSwitch, segmentSettings, triggerStateUpdate, setRenderMath, segmentFooterAttributes } = props
	let { parsedJSONObject, createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)

	const dispatch = useDispatch();
	const partnerName = useSelector(partnerNameSelector())
	const embedded = useSelector(getEmbeddedSelector())
  const mrIntl = useTranslate();

	let textAnswerString = props.text_answer || ''
	const propsAttemptStatus = props.attempt_status || {}
	let attemptStatusObject = {
		reset_count: propsAttemptStatus.reset_count || 0,
		json_attempted: propsAttemptStatus.json_attempted || false,
	}

	const extra = {
		contentRequired: false,
		contentPrefix: 'Option',
		placeholderPrefix: mrIntl("MCQSingle.option"),
	}
	if (createMode) {
		parsedJSONObject = {
			segment_data: {
				options: getItemsOfSize(0, 2, extra)
			},
			segment_version: '1.0.1'
		}
		if (question_type == 'mcq_single') {
			parsedJSONObject = props.value || parsedJSONObject
		}
	}

	if (answerMode && !props.response_json) {
		parsedJSONObject = shuffleItems(parsedJSONObject)
	}

	if (autoCheckMode) {
		let question_json = cloneDeep(props.teacher_json)
		let answer_json = cloneDeep(parsedJSONObject)
		parsedJSONObject = checkResponses(question_json, answer_json)
	}

	const [state, setState] = useState(parsedJSONObject)
	const [textAnswer, setTextAnswer] = useState(textAnswerString)
	const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject)
	const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
	const [stateSetting, setStateSetting] = useState({
		autoCheckMode: autoCheckMode,
		quickCheckModeEnabled: false,
		showCorrectAnswerSwitch: showCorrectAnswerSwitch,
		showCorrectAnswerSwitchValue: false,
	})
	const [myAnswer, setMyAnswer] = useState(state)
	const screenSettings = props.screenSettings || {}

	let currentJSON, text_answer, attempt_status
	if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
		currentJSON = parsedJSONObject
		text_answer = textAnswerString
		attempt_status = attemptStatusObject
	} else {
		currentJSON = state
		text_answer = textAnswer
		attempt_status = attemptStatus
	}

	const segment_data = currentJSON?.segment_data || {}
	const options = segment_data.options ? segment_data.options : []

	console.log("MCQ Single options", options, state)
	useEffect(() => {
		if (setRenderMath) {
			setRenderMath(getUniqueId())
		}
	}, [])

	useEffect(() => {
		if (createMode) {
			if (props.onChange) {
				let teacher_json = cloneDeep(state)
				props.onChange(teacher_json)
			}
			if (props.onSubmit) {
				// let teacher_json = cloneDeep(state)
				// props.onSubmit(teacher_json)
				console.log("On submit is available now", props.onSubmit)
			}
		} else if (answerMode && stateUpdatedCount > 0) {
			if (props.onChange) {
				let result = {
					response_json: cloneDeep(state),
					// text_answer: cloneDeep(textAnswer),
					attempt_status: cloneDeep(attemptStatus)
				}

				props.onChange(result)
			}
		}
	}, [stateUpdatedCount]);

	const saveState = (state) => {
		setState(state)
		setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
		console.log("stateUpdated count ==>", stateUpdatedCount)
	}

	const saveTextAnswer = (data) => {
		setTextAnswer(data)
		setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
	}

	function checkResponses(question_json, answer_json) {
		console.log('Question and Answer JSON ==>', question_json, answer_json)
		if (!question_json || !answer_json) {
			return
		}
		let q_segment_data = question_json.segment_data || {}
		let a_segment_data = answer_json.segment_data || {}

		let q_options = q_segment_data.options || []
		let a_options = a_segment_data.options || []

		let correct_status = { all_correct: false }
		if (a_options) {
			let i = 0;
			while (i < q_options.length) {
				let q_option = q_options[i]
				let a_option = a_options.find((option) => option.id == q_option.id) || {}
				
				if (q_option.checked && a_option.checked) {
					a_option.correct = true
					correct_status.all_correct = true
				}

				if(partnerName === "osc" && q_option && q_option.checked){
					a_option.correct = true
				}

				i++
			}
		}

		if (!autoCheckMode && props.onAutoCheck) {
			props.onAutoCheck(correct_status)
		}

		if (partnerName === "osc" && embedded){
			// postMessage to parent iframe to let osc know if correct or not when student attempts
			window.parent.postMessage({correct: correct_status.all_correct}, "*");
		}

		return answer_json
	}

	function shuffleItems(json) {
		if (segmentSettings.shuffle) {
			const segment_data = json.segment_data
			segment_data.options = shuffle(segment_data.options)
		}

		return json
	}

	const addItem = () => {
		const newOptions = addItemToArray(options, extra);

		const newSegmentData = {
			...segment_data,
			options: newOptions
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const removeItem = (index) => {
		const newOptions = removeItemFromArray(options, index);

		const newSegmentData = {
			...segment_data,
			options: newOptions
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const removeFile = (index) => {
		const newOptions = options
		delete newOptions[index].files

		const newSegmentData = {
			...segment_data,
			options: newOptions
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const selectCurrentItem = (index) => {
		if (options[index].checked) {
			return
		}
		
		let isAtleastOneSelected = false
		const newOptions = options.map((item, i) => {
			if (i == index) {
				item.checked = true
			} else {
				item.checked = false
			}
			if (item.checked) {
				isAtleastOneSelected = true
			}
			return item
		})

		const newSegmentData = {
			...segment_data,
			options: newOptions
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		if (answerMode) {
			if (isAtleastOneSelected != attemptStatus.json_attempted) {
				setAttemptStatus({
					...attemptStatus,
					json_attempted: isAtleastOneSelected
				})
			}

			if (partnerName === "osc") {
				setStateSetting({
					...stateSetting,
					allowCheckAnswer: true,
				});
			}
		} 

		saveState(newState)
	}

	const onTextChanged = (index, data, key = 'content') => {
		const newOptions = options
		newOptions[index][key] = data

		const newSegmentData = {
			...segment_data,
			options: newOptions
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const onSaveMedia = (index, files) => {
		const newOptions = options
		newOptions[index].files = files

		const newSegmentData = {
			...segment_data,
			options: newOptions
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

	const showCustomFeedback = (index) => {
		const newOptions = options

		newOptions[index].show_feedback = !(newOptions[index].show_feedback || newOptions[index].custom_feedback)
		newOptions[index].custom_feedback = ''

		const newSegmentData = {
			...segment_data,
			options: newOptions
		}

		const newState = {
			...state,
			segment_data: newSegmentData
		}

		saveState(newState)
	}

  const checkAnswer = (check = false) => {
    if (check) {
      let question_json = cloneDeep(props.teacher_json);
      let answer_json = cloneDeep(state);
      const newState = checkResponses(question_json, answer_json);

			if(partnerName === "osc"){
				// To make sure only sending to BE on checkAnswer click, not on answer select and try again
				console.log("updating userResponseConfig")
				dispatch(firestoreInteractionActions.setUserResponseConfigSuccess({ userResponseConfig: {saveToAPDB: true} }))
			}
      setMyAnswer(state);
      saveState(newState);
    }

		let doNotShowExplanation = false
		if(partnerName === "osc"){
			doNotShowExplanation = true
		}

		// if(partnerName !== "osc"){
			setStateSetting({
				...stateSetting,
				quickCheckModeEnabled: check,
				doNotShowExplanation: doNotShowExplanation,
				autoCheckMode: true,
			});
		// }


    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

	const showCorrectAnswer = (value) => {
		let question_json, answer_json
		if (value) {
			question_json = cloneDeep(props.teacher_json)
			answer_json = cloneDeep(props.teacher_json)
		} else {
			question_json = cloneDeep(props.teacher_json)
			answer_json = cloneDeep(myAnswer)
		}

		const newState = checkResponses(question_json, answer_json)
		saveState(newState)
		setStateSetting({
			...stateSetting,
			quickCheckModeEnabled: true,
			showCorrectAnswerSwitchValue: value
		})
		if (setRenderMath) {
			setRenderMath(getUniqueId())
		}
	}

	const resetQuestion = () => {
		let student_json = cloneDeep(props.student_json)
		const newState = shuffleItems(student_json)

		if(partnerName === "osc"){
			// To make sure only sending to BE on checkAnswer click, not on answer select and try again
			console.log("updating userResponseConfig")
			dispatch(firestoreInteractionActions.setUserResponseConfigSuccess({ userResponseConfig: {saveToAPDB: false} }))
		}

		setAttemptStatus({
			...attemptStatus,
			reset_count: (attemptStatus.reset_count + 1),
			json_attempted: false,
		})
		saveState(newState)
		if(partnerName === "osc"){
			setStateSetting({
				...stateSetting,
				allowCheckAnswer: false,
				quickCheckModeEnabled: false,
				doNotShowExplanation: false,
				autoCheckMode: false,
			})
		}else{
			setStateSetting({
				...stateSetting,
				quickCheckModeEnabled: false,
			})
		}
		if (setRenderMath) {
			setRenderMath(getUniqueId())
		}
		if (props.onTryAgain) {
			props.onTryAgain()
		}
	}

	const getMCQOption = (item, index) => {
		let mcqOption

		console.log("getMCQOption item", item)

		if (createMode) {
			const valid = item.content || (item.files && item.files.length)
			mcqOption =
				<TextArea
					key={`text-area-${item.id}`}
				>
					<Radio
						key={`radio-${item.id}`}
						checked={item.checked}
						onClick={(e) => selectCurrentItem(index)}
					>
					</Radio>
					<InputArea
						className={`singleline-input-area ${!valid && 'custom-error'}`}
					>
						<BasicCKE
							data={item.content}
              tools={CKETextOptionTools}
							placeholder={item.placeholder}
							onTextChanged={(data) => onTextChanged(index, data, 'content')}
						></BasicCKE>
						{item.files && <MediaShow
							file={item.files && item.files[0]}
							removeFile={(e) => removeFile(index)}
						>
						</MediaShow>}
						<CustomErrorMessage className="error-message" message={mrIntl("CommonText.required_msg")}></CustomErrorMessage>
					</InputArea>
					<MediaHoverButton
						onSaveMedia={(files) => onSaveMedia(index, files)}
					></MediaHoverButton>
					{!(item.show_feedback || item.custom_feedback) &&
						<AddFeedbackButton
							clicked={() => showCustomFeedback(index)}
						>
						</AddFeedbackButton>
					}
					<DeleteButton
						clicked={(e) => removeItem(index)}
            disabled={options.length <= 2}
            tooltipTitle={options.length <= 2 ? mrIntl("CommonText.minimum_limit_reached") : ""}
					></DeleteButton>
				</TextArea>
		} else {
			let className = 'answer-choice'
			if(answerMode && !stateSetting.quickCheckModeEnabled){
				className += ' answers-selectable'	
			}
			if (item.checked) {
				className += ' attempted-answer'
				// presentationMode ? className += ' correct-answer' : className += ' attempted-answer'
				if (autoCheckMode || stateSetting.quickCheckModeEnabled) {
					if (item.correct) {
						className += ' correct-answer'
					} else {
						className += ' incorrect-answer'
					}
				}
			}

			let bgClassName = ""
			if(partnerName === "osc"){
				const bgClassNames = ["bg-dark-blue", "bg-pink", "bg-orange", "bg-green"]
				bgClassName = bgClassNames[index]
				if (autoCheckMode || stateSetting.quickCheckModeEnabled) {
					if (item.correct) {
						className += ' correct-answer'
					}
				}
			}

			mcqOption =
				<ShowTextContainer
					key={`show-text-container-${item.id}`}
					onClick={(e) => (answerMode && !stateSetting.quickCheckModeEnabled) && selectCurrentItem(index)}
					className={className}
				>
					<ShowTextIndex style={{width: "auto"}}><span className={partnerName === "osc" ? `mcq-option-index-circled ${bgClassName}` : ""}>{getAlphabetAtIndex(index)}</span></ShowTextIndex>
					<ShowText >
						{item.content && <RenderHtml text={item.content}
						>
						</RenderHtml>}
						{item.files && <ShowTextFile>
							<MediaShow
								file={item.files && item.files[0]}
								removeIcon={false}
                showZoomButton={true}
							>
							</MediaShow>
						</ShowTextFile>}
					</ShowText>
					{(partnerName === "osc" && (autoCheckMode || stateSetting.quickCheckModeEnabled)) ? 
						item.correct ? <CheckCircleFilled style={{color: "limegreen", position: "absolute",
						right: "5px"}} /> : (item.checked && !item.correct) ? <CloseCircleFilled style={{color: "red", position: "absolute",
						right: "5px"}} /> : ""
					:
						""
					}
				</ShowTextContainer>
		}

		return mcqOption
	}

	const toggleAnswer = () => {
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: !stateSetting.quickCheckModeEnabled,
			doNotShowExplanation: !stateSetting.doNotShowExplanation
    })
  }

	console.log('MCQ Single State before render ==>', state, stateSetting)
	return (
    <Segment className="segment-mcq-single">
      {/* <SegmentItemHeader
				segmentStateSettings={stateSetting}
				showCorrectAnswer={(value) => showCorrectAnswer(value)}
			>
			</SegmentItemHeader> */}
      <SegmentData>
        <MCQList
          style={
            screenSettings.isMobile
              ? {
                  width: "100%",
                }
              : {
                  width: "100%",
                  maxWidth: "500px",
                }
          }
        >
          {options.map((item, index) => (
            <>
              {getMCQOption(item, index)}
              <OptionFeedback
                {...props}
                option={item}
                quickCheckModeEnabled={stateSetting.quickCheckModeEnabled}
                onTextChanged={(data) =>
                  onTextChanged(index, data, "custom_feedback")
                }
                removeFeedbackButton={
                  <RemoveFeedbackButton
                    clicked={() => showCustomFeedback(index)}
                  ></RemoveFeedbackButton>
                }
              ></OptionFeedback>
            </>
          ))}
          {createMode && <AddButton clicked={(e) => addItem()} />}
        </MCQList>
      </SegmentData>

      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        saveTextAnswer={saveTextAnswer}
        // resetQuestion={partnerName === "osc" ? null : resetQuestion}
        resetQuestion={resetQuestion}
        checkAnswer={checkAnswer}
        showCorrectAnswer={partnerName === "osc" ? null : showCorrectAnswer}
        toggleAnswer={partnerName === "osc" ? toggleAnswer : null}
        questionType={partnerName === "osc" ? "mcq_single" : null}
      ></SegmentItemFooter>
    </Segment>
  );
}

export default MCQSingle;