import React from "react";
import { MrCrudView, MrSelect ,FormRenderer} from "mr_react_framework";
// import {
//   appConstantsSelector,
//   loadingSelector,
//   errorSelector,
//   paginationSelector
// } from "./selector";
import { actions } from "./redux";
import AppConstantListItem from "./AppConstantListItem/AppConstantListItem";
// import AppConstantFilter from "./AppConstantFilter/AppConstantFilter";
// import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import commonSelectors from "../../App/commonSelectors";
import AppConstantsList from "./AppConstantsList";
import MrTranslate, { mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate";

// import MrReduxCrud from '../../lib/utils/MrReduxCrud/MrReduxCrud';
// const keyName = (<FormattedMessage
//   id="appConstantsForm.keyName"
//   defaultMessage="French Key Name"
// />);
// defineMessages({
//   keyName: {
//     id: "appConstantsForm.keyName",
//     defaultMessage: "Key Name"
//   },
//   desc: {
//     id: "appConstantsForm.desc",
//     defaultMessage: "Desc"
//   }
// });

const SelectValue = MrSelect({
  config: {
    processData: (data, props) => {
      return data.map((item) => {
        console.log("class selector item", item);
        return {label: item.label, value: item.value};
      });
    },
    searchApi: false,
    widgetConfig: {
      options: [
        {label: "String", value: "string"}, // incomplete grading
        {label: "Int", value: "int"},
        {label: "Text", value: "text"},
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Value",
      // style: { width: "120px" },
      allowClear: true
    },
  }
})

const ValueTypeComponent = ({getFieldValue, sourceValues, formInstance}) => {
  const valueType = getFieldValue(["value_type"]);
  const mrIntl = useTranslate()
  console.log('value type==>', valueType);
  let finalRender = null;
  if (valueType === 'string') {
    finalRender = <FormRenderer
        formObj={{
          type: "object",
          properties: {
            string_value: {
              title: "Select String Value",
              type: "string",
              span: 24,
              // setInitialValue: true,
              // widget: SelectGradeWidget,
              // widgetConfig: {
              //   disabled: sourceValues.id ? true : false
              // },
            },
          }
        }}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
  } else if (valueType === 'text') {
    finalRender = <FormRenderer
        formObj={{
          type: "object",
          properties: {
            string_value: {
              title: "Select Text Value",
              type: "string",
              span: 24,
              // setInitialValue: true,
              // widget: SelectGradeWidget,
              // widgetConfig: {
              //   disabled: sourceValues.id ? true : false
              // },
            },
          }
        }}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
  } else if (valueType === 'int') {
    finalRender = <FormRenderer
        formObj={{
          type: "object",
          properties: {
            string_value: {
              title: "Select Int Value",
              type: "string",
              span: 24,
              // setInitialValue: true,
              // widget: SelectGradeWidget,
              // widgetConfig: {
              //   disabled: sourceValues.id ? true : false
              // },
            },
          }
        }}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
  }
  return finalRender;
}

const returnAppConstantsForm = (props = {}) => {
  const { mrIntl, permission, currentUser } = props;
  // console.log("intl", intl);
  // const keyName = intl.formatMessage({
  //   id: "appConstantsForm.keyName"
  // });
  // const desc = intl.formatMessage({
  //   id: "appConstantsForm.desc"
  // });

  // const keyName = 'defaultKey'

  let appConstantsForm = {
    schema: {
      type: "object",
      required: ["key", "key_type"],
      properties: {
        key: {
          type: "string",
          default: "",
          // label: 'Key',
          label: "Key",
          span: 12,
          placeholder: "Key name"
          // required: true
        },
        key_type: {
          type: "string",
          span: 12,
          default: "",
          // label: 'Key Type',
          // label: 'Key Type',
          label: "Key Type",
          placeholder: "Key Type"
        },
        desc: {
          type: "string",
          default: "",
          label: mrIntl("CommonText.description"),
          placeholder: mrIntl("CommonText.description"),
        },
        value_type: {
          type: "string",
          span: 12,
          default: "",
          // label: "Select Value Type",
          label: "Select Value Type",
          widget: SelectValue,
          placeholder: "Select Value"
        },
        selected_value: {
          type: "string",
          label: "Text Value",
          span: 12,
          dependsOn: ["value_type"],
          children: ValueTypeComponent
          // default: "Text Value"
        },
        
      }
    },

  };
  return appConstantsForm;
};
// const AppConstants = () => {
//   const intl = useIntl();
//   const keyName = intl.formatMessage({
//     id: "appConstantsForm.keyName",
//     defaultMessage: "Key Name"
//   });
// };


const AppConstants = MrCrudView({
  resourceName: "appConstants",
  singleResourceName: "app_constant",
  displayName: "App Constants",
  resourceUrl: "/app_constants",
  isRoute: true,
  selectorsObj: {
    ...commonSelectors
  },
  forms: [{ name: "appConstants", form: returnAppConstantsForm }],
  // filter: AppConstantFilter,
  actions,
  actionNames: ["fetch", "create", "update", "delete", "show"],
  // firstTimeFetchParams: { with_users: true },
  config : {
    itemPreview: {
      widget: (props) => {
        console.log( "itemDetail props", props );
        return <span><MrTranslate id={"CommonText.item_detail"}/> {JSON.stringify(props.item)}</span>
      },
    },
    itemDetail: {
      widget: AppConstantListItem,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      widget: (props) => {
        return <span>Filter</span>;
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      widget: AppConstantsList,
      // widget: (props) => {
      //   return <span>List Component</span>;
      //   // Custom index view here - table/etc. comment out widget if you want itemPreview to be used
      // }
    },
  },
  
});
// return appConstants;

export default AppConstants;
