import React from "react";
import { List, Avatar, Space, Tooltip, Tag } from "antd";
import dayjs from "dayjs";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";

const SyllabusContentsList = (props) => {
  const { dataSource, currentUser, permission, deleted } = props;

  const Title = ({ item }) => item.position + " - " + item.name;

  const Description = ({ item }) => {
    const isAtlasSynced = item.uuid.indexOf("atlas") > -1
    let description = <Space direction={"vertical"}>
      <Space className="description">
        {isAtlasSynced && <Tooltip title="Atlas synced">
          <Tag color="blue">Atlas</Tag>
        </Tooltip>}
        <Tooltip title="Created">
          {<span>{dayjs(item.created_at).format("MMM DD, YYYY")}</span>}
        </Tooltip>
        <Tooltip title="Programme">
          <span>{item.programme && item.programme.name}</span>
        </Tooltip>
        <Tooltip title="Programme subject">
          <span>{item.programme_subject && item.programme_subject.label}</span>
        </Tooltip>
        <Tooltip title="Programme grade">
          <span>{item.programme_grade && item.programme_grade.label}</span>
        </Tooltip>
        <Tooltip title="Type">
          <span>{item.type_c}</span>
        </Tooltip>
      </Space>
      <ol>
        {item.children.map((a, i) => {
          let toRender = []
          toRender.push(<li>{a.name}</li>)
          a.children.length > 0 && 
          toRender.push(<ol>
            {a.children.map((b, i) => {
              let toRender1 = []
              toRender1.push(<li>{b.name}</li>)
              b.children.length > 0 && 
              toRender1.push(<ol>
                {b.children.map((c, i) => {
                  return <li>{c.name}</li>
                })}
              </ol>)
              return toRender1;
            })}
          </ol>)
          return toRender;
        })}
      </ol>
    </Space>

    return description
  };

  const ProfileIcon = ({ item }) => (
    <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
      {item.abbr_display}
    </Avatar>
  );

  return (
    <>
      <List
        className="custom-main-list"
        // loading={initLoading}
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(item, i) => {
          const isAtlasSynced = item.uuid.indexOf("atlas") > -1
          const canEdit = !isAtlasSynced
          return <CustomListItem
            type="programme"
            item={item}
            title={<Title item={item} />}
            description={<Description item={item} />}
            avatar={<ProfileIcon item={item} />}
            currentUser={currentUser}
            edit={permission.tag && permission.tag.update && canEdit}
            deleted={deleted}
            deletedAllow={false}
            permission={permission}
            show={item.type_c == "content_area"}
          />
        }}
      />
    </>
  );
};

export default SyllabusContentsList;
