// Adding 'id' field to support saving in Redux. This field is removed before sending data to the backend.
import { buildColumnsForRBDInline, buildOptionsForFillType } from "./generateAIHelperMethods";
import { getUniqueId } from "/src/lib/utils/helperMethods";

export const mcqObject = ({
  experienceId,
  userId,
  marks,
  destinationPosition,
  questionData,
  questionType,
  rubricType,
  criteria,
  strands,
}) => {

  const answer = Array.isArray(questionData?.answer) ? questionData.answer : [questionData.answer];
  const teacherOptions = [];
  const studentOptions = [];
  questionData.options.forEach((option, index) => {
    const id = getUniqueId(); 
    const optionObject = {
      checked : answer.includes(option),
      content: option,
      placeholder: `Option ${index + 1}`,
      id,
    }
    teacherOptions.push(optionObject)
    studentOptions.push({...optionObject, checked: false})
  });

  let questionObject = {
    segment_type: "question",
    experience_id: experienceId,
    user_id: userId,
    position: destinationPosition,
    custom_fields: "{}",
    parent_id: null,
    id: getUniqueId(10),                  // Adding 'id' field to support saving in Redux. This field is removed before sending data to the backend.
    question_segment_attributes: {
      content: questionData.question,
      teacher_json: {
        segment_data: {
          options: teacherOptions,
        },
      },
      student_json: {
        segment_data: {
          options: studentOptions,
        },
      },
      question_type: questionType,
    },
  };
  if (rubricType === "points") {
    questionObject.points = marks;
  } else if (
    rubricType === "myp_achievement_level" ||
    rubricType === "criteria_with_points"
  ) {
    questionObject.criterium_associations_attributes =
      getCriteriumAssociationsAttributes(criteria, strands);
  }
  return questionObject;
};

export const longAnswerObject = ({
  experienceId,
  userId,
  marks,
  destinationPosition,
  questionData, 
  rubricType,
  criteria,
  strands,
  parentId = null,
}) => {
  let questionObject = {
    parent_id: parentId,
    segment_type: "question",
    experience_id: experienceId,
    user_id: userId,
    position: destinationPosition,
    custom_fields: "{}",
    id: getUniqueId(10),              // Adding 'id' field to support saving in Redux. This field is removed before sending data to the backend.
    question_segment_attributes: {
      content: questionData.question,
      question_type: "cke_subjective",
    },
  };

  if(rubricType === "points") {
    questionObject.points = marks
  } else if (rubricType === "myp_achievement_level" || rubricType === "criteria_with_points") {
    questionObject.criterium_associations_attributes = getCriteriumAssociationsAttributes(criteria, strands)
  }
  return questionObject;
};

export const nestedQuestionObject = ({
  experienceId,
  userId,
  marks,
  destinationPosition,
  questionData,
  questionType,
  rubricType,
  criteria,
  strands
}) => {
  // let customPropSegments = [];
  const id = getUniqueId(10);

  const finalObject = [];
  // let children = [];
  let questionObject = {
    id: id,
    segment_type: "question",
    experience_id: experienceId,
    user_id: userId,
    position: destinationPosition,
    parent_id: null,
    // children_order: children,
    // customPropSegments: customPropSegments,
    custom_fields: "{}",
    settings: {},
    question_segment_attributes: {
      content: questionData.question,
      question_type: "group",
    },
  };
  if (rubricType === "points") {
    questionObject.points = marks;
  } else if (
    rubricType === "myp_achievement_level" ||
    rubricType === "criteria_with_points"
  ) {
    questionObject.criterium_associations_attributes =
      getCriteriumAssociationsAttributes(criteria, strands);
  }
  finalObject.push(questionObject);
  if (questionData.sub_parts && questionData.sub_parts.length > 0) {
    questionData.sub_parts.forEach((element, index) => {
      finalObject.push({
        ...longAnswerObject({
          experienceId,
          userId,
          marks: element.points,
          destinationPosition,
          questionData:element,
          rubricType,
          criteria,
          strands,
          parentId: id,
        }),
      });
    });
  }
  return finalObject;
};

//  Using nestedQuestionObject for task
// export const taskQuestionObject = (experienceId, currentUserId, marks, destinationPosition, questionData, questionType, activeTopicId) => {
//   let children = [];
//   let customPropSegments = [];
//   if (questionData.sub_parts && questionData.sub_parts.length > 0) {
//     questionData.sub_parts.forEach((element, index) => {
//       customPropSegments.push({
//         ...longAnswerObject(experienceId, currentUserId, marks, destinationPosition, element, false),
//         generate_with_ai: questionType !== "task" ? true : false
//       });
//     });
//   }
//   return {
//     segment_type: "question",
//     experience_id: experienceId,
//     user_id: currentUserId,
//     points: marks,
//     position: destinationPosition,
//     children_order: children,
//     parent_id: activeTopicId,
//     customPropSegments: customPropSegments,
//     custom_fields: "{}",
//     settings: {},
//     question_segment_attributes: {
//       content: questionData.question,
//       question_type: "group",
//     },
//   }
// }

export const trueFalseObject = ({
  experienceId,
  userId,
  marks,
  destinationPosition,
  questionData,
  rubricType,
  criteria,
  strands,
}) => {

  const teacherStatements = [];
  const studentStatements = [];
  questionData.statements.forEach((option, index) => {
    const id = getUniqueId(); 
    const statementObject = {
      statementIs : option.answer,
      content: option.statement,
      placeholder: `Option ${index + 1}`,
      id,
    }
    teacherStatements.push(statementObject)
    studentStatements.push({...statementObject, statementIs: null})
  });
  let questionObject = {
    id: getUniqueId(10),
    parent_id: null,
    segment_type: "question",
    experience_id: experienceId,
    user_id: userId,
    position: destinationPosition,
    custom_fields: "{}",
    question_segment_attributes: {
      content: questionData.question,
      teacher_json: {
        segment_data: {
          statements: teacherStatements,
        },
      },
      student_json: {
        segment_data: {
          statements: studentStatements,
        },
      },
      question_type: "true_false",
    },
  };
  if (rubricType === "points") {
    questionObject.points = marks;
  } else if (
    rubricType === "myp_achievement_level" ||
    rubricType === "criteria_with_points"
  ) {
    questionObject.criterium_associations_attributes =
      getCriteriumAssociationsAttributes(criteria, strands);
  }
  return questionObject;
};

export const rbdInlineObject = ({
  experienceId,
  userId,
  marks,
  destinationPosition,
  questionData,
  questionType,
  rubricType,
  criteria,
  strands
}) => {
  const buildSegmentData = () => ({
    columns: buildColumnsForRBDInline(questionData, questionType),
    possible_responses: {
      items: [],
      title: "Possible Responses",
      id: getUniqueId(),
    },
    distractors: [],
  });

  const teacherJson = buildSegmentData("teacher");
  let items = [];
  const columns = teacherJson.columns.map((column) => {
    items.push(...column.items); 
    return { ...column, items: [] };
  });

  const studentJson = {
    columns,
    possible_responses: {
      ...teacherJson.possible_responses, 
      items
    },
    distractors: [],
  };

  const questionObject = {
    id: getUniqueId(10),
    segment_type: "question",
    experience_id: experienceId,
    user_id: userId,
    position: destinationPosition,
    custom_fields: "{}",
    parent_id: null,
    question_segment_attributes: {
      content: questionData.question,
      teacher_json: { segment_data: teacherJson },
      student_json: { segment_data: studentJson },
      question_type: questionType,
    },
  };
  if (rubricType === "points") {
    questionObject.points = marks
  } else if (rubricType === "myp_achievement_level" || rubricType === "criteria_with_points") {
    questionObject.criterium_associations_attributes = getCriteriumAssociationsAttributes(criteria, strands)
  }
  return questionObject;
};

const fillTypeObject = ({
  experienceId,
  userId,
  marks,
  destinationPosition,
  questionData,
  questionType,
  rubricType, 
  criteria, 
  strands
}) => {
  const createSegmentData = (role) => {
    const { gaps, question } = questionData;
    const isFillText = questionType === "cke_fill_text";
  
    const mappedGaps = gaps.map((gap, index) => {
      const options = buildOptionsForFillType(role, gap);
  
      const teacherAnswer = isFillText
        ? options[0]
        : options.find((option) => option.checked);
  
      const studentAnswer = isFillText
        ? { ...teacherAnswer, content: "" }
        : {};
  
      const studentOptions = questionType === "cke_fill_text"
        ? undefined
        : options.map((option) => ({
            ...option,
            checked: false,
          }));
  
      return {
        id: getUniqueId(),
        items: options,
        title: `Gap ${index + 1}`,
        answer: teacherAnswer,
        studentItems: studentOptions,
        studentAnswer: studentAnswer,
        archived: false,
      };
    });
  
    const updatedContent = injectInputsIntoQuestionContent(
      question,
      mappedGaps,
      questionType
    );
  
    return {
      gaps: mappedGaps,
      content: updatedContent,
    };
  };
  
  const teacherSegmentData = createSegmentData("teacher");
  const studentSegmentData = {
    ...teacherSegmentData,
    gaps: teacherSegmentData.gaps.map((gap) => ({
      ...gap,
      items: gap.studentItems,
      answer: gap.studentAnswer,
    })),
  };
  
  
  let questionObject = {
    id: getUniqueId(10),
    segment_type: "question",
    experience_id: experienceId,
    user_id: userId,
    position: destinationPosition,
    custom_fields: "{}",
    parent_id: null,
    question_segment_attributes: {
      teacher_json: {
        segment_data: teacherSegmentData,
      },
      student_json: {
        segment_data: studentSegmentData,
      },
      question_type: questionType,
    },
  };
  if (rubricType === "points") {
    questionObject.points = marks;
  } else if (
    rubricType === "myp_achievement_level" ||
    rubricType === "criteria_with_points"
  ) {
    questionObject.criterium_associations_attributes =
      getCriteriumAssociationsAttributes(criteria, strands);
  }

  return questionObject;
};

export const getSegmentObject = ({
  questionType,
  questionData,
  experienceId,
  userId,
  marks,
  destinationPosition,
  rubricType, 
  criteria,
  strands
}) => {
  let segmentObject = {};
  switch (questionType) {
    case "long_answer":
      segmentObject = longAnswerObject({experienceId, userId, marks, destinationPosition, questionData, rubricType, criteria, strands});
      break;
    case "mcq":
      segmentObject = mcqObject({experienceId, userId, marks, destinationPosition, questionData, questionType: "mcq_single",  rubricType, criteria, strands});
      break;
    case "mcq_multiple": 
      segmentObject = mcqObject({experienceId, userId, marks, destinationPosition, questionData, questionType: "mcq_multiple", rubricType, criteria, strands})
      break;
    case "true_false":
      segmentObject = trueFalseObject({experienceId, userId, marks, destinationPosition, questionData, rubricType, criteria, strands});
      break;
    case "fill_text": 
      segmentObject = fillTypeObject({experienceId, userId, marks, destinationPosition, questionData, questionType :"cke_fill_text", rubricType, criteria, strands});
      break;
    case "fill_dropdown": 
      segmentObject = fillTypeObject({experienceId, userId, marks, destinationPosition, questionData, questionType: "cke_fill_dropdown", rubricType, criteria, strands});
      break;
    case "match":
      segmentObject = rbdInlineObject({experienceId, userId, marks, destinationPosition, questionData, questionType: "rbd_inline_matching", rubricType, criteria, strands});
      break;
    case "sort":
      segmentObject = rbdInlineObject({experienceId, userId, marks, destinationPosition, questionData, questionType: "rbd_inline_sorting", rubricType, criteria, strands});
      break;
    case "classify":
      segmentObject = rbdInlineObject({experienceId, userId, marks, destinationPosition, questionData, questionType: "rbd_inline_classify", rubricType, criteria, strands});
      break;
    case "nested_question":
      segmentObject = nestedQuestionObject({experienceId, userId, marks, destinationPosition, questionData, questionType, rubricType, criteria, strands});
      break;
    case "task":
      segmentObject = nestedQuestionObject({experienceId, userId, marks, destinationPosition, questionData, questionType, rubricType, criteria, strands});
      break;
    default:
      break;
  }
  return segmentObject;
};

const injectInputsIntoQuestionContent = (question, mappedGaps, questionType) => {
  let inputIndex = 0;
  let inputType, inputClass;
  if (questionType === "cke_fill_text") {
    inputType = "textinput"
    inputClass = "ap-text-input"
  } else {
    inputType = "dropdowninput"
    inputClass = "ap-drop-down-input"
  }
  return question.replace(/\[input\d*\]/g, () => {
      const uniqueId = mappedGaps[inputIndex++].id || getUniqueId();
      return `<${inputType} class="${inputClass}" data-id="${uniqueId}"></${inputType}>`;
  });
};
const getCriteriumAssociationsAttributes = (criteria, strands) => {
  let criteriumAssociationsAttributes = []
  if (criteria && criteria.length > 0) {
    criteriumAssociationsAttributes = criteria.map((item) => {
      // The ...rest syntax is used to capture any remaining properties of the item object into the rest variable and then pass it without modifying them.
      const { id, marks, title: criteriaTitle, ...rest } = item;
      // Split the string at the first hyphen, capturing the rest of the text after it
      // EX: A-Applying mathematics in real-life contexts will give ['A', 'Applying mathematics in real-life contexts will give']
      const [title, label] = criteriaTitle.split(/-(.+)/);

      const matchingStrands = strands && strands
        .filter((strand) => {
          const [strandTitle] = strand.split("-");
          return strandTitle.trim().includes(title.trim());
        })
        .map((strand) => {
          const [strandTitle] = strand.split("-");
          return { label: strandTitle.trim() };
        });

      const strandsToAdd =
        matchingStrands && matchingStrands.length > 0
          ? matchingStrands
          : [];

      const criteriaWithStrands = {
        ...rest,
        criterium_id: id,
        associable_type: "Segment",
        points: marks,
        custom_fields: {
          title,
          label,
        },
        strands: strandsToAdd,
      };

      return criteriaWithStrands;
    });
    return criteriumAssociationsAttributes;
  }

}
