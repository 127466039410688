import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  Input,
  Row,
  Col,
  Drawer,
  Empty,
  Timeline,
  Tooltip,
  Typography,
  Checkbox,
  Space,
  TreeSelect,
  DatePicker,
  Alert,
} from "antd";
import { ProfileOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { firestoreDB, firestoreFieldValue } from "/src/config/initializers";
import MrFirebase from "/src/lib/MrFirebase/MrFirebase";
import { checkMob, getUTCDate, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";
import dayjs from "dayjs";
import {
  appRegionSelector,
  currentUserSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import SegmentLogInfo from "../SegmentLogInfo";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import EllipsisContent from "/src/components/UI/EllipsisContent/EllipsisContent";
import ScreenshotLogs from "/src/views/Screenshots/ScreenshotLogs";
import LogsInTable from "../LogsInTable";
import { actions as genericActions } from "/src/App/genericRedux";
import { MrSelect } from "mr_react_framework";
// import moment from "moment";
import { isFirebaseAuthenticatedSelector } from "../../FirestoreInteractions/selector";
import Spinner from "/src/components/UI/Spinner/Spinner";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import { cloneDeep, uniq } from "lodash";

const { RangePicker } = DatePicker;
const { Search } = Input;

const SelectTeachers = MrSelect({
  actions: genericActions,
  resourceName: "users",
  config: {
    url: "/users",
    params: { by_role: "teacher_admin" }, //adding multiple roles and parsing on BE - want admin also here //default is org_id on BE
    processData: (data, props) => {
      console.log("updated data", data);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.name, value: item.id };
      });
    },
    // searchApi: false,
    searchApi: true,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Search by user",
      // mode: "multiple",
      allowClear: true,
      style: { width: "200px" },
    },
  },
});

const AuditLogsFilter = (props) => {
  console.log("props in log modal==>", props);
  const { defaultLogParams, logParams, setLogParams, localSearchValue, setLocalSearchValue, logView, experience = {}, setShowScreenshots, filterOnSubmit = false } = props;
  const mrIntl = useTranslate()
  const currentUser = useSelector(currentUserSelector());
  const appRegion = useSelector(appRegionSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const isFirebaseAuthenticated = useSelector(isFirebaseAuthenticatedSelector);
  const experienceSettings = experience.settings || {}

  const org_id = currentUser.org_id
  const logsFilter = []
  const isChina = appRegion === "china" ? true : false;
  const autoScreenshotEnabled = enabledFeatures.auto_screenshots && experienceSettings.enable_auto_screenshots

  const defaultFilterValues = {
    categories: ["experience"],
    date: [getUTCDate({ type: "startOf", daysDiff: -7 }), getUTCDate({ type: "endOf" })],
    user_id: null,
    localSearchInput: "",
  }

  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const [localLogParams, setLocalLogParams] = useState({
    ...logParams,
  });
  
  const maxSelectableRange = 30;
  const [selectedDateRange, setSelectedDateRange] = useState();
  
  const categoryFilterAlertMessage = <Alert
    message={mrIntl("AuditLogsFilter.you_can_select_a_maximum_of_10_categories")}
    type="warning"
    showIcon
    style={{ marginBottom: '10px' }}
  />

  const localLogParamsCategory = localLogParams && localLogParams.category;
  
  let logFilterOptions = []

  if(logView === "org_audit_logs") {
    logFilterOptions = [
      {
        label: "Assessment Logs", value: "experience", key: "experience", children: [
          // TODO: Support old value create also 
          { label: "Create", value: "experience_crud", key: "experience_crud"  },
          { label: "Delivery Settings", value: "delivery_settings", key: "experience_delivery_settings" },
          { label: "Assign", value: "assign", key: "experience_assign" },
          { label: "Actions", value: "actions", key: "experience_actions" },
          { label: "Publish Status", value: "status", key: "experience_publish_status" }
        ],
      },
      {
        label: "Item Logs", value: "segment", key: "segment", children: [
          { label: "Content", value: "content", key: "segment_content" },
          { label: "Question", value: "question", key: "segment_question" },
          { label: "Section", value: "section", key: "segment_section" },
        ],
      },
      {
        label: "Library", value: "library_crud", key: "library"
      },
      {
        label: "Users", value: "user", key: "user", children: [
          { label: "User management", value: "user_crud", key: "user_crud" },
          { label: "User password management", value: "user_password_changes", key: "user_password_changes" },
          { label: "Access logs", value: "user_access", key: "user_access" },
        ]
      },
      {
        label: "Classes", value: "group_crud", key: "group_crud"
      },
      {
        label: "Organisation", value: "org_crud", key: "org"
      },
      {
        label: "Settings - Permissions", value: "org_settings", key: "org"
      },
      {
        label: "Settings - Programs", value: "org_programmes_manage", key: "org"
      },
    ]
  } else {
    logFilterOptions = [
      {
        label: "Assessment Logs", value: "experience", key: "experience", children: [
          // TODO: Support old value create also 
          { label: "Create", value: "experience_crud", key: "experience_crud" },
          { label: "Delivery Settings", value: "delivery_settings", key: "experience_delivery_settings" },
          { label: "Assign", value: "assign", key: "experience_assign" },
          { label: "Actions", value: "actions", key: "experience_actions" },
          { label: "Publish Status", value: "status", key: "experience_publish_status" },
        ]
      },
      {
        label: "Item Logs", value: "segment", key: "segment", children: [
          { label: "Content", value: "content", key: "segment_content" },
          { label: "Question", value: "question", key: "segment_question" },
          { label: "Section", value: "section", key: "segment_section" },
        ]
      },
    ]
  }
  
  if (localLogParamsCategory && localLogParamsCategory[1].length === 10) {
    const selectedCategories = localLogParams.category[1];
    logFilterOptions.map((option) => {
      let optionDisabled = false;
      if (option.children) {
        option.children.forEach((child) => {
          console.log("children >>>>>>>>>>>>>>>>>>>", option, option.children, child, child.value);
          child.disabled = !selectedCategories.includes(child.value);
          if (!optionDisabled && child.disabled) {
            optionDisabled = true;
          }
        });
      } else{
        optionDisabled = !selectedCategories.includes(option.value);
      }
      option.disabled = optionDisabled;
      return option;
    });

  }



  const updateLogParams = (category = [], label, extra) => {
    console.log("value, label, extra >>>>>>>>>>>>>>>> ", category, label, extra, extra.triggerValue);
    let params = { ...localLogParams };
    if (category.length > 0) {
      const triggerValue = extra.triggerValue
      const triggerValueCategories = []
      const categories = []
      category.forEach((c) => {
        // We need children because firebase handles only one "IN" with query 
        // converting experience and segment log type to children categories for experience log modals
        // converting user log type to children categories for org logs 
        if (c === "experience" || c === "segment" || c === "user") {
          // let children = []
          logFilterOptions.forEach((option) => {
            if (option.value === c && option.children) {
              option.children.forEach((child) => { 
                categories.push(child.value) 
                if (triggerValue === c) {
                  triggerValueCategories.push(child.value)
                }
              })
            }
          })
        } else {
          categories.push(c)
          if (triggerValue === c) {
            triggerValueCategories.push(c)
          }
        }
      })
      let newCategories = [...categories];
      if (categories.length > 10) {
        const combinedCategories = [...params['category'][1], ...triggerValueCategories];
        newCategories = uniq(combinedCategories).slice(0, 10);
      }
      console.log("newCategories ========>", newCategories);
      params['category'] = ["in", newCategories];
      setFilterValues((prevValues) => ({
        ...prevValues,
        categories: newCategories
      }));
    }
    else {
      delete params.category
      // params.category = defaultLogParams.category
    }
    if (filterOnSubmit) {
      setLocalLogParams({ ...params });
    } else {
      setLogParams({ ...params });
    }

    //  else {
    //   if (logView != "monitor") {
    //     if(isChina){
    //       params['log_type'] = ["eq", log_type]
    //       params['item_id'] = ["eq", itemId] 
    //       params["org_id"] = ["eq", org_id]
    //     } else {
    //       params['log_type'] = ["==", log_type]
    //       if(itemId) {
    //         params['item_id'] = ["==", itemId] // If the log type is "experience", the itemId is experience ID. On the other hand, if the log type is "segment", the itemId is segment ID.
    //       } else {
    //         params["org_id"] = ["==", org_id]
    //       }
    //     }
    //   }
    // }
  };
  
  const onSelectTeacher = (userId) => {
    console.log("onSelectTeacher ====>", userId, logParams.category, logParams);
    let params = {...localLogParams}
    if (userId) {
      if (isChina) {
        params['user_id'] = ["eq", userId]
      } else {
        params['user_id'] = ["==", userId]
      }
    } else {
      delete params.user_id
    }
    // setLogParams({...params})
    if(filterOnSubmit) {
      setLocalLogParams({...params});
    } else {
      setLogParams({ ...params });
    }
    setFilterValues((prevParams) => ({ ...prevParams, user_id: userId }));
  }
  
  const onRangeChange = (range) => {
    console.log("onRangeChange =====>", range);
    let params = {...localLogParams}
    const startDate = dayjs(range[0]).startOf("day")
    const endDate = dayjs(range[1]).endOf("day")
    console.log("startDate ======>", startDate.valueOf(), endDate.valueOf());
    // params["created_at"] = [">=", startDate.valueOf()]
    // params["created_at"] = ["<=", endDate.valueOf()]

    if (isChina) {
      params["created_at"] = [
        ["gte", startDate.valueOf()],
        ["lte", endDate.valueOf()]
      ]
    } else {
      params["created_at"] = [
        [">=", startDate.valueOf()],
        ["<=", endDate.valueOf()]
      ]
    }
    
    // setLogParams({...params})
    if(filterOnSubmit) {
      setLocalLogParams({...params});
    } else {
      setLogParams({ ...params });
    }
    setFilterValues((prevValues) => ({
      ...prevValues,
      date: range,
    }));
    // setSelectedDateRange([startDate, endDate]);
  }
  
  const onOpenChange = (open) => {
    if (open) {
      setSelectedDateRange([null, null]);
     }else {
      setSelectedDateRange(null);
    }
  };

  const handleIpSearch = (data) => {
    console.log("handleIpSearch =====>", data);
    setLocalSearchValue(data)
  }

  const handleShowScreenshots = (showScreenshot) => {
    updateLogParams(showScreenshot ? ["monitor_screenshot"] : [])
    setShowScreenshots(showScreenshot)
  }

  const resetParams = (params) => {
    if(filterOnSubmit) {
      setLocalLogParams({...params});
    }
    setLogParams(params);
    setFilterValues(defaultFilterValues)
    setLocalSearchValue("")
    // setSelectedDateRange(defaultFilterValues.date)
  }

  const resetFilter =
    <Space style={{justifyContent: "space-between", width: "100%", marginBottom: "15px"}}>
      <div className="text-right">
        <ResetFilter 
          label={mrIntl("CommonText.reset")} 
          setParams={resetParams} 
          defaultValues={defaultLogParams} 
          useDefaultValues={true} 
          setQueryParamsConfigValue={false}
        />
      </div>
    </Space>

  const searchLogsFilterLabel = <Space>Search by email, message or IP</Space>
  const searchLogsFilter = 
    <Search
      value={filterValues.localSearchInput}
      placeholder="Search by email, message or IP"
      onSearch={() => handleIpSearch(filterValues.localSearchInput)}
      onChange={(e) => {
        const inputValue = e.target.value;
        setFilterValues((prevValues) => ({
          ...prevValues,
          localSearchInput: inputValue,
        }));
        
      }}
      allowClear
      enterButton
      style={{
        width: "100%"
      }}
    />
  const categoryFilterLabel = <Space>Category</Space>
  
  const categoryFilter = (
    <TreeSelect
      treeData={logFilterOptions}
      defaultValue={["experience"]}
      value={filterValues.categories}
      style={{ width: "100%" }}
      className="categories-filtter"
      multiple
      treeDefaultExpandAll
      treeCheckable={true}
      showCheckedStrategy={"SHOW_PARENT"}
      getPopupContainer={(triggerNode) => {
        const parentContainer = document.querySelector(".categories-filtter")
        // container.parentNode
        return parentContainer;
      }}
      // treeCheckable
      placeholder={mrIntl("AuditLogs.select_log_type")}
      onChange={(value, label, extra) => updateLogParams(value, label, extra)}
    />
  );

  const dateFilterLabel = <Space>Date</Space>
  const dateFilter = 
    <RangePicker
      onChange={(range) => onRangeChange(range)}
      style={{ width: "100%" }}
      clearIcon={false}
      disabledDate={(currentDate) => {
        // Calculate the date three months ago
        const todayDate = new Date();
        const threeMonthsAgoDate = new Date().setMonth(todayDate.getMonth() - 3);
        
        // Disable dates earlier than three months ago or dates in the future
        if (currentDate && (currentDate < threeMonthsAgoDate || currentDate > todayDate)) {
          return true;
        }
        if (!selectedDateRange) {
          return false;
        }
        const isStartDateExceededAllowedDateRange = selectedDateRange[0] && currentDate.diff(selectedDateRange[0], 'days') >= maxSelectableRange;
        const isEndDateExceededAllowedDateRange = selectedDateRange[1] && selectedDateRange[1].diff(currentDate, 'days') >= maxSelectableRange;
        return isEndDateExceededAllowedDateRange || isStartDateExceededAllowedDateRange;
      }}
      // selectedDateRange temporarily stores the chosen date range while the calendar popup is open. It controls disabled dates on the calendar. After the user interaction is complete, selectedDateRange is reset to null to restore the calendar's current or default state.
      value={selectedDateRange || filterValues.date}
      onOpenChange={onOpenChange}
      onCalendarChange={(val) => {
        setSelectedDateRange(val);
      }}
      defaultValue={[getUTCDate({ type: "startOf", daysDiff: -7 }), getUTCDate({ type: "endOf" })]}
      cellRender={(current) => {
        const style = {};
        if (current.date() === 1) {
          style.border = '1px solid #1677ff';
          style.borderRadius = '50%';
        }
        return (
          <div className="ant-picker-cell-inner" style={style}>
            {current.date()}
          </div>
        );
      }}
    />

  const userFilterLabel = <Space>User</Space>
  const userFilter = 
    <SelectTeachers 
      style={{ width: "100%" }} 
      value={filterValues.user_id}
      config={{
        params: { by_org_user_unarchived: true, by_org_id: currentUser.org_id }
      }} 
      onChange={(userId) => onSelectTeacher(userId)}
    />

  console.log("SelectTeachers user_ids ==> ", logParams.user_ids)

  const handleSubmit = () => {
    console.log("aqpsd Filter values:", filterValues);
    console.log("aqpsd",{localLogParams});
    handleIpSearch(filterValues.localSearchInput)
    setLogParams(localLogParams);
  };

  

  const submitButton = <Space className="m-t-20">
    <Button type="primary" onClick={() => handleSubmit()}>Submit</Button>
  </Space>

  const autoScreenshotCheckbox = autoScreenshotEnabled && <Checkbox onChange={(e) => handleShowScreenshots(e.target.checked)}>{mrIntl("AuditLogs.show_screenshots")}</Checkbox>
  
  if(logView === "org_audit_logs") {
    logsFilter.push(resetFilter)
    logsFilter.push(searchLogsFilterLabel)
    logsFilter.push(searchLogsFilter)
    logsFilter.push(categoryFilterLabel)
    if(localLogParamsCategory){
      logsFilter.push(localLogParamsCategory[1].length === 10 && categoryFilterAlertMessage)
    }
    logsFilter.push(categoryFilter)
    logsFilter.push(dateFilterLabel)
    logsFilter.push(dateFilter)
    logsFilter.push(userFilterLabel)
    logsFilter.push(userFilter)
    if(filterOnSubmit) {
      logsFilter.push(submitButton)
    }
  } else if (logView === "experience_audit_logs") {
    logsFilter.push(categoryFilter)
  } else if (logView === "monitor_audit_logs") {
    logsFilter.push(autoScreenshotCheckbox)
  } else {

  }

  return logsFilter
};

export default AuditLogsFilter;