import React from "react";
import { List, Avatar, Space, Tooltip } from "antd";
import dayjs from "dayjs";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ProgrammeList = (props) => {
  const { dataSource, currentUser, permission, deleted } = props;

  const Title = ({ item }) => item.name;
  const mrIntl = useTranslate()
  const Description = ({ item }) => (
    <Space className="description">
      <Tooltip title={mrIntl("ProgrammeList.created")}>
        {<span>{dayjs(item.created_at).format("MMM DD, YYYY")}</span>}
      </Tooltip>
    </Space>
  );

  const ProfileIcon = ({ item }) => (
    <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
      {item.abbr_display}
    </Avatar>
  );

  return (
    <>
      <List
        className="custom-main-list"
        // loading={initLoading}
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(item, i) => (
          <CustomListItem
            type="programme"
            item={item}
            title={<Title item={item} />}
            description={<Description item={item} />}
            avatar={<ProfileIcon item={item} />}
            currentUser={currentUser}
            edit={true}
            editShouldRefreshPage={true}
            deleted={deleted}
            deletedAllow={false}
            permission={permission}
          />
        )}
      />
    </>
  );
};

export default ProgrammeList;
