// import { scan } from 'react-scan'; // import this BEFORE react
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
// import createHistory from "history/createBrowserHistory";
// import { IntlProvider, addLocaleData } from "react-intl";
import App from "./App/App";
// ADD REDUX IMPORT STATEMENT BELOW THIS
import {
  reducer as annotationsReducer,
  watchAnnotations,
} from "/src/views/Annotations/redux";
import {
  reducer as canvasAnnotationsReducer,
  watchCanvasAnnotations,
} from "/src/views/CanvasAnnotations/redux";
import {
  reducer as userResponsesReducer,
  watchUserResponses,
} from "/src/views/UserResponses/redux";
import {
  reducer as embeddedResourcesReducer,
  watchEmbeddedResources,
} from "/src/views/EmbeddedResources/redux";
import {
  reducer as experiencesReducer,
} from "/src/views/Experiences/redux";
import {
  watchExperiences,
} from "/src/views/Experiences/saga";
import {
  reducer as libraryexperiencesReducer,
  watchLibraryexperiences,
} from "/src/views/Experiences/LibraryExperiences/redux";
import {
  reducer as todayexperiencesReducer,
  watchTodayexperiences,
} from "/src/views/Experiences/TodayExperiences/redux";
import {
  reducer as segmentsReducer,
  watchSegments,
} from "/src/views/Segments/redux";
import {
  reducer as topicsReducer,
} from "/src/views/Segments/Topics/redux";
import {
  watchTopics
} from "/src/views/Segments/Topics/saga";
import {
  reducer as librarysegmentsReducer,
  watchLibrarysegments,
} from "/src/views/Segments/LibrarySegments/redux";
import {
  reducer as integrationsReducer,
  watchIntegrations,
} from "/src/views/Integrations/redux";
import {
  reducer as orgProgrammesReducer,
  watchOrgProgrammes,
} from "/src/views/OrgProgrammes/redux";
import { reducer as groupsReducer, watchGroups } from "/src/views/Groups/redux";
import {
  reducer as groupUsersReducer,
  watchGroupUsers,
} from "/src/views/GroupMembers/redux";
import {
  reducer as experienceUsersReducer,
} from "/src/views/ExperienceUsers/redux";
import {
  watchExperienceUsers,
} from "/src/views/ExperienceUsers/saga";
import { reducer as usersReducer, watchUsers } from "/src/views/Users/redux";
import {
  reducer as programmesReducer,
  watchProgrammes,
} from "/src/views/Programmes/redux";
import { reducer as boardsReducer, watchBoards } from "/src/views/Boards/redux";
import { reducer as rubricsReducer, watchRubrics } from "/src/views/Rubrics/redux";
import {
  reducer as questionbanksReducer,
  watchQuestionbanks,
} from "/src/views/Questionbanks/redux";
import { reducer as plansReducer, watchPlans } from "/src/views/Plans/redux";
import {
  reducer as featuresReducer,
  watchFeatures,
} from "/src/views/Features/redux";
import {
  reducer as promptsReducer,
  watchPrompts,
} from "/src/views/Prompts/redux";
import {
  reducer as feedbacksReducer,
  watchFeedbacks,
} from "/src/views/Feedbacks/redux";
import { reducer as termsReducer, watchTerms } from "/src/views/Terms/redux";
import { reducer as tagsReducer, watchTags } from "/src/views/Tags/redux";
import {
  reducer as firebaseReducer,
  watchFirebase,
} from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { reducer as requestReducer, watchRequests } from "/src/App/genericRedux";
import {
  reducer as offlineAppReducer,
  watchOfflineApp,
} from "/src/App/OfflineApp/offlineAppRedux";

import { watchLogin, reducer as loginReducer } from "./views/Auth/Login/redux";
import {
  watchAppConstants,
  reducer as appConstantsReducer,
} from "./views/AppConstants/redux";

import { watchOrgs, reducer as orgsReducer } from "./views/Orgs/redux";
import { reducer as programmeSubjectsReducer, watchProgrammeSubjects } from "/src/views/ProgrammeSubjects/redux";
import { ConfigProvider } from "antd";
import { getFromLS } from "/src/lib/utils/helperMethods";
import { watchAIsegments, reducer as aiSegmentsReducer } from "/src/views/Segments/GenerateAI/redux";
// import ReactGA from 'react-ga';

import {
  reducer as orgIntegrationReducer,
  watchOrgIntegration
} from "/src/views/OrgIntegration/redux.js";
import loadable from "@loadable/component";

const originalUserRole = getFromLS("originalUserRole")
if (
  import.meta.env.VITE_MODE !== "development" &&
  import.meta.env.VITE_MODE !== "staging" && 
  import.meta.env.VITE_MODE !== "demo" &&
  originalUserRole !== "superadmin"
) {
  console.log = () => {};
  console.warn = () => {};
  // console.error = () => {};
}

// This hasn't been working since VITE - using NODE_ENV instead of VITE_MODE
// if (import.meta.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//     trackExtraHooks: [
//       [require('react-redux/lib'), 'useSelector']
//     ]
//   });
// }

if (import.meta.env.VITE_MODE === 'development' && typeof window !== 'undefined') {
  const reactScan = loadable.lib(() =>
    import("react-scan")
  );
  reactScan.load().then((module) => {
    console.log("scan enabled");
    module.scan({
      enabled: true,
      log: true, // logs render info to console (default: false)
    });
  });
}

// const history = createHistory();

const appReducer = combineReducers({
  experiences: experiencesReducer,
  libraryexperiences: libraryexperiencesReducer,
  todayexperiences: todayexperiencesReducer,
  segments: segmentsReducer,
  topics: topicsReducer,
  librarysegments: librarysegmentsReducer,
  aisegments: aiSegmentsReducer,
  integrations: integrationsReducer,
  orgProgrammes: orgProgrammesReducer,
  groups: groupsReducer,
  groupUsers: groupUsersReducer,
  experienceUsers: experienceUsersReducer,
  users: usersReducer,
  programmes: programmesReducer,
  boards: boardsReducer,
  rubrics: rubricsReducer,
  questionbanks: questionbanksReducer,
  plans: plansReducer,
  features: featuresReducer,
  prompts: promptsReducer,
  feedbacks: feedbacksReducer,
  request: requestReducer,
  tags: tagsReducer,
  terms: termsReducer,
  firebase: firebaseReducer,
  userResponses: userResponsesReducer,
  annotations: annotationsReducer,
  canvasAnnotations: canvasAnnotationsReducer,
  offlineApp: offlineAppReducer,
  orgIntegrations: orgIntegrationReducer,

  // prefrences: prefrencesReducer,
  //
  // leads: leadsReducer,
  // lead: leadReducer,
  // call: callReducer,
  // actions: actionsReducer,
  // logs: logsReducer,
  // contacts: contactsReducer,
  // items: itemsReducer,
  // auth: authReducer,
  login: loginReducer,
  appConstants: appConstantsReducer,
  orgs: orgsReducer,
  embeddedResources: embeddedResourcesReducer,
  programmeSubjects: programmeSubjectsReducer,
});
const rootReducer = (state, action) => {
  console.log("rootReducer action", action);
  if (action.type === "LOGOUT_LOGIN_SUCCESS") {
    state = undefined;
  }

  return appReducer(state, action);
};

const sagaMiddleware = createSagaMiddleware();

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers =
  (import.meta.env.VITE_MODE === "development" &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  rootReducer,
  // composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(watchAnnotations);
sagaMiddleware.run(watchCanvasAnnotations);
sagaMiddleware.run(watchUserResponses);
sagaMiddleware.run(watchEmbeddedResources);
sagaMiddleware.run(watchExperiences);
sagaMiddleware.run(watchLibraryexperiences);
sagaMiddleware.run(watchTodayexperiences);
sagaMiddleware.run(watchSegments);
sagaMiddleware.run(watchTopics);
sagaMiddleware.run(watchLibrarysegments);
sagaMiddleware.run(watchAIsegments);
sagaMiddleware.run(watchIntegrations);
sagaMiddleware.run(watchOrgProgrammes);
sagaMiddleware.run(watchGroups);
sagaMiddleware.run(watchGroupUsers);
sagaMiddleware.run(watchExperienceUsers);
sagaMiddleware.run(watchUsers);
sagaMiddleware.run(watchProgrammes);
sagaMiddleware.run(watchBoards);
sagaMiddleware.run(watchRubrics);
sagaMiddleware.run(watchQuestionbanks);
sagaMiddleware.run(watchPlans);
sagaMiddleware.run(watchFeatures);
sagaMiddleware.run(watchPrompts);
sagaMiddleware.run(watchFeedbacks);
sagaMiddleware.run(watchTerms);
sagaMiddleware.run(watchRequests);
sagaMiddleware.run(watchTags);

sagaMiddleware.run(watchLogin);
sagaMiddleware.run(watchAppConstants);
sagaMiddleware.run(watchOrgs);
sagaMiddleware.run(watchFirebase);
sagaMiddleware.run(watchOfflineApp);
sagaMiddleware.run(watchProgrammeSubjects);
sagaMiddleware.run(watchOrgIntegration);
// sagaMiddleware.run(watchLeads);

if (
  import.meta.env.VITE_MODE === "production" ||
  import.meta.env.VITE_MODE === "demo" ||
  import.meta.env.VITE_MODE === "staging" ||
  import.meta.env.VITE_MODE === "development1"
) {
  let releaseString = `ap-v2-web-${import.meta.env.VITE_RELEASE_ENV}-${import.meta.env.VITE_RELEASE_TIME}-${import.meta.env.VITE_RELEASE_VERSION}`
  let envString = `${import.meta.env.VITE_MODE} - ${import.meta.env.VITE_RELEASE_ENV}`
  console.log("releaseString", releaseString)
  console.log("envString", envString)
  Sentry.init({
    dsn:
      "https://6dd80636ee8c44a8ada39ecb83ab0dd6@o444384.ingest.sentry.io/5419220",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.0, // we don't want transactions - tracks too many
    environment: envString,
    release: releaseString,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications",
    ],
  });

  // ReactGA.initialize('UA-71336374-3');
}

const render = (Component) => {
  return ReactDOM.render(
    <Router>
      <Provider store={store}>
        <ConfigProvider
          // getPopupContainer={node => {
          //   if (node) {
          //     return node.parentNode
          //   } 
          //   return document.body;
          // }}
          theme={{
            token: {
              fontFamily: 'Nunito Sans'
            },
          }}
        >
          <Component />
        </ConfigProvider>

      </Provider>
    </Router>,
    document.getElementById("root")
  );
};

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
