/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "antd";
import {
  changeSecurityStatusSelector,
  appTypeSelector,
  changeSecurityLoadingSelector,
} from "/src/App/OfflineApp/offlineAppSelectors";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import {actions as experienceReduxActions} from "/src/views/Experiences/redux"
import { currentTimeValidWrtServerTime, getServerTimeOffset } from "/src/lib/utils/helperMethods";
import { message } from "/src/components/UI/AntdAppHelper";
import { checkInternetConnectivity } from "/src/lib/CheckInternetConnectivity/CheckInternetConnectivity";
import { appRegionSelector } from "/src/views/Auth/Login/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { endSessionBtnLoadingSelector, startTestBtnLoadingSelector } from "/src/views/Experiences/selector";
import { getWhiteListUrls, macAppsToClose } from "/src/App/OfflineApp/offlineAppHelper";

const IosStartBtn = (props) => {
  // console.log("iosStartBtn",props);
  const { item, resourceName, showExperience, disabled } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const appType = useSelector(appTypeSelector());
  const appRegion = useSelector(appRegionSelector());
  // NOTE: Do not use it for showing loading, else it will reflect all start buttons on page
  const startTestBtnLoading = useSelector(startTestBtnLoadingSelector());
  const securityStatus = useSelector(changeSecurityStatusSelector());
  const mrIntl = useTranslate();
  const experienceSettings = item.settings || {}
  const whiteListUrls = getWhiteListUrls(experienceSettings)
  const [loading, setLoading] = useState(false);
  // const whitelistedUrls = "^https:\\/\\/((assessprep\\.(com|cn))|(.*?\.assessprep\.(com|cn))|(.*?\.youtube\.com)|(.*?\.managebac\.(com|cn))|(.*?\.vimeo\.com)|(vimeo\.com)|(login\.(microsoftonline|live)\.com)|(accounts\.(google|youtube)\.(co(m|(\.([a-z]{2}))))))(\/.*)?$"
  //   console.log("SecurityStatus before useEffect", securityStatus);
  let startTooltipTitle = item.status === "closed" ? "Test closed" : "Start";
  
  useEffect(() => {
    if (startTestBtnLoading && startTestBtnLoading[item.uid]) {
      setLoading(true)
    }
  }, [startTestBtnLoading]);
  
  useEffect(() => {
    // console.log("useEffect SecurityStatus", securityStatus);

    // added uid check because, earlier if there were 3 buttons in the list, this useffect was being triggered 3 times after security on for even 1 test and the last test in the list was opening always
    if (startTestBtnLoading && startTestBtnLoading[item.uid]) {
      if (securityStatus === "true") {
        setLoading(false)
        dispatch(experienceReduxActions.setStartTestBtnLoadingSuccess({startTestBtnLoading: null}))
        // console.log("security status is true redirect to new url");
        showExperience(item, resourceName);
        let urlToOpen = `/e/tests/${item.uid}${
          appType === "seb" || appType === "mac" || appType === "ios"
            ? "?firstLoad=true"
            : ""
        }`; // we want the firstload param on start click from list - but maybe should handle this also in showExperience
        history.push(urlToOpen);

        let nativeMethodsToCall = []
    
        nativeMethodsToCall.push({ key: "setWhitelistedUrls", value: whiteListUrls })
        if(appType === "mac"){
          // we still wanna close apps like textExpander 
          nativeMethodsToCall.push({ key: "closeAllApps", value: macAppsToClose })
        }
      
        dispatch(
          offlineAppActions.executeMethod(nativeMethodsToCall)
        );
        // dispatch(
        //   offlineAppActions.executeMethod({
        //     key: "setWhitelistedUrls",
        //     value: whitelistedUrls,
        //   })
        // );
      } else if (securityStatus === "false" || securityStatus === "failed") {
        console.log("Show error - user Declined");
        setLoading(false)
        dispatch(experienceReduxActions.setStartTestBtnLoadingSuccess({startTestBtnLoading: null}))
        message.error(
          "Security couldn't be turned on, and the test cannot be started. In case this issue continues, please restart your machine and try again.", 10
        );
      }
    }
  }, [securityStatus]);

  let iosStartBtn = (
      <Tooltip title={startTooltipTitle}>
        <Button
          loading={loading}
          disabled={disabled} //item.status === "closed" ? true : false
          type="primary"
          shape="round"
          onClick={(e) => {
            // added uid check for ios security useeffect
            if (!startTestBtnLoading) {
              setLoading(true)
              dispatch(experienceReduxActions.setStartTestBtnLoadingSuccess({startTestBtnLoading: {[item.uid]: true}}))
              checkInternetConnectivity(appRegion, (internetStatus) => {
                if (internetStatus === "online") {
                  getServerTimeOffset((offset) => { 
                    dispatch(experienceReduxActions.setServerTimeOffsetSuccess({serverTimeOffset: offset}))
                    if(currentTimeValidWrtServerTime(offset)){
                      dispatch(
                        offlineAppActions.executeMethod({
                          key: "changeSecurity",
                          value: true,
                        })
                      );
                    } else {
                      setLoading(false)
                      dispatch(experienceReduxActions.setStartTestBtnLoadingSuccess({startTestBtnLoading: null}))
                      message.error(mrIntl("CommonText.your_system_time_is_out_of_sync_not_be_able_to_start_tests"))
                    }
                  })
                } else {
                  setLoading(false)
                  dispatch(experienceReduxActions.setStartTestBtnLoadingSuccess({startTestBtnLoading: null}))
                  // message.error("Your internet connection not stable. Please check your internet connection and try again.")
                }
              }, mrIntl)
            }
          }}
          style={{ pointerEvents: disabled ? "none" : 'auto' }}
        >
          Start
        </Button>
      </Tooltip>
  );

  let startBtnToRender = iosStartBtn;
  return startBtnToRender;
};

export default IosStartBtn;
