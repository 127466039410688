import { fromJS } from "immutable";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { MrReduxCrud, formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import {actions as segmentActions} from "/src/views/Segments/redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import reduxCrud, { actionTypes, actions as topicActions } from "./redux";


function* rearrangeTopicSaga(action){
  const currentUser = yield select(currentUserSelector());

  try{
    yield put(topicActions.rearrangeSuccess({
      ...action.payload
    }));
    // const msg = `Topics rearranged by ${
    //   currentUser.name
    // } (${upperFirst(currentUser.role)})`;
    // yield put(firestoreInteractionActions.setLogs({
    //   logging: true,
    //   log_type: "experience",
    //   msg: msg,
    //   itemId: action.payload.experienceId,
    //   experienceId: action.payload.experienceId
    // }))
    // const fullState = yield select(state => state);
    // console.log("fullState", fullState);
    const topics = yield select(state => state.topics.get("segments"));
    console.log("saga topics", topics, action);
    if(action.payload.callback){
      yield call(action.payload.callback, topics);
    }

  } catch(e) {
    yield put(topicActions.rearrangeFail({
      ...action.payload
    }));
  }

}

function* showSaga(action) {
  yield put(topicActions.showStart());
  console.log( "segments show saga ==>", action );
  const topicId = action.payload.id;
  let url = `segments/${topicId}.json`;
  try {
    const response = yield axiosInstance.instance.get(url);
    // yield localStorage.setItem("token", response.data.token); 
    console.log( "show response 33", response );
    let topic = formatJsonApiData(response.data.segment);
    console.log( "show response topic", topic );

    let descendants = topic.descendants
    if(descendants && descendants.length > 0){
      yield put(segmentActions.fetchSuccess({data: {segments: descendants}}))
    }
    
    yield put(topicActions.showSuccess({data: {segment: topic}}));
  } catch (error) {
    // let errorMsg = response.data.error;
    console.log( "show error", error );
    yield put(topicActions.showFail({error: error}));
  }
}


export const watchTopics = reduxCrud.generateWatchSaga({
  REARRANGE_TOPIC: rearrangeTopicSaga,
  [actionTypes.SHOW_TOPIC]: showSaga,
});
