import React, { useState, useEffect } from "react";
import { Spinner, MrSelect } from "mr_react_framework";
import { useDispatch, useSelector } from "react-redux";
import {
  appRegionSelector,
  currentUserSelector,
} from "/src/views/Auth/Login/selector";
import { hasSyllabusItemsInSegments, checkMob } from "/src/lib/utils/helperMethods";
import { Col, Empty, Row, Tabs } from "antd";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import ExperienceAnalyticsSummary from "./ExperienceAnalyticsSummary";
import ExperienceAnalyticsQuestions from "./ExperienceAnalyticsQuestions";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import ExperienceAnalyticsTags from "./ExperienceAnalyticsTags";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import "./ExperienceAnalytics.scss";
import { syllabusTopicsSelector } from "/src/App/genericSelector";
import { activeSegmentSelector, segmentsSelector } from "/src/views/Segments/selector";

export const drawTopLineLabels = (shouldShowPercentage) => {
  return {
    afterDatasetsDraw(chart) {
      const { ctx, data, scales } = chart;
      const dataset = data.datasets[0].data;
      ctx.font = '12px sans-serif';
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';

      dataset.forEach((value, index) => {
        const xPosition = scales.x.getPixelForValue(index);
        const yPosition = scales.y.getPixelForValue(value);
        if ((!shouldShowPercentage && value !== 0) || (shouldShowPercentage && value !== '0.0')) {
          ctx.fillText(`${value}${shouldShowPercentage ? '%' : ''}`, xPosition, yPosition - 5);
        }
      });
    }
  }
}

const ExperienceAnalytics = (props) => {
  // const {  } = props;
  const currentUser = useSelector(currentUserSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const mrIntl = useTranslate()
  const dispatch = useDispatch();
  const segments = useSelector(segmentsSelector());
  const isSyllabusTopicTagged = hasSyllabusItemsInSegments(segments)
  console.log("ExperienceAnalytics unmount", segments, isSyllabusTopicTagged);

  useEffect(() => {
    const successObj = [
      { type: "summary", data: [] },
      { type: "questions", data: [] },
      { type: "tags", data: [] }
    ];
  
    return () => {
      successObj.forEach((obj) => {
        dispatch(experienceActions.getAnalyticsSuccess(obj));
      });
    };
  }, []);
 
  return (
    <React.Fragment>
      {/* {isFirebaseTokenLoading && <Spinner />} */}
      <Row className="experience-analytics-container">
        <Col span={16} offset={4}>
          <Tabs defaultActiveKey="1" centered="true" className="custom-tabs-pills">
            <Tabs.TabPane tab={mrIntl("ExperienceAnalytics.summary")} key="1">
              <ExperienceAnalyticsSummary />
            </Tabs.TabPane>
            {/* {activeExperience.settings["grading_setting"] === "per_question" && <Tabs.TabPane tab={mrIntl("ExperienceAnalytics.questions")} key="2">
              <ExperienceAnalyticsQuestions />
            </Tabs.TabPane>} */}
            <Tabs.TabPane tab={mrIntl("ExperienceAnalytics.questions")} key="2">
              <ExperienceAnalyticsQuestions />
            </Tabs.TabPane>
            {(activeExperience.settings["grading_setting"] === "per_question" && isSyllabusTopicTagged) && <Tabs.TabPane tab={mrIntl("ExperienceAnalytics.standards")} key="3">
              <ExperienceAnalyticsTags />
              {/* <Empty description={mrIntl("ExperienceAnalytics.no_data")} /> */}
            </Tabs.TabPane>}
          </Tabs>
            
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ExperienceAnalytics;
