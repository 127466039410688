import React, { useEffect } from "react";
import { Select, Row, Col, Form, Input, Radio, InputNumber } from "antd";
import { FormRenderer } from "mr_react_framework";
import { buildOptionsArr, getUniqueId } from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useSelector } from "react-redux";
import { activeAdjustedExperienceSelector } from "../../Experiences/selector";

const ShuffleOptionsChildComponent = (props) => {
  let { getFieldValue, sourceValues, formInstance } = props
  console.log("props inside question segment att ==>", props);
  console.log("sourceValues", sourceValues);
  const questionType = getFieldValue(["question_segment_attributes", "question_type"]);
  let finalRender = null;
  const mrIntl = useTranslate();

  if (questionType === "mcq_single" || questionType === 'mcq_multiple' || questionType === 'true_false' || questionType === 'cke_fill_dropdown') {
    finalRender = <FormRenderer
      formObj={{
        type: "string",
        title: mrIntl("SettingsForm.shuffle_option"),
        // title: "Shuffle options",
        widget: "SwitchWidget",
        valuePropName: "checked",
        wrapClassName: "text-left",
        widgetConfig: {
          size: 'small',
          defaultChecked: false,
          // checkedChildren: "1",
          // unCheckedChildren: "0",
        }
      }}
      propertyKey={["settings", "shuffle"]}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  return finalRender;
}

export const PrintLinesCountChildComponent = (props) => {
  let { getFieldValue, sourceValues, formInstance } = props;

  let activeExperience = useSelector(activeAdjustedExperienceSelector());
  const mrIntl = useTranslate()
  const questionType = getFieldValue(["question_segment_attributes", "question_type"])

  const experienceSettings = activeExperience?.settings || {};

  let finalRender = null;
  if(experienceSettings.mode === "paper" && questionType === "cke_subjective") {
    finalRender = <FormRenderer
    formObj={{
      type: "string",
      title: mrIntl("SettingsForm.number_of_lines_to_print"),
      widget: "InputNumberWidget",
      wrapClassName: "text-left",
      widgetConfig: {
        style: {
          width: "250px"
        },
        min: 0
      }
    }}
    propertyKey={["settings", "print_lines_count"]}
    sourceValues={sourceValues}
    formInstance={formInstance}
  />
  }
  return finalRender;
};

const settingsForm = (mrIntl) => {
  return {
    type: "object",
    properties: {
      // include_text_answer:{
      //   type: "string",
      //   title: "Include Text Answer",
      //   default: false,
      //   span: 6,
      //   widget: "CheckboxWidget",
      //   widgetConfig: {
      //     label: 'Include Text Answer',
      //   },
      // },
      include_explanation: {
        type: "string",
        title: mrIntl("SettingsForm.include_explanation"),
        span: 6,
        widget: "SwitchWidget",
        valuePropName: "checked",
        wrapClassName: "text-left",
        widgetConfig: {
          size: 'small',
        }
        // widget: "CheckboxWidget",
        // valuePropName: "checked",
        // widgetConfig: {
        //   label: 'Include Explanation',
        // },
      },
      include_markscheme: {
        type: "string",
        title: mrIntl("SettingsForm.include_markscheme_title"),
        span: 6,
        widget: "SwitchWidget",
        valuePropName: "checked",
        wrapClassName: "text-left",
        widgetConfig: {
          size: 'small',
        }
        // widget: "CheckboxWidget",
        // valuePropName: "checked",
        // widgetConfig: {
        //   label: 'Include Explanation',
        // },
      },
      // mode: {
      //   type: "string",
      //   title: "QC mode",
      //   default: "exam_mode",
      //   span: 4,
      //   widget: "SwitchWidget",
      //   valuePropName: "checked",
      //   wrapClassName: "text-left",
      //   widgetConfig: {
      //     size: 'small',
      //   }
      //   // widget: "RadioGroupWidget",
      //   // widgetConfig: {
      //   //   options: [
      //   //     {
      //   //       label: "Quick check",
      //   //       value: "quick_check",
      //   //     },
      //   //     {
      //   //       label: "Exam mode",
      //   //       value: "exam_mode",
      //   //     },
      //   //   ],
      //   // },
      // },
      // marking_mode: {
      //   type: "string",
      //   title: "Marking mode",
      //   default: "test",
      //   span: 6,
      //   widget: "RadioGroupWidget",
      //   widgetConfig: {
      //     options: [
      //       {
      //         label: "Weighted Marking",
      //         value: "weighted_marking",
      //       },
      //     ],
      //   },
      // },
      // allow_embed: {
      //   type: "string",
      //   title: "Allow embed",
      //   default: true,
      //   span: 4,
      //   widget: "SwitchWidget",
      //   valuePropName: "checked",
      //   wrapClassName: "text-left",
      //   widgetConfig: {
      //     size: 'small',
      //   }
      //   // widget: "RadioGroupWidget",
      //   // widgetConfig: {
      //   //   options: [
      //   //     {
      //   //       label: "Yes",
      //   //       value: true,
      //   //     },
      //   //     {
      //   //       label: "No",
      //   //       value: false,
      //   //     },
      //   //   ],
      //   // },
      // },
      // number_of_attempts: {
      //   type: "string",
      //   title: "Number of attempts",
      //   default: "any",
      //   span: 6,
      //   widget: "SelectWidget",
      //   widgetConfig: {
      //     options: buildOptionsArr(['any', "1", "2", "5", "10"]),
      //   },
      // },

      shuffle_options_parent: {
        type: "string",
        span: 12,                 // Increased from 6 to ensure it occupies the full width of the row, allowing `print_lines_count` to appear on the next line for better layout and readability.
        dependsOn: ["question_segment_attributes", "question_type"],
        children: ShuffleOptionsChildComponent,
      },
      print_lines_count: {
        type: "string",
        span: 6,
        placeholder: mrIntl("SettingsForm.enter_number_of_lines_to_print"),
        dependsOn: ["question_segment_attributes", "question_type"],
        children: PrintLinesCountChildComponent ,
      },
      // extra: {
      //   type: "string",
      //   title: "Optional",
      //   default: "test",
      //   span: 6,
      //   widget: "CheckboxGroupWidget",
      //   widgetConfig: {
      //     options: [
      //       {
      //         label: "Allow to include geogebra",
      //         value: "allow_to_include_geogebra",
      //       }
      //     ],
      //   },
      // }
    }
  }
}

export default settingsForm;