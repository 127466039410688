import React, { useState, useEffect } from "react";
// import "./GroupItemDetail.scss";
import { Route, useRouteMatch } from "react-router-dom";
import { Tabs, Button, Row, Col } from "antd";
import Segments from "/src/views/Segments/Segments";
import {
  segmentsSelector,
} from "/src/views/Segments/selector";
import { useSelector } from "react-redux";
import { checkMob } from "/src/lib/utils/helperMethods";
import { isPinnedSelector } from "/src/views/Segments/selector";
import { activeTopicIdSelector, topicsSelector } from "../selector";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { isNewGenerateQuestionsWithAI } from "../../GenerateAI/generateAIHelperMethods";

const TopicItemDetail = (props) => {
  console.log("TopicItemDetail props", props);
  const {
    item,
    history,
    experienceViewMode,
    rearrangeMode,
    experienceProps,
    mobileUploadMode,
    embedded,
    readingModeEnabled,
    ...nestedProps
  } = props;
  const { path, url } = useRouteMatch();
  const activeTopicId = useSelector(activeTopicIdSelector());
  const topics = useSelector(topicsSelector())
  const isPinned = useSelector(isPinnedSelector());
  const topicSegments = useSelector(segmentsSelector(activeTopicId));
  const enabledFeatures = useSelector(enabledFeaturesSelector());

  const newGenerateQuestionsWithAI = isNewGenerateQuestionsWithAI(enabledFeatures);
  console.log("activeTopicId 32", activeTopicId, topicSegments, props);

  let [activeTopicIdx, setActiveTopicIdx] = useState(0)
  let [parentIdxs, setParentIdxs] = useState([])
  // let parentIdxs = []
  useEffect(() => {
    if (activeTopicId) {
      let idx = topics.findIndex((topic) => topic.id == activeTopicId)
      let newParentIdxs = [...parentIdxs]
      newParentIdxs[0] = idx
      setParentIdxs(newParentIdxs)
      setActiveTopicIdx(idx)
    }
    console.log("activeTopicId 15 parentIdxs", parentIdxs);
  }, [activeTopicId, rearrangeMode])

  console.log("parentIdxs 46", parentIdxs);

  const segmentsComponent = (
    <Route
      path={`${path}`}
      render={(routeProps) => (
        <Segments
          doNotReloadOnUpdate={
            // added length condition to cater to case when just changed from draft to published and then try to open another topic - would show empty
            // when draft or when topicSegments.length == 0, doNotReloadOnUpdate should be false
            experienceProps.item.status === "draft" ? false : true
            // experienceProps.item.status !== "draft" ? true : false
          }
          experienceViewMode={experienceViewMode}
          experienceProps={experienceProps}
          mobileUploadMode={mobileUploadMode}
          embedded={embedded}
          rearrangeMode={rearrangeMode}
          readingModeEnabled={readingModeEnabled}
          topicProps={props}
          parentId={item.id}
          activeTopicIdx={activeTopicIdx}
          parentIdxs={parentIdxs}
          nestingLevel={1}
          params={{
            by_experience_id: item.experience_id, // see APL-2880  - no need here
            by_parent_id: item.id,
          }}
          createUpdateParams={{
            experience_id: item.experience_id, // see APL-2880  - no need here
            parent_id: item.id,
          }}
          libraryModalProps={props.libraryModalProps}
          isRoute={false}
          {...routeProps}
          config={{
            list: {
              shouldExecuteRequest: false // not fetching since assuming already in redux from topic show call 
            }
          }}
        />
      )}
    />
  );

  let isMobile = checkMob();
  return (
    <React.Fragment>
      {/* <h3 id={`topic-${item.id}`}>{item.section_segment_attributes.title}</h3> */}
      {/* <span>Topic show - {JSON.stringify(item)}</span> */}
      <Row>
        <Col
          span={isMobile || isPinned || embedded || newGenerateQuestionsWithAI ? 24 : 20}
          className={isMobile ? "segments-list mobile" : "segments-list"}
        >
          {/* {!isMobile && 
          <h3 style={{"paddingLeft":"20px"}} id={`topic-${item.id}`}>{item.section_segment_attributes.title}</h3>
        } */}
          {/* <h4>Segments</h4> */}

          
          {/* item comes first, activeTopicId comes later */}
          {/* IMP added (activeTopicId === item.id)  condition to render only when activeTopicId changes and becomes equalt to item.id - this way topicSegments is also as per activeTopicId and doNotReload condition is handled well */}
          {activeTopicId && (activeTopicId === item.id) && segmentsComponent}
        </Col>
      </Row>
    </React.Fragment>
  );
};

TopicItemDetail.defaultProps = {};

TopicItemDetail.propTypes = {};

export default TopicItemDetail;
