import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { put } from "redux-saga/effects";
import { message } from "/src/components/UI/AntdAppHelper";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "org_integration",
  actionNames: ["FETCH", "CREATE", "UPDATE", "DELETE","VALIDATE"],
});

const initialState = fromJS({
  org_integrations: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

export function* validateIntegrationSaga(action) {
  console.log("validateSaga", action.payload)
  try {
    yield put(actions.validateStart());
    const response = yield axiosInstance.instance.post(
      "/integrations/edlink/verify",
      action.payload
    );
    console.log("validateSaga", response.data)
    message.success("Integration validated successfully")
    yield put(actions.validateSuccess({ data: response.data}));
    if(action.options && action.options.successCallback){
      action.options.successCallback(response)
    }
  } catch (error) {
    console.log("validateSaga=====>", error.response.data.error)
    yield put(actions.validateFail({ error: error.response.data.error }));
    if(action.options.errorCallback){
      action.options.errorCallback()
    }
  }
}

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState);
export const watchOrgIntegration = reduxCrud.generateWatchSaga({
  [actionTypes.VALIDATE_ORG_INTEGRATION]: validateIntegrationSaga,
});

export default reduxCrud;
