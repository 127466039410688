import { Space } from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { displayPoints } from "/src/lib/utils/helperMethods";
import React from "react";

const ExperienceUserOverallTitle = (props) => {
  const { rubricType, exp_cas, experience, show_converted_al } = props
  let pointsToRender
  const mrIntl = useTranslate()

  if (rubricType === "points") {
    pointsToRender = <span>({displayPoints(experience.points, false, true)})</span>
  } else if(exp_cas){
    pointsToRender = exp_cas.map((ca) => {
      let cTitle = <sup>{ca.custom_fields.title}</sup>;
      let cPoints
      if (rubricType === "myp_achievement_level" || show_converted_al) {
        cPoints = 8
      } else {
        cPoints = displayPoints(ca.points, false, true);
      }

      let finalRender = <>{cTitle}{cPoints}&nbsp;</>
      return finalRender
    })
  }

  return (
    <Space direction="vertical" size={0}>
      <span>{show_converted_al ? mrIntl("ExperienceUserOverallTitle.equivalent_level") : mrIntl("ExperienceUserOverallTitle.overall")}</span>
      <span>{pointsToRender}</span>
    </Space>
  )
}

export default ExperienceUserOverallTitle;