import {css} from 'styled-components';
import theme from './theme';

// Iterate through the sizes and create a media template
const media = Object.keys(theme.sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${theme.sizes[label]}px) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export default media;
