import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import {
  Segment,
  SegmentData,
  showConfirmModal,
} from "/src/components/UI/Segment/UIHelper";
import { getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import { cloneDeep } from "lodash";
import Modal from "antd/lib/modal/Modal";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const UseDesmosGraph = (props) => {
  console.log("Desmos Props ==>", props);
  const { desmosConfig } = props;
  const Desmos = window.Desmos;
  const desmosRef = useRef();
  var calculator
  useEffect(() => {
    if (Desmos) {
      calculator = Desmos.GraphingCalculator(desmosRef.current, desmosConfig);
      calculator.setState(desmosConfig.dgBase64Data)
    }
    if (desmosConfig.setCalculator) {
      desmosConfig.setCalculator(calculator)
    }
  }, []);
  return (
    <div
      className={desmosConfig.desmosClass}
    >
      <div ref={desmosRef} graphId={desmosConfig.graphId}
        style={{ width: "95vw", height: "90vh", margin: "auto" }}
      ></div>
    </div>
  )
};

const DesmosGraph = (props) => {
  console.log("Desmos graph props ==>", props);
  const {
    question_type,
    segmentSettings,
    setRenderMath,
    segmentFooterAttributes,
  } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);

  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  if (createMode) {
    parsedJSONObject = {
      segment_data: {
        appName: "Desmos Graph",
        dgVersion: "1.8.3",
        graphId: getUniqueId(),
        pngBase64:
          "https://d3kgcbih1bmz00.cloudfront.net/direct_uploads/attachments/01FPWT43TX46HWMZ6B0S2G3HV1.png",
      },
      segment_version: "1.0.0",
    };
    if (props.question_type == "desmos_graph") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }
  const mrIntl = useTranslate()
  const [state, setState] = useState(parsedJSONObject);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    updatedCount: 0,
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitchValue: false,
  });
  const screenSettings = props.screenSettings || {};
  const [initialData, setInitialData] = useState(parsedJSONObject);
  const [graphingCalculator, setGraphingCalculator] = useState();

  let currentJSON;
  if (presentationMode) {
    currentJSON = parsedJSONObject;
  } else {
    currentJSON = state;
  }

  currentJSON = currentJSON || {};

  const unique_segment_identifier = props.unique_segment_identifier || props.id;
  const segment_data = currentJSON?.segment_data || {};
  const id = segment_data.graphId || getUniqueId();
  const graphId = `${id}_${unique_segment_identifier}`;
  const dgBase64Data = segment_data.dgBase64Data;
  const pngBase64 = segment_data.pngBase64 || "https://d3kgcbih1bmz00.cloudfront.net/direct_uploads/attachments/01FPWT43TX46HWMZ6B0S2G3HV1.png";

  const desmosConfig = {
    setCalculator: setGraphingCalculator,
    graphId: graphId,
    dgBase64Data:dgBase64Data,
    settingsMenu: presentationMode ? false : true,
    expressionsTopbar: presentationMode ? false : true,
    qwertyKeyboard: presentationMode ? false : true,
    images: false,
    folders: false,
    desmosClass: presentationMode ? "desmos-graphing" : "",
  }

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  useEffect(() => {
    if (stateSetting.dgModal) {
      setState(parsedJSONObject)
      setInitialData(parsedJSONObject)
    }
  }, [stateSetting.dgModal])


  const saveState = (state) => {
    setState(state);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };
  const updateGraphData = function (dgBase64Data) {
    if (graphingCalculator) {
      graphingCalculator.setState(dgBase64Data);
      
    }
  };

  const resetQuestion = () => {
    let student_json = cloneDeep(props.student_json);
    const newState = student_json || {
      segment_data: {},
      segment_version: "1.0.0",
    };
    updateGraphData(newState.segment_data.dgBase64Data);
    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    saveState(newState);
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const setJSONAttempted = (value) => {
    if (answerMode && !attemptStatus.json_attempted) {
      setAttemptStatus({
        ...attemptStatus,
        json_attempted: true,
      });
    }
  };

  const showDGGraph = !pngBase64;

  const openModal = () => {
    setStateSetting({
      ...stateSetting,
      dgModal: true,
    });
  };
  const handleOk = () => {
    if (!graphingCalculator) {
      showConfirmModal({
        title: answerMode
          ? mrIntl("DesmosGraph.something_went_answer_can_not_be_saved")
          : mrIntl("DesmosGraph.something_wrong_refresh_page"),
        onOk: () => {
          setStateSetting({
            ...stateSetting,
            dgModal: false,
          });
        },
        onCancel: () => {
          return;
        },
        mrIntl: mrIntl,
      });
      return;
    }

    if (createMode || answerMode) {
      const newDGBase64Data = graphingCalculator.getState();
      const newPNGBase64 = graphingCalculator.screenshot();

      const newSegmentData = {
        ...segment_data,
        graphId: id,
        dgBase64Data: newDGBase64Data,
        pngBase64: newPNGBase64,
      };

      const newState = {
        ...state,
        segment_data: newSegmentData,
      };

      saveState(newState);
    }

    setStateSetting({
      ...stateSetting,
      dgModal: false,
    });
    if (answerMode) {
      setJSONAttempted(true);
    }
  };

 
  const handleCancel = () => {
    if (presentationMode) {
      setStateSetting({
        ...stateSetting,
        dgModal: false,
      });
    } else {
      showConfirmModal({
        title: mrIntl("CommonText.are_you_sure_title"),
        okText: mrIntl("CommonText.yes"),
        onOk: () => {
          updateGraphData(initialData.segment_data.dgBase64Data)
          saveState(initialData);
          setStateSetting({
            ...stateSetting,
            dgModal: false,
          });
        },
        onCancel: () => {
          return;
        },
        mrIntl: mrIntl
      });
    }
  };

  let dgAppletDiv = (
    <UseDesmosGraph desmosConfig={desmosConfig}></UseDesmosGraph>
  );

  let dgImageDiv = (
    <div className="graph-container">
      <img
        src={pngBase64}
        width={200}
        height={200}
        onClick={() => openModal()}
      />
    </div>
  );

  return (
    <Segment>
      <SegmentData key={`segment-data-${props.unique_segment_identifier}`}>
        <Modal
          width="95%"
          open={stateSetting.dgModal}
          className={"full-screen-modal graph-modal"}
          closable={false}
          centered={true}
          // closeIcon={false}
          footer={[
            <Button
              key={`cancel-${graphId}`}
              onClick={() => handleCancel()}
              type="default"
            >
            {!presentationMode ? <MrTranslate id={"CommonText.cancel"}/> : <MrTranslate id={"CommonText.close"}/>}
            </Button>,
            !presentationMode ? <Button
              key={`ok-${graphId}`}
              onClick={() => handleOk()}
              type="primary"
            >
              <MrTranslate id={"CommonText.save"}/>
            </Button> : "",
          ]}
        >
          {dgAppletDiv}
        </Modal>

        {showDGGraph && !stateSetting.dgModal ? dgAppletDiv : dgImageDiv}

      </SegmentData>
      <SegmentItemFooter
        key={`segment-item-footer-${props.unique_segment_identifier}`}
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        resetQuestion={resetQuestion}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default DesmosGraph;
