import { Button, Col, Row, Select, Space, Steps, Upload } from 'antd';
import { SelectProgram } from '/src/components/AppSpecific/SelectSubject/SelectSubject';
import React, { useEffect, useState } from 'react';
import {
  DownloadOutlined,
  UploadOutlined
} from "@ant-design/icons";
import MrTranslate, { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { openUrl, userRoleMap } from '/src/lib/utils/helperMethods';
import { MrSelect } from "mr_react_framework";
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux';
import { currentUserSelector } from '/src/views/Auth/Login/selector';
import loadable from "@loadable/component";
import axios from "axios";
import { message } from '/src/components/UI/AntdAppHelper';
import { isEmpty } from 'lodash';
import {actions as genericActions} from "/src/App/genericRedux";
import dayjs from 'dayjs';
const xlsxLib = loadable.lib(() =>
  import(
    /* webpackChunkName: "xlsx", webpackPrefetch: true */ "xlsx"
  )
);

const { Option, OptGroup } = Select

const SelectEndTerm = MrSelect({
  actions: genericActions,
  resourceName: "terms",
  config: {
    url: "/terms",
    isJsx: true,
    processData: (data, props) => {
      console.log("data select groups terms", data);
      console.log("props select groups", props);

      let allTermGroups = [];
      let finalRender;
      data.map((item) => {
        if (item.children.length !== 0) {
          let optGroupChildren = [];
          item.children.forEach((child) => {
            let label = `${child.label} (${dayjs(child.starts_on).format(
              "MMM DD"
            )} - ${dayjs(child.ends_on).format("MMM DD")})`;
            optGroupChildren.push(<Option value={child.id}>{label}</Option>);
          });
          let optGroup = (
            <OptGroup label={item.label}>{optGroupChildren}</OptGroup>
          );
          allTermGroups.push(optGroup);
          finalRender = allTermGroups;
        }
      });
      return <>{finalRender}</>;

    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"CommonText.title_select_end_term"} />,
    },
  },
});


const SelectStartTerm = MrSelect({
  actions: genericActions,
  resourceName: "terms",
  config: {
    url: "/terms",
    isJsx: true,
    processData: (data, props) => {
      console.log("data select groups terms", data);
      console.log("props select groups", props);

      let allTermGroups = [];
      let finalRender;
      data.map((item) => {
        if (item.children.length !== 0) {
          let optGroupChildren = [];
          item.children.forEach((child) => {
            let label = `${child.label} (${dayjs(child.starts_on).format(
              "MMM DD"
            )} - ${dayjs(child.ends_on).format("MMM DD")})`;
            optGroupChildren.push(<Option value={child.id}>{label}</Option>);
          });
          let optGroup = (
            <OptGroup label={item.label}>{optGroupChildren}</OptGroup>
          );
          allTermGroups.push(optGroup);
          finalRender = allTermGroups;
        }
      });
      return <>{finalRender}</>;
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"FileUploadSteps.title_select_start_term"} />,
    },
  },
});


const SelectRole = MrSelect({
  config: {
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      let arr = []
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          arr.push({label: userRoleMap[key], value: key})
        }
      }
      return arr
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id = {"UsersFilter.select_role"}/>,
      // style: {width: "100%"},
      allowClear: true
    },
  }
})

const FileUploadSteps = (props) => {
  const { bulkImportConfig, setBulkImportConfig, setExcelData, singl, singleResourceName, clearLocalState, currentOrgId, setDisableNextButton } = props;
  const [loading, setLoading] = useState(false);
  const orgRoles = useSelector(state => {
    console.log("state user filter", singleResourceName, state, bulkImportConfig.role);
    return state.login.get("org").roles
  })
  const mrIntl = useTranslate();
  const defaultExcelURL = {
    student: "https://d3kgcbih1bmz00.cloudfront.net/direct_uploads/attachments/01HY2QBTVWK13ETAXX5K2QJ3XH/Bulk%20import%20student.xlsx",
    teacher: "https://d3kgcbih1bmz00.cloudfront.net/direct_uploads/attachments/01HY2QD9TXWTPA848DFBW3D19G/Bulk%20import%20teacher.xlsx",
    admin: "https://d3kgcbih1bmz00.cloudfront.net/direct_uploads/attachments/01HY2QEACJDYKAYA20249HVXPB/Bulk%20import%20admin.xlsx",
    parent: "https://v2res1-stag.assessprep.com/direct_uploads/attachments/01J87QN449NQBR18MN0XFDC07Y/Bulk%20import%20parent.xlsx",
    class: "https://v2res1-stag.assessprep.com/direct_uploads/attachments/01J72ZNS28GSGNKA645KJXFAR9/Bulk%20import%20classes%20%285%29.xlsx"
  }
  const currentUser = useSelector(currentUserSelector())
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if(clearLocalState) {
  //     clearLocalState()
  //   }
  // }, []);
  
  const handleFileUpload = (file) => {
    console.log("File uploaded ====>", file);
    setBulkImportConfig({
      ...bulkImportConfig,
      file: file
    });
    uploadFile(file);
    readExcelFile(file);
    clearLocalState()
  };

  const handleChange = (value, key, currentStep) => {
    console.log("value handleChange ===>", value, key, currentStep);
    setBulkImportConfig(prevConfig => {
      const newConfig = {
        ...prevConfig,
        [key]: value
      };
  
      if (key === 'programId') {
        newConfig.start_term = null;
        newConfig.end_term = null;
        if(setDisableNextButton) {
          setDisableNextButton(true)
        }
      }
      return newConfig;
    });
  }

  const handleDownloadExitingUsers = () => {
    setLoading(true)
    dispatch(actions.exportUsersToExcel({
      by_org_id: currentUser.org_id,
      by_org_programme_id: bulkImportConfig.programId,
      by_role: bulkImportConfig.role,
    }, {
      successCallback: (url) => {
        window.open(url)
        setLoading(false)
      },
      errorCallback: () => {
        setLoading(false)
      }
    }))
  }
  const uploadFile = (file) => {
    console.log("File uploaded ====>", file);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        token: token,
      },
    };

    const url =
      (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "attachments";
    const formData = new FormData();
    formData.append("file", file);

    axios.post(url, formData, config).then(
      (response) => {
        console.log("Returned response ==>", response);
        let responseData = response.data
        console.log("responseData ==>", responseData);
        setBulkImportConfig({
          ...bulkImportConfig,
          file: {
            uid: responseData.id,
            name: file.name,
            status: "done",
            url: responseData.url,
          },
        })
      },
      (error) => {
        message.error(
          `${file.name} file upload failed. Check if file is valid`
        );
      }
    );
  }

  const readExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const xlsx = await xlsxLib.load();
      const workbook = xlsx.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });
      console.log("jsonData =====>", jsonData);
      setExcelData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleDefaultFileDownload = () => {
    if(singleResourceName === 'users') {
      openUrl(defaultExcelURL[bulkImportConfig.role]);
    } else {
      openUrl(defaultExcelURL['class']);
    }
  }

  const fileUpload = (
    <Upload
      accept=".xlsx, .csv"
      fileList={bulkImportConfig.file ? [bulkImportConfig.file] : []}
      className="upload-bulk-students-file"
      beforeUpload={(file) => {
        handleFileUpload(file);
      }}
      onRemove={() => {
        setBulkImportConfig({
          ...bulkImportConfig,
          file: null
        });
        setExcelData([]);
      }}
    >
      {isEmpty(bulkImportConfig.file) ? <Button icon={<UploadOutlined />}>{mrIntl("FileUploadSteps.attach_file")} (.xlsx)</Button> : null}
    </Upload>
  );

  const steps = []
  if (singleResourceName === "groups") {
    steps.push({
      title: <>
        {mrIntl("FileUploadSteps.select_program")} <SelectProgram value={bulkImportConfig.programId} onChange={(value) => handleChange(value, "programId", 2)} config={{ params: {by_org_id: currentOrgId}}}/>
      </>,
      description: mrIntl("FileUploadSteps.you_can_only_upload_classes_for_one_program_at_a"),
    });
    if (bulkImportConfig.programId) {
      steps.push({
        title: <>
          {mrIntl("FileUploadSteps.select_start_term_and_end_term")} <SelectStartTerm value={bulkImportConfig.start_term} config={{
            params: {
              by_termable_id: bulkImportConfig.programId,
              by_org_id: currentOrgId
            },
            widgetConfig: {
              style: { width: "250px" },
            }
          }} onChange={(value, extra) => {
            console.log("label =======>", extra);
            
            handleChange(value, "start_term")
          }} />&nbsp;
          <SelectEndTerm value={bulkImportConfig.end_term} config={{
            params: {
              by_termable_id: bulkImportConfig.programId,
              by_org_id: currentOrgId
            },
            widgetConfig: {
              style: { width: "250px" },
            }
          }} onChange={(value, extra) => {
            handleChange(value, "end_term")
          }} />
        </>
      })
    }
  } if (singleResourceName === "users") {
    steps.push({
      title: <>
        {mrIntl("FileUploadSteps.select_role")} <SelectRole value={bulkImportConfig.role} onChange={(value) => handleChange(value, "role", 1)} config={{ widgetConfig: { options: orgRoles } }} />
      </>,
      description: '',
      current: 0,
    })
    if(bulkImportConfig.role === "student") {
      steps.push({
        title: <>
          {mrIntl("FileUploadSteps.select_program")} <SelectProgram value={bulkImportConfig.programId} onChange={(value) => handleChange(value, "programId", 2)} config={{ params: {by_org_id: currentOrgId}}} />
        </>,
        description: mrIntl("FileUploadSteps.you_can_only_upload_bulk_import_config_role_for_one_program_at_a", {role: bulkImportConfig.role}),
      });
    }
  }
  steps.push({
    title: mrIntl("FileUploadSteps.download_our_template"),
    current: 1,
    description: <Space direction="vertical">
      {mrIntl("FileUploadSteps.use_our_spreadsheet_template_to_add_or_update_students_to")}
      <Row>
        <Col span={6}>
          {mrIntl("FileUploadSteps.add_new")}
          <p></p>
          {singleResourceName === "users" ?
            mrIntl("FileUploadSteps.update_existing") : null
          }
        </Col>
        <Col span={18}>
          <Button type="link" icon={<DownloadOutlined />} onClick={() => handleDefaultFileDownload()}>{mrIntl("FileUploadSteps.download_blank_template")}</Button>
          {singleResourceName === "users" ? 
          <Button type="link" onClick={() => handleDownloadExitingUsers()} loading={loading} icon={<DownloadOutlined />}>{mrIntl("FileUploadSteps.download_template_with_existing")} {singleResourceName === "groups" ? "classes" : "student"}</Button> : null}
        </Col>
      </Row>
    </Space>,
  },
    {
      title: mrIntl("FileUploadSteps.upload_your_completed_file"),
      description: <Space direction="vertical">
        <span>{fileUpload}</span>
      </Space>,
    })

  return (
    <Steps direction="vertical" items={steps} className='upload-steps-view'/>
  );
};

export default FileUploadSteps;