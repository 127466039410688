import * as Sentry from "@sentry/react";

export function buildLogMessage(options) {
  console.log("buildLogMessage options ====>", options['action'], options);
  const action = options['action']
  const action_by = options['action_by']
  const previous_value = options['previous_value']
  const current_value = options['current_value'] || {}

  const logMsgMap = {
    opened_on_browser: { // Done
      msg: `Student ${current_value.status} the test via browser. Browser: ${current_value.browser_details}, OS: ${current_value.os_details}`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    opened_on_browswer_with_online_permission: { // Done
      msg: `Student ${current_value.status} the lockdown test in browser with security mode. Browser: ${current_value.browser_details}, OS: ${current_value.os_details}`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    opened_in_app: { // Done
      msg: `Student ${current_value.status} the test via Lockdown App. App: ${current_value.app_version}, OS: ${current_value.os_details}`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    launched_app: {
      msg: `Student launched the ${current_value.app_version} via browser. Browser: ${current_value.browser_details}, OS: ${current_value.os_details}`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    joined_external_video_call: { // Done
      msg: `Student opened the video call in a new tab. Link: ${current_value.link}`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    // joined_ap_video_monitoring: {
    //   msg: ``,
    //   category:  'monitor_student_actions',
    //   log_type: "experience",
    // },
    back_to_home_before_starting_test: { // Done
      msg: `Student navigated to home screen by clicking 'Back to Home' from the Join page.`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    back_to_home_using_browser_back_button: { // Done
      msg: `Student navigated to home screen by clicking 'browser's back button'/'Lockdown app back button'.`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    test_started_in_browser: { // Done
      msg: `Student started the test from the Join page via browser. Browser: ${current_value.browser_details}, OS: ${current_value.os_details}`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    test_started_in_app: { // Done
      msg: `Student started the test from the Join page via Lockdown App. App version: ${current_value.app_version}, OS: ${current_value.os_details}`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    online_status_change: { // Done
      msg: `Student ${current_value.status}`,
      category: 'monitor_student_actions',
      log_type: "experience",
    },
    focus_lost_on_blur: { // Done
      msg: `Student clicked outside the test window, triggering a focus lost alert.`,
      category: "monitor_student_actions",
      log_level: "warning",
      log_type: "experience",
    },
    focus_restored: { // Done
      msg: `Test resumed after focus loss. Duration : ${current_value.duration} Explanation: "${current_value.explanation}"`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    focus_lost_in_mac: { // Done
      msg: `Session interrupted. Power button was pressed during the exam, leading to a user switch or system restart. This may be considered as an attempt to circumvent exam security measures.`,
      category: "monitor_student_actions",
      log_level: "warning",
      log_type: "experience",
    },
    resume_requested: { // Done
      msg: `Student requested to resume the test.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    resume_request_cancelled: {
      msg: `Student cancelled the request to resume the test.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    test_ended_by_student: { // Done
      msg: `Test ended by student. ${current_value.remaining_time ? `Time remaining: ${current_value.remaining_time}` : ""}`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    test_ended_on_time_completed: { // Done
      msg: `Test ended automatically as the allotted time was over.`,
      category: 'monitor_student_actions',
      log_type: "experience",
    },
    time_completed_on_end_view: {
      msg: `Allotted time was over on the 'End Test' page.`,
      category: 'monitor_student_actions',
      log_type: "experience",
    },
    test_submitted: { // Done
      msg: "Test submitted.",
      category: "monitor_student_actions",
      log_type: "experience",
    },
    back_to_test_from_end_view: { // Done
      msg: `Student returned to test from the End Test page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    quit_app_from_join_view: {
      msg: `Student quit the lockdown app from the 'Join Test' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    quit_app_after_end: { // Done
      msg: `Student quit the lockdown app from the 'End Test' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    quit_app_before_resuming: { // Need to add message
      msg: `Student quit the lockdown app from the 'Resume' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    quit_app_while_error_in_page: { // Need to add message
      msg: `Student quit the lockdown app from the 'Error 500' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    quit_app_after_submit: { // Done
      msg: `Student quit the lockdown app from the 'Submit Test' page.`,
      category: 'monitor_student_actions',
      log_type: "experience",
    },
    quit_app_from_home_page: {
      msg: `Student quit the lockdown app from the 'Test Home' page.`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    quit_app_from_login_page: {
      msg: `Student quit the lockdown app from the 'Login' page.`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    back_to_home_after_submit: { // Done
      msg: `Student navigated to home screen by clicking 'Back to Home' from the Submit page.`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    log_out_after_submit: { // Done
      msg: `Student logged out after submitting the test.`,
      category: 'monitor_student_actions',
      log_type: "experience",
    },
    offline_db_status: { // Done
      msg: `Local backups not available. ${current_value.status}`,
      category: 'monitor_student_actions',
      log_type: "experience",
    },
    use_here_triggered: { // Done
      msg: `Student started the test on a different window/device, deactivating the test session on the previous window/device.`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    use_here_clicked: { // Done
      msg: `Student clicked the "Use Here" button. The test session on the other window/device was deactivated. Current active test session: ${current_value.current_device_browser_details}.`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    opened_in_upload_mode: { // Done
      msg: `Student opened the test in upload mode. Browser: ${current_value.browser_details}, OS: ${current_value.os_details}`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    resume_allowed_student: { // Done
      // msg: `${action_by} allowed ${current_value} to resume`,
      msg: `Student successfully resumed the test`,
      category: "monitor_teacher_actions",
      log_type: "experience",
    },
    resume_allowed_teacher: { // Done
      // msg: `${action_by} allowed ${current_value} to resume`,
      msg: `${action_by} approved the student's request to resume the test`,
      category: "monitor_teacher_actions",
      log_type: "experience",
    },
    resume_declined_teacher: { // Done
      // msg: `${action_by} did not allow ${current_value} to resume`,
      msg: `${action_by} declined the student's request to resume the test`,
      category: "monitor_teacher_actions",
      log_type: "experience",
    },
    extra_time_added: {
      msg: `${action_by} set an extra time of ${current_value.time} minutes`,
      category: 'monitor_teacher_actions',
      log_level: "info",
      log_type: "experience",
    },
    accommodation_changed: {
      msg: `${action_by} changed the accommodation settings. ${current_value}`,
      category: 'monitor_teacher_actions',
      log_level: "info",
      log_type: "experience",
    },
    student_allowed_in_browser: {
      msg: `Security mode was adjusted for student ${current_value.allowed_in_browser ? "allowed" : "restricted"} to take test online by ${action_by}`,
      category: 'monitor_teacher_actions',
      log_level: "info",
      log_type: "experience",
    },
    announcement_made: { // Done
      msg: `${action_by} made an announcement: "${current_value.announcement}"`,
      category: "monitor_teacher_actions",
      log_type: "experience",
    },
    paused_test: {
      msg: `Teacher paused the test.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    unpaused_test: {
      msg: `Teacher unpaused the test.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    lockdown_disabled: { 
      msg: `Student ended the lockdown session.`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    lockdown_disabled_from_join_view: {
      msg: `Student ended the lockdown session from the 'Join Test' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    lockdown_disabled_from_start_test: {
      msg: `Student ended the lockdown session from the 'Exam' page.`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    lockdown_disabled_from_resume_view: { // Done
      msg: `Student ended the lockdown session from the 'Resume' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    lockdown_disabled_from_end_view: { // Done
      msg: `Student ended the lockdown session from the 'End Test' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    lockdown_disabled_from_submit_view: { // Done
      msg: `Student ended the lockdown session from the 'Submit Test' page.`,
      category: 'monitor_student_actions',
      log_type: "experience",
    },
    lockdown_disabled_while_error_in_page: { 
      msg: `Student ended the lockdown session from the 'Error 500' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    mac_native_quit: { 
      msg: `Student Quit the app.`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    mac_native_quit_from_join_view: {
      msg: `Student Quit the app from the 'Join Test' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    mac_native_quit_from_start_test: {
      msg: `Student Quit the app from the 'Exam' page.`,
      category:  'monitor_student_actions',
      log_type: "experience",
    },
    mac_native_quit_from_resume_view: { // Done
      msg: `Student Quit the app from the 'Resume' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    mac_native_quit_from_end_view: { // Done
      msg: `Student Quit the app from the 'End Test' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    mac_native_quit_from_submit_view: { // Done
      msg: `Student Quit the app from the 'Submit Test' page.`,
      category: 'monitor_student_actions',
      log_type: "experience",
    },
    mac_native_quit_while_error_in_page: { 
      msg: `Student Quit the app from the 'Error 500' page.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    security_interrupted_in_aac_mode_quitting_app: {
      msg: `The lockdown security has been interrupted on the student's device, due to unintended key presses or system issues. The app quit automatically. The student will need to rejoin the test.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    security_interrupted_in_aac_mode: {
      msg: `The lockdown security has been interrupted on the student's device, due to unintended key presses or system issues.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    resuming_after_security_interrupted_in_aac_mode: {
      msg: `Student choose the Security Resume option after security interrupted on the student's device.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    quit_after_security_interrupted_in_aac_mode: {
      msg: `Student choose the Quit option after security interrupted on the student's device.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    security_could_not_be_turned_on: {
      msg: `Security couldn't be turned on, and the test has not opened on launching of app.`,
      category: "monitor_student_actions",
      log_type: "experience",
    },
    // force_submit: { // BE From ExperienceUser
    //   msg: `${action_by} force submitted the test.`,
    //   category: "monitor_teacher_actions",
    //   log_type: "experience",
    // },
    // reset_with_duration: { // BE From ExperienceUser
    //   msg: `${action_by} reset the submission and allotted an extra duration of ${current_value.time} minutes. `,
    //   category: "monitor_teacher_actions",
    //   log_type: "experience",
    // },
    // reset_untimed: { // BE From ExperienceUser
    //   msg: `${action_by} reset the submission in the untimed assessment.`,
    //   category: "monitor_teacher_actions",
    //   log_type: "experience",
    // },
    // reopen_uploads: { // BE From ExperienceUser
    //   msg: `${action_by} reopened uploads.`,
    //   category: "monitor_teacher_actions",
    //   log_type: "experience",
    // },

    // test_opened_on_browser: {
    //   msg:  `Student ${current_value.status} the test on ${current_value.device_name}`,
    //   category: 'monitor_student_actions',
    //   log_type: "experience",
    // },
    // test_opened_in_app: {
    //   msg: `Student ${current_value.status} the test in ${current_value.device_name}`,
    //   category: 'monitor_student_actions',
    //   log_type: "experience",
    // },
    // test_started: {
    //   msg: `Test started`,
    //   category: "monitor_student_actions",
    //   log_type: "experience",
    // },    
    focus_lost_on_go_back: {
      msg: `Focus lost. Student pressed back to home button.`,
      category: "monitor_student_actions",
      log_level: "warning",
      log_type: "experience",
    },
    // resumed_test_after_focus_lost: {
    //   msg: `Resumed the test after focus was lost for ${current_value.time}. Explanation given: ${current_value.explanation}`,
    //   category: "monitor_student_actions",
    //   log_type: "experience",
    // },
    print_started: {
      msg: `${current_value.action_msg} ${current_value.experience_name} ${current_value.excel_format ? "data" : ""} was exported to ${current_value.excel_format || current_value.pdf_format} ${current_value.excel_format ? '' : `(${current_value.print_option})`} ${current_value.export_type} by ${action_by}`,
      category: 'actions',
      log_type: "experience",
    },
    post_submission_to_mb: {
      msg: `${current_value.send_to_mb_msg} ${current_value.experience_name} sent to MB ${current_value.student_name} by ${action_by}`,
      category: 'actions',
      log_type: "experience",
    },
    post_to_mb_cancelled: {
      msg: `Sending ${current_value.send_to_mb_msg_cancel} ${current_value.experience_name} to MB  ${current_value.student_name} cancelled by ${action_by}`,
      category: 'actions',
      log_type: "experience",
    },
    print_cancelled: {
      msg: `Export of ${current_value.action_msg} ${current_value.experience_name} ${current_value.excel_format || current_value.pdf_format} ${current_value.export_type} was cancelled by ${action_by}`,
      category: 'actions',
      log_type: "experience",
    },
  }
  
  const logMsg = logMsgMap[action] 

  if (!logMsg) {
    Sentry.captureException(new Error(`Log message not found for action: ${action}`))
  }
  return logMsg || {}
}

export function buildLogObject(currentUser, log, options) {
  const { orgId, experienceId, internetStatus, userIp } = options
  const currentTime = Date.now();
  const currentUserId = currentUser.id
  const logId = `${currentUserId}_${currentTime}`;

  const logMsg = buildLogMessage({
    ...log,
    action_by: `${currentUser.name} (${currentUser.role})`,
  })
  console.log("currentUser.name ====>", currentUser, currentUser.name, userIp);
  let logObj = {
    log_id: logId,
    org_id: orgId, // handle guest user accounts
    experience_id: experienceId,
    item_id: log.item_id || experienceId || "",
    msg: logMsg.msg || "",
    log_level: logMsg.log_level || "info",
    log_type: logMsg.log_type || "experience",
    category: logMsg.category || "",
    user_id: log.user_id || parseInt(currentUserId), // Action created on user
    user_ids: log.user_ids || [log.user_id || parseInt(currentUserId)],
    user_names: [log.user_name || currentUser.name],
    by_user_id: currentUserId, // Action created by user
    user_ip: userIp || "",
    user_email: currentUser.email || "",
    internet_status: internetStatus || "online",
    updated_at: currentTime,
    created_at: currentTime,
  };

  if (log.url) {
    logObj = {
      ...logObj,
      url: log.url,
    }
  }
  console.log("logObj ======>", logObj, currentUser);
  return logObj
}