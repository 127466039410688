import React from "react";
import styled from "styled-components";

const Loader = () => {
  return (
    <StyledLoader>
      <LoaderBlueBgX />
    </StyledLoader>
  );
};

export default Loader;

const StyledLoader = styled.div`
  animation: spin 1.25s linear infinite;
  width: 31px;
  height: 31px;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const LoaderBlueBgX = () => {
  return (
    <svg
      className={"blue"}
      width="52px"
      height="52px"
      viewBox="0 0 52 52"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="0%"
          y1="100%"
          x2="106.755154%"
          y2="0%"
          id="linearGradient-118"
        >
          <stop stopColor="#43DAF0" offset="0%"></stop>
          <stop stopColor="#395BF8" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="(xx)-N----Loader-Spinner"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Loader-Spinner---Large-(White-Background)"
          transform="translate(-162.000000, -308.000000)"
        >
          <g
            id="MB/Content-Elements/Loader-Spinner/Large---Gray-Background"
            transform="translate(161.000000, 307.000000)"
          >
            <g
              id="Loader-Spinner-52px"
              transform="translate(3.000000, 3.000000)"
            >
              <circle
                id="Background"
                stroke="#FFFFFF"
                strokeWidth="3"
                cx="24"
                cy="24"
                r="24"
              ></circle>
              <path
                d="M24,0 C10.745166,0 0,10.745166 0,24"
                id="Loader"
                stroke="url(#linearGradient-118)"
                strokeWidth="3"
                strokeLinecap="round"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};