import React from "react";
import PropTypes from 'prop-types';
import { List, Card } from "antd";
// import ActionBtns from "/src/components/ActionBtns/ActionBtns";
const UserListItem = (props) => {
  console.log("props in users==>", props.dataSource);
  const { item, edited, deleted, actionBtns } = props;
  return (
    <List.Item>
      <Card title={item.key}>
        {actionBtns}
        {item.name} {JSON.stringify(item.settings)}
      </Card>
    </List.Item>
  );
};
UserListItem.defaultProps = {};

UserListItem.propTypes = {};

export default UserListItem;
