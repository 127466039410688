import React, { useEffect } from "react";
import DefaultCKE from "/src/components/UI/CKEditor/DefaultCKE"
import { CKETextContentTools } from "/src/components/UI/CKEditor/CKEConfig"
import { InputArea, CustomErrorMessage} from '/src/components/UI/Segment/UIHelper'
import { validateText } from "/src/lib/utils/helperMethods"

const CKETextContent = (props) => {
  console.log("CKE Text Content props ==>", props);

  const { ckeConfig = {}, writingAssistantConfig = {}, extraClass = "", editorFooterConfig } = props
  const wordCount = ckeConfig.wordCount || {}
  
  const ckeTools = {
    ...CKETextContentTools,
    ...ckeConfig
  }

  const validationRules = {
    isLimitExceeded: wordCount.isLimitExceeded
  }
  const validation = validateText(props.value, validationRules)

  const valid = validation.success
  let errorMessage = null
  let className = `multiline-input-area ${extraClass}`
  
  if (!valid) {
    className += ' show-error'
    errorMessage = validation.messages[0]
  }
  if(props.className) {
    className += ` ${props.className}`
  }

  if (!props.value) {
    className += ' custom-error'
  }

  if(writingAssistantConfig.enabled) {
    className += ' show-placeholder'
  }

  const onTextChanged = (data) => {
    props.onChange(data)
  }

  return (
    <>
      <InputArea
        className={className}
      >
        <DefaultCKE
          itemId={`cke-text-content-${props.itemId}`}
          tools={ckeTools}
          data={props.value}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          placeholder={props.placeholder}
          onTextChanged={(data) => onTextChanged(data)}
          writingAssistantConfig={writingAssistantConfig}
          editorFooterConfig={editorFooterConfig}
        >
        </DefaultCKE>
        <CustomErrorMessage className="error-message" message={errorMessage} />
      </InputArea>
     </>
  );
};
export default CKETextContent;