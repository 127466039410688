import React, { useState, useEffect } from "react";
import { Modal, Radio } from "antd";

import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { addItemToArray, removeItemFromArray } from "/src/views/Segments/InteractiveHelpers";
import { AddButton, DeleteButton, CheckBox, InputArea, TextArea, CustomErrorMessage } from '/src/components/UI/Segment/UIHelper'
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { CKETextOptionTools } from "/src/components/UI/CKEditor/CKEConfig";
import { getUniqueId } from "/src/lib/utils/helperMethods";

export const validateGap = (gap, correctType) => {
  const validation = {
		success: false,
		messages: []
  }
  
  let hasError, isAtleastOneSelected, allHaveContent = true
  gap.items.map((item) => {
    if (!item.content) {
      allHaveContent = false
    }

    if (item.checked) {
      isAtleastOneSelected = true
    }
  })

  if (correctType && !isAtleastOneSelected) {
    hasError = true
    validation.messages.push(<MrTranslate id={"CommonText.atleast_one_correct_answer_required_msg"}/>)
  }

  if (!allHaveContent) {
    hasError = true
  }

  if (!hasError) {
    validation.success = true
  }

  return validation
}

const GapAnswerModal = (props) => {
  console.log("CKE text input props ==>", props)
  const { currentGap, setCurrentGap, formSetting, setFormSetting, onGapModalSubmit, setTriggerRestoreLastSelectionRange } = props
  const mrIntl = useTranslate()

  let ckeTools = {
    ...CKETextOptionTools,
    autoFocusAtEnd: true,
  }

  const validateGapAnswerModal = (gap) => {
    const validation = validateGap(gap, props.correctType)

    if (validation.success) {
      setFormSetting({
        ...formSetting,
        hasError: false,
        messages: validation.messages,
      })
    } else {
      setFormSetting({
        ...formSetting,
        hasError: true,
        messages: validation.messages,
        validateTrigger: 'onChange',
      })
    }
    return validation
  }

  const handleOk = () => {
    const validation = validateGapAnswerModal(currentGap)

    if (!validation.success) {
      return
    }

    if (onGapModalSubmit) {
      delete currentGap.invalid
      onGapModalSubmit(currentGap)
    }

    setFormSetting({
      ...formSetting,
      visible: false
    })
    setTriggerRestoreLastSelectionRange(getUniqueId())
  }

  const handleCancel = () => {
    setFormSetting({
      ...formSetting,
      visible: false
    })
    setTriggerRestoreLastSelectionRange(getUniqueId())
  }

  const addItem = () => {
    let newItems = currentGap.items
    let extra = formSetting.gapItemsSetting
    newItems = addItemToArray(newItems, extra);
    setCurrentGap({
      ...currentGap,
      items: newItems
    })
  }

  const removeItem = (index) => {
    let newItems = currentGap.items
    newItems = removeItemFromArray(newItems, index);

    if (!newItems) {
      return
    }

    setCurrentGap({
      ...currentGap,
      items: newItems
    })
  }

  const selectCurrentItem = (index) => {
    let isAtleastOneSelected = false
    const newItems = currentGap.items
    newItems.map((item, i) => {
      if (i == index) {
        if (props.correctType == 'single') {
          item.checked = true
        } else {
          item.checked = !item.checked
        }
			} else {
        if (props.correctType == 'single') {
          item.checked = false
        }
			}
			if (item.checked) {
				isAtleastOneSelected = true
			}
      return item
    })

    const newGap = {
      ...currentGap,
      items: newItems
    }
    setCurrentGap(newGap)

    if (formSetting.validateTrigger == 'onChange') {
      validateGapAnswerModal(newGap)
    }
  }

  const onTextChanged = (index, data) => {
    const newItems = currentGap.items
    newItems[index].content = data

    const newGap = {
      ...currentGap,
      items: newItems
    }
    setCurrentGap(newGap)

    if (formSetting.validateTrigger == 'onChange') {
      validateGapAnswerModal(newGap)
    }
  }

  return (
    <Modal
      key={`gap-answer-modal-${currentGap.id}`}
      title={formSetting.title}
      open={formSetting.visible}
      closable={false}
      maskClosable={false}
      keyboard={false}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      className={`gap-answer-modal ${formSetting.hasError && 'gap-answer-modal-has-error'}`}
    >
      {currentGap.items.map((item, index) => (
        <TextArea key={`text-area-${item.id}`}>
          {props.correctType == 'multiple' && <CheckBox
            key={`checkbox-area-${item.id}`}
            checked={item.checked}
            onClick={(e) => selectCurrentItem(index)}
          >
          </CheckBox>}
          {props.correctType == 'single' && <Radio
            key={`radio-${item.id}`}
            checked={item.checked}
            onClick={(e) => selectCurrentItem(index)}
          >
          </Radio>}
          <InputArea key={`input-area-${item.id}`} className={`singleline-input-area ${!item.content && 'custom-error'}`}>
            <BasicCKE
              key={`basic-cke-${item.id}`}
              data={item.content}
              placeholder={item.placeholder}
              tools={ckeTools}
              onTextChanged={(data) => onTextChanged(index, data)}
            ></BasicCKE>
            <CustomErrorMessage className="error-message" message= {mrIntl("CommonText.required_msg")}></CustomErrorMessage>
          </InputArea>
          <DeleteButton
            clicked={(e) => removeItem(index)}
            disabled={currentGap?.items.length == 1}
            tooltipTitle={currentGap?.items.length == 1 ? mrIntl("CommonText.minimum_limit_reached") : ""}
          >
          </DeleteButton>
        </TextArea>
      ))}
      <AddButton
        clicked={(e) => addItem()}
        className="add-option-button"
      />
      {formSetting.hasError && formSetting.messages.map((message) => (
        <CustomErrorMessage style={{display: 'block', color: '#ff4d4f'}} message={message}></CustomErrorMessage>
      ))}
    </Modal>
  );
};
export default GapAnswerModal;

