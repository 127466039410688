import React from 'react';
import { Card, Row, Col, Typography, Space, Button } from 'antd';
import { LeftOutlined, SendOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '/src/views/Auth/Login/selector';
import {
  attachmentsCountSelector,
  attemptedCountSelector,
  currentViewSelector,
  submitExperienceLoadingSelector,
} from '../../FirestoreInteractions/selector';
import AttemptStatus from '../../Components/AttemptStatus';
import QuitBtn from '/src/components/UI/QuitBtn';
import "./ViewCard.scss";
import { checkIPAD, checkMob } from '/src/lib/utils/helperMethods';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import Spinner from '/src/components/UI/Spinner/Spinner';

const { Title, Text } = Typography;
const isIPAD = checkIPAD();
const isMobile = checkMob();
const isMobileOrIpad = isIPAD || isMobile

export const CardHeader = ({ experience }) => {
  const questionsCount = experience.attemptable_questions_count
  const currentUser = useSelector(currentUserSelector());
  const attemptedCount = useSelector(attemptedCountSelector);
  const attachmentsTotalCount = useSelector(attachmentsCountSelector);
  const mrIntl = useTranslate();

  const attemptPercentage = questionsCount
    ? ((attemptedCount / questionsCount) * 100).toFixed(0)
    : 0;

  return (
    <Row className={`views-card-info-container ${isMobileOrIpad && "views-card-info-ipad"}`}>
      <Col span={7} className={"text-left"}>
        <Title level={5}>{experience.name}</Title>
      </Col>
      <Col span={10} className={"text-center"}>
        <Title level={4} strong className="info-student-name">{currentUser.name}</Title>
      </Col>
      <Col span={isMobileOrIpad ? 6 : 7} className={`text-right assessment-progress-ipad`}>
        <Text type="secondary"><span className='progress-percent'>• {mrIntl("ViewCard.progress")}</span> {attemptPercentage}%</Text>
        <Text className='uploaded-files'>• {mrIntl("ViewCard.files")} {attachmentsTotalCount}</Text>
        <Text type="secondary"><span className='given-answer'>• {mrIntl("ViewCard.answered")}</span> {attemptedCount}/{questionsCount}</Text>
      </Col>
    </Row>
  )
};

export const CardBody = ({ experience }) => {
  const currentUser = useSelector(currentUserSelector());

  return (
    <Row className="question-grid">
      <Col span={24} className="text-center">
        {experience?.topics ? <AttemptStatus
          experienceTopics={experience.topics}
          experienceId={experience.id}
          userId={currentUser.id}
          wide={true}
          showFileIcon={true}
          hideGroupQuestionIndexPathLabel={true}
        /> : <Spinner />}
      </Col>
    </Row>
  )
};

export const CardFooter = ({
  quitButtonAction,
  footerLeftComponent, 
  footerCenterComponent,  
  footerRightComponent
}) => {
  const currentViewFromUserInfo = useSelector(currentViewSelector);
  const mrIntl = useTranslate()

  return (
    <Row className="view-card-footer-container">
      <Col span={6} className="text-left">
        {footerLeftComponent}
        <QuitBtn 
          key="join-view-quit-button"
          withLog={true} 
          action={quitButtonAction} 
          className="quit-button"
          quitButtonIcon={<CloseOutlined />}
          quitButtonText={currentViewFromUserInfo === "submittedTest" && mrIntl("ViewCard.quit_app")}
        />
      </Col>
      <Col span={12} className="text-center">
        {footerCenterComponent}
      </Col>
      <Col span={6} className="text-right">
        {footerRightComponent}
      </Col>
    </Row>
  )
}

// NOTE: After successful PROD deployment and Feature Release, remove the ViewCard.jsx file and rename ViewCard1.jsx file to ViewCard.jsx
const ViewCard = ({
  experience,
  isSubmitExperienceLoading,
  footerContent,
  footerCenterComponent,
  footerLeftComponent,
  footerRightComponent
}) => {
  const currentUser = useSelector(currentUserSelector());
 
  return (
    <Card className="views-card-container">
      <CardHeader experience={experience} />
      <CardBody experience={experience} />
      <CardFooter 
        footerCenterComponent={footerCenterComponent}
        footerLeftComponent={footerLeftComponent}
        footerRightComponent={footerRightComponent}
      />
    </Card>
  );
};

export default ViewCard;