import React, { useState } from "react";
import { Col, Row } from "antd";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import MediaShow from "/src/components/UI/Media/MediaShow";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { cloneDeep } from "lodash";
import {
  Segment,
  ShowText,
  ShowTextContent,
  ShowTextFile,
} from "/src/components/UI/Segment/UIHelper";
import { getUniqueId, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { CKETextOptionTools } from "/src/components/UI/CKEditor/CKEConfig";

export function checkResponses(question_json, answer_json) {
  console.log("Question and Answer json", question_json, answer_json);
  if (!question_json || !answer_json) {
    return;
  }

  let q_segment_data = question_json.segment_data;
  let a_segment_data = answer_json.segment_data;

  let q_answers = q_segment_data ? q_segment_data.answers : [];
  let a_answer = a_segment_data ? a_segment_data.answer : [];
  q_answers.map((q_item) => {
    if (
      q_answers &&
      a_answer &&
      removeHTMLTagsFromText(q_item.content) ==
        removeHTMLTagsFromText(a_answer.content)
    ) {
      a_answer.correct = true;
    }
  });
  return answer_json;
}

const ConnectAnswer = (props) => {
  const {
    createMode,
    state,
    saveState,
    segment_data,
    hints,
    answers,
    answer,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
    answerMode,
    presentationMode,
    autoCheckMode,
    attemptStatus,
    setAttemptStatus,
    screenSettings,
    stateSetting,
    setStateSetting,
  } = props;
  const mrIntl = useTranslate()
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  const [myAnswer, setMyAnswer] = useState(state)

  function validateAttempted(json, data) {
    let isAtleastOneChanged = attemptStatus.json_attempted;
    if (data) {
      isAtleastOneChanged = true;
    }

    if (isAtleastOneChanged != attemptStatus.json_attempted) {
      setAttemptStatus({
        ...attemptStatus,
        json_attempted: isAtleastOneChanged,
      });
    }
  }

  const onChangedText = (data) => {
    const newAnswer = answer;
    newAnswer.content = data;
    const newSegmentData = {
      ...segment_data,
      answer: newAnswer,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
    if (answerMode) {
      validateAttempted(newState, data);
    }
  };

  let className = "";
  if (autoCheckMode || stateSetting.quickCheckModeEnabled) {
    if (answer.correct || stateSetting.showCorrectAnswerSwitchValue) {
      className += "correct-answer";
    } else {
      className += "incorrect-answer";
    }
  }

  const checkAnswer = (check = false) => {
    if (check) {
      let question_json = cloneDeep(props.teacher_json);
      let answer_json = cloneDeep(state);
      const newState = checkResponses(question_json, answer_json);
      setMyAnswer(state);
      saveState(newState);
    }

    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: check,
    });

    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const showCorrectAnswer = (value) => {
    let question_json, answer_json;
    if (value) {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(props.teacher_json);
    } else {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(myAnswer);
    }

    const newState = checkResponses(question_json, answer_json);

    saveState(newState);
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: true,
      showCorrectAnswerSwitchValue: value,
    });
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const resetQuestion = () => {
    console.log("Student json ===>", props.student_json);
    const newState = cloneDeep(props.student_json);
    saveState(newState);
    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: false,
    });
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  return (
    <Segment>
      <Row className="connect-content">
        {hints.map((item, index) => (
          <Col span={12}>
            <ShowTextContent key={`show-text-container-${item.id}`}>
              <ShowText>
                {item.content && <RenderHtml text={item.content}></RenderHtml>}
                {item.files && (
                  <ShowTextFile>
                    <MediaShow
                      file={item.files && item.files[0]}
                      removeIcon={false}
                    ></MediaShow>
                  </ShowTextFile>
                )}
              </ShowText>
            </ShowTextContent>
          </Col>
        ))}
      </Row>
      <Row className={"connect-content"}>
        {answerMode && !stateSetting.quickCheckModeEnabled ? (
          <BasicCKE
            key={`basic-cke-${answer.id}`}
            tools={CKETextOptionTools}
            itemId={`ckeditor-${answer.id}`}
            data={answer && answer.content}
            onTextChanged={(data) => onChangedText(data)}
            className="correct-answer-cke"
            placeholder={mrIntl("ConnectAnswer.enter_answer")}
          ></BasicCKE>
        ) : (
          <ShowText className={className}>
            {!createMode && (
              <ShowTextContent
                dangerouslySetInnerHTML={{ __html: answer.content }}
              ></ShowTextContent>
            )}
          </ShowText>
        )}
      </Row>

      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        resetQuestion={resetQuestion}
        checkAnswer={checkAnswer}
        showCorrectAnswer={showCorrectAnswer}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default ConnectAnswer;
