
import { displayPoints, isStringEmpty } from "/src/lib/utils/helperMethods";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { generateClassName } from "/src/views/Experiences/ExperienceHelperMethods";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import { MessageOutlined, EditOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip } from "antd";
import { BiCommentDetail } from "react-icons/bi";

const NonMemoizedExperienceUserTotalPoints = (props) => {
  console.log("ExperienceUserOverall =====>", props);
  const { eu, rubricType } = props
  let finalRender = []
  let finalPoints
  if(rubricType === "myp_achievement_level") {
    const euSca = eu.submission_criterium_associations_attributes || []
    const allNull = euSca.every(
      (sca) => sca.points === null
    );
    console.log("allNull =====>", allNull, eu);
    if(allNull) {
      finalPoints = "-"
    } else {
      let points = 0
      euSca.forEach((sca) => {
        if(sca.points) {
          points += parseFloat(sca.points)
        }
      })
      finalPoints = points
    }
  } else {
    finalPoints = displayPoints(eu.points)
  }

  finalRender.push(
    <span className="ur-cell-points-span">{finalPoints}</span>
  )

  return (
    <Space
      key={`total-points-${eu.id}`}
      className={`exp-users-list-points-wrapper question-cell-color-${generateClassName(eu, rubricType, "experience_user")}`}
      size={0}
      >
        {finalRender}
      </Space>
  )
}

const ExperienceUserTotalPoints = memo(NonMemoizedExperienceUserTotalPoints)
export default ExperienceUserTotalPoints;