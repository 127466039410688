import React from "react";
// import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
// import { actions as segmentActions } from "/src/views/Segments/redux";
// import { actions as topicActions } from "/src/views/Segments/Topics/redux";

export const getMaxEngagementPoints = (segment, engagementConfig) => {
  let maxPoints = 0;
  if(segment.segment_type === "question"){
    if(segment.settings.is_game){
      maxPoints = engagementConfig.game.enabled ? engagementConfig.game.points : 0
    }else{
      maxPoints = engagementConfig.question.enabled ? engagementConfig.question.points : 0
    }
  }
  if(segment.segment_type === "resource" && segment.resource_segment_attributes.resource_type === "video"){
    maxPoints = engagementConfig.video.enabled ? engagementConfig.video.points : 0
  }
  return maxPoints;
}
// NOT USED NOW - moved to segments redux
// export const updateParentsPoints = (
//   updatedSegment, 
//   activeExperience, 
//   topics, 
//   segments,
//   dispatch = null,
// ) => {
//   let updatedParentsPoints = updatedSegment.parents_points
//   updatedParentsPoints.map((parent) => {
//     console.log("segment create callback  updatedParentsPoints parent", parent);
//     let parentSegment, actionsToUse
//     if(parent.segment_type === "section"){
//       parentSegment = topics.find(t => t.id === parent.id)
//       actionsToUse = topicActions
//     }else{
//       // to handle nested segments - points should bubble up
//       parentSegment = segments.find(s => s.id === parent.id)
//       actionsToUse = segmentActions
//     }
//     console.log("segment create callback  updatedParentsPoints parentSegment", segments, parentSegment)
//     if(parentSegment){
//       dispatch(actionsToUse.showSuccess({data: {
//         segment: {
//           ...parentSegment, 
//           points: parent.points, 
//         }
//       }}))
//     }
//   })

//   dispatch(experienceReduxActions.showSuccess({data: {
//     experience: {
//       ...activeExperience, 
//       points: updatedSegment.exp_points, 
//       criterium_associations: updatedSegment.exp_criterium_associations, 
//       questions_count: updatedSegment.exp_questions_count}
//   }}))

// };

