import React, { useEffect, useState,useRef } from "react";

import { Row, Col, Image, Tooltip, Button, Card, Rate, Alert, Space, Modal } from "antd";
import { CheckBox, ShowText, ShowTextContent } from "/src/components/UI/Segment/UIHelper";
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent";
import { getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { fetchWithStream, getAIServiceBaseURL, getAlphabetAtIndex, getFromLS, getUniqueId, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";
import MediaShow from "/src/components/UI/Media/MediaShow";
import ReactPlayer from "react-player";
import parse from "html-react-parser";
import EnlargableImage from "/src/components/UI/Image/EnlargableImage";
import { currentUserSelector, enabledFeaturesSelector, partnerNameSelector } from "/src/views/Auth/Login/selector";
import { useDispatch, useSelector } from "react-redux";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { activeAdjustedExperienceSelector, aiAssistantSettingsSelector, printModeSelector } from "/src/views/Experiences/selector";
import { ImMagicWand } from "react-icons/im";
import { FaRegStopCircle } from "react-icons/fa";
import axios from 'axios';
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import MagicButton from "../GenerateAI/MagicButton";
import { PlusOutlined } from "@ant-design/icons";
import { printOptionsSelector } from "/src/views/Experiences/selector";
import { isEmpty } from "lodash";
import RateAiResult from "../GenerateAI/RateAiResult";
import UseAISolutionsButton from "../GenerateAI/UseAISolutionButton";
import { aiModelSelector } from "/src/views/Orgs/selector";
import TypingEffect from "/src/views/Experiences/ExperienceShow/Components/TypingEffect/TypingEffect";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { message } from "/src/components/UI/AntdAppHelper";
import AIContentImprovementFooter from "./AIContentImprovementFooter/AIContentImprovementFooter";


const TextAnswerExplanation = (props) => {
  const { segmentSettings, triggerStateUpdate, setRenderMath, questionType, options, modal, showModal = false, setShowModal, segment = {}, formInstance } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);

  let answerExplanation;
  if (createMode) {
    answerExplanation = props.value || "";
  } else {
    answerExplanation = props.answer_explanation;
  }
  const mrIntl = useTranslate()
  const [state, setState] = useState(answerExplanation);
  const [loading, setLoading] = useState(false)
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const partnerName = useSelector(partnerNameSelector());
  const printMode = useSelector(printModeSelector());
  const printOptions = useSelector(printOptionsSelector());
  const aiModel = useSelector(aiModelSelector());
  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const sseRef = useRef(null);
  const dispatch = useDispatch();
  let experience = useSelector(activeAdjustedExperienceSelector())
  const aiAssistantSettings = useSelector(aiAssistantSettingsSelector());

  let customFields = props.formInstance && props.formInstance.getFieldValue(["custom_fields"]);
  const currentQuestionContent = props.questionContent || segment.question_segment_attributes?.content || "";
  console.log("TextAnswerExplanation props", experience);


  const [explanationLoading, setExplanationLoading] = useState(false);
  const [showExplanationForm, setShowExplanationForm] = useState(true);
  const [streamData, setStreamData] = useState({
    showCustomPrompt: false,
    showAIText: false,
    content: null,
    responseCompleted: false
  })

  const [triggerCKEDataUpdate, setTriggerCKEDataUpdate] = useState()
  let promptList = ["Simplify", "Make concise", "Make detailed", "Add step by step breakdown"];
  let allowImproveAiContent = enabledFeatures.allow_improve_ai_content;
  let showAiFooter = allowImproveAiContent ? (state && !streamData.isStreaming) : (streamData.showAIText && !streamData.isStreaming);
  let aiGeneratedContent = allowImproveAiContent ? state : streamData.content;

  let content;
  if (presentationMode) {
    content = props.answer_explanation;
  } else {
    content = state;
  }
  const isQuestionContentExist = currentQuestionContent !== "" ? true : false;

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        props.onChange(state);
      }
    }
  }, [stateUpdatedCount]);
  // the component's state is updated to reflect the new value of `answerExplanation`.
  // This is crucial for keeping the component's state in sync with the prop, especially
  // if `answerExplanation` is fetched or updated from an external source or parent component.
  useEffect(() => {
    setState(answerExplanation)
  },[answerExplanation])
  const saveState = (newState) => {
    setState(newState);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const onTextChanged = (data) => {
    const newState = data;
    console.log("onTextChanged==>", data);
    saveState(newState);
  };

  const stopGeneratingExplanation = () => {
    setStreamData((prevData) => ({
      ...prevData,
      isStreaming: false,
      showAIText: false,
    }));
    setExplanationLoading(false)
    if (sseRef && sseRef.current) {
      sseRef.current.abortSse();
    }
  };

  const handleCancel = () => {
    setShowModal(false)
    stopGeneratingExplanation()
    setState(answerExplanation);
  }

  const generateExplanation = async (extraDataToPost = {}) => {
    setExplanationLoading(true)
    const token = getFromLS("token");

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    // let questionContent = removeHTMLTagsFromText(props.questionContent, true)
    let questionContent = currentQuestionContent
    // if(questionType === "mcq_single" || questionType === "mcq_multiple") {
    //   let optionsString = options.map((option, index) => {
    //     const correctAnswer = option.checked ? "(Correct answer)" : "";
    //     return `\n${getAlphabetAtIndex(index)}. ${removeHTMLTagsFromText(option.content, true)} ${correctAnswer}`;
    //   }).join("");
    //   questionContent += optionsString
    // }
    let dataToPost = {
      ...extraDataToPost,
      "solution_type": "solution",
      "question_content": questionContent
    }

    if (enabledFeatures.ai_assistant_settings && aiAssistantSettings?.authoring) {
      dataToPost.output_language = aiAssistantSettings?.authoring?.output_language;
      dataToPost.explanation_length = aiAssistantSettings?.authoring?.explanation_length;
    }
    if(aiModel) {
      dataToPost.model = aiModel
    }
    if(streamData.showCustomPrompt) {
      dataToPost.prompt = streamData.customPromptContent;
    }
    try {
      const fetchOptions = {
        url: `${getAIServiceBaseURL()}/generate-solutions`,
        dataToPost: dataToPost,
        setLoading: setExplanationLoading,
        content: "",
        sseRef: sseRef,
        successCallback: (data, responseStatus) => {
          setStreamData((prevData) => ({
            showAIText: true,
            content: data,
            isStreaming: true,
            responseCompleted: responseStatus === "stop"
          }))
        },
        setTriggerCKEDataUpdate: setTriggerCKEDataUpdate
      }
      fetchWithStream(fetchOptions);

    } catch (error) {
      console.error(error);
    }
  }

  const handleUseButtonClick = () => {
    let newCustomFieldsObject =
      customFields && typeof customFields === "object" ? { ...customFields } : {};
    newCustomFieldsObject = {
      ...newCustomFieldsObject,
      ai_used: {
        ...newCustomFieldsObject.ai_used,
        answer_explanation: true,
      },
    };
    if (formInstance?.setFieldValue) {
      formInstance.setFieldValue(["custom_fields"], newCustomFieldsObject);
    }
    if (!allowImproveAiContent) {
      setStreamData((prevData) => ({
        ...prevData,
        showAIText: false,
      }));
    }
  };

  const handleMagicButtonClick = (extraDataToPost = {}) => {
    if (streamData.isStreaming) {
      stopGeneratingExplanation();
    } else {
      generateExplanation(extraDataToPost);
    }
  };

  const handleDiscard = () => {
    setStreamData({ ...streamData, showAIText: false });
  };

  let finalRender = [];
  if ((createMode && showExplanationForm) || showModal) {
    if ((questionType === "cke_subjective" || questionType === "mcq_single" || questionType === "mcq_multiple") && (enabledFeatures.generate_explanation_with_ai || import.meta.env.VITE_MODE === "development")) {
      if(streamData.showAIText) {
        if (streamData.isStreaming) {
          // Doing this to render the typing effect again when streamData gets false or when response completed, as it was previously storing the local state of the text/streamData.content which we were sending as a propdue to which the content was not getting cleared when click generate again
          finalRender.push(
            <TypingEffect
              isStreamStarted={streamData.isStreaming}
              text={streamData.content}
              typingSpeed={20}
              setIsStreamStarted={() => {
                if (allowImproveAiContent) {
                  handleUseButtonClick()
                  onTextChanged(streamData.content)
                  setStreamData((prevData) => ({
                    ...prevData,
                    isStreaming: false,
                    showAIText: false,
                  }));
                } else {
                  setStreamData((prevData) => ({
                    ...prevData,
                    isStreaming: false,
                  }))
                }
              }}
              className={"text-streaming-box"}
              responseCompleted={streamData.responseCompleted}
            />
          );
        } else {
          finalRender.push(
            <RenderHtml
              text={streamData.content}
              extraClassname="text-streaming-box"
            />
          );
        }
      } else {
        finalRender.push(
          <CKETextContent
            ckeConfig={{
              autoFocus: true,
              triggerCKEDataUpdate: triggerCKEDataUpdate
            }}
            placeholder={mrIntl("TextAnswerExplanation.provide_stepbystep_solution_or_reasoning_to_help_students_learn_it")}
            writingAssistantConfig={{
              // enabled: content ? true : false,
              enabled: false, // Removed this as we are not using the writing assistant in explanation. We want to use Improve - it has context of the question and the answer
            }}
            value={content}
            onChange={(data) => onTextChanged(data)}
          ></CKETextContent>
        );
      }
      if (streamData.showCustomPrompt) {
        finalRender.push(
          <CKETextContent
            ckeConfig={{ triggerCKEDataUpdate: triggerCKEDataUpdate }}
            placeholder={mrIntl("TextAnswerExplanation.add_custom_prompt")}
            value={streamData.customPromptContent}
            onChange={(data) => setStreamData({
              ...streamData,
              customPromptContent: data
            })}
          ></CKETextContent>
        );
      }
      finalRender.push(
      // <Tooltip title={mrIntl("TextAnswerExplanation.auto_generate_explanation")}>
        <div>
          {showAiFooter ? <AIContentImprovementFooter
            onTextChanged={onTextChanged}
            handleUseButtonClick={handleUseButtonClick}
            onMagicButtonClick={handleMagicButtonClick}
            handleDiscard={handleDiscard}
            promptList={promptList}
            showImproveButton={aiGeneratedContent && !streamData.isStreaming && isQuestionContentExist}
            aiGeneratedContent={aiGeneratedContent}
            isStreaming={streamData.isStreaming}
            showUseAndDiscardButton={!allowImproveAiContent}
            improveInputPlaceholder={mrIntl("TextAnswerExplanation.type_instructions_to_improve_the_explanation_or_select_from_the")}
          /> :
            <>
              {currentUser.role === "qb_author" && <CheckBox onChange={(e) => setStreamData({
                ...streamData,
                showCustomPrompt: e.target.checked
              })} disabled={explanationLoading} />}
              <MagicButton
                tooltipTitle={streamData.isStreaming ? mrIntl("CommonText.stop_generating") : !isQuestionContentExist ? mrIntl("TextAnswerExplanation.question_content_required_to_generate_explanation") : mrIntl("TextAnswerExplanation.auto_generate_explanation")}
                shape="default"
                type="text"
                // size="small"
                // text={mrIntl("TextAnswerExplanation.auto_generate_explanation")}
                loading={explanationLoading}
                feature_code="generate_explanation_with_ai"
                onClick={() => handleMagicButtonClick()}
                icon={
                  streamData.isStreaming ? <FaRegStopCircle /> : <ImMagicWand />
                }
                disabled={!isQuestionContentExist}
                extraClassName="float-right"
              />
            </>}
          {/* <Alert
            showIcon
            type="info"
            message={mrIntl("TextAnswerExplanation.use_aigenerated_explanations_as_guides_review_and_adjust_to_match")}
          /> */}
          {/* <Space>
            {streamData.showAIText && (
              <>
                {!streamData.isStreaming && <RateAiResult generatedData={streamData.content} action="generate_solutions" />}
                {!streamData.isStreaming && 
                  <UseAISolutionsButton
                  loading={streamData.isStreaming} 
                  onTextChanged={() => onTextChanged(streamData.content)}
                  setStreamData={setStreamData}
                  onClick={() => {
                    let newCustomFieldsObject =
                    customFields && typeof customFields === "object"
                        ? { ...customFields }
                        : {};
                    newCustomFieldsObject = {
                      ...newCustomFieldsObject,
                      ai_used: {
                        ...newCustomFieldsObject.ai_used,
                        answer_explanation: true,
                      },
                    };
                    if(props.formInstance?.setFieldValue) {
                      props.formInstance.setFieldValue(["custom_fields"], newCustomFieldsObject);
                    }
                  }}
                />} */}
                {/* <Button
                  type="primary"
                  shape="round"
                  loading={streamData.isStreaming}
                  style={{ marginRight: "5px" }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setStreamData((prevData) => ({
                      ...prevData,
                      showAIText: false,
                    }));
                    let newCustomFieldsObject =
                    customFields && typeof customFields === "object"
                        ? { ...customFields }
                        : {};
                    newCustomFieldsObject = {
                      ...newCustomFieldsObject,
                      ai_used: {
                        ...newCustomFieldsObject.ai_used,
                        answer_explanation: true,
                      },
                    };
                    props.formInstance.setFieldValue(["custom_fields"], newCustomFieldsObject);
                    onTextChanged(streamData.content);
                  }}>{mrIntl("Markscheme.use")}</Button> */}
              {/* </>
            )}
           {currentUser.role === "qb_author" && <CheckBox onChange={(e) => setStreamData({
              ...streamData,
              showCustomPrompt: e.target.checked
            })} disabled={explanationLoading} />}
            <MagicButton
              tooltipTitle={streamData.isStreaming ? mrIntl("CommonText.stop_generating") : !isQuestionContentExist ? mrIntl("TextAnswerExplanation.question_content_required_to_generate_explanation") : mrIntl("TextAnswerExplanation.auto_generate_explanation")}
              type="text"
              loading={explanationLoading}
              feature_code="generate_explanation_with_ai"
              onClick={() => streamData.isStreaming ? stopGeneratingExplanation() : generateExplanation()}
              icon={
                streamData.isStreaming ? <FaRegStopCircle /> : <ImMagicWand />
              }    
              disabled={!isQuestionContentExist}
            />
          </Space> */}
        </div>
        // {/* <Button loading={explanationLoading} icon={<ImMagicWand />} type="text" onClick={() => generateExplanation()} /> */}
      // </Tooltip>
    )
    } else {
      finalRender.push(
        <CKETextContent
          ckeConfig={{triggerCKEDataUpdate: triggerCKEDataUpdate}}
          value={content}
          placeholder={mrIntl("TextAnswerExplanation.provide_stepbystep_solution_or_reasoning_to_help_students_learn_it")}
          onChange={(data) => onTextChanged(data)}
        ></CKETextContent>
      );
    }
  } else {
    if (content) {
      if(printMode) {
        if (printOptions.with_answer_explanation === "true") {
          finalRender = (<Card
            className="print-answer-explanation"
            style={{marginTop: "20px"}}
            title="Teacher's Explanation"
          >
           <RenderHtml style={{overflowY: "auto"}} text={content} />
          </Card>
          )
        } else {
          finalRender = (<></>)
        }
      } else {
        finalRender.push(
          <ShowText key={`show-answer-explanation`} className={`show-answer-explanation p-20 m-t-20 m-b-10`}>
            <RenderHtml text={content} />
            {/* <ShowTextContent
                dangerouslySetInnerHTML={{ __html: content }}
              ></ShowTextContent> */}
            {/* {partnerName === "osc" ? (
              parse(content, {
                replace: (item) => {
                  if (item.name == "img") {
                    console.log("item is img", item);
                    return <EnlargableImage {...item.attribs} />;
                  }
                },
              })
            ) : (
              <ShowTextContent
                dangerouslySetInnerHTML={{ __html: content }}
              ></ShowTextContent>
            )} */}
          </ShowText>
        );
      }
      
    }
  }
  return (
    modal ? (
        <Modal
          title={`${mrIntl("TextAnswerExplanation.teachers_explanation")} `}
          open={showModal}
          className="teachers-explanation-markscheme-modal"
          okButtonProps={{
            disabled: streamData.isStreaming,
            loading: loading
          }}
          okText="Save"
          onOk={() => {
            setLoading(true);
            if (segment.question_segment_attributes) {
              dispatch(segmentActions.update(
                {
                  id: segment.id,
                  question_segments_attributes: [{
                    ...segment.question_segment_attributes,
                    answer_explanation: state
                  }],
                  settings: {
                    ...segment?.settings,
                    include_explanation: state ? true : false, // Need to set the include_explanation false if we try to save empty field
                  },
                },
                {
                  success: {
                    showMessage: true,
                    message: mrIntl("CommonText.item_successfully_updated"),
                  },
                  successCallback: () => {
                    setShowModal(false);
                    stopGeneratingExplanation();
                    setLoading(false)
                  },
                  errorCallback: (error) => {
                    console.log("errors in updating item ==>", error);
                    setLoading(false)
                  },
                  error: {
                    showMessage: true, 
                    message: mrIntl("CommonText.error_in_updating_item"),
                  },
                }
              ));
            }
          }}
          onCancel={handleCancel}
        >
          {finalRender}
        </Modal>
      ) : (
        finalRender
      )
  );
};
export default TextAnswerExplanation;
