import React, { useState, useEffect, memo } from "react";
import { MrSelect } from "mr_react_framework";
import { actions as genericActions } from "/src/App/genericRedux";
import { useSelector } from "react-redux";
import { experienceUsersSelector } from "../selector";
import { Progress, Tooltip } from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ExperienceUserGradingProgressBar = (props) => {
  console.log("ExperienceUserGradingProgressBar =====>", props);
  const { item } = props;
  const mrIntl = useTranslate();
  let width = props.width ? props.width : 100;
  // let isCommentsOnly = item.rubric && item.rubric.type_c === "comments_only" ? true : false
  let markedPercentage =
    item.marked_responses_count == undefined || item.marked_responses_count == 0
      ? 0
      : (
        (item.marked_responses_count / item.total_responses_count) *
        100
      ).toFixed(0);
      // let tooltipTitle = `Grading progress (${item.marked_responses_count}/${item.total_responses_count})`;
      let tooltipTitle = mrIntl("ExperienceUserGradingProgressBar.grading_progress", {markedResponses: item.marked_responses_count, totalResponses: item.total_responses_count });
  // if (isCommentsOnly) {
  //   markedPercentage = item.comments ? 100 : 0;
  //   tooltipTitle = "Grading progress";
  // }
  let markedProgressBar = (
    <Tooltip title={tooltipTitle} placement="bottom">
      {/* <Progress type="circle" percent={markedPercentage} width={width} /> */}
      <Progress percent={markedPercentage} size={"small"} style={{padding: "5px", width: width, bottom: "2px"}} />
    </Tooltip>
  );
  return markedProgressBar;
};

export default memo(ExperienceUserGradingProgressBar);
