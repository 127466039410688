import React from 'react';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import ViewStatus from '../ViewComponents/ViewStatus';
import { SolarPlayCircleBold } from '/src/components/UI/Icons/SolarIcons';
import ViewCard1 from '../ViewComponents/ViewCard1';
import "./PauseView.scss";
import { PauseCircleOutlined } from "@ant-design/icons";
import { currentViewSelector } from '../../FirestoreInteractions/selector';
import { useSelector } from 'react-redux';
const PauseView = (props) => {
  const {
    experienceProps,
    timer
  } = props;

  const { experience } = experienceProps;
  const mrIntl = useTranslate();
  const currentViewFromUserInfo = useSelector(currentViewSelector);

  const showConfig = {
    showProgress: true,
    timer: timer,
    titleColorClassName: 'bg-orange',
    // Hide right assessment details card for join view when experience is paused since topics is not available in experience
    showRightAssessmentDetailsCard: currentViewFromUserInfo === "joined" ? false : true
  }
  
  return (
    <div className="pause-view-container">
      <ViewStatus
        statusIcon={<PauseCircleOutlined />}
        statusText={mrIntl("PauseView.test_paused")}
        statusTextType={"warning"}
        statusMessage={mrIntl("PauseView.your_test_is_paused_by_the_teacher")}
      />
      <ViewCard1
        experience={experience}
        showConfig={showConfig}
        quitButtonAction="quit_app_before_resuming"
        footerCenterComponent={null} 
        footerLeftComponent={null} 
        footerRightComponent={[]} 
      />
    </div>
  );
};

export default PauseView;
