import React, { useEffect, useRef, useState } from "react";

import { Tag } from "antd";
import {
  FabricCanvas,
  useFabric,
  globalCanvasConfig,
} from "/src/components/UI/Canvas/FabricCanvas";
import FabricTools from "/src/components/UI/Canvas/FabricTools";
import {
  updateImagesSRCForCanvas,
  getParsedJSONObject,
} from "/src/views/Segments/InteractiveHelpers";
import { cloneDeep } from "lodash";
import { Segment, SegmentData } from "/src/components/UI/Segment/UIHelper";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import SegmentItemHeader from "/src/views/Segments/SegmentItemDetail/SegmentItemHeader";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const { shapeStyle, hotspotStyle } = globalCanvasConfig;

const FabricHotspot = (props) => {
  console.log("Fabric Hotspot props", props);
  const {
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
    unique_segment_identifier,
  } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);

  let { json_uuid } = parsedJSONObject;
  const mrIntl = useTranslate()
  const correctAnswerStatus = useRef({})
  let textAnswerString = props.text_answer || "";
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  if (createMode) {
    parsedJSONObject = {
      segment_data: {},
      segment_version: "1.0.0",
    };
    if (question_type == "fabric_hotspot") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }

  // if (answerMode) {
  // 	parsedJSONObject = shuffleItems(parsedJSONObject)
  // }

  if (autoCheckMode) {
    let question_json = cloneDeep(props.teacher_json);
    let answer_json = cloneDeep(parsedJSONObject);
    parsedJSONObject = checkResponses(question_json, answer_json);
  }

  // parsedJSONObject = updateImagesSRCForCanvas(parsedJSONObject, props.offlineView)

  const [state, setState] = useState(parsedJSONObject);
  const [textAnswer, setTextAnswer] = useState(textAnswerString);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    updatedCount: 0,
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
    showCorrectAnswerSwitchValue: false,
    showLoader: false,
  });
  const [myAnswer, setMyAnswer] = useState(state);
  const screenSettings = props.screenSettings || {};

  let currentJSON, text_answer, attempt_status;
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject;
    text_answer = textAnswerString;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    text_answer = textAnswer;
    attempt_status = attemptStatus;
  }

  const segment_data = currentJSON?.segment_data || {};
  const responses = currentJSON.segment_responses || {};
  const cd = segment_data.canvas_data;
  const canvas_data = (typeof cd == "string" ? JSON.parse(cd) : cd) || {};

  const [fabricReady, setCanvasFabricReady] = useState(false);
  const canvasRef = useRef({});
  const fabricRef = useFabric((currentCanvas) => {
    if (currentCanvas) {
      currentCanvas._customEventListeners = {
        onUpdateHotspot: (object) => {
          // setResponses(object)
        },
      };
      currentCanvas.toolType = {}; // TO Avoid sharing of tools between all canvas of page initialized
      currentCanvas.canvasInstancesForUndo = [cd];
      currentCanvas.canvasInstancesForRedo = [];
      canvasRef.current = currentCanvas;
      setCanvasFabricReady(true);
    }
  });

  const canvas = canvasRef.current || {};
  canvas.fabricReady = fabricReady
  canvas.role = createMode ? "teacher" : "student"

  if (!createMode && !answerMode) {
    canvas.notEvented = true;
  } else {
    canvas.notEvented = false;
  }

  useEffect(() => {
    setCanvasFabricReady(false)
  }, [unique_segment_identifier])

  useEffect(() => {
    if (canvas.fabricReady) {
      if (answerMode) {
        canvas.hotspotModeEnabled = true;
      }
      canvas.resizeCanvas();
      updateCanvasData(parsedJSONObject);
    }
  }, [canvas.fabricReady]);

  console.log("cd after each render ==>", cd);
  // Presentation mode watching data change - use case question wise grading, live responses
  useEffect(() => {
    if (canvas.fabricReady && presentationMode && json_uuid) {
      updateCanvasData(parsedJSONObject);
    }
  }, [json_uuid]);

  // When parent component want to update its state - use case reading mode, pause mode
  useEffect(() => {
    if (canvas.fabricReady && triggerStateUpdate) {
      updateCanvasData(parsedJSONObject);
    }
    if (answerMode) {
      canvas.hotspotModeEnabled = true;
    }
  }, [triggerStateUpdate]);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
    } else if (answerMode && stateUpdatedCount > 1) {
      if (props.onChange && stateSetting.canvasLoadedFromJSON) {
        let result = {
          response_json: cloneDeep(state),
          // text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    if (!presentationMode) {
      setState(state);
      setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
    }
    console.log("Hotspot Save state is called ==>", state, stateSetting);
  };

  const saveTextAnswer = (data) => {
    setTextAnswer(data);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  function checkResponses(question_json, answer_json) {
    console.log("Question and answer json ==>", question_json, answer_json);
    if (!question_json || !answer_json) {
      return;
    }
    let q_responses = question_json.segment_responses;
    let a_responses = answer_json.segment_responses;

    let correct_status = { all_correct: true };
    // Object.keys(a_responses).map((key) => {
    //   if (a_responses[key].answer) {
    //     if (q_responses[key] && q_responses[key].answer == a_responses[key].answer) {
    //       a_responses[key].correct = true
    //     } else {
    //       a_responses[key].correct = false
    //       correct_status = { all_correct: false }
    //     }
    //   }
    // })

    Object.keys(q_responses).map((key) => {
      if (a_responses[key] && a_responses[key].answer) {
        if (q_responses[key].answer) {
          a_responses[key].correct = true;
        } else {
          a_responses[key].correct = false;
          correct_status = { all_correct: false };
        }
      }

      if (
        q_responses[key].answer &&
        (!a_responses[key] || !a_responses[key].correct)
      ) {
        correct_status = { all_correct: false };
      }
    });

    correctAnswerStatus.current = correct_status;
    return answer_json;
  }

  const setResponses = () => {
    let isAtleastOneMarkedHotspot = false;
    const newResponses = {};
    const canvas = canvasRef.current;
    canvas.forEachObject((object) => {
      if (object.type != "image") {
        newResponses[object.id] = {
          id: object.id,
          answer: object.checkedHotspot ? true : false,
          name: object.name,
        };
        if (object.checkedHotspot) {
          isAtleastOneMarkedHotspot = true;
        }
      }
    });

    if (answerMode) {
      setAttemptStatus((attemptStatus) => {
        return {
          ...attemptStatus,
          json_attempted: isAtleastOneMarkedHotspot,
        };
      });
    }

    return newResponses;
  };

  function updateCanvasData(state) {
    if (state.errorInLoadFromJSON) {
      resetQuestion();
      return;
    }
    const segment_data = state.segment_data || {};
    const responses = state.segment_responses || {};
    const cd = segment_data.canvas_data;
    let canvas_data = (typeof cd == "string" ? JSON.parse(cd) : cd) || {};

    if (canvas_data && canvas_data.objects) {
      setStateSetting({
        ...stateSetting,
        showLoader: true,
      });
      canvas_data = updateImagesSRCForCanvas(
        canvas_data,
        state.segment_version
      );
      canvas.clearAll();
      canvas.loadFromJSON(
        canvas_data,
        function () {
          // if ($rootScope.printingStarted) {
          //   console.log('label', $rootScope.printWaitForInitQuestionsCount);
          //   $rootScope.printWaitForInitQuestionsCount += 1;
          // }
          canvas.resizeCanvas();
          updateCanvasObjects(responses);
          // canvas.renderAll.bind(canvas);
          setStateSetting((stateSetting) => {
            return {
              ...stateSetting,
              showLoader: stateSetting.errorInLoadFromJSON,
              canvasLoadedFromJSON: !stateSetting.errorInLoadFromJSON,
            };
          });
        },
        function (o, object) {
          if (!object) {
            console.log("Something wrong with this object ==>", o, object);
            setStateSetting({
              ...stateSetting,
              errorInLoadFromJSON: true,
            });
          }
          // console.log('Loaded Object ==>', object);
          // if (!props.answerMode && object) {
          //   object.hasControls = false;
          //   object.selectable = false;
          //   object.evented = false;
          // }
        }
      );
    }
  }

  function updateCanvasObjects(responses) {
    canvas.forEachObject(function (object) {
      if (answerMode && !stateSetting.quickCheckModeEnabled) {
        object.hasControls = false;
        object.hasBorders = false;
        object.lockMovementX = true;
        object.lockMovementY = true;
        object.selectable = true;
        object.hoverCursor = "pointer";

        if (object.name == "Background Image") {
          object.selectable = false;
          object.evented = false;
        } else if (!object.checkedHotspot) {
          object.set("fill", shapeStyle.fill);
          object.set("stroke", shapeStyle.stroke);
          object.set("strokeWidth", shapeStyle.strokeWidth);
        }
      }

      if (presentationMode) {
        object.selectable = false;
        object.evented = false;
      }

      if (autoCheckMode || stateSetting.quickCheckModeEnabled) {
        object.selectable = false;
        object.evented = false;
        if (responses.hasOwnProperty(object.id)) {
          let response = responses[object.id];
          if (response.answer) {
            if (response.correct) {
              object.correctAnswerStyle();
            } else {
              object.wrongAnswerStyle();
            }
          }
        }
      }

      // Overriding fill color if hotspot not selected yet
      if (!object.checkedHotspot) {
        object.set("fill", shapeStyle.fill);
        object.set("stroke", shapeStyle.stroke);
        object.set("strokeWidth", shapeStyle.strokeWidth);
      }
    });
    canvas.renderAll();
  }

  const saveCanvasData = (data) => {
    const newResponses = setResponses();
    const newCanvasData = {
      ...canvas_data,
      ...data,
    };

    const newSegmentData = {
      ...segment_data,
      canvas_data: newCanvasData,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
      segment_responses: newResponses,
    };

    saveState(newState);
  };

  const checkAnswer = (check = false) => {
    if (check) {
      let question_json = cloneDeep(props.teacher_json);
      let answer_json = cloneDeep(state);
      const newState = checkResponses(question_json, answer_json);

      setMyAnswer(state);
      saveState(newState);
      updateCanvasData(newState);
    }

    stateSetting.quickCheckModeEnabled = check;
  };

  const showCorrectAnswer = (value) => {
    let question_json, answer_json;
    if (value) {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(props.teacher_json);
    } else {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(myAnswer);
    }

    const newState = checkResponses(question_json, answer_json);
    saveState(newState);
    stateSetting.quickCheckModeEnabled = true;
    stateSetting.showCorrectAnswerSwitchValue = value;
    // setStateSetting({
    //   ...stateSetting,
    //   quickCheckModeEnabled: true,
    //   showCorrectAnswerSwitchValue: value
    // })
    updateCanvasData(newState);
  };

  const resetQuestion = () => {
    let student_json = cloneDeep(props.student_json);
    const newState = student_json;

    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    saveState(newState);
    stateSetting.quickCheckModeEnabled = false;
    // setStateSetting({
    //   ...stateSetting,
    //   quickCheckModeEnabled: false,
    // })

    updateCanvasData(newState);
  };

  console.log("Fabric hotspot state before render ==>", state, stateSetting);
  return (
    <Segment>
      {/* <SegmentItemHeader
        segmentStateSettings={stateSetting}
        showCorrectAnswer={(value) => showCorrectAnswer(value)}
      >
      </SegmentItemHeader> */}
      <SegmentData
        key={`segment-data-${unique_segment_identifier}`}
        className="interactive-questions"
        tabIndex={-1}
        onKeyDown={(e) => {
          if (!presentationMode) {
            canvas.onKeyDown(e);
          }
        }}
      >
        <FabricCanvas
          key={`fabric-canvas-${unique_segment_identifier}`}
          fabricRef={fabricRef}
          canvas={canvas}
          saveCanvasData={saveCanvasData}
          showLoader={stateSetting.showLoader}
          loaderMessage={
            stateSetting.errorInLoadFromJSON &&
            mrIntl("FabricHotspot.something_went_wrong_reset_question_loader_msg")
          }
        />
        {createMode && (
          <FabricTools
            canvas={canvas}
            toolBarConfig={{
              toolBar: [
                "move",
                "bgImage",
                "rectangle",
                "circle",
                "polygon",
                "path",
                "bringToFront",
                "sendToBack",
                "undo",
                "redo",
                "delete",
                "clearAll",
                "setHotspot",
              ],
              drawMode: true,
            }}
          />
        )}
        {(autoCheckMode || stateSetting.quickCheckModeEnabled) &&
          !correctAnswerStatus.current.all_correct && (
            <Tag color="red" style={{ margin: "10px" }}>
              {mrIntl("CommonText.not_all_correct_answers_were_selected")}
            </Tag>
          )}
      </SegmentData>

      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        saveTextAnswer={saveTextAnswer}
        resetQuestion={resetQuestion}
        checkAnswer={checkAnswer}
        showCorrectAnswer={showCorrectAnswer}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default FabricHotspot;
