import React, { useState, useEffect, useCallback } from "react";
import PropTypes, { elementType } from "prop-types";
import {
  List,
  Card,
  Table,
  Row,
  Col,
  Button,
  Collapse,
  Input,
  Anchor,
  Popconfirm,
  Tooltip,
  Menu,
  Select,
  Affix,
  Space,
} from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
// import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import { Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import {
  EditOutlined,
  DragOutlined,
  PlusOutlined,
  CheckOutlined,
  DeleteOutlined,
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  CloseOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import _, { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux";
import {actions as experienceActions} from "/src/views/Experiences/redux"
import {
  segmentsSelector,
} from "/src/views/Segments/selector";
import { checkMob, displayPoints, getDestinationPositionLex, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";
import { isPinnedSelector } from "/src/views/Segments/selector";
import SectionTitle from "/src/views/Segments/SegmentForms/SectionTitle";
import "/src/views/Segments/SegmentForms/SectionTitle.scss";
// import RenderHtml from "/src/components/UI/RenderHtml";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { upperFirst } from "lodash";
import { activeTopicIdSelector } from "../selector";
import { experienceLoadingSelector, topicsAndSegmentsLoadingSelector } from "/src/views/Experiences/selector";
import { initializeAiGenerateQuestionsConfig } from "../../GenerateAI/generateAIHelperMethods";


const CustomSubMenu = (props) => {
  console.log("topic props==>", props);
  const { SubMenu } = Menu;
  const { Link } = Anchor;
  const {
    segment,
    setTopic,
    deleted,
    topics,
    editAllowed,
    experience,
  } = props;
  const mrIntl = useTranslate();
  const activeTopicId = useSelector(activeTopicIdSelector());
  const topicSegments = useSelector(segmentsSelector(activeTopicId));
  const {url} = useRouteMatch();
  const isPinned = useSelector(isPinnedSelector());
  const dispatch = useDispatch();
  console.log("TopicListNew topicSegments", topicSegments);
  const sTitle = segment.section_segment_attributes && segment.section_segment_attributes.title
  const [topicVal, setTopicVal] = useState(
    sTitle
  );
  const [editMode, setEditMode] = useState(0);
  // const [questionHash, setQuestionHash] = useState("")
  // useEffect(() => {
  //   if (history.location.hash.length !== 0) {
  //     setQuestionHash(history.location.hash)
  //   }
  // }, [history.location.hash])

  let showPts = experience && experience.rubric && experience.rubric.type_c !== "myp_achievement_level" && experience.rubric.type_c !== "comments_only" && experience.settings.grading_setting === "per_question"
  let topicQuestionsCount;
  let topicQuestions;
  if (topicSegments) {
    topicQuestions = topicSegments.filter(
      (s) => (s.segment_type === "question") && s.parent_id === segment.id
    );
    topicQuestionsCount = topicQuestions.length || 0;
  }
  console.log('topicQuestions==>', topicQuestions);
  let questionLinks = [];
  const activeTopicIndex = topics.findIndex((topic) => topic.id === activeTopicId)
  let qTitle = "Q";
  if (topicQuestionsCount > 0) {
    for (var i = 0; i < topicQuestionsCount; i++) {
      let qPoints
      if(topicQuestions[i] && topicQuestions[i].points){
        qPoints = displayPoints(topicQuestions[i].points);
      }
      
      let linkTitle = <Space style={{ display: "flex", justifyContent: "space-between" }}>
        <span>{`${qTitle} ${activeTopicIndex + 1}.${i + 1}`}</span>
        {showPts && <span style={{paddingRight: "10px"}}>{`[${qPoints}]`}</span>}
      </Space>

      questionLinks.push(
        // not using menu item for now - using Anchor
        // <Menu.Item key={`topic-${segment.id}-question-${i}`} question={i} topic={segment.id} >
        //   Question {i}
        // </Menu.Item>
        // <Link href={`#question-${i}`} title={`${qTitle} ${i} [${qPoints}]`}></Link>
        <Link href={`#item-${topicQuestions[i].id}`} title={linkTitle}></Link>
        
      );
    }
  }
  // else{
  //   questionLinks.push(<span>No questions</span>)
  // }
  // questionLinks.push(<Menu.Item key="5">Question 1</Menu.Item>)

  const saveTopicTitle = () => {
    console.log("save topic clicked", topicVal);
    // removing html because ckeditor added the html
    const charactersCount = removeHTMLTagsFromText(topicVal).length;
    if (charactersCount === 0) {
      message.error(mrIntl("TopicListNew.section_name_cannot_be_blank_msg"));
    }
    // changed the limit to 50 earlier it was 30 and in the add section it is 50 so change here - ask Shikhar
    else if (charactersCount > 50) {
      message.error(mrIntl("TopicListNew.section_name_too_long_msg"));
    } else {
      props.updateResource({
        id: segment.id,
        section_segments_attributes: [
          {
            id: segment.section_segment_attributes.id,
            title: topicVal,
          },
        ],
      }, {
        success: { showMessage: true, message: mrIntl("TopicListNew.topic_updated") },
        error: { showMessage: true, message: mrIntl("TopicListNew.error_in_updating_topic") },
      });
      setEditMode(0);
    }
  };
  const confirm = (e) => {
    e.stopPropagation();
    // deleted(segment);
    props.deleted(segment, {
      success: {
        showMessage: true, 
        message: mrIntl("TopicListNew.topic_deleted")
      },
      successCallback: (data) => {
        console.log("delete section done", data);
        if(experience){
          dispatch(experienceActions.showSuccess({data: {
            experience: {
              ...experience, 
              points: data.data.segment.data.attributes.exp_points, 
              criterium_associations: data.data.segment.data.attributes.exp_criterium_associations, 
              questions_count: data.data.segment.data.attributes.exp_questions_count,
              attemptable_questions_count: data.data.segment.data.attributes.exp_attemptable_questions_count,
              
            }
          }}))
        }
      }
    });
    if (topics.length > 0) {
      topics.splice(props.idx, 1) 
      setTopic(topics[0].id)
    }
    // if (topics.length != 0) setTopic(topics[0].id);
    // message.success("Deleted successfully");
  };
  const cancel = (e) => {
    e.stopPropagation();
    // message.error("Delete cancelled");
  };

  let deleteBtn = (
    <Popconfirm
      key="delete"
      title= {mrIntl("TopicListNew.confirm_delete_tooltip_msg")}
      // title="Confirm delete? This will also archive all content inside this topic."
      onConfirm={confirm}
      onCancel={cancel}
      okText= {mrIntl("CommonText.yes")}
      cancelText= {mrIntl("CommonText.no")}
    >
      <Tooltip title={topics.length === 1 ? mrIntl("TopicListNew.at_least_one_section_is_required") : ""}>
        <Button
          type="text"
          className="section-delete-button vertical-align-middle"
          disabled={topics.length === 1}
          onClick={(event) => event.stopPropagation()}
          shape="circle"
          icon={<DeleteOutlined />}
          size="small"
        ></Button>
      </Tooltip>
    </Popconfirm>
  );

  let cancelBtn = (
    <Button
      type="text"
      onClick={(event) => {
        event.stopPropagation();
        setTopicVal(sTitle);
        setEditMode(0);
      }}
      shape="circle"
      icon={<CloseOutlined />}
      size="small"
    ></Button>
  );

  let editBtn = (
    <Button
      type="text"
      className="vertical-align-middle"
      onClick={(event) => {
        event.stopPropagation();
        setEditMode(1);
      }}
      shape="circle"
      icon={<EditOutlined />}
      size="small"
    ></Button>
  );

  let saveBtn = (
    <Button
      type="text"
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
        saveTopicTitle();
      }}
      shape="circle"
      icon={<CheckOutlined />}
      size="small"
    ></Button>
  );

  let sectiontTitle = removeHTMLTagsFromText(
    sTitle,
    true
  );
  let sectionPoints = showPts && segment.points && `[${displayPoints(segment.points)}]`
  console.log("sectiontitle==>", sectiontTitle);

  let titleInner = [];
  let topicTitle =
    sTitle &&
    removeHTMLTagsFromText(sTitle).length <=
      15 ? (
      <RenderHtml text={sTitle} />
    ) : (
      <Tooltip
        title={removeHTMLTagsFromText(
          sTitle,
          true
        )}
      >
        {
          <RenderHtml
            truncate
            text={sTitle}
          />
        }
        ...
      </Tooltip>
    );
  if (isPinned) {
    topicTitle = <Tooltip title={sectiontTitle}>S {props.idx + 1}</Tooltip>;
  }
  if (editAllowed) {
    if (editMode) {
      // titleInner.push(<Input autofocus defaultValue={segment.section_segment_attributes.title} onClick={(event) => event.stopPropagation()} onChange={(e)=>{console.log("input change", e.target.value); setTopicVal(e.target.value); }} onPressEnter={(e)=>{saveTopicTitle()}} />)
      // titleInner.push(<span>{saveBtn}{cancelBtn}</span>)

      titleInner.push(
        <SectionTitle
          edit
          autofocus
          value={sTitle}
          onClick={(event) => event.stopPropagation()}
          onChange={(value) => {
            setTopicVal(value);
          }}
          onPressEnter={(e) => {
            saveTopicTitle();
          }}
        />
      );
      titleInner.push(
        <span>
          {saveBtn}
          {cancelBtn}
        </span>
      );
    } else {
      titleInner.push(topicTitle);
      titleInner.push(
        <span>
          {sectionPoints}
          {editBtn}
          {deleteBtn}
        </span>
      );
    }
  } else {
    titleInner.push(topicTitle);
    titleInner.push(sectionPoints);
  }

  let title = (
    <Space
      style={{ display: "flex", justifyContent: "space-between" }}
      className={`topics-submenu ${editMode && "edit-section"}`}
    >
      {/* {(import.meta.env.VITE_MODE === "staging" || import.meta.env.VITE_MODE === "development") && displayPoints(segment.points)} */}
      {/* {(import.meta.env.VITE_MODE === "staging" || import.meta.env.VITE_MODE === "development") && segment.position} */}
      {/* {displayPoints(segment.points)} */}
      {titleInner}
    </Space>
  );
  

  // const getCurrentAnchor = () => {
  //   return questionHash;
  //   // return `#question-1`
  // }

  const updateTopicListUrl = debounce((activeLink) => {
    console.log("line number updateTopicListUrl===>",activeLink, activeTopicId);
    if (activeLink && activeTopicId) {
      window.history.replaceState(`null`, '', `${url}/topics/${activeTopicId}${activeLink}`)
    }
  }, 200)

  const debouncedChangeHandler = useCallback
    (updateTopicListUrl, [activeTopicId]);

  return (
    <SubMenu {...props} title={title}>
      {/* not using menu item for now - using Anchor */}
      <Anchor offsetTop={200} affix={false} showInkInFixed={true}
      onChange={debouncedChangeHandler}
        >
        {/* <Anchor offsetTop={200} affix={false} showInkInFixed={true} getCurrentAnchor={getCurrentAnchor}> */}
        {questionLinks}
      </Anchor>
      {/* {questionLinks} */}
    </SubMenu>
  );
};

const TopicList = (props) => {
  console.log("TopicList props", props);
  const dispatch = useDispatch();
  const location = useLocation();
  const mrIntl = useTranslate()
  const {
    topics,
    history,
    currentUser,
    rearrangeMode,
    experienceProps,
    experienceViewMode,
    editAllowed,
    updateResource
  } = props;
  // const { SubMenu } = Menu;
  const { Option } = Select;

  // let experienceViewMode = props.parentInstance.props.experienceViewMode
  let experience = (experienceProps || {}).item || {};
  const finalRender = [];

  const { path, url } = useRouteMatch();
  console.log("seg list item url", url);

  console.log("topics", topics);
  console.log("topics url", url);
  console.log("topics path", path);
  console.log("topics location", location);

  const [selectedTopicId, setSelectedTopicId] = useState(0);

  const activeTopicId = useSelector(activeTopicIdSelector());
  const topicSegments = useSelector(segmentsSelector(activeTopicId));
  const isPinned = useSelector(isPinnedSelector());
  const topicsAndSegmentsLoading = useSelector(topicsAndSegmentsLoadingSelector());
  console.log("fetchTopicsLoading =======>", topicsAndSegmentsLoading);
  
  let defaultOpenKeys = [];
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys);
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    console.log("line number 3787==>>", location.hash, history)
    if (topics.length !== 0) {
      let topicIdToSet, topicIdFromUrl, questionHashFromUrl;

      let topicIdString = location.pathname.split("/topics/")[1];
      if (topicIdString) {
        topicIdFromUrl = topicIdString.split("/#")[0];
        if (location.hash) {
          questionHashFromUrl = location.hash.split("#")[1];
        }
      }
      // get topicId from url and use that if found, priority order = activeTopicId > topicIdInUrl > first topic default
      // if case of topicIdInUrl, then check for question hash also and scroll to that if present, call setTopic with scrollToElementId value

      if (topicIdFromUrl) {
        topicIdToSet = parseInt(topicIdFromUrl);
      } else if (activeTopicId) {
        topicIdToSet = activeTopicId;
      } else {
        topicIdToSet = topics[0].id;
      }
      console.log(
        "topics useeffect ==>",
        topics,
        location.pathname,
        location.pathname.split("/topics/")[1],
        topicIdString,
        topicIdToSet,
        questionHashFromUrl
      );

      // defaultOpenKeys.push(`topic-${topicIdToSet}`)
      setTopic(topicIdToSet, questionHashFromUrl);
    }
  }, [location.pathname, location.hash]);

  useEffect(() => {
    console.log(
      "topicsAndSegmentsLoading in topic list ==>", topicsAndSegmentsLoading
    );
    
    if(topics[0] && topicsAndSegmentsLoading === false) {
      setTopic(topics[0].id);
    }
  }, [topicsAndSegmentsLoading]);

  useEffect(() => {
    if (selectedTopicId) {
      console.log("actions of topic ==>", actions)
      dispatch(actions.setActiveIdSuccess({ id: selectedTopicId }));
    }
  }, [selectedTopicId]);

  useEffect(() => {
    // NOTE: calling each time right now when draft - but have a selector above for topicSegments and if > 0 then don't call show? - leave for draft. Basically TopicItemDetail Segments crud doNotReloadOnUpdate condition has come here since there, we are not calling
    console.log("topicSegments ======>", topicSegments.length, topicSegments, experience.status, experienceViewMode, activeTopicId);
    if(activeTopicId && (experience.status === "draft" || topicSegments.length === 0 ) && (experienceViewMode === "apEditTest" || experienceViewMode === "apPreviewTest")){
      dispatch(actions.show({id: activeTopicId}))
    }
  }, [activeTopicId]);

  const [segmentViewMode, setSegmentViewMode] = useState("show");
  const [editSegmentId, setEditSegmentId] = useState(0);
  const [createSegmentFormAfterId, showCreateSegmentFormAfterId] = useState(0);

  // const [topics, setTopicsInList] = useState(topics)

  const switchMode = (mode, segmentId) => {
    console.log("segmentId", segmentId);
    console.log("mode", mode);
    showCreateSegmentFormAfterId(0);
    setEditSegmentId(0);
    setSegmentViewMode("show");
    if (mode === "edit") {
      setSegmentViewMode("edit");
      setEditSegmentId(segmentId);
    } else if (mode === "show") {
      setSegmentViewMode("show");
      setEditSegmentId(0);
    } else if (mode === "create") {
      showCreateSegmentFormAfterId(segmentId);
    } else if (mode === "createCancel") {
      showCreateSegmentFormAfterId(0);
    }
  };

  const EditButton = (props) => {
    return (
      // <Link to={`${url}/topics/${props.segment.id}/edit`}>
      // <EditOutlined />
      <EditOutlined onClick={(event) => switchMode("edit", props.segment.id)} />
      // </Link>
    );
  };

  const updateParent = (topics) => {
    let updatedOrder = _.map(topics, "id");
    console.log("on drag end result ==>", topics, updatedOrder);
    console.log("experienceProps in update parent ==>", experienceProps);
    // experienceProps.updateResource({id: experienceProps.item.id, children_order: updatedOrder})
    experienceProps.updateResource({
      uid: experienceProps.item.uid,
      children_order: updatedOrder,
    });
  };

  const insertOnDragEnd = (result, segmentArray) => {
    if (!result.destination) {
      return;
    }
    let sourceIdx = result.source.index;
    let destinationIdx = result.destination.index;

    console.log("on drag end result idxs ==>", sourceIdx, destinationIdx);
    if (sourceIdx == destinationIdx) {
      return;
    }

    if (sourceIdx != undefined && destinationIdx != undefined) {
      // to update segment position field on BE. before redux changes so using sourceIdx
      let topicToUpdate = topics[sourceIdx]
      // *Confusing but had to be different since indexes being changed after rearrange redux action not before and we don't want to count position of item being moved
      let prevIdx, nextIdx
      if(sourceIdx < destinationIdx){
        prevIdx = destinationIdx
        nextIdx = destinationIdx + 1
        
      }else if(sourceIdx > destinationIdx){
        prevIdx = destinationIdx - 1
        nextIdx = destinationIdx
      }
      let prevPosition = (topics[prevIdx] || {}).position
      let nextPosition = (topics[nextIdx] || {}).position
      let destinationPosition = getDestinationPositionLex(prevPosition, nextPosition)
      updateResource({ id: topicToUpdate.id, destination_position: destinationPosition })

      console.log("on drag end result ==> topics", sourceIdx, destinationIdx, experience);
      // to rearrange in redux and updateParent children_order in callback
      dispatch(
        actions.rearrange({
          sourceIdx,
          destinationIdx,
          experienceId: experience.id,
          callback: (data) => updateParent(data),
        })
      );
      // updateParent(topics)
      // const elmToMove = topicsInList.splice(sourceIdx, 1)[0];
      // console.log('on drag end result elmToMove ==>', elmToMove);
      // topicsInList.splice(destinationIdx, 0, elmToMove);
      // setTopicsInList(topicsInList)
    }

    return "";
  };

  const setTopic = (topicId, questionHashFromUrl = 0) => {
    let scrollToElementId = questionHashFromUrl;
    setSelectedTopicId(topicId);
    if (scrollToElementId === 0) {
      // by default scroll to top of segment list
      // scrollToElementId = `topic-${topicId}`
      // only set url if clicking on topic to set, not if url changed
      history.push(`${url}/topics/${topicId}`);
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
      console.log("setting topic no question hash - scroll to top");
    } else {
      console.log("setting topic scroll to question hash");
      // scrollToElementId value is being passed from bookmarksList setTopic
      setTimeout(() => {
        const scrolledElement = document.getElementById(
          // `question-${question_number}`
          scrollToElementId
        );
        // scrolledElement.scrollIntoView({ behavior: "smooth" });
        if (scrolledElement) {
          window.scrollTo({
            behavior: "smooth",
            top: scrolledElement.offsetTop - 3,
          });
        }
      }, 100);
    }

    // history.push(`${url}/topics/${topicId}`)
    setOpenKeys([`topic-${topicId}`]);

    // window.scrollTo({
    //   behavior: "smooth",
    //   top: 0
    // });
    // const scrolledElement = document.getElementById(
    //   // `question-${question_number}`
    //   `question-2`
    // );
    // // scrolledElement.scrollIntoView({ behavior: "smooth" });
    // if (scrolledElement) {
    //   window.scrollTo({
    //     behavior: "smooth",
    //     top: scrolledElement.offsetTop - 3,
    //   });
    // }
  };

  let menuInner = [];
  let topicListForRearrange = [];
  topics.map((segment, i) => {
    // normal list
    menuInner.push(
      <CustomSubMenu
        key={`topic-${segment.id}`}
        className={
          openKeys.indexOf(`topic-${segment.id}`) > -1 ? "active-topic" : ""
        }
        segment={segment}
        setTopic={setTopic}
        selectedTopicId={selectedTopicId}
        rearrangeMode={rearrangeMode}
        idx={i}
        experienceViewMode={experienceViewMode}
        experienceStatus={experience.status}
        experience={experience}
        updateResource={props.updateResource}
        deleted={props.deleted}
        topics={topics}
        editAllowed={editAllowed}
        history={history}
        activeTopicId={activeTopicId}
        url={url}
      ></CustomSubMenu>
    );

    // list for rearrange
    topicListForRearrange.push(
      <List.Item
        className="topic-list-item"
        key={`rearrange-topic-${segment.id}`}
      >
        <Draggable
          type="topics"
          draggableId={`draggable-${segment.id}`}
          index={i}
          isDragDisabled={!rearrangeMode}
        >
          {(provided, snapshot) => (
            <div
              className={
                rearrangeMode
                  ? "section-draggable rearrange-enabled"
                  : "section-draggable"
              }
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Space align="baseline">
                <DragOutlined />
                <h4>
                  <RenderHtml
                    text={segment.section_segment_attributes.title}
                    truncate
                  />
                </h4>
              </Space>
            </div>
          )}
        </Draggable>
      </List.Item>
    );
  });

  let addTopicBtn;
  let addTopicForm;
  if (topics && topics.length != 0) {
    let lastTopic = topics[topics.length - 1];
    addTopicBtn = (
      <Button
        className="add-topic-button" 
        type="primary"
        size="medium"
        ghost={true}
        onClick={(event) => {
          props.showForm({ name: "topics" })
        }}
      >
        <PlusOutlined />
        <MrTranslate id={"TopicListNew.add_section"}/>
      </Button>
    );

    addTopicForm = props.renderForm({
      name: "topics",
      config: {
        isModal: false,
        visible: props.showFormVal === "topics",
        actions: { submitText: mrIntl("CommonText.save"), showCancelBtn: true },
        handleCancelCallback: () => {
          props.showForm({ name: undefined });
        },
        submitConfig: {
          createAtIndex: topics.length,
          hideForm: true,
          previousId: lastTopic.id,
          destinationPosition: getDestinationPositionLex(lastTopic.position, null),
          success: {showMessage: true, message: mrIntl("TopicListNew.topic_added_success_msg")}, 
          successCallback: (data) => {},
        },
        
      },
      // resource: segment //use this for edit
    });
  }

  let newFinalRender = (
    <Affix offsetTop={100} className="section-menu-affix">
      <section className="section-menu-inner">
        {/* <h3><Button type="text"  onClick={() => setCollapsed(!collapsed)} icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} /> Sections</h3> */}
        {!isPinned && <h3>
        <MrTranslate id={"CommonText.sections"}/>
          {/* Sections */}
          </h3>}
        <Menu
          mode="inline"
          inlineCollapsed={collapsed}
          defaultOpenKeys={defaultOpenKeys}
          openKeys={openKeys}
          onOpenChange={(changedOpenKeys) => {
            const latestOpenKey = _.difference(changedOpenKeys, openKeys);
            console.log(
              "open change - ",
              openKeys,
              changedOpenKeys,
              latestOpenKey
            );

            if (latestOpenKey.length === 1) {
              // setOpenKeys(latestOpenKey)
              let topicId = parseInt(latestOpenKey[0].split("-")[1]);
              setTopic(topicId);
            } else {
              // collapse
              setOpenKeys([]);
            }
          }}
          // style={{ width: 256 }}
          style={{ width: "100%" }}
          // not using menu item for now - using Anchor
          // onClick={({item, key, keyPath, domEvent}) => {
          //   console.log("menu click - ", item, key)
          //   let topicId = item.props.topic
          //   let questionNo = item.props.question
          //   // history.push(`${url}/topics/${topicId}#question-${questionNo}`)
          //   history.push(`${url}/topics/${13616}#question-${1}`)
          // }}
        >
          {menuInner}
        </Menu>

        {editAllowed ? (
          // <Space align="vertical">
          //   {addTopicBtn}
          //   {addTopicForm}
          // </Space>
          <List className="topic-add-list">
            {props.showFormVal === "topics" ? (
              <List.Item className="topic-list-item">{addTopicForm}</List.Item>
            ) : (
              <List.Item className="topic-list-item add-button">
                {addTopicBtn}
              </List.Item>
            )}
          </List>
        ) : (
          ""
        )}
      </section>
    </Affix>
  );

  // if(((experienceViewMode == "apTakeTest" || !experienceViewMode) && topics.length <= 1) || checkMob()){
  // if(((experienceViewMode == "apTakeTest" || !experienceViewMode) && topics.length <= 1)){
  //   // console.log("dont show topics")
  //   newFinalRender = ""
  // }
  // Showing now in all cases even if there is 1 topic

  // if mobile, or small width in embedded preview for example, show dropdown instead
  if (checkMob() && topics.length !== 0) {
    console.log("selected topic id==>", selectedTopicId, topics);
    let options = [];
    topics.map((segment, i) => {
      options.push(
        <Option value={segment.id}>
          {
            <RenderHtml
              text={segment.section_segment_attributes.title}
              truncate
            />
          }
        </Option>
      );
    });
    const defaultValue = selectedTopicId !== 0 ? selectedTopicId : topics[0].id;
    newFinalRender = (
      <Select
        className="topic-selector-mobile"
        size="large"
        value={defaultValue}
        onChange={(value) => setTopic(value)}
      >
        {options}
      </Select>
    );
  }

  // use below for rearrangemode

  let newFinalRenderRearrange = (
    <DragDropContext onDragEnd={insertOnDragEnd}>
      <Droppable
        droppableId="droppable-topics"
        type="topics"
        key="droppable-topics"
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={{
              backgroundColor: snapshot.isDraggingOver ? "aliceblue" : "white",
              padding: "20px",
            }}
            {...provided.droppableProps}
            className="section-list-droppable"
          >
            {topicListForRearrange}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <React.Fragment>
      {rearrangeMode ? newFinalRenderRearrange : newFinalRender}
    </React.Fragment>
  );
};

TopicList.defaultProps = {};

TopicList.propTypes = {};

export default TopicList;
