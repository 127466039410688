import React, { useState } from "react";
import { Button, Modal, Tooltip, Row, Tag, Space } from "antd";
import {
  UserAddOutlined,
  InfoOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { actions as fireStoreReduxActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { checkIfShouldDispatchChangeSecurity } from "/src/App/OfflineApp/offlineAppHelper";


const LogoutBtn = (props) => {
  const { experience, className = "", logoutButtonIcon = null } = props;
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const appType = useSelector(appTypeSelector());
  const [loading, setLoading] = useState(false);

  const experienceSettings = experience.settings || {};

  let logoutBtn = "";

  const isShowLogoutButton = 
    appType === "seb" || 
    appType === "mac" || 
    appType === "ios";

  const shouldRedirectToHomePage = true; // redirect to home, if want to call openUrl("/")

  const handleLogoutButtonClick = (e) => {
    setLoading(true);
    props.withLog &&
      dispatch(
        fireStoreReduxActions.setUserInfo(
          {},
          {
            log: {
              logging: true,
              action: props.action,
            },
          }
        )
      );
    logoutDispatch();
  };

  const logoutDispatch = () => {
    setTimeout(
      () => {
        dispatch(
          loginActions.logout({
            shouldDispatchChangeSecurity: checkIfShouldDispatchChangeSecurity(appType, experienceSettings),
            shouldRedirectToHomePage,
          })
        );
        setLoading(false);
      },
      props.withLog ? 1500 : 0
    );
  };

  if (isShowLogoutButton) {
    logoutBtn = (
      <Button
        loading={loading}
        danger
        ghost={true}
        onClick={() => {
          handleLogoutButtonClick();
        }}
        className={className}
      >
        {mrIntl("LogoutBtn.logout")} {logoutButtonIcon}
      </Button>
    );
  }

  return logoutBtn;
};

export default LogoutBtn;