import { createSelector } from "reselect";
// import lodash from "lodash";

// const loadingState = (state, props) => {
//   return state.orgProgrammes.get("loading");
// };
// export const loadingSelector = () =>
//   createSelector([loadingState], (loading) => loading);

// const errorState = (state, props) => state.orgProgrammes.get("error");

// export const errorSelector = () =>
//   createSelector([errorState], (error) => error);

const orgProgrammesState = (state, props) =>
  state.request.get("orgprogrammes");

export const orgProgrammesSelector = () =>
  createSelector([orgProgrammesState], (orgProgrammes) => orgProgrammes);

// const orgProgrammesShowState = (state, props) => {

//   const orgProgrammes = state.orgProgrammes.get("org_programmes");

//   if (orgProgrammes.size === 0) {
//     return {};
//   } else {
//     const id = parseInt(props.match.params.id);
//     const idx = lodash.findIndex(orgProgrammes, {
//       id: id,
//     });
//     if (idx !== -1) {
//       return orgProgrammes[idx];
//     }
//     return {};
//   }
// };

// export const orgProgrammesShowSelector = () =>
//   createSelector([orgProgrammesShowState], (orgProgrammes) => orgProgrammes);

// const paginationState = (state, props) => {
//   const pageObj = {};
//   pageObj.page = state.orgProgrammes.get("page");
//   pageObj.totalPages = state.orgProgrammes.get("total_pages");

//   return pageObj;
// };

// export const paginationSelector = () =>
//   createSelector([paginationState], (pageObj) => pageObj);
