import React from "react";
import { Table, Space } from "antd";
import { Image } from "antd";
import size from "lodash/size";
import { isArray } from "lodash";
import dayjs from "dayjs";
import StudentInfoModal from "../StudentInfoModal";
import "../FeedbackModal.scss";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const FeedbacksList = (props) => {
  console.log("FeedbacksList props", props);
  const { dataSource } = props;
  const tableDataSource = [];
  const mrIntl = useTranslate();
  for (let index = 0; index < dataSource.length; index++) {
    const cf = dataSource[index].custom_fields;
    let objToPush = {};
    objToPush.id = dataSource[index].id;
    let user = dataSource[index].user;
    let org = dataSource[index].org;
    if (user && org) {
      objToPush.user_details = (
        <Space direction={"vertical"}>
          <span>
            {user.first_name +
              " " +
              (user.last_name ? user.last_name : "") +
              "(" +
              user.role +
              ")"}
          </span>
          <span>{user.email}</span>
          <span>{org.name}</span>
        </Space>
      );
    }
    objToPush.message = dataSource[index].message;
    objToPush.attachments_json =
      isArray(dataSource[index].attachments_json.images) &&
      dataSource[index].attachments_json.images.length > 0 ? (
        dataSource[index].attachments_json.images.map((image) => (
          <span style={{ marginRight: "5px" }}>
            <Image src={image} />
          </span>
        ))
      ) : size(dataSource[index].attachments_json) > 0 ? (
        <Image
          src={`${(objToPush.attachments_json =
            dataSource[index].attachments_json.images)}`}
        />
      ) : (
        mrIntl("FeedbacksList.no_attachment")
      );
    objToPush.feedback_type = dataSource[index].feedback_type;

    let device_os =
      size(cf.device_info) > 0
        ? `${cf.device_info.os}, ${cf.device_info.browser}`
        : "null";
    objToPush.context = (
      <Space direction={"vertical"}>
        <span>{"Url: " + dataSource[index].from_page}</span>
        <span>{"System info : " + device_os}</span>
        {size(cf.studentInfo) > 0 && (
          <StudentInfoModal
            appType={cf.studentInfo.appType}
            currentView={cf.studentInfo.currentView}
            experienceId={cf.studentInfo.experienceId}
            experienceName={cf.studentInfo.experienceName}
            sCode={cf.studentInfo.sCode}
            studentName={cf.studentInfo.studentName}
            studentDeviceinfo={cf.studentInfo.studentDeviceinfo}
            experienceMode={cf.studentInfo.experienceMode}
            appVersion={cf.studentInfo.appVersion}
            accessCode={cf.studentInfo.accessCode}
            appNativeVarsInfo={cf.studentInfo.appNativeVarsInfo}
          />
        )}
      </Space>
    );
    objToPush.created_at = (
      <Space>
        {dayjs(dataSource[index].created_at).format("MMM DD, HH:mm:ss a")}
      </Space>
    );
    // objToPush.actions = (
    //   <ActionBtns
    //     edit={true}
    //     delete={true}
    //     deleted={props.deleted}
    //     item={dataSource[index]}
    //     id={dataSource[index].id}
    //   />
    // );
    tableDataSource.push(objToPush);
  }

  const columns = [
    {
      title: mrIntl("CommonText.id"),
      dataIndex: "id",
      key: "id",
      // width: 50,
    },
    {
      title: mrIntl("FeedbacksList.users"),
      dataIndex: "user_details",
      key: "user_details",
      render: (text) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: mrIntl("CommonText.message"),
      dataIndex: "message",
      key: "message",
      // width: 400,
      // render: (text) => <p dangerouslySetInnerHTML={{ __html: text }} />,
      render: (text) => (
        <div
          className="feedback-message"
          dangerouslySetInnerHTML={{ __html: text }}
          style={{ wordWrap: "break-word", wordBreak: "break-word" }}
        ></div>
      ),
    },
    {
      title: mrIntl("CommonText.feedback_type"),
      dataIndex: "feedback_type",
      key: "feedback_type",
    },
    {
      title: mrIntl("FeedbacksList.attachment"),
      dataIndex: "attachments_json",
      key: "attachments_json",
    },
    {
      title: mrIntl("FeedbacksList.context"),
      dataIndex: "context",
      key: "context",
      // width: 400,
      render: (text) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: mrIntl("FeedbacksList.created_at"),
      dataIndex: "created_at",
      key: "created_at",
    },
    // {
    //   title: "Actions",
    //   dataIndex: "actions",
    //   key: "actions",
    // },
  ];
  return (
    <Table dataSource={tableDataSource} columns={columns} pagination={false} />
  );
};

export default FeedbacksList;
