import React, { useRef, useEffect } from "react";
import { renderMathInElement } from "/src/lib/utils/helperMethods";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const RenderExtraInstructions = ({ text }) => {
  const extraInstructionsRef = useRef(null);
  const mrIntl = useTranslate();

  useEffect(() => {
    if (extraInstructionsRef?.current) {
      renderMathInElement(extraInstructionsRef.current, true);
    }
  }, [extraInstructionsRef.current]);

  return (
    <div ref={extraInstructionsRef}>
      <h3>{mrIntl("RenderExtraInstructions.instructions")}</h3>
      <RenderHtml text={text} />
    </div>
  );
};

export default RenderExtraInstructions;