import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { put, select } from "redux-saga/effects";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { message } from "antd";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "questionbank",
  actionNames: ["FETCH", "CREATE", "SHOW", "UPDATE", "DELETE", "SYNC", "EXPORT_STUDENT_PERFORMANCE", "UPDATE_SEGMENT_AND_EXPERIENCE_COUNTS"]
});

const initialState = fromJS({
  questionbanks: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

export function* syncSaga(action) {
  const currentUser = yield select(currentUserSelector());
  const syncData = {
    qb_code: action.payload.code,
    static_only: action.payload.static_only,
    save: action.payload.save
  };
  let url = `questionbanks/sync_from_global_prod.json`;
  try {
    yield put(actions.syncStart());
    const response = yield axiosInstance.instance.post(url, syncData);

    console.log("response ===>", response)
    message.success(response.data.message);
    yield put(actions.syncSuccess(response.data));
  } catch (error) {
    console.error("error", error);
    if (error.response) {
      message.success(error.response.data.message);
      yield put(actions.syncFail({ error: error.response.data.error }));
    } else {
      message.success(error.message);
      yield put(actions.syncFail({ error: error }));
    }
  }
}

export function* exportStudentPerformanceSaga(action) {
  yield put(actions.exportStudentPerformanceStart());

  let url = "questionbanks/export_student_performance.json";

  const { successCallback, errorCallback } = action.options;
  try {
    const response = yield axiosInstance.instance.post(url, action.payload);
    console.log("exportStudentPerformanceSaga response", response);
    if (successCallback && response.data) {
      successCallback(response.data);
    }
  } catch (error) {
    console.error("exportStudentPerformanceSaga error", error);
    if (errorCallback) {
      errorCallback(error);
    }
    yield put(actions.exportStudentPerformanceFail({ error }));
  }
}

export function* updateSegmentAndExperienceCountsSaga(action) {
  yield put(actions.updateSegmentAndExperienceCountsStart());

  let url = "questionbanks/update_all_counts.json";
  try {
    const response = yield axiosInstance.instance.post(url, action.payload); 
    if (response) {
      message.success("Count updated successfully");
    }
  } catch (error) {
    message.error("Count update failed");
  }
}


export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState);
export const watchQuestionbanks = reduxCrud.generateWatchSaga({
  [actionTypes.SYNC_QUESTIONBANK]: syncSaga,
  [actionTypes.EXPORT_STUDENT_PERFORMANCE_QUESTIONBANK]: exportStudentPerformanceSaga,
  [actionTypes.UPDATE_SEGMENT_AND_EXPERIENCE_COUNTS_QUESTIONBANK]: updateSegmentAndExperienceCountsSaga,
});

export default reduxCrud;
