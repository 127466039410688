import { createSelector } from "reselect";
// import lodash from "lodash";

// const loadingState = (state, props) => {
//   return state.users.get("loading");
// };
// export const loadingSelector = () =>
//   createSelector([loadingState], (loading) => loading);

const bulkLoadingState = (state, props) => {
  return state.users.get("bulkloading");
};
export const bulkLoadingSelector = () =>
  createSelector([bulkLoadingState], (loading) => loading);

// const errorState = (state, props) => state.users.get("error");

// export const errorSelector = () =>
//   createSelector([errorState], (error) => error);

const usersState = (state, props) =>
  state.users.get("users");

export const usersSelector = () =>
  createSelector([usersState], (users) => users);

// const usersShowState = (state, props) => {

//   const users = state.users.get("users");

//   if (users.size === 0) {
//     return {};
//   } else {
//     const id = parseInt(props.match.params.id);
//     const idx = lodash.findIndex(users, {
//       id: id,
//     });
//     if (idx !== -1) {
//       return users[idx];
//     }
//     return {};
//   }
// };

// export const usersShowSelector = () =>
//   createSelector([usersShowState], (users) => users);

// const paginationState = (state, props) => {
//   const pageObj = {};
//   pageObj.page = state.users.get("page");
//   pageObj.totalPages = state.users.get("total_pages");

//   return pageObj;
// };

// export const paginationSelector = () =>
//   createSelector([paginationState], (pageObj) => pageObj);

export const activeChildIdState = (state) =>
  state.users.get("activeChildId", 0);

export const activeChildIdSelector = () =>
  createSelector([activeChildIdState], (activeChildId) => activeChildId); 

export const allOrgUsersState = (state) =>
  state.users.get("users", []);

export const allOrgUsersSelector = () =>
  createSelector([allOrgUsersState], (users) => users); 
