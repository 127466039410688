import React from "react";
import { useSelector } from "react-redux";
import { Progress, Space, Tag } from "antd";
import { CheckCircleTwoTone, CloseCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import { currentViewSelector, questionsStatusSelector, supportOldUserResponseIdSelector, userResponsesSelector } from "../FirestoreInteractions/selector";
import { getRecursiveSegments } from "/src/views/Experiences/ExperienceHelperMethods";
import { getLabelFromIndexPath } from "/src/lib/utils/helperMethods";
import { SolarDocumentAddFill } from "/src/components/UI/Icons/SolarIcons";
import { segmentsSelector } from "/src/views/Segments/selector";
import { IoCheckboxSharp } from "react-icons/io5";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";

const QuestionStatusTagRender = (props) => {
  console.log("QuestionStatusTagRender props ==>", props);
  const {questionsStatus, segmentId, tagText, showFileIcon, hideGroupQuestionIndexPathLabel, isQuestionTypeGroup} = props;

  const segmentAllDescendants = useSelector(segmentsSelector(segmentId, isQuestionTypeGroup));
  const questionStatusFromSelector = useSelector(questionsStatusSelector);
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const currentViewFromUserInfo = useSelector(currentViewSelector);
  const questionsStatusFromProps = questionsStatus
  const newJoinViewEnabled = enabledFeatures.new_join_view_with_dynamic_config
  const isIndividualQuestionStatusTag = newJoinViewEnabled && ["resumeTest", "endTest"].includes(currentViewFromUserInfo)

  const finalQuestionsStatus = questionsStatusFromProps ? questionsStatusFromProps : questionStatusFromSelector; // For monitor view, questionsStatus is coming from props, for student view, it is coming from selector

  let qAttemptStatus = finalQuestionsStatus.find((q) => q.segment_id === segmentId) || {};

  let nestedQuestionsAttemptStatus = {
    totalQuestionsCount: 0,
    questionsAttemptCount: 0,
    questionsFileUploadedCount: 0
  }
  
  // In case of group question, we need to get all the child segments user responses attempted count to show the progress of the group question
  if(isQuestionTypeGroup && hideGroupQuestionIndexPathLabel) {
    segmentAllDescendants.forEach((s) => {
      if (s.question_segment_attributes && s.question_segment_attributes.question_type !== "group") {
        nestedQuestionsAttemptStatus.totalQuestionsCount += 1;

        finalQuestionsStatus.forEach((qStatus) => {
          if (qStatus.segment_id === s.id && qStatus.attempted) {
            nestedQuestionsAttemptStatus.questionsAttemptCount += 1;
            if (qStatus.files_uploaded) {
              nestedQuestionsAttemptStatus.questionsFileUploadedCount += qStatus.files_uploaded;
            }
            console.log("qStatus ==>", qStatus)
          }
          
        });
      }
    })
    console.log("nestedQuestionsAttemptStatus ==>", nestedQuestionsAttemptStatus, finalQuestionsStatus, segmentAllDescendants)
  }

  const shouldShowAttemptIcon = newJoinViewEnabled && showFileIcon && (qAttemptStatus.attempted || nestedQuestionsAttemptStatus.questionsAttemptCount > 0);

  let finalRender = []
  let innerRender, color, icon;
  let attemptedStatusTagBorderColor = ''
  if (qAttemptStatus.attempted) {
    attemptedStatusTagBorderColor = 'border-green-4'
  } else if (qAttemptStatus.files_uploaded) {
    attemptedStatusTagBorderColor = 'border-orange'
  }

  if (qAttemptStatus.attempted || nestedQuestionsAttemptStatus.questionsAttemptCount > 0) {
    innerRender = <CheckCircleTwoTone />;
    color = "processing";
    icon = "";
  } else {
    innerRender = <CloseCircleOutlined />;
    color = "";
    icon = "";
  }
  if(showFileIcon && (qAttemptStatus.files_uploaded || nestedQuestionsAttemptStatus.questionsFileUploadedCount > 0)) {
    icon = <SolarDocumentAddFill className={'upload-file-icon'} /> 
  }

  // finalRender.push(<Tooltip title={`${i+1}.${j+1}`}>{innerRender}</Tooltip>)
  const nestedQuestionsAttemptPercentage = (nestedQuestionsAttemptStatus.questionsAttemptCount / nestedQuestionsAttemptStatus.totalQuestionsCount) * 100
  if (isQuestionTypeGroup && hideGroupQuestionIndexPathLabel && nestedQuestionsAttemptPercentage < 100) {
    finalRender.push(
      <Tag 
        key={`question-attempt-status-${segmentId}`} 
        className={"group-question-progress"} 
        icon={icon} 
        color={"default"} 
        style={{ cursor: "default", "--progress-width": `${nestedQuestionsAttemptPercentage}%` }}
      >
        {tagText}
      </Tag>
    )
  } else {
    finalRender.push(
      <Tag 
        key={`question-attempt-status-${segmentId}`} 
        icon={icon} 
        color={color} 
        style={{ cursor: "default" }}
        className={isIndividualQuestionStatusTag ? `individual-question-status-tag ${attemptedStatusTagBorderColor}` : ''}
      >
        {shouldShowAttemptIcon ? <IoCheckboxSharp className='question-attempt-icon' />  : ''}
        {tagText}
      </Tag>
    );
  }

  return finalRender
}

const AttemptStatus = (props) => {
  const { experienceId, userId, questionsStatus, experienceTopics, wide = false, showFileIcon = false, hideGroupQuestionIndexPathLabel } = props;
  // questionsStatus is not coming in props for student exam view, this is only coming from monitor
  // const supportOldUserResponseId = useSelector(supportOldUserResponseIdSelector());
  console.log("Attempt status props ==>", props)
  let finalRender = [];
  const indexPath = []
  // TODO: sort item.questions_status by qNo first and loop thru question count of exp, match with qNo in attempt_status array. PROB - qNo is not overall - only per topic.... need to change while saving
  // for (let qNo = 1; qNo <= totalQuestions; qNo++) {
  //   let q = item.questions_status.find((it) => it.qNo == qNo)
  //   if(q && q.attempted){
  //     finalRender.push(<Badge count={10} />)
  //   }else{
  //     finalRender.push(<Badge count={1} />)
  //   }
  // }

  if (experienceTopics) {
    experienceTopics.forEach((topic, i) => {
      let options = {
        segment_type: "question",
        segments: topic.children,
      }
      const callback = (data, segment, index, level) => {
        console.log("data callback =======>", segment);
        let questionType = segment.question_segment_attributes.question_type
        const isQuestionTypeGroup = questionType === "group" ? true : false
        // let qindex = [i, j]
        indexPath.splice(level + 1)
        indexPath[level + 1] = index
        // console.log("2 index path ==>", level, indexPath, titleToRender)
        // hideGroupQuestionIndexPathLabel => To hide the nested question index path label for the new join view
        if (isQuestionTypeGroup && !hideGroupQuestionIndexPathLabel) {
          getRecursiveSegments("", segment, index, level + 1, indexPath, options, callback)
        } else {
          let innerTagRender =
          <QuestionStatusTagRender
            segmentId={segment.id}
            tagText={getLabelFromIndexPath(indexPath)}
            experienceId={experienceId}
            userId={userId}
            // responseIdentifier={ // attempt status getting from the questionsStatusSelector
            //   supportOldUserResponseId
            //     ? `eId${experienceId}_uId${userId}_qId${segment.question_segment_attributes.id}_sId${segment.id}`
            //     : `eId${experienceId}_uId${userId}_sId${segment.id}`}
            questionsStatus={questionsStatus} 
            showFileIcon={showFileIcon}
            hideGroupQuestionIndexPathLabel={hideGroupQuestionIndexPathLabel}
            isQuestionTypeGroup={isQuestionTypeGroup}>
          </QuestionStatusTagRender>
          finalRender.push(innerTagRender)
        } 
      }
      getRecursiveSegments("", topic.segment, i, 0, indexPath, options, callback)
    })
  }

  // if (experienceTopics) {
  //   experienceTopics.forEach((topic, i) => {
  //     let l1_questions = topic.children.filter(
  //       (segment) => segment.segment_type == "question" && segment.parent_id === parseInt(topic.segment.id)
  //     );
  //     l1_questions.forEach((segment, j) => {
  //       let questionType = segment.question_segment_attributes.question_type
  //       if(isQuestionTypeGroup){
  //         let l2_questions = topic.children.filter(
  //           (s1) => s1.segment_type == "question" && s1.parent_id === parseInt(segment.id)
  //         ); 
  //         l2_questions.forEach((segment, k) => {
  //           let questionType = segment.question_segment_attributes.question_type
  //           if(isQuestionTypeGroup){
  //             let l3_questions = topic.children.filter(
  //               (s2) => s2.segment_type == "question" && s2.parent_id === parseInt(segment.id)
  //             ); 
  //             l3_questions.forEach((segment, l) => {
  //               let questionType = segment.question_segment_attributes.question_type
  //               if(isQuestionTypeGroup){
  //                 // let l3_questions = segment.children.filter(
  //                 //   (segment) => segment.segment_type == "question" && segment.parent_id === parseInt(segment.id)
  //                 // ); 
                  
  //               }else{
  //                 // let innerTagRender = getTagRender(segment.id, `${i + 1}.${j + 1}.${k + 1}.${l + 1}`)
  //                 let innerTagRender = 
  //                   <QuestionStatusTagRender 
  //                     segmentId={segment.id}
  //                     tagText={`${i + 1}.${j + 1}.${k + 1}.${l + 1}`} 
  //                     experienceId={experienceId}
  //                     userId={userId}
  //                     responseIdentifier={
  //                       supportOldUserResponseId 
  //                       ? `eId${experienceId}_uId${userId}_qId${segment.question_segment_attributes.id}_sId${segment.id}`
  //                       : `eId${experienceId}_uId${userId}_sId${segment.id}`}
  //                     questionsStatus={questionsStatus}>
  //                   </QuestionStatusTagRender>
  //                 finalRender.push(innerTagRender)
  //               }
  //             })
  //           }else{
  //             // let innerTagRender = getTagRender(segment.id, `${i + 1}.${j + 1}.${k + 1}`)
  //             let innerTagRender = 
  //               <QuestionStatusTagRender 
  //                 segmentId={segment.id}
  //                 tagText={`${i + 1}.${j + 1}.${k + 1}`} 
  //                 experienceId={experienceId}
  //                 userId={userId}
  //                 responseIdentifier={
  //                   supportOldUserResponseId 
  //                   ? `eId${experienceId}_uId${userId}_qId${segment.question_segment_attributes.id}_sId${segment.id}`
  //                   : `eId${experienceId}_uId${userId}_sId${segment.id}`}
  //                 questionsStatus={questionsStatus}>
  //               </QuestionStatusTagRender>
  //             finalRender.push(innerTagRender)
  //           }
  //         })
  //       }else{
  //         // let innerTagRender = getTagRender(segment.id, `${i + 1}.${j + 1}`)
  //         let innerTagRender = 
  //           <QuestionStatusTagRender 
  //             segmentId={segment.id}
  //             tagText={`${i + 1}.${j + 1}`} 
  //             experienceId={experienceId}
  //             userId={userId}
  //             responseIdentifier={
  //               supportOldUserResponseId 
  //               ? `eId${experienceId}_uId${userId}_qId${segment.question_segment_attributes.id}_sId${segment.id}`
  //               : `eId${experienceId}_uId${userId}_sId${segment.id}`}
  //             questionsStatus={questionsStatus}>
  //           </QuestionStatusTagRender>
  //         finalRender.push(innerTagRender)
  //       }
  //     });
  //   });
  // }

  // item.questions_status.map((question, i) => {
  //   let itemRender = question.attempted ? <Badge count={1} /> : <Badge count={0} />
  //   finalRender.push(itemRender)
  // })
  return (
    <Space
      wrap={true}
      className={`${hideGroupQuestionIndexPathLabel && 'attempt-status-group-questions'} attempt-status-questions ${wide ? "max-w-600" : "max-w-300"}`}
    >
      {finalRender}
    </Space>
  );
};

export default AttemptStatus;
