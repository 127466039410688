import { useState } from "react";

export const usePinUnpin = () => {

  const [isPinned, setIsPinned] = useState(true);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const togglePin = () => {
    setPosition({ x: 0, y: 0 });
    setIsPinned(!isPinned);
  };

  const handleDrag = (e, data) => {
    setPosition({ x: data.x, y: data.y });
    setIsDragging(true);
  };

  const handleStop = () => {
    setIsDragging(false);
  };

  return {
    isPinned,
    position,
    isDragging,
    togglePin,
    handleDrag,
    handleStop,
  };
};