import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { put } from "redux-saga/effects";
import {
  actions as experienceActions,
} from "/src/views/Experiences/redux";
import { makeShowSaga } from "../saga";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "libraryexperience",
  singleResourceName: "experience",
  apiUrl: "/experiences",
  actionNames: [
    "FETCH",
    "CREATE",
    "SHOW",
    "UPDATE",
    "DUPLICATE",
    "DELETE",
    "PRINT",
  ],
});

const initialState = fromJS({
  experiences: [],
  printExperience: {},
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

export function* printSaga(action) {
  let url = `experiences_for_print/${action.payload.id}.json`;
  if (action.payload.user_id) {
    url += `?user_id=${action.payload.user_id}`;
  }
  console.log("getting print segments", url);
  try {
    yield put(actions.printStart());
    const response = yield axiosInstance.instance.get(url);
    // yield localStorage.setItem("token", response.data.token);
    console.log("response 33", response);
    yield put(actions.printSuccess(response.data));
  } catch (error) {
    yield put(actions.printFail({ error: error }));
  }
}

function printSuccess(state = initialState, action) {
  // return state.set("printloading", false).set("printExperience", {segments: action.payload.segments});
  return state
    .set("printloading", false)
    .set("printExperience", { experience: action.payload.experience });
}

export const actions = reduxCrud.getActions();
const libraryShowSaga = makeShowSaga(
  "libraryexperiences",
  actions,
  experienceActions
);
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  [actionTypes.PRINT_LIBRARYEXPERIENCE_SUCCESS]: printSuccess,
});
export const watchLibraryexperiences = reduxCrud.generateWatchSaga({
  [actionTypes.PRINT_LIBRARYEXPERIENCE]: printSaga,
  [actionTypes.SHOW_LIBRARYEXPERIENCE]: libraryShowSaga,
});

export default reduxCrud;
