import React, { useEffect, useRef, useState } from "react";

import { Tabs, Row, Col, Carousel } from "antd";
import { removeItemFromArray, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { TabAddIcon, CustomLabel, CustomTextButton, InputArea, Segment, SegmentData, TextArea, ShowText, ShowTextIndex, ShowTextContent, ShowTextFile, showConfirmModal } from '/src/components/UI/Segment/UIHelper';
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { cloneDeep, shuffle } from 'lodash';
import MediaShow from "/src/components/UI/Media/MediaShow"
import MediaHoverButton from "/src/components/UI/Media/MediaHoverButton"
import { DeleteFilled } from "@ant-design/icons";
import { DraggableTabs } from "/src/components/UI/DnD/DraggableTabs"
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const FlashCard = props => {
  console.log("Card Card props ==>", props);
  const { TabPane } = Tabs;
  const { question_type, showCorrectAnswerSwitch, segmentSettings, triggerStateUpdate, setRenderMath } = props
  let { parsedJSONObject, createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)
  const mrIntl = useTranslate();
  let textAnswerString = props.text_answer || ''
  const propsAttemptStatus = props.attempt_status || {}
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  }

  const card = {
    id: getUniqueId(),
    title: 'Card',
    items: [
      {
        title: mrIntl("FlashCard.front_side_title"),
        id: getUniqueId(),
        content: '',
      },
      {
        title: mrIntl("FlashCard.back_side_title"),
        id: getUniqueId(),
        content: '',
      }
    ]
  }

  if (createMode) {
    createMode = true
    parsedJSONObject = {
      segment_data: {
        cards: [card]
      },
      segment_version: '1.0.0'
    }
    if (question_type == 'flash_card') {
      parsedJSONObject = props.value || parsedJSONObject
    }
  }

  if (answerMode) {
    parsedJSONObject = shuffleItems(parsedJSONObject)
  }

  // if (autoCheckMode) {
  // 	let question_json = cloneDeep(props.teacher_json)
  // 	let answer_json = cloneDeep(parsedJSONObject)
  // 	parsedJSONObject = checkResponses(question_json, answer_json)
  // }

  console.log('Updated data ==>', parsedJSONObject)
  const [state, setState] = useState(parsedJSONObject)
  const [textAnswer, setTextAnswer] = useState(textAnswerString)
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject)
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
  const screenSettings = props.screenSettings || {}
  const cardsCarousel = useRef()

  const segment_data = state && state.segment_data
  const cards = segment_data && segment_data.cards || []

  const [cardsSetting, setCardsSetting] = useState({
    currentCardIndex: 0,
    currentCard: cards[0],
    cardsCount: cards.length,
    flipCard: false
  })

  const [tabSetting, setTabSetting] = useState({
    activeKey: cards[0].id.toString()
  })

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }, [])

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state)
        props.onChange(teacher_json)
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus)
        }

        props.onChange(result)
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    setState(state)
    console.log("stateUpdated count ==>", stateUpdatedCount)
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
  }

  function shuffleItems(json) {
    if (segmentSettings.shuffle) {
      const segment_data = json.segment_data
      segment_data.cards = shuffle(segment_data.cards)
    }

    return json
  }

  const addItem = () => {
    const newCards = cards
    newCards.push(card);

    const newSegmentData = {
      ...segment_data,
      cards: newCards
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
    setTabSetting({
      ...tabSetting,
      activeKey: newCards[newCards.length - 1].id.toString()
    })
  }

  const removeItem = (index) => {
    const newCards = removeItemFromArray(cards, index);

    const newSegmentData = {
      ...segment_data,
      cards: newCards
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)

    const newCardsCount = newCards.length
    let activeKey
    if (index >= newCardsCount)
      activeKey = newCards[newCardsCount - 1].id.toString()
    else {
      activeKey = newCards[index].id.toString()
    }
    setTabSetting({
      ...tabSetting,
      activeKey: activeKey
    })
  }

  const removeFile = (index, childIndex) => {
    const newCards = cards
    delete newCards[index].items[childIndex].files

    const newSegmentData = {
      ...segment_data,
      cards: newCards
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const onTabEdit = (targetKey, action) => {
    if (action == 'add') {
      addItem()
    } else if (action == 'remove') {
      const index = cards.findIndex((card) => card.id == targetKey)
      showConfirmModal({
        title: mrIntl("FlashCard.remove_card_confirm_msg"),
        onOk: () => {
          removeItem(index)
        },
        mrIntl: mrIntl
      })
    }
  }

  const onTabChange = (targetKey) => {
    setTabSetting({
      ...tabSetting,
      activeKey: targetKey
    })
  }

  const onTabDragEnd = (cards) => {
    const newCards = cards.slice()

    const newSegmentData = {
      ...segment_data,
      cards: newCards
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const onTextChanged = (index, data, childIndex) => {
    const newCards = cards
    newCards[index].items[childIndex].content = data

    const newSegmentData = {
      ...segment_data,
      cards: newCards
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const onSaveMedia = (index, files, childIndex) => {
    const newCards = cards
    newCards[index].items[childIndex].files = files

    const newSegmentData = {
      ...segment_data,
      cards: newCards
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const resetCards = () => {
    let student_json = cloneDeep(props.student_json)
    const newState = shuffleItems(student_json)
    saveState(newState)

    const cards = newState.segment_data.cards
    return cards
  }

  const showCard = (switchTo) => {
    let nextIndex, nextCard
    if (switchTo == 'prev') {
      nextIndex = cardsSetting.currentCardIndex - 1
      nextCard = cards[nextIndex]
    } else if (switchTo == 'next') {
      nextIndex = cardsSetting.currentCardIndex + 1
      nextCard = cards[nextIndex]
    } else if (switchTo == 'reset') {
      const cards = resetCards()
      nextIndex = 0
      nextCard = cards[nextIndex]
    }
    console.log("Card Card Carousel ==>", cardsCarousel)
    cardsCarousel.current.goTo(nextIndex)
    setCardsSetting({
      ...cardsSetting,
      flipCard: false,
      currentCardIndex: nextIndex,
      currentCard: {
        ...nextCard
      }
    })
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }

  const updateCardsSetting = (index) => {
    setCardsSetting({
      ...cardsSetting,
      flipCard: false,
      currentCardIndex: index,
      currentCard: cards[index]
    })
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }

  const flipCard = (value) => {
    setCardsSetting({
      ...cardsSetting,
      flipCard: true,
    })
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }

  console.log("Card Card state before render ==>", state, cards)

  return (
    <Segment>
      <SegmentData>
        {createMode ? <DraggableTabs
          addIcon={<TabAddIcon
            iconText= {mrIntl("CommonText.add")}
          ></TabAddIcon>}
          type="editable-card"
          tabPosition={'top'}
          activeKey={tabSetting.activeKey}
          onEdit={onTabEdit}
          onChange={onTabChange}
          canDrag={true}
          order={cards}
          setOrder={onTabDragEnd}
          className="flash-card-tabular-text-boxes"
        >
          {cards.map((card, index) => (
            <TabPane
              key={card.id}
              // tab={`Card ${index + 1}`}
              tab={mrIntl("FlashCard.card_tab_title", {index: index + 1 })}
              closeIcon={<DeleteFilled />}
            >
              {card.items.map((side, childIndex) => (
                <div style={{ "marginBottom": "20px" }}>
                  <CustomLabel key={side.title}> {side.title} </CustomLabel>
                  <TextArea>
                    <InputArea>
                      <BasicCKE
                        key={`basic-ck-${side.id}`}
                        data={side.content}
                        onTextChanged={(data) => onTextChanged(index, data, childIndex)}
                      >
                      </BasicCKE>
                      {side.files && <MediaShow
                        file={side.files[0]}
                        removeFile={(e) => removeFile(index, childIndex)}
                      >
                      </MediaShow>}
                    </InputArea>
                    <MediaHoverButton
                      onSaveMedia={(files) => onSaveMedia(index, files, childIndex)}
                    ></MediaHoverButton>
                  </TextArea>
                </div>
              ))}
            </TabPane>
          ))}
        </DraggableTabs> : <div>
            <Row>
              <Col
                span={24}
                className="flash-card-carousel"
              >
                <Carousel
                  ref={ref => {
                    cardsCarousel.current = ref
                  }}
                  afterChange={(index) => updateCardsSetting(index)}
                  dotPosition='none'
                >
                  {cards.map((card) => {
                    const side = cardsSetting.flipCard ? card.items[1] : card.items[0]
                    return (<ShowText className={`show-text ${cardsSetting.flipCard ? "back-side" : "front-side"} ${side.content && side.content.replace(/&nbsp;/g, '').trim().length > 0 ? "with-caption" : "without-caption"}`}>
                      {side.files && <ShowTextFile>
                        <MediaShow
                          file={side.files[0]}
                        >
                        </MediaShow>
                      </ShowTextFile>}
                      {side.content && <ShowTextContent dangerouslySetInnerHTML={{ __html: side.content }}
                      >
                      </ShowTextContent>}
                    </ShowText>)
                  })}
                </Carousel>

                {!cardsSetting.flipCard ? <CustomTextButton
                  buttonText={mrIntl("FlashCard.flip")}
                  type="primary"
                  clicked={(e) => flipCard()}
                ></CustomTextButton>
                  : <div>
                    {cardsSetting.currentCardIndex == (cardsSetting.cardsCount - 1) ?
                      <CustomTextButton
                        buttonText={mrIntl("FlashCard.reset")}
                        clicked={(e) => showCard('reset')}
                      ></CustomTextButton>
                      :
                      <CustomTextButton
                        buttonText={mrIntl("FlashCard.next")}
                        type="primary"
                        // disabled={cardsSetting.currentCardIndex == (cardsSetting.cardsCount - 1)}
                        clicked={(e) => showCard('next')}
                      ></CustomTextButton>
                    }
                  </div>}
              </Col>
            </Row>
          </div>}
      </SegmentData >
    </Segment>
  );
}
export default FlashCard;