import { fromJS } from "immutable";
import { MrReduxCrud, formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { unionBy } from "lodash";
import { message } from "antd";


const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "experience_user",
  actionNames: [
    "FETCH",
    "CREATE",
    "SHOW",
    "UPDATE",
    "DELETE",
    "PUBLISH_RESULTS",
    "RECOVER_ATTACHMENTS",
    "AUTO_MAP_HANDWRITTEN_ANSWERS",
    "EXPORT_ALL_TO_EXCEL",
    "RETAKE_EXPERIENCE",
    "SUBMIT_ATTACHMENTS",
    "PRINT_STUDENT_SUBMISSION",
    "SET_ACTIVE_EXPERIENCE_USER_ID",
    "SET_STUDENT_NAME_FORMAT",
    "SET_CURRENT_CRITERIA_ID",
    "SET_GRADING_MODAL_VISIBLE",
    "SET_OVERALL_GRADING_FORM_VISIBLE",
    "SET_LOADING",
    "SET_HAS_USER_RESPONSES",
    "SORT_BY_IDS_ORDER",
    "CONVERT_TO_ACHIEVEMENT_LEVEL",
    "CREATE_NEW_SUBMISSIONS",
    "CALCULATE_ACHIEVEMENT_LEVEL",
    "AUTO_ASSIGN_REVIEWERS",
    "ASSIGN_REVIEWERS",
    "BULK_UPLOAD_ANSWER_SHEETS",
    "GRADE_ALL_WITH_AI"
  ],
});

const initialState = fromJS({
  experience_users: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
  grade_loading: false,
  has_user_responses_in_redux: false
});



function setGradingModalVisible(state, action) {
  return state.set('gradingModalVisble', action.payload.visible)
}

function setOverallGradingFormVisible(state, action) {
  return state.set('overallGradingFormVisible', action.payload.visible)
}

function setActiveExperienceUserIdSuccess(state, action) {
  console.log("activeExperienceUserId action ==>", action)
  return state.set('activeExperienceUserId', action.payload.id)
}

function setStudentNameFormatSuccess(state, action) {
  return state.set('studentNameFormat', action.payload.student_name_format)
}

function setCurrentCriteriaIdSuccess(state, action) {
  return state.set('currentCriteriaId', action.payload.currentCriteriaId)
}

function setLoading(state, action) {
  const experienceUsers = state.get("experience_users")
  const index = experienceUsers.findIndex((eu) => eu.id === action.payload.experience_user_id)
  // experienceUsers[index].loading = action.payload.loading;
  const updatedExperienceUser = {
    ...experienceUsers[index],
    loading: action.payload.loading
  }
  return state.setIn(["experience_users", index], updatedExperienceUser)
}

function setHasUserResponses(state, action) {
  const experienceUsers = state.get("experience_users")
  const segment_id = action.payload.segment_id
  const eu_ids = action.payload.ids
  const eus_to_update = experienceUsers.filter((eu) => segment_id ? eu : (eu.has_user_responses_in_redux !== true && eu_ids.includes(eu.id)))
  
  let newState = state
  eus_to_update.forEach((eu) => {
    let updatedEU = {}
    if (segment_id) {
      const has_user_responses_for_segment_ids_in_redux = eu.has_user_responses_for_segment_ids_in_redux || []
      has_user_responses_for_segment_ids_in_redux.push(segment_id)
      updatedEU = {
        ...eu,
        has_user_responses_for_segment_ids_in_redux: has_user_responses_for_segment_ids_in_redux
      }
    } else {
      updatedEU = {
        ...eu,
        has_user_responses_in_redux: true
      }
    }

    const index = experienceUsers.indexOf(eu)
    newState = newState.setIn(["experience_users", index], updatedEU);
  })

  return newState
}



// const fetchSuccessFunc = (state, action) => {
//   console.log("experienceUsers fetchSuccessFunc", action);
//   const data = action.payload.data || {};

//   let formattedExperienceUsers = formatJsonApiData(data.experience_users)
//   let formattedUserResponses = formatJsonApiData(data.user_responses)

//   let newState = state.set("experience_users", formattedExperienceUsers).set("user_responses", formattedUserResponses)
  
//   newState = newState.set("firstAction", true).set("loading", false).set("fetchloading", false)
//   return newState
// }

function sortByIdsOrder(state, action) {
  const experienceUsers = state.get("experience_users")
  const { sortedExperienceUserIds } = action.payload
  experienceUsers.sort(
    (a, b) =>
      sortedExperienceUserIds.indexOf(a.id) -
      sortedExperienceUserIds.indexOf(b.id)
  );

  return state.set("experience_users", experienceUsers)
}

function fetchSuccess(state = initialState, action) {
  console.log("fetchSuccess action", action);

  const { data: { experience_users: experienceUsersData } = {} } = action.payload;
  const { success: successConfig, mergeFetchedData } = action.options || {};

  const newExperienceUsers = formatJsonApiData(experienceUsersData) || [];
  let experienceUsersToSet;

  if (mergeFetchedData) {
    let existingExperienceUsers = state.get("experience_users", []);
    if (existingExperienceUsers.hasOwnProperty("size")) {
      existingExperienceUsers = existingExperienceUsers.toJS();
    }
    experienceUsersToSet = unionBy(newExperienceUsers, existingExperienceUsers, "id");
  } else {
    experienceUsersToSet = newExperienceUsers;
  }

  let newState = state
    .set("loading", false)
    .set("fetchloading", false)
    .set("extra", {})
    .set("firstAction", true)
    .set("experience_users", experienceUsersToSet);

  if (successConfig?.showMessage) {
    message.success(successConfig.message);
  }
  
  return newState;
}

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  SET_ACTIVE_EXPERIENCE_USER_ID_EXPERIENCE_USER_SUCCESS: setActiveExperienceUserIdSuccess,
  SET_STUDENT_NAME_FORMAT_EXPERIENCE_USER_SUCCESS: setStudentNameFormatSuccess,
  SET_CURRENT_CRITERIA_ID_EXPERIENCE_USER_SUCCESS: setCurrentCriteriaIdSuccess,
  SET_GRADING_MODAL_VISIBLE_EXPERIENCE_USER_SUCCESS: setGradingModalVisible,
  SET_OVERALL_GRADING_FORM_VISIBLE_EXPERIENCE_USER_SUCCESS: setOverallGradingFormVisible,
  SET_LOADING_EXPERIENCE_USER_SUCCESS: setLoading,
  SET_HAS_USER_RESPONSES_EXPERIENCE_USER_SUCCESS: setHasUserResponses,
  SORT_BY_IDS_ORDER_EXPERIENCE_USER_SUCCESS: sortByIdsOrder,
  FETCH_EXPERIENCE_USER_SUCCESS: fetchSuccess
  // FETCH_EXPERIENCE_USER_SUCCESS: fetchSuccessFunc
});


export default reduxCrud;