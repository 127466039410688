import React from "react";
import PropTypes, { object } from 'prop-types';

import { MrSelect, MrCrudView, MrForm as WrappedMrForm } from "mr_react_framework";
import { actions } from "./redux";
import SegmentListItem from "./SegmentListItem/SegmentListItem";
import commonSelectors from "/src/App/commonSelectors";
import SegmentList from "./SegmentList/SegmentList";
import SegmentItemDetail from "./SegmentItemDetail/SegmentItemDetail";
import SegmentsFilter from "./SegmentsFilter/SegmentsFilter";
import { segmentsSelector } from "./selector";

import generateSegmentCreateForm from "./SegmentForms/SegmentCreateForm";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";


export const returnForm = (props) => {
  console.log("test form props", props);
  let form = generateSegmentCreateForm("segment", props)
  return form
};


const Segments = MrCrudView({
  resourceName: "segments",
  singleResourceName: "segment",
  // displayName: "Create/Edit Tests",
  resourceUrl: "/segments",
  // layout: OrgsLayout,
  filter: SegmentsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
    segments: segmentsSelector,
    activeExperience: activeAdjustedExperienceSelector,
    enabledFeatures: enabledFeaturesSelector,
    // TodO: replace with specific grading_setting and rubric selector
  },
  // listItem: SegmentListItem,
  forms: [
    { 
      name: "segments", 
      form: returnForm, 
      config: { 
        isModal: false, 
        formLayout: { 
          scrollToFirstError: true,
          // onFinishFailed: ({ values, errorFields, outOfDate }) => {
          //   const firsError = errorFields[0]
          //   console.log("fields are changed ==>", values, errorFields, outOfDate)
          // } 
        } 
      } 
    },
  ],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete", "rearrange", "duplicate"],
  // firstTimeFetchParams: { with_users: true }
  useOwnProps: true, // IMP - sends props to selectors
  config: {
    itemPreview: {
      widget: SegmentListItem
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: SegmentItemDetail
    },
    pagination: {
      show: false,
    },
    filter: {
      show: false,
      widget: (props) => {
        return <span><MrTranslate id={"CommonText.filter"}/></span>
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      widget: SegmentList,
      // forceReload: true
      // widget: (props) => {
      //   return <span>List Component</span>;
      // }
    }
  },
});

// Segments.defaultProps = {};

Segments.propTypes = {};
export default Segments;
