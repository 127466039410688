import React from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { aiSegmentsSelector } from "../selector";
import { PlusOutlined } from "@ant-design/icons";
import { addAllAIQuestionsToSegments } from "../GenerateAI/generateAIHelperMethods";
import { aiGenerateQuestionsConfigSelector } from "../GenerateAI/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

function AddAllAISegmentsButton({ addedAiSegments }) {
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const aiGenerateQuestionsConfig = useSelector(
    aiGenerateQuestionsConfigSelector()
  );
  const aiSegments = useSelector(aiSegmentsSelector());
  const {
    addAllButtonLoading,
    totalAIQuestionsGenerated,
    loading: aiGenerateLoading,
  } = aiGenerateQuestionsConfig;

  let allAIQuestionsAdded =
    addedAiSegments.current?.length === totalAIQuestionsGenerated ||
    aiGenerateLoading;

  return (
    <Button
      className="ai-add-all"
      type="primary"
      shape="round"
      disabled={allAIQuestionsAdded}
      icon={<PlusOutlined />}
      onClick={() => {
        addAllAIQuestionsToSegments(
          aiSegments,
          aiGenerateQuestionsConfig,
          2000,
          dispatch,
          mrIntl,
          addedAiSegments
        );
      }}
      loading={addAllButtonLoading}
    >
      {mrIntl("SegmentList.add_all")}
    </Button>
  );
}

export default AddAllAISegmentsButton;
