import React, { useContext, useEffect, useState } from "react";
import { MrSelect, MrCrudView, FormRenderer } from "mr_react_framework";
import { actions } from "./redux";
import GroupListItem from "./GroupListItem/GroupListItem";
import GroupsFilter from "./GroupsFilter/GroupsFilter";
import GroupItemDetail from "./GroupItemDetail/GroupItemDetail";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors from "/src/App/commonSelectors";
// import {usersState} from "./selector";
import { Button, Dropdown, Menu, Select } from "antd";
import GroupsList from "./GroupsList/GroupsList";
import { actions as genericActions } from "/src/App/genericRedux";
import _ from "lodash";
import dayjs from "dayjs";
import { SelectSubject } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import { SelectProgram } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import { SelectGrade } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
// import IntegrationModal from "/src/lib/GoogleClassroomIntegration/IntegrationModal";
import GoogleClassroomIntegration, {
  IntegrationContext,
} from "/src/lib/GoogleClassroomIntegration/GoogleClassroomIntegration";
import MrTranslate, {
  mrTranslate,
  useTranslate,
} from "/src/lib/MrTranslate/MrTranslate";
import CustomExperienceCrudList from "/src/views/Experiences/CustomExperienceCrudList";

// const getUsers = () => {
//   console.log ("calling");
//   fetch(`https://api.github.com/search/users?q=john`)
//     .then(res => res.json())
//     .then(({ items = [] }) => {
//       console.log ("items", items)
//     });

//   // fetch(`http://localhost:3005/api/v1/users`)
//   // .then(res => res.json())
//   // .then(({ users = [] }) => {
//   //   console.log ("users", users)

//   // });
// }

const SelectStudents = MrSelect({
  actions: genericActions,
  resourceName: "students",
  apiResourceName: "users",
  config: {
    url: "/users",
    // params: {by_role: "student", by_org_programme_id: org_programme_id, by_grade_id: grade_id}, //default is org_id on BE
    params: { by_role: "student" }, //default is org_id on BE
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.name, value: item.id };
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"CommonText.search_for_students"} />,
      mode: "multiple",
      style: { width: "400px" },
    },
  },
});

const SelectTeachers = MrSelect({
  actions: genericActions,
  resourceName: "teachers",
  apiResourceName: "users",
  config: {
    url: "/users",
    params: { by_role: "teacher_admin", page_size: 300 },
    // params: {by_role: "teacher"}, //default is org_id on BE
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        console.log("class selector item", item);
        return { label: item.name, value: item.id };
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"Groups.search_for_teachers"} />,
      mode: "multiple",
      // style: {width: "400px"},
    },
  },
});

const { Option, OptGroup } = Select;

const SelectStartTerm = MrSelect({
  actions: genericActions,
  resourceName: "terms",
  config: {
    url: "/terms",
    isJsx: true,
    // params: {by_org_programme_id: 8}, //get from top right dropdown
    processData: (data, props) => {
      console.log("data select groups terms", data);
      console.log("props select groups", props);

      let allTermGroups = [];
      let finalRender;
      data.map((item) => {
        if (item.children.length !== 0) {
          let optGroupChildren = [];
          item.children.map((child) => {
            const startDate = child.starts_on ? `(${dayjs(child.starts_on).format("MMM DD")}` : " "; // In the case of edlink, there were no dates for the default term.
            const endDate = child.ends_on ? `- ${dayjs(child.ends_on).format("MMM DD")})` : " ";
            let label = `${child.label} ${startDate} ${endDate}`;
            optGroupChildren.push(<Option value={child.id}>{label}</Option>);
          });
          let optGroup = (
            <OptGroup label={item.label}>{optGroupChildren}</OptGroup>
          );
          allTermGroups.push(optGroup);
          finalRender = allTermGroups;
        }
      });
      return <>{finalRender}</>;

      // return data.map((item) => {
      //   console.log("class selector item", item);
      //   return {label: item.name, value: item.id};
      // });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"CommonText.select_term"} />,
      // mode: "multiple",
      // style: {width: "400px"},
    },
  },
});

const SelectEndTerm = MrSelect({
  actions: genericActions,
  resourceName: "terms",
  config: {
    url: "/terms",
    isJsx: true,
    // params: {by_org_programme_id: 8}, //get from top right dropdown
    processData: (data, props) => {
      console.log("data select groups terms", data);
      console.log("props select groups", props);

      let allTermGroups = [];
      let finalRender;
      data.map((item) => {
        if (item.children.length !== 0) {
          let optGroupChildren = [];
          item.children.map((child) => {
            const startDate = child.starts_on ? `(${dayjs(child.starts_on).format("MMM DD")}` : " "; // In the case of edlink, there were no dates for the default term.
            const endDate = child.ends_on ? `- ${dayjs(child.ends_on).format("MMM DD")})` : " ";
            let label = `${child.label} ${startDate} ${endDate}`;
            optGroupChildren.push(<Option value={child.id}>{label}</Option>);
          });
          let optGroup = (
            <OptGroup label={item.label}>{optGroupChildren}</OptGroup>
          );
          allTermGroups.push(optGroup);
          finalRender = allTermGroups;
        }
      });
      return <>{finalRender}</>;

      // return data.map((item) => {
      //   console.log("class selector item", item);
      //   return {label: item.name, value: item.id};
      // });
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"CommonText.select_term"} />,
      // mode: "multiple",
      // style: {width: "400px"},
    },
  },
});

const GenericWidgetCreator = (hocProps, Component) => {
  return (props) => {
    let finalHocProps = {};
    if (typeof hocProps === "function") {
      finalHocProps = hocProps(props);
    }
    return <Component {...props} {...finalHocProps} />;
  };
};

const SelectGradeWidget = GenericWidgetCreator((props) => {
  return {
    config: {
      forceReload: true,
      params: {
        by_org_programme_id: props.formInstance.getFieldValue([
          "org_programme_id",
        ]),
      },
    },
  };
}, SelectGrade);

const getSelectGradeField = (sourceValues, mrIntl) => {
  return {
    type: "object",
    properties: {
      grade_id: {
        title: mrIntl("CommonText.select_grade"),
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectGradeWidget,
        widgetConfig: {
          disabled: sourceValues.id ? true : false,
        },
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.please_choose_a_grade"),
          },
        ],
      },
    },
  };
};

const GradeChildComponent = ({ getFieldValue, sourceValues, formInstance }) => {
  const orgProgrammeId = getFieldValue(["org_programme_id"]);
  const mrIntl = useTranslate();
  let finalRender = null;
  if (orgProgrammeId) {
    finalRender = (
      <FormRenderer
        formObj={getSelectGradeField(sourceValues, mrIntl)}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};

const SelectSubjectWidget = GenericWidgetCreator((props) => {
  return {
    config: {
      forceReload: true,
      params: {
        by_org_programme_id: props.formInstance.getFieldValue([
          "org_programme_id",
        ]),
      },
    },
  };
}, SelectSubject);

const getSelectSubjectField = (sourceValues, mrIntl) => {
  return {
    type: "object",
    properties: {
      subject_id: {
        title: mrIntl("CommonText.select_subject"),
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectSubjectWidget,
        widgetConfig: {
          disabled: sourceValues.id ? true : false,
        },
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.please_choose_a_subject"),
          },
        ],
      },
    },
  };
};

const SubjectChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const orgProgrammeId = getFieldValue(["org_programme_id"]);
  let finalRender = null;
  const mrIntl = useTranslate();
  if (orgProgrammeId) {
    finalRender = (
      <FormRenderer
        formObj={getSelectSubjectField(sourceValues, mrIntl)}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};

const SelectStartTermWidget = GenericWidgetCreator((props) => {
  return {
    config: {
      forceReload: true,
      params: {
        by_termable_id: props.formInstance.getFieldValue(["org_programme_id"]),
      },
    },
  };
}, SelectStartTerm);

const getSelectStartTermField = (mrIntl) => {
  return {
    type: "object",
    properties: {
      start_term_id: {
        title: mrIntl("Groups.select_start_term"),
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectStartTermWidget,
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.please_choose_a_start_term"),
          },
        ],
      },
    },
  };
};

const StartTermChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const orgProgrammeId = getFieldValue(["org_programme_id"]);
  const mrIntl = useTranslate();
  let finalRender = null;
  if (orgProgrammeId) {
    finalRender = (
      <FormRenderer
        formObj={getSelectStartTermField(mrIntl)}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};

const SelectEndTermWidget = GenericWidgetCreator((props) => {
  return {
    config: {
      forceReload: true,
      params: {
        by_termable_id: props.formInstance.getFieldValue(["org_programme_id"]),
      },
    },
  };
}, SelectEndTerm);

const getSelectEndTermField = (mrIntl) => {
  return {
    type: "object",
    properties: {
      end_term_id: {
        title: mrIntl("CommonText.title_select_end_term"),
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectEndTermWidget,
        rules: [
          {
            required: true,
            message: mrIntl("CommonText.please_choose_an_end_term"),
          },
        ],
      },
    },
  };
};

const EndTermChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const orgProgrammeId = getFieldValue(["org_programme_id"]);
  let finalRender = null;
  const mrIntl = useTranslate();
  if (orgProgrammeId) {
    finalRender = (
      <FormRenderer
        formObj={getSelectEndTermField(mrIntl)}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};

// const processInitialValuesForGroups = data => {
//   console.log('initial data==>', data);
//   let newData = {...data};
//   return newData;
// }

export const returnForm = (props) => {
  // getUsers();
  const { mrIntl } = props;

  let formValues = props.formState.values;
  const currentUser = props.currentUser;

  const isMB = currentUser.org.is_mb;

  console.log("props groups form", props);
  let form = {
    // opts = {name, formFinishProps, formState, values }
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      let group_users_attributes = [];
      let toCreate = null;
      let toRemove = null;
      let toUnarchive = null;
      let existing = null;
      let modified = null;
      // create/update
      // create if id exists in opts.values.group_teacher_ids but not in dataProps.item.group_users_teachers.pluck("user_id")
      if (opts.values.group_teacher_ids) {
        modified = opts.values.group_teacher_ids;
      }
      if (dataProps.item && dataProps.item.group_users_teachers) {
        existing = _.map(dataProps.item.group_users_teachers, "user_id");
      }
      toCreate = _.difference(modified, existing);
      toRemove = _.difference(existing, modified);
      toUnarchive = _.intersection(existing, modified);

      console.log("toCreate, toRemove ", toCreate, toRemove);
      if (toCreate) {
        toCreate.map((userId, i) => {
          group_users_attributes.push({
            relation: "teacher",
            user_id: userId,
          });
        });
      }
      if (toRemove) {
        toRemove.map((userId, i) => {
          console.log(
            "userId, existing ",
            userId,
            existing,
            dataProps.item.group_users_teachers
          );
          let user = _.find(dataProps.item.group_users_teachers, [
            "user_id",
            userId,
          ]);
          group_users_attributes.push({
            id: user.id,
            relation: "teacher",
            user_id: userId,
            archived: true,
          });
        });
      }
      if (toUnarchive) {
        toUnarchive.map((userId, i) => {
          console.log(
            "userId, existing ",
            userId,
            existing,
            dataProps.item.group_users_teachers
          );
          let user = _.find(dataProps.item.group_users_teachers, [
            "user_id",
            userId,
          ]);
          group_users_attributes.push({
            id: user.id,
            relation: "teacher",
            user_id: userId,
            archived: false,
          });
        });
      }

      opts.values.group_users_attributes = group_users_attributes;
      opts.values.label = opts.values.name;
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        name: {
          type: "string",
          title: mrIntl("CommonText.class_name"),
          placeholder: mrIntl("CommonText.class_name"),
          span: 12,
          rules: [
            {
              required: true,
              message: mrIntl("Groups.please_add_class_name"),
            },
          ],
        },
        // label: {
        //   type: "string",
        //   placeholder: "Class label" //?
        // },
        group_teacher_ids: {
          type: "string",
          title: mrIntl("CommonText.select_teachers"),
          span: 12,
          widget: SelectTeachers,
          wrapClassName: "select-teacher"
          // rules: [{
          //   required: true,
          //   message: "Please choose atleast 1 teacher"
          // }],
        },

        org_programme_id: {
          title: mrIntl("CommonText.select_program"),
          type: "string",
          span: 8,
          widget: SelectProgram,
          widgetConfig: {
            disabled: formValues.id ? true : false,
            disableMBIntegratedProgrammes: isMB
          },
          rules: [
            {
              required: true,
              message: mrIntl("CommonText.please_choose_a_program"),
            },
          ],
        },
        // grade_id: {
        //   title: "Select grade",
        //   type: "string",
        //   span: 24,
        //   setInitialValue: true,
        //   widget: SelectGradeWidget
        // },
        grade_id: {
          type: "string",
          span: 8,
          dependsOn: ["org_programme_id"],
          children: GradeChildComponent,
        },
        subject_id: {
          span: 8,
          type: "string",
          dependsOn: ["org_programme_id"],
          children: SubjectChildComponent,
        },
        start_term_id: {
          span: 8,
          type: "string",
          dependsOn: ["org_programme_id"],
          children: StartTermChildComponent,
        },
        end_term_id: {
          span: 8,
          type: "string",
          dependsOn: ["org_programme_id"],
          children: EndTermChildComponent,
        },
        // group_student_ids: {
        //   type: "string",
        //   title: "Select students",
        //   span: 24,
        //   widget: SelectStudents,
        // },
      },
    },
  };
  return form;
};

export const returnGroupUsersForm = (props) => {
  console.log("props groups form", props);
  let form = {
    // opts = {name, formFinishProps, formState, values }
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      let group_users_attributes = [];

      let toCreate = null;
      let toRemove = null;
      let toUnarchive = null;
      let existing = null;
      let modified = null;
      // create/update
      // create if id exists in opts.values.group_student_ids but not in dataProps.item.group_users_students.pluck("user_id")
      if (opts.values.group_student_ids) {
        modified = opts.values.group_student_ids;
      }
      if (dataProps.item && dataProps.item.group_users_students) {
        existing = _.map(dataProps.item.group_users_students, "user_id");
      }
      toCreate = _.difference(modified, existing);
      toRemove = _.difference(existing, modified);
      toUnarchive = _.intersection(existing, modified);

      console.log("toCreate, toRemove ", toCreate, toRemove);
      if (toCreate) {
        toCreate.map((userId, i) => {
          group_users_attributes.push({
            relation: "student",
            user_id: userId,
          });
        });
      }
      if (toRemove) {
        toRemove.map((userId, i) => {
          console.log(
            "userId, existing ",
            userId,
            existing,
            dataProps.item.group_users_teachers
          );
          let user = _.find(dataProps.item.group_users_students, [
            "user_id",
            userId,
          ]);
          group_users_attributes.push({
            id: user.id,
            relation: "student",
            user_id: userId,
            archived: true,
          });
        });
      }
      if (toUnarchive) {
        toUnarchive.map((userId, i) => {
          console.log(
            "userId, existing ",
            userId,
            existing,
            dataProps.item.group_users_teachers
          );
          let user = _.find(dataProps.item.group_users_students, [
            "user_id",
            userId,
          ]);
          group_users_attributes.push({
            id: user.id,
            relation: "student",
            user_id: userId,
            archived: false,
          });
        });
      }

      opts.values.group_users_attributes = group_users_attributes;

      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        group_student_ids: {
          type: "string",
          title: <MrTranslate id={"Groups.add_students"} />,
          span: 24,
          widget: SelectStudents,
        },
      },
    },
  };
  return form;
};

// for Google Classroom Create button as dropdown
function CustomCreateBtn(parentProps) {
  const integrationContext = useContext(IntegrationContext);
  const menu = getMenu({ ...parentProps, integrationContext });
  // const [
  //   enableGoogleClassRoomIntegration,
  //   setEnableGoogleClassRoomIntegration,
  // ] = useState();
  return (
    <>
      {/* Note change onVisibleChange to onOpenChange when antd 5 merged */}
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        // onVisibleChange={() => {
        //   setEnableGoogleClassRoomIntegration(true);
        // }}
      >
        <Button type="primary">Create</Button>
      </Dropdown>
      {/* {enableGoogleClassRoomIntegration && <GoogleClassroomIntegration />} */}
    </>
  );
}

// function GoogleClassroomIntegrationChild(props) {
//   const integrationContext = useContext(IntegrationContext);
//   const [localModalStatus, setLocalModalStatus] = useState(false);
//   const googleCourses = integrationContext.googleCourses;
//   const getStudents = integrationContext.getStudents;

//   const handleIntegration = () => {
//     console.log("integrationContext", integrationContext);
//     if (
//       integrationContext &&
//       integrationContext &&
//       integrationContext.handleAuthClick
//     ) {
//       integrationContext.handleAuthClick({
//         callback: () => {
//           setLocalModalStatus(true);
//         },
//       });
//     }
//   };

//   const [students, setStudents] = useState();
//   useEffect(() => {
//     googleCourses.map((item) => {
//       setStudents(getStudents(item.id));
//     });
//   }, [googleCourses.length]);

//   return (
//     <>
//       <span onClick={handleIntegration}>Import from Google Classroom</span>
//       <IntegrationModal
//         modalTitle={"Google Classroom Integration"}
//         modalStatus={localModalStatus}
//       >
//         {students}
//       </IntegrationModal>
//     </>
//   );
// }

// function GoogleClassroomMenuComponent(props) {
//   return (
//     <GoogleClassroomIntegration>
//       <GoogleClassroomIntegrationChild />
//     </GoogleClassroomIntegration>
//   );
// }

const getMenu = (props) => {
  console.log("getMenu props", props);
  const { history, match } = props;

  // const url = match.url;
  return (
    <Menu
      onClick={({ item, key, keyPath, domEvent }) => {
        if (key === "create") {
          // history.push(`${url}/create`);
        } else if (key === "importFromGoogleClassroom") {
          console.log("importFromGoogleClassroom");
        }
      }}
      // items={[
      //   {
      //     label: <>Create </>,
      //     key: 'create',
      //   },
      //   {
      //     type: 'divider',
      //   },
      //   {
      //     label: "Import from Google Classroom",
      //     key: 'importFromGoogleClassroom',
      //   }
      // ]}
    >
      <Menu.Item key={"create"}>Create</Menu.Item>
      <Menu.Divider />
      <Menu.Item key={"importFromGoogleClassroom"}>
        {/* Import from Google Classroom */}
        {/* <GoogleClassroomMenuComponent /> */}
        <GoogleClassroomIntegration />
      </Menu.Item>
    </Menu>
  );
};

const getForms = (props) => {
  console.log("getForms =====>", props);
  // const { mrIntl } = props
  const { intl } = props
  const mrIntl = mrTranslate(intl); 

  const forms = [
    {
      name: "groups",
      form: returnForm,
      config: {
        // processInitialValues: processInitialValuesForGroups,  // Commenting it for now - will go after fix
        // title: { show: true, value: "Class" },
        title: {
          show: true,
          createValue: mrIntl("Groups.create_class"),
          editValue: mrIntl("Groups.edit_class"),
        },
        isModal: false,
        modalConfig: { width: "", wrapClassName: "class-create-modal" },
        actions: { submitText: mrIntl("CommonText.submit") },
        submitConfig: {
          success: {
            showMessage: true,
            message: mrIntl("Groups.class_saved_successfully_msg"),
          },
          error: {
            showMessage: true,
            message: mrIntl("Groups.error_in_saving_class"),
          },
        },
      },
    },
    { name: "groupUsers", form: returnGroupUsersForm },
  ];
  return forms;
};

const Groups = MrCrudView({
  resourceName: "groups",
  singleResourceName: "group",
  // displayName: "Create/Edit Class",
  resourceUrl: "/groups",
  // layout: OrgsLayout,
  filter: GroupsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
    // users: usersState
  },
  // listItem: GroupListItem, //allowed but now using itemPreview below
  forms: getForms,
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  config: {
    itemPreview: {
      widget: GroupListItem,
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: GroupItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      style: {
        width: 6,
        align: "right"
      },
      config: {
        search: {
          show: false // default hide in filter render
        },
        create: {
          show: true
        },
        actions: {
          show: true //commenting it because the actions buttons is also showing in the Classes tab as we are using CustomExperienceCrudList layout for both
        }
      }
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      widget: GroupsList,
      layout: CustomExperienceCrudList,
      forceReload: true,
      // createBtn: {
      //   component: CustomCreateBtn,
      // },
    },
  },
});

// Groups.defaultProps = {};

Groups.propTypes = {};
export default Groups;
