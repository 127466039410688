import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appRegionSelector, currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector } from '/src/views/Auth/Login/selector';
import { isFirebaseAuthenticatedSelector } from '/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector';
import { actions as orgActions } from "/src/views/Orgs/redux";

const CheckAIUsage = () => {
  const currentUser = useSelector(currentUserSelector());
  const dispatch = useDispatch();
  const isFirebaseAuthenticated = useSelector(isFirebaseAuthenticatedSelector);
  const embed = useSelector(getEmbeddedSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const appRegion = useSelector(appRegionSelector());
  const isChina = appRegion === "china" ? true : false;

  useEffect(() => {
    if(isFirebaseAuthenticated && !embed && enabledFeatures.ai_generate_questions_with_prompt && !isChina && currentUser && (currentUser.role === "admin" || currentUser.role === "teacher") && currentUser.org.custom_fields) {
      dispatch(orgActions.checkIfOrgHasExceededAiQuota({orgId: currentUser.org_id, aiCreditsUSD: currentUser.org.custom_fields.ai_credits_usd}))
    }
  }, [isFirebaseAuthenticated])

  return null;
}

export default CheckAIUsage;
