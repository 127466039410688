import React, { useState } from "react";
import { Row, Col, Alert } from "antd";
import { checkMob } from "/src/lib/utils/helperMethods";
import { Route, useRouteMatch } from "react-router-dom";
import Topics from "/src/views/Segments/Topics/Topics";
import TopicItemDetail from "/src/views/Segments/Topics/TopicItemDetail/TopicItemDetail";
import LibraryModal from "/src/views/Library/LibraryModal";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useSelector } from "react-redux";
import GenerateAIWithPrompt from "/src/views/Segments/GenerateAI/GenerateAIWithPrompt";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { aiGenerateModeSelector } from "/src/views/Segments/GenerateAI/selector";
import { isNewGenerateQuestionsWithAI } from "/src/views/Segments/GenerateAI/generateAIHelperMethods";

const ExperienceCreate = (props) => {
  console.log("ExperienceCreate props ==>", props);


  
  const { experienceProps } = props;
  
  const {
    experience,
    experienceViewMode,
    embedded,
    editAllowed,
    rearrangeMode,
  } = experienceProps;
  const mrIntl = useTranslate()
  const isMobile = checkMob();
  const isPamoja = experience.custom_fields.block;

  const { path } = useRouteMatch();

  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const aiGenerateMode = useSelector(aiGenerateModeSelector())

  const newGenerateQuestionsWithAI = isNewGenerateQuestionsWithAI(enabledFeatures);

  let defaultLibraryModalConfig = {
    modalVisible: false,
    addFromLibAtIndex: null,
    addFromLibPreviousId: null,
    addFromLibDestinationPosition: null,
    addFromLibPrevPosition: null,
    addFromLibNextPosition: null,
  }
  const [libraryModalConfig, setLibraryModalConfig] = useState(defaultLibraryModalConfig);  

  // const [libraryModalVisible, setLibraryModalVisible] = useState(false);
  // const [addFromLibAtIndex, setAddFromLibAtIndex] = useState(false);
  // const [addFromLibPreviousId, setAddFromLibPreviousId] = useState(false);
  // const [addFromLibDestinationPosition, setAddFromLibDestinationPosition] = useState(false);
  // const [addFromLibPrevPosition, setAddFromLibPrevPosition] = useState(false);
  // const [addFromLibNextPosition, setAddFromLibNextPosition] = useState(false);


  // One of those things that are needed in 2 child components - used in Add From Library
  const libraryModalProps = {
    // experience: experience,
    libraryModalConfig,
    setLibraryModalConfig
    // libraryModalVisible: libraryModalVisible,
    // addFromLibAtIndex: addFromLibAtIndex,
    // setAddFromLibAtIndex: setAddFromLibAtIndex,
    // setLibraryModalVisible: setLibraryModalVisible,
    // setAddFromLibDestinationPosition,
    // addFromLibDestinationPosition,
    // setAddFromLibPreviousId,
    // addFromLibPreviousId,
    // addFromLibPrevPosition,
    // setAddFromLibPrevPosition,
    // addFromLibNextPosition,
    // setAddFromLibNextPosition
  };

  // let title = experience.name

  // TILL ABOVE - all in separate pageheader

  let topicSpan = 5;
  let topicOffset = 1;
  let segmentSpan = 17;
  let segmentOffset = 1;
  let aiGenerateFilterSpan = 0;
  if (embedded) {
    if (isMobile) {
      topicSpan = 24;
      topicOffset = 0;
      segmentSpan = 24;
      segmentOffset = 0;
    } else {
      // topicSpan = 4;
      topicSpan = 5;
      topicOffset = 0;
      // segmentSpan = 19;
      segmentSpan = 18;
      segmentOffset = 0;
    }
  } else {
    if (isMobile) {
      topicSpan = 24;
      topicOffset = 0;
      segmentSpan = 24;
      segmentOffset = 0;
      // } else if (isPinned) {
      //   topicSpan = 2
      //   topicOffset = 0
      //   segmentSpan = 17
      //   segmentOffset = 0
    } else if (newGenerateQuestionsWithAI) {
      topicSpan = 4;
      topicOffset = 1;
      segmentSpan = 14;
      segmentOffset = 1;
      if (aiGenerateMode) {
        aiGenerateFilterSpan = 4;
      }
    } else {
      topicSpan = 5;
      topicOffset = 1;
      segmentSpan = 17;
      segmentOffset = 1;
    }
  }

  let showMBLinkedAlert = false;
  // let showMBLinkedAlert = true;
  if (experience.is_mb_linked && !embedded) {
    // showing alert mb linked and submission is also going to MB task
    showMBLinkedAlert = true;
  }

  let finalRender = (
    <React.Fragment>
      {/* <Row className="m-b-15">
        <Col span={22} offset={1}>
          {showMBLinkedAlert && (
            <Alert
              message= {mrIntl("ExperienceCreate.this_assessment_is_linked_to_a_manage_bac_banner")}
              type="info"
              showIcon
              action={<Space direction={"vertical"}  className={"alert-message-actions"}>
                <Button size="small" type={"ghost"} href={"https://managebac.zendesk.com/hc/en-us/articles/4410202672013"} target={"_blank"}>
                  <MrTranslate id={"ExperienceCreate.create_from_mb_btn"}/>
                </Button>
                <Button size="small" type={"ghost"} href={"https://managebac.zendesk.com/hc/en-us/articles/4410269026957"} target={"_blank"}>
                 <MrTranslate id={"ExperienceCreate.link_with_mb_btn"}/>
                </Button>
                <Button size="small" type={"ghost"} href={"https://managebac.zendesk.com/hc/en-us/articles/4410278998925"} target={"_blank"}>
                  <MrTranslate id={"ExperienceCreate.grade_with_mb_btn"}/> 
                </Button>
              </Space>}
            />
          )}
        </Col>
      </Row> */}
      <Row>
        {experience.status === "published" &&
          !embedded &&
          !experience.questionbank && (
            <Col span={24}>
              <Col span={isMobile ? 22 : 18} offset={isMobile ? 1 : 3} style={{ marginBottom: "10px" }}>
                <Alert
                message={mrIntl("ExperienceCreate.alert_message_warning_draft")}
                  // message="This assessment has been published. Students can now take this assessment based on the start time. If you wish to make any changes, unpublish first."
                  type="success"
                  showIcon
                />
              </Col>
            </Col>
          )}
        {experience.status === "closed" && !embedded && (
          <Col span={24}>
            <Col span={isMobile ? 22 : 18} offset={isMobile ? 1 : 3} style={{ marginBottom: "10px" }}>
              <Alert
                message={mrIntl("ExperienceCreate.this_assessment_has_been_closed")}
                type="warning"
                showIcon
              />
            </Col>
          </Col>
        )}
        {/* <Col span={embedded ? 4 : 5} offset={isMobile ? 0 : 1} style={isPamoja ? {"visibility":"hidden"} : ""}> */}
        <Col
          span={topicSpan}
          offset={topicOffset}
          style={isPamoja ? { visibility: "hidden" } : ""}
        >
          {/* <h4>Topics</h4> */}
          {/* pass this prop and hide edit and add buttons */}
          {/* experienceViewMode={experienceViewMode} */}
          <Route
            path={`${path}`}
            render={(routeProps) => (
              <Topics
                // Change this after updating topic item details
                experienceProps={{
                  // Remove this item - for now required in TopicItemDetails
                  item: experience,
                  ...experienceProps,
                }}
                editAllowed={editAllowed}
                listType="topics list"
                rearrangeMode={rearrangeMode}
                experienceViewMode={experienceViewMode}
                params={{
                  by_experience_id: experience.master_editable ? experience.id : experience.original_id, // see APL-2880
                  by_segment_type: "section",
                }}
                createUpdateParams={{
                  experience_id: experience.id,
                  parent_id: experience.id,
                  parent_type: "experience",
                }}
                isRoute={false}
                {...routeProps}
                config={{
                  routes: {
                    show: false,
                  }
                }}
              />
            )}
          />
        </Col>
        {/* <Col span={17} offset={embedded ? 1 : 1}> */}
        <Col span={segmentSpan} offset={segmentOffset}>
          {/* <h4>Segments</h4> */}
          <Route
            path={`${path}/topics/:topicsId`}
            render={(routeProps) => (
              <Topics
                listType="segments"
                experienceProps={{
                  // Remove this item - for now required in TopicItemDetails
                  item: experience,
                  ...experienceProps,
                }}
                rearrangeMode={rearrangeMode}
                experienceViewMode={experienceViewMode}
                parentId={experience.master_editable ? experience.id : experience.original_id} // see APL-2880
                parentName={"experience"}
                params={{
                  // by_experience_id: experience.experience_id, //was wrong, and seems like not being used since no segments fetch anymore here - getting with topic show
                  by_experience_id: experience.master_editable ? experience.id : experience.original_id, // see APL-2880
                }}
                createUpdateParams={{
                  // experience_id: experience.experience_id, //was wrong, and seems like not being used
                  experience_id: experience.id,
                }}
                embedded={embedded}
                libraryModalProps={libraryModalProps}
                isRoute={false}
                {...routeProps}
                config={{
                  routes: { fetch: false },
                  itemDetail: { widget: TopicItemDetail },
                }}
              />
            )}
          />
        </Col>
        {aiGenerateMode && (
          <Col className="generate-ai-with-prompt" span={aiGenerateFilterSpan}>
            <GenerateAIWithPrompt key="generate-ai-with-prompt" />
          </Col>
        )}

        {!embedded && <LibraryModal libraryModalProps={libraryModalProps} experience={experience} />}
      </Row>
    </React.Fragment>
  );

  return finalRender;
};

export default ExperienceCreate;
