import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { rdsTableCellTools } from "/src/components/UI/CKEditor/CKEConfig";
import { loadScript } from "/src/lib/utils/helperMethods";
// import { startLoadingScripts } from 'react-async-script-loader'

const CellEditorModal = (props) => {
  console.log("CellEditor text input props ==>", props);
  const {
    mathModalSetting,
    setMathModalSetting,
    onCellsChanged,
    currentCell,
  } = props;
  const { cell, col, row } = currentCell;
  // const [currentData, setCurrentData] = useState(cell.content);
  const editor = useRef()

  useEffect(() => {
    const initializeEditor = () => {
      if (window.com && window.com.wiris.jsEditor.JsEditor) {
        editor.current = window.com.wiris.jsEditor.JsEditor.newInstance({ language: "en" });
        editor.current.insertInto(document.getElementById("editorContainer"));
        editor.current.setMathML(cell.content);
      }
    };

    if (!window.com.wiris.jsEditor) {
      loadScript('https://www.wiris.net/demo/editor/editor', initializeEditor)
    } else {
      initializeEditor()
    }

  }, [mathModalSetting.visible]);

  // useEffect(() => {
  //   if (mathModalSetting.visible) {
  //     // startLoadingScripts(['https://www.wiris.net/demo/editor/editor'], () => {
  //     //   console.log("Wiris editor loaded", window.com.wiris.jsEditor.JsEditor);
  //     //   editor.current = window.com.wiris.jsEditor.JsEditor.newInstance({ language: "en" })
  //     //   editor.current.insertInto(document.getElementById("editorContainer"));
  //     //   editor.current.setMathML(cell.content)
  //     // })
  //   }
  // }, [mathModalSetting.visible]);

  // useEffect(() => {
  //   if (mathModalSetting.visible && editor.current) {
  //     editor.current.insertInto(document.getElementById("editorContainer"));
  //     editor.current.setMathML(cell.content)
  //   }
  // }, [mathModalSetting.visible]);

  console.log("cellData", currentCell);
  const handleOk = () => {
    const changes = [
      {
        cell: cell,
        row: row,
        col: col,
        // value: currentData
        value: editor.current.getMathML(),
      },
    ];

    if (onCellsChanged) {
      console.log("onCellsChanged", changes);
      onCellsChanged(changes, true);
    }

    setMathModalSetting({
      ...mathModalSetting,
      visible: false,
    });
  };

  const handleCancel = () => {
    setMathModalSetting({
      ...mathModalSetting,
      visible: false,
    });
  };

  // const onTextChanged = (data) => {
  //   setCurrentData(data);
  // };

  return (
    <Modal
      title={mathModalSetting.title}
      open={mathModalSetting.visible}
      closable={false}
      maskClosable={false}
      keyboard={false}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
    >
      <div style={{"width":"100%", "height":"350px"}} id="editorContainer"></div>
      {/* <BasicCKE
        data={cell.content}
        tools={rdsTableCellTools}
        onTextChanged={(data) => onTextChanged(data)}
      ></BasicCKE> */}
    </Modal>
  );
};

export default CellEditorModal;
