import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { getScreenshot } from "/src/lib/utils/helperMethods";

 const TakeScreenshots = (props) => {
  console.log("TakeScreenshots =======>", props);
  const { experienceId } = props;

  const dispatch = useDispatch()

  const uploadScreenshot = (src) => {
    console.log("Returned response ==>>>",src)
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        token: token,
      },
    };
    const url =
      (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "attachments";
  
    const formData = new FormData();
    formData.append("url", src);
  
    axios.post(url, formData, config)
      .then((response) => {
        const responseData = response.data;
        console.log("Returned response ==>", responseData);
        if(responseData.url){
          dispatch(
            firestoreInteractionActions.setLogs({
              logging: true,
              log_type: "experience", // NOTE: @shikhar Log type should be experience and category should be monitor_screenshot 
              category: "monitor_screenshot",
              msg: "",
              url: responseData.url,
              itemId: experienceId,
              experienceId: experienceId,
              collection: "experience",
            })
          );
        }       
      })
      .catch((error) => {
        console.log(
          "Something went wrong in take screenshot ==>",
          error
        );
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        getScreenshot(document.getElementById('root'),
          (url) => {
            uploadScreenshot(url)
          }
        )
      } catch (error) {
        Sentry.captureException(error);
      }
     
    }, 30000);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory 
  //return () => {};
  }, []);

  return <> </>;
};

export default TakeScreenshots;