import React from "react";
import _ from "lodash";
import {
  Select,
  Input,
  Form,
} from "antd";

import styled from "styled-components";


const Option = Select.Option;
const ErrorDiv = styled.span`
  div.srv-validation-message {
    color: #9f3a38;
    font-size: 12px;
  }
`;
export const buildOptions = (options, valueKey, textKey, extraOptions = {}) => {
  if (!options) {
    return [];
  }

  let newOptions = [];
  let optionObj, text, value;

  options.forEach(element => {
    if (typeof element === "string") {
      text = value = element;
    } else if (typeof element === "object") {
      if (valueKey === "object") {
        value = element;
      } else {
        value = element[valueKey];
      }
      text = element[textKey];
    }
    text = _.capitalize(text);

    if (value === "" && text === "") {
      text = "All";
    }
    optionObj = {
      key: text,
      text: text,
      value: value
    };
    newOptions.push(optionObj);
  });
  let finalOptions = [];
  if (extraOptions.clearOptions) {
    // return [{ key: "All", text: "All", value: "" }, ...newOptions];
    finalOptions = [{ key: "All", text: "All", value: "" }, ...newOptions];
  } else {
    finalOptions = newOptions;
  }
  const optionsToRender = finalOptions.map(item => {
    return (
      <Option key={item.key} value={item.value}>
        {item.text}
      </Option>
    );
  });
  return optionsToRender;
};
const input = props => {
  let toRender;
  let {
    controlName,
    type,
    placeholder,
    prefix,
    elementType,
    label,
    noLabel,
    disabled,
    isFormItem,
    mainForm,
    value,
    validationRules
  } = props;

  if (!type) {
    type = "text";
  }
  if (!elementType) {
    elementType = "input";
  }
  if (!disabled) {
    disabled = false;
  }
  if (!isFormItem) {
    isFormItem = false;
  }
  if (!placeholder) {
    placeholder = controlName;
  }
  if (!label) {
    if (!noLabel) {
      label = controlName;
    }
  }

  let prefixElement;

  if (!prefix) {
    prefix = null;
  } else {
    if (prefix.type === "element") {
      prefixElement = prefix.element;
    }
  }

  let onChange = event => {
    // debugger;
    console.log("event.target.value", event.target.value);
    if (props.hasOwnProperty("changed")) {
      let extraProps = {};
      if (mainForm) {
        extraProps.mainForm = mainForm;
      }
      props.changed(event, extraProps);
    }
  };
  let elementProps = {
    type,
    placeholder,
    disabled,
    prefix: prefixElement,
    onChange
  };
  let selectProps = {
    onChange
    // options: props.options,
  };

  const options = buildOptions(props.options);
  let inputElement;
  console.log("elementType", elementType);
  switch (elementType) {
    case "select":
      inputElement = <Select {...selectProps}>{options}</Select>;
      break;

    default:
      inputElement = <input {...elementProps} />;
      break;
  }
  toRender = inputElement;
  let finalControlName = controlName;
  console.log("toRender", toRender);
  debugger;
  if (isFormItem) {
    let formItemLayout = {
      layout: "horizontal"
      // label,
    };

    let initialValue = value;
    if (!initialValue) {
      if (props.default) {
        initialValue = props.default;
      }
    }
    // debugger;
    toRender = (
      <Form.Item {...formItemLayout}>
        {mainForm.getFieldDecorator(finalControlName, {
          initialValue,
          onChange,
          rules: validationRules
        })(inputElement)}
      </Form.Item>
    );
  }

  return toRender;
  // text input

  // password

  // datepicker

  // datetimepicker

  // timepicker

  // select/dropdown

  // is a different component altogether
};

// const input = props => {
//   let toRender;
//   let containerClasses = ["ui", "input"];
//   let elementClasses = [classes.InputElement];
//   let fieldClasses = ["field"];
//   let inputElement;
//   // if (props.invalid && props.shouldValidate && props.touched) {
//   //   elementClasses.push(classes.Invalid);
//   //   fieldClasses.push("error");
//   // }
//   let hasError = false;

//   if (props.invalid && (props.touched || props.showInvalid)) {
//     hasError = true;
//     // elementClasses.push(classes.Invalid);
//   } else {
//     hasError = false;
//   }
//   if (hasError) {
//     fieldClasses = ["field", "error"];
//   } else {
//     fieldClasses = ["field"];
//   }
//   let onChange;
//   if (
//     props.elementType === "dropdown" ||
//     props.elementType === "datetime" ||
//     props.elementType === "date"
//   ) {
//     onChange = (event, { value }) => {
//       let extraOpts = { ...props.extraOpts };
//       // extraOpts.itemObj = _.where(props.options, { id: value, })[0];
//       if (props.hasOwnProperty("options")) {
//         extraOpts.itemObj = props.options.find(element => {
//           if (element.id === parseInt(value)) {
//             return element;
//           }
//           return null;
//         });
//       }

//       props.changed(value, props.controlName, extraOpts);
//     };
//   } else if (props.elementType === "checkbox") {
//     onChange = () => {};
//   } else {
//     onChange = event => {
//       // debugger;
//       props.changed(event, props.controlName, props.extraOpts);
//     };
//   }
//   // const onChange = props.changed;

//   let elementProps = {
//     type: props.type,
//     placeholder: props.placeholder,
//     value: props.value,
//     onChange: onChange,
//     disabled: props.disabled || false
//   };
//   let selectProps = {
//     value: props.value,
//     onChange: onChange,
//     options: props.options
//   };
//   let finalOptions = [];
//   if (
//     props.options &&
//     typeof props.options[0] === "object" &&
//     props.options[0].hasOwnProperty("value")
//   ) {
//     finalOptions = props.options;
//   } else {
//     if (props.clearOptions) {
//       finalOptions = buildOptions(
//         props.options,
//         props.valueKey,
//         props.textKey,
//         { clearOptions: true }
//       );
//     } else {
//       finalOptions = buildOptions(props.options, props.valueKey, props.textKey);
//     }
//   }
//   let value = props.value;
//   // console.log("props.validator", props.validator);
//   // if (props.validator) {
//   //   if (props.validator.valueFields.hasOwnProperty(props.controlName)) {
//   //     value = getNested(props.controlName, props.validator.valueFields);
//   //   }
//   // }
//   // let value = getNested(props.controlName, props.validator.valueFields);
//   // if (props.hasOwnProperty("valueKeyMap")) {
//   //   if (Array.isArray(value)) {
//   //     let newValue = [];
//   //     value.map((item, i) => {
//   //       newValue.push(item[props.valueKeyMap]);
//   //     });
//   //     value = newValue;
//   //   }
//   // }
//   let dropdownSpecificProps = {
//     item: props.item || false,
//     search: props.search || false,
//     selection: props.selection || false,
//     options: finalOptions,
//     placeholder: props.placeholder,
//     value,
//     multiple: props.multiple || false,
//     onChange: onChange
//   };

//   let checkboxSpecificProps = {
//     label: props.placeholder,
//     value,
//     onChange: onChange
//   };

//   // let datetimePickerSpecificProps = {
//   //   showTimePicker: props.showTimePicker || true,
//   //   isOpen: props.isOpen || false,
//   //   moment: props.value || moment(),
//   //   maxDate: props.maxDate,
//   //   minDate: props.minDate,
//   //   onChange: onChange,
//   // };
//   let datetimePickerSpecificProps = {
//     name: props.name || "dateTime",
//     placeholder: "Date Time",
//     value: props.value,
//     iconPosition: "left",
//     onChange: onChange,
//     label: props.label
//   };
//   let dropdownProps = {
//     form: props.form || false,
//     onChange: onChange
//   };
//   let checkboxProps = {
//     form: props.form || true
//   };

//   let inputClassesString = elementClasses.join(" ");
//   let completeOverride = false;
//   const message = <ErrorDiv>{props.message}</ErrorDiv>;
//   switch (props.elementType) {
//     case "input":
//       inputElement = <input className={inputClassesString} {...elementProps} />;
//       break;
//     case "textarea":
//       inputElement = (
//         <textarea className={inputClassesString} {...elementProps} />
//       );
//       break;
//     case "select":
//       inputElement = (
//         <Dropdown style={{ paddingTop: "30px" }} {...dropdownProps}>
//           {message}
//         </Dropdown>
//       );
//       break;
//     case "dropdown":
//       completeOverride = true;
//       if (dropdownProps.form) {
//         inputElement = (
//           <Form.Dropdown
//             error={hasError}
//             label={props.label}
//             {...dropdownSpecificProps}
//           />
//         );
//       } else {
//         inputElement = <Dropdown {...dropdownSpecificProps} />;
//       }
//       break;
//     case "date":
//       completeOverride = true;
//       inputElement = (
//         <DateInput error={hasError} {...datetimePickerSpecificProps} />
//       );

//       break;
//     case "datetime":
//       completeOverride = true;
//       const shortcuts = {
//         Today: moment(),
//         Yesterday: moment().subtract(1, "days"),
//         Clear: ""
//       };
//       if (true) {
//         inputElement = (
//           <DateTimeInput error={hasError} {...datetimePickerSpecificProps} />
//         );
//       } else {
//         inputElement = <DatetimePicker {...datetimePickerSpecificProps} />;
//       }
//       break;
//     case "select":
//       inputElement = (
//         <select className={inputClassesString} {...selectProps}>
//           {props.options.map(option => (
//             <option key={option.value} value={option.value}>
//               {option.displayValue}
//             </option>
//           ))}
//         </select>
//       );
//       break;
//     case "checkbox":
//       if (checkboxProps.form) {
//         inputElement = (
//           <Form.Checkbox
//             error={hasError}
//             label={props.label}
//             {...checkboxSpecificProps}
//           />
//         );
//       } else {
//         inputElement = <Checkbox {...checkboxSpecificProps} />;
//       }

//       break;
//     default:
//       inputElement = <input className={inputClassesString} {...elementProps} />;
//       break;
//   }
//   let label;
//   if (props.label) {
//     label = <label className={classes.Label}>{props.label}</label>;
//   }
//   if (props.icon) {
//     containerClasses.push("left");
//     containerClasses.push("icon");
//     const iconClasses = ["icon", props.icon];
//     label = <i className={iconClasses.join(" ")} />;
//   }
//   if (props.width) {
//     fieldClasses.push(props.width);
//     fieldClasses.push("wide");
//   }

//   if (completeOverride) {
//     // toRender = inputElement;
//     toRender = [];
//     toRender.push(inputElement);
//     // toRender.push(message);
//   } else {
//     toRender = (
//       <div className={fieldClasses.join(" ")}>
//         {label}
//         <div className={containerClasses.join(" ")}>{inputElement}</div>
//         {message}
//       </div>
//     );
//   }
//   return toRender;
// };

export default Input;
