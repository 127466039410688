import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from "react-router-dom";
import { MrFormComponent, Spinner } from "mr_react_framework";
import { Button, Divider, Tabs, Tooltip } from 'antd';
import "../Login/Login.scss";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from "/src/views/Experiences/redux";
import { forgotpasswordloadingSelector } from "../Login/selector";
import queryString from "query-string";
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { LeftOutlined } from "@ant-design/icons";
import { appTypeSelector, sebConfigFileLoadingSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { openUrl } from "/src/lib/utils/helperMethods";
import DownloadAppsModal from "/src/views/OfflineApps/DownloadAppsModal";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { getDeviceInfo } from '/src/App/OfflineApp/offlineAppHelper';

const getGuestJoinCodeForm = (props, joinCodeDefaultValue, mrIntl, shouldShowStudentCodeInput) => {

  return {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      // opts.values.email = opts.values.email.toLowerCase().trim()
      // trim and downcase this
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        // email: {
        //   type: "string",
        //   format: "email",
        //   defaultValue: "sample@email.com",
        //   placeholder: "Email",
        //   className: "login-email",
        //   rules: [{
        //     required: true,
        //     message: "Please enter a valid email"
        //   }]
        // },
        name: {
          type: "string",
          // format: "email",
          // defaultValue: "sample@email.com",
          // placeholder: "Full name",
          placeholder: mrIntl("GuestJoinCode.full_name"),
          // className: "login-email",
          rules: [{
            required: true,
            // message: "Please enter your full name"
            message: mrIntl("GuestJoinCode.please_enter_your_full_name"),
          }]
        },
        join_code: {
          type: "string",
          // format: "email",
          default: joinCodeDefaultValue,
          // placeholder: "Join Code",
          placeholder: mrIntl("GuestJoinCode.join_code"),
          // className: "login-email",
          rules: [{
            required: true,
            // message: "Please enter a join code"
            message: mrIntl("GuestJoinCode.please_enter_a_join_code"),
          }]
        },
        // include_s_code: {
        //   type: "string",
        //   className: studentCode,
        //   widget: () => {
        //     // return "Enter student code to resume test or view results"
        //     return mrIntl("GuestJoinCode.enter_student_code_to_resume_test_or_view_results")
        //   }
        // },
        s_code: {
          type: "string",
          className: shouldShowStudentCodeInput ? "" : "hidden",
          // format: "email",
          // default: joinCodeDefaultValue,
          // placeholder: "Student code (optional)",
          placeholder: mrIntl("GuestJoinCode.student_code")
        },

      },
    }
  };
}

const GuestJoinCode = (props) => {
  const [shouldShowStudentCodeInput, setShouldShowStudentCodeInput] = useState(false);
  const [lockdownMode, setLockDownMode] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const appType = useSelector(appTypeSelector());
  const dispatch = useDispatch();
  const location = useLocation()
  const loading = useSelector(forgotpasswordloadingSelector)
  let joinCodeDefaultValue = ""
  const mrIntl = useTranslate()
  // const [joinCodeDefaultValue, setJoinCodeDefaultValue] = useState("")
  const [jsonForm, setJsonForm] = useState()
  const locationQueryParams = queryString.parse(location.search);

  const sebConfigFileLoading = useSelector(sebConfigFileLoadingSelector());

  useEffect(() => {
    if(location.search.indexOf("code") > -1){
      console.log("joinCode location.search", location.search)
      joinCodeDefaultValue = locationQueryParams.code
      // setJoinCodeDefaultValue(locationQueryParams.code)
      }
    let jsonFormTemp = getGuestJoinCodeForm(props, joinCodeDefaultValue, mrIntl, shouldShowStudentCodeInput);
    console.log("jsonFormTemp", jsonFormTemp)
    setJsonForm(jsonFormTemp)
  }, [shouldShowStudentCodeInput]);

  const handleSubmit = (data) => {
    // const finalData = {experience: {...data}};
    console.log("handleSubmit", data);
    dispatch(actions.fetchWithJoinCode(data, {
      success: {
        showMessage: true,
        message: mrIntl("GuestJoinCode.test_found"),
      },
      successCallback: (value) => {
        setLockDownMode(value);
      }
    }))
  }

  const mainForm = <MrFormComponent
    key={"mrForm"}
    {...props}
    visible={true}
    resourceForm={{form:{...jsonForm}, config: {isModal: false, visible: true }}}
    item={{}}
    loading={loading}
    // resourceName={"forgotPassword"}
    // formConfig={{actions: {submitText: "Login", showCancelBtn: false}}}
    formConfig={{actions: {submitText: mrIntl("GuestJoinCode.find_test"), showCancelBtn: false}, }}
    formProps={{
    }}
    // jsonForm={jsonForm}
    // schema={jsonForm.schema}
    handleOk={handleSubmit}
    handleSubmit={handleSubmit}
    // styleProps={styleProps}
  />;

  const onChange = (activeKey) => {
    const showStudnetCodeInput = activeKey === '2' ? true : false;
    setActiveTabKey(activeKey);
    setShouldShowStudentCodeInput(showStudnetCodeInput);
  };

  const formContent = (
    <div>
      <br />
      {jsonForm && mainForm}
    </div>
  );

  const items = [
    {
      key: '1',
      label: mrIntl("GuestJoinCode.start_a_new_test"),
      children: formContent,
    },
    {
      key: '2',
      label: mrIntl("GuestJoinCode.resume_test_or_view_result"),
      children: formContent,
    },
  ];

  const deviceInfo = getDeviceInfo(appType);
  const os = deviceInfo.os;
  const queryParams = queryString.parse(location.search);
  

  const SecureModeComponent = () => {

    const handleJoinLinkClick = () => {
      const webBaseUrl = import.meta.env.VITE_WEB_URL;
      let currentUrl = window.location.href;
      let urlWithoutProtocol = webBaseUrl.split("https://")[1];
      let joinCodeQueryParam =
        queryParams.code && currentUrl.includes("guest")
          ? `/guest/join?code=${queryParams.code}`
          : "";
      let expUrl = `assessprep://${urlWithoutProtocol + joinCodeQueryParam}`;
      console.log("with join link===>", expUrl)
      openUrl(expUrl);
    }
    
    return (
      <>
        <div className='auth-form-container lockdown-app'>
          <h2>{mrIntl("GuestJoinCode.lockdown_app_required")}</h2>
          <p>{mrIntl("GuestJoinCode.click_to_launch_the_app_and_start_your_test")}</p>
          {appType == "web" && (os == "Windows" || os == "Linux") && (
            <Tooltip title={mrIntl("AuthLayout.please_make_sure_seb_is_installed")}>
              <Button
                shape=""
                type="primary"
                disabled={sebConfigFileLoading}
                loading={sebConfigFileLoading}
                onClick={() => {
                  if (sebConfigFileLoading) {
                    return;
                  }
                  dispatch(
                    loginActions.sebConfigFile(
                      {},
                      { successCallback: openUrl }
                    )
                  );
                }}
              >
                {mrIntl("AuthLayout.launch_seb")}
              </Button>
            </Tooltip>
          )}
          {appType === "web" && (os === "Mac OS" || os === "Linux") && (
            <Button
              shape=""
              type="primary"
              onClick={() => handleJoinLinkClick()}
            >
              {mrIntl("GuestJoinCode.launch_assessprep_mac_app")}
            </Button>
          )}
          {appType === "web" &&
            (os === "Windows" || os === "Mac OS" || os === "Linux") && (
              <DownloadAppsModal showAppDownloadBtnText={true} />
            )}
        </div>
        <br />
        <div className="back-to-login" onClick={() => setLockDownMode(false)}>
          <LeftOutlined /> {mrIntl("GuestJoinCode.back")}
        </div>
      </>
    );
  }
  

  return (
    <div className={"forgot-pass-main auth-form-container guest-form"}>
      {lockdownMode ? (
        <SecureModeComponent />
      ) : (
        <div>
          <br />
          <h2>{mrIntl("GuestJoinCode.take_test_as_a_guest")}</h2>
          <br />
          <Tabs activeKey={activeTabKey} items={items} onChange={onChange} />
          <div className={"back-to-login"}>
            <Link className="back-to-login-page-link" to={"/auth/login"}>
              <LeftOutlined /> {mrIntl("GuestJoinCode.back_to_login")}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

GuestJoinCode.defaultProps = {}

GuestJoinCode.propTypes = {}

export default GuestJoinCode;