import React from "react";
import PropTypes, { object } from 'prop-types';
import { useSelector } from "react-redux";

import { MrSelect, MrCrudView, MrForm as WrappedMrForm } from "mr_react_framework";
import { actions } from "./redux";
import {actions as genericActions} from "/src/App/genericRedux";
import SegmentListItem from "../SegmentListItem/SegmentListItem";
import commonSelectors from "/src/App/commonSelectors";
import { buildOptionsArr } from "/src/lib/utils/helperMethods";
import { FormRenderer } from "mr_react_framework";
import SegmentItemDetail from "../SegmentItemDetail/SegmentItemDetail";
import SegmentsFilter from "../SegmentsFilter/SegmentsFilter";

import { getFinalStudentJSON } from "../InteractiveHelpers"
import { unmountComponentAtNode } from "react-dom";

import generateSegmentCreateForm from "../SegmentForms/SegmentCreateForm";
import { librarySegmentsSelector } from "../selector";
import SegmentList from "../SegmentList/SegmentList";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";


export const returnLibraryForm = (props) => {
  console.log("librarySegments form props", props);
  let form = generateSegmentCreateForm("librarysegment", props)
  return form
};

const LibrarySegments = MrCrudView({
  resourceName: "librarysegments",
  singleResourceName: "segment",
  // displayName: "Create/Edit Tests",
  resourceUrl: "/segments",
  // layout: OrgsLayout,
  filter: SegmentsFilter,
  isRoute: true,
  selectorsObj: {
    librarysegments: librarySegmentsSelector,
    enabledFeatures: enabledFeaturesSelector,
    // activeExperience: activeAdjustedExperienceSelector,
    ...commonSelectors,
  },
  // listItem: SegmentListItem,
  forms: [
    { name: "librarySegments", form: returnLibraryForm, config: { isModal: false } }
  ],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  useOwnProps: true, // IMP - sends props to selectors
  config: {
    itemPreview: {
      widget: SegmentListItem
    },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: SegmentItemDetail
    },
    pagination: {
      show: false,
    },
    filter: {
      show: false,
      widget: (props) => {
        return <span><MrTranslate id={"CommonText.filter"}/></span>
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "list",
      widget: SegmentList,
      // forceReload: true
      // widget: (props) => {
      //   return <span>List Component</span>;
      // }
    }
  },
});

// LibrarySegments.defaultProps = {};

LibrarySegments.propTypes = {};
export default LibrarySegments;
