import React from "react";
import { MrCrudView } from "mr_react_framework";
import { actions } from "./redux";
import FeedbacksList from "./FeedbacksList/FeedbacksList";
import commonSelectors from "/src/App/commonSelectors";
import FeedbackFileUpload from "./FeedbackFileUpload";
import FeedbackFilter from "./FeedbackFilter/FeedbackFilter";
import { SelectFeedbackType } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const returnForm = () => {
  let form = {
    schema: {
      type: "object",
      properties: {
        message: {
          type: "string",
          placeholder: <MrTranslate id={"CommonText.message"} />,
        },
        feedback_type: {
          type: "string",
          widget: SelectFeedbackType,
          placeholder: <MrTranslate id={"CommonText.select_feedback_type"}/>,
        },
        attachments_json: {
          type: "object",
          properties: {
            images: {
              type: "string",
              title: <MrTranslate id={"CommonText.upload_files"}/>,
              widget: FeedbackFileUpload,
              widgetConfig: {},
            },
          },
        },
      },
    },
  };
  return form;
};

const Feedbacks = MrCrudView({
  resourceName: "feedbacks",
  singleResourceName: "feedback",
  // displayName: "Create/Edit feedback",
  resourceUrl: "/feedbacks",
  // filter: BoardsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  filter: FeedbackFilter,
  // listItem: BoardListItem,
  forms: [{ name: "feedbacks", form: returnForm }],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span><MrTranslate id={"CommonText.item_detail"}/> {JSON.stringify(props.item)}</span>;
      },
    },
    itemDetail: {
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span><MrTranslate id={"CommonText.item_detail"}/> {JSON.stringify(props.item)}</span>;
      },
      // widget: ProgrammeItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      // widget: (props) => {
      //   return selectFeedbackType;
      // },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      widget: FeedbacksList,
      // widget: (props) => {
      // return <span>List</span>;
      // },
    },
  },
});

// Plans.defaultProps = {};

Feedbacks.propTypes = {};
export default Feedbacks;
