import React, { useEffect, useRef, useState } from "react";
import { FabricCanvas, globalCanvasConfig, useFabric } from "/src/components/UI/Canvas/FabricCanvas";
import FabricTools from "/src/components/UI/Canvas/FabricTools";
import {
  updateImagesSRCForCanvas,
  getParsedJSONObject,
} from "/src/views/Segments/InteractiveHelpers";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { cloneDeep } from "lodash";
import { Segment, SegmentData } from "/src/components/UI/Segment/UIHelper";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import SegmentItemHeader from "/src/views/Segments/SegmentItemDetail/SegmentItemHeader";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { Checkbox } from "antd";
import { useSelector } from "react-redux";
import { printModeSelector } from "/src/views/Experiences/selector";
const { shapeStyle, hotspotStyle } = globalCanvasConfig;

const FabricDrawing = (props) => {
  console.log("Fabric Drawing props", props);
  const {
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
    experienceSettings,
    unique_segment_identifier,
  } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);

  let { json_uuid } = parsedJSONObject;
  const mrIntl = useTranslate()
  let textAnswerString = props.text_answer || "";
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  if (createMode) {
    parsedJSONObject = {
      segment_data: {},
      segment_version: "1.0.0",
    };
    if (question_type == "fabric_drawing") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }

  // parsedJSONObject = updateImagesSRCForCanvas(parsedJSONObject, props.offlineView)

  const [state, setState] = useState(parsedJSONObject);
  const [textAnswer, setTextAnswer] = useState(textAnswerString);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    updatedCount: 0,
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitchValue: false,
    showLoader: false,
  });
  const screenSettings = props.screenSettings || {};
  const printMode = useSelector(printModeSelector())
  // const attemptStatus = useRef(attemptStatusObject);

  let currentJSON, text_answer, attempt_status;
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject;
    text_answer = textAnswerString;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    text_answer = textAnswer;
    attempt_status = attemptStatus;
  }

  currentJSON = currentJSON || {};

  const segment_data = currentJSON?.segment_data || {};
  const cd = segment_data.canvas_data;
  const canvas_data = (typeof cd == "string" ? JSON.parse(cd) : cd) || {};

  const enableModifyingOfObjects = segment_data.enableModifyingOfObjects;
  console.log("enableModifyingOfObjects ==>", enableModifyingOfObjects)

  const [fabricReady, setCanvasFabricReady] = useState(false);
  const [refreshCanvas, setRefreshCanvas] = useState(getUniqueId())
  const canvasRef = useRef({});
  const fabricRef = useFabric((currentCanvas) => {
    if (currentCanvas) {
      console.log("Current canvas in useFabric ==>", currentCanvas);
      currentCanvas._customEventListeners = {
        onObjectAdded: (options) => {
          const object = options.target;

          console.log("object role ", object, object.role, attemptStatus);
          if (object.role == "student") {
            setJSONAttempted(true);
          }
        },
        onObjectModified: (options) => {
          const object = options.target;

          console.log("object modified ", object);
          setJSONAttempted(true);
        },
      };
      currentCanvas.toolType = {}; // TO Avoid sharing of tools between all canvas of page initialized
      currentCanvas.canvasInstancesForUndo = [cd];
      currentCanvas.canvasInstancesForRedo = [];
      canvasRef.current = currentCanvas;
      setCanvasFabricReady(true);
    }
  });

  const canvas = canvasRef.current || {};
  canvas.fabricReady = fabricReady
  canvas.role = createMode ? "teacher" : "student"
  canvas.shapeStyle = {
    ...shapeStyle,
    fill: "rgba(73, 189, 212, 0)",
    perPixelTargetFind: false,
  }

  if (!createMode && !answerMode) {
    canvas.notEvented = true;
  } else {
    canvas.notEvented = false;
  }

  useEffect(() => {
    setCanvasFabricReady(false)
  }, [unique_segment_identifier])

  useEffect(() => {
    if (canvas.fabricReady) {
      canvas.resizeCanvas();
      updateCanvasData(parsedJSONObject);
    }
  }, [canvas.fabricReady]);

  // Presentation mode watching data change - use case question wise grading, live responses
  useEffect(() => {
    if (canvas.fabricReady && presentationMode && json_uuid) {
      updateCanvasData(parsedJSONObject);
    }
  }, [json_uuid]);

  // When parent component want to update its state - use case reading mode, pause mode
  useEffect(() => {
    if (canvas.fabricReady && triggerStateUpdate) {
      updateCanvasData(parsedJSONObject);
    }
  }, [triggerStateUpdate]);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
    } else if (answerMode && stateUpdatedCount > 1) {
      if (props.onChange && stateSetting.canvasLoadedFromJSON) {
        let result = {
          response_json: cloneDeep(state),
          // text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus),
          // attempt_status: cloneDeep(attemptStatus.current),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  
  const saveState = (state) => {
    if (!presentationMode) {
      setState(state);
      setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
    }
  };

  const saveTextAnswer = (data) => {
    setTextAnswer(data);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  function updateCanvasData(state) {
    if (state.errorInLoadFromJSON) {
      resetQuestion();
      return;
    }
    const segment_data = state.segment_data || {};
    const cd = segment_data.canvas_data;
    let canvas_data = (typeof cd == "string" ? JSON.parse(cd) : cd) || {};
    const enableModifyingOfObjects = segment_data.enableModifyingOfObjects

    if (canvas_data) {
      // let allowCanvasToUpdate = checkIfCanvasNeedToUpdated(props)
      setStateSetting({
        ...stateSetting,
        showLoader: true,
      });
      canvas.clearAll();
      if (!canvas_data.objects) {
        setStateSetting({
          ...stateSetting,
          showLoader: false,
        });
        return;
      }
      canvas_data = updateImagesSRCForCanvas(
        canvas_data,
        state.segment_version
      );
      canvas.loadFromJSON(
        canvas_data,
        function () {
          // if ($rootScope.printingStarted) {
          //   console.log('label', $rootScope.printWaitForInitQuestionsCount);
          //   $rootScope.printWaitForInitQuestionsCount += 1;
          // }
          console.log("Canvas is loaded with canvas data ==>", canvas_data);
          canvas.resizeCanvas();
          updateCanvasObjects(enableModifyingOfObjects);
          // canvas.renderAll.bind(canvas);
          setStateSetting((stateSetting) => {
            return {
              ...stateSetting,
              showLoader: stateSetting.errorInLoadFromJSON || false,
              canvasLoadedFromJSON: !stateSetting.errorInLoadFromJSON,
            };
          });
        },
        function (o, object) {
          console.log("Loaded objects are ==>", o, object);
          if (!object) {
            console.log("Something wrong with this object ==>", o, object);
            setStateSetting({
              ...stateSetting,
              errorInLoadFromJSON: true,
            });
          }
          // console.log('Loaded Object ==>', object);
          // if (!props.answerMode && object) {
          //   object.hasControls = false;
          //   object.selectable = false;
          //   object.evented = false;
          // }
        }
      );
    }
  }

  function updateCanvasObjects(enableModifyingOfObjects) {
    canvas.forEachObject(function (object) {
      if (answerMode) {
        console.log("updateCanvasObject ==>", object.role, enableModifyingOfObjects)
        if (object.role === "teacher" && !enableModifyingOfObjects) {
          object.selectable = false
          object.evented = false
          object.notDeletable = true
        }
      }

      if (presentationMode) {
        object.selectable = false;
        object.evented = false;
      }

      if (autoCheckMode) {
      }
    });
    canvas.renderAll();
  }

  const saveCanvasData = (data) => {
    const newCanvasData = {
      ...canvas_data,
      ...data,
    };

    const newSegmentData = {
      ...segment_data,
      canvas_data: newCanvasData,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const setJSONAttempted = (value) => {
    console.log("setJSONAttempted ==>", attemptStatus, value)
    if (answerMode) {
      // attemptStatus.current.json_attempted = true
      setAttemptStatus({
        ...attemptStatus,
        json_attempted: true,
      });
    }
  };

  const resetQuestion = () => {
    let student_json = cloneDeep(props.student_json);
    const newState = student_json || {
      segment_data: {},
      segment_version: "1.0.0",
    };
    
    // attemptStatus.current.json_attempted = false
    // attemptStatus.current.reset_count = attemptStatus.current.reset_count + 1
    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    
    saveState(newState);
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: false,
    });
    setRefreshCanvas(getUniqueId())
    updateCanvasData(newState);
  };

  const setProperty = (type, value) => {
    const newSegmentData = {
      ...segment_data,
      [type]: value,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    setRefreshCanvas(getUniqueId())
    saveState(newState);
  };

  let toolBar;
  if (createMode) {
    toolBar = [
      "move",
      "iText",
      "image",
      "line",
      "arrow",
      "rectangle",
      "circle",
      "dot",
      "polygon",
      "path",
      "fillColor",
      // "strokeColor",
      "bringToFront",
      "sendToBack",
      "undo",
      "redo",
      "delete",
      "clearAll",
      "resources",
    ];
  } else {
    toolBar = [
      "move",
      "iText",
      // "image",
      "line",
      "arrow",
      "rectangle",
      "circle",
      "dot",
      "polygon",
      "path",
      "fillColor",
      // "strokeColor",
      "bringToFront",
      "sendToBack",
      "undo",
      "redo",
      "delete",
      "resources",
    ];

    if (experienceSettings && experienceSettings.mode == "online") {
      toolBar.splice(2, 0, "image");
    }
  }

  console.log("Drawing state before render ==>", stateSetting.showLoader, state, attemptStatus, canvas);
  return (
    <Segment>
      <SegmentData
        key={`segment-data-${unique_segment_identifier}`}
        className="interactive-questions"
        tabIndex={-1}
        onKeyDown={(e) => {
          if (!presentationMode) {
            canvas.onKeyDown(e);
          }
        }}
      >
        <FabricCanvas
          key={`fabric-canvas-${unique_segment_identifier}`}
          fabricRef={fabricRef}
          canvas={canvas}
          saveCanvasData={saveCanvasData}
          showLoader={stateSetting.showLoader}
          loaderMessage={
            stateSetting.errorInLoadFromJSON &&
            mrIntl("CommonText.something_wrong_reset_question_msg")
          }
          refreshCanvas={refreshCanvas}
          autoSetActiveImageAfterInsert={true}
        />
        {!presentationMode && !printMode && (
          <FabricTools
            canvas={canvas}
            toolBarConfig={{
              toolBar: toolBar,
              drawMode: true,
              resources: true,
            }}
          />
        )}

        {createMode && <Checkbox
          checked={enableModifyingOfObjects}
          onChange={(e) => {
            setProperty("enableModifyingOfObjects", e.target.checked);
          }}
        >
          {mrIntl("FabricDrawing.enable_modifying_of_objects")}
        </Checkbox>}
      </SegmentData>

      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        saveTextAnswer={saveTextAnswer}
        resetQuestion={resetQuestion}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default FabricDrawing;
