import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "antd";
import { isEmpty } from "lodash";
import { actions as usersActions } from "/src/views/Users/redux";
import { Bar } from "react-chartjs-2";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { usersSelector } from "/src/views/Users/selector";
import { aiUsageInfoSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
);

const AIUsageList = (props) => {
  console.log("AIUsageList props  ==>", props);
  const mrIntl = useTranslate();
  const [isTableVisible, setIsTableVisible] = useState(false);
  const currentUser = useSelector(currentUserSelector());
  const showAiDataForSuperadmin = currentUser.role === "superadmin"
  const allOrgUsers = useSelector(usersSelector())
  const userStatsData = useSelector(aiUsageInfoSelector)
  const userWiseCost = userStatsData && userStatsData.user_wise
  let userIds = []
  if (userWiseCost && !isEmpty(userWiseCost)) {
    userIds = Object.keys(userWiseCost)
  }
  const dispatch = useDispatch();
  useEffect(() => {
    if (userIds && userIds.length > 0) {
      dispatch(usersActions.fetch({
        params :{
          by_org_id: props.extraProps.orgId,
          by_ids: userIds
        }
      }))
    }
  }, [!isEmpty(userWiseCost)])

  const userTableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Usage (USD)",
      dataIndex: "total_cost",
      key: "total_cost",
    }
  ]

  let userTableData = []
  const userWiseData = props.extraProps.totalUsage.user_wise;
  if (userWiseData && !isEmpty(userWiseData)) {
    const sortedUserValues = Object.entries(userWiseData).sort(
      (a, b) => b[1].total_cost - a[1].total_cost
    );
    const newSortedUserValues = sortedUserValues.map(item => {
      const user = allOrgUsers.find(user => user.id === parseInt(item[0]));
      const userName = user ? user.name : "Unknown"; 
      return {
        name: userName,
        total_cost: item[1].total_cost
      }
    })
    userTableData = newSortedUserValues
  }
  const allDataColumns = [
    {
      title: "Action",
      dataIndex: "action_type",
      key: "action_type",
      render: (text) => {
        const normalizedText = text.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
        return normalizedText;
      }
    },
    {
      title: "Input tokens",
      dataIndex: "input_tokens",
      key: "input_tokens",
    },
    {
      title: "Output tokens",
      dataIndex: "output_tokens",
      key: "output_tokens",
    },
    {
      title: "Usage (USD)",
      dataIndex: "request_cost",
      key: "request_cost",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
  ];

  const changeTimeToDate = (time) => {
    const date = new Date(0); // Start with the epoch
    date.setUTCSeconds(time.seconds);
    date.setMilliseconds(time.nanoseconds / 1e6);

    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };
  const graphDataLabels = [];
  const graphDataAccumulator = {};

  const startDate = (props.filterParams.created_at && props.filterParams.created_at[1]);
  const endDate = (props.filterParams.created_at && props.filterParams.created_at[3]);

  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  const dateRange = getDates(startDateObj, endDateObj);
  const dataRangeLabels = dataLabelsDates(startDateObj, endDateObj);

  !isEmpty(props.filterParams) && props.data && props.data.length > 0 && props.data.forEach((item) => {
    const dateKey = changeTimeToDate(item.created_at);
    const cost = item.request_cost;
    graphDataAccumulator[dateKey] = (graphDataAccumulator[dateKey] || 0) + cost;
    graphDataLabels.push(dateKey);
  });

  const graphDataObj = {
    labels: dataRangeLabels,
    datasets: [
      {
        label: mrIntl("AIUsageList.cost"),
        data: dateRange.map(dateKey => graphDataAccumulator[dateKey] || 0),
        fill: false,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderColor: "rgba(255, 99, 132, 0.5)",
        barPercentage: 0.5,
      },
    ],
  };

  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(currentDate.toLocaleDateString());
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  function dataLabelsDates(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    const options = { month: 'short', day: 'numeric' };
  
    while (currentDate <= endDate) {
      dates.push(currentDate.toLocaleDateString('en-US', options));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const handleSeeDetailLogClick = () => {
    setIsTableVisible(!isTableVisible);
  };

  const graphOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          min: 1,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
    height: 400,
    width: 600, 
  };
  
  const formatValues = (data, fieldsToFormat) => {
    return data.map(item => {
      const formattedItem = { ...item };
  
      fieldsToFormat.forEach(field => {
        if (typeof formattedItem[field] === 'number') {
          formattedItem[field] = parseFloat(formattedItem[field]).toFixed(4);
        }
      });
  
      return formattedItem;
    });
  };
  const formattedUserTableData = formatValues(userTableData, ['total_cost']);
  
  const filteredData = props.data.filter(item => {
    const dateKey = changeTimeToDate(item.created_at);
    return dateRange.includes(dateKey);
  })
  const formattedData = formatValues(filteredData, ['request_cost']);

  return (
    <>
      {!isEmpty(props.filterParams) && props.data && props.data.length > 0 && <div> 
        <Bar data={graphDataObj} options={graphOptions} height={100} width={500}/>
      </div>}
      {showAiDataForSuperadmin && (
        <div>
          <Button className="m-t-10" type="primary" onClick={handleSeeDetailLogClick}>
            {isTableVisible ? "Hide detail Log" : "See detail Log"}
          </Button>
          {isTableVisible && (
            <>
              <h3>Log details</h3>
              <Table dataSource={formattedData} columns={allDataColumns} />
            </>
          )}
        </div>
      )}
      <h3>{mrIntl("AIUsageList.top_users")}</h3>
      {userTableData && userTableData.length > 0 && <Table dataSource={formattedUserTableData} columns={userTableColumns} pagination={false} />}
    </>
  );
};
export default AIUsageList;