import React, { useState, useEffect } from "react";
import { filter, find } from "lodash";
import { useSelector } from "react-redux";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { MrSelect } from "mr_react_framework";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const SelectStrands = MrSelect({
  config: {
    isJsx: true,
    processData: (data, props) => {
      let options = []
      return options
    },
    widgetConfig: {
      showSearch: true,
      placeholder: <MrTranslate id={"StrandsForm.select_strands_placeholder"}/>,
    },
  },
});


const SelectStrandsForm = ({ strands, setSelectStrands, criteria, segment, disabled, extraClassName }) => {

  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const currentUser = useSelector(currentUserSelector());

  const mrIntl = useTranslate();

  const criteriaLength = criteria && criteria.length;

  // let mypOP;
  // if (currentUser.org.org_programmes) {
  //   mypOP = currentUser.org.org_programmes.find(op => op.code === "myp");
  // }

  // let currentGrade;
  // if (mypOP && mypOP.grades) {
  //   currentGrade = mypOP.grades.find(grade => grade.label === activeExperience.custom_fields.grade_item_label);
  // }

  const experienceGradeCode = activeExperience && activeExperience.relation_items && activeExperience.relation_items.length && activeExperience.relation_items[0].grade_code;
  
  const newStrands = [];
  const [data, setData] = useState([]);

  let experienceCritera = [];
  if (activeExperience && activeExperience.rubric && activeExperience.rubric.criteria && activeExperience.rubric.criteria.length > 0) {
    experienceCritera = activeExperience.rubric.criteria;
  }

  let segmentCriterium = null;
  if (segment && segment.criterium_associations_attributes && segment.criterium_associations_attributes.length > 0) {
    segmentCriterium = segment.criterium_associations_attributes;
  }
  let className = "m-t-5" + (extraClassName ? ` ${extraClassName}` : '');


  
  useEffect(() => {
    if (criteriaLength > 0) {
      criteria.forEach((item) => {
        let currentCriteria = item.title && item.title.split("-")[0];
        const criteriaStrands = filter(
          experienceCritera,
          (item) => item.title === currentCriteria
        )[0];
        const findCriteriaStrands = criteriaStrands && criteriaStrands.strands ? criteriaStrands.strands : [];
        if (findCriteriaStrands.length) {
          let finalStrands = find(
            findCriteriaStrands,
            // (item) => item.grade_code && item.grade_code === currentGrade.code
            (item) => item.grade_code && item.grade_code === experienceGradeCode
          );
          let findFinalStrands = finalStrands && finalStrands.strands ? finalStrands.strands : [];

          if (findFinalStrands && findFinalStrands.length > 0) {
            let finalOptions = findFinalStrands.map((item) => {
              const obj = {
                label: `${item.label} - ${item.desc}`,
                value: `${item.label} - ${item.desc}`,
              };
              if (segmentCriterium && segmentCriterium.length > 0) {
                segmentCriterium.forEach((st) => {
                  if (st.strands && st.strands.length > 0) {
                    const matchingStrands = st.strands.filter((strand) => strand.label === item.label);
                    const newStrandValues = matchingStrands.map((strand) => `${item.label} - ${item.desc}`);
                    setSelectStrands((prevSelectStrands) => [...(prevSelectStrands || []), ...newStrandValues]);
                  }
                });
              }
              return obj;
            });
            const objToPush = {
              label: currentCriteria,
              options: finalOptions,
            };
            console.log("finalstrands==>", newStrands, strands);

            newStrands.push(objToPush);
          }
        }
      });
    }
    console.log("criteria==>", newStrands);
    // Doing this to remove strands when we uncheck the criteria
    if (strands) {
      // using reduce so acc is the accumulator here which refers to the previous value
      const finalStrands = newStrands.reduce((acc, newStrand) => {
        const lowerCaseLabel = newStrand.label.toLowerCase();
        const matchingStrands = strands.filter(strand => strand.toLowerCase().startsWith(lowerCaseLabel));
        return [...acc, ...matchingStrands];
      }, []);
      setSelectStrands(finalStrands);
    }
    setData(newStrands);
  }, [criteria, criteria.length, segment.id]);

  const handleChange = (value) => {
    setSelectStrands(value)
  };
  
  return (
    criteriaLength > 0 && (
      <>
        <div>{mrIntl("SelectStrandsForm.select_strands")}</div>
        <SelectStrands
          className={className}
          disabled={disabled}
          value={strands}
          onChange={handleChange}
          config={{
            widgetConfig: {
              style: { width: "350px" },
              options: data,
              mode: "multiple",
            },
          }}
        />
      </>
    )
  );
};

export default SelectStrandsForm;