import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { put } from "redux-saga/effects";
import { axiosInstance } from "/src/api/apiModule";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "feedback",
  actionNames: ["FETCH", "CREATE", "SHOW", "UPDATE", "DELETE"],
});

const initialState = fromJS({
  feedbacks: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

export function* submitFeedbackSaga(action) {
  yield put(actions.createFeedback());
  const feedbackData = {
    feedback: {
      ...action.payload,
    },
  };
  // let msg = "Issue reported on"
  // let userInfoOptions = {
  //   log: {
  //     logging: true,
  //     msg: msg
  //   }
  // }
  let url = "/feedbacks";
  try {
    // yield put(actions.createFeedbackStart());
    const response = yield axiosInstance.instance.put(url, feedbackData);
    yield put(actions.createFeedbackSuccess(response.data, action.options));
  } catch (error) {
    console.log("feedbacks error", error);
    yield put(
      actions.createFeedbackFail({ error: error.response.data.message })
    );
  }
}

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState);
export const watchFeedbacks = reduxCrud.generateWatchSaga();

export default reduxCrud;
