import React, { memo } from "react";
import {
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { Button, Space, Tooltip, Popconfirm } from "antd";

const ActionButtons = (props) => {
  console.log("ActionButtons props", props);

  const {
    // addItem,
    addChildrenItem,
    deleteItem,
    // showAddButton,
    showAddChildrenButton,
    showDeleteButton,
    item,
    itemSiblingsCount,
  } = props;

  const mrIntl = useTranslate();
  return (
    <Space>
      {/* {
      showAddButton && 
      (<Button
        className="add-element-button"
        icon={<PlusCircleTwoTone />}
        onClick={() => addItem(item)}
      />)
    } */}
      {showAddChildrenButton && (
        <Tooltip title="Add child">
          <Button
            key={`add-children-${item.id}`}
            className="add-children-button"
            icon={<PlusCircleOutlined />}
            // onClick={() => setShowAddChildrenInput(true)}
            onClick={() => addChildrenItem(item)}
          />
        </Tooltip>
      )}
      {showDeleteButton && (
        <Popconfirm
          title={mrIntl("CommonText.confirm_delete")}
          okText={mrIntl("CommonText.yes")}
          cancelText={mrIntl("CommonText.no")}
          onConfirm={() => deleteItem(item, itemSiblingsCount)}
          placement="right"
        >
          <Tooltip
            title={mrIntl("CommonText.delete")}
            placement="bottom"
          >
            <Button
              key={`delete-${item.id}`}
              className="delete-button"
              icon={<DeleteOutlined />}
            />
        </Tooltip>
      </Popconfirm>
      )}
    </Space>
  );
};

export default memo(ActionButtons);
