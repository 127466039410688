import React, { useEffect } from 'react';
import { Card, Typography, Button, Space, Progress, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SendOutlined, LeftOutlined, ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons';
import MrTranslate, { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import './EndView.scss';

import {
  attemptedCountSelector,
  isTimeUpSelector,
  submitExperienceLoadingSelector,
} from '../../FirestoreInteractions/selector';
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { showConfirmModal } from '/src/components/UI/Segment/UIHelper';
import ViewStatus from '../ViewComponents/ViewStatus';
import { SolarCheckCircleBold, SolarClipboardTextLinear, SolarStopwatchBold } from '/src/components/UI/Icons/SolarIcons';
import ViewCard1 from '../ViewComponents/ViewCard1';
import FileUploadModal from '../../Components/FileUploadModal';
import { checkInternetConnectivity } from '/src/lib/CheckInternetConnectivity/CheckInternetConnectivity';
import { appRegionSelector } from '/src/views/Auth/Login/selector';

const EndView = (props) => {
  console.log("EndView props ==>", props);

  const {
    experienceProps,
    setSwitchFromStartToResumeView,
    isSetUserInfoLoading,
    backToTestButtonLoading,
    setBackToTestButtonLoading,
    timer,
    questionsCount,
    focusLostModal,
    sessionModalConfig,
    setShowQRModal
  } = props;

  const {
    experience,
    setUserInfo,
    experienceSettings
  } = experienceProps;

  const showConfig = {
    showProgress: true,
    timer: timer,
    titleColorClassName: 'bg-black-1',
    statusTextColorClassName: 'color-black',
  }

  // In StartView, if we're at the bottom and click "End Test" with a slow or disconnected internet, a scroll issue occurs in EndView on macOS app. We're adding a useEffect to fix this - APL-5765
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isSubmitExperienceLoading = useSelector(submitExperienceLoadingSelector);
  const attemptedCount = useSelector(attemptedCountSelector);
  const isTimeUp = useSelector(isTimeUpSelector);
  const appRegion = useSelector(appRegionSelector())
  const mrIntl = useTranslate();
  const dispatch = useDispatch();

  const handleBackToTestClick = () => {
    setBackToTestButtonLoading(true);
    setSwitchFromStartToResumeView(false);
    setUserInfo(
      { currentView: "startTest" },
      {
        log: {
          logging: true,
          action: "back_to_test_from_end_view",
        },
      }
    );
    setTimeout(() => {
      setBackToTestButtonLoading(false);
    }, 5000);
  };

  function showConfirm() {
    let confirmContent = (
      <>
        {mrIntl("EndView.attempted_question_message")}<br />
        {/* You have not attempted all questions. <br /> */}
        {mrIntl("EndView.attempted_question")}: {attemptedCount}/{questionsCount}
      </>
    );

    showConfirmModal({
      title: mrIntl("EndView.assessment_ended_modal_message"),
      icon: <ExclamationCircleOutlined />,
      content: confirmContent,
      okText: mrIntl("CommonText.submit"),
      cancelText: mrIntl("CommonText.cancel"),
      okType: "primary",
      // okText: "Submit",
      className: "height-centered-modal",
      onOk() {
        console.log("OK");
        submitResponses();
      },
      onCancel() {
        console.log("Cancel");
      },
    })
  }

  function submitResponses(params = {}) {
    console.log("submitResponses");
    if (isSubmitExperienceLoading || isSetUserInfoLoading) {
      return;
    }
    dispatch(
      firestoreInteractionActions.submitExperience({
        experienceId: experience.id,
        ...params,
      })
    );
  }

  const backToTestButton = <Button
    onClick={handleBackToTestClick}
    disabled={isSetUserInfoLoading || isSubmitExperienceLoading}
    loading={backToTestButtonLoading}
    // icon={<LeftOutlined />}
    className='back-to-test-button'
  >
    <Space>
      <LeftOutlined />
      <MrTranslate id={"EndView.back_to_test"} />
    </Space>
  </Button>

  const submitTestButton = 
    <Button
      loading={isSubmitExperienceLoading}
      type="primary"
      onClick={() => {
        checkInternetConnectivity(
          appRegion,
          (internetStatus) => {
            if (internetStatus === "online") {
              attemptedCount === questionsCount ? submitResponses() : showConfirm();
            }
          },
          mrIntl)
      }}
      className='submit-test-button'
      disabled={isSetUserInfoLoading || isSubmitExperienceLoading}
    >
      {mrIntl("EndView.submit_test")}<SolarClipboardTextLinear />
    </Button>

  return (
    <div className="end-view-container">
      {focusLostModal}
      <FileUploadModal
        itemMode={experienceSettings.mode}
        sessionModalConfig={sessionModalConfig}
        setShowQRModal={setShowQRModal}
      />
      <ViewStatus
        statusIcon={
          isTimeUp 
          ? <SolarStopwatchBold />
          : <SolarCheckCircleBold />
        }
        statusText={
          isTimeUp 
            ? mrIntl("EndView.times_up")
            : mrIntl("EndView.test_ended")
        }
        statusTextType={"danger"}
        statusMessage={mrIntl("EndView.please_click_submit_test_to_finish_and_submit_your_answers")}
        showConfig={showConfig}
      />
      <ViewCard1
        experience={experience}
        showConfig={showConfig}
        quitButtonAction="quit_app_after_end"
        // footerCenterComponent={timer} 
        footerLeftComponent={
          !isTimeUp 
            ? backToTestButton 
            : null
        }
        footerRightComponent={submitTestButton} 
      />
    </div>
  );
};

export default EndView;
