import React, { useState } from "react";
import { Tooltip, Row, Col, Modal } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import "../ExperienceItemDetail.scss";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const InstructionsModal = ({ instructions }) => {
  const [visible, setVisible] = useState(false);
  const mrIntl = useTranslate()

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };
  return (
    <>
      <Tooltip
        title={mrIntl("InstructionsModal.instructions")}
        placement="top"
        getPopupContainer={() => document.querySelector(".experience-header")}
      >
        <InfoCircleTwoTone onClick={showModal} style={{ marginRight: "5px" }} />
      </Tooltip>
      <Modal
        className="instructions-modal"
        title={mrIntl("InstructionsModal.instructions")}
        open={visible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        // width={800}
      >
        <Row>
          <Col span={24}>
            <span dangerouslySetInnerHTML={{ __html: instructions }}></span>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default InstructionsModal;
