import React, {useState} from "react";
import { MrSelect, MrCrudView, FormRenderer } from "mr_react_framework";
import { actions } from "./redux";
import commonSelectors from "/src/App/commonSelectors";
import PromptsList from "./PromptsList/PromptsList";
import { SelectAIModel, SelectPromptType, SelectResponseFormat } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import PromptsFilter from "./PromptsFilter/PromptsFilter";
import TextArea from "antd/lib/input/TextArea";
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { isKeyCodeValid } from "/src/lib/utils/helperMethods";

const UploadComponent = (props) => {
  const { getFieldValue, sourceValues, formInstance, type, onChange } = props;
  const mrIntl = useTranslate();

  const mediaFormInitialState = {
    modal: false,
    loading: false,
    visible: false,
    title: type === "image" ? mrIntl("CommonText.insert_images") : mrIntl("CommonText.insert_files"),
    okText: type === "image" ? mrIntl("CommonText.add_images_button") : mrIntl("CommonText.save"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    limit: 10,
    requireFooter: true,
    content_type: type === "image" ? "image" : "application/pdf",
    supportedTypes: type === "image"
      ? "image/jpg, image/jpeg, image/png, image/gif, image/bmp"
      : "application/pdf",
    supportedFormats: type === "image"
      ? "jpg, jpeg, png, gif, bmp"
      : "pdf",
    selectConfig: {
      maxSize: "20 MB",
    },
  };

  const [mediaFormSetting, setMediaFormSetting] = useState(mediaFormInitialState);

  const onSaveMedia = (files) => {
    console.log("attachmentFiles ==>",files);

    if (files && files.length > 0) {
      const urls = files.map((file) => file.url);
  
      if (onChange) {
        onChange(urls);
      }
      setMediaFormSetting({
        ...mediaFormSetting,
        from: '',
        triggerSaveAction: 0,
      })
    }
  }

  return (
    <>
      <SelectFromComputer
        mediaFormSetting={mediaFormSetting}
        setMediaFormSetting={setMediaFormSetting}
        onSaveMedia={onSaveMedia}
      />
    </>
  );
};

const PromptItemsChildComponent = (props) => {
  const { getFieldValue, sourceValues, formInstance, options } = props;
  const promptType = getFieldValue(["prompt_messages", options.arrayItemProps.name, "prompt_type"]);

  console.log("options ==>", options)

  let finalRender = null;

  if (promptType === 'text') {
    finalRender = (
      <FormRenderer
        formObj={{
          type: "object",
          properties: {
            prompt_text: {
              type: "text",
              title: "Prompt text",
              widget: TextArea,
              widgetConfig: {
                rows: 10,
                maxLength: 2000,
              },
            },
          },
        }}
        propertyKey={[`${options.arrayItemProps.name}`]}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  } else if (promptType === 'image') {
    finalRender = (
      <FormRenderer
        formObj={{
          type: "object",
          properties: {
            prompt_text: {
              type: "string",
              title: "Prompt text",
              span: 8,
            },
            prompt_attachments: {
              type: "string",
              widget: (props) => <UploadComponent {...props} type="image" />,
            },
          },
        }}
        propertyKey={[`${options.arrayItemProps.name}`]}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  } else if (promptType === 'pdf') {
    finalRender = (
      <FormRenderer
        formObj={{
          type: "object",
          properties: {
            prompt_text: {
              type: "string",
              title: "Prompt text",
              span: 8,
            },
            prompt_attachments: {
              type: "string",
              widget: (props) => <UploadComponent {...props} type="pdf" />,
            },
          },
        }}
        propertyKey={[`${options.arrayItemProps.name}`]}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }

  return finalRender;
};

const returnForm = () => {
  let form = {
    schema: {
      type: "object",
      properties: {
        label: {
          type: "string",
          title: "Label",
          rules: [
            {
              required: true,
              message: "Please enter label"
            },
          ],
        },
        code: {
          type: "string",
          title: "Code",
          span: 6,
          rules: [
            {
              required: true,
              validator(rule, value) {
                if (isKeyCodeValid(value)) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("Please enter valid code");
                }
              },
            },
          ],
        },
        // desc: {
        //   type: "text",
        //   title: "Description",
        // },
        model: {
          type: "string",
          title: "Model",
          widget: SelectAIModel,
          span: 6,
          rules: [
            {
              required: true,
              message: "Please select model"
            },
          ],
        },
        // response_format: {
        //   type: "string",
        //   title: "Response format",
        //   widget: SelectResponseFormat,
        //   span: 6,
        //   rules: [
        //     {
        //       required: true,
        //       message: "Please select format"
        //     },
        //   ],
        // },
        // prompt_text: {
        //   type: "text",
        //   title: "Prompt text",
        //   widget: TextArea,
        //   widgetConfig: {
        //     rows: 10,
        //     // autoSize: true
        //   }
        // },
        system_message: {
          type: "text",
          title: "System message",
          widget: TextArea,
          widgetConfig: {
            rows: 10,
          }
        },
        // api_endpoint: {
        //   type: "string",
        //   title: "API endpoint",
        // },
        // api_params: {
        //   type: "string",
        //   title: "API params",
        // },
        // status: {
        //   type: "string",
        //   title: "Status",
        // },
        prompt_messages: {
          type: "array",
          span: 24,
          min: 0,
          max: 100,
          default: [{}],
          title: "Prompt messages",
          items: {
            type: "object",
            properties: {
              prompt_type: {
                type: "string",
                title: "Prompt Type",
                widget: SelectPromptType,
                span: 8,
              },
              prompt_data: {
                type: "string",
                dependsOn: ["prompt_type"],
                children: PromptItemsChildComponent
              },
            },
          },
        },
      },
    },
  };
  return form;
};

const Prompts = MrCrudView({
  resourceName: "prompts",
  singleResourceName: "prompt",
  // displayName: "Create/Edit feature",
  resourceUrl: "/prompts",
  // filter: BoardsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  // listItem: BoardListItem,
  filter: PromptsFilter,
  forms: [{ 
    name: "prompts", 
    form: returnForm, 
    // config: {isModal: true, visible: true} 
  }],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span>Item Detail {JSON.stringify(props.item)}</span>;
      },
    },
    itemDetail: {
      widget: (props) => {
        console.log("itemDetail props", props);
        return <span>Item Detail {JSON.stringify(props.item)}</span>;
      },
      // widget: ProgrammeItemDetail,
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      widget: PromptsFilter,
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      widget: PromptsList,
      // widget: (props) => {
      // return <span>List</span>;
      // },
    },
  },
});

// Plans.defaultProps = {};

Prompts.propTypes = {};
export default Prompts;
