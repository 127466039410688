import React from "react";
import { Button } from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

export const OSCPDFButton = (props) => {
  const mrIntl = useTranslate()
  let finalRender = [];
  let button = (
    <Button ghost={true} style={props.style} shape={"round"} href={props.url} target={"_blank"}>
      {/* View IB Markscheme */}
      {mrIntl("GenericComponents.view_ib_markscheme")}
    </Button>
  );

  finalRender.push(button);
  return finalRender;
};

export const OSCSpinner = (props) => {

  return <div style={props.style}>
    <svg className={'blue osc-spinner'} width="52px" height="52px" viewBox="0 0 52 52" version="1.1" xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient x1="0%" y1="100%" x2="106.755154%" y2="0%" id="linearGradient-125">
        <stop stopColor="#43DAF0" offset="0%"></stop>
        <stop stopColor="#395BF8" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="(xx)-N----Loader-Spinner" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Loader-Spinner---Large-(White-Background)" transform="translate(-162.000000, -308.000000)">
        <g id="MB/Content-Elements/Loader-Spinner/Large---Gray-Background"
          transform="translate(161.000000, 307.000000)">
          <g id="Loader-Spinner-52px" transform="translate(3.000000, 3.000000)">
            <circle id="Background" stroke="#FFFFFF" strokeWidth="3" cx="24" cy="24" r="24"></circle>
            <path d="M24,0 C10.745166,0 0,10.745166 0,24" id="Loader" stroke="url(#linearGradient-125)" strokeWidth="3"
              strokeLinecap="round"></path>
          </g>
        </g>
      </g>
    </g>
    </svg>
  </div>
}

export const keyUpEventNotifier = (event) => {
  let keyUpEventObj = {
    keyCode: event.keyCode,
    key: event.key,
    // target: event.target
  }
  // if(event.target.className.indexOf("ant-drawer") === -1 || import.meta.env.VITE_MODE === "staging" || import.meta.env.VITE_MODE === "development"){
  // Fixed on OSc side so now triggering the event in all cases
  // if(event.target.className.indexOf("ant-drawer") === -1){
    // console.log('keyup A key was pressed', event.keyCode, event.key, event.target.className);
    window.parent.postMessage({keyUpEvent: keyUpEventObj}, "*");
  // }
}