import React, { useEffect, useState } from "react";

import { Tabs, Input, Radio, Button, Checkbox, Upload, Row, Col } from "antd";
import { InboxOutlined, UploadOutlined, SoundOutlined, PauseCircleOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { initialData, addItemToArray, removeItemFromArray, convertDataAsPerNewSystem, getParsedJSONObject, numaricalSort } from "/src/views/Segments/InteractiveHelpers";
import { AddButton, DeleteButton, CheckBox, CustomInput, InputArea, MCQArea, MCQList, TextArea, ShowText, ShowTextIndex, ShowTextContent } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { MrInput } from "mr_react_framework"
import { debounce } from 'lodash';
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import InsertFromURL from "/src/components/UI/Media/InsertFromURL";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import PlayListAudios from "./PlayListAudios";
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments"
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import ListAttachments from "/src/components/UI/Attachments/ListAttachments";

const AudioView = props => {
	console.log("Audio view props ==>", props);
	const { createMode, resource_json, onTabEdit, onTextChanged, removeFile, title, caption } = props
  const mrIntl = useTranslate()
	const segment_data = resource_json.segment_data
	const resource_setting = resource_json.resource_setting || {}
	const attachments = segment_data.attachments || []
  const printMode = props.printMode || false
	console.log("Audio view state before render ==>", resource_json, resource_setting, resource_json)

	return (
    <div>
      {!printMode ? (
        !createMode && resource_setting.view_type === "playlist" ? (
          <PlayListAudios
            // createMode={createMode}
            attachments={attachments}
            resource_setting={resource_setting}
          ></PlayListAudios>
        ) : (
          <TabularAttachments
            attachmentType="audio"
            tabPrefix={mrIntl("AudioView.audio")}
            attachments={attachments}
            resource_setting={resource_setting}
          ></TabularAttachments>
        )
      ) : (
        <ListAttachments
          printMode={printMode}
          key={`list-attachments-${props.unique_segment_identifier}`}
          titlePrefix={"Audio"}
          hideTitle={printMode}
          caption={caption}
          title={title}
          attachments={attachments}
        ></ListAttachments>
      )}
    </div>
  );
}
export default AudioView;