import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  GenericWidgetCreator,
  SelectProgramme,
  SelectProgrammeSubject,
} from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import "./SubjectResources.scss";
import { FormRenderer } from "mr_react_framework";
import { actions as programmeSubjectActions } from "../redux";
import { MrFormComponent } from "mr_react_framework";
import SubjectResourcesUpload from "./SubjectResourcesUpload";
import { mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const SelectSubjectWidget = GenericWidgetCreator((props) => {
  return {
    config: {
      forceReload: true,
      params: {
        by_programme_id: props.formInstance.getFieldValue([
          "selected_programme_id",
        ]),
        by_parent_id_null: true,
      },
    },
  };
}, SelectProgrammeSubject);

const getSelectSubjectField = (sourceValues) => {
  return {
    type: "object",
    properties: {
      id: {
        placeholder: "Select subject",
        type: "string",
        span: 24,
        widget: SelectSubjectWidget,
      },
    },
  };
};

const SubjectChildComponent = ({
  getFieldValue,
  sourceValues,
  formInstance,
}) => {
  const selectedProgrammeId = getFieldValue(["selected_programme_id"]);
  let finalRender = null;
  if (selectedProgrammeId) {
    finalRender = (
      <FormRenderer
        formObj={getSelectSubjectField(sourceValues)}
        sourceValues={sourceValues}
        formInstance={formInstance}
      />
    );
  }
  return finalRender;
};

const SubjectResourcesUploadChild = (props) => {
  console.log(
    "props SubjectResourcesUploadChild =>",
    props,
    props.getFieldValue(["id"])
  );
  const mrIntl = useTranslate()
  const selectedProgrammeId = props.getFieldValue(["id"]);
  const [currentProgrammeId, setCurrentProgrammeId] = useState(selectedProgrammeId);
  useEffect(() => {
    if (currentProgrammeId != selectedProgrammeId) {
      setCurrentProgrammeId(selectedProgrammeId);
    }
  }, [selectedProgrammeId]);
  if (currentProgrammeId != selectedProgrammeId) {
    return null;
  }
  let finalRender = null;
  if (selectedProgrammeId) {
    finalRender = (
      <FormRenderer
        formObj={{
          type: "object",
          properties: {
            resources: {
              type: "string",
              title: mrIntl("SubjectResources.upload_file"),
              widget: SubjectResourcesUpload,
            },
          },
        }}
        sourceValues={props.sourceValues}
        formInstance={props.formInstance}
        propertyKey={"attachments_json"}
      />
    );
  }

  return finalRender;
};

const getSubjectResourcesForm = (props) => {
  const { intl } = props
  const mrIntl = mrTranslate(intl)
  return {
    schema: {
      type: "object",
      properties: {
        // we are not using programme_id to avoid any override mistakenly
        selected_programme_id: {
          type: "string",
          widget: SelectProgramme,
          placeholder: "Select Program",
          rules: [
            {
              required: true,
              message: "Required",
            },
          ],
        },
        id: {
          type: "string",
          placeholder: "Select Subject",
          children: SubjectChildComponent,
          dependsOn: ["selected_programme_id"],
          rules: [
            {
              required: true,
              message: "Required",
            },
          ],
        },
        attachments_json: {
          type: "object",
          properties: {
            resources: {
              type: "string",
              title: mrIntl("SubjectResources.upload_file"),
              children: SubjectResourcesUploadChild,
              dependsOn: ["id"],
              // rules: [
              //   {
              //     required: true,
              //     message: "Please write feedback.",
              //   },
              // ],
            },
          },
        },
      },
    },
  };
};

const SubjectResources = (props) => {
  const dispatch = useDispatch();
  const jsonForm = getSubjectResourcesForm(props);
  const handleSubmit = (data) => {
    console.log('SubjectResources handleSubmit==>', data)
    const programmeSubjects = {
      id: data.id,
      programme_subjects: { ...data },
    };
    dispatch(
      programmeSubjectActions.update(programmeSubjects, {
        success: {
          showMessage: true,
          message: "Done",
        },
      })
    );
  };

  const mainForm = (
    <MrFormComponent
      key={"mrForm"}
      {...props}
      visible={true}
      resourceForm={{
        form: { ...jsonForm },
        config: { isModal: false, visible: true },
      }}
      item={{}}
      formConfig={{ actions: { submitText: "Submit", showCancelBtn: false, showSubmitBtn: false } }}
      formProps={{}}
      handleSubmit={(data) => handleSubmit(data)}
      handleOk={(data) => handleSubmit(data)}
    />
  );

  return mainForm;
};

export default SubjectResources;