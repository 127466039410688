import React, { useEffect, useState } from "react";
import { Tabs, Collapse, Space } from "antd";
import { TabAddIcon, CustomLabel, CustomInput, Segment, SegmentData, showConfirmModal } from '/src/components/UI/Segment/UIHelper'
import { getItemsOfSize, addItemToArray, removeItemFromArray, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import { cloneDeep } from 'lodash';
import { DeleteFilled } from "@ant-design/icons";
import { DraggableTabs } from "/src/components/UI/DnD/DraggableTabs"
// import SegmentItemFooter from '/src/views/Segments/SegmentItemDetail/SegmentItemFooter'
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent";
import { CKETextAccordionTitleTools } from "/src/components/UI/CKEditor/CKEConfig";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { printModeSelector } from "/src/views/Experiences/selector";
import { useSelector } from "react-redux";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";

const Accordion = (props) => {
  console.log("Accordion props ==>", props);
  const { TabPane } = Tabs;
  const { Panel } = Collapse;
  const { setRenderMath } = props
  let { createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)
  let parsedJSONObject
  const mrIntl = useTranslate()

  const extra = {
    contentRequired: false,
    contentPrefix: 'Text',
    placeholderPrefix: 'Text'
  }
  if (createMode) {
    parsedJSONObject = props.value || {
      segment_data: {
        panels: getItemsOfSize(1, 1, extra)
      },
      segment_version: '1.0.0'
    };
  } else {
    parsedJSONObject = props.resource_json
  }

  console.log('Updated data with parsedJSONObject ==>', presentationMode, parsedJSONObject)
  const [state, setState] = useState(parsedJSONObject)
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
  const printMode = useSelector(printModeSelector())
  let currentJSON
  if (presentationMode) {
    currentJSON = parsedJSONObject
  } else {
    currentJSON = state
  }
  const segment_data = currentJSON?.segment_data || {}
  const panels = segment_data.panels || []

  const [tabSetting, setTabSetting] = useState({
    activeKey: panels[0].id.toString()
  })

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let resource_json = cloneDeep(state)
        props.onChange(resource_json)
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    setState(state)
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
  }

  const addItem = () => {
    const newPanels = addItemToArray(panels, extra);

    if (!newPanels) {
      return
    }

    const newSegmentData = {
      ...segment_data,
      panels: newPanels
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
    setTabSetting({
      ...tabSetting,
      activeKey: newPanels[newPanels.length - 1].id.toString()
    })
  }

  const removeItem = (index) => {
    console.log("Remove index ==>", index)
    const newPanels = removeItemFromArray(panels, index);

    if (!newPanels) {
      return
    }

    const newSegmentData = {
      ...segment_data,
      panels: newPanels
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    console.log("Updated state after remove ==>", newState)

    saveState(newState)

    const newPanelsCount = newPanels.length
    let activeKey
    if (index >= newPanelsCount)
      activeKey = newPanels[newPanelsCount - 1].id.toString()
    else {
      activeKey = newPanels[index].id.toString()
    }
    setTabSetting({
      ...tabSetting,
      activeKey: activeKey
    })
  }

  const onTabEdit = (targetKey, action) => {
    if (action == 'add') {
      addItem()
    } else if (action == 'remove') {
      const index = panels.findIndex((panel) => panel.id == targetKey)
      showConfirmModal({
        // title: 'Are you sure to want to delete this panel?',
        title: mrIntl("CommonText.confirm_delete"),
        onOk: () => {
          removeItem(index)
        },
        mrIntl: mrIntl
      })
    }
  }

  const onTabChange = (targetKey) => {
    setTabSetting({
      ...tabSetting,
      activeKey: targetKey
    })
  }

  const onTabDragEnd = (panels) => {
    const newPanels = panels.slice()

    const newSegmentData = {
      ...segment_data,
      panels: newPanels
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const onTextChanged = (type, index, data) => {
    const newPanels = panels
    newPanels[index][type] = data

    const newSegmentData = {
      ...segment_data,
      panels: newPanels
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const handleChange = () => {
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }
  const panelIds = panels.map((data) => data.id)
  console.log("Accordion state before render ==>", panelIds, panels)
  return (
    <Segment>
      <SegmentData>
        {createMode ? <DraggableTabs
          addIcon={<TabAddIcon
            iconText={mrIntl("CommonText.add")}
          ></TabAddIcon>}
          type="editable-card"
          tabPosition={'top'}
          activeKey={tabSetting.activeKey}
          onEdit={onTabEdit}
          onChange={onTabChange}
          canDrag={true}
          order={panels}
          setOrder={onTabDragEnd}
          className="accordion-tabular-text-boxes"
        >
          {panels.map((panel, index) => (
            <TabPane
              key={panel.id}
              // tab={`Panel ${index + 1}`}
               tab={mrIntl("Accordion.panel_tab", {index: index + 1})}
              closeIcon={<DeleteFilled />}
            >
              <div style={{ "marginBottom": "20px" }}>
                <CustomLabel> <MrTranslate id={"Accordion.title"}/> </CustomLabel>
                {/* <CustomInput
                  className={panel.title && 'custom-success'}
                  defaultValue={panel.title}
                  // placeholder={`Panel Title ${index + 1}`}
                  onChange={(data) => onTextChanged('title', index, data)}
                ></CustomInput> */}
                <CKETextContent
                  ckeConfig={CKETextAccordionTitleTools}
                  value={panel.title}
                  // placeholder={`Panel Content ${index + 1}`}
                  onChange={(data) => onTextChanged('title', index, data)}
                >
                </CKETextContent>
              </div>
              <div>
                <CustomLabel><MrTranslate id = {"CommonText.content"}/></CustomLabel>
                <CKETextContent
                  value={panel.content}
                  // placeholder={`Panel Content ${index + 1}`}
                  onChange={(data) => onTextChanged('content', index, data)}
                >
                </CKETextContent>
              </div>
            </TabPane>
          ))}
        </DraggableTabs> : 
          <>
            {printMode && <div className="print-content-type" >
                <h3>Content type: Accordion</h3>
                <p>{props.title}</p>
                <RenderHtml text={props.caption} />
            </div>}
            <Collapse
              accordion={!printMode}
              onChange={(e) => handleChange()}
              defaultActiveKey={printMode ? panelIds : ""}
            >
              {panels.map((panel, index) => (<Panel
                key={panel.id}
                header={<div dangerouslySetInnerHTML={{ __html: panel.title }}></div>}
              >
                <div dangerouslySetInnerHTML={{ __html: panel.content }}></div>
              </Panel>))}
            </Collapse>
          </>
        }
      </SegmentData>
    </Segment>
  );
};
export default Accordion;