import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  MrSelect,
  MrCrudView,
  MrForm as WrappedMrForm,
  FormRenderer
} from "mr_react_framework";
import { actions } from "./redux";
import TagsList from "./TagsList";
import commonSelectors from "/src/App/commonSelectors";
import { actions as genericActions } from "/src/App/genericRedux";
import { GenericWidgetCreator, SelectProgrammeSubject, SelectProgrammeGrade, SelectProgramme, SelectTagType, SelectStandardCollectionOnly }  from "/src/components/AppSpecific/SelectSubject/SelectSubject"
import TagsFilter from "./TagsFilter/TagsFilter";
import TagItemDetail from "./TagItemDetail";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";



const SelectGradeWidget = GenericWidgetCreator((props) => {
  return {config: {forceReload: true, params: {by_programme_id: props.formInstance.getFieldValue(["programme_id"])}}}
}, SelectProgrammeGrade);


const getSelectGradeField = (sourceValues) => {
  return {
    type: "object",
    properties: {
      programme_grade_id: {
        title: "Select grade",
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectGradeWidget,
        widgetConfig: {
          // disabled: sourceValues && sourceValues.programme_grade_id ? true : false
        },
      },
    }
  }
};

const GradeChildComponent = ({getFieldValue, sourceValues, formInstance}) => {
  const programmeId = getFieldValue(["programme_id"]);
  let finalRender = null;
  if(programmeId){
    finalRender = <FormRenderer
      formObj={getSelectGradeField(sourceValues)}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  return finalRender;
}

const SelectSubjectWidget = GenericWidgetCreator((props) => {
  return {config: {forceReload: true, params: {by_type_c: "group", by_programme_id: props.formInstance.getFieldValue(["programme_id"])}}}
}, SelectProgrammeSubject);


const getSelectSubjectField = (sourceValues) => {
  console.log("getSelectSubjectField sourceValues", sourceValues)
  return {
    type: "object",
    properties: {
      programme_subject_id: {
        title: "Select subject",
        type: "string",
        span: 24,
        setInitialValue: true,
        widget: SelectSubjectWidget,
        widgetConfig: {
          // disabled: sourceValues && sourceValues.programme_subject_id ? true : false
        },
        // TODO: required needs to be conditional - based on value of type_c - true for topic, false for others
          // rules: [
          //   {
          //     required: true, 
          //     message: 'Required',
          //   },
          // ]
      },
    }
  }
};

const SubjectChildComponent = ({getFieldValue, sourceValues, formInstance}) => {
  const programmeId = getFieldValue(["programme_id"]);
  let finalRender = null;
  if(programmeId){
    finalRender = <FormRenderer
      formObj={getSelectSubjectField(sourceValues)}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  return finalRender;
}

const SelectStandardCollectionWidget = GenericWidgetCreator((props) => {
  return { config: { forceReload: true, params: { should_serialize: false }}}
}, SelectStandardCollectionOnly);

const getStandardCollection = (sourceValues, mrIntl) => {
  return {
    type: "object",
    properties: {
      parent_id: {
        title: mrIntl("Tags.select_standard_collection"),
        type: "string",
        span: 8,
        setInitialValue: true,
        widget: SelectStandardCollectionWidget,
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ]
      },
    }
  }
};

const StandardCollectionChildComponent = ({ getFieldValue, sourceValues, formInstance }) => {
  const typeC = getFieldValue(["type_c"]);
  let finalRender = null;
  const mrIntl = useTranslate();
  if (typeC === "content_area") {
    finalRender = <FormRenderer
      formObj={getStandardCollection(sourceValues, mrIntl)}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }

  return finalRender;
}


export const returnForm = (props) => {
  const { intl } = props;
  console.log("Tag returnForm props", props);
  let form = {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      // for 4 level nesting
      if(opts.values.children){
        opts.values.children.map((child, i) => {
          child.type_c = opts.values.type_c
          if(child.children){
            child.children.map((child2, i) => {
              child2.type_c = opts.values.type_c
              child2.children_attributes = child2.children
            })
            child.children_attributes = child.children
          }
        })
      }
      opts.values.children_attributes = opts.values.children;
      
      console.log("opts", opts);
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        type_c: {
          type: "string",
          span: 24,
          title: "Type",
          widget: SelectTagType,
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ]
        },
        parent_id: {
          type: "string",
          span: 24,
          dependsOn: ["type_c"],
          children: StandardCollectionChildComponent,
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ]
        },
        programme_id: {
          title: "Select program",
          type: "string",
          span: 8,
          widget: SelectProgramme,
          widgetConfig: {
            // style: {width: "300px"}
          },
          // TODO: required needs to be conditional - based on value of type_c - true for topic, false for others
          // rules: [
          //   {
          //     required: true, 
          //     message: 'Required',
          //   },
          // ]
        },
        programme_grade_id: {
          title: "Select grade",
          type: "string",
          span: 8,
          dependsOn: ["programme_id"],
          children: GradeChildComponent,
        },
        programme_subject_id: {
          title: "Select subject",
          type: "string",
          span: 8,
          dependsOn: ["programme_id"],
          children: SubjectChildComponent,
        },
        position: {
          type: "string",
          span: 3,
          title: "Position",
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ]
        },
        name: {
          type: "string",
          span: 21,
          title: "Unit/Topic name",
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ]
        },
        description: {
          type: "string",
          span: 24,
          title: "Description",
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ]
        },
        // only used for content_area
        line_break1: {
          type: "string",
          span: 24,
          widget: () => { return <hr></hr> }
        },
        custom_fields: {
          span: 6,
          type: "object",
          properties: {
            max_nesting_level_for_tagging: {
              title: "Max nesting level [only for content_area]",
              type: "string",
              span: 6,
            },
            definition: {
              title: "Definition [only for command_term]",
              type: "string",
              span: 24,
            },
          }
        },
        // data contains chapters and topics
        // info: {
        //   title: "Chapters and topics",
        //   type: "string",
        //   default: "{}",
        //   widget: ProgrammeCustomInput,
        // },
        line_break: {
          type: "string",
          span: 24,
          widget: () => { return <hr></hr> }
        },
        children: {
          type: "array",
          addButtonTitle: "+ Add child",
          span: 24,
          min: 0,
          max: 50,
          // default: [{}],
          items: {
            type: "object",
            properties: {
              position: {
                type: "string",
                span: 3,
                title: "Position",
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ]
              },
              name: {
                type: "string",
                title: "Topic/Sub-topic name",
                // default: "Term 1"
                span: 18,
                rules: [
                  {
                    required: true,
                    message: 'Required',
                  },
                ]
              },
              line_break: {
                type: "string",
                span: 24,
                widget: () => { return <hr></hr> }
              },
              children: {
                type: "array",
                addButtonTitle: "+ Add child",
                span: 24,
                min: 0,
                max: 50,
                // default: [{}],
                items: {
                  type: "object",
                  properties: {
                    position: {
                      type: "string",
                      span: 3,
                      title: "Position",
                      rules: [
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]
                    },
                    name: {
                      type: "string",
                      title: "Topic/Sub-topic name",
                      // default: "Term 1"
                      span: 18,
                      rules: [
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]
                    },
                    line_break: {
                      type: "string",
                      span: 24,
                      widget: () => { return <hr></hr> }
                    },
                    children: {
                      type: "array",
                      addButtonTitle: "+ Add child",
                      span: 24,
                      min: 0,
                      max: 50,
                      // default: [{}],
                      items: {
                        type: "object",
                        properties: {
                          position: {
                            type: "string",
                            span: 3,
                            title: "Position",
                            rules: [
                              {
                                required: true,
                                message: 'Required',
                              },
                            ]
                          },
                          name: {
                            type: "string",
                            title: "Topic/Sub-topic name",
                            // default: "Term 1"
                            span: 18,
                            rules: [
                              {
                                required: true,
                                message: 'Required',
                              },
                            ]
                          },
                        }
                      }
                    },
                  }
                }
              },
            }
          }
        },
      },
    },
  };
  return form;
};

const Tags = MrCrudView({
  resourceName: "tags",
  singleResourceName: "tag",
  displayName: "Tags",
  resourceUrl: "/tags",
  // layout: OrgsLayout,
  filter: TagsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  forms: [{ name: "tags", form: returnForm }],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    // itemPreview: {
    //   widget: TagListItem,
    // },
    itemDetail: {
      // widget: (props) => {
      //   console.log( "itemDetail props", props );
      //   return <span>Item Detail {JSON.stringify(props.item)}</span>
      // }
      widget: TagItemDetail
    },
    pagination: {
      show: true,
    },
    filter: {
      show: true,
      widget: (props) => {
        return <span>Filter</span>;
      },
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      type: "table/list/custom",
      widget: TagsList,
    }
  },
});

Tags.propTypes = {};
export default Tags;
