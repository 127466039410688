import React from "react";
import PropTypes from 'prop-types';

import { MrCrudView, MrForm as WrappedMrForm } from "mr_react_framework";
import { actions } from "./redux";
import BoardListItem from "./BoardListItem/BoardListItem";
import BoardsFilter from "./BoardsFilter/BoardsFilter";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors from "/src/App/commonSelectors";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";


const returnForm = () => {
  let form = {
    schema: {
      type: "object",
      properties: {
        name: {
          type: "string",
          placeholder: <MrTranslate id={"CommonText.name"}/>
        },
        code: {
          type: "string",
          placeholder: <MrTranslate id={"CommonText.code"}/>
        },
        abbr: {
          type: "string",
          placeholder: <MrTranslate id={"Boards.abbr"}/>
        },
        desc: {
          type: "string",
          default: "",
          placeholder: <MrTranslate id={"CommonText.description"}/>
        },
      },
    },
    uiSchema: {},
  };
  return form;
};


const Boards = MrCrudView({
  resourceName: "boards",
  singleResourceName: "board",
  displayName: "resourceName",
  resourceUrl: "/boards",
  // layout: OrgsLayout,
  filter: BoardsFilter,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
  },
  listItem: BoardListItem,
  forms: [{ name: "boards", form: returnForm }],
  actions,
  actionNames: ["fetch", "create", "show", "update", "delete"],
  // firstTimeFetchParams: { with_users: true }
});

// Boards.defaultProps = {};

Boards.propTypes = {};
export default Boards;
