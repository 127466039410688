import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "term",
  actionNames: ["FETCH", "CREATE", "SHOW", "UPDATE", "DELETE"],
});

const initialState = fromJS({
  terms: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState);
export const watchTerms = reduxCrud.generateWatchSaga({});

export default reduxCrud;
