import React from "react";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { actions as topicActions } from "/src/views/Segments/Topics/redux";
import { actions as userResponseReduxActions } from "/src/views/UserResponses/redux";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { calculatePercentage, isStringEmpty, setToLS } from "/src/lib/utils/helperMethods";
import {actions as experienceActions} from "/src/views/Experiences/redux"


export const showExperienceHelper = (
  item,
  fromResourceName,
  tab = "default",
  currentUser = null,
  history = null,
  dispatch = null,
  event,
  location = null,
  setLogs,
) => {
  console.log("showExperienceHelper params", item, fromResourceName, tab);  
  // let reduxActions = isLibrary ? libraryExperienceReduxActions : experienceReduxActions
  // using same actions now for both so no need
  // const currentUser = useSelector(currentUserSelector())
  // const history = useHistory()
  // const dispatch = useDispatch();
  console.log("showExperienceHelper location", location);
  if(item.experience_type === "group"){
    return;
  }

  // TO do: this is a problem when calling showExperience from within an experience - setActive should be done on exp mount no?
  dispatch(experienceReduxActions.setActiveSuccess({ id: item.id }, {success: {showMessage: true}}));

  // NOTE: UTTAM with topics should be removed before deploy
  dispatch(experienceReduxActions.show({ id: item.uid }, {
    successCallback: (data) => {
      if(setLogs) {
        setLogs(tab)
      }
    }
  }));

  // using same url now for both so no need
  // history.push(isLibrary ? `/e/all-tests/${item.id}` : `/e/tests/${item.id}`)
  if (tab === "monitor") {
    window.open(`/e/tests/${item.uid}/monitor`, "_blank");
  } else if (tab === "grade") {
    if (
      currentUser.role === "qb_author" ||
      currentUser.role === "superadmin" ||
      currentUser.role === "support"
    ) {
      window.open(`/e/tests/${item.uid}/grade`, "_blank");
    } else {
      history.push({
        pathname: `/e/tests/${item.uid}/grade`,
        state: { fromResourceName: fromResourceName },
      });
    }
  } else {
    if (
      currentUser.role === "superadmin" ||
      currentUser.role === "support"
    ) {
      // default to new tab
      window.open(`/e/tests/${item.uid}`, "_blank");
    } else {
      if (event && (event.ctrlKey || event.metaKey)) {
        //  to support Ctrl + click for new tab
        if (currentUser.role === 'student') {
          //  NOT for student - we always want to open in same tab
          event.preventDefault();
        } else {
          window.open(`/e/tests/${item.uid}/${fromResourceName}`);
        }
      } else {
        // default behaviour with just click - same tab
        history.push({
          pathname: `/e/tests/${item.uid}`,
          state: { fromResourceName: fromResourceName, fromUrl: location ? location.pathname + location.search : null },
        })
      }
    }
  }
};

export const unMountExperience = (
  item,
  appType,
  currentUser = null,
  dispatch = null
) => {
  if (
    appType === "mac" &&
    item.settings.mode === "offline" &&
    currentUser.role === "student"
  ) {
    window.removeEventListener("AssessPrepFocusLost",this); //TODO need to check undefined arg error whenever click on logout button from submit view, now added 'this' 
  }
  dispatch(segmentActions.fetchSuccess({ data: { segments: [] } }));
  dispatch(topicActions.fetchSuccess({ data: { segments: [] } }));
  dispatch(topicActions.setActiveIdSuccess({ id: null }));
  dispatch(experienceReduxActions.setActiveSuccess({ id: null }));
  dispatch(
    experienceUsersActions.fetchSuccess({ data: { experience_users: [] } })
  );
  console.log("firestoreInteractionActions", firestoreInteractionActions)
  dispatch(firestoreInteractionActions.setUserInfoSuccess({userInfo: {}, merge: false}))
  dispatch(firestoreInteractionActions.setExperienceInfoSuccess({
    experience_info: {}
  }, { reset: true }))
  dispatch(experienceActions.setViewModeSuccess({experienceViewMode: null})
  );

  if (currentUser.role !== "student") {
    dispatch(userResponseReduxActions.summary({ setEmpty: true }));
  }
  setToLS("graphingCalcData", null);
  // dispatch action to disconnect firebase realtime db
};

export const getMBTaskUrl = (org, experience) => {
  let subdomain = org.custom_fields.subdomain
  if(import.meta.env.VITE_MODE === "demo"){
    subdomain = "demo"
  }
  if(import.meta.env.VITE_MODE === "staging"){
    subdomain = "faria-partners.devel"
  }
  let domainSuffix = import.meta.env.VITE_REGION === "china" ? "cn" : "com"
  let mbClassId = experience.custom_fields.mb_class_id
  let mbTaskId = experience.custom_fields.mb_task_id

  return `https://${subdomain}.managebac.${domainSuffix}/teacher/classes/${mbClassId}/core_tasks/${mbTaskId}`
}

export const getExperienceModeOptionsForForm = (opts = {}, mrIntl) => {
  console.log("get expoerience optin =props==>>", mrIntl);
  let options = []
  let modesEnabled = {
    online: true,
    online_secure: opts.partnerName != "21k",
    offline: opts.partnerName != "21k" && !opts.isDisableLockdownMode,
    paper: opts.isPaperModeAllowed
  } 
  modesEnabled.online && options.push({
    label: mrIntl("ExperienceHelperMethods.any_browser"),
    // label: "Any browser",
    value: "online",
    // no-security
  })
  modesEnabled.online_secure && options.push({
    label: mrIntl("ExperienceHelperMethods.any_browser_with_security"),
    // label: "Any browser with security",
    value: "online_secure",
    // medium-stakes
  })
  modesEnabled.offline && options.push({
    label: mrIntl("ExperienceHelperMethods.lockdown_app"),
    // label: "Lockdown app",
    value: "offline",
    // lockdown - full security - allowed for all schools now
  })
  modesEnabled.paper && options.push({
    label: mrIntl("CommonText.paper_mode"),
    value: "paper"
  })
  return options
}

export const getAssessmentTypeOptionsForForm = (mrIntl) => {
  console.log("getAssessmentTypeOptionsForForm=====>", mrIntl);
  // used for pamoja quizzes right now
  let options = []
  let valuesEnabled = {
    exam: true,
    quick_check: true,
  } 
  valuesEnabled.exam && options.push({
    label: mrIntl("ExperienceHelperMethods.exam"),
    value: "exam",
    // exam for teaching-graded and manual publishing of submissions
  })
  valuesEnabled.quick_check && options.push({
    label: mrIntl("ExperienceHelperMethods.quick_check"),
    value: "quick_check",
    // Quick check for auto-grading and auto-publishing of submissions
  })
  return options
}

export const updateSegmentTypeCount = (
  data,
  dispatch = null,
  experience = null,
) => {
  let updatedSegment = data.data && data.data.segment && data.data.segment.data.attributes
  dispatch(
    experienceActions.showSuccess({
      data: {
        experience: {
          ...experience,
          custom_fields: {
            ...experience.custom_fields,
            segment_types_count: updatedSegment.exp_segment_types_count,
          },
        },
      },
    })
  );
};


export const getRecursiveSegments = (data, parent, index, level, indexPath, options, callback) => {
    console.log("getRecursiveSegments params ==>", data, parent, index, level, indexPath, options.segments, callback)
    const { segment_type, segments } = options
    indexPath[level] = index
    // NOTE: Not ordering segments by position here because segments from segmentsSelector are already ordered by position with parent_id too
    let filteredSegments = segments.filter(
      (segment) => (segment_type && segment.segment_type == segment_type || !segment_type) && segment.parent_id === parseInt(parent.id)
    );
    console.log("1 index path ==>", level, indexPath, index, filteredSegments)
    filteredSegments.forEach((segment, j) => {
      callback(data, segment, j, level, indexPath)
    })
}

export const getFirstQuestion = (topics = [], segments) => {
  let firstQuestion = null
  const options = {segment_type: "question", segments: segments}
  const callback = (data, segment, index, level) => {
    if (!firstQuestion ) {
      if (segment.question_segment_attributes.question_type != "group") {
        firstQuestion = segment
      } else {
        getRecursiveSegments({}, segment, index, level + 1, [], options, callback)
      }
    }
  } 
  topics && topics.forEach((topic, index) => { 
    getRecursiveSegments({}, (topic.segment ? topic.segment : topic), index, 0, [], options, callback)
  });

  console.log("first question ==>", firstQuestion)
  return firstQuestion
}

export const generateClassName1 = (ur = {}, rubricType, checkOnlyPoints = false) => {
  console.log("generateClassName =====>", ur);
  let currentPoints = 0, totalPoints, className = "";

  if (checkOnlyPoints) {
    currentPoints = ur.points;
    totalPoints = ur.custom_fields.experience_points;
  } else if (rubricType === "myp_achievement_level") {
    let urSCAs = ur.submission_criterium_associations_attributes
    // if (ur.max_points) {
    //   totalPoints = ur.max_points // coming as 0.0 in myp_al 
    // } else {
      totalPoints = ur && urSCAs && urSCAs.length * 8;
    // }
    if (ur.points) {
      currentPoints = ur.points
    } else {
      urSCAs && urSCAs.forEach((sca) => {
        if (sca.points) {
          currentPoints += parseFloat(sca.points)
        }
      })
    }
  } else if (rubricType === "comments_only") {
    if (ur && !isStringEmpty(ur.comments)) {
      return "blue"
    }
  } else {
    currentPoints = ur.points;
    totalPoints = ur.max_points;
  }

  const percent = calculatePercentage(currentPoints, totalPoints);
  console.log("generateClassName calculatePercentage", currentPoints, totalPoints, ur && !ur.marked && !checkOnlyPoints, percent);

  if (ur && !ur.marked && !checkOnlyPoints) { 
    className = "gray";
  } else {
    if (percent <= 25) {
      className = "red";
    } else if (percent <= 50) {
      className = "orange";
    } else if (percent <= 75) {
      className = "yellow";
    } else if (percent <= 100){
      className = "green";
    }
  }
  return className;
};



export const generateClassName = (item = {}, rubricType, itemType = "user_response") => {
  console.log("generateClassName =====>", item);
  let currentPoints = 0, totalPoints, className = "";

  if(rubricType === "myp_achievement_level"){
    if(itemType === "user_response"){
      if (item.marked) {
        return "blue"
      }  
    } else {
      // eu case only now
      let urSCAs = item.submission_criterium_associations_attributes
      // not using item.max_points or item.points - not accurate - see APL-2971
      totalPoints = item && urSCAs && urSCAs.length * 8;
      let scaPointsNull = false
      urSCAs && urSCAs.forEach((sca) => {
        if (sca.points) {
          currentPoints += parseFloat(sca.points)
        } else {
          if (sca.custom_fields && sca.custom_fields.is_na === true) {
            // counting N/A as graded
          } else {
            scaPointsNull = true
          }
        }
      })
      if(scaPointsNull){
        // means overall ungraded
        return "gray"
      }
    }
  } else if(rubricType === "comments_only") {
    let decidingFactor = itemType === "experience_user" ? (!isStringEmpty(item.comments) || (item.custom_fields.media_comments && item.custom_fields.media_comments.attachments.length > 0 )) : item.marked
    if (decidingFactor) {
      return "blue"
    } else {
      return "gray"
    }
  } else {
    currentPoints = item.points;
    totalPoints = itemType === "experience_user" ? item.custom_fields.experience_points : item.max_points;
  }

  const percent = calculatePercentage(currentPoints, totalPoints);
  console.log("generateClassName calculatePercentage", currentPoints, totalPoints, item && !item.marked && itemType, percent);

  if (item && !item.marked && itemType === "user_response") { 
    className = "gray";
  } else {
    if (percent <= 25) {
      className = "red";
    } else if (percent <= 50) {
      className = "orange";
    } else if (percent <= 75) {
      className = "yellow";
    } else if (percent <= 100){
      className = "green";
    }
  }
  return className;
};

export const highLightActiveCell = (selectedCell) => {
  if (selectedCell) {
    selectedCell.scrollIntoView({ inline: "center", block: "center" })

    selectedCell.classList.add("selected-cell");
    selectedCell.closest("td").style.outline = "none"
    selectedCell.closest("td").focus();
  }
}

export const removeHighLightActiveCellClass = () => {
  const cells = document.querySelectorAll(".selected-cell");
  cells && cells.forEach((cell) => {
    cell.classList.remove("selected-cell");
  });
}

export const isOfflineExperienceAndNotAllowedOnline = (experience, appType) => {
  if(experience && 
    experience.settings && 
    experience.settings.mode === "offline" &&
    appType === "web" &&
    !(experience.student_experience_membership &&
    experience.student_experience_membership.settings.allow_online)
  ){
    return true
  } else {
    return false
  }
}