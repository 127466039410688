import React, { useCallback, useEffect, useRef, useState } from "react";
import "./RenderHtml.scss"
import { Image } from "antd";
import parse from "html-react-parser";
import ReactDOMServer from 'react-dom/server'
import { useTextHighlighter } from "/src/views/TextHighlighter/HighlighterHelper";

const RenderHtml = ({ uniqueTextIdentifier = "renderHtml", text, truncate, style, extraClassname = "", replaceItem, renderUsingParse= false}) => {
  console.log("RenderHtml",  replaceItem, renderUsingParse)
  const htmlRef = useRef(null);
  const texthighlighter = useTextHighlighter(htmlRef, uniqueTextIdentifier)


  if(!text){
    return "";
  }

  // let imgHtml = "<h1>trying</h1><img class='zoomable' src='https://d3kgcbih1bmz00.cloudfront.net/direct_uploads/attachments/01FW5FASH64RF28Q8VQ6KEBYHM/image.png' alt=''/>"
  

  return (
    // <div className={truncate ? "render-html" : "show-html"}>
    //   {parse(text, {
    //     replace: (item) => {
    //       if (item.name === "img") {
    //         console.log("RenderHtml parsed item", item)
    //         // return <EnlargableImage {...item.attribs} />;
    //         {/* return <Image preview={{ mask: false }} {...item.attribs} />; */}
    //         return (
    //           <Image
    //             preview={{ mask: false }}
    //             src={item.attribs.src}
    //             alt={item.attribs.alt}
    //             className={item.attribs.class} // image_resized class for eg
    //             // style={item.attribs.style} // TODO: when image resized, its style but its a string - not applied need to convert to object; and also ant-image display-inline is already restricting width it seems - need to figure this out
    //           />
    //         );
    //       }
    //     },
    //   })}
    // </div>
    // <div
    //   className={truncate ? "render-html" : "show-html"}
    //   dangerouslySetInnerHTML={{ __html: imgHtml }}
    // ></div>
    <div>
      {renderUsingParse ?
        <div ref={htmlRef} 
        //  {...customEvents}
         >
          {parse(text, {
            replace: (content) => replaceItem(content)
          })}

        </div>
        :
        <div
          style={style}
          className={`${extraClassname} ${truncate ? "render-html" : "show-html"}`}
          // dangerouslySetInnerHTML={{ __html: text }}
          ref={htmlRef}
          // {...customEvents}
        // dangerouslySetInnerHTML={{ __html: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of de Finibus Bonorum et Malorum (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, Lorem ipsum dolor sit amet.., comes from a line in section The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham." }}
        // dangerouslySetInnerHTML={{ __html: "1. Luke has dropped a football from the height of a cliff. The equation <math xmlns='http://www.w3.org/1998/Math/MathML'><mi>y</mi><mo>=</mo><mo>-</mo><mn>0.5</mn><mi>g</mi><msup><mi>x</mi><mn>2</mn></msup><mo>+</mo><mi>v</mi><mi>x</mi><mo>+</mo><mi>h</mi></math> represents the height 'y' of the football (in meters) at the time 'x' (in seconds). Here, 'g' is the acceleration due to gravity (9.8 m/s<math xmlns='http://www.w3.org/1998/Math/MathML'><msup><mi>s</mi><mn>2</mn></msup></math>), 'v' is the initial velocity of the football (assumed to be 0), and 'h' is the initial height of the football from where it was dropped (assume it to be 100 m). Using the given equation, calculate the time 'x' when the football will reach the ground." }}
        >{parse(text)}</div>
      }
    </div>
  );
};

export default RenderHtml;
