
import React from 'react';
import { Alert, Button, Result } from 'antd';
import QuitBtn from '/src/components/UI/QuitBtn';
import dayjs from 'dayjs';
import BackToHomeBtn from '/src/components/UI/BackToHomeBtn';
import { useSelector } from 'react-redux';
import { appTypeSelector } from '/src/App/OfflineApp/offlineAppSelectors';
import { currentUserSelector } from '/src/views/Auth/Login/selector';

const NotAllowedAlert = (props) => {
  const { message, experience } = props;

  const appType = useSelector(appTypeSelector());
  const currentUser = useSelector(currentUserSelector());

  const isGuest = currentUser.custom_fields.account_type === "guest"
  
  let subTitle = []
  let extra = []
  subTitle.push(
    <Alert
      style={{ marginTop: "20px" }}
      message={message}
      type="error"
      showIcon
    />
  )

  if (appType === "web" && !isGuest) {
    extra.push(<BackToHomeBtn experience={experience} />)  
  }
  extra.push(<Button onClick={() => { window.location.reload() }}>Refresh</Button>)
  extra.push(<QuitBtn />)

  return <React.Fragment>
    <Result
      className="end-test-result"
      status="warning"
      // icon={<EditOutlined />}
      title={experience.name}
      subTitle={subTitle}
      extra={extra}
    />

  </React.Fragment>;
}

export default NotAllowedAlert;
