import React from "react";
import { Tooltip, Space } from "antd";
import { checkMob } from "/src/lib/utils/helperMethods";

const WithTooltip = ({ children, maxLength, title }) => {
  let tooltipTitle = title ? title : children;
  let textMaxLength;
  if (maxLength) {
    textMaxLength = maxLength;
  } else {
    textMaxLength = checkMob() ? 10 : 25;
  }

  const text =
    children && children.length > textMaxLength ? (
      <Tooltip title={tooltipTitle}>
        <Space>{children.substr(0, textMaxLength) + "..."}</Space>
      </Tooltip>
    ) : (
      <Space>{children}</Space>
    );
  return text;
};

export default WithTooltip;
