import React from 'react';
import { Breadcrumb, Row, Col, Pagination, Button, Space, Skeleton} from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
// import Spinner from '../../../UI/Spinner/Spinner';
import ExperienceUsersActions from './ExperienceUsersList/ExperienceUsersActions';
import "./GradingExperienceUsersCustomLayout.scss"
import RightSideGradingActions from './RightSideGradingActions';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';


export const CYButton = styled(Button)`
  background: ${(props) => props.theme.button.background};
  color: ${(props) => props.theme.main.colors.primary};
`;
const GradingExperienceUsersCustomLayout = (props) => {
  console.log("GradingExperienceUsersCustomLayout ==>", props)
  const {
    resourceName,
    singleResourceName,
    filter,
    forms,
    MrFilter,
    displayName,
    resourceForm,
    CustomListItem,
    ActionBtns,
    params,
    setParams,
    showFormVal,
    showForm,
    loading,
    item = {},
    handleOk,
    handleCancel,
    refresh,
    dataSource,
    pagination,
    onPageChange,
    edited,
    deleted,
    permission,
    currentUser,
    ListHeader,
    resourceUrl,
    parentInstance,
    hocProps,
    selectorProps,
    renderList,
    renderForm,
    manualFormRender,
    crudMode,
    
    formMode,
    config,
    duplicateResource,
    crudListData
  } = props;
  // let { path, url } = useRouteMatch();
  // const history = useHistory();
  
  const history = props.history;
  const path = props.match.path;
  const url = props.match.url;
  const CrudListData = crudListData;
  let isRoute = false;
  const mrIntl = useTranslate()
  
  const isStudent = currentUser.role === "student" || currentUser.role === "parent";


  // if(dataSource.length === 0) return null;
  if (hocProps.isRoute && props.isRoute) {
    isRoute = true;
  }
  // if (!item) {
  //   item = {};
  // }
  
  let newItem = {
    ...item,
  };
  
  const resourcePermission = permission[singleResourceName] || {};
  let toRender;
  const content = [];

  let visible = false;
  // if (loading && item && item.size === 0) {
  //   // debugger;
  //   return <Spinner />;
  // }
  
  if (showFormVal === resourceName) {
    visible = true;
  }
  
  let extraFilters = [];

  if (filter) {
    extraFilters = filter({
      ...params,
      ...selectorProps,
      changed: setParams,
      url,
      setParams,
      resourceName,
      singleResourceName,
      parentProps: {
        ...props
      }
    });
  }
  const filterProps = {
    parentProps: {
      ...props
    },
    name: displayName,
    singleResourceName,
    extraFilters,
    ...selectorProps,
    config: config.filter.config || {}
  };
  const Filter = MrFilter;
  
  
  // const intl = {
  //   formatMessage: () => {}
  // }
  
  // if (!manualFormRender) {
  //   const formHelperProps = {
  //     currentUser,
  //     permission,
  //     selectorProps,
  //     forms,
  //     handleOk,
  //     handleCancel,
  //     newItem,
  //     loading,
  //     displayName,
  //     showFormVal,
  //     resourceName,
  //   };
    
  //   const form = <MrFormRenderHelper {...formHelperProps}/>;

  //   content.push(form);
  // }
  let createBtn = null;
  
  let totalColWidth = 24;
  const gradeListActionsButton = !isStudent && (
    <RightSideGradingActions
      dataSource={props.dataSource}
      experience={props.experience}
      gradingConfig={props.gradingConfig}
      setGradingConfig={props.setGradingConfig}
      exportModalConfig={props.exportModalConfig}
      setExportModalConfig={props.setExportModalConfig}
    />
  );
  
  
  if (
    permission &&
    permission[singleResourceName] &&
    permission[singleResourceName].create
  ) {
    if(config.list.createBtn.component) {
      createBtn = config.list.createBtn.component;
    } else {
      // CreateBtnComponent = CYButton;
      createBtn = function CreateBtn(parentProps) {
        return <CYButton
          key={"createBtn"}
          // onClick={() => showForm({ name: resourceName })}
          type="primary"
          onClick={() => history.push(`${url}/create`)}
          icon={<PlusOutlined />}
        >
          <FormattedMessage
            id="general.create"
            defaultMessage={mrIntl("GradingExperienceUsersCustomLayout.create")}
            // defaultMessage={`{text}`}
            // description="Create"
            // values={{ text: "Create" }}
          />
        </CYButton>
      }
    }
    // content.push(createBtn);
  }
  const finalFilter = config.filter.show ? (
    <Space
      className="p-0-20"
      key={`filter${singleResourceName}MainContainer`}
    >
      <Filter
        key={`filter${singleResourceName}Main`}
        {...params}
        changed={setParams}
        setParams={setParams}
        refresh={refresh}
        createBtn={createBtn}
        {...filterProps}
      // create={() => this.showForm({ name: resourceName })}
      />
      <div className="exp-users-actions-btn p-0-20">
        {gradeListActionsButton}
      </div>
    </Space>
  ) : null;
  // if (isRoute) {
  //   content.push(
      
  //   );
  // }
  const listWrapperWidth = config.filter.style.width < 12 ? (totalColWidth - config.filter.style.width) : totalColWidth;
  
  console.log( "listWrapperWidth - width", listWrapperWidth );

  const list = <CrudListData key={`mainCrudList${resourceName}`} {...props}/>
  const listWrapper = (
    <Col span={listWrapperWidth} key={`mainList${resourceName}Container`}>
      <Row>
        {list}
        {hocProps.isRoute && config.pagination.show ? <Col span={24}>
        <Row>
          <Pagination
            key={"mainPagination"}
            // showQuickJumper
            total={pagination.totalCount}
            pageSize={pagination.pageSize}
            // showTotal={total => `Total ${total} items`}
            onChange={onPageChange}
            current={pagination.page}
            // to allow to disable show page size
            {...config.pagination.config}
            // defaultCurrent={params.page}
          />
        </Row>
      </Col> : null}
      </Row>
    </Col>
  );
  
  
  content.push(ListHeader);
  content.push(listWrapper);

  
  // content.push(pagination.totalPages);
  // if (hocProps.isRoute) {
  //   content.push(
  //     config.pagination.show ? () : null
  //   );
  // }
  // let breadcrumb = (
  //   <Breadcrumb key={"mainBreadCrumb"}>
  //     <Breadcrumb.Item>
  //       <a href="">Home</a>
  //     </Breadcrumb.Item>
  //     <Breadcrumb.Item>{displayName}</Breadcrumb.Item>
  //   </Breadcrumb>
  // );
  toRender = (
    // <Row key={"mainRowCRUDLayout"} loading={loading}>
    <Row key={"mainRowCRUDLayout"} >
      {content}
    </Row>
  );
  return (
    <React.Fragment>
      <Row>
        <Col span={24}>{config.filter.style.align !== "right" ? finalFilter : null}</Col>
        {/* {config.filter.style.align == "right" ? finalFilter : null} */}
      </Row>
      <Row>
        <Col span={24}>{loading ?
          <>
            {[...Array(3)].map((_, index) => (
              <Skeleton
                className="active-skeleton-loading"
                key={index}
                active
                title={true}
                paragraph={{ rows: 2 }}
              />
            ))}
          </>
          : content}</Col>
      </Row>
    </React.Fragment>
  );
};

// GradingExperienceCustomLayout.defaultProps = {}

GradingExperienceUsersCustomLayout.propTypes = {}
if(import.meta.env.NODE_ENV === 'development'){
  GradingExperienceUsersCustomLayout.whyDidYouRender = true;
}
export default GradingExperienceUsersCustomLayout;