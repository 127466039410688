import React, { useEffect, useState } from "react";

import { Tabs, Input, Radio, Button, Checkbox, Upload, Row, Col } from "antd";
import { InboxOutlined, UploadOutlined, SoundOutlined, PauseCircleOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { initialData, addItemToArray, removeItemFromArray, convertDataAsPerNewSystem, getParsedJSONObject, numaricalSort } from "/src/views/Segments/InteractiveHelpers";
import { AddButton, DeleteButton, CheckBox, InputArea, MCQArea, MCQList, TextArea, ShowText, ShowTextIndex, ShowTextContent } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { MrInput } from "mr_react_framework"
import { debounce } from 'lodash';
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import InsertFromURL from "/src/components/UI/Media/InsertFromURL";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments"
import ListAttachments from "/src/components/UI/Attachments/ListAttachments";
import { useSelector } from "react-redux";
import { printModeSelector } from "/src/views/Experiences/selector";

const SimulationView = props => {
  console.log("SImulation view props", props);

  const { createMode, resource_json, onTabEdit, onTextChanged, removeFile, caption, title } = props

  const segment_data = resource_json.segment_data
  const resource_setting = resource_json.resource_setting || {}
  const attachments = segment_data.attachments || []
  const printMode = useSelector(printModeSelector());
  return (
    <div>
      {printMode ? (
        <ListAttachments
          key={`list-attachments-${props.unique_segment_identifier}`}
          titlePrefix={"Simulation"}
          attachments={attachments}
          title={title}
          resource_setting={resource_setting}
          caption={caption}
          attachmentType="simulation"
        ></ListAttachments>
      ) : (
        <TabularAttachments
          attachmentType="simulation"
          tabPrefix="Simulation"
          attachments={attachments}
          resource_setting={resource_setting}
        ></TabularAttachments>
      )}
    </div>
  );
}
export default SimulationView;