import React, { useState } from "react";
import { MrSelect } from "mr_react_framework";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import { userRoleMap } from "/src/lib/utils/helperMethods";
import SelectOrgDropdown from "/src/components/AppSpecific/SelectOrg/SelectOrgDropdown";
import { SelectFeedbackType } from "/src/components/AppSpecific/SelectSubject/SelectSubject";

const SelectRole = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      let arr = [];
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          arr.push({ label: userRoleMap[key], value: key });
        }
      }
      return arr;
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   {label: "Admin", value: "admin"},
      //   {label: "Teacher", value: "teacher"},
      //   {label: "Student", value: "student"},
      //   {label: "Parent", value: "parent"},
      // ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Select role",
      // mode: "multiple",
      // style: {width: "500px"},
      allowClear: true,
    },
  },
});

const FeedbackFilter = (props) => {
  console.log("FeedbackFilter", props);
  //   const [feedbackType, setFeedbackType] = useState("");

  //   const changeFeedbackType = (e) => {
  //     setFeedbackType(e.target.value);
  //   };

  let extraFilters = [];
  let by_org_id = null;
  let include_archived;

  if (
    props.currentUser.role == "superadmin" ||
    props.currentUser.role == "support"
  ) {
    if (props.by_org_id) {
      by_org_id = parseInt(props.by_org_id);
    }
    extraFilters.push(
      <SelectOrgDropdown
        value={by_org_id}
        onChange={(value) => props.setParams({ by_org_id: value })}
      />
    );
  }

  let by_feedback_type;
  if (props.by_feedback_type) {
    by_feedback_type = props.by_feedback_type;
  }

  extraFilters.push(
    <SelectFeedbackType
      value={by_feedback_type}
      onChange={(value) => {
        // changeFeedbackType(value);
        props.setParams({ by_feedback_type: value });
      }}
    />
  );
  extraFilters.push(
    <ResetFilter key={"resetFiltersBtn"} setParams={props.setParams} />
  );

  return extraFilters;
};

FeedbackFilter.defaultProps = {};

FeedbackFilter.propTypes = {};

export default FeedbackFilter;
