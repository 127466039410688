import { createSelector } from "reselect";
import { activeAdjustedExperienceIdState, activeAdjustedExperienceState } from "/src/views/Experiences/selector";
import { segmentsSelector } from "../selector";
import _ from "lodash";

// const topicsState = (state, props) => {
//   return state.topics.get("segments")
// };

// const topicsState = (state, props) => {
//   let finalTopics = state.topics.get("segments");
//   if(finalTopics.size && finalTopics.size === 0 ){
//     finalTopics = [];
//   }
//   return finalTopics;
// };

const topicsState = (state, props) => {
  let finalTopics;
  let tempTopics = state.topics.get("segments");
  try {
    finalTopics = tempTopics.toJS();
  } catch (error) {
    finalTopics = tempTopics;
  }
  return finalTopics;
};

export const topicsSelector = () =>
  createSelector(
    [topicsState, activeAdjustedExperienceState],
    (topics, activeAdjustedExperience) => {
      console.log(
        "activeAdjustedExperience",
        activeAdjustedExperience,
        topics
      );
      let finalTopics = []
      if (activeAdjustedExperience) {
        let activeAdjustedExperienceId = activeAdjustedExperience.master_editable ? activeAdjustedExperience.id : activeAdjustedExperience.original_id
        finalTopics = topics.filter(
          (item) => item.experience_id === parseInt(activeAdjustedExperienceId)
        );
        console.log("finalTopics", finalTopics);
        // return finalTopics;
      } else {
        finalTopics = topics;
      }

      finalTopics = _.orderBy(finalTopics, ["position"], ["asc"]) 
      return finalTopics
    }
  );

const activeTopicIdState = (state, props) => {
  return state.topics.get("activeTopicId", null);
}

export const activeTopicIdSelector = () =>
  createSelector([activeTopicIdState], (topicId) => topicId);


const activeTopicState = (state, props) => {
  const activeTopicId = activeTopicIdState(state)
  if (!activeTopicId) {
    return {}
  }
  return state.topics.get("segments").find((item) => item.id === parseInt(activeTopicId)) || {}
}

export const activeTopicSelector = () =>
  createSelector([activeTopicState], (activeTopic) => activeTopic);