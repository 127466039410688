import validator from "validator";
import _ from "underscore.string";

export const parseHashFromUrl = (hash) => {
  try {
    const hashArray = hash.split("#")[1].split("&")
    const parsedHash = {}
    for (let index = 0; index < hashArray.length; index++) {
      const element = hashArray[index];
      const parsedElement = element.split("=");
      if(parsedElement.length > 0){
        parsedHash[parsedElement[0]] = parsedElement[1]
      }
      
    }
    return parsedHash;
    
  } catch (error) {
    return null;
  }
}
export const getValidationRule = str => {
  // console.log("str", str);

  // let returnStr = str.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
  //   return g1.toUpperCase() + g2.toLowerCase();
  // });
  let returnStr = _.classify(str);
  returnStr = `is${returnStr}`;
  // console.log("returnStr", returnStr);
  return returnStr;
};
export const cloneObject = obj => {
  let clone = {};
  if (Array.isArray(obj)) {
    clone = [];
  }
  for (var i in obj) {
    if (obj[i] != null && typeof obj[i] == "object")
      clone[i] = cloneObject(obj[i]);
    else clone[i] = obj[i];
  }
  return clone;
};
export const updateNested = (propertyPath, value, obj = {}, extraOpts = {}) => {
  // if (controlName === "object") {
  //   finalValue = event;
  // } else {}
  if (extraOpts.debugger) {
    // debugger;
  }
  let finalValue;
  if (typeof value === "function") {
    finalValue = value.target.value;
  } else {
    finalValue = value;
  }
  // let finalObj = JSON.parse(JSON.stringify(obj));
  let finalObj = cloneObject(obj);
  if (recNest(propertyPath, finalValue, finalObj)) {
    return finalObj;
  } else {
    return finalObj;
  }
};
// export const usingUpdateMethod = (propertyPath, value, obj = {}){

// }

export const getNested = (propertyPath, obj = {}) => {
  let properties = Array.isArray(propertyPath)
    ? propertyPath
    : propertyPath.split(".");
  if (properties.length > 1) {
    if (
      !obj.hasOwnProperty(properties[0]) ||
      typeof obj[properties[0]] !== "object"
    ) {
      // obj[properties[0]] = {};
      return undefined;
    }
    return getNested(properties.slice(1), obj[properties[0]]);
    // doneProperties.push(properties[0]);
    // let tempObj = recNest(
    //   properties.slice(1),
    //   value,
    //   finalObj[properties[0]],
    //   doneProperties
    // );

    // console.log("length 1", finalObj, value);
  } else {
    // obj[properties[0]] = value;
    return obj[properties[0]];
  }
};
export const recNest = (propertyPath, value, obj = {}, options = {}) => {
  console.log("propertyPath, obj, value", propertyPath, obj, value);
  // let keyArr = propertyPath.split(".");
  let properties = Array.isArray(propertyPath)
    ? propertyPath
    : propertyPath.split(".");

  if (properties.length > 1) {
    if (
      !obj.hasOwnProperty(properties[0]) ||
      typeof obj[properties[0]] !== "object"
    ) {
      obj[properties[0]] = {};
      if (typeof properties[1] !== undefined) {
        let index = parseInt(properties[1]);
        if (!isNaN(index)) {
          obj[properties[0]] = [];
        }
        console.log("index", index);
      }
      // if(properties[0] ==)
    }
    return recNest(properties.slice(1), value, obj[properties[0]]);
    // doneProperties.push(properties[0]);
    // let tempObj = recNest(
    //   properties.slice(1),
    //   value,
    //   finalObj[properties[0]],
    //   doneProperties
    // );

    // console.log("length 1", finalObj, value);
  } else {
    if (Array.isArray(value)) {
      obj[properties[0]] = value;
    } else if (typeof value === "object") {
      // console.log("last obj", obj);
      obj[properties[0]] = {
        ...obj[properties[0]],
        ...value,
      };
    } else {
      obj[properties[0]] = value;
    }
    // obj[properties[0]] = value;
    return true;
  }

  // console.log("finalObj", finalObj);
};
class MrValidationHelper {
  constructor(props = {}) {
    // super(props);
    // three inputs, options, str, item
    this.fields = {};
    this.valueFields = {};
    this.options = props.options || {};
    this.isShowMessages = false;
    this.isAllValid = false;
  }
  showMessages() {
    this.isShowMessages = true;
  }
  fieldValid(key, value = null) {
    let isFieldValid = false;
    if (!this.fields.hasOwnProperty(key)) {
      isFieldValid = false;
    } else {
      if (value) {
        isFieldValid = this.isValid(key, value, this.fields[key].rules);
      } else {
        isFieldValid = this.fields[key].valid || false;
      }
    }
    return isFieldValid;
  }
  isValid(key, value, rules, options = {}) {
    if (rules === undefined || rules === null) {
      return true;
    }
    let rulesArr = rules.split("|");

    let isValid = true;
    for (let index = 0; index < rulesArr.length; index++) {
      const rule = rulesArr[index];
      // console.log("rule", rule);
      if (rule === "required") {
        if (value === "" || value === null || value === undefined) {
          isValid = false;
          break;
        }
      } else {
        // console.log("value", value);
        // console.log("rule 1", rule);
        try {
          isValid = validator[getValidationRule(rule)](value);
        } catch (error) {
          console.log(
            "rule, value, \nerror in isValid @line 54",
            rule,
            value,
            error
          );
          isValid = true;
        }
        if (!isValid) {
          break;
        }
      }
    }
    return isValid;
  }
  allValid = () => {
    let isAllValid = false;
    for (const key in this.fields) {
      if (this.fields.hasOwnProperty(key)) {
        const element = this.fields[key].valid;
        if (!element) {
          isAllValid = false;
          break;
        } else {
          isAllValid = true;
        }
      }
    }
    // for (let index = 0; index < this.fields.length; index++) {
    //   const element = this.fields[index];
    //   if (!element) {
    //     isAllValid = false;
    //     break;
    //   }
    // }
    this.isAllValid = isAllValid;
    return isAllValid;
  };
  message(key, value, rules, options = {}) {
    const isValid = this.isValid(key, value, rules, options);

    if (!isValid) {
      this.isAllValid = false;
    }
    // this.fields.push({ [key]: isValid, });

    this.fields[key] = { valid: isValid, rules: rules, };

    if (this.isShowMessages) {
      // return `${key} field is not valid`;
      return null;
    } else {
      return null;
    }
  }
  setFieldValue(key, value, options = {}) {
    this.valueFields = updateNested(key, value, this.valueFields);
    // this.valueFields[key] = value;
    return true;
  }
}
export default MrValidationHelper;
