import { createSelector } from "reselect";
// import lodash from "lodash";

const canvasLoadingState = (state, props) => {
  return state.canvasAnnotations.get("loading");
};
export const canvasLoadingSelector = () =>
  createSelector([canvasLoadingState], (loading) => loading);

// const errorState = (state, props) => state.annotations.get("error");

// export const errorSelector = () =>
//   createSelector([errorState], (error) => error);

// const annotationsState = (state, props) =>
//   state.annotations.get("annotations");

// export const annotationsSelector = () =>
//   createSelector([annotationsState], (annotations) => annotations);

// const annotationsShowState = (state, props) => {

//   const annotations = state.annotations.get("annotations");

//   if (annotations.size === 0) {
//     return {};
//   } else {
//     const id = parseInt(props.match.params.id);
//     const idx = lodash.findIndex(annotations, {
//       id: id,
//     });
//     if (idx !== -1) {
//       return annotations[idx];
//     }
//     return {};
//   }
// };

// export const annotationsShowSelector = () =>
//   createSelector([annotationsShowState], (annotations) => annotations);

// const paginationState = (state, props) => {
//   const pageObj = {};
//   pageObj.page = state.annotations.get("page");
//   pageObj.totalPages = state.annotations.get("total_pages");

//   return pageObj;
// };

// export const paginationSelector = () =>
//   createSelector([paginationState], (pageObj) => pageObj);
