import React from "react";
// import "./GroupItemDetail.scss";
import { Route, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import OrgItemDetail from "/src/views/Orgs/OrgItemDetail/OrgItemDetail";
import Orgs from "/src/views/Orgs/Orgs";

const OrgItemDetailWrapper = (props) => {
  console.log("itemDetail props", props);
  // const { item, history } = props;
  const { path, url } = useRouteMatch();

  const orgId = useSelector((state) => {
    return state.login.get("org").id;
  });

  console.log("itemDetail orgId", orgId);
  console.log("itemDetail path", path, url);

  return (
    <React.Fragment>
      <Route
        path={`${path}`}
        render={(routeProps) => (
          <Orgs
            {...props}
            resourceId={orgId}
            isRoute={false}
            {...routeProps}
            config={{
              routes: { fetch: false },
              itemDetail: { widget: OrgItemDetail },
            }}
          />
        )}
      />
    </React.Fragment>
  );
};

OrgItemDetailWrapper.defaultProps = {};

OrgItemDetailWrapper.propTypes = {};

export default OrgItemDetailWrapper;
