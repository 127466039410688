import React from "react";
import { Tabs, Button, Card, Space, Tag, Typography, Tooltip } from "antd";
import {
  AppleOutlined,
  WindowsOutlined,
  CopyOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
// import { appRegionSelector } from "/src/views/Auth/Login/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import {
  appRegionSelector,
  currentUserSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";
import {
  writeToClipboardPolyfill,
} from "/src/lib/utils/helperMethods";
import { getLatestAppVersion, getOfflineAppDownloadUrl } from "/src/App/OfflineApp/offlineAppHelper";

const OfflineApps = () => {
  const { TabPane } = Tabs;
  const { Text, Link } = Typography;
  const appRegion = useSelector(appRegionSelector());
  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const chromebookKioskAppUrl = `${import.meta.env.VITE_WEB_URL}/auth/login?appType=cros`;
  const getMacAppLatestVersion = (returnAAC = false) => getLatestAppVersion("mac", enabledFeatures, returnAAC)
  const sebAppLatestVersion = getLatestAppVersion("seb")
  const isLegacyMacVersion902Allowed = currentUser?.custom_fields.accommodations?.legacy_ap_mac_9_0_2_allowed;
  const mrIntl = useTranslate();
  return (
    <>
      {/* <h1>
                Lockdown Applications
            </h1> */}
      <Card bordered={false}>
        {/* Download AssessPrep desktop apps for Windows and Mac. Our apps support Lockdown mode for secure assessments. */}
        <MrTranslate
          id={"OfflineApps.download_assessprep_desktop_apps_for_windows_msg"}
        />
        {/* See the installation instructions to setup the Apps on all student laptops. You can setup the Apps on your computer as well to try them out. */}
      </Card>
      <Card className="offline-apps">
        <Tabs>
          {/* <TabPane tab={`MAC (OSX 10.15.4 and above)`} key="mac_aac"> */}
          {/* <TabPane tab={mrIntl("OfflineApps.mac_os_10_15_4_and_above")} key="mac_aac">
            <Space>
              <Button
                type="primary"
                icon={<AppleOutlined />}
                href={getOfflineAppDownloadUrl(
                  "mac",
                  appRegion,
                  enabledFeatures,
                  true
                )}
              >
                {mrIntl("OfflineApps.download_assessprep_mac_v")}{getMacAppLatestVersion(true)}
              </Button>
              <Tag color="error">New update!</Tag> */}

              {/* Manual uploads in global */}
              {/* https://d15evq3hcqea3u.cloudfront.net/AP+Mac/AssessPrep+Mac+v8.0.0_2022_01_25.dmg */}
            {/* </Space>
            <br></br>
            <br></br>
            <h3>
              <MrTranslate
                id={"OfflineApps.instructions_to_install_the_app_on_mac"}
              />
            </h3>
            <ol>
              <li> */}
                {/* You can take a test by using our AssessPrep app for Mac.
                Download the app using the button above. */}
                {/* <MrTranslate
                  id={
                    "OfflineApps.you_can_take_a_test_by_using_our_assessprep_app_msg"
                  }
                />
              </li>
              <li> */}
                {/* Double click on the downloaded .dmg file and follow the
                instructions. */}
                {/* <MrTranslate
                  id={
                    "OfflineApps.downloaded_dmg_file_and_follow_the_instruction_msg"
                  }
                /> */}
                {/* Please make sure you drag the application to the Applications folder on your Mac as per the instructions. A shortcut on the Desktop will also be created. */}
              {/* </li> */}
              {/* <li>
                                Launch the App by double clicking on the AssessPrep shortcut on the Desktop or from your Applications
                            </li> */}
            {/* </ol> */}
          {/* </TabPane> */}
          {/* <TabPane tab={`MAC (OSX 10.15.3 and lower)`} key="mac"> */}
          {/* <TabPane tab={mrIntl("OfflineApps.mac_10_13_and_above")} key="mac"> */}
          <TabPane tab={mrIntl("OfflineApps.mac")} key="mac">
            <Space>
              {/* <Button type="primary" icon={<AppleOutlined />} href="https://tinyurl.com/y29yofu7">Download SEB for Mac</Button> */}
              {/* <Button type="primary" icon={<AppleOutlined />} href="https://tinyurl.com/yywh2eyy">Download AssessPrep Mac v7.0.0</Button> */}
              {/* <Button type="primary" icon={<AppleOutlined />} href="https://tinyurl.com/4yxyv7er">Download AssessPrep Mac v7.0.1</Button> */}
              <Button
                type="primary"
                icon={<AppleOutlined />}
                href={getOfflineAppDownloadUrl(
                  "mac",
                  appRegion,
                  enabledFeatures
                )}
              >
                {/* {" "} */}
                {/* Download AssessPrep Mac v */}
                {/* <MrTranslate id={"OfflineApps.download_assessprep_mac_v"} /> */}
                {mrIntl("OfflineApps.download_assessprep_mac_v")}
                {
                  enabledFeatures.release_mac_app_v_12_0_0 
                    ? "12.0.0"
                    : enabledFeatures.release_mac_app_v_11_0_0
                      ? "11.0.0"
                      : "10.2.0"
                }
              </Button>
              { 
              (enabledFeatures.release_mac_app_v_11_0_0 || 
                enabledFeatures.release_mac_app_v_12_0_0) &&
                <Tag color="error">
                  <MrTranslate id={"OfflineApps.new_update"} />
                </Tag>
              }

              {/* Manual uploads in global */}
              {/* https://d15evq3hcqea3u.cloudfront.net/AP+Mac/AssessPrep+Mac+v8.0.0_2022_01_25.dmg */}
            </Space>
            <br></br>
            <br></br>
            <h4><b>{mrIntl("OfflineApps.supported_versions_macos_11_and_above")}</b></h4>
            {/* <h3>Instructions to install the App on Mac:</h3> */}
            <h3>
              <MrTranslate
                id={"OfflineApps.instructions_to_install_the_app_on_mac"}
              />
            </h3>
            <ol>
              <li>
                {/* You can take a test by using our AssessPrep app for Mac.
                Download the app using the button above. */}
                <MrTranslate
                  id={
                    "OfflineApps.you_can_take_a_test_by_using_our_assessprep_app_msg"
                  }
                />
              </li>
              <li>
                {/* Double click on the downloaded .dmg file and follow the
                instructions. */}
                <MrTranslate
                  id={
                    "OfflineApps.downloaded_dmg_file_and_follow_the_instruction_msg"
                  }
                />
                {/* Please make sure you drag the application to the Applications folder on your Mac as per the instructions. A shortcut on the Desktop will also be created. */}
              </li>
              {/* <li>
                                Launch the App by double clicking on the AssessPrep shortcut on the Desktop or from your Applications
                            </li> */}
            </ol>
          </TabPane>
          <TabPane
            tab={mrIntl("OfflineApps.windows")}
            key="windows"
          >
            <Tabs>
              <TabPane 
                tab={mrIntl("OfflineApps.windows_10_11")} 
                key="win10_11"
              >
                {/* 3.1 */}
                {/* <Button type="primary" icon={<WindowsOutlined />} href="https://tinyurl.com/yxhn4jts">DOWNLOAD SEB for WINDOWS</Button> */}
                {/* 3.1.1 */}
                {/* <Button type="primary" icon={<WindowsOutlined />} href="https://tinyurl.com/y4gvs4v6">DOWNLOAD SEB for WINDOWS 8.1 and 10</Button> */}
                {/* <------------- last old version ----------- > */}
                {/* <Button type="primary" icon={<WindowsOutlined />} href="https://tinyurl.com/y62emqge">Download SEB for Windows 8.1 and 10</Button> */}
                <Space>
                  {/* 3.3.0 */}
                  {/* <Button type="primary" icon={<WindowsOutlined />} href="https://tinyurl.com/4fzpk8p5">Download SEB for Windows 8.1, 10 and 11</Button> */}
                  {/* 3.3.1 */}
                  <Button
                    type="primary"
                    icon={<WindowsOutlined />}
                    href={getOfflineAppDownloadUrl("win10", appRegion, enabledFeatures)}
                  >
                    {/* {" "} */}
                    {/* Download SEB Setup Bundle v */}
                    <MrTranslate id={"OfflineApps.seb_download"} />&nbsp;
                    {enabledFeatures.release_seb_app_v_3_8_0_9999 ? "3.8.0.9999" : sebAppLatestVersion}
                  </Button>
                  <Tag color="error">
                    <MrTranslate id={"OfflineApps.new_update"} />
                  </Tag>
                </Space>
                <br></br><br></br>
                <h4><b>{mrIntl("OfflineApps.supported_versions_windows_10_and_11")}</b></h4>
                {/* <h3>Instructions to install the App on Windows 8.1, 10 and 11:</h3> */}

                <h3>
                  <MrTranslate
                    id={
                      "OfflineApps.instructions_to_install_the_app_on_windows_10_and_11"
                    }
                  />
                </h3>
                <ol>
                  {/* <li>Download the Setup file using the button above.</li> */}
                  <MrTranslate
                    id={
                      "OfflineApps.download_the_setup_file_using_the_button_above"
                    }
                  />
                  <li>
                    {/* Run the Setup file and follow the instructions. In case any */}
                    {/* dependencies are missing, the Setup wizard will guide you */}
                    {/* through the installation of the dependencies also. (Make sure */}
                    {/* you have atleast 2GB free space) */}
                    <MrTranslate id={"OfflineApps.app_setup_guide_msg"} />
                  </li>
                  {/* <li>
                      Once the installation is complete, you will see 2 new items on your Desktop - a folder called AssessPrep Tests and the application called AssessPrep.
                    </li> */}
                </ol>
                <div className="troubleshoot-separator">
                  <br />
                  {/* <h3>Troubleshoot installation problems:</h3> */}
                  <h3>
                    <MrTranslate id={"OfflineApps.installation_troubleshooting"} />
                  </h3>
                  <ol>
                    <li>
                      {/* In case you are facing issues (Setup failed error) while
                      installing SEB using the setup bundle given above, please
                      install the prerequisites listed below in order followed by
                      the MSI package. */}
                      <MrTranslate id={"OfflineApps.installation_issues"} />
                    </li>
                  </ol>
                  {/* <h3>Prerequisites:</h3> */}
                  <h3>
                    <MrTranslate id={"OfflineApps.prerequisites"} />
                  </h3>
                  <ol>
                    <li>
                      <MrTranslate id={"OfflineApps.window_tab_net_framework"} />:{" "}
                      <a href="https://dotnet.microsoft.com/en-us/download/dotnet-framework/net481" target="_blank">
                      https://dotnet.microsoft.com/en-us/download/dotnet-framework/net481
                      </a>
                    </li>
                    <li>
                      <MrTranslate
                        id={"OfflineApps.window_tab_visual_cpp_redist"}
                      />
                      :{" "}
                      <a href="https://support.microsoft.com/en-us/help/2977003/the-latest-supported-visual-c-downloads">
                        https://support.microsoft.com/en-us/help/2977003/the-latest-supported-visual-c-downloads
                      </a>
                    </li>
                  </ol>
                  <h3>{mrIntl("OfflineApps.window_tab_msi_package_buttton")}:</h3>
                  <Button
                    type="default"
                    icon={<DownloadOutlined />}
                    href={
                      appRegion === "china"
                        // ? "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.5.0.544_SetupBundle.exe"
                        // : "https://tinyurl.com/2s4krphv"
                        // "https://tinyurl.com/bdzndwnx" // 3.5.0

                        ? "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.8.0.742_x64_Setup.msi"
                        : enabledFeatures.release_seb_app_v_3_8_0_9999 ? "https://tinyurl.com/5n78mcep" : "https://tinyurl.com/2s4emj8p" // 3.8.0
                        
                        // ? "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.4.1.505_x64_Setup.msi"
                        // : "https://tinyurl.com/473sj5xd"
                    }
                  >
                    {mrIntl("OfflineApps.window_tab_msi_package_buttton")}
                  </Button>
                  <br></br>
                  <br></br>
                  <p>
                    <MrTranslate
                      id={"OfflineApps.window_tab_msi_packages_note_msg"}
                    />
                  </p>
                </div>
              </TabPane>
              <TabPane 
                tab={mrIntl("OfflineApps.windows_8_1")} 
                key="win8_1"
              >
                {/* 3.1 */}
                {/* <Button type="primary" icon={<WindowsOutlined />} href="https://tinyurl.com/yxhn4jts">DOWNLOAD SEB for WINDOWS</Button> */}
                {/* 3.1.1 */}
                {/* <Button type="primary" icon={<WindowsOutlined />} href="https://tinyurl.com/y4gvs4v6">DOWNLOAD SEB for WINDOWS 8.1 and 10</Button> */}
                {/* <------------- last old version ----------- > */}
                {/* <Button type="primary" icon={<WindowsOutlined />} href="https://tinyurl.com/y62emqge">Download SEB for Windows 8.1 and 10</Button> */}
                <Space>
                  {/* 3.3.0 */}
                  {/* <Button type="primary" icon={<WindowsOutlined />} href="https://tinyurl.com/4fzpk8p5">Download SEB for Windows 8.1, 10 and 11</Button> */}
                  {/* 3.3.1 */}
                  <Button
                    type="primary"
                    icon={<WindowsOutlined />}
                    href={getOfflineAppDownloadUrl("win8_1", appRegion)}
                  >
                    {/* {" "} */}
                    {/* Download SEB Setup Bundle v */}
                    <MrTranslate id={"OfflineApps.seb_download"} />&nbsp;3.5.0
                  </Button>
                </Space>
                <br></br><br></br>
                <h4><b>{mrIntl("OfflineApps.supported_versions_windows_8_1")}</b></h4>
                {/* <h3>Instructions to install the App on Windows 8.1, 10 and 11:</h3> */}

                <h3>
                  <MrTranslate
                    id={
                      "OfflineApps.instructions_to_install_the_app_on_windows_8_1"
                    }
                  />
                </h3>
                <ol>
                  {/* <li>Download the Setup file using the button above.</li> */}
                  <MrTranslate
                    id={
                      "OfflineApps.download_the_setup_file_using_the_button_above"
                    }
                  />
                  <li>
                    {/* Run the Setup file and follow the instructions. In case any */}
                    {/* dependencies are missing, the Setup wizard will guide you */}
                    {/* through the installation of the dependencies also. (Make sure */}
                    {/* you have atleast 2GB free space) */}
                    <MrTranslate id={"OfflineApps.app_setup_guide_msg"} />
                  </li>
                  {/* <li>
                      Once the installation is complete, you will see 2 new items on your Desktop - a folder called AssessPrep Tests and the application called AssessPrep.
                    </li> */}
                </ol>
                <div className="troubleshoot-separator">
                  <br />
                  {/* <h3>Troubleshoot installation problems:</h3> */}
                  <h3>
                    <MrTranslate id={"OfflineApps.installation_troubleshooting"} />
                  </h3>
                  <ol>
                    <li>
                      {/* In case you are facing issues (Setup failed error) while
                      installing SEB using the setup bundle given above, please
                      install the prerequisites listed below in order followed by
                      the MSI package. */}
                      <MrTranslate id={"OfflineApps.installation_issues"} />
                    </li>
                  </ol>
                  {/* <h3>Prerequisites:</h3> */}
                  <h3>
                    <MrTranslate id={"OfflineApps.prerequisites"} />
                  </h3>
                  <ol>
                    <li>
                      <MrTranslate id={"OfflineApps.window_tab_net_framework_472"} />:{" "}
                      <a href="https://dotnet.microsoft.com/download/dotnet-framework/net472">
                        https://dotnet.microsoft.com/download/dotnet-framework/net472
                      </a>
                    </li>
                    <li>
                      <MrTranslate
                        id={"OfflineApps.window_tab_microsoft_edge_webview"}
                      />
                      :{" "}
                      <a href="https://go.microsoft.com/fwlink/p/?LinkId=2124703">
                        https://go.microsoft.com/fwlink/p/?LinkId=2124703
                      </a>
                    </li>
                    <li>
                      <MrTranslate
                        id={"OfflineApps.window_tab_visual_cpp_redist"}
                      />
                      :{" "}
                      <a href="https://support.microsoft.com/en-us/help/2977003/the-latest-supported-visual-c-downloads">
                        https://support.microsoft.com/en-us/help/2977003/the-latest-supported-visual-c-downloads
                      </a>
                    </li>
                  </ol>
                  <h3>{mrIntl("OfflineApps.window_tab_msi_package_buttton")}:</h3>
                  <Button
                    type="default"
                    icon={<DownloadOutlined />}
                    href={
                      appRegion === "china"
                        // ? "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.5.0.544_SetupBundle.exe"
                        // : "https://tinyurl.com/2s4krphv"
                        // "https://tinyurl.com/bdzndwnx" // 3.5.0

                        ? "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.5.0.544_x64_Setup.msi"
                        : "https://tinyurl.com/2s4emj8p" // 3.8.0
                        
                        // ? "https://production-eks-assets.assessprep.cn/SEB_setup/SEB_3.4.1.505_x64_Setup.msi"
                        // : "https://tinyurl.com/473sj5xd"
                    }
                  >
                    {mrIntl("OfflineApps.window_tab_msi_package_buttton")}
                  </Button>
                  <br></br>
                  <br></br>
                  <p>
                    <MrTranslate
                      id={"OfflineApps.window_tab_msi_packages_note_msg"}
                    />
                  </p>
                </div>
              </TabPane>
            </Tabs>
          </TabPane>
          {/*SEB Support in WIN7 is deprecated */}
          <TabPane
            tab={mrIntl("OfflineApps.ipad")}
            key="ios"
          >
            <Space>
              <Button
                type="primary"
                icon={<AppleOutlined />}
                href={getOfflineAppDownloadUrl("ios", appRegion)}
              >
                {/* {" "} */}
                {/* Download AssessPrep for IPad */}
                <MrTranslate id={"OfflineApps.download_assessprep_for_ipad"} />
              </Button>
            </Space>
            <br></br><br></br>
            <h4><b>{mrIntl("OfflineApps.supported_versions_ipados_13_and_above")}</b></h4>
            {/* <h3>Instructions to install the App on IPad:</h3> */}
            <h3>
              <MrTranslate
                id={"OfflineApps.instructions_to_install_the_app_on_ipad"}
              />
            </h3>
            <ol>
              <li>
                {/* You can take a test by using our AssessPrep app for IPad.
                Download the app using the button above or search for
                'AssessPrep' on the IPad App Store. */}
                <MrTranslate
                  id={"OfflineApps.search_assessprep_app_on_ipad_playstore_msg"}
                />
              </li>
            </ol>
          </TabPane>
          {currentUser &&
            currentUser.role === "admin" &&
            appRegion === "global" && (
              <TabPane tab={mrIntl("OfflineApps.chromebooks")} key="cros">
                {/* <Space>
                            <Button type="primary" icon={<AppleOutlined />} href={downloadUrls.ipad}>Download AssessPrep for IPad</Button>
                        </Space>
                        <br></br> */}
                {/* <br></br> */}
                {/* <h3>
                          Instructions to run AssessPrep on Chromebooks in Kiosk(Lockdown) mode:
                        </h3> */}
                <p>
                  {/* AssessPrep can be run in Chromebooks as a Kiosk app to enable
                  secure testing. To do so, the Chromebooks must be purchased
                  after 2016 and must be Managed Devices.{" "} */}
                  <MrTranslate
                    id={"OfflineApps.assessprep_can_be_run_in_chromebooks"}
                  />
                  &nbsp;
                  <Link
                    href="https://support.google.com/chrome/a/answer/1289314?ref_topic=4386913"
                    target="_blank"
                  >
                    {/* See more information */}
                    <MrTranslate id={"OfflineApps.see_more_information"} />
                  </Link>
                </p>
                <p>
                  <b>
                    {/* The following steps are for the school IT admin to perform. */}
                    <MrTranslate id={"OfflineApps.admin_to_perform_msg"} />
                  </b>{" "}
                  {/* These steps will install the AssessPrep Kiosk mode app on all
                  Chromebooks in your school or district. Students will then see
                  a menu of kiosk apps in the system tray (left side) on the
                  login screen where they can select the AssessPrep app to
                  begin. */}
                  <MrTranslate
                    id={
                      "OfflinApps.install_kiosk_mode_from_chromebook_steps_msg"
                    }
                  />
                </p>
                <ol>
                  {/* <li>Login to Google Admin</li> */}
                  <li>
                    <MrTranslate id={"OfflineApps.login_to_google_admin"} />
                  </li>
                  <li>
                    {/* From the left side navigation, select Devices > Chrome >
                    Apps & extensions > Kiosks */}
                    <MrTranslate id={"OfflineApps.steps_for_kiosks_msg"} />

                    <br></br>
                    <br></br>
                    <img
                      style={{ height: "500px" }}
                      alt={"cros-step-1-screenshot"}
                      src="https://v2res1.assessprep.com/direct_uploads/attachments/01GAC0524S4TC5WAA0RR8NZ3TY/Screen%20Shot%202022-08-13%20at%2011.26.33%20AM.png"
                    ></img>
                    <br></br>
                    <br></br>
                  </li>
                  <li>
                    {/* Select the organizational unit that has the Chromebooks you
                    will use or leave as default */}
                    <MrTranslate
                      id={"OfflineApps.select_the_organizational_unit"}
                    />
                  </li>
                  <li>
                    {/* Click on the yellow '+' (Add) button on the bottom right of
                    the page and select 'Add by url' */}
                    <MrTranslate id={"OfflineApps.add_by_url"} />
                    <br></br>
                    <img
                      style={{ height: "300px" }}
                      alt={"cros-step-1-screenshot"}
                      src="https://v2res1.assessprep.com/direct_uploads/attachments/01GAC0524WEF7MQKS6CYMARPA1/Screen%20Shot%202022-08-13%20at%2011.26.47%20AM.png"
                    ></img>
                    <br></br>
                    <br></br>
                  </li>
                  <li>
                    {/* Copy the URL below, paste it in the popup and click on Save */}
                    <MrTranslate id={"OfflineApps.url_save_msg"} />
                    <br></br>
                    <br></br>
                    <Space>
                      <Text code>{chromebookKioskAppUrl}</Text>
                      <Tooltip title={mrIntl("OfflineApps.copy_url")}>
                        <Button
                          onClick={() => {
                            writeToClipboardPolyfill(chromebookKioskAppUrl);
                          }}
                          type={"default"}
                          // size={"small"}
                          icon={<CopyOutlined />}
                        ></Button>
                      </Tooltip>
                    </Space>
                    <br></br>
                    <br></br>
                    <img
                      style={{ height: "300px" }}
                      alt={"cros-step-1-screenshot"}
                      src="https://v2res1.assessprep.com/direct_uploads/attachments/01GAC052BKQ4D988XVPDYBHXBJ/Screen%20Shot%202022-08-13%20at%2011.27.27%20AM.png"
                    ></img>
                    <br></br>
                    <br></br>
                  </li>
                  <li>
                    {/* Click on Agree in the next popup */}
                    <MrTranslate
                      id={"OfflineApps.click_on_agree_in_the_next_popup"}
                    />
                    <br></br>
                    <br></br>
                    <img
                      style={{ height: "500px" }}
                      alt={"cros-step-1-screenshot"}
                      src="https://v2res1.assessprep.com/direct_uploads/attachments/01GAC052BKFV0HV85K8DX593W0/Screen%20Shot%202022-08-13%20at%2011.27.39%20AM.png"
                    ></img>
                    <br></br>
                    <br></br>
                  </li>
                  <li>
                    <MrTranslate id={"OfflineApps.assessprep_installed_msg"} />
                    {/* The AssessPrep app will be Installed on all devices. Within
                    30 seconds, the app should appear on all Chromebooks and
                    students will see a menu of kiosk apps in the system tray
                    (left side) on the login screen where they can select the
                    AssessPrep app to begin. (If the app doesn't show
                    immediately, ask the students to restart their Chromebooks
                    once.) */}
                    <br></br>
                    <br></br>
                    <img
                      style={{ height: "200px" }}
                      alt={"cros-step-1-screenshot"}
                      src="https://v2res1.assessprep.com/direct_uploads/attachments/01GAC0524VR8B8BPDETFEKN22R/Screen%20Shot%202022-08-13%20at%2011.28.10%20AM.png"
                    ></img>
                    <br></br>
                    <br></br>
                    {/* Student view:
                              <br></br>
                              <br></br> */}
                    {/* <img style={{height: '500px'}} alt={"cros-step-1-screenshot"} src="https://v2res1.assessprep.com/direct_uploads/attachments/01GAC0524S4TC5WAA0RR8NZ3TY/Screen%20Shot%202022-08-13%20at%2011.26.33%20AM.png"></img>  */}
                  </li>
                </ol>
              </TabPane>
            )}
          {currentUser.role === "student" && isLegacyMacVersion902Allowed && (
            <TabPane
              tab={mrIntl("OfflineApps.offline_legacy_app")}
              key="legacy-app"
            >
              <Space>
                <Button
                  type="primary"
                  icon={<AppleOutlined />}
                  href={getOfflineAppDownloadUrl(
                    "mac",
                    appRegion,
                    {legacy_ap_mac_9_0_2_allowed: isLegacyMacVersion902Allowed},
                    false
                  )}
                >
                  {/* <MrTranslate id={"OfflineApps.download_assessprep_mac_v"} /> */}
                  {mrIntl("OfflineApps.download_assessprep_mac_v")}
                  {"9.0.2"}
                </Button>
              </Space>
              <br></br>
              <br></br>
              <h4><b>{mrIntl("OfflineApps.supported_versions_macos_11_and_above")}</b></h4>
              <h3>
                <MrTranslate
                  id={"OfflineApps.instructions_to_install_the_app_on_mac"}
                />
              </h3>
              <ol>
                <li>
                  <MrTranslate
                    id={
                      "OfflineApps.you_can_take_a_test_by_using_our_assessprep_app_msg"
                    }
                  />
                </li>
                <li>
                  <MrTranslate
                    id={
                      "OfflineApps.downloaded_dmg_file_and_follow_the_instruction_msg"
                    }
                  />
                </li>
              </ol>
            </TabPane>
          )}
        </Tabs>
      </Card>
    </>
  );
};

export default OfflineApps;
