import React, { useEffect, useMemo, useState } from "react";
import { Col, Checkbox, Image, Row, Modal, Button, Typography } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const { Text } = Typography;

const ImagePreview = ({ img, handleChange }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewBtnHovered, setPreviewBtnHovered] = useState(false);
  return (
    <Col span={6}>
      <div
        className={`converted-image-wrapper ${img.checked ? "selected-image" : ""} ${previewBtnHovered ? "preview-button-hovered" : ""}`}
        onClick={(e) => handleChange(img.id, !img.checked, e)}
      >
        <Image
          key={`image-preview-${img.id}`}
          src={img.url}
          preview={{
            visible: previewVisible,
            onVisibleChange: () => setPreviewVisible(false),
          }}
        />
        <Checkbox
          className="select-image-checkbox"
          onChange={(e) => {
            handleChange(img.id, e.target.checked, e);
          }}
          checked={img.checked}
        ></Checkbox>
        <Button
          className={`preview-btn`}
          onMouseEnter={() => setPreviewBtnHovered(true)}
          onMouseLeave={() => setPreviewBtnHovered(false)}
          onClick={() => setPreviewVisible(true)}
        >
          <EyeOutlined /> Preview
        </Button>
      </div>
    </Col>
  );
};

const ImagePreviewModal = ({
  isModalOpen,
  setIsModalOpen,
  convertedImages,
  setConvertedImages,
  aiGenerateLoading,
  showError,
  setShowError
}) => {



  const mrIntl = useTranslate();
  const [isCheckedAll, setIsCheckedAll] = useState(true);

  const filteredList = useMemo(
    () => convertedImages.filter((item) => item.checked !== false),
    [convertedImages]
  );
  
  let isFilteredListEmpty = filteredList.length === 0

  useEffect(() => {
    setShowError(isFilteredListEmpty);
  }, [isFilteredListEmpty]);

  const selectedImageCount = filteredList.length > 0 ? filteredList.length : isCheckedAll ? convertedImages.length : 0;
  const convertedImageModalTitle = mrIntl("ImagePreviewModal.select_from_the_converted_images_selected_images", { selectedImageCount: selectedImageCount });

  const selectedImageButtonText = filteredList.length > 0
    ? mrIntl("ImagePreviewModal.selected_images_from_pdf", {selectedImages: filteredList.length })
    : mrIntl("ImagePreviewModal.select_images_from_pdf");

  const onCheckAllChange = (e) => {
    const newCheckedState = e.target.checked;
    const newImages = convertedImages.map((item) => ({
      ...item,
      checked: newCheckedState,
    }));
    setConvertedImages(newImages);
    setIsCheckedAll(newCheckedState);
  };

  const handleChange = (id, value, e) => {
    e.stopPropagation();
    const updatedImages = [...convertedImages];
    const index = updatedImages.findIndex((item) => item.id === id);
    if (index !== -1) {
      updatedImages[index] = { ...updatedImages[index], checked: value };
    }
    setConvertedImages(updatedImages);

    const allChecked = updatedImages.every((item) => item.checked);
    setIsCheckedAll(allChecked);
  };

  return (
    <>
      <Button className="selected-images select-parameter-field" disabled={aiGenerateLoading} onClick={() => setIsModalOpen(true)}>{selectedImageButtonText}</Button>
      <br />
      {isFilteredListEmpty && <Text type="danger">{mrIntl("ImagePreviewModal.please_select_atleast_one_image")}</Text>}
      <Modal
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        title={convertedImageModalTitle}
        centered
        width={"65%"}
        okText={"Submit"}
        okButtonProps={{ disabled: convertedImages.length === 0 }}
        wrapClassName="image-preview-modal"
      >
        {convertedImages.length > 0 ? (
          <div>
            <Row>
              <Col className="select-all-checkbox" style={{ margin: "10px 0" }}>
                <Checkbox onChange={onCheckAllChange} checked={isCheckedAll}>
                  {mrIntl("ImagePreviewModal.select_all")}
                </Checkbox>
              </Col>
            </Row>
            <Row gutter={[8, 8]} style={{ paddingRight: "8px" }}>
              {convertedImages.map((img) => (
                <ImagePreview key={img.id} img={img} handleChange={handleChange} />
              ))}
            </Row>
          </div>
        ) : (
          <Spinner />
        )}
      </Modal>
    </>
  );
};

export default ImagePreviewModal;
