import dayjs from "dayjs";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";
import React, { useCallback } from "react";
import { Route, useRouteMatch, useLocation } from "react-router-dom";
// import {Tabs} from "antd";
// import {useSelector} from "react-redux";
// import { currentUserSelector } from '/src/views/Auth/Login/selector';
import TodayExperiences from "/src/views/Experiences/TodayExperiences/TodayExperiences";
import { getUTCDate } from "/src/lib/utils/helperMethods";

const Overview = (props) => {
  console.log("Overview props", props);
  // const {item, history} = props;
  const match = useRouteMatch();
  const { path, url } = match;
  const location = useLocation();
  // const currentUser = useSelector(currentUserSelector());
  console.log("Overview props", match, location, path, url);

  // const { TabPane } = Tabs;
  // const [activeKey, setActiveKey] = useState(null)
  // const handleTabClick = (key, event) => {
  //   history.push(`${url}/${key}`)
  //   setActiveKey(key)
  // }

  // const canViewResourcesTab = currentUser.role == "superadmin" || currentUser.role == "qb_author" ? true : false
  // const canCreateAssessments = currentUser.role == "superadmin" ? true : false

  // let libExperiencesCrudConfig = {filter: {show: true, config: {create: {show: false}}}}
  // if(canCreateAssessments){
  //   // params = {by_experience_id: experience.id, by_user_id: currentUser.id}
  //   libExperiencesCrudConfig = {filter: {show: true, config: {create: {show: true}} }}
  // }

  const defaultByStartAtDateRange = useCallback([getUTCDate({type: "startOf"}).toISOString(), getUTCDate({type: "endOf"}).toISOString()], [])
  return (
    <React.Fragment>
      {/* <Tabs activeKey={activeKey} onTabClick={handleTabClick}>
      <TabPane tab="All assessments" key="tests">
        <Route path={`${path}/tests`} render={routeProps => <LibraryExperiences isRoute={false} {...routeProps} isLibrary={true} params={{is_library: true, by_original: true}} config={libExperiencesCrudConfig}/>}/>
      </TabPane>

      {canViewResourcesTab && <TabPane tab="All resources" key="resources">
        <Row>
          <Col span={18} className="segments-list">
            
            <Route path={`${path}/resources`} render={routeProps => <LibrarySegments experienceViewMode="apEditTest" isLibrary={true} params={{is_library: true, by_original: true}} isRoute={false} {...routeProps} config={{filter: {show: true, config: {create: {show: false}} }, pagination: {show: true}}}/>}/>
          </Col>
        </Row>
      </TabPane>}
      
    </Tabs> */}

      {/* <h3><MrTranslate id={"Overview.todays_assessments"}/></h3> */}
      <Route
        path={`${path}`}
        render={(routeProps) => (
          <TodayExperiences
            isRoute={false}
            {...routeProps}
            params={{
              // by_starting: "today",
              // by_start_at_date_range: [dayjs().startOf('day').utc(), dayjs().endOf('day').utc()],
              by_start_at_date_range: defaultByStartAtDateRange,
              by_status: ["published", "closed"],
              is_library: true,
            }}
            config={{
              filter: { show: true, config: { create: { show: false }, search: { show: false } } },
            }}
          />
        )}
      />
      {/* is_library is set to show admin all tests not just based on memberable */}
    </React.Fragment>
  );
};

Overview.defaultProps = {};

Overview.propTypes = {};

export default Overview;
