import React from 'react';
import { Alert, Modal, Tag } from 'antd';
import './QuitPassword.scss';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { useSelector } from 'react-redux';
import { activeAdjustedExperienceSelector } from '/src/views/Experiences/selector';
import { enabledFeaturesSelector } from '/src/views/Auth/Login/selector';
const QuitPassword = (props) => {
  console.log("QuitPassword props ==>", props);
  const { setActiveModal } = props

  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  console.log("quitPassword experiece ===>", activeExperience);
  const experienceSettings = activeExperience.settings || {};
  const lockdownConfig = experienceSettings.lockdown_config || {};

  let quitPassword = "";

  if (lockdownConfig.quit_password) {
    quitPassword = lockdownConfig.quit_password;
  } else if (enabledFeatures.win_global_prod_seb_config_file_with_fixed_quit_password_mashrek) {
    quitPassword = 120690; // Quit password for Mashrek and DNS
  }

  const mrIntl = useTranslate();
  return (
    <>
      <Modal
        className='seb-password-modal'
        open={true}
        onCancel={() => setActiveModal(null) }
        footer={null}
      >
        <Alert
          className='seb-password-alert'
          message={mrIntl("QuitPassword.seb_quit_password")}
          type="info"
          showIcon
        />
        <div className='seb-password-div'>
          <Tag className='seb-password-tag'>{quitPassword}</Tag>
        </div>
        <br />
        <p>{mrIntl("QuitPassword.this_password_is_required_if_a_student_attempts_to_switch")}</p>
        <Alert
          className='seb-password-warning'
          message={<span><b>{mrIntl("QuitPassword.important")}</b>: {mrIntl("QuitPassword.do_not_reveal_this_password_to_students")}</span>}
          type="warning"
          showIcon
        />
      </Modal>
    </>
  );
};

export default QuitPassword;


