import React from "react";
import {MrSelect} from "mr_react_framework";
import {useSelector} from "react-redux";
import { buildOptionsArr, userRoleMap } from "/src/lib/utils/helperMethods";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";


const SelectRole = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      const { allowedRoles } = props
      console.log("data select groups", data);
      console.log("props select groups", props);
      let arr = []
      for (const key in data) {
        if (data.hasOwnProperty(key) && (!allowedRoles || (allowedRoles && allowedRoles.includes(key)))) {
          arr.push({label: userRoleMap[key], value: key})
        }
      }
      return arr
    },
    searchApi: false,
    widgetConfig: {
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select role",
      placeholder: <MrTranslate id={"SelectRoleDropdown.select_role"}/>,
      // mode: "multiple",
      // style: {width: "500px"},
      allowClear: true
    },

  }
})

const SelectRoleDropdown = (props) => {
  console.log( "SelectRoleDropdown props", props );
  
  const orgRoles = useSelector(state => {
    console.log("state user filter",state);
    return state.login.get("org").roles
  } )
  
  console.log( "SelectRoleDropdown orgRoles", orgRoles );
  
  // const currentUser = useSelector(currentUserSelector)
  // if(currentUser.role != "superadmin"){
    
  //   console.log( "SelectRoleDropdown orgRoles basic ones only", orgRoles );
  // }

  // return <SelectRoleWidget />
  return <SelectRole {...props} config={{widgetConfig: {options: orgRoles}}}/>
}


SelectRoleDropdown.defaultProps = {};

SelectRoleDropdown.propTypes = {};

export default SelectRoleDropdown;


                