import React, { useEffect, useState } from "react";

import { Row, Col } from "antd";
import MediaUI from "/src/components/UI/Media/MediaUI";
import MediaShow from "/src/components/UI/Media/MediaShow";
import { cloneDeep } from "lodash";
import { uploadVideoToBackend } from "/src/components/UI/Media/MediaHelper";
import { removeItemFromArray } from "../InteractiveHelpers";
import { Segment, SegmentData } from "/src/components/UI/Segment/UIHelper";
import ThumbnailAttachments from "/src/components/UI/Attachments/ThumbnailAttachments/ThumbnailAttachments";
import PreviewAttachment from "/src/components/UI/Attachments/PreviewAttachment";
import { useSelector } from "react-redux";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const VideoAnswerExplanation = (props) => {
  console.log("VideoAnswerExplanation props ==>", props);
  const { createMode, presentationMode } = props;
  const mrIntl = useTranslate()
  let parsedJSONObject;
  if (createMode) {
    parsedJSONObject = props.value || {
      videos: [],
    };
  } else {
    parsedJSONObject = props.video_answer_explanation;
  }

  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());

  const [state, setState] = useState(parsedJSONObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);

  const allowLargeFiles = enabledFeatures.allow_large_files

  const mediaFormInitialState = {
    modal: false,
    loading: false,
    visible: false,
    title: mrIntl("CommonText.insert_video_title"),
    okText: mrIntl("CommonText.add_video_button"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    requireFooter: false,
    content_type: "video",
    uploadOnOk: true,
    disableUploadURLToS3: true,
    limit: 1,
    supportedTypes: "video/mp4, video/avi, video/flv, video/webm, video/wmv",
    supportedFormats: "mp4, avi, flv, webm, wmv",
    // supportedURLs: "Only urls from youtube, vimeo and youku can be used here",
    supportedURLs: mrIntl("CommonText.support_url_from_youtube_only_message"),
    selectConfig: {
      multiple: false,
      // disabledButtonTooltip:
      //   "This feature is temporarily disabled. We working to enable this as soon as possible. In the mean time, you can upload any video directly to youtube and use the url here.",
      maxSize: allowLargeFiles ? "500 MB" : "100 MB",
      uploadFile: uploadVideoToBackend,
    },
    beforeLoadURL: (data) => {
      let updatedURL =
        data.match(/(https:\/\/\S*youtu\S*)\w+/g) ||
        // data.match(/(https:\/\/\S*vimeo\S*)\w+/g) ||
        data.match(/(https:\/\/\S*youku\S*)\w+/g);
      return updatedURL ? updatedURL[0] : updatedURL;
    },
  };
  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );

  mediaFormSetting.onPreview = async (file, value) => {
    console.log("file and value", file, value);
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    setMediaFormSetting({
      ...mediaFormSetting,
      previewCurrentFile: {
        ...file,
        url: src,
      },
    });
  };

  let currentJSON;
  if (presentationMode) {
    currentJSON = parsedJSONObject;
  } else {
    currentJSON = state;
  }

  const videos = currentJSON.videos || [];

  console.log("videos ==>", videos);
  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let video_answer_explanation = cloneDeep(state);
        props.onChange(video_answer_explanation);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    setState(state);
    console.log("stateUpdated count ==>", stateUpdatedCount);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const onSaveMedia = (files) => {
    console.log("files ==>", files);
    let newState = {
      ...state,
      videos: files,
    };

    saveState(newState);
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "",
      modal: false,
      visible: false,
      triggerSaveAction: 0,
    });
  };

  const removeFile = (index) => {
    const extra = {
      noMinSize: true,
      withConfirmation: true,
    };

    const newVideos = removeItemFromArray(videos, index, extra);
    let newState = {
      ...state,
      answer_explanation_video_attachments: newVideos,
    };

    saveState(newState);
  };

  return (
    <Row>
      <Col span={24}>
        {createMode && (
          <MediaUI
            computer={true}
            url={true}
            mediaFormSetting={mediaFormSetting}
            setMediaFormSetting={setMediaFormSetting}
            onSaveMedia={(files) => onSaveMedia(files, "video")}
          ></MediaUI>
        )}

        {videos.length > 0 &&
          (createMode ? (
            <ThumbnailAttachments
              attachments={videos}
              draggableProps={{
                isDragDisabled: true,
              }}
              listProps={{
                listType: "picture-card",
                showRemoveIcon: createMode ? true : false,
                onRemove: removeFile,
                onPreview: mediaFormSetting.onPreview,
              }}
            />
          ) : (
            <MediaShow 
              file={videos[0]} 
              mediaType="video"
            ></MediaShow>
          ))}

        <PreviewAttachment
          type={"modal"}
          visible={mediaFormSetting.previewCurrentFile}
          file={mediaFormSetting.previewCurrentFile}
          files={videos}
          onCancel={() =>
            setMediaFormSetting({
              ...mediaFormSetting,
              previewCurrentFile: null,
            })
          }
        />
      </Col>
    </Row>
  );
};

export default VideoAnswerExplanation;
