import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import { MrSelect, MrInput } from "mr_react_framework";
import {actions as genericActions} from "/src/App/genericRedux";
import {Route, useRouteMatch} from "react-router-dom";
import ResetFilter from "/src/components/ResetFilter/ResetFilter";
import { userRoleMap } from "/src/lib/utils/helperMethods";
import SelectOrgDropdown from "/src/components/AppSpecific/SelectOrg/SelectOrgDropdown";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { Checkbox, Space } from "antd";
import { SyncBtn } from "/src/views/Orgs/OrgsList";
import { SelectGrade, SelectProgram } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const SelectRole = MrSelect({
  // actions: genericActions,
  // resourceName: "grades",
  config: {
    // url: "/grades",
    // params: {by_org_programme_id: 3}, //get from previous filter
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      let arr = []
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          arr.push({label: userRoleMap[key], value: key})
        }
      }
      return arr
    },
    searchApi: false,
    widgetConfig: {
      // options: [
      //   {label: "Admin", value: "admin"},
      //   {label: "Teacher", value: "teacher"},
      //   {label: "Student", value: "student"},
      //   {label: "Parent", value: "parent"},
      // ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id = {"UsersFilter.select_role"}/>,
      // placeholder: "Select role",
      // mode: "multiple",
      style: {width: "100%"},
      allowClear: true
    },

  }
})

// const SelectProgram = MrSelect({
//   actions: genericActions,
//   resourceName: "orgprogrammes",
//   apiResourceName: "org_programmes",
//   config: {
//     url: "/org_programmes",
//     // params: {}, //default is org_id on BE
//     processData: (data, props) => {
//       console.log("data select groups", data);
//       console.log("props select groups", props);
//       return data.map((item) => {
//         console.log("class selector item", item);
//         return {label: item.name, value: item.id};
//       });
//     },
//     searchApi: false,
//     widgetConfig: {
//       showSearch: false, 
//       filterOption: true,
//       optionFilterProp: "label",
//       placeholder: "Select program",
//       // mode: "multiple",
//       // style: {width: "500px"},
//     },
//   }
// });

// const SelectGrade = MrSelect({
//   actions: genericActions,
//   resourceName: "grades",
//   config: {
//     url: "/grades",
//     // params: {by_org_programme_id: 3}, //get from previous filter
//     processData: (data, props) => {
//       console.log("data select groups", data);
//       console.log("props select groups", props);
//       return data.map((item) => {
//         console.log("class selector item", item);
//         return {label: item.name, value: item.id};
//       });
//     },
//     searchApi: false,
//     widgetConfig: {
//       showSearch: false,
//       filterOption: true,
//       optionFilterProp: "label",
//       placeholder: "Select grade",
//       // mode: "multiple",
//       // style: {width: "500px"},
//     },

//   }
// })

const SelectOrderBy = MrSelect({
  // actions: genericActions,
  // resourceName: "plans",
  config: {
    // url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      options: [
        {label: "Power authors (assessments delivered)", value: "power_authors_delivered"},
        {label: "Power authors (original items)", value: "power_authors_items"},
        {label: "Power students (most submissions)", value: "power_students_submissions"},
      ],
      showSearch: false,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "Power users",
      // style: { width: "100%" },
      allowClear: true
    },
  },
});

const UserFilter = (props) => {
  // return <span>User Filter</span>;
  const {path, url} = useRouteMatch();
  const mrIntl = useTranslate()

  const orgId = useSelector(state => {
    console.log("state user filter",state);
    return state.login.get("org").id
  })
  const orgRoles = useSelector(state => {
    console.log("state user filter",state);
    return state.login.get("org").roles
  } )
  console.log("props user filter",props);
  console.log("orgId user filter",orgId);

  console.log( "SelectRoleDropdown orgRoles", orgRoles );
  
  const currentUser = useSelector(currentUserSelector)
  if(currentUser.role != "superadmin"){
    delete(orgRoles["superadmin"])
    delete(orgRoles["support"])
    delete(orgRoles["qb_author"])
    console.log( "SelectRoleDropdown orgRoles basic ones only", orgRoles );
  }



  // Add in MRSelect to get from Redux -> data and process it
  // config: {
  //   widgetConfig: {
  //     options: (state) => state.login.get("currentUser") 
  //   }
  // }
  // OR
  // config: {
  //   widgetConfig: {
  //     options: [{"label": "Label", value: "value1"}]
  //   }
  // }

  let extraFilters = [];
  let by_org_id = null
  let include_archived
  let by_org_user_include_archived
  let only_archived
  let by_org_user_only_archived
  let with_multiple_org_users
  let order_by

  let filtersConfig = {
    showFiltersHeaderTitle: true, 
    showResetFilter: true
  }

  if(filtersConfig.showFiltersHeaderTitle){
    extraFilters.push(<Space style={{justifyContent: "space-between", width: "100%", marginBottom: "15px"}}>
      {/* <div>Filters</div>  */}
      {/* <Button icon={<FilterOutlined />}></Button> */}
      {filtersConfig.showResetFilter && <div className="text-right"><ResetFilter label={mrIntl("CommonText.reset")} setParams={props.setParams} useDefaultValues={false} /></div>}
    </Space>)
  }

  if(props.currentUser.role == "superadmin" || props.currentUser.role == "support"){
    if(props.by_org_id){
      by_org_id = parseInt(props.by_org_id)
    }
    extraFilters.push(
      <SelectOrgDropdown value={by_org_id} onChange={(value) => props.setParams({by_org_id: value})} 
      config={{
        widgetConfig: { 
          style: {width: "100%"}
        }
      }}
      />
    );
  }
  extraFilters.push(
    <SelectRole value={props.by_role} onChange={(value) => props.setParams({by_role: value, by_org_programme_id: null})} config={{widgetConfig: {options: orgRoles}}}/>
  );

  let org_id = orgId
  if(props.currentUser.role === "superadmin" || props.currentUser.role === "support"){
    org_id = props.by_org_id
  }
  if(props.by_role === "student" && org_id){
    let by_org_programme_id = null
    if(props.by_org_programme_id){
      by_org_programme_id = parseInt(props.by_org_programme_id)
    }
    extraFilters.push(
      <SelectProgram value={by_org_programme_id} onChange={(value) => props.setParams({by_org_programme_id: value})} config={{
        params: {by_org_id: org_id}, 
        widgetConfig: { 
          style: {width: "100%"}
        }
      }}/>
    )
    if(by_org_programme_id){
      // let by_grade_id = null
      // if(props.by_grade_id){
      //   by_grade_id = parseInt(props.by_grade_id)
      // }
      let by_grade_ids = []
      if(props.by_grade_ids){
        by_grade_ids = props.by_grade_ids.map((v) => parseInt(v))
      }
      extraFilters.push(
        // <SelectGrade value={by_grade_id} onChange={(value) => props.setParams({by_grade_id: value})} 
        <SelectGrade value={by_grade_ids} onChange={(value) => props.setParams({by_grade_ids: value})} 
          config={{
            params: {by_org_programme_id: by_org_programme_id}, 
            widgetConfig: { 
              style: {width: "100%"},
              mode: "multiple",
            }
          }}
        />
      )
    }
  }
  

  if(props.currentUser.role === "superadmin"){
    if(props.order_by){
      order_by = props.order_by
    }
    extraFilters.push(
      <SelectOrderBy key="users-select-order-by" value={order_by} onChange={(value) => props.setParams({order_by: value, by_role: null})} config={{
        widgetConfig:{ style: {width:"100%"}},
      }}/>
    );
  }


    

  if(props.include_archived){
    include_archived = props.include_archived
  }
  if(props.only_archived){
    only_archived = props.only_archived
  }

  if(props.by_org_user_include_archived){
    by_org_user_include_archived = props.by_org_user_include_archived
  }
  if(props.by_org_user_only_archived){
    by_org_user_only_archived = props.by_org_user_only_archived
  }
  let useOrgUserArchivedFilter = false
  // if((import.meta.env.VITE_MODE === "development") || (org_id === 528 && import.meta.env.VITE_MODE === "production")){
  if(org_id){
    useOrgUserArchivedFilter = true
  }
  if(useOrgUserArchivedFilter){
    // extraFilters.push(
    //   <Checkbox key="checkboxArchivedExperiencesFilter" checked={by_org_user_include_archived} onChange={(e) => {
    //     console.log("archived filter value", e); 
    //     props.setParams({by_org_user_include_archived: (e.target.checked ? true : null)})}}><MrTranslate id={"CommonText.show_archived"}/></Checkbox>
    // )
    extraFilters.push(
      <Checkbox key="checkboxArchivedExperiencesFilter" checked={by_org_user_only_archived} onChange={(e) => {
        console.log("archived filter value", e); 
        props.setParams({by_org_user_only_archived: (e.target.checked ? true : null)})}}><MrTranslate id={"CommonText.show_archived"}/></Checkbox>
    )
  }else{
    // extraFilters.push(
    //   <Checkbox key="checkboxArchivedExperiencesFilter" checked={include_archived} onChange={(e) => {
    //     console.log("archived filter value", e); 
    //     props.setParams({include_archived: (e.target.checked ? true : null)})}}><MrTranslate id={"CommonText.show_archived"}/></Checkbox>
    // )
    extraFilters.push(
      <Checkbox key="checkboxArchivedExperiencesFilter" checked={only_archived} onChange={(e) => {
        console.log("archived filter value", e); 
        props.setParams({only_archived: (e.target.checked ? true : null)})}}><MrTranslate id={"CommonText.show_archived"}/></Checkbox>
    )
  }

  if(props.currentUser.role === "superadmin" || props.currentUser.role === "support"){
    if(props.with_multiple_org_users){
      with_multiple_org_users = props.with_multiple_org_users
    }
    extraFilters.push(
      <Checkbox key="checkboxArchivedExperiencesFilter" checked={with_multiple_org_users} onChange={(e) => {
        console.log("archived filter value", e); 
        props.setParams({with_multiple_org_users: e.target.checked})}}>
          Only multiple org users
          </Checkbox>
    )
  }
 

  // extraFilters.push(<ResetFilter key={"resetFiltersBtn"} setParams={props.setParams} />)
  
  // const org = props.org;
  // if(org.is_mb && org.custom_fields && org.custom_fields.sync_info && org.custom_fields.sync_info.latest_started_at){
  //   extraFilters.push(
  //     <SyncBtn key={"mainMBSyncBtn"} size={"compact"} org={org}/>
  //   )
  // }
  return extraFilters;
};

UserFilter.defaultProps = {};

UserFilter.propTypes = {};

export default UserFilter;
