import React from "react";

function PrintPreviewIframe({ uid }) {
  return (
    <div className="paper-test-preview-iframe">
      <iframe
        title="paper-mode-assessment-preview"
        src={`${import.meta.env.VITE_WEB_URL}/print/experiences/${uid}?print_type=experience&with_questions=true&with_space_for_answers=true`}
      ></iframe>
    </div>
  );
}

export default PrintPreviewIframe;
