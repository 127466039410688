import React, { useState, useRef, memo, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Button, Row, Col, Space, Tag } from "antd";
import { PlusCircleTwoTone, EditOutlined } from "@ant-design/icons";
import ShadowItem from "./ShadowItem";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { showConfirmModal } from "../Segment/UIHelper";
import DragElement from "./DragElement";
import { sortBy } from "lodash";
import { CKETagDescriptionTools } from "../CKEditor/CKEConfig";
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent";
import RenderHtml from "../RenderHtml/RenderHtml";
import { SelectTeachingLevel } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import DetectOutsideClick from "/src/lib/DetectOutsideClick/DetectOutsideClick";
import { renderMathInElement } from "/src/lib/utils/helperMethods";

const ItemTypes = {
  tagType: "tag",
};

const DraggableListItem = (props) => {
  console.log("sdfh DraggableListItem props ===>", props);
  const {
    item,
    items,
    handleDragItemAndDrop,
    addItem,
    addChildrenItem,
    deleteItem,
    itemIndex,
    itemSiblings,
    isCollapseAll,
    level,
    updateItemDetail,
    showDetailAddons,
    loading,
    isEditable
  } = props;
  const { id, parent_id, label, type_c, relations, has_children } = item;
  const mrIntl = useTranslate();
  const [dropPosition, setDropPosition] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [showChildren, setShowChildren] = useState(false);
  const [showDetails, setShowDetails] = useState(false)
  const [activeItemKey, setActiveItemKey] = useState("")

  const relatedList = items.filter((listItem) => listItem.parent_id == id);
  const sortedRelatedList = sortBy(relatedList, "position");
  const itemSiblingsCount = itemSiblings.length;
  const isLabelEditing = activeItemKey === `item-label-${id}` && isEditable;
  const isDescriptionEditing = activeItemKey === `item-description-${id}`;

  useEffect(() => {
    setShowChildren(!isCollapseAll);
  }, [isCollapseAll]);

  useEffect(() => {
    if (isLabelEditing) {
      setIsHovering(false);
    }
  }, [isLabelEditing]);

  const dragRef = useRef(null);
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.tagType,
    item: {
      draggedItem: item,
      siblings: itemSiblings,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.tagType,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (itemInfo, monitor) => {
      console.log("dropped item ===>", itemInfo);
      let draggedItem = itemInfo.draggedItem;
      let draggedItemSiblings = itemInfo.siblings;
      let nextPositionForChild = haveChildren
        ? sortedRelatedList[0].position
        : null;
      if (
        (!has_children && draggedItem.parent_id != parent_id) ||
        dropPosition == "addChild"
      ) {
        showConfirmModal({
          title: "Confirm drop?",
          okType: "primary",
          okText: mrIntl("CommonText.yes"),
          cancelText: mrIntl("CommonText.no"),
          // okButtonProps: {style: {backgroundColor: 'red'}},
          onOk: () =>
            handleDragItemAndDrop(
              item,
              draggedItem,
              dropPosition,
              nextPositionForChild,
              itemIndex,
              itemSiblings,
              draggedItemSiblings
            ),
          mrIntl: mrIntl,
        });
      } else {
        handleDragItemAndDrop(
          item,
          draggedItem,
          dropPosition,
          nextPositionForChild,
          itemIndex,
          itemSiblings,
          draggedItemSiblings
        );
      }
    },
    hover: (draggedItem, monitor) => {
      console.log("ref====>", dragRef);
      if (!dragRef.current) {
        return;
      }

      const hoveredItemDimensions = dragRef.current.getBoundingClientRect();
      const dragPointerDimensions = monitor.getClientOffset();

      const hoveredItemMiddleY =
        (hoveredItemDimensions.bottom - hoveredItemDimensions.top) / 2;
      const hoveredItemfourthX =
        (hoveredItemDimensions.right - hoveredItemDimensions.left) / 4;

      const dragPointerY = dragPointerDimensions.y - hoveredItemDimensions.top;
      const dragPointerX = dragPointerDimensions.x - hoveredItemDimensions.left;

      if (draggedItem.id !== id && isOver) {
        if (
          dragPointerDimensions.y > hoveredItemDimensions.top &&
          dragPointerY < hoveredItemMiddleY
        ) {
          if (dropPosition != "addBefore") {
            setDropPosition((value) => "addBefore");
          }
        } else if (dragPointerX > hoveredItemfourthX && level != 4) {
          if (dropPosition != "addChild") {
            setDropPosition((value) => "addChild");
          }
        } else if (
          dragPointerDimensions.y > hoveredItemDimensions.top &&
          dragPointerY > hoveredItemMiddleY
        ) {
          if (dropPosition != "addAfter") {
            setDropPosition((value) => "addAfter");
          }
        }
        console.log("aq dropPosition", dropPosition);
      }
    },
  });
  console.log("isitDragging", isDragging);

  const showError = item.label === "";
  const haveChildren = sortedRelatedList.length > 0;
  const descriptionRef = useRef();

  useEffect(() => {
    console.log("descriptionRef ===>", descriptionRef);
    if (descriptionRef.current && showDetails && !isDescriptionEditing) {
      renderMathInElement(descriptionRef.current, true);
    }
  }, [descriptionRef.current, showDetails, isDescriptionEditing]);

  const handleMouseEnter = () => {
    if (!isOver && !isLabelEditing) {
      setIsHovering(true);
    }
  };
  const handleMouseLeave = () => {
    if (!isOver && !isLabelEditing) {
      setIsHovering(false);
    }
  };
  let ckeConfig = {
    ...CKETagDescriptionTools,
    debounceInterval: 1000,
  };

  return (
    <div
      className={
        (isDragging ? "hidden " : "") + "draggable-list-item-container"
      }
    >
      <div className="drop-cointainer" ref={(node) => drop(node)}>
        <div
          className={`${isDragging ? "blur" : ""} element-contiainer level-${level}`}
          ref={dragRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Row>
            {isOver && dropPosition == "addBefore" && (
              <ShadowItem dropPosition={dropPosition} />
            )}
          </Row>
          {/* <Row className="button-row">
          <Col offset={11}>
            {
              !isOver && isHovering && 
              <Button
                className="add-element-button m-b-5"
                icon={<PlusCircleTwoTone />}
                onClick={() => addItem("before")}
              />
            }
          </Col>
          </Row> */}
          <Row className="element-row">
            {isEditable && !isOver && isHovering && (
              <Button
                key={`add-sibling-above-${id}`}
                type={"text"}
                size={"small"}
                className="add-element-button-above m-b-5"
                icon={<PlusCircleTwoTone />}
                onClick={() => addItem(item, "before", itemIndex, itemSiblings)}
              />
            )}

            <DragElement
              drop={drop}
              preview={preview}
              isDragging={isDragging}
              drag={drag}
              item={item}
              addItem={addItem}
              addChildrenItem={addChildrenItem}
              deleteItem={deleteItem}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              showAddChildrenButton={!item.has_children && level != 4}
              isLabelEditing={isLabelEditing}
              showChildren={showChildren}
              setShowChildren={setShowChildren}
              showCollapseButton={haveChildren}
              itemSiblingsCount={itemSiblingsCount}
              showDetails={showDetails}
              setShowDetails={setShowDetails}
              updateItemDetail={updateItemDetail}
              showDetailAddons={showDetailAddons}
              activeItemKey={activeItemKey}
              setActiveItemKey={setActiveItemKey}
              loading={loading}
              isEditable={isEditable}
              items={items}
            />
            {isEditable && !isOver && isHovering && (
              <Button
                key={`add-sibling-below-${id}`}
                type={"text"}
                size={"small"}
                className="add-element-button-below m-t-5"
                icon={<PlusCircleTwoTone />}
                onClick={() => addItem(item, "after", itemIndex, itemSiblings)}
              />
            )}
          </Row>
          {/* <Row>{showError && <span className="erro-massage">required</span>}</Row> */}
          <Row className="button-row ">
            {showError && (
              <Col span={8} offset={0.5}>
                <span className="erro-massage">required</span>
              </Col>
            )}
            <Col offset={showError ? 3 : 11}>
              {/* {
                !isOver && isHovering && 
                <Button
                  className="add-element-button m-t-5"
                  icon={<PlusCircleTwoTone />}
                  onClick={() => addItem("after")}
                />
              } */}
            </Col>
          </Row>
          {showDetailAddons && (
            <Row
              className={
                "item-details-wrapper " + (haveChildren && "having-children")
              }
            >
              {showDetails && (
                isEditable ? 
                <Space
                  direction="vertical"
                  className="item-details item-details-edit"
                >
                  {/* TODO: check if need hide any teaching level */}
                  <SelectTeachingLevel
                    key={`item-details-level-${id}`}
                    className="select-tag-level"
                    value={item.tag_teaching_level}
                    onChange={(value, option) => {
                      if (option) {
                        updateItemDetail(
                          id,
                          {
                            tag_teaching_level: option.label,
                          },
                        );
                      } else {
                        updateItemDetail(
                          id,
                          { tag_teaching_level: null },
                        );
                      }
                    }}
                    config={{
                      widgetConfig: {
                        hideSl: true,
                      },
                    }}
                  />
                  {isDescriptionEditing ? (
                    <DetectOutsideClick
                      detectOutside={true}
                      targetExceptionClassname={[
                        "ck-toolbar",
                        "wrs_modal_dialogContainer",
                        "mqEditor-wrapper"
                      ]}
                      callback={() => {
                        setActiveItemKey("");
                      }}
                    >
                      <CKETextContent
                        className="item-input-cke"
                        itemId={`item-description-${id}`}
                        value={item.description}
                        tools={CKETagDescriptionTools}
                        ckeConfig={ckeConfig}
                        onChange={(data) =>
                          updateItemDetail(id, { description: data })
                        }
                        placeholder={mrIntl(
                          "DraggableListItem.write_description"
                        )}
                      />
                    </DetectOutsideClick>
                  ) : (
                    <div
                      key={`description-${id}`}
                      ref={descriptionRef}
                      className={`${
                        item.description && "cursor-pointer"
                      } item-show-input-wrapper`}
                      onClick={() => {
                        if (!ckeConfig.isReadOnly && !loading) {
                          setActiveItemKey(`item-description-${id}`);
                        }
                      }}
                    >
                      <Space className="item-content">
                        {item.description ? (
                          <RenderHtml text={item.description} />
                        ) : (
                          <span className="action-text">
                              <EditOutlined /> Write description
                          </span>
                        )}
                      </Space>
                    </div>
                  )}
                </Space>
                : 
                <Space direction="vertical">
                  {item.tag_teaching_level && <Tag>{item.tag_teaching_level}</Tag>}
                  {item.description && <RenderHtml text={item.description} ref={descriptionRef} />}
                </Space>
              )}
            </Row>
          )}
          <Row>
            {isOver &&
              (dropPosition == "addAfter" || dropPosition == "addChild") && (
                <ShadowItem dropPosition={dropPosition} />
              )}
          </Row>
        </div>
      </div>
      {showChildren && (
        <div style={{ marginLeft: isEditable ? "40px" : "30px" }}>
          {sortedRelatedList.map((item, index) => (
            <DraggableListItem
              key={`${item.type_c}-${id}`}
              item={item}
              itemIndex={index}
              itemSiblings={sortedRelatedList}
              items={items}
              handleDragItemAndDrop={handleDragItemAndDrop}
              addItem={addItem}
              addChildrenItem={addChildrenItem}
              deleteItem={deleteItem}
              level={level + 1}
              updateItemDetail={updateItemDetail}
              showDetailAddons={showDetailAddons}
              loading={loading}
              isCollapseAll={isCollapseAll}
              isEditable={isEditable}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(DraggableListItem);
