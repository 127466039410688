import React, { useState } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import SelectFromComputer from '/src/components/UI/Media/SelectFromComputer';
import Dragger from 'antd/es/upload/Dragger';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { axiosInstance } from '/src/api/apiModule';
import loadable from '@loadable/component';

const papaParseLib = loadable.lib(() =>
  import(
    /* webpackChunkName: "papaparse", webpackPrefetch: true */ "papaparse"
  )
);

const OneRoster = (props) => {
  console.log("CSVUpload ====>", props);
  const [csvData, setCSVData] = useState({});
  const mrIntl = useTranslate()

  const handleFileUpload = (file) => {
    
    try {
      papaParseLib.load().then((module) => {
        console.log("papaParseLib ===>", module);
        const { parse } = module
        parse(file, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: (result) => {
            if (result.errors.length > 0) {
              console.error('CSV parsing error:', result.errors);
              // Handle parsing errors as needed
              message.error('Error parsing CSV file');
            } else {
              const fileName = file.name.replace(/\.[^.]+$/, '');
              setCSVData((prevCSV) => (
                {
                  ...prevCSV,
                  [fileName]: result.data
                }
              ));
              message.success('CSV file uploaded and parsed successfully');
            }
          },
        });
      })
    } catch (error) {
      console.error('Error uploading CSV file', error);
      message.error('Error uploading CSV file');
    }
  };

  console.log("csvData =====>", csvData);

  const uploadProps = {
    accept: ".csv",
    showUploadList: false,
    beforeUpload: (file) => {
      handleFileUpload(file);
    },
    multiple: true,
    selectConfig: {
      maxSize: 5,
      showUploadList: {
        showPreviewIcon: false,
      },
    },
  }

  const handlePostRequest = async () => {
    try {
      const url = `${import.meta.env.VITE_API_URL}orgs/${props.orgId}/oneroster_import`;
      const params = {
        source_name: props.orgUUID,
        source_data: csvData
      }
      console.log("");
      const response = axiosInstance.instance.post(url, params);

      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      const responseData = await response.json();
      console.log('POST response data:', responseData);
    } catch (error) {
      console.error('Error sending POST request:', error);
    }
  };

  return (
    <div>
      <h2>Upload CSV File</h2>
      <Dragger {...uploadProps} style={{ margin: "10px 0px 10px 0px" }}
        className="upload-dragger"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {mrIntl("CommonText.click_or_drag_file_to_this_area_to_upload")}
          {/* Click or drag file to this area to upload */}
        </p>
        <p className="ant-upload-hint">
          {mrIntl("SelectFromComputer.supported_types")}
          : .csv
          <br></br>
        </p>
      </Dragger>
      <p>Selected File: {Object.keys(csvData).map((item) => {
        return <p>{item}</p>
      })}</p>
      <Button type="primary" onClick={() => handlePostRequest()}>Submit</Button>
    </div>
  );
}

export default OneRoster;
