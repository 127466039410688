import { Col, Row, Switch, Tooltip } from "antd";
import MarksLegend from "/src/components/UI/MarksLegend";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { studentNameFormatSelector } from "../selector";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { currentUserSelector } from "../../Auth/Login/selector";

const ViewBySwitch = (props) => {
  const studentNameFormat = useSelector(studentNameFormatSelector());
  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  return (
    <Tooltip title={studentNameFormat === "last_name" ? mrIntl("ExperienceUsersListTableFooter.set_to_first_name") : mrIntl("ExperienceUsersListTableFooter.set_to_last_name")} placement="bottom">
      <Switch
        size={"small"}
        className="anonymous-grading-switch"
        checked={studentNameFormat === "last_name"}
        // disabled={props.disabled}
        onClick={(event) =>
          dispatch(
            experienceUsersActions.setStudentNameFormatSuccess({
              student_name_format: studentNameFormat === "last_name" ? "first_name" : "last_name",
            })
          )
        }
        style={{ margin: "10px" }}
      />
      <MrTranslate id={"ExperienceUsersListTableFooter.change_name_format"}/>
    </Tooltip>
  )
};

const ExperienceUsersListTableFooter = (props) => {
  let { rubricType = "", gradingConfig = {}, setGradingConfig, isAnonymousGradingAvailable } = props;
  const mrIntl = useTranslate()
  const currentUser = useSelector(currentUserSelector());
  const currentUserPermission = currentUser.permission || {}
  const canSwitchAnonymousGrading = currentUserPermission.experience?.can_switch_anonymous_grading
  const isStudent = currentUser.role === "student"
  const disabledAnonymousGrading = (currentUser.role !== "admin" && gradingConfig.anonymousGradingEnabled && !canSwitchAnonymousGrading) ? true : false
  const toggleAnonymousGradingTitle = gradingConfig.anonymousGradingEnabled 
  ? mrIntl("ExperienceUsersListTableFooter.turn_off") 
  : mrIntl("ExperienceUsersListTableFooter.turn_on");

  const anonymousGradingTooltipTitle = disabledAnonymousGrading ? mrIntl("ExperienceUsersListTableFooter.you_dont_have_permission_to_disable_anonymous_grading") : toggleAnonymousGradingTitle

  const anonymousSwitcherClick = (value, event) => {
    event.stopPropagation();
    setGradingConfig((gradingConfig) => {
      return { ...gradingConfig, anonymousGradingEnabled: value };
    });
  };

  return (
    <Row className="experience-grading-table-footer p-0-20 f-s-12">
      <Col flex="auto">
        {isAnonymousGradingAvailable && !isStudent &&
          <span>
            <Tooltip
              title={anonymousGradingTooltipTitle}
              placement="bottom"
            >
              <Switch
                size={"small"}
                disabled={disabledAnonymousGrading}
                className="anonymous-grading-switch"
                checked={gradingConfig.anonymousGradingEnabled}
                onChange={(value, event) => anonymousSwitcherClick(value, event)}
                style={{ margin: "10px" }}
              />
            </Tooltip> <MrTranslate id={"ExperienceUsersListTableFooter.anonymous_grading"}/>
          </span>
        }  

        {!gradingConfig.anonymousGradingEnabled && <ViewBySwitch />}
      </Col>
      <Col flex={gradingConfig.anonymousGradingEnabled ? "none" : "auto"}>
        <MarksLegend rubricType={rubricType} />
      </Col>
    </Row>
  );
};

export default ExperienceUsersListTableFooter;