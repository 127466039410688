import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';

import { BsChevronDown } from "react-icons/bs"
import { CKEDropDownInputTools } from "/src/components/UI/CKEditor/CKEConfig"
import { debounce } from 'lodash';
import { getUniqueId } from "/src/lib/utils/helperMethods";
import DefaultCKE from "/src/components/UI/CKEditor/DefaultCKE";
import GapAnswerModal from "/src/views/Segments/CKEFill/GapAnswerModal"
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const DropDownCKE = (props) => {
  console.log("CKE text input props ==>", props)

  const { onTextChanged, onGapAnswerChanged } = props
  const [triggerSaveLastSelectionRange, setTriggerSaveLastSelectionRange] = useState(null)
  const [triggerRestoreLastSelectionRange, setTriggerRestoreLastSelectionRange] = useState(null)
  const [currentGap, setCurrentGap] = useState(null)
  const mrIntl = useTranslate()
  const [formSetting, setFormSetting] = useState({
    title: mrIntl("DropDownInputCKE.choose_correct_answer"),
    visible: false,
    gapItemsSetting: {
      contentRequired: false,
      contentPrefix: 'Option',
      placeholderPrefix: 'Option'
    }
  })

  const tools = {
    ...CKEDropDownInputTools,
    customReactComponent: {
      dropDownInputRenderer: (id, domElement) => {
        const newGaps = props.gaps
        const gapsLength = newGaps.length
        let index = newGaps.findIndex(gap => gap.id == id)
        let gap = newGaps[index]
        let gapPlaceholderText
        if (!gap) {
          gapPlaceholderText = `Drop Down ${gapsLength + 1 || 1}`
          let firstItem = {
            id: getUniqueId(),
            content: '',
            placeholder: mrIntl("DropDownCKE.option_1_placeholder"),
            checked: true
          }
          gap = {
            id: id,
            title: `Gap ${gapsLength + 1}`,
            answer: firstItem,
            items: [firstItem],
            invalid: true,
          }
          // openModal(gap)
          onGapAnswerChanged(gap)
        } else {
          gapPlaceholderText = `Drop Down ${index + 1}`
        }

        ReactDOM.render(
          <div
            key={`dropdown-input-placeholder-${id}`}
            className={`dropdown-input-react-component`}
            // onDoubleClick={() => openModal(gap)}
            onClick={() => openModal(gap)}
          >
            <div className="text-content" dangerouslySetInnerHTML={{ __html: gapPlaceholderText }}></div><BsChevronDown></BsChevronDown>
          </div>,
          domElement
        );
      }
    },
  }

  const openModal = (gap) => {
    setFormSetting({
      ...formSetting,
      visible: true
    })
    setCurrentGap({ ...gap })
    setTriggerSaveLastSelectionRange(getUniqueId())
  }

  return (
    <div>
      {currentGap && <GapAnswerModal
        correctType='single'
        currentGap={currentGap}
        setCurrentGap={setCurrentGap}
        formSetting={formSetting}
        setFormSetting={setFormSetting}
        onGapModalSubmit={onGapAnswerChanged}
        setTriggerRestoreLastSelectionRange={setTriggerRestoreLastSelectionRange}
      >
      </GapAnswerModal>}

      <DefaultCKE
        tools={tools}
        data={props.data}
        onTextChanged={onTextChanged}
        triggerSaveLastSelectionRange={triggerSaveLastSelectionRange}
        triggerRestoreLastSelectionRange={triggerRestoreLastSelectionRange}
      >
      </DefaultCKE>
    </div>
  );
};
export default DropDownCKE;