import React from 'react';

export function ZoomClose() {
    return (
        <svg className="zoom-close-icon" width="28" height="28" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.2426 4.75729C12.9823 4.49694 12.5602 4.49694 12.2998 4.75729L9 8.05712L5.70017 4.75729C5.43982 4.49694 5.01771 4.49694 4.75736 4.75729C4.49701 5.01764 4.49701 5.43975 4.75736 5.7001L8.05719 8.99993L4.75736 12.2998C4.49701 12.5601 4.49701 12.9822 4.75736 13.2426C5.01771 13.5029 5.43982 13.5029 5.70017 13.2426L9 9.94274L12.2998 13.2426C12.5602 13.5029 12.9823 13.5029 13.2426 13.2426C13.503 12.9822 13.503 12.5601 13.2426 12.2998L9.94281 8.99993L13.2426 5.7001C13.503 5.43975 13.503 5.01764 13.2426 4.75729Z" fill="white" />
        </svg>
    )
}

export function PinZoomIncrease() {
    return (
        <svg className="pinch-zoom-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.4879 8.48787C16.6769 8.67686 16.543 9 16.2757 9H11.3C11.1343 9 11 8.86569 11 8.7V3.72426C11 3.45699 11.3231 3.32314 11.5121 3.51213L16.4879 8.48787Z" fill="white" />
            <path d="M18.5 1.5L12.5 7.5" stroke="white" strokeWidth="1.3" strokeLinecap="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.51213 11.5121C3.32314 11.3231 3.45699 11 3.72426 11H8.7C8.86569 11 9 11.1343 9 11.3V16.2757C9 16.543 8.67686 16.6769 8.48787 16.4879L3.51213 11.5121Z" fill="white" />
            <path d="M7.5 12.5L1.5 18.5" stroke="white" strokeWidth="1.3" strokeLinecap="round" />
        </svg>
    );
}

export function DecreaseFlashcardZoom() {
    return (
        <svg className="minus-zoom-icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7" y="12.3501" width="12" height="1.3" rx="0.65" fill="white" />
        </svg>
    );
}

export function IncreaseFlashcardZoom() {
    return (
        <svg className="plus-zoom-icon" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13 7C12.641 7 12.35 7.29101 12.35 7.65V12.35H7.65C7.29101 12.35 7 12.641 7 13C7 13.359 7.29101 13.65 7.65 13.65H12.35V18.35C12.35 18.709 12.641 19 13 19C13.359 19 13.65 18.709 13.65 18.35V13.65H18.35C18.709 13.65 19 13.359 19 13C19 12.641 18.709 12.35 18.35 12.35H13.65V7.65C13.65 7.29101 13.359 7 13 7Z" fill="white" />
        </svg>
    );
}