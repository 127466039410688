import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Table,
  List,
  Avatar,
  Space,
  Tag,
  Tooltip,
  Button,
  Modal,
  Row,
  Select,
  Col,
  Progress,
  Badge,
  Timeline,
  Empty,
  Input,
  Popover,
  Menu,
  Dropdown,
  Spin,
  Switch,
} from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
// import classNames from "classnames";
// import ResizeObserver from "rc-resize-observer";
import _, { isEmpty, toLower, toUpper } from "lodash";
import CountDownTimer from "/src/views/Experiences/ExperienceShow/Components/CountDownTimer";
import dayjs from "dayjs";
import {
  LockOutlined,
  SendOutlined,
  AndroidOutlined,
  AppleOutlined,
  LaptopOutlined,
  MessageOutlined,
  ProfileOutlined,
  SwapOutlined,
  FileOutlined,
  NotificationOutlined,
  ThunderboltOutlined,
  DownOutlined,
  MoreOutlined,
  CommentOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
  WechatOutlined,
  ClockCircleOutlined,
  InfoCircleOutlined,
  WarningOutlined,
  SoundOutlined,
  CloudTwoTone,
  CalculatorOutlined,
  AudioOutlined,
  CloudOutlined,
  LineChartOutlined,
  PlayCircleOutlined,
  EyeOutlined,
  CloudSyncOutlined,
} from "@ant-design/icons";
import { FaCircle } from "react-icons/fa";
import {
  checkMob,
  convertMs,
  dropDownTrigger,
  extraTimeValues,
  getFromLS,
  getKeysFromObject,
  getUniqueId,
} from "/src/lib/utils/helperMethods";
import "/src/views/Experiences/Experiences.scss";
import StatisticsData from "/src/views/Experiences/ExperienceShow/ExperienceManage/ExperienceMonitor/StatisticsData";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";
import {
  appRegionSelector,
  currentUserSelector,
  enabledFeaturesSelector,
  getUserIpSelector,
} from "/src/views/Auth/Login/selector";
// import WithTooltip from "/src/components/WithTooltip/WithTooltip";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import ChatWrapper from "/src/lib/MrChat/UI/ChatWrapper/ChatWrapper";
import MrChat from "/src/lib/MrChat/MrChat";
import { firestoreDB, firestoreFieldValue } from "/src/config/initializers";
import {
  experiencePausedSelector,
  firstActiveChatSelector,
  experiencePausedAtSelector,
  presenceDataSelector,
  experienceUnpausedAtSelector,
  unpausedOffsetUserInfoSelector,
  studentUnreadMessagesCountUserInfoSelector,
} from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import MrFirebase from "/src/lib/MrFirebase/MrFirebase";

// import { FixedSizeList } from "react-window";
import { memo } from "react";
import { realtimeBatchActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/firebaseHelper";
import Checkbox from "antd/lib/checkbox/Checkbox";

import NotificationSound from "/src/assets/audio/notification-insight.mp3";
import FeedbackModal from "/src/views/Feedbacks/FeedbackModal";
import AttemptStatus from "/src/views/Experiences/ExperienceShow/Components/AttemptStatus";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import AuditLogs from "../../Components/AuditLogs/AuditLogs";
import { activeAdjustedExperienceSelector, monitorConfigSelector } from "/src/views/Experiences/selector";
import BulkAddTime from "../../Components/BulkAddTime";
import AppVersionDistributionModal from "./AppVersionDistributionModal";
import { teacherSubmitLoadingSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { buildLogObject } from "/src/lib/utils/auditLogsHelper";
import ResponseAttachments from "/src/views/Segments/ResponseAttachments/ResponseAttachments";
import QuitPassword from "./QuitPassword/QuitPassword";
import { MdSpellcheck } from "react-icons/md";
import { checkUserAppVersionStatus } from "/src/App/OfflineApp/offlineAppHelper";
import StudentAccommodationsMonitor from "../../Components/StudentAccommodationsMonitor";
import AddTimeBtn, { getCurrentExtraTime } from "../../Components/AddTimeBtn";
import { CiWifiOff } from "react-icons/ci";
import PauseUnpauseButton from "./PauseUnpauseButton";
import "./StudentsList.scss";

const { Option } = Select;
const notification = new Audio(NotificationSound);

// const ListItem = ({ index, style }) => <div style={style}>Row {index}</div>;

// const Example = () => (
//   <FixedSizeList height={150} itemCount={1000} itemSize={35} width={300}>
//     {ListItem}
//   </FixedSizeList>
// );


const ForceSubmit = ({
  experienceId,
  teacherSubmitLoading,
  itemUid,
  itemName,
  submitStudentResponse,
}) => {
  // const confirm = e => {
  //   e.stopPropagation()
  //   if(!teacherSubmitLoading){
  //     submitStudentResponse({userId: itemUid, experienceId: experienceId, name: itemName})
  //   }
  //   // message.error("Delete cancelled");
  // };
  // const cancel = e => {
  //   e.stopPropagation()
  //   // message.error("Delete cancelled");
  // };
  const mrIntl = useTranslate();

  return (
    // <Popconfirm
    //   title="Confirm force submit?"
    //   onConfirm={confirm}
    //   onCancel={cancel}
    //   okText="Yes"
    //   cancelText="No"
    // >
    //   {/* <Tooltip title="Force submit" placement="top"> */}
    //   {/* </Tooltip> */}
    // </Popconfirm>

    //Todo: add type “submit“ to confirm and then force submit
    <Button
      style={{ padding: "0px", background: "none" }}
      // size="small"
      icon={<SendOutlined />}
      shape={false}
      ghost={false}
      type="text"
      disabled={teacherSubmitLoading}
      onClick={() => 
        showConfirmModal({
          title: mrIntl("StudentsList.confirm_force_submit"),
          content: mrIntl("StudentsList.force_submit_tooltip_msg"),
          className: "height-centered-modal",
          onOk: () => {
            if (!teacherSubmitLoading) {
              submitStudentResponse({
                userId: itemUid,
                experienceId: experienceId,
                name: itemName,
              });
            }
          },
          onCancel: () => {},
          mrIntl: mrIntl
        })
      }
    >
    {mrIntl("StudentsList.force_submit")}
    </Button>
  );
};

const TTSEnableSwitch = ({ item, itemState, updateRecord }) => {
  console.log("TTSEnableSwitch ====>", item);
  const mrIntl = useTranslate()
  const toggleTTSEnabled = () => {
    updateRecord(item.uid, { tts_allowed: !item.tts_allowed }, item);
  };

  return (
    <Tooltip
      placement="left"
      title={
        itemState.state !== "online" 
          ? mrIntl("CommonText.cannot_perform_action_when_student_is_offline")
          : item.currentView === "submittedTest"
            ? mrIntl("CommonText.cannot_perform_action_after_student_submission")
            : ""
      }
    >
      <div>
        <Space>
          <SoundOutlined />
          {mrIntl("CommonText.text_to_speech")}
        </Space>
          <Switch
            size="small"
            checked={item.tts_allowed}
            disabled={item.currentView === "submittedTest" || itemState.state !== "online"}
            onChange={toggleTTSEnabled}
          />
      </div>
    </Tooltip>
  );
};

const STTEnableSwitch = ({ item, itemState, updateRecord }) => {

  console.log("SSTEnableSwitch ====>", item);
  const mrIntl = useTranslate()
  const toggleSTTEnabled = (checked) => updateRecord(item.uid, { stt_allowed: checked }, item);

  return (
    <Tooltip
      placement="left"
      title={
        itemState.state !== "online" 
          ? mrIntl("CommonText.cannot_perform_action_when_student_is_offline")
          : item.currentView === "submittedTest"
            ? mrIntl("CommonText.cannot_perform_action_after_student_submission")
            : ""
      }
    >
      <div>
        <Space>
        <AudioOutlined />
        {mrIntl("CommonText.speech_to_text")}
        </Space>
        <Switch
          size="small"
          checked={item.stt_allowed}
          disabled={item.currentView === "submittedTest" || itemState.state !== "online"}
          onChange={toggleSTTEnabled}
        />
      </div>
    </Tooltip>
  );
};

const AllowOnlineSwitch = ({ item, updateRecord }) => {
  console.log("AllowOnlineSwitch ====>", item);
  const mrIntl = useTranslate()
  const toggleAllowOnline = () => {
    updateRecord(item.uid, { allowed_in_browser: !item.allowed_in_browser }, item, {
      log: {
        logging: true,
        action: "student_allowed_in_browser",
        user_id: item.user_id,
        current_value: {
          allowed_in_browser: !item.allowed_in_browser
        },
      },
    });
  };

  return (
    <Tooltip title={mrIntl("StudentsList.allow_in_browser_with_security")} placement="left">
      <div>
        <Space>
          <CloudOutlined />
          {mrIntl("StudentsList.allow_in_browser")}
        </Space>
        <Switch
          size="small"
          checked={item.allowed_in_browser}
          onChange={(value) => toggleAllowOnline()}
        />
      </div>
    </Tooltip>
  );
};

const EnableLocalStorageSync = ({ item, updateRecord }) => {
  console.log("EnableLocalStorageSync ====>", item);
  const mrIntl = useTranslate()
  const enableLocalStorageSync = () => {
    updateRecord(item.uid, { enable_local_storage_sync: !item.enable_local_storage_sync }, item);
  };

  return (
    <Tooltip title={mrIntl("StudentsList.enable_local_storage_sync")} placement="left">
      <div>
        <Space>
          <CloudSyncOutlined />
          {mrIntl("StudentsList.enable_local_storage_sync")}
        </Space>
        <Switch
          size="small"
          checked={item.enable_local_storage_sync}
          onChange={(value) => enableLocalStorageSync()}
        />
      </div>
    </Tooltip>
  );
};

// const AddTimeBtn = ({
//   item,
//   itemState,
//   experienceDuration,
//   updateRecord,
// }) => {
//   console.log(
//     "Add time button props ==>",
//     item,
//     itemState,
//     experienceDuration,
//     updateRecord
//   );
//   const { Option } = Select;
//   const mrIntl = useTranslate();

//   // let currentTime = Date.now()
//   const currentUser = useSelector(currentUserSelector());
//   let extraTime =
//     typeof item.extra_time != "undefined" ? item.extra_time / 60000 : null;
//   return (
//     <Tooltip
//       key={`add-time-btn-${item.uid}`}
//       title={itemState.state !== "online" ? mrIntl("StudentsList.cannot_add_time_while_student_is_offline") : mrIntl("StudentsList.total_extra_time_in_minutes")}
//       placement="right"
//     >
//       <Select
//         placeholder={mrIntl("CommonText.extra_time")}
//         disabled={item.currentView === "submittedTest" || itemState.state !== "online"}
//         size="small"
//         style={{ width: "70px" }}
//         value={extraTime}
//         onChange={(value) => {
//           let exTimeOffset = 0;
//           if (item.currentView === "endTest") {
//             let totalTestTime =
//               item.started_at +
//               convertMs(experienceDuration) +
//               (item.extra_time || 0);
//             console.log(
//               "extra time 539 ",
//               totalTestTime,
//               item.ended_at,
//               Date.now()
//             );

//             if (item.is_time_up === true) {
//               exTimeOffset = Date.now() - totalTestTime;
//             } else {
//               exTimeOffset = item.extra_time_offset || 0;
//             }

//             // if (item.ended_at < totalTestTime) {
//             //   // Case: When student ends test before time ends
//             //   // leave as 0
//             //   exTimeOffset = item.extra_time_offset || 0;
//             // } else {
//             //   // Case: After time ends
//             //   exTimeOffset = Date.now() - totalTestTime;
//             // }
//             console.log("extra time ", exTimeOffset);
//           } else if (item.currentView === "startTest") {
//             // when coming back to test after adding time and then adding time was an issue.
//             exTimeOffset = item.extra_time_offset || 0;
//           }
//           // updateRecord(item.uid, { extra_time: convertMs(value), extra_time_offset: exTimeOffset }, item);
//           updateRecord(
//             item.uid,
//             { extra_time: convertMs(value), extra_time_offset: exTimeOffset },
//             item,
//             {
//               log: {
//                 // logging: experienceItemConfig.log.logging,
//                 logging: true,
//                 action: "extra_time_added",
//                 user_id: item.user_id,
//                 current_value: {
//                   time: value
//                 },
//               },
//             }
//           );
//         }}
//       >
//         {extraTimeValues.map((currentExtraTime) => {
//           return <Option value={currentExtraTime} disabled={extraTime >= currentExtraTime ? true : false}>
//             {currentExtraTime}
//           </Option>
//         })}
//       </Select>
//     </Tooltip>
//   );
// };

const ChatBtn = (props) => {
  const mrIntl = useTranslate()
  const { item } = props;
  const dispatch = useDispatch();
  const isMobile = checkMob();
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const experienceSettings = activeExperience.settings || {}
  useEffect(() => {
    if (item.has_teacher_read === false) {
      notification.play().then(
        () => {
          console.log("play successfull");
        },
        (err) => {
          console.log("play fail since tab inactive", err);
        }
      );
    }
  }, [item.has_teacher_read]);

  const chatDisabled = experienceSettings?.chat_disabled;
  const chatButtonTitle = chatDisabled ? mrIntl("ChatWrapper.chat_has_been_disabled_for_this_assessment") : null;
  

  let chatBtnRender = (
    <Badge key={`chatBtn-${item.uid}`} dot={item.has_teacher_read === false}>
       <Tooltip title={chatButtonTitle}>
        <Button
          type={
            item.has_teacher_read === false ? "primary" : isMobile ? "text" : "default"
          }
          disabled={chatDisabled}
          shape={isMobile ? "circle" : "round"}
          icon={<MessageOutlined />}
          onClick={() => {
            dispatch(
              firestoreInteractionActions.setActiveChatSuccess({
                id: item.uid,
                status: "open",
                name: item.name,
              })
            );
          }}
        >
            {/* {isMobile ? "" : "Chat"} */}
          {isMobile ? "" : mrIntl("StudentsList.chat")}
        </Button>
      </Tooltip>
    </Badge>
  );

  return chatBtnRender;
};

const TeacherDashboardChat = (props) => {
  const { 
    experience, 
    allUserInfo,
    // presenceData
  } = props;
  const appRegion = useSelector(appRegionSelector());
  // const activeChats = useSelector(activeChatsSelector);
  const firstActiveChat = useSelector(firstActiveChatSelector);
  const currentUser = useSelector(currentUserSelector());
  // const currentActiveChat = activeChats[0];
  const studentUnreadCountFromRedux = useSelector(studentUnreadMessagesCountUserInfoSelector);
  const presenceDataFromRedux = useSelector(presenceDataSelector());
  const dispatch = useDispatch();
  const isChina = appRegion === "china" ? true : false;


  console.log("chat firstActiveChat", firstActiveChat);
  const setUserInfo = (id, userInfo) => {
    dispatch(
      firestoreInteractionActions.setUserInfo({
        id: id,
        experienceId: experience.id,
        userInfo,
      })
    );
  };
  // useEffect(() => {
  //   // effect
  //   if(firstActiveChat){
  //     setActiveChatId(firstActiveChat.id)
  //   }
  //   // return () => {
  //   //   cleanup
  //   // }
  // }, [firstActiveChat])
  if (!firstActiveChat) {
    return null;
  }
  const userInfo = allUserInfo.find((itm) => itm.uid === firstActiveChat.id);
  if (!userInfo) {
    return null;
  }
  // const userChatInfo = {
  //   isOnline: userInfo.online,
  //   isActive: userInfo.is_student_active
  // }
  console.log("chat userInfo", userInfo);
  let isOnline =
    presenceDataFromRedux[userInfo.uid] && presenceDataFromRedux[userInfo.uid].state === "online"
      ? true
      : false;
  let chatModule = (
    <MrChat
      key={`student-chat-module-${firstActiveChat.id}`}
      config={{
        header: firstActiveChat.name,
        role: "student",
        sender_name: currentUser.name,
        chat_type: "individual",
        sender_id: currentUser.id,
        chat_id: `${firstActiveChat.id}`,
        isOnline: isOnline,
        isActive: userInfo.is_student_active,
        userInfo: userInfo,
        totalQuestionsCount: experience.attemptable_questions_count,
      }}
      msgExtra={{ experience_id: experience.id }}
      collection={{ path: `/experiences/${experience.id}/messages` }}
      afterMessageCallback={() => {
        let userInfoToSet = {};
        let updateUserInfo = false;
        if (!userInfo.is_student_active) {
          let studentUnreadCount = studentUnreadCountFromRedux
          userInfoToSet.has_student_read = false;
          userInfoToSet.student_unread_count = isChina ? ++studentUnreadCount : firestoreFieldValue.increment(1);
          updateUserInfo = true;
        }
        if (!userInfo.is_teacher_active) {
          userInfoToSet.is_teacher_active = true;
          updateUserInfo = true;
        }
        console.log("supabase - realtimeBatchActions afterMessageCallback userInfoToSet", userInfoToSet, updateUserInfo);
        if (updateUserInfo) {
          setUserInfo(firstActiveChat.id, userInfoToSet);
        }
      }}
    />
  );
  const initiallyOpen = firstActiveChat.status === "open" ? true : false;

  // if (appRegion === "china") {
  //   return null;
  // } else {
    return (
      <>
        <ChatWrapper
          chatUnmountCallback={() => {
            console.log("chat unmounted");
            setUserInfo(firstActiveChat.id, {
              is_teacher_active: false,
              chat_teacher_last_seen_at: Date.now(),
            });
          }}
          onVisibleChangeCallback={(visible) => {
            console.log('checking on teacher side==>', visible);
            if (visible) {
              setUserInfo(firstActiveChat.id, {
                active_teachers: appRegion !== "china" ? firestoreFieldValue.arrayUnion(currentUser.id) : [],
                is_teacher_active: true,
                has_teacher_read: true,
              });
            } else {
              console.log("visibleChangeCalled");
              setUserInfo(firstActiveChat.id, {
                active_teachers: appRegion !== "china" ? firestoreFieldValue.arrayRemove(currentUser.id) : [],
                is_teacher_active: false,
                chat_teacher_last_seen_at: Date.now(),
              });
            }
          }}
          config={{
            closeOnOutsideClick: false,
            initiallyOpen,
            chat_id: firstActiveChat.id,
          }}
         
        >
          {chatModule}
        </ChatWrapper>
      </>
    );
  // }
}; 

function areEqual(prevProps, nextProps) {
  printChanges(prevProps, nextProps);
  if (
    prevProps.item === nextProps.item &&
    prevProps.selected[prevProps.item.uid] ===
      nextProps.selected[nextProps.item.uid] &&
    prevProps.batchSelectionMode === nextProps.batchSelectionMode &&
    prevProps.showSelectBoxes === nextProps.showSelectBoxes
  ) {
    return true;
  } else {
    return false;
  }
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}
const printChanges = (a, b, opts = { print: "updateFix" }) => {
  // let a = prevProps, b = this.props;
  // let a = prevState, b = this.state;
  //
  //
  let changes = _.reduce(
    a,
    function (result, value, key) {
      return _.isEqual(value, b[key]) ? result : result.concat(key);
    },
    []
  );
  console.log(`${opts.print} changes`, changes);
  return changes;
};

export const getExperienceDurationForStudent = (item, experienceSettings) => { 
  return item.duration_after_reset ? item.duration_after_reset : experienceSettings.duration
}

const StudentsListComponent = memo(function StudentsListComponent(props) {
  const {
    index,
    style,
    data,
    // presenceData,
    experience,
    currentUser,
    extraActions,
    item,
    showSelectBoxes,
    headerActions,
    bulkActionLoading,
    setActiveModal,
    ...extraProps
  } = props;
  console.log("stundetListComponent===>", item.uid);
  // let item = data[index]
  const experienceSettings = extraProps.experienceSettings;
  const dispatch = useDispatch();
  const experienceDuration = getExperienceDurationForStudent(item, experienceSettings); 
  console.log("socketIO - StudentsListComponent index", experienceDuration);
  // return <div style={style}>Row {index}</div>
  // const avatar = <span onClick={() => {
  //   extraProps.setSelection(item.uid, extraProps.selected[item.uid] ? false : true);
  //   console.log("selection checked", item.uid, extraProps.selected[item.uid]);
  // }}>{extraProps.selected[item.uid] ? "y" : <ProfileIcon item={item} totalQuestionsCount={experience.questions_count} />}</span>
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const mrIntl = useTranslate();
  console.log(
    "selectAll extraProps.selected[item.uid]",
    extraProps.selected[item.uid]
  );
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const presenceDataForUserFromRedux = useSelector(presenceDataSelector(item.uid));
  console.log("socketIO - presenceDataFromRedux", presenceDataForUserFromRedux)
  const isShowTimer = (item.currentView === "startTest" ||
  item.currentView === "endTest" ||
  item.currentView === "resumeTest") &&
  experienceSettings.is_timed &&
  experienceDuration > 0

  if (item) {
    return (
      <CustomListItem
        type={"students-monitor-list"}
        style={style}
        item={item}
        title={<Title item={item} itemState={presenceDataForUserFromRedux} />}
        // title={<Title item={item} itemState={presenceData[item.uid]} />}
        description={
          <Description
            item={item}
            experience={experience}
            enabledFeatures={enabledFeatures}
          />
        }
        avatar={
          <ProfileIcon
            item={item}
            bulkActionLoading={bulkActionLoading}
            totalQuestionsCount={experience.attemptable_questions_count}
            experience={experience}
            setSelection={extraProps.setSelection}
            userSelected={extraProps.selected[item.uid]}
            showSelectBoxes={props.showSelectBoxes}
            batchSelectionMode={extraProps.batchSelectionMode}
            itemState={presenceDataForUserFromRedux}
            headerActions={headerActions}
          />
        }
        extraInfo={
          <ExtraInfo
            key={"extra-info"}
            item={item}
            itemState={presenceDataForUserFromRedux}
            isShowTimer={isShowTimer}
            setIsTimeUp={setIsTimeUp}
            experienceSettings={experienceSettings}
            isChina={extraProps.isChina}
            experienceUid={experience.uid}
            experienceDuration={experienceDuration}
          />
        }
        extraActions={
          !showSelectBoxes
            ? extraActions(
                item,
                isTimeUp,
                // presenceData[item.uid],
                presenceDataForUserFromRedux,
                experienceSettings,
                extraProps.teacherSubmitLoading,
                extraProps.submitStudentResponse,
                experience,
                extraProps.isChina,
                dispatch,
                extraProps.updateRecord,
                extraProps.showLogs,
                enabledFeatures,
                experienceDuration,
                mrIntl, 
                setActiveModal,
                dropdownVisible,
                setDropdownVisible,
                isShowTimer
              )
            : null
        }
        currentUser={currentUser}
        // itemState={presenceData[item.uid]}
        itemState={presenceDataForUserFromRedux}
        totalQuestionsCount={experience.attemptable_questions_count}
        {...extraProps}
      />
    );
    // return <div>{item.name}</div>
  } else {
    // shouldn't go here
    return "";
  }
}, areEqual);

const Title = ({ item, itemState }) => {
  const isMobile = checkMob();
  const monitorConfig = useSelector(monitorConfigSelector());
  const sortType = monitorConfig.sortType || 'smart_sort';
  const mrIntl = useTranslate();
  const status =
    itemState &&
    (itemState.state === "online" ? (
      <Tooltip title= {mrIntl("CommonText.online")}>
        <FaCircle color="green" size={"0.7em"} />
      </Tooltip>
    ) : (
      <Tooltip title={mrIntl("CommonText.offline")}>
        <FaCircle color="red" size={"0.7em"} />
      </Tooltip>
    ));

  console.log("item and itemstate in title ==>", item, itemState);
  let maxLength = isMobile ? 10 : 35;
  let guestText = isMobile ? mrIntl("StudentsList.g") : mrIntl("StudentsList.guest");
  let itemName = item.name;
  if(sortType === "last_name_sort" && item.name_by_last_name){
    itemName = item.name_by_last_name
  }
  return (
    <Space className="title">
      {status}
      {item.guest ? guestText : ""}
      {/* {itemState && itemState.state} */}
      {itemName && (
        <span>
          {itemName.length > maxLength ? (
            <Tooltip title={itemName}>
              {itemName.substr(0, maxLength) + "..."}
            </Tooltip>
          ) : (
            itemName
          )}
        </span>
      )}
    </Space>
  );
};

const Description = (props) => {
  const { item, experience } = props;
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const mrIntl = useTranslate();
  let deviceInfo;
  let uploadDeviceInfo;
  let appVersionWarningMessage = "";
  let appVersionWarningMessageColor = "#108ee9";
  const isMobile = checkMob();
  const osVersion =
    item.deviceInfo &&
    item.deviceInfo.osVersion || '';
  let appVersionStatus
  appVersionStatus = checkUserAppVersionStatus(
    item.app_type,
    item.deviceInfo
  );
  if (!isEmpty(appVersionStatus)) {
    if (appVersionStatus.status === "unsupported") {
      appVersionWarningMessage =
        mrIntl("StudentsList.unsupported_version_lockdown_app")
    } else if (appVersionStatus.status === "old") {
      appVersionWarningMessage =
        mrIntl("StudentsList.old_but_supported_version");
    } else {
      appVersionWarningMessage = mrIntl("StudentsList.latest_version");
    }

    appVersionWarningMessageColor =
      appVersionStatus.status === "unsupported"
        ? "#FF0000"
        : appVersionStatus.status === "old"
        ? "#faad14"
        : "#108ee9";
  }
  

  // const macVersion = item.deviceInfo.os === 'Mac OS' && item.app_native_vars.systemInfo && item.app_native_vars.
  if (item.deviceInfo) {
    // console.log("item.deviceInfo", item.deviceInfo, typeof(item.deviceInfo.os))
    if (
      typeof item.deviceInfo.os != undefined &&
      typeof item.deviceInfo.os != "undefined"
    ) {
      deviceInfo =
        item.deviceInfo.os === "Android" ? (
          <Tooltip
            title={mrIntl("StudentsList.taking_test_from_title",{deviceInfoOS: item.deviceInfo.os, deviceInfoBrowser: item.deviceInfo.browser,})}
          >
            <AndroidOutlined style={{color: appVersionWarningMessageColor}} />
          </Tooltip>
        ) : item.deviceInfo.os === "IOS" ? (
          <Tooltip
           title={mrIntl("StudentsList.taking_test_from_title",{deviceInfoOS: item.deviceInfo.os, deviceInfoBrowser: item.deviceInfo.browser,})}
          >
            <AppleOutlined style={{color: appVersionWarningMessageColor}} />
          </Tooltip>
        ) : item.deviceInfo.os === "Linux" ? (
          <Tooltip
           title={mrIntl("StudentsList.taking_test_from_title",{deviceInfoOS: item.deviceInfo.os, deviceInfoBrowser: item.deviceInfo.browser,})}
          >
            <LaptopOutlined style={{color: appVersionWarningMessageColor}} />
          </Tooltip>
        ) : (
          <Tooltip
            title={mrIntl("StudentsList.taking_test_from_title",{deviceInfoOS: item.deviceInfo.os, deviceInfoBrowser: item.deviceInfo.browser,})}
          >
            <LaptopOutlined style={{color: appVersionWarningMessageColor}} />
          </Tooltip>
        );
    } else {
      // console.log("item.deviceInfo bc", item.deviceInfo)
      // backward compatibility
      deviceInfo =
        item.deviceInfo === "Android" ? (
          <Tooltip title={mrIntl("StudentsList.taking_test_from_device_info_title", {deviceInfo: item.deviceInfo})}>
            <AndroidOutlined style={{color: appVersionWarningMessageColor}} />
          </Tooltip>
        ) : item.deviceInfo === "IOS" ? (
          <Tooltip title={mrIntl("StudentsList.taking_test_from_device_info_title", {deviceInfo: item.deviceInfo})}>
            <AppleOutlined style={{color: appVersionWarningMessageColor}} />
          </Tooltip>
        ) : (
          <Tooltip title={mrIntl("StudentsList.taking_test_from_device_info_title", {deviceInfo: item.deviceInfo})}>
            <LaptopOutlined style={{color: appVersionWarningMessageColor}} />
          </Tooltip>
        );
    }
  }
  if (item.uploadDeviceInfo) {
    uploadDeviceInfo =
      item.uploadDeviceInfo.os === "Android" ? (
        <Tooltip title={mrIntl("StudentsList.taking_test_from_uploading_from_device_title", {uploadDeviceInfoOS: item.uploadDeviceInfo.os , uploadDeviceInfoBrowser: item.uploadDeviceInfo.browser})}>
          <AndroidOutlined />
        </Tooltip>
      ) : item.uploadDeviceInfo.os === "IOS" ? (
        <Tooltip title={mrIntl("StudentsList.taking_test_from_uploading_from_device_title", {uploadDeviceInfoOS: item.uploadDeviceInfo.os , uploadDeviceInfoBrowser: item.uploadDeviceInfo.browser})}>
          <AppleOutlined />
        </Tooltip>
      ) : (
        ""
      );
  }

  let group_name = "";
  if (experience.groups && experience.groups.length >= 0) {
    if (typeof item.group_id == "object") {
      // after changing to arr
      experience.groups.map((g) => {
        if (item.group_id && item.group_id.indexOf(g.id) > -1) {
          group_name += " | " + g.name;
        }
      });
    } else {
      // old
      let group = experience.groups.find((g) => g.id === item.group_id) || {};
      group_name = group.name;
    }
  }

  const appVersion = (item.deviceInfo && item.deviceInfo.appVersion) || (item.app_native_vars && item.app_native_vars.appVersion)
  
  return (
    <Fragment>
      <Space className="description">
        {(item.app_type === "seb" ||
          item.app_type === "mac" ||
          item.app_type === "ios" ||
          item.app_type === "cros") && (
          <Tooltip
            title={`${
              item.app_type === "seb"
              ? mrIntl("CommonText.seb",{appVersion: appVersion})
              : item.app_type === "mac"
              ? mrIntl("CommonText.ap_mac", {appVersion: appVersion})
              : item.app_type === "ios"
              ? mrIntl("CommonText.ap_ios", {appVersion: appVersion})
              : item.app_type === "cros"
              ? mrIntl("StudentsList.ap_chromebook")
              : ""
            } Lockdown App (${
              item.deviceInfo && item.deviceInfo.os
            }${osVersion && ` ${osVersion}`})
              ${appVersionWarningMessage && `- ${appVersionWarningMessage}`} 
            `}
          >
            { appVersionStatus && appVersionStatus.status === "unsupported" ? <WarningOutlined style={{color: appVersionWarningMessageColor}} /> : <LockOutlined style={{color: appVersionWarningMessageColor}} /> }
          </Tooltip>
        )}
        {item.app_type === "web" && deviceInfo}
        {item.uploadDeviceInfo && uploadDeviceInfo}
        {/* {currentUser.role == "superadmin" &&  <span>{item.org.name}</span>} */}
        {<Tooltip placement="bottom" title={mrIntl("CommonText.student_code")}><span>{item.s_code}</span></Tooltip>}
        {!isMobile && item.group_id && <span>{group_name}</span>}
        {/* {item.group_id && <span>{item.group_id}</span>} */}
        {/* {isStudent && item.grade && <span>{item.grade.name}</span>} */}
        {item.started_at && !isMobile
        ? mrIntl("StudentsList.started_date_and_time", {startedAt: dayjs(item.started_at).format("MMM DD, HH:mm a") })
        : ""}
        {/* {item.started_at && !isMobile
        ? `| Started ${dayjs(item.started_at).format("MMM DD, HH:mm a")}`
        : ""} */}
      </Space>
      <br />
      {/* <Space>
      {item.started_at ? `Started ${dayjs(item.started_at).format("MMM DD, HH:mm a")}` : ""}
    </Space>
    <br /> */}
      {/* <Space>
      {experience.settings.live_attempt_status && item.questions_status && <AttemptStatus item={item} totalQuestions={experience.questions_count} experience={experience} />}
    </Space> */}
    </Fragment>
  );
};

const ProgressBar = (props) => {
  const { item, totalQuestionsCount, experience } = props;
  console.log("Dashboard list progress bar props ==>", props);
  let attemptPercentage = item.attempt_count
    ? ((item.attempt_count / totalQuestionsCount) * 100).toFixed(0)
    : 0;

  const appRegion = useSelector(appRegionSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const mrIntl = useTranslate()
  const attemptCount = item.attempt_count ? item.attempt_count : 0
  // OLD
  // let attemptProgressBar = <Tooltip title={`Questions attempted (${item.attempt_count}/${totalQuestionsCount})`} placement="bottom"><Progress type="circle" percent={attemptPercentage} width={45} /></Tooltip>

  // NEW
  // not using org enabledFeatures.live_attempt_status since in guest mode, org is diff. saving the feature flag in exp settings after save and using that
  let content = enabledFeatures.live_attempt_status && (appRegion != "china" || import.meta.env.VITE_MODE !== "production") ? (
    <AttemptStatus
      questionsStatus={item.questions_status}
      experienceTopics={experience.topics}
    />
  ) : (
    ""
  );
  let attemptProgressBar = (
    <Popover
      content={content}
      title={mrIntl("StudentsList.questions_attempted", {attemptCount: attemptCount, totalQuestionsCount: totalQuestionsCount})}
    >
      <Progress
        type="circle"
        percent={attemptPercentage}
        width={45}
        style={{ cursor: "default" }}
      />
    </Popover>
  );

  return attemptProgressBar;
};

const ProfileIcon = ({
  item,
  totalQuestionsCount,
  setSelection,
  userSelected,
  showSelectBoxes,
  experience,
  itemState,
  batchSelectionMode,
  headerActions,
  bulkActionLoading
}) => {
  console.log("selectAll userSelected", showSelectBoxes);
  const isCheckBoxDisabled =
    bulkActionLoading ||
    (headerActions.addTime &&
      (itemState.state !== "online" ||
        item.currentView === "joined" ||
        item.currentView === "submittedTest"));

  const mrIntl = useTranslate();
  const tooltipText = isCheckBoxDisabled
    ? itemState.state !== "online"
      ? mrIntl("StudentsList.can_only_set_extra_time_while_student_is_online")
      : mrIntl("StudentsList.set_extra_time_msg")
    : "";
  // return <Avatar size="large"><ProgressBar item={item} totalQuestionsCount={totalQuestionsCount} /></Avatar>
  return (
    <Space>
      {/* <Checkbox onChange={(e) => {selectUsers(e, item.uid)}}></Checkbox> */}
      {/* <Checkbox onChange={(e) => {setSelection(item.uid, userSelected ? false : true); */}
      {showSelectBoxes && (
        <Tooltip title={tooltipText}>
        <Checkbox
          disabled={isCheckBoxDisabled}
          checked={userSelected}
          onChange={(e) => {
            console.log(
              "selection checked",
              item.uid,
              userSelected,
              e.target.checked
            );
            setSelection(item.uid, e.target.checked ? item.name : false);
          }}
        ></Checkbox>
        </Tooltip>
      )}
      <Avatar size="large">
        <ProgressBar
          item={item}
          totalQuestionsCount={totalQuestionsCount}
          experience={experience}
        />
      </Avatar>
    </Space>
  );
};

const Timer = (props) => {
  const { item, setIsTimeUp, experienceDuration } = props;
  
  return (
    <CountDownTimer
      key="experience-timer-component"
      forMonitor={true}
      duration={experienceDuration}
      extra_time={item.extra_time}
      extra_time_offset={item.extra_time_offset}
      startedAt={item.started_at}
      setIsTimeUp={setIsTimeUp}
      unpaused_offset={item.unpaused_offset}
      currentView={item.currentView}
    />
  );
};

const ScientificCalcSwitch = ({ item, updateRecord, experienceSettings, itemState }) => {
  const mrIntl = useTranslate()
  const onChange = (checked) => {
    updateRecord(item.uid, { calc_enabled: checked }, item);
  };
  return (
    <Tooltip
      placement="left"
      title={
        itemState.state !== "online"
          ? mrIntl("CommonText.cannot_perform_action_when_student_is_offline")
          : item.currentView === "submittedTest"
            ? mrIntl("CommonText.cannot_perform_action_after_student_submission")
            : ""
      }
    >
      <div>
        <Space>
          <CalculatorOutlined />
          {mrIntl("StudentsList.scientific_calculator")}
        </Space>
        <Switch
          disabled={itemState.state !== "online" || item.currentView === "submittedTest"}
          checked={item.calc_enabled}
          size="small"
          onChange={onChange}
        />
      </div>
    </Tooltip>
  );
};

const GraphingCalcSwitch = ({ item, updateRecord, experienceSettings, itemState }) => {
  const mrIntl = useTranslate()
  const onChange = (checked) => {
    updateRecord(item.uid, { graphing_calc_enabled: checked }, item);
  };
  return (
    <Tooltip
      placement="left"
      title={
        itemState.state !== "online"
          ? mrIntl("CommonText.cannot_perform_action_when_student_is_offline")
          : item.currentView === "submittedTest"
            ? mrIntl("CommonText.cannot_perform_action_after_student_submission")
            : ""
      }
    >
      <div>
        <Space>
          <LineChartOutlined />
          {mrIntl("StudentsList.graphing_calculator")}
        </Space>
        <Switch
          disabled={itemState.state !== "online" || item.currentView === "submittedTest"}
          checked={item.graphing_calc_enabled}
          size="small"
          onChange={onChange}
        />
      </div>
    </Tooltip>
  );
};

const SpellCheckSwitch = ({
  item,
  updateRecord,
  experienceSettings,
  itemState,
}) => {
  const mrIntl = useTranslate()
  const onChange = (checked) => {
    updateRecord(item.uid, { spellcheck_enabled: checked }, item);
  };
  return (
    <Tooltip
      placement="left"
      title={
        itemState.state !== "online"
          ? mrIntl("CommonText.cannot_perform_action_when_student_is_offline")
          : item.currentView === "submittedTest"
            ? mrIntl("CommonText.cannot_perform_action_after_student_submission")
            : ""
      }
    >
      <div>
        <Space>
          <MdSpellcheck className="md-spell-check-icon" />
          {mrIntl("CommonText.spellcheck")}
        </Space>
        <Switch
          disabled={itemState.state !== "online" || item.currentView === "submittedTest"}
          size="small"
          checked = {item.spellcheck_enabled}
          onChange={onChange}
        />
      </div>
    </Tooltip>
  );
};

const extraActions = (
  item,
  isTimeUp,
  itemState,
  experienceSettings,
  teacherSubmitLoading,
  submitStudentResponse,
  experience,
  isChina,
  dispatch,
  updateRecord,
  showLogs,
  enabledFeatures,
  experienceDuration,
  mrIntl, 
  setActiveModal,
  dropdownVisible,
  setDropdownVisible,
  isShowTimer
) => {
  console.log("inside extraactions==>=====>", mrIntl);
  const actions = [];
  const isMobile = checkMob();
  const originalUserRole = getFromLS('originalUserRole');
  const lockdownConfig = experienceSettings.lockdown_config || {};
  const enableAccommodations = enabledFeatures.student_accommodations || false;
  const extraTime = getCurrentExtraTime(item, experienceDuration);
  const showAllowOnlineSwitch = experienceSettings.mode === "offline" && enabledFeatures.new_join_view_with_dynamic_config

  const countDownTimer = isShowTimer ? (
    <Timer
      item={item}
      experienceDuration={experienceDuration}
    />
  ) : null; 
  let showQuitPasswordButton = false;
  if (
    experienceSettings.mode === "offline" &&
    item.usedDevices &&
    item.usedDevices.some((device) => device.app_type === "seb") &&
    (lockdownConfig.quit_password || enabledFeatures.win_global_prod_seb_config_file_with_fixed_quit_password_mashrek)
  ) {
    showQuitPasswordButton = true;
  }
  console.log("lockdownConfig=====>>>", lockdownConfig);
  // let targetTime =
  //   item.started_at +
  //   convertMs(experienceSettings.duration) +
  //   (item.extra_time || 0) +
  //   (item.extra_time_offset || 0);

  // if (item.currentView === 'endTest' || ((item.currentView === 'startTest' || item.currentView === 'resumeTest') && (isTimeUp || Date.now() > targetTime))) {
  // if (item.currentView === 'joined' || item.currentView === 'endTest' || item.currentView === 'startTest' || item.currentView === 'resumeTest') {
  //   // actions.push(<ForceSubmit key={`ForceSubmit-${item.uid}`} item={item} />)
  //   actions.push(<ForceSubmit key={`ForceSubmit-${item.uid}`} experienceId={experience.id} teacherSubmitLoading={teacherSubmitLoading} itemUid={item.uid} itemName={item.name} submitStudentResponse={submitStudentResponse}/>)
  //   // actions.push(<Button key={`ForceSubmit-${item.uid}`}>This</Button> )
  // }
  if (item.request_resume === "request") {
    actions.push(
      <AllowResumeBtn
        key={`AllowResumeBtn-${item.uid}`}
        item={item}
        updateRecord={updateRecord}
        itemState={itemState}
      />
    );
    // Also add decline button
  }

  actions.push(<ChatBtn item={item} />);

  if ((!enableAccommodations && (item.currentView === "startTest" ||
      item.currentView === "endTest" ||
      item.currentView === "submittedTest")) &&
    experienceSettings.is_timed &&
    experienceDuration > 0
  ) {
    // if(item.currentView == "startTest"){
    actions.push(
      <AddTimeBtn
        key={`AddTimeBtn-${item.uid}`}
        item={item}
        itemState={itemState}
        updateRecord={updateRecord}
        experienceDuration={experienceDuration}
      />
    );

    if (
      import.meta.env.VITE_MODE === "development" ||
      import.meta.env.VITE_MODE === "staging"
    ) {
      actions.push(
        <Button
          onClick={() => {
            updateRecord(
              item.uid,
              { started_at: item.started_at - 60 * 1000 },
              item
            );
          }}
        >
          RST
        </Button>
      );
    }
  }
  console.log("chat item", item);
  // let chatModule = <MrChat config={{header: `${item.name}`,role: "student", sender_name: currentUser.name, chat_type: "individual", sender_id: currentUser.id, chat_id: `${item.uid}`}} msgExtra={{experience_id: experience.id}} collection={{path: `/experiences/${experience.id}/messages`}}/>;

  // !isChina && item.started_at > new Date("2020-12-17").getTime() && actions.push(
  // !isChina && actions.push(
  //   <Tooltip key={`logs-${item.uid}`} title="Logs" placement="right">
  //     <Button shape="circle" type={"text"} icon={<ProfileOutlined />} onClick={() => {
  //         showLogs(
  //           item.name, {
  //           // user_id: ["==", parseInt(item.uid)]
  //           user_ids: ["array-contains", parseInt(item.uid)]
  //         });
  //         // dispatch(firestoreInteractionActions.setActiveChatSuccess({id: item.uid, status: "open", name: item.name}))
  //     }}></Button>
  //   </Tooltip>
  // )

  const menu = (
    <Menu className="students-list-menu">
      {(item.currentView === "joined" ||
        item.currentView === "endTest" ||
        item.currentView === "startTest" ||
        item.currentView === "resumeTest") && (
        <Menu.Item key={`force-submit-${item.uid}`}>
          <ForceSubmit
            key={`ForceSubmit-${item.uid}`}
            experienceId={experience.id}
            teacherSubmitLoading={teacherSubmitLoading}
            itemUid={item.uid}
            itemName={item.name}
            submitStudentResponse={submitStudentResponse}
          />
        </Menu.Item>
      )}
      {(
        <Menu.Item
        key={`student-logs-${item.uid}`}
        onClick={() => {setDropdownVisible(false)}}
        >  
          <AuditLogs 
          className="logs-btn"
            item={item}
            experience={experience}
            // title={`${item.name} logs`}
            title={mrIntl("StudentsList.item_name_logs", {itemName: item.name})}
            logView={"monitor_audit_logs"}
            log_type={"experience"}
          />
        </Menu.Item>
      )}
      <Menu.Item key={`feedback-modal-${item.uid}`} onClick={() => {setDropdownVisible(false)}}>
        <FeedbackModal
          title= {mrIntl("StudentsList.report_issue")}
          // title={"Report Issue"}
          modalTitle={mrIntl("StudentsList.report_issue_for", {itemName: item.name})}
          // modalTitle={`Report issue for ${item.name}`}
          buttonStyle={{ padding: "0px", background: "none" }}
          shape={false}
          type={"text"}
          icon={<CommentOutlined />}
          ghost={false}
          default={"reported_issue"}
          // onlySingle={true}
          // singleLabel={"Report Issue"}
          item={item}
          experience={experience}
        />
      </Menu.Item>
      {showQuitPasswordButton ? (
        <Menu.Item
          key={`quit-password-${item.uid}`}
          icon={<LockOutlined />}
          onClick={() => setActiveModal("quit_password")}
        >
          {mrIntl("QuitPassword.seb_quit_password")}
        </Menu.Item>
      ) : null}
      {!enableAccommodations ?
        <Menu.Item key={`calc-switch-${item.uid}`}>
          <ScientificCalcSwitch
            item={item}
            itemState={itemState}
            updateRecord={updateRecord}
            experienceSettings={experienceSettings}
          />
        </Menu.Item>
        : null}
      {!enableAccommodations ?
        <Menu.Item key={`graphing-switch-${item.uid}`}>
          <GraphingCalcSwitch
            item={item}
            itemState={itemState}
            updateRecord={updateRecord}
            experienceSettings={experienceSettings}
          />
        </Menu.Item>
        : null}
      {!enableAccommodations && enabledFeatures.spellcheck ?
      <Menu.Item key={`spellcheck-switch-${item.uid}`}>
        <SpellCheckSwitch
          item={item}
          itemState={itemState}
          updateRecord={updateRecord}
          experienceSettings={experienceSettings}
        />
      </Menu.Item>
      : null}
      {!enableAccommodations ?
      <Menu.Item key={`tts-feature-${item.uid}`}>
        <TTSEnableSwitch updateRecord={updateRecord} item={item} itemState={itemState} />
      </Menu.Item>
      : null}
      {(enabledFeatures.speech_to_text_plugin && !enableAccommodations) ?
      <Menu.Item key={`stt-feature-${item.uid}`}>
        <STTEnableSwitch updateRecord={updateRecord} item={item} itemState={itemState} />
      </Menu.Item>
      : null}
      {showAllowOnlineSwitch && (
        <Menu.Item key={`allow-online-${item.uid}`}>
          <AllowOnlineSwitch updateRecord={updateRecord} item={item} />
        </Menu.Item>
      )}
      {enableAccommodations && (
        <Menu.Item key={`student-accommodations-${item.uid}`}>
          <StudentAccommodationsMonitor
            item={item}
            updateRecord={updateRecord}
            experienceDuration={experienceDuration}
            itemState={itemState}
            modalConfig={{
              hideAddTime: !experienceSettings.is_timed,
            }}
            countDownTimer={countDownTimer}
          />
        </Menu.Item>
      )}

      {(originalUserRole === "superadmin") && (
        <Menu.Item key={`sync-offline-responses-${item.uid}`}>
          <EnableLocalStorageSync item={item} updateRecord={updateRecord} />
        </Menu.Item>
      )}
    </Menu>
  );

  const handleOpenChange = (open, info) => {
    if (info?.source === "menu") {
      return;
    } 
    setDropdownVisible(open);
  }; 

  const extraDropdown = (
    <Dropdown overlay={menu} trigger={[dropDownTrigger]} onOpenChange={(open, info) => handleOpenChange(open, info)} open={dropdownVisible}>
      <Button
        type="text"
        trigger={"click"}
        size={isMobile ? "small" : ""}
        shape={isMobile ? "circle" : ""}
        icon={isMobile ? <MoreOutlined /> : ""}
      >
        {isMobile ? "" : mrIntl("StudentsList.more")} {isMobile ? "" : <DownOutlined />} 
      </Button>
    </Dropdown>
  );

  if (enableAccommodations && experienceSettings?.is_timed) {
    actions.push(
      <StudentAccommodationsMonitor
        key={`student-accommodations-${item.uid}`}
        item={item}
        updateRecord={updateRecord}
        experienceDuration={experienceDuration}
        itemState={itemState}
        buttonConfig={{
          buttonProps: {
            className: "",
            type: "",
            icon: "",
          },
          tooltip: "Extra time",
          buttonText: extraTime ? `${extraTime} mins` : "Extra Time"
        }}
        modalConfig={{
          hideAddTime: !experienceSettings.is_timed,
        }}
        countDownTimer={countDownTimer}
      />
    )
  }

  actions.push(extraDropdown);
  
  return actions;
};

const ExtraInfo = (props) => {
  const {
    item,
    setIsTimeUp,
    experienceSettings,
    isChina,
    experienceDuration,
    // experienceUid,
    itemState,
    isShowTimer
  } = props;
  const history = useHistory();
  const isMobile = checkMob();
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  console.log("ExtraInfo", item, history);
  const mrIntl = useTranslate();
  console.log("ExtraInfo props ==>", props);

  const [responseAttachmentsModalVisible, setResponseAttachmentsModalVisible] = useState(false)
  console.log("responseAttachmentsModalVisible ==>", responseAttachmentsModalVisible)

  const teacherSubmitLoading = useSelector(teacherSubmitLoadingSelector)
  const experiencePaused = useSelector(experiencePausedSelector)
  const isOffline = itemState.state !== "online";
  console.log("teacherSubmitLoading ==>", teacherSubmitLoading)

  const getActiveQuestionNo = (activeSegmentInfo) => {
    let qNoRender = "";
    if (
      activeSegmentInfo &&
      activeSegmentInfo.topic_idx !== undefined &&
      activeSegmentInfo.question_number !== undefined
    ) {
      qNoRender = (
        <Tooltip key={"active-question"} title={mrIntl("StudentsList.active_on_question")}>
          <Tag icon={<ThunderboltOutlined />} color="processing">{`Q ${
            activeSegmentInfo.topic_idx + 1
          }.${activeSegmentInfo.question_number}`}</Tag>
        </Tooltip>
      );
    }
    return qNoRender;
  };

  let currentView = "";
  if(experiencePaused && item.currentView !== "submittedTest") {
    currentView = (
      <>
        <Tag key={"current-view"} color={isOffline ? "warning" : "error"} className="experience-current-view-tag-pause-unpause-pending">
          {isOffline ? mrIntl("StudentsList.pause_pending") : mrIntl("StudentsList.paused")}
          {itemState.state !== "online" && <Tooltip key={"eye-icon"} title={mrIntl("StudentsList.student_is_offline")}>
            <CiWifiOff />
          </Tooltip>}
        </Tag>
      </>
    );
  } else if (item.paused) {
    currentView = <Tag key={"current-view"} color="error" className="experience-current-view-tag">{mrIntl("StudentsList.paused")}</Tag>;
  } else if (item.currentView === "startTest") {
    currentView = <Tag color="processing" className="experience-current-view-tag">{mrIntl("StudentsList.started")}</Tag>;
  } else if (item.currentView === "endTest") {
    currentView = <Tag key={"current-view"} color="error" className="experience-current-view-tag">{mrIntl("StudentsList.ended")}</Tag>;
  } else if (item.currentView === "submittedTest") {
    currentView = <Tag key={"current-view"} color="success" className="experience-current-view-tag">{mrIntl("StudentsList.submitted")}</Tag>;
    // TEMP DISABLED
    // currentView = <Tooltip title={item.experience_user_id ? "Open submission" : ""}><Tag key={"current-view"} color="success" style={item.experience_user_id ? {"cursor":"pointer"} : {}} onClick={ () => {
    //   if(item.experience_user_id){
    //     history.push({
    //       pathname: `/e/tests/${experienceUid}/grade/${item.experience_user_id}`,
    //     })
    //   }
    // } }>Submitted</Tag></Tooltip>
  } else if (item.currentView === "resumeTest") {
    currentView = <Tag key={"current-view"} color="processing" className="experience-current-view-tag">{mrIntl("StudentsList.resuming")}</Tag>;
  } else if (item.currentView === "joined") {
    currentView = <Tag key={"current-view"} className="experience-current-view-tag">{mrIntl("StudentsList.joined")}</Tag>;
  } else {
    // currentView = <Tag key={"current-view"}>Not started</Tag>
    currentView = <Tag key={"current-view"} className="experience-current-view-tag">{mrIntl("StudentsList.joined")}</Tag>;
  }

  const totalResponseAttachments = responseAttachmentsModalVisible && 
    <ResponseAttachments
      previewType={'modal'}
      modalVisible={responseAttachmentsModalVisible}
      attachments_json={item.total_attachments_json}
      closeModal={() => {
        console.log("close modal called ==>")
        setResponseAttachmentsModalVisible(false)
      }}
    />
  
  const extra = [];
  enabledFeatures.live_attempt_status &&
    item.currentView === "startTest" &&
    item.active_segment_info &&
    extra.push(getActiveQuestionNo(item.active_segment_info));
  item.attachments_count &&
    item.attachments_count !== 0 &&
    extra.push(
      <Tooltip
        key={"total-uploads-count"}
        // title="Total uploads"
        title={mrIntl("StudentsList.total_uploads")}
        placement="top"
      >
        <Tag onClick={() => { setResponseAttachmentsModalVisible(true) }}>
          <FileOutlined /> {item.attachments_count}
        </Tag>
        {totalResponseAttachments}
      </Tooltip>
    );
  true &&
    !isMobile &&
    item.focus_lost_count &&
    item.focus_lost_count !== 0 &&
    extra.push(
      <Tooltip
        key={"focus-lost-count"}
        title= {mrIntl("StudentsList.focus_lost_count")}
        // title="Focus lost count"
        placement="top"
      >
        <Tag color="error">
          <SwapOutlined /> {item.focus_lost_count}
        </Tag>
      </Tooltip>
    );
  isShowTimer &&
    extra.push(
      <Timer
        key={"user-info-timer"}
        item={item}
        setIsTimeUp={setIsTimeUp}
        experienceDuration={experienceDuration}
      />
    );
  extra.push(<Spin spinning={teacherSubmitLoading == item.uid} size="small">{currentView}</Spin>);

  return extra;
};

// const AddTimeBtn = ({item, itemState}) => {
//   // let currentTime = Date.now()
//   const currentUser = useSelector(currentUserSelector());
//   let extraTime = typeof(item.extra_time) != "undefined" ? item.extra_time/60000 : null
//   return <Tooltip key={`add-time-btn-${item.uid}`} title="Total extra time in mins" placement="right">
//       <Select placeholder="Extra time" disabled={item.currentView === "submittedTest"} size="small" style={{"width":"70px"}} value={extraTime} onChange={(value) => {
//         let exTimeOffset = 0
//         if(item.currentView === "endTest"){
//           let totalTestTime = item.started_at + convertMs(experienceSettings.duration) + (item.extra_time || 0)
//           console.log("extra time 539 ", totalTestTime, item.ended_at, Date.now())
//           if(item.ended_at < totalTestTime){
//             // leave as 0
//             exTimeOffset = item.extra_time_offset || 0
//           }else{
//             exTimeOffset = Date.now() - totalTestTime
//           }
//           console.log("extra time ", exTimeOffset)
//         }else if(item.currentView === "startTest"){
//           // when coming back to test after adding time and then adding time was an issue.
//           exTimeOffset = item.extra_time_offset || 0
//         }
//         // updateRecord(item.uid, { extra_time: convertMs(value), extra_time_offset: exTimeOffset }, item);
//         updateRecord(item.uid, { extra_time: convertMs(value), extra_time_offset: exTimeOffset }, item, {
//           log: {
//             // logging: experienceItemConfig.log.logging,
//             logging: true,
//             msg: `Extra time set to ${value} mins by ${currentUser.name}`
//           }
//         });
//       }}>
//         <Option value="0" disabled={extraTime >= 0 ? true : false}>0</Option>
//         <Option value="2" disabled={extraTime >= 2 ? true : false}>2</Option>
//         <Option value="5" disabled={extraTime >= 5 ? true : false}>5</Option>
//         <Option value="10" disabled={extraTime >= 10 ? true : false}>10</Option>
//         <Option value="15" disabled={extraTime >= 15 ? true : false}>15</Option>
//         <Option value="20" disabled={extraTime >= 20 ? true : false}>20</Option>
//         <Option value="30" disabled={extraTime >= 30 ? true : false}>30</Option>
//         <Option value="40" disabled={extraTime >= 40 ? true : false}>40</Option>
//         <Option value="50" disabled={extraTime >= 50 ? true : false}>50</Option>
//         <Option value="60" disabled={extraTime >= 60 ? true : false}>60</Option>
//         <Option value="70" disabled={extraTime >= 70 ? true : false}>70</Option>
//         <Option value="80" disabled={extraTime >= 80 ? true : false}>80</Option>
//         <Option value="90" disabled={extraTime >= 90 ? true : false}>90</Option>
//         <Option value="100" disabled={extraTime >= 100 ? true : false}>100</Option>
//         <Option value="110" disabled={extraTime >= 110 ? true : false}>110</Option>
//         <Option value="120" disabled={extraTime >= 120 ? true : false}>120</Option>
//       </Select>
//     </Tooltip>
// }

// const ForceSubmit = ({item}) => {
// const ForceSubmit = ({itemUid, itemName}) => {
//   const confirm = e => {
//     e.stopPropagation()
//     if(!teacherSubmitLoading){
//       submitStudentResponse({userId: itemUid, experienceId: experience.id, name: itemName})
//     }
//     // message.error("Delete cancelled");
//   };
//   const cancel = e => {
//     e.stopPropagation()
//     // message.error("Delete cancelled");
//   };
//   return (
//     <Popconfirm
//       title="Confirm force submit?"
//       onConfirm={confirm}
//       onCancel={cancel}
//       okText="Yes"
//       cancelText="No"
//     >
//       <Tooltip title="Force submit" placement="top">
//         <Button shape="round" disabled={teacherSubmitLoading} type="danger" icon={<SendOutlined />} onClick={(e) => {e.stopPropagation(); console.log(e)}}></Button>
//       </Tooltip>
//     </Popconfirm>
//   )
// }

const AllowResumeBtn = (props) => {
  const { item, updateRecord, itemState } = props;
  const mrIntl = useTranslate();

  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const newJoinViewWithDynamicConfigEnabled = enabledFeatures.new_join_view_with_dynamic_config
  const requestResumeSessionId = 
    newJoinViewWithDynamicConfigEnabled 
      ? item.request_resume_session_id 
      : item.session_id

  return (
    <Space>
      <Tooltip title={mrIntl("StudentsList.maintain_test_integrity_msg")} placement="bottom">
        <Button
          shape="round"
          type="primary"
          disabled={itemState?.state === "offline"}
          onClick={(e) => {
            e.stopPropagation();
            console.log(e);
            updateRecord(item.uid, { request_resume: "allow", session_id: requestResumeSessionId }, item,
            {
              log: {
                // logging: experienceItemConfig.log.logging,
                logging: true,
                action: "resume_allowed_teacher",
                user_id: item.user_id,
              },
            });
          }}
        >
          {/* {item.session_id === requestResumeSessionId ? mrIntl("StudentsList.allow") : mrIntl("StudentsList.allow_switch_session")} */}
          {mrIntl("StudentsList.allow")}
        </Button>
      </Tooltip>
      <Tooltip title={mrIntl("StudentsList.decline")} placement="bottom">
        <Button
          shape="round"
          danger
          onClick={(e) => {
            e.stopPropagation();
            console.log(e);
            updateRecord(item.uid, { request_resume: "decline", request_resume_session_id: item.session_id }, item,
            {
              log: {
                // logging: experienceItemConfig.log.logging,
                logging: true,
                action: "resume_declined_teacher",
                user_id: item.user_id,
              },
            });
          }}
        >
          {/* {item.session_id === requestResumeSessionId ? mrIntl("StudentsList.decline") : mrIntl("StudentsList.decline_switch")} */}
          {mrIntl("StudentsList.decline")}
        </Button>
      </Tooltip>
    </Space>
  );
};

const BulkChat = (props) => {
  const { Search } = Input;
  const [input, setInput] = useState(null);
  const mrIntl  = useTranslate();

  const onClick = (e) => {
    if (!input || input === "") {
      return;
    }
    props.onClick && props.onClick(input);
    setInput(null);
  };
  return (
    <div className={"bulk-chat-action"} style={{ width: "500px" }}>
      {/* <Input value={input} onChange={(e) => {
      setInput(e.target.value);
    }}/>
    <Button onClick={onClick}>Send</Button> */}
      <Search
        placeholder={`${mrIntl("StudentsList.write_message")}...`}
        // allowClear
        enterButton={mrIntl("StudentsList.send")}
        size="middle"
        onSearch={onClick}
        value={input}
        disabled={props.bulkActionLoading}
        loading={props.bulkActionLoading}
        onChange={(e) => {
          setInput(e.target.value);
        }}
      />
    </div>
  );
};

const HeaderTitle = ({ data, enabledFeatures, createdAt }) => {
  const mrIntl = useTranslate()
  let createdAtDate = new Date(createdAt);
  let dateToCompare = new Date("01/04/2023")
  let shouldShowAppVersionDistribution = import.meta.env.VITE_MODE === "production" ? createdAtDate >= dateToCompare : true;
  
  return (
    <>
      <Space>
        <span style={{ marginLeft: "10px" }}>
          {mrIntl("CommonText.students")}
          {shouldShowAppVersionDistribution && <AppVersionDistributionModal data={data} />}
        </span>
      </Space>
    </>
  );
};

const StudentsList = (props) => {
  const {
    dataWithoutLocalFilters,
    data,
    experience,
    updateRecord,
    extraProps,
    // sendMessage,
    currentUser,
    // toggleBatchSelectionMode,
    toggleShowSelectBoxes,
    // fetchChat,
    setSelection,
    // batchAction,
    selected,
    batchSelectionMode,
    showSelectBoxes,
    allSelected,
    totalSelections,
    // item,
    // presenceData,
    submitStudentResponse,
    teacherSubmitLoading,
    filterParams,
    selectAll,
    clearSelection,
  } = props;

  console.log(
    "socketIO - Dashboard presenceData experience",
    props,
    data,
    experience,
    extraProps
  );
  const [selectedStudentId, setSelectedStudentId] = useState(0);

  // const [allowBulkSelect, setAllowBulkSelect] = useState(false);
  const [bulkActionLoading, setBulkActionLoading] = useState(false);

  const [showTable, setShowTable] = useState("started");
  const [singleCard, setSingleCard] = useState(false);
  // const [spanWidth, setSpanWidth] = useState(false);
  // const [multiCard, setMultiCard] = useState(false);
  const [selectElement, setSelectedElement] = useState("");
  const [activeModal, setActiveModal] = useState(null);

  // const [selectedRow, setSelectedRow] = useState([]);
  // const [selectedKey, setSelectedKey] = useState([]);
  // const [isLogModalOpen, setIsLogModalOpen] = useState(false);
  // const [logParams, setLogParams] = useState({});
  // const [selectedUserName, setSelectedUserName] = useState(null);
  const dispatch = useDispatch();
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const userIp = useSelector(getUserIpSelector())
  const experiencePaused = useSelector(experiencePausedSelector)
  console.log("userIp student list ==>", userIp)


  const [headerActions, setHeaderActions] = useState({
    announcement: false,
    addTime: false,
  })
  // const sortType = monitorConfig.sortType || "smart_sort"
  // console.log('props in student list==>', sortType);


  // useEffect(() => {
  //   if (document) {
  //     var sortDropdown = document.querySelector('.sort-dropdown')
  //     console.log('sortdropdown==>', sortDropdown);
  //     if (sortDropdown) {
  //       sortDropdown.parentElement.classList.add('sort-element-parent');
  //     }
  //   }
  // }, [])

  const appRegion = useSelector(appRegionSelector());
  const isChina = appRegion == "china" ? true : false;
  const isMobile = checkMob();
  const mrIntl = useTranslate()  
  // let nameClick = (item) => {
  //   console.log("chat item", item);
  // };
  // const [isTimeUp, setIsTimeUp] = useState(false)
  // Todo: Prob here is setIsTimeUp fr anyone wil rerender the list? Fix this

  // const { Option } = Select;

  const notYetStartedTableData = [];
  if (extraProps) {
    for (let index = 0; index < extraProps.length; index++) {
      const current = extraProps[index];
      let objToPush = {};
      objToPush.id = current.id;
      objToPush.name = current.first_name + current.last_name;
      objToPush.s_code = current.s_code;
      notYetStartedTableData.push(objToPush);
    }
  }
  const notYetStartedColumns = [
    {
      title: mrIntl("StudentsList.id"),
      dataIndex: "id",
      key: "id",
      // width: 200,
    },
    {
      title: mrIntl("StudentsList.name"),
      dataIndex: "name",
      key: "name",
      // width: 200,
    },
    {
      title: mrIntl("StudentsList.student_code"),
      dataIndex: "s_code",
      key: "s_code",
      // width: 200,
    },
  ];
  // const closeCard = () => {
  //   setSelectedRow([]);
  //   setSpanWidth(false);
  //   setSingleCard(false);
  //   setMultiCard(false);
  //   setSelection({});
  //   console.log("selectedrow==>", selectedRow);
  // };

  // const onSelectChange = (selectedRowKeys, rows) => {
  //   setSelectedKey(selectedRowKeys);
  // };

  // const onSelect = (record, selected, selectedRows) => {
  //   console.log("selectedkey line 274", record, selected, selectedRows);
  //   setMultiCard(true);
  //   setSpanWidth(true);
  //   setSelectedRow(selectedRows);
  //   setSelection(record.id, selected);
  //   if (selectedRows.length === 0) {
  //     setMultiCard(false);
  //     setSpanWidth(false);
  //     setSelectedRow([]);
  //     setSelection({});
  //   }
  // };

  // const rowSelection = {
  //   type: "checkbox",
  //   selectedRowKeys: selectedKey,
  //   onChange: onSelectChange,
  //   onSelect: onSelect,
  //   // onSelectAll: onSelectAll,
  //   hideSelectAll: true,
  // };

  let finalRender = null;
  // const mrIntl = useTranslate();

  let experienceSettings = experience.settings;
  const chatDisabled = experienceSettings?.chat_disabled;

    let announcementTooltipTitle =   chatDisabled ? mrIntl("StudentsList.announcements_has_been_disabled_for_this_assessment") : (data.length === 0 ? mrIntl("StudentsList.no_students_have_joined_yet") : "")

  console.log("data list", data);

  // {item.attachments_count != 0 && <Tooltip title="Total uploads" placement="bottom"><Tag>{item.attachments_count}</Tag></Tooltip>}
  //         {(item.currentView == "startTest" || item.currentView == "endTest") && experienceSettings.isTimed && experienceSettings.duration > 0 && timer}
  //         {currentView}

  // const title = `${status} ${item.name}`

  // const StudentsListComponent = ({item}) => {

  // extraActions={<ExtraActions item={item} isTimeUp={isTimeUp}/>}
  // const sortMenu = (
  //   <Menu>
  //     <Menu.Item>
  //       <Tooltip title="Auto Sort">
  //         <Button
  //           type="text"
  //           icon={<SortAscendingOutlined />}
  //           // onClick={() => localSort('ascending')}
  //           onClick={() => setSortType("auto_sort")}
  //         >
  //           Auto sort
  //         </Button>
  //       </Tooltip>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <Tooltip title="Sort by ascending">
  //         <Button
  //           type="text"
  //           icon={<SortAscendingOutlined />}
  //           // onClick={() => localSort('ascending')}
  //           onClick={() => setSortType("ascending")}
  //         >
  //           Sort by name
  //         </Button>
  //       </Tooltip>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <Tooltip title="Sort by descending">
  //         <Button
  //           type="text"
  //           icon={<SortDescendingOutlined />}
  //           // onClick={() => localSort('descending')}
  //           onClick={() => setSortType("descending")}
  //         >
  //           Sort by name
  //         </Button>
  //       </Tooltip>
  //     </Menu.Item>
  //     {/* <Menu.Item>
  //       <Tooltip title="Sort by chat">
  //         <Button
  //           type="text"
  //           icon={<WechatOutlined />}
  //           onClick={() => localSort('chat')}
  //         >Sort by chat</Button>
  //       </Tooltip>
  //     </Menu.Item> */}
  //   </Menu>
  // );

  let bulkChatInputRender = (
    <BulkChat
      type="text"
      bulkActionLoading={bulkActionLoading}
      onClick={(value) => {
        setBulkActionLoading(true);
        
        const logObj = buildLogObject(currentUser, 
          {
            action: "announcement_made",
            current_value: {
              announcement: value
            },
            user_ids: getKeysFromObject(selected, { parseInt: true }),
            user_names: getKeysFromObject(selected, { value: true }),
          }, {
            orgId: currentUser.org_id,
            experienceId: experience.id,
            userIp: userIp,
          })
          
        realtimeBatchActions(
          [
            {
              // experienceId: experience.id,
              basePath: `/experiences/${experience.id}/messages`,
              docIds: selected,
              tableName: "messages",
              uuids: Object.keys(selected).length > 0 ? Object.keys(selected).map((userId) => { return `eId${experience.id}_uId${userId}_____${getUniqueId(10)}_${Date.now()}`}) : [],
              object: {
                // msg_type: "text",
                msg_type: "announcement",
                msg: value,
                read_by: [],
                read_by_names: [],
                sender_role: "teacher",
                // created_at: Date.now(),
                updated_at: Date.now(),
                created_at: Date.now(),
                chat_type: "individual",
                // chat_id: finalConfig.chat_id,
                sender_name: currentUser.name,
                // receiver_ids: finalConfig.receiver_ids,
                sender_id: currentUser.id,
                experience_id: experience.id
              },
              options: { createNewDocId: true, newDocKey: "chat_id" },
              // actionType: "set",
            },
            {
              // experienceId: experience.id,
              basePath: `/experiences/${experience.id}/user_info`,
              docIds: selected,
              tableName: "user_info",
              uuids: Object.keys(selected).length > 0 ? Object.keys(selected).map((userId) => { return `eId${experience.id}_uId${userId}`}) : [], // need this format for each student - eId${experience.id}_uId${student.uid} - in an array
              object: {
                has_student_read: false,
                student_unread_count: isChina ? 1 : firestoreFieldValue.increment(1)
              },
              options: {
                merge: true
              },
              // actionType: "set",
            },
            {
              // experienceId: experience.id,
              basePath: `/experiences/${experience.id}/logs`,
              docIds: [logObj.log_id],
              tableName: "logs",
              uuids: [logObj.log_id],
              object: logObj,
              options: {
                // createNewDocId: true, // TODO: causing issues
                // newDocKey: "by_user_id",
                parseInt: true,
              },
              // actionType: "set",
            },
          ],
          {
            successCallback: () => {
              console.log(
                "successcallback 788",
                Object.keys(selected).length,
                totalSelections
              );
              setHeaderActions({
                ...headerActions,
                announcement: false,
                addTime: false,
              });
              toggleShowSelectBoxes();
              clearSelection();
              // message.success(
              //   `Announcement made successfully to ${totalSelections} student${
              //     totalSelections > 1 ? "s" : ""
              //   }!`
              // );
              // message.success( totalSelections > 1 ?
              //   `Announcement made successfully to ${totalSelections} students` : // need to add
              //   `Announcement made successfully to ${totalSelections} student`
              // )
              message.success( totalSelections > 1 ?
                mrIntl("StudentsList.announcement_made_successfully_for_students", {totalSelections: totalSelections}) : // need to add
                mrIntl("StudentsList.announcement_made_successfully_for_student", {totalSelections: totalSelections})
              )
              setBulkActionLoading(false);
            },
            errorCallback: (error) => {
              console.log("errorcallback 791", error);
              message.error(mrIntl("StudentsList.something_went_wrong_in_making_announcement"));
              setBulkActionLoading(false);
            },
            appRegion: appRegion,
          }
        );
      }}
    />
  );
  let monitorListHeaderActions = [];
  showSelectBoxes &&
    totalSelections &&
    monitorListHeaderActions.push(`${totalSelections} ${mrIntl("StudentsList.selected")}`);
  batchSelectionMode &&
    headerActions.announcement &&
    monitorListHeaderActions.push(bulkChatInputRender);
  // showSelectBoxes &&
  //   !batchSelectionMode &&
  //   monitorListHeaderActions.push("Select students");

  // batchSelectionMode &&
    headerActions.addTime &&
    monitorListHeaderActions.push(
      <BulkAddTime
        bulkActionLoading={bulkActionLoading}
        setBulkActionLoading={setBulkActionLoading}
        selected={selected}
        experience={experience}
        totalSelections={totalSelections}
        toggleShowSelectBoxes={toggleShowSelectBoxes}
        clearSelection={clearSelection}
        data={data}
        setHeaderActions={setHeaderActions}
      />
    );

    if(!showSelectBoxes && enabledFeatures.pause_unpause_test) {
      monitorListHeaderActions.push(
        <PauseUnpauseButton
          teacherSubmitLoading={teacherSubmitLoading}
          updateRecord={updateRecord}
          data={data}
        />
      )
    }

    (!showSelectBoxes &&
    monitorListHeaderActions.push(
      <Tooltip title={announcementTooltipTitle}>
        <Button
          disabled={data.length === 0 || chatDisabled}
          type={"primary"}
          ghost
          onClick={() => {
            setHeaderActions({
              ...headerActions,
              announcement: true,
            });
            toggleShowSelectBoxes();
            selectAll({ keyName: "uid", valueKey: "name" });
          }}
          icon={<NotificationOutlined />}
        >
          {/* Make announcement */}
          {/* <MrTranslate id= {"StudentsList.make_announcement"}/> */}
          {mrIntl("StudentsList.make_announcement")}
        </Button>
      </Tooltip>
    ));

  (!showSelectBoxes &&
    experienceSettings.is_timed &&
    monitorListHeaderActions.push(
      <Tooltip title={
        data.length === 0 ?
          mrIntl("StudentsList.no_students_have_joined_yet") :
          experiencePaused ? mrIntl("StudentsList.test_is_paused") : ""
      }>
        <Button
          disabled={data.length === 0 || experiencePaused}
          type={"primary"}
          ghost
          onClick={() => {
            setHeaderActions({
              ...headerActions,
              addTime: true,
            });
            toggleShowSelectBoxes();
            selectAll({ keyName: "uid", valueKey: "name", onlineOnly: true });
          }}
          icon={<ClockCircleOutlined />}
        >
          {mrIntl("CommonText.extra_time")}
        </Button>
      </Tooltip>
    ));

  showSelectBoxes &&
    monitorListHeaderActions.push(
      <Button
        disabled={bulkActionLoading}
        type={"danger"}
        loading={headerActions.addTime && bulkActionLoading}
        onClick={() => {
          setHeaderActions({
            ...headerActions,
            announcement: false,
            addTime: false,
          });
          toggleShowSelectBoxes();
          clearSelection();
        }}
      >
        {mrIntl("CommonText.cancel")}
      </Button>
    );
    // !showSelectBoxes &&
    //   monitorListHeaderActions.push(
    //     <Dropdown overlay={sortMenu} className="sort-dropdown">
    //       <Button type="text" icon={<MoreOutlined />} />
    //     </Dropdown>
    //   );
  
  const listHeader = (
    <Space
      align="baseline"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      className="monitor-header-actions"
    >
      {showSelectBoxes && (
        <Checkbox
          checked={allSelected}
          disabled={bulkActionLoading}
          onChange={(e) => {
            // TODO
            selectAll({ unselect: !e.target.checked, keyName: "uid", onlineOnly: headerActions.addTime ? true : false });
          }}
        >
          {mrIntl("StudentsList.select_all")}
        </Checkbox>
      )}
      {!showSelectBoxes && <HeaderTitle createdAt={experience.created_at} data={data} enabledFeatures={enabledFeatures}/>}
      
      <Space>{monitorListHeaderActions}</Space>
    </Space>
  );
 
  const StudentsListComponentProps = {
    data,
    // presenceData,
    experience,
    currentUser,
    extraActions,
    setSelectedStudentId,
    selectedStudentId,
    setSingleCard,
    setSelectedElement,
    setSelection,
    selected,
    experienceSettings,
    updateRecord,
    isChina,
    submitStudentResponse,
    teacherSubmitLoading,
    batchSelectionMode,
    showSelectBoxes,
    allSelected,
    totalSelections,
    bulkActionLoading,
    setActiveModal,
  };

  console.log("Student list data ==>", data);

  finalRender = (
    <React.Fragment>
      <StatisticsData
        listData1={data}
        listData={dataWithoutLocalFilters}
        experience={experience}
        filterParams={filterParams}
      />
      {/* <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            className="custom-main-list monitor-list"
            height={500}
            // height={height}
            itemCount={data.length}
            itemSize={80}
            // width={1000}
            width={width}
            itemKey={(index) => {
              // return getUniqueId(10)
              const it = data[index]
              if(it){
                return `react-window-list-item-${it.uid}`
              }else{
                return `react-window-list-item-${index}`
              }
            }}
          >
            {({index, style}) => {

              return <StudentsListComponent index={index} style={style} {...studentListComponentConfig}/>
            }}
            
            
          </FixedSizeList>
      isMobile  )}
      </AutoSizer> */}

      <List
        className={`custom-main-list monitor-list ${isMobile ? "mobile" : ""}`}
        // loading={initLoading}
        itemLayout="horizontal"
        dataSource={data}
        header={isMobile ? "" : listHeader}
        renderItem={(item, i) => (
          // <StudentsListComponent key={`studentItem-${item.uid}`} item={item}/>
          <StudentsListComponent
            headerActions={headerActions}
            showSelectBoxes={showSelectBoxes}
            key={`studentItem-${item.uid}`}
            item={item}
            {...StudentsListComponentProps}
          />
        )}
      />
      
    </React.Fragment>
  );
  return (
    <>
      {showTable === "not_started" ? (
        <Row>
          <Col>
            <Table
              // tableLayout={"auto"}
              dataSource={notYetStartedTableData}
              columns={notYetStartedColumns}
              tableStatus={"not_started"}
              scroll={{
                y: 500,
                // x: "100vw",
              }}
            />
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col span={24}>{showTable === "started" ? finalRender : null}</Col>
      </Row>

      {/* <Example /> */}

      <TeacherDashboardChat
        key={`teacher-dashboard-chat`}
        experience={experience}
        allUserInfo={data}
        // presenceData={presenceData}
      />
      {activeModal === "quit_password" && (
        <QuitPassword setActiveModal={setActiveModal}/>
      )}
    </>
  );
};

export default StudentsList;
