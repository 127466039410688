

import React, { useEffect } from 'react';
import { Result, Alert, Space, Button } from 'antd';
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { WarningFilled, CheckCircleFilled } from "@ant-design/icons";
import ViewCard1 from '../ViewComponents/ViewCard1';
import ViewStatus from '../ViewComponents/ViewStatus';
import "./LocalStorageSyncView.scss";
import { actions as firestoreInteractionActions } from '../../FirestoreInteractions/redux';
import { useDispatch, useSelector } from 'react-redux';
import { syncOfflineResponsesLoadingSelector } from '../../FirestoreInteractions/selector';

const LocalStorageSyncView = (props) => {
  const { experience } = props;

  const mrIntl = useTranslate();
  const dispatch = useDispatch();
  const syncOfflineResponsesLoading = useSelector(syncOfflineResponsesLoadingSelector);
  console.log("syncOfflineResponsesLoading", syncOfflineResponsesLoading);

  let subTitle = []
  let extra = []

  const showConfig = {
    showProgress: false,
    showInstructions: false,
    showRightAssessmentDetailsCard: false,
    titleColorClassName: 'bg-red'
  }
  
  subTitle.push(
    <Alert
      message= {"To do thorough local database sync, please click on the button below."}
      type="error"
      showIcon
    />
  )

  extra.push(
    <Button 
      loading={syncOfflineResponsesLoading}
      type="primary" 
      danger
      onClick={() => {
        dispatch(firestoreInteractionActions.syncOfflineResponses());
      }}
    > 
      Sync
    </Button>
  );

  return <React.Fragment>
    <div className='offline-sync-view'>
      <ViewStatus
        statusIcon={<WarningFilled />}
        statusText={subTitle}
        statusTextType="warning"
        showConfig={showConfig}
      />

      <ViewCard1
        experience={experience}
        footerCenterComponent={extra}
        showConfig={showConfig}
      />
    </div>
  </React.Fragment>;
}

export default LocalStorageSyncView;
