import React, { useEffect, useState } from "react";
import MrFirebase from "/src/lib/MrFirebase/MrFirebase";
import { firestoreDB } from "/src/config/initializers";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { Card, Col, Progress, Row, Space, Statistic } from "antd";
import { isEmpty } from "lodash";
import AIUsageList from "./AIUsageList";
import AIUsageFilter from "./AIUsageFilter";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { isFirebaseAuthenticatedSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { calculatePercentage, getUTCDate } from "/src/lib/utils/helperMethods";

const AIUsage = (props) => {
  console.log("AIUsage props main component ==>", props);
  const { orgId, aiCreditsUSD } = props;
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  
  const defaultLogParams = {
    'created_at':  [">=", new Date (getUTCDate({ type: "startOf", daysDiff: -30 }).valueOf()), "<=", new Date (getUTCDate({ type: "endOf" }).valueOf())],
  }
  const [totalUsage, setTotalUsage] = useState({});
  const [logParams, setLogParams] = useState({...defaultLogParams});
  const currentUser = useSelector(currentUserSelector());
  const showAiDataForSuperadmin = currentUser.role === "superadmin"
  const isFirebaseAuthenticated = useSelector(isFirebaseAuthenticatedSelector);
  useEffect(() => {
    dispatch(
      firestoreInteractionActions.getOrgAiUsageInfo(
        {
          orgId: orgId,
        },
        {
          successCallback: (data) => {
            setTotalUsage(data.stats || {total_cost: 0});
          },
        }
      )
    );
  }, []);
  const extraProps = {
    totalUsage,
    orgId: orgId,
  }
  const aiUsageFilter =
    <AIUsageFilter
      orgId={orgId}
      logParams={logParams}
      setLogParams={setLogParams}
    />
  const FirebaseFilter = (props) => {
    return null;
  };

  const actionWiseUsage = totalUsage && totalUsage.action_wise
  const totalUsagePercentage = calculatePercentage(totalUsage.total_cost, aiCreditsUSD)
  const twoColors = {
    '0%': '#8E2DE2',
    '100%': '#4A00E0',
  };

  let dashboard = !isEmpty(totalUsage) && <>
    <Row>
        <Col span={24}>
          <h3>Total usage</h3>
        </Col>
        <Col span={12}>
          <Card bordered={true} className="m-b-10">
            <Space>
              <Progress percent={totalUsagePercentage} strokeColor={twoColors} showInfo={false} style={{minWidth: "300px"}}/>
              <h3>${totalUsage.total_cost.toFixed(0)} / <b>${aiCreditsUSD}</b></h3>
            </Space>
          </Card>
        </Col>
        {showAiDataForSuperadmin && (
          <Col span={6}>
            <Card bordered={true} className="m-b-10 m-l-10">
              <Statistic
                title="Total Tokens Used"
                value={totalUsage.total_tokens_used}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                  textAlign: "center",
                }}
              />
            </Card>
          </Col>
        )}
    </Row>

    {!isEmpty(totalUsage.action_wise) && <Row>
      <Col span={24}>
        <h3>Action wise usage</h3>
      </Col>
      <Col span={6}>
        <Card bordered={true} className="m-b-10">
          <Statistic
            title={mrIntl("AIUsage.action_chat")}
            value={actionWiseUsage && actionWiseUsage.chat && actionWiseUsage.chat.total_cost}
            prefix="$"
            precision={2}
            valueStyle={{
              color: "#3f8600",
              textAlign: "center",
            }}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card bordered={true} className="m-b-10 m-l-10">
          <Statistic
            title={mrIntl("AIUsage.action_generate_questions")}
            value={actionWiseUsage && actionWiseUsage.generate_segments && actionWiseUsage.generate_segments.total_cost}
            prefix="$"
            precision={2}
            valueStyle={{
              color: "#3f8600",
              textAlign: "center",
            }}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card bordered={true} className="m-b-10 m-l-10">
          <Statistic
            title={mrIntl("AIUsage.action_generate_solutions")}
            value={actionWiseUsage && actionWiseUsage.generate_solutions && actionWiseUsage.generate_solutions.total_cost}
            prefix="$"
            precision={2}
            valueStyle={{
              color: "#3f8600",
              textAlign: "center",
            }}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card bordered={true} className="m-b-10 m-l-10">
          <Statistic
            title={mrIntl("AIUsage.action_grading")}
            value={ actionWiseUsage && actionWiseUsage.grading && actionWiseUsage.grading.total_cost}
            prefix="$"
            precision={2}
            valueStyle={{
              color: "#3f8600",
              textAlign: "center",
            }}
          />
        </Card>
      </Col>
    </Row>}
    {showAiDataForSuperadmin && (
      <Row>
          <Col span={6}>
            <Card bordered={true} className="m-b-10">
              <Statistic
                title={mrIntl("AIUsage.action_chat")}
                value={actionWiseUsage && actionWiseUsage.chat && actionWiseUsage.chat.total_tokens_used}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                  textAlign: "center",
                }}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={true} className="m-b-10 m-l-10">
              <Statistic
                title={mrIntl("AIUsage.action_generate_questions")}
                value={actionWiseUsage && actionWiseUsage.generate_segments && actionWiseUsage.generate_segments.total_tokens_used}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                  textAlign: "center",
                }}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={true} className="m-b-10 m-l-10">
              <Statistic
                title={mrIntl("AIUsage.action_generate_solutions")}
                value={actionWiseUsage && actionWiseUsage.generate_solutions && actionWiseUsage.generate_solutions.total_tokens_used}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                  textAlign: "center",
                }}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={true} className="m-b-10 m-l-10">
              <Statistic
                title={mrIntl("AIUsage.action_grading")}
                value={actionWiseUsage && actionWiseUsage.grading && actionWiseUsage.grading.total_tokens_used}
                precision={0}
                valueStyle={{
                  color: "#3f8600",
                  textAlign: "center",
                }}
              />
            </Card>
          </Col>
      </Row>
    )}
    {showAiDataForSuperadmin && <><Row>
        <Col span={24}>
          <h3> {showAiDataForSuperadmin ? "Daily usage and logs" : "Daily usage"} </h3>
        </Col>
        <Col span={24}>
          {aiUsageFilter}
        </Col>
      </Row>
      {(isFirebaseAuthenticated &&
        <MrFirebase
          firestoreDB={firestoreDB}
          mode={"not-realtime"}
          // default mode is realtime
          filter={FirebaseFilter}
          sort={{
            created_at: "desc",
          }}
          list={AIUsageList}
          currentUser={currentUser}
          params={logParams}
          extraProps={extraProps}
          collectionPath={`orgs/${orgId}/products/ai/logs`}
          tableName={"logs"}
        />
      )}
      </>
    }
  </>

  let finalRender = aiCreditsUSD ? dashboard : "AI Credits not available yet"

  return finalRender
};

export default AIUsage;
