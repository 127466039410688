import React from "react";
import PropTypes from 'prop-types';
import { List, Card, Table } from "antd";
// import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import {Link, useRouteMatch} from "react-router-dom";


const ExperienceListItem = (props) => {
  console.log("EXP LIST props", props.dataSource);
  const { item, edited, deleted, actionBtns, dataSource } = props;

  const {path, url} = useRouteMatch();
  console.log("exp list item url", url);
  return (
    <>
      <List.Item>
      <Card title={item.key}>
        <Link to={`/tests/${item.id}`}> {item.id} - {item.name} - test </Link>  
         {/* {JSON.stringify(dataSource)} */}
        {actionBtns}
      </Card>
    </List.Item>
    </>
    
  );
};
ExperienceListItem.defaultProps = {};

ExperienceListItem.propTypes = {};

export default ExperienceListItem;
