import { Button, Col, Space } from 'antd';
import React from 'react'

function IcpText({buttonStyles}) {
  return (
    <>
      {import.meta.env.VITE_REGION === "china" ? (
        <Col span={24}>
          <Space>
            <Button
              style={buttonStyles}
              type="link"
              href="https://beian.miit.gov.cn/"
              target="_blank"
            >
              AssessPrep 沪ICP备17051512号
            </Button>
          </Space>
        </Col>
      ) : (
        ""
      )}
    </>
  );
}

export default IcpText;