import React from "react";
import { Space } from "antd";
import { RocketOutlined } from "@ant-design/icons";
import CountDown from "react-countdown";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const DemoResettingComponent = (props) => {
  console.log("DemoResettingComponent props", props);
  const { history } = props;
  const mrIntl = useTranslate()

  // let finalRender = []

  const switchToApp = () => {
    history.push("/");
  };

  if (import.meta.env.VITE_MODE !== "demo") {
    switchToApp();
  }

  let currentTime = new Date();
  let secondsLeft = 60;
  if (
    currentTime.getUTCHours() % 2 === 0 &&
    currentTime.getUTCMinutes() === 0
  ) {
    secondsLeft = 60 - currentTime.getUTCSeconds();
  }

  return (
    <>
      <Space
        className="demo-reset-info-box"
        direction="vertical"
        align="center"
      >
        {/* <h2>Demo is resetting</h2> */}
        <h2>{mrIntl("DemoResettingComponent.demo_is_resetting")}</h2>
        {/* <Spinner /> */}
        <RocketOutlined style={{ fontSize: "30px" }} />
        {/* <UndoOutlined spin="true" style={{"fontSize":"20px"}}/> */}
        <h3>
          {mrIntl("DemoResettingComponent.we_will_be_back_in")}{" "}
          <span>
            <CountDown
              date={Date.now() + secondsLeft * 1000}
              daysInHours={true}
              onComplete={() => switchToApp()}
            />
          </span>
        </h3>
      </Space>
    </>
  );
};

DemoResettingComponent.defaultProps = {};

DemoResettingComponent.propTypes = {};

export default DemoResettingComponent;
