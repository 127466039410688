import React, { useEffect } from "react";
import {
  Button,
  Tag,
  Space,
  Alert,
  Statistic,
  Col,
  Result,
} from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import {} from "@ant-design/icons";
import QuitBtn from "/src/components/UI/QuitBtn";
import {
  attachmentsCountSelector,
  isTimeUpSelector,
  requestResumeSelector,
} from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { useSelector } from "react-redux";
import CountDownTimer from "/src/views/Experiences/ExperienceShow/Components/CountDownTimer";
import { appRegionSelector, currentUserSelector } from "/src/views/Auth/Login/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { writeToClipboardPolyfill } from "/src/lib/utils/helperMethods";
import { experienceViewModeSelector } from "/src/views/Experiences/selector";
import { checkInternetConnectivity } from "/src/lib/CheckInternetConnectivity/CheckInternetConnectivity";

const ResumeViewOld = (props) => {
  const {
    experienceProps,
    videoRender,
    setSwitchFromStartToResumeView,
    // isTimeUp,
    progressBar,
    endButton,
    // setIsTimeUp,
    timer,
    experienceDuration
  } = props;

  const { experience, embedded, setUserInfo } = experienceProps;

  const appRegion = useSelector(appRegionSelector());
  const isTimeUp = useSelector(isTimeUpSelector);
  const attachmentsCount = useSelector(attachmentsCountSelector);
  const userInfoFromReduxrequestResume = useSelector(requestResumeSelector);
  const currentUser = useSelector(currentUserSelector());
  let experienceViewMode = useSelector(experienceViewModeSelector());
  const mrIntl = useTranslate();
  const experienceSettings = experience.settings || {};

  // Resume test view - allow-decline logic
  // TODO: move this to ResumeTestView component
  useEffect(() => {
    console.log(
      "userInfoFromReduxrequestResume",
      userInfoFromReduxrequestResume
    );
    if (userInfoFromReduxrequestResume == "allow") {
      setSwitchFromStartToResumeView(false);
      setUserInfo(
        {
          currentView: "startTest",
          request_resume: "initial",
        },
        {
          log: {
            logging: true,
            action: "resume_allowed_student",
            current_value: currentUser.name,
            action_by: `${currentUser.name} (${currentUser.role})`,
          },
        }
      );
      message.success(mrIntl("ResumeView.request_resume_allowed_msg"));
    } else if (userInfoFromReduxrequestResume == "decline") {
      setUserInfo(
        {
          request_resume: "initial",
        },
        // {
        //   log: {
        //     logging: true,
        //     action: "resume_declined",
        //     currrent_value: currentUser.name,
        //     action_by: `${currentUser.name} (${currentUser.role})`,
        //   },
        // }
      );
      message.error(mrIntl("ResumeView.request_to_resume_declined_msg"));
    }
  }, [userInfoFromReduxrequestResume]);
  // Resume test view end

  let testResumeSubtitle = (
    <div>
      <Alert
        className="instructions-text"
        message={mrIntl("ResumeView.request_your_teacher_to_let_you_resume_msg")}
        type="warning"
        showIcon
      />

      <Space direction="vertical" style={{ marginTop: "20px" }}>
        <div>{progressBar}</div>

        {experienceSettings.is_timed &&
          experienceSettings.duration > 0 &&
          isTimeUp && <Tag color="red"><MrTranslate id={"ResumeView.time_up"}/></Tag>}
        {experienceSettings.is_timed && experienceSettings.duration > 0 && (
          timer
        )}

        {experienceSettings.allow_student_upload_files && (
          <Statistic title={mrIntl("ResumeView.total_files_uploaded")} value={attachmentsCount} />
        )}
      </Space>

      {/* {!embedded && experienceSettings.enable_ap_video_monitoring && (
        <Col span={20} offset={2} className="m-t-15">
          <div style={{width: "100%", height: '350px'}}>
            {videoRender}
          </div>
        </Col>
      )} */}
    </div>
  );

  let requestResumeBtn = (
    <Button
      type="primary"
      disabled={userInfoFromReduxrequestResume == "request"}
      loading={userInfoFromReduxrequestResume == "request"}
      onClick={() => {
        !embedded && experienceViewMode === "apTakeTest" && writeToClipboardPolyfill("");
        checkInternetConnectivity(appRegion, (internetStatus) => {
          if (internetStatus === "online") {
            setUserInfo(
              {
                request_resume: "request",
                needs_attention_at: Date.now()
              },
              {
                log: {
                  // logging: experienceProps.log.logging,
                  logging: true,
                  // msg: `Requested to resume`,
                  action: "resume_requested",
                  // msg: mrIntl("ResumeView.requested_to_resume"),
                },
              }
            );
          } 
          // else {
          //   message.error("Your internet connection not stable. Please check your internet connection and try again.")
          // }
        }, mrIntl)
        
      }}
    >
      <MrTranslate id={"ResumeView.request_resume"}/>
    </Button>
  );

  let cancelRequestResumeBtn = (
    <Button
      danger
      onClick={() => {
        !embedded &&
            experienceViewMode === "apTakeTest" &&
            writeToClipboardPolyfill("");
        setUserInfo(
          {
            request_resume: "initial",
          },
          {
            log: {
              logging: true,
              action: "resume_request_cancelled",
            },
          }
        );
      }}
    >
      <MrTranslate id={"ResumeView.cancel_request"}/>
    </Button>
  );

  const extra = [];

  !isTimeUp && extra.push(requestResumeBtn);
  !isTimeUp && userInfoFromReduxrequestResume == "request" && extra.push(cancelRequestResumeBtn);
  isTimeUp && extra.push(endButton);

  extra.push(<QuitBtn key="resume-view-quit-button" withLog={true} action={"quit_app_before_resuming"} />);

  let finalRender = (
    <React.Fragment>
      <Result
        className="end-test-result"
        status="info"
        // icon={<EditOutlined />}
        title={experience.name}
        subTitle={testResumeSubtitle}
        extra={extra}
      />

      {/* {focusLostModal} */}
      {/* <SessionActiveModal sessionModalConfig={sessionModalConfig} setShowQRModal={setShowQRModal}/> */}
    </React.Fragment>
  );
  return finalRender;
};

export default ResumeViewOld;
