import React from "react";
import {MrSelect} from "mr_react_framework";
import {useSelector} from "react-redux";
import { buildOptionsArr, userRoleMap } from "/src/lib/utils/helperMethods";
import {actions as genericActions} from "/src/App/genericRedux";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

export const SelectOrg = MrSelect({
  actions: genericActions,
  resourceName: "orgs",
  config: {
    url: "/orgs",
    params: { should_serialize: false },
    processData: (data, props) => {
      const { config = {} } = props;
      const { widgetConfig = {} } = config;
      const hiddenOrgIds = widgetConfig.hiddenOrgIds;
      let newData = data;
      if (hiddenOrgIds) {
        newData = data.filter(item => !hiddenOrgIds?.includes(parseInt(item.id)))
      }
      return newData.map((item) => {
        return { label: item.name, value: parseInt(item.id) };
      });
    },
    // searchApi: false,
    searchApi: true,
    widgetConfig: {
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      // placeholder: "Select school",
      placeholder: <MrTranslate id={"SelectOrgDropdown.select_school"} />,
      // mode: "multiple",
      style: { width: "250px" },
      allowClear: true,
      dropdownMatchSelectWidth: false
    },
  },
});

const SelectOrgDropdown = (props) => {
  console.log( "SelectOrgDropdown props", props );

  // const orgRoles = useSelector(state => {
  //   console.log("state user filter",state);
  //   return state.login.get("org").roles
  // } )

  return <SelectOrg {...props}/>
}


SelectOrgDropdown.defaultProps = {};

SelectOrgDropdown.propTypes = {};

export default SelectOrgDropdown;


                