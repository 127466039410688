import React, { useState, useEffect } from "react";
import { Space, Col, Button, Tooltip, Row, Skeleton } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { checkIPAD, checkMob } from "/src/lib/utils/helperMethods";
import ExperiencePageHeader from "/src/views/Experiences/ExperienceShow/ExperiencePageHeader/ExperiencePageHeader";
import MobileUploadDone from "/src/views/Experiences/ExperienceShow/Components/MobileUploadDone";
import TopicItemDetail from "/src/views/Segments/Topics/TopicItemDetail/TopicItemDetail";
import Topics from "/src/views/Segments/Topics/Topics";
import { getUserResponsesLoadingSelector, experienceInfoSelector, publishedAtSelector, calcEnabledSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import PausedTimeModal from "/src/views/Experiences/ExperienceShow/Components/PausedTimeModal";
import { actions as segmentActions } from "/src/views/Segments/redux";
import { isPinnedSelector } from "/src/views/Segments/selector";
import { Route, useRouteMatch } from "react-router-dom";
import QRModal from "/src/views/Experiences/ExperienceShow/Components/QRModal";
import BookmarkList from "/src/views/Experiences/ExperienceShow/Components/BookmarkList/BookmarkList";
import ResourceModal from "/src/views/Experiences/ExperienceShow/Components/ResourceModal/ResourceModal";
import ExternalVideoCallButton from "/src/views/Experiences/ExperienceShow/Components/ExternalVideoCallButton";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import screenfull from "screenfull";
import { showNotification } from "/src/components/UI/Segment/UIHelper";
import dayjs from "dayjs";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

import { GrCloudComputer, GrPersonalComputer } from "react-icons/gr";
import { experienceConfigSelector } from "/src/views/Experiences/selector";
import TextToSpeech from "/src/views/Segments/TextToSpeech/TextToSpeech";
import Calculator from "../../Components/Calculator/Calculator";
import TaskSpecificClarification from "../../ExperienceManage/TaskSpecificClarification/TaskSpecificClarification";
// import SavingStatusIndicator from "../Components/SavingStatusIndicator";
import TextHighlighter from "/src/views/TextHighlighter/TextHighlighter";
import { topicsSelector } from "/src/views/Segments/Topics/selector";
import FileUploadModal from "../../Components/FileUploadModal";
import PrintPreviewIframe from "../PrintPreviewIframe";
const StartView = (props) => {

  const {
    experienceProps,
    mobileUploadMode,
    sessionModalConfig,
    showQRModal,
    setShowQRModal,
    timer,
    focusLostModal,
    progressBar,
    endButton,
    videoRender,
    experienceDuration,
    setSwitchFromStartToResumeView
  } = props;

  const {
    experience,
    experienceSettings,
    experienceViewMode,
    embedded,
    editAllowed,
    setUserInfo,
  } = experienceProps;

  const isMobile = checkMob();
  const isIPAD = experienceSettings.mode != "offline" && checkIPAD();
  const isGetUserResponsesLoading = useSelector(
    getUserResponsesLoadingSelector
  );
  const isPinned = useSelector(isPinnedSelector());
  const currentUser = useSelector(currentUserSelector());
  const experienceInfo = useSelector(experienceInfoSelector);
  const publishedAt = useSelector(publishedAtSelector);
  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const mrIntl = useTranslate();
  const experienceConfigFromRedux = useSelector(experienceConfigSelector());

  console.log("experienceinfo==>", experienceInfo, Date.parse(experience.updated_at))
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const experienceRubric = experience.rubric || {};
  const tscEnabled = experienceSettings.tsc_enabled
  const showTaskSpecificClarification = tscEnabled && experienceRubric.type_c === "myp_achievement_level";
  const isPaperModeAssessmentPreview =
    experienceSettings.mode === "paper" &&
    experienceViewMode === "apPreviewTest";

  const textHighlighterFeatureEnabled = enabledFeatures.text_highlight || experienceSettings.text_highlight;

  console.log('showTaskSpecificClarification, tscEnabled ===>>',experienceSettings, experience, showTaskSpecificClarification, tscEnabled, experience.settings);



  useEffect(() => {
    // removing pinned on unmount in preview case as well as student taking test
    return () => {
      showNotification('destroy');
      dispatch(
        segmentActions.pinnedSuccess({ pinnedSegments: [], isPinned: false })
      );
    }
  }, []);

  const removeFullScreen = () => {
    console.log('exiting fullscreen==>', screenfull.isEnabled)
    if (screenfull.isEnabled) {
      screenfull.exit();
    }
  
  }

  // // let topicSpan = embedded ? (isMobile ? 0 : 5) : (isMobile ? 0 : 4)
  // let topicSpan = embedded ? (isMobile ? 0 : 5) : (isMobile ? 24 : 4)
  // let topicOffset = isMobile || embedded ? 0 : 1
  // // let segmentSpan = embedded ? (isMobile ? 24 : 19) : (isMobile ? 24 : 17)
  // let segmentSpan = embedded ? (isMobile ? 24 : 19) : (isMobile ? 24 : 17)
  // let segmentOffset = embedded ? (isMobile ? 0 : 0) : (isMobile ? 0 : 1)

  let topicSpan = 4;
  let topicOffset = 1;
  let segmentSpan = 24;
  let segmentOffset = 1;
  if (embedded) {
    if (isMobile) {
      topicSpan = 0;
      segmentSpan = 24;
    } else {
      topicSpan = 5;
      segmentSpan = 19;
    }
    topicOffset = 0;
    segmentOffset = 0;
  } else {
    if (isMobile) {
      topicSpan = 24;
      topicOffset = 0;
      segmentSpan = 24;
      segmentOffset = 0;
    } else if (isPinned) {
      topicSpan = 2;
      topicOffset = 0;
      segmentSpan = 22;
      segmentOffset = 0;
    } else {
      topicSpan = 4;
      topicOffset = 0;
      segmentSpan = 18;
      segmentOffset = 1;
    }
  }

  let extra = [];
  // extra.push(isIPAD.toString())
  const resources =
    (experience.attachments_json && experience.attachments_json.resources) ||
    [];
  // let battery = <Battery />
  
  // const savingStatusIndicator = <SavingStatusIndicator />
  // extra.push(savingStatusIndicator);

  if (
    !isMobile &&
    !isIPAD &&
    !embedded &&
    experienceSettings.allow_student_upload_files
  ) {
    extra.push(
      <QRModal
        experience={experience}
        showQRModal={showQRModal}
        setShowQRModal={setShowQRModal}
      />
    );
  }

  // Example config and convention:
  // 1. Configs to be inherited and merged
  // 2. If boolean - config.showJitsi = true/false - DONT DO THIS - LET's do object's everywhere- moree flexible
  // 3. If object/other configs needed - config.jitsiConfig = {
  //   show: true/false,
  //   mute: true,
  //   edit: true
  // }
  // 4. Don't pass what can be from selector
  // 5. Use show/hide condition to show hide component outside - not inside component


  // Put into floating tools to make common across views
  // !embedded &&
  //   experienceSettings.enable_ap_video_monitoring &&
  //   extra.push(
  //     <JitsiVideoModal
  //       minimized={true}
  //       currentUser={currentUser}
  //       uuid={experience.access_code}
  //       videoRender={videoRender}
  //     />
  //   );

  const viewResourcesButton = (
    <ResourceModal
      attachments_json={experience.attachments_json}
    ></ResourceModal>
  );
  
  const viewTaskSpecificClarificationButton = (
    <TaskSpecificClarification />
  );

  const externalVideoCallButton = (
    <ExternalVideoCallButton
      videoCallLink={experienceSettings.video_call_link}
      experienceSettings = {experienceSettings}
    ></ExternalVideoCallButton> 
  );

  // experienceSettings.video_call_link && extra.push(videoCallButton)
  showTaskSpecificClarification &&
    extra.push(viewTaskSpecificClarificationButton)



  if(!embedded){ // TODO - not working
    extra.push(<BookmarkList />);
  }
  // extra.push(battery)
  // extra.push(changeLanguageButton)
  // userInfoFromRedux.calc_enabled && extra.push(enableCalcButton)
  let enableCalcButton = <Calculator experienceSettings={experienceSettings}></Calculator>;


  
  extra.push(progressBar);
  experienceSettings.is_timed &&
    experienceDuration > 0 &&
    experienceViewMode == "apTakeTest" &&
    extra.push(timer);
  if(experienceConfigFromRedux.takeExperience.start.pageHeader.endButton.show){
    extra.push(endButton);
  }
  // experienceSettings.assessment_type == "exam" && extra.push(endButton)
  // experienceSettings.assessment_type == "quick_check" && extra.push(QuickCheckSubmitButton)

  let finalRender = (
    <React.Fragment>
      <ExperiencePageHeader
        experience={experience}
        experienceViewMode={experienceViewMode}
        embedded={embedded}
        setSwitchFromStartToResumeView={setSwitchFromStartToResumeView}
        pageHeaderProps={{
          className: `site-page-header-responsive experience-header ${
            isMobile ? "mobile" : ""
          }`,
          // onBack={() => window.history.back()}
          backIcon:
            experienceViewMode == "apPreviewTest" ? (
              <Button className="page-header-back-button" shape="round" type="primary" icon={<ArrowLeftOutlined />}>
               {mrIntl("StartView.back_to_edit")}
              </Button>
            ) : experienceSettings.mode == "offline" ||
            experienceSettings.mode === 'online_secure' ||
              // experienceSettings.is_timed ||
              embedded ||
              mobileUploadMode ? (
              false
            ) : (
              <Tooltip title={ mrIntl("CommonText.back")} placement="top" getPopupContainer={() => document.querySelector(".experience-header")}>
                <Button
                  className="page-header-back-button"
                  shape="circle"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => removeFullScreen()}
                ></Button>
              </Tooltip>
            ),
            extra: isPaperModeAssessmentPreview ? (
              <></>
            ) : mobileUploadMode ? (
              <Space>
                {experienceSettings.is_timed && experienceDuration > 0 && timer}
                <MobileUploadDone setUserInfo={setUserInfo}></MobileUploadDone>
              </Space>
            ) : (
              <Space>{extra}</Space>
            ),
        }}
      ></ExperiencePageHeader>

      {isPaperModeAssessmentPreview ? (
        <PrintPreviewIframe uid={experience.uid} />
      ) : (
        <Row className={`experience-container ${isMobile ? "mobile" : ""}`}>
          <Col span={topicSpan} offset={topicOffset}>
            <Route
              path={`${path}`}
              render={(routeProps) => (
                // Cleanup this code after Topics cleanup
                <Topics
                  listType="topics"
                  editAllowed={editAllowed}
                  params={{
                    by_experience_id: experience.id,
                    by_segment_type: "section",
                  }}
                  createUpdateParams={{
                    experience_id: experience.id,
                  }}
                  experienceViewMode={experienceViewMode}
                  experienceProps={{
                    item: experience,
                    ...props,
                  }}
                  mobileUploadMode={mobileUploadMode}
                  isRoute={false}
                  {...routeProps}
                  config={{
                    routes: {
                      show: false,
                    },
                    list: {
                      shouldExecuteRequest: false,
                    },
                  }}
                />
              )}
            />
          </Col>
          <Col span={segmentSpan} offset={segmentOffset}>
            {/* In case of preview topics are coming null   */}
            {isGetUserResponsesLoading ||
            (experienceViewMode !== "apPreviewTest" && !experience.topics) ? (
              <Skeleton active />
            ) : (
              <Route
                path={`${path}/topics/:topicsId`}
                render={(routeProps) => (
                  // Cleanup this code after Topics cleanup
                  <Topics
                    listType="segments"
                    parentId={experience.id}
                    parentName={"experience"}
                    experienceProps={{
                      // Fix this having issue in TopicItemDetails
                      item: experience,
                      ...props,
                    }}
                    experienceViewMode={experienceViewMode}
                    params={{ by_experience_id: experience.experience_id }}
                    createUpdateParams={{
                      experience_id: experience.experience_id,
                    }}
                    embedded={embedded}
                    mobileUploadMode={mobileUploadMode}
                    isRoute={false}
                    config={{
                      routes: { fetch: false },
                      itemDetail: { widget: TopicItemDetail },
                    }}
                    {...routeProps}
                  />
                )}
              />
            )}
          </Col>
        </Row>
      )}

      <PausedTimeModal setUserInfo={setUserInfo}></PausedTimeModal>

      {focusLostModal}
      <FileUploadModal
        itemMode={experienceSettings.mode}
        sessionModalConfig={sessionModalConfig}
        setShowQRModal={setShowQRModal}
      />
    </React.Fragment>
  );
  return finalRender;
};

export default StartView;
