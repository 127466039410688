import React, { useEffect, useRef } from "react";
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { cloneDeep } from 'lodash';
import { globalCanvasConfig, hotspotShapes } from "/src/components/UI/Canvas/FabricCanvas";
import { showInfoModal } from '/src/components/UI/Segment/UIHelper'
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const { shapeStyle } = globalCanvasConfig;

// export const segmentConfig = () => {
// 	return {
// 		createMode: true,
// 		editMode: false,
// 		answerMode: false,
// 		presentationMode: false,
// 		printMode: false,
// 		offlineMode: false,
// 		autoCheckMode: false,
// 		showCorrectAnswer: false,
// 	}
// }

export const getArrayOfList = (col, row, extra = {}) => {
	let listArray = []

	const titleRequired = extra.titleRequired
	const titlePrefix = extra.titlePrefix || 'Column'
	const namePrefix = extra.namePrefix || 'Column'
	const placeholderPrefix = extra.placeholderPrefix || 'Column'

	for (let i = 0; i < col; i++) {
		let key = getUniqueId();
		let id = getUniqueId()
		let title = titleRequired ? getContent(titlePrefix, i) : '';
		let name = getContent(namePrefix, i)
		let placeholder = getContent(placeholderPrefix, i)
		let items = getItemsOfSize(i, row, extra.itemConfig);

		listArray.push({
			key: key,
			id: id,
			name: name,
			title: title,
			placeholder: placeholder,
			items: items,
		})
	}

	return listArray
}

export const getList = (type, col, row, extra) => {
	let list = {}

	const titleRequired = extra.titleRequired
	const titlePrefix = extra.titlePrefix || 'Column'
	const namePrefix = extra.namePrefix || 'Column'
	const placeholderPrefix = extra.placeholderPrefix || 'Column'
	for (let i = 0; i < col; i++) {
		let key = getUniqueId();
		let id = getUniqueId()
		let title = titleRequired ? getContent(titlePrefix, i) : '';
		let name = getContent(namePrefix, i)
		let placeholder = getContent(placeholderPrefix, i)
		let items = getItemsOfSize(i, row, extra.itemConfig);

		list[i] = {
			key: key,
			id: id,
			title: title,
			name: name,
			placeholder: placeholder,
			items: items,
		}
	}
	return list;
}

export const getItemsOfSize = (col, size, extra = {}) => {
	const contentRequired = extra.contentRequired
	const contentPrefix = extra.contentPrefix || 'Choice'
	const placeholderPrefix = extra.placeholderPrefix || 'Choice'
	let items = [];
	for (let i = 0; i < size; i++) {
		let id = getUniqueId();
		let position = (col * size) + i
		let content = contentRequired ? getContent(contentPrefix, position) : '';
		let placeholder = getContent(placeholderPrefix, position)
		items.push({ id: id, content: content, placeholder: placeholder })
	}
	return items
}

const getContent = (prefix, position) => {
	let postfix = '';
	if (prefix == 'Choice') {
		let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		postfix = possible.charAt(position)
		let r = Math.floor(position / 26)
		if (r >= 1) {
			postfix = postfix.repeat(r)
		}
	} else {
		postfix = position + 1;
	}
	let content = prefix + ' ' + postfix
	return content
}

export const getItemFromArrayById = (items, id) => {
	let returnItem;
	items.map((item, index) => {
		if (item.id == id) {
			returnItem = item
			// returnItem.index = index
		}
	})
	return returnItem
}

export const addListToArray = (items, extra = {}) => {
	let size = items.length
	const maxSize = extra.maxSize || 20;

	console.log("Items before adding ==>", items)
	if (size >= maxSize) {
		showInfoModal({
			type: 'warning',
			title: <MrTranslate id={"CommonText.reached_maximum_allowed_limit"}/>
		})
		return items
	} else {
		const titleRequired = extra.titleRequired
		const titlePrefix = extra.titlePrefix || 'Column'
		const namePrefix = extra.namePrefix || 'Column'
		const placeholderPrefix = extra.placeholderPrefix || 'Column'

		let id = getUniqueId()
		let title = titleRequired ? getContent(titlePrefix, extra.colSize) : '';
		let name = getContent(namePrefix, extra.colSize)
		let placeholder = getContent(placeholderPrefix, extra.colSize)
		let childItems = getItemsOfSize(extra.colSize, 1, extra.itemConfig);

		const newList = extra.list || {
			id: id,
			name: name,
			title: title,
			placeholder: placeholder,
			items: childItems,
		}

		items.push(newList)

		console.log("Items after adding ==>", items)
		return items;
	}
}

export const addItemToArray = (items, extra = {}) => {
	let size = items.length

	const maxSize = extra.maxSize || 10;
	const contentRequired = extra.contentRequired
	const contentPrefix = extra.contentPrefix || 'Choice'
	const placeholderPrefix = extra.placeholderPrefix || 'Choice'

	if (size >= maxSize) {
		showInfoModal({
			type: 'warning',
			title: <MrTranslate id={"CommonText.reached_maximum_allowed_limit"}/>
		})
		return items
	} else {
		let id = getUniqueId();
		let content = contentRequired ? getContent(contentPrefix, size) : '';
		let placeholder = getContent(placeholderPrefix, size);
		items.push({ id: id, content: content, placeholder: placeholder })
	}
	return items;
}

export const removeItemFromArray = (items, index, extra = {}) => {
	let size = items.length

	let minSize = extra.minSize || 1;
	if (extra.noMinSize) {
		minSize = 0
	}

	index = typeof (index) == 'undefined' ? size - 1 : index
	if (minSize && size <= minSize) {
		showInfoModal({
			type: 'warning',
			title: <MrTranslate id={"CommonText.reached_maximum_allowed_limit"}/>
		})
		return items
	} else {
		items.splice(index, 1)
	}
	return items
}

export const addItemToObject = (list, keyName, size, extra = {}) => {
	const list_length = Object.keys(list).length
	let key = getUniqueId();
	let id = getUniqueId()
	const titleRequired = extra.titleRequired
	const titlePrefix = extra.titlePrefix || 'Column'
	const placeholderPrefix = extra.placeholderPrefix || 'Column'
	let title = titleRequired ? getContent(titlePrefix, list_length) : '';
	let placeholder = getContent(placeholderPrefix, list_length)
	let items = getItemsOfSize(list_length, size, extra.itemConfig);

	list[keyName] = {
		key: key,
		id: id,
		title: title,
		placeholder: placeholder,
		items: items,
	}

	return list
}

export const removeItemFromObject = (list, keyName) => {
	let minSize = 1;
	let list_length = Object.keys(list).length
	if (list_length <= minSize) {
		showInfoModal({
			type: 'warning',
			title: <MrTranslate id={"CommonText.reached_maximum_allowed_limit"}/>
		})
		return list
	} else {
		delete list[keyName]
	}

	return list
}

export const addColumnToTable = (columns, maxSize) => {
	let size = columns.length
	maxSize = maxSize ? maxSize : 10;
	if (size >= maxSize) {
		showInfoModal({
			type: 'warning',
			title: <MrTranslate id={"CommonText.reached_maximum_allowed_limit"}/>
		})
		return columns
	} else {
		let key = getUniqueId();
		let id = getUniqueId();
		let c = getContent('Column', size);
		columns.push({ key: key, id: id, name: c, placeholder: c })
	}
	return columns;
}

const getTableData = (colSize, rowSize) => {
	let data = []
	for (let i = 0; i < rowSize; i++) {
		let row = []
		for (let j = 0; j < colSize; j++) {
			let key = getUniqueId()
			let id = getUniqueId();
			let c = getContent('Data', (colSize * i + j));
			row.push({ key: key, id: id, content: c, placeholder: c })
		}
		data.push(row)
	}
	return data
}

const getTableColumns = (type, size) => {
	let columns = [];
	for (let i = 0; i < size; i++) {
		let key = getUniqueId()
		let id = getUniqueId();
		let c = getContent('Column', i);
		columns.push({ key: key, id: id, name: c, placeholder: c })
	}
	return columns
}

const getTableRows = (columns, size) => {
	let rows = [];
	for (let i = 0; i < size; i++) {
		// let key = getUniqueId()
		// let id = getUniqueId();
		let rowData = {}
		rowData['id'] = getUniqueId()
		columns.map((column, j) => {
			rowData[column['key']] = getContent('Data', j + 1);
		})
		rows.push(rowData)
	}
	return rows
}

export const initialData = (type, colSize, rowSize, extra) => {
	let initialData;
	if (type == 'table') {
		initialData = {
			segment_data: {
				table_data: getTableData(colSize, rowSize),
				chart_settings: {}
			},
			segment_version: '1.0.0'
		}
	} else if (type == 'resource') {
		initialData = {
			segment_data: {
				attachments: []
			},
			segment_version: '1.0.0'
		}
	} else {
		initialData = {
			segment_data: {},
			segment_version: '1.0.0'
		}
	}
	return initialData
}

export const convertDataAsPerNewSystem = (data, props) => {
	if (!data || data.segment_version) {
		// if (data.segment_version == '1.0.0' && props.question_type == 'rbd_inline_matching') {
		// 	let newData = data
		// 	const vertical_lists = data.segment_data.vertical_lists
		//   Object.keys(data.segment_data.vertical_lists).map((keyName) => {
		// 		newData.segment_data.vertical_lists[keyName].title = vertical_lists[keyName].items[0].content

		// 		newData.segment_data.vertical_lists[keyName].items = vertical_lists[keyName].items[1] ? [vertical_lists[keyName].items[1]] : []
		// 	})
		// 	newData.segment_version = '1.0.1'
		// 	return newData
		// }
		return data
	}
	let id, newData, type = props.question_type;
	console.log('Old format ==>', data);

	if (type == 'subjective') {

	} else if (type == 'mcq_single') {
		newData = { segment_data: { lists: {} } }
		let items = data.list
		items.map((item, indx) => {
			if (item.id == data.user_answer_id) {
				item.checked = true
			} else {
				item.checked = item.correct ? true : false
			}

			item.placeholder = 'Option ' + (indx + 1)
			item.id = item.id ? item.id : getUniqueId()
			item.correct = false
			// delete item.id
			delete item.question_id
			// delete item.correct
		})
		id = getUniqueId()
		newData.segment_data.lists[id] = {
			id: id,
			title: "Column 1",
			items: items,
		}
	} else if (type == 'mcq_multiple') {
		newData = { segment_data: { lists: {} } }
		let items = data.list
		items.map((item) => {
			item.id = item._id ? item._id : getUniqueId()
		})
		id = getUniqueId()
		newData.segment_data.lists[id] = {
			id: id,
			title: 'Column 1',
			items: items,
		}
	} else if (type == 'table') {

	} else if (type == 'ggb_graph') {
		newData = { segment_data: { ggbBase64Data: data, appName: 'graphing' } }
	} else if (type == 'fill_blanks') {

	} else if (type == 'fabric_drawing') {
		newData = { segment_data: Object.assign({}, data) }
		newData.segment_data.objects = data.objects.filter((object) => {
			if (object.type === "image") {
				object.online_src = object.offline_src = object.src
				if (object.name == 'bgImg') {
					object.name = 'Background Image'
				}
				return object
			} else {
				return object
			}
		})
	} else if (type == 'fabric_fill_label' || type == 'fabric_dnd_label') {
		newData = { segment_data: Object.assign({}, data), segment_responses: {}, backupTextHolders: {} }
		newData.segment_data.objects = data.objects.filter((object) => {
			if (object.type === "image") {
				object.online_src = object.offline_src = object.src
				if (object.name == 'bgImg') {
					object.name = 'Background Image'
				}
				return object
			} else if (object.type == 'i-text') {
				let text = object
				text.name = 'Label Text'
				text.opacity = 1
				text.requireContainerRect = true
				text.requireLabelHolder = true
				text.unsupportedHolder = Object.assign({}, newData.segment_data.backupTextHolders[text.id])
				if (type == 'fabric_fill_label') {
					newData.segment_responses[object.id] = { id: object.id, answer: removeSpaceCamelCase(object.text) }
				}
				return object
			} else if (object.type == 'rect') {
				if (object.name != 'objectBox') {
					return object
				}
			} else if (object.type == 'group') {
				if (object.name == 'holder') {
					if (type == 'fabric_dnd_label') {
						newData.responses[object.id] = { id: object.id, answer: object.holdingObjectId }
						newData.backupTextHolders[object.holdingObjectId] = Object.assign({}, object)
					}
				} else {
					return object
				}
			} else if (object.type == 'polyline') {
				return object
			} else if (object.type == 'polygon') {
				return object
			}
		})
		return newData
	} else if (type == 'fabric_hotspot') {
		newData = { segment_data: Object.assign({}, data), segment_responses: {} }
		newData.segment_data.objects = data.objects.filter((object) => {
			if (object.type === "image") {
				object.online_src = object.offline_src = object.src
				if (object.name == 'bgImg') {
					object.name = 'Background Image'
				}
				return object
			} else {
				if (object.correctHotspot) {
					newData.segment_responses[object.id] = { id: object.id, answer: object.correctHotspot }
				}
				return object
			}
		})
	} else if (type == 'matching') {
		newData = { segment_data: { vertical_lists: {}, horizontal_lists: {} } }
		data.list.map((items, i) => {
			items.map((item, indx) => {
				item.id = item._id ? item._id : getUniqueId()
				item.placeholder = 'Write here ' + (indx + 1)
			})
			id = getUniqueId()
			newData.segment_data.vertical_lists[id] = {
				id: id,
				title: 'Column ' + (i + 1),
				items: items,
			}
		})

		if (data.possibility_resp_list) {
			data.possibility_resp_list.map((item) => {
				item.id = item._id
			})

			id = getUniqueId()
			newData.segment_data.horizontal_lists[id] = {
				id: id,
				title: "Possible Responses",
				items: data.possibility_resp_list
			}
		}

	} else if (type == 'sorting') {
		newData = { segment_data: { vertical_lists: {}, horizontal_lists: {} } }
		let items = data.list
		items.map((item, indx) => {
			item.id = item._id ? item._id : getUniqueId()
			item.placeholder = 'Choice ' + (indx + 1)
		})
		id = getUniqueId()
		newData.segment_data.vertical_lists[id] = {
			id: id,
			title: "Column 1",
			items: items,
		}

		if (data.possibility_resp_list) {
			data.possibility_resp_list.map((item) => {
				item.id = item._id
			})

			id = getUniqueId()
			newData.segment_data.horizontal_lists[id] = {
				id: id,
				title: "Possible Responses" ,
				items: data.possibility_resp_list
			}
		}

	} else if (type == 'classify') {
		newData = { segment_data: { vertical_lists: {}, horizontal_lists: {} } }
		data.list.map((items, i) => {
			items.map((item, indx) => {
				item.id = item._id ? item._id : getUniqueId()
				item.placeholder = 'Write here ' + (indx + 1)
			})
			id = getUniqueId()
			newData.segment_data.vertical_lists[id] = {
				id: id,
				title: 'Column ' + (i + 1),
				items: items,
			}
		})

		if (data.possibility_resp_list) {
			data.possibility_resp_list.map((item) => {
				item.id = item._id
			})

			id = getUniqueId()
			newData.segment_data.horizontal_lists[id] = {
				id: id,
				title: "Possible Responses",
				items: data.possibility_resp_list
			}
		}
	}

	newData.segment_version = '1.0.0'
	console.log('Updated format data ===>', newData)
	return newData
}

export const getParsedJSONObject = (props) => {
	console.log('Props in get parsed json ==>', props)
	let { createMode, editMode, answerMode, gameMode, presentationMode, autoCheckMode, showCorrectAnswer } = props
	let value = cloneDeep(props.value)
	let teacher_json = cloneDeep(props.teacher_json)
	let student_json = cloneDeep(props.student_json)
	let response_json = cloneDeep(props.response_json)
	let parsedJSONObject = {}

	if (createMode) {
		parsedJSONObject = value || {}
	// } else if (editMode) {
  // 	if (value) {
  // 		parsedJSONObject = value
  // 	} else {
  // 		parsedJSONObject = teacher_json
  // 	}
  // 	createMode = true
  } else if (answerMode) {
		if (response_json || value) {
			parsedJSONObject = response_json || value
		} else {
      if (gameMode) {
        parsedJSONObject = teacher_json
      } else {
        parsedJSONObject = student_json
      }
		}
	} else if (presentationMode) {
		if (showCorrectAnswer) {
			parsedJSONObject = teacher_json
		} else {
			if ((response_json && response_json.segment_data) || value) {
				parsedJSONObject = response_json || value
			} else {
				parsedJSONObject = student_json
			}
		}
	}

	parsedJSONObject = parsedJSONObject || student_json || {}

	console.log('parsedJSONObject in getparsed ==>', parsedJSONObject)
	return { parsedJSONObject, createMode, answerMode, presentationMode, autoCheckMode }
}

export const removeSpaceCamelCase = (text) => {
	if (text) {
		return text.replace(/[\r\s]+/g, "").toLowerCase()
	}
}

export const updateImagesSRCForCanvas = (canvas_data, segment_version) => {
	if (segment_version != 'old_version') {
		return canvas_data
	}
	if (!canvas_data || !canvas_data.objects) {
		return canvas_data
	}
	let objects = canvas_data.objects;
	objects.map((object) => {
		if (object.type == "image") {
			if (object.src == null) {
				object.src = object.local_src_backup
			}
		}
	})
	return canvas_data
}

export const checkIfCanvasNeedToUpdated = (viewConfig) => {
	let allowCanvasToUpdate
	// if (presentationMode || (printView && autoCheckEanabled)) {
	// 	allowCanvasToUpdate = false;
	// } else if (autoCheckEanabled && presentationMode) {
	// 	allowCanvasToUpdate = false;
	// }
	return allowCanvasToUpdate
}

export const usePrevious = value => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

export const getMCQSingleStudentJSON = (json) => {
	console.log("get student json ==>", json)
	let student_json = cloneDeep(json)
	const segment_data = student_json.segment_data
	segment_data.options.map((option) => {
		option.checked = false
	})

	return student_json
}


export const getMCQMultipleStudentJSON = (json) => {
	let student_json = cloneDeep(json)
	const segment_data = student_json.segment_data
	segment_data.options.map((item) => {
		item.checked = false
	})

	return student_json
}

export const getTrueFalseStudentJSON = (json) => {
  let student_json = cloneDeep(json)
  const segment_data = student_json.segment_data
  segment_data.statements.map((item) => {
    item.statementIs = null
  })

  return student_json
}

export const getRBDInlineMatchingStudentJSON = (json) => {
  let student_json = cloneDeep(json);
  let segment_data = student_json.segment_data;
  if (segment_data) {
    const possible_responses = segment_data.possible_responses || {
      id: getUniqueId(),
      title: "Possible Responses",
      items: [],
    };

    const columns = segment_data.columns;
    const distractors = segment_data.distractors || [];

    columns.map((column) => {
      column.items.map((item) => {
        possible_responses.items.push(item);
      });
      column.items = [];
    });

    distractors.map((distractor) => {
      possible_responses.items.push(distractor);
    });

    segment_data.possible_responses = possible_responses;
  }

  return student_json;
};

export const getRBDInlineSortingStudentJSON = (json) => {
	let student_json = cloneDeep(json)
	let segment_data = student_json.segment_data
	if (segment_data) {
		const possible_responses = segment_data.possible_responses || {
			id: getUniqueId(),
			title: 'Possible Responses',
			items: []
		}
		const columns = segment_data.columns
		columns.map((column) => {
			column.items.map((item) => {
				possible_responses.items.push(item)
			})
			column.items = []
		})

		segment_data.possible_responses = possible_responses
	}

	return student_json
}

export const getRBDInlineClassifyStudentJSON = (json) => {
  let student_json = cloneDeep(json)
  let segment_data = student_json.segment_data
  if (segment_data) {
    const possible_responses = segment_data.possible_responses || {
      id: getUniqueId(),
      title: "Possible Responses",
      items: []
    }
    const columns = segment_data.columns
    const distractors = segment_data.distractors || []

    columns.map((column) => {
      column.items.map((item) => {
        possible_responses.items.push(item)
      })
      column.items = []
    })

    distractors.map((distractor) => {
      possible_responses.items.push(distractor)
    })

    segment_data.possible_responses = possible_responses
  }

  return student_json
}

export const getRBDMatchingStudentJSON = (json) => {
	let student_json = cloneDeep(json)
	let segment_data = student_json.segment_data
	if (segment_data) {
		segment_data.horizontal_lists = {}
		let horizontalItemsArr = []
		segment_data.vertical_lists[1].items = segment_data.vertical_lists[1].items.map((item) => {
			horizontalItemsArr.push(item)
			return { id: getUniqueId() }
		})
		segment_data.horizontal_lists[0] = {
			id: getUniqueId(),
			title: "Possible Responses",
			items: horizontalItemsArr
		}
	}

	return student_json
}

export const getRBDSortingStudentJSON = (json) => {
	let student_json = cloneDeep(json)
	let segment_data = student_json.segment_data
	if (segment_data) {
		segment_data.horizontal_lists = {}
		let horizontalItemsArr = []
		segment_data.vertical_lists[0].items = segment_data.vertical_lists[0].items.map((item) => {
			horizontalItemsArr.push(item)
			return { id: getUniqueId() }
		})
		segment_data.horizontal_lists[0] = {
			id: getUniqueId(),
			title: "Possible Responses",
			items: horizontalItemsArr
		}
	}

	return student_json
}

export const getRBDClassifyStudentJSON = (json) => {
	let student_json = cloneDeep(json)
	let segment_data = student_json.segment_data
	if (segment_data) {
		let horizontalItemsArr = []

		const verticalListKeyArray = Object.keys(segment_data.vertical_lists).sort()
		verticalListKeyArray.map((key) => {
			segment_data.vertical_lists[key].items.map((item) => {
				horizontalItemsArr.push(item)
			})
			segment_data.vertical_lists[key].items = []
		})

		let id = getUniqueId()
		segment_data.horizontal_lists[0] = {
			id: id,
			title: "Possible Responses",
			items: horizontalItemsArr
		}
	}

	return student_json
}

export const getFabricDrawingStudentJSON = (json) => {
  let student_json = cloneDeep(json);

  return student_json;
};

export const getFabricDnDLabelStudentJSON = (json) => {
  let student_json = cloneDeep(json);
  const segment_data = student_json.segment_data;
  const canvas_data = segment_data.canvas_data;
  if (canvas_data && canvas_data.objects) {
    canvas_data.objects.forEach((object) => {
      if (object.name == "Label Holder") {
        object.holdingObjectId = "";
      }
      if (object.name == "Label Text" || object.name == "Label Image") {
        object.objectPlaced = false;
      }
    });
  }
  student_json.segment_responses = {};

  return student_json;
};

export const getFabricFillLabelStudentJSON = (json) => {
  let student_json = cloneDeep(json);
  const segment_data = student_json.segment_data;
  const canvas_data = segment_data.canvas_data;
  if (canvas_data && canvas_data.objects) {
    canvas_data.objects.forEach((object) => {
      if (object.name == "Label Text") {
        object.text = "";
      }
    });
  }
  student_json.segment_responses = {};

  return student_json;
};

export const getFabricHotspotStudentJSON = (json) => {
  let student_json = cloneDeep(json);
  const segment_data = student_json.segment_data;
  const canvas_data = segment_data.canvas_data;
  if (canvas_data && canvas_data.objects) {
    canvas_data.objects.forEach((object) => {
      if (object.checkedHotspot) {
        object.fill = shapeStyle.fill;
        object.stroke = shapeStyle.stroke;
        object.strokeWidth = shapeStyle.strokeWidth;
        object.checkedHotspot = false;
      }
      // if (object.name == 'Label Text' || object.name == 'Label Image') {
      //   object.connectedObjectsIds = [object.connectedObjectsIds]
      // }
    });
  }
  student_json.segment_responses = {};
  return student_json;
};

export const getGGBGraphStudentJSON = (json) => {
  let student_json = cloneDeep(json);

  return student_json;
};


export const getDesmosGraphStudentJSON = (json) => {
  let student_json = cloneDeep(json);

  return student_json;
};

export const getConnectStudentJSON = (json) => {
  let student_json = cloneDeep(json);
  let segment_data = student_json.segment_data || {};
  let answer = segment_data.answer;
  if (segment_data) {
    segment_data.answers = [];
    answer.content = "";
  }

  return student_json;
};

export const getRDSTableStudentJSON = (json) => {
  let student_json = cloneDeep(json);

  const segment_data = student_json.segment_data;
  const td = segment_data.table_data;
  const table_data = (typeof td == "string" ? JSON.parse(td) : td) || [[]];
  const table_editable = segment_data.table_editable;

  table_data.map((row) => {
    row.map((cell) => {
      if (table_editable) {
        cell.readOnly = false;
      } else if (cell.content) {
        cell.readOnly = true;
      }
    });
  });

  segment_data.table_data = table_data;

  return student_json;
};

export const getCKEFillTextStudentJSON = (json) => {
  let student_json = cloneDeep(json);
  let segment_data = student_json.segment_data || {};
  if (segment_data) {
    segment_data.gaps = segment_data.gaps.filter((gap) => {
      if (!gap.archived) {
        gap.answer.content = "";
        delete gap.items;
        return gap;
      }
    });
  }

  console.log("Updated student json, ckefill text ==>", student_json);

  return student_json;
};

export const getCKEFillDropDownStudentJSON = (json) => {
  let student_json = cloneDeep(json);
  let segment_data = student_json.segment_data;
  if (segment_data) {
    segment_data.gaps = segment_data.gaps.filter((gap) => {
      if (!gap.archived) {
        gap.answer = {};
        gap.items.map((item) => {
          item.checked = false;
        });
        return gap;
      }
    });
  }

  console.log("Updated student json, ckefill text ==>", student_json);

  return student_json;
};

export const getUploadFileStudentJSON = (json) => {
  let student_json = cloneDeep(json)
  return student_json
}

export const getFinalStudentJSON = (type, json) => {
	console.log("Final student json type ==>", type)
	if (!type || !json) {
		return
	}

	const studentJSONFunctions = {
		mcq_single: (json) => getMCQSingleStudentJSON(json),
		mcq_multiple: (json) => getMCQMultipleStudentJSON(json),
		true_false: (json) => getTrueFalseStudentJSON(json),
		rbd_inline_matching: (json) => getRBDInlineMatchingStudentJSON(json),
		rbd_inline_sorting: (json) => getRBDInlineSortingStudentJSON(json),
		rbd_inline_classify: (json) => getRBDInlineClassifyStudentJSON(json),
		rbd_matching: (json) => getRBDMatchingStudentJSON(json),
		rbd_sorting: (json) => getRBDSortingStudentJSON(json),
		rbd_classify: (json) => getRBDClassifyStudentJSON(json),
		fabric_drawing: (json) => getFabricDrawingStudentJSON(json),
		fabric_dnd_label: (json) => getFabricDnDLabelStudentJSON(json),
		fabric_fill_label: (json) => getFabricFillLabelStudentJSON(json),
		fabric_hotspot: (json) => getFabricHotspotStudentJSON(json),
		ggb_graph: (json) => getGGBGraphStudentJSON(json),
		desmos_graph: (json) => getDesmosGraphStudentJSON(json),
    connect: (json) => getConnectStudentJSON(json),
		rds_table: (json) => getRDSTableStudentJSON(json),
		cke_fill_text: (json) => getCKEFillTextStudentJSON(json),
		cke_fill_dropdown: (json) => getCKEFillDropDownStudentJSON(json),
    file_upload: (json) => getUploadFileStudentJSON(json),
	}

	const studentJSON = studentJSONFunctions[type] ? studentJSONFunctions[type](json) : json

	console.log("studentJSON ==>", studentJSON)
	return studentJSON
}

export const getMarkingScheme = (type, scheme) => {
  console.log("getMarkingScheme type ==>", type, scheme);
 let finalRender = null
  if (!type) {
    return;
  }
  const markingScheme = {
    mcq_single: scheme || 'all_or_nothing',
    true_false: scheme || 'correct_answer',
    cke_fill_text: scheme || 'correct_answer',
    cke_fill_dropdown: scheme || 'correct_answer',
    rbd_inline_matching: scheme || 'correct_answer',
    rbd_matching: scheme || 'correct_answer',
    rbd_inline_sorting: scheme || 'correct_answer',
    rbd_inline_classify: scheme || 'correct_answer',
    rbd_classify: scheme || 'correct_answer',
    rds_table: scheme || 'correct_answer',
    fabric_drawing: scheme || 'correct_answer',
    fabric_dnd_label: scheme || 'correct_answer',
    fabric_fill_label: scheme || 'correct_answer',
    ggb_graph: scheme || 'correct_answer',
    desmos_graph: scheme || 'correct_answer',
    mini_quiz: scheme || 'correct_answer',
    audio_input: scheme || 'correct_answer',
    rbd_sorting: scheme || 'correct_answer',
    mcq_multiple: scheme || 'right_minus_wrong',
    fabric_hotspot: scheme || 'right_minus_wrong',
  }
  if (markingScheme[type] === "correct_answer") {
    finalRender = <div>
      <h4><MrTranslate id={"InteractiveHelpers.correct_answer_heading"}/></h4>
      <p>
       <MrTranslate id={"InteractiveHelpers.get_point_for_correct_answer_info_msg"}/>
      </p>
    </div>

  } else if (markingScheme[type] === "right_minus_wrong") {
    finalRender = (
      <div>
        <h3><MrTranslate id={"InteractiveHelpers.right_minus_wrong_heading"}/></h3>
        <p>
          <MrTranslate id={"InteractiveHelpers.right_minus_wrong_point_info_msg"}/>
        </p>
      </div>
    )
  } else if (markingScheme[type] === "all_or_nothing") {
      finalRender = (
        <div>
          <h3><MrTranslate id={"InteractiveHelpers.all_or_nothing_heading"}/></h3>
          <p><MrTranslate id={"InteractiveHelpers.all_or_nothing_point_info_msg"}/></p>
        </div> 
      )
  }
  return finalRender;
};

export const validateAccordionJSON = (json) => {
  const segment_data = json.segment_data || {}
  const panels = segment_data.panels || []
  const validation = {
    success: false,
    messages: []
  }

  let allHaveContent = true
  panels.map((panel) => {
    if (!panel.title || !(panel.content)) {
      allHaveContent = false
    }
  })

  if (!allHaveContent) {
    validation.messages.push(<MrTranslate id={"CommonText.all_text_boxes_is_required"}/>)
  }

  if (allHaveContent) {
    validation.success = true
  }

  return validation
}

export const validateImageJSON = (json) => {
	const segment_data = json.segment_data || {}
	const attachments = segment_data.attachments || []

	const validation = {
		success: false,
		messages: []
	}

	const atleastOneAttachment = attachments.length

	if (!atleastOneAttachment) {
		validation.messages.push(<MrTranslate id={"ImageForm.atleast_one_image_required"}/>)
	}

	if (atleastOneAttachment) {
		validation.success = true
	}

	return validation
}

export const validatePDFJSON = (json) => {
  const segment_data = json.segment_data || {}
  const attachments = segment_data.attachments || []

  const validation = {
    success: false,
    messages: []
	}
	
	const atleastOneAttachment = attachments.length

  if (!atleastOneAttachment) {
    validation.messages.push(<MrTranslate id={"PDFForm.atleast_one_pdf_required"}/>)
  }

  if (atleastOneAttachment) {
    validation.success = true
  }

  return validation
}

export const validateAudioJSON = (json) => {
	const segment_data = json.segment_data || {}
	const attachments = segment_data.attachments || []

	const validation = {
		success: false,
		messages: []
	}

	const atleastOneAttachment = attachments.length

	if (!atleastOneAttachment) {
		validation.messages.push(<MrTranslate id={"AudioForm.atleast_one_audio_required_msg"} />)
	}

	if (atleastOneAttachment) {
		validation.success = true
	}

	return validation
}

export const validateVideoJSON = (json) => {
  const segment_data = json.segment_data || {};
  const attachments = segment_data.attachments || [];

  const validation = {
    success: false,
    messages: [],
  };

  const atleastOneAttachment = attachments.length;

  if (!atleastOneAttachment) {
    validation.messages.push(<MrTranslate id={"VideoForm.atleast_one_video_required_msg"}/>);
  }

  if (atleastOneAttachment) {
    validation.success = true;
  }

  return validation;
};

export const validateSimulationJSON = (json) => {
  const segment_data = json.segment_data || {}
  const attachments = segment_data.attachments || []

  const validation = {
    success: false,
    messages: []
	}
	
	const atleastOneAttachment = attachments.length

  if (!atleastOneAttachment) {
    validation.messages.push(<MrTranslate id={"SimulationForm.atleast_one_simulation_required_msg"}/>)
  }

  if (atleastOneAttachment) {
    validation.success = true
  }

  return validation
}

export const validateEmbedJSON = (json) => {
  const segment_data = json.segment_data || {}
  const attachments = segment_data.attachments || []

  const validation = {
    success: false,
    messages: []
	}
	
	const atleastOneAttachment = attachments.length

  if (!atleastOneAttachment) {
    validation.messages.push(<MrTranslate id={"EmbedForm.atleast_one_embed_url_required_message"}/>)
  }

  if (atleastOneAttachment) {
    validation.success = true
  }

  return validation
}

export const validateDocumentJSON = (json) => {
  const segment_data = json.segment_data || {};
  const attachments = segment_data.attachments || [];

  const validation = {
    success: false,
    messages: [],
  };

  const atleastOneAttachment = attachments.length;

  if (!atleastOneAttachment) {
    validation.messages.push("Atleast one document required");
  }

  if (atleastOneAttachment) {
    validation.success = true;
  }

  return validation;
};

export const validatePPTJSON = (json) => {
  const segment_data = json.segment_data || {};
  const attachments = segment_data.attachments || [];
  console.log("validatePPTJSON ==>>", json);

  const validation = {
    success: false,
    messages: [],
  };

  const atleastOneAttachment = attachments.length;

  if (!atleastOneAttachment) {
    validation.messages.push("Atleast one ppt required");
  }

  if (atleastOneAttachment) {
    validation.success = true;
  }

  return validation;
};

export const validateMCQSingleJSON = (json) => {
	const teacher_json = json
	const segment_data = teacher_json.segment_data || {}
	const options = segment_data.options || []
	const validation = {
		success: false,
		messages: []
	}
	let atleastOneChecked, allHaveContentOrFile = true
	options.map((option) => {
		if (option.checked) {
			atleastOneChecked = option.checked
		}

		if (!option.content && !(option.files && option.files.length)) {
			allHaveContentOrFile = false
		}

		if (option.show_feedback && !option.custom_feedback) { 
			allHaveContentOrFile = false
		}
	})

	if (!atleastOneChecked) {
		validation.messages.push(<MrTranslate id={"MCQSingle.correct_answer_required_msg"}/>)
	}

	if (!allHaveContentOrFile) {
		validation.messages.push(<MrTranslate id={"CommonText.all_option_text_is_required_msg"}/>)
	}

	if (atleastOneChecked && allHaveContentOrFile) {
		validation.success = true
	}

	return validation
}


export const validateMCQMultipleJSON = (json) => {
	const teacher_json = json
	const segment_data = teacher_json.segment_data
	const validation = {
		success: false,
		messages: []
	}
	let atleastOneChecked, allHaveContentOrFile = true
	segment_data.options.map((option) => {
		if (option.checked) {
			atleastOneChecked = option.checked
		}

		if (!option.content && !(option.files && option.files.length)) {
			allHaveContentOrFile = false
		}

		if (option.show_feedback && !option.custom_feedback) { 
			allHaveContentOrFile = false
		}
	})

	if (!atleastOneChecked) {
		validation.messages.push(<MrTranslate id={"CommonText.atleast_one_correct_answer_required_msg"} />)
	}

	if (!allHaveContentOrFile) {
		validation.messages.push(<MrTranslate id={"CommonText.all_option_text_is_required_msg"} />)
	}

	if (atleastOneChecked && allHaveContentOrFile) {
		validation.success = true
	}

	return validation
}

export const validateTrueFalseJSON = (json) => {
  const teacher_json = json
  const segment_data = teacher_json.segment_data
  const statements = segment_data.statements || []
  const validation = {
    success: false,
    messages: []
  }

  let allHaveCorrectAnswer = true, allHaveContent = true
  statements.map((statement) => {
    if (statement.statementIs == undefined) {
      allHaveCorrectAnswer = false
    }

    if (!statement.content) {
      allHaveContent = false
    }
  })

  if (!allHaveCorrectAnswer) {
    validation.messages.push(<MrTranslate id={"TrueFalse.please_select_correct_answer_for_all_statement"}/>)
  }

  if (!allHaveContent) {
    validation.messages.push(<MrTranslate id={"TrueFalse.all_statement_text_is_required_msg"}/>)
  }

  if (allHaveCorrectAnswer && allHaveContent) {
    validation.success = true
  }

  return validation
}

export const validateRBDInlineMatchingJSON = (json) => {
  const teacher_json = json;
  const segment_data = teacher_json.segment_data || {};
  const columns = segment_data.columns || [];
  const distractors = segment_data.distractors || [];
  console.log("Validation json and columns ==>", columns);

  const validation = {
    success: false,
    messages: [],
  };

  let allHaveContentOrFile = true;

  columns.map((column) => {
    if (!column.title && !(column.files && column.files.length)) {
      allHaveContentOrFile = false;
    } else {
      column.items.map((item) => {
        if (!item.content && !(item.files && item.files.length)) {
          allHaveContentOrFile = false;
        }
      });
    }
  });

  distractors.map((distractor) => {
    if (!distractor.content && !(distractor.files && distractor.files.length)) {
      allHaveContentOrFile = false;
    }
  });

  if (!allHaveContentOrFile) {
    validation.messages.push(<MrTranslate id={"CommonText.all_text_boxes_is_required"}/>);
  }

  if (allHaveContentOrFile) {
    validation.success = true;
  }

  return validation;
};


export const validateRBDInlineSortingJSON = (json) => {
	const teacher_json = json
	const segment_data = teacher_json.segment_data || {}
	const columns = segment_data.columns || []

	const validation = {
		success: false,
		messages: []
	}

	let allHaveContentOrFile = true

	columns.map((column) => {
		column.items.map((item) => {
			if (!item.content && !(item.files && item.files.length)) {
				allHaveContentOrFile = false
			}
		})
	})

	if (!allHaveContentOrFile) {
		validation.messages.push(<MrTranslate id={"CommonText.all_text_boxes_is_required"}/>)
	}

	if (allHaveContentOrFile) {
		validation.success = true
	}

	return validation
}

export const validateRBDInlineClassifyJSON = (json) => {
  const teacher_json = json
  const segment_data = teacher_json.segment_data || {}
  const columns = segment_data.columns || []
  const distractors = segment_data.distractors || []

  const validation = {
    success: false,
    messages: []
  }

  let allHaveContentOrFile = true

  columns.map((column) => {
    if (!column.title && !(column.files && column.files.length)) {
      allHaveContentOrFile = false
    } else {
      column.items.map((item) => {
        if (!item.content && !(item.files && item.files.length)) {
          allHaveContentOrFile = false
        }
      })
    }
  })

  distractors.map((distractor) => {
    if (!distractor.content && !(distractor.files && distractor.files.length)) {
      allHaveContentOrFile = false
    }
  })

  if (!allHaveContentOrFile) {
    validation.messages.push(<MrTranslate id={"CommonText.all_text_boxes_is_required"}/>)
  }

  if (allHaveContentOrFile) {
    validation.success = true
  }

  return validation
}

export const validateRBDMatchingJSON = (json) => {
	const teacher_json = json
	const segment_data = teacher_json.segment_data
	const validation = {
		success: true,
		messages: []
	}

	return validation
}

export const validateRBDSortingJSON = (json) => {
	const teacher_json = json
	const segment_data = teacher_json.segment_data
	const validation = {
		success: true,
		messages: []
	}

	return validation
}

export const validateRBDClassifyJSON = (json) => {
	const teacher_json = json
	const segment_data = teacher_json.segment_data
	const validation = {
		success: true,
		messages: []
	}

	return validation
}

export const validateFabricDrawingJSON = (json) => {
  const teacher_json = json;
  const segment_data = teacher_json.segment_data;
  const validation = {
    success: true,
    messages: [],
  };

  return validation;
};

export const validateFabricDnDLabelJSON = (json) => {
  const teacher_json = json;
  const segment_data = teacher_json.segment_data || {};
  const canvas_data = segment_data.canvas_data || {};
  const objects = canvas_data.objects || [];
  const validation = {
    success: false,
    messages: [],
  };
  let atleastOneBackGroundImage, atleastOneLabel;
  let allHaveContent = true;

  objects.map((object) => {
    if (object.name == "Background Image") {
      atleastOneBackGroundImage = true;
    }

    if (object.name == "Label Text") {
      atleastOneLabel = true;
      if (object.text == object.placeholder) {
        allHaveContent = false;
      }
    }
  });

  if (!atleastOneBackGroundImage) {
    validation.messages.push(<MrTranslate id={"CommonText.atleast_one_background_image_required"}/>);
  }

  if (!atleastOneLabel) {
    validation.messages.push(<MrTranslate id={"FabricLabel.atleast_one_label_is_required"}/>);
  }

  if (!allHaveContent) {
    validation.messages.push(<MrTranslate id={"FabricLabel.all_label_is_required"}/>);
  }

  if (atleastOneBackGroundImage && atleastOneLabel && allHaveContent) {
    validation.success = true;
  }

  return validation;
};

export const validateFabricFillLabelJSON = (json) => {
  const teacher_json = json;
  const segment_data = teacher_json.segment_data || {};
  const canvas_data = segment_data.canvas_data || {};
  const objects = canvas_data.objects || [];
  const validation = {
    success: false,
    messages: [],
  };

  let atleastOneBackGroundImage, atleastOneLabel;
  let allHaveContent = true;

  objects.map((object) => {
    if (object.name == "Background Image") {
      atleastOneBackGroundImage = true;
    }

    if (object.name == "Label Text") {
      atleastOneLabel = true;
      if (object.text == object.placeholder) {
        allHaveContent = false;
      }
    }
  });

  if (!atleastOneBackGroundImage) {
    validation.messages.push(<MrTranslate id={"CommonText.atleast_one_background_image_required"}/>);
  }

  if (!atleastOneLabel) {
    validation.messages.push(<MrTranslate id={"FabricLabel.atleast_one_label_is_required"}/>);
  }

  if (!allHaveContent) {
    validation.messages.push(<MrTranslate id={"FabricLabel.all_label_is_required"}/>);
  }

  if (atleastOneBackGroundImage && atleastOneLabel && allHaveContent) {
    validation.success = true;
  }

  return validation;
};

export const validateFabricHotspotJSON = (json) => {
  const teacher_json = json;
  const segment_data = teacher_json.segment_data || {};
  const canvas_data = segment_data.canvas_data || {};
  const objects = canvas_data.objects || [];
  const validation = {
    success: false,
    messages: [],
  };
  let atleastOneBackGroundImage, atleastOneMarkedArea, atleastOneHotspot;
  objects.map((object) => {
    if (object.name == "Background Image") {
      atleastOneBackGroundImage = true;
    }

    if (hotspotShapes.indexOf(object.name)) {
      atleastOneMarkedArea = true;
    }

    if (object.checkedHotspot) {
      atleastOneHotspot = object.checkedHotspot;
    }
  });

  if (!atleastOneBackGroundImage) {
    validation.messages.push(<MrTranslate id={"CommonText.atleast_one_background_image_required"}/>);
  }
  if (!atleastOneMarkedArea) {
    validation.messages.push(<MrTranslate id={"FabricHotspot.atleast_one_area_to_be_marked_for_hotspot"}/>);
  }
  if (!atleastOneHotspot) {
    validation.messages.push(
      <MrTranslate id={"FabricHotspot.atleast_one_correct_hotspot_is_required"}/>
    );
  }
  if (atleastOneBackGroundImage && atleastOneMarkedArea && atleastOneHotspot) {
    validation.success = true;
  }

  return validation;
};


export const validateGGBGraphJSON = (json) => {
  const teacher_json = json;
  const segment_data = teacher_json.segment_data;
  const validation = {
    success: true,
    messages: [],
  };

  return validation;
};

export const validateDesmosGraphJSON = (json) => {
  const teacher_json = json;
  const segment_data = teacher_json.segment_data;
  const validation = {
    success: true,
    messages: [],
  };

  return validation;
};

export const validateConnectJSON = (json) => {
  const teacher_json = json;
  const segment_data = teacher_json.segment_data || {};
  const hints = segment_data.hints || [];
  const answers = segment_data.answers || [];

  const validation = {
    success: false,
    messages: [],
  };

  let allHaveContentOrFile = true;

  hints.map((hints) => {
    if (!hints.content && !(hints.files && hints.files.length)) {
      allHaveContentOrFile = false;
    }
  });
  answers.map((ca) => {
    if (!ca.content && !(ca.files && ca.files.length)) {
      allHaveContentOrFile = false;
    }
  });

  if (!allHaveContentOrFile) {
    validation.messages.push(<MrTranslate id={"CommonText.all_text_boxes_is_required"}/>);
  }

  if (allHaveContentOrFile) {
    validation.success = true;
  }

  return validation;
};

export const validateRDSTableJSON = (json) => {
  const teacher_json = json;
  const segment_data = teacher_json.segment_data;
  const validation = {
    success: true,
    messages: [],
  };

  return validation;
};

export const validateCKEFillTextJSON = (json) => {
  const teacher_json = json;
  const segment_data = teacher_json.segment_data || {};
  const gaps = segment_data.gaps || [];
  const validation = {
    success: false,
    messages: [],
  };
  let atleastOneGap,
    allGapsAreValid = true;
  gaps.map((gap) => {
    const gapElement = document.querySelectorAll(`[data-id="${gap.id}"]`);
    if (gapElement.length) {
      const parentElement = gapElement[0].closest(".ap-text-input");
      parentElement.classList.remove("ap-text-input-error");
      if (gap.invalid) {
        allGapsAreValid = false;
        parentElement.classList.add("ap-text-input-error");
      }
      atleastOneGap = true;
      gap.archived = false;
    } else {
      gap.archived = true;
    }
  });

  if (!atleastOneGap) {
    validation.messages.push(<MrTranslate id={"CKEFillText.atleast_one_text_input_required_msg"}/>);
  }
  if (!allGapsAreValid) {
    validation.messages.push(<MrTranslate id={"CommonText.atleast_one_correct_answer_required_msg"}/>);
  }

  if (atleastOneGap && allGapsAreValid) {
    validation.success = true;
  }

  return validation;
};

export const validateCKEFillDropDownJSON = (json) => {
  const teacher_json = json;
  const segment_data = teacher_json.segment_data || {};
  const gaps = segment_data.gaps || [];
  const validation = {
    success: false,
    messages: [],
  };
  let atleastOneGap,
    allGapsAreValid = true;
  gaps.map((gap) => {
    const gapElement = document.querySelectorAll(`[data-id="${gap.id}"]`);
    if (gapElement.length) {
      const parentElement = gapElement[0].closest(".ap-drop-down-input");
      parentElement.classList.remove("ap-drop-down-input-error");
      if (gap.invalid) {
        allGapsAreValid = false;
        parentElement.classList.add("ap-drop-down-input-error");
      }
      atleastOneGap = true;
      gap.archived = false;
    } else {
      gap.archived = true;
    }
  });

  if (!atleastOneGap) {
    validation.messages.push(<MrTranslate id={"CKEFillDropDown.atleast_one_drop_down_required_msg"}/>);
  }
  if (!allGapsAreValid) {
    validation.messages.push(<MrTranslate id={"CommonText.atleast_one_correct_answer_required_msg"}/>);
  }

  if (atleastOneGap && allGapsAreValid) {
    validation.success = true;
  }

  return validation;
};

export const validateMiniQuizJSON = (json) => {
  const segment_data = json.segment_data || {}
  const questions = segment_data.questions || []

  const validation = {
    success: false,
    messages: []
  }

  let allQuestionsValidated = true
  questions.map((question, index) => {
    // let currentQuestionString = `Question ${index + 1}`
    let currentQuestionString = <MrTranslate id={"MiniQuiz.question"} values={{intex: index + 1 }}/>
    if (!question.points) {
      // validation.messages.push(`${currentQuestionString} points required`)
      validation.messages.push(<MrTranslate id={"MiniQuiz.points_required_msg"} values={{currentQuestionString: currentQuestionString}}/>)
      allQuestionsValidated = false
    }

    if (!question.content) {
      // validation.messages.push(`${currentQuestionString} content is required`)
      validation.messages.push(<MrTranslate id={"MiniQuiz.content_is_required_msg"} values={{currentQuestionString: currentQuestionString}}/>)
      allQuestionsValidated = false
    }

    if (question.question_type == 'mcq_single') {
      const questionValidation = validateMCQSingleJSON(question.teacher_json)
      if (!questionValidation.success) {
        questionValidation.messages.map((message) => {
          validation.messages.push(`${currentQuestionString} ${message}`)
        })
        allQuestionsValidated = false
      }
    } else if (question.question_type == 'mcq_multiple') {
      const questionValidation = validateMCQMultipleJSON(question.teacher_json)
      if (!questionValidation.success) {
        questionValidation.messages.map((message) => {
          validation.messages.push(`${currentQuestionString} ${message}`)
        })
        allQuestionsValidated = false
      }
    } else if (question.question_type == 'cke_subjective') {
      if (!question.answer_explanation) {
        // validation.messages.push(`${currentQuestionString} explanation required`)
        validation.messages.push(<MrTranslate id={"MiniQuiz.explanation_required_msg"} values={{currentQuestionString: currentQuestionString}}/>)
        allQuestionsValidated = false
      }
    }
  })

  if (!allQuestionsValidated) {
    // validation.messages.push('All text boxes is required.')
  }

  if (allQuestionsValidated) {
    validation.success = true
  }

  return validation
}

export const validateFlashCardJSON = (json) => {
  const segment_data = json.segment_data || {}
  const cards = segment_data.cards || []

  const validation = {
    success: false,
    messages: []
  }

  let allHaveContent = true
  cards.forEach((card) => {
    card.items.forEach((item) => {
      if (!item.content && !item.files) {
        allHaveContent = false
      }
    })
  })

  if (!allHaveContent) {
    validation.messages.push(<MrTranslate id={"CommonText.all_text_boxes_is_required"}/>)
  }

  if (allHaveContent) {
    validation.success = true
  }

  return validation
}


export const validateJSON = (type, json) => {
	console.log("Validate json type ==>", type)
	if (!type || !json) {
		// return 'Required'
		return {
			success: false,
			messages: ["Required"]
		}
	}

	const validateJSONFunctions = {
		accordian: (json) => validateAccordionJSON(json),
		image: (json) => validateImageJSON(json),
		audio: (json) => validateAudioJSON(json),
		video: (json) => validateVideoJSON(json),
		pdf: (json) => validatePDFJSON(json),
		simulation: (json) => validateSimulationJSON(json),
    embed: (json) => validateEmbedJSON(json),
		mcq_single: (json) => validateMCQSingleJSON(json),
		mcq_multiple: (json) => validateMCQMultipleJSON(json),
		true_false: (json) => validateTrueFalseJSON(json),
		rbd_inline_matching: (json) => validateRBDInlineMatchingJSON(json),
		rbd_inline_sorting: (json) => validateRBDInlineSortingJSON(json),
		rbd_inline_classify: (json) => validateRBDInlineClassifyJSON(json),
		rbd_matching: (json) => validateRBDMatchingJSON(json),
		rbd_sorting: (json) => validateRBDSortingJSON(json),
		rbd_classify: (json) => validateRBDClassifyJSON(json),
		fabric_drawing: (json) => validateFabricDrawingJSON(json),
		fabric_dnd_label: (json) => validateFabricDnDLabelJSON(json),
		fabric_fill_label: (json) => validateFabricFillLabelJSON(json),
		fabric_hotspot: (json) => validateFabricHotspotJSON(json),
		ggb_graph: (json) => validateGGBGraphJSON(json),
		desmos_graph: (json) => validateDesmosGraphJSON(json),
    connect: (json) => validateConnectJSON(json),
		rds_table: (json) => validateRDSTableJSON(json),
		cke_fill_text: (json) => validateCKEFillTextJSON(json),
		cke_fill_dropdown: (json) => validateCKEFillDropDownJSON(json),
		mini_quiz: (json) => validateMiniQuizJSON(json),
		flash_card: (json) => validateFlashCardJSON(json),
    doc: (json) => validateDocumentJSON(json),
    ppt: (json) => validatePPTJSON(json),
	}

	if (validateJSONFunctions[type]) {
		return validateJSONFunctions[type](json)
	} else {
		return {success: true}
	}
}

export const autoCheckableQuestionTypes = [
  "mcq_single",
  "mcq_multiple",
  "true_false",
  "rbd_inline_matching",
  "rbd_inline_sorting",
  "rbd_inline_classify",
  "fabric_dnd_label",
  "fabric_fill_label",
  "fabric_hotspot",
  "mini_quiz",
  "cke_fill_text",
  "cke_fill_dropdown",
];

export const numaricalSort = (array) => {
	return array.sort(function (a, b) { return a - b })
}

export const usePollingEffect = (shouldPoll, callback, interval = 10000) => {

  useEffect(() => {
    if (!shouldPoll) return;
    const intervalId = setInterval(() => {
      callback(() => clearInterval(intervalId));
    }, interval);

    return () => clearInterval(intervalId);
  }, [shouldPoll]);
};

