import React from "react";
import PropTypes from 'prop-types';

import { MrSelect, MrCrudView, MrForm as WrappedMrForm, FormRenderer } from "mr_react_framework";
import { actions } from "./redux";
import ExperienceListItem from "../ExperienceListItem/ExperienceListItem";
import ExperienceList from "../ExperienceList/ExperienceList";
import ExperiencesFilter from "../ExperiencesFilter/ExperiencesFilter";
import ExperienceItemDetail from "/src/views/Experiences/ExperienceShow/ExperienceItemDetail";
// import WrappedMrForm from "../../lib/utils/MrForm/MrForm";
import commonSelectors from "/src/App/commonSelectors";
import {groupsSelector} from "../../Groups/selector";
import {buildOptionsArr} from "/src/lib/utils/helperMethods";
import {actions as genericActions} from "/src/App/genericRedux";
// import TextArea from "/src/views/Segments/TextArea/TextArea";
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent";
import _ from "lodash";
import {Switch, Space} from "antd";
import { libraryexperiencesSelector } from "../selector";
import {getFromLS} from "/src/lib/utils/helperMethods"
import generateExperienceCreateForm from "../ExperienceForms/ExperienceCreateForm";
import { enabledFeaturesSelector, partnerNameSelector } from "/src/views/Auth/Login/selector";
import CustomExperienceCrudList from "../CustomExperienceCrudList";
import { orgSubjectsSelector } from "/src/views/Orgs/selector";
import CustomSegmentsCrudList from "../CustomSegmentsCrudListLayout";
import MrTranslate, { mrTranslate } from "/src/lib/MrTranslate/MrTranslate";


export const returnForm = (props) => {
  console.log("test form props", props, getFromLS("activeOrgProgrammeId"));

  let form = generateExperienceCreateForm("libraryexperience", props)

  return form
};

const processExperienceCreateFormInitialValues = (data, props) => {
  console.log("initial data ====>", data,props);
  const { formProps = {} } = props
  let newData = { ...data };
  const enabledFeatures = formProps.enabledFeatures
  const relationItems = newData?.relation_items || []
  if(enabledFeatures.enable_standards_content_area_tag_multiple && relationItems && relationItems[0]?.standard_collection_content_area_items) {

    const standard_set_tag_data = []
    const standard_collection_content_area_items = relationItems[0].standard_collection_content_area_items
    
    standard_collection_content_area_items.forEach((item) => {
      const customFields = item?.custom_fields || {}
      if(!item.archived) {
        standard_set_tag_data.push({
          content_area_id: item?.tag_id,
          content_area_label: customFields?.content_area_label,
          grade_group_id: customFields?.grade_group_id,
          grade_group_label: customFields?.grade_group_label,
          standard_collection_label: customFields?.standard_collection_label,
          standard_collection_id: customFields?.standard_collection_id,
        })
      }
    })
    newData.relation_items[0].standard_set_tag_data = standard_set_tag_data;
  }
  
  return newData
}

const getForms = (props) => {
  console.log("getForms =====>", props);
  // const { mrIntl } = props
  const { intl } = props
  const mrIntl = mrTranslate(intl); 
  
  const forms = [
    // Only need this form here for create/edit - if we want a different form - for now, all forms in Experiences.js
    { name: "libraryexperiences", form: returnForm, config: { processInitialValues: processExperienceCreateFormInitialValues, title: {show: true, value: "assessment"}, submitConfig: {success: {showMessage: true, message: mrIntl("CommonText.assessment_saved_successfully")}, error: {showMessage: true, message: mrIntl("CommonText.error_in_saving_assessment")}}, actions: {submitText: mrIntl("CommonText.save"), showCancelBtn: false} } }, 
    
    // NO need for these forms - opening experience in this case and in TodayExp - so all there
    // { name: "experiencesSettings", form: returnSettingsForm }, 
    // { name: "experiencesPublishedSettings", form: returnSettingsForm }, 
    // { name: "experiencesCollaborators", form: returnCollaboratorsForm, config: { isModal: true }},
    // { name: "experiencesInvigilators", form: returnInvigilatorsForm, config: { isModal: true }},
    // { name: "experiencesStudents", form: returnStudentsForm}
  ]
  return forms
}

const LibraryExperiences = MrCrudView({
  resourceName: "libraryexperiences",
  singleResourceName: "experience",
  // displayName: "Create/Edit Tests",
  resourceUrl: "/experiences",
  // layout: OrgsLayout,
  filter: ExperiencesFilter,
  // listHeader: ExperienceHeader,
  isRoute: true,
  selectorsObj: {
    ...commonSelectors,
    // classesForMultiselect: groupsSelector,
    libraryexperiences: libraryexperiencesSelector,
    subjects: orgSubjectsSelector,
    partnerName: partnerNameSelector,
    enabledFeatures: enabledFeaturesSelector,
  },
  listItem: ExperienceListItem,
  forms: getForms,
  actions,
  actionNames: ["fetch", "create", "show", "update", "duplicate", "delete"],
  // firstTimeFetchParams: { with_users: true }
  config: {
    itemPreview: {
      widget: ExperienceListItem
    },
    itemDetail: {
      widget: ExperienceItemDetail
    },
    pagination: {
      show: true,
    },
    filter: {
      show: false,
      style: {
        width: 6,
        align: "right"
      }
    },
    core: {
      setQueryParams: true,
    },
    list: {
      show: true,
      // type: "table/list/custom",
      layout: CustomExperienceCrudList,
      widget: ExperienceList,
      // forceReload: true
    },
    uuid: {
      active: true,
      key: "uid",
    }
  },
});

// LibraryExperiences.defaultProps = {};

LibraryExperiences.propTypes = {};
export default LibraryExperiences;
