import React, { useState } from "react";
import SetAccommodationBtn from "../../../Users/UsersList/SetAccommodationBtn";
import AccommodationModal from "../../../Users/UsersList/AccommodationModal";
import { message } from "/src/components/UI/AntdAppHelper";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const StudentAccommodationsMonitor = (props) => {
  console.log('StudentAccommodationsMonitor===>>>>>>', props);
  const { item, updateRecord, itemState, experienceDuration, buttonConfig, countDownTimer, modalConfig } = props;
  const mrIntl = useTranslate();
  const isStudentOnline = itemState?.state === "online";
  const isSubmittedTest = item?.currentView === "submittedTest";

  const [isAccommodationModalOpen, setIsAccommodationModalOpen] = useState(false);

  const handleAccommodationChange = (accommodations) => {
    console.log("handleAccommodationChange ==>", accommodations, item);
    let data = { accommodations };
    let options = {};
    const updatedKeys = Object.keys(accommodations).filter((key) => accommodations[key] !== item.accommodations?.[key]);

    updatedKeys.forEach((key) => {
      let changedAccommodationMessage = "";
  
      if (key === "extra_time_data" && accommodations[key].extra_time && item.extra_time  !== accommodations[key].extra_time) {
        data = {
          ...data,
          ...accommodations[key]
        };
        changedAccommodationMessage = `Extra time added: ${accommodations.extra_time_data.extra_time / 60000} minutes`;
        delete data.accommodations[key];
      } else if (key === "spellcheck") {
        changedAccommodationMessage = accommodations[key] ? "Spell check enabled" : "Spell check disabled";
      } else if (key === "calc_enabled") {
        changedAccommodationMessage = accommodations[key] ? "Calculator enabled" : "Calculator disabled";
      } else if (key === "graphing_calc_enabled") {
        changedAccommodationMessage = accommodations[key] ? "Graphing calculator enabled" : "Graphing calculator disabled";
      } else if (key === "tts_allowed") {
        changedAccommodationMessage = accommodations[key] ? "Text to speech enabled" : "Text to speech disabled";
      } else if (key === "stt_allowed") {
        changedAccommodationMessage = accommodations[key] ? "Speech to text enabled" : "Speech to text disabled";
      }
      if (changedAccommodationMessage) {
        options = {
          log: {
            logging: true,
            action: "accommodation_changed",
            user_id: item.user_id,
            current_value: changedAccommodationMessage,
          },
        };
        updateRecord(item.uid, data, item, options);
      }
    });
    message.success(mrIntl("StudentAccommodationsMonitor.accommodations_updated_successfully"));
  };

  const tooltipTitle = !isStudentOnline
  ? mrIntl("CommonText.cannot_perform_action_when_student_is_offline")
  : isSubmittedTest
  ? mrIntl("CommonText.cannot_perform_action_after_student_submission")
  : "";

  return (
    <>
      <SetAccommodationBtn
        key={`SetAccommodationBtn-${item.uid}`}
        openAccommodationModal={() => setIsAccommodationModalOpen(true)}
        {...buttonConfig}
      />
      <AccommodationModal
        name={item?.name}
        shouldDisabled={!isStudentOnline || isSubmittedTest}
        tooltipTitle={tooltipTitle}
        isAccommodationModalOpen={isAccommodationModalOpen}
        accommodations={item?.accommodations}
        monitorView={true}
        addTimeBtnProps={{
          item: item,
          itemState: itemState,
          experienceDuration: experienceDuration,
          updateRecord: updateRecord,
        }}
        onChange={handleAccommodationChange}
        closeModal={() => setIsAccommodationModalOpen(false)}
        onOK={true}
        countDownTimer={countDownTimer}
        {...modalConfig}
      />
    </>
  )
}

export default StudentAccommodationsMonitor;