import React from "react";

import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import BasicCKE from "../CKEditor/BasicCKE";
import {
  DeleteButton,
  TextArea,
  InputArea,
  DragHandle,
  ShowText,
  ShowTextIndex,
  ShowTextContent,
  ShowTextFile,
} from "../Segment/UIHelper";
import MediaHoverButton from "/src/components/UI/Media/MediaHoverButton";
import MediaShow from "/src/components/UI/Media/MediaShow";
import { RiDragMoveLine } from "react-icons/ri";
import { liftDraggableItem } from "/src/views/Segments/DnDHelpers";
import { useSelector } from "react-redux";
import { getEmbeddedSelector, isMBIframeSelector, partnerNameSelector } from "/src/views/Auth/Login/selector";

const DraggableContainer = styled.div`
  margin: 2px;
  // width: 100%;
  min-width: 100px;
  max-width: 300px;
  white-space: break-spaces;
  border: 1px solid #eee;
  height: ${(props) => (props.stretchHeight ? "100%" : "")};
  color: ${(props) =>
    props.isDragging && props.isMBIframe ? "white" : "black"};
  background-color: ${(props) =>
    // console.log("Props in background color ==>", props.isDragging)
    props.isDragging
      ? props.isMBIframe
        ? "#4b8ffa"
        : "lightcoral"
      : // : '#f0f0f0'
        "#fafafa"};
`;

function getStyle(style, snapshot, props) {
  console.log("style props ==>", style, snapshot, props);

  if (!props.hidePlaceholder) {
    return style;
  }

  if (!snapshot.isDragging) return {};

  if (!snapshot.isDropAnimating) {
    return style;
  }

  // cannot be 0, but make it super tiny
  style = {
    ...style,
    transitionDuration: `0.001s`,
  };

  console.log("updated style ==>", style);

  return style;
}

export const getRenderItem = (props, items) => (provided, snapshot, rubric) => {
  // const partnerName = useSelector(partnerNameSelector());
  // const embedded = useSelector(getEmbeddedSelector());

  const isMBIframe = useSelector(isMBIframeSelector());


  const item = items[rubric.source.index];

  console.log("Item with render ==>", props, item, rubric.source.index);
  console.log("provided and snapshot ==>", provided, snapshot, rubric);

  let className = "";
  if (props.direction == "horizontal") {
    className += "inline-draggable";
  }

  if (props.draggingDisabled || props.forcedDraggingDisabled) {
    className += " dragging-disabled";
  }

  if (props.showCorrect) {
    if (item.correct) {
      className += " correct-answer";
    } else {
      className += " incorrect-answer";
    }
  }

  const style = getStyle(provided.draggableProps.style, snapshot, props);
  console.log("Classname in draggable item ==>", className, props);

  const innerRender = props.showTextArea ? (
    <TextArea>
      <DragHandle key={`drag-handle-${item.id}`} {...provided.dragHandleProps}>
        <RiDragMoveLine />
      </DragHandle>
      <InputArea>
        <BasicCKE
          key={`basic-ck-${item.id}`}
          itemId={item.id}
          data={item.content}
          onTextChanged={(data) => props.onTextChanged(props.index, data)}
        ></BasicCKE>
        {item.files && (
          <MediaShow
            file={item.files && item.files[0]}
            removeFile={(e) => props.removeFile(props.index)}
          ></MediaShow>
        )}
      </InputArea>
      {props.onSaveMedia && (
        <MediaHoverButton
          key={`media-hover-button-${item.id}`}
          onSaveMedia={(files) => props.onSaveMedia(props.index, files)}
        ></MediaHoverButton>
      )}
      {props.removeItem && (
        <DeleteButton
          key={`delete-button-${item.id}`}
          clicked={(e) => props.removeItem(props.index)}
        >
          <DeleteOutlined />
        </DeleteButton>
      )}
    </TextArea>
  ) : (
    <ShowText
      key={`show-text-${item.id}`}
      {...provided.dragHandleProps}
      className="draggable-content"
      onMouseUp={(event) => {
        if (props.sensorAPI) {
          liftDraggableItem(
            event,
            props.draggableId,
            props.liftedItem,
            props.sensorAPI
          );
        }
      }}
    >
      {item.content && (
        <ShowTextContent
          key={`show-text-content-${item.id}`}
          dangerouslySetInnerHTML={{ __html: item.content }}
        ></ShowTextContent>
      )}
      {item.files && (
        <ShowTextFile>
          <MediaShow
            file={item.files && item.files[0]}
            removeIcon={false}
          ></MediaShow>
        </ShowTextFile>
      )}
    </ShowText>
  );

  return (
    <>
      <DraggableContainer
        key={`draggable-container-${item.id}`}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...props}
        isDragging={snapshot.isDragging}
        style={{ ...style }}
        className={className}
        isMBIframe={isMBIframe}
      >
        {innerRender}
      </DraggableContainer>
      {snapshot.isDragging ? (
        <DraggableContainer
          {...props}
          key={`draggable-container-copy-${item.id}`}
          isDragging={snapshot.isDragging}
          className={className}
          isMBIframe={isMBIframe}
        >
          {innerRender}
        </DraggableContainer>
      ) : null}
    </>
  );
};

export const RBDDraggableItem = (props) => {
  const { draggableProps } = props;

  if (draggableProps.draggingDisabledIndexArray) {
    draggableProps.forcedDraggingDisabled =
      draggableProps.draggingDisabledIndexArray.indexOf(draggableProps.index) >=
      0;
  }

  console.log(
    "draggableProps in draggable item ==>",
    draggableProps,
    draggableProps.forcedDraggingDisabled
  );
  const renderItem = getRenderItem(draggableProps, draggableProps.list.items);

  return draggableProps.item.placeholder ? (
    <Draggable
      key={`draggable-${draggableProps.draggableId}`}
      draggableId={draggableProps.draggableId}
      index={draggableProps.index}
      isDragDisabled={
        draggableProps.draggingDisabled || draggableProps.forcedDraggingDisabled
      }
    >
      {renderItem}
    </Draggable>
  ) : (
    ""
  );
};
