import React, { useState } from "react";
import { MrSelect } from "mr_react_framework";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { useSelector } from "react-redux";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import _ from "lodash";

const SelectStrands = MrSelect({
  config: {
    isJsx: true,
    processData: (data, props) => {
      let options = []
      return options
    },
    widgetConfig: {
      showSearch: true,
      placeholder: <MrTranslate id={"StrandsForm.select_strands_placeholder"}/>,
    },
  },
});

const StrandsForm = (props) => {
  let { criteriumAssociations, onChange } = props;
  const mrIntl = useTranslate()
  let defaultValue = [];
  criteriumAssociations && criteriumAssociations.forEach((data) => {
    data && data.strands && data.strands.length > 0 && data.strands.forEach((strand) => {
      // defaultValue.push(strand.id)
      defaultValue.push(strand.label)
    })
  })

  const [value, setValue] = useState(defaultValue);
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const currentUser = useSelector(currentUserSelector());
  console.log("StrandsForm activeExperience", activeExperience, currentUser);
  // Note: going into ops to get the grade code corresponding to the grade label in the exp. Need code for strands
  let expGrade
  let orgProgrammes = currentUser.org.org_programmes
  if(orgProgrammes){
    let mypOP = orgProgrammes.find(op => op.code === "myp")
    console.log("StrandsForm orgProgrammes", orgProgrammes, mypOP);
    if(mypOP){
      expGrade = mypOP.grades.find(grade => grade.code === (activeExperience && activeExperience.relation_items && activeExperience.relation_items.length && activeExperience.relation_items[0].grade_code))
    }
  }

  const handleChange = (value) => {
    onChange(value)
    setValue(value)
  };

  let filteredData = [];

  if (criteriumAssociations && expGrade) {
    criteriumAssociations.forEach((data) => {
      const criteriaTitle = data.custom_fields.title;
      activeExperience.rubric.criteria.forEach((value) => {
        if (value.title === criteriaTitle) {
          let strands = value.strands && value.strands.length > 0 && value.strands.find(item => item.grade_code === expGrade.code)
          console.log("StrandsForm strands", value, strands, expGrade);
          const options = strands && strands.strands.length > 0 ? strands.strands.map((strand) => {
            return {
              // label: strand.label,
              label: `${strand.label} - ${strand.desc}`,
              value: strand.label
            };
          }) : [{ label: mrIntl("CommonText.no_data"), options: "No data", disabled: true }];
          filteredData.push({
            label: criteriaTitle,
            options: options
          });
        }
      });
    });

    filteredData = _.orderBy(filteredData, ["label"], ["asc"]) 
  }
  

  let finalRender = [];
  finalRender.push(
    <SelectStrands
      value={value}
      onChange={handleChange}
      config={{
        widgetConfig: {
          style: { width: "500px" },
          options: filteredData,
          mode: "multiple",
        },
      }}
    />
  );

  return finalRender;
};

export default StrandsForm;