import loadable from "@loadable/component";
import { Space } from "antd";
import MediaShow from "/src/components/UI/Media/MediaShow";
import { ShowText, ShowTextContent } from "/src/components/UI/Segment/UIHelper";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import React from "react";

const ReactPlayer = loadable(() =>
  import(/* webpackChunkName: "react-player" */ "react-player")
);

const SegmentContentDisplay = ({ segment, createCondition }) => {
  const mrIntl = useTranslate();

  const segmentType = segment && segment.segment_type;
  const segmentResourceType =
    segmentType === "resource" &&
    segment.resource_segment_attributes.resource_type;
  const segmentQuestionAttributes =
    segmentType === "question" && segment.question_segment_attributes;
  const segmentResourceAttributes =
    segmentType === "resource" &&
    segment.resource_segment_attributes.resource_json.segment_data
      .attachments &&
    segment.resource_segment_attributes.resource_json.segment_data
      .attachments[0];

  const isBasedOnText =
    createCondition === "based_on" && segmentType === "text";

  const isBasedOnQuestion =
    (createCondition === "based_on" || createCondition === "similar") &&
    segmentQuestionAttributes.question_type === "cke_subjective";

  const isBasedOnVideo =
    createCondition === "based_on" &&
    segmentType === "resource" &&
    segmentResourceType === "video";

  const isBasedOnImage =
    createCondition === "based_on" &&
    segmentType === "resource" &&
    segmentResourceType === "image";

  const isBasedOnPdf =
    createCondition === "based_on" &&
    segmentType === "resource" &&
    segmentResourceType === "pdf";

  return (
    <>
      {isBasedOnText && (
        <>
          <span>{mrIntl("GenerateAIWithPrompt.based_on_this_text")}</span>
          <ShowText>
            {/* <RenderHtml text={content} /> */}
            <ShowTextContent
              className="show-text-answer"
              dangerouslySetInnerHTML={{
                __html: segment.text_segment_attributes.content,
              }}
            ></ShowTextContent>
          </ShowText>
        </>
      )}
      {isBasedOnQuestion && (
        <>
          <span>{mrIntl("GenerateAIWithPrompt.similar_to_this_question")}</span>
          <ShowText>
            {/* <RenderHtml text={content} /> */}
            <ShowTextContent
              className="show-text-answer"
              dangerouslySetInnerHTML={{
                __html: segmentQuestionAttributes.content,
              }}
            ></ShowTextContent>
          </ShowText>
        </>
      )}
      {isBasedOnImage && (
        <>
          <span>{mrIntl("GenerateAIWithPrompt.based_on_this_image")}</span>
          <MediaShow mediaType={"image"} file={segmentResourceAttributes} />
        </>
      )}
      {isBasedOnPdf && (
        <>
          <span>{mrIntl("GenerateAIWithPrompt.based_on_this_pdf")}</span>
          <MediaShow mediaType={"pdf"} file={segmentResourceAttributes} />
        </>
      )}
      {isBasedOnVideo && (
        <Space direction="vertical" className="video-player-space">
          <span>{mrIntl("GenerateAIWithPrompt.based_on_this_video")}</span>
          <ReactPlayer
            key={`react-player`}
            width={"100%"}
            height={"300px"}
            // ref={mediaPlayer}
            url={
              segmentType === "resource" &&
              segmentResourceType === "video" &&
              segmentResourceAttributes.url
            }
          />
        </Space>
      )}
    </>
  );
};

export default SegmentContentDisplay;
