import { Button, Dropdown, Input, Space, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { EditOutlined, LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useDispatch, useSelector } from "react-redux";
import { aiSegmentsSelector, segmentsCreateLoadingSelector } from "../selector";
import { currentUserSelector, enabledFeaturesSelector } from "../../Auth/Login/selector";
import { activeAdjustedExperienceSelector } from "../../Experiences/selector";
import { aiGenerateQuestionsConfigSelector } from "./selector";
import { getAIServiceBaseURL, getDestinationPositionLex, getFromLS, updateConfigHeaderForDemoUseStagingAI } from "/src/lib/utils/helperMethods";
import axios from "axios";
import { actions as aiSegmentActions } from "./redux"
import { message } from "/src/components/UI/AntdAppHelper";
import { getSegmentObject } from "./questionTypeObjects";
import { isNewGenerateQuestionsWithAI, questionTypesRequiringAnswers } from "./generateAIHelperMethods";


const ModifyQuestionInput = ({
  activeEditQuestion,
  setActiveEditQuestion,
  questionType,
  setGeneratedQuestions,
  question,
  setShowModifyAIResultCommands,
  showModifyAIResultCommands,
  modifyQuestionLoading,
  setModifyQuestionLoading
}) => {
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const aiGenerateQuestionsConfig = useSelector(aiGenerateQuestionsConfigSelector());
  const currentUser = useSelector(currentUserSelector());
  const allQuestions = useSelector(aiSegmentsSelector())
  const dispatch = useDispatch()
  const mrIntl = useTranslate()
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  const {
    marks,
    criteria,
    strands,
    syllabusTopicLabel,
    paperTypeLabel,
    teachingLevelLabel,
    keyConceptsLabels,
    relatedConceptsLabels,
    globalContextLabel,
    possibleExplorationLabel,
    atlSkillsLabels,
    language,
    totalAIQuestionsGenerated,
    additionalInstructions,
    basedOnValue,
    useExamples,
    qbUUID,
    teachingLevelId,
    paperTypeId,
    commandTermId,
    syllabusTopicUUID
  } = aiGenerateQuestionsConfig;

  const questionBankCustomFields =
    activeExperience?.questionbank?.custom_fields || {};
  const relationItem = activeExperience?.relation_items?.[0] || {};
  const customFields = activeExperience?.custom_fields || {};

  const programmeLabel = questionBankCustomFields.programme_name;

  const subjectId = relationItem.subject_id;
  const gradeId = relationItem.grade_id;
  const orgProgrammeId = relationItem.org_programme_id;
  const programmeId = relationItem.programme_id;

  const orgProgrammeLabel = customFields.org_programme_item_label;
  const subjectLabel = customFields.subject_item_label;
  const gradeLabel = customFields.grade_item_label;

  const activeExperienceRubricTypeC = activeExperience?.rubric?.type_c;

  const items = [
    {
      key: "Make it easier",
      label: "Make it easier",
    },
    {
      key: "Make it harder",
      label: "Make it harder",
    },
    {
      key: "Make it shorter",
      label: "Make it shorter",
    },
    {
      key: "Make it longer",
      label: "Make it longer",
    },
  ];

  const processCommand = async (command, question) => {
    setModifyQuestionLoading(true);
    const token = getFromLS("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    config = updateConfigHeaderForDemoUseStagingAI(config)


    const questionSegmentAttributes = question.question_segment_attributes || {}    
    let dataToPost = {
      action: "modify",
      segment_type: "question",
      child_segment_type: questionType,
      question_text: questionSegmentAttributes.content,
      criteria: criteria,
      strands: strands,
      teaching_level_id: teachingLevelId,
      syllabus_topic_uuid: syllabusTopicUUID,
      paper_type_id: paperTypeId,
      command_term_id: commandTermId,
      syllabus_topic_label: syllabusTopicLabel,
      paper_type_label: paperTypeLabel,
      key_concept_labels: keyConceptsLabels,
      related_concept_labels: relatedConceptsLabels,
      global_context_label: globalContextLabel,
      possible_exploration_label: possibleExplorationLabel,
      atl_skills_labels: atlSkillsLabels,
      teaching_level_label: teachingLevelLabel,
      prompt: command,
      subject_id: subjectId,
      grade_id: gradeId,
      org_programme_id: orgProgrammeId,
      programme_id: programmeId,
      programme_label: programmeLabel,
      org_programme_label: orgProgrammeLabel,
      subject_label: subjectLabel,
      grade_label: gradeLabel,
      use_examples: useExamples,
      output_language: language,
    };

    if (activeExperienceRubricTypeC === "points") {
      dataToPost.points = parseFloat(marks)
    }
    if (additionalInstructions) {
      dataToPost = {
        ...dataToPost,
        additional_instructions: additionalInstructions
      }
    }
    if(useExamples) { // so that qb_uuid is not sent if !useExamples
      dataToPost = {
        ...dataToPost,
        qb_uuid: qbUUID
      }
    }
    if(basedOnValue && basedOnValue.length > 0) {
      dataToPost = {
      ...dataToPost,
      based_on: basedOnValue,
      }
    }

    const questionContentKeys = {
      mcq: 'options',
      true_false: 'statements',
    };
    
    if (questionContentKeys[questionType]) {
      const contentKey = questionContentKeys[questionType];
      const items = questionSegmentAttributes.teacher_json?.segment_data?.[contentKey] || [];
      const joinedContent = items.map(item => item.content).join(", ");
      
      dataToPost.question_text = `${questionSegmentAttributes.content}\n${questionType === 'mcq' ? 'Options' : 'Statements'}: ${joinedContent}`;
    }    
    // Ignore -- Not modifying task and nested questioon 
    // if (questionType === "task" || questionType === "nested_question") {
    //   const finalSubParts = question.sub_parts.map((item, index) => {
    //     return `\n${index + 1}. ${item.question}`;
    //   });
    //   dataToPost.question_text = `${question.question}\nSub Parts: ${finalSubParts}`;
    // }
    await axios
      .post(`${getAIServiceBaseURL()}/generate-segments`, dataToPost, config)
      .then(function (response) {
        console.log("finalResponse==>", response);
        let finalResponse = response.data.questions
        if(finalResponse && finalResponse.length > 0) {
          finalResponse = finalResponse[0]
          if (questionTypesRequiringAnswers.includes(questionType) && !finalResponse.answer) {
            throw new Error("The generated question requires an answer, but no answer was provided.");
          }
          const modifiedSegment = getSegmentObject({
            questionType,
            questionData: finalResponse,
            experienceId: activeExperience.id,
            userId: currentUser.id,
            marks,
            destinationPosition: getDestinationPositionLex(allQuestions[activeEditQuestion].position, allQuestions[activeEditQuestion + 1]?.position),
            rubricType: activeExperienceRubricTypeC,
            criteria,
            strands,
          });
          dispatch(
            aiSegmentActions.createSuccess(
              { data: { segment: modifiedSegment } },
              { success: { showMessage: false } }
            )
          );
          dispatch(
            aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
              data: {
                ...aiGenerateQuestionsConfig,
                prevPosition: getDestinationPositionLex(
                  aiGenerateQuestionsConfig.prevPosition,
                  aiGenerateQuestionsConfig.nextPosition
                ),
                totalAIQuestionsGenerated: totalAIQuestionsGenerated + 1,
              },
            })
          );
          setModifyQuestionLoading(false);
          setActiveEditQuestion(null);
          setInputValue("");
        } else {
          throw new Error("The response does not contain the modified question as expected.");
        }
        //  else {
        //   if (questionType === "task") {
        //     const finalQuestions = [...allQuestions];
        //     finalResponse = {
        //       sub_parts: response.data.questions,
        //       question: response.data.text,
        //     };
        //     finalQuestions.splice(activeEditQuestion + 1, 0, finalResponse);
        //     setGeneratedQuestions(finalQuestions);
        //     setActiveEditQuestion(null);
        //     setInputValue("");
        //   } else if (
        //     response?.data?.questions?.length > 0
        //   ) {
        //     console.log("finalQuestions==>", response.data);
        //     finalResponse = response.data.questions[0];
        //     const finalQuestions = [...allQuestions];
        //     finalQuestions.splice(activeEditQuestion + 1, 0, finalResponse);
        //     setGeneratedQuestions(finalQuestions);
        //     setModifyQuestionLoading(false);
        //     setActiveEditQuestion(null);
        //     setInputValue("");
        //   }
        // }
      })
      .catch(function (error) {
        message.error(mrIntl("ModifyQuestionInput.something_went_wrong_please_try_again"));
        setModifyQuestionLoading(false);
      });
  };

  return (
    <>
      <Space>
        <Dropdown
          disabled={modifyQuestionLoading}
          menu={{
            items,
            onClick: ({ item, key }) => {
              setShowModifyAIResultCommands(false);
              processCommand(key, question);
              setInputValue(key);
            },
          }}
          open={showModifyAIResultCommands}
        >
          <Input
            disabled={modifyQuestionLoading}
            ref={inputRef}
            placeholder={mrIntl("ModifyAIQuestion.ask_ai_to_write_or_edit")}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              showModifyAIResultCommands && setShowModifyAIResultCommands(false);
            }}
            onPressEnter={(e) => {
              e.preventDefault();
              if (!inputValue || (inputValue && !inputValue.trim())) {
                return;
              }
              processCommand(inputValue, question);
              // }
            }}
          />
        </Dropdown>
        {!modifyQuestionLoading && (
          <Button
            ghost
            size="small"
            type="text"
            danger
            onClick={() => {
              setActiveEditQuestion(null);
              setShowModifyAIResultCommands(false);
            }}
            icon={<CloseOutlined />}
          />
        )}
        {modifyQuestionLoading && <LoadingOutlined spin />}
      </Space>
    </>
  );
};
const ModifyAIQuestion = ({
  index,
  showModifyAIResultCommands,
  setShowModifyAIResultCommands,
  addAllButtonLoading,
  isStreaming = false,
  question, 
  questionType,
  aiGenerateLoading = false,
  activeEditQuestion,
  setActiveEditQuestion
}) => {
  const mrIntl = useTranslate()
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const segmentsCreateLoading = useSelector(segmentsCreateLoadingSelector());

  let newGenerateQuestionsWithAI = isNewGenerateQuestionsWithAI(enabledFeatures)
  const [modifyQuestionLoading, setModifyQuestionLoading] = useState(false);

  useEffect(() => {
    if((newGenerateQuestionsWithAI && aiGenerateLoading) || addAllButtonLoading) {
      if(activeEditQuestion !== null) {
        setActiveEditQuestion(null);
      }
    }
  }, [aiGenerateLoading, addAllButtonLoading])
  
  return ( 
    <Space>
      {activeEditQuestion === index && (
        <ModifyQuestionInput
          showModifyAIResultCommands={showModifyAIResultCommands}
          setShowModifyAIResultCommands={setShowModifyAIResultCommands}
          activeEditQuestion={activeEditQuestion}
          setActiveEditQuestion={setActiveEditQuestion}
          questionType={questionType}
          question={question}
          modifyQuestionLoading={modifyQuestionLoading}
          setModifyQuestionLoading={setModifyQuestionLoading}
        />
      )}
      <Tooltip title={mrIntl("ModifyAIQuestion.modify")}>
        <Button
          type="primary"
          size="medium"
          ghost={false}
          shape="round"
          icon={<EditOutlined />}
          disabled = {modifyQuestionLoading || addAllButtonLoading || isStreaming || segmentsCreateLoading || aiGenerateLoading}
          onClick={() => {
            setActiveEditQuestion(index);
            setShowModifyAIResultCommands(true);
          }}
        >
          {mrIntl("ModifyAIQuestion.modify")}
        </Button>
      </Tooltip>
    </Space>
  );
};

export default ModifyAIQuestion;
