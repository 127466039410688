import React, { useEffect, useRef, useState, useCallback } from "react";
import { Route, useRouteMatch, useLocation } from "react-router-dom";
import { Button, Card, Col, Collapse, Row, Space, Tag, Tooltip } from "antd";
import { SyncOutlined, EditOutlined, CheckOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  currentUserSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";
import { activeAdjustedExperienceIdSelector, activeExperienceGradingDisabledSelector } from "/src/views/Experiences/selector";
import { ExperienceUserSelect } from "./ExperienceUserSelect";
import {
  activeExperienceUserIdSelector,
  activeExperienceUserSelector,
  experienceUsersLoadingSelector,
  experienceUsersSelector,
  showConvertedAchievementLevelSelector,
} from "../selector";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import { ExperienceUserSwitcher } from "./ExperienceUserSwitcher";
import ExperienceUserActions from "./ExperienceUserActions";
import TopicQuestionSwitcher from "./TopicQuestionSwitcher";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import ViewUploadsModal from "/src/components/UI/ViewUploadsModal";
import { checkMob, displayPoints, isStringEmpty, renderMathInElement } from "/src/lib/utils/helperMethods";
import Spinner from "/src/components/UI/Spinner/Spinner";
import ExperienceUserOverallGradingForm from "./ExperienceUserOverallGradingForm";
import { dbUserResponsesSelector } from "/src/views/UserResponses/selector";
import PublishResultButton from "./PublishResultButton";
import ShowMediaComments from "/src/views/UserResponses/ShowMediaComments";
import ExperienceUserGradingProgressBar from "./ExperienceUserGradingProgressBar";
import ExperienceUserOverallPoints from "../ExperienceUsersList/ExperienceUserOverallPoints";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import TaskSpecificClarification from "/src/views/Experiences/ExperienceShow/ExperienceManage/TaskSpecificClarification/TaskSpecificClarification";
import { useLoadingCheck } from "../ExperienceUsersHelpers";

// const CustomErrorBoundary = import.meta.env.VITE_MODE === "production" ? Sentry.ErrorBoundary : Alert.ErrorBoundary;
const ExperienceUserHeader = (props) => {
  console.log("ExperienceUserHeader props ==>", props);
  const {
    // item,
    experience,
    gradingConfig,
    setGradingConfig,
    setCurrentExperienceUser,
    exportModalConfig = {},
    setExportModalConfig,
    mbGradeBookEmbeddedView,
    closeModal,
    updateResource
  } = props;
  
  const [loading, setLoading] = useState(false);
  const [collapseActiveKey, setCollapseActiveKey] = useState([]);
  const [showGradingForm, setShowGradingForm] = useState(null)

  const activeExperienceGradingDisabled = useSelector(
    activeExperienceGradingDisabledSelector()
  );
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const currentUser = useSelector(currentUserSelector());
  const experienceUser = useSelector(activeExperienceUserSelector());
  const dispatch = useDispatch();
  const experienceSettings = experience.settings || {};
  const experienceRubric = experience.rubric || {};
  const item = experienceUser;
  const isMobile = checkMob();
  const mrIntl = useTranslate();
  const commentRef = useRef()
  
  let media_comments;
  if (item.custom_fields && item.custom_fields.media_comments) {
    media_comments = item.custom_fields.media_comments;
  } 
  const checkIfLoadingCompleted = useLoadingCheck();
  
  const tscEnabled = experienceSettings.tsc_enabled
  const showTaskSpecificClarification = tscEnabled && experienceRubric.type_c === "myp_achievement_level";

  let finalRender = null;
  const { Panel } = Collapse;

  useEffect(() => {
    if (commentRef.current) {
      renderMathInElement(commentRef.current);
    }
  }, []);

  let isStudent =
    currentUser.role === "student" || currentUser.role === "parent"
      ? true
      : false;

  const isStudentReviewerMode = experience.student_reviewer_mode
  
  let isMYPAchievementLevel =
    experience.rubric && experience.rubric.type_c === "myp_achievement_level"
      ? true
      : false;

  let headerConfig = {
    showReviewedByTitle: 
      experienceSettings.peer_review_enabled &&
      isStudent && 
      !isStudentReviewerMode,
    showExperienceUserSwitcher: 
      ((gradingConfig.gradingEnabled && !isStudent) || 
        experienceSettings.peer_review_enabled) && 
        !mbGradeBookEmbeddedView,
    showLimitedExperienceUserHeaderForMYPAL: 
      mbGradeBookEmbeddedView && 
      isMYPAchievementLevel,
    showTopicQuestionSwitcher: 
      isStudent && 
      !isStudentReviewerMode 
        ? item.marked 
        : true,
    showPublishButton: gradingConfig.gradingEnabled,
    showExperienceUserHeaderExtraActionsButton:
      gradingConfig.gradingEnabled && !mbGradeBookEmbeddedView && 
      (!isStudent),
  }

  console.log("headerConfig ===>", headerConfig)

  let isStudentMarked = 
    isStudent &&
    !isStudentReviewerMode 
      ? item.marked 
      : true;
  // const shouldDisplayPoints = isStudentMarked && experienceRubric.type_c !== "myp_achievement_level" && experienceRubric.type_c !== "criteria_with_points";
  // const overAllCommentsStyle = {padding: "0 12px"}
  let displayUserName =
    item.name && item.name.length > 15
      ? `${item.name && item.name.substring(0, 15)}...`
      : item.name;

  // let showPts = false;
  // if (
  //   experienceRubric.type_c !== "comments_only" && experienceRubric.type_c !== "myp_achievement_level" && experienceRubric.type_c !== "criteria_with_points" &&
  //   experienceRubric.type_c !== "custom" &&
  //   experience.points
  // ) {
  //   showPts = true;
  // }

  // let criteriaPointsView = [];
  // if (experience.criterium_associations) {
  //   experience.criterium_associations.forEach((ca, i) => {
  //     const currentCriteriaPoints = item.submission_criterium_associations_attributes && item.submission_criterium_associations_attributes.find((sca) => sca.custom_fields.title === ca.custom_fields.title) || {}
  //     criteriaPointsView.push(
  //       <Tag key={`criteria-points-${ca.id}`} color="blue">
  //         {ca.custom_fields.title} {displayPoints(currentCriteriaPoints.points)}{ca.points && "/"}{displayPoints(ca.points)}
  //       </Tag>
  //     );
  //   });
  // }

  const handlePointsClick = () => {
    if(gradingConfig.gradingEnabled) {
      if(collapseActiveKey.length > 0) {
        setCollapseActiveKey([]);  
      } else {
        setCollapseActiveKey(['1']);
      }
    }
  }

  let overallGradingCommentsRender = (
    <Space direction="vertical">
      <b>
        {mrIntl("ExperienceUserHeader.comments")}
      </b>
      {isStringEmpty(experienceUser.comments) 
        ? <p>{mrIntl("ExperienceUserHeader.no_comments_given")}</p> 
        : <div className="overall-comment" dangerouslySetInnerHTML={{ __html: item.comments }} ref={commentRef}></div>}
      {media_comments && (
        <div> <ShowMediaComments media_comments={media_comments} /></div>
      )}
    </Space>
  );

  let formEU = { ...item };
  if (experienceSettings.grading_setting === "per_question") {
    delete formEU.points;
    delete formEU.submission_criterium_associations_attributes;
    // modifying to remove points from item so that when giving only comments (we want to show comments in all cases), pts don't get overridden by old value
  }

  // let overallGradingForm = <ExperienceUserOverallGradingForm 
  //                             experience={experience} 
  //                             showFormVal={"experienceUsers"} 
  //                             renderForm={props.renderForm}
  //                             isModal={false}
  //                           ></ExperienceUserOverallGradingForm>
  
  let experienceUserSwitcher = (
    <ExperienceUserSwitcher 
      item={item} 
      checkIfLoadingCompleted={checkIfLoadingCompleted} 
      gradingConfig={gradingConfig}
      showReviewedByTitle={headerConfig.showReviewedByTitle}
    ></ExperienceUserSwitcher>
  );

  let topicQuestionsSwitcher = (
    <TopicQuestionSwitcher
      checkIfLoadingCompleted={checkIfLoadingCompleted}
    ></TopicQuestionSwitcher>
  );

  let experienceUserHeaderExtraActionsButton = (
    <ExperienceUserActions
      buttonText={mrIntl("CommonText.actions")}
      showPublishButton={false}
      experienceUser={experienceUser}
      experience={experience}
      updateResource={updateResource}
      currentUser={currentUser}
      gradingConfig={gradingConfig}
      exportModalConfig={exportModalConfig}
      setExportModalConfig={setExportModalConfig}
      closeModal={closeModal}
    ></ExperienceUserActions>
  );

  let publishBtn = <PublishResultButton item={item} updateResource={updateResource} experience={experience}/>;
 
  let responseAttachments = (
    <ViewUploadsModal count={"View uploads"} type={"default"} shape={"round"}
    size={"small"} item={experienceUser}/>
  );
  
  // let totalPts = !isMobile &&
  //   (showPts ? (
  //     <Tag color="blue" onClick={() => handlePointsClick()}>{displayPoints(item.points)}/{displayPoints(experience.points)} Pts</Tag>
  //   ) : (
  //     <span onClick={() => handlePointsClick()}>{criteriaPointsView}</span>
  //   ))

  const handleClick = () => {
    dispatch(experienceUsersActions.setOverallGradingFormVisibleSuccess({visible: true}))
  }
  let totalPts = <ExperienceUserOverallPoints 
    eu={item} 
    exp_cas={experience.criterium_associations} 
    rubricType={experienceRubric.type_c} 
    inExperienceUserHeader={true} 
    onClick={!mbGradeBookEmbeddedView && gradingConfig.gradingEnabled && handleClick } 
  />

 
  useEffect(() => {
    setShowGradingForm(false)
    let timeout = setTimeout(() => {setShowGradingForm(true)}, 1)
    return () => {
      clearTimeout(timeout)
    }
  }, [item.id])
  const rubricType = experienceRubric.type_c;

  const shouldDisplayCriteriaPoints = experienceUser.marked && (rubricType === "criteria_with_points" || rubricType === "myp_achievement_level");

  const shouldShowEquivalentAchievementLevel = useSelector(showConvertedAchievementLevelSelector());
  
  const euScas = experienceUser.submission_criterium_associations_attributes || [];
  let criteriaPointsView = [];
  let convertedCriteriaLevelsView = [];
  if (experience.criterium_associations) {
    experience.criterium_associations.forEach((ca, i) => {
      const currentSCA = euScas && euScas.find((sca) => sca.custom_fields.title === ca.custom_fields.title) || {}
      const title = ca.custom_fields.title || ""
      const currentSCACustomFields = currentSCA.custom_fields || {};

      let naSpanRender = <span style={{"fontSize": "10px"}}>N/A</span>

      if(rubricType === "myp_achievement_level") {
        let points;
        points = currentSCA.points != null ? displayPoints(currentSCA.points) : currentSCACustomFields.is_na ? naSpanRender : "-"
        criteriaPointsView.push(<Tag color="blue">{title} {points}</Tag>)
      } else {
        let caMaxPoints = ca.points
        criteriaPointsView.push(<Tag color="blue">{title} {displayPoints(currentSCA.points)}/{displayPoints(caMaxPoints)}</Tag>)

        if (shouldShowEquivalentAchievementLevel) {
          let convertedLevel = currentSCACustomFields.converted_achievement_level != null ? displayPoints(currentSCACustomFields.converted_achievement_level) : currentSCACustomFields.is_na ? naSpanRender : "-"
          convertedCriteriaLevelsView.push(<Tag color="blue">{title} {convertedLevel}/8</Tag>)
        }
      }
    });
  }




  return (
    <Collapse
      size="large"
      className="experience-user-header-collapse"
      // defaultActiveKey={ ? ["1"] }
      expandIcon={() => null}
      activeKey={!gradingConfig.gradingEnabled ? ["1"] : collapseActiveKey}
      // defaultActiveKey={!gradingConfig.gradingEnabled && ['1']}
    >
      <Panel
        // collapsible={"icon"}  // NOTE: Working only after antd 4.24
        header={
          <Row className="panel-header" onClick={(e) => e.stopPropagation()}>
            <Col span={10} style={{ textAlign: "left" }}>
              <Space>
                {/* {headerConfig.showExperienceUserSwitcher && <ExperienceUserGradingProgressBar item={item} />} */}
                {headerConfig.showExperienceUserSwitcher
                  ? experienceUserSwitcher
                  : displayUserName}
                {isStudentMarked ? (
                  totalPts
                ) : (
                  <>
                    <Tooltip
                      // title={`Submitted on ${dayjs(item.created_at).format("MMM DD, HH:mm a")}`}
                      title={mrIntl("ExperienceUserHeader.submitted_on", {
                        submittedOn: dayjs(item.created_at).format(
                          "MMM DD, HH:mm a"
                        ),
                      })}
                    >
                      <Tag color="processing" icon={<CheckOutlined />}>
                        {mrIntl("CommonText.submitted")}
                      </Tag>
                    </Tooltip>
                    <Tag color="warning" icon={<SyncOutlined spin />}>
                      {mrIntl("CommonText.awaiting_grading")}
                    </Tag>
                  </>
                )}
              </Space>
              {showTaskSpecificClarification &&
                <TaskSpecificClarification className='task-specific-clarification-button-grading' />
              }
            </Col>
            <Col span={8}>
              {" "}
              {headerConfig.showTopicQuestionSwitcher
                ? topicQuestionsSwitcher
                : ""}
            </Col>
            <Col
              span={6}
              style={{ textAlign: "right" }}
              className="experience-user-right-side-actions"
            >
              <Space>
                {/* {isStudentMarked ? totalPts : <><Tooltip
                  title={`Submitted on ${dayjs(item.created_at).format("MMM DD, HH:mm a")}`}
                >
                  <Tag color="processing" icon={<CheckOutlined />}>
                    Submitted
                  </Tag>
                </Tooltip>
                  <Tag color="warning" icon={<SyncOutlined spin />}>
                    Awaiting grading
                  </Tag></>} */}
                {headerConfig.showPublishButton && publishBtn}
                {/* {viewUploadsButton} */}
                {gradingConfig.gradingEnabled &&
                  headerConfig.showExperienceUserHeaderExtraActionsButton &&
                  experienceUserHeaderExtraActionsButton}
              </Space>
            </Col>
          </Row>
        }
        key="1"
      >
        {/* {isStudent ? (
          item.marked && (
            <span>
              <p>{!isStringEmpty(item.comments) ? "Teacher's comments" : "No comments given"}</p>
              <span>{item.marked && overallGradingCommentsRender}</span>
            </span>
          )
        ) : currentUser.role === "support" ? (
          <span>{overallGradingCommentsRender}</span> // isOverallGrading ?
        ) : (
          <span>
            <p style={overAllCommentsStyle}>
              Overall grading &nbsp;
                {shouldDisplayPoints && <Tag color="blue">{displayPoints(item.points)}/{displayPoints(experience.points)}</Tag>}
            </p>
            <p>{!item.marked ? showGradingForm ? overallGradingForm : <Spinner /> : ""}</p>
            <span>{item.marked && overallGradingCommentsRender}</span>
          </span>
        )} */}

        {/* Still using the collapse in open state for student side and MB embedded */}
        {shouldDisplayCriteriaPoints && <Space size={"large"}>
          {/* Overall grading &nbsp; */}
            <Card className="grading-results-card">
              <Space direction="vertical">
                <b>{rubricType === "criteria_with_points" ? "Criteria points": "Achievement Level"}</b>
                {/* criteriaPointsView is for AL and CWP */}
                <Space>{criteriaPointsView}</Space>
              </Space>
            </Card>
            {shouldShowEquivalentAchievementLevel && (
              <Card className="grading-results-card">
                <Space direction="vertical">
                  <b>
                    {mrIntl(
                      "ExperienceUserOverallGradingForm.equivalent_achievement_level"
                    )}
                  </b>
                  <Space>{convertedCriteriaLevelsView}</Space>
                </Space>
              </Card>
            )}
        </Space>
        }
        <br />
        {isStudent && experienceUser.marked && (
          <>
            {overallGradingCommentsRender}
          </>
        )}
      </Panel>
    </Collapse>
  );
};

export default ExperienceUserHeader;
