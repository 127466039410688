import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { message } from '/src/components/UI/AntdAppHelper';
import { setToLS } from "/src/lib/utils/helperMethods";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "experience",
  actionNames: [
    "FETCH",
    "CREATE",
    "SHOW",
    "UPDATE",
    "DUPLICATE",
    "DELETE",
    "SET_VIEW_MODE",
    "SET_MONITOR_CONFIG",
    "PRINT",
    "SET_ACTIVE",
    "SET_INTERNET_STATUS",
    "SET_USER_IP",
    "SET_SERVER_TIME_OFFSET",
    "FETCH_WITH_JOIN_CODE",
    "EXPORT",
    "EXPORT_STATUS",
    "UPDATE_STATUS",
    "SET_OFFLINE_DB_STATUS",
    "SET_PRINT_MODE",
    "SET_PRINT_OPTIONS",
    "SET_SWITCH_FROM_START_TO_RESUME_VIEW",
    "SET_CONFIG",
    "MAKE_EXPERIENCE_USER",
    "GET_ANALYTICS",
    "SET_CALC_TYPE",
    "VERIFY_JOIN_CODE",
    "SET_SKIP_FOCUS_LOST_MODAL",
    "SET_HIGHLIGHT_TEXT",
    "TOPICS_AND_SEGMENTS_FETCH",
    "SET_START_TEST_BTN_LOADING",
    "SET_END_SESSION_BTN_LOADING",
    "SET_AI_GRADING_SETTINGS",
    "SET_JOIN_TEST_BTN_LOADING",
    "BULK_AUTO_MAPPING_PROGRESS",
    "TRANSLATE_ASSESSMENT_STATUS",
    "GRADE_ALL_SUBMISSIONS_WITH_AI_STATUS"
  ],
  message,
  messageConfig: {
    top: 45
  },
});

const initialState = fromJS({
  experiences: [],
  printExperience: {},
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
  internetStatus: "online",
  userIp: "",
  serverTimeOffset: null,
  experienceConfig: {
    isMBTask: true,
    isPamojaTask: true,
    mbGradeBookEmbeddedView: true,
    pageHeader: {
      back: {
        show: true,
      },
      title: {
        show: true,
        inlineEdit: true,
      },
      subtitle: {
        show: true,
      },

      resource: {
        show: true, // based on manage.canEdit below
      },
      preview: {
        show: true,
      },
      rearrange: {
        show: true,
      },
      changeStatus: {
        show: true,
      },
      joinCode: {
        show: true,
      },
      duplicate: {
        show: true,
      },
      points: {
        show: true,
      },
      criteria: {
        show: true,
      },
      deliveryMode: {
        show: true,
      },
      selectInvigilators: {
        show: true,
      },
      selectCollaborators: {
        show: true,
      },
      progressBar: {
        show: true,
      },
      instructions: {
        show: true,
      },
      questionsCount: {
        show: true,
      },
      userName: {
        show: true,
      },
      endTest: {
        show: true,
        disabled: true,
      },
      timer: {
        show: false,
      },
      calculator: {
        enabled: true,
      },
      qrModal: {
        show: true,
      },
      bookmark: {
        show: true,
        disabled: true,
      },
      video: {
        apVideoMonitoring: {
          enabled: true,
          // jitsiConfig: getJitsiConfig,
        },
        externalLink: {
          show: true,
          openInNewTab: true,
        },
      },
      mobileUploadDone: {
        show: true,
      },
    },
    manage: {
      canEdit: true,
      create: {
        show: true,
        alerts: {
          published: {
            show: true,
          },
          closed: {
            show: true,
          },
        },
        topics: {
          layout: {
            span: 4,
            offset: 1,
          },
          list: {
            show: true,
          },
          create: {
            showFirst: true,
            show: true,
            disabled: false,
          },
          edit: {
            enabled: true,
            disabled: false,
          },
          delete: {
            enabled: true,
            disabled: false,
          },
        },
        segments: {
          layout: {
            span: 18,
            offset: 1,
          },
          list: {
            show: true,
          },
          create: {
            showFirst: true,
            show: true,
            disabled: false,
            content: {
              enabled: true
            },
            questions: {
              enabled: true
            },
            games: {
              enabled: false
            },
            addFromLibrary: {
              enabled: true
            }
          },
          edit: {
            enabled: true,
            disabled: false,
          },
          delete: {
            enabled: true,
            disabled: false,
          },
        },
      },
      deliver: {
        show: true,
        alerts: {
          draft: {
            show: true,
          },
          published: {
            show: true,
          },
          closed: {
            show: true,
          },
        },
        settings: {
          show: true,
          mode: {
            show: true,
            disabled: true,
          },
        },
        studentMemberships: {
          show: true,
          disabled: true,
          allowGuests: {
            show: true,
          },
          mappedStudents: {
            show: true,
            disabled: true,
            allowOnline: true,
            selectable: true,
          },
        },
      },
      monitor: {
        show: true,
        realtime: {
          enabled: true,
        },
        liveResponses: {
          // teacher can choose to see one particular live response
          enabled: true,
        },
        liveQuiz: {
          enabled: true, // also in take - SID in sync between teacher and student - teacher controls - one at a time
        },
        filters: {
          show: true,
          search: {
            show: true,
          },
          group: {
            show: true,
          },
        },
        addTime: {
          show: true,
        },
        timer: {
          show: true,
        },
        apVideoMonitoring: {
          show: true,
        },
        studentList: {
          show: true,
        },
      },
      grade: {
        show: true,
      },
    },
    takeExperience: {
      firstView: "joined",
      // separate pageHeader here OR use above and have role/experienceViewMode conditions there
      
      focusLost: {
        enabled: true,
      },
      nativeSecurity: {
        // @uttam @abhimanyu for example this can be changed from monitor page - so a watcher for userInfoFromReduxNativeSecurity will set this as false? obv set this later inside the take component, only default here false
        // enabled:
          // currentUser.role === "student" &&
          // (appType === "mac" || appType === "ios") &&
          // experienceSettings.mode === "offline"
          //   ? true
          //   : false,
      },
      video: {
        apVideoMonitoring: {
          enabled: true,
        },
        externalLink: {
          show: true,
          openInNewTab: true,
        },
      },
      spellcheck: {
        enabled: false,
      },
      calculator: {
        enabled: false,
      },
      gamification: {
        enabled: false, // first topic open and earn points to unlock next topics
        leaderboard: {
          show: false,
        },
      },
      engagement: {
        enabled: false,
        video: {
          enabled: false,
          points: 100
        },
        question: {
          enabled: true,
          points: 20
        },
        game: {
          enabled: true,
          points: 50
        }
      },
      segmentFlow: {
        oneByOne: false, // for livequizzes/ slides creation/take one by one
      },
      liveQuiz: {
        enabled: false, // means that SID with live firestore connection to teacher monitor - segment that student sees controlled by teacher. teacher sees all responses/graph in realtime
      },
      join: {
        createExperienceUserOnStart: false,
        startTime: {
          show: true,
          timer: true,
        },
        startTestBtn: {
          // button
          show: true,
          disabled: true,
        },
        quitTestBtn: {
          // button
          show: true,
        },
        backToHomeBtn: {
          show: true,
        },
      },
      start: {
        video: {
          show: true,
        },
        // TODO: NEED to separate segmentConfigFromRedux - embed case
        segment: {
          points: {
            show: true
          },
          ePoints: {
            show: false
          }
        },
        userResponseSave: {
          showSavingStatusIndicator: true,
          cloud: {
            enabled: true,
          },
          local: {
            enabled: true,
          },
          redux: {
            enabled: true
          },
          apDb: {
            enabled: false
          }
        },
        chat: {
          show: true,
        },
        upload: {
          enabled: true, // from setting and mode and appType - override in segmentConfig later as needed
        },
        pageHeader: {
          endButton: {
            show: true
          }
        }
      },
      resume: {
        requestResume: {
          show: true,
        },
      },
      end: {
        timer: {
          show: true,
        },
        progressBar: {
          show: true,
          attemptedQuestions: {
            show: true,
            wide: true,
          },
        },
        uploadSummary: {
          show: true,
        },
        quitTest: {
          // button
          show: true,
        },
        backToStart: {
          show: true,
        },
        video: {
          apVideoMonitoring: {
            enabled: true,
          },
        },
        requestResume: {
          show: true,
        },
      },
      submit: {
        submitTest: {
          show: true,
          disabled: true,
        },
        quitTest: {
          // button
          show: true,
        },
        backToHome: {
          show: true,
        },
        viewResult: {
          show: false,
        },
      },
      result: {
        quitTest: {
          // button
          show: true,
        },
      },
    },
  }
});


function getAnalyticsSuccess(state = initialState, action) {
  if(action.payload.type === "summary"){
    return state
    // .set("printloading", false)
      .set("experienceAnalyticsSummary", action.payload.data);
  }
  if(action.payload.type === "questions"){
    return state
    // .set("printloading", false)
      .set("experienceAnalyticsQuestions", action.payload.data);
  }
  if(action.payload.type === "tags"){
    return state
    // .set("printloading", false)
      .set("experienceAnalyticsTags", action.payload.data);
  }
  if(action.payload.type === "gradebook"){
    return state
    // .set("printloading", false)
      .set("gradebookAnalytics", action.payload.data);
  }
}

function setViewModeSuccess(state = initialState, action) {
  return state.set("experienceViewMode", action.payload.experienceViewMode);
}

function setMonitorConfigSuccess(state = initialState, action) {
  let monitorConfig = {
    sortType: "smart_sort",
  };
  if (action.payload.sortType) {
    monitorConfig = {
      ...monitorConfig,
      sortType: action.payload.sortType,
    };
  }

  return state.set("monitorConfig", monitorConfig);
}

function setActiveSuccess(state = initialState, action) {
  return state.set("activeAdjustedExperienceId", action.payload.id);
}

function setInternetStatusSuccess(state = initialState, action) {
  return state.set("internetStatus", action.payload.status);
}
function setUserIpSuccess(state = initialState, action) {
  return state.set("userIp", action.payload.ip);
}

function setOfflineDBStatusSuccess(state = initialState, action) {
  return state.set("offlineDBStatus", action.payload.status);
}

function setServerTimeOffsetSuccess(state = initialState, action) {
  return state.set("serverTimeOffset", action.payload.serverTimeOffset);
}

function setPrintMode(state = initialState, action) {
  return state.set("printMode", action.payload.printMode)
}
function setPrintOptions(state = initialState, action) {
  return state.set("printOptions", action.payload.printOptions)
}

function setSwitchFromStartToResumeView(state = initialState, action) {
  return state.set("switchFromStartToResumeView", action.payload.switchFromStartToResumeView)
}

function setCalcType(state = initialState, action) {
  return state.set("calcType", action.payload.calcType);
}

function setSkipFocusLostModal(state = initialState, action) {
  return state.set("skipFocusLostModal", action.payload.skipFocusLostModal);
}

function printSuccess(state = initialState, action) {
  // set segments in redux 
  return state
    .set("printloading", false)
    .set("printExperience", action.payload.experience);
}

function setConfigSuccess(state = initialState, action) {
  console.log("setConfigSaga success", action.payload);
  return state
    .set("experienceConfig", {...action.payload.experienceConfig});
}

function setHighlightTextSuccess(state= initialState, action){
  return state
  .set("highlightTextEnabled", action.payload.highlightTextEnabled);
}

function setStartTestBtnLoadingSuccess(state= initialState, action){
  return state
  .set("startTestBtnLoading", action.payload.startTestBtnLoading);
}

function setEndSessionBtnLoadingSuccess(state= initialState, action){
  return state
  .set("endSessionBtnLoading", action.payload.endSessionBtnLoading);
}

function setAIGradingSettingsSuccess(state = initialState, action) {
  const { aiGradingSettings } = action.payload;
  setToLS(
    "aiGradingSettings",
    JSON.stringify(aiGradingSettings)
  );

  return state.set("aiGradingSettings", aiGradingSettings);
}

function setJoinTestBtnLoadingSuccess(state = initialState, action) {
  return state.set("joinTestBtnLoading", action.payload.joinTestBtnLoading);
}

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  [actionTypes.PRINT_EXPERIENCE_SUCCESS]: printSuccess,
  [actionTypes.SET_CONFIG_EXPERIENCE_SUCCESS]: setConfigSuccess,
  [actionTypes.SET_ACTIVE_EXPERIENCE_SUCCESS]: setActiveSuccess,
  [actionTypes.SET_VIEW_MODE_EXPERIENCE_SUCCESS]: setViewModeSuccess,
  [actionTypes.SET_MONITOR_CONFIG_EXPERIENCE_SUCCESS]: setMonitorConfigSuccess,
  [actionTypes.SET_INTERNET_STATUS_EXPERIENCE_SUCCESS]: setInternetStatusSuccess,
  [actionTypes.SET_USER_IP_EXPERIENCE_SUCCESS]: setUserIpSuccess,
  [actionTypes.SET_OFFLINE_DB_STATUS_EXPERIENCE_SUCCESS]: setOfflineDBStatusSuccess,
  [actionTypes.SET_SERVER_TIME_OFFSET_EXPERIENCE_SUCCESS]: setServerTimeOffsetSuccess,
  [actionTypes.SET_PRINT_MODE_EXPERIENCE_SUCCESS]: setPrintMode,
  [actionTypes.SET_PRINT_OPTIONS_EXPERIENCE_SUCCESS]: setPrintOptions,
  [actionTypes.SET_SWITCH_FROM_START_TO_RESUME_VIEW_EXPERIENCE_SUCCESS]: setSwitchFromStartToResumeView,
  [actionTypes.GET_ANALYTICS_EXPERIENCE_SUCCESS]: getAnalyticsSuccess,
  [actionTypes.SET_CALC_TYPE_EXPERIENCE_SUCCESS]: setCalcType,
  [actionTypes.SET_SKIP_FOCUS_LOST_MODAL_EXPERIENCE_SUCCESS]: setSkipFocusLostModal,
  [actionTypes.SET_HIGHLIGHT_TEXT_EXPERIENCE_SUCCESS]: setHighlightTextSuccess,
  [actionTypes.SET_START_TEST_BTN_LOADING_EXPERIENCE_SUCCESS]: setStartTestBtnLoadingSuccess,
  [actionTypes.SET_END_SESSION_BTN_LOADING_EXPERIENCE_SUCCESS]: setEndSessionBtnLoadingSuccess,
  [actionTypes.SET_AI_GRADING_SETTINGS_EXPERIENCE_SUCCESS]: setAIGradingSettingsSuccess,
  [actionTypes.SET_JOIN_TEST_BTN_LOADING_EXPERIENCE_SUCCESS]: setJoinTestBtnLoadingSuccess,
});

export default reduxCrud;
