import React from "react";
import { Alert, Space } from "antd";
import { getBrowser, checkMob } from "/src/lib/utils/helperMethods";
import { useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import DemoResetCountDown from "/src/components/UI/DemoResetCountDown/DemoResetCountDown";
import DemoUserSwitcher from "/src/components/UI/DemoUserSwitcher/DemoUserSwitcher";
import "/src/components/UI/DemoPreHeader/DemoPreHeader.scss";
import { PageHeader } from '@ant-design/pro-layout';

const DemoPreHeader = (props) => {
  console.log("props", props);
  const isMobile = checkMob();
  const currentUser = useSelector(currentUserSelector());
  const org = currentUser.org;

  let showDemoResetCountdown = import.meta.env.VITE_MODE === "demo"; // not on demo-internal
  let subTitle = showDemoResetCountdown ? <DemoResetCountDown /> : "";
  let operations = (
    <Space>
      <DemoUserSwitcher />
    </Space>
  );

  let preHeader = (
    <PageHeader
      className={"demo-pre-header"}
      title={isMobile ? "" : "Demo Mode"}
      subTitle={subTitle}
      backIcon={false}
      extra={operations}
    ></PageHeader>
  );

  return preHeader;
};

export default DemoPreHeader;
