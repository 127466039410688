import React, { useEffect, useState, useRef } from "react";

import { Carousel, Row, Col } from "antd";
import { InboxOutlined, UploadOutlined, SoundOutlined, PauseCircleOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { initialData, addItemToArray, removeItemFromArray, convertDataAsPerNewSystem, getParsedJSONObject, numaricalSort } from "/src/views/Segments/InteractiveHelpers";
import { AddButton, DeleteButton, CheckBox, InputArea, MCQArea, MCQList, TextArea, ShowText, ShowTextIndex, ShowTextContent, CustomTextButton, ShowTextContainer, PlayList } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { MrInput } from "mr_react_framework"
import { debounce } from 'lodash';
import MediaShow from "/src/components/UI/Media/MediaShow"
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const PlayListAudios = props => {
  console.log("PlayList Images props", props);

  const playlist = props.attachments
  const mrIntl = useTranslate()
  const playListAudiosCarousel = useRef()
  const [playListSetting, setPlayListSetting] = useState({
    currentAudioIndex: 0,
    currentAudio: playlist[0],
    playlistCount: playlist.length,
  })

  const showAudio = (switchTo) => {
    let nextIndex, nextAudio
    if (switchTo == 'prev') {
      if (playListSetting.currentAudioIndex == 0) {
        nextIndex = playListSetting.playlistCount - 1
      } else {
        nextIndex = playListSetting.currentAudioIndex - 1
      }
    } else if (switchTo == 'next') {
      if (playListSetting.currentAudioIndex == playListSetting.playlistCount - 1) {
        nextIndex = 0
      } else {
        nextIndex = playListSetting.currentAudioIndex + 1
      }
    } else if (switchTo == 'reset') {
      nextIndex = 0
    } else {
      nextIndex = switchTo
    }
    nextAudio = playlist[nextIndex]
    
    playListAudiosCarousel.current.goTo(nextIndex, true)  
    setPlayListSetting({
      ...playListSetting,
      currentAudioIndex: nextIndex,
      currentAudio: {
        ...nextAudio
      }
    })
  }

  const updateplayListSetting = (index) => {
    setPlayListSetting({
      ...playListSetting,
      currentAudioIndex: index,
      currentAudio: playlist[index]
    })
  }

  console.log("playlist state before render ==>", playListSetting)
  return (
    <Row>
      <Col
        span={12}
        className="audio-playlist-carousel"
      >
        <Carousel
          ref={ref => {
            playListAudiosCarousel.current = ref
          }}
          // afterChange={(index) => updateplayListSetting(index)}
          dotPosition='none'
        >
          {playlist.map((file, index) => (<>
            {(playListSetting.currentAudioIndex == index) && <>
              <ShowText className="playlist-content">
                <h4>{file.title ? file.title : `${"Audio"} ${index + 1}`}</h4>
                {file.files && <MediaShow file={file.files && file.files[0]} mediaType="image"></MediaShow>}
                <ShowTextContent dangerouslySetInnerHTML={{
                  __html: file.caption
                }} ></ShowTextContent>
              </ShowText>
              <MediaShow file={file} mediaType="audio"></MediaShow>
            </>}
          </>)
          )}
        </Carousel>
        <CustomTextButton
          buttonText={mrIntl("PlayListAudios.prev_btn")}
          type="primary"
          // disabled={playListSetting.currentAudioIndex == (playListSetting.playlistCount - 1)}
          clicked={(e) => showAudio('prev')}
        ></CustomTextButton>

        <CustomTextButton
          buttonText={mrIntl("PlayListAudios.next_btn")}
          type="primary"
          // disabled={playListSetting.currentAudioIndex == (playListSetting.playlistCount - 1)}
          clicked={(e) => showAudio('next')}
        ></CustomTextButton>
      </Col>
      <Col span={12}>
        <PlayList>
          {playlist.map((file, index) => (<ShowTextContainer
            onClick={(e) => showAudio(index)}
            className={playListSetting.currentAudioIndex == index && 'selected-audio'}
          >
            <ShowText >
              <ShowTextContent dangerouslySetInnerHTML={{ __html: file.title ? file.title : `${"Audio"} ${index + 1}`}}></ShowTextContent>
            </ShowText>
          </ShowTextContainer>))}
        </PlayList>
      </Col>
    </Row >
  );
}
export default PlayListAudios;