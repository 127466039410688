import React, { useState } from "react";

import { Modal, Drawer, Empty } from "antd";
import MediaShow from "../Media/MediaShow";
import ListAttachments from "/src/components/UI/Attachments/ListAttachments";
import { checkMob } from "/src/lib/utils/helperMethods";

const PreviewAttachment = (props) => {
  console.log("Preview attachment props ==>", props);
  const { visible, type, onCancel, file, files, title, previewMediaConfig = {} } = props;
  const modalTitle = title ? file?.name?.split("/")[1]?.replace(".pdf", "") : null;
  const isMobile = checkMob();
  const modalConfig = previewMediaConfig?.modal;
  const modalWidth = isMobile ? "" : (modalConfig?.width || "50%");
  const modalClassName = isMobile ? "height-centered-modal" : (modalConfig?.extraClass || "preview-attachment-modal");
  let finalRender = null;

  if (file) {
    let previewFileComponent = <MediaShow rotateZoom={true} file={file} mediaShowSetting={previewMediaConfig.mediaShowSetting} />;
    if (type == "modal") {
      if (file.type && file.type.indexOf('image') >= 0) {
        finalRender = (<MediaShow rotateZoom={true} file={file} imagePreview={{ visible: visible, onVisibleChange: onCancel }} />)
      } else {
        finalRender = (
          <Modal
            open={visible}
            title={modalTitle}
            width={modalWidth}
            centered={isMobile ? true : false}
            className={modalClassName}
            footer={null}
            onCancel={onCancel}
          >
            {previewFileComponent}
          </Modal>)
      }
    } else if (type == "drawer") {
      finalRender = (
        <Drawer
          open={visible}
          placement="left"
          key="response-attachment"
          onClose={onCancel}
          mask={false}
          maskClosable={false}
          width={"60%"}
        >
          {props.actionsComponent}
          {files && files.length > 0 ? (
            <ListAttachments
              attachments={files}
              draggableProps={{
                isDragDisabled: true,
              }}
            />
          ) : (
            <Empty description="No files" />
          )}
        </Drawer>
      );
    } else if (type == "list") {
      finalRender = files && files.length > 0 ? (
        <ListAttachments
          attachments={files}
          draggableProps={{
            isDragDisabled: true,
          }}
        />
      ) : (
        <Empty description="No files" />
      );
    } else {
      finalRender = previewFileComponent;
    }
  }

  return finalRender;
};

export default PreviewAttachment;
