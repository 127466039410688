import React, { useEffect, useState } from "react";

import { getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import {
  Segment,
  SegmentData,
  InputArea,
} from "/src/components/UI/Segment/UIHelper";
import { cloneDeep } from "lodash";
import { getUniqueId, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";

import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import TextInputCKE from "/src/views/Segments/CKEFill/TextInputCKE";
import InlineTextInput from "/src/views/Segments/CKEFill/InlineTextInput";
import parse from "html-react-parser";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";

const CKEFillText = (props) => {
  console.log("CKEFillText props", props);

  const {
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
  } = props;
  let {
    parsedJSONObject,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
  } = getParsedJSONObject(props);

  let textAnswerString = props.text_answer || "";
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };

  // const extra = { titlePrefix: 'Match' }
  if (createMode) {
    parsedJSONObject = {
      segment_data: {
        content: "",
        gaps: [],
      },
      segment_version: "1.0.0",
    };
    if (props.question_type == "cke_fill_text") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }

  // if (answerMode) {
  // 	parsedJSONObject = shuffleItems(parsedJSONObject)
  // }

  if (autoCheckMode) {
    let question_json = cloneDeep(props.teacher_json);
    let answer_json = cloneDeep(parsedJSONObject);
    parsedJSONObject = checkResponses(question_json, answer_json);
  }

  const [state, setState] = useState(parsedJSONObject);
  const [textAnswer, setTextAnswer] = useState(textAnswerString);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
    showCorrectAnswerSwitchValue: false,
  });
  const [myAnswer, setMyAnswer] = useState(state);
  const screenSettings = props.screenSettings || {};

  let currentJSON, text_answer, attempt_status;
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject;
    text_answer = textAnswerString;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    text_answer = textAnswer;
    attempt_status = attemptStatus;
  }

  const segment_data = currentJSON?.segment_data || {};
  const content = segment_data.content || "";
  const gaps = segment_data.gaps || [];

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        console.log("Final state saved ==>", teacher_json);
        props.onChange(teacher_json);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          // text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (newState) => {
    setState(newState);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
    console.log("state is getting saved ==> ", newState);
  };

  const saveTextAnswer = (data) => {
    setTextAnswer(data);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  function checkResponses(question_json, answer_json) {
    console.log("Question and Answer json", question_json, answer_json);
    if (!question_json || !answer_json) {
      return;
    }

    let q_segment_data = question_json.segment_data;
    let a_segment_data = answer_json.segment_data;

    let q_gaps = q_segment_data ? q_segment_data.gaps : [];
    let a_gaps = a_segment_data ? a_segment_data.gaps : [];

    let i = 0;
    while (i < q_gaps.length) {
      const q_gap = q_gaps[i];
      const a_gap = a_gaps?.find((gap) => gap.id == q_gap.id);
      let q_items = q_gap.items;

      q_items.map((q_item) => {
        if (
          a_gap &&
          a_gap.answer &&
          removeHTMLTagsFromText(q_item.content) ==
            removeHTMLTagsFromText(a_gap.answer.content)
        ) {
          a_gap.correct = true;
        }
      });

      i++;
    }

    return answer_json;
  }

  const onTextChanged = (data) => {
    const newSegmentData = {
      ...segment_data,
      content: data,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };
    saveState(newState);
  };

  const onGapAnswerChanged = (gap) => {
    const newGaps = gaps;
    const index = gaps.findIndex((g) => g.id == gap.id);

    const correctAnswer = gap.items[0];
    if (index >= 0) {
      const newGap = {
        ...gap,
        answer: correctAnswer,
      };
      newGaps[index] = newGap;
    } else {
      newGaps.push({
        ...gap,
        answer: correctAnswer,
      });
    }

    const newSegmentData = {
      ...segment_data,
      gaps: newGaps,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    console.log("on gaps array save ==>", newState);
    saveState(newState);
  };

  function validateAttempted(json, data) {
    let isAtleastOneChanged = attemptStatus.json_attempted;
    if (data) {
      isAtleastOneChanged = true;
    }

    if (isAtleastOneChanged != attemptStatus.json_attempted) {
      setAttemptStatus({
        ...attemptStatus,
        json_attempted: isAtleastOneChanged,
      });
    }
  }

  const onInlineGapTextChanged = (gap, data) => {
    const index = gaps.findIndex((g) => g.id == gap.id);

    const newGaps = gaps;
    newGaps[index] = {
      ...gap,
      answer: {
        content: data,
      },
    };

    const newSegmentData = {
      ...segment_data,
      gaps: newGaps,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    if (answerMode) {
      validateAttempted(newState, data);
    }

    console.log("On inline answer text changed ===>", newState);
    saveState(newState);
  };

  const checkAnswer = (check = false) => {
    if (check) {
      let question_json = cloneDeep(props.teacher_json);
      let answer_json = cloneDeep(state);
      const newState = checkResponses(question_json, answer_json);

      setMyAnswer(state);
      saveState(newState);
    }

    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: check,
    });

    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const showCorrectAnswer = (value) => {
    let question_json, answer_json;
    if (value) {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(props.teacher_json);
    } else {
      question_json = cloneDeep(props.teacher_json);
      answer_json = cloneDeep(myAnswer);
    }

    const newState = checkResponses(question_json, answer_json);
    saveState(newState);
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: true,
      showCorrectAnswerSwitchValue: value,
    });
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const resetQuestion = () => {
    console.log("Student json ===>", props.student_json);
    const newState = cloneDeep(props.student_json);

    setAttemptStatus({
      ...attemptStatus,
      reset_count: attemptStatus.reset_count + 1,
      json_attempted: false,
    });
    saveState(newState);
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: false,
    });
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  console.log(
    "CKEFillText State before render ==>",
    state,
    stateSetting.quickCheckModeEnabled
  );

  const replace = (item) => {
    console.log("item==>", item);
    if (item.name == "textinput") {
      return (
        <InlineTextInput
          {...item.attribs}
          gaps={gaps}
          answerMode={answerMode}
          autoCheckMode={autoCheckMode}
          stateSetting={stateSetting}
          setRenderMath={setRenderMath}
          onInlineGapTextChanged={(gap, data) =>
            onInlineGapTextChanged(gap, data)
          }
        ></InlineTextInput>
      );
    }
  }

  return (
    <Segment>
      <SegmentData key={`segment-data-${props.unique_segment_identifier}`}>
        {createMode ? (
          <InputArea
            key={`fill-text-input-area`}
            className="multiline-input-area max-height-editor"
          >
            <TextInputCKE
              key={`text-input-cke-${props.unique_segment_identifier}`}
              data={content}
              gaps={gaps}
              onGapAnswerChanged={onGapAnswerChanged}
              onTextChanged={onTextChanged}
            ></TextInputCKE>
          </InputArea>
        ) : (
            <div className="show-fill-answer">
              <RenderHtml text={content} replaceItem={replace} renderUsingParse={true} uniqueTextIdentifier={props.unique_segment_identifier} />
            </div>
        )}
      </SegmentData>

      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        saveTextAnswer={saveTextAnswer}
        resetQuestion={resetQuestion}
        checkAnswer={checkAnswer}
        showCorrectAnswer={showCorrectAnswer}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default CKEFillText;
