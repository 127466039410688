import React from "react";
// import "./GroupItemDetail.scss";
import {Route, useRouteMatch} from "react-router-dom";
import {Tabs, Button} from "antd";
// import Segments from "../../Segments/Segments";
// import SegmentListItem from "../../Segments/SegmentListItem/SegmentListItem";

const ProgramItemDetail = (props) => {
  console.log( "itemDetail props", props );
  const {item, history, ...nestedProps} = props;
  const {path, url} = useRouteMatch();
  const { TabPane } = Tabs;
  const operations = <Button>Extra Action</Button>;

  const handleTabClick = (key, event) => {
    history.push(`${url}/${key}`)
    // if(key === "tests"){
    //   history.push(`${url}/tests`)
    // } else {
    //   // history.replace(`${url}`)
    // }
  }
  return <React.Fragment>
    {/* <span>Test show - {JSON.stringify(item)}</span>
    <Tabs tabBarExtraContent={operations} onTabClick={handleTabClick}>
      <TabPane tab="Create" key="create">
        <h4>Segments</h4>
        
        <Route path={`${path}`} render={routeProps => <Segments  parentId={item.id} parentName={"experience"} params={{by_experience_id: item.id}} createUpdateParams={{experience_id: item.id}} isRoute={false} {...routeProps}/>}/>
  
        
      </TabPane>
      <TabPane tab="Deliver" key="deliver">
        Deliver/Monitor
      </TabPane>
      <TabPane tab="Grade" key="grade">
        Grade
      </TabPane>
      <TabPane tab="Analytics" key="analytics">
        Analytics
      </TabPane>
    </Tabs> */}
    
  </React.Fragment>
}


ProgramItemDetail.defaultProps = {};

ProgramItemDetail.propTypes = {};

export default ProgramItemDetail;
