import React, { useState } from "react";
import { Modal, Button } from "antd";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const StudentInfoModal = ({
  appType,
  currentView,
  experienceId,
  experienceName,
  sCode,
  studentName,
  studentDeviceinfo,
  experienceMode,
  appVersion,
  accessCode,
  appNativeVarsInfo
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const mrIntl = useTranslate(); 
  // let appTypeWithVersion =
  //   appType && appType === "seb"
  //   ? `SEB ${appVersion}`
  //   : appType === "mac"
  //   ? `AssessPrep Mac App ${appVersion}`
  //   : appType === "ios"
  //   ? `AssessPrep iOS App ${appVersion}`
  //   : "";

  let appTypeWithVersion =
    appType && appType === "seb"
      ? mrIntl("CommonText.seb",{appVersion: appVersion})
      : appType === "mac"
      ? mrIntl("CommonText.ap_mac", {appVersion: appVersion})
      : appType === "ios"
      ? mrIntl("CommonText.ap_ios", {appVersion: appVersion})
      : "";

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
       <MrTranslate id={"StudentInfoModal.view_student_Info"}/>
      </Button>
      <Modal
        title={mrIntl("StudentInfoModal.basic_modal")}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p><MrTranslate id={"StudentInfoModal.app_type"}/>: {appType}</p>
        <p><MrTranslate id={"StudentInfoModal.current_view"}/>: {currentView}</p>
        <p>
          <MrTranslate id={"StudentInfoModal.experience_id"}/>: {experienceId} <br />
          <MrTranslate id={"StudentInfoModal.experience_name"}/>: {experienceName}
        </p>
        <p><MrTranslate id={"CommonText.join_code"} />: {accessCode}</p>
        <p><MrTranslate id={"CommonText.student_code"} />: {sCode}</p>
        <p><MrTranslate id={"CommonText.student_name"}/>: {studentName}</p>
        <p>
          <MrTranslate id={"CommonText.system_info"}/>:{" "}
          {studentDeviceinfo &&
            `${studentDeviceinfo.os} - ${studentDeviceinfo.osVersion} - ${studentDeviceinfo.browser}`}
        </p>
        <p><MrTranslate id={"StudentInfoModal.exam_mode"}/>: {experienceMode}</p>
        <p><MrTranslate id={"StudentInfoModal.app_version"}/>: {appTypeWithVersion}</p>
        <p><MrTranslate id={"StudentInfoModal.app_native_vars"}/>: {JSON.stringify(appNativeVarsInfo)}</p>
      </Modal>
    </>
  );
};

export default StudentInfoModal;
