import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Tooltip, Space, Dropdown, message, Button } from "antd";
import { getAIServiceBaseURL, getFromLS, getSelectedHtml, getUniqueId, updateConfigHeaderForDemoUseStagingAI } from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { SoundOutlined, PauseOutlined, DownOutlined, CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import "./TextToSpeech.scss";
import { enabledFeaturesSelector } from "../../Auth/Login/selector";
import { useDispatch, useSelector } from "react-redux";
import { activeMediaIdSelector } from "../selector";
import { actions as segmentsActions } from "/src/views/Segments/redux";

const TextToSpeech = () => {

  const enabledFeatures = useSelector(enabledFeaturesSelector())
  const [audioBlob, setAudioBlob] = useState(null);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedText, setSelectedText] = useState(null);
  const [selectedTTSGenderVoice, setSelectedTTSGenderVoice] = useState({
    voice: "female",
    name: "en-US-AvaMultilingualNeural"
  })
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let previousSelectedText = ""; // track the last selection
  const activeMediaId = useSelector(activeMediaIdSelector())
  const newJoinViewWithDynamicConfigEnabled = enabledFeatures.new_join_view_with_dynamic_config

  const textToSpeechAPIEndpoint = `${getAIServiceBaseURL()}/tts/cognitiveservices/v1`;
  const token = getFromLS("token");
  const mrIntl = useTranslate();
  const dispatch = useDispatch()
  const id = useRef(getUniqueId()).current;  
  const items = [
    {
      key: 'gender-selector',
      type: 'group',
      label: mrIntl("TextToSpeech.select_voice"),
      children: [
        {
          key: 'gender-male',
          label: mrIntl("TextToSpeech.male"),
          icon: selectedTTSGenderVoice.voice === 'male' ? <CheckOutlined /> : <span></span>,
          onClick: () => handleVoiceChange({ key: 'gender-male' }, false)
        },
        {
          key: 'gender-female',
          label: mrIntl("TextToSpeech.female"),
          icon: selectedTTSGenderVoice.voice === 'female' ? <CheckOutlined /> : <span></span>,
          onClick: () => handleVoiceChange({ key: 'gender-female' }, false)
        }
      ]
    },
    
  ];

  useEffect(() => {
    document.addEventListener("selectionchange", handleSelectionChange);
    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, [isLoading, isPlaying]);

  useEffect(() => {
    if (audioBlob && isPlaying) {
      playAudio();
    } else {
      pauseAudio();
    }
  }, [audioBlob, isPlaying]);

  useEffect(() => {
    return () => pauseAudio();
  }, [audio])

  useEffect(() => {
    if (activeMediaId !== id && isPlaying) {
      pauseAudio();
      console.log("Pausing TTS audio due to other media playback", activeMediaId);
    }
  }, [activeMediaId]);

  const handleSelectionChange = () => {
    try {
      const selectedText = getSelectedHtml();
      const textToSpeechGenderDropdown = document.querySelector('.text-to-speech-gender-selector');
      let isInsideDropdownMenu = false;
      if (textToSpeechGenderDropdown) {
        isInsideDropdownMenu = textToSpeechGenderDropdown.contains(window.getSelection().anchorNode);
      }
      if (!isPlaying && !isLoading && !selectedText && !isInsideDropdownMenu) {
        setSelectedText(null);
      }

      // Update state if the selection is different from the previous selection
      if (selectedText && selectedText !== previousSelectedText) {
        setAudioBlob(null);
        setAudio(null);
        setSelectedText(selectedText);
        previousSelectedText = selectedText;
      }
    } catch (error) {
      console.error('Error occurred in handleSelectionChange:', error);
    }
  };

  const manageTextToSpeechPlayback = async () => {
    if (activeMediaId && activeMediaId !== id) {
      dispatch(segmentsActions.setActiveMediaIdSuccess({ activeMediaId: null }));
    }
    if (!audioBlob) {
      // Make API request if audioBlob is not available
      try {
        setIsLoading(true);
        const text = selectedText.replace(/&nbsp;|<br>/g, ' ');
        const speechSynthesisMarkupLanguage = `<speak version='1.0' xml:lang='en-us'>
                      <voice xml:lang='en-us' xml:gender='${selectedTTSGenderVoice.voice}' name='${selectedTTSGenderVoice.name}'>
                        ${text}
                      </voice>
                    </speak>`;
        let config = {
          method: "POST",
          url: textToSpeechAPIEndpoint,
          headers: {
            "Content-Type": "application/ssml+xml",
            "X-Microsoft-OutputFormat": "audio-16khz-32kbitrate-mono-mp3",
            Authorization: token,
          },
          data: speechSynthesisMarkupLanguage,
          responseType: "arraybuffer",
        };
        config = updateConfigHeaderForDemoUseStagingAI(config)
        const response = await axios.request(config);
        // Convert array buffer to stream and create a ReadableStream from the response data received from the Text-to-Speech API.
        // This stream allows for the efficient reading of the audio data in chunks.
        const arrayBufferStream = new ReadableStream({
          start(controller) {
            // Enqueue the response data (converted to Uint8Array) into the stream controller.
            controller.enqueue(new Uint8Array(response.data));
            controller.close();
          },
        });
        // Convert the ReadableStream containing audio data into a File.
        // Creating a File object named 'audio.mp3' from the binary data received from the Response object
        // The binary data is obtained by converting the ReadableStream 'arrayBufferStream' to an ArrayBuffer
        // The File object represents an audio file with MIME type 'audio/mpeg'
        const audioData = new File([await new Response(arrayBufferStream).arrayBuffer()], 'audio.mp3', { type: 'audio/mpeg' });

        setIsPlaying(true)
        setAudioBlob(audioData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        message.error(mrIntl("CommonText.something_went_wrong_please_try_again"))
        console.log("manageTextToSpeechPlayback in TexttoSpeech =====>",error);
      }
    } else {
      setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    }
    dispatch(segmentsActions.setActiveMediaIdSuccess({ activeMediaId: id }))
  };

  const playAudio = () => {
    if (!audioBlob) {
      return;
    }
    if (!audio) {
      const newAudio = new Audio(URL.createObjectURL(audioBlob));
      newAudio.onended = () => {
        setIsPlaying(false);
        setSelectedText(null)
        dispatch(segmentsActions.setActiveMediaIdSuccess({ activeMediaId: null }))
      };
      newAudio.play();
      setAudio(newAudio);
    } else {
      audio.play();
    }
  };

  const pauseAudio = () => {
    if (audio) {
      audio.pause();
      setIsPlaying(false);
      dispatch(segmentsActions.setActiveMediaIdSuccess({ activeMediaId: null }))
    }
  };

  const handleVoiceChange = ({key}) => {
    let voice;
    let name;
    switch (key) {
      case 'gender-male':
        voice = "male";
        name = 'en-US-RyanMultilingualNeural';
        break;
      case 'gender-female':
        voice = "female";
        name = 'en-US-AvaMultilingualNeural';
        break;
      default:
        voice = 'female';
        name = 'en-US-AvaMultilingualNeural';
    }
    setSelectedTTSGenderVoice({
      voice: voice,
      name: name
    });
    setTimeout(() => {
      setDropdownVisible(false);
    }, 500);
  };

  const handleOpenChange = (Open) => {
    setDropdownVisible(Open);
  };

  const tooltipTitle = !selectedText
    ? mrIntl("TextToSpeech.select_text_then_click_to_read_aloud")
    : isPlaying
      ? mrIntl("CommonText.pause")
      : mrIntl("TextToSpeech.read_aloud");

  const className = selectedText && (isPlaying || isLoading) ? "text-to-speech-button" : "";
      
  return (
    <Space className="text-to-speech-wrapper" key={`text-to-speech-${id}`}>
        {newJoinViewWithDynamicConfigEnabled ?
          <Tooltip title={tooltipTitle}
            placement="left"
          >
            <Button
              type="text"
              disabled={!selectedText || isLoading}
              onClick={() => manageTextToSpeechPlayback()}
              icon={isPlaying ? <PauseOutlined /> : <SoundOutlined />}
              className={className}
            />
          </Tooltip>
          : <Dropdown.Button
            overlayClassName="text-to-speech-gender-selector"
            menu={{
              items,
              onClick: (value) => handleVoiceChange(value, false),
              defaultChecked: selectedTTSGenderVoice.voice,
            }}
            onOpenChange={handleOpenChange}
            open={dropdownVisible}
            buttonsRender={([leftButton, rightButton]) => [
              <Tooltip title={tooltipTitle} key="left-button">
                {leftButton}
              </Tooltip>,
              rightButton
            ]}
            disabled={!selectedText || isLoading}
            onClick={() => manageTextToSpeechPlayback()}
            icon={<DownOutlined />}
            placement="bottomRight"
          > {isLoading ? <LoadingOutlined /> : isPlaying ? <PauseOutlined /> : <SoundOutlined />}
            {isPlaying ? mrIntl("CommonText.pause") : mrIntl("TextToSpeech.speak")}
          </Dropdown.Button>
        }
    </Space>
  )
};
export default TextToSpeech;