import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "app_constant",
  actionNames: ["FETCH", "CREATE", "UPDATE", "DELETE"],
});

const initialState = fromJS({
  app_constants: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // lead: {},
  error: null,
  loading: false,
});

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
// export const reducer = reduxCrud.getReducer(initialState, {
//   UPDATE_LEAD_ACTIONS: updateChildCreator("action"),
//   UPDATE_LEAD_LOGS: updateChildCreator("log")
// });
export const reducer = reduxCrud.getReducer(initialState);
export const watchAppConstants = reduxCrud.generateWatchSaga();

export default reduxCrud;
