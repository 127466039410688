import React from "react";
import { useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import AuditLogs from "/src/views/Experiences/ExperienceShow/Components/AuditLogs/AuditLogs";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";

const OrgAuditLogs = (props) => {
  const { org } = props

  const finalRender = <AuditLogs
    // title={"Assessment Logs"}
    title={"Audit Logs"}
    // experience={experience}
    org_id={org.id}
    // showOrgLogs={true}
    filterOnSubmit={true}
    logView={"org_audit_logs"}
    // log_type={"experience"}
    // itemId={experience.id}
  />
  
  return finalRender
}

export default OrgAuditLogs