import React from "react";
import { Row, Col, Space, Tag, Button, Dropdown } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FaGlobe } from "react-icons/fa";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { availableLanguageOptionsForAI } from "/src/lib/utils/helperMethods";
import SelectAIOutputLanguage from "./SelectAIOutputLanguage";
import { useSelector } from "react-redux";
import { enabledFeaturesSelector } from "../../Auth/Login/selector";
import AIAssistantSettings from "./AIAssistantSettings";

const ModalTitle = ({
  language,
  setLanguage,
  outputLanguageDisabled,
  newGenerateQuestionsWithAI,
  handleCancel,
}) => {
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const mrIntl = useTranslate();
  return (
    <Row justify={"space-between"}>
      <Space className="modal-title">
        <Space>
          {mrIntl("GenerateAIWithPrompt.generate_question_with_ai_msg")}
          <Tag className="ai-header-beta-tag">{mrIntl("CommonText.beta")}</Tag>
        </Space>

        <Space>
          {enabledFeatures.ai_assistant_settings ? (
            <AIAssistantSettings
              defaultActiveTab={"authoring"}
              showTransparentIconOnly={true}
            />
          ) : (
            <SelectAIOutputLanguage
              language={language}
              setLanguage={setLanguage}
              disabled={outputLanguageDisabled}
              newGenerateQuestionsWithAI={newGenerateQuestionsWithAI}
            />
          )}
          <Button
            style={{
              backgroundColor: "transparent",
              color: "white",
              border: "none",
            }}
            onClick={handleCancel}
            icon={<CloseOutlined />}
          />
        </Space>
      </Space>
    </Row>
  );
};

export default ModalTitle;