import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const VideoCall = (props) => {
  console.log("Video call props ==>", props)
  const { config, containerDimensions } = props;
  const jitsiContainerId = "jitsi-assessprep-container-id";
  const [jitsi, setJitsi] = useState({});

  useEffect(() => {
    if (jitsi && jitsi.isAudioMuted) {
      jitsi.isAudioMuted().then((muted) => {
        console.log("jitsi muted", muted, props.muteAction);
        // if(muted === props.muteAction){
        if (muted === false) {
          jitsi.executeCommand("toggleAudio");
        }
      });
      // jitsi.resizeLargeVideo(config.width, config.height);
    }
  }, [props.muteAction]);

  const loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null;

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const script = document.createElement("script");
    script.src = "https://meet.jit.si/external_api.js";
    script.async = true;
    script.onload = () => resolveLoadJitsiScriptPromise(true);
    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }
    const domain = "meet.jit.si";
    console.log("videocall config 31", config);
    const iframeItem = document.getElementById(jitsiContainerId);
    if (!iframeItem) {
      return;
    }
    const options = {
      parentNode: iframeItem,
      ...config,
    };
    iframeItem.oncontextmenu = function () {
      return false;
    };

    const _jitsi = new window.JitsiMeetExternalAPI(domain, options);

    _jitsi.addEventListener("participantRoleChanged", function (event) {
      if (event.role === "moderator") {
        console.log("event.role, event", event.role, event);
        _jitsi.executeCommand("password", "hello");
      }
    });
    _jitsi.on("passwordRequired", function () {
      _jitsi.executeCommand("password", "hello");
    });
    setJitsi(_jitsi);
  };

  useEffect(() => {
    initialiseJitsi();

    return () => {
      if (jitsi && jitsi.dispose) {
        // jitsi?.dispose?.();
        jitsi.dispose();
      }
    };
  }, []);

  // return <div id={jitsiContainerId} style={{ height: "400px", width: "500px" }} />;
  return (
    <div className="video-call">
      <div className="custom-mini-overlay"></div>
      <div
        id={jitsiContainerId}
        style={{
          height: containerDimensions.height || "30px",
          width: containerDimensions.width || "30px",
        }}
        data-html2canvas-ignore
      />
    </div>
  );
};

VideoCall.defaultProps = {
  config: {},
};
VideoCall.propTypes = {
  config: PropTypes.object,
};
export default VideoCall;
