import React from "react";
import { Input, Checkbox, Modal, Button, Space, Tooltip, Popconfirm, Tag } from "antd";
import { message, notification, modal } from '/src/components/UI/AntdAppHelper';
import styled from 'styled-components'
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, FullscreenOutlined, FullscreenExitOutlined, UploadOutlined, PushpinOutlined, AlignLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { BiPin } from "react-icons/bi";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs"
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const CustomButton = styled(Button)`
	margin: 5px;
	// width: 50px;
`
export const CustomIconButton = props => {
  return (
    <CustomButton
      onClick={props.clicked}
      disabled={props.disabled}
      style={props.style}
      className={props.className}
      type={props.type}
      icon={props.icon}
    >
    </CustomButton>
  );
}

export const AddButton = props => {
  const mrIntl = useTranslate();
  return (
    <Tooltip title={props.title ? props.title : mrIntl("CommonText.add")} placement="right">
      <CustomButton
        onClick={props.clicked}
        disabled={props.disabled}
        style={props.style}
        className={props.className}
      >
        <PlusOutlined />
      </CustomButton>
    </Tooltip>
  );
};

export const DeleteButton = props => {
  const mrIntl = useTranslate();
  return (
    <Popconfirm
      title={props.popConfirmTitle || mrIntl("CommonText.confirm_delete")}
      onConfirm={(e) => props.clicked(e)}
      onCancel={props.onCancel}
      okText= {mrIntl("CommonText.yes")}
      cancelText= {mrIntl("CommonText.no")}
    >
      <Tooltip title={props.tooltipTitle ? props.tooltipTitle : mrIntl("CommonText.delete")} placement="right">
        <CustomButton
          // type="danger"
          type="text"
          // ghost={true}
          shape="circle"
          // onClick={(e) => props.clicked(e)}
          disabled={props.disabled}
          style={props.style}
        >
          <DeleteOutlined />
        </CustomButton>
      </Tooltip>
    </Popconfirm>
  );
};

export const FullScreenButton = props => {
  return (
    <Tooltip title={props.fullscreen ? 'Exit full screen' : 'Full screen'} placement="top">
      <CustomButton
        onClick={props.clicked}
        disabled={props.disabled}
        style={props.style}
        className={props.className}
      >
        {props.fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
      </CustomButton>
    </Tooltip>
  );
};

export const PinButton = props => {
  return (
    <Tooltip title={props.tooltipTitle} placement="top">
      <CustomButton
        onClick={props.clicked}
        disabled={props.disabled}
        style={props.style}
        className={props.className}
        shape={props.shape}
        ghost={props.ghost}
        type={props.type}
      >
        <PushpinOutlined />
      </CustomButton>
    </Tooltip>
  );
}

export const BookMarkButton = props => {
  return (
    <Tooltip title={props.selected ? "Unbookmark" : "Bookmark"} placement="top">
      <CustomButton
        onClick={props.clicked}
        disabled={props.disabled}
        style={props.style}
        className={props.className}
        type={props.type}
        icon={props.selected ? <BsBookmarkFill /> : <BsBookmark />}
      >
      </CustomButton>
    </Tooltip>
  );
}

export const UploadFilesButton = props => {
  return (
    <Tooltip title={props.tooltipTitle} placement="top">
      <CustomButton
        onClick={props.clicked}
        disabled={props.disabled}
        style={props.style}
        className={props.className}
        shape={props.shape}
        type={props.type}
        icon={<UploadOutlined />}
      >
        {props.buttonText}
      </CustomButton>
    </Tooltip>
  );
}

export const CheckAnswerButton = props => {
  return (
    <CustomButton
      onClick={props.clicked}
      disabled={props.disabled}
      className="check-answer-button"
      type="primary"
    >
      Check Answer
    </CustomButton>
  );
}

export const CustomTextButton = props => {
  return (
    <CustomButton
      onClick={props.clicked}
      disabled={props.disabled}
      style={props.style}
      type={props.type}
      shape={props.shape}
      size={props.size}
    >
      {props.buttonText}
    </CustomButton>
  );
}

export const TryAgainButton = props => {
  return (
    <CustomButton
      onClick={props.clicked}
      disabled={props.disabled}
    >
      Try Again
    </CustomButton>
  );
}

export const ResetButton = (props) => {
  const { mrIntl } = props
  return (
    <CustomButton
      onClick={props.clicked}
      disabled={props.disabled}
    >
      {/* Reset */}
     {mrIntl("CommonText.reset")}
    </CustomButton>
  );
}

export const MediaHoverButton = props => {
  return (
    <CustomButton
      onClick={props.clicked}
      disabled={props.disabled}
      className={props.className}
      type="primary"
    >
      <PlusOutlined />
    </CustomButton>
  )
}

export const AddFeedbackButton = props => {
  const mrIntl = useTranslate();
  return (
    <Tooltip title={mrIntl("UIHelper.add_specific_feedback")} placement="right">
      <CustomButton
        type="text"
        shape="circle"
        onClick={props.clicked}
        disabled={props.disabled}
        className={props.className}
        icon={<AlignLeftOutlined />}
      ></CustomButton>
    </Tooltip>
  )
}

export const RemoveFeedbackButton = props => {
  const mrIntl = useTranslate()
  return (
    <Popconfirm
      title={props.popConfirmTitle || "Confirm remove?"}
      onConfirm={props.clicked}
      onCancel={props.onCancel}
      okText= {mrIntl("CommonText.yes")}
      cancelText= {mrIntl("CommonText.no")}
    >
      <Tooltip title="Remove specific feedback" placement="right">
        <CustomButton
          type="text"
          shape="circle"
          // onClick={props.clicked}
          disabled={props.disabled}
          className={props.className}
          icon={<CloseOutlined />}
        ></CustomButton>
      </Tooltip>
    </Popconfirm>
  )
}

export const TabAddIcon = props => {

  return (
    <span>
      <PlusOutlined /> {props.iconText}
    </span>
  )
}

export const PopConfirmDeleteIcon = props => {
const mrIntl = useTranslate()
  return (
    <Popconfirm
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      okText= {mrIntl("CommonText.yes")}
      cancelText= {mrIntl("CommonText.no")}
    >
      <DeleteOutlined />
    </Popconfirm>
  )
}

export const QuickCheckBox = props => {
  return (
    <CheckBox
      checked={props.checked}
      onClick={props.clicked}
    >
      Quick Check Mode
    </CheckBox>
  );
}


export const CustomInput = props => {
  const onChangeWithDebounce = debounce((data) => {
    props.onChange(data)
  }, 1000)

  console.log("custom input props ==>", props)
  return (
    <Input
      {...props}
      onChange={(e) => onChangeWithDebounce(e.target.value)}
    >
    </Input>
  )
}

export const CustomErrorMessage = props => {


  return (
    <div {...props}>
      {props.message}
    </div>
  )
}

export const WordCount = (props) => {
  let { wordCount = 0 } = props
  const mrIntl = useTranslate()

  return (
    <div className='word-count'>
      {/* {wordCount} word{wordCount > 1 && 's'} */}
      {/* <Tag>Words: {wordCount}</Tag> */}
      <span>{mrIntl("UIHelper.words")}: {wordCount}</span>
    </div>
  )
}
// export const CustomLabel = styled.h3`
export const CustomLabel = styled.label`
  margin-top: 10px;
  margin-bottom: 10px;
`

export const Segment = styled.div`
  width: 100%;
  text-align: initial;
`
export const SegmentData = styled.div`
  width: 100%;
`
export const SegmentSetting = styled.div`
  width: 100%;
`
export const DragHandle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 8px;
  // background-color: skyblue;
  margin: 2px;
  font-size: 20px;
  color: cornflowerblue;
`
export const InputArea = styled.div`
  flex-grow: 10;
  margin: 2px;
  border: 1px solid #8f8f8f;  
`
export const InlineInputArea = styled.div`
  display: inline-grid;
  min-width: 100px;
  border-bottom: 1px solid #8f8f8f;
  margin: 5px;
  min-height: 35px;
  // vertical-align: middle;
`

export const VerticalDroppableContainer = styled.div`
	// margin: 10px;
	// min-height: 200px;
`
export const HorizontalDroppableContainer = styled.div`
	// display: flex;
  // border: 1px solid #939393;
  // border: 1px solid #f0f0f0; //shikhar commented out
	border-radius: 3px;
	min-height: 80px;
  height: 100%;
	text-align: center;
	padding: 10px;
  margin-top: 20px;
  overflow: auto;
`

export const DroppableHeader = styled.div`
  text-align: center;
  border: 1px solid #eee; // changed by shikhar from grey
  border-radius: 2px;
  margin-right: 10px;
  margin-top: 10px;
`

export const DragDropArea = styled.div`
	display: flex;
`
export const Column = styled.div`
  width: 100%;
	display: flex;
  flex-direction: column;
  margin: 5px 5px 10px 5px;
`
export const ColumnHeader = styled.div`
  display: flex;
`

export const CheckBox = styled(Checkbox)`
  margin: 10px;
  margin-right: 20px;
`;

export const MCQArea = styled.div`
  width: 100%;
`;

export const MCQList = styled.div`
`;

export const TextArea = styled.div`
  display: flex;
  margin: 5px;
  align-items: center;
  justify-content: center;
`;

export const ShowTextContainer = styled.div`
  // border: 1px solid lightgrey;
  margin: 5px;
  padding: 5px;
  display:flex;
  position: relative;
  // margin: 0;
  // cursor: pointer;
`;

export const ShowText = styled.div`
  // display: flex;
  // cursor: pointer; // shikhar moved to class
  // user-select: none;
  // min-height: 20px;
  // border: 1px solid #f0f0f0;
  // border-radius: 2px;
  // margin: 10px;
  // height: 100%;
  padding: 5px;
`;

export const ShowTextIndex = styled.div`
	width: 20px;
	margin: 5px;
	text-align: center;
`;

export const ShowTextContent = styled.div`
  // flex-grow: 1;
  // border: 1px solid black;
  // border: 1px solid #f0f0f0;
  // border-radius: 2px;
  // margin: 10px;
  // padding: 10px;
`;

export const ShowTextFile = styled.div`
  display: inline-block;
`

export const InlineDroppablesContainer = styled.div`
`
export const InlineDroppable = styled.div`
  display: inline-table;
  height: 40%;
`

export const MediaButtonsContainer = styled.div`
  // display: inline-block;
`

export const PlayList = styled.div`
  max-height: 400px;
  overflow: auto;
`

// const { confirm } = Modal;

export const showInfoModal = (props) => {
  const type = props.type || 'error'

  // Modal[type]({
  //   title: props.title || 'Something went wrong!',
  //   content: props.content || '',
  //   buttonLabel: props.buttonText || "OK",
  // })
  const modalProps = {
    title: props.title || 'Something went wrong!',
    content: props.content || '',
    okText: props.buttonText || "OK",
  };

  Modal[type](modalProps);
}

export const showConfirmModal = (props) => {
  const mrIntlFunction = () => {
  }
  const mrIntl = props.mrIntl || mrIntlFunction
  console.log("line number 476===>", props);
  modal.confirm({
    title: props.title || mrIntl("CommonText.are_you_sure_title"),
    icon: props.icon || <ExclamationCircleOutlined />,
    content: props.content || '',
    okText: props.okText || mrIntl("CommonText.yes") || "Yes",
    okType: props.okType || 'danger',
    cancelText: props.cancelText || mrIntl("CommonText.no") || "No",
    className: `height-centered-modal ${props.className}`,
    onOk() {
      console.log('OK');
      if (props.onOk) {
        props.onOk()
      }
    },
    onCancel() {
      console.log('Cancel');
      if (props.onCancel) {
        props.onCancel()
      }
    },
    ...props,
  });
}

export const showNotification = (type, config) => {
  notification[type](config)
}
