import React from "react";
import { Button } from "antd";
import styled from 'styled-components'
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

export const AttemptLeftButton = props => {
	return (
		<Button
			// onClick={props.clicked}
      className={props.className}
			disabled={props.disabled}
		>
			Attempt Left: {props.attempt_left}
		</Button>
	);
};
