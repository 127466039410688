import React from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { MrSelect, MrFormComponent } from "mr_react_framework";
import queryString from "query-string";
import { Divider } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions } from "/src/views/Users/redux";
import { actions } from "../Login/redux";
import {
  isAuthenticatedSelector,
  resetpasswordloadingSelector,
} from "../Login/selector";
import { buildCountriesOptionsArr } from "/src/lib/utils/helperMethods";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const SelectCountry = MrSelect({
  // actions: genericActions,
  // resourceName: "plans",
  config: {
    // url: "/plans",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.label, value: item.value };
      });
    },
    widgetConfig: {
      options: buildCountriesOptionsArr(),
      showSearch: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"CommonText.country"} />,
      // style: { width: "100%" },
      allowClear: true
    },
  },
});

const getForgotPassForm = (props, mrIntl) => {
  return {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      opts.values.email = opts.values.email.toLowerCase().trim()
      // trim and downcase this
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        first_name: {
          type: "string",
          span: 12,
          placeholder: mrIntl("SignUp.first_name"),
          className: "login-email",
          rules: [{
            required: true,
            message: mrIntl("CommonText.required_msg"),
          }]
        },
        last_name: {
          type: "string",
          span: 12,
          placeholder: mrIntl("SignUp.last_name"),
          className: "login-email",
        },
        email: {
          type: "string",
          format: "email",
          placeholder: mrIntl("SignUp.email_placeholder"),
          className: "login-email",
          rules: [{
            required: true,
            message: mrIntl("SignUp.please_enter_a_valid_email"),
          }]
        },
        password: {
          type: "string",
          placeholder: mrIntl("SignUp.password_placeholder"),
          // widget: Input.Password,
          widget: "PasswordWidget",
          className: "login-password",
          rules: [
            {
              // required: props.formState.values.id ? false : true,
              required: true,
              message: mrIntl("CommonText.please_enter_a_password"),
            },
            {
              min: 8,
              message: mrIntl("SignUp.minimum_charaters_required_message"),
            },
          ],
        },
        password_confirmation: {
          type: "string",
          placeholder: mrIntl("SignUp.confirm_password_placeholder"),
          // widget: Input.Password,
          widget: "PasswordWidget",
          widgetConfig: {
            dependencies: ["password"],
          },
          className: "login-password",
          rules: [
            {
              // required: props.formState.values.id ? false : true,
              required: true,
              message: mrIntl("CommonText.please_enter_a_password"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  mrIntl("SignUp.confirm_password_not_match_message"),
                );
              },
            }),
          ],
        },
        signup_org_name: {
          type: "string",
          placeholder: mrIntl("SignUp.organisation_name"),
          className: "login-email",
          rules: [{
            required: true,
            message: mrIntl("SignUp.required"),
          }]
        },
        signup_org_country: {
          type: "string",
          placeholder: mrIntl("CommonText.country"),
          widget: SelectCountry,
          rules: [{
            required: true,
            message: mrIntl("SignUp.required"),
          }]
        },
      },
    },
  };
};
const SignUp = (props) => {
  const mrIntl = useTranslate()
  const dispatch = useDispatch();
  const jsonForm = getForgotPassForm(props, mrIntl);
  const location = useLocation();
  const loading = useSelector(resetpasswordloadingSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector());
  const queryParams = queryString.parse(location.search);
  const handleSubmit = (data) => {
    const finalData = { user: { ...data } };
    console.log("handleSubmit", finalData);
    dispatch(
      actions.signup(
        {
          ...data,
        },
        {
          success: {
            showMessage: true,
            message: mrIntl("SignUp.account_created"),
          },
        }
      )
    );
    // dispatch(
    //   actions.resetpassword(
    //     {
    //       reset_token: queryParams.reset_token,
    //       ...data,
    //     },
    //     {
    //       success: {
    //         showMessage: true,
    //         message: "Password successfully changed.",
    //       },
    //     }
    //   )
    // );
  };
  useEffect(() => {
    console.log("location", location);
    // return () => {
    //   cleanup
    // }
  }, [location]);
  console.log("location", location);
  if (isAuthenticated) {
    return <Redirect to={"/"} />;
  }

  const mainForm = (
    <MrFormComponent
      key={"mrForm"}
      {...props}
      visible={true}
      resourceForm={{
        form: { ...jsonForm },
        config: { isModal: false, visible: true },
      }}
      item={{}}
      loading={loading}
      // formConfig={{actions: {submitText: "Login", showCancelBtn: false}}}
      formConfig={{ actions: { submitText: mrIntl("CommonText.submit"), showCancelBtn: false } }}
      formProps={{}}
      // jsonForm={jsonForm}
      // schema={jsonForm.schema}
      handleOk={handleSubmit}
      handleSubmit={handleSubmit}
      // styleProps={styleProps}
    />
  );

  return (
    <>
      {/* <h3>Create a new teacher account</h3> */}
      <h3>{mrIntl("SignUp.create_a_new_teacher_account")}</h3>
      <br></br>
      {mainForm}
      <Divider> {mrIntl("CommonText.or")} </Divider>
      <div class={"back-to-login-link"}>
        <Link to={"/auth/login"}>{mrIntl("SignUp.go_back_to_login")}</Link>
      </div>
    </>
  );
};

SignUp.defaultProps = {};

SignUp.propTypes = {};

export default SignUp;
