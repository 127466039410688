import React from "react";
import { Table } from "antd";
import {Link, useRouteMatch} from "react-router-dom";
import ActionBtns from "/src/components/ActionBtns/ActionBtns";


const PlansList = (props) => {
  console.log("PlansList props", props)
  const { dataSource } = props;
  const tableDataSource = []
  for (let index = 0; index < dataSource.length; index++) {
    let objToPush = {}
    objToPush.id = dataSource[index].id
    objToPush.name = dataSource[index].name
    objToPush.code = dataSource[index].code
    objToPush.tiers_json = JSON.stringify(dataSource[index].tiers_json)
    objToPush.actions = <ActionBtns edit={true} delete={false} deleted={props.deleted} item={dataSource[index]} id={dataSource[index].id}/>
    tableDataSource.push(objToPush)
  }
  
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'tiers_json',
      dataIndex: 'tiers_json',
      key: 'tiers_json',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
    },
   
  ];
  const {path, url} = useRouteMatch();
  console.log("exp list item url", url);
  return (
      <Table dataSource={tableDataSource} columns={columns} pagination={false} />  
  );
};

export default PlansList;
