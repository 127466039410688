
      if (import.meta.env.VITE_REGION !== 'china') {
        var script=document.createElement("script");
        script.async=true;
        script.defer=true
        script.src="https://apis.google.com/js/api.js";
        document.getElementsByTagName("head")[0].appendChild(script); 

        var script=document.createElement("script");
        script.async=true;
        script.defer=true
        script.src="https://accounts.google.com/gsi/client";
        document.getElementsByTagName("head")[0].appendChild(script); 

        var script=document.createElement("script");
        script.async=true;
        script.src="https://www.googletagmanager.com/gtag/js?id=G-WKF76YNVPM";
        document.getElementsByTagName("head")[0].appendChild(script); 

        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-WKF76YNVPM');
      }
    