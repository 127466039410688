import React, { useState, useEffect, useCallback } from "react";
import { MrSelect } from "mr_react_framework";
import { Route, useRouteMatch, useHistory } from "react-router-dom";
import { Button, Row, Col, Tooltip, Space, Tag, Modal, Spin } from "antd";
import SegmentItemDetail from "/src/views/Segments/SegmentItemDetail/SegmentItemDetail";
import { useSelector, useDispatch } from "react-redux";
import { loadingSelector, summaryLoadingSelector } from "/src/views/UserResponses/selector";
import { displayPoints } from "/src/lib/utils/helperMethods";
import {
  currentUserSelector,
  enabledFeaturesSelector,
  getEmbeddedSelector,
} from "/src/views/Auth/Login/selector";
// import SampleAnnotateImg from "/src/assets/images/sample_annotate.png";
import NoData from "/src/components/UI/NoData/NoData";
// import MBIcon from "/src/assets/images/mb-icon.png";
import Segments from "/src/views/Segments/Segments";
import ExperienceUserHeader from "./ExperienceUserHeader";
import {
  activeSegmentIdSelector,
  activeSegmentParentIdxsSelector,
  activeSegmentSelector,
  segmentsSelector,
} from "/src/views/Segments/selector";
import {
  activeTopicIdSelector,
  activeTopicSelector,
} from "/src/views/Segments/Topics/selector";
import { activeExperienceUserSelector } from "../selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ExperienceUserGrading = (props) => {
  console.log("ExperienceUserGrading props", props);

  const {
    showInModal,
    experience,
    gradingConfig = {},
    setGradingConfig,
    mbGradeBookEmbeddedView,
    exportModalConfig = {},
    setExportModalConfig,
    experience_user
  } = props;

  const { path, url } = useRouteMatch();
  const history = useHistory();
  const embedded = useSelector(getEmbeddedSelector());
  const currentUser = useSelector(currentUserSelector());
  const experienceUser = useSelector(activeExperienceUserSelector());
  const activeSegmentId = useSelector(activeSegmentIdSelector());
  const activeSegment = useSelector(activeSegmentSelector());
  const activeTopicId = useSelector(activeTopicIdSelector());
  const activeTopic = useSelector(activeTopicSelector());
  const topicSegments = useSelector(segmentsSelector(activeTopicId));
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const summaryLoading = useSelector(summaryLoadingSelector())
  const mrIntl = useTranslate();
  const activeSegmentParentIdxs = useSelector(activeSegmentParentIdxsSelector())

  console.log("summaryLoading==>", summaryLoading)

  // const isMobile = checkMob();
  // not redirecting now - esp needed for embed
  // currentUser.role == "student" && item && !experienceUser.marked && history.push("/u/tests") // redirect if opening directly and not marked should do this via pundit?
  let isStudent =
    currentUser.role === "student" || currentUser.role === "parent"
      ? true
      : false;

  const checkIfUserCanSeeResponses = 
    isStudent && !experience_user.review_by_user_id
      ? experience_user.marked 
      : true;

  console.log("found segment", activeSegment, activeSegmentId, activeTopic);

  const experienceSettings = experience.settings || {};

  // const dispatch = useDispatch()
  // useEffect(() => {
  //   console.log("annotationActions ==>", annotationActions)
  //   dispatch(annotationActions.fetch({params: {by_experience_id: experience.id, by_user_id: experienceUser.user_id}}))
  // }, [experienceUser.user_id])

  let experienceViewMode = "apGradeTest";
  let segmentList = [];

  if (activeSegment && activeSegment.segment_type == "question") {
    segmentList.push(
      <Route
        key={"segments-single"}
        path={`${path}`}
        render={(routeProps) => (
          <Segments
            doNotReloadOnUpdate={true}
            embedded={embedded}
            resourceId={activeSegmentId}
            // experienceProps={{ item: experience }}
            experience={experience}
            topicProps={{ item: activeTopic }}
            parentId={activeTopicId}
            parentIdxs={activeSegmentParentIdxs}
            nestingLevel={activeSegmentParentIdxs.length - 1}
            gradingConfig={gradingConfig}
            experienceUser={experienceUser}
            experienceViewMode={experienceViewMode}
            isRoute={false}
            {...routeProps}
            config={{
              routes: { fetch: false },
              itemDetail: { widget: SegmentItemDetail },
            }}
          />
        )}
      />
    );
  } else {
    if (topicSegments && topicSegments.length === 0) {
      segmentList.push(<NoData description={mrIntl("ExperienceUserGrading.no_data_in_section")} />);
    }

    segmentList.push(
      <Route
        key={"segments-all"}
        path={`${path}`}
        render={(routeProps) => (
          <Segments
            doNotReloadOnUpdate={false}
            embedded={embedded}
            experienceProps={{ item: experience }}
            topicProps={{ item: activeTopic }}
            parentId={activeTopicId}
            parentIdxs={activeSegmentParentIdxs}
            nestingLevel={1}
            gradingConfig={gradingConfig}
            experienceUser={experienceUser}
            experienceViewMode={experienceViewMode}
            isRoute={false}
            {...routeProps}
            config={{
              list: {
                shouldExecuteRequest: false,
              },
              routes: { show: false },
            }}
          />
        )}
      />
    );
  }


  let segmentListClassname = "segments-list";
  segmentListClassname =
    experienceViewMode === "apGradeTest"
      ? segmentListClassname.concat(" ", "grading-view")
      : segmentListClassname;
  segmentListClassname =
    gradingConfig.gradingMode == "annotate"
      ? segmentListClassname.concat(" ", "annotate-mode")
      : segmentListClassname;

  let isMYPAchievementLevel =
    experience.rubric && experience.rubric.type_c === "myp_achievement_level"
      ? true
      : false;

  let criteriaPointsView = [];
  experienceUser.submission_criterium_associations_attributes &&
    experienceUser.submission_criterium_associations_attributes.map((ca, i) => {
      criteriaPointsView.push(
        <Tag style={{ fontWeight: "800" }}>
          {ca.custom_fields.title}{" "}
          {ca.level_id
            ? `${ca.custom_fields.level_label} ${
                ca.points ? "(" + displayPoints(ca.points) + ")" : ""
              }`
            : `${displayPoints(ca.points)}/${displayPoints(
                ca.custom_fields.max_points
              )}`}
        </Tag>
      );
    });
  

  let formEU = { ...experienceUser };
  if (experienceSettings.grading_setting === "per_question") {
    delete formEU.points;
    delete formEU.submission_criterium_associations_attributes;
    // modifying to remove points from item so that when giving only comments (we want to show comments in all cases), pts don't get overridden by old value
  }

  let showLimitedexperienceUserHeaderForMYPAL = false;
  if (mbGradeBookEmbeddedView && isMYPAchievementLevel) {
    // showing header but disabled, collapsed - only publish/change grading allowed
    showLimitedexperienceUserHeaderForMYPAL = true;
  }

  let experienceUserHeader = (
    <ExperienceUserHeader
      updateResource={props.updateResource}
      renderForm={props.renderForm}
      experience={experience}
      gradingConfig={gradingConfig}
      setGradingConfig={setGradingConfig}
      exportModalConfig={exportModalConfig}
      setExportModalConfig={setExportModalConfig}
      mbGradeBookEmbeddedView={mbGradeBookEmbeddedView}
    ></ExperienceUserHeader>
  );

  let renderItem = (
    <React.Fragment>
      <Row className={segmentListClassname}>
        {!showInModal && <Col span={24} style={{marginTop: '50px'}}>{experienceUserHeader}</Col>}
        {checkIfUserCanSeeResponses && <Col span={24}> {<Spin spinning={summaryLoading}>
          {segmentList}
        </Spin>}</Col>}
      </Row>
    </React.Fragment>
  );

  return renderItem;
};

export default ExperienceUserGrading;
