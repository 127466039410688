/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
 export const oAuthRedirectUrl = import.meta.env.VITE_WEB_URL;
 export const msalConfig = {
  auth: {
      clientId: "57cbe5ae-1b55-4049-b707-d7fef5eb0cf9",
      authority: "https://login.microsoftonline.com/common/",
      // redirectUri: "http://localhost:3000",
      // redirectUri: `${oAuthRedirectUrl}/oauth/ms/callback`,
      redirectUri: `${oAuthRedirectUrl}/auth/login`,
  },
  cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  // system: {	
  //     loggerOptions: {	
  //         loggerCallback: (level, message, containsPii) => {	
  //             if (containsPii) {		
  //                 return;		
  //             }		
  //             switch (level) {		
  //                 case msal.LogLevel.Error:		
  //                     console.error(message);		
  //                     return;		
  //                 case msal.LogLevel.Info:		
  //                     console.info(message);		
  //                     return;		
  //                 case msal.LogLevel.Verbose:		
  //                     console.debug(message);		
  //                     return;		
  //                 case msal.LogLevel.Warning:		
  //                     console.warn(message);		
  //                     return;		
  //             }	
  //         }	
  //     }	
  // }
};

/**
* Scopes you add here will be prompted for user consent during sign-in.
* By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
* For more information about OIDC scopes, visit: 
* https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
*/
export const msLoginRequest = {
  // scopes: ["User.Read","openid"]
  scopes: ["User.Read", "openid", "profile", "email"],
  prompt: "select_account"
};

/**
* Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
*/
export const msTokenRequest = {
  scopes: ["User.Read", "Mail.Read"],
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};
