import { Button, Col, Dropdown, Form, Input, Menu, Popconfirm, Row, Space, Switch } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { PlusCircleOutlined, MoreOutlined, ArrowUpOutlined, ArrowDownOutlined, ArrowLeftOutlined, DeleteOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { currentAlphabetByIndex, getQuestionNumber, getUniqueId } from "/src/lib/utils/helperMethods";
import { InputArea, showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import { CustomErrorMessage } from "/src/components/UI/Segment/UIHelper";
import RubricLevels from "./RubricLevels";
import DefaultCKE from "/src/components/UI/CKEditor/DefaultCKE";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import RubricCriteria from "./RubricCriteria";
import PreviewRubrics from "./PreviewRubrics";
import { useSelector } from "react-redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
export const validateJsonValue = (value) => {
  console.log("value in validate==>", value);
  const validation = {
    success: true,
    messages: [],
  };
  let validateRubric = true;
  value.criteria && value.criteria.length !== 0 &&
    value.criteria.map((criteria) => {
      if (!criteria._destroy) {
        if (!criteria.label) {
          validateRubric = false;
        }
        !_.isEmpty(criteria.levels_json) && criteria.levels_json.map((level) => {
          if (value.settings.use_scoring && !level.level_points) {
            validateRubric = false;
          }
          if (!level.level_label) {
            validateRubric = false;
          }
        });
      }
    });
  if (!validateRubric) {
    validation.messages.push(<MrTranslate id={"RubricsCustomComponent.all_fields_are_mandatory_message"} />);
    validation.success = false;
  }
  return validation;
};

const RubricsCustomComponent = (props) => {
  console.log("RubricsCustomComponent ====> ", props);
  const { TextArea } = Input;
  const values = props.value
  let defaultState
  const mrIntl = useTranslate();
  const type_c = props.formInstance.getFieldValue(["type_c"])
  const currentUser = useSelector(currentUserSelector())

  console.log("type_c =======>", type_c);
  
  if (values === undefined) {
    defaultState = {
      criteria: [{
        // id: 0,
        title: "A",
        label: "",
        desc: "",
        type: "",
        strands: [],
        levels_json: [{
          id: 0,
          level_points: "",
          level_label: "",
          desc: "",
        }]
      }],
      settings: {
        use_scoring: false,
        one_level_for_all: true,
        strands: false,
      }
    }
  } else {
    defaultState = values
  }

  const [state, setState] = useState(defaultState)
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
  const [showPreviewRubrics, setShowPreviewRubrics] = useState(false);
  console.log("state ========>", state);
  useEffect(() => {
    if((type_c !== "points") && (type_c !== "comments_only")) {
      props.onChange(state)
      changeCriteriaTitleByIndex()
    }
  }, [stateUpdatedCount])


  if((type_c === "points") || (type_c === "comments_only")) {
    return null
  }

  // window.addEventListener("scroll", (event) => {
  //   console.log("onScroll event", event);
  //   // var element = document.getElementsByClassName('levels-main-wraper')[0];
  //   // element.scrollIntoView({ behavior: 'smooth' });
  // })

  const changeCriteriaTitleByIndex = () => {
    const stateObj = [...state.criteria]
    stateObj.map((data, index) => {
      const currentAlphabet = currentAlphabetByIndex(index)
      const strands = data.strands.length ? data.strands : []
      data.title = currentAlphabet.toUpperCase()
      strands.forEach((s, j) => {
        s.title = getQuestionNumber(j + 1, 3)
      })
    })
    const finalStateObj = {
      ...state,
      criteria: stateObj
    }
    return finalStateObj
    // setStateUpdatedCount(stateUpdatedCount + 1)
  }

  const handleRearrange = (type, levelIndex, parentIndex, criteriaMenu) => {
    console.log("handleRearrange", type, levelIndex, parentIndex);
    const stateObj = [...state.criteria]
    if (type === "moveRight") {
      if (state.settings.one_level_for_all) {
        stateObj.map((d, i) => {
          var element = stateObj[i].levels_json[levelIndex];
          stateObj[i].levels_json.splice(levelIndex, 1);
          stateObj[i].levels_json.splice(levelIndex + 1, 0, element);
        })
      } else {
        var element = stateObj[parentIndex].levels_json[levelIndex];
        stateObj[parentIndex].levels_json.splice(levelIndex, 1);
        stateObj[parentIndex].levels_json.splice(levelIndex + 1, 0, element);
      }
    } else if (type === "moveLeft") {
      if (state.settings.one_level_for_all) {
        stateObj.map((d, i) => {
          var element = stateObj[i].levels_json[levelIndex];
          stateObj[i].levels_json.splice(levelIndex, 1);
          stateObj[i].levels_json.splice(levelIndex - 1, 0, element);
        })
      } else {
        var element = stateObj[parentIndex].levels_json[levelIndex];
        stateObj[parentIndex].levels_json.splice(levelIndex, 1);
        stateObj[parentIndex].levels_json.splice(levelIndex - 1, 0, element);
      }
    } else if (type === "moveUp" && criteriaMenu) {
      var element = stateObj[parentIndex];
      stateObj.splice(parentIndex, 1);
      stateObj.splice(parentIndex - 1, 0, element);
    } else if (type === "moveDown" && criteriaMenu) {
      var element = stateObj[parentIndex]
      stateObj.splice(parentIndex, 1);
      stateObj.splice(parentIndex + 1, 0, element);
    }

    setState({
      ...state,
      criteria: stateObj
    })
    setStateUpdatedCount(stateUpdatedCount + 1)
  }

  const addCriteria = (index, type) => {
    console.log("addCriteria index", index);
    const newId = state.criteria.length;
    const stateObj = [...state.criteria];
    if (state.settings.one_level_for_all) {
      const firstLevel = state.criteria[0].levels_json
      const objToPush = {
        // id: newId,
        title: "",
        label: "",
        desc: "",
        type: type,
        strands: [],
        levels_json: []
      };
      firstLevel.map((data, i) => {
        const objLevel = {
          id: i,
          level_points: data.level_points,
          level_label: data.level_label,
          desc: "",
        }
        objToPush.levels_json.push(objLevel)
      })
      stateObj.splice(index + 1, 0, objToPush);
    } else {
      const objToPush = {
        // id: newId,
        title: "",
        label: "",
        desc: "",
        strands: [],
        levels_json: [{
          id: newId,
          level_points: "",
          level_label: "",
          desc: "",
        }]
      };
      stateObj.splice(index + 1, 0, objToPush);
    }
    const finalStateObj = {
      ...state,
      criteria: stateObj
    }
    setState(finalStateObj)
    console.log("oneLevelForAll", values);
    setStateUpdatedCount(stateUpdatedCount + 1)
  }

  const addStrands = (index, parentIndex) => {
    console.log("addCriteria index", index);
    const newId = state.criteria.length;
    const stateObj = [...state.criteria];
    if (state.settings.one_level_for_all) {
      const firstLevel = state.criteria[0].levels_json || []
      const objToPush = {
        id: getUniqueId(),
        title: "",
        label: "",
        levels_json: []
      };
      firstLevel.map((data, i) => {
        const objLevel = {
          id: i,
          level_points: data.level_points,
          level_label: data.level_label,
          desc: "",
        }
        objToPush.levels_json.push(objLevel)
      })
      stateObj[parentIndex].strands.splice(index + 1, 0, objToPush);
    } else {
      const objToPush = {
        id: getUniqueId(),
        title: "",
        label: "",
        levels_json: [{
          id: newId,
          level_points: "",
          level_label: "",
          desc: "",
        }]
      };
      stateObj[parentIndex].strands.splice(index + 1, 0, objToPush);
    }
    const finalStateObj = {
      ...state,
      criteria: stateObj
    }
    setState(finalStateObj)
    console.log("oneLevelForAll", values);
    setStateUpdatedCount(stateUpdatedCount + 1)
  }

  const customOnChange = (data, criteriaName, levelName, criteriaIndex, levelIndex, type, strandIndex) => {
    console.log("customOnChange ======>", data, criteriaName, levelName, criteriaIndex, levelIndex, type, strandIndex);
  
    const criteriaCopy = [...state.criteria];
  
    if (state.settings.one_level_for_all && (levelName === "level_points" || levelName === "level_label")) {
      if (levelIndex !== null) {
        criteriaCopy.forEach((c, i) => {
          const levelJson = type === "strands" ? c.strands[criteriaIndex].levels_json[levelIndex] : c.levels_json[levelIndex];
          levelJson[levelName] = data;
        });
      }
    } else {
      if (levelIndex !== null) {
        const levelJson = type === "strands" ? criteriaCopy[criteriaIndex].strands[strandIndex].levels_json[levelIndex] : criteriaCopy[criteriaIndex].levels_json[levelIndex];
        levelJson[levelName] = data;
      } else {
        const finalCriteria = type === "strands" ? criteriaCopy[criteriaIndex].strands[strandIndex] : criteriaCopy[criteriaIndex];
        finalCriteria[criteriaName] = data;
        console.log("checking==>", criteriaCopy);
      }
    }
  
    setState({
      ...state,
      criteria: criteriaCopy,
    });
  
    setStateUpdatedCount(stateUpdatedCount + 1);
  };

  const addLevels = (index, parentIndex) => {
    // const newId = state.criteria.length - 1;
    const stateObj = [...state.criteria];
    if (state.settings.one_level_for_all) {
      stateObj.map((data, i) => {
        let uid = stateObj[i].levels_json.length
        const objToPush = {
          id: uid,
          level_points: "",
          level_label: "",
          desc: "",
        };
        stateObj[i]["levels_json"].splice(index + 1, 0, objToPush);
      })
    } else {
      let uid = stateObj[parentIndex].levels_json.length
      const objToPush = {
        id: uid,
        level_points: "",
        level_label: "",
        desc: "",
      };
      console.log("state here==>>", index, parentIndex, objToPush);
      stateObj[parentIndex]["levels_json"].splice(index + 1, 0, objToPush);
    }
    const finalStateObj = {
      ...state,
      criteria: stateObj
    }
    setState(finalStateObj)
    setStateUpdatedCount(stateUpdatedCount + 1)
  }

  const addLevelsToStrands = (index, parentIndex, strandIndex) => {
    // const newId = state.criteria.length - 1;
    const stateObj = [...state.criteria];
    let uid = stateObj[parentIndex].levels_json.length
    const objToPush = {
      id: uid,
      level_points: "",
      level_label: "",
      desc: "",
    };
    console.log("state here==>>", index, parentIndex, objToPush);
    stateObj[parentIndex].strands[strandIndex]["levels_json"].splice(index + 1, 0, objToPush);
    const finalStateObj = {
      ...state,
      criteria: stateObj
    }
    setState(finalStateObj)
    setStateUpdatedCount(stateUpdatedCount + 1)
  }

  const deleteLevels = (index, parentId) => {
    const stateObj = [...state.criteria];
    if (state.settings.one_level_for_all) {
      if (index != null) {
        stateObj.map((data, i) => {
          stateObj[i]["levels_json"].splice(index, 1);
        })
      } else {
        stateObj[parentId]._destroy = true
        // stateObj.splice(parentId, 1);
      }
    } else {
      if (index != null) {
        stateObj[parentId].levels_json.splice(index, 1);
      } else {
        stateObj[parentId]._destroy = true
        // stateObj.splice(parentId, 1);
      }
    }
    console.log("stateObjstateObjstateObj====>", stateObj, index, parentId);
    const finalStateObj = {
      ...state,
      criteria: stateObj
    }
    setState(finalStateObj)
    setStateUpdatedCount(stateUpdatedCount + 1)
  }

  const handleOneLevelForAll = () => {
    let finalStateObj
    const stateObj = [...state.criteria]
    const firstCriteriaLavels = state.criteria[0].levels_json
    if (state.settings.one_level_for_all) {
      stateObj.map((data, index) => {

      })
      const settingObj = {
        ...state.settings,
        one_level_for_all: !state.settings.one_level_for_all
      }

      finalStateObj = {
        ...state,
        settings: settingObj
      }
      setState(finalStateObj)
      setStateUpdatedCount(stateUpdatedCount + 1)
    } else {
      showConfirmModal({
        centered: true,
        title: mrIntl("CommonText.are_you_sure_title"),
        content: "",
        onOk: () => {
          const settingObj = {
            ...state.settings,
            one_level_for_all: !state.settings.one_level_for_all
          }
          const objToPush = {
            id: firstCriteriaLavels.length,
            level_points: "",
            level_label: "",
            desc: "",
          };
          stateObj.map((data, index) => {
            if (data.levels_json.length > firstCriteriaLavels.length) {
              const difference = data.levels_json.length - firstCriteriaLavels.length
              for (var i = 1; i <= difference; i++) {
                data.levels_json.pop();
              }
            } else if (data.levels_json.length < firstCriteriaLavels.length) {
              const difference = firstCriteriaLavels.length - data.levels_json.length
              for (var i = 1; i <= difference; i++) {
                data.levels_json.push(objToPush)
              }
            }
            data.levels_json.map((levelData, levelIndex) => {
              levelData.level_points = firstCriteriaLavels[levelIndex].level_points
              levelData.level_label = firstCriteriaLavels[levelIndex].level_label
            })
          })
          finalStateObj = {
            ...state,
            criteria: stateObj,
            settings: settingObj
          }
          setState(finalStateObj)
          setStateUpdatedCount(stateUpdatedCount + 1)
        },
        mrIntl: mrIntl
      })
    }
  }

  const menuItem = (type, levelIndex, parentIndex) => {
    const criteriaMenu = type == "criteria"
    const currentCriteriaLevels = state.criteria[parentIndex] && state.criteria[parentIndex].levels_json
    const menu =
      <Menu style={{ display: "flex" }}>
        {parentIndex != 0 && criteriaMenu &&
          <Menu.Item onClick={() => handleRearrange("moveUp", levelIndex, parentIndex, criteriaMenu)}>
            <ArrowUpOutlined />
          </Menu.Item>}
        {parentIndex + 1 != state.criteria.length && criteriaMenu &&
          <Menu.Item onClick={() => handleRearrange("moveDown", levelIndex, parentIndex, criteriaMenu)}>
            <ArrowDownOutlined />
          </Menu.Item>}
        {!criteriaMenu && levelIndex != 0 &&
          <Menu.Item onClick={() => handleRearrange("moveLeft", levelIndex, parentIndex, criteriaMenu)}>
            <ArrowLeftOutlined />
          </Menu.Item>}
        {!criteriaMenu && levelIndex + 1 != currentCriteriaLevels.length &&
          <Menu.Item onClick={() => handleRearrange("moveRight", levelIndex, parentIndex, criteriaMenu)}>
            <ArrowRightOutlined />
          </Menu.Item>}
        <Popconfirm
          title= {mrIntl("RubricsCustomComponent.are_you_sure_to_delete_this_level_confirm_msg")}
          onConfirm={() =>
            deleteLevels(levelIndex, parentIndex)
          }
          // onCancel={cancel}
          okText={mrIntl("CommonText.yes")}
          cancelText={mrIntl("CommonText.no")}
        >
          <Menu.Item>
            <DeleteOutlined />
          </Menu.Item>
        </Popconfirm>
      </Menu>

    return menu
  }

  const valid = validateJsonValue(state)
  console.log("validvalid====>", valid);
  return (
    <div className="rubric-criteria-levels">
      <Col span={12} style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "10px" }}>
        <Switch
          checked={state.settings.use_scoring}
          onChange={() => {
            setState({
              ...state,
              settings: {
                ...state.settings,
                use_scoring: !state.settings.use_scoring
              }
            })
            setStateUpdatedCount(stateUpdatedCount + 1)
          }}
        /> {mrIntl("RubricsCustomComponent.use_scoring_button")}
        <Switch
          checked={state.settings.one_level_for_all}
          onChange={() => handleOneLevelForAll()}
        />
        {mrIntl("RubricsCustomComponent.one_levels_are_same_for_all_criteria_message")}
        {currentUser.role === "superadmin" &&
          <span><Switch
            checked={state.settings.strands}
            disabled={state.settings.one_level_for_all}
            title={state.settings.one_level_for_all && mrIntl("RubricsCustomComponent.disable_one_level_for_all_to_enable_strands_title")}
            onChange={() => {
              setState({
                ...state,
                settings: {
                  ...state.settings,
                  strands: !state.settings.strands
                }
              })
              setStateUpdatedCount(stateUpdatedCount + 1)
            }}
          />
          {mrIntl("RubricsCustomComponent.strands")}
          </span>
        }
        <Button onClick={() => setShowPreviewRubrics(!showPreviewRubrics)}>Preview</Button>
      </Col>
      <Row>
        <Col>
          {state && state.criteria.map((criteria, index) => {
            if (!criteria._destroy) {
              return (
                <div>
                  <RubricCriteria
                    key={`criteria-${criteria.id}`}
                    criteria={criteria}
                    index={index}
                    state={state}
                    menuItem={menuItem}
                    customOnChange={customOnChange}
                    addCriteria={addCriteria}
                    createMode={true}
                    addStrands={addStrands} />
                  <Row>
                    <Col>
                      {state.settings.strands && criteria.strands && criteria.strands.length > 0 && criteria.strands.map((strand, strandIndex) => {
                        if (!strand._destroy) {
                          return (
                            <RubricCriteria
                              key={`strand-${strand.id}`}
                              criteria={strand}
                              // strand={strand}
                              index={index}
                              strandIndex={strandIndex}
                              state={state}
                              menuItem={menuItem}
                              customOnChange={customOnChange}
                              createMode={true}
                              addStrands={addStrands} 
                              type="strands" />
                          )
                        }
                      })
                      }
                    </Col>
                  </Row>
                </div>
              )
            }
          })} 
        </Col>
        <Col className="levels-main-wraper">
          {state && state.criteria.map((criteria, index) => {
            if (!criteria._destroy) {
              return (
                <div>
                {/* <Row> */}
                <div style={{ position: "relative" }}>
                  <div className="criteria-wrapper">
                    <div style={{ display: "flex" }}>
                      {!_.isEmpty(criteria.levels_json) && criteria.levels_json.map((level, levelIndex) => {
                        return (
                            <RubricLevels
                              levels={level}
                              levelIndex={levelIndex}
                              addLevels={addLevels}
                              parentIndex={index}
                              deleteLevels={deleteLevels}
                              customOnChange={customOnChange}
                              useScoring={state.settings.use_scoring}
                              oneLevelForAll={state.settings.one_level_for_all}
                              criteria={criteria}
                              menuItem={menuItem}
                              createMode={true}
                            />
                        )
                      })}
                    </div>
                  </div>
                </div>
                {/* </Row> */}
                <Row>
                <Col>
                  {state.settings.strands && criteria.strands && criteria.strands.length > 0 && criteria.strands.map((strands, strandIndex) => {
                    if (!strands._destroy) {
                      return (
                        <div style={{ position: "relative" }}>
                          <div className="criteria-wrapper">
                            <div style={{ display: "flex" }}>
                              {strands.levels_json && strands.levels_json.map((level, levelIndex) => {
                                return (
                                  <RubricLevels
                                    levels={level}
                                    levelIndex={levelIndex}
                                    addLevels={addLevelsToStrands}
                                    parentIndex={index}
                                    // deleteLevels={deleteLevels}
                                    customOnChange={customOnChange}
                                    useScoring={state.settings.use_scoring}
                                    oneLevelForAll={state.settings.one_level_for_all}
                                    strands={strands}
                                    menuItem={menuItem}
                                    createMode={true}
                                    strandIndex={strandIndex}
                                    type="strands"
                                  />
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })
                  }
                </Col>
              </Row>
              </div>
              )
            }
          })}
        </Col>
        <PreviewRubrics 
          addLevels={addLevels}
          addCriteria={addCriteria}
          deleteLevels={deleteLevels}
          customOnChange={customOnChange}
          state={state}
          menuItem={menuItem}
          createMode={true} 
          showPreviewRubrics={showPreviewRubrics} setShowPreviewRubrics={setShowPreviewRubrics}
        />
      </Row> 
    </div>
  )
}

export default RubricsCustomComponent;