import firebase from "firebase/compat/app";
import firebaseConfig from "./firebaseConfig.json";

import "firebase/compat/auth"
import "firebase/compat/firestore"
import 'firebase/compat/database';

// import loadable from '@loadable/component'
// const firebaseLib = loadable.lib(() => import(/* webpackChunkName: "firebase" */ 'firebase/app'))

// const firebaseConfig1 = {
//     apiKey: "AIzaSyDSJ3sVjyVBVC0FGCHFXxsJiYmT2YAvWYY",
//     authDomain: "assessprepv2dev.firebaseapp.com",
//     databaseURL: "https://assessprepv2dev.firebaseio.com",
//     projectId: "assessprepv2dev",
//     storageBucket: "assessprepv2dev.appspot.com",
//     messagingSenderId: "734907979281",
//     appId: "1:734907979281:web:d43f8a16fa8dbfeafa03b0"
//   };

// export const getFirestoreDB = () => {
//   firebaseLib.load().then((firebase) => {
//     console.log("Firebase after loaded =>", firebase)
//     const firebaseInstance = firebase.initializeApp(firebaseConfig);
//     return firebaseInstance.firestore()
//   })
// }
// console.log("firebase.database.ServerValue.TIMESTAMP, ", firebase.database.ServerValue.TIMESTAMP)



export const firebaseInstance = firebase.initializeApp(firebaseConfig);
export const firebaseAuth = firebaseInstance.auth()
export const firestoreDB = firebaseInstance.firestore();
export const realtimeDB = firebaseInstance.database();
export const firestoreFieldValue = firebase.firestore.FieldValue;
// if(firestoreDB){
//   firestoreDB.enablePersistence({
//     synchronizeTabs: true
//   })
//     .catch(function (err) {
//       if (err.code == 'failed-precondition') {
//         // Multiple tabs open, persistence can only be enabled
//         // in one tab at a a time.
//         // ...
//       } else if (err.code == 'unimplemented') {
//         // The current browser does not support all of the
//         // features required to enable persistence
//         // ...
//       }
//       console.log("firebase persistance error ==>", err)
//     });
  
// }
