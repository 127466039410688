import React from 'react';
import { Row, Col, Typography } from 'antd';
import './ViewCard1.scss';
import { checkIPAD, checkMob } from '/src/lib/utils/helperMethods';
import classNames from 'classnames';

const { Title, Text } = Typography;
const isIPAD = checkIPAD();
const isMobile = checkMob();
const isMobileOrIpad = isIPAD || isMobile

const ViewStatus = (props) => {
  const {
    statusIcon,
    statusText,
    statusTextType,
    statusMessage,
    showConfig = {}
  } = props;
 
  const { statusTextColorClassName = 'color-blue' } = showConfig;
  return (
    <Row className={`status-container ${isMobileOrIpad && 'status-container-ipad'}`}>
      <div className={`status-icon-circle outer-circle-${statusTextType}`} >
        <div 
          className={`status-icon-inner-circle inner-circle-${statusTextType}`}
        >
          {statusIcon}
        </div>
      </div>
      <Title 
        level={4} 
        className={`status-title ${statusTextColorClassName}`}
        type={statusTextType}
      >
          {statusText}
      </Title>
      <Title 
        level={4}
        className="status-message"
      >
        {statusMessage}
      </Title>
    </Row>
  );
};

export default ViewStatus;