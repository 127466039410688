import React from "react";
import PropTypes from 'prop-types';
import { List, Card } from "antd";
import { Link } from "react-router-dom";
// import ActionBtns from "/src/components/ActionBtns/ActionBtns";
const ProgrammeListItem = (props) => {
  // console.log("props", props);
  const { item, edited, deleted, actionBtns, resourceUrl, showForm } = props;
  return (
    <List.Item>
      <Card title={item.key}>
        {/* <Link to={resourceUrl}>{item.name}</Link> */}
        <Link to={`/programmes/${item.id}`}> {item.id} - {item.name}</Link>  
        {/* {JSON.stringify(item)} */}
        {actionBtns}
      </Card>
    </List.Item>
  );
};
ProgrammeListItem.defaultProps = {};

ProgrammeListItem.propTypes = {};

export default ProgrammeListItem;
