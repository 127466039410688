import React, { useEffect } from "react";

import DefaultCKE from "/src/components/UI/CKEditor/DefaultCKE"
import { CKETextFeedbackTools } from "/src/components/UI/CKEditor/CKEConfig"
import { InputArea } from '/src/components/UI/Segment/UIHelper'
import MediaShow from "/src/components/UI/Media/MediaShow"
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";

const CKETextFeedback = (props) => {
  console.log("CKE Text Caption props ==>", props);

  const onTextChanged = (data) => {
    props.onChange(data)
  }

  return (
    <InputArea
      className="singleline-input-area"
    >
      <BasicCKE
        key="feedback-message"
        tools={CKETextFeedbackTools}
        data={props.value}
        onTextChanged={(data) => onTextChanged(data)}
      >
      </BasicCKE>
      {props.files && <MediaShow
        file={props.files && props.files[0]}
        removeFile={props.removeFile}
      >
      </MediaShow>}
    </InputArea>
  );
};
export default CKETextFeedback;