
    if (import.meta.env.VITE_REGION === 'china') {
      console.log("Development Mode");

      
      // this works
      var csslink=document.createElement("link");
      csslink.href="https://production-eks-assets.assessprep.cn/fonts/nunito-sans.css";
      csslink.rel = 'stylesheet';
      csslink.onload = "this.media='all'";
      document.getElementsByTagName("head")[0].appendChild(csslink); 

      var csslink=document.createElement("link");
      csslink.href="https://production-eks-assets.assessprep.cn/fonts/open-sans.css";
      csslink.rel = 'stylesheet';
      document.getElementsByTagName("head")[0].appendChild(csslink); 

      // var script=document.createElement("script");
      // // script.async=true;
      // script.src="/mathquill-0.10.1/mathquill.js";
      // document.getElementsByTagName("head")[0].appendChild(script); 
    } else {
      var csslink=document.createElement("link");
      csslink.href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap";
      csslink.rel = 'stylesheet';
      csslink.onload = "this.media='all'";
      document.getElementsByTagName("head")[0].appendChild(csslink);

      var csslink=document.createElement("link");
      csslink.href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;550;600;700&display=swap";
      csslink.rel = 'stylesheet';
      document.getElementsByTagName("head")[0].appendChild(csslink); 
      
      // Uncomment for new font
      // Plus Jakarta Sans font link 
      // var csslink=document.createElement("link");
      // csslink.href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap"
      // csslink.rel = 'stylesheet';
      // csslink.onload = "this.media='all'";
      // document.getElementsByTagName("head")[0].appendChild(csslink);

    }
  