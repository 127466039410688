import React, { useRef, useEffect } from "react";

export function useInsideDetector(ref, detectInside, callback = null) {
  useEffect(() => {
    const handleClickInside = (event) => {
      console.log("event and event.type ==>", event, event.type)
      const el = event.target
      if (el.closest(".data-grid-container")) {
        // NOTE: Table custom onDoubleClick on cell was not triggering so returning for now
        return
      }

      if (ref.current && ref.current.contains(el)) {
        // alert("You clicked outside of me!");
        console.log("current target is", ref.current, el);
        if (callback) {
          callback(event);
        }
      }
    }

    if (detectInside) {
      document.addEventListener("mousedown", handleClickInside);
    }
    return () => {
      if (detectInside) {
        document.removeEventListener("mousedown", handleClickInside);
      }
    };
  }, []);
}

export default function DetectInsideClick(props) {
  const { detectInside, callback } = props;
  const wrapperRef = useRef(null);
  useInsideDetector(wrapperRef, detectInside, callback);

  return <span ref={wrapperRef}>{props.children}</span>;
}
