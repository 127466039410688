import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Input, Checkbox, Card} from "antd";

const CustomFormInput = (props) => {
  const {value, onChange} = props;
  console.log("roles props", props);
  // console.log("roles value", value);
  // value will be set by MrFormComponent automatically, in case of default value and edit form
  const [values, setValues] = useState(value);

  // const orgRoles = JSON.parse(values);
  const orgRoles = values;

  console.log("values", orgRoles);

  const getPermissions = (rolePerms) => {
    let permsRender = [];
    for (const key in rolePerms) {
      if (rolePerms.hasOwnProperty(key)) {
        console.log("key", key);
        console.log("value", rolePerms[key]);
        permsRender.push(
          <React.Fragment key={`showSection__${key}`}>
            {JSON.stringify(rolePerms[key])}
            {/* <Card title={key} className="section-card section-card-show">
              {JSON.stringify(rolePerms[key])}
            </Card> */}
            {/* <MrRow className="section-field-row">
              <MrColumn style={{flex: 4}}>{rolePerms[key]}</MrColumn>
            </MrRow> */}
          </React.Fragment>
        );
      }
    }
    return permsRender;
  }

  const customOnChange = (role, resource, action, val) => {
    console.log("onchange roles form role", role);
    console.log("onchange roles form resource", resource);
    console.log("onchange roles form action", action);
    console.log("onchange roles form val", val);
    // const val = event.target.value; // this could be just value check antd docs, basically it depends on the input type

    orgRoles[role][resource][action] = val;
    console.log("values updating", orgRoles);
    // setValues(JSON.stringify(orgRoles));
    // onChange(JSON.stringify(orgRoles));
    setValues(orgRoles);
    onChange(orgRoles);
    // setValues({...values, [key]: val});
    // onChange({...values, [key]: val})
  }

  let finalRender = [];
  if(orgRoles){
    for (const key in orgRoles) {
      if (orgRoles.hasOwnProperty(key)) {
        console.log("key", key);
        console.log("value", orgRoles[key]);
        const role = key;
        const rolePerms = orgRoles[key];
        finalRender.push(
          <React.Fragment key={`role_${role}`}>
            <Card title={role} className="section-card section-card-show">
              {/* {JSON.stringify(rolePerms.org)} */}
              {/* {getPermissions(roles[key])} */}
              {
                Object.keys(rolePerms).map((resource, i) => 
                  <div key={`${role}_${resource}`}>
                    <h3>{resource}</h3>
                    {/* {JSON.stringify(rolePerms[resource])}  */}

                    {
                      Object.keys(rolePerms[resource]).map((action, i) => 
                        <span key={`${role}_${resource}_${action}`}>
                          
                          {/* - */}
                          {/* {JSON.stringify(rolePerms[resource][action])}  */}
                          {/* |  */}
                          {/* change to select for type -> move this render to new fn */}
                          <Checkbox defaultChecked={rolePerms[resource][action]} onChange={(event) => customOnChange(role, resource, action, event.target.checked)}/>
                          {action}
                        </span>
                      )
                    }

                  </div>
                )
              }
            </Card>
          </React.Fragment>
        );
      }
    }
  }

  return finalRender;

  // return <React.Fragment>
  //     <Input value={values.key1} onChange={(event) => customOnChange("key1", event.target.value)}/>
  //     <Input value={values.key2} onChange={(event) => customOnChange("key1", event.target.value)}/>
  // </React.Fragment>;


};
CustomFormInput.defaultProps = {}
CustomFormInput.propTypes = {}
export default CustomFormInput;