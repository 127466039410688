import { Alert, Button, Col, Modal, Row, Select, Space, Switch, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import {
  activeAdjustedExperienceSelector,
  aiAssistantSettingsSelector,
} from "../../Experiences/selector";
import { useDispatch, useSelector } from "react-redux";
import { availableLanguageOptionsForAI } from "/src/lib/utils/helperMethods";
import { debounce, includes } from "lodash";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import TextArea from "antd/es/input/TextArea";
import MrSelect from "/src/components/UI/MrSelect/MrSelect";
import { actions as userReduxActions } from "/src/views/Users/redux";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import { currentUserSelector } from "../../Auth/Login/selector";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";

const AIGradingSettings = ({
  setAIAssistantSettings,
  initialAIAssistantSettings,
  aiAssistantSettings,
}) => {
  const mrIntl = useTranslate();
  const enabledFeatures = useSelector(enabledFeaturesSelector());

  const initialValues = initialAIAssistantSettings?.grading || {};

  const options = {
    gradingMode: [
      { label: "Lenient", value: "lenient" },
      { label: "Standard", value: "standard" },
      { label: "Strict", value: "strict" },
    ],
    feedbackLength: [
      { label: "Brief", value: "brief" },
      { label: "Standard", value: "standard" },
      { label: "Detailed", value: "detailed" },
    ],
    feedbackToneAndStyle: [
      { label: "Encouraging", value: "encouraging" },
      { label: "Neutral", value: "neutral" },
      { label: "Constructive", value: "constructive" },
      { label: "Formal", value: "formal" },
      { label: "Friendly", value: "friendly" },
    ],
    language: availableLanguageOptionsForAI.map((language) => ({
      value: language.toLowerCase(),
      label: language,
    })),
  };

  const onChangeGradingSettings = (key, value) => {
    setAIAssistantSettings((prevSettings) => ({
      ...prevSettings,
      grading: {
        ...prevSettings.grading,
        [key]: value,
      },
    }));
  };

  const debouncedSetInstructions = debounce(
    (value) => onChangeGradingSettings("additional_instructions", value),
    500
  );

  const renderSelect = (
    label,
    options,
    defaultValue,
    onChange,
    showSearch = false
  ) => (
    <Select
      options={options}
      defaultValue={defaultValue}
      style={{ width: "100%" }}
      onChange={onChange}
      showSearch={showSearch}
    />
  );

  return (
    <>
    <Alert
      message={mrIntl("AIAssistantSettings.these_settings_define_how_the_ai_grades_student_responses_and")}
      type="info"
      showIcon
      className="m-b-20"
    />
      {(enabledFeatures.grade_with_ai_using_seeds || import.meta.env.VITE_MODE === "development") && <Row className="m-b-20">
        <Col span={24}>
          <Space>
            <Switch
              size="small"
              checked={aiAssistantSettings?.grading?.use_seeds_for_grading}
              onChange={(value) => onChangeGradingSettings("use_seeds_for_grading", value)}
            />
            <label >
              {mrIntl("AIAssistantSettings.use_seeds_for_grading")}
            </label>
          </Space>
        </Col>
      </Row>}
      <Row className="m-b-20">
        <Col span={24}>
          <Space>
            <Switch
              size="small"
              checked={aiAssistantSettings?.grading?.decimal_values_in_points}
              onChange={(value) => onChangeGradingSettings("decimal_values_in_points", value)}
            />
            <label>
              {mrIntl("AIAssistantSettings.decimal_values_in_points")}
            </label>
          </Space>
        </Col>
      </Row>
      <Row className="m-b-20">
        <Col span={24}>
          <label className="m-b-5">
            {mrIntl("AIGradingSettingsModal.grading_mode")}
          </label>
          {renderSelect(
            "Grading Mode",
            options.gradingMode,
            initialValues.strictness,
            (value) => onChangeGradingSettings("strictness", value)
          )}
        </Col>
      </Row>
      <Row className="m-b-20">
        <Col span={11}>
          <label className="m-b-5">
            {mrIntl("AIGradingSettingsModal.feedback_language")}
          </label>
          {renderSelect(
            "Feedback Language",
            options.language,
            initialValues.feedback_language,
            (value) => onChangeGradingSettings("feedback_language", value),
            true
          )}
        </Col>
        <Col offset={2} span={11}>
          <label className="m-b-5">
            {mrIntl("AIGradingSettingsModal.feedback_length")}
          </label>
          {renderSelect(
            "Feedback Length",
            options.feedbackLength,
            initialValues.feedback_length,
            (value) => onChangeGradingSettings("feedback_length", value) // these key need to be updated
          )}
        </Col>
      </Row>
      <Row className="m-b-20">
        <Col span={24}>
          <label className="m-b-5">
            {mrIntl("AIGradingSettingsModal.feedback_tone_and_style")}
          </label>
          {renderSelect(
            "Feedback Tone and Style",
            options.feedbackToneAndStyle,
            initialValues.feedback_tone,
            (value) => onChangeGradingSettings("feedback_tone", value)
          )}
        </Col>
      </Row>
      <Row className="m-b-20">
        <Col span={24}>
          <label className="m-b-5">
            {mrIntl("AIGradingSettingsModal.additional_instructions")}
          </label>
          <TextArea
            maxLength={500}
            onChange={(e) => debouncedSetInstructions(e.target.value)}
            defaultValue={initialValues.additional_instructions}
            autoSize={{ minRows: 5, maxRows: 5 }}
            placeholder={mrIntl(
              "AIGradingSettingsModal.provide_additional_instructions_for_the_ai_grader_eg_prioritize_creativity"
            )}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    </>
  );
};

const AuthoringSettings = ({
  setAIAssistantSettings,
  initialAIAssistantSettings,
}) => {
  const mrIntl = useTranslate();


  const initialValues = initialAIAssistantSettings?.authoring || {};

  const onChangeAuthoringSettings = (key, value) => {
    setAIAssistantSettings((prevSettings) => ({
      ...prevSettings,
      authoring: {
        ...prevSettings.authoring,
        [key]: value,
      },
    }));
  };

  const options = {
    difficultyLevel: [
      { label: "Easy", value: "easy" },
      { label: "Medium", value: "medium" },
      { label: "Hard", value: "hard" },
    ],
    explanationLength: [
      { label: "Brief", value: "brief" },
      { label: "Standard", value: "standard" },
      { label: "Detailed", value: "detailed" },
    ],
    language: availableLanguageOptionsForAI.map((language) => ({
      value: language.toLowerCase(),
      label: language,
    })),
    numberOfQuestions: [{
      label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "5", value: 5 },
      { label: "10", value: 10 },
    ]
  };

  const debouncedSetInstructions = debounce(
    (value) => onChangeAuthoringSettings("overall_additional_instructions", value),
    500
  );

  const renderSelect = (
    label,
    options,
    defaultValue,
    onChange,
    showSearch = false
  ) => (
    <Select
      options={options}
      defaultValue={defaultValue}
      style={{ width: "100%" }}
      onChange={onChange}
      showSearch={showSearch}
    />
  );


  return (
    <>
    <Alert
      message={mrIntl("AIAssistantSettings.these_settings_define_how_the_ai_generates_questions_and_explanations")}
      type="info"
      showIcon
      className="m-b-20"
    />
    <Row className="m-b-20">
      {/* <Col span={24}> */}
      <Col span={12}>
        <label className="m-b-5">
          {mrIntl("AIAssistantSettings.output_language")}
        </label>
        {renderSelect(
          "Select language",
          options.language,
          initialValues.output_language,
          (value) => onChangeAuthoringSettings("output_language", value),
          true
        )}
      </Col>

    </Row>
    {/* <Row className="m-b-20">
      <Col span={11}>
        <label className="m-b-5">
        {mrIntl("AIAssistantSettings.difficulty_level")}
        </label>
        {renderSelect(
          "Difficulty level",
          options.difficultyLevel,
          initialValues.difficulty_level,
          (value) => onChangeAuthoringSettings("difficulty_level", value)
        )}
      </Col>
      <Col offset={2} span={11}>
        <label className="m-b-5">
        {mrIntl("AIAssistantSettings.number_of_questions")}
        </label>
        {renderSelect(
          "Number of Question",
          options.numberOfQuestions,
          initialValues.number_of_questions,
          (value) => onChangeAuthoringSettings("number_of_questions", value),
        )}
      </Col>
    </Row> */}
    <Row className="m-b-20">
      {/* <Col span={24}> */}
      <Col span={12}>
        <label className="m-b-5">
        {mrIntl("AIAssistantSettings.explanation_length")}
        </label>
        {renderSelect(
          "Explanation length",
          options.explanationLength,
          initialValues.explanation_length,
          (value) => onChangeAuthoringSettings("explanation_length", value) 
        )}
      </Col>
    </Row>
   
    {/* <Row className="m-b-20">
      <Col span={24}>
        <label className="m-b-5">
          {mrIntl("AIAssistantSettings.overall_additional_instructions")}
        </label>
        <TextArea
          maxLength={500}
          onChange={(e) => debouncedSetInstructions(e.target.value)}
          defaultValue={initialValues.overall_additional_instructions}
          autoSize={{ minRows: 5, maxRows: 5 }}
          placeholder={mrIntl("AIAssistantSettings.use_real_life_examples_or_create_data_based_questions")}
          style={{ width: "100%" }}
        />
      </Col>
    </Row> */}
  </>
  );
};

// const ChatSettings = ({
//   setAIAssistantSettings,
//   initialAIAssistantSettings,
// }) => {
//   const mrIntl = useTranslate();
//   const languageOptions = availableLanguageOptionsForAI.map((language) => ({
//     value: language.toLowerCase(),
//     label: language,
//   }));

//   const onChangeChatSettings = (newLanguage) => {
//     setAIAssistantSettings((prevSettings) => ({
//       ...prevSettings,
//       chat: {
//         ...prevSettings.chat,
//         language: newLanguage,
//       },
//     }));
//   };

//   const initialValues = initialAIAssistantSettings?.chat || {};

//   return (
//     <Row className="m-b-20">
//       <Space>
//         <label className="m-b-5">
//           {mrIntl("AIAssistantSettings.select_langauge")}
//         </label>
//         <MrSelect
//           config={{
//             style: { width: "120px" },
//             placeholder: mrIntl("AIAssistantSettings.select_langauge"),
//             defaultValue: initialValues.language || null,
//             onChange: onChangeChatSettings,
//             showSearch: true,
//           }}
//           options={languageOptions}
//         />
//       </Space>
//     </Row>
//   );
// };

const AIAssistantSettings = ({
  withText = false,
  defaultActiveTab = "authoring",
  showTransparentIconOnly = false,
  size = "middle",
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const aiAssistantSettingsFromSelector = useSelector(aiAssistantSettingsSelector());
  const currentUser = useSelector(currentUserSelector());
  const mrIntl = useTranslate();
  

  const initialAIAssistantSettings = aiAssistantSettingsFromSelector 
  const [isAIAssistantSettingsModalOpen, setIsAIAssistantSettingsModalOpen] =
    useState(false);
  const [aiAssistantSettings, setAIAssistantSettings] = useState(
    initialAIAssistantSettings
  );

  const saveSettings = () => {
    const updatedUser = {
      id: currentUser.id,
      org_user_org_id: currentUser.org_id,
      should_update_org_user: true,
      custom_fields: {
        ...currentUser?.custom_fields,
        ai_assistant_settings: aiAssistantSettings,
      },
    };
    console.log('these are the more settings==>',aiAssistantSettings)
    
    const callbacks = {
      success: {
        showMessage: true,
        message: "AI assistant settings updated successfully",
      },
      error: {
        showMessage: true,
        message: "Error in setting AI assistant settings",
      },
      successCallback: (response) => {
        const updatedSettings =
          response.data?.user?.data?.attributes?.custom_fields
            ?.ai_assistant_settings;
        if (updatedSettings) {
          dispatch(
            loginActions.updateCurrentUserSuccess({
              currentUser: {
                ...currentUser,
                custom_fields: {
                  ...currentUser?.custom_fields,
                  ai_assistant_settings: updatedSettings,
                },
              },
            })
          );
        }
      },
      errorCallback: (error) => {
        console.log("Error in updating AI assistant settings", error);
      },
    };

    dispatch(userReduxActions.update(updatedUser, callbacks));
    setIsAIAssistantSettingsModalOpen(false);
  };

  const toggleModal = (isOpen) => {
    setIsAIAssistantSettingsModalOpen(isOpen);
  };

  const items = [
    {
      key: "authoring",
      label: mrIntl("AIAssistantSettings.authoring"),
      children: (
        <AuthoringSettings
          initialAIAssistantSettings={initialAIAssistantSettings}
          setAIAssistantSettings={setAIAssistantSettings}
        />
      ),
    },
    {
      key: "grading",
      label: mrIntl("AIAssistantSettings.grading"),
      children: (
        <AIGradingSettings
          setAIAssistantSettings={setAIAssistantSettings}
          initialAIAssistantSettings={initialAIAssistantSettings}
          aiAssistantSettings={aiAssistantSettings}
        />
      ),
    },
    // {
    //   key: "chat",
    //   label: mrIntl("AIAssistantSettings.chat"),
    //   children: (
    //     <ChatSettings
    //       initialAIAssistantSettings={initialAIAssistantSettings}
    //       setAIAssistantSettings={setAIAssistantSettings}
    //     />
    //   ),
    // },
  ];

  const buttonStyle = withText
    ? {
        width: "100%",
        height: "100%",
        textAlign: "left",
        padding: "0px",
        background: "none",
      }
    : showTransparentIconOnly
    ? {
        background: "transparent",
        color: "white",
        border: "none",
        padding: "0px",
      }
    : {};

  return (
    <>
      <Tooltip
        title={!withText && mrIntl("AIAssistantSettings.ai_settings")}
        placement="right"
      >
        <Button
          style={buttonStyle}
          type={withText ? "text" : "default"}
          icon={<SettingOutlined />}
          onClick={() => toggleModal(true)}
          size={size}
          disabled={disabled}
        >
          {withText && mrIntl("AIAssistantSettings.ai_settings")}
        </Button>
      </Tooltip>

      {isAIAssistantSettingsModalOpen && (
        <Modal
          wrapClassName="ai-assistant-settings-modal-wrapper"
          // width={650}
          open={true}
          title={mrIntl("AIAssistantSettings.ai_assistant_settings")}
          onOk={saveSettings}
          onCancel={() => toggleModal(false)}
          okText={mrIntl("CommonText.save")}
        >
          <Tabs
            defaultActiveKey={defaultActiveTab}
            items={items}
            onChange={(key) => console.log(key)}
          />
        </Modal>
      )}
    </>
  );
};

export default AIAssistantSettings;
