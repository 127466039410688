import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const UseAISolutionsButton = ({ loading, onClick, onTextChanged }) => {
  const mrIntl = useTranslate();
  const handleOnClick = () => {
    // setStreamData && setStreamData((prevData) => ({
    //   ...prevData,
    //   showAIText: false,
    // }));
    onClick && onClick();
    onTextChanged && onTextChanged();
  }
  return (
    <Button
      type="primary"
      size="small"
      // shape="round"
      loading={loading}
      // style={{ marginRight: "5px" }}
      icon={<PlusOutlined />}
      onClick={() => handleOnClick()}
    >
      {mrIntl("Markscheme.use")}
    </Button>
  );
};

export default UseAISolutionsButton;
