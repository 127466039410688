import React, { useState, useEffect, useRef } from "react";
import { Spinner, MrSelect } from "mr_react_framework";
import { useDispatch, useSelector } from "react-redux";
import {
  appRegionSelector,
  currentUserSelector,
} from "/src/views/Auth/Login/selector";
import { checkMob, displayPoints, getAPColorPallete } from "/src/lib/utils/helperMethods";
import { Card, Col, Collapse, Row, Space, Statistic, Table, Tag, Tooltip, Typography } from "antd";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import { activeAdjustedExperienceSelector, experienceAnalyticsSummarySelector } from "/src/views/Experiences/selector";
import loadable from "@loadable/component";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import {actions as genericActions} from "/src/App/genericRedux";
import { experienceUsersSelector } from "/src/views/ExperienceUsers/selector";
import { isArray } from "lodash";
import { drawTopLineLabels } from "./ExperienceAnalytics";

const { Text } = Typography;

const RCJChart = loadable(() =>
  import(/* webpackChunkName: "react-chart" */ "/src/views/Segments/RCJChart/RCJChart")
);

const SelectCriteria = MrSelect({
  actions: genericActions,
  // resourceName: "criteria",
  // apiResourceName: "criteria",
  // resourceName: "questionbanks",
  config: {
    // url: "/criteria",
    // params: { key: "value" },
    processData: (data, props) => {
      console.log("data rubric type====>",data);
      return data.map((item) => {
        const label = `Criteria ${item.criteria_title}`;
        return { label: label, value: item.criteria_id };
      });
    },
    widgetConfig: {
      filterOption: true,
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      placeholder: "Select criteria",
      allowClear: true,
      style: { width: "100%" },
    },
  },
});

const useChartTooltip = () => {
  const tooltipRef = useRef(null);
  const isTooltipHoveredRef = useRef(false);

  useEffect(() => {
    return () => {
      if (tooltipRef.current) {
        tooltipRef.current.remove();
      }
    };
  }, []);

  const createTooltipElement = () => {
    const tooltipEl = document.createElement("div");
    tooltipEl.className = "chartjs-tooltip";
    Object.assign(tooltipEl.style, {
      pointerEvents: "auto",
      position: "absolute",
      background: "rgba(0, 0, 0, 0.8)",
      color: "white",
      padding: "8px",
      borderRadius: "5px",
      maxWidth: "200px",
      fontSize: "12px",
      zIndex: 1
    });
    return tooltipEl;
  };

  const getOrCreateTooltip = (chart) => {
    if (!tooltipRef.current) {
      const tooltipEl = createTooltipElement();
      chart.canvas.parentNode.appendChild(tooltipEl);
      tooltipRef.current = tooltipEl;
    }
    return tooltipRef.current;
  };

  const hideTooltip = () => {
    if (tooltipRef.current) {
      Object.assign(tooltipRef.current.style, {
        opacity: 0,
        pointerEvents: "none",
      });
    }
  };

  const renderTooltipContent = (tooltipData) => {
    if (!tooltipRef.current) return;

    const isScrollable = tooltipData.length > 10;
    tooltipRef.current.innerHTML = `
      Total students
      <div class="tooltip-container" style="
        max-height: ${isScrollable ? "190px" : "auto"};
        overflow-y: ${isScrollable ? "auto" : "visible"};
      ">
        ${tooltipData.map((name, index) => `<div class="tooltip-text">${index + 1}. ${name}</div>`).join("")}
      </div>
    `;

    tooltipRef.current.onmouseenter = () => isTooltipHoveredRef.current = true;
    tooltipRef.current.onmouseleave = () => {
      isTooltipHoveredRef.current = false;
      hideTooltip();
    };
  };

  const positionTooltip = (chart, tooltipModel) => {
    if (!tooltipRef.current) return;

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    const tooltipWidth = tooltipRef.current.offsetWidth;
    const chartWidth = chart.canvas.offsetWidth;
    let left = positionX + tooltipModel.caretX;
    let top = positionY + tooltipModel.caretY;
    if (left + tooltipWidth > chartWidth) {
      left = chartWidth - tooltipWidth - 40;
    }

    Object.assign(tooltipRef.current.style, {
      left: `${left}px`,
      top: `${top}px`,
      opacity: 1,
      pointerEvents: "auto",
    });
  };

  return { getOrCreateTooltip, hideTooltip, renderTooltipContent, positionTooltip, isTooltipHoveredRef };
};

const SummaryChart = (props) => {
  const { chartData, chartColors } = props
  console.log("ExperienceAnalyticsSummary SummaryChart chartData", chartData)
  const mrIntl = useTranslate()
  const { getOrCreateTooltip, hideTooltip, renderTooltipContent, positionTooltip, isTooltipHoveredRef } = useChartTooltip();

  let tooltip
  if (chartData?.student_names) {
    tooltip = {
      enabled: false,
      intersect: true,
      external: (context) => {
        const { chart, tooltip: tooltipModel } = context;
        const firstDataIndex = tooltipModel?.dataPoints?.[0]?.dataIndex;
        const tooltipData = chartData.student_names[firstDataIndex] || [];
  
        if (isTooltipHoveredRef.current) return; // Prevent tooltip update on hover
  
        getOrCreateTooltip(chart);
  
        if (!tooltipData.length || tooltipModel.opacity === 0) {
          hideTooltip();
          return;
        }
  
        renderTooltipContent(tooltipData);
        positionTooltip(chart, tooltipModel);
      }
    };
  }

  let chart_settings = {
    type: "bar",
    // type: "pie",
    title: "Score-distribution histogram",
    // xAxesLabel: mrIntl("ExperienceAnalyticsSummary.score_range"),
    yAxesLabel: mrIntl("ExperienceAnalyticsSummary.number_of_students"),
    labels: chartData.labels, 
    // datasets: chartData.student_counts,
    // labels: ["10", "20", "30", "40"], // Column headers - week number or month number
    // labels: ["Jan", "Feb", "Mar", "Apr"], // Column headers - week number or month number
    datasets: [ // one object for each data row/line graph - one object for single line, multiple for multiple lines
      {
        data: chartData.student_counts, // customers
        // label: mrIntl("ExperienceAnalyticsSummary.points"),
        // label: mrIntl("ExperienceAnalyticsSummary.number_of_students"),
        // label: "Displays the distribution of students across different score bands",
        backgroundColor: chartColors, // array for pie/bar if all different needed - TODO: choose better colours
        // backgroundColor: ["red", "blue", "green", "yellow", "orange", "purple"] // array for pie/bar if all different needed
        barThickness: 30,
        minBarLength: 2,
      }
    ],
    showGridLines: false,
    showLegend: false,
    customColors: true, // only supported in bar for now
    // stacked: true,
    // stepSize: 2, // using precision: 0 instead // only supported in bar for now
    tooltip: tooltip,
    topLine: drawTopLineLabels(false),
  }

  return <RCJChart chart_settings={chart_settings} />
}

const SummaryDistributionStudentNames = (props) => {
  console.log("SummaryDistributionStudentNames props", props);
  const { chartData, chartColors } = props;
  const { Panel } = Collapse;
  const { Text } = Typography;
  let panelsRender = []
  chartData.labels.forEach((label, i) => {
    let studentNames = chartData.student_names[i]
    let header = <Space><Tag color={chartColors[i]}>{label}</Tag></Space>
    let panel =  <Panel header={header} key="1" showArrow={false}>
      {studentNames ? studentNames.map((name, j) => {
        return name.concat(studentNames.length - 1 !== j ? ", " : "");
      }) : <Text type="secondary">-</Text> }
    </Panel>
    panelsRender.push(panel)  
  })
  let finalRender = <Collapse defaultActiveKey={['1']} collapsible={false}>
    {panelsRender}
  </Collapse>

  return finalRender;
}

const ExperienceAnalyticsSummary = (props) => {
  console.log("ExperienceAnalyticsSummary props ==>", props);
  // const { } = props;
  const dispatch = useDispatch();
  const mrIntl = useTranslate()
  const currentUser = useSelector(currentUserSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const rubric = activeExperience.rubric || {};
  const experienceAnalyticsSummaryData = useSelector(experienceAnalyticsSummarySelector());
  const experienceUsers = useSelector(experienceUsersSelector())
  
  let mappedStudents = [];
  if(activeExperience.group_students) {
    mappedStudents = activeExperience.group_students;
  } else if(activeExperience.students) {
    mappedStudents = activeExperience.students;
  }
  
  const showAssignedAndAbsentCount = mappedStudents.length > 0 ? true : false;

  console.log("experienceMemberships ====>", activeExperience);
  
  console.log("ExperienceAnalyticsSummary experienceAnalyticsSummaryData", experienceAnalyticsSummaryData);
  
  const apColors = getAPColorPallete();
  const [criteriaId, setCriteriaId] = useState(null);
  const [currentChartData, setCurrentChartData] = useState({});
  
  const isCWPAndAL = (rubric.type_c === "criteria_with_points" || rubric.type_c === "myp_achievement_level")
  const chartData = experienceAnalyticsSummaryData?.chart_data || {};
  const criteriaWiseDistribution = chartData.criteria_wise_distribution || [];
  const showTable = criteriaWiseDistribution.length > 0 && isCWPAndAL;
  const averagePercentage = isCWPAndAL ? currentChartData?.average_percentage : experienceAnalyticsSummaryData?.average?.percentage;
  const averagePoints = isCWPAndAL ? currentChartData?.average_points : experienceAnalyticsSummaryData?.median?.points;
  const maxPoints = isCWPAndAL ? currentChartData?.max_points : experienceAnalyticsSummaryData?.max?.points;
  const minPoints = isCWPAndAL ? currentChartData?.min_points : experienceAnalyticsSummaryData?.min?.points;
  const headerLabelsInAL = criteriaWiseDistribution[0]?.labels || [];
  
  
  // let chartColors = [apColors.red, apColors.red, apColors.red, apColors.lightorange, apColors.lightorange, apColors.lightorange, apColors.green, apColors.green, apColors.green, apColors.blue]
  let chartColors = [apColors.skyblue, apColors.skyblue, apColors.skyblue, apColors.skyblue, apColors.skyblue, apColors.skyblue, apColors.skyblue, apColors.skyblue, apColors.skyblue, apColors.skyblue]

  useEffect(() => {
    console.log("criteriaId =====>", criteriaId, criteriaWiseDistribution);
    if(isCWPAndAL && criteriaWiseDistribution.length) {
      // Ensure criteriaWiseDistribution matches the current experience criterium_associations by checking activeExperience.criterium_associations length.
      if(activeExperience.criterium_associations?.length === criteriaWiseDistribution?.length) {
        if(criteriaId) {
          let selectedChartData = criteriaWiseDistribution.find((item) => item.criteria_id === criteriaId);
          if(selectedChartData) {
            setCurrentChartData(selectedChartData);
          }
        } else {
          if(criteriaWiseDistribution.length) {
            setCriteriaId(criteriaWiseDistribution[0].criteria_id);
            setCurrentChartData(criteriaWiseDistribution[0]);
          }
        }
      }
    } else if(rubric.type_c === "points" && chartData) {
      setCurrentChartData(chartData)
    }
    
  }, [criteriaId, criteriaWiseDistribution.length, chartData.labels]);

  useEffect(() => {
    dispatch(experienceActions.getAnalytics({experience_id: activeExperience.id, type: "summary"}))
    return () => {
    };
  }, []);

  const columns = [
    {
      title: 'Criteria',
      dataIndex: 'criteria_title',
      key: 'criteria_title',
      fixed: 'left',
      width: 200,
      render: (text, record) => {
        console.log("record =====>", record, text);
        const strandsLabels = isArray(record.strands) &&  record.strands?.map((strand) => strand.label);
        const strandsLabelsString = strandsLabels.length ? strandsLabels.join(",") : "";
        const criteriaLabel = record.criteria_label;
        // console.log("newLabel =====>", strandsLabel);
        
        return <div>
          <Text strong >{record.criteria_title}: </Text>
          <Text type="secondary">{criteriaLabel}</Text> <br/>
          <Text type="secondary">{strandsLabelsString}</Text>
        </div>
      }
    }
  ];

  if(rubric.type_c === "points" || rubric.type_c === "criteria_with_points") {
    columns.push({
      title: 'Average',
      dataIndex: 'average_percentage',
      key: 'average_percentage',
      width: 200,
      fixed: 'left',
      render: (text) => (
        <Text strong>{text}%</Text>
      ),
    })
  }

  if(rubric.type_c === "criteria_with_points") {
    chartData?.labels?.map((label, index) => columns.push({
      title: label,
      dataIndex: label,
      key: `student_count_${index}`,
      width: 150,
      render: (text, record) => {
        const studentNames = (record.student_names && record.student_names[index]?.join(", ")) || "";

        return <div>
          <Tooltip title={studentNames}>
          {record?.student_counts[index]}<br/>
          <Text type="secondary">Students</Text>
        </Tooltip>
        </div>
      }
    }))
  } else if(rubric.type_c === "myp_achievement_level") {
    headerLabelsInAL.map((label, index) => columns.push({
      title: label,
      dataIndex: label,
      key: `student_count_${index}`,
      render: (text, record) => {
        console.log("student_counts =====>", record.student_names[index], label);
        const studentNames = (record.student_names && record.student_names[index]?.join(", ")) || "";
        return <Tooltip title={studentNames}>
          {record?.student_counts[index]}<br/>
          <Text type="secondary">Students</Text>
        </Tooltip>
      },
    }))
  }


  return (
    <React.Fragment>
      {/* {isFirebaseTokenLoading && <Spinner />} */}
      
      <Row gutter={16} className={"summary-statistic"}>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic className={"custom-stat"} title={mrIntl("CommonText.submissions")} value={`${activeExperience.submissions_count}`} />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic className={"custom-stat"} title={mrIntl("ExperienceAnalyticsSummary.published_grades")} value={`${activeExperience.marked_submissions_count}`} />
          </Card>
        </Col>
        {showAssignedAndAbsentCount && <Col span={6}>
          <Card bordered={false}>
            <Statistic className={"custom-stat"} title={mrIntl("ExperienceAnalyticsSummary.assigned")} value={experienceAnalyticsSummaryData.assigned_students_count} />
          </Card>
        </Col>}
        {showAssignedAndAbsentCount && <Col span={6}>
          <Card bordered={false}>
            <Statistic className={"custom-stat"} title={mrIntl("ExperienceAnalyticsSummary.absent")} value={`${experienceAnalyticsSummaryData.absent_students_count}`} />
          </Card>
        </Col>}
        {/* <Col span={5}>
          <Statistic title={mrIntl("ExperienceAnalyticsSummary.median")} value={`${experienceAnalyticsSummaryData.median.percentage} %`} />
        </Col>
        <Col span={5}>
          <Statistic title={mrIntl("ExperienceAnalyticsSummary.min")} value={`${experienceAnalyticsSummaryData.min.percentage} %`} />
        </Col>
        <Col span={5}>
          <Statistic title={mrIntl("ExperienceAnalyticsSummary.max")} value={`${experienceAnalyticsSummaryData.max.percentage} %`} />
        </Col> */}
      </Row>
      {chartData && <Row>
        <Col span={24} className="m-t-0">
          <Card bordered={false}>
            <Row>
              <Col span={5} className="m-t-0">
                <Space direction="vertical" className={"m-l-10 p-r-30"} style={{ width: '70%', borderRight: "1px dashed #eee"}}>
                {rubric.type_c !== "points" && criteriaWiseDistribution.length > 0 && <SelectCriteria
                  value={criteriaId}
                  onChange={(value) => setCriteriaId(value)}
                  config={{
                    widgetConfig: {
                      options: criteriaWiseDistribution,
                      style: { width: "100%" },
                      placeholder: mrIntl("SegmentsFilter.all_criteria"),
                    },
                  }}
                />}
                  {rubric.type_c !== "myp_achievement_level" && <Statistic className={"custom-stat m-b-20"} title={mrIntl("ExperienceAnalyticsSummary.average_percentage")} value={`${displayPoints(averagePercentage)}%`} />}
                  <Statistic className={"custom-stat m-b-20"} title={mrIntl("ExperienceAnalyticsSummary.average")} value={`${displayPoints(averagePoints)}`} />
                  <Statistic className={"custom-stat m-b-20"} title={"Highest score"} value={`${displayPoints(maxPoints)}`} />
                  <Statistic className={"custom-stat m-b-20"} title={"Lowest score"} value={`${displayPoints(minPoints)}`} />
                </Space>
              </Col>
              <Col span={17} className="m-t-0">
                <SummaryChart chartData={currentChartData} chartColors={chartColors}/>
              </Col>            
            </Row>
          
          </Card>
        </Col>
        {/* <Col span={24} className="m-t-30">
          <SummaryDistributionStudentNames chartData={experienceAnalyticsSummaryData.chart_data} chartColors={chartColors}/>
        </Col> */}
      </Row>}
      {showTable && <Row className="m-t-30">
        <Col span={24}>
          <Table
            scroll={{ x: 800, y: "70vh" }}
            dataSource={criteriaWiseDistribution}
            columns={columns}
            pagination={false}
          />
        </Col>
      </Row>}
    </React.Fragment>
  );
};

export default ExperienceAnalyticsSummary;
