import React, { useEffect, useState } from "react";
import { Checkbox, InputNumber, Space, Tooltip, Typography } from "antd";
import { find } from "lodash";
import { useSelector } from "react-redux";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const { Text } = Typography;

const SelectCriteria = ({
  criteria = [],
  setCriteria,
  segment,
  disabled,
  showError,
  setShowError,
}) => {
  const mrIntl = useTranslate();
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const activeExperienceRubric = activeExperience && activeExperience.rubric;
  const [showPointsErrorMsg, setShowPointsErrorMsg] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState([]);

  let experienceRubricCriteria = [];
  if (
    activeExperienceRubric &&
    activeExperienceRubric.criteria
  ) {
    experienceRubricCriteria = activeExperienceRubric.criteria;
  }

  let criteriaLength = criteria && criteria.length;

  let segmentScas = segment && segment.criterium_associations_attributes;

  let isMYPAL = false;
  if (
    activeExperienceRubric &&
    activeExperienceRubric.type_c === "myp_achievement_level"
  ) {
    isMYPAL = true;
  }

  let errorMessage = null;

  if (showError && criteria.length === 0) {
    if (!showPointsErrorMsg) {
      errorMessage = mrIntl("SelectCriteria.please_select_at_least_one_criteria");
    } else {
      errorMessage = mrIntl("SelectCriteria.please_select_points");
    }
  }

  let checkboxOptions = [];

  checkboxOptions = experienceRubricCriteria.map((criteriaItem) => ({
    label: `${criteriaItem.title}-${criteriaItem.label}`,
    value: criteriaItem.label,
    id: criteriaItem.id,
  }));

  // get criteria values from criteria to set values in checkbox.group -- using with defaultValue also
  const getCriteriaValues = criteria => {
    let values = []
    if (criteria.length > 0) {
      values = criteria.map(item => {
        // removing "A-", "B-" from title to set values
        return item.title.substring(2)
      })
    }
    return values
  }

  useEffect(() => {
    if (criteriaLength > 0) {
      if (showError && !showPointsErrorMsg) {
        setShowError(false);
      }
    
      let criteriaValues = getCriteriaValues(criteria);
      setSelectedCriteria(criteriaValues);
    } else {
      setSelectedCriteria(null)
    }
    
  }, [criteria, criteriaLength]);

  useEffect(() => {
    if (segmentScas.length > 0) {
      const updatedCriteria = segmentScas.map((ca) => {
        let criteriaItem = {}
        if(ca.criterium_id && ca.custom_fields) {
          criteriaItem = {
            id: ca.criterium_id,
            title: `${ca.custom_fields.title}-${ca.custom_fields.label}`,
          };
        }
        if (ca.points) {
          criteriaItem.marks = parseInt(ca.points);
        }
        return criteriaItem;
      });

      setCriteria(updatedCriteria);
    }
  }, [segmentScas]);

  const handleChange = (selectedLabels) => {
    if (selectedLabels.length === 0) {
      setShowError(false);
      setShowPointsErrorMsg(false);
      errorMessage = null;
    }
    const updatedCriteria = experienceRubricCriteria.reduce((result, item) => {
      const marks = (criteria || []).find((ct) => ct.id === item.id) || {
        marks: 1,
      };
      if (selectedLabels.includes(item.label)) {
        let objToPush = {
          id: item.id,
          title: `${item.title}-${item.label}`,
        };
        if (!isMYPAL) {
          objToPush.marks = marks.marks;
        }
        result.push(objToPush);
      }
      return result;
    }, []);

    setCriteria(updatedCriteria);
  };

  const findValue = (id) => {
    const item = find(criteria, (item) => item.id === id);
    return item && item.marks;
  };

  const disabledInput = (id) => {
    let isCriteriaSelected = find(criteria, (ct) => ct.id === id);
    return disabled || !isCriteriaSelected;
  };

  const checkboxOptionsInput = checkboxOptions.map((option) => ({
    label:
      activeExperienceRubric.type_c === "criteria_with_points" ? (
        <Space direction="vertical">
          <InputNumber
            controls={false}
            disabled={disabledInput(option.id)}
            min={1}
            max={50}
            value={findValue(option.id)}
            className="rubric-points-input"
            key={`criteria-form-input-${option.id}`}
            addonBefore={
              <Tooltip title={option.label}>
                <div className="addon-label">{option.value}</div>
              </Tooltip>
            }
            placeholder={mrIntl("CommonText.pts")}
            onChange={(marks) => {
              setShowPointsErrorMsg(!marks);
              setShowError(!marks);
              const updatedCriteria = criteria.map((item) => {
                if (item.id === option.id) {
                  return {
                    ...item,
                    marks: marks,
                  };
                }
                return item;
              });
              setCriteria(updatedCriteria);
            }}
          />
        </Space>
      ) : (
        option.label
      ),
    value: option.value,
  }))

  return (
    <>
      <div>{mrIntl("SelectCriteria.select_criteria")}</div>
      <Checkbox.Group
        disabled={disabled}
        className="select-criteria m-t-5"
        value={selectedCriteria}
        options={checkboxOptionsInput}
        onChange={(e) => handleChange(e)}
      />
      <br />
      {errorMessage && <Text type="danger">{errorMessage}</Text>}
    </>
  );
};

export default SelectCriteria;
