import { Space } from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { displayPoints } from "/src/lib/utils/helperMethods";
import React from "react";

const ExperienceUserTotalPointsTitle = (props) => {
  console.log("ExperienceUserTotalPointsTitle", props);
  const { rubricType, exp_cas, experience } = props
  let finalRender
  const mrIntl = useTranslate()

  if (rubricType === "points") {
    finalRender = <span>({displayPoints(experience.points, false, true)})</span>
  } else if(exp_cas){
    let cPoints = 0
    if (rubricType === "myp_achievement_level") {
      finalRender = ""
    } else {
      exp_cas.map((ca) => {
        cPoints += parseFloat(ca.points);
      })
      finalRender = cPoints
    }
  }

  return (
    <Space direction="vertical" size={0}>
      <span>{mrIntl("ExperienceUserTotalPointsTitle.total")}</span>
      <span>{finalRender}</span>
    </Space>
  )
}

export default ExperienceUserTotalPointsTitle;