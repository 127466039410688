import React from "react";
import { Dropdown, Tooltip, Button, Menu, Badge } from "antd";
import { BookOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { sortBy, filter } from "lodash";
import { bookmarksSelector } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/selector";
import { checkMob, dropDownTrigger } from "/src/lib/utils/helperMethods";
// import { GrBookmark } from "react-icons/gr"; //Not able to use this family icon
// import { GoBookmark } from "react-icons/go"; 
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs"
import "/src/views/Experiences/ExperienceShow/Components/BookmarkList/BookmarkList.scss";

const BookmarkList = () => {
  const history = useHistory();
  const bookmarkedSegments = sortBy(
    filter(useSelector(bookmarksSelector), { bookmarked: true }),
    ["topic_idx", "question_number"]
  );
  const mrIntl = useTranslate()
  console.log("bookmarksSelector ==>", bookmarkedSegments);
  const { url } = useRouteMatch();

  const scrollTo = (segment_id) => {
    const currentSegment = bookmarkedSegments.find(
      (item) => item.segment_id == segment_id
    );
    console.log("scroll to segment ==>", bookmarkedSegments, segment_id, currentSegment)
    if (currentSegment) {
      const { topic_id, question_number, segment_id } = currentSegment;
      // dispatch(topicActions.setActiveIdSuccess({ id: topic_id }));
      history.push(`${url}/topics/${topic_id}#item-${segment_id}`);

      // setTimeout(() => {
      //   const scrolledElement = document.getElementById(
      //     `question-${question_number}`
      //   );
      //   // scrolledElement.scrollIntoView({ behavior: "smooth" });
      //   if (scrolledElement) {
      //     window.scrollTo({
      //       behavior: "smooth",
      //       top: scrolledElement.offsetTop - 3,
      //     });
      //   }
      // }, 500);
    }
  };

  const menus = bookmarkedSegments.map((item) => {
    return (
      <Menu.Item key={`${item.segment_id}`}>
        {mrIntl("BookmarkList.q")} {item.topic_idx + 1}.{item.question_number}
      </Menu.Item>
    );
  });

  const menu = <Menu key={"bookmark-menu"} onClick={({ key }) => scrollTo(key)}>{menus}</Menu>;
  let bookMarkedDropdown = (
    <Badge
      count={bookmarkedSegments && bookmarkedSegments.length}
      // showZero
      size="small"
      style={{
        backgroundColor: "#108ee9",
      }}
    >
      <Dropdown
        className="bookmark-dropdown"
        disabled={bookmarkedSegments.length === 0}
        overlay={menu}
        trigger={[dropDownTrigger]}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        <Tooltip
          title={!bookmarkedSegments.length && mrIntl("BookmarkList.no_bookmarked_questions")}
          placement="bottom"
          getPopupContainer={()=> document.querySelector(".experience-header")}
        >
          <Button
            className="bookmark-btn"
            disabled={bookmarkedSegments.length === 0}
            // type='text'
            shape="circle"
            icon={
              bookmarkedSegments.length !== 0 ? (
                <BsBookmarkFill
                  className="bookmark-icon-fill"
                />
              ) : (
                <BsBookmark
                  className="bookmark-icon"
                />
              )
            }
          ></Button>
        </Tooltip>
      </Dropdown>
    </Badge>
  );
  return !checkMob() && bookMarkedDropdown;
};

export default BookmarkList;
