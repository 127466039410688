// Entry component
import { App, message as message1, notification as notification1, Modal as modal1 } from 'antd';

let message
let notification
let modal

// const AntdAppHelper1 = () => {
//   const staticFunction = App.useApp();
//   message = staticFunction.message;
//   modal = staticFunction.modal;
//   notification = staticFunction.notification;
//   console.log("message import helper", message);
//   return null;
// };

// export default AntdAppHelper1


const AntdAppHelper = () => {
  const [messageApi, contextHolderMessage] = message1.useMessage({top: 50});
  const [notifApi, contextHolderNotif] = notification1.useNotification({top: 60});
  const [modalApi, contextHolderModal] = modal1.useModal();
  message = messageApi
  notification = notifApi
  modal = modalApi
  console.log("message import helper", message, message1);
  let finalRender = []
  finalRender.push(contextHolderMessage)
  finalRender.push(contextHolderNotif)
  finalRender.push(contextHolderModal)
  return finalRender;
};

export default AntdAppHelper

console.log("message import helper", message);
export { message, modal, notification };