import React from "react";
import { Alert, Button, Progress, Space, Tag, Tooltip } from "antd";
import "./GradingExperienceUsersCustomLayout.scss"
import ExperienceUsersActions from "./ExperienceUsersList/ExperienceUsersActions";
import { useSelector } from "react-redux";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { experienceUsersSelector } from "./selector";
import { getMBTaskUrl  } from "/src/views/Experiences/ExperienceHelperMethods";
import { currentUserSelector, enabledFeaturesSelector, getEmbeddedSelector } from "/src/views/Auth/Login/selector";
import { useLocation } from "react-router-dom";
import {
  LoadingOutlined,
  CheckCircleOutlined, 
  CloseCircleOutlined
} from "@ant-design/icons";
import MBIcon from "/src/assets/images/MB-icon-new.png";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import AssignReviewerButton from "./AssignReviewerButton";
import { getStepProps } from "./ExperienceUsersList/BulkUploadAnswerSheets";
import GradeAllSubmissions from "./GradeAllSubmissions";

const BulkUploadAnswerSheetsProgress = () => {
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const mrIntl = useTranslate();
  const bulkAutoMappingProgress = activeExperience?.custom_fields.bulk_auto_mapping_progress;

  if (!bulkAutoMappingProgress) return null;

  const { current: currentStep, status } = getStepProps(bulkAutoMappingProgress);

  const tagProps = {
    finish: { color: "success", icon: <CheckCircleOutlined /> },
    process: { color: "processing", icon: <LoadingOutlined /> },
    error: { color: "error", icon: <CloseCircleOutlined /> },
    default: { color: "default", icon: null }
  };

  const { color, icon } = tagProps[status] || tagProps.default;

  return (
    <Tooltip title={mrIntl("RightSideGradingActions.bulk_answer_sheets_mapping_progress")}>
      <Tag color={color} icon={icon}>
        {`${bulkAutoMappingProgress.message} (${currentStep + 1}/3)`}
      </Tag>
    </Tooltip>
  );
};

const OverallGradingProgress = (props) => {
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const allExperienceUsers = useSelector(experienceUsersSelector())
  // const allUserResponses = useSelector(dbUserResponsesSelector(activeExperience.id)); 
  console.log("OverallGradingProgress", allExperienceUsers.length, activeExperience.submissions_count)
  // const totalItems = activeExperience.submissions_count;
  // const markedItems = activeExperience.marked_submissions_count;
  // let markedPercentage = (markedItems / totalItems * 100).toFixed(0);
  const mrIntl = useTranslate()
  let markedResponsesCount = 0
  allExperienceUsers.map((eu) => markedResponsesCount += eu.marked_responses_count) 
  let totalResponsesCount = allExperienceUsers.length * activeExperience.attemptable_questions_count
  let overallPercentage = 0
  if(totalResponsesCount !== 0){
    overallPercentage = ((markedResponsesCount * 100) / totalResponsesCount).toFixed(0);
  }

  return (
    allExperienceUsers.length > 0 && <>
      <Tooltip
      //  title={`Total responses graded (${markedResponsesCount}/${totalResponsesCount})`}
       title={mrIntl("RightSideGradingActions.total_responses_graded", {markedResponsesCount: markedResponsesCount,totalResponsesCount: totalResponsesCount})}
       >
        <Progress percent={overallPercentage} size="small" status="active" className='grade-progress' />
      </Tooltip>
      {/* <Tooltip title={`Student graded (${markedItems}/${totalItems})`}>
        <Progress percent={markedPercentage} size="small" status="active" className='grade-progress' />
      </Tooltip> */}
    </>
  )
}

const MBLinkedInfo = (props) => {
  const { experience, rubricType } = props
  const currentUser = useSelector(currentUserSelector())
  const location = useLocation();
  const mrIntl = useTranslate();

  let isStudent = currentUser.role === "student" || currentUser.role === "parent";
  let isMYPAchievementLevel =
    rubricType === "myp_achievement_level"
      ? true
      : false;
  const embedded = useSelector(getEmbeddedSelector())
  const mbGradeBookEmbeddedView =
    embedded &&
      experience.is_mb_linked &&
      location.pathname.indexOf("grade") > -1
      ? true
      : false;

  let showOpenInMBBtn = false;
  if (
    experience.custom_fields.mb_class_id &&
    experience.custom_fields.mb_task_id &&
    !isStudent
  ) {
    showOpenInMBBtn = true;
  }
  let showMBLinkedAlert = false;
  if (experience.is_mb_linked && !mbGradeBookEmbeddedView && !isMYPAchievementLevel && !isStudent) {
    showMBLinkedAlert = true;
  }
  let showGradingDisabledAlert = false;
  // if (
  //   experience.is_mb_linked &&
  //   !mbGradeBookEmbeddedView &&
  //   isMYPAchievementLevel && 
  //   !isStudent
  // ) {
  //   // showing alert that grading disabled if mb linked and myp_al. please grade on MB and grades will be sent here too.
  //   showGradingDisabledAlert = true;
  // }

  const mbLinkedInfoRender = []

  const MBIconImage = <img
    alt="MB-logo"
    style={{ width: "16px", marginTop: "-2px", marginLeft: "5px", verticalAlign: "middle" }}
    src={MBIcon}
    onClick={() => {
      window.open(getMBTaskUrl(currentUser.org, experience), "_blank");
    }}
  ></img>

  const openInMBButton = <Button
    size="small"
    type={"primary"}
    href={getMBTaskUrl(currentUser.org, experience)}
    target={"_blank"}
  >
    <MrTranslate id={"RightSideGradingActions.open_in"}/> {MBIconImage}
  </Button>

  

  if(showMBLinkedAlert){
    mbLinkedInfoRender.push(
      <Tooltip
      // title={"This assessment is linked to a ManageBac task. Submissions are also sent to the linked ManageBac task. After grading and giving feedback when you publish the result or export to PDF on AssessPrep, the total points, comments and the submission PDF are automatically sent to the linked ManageBac task gradebook."}
      title={mrIntl("RightSideGradingActions.linked_to_managebac_task")}
      placement="bottom"
        >
          <Alert
          message={mrIntl("RightSideGradingActions.linked_to_managebac")}
          type="info"
          showIcon
        />
    </Tooltip>
    )
  }

  if(showGradingDisabledAlert){
    mbLinkedInfoRender.push(
      <Tooltip
        // title={"This assessment is linked to a ManageBac task. Submissions are also sent to the linked ManageBac task. Since this is an MYP Achievement Level assessment, overall grading is disabled on AssessPrep and can be done directly on ManageBac. The criteria levels assigned on ManageBac will be sent to AssessPrep as well."}
        title={mrIntl("RightSideGradingActions.overall_grading_disabled")}
        placement="bottom"
        >
        <Alert
          message={mrIntl("RightSideGradingActions.overall_grading")}
          type="warning"
          showIcon
        />
      </Tooltip>
    )
  }

  if(showOpenInMBBtn){
    mbLinkedInfoRender.push(openInMBButton)
  }

  return <Space>{mbLinkedInfoRender}</Space>
}

const RightSideGradingActions = (props) => {
  const { experience, gradingConfig, setGradingConfig } = props
  console.log("GradingActionsBar props ==>", props);
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const rubricType = activeExperience && activeExperience.rubric && activeExperience.rubric.type_c
  const showOverallGradingProgress = rubricType !== "comments_only"
  const experienceSettings = (activeExperience && activeExperience.settings) || {}
  const experienceUsers = useSelector(experienceUsersSelector())

  const gradeAllMagicButtonEnabled =
    experienceUsers.length &&
    rubricType === "points" &&
    !experienceSettings.peer_review_enabled &&
    (enabledFeatures.grade_all_with_ai ||
      import.meta.env.VITE_MODE === "development");

  let isPeerReviewEnabledWithSubmissions
  if (experienceSettings.peer_review_enabled && activeExperience.submissions_count) {
    isPeerReviewEnabledWithSubmissions = true
  }

  const gradeListActionsButton = (
    <ExperienceUsersActions
      experience={props.experience}
      gradingConfig={gradingConfig}
      setGradingConfig={setGradingConfig}
      // setSortType={setSortType}
      // by_group_id={props.params.by_group_id}
      exportModalConfig={props.exportModalConfig}
      setExportModalConfig={props.setExportModalConfig}
    ></ExperienceUsersActions>
  );


  const assignReviewerButton = 
    (experienceSettings.peer_review_enabled) &&
      <AssignReviewerButton
        activeExperience={activeExperience}
        reviewerSetting={experienceSettings.reviewer_setting}
        gradingConfig={gradingConfig}
        setGradingConfig={setGradingConfig} 
      ></AssignReviewerButton>

  return (
    <Space align="baseline">
      <MBLinkedInfo experience={experience} rubricType={rubricType} />
      {gradeAllMagicButtonEnabled ? <GradeAllSubmissions /> : null}
      {experienceUsers.length ? <BulkUploadAnswerSheetsProgress /> : null}
      {showOverallGradingProgress && <OverallGradingProgress />}
      {isPeerReviewEnabledWithSubmissions ? assignReviewerButton : null}
      {gradeListActionsButton}
    </Space>
  );
};

export default RightSideGradingActions;