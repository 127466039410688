import React, { useEffect, useState, useRef } from "react";

import { Button, Modal, Space, Tag } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import axios from "axios";
import {
  HiOutlineMicrophone,
  HiOutlinePause,
  HiOutlinePlay,
  HiOutlineStop,
} from "react-icons/hi";
import { VideoCameraAddOutlined } from "@ant-design/icons";
// import RecordRTC from "recordrtc";
// import ReactPlayer from 'react-player'

import loadable from "@loadable/component";
import { showInfoModal } from "../Segment/UIHelper";
import { checkMob, getPermissions } from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
const ReactPlayer = loadable(() =>
  import(/* webpackChunkName: "react-player" */ "react-player")
);
const RecordRTCLib = loadable(() =>
  import(/* webpackChunkName: "recordrtc" */ "recordrtc")
);

const MediaRecorder = (props) => {
  const { mediaFormSetting, setMediaFormSetting, onSaveMedia, audio, video } = props;
  console.log("MediaRecorder props", props);

  const mrIntl = useTranslate()

  const [stream, setStream] = useState(null);
  var permissionTitleMsg = audio
  ? "Please go to settings and allow to use your microphone!"
  : "Please go to settings and allow to use your camera and microphone!";
  const [mediaRecorder, setMediaRecorder] = useState({});
  const [mediaRecordConstraints, setMediaRecordConstraints] = useState({
    audio: true,
    video: video,
  })
  const [recorder, setRecorder] = useState({});

  useEffect(() => {
    const initMediaRecorder = (stream) => {
      const options = video
      ? { mimeType: "video/webm;codecs=vp8,opus" }
      : { mimeType: "audio/webm;codecs=opus" };
    
      RecordRTCLib.load()
        .then((module) => {
          console.log("Record RTC Lib is loaded ==>", module);
          const RecordRTC = module.default;
          const mediaRecorder = RecordRTC(stream, options);
          setMediaRecorder(mediaRecorder);
    
          if (video) {
            setStream(stream);
            const video = document.querySelector("#stream_video");
            video.srcObject = stream;
            video.onloadedmetadata = function (e) {
              video.play();
            };
          }
        })
    };

    getPermissions(mediaRecordConstraints, {
      successCallback: initMediaRecorder,
      errorCallback: errorNotSupported,
    });

    return () => {
      // To disable the save button on unmount
      setMediaFormSetting(prevMediaFormSetting => ({
        ...prevMediaFormSetting, 
        okDisabled: true
      })) 
    }
  }, []);

  const [timer, setTimer] = useState({
    minutes: 0,
    seconds: 0,
    duration: 0,
  });
  const maxRecordingDuration = mediaFormSetting.maxRecordingDuration || 1200;
  useEffect(() => {
    if (recorder.state) {
      setMediaFormSetting({
        ...mediaFormSetting,
        okDisabled: recorder.state != "stopped",
      });
    }
    console.log("recorder state changed ==>", recorder, recorder.state);
  }, [recorder.state]);

  useEffect(() => {
    if (mediaFormSetting.triggerSaveAction > 0) {
      handleOk();
    }
  }, [mediaFormSetting.triggerSaveAction]);

  useEffect(() => {
    console.log("Recorder state on change ==>", recorder.state);
    let timeInterval = null;
    if (recorder.state == "recording") {
      timeInterval = setInterval(() => {
        setTimer((timer) => {
          console.log("timer interval running ==>", timer);
          let { minutes, seconds, duration } = timer;
          let newSeconds = seconds + 1;
          let newMinutes = minutes;
          let newDuration = duration + 1;
          let newTimer = {
            ...timer,
            duration: newDuration,
            seconds: newSeconds,
          };

          if (newSeconds >= 60) {
            newMinutes += 1;
            newTimer = {
              ...newTimer,
              seconds: 0,
              minutes: newMinutes,
            };
          }

          if (newDuration >= maxRecordingDuration) {
            stopRecording();
            clearInterval(timeInterval);
            showInfoModal({
              type: "warning",
              // title: `You have reached max allowed duration of ${maxRecordingDuration} seconds.`,
              title: mrIntl("MediaRecorder.you_have_reached_max_allowed", {maxRecordingDuration: maxRecordingDuration})
            });
          }
          return newTimer;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timeInterval);
    };
  }, [recorder.state]);

  console.log("Updated recorder ==>", recorder);

  const startRecording = () => {
    console.log("Media Recorder on start ==>", mediaRecorder, video);
    if (!mediaRecorder.startRecording) {
      showInfoModal({
        type: "warning",
        // title: permissionTitleMsg,
        // title: "Please allow to use your microphone!",
        title: mrIntl("MediaRecorder.please_allow_microphone")
      });
      return;
    }
    mediaRecorder.startRecording();
    resetState();
    if(video) {
      let streamContainer = document.getElementById("stream_video");
       streamContainer.srcObject = stream
       streamContainer.style.display = "block";

    }
    setRecorder({
      ...recorder,
      state: mediaRecorder.state,
    });
    console.log("Custom Recorder on start ==>", recorder);
  };

  const pauseRecording = () => {
    mediaRecorder.pauseRecording();
    console.log("Media Recorder on pause ==>", mediaRecorder);

    setRecorder({
      ...recorder,
      state: mediaRecorder.state,
    });
    console.log("Custom Recorder on pause ==>", recorder);
  };

  const resumeRecording = () => {
    mediaRecorder.resumeRecording();
    console.log("Media Recorder on resume ==>", mediaRecorder);
    setRecorder({
      ...recorder,
      state: mediaRecorder.state,
    });
    console.log("Custom Recorder on resume ==>", recorder);
  };

  const stopRecording = () => {
    mediaRecorder.stopRecording(() => {
      console.log("Media Recorder on stop ==>", mediaRecorder);
      if(video){
        let streamContainer = document.getElementById("stream_video");
        streamContainer.style.display = "none";
        streamContainer.srcObject = null;
        streamContainer.muted = true;
        }

      setRecorder({
        ...recorder,
        saved: false,
        state: mediaRecorder.state,
        blob: mediaRecorder.getBlob(),
        tempUrl: mediaRecorder.toURL(),
      });
      console.log("Custom Recorder on stop", recorder);
    });
  };

  const errorNotSupported = () => {
    console.log("Recording not supported");
  };

  const stopRedDot = () => {
    stream?.getTracks().forEach((track) => {
      track.stop();
    });
  };
  
  const handleOk = () => {
    console.log("On save media ==>", recorder);
    if (!mediaRecorder) {
      return;
    }

    setRecorder({
      ...recorder,
      saved: true,
    });

    setMediaFormSetting({
      ...mediaFormSetting,
      okDisabled: true,
    });

    const blob = mediaRecorder.getBlob();
    // Issue: Adding an image, reopening the modal, and clicking "Add Audio" without recording breaks the code, causing a white screen due to the missing blob.
    if (!blob) {
      message.error(mrIntl("MediaRecorder.something_went_wrong"));
      return;
    }
    blob.duration = 40;
    console.log("file blob ==>", blob, blob.duration);
    const formData = new FormData();
    formData.append("file", blob, "recorded_audio.webm");

    console.log("Form data ==>", recorder, formData, mediaRecorder.getBlob());

    // return
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        token: token,
      },
    };
    const url =
      (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "attachments";
      axios.post(url, formData, config).then(
      (response) => {
        console.log("Returned response ==>", response);
        const files = [response.data];

        if (onSaveMedia) {
          onSaveMedia(files);
        }
        resetState();
        if (video) {
          stopRedDot();
        }
      },
      (error) => {
        message.error(mrIntl("MediaRecorder.something_went_wrong"));
        setMediaFormSetting({
          ...mediaFormSetting,
          okDisabled: false,
        });
      }
    );
  };

  const handleCancel = () => {
    setRecorder({});
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "",
      visible: false,
    });
    resetState();
    if (video) {
      stopRedDot();
    }
  };

  const resetState = () => {
    setRecorder({});
    setTimer({
      ...timer,
      minutes: 0,
      seconds: 0,
      duration: 0,
    });
  };
 
  const getForm = () => {
    const reactPlayerShow = video ? recorder.state != "recording" : true
    return (
      <div>
        <div className="recorder-form">
          <Space direction="vertical">
            <Space>
              {(!recorder.state || recorder.state == "stopped") && (
                <Button
                  onClick={(e) => startRecording()}
                  size="large"
                  shape="round"
                  type="primary"
                  ghost={true}
                  icon={video ? <VideoCameraAddOutlined/> : <HiOutlineMicrophone />}
                >
                  {!recorder.tempUrl ? mrIntl("MediaRecorder.start_recording") : mrIntl("MediaRecorder.record_again")}
                </Button>
              )}
              {!video && recorder.state == "recording" && (
                <Button
                  onClick={(e) => pauseRecording()}
                  size="large"
                  shape="round"
                  icon={<HiOutlinePause />}
                >
                  {mrIntl("CommonText.pause")}
                </Button>
              )}
              {recorder.state == "paused" && (
                <Button
                  onClick={(e) => resumeRecording()}
                  size="large"
                  shape="round"
                  icon={<HiOutlinePlay />}
                >
                  {mrIntl("MediaRecorder.resume")}
                </Button>
              )}
              {(recorder.state == "recording" ||
                recorder.state == "paused") && (
                <Button
                  onClick={(e) => stopRecording()}
                  size="large"
                  shape="round"
                  icon={<HiOutlineStop />}
                >
                  {mrIntl("MediaRecorder.stop")}
                </Button>
              )}
            </Space>
            <Tag
              size="large"
              color="error"
              // shape="round"
              // type="primary"
              // ghost={true}
            >
              {timer.minutes.toString().length < 2 && 0}
              {timer.minutes}:{timer.seconds.toString().length < 2 && 0}
              {timer.seconds}
            </Tag>
          </Space>
        </div>
        
        <div className="recorder-preview">
          {video && <video
            className="video-input"
            id="stream_video"
            style={{
              visibility: recorder.state == "recording" ? "visible" : "hidden",
              height: recorder.state  ? "360px" : "60px"
            }}
          />}
           {reactPlayerShow && (
            <ReactPlayer
              url={recorder.tempUrl}
              controls={true}
              width={"100%"}
              height={video ? "360px" : "60px"}
              playing={true}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                  forceAudio: video ? false : true,
                },
              }}
            />
          )}
          <p style={{ color: "#8b8b8b", marginTop: "5px" }}>
            <i>
              {maxRecordingDuration < 60 ?
                mrIntl("CommonText.max_allowed_duration_in_second", { maxRecordingDuration: maxRecordingDuration }) :
                maxRecordingDuration >= 120 ?
                  mrIntl("CommonText.duration_in_minutes", { maxRecordingDuration: maxRecordingDuration / 60 }) :
                  mrIntl("CommonText.duration_in_minute", { maxRecordingDuration: maxRecordingDuration / 60 })}
            </i>
          </p>
        </div>
      </div>
    );
  };

  const getFooter = () => {
    return [
      <Button
        key="back"
        onClick={(e) => handleCancel()}
        disabled={mediaFormSetting.okDisabled && mediaFormSetting.loading}
      // style={saveButtonStyle}
      >
        {mediaFormSetting.cancelText || "Return"}
      </Button>,
      <Button
        key="submit"
        type="primary"
        onClick={(e) => handleOk()}
        loading={mediaFormSetting.okDisabled && mediaFormSetting.loading}
        disabled={mediaFormSetting.okDisabled}
        // style={saveButtonStyle}
      >
        {mediaFormSetting.okText || mrIntl("CommonText.submit")}
      </Button>,
    ];
  };

  let finalRender;
  if (props.modal) {
    finalRender = (
      <Modal
        {...mediaFormSetting}
        width={checkMob() ? "" : "50%"}
        centered={checkMob() ? true : false}
        className={checkMob() ? "height-centered-modal" : ""}
        open={mediaFormSetting.visible}
        closable={false}
        maskClosable={false}
        keyboard={false}
        title={mediaFormSetting.title || "Insert Media"}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={getFooter()}
        loading={false}
      >
        <div className="media-recorder-form">{getForm()}</div>
      </Modal>
    );
  } else {
    finalRender = (
      <div className="media-recorder-form">
        {getForm()}
        {mediaFormSetting.requireFooter && getFooter()}
      </div>
    );
  }

  console.log("Final render ==>", finalRender);
  return finalRender;
};
export default MediaRecorder;
