import { Button, Space } from "antd";
import Notepad from "/src/components/Notepad/Notepad";
import React from "react";
import { useSelector } from "react-redux";
import {
  appRegionSelector,
  currentUserSelector,
  getEmbeddedSelector,
  enabledFeaturesSelector,
} from "/src/views/Auth/Login/selector";
import { currentViewSelector } from "../FirestoreInteractions/selector";
import { experienceViewModeSelector } from "/src/views/Experiences/selector";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import StudentChat from "./StudentChat";
import VideoCallWrapper from "./VideoCallWrapper";
import { checkMob } from "/src/lib/utils/helperMethods";
import TalkToGPT from "./TalkToGPT";
import { getDeviceInfo, isNewAppVersion } from "/src/App/OfflineApp/offlineAppHelper";

const StudentFloatingTools = (props) => {
  console.log("StudentFloatingTools props==>", props);

  const { setUserInfo, experience, experienceSettings } = props;
  const isMobile = checkMob();
  const appRegion = useSelector(appRegionSelector());
  const currentUser = useSelector(currentUserSelector());
  const currentView = useSelector(currentViewSelector);
  const embedded = useSelector(getEmbeddedSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const experienceViewMode = useSelector(experienceViewModeSelector()); 
  const appType = useSelector(appTypeSelector());

  const deviceInfo = getDeviceInfo(appType);
  const deviceOsVersion = deviceInfo.osVersion;

  const shouldDisableVideoCall = (appType === "mac" && !isNewAppVersion(deviceOsVersion,"11.0.0"))
  //TODO added in showVideoCall config if needed 
  let finalRender = [];

  let config = {
    showNotepad:
      !embedded &&
      !isMobile &&
      (currentView === "startTest" || currentView === "endTest"),
    showChat:
      experienceViewMode === "apTakeTest" &&
      !embedded,
    showVideoCall: false && !embedded && experienceSettings.enable_ap_video_monitoring && appType != "ios",
    showTalkToGPT: enabledFeatures.allow_chatgpt_for_students && experienceSettings.chatgpt_enabled
  };

  if(config.showNotepad) {
    finalRender.push(
      <Notepad />
    );
  }
  if(config.showChat){
    finalRender.push(
      <StudentChat setUserInfo={setUserInfo} experience={experience} />
    );
  }
  if (config.showVideoCall) {
    finalRender.push(
      <VideoCallWrapper
        videoCallUUID={experience.uid}
      />
    );
  }
  if (config.showTalkToGPT){
    finalRender.push(<TalkToGPT />)
  }


  return <Space direction="vertical" className="student-floating-tools">
    {finalRender}
  </Space>
};

export default StudentFloatingTools;
