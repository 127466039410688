import React, { useEffect, useState } from "react";

import { Button, Popover } from "antd";
import { LinkOutlined } from "@ant-design/icons"
import MediaButtons from "/src/components/UI/Media/MediaButtons"
import { AiOutlinePicture, AiOutlineAudio, } from "react-icons/ai"

const MediaHoverButton = props => {
  console.log("Media Buttons props", props);

  const { mediaButtonsConfig, onSaveMedia } = props
  const [popoverSetting, setPopoverSetting] = useState({})

  const handlVisibleChange = (value) => {
    console.log("handlVisibleChange ==>", value)
    setPopoverSetting({
      visible: value
    })
  }

  return (
    <Popover
      placement="left"
      content={<MediaButtons
        mediaButtonsConfig={mediaButtonsConfig}
        setPopoverSetting={(data) => setPopoverSetting(data)}
        onSaveMedia={(files) => onSaveMedia(files)}
      ></MediaButtons>}
      trigger="hover"
      open={popoverSetting.visible}
      onOpenChange={handlVisibleChange}
    >
      <Button
        // type="primary"
        type="text"
        shape="circle"
      // ghost
      >
        {/* <PlusOutlined /> */}
        <LinkOutlined />
      </Button>
    </Popover>
  );
}
export default MediaHoverButton;