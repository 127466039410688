import React, { useState } from 'react';
import { Space, Button, Input, InputNumber, Checkbox, Select, Typography, Tag, Row } from 'antd';
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector, enabledFeaturesSelector } from '/src/views/Auth/Login/selector';
import { CKETextGenerateAISourceTools } from "/src/components/UI/CKEditor/CKEConfig";
import ImagePreviewModal from './ImagePreviewModal';
import { activeAdjustedExperienceSelector } from '/src/views/Experiences/selector';
import { SelectATLSkills, SelectCommandTermLabel, SelectGlobalContext, SelectKeyConcepts, SelectPaperType, SelectPossibleExplorations, SelectQuestionbankUUID, SelectRelatedConcepts, SelectTeachingLevel, SelectTreeSyllabusContentsSingle } from '/src/components/AppSpecific/SelectSubject/SelectSubject';
import SelectCriteria from './SelectCriteria';
import SelectStrandsForm from './SelectStrandsForm';
import CKETextContent from '/src/views/Segments/CKETextContent/CKETextContent';
import SelectFromComputer from '/src/components/UI/Media/SelectFromComputer';
import { findSyllabusTopicKey, isNewGenerateQuestionsWithAI } from './generateAIHelperMethods';
import {actions as aiSegmentActions} from './redux'
import { aiGenerateQuestionsConfigSelector } from './selector';
const { TextArea } = Input;
const { Text } = Typography;

const SelectParametersForGenerate = (props) => {
  const {
    promptUsed, setPromptUsed, aiGenerateLoading, isStreaming, setSourceText, sourceImages, sourcePdfs, sourceText, extractQuestions, setExtractQuestions, showError, setShowError, questionType, setQuestionType, isModalOpen, setIsModalOpen, convertedImages, setConvertedImages, promptConfig, nestingLevel, keyConceptsIds, setKeyConceptsIds, relatedConceptsIds, setRelatedConceptsIds, globalContextId, setGlobalContextId, possibleExplorationId, setPossibleExplorationId, atlSkillsIds, setAtlSkillsIds, criteria, setCriteria, segment, selectStrands, setSelectStrands, syllabusTopicId, setSyllabusTopicId, setSyllabusTopicLabel, commandTerm, setCommandTerm, paperTypeId, setPaperTypeId, teachingLevelId, setTeachingLevelId, marks, setMarks, questionsCount, setQuestionsCount, additionalInstructions, setAdditionalInstructions, useCustomPrompt, setUseCustomPrompt, customPrompt, setCustomPrompt, useCustomExamples, setUseCustomExamples, customExamples, setCustomExamples, useExamples, setUseExamples, qbUUID, setQbUUID, generatedQuestionsLength, tokensUsed, requestCost, extractFromPdf, imageMediaFormSetting, setImageMediaFormSetting, pdfMediaFormSetting, setPdfMediaFormSetting, handleSaveFiles, createCondition
  } = props;
  const mrIntl = useTranslate();
  const dispatch = useDispatch();
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const aiGenerateQuestionsConfig = useSelector(aiGenerateQuestionsConfigSelector())
  const enableStandardsContentAreaTagMultiple = enabledFeatures.enable_standards_content_area_tag_multiple

  const [showPromptUsed, setShowPromptUsed] = useState(false)

  let keyConceptsIdsLength = keyConceptsIds && keyConceptsIds.length;
  let relatedConceptsIdsLength =
    relatedConceptsIds && relatedConceptsIds.length;
  let atlSkillsIdsLength = atlSkillsIds && atlSkillsIds.length;
  let selectStrandsLength = selectStrands && selectStrands.length;
  let criteriaLength = criteria && criteria.length
  const currentUser = useSelector(currentUserSelector());

  const newGenerateQuestionsWithAI = isNewGenerateQuestionsWithAI(enabledFeatures);

  // useEffect(() => {
  //   // dispatch(aiSegmentActions.fetchSuccess({data: {segments: []}}));
  //   setGeneratedQuestions([]);
  // }, [
  //   commandTerm,
  //   paperTypeId,
  //   teachingLevelId,
  //   syllabusTopicId,
  //   keyConceptsIdsLength,
  //   globalContextId,
  //   possibleExplorationId,
  //   relatedConceptsIdsLength,
  //   atlSkillsIdsLength,
  //   selectStrandsLength,
  //   criteriaLength,
  //   marks,
  //   sourceBasedOn, 
  //   criteria
  // ]);

  // useEffect(() => {
  //   if (isEmpty(aiGenerateQuestionsConfig)) {
  //     setSourceText("");
  //     setSourceImages("");
  //     setSourcePdfs("");
  //     setExtractQuestions(false);
  //     setQuestionType("");
  //     setKeyConceptsIds([]);
  //     setRelatedConceptsIds([]);
  //     setGlobalContextId("");
  //     setPossibleExplorationId(undefined);
  //     setAtlSkillsIds([]);
  //     setCriteria([]);
  //     setSelectStrands([]);
  //     setSyllabusTopicId("");
  //     setSyllabusTopicLabels("");
  //     setCommandTerm("");
  //     setPaperTypeId("");
  //     setTeachingLevelId("");
  //     setMarks(1);
  //     setQuestionsCount(3);
  //     setAdditionalInstructions("");
  //     setUseCustomPrompt(false);
  //     setCustomPrompt("");
  //     setUseCustomExamples(false);
  //     setCustomExamples("");
  //     setUseExamples(false);
  //     setQbUUID("");
  //   }
  // }, [aiGenerateQuestionsConfig])

  // #occ
  // let sourceBasedOnOptions = [
  //   { label: "Text", value: "text" }
  // ]
  // if (enabledFeatures.ai_generate_questions_from_image || isDevelopmentOrStagingMode) {
  //   sourceBasedOnOptions.push({ label: "Image", value: "image" })
  // }
  // if (enabledFeatures.ai_generate_questions_from_pdf || isDevelopmentOrStagingMode || currentUser.role === "qb_author") {
  //   sourceBasedOnOptions.push({ label: "PDF", value: "pdf" })
  // }
  const atlasDemoDataEnabled = enabledFeatures.atlas_integration_demo_data ? true : false;

  const isDPProgram =
    activeExperience.questionbank &&
    activeExperience.questionbank.custom_fields &&
    activeExperience.questionbank.custom_fields.programme_name
      ? activeExperience.questionbank.custom_fields.programme_name.includes(
          "IB Diploma"
        )
      : activeExperience.custom_fields &&
        activeExperience.custom_fields.org_programme_item_label &&
        activeExperience.custom_fields.org_programme_item_label.includes(
          "IB Diploma"
        );

        
  let isMYPAL = false;
  let isCWP = false;
  if (activeExperience && activeExperience.rubric) {
    if (activeExperience.rubric.type_c === "myp_achievement_level") {
      isMYPAL = true;
    }
    if (activeExperience.rubric.type_c === "criteria_with_points") {
      isCWP = true;
    }
  }



  let questionTypes = [
    {
      label: mrIntl("CommonText.long_answer"),
      value: "long_answer",
    },
    {
      label: mrIntl("CommonText.mcq_single_label"),
      value: "mcq",
    }  
  ];

  if (enabledFeatures.all_question_types_with_ai) {
    questionTypes.push(
      {
        label: mrIntl("CommonText.mcq_multiple"),
        value: "mcq_multiple"
      },
      {
        label: mrIntl("CommonText.true_false"),
        value: "true_false",
      },
      {
        label: mrIntl("CommonText.fill_text"),
        value: "fill_text",
      },
      {
        label: mrIntl("CommonText.fill_dropdown"),
        value: "fill_dropdown",
      },
      {
        label: mrIntl("CommonText.match"),
        value: "match",
      },
      {
        label: mrIntl("CommonText.sort"),
        value: "sort",
      },
      {
        label: mrIntl("CommonText.classify"),
        value: "classify",
      },
    );
  }
  if (isDPProgram && nestingLevel === 1 && !extractFromPdf && (enabledFeatures.all_question_types_with_ai || currentUser.role === "qb_author")) {
    questionTypes.push({
      label: "Question with sub-parts",
      value: "nested_question",
    });
  }
  if ((isMYPAL || isCWP) && nestingLevel === 1 && !extractFromPdf && (enabledFeatures.all_question_types_with_ai || currentUser.role === "qb_author")) {
    questionTypes.push({
      label: "Task",
      value: "task",
    })
  }

  let allSyllabusTopics = [];
  // if (activeExperience.standards_content_area_tag && activeExperience.standards_content_area_tag.final_json) {
  //   allSyllabusTopics = activeExperience.standards_content_area_tag.final_json.data;
  // }
  // allSyllabusTopics = activeExperience?.tagged_standards_content_areas_data?.tag?.final_json?.data || [];
  const experienceTaggedStandardsContentAreasData = activeExperience?.tagged_standards_content_areas_data;
  if (enableStandardsContentAreaTagMultiple) {
    experienceTaggedStandardsContentAreasData?.forEach((item) => {
      const itemTag = item?.tag || {};
      const itemTagFinalJson = itemTag.final_json || {};
      const itemTagFinalJsonData = itemTagFinalJson.data || [];
      if (itemTagFinalJsonData) {
        allSyllabusTopics.push(...itemTagFinalJsonData);
      }
    })
  } else {
    allSyllabusTopics = experienceTaggedStandardsContentAreasData?.tag?.final_json?.data
  }

  let firstActiveExperienceRelationItems = null;
  if (activeExperience && activeExperience.relation_items && activeExperience.relation_items[0]) {
    firstActiveExperienceRelationItems = activeExperience.relation_items[0];
  }

  let programme_id;
  if (firstActiveExperienceRelationItems) {
    programme_id = firstActiveExperienceRelationItems.programme_id;
  }

  let programme_subject_id;
  if (firstActiveExperienceRelationItems) {
    programme_subject_id = firstActiveExperienceRelationItems.programme_subject_id;
  }
  
  const handleRejectFiles = (type) => {
    let mediaFormSetting, setMediaFormSetting;
    if (type === "image") {
      mediaFormSetting = imageMediaFormSetting
      setMediaFormSetting = setImageMediaFormSetting
    } else {
      mediaFormSetting = pdfMediaFormSetting
      setMediaFormSetting = setPdfMediaFormSetting
    }
  
    setMediaFormSetting({
      ...mediaFormSetting,
      modal: false,
      visible: false,
      selectConfig: {
        ...mediaFormSetting.selectConfig,
        type: "pictures-wall",
      },
    });
  };
  
  return <Space direction='vertical' className="select-parameters">
    {showPromptUsed ? (
      <TextArea
        className="prompt-used-textarea select-parameter-field"
        value={promptUsed}
        rows={30}
        onChange={(e) => {
          setPromptUsed(e.target.value);
        }}
      />
    ) : (
      <Space direction='vertical'>
        {createCondition === "new" && (
          <Space direction="vertical" className="m-b-10">
              {!newGenerateQuestionsWithAI && <span>{mrIntl("GenerateAIWithPrompt.source")}</span>}
                <>
                  {!extractFromPdf && (
                    <>
                      <CKETextContent
                        ckeConfig={{
                          ...CKETextGenerateAISourceTools,
                          isReadOnly: aiGenerateLoading,
                        }}
                        writingAssistantConfig={{
                          enabled: true,    
                          loading: aiGenerateLoading,
                        }}
                        value={sourceText}
                        placeholder={mrIntl("SelectParametersForGenerate.jsx.add_source_text")}
                        onChange={(text) => {
                          setShowError(false);
                          setSourceText(text);
                          console.log("log 4343 ==>", text);
                          // if(text && sourceBasedOn !== "text") {
                          //   setSourceBasedOn("text")
                          // } else {
                          //   setSourceBasedOn("")
                          // }
                        }}
                      />
                      <Space>
                        <Button	
                          className='source-button'	
                          disabled={aiGenerateLoading || isStreaming || extractQuestions}	
                          onClick={(e) => {	
                            setExtractQuestions(false);	
                            setShowError(false);	
                            setImageMediaFormSetting({
                              ...imageMediaFormSetting, 
                              modal: true,
                              visible: true,
                              selectConfig: {
                                ...imageMediaFormSetting.selectConfig,
                                type: ""
                              }
                            })
                          }}	
                        >	
                          {mrIntl("SelectParametersForGenerate.image")}	
                        </Button>
                        <Button	
                          className='source-button'	
                          disabled={aiGenerateLoading || isStreaming || extractQuestions}	
                          onClick={(e) => {	
                            setExtractQuestions(false);	
                            // setSourceBasedOn("pdf");	
                            setShowError(false);	
                            setPdfMediaFormSetting({
                              ...pdfMediaFormSetting, 
                              modal: true,
                              visible: true,
                              selectConfig: {
                                ...pdfMediaFormSetting.selectConfig,
                                type: ""
                              }
                            })
                          }}	
                        >	
                          {mrIntl("SelectParametersForGenerate.pdf")}
                        </Button>
                      </Space>
                    </>
                  )}
                  <Row>
                    {(imageMediaFormSetting.modal || sourceImages.length > 0) && 
                      <SelectFromComputer
                      key={`select-from-computer-image`}
                      mediaFormSetting={imageMediaFormSetting}
                      setMediaFormSetting={setImageMediaFormSetting}
                      computer={true}
                      url={false}
                      modal={imageMediaFormSetting.modal}
                      onSaveMedia={(files) => handleSaveFiles(files, "image")}
                      onCancelMedia={() => handleRejectFiles("image")}
                    />
                    }
                    {(((pdfMediaFormSetting.modal || sourcePdfs.length > 0) && !extractFromPdf) || (!newGenerateQuestionsWithAI && extractFromPdf && sourcePdfs)) &&
                    <SelectFromComputer
                        key={`select-from-computer-pdf`}
                        mediaFormSetting={pdfMediaFormSetting}
                        setMediaFormSetting={setPdfMediaFormSetting}
                        computer={true}
                        url={false}
                        modal={pdfMediaFormSetting.modal}
                        onSaveMedia={(files) => handleSaveFiles(files, "pdf")}
                        onCancelMedia={() => handleRejectFiles("pdf")}
                      />
                    }
                  </Row>
              </>          
              {/* //  #occ
              // :
              // <Space direction="horizontal">
              //   <Radio.Group
              //     options={sourceBasedOnOptions}
              //     disabled={aiGenerateLoading || isStreaming || extractQuestions}
              //     onChange={(e) => {
              //       if(!aiGenerateMode) {
              //       setSourceText("");
              //       setSourceImages("");
              //       setSourcePdfs("");
              //       } else {
              //         let list = e.target.value === "image" ? [...sourceImages] : [...sourcePdfs]
              //         setMediaFormSetting({
              //           ...mediaFormSetting, 
              //           modal: !list.length,
              //           visible: !list.length,
              //           selectConfig: {
              //             ...mediaFormSetting.selectConfig,
              //             type: list.length ? "pictures-wall" : ""
              //           }
              //         })
              //       }
              //       setExtractQuestions(false);
              //       setSourceBasedOn(e.target.value);
              //       setShowError(false);
              //     }}
              //     value={sourceBasedOn}
              //     optionType="button"
              //     buttonStyle="solid"
              //   />
              //   {sourceBasedOn !== "" && <Button disabled={aiGenerateLoading || isStreaming || uploadedPdf} icon={<CloseCircleOutlined />} type="text" onClick={() => {
              //     setShowError(false);
              //     setSourceBasedOn("");
              //     setExtractQuestions(false);
              //   }} size="small" />}
              // </Space>
            // } */}
            {/* {sourceBasedOn === "text" && !aiGenerateMode && (
              <>
                <CKETextContent
                  ckeConfig={{
                    ...CKETextGenerateAISourceTools,
                    isReadOnly: aiGenerateLoading,
                  }}
                  writingAssistantConfig={{
                    enabled: true,
                    loading: aiGenerateLoading,
                  }}
                  value={sourceText}
                  placeholder={"Add source text"}
                  onChange={(text) => {
                    setShowError(false);
                    setSourceText(text);
                  }}
                />
                {showError && sourceText === "" && (
                  <Text type="danger">{mrIntl("SelectParametersForGenerate.please_add_source_text")}</Text>
                )}
              </>
            )} */}
            {/* {(sourceBasedOn === "image" ||
              sourceBasedOn === "pdf") && !aiGenerateMode &&  (
                <div>
                  <SelectFromComputer
                    key={`select-from-computer-${sourceBasedOn}`}
                    mediaFormSetting={mediaFormSetting}
                    setMediaFormSetting={setMediaFormSetting}
                    computer={true}
                    url={false}
                    modal={mediaFormSetting.modal}
                    onSaveMedia={handleSaveFiles}
                  />

                  {showError &&
                    (sourceBasedOn === "image"
                      ? sourceImages === ""
                      : sourcePdfs === "") && (
                      <Text type="danger">{mrIntl("SelectParametersForGenerate.please_select_file")}</Text>
                    )}
                </div>
              )} */}
          </Space>
        )}

        {sourcePdfs.length > 0 && (
          <Space direction="vertical" className="m-b-10">
            <ImagePreviewModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              convertedImages={convertedImages}
              setConvertedImages={setConvertedImages}
              aiGenerateLoading={aiGenerateLoading}
              showError={showError}
              setShowError={setShowError}
            />
          </Space>
        )}

        {/* {promptConfig.showExtractQuestionsSwitch && (
          <Space direction="vertical" className="m-b-10">
            <span>Extract questions</span>
            <Switch
              checked={extractQuestions}
              onChange={(value) => setExtractQuestions(value)}
              disabled={aiGenerateLoading || isStreaming}
            />
          </Space>
        )} */}
        {/* TODO: hide all other params if extractQuestions true */}

        {promptConfig.showQuestionType && (
          <Space direction="vertical" className="m-b-10">
            {!newGenerateQuestionsWithAI && <span>{mrIntl("SelectParametersForGenerate.select_question_type")}</span>}
            <Select
              onChange={(value) => {
                // setGeneratedQuestions([]);
                dispatch(aiSegmentActions.fetchSuccess({data: {segments: []}}));
                dispatch(aiSegmentActions.setAiGenerateQuestionsConfigSuccess({data: {
                  ...aiGenerateQuestionsConfig,
                  totalAIQuestionsGenerated: 0
                }}))
                setQuestionType(value);
              }}
              key={"select-question-type"}
              className="select-question-type select-filter select-parameter-field"
              value={questionType}
              placeholder={mrIntl("SelectParametersForGenerate.select_question_type")}
              options={questionTypes}
              disabled={aiGenerateLoading || isStreaming}
              dropdownMatchSelectWidth={false}
            />
          </Space>
        )}
        {promptConfig.showConceptsFilter && (
          <Space direction="vertical" className="m-b-10">
            {!newGenerateQuestionsWithAI && <span>{mrIntl("GenerateAIWithPrompt.key_concepts")}</span>}
            <SelectKeyConcepts
              dropdownMatchSelectWidth={false}
              key="select-key-concepts"
              value={keyConceptsIds}
              disabled={aiGenerateLoading || isStreaming}
              onChange={(value) => {
                setKeyConceptsIds(value);
              }}
              className="select-key-concepts select-filter select-parameter-field"
            />
            {!newGenerateQuestionsWithAI && <span>{mrIntl("GenerateAIWithPrompt.related_concepts")}</span>}
            <SelectRelatedConcepts
              dropdownMatchSelectWidth={false}
              key="select-related-concepts"
              value={relatedConceptsIds}
              disabled={aiGenerateLoading || isStreaming}
              onChange={(value) => {
                setRelatedConceptsIds(value);
              }}
              config={{
                by_programme_subject_id: programme_subject_id, by_type_c: "related_concept"
              }}
              className="select-related-concepts select-filter select-parameter-field"
            />
            {!newGenerateQuestionsWithAI && <span>{mrIntl("GenerateAIWithPrompt.global_context")}</span>}
            <SelectGlobalContext
              dropdownMatchSelectWidth={false}
              key="select-global-context"
              value={globalContextId}
              disabled={aiGenerateLoading || isStreaming}
              onChange={(value) => {
                setGlobalContextId(value);
                if(!value) {
                  setPossibleExplorationId(undefined);
                }
              }}
              className="select-global-context select-filter select-parameter-field"
            />
            {globalContextId && <>
              {!newGenerateQuestionsWithAI && <span>{mrIntl("GenerateAIWithPrompt.possible_explorations")}</span>}
              <SelectPossibleExplorations
                dropdownMatchSelectWidth={false}
                key="select-possible-explorations"
                value={possibleExplorationId}
                disabled={aiGenerateLoading || isStreaming}
                onChange={(value) => {
                  setPossibleExplorationId(value);
                }}
                config={{
                  params: {
                    by_type_c: "possible_exploration",
                    by_parent_id: globalContextId,
                  }
                }}
                className="select-possible-explorations select-filter select-parameter-field"
              />
            </>}
            {!newGenerateQuestionsWithAI ? <span>{mrIntl("GenerateAIWithPrompt.atl_skills")}</span> : null}
            <SelectATLSkills
              dropdownMatchSelectWidth={false}
              key="select-atl-skills"
              value={atlSkillsIds}
              disabled={aiGenerateLoading || isStreaming}
              onChange={(value) => {
                setAtlSkillsIds(value);
              }}
              className="select-atl-skills select-filter select-parameter-field"
            />
          </Space>
        )}

        {promptConfig.showCriteria && (
          <Space direction="vertical" className="m-b-10">
            <SelectCriteria
              // disabled={disableOptions}
              criteria={criteria}
              setCriteria={setCriteria}
              showError={showError}
              setShowError={setShowError}
              segment={createCondition === "similar" && segment}
              disabled={aiGenerateLoading || isStreaming}
            />
            {criteria && criteria.length > 0 && (
              <SelectStrandsForm
                segment={segment}
                strands={selectStrands}
                setSelectStrands={setSelectStrands}
                criteria={criteria}
                disabled={aiGenerateLoading || isStreaming}
                extraClassName="select-strands select-filter select-parameter-field"
              />
            )}
          </Space>
        )}
        {promptConfig.showSyllabusTopicsDropdown && (
          <Space direction="vertical" className="m-b-10">
            {!newGenerateQuestionsWithAI ? <span>{mrIntl("SelectParametersForGenerate.syllabus_topic")}</span> : null}
            <SelectTreeSyllabusContentsSingle
              // disabled={disableOptions}
              value={syllabusTopicId}
              treeDefaultExpandAll={atlasDemoDataEnabled}
              virtual={atlasDemoDataEnabled ? false : true}
              onChange={(selectedId) => {
                console.log(
                  "SelectTreeSyllabusContentsSingle==>",
                  selectedId
                );
                setSyllabusTopicId(selectedId);
                const label = findSyllabusTopicKey(
                  allSyllabusTopics,
                  selectedId, // syllabusTopicId,
                  "label"
                );
                setSyllabusTopicLabel(label);
              }}
              config={{
                forceReload: true,
                // params: {
                //   by_programme_id: programme_id,
                //   by_programme_subject_id: programme_subject_id,
                // },
                widgetConfig: {
                  options:
                    activeExperience?.tagged_standards_content_areas_data ||
                    {},
                  enableStandardsContentAreaTagMultiple: enableStandardsContentAreaTagMultiple
                },
              }}
              disabled={aiGenerateLoading || isStreaming}
              className="select-syllabus-topics select-filter select-parameter-field"
            />
          </Space>
        )}
        {promptConfig.showCommandTermDropdown && (
          <Space direction="vertical" className="m-b-10">
            {!newGenerateQuestionsWithAI ? <span>{mrIntl("SelectParametersForGenerate.command_term")}</span> : null}
            <SelectCommandTermLabel
              // disabled={disableOptions}
              value={commandTerm}
              onChange={(value) => {
                console.log("SetCommandTerm===>", value);
                setCommandTerm(value);
              }}
              config={{
                params: { by_programme_id: programme_id },
              }}
              disabled={aiGenerateLoading || isStreaming}
              className= "select-command-term select-filter select-parameter-field"
            />
          </Space>
        )}
        {promptConfig.showDPFilters && (
          <>
            {/* <Space direction="vertical" className="m-b-10"> */}
            {/* <span>Difficulty</span> */}
            {/* <GenericFilter
                showClearButton={true}
                label={mrIntl(
                  "GenerateAIWithPrompt.select_difficulty_level"
                )}
                options={[
                  {
                    label: mrIntl(
                      "GenerateAIWithPrompt.accessible_label"
                    ),
                    value: "accessible",
                  },
                  {
                    label: mrIntl(
                      "GenerateAIWithPrompt.moderate_label"
                    ),
                    value: "moderate",
                  },
                  {
                    label: mrIntl(
                      "GenerateAIWithPrompt.hard_label"
                    ),
                    value: "hard",
                  },
                ]}
                value={difficultyLevel}
                keyToUpdate="by_tag_difficulty_levels"
                onChange={(value) => setDifficultyLevel(value)}
                config={{
                  widgetConfig: {
                    // style: { "100%" : "15vw" },
                    // allowClear: !filtersConfig.showModal,
                  },
                }}
              /> */}
            {/* <SelectDifficultyLevel
                value={difficultyLevelId}
                key="selectDifficultyLevel"
                onChange={(value) => {
                  setDifficultyLevelId(value);
                }}
                config={{
                  params: { by_programme_id: programme_id },
                  widgetConfig: {
                    style: { width: "200px" },
                    placeholder: mrIntl("CommonText.all_levels"),
                  },
                }}
                disabled={aiGenerateLoading || isStreaming}
              /> */}
            {/* </Space> */}
            <Space direction="vertical" className="m-b-10">
              {!newGenerateQuestionsWithAI ? <span>{mrIntl("SelectParametersForGenerate.paper_type")}</span> : null}
              {/* <GenericFilter
                showClearButton={true}
                label={mrIntl(
                  "GenerateAIWithPrompt.select_paper_type"
                )}
                options={[
                  {
                    label: mrIntl(
                      "GenerateAIWithPrompt.paper_1_label"
                    ),
                    value: "Paper 1",
                  },
                  {
                    label: mrIntl(
                      "GenerateAIWithPrompt.paper_2_label"
                    ),
                    value: "Paper 2",
                  },
                  {
                    label: mrIntl(
                      "GenerateAIWithPrompt.paper_3_label"
                    ),
                    value: "Paper 3",
                  },
                ]}
                value={paperType}
                disabled={disableOptions}
                keyToUpdate="by_paper_difficulty_levels"
                onChange={(value) => setPaperType(value)}
                config={{
                  widgetConfig: {
                    // style: { "100%" : "15vw" },
                    // allowClear: !filtersConfig.showModal,
                  },
                }}
              /> */}
              <SelectPaperType
                key="select-paper-type-filter"
                // disabled={disableOptions}
                value={paperTypeId}
                onChange={(value) => {
                  setPaperTypeId(value);
                }}
                config={{
                  widgetConfig: {
                    style: { width: "200px" },
                    // mode: "multiple",
                    placeholder: mrIntl("CommonText.all_papers"),
                  },
                }}
                disabled={aiGenerateLoading || isStreaming}
                className="select-paper-type select-filter select-parameter-field"
              />
            </Space>
            <Space direction="vertical" className="m-b-10">
              {!newGenerateQuestionsWithAI ? <span>{mrIntl("SelectParametersForGenerate.teaching_level")}</span> : null}
              {/* <GenericFilter
                showClearButton={true}
                label={mrIntl(
                  "GenerateAIWithPrompt.select_teaching_type"
                )}
                options={[
                  {
                    label: mrIntl(
                      "GenerateAIWithPrompt.sl_only_label"
                    ),
                    value: "SL only",
                  },
                  {
                    label: mrIntl(
                      "GenerateAIWithPrompt.sl_and_hl_label"
                    ),
                    value: "SL & HL",
                  },
                  {
                    label: mrIntl("GenerateAIWithPrompt.hl_label"),
                    value: "HL only",
                  },
                ]}
                value={teachingLevel}
                disabled={disableOptions}
                keyToUpdate="by_paper_difficulty_levels"
                onChange={setTeachingLevel}
                config={{
                  widgetConfig: {
                    // style: { "100%" : "15vw" },
                    // allowClear: !filtersConfig.showModal,
                  },
                }}
              /> */}
              <SelectTeachingLevel
                // disabled={disableOptions}
                key="select-teaching-level-filter"
                value={teachingLevelId}
                onChange={(value) => {
                  setTeachingLevelId(value);
                }}
                config={{
                  widgetConfig: {
                    hideSl: true,
                    style: { width: "200px" },
                    // mode: "multiple",
                    placeholder: mrIntl("CommonText.all_levels"),
                  },
                }}
                disabled={aiGenerateLoading || isStreaming}
                className="select-teaching-level select-filter select-parameter-field"
              />
            </Space>
          </>
        )}
        {promptConfig.showPointsInput && (
          <Space direction="vertical" className="m-b-10">
            {!newGenerateQuestionsWithAI ? <span>{mrIntl("SelectParametersForGenerate.points")}</span> : null}
            <InputNumber
              min={1}
              max={50}
              addonBefore={"Enter points"}
              className="points-input select-parameter-field"
              value={marks}
              onChange={(value) => {
                if (
                  questionType === "nested_question" &&
                  value >= 3 &&
                  showError
                ) {
                  setShowError(false);
                }
                if (typeof value === "number") {
                  setMarks(parseInt(value));
                } else {
                  setMarks(1);
                }
              }}
              disabled={aiGenerateLoading || isStreaming}
            />
            {showError &&
              questionType === "nested_question" &&
              marks < 3 && (
                <Text type="danger">
                  {mrIntl("GenerateAIWithPrompt.marks_should_be_3_or_more_for_nested_questions")}
                </Text>
              )}
          </Space>
        )}
        {(questionType === "task" || atlasDemoDataEnabled) && <Space direction="vertical" className="m-b-10">
          {!newGenerateQuestionsWithAI && <span>{mrIntl("GenerateAIWithPrompt.number_of_questions")}</span>}
          <InputNumber
            required
            min={1}
            max={10}
            controls={false}
            addonBefore={mrIntl("GenerateAIWithPrompt.number_of_questions")}
            value={questionsCount}
            disabled={aiGenerateLoading || isStreaming}
            onChange={value => {
              if(typeof value === "number") {
                setQuestionsCount(parseInt(value));
              } else {
                setQuestionsCount(3)
              }
            }}
            className='number-of-questions select-parameter-field'
            // style={{ width: "250px" }}
          />
        </Space>}
        {/* <br /> */}
        {promptConfig.showAdditionalInstructions && (
          <Space direction="vertical" className="m-b-10">
            {!newGenerateQuestionsWithAI ? <span>{mrIntl("GenerateAIWithPrompt.additional_instructions")}</span> : null}
          <CKETextContent
            ckeConfig={{
              ...CKETextGenerateAISourceTools,
              isReadOnly: aiGenerateLoading,
            }} 
            value={additionalInstructions}
            onChange={(text) =>
              setAdditionalInstructions(text)
            }
            className="additional-instructions-textarea select-parameter-field"
            placeholder={mrIntl(
              "GenerateAIWithPrompt.enter_additional_instructions"
            )}

            disabled={aiGenerateLoading || isStreaming}
          />
          </Space>
        )}

        {promptConfig.showCustomPromptCheckbox && (
          <Space
            direction="vertical"
            className="m-b-10 custom-prompt-space"
          >
            <Checkbox
              disabled={aiGenerateLoading || isStreaming}
              checked={useCustomPrompt}
              onChange={(e) => {
                setUseExamples(false);
                setUseCustomPrompt(e.target.checked);
              }}
            >
              <span>{mrIntl("GenerateAIWithPrompt.use_custom_prompt")}</span>
            </Checkbox>
            {useCustomPrompt && (
              <>
                <CKETextContent
                  ckeConfig={{
                    ...CKETextGenerateAISourceTools,
                    isReadOnly: aiGenerateLoading,
                  }}
                  writingAssistantConfig={{
                    enabled: true,
                    loading: aiGenerateLoading,
                  }}
                  value={customPrompt}
                  placeholder={"Add custom prompt"}
                  onChange={(value) => {
                    setShowError(false);
                    setCustomPrompt(value);
                  }}
                />
              </>
            )}
          </Space>
        )}

        {promptConfig.showUseExamplesCheckbox && false && (
          <Space direction="vertical" className="m-b-10">
            <Checkbox
              disabled={aiGenerateLoading || isStreaming}
              checked={useCustomExamples}
              onChange={(e) => {
                setShowError(false);
                setUseCustomExamples(e.target.checked)
              }
              }
            >
              <span>{mrIntl("GenerateAIWithPrompt.use_custom_examples")}</span>
            </Checkbox>
            {useCustomExamples && (
              <>
                <CKETextContent
                  ckeConfig={{
                    ...CKETextGenerateAISourceTools,
                    isReadOnly: aiGenerateLoading,
                  }}
                  writingAssistantConfig={{
                    enabled: true,
                    loading: aiGenerateLoading,
                  }}
                  value={customExamples}
                  placeholder={"Add custom examples"}
                  onChange={(value) => {
                    setShowError(false);
                    setCustomExamples(value);
                  }}
                />
              </>
            )}
          </Space>
        )}

        {promptConfig.showUseExamplesCheckbox && (
          <Space direction="vertical" className="m-b-10">
            <Checkbox
              disabled={aiGenerateLoading || isStreaming}
              checked={useExamples}
              onChange={(e) => {
                setShowError(false);
                setUseExamples(e.target.checked);
              }}
            >
              <span>{mrIntl("SelectParametersForGenerate.use_examples_from_banks")}</span>
            </Checkbox>
            {useExamples && (
              <>
                <br />
                <SelectQuestionbankUUID
                  disabled={aiGenerateLoading || isStreaming}
                  dropdownMatchSelectWidth={false}
                  key="select-questionbank-uuid"
                  value={qbUUID}
                  onChange={(value) => {
                    console.log("value==>", value);
                    setQbUUID(value);
                    setShowError(false);
                  }}
                  config={{
                    params: {
                      // without_qb_codes:
                      //   currentUser.role !== "qb_author"
                      //     ? ["osc_dp_mocks"]
                      //     : [],
                      by_programme_id:
                        activeExperience.questionbank.programme_id,
                    },
                    widgetConfig: {
                      style: { width: "280px" },
                    },
                  }}
                />
                {/* <br /> */}
                {showError && useExamples && !qbUUID && (
                  <Text type="danger">
                    {mrIntl("SelectParametersForGenerate.please_select_questionbank")}
                  </Text>
                )}
              </>
            )}
          </Space>
        )}
      </Space>
    )}
    {/* <br /> */}

    {promptConfig.showPromptButton &&
      generatedQuestionsLength > 0 && (
        <Space>
          <Button onClick={() => setShowPromptUsed(!showPromptUsed)}>
            {showPromptUsed ? mrIntl("SelectParametersForGenerate.jsx.hide_prompt") : mrIntl("SelectParametersForGenerate.jsx.see_prompt")}
          </Button>
        </Space>
      )
    }
    {promptConfig.showTokenUsageCost &&
      generatedQuestionsLength > 0 && (
      <Space>
        <Tag>Tokens: {tokensUsed}</Tag>
        <Tag>Cost: ${requestCost}</Tag>
      </Space>
      )}
  </Space>
}

export default SelectParametersForGenerate;