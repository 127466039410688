import React, { useEffect, useState } from "react";
import { Radio, Row, Col } from "antd";
import { getUniqueId } from '/src/lib/utils/helperMethods';
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { getItemsOfSize, addItemToArray, removeItemFromArray, getParsedJSONObject } from "/src/views/Segments/InteractiveHelpers";
import { AddButton, DeleteButton, InputArea, Segment, SegmentData, MCQList, ShowTextContainer, TextArea, ShowText, ShowTextIndex, ShowTextContent, ShowTextFile, CustomErrorMessage, AddFeedbackButton } from '/src/components/UI/Segment/UIHelper'
import { cloneDeep, shuffle } from 'lodash';
import SegmentItemFooter from '/src/views/Segments/SegmentItemDetail/SegmentItemFooter'
import SegmentItemHeader from '/src/views/Segments/SegmentItemDetail/SegmentItemHeader'
import MediaHoverButton from "/src/components/UI/Media/MediaHoverButton"
import MediaShow from "/src/components/UI/Media/MediaShow"
import OptionFeedback from "/src/views/Segments/Explanation/OptionFeedback";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { CKETextOptionTools } from "/src/components/UI/CKEditor/CKEConfig";

const TrueFalse = props => {
  console.log("True False props", props);
  const { question_type, showCorrectAnswerSwitch, segmentSettings, triggerStateUpdate, setRenderMath, segmentFooterAttributes } = props
  let { parsedJSONObject, createMode, answerMode, presentationMode, autoCheckMode } = getParsedJSONObject(props)

  let textAnswerString = props.text_answer || ''
  const propsAttemptStatus = props.attempt_status || {}
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  }
  const mrIntl = useTranslate();
  const extra = {
    contentRequired: false,
    contentPrefix: mrIntl("TrueFalse.statement"),
    placeholderPrefix: mrIntl("TrueFalse.statement"),
    // placeholderPrefix: 'Statement'
    maxSize: 20,
  }
  if (createMode) {
    parsedJSONObject = {
      segment_data: {
        statements: getItemsOfSize(0, 2, extra)
      },
      segment_version: '1.0.1'
    }
    if (question_type == 'true_false') {
      parsedJSONObject = props.value || parsedJSONObject
    }
  }

  if (answerMode && !props.response_json) {
    parsedJSONObject = shuffleItems(parsedJSONObject)
  }

  if (autoCheckMode) {
    let question_json = cloneDeep(props.teacher_json)
    let answer_json = cloneDeep(parsedJSONObject)
    parsedJSONObject = checkResponses(question_json, answer_json)
  }

  const [state, setState] = useState(parsedJSONObject)
  const [textAnswer, setTextAnswer] = useState(textAnswerString)
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject)
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
  const [gradeSetting, setGradeSetting] = useState(props.grade_json || {})
  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
    showCorrectAnswerSwitchValue: false,
  })
  const screenSettings = props.screenSettings || {}
  const [myAnswer, setMyAnswer] = useState(state)

  let currentJSON, text_answer, attempt_status
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject
    text_answer = textAnswerString
    attempt_status = attemptStatusObject
  } else {
    currentJSON = state
    text_answer = textAnswer
    attempt_status = attemptStatus
  }

  const segment_data = currentJSON?.segment_data || {}
  const statements = segment_data.statements || []

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }, [])

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state)
        props.onChange(teacher_json)
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          // text_answer: cloneDeep(textAnswer),
          attempt_status: cloneDeep(attemptStatus)
        }

        props.onChange(result)
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    setState(state)
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
    console.log("stateUpdated count ==>", stateUpdatedCount)
  }

  const saveTextAnswer = (data) => {
    setTextAnswer(data)
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1)
  }

  function checkResponses(question_json, answer_json) {
    console.log('Question and Answer JSON ==>', question_json, answer_json)
    if (!question_json || !answer_json) {
      return
    }
    let q_segment_data = question_json.segment_data
    let a_segment_data = answer_json.segment_data

    let q_statements = q_segment_data.statements || []
    let a_statements = a_segment_data.statements || []

    let correct_status = { all_correct: true }
    if (a_statements) {
      let i = 0;
      while (i < a_statements.length) {
        let q_statement = q_statements[i]
        let a_statement = a_statements.find((statement) => statement.id == q_statements[i].id)
        
        if (q_statement.statementIs == a_statement.statementIs) {
          a_statement.correct = true
          correct_status.partial_correct = true
        } else {
          a_statement.correct = false
          correct_status.all_correct = false
        }
        i++
      }
    }

    if (!autoCheckMode && props.onAutoCheck) {
      props.onAutoCheck(correct_status)
    }

    return answer_json
  }

  function shuffleItems(json) {
    if (segmentSettings.shuffle) {
      const segment_data = json.segment_data
      segment_data.statements = shuffle(segment_data.statements)
    }

    return json
  }

  const addItem = () => {
    const newStatements = addItemToArray(statements, extra);

    const newSegmentData = {
      ...segment_data,
      statements: newStatements
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const removeItem = (index) => {
    const newStatements = removeItemFromArray(statements, index);

    const newSegmentData = {
      ...segment_data,
      statements: newStatements
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const removeFile = (index) => {
    const newStatements = statements
    delete newStatements[index].files

    const newSegmentData = {
      ...segment_data,
      statements: newStatements
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const setStatement = (index, value) => {
    let isAtleastOneSelected = false
    console.log("set statement index value ==>", index, value)
    const newStatements = statements.map((item, i) => {
      if (i == index) {
        item.statementIs = value
      }
      if (item.statementIs != undefined) {
        isAtleastOneSelected = true
      }
      return item
    })

    const newSegmentData = {
      ...segment_data,
      statements: newStatements
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    if (answerMode && isAtleastOneSelected != attemptStatus.json_attempted) {
      setAttemptStatus({
        ...attemptStatus,
        json_attempted: isAtleastOneSelected
      })
    }

    saveState(newState)
  }

  const onTextChanged = (index, data, key = 'content') => {
    const newStatements = statements
    newStatements[index][key] = data

    const newSegmentData = {
      ...segment_data,
      statements: newStatements
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const onSaveMedia = (index, files) => {
    const newStatements = statements
    newStatements[index].files = files

    const newSegmentData = {
      ...segment_data,
      statements: newStatements
    }

    const newState = {
      ...state,
      segment_data: newSegmentData
    }

    saveState(newState)
  }

  const checkAnswer = (check = false) => {
    if (check) {
      let question_json = cloneDeep(props.teacher_json);
      let answer_json = cloneDeep(state);
      const newState = checkResponses(question_json, answer_json);

      setMyAnswer(state);
      saveState(newState);
    }

    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: check,
    });

    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  };

  const showCorrectAnswer = (value) => {
    console.log("Value in switch ==>", value)
    let question_json, answer_json
    if (value) {
      question_json = cloneDeep(props.teacher_json)
      answer_json = cloneDeep(props.teacher_json)
    } else {
      question_json = cloneDeep(props.teacher_json)
      answer_json = cloneDeep(myAnswer)
    }

    const newState = checkResponses(question_json, answer_json)
    saveState(newState)
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: true,
      showCorrectAnswerSwitchValue: value
    })
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }

  const resetQuestion = () => {
    let student_json = cloneDeep(props.student_json)
    const newState = shuffleItems(student_json)

    setAttemptStatus({
      ...attemptStatus,
      reset_count: (attemptStatus.reset_count + 1),
      json_attempted: false,
    })
    saveState(newState)
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: false,
    })
    if (setRenderMath) {
      setRenderMath(getUniqueId())
    }
  }

  const getStatement = (item, index) => {
    let statement
    if (createMode) {
      const valid = item.content ? true : false
      statement = <TextArea
        key={`text-area-${item.id}`}
      >
        <InputArea className={`singleline-input-area ${!valid && 'custom-error'}`}>
          <BasicCKE
            data={item.content}
            tools={CKETextOptionTools} 
            placeholder={item.placeholder}
            onTextChanged={(data) => onTextChanged(index, data, 'content')}
          ></BasicCKE>
          {item.files && <MediaShow
            file={item.files && item.files[0]}
            removeFile={(e) => removeFile(index)}
          >
          </MediaShow>}
          <CustomErrorMessage className="error-message" message={mrIntl("CommonText.required_msg")}></CustomErrorMessage>
        </InputArea>
        <MediaHoverButton
          onSaveMedia={(files) => onSaveMedia(index, files)}
        ></MediaHoverButton>
        <DeleteButton
          clicked={(e) => removeItem(index)}
          disabled={statements.length == 1}
          tooltipTitle={statements.length == 1 ? mrIntl("CommonText.minimum_limit_reached") : ""}
        >
        </DeleteButton>
        <Radio.Group onChange={(e) => setStatement(index, e.target.value)} value={item.statementIs} className='statement-radio-group'>
          <Radio value={true}>True</Radio>
          <Radio value={false}>False</Radio>
        </Radio.Group>
      </TextArea>
    } else {
      let answerStatusClass = ''
      if (item.statementIs != undefined) {
        // answerStatusClass += 'attempted-answer'
        if (autoCheckMode || stateSetting.quickCheckModeEnabled) {
          if (item.correct) {
            answerStatusClass += 'correct-answer'
          } else {
            answerStatusClass += 'incorrect-answer'
          }
        }
      }

      statement = <Row key={`mcq-option-${item.id}`}>
        <Col span={16}>
          <ShowTextContainer
            key={`show-text-container-${item.id}`}
          // onClick={(e) => (answerMode && !stateSetting.quickCheckModeEnabled) && selectCurrentItem(index)}
          >
            <ShowText >
              {item.content && <ShowTextContent dangerouslySetInnerHTML={{ __html: item.content }}
              >
              </ShowTextContent>}
              {item.files && <ShowTextFile>
                <MediaShow
                  file={item.files && item.files[0]}
                  removeIcon={false}
                >
                </MediaShow>
              </ShowTextFile>}
            </ShowText>
          </ShowTextContainer>
        </Col>
        <Col span={8}>
          <Radio.Group
            onChange={(e) => setStatement(index, e.target.value)} value={item.statementIs}
            disabled={!answerMode || stateSetting.quickCheckModeEnabled}
            className='statement-radio-group'
          >
            <Radio
              style={{
                padding: '5px',
              }}
              value={true} className={item.statementIs && answerStatusClass}
            >True</Radio>
            <Radio
              style={{
                padding: '5px',
              }}
              value={false} className={!item.statementIs && answerStatusClass}
            >False</Radio>
          </Radio.Group>
        </Col>
      </Row>
    }

    return statement
  }

  console.log('True False State before render ==>', state, stateSetting)
  return (
    <Segment className="segment-true-false">
      {/* <SegmentItemHeader
				segmentStateSettings={stateSetting}
				showCorrectAnswer={(value) => showCorrectAnswer(value)}
			>
			</SegmentItemHeader> */}
      <SegmentData>
        <MCQList
          style={screenSettings.isMobile ? {
            'width': '100%'
          } : {
              'width': '100%'
            }}
        >
          {statements.map((item, index) => (
            getStatement(item, index)
          ))}
          {createMode && <AddButton
            clicked={(e) => addItem(0)}
            disabled={statements.length >= 20}
            title={statements.length >= 20 ? mrIntl("CommonText.reached_maximum_allowed_limit") : mrIntl("CommonText.add")}
          />}
        </MCQList>
      </SegmentData>

      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        saveTextAnswer={saveTextAnswer}
        resetQuestion={resetQuestion}
        checkAnswer={checkAnswer}
        showCorrectAnswer={showCorrectAnswer}
      >
      </SegmentItemFooter>
    </Segment >
  );
}
export default TrueFalse;