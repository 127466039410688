import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./EllipsisContent.scss";
import { useSelector } from "react-redux";
import { printModeSelector } from "/src/views/Experiences/selector";
const EllipsisContent = (props) => {
  console.log("EllipsisContent =====>", props);
  const { children, heightForEllipsis = "60px" } = props
  const [questionContentEllipsis, setQuestionContentEllipsis] = useState(true);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const gradeQuestionContentRef = useRef();
  const printMode = useSelector(printModeSelector())

  useEffect(() => {
    if (gradeQuestionContentRef.current && !printMode) {
      const { clientHeight, scrollHeight } = gradeQuestionContentRef.current;
      console.log("isEllipsisActive =====>", isEllipsisActive, clientHeight, scrollHeight, gradeQuestionContentRef.current);

      if (scrollHeight >= 120) {
        setIsEllipsisActive(true);
        gradeQuestionContentRef.current.style.height = heightForEllipsis
      } else {
        setIsEllipsisActive(false);
      }
    }
  }, [gradeQuestionContentRef.current]);

  return (
    <>
      <span className={`${(questionContentEllipsis) && "ellipsis-content"}`} ref={gradeQuestionContentRef}>
        {children}
      </span>
      {isEllipsisActive &&
        <Button
          onClick={() => {
            setQuestionContentEllipsis(!questionContentEllipsis);
          }}
          className="show-more-button">
          {questionContentEllipsis ? "Show more" : "Show less"}
        </Button>
      }
      {isEllipsisActive && <hr />}
    </>
  )
}

export default EllipsisContent;