

import React, { useEffect } from 'react';
import { Result, Alert, Space, Button } from 'antd';
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import StartInSebBtn from '/src/components/UI/SEB/StartInSebBtn';
import DownloadAppsModal from '/src/views/OfflineApps/DownloadAppsModal';
import { useDispatch, useSelector } from 'react-redux';
import BackToHomeBtn from '/src/components/UI/BackToHomeBtn';
import { firestoreConnectionClosedSelector } from '../FirestoreInteractions/selector';
import ViewCard1 from '../ExperienceTake/ViewComponents/ViewCard1';
import ViewStatus from '../ExperienceTake/ViewComponents/ViewStatus';
import { WarningFilled, CheckCircleFilled } from "@ant-design/icons";
import "./OfflineExperienceAndNotAllowedOnlineWarning.scss";

const OfflineExperienceAndNotAllowedOnlineWarning = (props) => {
  // This file is used only in the new join view case.

  const mrIntl = useTranslate();
  const { experience, setUserInfo, viewToShow } = props;
  let subTitle = []
  let extra = []

  const showConfig = {
    showProgress: false,
    showInstructions: false,
    showRightAssessmentDetailsCard: false,
    titleColorClassName: 'bg-orange'
  }
  
  const firestoreConnectionClosed = useSelector(firestoreConnectionClosedSelector())

  if(viewToShow === "submittedTest") {
    subTitle.push(
      <Alert 
        message= {"Test Submitted"}
        type="success"
        showIcon
      />
    )
    extra.push(
      <Space>
        <BackToHomeBtn
          key="offline-page-back-to-home-button" 
          experience={experience}
        />
      </Space>
    )
  } else {
    subTitle.push(
      <Alert 
        // message="This assessment can only be taken in the lockdown application" 
        message= {mrIntl("ExperienceTake.assessment_for_lockdown_app")}
        type="error"
        showIcon
      />
    )
    extra.push(
      <StartInSebBtn className="offline-experience-start-button" item={experience} disabled={firestoreConnectionClosed} tooltip={firestoreConnectionClosed ? mrIntl("StartInSebBtn.to_try_again_refresh_the_page") : null} />
    );
    if (firestoreConnectionClosed) {
      extra.push(<Button onClick={() => { window.location.reload() }}>Refresh</Button>)
    } else {
      extra.push(
        <Space>
          <DownloadAppsModal />
        </Space>
      );
    }
  }

  return <React.Fragment>
    <div className='offline-experience-not-allowed-online-warning'>
      <ViewStatus
        statusIcon={<WarningFilled />}
        statusText={subTitle}
        statusTextType="warning"
        showConfig={showConfig}
      />

      <ViewCard1
        experience={experience}
        footerCenterComponent={extra}
        showConfig={showConfig}
      />
    </div>
  </React.Fragment>;
}

export default OfflineExperienceAndNotAllowedOnlineWarning;
