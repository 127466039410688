import React from "react";
import {MrSelect} from "mr_react_framework";
import {useSelector} from "react-redux";
import { buildOptionsArr, userRoleMap } from "/src/lib/utils/helperMethods";
import {actions as genericActions} from "/src/App/genericRedux";
import MrTranslate from "/src/lib/MrTranslate/MrTranslate";

const SelectQuestionbank = MrSelect({
  actions: genericActions,
  resourceName: "questionbanks",
  config: {
    url: "/questionbanks",
    // params: { key: "value" },
    processData: (data, props) => {
      return data.map((item) => {
        return { label: item.title, value: item.id };
      });
    },
    widgetConfig: {
      showSearch: false, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      // placeholder: "Select questionbanks",
      placeholder: <MrTranslate id={"SelectQuestionbankDropdown.select_questionbanks"} />,
      mode: "multiple",
      // style: { width: "300px" },
    },
  },
});

const SelectQuestionbankDropdown = (props) => {
  console.log( "SelectQuestionbankDropdown props", props );

  const orgRoles = useSelector(state => {
    console.log("state user filter",state);
    return state.login.get("org").roles
  } )

  return <SelectQuestionbank {...props}/>
}


SelectQuestionbankDropdown.defaultProps = {};

SelectQuestionbankDropdown.propTypes = {};

export default SelectQuestionbankDropdown;


                