import React, { useState } from 'react';
import { Space, Card, Switch, Select, Row, Col, Modal, Tooltip, Collapse } from 'antd';
import { MdOutlineSpellcheck } from 'react-icons/md';
import { FaRegClock } from 'react-icons/fa';
import { CalculatorOutlined, SoundOutlined, AudioOutlined, StopOutlined } from '@ant-design/icons';
import { RiFontSize } from 'react-icons/ri';
import { CgFontSpacing } from 'react-icons/cg';
import { SolarCloudsBroken } from "/src/components/UI/Icons/SolarIcons";
import "./AccommodationModal.scss";
import AddTimeBtn from '../../Experiences/ExperienceShow/Components/AddTimeBtn';
import { convertToHoursAndMinutes, extraTimeValues, } from '/src/lib/utils/helperMethods';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { enabledFeaturesSelector } from '../../Auth/Login/selector';
import { useSelector } from 'react-redux';

const AccommodationItem = ({ key, label, action }) => (
  <Row key={key} className="accommodation-content" align="middle" justify="end">
    <Col className='accommodation-content-inner'>{label}</Col>
    <Col className='accommodation-content-inner'>{action}</Col>
  </Row>
);
const AccommodationModal = (props) => {
  console.log('AccommodationModal===>>>>>>', props);
  const { name, shouldDisabled = false, tooltipTitle = "", onChange, isAccommodationModalOpen, closeModal, hideAddTime, monitorView = false, addTimeBtnProps, onOK, countDownTimer, accommodations } = props;
  const enabledFeatures = useSelector(enabledFeaturesSelector());

  const mrIntl = useTranslate();
  const defaultAccommodations = {
    spellcheck: false,
    additional_time: '0',
    additional_time_type: 'percentage',
    calc_enabled: false,
    graphing_calc_enabled: false,
    tts_allowed: false,
    stt_allowed: false,
    // dyslexia_font: false,
    // font_size: 'Default',
    // font_spacing: 'Default',
    legacy_ap_mac_9_0_2_allowed: false,
  };

  const [localAccommodations, setLocalAccommodations] = useState(accommodations);

  const originalDuration = addTimeBtnProps?.experienceDuration || 0;
  const localAccommodationsExtraTime = localAccommodations?.extra_time_data?.extra_time;
  
  const getExtraTime = (extraTime) => extraTime ? parseInt(extraTime) / 60000 : 0;
  const extraTimeInMinutes =
  localAccommodationsExtraTime ? getExtraTime(localAccommodationsExtraTime) : getExtraTime(addTimeBtnProps?.item?.extra_time);

  const totalDuration = parseInt(originalDuration) + extraTimeInMinutes;
  
  if (monitorView) {
    defaultAccommodations.extra_time_data = {}
  }

  const shouldShowTotalDuration = extraTimeInMinutes > 0;

  // in case of onOK, we will use local state to update the accommodations 
  const currentAccommodations = {
    ...defaultAccommodations,
    ...(onOK ? localAccommodations : accommodations)
  };

  const handleAccommodationChange = (key, value) => {
    console.log('key===>>>>>>', key, value);
    const newAccommodations = {
      ...(onOK ? localAccommodations : accommodations),
      [key]: value,
    };

    if (key === "additional_time_type") {
      newAccommodations.additional_time = "0";
    }

    if (onOK) {
      setLocalAccommodations(newAccommodations);
    } else {
      onChange(newAccommodations);
    }
  }
  
  const onCancel = () => {
    setLocalAccommodations(accommodations);
    closeModal();
  }
  const additionalTimeValues =
    currentAccommodations.additional_time_type === "percentage"
      ? [
        { value: "0", label: '0%' },
        { value: "10", label: '10%' },
        { value: "25", label: '25%' },
        { value: "50", label: '50%' },
        { value: "75", label: '75%' },
        { value: "100", label: '100%' }
      ]
      : [
        { value: "0", label: "0" },
        ...extraTimeValues.map(minutes => ({ value: minutes, label: minutes }))
      ];

  let modalProps = {}
  if (onOK) {
    modalProps = {
      onOk: () => {
        onChange(localAccommodations);
        closeModal();
      },
      onCancel: onCancel
    }
  } else {
    modalProps = {
      footer: null
    }
  }
  const items = [
    {
      key: '1',
      label: mrIntl("AccommodationModal.legacy_app"),
      children: (
        <Card className='legacy-app-item'>
          <Row className="accommodation-content" align="middle" justify="end">
            <Col className='accommodation-content-inner'>
              <span><StopOutlined /></span> {mrIntl("AccommodationModal.ap_mac_9_0_2")}
            </Col>
            <Col>
              <Switch
                size="small"
                checked={accommodations?.legacy_ap_mac_9_0_2_allowed || false}
                onChange={(checked) => handleAccommodationChange('legacy_ap_mac_9_0_2_allowed', checked)}
                disabled={shouldDisabled}
              />
            </Col>
          </Row>
        </Card>
      ),
    },
  ];
  const showBasicAccommodation = enabledFeatures.spellcheck || !monitorView || !hideAddTime;
  return (
    <Modal
      key={"accommodation-modal"}
      title={`${mrIntl("CommonText.set_accommodation_for")} ${name}`}
      open={isAccommodationModalOpen}
      onCancel={onCancel}
      maskClosable={false}
      {...modalProps}
    >
      <div className="student-accommodations-main-content">
        <div className="student-accommodations-inner-content">
         {showBasicAccommodation && <div className="accommodation-card">
            <p>{mrIntl("AccommodationModal.basic_accommodation")}</p>
            <Card key={"basic-accommodation-card"}>
             {enabledFeatures.spellcheck && <Row className="accommodation-content" align="middle" justify="end">
                <Col className='accommodation-content-inner'>
                  <span><MdOutlineSpellcheck /></span> {mrIntl("CommonText.spellcheck")}
                </Col>
                <Col>
                  <Tooltip title={tooltipTitle}>
                    <Switch
                      size="small"
                      checked={currentAccommodations.spellcheck || false}
                      onChange={(checked) => handleAccommodationChange('spellcheck', checked)}
                      disabled={shouldDisabled}
                    />
                  </Tooltip>
                </Col>
              </Row>}
              {monitorView
                ? !hideAddTime
                  ?
                  <>
                    <AccommodationItem
                      key={'original-duration'}
                      label={<><FaRegClock /> {mrIntl("AccommodationModal.original_duration")}</>}
                      action={`${convertToHoursAndMinutes(originalDuration)}`}
                    />

                    <AccommodationItem
                      key={'extra-time'}
                      label={<><FaRegClock /> {"Total Extra Time (minutes)"}</>}
                      action={<AddTimeBtn
                        key={`modal-add-time-btn`}
                        {...addTimeBtnProps}
                        item={{
                          ...addTimeBtnProps.item,
                          ...currentAccommodations.extra_time_data,
                        }}
                        updateRecord={
                          onOK
                            ? (uid, data, item, options) => handleAccommodationChange("extra_time_data", data)
                            : addTimeBtnProps.updateRecord
                        }
                      />}
                    />

                    {shouldShowTotalDuration &&
                      <AccommodationItem
                        key={'total-duration'}
                        label={mrIntl("AccommodationModal.new_total_duration")}
                        action={`${convertToHoursAndMinutes(totalDuration)}`}
                      />
                    }
                    {countDownTimer && <AccommodationItem
                      key={'time-remaining'}
                      label={mrIntl("AccommodationModal.time_remaining")}
                      action={countDownTimer}
                    />}
                  </>
                  : null
                :
                <Row className="accommodation-content" align="middle" justify="end">
                  <Col className='accommodation-content-inner'>
                    <span><FaRegClock /></span> {mrIntl("AccommodationModal.additional_time")}
                  </Col>
                  <Col>
                    <Space>
                      <Select
                        popupMatchSelectWidth={false}
                        value={currentAccommodations.additional_time}
                        onChange={(value) => handleAccommodationChange('additional_time', value)}
                        options={additionalTimeValues}
                      />
                      <Tooltip title={tooltipTitle}>
                        <Switch
                          size="large"
                          checkedChildren="%"
                          unCheckedChildren="mins"
                          checked={currentAccommodations.additional_time_type === "percentage"}
                          onChange={(value) => handleAccommodationChange("additional_time_type", value ? "percentage" : "minutes")}
                          disabled={shouldDisabled}
                        />
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
              }
            </Card>
          </div>}
          <div className="accommodation-card">
            <p>{mrIntl("AccommodationModal.math_tools")}</p>
            <Card key={"math-tools-card"}>
              <Row className="accommodation-content" align="middle" justify="end">
                <Col className='accommodation-content-inner'>
                  <span><CalculatorOutlined /></span> {mrIntl("AccommodationModal.desmos_scientific_calculator")}
                </Col>
                <Col>
                  <Tooltip title={tooltipTitle}>
                    <Switch
                      size="small"
                      checked={currentAccommodations.calc_enabled || false}
                      onChange={(checked) => handleAccommodationChange('calc_enabled', checked)}
                      disabled={shouldDisabled}
                    />
                  </Tooltip>
                </Col>
              </Row>
              <Row className="accommodation-content" align="middle" justify="end">
                <Col className='accommodation-content-inner'>
                  <span><CalculatorOutlined /></span> {mrIntl("AccommodationModal.desmos_graphing_calculator")}
                </Col>
                <Col>
                  <Tooltip title={tooltipTitle}>
                    <Switch
                      size="small"
                      checked={currentAccommodations.graphing_calc_enabled || false}
                      onChange={(checked) => handleAccommodationChange('graphing_calc_enabled', checked)}
                      disabled={shouldDisabled}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Card>
          </div>

          <div className="accommodation-card">
            <p>{mrIntl("AccommodationModal.reading_support")}</p>
            <Card key={"reading-support-card"}>
              <Row className="accommodation-content" align="middle" justify="end">
                <Col className='accommodation-content-inner'>
                  <span><SoundOutlined /></span> {mrIntl("CommonText.text_to_speech")}
                </Col>
                <Col>
                  <Tooltip title={tooltipTitle}>
                    <Switch
                      size="small"
                      checked={currentAccommodations.tts_allowed || false}
                      onChange={(checked) => handleAccommodationChange('tts_allowed', checked)}
                      disabled={shouldDisabled}
                    />
                  </Tooltip>
                </Col>
              </Row>
              <Row className="accommodation-content" align="middle" justify="end">
                <Col className='accommodation-content-inner'>
                  <span><AudioOutlined /></span> {mrIntl("CommonText.speech_to_text")}
                </Col>
                <Col>
                  <Tooltip title={tooltipTitle}>
                    <Switch
                      size="small"
                      checked={currentAccommodations.stt_allowed || false}
                      onChange={(checked) => handleAccommodationChange('stt_allowed', checked)}
                      disabled={shouldDisabled}
                    />
                  </Tooltip>
                </Col>
              </Row>
              {/* <div className="accommodation-content">
                <div className="accommodation-item">
                  <span><SolarCloudsBroken /></span> Dyslexia Font
                </div>
                <div>
                  <Switch
                    size="small"
                    checked={accommodations.dyslexia_font || false}
                    onChange={(checked) => handleAccommodationChange('dyslexia_font', checked)}
                  />
                </div>
              </div>
              <div className="accommodation-content">
                <div className="accommodation-item">
                  <span><RiFontSize /></span> Font Size
                </div>
                <div>
                  <Select
                    defaultValue={accommodations.font_size || 'Default'}
                    style={{ width: 120 }}
                    onChange={(value) => handleAccommodationChange('font_size', value)}
                    options={[
                      { value: 'Default', label: 'Default' },
                      { value: 'Medium', label: 'Medium' },
                      { value: 'Large', label: 'Large' },
                    ]}
                  />
                </div>
              </div>
              <div className="accommodation-content">
                <div className="accommodation-item">
                  <span><CgFontSpacing /></span> Font Spacing
                </div>
                <div>
                  <Select
                    defaultValue={accommodations.font_spacing || 'Default'}
                    style={{ width: 120 }}
                    onChange={(value) => handleAccommodationChange('font_spacing', value)}
                    options={[
                      { value: 'Default', label: 'Default' },
                      { value: 'Medium', label: 'Medium' },
                      { value: 'High', label: 'High' },
                    ]}
                  />
                </div>
              </div> */}
            </Card>
          </div>
          {!monitorView && (
            <div className="accommodation-card">
              <Collapse bordered={false} items={items} className='legacy-app'></Collapse>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AccommodationModal;
