import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button, Space, Tooltip, Alert } from "antd";
import { AntCloudOutlined, CloudOutlined, LaptopOutlined } from "@ant-design/icons";
import { GrCloudComputer, GrPersonalComputer } from "react-icons/gr";
import { useSelector } from "react-redux";
import { savingStatusState } from "../FirestoreInteractions/selector";
import dayjs from "dayjs";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const SavingStatusIndicator = (props) => {
  const { segment_id, initialData } = props;
  const mrIntl = useTranslate()
  const savingStatusProps = { segment_id: segment_id };
  const savingStatus = useSelector((state) =>
    savingStatusState(state, savingStatusProps)
  );
  

  let finalRender = [];

  if (segment_id) {
    finalRender.push(
      <Tooltip
        placement="right"
        title={() => {
          let backupExists = true
          const getFormattedDate = (dateTime) => {
            return dayjs(dateTime).format("MMM DD, hh:mm a")
          }
          const formattedDateOffline = getFormattedDate(savingStatus.offline_saved_at || initialData.updated_at)
          const formattedDateOnline = getFormattedDate(savingStatus.online_saved_at || initialData.updated_at)
          if(["saved", "failed", "saving"].indexOf(savingStatus.online_status) == -1){
            // default case - either no backup or page refreshed  
            // savingStatus is only in redux so can be blank on refresh but doesn't mean backup doesn't exist
            if(initialData.updated_at){
              // using this for now to indicate backup exists but not accurate for offline backups - may not exist
            }else{
              // case where no backup exists
              backupExists = false
            }
          }
          let renderHTML = <Space direction="vertical">
            <span>
              <CloudOutlined /> <MrTranslate id={"SavingStatusIndicator.cloud_backup"}/> {savingStatus.online_status ? `(${savingStatus.online_status})` : ""}
              <br /> 
              <i>{backupExists ? mrIntl("SavingStatusIndicator.last_saved_online", {formattedDateOnline: formattedDateOnline}) : mrIntl("SavingStatusIndicator.no_backup_exists")}</i>
              {/* online_saved_at only being updated when saved - not when saving or failed */}
            </span>
            <span>
              <LaptopOutlined />{mrIntl("SavingStatusIndicator.local_backup")}{savingStatus.offline_status ? `(${savingStatus.offline_status})` : ""}
              <br /> 
              <i>{backupExists ? mrIntl("SavingStatusIndicator.last_saved_offline", {formattedDateOffline: formattedDateOffline}) : mrIntl("SavingStatusIndicator.no_backup_exists")}</i>
              {/* offline_saved_at only being updated when saved - not when saving or failed */}
            </span>
          </Space>
          return renderHTML;
        }}
        // title={`Online: Backup ${
        //   savingStatus.online_status || "not changed"
        // } (${
        //   savingStatus.online_saved_at
        //     ? dayjs(savingStatus.online_saved_at).format("MMM DD, hh:mm a")
        //     : initialData.updated_at
        //     ? dayjs(initialData.updated_at).format("MMM DD, hh:mm a")
        //     : "--"
        // }), Offline: Backup ${savingStatus.offline_status || "not changed"} (${
        //   savingStatus.offline_saved_at
        //     ? dayjs(savingStatus.offline_saved_at).format("MMM DD, hh:mm a")
        //     : initialData.updated_at
        //     ? dayjs(initialData.updated_at).format("MMM DD, hh:mm a")
        //     : "--"
        // })`}


        // title={`Backup ${
        //   savingStatus.online_status || "not changed"
        // } (Last saved at ${
        //   savingStatus.offline_saved_at
        //     ? dayjs(savingStatus.offline_saved_at).format("MMM DD, hh:mm a")
        //     : savingStatus.online_saved_at
        //     ? dayjs(savingStatus.online_saved_at).format("MMM DD, hh:mm a")
        //     : "--"
        // })`}
      >
        <Button
          className={"response-saving-status"}
          shape="circle"
          type="text"
          icon={
            <AntCloudOutlined
              className={
                (!savingStatus.online_status &&
                  !savingStatus.offline_status &&
                  initialData.updated_at &&
                  "saved") ||
                (savingStatus.online_status == "saving" &&
                  savingStatus.offline_status == "saving" &&
                  "saving") ||
                (((savingStatus.online_status == "saved" &&
                  savingStatus.offline_status != "saved") ||
                  (savingStatus.online_status != "saved" &&
                    savingStatus.offline_status == "saved")) &&
                  "partially-saved") ||
                (savingStatus.online_status == "saved" &&
                  savingStatus.offline_status == "saved" &&
                  "saved") ||
                (savingStatus.online_status == "failed" &&
                  savingStatus.offline_status == "failed" &&
                  "failed") ||
                ""
              }
            />
          }
        ></Button>
      </Tooltip>
    );
  } else {
    // finalRender.push(
    //   <Tooltip
    //     placement="right"
    //     title={`Backup ${
    //       savingStatus.online_status || "not changed"
    //     } (Last saved at ${
    //       savingStatus.offline_saved_at
    //         ? dayjs(savingStatus.offline_saved_at).format("MMM DD, hh:mm a")
    //         : savingStatus.online_saved_at
    //         ? dayjs(savingStatus.online_saved_at).format("MMM DD, hh:mm a")
    //         : "--"
    //     })`}
    //   >
    //     <Button
    //       className={"responses-saving-status"}
    //       shape="circle"
    //       icon={
    //         <AntCloudOutlined
    //           className={
    //             (savingStatus.online_status == "saving" &&
    //               savingStatus.offline_status == "saving" &&
    //               "saving") ||
    //             (((savingStatus.online_status == "saved" &&
    //               savingStatus.offline_status != "saved") ||
    //               (savingStatus.online_status != "saved" &&
    //                 savingStatus.offline_status == "saved")) &&
    //               "partially-saved") ||
    //             (savingStatus.online_status == "saved" &&
    //               savingStatus.offline_status == "saved" &&
    //               "saved") ||
    //             (savingStatus.online_status == "failed" &&
    //               savingStatus.offline_status == "failed" &&
    //               "failed") ||
    //             ""
    //           }
    //         />
    //       }
    //     ></Button>
    //   </Tooltip>
    // );
  }
  console.log("saving status ==>", savingStatus);
  return <span className="responses-saving-status-wrapper">{finalRender}</span>;
};

export default SavingStatusIndicator;
