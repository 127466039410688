import { createSelector } from "reselect";

const defaultEmptyObject = {};
const aiGenerateQuestionsConfigState = (state) => {
  return state.aisegments.get("aiGenerateQuestionsConfig", defaultEmptyObject);
};

export const aiGenerateQuestionsConfigSelector = () =>
  createSelector([aiGenerateQuestionsConfigState], (aiGenerateQuestionsConfig) => aiGenerateQuestionsConfig);

const aiSegmentsState = (state) => {
  console.log("state ai==>", state)
  return state.aisegments.get("segments");
}

export const aiGenerateModeSelector = () => 
  createSelector([aiGenerateQuestionsConfigState], (aiConfig) => aiConfig.generateMode)

export const aiGenerateLoadingSelector = () => 
  createSelector([aiGenerateQuestionsConfigState], (aiConfig) => aiConfig.loading)

export const activeAISegmentPositionSelector = () => 
  createSelector([aiGenerateQuestionsConfigState], (aiConfig) => aiConfig.activeAISegmentPosition)

export const addAllButtonLoadingSelector = () => 
  createSelector([aiGenerateQuestionsConfigState], (aiConfig) => aiConfig.addAllButtonLoading)

export const aiSegmentsSelector = () =>
  createSelector([aiSegmentsState], (aiSegments) => {
    let finalAISegments = []
    try {
      finalAISegments = aiSegments.toJS()
    } catch (error) {
      finalAISegments = aiSegments
    }
    return finalAISegments
  });

const allPaperTypesState = (state) => {
  return state.request.get("papertype");
};

export const allPaperTypesSelector = () =>
  createSelector([allPaperTypesState], (allPaperTypes) => allPaperTypes);

const keyConceptsState = (state) => {
  return state.request.get("keyconcepts");
};

export const keyConceptsSelector = () =>
  createSelector([keyConceptsState], (keyConcepts) => keyConcepts);

const relatedConceptsState = (state) => {
  return state.request.get("relatedconcepts");
};

export const relatedConceptsSelector = () =>
  createSelector([relatedConceptsState], (relatedConcepts) => relatedConcepts);


const globalContextsState = (state) => {
  return state.request.get("globalcontexts");
};

export const globalContextsSelector = () =>
  createSelector([globalContextsState], (globalContexts) => globalContexts);


const possibleExplorationsState = (state) => {
  return state.request.get("possibleexplorations");
};


export const possibleExplorationsSelector = () =>
  createSelector([possibleExplorationsState], (possibleExplorations) => possibleExplorations);


const atlSkillsState = (state) => {
  return state.request.get("atlskills");
};

export const atlSkillsSelector = () =>
  createSelector([atlSkillsState], (atlskills) => atlskills);

// const allDifficultyLevels = useSelector((state) => {
//   return state.request.get("difficultylevels")
// })

const allTeachingLevelsState = (state) => {
  return state.request.get("teachinglevels");
};


export const allTeachingLevelsSelector = () =>
  createSelector([allTeachingLevelsState], (allTeachingLevels) => allTeachingLevels);

