import React, { useEffect } from 'react';
import { Tooltip, Tag, Space } from 'antd';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';

const ShortcutsTip = () => {
  const mrIntl = useTranslate()
  const tips = [
    {
      // text: 'Previous Student',
      text: mrIntl("ShowShortCuts.previous_student"),
      shortcut: <span><Tag className='shortcut-tag'>⇧ Shift</Tag><Tag className='shortcut-tag'><ArrowUpOutlined /></Tag></span>,
      // tooltip: "Shift and Arrow Up",
      tooltip: mrIntl("ShowShortCuts.shift_and_arrow_up"),
    },
    {
      // text: 'Next Student',
      text: mrIntl("ShowShortCuts.next_student"),
      shortcut: <span><Tag className='shortcut-tag'>⇧ Shift</Tag><Tag className='shortcut-tag'><ArrowDownOutlined /></Tag></span>,
      // tooltip: "Shift and Arrow Down",
      tooltip: mrIntl("ShowShortCuts.shift_and_arrow_down"),
    },
    {
      // text: 'Previous Question',
      text: mrIntl("ShowShortCuts.previous_question"),
      shortcut: <span><Tag className='shortcut-tag'>⇧ Shift</Tag><Tag className='shortcut-tag'><ArrowLeftOutlined /></Tag></span>,
      // tooltip: "Shift and Arrow Left",
      tooltip: mrIntl("ShowShortCuts.shift_and_arrow_left"),
    },
    {
      // text: 'Next Question',
      text: mrIntl("ShowShortCuts.next_question"),
      shortcut: <span><Tag className='shortcut-tag'>⇧ Shift</Tag><Tag className='shortcut-tag'><ArrowRightOutlined /></Tag></span>,
      // tooltip: "Shift and Arrow Right",
      tooltip: mrIntl("ShowShortCuts.shift_and_arrow_right"),
    },
    {
      // text: 'Next Ungraded Question',
      text: mrIntl("ShowShortCuts.next_ungraded_question"),
      shortcut: <span><Tag className='shortcut-tag'>Ctrl</Tag><Tag className='shortcut-tag'>⇧ Shift</Tag><Tag className='shortcut-tag'><ArrowRightOutlined /></Tag></span>,
      // tooltip: "Ctrl and Shift and Arrow Right",
      tooltip: mrIntl("ShowShortCuts.ctrl_and_shift_and_arrow_right"),
    },
  ];
  // @UTTAM & SHIKHAR TO REVIEW TO SHOW Show that they have to press shift key
  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.shiftKey && event.key.includes('Arrow')) {
  //       message.info(`You pressed Shift + ${event.key}`, {
  //         duration: .5,
  //         icon: null
  //       });
  //     }
  //   };

  //   window.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  return (
    <div className="shortcuts-container">
      <strong>{mrIntl("ShowShortCuts.shortcuts")}:</strong>
      {tips.map((tip) => (
        <Tooltip title={tip.tooltip} key={tip.text}>
          <Space className="shortcut">
            {tip.text}
            {tip.shortcut}
          </Space>
        </Tooltip>
      ))}
    </div>
  );
};

export default ShortcutsTip;
