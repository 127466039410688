/* global gapi */
/* global google */
import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, List, Spin } from "antd";
import Spinner from "/src/components/UI/Spinner/Spinner";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";
import IntegrationModal from "./IntegrationModal";
import "./GCIntegration.scss";
import { axiosInstance, makeAxiosInstance } from "/src/api/apiModule";
import { actions as groupActions } from '/src/views/Groups/redux';
import { useDispatch, useSelector } from "react-redux";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { SelectProgram } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import { currentUserSelector } from "/src/views/Auth/Login/selector";

export const IntegrationContext = React.createContext();
// import { List } from 'antd';

function useScript(src, opts = {}) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "idle");

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus("idle");
        return;
      }
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);
      if (!script) {
        // Create script
        script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.defer = true;
        script.setAttribute("data-status", "loading");
        // if(opts.scriptOpts){
        //   for (const key in opts.scriptOpts) {
        //     if (Object.hasOwnProperty.call(opts.scriptOpts, key)) {
        //       const element = opts.scriptOpts[key];
        //       script[key] = element;
        //     }
        //   }
        // }
        // Add script to document body
        document.body.appendChild(script);
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute(
            "data-status",
            event.type === "load" ? "ready" : "error"
          );
        };
        script.addEventListener("load", setAttributeFromEvent);
        script.addEventListener("error", setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute("data-status"));
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === "load" ? "ready" : "error");
      };
      // Add event listeners
      script.addEventListener("load", setStateFromEvent);
      script.addEventListener("error", setStateFromEvent);
      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener("load", setStateFromEvent);
          script.removeEventListener("error", setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );
  return status;
}

const GoogleClassroomIntegration = (props) => {
  let tokenClient = useRef(null);

  const currentUser = useSelector(currentUserSelector());
  const [gapiInitiated, setGapiInitiated] = useState(false);
  const [gisInitiated, setGisInitiated] = useState(false);
  const [gcCourses, setGCCourses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [groupUsers, setGroupUsers] = useState([]);

  const [selectedOrgProgramme, setSelectedProgramme] = useState(null);

  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const CLIENT_ID = import.meta.env.VITE_GOOGLE_CLASSROOM_CLIENT_ID;
  
  const API_KEY = import.meta.env.VITE_GOOGLE_CLASSROOM_API_KEY;
  const org_id = props.org_id || currentUser.org_id;
  // const CLIENT_ID = '275984646480-g2rrsg8b7n1pllfusc0lc68c4bsnhe5s.apps.googleusercontent.com';
  // const API_KEY = 'AIzaSyC7OZ7aB8Y6Qd4QQF6igJjN1oOHgz2ItXc';
  // const finalRenderArr = [];
  // const API_KEY = import.meta.env.VITE_GOOGLE_CLASSROOM_API_KEY;

  // Discovery doc URL for APIs used by the quickstart
  const DISCOVERY_DOC = "https://classroom.googleapis.com/$discovery/rest";

  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  const SCOPES =
    "https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.profile.emails https://www.googleapis.com/auth/classroom.push-notifications https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/classroom.coursework.students.readonly https://www.googleapis.com/auth/classroom.profile.emails";

  const gapiStatus = useScript("https://apis.google.com/js/api.js", {
    scriptOpts: {
      defer: true,
    },
  });

  const gisStatus = useScript("https://accounts.google.com/gsi/client", {
    scriptOpts: {
      defer: true,
    },
  });

  useEffect(() => {
    async function intializeGapiClient() {
      if (gapi.client) {
        await gapi.client.init({
          apiKey: API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
        });
      }

      setGapiInitiated(true);
      // maybeEnableButtons();
    }
    if (gapiStatus === "ready") {
      gapi.load("client", intializeGapiClient);
    }
    return () => {};
  }, [gapiStatus]);

  useEffect(() => {
    if (gisStatus === "ready") {
      if (google && google.accounts) {
        tokenClient.current = google.accounts.oauth2.initTokenClient({
          client_id: CLIENT_ID,
          scope: SCOPES,
          callback: "", // defined later
        });
      } else {
        console.error("google not found");
      }
      setGisInitiated(true);
      // maybeEnableButtons();
    }
    return () => {};
  }, [gisStatus]);

  const handleIntegration = () => {
    if (handleAuthClick) {
      handleAuthClick({
        callback: () => {
          setShowModal(true);
        },
      });
    }
  };

  const handleAuthClick = (opts = {}) => {
    console.log("gapi", gapi);
    console.log("google", google);
    // return;
    tokenClient.current.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      // document.getElementById('signout_button').style.visibility = 'visible';
      // if (!props.children) {
      //   document.getElementById("authorize_button").innerText = "Refresh";
      // }
      opts.callback && opts.callback();
      await getCourses();
    };
    // console.log("gapi.client.getToken", gapi.client.getToken());
    // console.log("gapi.auth.getToken", gapi.auth.getToken());
    // console.log("gapi.client", gapi.client);
    if (gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.current.requestAccessToken({ prompt: "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.current.requestAccessToken({ prompt: "" });
      opts.callback && opts.callback();
      getCourses();
    }
  };
  
  async function getCourses() {
    console.log("getCourses");
    let gcResponse;
    try {
      gcResponse = await gapi.client.classroom.courses.list({
        pageSize: 100,
      });
    } catch (err) {
      document.getElementById("content").innerText = err.message;
      console.error("err", err);
      return;
    }
    console.log("gcResponse line 108", gcResponse);
    const courses = gcResponse.result.courses;

    // Create an array of course IDs
    const courseUUIds = courses.map((course) => `gc-gId-${course.id}`);

    let url = "groups.json";
    const apResponse = await axiosInstance.instance.get(url, {
      params: { by_uuids: courseUUIds },
    });
    const groups = apResponse.data.groups.data;
    console.log("groups apResponse ==>", apResponse);

    setGCCourses(courses);
    courses.forEach((course, courseIdx) => {
      const matchingGroup = groups.find((group) => group.source_id === course.id);
      if (matchingGroup) {
        updateCourse(course, courseIdx, true);
      }
    });    
    // updateGroupUsers();
    // Send the course IDs to the backend
    // try {
    //    // Dispatch the action to fetch groups by UUIDs
    //   dispatch(groupActions.fetchByUuids({by_uuids: courseUUIds}));
    //   console.log("groupActions ==>", groupActions);
    // } catch (error) {
    //   console.error(error);
    // }
  }

  async function fetchCourseUsers(userType, courseId) {
    const res = await gapi.client.request({
      path: `https://classroom.googleapis.com/v1/courses/${courseId}/${userType}`,
    });
    console.log(`${userType} res`, res);
    let users = (res.result && res.result[`${userType}`]) || [];
    let userUUIDs = users.map((user) => `gc-uId-${user.userId}`);
    console.log(`${userType} => userUUIDs, users`, userUUIDs, users)
    return { userUUIDs, users };
  }

  async function getCourseUsers(course, checked) {
    const courseId = course.id;
    console.log("course.id", courseId, course);

    let { userUUIDs: studentUUIDs, users: students } = await fetchCourseUsers("students", courseId);
    let { userUUIDs: teacherUUIDs, users: teachers } = await fetchCourseUsers("teachers", courseId);

    console.log("studentUUIDs, students, teacherUUIDs, teachers", studentUUIDs, students, teacherUUIDs, teachers);
    let userUUIDs = [...studentUUIDs, ...teacherUUIDs];

    let url = "users.json";
    const apResponse = await axiosInstance.instance.get(url, { params: { by_uuids: userUUIDs } });
    
    const users = apResponse.data.users.data;
    console.log("users apResponse", apResponse);

    if (students.length > 0) {
      students.forEach((student) => {
        const matchingUser = users.find((user) => user.source_id === student.id);
        if (matchingUser || checked) {
          student.import = true;
        }
      });
    }

    if (teachers.length > 0) {
      teachers.forEach((teacher) => {
        const matchingUser = users.find((user) => user.source_id === teacher.id);
        if (matchingUser || checked) {
          teacher.import = true;
        }
      });
    }

    console.log("course users ===>", students, teachers)
    return {students, teachers};
  }


  const updateCourse = async (course, courseIdx, checked) => {
    setGCCourses((gcCourses) => {
      const newgcCourses = [...gcCourses];
      course.import = checked;
      newgcCourses[courseIdx] = course;
      return newgcCourses;
    });
    
    const { students, teachers } = await getCourseUsers(course, checked);
    course.students = students;
    course.teachers = teachers;
    
    setGCCourses((gcCourses) => {
      const newgcCourses = [...gcCourses];
      newgcCourses[courseIdx] = course;
      return newgcCourses;
    });
    updateGroupUsers(course, checked);
  };

  const updateCourseUser = (courseIdx, user, userIdx, checked, userType) => {
    setGCCourses((gcCourses) => {
      const newgcCourses = [...gcCourses];
      newgcCourses[courseIdx][userType][userIdx] = {
        ...user,
        import: checked,
      };
      return newgcCourses;
    });
  };

  const getGroupUserObject = (course, user, userType, checked) => {
    return {
      // id: user.id,
      source_type: "gc",
      source_id: user.userId,
      // final_grade: ,
      // criteria_sum: ,
      uuid: "gc-gId-" + course.id + "-uId-" + user.userId, 
      relation: userType,
      group_id: null,
      user_id: null,
      user: {
        first_name: user.profile.name.givenName,
        last_name: user.profile.name.familyName,
        email: user.profile.emailAddress,
        role: userType,
        uuid: "gc-uId-" + user.userId,
      },
      group: {
        name: course["descriptionHeading"],
        source_type: "gc",
        custom_fields: {
          "alternateLink": course["alternateLink"], 
          "teacherGroupEmail": course["teacherGroupEmail"], 
          "courseGroupEmail": course["courseGroupEmail"], 
          "gradebookSettings": course["gradebookSettings"], 
          "teacherFolder": course["teacherFolder"], 
          "calendarId": course["calendarId"], 
          "enrollmentCode": course["enrollmentCode"],
          "ownerId": course["ownerId"],
        },
        uuid: "gc-gId-" + course.id,
        source_id: course.id,
        archived: course["courseState"] === "ACTIVE" ? false : true,
      },
      archived: !checked,
    }
  }

  const updateGroupUser = (course, item, itemId, checked, userType) => {
    let groupUserIdx = groupUsers.findIndex((item) => item.source_id === itemId);
    let groupUser = getGroupUserObject(course, item, userType, checked)
    setGroupUsers((groupUsers) => {
      const newGroupUsers = [...groupUsers];
      if (groupUsers[groupUserIdx].id || checked) {
        newGroupUsers[groupUserIdx] = {
          ...groupUsers[groupUserIdx],
          ...groupUser,
        };
      } else {
        newGroupUsers.splice(groupUserIdx, 1);
      }
      return newGroupUsers;
    });
  };

  const updateGroupUsers = (course, checked = false) => {
    const newGroupUsers = [...groupUsers];
    const userTypes = ['student', 'teacher'];
    userTypes.forEach((userType) => {
      if (course[userType + "s"]) {
        course[userType + "s"].forEach((user) => {
          let groupUserIdx = groupUsers.findIndex((item) => item.id && item.gcUser.source_id === user.userId);
          let groupUser = getGroupUserObject(course, user, userType, checked);

          console.log("groupUserIdx, checked ==>", groupUserIdx, checked, groupUser)
          if (checked && groupUserIdx === -1) {
            newGroupUsers.push(groupUser);
          } else {
            if (groupUserIdx >= 0){
              newGroupUsers.push({
                ...groupUsers[groupUserIdx],
                ...groupUser,
                archived: !checked,
              })
            }
          }
        });
      }
    });
    console.log("newGroupUsers ==>", newGroupUsers);
    setGroupUsers(newGroupUsers);
  };

  const onCheckboxChange = (type, course, item, itemId, checked) => {
    let courseIdx = gcCourses.findIndex((gcCourse) => gcCourse.id === course.id);
    if (type === "course") {
      updateCourse(course, courseIdx, checked);
    } else if (type === "student" || type === "teacher") {
      let userIdx = course[type + 's'].findIndex((user) => user.id === itemId);
      updateCourseUser(courseIdx, item, userIdx, checked, type + 's');    
      updateGroupUser(course, item, itemId, checked, type);
    }
  }

  console.log("group users ===>", gcCourses, groupUsers)
  function renderCourses() {
    return (
      <div className={"gcourses-main-list"}>
        {gcCourses.map((course) => {
          return (
            <div className={"gcourse-data-list"}>
              <Checkbox 
                key={`course-${course.id}`} 
                onChange={(e) => {
                  onCheckboxChange("course", course, course, course.id, e.target.checked)
                }} 
                checked={course.import}
              >
                {course.id} - {course.name}
              </Checkbox>

              <div className="gcourse-users-list">
                {course.teachers && (
                  <List
                    dataSource={course.teachers}
                    renderItem={(teacher, i) => (
                      <List.Item key={`teacher-${teacher.id}`}>
                        <Checkbox
                          checked={teacher.import} 
                          onChange={(e) => {
                            onCheckboxChange("teacher", course, teacher, teacher.id, e.target.checked)
                          }}>
                          Teacher {i+1} - {teacher.userId} - {teacher.profile.name.fullName} - {teacher.profile.emailAddress}
                        </Checkbox>
                      </List.Item>
                    )}
                  />
                )}
                {course.students && (
                  <List
                    dataSource={course.students}
                    renderItem={(student, i) => (
                      <List.Item key={`student-${student.id}`}>
                        <Checkbox 
                          checked={student.import}
                          onChange={(e) => {
                            onCheckboxChange("student", course, student, student.id, e.target.checked)
                          }}>
                          Student {i+1} - {student.userId} - {student.profile.name.fullName} - {student.profile.emailAddress}
                        </Checkbox>
                      </List.Item>
                    )}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );

    // return <div>{gcCourses.map((course) => {
    //   return <div key={`googleCourse${course.id}`}>
    //     {course.id} {course.name}
    //   </div>
    // })}</div>
  }

  // function GoogleClassroomIntegrationChild(props) {
  //   const integrationContext = useContext(IntegrationContext);
  //   const {gcCourses, getCourseUsers, handleAuthClick } = integrationContext

  //   const [showModal, setShowModal] = useState(false);

  //   const handleIntegration = () => {
  //     if (handleAuthClick) {
  //       handleAuthClick({
  //         callback: () => {
  //           setShowModal(true);
  //         },
  //       });
  //     }
  //   };

  //   const [students, setStudents] = useState();
  //   useEffect(() => {
  //     gcCourses.map((item) => {
  //       setStudents(getCourseUsers(item.id));
  //     });
  //   }, [gcCourses.length]);

  //   return (
  //     <>
  //       <span onClick={handleIntegration}> Import from Google Classroom </span>
  //       <IntegrationModal
  //         modalTitle={"Google Classroom Integration"}
  //         showModal={showModal}
  //         setShowModal={setShowModal}
  //       >
  //         {students}
  //       </IntegrationModal>
  //     </>
  //   );
  // }


  const handleCancel = () => {
    setShowModal(false);
  }

  const handleOk = () => {
    const url = "/group_users/bulk_create"
    const final_data = {
      import_type: "google_classroom",
      org_programme_id: selectedOrgProgramme,
      group_users: groupUsers,
    }
    axiosInstance.instance.post(url, final_data).then((res) => {
      console.log("group_users bulk_create res ==>", res);
      setShowModal(false);
    }).catch((err) => {
      console.error("group_users bulk_create err", err);
    })
  }

  console.log("gcCourses, groupUsers ==>", gcCourses, groupUsers);
  console.log("gapiInitiated, gisInitiated", gapiInitiated, gisInitiated);
  return (
    // <>
    //   {props.children ? (
    //     gapiInitiated && gisInitiated ? (
    //       <IntegrationContext.Provider
    //         value={{ handleAuthClick, gcCourses, getCourseUsers }}
    //       >
    //         {props.children}
    //       </IntegrationContext.Provider>
    //     ) : (
    //       <Spinner />
    //     )
    //   ) : gapiInitiated && gisInitiated ? (
    //     <button id="authorize_button" onClick={handleAuthClick}>
    //       Authorize
    //     </button>
    //   ) : (
    //     ""
    //   )}
    // </>

    <Spin spinning={!gapiInitiated || !gisInitiated} size="small">
      <span onClick={() => handleIntegration()}>
        {" "}
        Import from Google Classroom{" "}
      </span>
      <IntegrationModal
        modalTitle={"Google Classroom Integration"}
        showModal={showModal}
        setShowModal={setShowModal}
        modalProps={{
          footer: [
            <Button
              key={`import-cancel`}
              onClick={() => handleCancel()}
              type="default"
            >
              {mrIntl("CommonText.cancel")}
            </Button>,
            <Button
              key={`import-submit`}
              onClick={() => handleOk()}
              type="primary"
            >
              {mrIntl("CommonText.submit")}
            </Button>
          ],
        }}
      >
        <SelectProgram key="selectProgramGroupsFilter" value={selectedOrgProgramme} onChange={(value) => {setSelectedProgramme(value)}} config={{
          params: {by_org_id: org_id},
          widgetConfig:{ style: {width:"100%"}},
        }}/>
        {renderCourses()}
      </IntegrationModal>

      {/* <IntegrationContext.Provider
        value={{ handleAuthClick, gcCourses, getCourseUsers }}
      >
        <GoogleClassroomIntegrationChild />
      </IntegrationContext.Provider> */}
    </Spin>
  );
};

GoogleClassroomIntegration.defaultProps = {};

GoogleClassroomIntegration.propTypes = {};

export default GoogleClassroomIntegration;
