import RenderHtml from '/src/components/UI/RenderHtml/RenderHtml';
import React, { useState, useEffect } from 'react';
import { ImMagicWand } from "react-icons/im";

const TypingEffect = ({ text, typingSpeed, isStreamStarted, setIsStreamStarted, className = "", responseCompleted }) => {
  const [typedText, setTypedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cursorVisible, setCursorVisible] = useState(true);

  useEffect(() => {
    console.log("text currentIndex", text, currentIndex);
    if(text) {
      const interval = setInterval(() => {
        if (currentIndex < text.length) {
          setTypedText(text.slice(0, currentIndex + 1));
          setCurrentIndex(currentIndex + 1);
          setCursorVisible((prevCursorVisible) => !prevCursorVisible);
        } else if(responseCompleted) {
          clearInterval(interval);
          setCursorVisible(false); // Hide the cursor when typing is complete
          setIsStreamStarted(false)
        }
      }, typingSpeed);
  
      return () => clearInterval(interval);
    }
    // setTypedText(text)
  }, [text, currentIndex, typingSpeed]);

  // const cursorStyle = {
  //   display: cursorVisible ? 'inline-block' : 'none',
  //   width: "3ch",
  //   animation: "flicker 0.5s infinite",
  // };

  const cursor = 
    isStreamStarted 
    ? `<span   
          style="height: 10px;
          width: 10px;
          background-color: #bbb;
          border-radius: 50%;
          display: inline-block;"></span>` 
    : ""
    return (
      <div>
      {/* {typedText} */}
      {/* 
        /////////////
        // IMPORTANT: extra space is used because parse was breaking inside RenderHtml
        // TODO: find different way to do this
        /////////////
      */}
      <RenderHtml text={typedText + " " + cursor} extraClassname={className}/>
      {/* <span style={cursorStyle}> |</span> */}
    </div>
  );
};

export default TypingEffect;
