import { Button, Col, Dropdown, Form, Input, Row, Space } from "antd";
import React from "react";
import { PlusCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { InputNumber } from 'antd';
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { InputArea } from "/src/components/UI/Segment/UIHelper";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const RubricLevels = (props) => {
  console.log("RubricLevels===>", props);
  const { addLevels, levels, levelIndex, parentIndex, deleteLevels, customOnChange, useScoring, oneLevelForAll, criteria = {}, menuItem, createMode, strands = {}, strandIndex, type } = props

  const levelsJson = criteria.levels_json || []
  const mrIntl = useTranslate()
  const moreOptions = (
    <Dropdown overlay={menuItem("level", levelIndex, parentIndex)} trigger={["click"]} placement="left" disabled={levelsJson.length === 1}>
      <Button
        type="text"
        icon={<MoreOutlined />}
        style={oneLevelForAll ? {marginTop: "-10px", float: "right", marginRight: "-15px"} : { float: "right", marginRight: "-25px" }}
      />
    </Dropdown>
  )

  const pointsInput = (
    createMode ? <Form.Item
      // name={`points ${levelIndex}`}
      rules={[{
        required: !levels.level_points ? true : false,
        message: mrIntl("CommonText.required_msg")
      }]}
    >
      <InputNumber
        placeholder={mrIntl("CommonText.points")}
        style={{ width: "60px" }}
        value={levels.level_points}
        onChange={(value) => customOnChange(
          value,
          "levels_json",
          "level_points",
          parentIndex,
          levelIndex,
          type,
          strandIndex
        )}
      ></InputNumber>
    </Form.Item> : <p>{levels.level_points}</p>
  )

  const titleInput = (
    createMode ? <Form.Item
      name={`label-${type}-${criteria.id}`}
      rules={[{
        required: !levels.level_label ? true : false,
        message: mrIntl("CommonText.required_msg")
      }]}
    >
      <Input
        placeholder= {mrIntl("CommonText.title")}
        value={levels.level_label}
        onChange={(e) => customOnChange(
          e.target.value,
          "levels_json",
          "level_label",
          parentIndex,
          levelIndex,
          type,
          strandIndex
        )}
      >
      </Input>
      {" "}
    </Form.Item> : <p>{levels.level_label}</p>
  )
  const addButton = (
    <Button type="text" onClick={() => addLevels(levelIndex, parentIndex, strandIndex)} icon={<PlusCircleOutlined />} className="add-levels-button" size="large"></Button>
  )

  return (
    <div>
      <div className="critria-levels">
        {oneLevelForAll && parentIndex === 0 && type !== "strands" &&
          <Col span={24} className={"levels-header"}>
            {/* {createMode && type !== "strands" && moreOptions} */}
            <Row>
              <Col span={useScoring ? 14 : 24}>{titleInput}</Col>
              {useScoring && <Col span={6} >{pointsInput}</Col>}
              {/* {useScoring && <Col span={4} style={{margin: "auto", float: 0}}>/ Pts</Col>} */}
            </Row>
            {createMode && addButton}
          </Col>
        }
        <Space
          direction="vertical"
          className="levels-wrapper"
        >
          {!oneLevelForAll && createMode && type !== "strands" && moreOptions}
          <Row>
              {!oneLevelForAll && <Col span={useScoring ? 14 : 24}>{titleInput}</Col>}
              {useScoring && !oneLevelForAll && <Col span={6}>{pointsInput}</Col>}
              {/* {useScoring && !oneLevelForAll && <Col span={4} style={{margin: "auto", float: 0}}>/ Pts</Col>} */}
            </Row>
            {createMode ? <InputArea className={`multiline-input-area ${(oneLevelForAll || criteria !== "strands") && "multiline-description"}`}>
              <BasicCKE
                placeholder={mrIntl("CommonText.description")}
                data={levels.desc}
                onTextChanged={(data) => customOnChange(
                  data,
                  null,
                  "desc",
                  parentIndex,
                  levelIndex,
                  type,
                  strandIndex
                )}
              ></BasicCKE>
            </InputArea> : <p><RenderHtml text={levels.desc}/></p>}
            
          {/* <TextArea
            rows={4}
            placeholder="Description"
            // className={`${oneLevelForAll && "multiline-input-for-levels"}`}
            value={levels.desc}
            onChange={(e) => customOnChange(
              e.target.value,
              null,
              "desc",
              parentIndex,
              levelIndex
            )}
          >
          </TextArea> */}
          {!oneLevelForAll && createMode && addButton}
        </Space>
      </div>
    </div>
  )
}
export default RubricLevels;