import React from "react";
import { Alert } from "antd";
import { getBrowser } from "/src/lib/utils/helperMethods";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { useSelector } from "react-redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const CheckBrowser = () => {
  const mrIntl = useTranslate()
  console.log("inside check browser==>", getBrowser());

  const appType = useSelector(appTypeSelector());

  var browserName = getBrowser().match(/\w.+\//g)[0];
  // var finalBrowserName = browserName.replace(/\//g, "");
  // var browserVersion = getBrowser().match(/\/\d+/g) && getBrowser().match(/\/\d+/g)[0];
  // var finalBrowserVersion = browserVersion ? browserVersion.replace(/\//g, "") : "";

  var browserAlert;

  if (
    browserName.includes("Chrome") ||
    browserName.includes("Firefox") ||
    browserName.includes("Safari") || 
    browserName.includes("Edge")
  ) {
    // TEMP hide version warning
    // if (
    //   (finalBrowserName === "Chrome" && finalBrowserVersion < "84") ||
    //   (finalBrowserName === "Firefox" && finalBrowserVersion < "81") ||
    //   (finalBrowserName === "Safari" && finalBrowserVersion < "14")
    // ) {
    //   browserAlert = (
    //     <Alert
    //       message={`Your ${finalBrowserName} version ${finalBrowserVersion} is outdated. Please update it to the latest version.`}
    //       banner
    //       closable={false}
    //       className={"internet-alert"}
    //     />
    //   );
    // }
  } else {
    browserAlert = (
      <Alert
        // message={`${browserName} is not supported. Please use Google Chrome, Safari or Firefox`}
        message={mrIntl("CheckBrowser.not_supported_msg", {browserName: browserName})}
        banner
        closable={false}
        className={"internet-alert"}
      />
    );
  }
  return <>{appType === "web" && browserAlert}</>;
};

export default CheckBrowser;
