import React, { Suspense, useEffect, useState } from "react";
import { getUniqueId } from "/src/lib/utils/helperMethods";
import commonSelectors from "/src/App/commonSelectors";
import { Segment } from "/src/components/UI/Segment/UIHelper";
import SegmentItemFooter from "/src/views/Segments/SegmentItemDetail/SegmentItemFooter";
import { Route, useRouteMatch } from "react-router";
// import LibrarySegments from "../LibrarySegments/LibrarySegments";
// import Segments from "../Segments";
import SegmentList from "../SegmentList/SegmentList";
import {
  librarySegmentsSelector,
} from "../selector";
import CrypticHunt from "../CrypticHunt/CrypticHunt";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { useSelector } from "react-redux";
import { enabledFeaturesSelector } from "../../Auth/Login/selector";
// import AISegments from "../GenerateAI/AISegments";

const LibrarySegments = React.lazy(() => import('../LibrarySegments/LibrarySegments'));
const Segments = React.lazy(() => import('../Segments'));
const AISegments = React.lazy(() => import('../GenerateAI/AISegments'));

const QuestionGroup = (props) => {
  console.log("Question Group props", props);
  const {
    createMode,
    answerMode,
    gameMode,
    presentationMode,
    autoCheckMode,
    segmentSettings,
    setRenderMath,
    segmentFooterAttributes,
    childrenRenderProps,
    game_type,
    customPropSegments,
    renderQuestionAreaOnly
  } = props;

  const {
    experienceViewMode,
    experience,
    mobileUploadMode,
    embedded,
    rearrangeMode,
    readingModeEnabled,
    printMode,
    segment,
    nestingLevel,
    parentIdxs,
    activeTopicIdx,
    libraryModalProps,
    gradingConfig,
    experienceUser,
    isLibrary,
    isSegmentInAIList,
    isLibraryModal,
  } = childrenRenderProps;

  const { path } = useRouteMatch();
  const enabledFeatures = useSelector(enabledFeaturesSelector());

  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: false,
    showCorrectAnswerSwitchValue: false,
  });

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  const toggleAnswer = () => {
    setStateSetting({
      ...stateSetting,
      quickCheckModeEnabled: !stateSetting.quickCheckModeEnabled,
    });
  };

  let segmentsToRender = null;

  if (isLibrary) {
    segmentsToRender = (
      <Route
        path={`${path}`}
        render={(routeProps) => (
          <Suspense fallback={<Spinner />}>
            <LibrarySegments
              experienceViewMode={experienceViewMode}
              // experienceProps={{ item: experience }}
              // mobileUploadMode={mobileUploadMode}
              // embedded={embedded}
              // rearrangeMode={rearrangeMode}
              // readingModeEnabled={readingModeEnabled}
              // topicProps={{ item: segment }}
              // children={segment.descendants} // would have had to find children from descendants
              isLibrary={true}
              isLibraryModal={isLibraryModal}
              parentId={segment.id}
              activeTopicIdx={activeTopicIdx}
              // parentIdxs={parentIdxs}
              nestingLevel={nestingLevel + 1}
              customPropSegments={customPropSegments}
              renderQuestionAreaOnly={renderQuestionAreaOnly}
              // gradingConfig={gradingConfig}
              // experienceUser={experienceUser}
              params={{
                is_library: true,
                by_original: true,
                is_library_modal: isLibraryModal,
              }}
              // createUpdateParams={{
              //   experience_id: segment.experience_id,
              //   parent_id: segment.id,
              // }}
              libraryModalProps={libraryModalProps}
              isRoute={false}
              {...routeProps}
              config={{
                filter: {
                  show: false,
                  config: {
                    create: { show: false },
                    search: { show: false },
                  },
                },
                pagination: { show: false },
                list: {
                  widget: SegmentList, // TODO: not working here wihtout explicitly mentioning widget - something strange in framework - overriding default list widget with undefined even just if LibrarySegments is imported
                  shouldExecuteRequest: false, // No fetch, getting from redux
                },
              }}
            />
          </Suspense>
        )}
      />
    );
  } else {
    console.log("answerMode, gameMode, segmentSettings.is_game, game_type", answerMode, gameMode, segmentSettings.is_game, game_type)
    if (answerMode && gameMode && segmentSettings.is_game) {
      if (game_type == "cryptic_hunt" || true) { // NOTE: Handle this when game_type available
        segmentsToRender = (
          <CrypticHunt
            {...childrenRenderProps}
            nestingLevel={nestingLevel + 1}
          ></CrypticHunt>
        );
      }
    } else {
      segmentsToRender = (
        <Route
          path={`${path}`}
          render={(routeProps) => (
            <Suspense fallback={<Spinner />}>
              <Segments
                experienceViewMode={experienceViewMode}
                experienceProps={{ item: experience }}
                mobileUploadMode={mobileUploadMode}
                printMode={printMode}
                embedded={embedded}
                rearrangeMode={rearrangeMode}
                readingModeEnabled={readingModeEnabled}
                topicProps={{ item: segment }}
                parentId={segment.id}
                parentSegment={segment}
                activeTopicIdx={activeTopicIdx}
                parentIdxs={parentIdxs}
                nestingLevel={nestingLevel + 1}
                gradingConfig={gradingConfig}
                experienceUser={experienceUser}
                customPropSegments={customPropSegments}
                renderQuestionAreaOnly={renderQuestionAreaOnly}
                params={{
                  by_experience_id: segment.experience_id,
                  by_parent_id: segment.id,
                }}
                createUpdateParams={{
                  experience_id: segment.experience_id,
                  parent_id: segment.id,
                }}
                libraryModalProps={libraryModalProps}
                isRoute={false}
                {...routeProps}
                config={{
                  list: {
                    shouldExecuteRequest: false,
                  },
                  //   routes: { fetch: false }, // don't fetch even if empty - in this case, always will be present in redux and getting from selector
                }}
              />
            </Suspense>
          )}
        />
      );
    }
  }
  if (isSegmentInAIList && nestingLevel === 1) {
    segmentsToRender = (
      <Route
          path={`${path}`}
          render={(routeProps) => (
            <Suspense fallback={<Spinner />}>
              <AISegments
                isSegmentInAIList={true}
                experienceViewMode="apEditTest"
                isRoute={false}
                // parentId={} // No parentId here so filtering based on nestingLevel 1 - see librarySegmentsSelector 
                activeTopicIdx={activeTopicIdx}
                parentId={segment.id}
                nestingLevel={nestingLevel + 1}
                {...routeProps}
                config={{
                  routes: { show: false },
                  core: {
                    setQueryParams: false, // not setting query params in the url
                  },
                  pagination: { show: false },
                  // itemDetail: { widget: SegmentItemDetail },
                  list: { forceReload: true, widget: SegmentList, shouldExecuteRequest: false,},
                
                }}
              />
            </Suspense>
          )}
        />
    )
  }

  return (
    // TODO: if segment.settings.is_game and segment.question_segment.game_type, and not createMode, then don't load Segments crud, load game component based on game_type. all nested_segments/levels would be in segment already.
    <Segment>
      {/* Render children CRUD */}
      {segmentsToRender}

      <SegmentItemFooter
        createMode={createMode}
        answerMode={answerMode}
        presentationMode={presentationMode}
        segmentFooterAttributes={segmentFooterAttributes}
        segmentSettings={segmentSettings}
        segmentStateSettings={stateSetting}
        // screenSettings={screenSettings}
        setRenderMath={setRenderMath}
        // saveTextAnswer={saveTextAnswer}
        // resetQuestion={resetQuestion}
        toggleAnswer={toggleAnswer}
        // showCorrectAnswer={showCorrectAnswer}
        childrenRenderProps={childrenRenderProps}
        showChildrenExplanations={true}
      ></SegmentItemFooter>
    </Segment>
  );
};
export default QuestionGroup;
