import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Space, Divider, Tooltip } from "antd";
// import MicrosoftLogin from "react-microsoft-login";
import "./Login.scss";
import MrReactFramework, { MrFormComponent, Spinner } from "mr_react_framework";
import * as Sentry from "@sentry/react";
import AuthLayout from "../../../components/Layouts/AuthLayout/AuthLayout";
import { GoogleLogin } from "react-google-login";
import ManageBacLogoImage from "../../../assets/images/managebac_logo_1.png";
import MBIconNew from "../../../assets/images/MB-icon-new.png";
import GoogleLogoImage from "../../../assets/images/google_logo.png";
import MicrosoftLogoImage from "../../../assets/images/microsoft_logo.png";
import AppleLogin from 'react-apple-login'
import { connect } from "react-redux";
import {
  loadingSelector,
  errorSelector,
  isAuthenticatedSelector,
  mbLoadingSelector,
  googleLoadingSelector,
  microsoftLoadingSelector,
  emailLoadingSelector,
  appRegionSelector
} from "./selector";
import {
  GoogleOutlined,
  ArrowRightOutlined
} from "@ant-design/icons";
import { actions } from "./redux";
import { Link } from "react-router-dom";
import MsLogin from "./MsLogin/MsLogin";
import { oAuthRedirectUrl } from "/src/lib/microsoft-login/authConfig";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { parseHashFromUrl } from "/src/lib/utils/validationHelper";
import { merge } from "lodash";
import CustomAppleLogin from "./CustomAppleLogin/CustomAppleLogin";
import { getUniqueId, checkMob, checkIPAD, getFromLS } from "/src/lib/utils/helperMethods";
import MrTranslate, { mrTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import RedirectToBrowser from "./RedirectToBrowser";
import EdlinkLogo from "../../../assets/images/edlink_logo.png";
import { getAppVersion, isNewAppVersion } from "/src/App/OfflineApp/offlineAppHelper";

// const MrForm = MrReactFramework.MrForm;
// import styles from "./Login.css";
const reactAppApiUrl = import.meta.env.VITE_API_URL;
const apiHost = reactAppApiUrl.replace("/api/v1", "");
const mbLoginUrl = `${apiHost}auth/managebac`;
const googleCreds = {
  // clientId: "583564624211-8t1c3nqmq30cbfgvabdrvhia25o82lvg.apps.googleusercontent.com",
  clientId: "583564624211-fv9lk1ossa45vcd60n5cdkscramesd1t.apps.googleusercontent.com",
  // clientSecret: ""
}
// production
// googleCreds.clientId= "583564624211-fv9lk1ossa45vcd60n5cdkscramesd1t.apps.googleusercontent.com";
const getLoginForm = (props) => {
  const { mrIntl } = props
  console.log("get login form=====>", props);

  return {
    processFormData: (dataProps, opts) => {
      console.log("dataProps", dataProps);
      console.log("opts", opts);
      opts.values.email = opts.values.email.toLowerCase().trim()
      // trim and downcase this
      return opts.values;
    },
    schema: {
      type: "object",
      properties: {
        email: {
          type: "string",
          format: "email",
          defaultValue: "sample@email.com",
          placeholder: mrIntl("Login.email"),
          className: "login-email",
          rules: [{
            required: true,
            message: mrIntl("Login.please_enter_a_valid_email"),
          }]
        },
        password: {
          type: "string",
          placeholder: mrIntl("CommonText.password"),
          // widget: Input.Password,
          widget: "PasswordWidget",
          className: "login-password",
          rules: [
            {
              // required: props.formState.values.id ? false : true,
              required: true,
              message: mrIntl("CommonText.please_enter_a_password"),
            },
            {
              min: 8,
              message: mrIntl("Login.minimum_pass_characters"),
            }
          ]
        },
      },
    },
    uiSchema: {
      password: {
        "ui:widget": "password",
      },
    },
    // email: {
    //   value: "sample@email.com",
    //   elementType: "input",
    //   group: 1,
    //   validationRules: [
    //     { required: true },
    //     { type: "email", message: "Not a valid email." }
    //   ],
    //   placeholder: "email",

    //   prefix: {
    //     type: "element",
    //     element: <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
    //   }
    // },
    // password: {
    //   value: "password",
    //   validationRules: [{ required: true }],
    //   type: "password",
    //   elementType: "input",
    //   group: 1,
    //   placeholder: "Passowrd",
    //   prefix: {
    //     type: "element",
    //     element: <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
    //   }
    // }
    // gender: {
    //   validationRules: [{ required: true }],
    //   type: "text",
    //   elementType: "select",
    //   group: 2,
    //   placeholder: "gender"
    // }
  };
};

const LoginText = () => {
  let loginText = []
  loginText.push("Login")
  // loginText.push(<ArrowRightOutlined style={{"marginLeft":"10px"}}/>)
  return loginText
}

// const googleConfig = {
//   uxMode: "redirect"
// }
// const microsoftConfig = {
//   redirec
// }
const defaultLoginConfig = {
  sso: {
    google: {
      redirectUri: `${oAuthRedirectUrl}/auth/login`
    },
    redirectMode: true
  }
}
class Login extends Component {
  constructor(props) {
    super(props);
    
    this.config = merge({}, defaultLoginConfig)
    // this.state = { gifts: [] };
    this.token = getFromLS("token");
    this.state = {
      shouldDisableSSOLoginButton: false,
      isInIframe: window !== window.parent,
    };
  }
  
  componentDidMount() {
    console.log("login extends===>", this.props);
    try {
      if(this.config.sso.redirectMode && this.props.location && this.props.location.hash){
        console.log( "login this.props.location.hash", this.props.location.hash );
        const parsedHash = parseHashFromUrl(this.props.location.hash);
        console.log( "login parsedHash", parsedHash );
        if(parsedHash){
          if(parsedHash.id_token){
            console.log( "login parsedHash.id_token", parsedHash.id_token );
            this.responseGoogle({
              tokenId: parsedHash.id_token,
              profileObj: {}
            })
          }
        }
      }
    } catch (error) {
      console.log("Sentry capture", error);
      Sentry.captureException(error);
    } 

    if (this.token) {
      this.handleSSOLogin();
    }
  }
  responseGoogle = (response) => {
    console.log( "login response", response );
    if(response){
      if(!response.error){
        console.log("response", response);
        this.props.onGoogleLogin(response);
      }
    }
  };
  responseMs = (response) => {
    console.log( "MS LOGIN response", response );
    if(response !== null){
      console.log("response", response);
      this.props.onMicrosoftLogin(response);
    }
  };
  errorMs = (error) => {
    console.error( "login MS LOGIN error", error );
    // this.props.onMicrosoftLoginFail(error);
  };
  oauthResponseCallback = (response) => {
    if(!response.error){
      console.log("response", response);
      this.props.onResponseLogin(response);
    }
  };

  handleSubmit = (params, opts = {}) => {
    this.props.onSubmitHandler(params);
  };
  
  handleMbLogin = () => {
    this.props.onManagebacLogin();
  };
  
  handleClick = () => {
    const baseApiUrl = import.meta.env.VITE_API_URL.replace('/api/v1/', '/');
    const redirect_uri = encodeURIComponent(`${baseApiUrl}oauth/edlink/callback`);
    const client_id = import.meta.env.VITE_EDLINK_CLIENT_ID
    const url = `https://ed.link/sso/login?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code`
    window.location.href = url;
  };

  handleSSOLogin = () => {
    this.setState({ shouldDisableSSOLoginButton: true });
    setTimeout(() => {
      this.setState({ shouldDisableSSOLoginButton: false });
    }, 5000);
  };

  render() {
    const styleProps = {
      submitBtn: {
        width: "100%",
      },
    };
    let mainContent = [];
    const TempForm = MrFormComponent;
    const jsonForm = getLoginForm(this.props);
    const extraGoogleLoginProps = {}
    const extraMSLoginProps = {}
    if(this.config.sso.redirectMode){
      extraGoogleLoginProps.uxMode = "redirect";
      extraMSLoginProps.forceRedirectStrategy = true;
    }
    const mainForm = []
    mainForm.push(<TempForm
      key={"mrForm"}
      {...this.props}
      visible={true}
      resourceForm={{form: {...jsonForm}, config: {isModal: false, visible: true}}}
      
      item={{}}
      // formConfig={{actions: {submitText: "Login", showCancelBtn: false}}}
      formConfig={{actions: {submitText: <LoginText />, showCancelBtn: false}}}
      formProps={{}}
      // jsonForm={jsonForm}
      // schema={jsonForm.schema}
      handleOk={this.handleSubmit}
      handleSubmit={this.handleSubmit}
      styleProps={styleProps}
    />)
    
    // if(import.meta.env.VITE_REGION != "china"){
      mainForm.push(<div key={"forgot-password-link"} className={"forgot-pass-link"}>
        <span><Link to={"/auth/forgot-password"}><MrTranslate id={"Login.forgot_password"}/></Link></span>
      </div>)
    // }
    // if(this.props.appType != "ios"){
    //   mainForm.push(<div key={"guest-join-code"} className={"guest-join-code-link"}>
    //     {/* <span><Link to={"/auth/guest-join-code"}>Start with join code</Link></span> */}
    //     <span><Link to={"/guest/join"}>Take test as a guest</Link></span>
    //   </div>)
    // }

    let loginBtns = []
    // const mbLoginUrl = "http://localhost:3003/oauth/managebac/authorize"
    // const mbLoginUrl = "http://localhost:3003/auth/managebac"
    loginBtns.push(
      <form
        action={mbLoginUrl}
        method={"get"}
        onSubmit={() => {
          this.handleSSOLogin();
          this.handleMbLogin();
        }}
      >
        <Button
          loading={this.props.mbLoading}
          onClick={() => {
            if (this.props.mbLoading) {
              return;
            }
          }}
          className="social-login-btn"
          htmlType={"submit"}
          disabled={
            !this.props.mbLoading && this.state.shouldDisableSSOLoginButton
          }
        >
          <img
            style={{ height: "20px", marginTop: "-3px" }}
            src={MBIconNew}
          ></img>
          <MrTranslate id={"Login.login_with_managebac"} />
        </Button>
      </form>
    );
    const shouldHideGoogleLogin = this.state.isInIframe;
    // if(this.props.appRegion !== "china" && (this.props.appType != "ios" || (this.props.appType == "ios" && (import.meta.env.VITE_MODE == "staging" || import.meta.env.VITE_MODE == "development")))){
    if (this.props.appRegion !== "china") {
      //on v3.2.0 When attempting to login using the Google SSO button in the iPad app, after entering credentials, the app fails to redirect back to the login screen and  displays the error: 403 disallowed_useragent, thats why we have approach to redirect the link to browser fro google soo

      // skip to redirect to Browser after v5.0.0 as sso fixed from native side
      const appVersion = getAppVersion(this.props.appType);
      if (this.props.appType === "ios" && (isNewAppVersion(appVersion,"3.2.0") && !isNewAppVersion(appVersion,"5.0.0"))) {
        loginBtns.push(<RedirectToBrowser {...this.props}/>)
      } else if (!shouldHideGoogleLogin) {
        // The `shouldHideGoogleLogin` condition is added to hide the Google login button in an iframe. 
        // However, this condition is not affecting the behavior in apps and SEB, and they are functioning as before.
        loginBtns.push(
          <GoogleLogin
            render={(renderProps) => (
              <Button
                loading={this.props.googleLoading}
                className="social-login-btn"
                onClick={() => {
                  if (this.props.googleLoading) {
                    return;
                  }
                  this.props.onGoogleLoginStart();
                  renderProps.onClick();
                  this.handleSSOLogin();
                }}
                disabled={renderProps.disabled || (!this.props.googleLoading && this.state.shouldDisableSSOLoginButton)}
              >
                <img
                  style={{
                    height: "20px",
                    marginTop: "-3px",
                  }}
                  src={GoogleLogoImage}
                ></img>
                <MrTranslate id={"Login.login_with_google"} />
              </Button>
            )}
            key="googleLogin"
            // clientId="758735174332-clv9t2m6qkf1g55vpnm1ibpakit8derg.apps.googleusercontent.com"
            // clientId="583564624211-fv9lk1ossa45vcd60n5cdkscramesd1t.apps.googleusercontent.com"
            clientId={googleCreds.clientId}
            prompt={"select_account"}
            // uxMode={"redirect"}
            // onAutoLoadFinished={this.responseGoogle}
            // onRequest={this.responseGoogle}
            // buttonText="Sign in with Google"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            // redirectUri="http://localhost:3005/omniauth/google/callback"
            redirectUri={this.config.sso.google.redirectUri}
            // redirectUri={"http://localhost:3000/auth/login"}
            {...extraGoogleLoginProps}
          // isSignedIn="true"
          />
        );
      }
    }
    // loginBtns.push(
    //   <MicrosoftLogin key={"msLogin"} clientId={"57cbe5ae-1b55-4049-b707-d7fef5eb0cf9"} authCallback={this.responseMs}
    //   className={"social-login-btn"}
    //   responseType={"code"}
    //   prompt={"select_account"}
    //   redirectUri={"http://localhost:3000/oauth/ms/callback"}>
    //     <Button loading={this.props.microsoftLoading} className="social-login-btn">
    //       <img style={{"height":"20px","marginTop":"-3px","paddingRight":"5px"}} src={MicrosoftLogoImage} alt={"Microsoft Logo"}/>
    //       Microsoft
    //     </Button>
    //   </MicrosoftLogin>
    // );

    loginBtns.push(
      <MsLogin
        key={"msLogin"}
        clientId={"57cbe5ae-1b55-4049-b707-d7fef5eb0cf9"}
        // authCallback={this.responseMs}
        successCallback={this.responseMs}
        errorCallback={this.errorMs}
        // forceRedirectStrategy={true}
        className={"social-login-btn"}
        // loginActions={actions}
        responseType={"code"}
        prompt={"select_account"}
        {...extraMSLoginProps}
        onMicrosoftLoginStart={this.props.onMicrosoftLoginStart}
        microsoftLoading={this.props.microsoftLoading}
      >
        <Button
          loading={this.props.microsoftLoading}
          className="social-login-btn"
          onClick={() => {
            if (this.props.microsoftLoading) {
              return;
            }
            this.handleSSOLogin();
          }}
          disabled={!this.props.microsoftLoading && this.state.shouldDisableSSOLoginButton}
        >
          <img
            style={{ height: "20px", marginTop: "-3px" }}
            src={MicrosoftLogoImage}
            alt={"Microsoft Logo"}
          />
          <MrTranslate id={"Login.login_with_microsoft"} />
        </Button>
      </MsLogin>
    );

    // loginBtns.push(
    //   <Button className="social-login-btn" onClick={this.handleClick} >
    //     <img
    //       style={{ height: "20px", marginTop: "-3px" }}
    //       src={EdlinkLogo}
    //     ></img>
    //     <MrTranslate id={"Login.login_with_edlink"} />
    //   </Button>
    // );
      
    const appleLoginCallback = (data) => {
      console.log( "apple login callback", data );

    }
    const appleLoginState = getUniqueId();
    // loginBtns.push(
    //   <AppleLogin key={"appleLoginBtn"} clientId={"com.assessprep.applessoclient"} redirectURI="https://api-dev2.assessprep.com/oauth/apple/callback" responseMode={"form_post"} scope={"name email"} state={appleLoginState} callback={appleLoginCallback} usePopup={false} />
    // );
    if(this.props.appType === "ios" || (this.props.appType != "ios" && (import.meta.env.VITE_MODE == "staging" || import.meta.env.VITE_MODE == "development"))){
      loginBtns.push(
        <CustomAppleLogin
          key={"customappleLoginBtn"}
          clientId={"com.assessprep.apple-sso-client"}
          redirectURI="https://api-dev2.assessprep.com/oauth/apple/callback"
          scope={"name email"}
          state={appleLoginState}
          disabled={this.state.shouldDisableSSOLoginButton}
        />
      );
    }

    // mainContent.push(<CustomOrHr />)
    // mainContent.push(<Divider plain style={{"marginTop":"0px"}}>Or Login With</Divider>)
    // mainContent.push()
    // return <AuthLayout top={"Hello"} main={mainContent} />;
    // return mainContent;
    return <>
      {!this.props.oauthLoading && mainForm}
      {this.props.oauthLoading && <Spinner />}
      {/* {import.meta.env.VITE_MODE != "demo" && <Divider plain style={{"marginTop":"0px"}}>Or Login With</Divider>} */}
      {import.meta.env.VITE_MODE != "demo" && <Divider plain style={{"marginTop":"10px"}}><MrTranslate id={"CommonText.or"}/></Divider>}
      {import.meta.env.VITE_MODE != "demo" && <Space direction={checkMob() || checkIPAD() || true ? "vertical" : "horizontal"} className={checkMob() || checkIPAD() || true ? "login-btns-vertical" : "login-btns-horizontal"}>{loginBtns}</Space>}
      
      
    </>
  }
}
const makeMapStateToProps = () => {
  const finalLoadingSelector = loadingSelector();
  const finalErrorSelector = errorSelector();
  const finalIsAuthenticatedSelector = isAuthenticatedSelector();
  const appRegionFinalSelector = appRegionSelector();
  const appTypeFinalSelector = appTypeSelector();
  
  const mapStateToProps = (state, props) => {
    return {
      // loading: finalLoadingSelector(state, props),
      loading: emailLoadingSelector(state),
      error: finalErrorSelector(state, props),
      //  bar: getBarState(state, props)
      isAuthenticated: finalIsAuthenticatedSelector(state, props),
      googleLoading: googleLoadingSelector(state),
      microsoftLoading: microsoftLoadingSelector(state),
      mbLoading: mbLoadingSelector(state),
      emailLoading: emailLoadingSelector(state),
      appRegion: appRegionFinalSelector(state),
      appType: appTypeFinalSelector(state)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitHandler: (params) => {
      let finalParams = {
        user: {
          ...params,
        },
      };

      dispatch(actions.create(finalParams));
    },
    onManagebacLogin: (params) => {
      dispatch(actions.mbCreateStart());
    },
    onMicrosoftLogin: (msOauthLoginObj) => {
      const msLoginData = {
        msLoginObj: msOauthLoginObj,
      };
      dispatch(actions.microsoftCreate(msLoginData));
    },
    onMicrosoftLoginStart: () => {
      dispatch(actions.microsoftCreateStart());
    },
    onGoogleLogin: (googleOauthObj) => {
      const googleLoginData = {
        googleLoginObj: googleOauthObj,
      };
      dispatch(actions.googleCreate(googleLoginData));
    },
    onGoogleLoginStart: () => {
      dispatch(actions.googleCreateStart());
    },
  };
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Login);

// export default Login;

// class Login extends React.Component {
//   handleSubmit = e => {
//     e.preventDefault();
//     this.props.form.validateFields((err, values) => {
//       if (!err) {
//         console.log("Received values of form: ", values);
//       }
//     });
//   };

//   render() {
//     const { getFieldDecorator, setFieldsValue } = this.props.form;
//     return (
//       <div id="components-form-demo-normal-login">
//         <Form onSubmit={this.handleSubmit} className="login-form">
//           <Form.Item>
//             {getFieldDecorator("userName", {
//               initialValue: "myfirstUsername",
//               rules: [
//                 { required: true, message: "Please input your username!" }
//               ]
//             })(
//               <Input
//                 prefix={
//                   <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
//                 }
//                 placeholder="Username"
//               />
//             )}
//           </Form.Item>
//           <Form.Item>
//             {getFieldDecorator("password", {
//               initialValue: "hellopassword",
//               rules: [
//                 { required: true, message: "Please input your Password!" }
//               ]
//             })(
//               <Input
//                 prefix={
//                   <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
//                 }
//                 type="password"
//                 placeholder="Password"
//               />
//             )}
//           </Form.Item>
//           <Form.Item>
//             {getFieldDecorator("remember", {
//               valuePropName: "checked",
//               initialValue: true
//             })(<Checkbox>Remember me</Checkbox>)}
//             <a className="login-form-forgot" href="">
//               Forgot password
//             </a>
//             <Button
//               type="primary"
//               htmlType="submit"
//               className="login-form-button"
//             >
//               Log in
//             </Button>
//             Or <a href="">register now!</a>
//           </Form.Item>
//         </Form>
//       </div>
//     );
//   }
// }

// const LoginForm = Form.create({
//   name: "login",
//   values: { userName: "hello123", password: "hellohellohello" }
// })(Login);

// export default LoginForm;
