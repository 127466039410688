// import { createSelector } from "reselect";
// import lodash from "lodash";

import { createSelector } from "reselect";

// const loadingState = (state, props) => {
//   return state.integrations.get("loading");
// };
// export const loadingSelector = () =>
//   createSelector([loadingState], (loading) => loading);

// const errorState = (state, props) => state.integrations.get("error");

// export const errorSelector = () =>
//   createSelector([errorState], (error) => error);

// const integrationsState = (state, props) =>
//   state.integrations.get("integrations");

// export const integrationsSelector = () =>
//   createSelector([integrationsState], (integrations) => integrations);

// const integrationsShowState = (state, props) => {

//   const integrations = state.integrations.get("integrations");

//   if (integrations.size === 0) {
//     return {};
//   } else {
//     const id = parseInt(props.match.params.id);
//     const idx = lodash.findIndex(integrations, {
//       id: id,
//     });
//     if (idx !== -1) {
//       return integrations[idx];
//     }
//     return {};
//   }
// };

// export const integrationsShowSelector = () =>
//   createSelector([integrationsShowState], (integrations) => integrations);

// const paginationState = (state, props) => {
//   const pageObj = {};
//   pageObj.page = state.integrations.get("page");
//   pageObj.totalPages = state.integrations.get("total_pages");

//   return pageObj;
// };

// export const paginationSelector = () =>
//   createSelector([paginationState], (pageObj) => pageObj);

export const integrationsState = (state) =>
  state.integrations.get("integrations", []);

export const integrationsSelector = () =>
  createSelector([integrationsState], (integrations) => integrations);