import React from "react";
import { Button, List } from "antd";
import { EllipsisOutlined, CheckCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { actions as orgActions } from "/src/views/Orgs/redux";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPlanStatus } from '../utils/helperMethods';
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import "./DummyListItemsForUpgradePlan.scss";
import { useTranslate } from '../MrTranslate/MrTranslate';

const DummyListItemsForUpgradePlan = (props) => {
  // const { } = props;
  const dispatch = useDispatch();
  const mrIntl = useTranslate();

  const dummyData = [
    {
      title: "Assessment - 3",
      description: "AP MYP Year 5 Criteria with points"
    },
    {
      title: "Assessment - 4",
      description: "AP MYP Year 5 Achievement level"
    }
  ];
    
  return (<div className="dummy-items-wrapper-class">
        <Button
          type="primary"
          className="dummy-list-items-btn"
          onClick={() => {
            dispatch(
              orgActions.setShowUpgradePlanModalSuccess({
                show: true,
                feature_code : "library_assessment_list_page",
              }
            ))
          }}
        >
          {mrIntl("ExperienceList.upgrade_to_see_more")}
        </Button>
        <List
          itemLayout="horizontal"
          className="custom-main-list experiences-list dummy-experience-list"
          dataSource={dummyData}
          renderItem={(item, index) => (
            <List.Item
             className="dummy-experience-list-item"
              actions={[
                <PlusOutlined key="add" />,
                <EllipsisOutlined key="more" />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <CheckCircleOutlined className="dummy-items-list-check-btn"/>
                }
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </div>)
  };

  export default DummyListItemsForUpgradePlan;