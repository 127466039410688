import React, { useEffect, useRef, useState } from "react";
import { Modal, Row, Tag, Space, Col, Empty, Skeleton, List, Alert, Button, Tooltip, Card } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import { UndoOutlined, CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { activeAdjustedExperienceSelector, aiAssistantSettingsSelector } from "/src/views/Experiences/selector";
import  { find, flattenDeep, includes, isEmpty } from "lodash";
import { fetchWithStream, getAIServiceBaseURL, getFromLS, availableLanguageOptionsForAI, removeHTMLTagsFromText, showSyllabusTopicFilterForDPSubjectsInAIModal, showSyllabusTopicFilterForMYPSubjectsInAIModal, updateConfigHeaderForDemoUseStagingAI, getUniqueId, getDestinationPositionLex } from "/src/lib/utils/helperMethods";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import "./GenerateAIWithPrompt.scss"
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import MagicButton from "./MagicButton";
import { commandTermsSelector } from "/src/App/genericSelector";
import { aiModelSelector } from "/src/views/Orgs/selector";
import ModalTitle from "./ModalTitle";
import ModalFooter from "./ModalFooter";
import SelectParametersForGenerate from "./SelectParametersForGenerate";
import { allPaperTypesSelector, allTeachingLevelsSelector, globalContextsSelector, keyConceptsSelector, possibleExplorationsSelector, relatedConceptsSelector, atlSkillsSelector, aiGenerateQuestionsConfigSelector, aiSegmentsSelector, aiGenerateModeSelector, aiGenerateLoadingSelector } from "./selector";
import { initializeAiGenerateQuestionsConfig, convertPDFToImages, findSyllabusTopicKey, getDetailsFromIds, groupByParent, isNewGenerateQuestionsWithAI, questionTypesRequiringAnswers } from "./generateAIHelperMethods";
import SegmentContentDisplay from "./SegmentContentDisplay";
import { actions as aiSegmentActions } from "./redux"
import WithTooltip from "/src/components/WithTooltip/WithTooltip";
import { FaRegStopCircle } from "react-icons/fa";
import SelectAIOutputLanguage from "./SelectAIOutputLanguage";
import { aiQuestionTypesAllowedToAppend } from "./generateAIHelperMethods";
import { segmentsCreateLoadingSelector } from "../selector";
import AISegmentsWrapper from "../SegmentList/AISegmentsWrapper";
import { getSegmentObject } from "./questionTypeObjects";
import { activeTopicIdSelector } from "../Topics/selector";
import AIAssistantSettings from "./AIAssistantSettings";



const GenerateAIWithPrompt = () => {
  const dispatch = useDispatch();
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const currentUser = useSelector(currentUserSelector())
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const activeExperienceRubricTypeC = activeExperience && activeExperience.rubric && activeExperience.rubric.type_c
  const isMYPAL = activeExperienceRubricTypeC === "myp_achievement_level";
  const isCWP = activeExperienceRubricTypeC === "criteria_with_points";
  const newGenerateQuestionsWithAI = isNewGenerateQuestionsWithAI(enabledFeatures);
  
  const aiGenerateQuestionsConfig = useSelector(aiGenerateQuestionsConfigSelector());
  const segmentsCreateLoading = useSelector(segmentsCreateLoadingSelector())
  const aiGenerateMode = useSelector(aiGenerateModeSelector())
  const aiGenerateLoading = useSelector(aiGenerateLoadingSelector());
  const activeTopicId = useSelector(activeTopicIdSelector())
  const aiAssistantSettings = useSelector(aiAssistantSettingsSelector());
  const {
    segment = {},
    extractFromPdf = false,
    createCondition,
    nestingLevel,
    uploadedPdf,
    totalAIQuestionsGenerated=0,
    addAllButtonLoading=false,
    lastAISegmentPosition
  } = aiGenerateQuestionsConfig;
  


  console.log("GenerateAIWithPrompt==>", aiGenerateQuestionsConfig)

  
  const aiModel = useSelector(aiModelSelector()); 
  const mrIntl= useTranslate()
  // Old code
  // const [visible, setVisible] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [taskAdded, setTaskAdded] = useState(false);
  // const [generatedQuestions, setGeneratedQuestions] = useState([]);

  const allPaperTypes = useSelector(allPaperTypesSelector());
  const allTeachingLevels = useSelector(allTeachingLevelsSelector());
  const keyConcepts = useSelector(keyConceptsSelector());
  const relatedConcepts = useSelector(relatedConceptsSelector());
  const globalContexts = useSelector(globalContextsSelector());
  const possibleExplorations = useSelector(possibleExplorationsSelector());
  const atlSkills = useSelector(atlSkillsSelector());

  const aiSegments = useSelector(aiSegmentsSelector());
  console.log("aiSegments check ===>", typeof(aiSegments));
  

  const maxGeneratedQuestions = 10;
  let text_to_process = ""
  let question_type = "long_answer"
  // let category = "similar";
  let videoUrl = "";
  // let imageUrl = "";
  // let pdfUrl = "";
  let similarTo;
  // let prompt;

  let programmeLabel;
  let orgProgrammeLabel;
  let subjectLabel;
  let gradeLabel;
  if(activeExperience && activeExperience.questionbank && activeExperience.questionbank.custom_fields) {
    programmeLabel = activeExperience.questionbank.custom_fields.programme_name
  }
  if(activeExperience &&
    activeExperience.custom_fields) {
    orgProgrammeLabel = activeExperience.custom_fields.org_programme_item_label
    subjectLabel = activeExperience.custom_fields.subject_item_label
    gradeLabel = activeExperience.custom_fields.grade_item_label
  }

  const segmentType = segment.segment_type;
  const resourceSegmentAttributes = segment.resource_segment_attributes || {};
  const textSegmentAttributes = segment.text_segment_attributes || {}
  const resourceType = resourceSegmentAttributes.resource_type;

  const segmentResourceAttachments =
    (segmentType === "resource" &&
    resourceSegmentAttributes.resource_json?.segment_data?.attachments) || [];

  const outputLanguageDisabled = aiGenerateLoading || extractFromPdf

  const resetSources = () => {
    setConvertedImages([]);
    setSourcePdfs([]);
    setSourceImages([]);
    setSourceText(null);
  };

  const handlePdfUpload = (pdfFiles) => {
    if (pdfFiles && pdfFiles.length > 0) {
      handleSavePDFs(pdfFiles, true);
      setPdfMediaFormSetting(prev => ({
        ...prev,
        fileList: pdfFiles,
        visible: false, 
        modal: false,
        selectConfig: {
          ...prev.selectConfig,
          defaultFileList: pdfFiles,
          type: "pictures-wall",
          disabled: true
        },
      }));
    }
  };
  if (["similar", "based_on"].includes(createCondition)) {
    if (segmentType === "question") {
      const { question_type: questionType, content: segmentContent } = segment.question_segment_attributes;
      question_type = questionType.includes("mcq") ? "mcq" : "long_answer";
      text_to_process = removeHTMLTagsFromText(segmentContent, true);
      similarTo = questionType === "cke_subjective" ? text_to_process : undefined;
    } else if (segmentType === "text") {
      text_to_process = removeHTMLTagsFromText(textSegmentAttributes.content, true);
      question_type = "long_answer";
    } 
    else if (segmentType === "resource") {
      const attachment = segmentResourceAttachments[0];
      if (resourceType === "video") {
        videoUrl = attachment?.url;
        question_type = "long_answer";
      }
    }
  } else {
    question_type =
      createCondition === "new" &&
      (isMYPAL || isCWP) &&
      nestingLevel === 1 &&
      !extractFromPdf &&
      (currentUser.role === "qb_author" || enabledFeatures.all_question_types_with_ai)
        ? "task"
        : "long_answer";
  }
  
  // const [itemType, setItemType] = useState([])
  // const [syllabuscontentsId, setSyllabusContentsId] = useState("")
  // setting null or "" uses the value in dataToPost - setting nothing - undefined - doesn't get included in object
  const points = segment.points
  let defaultMarks = 0
  if (activeExperienceRubricTypeC === "points") {
    if (points && createCondition === "similar") {
      defaultMarks = points
    } else {
      defaultMarks = 3
    }
  }
  // let streamJson = (import.meta.env.VITE_MODE !== "production" && import.meta.env.VITE_MODE !== "china_production") ? true : false;
  let streamJson = false; // page gets stuck - too much re-rendering i think
  // const defaultMarks = activeExperienceRubricTypeC === "points" ? segment && segment.points && createCondition === "similar" ? segment.points : 3 : 0;
  
  // const [difficultyLevel, setDifficultyLevel] = useState()
  // const [difficultyLevelId, setDifficultyLevelId] = useState()
  const [teachingLevel, setTeachingLevel] = useState();
  const [teachingLevelId, setTeachingLevelId] = useState();
  const [paperType, setPaperType] = useState();
  const [paperTypeId, setPaperTypeId] = useState()
  const [syllabusTopicLabel, setSyllabusTopicLabel] = useState();
  const [syllabusTopicId, setSyllabusTopicId] = useState()
  const [commandTerm, setCommandTerm] = useState()
  const [commandTermId, setCommandTermId] = useState()
  const [criteria, setCriteria] = useState()
  // const [criteriaForStrands, setCriteriaForStrands] = useState([])
  const [marks, setMarks] = useState(defaultMarks); // not null but 0 default for MYP
  const allCommandTerms = useSelector(commandTermsSelector())
  const enableStandardsContentAreaTagMultiple = enabledFeatures.enable_standards_content_area_tag_multiple
  // const allSyllabusTopics =
  //   activeExperience.standards_content_area_tag &&
  //   activeExperience.standards_content_area_tag.final_json
  //     ? activeExperience.standards_content_area_tag.final_json.data
  //     : [];
  // const allSyllabusTopics = activeExperience?.tagged_standards_content_areas_data?.tag?.final_json?.data || [];
  let allSyllabusTopics = [];
  const experienceTaggedStandardsContentAreasData = activeExperience?.tagged_standards_content_areas_data;

  if (enableStandardsContentAreaTagMultiple) {
    experienceTaggedStandardsContentAreasData?.forEach((item) => {
      const itemTag = item?.tag || {};
      const itemTagFinalJson = itemTag.final_json || {};
      const itemTagFinalJsonData = itemTagFinalJson.data || [];
      if (itemTagFinalJsonData) {
        allSyllabusTopics.push(...itemTagFinalJsonData);
      }
    })
  } else {
    allSyllabusTopics = experienceTaggedStandardsContentAreasData?.tag?.final_json?.data
  }
  // const defaultQuestionType =
  //   createCondition === "new" &&
  //   (isMYPAL || isCWP) &&
  //   (isDevelopmentOrStagingMode || currentUser.role === "qb_author") &&
  //   !extractFromPdf
  //     ? "task"
  //     : question_type;
  const [promptUsed, setPromptUsed] = useState("")
  const [extractQuestions, setExtractQuestions] = useState(false)
  const [tokensUsed, setTokensUsed] = useState(0)
  const [requestCost, setRequestCost] = useState(0)
  const [questionsCount, setQuestionsCount] = useState(3);
  const [qbUUID, setQbUUID] = useState();
  const [useExamples, setUseExamples] = useState(false);
  const [useCustomExamples, setUseCustomExamples] = useState(false);
  const [useCustomPrompt, setUseCustomPrompt] = useState(false);
  const [customPrompt, setCustomPrompt] = useState("");
  const [customExamples, setCustomExamples] = useState("");
  const [additionalInstructions, setAdditionalInstructions] = useState("")
  const [selectStrands, setSelectStrands] = useState();
  // const defaultQuestionType =
  //   createCondition === "new" &&
  //   (isMYPAL || isCWP) &&
  //   (isDevelopmentOrStagingMode || currentUser.role === "qb_author") &&
  //   !extractFromPdf
  //     ? "task"
  //     : question_type;
  const [questionType, setQuestionType] = useState(question_type);
  // const [sourceBasedOn, setSourceBasedOn] = useState("");
  const [sourceText, setSourceText] = useState();
  const [sourceImages, setSourceImages] = useState([]);
  const [sourcePdfs, setSourcePdfs] = useState([])
  const [isStreaming, setIsStreaming] = useState(false)
  const sseRef = useRef(null);
  const skeletonRef = useRef(null);
  const [error, setError] = useState(false);
  const [keyConceptsIds, setKeyConceptsIds] = useState([]);
  const [relatedConceptsIds, setRelatedConceptsIds] = useState([]);
  const [globalContextId, setGlobalContextId] = useState(null);
  const [possibleExplorationId, setPossibleExplorationId] = useState(null);
  const [atlSkillsIds, setAtlSkillsIds] = useState([]);
  // const [addAllButtonLoading, setAddAllButtonLoading] = useState(false);

  const [convertedImages, setConvertedImages] = useState([])
  const [language, setLanguage] = useState("English")

  let outputLanguage = enabledFeatures.ai_assistant_settings ? aiAssistantSettings?.authoring?.output_language : language;
  console.log("1035 line nmo=>", sourcePdfs)

  useEffect(() => {
    return () => {
      // Reinitialize AI question generation configuration when switching experience sections.
      // This ensures AI Space is closed if active. The condition restricts this behavior 
      // to "draft" status, as AI Space is only accessible in draft experiences.
      if (newGenerateQuestionsWithAI && activeExperience.status === "draft") {        
        initializeAiGenerateQuestionsConfig(dispatch);
      }
    };
  }, [activeTopicId, activeExperience.status]);

  useEffect(() => {
    if (sourcePdfs.length === 0) {
      setShowError(false);
    }
  }, [sourcePdfs.length])

  const imageMediaFormInitialState = {
    modal: true,
    loading: false,
    visible: false,
    from: 'computer',
    currentMediaType: '',
    okDisabled: true,
    triggerSaveAction: 0,
    limit: 10,
    fileUploading: true,
    supportedTypes: "image/jpg, image/jpeg, image/png, image/gif, image/bmp",
    supportedFormats: 'jpg, jpeg, png, gif, bmp',
    cancelText: "Cancel",
    selectConfig: {
      multiple: true,
      maxSize: "20 MB",
      hide_uploader: true
    },
    onRemoveFile: (file) => {
      console.log("onRemoveFile==>", file);
        setSourceImages(prevImages => prevImages.filter(f => f.id !== file.id));
    }
  }
  const pdfMediaFormInitialState = {
    modal: true,
    loading: false,
    visible: false,
    from: 'computer',
    currentMediaType: '',
    okDisabled: true,
    triggerSaveAction: 0,
    limit: 10,
    fileUploading: true,
    cancelText: "Cancel",
    selectConfig: {
      multiple: true,
      maxSize: "30 MB",
      hide_uploader: true

    },
    supportedTypes: "application/pdf",
    supportedFormats: 'pdf',
    onRemoveFile: (file) => {
      console.log("onRemoveFile==>", file);
      setSourcePdfs(prevPDFs => prevPDFs.filter(f => f.id !== file.id))
      // Removing the converted images of the pdf which is deleted
      setConvertedImages(prevImages => prevImages.filter(f => f.parentId !== file.id ))

    }
  }
  const [imageMediaFormSetting, setImageMediaFormSetting] = useState(imageMediaFormInitialState);
  const [pdfMediaFormSetting, setPdfMediaFormSetting] = useState(pdfMediaFormInitialState);

    
  // useEffect(() => {
  //   const settingsMap = {
  //     pdf: {
  //       supportedTypes: "application/pdf",
  //       supportedFormats: 'pdf',
  //       selectConfig: {
  //         ...mediaFormSetting.selectConfig,
  //         maxSize: "30 MB",
  //       }
  //     },
  //     image: {
  //       supportedTypes: "image/jpg, image/jpeg, image/png, image/gif, image/bmp",
  //       supportedFormats: 'jpg, jpeg, png, gif, bmp',
  //       selectConfig: {
  //         ...mediaFormSetting.selectConfig,
  //         maxSize: "20 MB",
  //       }
  //     },
  //   };
  
  //   setMediaFormSetting({
  //     ...mediaFormSetting,
  //     ...settingsMap[sourceBasedOn],
  //   });
  // }, [sourceBasedOn]);
  
  const updateSelectConfigDisabled = (setConfig, config, disabled) => {
    // Disabled in handlePdfUpload as we don't want file to be deletable in case of extractFromPdf
    if(!extractFromPdf) {
      setConfig({
        ...config, 
        selectConfig: {
          ...config.selectConfig, 
          disabled: disabled
        }
      });
    }
  };  

  const handleSaveImages = (files, createConditionChanged=false) => {
    if (files && files.length > 0) {
      if(createConditionChanged) {
        setShowError(false);
        setSourceImages(files);
        return;
      }
      setShowError(false);
      setSourceImages(prevImages => [...prevImages , ...files]);
    }
  }
  const handleSavePDFs = async (files, createConditionChanged=false) => {
    if (files && files.length > 0) {
      setShowError(false);
      if(createConditionChanged) {
        setSourcePdfs(files);
      } else {
        setSourcePdfs(prevPDFs => [...prevPDFs, ...files]);
      }
      setIsModalOpen(true);
      // TODO: send pdf for conversion - get images in response (max 25) and show those images to select which pages to use for extraction - no need for  from and to page then
      try {
        const results = await Promise.all(files.map(async file => {
          try {
            const result = await convertPDFToImages(file.url);
            const images = result[0].data.image_urls.map((item) => ({
              id: getUniqueId(),
              checked: true,
              url: item,
              parentId: file.id
            }));
            return images;
          } catch (error) {
            console.error("Error converting PDF to images:", error);
            message.error("Something went wrong. Please try again!")
            initializeAiGenerateQuestionsConfig(dispatch)
            return [];
          }
        }));
  
        const tempConvertedImages = results.flat(); // Flatten the array of arrays
        console.log("tempConvertedImages ===>", tempConvertedImages);
        if (createConditionChanged) {
          setConvertedImages(tempConvertedImages);
        } else {
          setConvertedImages(prevImages => [...prevImages, ...tempConvertedImages]);
        }
      } catch (error) {
        console.error("Error in processing all PDFs:", error);
        message.error("Something went wrong. Please try again!")
        initializeAiGenerateQuestionsConfig(dispatch)
      }
    }
  }

  const handleSaveFiles = (files, type) => {
    const mediaConfig = {
      image: {
        mediaFormSetting: imageMediaFormSetting,
        setMediaFormSetting: setImageMediaFormSetting,
        sourceMedia: sourceImages,
        handleSave: handleSaveImages,
      },
      pdf: {
        mediaFormSetting: pdfMediaFormSetting,
        setMediaFormSetting: setPdfMediaFormSetting,
        sourceMedia: sourcePdfs,
        handleSave: handleSavePDFs,
      },
    };
  
    const { mediaFormSetting, setMediaFormSetting, sourceMedia, handleSave } = mediaConfig[type] || {};
  
    if (!mediaFormSetting || !setMediaFormSetting || !sourceMedia || !handleSave) {
      console.error(`Unsupported media type: ${type}`);
      return;
    }
  
    const sourceMediaIds = new Set(sourceMedia.map((media) => media.id));
    const newFiles = files.filter((file) => !sourceMediaIds.has(file.id));
  
    handleSave(newFiles);
  
    setMediaFormSetting({
      ...mediaFormSetting,
      modal: false,
      visible: false,
      selectConfig: {
        ...mediaFormSetting.selectConfig,
        type: "pictures-wall",
      },
    });
  };
  
  const resetFilters = () => {
    if (isCWP || isMYPAL) {
      setCriteria();
      setSelectStrands();
      setQuestionsCount(3)
      if (isMYPAL) {
        setRelatedConceptsIds([]);
        setKeyConceptsIds([]);
        setAtlSkillsIds([]);
        setGlobalContextId(null);
        setPossibleExplorationId(null);
      }
    }
    if(activeExperienceRubricTypeC === "points") {
      setMarks(3)
    }
    setQuestionType(question_type)
    setAdditionalInstructions("");
    setShowError(false);
    // let checkedConvertedImages = convertedImages.map(convertedImage => {
    //   return {...convertedImage, checked: true}
    // })
    // if(sourceBasedOn === "pdf") {
    //   setConvertedImages(checkedConvertedImages)
    // }
    // if(!extractQuestions) {
    //   setSourceBasedOn("");
    // }
    setSyllabusTopicId();
    setCommandTerm();
    setPaperTypeId();
    setTeachingLevelId();
    setQbUUID();
    setUseExamples(false);
    setCustomPrompt("");
    setUseCustomPrompt(false);
  };


  useEffect(() => {
    error && setError(false)
    if(uploadedPdf && uploadedPdf.length > 0) {
      setExtractQuestions(true)
    } else {
      setExtractQuestions(false)
    }
    if (activeExperience && activeExperience.custom_fields) {
      console.log("line 1171=>", activeExperience.custom_fields.subject_item_label, availableLanguageOptionsForAI)
     
      if (includes(availableLanguageOptionsForAI, activeExperience.custom_fields.subject_item_label)) {
        setLanguage(activeExperience.custom_fields.subject_item_label)
      }
    }

    if (segment && createCondition === "similar") {
      setQuestionType(question_type)
      // need to clear stands when change question as we are re configuring them in selectStrandsForm according to the segment
      if(isMYPAL || isCWP) {
        setSelectStrands()
      }
      let firstRelationItem =
        segment.relation_items && segment.relation_items[0];
      let updatedCommandTerm
      // let difficultyLevelId;
      // let difficultyLevelLabel;
      let teachingLevelId;
      let teachingLevelLabel;
      // let paperType;

      if (firstRelationItem) {
        if (
          firstRelationItem.command_term_items &&
          firstRelationItem.command_term_items.length > 0
        ) {
          updatedCommandTerm = firstRelationItem.command_term_items[0].label;
          setCommandTerm(updatedCommandTerm);
        } else {
          setCommandTerm()
        }
        // if (firstRelationItem.difficulty_level_tag_ids && firstRelationItem.difficulty_level_tag_ids.length > 0) {
        //   difficultyLevelId = firstRelationItem.difficulty_level_tag_ids[0];
        //   setDifficultyLevelId(difficultyLevelId)
        // }
        // if (firstRelationItem.difficulty_level_items && firstRelationItem.difficulty_level_items.length > 0) {
        //   difficultyLevelLabel = firstRelationItem.difficulty_level_items[0].label;
        //   setDifficultyLevel(difficultyLevelLabel)
        // }
        if (firstRelationItem.teaching_level_tag_ids && firstRelationItem.teaching_level_tag_ids.length > 0) {
          teachingLevelId = firstRelationItem.teaching_level_tag_ids[0];
          setTeachingLevelId(teachingLevelId)
        } else {
          setTeachingLevelId()
        }
        if (firstRelationItem.teaching_level_items && firstRelationItem.teaching_level_items.length > 0) {
          teachingLevelLabel = firstRelationItem.teaching_level_items[0].label;
          setTeachingLevel(teachingLevelLabel)
        } else {
          setTeachingLevel()
        }

        if (
          firstRelationItem.syllabus_items &&
          firstRelationItem.syllabus_items.length > 0
        ) {
          let syllabusItem = firstRelationItem.syllabus_items[0] || {};
          setSyllabusTopicLabel(syllabusItem.label);
          setSyllabusTopicId(syllabusItem.tag_id);
        } else {
          setSyllabusTopicId()
          setSyllabusTopicLabel()
        }
      }
      // console.log("updatedCommandTerm==>", difficultyLevel, teachingLevel)

      if (activeExperience && activeExperience.relation_items) {
        const firstActiveExperienceRelation =
          activeExperience.relation_items[0];

        if (
          firstActiveExperienceRelation &&
          firstActiveExperienceRelation.paper_type_items &&
          firstActiveExperienceRelation.paper_type_items.length > 0
        ) {
          setPaperType(firstActiveExperienceRelation.paper_type_items[0].label);
        }

        // if (
        //   firstActiveExperienceRelation &&
        //   firstActiveExperienceRelation.teaching_level_items &&
        //   firstActiveExperienceRelation.teaching_level_items.length > 0
        // ) {
        //   setTeachingLevel(
        //     firstActiveExperienceRelation.teaching_level_items[0].label
        //   );
        // }
      }
      resetSources();
    }
      if(createCondition === "new") {
        resetFilters();
        setSourceText("");
        setSourceImages([]);
        if (extractFromPdf) {
          const updatedPDFs = flattenDeep(uploadedPdf)
          handlePdfUpload(updatedPDFs);
        } else {
          setConvertedImages([]);
          setSourcePdfs([]);        
        }
      }
      if (createCondition === "based_on") {
        resetFilters();
        if (resourceType === "pdf") {
          handlePdfUpload(segmentResourceAttachments);
        } else {
          setConvertedImages([]);
          setSourcePdfs([]);
        }
        if (resourceType === "image") {
          handleSaveImages(segmentResourceAttachments, true);
        } else {
          setSourceImages([]);
        }
      }
  }, [createCondition, uploadedPdf, resourceType, segmentType, segment.id]);

  useEffect(() => {
    if (skeletonRef.current) {
      skeletonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [aiGenerateLoading]);

  useEffect(() => {
    if (activeExperienceRubricTypeC === "points") {
      if (questionType === "mcq") {
        setMarks(1);
      } else {
        setMarks(3);
      }
    }
  }, [questionType]);


  // const [showAdditionalInstructions, setShowAdditionalInstructions] = useState(false);
  const shouldAppendOnGenerateMore = aiQuestionTypesAllowedToAppend.includes(questionType) && !extractQuestions;

  // let disableOptions = false;
  // if (!createNew) {
  //   if (segmentType === "question") {
  //     disableOptions = true;
  //   }
  // }
  const currentSubjectLabel =
    activeExperience &&
    activeExperience.custom_fields &&
    activeExperience.custom_fields.subject_item_label;
  const isMYPSubjectSyllabusTopicFilterAvailable =
    currentSubjectLabel &&
    showSyllabusTopicFilterForMYPSubjectsInAIModal.some(
      (item) => item.toLowerCase() === currentSubjectLabel.toLowerCase()
    );
  const isDPSubjectSyllabusTopicFilterAvailable =
    currentSubjectLabel &&
    showSyllabusTopicFilterForDPSubjectsInAIModal.some(
      (item) => item.toLowerCase() === currentSubjectLabel.toLowerCase()
    );

  const isMYPProgram =
    activeExperience.questionbank &&
    activeExperience.questionbank.custom_fields.programme_name
      ? activeExperience.questionbank.custom_fields.programme_name.includes(
          "IB Middle Years"
        )
      : activeExperience.custom_fields && activeExperience.custom_fields.org_programme_item_label &&
        activeExperience.custom_fields.org_programme_item_label.includes(
          "IB Middle Years"
        );

  const isDPProgram =
    activeExperience.questionbank &&
    activeExperience.questionbank.custom_fields.programme_name
      ? activeExperience.questionbank.custom_fields.programme_name.includes(
          "IB Diploma"
        )
      : activeExperience.custom_fields && activeExperience.custom_fields.org_programme_item_label &&
        activeExperience.custom_fields.org_programme_item_label.includes(
          "IB Diploma"
        );
  let taggedStandardsContentAreasData = enableStandardsContentAreaTagMultiple ? experienceTaggedStandardsContentAreasData?.[0] : experienceTaggedStandardsContentAreasData

  const appendLabels = (baseLabel, ...labels) => {
    return labels.reduce((acc, label) => {
      return label ? acc?.concat(" - ", label) : acc;
    }, baseLabel);
  };
  if (!isMYPProgram && !isDPProgram) {
    const getOrgProgrammeLabel = (data = {}) => {
      return appendLabels(
        data.standard_collection_label,
        data.tag?.label,
        data.grade_group_label
      );
    };

    if (enableStandardsContentAreaTagMultiple) {
      if (syllabusTopicId) {
        // Find the matching syllabus topic label      
        const taggedItem = experienceTaggedStandardsContentAreasData.find(item => {
          const data = item.tag?.final_json?.data || [];
          return findSyllabusTopicKey(data, syllabusTopicId, "label");
        });
        if (taggedItem) {
          taggedStandardsContentAreasData = taggedItem;
          orgProgrammeLabel = getOrgProgrammeLabel(taggedItem);
        }
      } else {
        // Default to the first item if syllabusTopicId is not provided
        taggedStandardsContentAreasData = experienceTaggedStandardsContentAreasData?.[0];
      }
    } else {
      if (taggedStandardsContentAreasData?.standard_collection_label) {
        orgProgrammeLabel = getOrgProgrammeLabel(taggedStandardsContentAreasData)
      }
    }
  }
  
  // Old code
  // let isFullScreenModal = (segmentType === "resource" && resourceSegmentAttributes.resource_type === "video") || (segmentType === "text" && textSegmentAttributes.content && textSegmentAttributes.content)
  // Old code
  // let showGenerateAIButton =
  //   createCondition === "new" ||
  //   segmentType === "text" ||
  //   (segmentType === "question" &&
  //     segment.question_segment_attributes &&
  //     segment.question_segment_attributes.question_type === "cke_subjective") ||
  //   (segmentType === "resource" &&
  //     resourceSegmentAttributes.resource_type === "video") ||
  //   (segmentType === "resource" &&
  //     resourceSegmentAttributes.resource_type === "image") ||
  //   (segmentType === "resource" &&
  //     resourceSegmentAttributes.resource_type === "pdf");

  // Old code
  // const textToRender = (
  //   segmentType === "resource" && resourceSegmentAttributes.resource_type === "video" &&
  //   resourceSegmentAttributes.resource_json.segment_data
  //     .attachments[0] &&
  //   resourceSegmentAttributes.resource_json.segment_data
  //     .attachments[0].transcript &&
  //   resourceSegmentAttributes.resource_json.segment_data
  //     .attachments[0].transcript) || (segmentType === "text" && textSegmentAttributes.content && textSegmentAttributes.content)

  // const mediaPlayer = useRef(null);


  console.log('empty segment==>', isEmpty(segment))
  const skeletonDataSource = [...Array(3).keys()];
  // const isContentExistForAllQuestions = generatedQuestions.filter((q) => q.question !== "" && q.hasOwnProperty("question")).length > 0;
  // const isCorrectAnswerExistForAllQuestions = questionType === "mcq" ? generatedQuestions.every(q => q.hasOwnProperty("answer") && q.answer !== "" && q.options.includes(q.answer)) : true;
  // const shouldShowQuestions = isContentExistForAllQuestions && isCorrectAnswerExistForAllQuestions;

  // TODO : change activeExperience.rubric.type_c to ExperienceRubricTypeC on merge
  const isCommentsOnly = activeExperienceRubricTypeC === "comments_only";
  const isBasedOnPdf = createCondition === "based_on" && segmentType === "resource" && resourceType === "pdf";
  // Old code
  // const tooltipTitle =
  //   createCondition === "new"
  //     ? sourceBasedOn === "pdf"
  //       ? mrIntl("GenerateAIWithPrompt.extract_from_pdf")
  //       : mrIntl("GenerateAIWithPrompt.auto_generate_question_title")
  //     : segmentType === "text"
  //       ? mrIntl("GenerateAIWithPrompt.generate_questions_from_this_text_title")
  //       : resourceType
  //         ? mrIntl("GenerateAIWithPrompt.generate_questions_from_this_title", { resourceType: resourceType })
  //         : mrIntl("GenerateAIWithPrompt.generate_similar_questions");

  const promptConfig = {
    // showExtractQuestionsSwitch: ((enabledFeatures.ai_generate_questions_from_pdf || isDevelopmentOrStagingMode) && isBasedOnPdf && !uploadedPdf) && !useCustomPrompt,
    showQuestionType: (isMYPProgram || isDPProgram) || true, // enabling for all programmes
    showCriteria: isMYPProgram && activeExperienceRubricTypeC !== "comments_only" && !useCustomPrompt,
    showDPFilters: false && isDPProgram && !useCustomPrompt && !extractQuestions,
    showPointsInput: activeExperienceRubricTypeC === "points" && !useCustomPrompt,
    // showSyllabusTopicsDropdown: ((isMYPProgram && isMYPSubjectSyllabusTopicFilterAvailable) || (isDPProgram && isDPSubjectSyllabusTopicFilterAvailable) || taggedStandardsContentAreasData?.tag) && !useCustomPrompt && !extractQuestions, 
    showSyllabusTopicsDropdown: (taggedStandardsContentAreasData?.tag) && !useCustomPrompt && !extractQuestions, 
    showCommandTermDropdown: (isMYPProgram || isDPProgram) && !useCustomPrompt && !extractQuestions,
    showAdditionalInstructions: !useCustomPrompt && !extractQuestions,
    showCustomPromptCheckbox: currentUser.role === "qb_author" && !extractQuestions,
    showUseExamplesCheckbox: currentUser.role === "qb_author" && !useCustomPrompt && !extractQuestions,
    showTokenUsageCost: currentUser.role === "qb_author",
    showPromptButton: currentUser.role === "qb_author",
    showConceptsFilter: isMYPAL && currentUser.role === "qb_author" && !extractQuestions && !useCustomPrompt, // do we want this for all question or just for task? @SHIKHAR @UTTAM
    showNumberOfQuestionsInput: questionType === "task" && !extractQuestions && !useCustomPrompt,
  };

 
  const showModal = () => {
    // setVisible(true);
    if(createCondition === "new"){
      setCommandTerm()
      setSyllabusTopicLabel();
      setSyllabusTopicId();
      setPaperTypeId()
      // setDifficultyLevelId()
      setTeachingLevelId()
    }
  };

  const handleOk = (e) => {
    console.log("handleOk==>", e);
    initializeAiGenerateQuestionsConfig(dispatch)
  };

  const handleCancel = () => {
    // setShowPromptUsed(false) // IMP: - need to check this in QB author if this state is resetting or not
    // setVisible(false);
    // setGeneratedQuestions([]);
    resetFilters();
    setIsStreaming(false);
    console.log("sseRef.current =====>", sseRef);
    if (sseRef.current) {
      // if (streamJson) {
        //   // setLoading(false);
      // }
      sseRef.current.abortSse();
    }
    initializeAiGenerateQuestionsConfig(dispatch);
    if (sourcePdfs && sourcePdfs.length > 0) {
      updateSelectConfigDisabled(setPdfMediaFormSetting, pdfMediaFormSetting, false)
    }
    if(sourceImages && sourceImages.length > 0) {
      updateSelectConfigDisabled(setImageMediaFormSetting, imageMediaFormSetting, false)
    }
  };

  const getGeneratedQuestions = async () => {
    // setTaskAdded(false)
    if(showError){
      return;
    }
    if (questionType === "nested_question" && !isCommentsOnly && marks < 3) {
      setShowError(true);
      return;
    }

    if (promptConfig.showCriteria && (criteria || []).length === 0) {
      setShowError(true);
      return
    }
    if (useExamples && !qbUUID) {
      setShowError(true)
      return;
    }
    let selectedImagesUrl = []
    if(convertedImages && convertedImages.length > 0) {
      selectedImagesUrl = convertedImages.filter(item => item.checked).map(item => item.url)
      if(selectedImagesUrl.length === 0) {
        setShowError(true);
        return;
      }
    }
    // Old code
    // if (sourceBasedOn !== "") {
    //   if (sourceBasedOn === "text") {
    //     if (!sourceText) {
    //       setShowError(true)
    //       return;
    //     }
    //   } 
    //   if (sourceBasedOn === "image") {
    //     if (sourceImages === "") {
    //       setShowError(true)
    //       return;
    //     }
    //   }
    //   console.log("sourceBasedOn==>", sourceBasedOn, sourcePdfs, sourceImages, sourceText)
    //   if (sourceBasedOn === "pdf") {
    //     if (sourcePdfs === "") {
    //       setShowError(true)
    //       return;
    //     }
    //   }
    // }

    const abortController = new AbortController();
    if (sseRef) {
      sseRef.current = {
        abortSse: () => {
          abortController.abort();
        }
      }
    }
    console.log("checking criteria", criteria)
    // setLoading(true);
    dispatch(aiSegmentActions.setAiGenerateQuestionsConfigSuccess({ data: {
      ...aiGenerateQuestionsConfig,
      questionType: questionType,
      loading: true,
      sseRef: sseRef,
    } }))
    if (sourcePdfs && sourcePdfs.length > 0) {
      updateSelectConfigDisabled(setPdfMediaFormSetting, pdfMediaFormSetting, true)
    }
    if(sourceImages && sourceImages.length > 0) {
      updateSelectConfigDisabled(setImageMediaFormSetting, imageMediaFormSetting, true)
    }
    setError("")
    const token = getFromLS("token");
    if (!shouldAppendOnGenerateMore) {
      // setGeneratedQuestions([]);
      dispatch(aiSegmentActions.fetchSuccess({data: {segments: []}}))
    }

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    config = updateConfigHeaderForDemoUseStagingAI(config)

    let commandTermId;
    // let questionBankUUID;
    let paperTypeLabel;
    // let difficultyLevelLabel;
    let teachingLevelLabel;
    let keyConceptsLabels;
    let relatedConceptsLabels;
    let globalContextLabel;
    let possibleExplorationLabel;
    let atlSkillsLabels;
    if(commandTerm ) {
      const selectedCommandTerm = find(allCommandTerms, (ct) => ct.name === commandTerm)
      commandTermId = selectedCommandTerm && selectedCommandTerm.id
      setCommandTermId(commandTermId)
    }
    if(paperTypeId) {
      const selectedPaperType = find(allPaperTypes, (ct) => ct.id === paperTypeId)
      paperTypeLabel = selectedPaperType && selectedPaperType.name
      setPaperType(paperTypeLabel)
    }
    if (globalContextId) {
      const selectedGlobalContextLabels = getDetailsFromIds(
        [globalContextId],
        globalContexts
      );
      globalContextLabel =
        selectedGlobalContextLabels && selectedGlobalContextLabels[0];
    }
    if (globalContextId && possibleExplorationId) {
      const selectedPossibleExplorationLabels = getDetailsFromIds(
        [possibleExplorationId],
        possibleExplorations
      );
      possibleExplorationLabel =
        selectedPossibleExplorationLabels &&
        selectedPossibleExplorationLabels[0];
    }

    if (keyConceptsIds.length > 0) {
      keyConceptsLabels = getDetailsFromIds(keyConceptsIds, keyConcepts);
    }
    if (relatedConceptsIds.length > 0) {
      relatedConceptsLabels = getDetailsFromIds(
        relatedConceptsIds,
        relatedConcepts
      );
    }
    if (atlSkillsIds.length > 0) {
      atlSkillsLabels = getDetailsFromIds(atlSkillsIds, atlSkills);
    }
    // if(difficultyLevelId) {
    //   const selectedDifficultyLevel = find(allDifficultyLevels, (ct) => ct.id === difficultyLevelId)
    //   difficultyLevelLabel = selectedDifficultyLevel && selectedDifficultyLevel.name
    //   setDifficultyLevel(difficultyLevelLabel)
    // }
    if(teachingLevelId) {
      const selectedTeachingLevel = find(allTeachingLevels, (ct) => ct.id === teachingLevelId)
      teachingLevelLabel = selectedTeachingLevel && selectedTeachingLevel.name
      setTeachingLevel(teachingLevelLabel)
    }

    
    let syllabusTopicUUID;
    // getting label and uuid from id now
    if (syllabusTopicId) {
      syllabusTopicUUID = findSyllabusTopicKey(
        allSyllabusTopics,
        syllabusTopicId,
        "uuid"
      );
    }

    let subjectId;
    let gradeId;
    let orgProgrammeId;
    let programmeId;
    
    if (
      activeExperience &&
      activeExperience.relation_items &&
      activeExperience.relation_items[0]
    ) {
      subjectId = activeExperience.relation_items[0].subject_id;
      gradeId = activeExperience.relation_items[0].grade_id;
      orgProgrammeId = activeExperience.relation_items[0].org_programme_id;
      programmeId = activeExperience.relation_items[0].programme_id;
    }

    const defaultQuestionsCount = (questionType === "task" || enabledFeatures.atlas_integration_demo_data) ? Number(questionsCount) : aiAssistantSettings?.authoring?.number_of_questions;

    
    // console.log("sending params==>", {paperType, allPaperTypes}, {difficultyLevel, allDifficultyLevels}, {teachingLevel, allTeachingLevels})

    let buildPromptOnBE = true
    // if ((showPromptUsed && promptUsed) || useCustomPrompt) {
    if (useCustomPrompt) {
      buildPromptOnBE = false
    }
    let dataToPost = {}

    // (segmentType === "resource" || segmentType === "text") ? createCondition === "based_on" ? segmentType === "resource" && segment.resource_segment_attributes.resource_type === "image" ? "image" : "text" : "" : createCondition === "new" && sourceBasedOn ? sourceBasedOn : "",

    // Old code
    // let basedOn = "";
    // if ((segmentType === "resource" || segmentType === "text") && createCondition === "based_on") {
    //   if (segmentType === "resource" && resourceType === "image") {
    //     basedOn = "image";
    //   } else if (resourceType === "video") {
    //     basedOn = "video";
    //   } else {
    //     basedOn = "text";
    //   }
    //   if (segmentType === "resource" && resourceType === "pdf") {
    //     basedOn = "image";
    //   }
    // } 
    // else if (createCondition === "new" && sourceBasedOn) {
    //   basedOn = sourceBasedOn === "pdf" ? "image" : sourceBasedOn
    // } 


    // segmentType === "resource" ?
    //    segment.resource_segment_attributes.resource_type === "image" ? 
    //     imageUrl 
    //     : text_to_process 
    //   : segmentType === "text" ? text_to_process : sourceBasedOn ? sourceBasedOn === "text" ? sourceText : sourceBasedOn === "image" ? sourceImages : "" : ""
    let basedOnValue = []
    if(sourceText) {
      basedOnValue.push({type: "text", values: [sourceText]})
    } else if(createCondition === "based_on" && text_to_process) {
      basedOnValue.push({type: "text", values: [text_to_process]})
    }
    if(sourceImages && sourceImages.length) {
      let sourceImagesUrls = sourceImages.map(image => image.url)
      basedOnValue.push({type: "image", values: sourceImagesUrls})
    }
    if(selectedImagesUrl && selectedImagesUrl.length > 0) {
      basedOnValue.push({type: "image", values: selectedImagesUrl })
    }
    if(videoUrl) {
      basedOnValue.push({type: "video", values : [videoUrl]})
    }
    // Old code
    // else if (segmentType === "resource") {
    //     if (resourceType === "image") {
    //       basedOnValue = [imageUrl];
    //     } else if (resourceType === "pdf") {
    //       basedOnValue = convertedImages.filter(item => item.checked).map(item => item.url);
    //     } else if (resourceType === "video" && segmentResourceAttachments.length > 0) {
    //       basedOnValue = [segmentResourceAttachments[0].url];
    //     } else {
    //       basedOnValue = [text_to_process];
    //     }
    // } else if (segmentType === "text") {
    //   basedOnValue = [text_to_process];
    // } else if(sourceBasedOn) {
    //   if (sourceBasedOn === "text") {
    //     basedOnValue = [sourceText];
    //   } else if (sourceBasedOn === "image") {
    //     basedOnValue = [...sourceImages];
    //   } else if (sourceBasedOn === "pdf") {
    //     basedOnValue = convertedImages.filter(item => item.checked).map(item => item.url)
    //   }
    // }


    if(buildPromptOnBE){

      dataToPost = {
        // prompt: prompt // works when we want prompt from FE - but moving to BE
        segment_type: "question",
        child_segment_type: questionType,
        number_of_segments: defaultQuestionsCount,
        programme_label: programmeLabel,
        org_programme_label: orgProgrammeLabel,
        subject_label: subjectLabel,
        grade_label: gradeLabel,
        paper_type_label: paperTypeLabel,
        paper_type_id: paperTypeId,
        // difficulty_level_label: difficultyLevelLabel,
        // difficulty_level_id: difficultyLevelId,
        teaching_level_label: teachingLevelLabel,
        teaching_level_id: teachingLevelId,
        syllabus_topic_label: syllabusTopicLabel,
        syllabus_topic_uuid: syllabusTopicUUID,
        command_term_label: commandTerm,
        command_term_id: commandTermId,
        // criteria_label: "criteria",
        criteria: criteria,
        strands: selectStrands,
        key_concept_labels: keyConceptsLabels,
        related_concept_labels: relatedConceptsLabels,
        global_context_label: globalContextLabel,
        possible_exploration_label: possibleExplorationLabel,
        atl_skills_labels: atlSkillsLabels,
        subject_id: subjectId,
        grade_id: gradeId,
        org_programme_id: orgProgrammeId,
        programme_id: programmeId,
        // myp_al_level_label: "1-2",
        similar_to: similarTo,
        use_examples: useExamples,
        // custom_examples: customExamples,
        user_role: currentUser.role,
        extract_questions: extractQuestions,
        output_language: outputLanguage,
      }

      // if(sourceBasedOn === "pdf") {
      //   dataToPost = { 
      //     ...dataToPost,
      //   }
      // }
      if (activeExperienceRubricTypeC === "points") {
        dataToPost.points = parseFloat(marks)
      }
      if (additionalInstructions) {
        dataToPost = {
          ...dataToPost,
          additional_instructions: additionalInstructions
        }
      }

      if(enabledFeatures.ai_assistant_settings) {
        if (aiAssistantSettings?.authoring?.overall_additional_instructions){
          dataToPost.overall_additional_instructions = aiAssistantSettings?.authoring?.overall_additional_instructions
        }
        if (aiAssistantSettings?.authoring?.difficulty_level) {
          dataToPost.difficulty_level = aiAssistantSettings?.authoring?.difficulty_level
        }
      }

      if(useExamples) { // so that qb_uuid is not sent if !useExamples
        dataToPost = {
          ...dataToPost,
          qb_uuid: qbUUID
        }
      }

    } else {

      dataToPost = {
        user_role: currentUser.role,
        segment_type: "question",
        child_segment_type: questionType,
        number_of_segments: defaultQuestionsCount,
        prompt: customPrompt,
        // prompt: promptUsed || customPrompt, // disabled supporting modification of BE prompt
        // custom_examples: customExamples,
        programme_label: programmeLabel,
        org_programme_label: orgProgrammeLabel,
        subject_label: subjectLabel,
        grade_label: gradeLabel,
      }
    }
      if(basedOnValue && basedOnValue.length > 0) {
        dataToPost = {
        ...dataToPost,
        based_on: basedOnValue,
        }
      }
    // Old code
    // else {
    //   dataToPost = {
    //     ...dataToPost,
    //     based_on: basedOn,
    //     based_on_value: basedOnValue,
    //   }
    // }
    if(aiModel) {
      dataToPost.model = aiModel
    }

    if (streamJson) {
      try {
        const fetchOptions = {
          url: `${getAIServiceBaseURL()}/generate-segments`,
          expectedResponseFormat: "json",
          dataToPost: dataToPost,
          content: "",
          // setLoading: setLoading,
          setError: setError,
          sseRef: sseRef,
          removeVerticalCursor: true,
          successCallback: (data, responseStatus) => {
            console.log("questions data==>", data,)
            if(data && data.questions && data.questions.length > 0){
              // setLoading(false);
              setIsStreaming(responseStatus === "stop" ? false : true)
              // if (questionType === "task") {
              //   setGeneratedQuestions(data);
              // } else {
              //   setGeneratedQuestions(data.questions)
              // }
              
              if(responseStatus === "stop"){
                if (sourcePdfs && sourcePdfs.length > 0) {
                  updateSelectConfigDisabled(setPdfMediaFormSetting, pdfMediaFormSetting, false)
                }
                if(sourceImages && sourceImages.length > 0) {
                  updateSelectConfigDisabled(setImageMediaFormSetting, imageMediaFormSetting, false)
                }
              }
            }
          }
        }
        fetchWithStream(fetchOptions);
      } catch (error) {
        console.error(error);
      }
  
    } else {
      try {
        // await axios.post(`${import.meta.env.VITE_AI_SERVICE_URL}/generate-using-prompt`, dataToPost, config)
        config={
          ...config,
          signal: abortController.signal
        }
        await axios.post(`${getAIServiceBaseURL()}/generate-segments`, dataToPost, config)
        .then(function (response) {
          console.log('generating questions==>', response, paperType)
          let finalResponse;
          let finalSegments;

          if (questionType === "task") {
            finalResponse = [
              {
                sub_parts: response.data?.questions,
                question: response.data?.text,
              },
            ];
          } else if (response && response.data && response.data.text) {
            finalResponse = response.data.text.split("\n");
          } else {
            finalResponse = response.data.questions;
          } 

          setPromptUsed(response.data.prompt_used)
          setRequestCost(response.data.request_cost)
          setTokensUsed(response.data.total_used)
          // showing last 10 questions on generate more - except in task, not appedning on generate more in task
          // if (shouldAppendOnGenerateMore) {
          //   setGeneratedQuestions((generatedQuestions) => {
          //     let updatedQuestions = [...generatedQuestions, ...finalResponse];
          //     // if generated questions length is more than {maxGeneratedQuestions} than we are only showing the last {maxGeneratedQuestions}(10 for now) questions
          //     if (updatedQuestions.length > maxGeneratedQuestions) {
          //       return updatedQuestions.slice(-maxGeneratedQuestions);
          //     }
          //     return updatedQuestions;
          //   });
          // } else {
          //   setGeneratedQuestions(finalResponse);
          // }
          // finalResponse = classifyDummyData;
          let aiSegmentDestinationPosition = lastAISegmentPosition;
          
          finalSegments = finalResponse.reduce((validSegments, segment, index) => {
            if (questionTypesRequiringAnswers.includes(questionType) && !segment.answer) {
              return validSegments; // Skip the segment if it doesn't have an answer
            }
            
            aiSegmentDestinationPosition = getDestinationPositionLex(aiSegmentDestinationPosition);
            
            const finalObject = getSegmentObject({
              questionType,
              questionData: segment,
              experienceId: activeExperience.id,
              userId: currentUser.id,
              marks,
              destinationPosition: aiSegmentDestinationPosition,
              rubricType: activeExperienceRubricTypeC,
              criteria,
              strands: selectStrands,
            });
            
            validSegments.push(finalObject);
            return validSegments;
          }, []); 

          if(!finalSegments || finalSegments.length === 0) {
            throw new Error("Empty finalSegments or segments with no answer"); 
          }

          let generatedQuestionsLength = totalAIQuestionsGenerated + finalSegments.length
          
          if(shouldAppendOnGenerateMore) {
            let updatedSegments
            if(questionType === "nested_question") {
              let nestedAISegments = groupByParent(aiSegments)              
              updatedSegments = [...nestedAISegments, ...finalSegments]
            } else {
              updatedSegments = [...aiSegments, ...finalSegments];
            }
            if (updatedSegments.length > maxGeneratedQuestions) {
              // if number of questions get more than limt then we are only showing latest questions under limit 
              updatedSegments = updatedSegments.slice(-maxGeneratedQuestions);
            }
            finalSegments = updatedSegments;
          }

          if (questionType === "nested_question" || questionType === "task") {
            finalSegments = flattenDeep(finalSegments)
          }

          dispatch(aiSegmentActions.fetchSuccess({data: {segments: finalSegments}}))
          // setLoading(false);
          dispatch(aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
            data: {
              ...aiGenerateQuestionsConfig,
              loading: false,
              criteria,
              strands: selectStrands,
              commandTermId,
              syllabusTopicId,
              paperTypeId,
              teachingLevelId,
              commandTerm,
              syllabusTopicLabel,
              paperTypeLabel,
              teachingLevelLabel,
              keyConceptsLabels,
              relatedConceptsLabels,
              globalContextLabel,
              possibleExplorationLabel,
              atlSkillsLabels,
              showCriteria: promptConfig.showCriteria,
              questionType,
              marks,
              totalAIQuestionsGenerated: generatedQuestionsLength,
              taskAdded: false,
              language,
              additionalInstructions,
              basedOnValue,
              useExamples,
              qbUUID,
              lastAISegmentPosition: aiSegmentDestinationPosition,
              syllabusTopicUUID,
            }
          }));
          
          if (sourcePdfs && sourcePdfs.length > 0) {
            updateSelectConfigDisabled(setPdfMediaFormSetting, pdfMediaFormSetting, false)
          }
          if(sourceImages && sourceImages.length > 0) {
            updateSelectConfigDisabled(setImageMediaFormSetting, imageMediaFormSetting, false)
          }
        })
        .catch(function (error) {
          console.log("error==>", error);
          console.log(error);
          if (error.message !== "canceled") {
            setError(true)
            dispatch(aiSegmentActions.setAiGenerateQuestionsConfigSuccess({ data: {
              ...aiGenerateQuestionsConfig,
              loading: false
            } }))
            if (sourcePdfs && sourcePdfs.length > 0) {
              updateSelectConfigDisabled(setPdfMediaFormSetting, pdfMediaFormSetting, false)
            }
            if(sourceImages && sourceImages.length > 0) {
              updateSelectConfigDisabled(setImageMediaFormSetting, imageMediaFormSetting, false)
            }
            // old code
            // setLoading(false)
          }
          
        }); 
      } catch (error) {
        console.error(error);
      }
    }
  }

  const magicButtonRenderInFooter = (
    <MagicButton
      tooltipTitle={
        !newGenerateQuestionsWithAI && aiGenerateLoading 
          ? mrIntl("GenerateAIWithPrompt.stop_generating") 
          : ""
      }
      type={aiGenerateLoading ? "default" : "primary"}
      shape="default"
      icon={
        !newGenerateQuestionsWithAI && aiGenerateLoading 
          ? <FaRegStopCircle style={{ marginTop: "3px" }} /> 
          : null
      }
      disabled={
        !questionType ||
        showError ||
        isStreaming ||
        (newGenerateQuestionsWithAI && aiGenerateLoading) || 
        addAllButtonLoading || 
        segmentsCreateLoading
      }
      feature_code="ai_generate_questions_with_prompt"
      onClick={() => {
        if (aiGenerateLoading) {
          if (!newGenerateQuestionsWithAI && sseRef.current) {
            // if (streamJson) setLoading(false);
            dispatch(aiSegmentActions.setAiGenerateQuestionsConfigSuccess({
              data: {
                ...aiGenerateQuestionsConfig,
                loading: false
              }
            }))
            sseRef.current.abortSse();
          }
          if (sourcePdfs?.length) {
            updateSelectConfigDisabled(setPdfMediaFormSetting, pdfMediaFormSetting, false);
          }
          if (sourceImages?.length) {
            updateSelectConfigDisabled(setImageMediaFormSetting, imageMediaFormSetting, false);
          }
        } else {
          getGeneratedQuestions();
        }
      }}
      text={
        !newGenerateQuestionsWithAI && aiGenerateLoading
          ? null
          : aiSegments.length > 0 &&
            aiQuestionTypesAllowedToAppend.includes(questionType) &&
            !extractQuestions
          ? mrIntl("GenerateAIWithPrompt.generate_more")
          : mrIntl("GenerateAIWithPrompt.generate")
      }
    />
  );
  
  const generateFilters = 
  <Card 
    bordered={false} 
    extra={
      <Space>
        {enabledFeatures.ai_assistant_settings ? (
          <AIAssistantSettings 
            size={"small"}
          />
        ) : (
          <SelectAIOutputLanguage 
            language={language} 
            setLanguage={setLanguage} 
            disabled={outputLanguageDisabled} 
            newGenerateQuestionsWithAI={newGenerateQuestionsWithAI}
          />
        )}
        <Button icon={<CloseOutlined/>} size="small" danger onClick={() => handleCancel()}></Button>
          <Tooltip title={mrIntl("CommonText.reset")}>
            <Button disabled={aiGenerateLoading} size="small" icon={<UndoOutlined />} onClick={() => {
              resetFilters();
              dispatch(aiSegmentActions.setAiGenerateQuestionsConfigSuccess({data:{
                ...aiGenerateQuestionsConfig, 
                extractFromPdf: false, 
                uploadedPdf: null
              }}))
            }}/>
          </Tooltip>
      </Space>
    }
    actions={[magicButtonRenderInFooter]}
  >
    <>
    <Row>
            <Col className="generate-ai-filters">  
              <Space direction="vertical" style={{ width: "100%" }}>
                {/* <Space className="m-b-10">
                  {programmeLabel && <Tag>{programmeLabel}</Tag>}
                  {orgProgrammeLabel && <Tag>{orgProgrammeLabel}</Tag>}
                  {subjectLabel && <Tag>{subjectLabel}</Tag>}
                  {gradeLabel && <Tag>{gradeLabel}</Tag>}
                </Space> */}
                {/* createCondition here means what kind of question you want to create i.e - new, similar or based_on */}
                {/* Not showing sources in new view */}
                {/* <SegmentContentDisplay segment={segment} createCondition={createCondition} /> */}

                <SelectParametersForGenerate 
                  setPromptUsed={setPromptUsed}
                  promptUsed={promptUsed}
                  aiGenerateLoading={aiGenerateLoading}
                  createCondition={createCondition}
                  isStreaming={isStreaming}
                  // sourceBasedOn={sourceBasedOn}
                  setSourceText={setSourceText}
                  setSourceImages={setSourceImages}
                  setSourcePdfs={setSourcePdfs}
                  // setSourceBasedOn={setSourceBasedOn}
                  sourceText={sourceText}
                  sourceImages={sourceImages}
                  sourcePdfs={sourcePdfs}
                  setExtractQuestions={setExtractQuestions}
                  extractQuestions={extractQuestions}
                  showError={showError}
                  setShowError={setShowError}
                  pdfMediaFormSetting={pdfMediaFormSetting}
                  setPdfMediaFormSetting={setPdfMediaFormSetting}
                  imageMediaFormSetting={imageMediaFormSetting}
                  setImageMediaFormSetting={setImageMediaFormSetting}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  convertedImages={convertedImages}
                  setConvertedImages={setConvertedImages}
                  // setGeneratedQuestions={setGeneratedQuestions}
                  setQuestionType={setQuestionType}
                  questionType={questionType}
                  promptConfig={promptConfig}
                  nestingLevel={nestingLevel}
                  keyConceptsIds={keyConceptsIds}
                  setKeyConceptsIds={setKeyConceptsIds}
                  relatedConceptsIds={relatedConceptsIds}
                  setRelatedConceptsIds={setRelatedConceptsIds}
                  globalContextId={globalContextId}
                  setGlobalContextId={setGlobalContextId}
                  possibleExplorationId={possibleExplorationId}
                  setPossibleExplorationId={setPossibleExplorationId}
                  atlSkillsIds={atlSkillsIds}
                  setAtlSkillsIds={setAtlSkillsIds}
                  criteria={criteria}
                  setCriteria={setCriteria}
                  selectStrands={selectStrands}
                  setSelectStrands={setSelectStrands}
                  marks={marks}
                  setMarks={setMarks}
                  segment={segment}
                  syllabusTopicId={syllabusTopicId}
                  setSyllabusTopicId={setSyllabusTopicId}
                  setSyllabusTopicLabel={setSyllabusTopicLabel}
                  commandTerm={commandTerm}
                  setCommandTerm={setCommandTerm}
                  setPaperTypeId={setPaperTypeId}
                  paperTypeId={paperTypeId}
                  teachingLevelId={teachingLevelId}
                  setTeachingLevelId={setTeachingLevelId}
                  questionsCount={questionsCount}
                  setQuestionsCount={setQuestionsCount}
                  additionalInstructions={additionalInstructions}
                  setAdditionalInstructions={setAdditionalInstructions}
                  customPrompt={customPrompt}
                  setCustomPrompt={setCustomPrompt}
                  useCustomPrompt={useCustomPrompt}
                  setUseCustomPrompt={setUseCustomPrompt}
                  useCustomExamples={useCustomExamples}
                  setUseCustomExamples={setUseCustomExamples}
                  customExamples={customExamples}
                  setCustomExamples={setCustomExamples}
                  useExamples={useExamples}
                  setUseExamples={setUseExamples}
                  qbUUID={qbUUID}
                  setQbUUID={setQbUUID}
                  generatedQuestionsLength={aiSegments?.length}
                  tokensUsed={tokensUsed}
                  requestCost={requestCost}
                  extractFromPdf={extractFromPdf}
                  handleSaveFiles={handleSaveFiles}
                />
                <br />
                {error && <Space direction="vertical">
                  <Alert
                    type="warning"
                    className="error-alert"
                    message={mrIntl("CommonText.something_went_wrong_please_try_again")}
                  />
                </Space>}
                {/* <SelectPaperType key="selectPaperTypeFilter" value={paperType} onChange={(value) => setPaperType(value)} config={{ widgetConfig: { mode: "multiple", style: { width: '150px' } } }} /> */}
                {/* {props.withAddButton && <Input addonBefore={mrIntl("GenerateAIWithPrompt.enter_topic")} value={syllabusTopicLabel} onChange={(e) => setSyllabusTopicLabel(e.target.value)} />} */}
                {/* {!showPromptUsed && } */}
                {/* {props.withAddButton && <Input addonBefore={mrIntl("GenerateAIWithPrompt.command_term")} value={commandTerm} onChange={(e) => setCommandTerm(e.target.value)} />} */}
                {/* {
                  <Space direction="vertical">
                    
                  </Space>
                } */}

                {/* {!showPromptUsed && <Checkbox
                  onChange={(e) =>
                    setShowAdditionalInstructions(e.target.checked)
                  }
                >
                  {mrIntl("GenerateAIWithPrompt.additional_instructions")}
                </Checkbox>}
                {!showPromptUsed && showAdditionalInstructions && (
                  <TextArea
                    value={additionalInstructions}
                    onChange={(e) => setAdditionalInstructions(e.target.value)}
                    rows={2}
                    placeholder={mrIntl(
                      "GenerateAIWithPrompt.enter_additional_instructions"
                    )}
                  />
                )} */}
              </Space>
            </Col>
          </Row></>

  </Card>
  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Share
      </Button> */}
      {/* Old code */}
      {/* {(showGenerateAIButton && false) ? (
        createCondition === "new" ? (
          <MagicButton
            tooltipTitle={tooltipTitle}
            tooltipPlacement="top"
            shape="default"
            size="medium"
            ghost={true}
            onClick={showModal}
            text={
              extractFromPdf ? "Extract from PDF":
              <MrTranslate
                id={"GenerateAIWithPrompt.generate_with_ai_button"}
              />
            }
          />
        ) : (
          <MagicButton
            // mode="subtle"
            tooltipTitle={tooltipTitle}
            tooltipPlacement="top"
            type={"text"}
            size="medium"
            onClick={showModal}
          />
        )
      ) : null} */}

      {aiGenerateMode && (
        newGenerateQuestionsWithAI   ? 
          generateFilters
          : (
            <Modal
              title={<ModalTitle handleCancel={handleCancel}  language={language} setLanguage={setLanguage} outputLanguageDisabled={outputLanguageDisabled} newGenerateQuestionsWithAI={newGenerateQuestionsWithAI} />}
              open={aiGenerateMode}
              footer={
                <ModalFooter
                  magicButtonRenderInFooter={magicButtonRenderInFooter}
                  error={error}
                  generatedQuestions={aiSegments}
                  isStreaming={isStreaming}
                  aiGenerateLoading={aiGenerateLoading}
                />
              }
              closable={false}
              onOk={handleOk}
              onCancel={() => handleCancel()}
              width={"75%"}
              wrapClassName={
                "full-screen-modal height-centered-modal generate-ai-modal"
              }
            >
              <Row gutter={24}>
                <Col className="generate-ai-modal-param-col" span={9}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Space className="m-b-10">
                      {programmeLabel && <Tag>{programmeLabel}</Tag>}
                      {/* {orgProgrammeLabel && <Tag>{orgProgrammeLabel}</Tag>} */}
                      {orgProgrammeLabel && <Tag><WithTooltip maxLength={20}>{orgProgrammeLabel}</WithTooltip></Tag>}
                      {subjectLabel && <Tag>{subjectLabel}</Tag>}
                      {gradeLabel && <Tag>{gradeLabel}</Tag>}
                    </Space>
                    {/* createCondition here means what kind of question you want to create i.e - new, similar or based_on */}
                    {!extractQuestions && (
                      <Tooltip title={mrIntl("CommonText.reset")}>
                        <Button disabled={aiGenerateLoading} icon={<UndoOutlined />} onClick={resetFilters}>
                          {mrIntl("CommonText.reset")}
                        </Button>
                      </Tooltip>
                    )}
                    <SegmentContentDisplay segment={segment} createCondition={createCondition} />
    
                    <SelectParametersForGenerate 
                  setPromptUsed={setPromptUsed}
                  promptUsed={promptUsed}
                  aiGenerateLoading={aiGenerateLoading}
                  createCondition={createCondition}
                  isStreaming={isStreaming}
                  // sourceBasedOn={sourceBasedOn}
                  setSourceText={setSourceText}
                  setSourceImages={setSourceImages}
                  setSourcePdfs={setSourcePdfs}
                  // setSourceBasedOn={setSourceBasedOn}
                  sourceText={sourceText}
                  sourceImages={sourceImages}
                  sourcePdfs={sourcePdfs}
                  setExtractQuestions={setExtractQuestions}
                  extractQuestions={extractQuestions}
                  showError={showError}
                  setShowError={setShowError}
                  pdfMediaFormSetting={pdfMediaFormSetting}
                  setPdfMediaFormSetting={setPdfMediaFormSetting}
                  imageMediaFormSetting={imageMediaFormSetting}
                  setImageMediaFormSetting={setImageMediaFormSetting}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  convertedImages={convertedImages}
                  setConvertedImages={setConvertedImages}
                  // setGeneratedQuestions={setGeneratedQuestions}
                  setQuestionType={setQuestionType}
                  questionType={questionType}
                  promptConfig={promptConfig}
                  nestingLevel={nestingLevel}
                  keyConceptsIds={keyConceptsIds}
                  setKeyConceptsIds={setKeyConceptsIds}
                  relatedConceptsIds={relatedConceptsIds}
                  setRelatedConceptsIds={setRelatedConceptsIds}
                  globalContextId={globalContextId}
                  setGlobalContextId={setGlobalContextId}
                  possibleExplorationId={possibleExplorationId}
                  setPossibleExplorationId={setPossibleExplorationId}
                  atlSkillsIds={atlSkillsIds}
                  setAtlSkillsIds={setAtlSkillsIds}
                  criteria={criteria}
                  setCriteria={setCriteria}
                  selectStrands={selectStrands}
                  setSelectStrands={setSelectStrands}
                  marks={marks}
                  setMarks={setMarks}
                  segment={segment}
                  syllabusTopicId={syllabusTopicId}
                  setSyllabusTopicId={setSyllabusTopicId}
                  setSyllabusTopicLabel={setSyllabusTopicLabel}
                  commandTerm={commandTerm}
                  setCommandTerm={setCommandTerm}
                  setPaperTypeId={setPaperTypeId}
                  paperTypeId={paperTypeId}
                  teachingLevelId={teachingLevelId}
                  setTeachingLevelId={setTeachingLevelId}
                  questionsCount={questionsCount}
                  setQuestionsCount={setQuestionsCount}
                  additionalInstructions={additionalInstructions}
                  setAdditionalInstructions={setAdditionalInstructions}
                  customPrompt={customPrompt}
                  setCustomPrompt={setCustomPrompt}
                  useCustomPrompt={useCustomPrompt}
                  setUseCustomPrompt={setUseCustomPrompt}
                  useCustomExamples={useCustomExamples}
                  setUseCustomExamples={setUseCustomExamples}
                  customExamples={customExamples}
                  setCustomExamples={setCustomExamples}
                  useExamples={useExamples}
                  setUseExamples={setUseExamples}
                  qbUUID={qbUUID}
                  setQbUUID={setQbUUID}
                  generatedQuestionsLength={aiSegments?.length}
                  tokensUsed={tokensUsed}
                  requestCost={requestCost}
                  extractFromPdf={extractFromPdf}
                  handleSaveFiles={handleSaveFiles}
                />
                    {/* <SelectPaperType key="selectPaperTypeFilter" value={paperType} onChange={(value) => setPaperType(value)} config={{ widgetConfig: { mode: "multiple", style: { width: '150px' } } }} /> */}
                    {/* {props.withAddButton && <Input addonBefore={mrIntl("GenerateAIWithPrompt.enter_topic")} value={syllabusTopicLabel} onChange={(e) => setSyllabusTopicLabel(e.target.value)} />} */}
                    {/* {!showPromptUsed && } */}
                    {/* {props.withAddButton && <Input addonBefore={mrIntl("GenerateAIWithPrompt.command_term")} value={commandTerm} onChange={(e) => setCommandTerm(e.target.value)} />} */}
                    {/* {
                      <Space direction="vertical">
                        
                      </Space>
                    } */}
    
                    {/* {!showPromptUsed && <Checkbox
                      onChange={(e) =>
                        setShowAdditionalInstructions(e.target.checked)
                      }
                    >
                      {mrIntl("GenerateAIWithPrompt.additional_instructions")}
                    </Checkbox>}
                    {!showPromptUsed && showAdditionalInstructions && (
                      <TextArea
                        value={additionalInstructions}
                        onChange={(e) => setAdditionalInstructions(e.target.value)}
                        rows={2}
                        placeholder={mrIntl(
                          "GenerateAIWithPrompt.enter_additional_instructions"
                        )}
                      />
                    )} */}
                  </Space>
                </Col>
                <Col span={15} className="generated-questions">
                  {/* {(questionType === "task" ? (generatedQuestions.questions && generatedQuestions.questions.length > 0) : generatedQuestions.length > 0) && (

                    // <RenderGeneratedQuestions
                    //   marks={marks}
                    //   criteria={criteria}
                    //   showCriteria={promptConfig.showCriteria}
                    //   strands={selectStrands}
                    //   commandTermId={commandTermId}
                    //   syllabusTopicId={syllabusTopicId}
                    //   questions={generatedQuestions}
                    //   setGeneratedQuestions={setGeneratedQuestions}
                    //   questionType={questionType}
                    //   previousId={previousId}
                    //   destinationPosition={destinationPosition}
                    //   createAtIndex={createAtIndex}
                    //   paperTypeId={paperTypeId}
                    //   teachingLevelId={teachingLevelId}
                    //   // difficultyLevelId={difficultyLevelId}
                    //   parentId={parentId}
                    //   isStreaming={isStreaming}
                    //   extractQuestions={extractQuestions}
                    //   streamJson={streamJson}
                    //   aiGenerateLoading={aiGenerateLoading}
                    //   setVisible={setVisible}
                    //   taskAdded={taskAdded}
                    //   setTaskAdded={setTaskAdded}
                    //   addAllButtonLoading={addAllButtonLoading}
                    //   setAddAllButtonLoading={setAddAllButtonLoading}
                    //   nextPosition={nextPosition}
                    // />
                  )} */}
                  {aiSegments && aiSegments.length > 0 && (
                   <AISegmentsWrapper />
                  )}
                  {aiGenerateLoading && (
                    <div className="loading-div">
                      <List
                        itemLayout="vertical"
                        size="large"
                        dataSource={skeletonDataSource}
                        renderItem={(item, index) => (
                          <List.Item
                            ref={
                              index === skeletonDataSource.length - 1
                                ? skeletonRef
                                : null
                            }
                          >
                            <Skeleton active></Skeleton>
                          </List.Item>
                        )}
                      />
                    </div>
                  )}
                  {(!aiSegments || aiSegments.length === 0) && !aiGenerateLoading && (
                    <>
                      <Empty
                        description={mrIntl(
                          "GenerateAIWithPrompt.please_click_generate_to_see_questions_description"
                        )}
                      />
                      <Alert
                        type="info"
                        message={
                          <span>
                            <p>
                              1.{" "}
                              {mrIntl(
                                "GenerateAIWithPrompt.provide_clear_concise_instructions_with_specific_keywords_for_more_relevant"
                              )}
                            </p>
                            <p>
                              2.{" "}
                              {mrIntl(
                                "GenerateAIWithPrompt.do_not_include_any_personal_or_sensitive_information_in_your"
                              )}
                            </p>
                            <p>
                              3.{" "}
                              {mrIntl(
                                "GenerateAIWithPrompt.while_our_ai_assistant_is_advanced_it_is_not_perfect"
                              )}
                            </p>
                          </span>
                        }
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Modal>
          ) 
        )}

    </>
  );
};


export default GenerateAIWithPrompt;
