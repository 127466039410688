import React, { useEffect, useState } from "react";
import { Button, Tooltip, Modal, Row, Col, Upload, List } from "antd";
import { UploadOutlined, FileOutlined, PlusOutlined, EyeOutlined } from "@ant-design/icons";
import { removeItemFromArray } from "/src/views/Segments/InteractiveHelpers";
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments";
import { cloneDeep } from "lodash";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { checkMob, getUniqueId } from "/src/lib/utils/helperMethods";
import PreviewAttachment from "/src/components/UI/Attachments/PreviewAttachment";
import "./ResourceModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { actions as programmeSubjectActions } from "/src/views/ProgrammeSubjects/redux";
import { currentUserSelector, enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { showInfoModal } from "/src/components/UI/Segment/UIHelper";
import { experienceViewModeSelector } from "/src/views/Experiences/selector.js"
import { SolarFileLinear } from "/src/components/UI/Icons/SolarIcons";
import NoData from "/src/components/UI/NoData/NoData";

const ResourceModal = (props) => {
  console.log("Experience Resource props", props);
  const dispatch = useDispatch()
  const { createMode } = props;
  const isMobile = checkMob();
  
  const [state, setState] = useState(cloneDeep(props.attachments_json));
  const [suggestedResources, setSuggestedResources] = useState([])
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const currentUser = useSelector(currentUserSelector());
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const experienceViewMode = useSelector(experienceViewModeSelector());

  const mrIntl = useTranslate();
  const attachments_json = state || {};
  const resources = attachments_json.resources || [];
  const isStudent = currentUser.role === "student" ? true : false
  const isPreviewMode = experienceViewMode == "apPreviewTest" ? true : false
  const newJoinViewWithDynamicConfigEnabled = enabledFeatures.new_join_view_with_dynamic_config
  const updateButtonPramsForSidebar = newJoinViewWithDynamicConfigEnabled && (isStudent || isPreviewMode)
  const isQBAuthor = currentUser.role === "qb_author" ? true : false
  const programmeSubjectId = props.programmeSubjectId;

  const buttonType = updateButtonPramsForSidebar ? "text" : "";
  const buttonShape = updateButtonPramsForSidebar ? "" : "circle";

  const defaultSupportedTypes = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp", "application/pdf"];
  const defaultSupportedFormats = ["jpg", "jpeg", "png", "gif", "bmp", "pdf"];
  let maxSize = "20 MB";
  if (isQBAuthor) {
    defaultSupportedTypes.push("audio/mpeg", "audio/wav");
    defaultSupportedFormats.push("mp3", "wav");
    maxSize = "50 MB"
  }
  const mediaFormInitialState = {
    modal: false,
    loading: false,
    visible: false,
    title: createMode ? mrIntl("ResourceModal.insert_resources") : mrIntl("ResourceModal.resources"),
    okText: mrIntl("ResourceModal.save"),
    cancelText: createMode ? mrIntl("CommonText.cancel") : mrIntl("ResourceModal.close"),
    triggerSaveAction: 0,
    requireFooter: false,
    content_type: "image",
    supportedTypes: defaultSupportedTypes.join(", "),
    supportedFormats: defaultSupportedFormats.join(", "),
    selectConfig: {
      defaultFileList: resources.slice(),
      maxSize: maxSize,
      showUploadList: false,
    },
  };
  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );

  mediaFormSetting.onChangeSaveFiles = (files) => {
    const newFiles = files;
    onSaveMedia(newFiles);
  };

  mediaFormSetting.onRemoveFile = (file) => {
    onRemoveMedia(file);
  };

  useEffect(() => {
    if (createMode && mediaFormSetting.visible && programmeSubjectId) {
      dispatch(
        programmeSubjectActions.show({ id: programmeSubjectId }, {
          successCallback: (data) => {
            const resourceSheet = (data &&
              data.data &&
              data.data.programme_subject &&
              data.data.programme_subject.data &&
              data.data.programme_subject.data.attributes &&
              data.data.programme_subject.data.attributes.attachments_json &&
              data.data.programme_subject.data.attributes.attachments_json.resources) || [];
            setSuggestedResources(resourceSheet)
          }
        })
      );
    }
  }, [mediaFormSetting.visible])

  const openResourceModal = () => {
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: true,
      triggerFileListChange: getUniqueId(),
      selectConfig: {
        ...mediaFormSetting.selectConfig,
        controlledFileList: resources.slice(),
        maxSize: maxSize
      }
    });
  };

  const handleOk = () => {
    props.submitResources(state);
    setMediaFormSetting({
      ...mediaFormInitialState,
    });
  };

  const handleCancel = () => {
    const attachments_json = cloneDeep(props.attachments_json);
    console.log("attachments_json======>", attachments_json);
    const resources = attachments_json.resources || [];
    setState(attachments_json);
    setMediaFormSetting({
      ...mediaFormSetting,
      visible: false,
      triggerFileListChange: getUniqueId(),
      selectConfig: {
        ...mediaFormSetting.selectConfig,
        controlledFileList: resources.slice(),
      },
    });
  };

  const onTabDragEnd = (resources) => {
    const newResources = resources.slice();

    const newAttachemntsJSON = {
      ...attachments_json,
      resources: newResources,
    };
    setState(newAttachemntsJSON);
  };

  const removeItem = (index) => {
    const extra = {
      noMinSize: true,
    };
    const newResources = removeItemFromArray(resources, index, extra);
    const newAttachemntsJSON = {
      ...attachments_json,
      resources: newResources,
    };
    setState(newAttachemntsJSON);
    setMediaFormSetting((mediaFormSetting) => {
      return {
        ...mediaFormSetting,
        triggerFileListChange: getUniqueId(),
        selectConfig: {
          ...mediaFormSetting.selectConfig,
          controlledFileList: newResources,
        },
        okDisabled: false,
      };
    });
  };

  let newResources = resources;
  const onSaveMedia = (files) => {
    newResources = files;

    const newAttachemntsJSON = {
      ...attachments_json,
      resources: newResources,
    };
    setState(newAttachemntsJSON);
    setMediaFormSetting((mediaFormSetting) => {
      return {
        ...mediaFormSetting,
        triggerFileListChange: getUniqueId(),
        selectConfig: {
          ...mediaFormSetting.selectConfig,
          controlledFileList: newResources,
        },
        okDisabled: false,
      };
    });
  };

  const onRemoveMedia = (file) => {
    const index = newResources.findIndex((res) => res.id == file.id);
    if (index >= 0) {
      removeItem(index);
    }
  };

  const onTextChanged = (type, index, data) => {
    const newResources = resources.slice();
    newResources[index][type] = data;

    const newAttachemntsJSON = {
      ...attachments_json,
      resources: newResources,
    };
    setState(newAttachemntsJSON);
  };

  const onTabEdit = (index, action) => {
    if (action == "add") {
    } else if (action == "remove") {
      removeItem(index);
    }
  };


  const closePreview = () => {
    setPreviewImage("");
    setPreviewOpen(false)
  };

  const handlePreview = (file) => {
    setPreviewImage(file);
    setPreviewOpen(true);
  };

  const addSuggestedSubjectResources = (file) => {
    let currentResource = resources.find((resource) => resource.id === file.id);
    if (!currentResource) {
      const newFileList = [...resources, file];
      mediaFormSetting.onChangeSaveFiles(newFileList);
    } 
    return false;
  };

  const renderDownloadButton = (file) => {
    let currentResource = resources.find((resource) => resource.id === file.id);    
      return (
        <span className="file-item-preview-button">
          <Tooltip title="Preview">
            <Button icon={<EyeOutlined />} size="small" onClick={() => handlePreview(file)} />
          </Tooltip>
          
          <Tooltip title={currentResource && mrIntl("ResourceModal.already_added")}>
            <Button type="primary" icon={<PlusOutlined />} disabled = {currentResource} size="small" onClick={() => addSuggestedSubjectResources(file)}>Use</Button>
          </Tooltip>
        </span>
    );
  };

  const uploadedFileListItemRender = (file) => {
    const downloadButton = renderDownloadButton(file);
    return (
      <List.Item
        className="file-item unstyled-list-item"
        actions={[downloadButton]}
      >
        <div
          className="file-item-name"
        >
          {file.name && file.name.split("/")[1]}
        </div>
      </List.Item>
    );
  }

  const resourceFooter = [
    <Button key="back" onClick={handleCancel}>
      {mediaFormSetting.cancelText || mrIntl("ResourceModal.return")}
    </Button>,
  ];

  if (createMode) {
    resourceFooter.push(
      <Button
        key="submit"
        type="primary"
        onClick={handleOk}
        loading={mediaFormSetting.loading}
        disabled={mediaFormSetting.okDisabled}
      >
        {mediaFormSetting.okText || mrIntl("CommonText.submit")}
      </Button>
    );
  }

  // we are using this className for cypress test
  let className = "upload-resource-button" 

  if (updateButtonPramsForSidebar && mediaFormSetting.visible) {
    className += " sidebar-resource-sheet-button"
  } 

  const previewMediaConfig = {
    modal: {
      width: "80%",
      extraClass: "resource-preview-attachment-modal"
    },
    mediaShowSetting: {
      iframeHeight: "660px"
    }
  };

  resources.map(item => {
    const itemName = item?.name?.split("/")[1] ?? "";
    const fileName = itemName.replace(/\.(pdf|jpg|jpeg|png|gif|bmp)$/i, "");
    item.title = fileName;
    
    return fileName;
  });

  return (
    <div>
      <Tooltip
        title={createMode ? mrIntl("ResourceModal.upload_resource_sheet") : mrIntl("ResourceModal.open_resource_sheet")}
        placement={newJoinViewWithDynamicConfigEnabled ? "left" : "bottom"}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        <Button
          className={className}
          type= {buttonType}
          shape={buttonShape}      
          icon={createMode ? <UploadOutlined /> : <SolarFileLinear />}
          onClick={() => openResourceModal()}
        />
      </Tooltip>

      <Modal
        {...mediaFormSetting}
        width="80%"
        wrapClassName="height-centered-modal"
        centered={true}
        bodyStyle={{
          minHeight: isMobile ? "300px" : "600px",
        }}
        open={mediaFormSetting.visible}
        title={mediaFormSetting.title || mrIntl("CommonText.insert_media_title")}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={resourceFooter}
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        loading={false}
      >
        <Row gutter={20}>
          <Col span={createMode ? 12 : 24}>
            {resources.length === 0 
              ? <NoData 
                  description={
                    createMode 
                      ? mrIntl("ResourceModal.preview_resource_here") 
                      : mrIntl("ResourceModal.no_resource_here")} 
                />
              : <TabularAttachments
                  createMode={createMode}
                  hideAdd={true}
                  hideClose={false}
                  attachments={resources}
                  onTabDragEnd={onTabDragEnd}
                  onTabEdit={onTabEdit}
                  onTextChanged={onTextChanged}
                  tabTitleLength={true}
                ></TabularAttachments>
            }
          </Col>
          {createMode && (
            <Col span={12}>
              {
                <SelectFromComputer
                  computer={true}
                  url={true}
                  mediaFormSetting={mediaFormSetting}
                  setMediaFormSetting={(data) => setMediaFormSetting(data)}
                ></SelectFromComputer>
              }
              {createMode && suggestedResources.length > 0 && (
                <div className="suggested-resources">
                  <div className="suggested-resources-header">
                    {mrIntl("ResourceModal.suggested_resources")}
                  </div>
                  <div className="upload-resource-file">
                    <Upload
                      fileList={suggestedResources}
                      onPreview={handlePreview}
                      itemRender={(originNode, file) => uploadedFileListItemRender(file)}
                    ></Upload>
                  </div>
                  <PreviewAttachment
                    type={"modal"}
                    title={true}
                    visible={previewOpen}
                    file={previewImage}
                    onCancel={closePreview}
                    previewMediaConfig={previewMediaConfig}
                  />
                </div>
              )}
            </Col>
          )}
        </Row>
      </Modal>
    </div>
  );
};
export default ResourceModal;
