import React from "react";

import "./ActionBtns.scss";
import { Button, Popconfirm, Tooltip } from "antd";
import styled from "styled-components";
import {Link, useRouteMatch} from "react-router-dom";
import {EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { MdSync } from "react-icons/md";

const ButtonGroup = Button.Group;
const StyledButtonGroup = styled(ButtonGroup)`
  // position: absolute;
  // right: 10px;
  // top: 10px;
`;


const ActionBtns = props => {
  console.log("props action btns", props);
  const {path, url} = useRouteMatch();
  const mrIntl = useTranslate()
  let toRender;
  let actionsArr = [];
  // add permissions verification aswell

  if (props.edit) {
    actionsArr.push(<Button key="edit" icon="edit" onClick={props.edited} />);
  }
  if (props.delete) {
    actionsArr.push(
      <Button key="delete" icon="delete" onClick={props.deleted} />
    );
  }
  const confirm = e => {
    props.deleted(props.item, { success: { showMessage: true, message: mrIntl("ActionBtns.successfully_archived") } });
    // message.success("Deleted successfully");
  };
  const cancel = e => {
    // message.error("Delete cancelled");
  };
  let menuItem;
  let menuItems = [];
  if (props.edit) {
    // menuItem = (
    //   <Button
    //     key="edit"
    //     onClick={() => props.edited({ name: props.name, item: props.item })}
    //     icon={"edit"}
    //   />
    // );
    console.log("props.resource", props.resource)
    menuItem = (
      
      <Link to={`${url}/${props.id}/edit`}> 
        <Tooltip title={mrIntl("CommonText.edit")}>
          <Button icon={<EditOutlined />} />
        </Tooltip>
      </Link>  
    );
  }
  menuItems.push(menuItem);
  if (props.delete) {
    menuItem = (
      <Popconfirm
        key="delete"
        // title="Confirm archive?"
        title={mrIntl("CommonText.confirm_archive")}
        okText= {mrIntl("CommonText.yes")}
        cancelText= {mrIntl("CommonText.no")}
        onConfirm={confirm}
        onCancel={cancel}
      >
        <Tooltip
         title={mrIntl("CommonText.archive")}
         >
          <Button
            // shape="circle"
            // onClick={() => props.deleted(props.item)}
            icon={<DeleteOutlined />}
          />
        </Tooltip>
      </Popconfirm>
    );
    menuItems.push(menuItem);
  }

  // <Button name='gamepad' active={activeItem === 'gamepad'} onClick={this.handleItemClick}>
  //   <Icon name='gamepad' />
  //   Games
  //       </Button>
  
  
  return (
    <StyledButtonGroup className={"action-btns"}>{menuItems}</StyledButtonGroup>
  );


  // return <Button.Group>{actionsArr}</Button.Group>;
};

export default ActionBtns;
