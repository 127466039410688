
      if (import.meta.env.VITE_REGION != 'china') {
        // Causing print delay in china
        var csslink=document.createElement("link");
        csslink.href="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.css";
        csslink.rel = 'stylesheet';
        csslink.type = 'text/css';
        document.body.appendChild(csslink);
        var script=document.createElement("script");
        // script.async=true;
        script.src="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js";
        script.onload=() => {
          if (window.location === window.parent.location) {
            window.cookieconsent.initialise({
              container: document.getElementById("content"),
              palette: {
                popup: { background: "#eaf7f7", text: "#5c7291" },
                button: { background: "#56cbdb", text: "#ffffff" },
              },
              showLink: false,
              revokable: true,
              theme: "classic",
              position: "bottom-left",
              elements: {
                message:
                  '<span id="cookieconsent:desc" class="cc-message">AssessPrep uses cookies to ensure you get the best experience on our website. By using our site, you acknowledge that you have read and understand our <a href="https://www.assessprep.com/cookies" target="_blank">Cookie Policy</a>, <a href="https://www.assessprep.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://www.assessprep.com/terms" target="_blank">Terms of Service</a> as your use of this site is subject to these terms and policies.</span>',
                  // policy: null
              },
              // onStatusChange: function (status) {
              //   console.log("status", status);
              //   console.log(
              //     this.hasConsented()
              //       ? localStorage.setItem("cookies_enabled", 1)
              //       : "disable cookies"
              //   );
              // },
              // law: {
              //   regionalLaw: false,
              // },
              // location: true,
            });
          }
        }
        document.body.appendChild(script);
        // if (localStorage.getItem("cookies_enabled") === null) {
          // display banner
          
      }
    