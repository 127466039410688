import React from "react";
import { CKETextSectionTitleTools } from "/src/components/UI/CKEditor/CKEConfig"
import { InputArea } from '/src/components/UI/Segment/UIHelper'
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import './SectionTitle.scss';

const SectionTitle = (props) => {
    console.log('props in section title==>', props)
  const onTextChanged = (data) => {
    props.onChange(data)
  }
  return (
    <InputArea
      // onClick={props.onClick}
      className={`singleline-input-area ${props.edit && 'edit-section-title'}`}
    >
      <BasicCKE
        tools={CKETextSectionTitleTools}
        data={props.value}
        onTextChanged={(data) => onTextChanged(data)}
      >
      </BasicCKE>
      
    </InputArea>
  );
};
export default SectionTitle;