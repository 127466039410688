import { fromJS } from "immutable";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { MrReduxCrud, formatJsonApiData } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import _ from "lodash";


const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "aisegment",
  singleResourceName: "segment",
  apiUrl: "/segments",
  actionNames: ["FETCH", "CREATE", "SHOW", "UPDATE", "DELETE", "SET_AI_GENERATE_QUESTIONS_CONFIG"],
});

const initialState = fromJS({
  segments: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
});

function setAiGenerateQuestionsConfigSuccess(state, action) {
  console.log("action.payload==>", action.payload)
  return state.set('aiGenerateQuestionsConfig', action.payload.data)
}


export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  SET_AI_GENERATE_QUESTIONS_CONFIG_AISEGMENT_SUCCESS: setAiGenerateQuestionsConfigSuccess

});
export const watchAIsegments = reduxCrud.generateWatchSaga();

export default reduxCrud;
