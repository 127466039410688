import React, { useState } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import GoogleLogoImage from "../../../assets/images/google_logo.png";
import MrTranslate, { mrTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { openUrl } from '/src/lib/utils/helperMethods';
import { message } from '/src/components/UI/AntdAppHelper';

const RedirectToBrowser = (props) => {
  const { googleLoading, onGoogleLoginStart } = props;
  const dispatch = useDispatch();

  const handleClick = () => {
    if (googleLoading) {
      return;
    }
    onGoogleLoginStart()
    // openUrl("google.com");
    dispatch(
      offlineAppActions.executeMethod([
        {
          key: "openLinkInBrowser",
          value: import.meta.env.VITE_WEB_URL,
          successCallback: () => {
            dispatch(
              offlineAppActions.executeMethod([
                {
                  key: "quit",
                  value: true
                },
              ])
            );
          }
        },
      ])
    );
  };

  return (
    <Button
      loading={googleLoading}
      className="social-login-btn"
      onClick={handleClick}
      // disabled={(!googleLoading)}
    >
      <img
        style={{
          height: "20px",
          marginTop: "-3px",
          paddingRight: "5px",
        }}
        src={GoogleLogoImage}
        alt="Google Logo"
      />
      <MrTranslate id={"Login.login_with_google"} />
    </Button>
  );
};

export default RedirectToBrowser;