import React, { useState, useEffect } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Button, Modal, Tooltip, Row, Tag, Col, Alert } from "antd";
import {
  UserAddOutlined,
  InfoOutlined,
  InfoCircleTwoTone,
  QrcodeOutlined,
  UploadOutlined,
  QuestionOutlined
} from "@ant-design/icons";
import OfflineApps from "./OfflineApps";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const DownloadAppsModal = (props) => {
  const { showAppDownloadBtnText } = props;
  console.log( "DownloadAppsModal props===>>>", props );
  const [showDownloadAppsModal, setShowDownloadAppsModal] = useState(false);
  const mrIntl = useTranslate();
  const showModal = () => {
    setShowDownloadAppsModal(true);
  };

  const handleOk = (e) => {
    setShowDownloadAppsModal(false);
  };

  const handleCancel = (e) => {
    setShowDownloadAppsModal(false);
  };

  return (
    <>
      <Tooltip title={mrIntl("DownloadAppsModal.download_desktop_apps")} placement="top">
        {/* <Button shape="circle" icon={<QrcodeOutlined />} onClick={showModal}/> */}
        {showAppDownloadBtnText ? (
          <p>
            {mrIntl("DownloadAppsModal.need_to_install_the_app_first")}{" "}
            <span className='download-lockdown-app' onClick={showModal}>
              {mrIntl("DownloadAppsModal.download_lockdown_app")}
            </span>
          </p>
        ) : (
          <Button
            shape="circle"
            danger
            ghost={true}
            icon={<QuestionOutlined />}
            onClick={showModal}
          ></Button>
        )}

      </Tooltip>
      <Modal
        // title="Upload handwritten answers"
        open={showDownloadAppsModal}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        wrapClassName="qr-modal"
        closable={true}
        className="download-app-modal"
        // maskClosable={false}
        // keyboard={false}
      >
        <Row>
          <Col span={22} offset={1}>
            <OfflineApps />
          </Col>
          
        </Row>
      </Modal>
    </>
  );
};

export default DownloadAppsModal;
