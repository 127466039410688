import React, { useEffect, useState } from "react";

import { Button, Tabs, Tooltip } from "antd";
import { DeleteOutlined, DeleteFilled } from "@ant-design/icons";
import { AddButton, DeleteButton, CheckBox, CustomInput, TabAddIcon, InputArea, MCQArea, MCQList, TextArea, ShowText, ShowTextIndex, ShowTextContent, CustomLabel, CustomErrorMessage, showConfirmModal } from '/src/components/UI/Segment/UIHelper'
import MediaHoverButton from "/src/components/UI/Media/MediaHoverButton"
import MediaShow from "/src/components/UI/Media/MediaShow"
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import { DraggableTabs } from "/src/components/UI/DnD/DraggableTabs"
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { CKETextOptionTools } from "../CKEditor/CKEConfig";

export const DnDTabularTextBoxes = props => {
  console.log("DnD Tabular Text Boxes props", props);

  const { columns, addItem, removeItem, onTabDragEnd, onTextChanged, onTabEdit, removeFile, onSaveMedia } = props
  const { TabPane } = Tabs;
  const mrIntl = useTranslate()
  const columnsCount = columns.length
  const [tabSetting, setTabSetting] = useState({
    activeKey: columns[0] && columns[0].id.toString(),
    currentColumnsCount: columns.length
  })

  const onTabChange = (targetKey) => {
    setTabSetting({
      ...tabSetting,
      activeKey: targetKey
    })
  }

  useEffect(() => {
    console.log("columns count changed ==>", columnsCount)
    if (columnsCount > tabSetting.currentColumnsCount) {
      setTabSetting({
        ...tabSetting,
        activeKey: columns[columnsCount - 1].id.toString(),
        currentColumnsCount: columnsCount
      })
    }
  }, [columnsCount])

  const onEdit = (targetKey, action) => {
    let index
    if (action == 'remove') {
      index = columns.findIndex((column) => column.id == targetKey)
      let activeKey = ''
      if (index == columnsCount - 1) {
        if (index == 0) {
          activeKey = columns[index].id
        } else {
          activeKey = columns[index - 1].id
        }
      } else {
        activeKey = columns[index + 1].id
      }
      showConfirmModal({
        title: `Are you sure to remove this ${props.tabPrefix}?`,
        onOk: () => {
          setTabSetting({
            ...tabSetting,
            activeKey: activeKey.toString(),
            currentColumnsCount: columnsCount - 1
          })
          onTabEdit(index, action)
        },
        mrIntl: mrIntl
      })
    } else {
      onTabEdit(index, action)
    }
  }

  console.log("tab setting before render ==>", tabSetting, columns)
  return (
    <div>
      <DraggableTabs
        addIcon={<TabAddIcon
          iconText='Add'
        ></TabAddIcon>}
        type="editable-card"
        tabPosition={'top'}
        activeKey={tabSetting.activeKey}
        onEdit={onEdit}
        onChange={onTabChange}
        canDrag={true}
        order={columns}
        setOrder={onTabDragEnd}
        className="dnd-tabular-text-boxes"
      >
        {columns.map((column, index) => (
          <TabPane
            key={column.id}
            tab={`${props.tabPrefix} ${index + 1}`}
            closeIcon = {
              <Tooltip title={index <= 1 ? mrIntl("CommonText.minimum_limit_reached") : ""}>
                <Button
                  size="small"
                  disabled={index <= 1}
                  type="text"
                  icon={<DeleteFilled className="tab-pane-delete-icon" />}
                />
              </Tooltip>
            }
          >
            {props.editableTitle && <div style={{"marginBottom":"20px"}}>
              <CustomLabel key={`label-${column.id}`}> {props.titleLabel} </CustomLabel>
              <TextArea key={`text-area-${column.id}`}>
                <InputArea
                  key={`input-area-${column.id}`}
                  className={`singleline-input-area ${!(column.title || (column.files && column.files.length)) && 'custom-error'}`}
                >
                  <BasicCKE
                    key={`basic-ck-${column.id}`}
                    itemId={column.id}
                    data={column.title}
                    tools={CKETextOptionTools}
                    placeholder={column.placeholder}
                    onTextChanged={(data) => onTextChanged('column', index, data)}
                  >
                  </BasicCKE>
                  {column.files && <MediaShow
                    key={`media-show-${column.id}`}
                    file={column.files && column.files[0]}
                    removeFile={(e) => removeFile('column', index)}
                  >
                  </MediaShow>}
                  <CustomErrorMessage className="error-message" message={mrIntl("CommonText.required_msg")}></CustomErrorMessage>
                </InputArea>
                <MediaHoverButton
                  key={`media-hover-button-${column.id}`}
                  onSaveMedia={(files) => onSaveMedia('column', index, files)}
                ></MediaHoverButton>
              </TextArea>
            </div>}

            <CustomLabel key={`items-label-${column.id}`}> {props.itemsLabel} </CustomLabel>
            {column.items.map((item, childIndex) => (
              <TextArea key={`text-area-${item.id}`}>
                <InputArea
                  key={`input-area-${item.id}`}
                  className={`singleline-input-area ${!(item.content || (item.files && item.files.length)) && 'custom-error'}`}
                >
                  <BasicCKE
                    key={`basic-ck-${item.id}`}
                    itemId={item.id}
                    data={item.content}
                    placeholder={item.placeholder}
                    tools={CKETextOptionTools}
                    onTextChanged={(data) => onTextChanged('column', index, data, childIndex)}
                  >
                  </BasicCKE>
                  {item.files && <MediaShow
                    key={`media-show-${column.id}`}
                    file={item.files && item.files[0]}
                    removeFile={(e) => removeFile('column', index, childIndex)}
                  >
                  </MediaShow>}
                  <CustomErrorMessage className="error-message" message={mrIntl("CommonText.required_msg")}></CustomErrorMessage>
                </InputArea>
                <MediaHoverButton
                  key={`media-hover-button-${item.id}`}
                  onSaveMedia={(files) => onSaveMedia('column', index, files, childIndex)}
                ></MediaHoverButton>
                {removeItem && <DeleteButton
                  key={`delete-button-${item.id}`}
                  clicked={(e) => removeItem('column', index, childIndex)}
                  disabled={column?.items.length == 1}
                  tooltipTitle= {column?.items.length == 1 ? mrIntl("CommonText.minimum_limit_reached") : ""}
                >
                  <DeleteOutlined />
                </DeleteButton>}
              </TextArea>
            ))}

            {addItem && <AddButton
              clicked={(e) => addItem(index)}
            />}
          </TabPane>
        ))}
      </DraggableTabs>
    </div>
  );
}