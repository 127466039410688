export const v3ThemeOptions = {
  cssVar: { key: 'ap-app', prefix: "ap" },
  token: {
    // Seed Token
    colorPrimary: "#00a6bd",
    colorInfo: "#00a6bd",
    colorSuccess: "#61cd79",
    colorWarning: "#fcab4c",
    colorError: "#ff4050",
    borderRadius: "10",
    colorBgBase: "#ffffff",
    colorBgLayout: "#F8F9FA", 

    // Alias Token
    // colorBgContainer: '#f6ffed',
  },
  "components": {
   "Input": {
      borderRadius: 10,
      borderRadiusLG: 10,
      borderRadiusSM: 10,
      colorTextPlaceholder: "rgba(0,0,0,0.81)",
      hoverBorderColor: "rgb(255,255,255)",
      colorBorder: "rgb(255,255,255)",
      controlHeightLG: 43,
      activeBorderColor: "rgb(255,255,255)",
      paddingBlock: 11,

      
    },
    "Radio": {
      borderRadius: "10",
      borderRadiusLG: "10",
      borderRadiusSM: "8",
      colorPrimaryHover: "#00a6bd"
    },
    "Button": {
      defaultShadow: "0 4px 0 rgba(0, 0, 0, 0.02)",
      borderRadius: 10,
      borderRadiusLG: 10,
      borderRadiusSM: 10,
      fontWeight: 600,
      primaryColor: "rgba(0,0,0,0.88)",
      colorPrimary: "rgb(255,255,255)",
      paddingBlock: 10,
      paddingInline: 20,

    }

  }
  // "algorithm": theme.darkAlgorithm
}