import { message } from "/src/components/UI/AntdAppHelper";
import axios from "axios";

export const uploadVideoToBackend = (file, options) => {
  console.log("uploadVideoToBackend ==>", file, options);
  const { successCallback, errorCallback } = options;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      token: token,
    },
  };

  const get_vimeo_url_link =
    (import.meta.env.VITE_API_URL || "/backend/api/v1/") + "get_vimeo_url";
  const data = { size: file.size, name: file.name };

  axios.post(get_vimeo_url_link, data, config).then((response) => {
    console.log("get_vimeo_url_link response ==>", response);
    const responseData = response.data;
    const tus = window.tus;
    const videoId = responseData.uri && responseData.uri.match(/\d+/g)[0];
    const uploadLink = responseData.upload && responseData.upload.upload_link;

    const vimeo_url = `https://player.vimeo.com/video/${videoId}`;
    let upload = new tus.Upload(file, {
      uploadUrl: uploadLink,
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        console.log(bytesUploaded, bytesTotal, percentage + "%");
      },
      onSuccess: function (response) {
        console.log("response after upload", response, upload, upload.file)
        const file = upload.file;

        let newFile = {
          id: videoId,
          url: vimeo_url,
          name: file.name,
          size: file.size,
          type: file.type,
        };
        successCallback(newFile);
        message.success(`${file.name} file uploaded successfully`);
      },
      onError: function (error) {
        console.log("Vimeo Upload Failed because: " + error);
        errorCallback(error);
      },
    });
    upload.start();
  });

  return false;
};

export const processForConversion = (data, options) => {
  const { successCallback, errorCallback } = options;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      token: token,
    },
  };

  const convert_attachments_url =
    (import.meta.env.VITE_API_URL || "/backend/api/v1/") +
    "convert_attachment";

  axios.post(convert_attachments_url, data, config)
    .then((response) => {
      console.log("Response from convert attachments ==>", response);
      if (successCallback) {
        successCallback(response.data);
      }
    })
    .catch((error) => {
      console.log("Video conversion failed", error);
      if (errorCallback) {
        errorCallback(error);
      }
    });
};