import React, { useEffect, useState } from "react";

import { Tabs, Input, Radio, Button, Checkbox, Upload, Row, Col } from "antd";
import { InboxOutlined, UploadOutlined, SoundOutlined, PauseCircleOutlined, PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import { initialData, addItemToArray, removeItemFromArray, convertDataAsPerNewSystem, getParsedJSONObject, numaricalSort } from "/src/views/Segments/InteractiveHelpers";
import { AddButton, DeleteButton, CheckBox, InputArea, MCQArea, MCQList, TextArea, ShowText, ShowTextIndex, ShowTextContent } from '/src/components/UI/Segment/UIHelper'
import { getUniqueId } from '/src/lib/utils/helperMethods';
import { MrInput } from "mr_react_framework"
import { debounce } from 'lodash';
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import InsertFromURL from "/src/components/UI/Media/InsertFromURL";
import BasicCKE from "/src/components/UI/CKEditor/BasicCKE";
import SliderImages from "./SliderlImages";
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments"
import ListAttachments from "/src/components/UI/Attachments/ListAttachments";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ImageView = props => {
	console.log("Image view props ==>", props);
	const { createMode, printMode, resource_json, onTabEdit, onTextChanged, caption, title } = props
  const mrIntl = useTranslate()

	// const [state, setState] = useState(resource_json)
	// const [stateUpdatedCount, setStateUpdatedCount] = useState(0)
	// const [mediaSetting, setMediaSetting] = useState({})

	const segment_data = resource_json.segment_data
	const resource_setting = resource_json.resource_setting || {}
	const attachments = segment_data.attachments || []

	console.log("Image view state before render ==>", resource_json, resource_setting, resource_json, resource_setting.view_type == "slider", resource_setting.view_type)

	return (
		<div>
			{!printMode
				?
				(!createMode && resource_setting.view_type == 'slider')
					?
					<SliderImages
						key={`slider-images-${props.unique_segment_identifier}`}
						// createMode={createMode}
						attachments={attachments}
						resource_setting={resource_setting}
					></SliderImages>
					:
					<TabularAttachments
						key={`tabular-attachments-${props.unique_segment_identifier}`}
						attachmentType="image"
						tabPrefix={mrIntl("CommonText.image")}
						attachments={attachments}
						resource_setting={resource_setting}
					></TabularAttachments>
				:
				<ListAttachments 
					key={`list-attachments-${props.unique_segment_identifier}`}
					titlePrefix={'Image'}
          caption={caption}
          title={title}
					attachments={attachments}>
				</ListAttachments>
			}
		</div>
	);
}
export default ImageView;