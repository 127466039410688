import React, { useState } from "react";
import { Button } from "antd"; 
import { ReloadOutlined } from "@ant-design/icons";

const RefreshBtn = () => {
  const [loading, setLoading] = useState(false);
  const handleRefresh = () => {
    setLoading(true);
    window.location.reload();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <Button
      shape="circle"
      loading={loading}
      onClick={handleRefresh}
      icon={<ReloadOutlined />}
    >
    </Button>
  );
};

export default RefreshBtn;