import React, { useEffect, useState } from "react";
import { CheckOutlined, PlusCircleTwoTone, LineOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal, Select, Tooltip, Table, Menu, Tag, Space, Row, Col } from "antd";
import { MrSelect } from "mr_react_framework";
import { useDispatch, useSelector } from "react-redux";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { experienceUsersSelector } from "../selector";
import "./ExperienceUsersActions.scss";
import { find, countBy, unionBy } from "lodash";
import { message } from "/src/components/UI/AntdAppHelper";
import MrTranslate, { mrTranslate, useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { actions as genericActions } from "/src/App/genericRedux";

const SelectStudents = MrSelect({
  actions: genericActions,
  resourceName: "students",
  apiResourceName: "users",
  config: {
    url: "/users",
    params: { by_role: "student", page_size: 100 }, //default is org_id on BE
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      const { alreadyAddedStudentIds, showOnlyMappedStudents, mappedStudentsWithoutSubmission } = props.config?.widgetConfig || {};
      const mappedStudentsWithoutSubmissionIds = mappedStudentsWithoutSubmission.map(student => student.id);

      let searchList = [];
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (!alreadyAddedStudentIds.includes(item.id) 
            // && 
            // (!showOnlyMappedStudents || mappedStudentsWithoutSubmissionIds.includes(item.id))
          ) {
          searchList.push({
            label: item.name,
            value: `${item.id}_${item.name}_${item.grade && item.grade.label}`,
          });
        }
      }
      return searchList;
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true,
      showArrow: true,
      filterOption: true,
      optionFilterProp: "label",
      placeholder: <MrTranslate id={"CommonText.search_for_students"} />,
      // mode: "multiple",
      style: { width: "100%" },
    },
  },
});

const CreateNewSubmissionModal = ({ experience }) => {
  
  const mrIntl = useTranslate()
  const dispatch = useDispatch();

  const experienceUsers = useSelector(experienceUsersSelector());

  const [showModal, setShowModal] = useState(false);
  const [listItems, setListItems] = useState([]);
  const [unmappedStudentsInListCount, setUnmappedStudentsInListCount] = useState(0)
  const [showMappedStudents, setShowMappedStudents] = useState(false);
  const [loading, setLoading] = useState(false);

  const mappedStudents = experience.students || []
  const isMappedStudents = mappedStudents.length > 0;
  const listItemsCount = listItems.length
  const studentsInListIds = listItems.map((item) => item.key)
  const studentsHavingSubmissionsIds = experienceUsers.map(user => user.user_id)
  const alreadyAddedStudentIds = [...studentsInListIds, ...studentsHavingSubmissionsIds]
  const mappedStudentsWithoutSubmission = []
  const maxAllowedUnmappedSubmissions = 20
  const isMbLinked = experience.is_mb_linked

  mappedStudents.forEach(student => {
    if (!studentsHavingSubmissionsIds.includes(student.id)) {
      mappedStudentsWithoutSubmission.push(student)
    }
  })
  
  const mappedStudentsWithoutSubmissionCount = mappedStudentsWithoutSubmission.length

  const toggleCreateSubmissionsModal = () => {
    if (showModal) {
      setListItems([])
      setShowMappedStudents(false)
      setUnmappedStudentsInListCount(0)
    }
    setShowModal(prevState => !prevState)
  }

  // NOTE: Still may need to optimize this
  const handleCreateSubmissions = () => {
    setLoading(true);
    const newSubmissionKeys = listItems.map(data => data.key)
    let newSubmissionParams = {
      experienceId: experience.id,
      newSubmissionIds: newSubmissionKeys,
    };
    dispatch(
      experienceUserActions.createNewSubmissions(newSubmissionParams, {
        successCallback: (updatedExperienceUsers, failedExperienceUsersLength) => {
          console.log("updatedExperienceUsers, failedExperienceUsersLength===>", updatedExperienceUsers, failedExperienceUsersLength)
          if (failedExperienceUsersLength > 0) {
            const failedSubmissions = listItems.filter(
              (item) => {
                let failed = !updatedExperienceUsers.some((updatedEU) => {
                  return (
                    // NOTE: It should not need this condition check wheres the issue
                    updatedEU.user_id
                      ? (updatedEU.user_id === item.key)
                      : (updatedEU.attributes.user_id === item.key)
                  )
                })
                console.log("failed? ", failed);
                return failed
              }
            );
            setListItems(failedSubmissions)
          } else {
            toggleCreateSubmissionsModal()
          }
          setLoading(false);
        },
        errorCallback: (error) => {
          setLoading(false);
          console.error("createNewSubmissionsSaga error", error);
        },
      })
    );
  };

  const tableColumns = [
    {
      title: mrIntl("CommonText.name"),
      dataIndex: "name",
      ellipsis: true,
      width: 300,
      align: "left"
    },
    {
      title: mrIntl("CommonText.grade"),
      dataIndex: "grade",
      width: 200,
      align: "left"
    },
    {
      title: mrIntl("FabricAnnotator.mapped"),
      dataIndex: "mapped",
      align: "center",
      width: 100
    },
    {
      title: mrIntl("CommonText.remove"),
      dataIndex: "remove",
      align: "center",
      width: 100
    }
  ];

  const isCheckboxDisabled = () => {
    if (mappedStudentsWithoutSubmissionCount === 0 ) {
      return true
    } else {
      return false
    }
  }
  const removeStudentFromList = (student_id) => {
    console.log("removeStudentFromList", student_id)
    setListItems((prevState) =>
      prevState.filter((item) => item.key !== student_id)
    );
  };

  const addStudentsDataToListItems = (studentsData) => {
    const newListItems = studentsData.map(studentData => {
      return {
        key: studentData.id,
        name: studentData.name,
        grade: studentData.grade,
        mapped: 
          studentData.mapped 
            ? <CheckOutlined />
            : <LineOutlined />,
        remove: (
          <DeleteOutlined
            onClick={() => removeStudentFromList(studentData.id)}
          />
        ),
      }
    })

    const updatedListItems = unionBy(newListItems, listItems, 'key');

    // if (updatedListItems.length > maxAllowedUnmappedSubmissions) {
    //   updatedListItems.length = maxAllowedUnmappedSubmissions
    // }

    setListItems(updatedListItems);
  };

  const updateMappedStudentsInList = (checked) => {
    setShowMappedStudents(checked);
    if (checked) {
      const studentsData = mappedStudentsWithoutSubmission.map((student) => {
        return {
          id: student.id,
          name: student.name,
          grade: student.grade && student.grade.label,
          mapped: true,
        };
      })
      addStudentsDataToListItems(studentsData)
    } else {
      mappedStudents.forEach((student) => {
        removeStudentFromList(student.id);
      })
    }
  };

  const addStudentToList = (value) => {
    if (unmappedStudentsInListCount < maxAllowedUnmappedSubmissions) {
      let studentData = value && value.split("_");
      const student_id = parseInt(studentData[0])
      let isStudentMapped = find(mappedStudents, (mappedStudent) => {
        return (mappedStudent.id === student_id);
      });
      let studentsData = [{
        id: student_id,
        name: studentData[1],
        grade: studentData[2],
        mapped: isStudentMapped,
      }];
      addStudentsDataToListItems(studentsData);
      if(!isStudentMapped){
        setUnmappedStudentsInListCount((count) => count + 1)
      }
    } else {
      message.warning(mrIntl("CreateNewSubmissionModal.cannot_add_more_than_max_allowed_unmapped_students_at_once", { maxAllowedUnmappedSubmissions }))
    }
  };

  return (
    <>
      <Button
        className="create-submissions-button"
        type="text"
        icon={<PlusCircleTwoTone twoToneColor="#52c41a" />}
        onClick={() => toggleCreateSubmissionsModal()}
      >
        {mrIntl("CreateNewSubmissionModal.create_new_submission")}
      </Button>
      <Modal
        title={mrIntl("CreateNewSubmissionModal.create_submissions")}
        className="create-submissions-modal"
        open={showModal}
        onCancel={() => toggleCreateSubmissionsModal()}
        width="65%"
        maskClosable={false}
        footer={[
          <Row className="create-submissions-modal-footer">
            <Col span={12} className="students-count">
              <Tag>
                {listItemsCount +
                  " " +
                  (listItemsCount == 1
                    ? mrIntl("CreateNewSubmissionModal.student_selected")
                    : mrIntl("CreateNewSubmissionModal.students_selected"))}
              </Tag>
            </Col>
            <Col span={12} className="action-buttons">
              <Space>
                <Tooltip
                  title={
                    listItemsCount === 0 &&
                    mrIntl("CreateNewSubmissionModal.please_add_atleast_one_student")
                  }
                >
                  <Button
                    key="back"
                    type="primary"
                    disabled={listItemsCount === 0}
                    onClick={() => handleCreateSubmissions()}
                    loading={loading}
                  >
                    {listItemsCount === 1
                      ? mrIntl("CreateNewSubmissionModal.create_submission")
                      : mrIntl("CreateNewSubmissionModal.create_submissions")}
                  </Button>
                </Tooltip>
                <Button key="submit" onClick={() => toggleCreateSubmissionsModal()}>
                  {mrIntl("CommonText.cancel")}
                </Button>
              </Space>
            </Col>
          </Row>,
        ]}
      >
        <SelectStudents
          // value={null} because we dont want any value inside search bar
          key="create-submissions-select-students"
          value={null}
          className="select-students"
          onSelect={(value) => addStudentToList(value)}
          config={{
            forceReload: false,
            clearValueOnParamsChange: false,
            widgetConfig: {
              mode: "multiple",
              alreadyAddedStudentIds: alreadyAddedStudentIds,
              showOnlyMappedStudents: isMbLinked,
              mappedStudentsWithoutSubmission: mappedStudentsWithoutSubmission
            }
          }}
        />
        <Tooltip
          title={
            !isMappedStudents ? mrIntl("CreateNewSubmissionModal.no_mapped_students") : mappedStudentsWithoutSubmissionCount === 0 ? mrIntl("CreateNewSubmissionModal.mapped_students_already_added") : ""
          }
        >
          <Checkbox
            className="select-all-mapped-students-checkbox"
            checked={showMappedStudents}
            disabled={isCheckboxDisabled()}
            onClick={(e) => {
              updateMappedStudentsInList(e.target.checked);
            }}
          >
            {mrIntl("CreateNewSubmissionModal.select_all_mapped_students")}
          </Checkbox>
        </Tooltip>
        {!listItemsCount == 0 && (
        <Table
          className="create-submissions-modal-table"
          columns={tableColumns}
          dataSource={listItems}
          pagination={false}
        />
        )}
      </Modal>
    </>
  );
};

export default CreateNewSubmissionModal;