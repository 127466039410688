import React, { useState } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Modal, Tooltip, Row, Col, Alert } from "antd";
import {
  UploadOutlined,
} from "@ant-design/icons";
import QRCode from "qrcode.react";
import { getFromLS } from "/src/lib/utils/helperMethods";
import { getInternetStatusSelector } from "/src/views/Experiences/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const internetStatusSelector = getInternetStatusSelector();
const QRModal = (props) => {
  const { url, path } = useRouteMatch();
  const location = useLocation();
  const { experience, setShowQRModal, showQRModal } = props;
  console.log("QRModal props", url, path, location);
  const mrIntl = useTranslate();
  const internetStatus = useSelector(internetStatusSelector);
  // const [visible, setVisible] = useState(false);
  const [token, setToken] = useState(getFromLS("token"));

  const showModal = () => {
    setShowQRModal(true);
  };

  const handleOk = (e) => {
    setShowQRModal(false);
  };

  const handleCancel = (e) => {
    setShowQRModal(false);
  };

  let offlineAlert;
  if (internetStatus !== "online") {
    offlineAlert = (
      <Alert
        message={mrIntl("QRModal.it_seems_you_are_not_connected_to_the_internet")}
        banner
        closable={false}
      />
    );
  }

  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Share
      </Button> */}
      <Tooltip
        // title="Upload using QR code"
        title={mrIntl("QRModal.upload_using_qr_code")}
        placement="bottom"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      >
        {/* <Button shape="circle" icon={<QrcodeOutlined />} onClick={showModal}/> */}
        <Button
          shape="round"
          danger
          ghost={true}
          icon={<UploadOutlined />}
          onClick={showModal}
        >
        {mrIntl("CommonText.upload")}
        </Button>
      </Tooltip>
      <Modal
        title= {mrIntl("QRModal.upload_handwritten_answers")}
        // title="Upload handwritten answers"
        open={showQRModal}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        wrapClassName="qr-modal"
        closable={internetStatus === "online"}
        maskClosable={false}
        keyboard={false}
      >
        {offlineAlert}
        <Row>
          {/* <Row style={{"textAlign":"center"}}> */}
          {/* <QRCode value="http://facebook.github.io/react/"  /> */}
          {/* <h4>Scan the code on your phone and open the link to upload documents</h4> */}
          <Col span={10} offset={1}>
            {/* PROD */}
            <QRCode
              value={`https://${window.location.host}/e/tests/${experience.uid}?token=${token}&mobileUploadMode=true`}
              size={250}
              style={{ marginTop: "15px" }}
            />
            {/* DEV */}
            {/* <QRCode value={`http://${window.location.host}/e/tests/${experience.uid}?token=${token}&mobileUploadMode=true`} size={250} style={{"marginTop":"15px"}}/> */}
          </Col>
          <Col span={10} offset={1}>
            {/* <b> */}
            {mrIntl("QRModal.steps_to_upload")}
            {/* </b> */}
            <br></br>
            <ol>
              <li>
               {mrIntl("QRModal.scan_qr_code_msg")}
              </li>
              <li> {mrIntl("QRModal.click_on_the_link_to_open_in_a_browser")} </li>
              <li>
              {mrIntl("QRModal.can_upload_photos_with_each_question_msg")}{" "}
              </li>
              <li>
              {mrIntl("QRModal.finish_answer_file_upload_info_msg")}{" "}
              </li>
            </ol>
            <b> {mrIntl("QRModal.note")}</b>
            <ol>
              <li>
              {mrIntl("QRModal.only_25_images_can_be_uploaded_per_question_msg")}{" "}
              </li>
            </ol>
          </Col>

          {/* <Card title="Test settings and publish"> */}

          {/* </Card> */}
        </Row>
      </Modal>
    </>
  );
};

export default QRModal;
