import {
  Alert,
  Button,
  Col,
  InputNumber,
  Modal,
  Popover,
  Row,
  Space,
  Tag,
  Tooltip,
  message,
} from "antd";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import { actions as experienceUserActions } from "/src/views/ExperienceUsers/redux";
import { QuestionCircleOutlined, CalculatorOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { experienceUsersCountSelector } from "../selector";



let checkValidValue = (index, value, array) => {
  console.log("index==>", index, value, array);
  for (let i = 0; i < index; i++) {
    if (array[i] && array[i] !== "" && array[i] !== 0 && index !== 9) {
      if (array[i] >= value) {
        return "small"
      }
    }
  }
  for (let i = index + 1; i < array.length; i++) {
    if (array[i] && array[i] !== "" && array[i] !== 0 && index !== 9) {
      if (array[i] <= value) {
        return "large"
      }
    }
  }
  return false;
};



const ConvertToAchievementLevelLegacy = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [exceededIndex, setExceededIndex] = useState(null);
  const [exceededCriteriumId, setExceededCriteriumId] = useState(null);
  const mrIntl = useTranslate();

  const firstInputRef = useRef({});

  let disableConvertAchievementLevelButton = false;
  const [tooltipMsg, setTooltipMsg] = useState("");
  activeExperience && activeExperience.criterium_associations && activeExperience.criterium_associations.length > 0 && activeExperience.criterium_associations.forEach((criterium) => {
    if (criterium.points < 8) {
      disableConvertAchievementLevelButton = true;
    }
  });
  const [dataSource, setDataSource] = useState([]);

  const experienceUsersCount = useSelector(experienceUsersCountSelector())
  const isExperienceUsersExist = experienceUsersCount === 0 ? false : true
  const isAnyInputEmpty = () => {
    const tempDataSource = [...dataSource];
    return tempDataSource.some(row => {
      console.log("row==>", row.levels);
      return row.levels.some(key => key === "" || key === null);
    });
  };

  const handleErrorAndUpdateDataSource = (tempDataSource, rowIndex, criteriumId, parentIndex, errorMessageKey) => {
    const item = tempDataSource.find((obj) => obj.criterium_id === criteriumId);
    item.levels.splice(rowIndex, 1, null);
    tempDataSource[parentIndex]['levels'] = item.levels;
  
    setError(true);
    setExceededIndex(rowIndex);
    setExceededCriteriumId(criteriumId);
    setDataSource(tempDataSource);
    setErrorMessage(errorMessageKey);
  };
  
  const onInputChange = (e, rowIndex, criteriumId, criteriumPoints, parentIndex) => {
    const tempDataSource = [...dataSource];
  
    const item = tempDataSource.find((obj) => obj.criterium_id === criteriumId);

  
    if (e === null || Math.sign(e) !== 1 || e >= criteriumPoints || !Number.isInteger(e)) {
      let errorMessageKey;
  
      if (Math.sign(e) === -1) {
        errorMessageKey = mrIntl("ConvertToAchievementLevelLegacy.enter_a_positive_value");
      } else if (e === 0) {
        errorMessageKey = mrIntl("ConvertToAchievementLevelLegacy.enter_a_non_zero_value");
      } else if (!Number.isInteger(e)) {
        errorMessageKey = mrIntl("ConvertToAchievementLevel.enter_a_whole_number");
      } else {
        errorMessageKey = mrIntl("ConvertToAchievementLevelLegacy.must_be_less_than_the_maximum_points");
      }
  
      handleErrorAndUpdateDataSource(tempDataSource, rowIndex, criteriumId, parentIndex, errorMessageKey);
      return;
    }
  
    if (rowIndex !== 0) {
      const isValueSmallOrLarge = checkValidValue(rowIndex, e, item.levels);
  
      if (isValueSmallOrLarge === "small" || isValueSmallOrLarge === "large") {
        const errorMessageKey = isValueSmallOrLarge === "small" ? mrIntl("ConvertToAchievementLevelLegacy.must_be_greater_than_the_previous_value") : mrIntl("ConvertToAchievementLevelLegacy.must_be_less_than_the_next_value");
        handleErrorAndUpdateDataSource(tempDataSource, rowIndex, criteriumId, parentIndex, errorMessageKey);
        return;
      }
    }
  
    setError(false);
    setExceededIndex(null);
    setExceededCriteriumId(null);
    setErrorMessage("");
  
    item.levels.splice(rowIndex, 1, e);
    tempDataSource[parentIndex]['levels'] = item.levels;
    setDataSource(tempDataSource);
  };
  
  const debouncedOnInputChange = debounce(onInputChange, 200);
  const handleInputChange = useCallback(debouncedOnInputChange, [dataSource]);

  const equivalentLevel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    // doing this because the ref initialize after sometime
    setTimeout(() => {
      if (isModalOpen && firstInputRef && firstInputRef.current) {
        console.log("firstInputRef==>", firstInputRef.current)
        firstInputRef.current.focus();
      }
    }, 100);
  }, [isModalOpen, firstInputRef]);

  useEffect(() => {
    
    const updatedDataSource = activeExperience.criterium_associations.map((criterium) => {
      const obj = {
        criterium_points: criterium.points,
        criteria_label: criterium.custom_fields.title,
        criterium_id: criterium.criterium_id,
      };
      
      const criteriumCustomFields = criterium?.custom_fields || {};
      const cwpALConversions = criteriumCustomFields.cwp_al_conversions || {};
      const cwpALConversionRanges = criteriumCustomFields.cwp_al_conversion_ranges || [];

      if (cwpALConversions.ranges && cwpALConversions.ranges.length > 0 && cwpALConversions.type === "points") {  
        obj.levels = [...cwpALConversions.ranges];
      } else if (Array.isArray(cwpALConversionRanges) && cwpALConversionRanges.length > 0) {
        obj.levels = [...cwpALConversionRanges];
      } else {
        obj.levels = equivalentLevel.map((item, level) => (level === 0 ? 0 : level === 9 ? criterium.points : ''));
      }
      return obj;
    });

    setDataSource(updatedDataSource);
  }, []);
  

  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const calculateAchievementLevel = () => {
    const tempDataSource = [...dataSource];
    const resultData = tempDataSource.map((obj) => {
      return {
        criterium_id: obj.criterium_id,
        ranges: obj.levels,
      };
    })
    showConfirmModal({
      centered: true,
      title: mrIntl("ConvertToAchievementLevel.this_action_will_overwrite_any_existing_overall_achievement_levels_with"),
      okText: "Proceed",
      cancelText: "Cancel",
      onOk: () => {
        dispatch(
          experienceUserActions.convertToAchievementLevel({
            experience_id: activeExperience.id,
            criteria_boundary: resultData,
            type: "points",
          }, {
            successCallback: () => {
              message.success(mrIntl("ConvertToAchievementLevel.achievement_levels_calculated_successfully"));
              setIsModalOpen(false);
            }
          })
        );
        
      },
      onCancel: () => {},
      mrIntl: mrIntl,
    });
  };
  return (
    <>
      <Tooltip placement="left" title={!isExperienceUsersExist ? "" : mrIntl("ConvertToAchievementLevel.convert_pointbased_scores_into_achievement_levels_on_a_scale_from")}>
        <Button
          className="convert-to-achievement-level-button"
          type="text"
          ghost={true}
          onClick={showModal}
          icon={<CalculatorOutlined />}
          disabled={!isExperienceUsersExist}
        >
          {mrIntl("ConvertToAchievementLevel.convert_to_achievement_level")}
        </Button>
      </Tooltip>
      <Modal
        title={
          <span>
            {mrIntl("ConvertToAchievementLevel.convert_to_achievement_level")}
            <Popover
              content={
                <p className="convert-to-achievement-level-popover-msg">
                  {mrIntl("ConvertToAchievementLevelLegacy.this_tool_converts_point_values_into_achievement_levels_on_a")}
                </p>
              }
              trigger="hover"
            >
              <QuestionCircleOutlined style={{ marginLeft: 5 }} />
            </Popover>
          </span>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"100%"}
        footer={null}
        wrapClassName={"convert-to-achievement-level-modal"}
      >
        <hr />
        <Row>
          {disableConvertAchievementLevelButton && (
            <Col span={24} className="achievement-level-calculator-warning">
              <Alert
                type="error"
                message={mrIntl(
                  "ConvertToAchievementLevelLegacy.criteria_total_must_be_at_least_8_or_higher_to"
                )}
              />
            </Col>
          )}
          <Col span={24}>
            <Row className="convert-to-achievement-level-heading">
              <Col span={2}><Tag className="level-label">Criteria</Tag></Col>
              {[...Array(9)].map((level, index) => {
                return (
                  <Col span={1} offset={1} className="level-label-heading">
                    <Space>
                      <Tag className="level-label">{mrIntl("ConvertToAchievementLevelLegacy.level")} {index}</Tag>
                    </Space>
                    <div className="main-separator">
                      <div className="main-vertical-line"></div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            {dataSource &&
              dataSource.length > 0 &&
              dataSource.map((obj, dateSourceIndex) => {
                return (
                  <Row className="convert-to-achievement-level-grid">
                    <Col  span={2}><Tag className="level-label">{obj.criteria_label}</Tag></Col>
                    {obj.levels.map((level, objectLevelIndex) => {
                      return (
                        <>
                        <Col className="level-input" span={1}>
                          <Space>
                            <InputNumber
                              keyboard={false}
                              controls={false}
                              ref={objectLevelIndex === 1 && dateSourceIndex === 0 ? firstInputRef : null}
                              className={`achievement-level-input ${
                                objectLevelIndex === 0
                                  ? "achievement-level-first-input"
                                  : ""
                              }`}
                              status={
                                error &&
                                objectLevelIndex == exceededIndex &&
                                obj.criterium_id == exceededCriteriumId &&
                                "error"
                              }
                              value={level}
                              disabled={
                                objectLevelIndex === 0 ||
                                objectLevelIndex === 9 ||
                                disableConvertAchievementLevelButton
                              }
                              onChange={(e) => {
                                handleInputChange(e, objectLevelIndex, obj.criterium_id, obj.criterium_points, dateSourceIndex);
                              }}
                            />
                          </Space>          
                              <br />
                            {error &&
                              objectLevelIndex == exceededIndex &&
                              obj.criterium_id == exceededCriteriumId && (
                                <>
                                  <span style={{ color: "red", fontSize: "10px", position: 'absolute', minWidth: "75px" }}>
                                    {errorMessage}
                                  </span>
                                </>
                              )}
                        </Col>
                        {objectLevelIndex !== 9 && (
                          <Col span={1} className="separator">
                              <hr />
                              <div className={`${dateSourceIndex === 0 && "first-row-vertical-separator"} row-vertical-separator`} ></div>
                          </Col>
                        )}
                        </>
                      );
                    })}
                  </Row>
                );
              })}
          </Col>
        </Row>
        <Row>
          <Col span={24} className="achievement-level-calculator-button">
            <Tooltip title={disableConvertAchievementLevelButton ? tooltipMsg : ""}>
              <Button
                disabled={
                  error || disableConvertAchievementLevelButton || isAnyInputEmpty()
                }
                type="primary"
                onClick={() => calculateAchievementLevel()}
              >
                {mrIntl("ConvertToAchievementLevelLegacy.calculate_and_assign")}
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ConvertToAchievementLevelLegacy;
