import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Space,
  Alert,
  Result,
  Tag,
  Col,
  Button,
  Tooltip,
  Spin,
  Input,
  Form,
} from "antd";
import { CalendarOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import CountDown from "react-countdown";
import queryString from "query-string";
import {
  writeToClipboardPolyfill,
  currentTimeValidWrtServerTime,
  displayPoints,
} from "/src/lib/utils/helperMethods";
import { extraTimeSelector, setUserInfoLoadingSelector, startedAtSelector } from "/src/views/Experiences//ExperienceShow/FirestoreInteractions/selector";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { actions as experienceActions } from "/src/views/Experiences/redux";
import {
  endSessionBtnLoadingSelector,
  experienceConfigSelector,
  experienceLoadingSelector,
  experienceViewModeSelector,
  getServerTimeOffsetSelector,
} from "/src/views/Experiences/selector";
import StudentCodeAckModal from "/src/views/Experiences/ExperienceShow/Components/StudentCodeAckModal";
import {
  currentUserSelector,
  enabledFeaturesSelector,
  getEmbeddedSelector,
} from "/src/views/Auth/Login/selector";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import "../ExperienceItemDetail.scss";
import StartInSebBtn from "/src/components/UI/SEB/StartInSebBtn";
import DownloadAppsModal from "/src/views/OfflineApps/DownloadAppsModal";
import screenfull from "screenfull";
import { firestoreFieldValue } from "/src/config/initializers";
import { actions as loginActions } from "/src/views/Auth/Login/redux";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

import BackToHomeBtn from "/src/components/UI/BackToHomeBtn";
import { isOfflineExperienceAndNotAllowedOnline } from "/src/views/Experiences/ExperienceHelperMethods";
import QuitBtn from "/src/components/UI/QuitBtn";
import { getAppNativeVars, getDeviceInfo } from "/src/App/OfflineApp/offlineAppHelper";

const StartTestLoader = (props) => {
  let isSetUserInfoLoading = useSelector(setUserInfoLoadingSelector);
  if (!isSetUserInfoLoading) return null;
  return <>{isSetUserInfoLoading && <Spin size="large" />}</>;
};

const CountDownWrapper = (props) => {
  const {
    targetTime,
    config,
    startCountDownTriggerMins,
    showBeforeCountDownStarts,
    showAfterCountDownComplete,
    isJoinCodeCorrect
  } = props;
  console.log("CountDownWrapper props", props);
  // const {showTimer} = config;
  const showTimer = true;
  const mrIntl = useTranslate()
  // const [showChildren, setShowChildren] = useState(false);
  const [finalRender, setFinalRender] = useState(null);
  // const timeDiff = targetTime - currentDatetime;
  const countDownTimer = (
    <Tag color="orange">
      {mrIntl("JoinView.starts_in")}{" "}
      <CountDown
        date={targetTime}
        daysInHours={true}
        onComplete={() => setFinalRender(showAfterCountDownComplete)}
      />
    </Tag>
  );

  // check utc vs local time ir checkstarttime?
  useEffect(() => {
    let targetTimeUTC = Date.parse(targetTime);
    let currentTimeUTC = new Date().getTime();

    if (targetTime && targetTimeUTC > currentTimeUTC) {
      // console.log("Date.parse(targetTime) - new Date().getTime()", targetTime, new Date(), Date.parse(targetTime), new Date().getTime(), Date.parse(targetTime) - new Date().getTime())
      if (targetTimeUTC - currentTimeUTC > 60 * 60 * 1000) {
        // 1 hr in milliseconds
        setFinalRender(showBeforeCountDownStarts);
      } else {
        setFinalRender(countDownTimer);
      }
    } else {
      setFinalRender(showAfterCountDownComplete);
    }
  }, [isJoinCodeCorrect]);

  return (
    <>
      {finalRender}
      {/* {!showChildren && <span style={{visibility: showTimer ? "visible" : "hidden" }}>
        {countDownTimer}
      </span>}
      
      {showChildren && props.children} */}
    </>
  );
};
const InputJoinCode = (props) => {
  const {
    joinCodeDefaultValue,
    isJoinCodeCorrect,
    setIsJoinCodeCorrect,
    expUUID
  } = props;
  console.log("InputJoinCode props", props);
  const dispatch = useDispatch()
  const loading = useSelector(experienceLoadingSelector())
  const joinCodeLength = 6;
  const [validationFailed, setValidationFailed] = useState(false)
  const mrIntl = useTranslate()

  const verifyOnChange = (joinCode) => {
    dispatch(experienceActions.verifyJoinCode({uuid: expUUID, join_code: joinCode}, 
      {
        successCallback: () => {
          setValidationFailed(false)
          setIsJoinCodeCorrect(true)
        },
        errorCallback: () => {
          setValidationFailed(true)
          setIsJoinCodeCorrect(false)
        }
    }))
  }

  useEffect(() => {
    if (joinCodeDefaultValue) {
      verifyOnChange(joinCodeDefaultValue) 
    }
  }, [])

  return (
    <Form.Item
      validateStatus={validationFailed ? "error" : ""}
      help={validationFailed && "Incorrect code"}
    >
      <Input
        key="join-code-input"
        placeholder={mrIntl("JoinView.enter_join_code")}
        defaultValue={joinCodeDefaultValue}
        disabled={isJoinCodeCorrect  ? true : false}
        className={"input-join-code"}
        // status={showError && "error"}
        maxLength={joinCodeLength}
        onChange={(e) => {
          const joinCode = e.target.value;
          if (joinCode.length === joinCodeLength) {
            verifyOnChange(joinCode)
          }
        }}
      />
      <br/>
      {<Spin spinning={loading} />}
    </Form.Item>
  );
};

const JoinViewOld = (props) => {
  console.log("JoinView props", props);
  console.log('firestoreInteractionActions==>', firestoreInteractionActions);
  const {
    experienceProps,
    videoRender,
    showStudentCodeAckModal,
    setShowStudentCodeAckModal,
    setSwitchFromStartToResumeView,
    experienceDuration
  } = props;

  const { experience, setUserInfo } = experienceProps;
  const enabledFeatures = useSelector(enabledFeaturesSelector()) 
  const dispatch = useDispatch();

  const shouldHideForPamoja = enabledFeatures.special_ui_for_pamoja_school && experience?.custom_fields?.block
  const location = useLocation();
  const locationQueryParams = queryString.parse(location.search);
  // console.log("locationQueryParams join view", locationQueryParams)  

  // join code default fill if in URL - doing this here and not in useEffect - since using defaultValue in Input and that renders blank on first time load - useEffect triggered after that
  const [isJoinCodeCorrect, setIsJoinCodeCorrect] = useState(false);

  let experienceViewMode = useSelector(experienceViewModeSelector());
  const currentUser = useSelector(currentUserSelector());
  const serverTimeOffset = useSelector(getServerTimeOffsetSelector());
  const embedded = useSelector(getEmbeddedSelector());
  const appType = useSelector(appTypeSelector());
  let appNativeVars = getAppNativeVars(appType);
  let currentDeviceInfo = getDeviceInfo(appType);
  console.log("currentDeviceInfo ====>", currentDeviceInfo);
  const experienceConfigFromRedux = useSelector(experienceConfigSelector());
  const endSessionBtnLoading = useSelector(endSessionBtnLoadingSelector());

  const experienceRubric = experience.rubric || {};
  const isMBLinked = experience.is_mb_linked
  const isGuest = currentUser.custom_fields.account_type === "guest";

  const mrIntl = useTranslate();

  const fullScreenTab =  () =>{
    if(experience.settings.mode == "online_secure" && false){
      if (screenfull.isEnabled) {
        screenfull.request();
      }
    }
  } 

  const Title = (props) => {
    const {expName, userName, userSCode, userAccountType, isUserMapped} = props

    let isUserGuest = userAccountType === "guest" ? true : false
    let showUserConfirmWarning = !embedded && !isUserMapped && !isUserGuest

    let innerTitleRender = []
    let studentNameAndSCode = <Space>
      <Tag color={"processing"}>{userName}</Tag>
      {isGuest && <Tag color={"processing"}>{userSCode}</Tag>}
    </Space>

    let message = <span><span>{mrIntl("JoinView.you_are_starting_this_assessment_as")} </span>{userName}. {mrIntl("JoinView.if_this_is_not_you")}, <a href={"/"} onClick={() => {dispatch(loginActions.logout())}}>{mrIntl("JoinView.click_here_to_login_to_a_different_account")}</a>.</span>

    let unmappedUserConfirmWarning = <Alert
      message={message}
      type="warning"
      showIcon
      // className="m-b-15"
    />

    innerTitleRender.push(expName)
    innerTitleRender.push(studentNameAndSCode)
    if(showUserConfirmWarning){
      innerTitleRender.push(unmappedUserConfirmWarning)
    }

    let titleRender = <Space direction={"vertical"}>
      {innerTitleRender}
    </Space>

    return titleRender;
  }

  const TimeAlertMessage = () => (
    <>
      <MrTranslate id={"CommonText.your_system_time_is_out_of_sync_not_be_able_to_start_tests"}/> {" "}
      <a
        href="https://drive.google.com/drive/u/2/folders/1FR5_R5ANMSMCAWTfCa27mqHX3GBb7kOw"
        target="_blank"
        rel="noopener noreferrer"
      >
        <MrTranslate id={"JoinView.fix_issue_msg"} />
      </a>
    </>
  );
  let instructionsText =
    experience.custom_fields.block && experience.custom_fields.block.id && experience.custom_fields.assignment
      ? experience.custom_fields.assignment.description
      : experience.settings.extra_instructions_text; // pamoja vs ours
  // + experience.custom_fields.block.instructions

    const isMYPHasCriteria = (experienceRubric.type_c === "myp_achievement_level" || experienceRubric.type_c === "criteria_with_points") && experience.criterium_associations && experience.criterium_associations.length > 0;

    const showMappedCriteriaTags =
      isMYPHasCriteria &&
      experience.criterium_associations.map((criterium) => {
        return (
          <Tooltip title={criterium.custom_fields.label}>
            <Tag>
              {criterium.custom_fields.title} {criterium.points && displayPoints(criterium.points)}
            </Tag>
          </Tooltip>
        );
      });

  let instructionsHtml = (
    <div key="instructions-html">
      <br />
      <Space className="join-test-tags">
        {experienceRubric.type_c === "points" && experience.points && <Tag>{displayPoints(experience.points)} {mrIntl("JoinView.pts")} </Tag>}
        {isMYPHasCriteria && showMappedCriteriaTags}
        {experience.settings.is_timed && experienceDuration > 0 && (
          <Tag>{experienceDuration} {mrIntl("JoinView.mins")} </Tag>
        )}
        {experience.questions_count != 0 && (
          <Tag>{experience.questions_count} {mrIntl("JoinView.questions")} </Tag>
        )}
      </Space>
      {currentTimeValidWrtServerTime(serverTimeOffset) == false && (
        <Alert
          style={{ marginTop: "20px" }}
          message={<TimeAlertMessage />}
          type="error"
          showIcon
        />
      )}
      {/* {!embedded && experience.settings.include_video_call_link && experience.settings.video_call_link && videoCallButton} */}
      {/* {!embedded &&
        experience.status == "published" &&
        experience.settings.enable_ap_video_monitoring && (
          <Col span={12} offset={6} className="m-t-15">
            <div style={{width: "100%", height: '350px'}}>
              {videoRender}
            </div>
            <Alert
              message="Please allow access to your camera and mic so that your teacher can see your video during this test. You can talk to your teacher during the test if you have any questions"
              type="warning"
              showIcon
            />
          </Col>
        )} */}
      <h3
        className="instructions-text"
        dangerouslySetInnerHTML={{ __html: instructionsText }}
      ></h3>
      <StartTestLoader />
      {/* {experience.settings.start_condition == "join_code" && <p>Enter join code to start test</p>} */}
      {experience.settings.with_join_code && (
        <p>
          <p><MrTranslate id={"JoinView.enter_join_code_to_start_test"}/></p>
          <InputJoinCode
            key="join-code-input-outer"
            isJoinCodeCorrect={isJoinCodeCorrect}
            setIsJoinCodeCorrect={setIsJoinCodeCorrect}
            joinCodeDefaultValue={locationQueryParams.joinCode || ""}
            expUUID={experience.uid}
          />
        </p>
      )}

      {/* THIS IS THE CULPRIT BELOW */}
      {/* {isSetUserInfoLoading && <Spin size="large"/>} */}
    </div>
  );

   

  let extra = [];
  // if (!embedded && appType != "seb") {
  if (!embedded && appType === "web" && !shouldHideForPamoja) { // showing only for web now - see APL-3847, letting user quit though
    extra.push(<BackToHomeBtn key="join-view-back-to-home-button" experience={experience} action="back_to_home_before_starting_test"/>);
  }
  extra.push(<QuitBtn key="join-view-quit-button" withLog={true} action={"quit_app_from_join_view"} />); // showing this since hiding back to home button for mac and ipad also TODO: mrIntl
  // mainTestScreen.enter(); // add inside onClick below when fullscreen is working again
  // not checking for start time in case of pamoja
  let startTooltipTitle =
    experience.status == "closed" ? mrIntl("JoinView.test_closed") : mrIntl
    ("JoinView.start_test");
  let startBtn = (
    <Tooltip title={startTooltipTitle}>
      <Button
        type="primary"
        key="start-test"
        disabled={(experience.status === "closed" || endSessionBtnLoading) ? true : false}
        onClick={() => {
          console.log('on join==>', firestoreInteractionActions, experience.id);
          let experienceInfo = {
            // studentsCount: firestoreFieldValue.increment(1)
            haveStudentsStarted: true
          }
          dispatch(firestoreInteractionActions.setExperienceInfo({
            experienceId: experience.id,
            experienceInfo
          }))
          setSwitchFromStartToResumeView(false);
          !embedded &&
            experienceViewMode === "apTakeTest" &&
            writeToClipboardPolyfill("");

          let logValues = {
            status: "joined",
            app_version: appType === "mac"
              ? `AssessPrep Mac App ${appNativeVars.appVersion}`
              : appType == "ios"
                ? `AssessPrep iOS App ${appNativeVars.appVersion}`
                : appType == "cros"
                  ? `Chromebook`
                  : `SEB ${appNativeVars.appVersion}`,
            browser_details: currentDeviceInfo.browser,
            os_details: currentDeviceInfo.os + " " + currentDeviceInfo.osVersion,
          }
          setUserInfo(
            {
              currentView: "startTest",
              // currentView: "joined",
              // group_id: getStudentGroupId(experience.groups, currentUser),
              group_id: experience.current_student_group_ids || null,
              started_at: Date.now(),
              needs_attention_at: Date.now(),
              security: experience.settings["mode"] == "offline" ? true : false,
              // active_teachers: [],
              // active_students: []
            },
            {
              log: {
                logging: true,
                action: appType !== "web" ? "test_started_in_app" : "test_started_in_browser",
                current_value: logValues,
              },
            }
          );
          // setIsJoinCodeCorrect(false); // resetting so that when submission reset and come back to joined screen, it is set to false
          fullScreenTab()

          if(experienceConfigFromRedux.takeExperience.join.createExperienceUserOnStart){
            dispatch(experienceActions.makeExperienceUser({
              experienceId: experience.id
            }))
          }
        }}
      >
       <MrTranslate id={"JoinView.start_test"}/>
      </Button>
    </Tooltip>
  );

  // in case of student and embedded mb_task and offline test then showing button to launch app instead of normal start button unless allow_online true - embedded and when in new tab (Start from MB)
  if (isOfflineExperienceAndNotAllowedOnline(experience, appType)) {
    startBtn = (
      <Space>
        <StartInSebBtn item={experience} />
        <DownloadAppsModal />
      </Space>
    );
  }

  if (currentTimeValidWrtServerTime(serverTimeOffset)) {
    if (experience.settings.start_condition == "scheduled_time") {
        let showBeforeCountDownStarts = (
          <Tag color="error" icon={<CalendarOutlined />}>
            {mrIntl("JoinView.starts_on")}{" "}
            {dayjs(experience.settings.start_at_datetime).format(
              "MMM DD, hh:mm a"
            )}
          </Tag>
        );
        let componentAfterCountDownComplete = null;
        console.log("experience.settings.with_join_code", experience.settings.with_join_code, isJoinCodeCorrect);
        if (experience.settings.with_join_code) {
          if (isJoinCodeCorrect) {
            componentAfterCountDownComplete = startBtn;
          }
        } else {
          componentAfterCountDownComplete = startBtn;
        }
        let startCountDown = (
          <CountDownWrapper
            key={`start-count-down-wrapper-${experience.id}`}
            targetTime={experience.settings.start_at_datetime}
            startCountDownTriggerMins={60}
            showBeforeCountDownStarts={showBeforeCountDownStarts}
            showAfterCountDownComplete={componentAfterCountDownComplete}
            isJoinCodeCorrect={isJoinCodeCorrect}
          ></CountDownWrapper>
        );

        extra.push(startCountDown);
    } else if (experience.settings.start_condition == "anytime") {
      if (experience.settings.with_join_code) {
        if (isJoinCodeCorrect) {
          extra.push(startBtn);
        }
      } else {
        extra.push(startBtn);
      }
    }
  }
  
  if (experienceViewMode == "apPreviewTest") {
    // regardless of start_condition setting
    extra.push(startBtn);
  }
  extra.push(instructionsHtml);
  

  let finalRender = (
    <>
      <Result
        // status="info"
        className="start-test-info"
        icon={<EditOutlined />}
        title={ <Title expName={experience.name} userName={currentUser.name} userSCode={currentUser.s_code} userAccountType={currentUser.custom_fields["account_type"]} isUserMapped={experience.is_student_mapped} />}
        // subTitle={instructionsHtml}
        extra={extra}
      />
      <StudentCodeAckModal
        sCode={currentUser.s_code}
        showStudentCodeAckModal={showStudentCodeAckModal}
        setShowStudentCodeAckModal={setShowStudentCodeAckModal}
      />
    </>
  );
  return finalRender;
};

export default JoinViewOld;
