import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { message } from "/src/components/UI/AntdAppHelper";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "request",
  message,
  actionNames: ["PROGRAMMES", "PROGRAMMESUBJECTS", "GROUPS", "GRADES", "SUBJECTS", "ORGS", "USERS", "STUDENTS", "TEACHERS", "PARENTS", "ORGPROGRAMMES", "TERMS", "BOARDS", "PROGRAMMEGRADES", "STATICBOARDS", "QUESTIONBANKS", "ORGQUESTIONBANKS", "LEARNINGOUTCOMES", "TOPICS", "SEGMENTS", "PLANS", "RUBRICS", "EXPERIENCES", "FEATURES", "TAGS", "DIFFICULTYLEVELS", "TEACHINGLEVELS", "PAPERTYPE", "DEPTHOFKNOWLEDGE", "COMMANDTERMS", "ASSESSMENTOBJECTIVES", "STANDARDCOLLECTIONCONTENTAREAS", "STANDARDCOLLECTIONS", "CONTENTAREAGRADEGROUPS", "KEYCONCEPTS", "RELATEDCONCEPTS", "GLOBALCONTEXTS", "POSSIBLEEXPLORATIONS", "ATLSKILLS"]
});


const initialState = fromJS({
  // segments: [],
  experiences: [],
  topics: [],
  programmes: [],
  groups: [],
  grades: [],
  subjects: [],
  orgs: [],
  users: [],
  students: [],
  teachers: [],
  parents: [],
  orgprogrammes: [],
  term: [],
  boards: [],
  plans: [],
  features: [],
  programmesubjects: [],
  programmegrades: [],
  staticboards: [],
  questionbanks: [],
  tags: [],
  learningoutcome: [],
  commandterms: [],
  keyconcepts: [],
  relatedconcepts: [],
  globalcontexts: [],
  possibleexplorations: [],
  atlskills: [],
  error: null,
  loading: false
});
export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState);
export const watchRequests = reduxCrud.generateWatchSaga();
export default reduxCrud;