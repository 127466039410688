import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import {isTouchDevice} from './helpers';
const MAX_ZOOM = 3;

class ZoomCanvas extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      scale: 1,
      offset: {
        x: 0, y: 0
      },
      zoomFactor: 1,
      lastScale: 1,
      isTouching: false,
      isScroll: false,
      isZoomImageDrag:false,
      flipClick: false,
    };
    this.isTouchDevice = isTouchDevice();

    this.zoomArea = React.createRef();
    this.contentElement = React.createRef();

    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }
  
  componentDidMount() {
    this.zoomArea.current.addEventListener('touchstart', this.onTouchStart, {passive: false});
    this.zoomArea.current.addEventListener('touchmove', this.onTouchMove, {passive: false});
    this.zoomArea.current.addEventListener('touchend', this.onTouchEnd, {passive: false});

    this.zoomArea.current.addEventListener('mousedown', this.onTouchStart, {passive: false});
    this.zoomArea.current.addEventListener('mousemove', this.onTouchMove, {passive: false});
    this.zoomArea.current.addEventListener('mouseup', this.onTouchEnd, {passive: false});
    if(this.props.imageZoom){
      setTimeout(() => {
          this.initializeZoomArea();
      },100)
    }else{
      this.initializeZoomArea();
    }


    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    this.zoomArea.current.addEventListener('mousedown', this.onTouchStart, {passive: false});
    this.zoomArea.current.addEventListener('mousemove', this.onTouchMove, {passive: false});
    this.zoomArea.current.addEventListener('mouseup', this.onTouchEnd, {passive: false});

    this.zoomArea.current.removeEventListener('touchstart', this.onTouchStart, {passive: false});
    this.zoomArea.current.removeEventListener('touchmove', this.onTouchMove, {passive: false});
    this.zoomArea.current.removeEventListener('touchend', this.onTouchEnd, {passive: false});
    window.removeEventListener('resize', this.onResize);
  }

  onResize = debounce(() => {
    this.initializeZoomArea();
  }, 300);

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.externalZoomFactor !== this.props.externalZoomFactor || prevProps.windowWidth !== this.props.windowWidth || (prevProps.isZoomed !== this.props.isZoomed && this.props.isZoomed === false)) {
      this.initializeZoomArea();  
    }
    if(this.props.isZoomed && prevState.zoomFactor > this.state.zoomFactor && this.state.zoomFactor === this.getInitialZoomFactor()){
      this.props.resetZoom(false);
    }
    
  }

  handleOnActionButtonClick = (callback) => {
    if(!this.state.isZoomImageDrag){
        callback && callback();        
    }
}

  onWheel = (e) => {			
    this.setState({		
      isScroll: true		
    });		
    clearTimeout(this.scrollTimeOut);		
    this.scrollTimeOut = setTimeout(() => {		
      this.setState({		
        isScroll: false		
      });		
    }, 250);	
  }
  render() {
    const contentStyle = this.createStyle();
    return (
      <StyledZoomCanvas isTouchDevice={this.isTouchDevice} ref={this.zoomArea} imageZoom={this.props.imageZoom} zoomFactor={this.state.zoomFactor} onWheel ={(e) => this.onWheel(e)} className={'zoom-canvas-container'}>
       
        <Content imageZoom={this.props.imageZoom} width={this.props.width} height={this.props.height} onClick={(e) => this.props.imageZoom &&  this.handleOnActionButtonClick(() => this.props.onActionFlashcard(true)) }
        key={this.props.isDisabled && this.state.zoomFactor} style={contentStyle} ref={this.contentElement} zoomFactor={this.state.zoomFactor}>
          {this.props.children}
        </Content>
      </StyledZoomCanvas>
    );
  }

  createStyle() {
    const {offset, zoomFactor, isTouching} = this.state;
    const offsetX = -offset.x / zoomFactor;
    const offsetY = -offset.y / zoomFactor;
    const hasScaleRatio = !isNaN(zoomFactor);
    const transform3d = `scale3d(${zoomFactor}, ${zoomFactor}, ${zoomFactor}) translate3d(${offsetX}px, ${offsetY}px, 0)`;
    const transform2d = `scale(${zoomFactor}) translate(${offsetX}px, ${offsetY}px)`;
    const zoom = {zoomFactor}
    return {
      transform: isTouching ? transform2d : transform2d,
      visibility: hasScaleRatio || this.props.imageZoom ? 'visible' : 'hidden',
    };
   

  }

  initializeZoomArea() {
    this.setState({
      zoomFactor: isNaN(this.getInitialZoomFactor()) ? 1 : this.getInitialZoomFactor()
    }, () => {
      this.resetOffset();
    });
    
  }

  onTouchEnd() {
    if (this.props.isDisabled) return;
    this.setState({
      lastTouch: null,
      lastDistance: null,
      lastScale: 1,
      lastZoomCenter: null,
      isTouching: false,
      isScroll: false	
    });
  }

  mapTouches(touches) {
    const rect = this.zoomArea.current.getBoundingClientRect();
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;
    const posTop = rect.top + scrollTop;
    const posLeft = rect.left + scrollLeft;
    if(touches){
      if(!this.isTouchDevice){
        return [{x: touches.pageX - posLeft, y: touches.pageY - posTop}]
      } else {
        return Array.from(touches).map(touch => ({x: touch.pageX - posLeft, y: touch.pageY - posTop})); 
      }
    }
  }

  getMaxOffsets() {
    const elWidth = this.contentElement.current.offsetWidth * this.state.zoomFactor;
    const elHeight = this.contentElement.current.offsetHeight * this.state.zoomFactor;
    const maxX = elWidth - this.zoomArea.current.offsetWidth,
      maxY = elHeight - this.zoomArea.current.offsetHeight;

    return {x: maxX, y: maxY};
  }

  onTouchMove(event) {
    if (this.props.isDisabled) return;
    if (!this.state.isTouching) return;
    const touches = this.mapTouches(this.isTouchDevice ? event.touches : event);
    const maxOffsets = this.getMaxOffsets();
    if (touches && touches.length === 1 && maxOffsets.x < 10 && maxOffsets.y < 10) {
      return;
    }
    this.cancelEvent(event);
    const touch = touches && touches[0];
    if (!this.state.lastTouch) {
      this.setState({
        lastTouch: touch
      });
      return;
    }
    if (touches && touches.length === 2) {
      this.setState({		
        isScroll: true		
      });
      this.handleZoom(touches, calculateScale(this.state.startTouches, touches));
      return;
    }
    this.handleDrag(touch);

    this.setState({
      lastTouch: touch
    });
  }

  handleZoom(touches, newScale) {
    const {lastScale} = this.state;
    const scale = newScale / lastScale;
 
    const touchCenter = getVectorAvg(touches);

    this.setState({
      lastScale: newScale
    });

    this.scale(scale, touchCenter);
    this.drag(touchCenter, this.state.lastZoomCenter);
    this.setState(({offset}) => ({
      offset: this.sanitizeOffset(offset)
    }));
    this.setState({
      lastZoomCenter: touchCenter
    });
  }

  scale(scale, center) {
    scale = this.scaleZoomFactor(scale);
    this.addOffset({
      x: (scale - 1) * (center.x + this.state.offset.x),
      y: (scale - 1) * (center.y + this.state.offset.y)
    });
  }

  scaleZoomFactor(scale) {
    const originalZoomFactor = this.state.zoomFactor;
    let newZoomFactor = this.state.zoomFactor * scale;
    const minZoomFactor = this.getInitialZoomFactor();
    newZoomFactor = Math.min(MAX_ZOOM, Math.max(newZoomFactor, minZoomFactor));
    this.setState({
      zoomFactor: newZoomFactor
    });
    return newZoomFactor / originalZoomFactor;
  }

  handleDrag(touch) {
    this.drag(touch, this.state.lastTouch);
    this.setState(({offset}) => ({
      offset: this.sanitizeOffset(offset)
    }));
    this.setState({
      lastTouch: touch,
      isZoomImageDrag: true
    });
  }

  getInitialZoomFactor() {
    if (!this.zoomArea.current) return 1;
    const xZoomFactor = this.zoomArea.current.offsetWidth / this.contentElement.current.offsetWidth;
    const yZoomFactor = this.zoomArea.current.offsetHeight / this.contentElement.current.offsetHeight;
    let factor;
    if (xZoomFactor < 1 || yZoomFactor < 1) {
      // If any axis is already too small, use the biggest factor
      factor = Math.max(xZoomFactor, yZoomFactor);
    } else {
      // Both axis are small enough, use the smallest factor
      factor = Math.min(xZoomFactor, yZoomFactor);
    }
    // Does not allow the zoom to go under 1 (minZoom)
    if(this.props.externalZoomFactor > 1){
      return Math.abs(factor * this.props.externalZoomFactor);
    }
    if (this.props.minZoom >= 0) {
      return Math.max(factor, this.props.minZoom);
    }
    return factor;
  }

  sanitizeOffset(offset) {
    const maxOffsets = this.getMaxOffsets();
    const maxX = maxOffsets.x,
      maxY = maxOffsets.y,
      maxOffsetX = Math.max(maxX, 0),
      maxOffsetY = Math.max(maxY, 0),
      minOffsetX = Math.min(maxX, 0),
      minOffsetY = Math.min(maxY, 0);
    return {
      x: Math.min(Math.max(offset.x, minOffsetX), maxOffsetX),
      y: Math.min(Math.max(offset.y, minOffsetY), maxOffsetY)
    };
  }

  drag(center, lastCenter) {
    if (!lastCenter) return;
    this.setState({		
      isScroll: true		
    });
    this.addOffset({
      x: -(center.x - lastCenter.x),
      y: -(center.y - lastCenter.y),
    });
  }

  addOffset({x, y}) {
    this.setState(({offset}) => ({
      offset: {
        x: offset.x + x,
        y: offset.y + y,
      }
    }));
  }

  onTouchStart(event) { 
    if (this.props.isDisabled) return;
    if (this.isTouchDevice && !event.touches) return;
    const touches = this.mapTouches(this.isTouchDevice ? event.touches : event);
    this.setState({
      startTouches: touches,
      isTouching: true,
      isZoomImageDrag: false
    });
    if (this.detectDoubleTab(touches)) {
      this.cancelEvent(event);
    }
  }

  detectDoubleTab(touches) {
    const time = (new Date()).getTime();
    if (touches.length > 1) {
      this.setState({
        lastTouchStart: null
      });
      return false;
    }
    const isDoubleTap = (time - this.state.lastTouchStart) < 300;
    this.setState({
      lastTouchStart: time
    });
    return isDoubleTap;
  }

  cancelEvent(event) {
    event.preventDefault();
    event.stopPropagation();
  }
  computeInitialOffset2() {
    const sanitizedOffset = this.sanitizeOffset({
      x: -(Math.abs(this.contentElement.current.offsetWidth * this.state.zoomFactor - this.zoomArea.current.offsetWidth) / 2),
      y: -(Math.abs(this.contentElement.current.offsetHeight * this.state.zoomFactor - this.zoomArea.current.offsetHeight) / 2),
    });
    return sanitizedOffset;
  }
  computeInitialOffset() {
    //let x = -(Math.abs(this.contentElement.current.offsetWidth * this.state.zoomFactor - this.zoomArea.current.offsetWidth) / 2);
    let x = (this.contentElement.current.offsetWidth * this.state.zoomFactor - this.zoomArea.current.offsetWidth) / 2;
    let contentOffsetHeight;
    let isScrollPresent = this.contentElement.current.offsetHeight > this.zoomArea.current.offsetHeight;
    let adjustFactor;
    if(isScrollPresent){
      adjustFactor = 0;
      contentOffsetHeight = (this.zoomArea.current.offsetHeight - adjustFactor);
    } else {
      contentOffsetHeight = this.contentElement.current.offsetHeight;
    }
    let y = ((contentOffsetHeight * this.state.zoomFactor - this.zoomArea.current.offsetHeight) / 2);
    if(isScrollPresent){
      y = ((contentOffsetHeight * this.props.externalZoomFactor - this.zoomArea.current.offsetHeight) / 2);

    }

    let finalOffset = this.sanitizeOffset({x, y})
   
    return finalOffset;
    //return (this.isTouchDevice || (this.props.externalZoomFactor === 1 && !this.isTouchDevice)) ? this.sanitizeOffset({x,y}) : {x: -x, y: -y};
  }

  resetOffset() {
    this.setState({
      offset: this.computeInitialOffset()
    });
  }
}

ZoomCanvas.defaultProps = {
  minZoom: 1,
  windowWidth: window.screen.width
};
ZoomCanvas.propTypes = {
  children: PropTypes.node,
  externalZoomFactor: PropTypes.number,
  windowWidth: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  minZoom: PropTypes.number,
  isDisabled: PropTypes.bool,
};


export default ZoomCanvas;

const StyledZoomCanvas = styled.div`
  height: calc(100% - 3.6rem);
  margin: 1.8rem 2.2rem;
  ${props => props.imageZoom && `height: unset;`}
  ${props => props.imageZoom && (props.zoomFactor || isNaN(props.zoomFactor)) && `display: flex; align-items: center;  justify-content: center;`} 
  ${props => props.imageZoom && props.isTouchDevice && ` width: 100%; height: 100%;`}
`;

const Content = styled.div`
  transform-origin: 0 0;
  display: flex;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  @media (pointer: fine) {
    &:hover {
      ${props => props.imageZoom && (props.zoomFactor <= 1 || isNaN(props.zoomFactor) ? `cursor: zoom-in ` : `cursor: zoom-out `)}  
    }
  }  
  &:active {
    ${props => props.imageZoom && props.zoomFactor > 1 &&  `cursor: grabbing `}  
  }
  ${props => props.imageZoom && `width: 100%;  height: 100%;`} 

`;


function getDistance(a, b) {
  let x, y;
  x = a.x - b.x;
  y = a.y - b.y;
  return Math.sqrt(x * x + y * y);
}

function sum(a, b) {
  return a + b;
}

function getVectorAvg(vectors) {
  return {
    x: vectors.map(function (v) {
      return v.x;
    }).reduce(sum) / vectors.length,
    y: vectors.map(function (v) {
      return v.y;
    }).reduce(sum) / vectors.length
  };
}

function calculateScale(startTouches, endTouches) {
  const startDistance = getDistance(startTouches[0], startTouches[1]);
  const endDistance = getDistance(endTouches[0], endTouches[1]);
  return endDistance / startDistance;
}
