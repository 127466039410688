import React, {useEffect} from 'react';
import { Tabs } from "antd";
import { useSelector } from 'react-redux';
import { Link, useRouteMatch, Route, useLocation } from "react-router-dom";
import Users from '/src/views/Users/Users';
import UserItemDetail from '/src/views/Users/UserItemDetail/UserItemDetail';

const Profile = (props) => {
  const {history} = props;
  const { path, url } = useRouteMatch();
  const location = useLocation();

  const {TabPane} = Tabs;
  const currentUser = useSelector(state => {
    console.log("state user filter",state);
    return state.login.get("currentUser")
  } )

  useEffect(() => {
    if(currentUser){
      console.log( "currentUser", currentUser );
      history.push(`${url}/${currentUser.id}`)
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/u/profile') {
      history.push(`${url}/${currentUser.id}`)
    }
  }, [location.pathname])
  
  return (
      <>
        <Route path={`${path}/:usersId`} render={routeProps => <Users isRoute={false} {...routeProps} config={{routes: {fetch:false}, itemDetail: {widget: UserItemDetail}}}/>}  /> 
      </> 
  )
}

export default Profile;