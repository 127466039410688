import React, { useState, useEffect } from "react";
import { Button, Tooltip } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { last } from "lodash";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { topicsSelector } from "/src/views/Segments/Topics/selector";

const GoToNextSection = ({ activeTopicId }) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const topics = useSelector(topicsSelector());

  const topicsCount = topics.length;
  const index = topics.findIndex((topic) => topic.id == activeTopicId);
  const nextActiveTopic = topics[index + 1];

  const goToNextSection = () => {
    if (index >= 0 && index < topicsCount) {
      const newUrl = url.replace(/\/topics\/\d.+/g, "");
      history.push(`${newUrl}/topics/${nextActiveTopic.id}`);
    }
  };

  if (!nextActiveTopic) {
    return null;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <Tooltip
        title={
          nextActiveTopic && nextActiveTopic.section_segment_attributes.title
        }
      >
        <Button type="primary" onClick={() => goToNextSection()}>
          Next Section <ArrowRightOutlined />
        </Button>
      </Tooltip>
    </div>
  );
};

export default GoToNextSection;
