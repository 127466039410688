import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import "./PoweredBy.scss";
import './DefaultCKE.scss';
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import CKEDITOR from "@ckeditor/ckeditor5-super-build";
import {
  CKEDefaultTools,
  WirisMathTool,
  updateConfigForTool,
} from "./CKEConfig";
import { debounce } from "lodash";
import { Skeleton, Input, Alert, Button, Row, Col } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import { getUniqueId, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";
import * as Sentry from "@sentry/react";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import {
  activeAdjustedExperienceSelector,
  experienceViewModeSelector,
  getInternetStatusSelector,
  printModeSelector,
} from "/src/views/Experiences/selector";
import Listeners from "@wiris/mathtype-html-integration-devkit/src/listeners";

import loadable from "@loadable/component";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import MagicButton from "/src/views/Segments/GenerateAI/MagicButton";
import WritingAssistant from "/src/views/Segments/GenerateAI/WritingAssistant";
import DefaultCKEFooter from "./DefaultCKEFooter";

// import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';

const ckLib = loadable.lib(() =>
  import(
    /* webpackChunkName: "ckeditor", webpackPrefetch: true */ "@ckeditor/ckeditor5-super-build"
  )
);

const DefaultCKE = (props) => {
  const { writingAssistantConfig = {}, triggerSaveLastSelectionRange, triggerRestoreLastSelectionRange, editorFooterConfig = {} } = props;
  const { triggerCKEDataUpdate } = props.tools;
  const CKEDITOR = window.CKEDITOR || {};
  const printMode = useSelector(printModeSelector())
  console.log("print mdode in default cke ==>", printMode)
  const mrIntl = useTranslate();


  let editor,
    config = {};
  if (props.classicEditor) {
    editor = CKEDITOR.ClassicEditor;
  } else {
    editor = CKEDITOR.InlineEditor;
  }

  if (props.tools) {
    config = {
      ...props.tools,
    };
  } else {
    config = {
      ...CKEDefaultTools,
    };
  }
  config = {
    ...config,
    placeholder: (!printMode ? (props.placeholder || mrIntl("CommonText.write_here")) : ""),
    simpleUpload: {
      ...config.simpleUpload,
      headers: {
        ...config.simpleUpload.headers,
        token: localStorage.getItem("token"), // Token should be latest, in case of logout/caching it was going null
      },
    },
  };

  const enabledFeatures = useSelector(enabledFeaturesSelector());
  const activeExperience = useSelector(activeAdjustedExperienceSelector());
  let internetStatus = useSelector(getInternetStatusSelector());

  const experienceSettings = activeExperience.settings || {};

  let currentData = "";
  const propsData = props.data;

  // const propsData = '<p>Uttam&nbsp;</p><audio class="cke-custom-audio-player" src="https://v2res1-stag.assessprep.com/direct_uploads/attachments/01FBGYSMTCHG66EWG8BVV22RTS/file_example_MP3_700KB.mp3" controls></audio>'

  const myEditor = useRef();
  const [initialData, setInitialData] = useState(propsData);
  const [showCKE, setShowCKE] = useState(false);
  const [reloadCKE, setReloadCKE] = useState(0);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const [pendingDebounce, setPendingDebounce] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  //initial stare of isEditorReady should always be false, it is being used for writing assistant, the situation in which it needs to be set false again will be discussed. 
  const [isEditorReady, setIsEditorReady] = useState(false);

  const allowFormattedPaste = enabledFeatures.allow_formatted_paste;
  const wirisMathPluginEnabled =
    enabledFeatures.wiris_math_plugin;
  const [showWirisMath, setShowWirisMath] = useState(wirisMathPluginEnabled);
  const [lastSelectionRange, setLastSelectionRange] = useState(null)


  if (showWirisMath) {
    config = updateConfigForTool(config, WirisMathTool.toolbarName);
  }
  const extraPaddingTopOnFocus = config.extraPaddingTopOnFocus

  useEffect(() => {
    if (myEditor.current) {
      const editor = myEditor.current;
      const view = editor.editing.model;
      const selection = view.document.selection;
      const savedRange = Array.from(selection.getRanges())[0];
      setLastSelectionRange(savedRange)
    }
  }, [triggerSaveLastSelectionRange])

  useEffect(() => {
    if (myEditor.current) {
      const editor = myEditor.current;
      const view = editor.editing.view;
      editor.model.change(writer => {
        writer.setSelection(lastSelectionRange);
      });
      view.focus();
    }
  }, [triggerRestoreLastSelectionRange])

  useEffect(() => {
    if (myEditor.current && stateUpdatedCount > 0) {
      const data = myEditor.current.getData();
      const extra = {
        wordCount: config.wordCount,
      };
      setPendingDebounce(false);
      props.onTextChanged(data, extra);
    }
  }, [stateUpdatedCount]);
  console.log("Default CKE Props ==>", config);

  // useEffect(() => {
  // 	if (myEditor.current) {
  // 		currentData = myEditor.current.getData()
  // 		if (currentData != propsData && propsData == '') {
  // 			// Check why inconsistent for fill
  // 			// myEditor.current.setData('')
  // 		}
  // 	}
  // }, [propsData])

  useEffect(() => {
    if (myEditor.current && triggerCKEDataUpdate) {
      myEditor.current.setData(propsData);
    }
    console.log(
      "propsData on triggerCKEDataUpdate ==>",
      propsData,
      triggerCKEDataUpdate
    );
  }, [triggerCKEDataUpdate]);

  useEffect(() => {
    if (window.WirisPlugin) {
      console.log("Wiris plugin is loaded ==>", window.WirisPlugin);
      window.WirisPlugin.currentInstance.core.listeners.add(
        Listeners.newListener(
          "onBeforeFormulaInsertion",
          onBeforeFormulaInsertion
        )
      );
    }
  }, [window.WirisPlugin]);

  useEffect(() => {
    // if (config.isReadOnly)  {
      if (myEditor.current) {
        // myEditor.current.isReadOnly = config.isReadOnly;
        if (config.isReadOnly) {
          myEditor.current.enableReadOnlyMode(`default-editor-${props.itemId}`); // new method for setting readonly mode in ckeditor
        } else {
          myEditor.current.disableReadOnlyMode(`default-editor-${props.itemId}`);
        }
      }
    // }
  }, [config.isReadOnly])

  useEffect(() => {
    if (reloadCKE >= 3) {
      setShowErrorMessage(true);
      return;
    }

    let ckeTimeout;
    setShowCKE(false);
    if (!window.CKEDITOR) {
      ckLib.load().then((module) => {
        window.CKEDITOR = module.default;
        console.log("Ckeditor lib load module ==>", module, module.default);
        if (window.CKEDITOR && window.CKEDITOR.ClassicEditor) {
          ckeTimeout = setTimeout(() => {
            setShowCKE(true);
          }, 10);
        } else {
          window.CKEDITOR = undefined;
          console.log("Some error occured in loading ckeditor");
          Sentry.captureException(module);
          if (reloadCKE < 3) {
            setReloadCKE((reloadCKE) => reloadCKE + 1);
          }
        }
      });
    } else {
      if (window.CKEDITOR && window.CKEDITOR.ClassicEditor) {
        ckeTimeout = setTimeout(() => {
          setShowCKE(true);
        }, 10);
      }
    }

    return () => {
      if (ckeTimeout) {
        clearTimeout(ckeTimeout);
      }
      if (myEditor.current) {
        myEditor.current = null;
      }
    };
  }, [reloadCKE]);

  // console.log("triggerGetSelectedContent ==> outside", triggerGetSelectedContent)
  // useEffect(() => {
  //   console.log("triggerGetSelectedContent ==>", triggerGetSelectedContent)
  //   if (myEditor.current) {
  //     const selectedContent = getSelectedContent(myEditor.current);
  //     if(setSelectedContent) {
  //       setSelectedContent(selectedContent[0])
  //     }
  //     console.log("selectedContent ==>", selectedContent);
  //   }
  // }, [triggerGetSelectedContent])

  // useEffect(() => {
  //   console.log("triggerGetSelectedContent ==>", triggerSetSelectedContent)
  //   if (myEditor.current) {
  //     replaceSelectedContent(myEditor.current, "one");
  //     console.log("selectedContent ==>", selectedContent);
  //   }
  // }, [triggerSetSelectedContent])


  // useEffect(() => {

  // 	return () => {
  // 		if (pendingDebounce) {
  // 			onTextChangedWithDebounce.cancel()
  // 			currentData = myEditor.current.getData()
  // 			props.onTextChanged(currentData)
  // 		}
  // 	}
  // }, [pendingDebounce])

  // if (reloadCKE >= 3) {
  //   return editorErrorMessage;
  // }

  // sending data from useEffect because debounce using old values of index in parent components
  const onTextChangedWithDebounce = debounce((data, extra = {}) => {
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  }, config.debounceInterval || 1000);

  const handleChange = useCallback(onTextChangedWithDebounce, []);

  // To create space for toolbar so it doesn't hide the question
  const handleEditorPaddingAndMargin = (event, paddingValue, editor) => {
    if (editor) {
      let inputAreaMarginBottom =
        event === "focus"
          ? "0px"
          : extraPaddingTopOnFocus;

      try {
        if (event === "focus" || event === "blur") {
          editor.sourceElement.parentElement.parentElement.style.paddingTop = paddingValue;
        }
        editor.sourceElement.parentElement.style.marginBottom = inputAreaMarginBottom;
      } catch (error) {
        console.error("An error occurred adding editor padding on focus:", error);
        Sentry.captureException(error);
      }
    }
  };

  const handleBlur = (data, editor) => {
    if (extraPaddingTopOnFocus) {
      handleEditorPaddingAndMargin("blur", "0px", editor);
    }
    if (pendingDebounce) {
      onTextChangedWithDebounce.cancel();
      setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
    }
    if (props.onBlur) {
      props.onBlur(data)
    }
  };

  const handleFocus = (data, editor) => {
    if (extraPaddingTopOnFocus) {
      handleEditorPaddingAndMargin("focus", extraPaddingTopOnFocus, editor);
    }
    if (props.onFocus) {
      props.onFocus(data)
    }
  };

  var onBeforeFormulaInsertion = (event) => {
    const internetStatus = localStorage.getItem("internetStatus")
    console.log(
      "onBeforeFormulaInsertion internetStatus and event",
      internetStatus,
      event
    );
    if (internetStatus === "offline" || internetStatus === "network_issue") {
      const messageConfig = {
        key: "formula-insertion-error",
        content: "Your internet is not working. Please resolve that to proceed."
      }
      message.error(
        messageConfig
      );
      return event;
    }
  };

  let editorErrorMessage = (
    <Alert
      message="Something went wrong in loading the text editor. Please refresh the page. If the issue persists, then use a different browser or try incognito mode."
      type="error"
      className="text-left"
    ></Alert>
  );

  let mathErrorMessage = (
    <Alert
      message="Something went wrong in loading advanced math plugin. Please refresh the page."
      type="warning"
      className="text-left"
    ></Alert>
  );

  function getSelectedData(selection) {
    let selectedData = '';
    for (const range of selection.getRanges()) {
      for (const item of range.getItems()) {
        selectedData += item.data;
      }
    }
    return selectedData;
  }

  function calculateRemainingLimit(
      maxCharacters, 
      editorAndClipboardDataCombinedLength, 
      editorDataLength, 
      selectedDataLength, 
      pasteCharactersLimit
  ) {
    let remainingLimit = maxCharacters - editorAndClipboardDataCombinedLength;

    if (selectedDataLength) {
      if ((!pasteCharactersLimit && 
          (editorDataLength >= maxCharacters)) || 
        (pasteCharactersLimit && 
          (pasteCharactersLimit > selectedDataLength))
      ) {
        remainingLimit = selectedDataLength;
      } else if (pasteCharactersLimit) {
        remainingLimit = pasteCharactersLimit;
      } else {
        remainingLimit = maxCharacters - editorDataLength + selectedDataLength;
      }
    }
    return remainingLimit;
  }

  function getModifiedClipboardData(
    clipboardData, 
    remainingLimit, 
    maxCharacters, 
    editorAndClipboardDataCombinedLength, 
    pasteCharactersLimit
  ) {  
    const isMaxCharactersLimitReached = editorAndClipboardDataCombinedLength > maxCharacters;
    let content = clipboardData;

    if (isMaxCharactersLimitReached) {
      content = content.slice(0, remainingLimit);
    } 
    if (pasteCharactersLimit) {
      content = removeHTMLTagsFromText(content, true).slice(0, pasteCharactersLimit);
    }
    return content;
  }

  if (allowFormattedPaste) {
    // For the org_id 1430 (AP H), we want to paste default
    config.pastePlainText = false
  }

  console.log("default cke final config ==>", config, myEditor.current);
  return showCKE ? (
    <>
      <CKEditor
        key={`default-ckeditor-${props.itemId}`}
        editor={editor}
        config={config}
        data={initialData}
        onReady={(editor) => {
          console.log("on ready giving editors ==>", editor);
          if (editor) {
            if (config.autoFocus || config.autoFocusAtEnd) {
              editor.focus()
              if (config.autoFocusAtEnd) {
                try {
                  editor.model.change((writer) => {
                    writer.setSelection(writer.createPositionAt(editor.model.document.getRoot(), "end"));
                  });
                } catch (error) {
                  console.error("An error occurred:", error);
                  Sentry.captureException(error);
                }
              }
            }
            if (config.isReadOnly) {
              editor.enableReadOnlyMode(`default-editor-${props.itemId}`); // new method for setting readonly mode in ckeditor
            }
            editor.ui.view.toolbar.items.map(
              (item) => (item.tooltipPosition = "n")
            );
            myEditor.current = editor;
            setIsEditorReady(true);       
            // adding extra margin which can be changed to make space for changes happening in handleEditorPaddingAndMargin -- so that toolbar doesn't hide the question.
            if (extraPaddingTopOnFocus) {
              handleEditorPaddingAndMargin('ready', extraPaddingTopOnFocus, editor)
            }
            // editor.sourceElement.setAttribute("data-gram", false)
            console.log("Editor is ready to use!", editor);
            // console.log("Text Input plugin is ==>", editor.plugins)
            // const textInputPlugin = editor.plugins.get('TextInput')
            // textInputPlugin.on('update', (evt, data) => {
            // 	console.log('Text Input plugin is updated ==>', evt, data)
            // })

            if (config.pastePlainText) {
              editor.editing.view.document.on('clipboardInput', (evt, data) => {
                try {
                  const editorData = myEditor.current.getData();
                  const clipboardData = data.dataTransfer.getData('text/plain');
                  const maxCharacters = config.wordCount.maxCharacters;
                  const pasteCharactersLimit = config.pasteCharactersLimit;
                  const selectedData = getSelectedData(editor.model.document.selection);

                  const editorAndClipboardDataCombinedLength = (editorData + clipboardData).length;
                  let remainingLimit = calculateRemainingLimit(
                    maxCharacters,
                    editorAndClipboardDataCombinedLength,
                    editorData.length,
                    selectedData.length,
                    pasteCharactersLimit
                  );

                  const trimmedContent = getModifiedClipboardData(
                    clipboardData,
                    remainingLimit,
                    maxCharacters, editorAndClipboardDataCombinedLength, pasteCharactersLimit
                  );
                  data.content = editor.data.htmlProcessor.toView(trimmedContent);
                } catch (error) {
                  console.log("error==>", error)
                }
              });
            }

            
            // if (window.WirisPlugin) {
            //   console.log("Wiris plugin is loaded ==>", window.WirisPlugin);
            //   window.WirisPlugin.currentInstance.core.listeners.add(
            //     Listeners.newListener(
            //       "onBeforeFormulaInsertion",
            //       onBeforeFormulaInsertion
            //     )
            //   );
            // }
            // editor.editing.view.document.on('clipboardInput', (evt, data) => {
            //   // if (editor.isReadOnly) {
            //   //   return;
            //   // }

            //   const dataTransfer = data.dataTransfer;
            //   let content = ( dataTransfer.getData( 'text/plain' ) );

            //   data.content = editor.data.htmlProcessor.toView(content);

            //   const viewFragment = editor.data.processor.toView(content);
            //   const modelFragment = editor.data.toModel(viewFragment);
            //   editor.model.insertContent(modelFragment);
            //   console.log('data.content==>', viewFragment, modelFragment)

            // });
            // editor.conversion.for('downcast').elementToElement({
            //   model: 'copyChatGpt',
            //   view: (modelElement, { writer: viewWriter }) => {
            //     // In the data view, the model <textInput> corresponds to:
            //     //
            //     // <section class="product" data-id="..."></section>
            //     return viewWriter.createEditableElement('copyChatGpt', {
            //       // class: 'copied-span',
            //       // 'data-id': modelElement.getAttribute('id'),
            //       'class': modelElement.getAttribute('className')
            //     });
            //   }
            // });
          } else {
            setShowErrorMessage(true);
          }
        }}
        onChange={(event, editor) => {
         // issue was here--- in onChange editor.getData was getting called while math was getting inserted although we were never using it so removing
          // const data = editor.getData();

          // const extra = {
          //   wordCount: config.wordCount,
          // };
          handleChange();
          if (!pendingDebounce) {
            setPendingDebounce(true);
          }
        }}
        onBlur={(event, editor) => {
          const data = editor.getData();
          handleBlur(data, editor);
        }}
        onFocus={(event, editor) => {
          const data = editor.getData();
          handleFocus(data, editor);
        }}
        onError={(error, { phase }) => {
          console.error(
            "Error happend in ckeditor ==>",
            error.message,
            error.message.indexOf("wiris"),
            phase
          );
          if (phase == "initialization") {
            if (error.message.indexOf("wiris") >= 0) {
              setShowWirisMath(false);
            } else {
              setShowErrorMessage(true);
            }
            setReloadCKE(1);
          }
        }}
      />
      {showErrorMessage && editorErrorMessage}
      {wirisMathPluginEnabled && !showWirisMath && mathErrorMessage}
      <Row>
        <Col span={24}>
          <DefaultCKEFooter {...editorFooterConfig} />
        </Col>
        <Col span={24}>
          {(enabledFeatures.ai_writing_assistant || import.meta.env.VITE_MODE === "development") && (writingAssistantConfig.enabled) && (
            <WritingAssistant config={writingAssistantConfig} editor={myEditor} isEditorReady={isEditorReady} />
          )}
        </Col>
      </Row>
    </>
  ) : (
    <Skeleton.Input active={true} size={"small"} />
  );
  // /> : appType == "seb" ? <TextArea placeholder="Write here.." rows={2} /> : <Skeleton.Input active={true} size={'small'} />
};
export default DefaultCKE;
