import React, { useState } from "react";
import { Select, DatePicker } from "antd";
import { actions as genericActions } from "/src/App/genericRedux";
import { MrSelect } from "mr_react_framework";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import "./AIUsageFilter.scss"
import { getUTCDate } from "/src/lib/utils/helperMethods";
const { RangePicker } = DatePicker;

const SelectUsers = MrSelect({
  actions: genericActions,
  resourceName: "users",
  config: {
    url: "/users",
    processData: (data, props) => {
      console.log("data select groups", data);
      console.log("props select groups", props);
      return data.map((item) => {
        return { label: item.name, value: item.id };
      });
    },
    searchApi: true,
    widgetConfig: {
      showSearch: true, // if false, this will either load entire data from backend on widget load or onSearch
      // TBD: If false, need to be able to search. Also if can do regular select from redux with this component, then can use same everywhere.
      filterOption: true,
      optionFilterProp: "label",
      placeholder: "All users",
      style: { width: "200px" },
      allowClear: true,
    },
  },
});

const AIUsageFilter = (props) => {
  const { orgId, logParams, setLogParams } = props;
  console.log("props inside the filter 4541==>", props)
  const mrIntl = useTranslate();
  const [selectedDateRange, setSelectedDateRange] = useState([getUTCDate({ type: "startOf", daysDiff: -30 }), getUTCDate({ type: "endOf" })]);
  console.log("selectedDateRange selectedDateRange==>", selectedDateRange);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedAction, setSelectedAction] = useState();

  const handleOnChange = (key, value, dateString) => {
    let params = { ...logParams };
    switch (key) {
      case "selectedAction":
        if (value) {
          setSelectedAction(value);
          params["action_type"] = ["==", value];
        } else {
          setSelectedAction(null);
          delete params["action_type"]; // Only remove the action_type from params
        }
        break;
      case "selectedDateRange":
        if (value) {
          if (dateString && dateString.length === 2) {
            params["created_at"] = [
              ">=",
              new Date(dateString[0]),
              "<=",
              new Date(dateString[1]),
            ];
            setSelectedDateRange(value);
          }
        } else {
          setSelectedDateRange(null);
          delete params["created_at"];
        }
        break;
      case "selectedUser":
        if (value) {
          setSelectedUser(value);
          params["user_id"] = ["==", value];
        } else {
          setSelectedUser(null);
          delete params["user_id"];
        }
        break;

      default:
        break;
    }
    setLogParams({ ...params });
  };

  return (
    <div className="ai-usage-filters">
      <RangePicker
        className="range-picker"
        value={selectedDateRange}
        onChange={(value, dateString) => handleOnChange("selectedDateRange", value, dateString)}
        placeholder={[mrIntl("AIUsageFilter.start_date"), mrIntl("AIUsageFilter.end_date")]}
        disabledDate={(currentDate) => {
          const todayDate = new Date();
          const threeMonthsAgoDate = new Date().setMonth(todayDate.getMonth() - 3);
          if (currentDate && (currentDate < threeMonthsAgoDate)) {
            return true;
          }
          return false;
        }}
      />
      <Select
        className="mr-select"
        value={selectedAction}
        placeholder={mrIntl("AIUsageFilter.all_actions")}
        onChange={(value) => handleOnChange("selectedAction", value)}
        allowClear={() => handleOnChange("selectedAction", null)}
        options={[
          {
            value: "generate_segments",
            label: mrIntl("AIUsage.action_generate_questions"),
          },
          {
            value: "chat",
            label: mrIntl("AIUsage.action_chat"),
          },
          {
            value: "generate_solutions",
            label: mrIntl("AIUsage.action_generate_solutions"),
          },
          {
            value: "grading",
            label: mrIntl("AIUsage.action_grading"),
          }
        ]}
      />
      <SelectUsers
        // value={selectedUser}
        onChange={(value) => handleOnChange("selectedUser", value)}
        config={{
          params: { by_role: "admin", by_org_id: orgId },
        }}
      />
    </div>
  );
};

export default AIUsageFilter;