import { createSelector } from "reselect";

const allTagsState = (state, props) =>
  state.tags.get("tags", []);

export const allTagsSelector = (tagId) =>
  createSelector([allTagsState], (tags) => {
    if (tagId) {
      // console.log("StandardSetTagData allTagsSelector", tags, tagId);
      return tags.find((tag) => tag.id === tagId);
    }
    return tags;
  });