import React, { useEffect, useState } from "react";
import {
  Segment,
} from "/src/components/UI/Segment/UIHelper";
import { getUniqueId, removeHTMLTagsFromText } from "/src/lib/utils/helperMethods";
import { cloneDeep } from "lodash";
import {
  addItemToArray,
  getItemsOfSize,
  getParsedJSONObject,
  removeItemFromArray,
} from "../InteractiveHelpers";
import ConnectForm from "./ConnectForm";
import CrypticHunt from "../CrypticHunt/CrypticHunt";
import ConnectAnswer, { checkResponses } from "./ConnectAnswer";
import ConnectGame, { convertToConnectGame } from "./ConnectGame";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const Connect = (props) => {
  const mrIntl = useTranslate()
  console.log("Connet props ==>", props);
  const {
    question_type,
    showCorrectAnswerSwitch,
    segmentSettings,
    triggerStateUpdate,
    setRenderMath,
    segmentFooterAttributes,
    createMode,
    answerMode,
    presentationMode,
    autoCheckMode,
    gameMode,
  } = props;
  let { parsedJSONObject } = getParsedJSONObject(props);
  let textAnswerString = props.text_answer || "";
  const propsAttemptStatus = props.attempt_status || {};
  let attemptStatusObject = {
    reset_count: propsAttemptStatus.reset_count || 0,
    json_attempted: propsAttemptStatus.json_attempted || false,
  };
  const hintsExtra = {
    contentRequired: false,
    contentPrefix: "Hint",
    placeholderPrefix: mrIntl("Connect.hint"),
  };
  const answersExtra = {
    contentRequired: false,
    contentPrefix: "Correct Answer",
    placeholderPrefix: mrIntl("CommonText.correct_answer"),
  };
  if (createMode) {
    parsedJSONObject = {
      segment_data: {
        hints: getItemsOfSize(0, 1, hintsExtra),
        answers: getItemsOfSize(0, 1, answersExtra),
      },
      segment_version: "1.0.1",
    };
    if (question_type == "connect") {
      parsedJSONObject = props.value || parsedJSONObject;
    }
  }

  if (!props.response_json && gameMode) {
    // NOTE: In game mode using teacher_json which has answers too.
    parsedJSONObject = convertToConnectGame(parsedJSONObject);
  }

  if (autoCheckMode) {
    let question_json = cloneDeep(props.teacher_json);
    let answer_json = cloneDeep(parsedJSONObject);
    // parsedJSONObject = checkResponses(question_json, answer_json);
  }

  const [state, setState] = useState(parsedJSONObject);
  const [textAnswer, setTextAnswer] = useState(textAnswerString);
  const [attemptStatus, setAttemptStatus] = useState(attemptStatusObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);
  const screenSettings = props.screenSettings || {};

  const [stateSetting, setStateSetting] = useState({
    autoCheckMode: autoCheckMode,
    quickCheckModeEnabled: false,
    showCorrectAnswerSwitch: showCorrectAnswerSwitch,
    showCorrectAnswerSwitchValue: false,
  });

  let currentJSON, text_answer, attempt_status;
  if (presentationMode && !stateSetting.showCorrectAnswerSwitchValue) {
    currentJSON = parsedJSONObject;
    text_answer = textAnswerString;
    attempt_status = attemptStatusObject;
  } else {
    currentJSON = state;
    text_answer = textAnswer;
    attempt_status = attemptStatus;
  }

  const segment_data = currentJSON?.segment_data || {};
  const options = segment_data.options ? segment_data.options : [];
  const possible_responses = segment_data.possible_responses || [];
  const hints = segment_data.hints || [];
  const answers = segment_data.answers || [];
  const answer = segment_data.answer || {};

  useEffect(() => {
    if (setRenderMath) {
      setRenderMath(getUniqueId());
    }
  }, []);

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let teacher_json = cloneDeep(state);
        props.onChange(teacher_json);
      }
    } else if (answerMode && stateUpdatedCount > 0) {
      if (props.onChange) {
        let result = {
          response_json: cloneDeep(state),
          attempt_status: cloneDeep(attemptStatus),
        };

        props.onChange(result);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    setState(state);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const saveTextAnswer = (data) => {
    setTextAnswer(data);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  // const addHints = (index) => {
  //   if (hints.length < 4) {
  //     const extra = {
  //       contentRequired: false,
  //       contentPrefix: 'Hint',
  //       placeholderPrefix: 'Hint'
  //     }
  //     const newHints = addItemToArray(hints, extra)

  //     const newSegmentData = {
  //       ...segment_data,
  //       hints: newHints
  //     }

  //     const newState = {
  //       ...state,
  //       segment_data: newSegmentData
  //     }

  //     saveState(newState)
  //   } else {
  //     showInfoModal({
  //       type: 'warning',
  //       title: 'Reached maximum allowed limit!'
  //     })
  //   }
  // }

  const addCorrectAnswer = (index) => {
    const extra = {
      contentRequired: false,
      contentPrefix: "Correct Answer",
      placeholderPrefix: "Correct Answer",
    };
    const newAnswers = addItemToArray(answers, extra);

    const newSegmentData = {
      ...segment_data,
      answers: newAnswers,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  // const removeFile = (type, index, childIndex) => {
  //   const newHints = hints

  //   if (type == "hints") {
  //     delete newHints[index].files
  //   }

  //   const newSegmentData = {
  //     ...segment_data,
  //     hints: newHints
  //   }

  //   const newState = {
  //     ...state,
  //     segment_data: newSegmentData
  //   }

  //   saveState(newState)
  // }

  // const removeHints = (index) => {
  //   if (hints.length > 1) {
  //     const extra = {
  //       noMinSize: true
  //     }
  //     const newHints = removeItemFromArray(hints, index, extra)
  //     const newSegmentData = {
  //       ...segment_data,
  //       hints: newHints
  //     }
  //     const newState = {
  //       ...state,
  //       segment_data: newSegmentData
  //     }

  //     saveState(newState)
  //   } else {
  //     showInfoModal({
  //       type: 'warning',
  //       title: 'Reached maximum allowed limit!'
  //     })
  //   }
  // }

  // const removeCorrectAnswer = (index) => {
  //   if (answers.length > 1) {
  //     const extra = {
  //       noMinSize: true
  //     }
  //     const newAnswers = removeItemFromArray(answers, index, extra)
  //     const newSegmentData = {
  //       ...segment_data,
  //       answers: newAnswers
  //     }
  //     const newState = {
  //       ...state,
  //       segment_data: newSegmentData
  //     }
  //     saveState(newState)
  //   } else {
  //     showInfoModal({
  //       type: 'warning',
  //       title: 'Reached maximum allowed limit!'
  //     })
  //   }
  // }

  // const onTextChanged = (type, data, index, childIndex) => {
  //   const newHints = hints
  //   const newAnswers = answers
  //   const newAnswer = answer
  //   const i = newAnswers.findIndex((g) => g.id == data.id);
  //   if (type == 'correct') {
  //     if (index === 0) {
  //       newAnswers[index].content = data
  //       newAnswer.content = data
  //     } else {
  //       newAnswers[index].content = data
  //     }

  //   } else if (type == 'hints') {
  //     newHints[index].content = data
  //   }
  //   const newSegmentData = {
  //     ...segment_data,
  //     hints: newHints,
  //     answers: newAnswers,
  //     answer: newAnswer
  //   }

  //   const newState = {
  //     ...state,
  //     segment_data: newSegmentData
  //   }

  //   saveState(newState)
  // }

  // const onChangedText = (data) => {
  //   const newAnswer = answer
  //   newAnswer.content = data
  //   const newSegmentData = {
  //     ...segment_data,
  //     answer: newAnswer
  //   }

  //   const newState = {
  //     ...state,
  //     segment_data: newSegmentData
  //   }

  //   saveState(newState)
  //   if (answerMode) {
  //     validateAttempted(newState, data)
  //   }

  // }

  const onSaveMedia = (type, index, files, childIndex) => {
    const newHints = hints;

    if (type == "hints") {
      newHints[index].files = files;
    }

    const newSegmentData = {
      ...segment_data,
      hints: newHints,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  return (
    <Segment>
      <div className="segment-connect">
        {createMode ? (
          <ConnectForm
            {...props}
            segment_data={segment_data}
            hints={hints}
            answers={answers}
            answer={answer}
            state={state}
            saveState={saveState}
          ></ConnectForm>
        ) : gameMode ? (
          <ConnectGame
            {...props}
            segment_data={segment_data}
            hints={hints}
            answers={answers}
            answer={answer}
            state={state}
            saveState={saveState}
            attemptStatus={attemptStatus}
            setAttemptStatus={setAttemptStatus}
          ></ConnectGame>
        ) : (
          <ConnectAnswer
            {...props}
            segment_data={segment_data}
            hints={hints}
            answers={answers}
            answer={answer}
            state={state}
            saveState={saveState}
            attemptStatus={attemptStatus}
            setAttemptStatus={setAttemptStatus}
            stateSetting={stateSetting}
            setStateSetting={setStateSetting}
          ></ConnectAnswer>
        )}
      </div>
    </Segment>
  );
};
export default Connect;
