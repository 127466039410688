import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Row, Input, Checkbox, Card, List, Col, Space, InputNumber, Tooltip, Select, Rate, Tag, Radio} from "antd";
import {useSelector} from "react-redux";
import { activeAdjustedExperienceSelector, activeExperienceGradingDisabledSelector } from '/src/views/Experiences/selector';
import { displayPoints, focusElement, getStrandsFromRubricsWithUpdatedCA } from '/src/lib/utils/helperMethods';
import { isEmpty, orderBy } from 'lodash';
import { useTranslate } from '/src/lib/MrTranslate/MrTranslate';
import { showConvertedAchievementLevelSelector } from '/src/views/ExperienceUsers/selector';
import { getSegmentByIdSelector } from '../selector';

const RubricCriteriaPointsForm = (props) => {
  const {value, onChange, id, selectedRubric, associableType, segmentId = ""} = props;
  console.log("RubricCriteriaPointsForm props", props);
  console.log("RubricCriteriaPointsForm value", value);
  // value will be set by MrFormComponent automatically, in case of default value and edit form
  const showConvertedAchievementLevel = useSelector(showConvertedAchievementLevelSelector())
  const currentSegment = useSelector(getSegmentByIdSelector(segmentId))
  console.log("currentSegment =====>", currentSegment);
  // let initialValue = value || []
  const [criteriaAssociations, setCriteriaAssociations] = useState([])
  const [formType, setFormType] = useState(null)
  const [scaType, setScaType] = useState(null)
  const { Option } = Select;

  let rubric
  
  useEffect(() => {
    if(value && criteriaAssociations.length == 0){
      let newValue = value.map((criterium, i) => {
        return {
          ...criterium,
          checked: true
        }
      })
      // setCriteriaAssociations(value)
      
      setCriteriaAssociations(newValue)
      // onChange(newValue);
    }
    if(id && !formType){
      if(id.indexOf("submission_criterium_associations_attributes") > -1){
        setFormType("sca")
      }else{
        setFormType("ca")
      }
      if(id.indexOf("experienceUser") > -1){
        setScaType("eu")
      }else{
        setScaType("ur")
      }
    }
    console.log("RubricCriteriaPointsForm value useEffect", value);
  }, [value]);

  useEffect(() => {
    if (rubric.type_c !== "myp_achievement_level") {
      focusElement("form#userResponses_1 input")
    }
  }, [formType])
  
  // const activeExperienceId = useSelector(activeAdjustedExperienceIdSelector())
  // const activeExperience = useSelector(state => state.experiences.get("experiences").find((item) => item.id == activeExperienceId))
  const activeExperience = useSelector(activeAdjustedExperienceSelector())
  const rubricType = activeExperience && activeExperience.rubric && activeExperience.rubric.type_c
  const isMYPAchievementLevel = rubricType === "myp_achievement_level";
  const shouldShowConvertedAchievementLevel = showConvertedAchievementLevel && scaType === "eu"
  const activeExperienceSettings = (activeExperience && activeExperience.settings) || {};
  const showStrandsWithIsTSCEnabled = activeExperienceSettings.tsc_enabled && isMYPAchievementLevel;
  const showStrands = isMYPAchievementLevel || rubricType === "criteria_with_points";

  if(!isEmpty(activeExperience)){
    rubric = activeExperience.rubric
  }else{
    rubric = selectedRubric
  }
  const criteria = rubric.criteria

  const activeExperienceGradingDisabled = useSelector(activeExperienceGradingDisabledSelector())


  const customOnChangeUpdatePoints = (value, criterium) => {
    console.log("customOnChange", value, criterium)
    console.log("customOnChange criteriaAssociations", criteriaAssociations)
    let updatedPoints = value
    let updatedCriteriaAssociations = [...criteriaAssociations]
    // find the criteria whose points were updated and update updatedCriteriaAssociations
    let existingCA = updatedCriteriaAssociations.find(ca => ca.criterium_id === criterium.id)
    if(existingCA){
      existingCA.points = updatedPoints
    }

    // if(existingCA){
    //   // update points
    //   if(!updatedPoints){
    //     // remove if empty - problem here in case of edit
    //     if(existingCA.id){
    //       // edit case, dont splice, if user adds pts again, id gets lost, causes to create again on BE
    //       existingCA.points = 0
    //       existingCA._destroy = true
    //     }else{
    //       let idx = updatedCriteriaAssociations.indexOf(existingCA)
    //       updatedCriteriaAssociations.splice(idx,1)
    //     }
    //   }else{
    //     existingCA.points = updatedPoints
    //   }
    // }else{
    //   // create CA
    //   let newCA = {
    //     criterium_id: criterium.id,
    //     points: updatedPoints,
    //     associable_type: "Segment",
    //     custom_fields: {
    //       title: criterium.title,
    //       label: criterium.label
    //     }
    //   }
    //   updatedCriteriaAssociations.push(newCA)
    // }
    
    console.log("customOnChange updatedCriteriaAssociations", updatedCriteriaAssociations)
    setCriteriaAssociations(updatedCriteriaAssociations)
    onChange(updatedCriteriaAssociations);
  }

  const customOnChangeSCA = (value, criteriumAssociation, criteriaLevels) => {
    console.log("customOnChange ==>321", value,  criteriumAssociation)
    console.log("customOnChange criteriaAssociations", criteriaAssociations, activeExperience.rubric)
    let isNA = value === "na"

    // let updatedPoints = isMYPAchievementLevel ? value - 1 : value; //antd Rate component return index + 1
    let updatedPoints = value;

    // in case criteriaLevels are present, means level being selected - and so value = level_id not points
    let maxPoints = criteriumAssociation.custom_fields.max_points
    console.log("typeof(updatedPoints)", updatedPoints, maxPoints, typeof(parseFloat(updatedPoints)))
    let updatedCriteriaAssociations = [...criteriaAssociations]
  
    // // find the criteria whose points were updated and update updatedCriteriaAssociations
    let existingCA = updatedCriteriaAssociations.find(ca => ca.criterium_id === criteriumAssociation.criterium_id)
    if(existingCA){
      if(criteriaLevels && criteriaLevels.length){
        // selecting level
        // in case criteriaLevels are present, means level being selected - and so value = level_id not points
        existingCA.level_id = value
        let selectedLevel = criteriaLevels.find((level) => level.id === value)
        if(selectedLevel){
          existingCA.custom_fields.level_label = selectedLevel.level_label
          if(selectedLevel.level_points){
            existingCA.points = selectedLevel.level_points
          }
        }
      }else{
        // # APL-5014 - if NA is selected, set points to null
        if (isNA) {
          existingCA.custom_fields.is_na = true
          if (shouldShowConvertedAchievementLevel) {
            existingCA.custom_fields.converted_achievement_level = null
          } else {
            existingCA.points = null
          }
        } else {
          if (existingCA.custom_fields.is_na === true) {
            existingCA.custom_fields.is_na = false
          }
          // inputing points       
          if (shouldShowConvertedAchievementLevel) {
            existingCA.custom_fields.converted_achievement_level = updatedPoints
          } else {
            existingCA.points = updatedPoints
          }
        }
      }
      // existingCA.points = parseFloat(updatedPoints) || 0  // if blank set to 0
      setCriteriaAssociations(updatedCriteriaAssociations)
      onChange(updatedCriteriaAssociations);
    }
    
    // if(updatedPoints == "0" || (parseFloat(updatedPoints) >= 0 && parseFloat(updatedPoints) <= maxPoints && typeof(parseFloat(updatedPoints)) == "number")){
    //   onChange(updatedCriteriaAssociations);
    // }
    // else{
    //   console.log("invalid points - not allowed")
    //   // how to fail validation? ie disable submit btn
    //   // use rules validator only"comments_only"
    // }
   
  }
  const mrIntl = useTranslate()

  const customOnChangeChecked = (e, criterium) => {
    console.log("customOnChangeChecked", e.target.checked, criterium)
    console.log("customOnChangeChecked criteriaAssociations", criteriaAssociations)
    let checked = e.target.checked
    let updatedCriteriaAssociations = [...criteriaAssociations]
    // find the criteria whose points were updated and update updatedCriteriaAssociations
    let existingCA = updatedCriteriaAssociations.find(ca => ca.criterium_id === criterium.id)
    if(existingCA){
      // update checked
      if(checked){
        existingCA.checked = true
        existingCA._destroy = false
      }
      else{
        // remove if empty - problem here in case of edit
        console.log("customOnChangeChecked removing")
        if(existingCA.id){
          console.log("customOnChangeChecked deleting")
          // edit case, dont splice, if user adds pts again, id gets lost, causes to create again on BE
          // existingCA.points = ""
          existingCA.checked = false
          existingCA._destroy = true
        }else{
          console.log("customOnChangeChecked splicing")
          let idx = updatedCriteriaAssociations.indexOf(existingCA)
          updatedCriteriaAssociations.splice(idx,1)
        }
        // let idx = updatedCriteriaAssociations.indexOf(existingCA)
        // updatedCriteriaAssociations.splice(idx,1)
      }
    }else{
      console.log("customOnChangeChecked creating new")
      // create CA
      let newCA = {
        criterium_id: criterium.id,
        checked: true,
        // points: "1",
        associable_type: associableType || "Segment",
        custom_fields: {
          title: criterium.title,
          label: criterium.label
        }
      }
      // if(rubric.type_c != "myp_achievement_level"){
      //   newCA.points = "1" 
      // }
      // TEMP modification
      if(rubric.type_c != "myp_achievement_level" && !criterium.levels_json.length){
        newCA.points = "1" 
      }
      //TODO: below covers all cases once myp_al and cwp have been merged into custom_rubric structure.
      // if(!criterium.levels_json.length){
      //   newCA.points = "1" 
      // }
      updatedCriteriaAssociations.push(newCA)
    }
    
    console.log("customOnChangeChecked updatedCriteriaAssociations", updatedCriteriaAssociations)
    setCriteriaAssociations(updatedCriteriaAssociations)
    onChange(updatedCriteriaAssociations);
  }

  let finalRender = [];
  if(formType == "ca"){
    if(criteria){
      criteria.map((criterium, i) => {
        let criteriumAssociation = criteriaAssociations.find(ca => ca.criterium_id === criterium.id) || {}
        if(rubric.type_c == "myp_achievement_level" || criterium.levels_json.length > 0){
          // allow checkbox criteria even though grading is overall
          // TODO: LATER - not doing L1-8 right now
          // if(rubric.type_c == "myp_achievement_level"){
          //   finalRender.push(
          //     <Space className="rubric-myp-al-level-select-container" size={0}>
          //       <Tooltip title={criterium.title + ": " + criterium.label}>
          //         <Checkbox className="rubric-points-input"  key={`criteria-form-checkbox-${criterium.id}`} checked={criteriumAssociation.checked} onChange={(e) => customOnChangeChecked(e, criterium)}>{criterium.title}</Checkbox>
          //       </Tooltip>
          //       <Select
          //         defaultValue={criteriumAssociation.points ? parseInt(criteriumAssociation.points) : 8}
          //         style={{
          //           width: 80,
          //         }}
          //         size={"small"}
          //         disabled={!criteriumAssociation.checked}
          //         onChange={(value) => customOnChangeUpdatePoints(value, criterium)}
          //         options={[
          //           {
          //             value: 2,
          //             label: '1-2',
          //           },
          //           {
          //             value: 4,
          //             label: '3-4',
          //           },
          //           {
          //             value: 6,
          //             label: '5-6',
          //           },
          //           {
          //             value: 8,
          //             label: '7-8',
          //           },
          //         ]}
          //       />
          //     </Space>
          //   )
          // } else {
            finalRender.push(
              <Tooltip title={criterium.title + ": " + criterium.label}>
                <Checkbox className="rubric-points-input" key={`criteria-form-checkbox-${criterium.id}`} checked={criteriumAssociation.checked} onChange={(e) => customOnChangeChecked(e, criterium)}>{criterium.title}</Checkbox>
              </Tooltip>
            )
          // }
        }else{

          let addonBefore = <Tooltip title={criterium.title + ": " + criterium.label}>
            <Checkbox key={`criteria-form-checkbox-${criterium.id}`}  checked={criteriumAssociation.checked} onChange={(e) => customOnChangeChecked(e, criterium)}>{criterium.title}</Checkbox>
          </Tooltip>
          finalRender.push(
            <Input className="rubric-points-input" key={`criteria-form-input-${criterium.id}`} disabled={!criteriumAssociation.checked} addonBefore={addonBefore} value={criteriumAssociation.points} placeholder={mrIntl("CommonText.pts")} onChange={(e) => customOnChangeUpdatePoints(e.target.value, criterium)}/>
          )
        }
      })
    }

  }
  else if(formType == "sca"){
    if(criteriaAssociations){
      criteriaAssociations.map((sca, i) => {

        console.log("criteriaAssociations sca ===>>", sca,i)
        let addonBefore = <Tooltip title={sca.custom_fields.title + ": " + sca.custom_fields.label}>{sca.custom_fields.title}</Tooltip>

        let currentCA = {}
        if(activeExperience) {
          const criteriumAssociations = activeExperience.criterium_associations || []
          const relationItems = activeExperience.relation_items || []
          if(showStrandsWithIsTSCEnabled) {
            currentCA = criteriumAssociations.find(ca => ca.criterium_id === sca.criterium_id)
          } else if (showStrands) {
            let updatedCA = []
            if(scaType === "eu") {
              updatedCA = getStrandsFromRubricsWithUpdatedCA(criteriumAssociations, criteria, relationItems)
            } else {
              updatedCA = getStrandsFromRubricsWithUpdatedCA(currentSegment.criterium_associations_attributes, criteria, relationItems)
            }
            currentCA = updatedCA && updatedCA.find(ca => ca.criterium_id === sca.criterium_id)
          }
        }
        const orderedStrands = (currentCA && currentCA.strands && orderBy(currentCA.strands, ["label"], ["asc"])) || []

        if(rubric.type_c === "custom"){
          let criteriaLevels = (criteria.find(c => c.id === sca.criterium_id) || {}).levels_json
          if(!isEmpty(criteriaLevels)){
            let addonAfter = <Select value={sca.level_id} style={{ width: 80 }} onChange={(value) => customOnChangeSCA(value, sca, criteriaLevels)}>
              {criteriaLevels.map((l, i) => {
                return <Option value={l.id} title={l.desc}>{l.level_label}</Option>
              })}
            </Select>
            // IMP: hiding the input box, keeping levels in the addOnAfter but TODO: activeExperienceGradingDisabled may not work here - may be change to proper Select - no need for InputNumber here
            finalRender.push(
              <InputNumber className="rubric-points-input level-selector" disabled={activeExperienceGradingDisabled} style={{"width": scaType == "eu" ? "110px" : "auto"}} key={`criteria-form-input-${sca.id}`} addonBefore={addonBefore} value={sca.points} placeholder={mrIntl("CommonText.points")} addonAfter={addonAfter} keyboard={false} controls={false} />
            )
          }else{
            finalRender.push(
              <InputNumber className="rubric-points-input" disabled={activeExperienceGradingDisabled} style={{"width": scaType == "eu" ? "110px" : "auto"}} key={`criteria-form-input-${sca.id}`} addonBefore={addonBefore} value={sca.points} placeholder={mrIntl("CommonText.points")} addonAfter={displayPoints(sca.custom_fields.max_points)} onChange={(value) => customOnChangeSCA(value, sca)} keyboard={false} controls={false}/>
            )
          }
        }else if(isMYPAchievementLevel || shouldShowConvertedAchievementLevel){
          // console.log("sca.points === index", sca.points index)
          // let radioButtonsRender = [0,1,2,3,4,5,6,7,8].map((i) => {
          //   return <Radio.Button onKeyDown={(e) => {e.preventDefault();}} value={i}>{i}</Radio.Button>
          // })
          let radioButtonsRender = []
          if (scaType === "eu") {
            radioButtonsRender.push(<Radio.Button onKeyDown={(e) => {e.preventDefault();}} value={"na"}>N/A</Radio.Button>)
          }
          // TODO: LATER - not doing L1-8 right now
          // let maxPoints = sca.custom_fields.max_points || 8
          // TODO: LATER - not doing L1-8 right now
          let maxPoints = 8
          for (let i = 0; i <= maxPoints; i++) {
            radioButtonsRender.push(<Radio.Button onKeyDown={(e) => {e.preventDefault();}} value={i}>{i}</Radio.Button>) 
          }
          let pointsValue = sca.points
          if (shouldShowConvertedAchievementLevel) {
            pointsValue = sca.custom_fields.converted_achievement_level 
          }
          
          finalRender.push(
            
            <React.Fragment key={`criteria-form-input-${sca.id}`}>
              <div key={`show-rubric-criteria`} className={((showStrandsWithIsTSCEnabled || showStrands) && !shouldShowConvertedAchievementLevel) ? `show-rubric-criteria` : ''}>
                <Space className='myp-al-criteria-level-selector'>
                  {/* <h4>{`${sca.custom_fields.title}: ${sca.custom_fields.label}`}</h4> */}
                  <Tooltip title={sca.custom_fields.label}>
                    <div className='criteria-title'>{sca.custom_fields.title}</div>
                  </Tooltip>
                  <Radio.Group className="radio-level-selector" value={sca.custom_fields.is_na === true ? "na" : parseInt(pointsValue)} buttonStyle="solid" onChange={(e) => customOnChangeSCA(e.target.value, sca)} disabled={scaType === "eu" && activeExperienceGradingDisabled}>
                    {radioButtonsRender}
                  </Radio.Group>
                </Space>
                {!shouldShowConvertedAchievementLevel && orderedStrands.map((st) => (
                  <div key={`rubric-criteria-level-descriptor-${st.title}`} style={{ marginTop: 10 }}>
                    {st.title + '. ' + st.desc}
                  </div>
                ))}
              </div>            

              {/* <Rate
                className="rubric-points-rate"
                character={({ index }) => <Tag color={parseInt(displayPoints(sca.points)) === index ? "#1890ff" : "default"}>{index}</Tag>}
                value={sca.points}
                // defaultValue={sca.points}
                autoFocus={true}
                count={9}
                onChange={(value) => customOnChangeSCA(value, sca)}
                onKeyDown={(e) => {e.preventDefault();}}
              /> */}
              
          </React.Fragment>
          )
        }else{
          // can get rid of this once myp al and cwp also moved to custom
          finalRender.push(
            <div className={`${showStrands && "show-rubric-criteria"}`}>
              <InputNumber className="rubric-points-input" disabled={activeExperienceGradingDisabled} style={{ "width": scaType == "eu" ? "110px" : "auto" }} key={`criteria-form-input-${sca.id}`} addonBefore={addonBefore} value={sca.points} placeholder={mrIntl("CommonText.points")} addonAfter={displayPoints(sca.custom_fields.max_points)} onChange={(value) => customOnChangeSCA(value, sca)} keyboard={false} controls={false} />
              {orderedStrands.map((st) => (
                <div key={`rubric-criteria-level-descriptor-${st.title}`} style={{ marginTop: 10 }}>
                  {st.title + '. ' + st.desc}
                </div>
              ))}
            </div>)
        }

      })
    }
  }

  return <React.Fragment>
    {/* <Space direction={formType == "sca" && scaType == "ur" ? "vertical" : "horizontal" } size={5} style={{"flexWrap": scaType == "eu" ? "wrap" : ""}}>
      {finalRender}
    </Space> */}

    <Space direction={formType == "sca" ? "vertical" : "horizontal" } size={formType == "sca" ? 15 : 5} className={`${shouldShowConvertedAchievementLevel ? "m-t-15" : ""} rubric-criteria-points-container`}>
      {shouldShowConvertedAchievementLevel && <b>{mrIntl("RubricCriteriaPointsForm.equivalent_achievement_level")}</b>}
      {finalRender}
      
    </Space>
      
  </React.Fragment>;
};
RubricCriteriaPointsForm.defaultProps = {}
RubricCriteriaPointsForm.propTypes = {}
export default RubricCriteriaPointsForm;

