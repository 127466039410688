import React from "react"
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { Select, Tooltip } from "antd";
import { convertMs, extraTimeValues } from "/src/lib/utils/helperMethods";
import { useSelector } from "react-redux";
import { experiencePausedSelector } from "../FirestoreInteractions/selector";

export const getCurrentExtraTime = (item, experienceDuration, timeInMiliSeconds) => {
  console.log("getCurrentExtraTime ==>", item, experienceDuration, item.extra_time);
  const accommodations = item.accommodations || {};
  const additionalTime = parseInt(accommodations.additional_time) || 0;
  const duration = experienceDuration || 0;

  let extraTime = null;
  if (typeof item.extra_time !== "undefined") {
    extraTime = Math.floor(item.extra_time / 60000);
  } else if (additionalTime) {
    if (accommodations.additional_time_type === "minutes") {
      extraTime = additionalTime;
    } else {
      extraTime = Math.round((duration * additionalTime) / 100);
    }
  }
  if (timeInMiliSeconds) {
    extraTime = (extraTime || 0)* 60000;
  }
  return extraTime;
}

const AddTimeBtn = (props) => {
  console.log('AddTimeBtn===>>>>>>', props);
  const {
    item,
    itemState,
    updateRecord,
    experienceDuration,
  } = props

  const { Option } = Select;
  const mrIntl = useTranslate();

  const extraTime = getCurrentExtraTime(item, experienceDuration)
  const experiencePaused = useSelector(experiencePausedSelector)
  console.log("AddTimeBtn ==>", extraTime, extraTimeValues); 

  const getAddTimeTooltipTitle = (itemState, experiencePaused, mrIntl) => {
    if (itemState?.state !== "online") {
      return mrIntl("StudentsList.cannot_add_time_while_student_is_offline");
    }
    if (experiencePaused) {
      return mrIntl("StudentsList.test_is_paused");
    }
    if (item.currentView === "submittedTest") {
      return mrIntl("AddTimeBtn.can_not_add_time_to_submitted_test");
    }
    return mrIntl("StudentsList.total_extra_time_in_minutes");
  }; 

  const isAddTimeButtonDisabled = (item, itemState, experiencePaused) => {
    return item.currentView === "submittedTest" || itemState.state !== "online" || experiencePaused;
  };

  return (
    <Tooltip
      key={`add-time-btn-${item.uid}`}
      title={getAddTimeTooltipTitle(itemState, experiencePaused, mrIntl)}
      placement="left"
    >
      <Select
        title={null}
        placeholder={mrIntl("CommonText.extra_time")}
        disabled={isAddTimeButtonDisabled(item, itemState, experiencePaused)}
        size="small"
        style={{ minWidth: 70 }}
        value={extraTime}
        onChange={(value) => {
          let exTimeOffset = 0;
          if (item.currentView === "endTest") {
            let totalTestTime =
              item.started_at +
              convertMs(experienceDuration) +
              (item.extra_time || 0);
            if (item.is_time_up === true) {
              exTimeOffset = Date.now() - totalTestTime;
            } else {
              exTimeOffset = item.extra_time_offset || 0;
            }
          } else if (item.currentView === "startTest") {
            // when coming back to test after adding time and then adding time was an issue.
            exTimeOffset = item.extra_time_offset || 0;
          }
          updateRecord(
            item.uid,
            { extra_time: convertMs(value), extra_time_offset: exTimeOffset },
            item,
            {
              log: {
                logging: true,
                action: "extra_time_added",
                user_id: item.user_id,
                current_value: {
                  time: value
                },
              },
            }
          );
        }}
      >
        {extraTimeValues.map((extraTimeValue) => {
          return <Option key={`extra-time-option-${extraTimeValue}`} value={extraTimeValue} disabled={extraTime >= extraTimeValue
            ? true : false}>
            {extraTimeValue}
          </Option>
        })}
      </Select>
    </Tooltip>
  );
};

export default AddTimeBtn;