import React, { useState, useEffect, useCallback, memo } from "react";
import {
  Button,
  Modal,
  Tooltip,
  Menu,
  Spin,
  Result,
  Dropdown,
  Popconfirm,
  Row,
  Col,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import {
  PlusOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { orgquestionbanksSelector } from "/src/views/Questionbanks/selector";
import { actions as experienceReduxActions } from "/src/views/Experiences/redux";
import {
  showExperienceHelper,
} from "/src/views/Experiences/ExperienceHelperMethods";
import { appTypeSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { dropDownTrigger } from "/src/lib/utils/helperMethods";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { SelectGrade, SelectProgrammeSubject, SelectSubject } from "/src/components/AppSpecific/SelectSubject/SelectSubject";
import { isEmpty } from "lodash";
import { message } from "/src/components/UI/AntdAppHelper";

const GradeOrSubjectNotExist = (props) => {
  console.log("GradeOrSubjectNotExist props ===>", props);
  const { subjectParams = {}, gradeParams = {}, confirm, currentData , setCurrentData } = props
  let { org_programme_id, subject_group_id } = subjectParams

  const gradeParamsExist = !isEmpty(gradeParams) ? true : false

  if(gradeParamsExist) {
    org_programme_id = gradeParams.org_programme_id
  }

  const mrIntl = useTranslate()

  let finalRender = null
  if(subject_group_id) {
    finalRender = <SelectSubject
    key="select-subject-groups"
    onChange={(value) => setCurrentData((currentData) => ({
      ...currentData,
      subject_id: value
    }))}
    config={{
      forceReload: true,
      params: {
        by_ids: [subject_group_id],
        by_org_programme_id: org_programme_id,
        by_type_c: "group",
        unarchived: true
      },
      widgetConfig: { style: { width: "100%" } }
    }} />
  } 

  if(gradeParams && gradeParams.org_programme_id) {
    finalRender = <SelectGrade
      key="select-grade-filter"
      onChange={(value) => setCurrentData((currentData) => ({
        ...currentData,
        grade_id: value
      }))}
      config={{
        forceReload: true,
        params: {
          by_org_programme_id: org_programme_id,
          unarchived: true
        },
        widgetConfig: { style: { width: "100%" } }
      }}
    />
  }

  return <>
    <p>{gradeParamsExist ? mrIntl("DuplicateExperienceButton.please_select_a_different_grade") : mrIntl("DuplicateExperienceButton.please_select_a_different_subject")}</p>
    <Row>
      <Col span={12}>
        {finalRender}
      </Col>
      <Col span={12}>
        <Tooltip title={mrIntl("DuplicateExperienceButton.duplicate")}>
          <Button
            type={"primary"}
            style={{ marginLeft: "10px" }}
            onClick={(e) => confirm(e, currentData)}
          >
            {mrIntl("DuplicateExperienceButton.duplicate")}
          </Button>
        </Tooltip>
      </Col>
    </Row>
  </>
}

const DuplicateExperienceButton = (props) => {
  console.log("DupSuccessModal props", props);
  const {
    sourceId,
    index,
    duplicateResource,
    isLibrary,
    item,
    showExperience,
    withText,
    showOpenBtn = true,
    popconfirmPlacement = "bottom",
  } = props;
  const [visible, setVisible] = useState(false);
  const [dupComplete, setDupComplete] = useState(false);
  const [newExperienceId, setNewExperienceId] = useState(null);
  const [copyToQBBtnMenuItems, setCopyToQBBtnMenuItems] = useState([]);
  const [subjectParams, setSubjectParams] = useState({})
  const [gradeParams, setGradeParams] = useState({})
  const [currentData, setCurrentData] = useState({})

  const currentUser = useSelector(currentUserSelector());
  const orgquestionbanks = useSelector(orgquestionbanksSelector());
  const appType = useSelector(appTypeSelector());
  const dispatch = useDispatch();
  const history = useHistory();
  const mrIntl = useTranslate();
  const isAdminOrTeacher = currentUser.role === "admin" || currentUser.role === "teacher"
  console.log("checkingsomevalues==>", isLibrary, isAdminOrTeacher);
  const handleOk = (e) => {
    e.stopPropagation()
    setVisible(false);
  };
  const handleCancel = (e) => {
    e.stopPropagation()
    setVisible(false);
  };
  const qbUser = currentUser.role === "qb_author"
  useEffect(() => {
    console.log("orgquestionbanks", orgquestionbanks, item);
    let copyToQBBtnMenuItemsTemp = [];
    if (orgquestionbanks) {
      orgquestionbanks.map((oqb) => {
        console.log(
          "orgquestionbanks",
          oqb.custom_fields.programme_id === item.relation_items[0].programme_id
        );
        if (
          oqb.custom_fields.programme_id === item.relation_items[0].programme_id
        ) {
          copyToQBBtnMenuItemsTemp.push(
            <Menu.Item key={oqb.questionbank_id}>
              {oqb.custom_fields.title}
            </Menu.Item>
          );
        }
      });
      setCopyToQBBtnMenuItems(copyToQBBtnMenuItemsTemp);
      console.log(
        "orgquestionbanks",
        copyToQBBtnMenuItemsTemp,
        copyToQBBtnMenuItems
      );
    }
  }, [orgquestionbanks]);

  let contentCompleteExtraArr = [];
  contentCompleteExtraArr.push(
    <Link to={`/u/tests`}>
      <Button key="my-assessments" type="primary" onClick={handleOk}>
        <MrTranslate id={"DuplicateExperienceButton.go_to_my_assessments"}/>
      </Button>
    </Link>
  );
  // TODO: Hiding for all for now - need to fix thie showExperience and Link - calling with original item not new item - won;t work
  false &&
    showOpenBtn &&
    contentCompleteExtraArr.push(
      <Link
        to={`/e/tests/${newExperienceId}`}
        onClick={(e) => {
          // showExperience(item, props.resourceName)

          showExperienceHelper(
            item,
            props.resourceName,
            null,
            currentUser,
            history,
            dispatch
          );
          // if(needsUnmount){
          //   // when duplicating from inside the test, an active test is open and we are calling showExperience for another test. needs unmount
          //   unMountExperience(item, appType, currentUser, dispatch)
          //   setTimeout(() => {
          //     showExperienceHelper(item, props.resourceName, null, currentUser, history, dispatch)
          //   }, 100)
          // }
          // else{
          // showExperienceHelper(item, props.resourceName, null, currentUser, history, dispatch)
          // }
        }}
      >
        <Button key="open-assessment" type="primary" onClick={handleOk}>
          <MrTranslate id={"DuplicateExperienceButton.open_assessment"}/>
        </Button>
      </Link>
    );
  contentCompleteExtraArr.push(
    <Button key="close" onClick={handleCancel}>
     <MrTranslate id = {"CommonText.close"}/>
    </Button>
  );

  let contentInProgress = (
    <div className="duplicate-loading">
      <Spin size="large" />
      <br/><br/>
      {mrIntl("DuplicateExperienceButton.duplicating")}...
    </div>
  );

  let contentComplete = (
    <Result
      status="success"
      title= {mrIntl("DuplicateExperienceButton.assessment_successfully_duplicated_into_your_assessments")}
      // title="Assessment successfully duplicated into your assessments!"
      subTitle= {mrIntl("DuplicateExperienceButton.click_below_to_open_your_assessment")}
      // subTitle="Click below to open your assessment"
      extra={contentCompleteExtraArr}
    />
  );

  const confirm = (e, currentData) => {
    e.stopPropagation();
    // deleted(item);
    setVisible(true);
    setDupComplete(false);
    // props.duplicateResource({id: sourceId}, {duplicateAtIndex: 0, successCallback: (data) => {console.log("duplicate done", data); setDupComplete(true);setNewExperienceId(data.data.experience.data.id)}})
    let extra = {}
    if(!isEmpty(currentData)) {
      extra = {
        ...extra,
        ...currentData
      }
    }

    dispatch(
      experienceReduxActions.duplicate(
        { uid: sourceId, id: sourceId, ...extra },
        {
          duplicateAtIndex: 0,
          success: {showMessage: true, message: mrIntl("DuplicateExperienceButton.assessment_successfully_duplicated")},
          error: {showMessage: false},
          // errorCallback: (error) => {
          //   console.log("error duplicate done", error.response);
          // },
          successCallback: (data) => {
            console.log("duplicate done", data);
            // setNewExperienceId(data.data.experience.data.id);
            setDupComplete(true);
            setNewExperienceId(data.data.experience.data.attributes.uid);
            setSubjectParams({})
            setGradeParams({})
          },
          errorCallback: (error) => {
            console.log("error ======>", error.response.data);
            const data = error.response.data || {}
            const extraData = data.extra_data
            if(extraData) {
              if(extraData.subject_group_id){
                setSubjectParams(extraData)
                message.error(mrIntl("DuplicateExperienceButton.the_subject_for_this_assessment_is_not_currently_enabled_for"))
              } else if(extraData.org_programme_id) {
                message.error(mrIntl("DuplicateExperienceButton.the_grade_for_this_assessment_is_not_currently_enabled_for"))
                setGradeParams(extraData)
              } else {
                message.error(mrIntl("DuplicateExperienceButton.error_in_duplicating_assessment"))
              }
            }
            setDupComplete(true);
          }
        }
      )
    );
    // message.success("Archived successfully");
  };
  console.log("subjectParams gradeParams ====>", subjectParams, gradeParams);
  const cancel = (e) => {
    e.stopPropagation();
    // message.error("Delete cancelled");
  };

  const performDuplicateIntoQB = (event) => {
    // event.stopPropagation()
    // deleted(item);
    setVisible(true);
    dispatch(
      experienceReduxActions.duplicate(
        { uid: sourceId, id: sourceId, destination_questionbank_id: event.key },
        {
          duplicateAtIndex: 0,
          successCallback: (data) => {
            console.log("duplicate done", data);
            setDupComplete(true);
            // setNewExperienceId(data.data.experience.data.id);
            setNewExperienceId(data.data.experience.data.attributes.uid);
          },
        }
      )
    );
  };

  // done is useEffect - useSelector to get QBs from generic redux

  const copyToQBBtnMenu = (
    <Menu onClick={(e) => performDuplicateIntoQB(e)}>
      {copyToQBBtnMenuItems}
    </Menu>
  );

  const qBDuplicateBtnRender = (
    <React.Fragment>
      <Tooltip title={mrIntl("DuplicateExperienceButton.copy_to_qb")} placement="top">
        <Dropdown overlay={copyToQBBtnMenu} trigger={[dropDownTrigger]}>
          {/* <Dropdown overlay={menu} disabled={org.migration_complete || loading } > */}
          <Button>
            <PlusOutlined /> <MrTranslate id={"DuplicateExperienceButton.qb"}/>
          </Button>
        </Dropdown>
      </Tooltip>
      <Modal
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        // footer={dupComplete ? footerComplete : false}
        footer={false}
      >
        {dupComplete ? contentComplete : contentInProgress}
        {/* {newExperienceId} */}
      </Modal>
    </React.Fragment>
  );

  const regularDuplicateBtnRender = (
    <React.Fragment>
      {isLibrary && isAdminOrTeacher ? (
        <Tooltip
          title={mrIntl("DuplicateExperienceButton.add_to_my_assessments")}
          placement="left"
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <Button
            type={withText ? "primary" : "text"}
            onClick={(event) => {
              event.stopPropagation()
              confirm(event);
            }}
            shape={withText ? "round" : "circle"}
            icon={<PlusOutlined />}
          >
            {withText ? mrIntl("DuplicateExperienceButton.use_btn") : ""}
          </Button>
        </Tooltip>
      ) : (
        <Popconfirm
          key="duplicate"
          title={mrIntl("DuplicateExperienceButton.confirm_duplicate")}
          placement={popconfirmPlacement}
          zIndex="10000"
          onConfirm={confirm}
          onCancel={cancel}
          okText={mrIntl("CommonText.yes")}
          cancelText={mrIntl("CommonText.no")}
          // getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          <Tooltip
            title={
              withText ? "" : mrIntl("DuplicateExperienceButton.duplicate")
            }
            placement="left"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            <Button
              className="assessment-copy full-width-action-button"
              type="text"
              onClick={(event) => {
                event.stopPropagation();
              }}
              
              shape={withText ? "round" : "circle"}
              icon={<CopyOutlined />}
            >
              {withText ? withText : ""}
            </Button>
          </Tooltip>
        </Popconfirm>
      )}
      {/* <Tooltip title="Duplicate">
      <Button shape="circle" icon={<CopyOutlined />}  onClick={(e) => {e.stopPropagation(); setVisible(true); props.duplicateResource({id: sourceId}, {duplicateAtIndex: index+1, successCallback: (data) => {console.log("duplicate done", data); setDupComplete(true); setNewExperienceId(data.data.experience.data.id)}})}}>
      </Button>
      </Tooltip> */}
      <Modal
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        // footer={dupComplete ? footerComplete : false}
        footer={false}
        centered={true}
        className="duplicate-modal"
      >
        {dupComplete ? (!isEmpty(subjectParams) ||  !isEmpty(gradeParams)) ? <GradeOrSubjectNotExist subjectParams={subjectParams} gradeParams={gradeParams} confirm={confirm} currentData={currentData} setCurrentData={setCurrentData} /> : contentComplete : contentInProgress}
        {/* {newExperienceId} */}
      </Modal>
    </React.Fragment>
  );

  if (
    !item.questionbank &&
    currentUser.role === "qb_author" &&
    currentUser.custom_fields.can_view_all_experiences
  ) {
    return qBDuplicateBtnRender;
  } else {
    return regularDuplicateBtnRender;
  }
};

export default DuplicateExperienceButton;
