import React, { useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import UploadList from 'antd/es/upload/UploadList/ListItem';
import { Upload } from "antd";
import "./ThumbnailAttachments.scss";

const ThumbnailAttachments = (props) => {
  const { attachments = [], listProps = {}, droppableProps = {}, draggableProps = {}, onDragEnd, componentAfterThumbnails } = props

  return (
    <div className={listProps.showRemoveIcon ? 'thumbnail-attachments' : 'thumbnail-attachments preview-center' }>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableProps.droppableId || 'droppable'} {...droppableProps} >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            // style={snapshot.isDraggingOver ? {
            //   display: 'flex',
            //   overflow: 'auto',
            // } : {
            //     display: 'flex',
            //     flexWrap: 'wrap',
            //   }}
            // style={{
            //   display: 'flex',
            //   overflow: 'auto',
            // }}
            >
              {/* {attachments.map((atc, index) => (
                <Draggable key={atc.uid} draggableId={atc.uid} index={index} {...draggableProps}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        display: 'inline-block',
                      }}
                    >
                      <Upload
                        {...listProps}
                        locale={{ previewFile: 'Preview', removeFile: 'Remove' }}
                        fileList={[atc]}
                        showUploadList={{
                          showRemoveIcon: listProps.showRemoveIcon
                        }}
                        // items={[atc]}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {componentAfterThumbnails} */}
              <Upload
                {...listProps}
                locale={{ previewFile: 'Preview', removeFile: 'Remove' }}
                fileList={attachments}
                showUploadList={{
                  showRemoveIcon: listProps.showRemoveIcon
                }}
                itemRender={(originalNode, atc) => {
                  const index = attachments.findIndex((a) => a.uid === atc.uid)
                  console.log("index =====>", index);
                  return (
                    <Draggable key={atc.uid} draggableId={atc.uid} index={index} {...draggableProps}>
                    {(provided, snapshot) => (
                      <div
                        className="thumbnail-preview"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                          onClick={() => {
                            // Handle the case if we have only the preview icon (we are not showing the remove icon). We want to give the user the ability to preview the image on click anywhere on the Image.
                            if (!listProps.onRemove) {
                              listProps.onPreview(atc)
                            }
                          }}
                      >
                        {originalNode}
                      </div>
                    )}
                  </Draggable>
                  )
                }}
              >
                {componentAfterThumbnails}
              </Upload>
              {provided.placeholder} 
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default ThumbnailAttachments
