import axios from "axios";
import { getAIServiceBaseURL, getPermissions, updateConfigHeaderForDemoUseStagingAI } from "/src/lib/utils/helperMethods";
import { message } from "/src/components/UI/AntdAppHelper";
import { debounce } from "lodash";
import loadable from "@loadable/component";
import { showInfoModal } from "../UI/Segment/UIHelper";
import * as Sentry from "@sentry/react";

const speechsdkLib = loadable.lib(() =>
  import(
    /* webpackChunkName: "speechsdk", webpackPrefetch: true */ "microsoft-cognitiveservices-speech-sdk"
  )
);


let speechsdk = null
const mediaRecordConstraints = {
  audio: true,
};
let errorHandled = false;

const deactivateSpeechToText = () => {
  try {
    const toolbarElement = speechToText.editorInstance?.ui?.view?.toolbar?.element;
    if (!toolbarElement) throw new Error('Toolbar element not found.');

    const stopSpeechButton = toolbarElement.querySelector(".ck-speech-to-text-active-button");
    if (!stopSpeechButton) throw new Error('Stop Speech button not found in toolbar');

    stopSpeechButton.click();
  } catch (error) {
    console.error(error.message);
    Sentry.captureException(error);
  }
};

const updateStatusText = (editor, status) => {
  try {
    const editorElement = editor.sourceElement || {};
    const parentElement = editorElement.parentElement;
    
    if (!parentElement?.classList?.contains("multiline-input-area")) return;
    
    const sttMessageContainer = parentElement?.querySelector('.footer-status-title');

    if (!sttMessageContainer) return;

    if (status === 'initializing') {
      sttMessageContainer.innerHTML = `${initializingSvgIcon}&nbsp;Initializing speech to text`;
    } else if (status === 'listening') {
      sttMessageContainer.innerHTML = `Listening&nbsp;${listeningSvgIcon}`;
    } else if (status === 'listen') {
      sttMessageContainer.innerHTML = `Listening&nbsp;...`;
    }
    else {
      sttMessageContainer.innerHTML = '';
    }
  } catch (error) {
    console.error('Error updating the status text:', error);
    Sentry.captureException(error);
  }
};

const handleMicrophonePermissionError = (editor) => {
  if (editor) {
    updateStatusText(editor,'sttOff')
  }
  if (errorHandled) return;

  errorHandled = true;

  if (speechToText.editorInstance) {
    deactivateSpeechToText();
  }
  
  showInfoModal({
    type: "warning",
    title: "Please allow access to your microphone!",
  });

  setTimeout(() => {
    errorHandled = false;
  }, 1000);
};

const initSpeechSDK = (editor,callback) => {
  getPermissions(mediaRecordConstraints, {
    successCallback: () => {
      if (!speechsdk) {
        speechsdkLib.load().then((module) => {
          speechsdk = module;
          callback();
        });
      } else {
        callback();
      }
    },
    errorCallback: () => handleMicrophonePermissionError(editor),
  });
};

// We use SVG icons in this way because components cannot be used in the JavaScript object.

const initializingSvgIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M8.47 2.47a.75.75 0 0 1 1.06 0l2 2A.75.75 0 0 1 11 5.75H9a6.25 6.25 0 1 0 0 12.5h.5a.75.75 0 0 1 0 1.5H9a7.75 7.75 0 0 1 0-15.5h.19l-.72-.72a.75.75 0 0 1 0-1.06M13.75 5a.75.75 0 0 1 .75-.75h.5a7.75 7.75 0 0 1 0 15.5h-.19l.72.72a.75.75 0 1 1-1.06 1.06l-2-2a.75.75 0 0 1 .53-1.28h2a6.25 6.25 0 1 0 0-12.5h-.5a.75.75 0 0 1-.75-.75" clip-rule="evenodd"/></svg>`;

const listeningSvgIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M12 3.25a.75.75 0 0 1 .75.75v16a.75.75 0 0 1-1.5 0V4a.75.75 0 0 1 .75-.75m-4 3a.75.75 0 0 1 .75.75v10a.75.75 0 0 1-1.5 0V7A.75.75 0 0 1 8 6.25m8 0a.75.75 0 0 1 .75.75v10a.75.75 0 0 1-1.5 0V7a.75.75 0 0 1 .75-.75m-12 4a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75m16 0a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75" clip-rule="evenodd"/></svg>`;

const toggleSpeechToText = debounce(async (editor, isSpeechActive) => {
  try {
    speechToText.updateStatusText(editor, 'initializing')
    // editor.editing.view.focus();
    speechToText.editorInstance = editor;
    const editableElement = editor.ui.view.editable.element;

    initSpeechSDK(editor, async () => {

      if (isSpeechActive) {
        // Set flag when stt toolbar button is clicked
        const toolbar = editor.ui.view.toolbar.element;
        const sttButton = toolbar ? toolbar.querySelector('button[id="speech_to_text_button"]') : null;
        let isSTTButtonClicked = false;
        const handleSTTButtonClick = () => {
          isSTTButtonClicked = true;
        };
        sttButton.addEventListener('mousedown', handleSTTButtonClick);

        // Blur event handler
        const handleBlur = (event) => {
          if (isSTTButtonClicked) {
            event.stopPropagation();
            event.preventDefault();
            isSTTButtonClicked = false;
          } else {
            speechToText.handleEditorBlur(editor);
          }
        };

        // Add blur listener
        console.log("toggleSpeechToText editor =========> blurListener",speechToText.blurListener,handleBlur);
        if (speechToText.blurListener) {
          editableElement.removeEventListener('blur', speechToText.blurListener);
        }
        speechToText.blurListener = handleBlur;
        editableElement.addEventListener('blur', speechToText.blurListener);

        // Start recognition
        await speechToText.startRecognition(editor);
      } else {
        // Remove blur listener and stop recognition if not active
        if (speechToText.blurListener) {
          console.log("toggleSpeechToText editor =========> blurListener removeEventListener",speechToText.blurListener);
          editableElement.removeEventListener('blur', speechToText.blurListener);
          speechToText.blurListener = null;
        }
        speechToText.stopCurrentRecognizer();
        speechToText.updateStatusText(editor, 'sttOff');
      }
    });
  } catch (error) {
    console.error('Error in toggleSpeechToText:', error);
    Sentry.captureException(error);
    message.error('Something went wrong, Please try again');
  }
}, 100);

const handleEditorBlur = (editor) => {
  // If the recognizer is active, stop it automatically on blur (outside click)
  if (speechToText.recognizer) {
    speechToText.stopCurrentRecognizer(() => {
      speechToText.updateStatusText(editor, 'sttOff');
      speechToText.deactivateSpeechToText();
    });
  }
};

const stopCurrentRecognizer = (callback) => {
  if (speechToText.recognizer) {
    speechToText.recognizer.stopContinuousRecognitionAsync(
      () => {
        speechToText.recognizer = null;
        if (callback) callback();
      },
      (err) => {
        console.error('Error stopping recognition:', err);
        message.error('An error occurred while stopping the recognition. Please try again.');
        if (callback) callback();
      }
    );
  } else {
    if (callback) callback();
  }
};

const startRecognition = async (editor) => {
  try {
    if (speechToText.recognizer) {
      speechToText.stopCurrentRecognizer();
      speechToText.updateStatusText(editor, 'sttOff');
      speechToText.deactivateSpeechToText();
      return;
    }

    const tokenObj = await fetchValidSTTToken();
    if (!tokenObj.authToken) {
      throw new Error('Authorization token is null or undefined.');
    }

    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
    speechConfig.speechRecognitionLanguage = 'en-US';

    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();

    const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

    startContinuousRecognition(recognizer, editor);
  } catch (error) {
    console.error('Error:', error);
    message.error('An error occurred while starting speech recognition. Please try again.');
  }
};

const startContinuousRecognition = (recognizer, editor) => {
  speechToText.updateStatusText(editor, 'listen');
  recognizer.recognizing = (s, e) => {
    console.log(`RECOGNIZING: Text=${e.result.text}`);
    speechToText.updateStatusText(editor, 'listening');
  };

  recognizer.recognized = (s, e) => {
    if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
      editor.model.change(writer => {
        speechToText.updateStatusText(editor, 'listen');
        const selection = editor.model.document.selection;
        const insertPosition = selection.getFirstPosition();
        writer.insertText(e.result.text, insertPosition);

        const newInsertPosition = insertPosition.getShiftedBy(e.result.text.length);
        writer.insertText(' ', newInsertPosition);
        writer.setSelection(newInsertPosition.getShiftedBy(1));
        // editor.editing.view.focus();
      });
    } else if (e.result.reason === speechsdk.ResultReason.NoMatch) {
      console.log('NOMATCH: Speech could not be recognized.');
    }
  };

  recognizer.canceled = (s, e) => {
    if (e.reason === speechsdk.CancellationReason.Error) {
      message.error(`Speech recognition was canceled due to an error: ${e.errorDetails}. Please try again.`);
    }
    speechToText.stopCurrentRecognizer();
    speechToText.updateStatusText(editor, 'sttOff');
  };

  recognizer.sessionStopped = (s, e) => {
    speechToText.stopCurrentRecognizer();
    speechToText.updateStatusText(editor, 'sttOff');
  };

  recognizer.startContinuousRecognitionAsync(
    () => {
      editor.editing.view.focus();
      speechToText.recognizer = recognizer;
    },
    (err) => {
      console.error('Error starting recognition:', err);
      message.error(err);
      speechToText.deactivateSpeechToText();
      speechToText.updateStatusText(editor, 'sttOff');
    }
  );
};

const fetchValidSTTToken = async () => {
  const storedSpeechToken = localStorage.getItem("stt_token");
  const tokenExpirationTime = localStorage.getItem('stt_token_expiration');
  const currentTime = new Date().getTime();
  const speechToTextAPIEndpoint = `${getAIServiceBaseURL()}/stt/token`;
  // Check if token exists and is not expired
  if (storedSpeechToken && tokenExpirationTime && currentTime < parseInt(tokenExpirationTime, 10)) {
    return { authToken: storedSpeechToken, region: 'westeurope' };
  }

  try {
    let config = {
      method: "POST",
      url: speechToTextAPIEndpoint,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
      },
    };

    config = updateConfigHeaderForDemoUseStagingAI(config);
    const response = await axios.request(config);

    if (response.status === 200) {
      const newToken = response.data.token;
      const tokenValidityDuration = 540 * 1000; // Token expiration time in milliseconds (9 minutes)
      const newExpirationTime = currentTime + tokenValidityDuration;

      localStorage.setItem('stt_token', newToken);
      localStorage.setItem('stt_token_expiration', newExpirationTime.toString());

      // Schedule token refresh
      setTimeout(fetchValidSTTToken, tokenValidityDuration);

      return { authToken: newToken, region: 'westeurope' };
    } else {
      console.error('Unexpected response status:', response.status);
      return { authToken: null, error: 'Unexpected response status' };
    }
  } catch (err) {
    console.error('Error fetching token:', err.response ? err.response.data : err.message);
    return { authToken: null, error: err.response ? err.response.data : err.message };
  }
}

export const speechToText = {
  recognizer: null,
  editorInstance: null,
  blurListener: null,
  toggleSpeechToText,
  handleEditorBlur,
  stopCurrentRecognizer,
  startRecognition,
  startContinuousRecognition,
  deactivateSpeechToText,
  updateStatusText
};