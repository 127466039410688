import React, { useEffect, useState } from "react";
import { Button, Modal, Space, Steps } from "antd";
import { LoadingOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import { actions as experiencesActions } from "/src/views/Experiences/redux";
import { useDispatch, useSelector } from "react-redux";
import SelectFromComputer from "/src/components/UI/Media/SelectFromComputer";
import { message } from "/src/components/UI/AntdAppHelper";
import { usePollingEffect } from "../../Segments/InteractiveHelpers";
import { checkMob } from "/src/lib/utils/helperMethods";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { formatJsonApiData } from "mr_react_framework";
import { activeAdjustedExperienceSelector } from "../../Experiences/selector";
import { axiosInstance } from "/src/api/apiModule";

const IMAGE_MAPPING_COMPLETED = "image_mapping_to_questions_completed";

export const getStepProps = (bulkAutoMappingProgress) => {
  const statusMapping = {
    [IMAGE_MAPPING_COMPLETED]: { current: 2, status: "finish" },
    image_mapping_to_questions_failed: { current: 2, status: "error" },
    image_mapping_to_questions_in_progress: { current: 2, status: "process" },
    image_mapping_to_students_has_completed: { current: 1, status: "process" },
    image_mapping_to_students_has_failed: { current: 1, status: "error" },
    image_mapping_to_students_in_progress: { current: 1, status: "process" },
    image_mapping_to_students_has_started: { current: 1, status: "process" },
    images_processing_completed: { current: 0, status: "process" },
    images_processing_failed: { current: 0, status: "error" },
    images_processing_started: { current: 0, status: "process" },
    cancelled: { current: 0 , status: "error" },
  };

  return statusMapping[bulkAutoMappingProgress?.status] || {};
};

const useAutoMappingProgress = (experienceId, shouldPoll, onSuccessCallback) => {
  console.log("useAutoMappingProgress ===>", {experienceId, shouldPoll});
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const checkAutomappingProgress = (clearIntervalCallback) => {

    dispatch(
      experiencesActions.bulkAutoMappingProgress(
        {
          experience_id: experienceId
        },
        {
          successCallback: (data) => {
            const autoMappingStatus = data.bulk_auto_mapping_progress
            onSuccessCallback?.(autoMappingStatus)

            let progressCompleted = (autoMappingStatus.status === IMAGE_MAPPING_COMPLETED) || autoMappingStatus.status.includes("failed")

            if(progressCompleted) {
              clearIntervalCallback();
            }
          },
          errorCallback: () => {
            clearIntervalCallback();
            message.error(mrIntl("BulkUploadAnswerSheets.bulk_uploading_failed"));
          },
        }
      )
    );
  };

  usePollingEffect(shouldPoll, checkAutomappingProgress, 30000);
};

const BulkUploadAnswerSheets = () => {
  const dispatch = useDispatch();
  const mrIntl = useTranslate();
  const experience = useSelector(activeAdjustedExperienceSelector())
  const experienceId = experience.id
  const bulkAutoMappingProgress = experience.custom_fields?.bulk_auto_mapping_progress || {}
  
  let mediaFormInitialState = {
    modal: false,
    loading: false,
    visible: false,
    from: "computer",
    title: mrIntl("BulkUploadAnswerSheets.upload_file"),
    okText: mrIntl("CommonText.submit"),
    cancelText: mrIntl("CommonText.cancel"),
    limit: 1,
    content_type: "image",
    supportedTypes: "application/zip, application/x-zip-compressed",
    supportedFormats: "zip",
    requireFooter: true,
    okDisabled: true,
    selectConfig: {
      multiple: false,
      maxSize: "200 MB",
    },
  };

  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );
  const [showProgressSteps, setShowProgressSteps] = useState(false)
  const [stepProps, setStepProps] = useState({})
  
  const mappingUnderProgress = stepProps?.status === "process";
  const shouldPoll =  mappingUnderProgress && mediaFormSetting.visible;

  const updateExperienceCustomFields = (bulkAutoMappingProgress) => {
    if (bulkAutoMappingProgress) {
      let updatedExperience = {
        ...experience,
        custom_fields: {
          ...experience.custom_fields,
          bulk_auto_mapping_progress: bulkAutoMappingProgress
        }
      }
      updateStepsConfig(bulkAutoMappingProgress)
      if (bulkAutoMappingProgress.status === IMAGE_MAPPING_COMPLETED) {
        dispatch(
          experiencesActions.show(
            { id: experience.uid },
            { forceApiRequest: true }
          )
        ); 
      } else {
        dispatch(experiencesActions.updateSuccess({data: {experience: updatedExperience }}, {success: {showMessage: false}}))
      }
    }
  }
  
  useEffect(() => {
    updateStepsConfig(bulkAutoMappingProgress)
    return () => {
      setMediaFormSetting(mediaFormInitialState)
    }
  },[])


  useAutoMappingProgress(experienceId, shouldPoll, updateExperienceCustomFields)


  const onSaveMedia = (files) => {
    console.log("files onsavemedia ==>", files);
    if (files.length === 0) {
      message.error(mrIntl("CommonText.at_least_one_file_required"));
      return
    }
    dispatch(
      experienceUsersActions.bulkUploadAnswerSheets({
        experienceId: experienceId,
        filesToUpload: files[0],
      }, 
      {
        successCallback: (data) => {
          console.log("bulkuploadsucccesscallbalk data ===>", data);
          let formattedExperience = formatJsonApiData(data.experience)
          let bulkAutoMappingProgress = formattedExperience.custom_fields.bulk_auto_mapping_progress;

          let updatedExperience = {
            ...experience,
            custom_fields: {
              ...experience.custom_fields,
              bulk_auto_mapping_progress: bulkAutoMappingProgress
            }
          }
          updateStepsConfig(bulkAutoMappingProgress)
          setMediaFormSetting((prev) => ({
            ...prev,
            visible: false,
          }))
          dispatch(experiencesActions.updateSuccess({ data: { experience: updatedExperience } }, { success: { showMessage: false } }));
          message.success(mrIntl("BulkUploadAnswerSheets.file_uploaded_successfully"))
        },
        errorCallback: () => {
          message.error(mrIntl("CommonText.something_went_wrong_please_try_again"))
        }
      },
    )
    );
  };

  const cancelBulkUpload = () => {
    const url = `${import.meta.env.VITE_API_URL}cancel_bulk_auto_mapping`;
    const params = {
      experience_id: experience.id,
    };
    axiosInstance.instance.post(url, params)
      .then(response => {
        console.log('Cancel bulk upload response:', response);
        if(response.data) {
          let newBulkUploadAutomappingProgress = response.data.experience?.custom_fields?.bulk_auto_mapping_progress;
          let updatedExperience = {
            ...experience,
            custom_fields: {
              ...experience.custom_fields,
              bulk_auto_mapping_progress: newBulkUploadAutomappingProgress
            }
          }
          message.success(mrIntl("BulkUploadAnswerSheets.bulk_upload_has_been_successfully_cancelled"));
          updateStepsConfig(newBulkUploadAutomappingProgress);
          dispatch(experiencesActions.updateSuccess({data: {experience: updatedExperience }}, {success: {showMessage: false}}));
        }
      })
      .catch(error => {
        console.error('Failed to cancel the bulk upload process:', error);
        message.error(mrIntl("BulkUploadAnswerSheets.unable_to_cancel_the_bulk_upload_answer_sheets_please_try"));
      });
  };


  const updateStepsConfig = (bulkAutoMappingProgress) => {
    if (bulkAutoMappingProgress?.status) {
      setShowProgressSteps(true)
    }
    setStepProps(getStepProps(bulkAutoMappingProgress))
  }

  return (
    <>
      <Button
        type="text"
        icon={<PlusCircleTwoTone twoToneColor="#52c41a" />}
        onClick={() =>
          setMediaFormSetting((prev) => ({
            ...prev,
            visible: true,
          }))
        }
      >
        {mrIntl("BulkUploadAnswerSheets.bulk_upload_answer_sheets")}
      </Button>
      {mediaFormSetting.visible && (
        <Modal
          open={true}
          title={mediaFormSetting.title}
          width={checkMob() ? "" : "50%"}
          className="bulk-upload-answer-sheets-modal"
          centered={checkMob() ? true : false}
          closable={showProgressSteps}
          footer={null}
          onCancel={() => {
            setMediaFormSetting(mediaFormInitialState);
          }}
        >
          <>
            {showProgressSteps ? (
              <>
                <Steps
                  {...stepProps}
                  className="m-t-10 m-b-10"
                  direction="vertical"
                  items={[
                    {
                      title: "Processing files",
                      subTitle: stepProps.current === 0 && mappingUnderProgress ? <LoadingOutlined /> : null,
                      description: stepProps.current === 0 && (
                        <span className="color-bbb">
                          {bulkAutoMappingProgress.message}
                        </span>
                      ),
                    },
                    {
                      title: "Auto mapping images to students",
                      subTitle: stepProps.current === 1 && mappingUnderProgress ? <LoadingOutlined /> : null,
                      description: stepProps.current === 1 && (
                        <span className="color-bbb">
                          {bulkAutoMappingProgress.message}
                        </span>
                      ),
                    },
                    {
                      title: "Auto mapping images to questions",
                      subTitle: stepProps.current === 2 && mappingUnderProgress ? <LoadingOutlined /> : null,
                      description: stepProps.current === 2 && (
                        <span className="color-bbb">
                          {bulkAutoMappingProgress.message}
                        </span>
                      ),
                    },
                  ]}
                />
                <Space>
                  <Button
                    disabled={mappingUnderProgress}
                    onClick={() => {
                      setMediaFormSetting({
                        ...mediaFormInitialState,
                        visible: true,
                        okDisabled: true,
                      });
                      setShowProgressSteps(false);
                    }}
                  >
                    {mrIntl("BulkUploadAnswerSheets.upload_more")}
                  </Button>
                  {mappingUnderProgress && (
                    <Button
                      danger
                      onClick={() => cancelBulkUpload()}
                    >
                      {mrIntl("CommonText.cancel")}
                    </Button>
                  )}
                </Space>
              </>
            ) : (
              <SelectFromComputer
                modal={mediaFormSetting.modal}
                mediaFormSetting={mediaFormSetting}
                setMediaFormSetting={(data) => setMediaFormSetting(data)}
                onSaveMedia={onSaveMedia}
              />
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default BulkUploadAnswerSheets;