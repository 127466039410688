import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import Spinner from "/src/components/UI/Spinner/Spinner"
import { appTypeSelector, changeSecurityLoadingSelector, changeSecurityStatusSelector } from "/src/App/OfflineApp/offlineAppSelectors";
import { Alert, Result } from "antd";
import BackToHomeBtn from "./BackToHomeBtn";
import QuitBtn from "./QuitBtn";
import LogoutBtn from "./LogoutBtn";
import { checkNativeMethodSupported } from "/src/App/OfflineApp/offlineAppHelper";
import { actions as offlineAppActions } from "/src/App/OfflineApp/offlineAppRedux";
import { message } from "./AntdAppHelper";

const QuitRequested = (props) => {
  console.log("QuitRequested props ==>", props); 
  const { experience } = props;

  const mrIntl = useTranslate();
  // const history = useHistory();
  const dispatch = useDispatch();
  const appType = useSelector(appTypeSelector());

  const changeSecurityLoading = useSelector(changeSecurityLoadingSelector());
  const changeSecurityStatus = useSelector(changeSecurityStatusSelector());
  // const [quitStatus, setQuitStatus] = useState("loading")

  let subTitle = []
  let extra = []

  if (changeSecurityLoading) {
    subTitle.push(<Spinner />)
  } else {
    subTitle.push(
      <Alert
        style={{ marginTop: "20px" }}
        message= {
          changeSecurityStatus === "false"
            ? mrIntl("QuitRequested.success_message") 
            : mrIntl("QuitRequested.error_message")
        }
        type={changeSecurityStatus === "false" ? "success" : "error"}
        showIcon
      />
    )
    extra.push(<BackToHomeBtn key="quit-requested-back-to-home-button" experience={experience}/>)
  }

  extra.push(<LogoutBtn key="quit-requested-logout-button" experience={experience} />);
  
  if (!changeSecurityLoading && changeSecurityStatus !== "false") {
    extra.push(<QuitBtn />)
  }

  useEffect(() => {
    // In case of refresh want to check security status and show message accordingly
    if (checkNativeMethodSupported(appType, "getSecurityStatus") && !changeSecurityStatus) {
      dispatch(
        offlineAppActions.executeMethod([{
          key: "getSecurityStatus",
          value: "status",
        }])
      )
    }
  }, [appType])

  // const onError = () => {
  //   setQuitStatus("error")
  //   // redirectToHome()
  // }

  // const onSuccess = () => {
  //   // dispatch(offlineAppActions.quitrequestedSuccess({ quitRequested: false }));
  //   // redirectToHome()
  //   setQuitStatus("success")
  // }

  // const redirectToHome = () => {
  //   history.replace("/")
  //   // openUrl("/") // IMP: openUrl not working offline - gives native error
  // }
  
  // useEffect(() => {
  //   if (quitStatus === "loading") {
  //     checkAndChangeSecurity(dispatch, {...props, appType: appType, changeSecurityTo: false, onSuccess, onError, mrIntl});
  //   }
  // }, [appType, quitStatus]);


  return (
    <React.Fragment>
      <Result
        className="quit-requested-result"
        icon={changeSecurityLoading ? mrIntl("CommonText.ending_session") : null}
        status={!changeSecurityLoading && changeSecurityStatus === "false" ? "success" : "error"} 
        title={experience.name}
        subTitle={subTitle}
        extra={extra}
      />
    </React.Fragment>
  );
};

export default QuitRequested;
