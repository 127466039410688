import React, { useState, useEffect } from "react";
import { Row, Tag, Space, Menu, Col, Alert, Card, App } from "antd";
import { message } from '/src/components/UI/AntdAppHelper';
import { FormRenderer } from "mr_react_framework";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckOutlined,
  MinusOutlined,
  CloseOutlined,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import dayjs from "dayjs";
import ExperienceMemberships from "/src/views/Experiences/ExperienceShow/ExperienceManage/ExperienceDeliver/ExperienceMemberships";
import { checkMob, getUniqueId } from "/src/lib/utils/helperMethods";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { actions as firestoreInteractionActions } from "/src/views/Experiences/ExperienceShow/FirestoreInteractions/redux";
import { currentUserSelector } from "/src/views/Auth/Login/selector";
import { isEqual, upperFirst } from "lodash";
import { useForm } from "antd/lib/form/Form";
import { assignStudentsFormObj, returnSettingsForm, settingsFormObj } from "/src/views/Experiences/Experiences";
import { activeAdjustedExperienceIdSelector, activeAdjustedExperienceSelector } from "/src/views/Experiences/selector";

export const LeftSideFormChildComponent = (props) => {
  console.log("LeftSideFormChildComponent props ==>", props)
  const {
    sourceValues,
    options, 
    formInstance
  } = props;

  // const {} = options.mainProps || {}

  const mrIntl = useTranslate();

  const isMobile = checkMob();

  let finalRender = (
    <Col span={24} offset={1}>
      <Card className="experience-deliver-settings-card" title={mrIntl("ExperienceDeliver.settings")}>
        <Row className="settings-tab">
          <FormRenderer
            formObj={settingsFormObj(props, mrIntl)}
            propertyKey={"settings"}
            sourceValues={sourceValues}
            formInstance={formInstance}
          />
        </Row>
      </Card>
    </Col>
  );

  return finalRender;
}


export const RightSideFormChildComponent = (props) => {
  console.log("RightSideFormChildComponent props ==>", props)
  const {
    sourceValues,
    formInstance,
    options, 
    getFieldValue
  } = props;

  // const {} = options.mainProps || {}

  const experience = useSelector(activeAdjustedExperienceSelector())

  const mrIntl = useTranslate();
  const mapClassesOrStudents = getFieldValue(["settings", "map_classes_or_students"])
  const possibleStudents =
    (mapClassesOrStudents == "groups"
    ? experience.group_students
    : experience.students) || []
  const allowGuests = getFieldValue(["settings", "allow_guests"])
  const allowUnmappedStudents = getFieldValue(["settings", "allow_unmapped_students"])

  // const resourceForForm = {
  //   uid: experience.uid,
  //   experience_memberships_attributes: experience.settings.map_classess_or_students === "groups" ? experience.experience_memberships_groups : experience.experience_memberships_students
  // }

  // const experienceMembershipForm = renderForm({
  //   name: "experiencesSettings",
  //   config: {
  //     isModal: false,
  //     visible: true,
  //     submitConfig: {
  //       successCallback: (data) => {},
  //     },
  //     actions: {
  //       showSubmitBtn: false,
  //       showSavedMsg: true,
  //       autoSaveConfig: {
  //         shouldAutoSave: true,
  //         debounce: 100,
  //         successMsg: mrIntl("CommonText.assessment_saved_successfully"),
  //         errorMsg: mrIntl("CommonText.error_in_saving"),
  //         hideMessage: true,
  //         hideMessageTimeout: 30000,
  //       }
  //     },
  //   },    
  //   resource: { ...resourceForForm, id: experience.uid },
  // })

  let alertForMappedAndUnmapped
  let mappedStudentsExist = possibleStudents.length != 0

  if (allowGuests && (allowUnmappedStudents || allowUnmappedStudents === undefined)) {
    alertForMappedAndUnmapped = mrIntl("ExperienceDeliver.all_students_able_to_take_test")
  } else if (!allowGuests && (allowUnmappedStudents || allowUnmappedStudents === undefined)) {
    alertForMappedAndUnmapped = mrIntl("ExperienceDeliver.only_mapped_and_unmapped_students_able_take_test")
  } else if (mappedStudentsExist && !allowGuests && allowUnmappedStudents === false) {
    alertForMappedAndUnmapped = mrIntl("ExperienceDeliver.only_mapped_students_able_take_test")
  } else if (!mappedStudentsExist && !allowGuests && allowUnmappedStudents === false) {
    alertForMappedAndUnmapped = mrIntl("ExperienceDeliver.no_students_are_currently_mapped_to_this_test_please_assign")
  }

  let finalRender = (
    <Col span={24} offset={1}>
    {/* <Col span={isPamoja ? 0 : 11} offset={1}>  */}
      <Card
        className="experience-deliver-settings-card"
        // title="Assign to Students"
        title= {mrIntl("ExperienceDeliver.assign_to_students")}
      >
        <div className="students-tab">
          {experience.is_mb_linked && (
            <div className="m-b-15">
              <Alert
              message= {mrIntl("ExperienceDeliver.deliver_settings_info_message")}
                // message="This assessment is linked to a ManageBac task. The students who are mapped to take this assessment are determined by the students selected for this task on ManageBac. If you have made a change on ManageBac, it can take a few seconds to reflect here."
                type="info"
                showIcon
              />
              {/* <Tag>
                {experience.custom_fields.sync_mb_task_memberships_status && experience.custom_fields.sync_mb_task_memberships_status.toString()}
                ---
                {experience.custom_fields.sync_mb_task_memberships_details} 
              </Tag> */}
            </div>
          )}
          <div className="m-b-15">
            {alertForMappedAndUnmapped && <Alert
              message={alertForMappedAndUnmapped}
              type="warning"
              showIcon
            />}
          </div>
          <Row className="right-side-form">
            <FormRenderer
              formObj={assignStudentsFormObj(props, mrIntl)}
              propertyKey={"settings"}
              sourceValues={sourceValues}
              formInstance={formInstance}
              // item={experience}
            />

            <Col span={24} offset={0}>
              {/* Note: Custom component here because experience_memberships_attributes is not nested of settings */}
              <ExperienceMemberships
                {...props}
              />
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );

  return finalRender;
}
const ExperienceDeliver = (props) => {
  console.log('ExperienceDeliver props ==>', props);
  const { experienceProps } = props;
  const { experience, renderForm } = experienceProps;
  // const { message } = App.useApp()
  
  const experienceSettings = experience.settings || {};
  const experienceMembershipsGroups = experience.experience_memberships_groups || [];
  const experienceMembershipsStudents = experience.experience_memberships_students || [];
  // Note: Merging both groups and students memberships so that we can iterate them for archiving if map_classess_or_students switched
  const experienceMembershipsAttributes = [...experienceMembershipsGroups, ...experienceMembershipsStudents]
  // const experienceMembershipsAttributes = experienceSettings.map_classess_or_students === "groups" ? experience.experience_memberships_groups : experience.experience_memberships_students

  const mrIntl = useTranslate();
  
  const latestFormRenderFieldsData = {
    status: experience.status,
    // Note: If we rerender form for experience_memberships_attributes then Select drop down gets close on every onChange
    // experience_memberships_attributes: experienceMembershipsAttributes
  }
  const [prevFormRenderFieldsData, setPrevFormRenderFieldsData] = useState(latestFormRenderFieldsData)
  // const [allowPublishValidation, setAllowPublishValidation] = useState(null);
  
  // Note: Sending required attributes as we don't want to submit old values of property changed outside of form
  const resourceForForm = {
    uid: experience.uid,
    status: experience.status,
    id: experience.id,
    access_code: experience.access_code,
    is_mb_linked: experience.is_mb_linked,
    settings: experienceSettings,
    experience_memberships_attributes: experienceMembershipsAttributes,
    reviewers_assigned_at: experience.custom_fields["reviewers_assigned_at"]
  }

  console.log("resourceForForm ==>", resourceForForm)
  const experienceSettingsForm = renderForm({
    name: "experiencesSettings",
    config: {
      isModal: false,
      visible: true,
      submitConfig: {
        successCallback: (data) => {
          message.success(mrIntl("ExperienceDeliver.delivery_settings_saved_success"))
        },
        errorCallback: () => {
          message.error(mrIntl("CommonText.error_in_saving"))
        }
      },
      actions: {
        showSubmitBtn: false,
        showSavedMsg: false,
        autoSaveConfig: {
          shouldAutoSave: true,
          debounce: 100,
          successMsg: mrIntl("CommonText.assessment_saved_successfully"),
          errorMsg: mrIntl("CommonText.error_in_saving"),
          hideMessage: true,
          hideMessageTimeout: 30000,
        }
      },
    },    
    resource: { ...resourceForForm, id: experience.uid },
  })

  useEffect(() => {
    setPrevFormRenderFieldsData({
      status: experience.status,
      // experience_memberships_attributes: experienceMembershipsAttributes
    })
  }, [experience.status])


  console.log("prevFormRenderFieldsData, latestFormRenderFieldsData", prevFormRenderFieldsData, latestFormRenderFieldsData)
  // Note: to rerender form to get updated values of props changed outside of form
  if (!isEqual(prevFormRenderFieldsData, latestFormRenderFieldsData)) {
    return null
  }

  
  // const doNotRerenderOnFormFields = [
  //   "extra_instructions_text",
  //   "start_condition",
  //   "start_at_datetime",
  //   "with_join_code",
  //   "is_timed",
  //   "duration",
  //   "allow_student_upload_files",
  //   "calc_enabled",
  //   "spellcheck_enabled",
  //   "anonymous_grading_enabled",
  //   "enable_ap_video_monitoring",
  //   "include_video_call_link",
  //   "video_call_link",
  //   "include_extra_instructions",
  //   "extra_instructions_text",
  //   "enable_auto_screenshots",
  //   "chatgpt_enabled"
  // ]

  // if ((experienceSettings != currentExpSettings) || (experience.status != currentExpStatus)) {
  //   let rerender = true
  //   doNotRerenderOnFormFields.forEach(field => {
  //     // we don't rerender from when extra_instructions_text change if we rerender if CKE focus removed
  //     if(experienceSettings[field] !== currentExpSettings[field] ) {
  //       console.log("experienceSettings[field]", field, experienceSettings[field], currentExpSettings[field]);
  //       rerender = false
  //     }
  //   });
  //   if(rerender) {
  //     return null
  //   }
  // }

  const isMobile = checkMob();

  console.log("experienceSettingsForm =====>", experienceSettingsForm);

  let finalRender = (
    <React.Fragment>
      <Row>
        <Col
          span={isMobile ? 22 : 18}
          offset={isMobile ? 1 : 3}
          style={{ "margin-bottom": "20px" }}
        >
          {experience.status == "published" && (
            <Alert
              message={mrIntl("ExperienceDeliver.alert_message_warning_draft")}
              type="success"
              showIcon
            />
          )}

          {/* <Alert message="Please note that you have not mapped any students to take this assessment. Students will have to find this assessment using the Join Code." type="warning" showIcon /> */}

          {experience.status == "draft" && (
            <Alert
            message = {mrIntl("ExperienceDeliver.choose_the_appropriate_settings_select_students_who_should_take_this_assessment_and_then_publish_this_assessment")}
              // message="Choose the appropriate settings, select students who should take this assessment and then publish this assessment"
              type="warning"
              showIcon
            />
          )}

          {/* {experience.status == "draft"  && 
            // allowPublishValidation == false && 
          (
            <Alert
              message={ mrIntl("ExperienceDeliver.alert_message_error_draft")}
              // message="Please make sure you have selected a start time for this assessment"
              type="error"
              showIcon
            />
          )} */}

          {experience.status == "closed" && (
            <Alert
              message= {mrIntl("ExperienceDeliver.alert_message_success_closed")}
              // message="This assessment has been closed."
              type="success"
              showIcon
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24} className="experience-deliver-settings">
          {experienceSettingsForm}
        </Col>
      </Row>
    </React.Fragment>
  );

  return finalRender;
};

export default ExperienceDeliver;
