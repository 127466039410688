import React, { useState } from "react";
import { Tooltip, Typography } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import { useSelector } from "react-redux";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";
import { checkMob } from "/src/lib/utils/helperMethods";
import { debounce } from "lodash";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const ExperienceTitle = (props) => {
  console.log("ExperienceTitle props ==>", props);
  const { experience = {}, updateResource, experienceViewMode } = props;
  const { Paragraph } = Typography;
  const isMobile = checkMob();
  const mrIntl = useTranslate()
  let allowEditTitle =
    experience.status === "draft" &&
    experience.master_editable &&
    experience.current_user_can_edit &&
    !isMobile
      ? true
      : false;
  const [testTitle, setTestTitle] = useState(experience && experience.name);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const maxTitleLength = isMobile ? 7 : 20;

  const updateExperienceTitle = debounce((title) => {
    setIsEditMode(false);
    console.log("experience title==>", title, title.length, !title);
    if (title) {
      if (title.length > 50) {
        message.error(mrIntl("CommonText.characters_limit_msg"));
        return false;
      }
      if (testTitle !== title) {
        updateResource(
          {
            uid: experience.uid,
            name: title,
          },
          {
            success: {
              showMessage: true,
              message: mrIntl("ExperienceTitle.title_updated"),
            },
            error: {
              showMessage: true,
              message: mrIntl("ExperienceTitle.error_in_updating_title"),
            },
            successCallback: () => {
              setTestTitle(title);
            },
          }
        );
      }
    } else {
      message.error(mrIntl("ExperienceTitle.title_cannot_be_empty"));
    }
  }, 1000);
  return (
    <>
      {experienceViewMode === "apEditTest" ? (
        <Tooltip
          title={`${testTitle} (#${experience.id})`}
          overlayStyle={{ position: "fixed" }}
          placement="bottom"
          open={!isEditMode && showTooltip}
          onOpenChange={setShowTooltip}
        >
          <Paragraph
            disabled={!allowEditTitle}
            className={`editable-title ${!testTitle ? "title-error" : ""}`}
            editable={
              allowEditTitle && {
                tooltip: "Edit",
                onChange: updateExperienceTitle,
                triggerType: ["text"],
                enterIcon: null,
                autoSize: { minRows: 1, maxRows: 1 },
                editing: isEditMode,
              }
            }
            onClick={() => {
              allowEditTitle && setIsEditMode(true);
            }}
            style={{
              minWidth: isMobile ? "50px" : "100px",
              maxWidth: isMobile ? "70px" : "150px",
              marginBottom: 0,
              whiteSpace: "nowrap",
              resize: "none",
              overflowX: "hidden",
              fontSize: "20px",
            }}
            ellipsis={true}
          >
            {testTitle}
          </Paragraph>
        </Tooltip>
      ) : experience.name.length > maxTitleLength ? (
        <Tooltip
          title={experience.name}
          placement="top"
          getPopupContainer={()=> document.querySelector(".experience-header")}
        >
          {experience.name.substring(0, maxTitleLength)}...
        </Tooltip>
      ) : (
        experience.name
      )}
    </>
  );
};
export default ExperienceTitle;
