import React from "react";
import { Col, Row, Select, Tooltip } from "antd";
import { message } from "/src/components/UI/AntdAppHelper";
import {
  convertMs,
  extraTimeValues,
  getKeysFromObject,
} from "/src/lib/utils/helperMethods";
import { showConfirmModal } from "/src/components/UI/Segment/UIHelper";
import { realtimeBatchActions } from "../FirestoreInteractions/firebaseHelper";
import {
  appRegionSelector,
  currentUserSelector,
  getUserIpSelector,
} from "/src/views/Auth/Login/selector";
import { useSelector } from "react-redux";
import { clone, isEmpty } from "lodash";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { buildLogObject } from "/src/lib/utils/auditLogsHelper";

const { Option } = Select;

const BulkAddTime = ({
  setBulkActionLoading,
  bulkActionLoading,
  selected,
  experience,
  totalSelections,
  toggleShowSelectBoxes,
  clearSelection,
  data,
  setHeaderActions,
}) => {
  const currentUser = useSelector(currentUserSelector());
  const appRegion = useSelector(appRegionSelector());
  const localSelected = clone(selected);
  const mrIntl = useTranslate()
  const userIp = useSelector(getUserIpSelector())
  console.log("BulkAddTime userIp ==>", userIp)
  // let logDocIds = {
  //   [`${currentUser.id}`]: true,
  // };

  console.log("BulkAddTime data==>", extraTimeValues, localSelected, data);

  const addTimeForSelected = (time) => {
    setBulkActionLoading(true);

    const currentTime = new Date().getTime();
    let logDocIds = [`${experience.id}_${currentUser.id}_${currentTime}`];

    const filteredData = [];
    // filtering the selected students from the data
    data.map((item, index) => {
      Object.keys(selected).forEach((innerItem) => {
        if (selected[innerItem]) {
          if (parseInt(innerItem) === parseInt(item.uid)) {
            filteredData.push(item);
          }
        }
      });
    });
    const finalData = [];
    const studentsHavingExtraTime = [];
    const studentsNotHavingExtraTime = [];
    filteredData.map((student, index) => {
      const experienceDuration = student.duration_after_reset ? student.duration_after_reset : experience.settings.duration;
      // checking if the student has already been given the extra time that is selected
      if (student.extra_time >= convertMs(time)) {
        studentsHavingExtraTime.push(student);
        delete localSelected[Number(student.uid)];
      } else {
        studentsNotHavingExtraTime.push(student);
        let objToPush = {};
        let valuesToUpdate = {};
        let docIds = {
          [student.uid]: student.name,
        };
        objToPush = {
          // experienceId: experience.id,
          basePath: `/experiences/${experience.id}/user_info`,
          docIds: docIds,
          tableName: "user_info",
          uuids: [`eId${experience.id}_uId${student.uid}`],
          options: {
            merge: true,
          },
        };
        let exTimeOffset = 0;
        if (student.currentView === "endTest") {
          let totalTestTime =
            student.started_at +
            convertMs(experienceDuration) +
            (student.extra_time || 0);

          if (student.is_time_up === true) {
            exTimeOffset = Date.now() - totalTestTime;
          } else {
            exTimeOffset = student.extra_time_offset || 0;
          }
        } else if (student.currentView === "startTest") {
          // when coming back to test after adding time and then adding time was an issue.
          exTimeOffset = student.extra_time_offset || 0;
        }
        valuesToUpdate = {
          ...valuesToUpdate,
          extra_time: convertMs(time),
          extra_time_offset: exTimeOffset,
        };
        objToPush = {
          ...objToPush,
          object: valuesToUpdate,
        };
        finalData.push(objToPush);
      }
    });

    if (!isEmpty(localSelected)) {
      const logObj = buildLogObject(currentUser, 
        {
          action: `extra_time_added`, 
          current_value: {
            time: time
          },
          user_ids: getKeysFromObject(localSelected, { parseInt: true }), 
          user_names: getKeysFromObject(localSelected, { value: true })
        }, 
        {
          orgId: currentUser.org_id, 
          experienceId: experience.id, 
          internetStatus: 'online', 
          userIp: userIp,
        }
      )
      finalData.push({
        // experienceId: experience.id,
        basePath: `/experiences/${experience.id}/logs`,
        docIds: [logObj.log_id],
        tableName: "logs",
        uuids: [logObj.log_id],
        object: logObj,
        options: {
          // createNewDocId: true, // TODO: causing issues
          // newDocKey: "by_user_id",
          parseInt: true,
        },
        // actionType: "set",
      });
    }
    // const  = Object.keys(localSelected).length;
    // const confirmTitle = studentsNotHavingExtraTime.length
    //   ? `You are setting extra time of ${time} minute${
    //       time == 1 ? "" : "s"
    //     } for ${studentsNotHavingExtraTime.length} student${
    //       studentsNotHavingExtraTime.length === 1 ? "" : "s"
    //     }. Are you sure?`
    //   : `All selected students already have more than ${time} minute${
    //       time == 1 ? "" : "s"
    //     } extra time. Please choose a higher value.`;
    
    // const confirmTitle = studentsNotHavingExtraTime.length
    //   ? time == 1 && studentsNotHavingExtraTime.length == 1
    //     ? `you are setting extra time of ${time} minute for ${studentsNotHavingExtraTime.length} student Are you sure?` :
    //     time == 1 && studentsNotHavingExtraTime.length > 1
    //       ? `you are setting extra time of ${time} minute for ${studentsNotHavingExtraTime.length} students Are you sure?`
    //       : time > 1 && studentsNotHavingExtraTime.length == 1
    //         ? `you are setting extra time of ${time} minutes for ${studentsNotHavingExtraTime.length} student Are you sure?`
    //         : `you are setting extra time of ${time} minutes for ${studentsNotHavingExtraTime.length} students Are you sure?`
    //   : time == 1
    //     ? `All selected students already have more than ${time} minute extra time. Please choose a higher value.`
    //     : `All selected students already have more than ${time} minutes extra time. Please choose a higher value.`
    const studentsNotHavingExtraTimeCount = studentsNotHavingExtraTime.length
    const extraTimeWithStudentsCountObj = { time: time, students:  studentsNotHavingExtraTimeCount}
    const confirmTitle = studentsNotHavingExtraTimeCount
      ? time == 1 && studentsNotHavingExtraTimeCount == 1
        ? mrIntl("BulkAddTime.you_are_setting_extra_time_for_student", extraTimeWithStudentsCountObj) :
        time == 1 && studentsNotHavingExtraTimeCount > 1
          ? mrIntl("BulkAddTime.you_are_setting_extra_time_for_students", extraTimeWithStudentsCountObj)
          : time > 1 && studentsNotHavingExtraTimeCount == 1
            ? mrIntl("BulkAddTime.you_are_setting_extra_times_for_student", extraTimeWithStudentsCountObj)
            : mrIntl("BulkAddTime.you_are_setting_extra_times_for_students", extraTimeWithStudentsCountObj)
      : time == 1
        ? mrIntl("BulkAddTime.students_already_have_more_than_minute", { time: time })
        : mrIntl("BulkAddTime.BookmarkList.students_already_have_more_than_minutes", { time: time })

    const confirmContent = studentsNotHavingExtraTime.length > 0 && studentsHavingExtraTime.length > 0 && (
      <>
        <Row>
          <Col span={24} style={{ maxHeight: "500px", overflowX: "auto" }}>
            <>
              {/* Note: These students already have extra time equal to or greater
                than {time} minutes. Hence, they will not be affected. */}
              {mrIntl("BulkAddTime.student_already_have_extra_time_msg", { time: time })}
              <ol>
                {studentsHavingExtraTime.map((item, key) => (
                  <li>{item.name}</li>
                ))}
              </ol>
            </>
          </Col>
        </Row>
      </>
    );
    
    showConfirmModal({
      title: confirmTitle,
      content: confirmContent,
      onOk: () => {
        realtimeBatchActions(finalData, {
          successCallback: () => {
            toggleShowSelectBoxes();
            clearSelection();
            setHeaderActions({
              addTime: false,
              announcement: false,
            });
            message.success(mrIntl("CommonText.extra_time_set_successfullly"));
            setBulkActionLoading(false);
          },
          errorCallback: (error) => {
            console.log("errorcallback 791", error);
            message.error(mrIntl("BulkAddTime.add_time_error_msg"));
            setBulkActionLoading(false);
          },
          appRegion: appRegion,
        });
      },
      onCancel: () => {
        setBulkActionLoading(false);
        toggleShowSelectBoxes();
        clearSelection();
        setHeaderActions({
          addTime: false,
          announcement: false,
        });
      },
      cancelText: mrIntl("CommonText.cancel"),
      width: 800,
      okType: "primary",
      okButtonProps: {
        // ghost: true,
        style: {
          display: studentsNotHavingExtraTime.length === 0 && "none",
        },
      },
      cancelButtonProps: {
        type: "danger",
        // ghost: true,
      },
      mrIntl: mrIntl
    });
  };

  return (
    <Tooltip
      title={
        totalSelections === 0
          ? mrIntl("BulkAddTime.cannot_add_time_while_no_student_is_online")
          : mrIntl("BulkAddTime.select_extra_time")
      }
    >
      <Select
        disabled={totalSelections === 0 || bulkActionLoading}
        placeholder={mrIntl("BulkAddTime.extra_time")}
        onChange={(value) => addTimeForSelected(value)}
      >
        {extraTimeValues.map((currentExtraTime) => {
          return <Option value={currentExtraTime}>{currentExtraTime}</Option>;
        })}
      </Select>
    </Tooltip>
  );
};

export default BulkAddTime;
