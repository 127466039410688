import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRouteMatch, useLocation, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Result, Button } from "antd";
import { actions } from "../redux";
import "/src/components/Partners/OSC/styles.scss";
import "/src/components/Partners/Pamoja/styles.scss";
import Segments from "/src/views/Segments/Segments";
import {
  activeEmbeddedResourceSelector,
  findloadingSelector,
  errorSelector,
} from "../selector";
import Spinner from "/src/components/UI/Spinner/Spinner";
import SegmentItemDetail from "/src/views/Segments/SegmentItemDetail/SegmentItemDetail";
import ExperienceItemDetail from "/src/views/Experiences/ExperienceShow/ExperienceItemDetail";
import Experiences from "/src/views/Experiences/Experiences";
import { actions as loginReduxActions } from "/src/views/Auth/Login/redux";
import { actions as segmentActions } from "/src/views/Segments/redux";
import queryString from "query-string";
import { checkMob } from "/src/lib/utils/helperMethods";
import { getEmbeddedSelector, oscAccountTypeSelector, partnerNameSelector } from "/src/views/Auth/Login/selector";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";

const EmbeddedResourceItemDetailUuid = (props) => {
  console.log( "EmbeddedResourceItemDetailUuid props", props );
  const { path, params: matchParams, intl } = useRouteMatch();
  const dispatch = useDispatch();
  const activeEmbeddedResource =
    useSelector(activeEmbeddedResourceSelector()) || {};
  const loading = useSelector(findloadingSelector());
  const error = useSelector(errorSelector());
  const oscAccountType = useSelector(oscAccountTypeSelector());
  const location = useLocation();
  const isMobile = checkMob();
  const locationQueryParams = queryString.parse(location.search);
  const mrIntl = useTranslate()
  const partnerName = useSelector(partnerNameSelector())
  // console.log('partnerName==> ', partnerName);
  const embedded = useSelector(getEmbeddedSelector());
  console.log( "partneruuid location, path, url", partnerName, embedded );


  // console.log("partneruuid locationQueryParams ==>", locationQueryParams);
  useEffect(() => {
    dispatch(actions.findBy({ key: "uuid", value: matchParams.uuid, partner: locationQueryParams.partner }));

    dispatch(loginReduxActions.setEmbeddedSuccess({ embedded: true }));
  }, []);

  useEffect(() => {
    if(locationQueryParams.partner){
      dispatch(loginReduxActions.setPartnerNameSuccess({partnerName: locationQueryParams.partner}))
    }
    if(locationQueryParams.account_type){
      dispatch(loginReduxActions.setOscAccountTypeSuccess({oscAccountType: locationQueryParams.account_type}))
    }
    
    if (activeEmbeddedResource.resource_type == "Segment") {
      if(locationQueryParams.layout){
        dispatch(segmentActions.setLayoutSuccess({segmentLayout: locationQueryParams.layout}))
      }
      if(locationQueryParams.mode){
        dispatch(segmentActions.setModeSuccess({segmentMode: locationQueryParams.mode}))
      }
    } 

    // Add condition for Experience layout here
  }, [activeEmbeddedResource.resource_type])

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return (
      <Result
        status="403"
        title="403"
        subTitle={mrIntl("EmbeddedResourceItemDetailUuid.not_authorized_msg")}
        // extra={<Button type="primary">Back Home</Button>}
      />
    );
  }
  let finalRender;
  if (activeEmbeddedResource.resource_type === "Segment") {
    finalRender = (
      <>
        <Route
          path={`${path}`}
          render={(routeProps) => (
            <Segments
              resourceId={activeEmbeddedResource.resource_id}
              embedded={true}
              params={{}}
              isRoute={false}
              {...routeProps}
              config={{
                routes: { fetch: false },
                itemDetail: { widget: SegmentItemDetail },
              }}
            />
          )}
        />
      </>
    );
  } else if (activeEmbeddedResource.resource_type === "Experience") {
    // logic here
    // figure out currentUser
    finalRender = (
      <>
        <Route
          path={`${path}`}
          render={(routeProps) => (
            <Experiences
              resourceId={activeEmbeddedResource.resource_id}
              embedded={true}
              isRoute={false}
              mrIntl={mrIntl}
              intl={intl}
              {...routeProps}
              config={{
                uuid: { active: false, key: "id" },
                routes: { fetch: false },
                itemDetail: { widget: ExperienceItemDetail },
              }}
            />
          )}
        />
      </>
    );
  }

  return <div className={`embedded-wrapper ${activeEmbeddedResource.resource_type === "Segment" ? "embedded-segment" : ""} ${locationQueryParams.partner === "osc" ? 'osc' : embedded && !partnerName ? "mb" : ''} ${oscAccountType === "b2c" ?  "osc-b2c" : ""} ${isMobile ? 'mobile' : ''}`}>
    {finalRender}
  </div>;
};

EmbeddedResourceItemDetailUuid.defaultProps = {};

EmbeddedResourceItemDetailUuid.propTypes = {};

export default EmbeddedResourceItemDetailUuid;
