// import { createClient } from '@supabase/supabase-js'
import supabaseConf from './supabaseConfig';

// const Supabase = createClient(supabaseConf.supabaseUrl, supabaseConf.supabaseKey)

// export const supabaseDBWithoutToken = window.supabase && window.supabase.createClient(supabaseConf.supabaseUrl, supabaseConf.supabaseKey); // need this for the first call in getFirebaseToken when getting from session/LS, IMP: No need now since we're fetching token each time

let supabaseDBClient // declaring outside the function so that it's not initialized each time the function is called. Just the first time.
// let customJWT = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcF9yb2xlIjoic3R1ZGVudCIsInVzZXJfaWQiOjg0OSwiZXhwIjoxNjgzMjA4NzI1LCJpYXQiOjE2ODMwMzU5MjV9.3_g2PILelzUZpe_pUex08jslN9bHVnmBrfyFcZrzAZE" // this token didn't work for realtime (connection would be stuck on joining) - worked for insert and read - needed some extra attrs in the jwt - iss, ref, etc - added those and below token now works. adding these to BE node
// IMP: Below token works with insert, read and realtime with RLS also
// let customJWT = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhpbWJkd3lla2ZtaW94eGVrbGh4Iiwicm9sZSI6ImF1dGhlbnRpY2F0ZWQiLCJhcF9yb2xlIjoic3R1ZGVudCIsInVzZXJfaWQiOjg0OSwiZXhwIjoxNjgzMjEyNzMyLCJpYXQiOjE2ODMwMzk5MzJ9.66Twsrr18p0314fR6VBraw_R43KWtmjlHKU1qTlKhpo"

const supabaseDB = (customJWT) => {
  // When ever the customJWT is passed, it means the user is updated and we need to initialize the supabaseDBClient again
  if(supabaseDBClient && !customJWT) {
    // supabaseDBClient.realtime.setAuth(customJWT)
    // console.log("supabaseDBClient 13", supabaseDBClient);
    return supabaseDBClient
  }
  let AUTHORIZATION_TOKEN = `Bearer ${customJWT}`

  supabaseDBClient = window.supabase && window.supabase.createClient(supabaseConf.supabaseUrl, supabaseConf.supabaseKey,
    { 
      global: { headers: { Authorization: AUTHORIZATION_TOKEN } },
      realtime: {
        headers: {
            apikey: AUTHORIZATION_TOKEN, // my custom token returned from my edge fn --docs have it backwards
        },
        params: {
            apikey: supabaseConf.supabaseKey, // the supabase anon key
        },
      },
    }
  )
  supabaseDBClient.realtime.setAuth(customJWT)
  // console.log("supabaseDBClient 32", supabaseDBClient);
  return supabaseDBClient;

}
export default supabaseDB;


// Token that supabase creates when using sign in
// let customJWT = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInVzZXJfaWQiOjI0NSwicmVmIjoieGltYmR3eWVrZm1pb3h4ZWtsaHgiLCJyb2xlIjoiYXV0aGVudGljYXRlZCIsImFwX3JvbGUiOiJ0ZWFjaGVyIiwiaWF0IjoxNjczNDk1OTY1LCJleHAiOjE5ODkwNzE5NjV9.P6NSBWqpqAu9ySVDp8xWnJ1JjyUnP8UVclbSG3O0HiE"
// let AUTHORIZATION_TOKEN = `Bearer ${customJWT}`

// const supabaseDB = window.supabase && window.supabase.createClient(supabaseConf.supabaseUrl, supabaseConf.supabaseKey,
//   { 
//     global: { headers: { Authorization: AUTHORIZATION_TOKEN } },
//     realtime: {
//       headers: {
//           apikey: AUTHORIZATION_TOKEN, // my custom token returned from my edge fn --docs have it backwards
//       },
//       params: {
//           apikey: supabaseConf.supabaseKey, // the supabase anon key
//       },
//     },
//   }
// )
// supabaseDB.realtime.setAuth(customJWT)
// export default supabaseDB;