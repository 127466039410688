import React, { useEffect, useState } from "react";
// import "./GroupItemDetail.scss";
import { Route, useLocation, useRouteMatch } from "react-router-dom";
import { Card, Row, Col, List, Tag, Space, Divider, Image, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { actions } from "/src/views/Experiences/redux";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { CheckOutlined, SoundOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // load on demand
import { displayPoints } from "/src/lib/utils/helperMethods";
import FabricAnnotator from "/src/views/Segments/FabricAnnotator/FabricAnnotator";
import RenderHtml from "/src/components/UI/RenderHtml/RenderHtml";
import { printExperienceSelector, printLoadingSelector } from "/src/views/Experiences/selector";
import Segments from "/src/views/Segments/Segments";
import { getEmbeddedSelector } from "/src/views/Auth/Login/selector";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { isEmpty, toLower } from "lodash";
import RenderExtraInstructions from "./RenderExtraInstructions";
import TaskSpecificClarification from "../TaskSpecificClarification/TaskSpecificClarification";

const ExperiencePrint = (props) => {
  console.log("ExperiencePrint props", props);
  const {
    // item,
    match,
    gradingConfig,
  } = props;

  const { path, url } = useRouteMatch();
  const location = useLocation();
  const mrIntl = useTranslate()
  const dispatch = useDispatch();
  dayjs.extend(utc);

  const locationQueryParams = queryString.parse(location.search);
  const paramsUUID = match.params && match.params.uuid;
  const print_type = locationQueryParams.print_type;
  const userId = parseInt(locationQueryParams.user_id);
  const current_user_utc_offset = locationQueryParams.current_user_utc_offset;
  console.log("params coming here==>", locationQueryParams)

  const embedded = useSelector(getEmbeddedSelector())
  const printLoading = useSelector(printLoadingSelector());
  const printExperience = useSelector(printExperienceSelector());

  useEffect(() => {
    console.log("paramsId 23", paramsUUID);
    let printOptions = {
      id: paramsUUID,
      print_type: print_type,
    };
    // Need to check this, why do we need  !locationQueryParams.with_print_copies_for_each_student here
    if (userId && locationQueryParams.with_print_copies_for_each_student !== "true") {	
      printOptions = {	
        ...printOptions,	
        user_id: userId,	
        with_exp_org_time: true,	
      };
    }

    dispatch(actions.print(printOptions));
    dispatch(actions.setPrintModeSuccess({ printMode: true }));
    dispatch(actions.setPrintOptionsSuccess({ printOptions: locationQueryParams }));
  }, []);

  if (printLoading) {
    return <Spinner />;
  }
  
  // const experience = printExperience.experience || {}; // get segments from /experiences_for_print/:id
  // const segments = printExperience.segments || {}; // get segments from /experiences_for_print/:id
  
  let experienceViewMode = "apTakeTest"; // Setting default view mode to apTakeTest

  /////////////////////////////////////////////////////////////////
  // print_type = experience is default
  // print_type = "student_experience" is not supported anymore
  /////////////////////////////////////////////////////////////////
  if (print_type === "student_submission" ||
    print_type === "all_student_submissions") {
    experienceViewMode = "apGradeTest";
  }

  let showCorrectAnswer = 
  (locationQueryParams.with_markscheme === 'true' || locationQueryParams.with_answer_explanation === 'true');

  if(print_type === "experience" && showCorrectAnswer){
    experienceViewMode = "apEditTest";
  }

  const gradingEnabled = false;
  const experience = printExperience.experience || {};
  const segments = printExperience.segments || [];
  const experienceUser = printExperience.experience_user || {};
  const eu = experienceUser.eu || {};
  const euCustomFields = eu.custom_fields;
  const reviewerName = euCustomFields?.reviewer_name;
  const exp_org_time = printExperience.org_time || {};
  const exp_org_utc_offset = exp_org_time.utc_offset;
  const experienceSettings = experience.settings || {};
  const experienceCustomFields = experience.custom_fields || {};
  const experienceRubric =  experience.rubric || {};
  const rubricType = experienceRubric.type_c;

  console.log("ExperiencePrint locationQueryParams==>", eu);

  const isMBTask =
    experience.custom_fields && experienceCustomFields.mb_task_id
      ? true
      : false;
  const isMYPAchievementLevel =
    rubricType === "myp_achievement_level"
      ? true
      : false;
  const isCriteriaWithPoints = rubricType === "criteria_with_points" ? true : false;


  const showPointsTable = isMYPAchievementLevel || (isCriteriaWithPoints)

  console.log("checking rubric==>", showPointsTable);


  let criteriaPointsView = [];
  let criteriaTableData = [];
  if (experience.criterium_associations) {
    experience.criterium_associations.forEach((ca, i) => {
      criteriaPointsView.push(
        <Tag>
          {ca.custom_fields.title}{isCriteriaWithPoints && ` ${displayPoints(ca.points, isMBTask)}`}
          {/* {!isMYPAchievementLevel && displayPoints(ca.points, isMBTask)} */}
        </Tag>
      );
    });
  }

  const columns = [
    {
      title: 'Criterion',
      dataIndex: 'criterion',
      key: 'criterion',
    },
    {
      title: `${rubricType === "criteria_with_points" ? "Criteria with points" : "Maximum"}`,
      dataIndex: 'points',
      key: 'points',
      align: 'center',
    },
    {
      title: `${rubricType === "criteria_with_points" ? "Equivalent achievement level" : "Level"}`,
      dataIndex: 'level',
      key: 'level',
      align: 'center',
    }
  ]

  let finalRender = [];

  if (experience) {
    finalRender.push(
      <List.Item
        className="unstyled-list-item"
        key={`org-name-${experience.id}`}
      >
        <Row className="print-header-row">
          <Col span={24}>
            <Space>
              {printExperience.org_logo ? (
                <>
                  <img
                    src={printExperience.org_logo}
                    alt={mrIntl("ExperiencePrint.school_logo_alt_msg")}
                    style={{ "max-height": "50px", width: "auto" }}
                  />
                  <h2>{printExperience.org_name}</h2>
                </>
              ) : (
                <h2>{printExperience.org_name}</h2>
              )}
            </Space>
          </Col>
          <Divider style={{margin: "0px"}} />
          {/* <Col span={12} style={{ textAlign: "right" }}>
            <img
              style={{ width: "50px" }}
              src={LogoImageCollapsed}
              alt={mrIntl("ExperiencePrint.ap_logo")}
            />
          </Col> */}
        </Row>
      </List.Item>
    );
    finalRender.push(
      <List.Item
        className="unstyled-list-item experience-print-title"
        key={`experience-title-${experience.id}`}
      >
        <h2>
          <b>{experience.name}</b>
          <br />
         
        </h2>
        {/* <p>{experience.author_name}</p> */}
      </List.Item>
    );
    finalRender.push(
      <List.Item
        className="unstyled-list-item print-programme-name"
        key={`experience-programme-name-${experience.id}`}
      >
        <h3>
          <b>{experience && experience.custom_fields && experience.custom_fields.org_programme_item_label}</b>
          <br />
          <b>{experience && experience.custom_fields && experience.custom_fields.subject_item_label}</b>
        </h3>
      </List.Item>
    );
    
    finalRender.push(
      <List.Item
        className="unstyled-list-item print-header"
        key={`experience-details-${experience.id}`}
      >
        <Row className="print-header-row">
          {/* <Col span={6}>
            <Space direction="vertical">
              <span>Assessment</span>
              <h2>{experience.name}</h2>
            </Space>
          </Col> */}
          {/* {experienceCustomFields.subject_item_label && (
            <Col span={24}>
                <h4>
                  <span>{mrIntl("ExperiencePrint.subject")}{" "}</span>
                  {experienceCustomFields.subject_item_label}{" "}
                </h4>
            </Col>
          )} */}

          {/* Already showing name at the top in this case, also not getting student name in this case from eu */}
          {/* {print_type === "experience" && locationQueryParams.with_print_copies_for_each_student === "true" && <Row className="print-header-row">
              <Col span={3}>
                <h4>Student name:</h4>
              </Col>
              <Col span={12}>
                <p>{studentName}</p>
              </Col>
            </Row>} */}
          {experienceCustomFields.grade_item_label && (
            <Row className="print-header-row">
              <Col span={3}>
                <h4>{mrIntl("ExperiencePrint.grade")}</h4>
              </Col>
              <Col span={12}>
                <p>{experienceCustomFields.grade_item_label}</p>
              </Col>
            </Row>
          )}
          {
          experience && experience.criterium_associations && experience.criterium_associations.length > 0 && ( <Row className="print-header-row">
              <Col span={3}>
                <h4>
                  <span>{mrIntl("ExperiencePrint.criteria")}: </span>
                </h4>
              </Col>
              <Col span={12}>
                <p>
                  {criteriaPointsView.length > 0
                    && criteriaPointsView
                   }{" "}
                </p>
              </Col>
            </Row> )
          }
          {experience.points && rubricType !== "myp_achievement_level" && rubricType !== "comments_only" && (
            <Row className="print-header-row">
              <Col span={3}>
                {/* using eu exp_pts for pamoja submissions, exp.points was 0 */}
                <h4>
                  <span>{mrIntl("ExperiencePrint.total")}: </span>
                </h4>
              </Col>
              <Col span={12}>
                <p>
                {displayPoints(
                        isMBTask && !isEmpty(eu) && euCustomFields
                          ? euCustomFields.experience_points
                          : experience.points,
                        isMBTask
                      )}{" "}
                  {/* {criteriaPointsView.length > 0
                    ? criteriaPointsView
                    : displayPoints(
                        isMBTask && eu
                          ? euCustomFields.experience_points
                          : experience.points,
                        isMBTask
                      )}{" "} */}
                  <span>{toLower(mrIntl("ExperiencePrint.points"))}</span>
                </p>
              </Col>
            </Row>
          )}
          {experienceSettings.is_timed && experienceSettings.duration > 0 && (
            <Row className="print-header-row">
              <Col span={3}>
                <h4>{mrIntl("ExperienceList.duration")}: </h4>
              </Col>
              <Col span={12}>
                <p>{experienceSettings.duration} mins</p>
              </Col>
            </Row>
          )}
          {experienceSettings.start_at_datetime && (
            <Row className="print-header-row">
              <Col span={3}>
                <h4>{mrIntl("ExperiencePrint.start_time")}:</h4>
              </Col>
              <Col span={12}>
                <p>
                  {current_user_utc_offset
                    ? dayjs(experienceSettings.start_at_datetime)
                        .utc()
                        .add(current_user_utc_offset, "seconds")
                        .format("MMM DD, hh:mm a")
                    : dayjs(experienceSettings.start_at_datetime)
                        .utc()
                        .add(exp_org_utc_offset, "seconds")
                        .format("MMM DD, hh:mm a")}
                  {mrIntl("ExperiencePrint.school_tz")}
                </p>
              </Col>
            </Row>
          )}
          {experienceSettings.close_at_datetime && (
            <Row className="print-header-row">
              <Col span={3}>
                <h4>
                  <span>{mrIntl("ExperiencePrint.close_time")}: </span>
                </h4>
              </Col>
              <Col span={12}>
                <p>
                  {current_user_utc_offset
                    ? dayjs(experienceSettings.close_at_datetime)
                        .utc()
                        .add(current_user_utc_offset, "seconds")
                        .format("MMM DD, hh:mm a")
                    : dayjs(experienceSettings.close_at_datetime)
                        .utc()
                        .add(exp_org_utc_offset, "seconds")
                        .format("MMM DD, hh:mm a")}
                  {mrIntl("ExperiencePrint.school_tz")}
                </p>
              </Col>
            </Row>
          )}
          {experience.collaborators && experience.collaborators.length > 0 && (
            <Row className="print-header-row">
              <Col span={3}>
                <h4>{mrIntl("ExperiencePrint.authors")}:</h4>
              </Col>
              <Col span={12}><p>{experience.collaborators.join(", ")}</p></Col>
            </Row>
          )}
          {reviewerName && (
            <Row className="print-header-row">
              <Col span={3}>
                <h4>{mrIntl("ExperiencePrint.reviewed_by")}:</h4>
              </Col>
              <Col span={12}>{reviewerName}</Col>
            </Row>
          )}
          <Divider style={{ margin: "0px" }} />
          {experienceSettings?.extra_instructions_text && (
            <Col span={24}>
              <RenderExtraInstructions text={experienceSettings?.extra_instructions_text}/>
              <Divider />
            </Col>
          )}
        </Row>
        {/* <h2>{experience.name}</h2>
        <p>
          {experience.points && <Tag>{experience.points} Pts </Tag>}
          {experienceSettings.duration && <Tag icon={<ClockCircleOutlined />}>{experienceSettings.duration} mins </Tag>}
          {experienceSettings.start_at_datetime && <Tag color="success" icon={<CalendarOutlined />}>Scheduled for {dayjs(experienceSettings.start_at_datetime).format("MMM DD, hh:mm a")}</Tag>}
        </p> */}
      </List.Item>
    );
  }

  if (eu && !isEmpty(eu) && userId) {
    // console.log("exp_org_time 1", eu.created_at, dayjs(eu.created_at));
    let criteriaPointsViewEU = [];
    if (eu.submission_criterium_associations_attributes) {
      eu.submission_criterium_associations_attributes.forEach((sca, i) => {
        criteriaPointsViewEU.push(
          <Tag key={`criteria-${i}`} style={{ fontWeight: "800" }}>
            {sca.custom_fields.title}{" "}
            {sca.points ? displayPoints(sca.points, isMBTask) : "_"} /
            {displayPoints(sca.custom_fields.max_points, isMBTask)}
          </Tag>
        );

        criteriaTableData.push({
          key: `criteria-points-${i}`,
          criterion: sca.custom_fields.title + "-" + sca.custom_fields.label,
          points: experience.rubric.type_c === "criteria_with_points" ? `${sca.points ? displayPoints(sca.points, isMBTask) : "_"}/${displayPoints(sca.custom_fields.max_points, isMBTask)}` : displayPoints(sca.custom_fields.max_points, isMBTask),
          level: sca.custom_fields.is_na === true ? "N/A" : experience.rubric.type_c === "criteria_with_points" ? `${sca.custom_fields && sca.custom_fields.converted_achievement_level ? sca.custom_fields.converted_achievement_level : "-"}/8` : sca.points ? displayPoints(sca.points, isMBTask) : "_",
        });
      });
    }
    // if (isMYPAchievementLevel) {
    //   criteriaPointsViewEU.push(
    //     <Tag style={{ fontWeight: "800" }}>
    //       Total {eu.points}/
    //       {euCustomFields.experience_points}
    //     </Tag>
    //   );
    // }

    finalRender.push(
      <List.Item
        className="unstyled-list-item"
        key={`experience-user-info-${eu.id}`}
      >
      <Row>
        <Col span={12}>
          <h2>{eu.name}</h2>
        </Col>
        <Col span={12}>
          <h2>{(exp_org_utc_offset || exp_org_utc_offset == 0 ) && (
              <Tag color="processing" icon={<CheckOutlined />}>
                {mrIntl("ExperiencePrint.submitted_on")}{" "}
                {dayjs(eu.created_at)
                  .utc()
                  .add(exp_org_utc_offset, "seconds")
                  .format("MMM DD, hh:mm a")}{" "}
                {mrIntl("ExperiencePrint.school_tz")}
              </Tag>
            )}</h2>
        </Col>
      </Row>
        <div>
          {/* {criteriaPointsViewEU.length > 0 && !isMYPAchievementLevel && criteriaPointsViewEU} */}

          {!isMYPAchievementLevel && eu.points && experience.rubric.type_c !== "comments_only" && (
            <h3>{mrIntl("ExperiencePrint.score")}: {`${displayPoints(eu.points, isMBTask)}/${displayPoints(
              isMBTask && !isEmpty(eu) && euCustomFields
                ? euCustomFields?.experience_points
                : experience.points,
              isMBTask
            )}`} {mrIntl("ExperiencePrint.pts_tag")} </h3>
          )}

          {/* {experienceUser.eu.points &&
            (criteriaPointsViewEU.length > 0 ? (
              criteriaPointsViewEU
            ) : (
              <Tag>
                {displayPoints(eu.points, isMBTask)} Pts{" "}
              </Tag>
            ))} */}

          {showPointsTable && (
            <Space direction="vertical">
            <Table columns={columns} dataSource={criteriaTableData} pagination={false} /> 
          </Space>
          )}
        </div>
        {/* {eu.marked_responses_count} - {eu.total_responses_count} */}
      </List.Item>
    );
    const showComments = eu && (eu.comments || (euCustomFields &&  euCustomFields.media_comments && euCustomFields.media_comments.attachments && euCustomFields.media_comments.attachments.length > 0)) 

    showComments &&
      finalRender.push(
        <List.Item
          className="unstyled-list-item"
          key={`experience-user-info-${eu.id}-comments`}
        >
          <Card style={{marginTop: "20px"}} className="print-overall-comments" title={<MrTranslate id={"ExperiencePrint.teachers_overall_comments"}/>}>
            <div dangerouslySetInnerHTML={{ __html: eu.comments }}></div>
            {euCustomFields && euCustomFields.media_comments && euCustomFields.media_comments.attachments && euCustomFields.media_comments.attachments.length > 0 && <Space><p className="no-comments"><span><SoundOutlined /></span>{" "}This comment includes audio feedback. Please visit AssessPrep to listen to this feedback.</p></Space>}
          </Card>
        </List.Item>
      );
  }

  if (segments) {
    segments.map((topic, i) => {
      let t = topic.segment;
      let topicId = t.id
      finalRender.push(
        <List.Item
          className={
            i == 0
              ? "segment-list-item unstyled-list-item first-topic-title"
              : "segment-list-item unstyled-list-item topic-title"
          }
          key={`topic-title-${topicId}`}
        >
          <h3>
            <b>
              <RenderHtml text={t.section_segment_attributes.title} truncate />
            </b>
          </h3>
        </List.Item>
      );
      if (topic.children) {
        // let qCount = 1;
        // topic.children.map((segment, i) => {
        //   let qNo = segment.segment_type === "question" ? qCount : "";

        //   if (segment.segment_type === "question" && userId && userResponses) {
        //     let userResponse = userResponses.find(
        //       (ur) =>
        //         ur.user_id === userId &&
        //         ur.question_id === segment.question_segment_attributes.id &&
        //         ur.segment_id === segment.id
        //     );

        //     console.log(
        //       "userResponse print",
        //       userResponse,
        //       userId,
        //       segment.question_segment_attributes.id,
        //       experienceUser,
        //       userResponses
        //     );

        //     finalRender.push(
        //       <List.Item
        //         className="segment-list-item"
        //         key={`segment-show-${segment.id}`}
        //       >
        //         <Card bordered={false}>
        //           <SegmentItemDetail
        //             key={`segment-item-detail-${segment.id}`}
        //             item={segment}
        //             experienceViewMode={experienceViewMode}
        //             userResponse={userResponse}
        //             gradingEnabled={gradingEnabled}
        //             experienceUser={eu}
        //             experience={experience}
        //             qNo={qNo}
        //             readingModeEnabled={true}
        //             printMode={true}
        //           />
        //         </Card>
        //       </List.Item>
        //     );
        //   } else {
        //     finalRender.push(
        //       <List.Item
        //         className="segment-list-item"
        //         key={`segment-show-${segment.id}`}
        //       >
        //         <Card bordered={false}>
        //           <SegmentItemDetail
        //             key={`segment-item-detail-${segment.id}`}
        //             item={segment}
        //             experienceViewMode={experienceViewMode}
        //             experience={experience}
        //             qNo={qNo}
        //             printMode={true}
        //           />
        //         </Card>
        //       </List.Item>
        //     );
        //   }

        //   if (segment.segment_type === "question") {
        //     qCount += 1;
        //   }
        // });
        
        let segmentsComponent = (
          <Route
            path={`${path}`}
            render={(routeProps) => (
              <Segments
                doNotReloadOnUpdate={true}
                experienceViewMode={experienceViewMode}
                experienceProps={{item: experience}}
                embedded={embedded}
                mobileUploadMode={false}
                rearrangeMode={false}
                readingModeEnabled={false}
                printMode={true}
                topicProps={null}
                // topicProps={{item: selectedTopic}}
                parentId={topicId}
                parentIdxs={[i]}
                nestingLevel={1}
                gradingConfig={gradingConfig}
                experienceUser={eu}
                params={{
                  by_experience_id: experience.id,
                  by_parent_id: topicId,
                }}
                createUpdateParams={{
                  experience_id: experience.id,
                  parent_id: topicId,
                }}
                libraryModalProps={{}}
                isRoute={false}
                {...routeProps}
                config={{
                  list: {
                    shouldExecuteRequest: false
                  }
                }}
              />
            )}
          />
        );
        finalRender.push(segmentsComponent)
      }
    });
  }

  if (eu && !isEmpty(eu) && eu.attachments_count) {
    const annotatorConfig = {
      ...eu,
      ...gradingConfig,
      eu_id: eu.id,
      // topicId: topicId,
      // segmentId: segmentId,
      // setTopicId: setTopicId,
      // setSegmentId: setSegmentId,
    };
    finalRender.push(
      <FabricAnnotator
        {...annotatorConfig}
        gradingEnabled={gradingEnabled}
        printMode={true}
        showOnlyUnmapped={true}
        // gradingEnabled={false}
        // previewType={'drawer'}
        // attachments_json={item.attachments_json}
        // updateAttachmentsJSON={updateAttachmentsJSON}
      />
    );
  }
  if(locationQueryParams?.with_tsc == 'true' || locationQueryParams?.with_tsc == true){
    finalRender.push(<div className="task-specific-clarification-print">
          {" "}
        <TaskSpecificClarification
          tscInModal={false}
        />
      </div>
  );
}
  console.log(
    "experience_user inside print ==>",
    printExperience,
    experienceUser
  );

  return (
    <>
      <Row className="experience-print">
        <Col span={22} offset={1}>
          {/* <h3>Segments list render</h3> */}
          <div
            className={`segments-list ${
              experienceViewMode === "apGradeTest" ? "grading-view" : ""
            }`}
          >
            {finalRender}
          </div>
        </Col>
      </Row>
     
      {/* TodO: show only questions with student responses */}
    </>
  );

  // return <React.Fragment>
  //   <Route path={`${path}`} render={routeProps => <Experiences  isRoute={false} {...routeProps} resourceId={match.params.experience_id} config={{routes: {fetch:false}, itemDetail: {widget: ExperienceItemDetail}}}/>}  />
  // </React.Fragment>
};

ExperiencePrint.defaultProps = {};

ExperiencePrint.propTypes = {};

export default ExperiencePrint;
