import { Button, Space, Tag, Tooltip } from "antd";
import React, { memo, useState, useEffect, useRef } from "react";
import {
  DragOutlined,
  MenuOutlined,
  CaretDownFilled,
  CaretRightFilled,
  EditOutlined,
} from "@ant-design/icons";
import ActionButtons from "./ActionButtons";
import InfoElement from "./InfoElement";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { CKETagDescriptionTools } from "../CKEditor/CKEConfig";
import CKETextContent from "/src/views/Segments/CKETextContent/CKETextContent";
import RenderHtml from "../RenderHtml/RenderHtml";
import DetectOutsideClick from "/src/lib/DetectOutsideClick/DetectOutsideClick";
import { isEqual, pick } from "lodash";
import { renderMathInElement } from "/src/lib/utils/helperMethods";

const areEqual = (prevProps, nextProps) => {
  const propertiesToCompare = [
    "item",
    "isLabelEditing",
    "activeItem",
    "showAddChildrenButton",
    "itemSiblingsCount",
    "showDetails",
    "showChildren",
    "showCollapseButton",
    "loading",
    "items"
  ];

  const prevPropsSubset = pick(prevProps, propertiesToCompare);
  const nextPropsSubset = pick(nextProps, propertiesToCompare);

  return isEqual(prevPropsSubset, nextPropsSubset);
};

const DragElement = (props) => {
  console.log("DragElement ==>", props);
  const {
    preview,
    isDragging,
    drag,
    item,
    deleteItem,
    // addItem,
    addChildrenItem,
    showAddChildrenButton,
    isLabelEditing,
    showChildren,
    setShowChildren,
    showCollapseButton,
    itemSiblingsCount,
    showDetails,
    setShowDetails, 
    updateItemDetail, 
    showDetailAddons,
    setActiveItemKey,
    loading,
    isEditable,
    items
  } = props;
  const { id, label } = item;
  const [tagName, setTagName] = useState(label);
  const mrIntl = useTranslate()
  const labelRef = useRef();

  useEffect(() => {
    console.log("descriptionRef ===>", labelRef);
    if (labelRef.current && !isLabelEditing) {
      renderMathInElement(labelRef.current, true);
    }
  }, [labelRef.current, isLabelEditing]);


  useEffect(() => {
    setTagName(label);
  }, [label]);

  const labelInputKey = `item-label-${item.id}`;
  const labelShowClassName = "item-show-input-wrapper" + (tagName ? " cursor-pointer" : "")

  let ckeConfig = {
    ...CKETagDescriptionTools,
    debounceInterval: 1000,
  };

  let detailButtonTitle = showDetails
    ? mrIntl("CommonText.close")
    : item.description || item.tag_teaching_level
    ? mrIntl("CommonText.edit")
    : mrIntl("CommonText.add") +
    " " +
    mrIntl("DragElement.details")

    if(!isEditable) {
      detailButtonTitle = showDetails ? mrIntl("DragElement.hide_details") : mrIntl("DragElement.show_details")
    }

  return (
    <div
      className={`element ${isDragging ? "blur" : ""}`}
      ref={(node) => {
        preview(node);
      }}
    >
      <Space>
        {showCollapseButton &&
          (showChildren ? (
            <CaretDownFilled onClick={() => setShowChildren(false)} />
          ) : (
            <CaretRightFilled onClick={() => setShowChildren(true)} />
          ))}
        {isEditable && <MenuOutlined ref={(node) => drag(node)} className="drag-icon" />}
        {isLabelEditing ? (
          <DetectOutsideClick
            detectOutside={true}
            targetExceptionClassname={[
              "ck-toolbar",
              "wrs_modal_dialogContainer",
              "mqEditor-wrapper"
            ]}
            callback={() => {
              setActiveItemKey("");
            }}
          >
            <CKETextContent
              className={
                "item-input-cke"
              }
              key={labelInputKey}
              itemId={labelInputKey}
              value={tagName}
              tools={CKETagDescriptionTools}
              ckeConfig={ckeConfig}
              onChange={(data) =>
                updateItemDetail(id, { label: data, name: data })
              }
              placeholder="Enter value"
              // onBlur={() => setActiveItemKey("")}
            />
          </DetectOutsideClick>
        ) : (
          <div
            key={`label-${item.id}`}
            className={labelShowClassName}
            ref={labelRef}
            onClick={() => {
              if (!ckeConfig.isReadOnly && !loading) {
                setActiveItemKey(labelInputKey);
              }
            }}
          >
            <Space direction="vertical" className="item-content">
              {tagName ? (
                <RenderHtml text={tagName} />
              ) : (
                <span className="action-text">
                  {isEditable ? <><EditOutlined /> Write</> : "-"}
                </span>
              )}
            </Space>
          </div>
        )}
        {showDetailAddons && (
          <>
            <Button onClick={() => setShowDetails(!showDetails)}>
              {detailButtonTitle}
            </Button>
            {item.tag_teaching_level && !showDetails && (
              <Tooltip title={mrIntl("DragElement.teaching_level")}>
                <Tag>{item.tag_teaching_level}</Tag>
              </Tooltip>
            )}
            {item.description && !showDetails && (
              <Tag>{mrIntl("DragElement.contains_description")}</Tag>
            )}
          </>
        )}
        
        {isEditable && import.meta.env.VITE_MODE !== "production" && import.meta.env.VITE_MODE !== "demo" && <InfoElement item={item} />}
        {isEditable && (
          <ActionButtons
            // addItem = {addItem}
            addChildrenItem={addChildrenItem}
            deleteItem={deleteItem}
            // showAddButton = {false}
            showAddChildrenButton={showAddChildrenButton}
            showDeleteButton={true}
            item={item}
            itemSiblingsCount={itemSiblingsCount}
          />

        )}
      </Space>
    </div>
  );
};

export default memo(DragElement, areEqual);
