import React, { useEffect, useState } from "react";
import {
  initialData,
  removeItemFromArray,
  getParsedJSONObject,
} from "/src/views/Segments/InteractiveHelpers";
import { Segment, SegmentData } from "/src/components/UI/Segment/UIHelper";
import { cloneDeep } from "lodash";
import MediaUI from "/src/components/UI/Media/MediaUI";
import TabularAttachments from "/src/components/UI/Attachments/TabularAttachments";
import EmbedView from "../Embed/EmbedView";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import PPTView from "./PPTView";

const PPTForm = (props) => {
  console.log("document props PPTForm", props);
  let { createMode, presentationMode } = getParsedJSONObject(props);
  let parsedJSONObject;
  const mrIntl = useTranslate();
  if (createMode) {
    parsedJSONObject = props.value || initialData("resource", 1, 4);
  } else {
    parsedJSONObject = props.resource_json;
  }

  const [state, setState] = useState(parsedJSONObject);
  const [stateUpdatedCount, setStateUpdatedCount] = useState(0);

  const mediaFormInitialState = {
    modal: false,
    loading: false,
    visible: false,
    title: mrIntl("PPTForm.insert_ppt_title"),
    okText: mrIntl("PPTForm.add_ppt_button"),
    cancelText: mrIntl("CommonText.cancel"),
    triggerSaveAction: 0,
    limit: 1,
    requireFooter: false,
    content_type: "ppt",
    supportedTypes:
      "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.oasis.opendocument.presentation",
    supportedFormats: "ppt, pptx, odp",
    selectConfig: {
      maxSize: "200 MB",
    },
    disableUploadURLToS3: true,
    notShowDriveBtn: true,
  };
  const [mediaFormSetting, setMediaFormSetting] = useState(
    mediaFormInitialState
  );

  mediaFormSetting.beforeLoadURL = (data) => {
    setMediaFormSetting({
      ...mediaFormSetting,
    });
    return data;
  };

  let currentJSON;
  if (presentationMode) {
    currentJSON = parsedJSONObject;
  } else {
    currentJSON = state;
  }
  const segment_data = currentJSON?.segment_data || {};
  const resource_setting = currentJSON.resource_setting || {};
  const attachments = segment_data.attachments || [];
  const attachmentsCount = attachments.length;

  useEffect(() => {
    if (createMode) {
      if (props.onChange) {
        let resource_json = cloneDeep(state);
        props.onChange(resource_json);
      }
    }
  }, [stateUpdatedCount]);

  const saveState = (state) => {
    setState(state);
    console.log("stateUpdated count ==>", stateUpdatedCount);
    setStateUpdatedCount((stateUpdatedCount) => stateUpdatedCount + 1);
  };

  const onTabDragEnd = (attachments) => {
    const newAttachments = attachments.slice();

    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    saveState(newState);
  };

  const removeItem = (index) => {
    const extra = {
      noMinSize: true,
    };
    const newAttachments = removeItemFromArray(attachments, index, extra);
    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };
    saveState(newState);
  };

  const onSaveMedia = (files) => {
    console.log("Files and attachmetns ==>", attachments, files);
    let newAttachments = attachments.concat(files);

    const limit = mediaFormSetting.limit;
    if (limit) {
      newAttachments = newAttachments.slice(-limit);
    }

    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    console.log("ON save Embed ==>", newAttachments, newState);

    saveState(newState);
    setMediaFormSetting({
      ...mediaFormSetting,
      from: "",
      triggerSaveAction: 0,
    });
  };

  const onTextChanged = (type, index, data) => {
    const newAttachments = attachments;
    newAttachments[index][type] = data;

    const newSegmentData = {
      ...segment_data,
      attachments: newAttachments,
    };

    const newState = {
      ...state,
      segment_data: newSegmentData,
    };

    console.log("onchange is getting fired ==>", newState, data);
    saveState(newState);
  };

  const setResourceSetting = (key, value) => {
    const newResourceSetting = {
      ...resource_setting,
      [key]: value,
    };

    const newState = {
      ...state,
      resource_setting: newResourceSetting,
    };

    saveState(newState);
  };

  const onTabEdit = (index, action) => {
    if (action == "add") {
      setMediaFormSetting({
        ...mediaFormSetting,
        from: "drive",
        modal: false,
      });
    } else if (action == "remove") {
      removeItem(index);
    }
  };

  const getMediaForm = () => {
    return (
      <MediaUI
        drive={true}
        mediaFormSetting={mediaFormSetting}
        setMediaFormSetting={(data) => setMediaFormSetting(data)}
        onSaveMedia={(files) => onSaveMedia(files)}
        attachmentsCount={attachmentsCount}
      ></MediaUI>
    );
  };

  return (
    <Segment>
      <SegmentData>
        {createMode && getMediaForm()}

        {attachmentsCount > 0 && (
          <div>
            {createMode ? (
              <TabularAttachments
                createMode={createMode}
                hideAdd={mediaFormSetting.limit == 1}
                titleRequired={true}
                attachmentType="ppt"
                tabPrefix="PPT"
                attachments={attachments}
                resource_setting={resource_setting}
                onTabEdit={onTabEdit}
                onTextChanged={onTextChanged}
                onTabDragEnd={onTabDragEnd}
              ></TabularAttachments>
            ) : (
              <PPTView resource_json={state}></PPTView>
            )}
          </div>
        )}
      </SegmentData>
    </Segment>
  );
};
export default PPTForm;
