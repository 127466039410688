import { fromJS } from "immutable";
import { MrReduxCrud } from "mr_react_framework";
import { axiosInstance } from "/src/api/apiModule";
import { put } from "redux-saga/effects";
import { message } from "/src/components/UI/AntdAppHelper";

const reduxCrud = new MrReduxCrud({
  axiosInstance,
  resourceName: "user",
  actionNames: [
    "FETCH",
    "CREATE",
    "UPDATE",
    "DELETE",
    "SHOW",
    "BULK_DELETE",
    "SET_ACTIVE_CHILD_ID",
    "EXPORT_USERS_TO_EXCEL",
    "UPDATE_PERMISSION",
    "EXPORT_USERS_USAGE_TO_EXCEL",
    "SEND_WELCOME_EMAIL"
  ],
});

const initialState = fromJS({
  users: [],
  page: 1,
  total_pages: 1,
  total_count: 0,
  page_size: 0,
  // action: {},
  error: null,
  loading: false,
  activeChildId: 0,
});

function setActiveChildIdSuccess(state = initialState, action) {
  return state.set("activeChildId", action.payload.childId);
}
export function* bulkDeleteSaga(action) {
  yield put(actions.bulkDeleteStart());
  let url = "users/bulk_delete.json";
  let params = action.payload.paramsToUse;
  console.log("BulkActions bulkDeleteSaga calling", action);
  try {
    const response = yield axiosInstance.instance.post(url, params);
    console.log("BulkActions bulkDeleteSaga response", response);
    if (response && response.status === 200) {
      message.success("Bulk archive successful");
      yield put(
        // actions.fetchSuccess({data: {users: []}}) // to set empty list
        actions.fetch({params: params}) // to reload list
      );
      yield put(actions.bulkDeleteSuccess());
      if(action.options.successCallback){
        action.options.successCallback()
      }
    }
  } catch (error) {
    console.error("bulkDeleteSaga error", error, error.response);
    let errorMessageToshow = "Something went wrong in bulk archive";
    if(error.response && error.response.data && error.response.data.error){
      errorMessageToshow = error.response.data.error
    }
    // message.error("Something went wrong in bulk archive");
    yield put(actions.bulkDeleteFail({ error: errorMessageToshow }));
    if(action.options.errorCallback){
      action.options.errorCallback()
    }
  }
}


export function* exportUsersToExcelSaga(action) {
  yield put(actions.exportUsersToExcelStart());
  let url = "/users/export_users_to_excel.json";
  let exportUsersToExcelData = { ...action.payload };
  console.log("exportUsersToExcelSaga calling", exportUsersToExcelData);
  try {
    const response = yield axiosInstance.instance.post(
      url,
      exportUsersToExcelData
    );

    if (response && response.data.export_excel_url) {
      yield put(
        actions.exportUsersToExcelSuccess({
          key: "exported_excel_url",
          response: response.data.export_excel_url,
        })
      );
      if (action.options.successCallback) {
        action.options.successCallback(response.data.export_excel_url);
      }
    }
  } catch (error) {
    console.error("exportUsersToExcelSaga error", error);
    message.error("Something went wrong in exporting to excel");
    yield put(actions.exportUsersToExcelFail({ error }));
    if (action.options.errorCallback) {
      action.options.errorCallback(error);
    }
  }
}

export function* exportUsersUsageToExcelSaga(action) {
  console.log("actions =======>", actions);
  
  yield put(actions.exportUsersUsageToExcelStart());
  let url = "/users/export_users_usage_to_excel.json";
  let exportUsersToExcelData = { ...action.payload };
  console.log("exportUsersToExcelSaga calling", exportUsersToExcelData);
  try {
    const response = yield axiosInstance.instance.post(
      url,
      exportUsersToExcelData
    );

    if (response && response.data.export_excel_url) {
      yield put(
        actions.exportUsersUsageToExcelSuccess({
          key: "exported_excel_url",
          response: response.data.export_excel_url,
        })
      );
      if (action.options.successCallback) {
        action.options.successCallback(response.data.export_excel_url);
      }
    }
  } catch (error) {
    console.error("exportUsersToExcelSaga error", error);
    message.error("Something went wrong in exporting to excel");
    yield put(actions.exportUsersUsageToExcelFail({ error }));
    if (action.options.errorCallback) {
      action.options.errorCallback(error);
    }
  }
}


export function* updatePermissionSaga(action) {
  yield put(actions.updatePermissionStart());
  let url = "users/update_permissions.json";
  let params = action.payload;
  try {
    const response = yield axiosInstance.instance.post(url, params);
    console.log("updatePermission updatePermissionSaga response", response);
    if (response && response.status === 200) {
      message.success("Permission updated successfully");
      // We update users in redux later."
      // yield put(
      //   // actions.fetchSuccess({data: {users: []}}) // to set empty list
      //   // actions.fetch({params: params}) // to reload list
      //   // actions.updateSuccess({
      //   //   data: {
      //   //     user: response.data.users
      //   //   }
      //   // })
      // );
      yield put(actions.updatePermissionSuccess());
      if(action.options.successCallback){
        action.options.successCallback()
      }
    }
  } catch (error) {
    console.log("error =====>", error);
    // yield put(actions.updatePermissionFail());
    if(action.options.errorCallback){
      action.options.errorCallback()
    }
  }
}


export function* sendWelcomeEmailSaga(action) {
  let url = "/users/send_welcome_email.json";
  let params = action.payload;

  try {
    const response = yield axiosInstance.instance.post(url, params);
    if (response && response.status === 200) {
      message.success("Email sent successfully");
      if (action.options.successCallback) {
        action.options.successCallback();
      }
    }
  } catch (error) {
    console.log("send_welcome_email==>", error);
    if(action.options.errorCallback){
      action.options.errorCallback();
    }
    message.error("Something went wrong in sending email");
  }
}

export const actions = reduxCrud.getActions();
export const actionTypes = reduxCrud.getActionTypes();
export const reducer = reduxCrud.getReducer(initialState, {
  [actionTypes.SET_ACTIVE_CHILD_ID_USER_SUCCESS]: setActiveChildIdSuccess,
});
export const watchUsers = reduxCrud.generateWatchSaga({
  [actionTypes.BULK_DELETE_USER]: bulkDeleteSaga,
  [actionTypes.UPDATE_PERMISSION_USER]: updatePermissionSaga,
  [actionTypes.EXPORT_USERS_TO_EXCEL_USER]: exportUsersToExcelSaga,
  [actionTypes.EXPORT_USERS_USAGE_TO_EXCEL_USER]: exportUsersUsageToExcelSaga,
  [actionTypes.SEND_WELCOME_EMAIL_USER]: sendWelcomeEmailSaga

});

export default reduxCrud;
