import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col, Input } from "antd";
// import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import { useRouteMatch } from "react-router-dom";
// import '../Groups.scss'
// import Groups from "/src/views/Groups/Groups"
// import GroupGUFormItemDetail from "/src/views/Groups/GroupItemDetail/GroupGUFormItemDetail"
import { useSelector } from "react-redux";
import _ from "lodash";
// import Search from "antd/lib/input/Search";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { currentUserSelector } from "/src/views/Auth/Login/selector";

const { Search } = Input;

const GroupMembersList = (props) => {
  console.log("groups members list props===>", props);
  const { dataSource, groupUpdateResource, group } = props;
  // let canEdit = !group.source_id
  const currentUser = useSelector(currentUserSelector());
  let canEdit = !group.org_programme_is_mb && !currentUser.org.is_one_roster && !currentUser.org.is_edlink;
  // dataSource has students and teachers for when we want to do teacher here also
  // const tableDataSource = []
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredTableDataSource, setFilteredTableDataSource] = useState([]);
  const [tableDataSource, setTableDataSource] = useState([]);
  // using below for after updateResource response and set
  // const [existingGroupUsers, setExistingGroupUsers] = useState(dataSource.filter(gu => gu.archived === false && gu.relation == "student"))
  const [existingGroupUsers, setExistingGroupUsers] = useState(
    dataSource.filter((gu) => gu.relation == "student")
  );

  const allGradeUsers = useSelector((state) => {
    console.log("state allGradeUsers", state);
    if (state.groupUsers.get("extra"))
      return state.groupUsers.get("extra").users;
  });

  console.log("groups members list allGradeUsers===>", allGradeUsers);

  useEffect(() => {
    let existingActiveGroupUserIds = [];
    if (dataSource.length !== 0) {
      console.log("groups members list dataSource===>", dataSource);
      let existingActiveGroupUsers = dataSource.filter(
        (gu) => gu.archived === false && gu.relation == "student"
      );
      existingActiveGroupUserIds = _.map(existingActiveGroupUsers, "user_id");
      setSelectedRowKeys(existingActiveGroupUserIds);
    }
    if (allGradeUsers) {
      let temp = [];
      let data = allGradeUsers.data;
      for (let index = 0; index < data.length; index++) {
        let objToPush = {};
        objToPush.key = data[index].attributes.id;
        objToPush.id = data[index].attributes.id;
        objToPush.full_name = data[index].attributes.name;
        objToPush.first_name = data[index].attributes.first_name;
        if (data[index].attributes.display_name) {
          objToPush.first_name += " (" + data[index].attributes.display_name + ")";
        }
        objToPush.last_name = data[index].attributes.last_name;
        objToPush.email = data[index].attributes.email;
        objToPush.s_code = data[index].attributes.s_code;
        // objToPush.role = data[index].attributes.role
        objToPush.grade_label = data[index].attributes.grade
          ? data[index].attributes.grade.label
          : "";

        temp.push(objToPush);
      }
      const sortOrderTemp = [];
      const filteredTemp = [];
      temp.map((t, i) => {
        if (
          existingActiveGroupUserIds &&
          existingActiveGroupUserIds.includes(t.id)
        ) {
          sortOrderTemp.push(t);
        } else {
          filteredTemp.push(t);
        }
      });
      const finalTemp = [...sortOrderTemp, ...filteredTemp];
      setTableDataSource(finalTemp);
      setFilteredTableDataSource(finalTemp);
    }
  }, []);
  
  const mrIntl = useTranslate();
  const updateGroupUsers = () => {
    let groupUsersAttributes = [];
    let existingGroupUserIds = _.map(existingGroupUsers, "user_id");
    let updatedGroupUserIds = selectedRowKeys;

    console.log(
      "update called",
      existingGroupUsers,
      existingGroupUserIds,
      updatedGroupUserIds
    );

    let toCreate = _.difference(updatedGroupUserIds, existingGroupUserIds);
    let toRemove = _.difference(existingGroupUserIds, updatedGroupUserIds);
    let toUnarchive = _.intersection(existingGroupUserIds, updatedGroupUserIds);

    // ** dataSource has students and teachers for when we want to do teacher here also - be careful when setting relation

    console.log(
      "toCreate, toRemove, toUnarchive ",
      toCreate,
      toRemove,
      toUnarchive
    );
    if (toCreate) {
      toCreate.map((userId, i) => {
        groupUsersAttributes.push({
          relation: "student",
          user_id: userId,
        });
      });
    }
    if (toRemove) {
      toRemove.map((userId, i) => {
        // console.log("userId, existing ", userId, existing, dataProps.item.experience_memberships_collaborators);
        let gu = _.find(existingGroupUsers, ["user_id", userId]);
        groupUsersAttributes.push({
          id: gu.id,
          // relation: "student",
          // user_id: userId,
          archived: true,
        });
      });
    }
    if (toUnarchive) {
      toUnarchive.map((userId, i) => {
        // console.log("userId, existing ", userId, existing, dataProps.item.experience_memberships_collaborators);
        let gu = _.find(existingGroupUsers, ["user_id", userId]);
        groupUsersAttributes.push({
          id: gu.id,
          // relation: "student",
          // user_id: userId,
          archived: false,
        });
      });
    }

    console.log("update called", groupUsersAttributes);
    groupUpdateResource(
      { id: group.id, group_users_attributes: groupUsersAttributes },
      {
        success: { showMessage: true, message: mrIntl ("CommonText.student_list_updated") },
        error: { showMessage: true, message: mrIntl("CommonText.error_in_updating_student_list") },
        successCallback: (data) => {
          console.log("updated data", data);
          let updatedGroup = data.data.group.data.attributes;
          if (
            updatedGroup &&
            updatedGroup.group_users_students_with_names &&
            updatedGroup.group_users_students_with_names.data
          ) {
            let diggedGUs = updatedGroup.group_users_students_with_names.data.map(
              (gu, i) => {
                return gu.attributes;
              }
            );
            setExistingGroupUsers(diggedGUs);
          }
        },
      }
    );
    // add success and error message
  };

  const rowSelection = {
    selectedRowKeys,
    hideSelectAll: true,
    // onChange: (tempSelectedRowKeys) => {
    // // onChange: (tempSelectedRowKeys) => {
    //   // to support search and select
    //   // selectedRowKeys = _.intersection(selectedRowKeys, tempSelectedRowKeys)
    //   // let sRowKeys = [...tempSelectedRowKeys]
    //   let sRowKeys = _.union([...selectedRowKeys], [...tempSelectedRowKeys])
    //   // console.log('tempSelectedRowKeys, selectedRowKeys ', tempSelectedRowKeys, selectedRowKeys, _.union(selectedRowKeys, tempSelectedRowKeys));
    //   console.log('tempSelectedRowKeys, selectedRowKeys ', tempSelectedRowKeys, selectedRowKeys, sRowKeys);
    //   // setSelectedRowKeys(_.union(selectedRowKeys, tempSelectedRowKeys))
    //   setSelectedRowKeys(sRowKeys)
    //   // console.log('selectedRowKeys changed: ', tempSelectedRowKeys, selectedRowKeys);
    // },
    onSelect: (row, selected, selectedRows) => {
      console.log("row, selected ", row, selected, selectedRowKeys);
      let sRowKeys;
      if (selected === true) {
        sRowKeys = _.union([...selectedRowKeys], [row.id]);
      } else {
        sRowKeys = _.difference([...selectedRowKeys], [row.id]);
      }
      setSelectedRowKeys(sRowKeys);
      console.log("row, selected ", sRowKeys, selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: !canEdit, // Column configuration not to be checked
    }),
  };
  const filterBy = (attr, value) => {
    console.log("filterBy value", value);
    if (value) {
      let searchValue = value.toLowerCase();
      // var re = new RegExp(input+'.+$', 'i');
      // patients = patients.filter(function(e, i, a){
      //     return e.search(re) != -1;
      // });
      let filtered = tableDataSource.filter(
        (user) => user?.full_name?.toLowerCase()?.indexOf(searchValue) > -1 || user?.email?.toLowerCase()?.indexOf(searchValue) > -1
      );
      setFilteredTableDataSource(filtered);
      console.log(
        "filteredTableDataSource",
        filteredTableDataSource,
        tableDataSource
      );
    } else {
      console.log("tableDataSource", tableDataSource);
      setFilteredTableDataSource(tableDataSource);
    }
  };

  const columns = [
    {
      title: mrIntl("CommonText.first_name_small_name"),
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["ascend", "descend"],
      defaultSorter: "ascend",
    },
    {
      title: mrIntl("CommonText.last_name"),
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      sortDirections: ["ascend", "descend"],
      defaultSorter: "ascend",
    },
    {
      title: mrIntl("CommonText.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: mrIntl("CommonText.student_code"),
      dataIndex: "s_code",
      key: "s_code",
    },
    // {
    //   title: 'Role',
    //   dataIndex: 'role',
    //   key: 'role',
    // },
    {
      title: mrIntl("CommonText.grade"),
      dataIndex: "grade_label",
      key: "grade_label",
    },
  ];
  const { url } = useRouteMatch();
  console.log("exp list item url", url);

  let tempOffset = canEdit ? 12 : 14;

  return (
    <React.Fragment>
      {/* <Card>
      <Route path={`${path}`} render={routeProps => <Groups {...props} resourceId={props.createUpdateParams.group_id} isRoute={false} {...routeProps} config={{routes: {fetch:false}, itemDetail: {widget: GroupGUFormItemDetail}}}/>}  /> 
    </Card> */}
      {/* <Card>
      <div>
        {props.renderForm({
          name: "groupUsers",
          config: { isModal: false, visible: true },
          // resource: item //use this for edit
        })}
      </div>
    </Card> */}

      {/* {filteredTableDataSource.length == 0 && <h3>No students</h3>} */}

      <Row style={{ width: "100%", textAlign: "right", marginBottom: "20px" }}>
        <Col span={6}>
          <Search
            placeholder={mrIntl("GroupMembersList.search_by_name")}
            onSearch={(value) => {
              console.log(value);
              filterBy("name", value);
            }}
            enterButton
          />
        </Col>
        <Col span={4} offset={tempOffset} style={{ padding: "6px 0px" }}>
          {selectedRowKeys.length} {mrIntl("GroupMembersList.students_selected")}
        </Col>
        {canEdit && (
          <Col span={2}>
            <Button
              type="primary"
              onClick={(e) => {
                updateGroupUsers();
              }}
            >
              <MrTranslate id={"CommonText.save"}/>
            </Button>
          </Col>
        )}
      </Row>
      {/* {selectedRowKeys} */}

      {existingGroupUsers.length !== 0 && (
        <Row>
          <Col span={24}>
            {/* <h5>Students mapped to this class:</h5> */}
            <h5> <MrTranslate id={"GroupMembersList.students_mapped_to_this_class"}/>:</h5>
            <p>
              {existingGroupUsers.map((gu, i) => {
                return !gu.archived ? <span>{gu.full_name}, </span> : "";
              })}
            </p>
          </Col>
        </Row>
      )}

      {/* {JSON.stringify(filteredTableDataSource)} */}
      <Table
        rowSelection={rowSelection}
        dataSource={filteredTableDataSource}
        columns={columns}
        pagination={false}
        style={{ maxHeight: "500px", overflow: "auto" }}
      />
    </React.Fragment>
  );
};
GroupMembersList.defaultProps = {};

GroupMembersList.propTypes = {};

export default GroupMembersList;
