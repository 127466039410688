import React from "react";
import { Table } from "antd";
import { useRouteMatch } from "react-router-dom";
import ActionBtns from "/src/components/ActionBtns/ActionBtns";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux";
import { Space, List, Tooltip, Button, Dropdown, Menu, Popconfirm } from "antd";
import CustomListItem from "/src/components/CustomListItem/CustomListItem";
import { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { SyncOutlined, DownOutlined } from "@ant-design/icons";
import { enabledFeaturesSelector } from "/src/views/Auth/Login/selector";



const QuestionbanksList = (props) => {
  console.log("QuestionbanksList props", props);
  const { dataSource, permission } = props;
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const enabledFeatures = useSelector(enabledFeaturesSelector());
  console.log("exp list item url", url);

  let showSyncBtn = (import.meta.env.VITE_MODE != 'production' || import.meta.env.VITE_REGION == "china") && permission.questionbank && permission.questionbank.update;
  // let showSyncBtn = true;
  // meant for non-global non-prod envs - mainly china prod

  let showUpdateCountBtn = (import.meta.env.VITE_REGION != "china") && permission.questionbank && permission.questionbank.update;
  
  const mrIntl = useTranslate()

  const Title = ({ item }) => <span>{`${item.title} (${item.code})`}</span>

  const Description = ({ item }) => {
    return <Space className="description" key={item.id} direction="vertical">
      <Space>
        <Tooltip title="Program" >
          <span>{`${item.program_name}`}</span>
        </Tooltip>
        <span>
          | Duplicates editable: {
            (item.settings.dups_editable === null ||
              item.settings.dups_editable === undefined)
              ? "False"
              : item.settings.dups_editable.toString()
          }
        </span>
      </Space>
      <span>
        Counts : {JSON.stringify(item.custom_fields.counts)}
      </span>
      {showSyncBtn && item.custom_fields.sync_info && <span>
        Sync info : {JSON.stringify(item.custom_fields.sync_info)}
      </span>}
    </Space>
  };


  const extraActions = (item) => {
    const actionButtons = [];

    let onSync = (save, staticOnly = false) => {
      dispatch(actions.sync({ code: item.code, save: save, static_only: staticOnly }))
    }
    const cancel = (e) => {
      e.stopPropagation();
    };
    const confirm = (e) => {
      e.stopPropagation();
      onSync(true)
    };
    const staticConfirm = (e) => {
      e.stopPropagation();
      onSync(true, true)
    };

    const updateCount = () => {
      dispatch(actions.updateSegmentAndExperienceCounts({ id: item.id }))
    };

    const syncMenu = (
      <Menu>
        <Menu.Item
          key={`staticDryRunSyncBtn-${item.id}`}
          title={"Static tables dry run"}
          onClick={() => onSync(false, true)}
        >
          Static dry run
        </Menu.Item>
        <Menu.Item
          key={`staticLiveRunSyncBtn-${item.id}`}
          title={"Static tables live run"}
          onClick={() => onSync(true, true)}
        >
          Static live run
        </Menu.Item>
        {/* <Popconfirm
          key={`static-sync1-${item.id}`}
          title={"Confirm sync"}
          // title="Confirm archive?"
          onConfirm={staticConfirm}
          onCancel={cancel}
          placement={"left"}
          okText={mrIntl("CommonText.yes")}
          cancelText={mrIntl("CommonText.no")}
        >
          <Menu.Item key={`runStaticSyncBtn-${item.id}`}
            title={"Run"}
          >
            Static live run
          </Menu.Item>
        </Popconfirm> */}
        <Menu.Item
          key={`dryRunSyncBtn-${item.id}`}
          title={"Dry run"}
          onClick={() => onSync(false)}
        >
          Full dry run
        </Menu.Item>
        <Popconfirm
          key={`sync-${item.id}`}
          title={"Confirm sync"}
          // title="Confirm archive?"
          onConfirm={confirm}
          onCancel={cancel}
          placement={"left"}
          okText={mrIntl("CommonText.yes")}
          cancelText={mrIntl("CommonText.no")}
        >
          <Menu.Item key={`runSyncBtn-${item.id}`}
            title={"Run"}
          >
            Full live run
          </Menu.Item>
        </Popconfirm>
      </Menu>
    );

    if(showUpdateCountBtn){
      actionButtons.push(
        <>
          <Button type="text" onClick={updateCount} >
            Update counts
          </Button>
        </>
      );
    }
    
    if (showSyncBtn) {
      actionButtons.push(
        <div>
          <Dropdown
            key={`syncBtn-${item.id}`}
            overlay={syncMenu}
          >
            <Button type="text" ghost={false} icon={<SyncOutlined />}>
              Sync <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    }
    return actionButtons;
  }

  return (
    // <Table dataSource={tableDataSource} columns={columns} pagination={false} />
    <>
      <List
        // header={listHeader}
        className="custom-main-list"
        // loading={initLoading}
        itemLayout="horizontal"
        dataSource={dataSource}
        renderItem={(item, i) => (
          <CustomListItem
            item={item}
            type="questionbanks"
            // linkToGo={`${url}/${item.id}/edit`}
            // currentUser={currentUser}
            title={<Title item={item} />}
            description={<Description item={item} />}
            edit={permission.questionbank && permission.questionbank.update}
            deleted={props.deleted}
            deletedAllow={false}
            extraActions={extraActions(item)}
          // permission={permission}
          // showForm={props.showForm}
          // setUserToSetPassword={setUserToSetPassword}
          />
        )}
      />
    </>
  );
};

export default QuestionbanksList;
