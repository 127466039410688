

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd';
import { FormRenderer } from "mr_react_framework";
import SelectOrgDropdown from '/src/components/AppSpecific/SelectOrg/SelectOrgDropdown';
import { actions as genericActions } from "/src/App/genericRedux";
import Spinner from "/src/components/UI/Spinner/Spinner";
import { SelectFeaturesCode } from '/src/components/AppSpecific/SelectSubject/SelectSubject';

export const SelectOrgCustomComponent = (props) => {
  console.log("SelectOrgCustomComponent props", props);
  const { value, onChange, formInstance: {getFieldValue} } = props
  const orgFeatures = getFieldValue(["org_features"]) || [];
  const hiddenOrgIds = []
  orgFeatures.forEach((of) => {
    console.log("of ==>", of, value);
    if (of && of.org_id !== value) {
      hiddenOrgIds.push(of.org_id)
    }
  });
  const params = { should_serialize: false, page_size: 10 };
  
  return (
    <SelectOrgDropdown
      value={value}
      onChange={(newValue) => {
        onChange(newValue); 
      }}
      config={{
        // initialSearchParams: {...params, by_feature_id: feature_id, page_size: 100},
        searchApi: true,
        params: params,
        debounceTime: 1000,
        widgetConfig:{
          hiddenOrgIds: hiddenOrgIds,
          allowClear: false,
        }
      }}
    />
  );
};

export const SelectFeaturesCustomComponent = (props) => {
  console.log("SelectFeaturesCustomComponent props", props);
  const { value, onChange, formInstance: { getFieldValue } } = props;
  
  const orgFeatures = getFieldValue(["org_features"]) || [];
  const hiddenFeatureIds = []
  orgFeatures.forEach((of) => {
    if (of && of.feature_id !== value) {
      hiddenFeatureIds.push(of.feature_id)
    }
  });
  const params = { should_serialize: false, page_size: 100 };

  return (
    <SelectFeaturesCode
      value={value}
      onChange={(newValue) => {
        onChange(newValue);
      }}
      config={{
        // initialSearchParams: {...params, by_org_id: org_id, page_size: 200},
        searchApi: true,
        params: params,
        debounceTime: 1000,
        widgetConfig: {
          hiddenFeatureIds: hiddenFeatureIds,
          allowClear: false,
        }
      }}
    />
  );
};

const OrgFeaturesChildComponent = (props) => {
  console.log("OrgFeaturesChildComponent props", props, props.__INTERNAL__.name);
  const { getFieldValue, sourceValues, formInstance } = props;
  let finalRender = null;

  const formName = props.__INTERNAL__.name;
  const resourceName = formName.indexOf("orgs") !== -1 ? "features" : "orgs"; // For features, we are adding orgs and for orgs, we are adding features - Passing resourceName from above is causing remounting of the component

  const orgFeatures = sourceValues.org_features || [];
  const dispatch = useDispatch();
  const [isDataLoaded, setIsDataLoaded] = useState(orgFeatures.length > 0 ? false : true);
  
  let shouldShowForm = true
  let resource_key = "feature_id";
  let by_filter_key = "by_org_id";
  if (resourceName === "orgs") {
    const status = getFieldValue(["status"]);
    shouldShowForm = status !== "released";
    resource_key = "org_id";
    by_filter_key = "by_feature_id";
  }

  useEffect(() => {
    if (shouldShowForm && !isDataLoaded) {
      dispatch(genericActions[resourceName]({params: {[by_filter_key]: sourceValues.id, unarchived: true, should_serialize: false, page_size: 200}}, {
        resourceName: resourceName,
        apiResourceName: resourceName,
        url: "/" + resourceName,
        successCallback: (data) => {
          console.log("successCallback data ===>", data);
          setIsDataLoaded(true);
        }
      }));
    }
  }, [shouldShowForm, resourceName, sourceValues.id]);

  
  if (shouldShowForm) {
    if (!isDataLoaded) {
      return <Spinner />; // or a loading spinner
    }

    finalRender = <FormRenderer
      formObj={{
        type: "object",
        properties: {
          org_features: {
            type: "array",
            span: 24,
            min: 0,
            max: 100,
            default: [{}],
            title: resourceName === "orgs" ? "Add organization" : "Feature overrides",
            items: {
              type: "object",
              properties: {
                [resource_key]: {
                  type: "string",
                  title: resourceName === "orgs" ? "Select org" : "Select feature",
                  widget: resourceName === "orgs" ? SelectOrgCustomComponent : SelectFeaturesCustomComponent,
                  span: 8,
                  rules: [
                    {
                      required: true,
                      message:  resourceName === "orgs" ? "Please select org" : "Please select feature",
                    },
                  ],
                },
                enabled: {
                  type: "boolean",
                  title: "Enable/Disable",
                  span: 6,
                  widget: Switch,
                  widgetConfig: {
                    defaultValue: true,
                  },
                  valuePropName: "checked",
                  setInitialValue: true,
                },
              },
            },
          },
        },
      }}
      sourceValues={sourceValues}
      formInstance={formInstance}
    />
  }
  return finalRender;
}

export default OrgFeaturesChildComponent;