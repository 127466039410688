import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";
import { activeExperienceUserSelector, experienceUsersLoadingSelector, gradingModalVisibleSelector } from "../selector";
import { Button, Modal } from "antd";
import { Route, useHistory, useRouteMatch, useLocation } from "react-router-dom";
import ExperienceUserHeader from "./ExperienceUserHeader";
import ExperienceUsers from "../ExperienceUsers";
import ExperienceUserGrading from "./ExperienceUserGrading";
import { actions as experienceUsersActions } from "/src/views/ExperienceUsers/redux";
import { activeSegmentIdSelector } from "/src/views/Segments/selector";
import ShortcutsTip from "/src/components/UI/ShowShortCuts";
import { canvasLoadingSelector } from "/src/views/CanvasAnnotations/selector";
import { loadingSelector } from "/src/views/UserResponses/selector";
import { highLightActiveCell, removeHighLightActiveCellClass } from "/src/views/Experiences/ExperienceHelperMethods";
import { forceHideAnnotation } from "/src/lib/utils/helperMethods";

const ExperienceUserGradingModal = (props) => {
  const { experience, gradingConfig, setGradingConfig, exportModalConfig, setExportModalConfig } = props
  console.log("ExperienceUserGradingModal==>", props)
 
  const dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation()
  const { path, url } = useRouteMatch();
  const experienceUser = useSelector(activeExperienceUserSelector());
  const gradingModalVisible = useSelector(gradingModalVisibleSelector());
  const activeSegmentId = useSelector(activeSegmentIdSelector());
  const loadingCheckRef =  useRef();
  const loadingUserResponse =  useSelector(loadingSelector());
  const loadingCanvas =  useSelector(canvasLoadingSelector());
  const loadingExperienceUsers = useSelector(experienceUsersLoadingSelector())

  useEffect(() => {
    loadingCheckRef.current = loadingUserResponse || loadingCanvas || loadingExperienceUsers;
    console.log("loadingCheckRef =====> outside", loadingCheckRef.current, {loadingUserResponse, loadingCanvas, loadingExperienceUsers})
  }, [loadingUserResponse, loadingCanvas, loadingExperienceUsers]);

  const checkIfLoadingCompleted = (callback) => {
    console.log("loadingCheckRef =====>", loadingCheckRef.current, {loadingUserResponse, loadingCanvas, loadingExperienceUsers});
    if (loadingCheckRef.current) {
      setTimeout(() => {
        checkIfLoadingCompleted(callback);
      }, 200);
    } else if (callback) {
      callback();
    }
  };

  const closeModal = () => {
    // const newUrl = url.substring(0, url.lastIndexOf("/"));
    checkIfLoadingCompleted(() => {
      // const selectedCell = document.querySelector(`#selected-cell-${experienceUser.id}-${activeSegmentId}`);
      const selectedCell = document.querySelector(`[data-id="segment-cell-${experienceUser.id}-${activeSegmentId}"] div`);

      if (selectedCell) {
        removeHighLightActiveCellClass()
        highLightActiveCell(selectedCell)
      }
      // Doing this because when we close the modal then the text annotation popup don't close sometime
      forceHideAnnotation()
      dispatch(experienceUsersActions.setGradingModalVisibleSuccess({ visible: false }));
      // TODO: Need to find better way to handle this as history push/replace causing component to re-load remove last selection state
      // history.replace(`${url}${location.search}`);
    })
  }

  const modalCloseIcon = (
    <Button type="text" className="experience-user-list-modal-close" onClick={() => closeModal()} icon={<CloseCircleOutlined />}></Button>
  );
  
  return gradingModalVisible ? <Modal
    width="100%"
    open={gradingModalVisible}
    className={"full-screen-modal exp-user-list"}
    closeIcon={modalCloseIcon}
    title={<ExperienceUserHeader
      showFormVal={props.showFormVal} 
      renderForm={props.renderForm}
      updateResource={props.updateResource}
      experience={experience}
      gradingConfig={gradingConfig}
      setGradingConfig={setGradingConfig}
      exportModalConfig = {exportModalConfig}
      setExportModalConfig = {setExportModalConfig}
      closeModal={closeModal}
    ></ExperienceUserHeader>}
    footer={<ShortcutsTip />}
    closable={true}
    onCancel={() => closeModal()}
  >
    <Route
      key={"experience-user-grading"}
      path={`${path}`}
      render={(routeProps) => (
        <ExperienceUsers
          doNotReloadOnUpdate={true}
          showInModal={true}
          resourceId={experienceUser.id}
          gradingConfig={gradingConfig}
          setGradingConfig={setGradingConfig}
          experience={experience}
          exportModalConfig={exportModalConfig}
          setExportModalConfig={setExportModalConfig}
          isRoute={false}
          config={{
            list: {
              shouldExecuteRequest: false,
            },
            // routes: { fetch: false },
            itemDetail: { widget: ExperienceUserGrading },
          }}
          {...routeProps}
        />
      )}
    />
  </Modal> : null
  
};


export default ExperienceUserGradingModal;
