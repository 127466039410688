import { createSelector } from "reselect";
import lodash from "lodash";

// const loadingState = (state, props) => {
//   return state.programmes.get("loading");
// };
// export const loadingSelector = () =>
//   createSelector([loadingState], (loading) => loading);

// const errorState = (state, props) => state.programmes.get("error");

// export const errorSelector = () =>
//   createSelector([errorState], (error) => error);

const programmesState = (state, props) =>
  state.programmes.get("programmes");

export const programmesSelector = () =>
  createSelector([programmesState], (programmes) => programmes);

const programmesShowState = (state, props) => {

  const programmes = state.programmes.get("programmes");

  if (programmes.size === 0) {
    return {};
  } else {
    const id = parseInt(props.match.params.id);
    const idx = lodash.findIndex(programmes, {
      id: id,
    });
    if (idx !== -1) {
      return programmes[idx];
    }
    return {};
  }
};

export const programmesShowSelector = () =>
  createSelector([programmesShowState], (programmes) => programmes);


const programmegradesState = (state, props) =>
  state.request.get("programmegrades");

export const programmegradesSelector = () =>
  createSelector([programmegradesState], (programmegrades) => programmegrades);

const programmesubjectsState = (state, props) =>
  state.request.get("programmesubjects");

export const programmesubjectsSelector = () =>
  createSelector([programmesubjectsState], (programmesubjects) => programmesubjects);


// const paginationState = (state, props) => {
//   const pageObj = {};
//   pageObj.page = state.programmes.get("page");
//   pageObj.totalPages = state.programmes.get("total_pages");

//   return pageObj;
// };

// export const paginationSelector = () =>
//   createSelector([paginationState], (pageObj) => pageObj);
