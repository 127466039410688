import React, { useState, useEffect } from "react";
import MrTranslate, { useTranslate } from "/src/lib/MrTranslate/MrTranslate";
import { Tag } from "antd";
import Countdown from "react-countdown";

const ButtonCountDownWrapper = (props) => {
  const {
    targetTime,
    startCountDownTriggerMins = 60,
    showBeforeCountDownStarts,
    showWhenCountDownStart,
    showAfterCountDownComplete,
    // isJoinCodeCorrect
    onCountDownComplete,
    triggerUpdateFinalRender
  } = props;
  console.log("CountDownTimer props", props);
  const mrIntl = useTranslate()
  const [finalRender, setFinalRender] = useState(null);
  const countDownTimer = (
    <Tag color="orange">
      {mrIntl("JoinView.starts_in")}{" "}
      <Countdown
        date={targetTime}
        daysInHours={true}
        onComplete={() => {
          if (showAfterCountDownComplete) {
            setFinalRender(showAfterCountDownComplete)
          }

          if (onCountDownComplete) {
            onCountDownComplete()
          }
        }}
      />
    </Tag>
  );

  useEffect(() => {
    let targetTimeUTC = Date.parse(targetTime)
    let currentTimeUTC = new Date().getTime()
    if (targetTime && targetTimeUTC > currentTimeUTC) {
      // console.log("Date.parse(targetTime) - new Date().getTime()", targetTime, new Date(), Date.parse(targetTime), new Date().getTime(), Date.parse(targetTime) - new Date().getTime())
      if (targetTimeUTC - currentTimeUTC > startCountDownTriggerMins * 60 * 1000) {
        setFinalRender(showBeforeCountDownStarts)
      } else {
        if(showWhenCountDownStart) {
          setFinalRender(showWhenCountDownStart)
        } else {
          setFinalRender(countDownTimer)
        }
      }
    } else {
      if (showAfterCountDownComplete) {
        setFinalRender(showAfterCountDownComplete)
      } else {
        setFinalRender(countDownTimer)
      }
    }
  }, [triggerUpdateFinalRender]);

  return <>
    {finalRender}
  </>
};

export default ButtonCountDownWrapper